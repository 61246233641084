import React from "react";
import { Chart } from "chart.js";
import { getRelativePosition } from "chart.js/helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import {
  chartColors,
  emptyDoughnut,
  innerText,
  pointInCircle,
  textSize,
  wrapText
} from "./chartUtils";

const TITLE_SIZE = 50;
const SUBTITLE_SIZE = 12;
const CHART_PADDING = 40;

const VehiclesChart = props => {
  const {
    chartRef,
    chartData,
    total,
    segmentColors,
    setSegmentColors = () => {},
    handleChartClick = () => {}
  } = props;

  const handleClick = event => {
    if (chartData.data.length) {
      const canvasPosition = getRelativePosition(event, chartRef.current);
      const selectedSegment = getElementAtEvent(chartRef.current, event);
      let {
        width,
        height
      } = chartRef.current.chartArea;

      const circleCenterPoint = {
        x: width / 2 + CHART_PADDING,
        y: height / 2 + CHART_PADDING
      };
      const { selected, clicked } = chartColors;
      const { innerRadius } =
        chartRef.current._metasets[0]?.controller || 0;
      if (selectedSegment.length) {
        handleChartClick(selectedSegment[0]?.index);
        const selectedIdx = selectedSegment[0]?.index;
        setSegmentColors(prev =>
          prev.map((_, idx) => (idx === selectedIdx ? clicked : selected))
        );
      } else if (
        pointInCircle(canvasPosition, circleCenterPoint, innerRadius)
      ) {
        handleChartClick(null);
        setSegmentColors(prev => prev.map(c => selected));
      }
    }
  };

  return (
    <Doughnut
      ref={chartRef}
      onClick={handleClick}
      data={{
        labels: chartData.labels,
        datasets: [
          {
            data: chartData.data,
            backgroundColor: segmentColors,
            datalabels: chartData.labels
          },
          {
            datalabels: {
              title: { color: "white" }
            }
          },
          {
            datalabels: {
              total: { color: "black" }
            }
          }
        ]
      }}
      plugins={[innerText, emptyDoughnut, ChartDataLabels]}
      options={{
        cutout: 12,
        animation: {
          duration: 0
        },
        events: [],
        rotation: 90,
        plugins: {
          tooltip: {
            enabled: true
          },
          emptyDoughnut: {
            color: chartColors.default,
            width: 24,
            radiusDecrease: 1
          },
          innerText: {
            labels: [
              {
                text: total,
                fontSize: TITLE_SIZE,
                fontWeight: "bolder",
                color: "#585858"
              },
              {
                text: "TOTAL VEHICLES",
                fontSize: SUBTITLE_SIZE,
                fontWeight: "bold",
                color: "black"
              }
            ]
          },
          legend: {
            display: false
          },
          datalabels: {
            labels: {
              value: {
                anchor: "center",
                color: "white",
                font: context => {
                  let fontSize = 16;
                  let { ctx } = context.chart;
                  let text = context.dataset.data[context.dataIndex];
                  ctx.font = `bold ${fontSize}px`;

                  const cutoutWidth =
                    context.chart.chartArea.width -
                    context.chart._metasets[0].controller.innerRadius * 2;
                  fontSize = textSize(ctx, text, fontSize, cutoutWidth, 0.6);

                  return {
                    size: fontSize,
                    weight: "bold"
                  };
                },
                padding: 24
              },
              title: {
                formatter: (value, context) => {
                  if (!value) return "";
                  return wrapText(
                    context.dataset.datalabels[context.dataIndex]
                  );
                },
                anchor: "end",
                clamp: true,
                align: "end",
                offset: 8,
                font: context => {
                  let fontSize = segmentColors.length >= 10 ? 10 : 16;
                  let offset = 8;
                  let { ctx } = context.chart;
                  let text = context.dataset.datalabels[context.dataIndex];
                  ctx.font = `bolder ${fontSize}px`;

                  let outerAreaWidth =
                    (context.chart.width -
                      context.chart._metasets[0].controller.outerRadius * 2) /
                    2;
                  
                  fontSize = textSize(
                    ctx,
                    text,
                    fontSize,
                    outerAreaWidth,
                    0.4,
                    offset,
                    8
                  );

                  return {
                    size: fontSize,
                    weight: "bold"
                  };
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          autoPadding: true,
          padding: CHART_PADDING
        }
      }}
    />
  );
};

export default VehiclesChart;
