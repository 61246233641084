import {
  AppBar,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Event as EventIcon,
  FilterList as FilterListIcon
} from "@material-ui/icons";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from "lodash";
import React, { useRef, useState } from "react";
import useFormStyles from "../../../../styles/useFormStyles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import useUserContext from "../../../../context/User/useUserContext";
import AddLogs from "../../../../utils/functions/AddLogs";
import { useFilterStyles } from "./Filter.styles";
import CategoryChipInput from "./CategoryChipInput";
import SelectGeoChipInput from "./SelectGeoChipInput";

const Filter = props => {
  const {
    filterDrawer,
    closeFilterDrawer,
    categories,
    setCategories,
    initialDate,
    date,
    setDate,
    geoFilter,
    setGeoFilter,
    setIsFiltered
  } = props;
  const styles = useFilterStyles();
  const formStyles = useFormStyles();
  const userCtx = useUserContext();

  const [selectGeofence, setSelectGeofence] = useState(_.cloneDeep(geoFilter.type));
  const [dateRange, setDateRange] = useState({
    start: date.start || null,
    end: date.end || null,
    isValid: true
  });
  const categoriesRef = useRef(null);
  const geofencesRef = useRef(null);

  // resets date to initial && clears other filter values
  const handleClearFilter = () => {
    setDateRange({
      start: initialDate.from,
      end: initialDate.to,
      isValid: true
    });
    setSelectGeofence(null);
    categoriesRef.current.clearSelected();
    geofencesRef.current.clearSelected();
  };

  const handleSetFilters = () => {
    const selectedCategories = categoriesRef.current.getSelected();
    const selectedGeoFilters = geofencesRef.current.getSelected();
    
    // from / to
    setDate({
      base: moment(dateRange.start).format("YYYY-MM-DD HH:mm:ss"),
      start: moment(dateRange.start).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(dateRange.end).format("YYYY-MM-DD HH:mm:ss")
    });

    // geofence type
    if (selectedCategories.length) {
      setCategories(prev =>
        prev.map(cat => ({
          ...cat,
          isShown: selectedCategories.includes(cat.label)
        }))
      );
    } else {
      setCategories(prev =>
        prev.map(cat => ({
          ...cat,
          isShown: true
        }))
      );
    }

    // geofence code / geofence name
    setGeoFilter({
      type: selectGeofence,
      values: selectedGeoFilters
    });
    
    if (
      !_.isEqual(initialDate.from, dateRange.start) ||
      !_.isEqual(initialDate.to, dateRange.end) ||
      (selectedCategories.length && selectedCategories.length < categories.length) ||
      selectedGeoFilters.length
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    AddLogs(
      process.env.REACT_APP_FLEET_MODULE,
      "filter_historical",
      `Date Range: ${dateRange.start} - ${dateRange.end}, Geofence Category: ${
        selectedCategories.length ? selectedCategories.join(", ") : `All`
      }, Geofence Code: ${
        _.isEqual(selectGeofence, "Geofence Code")
          ? selectedGeoFilters.map(g => g.name).join(", ") || `All`
          : `All`
      }, Geofence Name: ${
        _.isEqual(selectGeofence, "Geofence Name")
          ? selectedGeoFilters.map(g => g.name).join(", ") || `All`
          : `All`
      }`
    );

    closeFilterDrawer();
  };

  // handles switching between geofence name and code search 
  const handleSelectGeoChange = event => {
    setSelectGeofence(event?.target?.value);
    geofencesRef.current.clearSelected();
  };

  const maxDate = date => {
    const dateToday = moment(new Date());
    const dateSelected = moment(date);
    const diff = dateToday.diff(dateSelected, "days");
    if (diff === 0 || diff === 1) {
      return dateToday.endOf("day").format("YYYY-MM-DD HH:mm:ss");
    }
    return dateSelected.add(13, "days").format("YYYY-MM-DD HH:mm:ss");
  };

  const handleDaySelectorChanged = e => {
    const { name, value } = e;
    let date1 = dateRange.start ? moment(dateRange.start) : moment(value);
    let date2 = moment(dateRange.end);
    if (name === "from") {
      date1 = moment(value);
      const diffDate = date1.diff(date2, "days");
      const isValid = diffDate < 1 && diffDate > -14 && date1.isBefore(date2);
      if (!isValid) {
        date2 = moment(value, "YYYY-MM-DD HH:mm:ss").add({
          days: 13
        });
        if (date2.isAfter(moment(new Date()))) {
          date2 = moment(new Date());
        }
      }
    } else {
      date2 = moment(value);
    }
    const diff = date1.diff(date2, "days");
    const isValidDateRange =
      diff < 1 && diff > -14 && moment(date2).isSameOrAfter(moment(date1));
    setDateRange(prev => ({
      ...prev,
      start: moment(date1).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(date2).format("YYYY-MM-DD HH:mm:ss"),
      isValid: isValidDateRange
    }));
  };

  return (
    <Drawer
      className={styles.drawer}
      open={filterDrawer}
      onClose={closeFilterDrawer}
    >
      <AppBar position="static">
        <Toolbar>
          <FilterListIcon style={{ color: "white", marginRight: "16px" }} />
          <Typography
            variant="h6"
            color="inherit"
            style={{ color: "white", flexGrow: 1 }}
          >
            Filter
          </Typography>
          <IconButton style={{ color: "white" }} onClick={closeFilterDrawer}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.container}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button onClick={handleClearFilter} size="small">
            <span
              style={{
                fontSize: "12px",
                textTransform: "capitalize",
                color: "black"
              }}
            >
              Clear All
            </span>
          </Button>
        </div>
        <div style={{ width: "100%", textAlign: "center", minHeight: "85%" }}>
          <div style={{ width: "85%", margin: "0 auto" }}>
            <List style={{ minHeight: "460px" }}>
              <ListItem>
                <FormControl
                  style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                  classes={{ root: formStyles.formRoot }}
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      classes={{ root: formStyles.textFieldRoot }}
                      fullWidth
                      disableFuture
                      value={dateRange.start}
                      // emptyLabel={moment(initialDate.from).format("L HH:mm")}
                      label="From"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontWeight: "bold",
                          color: "black",
                          letterSpacing: 1
                        }
                      }}
                      onChange={date => {
                        handleDaySelectorChanged({
                          name: "from",
                          value: moment(date).format("YYYY-MM-DD HH:mm:ss")
                        });
                      }}
                      InputProps={{
                        placeholder: "Select Date/Time",
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon
                              fontSize="small"
                              style={{ color: "#ccc" }}
                            />
                          </InputAdornment>
                        )
                      }}
                      format="L HH:mm"
                      ampm={false}
                      showTodayButton
                      todayLabel="NOW"
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl
                  style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                  classes={{ root: formStyles.formRoot }}
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      classes={{ root: formStyles.textFieldRoot }}
                      fullWidth
                      disableFuture
                      label="To"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontWeight: "bold",
                          color: "black",
                          letterSpacing: 1
                        }
                      }}
                      onChange={date => {
                        handleDaySelectorChanged({
                          name: "to",
                          value: moment(date).format("YYYY-MM-DD HH:mm:ss")
                        });
                      }}
                      InputProps={{
                        placeholder: "Select Date/Time",
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon
                              fontSize="small"
                              style={{ color: "#ccc" }}
                            />
                          </InputAdornment>
                        )
                      }}
                      minDate={moment(dateRange.start).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                      minDateMessage={""}
                      maxDate={moment
                        .min(
                          moment(maxDate(dateRange.start)),
                          moment(new Date())
                        )
                        .format("YYYY-MM-DD HH:mm:ss")}
                      format="L HH:mm"
                      ampm={false}
                      showTodayButton
                      value={dateRange.end}
                      todayLabel="NOW"
                    />
                  </MuiPickersUtilsProvider>
                  {moment(dateRange.start).isAfter(moment(dateRange.end)) && (
                    <FormHelperText error>
                      Date should not be before minimal date
                    </FormHelperText>
                  )}
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl
                  style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                >
                  <CategoryChipInput
                    ref={categoriesRef}
                    categories={categories}
                  />
                </FormControl>
              </ListItem>
              <ListItem
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <FormControl
                  style={{ minWidth: "auto", marginBottom: 4 }}
                >
                  <Select
                    label="Select Geofence"
                    displayEmpty
                    disableUnderline
                    value={selectGeofence}
                    renderValue={val =>
                      val === null ? "Select Geofence" : val
                    }
                    onChange={handleSelectGeoChange}
                    className={styles.selectLabel}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value="Geofence Code" className={styles.menuItem}>
                      Geofence Code
                    </MenuItem>
                    <MenuItem value="Geofence Name" className={styles.menuItem}>
                      Geofence Name
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                >
                  <SelectGeoChipInput
                    ref={geofencesRef}
                    type={selectGeofence}
                    initialValues={geoFilter.values}
                    groups={userCtx.client.group_ids}
                  />
                </FormControl>
              </ListItem>
            </List>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            right: 0,
            bottom: "0px",
            marginTop: 10
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={!dateRange.isValid}
            onClick={handleSetFilters}
            style={{
              borderRadius: 100,
              width: "85%",
              color: "white"
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Filter;
