/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import PropTypes from "prop-types";
// import clsx from "clsx";
import { 
  // lighten, 
  makeStyles 
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Typography
} from "@material-ui/core";
import {
  // Delete as DeleteIcon,
  // FilterList as FilterListIcon,
  Brightness1
} from "@material-ui/icons";
import EnhancedTableHead from "../utils/table/EnhancedTableHead";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%"
    // marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable(props) {
  const { rows, trip_number } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]); // eslint-disable-line no-unused-vars
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const headCells = [
    {
      id: "do_number",
      numeric: false,
      disablePadding: false,
      label: "SO number"
    },
    {
      id: "items",
      numeric: false,
      disablePadding: false,
      label: "Items"
    },
    {
      id: "items_qty",
      numeric: false,
      disablePadding: false,
      label: "SO Qty"
    },
    {
      id: "dr_count",
      numeric: false,
      disablePadding: false,
      label: "DR Qty"
    },
    {
      id: "arrival",
      numeric: false,
      disablePadding: false,
      label: "Planned Delivery date"
    },
    {
      id: "delivery_location",
      numeric: false,
      disablePadding: false,
      label: "Delivery location"
    },
    { id: "status", numeric: false, disablePadding: false, label: "Status" }
    // { id: "action", numeric: false, disablePadding: false, label: "" }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event, trip_number) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map(n => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCheckAll = event => {
    props.handleCheckAll(event, trip_number);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer style={{ maxHeight: "250px" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleCheckAll}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              checkbox
              selected={rows.filter(row => row.trip_number !== "")}
              rows={rows}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.so_number);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let statusColor = "";
                  switch (row.status) {
                    case "Completed":
                      statusColor = "#2dd112";
                      break;
                    case "In Transit":
                    case "Arrived at Pickup":
                    case "Arrived at Drop off":
                      statusColor = "#ffc207";
                      break;
                    default:
                      statusColor = "#c6c6c6";
                      break;
                  }
                  let item_name;
                  if (row.items.length > 1) {
                    item_name = (
                      <Typography>
                        {row.items[0].name}
                        <span style={{ color: "#3BA4F7" }}>
                          {" "}
                          (+{row.items.length - 1})
                        </span>
                      </Typography>
                    );
                  } else {
                    item_name = row.items.length ? row.items[0].name : "";
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.so_number}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={row.trip_number === props.trip_number}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={event =>
                            props.handleCheckBox(
                              event,
                              row.so_number,
                              props.trip_number
                            )
                          }
                          indeterminate={
                            row.trip_number !== props.trip_number &&
                            row.trip_number !== ""
                              ? true
                              : false
                          }
                          disabled={
                            row.trip_number !== props.trip_number &&
                            row.trip_number !== ""
                              ? true
                              : false
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.so_number}
                      </TableCell>
                      {/* <TableCell align="center">{row.do_number}</TableCell> */}
                      <TableCell align="center">
                        {/* {row.items.length ? `${row.items[0].name} (+${row.items.length - 1})` : ""} */}
                        {item_name}
                      </TableCell>
                      <TableCell align="center">{row.items_qty}</TableCell>
                      <TableCell align="center">{row.dr_count}</TableCell>
                      <TableCell align="center">{row.arrival}</TableCell>
                      <TableCell align="center">{row.destination}</TableCell>
                      <TableCell align="center">
                        <Brightness1
                          style={{ fontSize: "20px", color: statusColor }}
                        />
                        {/* {row.status === "Accepted" || row.status === "Scheduled"
                          ? "Processing"
                          : row.status} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
