import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Select,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  Grid,
  MenuItem
} from "@material-ui/core";
import { VolumeDown as VolumeDownIcon } from "@material-ui/icons";
import Cookie from "js-cookie";
import audio1 from "../../../../assets/Audio/alerts/level1.wav";
import audio2 from "../../../../assets/Audio/alerts/level2.wav";
import audio3 from "../../../../assets/Audio/alerts/level3.wav";
import audio4 from "../../../../assets/Audio/alerts/level4.wav";
import audio5 from "../../../../assets/Audio/alerts/level5.wav";
import audio6 from "../../../../assets/Audio/alerts/level6.wav";

const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 350,
    paddingBottom: 13,
    paddingRight: 13,
    color: "black"
  },
  closeTitle: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  circularButton: {
    borderRadius: "25px"
  },
  select: {
    width: "80%",
    "& .MuiSelect-root": {
      backgroundColor: "#dedede",
      borderRadius: "2px",
      padding: "4px 10px"
    }
  },
  alertContainer: {
    marginTop: 8
  },
  alertTypes: {
    minHeight: 52,
    marginTop: 5
  }
}));

const soundLevels = [
  {
    label: "Discrete",
    key: "alert_level0_sound"
  },
  {
    label: "Normal",
    key: "alert_level1_sound"
  },
  {
    label: "Moderate",
    key: "alert_level2_sound"
  },
  {
    label: "Critical",
    key: "alert_level3_sound"
  }
];

const AlertSoundModal = props => {
  const { toggle, close, handleSubmit } = props;
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));

  const getAudios = sound => {
    switch (sound) {
      case "level1.wav":
        return audio1;
      case "level2.wav":
        return audio2;
      case "level3.wav":
        return audio3;
      case "level4.wav":
        return audio4;
      case "level5.wav":
        return audio5;
      case "level6.wav":
        return audio6;
      default:
        return "";
    }
  };

  const [alertSound, setAlertSound] = useState(() => {
    const initialState = {};
    soundLevels.forEach(s => {
      const savedFilename = user.client.alert_sounds?.[s.key] || "";
      initialState[s.key] = {
        value: getAudios(savedFilename) ? savedFilename : "",
        audio: new Audio(getAudios(savedFilename)),
        isPlaying: false
      };
    });
    return initialState;
  });

  useEffect(() => {
    Object.values(alertSound).forEach((source, i) => {
      source.audio.addEventListener("ended", () => {
        const alertLevel = Object.keys(alertSound)[i];
        setAlertSound({
          ...alertSound,
          [alertLevel]: {
            ...alertSound[alertLevel],
            isPlaying: false
          }
        });
      });
    });
    return () => {
      Object.values(alertSound).forEach((source, i) => {
        source.audio.removeEventListener("ended", () => {
          const alertLevel = Object.keys(alertSound)[i];
          setAlertSound({
            ...alertSound,
            [alertLevel]: {
              ...alertSound[alertLevel],
              isPlaying: false
            }
          });
        });
      });
    };
  }, [alertSound]);

  const muteSounds = () => {
    Object.values(alertSound).forEach(source => {
      source.audio.pause();
    });
  };

  const getSoundOptions = () => {
    const selection = [<MenuItem value="0">No Sound</MenuItem>];

    for (let index = 1; index <= 6; index++) {
      selection.push(
        <MenuItem value={`level${index}.wav`}>Sound {index}</MenuItem>
      );
    }
    return selection;
  };

  return (
    <Dialog open={toggle} classes={{ paper: classes.closeContainer }}>
      <DialogTitle id="alert-dialog-title">
        <span className={classes.closeTitle}>Alert Sound</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black" }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              Level
            </Grid>
            <Grid item xs={8}>
              Sound
            </Grid>
          </Grid>

          <Divider />

          <div className={classes.alertContainer}>
            {soundLevels.map(({ label, key }) => (
              <Grid
                container
                spacing={3}
                alignItems="center"
                className={classes.alertTypes}
              >
                <Grid item xs={4}>
                  {label}
                </Grid>
                <Grid item xs={8}>
                  <Select
                    className={classes.select}
                    name={key}
                    value={alertSound[key].value || "0"}
                    onChange={e => {
                      alertSound[key].audio.pause();
                      const { name, value } = e.target;
                      setAlertSound({
                        ...alertSound,
                        [name]: {
                          value: value !== "0" ? value : "",
                          isPlaying: false,
                          audio: new Audio(
                            value !== "0" ? getAudios(value) : ""
                          )
                        }
                      });
                    }}
                  >
                    {getSoundOptions()}
                  </Select>
                  {alertSound[key].value && (
                    <IconButton
                      color={alertSound[key].isPlaying ? "primary" : "#dedede"}
                      size="small"
                      onClick={() => {
                        const { audio } = alertSound[key];
                        audio.load();
                        !alertSound[key].isPlaying
                          ? audio.play()
                          : audio.pause();
                        setAlertSound({
                          ...alertSound,
                          [key]: {
                            ...alertSound[key],
                            isPlaying: !alertSound[key].isPlaying
                          }
                        });
                      }}
                    >
                      <VolumeDownIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            muteSounds();
            close();
          }}
          variant="outlined"
          color="primary"
          className={classes.circularButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            muteSounds();
            handleSubmit({
              variables: {
                id: user.client.id,
                client: {
                  alert_sounds: {
                    alert_level0_sound: alertSound.alert_level0_sound.value,
                    alert_level1_sound: alertSound.alert_level1_sound.value,
                    alert_level2_sound: alertSound.alert_level2_sound.value,
                    alert_level3_sound: alertSound.alert_level3_sound.value
                  }
                }
              }
            });
          }}
          className={classes.circularButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertSoundModal;
