import React from "react";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import { useLazyQuery } from "@apollo/client";
import client from "../../Client";
import { CHECK_SESSION } from "../../graphql/Queries";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

export const useNavigatorOnLine = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  const [check_session] = useLazyQuery(CHECK_SESSION, {
    onCompleted: sessionInfo => {
      if (!sessionInfo.check_session) {
        Swal.fire({
          title: "Session Expired",
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            client.clearStore();
            Cookie.remove("token");
            Cookie.remove("user");
            window.location.href = "/login";
          }
        })
      }
    }
  });

  React.useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  React.useEffect(() => {
    if (status && Cookie.get("token")) {
      check_session({
        variables: {
          token: Cookie.get("token")
        }
      })
    }
  }, [status]);

  return status;
};
