import React from "react";
import DashboardDemo from "../../utils/Onboarding/modules/Dashboards";
import Dashboard from "./index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../utils/Onboarding";
import useUserContext from "../../context/User/useUserContext";
import Introductory from "../../utils/Onboarding/pages/Introductory";

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.dashboard,
    allowContextToChange: true
  });
  const dashboardProgress = progress[Onboarding.modules.dashboard];
  const isDemoCompleted =
    dashboardProgress >= onboarding_max[Onboarding.modules.dashboard];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Dashboard /> : <DashboardDemo />;
};

export default Main;
