/* global google */
import React from "react";
import GeofenceWithInfo from "../MapPartials/GeofenceWithInfo";

const Geofences = React.memo(props => {
  const { geofencesData } = props;
  const icon = {
    url: `${process.env.PUBLIC_URL}/assets/Markers/Dropoff 1.png`,
    // scale: 6,
    // scaledSize: new google.maps.Size(32, 32)
    scaledSize: new google.maps.Size(24, 24),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(12, 12)
  };
  if (geofencesData) {
    return geofencesData.map(geofence => {
      const { id } = geofence;
      return <GeofenceWithInfo key={id} data={geofence} icon={icon} />;
    });
  }

  return null;
});

export default Geofences;
