import React from "react";
import { withRouter } from "react-router-dom";
import PrebookingDemo from "../../utils/Onboarding/modules/Prebooking";
import Prebooking from "./index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../utils/Onboarding";
import Introductory from "../../utils/Onboarding/pages/Introductory";
import useUserContext from "../../context/User/useUserContext";

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.prebooking,
    allowContextToChange: true
  });
  const prebookingProgress = progress[Onboarding.modules.prebooking];
  const isDemoCompleted =
    prebookingProgress === onboarding_max[Onboarding.modules.prebooking];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Prebooking /> : <PrebookingDemo />;
};

export default withRouter(Main);
