import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  Grid,
  Button
} from "@material-ui/core";
import _ from "lodash";
import SecurityIcon from "@material-ui/icons/Security";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import {
  userPassworSchema,
  profileFields
} from "../../../data/userProfileInfo";

import useYupValidationResolver from "../../../utils/hooks/useYupValidationResolver";
import FormField from "./UserFormField";
import { CHANGE_PASSWORD_MUTATION } from "../../../graphql/Mutations";
import AddLogs from "../../../utils/functions/AddLogs";

const UserPasswordInfoPanel = props => {
  const { isOpen, setIsOpen } = props;

  const resolver = useYupValidationResolver(userPassworSchema());
  const {
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    control,
    reset,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({ mode: "onBlur", resolver });

  const [updatePasswordMutation] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted: data => {
      const { change_password } = data;
      if (change_password.success) {
        AddLogs("Profile", "change_password", "");
        reset();
        clearErrors();
        Swal.fire({
          icon: "success",
          text: "Password updated successfully",
          showConfirmButton: false,
          timer: 2500,
          onclose: setIsOpen(!isOpen)
        });
      } else {
        const { error } = change_password;
        let errorMessage = "";
        error.forEach(element => {
          errorMessage += element;
        });
        setError(
          !errorMessage.search("Incorrect") ? "old_password" : "password",
          {
            type: "manual",
            message: errorMessage
          }
        );
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something weng wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const upatePasswordHandler = () => {
    updatePasswordMutation({
      variables: {
        old_password: getValues("old_password"),
        password: getValues("password")
      }
    });
  };

  return (
    <Dialog
      aria-labelledby="Change-Password"
      aria-describedby="user-profile-change-password"
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      maxWidth="xs"
    >
      <DialogTitle>
        <StyledDialogBox>
          <SecurityIcon /> Manage Account
        </StyledDialogBox>
        <StyledDialogSubTitle>
          For enhanced account security, we advise you to update your password
          regularly, ideally every 3 months. This proactive measure helps
          safeguard your account against potential threats and ensures a higher
          level of protection.
        </StyledDialogSubTitle>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(upatePasswordHandler)}>
          <Grid container spacing={2}>
            <FormField
              fields={profileFields}
              Controller={Controller}
              control={control}
              errors={errors}
              fieldCategory="securityInfo"
            />
          </Grid>
          <StyledBoxBtn>
            <StyledCancelBtn type="button" onClick={() => setIsOpen(!isOpen)}>
              Cancel
            </StyledCancelBtn>
            <StyledUpateBtn
              type="submit"
              disabled={!_.isEmpty(errors) || !isDirty || isSubmitting}
            >
              Update Password
            </StyledUpateBtn>
          </StyledBoxBtn>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const StyledDialogSubTitle = styled("p")(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 12,
  margin: 0
}));

const StyledBoxBtn = styled("div")(() => ({
  textAlign: "right"
}));

const StyledDialogBox = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  gap: 5
}));

const StyledCancelBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  textTransform: "capitalize",
  padding: "0.5rem 1rem",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.error.dark
  }
}));

const StyledUpateBtn = styled(Button)(({ theme }) => ({
  margin: "2rem auto",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textTransform: "capitalize",
  padding: "5px 10px",
  borderRadius: 30,
  width: 150,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    opacity: "0.75"
  }
}));

export default UserPasswordInfoPanel;
