import React from "react";
import {
  ListItem,
  Tooltip,
  Switch,
  // Icon,
  Box,
  Typography
} from "@material-ui/core";
import { useStyles } from "./SwitchHelperStyle";

/*
    Custom Switch with the helper icon and switch button.
*/

const SwitchHelper = props => {
  const classes = useStyles();
  const {
    handleChangeHandler,
    checkedValue,
    label,
    name,
    tooltipPlacement,
    tooltipTitle,
    icon,
    disabled,
    customStyle = {}
  } = props;

  return (
    <ListItem
      className={classes.containerBox}
      style={customStyle}
    >
      <Box className={classes.containerLabel}>
        <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
          {icon}
        </Tooltip>
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      <Switch
        name={name}
        size="small"
        color="primary"
        checked={checkedValue}
        onChange={handleChangeHandler}
        disabled={disabled}
      />
    </ListItem>
  );
};

export default SwitchHelper;
