import React, { useState, memo } from "react";
import { styled, TextField, Button, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import { ADD_FEEDBACK } from "../../../../graphql/Mutations";
import AddLogs from "../../../../utils/functions/AddLogs";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";

const UserFeedback = () => {
  const [message, setMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const user = JSON.parse(Cookies.get("user"));

  const [addFeedback] = useMutation(ADD_FEEDBACK, {
    onCompleted(res) {
      const { success } = res.add_feedback;
      if (success) {
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            setMessage("");
          }
        });
        AddLogs("Help and Feedback", "feedback");
      } else {
        Swal.fire({
          title: "Something went wrong. Try again.",
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
  });

  const handleSubmit = event => {
    event.preventDefault();
    addFeedback({
      variables: {
        feedback: {
          user_id: user.id,
          message: message
        }
      }
    });
  };

  return (
    <>
      <StyledTitleTypography variant="h5" noWrap>
        How was your experience?
      </StyledTitleTypography>
      <StyledBox>
        <TextField
          placeholder="Type your review and suggestions here."
          multiline
          rows="8"
          value={message}
          onChange={e => setMessage(e.target.value)}
          variant="outlined"
          fullWidth
          helperText={`${message.length}/500`}
          FormHelperTextProps={{ style: { textAlign: "right" } }}
          inputProps={{ maxLength: 500 }}
        />
        <StyleSubmitBtn
          variant="contained"
          color="primary"
          onClick={() => setShowDialog(true)}
          disabled={!message.length}
        >
          Submit
        </StyleSubmitBtn>
      </StyledBox>

      {showDialog && (
        <ConfirmationDialog
          toggle={showDialog}
          close={() => setShowDialog(false)}
          fn={e => handleSubmit(e)}
          title="Submit?"
          content="Are you sure you want to submit your feedback?"
        />
      )}
    </>
  );
};

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "700",
  fontSize: 36,
  fontFamily: "Nunito",
  lineHeight: "49px",
  marginTop: 30,
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    fontSize: 25
  }
}));

const StyleSubmitBtn = styled(Button)(({ theme }) => ({
  borderRadius: "100px",
  fontSize: 13,
  color: "white",
  margin: "1rem auto",
  width: "200px",
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: "2rem auto 0 auto",
  borderRadius: "10px 10px 0 0",
  height: "90vh",
  padding: "2rem",
  width: "80%",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    height: "calc(80vh - 22px)"
  }
}));

export default memo(UserFeedback);
