// import { gql } from "apollo-boost";
import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      token_V2
      user {
        id
        username
        first_name
        last_name
        profile_image
        group_ids
        group_names
        account_expiration
        is_auto_logout
        client {
          id
          name
          is_restricted
          alert_sounds {
            alert_level0_sound
            alert_level1_sound
            alert_level2_sound
            alert_level3_sound
          }
          group_ids
          group_names
        }
        user_level_id
      }
      error {
        field
        message
      }
    }
  }
`;
/*
split - Trip Number, Items, Dropoffs, 
dispatch - status_code, status_code_id, pickups.manual_departure
change vehicle - vehicle_id, vehicle_type, vehicle_plate_no, vehicle_type_id */
export const UPDATE_BOOKING = gql`
  mutation update_booking(
    $id: ID!
    $ref_number: String
    $trip_number: String
    $so_number: String
    $pickups: [DestinationInput!]
    $dropoffs: [DestinationInput!]
    $status_code_id: ID
    $status_code: String
    $client_name: String
    $client_id: ID
    $group_names: [String!]
    $group_ids: [Int!]
    $is_urgent: Boolean
    $ordinal: Int
    $vehicle_plate_no: String
    $personnels: [BookingPersonnelInput]
    $vehicle_id: ID
    $vehicle_type: String
    $data_complete: Boolean
    $vehicle_history: [VehicleHistoryInput]
    $dispatch_type: String
    $service_type_id: Int
    $service_type: String
    $pre_dispatched_ready: Boolean
    $items: [BookingItemInput!]
    $dispatch_time: ISODateV2
    $for_reprocess: Boolean
    $dr_number: String
  ) {
    update_booking(
      id: $id
      is_urgent: $is_urgent
      trip_number: $trip_number
      so_number: $so_number
      ref_number: $ref_number
      client_id: $client_id
      client_name: $client_name
      group_ids: $group_ids
      group_names: $group_names
      status_code: $status_code
      status_code_id: $status_code_id
      vehicle_id: $vehicle_id
      vehicle_type: $vehicle_type
      vehicle_plate_no: $vehicle_plate_no
      personnels: $personnels
      ordinal: $ordinal
      pickups: $pickups
      dropoffs: $dropoffs
      data_complete: $data_complete
      vehicle_history: $vehicle_history
      dispatch_type: $dispatch_type
      service_type_id: $service_type_id
      service_type: $service_type
      items: $items
      pre_dispatched_ready: $pre_dispatched_ready
      dispatch_time: $dispatch_time
      for_reprocess: $for_reprocess
      dr_number: $dr_number
    ) {
      id
    }
  }
`;

export const DISPATCH_MUTATION = gql`
  mutation update(
    $id: ID!
    $status_code: String
    $status_code_id: ID
    # $manual_departure: ISODate
    $pickups: [DestinationInput!]
  ) {
    update_booking(
      id: $id
      status_code: $status_code
      status_code_id: $status_code_id
      pickups: $pickups
    ) {
      id
      trip_number
      vehicle_plate_no
      vehicle_type
      status_code
      status_code_id
      pickups {
        manual_departure
      }
    }
  }
`;

export const CHANGE_VEHICLE_MUTATION = gql`
  mutation update(
    $fields: [SearchInput!]!
    $conditions: [DynamicSearchInput!]
  ) {
    update_booking_all(fields: $fields, conditions: $conditions) {
      id
      trip_number
      vehicle_plate_no
      vehicle_type
    }
  }
`;

export const UPDATE_INTERISLAND_BOOKING = gql`
  mutation update_interisland(
    $fields: [SearchInput!]!
    $conditions: [DynamicSearchInput!]
  ) {
    update_booking_all(fields: $fields, conditions: $conditions) {
      trip_number
      is_inter_island
    }
  }
`;
/* export const UPDATE_SPLIT_BOOKING_MUTATION = gql`
  mutation update(
    $id: ID!
    $trip_number: String
    $dropoffs: [DestinationInput!]!
    $items: [BookingItemInput!]
    $vehicle_plate_no: String
    $vehicle_id: ID
    $vehicle_type: String
    $vehicle_type_id: ID
  ) {
    update_booking(
      id: $id
      trip_number: $trip_number
      dropoffs: $dropoffs
      items: $items
      vehicle_type: $vehicle_type
      vehicle_type_id: $vehicle_type_id
      vehicle_id: $vehicle_id
      vehicle_plate_no: $vehicle_plate_no
    ) {
      id
      trip_number
      vehicle_plate_no
      vehicle_type
      dropoffs {
        geofence_id
        name
        address
        arrival
      }
      items {
        name
        qty
        dr_count
      }
    }
  }
`; */

export const UPDATE_SPLIT_BOOKING_MUTATION = gql`
  mutation update(
    $id: ID!
    $trip_number: String
    $vehicle_plate_no: String
    $vehicle_id: ID
    $vehicle_type: String
    $vehicle_type_id: ID
    $is_split: Boolean
    $status_code_id: ID
    $status_code: String
    $for_reprocess: Boolean
  ) {
    update_booking(
      id: $id
      trip_number: $trip_number
      vehicle_type: $vehicle_type
      vehicle_type_id: $vehicle_type_id
      vehicle_id: $vehicle_id
      vehicle_plate_no: $vehicle_plate_no
      is_split: $is_split
      status_code_id: $status_code_id
      status_code: $status_code
      for_reprocess: $for_reprocess
    ) {
      id
      trip_number
      vehicle_plate_no
      vehicle_type
      dropoffs {
        geofence_id
        name
        address
        arrival
      }
      items {
        name
        qty
        dr_count
      }
      status_code_id
      status_code
    }
  }
`;

export const ADD_BOOKING_MUTATION = gql`
  mutation add(
    $do_number: String
    $ref_number: String!
    $trip_number: String
    $transport_mode_id: Int
    $transport_mode: String
    $service_type_id: Int
    $service_type: String
    $total_weight: Float
    $total_volume: Float
    $pickups: [DestinationInput!]!
    $dropoffs: [DestinationInput!]!
    $bill_to_id: Int
    $bill_to_name: String
    $items: [BookingItemInput!]
    $status_code_id: ID
    $status_code: String
    $client_name: String
    $client_id: ID
    $group_names: [String!]!
    $group_ids: [Int!]!
    $is_urgent: Boolean!
    $reason: String
    $reason_id: ID
    $vehicle_plate_no: String
    $vehicle_id: ID
    $vehicle_type: String
    $vehicle_type_id: ID
    $personnels: [BookingPersonnelInput]
    $notification_status: Int
    $dr_number: String
    $remarks: String
    $so_number: String
    $vehicle_history: [VehicleHistoryInput]
    $ordinal: Int
    $dispatch_type: String
    $data_complete: Boolean
    $pre_dispatched_ready: Boolean
    $dispatch_time: ISODateV2
  ) {
    add_booking(
      do_number: $do_number
      ref_number: $ref_number
      trip_number: $trip_number
      transport_mode_id: $transport_mode_id
      transport_mode: $transport_mode
      service_type_id: $service_type_id
      service_type: $service_type
      total_weight: $total_weight
      total_volume: $total_volume
      pickups: $pickups
      dropoffs: $dropoffs
      bill_to_id: $bill_to_id
      bill_to_name: $bill_to_name
      items: $items
      status_code_id: $status_code_id
      status_code: $status_code
      client_name: $client_name
      client_id: $client_id
      group_names: $group_names
      group_ids: $group_ids
      is_urgent: $is_urgent
      reason: $reason
      reason_id: $reason_id
      vehicle_plate_no: $vehicle_plate_no
      vehicle_id: $vehicle_id
      vehicle_type: $vehicle_type
      vehicle_type_id: $vehicle_type_id
      personnels: $personnels
      notification_status: $notification_status
      dr_number: $dr_number
      remarks: $remarks
      so_number: $so_number
      vehicle_history: $vehicle_history
      ordinal: $ordinal
      dispatch_type: $dispatch_type
      data_complete: $data_complete
      pre_dispatched_ready: $pre_dispatched_ready
      dispatch_time: $dispatch_time
    )
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation change_password($old_password: String!, $password: String!) {
    change_password(old_password: $old_password, password: $password) {
      success
      error
    }
  }
`;
export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgot_password(email_address: $email) {
      success
      error
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    reset_password(token: $token, password: $password) {
      success
      error
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation update_user(
    $id: ID!
    $first_name: String!
    $last_name: String!
    $email_address: String!
    $contact_number: String!
    $profile_image: Upload
  ) {
    update_user(
      id: $id
      first_name: $first_name
      last_name: $last_name
      email_address: $email_address
      contact_number: $contact_number
      profile_image: $profile_image
    ) {
      success
      user {
        username
        first_name
        last_name
        email_address
        contact_number
        profile_image
        id
        group_ids
        group_names
        client {
          id
          name
        }
      }
      error {
        field
        message
      }
    }
  }
`;

export const ADD_REPORT_REQUEST_MUTATION = gql`
  mutation addReportRequests(
    $user_id: ID!
    $report_type_id: ID!
    $startstamp: ISODate!
    $endstamp: ISODate!
    $group_ids: String!
    $trip_status_id: [Int]
    $device_id: [ID]
    $alert_description_ids: [ID]
    $activity_only: Int
    $vehicle_status: [Int]
    $gps_status: [Int]
    $reporting_delay: String
    $duration: Int
    $headers: [[String]]
    $geofence_ids: [ID]
    $battery_level: String
    $use_logistics_status: Boolean
  ) {
    add_report_request(
      user_id: $user_id
      report_type_id: $report_type_id
      startstamp: $startstamp
      endstamp: $endstamp
      group_ids: $group_ids
      trip_status_id: $trip_status_id
      device_id: $device_id
      alert_description_ids: $alert_description_ids
      activity_only: $activity_only
      vehicle_status: $vehicle_status
      gps_status: $gps_status
      reporting_delay: $reporting_delay
      duration: $duration
      headers: $headers
      geofence_ids: $geofence_ids
      battery_level: $battery_level
      use_logistics_status: $use_logistics_status
    )
  }
`;

export const UPDATE_REPORT_REQUEST_MUTATION = gql`
  mutation updateReportRequest($id: ID!, $status_code_id: Int) {
    update_report_request(id: $id, status_code_id: $status_code_id) {
      success
      error
    }
  }
`;

export const DELETE_REPORT_REQUEST_MUTATION = gql`
  mutation deleteReportRequest($id: [ID]!) {
    delete_report_request(id: $id) {
      success
      error
    }
  }
`;

export const READ_NOTIFICATION_MUTATION = gql`
  mutation updateNotif($id: ID!, $notification: NotificationInput!) {
    update_notification(id: $id, notification: $notification) {
      success
    }
  }
`;

export const CLEAR_ALL_NOTIFICATION_MUTATION = gql`
  mutation updateAll(
    $conditions: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
  ) {
    update_all_notifications(
      fields: { is_archived: true }
      conditions: $conditions
      filter: $filter
    ) {
      notification
      client_id
      is_archived
    }
  }
`;

export const MANUAL_COMPLETE = gql`
  mutation manualComplete($bookings: [BookingConditionInput]) {
    update_booking_many(bookings: $bookings) {
      id
      success
      error
    }
  }
`;
export const MANUAL_COMPLETE_WITH_UPLOAD = gql`
  mutation manualComplete(
    $bookings: [BookingConditionInput]
    $file: [Upload!]!
    $trip_number: String!
  ) {
    update_booking_many(bookings: $bookings) {
      id
      success
      error
    }
    upload_trip_attachments(file: $file, trip_number: $trip_number) {
      success
      error {
        rn
        error
      }
      total
    }
  }
`;

export const COMPLETE_BOOKING = gql`
  mutation completeBooking($id: ID!, $dropoffs: [DestinationInput!]) {
    update_booking(
      id: $id
      dropoffs: $dropoffs
      status_code: "Completed"
      status_code_id: "4"
    ) {
      id
      status_code
      status_code_id
      dropoffs {
        name
        address
        arrival
        actual_arrival
        manual_arrival
        departure
        actual_departure
        manual_departure
        consignee
        contact_no
        location_details
        so_number
        issue
        eta
        actual_eta
        eta_modified
        status_code
        status_code_id
        geofence_code
      }
    }
  }
`;

// start of admin

export const ADD_USERS = gql`
  mutation AddUsers($users: [AddUserInput]) {
    add_users(users: $users) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUserMutation(
    $username: String!
    $first_name: String!
    $last_name: String!
    $email_address: String!
    $contact_number: String!
    $user_level_id: ID
    $password_expiry: Int
    $password_expiration: ISODate
    $account_expiration: ISODate!
    $groups: [GroupInput!]!
    $remarks: String
    $is_auto_logout: Boolean
    $geofence_id: ID
  ) {
    add_user(
      username: $username
      first_name: $first_name
      last_name: $last_name
      email_address: $email_address
      contact_number: $contact_number
      user_level_id: $user_level_id
      password_expiry: $password_expiry
      password_expiration: $password_expiration
      account_expiration: $account_expiration
      groups: $groups
      remarks: $remarks
      is_auto_logout: $is_auto_logout
      geofence_id: $geofence_id
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUserMutation(
    $id: ID!
    $username: String
    $first_name: String
    $last_name: String
    $email_address: String
    $contact_number: String
    $user_level_id: ID
    $password_expiry: Int
    $password_expiration: ISODate
    $remarks: String
    $account_expiration: ISODate
    $is_auto_logout: Boolean
    $geofence_id: ID
    $onboarding: InputOnboarding
  ) {
    edit_user(
      id: $id
      username: $username
      first_name: $first_name
      last_name: $last_name
      email_address: $email_address
      contact_number: $contact_number
      user_level_id: $user_level_id
      password_expiry: $password_expiry
      password_expiration: $password_expiration
      remarks: $remarks
      account_expiration: $account_expiration
      is_auto_logout: $is_auto_logout
      geofence_id: $geofence_id
      onboarding: $onboarding
    ) {
      success
      error {
        field
        message
      }
      user {
        id
        username
        first_name
        last_name
        email_address
        contact_number
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    delete_user(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_USER_ALL = gql`
  mutation DeleteUserAll($condition: [DynamicSearchInput]) {
    delete_user_all(condition: $condition) {
      success
      error
    }
  }
`;

export const ADD_USER_LEVEL = gql`
  mutation AddUserLevel(
    $user_level: UserLevelInput!
    $permissions: [PermissionInput]
  ) {
    add_user_level(user_level: $user_level, permissions: $permissions) {
      success
      error
    }
  }
`;
export const EDIT_USER_LEVEL = gql`
  mutation EditUserLevel(
    $id: ID!
    $user_level: UserLevelInput!
    $permissions: [PermissionInput]
  ) {
    edit_user_level(
      id: $id
      user_level: $user_level
      permissions: $permissions
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_USER_LEVEL = gql`
  mutation DeleteUserLevel($id: ID!) {
    delete_user_level(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_USER_LEVEL_ALL = gql`
  mutation DeleteUserLevelAll($condition: [DynamicSearchInput]) {
    delete_user_level_all(condition: $condition) {
      success
      error
      deleted
      total
    }
  }
`;
// $wkb_geometry: PolygonGeometryInput
// wkb_geometry: $wkb_geometry
export const ADD_GEOFENCE = gql`
  mutation AddGeofence(
    $name: String!
    $address: String!
    $type: String
    $location: PointGeometryInput!
    $group_ids: [Int!]!
    #$group_names: [String!]!
    #$wkb_geometry: PolygonGeometryInput
    $geom: String!
    $category: String!
    $barangay: String
    $window_time_start: ISODate
    $window_time_end: ISODate
    $municipality: String
    $province: String
    $region: String
    $geofence_code: String
    $radius: Float
    $opacity: Float
    $color: String
    $geofence_geom_id: Int
    $speed_limit: Int
  ) {
    add_geofence(
      name: $name
      address: $address
      type: $type
      location: $location
      group_ids: $group_ids
      window_time_start: $window_time_start
      window_time_end: $window_time_end
      #group_names: $group_names
      geom: $geom
      category: $category
      barangay: $barangay
      municipality: $municipality
      province: $province
      region: $region
      geofence_code: $geofence_code
      radius: $radius
      opacity: $opacity
      color: $color
      geofence_geom_id: $geofence_geom_id
      speed_limit: $speed_limit
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_ROUTEGEOFENCE = gql`
  mutation AddGeofence(
    $name: String!
    $address: String!
    $location: PointGeometryInput!
    $group_ids: [Int!]!
    $geom: String!
    $line_geom: String
    $line_buffer: Int
    $category: String!
    $geofence_code: String
    $speed_limit: Int
  ) {
    add_geofence(
      name: $name
      address: $address
      location: $location
      group_ids: $group_ids
      geom: $geom
      line_geom: $line_geom
      line_buffer: $line_buffer
      category: $category
      geofence_code: $geofence_code
      speed_limit: $speed_limit
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_GEOFENCE = gql`
  mutation EditGeofence(
    $id: ID!
    $name: String!
    $address: String!
    $type: String
    $location: PointGeometryInput!
    $group_ids: [Int!]
    #$group_names: [String!]!
    $geom: String
    $window_time_start: ISODate
    $window_time_end: ISODate
    $category: String!
    $barangay: String
    $municipality: String
    $province: String
    $region: String
    $geofence_code: String
    $radius: Float
    $opacity: Float
    $color: String
    $geofence_geom_id: Int
    $speed_limit: Int
  ) {
    update_geofence(
      id: $id
      name: $name
      address: $address
      type: $type
      location: $location
      group_ids: $group_ids
      #group_names: $group_names
      window_time_start: $window_time_start
      window_time_end: $window_time_end
      geom: $geom
      category: $category
      barangay: $barangay
      municipality: $municipality
      province: $province
      region: $region
      geofence_code: $geofence_code
      radius: $radius
      opacity: $opacity
      color: $color
      geofence_geom_id: $geofence_geom_id
      speed_limit: $speed_limit
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_ROUTEGEOFENCE = gql`
  mutation EditGeofence(
    $id: ID!
    $name: String!
    $address: String
    $location: PointGeometryInput
    $group_ids: [Int!]!
    $geom: String
    $line_geom: String
    $line_buffer: Int
    $category: String
    $geofence_code: String
    $speed_limit: Int
  ) {
    update_geofence(
      id: $id
      name: $name
      address: $address
      location: $location
      group_ids: $group_ids
      geom: $geom
      line_geom: $line_geom
      line_buffer: $line_buffer
      category: $category
      geofence_code: $geofence_code
      speed_limit: $speed_limit
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_GEOFENCE = gql`
  mutation DeleteGeofence($id: ID!) {
    delete_geofence(id: $id) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_GEOFENCE_ALL = gql`
  mutation DeleteGeofenceAll($condition: [DynamicSearchInput]) {
    delete_geofence_all(condition: $condition) {
      success
      error
      deleted
      total
    }
  }
`;

export const DELETE_DEVICE_ALL = gql`
  mutation deleteDeviceAll($condition: [DynamicSearchInput]) {
    delete_device_all(condition: $condition) {
      success
      error
    }
  }
`;

export const EDIT_ALERT_REFERENCE = gql`
  mutation EditAlert($id: ID!, $alert_reference: AlertReferenceInput!) {
    edit_alert_reference(id: $id, alert_reference: $alert_reference) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_GEOFENCES = gql`
  mutation AddGeofences($geofences: [AddGeofenceInput]) {
    add_geofences(geofences: $geofences) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_DEVICES = gql`
  mutation AddDevices($devices: [AddDeviceInput]) {
    add_devices(devices: $devices) {
      success
      error {
        field
        message
        id
        override
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPass($token: String!, $password: String!) {
    reset_password(token: $token, password: $password) {
      success
      error
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPass($email_address: String!) {
    forgot_password(email_address: $email_address) {
      success
      error
    }
  }
`;

export const GET_SHAREABLE_LINK = gql`
  mutation getShareLink(
    $tripNumber: String!
    $dateRange: DateRangeInputV2!
    $group_ids: [Int!]!
    $client_name: String!
  ) {
    get_shareable_link(
      tripNumber: $tripNumber
      dateRange: $dateRange
      group_ids: $group_ids
      client_name: $client_name
    )
  }
`;

export const ADD_DEVICE = gql`
  mutation addDevice(
    $name: String!
    $remarks: String
    $devicealias: String!
    $mobile_imei: String
    $device_type_id: Int
    $client_id: Int!
    $vehicle_info: VehicleInfoInput
    $group_ids: [Int!]!
    $device_status: DeviceStatusInput
    $device_config: AddDeviceConfigInput
    $mirror_to_ngt: Boolean
    $contract_ref_no: String
    $reserve_field: String
  ) {
    add_device(
      name: $name
      remarks: $remarks
      devicealias: $devicealias
      mobile_imei: $mobile_imei
      device_type_id: $device_type_id
      client_id: $client_id
      vehicle_info: $vehicle_info
      group_ids: $group_ids
      device_status: $device_status
      device_config: $device_config
      mirror_to_ngt: $mirror_to_ngt
      contract_ref_no: $contract_ref_no
      reserve_field: $reserve_field
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation deleteVehicle($id: ID!) {
    delete_vehicle(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($id: ID!) {
    delete_device(id: $id) {
      success
      error
    }
  }
`;

export const EDIT_DEVICE = gql`
  mutation editDevice($id: ID!, $device: EditDeviceInput) {
    edit_device(id: $id, device: $device) {
      success
      error {
        field
        message
      }
      device {
        vehicle_info {
          id
          plateno
        }
        group_ids
        group_names
        client_id
      }
    }
  }
`;

export const EDIT_DEVICES = gql`
  mutation editDevices($devices: [EditDevicesInput]) {
    edit_devices(devices: $devices) {
      success
      error {
        message
        field
        id
        override
      }
    }
  }
`;

export const DELETE_VEHICLE_MANY = gql`
  mutation deleteVehicleMany($condition: [DynamicSearchInput]) {
    delete_vehicle_all(condition: $condition) {
      success
      error
    }
  }
`;

export const EDIT_VEHICLE = gql`
  mutation editVehicle($id: ID!, $vehicle: EditVehicleInput) {
    edit_vehicle(id: $id, vehicle: $vehicle) {
      vehicle {
        id
        plateno
        remarks
        device_id
        status_code
        status_code_id
        vehicle_type_id
        group_ids
        group_names
        vehicle_type
        icon
        avefuelconsumption
        under_repair
        auto_unpair
        make
        area
        heads
        region
        assignment
        device_info {
          devicealias
          device_status {
            status_code
            status_code_id
          }
          device_config {
            intervalreporting
            battery_intervalreporting
            aux_voltage
            gps_provider
          }
        }
        model
        manufacture_year
        expiration
        client_id
        client_name
        bodyno
        paired
        personnel_id
        last_360_check
        is_for_repair
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_DRIVER_VEHICLE = gql`
  mutation editVehicle($id: ID!, $vehicle: EditVehicleInput) {
    edit_vehicle(id: $id, vehicle: $vehicle) {
      vehicle {
        paired
        personnel_id
        paired
        maintenance_km
        maintenance_months
        maintenance_remarks
        last_maintenance_date
        last_maintenance_km
        last_360_check
        is_for_repair
        is_for_maintenance
        gps_code
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_VEHICLES = gql`
  mutation editVehicles($vehicles: [EditVehiclesInput]) {
    edit_vehicles(vehicles: $vehicles) {
      vehicle {
        id
        plateno
        remarks
        device_id
        status_code
        status_code_id
        vehicle_type_id
        group_ids
        group_names
        vehicle_type
        icon
        avefuelconsumption
        under_repair
        device_info {
          devicealias
          device_status {
            status_code
            status_code_id
          }
          device_config {
            intervalreporting
            battery_intervalreporting
            aux_voltage
            gps_provider
          }
        }
        model
        manufacture_year
        expiration
        client_id
        client_name
        bodyno
        paired
        maintenance_km
        maintenance_months
        maintenance_remarks
        last_maintenance_date
        last_maintenance_km
        last_360_check
        is_for_repair
        is_for_maintenance
      }
      success
      error {
        field
        message
        id
        override
      }
    }
  }
`;

export const ADD_VEHICLE = gql`
  mutation AddVehicle(
    $plateno: String!
    $icon: String!
    $client_id: ID!
    $group_ids: [Int]
    $expiration: DateStamp
    $bodyno: String
    $model: String
    $avefuelconsumption: Float
    $remarks: String
    $manufacture_year: String
    $vehicle_type_id: ID!
    $under_repair: Boolean
    $not_in_use: Boolean
    $has_fuel_sensor: Boolean
    $has_temp_sensor: Boolean
    $device_id: ID
    $auto_unpair: ID
    $make: String
    $region: String
    $assignment: String
    $area: String
    $heads: String
    $has_vss_camera: Int
    $channel_count: Int
  ) {
    add_vehicle(
      plateno: $plateno
      icon: $icon
      client_id: $client_id
      group_ids: $group_ids
      expiration: $expiration
      bodyno: $bodyno
      model: $model
      avefuelconsumption: $avefuelconsumption
      remarks: $remarks
      manufacture_year: $manufacture_year
      vehicle_type_id: $vehicle_type_id
      under_repair: $under_repair
      not_in_use: $not_in_use
      has_fuel_sensor: $has_fuel_sensor
      has_temp_sensor: $has_temp_sensor
      device_id: $device_id
      auto_unpair: $auto_unpair
      make: $make
      region: $region
      assignment: $assignment
      area: $area
      heads: $heads
      has_vss_camera: $has_vss_camera
      channel_count: $channel_count
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_ADMIN_CLIENT = gql`
  mutation addAdminClient(
    $name: String!
    $client_type: String
    $category: String!
    $user_count: Int!
    $device_count: Int!
    $geofence_count: Int!
    $access_end_date: ISODate!
    $data_admin: String!
    $remarks: String
    $acl_module_id: Int!
    $acl_modules: [ClientPermissionInput]!
    $client_preference: AddClientPreferenceInput
    $client_codes: [ClientCodeInput]
    $sms_sending_type: String
    $eta_enabled: Int
    $is_restricted: Boolean
  ) {
    add_client(
      name: $name
      client_type: $client_type
      category: $category
      user_count: $user_count
      device_count: $device_count
      geofence_count: $geofence_count
      access_end_date: $access_end_date
      data_admin: $data_admin
      remarks: $remarks
      acl_module_id: $acl_module_id
      acl_modules: $acl_modules
      client_preference: $client_preference
      client_codes: $client_codes
      sms_sending_type: $sms_sending_type
      eta_enabled: $eta_enabled
      is_restricted: $is_restricted
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;
export const DELETE_ADMIN_CLIENTS = gql`
  mutation deleteAdminClients($ids: [ID]!) {
    delete_client(id: $ids) {
      success
      error
    }
  }
`;

export const EDIT_ADMIN_CLIENT = gql`
  mutation editAdminCLient($id: ID!, $client: EditClientInput) {
    edit_client(id: $id, client: $client) {
      success
      error {
        field
        message
      }
      client {
        compute_stoppage
        alert_sounds {
          alert_level0_sound
          alert_level1_sound
          alert_level2_sound
          alert_level3_sound
        }
      }
    }
  }
`;
export const ADMIN_UNLINK = gql`
  mutation adminUnlink($model: String!, $group: GroupInput!, $target: [ID!]!) {
    remove_link(model: $model, group: $group, target: $target) {
      success
      error
    }
  }
`;

export const UPLOAD_VEHICLES = gql`
  mutation uploadVehicles($vehicles: [AddVehicleInput]) {
    add_vehicles(vehicles: $vehicles) {
      id
      success
      error {
        field
        message
        override
        id
      }
    }
  }
`;
export const ADMIN_LINK = gql`
  mutation adminLink($model: String!, $group: GroupInput!, $target: [ID!]!) {
    add_link(model: $model, group: $group, target: $target) {
      success
      error
    }
  }
`;

export const ADD_DASHBOARD_FILTER = gql`
  mutation addFilter(
    $name: String!
    $service_type_id: Int
    $client_id: Int
    $pickup_ids: [ID]
    $dropoff_ids: [ID]
    $port_cy_ids: [ID]
    $account: String
    $user_id: ID!
    $dashboard_type: String
    $vehicle_type_id: Int
    $location_ids: [ID]
    $is_interisland: Int
  ) {
    add_dashboard_filter(
      name: $name
      service_type_id: $service_type_id
      client_id: $client_id
      pickup_ids: $pickup_ids
      dropoff_ids: $dropoff_ids
      port_cy_ids: $port_cy_ids
      user_id: $user_id
      dashboard_type: $dashboard_type
      account: $account
      vehicle_type_id: $vehicle_type_id
      location_ids: $location_ids
      is_interisland: $is_interisland
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_DASHBOARD_FILTER = gql`
  mutation deleteFilter($id: ID!) {
    delete_dashboard_filter(id: [$id]) {
      success
      error
    }
  }
`;

export const UNBLOCK_USER = gql`
  mutation unblockUser($id: ID!) {
    unblock_user(id: $id) {
      success
      error
    }
  }
`;

export const UNBLOCK_USER_ALL = gql`
  mutation UnblockUserAll($ids: [ID!]) {
    unblock_users(ids: $ids) {
      id
      response {
        success
        error
      }
    }
  }
`;

export const SIGNUP_CLIENT_USER = gql`
  mutation signup_client_user(
    $username: String!
    $password: String!
    $first_name: String!
    $last_name: String!
    $email_address: String!
    $contact_number: String!
  ) {
    signup_client_user(
      username: $username
      password: $password
      first_name: $first_name
      last_name: $last_name
      email_address: $email_address
      contact_number: $contact_number
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation addNotif($notification: NotificationInput) {
    add_notification(notification: $notification)
  }
`;

export const REFRESH_TRIPS = gql`
  mutation refreshTrips($trip_numbers: [String!]!) {
    refresh_trips(trip_numbers: $trip_numbers) {
      success
      error
    }
  }
`;

export const ADD_ALERT_DESCRIPTION = gql`
  mutation addAlertDescription(
    $description: String
    $code: String!
    $parent_id: Int
  ) {
    add_alert_description(
      description: $description
      code: $code
      parent_id: $parent_id
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const UPLOAD_ALERT_DESCRIPTIONS = gql`
  mutation addAlertDescriptions(
    $alert_descriptions: [AddAlertDescriptionInput]
  ) {
    add_alert_descriptions(alert_descriptions: $alert_descriptions) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_ALERT_DESCRIPTION = gql`
  mutation editAlertDescription(
    $id: ID!
    $alert_description: AlertDescriptionInput!
  ) {
    edit_alert_description(id: $id, alert_description: $alert_description) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_ALERT_DESCRIPTION = gql`
  mutation deleteAlertDescription($id: ID!) {
    delete_alert_description(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_ALERT_DESCRIPTION_MULTIPLE = gql`
  mutation deleteAlertDescriptionMultiple($condition: [DynamicSearchInput]) {
    delete_alert_description_all(condition: $condition) {
      success
      error
      deleted
      total
    }
  }
`;

export const DELETE_ALERT_REFERENCE = gql`
  mutation deleteAlertReference($id: ID!) {
    delete_alert_reference(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_ALERT_REFERENCE_MULTIPLE = gql`
  mutation deleteAlertReferenceAll($condition: [DynamicSearchInput]) {
    delete_alert_reference_all(condition: $condition) {
      success
      error
    }
  }
`;

export const ADD_ALERT_REFERENCE = gql`
  mutation addAlertReference($alert_reference: AlertReferenceInput!) {
    add_alert_reference(alert_reference: $alert_reference) {
      success
      error {
        field
        message
      }
      id
    }
  }
`;

export const UPLOAD_ALERT_REFERENCES = gql`
  mutation uploadAlertReference(
    $alert_references: [AlertReferenceUploadInput]
  ) {
    add_alert_references(alert_references: $alert_references) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_GPS_PROVIDERS = gql`
  mutation addGPSProviders(
    $name: String!
    $remarks: String!
    $api_key: String
    $registered_IPS: String
    $group_ids: [Int]
  ) {
    add_gps_provider(
      name: $name
      remarks: $remarks
      api_key: $api_key
      registered_IPS: $registered_IPS
      group_ids: $group_ids
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_GPS_PROVIDER = gql`
  mutation editGPSProvider($id: ID!, $gps_provider: EditGpsProviderInput) {
    edit_gps_provider(id: $id, gps_provider: $gps_provider) {
      gps_provider {
        id
        name
        remarks
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_GPS_PROVIDER = gql`
  mutation deleteGPSProvider($id: ID!) {
    delete_gps_provider(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_MULTIPLE_GPS_PROVIDER = gql`
  mutation deleteMultipleGPSProvider($condition: [DynamicSearchInput]) {
    delete_gps_provider_all(condition: $condition) {
      success
      error
      deleted
      total
    }
  }
`;

export const UPLOAD_GPS_PROVIDERS = gql`
  mutation uploadGPSProviders($gps_providers: [AddGpsProviderInput]) {
    add_gps_providers(gps_providers: $gps_providers) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_VEHICLE_TYPE = gql`
  mutation addVehicleType(
    $name: String!
    $capacity_weight: Float
    $capacity_weight_unit: String
    $capacity_volume: Float
    $capacity_volume_unit: String
    $remarks: String
    $groups: [GroupInput!]!
    $number_of_wheels: Int
    $client_id: Int
  ) {
    add_vehicle_type(
      name: $name
      capacity_weight: $capacity_weight
      capacity_weight_unit: $capacity_weight_unit
      capacity_volume: $capacity_volume
      capacity_volume_unit: $capacity_volume_unit
      remarks: $remarks
      groups: $groups
      number_of_wheels: $number_of_wheels
      client_id: $client_id
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;
export const UPLOAD_VEHICLE_TYPES = gql`
  mutation uploadVehicleTypes($vehicle_types: [AddVehicleTypeInput]) {
    add_vehicle_types(vehicle_types: $vehicle_types) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_VEHICLE_TYPES = gql`
  mutation editVehicleType($id: ID!, $vehicle_type: EditVehicleTypeInput) {
    edit_vehicle_type(id: $id, vehicle_type: $vehicle_type) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_VEHICLE_TYPE = gql`
  mutation deleteVehicleType($id: ID!) {
    delete_vehicle_type(id: $id) {
      success
      error
    }
  }
`;

export const DELETE_MULTIPLE_VEHICLE_TYPE = gql`
  mutation deleteVehicleTypeAll($condition: [DynamicSearchInput]) {
    delete_vehicle_type_all(condition: $condition) {
      success
      error
      deleted
      total
    }
  }
`;

export const RESEND_ADMIN_LINK = gql`
  mutation resendDataAdminLink($client_id: ID!) {
    resend_data_admin_link(client_id: $client_id) {
      success
      error
    }
  }
`;

export const ADD_SCHEDULED_REPORT = gql`
  mutation addScheduledReport(
    $user_id: Int!
    $reporttype_id: Int!
    $sked: String!
    $timesked: String!
    $group_ids: [Int]!
    $device_id: [ID]
    $personnel_id: Int
    $status: Int
    $created: DateTime
    $filename: String
    $is_sent: Int
    $time_checked: DateTime
    $process_tries: Int
    $checked: Int
    $time_sent: DateTime
    $email_add: String
    $email_body: String
    $emailer: String
    $interval: Int
    $emailer_pw: String
    $is_blank_report: Int
    $alert_description_ids: [ID]
    $trip_status_id: [Int]
    $activity_only: Int
    $vehicle_status: [Int]
    $gps_status: [Int]
    $reporting_delay: String
    $duration: Int
    $headers: [[String]]
    $sftp: InputSFTP
    $via_email: Int!
    $via_sftp: Int!
    $geofence_ids: [ID]
    $startstamp: String
    $endstamp: String
    $battery_level: String
    $use_logistics_status: Boolean
  ) {
    add_scheduled_report(
      user_id: $user_id
      reporttype_id: $reporttype_id
      sked: $sked
      timesked: $timesked
      group_ids: $group_ids
      device_id: $device_id
      personnel_id: $personnel_id
      status: $status
      created: $created
      filename: $filename
      is_sent: $is_sent
      time_checked: $time_checked
      process_tries: $process_tries
      checked: $checked
      time_sent: $time_sent
      email_add: $email_add
      email_body: $email_body
      emailer: $emailer
      interval: $interval
      emailer_pw: $emailer_pw
      is_blank_report: $is_blank_report
      alert_description_ids: $alert_description_ids
      trip_status_id: $trip_status_id
      activity_only: $activity_only
      vehicle_status: $vehicle_status
      gps_status: $gps_status
      reporting_delay: $reporting_delay
      duration: $duration
      headers: $headers
      sftp: $sftp
      via_email: $via_email
      via_sftp: $via_sftp
      geofence_ids: $geofence_ids
      startstamp: $startstamp
      endstamp: $endstamp
      battery_level: $battery_level
      use_logistics_status: $use_logistics_status
    ) {
      id
      success
      error {
        message
        field
      }
    }
  }
`;

export const DELETE_SCHEDULE_REPORTS = gql`
  mutation deleteScheduleReports(
    $condition: [DynamicSearchInput]
    $dateFilter: DateFilterInput
  ) {
    delete_scheduled_report_all(
      condition: $condition
      dateFilter: $dateFilter
    ) {
      success
      error
      deleted
      total
    }
  }
`;

export const EDIT_SCHEDULED_REPORT = gql`
  mutation editScheduledReport(
    $id: ID!
    $scheduled_report: EditScheduledReportInput
  ) {
    edit_scheduled_report(id: $id, scheduled_report: $scheduled_report) {
      scheduled_report {
        id
        user_id
        reporttype_id
        sked
        timesked
        group_ids
        device_id
        personnel_id
        status
        created
        filename
        is_sent
        time_checked
        process_tries
        checked
        time_sent
        email_add
        email_body
        emailer
        interval
        emailer_pw
        is_blank_report
        report_type
        alert_description_ids
        trip_status_id
        activity_only
        vehicle_status
        gps_status
        reporting_delay
        headers
        sftp {
          key
          port
          username
          password
          sftp_link
        }
        via_email
        via_sftp
        battery_level
        use_logistics_status
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_ACTIVITY_LOG = gql`
  mutation addActivityLog($activity_log: ActivityLogInput!) {
    add_activity_log(activity_log: $activity_log) {
      success
      error
    }
  }
`;

export const ADD_FEEDBACK = gql`
  mutation addFeedback($feedback: FeedbackInput!) {
    add_feedback(feedback: $feedback) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_INFO_BUBBLE = gql`
  mutation addInfoBubble($InfoBubbleInput: InfoBubbleInput) {
    add_info_bubble(info_bubble: $InfoBubbleInput) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_INFO_BUBBLE = gql`
  mutation editInfoBubble($client_id: ID!, $info_bubble: InfoBubbleInput!) {
    edit_info_bubble(client_id: $client_id, info_bubble: $info_bubble) {
      success
      error {
        field
        message
      }
    }
  }
`;
export const DELETE_CONTROL_ROOM = gql`
  mutation deleteControlRoom($id: ID!) {
    delete_control_room(id: $id) {
      success
      error {
        field
        message
      }
    }
  }
`;
export const EDIT_CONTROL_ROOM = gql`
  mutation editControlRoom($id: ID!, $data: ControlRoomEditData!) {
    edit_control_room(id: $id, data: $data) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($id: ID!) {
    delete_booking(id: $id) {
      success
    }
  }
`;

export const ADD_CONTROL_ROOM = gql`
  mutation AddControlRoom($data: ControlRoomData) {
    add_control_room(data: $data) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_FUEL_CALIBRATION = gql`
  mutation editFuelCaliration(
    $vehicleModel_id: Int!
    $calibrations: [InputFuelCalibrations]
  ) {
    edit_fuel_calibration(
      vehicleModel_id: $vehicleModel_id
      calibrations: $calibrations
    ) {
      success
      error
    }
  }
`;

export const DELETE_FUEL_CALIBRATION = gql`
  mutation deleteFuelCalibration($vehicleModel_id: [Int!]) {
    delete_fuel_calibration(vehicleModel_id: $vehicleModel_id) {
      success
      error
    }
  }
`;

export const CLONE_ALERT_DESCRIPTIONS = gql`
  mutation cloneAlertDescriptions(
    $group_id_source: Int!
    $group_id_destination: Int!
    $alert_description_ids: [ID!]!
  ) {
    clone_alert_descriptions(
      group_id_source: $group_id_source
      group_id_destination: $group_id_destination
      alert_description_ids: $alert_description_ids
    ) {
      success
      error
    }
  }
`;

export const SYNC_VEHICLE_ODOMETER = gql`
  mutation syncOdometer($device_id: ID, $odometer: Int) {
    sync_odometer(device_id: $device_id, odometer: $odometer) {
      odometer {
        odom_diff
        odom_diff_changed
      }
      distance_travelled
      success
      error {
        field
        message
      }
    }
  }
`;
export const ADD_DASHBOARD_LINK = gql`
  mutation addDashboardLink(
    $name: String!
    $link: String!
    $group_ids: [Int!]
  ) {
    add_dashboard_link(name: $name, link: $link, group_ids: $group_ids) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_DASHBOARD_LINK = gql`
  mutation deleteDashboardLink($id: ID!) {
    delete_dashboard_link(id: $id) {
      success
      error
    }
  }
`;

export const EDIT_DASHBOARD_LINK = gql`
  mutation editDashboardLink(
    $id: ID!
    $name: String!
    $link: String!
    $group_ids: [Int!]
  ) {
    edit_dashboard_link(
      id: $id
      dashboard_link: { name: $name, link: $link, group_ids: $group_ids }
    ) {
      dashboard_link {
        id
        name
        link
        group_ids
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_SHARE_LOCATION = gql`
  mutation addShareLoc($share_location: ShareLocationInput) {
    add_share_location(share_location: $share_location) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const UPDATE_BOOKING_V2 = gql`
  mutation updateBookingV2(
    $id: ID!
    $vehicle_id: ID!
    $vehicle_plate_no: String
    $vehicle_type: String
    $data_complete: Boolean
    $dispatch_type: String
    $dispatch_time: ISODateV2
    $status_code: String
    $status_code_id: ID!
    $for_reprocess: Boolean
    $vehicle_history: [VehicleHistoryInput]
  ) {
    update_booking_v2(
      id: $id
      vehicle_id: $vehicle_id
      vehicle_plate_no: $vehicle_plate_no
      vehicle_type: $vehicle_type
      data_complete: $data_complete
      dispatch_type: $dispatch_type
      dispatch_time: $dispatch_time
      status_code: $status_code
      status_code_id: $status_code_id
      for_reprocess: $for_reprocess
      vehicle_history: $vehicle_history
    ) {
      id
      trip_number
      vehicle_plate_no
      vehicle_type
      status_code
      status_code_id
      pickups {
        manual_departure
      }
    }
  }
`;

export const ADD_CLIENT_GROUP = gql`
  mutation add_client_group($client_group: ClientGroupInput) {
    add_client_group(client_group: $client_group) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_CLIENT_GROUP = gql`
  mutation edit_client_group($client_group: ClientGroupInput) {
    add_client_group(client_group: $client_group) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_CLIENT_GROUP = gql`
  mutation delete_client_group($condition: [DynamicSearchInput]) {
    delete_client_group(condition: $condition) {
      success
      error
    }
  }
`;

export const ADD_DRIVER_INFO = gql`
  mutation add_personnel(
    $name: String!
    $employeeid: String!
    $designation: String
    $mobileno: String
    $driver_info: DriverInfoInput
    $group_ids: [Int!]
    $gps_code: String
    $supervisor: String
    $client_id: Int!
  ) {
    add_personnel(
      name: $name
      employeeid: $employeeid
      designation: $designation
      mobileno: $mobileno
      driver_info: $driver_info
      group_ids: $group_ids
      gps_code: $gps_code
      supervisor: $supervisor
      client_id: $client_id
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const UPDATE_DRIVER_INFO = gql`
  mutation update_personnel(
    $id: ID!
    $name: String
    $employeeid: String
    $designation: String
    $mobileno: String
    $driver_info: DriverInfoInput
    $group_ids: [Int]
    $gps_code: String
    $supervisor: String
    $client_id: Int
  ) {
    update_personnel(
      id: $id
      name: $name
      employeeid: $employeeid
      designation: $designation
      mobileno: $mobileno
      driver_info: $driver_info
      group_ids: $group_ids
      gps_code: $gps_code
      supervisor: $supervisor
      client_id: $client_id
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const DELETE_DRIVER_INFO = gql`
  mutation delete_personnels($id: [ID]!) {
    delete_personnels(id: $id) {
      success
    }
  }
`;

export const ADD_CHECKLIST_360 = gql`
  mutation initializeChecklist360($group_id: Int!) {
    initialize_checklist_360(group_id: $group_id) {
      success
      checklists360 {
        id
        checklist
      }
    }
  }
`;

export const EDIT_CHECKLIST_360 = gql`
  mutation editChecklist($id: Int!, $group_id: Int, $checklist: String) {
    edit_checklist_360(id: $id, group_id: $group_id, checklist: $checklist) {
      success
      checklists360 {
        checklist
      }
    }
  }
`;

export const ADD_CHECKLIST_HISTORY = gql`
  mutation add_checklist_history(
    $datestamp: TimeStamp!
    $vehicle_id: Int!
    $vehicle_plate_no: String!
    $checklists: String!
    $odometer_reading: Int
    $user_id: Int
    $user_name: String
  ) {
    add_checklist_history(
      datestamp: $datestamp
      vehicle_id: $vehicle_id
      vehicle_plate_no: $vehicle_plate_no
      checklists: $checklists
      odometer_reading: $odometer_reading
      user_id: $user_id
      user_name: $user_name
    ) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const CLONE_GEOFENCES = gql`
  mutation cloneGeofences(
    $group_id_source: Int!
    $group_id_destination: Int!
    $geofence_ids: [Int!]!
  ) {
    clone_geofences(
      group_id_source: $group_id_source
      group_id_destination: $group_id_destination
      geofence_ids: $geofence_ids
    ) {
      existing_geofence_codes {
        id
        name
        geofence_code
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const ASSIGN_STANDARD_GEOFENCE = gql`
  mutation($vehicle_id: Int!, $geofence_ids: [Int]!) {
    assign_standard_geofence(
      vehicle_id: $vehicle_id
      geofence_ids: $geofence_ids
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ASSIGN_ROUTE_GEOFENCE = gql`
  mutation($assign_route: VehicleRouteInput) {
    assign_route_geofence(assign_route: $assign_route) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const UPLOAD_WORKING_HOURS = gql`
  mutation uploadWorkingHours($working_hours: [Attendances]) {
    add_driver_attendances(attendances: $working_hours) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_DRIVERS_ATTENDANCES = gql`
  mutation editDriversAttendances($attendances: [EditAttendances]) {
    edit_driver_attendances(attendances: $attendances) {
      id
      success
      error {
        field
        message
      }
    }
  }
`;

export const EDIT_DRIVERS_ATTENDANCE = gql`
  mutation editDriversAttendance($id: ID!, $attendance: Attendance) {
    edit_driver_attendance(id: $id, attendance: $attendance) {
      success
      error
    }
  }
`;

export const DELETE_DRIVERS_ATTENDANCE = gql`
  mutation deleteDriverAttendances($id: ID!) {
    delete_driver_attendance(id: $id) {
      success
      error
    }
  }
`;

export const EDIT_VEHICLE_ALLOCATION = gql`
  mutation edit_allocation($id: ID!, $allocation: EditAllocation) {
    edit_allocation(id: $id, allocation: $allocation) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const ADD_ALLOCATIONS = gql`
  mutation addAllocations($allocations: [AllocationInput]) {
    add_allocations(allocations: $allocations) {
      success
      error {
        message
      }
    }
  }
`;

export const ADD_EXPO_TOKEN = gql`
  mutation addExpoToken(
    $token: String!
    $reference_id: String!
    $remarks: String
  ) {
    add_expo_token(
      token: $token
      reference_id: $reference_id
      remarks: $remarks
    )
  }
`;

export const UPDATE_EXPO_TOKEN = gql`
  mutation updateExpoToken(
    $id: ID!
    $token: String!
    $reference_id: String!
    $remarks: String
  ) {
    update_expo_token(
      id: $id
      token: $token
      reference_id: $reference_id
      remarks: $remarks
    ) {
      success
      error
    }
  }
`;

export const DELETE_EXPO_TOKEN = gql`
  mutation deleteExpoToken($id: [ID]!) {
    delete_expo_token(id: $id) {
      success
      error
    }
  }
`;  
