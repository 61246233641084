/* global google */
/* eslint-disable no-nested-ternary */
import React from "react";
import {
  IconButton,
  SvgIcon,
  Grid,
  Slider,
  Typography,
  Tooltip,
  TextField
} from "@material-ui/core";
import { useStyles } from "./CustomDrawing.styles";
import {
  getBounds,
  polygonToMultiPolygon,
  stdGeojsonToWkt
} from "../../../../../../../utils/functions/coordinatesParserV2";

const CustomDrawing = props => {
  const classes = useStyles();
  const {
    mapState: { drawingMode, opacity, radius, area, geofence_geom_id },
    setMapState,
    state: { location, zoom },
    univGeofences
  } = props;

  const handleRadiusChange = value => {
    const numSides = 48;
    const degreeStep = 360 / numSides;
    const drawingCoords = [];
    for (let i = 0; i < numSides; i += 1) {
      const gpos = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(location.lat, location.lon),
        value,
        degreeStep * i
      );
      drawingCoords.push([gpos.lng(), gpos.lat()]);
    }
    drawingCoords.push(drawingCoords[0]);
    const polygonGeom = { type: "polygon", coordinates: [drawingCoords] };
    const newGeom = stdGeojsonToWkt(polygonToMultiPolygon(polygonGeom));

    setMapState(prev => ({
      ...prev,
      geom: newGeom,
      radius: value,
      area: google.maps.geometry.spherical.computeArea(getBounds(newGeom))
    }));
  };

  return (
    <>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Navigate">
            <IconButton
              className={classes.button}
              onClick={() => {
                setMapState(prev => ({
                  ...prev,
                  drawingMode: null
                }));
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === null ? "#FF845E" : "#EAEAEA"}
                  fillOpacity={drawingMode === null ? "1" : "0.45"}
                />
                <path
                  d="M23.6867 7.17917L5.38336 17.5346C4.52692 18.0191 4.81408 19.32 5.79545 19.4014L15.1224 20.1755C15.4623 20.2037 15.7647 20.4032 15.9245 20.7047L20.6935 29.7013C21.1518 30.566 22.4504 30.3279 22.5696 29.3573L25.1728 8.17285C25.273 7.35758 24.4014 6.77481 23.6867 7.17917Z"
                  fill={drawingMode === null ? "#FFFFFF" : "#FF845E"}
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Circle">
            <IconButton
              className={classes.button}
              onClick={() => {
                setMapState(prev => ({
                  ...prev,
                  drawingMode: google.maps.drawing.OverlayType.CIRCLE
                }));
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "circle" ? "#FFB74D" : "#D1D1D1"}
                  fillOpacity={drawingMode === "circle" ? "1" : "0.45"}
                />
                <path
                  d="M27.6841 17.501C27.6841 22.5899 23.5095 26.751 18.3157 26.751C13.1219 26.751 8.94727 22.5899 8.94727 17.501C8.94727 12.412 13.1219 8.25098 18.3157 8.25098C23.5095 8.25098 27.6841 12.412 27.6841 17.501Z"
                  stroke={drawingMode !== "circle" ? "#FFB74D" : "#D1D1D1"}
                  strokeWidth="4"
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Rectangle">
            <IconButton
              className={classes.button}
              onClick={() => {
                setMapState(prev => ({
                  ...prev,
                  drawingMode: google.maps.drawing.OverlayType.RECTANGLE
                }));
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "rectangle" ? "#BB6BD9" : "#D1D1D1"}
                  fillOpacity={drawingMode === "rectangle" ? "1" : "0.45"}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 8C8.44772 8 8 8.44771 8 9V26C8 26.5523 8.44772 27 9 27H27C27.5523 27 28 26.5523 28 26V9C28 8.44772 27.5523 8 27 8H9ZM12.5312 11.3533C11.979 11.3533 11.5312 11.801 11.5312 12.3533V22.6474C11.5312 23.1997 11.979 23.6474 12.5313 23.6474H23.4724C24.0247 23.6474 24.4724 23.1997 24.4724 22.6474V12.3533C24.4724 11.801 24.0247 11.3533 23.4724 11.3533H12.5312Z"
                  fill={drawingMode !== "rectangle" ? "#BB6BD9" : "#D1D1D1"}
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Polyline">
            <IconButton
              className={classes.button}
              onClick={() => {
                setMapState(prev => ({
                  ...prev,
                  drawingMode: google.maps.drawing.OverlayType.POLYGON
                }));
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "polygon" ? "#63F160" : "#D1D1D1"}
                  fillOpacity={drawingMode === "polygon" ? "1" : "0.45"}
                />
                <path
                  d="M11 11.5184C15.3077 7.34537 21.0513 12.6568 18.1795 16.8297C15.3076 21.0027 18.1795 27.4521 25 24.0377"
                  stroke={drawingMode !== "polygon" ? "#63F160" : "#D1D1D1"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <IconButton
            className={classes.button}
            disabled={univGeofences.length === 0 || zoom < 17}
            onClick={() => {
              setMapState(prev => ({
                ...prev,
                drawingMode: "select",
                opacity: 0.5
              }));
            }}
          >
            <Tooltip title="Select">
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={
                    drawingMode === "select"
                      ? "#3CA4F7"
                      : univGeofences.length === 0 || zoom < 17
                      ? "#F5F5F5"
                      : "#D1D1D1"
                  }
                  fillOpacity={drawingMode === "select" ? "1" : "0.45"}
                />
                <path
                  d="M9 9.5L9.36858 7.53426C8.67869 7.4049 7.97148 7.64667 7.50516 8.17129C7.03885 8.69591 6.88168 9.42659 7.09104 10.0965L9 9.5ZM14 25.5L12.091 26.0965C12.3467 26.9147 13.0954 27.479 13.9524 27.4994C14.8093 27.5198 15.584 26.9919 15.8784 26.1868L14 25.5ZM17.3228 16.4118L16.4148 14.6298C15.9646 14.8591 15.6179 15.2504 15.4444 15.725L17.3228 16.4118ZM25 12.5L25.908 14.282C26.6707 13.8934 27.1016 13.0624 26.9796 12.2151C26.8576 11.3678 26.21 10.692 25.3686 10.5343L25 12.5ZM13.8227 10.9998C12.9942 10.2693 11.7303 10.3488 10.9998 11.1773C10.2693 12.0058 10.3488 13.2697 11.1773 14.0002L13.8227 10.9998ZM26.3896 27.413C27.2182 28.1435 28.482 28.064 29.2125 27.2355C29.943 26.407 29.8635 25.1432 29.035 24.4126L26.3896 27.413ZM14.5 18L12.6085 18.6497C12.847 19.3442 13.4469 19.8521 14.1713 19.9728C14.8956 20.0935 15.6279 19.8075 16.0787 19.2279L14.5 18ZM18 13.5L19.5787 14.7279C20.0125 14.1701 20.1198 13.4248 19.861 12.7673C19.6021 12.1098 19.0155 11.6377 18.3178 11.5254L18 13.5ZM7.09104 10.0965L12.091 26.0965L15.909 24.9035L10.909 8.90345L7.09104 10.0965ZM15.8784 26.1868L19.2012 17.0985L15.4444 15.725L12.1216 24.8132L15.8784 26.1868ZM18.2308 18.1938L25.908 14.282L24.092 10.718L16.4148 14.6298L18.2308 18.1938ZM25.3686 10.5343L9.36858 7.53426L8.63142 11.4657L24.6314 14.4657L25.3686 10.5343ZM11.1773 14.0002L11.3365 14.1405L13.9819 11.1402L13.8227 10.9998L11.1773 14.0002ZM11.3365 14.1405L26.3896 27.413L29.035 24.4126L13.9819 11.1402L11.3365 14.1405ZM10.7676 13.29L12.6085 18.6497L16.3915 17.3503L14.5507 11.9907L10.7676 13.29ZM16.0787 19.2279L19.5787 14.7279L16.4213 12.2721L12.9213 16.7721L16.0787 19.2279ZM18.3178 11.5254L12.977 10.6658L12.3414 14.6149L17.6822 15.4746L18.3178 11.5254Z"
                  fill={
                    drawingMode === "select"
                      ? "#FFFFFF"
                      : univGeofences.length === 0
                      ? "#9DD1FB"
                      : "#3CA4F7"
                  }
                />
              </SvgIcon>
            </Tooltip>
          </IconButton>
        </Grid>
        {drawingMode && (
          <Grid item xs={12}>
            <Grid container style={{ padding: 8 }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {drawingMode === "circle" && (
                    <>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Radius
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <TextField
                          value={radius}
                          size="small"
                          variant="outlined"
                          type="number"
                          className={classes.radiusInput}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: 100
                            }
                          }}
                          disabled={
                            !(drawingMode === "circle") || geofence_geom_id
                          }
                          onChange={event => {
                            const tempValue = parseFloat(event.target.value);
                            const temmpRadius =
                              tempValue >= 100 ? 100 : tempValue;
                            handleRadiusChange(temmpRadius);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Slider
                          value={radius || 0}
                          min={1}
                          max={100}
                          disabled={
                            !(drawingMode === "circle") || geofence_geom_id
                          }
                          onChange={(_, value) => handleRadiusChange(value)}
                        />
                      </Grid>
                    </>
                  )}
                  {(drawingMode === "rectangle" ||
                    drawingMode === "polygon" ||
                    drawingMode === "select") && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Area
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {area && (
                          <Typography style={{ fontSize: 12 }}>{`${area.toFixed(
                            2
                          )} sqm | ${(area * 10.764).toFixed(2)} sq ft | ${(
                            area / 4047
                          ).toFixed(2)} Acres`}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  {drawingMode !== null && (
                    <>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Opacity
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography style={{ fontSize: 12 }}>
                          {`${Math.round(opacity * 100)}%`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Slider
                          value={opacity * 100}
                          min={1}
                          max={100}
                          step={1}
                          onChange={(_, value) => {
                            setMapState(prev => ({
                              ...prev,
                              opacity: value / 100
                            }));
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustomDrawing;
