 /*eslint-disable no-unused-vars*/
 /*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Table,
  InputBase,
  IconButton,
  Tooltip,
  Switch,
  withStyles,
  Box
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  HelpRounded
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import Header from "../../Header";
import PairVehicle from "../../../../utils/modals/PairVehicle";
import SelectGroup from "../../../../utils/modals/SelectGroup";
import {
  GET_CLIENTS_QUERY_WO_GROUPS,
  GET_GPS_PROVIDERS,
  GET_DEVICE_TYPES
} from "../../../../graphql/Queries";
import { ADD_DEVICE, ADD_NOTIFICATION } from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../utils/validation";
import redirectToTable from "../../../../utils/redirect";
import AddLogs from "../../../../utils/functions/AddLogs";
import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
import useFormStyles from "../../../../styles/useFormStyles";
import AccessControl from "../../../../utils/AccessControl";
import { getMaxLength } from "./utils";
import Loading from "../../../../utils/Loading";
import debounceKeywordChange from "../../../../utils/functions/debounce.util";
const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#F8F8F8",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      border: "5px solid #f8f8f8"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      border: "4px solid #f8f8f8",
      background: "#969696"
    },
    "&::-webkit-scrollbar-track": {
      border: "5px solid #f8f8f8"
    }
  },
  searchRoot: {
    padding: "0px 9px",
    display: "flex",
    alignItems: "center",
    width: 325,
    height: 36,
    backgroundColor: "#fafafa",
    borderRadius: "25px",
    boxShadow: "none",
    border: "2px solid #ccc"
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 600,
    fontSize: 14,
    "& .MuiInputBase-input": {
      letterSpacing: 0.7
    }
  },
  container: {
    minHeight: "calc(100% - 66px - 32px - 36px)", // 66 for header, 32 for top + bottom margins
    margin: theme.spacing(0, 4, 2),
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    borderRadius: 0
  },
  scrollable: {
    height: "calc(100% - 66px)",
    boxSizing: "border-box",
    overflow: "auto"
  },
  field: {
    margin: "18px 0 0 0",
    width: "90%",
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      opacity: 1
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "12px 0"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#CBCBCB"
    }
  },
  addEntryButton: {
    fontSize: 12,
    letterSpacing: 0.7,
    // fontWeight: 600,
    textTransform: "capitalize"
  },
  column: {
    fontSize: "1.5rem"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  formControl: {
    margin: theme.spacing(1),
    display: "block"
  },
  tabs: {
    margin: theme.spacing(2, 4, 0)
  },
  tab: {
    width: "150px",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
    // backgroundColor: (props)=> props === 0 || props === 1 ? theme.palette.primary.main : 'white'
  },
  tab_active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  alertDate: {
    "& + .MuiFormHelperText-root.Mui-error": {
      marginTop: 0
    }
  },
  alertGroups: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "100%",
    marginTop: "6px 8px"
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  textField: {
    "& .MuiInput-underline:after, & .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    verticalAlign: "unset"
  },
  checkBoxTitle: { fontSize: 14, fontWeight: 600, letterSpacing: 0.7 },
  mediumTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#565656",
    letterSpacing: 0.7
  },
  tabTitle: {
    fontWeight: 600,
    letterSpacing: 1.5,
    textTransform: "capitalize"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  switch: {
    margin: "18px 0 0 0",
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    display: "flex",
    alignItems: "center"
  },
  unpairBox: {
    // padding: "2px 4px",
    display: "flex",
    alignItems: "end"
  },
  unpairIcon: {
    marginLeft: -45
  },
  customTextFieldLabel: {
    fontSize: "12px",
    fontFamily: "Nunito",
    lineHeight: 1,
    letterSpacing: "1px",
    fontWeight: 600,
    color: "black",
    marginTop: "22px"
  },
  customInputLabel: {
    fontSize: "12px",
    fontFamily: "Nunito",
    lineHeight: 1,
    letterSpacing: "1px",
    fontWeight: 600,
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px"
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

export default function AddDevice(props) {
  const [activeTab, setActiveTab] = React.useState(0);
  const classes = useStyles(activeTab);
  const formStyles = useFormStyles();
  const [state, setState] = useState({
    name: "",
    devicealias: "",
    device_type_id: "",
    client_id: -1,
    remarks: "",
    mirror_to_ngt: false,
    contract_ref_code: "",
    reserve_field: "",
  });
  const [keyword, setKeyword] = useState("");
  const [deviceConfig, setDeviceConfig] = useState({
    mobileno: "",
    intervalreporting: 1,
    battery_intervalreporting: 0,
    aux_voltage: 0,
    warranty: moment().format("MM-DD-YYYY"),
    dateinstalled: moment().format("MM-DD-YYYY"),
    expirationdate: moment().format("MM-DD-YYYY"),
    hasFuelSensor: false,
    fuelrefill: 1,
    fuelpilferage: 1,
    hastempsensor: false,
    mintemp: 1,
    maxtemp: 1,
    monitordooropenning: false,
    checkhomebasestatus: false,
    speedlimit: 0,
    maxvalidspeed: 0,
    highwayspeedlimit: 0,
    warningspeed: 0,
    idlesetting: 1,
    gps_provider_id: null,
    use_start_end_idle: true,
    ha_min: 0,
    ha_max: 0,
    hb_min: 0,
    hb_max: 0
  });
  const [initialState] = useState({
    state: _.cloneDeep(state),
    deviceConfig: _.cloneDeep(deviceConfig)
  });

  const [groupList, setGroupList] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    devicealias: "",
    mobileno: "",
    client_id: "",
    device_type_id: "",
    gps_provider_id: "",
    groupList: "",
    idlesetting: "",
    intervalreporting: "",
    battery_intervalreporting: "",
    aux_voltage: "",
    speedlimit: "",
    maxvalidspeed: "",
    highwayspeedlimit: "",
    warningspeed: "",
    warranty: "",
    dateinstalled: "",
    expirationdate: "",
    ha_min: "",
    ha_max: "",
    hb_min: "",
    hb_max: "",
    contract_ref_code: "",
    reserve_field: "",
  });
  const [vehicleModal, toggleVehicleModal] = useState(false);
  const [groupModal, toggleGroupModal] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState();
  const [dialog, setDialog] = useState({ save: false });
  const [forceRerender, setForceRerender] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [render, setRender] = useState(false);
  const [primaryClient, setPrimaryClient] = useState({});
  const [clientCode, setClientCode] = useState({
    bdg: "",
    qne: ""
  });
  const history = useHistory();
  const { loading, data: dataClients, refetch } = useQuery(GET_CLIENTS_QUERY_WO_GROUPS, {
    variables: {
      first: 15
    }
  });

  const { loading: deviceTypesLoading, data: deviceTypesData } = useQuery(
    GET_DEVICE_TYPES
  );

  const {
    loading: gpsProvidersLoading,
    data: gpsProvidersData
  } = useQuery(GET_GPS_PROVIDERS, { variables: { first: 50 } });

  // const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [addDevice] = useMutation(ADD_DEVICE, {
    onCompleted(data) {
      if (data) {
        if (data.add_device.success) {
          AddLogs("Admin - GPS Devices", "insert", state.name);
          if (vehicleInfo) {
            AddLogs(
              "Admin - GPS Devices",
              "paired_device",
              [state.name, vehicleInfo.plateno]
            );

            // addNotif({
            //   variables: {
            //     notification: {
            //       client_id: [primaryClient.id],
            //       vehicle_plate_no: vehicleInfo.plateno,
            //       datestamp: moment()
            //         .format("YYYY-MM-DD HH:mm:ss"),
            //       notification: `GPS Device ${state.name} was paired to Vehicle/CV ${vehicleInfo.plateno}`,
            //       category: "User Activity",
            //       is_archived: false,
            //       creator: "Tmsuite-web",
            //       group_ids: groupList.map(grp => grp.id),
            //       group_names: groupList.map(grp => grp.name)
            //     }
            //   }
            // });
          }
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            // onClose: () => {
            //   redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
            // }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              // redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
              // set values again for vehicles table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GPSDEVICES_MODULE,
                    vars: history?.location?.state?.vars,
                    filter: history?.location?.state?.filter
                  }
                }
              });
            }
          });
        } else {
          const temp = data.add_device.error.map(element => {
            const errorKey = element.field.split(".");
            if (errorKey.length > 1) {
              const secondKey = errorKey[1];
              return { [secondKey]: element.message };
            }
            if (element.field === "name") {
              return { [element.field]: "IMEI/Serial Number already exists" };
            }

            return { [element.field]: element.message };
          });
          // temp.push(element.split(".").length > 1 ? {  : element.message })
          setErrors(Object.assign(errors, ...temp));
          setRender(!render);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          // onClose: () => {
          //   redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          // }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            // redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
            // set values again for vehicles table filter after adding
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_GPSDEVICES_MODULE,
                  vars: history?.location?.state?.vars,
                  filter: history?.location?.state?.filter
                }
              }
            });
          }
        });
      }
    }
  });

  const check = field => {
    if (!errors[field]) {
      if (field === "mobileno") {
        // if (!deviceConfig[field]) {
        //   return "This field is required";
        // }
        // if (deviceConfig[field].length < 11) {
        //   return "Contact number must be 11 digits";
        // }
      } else if (field === "gps_provider_id") {
        return deviceConfig.gps_provider_id === null
          ? "This field is required"
          : "";
      } else if (field === "groupList") {
        return groupList.length > 0 ? "" : "This field is required";
      } else if (field === "client_id") {
        return _.isEmpty(primaryClient) ? "This field is required" : "";
      } else {
        // return state[field] === "" || state[field] === -1
        //   ? "This field is required"
        //   : "";
      }
    }

    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setErrors(Object.assign(errors, ...temp));
    return keys.every(element => errors[element] === "");
  };

  const handleOpen = name => {
    if (name === "save") {
      if (validateFields()) setDialog({ ...dialog, [name]: true });
      else setForceRerender(!forceRerender);
    } else {
      setDialog({ ...dialog, [name]: true });
    }
  };

  const handleClose = name => {
    setDialog({ ...dialog, [name]: false });
  };

  function openVehicleModal() {
    toggleVehicleModal(true);
  }

  function closeVehicleModal() {
    toggleVehicleModal(false);
  }

  function openGroupModal() {
    toggleGroupModal(true);
  }

  function closeGroupModal() {
    toggleGroupModal(false);
  }

  const handlePrimaryClient = (e, val) => {
    const qne = val?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "qne")
    const bdg = val?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "bdg")

    setErrors({ ...errors, client_id: "" });
    setPrimaryClient(val);
    setClientCode({...clientCode, 
      qne,
      bdg
    })
  };

  const handleRemoveGroup = id => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(id);
    temp.splice(index, 1);
    setGroupList(temp);
    setRender(!render);
  };

  const handleGroupList = array => {
    setGroupList(array);
    setErrors({ ...errors, groupList: "" });
  };

  const {
    monitordooropenning,
    checkhomebasestatus,
    hasFuelSensor,
    hastempsensor
  } = deviceConfig;

  const handleChangeTab = newValue => {
    setActiveTab(newValue);
  };

  const handleGPSProviderConfig = (_e, newValue) => {
    setDeviceConfig({
      ...deviceConfig,
      gps_provider_id: newValue
    });
    setErrors({ ...errors, gps_provider_id: "" });
  };

  const handleBlur = e => {
    const { name, value } = e.target;
    let error = "";
    switch (name) {
      case "name":
        if (value.trim() === "") {
          error = "This field is required";
        } else if (!validate("alphanumeric_default", value.trim())) {
          error = "Alphanumeric characters only";
        }
        break;
      case "mobileno":
        if (value === "") {
          error = "";
          // error = "This field is required";
        } else if (!validate("mobile_number_ph", value)) {
          error = "Invalid Mobile Number";
        }
        break;
      case "aux_voltage":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Auxiliary voltage (mV) is invalid format";
        } else if (value > 10080 || value < 0)
          error = "Invalid value min: 0, max: 10,080";
        break;
      case "idlesetting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Idle setting (min) is invalid format";
        } else if (value > 10080 || value < 1)
          error = "Invalid value min: 1, max: 10,080";
        break;
      case "intervalreporting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Interval reporting (min) is invalid format";
        } else if (value > 10080 || value < 1)
          error = "Invalid value min: 1, max: 10,080";
        break;
      case "battery_intervalreporting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Auxiliary Interval Reporting (min) is invalid format";
        } else if (value > 10080 || value < 0)
          error = "Invalid value min: 0, max: 10,080";
        break;
      case "speedlimit":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Speed Limit is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "maxvalidspeed":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Max Valid Speed is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "highwayspeedlimit":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Highway Speed Limit is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "warningspeed":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Warning Speed is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "dateinstalled":
      case "warranty":
      case "expirationdate":
        if (value === null || value === "") {
          error = "This field is required";
        } else if (moment(value).format("MMM DD YYYY") === "Invalid date") {
          error = "Invalid date format";
        }
        break;
      case "devicealias":
        if (value.trim() === "") {
          error = "This field is required";
        }
        // else if (!validate("alphanumeric_default", value)) {
        //   error = "Alphanumeric characters only";
        // }
        break;
      case "client_id":
      case "gps_provider_id":
        if (value === "") {
          error = "This field is required";
        }
        break;
      case "contract_ref_code":
      case "reserve_field":
        if (!validate("alphanumeric", value)) {
          error = "Alphanumeric characters only";
        } else if(value.length > 50) {
            error = "Maximum of 50 characters only"
        }
        break;
      default:
        break;
    }

    // setDeviceConfig({
    //   ...deviceConfig,
    //   [name]: type === "checkbox" ? checked : val
    // });
    setErrors({ ...errors, [name]: error });
  };

  const handleConfig = e => {
    const { name, value, checked, type } = e.target;
    let val = value;
    let error = "";
    switch (name) {
      case "mobileno":
        const maxLength = getMaxLength(val);
        if (val.length > maxLength) {
          val = deviceConfig.mobileno;
        }
        break;
      case "ha_min":
      case "ha_max":
        if (+val >= -0.09 && +val <= 99) {
          val = +value;
        } else {
          error = "Harsh Acceleration(HA) value min: -0.09, max: 99";
        };
        break;
      case "hb_min":
      case "hb_max":
        if (+val >= -0.09 && +val <= 99) {
          val = +value;
        } else {
          error = "Harsh Braking(HB) value min: -0.09, max: 99";
        };
        break;
      // case "aux_voltage":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Auxiliary voltage (mV) is invalid format";
      //   } else if (value > 10080 || value < 0)
      //     error = "Auxiliary voltage (mV) value min: 0, max: 10,080";
      //   break;
      // case "idlesetting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Idle setting (min) is invalid format";
      //   } else if (value > 10080 || value < 1)
      //     error = "Idle setting (min) value min: 1, max: 10,080";
      //   break;
      // case "intervalreporting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Interval reporting (min) is invalid format";
      //   } else if (value > 10080 || value < 1)
      //     error = "Interval reporting (min) value min: 1, max: 10,080";
      //   break;
      // case "battery_intervalreporting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Auxiliary Interval Reporting (min) is invalid format";
      //   } else if (value > 10080 || value < 0)
      //     error =
      //       "Auxiliary Interval Reporting (min) value min: 0, max: 10,080";
      //   break;
      // case "speedlimit":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Speed Limit is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Speed Limit value min: 0, max: 500";
      //   break;
      // case "maxvalidspeed":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Max Valid Speed is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Max Valid Speed value min: 0, max: 500";
      //   break;
      // case "highwayspeedlimit":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Highway Speed Limit is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Highway Speed Limit value min: 0, max: 500";
      //   break;
      // case "warningspeed":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Warning Speed is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Warning Speed value min: 0, max: 500";
      //   break;
      default:
        // val = parseInt(value, 10);
        break;
    }

    // speedlimit: 1,
    // maxvalidspeed: 1,
    // highwayspeedlimit: 1,
    // warningspeed: 1,
    setDeviceConfig({
      ...deviceConfig,
      [name]: type === "checkbox" ? checked : +val
    });
    setErrors({ ...errors, [name]: error });
  };

  const handleDate = (val, name) => {
    setDeviceConfig({
      ...deviceConfig,
      [name]: val
    });
  };

  const handleSelectAll = e => {
    const { checked } = e.target;
    setDeviceConfig({
      ...deviceConfig,
      monitordooropenning: checked,
      checkhomebasestatus: checked,
      hasFuelSensor: checked,
      hastempsensor: checked
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const group_ids = groupList.map(gl => parseInt(gl.id, 10));
    _.unset(vehicleInfo, ["vehicle_type"]);

    addDevice({
      variables: {
        remarks: state.remarks,
        name: state.name,
        devicealias: state.devicealias,
        device_type_id: parseInt(state.device_type_id, 10),
        client_id: parseInt(primaryClient.id, 10),
        vehicle_info: vehicleInfo,
        group_ids: group_ids,
        device_config: {
          ...deviceConfig,
          gps_provider_id: parseInt(deviceConfig.gps_provider_id.id, 10),
          battery_intervalreporting: +deviceConfig.battery_intervalreporting,
          intervalreporting: +deviceConfig.intervalreporting,
          aux_voltage: +deviceConfig.aux_voltage,
          highwayspeedlimit: +deviceConfig.highwayspeedlimit,
          idlesetting: +deviceConfig.idlesetting,
          maxvalidspeed: +deviceConfig.maxvalidspeed,
          speedlimit: +deviceConfig.speedlimit,
          warningspeed: +deviceConfig.warningspeed,
          fuelpilferage: +deviceConfig.fuelpilferage,
          fuelrefill: +deviceConfig.fuelrefill,
          maxtemp: +deviceConfig.maxtemp,
          mintemp: +deviceConfig.mintemp
        },
        device_status: { remarks: "Placeholder" },
        mirror_to_ngt: state.mirror_to_ngt,
        contract_ref_no: state.contract_ref_code,
        reserve_field: state.reserve_field
      }
    });
  };

  const allSelected = () => {
    return (
      monitordooropenning &&
      checkhomebasestatus &&
      hasFuelSensor &&
      hastempsensor
    );
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // Characters that are not included in this list (a-zA-Z0-9) are not allowed.
    if (/[^a-zA-Z0-9]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleChange = event => {
    const { name, type, checked, value } = event.target;
    const error = "";
    // switch (name) {
    //   case "name":
    //     error = validate("alphanumeric_default", value)
    //       ? ""
    //       : "Alphanumeric characters only";
    //     break;
    //   case "devicealias":
    //     error = validate("alphanumeric_default", value)
    //       ? ""
    //       : "Alphanumeric characters only";
    //     break;
    //   default:
    //     break;
    // }
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
    setErrors({ ...errors, [name]: error });
  };

  useEffect(() => {
    setErrors({ ...errors, vehicleInfo: "" });
  }, [vehicleInfo]);

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/wti/devices/"
  // );

  const onPrimaryClientChanged = event => {
    refetch({
      first: 15,
      keyword: event.target.value
    })
  }
  const debouncedPrimaryClientChanged = React.useMemo(
    () => debounceKeywordChange(onPrimaryClientChanged, 250),
    []
  );

  if (
    loading ||
    deviceTypesLoading ||
    gpsProvidersLoading // ||
    // loadingPermissions
  ) {
    return <Loading />;
  }

  // if (!permissions.edit) {
  //   return <Typography>403</Typography>;
  // }

  const { pathname } = props.location;

  return (
    <AccessControl resource={"/admin/wti/devices/add"} process="add">
      <div className={classes.root}>
        <Header
          // process="Add"
          process="Add"
          moduleName={process.env.REACT_APP_GPSDEVICES_MODULE}
          history={history?.location?.state}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={
            _.isEqual(initialState.state, state) &&
            _.isEqual(initialState.deviceConfig, deviceConfig) &&
            _.isEqual(groupList, [])
          }
        />
        <div className={classes.scrollable}>
        <div style={{ display: "flex" }} className={classes.tabs}>
          <Button
            variant="contained"
            style={{
              background: activeTab === 0 ? "#40A8FF" : "white",
              color: activeTab === 0 ? "white" : "black",
              // width: 160,
              borderRadius: 0,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              padding: 12,
              boxShadow: "none",
              border: "1px solid rgb(0 0 0 / 12%)",
              borderBottom: "none"
            }}
            className={[classes.tab, activeTab === 0 && classes.tab_active]}
            onClick={() => handleChangeTab(0)}
          >
            <span className={classes.tabTitle}>Device Info</span>
          </Button>
          <Button
            variant="contained"
            style={{
              background: activeTab === 1 ? "#40A8FF" : "white",
              color: activeTab === 1 ? "white" : "black",
              // width: 160,
              borderRadius: 0,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              padding: 12,
              boxShadow: "none",
              border: "1px solid rgb(0 0 0 / 12%)",
              borderBottom: "none"
            }}
            className={[classes.tab, activeTab === 1 && classes.tab_active]}
            onClick={() => handleChangeTab(1)}
          >
            <span className={classes.tabTitle}>Config</span>
          </Button>
        </div>
        <Paper className={classes.container} variant="outlined">
          <Grid item xs={12} style={{ display: "flex", marginBottom: 12 }}>
            <Typography>
              All fields with <span style={{ color: "red" }}>*</span> are
              required
            </Typography>
          </Grid>
          <Grid container spacing={6}>
            {activeTab === 0 && (
              <>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="name"
                    label="IMEI / Serial Number"
                    placeholder="Enter Serial Number"
                    value={state.name}
                    // onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.name && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.name}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="devicealias"
                    label="Device Alias"
                    placeholder="Enter Device Alias"
                    value={state.devicealias}
                    // onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.devicealias && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.devicealias}
                    </Alert>
                  )}
                  <FormControl className={classes.field} required>
                    <InputLabel shrink>Device Type</InputLabel>
                    <Select
                      classes={{ root: formStyles.selectRoot }}
                      value={state.device_type_id}
                      onChange={e => handleChange(e)}
                      name="device_type_id"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em style={{ color: "#8e8e8e" }}>Select Device Type</em>
                      </MenuItem>
                      {deviceTypesData.get_device_types.device_types.map(
                        device_type => (
                          <MenuItem key={device_type.id} value={device_type.id}>
                            {device_type.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>

                  {errors.device_type_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.device_type_id}
                    </Alert>
                  )}
                  <Box className={classes.unpairBox}>
                    <TextField
                      classes={{ root: formStyles.textFieldRoot }}
                      label="Paired Vehicle/CV"
                      placeholder="Enter Pair Vehicle/CV"
                      value={vehicleInfo ? vehicleInfo.plateno : ""}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "black" }
                      }}
                      readOnly
                      onClick={() => openVehicleModal()}
                      className={classes.field}
                    />
                    {vehicleInfo ? (
                      <IconButton
                        className={classes.unpairIcon}
                        disabled={!vehicleInfo?.plateno}
                        onClick={() => {
                          setVehicleInfo(null);
                        }}
                      >
                        <svg
                        width="20"
                        height="20"
                        viewBox="0 0 1024 1024"
                        fill={vehicleInfo?.plateno ? "#000000" : "none"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                           d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z" 
                          stroke-width="8"  
                          stroke={vehicleInfo?.plateno ? "#757575" : "#000000"}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Box>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    // required
                    name="mobileno"
                    label="Mobile Number"
                    placeholder="+63----------"
                    defaultValue={deviceConfig.mobileno}
                    value={deviceConfig.mobileno}
                    onChange={e => handleConfig(e)}
                    onBlur={e => {
                      e.persist();
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                      setDeviceConfig(config => ({
                        ...config,
                        mobileno: beginWith63
                      }));
                      e.target.value = beginWith63;
                      handleBlur(e);
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: () => {
                          getMaxLength(deviceConfig.mobileno);
                        }
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                  />

                  {errors.mobileno && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.mobileno}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="intervalreporting"
                    label="Main Interval Reporting"
                    value={deviceConfig.intervalreporting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "1", max: "10080", step: "1" }}
                    onBlur={handleBlur}
                  />
                  {errors.intervalreporting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.intervalreporting}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="battery_intervalreporting"
                    label="Auxillary Interval Reporting (min)"
                    value={deviceConfig.battery_intervalreporting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "10080", step: "1" }}
                    onBlur={handleBlur}
                  />
                  {errors.battery_intervalreporting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.battery_intervalreporting}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="idlesetting"
                    label="Idle Setting (min)"
                    value={deviceConfig.idlesetting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "1", max: "10080", step: "1" }}
                    onBlur={handleBlur}
                  />
                  {errors.idlesetting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.idlesetting}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="aux_voltage"
                    label="Aux Voltage"
                    value={deviceConfig.aux_voltage ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "10080", step: "1" }}
                    onBlur={handleBlur}
                  />
                  {errors.aux_voltage && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.aux_voltage}
                    </Alert>
                  )}
                  {/* <FormControl className={classes.field}>
              <InputLabel shrink required>
                GPS Provider
              </InputLabel>
              <Select
                value={deviceConfig.gps_provider_id}
                onChange={e => handleConfig(e)}
                name="gps_provider_id"
                displayEmpty
              >
                <MenuItem value={-1} disabled>
                  <em style={{ color: "#8e8e8e" }}>
                    Select GPS Provider
                  </em>
                </MenuItem>
                {gpsProvidersData.get_gps_providers.gps_providers.map(
                  gps_provider => (
                    <MenuItem
                      value={gps_provider.id}
                      key={gps_provider.id}
                    >
                      {gps_provider.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl> */}
                  <FormControl className={classes.field}>
                    <InputLabel shrink required>
                      GPS Provider
                    </InputLabel>
                    <Autocomplete
                      name="gps_provider_id"
                      options={
                        gpsProvidersData.get_gps_providers.gps_providers || []
                      }
                      getOptionLabel={option => option.name || ""}
                      renderOption={option => option.name}
                      defaultValue={null}
                      value={deviceConfig.gps_provider_id}
                      onChange={handleGPSProviderConfig}
                      onBlur={handleBlur}
                      renderInput={params => (
                        <TextField
                          classes={{ root: formStyles.textFieldRoot }}
                          {...params}
                          style={{ marginTop: 16 }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "black" }
                          }}
                          placeholder="Select GPS Provider"
                          fullWidth
                          name="gps_provider_id"
                        />
                      )}
                    />
                  </FormControl>
                  {errors.gps_provider_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.gps_provider_id}
                    </Alert>
                  )}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      classes={{ root: formStyles.textFieldRoot }}
                      className={classes.field}
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      openTo="date"
                      format="MM/dd/yyyy"
                      label="Date Installed"
                      views={["year", "month", "date"]}
                      InputProps={{
                        className: classes.alertDate
                      }}
                      invalidDateMessage={
                        errors.dateinstalled && (
                          <Alert
                            severity="error"
                            className={classes.alertGroups}
                          >
                            {errors.dateinstalled}
                          </Alert>
                        )
                      }
                      value={deviceConfig.dateinstalled}
                      name="dateinstalled"
                      onBlur={handleBlur}
                      onChange={e =>
                        handleDate(
                          moment(e).format("MMM DD YYYY"),
                          "dateinstalled"
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      classes={{ root: formStyles.textFieldRoot }}
                      className={classes.field}
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      openTo="date"
                      format="MM/dd/yyyy"
                      label="Warranty"
                      views={["year", "month", "date"]}
                      InputProps={{
                        className: classes.alertDate
                      }}
                      invalidDateMessage={
                        errors.warranty && (
                          <Alert
                            severity="error"
                            className={classes.alertGroups}
                          >
                            {errors.warranty}
                          </Alert>
                        )
                      }
                      value={deviceConfig.warranty}
                      name="warranty"
                      onBlur={handleBlur}
                      onChange={e =>
                        handleDate(moment(e).format("MMM DD YYYY"), "warranty")
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      classes={{ root: formStyles.textFieldRoot }}
                      className={classes.field}
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      openTo="date"
                      format="MM/dd/yyyy"
                      label="Expiration Date"
                      views={["year", "month", "date"]}
                      InputProps={{
                        className: classes.alertDate
                      }}
                      invalidDateMessage={
                        errors.expirationdate && (
                          <Alert
                            severity="error"
                            className={classes.alertGroups}
                          >
                            {errors.expirationdate}
                          </Alert>
                        )
                      }
                      value={deviceConfig.expirationdate}
                      name="expirationdate"
                      onBlur={handleBlur}
                      onChange={e =>
                        handleDate(
                          moment(e).format("MMM DD YYYY"),
                          "expirationdate"
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <FormControl className={classes.field} required>
                    {/* <InputLabel shrink>Primary Client</InputLabel> */}
                    <Autocomplete
                      // label="Aux Voltage"
                      // InputLabelProps={{
                      //   shrink: true,
                      //   style: { color: "black" }
                      // }}
                      loading={loading}
                      options={dataClients.get_clients.clients || []}
                      getOptionLabel={option => option.name || ""}
                      getOptionSelected={(o, v) => {
                        if (v.name === o.name) return o.name;
                        return {};
                      }}
                      onChange={handlePrimaryClient}
                      onBlur={handleBlur}
                      name="client_id"
                      value={primaryClient}
                      renderInput={params => (
                        <TextField
                          {...params}
                          onChange={debouncedPrimaryClientChanged}
                          required
                          classes={{ root: formStyles.textFieldRoot }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="none"
                          InputProps={{
                            ...params.InputProps
                          }}
                          label={
                            <span>
                            <Tooltip
                              classes={{ tooltip: formStyles.toolTip }}
                              title={
                                <div>
                                  <span>Client Code</span>
                                  <br />
                                  <span>BDG: {clientCode.bdg ? clientCode.bdg.code : "N/A"} </span>
                                  <br />
                                  <span>QNE: {clientCode.qne ? clientCode.qne.code : "N/A"} </span>
                                  <br />
                                </div>
                              }
                              placement="top-start"
                              >
                              <HelpRounded className={classes.helpIcon} />
                            </Tooltip>
                              Primary Client
                            </span>
                          }
                          placeholder="Select Primary Client"
                          name="client_id"
                          // fullWidth
                        />
                      )}
                    />
                  </FormControl>

                  {errors.client_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.client_id}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    name="contract_ref_code"
                    label="Contract Reference Code"
                    placeholder="Enter Contract Reference Code"
                    value={state.contract_ref_code}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.contract_ref_code && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.contract_ref_code}
                    </Alert>
                  )}
                  
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    name="reserve_field"
                    label="Reserve Field 1"
                    placeholder="Enter Reserve Field 1"
                    value={state.reserve_field}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.reserve_field && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.reserve_field}
                    </Alert>
                  )}

                  <div className={classes.switch}>
                    {/* <InputLabel shrink className={classes.inputLabel}> */}
                    <Tooltip
                      classes={{ tooltip: formStyles.toolTip }}
                      title="Turn on: This GPS device info will be passed to NGT"
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    <Typography color="inherit" className={classes.mediumTitle}>
                      &nbsp;Mirror to NGT&nbsp;
                    </Typography>
                    {/* </InputLabel> */}
                    <AntSwitch
                      color="primary"
                      name="mirror_to_ngt"
                      checked={state.mirror_to_ngt}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                  <div className={classes.switch}>
                    <Tooltip
                      classes={{ tooltip: formStyles.toolTip }}
                      title={
                        <span>
                          Turn on: Computation will be Idling Duration =
                          idle_end - idle_start + idle_setting
                          <br />
                          Turn Off: Computation will be Idling Duration =
                          idle_setting * number of idle
                        </span>
                      }
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    <Typography color="inherit" className={classes.mediumTitle}>
                      &nbsp;Start and End Idle Enable toggle&nbsp;
                    </Typography>
                    {/* </InputLabel> */}
                    <AntSwitch
                      color="primary"
                      name="use_start_end_idle"
                      checked={deviceConfig.use_start_end_idle}
                      onChange={e => {
                        const { name, checked } = e.target;
                        setDeviceConfig({ ...deviceConfig, [name]: checked });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.mediumTitle} gutterBottom>
                    Remarks
                  </Typography>
                  <TextField
                    name="remarks"
                    placeholder="Type here..."
                    multiline
                    rows="4"
                    value={state.remarks}
                    onChange={e => handleChange(e)}
                    variant="outlined"
                    fullWidth
                    className={classes.multiline}
                    helperText={`${state.remarks.length}/100`}
                    FormHelperTextProps={{ style: { textAlign: "right" } }}
                    inputProps={{ maxLength: 100 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 12,
                      marginTop: 12
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Tooltip
                        classes={{ tooltip: formStyles.toolTip }}
                        title="Data of primary client will be linked to all selected partners(and vise versa)"
                        placement="top-start"
                      >
                        <HelpRounded className={classes.helpIcon} />
                      </Tooltip>
                      <InputLabel
                        required
                        classes={{ root: classes.mediumTitle }}
                      >
                        Partners
                      </InputLabel>
                    </div>
                    <Paper component="form" className={classes.searchRoot}>
                      <InputBase
                        className={classes.searchInput}
                        placeholder="Search Client"
                        inputProps={{ "aria-label": "plate #" }}
                        onChange={e => setKeyword(e.target.value)}
                        // value={keyword}
                        defaultValue={keyword}
                      />
                      <IconButton
                        // type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  <Paper
                    style={{
                      minHeight: "50%",
                      maxHeight: "290px",
                      overflow: "auto"
                    }}
                    variant="outlined"
                  >
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                // indeterminate={numSelected > 0 && numSelected < rowCount}
                                // checked={rowCount > 0 && numSelected === rowCount}
                                // onChange={onSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all desserts"
                                }}
                              />
                            </TableCell>
                            <TableCell
                              padding="normal"
                              // sortDirection={orderBy === headCell.id ? order : false}
                            >
                              <TableSortLabel
                              // classes={{ icon: classes.icon }}
                              // active={orderBy === headCell.id}
                              // direction={orderBy === headCell.id ? order : "asc"}
                              // onClick={createSortHandler(headCell.id)}
                              // onClick={() => handleSort(headCell.id)}
                              // IconComponent={UnfoldMoreIcon}
                              >
                                Client
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                size="small"
                                color="primary"
                                classes={{ root: classes.addEntryButton }}
                                onClick={() => openGroupModal()}
                              >
                                Add Entry
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupList &&
                            groupList
                              .filter(
                                row =>
                                  row.name
                                    .toUpperCase()
                                    .indexOf("".toUpperCase()) > -1 &&
                                  row.name
                                    .toLowerCase()
                                    .indexOf(keyword.toLowerCase()) > -1
                              )
                              .map((row, index) => {
                                // const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    // selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        // onClick={event => handleClick(event, row.id)}
                                        // checked={isItemSelected}
                                        // onClick={e => handleGroupSelect(e, row)}
                                        // checked={isChecked(row.id)}
                                        inputProps={{
                                          "aria-labelledby": labelId
                                        }}
                                        color="primary"
                                      />
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="left"
                                    >
                                      {row.name}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="right"
                                    >
                                      <DeleteIcon
                                        onClick={() =>
                                          handleRemoveGroup(row.id)
                                        }
                                        style={{ paddingRight: 8 }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  {errors.groupList && (
                    <Alert severity="error" className={classes.alertGroups}>
                      {errors.groupList}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
            {activeTab === 1 && (
              <>
                <Grid item xs={4}>
                  <Paper variant="outlined" style={{ padding: 12 }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        <div className={classes.checkBox}>
                          <Typography className={classes.checkBoxTitle}>
                            Select All
                          </Typography>
                          <Checkbox
                            color="primary"
                            checked={allSelected()}
                            onChange={e => handleSelectAll(e)}
                          />
                        </div>
                        {[
                          {
                            label: "Monitor Door Opening",
                            value: deviceConfig.monitordooropenning,
                            name: "monitordooropenning"
                          },
                          {
                            label: "Check Homebase Status",
                            value: deviceConfig.checkhomebasestatus,
                            name: "checkhomebasestatus"
                          }
                        ].map(checkbox => (
                          <div
                            key={checkbox.label}
                            className={classes.checkBox}
                          >
                            <Typography className={classes.checkBoxTitle}>
                              {checkbox.label}
                            </Typography>
                            <Checkbox
                              color="primary"
                              checked={checkbox.value}
                              onClick={e => {
                                handleConfig(e);
                              }}
                              value={checkbox.value}
                              name={checkbox.name}
                            />
                          </div>
                        ))}

                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <div className={classes.checkBox}>
                              <Typography className={classes.checkBoxTitle}>
                                Has Fuel Sensor
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={deviceConfig.hasFuelSensor}
                                onChange={e => {
                                  handleConfig(e);
                                }}
                                value={deviceConfig.hasFuelSensor}
                                name="hasFuelSensor"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px",
                                  fontSize: 14
                                }}
                              >
                                +&#9651;
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  name="fuelrefill"
                                  value={deviceConfig.fuelrefill}
                                  onChange={e => handleConfig(e)}
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  L
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px",
                                  fontSize: 14
                                }}
                              >
                                +&#9651;
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  name="fuelpilferage"
                                  value={deviceConfig.fuelpilferage}
                                  onChange={e => handleConfig(e)}
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  L
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <div className={classes.checkBox}>
                              <Typography className={classes.checkBoxTitle}>
                                Has Temperature Sensor
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={deviceConfig.hastempsensor}
                                onChange={e => {
                                  handleConfig(e);
                                }}
                                name="hastempsensor"
                                value={deviceConfig.hastempsensor}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 10 }}>
                                  Min
                                </Typography>
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  value={deviceConfig.mintemp}
                                  name="mintemp"
                                  onChange={e => handleConfig(e)}
                                  // variant="outlined"
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  &#176;C
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ paddingBottom: "8px" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 10 }}>
                                  Max
                                </Typography>
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  value={deviceConfig.maxtemp}
                                  name="maxtemp"
                                  onChange={e => handleConfig(e)}
                                  // variant="outlined"
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  &#176;C
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="speedlimit"
                    label="Speed Limit"
                    value={deviceConfig.speedlimit ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "500", step: "1" }}
                  />
                  {errors.speedlimit && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.speedlimit}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="highwayspeedlimit"
                    label="Highway Speed Limit"
                    value={deviceConfig.highwayspeedlimit ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "500", step: "1" }}
                  />
                  {errors.highwayspeedlimit && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.highwayspeedlimit}
                    </Alert>
                  )}
                  <InputLabel
                    className={classes.customTextFieldLabel}
                    required
                  >
                    Harsh Acceleration(HA)
                  </InputLabel>
                  <Grid container>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Min
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1
                          }}
                          name="ha_min"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.ha_min ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Max
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1
                          }}
                          name="ha_max"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.ha_max ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {(errors.ha_min || errors.ha_max) && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.ha_min || errors.ha_max}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="maxvalidspeed"
                    label="Maximum Valid Speed"
                    value={deviceConfig.maxvalidspeed ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "500", step: "1" }}
                  />
                  {errors.maxvalidspeed && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.maxvalidspeed}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="warningspeed"
                    label="Warning Speed"
                    value={deviceConfig.warningspeed ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{ min: "0", max: "500", step: "1" }}
                  />
                  {errors.warningspeed && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.warningspeed}
                    </Alert>
                  )}
                  <InputLabel
                    className={classes.customTextFieldLabel}
                    required
                  >
                    Harsh Braking(HB)
                  </InputLabel>
                  <Grid container>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Min
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1
                          }}
                          name="hb_min"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.hb_min ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Max
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1
                          }}
                          name="hb_max"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.hb_max ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {(errors.hb_min || errors.hb_max) && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.hb_min || errors.hb_max}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  textAlign: "center"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleOpen("save")}
                >
                  Save Details
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
        </div>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save device?"
        />

        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <PairVehicle
          toggle={vehicleModal}
          closeVehicleModal={closeVehicleModal}
          setVehicleInfo={(e) => {
            setVehicleInfo(e);
            closeVehicleModal();
          }}
        />
        <SelectGroup
          toggle={groupModal}
          closeGroupModal={closeGroupModal}
          handleGroupList={handleGroupList}
          groupList={groupList}
        />
      </div>
    </AccessControl>
  );
}
