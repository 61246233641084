/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef
} from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Link
  // Chip
} from "@material-ui/core";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete as DeleteIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
  Cancel as CancelIcon,
  Close as CloseIcon,
  // KeyboardArrowLeft as KeyboardArrowLeftIcon,
  // KeyboardArrowRight as KeyboardArrowRightIcon,
  Event,
  Help as HelpIcon
  // CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  // CheckBox as CheckBoxIcon
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import lodash from "lodash";
import Cookie from "js-cookie";
// import { Multiselect } from "multiselect-react-dropdown";
import {
  GET_REPORT_REQUESTS_QUERY,
  GET_DOWNLOAD_REPORT,
  // GET_STATUS_CODES,
  // GET_HAULER_GROUPS,
  // GET_BOOKING_GROUPS
  GET_ACL_MODULES_CLIENT
} from "../../graphql/Queries";
import { REPORT_REQUESTS_SUBSCRIPTION } from "../../graphql/Subscription";
import {
  GET_REPORT_TYPES,
  UPDATE_REPORT_REQUEST,
  DELETE_REPORT_REQUEST
} from "../../api/reportRepository";
import ConfirmationDialog from "../../utils/modal/ConfirmationDialog";
import UserContext from "../../context/UserContext";
import AccessControl from "../../utils/AccessControl";
import { ADD_REPORT_REQUEST_MUTATION } from "../../graphql/Mutations";
import Loading from "../../utils/Loading";
import AddLogs from "../../utils/functions/AddLogs";

import Header from "./common/Header";
import ButtonLink from "./common/ButtonLink";
import VehicleChipInput from "./common/VechicleChipInput";
import AlertTypeChipInput from "./common/AlertTypeChipInput";

import useFormStyles, { useFormErrorStyles } from "../../styles/useFormStyles";
import TripStatusChipInput from "./common/TripStatusChipInput";
// import Pagination from "./common/Pagination";
import ActivityOnlyInput from "./common/ActivityOnlyInput";
import VehicleSearchInput from "./common/VehicleSearchInput";
import HaulersChipInput from "./common/HaulerChipInput/HaulerChipInput";
import VehicleStatus, { vehicleStatusOptions } from "./common/VehicleStatus";
import GPSStatusChipInput, {
  defaultValue as gpsStatusDefault,
  valuesToSting
} from "./common/GPSStatusChipInput";
import ReportDelaySearchInput, {
  reportDelayOptions
} from "./common/ReportDelaySearchInput";
import IdlingDuration, { idlingDurationParser } from "./common/IdlingDuration";
import minMax from "./utils/minMax";
import FieldGroup from "./common/Fields/FieldGroup";
import Pagination from "../../utils/table/Pagination";
import useUserContext from "../../context/User/useUserContext";
import ReorderList, { parseHeader } from "./common/ReorderList";
import GeofenceChipInput from "./common/GeofenceChipInput";
import BatteryLevelInput from "./common/BatteryLevel";
import SwitchHelper from "../../utils/UI/Switch/SwitchHelper";

const moduleName = process.env.REACT_APP_REPORT_MODULE;
const MUI_PICKER_MAX_DATE = "2100/01/01";
const MUI_PICKER_MIN_DATE = "1900/01/01";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    overflow: "auto"
  },
  root: {
    margin: 0,
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    padding: 15
    // height: "calc(100% - 62px)",
    // overflowY: "auto",
    // "&::-webkit-scrollbar-track": {
    //   border: "5px solid #f8f8f8"
    // },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   border: "4px solid #f8f8f8",
    //   background: "#969696"
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   border: "5px solid #f8f8f8"
    // },
  },
  paper: {
    width: "100%",
    // margin: theme.spacing(2),
    padding: "18px !important",
    minHeight: "85vh",
    height: "auto",
    position: "relative",
    // maxHeight: "85vh",
    borderRadius: "15px",
    background: "#fff",
    boxShadow: "0px 0px 5px #cccccc73",
    marginRight: "15px !important",
    boxSizing: "border-box",
    flex: 2.5,
    display: "flex",
    flexDirection: "column"
  },
  reportsWrapper: {
    flex: 10.5,
    width: "100%",
    background: "#fff",
    boxShadow: "0px 0px 5px #cccccc73",
    // margin: theme.spacing(2),
    // marginLeft: 0,
    // marginRight: 0,
    // height: "85vh",
    height: "auto",
    minHeight: "85vh",
    position: "relative",
    // maxHeight: "85vh",
    borderRadius: "15px",
    padding: "0px !important",
    "& .MuiTableCell-stickyHeader": {
      background: "#fff",
      padding: "8px 5px"
    }
  },
  table: {
    minWidth: 750
  },
  deleteColumnHeight: ({ selected }) =>
    selected
      ? {
          background: "#5d5d5d0f",
          padding: "15px 0px"
        }
      : {},
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  filterInput: {
    // marginTop: "20px",
    minWidth: "100%",
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    }
  },
  iconStyle: { width: 16, height: 16 },
  generateButton: {
    borderRadius: "25px",
    position: "relative",
    width: "100%"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const data = stabilizedThis.map(el => el[0]);
  return data;
}

const headCells = [
  {
    id: "report_type",
    numeric: false,
    disablePadding: true,
    label: "Report"
  },
  { id: "startstamp", numeric: false, disablePadding: false, label: "From" },
  { id: "endstamp", numeric: false, disablePadding: false, label: "To" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Requested"
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    label: "Requested By"
  },
  { id: "status_code", numeric: false, disablePadding: false, label: "Status" },
  { id: "id", numeric: false, disablePadding: false, label: "Action" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount && editMode}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all reports" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            align="center"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const Create = () => {
  const STATUS_PENDING = process.env.REACT_APP_REPORT_STATUS_PENDING;
  const STATUS_ERROR = process.env.REACT_APP_REPORT_STATUS_ERROR;
  const STATUS_COMPLETED = process.env.REACT_APP_REPORT_STATUS_COMPLETED;
  const STATUS_ONGOING = process.env.REACT_APP_REPORT_STATUS_ONGOING;

  const ERROR_LOGS = process.env.REACT_APP_REPORT_TYPE_ERROR_LOGS;
  const GPS_STATUS = process.env.REACT_APP_REPORT_TYPE_GPS_STATUS;
  const TRIP_SUMMARY = process.env.REACT_APP_REPORT_TYPE_TRIP_SUMMARY;
  const VEHICLE_ALERT = process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT;
  const VEHICLE_SUMMARY = process.env.REACT_APP_REPORT_TYPE_VEHICLE_SUMMARY;
  const VEHICLE_UTILIZATION =
    process.env.REACT_APP_REPORT_TYPE_VEHICLE_UTILIZATION;
  const FULL_MOVEMENT = process.env.REACT_APP_REPORT_TYPE_FULL_MOVEMENT;
  const ALERT_COUNT_DASHBOARD =
    process.env.REACT_APP_REPORT_TYPE_ALERT_COUNT_DASHBOARD;
  const VEHICLE_STATUS = process.env.REACT_APP_REPORT_TYPE_VEHICLE_STATUS;
  const STANDARD_IDLING = process.env.REACT_APP_REPORT_TYPE_STANDARD_IDLING;

  const CICO = process.env.REACT_APP_REPORT_TYPE_CICO;
  const CICO_NO_TRIP = process.env.REACT_APP_REPORT_TYPE_CICO_NO_TRIP;
  const IGNITION = process.env.REACT_APP_REPORT_TYPE_IGNITION;
  const COMPLETE_LOG = process.env.REACT_APP_REPORT_TYPE_COMPLETE_LOG;
  const SPEEDING = process.env.REACT_APP_REPORT_TYPE_SPEEDING;
  const GEOFENCE_IN_AND_OUT =
    process.env.REACT_APP_REPORT_TYPE_GEOFENCE_IN_AND_OUT;
  const FUEL_CONSUMPTION = process.env.REACT_APP_REPORT_TYPE_FUEL_CONSUMPTION;
  const DLTB_IDLING = process.env.REACT_APP_REPORT_DLTB_IDLING;
  const SPEEDING_DURATION = process.env.REACT_APP_REPORT_SPEEDING_DURATION;
  const UNCATEGORIZED = process.env.REACT_APP_REPORT_UNCATEGORIZED;
  const CONVAN_INVENTORY = process.env.REACT_APP_REPORT_CONVAN_INVENTORY;
  const FUEL_REPORT = process.env.REACT_APP_REPORT_FUEL_REPORT;
  const CICO_HOMEBASE = process.env.REACT_APP_REPORT_CICO_HOMEBASE;

  // const user = JSON.parse(Cookie.get("user"));
  const formStyles = useFormStyles();
  const formErrorStyles = useFormErrorStyles();
  const [reportType, setReportType] = useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  // const [haulerInput, setHaulerInput] = useState(false);
  const [reportRequest, setReportRequest] = useState({
    report_type: -1,
    date_reference: "",
    date_range: "",
    hauler_option: "current_day"
  });

  // const [tripStatusInputValue, setTripStatusInputValue] = useState([]);

  // const [selectedStatus, setSelectedStatus] = useState([]);

  const [reportTypeError, setReportTypeError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [vehicleError, setVehicleError] = useState(false);
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(true);
  const [selected, setSelected] = useState([]);

  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);

  const [rows, setRows] = useState([]);
  const [error, setErrors] = useState("");
  const [chosenRow, setChosenRow] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [confirmationModal, toggleConfirmationModal] = useState(false);
  const [unlinkDialog, setDeleteDialog] = useState(false);
  const [isDateRangeDisabled, setIsDateRangeDisabled] = useState(false);
  const userDetails = useUserContext();
  const isWTIAdmin =
    +userDetails.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const [selectedHaulers, setSelectedHaulers] = useState(() => {
    if (+userDetails.user_level_id === +process.env.REACT_APP_WTI_ADMIN)
      return [];
    return userDetails.group_ids;
  });
  const [isLogitics, setIsLogitics] = useState(false);

  useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: userDetails?.client?.id },
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setIsLogitics(hasTripsPrebooking);
      }
    },
    onError: err => console.log(err),
    skip: isWTIAdmin || VEHICLE_STATUS !== reportRequest.report_type,
    // fetchPolicy: "cache-and-network"
  });
  const classes = useStyles({ selected: !!selected.length });

  const getVariables = React.useCallback(() => {
    const variables = {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      filter: [
        { field: "status_code_id", value: STATUS_PENDING },
        { field: "status_code_id", value: STATUS_ERROR },
        { field: "status_code_id", value: STATUS_ONGOING },
        { field: "status_code_id", value: STATUS_COMPLETED }
      ],
      dateRange: {
        field: "report_requests.created",
        start: `${moment(new Date())
          .subtract(6, "days")
          .format("YYYY-MM-DD")} 00:00:00`,
        end: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
      },
      orderBy: [{ field: orderBy, direction: order }]
    };

    return variables;
  }, [orderBy, order, rowsPerPage, page]);

  // Refs
  const haulerRef = useRef([]);
  const vehiclesRef = useRef([0]);
  const alertTypesRef = useRef([0]);
  const tripStatusRef = useRef([]);
  const activityOnlyRef = useRef(false);
  const vehicleStatusRef = useRef([]);
  const gpsStatusRef = useRef(gpsStatusDefault);
  const reportDelayRef = useRef(reportDelayOptions[0]);
  const idlingDurationRef = useRef([0]);
  const reorderRef = useRef([]);
  const geofencesRef = useRef([]);
  const batteryLevelRef = useRef("All");

  const [
    getDownloadReport,
    { data: base64Data, loading: base64Loading }
  ] = useLazyQuery(GET_DOWNLOAD_REPORT, {
    fetchPolicy: "network-only"
  });

  function saveAsXlsxFile(base64Input, fileName) {
    var mediaType =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var userInput = base64Input;
    var a = document.createElement("a");
    const file = mediaType + userInput;
    a.href = file;
    a.download = fileName;
    a.click();
  }

  useEffect(() => {
    if (!base64Loading && base64Data) {
      saveAsXlsxFile(
        base64Data.download_report.content,
        base64Data.download_report.fileName
      );
    }
  }, [base64Loading, base64Data]);

  const getFormFields = keyword => {
    const selectedDate = `, Date Range: ${reportRequest.hauler_option}, from: ${dateFrom} to: ${dateTo}`;
    const selectedTripStatus = `, Trip Status: ${tripStatusRef.current
      .map(status => status.name)
      .toString()}`;
    const selectedHauler = `, Hauler: ${haulerRef.current
      .map(hauler => hauler.group_name)
      .join(", ") || "All"}`;
    const selectedVehicle = `, Plate Number: ${
      vehiclesRef.current[0] === 0
        ? "All"
        : vehiclesRef.current.map(i => i.plateno).join(", ") || "All"
    }`;
    const selectedAlerts = `, Alert Type: ${
      alertTypesRef.current[0] === 0
        ? "All"
        : alertTypesRef.current.map(i => i.code).join(", ")
    }`;
    const geofences = `, Geofences: ${
      geofencesRef.current.length === 0
        ? "All"
        : geofencesRef.current.map(g => g.name).join(", ")
    }`;
    const activityOnly = `, Activity Only: ${activityOnlyRef.current}`;
    const tempVehicleStatus = vehicleStatusRef.current.map(
      ({ label }) => label
    );
    const vehicleStatusText =
      tempVehicleStatus.length === vehicleStatusOptions.length
        ? "All"
        : tempVehicleStatus.join(", ");
    const vehicleStatus = `, Vehicle Status: ${vehicleStatusText}`;
    const gpsStatus = `, GPS Status: ${valuesToSting(gpsStatusRef.current)}`;
    const reportDelay = `, Reporting delay: ${reportDelayRef.current}`;
    const idlingDuration = `, Idling Duration: ${idlingDurationRef.current}`;
    const batteryLevel = `, Battery level: ${batteryLevelRef.current}`;

    switch (reportRequest.report_type) {
      case GPS_STATUS:
        return keyword.concat(selectedHauler);
      case TRIP_SUMMARY:
        return keyword.concat(
          selectedDate,
          selectedHauler,
          selectedTripStatus,
          gpsStatus
        );
      case VEHICLE_ALERT:
        return keyword.concat(
          selectedDate,
          selectedHauler,
          selectedAlerts,
          selectedVehicle
        );
      case FULL_MOVEMENT:
        return keyword.concat(
          selectedDate,
          selectedHauler,
          selectedVehicle,
          activityOnly
        );
      case ALERT_COUNT_DASHBOARD:
        return keyword.concat(
          selectedAlerts,
          selectedHauler,
          selectedVehicle,
          selectedDate
        );
      case VEHICLE_STATUS:
        return keyword.concat(
          selectedHauler,
          selectedVehicle,
          vehicleStatus,
          gpsStatus,
          reportDelay
        );
      case STANDARD_IDLING:
        return keyword.concat(
          selectedHauler,
          selectedVehicle,
          selectedDate,
          idlingDuration
        );
      case VEHICLE_UTILIZATION:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case CICO:
        return keyword.concat(
          selectedHauler,
          selectedVehicle,
          // geofences,
          selectedDate
        );
      case CICO_NO_TRIP:
        return keyword.concat(
          selectedHauler,
          selectedVehicle,
          geofences,
          selectedDate
        );
      case IGNITION:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case COMPLETE_LOG:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case SPEEDING:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case SPEEDING_DURATION:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case GEOFENCE_IN_AND_OUT:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case FUEL_CONSUMPTION:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case DLTB_IDLING:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case CONVAN_INVENTORY:
        return keyword.concat(
          selectedHauler,
          // selectedVehicle,
          batteryLevel,
          selectedDate
        );
      case UNCATEGORIZED:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case FUEL_REPORT:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      case CICO_HOMEBASE:
        return keyword.concat(selectedHauler, selectedVehicle, selectedDate);
      default:
        return keyword;
    }
  };

  const [addRequestMutation] = useMutation(ADD_REPORT_REQUEST_MUTATION, {
    refetchQueries: () => {
      return [
        {
          query: GET_REPORT_REQUESTS_QUERY,
          variables: getVariables()
        }
      ];
    }
  });

  const {
    data: requests_data,
    refetch,
    subscribeToMore,
    loading: reportRequestLoading
  } = useQuery(GET_REPORT_REQUESTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: getVariables(),
    pollInterval: 60 * 1000
  });

  useEffect(() => {
    if (requests_data) {
      // mutate data if you need to
      const report_requests = requests_data?.get_report_requests?.report_requests.map(
        el => {
          return {
            id: el.id,
            report_type: el.report_type,
            date_from: el.startstamp,
            date_to: el.endstamp,
            date_requested: el.created,
            requested_by: el.user_name,
            status: el.status_code,
            status_code_id: el.status_code_id,
            filename: el.filename
          };
        }
      );
      setRows(report_requests || []);
      setTotalRows(requests_data?.get_report_requests?.total ?? 0);
    }
  }, [requests_data]);

  const handlePageChange = nextPage => {
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
    }
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const subscribeToNewRequests = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: REPORT_REQUESTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newReportRequest = subscriptionData.data.report_request_updated;
        const isSameUser = newReportRequest.user_name === userDetails.username;
        const isExisting = prev.get_report_requests.report_requests.findIndex(
          rr => rr.id === newReportRequest.id
        );

        if (!isSameUser) return;
        let prevData = { ...prev };
        if (isExisting !== -1) {
          const updatedReportRequests = [
            ...prevData.get_report_requests.report_requests
          ];
          updatedReportRequests[isExisting] = newReportRequest;
          prevData = {
            ...prevData,
            get_report_requests: {
              ...prevData.get_report_requests,
              report_requests: updatedReportRequests
            }
          };
        } else {
          prevData = {
            ...prevData,
            get_report_requests: {
              ...prevData.get_report_requests,
              report_requests: [
                ...prevData.get_report_requests.report_requests,
                newReportRequest
              ]
            }
          };
        }

        prevData = {
          ...prevData,
          get_report_requests: {
            ...prevData.get_report_requests,
            count: prevData.get_report_requests.report_requests.length
          }
        };

        const report_requests = prevData.get_report_requests.report_requests.map(
          el => {
            return {
              id: el.id,
              report_type: el.report_type,
              date_from: el.startstamp,
              date_to: el.endstamp,
              date_requested: el.created,
              requested_by: el.user_name,
              status: el.status_code,
              status_code_id: el.status_code_id,
              filename: el.filename
            };
          }
        );
        setRows(report_requests);

        return prevData;
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToNewRequests();
    return () => unsubscribe();
  }, [subscribeToNewRequests]);

  function openConfirmationModal() {
    toggleConfirmationModal(true);
  }

  const handleChange = async event => {
    const data = { ...reportRequest };
    if (event.target.name === "report_type") {
      // if (event.nativeEvent.target.textContent === "Vehicle Summary Report") {
      if (event.target.value === VEHICLE_SUMMARY) {
        setIsDateRangeDisabled(true);
      } else {
        setIsDateRangeDisabled(false);
      }
      if ([GPS_STATUS].includes(event.target.value)) {
        setDateFrom(null);
        setDateTo(null);
      } else {
        setDateFrom(
          moment()
            .startOf("day")
            .format("YYYY-MM-DD HH:mm")
        );
        setDateTo(
          moment()
            .endOf("day")
            .format("YYYY-MM-DD HH:mm")
        );
      }
      data.date_range = "current_day";
      data.hauler_option = "current_day";
    }
    if (data[event.target.name] !== event.target.value) {
      setIsGenerateDisabled(false);
    }
    data[event.target.name] = event.target.value;

    if (event.target.name) {
      switch (event.target.value) {
        case "current_day":
          setDateFrom(
            moment()
              .startOf("day")
              .format("YYYY-MM-DD HH:mm")
          );
          setDateTo(
            moment()
              .endOf("day")
              .format("YYYY-MM-DD HH:mm")
          );
          break;
        case "past_7_days":
          setDateFrom(
            moment()
              .subtract(7, "day")
              .format("YYYY-MM-DD HH:mm")
          );
          setDateTo(moment().format("YYYY-MM-DD HH:mm"));
          break;
        case "past_5_days":
          setDateFrom(
            moment()
              .subtract(5, "days")
              .format("YYYY-MM-DD HH:mm")
          );
          setDateTo(moment().format("YYYY-MM-DD HH:mm"));
          break;
        case "past_30_days":
          setDateFrom(
            moment()
              .subtract(30, "day")
              .format("YYYY-MM-DD HH:mm")
          );
          setDateTo(moment().format("YYYY-MM-DD HH:mm"));
          break;
        default:
          break;
      }
    }
    setReportRequest(data);
  };

  const fetchReportTypes = async () => {
    const report_types = await GET_REPORT_TYPES();
    setReportType(report_types);
  };

  let action;
  let title;
  let content;
  let status_id;
  switch (chosenRow.status) {
    case "Cancelled":
      action = "Regenerate";
      title = "Generate again?";
      content = "Are you sure you want to generate this report again?";
      /* processing */
      status_id = 25;
      break;
    case "Ongoing":
      action = "Cancelled";
      title = "Cancel?";
      content = "Are you sure you want to cancel this report?";
      /* cancelled */
      status_id = 13;
      break;

    case "Completed":
      action = "Deleted";
      title = "Delete?";
      content = "Are you sure you want to delete this report?";
      break;

    default:
      action = "Cancelled";
      title = "Cancel?";
      content = "Are you sure you want to cancel this report?";
      status_id = 13;
      break;
  }

  const updateReportRequest = async () => {
    const data = {
      id: chosenRow.id,
      status_code_id: status_id
    };

    UPDATE_REPORT_REQUEST(data).then(response => {
      const { update_report_request } = response.data;
      if (update_report_request.success) {
        refetch(getVariables());
        AddLogs(
          moduleName,
          "update_report",
          `Report ID: ${chosenRow.id}, ${action} ${chosenRow.report_type} report`
        );
      }
    });
  };

  const deleteReportRequest = async () => {
    const data = {
      id: chosenRow.id
    };
    const res = await DELETE_REPORT_REQUEST(data);
    if (res.success) {
      AddLogs(
        moduleName,
        "delete_report",
        `Report ID: ${chosenRow.id}, Deleted ${chosenRow.report_type} report`
      );
      refetch(getVariables());
      setSelected([]);
    }
  };
  const deleteReportRequests = async () => {
    const data = {
      id: selected
    };
    const res = await DELETE_REPORT_REQUEST(data);
    if (res.success) {
      AddLogs(
        moduleName,
        "delete_report",
        `Report ID: ${selected.toString()}, Deleted ${
          selected.length
        } reports`
      );
      refetch(getVariables());
      setSelected([]);
    }
  };
  useEffect(() => {
    fetchReportTypes();
  }, []);

  const selectedReport = React.useMemo(() => {
    if (isLogitics) setIsLogitics(false);
    const report_type = reportType.filter(
      item => item.id === reportRequest.report_type
    )[0];
    if (!report_type) return [];
    return {
      ...report_type,
      headers: parseHeader(report_type.headers)
    };
  }, [reportRequest.report_type]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpenDialog = () => {
    setDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialog(false);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < rows.length) {
      const displayedData = rows.map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = rows.map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      rows.map(n => tempSelected.push(n.id));
      setSelected(lodash.uniq(tempSelected));
      return;
    }
    deselect();
  };

  useEffect(() => {
    if (isGenerateDisabled && reportRequest.report_type !== -1) {
      setTimeout(() => {
        setIsGenerateDisabled(false);
      }, 5000);
    }
  }, [isGenerateDisabled]);

  const [alertTypes, setAlertTypes] = useState([]);
  const onSelectedAlertTypeChanged = newItems => {
    if ([ALERT_COUNT_DASHBOARD].includes(reportRequest.report_type)) {
      setAlertTypes([...newItems]);
    }
  };

  const handleIsLogisticsChange = e => {
    setIsLogitics(e.target.checked);
  };

  return (
    <React.Fragment>
      {reportRequestLoading && <Loading />}
      <AccessControl resource="/reports/" process="reports">
        <div className={classes.wrapper}>
          <Header>
            <div style={{ flex: 1 }}>
              <ButtonLink
                text="Automated Reports"
                resource="/reports/automated/"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" style={{ marginRight: 32 }}>
                As of {moment().format("MMM D YYYY HH:mm A")}
              </Typography>
              <Pagination
                rowsPerPage={rowsPerPage}
                total={Math.ceil(totalRows / rowsPerPage)}
                currentPage={page}
                onRowsPerPageChange={n => {
                  setRowsPerPage(n);
                }}
                onPageChange={n => setPage(n)}
                onPageUp={n => setPage(n)}
                onPageDown={n => setPage(n)}
              />
            </div>
          </Header>
          <div className={classes.root} style={{ background: "#F8F8F8" }}>
            <div className={classes.paper}>
              {/* ADD REPORT */}
              <div style={{ position: "relative", height: "100%" }}>
                <Typography
                  variant="h6"
                  component="span"
                  style={{
                    marginBottom: "20px",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    letterSpacing: "1.25px",
                    display: "block"
                  }}
                  gutterBottom
                >
                  REPORT
                </Typography>
                <FormControl
                  className={classes.filterInput}
                  classes={{ root: formStyles.formRoot }}
                >
                  <InputLabel>Report Type</InputLabel>
                  <Select
                    classes={{ root: formStyles.selectRoot }}
                    id="report-type"
                    name="report_type"
                    value={reportRequest.report_type}
                    onChange={handleChange}
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    <MenuItem value={-1} disabled>
                      Select Report type
                    </MenuItem>
                    {reportType.map((type, index) => {
                      const reportKey = `${type.name}-${index}`;
                      return (
                        <MenuItem key={reportKey} value={type.id}>
                          {`${type.name} Report`}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  {reportTypeError && reportRequest.report_type === -1 && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      Please select report type
                    </Alert>
                  )}
                </FormControl>
                <FieldGroup reportType={reportRequest.report_type}>
                  <FieldGroup.Field>
                    <HaulersChipInput
                      ref={haulerRef}
                      setSelectedHaulers={setSelectedHaulers}
                    />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <AlertTypeChipInput
                      values={alertTypes}
                      ref={alertTypesRef}
                      params={{ haulers: selectedHaulers }}
                      onSelectedAlertTypeChanged={onSelectedAlertTypeChanged}
                    />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <VehicleChipInput
                      ref={vehiclesRef}
                      group_ids={selectedHaulers}
                    />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <VehicleSearchInput
                      ref={vehiclesRef}
                      group_ids={selectedHaulers}
                      vehicleError={vehicleError}
                    />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <GeofenceChipInput
                      ref={geofencesRef}
                      groups={selectedHaulers}
                    />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <>
                      {(VEHICLE_STATUS === reportRequest.report_type && isWTIAdmin) && (
                        <SwitchHelper
                          handleChangeHandler={handleIsLogisticsChange}
                          checkedValue={isLogitics}
                          label="Use Logistics Status"
                          name="is_logistics"
                          tooltipPlacement="top-start"
                          tooltipTitle={
                            <>
                              Toggle on: report that will be generated will
                              display logistics (Booked/Available) status <br />
                              <br />
                              Toggle off: report that will be based on
                              non-logistics (Active) status
                            </>
                          }
                          icon={<HelpIcon className={classes.helpIcon} />}
                          customStyle={{ padding: 0, margin: "0 0 24px 0" }}
                        />
                      )}
                      <VehicleStatus
                        ref={vehicleStatusRef}
                        isLogitics={{ isTriggered: true, value: isLogitics }}
                      />
                    </>
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <GPSStatusChipInput ref={gpsStatusRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <ReportDelaySearchInput ref={reportDelayRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <TripStatusChipInput ref={tripStatusRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <ActivityOnlyInput ref={activityOnlyRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <IdlingDuration ref={idlingDurationRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <BatteryLevelInput ref={batteryLevelRef} />
                  </FieldGroup.Field>
                  <FieldGroup.Field>
                    <ReorderList
                      // initialValues={[
                      //   ["Field 1", "Field 2", "Field 3"],
                      //   ["Field 1", "Field 2", "Field 3"].reverse()
                      // ]}
                      ref={reorderRef}
                      initialValues={selectedReport.headers}
                      nonpermanentItems={alertTypes}
                      onSelectedAlertTypeChanged={onSelectedAlertTypeChanged}
                    />
                  </FieldGroup.Field>
                </FieldGroup>
                {[
                  ERROR_LOGS,
                  TRIP_SUMMARY,
                  VEHICLE_ALERT,
                  VEHICLE_SUMMARY,
                  VEHICLE_UTILIZATION,
                  FULL_MOVEMENT,
                  ALERT_COUNT_DASHBOARD,
                  STANDARD_IDLING,
                  CICO,
                  CICO_NO_TRIP,
                  IGNITION,
                  COMPLETE_LOG,
                  SPEEDING,
                  GEOFENCE_IN_AND_OUT,
                  FUEL_CONSUMPTION,
                  DLTB_IDLING,
                  SPEEDING_DURATION,
                  FUEL_REPORT,
                  CICO_HOMEBASE
                ].includes(reportRequest.report_type) && (
                  <FormControl
                    className={classes.filterInput}
                    classes={{ root: formStyles.formRoot }}
                  >
                    <InputLabel>Date Range</InputLabel>
                    <Select
                      classes={{ root: formStyles.selectRoot }}
                      id="hauler_option"
                      name="hauler_option"
                      value={reportRequest.hauler_option}
                      onChange={handleChange}
                      label="Date Range"
                      style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                      disabled={isDateRangeDisabled}
                    >
                      <MenuItem value="current_day">Current day</MenuItem>
                      {VEHICLE_ALERT === reportRequest.report_type && (
                        <MenuItem value="past_5_days">Past 5 days</MenuItem>
                      )}
                      {VEHICLE_ALERT !== reportRequest.report_type && (
                        <MenuItem value="past_7_days">Past 7 days</MenuItem>
                      )}
                      {VEHICLE_ALERT !== reportRequest.report_type && (
                        <MenuItem value="past_30_days">Past 30 days</MenuItem>
                      )}
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {(reportRequest.hauler_option === "custom" ||
                  VEHICLE_STATUS === reportRequest.report_type) &&
                reportRequest.report_type !== GPS_STATUS &&
                reportRequest.report_type !== VEHICLE_SUMMARY ? (
                  <Fragment>
                    <FormControl classes={{ root: formStyles.formRoot }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          classes={{ root: formStyles.textFieldRoot }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          disableFuture
                          format="MM/DD/YYYY HH:mm"
                          className={classes.filterInput}
                          id="date-from"
                          name="date_from"
                          value={dateFrom}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Event fontSize="small" />
                              </InputAdornment>
                            ),
                            name: "expiration"
                          }}
                          placeholder="Select Date/Time"
                          label={
                            reportRequest.report_type === VEHICLE_STATUS
                              ? "Date"
                              : "Start"
                          }
                          showTodayButton
                          todayLabel="NOW"
                          onKeyDown={e => e.preventDefault()}
                          onChange={date => {
                            setIsGenerateDisabled(false);
                            if (
                              reportRequest.report_type === VEHICLE_ALERT ||
                              reportRequest.report_type ===
                                ALERT_COUNT_DASHBOARD ||
                              reportRequest.report_type === STANDARD_IDLING ||
                              reportRequest.report_type ===
                                VEHICLE_UTILIZATION ||
                              reportRequest.report_type === CICO ||
                              reportRequest.report_type === CICO_NO_TRIP ||
                              reportRequest.report_type === IGNITION ||
                              reportRequest.report_type === COMPLETE_LOG ||
                              reportRequest.report_type === SPEEDING ||
                              reportRequest.report_type === GEOFENCE_IN_AND_OUT
                            ) {
                              setDateFrom(
                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                              );
                              setDateTo(() => {
                                const next_date = moment(date).add(
                                  minMax[reportRequest.report_type]
                                    .additional_days,
                                  "days"
                                );

                                if (next_date.diff(moment(), "days") <= 0) {
                                  return next_date.format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  );
                                }

                                return moment()
                                  .endOf("days")
                                  .format("YYYY-MM-DD HH:mm:ss");
                              });
                            } else {
                              setDateFrom(
                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                              );
                            }
                            // }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {fromDateError && !dateFrom && (
                        <Alert
                          severity="error"
                          classes={{
                            root: formErrorStyles.root,
                            standardError: formErrorStyles.standardError,
                            icon: formErrorStyles.icon
                          }}
                        >
                          Please select date
                        </Alert>
                      )}
                    </FormControl>
                    {reportRequest.report_type !== VEHICLE_STATUS && (
                      <FormControl classes={{ root: formStyles.formRoot }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDateTimePicker
                            classes={{ root: formStyles.textFieldRoot }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            format="MM/DD/YYYY HH:mm"
                            disableFuture
                            className={classes.filterInput}
                            id="date-to"
                            name="date_to"
                            value={dateTo}
                            minDate={
                              reportRequest.report_type === VEHICLE_ALERT ||
                              reportRequest.report_type ===
                                ALERT_COUNT_DASHBOARD ||
                              reportRequest.report_type === STANDARD_IDLING ||
                              reportRequest.report_type ===
                                VEHICLE_UTILIZATION ||
                              reportRequest.report_type === CICO ||
                              reportRequest.report_type === CICO_NO_TRIP ||
                              reportRequest.report_type === IGNITION ||
                              reportRequest.report_type === COMPLETE_LOG ||
                              reportRequest.report_type === SPEEDING ||
                              reportRequest.report_type === GEOFENCE_IN_AND_OUT
                                ? moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
                                : MUI_PICKER_MIN_DATE
                            }
                            maxDate={
                              reportRequest.report_type === VEHICLE_ALERT ||
                              reportRequest.report_type ===
                                ALERT_COUNT_DASHBOARD ||
                              reportRequest.report_type === STANDARD_IDLING ||
                              reportRequest.report_type ===
                                VEHICLE_UTILIZATION ||
                              reportRequest.report_type === CICO ||
                              reportRequest.report_type === CICO_NO_TRIP ||
                              reportRequest.report_type === IGNITION ||
                              reportRequest.report_type === COMPLETE_LOG ||
                              reportRequest.report_type === SPEEDING ||
                              reportRequest.report_type === GEOFENCE_IN_AND_OUT
                                ? moment(dateFrom)
                                    .add(
                                      minMax[reportRequest.report_type]
                                        .additional_days,
                                      "days"
                                    )
                                    .format("YYYY-MM-DD HH:mm:ss")
                                : MUI_PICKER_MAX_DATE
                            }
                            minDateMessage="Date from cannot exceed 'Date to'"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Event />
                                </InputAdornment>
                              ),
                              name: "expiration"
                            }}
                            placeholder="Select Date/Time"
                            label="End"
                            showTodayButton
                            todayLabel="NOW"
                            onKeyDown={e => e.preventDefault()}
                            onChange={date => {
                              setIsGenerateDisabled(false);
                              if (
                                reportRequest.report_type === VEHICLE_ALERT ||
                                reportRequest.report_type ===
                                  ALERT_COUNT_DASHBOARD
                              ) {
                                setDateTo(() => {
                                  const from = moment(dateFrom);
                                  const maxFrom = from.add(
                                    minMax[reportRequest.report_type]
                                      .additional_days,
                                    "days"
                                  );
                                  const nextTo = moment(date);
                                  const valid =
                                    nextTo.diff(maxFrom, "days") <= 0;
                                  if (valid) {
                                    return moment(date).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    );
                                  }
                                  return moment(maxFrom)
                                    .endOf("days")
                                    .format("YYYY-MM-DD HH:mm:ss");
                                });
                              } else {
                                setDateTo(date);
                              }
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {toDateError && !dateTo && (
                          <Alert
                            severity="error"
                            classes={{
                              root: formErrorStyles.root,
                              standardError: formErrorStyles.standardError,
                              icon: formErrorStyles.icon
                            }}
                          >
                            Please select date
                          </Alert>
                        )}
                      </FormControl>
                    )}

                    <Collapse in={!!error}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setErrors("");
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {error}
                      </Alert>
                    </Collapse>
                  </Fragment>
                ) : (
                  error && (
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                        />
                      }
                    >
                      {error}
                    </Alert>
                  )
                )}
              </div>
              <Button
                fullWidth
                disabled={
                  moment(dateFrom).diff(moment(dateTo)) > 0 ||
                  isGenerateDisabled
                }
                className={classes.generateButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  const status_ids = tripStatusRef.current.map(status =>
                    parseInt(status.id, 10)
                  );
                  setVehicleError(false);

                  const variables = {
                    user_id: userDetails.id,
                    report_type_id: reportRequest.report_type,
                    startstamp:
                      reportRequest.report_type === GPS_STATUS
                        ? "1111-11-11 00:00:00"
                        : moment(dateFrom)
                            .utc()
                            .format("YYYY-MM-DD HH:mm:ss"),
                    endstamp:
                      reportRequest.report_type === GPS_STATUS
                        ? "1111-11-11 00:00:00"
                        : moment(dateTo)
                            .utc()
                            .format("YYYY-MM-DD HH:mm:ss"),
                    group_ids: haulerRef.current
                      .map(hauler => hauler.group_id)
                      .join(),
                    trip_status_id: status_ids.length ? status_ids : [0],
                    device_id:
                      vehiclesRef.current[0] === 0 ||
                      vehiclesRef.current.length === 0
                        ? [0]
                        : vehiclesRef.current.map(i => i.device_id),
                    alert_description_ids:
                      alertTypesRef.current[0] === 0
                        ? [0]
                        : alertTypesRef.current.map(i => i.id),
                    activity_only: activityOnlyRef.current ? 1 : 0,
                    vehicle_status: vehicleStatusRef.current.map(
                      ({ id }) => +id
                    ),
                    gps_status: gpsStatusRef.current,
                    reporting_delay: reportDelayRef.current,
                    duration: idlingDurationParser(idlingDurationRef.current)
                      .val,
                    headers: JSON.stringify(reorderRef.current),
                    geofence_ids: geofencesRef.current.map(
                      geofence => geofence.id
                    ),
                    battery_level: batteryLevelRef.current,
                    use_logistics_status: isLogitics
                  };

                  if (reportRequest.report_type === GPS_STATUS) {
                    setIsGenerateDisabled(true);
                    addRequestMutation({
                      variables
                    }).then(res => {
                      const {
                        0: { name }
                      } = reportType.filter(
                        type => reportRequest.report_type === type.id
                      );
                      const reportId = res?.data?.add_report_request;
                      const keyword = `Report ID: ${reportId}, Requested ${name} report`;

                      AddLogs(
                        moduleName,
                        "request_report",
                        getFormFields(keyword)
                      );
                    });
                    return;
                  }

                  if (
                    [FULL_MOVEMENT].includes(reportRequest.report_type) &&
                    (vehiclesRef.current.length === 0 ||
                      vehiclesRef.current[0] === 0)
                  ) {
                    setVehicleError(true);
                    return;
                  }

                  if (
                    reportRequest.hauler_option === "past_30_days" ||
                    reportRequest.hauler_option === "past_7_days" ||
                    reportRequest.hauler_option === "past_5_days" ||
                    reportRequest.hauler_option === "current_day" ||
                    reportRequest.hauler_option === "custom"
                  ) {
                    if (reportRequest.report_type === -1) {
                      setReportTypeError(true);
                    } else {
                      setReportTypeError(false);
                    }
                    if (dateFrom === null || dateFrom === "") {
                      setFromDateError(true);
                    } else {
                      setFromDateError(false);
                    }

                    if (dateTo === null || dateTo === "") {
                      setToDateError(true);
                    } else {
                      setToDateError(false);
                    }

                    if (
                      !fromDateError &&
                      !toDateError &&
                      reportRequest.report_type !== -1
                    ) {
                      setIsGenerateDisabled(true);
                      addRequestMutation({
                        variables
                      }).then(res => {
                        const {
                          0: { name }
                        } = reportType.filter(
                          type => reportRequest.report_type === type.id
                        );
                        const reportId = res?.data?.add_report_request;
                        const keyword = `Report ID: ${reportId}, Requested ${name} report`;

                        AddLogs(
                          moduleName,
                          "request_report",
                          getFormFields(keyword)
                        );
                      });
                    }
                  } else {
                    if (reportRequest.report_type === -1) {
                      setReportTypeError(true);
                    } else {
                      setReportTypeError(false);
                    }

                    if (!reportRequest.date_range) {
                      setErrors("Please select date");
                    } else {
                      setErrors("");
                    }
                    if (reportRequest.report_type && reportRequest.date_range) {
                      setIsGenerateDisabled(true);
                      addRequestMutation({ variables }).then(res => {
                        const {
                          0: { name }
                        } = reportType.filter(
                          type => reportRequest.report_type === type.id
                        );

                        const reportId = res?.data?.add_report_request;
                        const keyword = `Report ID: ${reportId}, Requested ${name} report`;

                        AddLogs(
                          moduleName,
                          "request_report",
                          getFormFields(keyword)
                        );
                      });
                    }
                  }
                }}
              >
                Generate
              </Button>
            </div>
            <div className={classes.reportsWrapper}>
              <TableContainer
                style={{ maxHeight: "100%", borderRadius: "15px 15px 0px 0px" }}
              >
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="report-table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    handleSort={handleRequestSort}
                    isChecked={isChecked}
                  />
                  {/* {selected.length > 0 && ( */}
                  <tbody>
                    <tr rowSpan="2">
                      <td
                        colSpan="4"
                        align="left"
                        className={classes.deleteColumnHeight}
                      >
                        {selected.length > 0 && (
                          <span
                            style={{
                              marginLeft: 20
                            }}
                          >
                            {selected.length} reports are seleted on this page
                          </span>
                        )}
                      </td>
                      <td
                        colSpan="4"
                        align="right"
                        className={classes.deleteColumnHeight}
                      >
                        <Collapse in={!!selected.length}>
                          <Tooltip title="Delete">
                            <Button
                              onClick={() => {
                                handleOpenDialog();
                              }}
                              variant="contained"
                              color="primary"
                              style={{
                                borderRadius: "17px",
                                marginRight: 20
                              }}
                            >
                              Delete Selected
                            </Button>
                          </Tooltip>
                        </Collapse>
                      </td>
                    </tr>
                  </tbody>
                  {/* )} */}
                  <TableBody>
                    {rows ? (
                      stableSort(rows, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `entry-checkbox-${index}`;
                          const reportKey = `${row.report_type}-${index}`;
                          const root = `${process.env.PUBLIC_URL}/reports`;
                          const downloadLink = `${root}/${row.filename}`;
                          let color = "";
                          let icon = "";
                          let status = "";
                          switch (row.status) {
                            case "Cancelled":
                              color = "#ACACAC";
                              icon = (
                                <SettingsBackupRestoreIcon
                                  style={{ color: color }}
                                />
                              );
                              break;
                            case "Ongoing":
                              color = "#757575";
                              icon = <CancelIcon style={{ color: color }} />;
                              break;
                            case "Completed":
                              color = "#2286C3";
                              icon = <DeleteIcon style={{ color: color }} />;
                              break;
                            default:
                              color = "#757575";
                              icon = <CancelIcon style={{ color: color }} />;
                              break;
                          }
                          if (row.status === "Completed") {
                            status = (
                              <Button
                                style={{
                                  fontSize: "11px",
                                  letterSpacing: "1.25px",
                                  fontWeight: "600",
                                  color: "#2286C3"
                                }}
                                onClick={() => {
                                  getDownloadReport({
                                    variables: { name: row.filename }
                                  }).then(() => {
                                    AddLogs(
                                      moduleName,
                                      "download_table",
                                      `Date Ranged from ${row.date_from} to ${row.date_to}`
                                    );
                                  });
                                }}
                              >
                                Download
                              </Button>
                              // <a
                              //   href={downloadLink}
                              //   style={{ color: "#2286C3" }}
                              //   download
                              //   onClick={() => {
                              //     AddLogs(
                              //       moduleName,
                              //       "download_table",
                              //       `Date Ranged from ${row.date_from} to ${row.date_to}`
                              //     );
                              //   }}
                              // >
                              //   Download
                              // </a>
                            );
                          } else if (
                            row.status === "Ongoing" ||
                            row.status === "Pending"
                          ) {
                            status = "Preparing";
                          } else {
                            // eslint-disable-next-line prefer-destructuring
                            status = row.status;
                          }
                          return (
                            <TableRow key={reportKey}>
                              <TableCell
                                padding="checkbox"
                                onClick={event => handleClick(event, row.id)}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {row.report_type}
                              </TableCell>
                              <TableCell align="center">
                                {row.date_from.includes("1111-11-11")
                                  ? "-"
                                  : row.date_from}
                              </TableCell>
                              <TableCell align="center">
                                {row.date_to.includes("1111-11-11")
                                  ? "-"
                                  : row.date_to}
                              </TableCell>
                              <TableCell align="center">
                                {moment(row.date_requested).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {row.requested_by}
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  component="span"
                                  style={{ color: color }}
                                >
                                  {status}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    openConfirmationModal();
                                    setChosenRow(row);
                                  }}
                                >
                                  {icon}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableCell align="center" colSpan={7}>
                        <Typography variant="h5" align="center">
                          No Reports
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ConfirmationDialog
            title={title}
            content={content}
            fn={() =>
              chosenRow.status !== "Completed"
                ? updateReportRequest()
                : deleteReportRequest()
            }
            close={() => toggleConfirmationModal(null)}
            toggle={confirmationModal}
          />
          <ConfirmationDialog
            toggle={unlinkDialog}
            close={() => handleCloseDialog()}
            fn={() => deleteReportRequests()}
            title="Delete All Selected"
            content="You cannot undo the action. Do you want to continue deleting the selected items?"
          />
        </div>
      </AccessControl>
    </React.Fragment>
  );
};

export default Create;
