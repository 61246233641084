import React from "react";
import {
  InputAdornment,
  Typography
} from "@material-ui/core";
import { Item, StyledTitle, useStyles } from "./TripAllocation.styles";
import { Event } from "@material-ui/icons";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import { ReactComponent as AllocateIcon } from "../../../assets/human-dolly.svg";

export const DATE_FORMAT = "ll HH:mm";

export const DateTimeField = props => {
  const { dateTimePickerProps } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        InputLabelProps={{
          shrink: true,
          style: {
            color: "black",
            fontWeight: "bold",
            letterSpacing: 1
          }
        }}
        className={classes.dateInput}
        format={DATE_FORMAT}
        ampm={false}
        showTodayButton
        todayLabel="NOW"
        {...dateTimePickerProps}
        InputProps={{
          placeholder: "Select Date/Time",
          endAdornment: (
            <InputAdornment position="end">
              <Event fontSize="small" style={{ color: "#ccc" }} />
            </InputAdornment>
          ),
          ...dateTimePickerProps.InputProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export const DateField = props => {
  const { dateTimePickerProps } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        InputLabelProps={{
          shrink: true,
          style: {
            color: "black",
            fontWeight: "bold",
            letterSpacing: 1
          }
        }}
        className={classes.dateInput}
        format={"ll"}
        ampm={false}
        showTodayButton
        todayLabel="NOW"
        {...dateTimePickerProps}
        InputProps={{
          placeholder: "Select Date",
          endAdornment: (
            <InputAdornment position="end">
              <Event fontSize="small" style={{ color: "#ccc" }} />
            </InputAdornment>
          ),
          ...dateTimePickerProps.InputProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export const SelectedTrips = ({ trips }) => {
  const classes = useStyles();

  return (
    <Item padding={"8"} style={{ justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          gap: 12,
          paddingLeft: 14,
          alignItems: "center"
        }}
      >
        <div className={classes.iconContainer}>
          <AllocateIcon fill="white" width={"70%"} />
        </div>
        <div>
          <StyledTitle
            variant="h4"
            style={{ fontWeight: "800px", color: "#808080" }}
          >
            {trips.length}
          </StyledTitle>
          <Typography variant="subtitle2">Selected Trips</Typography>
        </div>
      </div>
    </Item>
  );
};