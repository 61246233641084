import React from "react";
import { Button } from "@material-ui/core";
import { useOnboardingContext } from "../../index";
import useStageStyles from "./Stage.styles";
import you_are_ready from "../../images/you-are-ready.svg";

const Stage = ({
  image = you_are_ready,
  title = "You are ready!",
  description = "Now that you know how things work, you may start exploring this module.",
  buttonText = "Next",
  buttonProps = {},
  renderActions
}) => {
  const { nextStep, module } = useOnboardingContext();
  const classes = useStageStyles();
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <img src={image} alt="fleet-welcome" className={classes.image} />
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </div>
      <div className={classes.footer}>
        {typeof renderActions === "function" ? (
          renderActions()
        ) : (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            className={classes.button}
            onClick={() => nextStep(module)}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Stage;
