/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import clsx from "clsx";
import {
  Toolbar,
  Typography,
  makeStyles,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
  Select,
  Paper,
  Popover,
  List,
  ListItem,
  Fab,
  Badge,
  ListItemText,
  ListItemIcon,
  CircularProgress
} from "@material-ui/core";
import _, { isNull } from "lodash";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { CSVLink } from "react-csv";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  FilterList as FilterListIcon,
  Add as AddIcon
} from "@material-ui/icons";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import VehicleTable from "./VehiclesTable";
import Loading from "../../../../utils/Loading";
import Filter from "./Filter";
import { DeleteManyConfirmation } from "./Dialogs";

import {
  GET_VEHICLE_INFOS,
  GET_GROUPS,
  DOWNLOAD_GET_VEHICLE_INFOS,
  GET_ACL_MODULES_CLIENT
} from "../../../../graphql/Queries";
import AccessControl from "../../../../utils/AccessControl";
// import Upload from "./Upload";
import SearchContext from "../../../../context/SearchContext";
import { gpsStatus } from "../../../Utils";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../../../utils/table/Pagination";
import PaperCount from "../../../../utils/UI/Paper/PaperCount";
import ClientAutocomplete from "../../../../utils/ClientAutocomplete";
import Upload, { status } from "../../../../utils/Upload";
import { UPLOAD_VEHICLES, EDIT_VEHICLES } from "../../../../graphql/Mutations";
import useUserContext from "../../../../context/User/useUserContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  title: {
    // flex: "1 1 25%",
    // color: "#000000"
    display: "flex",
    position: "relative",
    color: "black"
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  field: {
    marginRight: 40,
    marginLeft: 30,
    width: "20%"
  },
  button: {
    margin: 5,
    borderRadius: 32
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  // paper: {
  //   display: "flex",
  //   backgroundColor: "#eaeaea",
  //   paddingLeft: 16,
  //   position: "relative",
  //   height: "40px",
  //   alignItems: "center"
  // },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  fab: {
    // margin: theme.spacing(1),
    // position: "fixed",
    // bottom: 20,
    // right: 30
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  select_root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

// REACT_APP_INCOMING_STATUS=27
// REACT_APP_STATUS_ARRIVED_AT_PICKUP=15
// REACT_APP_STATUS_ARRIVED_AT_DROPOFF=24
// REACT_APP_STATUS_IN_TRANSIT=20
const booked = [
  process.env.REACT_APP_STATUS_IN_TRANSIT,
  process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
  process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
  process.env.REACT_APP_INCOMING_STATUS
];

// REACT_APP_STATUS_AVAILABLE=18
const available = [process.env.REACT_APP_STATUS_AVAILABLE];

const columns = {
  Client: "client_name",
  "Plate/CV number*": "plateno",
  "IMEI/Serial Number": "device_name",
  "Vehicle Type*": "vehicle_type",
  "Partner/subgroup": "group_names",
  "KML (Standard)": "avefuelconsumption",
  "Registry expiration": "expiration",
  Model: "model",
  "Body number": "bodyno",
  "Manufacture year": "manufacture_year",
  Make: "make",
  Area: "area",
  Heads: "heads",
  Region: "region",
  Assignment: "assignment",
  Remarks: "remarks"
};

const Vehicles = () => {
  const classes = useStyles();
  // const user = JSON.parse(Cookies.get("user"));
  const user = useUserContext();
  const history = useHistory();
  const variable = history?.location?.state?.params;
  const userLevelId = user.user_level_id;
  const clientId = user?.client?.id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [confirmDeleteMany, setConfirmDeleteMany] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("vehicle_info.plateno");
  const [canEdit, setcanEdit] = useState(false);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [groupFilter, setGroupFilter] = useState({
    field: "vehicles_groups.group_id",
    value: user.group_ids[
      _.findIndex(user.group_names, gn => gn === user.client.name)
    ].toString(),
    name:
      user.group_names[
        _.findIndex(user.group_names, gn => gn === user.client.name)
      ],
    index: 0
  });
  const [vehicles, setVehicles] = useState({
    count: 0,
    total: 0,
    vehicles: []
  });

  const [uploadVehicles, { loading: uploading }] = useMutation(
    UPLOAD_VEHICLES,
    {
      onCompleted: () => {
        AddLogs("Admin - Vehicles/CV", "upload", ``);
      }
    }
  );
  const [overrideVehicles, { loading: overriding }] = useMutation(
    EDIT_VEHICLES
  );

  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const initialValueHauler = isWTIAdmin
    ? { value: [30], label: "Webcast", id: 30 }
    : { value: [], label: "All" };
  const [selected, setSelected] = useState([]);
  const [vtypeFilter, setVtypeFilter] = useState({ id: "all", name: "All" });
  const [haulerFilter, setHaulerFilter] = useState(initialValueHauler);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    start: null,
    end: null,
    field: "vehicle_info.expiration"
  });
  const [gStatusFilter, setGStatusFilter] = useState("all");
  const [vStatusFilter, setVStatusFilter] = useState("all");
  const [openFilter, setOpenFilter] = useState(false);

  const [uploadOpen, setUploadOpen] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [keyLog, setKeyLog] = useState(`Vehicle Type: All, GPS Status: All`);
  const [selDel, setSelDel] = useState([]); // set to get all selected value in table

  // for logistics and non-logistics
  const [hasTripPrebookingAccess, setHasTripPrebookingAccess] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);

  const [downloadData, setDownloadData] = useState({
    body: [],
    header: [
      {
        label: "Plate/CV Number",
        key: "plateno"
      },
      {
        label: "Vehicle Status",
        key: "vehicle_status"
      },
      {
        label: "IMEI/Serial Number",
        key: "imei"
      },
      {
        label: "Vehicle Type",
        key: "vehicle_type"
      },
      {
        label: "Paired Device",
        key: "paired_device"
      },
      {
        label: "Hauler",
        key: "hauler"
      },
      {
        label: "Battery level",
        key: "battery_level"
      },
      {
        label: "GPS Status",
        key: "status"
      },
      {
        label: "Make",
        key: "make"
      },
      {
        label: "Area",
        key: "area"
      },
      {
        label: "Heads",
        key: "heads"
      },
      {
        label: "Region",
        key: "region"
      },
      {
        label: "Assignment",
        key: "assignment"
      }
    ]
  });
  const csvLink = useRef(null);
  const start = moment().format("YYYY-MM-DD");

  const savedFilter = () => {
    // assign the saved filter values to query
    const temp = variable?.vars?.condition;
    let vstat;
    if (temp) {
      temp.forEach(carfil => {
        switch (carfil.field) {
          case "vehicle_type_id":
            setVtypeFilter({ name: variable?.vTypeName, id: carfil?.value });
            break;
          case "under_repair":
            setVStatusFilter(carfil.value[0]);
            break;
          case "not_in_use":
            vstat = +carfil.value[0] + 1;
            setVStatusFilter(vstat.toString());
            break;
          case "vehicle_info.status_code_id":
            setVStatusFilter(carfil.value);
            break;
          case "rover_status.status_code_id":
            setGStatusFilter(carfil.value);
            break;
          case "vehicle_info.device_id":
            setGStatusFilter("0");
            break;
          default:
            break;
        }
      });

      setIsLogistics(variable?.isLogistics);

      if (variable?.group?.value[0] !== haulerFilter.value[0]) {
        setHaulerFilter({
          value: [+variable?.group?.value],
          label: variable?.group?.label,
          id: +variable?.group?.id
        });
      }

      if (variable?.vars?.dateRange?.field === "expiration") {
        setDateRangeFilter({
          start: moment(variable?.vars?.dateRange?.start).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          end: moment(variable?.vars?.dateRange?.end).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          field: "expiration"
        });
      }
    }
  };

  const {loading: aclLoading } = useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: clientId },
    skip: isWTIAdmin,
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setHasTripPrebookingAccess(hasTripsPrebooking);
        setIsLogistics(hasTripsPrebooking)
      }
    },
    onError: err => console.log(err),
    fetchPolicy: "cache-and-network"
  });

  const getQueryConditions = () => {
    // const conditions = [
    //   {
    //     field: "clients.id",
    //     value:
    //       groupFilter.index !== 0
    //         ? groupFilter.index.toString()
    //         : user.client.id.toString()
    //   }
    // ];
    let filter_val = {
      group_ids: [],
      condition: [],
      keyword: keyword,
      orderBy: [{ field: orderBy, direction: order }],
      first: rowsPerPage,
      skip: currentPage * rowsPerPage,
      not: [],
      is_logistics: isWTIAdmin ? isLogistics : hasTripPrebookingAccess
    };
    if (vtypeFilter.id !== "all") {
      filter_val.condition.push({
        field: "vehicle_type_id",
        value: vtypeFilter.id
      });
    }
    // .toLowerCase()
    // if (isWTIAdmin) {
    //   filter_val.group_ids.push(...haulerFilter.value);
    // } else {
    //   if (haulerFilter.label.toLowerCase() !== "all") {
    //     filter_val.condition.push({
    //       field: "vehicle_info.client_id",
    //       value: haulerFilter.id.toString()
    //     });
    //   }

    if (haulerFilter.id !== "all") {
      filter_val.group_ids.push(...haulerFilter.value);
      // filter_val.condition.push({
      //   field: "vehicles_groups.group_id",
      //   value: haulerFilter.id.toString()
      // });
    }

    if (vStatusFilter !== "all") {
      if (vStatusFilter === "0") {
        filter_val.condition.push({
          field: "vehicle_info.device_id",
          value: ""
        });
      } else if (vStatusFilter === process.env.REACT_APP_STATUS_ACTIVE) {
        filter_val.condition.push({
          field: "vehicle_info.status_code_id",
          value: process.env.REACT_APP_STATUS_ACTIVE
        });
        filter_val.not.push(
          {
            field: "under_repair",
            value: ["1"]
          },
          {
            field: "not_in_use",
            value: ["1"]
          }
        );
      } else if (vStatusFilter === process.env.REACT_APP_STATUS_AVAILABLE) {
        filter_val.condition.push({
          field: "vehicle_info.status_code_id",
          value: process.env.REACT_APP_STATUS_AVAILABLE
        });
        filter_val.not.push(
          {
            field: "under_repair",
            value: ["1"]
          },
          {
            field: "not_in_use",
            value: ["1"]
          }
        );
      } else if (vStatusFilter === "1") {
        filter_val.condition.push({
          field: "under_repair",
          value: ["1"]
        });
        // filter_val.not.push({
        //   field: "not_in_use",
        //   value: ["1"]
        // });
      } else if (vStatusFilter === "2") {
        filter_val.condition.push({
          field: "not_in_use",
          value: ["1"]
        });
        filter_val.not.push({
          field: "under_repair",
          value: ["1"]
        });
      } else {
        filter_val.condition.push({
          field: "vehicle_info.status_code_id",
          value: vStatusFilter
        });
        if (vStatusFilter === process.env.REACT_APP_VEHICLE_STATUS_BOOKED) {
          filter_val.dateRange = { start: `${start} 00:00:00` };
          filter_val.first = 10;
        }
        // filter_val.not.push({
        //   field: "under_repair",
        //   value: ["1"]
        // });
      }
    }

    if (gStatusFilter !== "all") {
      if (gStatusFilter === "0") {
        filter_val.condition.push({
          field: "vehicle_info.device_id",
          value: ""
        });
      } else {
        filter_val.condition.push({
          field: "rover_status.status_code_id",
          value: gStatusFilter
        });
      }
    }
    if (dateRangeFilter.start && dateRangeFilter.end) {
      filter_val = {
        ...filter_val,
        specialCondition: [
          {
            field: dateRangeFilter.field,
            condition: ">=",
            value: dateRangeFilter.start
          },
          {
            field: dateRangeFilter.field,
            condition: "<=",
            value: dateRangeFilter.end
          }
        ]
      };
    }
    return filter_val;
  };

  const [getAllVehicles, { loading: loadingAllVehicles }] = useLazyQuery(
    DOWNLOAD_GET_VEHICLE_INFOS,
    {
      // fetchPolicy: "cache-and-network",
      // variables: {
      //   ...getQueryConditions(),
      //   first: vehicles.total,
      //   dateRange: { start: `${start} 00:00:00` }
      // },
      onCompleted: data => {
        const { get_vehicles } = data;
        const tableData = get_vehicles.vehicles.map(vehicle => {
          return {
            plateno: vehicle.plateno,
            vehicle_status:
              booked.includes(vehicle.status_code_id) &&
              vehicle.not_in_use === true &&
              isLogistics
                ? "Booked - Not in use"
                : booked.includes(vehicle.status_code_id) &&
                  vehicle.under_repair === true &&
                  isLogistics
                ? "Booked - Under repair"
                : vehicle.under_repair === true
                ? "Under Repair"
                : vehicle.not_in_use === true
                ? "Not in use"
                : available.includes(vehicle.status_code_id) &&
                  vehicle.under_repair === false &&
                  isLogistics
                ? "Available"
                : booked.includes(vehicle.status_code_id) && isLogistics
                ? "Booked"
                : available.includes(vehicle.status_code_id) && !isLogistics
                ? "Active"
                : "-",
            imei: vehicle?.device_info?.name,
            vehicle_type: vehicle.vehicle_type,
            paired_device: vehicle.device_info
              ? vehicle.device_info.devicealias
              : "-",
            hauler: vehicle.client_name,
            status: vehicle.device_info
              ? gpsStatus(vehicle.device_info?.device_status?.status_code).label
              : "No GPS",
            client_id: vehicle.client_id,
            battery_level:
              vehicle.device_info?.device_status?.battery_level || "N/A",
            make: vehicle.make,
            area: vehicle.area,
            heads: vehicle.heads,
            region: vehicle.region,
            assignment: vehicle.assignment
          };
        });
        setDownloadData({
          // ...downloadData,
          body: tableData
        });
      }
    }
  );

  const { data: vehicles_data, refetch, loading } = useQuery(
    GET_VEHICLE_INFOS,
    {
      variables: getQueryConditions(),
      skip: aclLoading,
      // _.isEqual(variable, getQueryConditions()) ? variable.vars :
      // {
      // ...getQueryConditions(),
      // dateRange: { start: `${start} 00:00:00` }
      // },
      onCompleted: data => {
        if (keyword) {
          AddLogs("Admin - Vehicles/CV", "search", searchKeyword.value);
        }
        setVehicleCount(data?.get_vehicles.total);
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          // title: "Success",
          text: "Something went wrong"
        });
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const [getSelectedVehicles] = useLazyQuery(GET_VEHICLE_INFOS, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_vehicles.vehicles.map(val => {
          return temp.push(val.plateno);
        });
        setSelDel(temp);
      }
    }
  });

  useEffect(() => {
    searchKeyword.moduleName = process.env.REACT_APP_VEHICLES_MODULE;
    // excute save filter once to prevent cancelled query
    savedFilter();
  }, []);

  useEffect(() => {
    if (searchKeyword.submit) {
      // retain filter values when searching
      // setDateRangeFilter({ ...dateRangeFilter, start: null, end: null });
      // setVtypeFilter({ id: "all", name: "All" });
      // setGStatusFilter("all");
      // setVStatusFilter("all");
      // setHaulerFilter(initialValueHauler);
      setKeyword(searchKeyword.value);
      setCurrentPage(0);
      // refetch({
      //   variables: {
      //     condition: getQueryConditions(),
      //     first: rowsPerPage,
      //     skip: currentPage === 1 ? 0 : vehicles_data.get_vehicles.count
      //   }
      // });
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (vehicles_data) {
      const { get_vehicles } = vehicles_data;
      const vArr = get_vehicles.vehicles.map(v => {
        return {
          id: v.id,
          plateno: v.plateno,
          vehicle_type: v.vehicle_type,
          client_name: v.client_name || "-",
          device_info: v.device_info,
          paired_device: v.device_info ? v.device_info.devicealias : "-",
          status_code: v.device_info
            ? v.device_info?.device_status?.status_code
            : "No GPS",
          // status_code: v.device_info ? v.status_code : "No GPS",
          status_code_id: v.status_code_id,
          icon: v.icon,
          client_id: v.client_id,
          battery_level: v.device_info?.device_status?.battery_level,
          device_type_id: v.device_info?.device_type_id,
          reportstamp: v.device_info?.device_status?.reportstamp,
          under_repair: v.under_repair,
          not_in_use: v.not_in_use,
          imei: v.device_info?.name,
          trip_numbers: v.trip_numbers,
          device_id: v?.device_id
        };
      });
      setVehicles({
        count: get_vehicles.count,
        total: get_vehicles.total,
        vehicles: vArr
      });
      setTotalPage(
        get_vehicles.total ? Math.ceil(get_vehicles.total / rowsPerPage) : 1
      );
    }
  }, [vehicles_data]);

  // reset dafault of filter value
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: "Vehicles/CV"
        }
      }
    });
  };

  const handleClearFilter = () => {
    setDateRangeFilter({ ...dateRangeFilter, start: null, end: null });
    setVtypeFilter({ id: "all", name: "All" });
    setGStatusFilter("all");
    setVStatusFilter("all");
    setHaulerFilter(initialValueHauler);
    setKeyLog(`Vehicle Type: All, GPS Status: All`);
    setIsLogistics(false);
  };

  const handlePaginator = async pageUp => {
    if (pageUp) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
    setSelected([]);
  };

  const handleSorting = (o, oBy) => {
    const activeColumn = oBy;
    let dir = "asc";
    if (oBy === orderBy) {
      dir = o === "asc" ? "desc" : "asc";
    }
    setOrder(dir);
    setOrderBy(activeColumn);
  };

  const handlePage = e => {
    const { value } = e.target;
    if (currentPage < value) {
      if (value > totalPage) {
        // if the incoming page number is greater than total number of page
        // it will not change its value
        e.preventDefault();
      } else {
        setCurrentPage(value - 1);
      }
    } else if (Number(value) === 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(value - 1);
    }
  };

  // Function: Block other inputs beside number/integer
  const handleKeyPress = event => {
    // get the event value based on keyCode or which
    const keyCode = event.keyCode || event.which;
    // convert variable keyCode into a string
    const keyValue = String.fromCharCode(keyCode);
    // check if variable keyValue is NOT number or integer
    if (/[^0-9]/g.test(keyValue)) {
      // if true
      // blocked the value of keyValue
      event.preventDefault();
    }
  };

  const { loading: groupsLoading, data: groupsData } = useQuery(GET_GROUPS, {
    variables: { first: 10000 }
  });

  function parseDateExcel(excelTimestamp) {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return Number.isNaN(parsed) ? excelTimestamp : parsed;
  }

  const validateColumn = (key, value) => {
    switch (key) {
      case "client_name": {
        if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
          if (!value)
            return {
              error: "Using a WTI Admin account requires a client name."
            };
          return value;
        }
        return user.client.name;
      }
      case "plateno": {
        if (value === "") return { error: "Plate number is required." };
        if (value.length > 50)
          return { error: "Only 50 characters allowed in plate number." };
        return value?.toString()?.trim();
      }
      case "expiration": {
        if (!value) return null;
        const parseExcelDate = parseDateExcel(value);
        if (!moment(parseExcelDate).isValid([]))
          return { error: "Registration Expiration format is invalid" };
        const expiration = moment(parseExcelDate).format("YYYY-MM-DD");
        return expiration;
      }
      case "vehicle_type": {
        if (value === "") return { error: "Vehicle type is required." };
        return value;
      }
      case "avefuelconsumption": {
        const fuelConsumption = Number(value);
        if (Number.isNaN(fuelConsumption))
          return { error: "KML (Standard) is invalid format." };
        if (fuelConsumption < 0 || fuelConsumption > 99999)
          return { error: "Invalid KML (Standard) min: 0, max: 99999." };
        return fuelConsumption;
      }
      case "model": {
        if (/[^a-zA-Z0-9 ]/.test(value))
          return { error: "Model is invalid format." };
        return value;
      }
      case "bodyno": {
        if (value.length > 25)
          return { error: "Body number should not exceed 25 characters." };
        if (/[^a-zA-Z0-9 ]/.test(value))
          return { error: "Body Number is invalid format." };

        return value;
      }
      case "manufacture_year": {
        if (value === "") return new Date().getFullYear().toString();
        if (/[^0-9]/.test(value))
          return { error: "Manufacture Year format is invalid." };
        if (value < 1980 || value > 2030)
          return { error: "Invalid manufacture year." };
        return value;
      }
      case "group_names":
        if (value) {
          const arrData = value
            .toLowerCase()
            .replaceAll(/, /g, "^ ")
            .split(",")
            .map(data => data.replaceAll("^", ","));
          return arrData;
        }
        return [];
      case "make":
        if (value.length > 80)
          return { error: "Make should not exceed 80 characters" };
        return value;
      case "heads":
        if (value.length > 150)
          return { error: "Heads should not exceed 150 characters" };
        return value;
      case "area":
        if (value.length > 100)
          return { error: "Area should not exceed 100 characters" };
        return value;
      case "region":
        if (value.length > 80)
          return { error: "Region should not exceed 80 characters" };
        return value;
      case "assignment":
        if (value.length > 100)
          return { error: "Assignment should not exceed 100 characters" };
        return value;
      default:
        return value;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AccessControl
      resource="/admin/resources/vehicles/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        {/* <Fragment> */}
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              Vehicles/CV
            </Typography>
            {/* {user.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
              <ClientAutocomplete
                groupFilter={groupFilter}
                // setter={setGroupFilter}
                onChange={async (e, nv) => {
                  // const { get_vehicles } = vehicles_data;
                  await setGroupFilter({
                    ...groupFilter,
                    // value: nv.id,
                    value: nv.group_ids,
                    name: nv.name,
                    index: nv.id
                  });
                  refetch({
                    variables: {
                      condition: getQueryConditions(),
                      first: rowsPerPage,
                      skip: currentPage === 1 ? 0 : vehicles.count
                    }
                  });
                }}
              />
            )
            } */}
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "5px",
                alignItems: "center"
              }}
            >
              <PopupState variant="popover">
                {popupState => (
                  <Fragment>
                    <Button
                      startIcon={<GetAppIcon />}
                      variant="contained"
                      className={classes.btn_download}
                      {...bindTrigger(popupState)}
                    >
                      Download
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "center"
                      }}
                      onClose={() => {
                        if (!loadingAllVehicles) popupState.close();
                      }}
                    >
                      <List>
                        <ListItem
                          button
                          onClick={() =>
                            AddLogs(
                              "Admin - Vehicles/CV",
                              "download_template",
                              ``
                            )
                          }
                        >
                          <Link
                            to="/assets/vehicles_upload_template.xlsx"
                            style={{ color: "black", textDecoration: "none" }}
                            download
                            target="_blank"
                          >
                            Download Template
                          </Link>
                        </ListItem>
                        <ListItem button disabled={loadingAllVehicles}>
                          <React.Fragment>
                            <ListItemText
                              primary="Download Table"
                              onClick={() => {
                                getAllVehicles({
                                  fetchPolicy: "cache-and-network",
                                  variables: {
                                    ...getQueryConditions(),
                                    first: vehicles.total,
                                    dateRange: { start: `${start} 00:00:00` }
                                  }
                                }).then(() => {
                                  csvLink.current.link.click();
                                  AddLogs(
                                    "Admin - Vehicles/CV",
                                    "download_table",
                                    keyLog
                                  );
                                });
                              }}
                            />
                            <CSVLink
                              data={downloadData.body}
                              headers={downloadData.header}
                              ref={csvLink}
                              style={{ display: "none", pointerEvents: "none" }}
                            />
                            {loadingAllVehicles ? (
                              <CircularProgress size="1rem" />
                            ) : null}
                          </React.Fragment>
                        </ListItem>
                      </List>
                    </Popover>
                  </Fragment>
                )}
              </PopupState>

              {canEdit && (
                <Button
                  startIcon={<PublishIcon />}
                  variant="contained"
                  className={classes.btn_upload}
                  onClick={() => setUploadOpen(true)}
                >
                  Upload
                </Button>
              )}

              <Tooltip title="Filter List">
                <IconButton onClick={() => setOpenFilter(true)}>
                  <Badge
                    color="secondary"
                    variant={
                      getQueryConditions()?.condition?.length >= 1
                        ? "dot"
                        : "standard"
                    }
                  >
                    <FilterListIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Pagination
                rowsPerPage={rowsPerPage}
                total={totalPage}
                currentPage={currentPage}
                onRowsPerPageChange={n => {
                  setRowsPerPage(n);
                  // setPage(0);
                }}
                onPageChange={n => setCurrentPage(n)}
                onPageUp={n => setCurrentPage(n)}
                onPageDown={n => setCurrentPage(n)}
              />
              {/* <Typography>Rows per page: </Typography> */}
              {/* <Select
                displayEmpty
                value={rowsPerPage}
                style={{ padding: 0, margin: "0 8px" }}
                disableUnderline
                onChange={e => {
                  setRowsPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                {[10, 20, 30, 40, 50].map(rpp => (
                  <MenuItem key={rpp} value={rpp}>
                    {rpp}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                onClick={() => handlePaginator(false)}
                disabled={Number(currentPage + 1) === 1}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                inputProps={{
                  style: { padding: 0, textAlign: "center" }
                  // readOnly: true
                }}
                onFocus={e => {
                  e.target.select();
                }}
                style={{ width: "32px", marginRight: "8px" }}
                variant="outlined"
                value={currentPage + 1}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
              />
              {`/${totalPage}`}
              <IconButton
                onClick={() => handlePaginator(true)}
                disabled={Number(currentPage + 1) === Number(totalPage)}
              >
                <KeyboardArrowRightIcon />
              </IconButton> */}
            </div>
          </Toolbar>

          <Toolbar
            className={clsx(classes.toolbar_root, {
              [classes.highlight]: selected.length > 0,
              [classes.invisible]: selected.length <= 0
            })}
          >
            {selected.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {`${selected.length} vehicle/s selected on this page`}
              </Typography>
            )}
            {selected.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  borderRadius: 36,
                  color: "white"
                }}
                onClick={() => {
                  const selectedName = [""];
                  if (selected.length > 0) {
                    selected.map(id => selectedName.push(id));
                  }
                  getSelectedVehicles({
                    variables: {
                      filter: { field: "vehicle_info.id", value: selectedName }
                    }
                  });
                  setConfirmDeleteMany(true);
                }}
              >
                Delete Selected
              </Button>
            )}
          </Toolbar>
          {loading && groupsLoading ? (
            <Loading />
          ) : (
            <div style={{ width: "100%" }}>
              <VehicleTable
                vehicles={vehicles}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                selected={selected}
                setSelected={setSelected}
                getQueryConditions={getQueryConditions}
                handlePaginator={handlePaginator}
                canEdit={canEdit}
                selectedGroup={user.group_ids[0]}
                order={order}
                orderBy={orderBy}
                handleSorting={handleSorting}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                haulerFilter={haulerFilter}
                vtypeFilter={vtypeFilter}
                isLogistics={isLogistics}
                updateTable={() => {
                  refetch({
                    variables: {
                      condition: getQueryConditions(),
                      first: rowsPerPage,
                      skip: currentPage === 1 ? 0 : vehicles.count
                    }
                  });
                }}
              />
            </div>
          )}
        </Paper>
        {/* <Upload
          open={uploadOpen}
          setUploadOpen={setUploadOpen}
          getQueryConditions={getQueryConditions}
          currentPage={currentPage}
        /> */}
        <Upload
          open={uploadOpen}
          onDialogClose={() => setUploadOpen(false)}
          loading={uploading || overriding}
          // ❗ Required
          columns={columns}
          validateTemplate={(col1, col2) => {
            return (
              col1.join(",") === col2.join(",") &&
              col1.length > 0 &&
              col2.length > 0
            );
          }}
          // ❗ Required
          validate={columnValues => {
            // ❗ Required
            const validateResult = {
              valid: true,
              data: {},
              errors: {}
            };

            Object.keys(columnValues.data).forEach(key => {
              const result = validateColumn(key, columnValues.data[key]);
              if (
                typeof result === "object" &&
                !Array.isArray(result) &&
                !isNull(result)
              ) {
                validateResult.data[key] = columnValues.data[key];
                validateResult.errors[key] = result.error;
              } else {
                validateResult.data[key] = result;
              }
            });
            return validateResult;
          }}
          fileRules={{
            minLength: 1,
            maxLength: 1001
          }}
          getDocumentStatus={({ document, rawData: rows }) => {
            let isPartialSuccess = false;
            const numberOfErrors = Object.keys(
              document.errors.fields.reduce((accum, current) => {
                if (!accum[current.row]) {
                  if (current.success === null || !current.success) {
                    accum[current.row] = "__placeholder__";
                  } else if (current.success && current.message) {
                    accum[current.row] = "__placeholder__";
                    isPartialSuccess = true;
                  }
                }
                return accum;
              }, {})
            ).length;

            if (isPartialSuccess) return status.PARTIAL_SUCCESS;

            if (numberOfErrors === 0) return status.SUCCESS;
            if (rows.length !== numberOfErrors) return status.PARTIAL_SUCCESS;
            if (rows.length === numberOfErrors) return status.FAILED;

            return status.FAILED;
          }}
          getForOverrides={uploadResponse => {
            /**
             * @description
             * This function will run before `onOverride` function triggers
             * If this returns empty array, override button will not show up.
             */

            return uploadResponse.map(({ id, data, ...restForOverride }) => {
              return {
                id,
                vehicle: data,
                ...restForOverride
              };
            });
          }}
          onSubmit={async ({ validRowsObject }) => {
            /**
             * Submit the data ✅
             * Server returns response with errors payload (when there is) ✅
             * check if errors are valid for overriding. (Invoke `getForOverrides`) ✅
             * call the `override` method.
             */
            const payload = validRowsObject.map(({ data }) => ({
              ...data,
              manufacture_year: data.manufacture_year?.toString(),
              device_name: data.device_name.toString(),
              bodyno: data.bodyno.toString()
              // client_id: user.client.id
              // group_ids: [
              //   user.group_ids[
              //     _.findIndex(user.group_names, gn => {
              //       return gn === user.client.name;
              //     })
              //   ]
              // ]
            }));

            const response = await uploadVehicles({
              variables: {
                vehicles: payload
              }
            });
            const { data } = response;
            const { add_vehicles } = data;
            const errors = add_vehicles.reduce(
              (accum, mutation, currentIndex) => {
                mutation.error.forEach(error => {
                  const { field, message, override, id } = error;
                  const { row, data: payloadData } = validRowsObject[
                    currentIndex
                  ];
                  accum.push({
                    id,
                    row,
                    data: payloadData,
                    field,
                    message,
                    override,
                    success: mutation.success
                  });
                });
                return accum;
              },
              []
            );

            return {
              errors
            };
          }}
          onOverride={async forOverrideItems => {
            // Override method
            const payload = forOverrideItems.map(({ id, vehicle }) => {
              const requiredFields = [
                "plateno",
                "client_name",
                "device_name",
                "vehicle_type"
              ];
              const v = Object.fromEntries(
                Object.entries(vehicle).filter(([key]) =>
                  requiredFields.includes(key)
                )
              );
              let item = {
                id,
                vehicle: {
                  ...v,
                  // manufacture_year: vehicle?.manufacture_year?.toString(),
                  device_name: vehicle.device_name.toString()
                }
              };

              // exclude entries with null/empty value
              Object.assign(
                item.vehicle,
                Object.fromEntries(
                  Object.entries(vehicle).filter(
                    ([key, value]) =>
                      !requiredFields.includes(key) && value && { key }
                  )
                ),
                vehicle?.manufacture_year && {
                  manufacture_year: vehicle?.manufacture_year?.toString()
                }
              );

              return item;
            });
            const response = await overrideVehicles({
              variables: { vehicles: payload }
            });

            const { data } = response;
            const { edit_vehicles: overrideResponse } = data;
            const errors = overrideResponse.reduce(
              (accum, mutation, currentIndex) => {
                const { error } = mutation;
                error.forEach(e => {
                  const { row } = forOverrideItems[currentIndex];
                  accum.push({ row, success: mutation.success, ...e });
                });
                return accum;
              },
              []
            );

            return { errors };
          }}
        />
        <Filter
          open={openFilter}
          setOpenFilter={setOpenFilter}
          setVtypeFilter={setVtypeFilter}
          vtypeFilter={vtypeFilter}
          setDateRangeFilter={setDateRangeFilter}
          dateRangeFilter={dateRangeFilter}
          setGStatusFilter={setGStatusFilter}
          gStatusFilter={gStatusFilter}
          setVStatusFilter={setVStatusFilter}
          vStatusFilter={vStatusFilter}
          setCurrentPage={setCurrentPage}
          setKeyLog={setKeyLog}
          setHaulerFilter={setHaulerFilter}
          haulerFilter={haulerFilter}
          initialValueHauler={initialValueHauler}
          hasTripPrebookingAccess={hasTripPrebookingAccess}
          isWTIAdmin={isWTIAdmin}
          history={() => clearHistory()}
          handleClearFilter={() => handleClearFilter()}
          isLogistics={isLogistics}
          setIsLogistics={setIsLogistics}
        />
        <DeleteManyConfirmation
          selDel={selDel}
          selected={selected}
          getQueryConditions={getQueryConditions}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          confirmDeleteMany={confirmDeleteMany}
          setConfirmDeleteMany={setConfirmDeleteMany}
          handlePaginator={handlePaginator}
          setSelected={setSelected}
          updateTable={() => {
            refetch({
              variables: {
                condition: getQueryConditions(),
                first: rowsPerPage,
                skip: currentPage === 1 ? 0 : vehicles.count
              }
            });
          }}
        />

        <div className={classes.fab}>
          {!loading && vehicleCount ? (
            <PaperCount label="vehicles created" data={vehicleCount} />
          ) : null}
          {groupFilter.name === user.client.name && canEdit && (
            <Fab size="large" color="primary">
              <Link
                className={classes.fabIcon}
                to={{
                  // save the filter values
                  pathname: "/admin/resources/vehicles/add",
                  state: {
                    vars: getQueryConditions(),
                    vTypeName: vtypeFilter.name,
                    group: haulerFilter,
                    isLogistics: isLogistics
                  }
                }}
              >
                <AddIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "white"
                  }}
                  // onClick={() =>
                  //   setSearchKeyword({ ...searchKeyword, value: "" })
                  // }
                />
              </Link>
            </Fab>
          )}
        </div>
        {/* </Fragment> */}
      </div>
    </AccessControl>
  );
};

export default Vehicles;
