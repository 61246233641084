/* global google */

import React, { useState, useMemo } from "react";
import Map from "../../utils/Map";
import {
  control_positions,
  mapContainerStyle,
  getGeoPaths,
  mapRef,
  center as DEFAULT_CENTER
} from "../../utils/Map/Inner";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import Logo from "../../assets/Images/logo.png";
import moment from "moment";
import CustomMarker from "../../utils/Map/Marker/CustomMarker";
import { Marker, Polygon } from "@react-google-maps/api";
import SvgIcons from "../../assets/SvgIcons/SvgIcons";
import { Add, Remove } from "@material-ui/icons";
import useViewControlRoomStyles from "../Admin/Activity/ControlRoom/ViewChannel/ViewControlRoom.styles";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_TEXTUAL_LOCATION } from "../../graphql/Queries";
import Cookies from "js-cookie";
import BlinkingAlertsLegend from "../../utils/BlinkingAlertsLegend";
import { GET_GEOFENCE } from "../../graphql/Queries";
import { wktToCoordinates } from "../../utils/functions/coordinatesParserV2";
import { notifPoly_options } from "../Admin/Trip/Geofences/RouteGeofence/options";

const GpsNotification = () => {
  const notificationInfo = JSON.parse(localStorage.getItem("gps_notification"));
  const classes = useStyles();
  const { default_icon, LBS_vehicle } = SvgIcons;
  const { notification: notif } = notificationInfo;
  const user = JSON.parse(Cookies.get("user"));
  const [route, setRoute] = useState(null);
  const [address, setAddress] = useState(null);

  useQuery(GET_GEOFENCE, {
    skip: notif.notification.search("out of route") < 0,
    variables: {
      id: notif.geofence_id[0]
    },
    onCompleted: res => {
      if (res) {
        const geofence = res.get_geofence;
        setRoute(geofence.geom);
      }
    },
    fetchPolicy: "cache-and-network"
  });

  // initial value for map.
  const initialValue = {
    zoom: 12,
    coordinates: {
      lat: 12.248727944234455,
      lng: 121.95099687500002
    },
    isZoomEnabled: false,
    bounds: null
  };
  const [initialState, setInitialState] = useState(initialValue);
  const styles = useViewControlRoomStyles({ _, _ });
  // default options for map.
  const options = {
    zoomControl: false,
    panControl: true,
    draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false
  };

  const { loading } = useQuery(GET_TEXTUAL_LOCATION, {
    variables: {
      points: notif.location,
      group_ids: user.group_ids
    },
    onCompleted: data => {
      setAddress(data.get_textual_location);
    }
  });

  // validate zoom level handler.
  const validateZoomLevel = n => n;

  const setCurrentBounds = () => {
    if (!mapRef.current) return;
    const tempBounds = getGeoPaths();
    const boundingBox = tempBounds.map(coords => {
      return `${coords.lat} ${coords.lon}`;
    });

    setInitialState(prevState => {
      return {
        ...prevState,
        bounds: boundingBox
      };
    });
  };

  const getCurrentCenter = () => mapRef.current.getCenter() || DEFAULT_CENTER;
  const handleIdle = () => {
    if (notif.notification.includes("out of route") && route) return;
    const { lat, lng } = getCurrentCenter();
    // if (drawingMode) setCurrentBounds();
    setCurrentBounds();
    setInitialState(prevState => {
      return {
        ...prevState,
        zoom: validateZoomLevel(mapRef.current.getZoom()),
        coordinates: {
          lat: notif.location.lat,
          lng: notif.location.lon
        }
      };
    });
  };

  const isLBSVehicle = notif?.alert_code === "LBS DATA";
  const Icon = SvgIcons[isLBSVehicle ? "LBS_vehicle" : "default_icon"];

  // limits the rerender of the map.
  const map = useMemo(() => {
    return (
      <Map
        defaultZoom={initialState.zoom}
        zoom={initialState.zoom}
        mapContainerStyle={mapContainerStyle}
        center={initialState.coordinates}
        onIdle={handleIdle}
        options={options}
      >
        <Marker
          position={{ lat: notif.location.lat, lng: notif.location.lon }}
          options={{
            icon: {
              path: Icon.path,
              fillColor: Icon.fill,
              fillOpacity: 1,
              scale: 1.2,
              strokeColor: Icon.stroke,
              strokeWeight: Icon.strokeWidth,
              size: new google.maps.Size(22, 17),
              origin: new google.maps.Point(0, 0),
              anchor: isLBSVehicle
                ? new google.maps.Point(12, 12)
                : new google.maps.Point(-6, -4)
            }
          }}
        >
          <CustomMarker
            getVehiclePosition={() => {
              return { lat: notif.location.lat, lng: notif.location.lon };
            }}
            vehiclePlateNo={notif.vehicle_plate_no}
            moduleType="TRIPS_MAP_VIEW"
            zoom={13}
            alertLevel={notif.alert_level ? { level: notif.alert_level } : null}
            isMoving={notif.speed}
          />
        </Marker>
        {notif.notification.search("out of route") > 0 && route && (
          <Polygon 
            paths={wktToCoordinates(route)}
            options={notifPoly_options}
            onLoad={()=> {
              //Auto zoom to view whole route
              var bounds = new window.google.maps.LatLngBounds();
              const paths = wktToCoordinates(route);
              paths.forEach(path => {
                path.forEach(coor => bounds.extend(coor))
              });
              bounds.extend({
                lat: notif.location.lat,
                lng: notif.location.lon
              });
              var extendPoint = new window.google.maps.LatLng(
                bounds.getNorthEast().lat(),
                bounds.getNorthEast().lng()
              );
              bounds.extend(extendPoint);
              mapRef.current.fitBounds(bounds);
            }}
          />
        )}
        <Map.Control position={control_positions.LEFT_TOP}>
          <div className={classes.containerNotif}>
            <img src={Logo} className={classes.logo} alt="Webcast Logo" />
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography className={classes.vehiclePlateNo}>
                {notif.vehicle_plate_no}
              </Typography>
              <Typography className={classes.label}>
                Vehicle Plate Number
              </Typography>
            </Box>
            <Box style={{ marginTop: "1.5rem" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gridGap="1rem"
                style={{
                  borderBottom: "1px solid rgba(181, 181, 181, 0.1)",
                  paddingBottom: "1rem"
                }}
              >
                <div className={classes.gpsAlertBox}>
                  <Typography className={classes.title}>
                    Alert Information
                  </Typography>
                  <Typography className={classes.label}>Message</Typography>
                  <Typography>
                    <span className={classes.notifDescription}>
                      {notif.notification}
                    </span>
                  </Typography>
                  <Typography className={classes.label}>Location</Typography>
                  <Typography className={classes.notifDescription}>
                    {address || (
                      <span className={classes.errorMessage}>
                        Location Unavailable
                      </span>
                    )}
                  </Typography>
                  {address && (
                    <span
                      className={classes.coordinates}
                    >{`(X: ${notif.location?.lat} Y: ${notif.location?.lon})`}</span>
                  )}
                </div>
                <Typography className={classes.label}>
                  {moment(notif.datestamp)
                    .add(8, "hours")
                    .format("LLLL")}
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: "1rem" }}>
              <div className={classes.gpsAlertBox}>
                <Typography className={classes.title}>
                  Vehicle Information
                </Typography>
                <Typography className={classes.label}>Driver's Name</Typography>
                <Typography>
                  <span className={classes.notifDescription}>
                    {notif.driver_name || "-"}
                  </span>
                </Typography>
                <Typography className={classes.label}>Trip Number</Typography>
                <Typography className={classes.notifDescription}>
                  {notif.trip_number || "-"}
                </Typography>
              </div>
            </Box>
            <Box display="flex" gridGap="10px" style={{ marginTop: "1rem" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                className={classes.vehicleStatusContainer}
              >
                <Typography
                  className={classes.statusValue}
                >{`${notif.temperature}°`}</Typography>
                <Typography className={classes.label}>Temperature</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                className={classes.vehicleStatusContainer}
              >
                <Typography className={classes.statusValue}>
                  {`${notif.speed}`} <span className={classes.unit}>kph</span>
                </Typography>
                <Typography className={classes.label}>Speed</Typography>
              </Box>
            </Box>
          </div>
        </Map.Control>
        <Map.Control position={control_positions.RIGHT_BOTTOM}>
          <BlinkingAlertsLegend showAlerts margin={`15px 0`} />
          <div className={styles.drawing_mode_container}>
            <div className={styles.zoom_control_btn_grp}>
              <Button
                size="small"
                className={styles.zoom_add_btn}
                disabled={initialState.zoom >= 22}
                onClick={() => {
                  mapRef.current.setZoom(
                    validateZoomLevel(mapRef.current.zoom + 1)
                  );
                }}
              >
                <Add size="small" />
              </Button>
              <div
                style={{ backgroundColor: "rgb(230, 230, 230)", height: "1px" }}
              ></div>
              <Button
                size="small"
                className={styles.zoom_minus_btn}
                disabled={initialState.zoom <= 2}
                onClick={() => {
                  mapRef.current.setZoom(
                    validateZoomLevel(mapRef.current.zoom - 1)
                  );
                }}
              >
                <Remove size="small" />
              </Button>
            </div>
          </div>
        </Map.Control>
      </Map>
    );
  }, [loading, initialState, address, route]);
  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      {map}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  containerNotif: {
    margin: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    width: "400px",
    borderRadius: 30,
    height: "auto",
    minHeiht: 500,
    padding: "1rem 2rem"
  },
  logo: {
    width: "150px",
    height: "auto",
    margin: "auto",
    display: "block"
  },
  vehiclePlateNo: {
    color: theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: "rgba(181, 181, 181, 0.1)",
    width: "100%",
    textAlign: "center",
    padding: "0.25rem",
    borderRadius: "20px",
    marginBottom: 5,
    minHeight: 20
  },
  label: {
    color: "#B5B5B5",
    fontSize: 11,
    marginTop: 2,
    letterSpacing: 0.5
  },
  notifDescription: {
    color: theme.palette.primary.dark,
    fontSize: 12
  },
  gpsAlertBox: {
    maxWidth: "60%"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 13
  },
  statusValue: {
    color: theme.palette.primary.dark,
    fontSize: 13,
    fontWeight: "600"
  },
  coordinates: {
    fontSize: 10,
    color: "rgba(60, 118, 210, 0.7)"
  },
  vehicleStatusContainer: {
    width: 70,
    height: 70,
    borderRadius: 10,
    backgroundColor: "rgba(181, 181, 181, 0.1)",
    padding: "0.5rem"
  },
  unit: {
    fontSize: 10,
    color: "rgba(181, 181, 181, 0.5)"
  },
  errorMessage: {
    color: "#E52E2D",
    fontSize: 12
  }
}));

export default GpsNotification;
