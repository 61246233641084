/* eslint-disable import/prefer-default-export */
import * as yup from "yup";
import validate from "../utils/validation";

export const profileFields = [
  {
    name: "first_name",
    label: "First Name",
    category: "personalInfo",
    isReadOnly: false,
    placeholder: "Enter first name"
  },
  {
    name: "last_name",
    label: "Last Name",
    category: "personalInfo",
    isReadOnly: false,
    placeholder: "Enter last name"
  },
  {
    name: "username",
    label: "Username",
    category: "personalInfo",
    isReadOnly: true,
    placeholder: "Enter username"
  },
  {
    name: "email_address",
    label: "Email",
    category: "contactInfo",
    isReadOnly: false,
    placeholder: "Enter email address"
  },
  {
    name: "contact_number",
    label: "Contact Number",
    category: "contactInfo",
    isReadOnly: false,
    placeholder: "Enter contact number"
  },
  {
    name: "old_password",
    label: "Current Password",
    category: "securityInfo",
    isReadOnly: false,
    placeholder: "Enter current password"
  },
  {
    name: "password",
    label: "New Password",
    category: "securityInfo",
    isReadOnly: false,
    placeholder: "Enter new password"
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    category: "securityInfo",
    isReadOnly: false,
    placeholder: "Re-enter password"
  }
];

const validateUserPassword = (value, ctx, typeOfField = "") => {
  if (!value) {
    return ctx.createError({ message: "This field is required" });
  }
  if (value.length < 8) {
    return ctx.createError({
      message: "Password must at least be 8 characters"
    });
  }
  if (ctx.parent.password !== value && typeOfField === "matchPassword") {
    return ctx.createError({
      message: "Entered Password and Confirm Password do not match"
    });
  }
  return true;
};

export const userPassworSchema = () =>
  yup.object().shape({
    old_password: yup.mixed().test({
      name: "validate current password",
      test: async (value, ctx) => {
        return validateUserPassword(value, ctx);
      }
    }),
    password: yup.mixed().test({
      name: "validate current password",
      test: async (value, ctx) => {
        return validateUserPassword(value, ctx);
      }
    }),
    confirm_password: yup.mixed().test({
      name: "validate current password",
      test: async (value, ctx) => {
        return validateUserPassword(value, ctx, "matchPassword");
      }
    })
  });

export const userProfileSchema = () =>
  yup.object().shape({
    first_name: yup.string().test({
      name: "validate first name",
      test: async (value, ctx) => {
        if (!value) {
          return ctx.createError({ message: "This field is required" });
        }
        if (!validate("letters", value)) {
          return ctx.createError({ message: "Invalid first name" });
        }
        return true;
      }
    }),
    last_name: yup.string().test({
      name: "validate last name",
      test: async (value, ctx) => {
        if (!value) {
          return ctx.createError({ message: "This field is required" });
        }
        if (!validate("letters", value)) {
          return ctx.createError({ message: "Invalid last name" });
        }
        return true;
      }
    }),
    email_address: yup.mixed().test({
      name: "validate email",
      test: async (value, ctx) => {
        if (!value) {
          return ctx.createError({ message: "This field is required" });
        }
        if (!validate("email", value)) {
          return ctx.createError({ message: "Invalid email address" });
        }

        return true;
      }
    }),
    contact_number: yup.mixed().test({
      name: "validate contact number",
      test: async (value, ctx) => {
        if (!value) {
          return ctx.createError({ message: "This field is required" });
        }
        if (!validate("mobile_number_ph", value)) {
          return ctx.createError({
            message: "Must follow number format (+63) xxx-xxx-xxxx"
          });
        }

        return true;
      }
    })
  });
