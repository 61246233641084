import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  makeStyles,
  Toolbar,
  Typography,
  Switch,
  FormControlLabel,
  AppBar,
  IconButton,
  withStyles,
  Button,
  styled
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { normalize } from "path";
import { transform } from "@babel/core";
import ConfirmationDialog from "../../utils/modals/ConfirmationDialog";
import _ from "lodash";
import HaulerGroupSelector from "./Trip/Geofences/HaulerGroupSelector";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginRight: 20,
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  text: {
    fontSize: "18px",
    letterSpacing: 1.2,
    fontWeight: 600,
    marginLeft: 8,
    color: "#3c3c3c"
  },
  uploadDetailsTitle: {
    color: "#3c3c3c",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: 1.2
  },
  btn_save: {
    backgroundColor: "#53A4F7",
    color: "#FFFFFF",
    lineHeight: 1.5,
    margin: 10,
    textTransform: "none",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#5389f7"
    }
  },
  btn_disabled: {
    backgroundColor: "#9DD1FB",
    color: "#FFFFFF",
    lineHeight: 1.5,
    margin: 10,
    textTransform: "none",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#9DD1FB"
    }
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openRedirect, setOpenRedirect] = useState(false);
  const {
    process: processModule,
    moduleName,
    history,
    editMode,
    toggleEditMode,
    editPermission,
    redirect,
    setRedirect,
    setDiscard,
    isDataNotChanged = false,
    locationState,
    disableBackButton = false,
    hasUploadedGeofence,
    countOfData,
    totalOfData,
    isSavable,
    openModalSaveHandler,
    closeModalSaveHandler,
    openSaveModal,
    uploadFileHandler,
    fileInfo,
    noOfNotSaved,
    selectedData,
    checkFileStatusHandler,
    loadingUpload,
    selectedlength,
    label,
    filter,
    setFilter,
    isWTIAdmin,
    // formEdit
  } = props;
  // const goBack = () => {
  //   if (process === "View") {
  //     setRedirect(true);
  //   } else {
  //     setDiscard(true);
  //     setOpen(true);
  //   }
  // };
  const goBack = () => {
    if (processModule === "View") setRedirect(true);
    else if (isDataNotChanged) setRedirect(true);
    else setOpen(true);
  };

  const handleOpen = () => {
    setOpen(false);
    setOpenRedirect(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (selectedlength > 0 && !loadingUpload) {
      handleOpen();
    }
  }, [loadingUpload]);

  return (
    <div className={classes.root}>
      {openRedirect && (
        <Redirect
          push
          to={
            locationState?.source
              ? locationState.source.includes("history") ? locationState.source : 
                {
                  pathname: locationState.source,
                  state: locationState
                }
              : {
                  pathname: "/admin",
                  state: {
                    hasUploaded: _.isEmpty(fileInfo) ? false : true,
                    fileInfo: _.isEmpty(fileInfo) ? [] : fileInfo,
                    refresh: _.isEmpty(selectedData) ? false : true,
                    params: {
                      moduleName: moduleName
                    }
                  }
                }
          }
        />
      )}
      {redirect && (
        <Redirect
          push
          to={
            locationState?.source
              ? locationState.source.includes("history") ? locationState.source : 
                {
                  pathname: locationState.source,
                  state: locationState
                }
              : {
                  pathname: "/admin",
                  state: {
                    params: {
                      // variables for history
                      moduleName: moduleName,
                      vars: history?.vars,
                      filter: history?.filter,
                      vTypeName: history?.vTypeName,
                      group: history?.group,
                      isLogistics: history?.isLogistics,
                    }
                  }
                }
          }
        />
      )}
      <AppBar
        position="static"
        elevation={0}
        variant="outlined"
        color="default"
      >
        <Toolbar style={{ backgroundColor: "white" }}>
          {!disableBackButton && (
            <IconButton onClick={goBack}>
              <ArrowBackIcon style={{ margin: "0" }} />
            </IconButton>
          )}
          {!hasUploadedGeofence ? (
            <Typography variant="h6" classes={{ root: classes.text }}>
              {label
                ? label
                : `${processModule} ${processModule === "Add" ? "New" : ""} ${
                    moduleName !== "Access Rights"
                      ? moduleName.replace(/s\b/g, "")
                      : "Access Right"
                  }`}
            </Typography>
          ) : (
            <Typography classes={{ root: classes.uploadDetailsTitle }}>
              {`Intersect Geofence ${countOfData}/${totalOfData}`}
            </Typography>
          )}
          {moduleName === "Control Room" && isWTIAdmin && (
            <HaulerContainer>
              <HaulerGroupSelector
                hauler={filter.hauler}
                setHauler={newHauler => {
                  setFilter({ hauler: newHauler });
                }}
                isLabelShown={false}
                styleProperty={{
                  backgroundColor: "#eaeaea",
                  minWidth: 200,
                  borderRadius: 10,
                  padding: "6px 15px"
                }}
              />
            </HaulerContainer>
          )}
          <div style={{ flexGrow: 1 }} />
          {!hasUploadedGeofence && processModule !== "Add" && (
            <FormControlLabel
              control={
                <AntSwitch
                  checked={editMode}
                  onChange={e => toggleEditMode(e)}
                  name="checkedNotif"
                  value="checkedNotif"
                  color="primary"
                  disabled={!editPermission}
                />
              }
              labelPlacement="start"
              label={
                <Typography
                  style={{
                    fontSize: 12,
                    // marginRight: 20,
                    fontWeight: "bold",
                    color: "#454545",
                    letterSpacing: "1.2px"
                  }}
                  variant="subtitle2"
                >
                  Edit Mode
                </Typography>
              }
            />
          )}
          {hasUploadedGeofence && (
            <Button
              classes={{
                root: !isSavable ? classes.btn_disabled : classes.btn_save
              }}
              onClick={openModalSaveHandler}
              disabled={!isSavable}
            >
              Save
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {!hasUploadedGeofence ? (
        <ConfirmationDialog
          toggle={open}
          close={() => handleClose()}
          fn={() => handleOpen()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      ) : (
        <ConfirmationDialog
          toggle={open}
          close={() => handleClose()}
          fn={() => {
            checkFileStatusHandler();
            handleOpen();
          }}
          title="Discard?"
          content="Are you sure you want to leave this page? Upload will be unsucccesful."
        />
      )}
      {hasUploadedGeofence && (
        <ConfirmationDialog
          toggle={openSaveModal}
          close={closeModalSaveHandler}
          fn={() => {
            uploadFileHandler();
            // handleOpen()
          }}
          title="Save"
          content="Are you sure you want to save these geofences?"
        />
      )}
    </div>
  );
}

const HaulerContainer = styled("div")(({ theme }) => ({
  marginLeft: 30
}));
