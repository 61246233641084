import { Switch } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)",
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  numeric_field: {
    margin: "12px 0",
    width: "90%",
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  custom_field: {
    margin: "12px 0",
    width: "100%"
  },
  custom_limit: {
    padding: "28px 0",
    width: "100%"
  },
  multiline: {
    margin: "12px 0"
  },
  column: {
    fontSize: "1.5rem"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dialog: {
    borderRadius: "10px"
  },
  switch: { position: "absolute", right: 50, top: 0 }
}));

export const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    position: "absolute",
    right: 50,
    top: 0
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);
