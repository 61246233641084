/* eslint-disable no-unused-vars */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map(arg => {
      // Apply the "theme" object for style functions.
      if (typeof arg === "function") {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

export default combineStyles;

export const useChipInputStyles = _theme => ({
  filterInput: {
    minWidth: "100%",
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    },
    "& ::placeholder": {
      color: "#000",
      opacity: 1
    },
    "& .Mui-disabled ::placeholder": {
      /* Firefox */
      color: "#b1b1b1",
      opacity: 1
    },
    "& .Mui-disabled :-ms-input-placeholder": {
      /* Internet Explorer 10-11 */
      color: "#b1b1b1",
      opacity: 1
    },
    "& .Mui-disabled ::-ms-input-placeholder": {
      /* Microsoft Edge */
      color: "#b1b1b1",
      opacity: 1
    }
  },
  iconStyle: { width: 16, height: 16 }
});

export const useFormStyles = _theme => ({
  formLabelRoot: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#454545",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center",
    marginBottom: 12
  },
  formRoot: {
    margin: "0 0 24px 0",
    // width: "250px",
    minWidth: "250px",
    width: "100%",
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiFormLabel-root": {
      fontWeight: "bold",
      color: "#454545",
      letterSpacing: "1px"
    },
    "& .MuiInput-formControl": {
      width: "100%"
    },
    "& .MuiInputBase-multiline": {
      padding: 0
    }
  },
  selectRoot: {
    fontSize: "12px",
    padding: "12px 0px",
    textTransform: "capitalize"
  },
  textFieldRoot: {
    "& input": {
      fontSize: "12px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "12px",
      padding: "12px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "12px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    }
  },
  menuItem: {
    textTransform: "capitalize"
  },
  helpIcon: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  saveButton: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    width: "200px",
    borderRadius: "100px",
    transform: "translateX(-50%)"
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    justifyItems: "left"
  },
  checkBoxTitle: {
    fontSize: "0.75rem",
    fontWeight: 600,
    letterSpacing: 0.7
  }
});
