import React, { Fragment } from "react";
import { IconButton, Tooltip, Icon, Avatar } from "@material-ui/core";
import { useStyles } from "./ActionButtonStyle";

const ActionButton = (props) => {
  const classes = useStyles();
  const { title, isDisabled, onClickHandler } = props;  
  return (
    <IconButton className={classes.btn} disabled={isDisabled} onClick={onClickHandler}>
      <Tooltip title={title} placement="top" arrow>
        {props.children}
      </Tooltip>
    </IconButton>
  );
};

export default ActionButton;
