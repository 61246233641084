import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  makeStyles,
  Box,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
  Chip
} from "@material-ui/core";
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { getBooking } from "../../api/bookingRepository";
import { READ_NOTIFICATION } from "../../api/notificationRepository";
import {
  Error as ErrorIcon,
  ClearAll as ClearAllIcon,
  VolumeOff,
  VolumeUp
} from "@material-ui/icons";
import ConfirmationDialog from "../../utils/modals/ConfirmationDialog";
import AddLogs from "../../utils/functions/AddLogs";
import NoNotification from "./NoNotification";

const Notification = props => {
  const {
    isShown,
    notificationData,
    getAgainNotif,
    history,
    getMoreNotif,
    clearNotificationHandler,
    isFetchingData,
    mute,
    toggleMute
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [tripNotification, setTripNotification] = useState([]);
  const [systemNotification, setSystemNotification] = useState([]);
  const [gpsNotification, setGpsNotification] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const {
    get_notifications,
    gps_notifications,
    system_notifications
  } = notificationData;

  const trip_notifs = get_notifications?.notifications;
  const system_notifs = system_notifications?.notifications;
  const gps_notifs = gps_notifications?.notifications;

  const parseNotif = notifArray => {
    return notifArray.map(notif => {
      return { ...notif, is_urgent: Boolean(notif.is_urgent) }
    });
  };

  useEffect(() => {
    setTripNotification(_.orderBy(trip_notifs, ["created"], ["desc"]));
    setSystemNotification(
      _.orderBy(parseNotif(system_notifs), ["is_urgent", "created "], ["desc", "desc"])
    );
    setGpsNotification(
      _.orderBy(parseNotif(gps_notifs), ["is_urgent", "created "], ["desc", "desc"])
    );
  }, [trip_notifs, system_notifs, gps_notifs]);

  /* important functions */
  // this a function to fetchmore results upon reaching the infinite scroll value.
  // this will get more notifications depending on the active tab.
  function fetchMoreNotifications(notification) {
    let skipNotif;
    // set the skip notif depending on the notification that the user currently points.
    if (_.isEqual(activeTab, 0)) skipNotif = system_notifications.count;
    else if (_.isEqual(activeTab, 1)) skipNotif = get_notifications.count;
    else skipNotif = gps_notifications.count;

    getMoreNotif({
      variables: {
        skip: skipNotif,
        first: 10
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        let data = {};
        switch (notification) {
          case "get_notifications":
            data = {
              ...prev,
              get_notifications: {
                ...prev.get_notifications,
                count:
                  fetchMoreResult.get_notifications.count +
                  prev.get_notifications.count,
                notifications: [
                  ...prev.get_notifications.notifications,
                  ...fetchMoreResult.get_notifications.notifications
                ]
              }
            };
            break;
          case "gps_notifications":
            data = {
              ...prev,
              gps_notifications: {
                ...prev.gps_notifications,
                count:
                  fetchMoreResult.gps_notifications.count +
                  prev.gps_notifications.count,
                notifications: [
                  ...prev.gps_notifications.notifications,
                  ...fetchMoreResult.gps_notifications.notifications
                ]
              }
            };
            break;
          default: {
            data = {
              ...prev,
              system_notifications: {
                ...prev.system_notifications,
                count:
                  fetchMoreResult.system_notifications.count +
                  prev.system_notifications.count,
                notifications: [
                  ...prev.system_notifications.notifications,
                  ...fetchMoreResult.system_notifications.notifications
                ]
              }
            };
          }
        }

        return data;
      }
    });
  }
  // this function is use for setting the active tab for the notification.
  function tabOnChangeHandler(event, newValue) {
    setActiveTab(newValue);
  }
  // this function will fetch the total count of notification for each type.
  function getTotalNotificationTab(tab) {
    // check if notification data has a value else end this process.
    if (!notificationData) return;
    let noOfNotification = 0;
    switch (tab) {
      case 0: {
        noOfNotification = system_notifications?.total;
        break;
      }
      case 1: {
        noOfNotification = get_notifications?.total;
        break;
      }
      default: {
        noOfNotification = gps_notifications?.total;
        break;
      }
    }
    return noOfNotification;
  }
  // this function setups the values for the infinite scroll depending on the active tab on the notifications.
  function setupInfiniteScrollHandler() {
    const setup = { lengthOfData: "", next: "", hasMore: false };
    switch (activeTab) {
      case 1: {
        const { count, total, notifications } = get_notifications;
        setup.lengthOfData = notifications.length;
        setup.hasMore = count !== total;
        setup.next = fetchMoreNotifications("get_notifications");
        break;
      }
      case 2: {
        const { count, total, notifications } = gps_notifications;
        setup.lengthOfData = notifications.length;
        setup.hasMore = count !== total;
        setup.next = fetchMoreNotifications("gps_notifications");
        break;
      }
      default: {
        const { count, total, notifications } = system_notifications;
        setup.lengthOfData = notifications.length;
        setup.hasMore = count !== total;
        setup.next = fetchMoreNotifications("system_notifications");
        break;
      }
    }
    return setup;
  }
  // this is the design for the notifications label with the corresponding total count for each notifications.
  // get the custom badge and set if style for active and not active tab.
  // if total count of notification is 0 or null custom badge will not be shown.
  const notificationsLabel = (index, notifLabel) => (
    <Box display="flex" alignItems="center" gridGap="5px">
      <span className={classes.tabLabel}>{notifLabel}</span>
      {getTotalNotificationTab(index) > 0 && (
        <div
          className={
            _.isEqual(index, activeTab)
              ? classes.customBadgeActive
              : classes.customBadgeInActive
          }
        >
          {getTotalNotificationTab(index) > 99
            ? "99+"
            : getTotalNotificationTab(index)}
        </div>
      )}
    </Box>
  );
  // displays the notification tab options.
  const notificationOptionTabs = notificationOptions.map(
    (notifLabel, index) => (
      <Tab
        key={index}
        label={notificationsLabel(index, notifLabel)}
        className={classes.tabOptions}
        classes={{ root: classes.tabBtn }}
      />
    )
  );
  // updates the status of a notification to read.
  const updateReadNotificationHandler = async notificationInfo => {
    const notifInfoTemp = { ...notificationInfo, is_read: true, vehicle_plate_no: notificationInfo?.vehicle_plate_no || "" };
    _.unset(notifInfoTemp, "id");
    _.unset(notifInfoTemp, "created");

    const res = await READ_NOTIFICATION({
      id: notificationInfo.id,
      notification: notifInfoTemp
    });
    if (res === "success") {
      getAgainNotif();
    }
  };

  // this function redirects user to the view trip details and updates the clicked notification to read.
  const tripNotifOnClickHandler = async notificationInfo => {
    updateReadNotificationHandler(notificationInfo);

    if (
      notificationInfo.notification.includes("New trip has been assigned") ||
      (notificationInfo.notification.includes("Trip request") && 
      notificationInfo.notification.includes("has expired"))
    ) {
      const variables = {
        conditions: [
          {
            "field": "trip_number",
            "value": notificationInfo.trip_number
          }
        ]
      };

      await history.push("/temp");
      history.push({
        pathname: "/allocation",
        state: {
          params: {
            moduleName: process.env.REACT_APP_VEHICLE_ALLOCATION,
            vars: variables,
            isFromNotif: true
          }
        }
      });
    } else {
      const booking = await getBooking(notificationInfo.trip_number);
      const state = {
        tripData: booking.tripList[0],
        rawTripData: booking.rawTripData[0]
      };
      if (booking.tripList.length === 1) {
        if (booking.tripList[0].tripNumber === notificationInfo.trip_number) {
          const to = {
            pathname: "/details",
            state: state
          };
          history.push("/temp");
          history.push(to);
        }
      }
    }
  };
  // proceeds on displaying the notification on the map.
  function gpsAlertNotifOnClickHandler(notificationInfo) {
    if (!notificationInfo) return;
    const { location } = notificationInfo;

    if (!location || !location?.lat || !location?.lon) return;
    // updateReadNotificationHandler(notificationInfo);
    // add action to activity logs.
    AddLogs(
      "Notification",
      "view_gps_alert",
      notificationInfo.vehicle_plate_no,
      _
    );
    // set local storage for new tab gps notifications.
    return localStorage.setItem(
      "gps_notification",
      JSON.stringify({ notification: notificationInfo })
    );
  }

  function dwellNotifSetup(dwellValue){
    let dwellText, textTemp;
    const position = dwellValue.search("Dwell");
    dwellText = dwellValue.slice(position - 1);
    textTemp = dwellValue.slice(0, position);

    return {
      text: textTemp,
      time: dwellText,
    }
  }
  // design for each notifications.
  const notificationsBody = (notif, index) => {
    return (
      <Box
        key={index}
        className={classes.notifBox}
        onClick={() =>
          _.isEqual(activeTab, 1) ? tripNotifOnClickHandler(notif) : updateReadNotificationHandler(notif)
        }
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gridGap="10"
        >
          <div style={{ maxWidth: 300 }}>
            {notif.vehicle_plate_no && (
              <Typography className={classes.notif_vehicle_plate_no}>
                {notif.vehicle_plate_no}
              </Typography>
            )}
            {(_.isEqual(activeTab, 1) && _.isEqual(notif.category, "Dwell Time")) ? <>
              <Typography className={classes.notificationDescription}>
                {dwellNotifSetup(notif.notification).text}
              </Typography>
              <Typography className={classes.dwell_text}>
              {dwellNotifSetup(notif.notification).time}
              </Typography>
              </> : (
              <Typography className={classes.notificationDescription}>
                {notif.notification}
              </Typography>
            )}

            {_.isEqual(activeTab, 2) &&
              !notif?.location && (
                <Typography className={classes.coordinatesNotifError}>
                  <ErrorIcon style={{ fontSize: "1rem" }} /> Location
                  Unavailable
                </Typography>
              )}

            <Typography className={classes.notificationTime}>
              {/* {notifDateTimeStatus(notif.created)} */}
              {moment(notif.created).fromNow()}
            </Typography>
          </div>
          {!notif.is_read && <div className={classes.unreadNotif} />}
        </Box>
      </Box>
    );
  };
  // show notifications.
  const showActiveTabNotificationsHandler = () => {
    if (!notificationData) return;
    let hasNotif = getTotalNotificationTab(activeTab) > 0;
    let tabShown;
    switch (activeTab) {
      case 1:
        if (tripNotification.length) {
          tabShown = tripNotification.map((notif, index) =>
            notificationsBody(notif, index)
          );
          break;
        }
      case 2:
        if (gpsNotification.length) {
          tabShown = gpsNotification.map((notif, index) => {
            if (notif.location) {
              return <Link
                to={`/notification/view/${notif.id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
                onClick={() => gpsAlertNotifOnClickHandler(notif)}
              >
                {notificationsBody(notif, index)}
              </Link>
            } else {
              return notificationsBody(notif, index)
            }
          });
          break;
        }
      default:
        if (systemNotification.length) {
          tabShown = systemNotification.map((notif, index) =>
            notificationsBody(notif, index)
          );
          break;
        }
    }

    return hasNotif ? tabShown : <NoNotification />;
  };

  return (
    <>
      <Container disableGutters className={classes.containerNotifHeader}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.headerBox}
        >
          <Box display="flex" gridGap="5" alignItems="center">
            <Typography className={classes.titleNotif}>Notification</Typography>
            <span>
              <Tooltip title={`${mute ? "Unmute alerts" : "Mute alerts"}`}>
                <IconButton
                  size="small"
                  onClick={() => {
                    toggleMute(currentMute => {
                      localStorage.setItem("mute_notifications", currentMute);
                    });
                  }}
                  className={classes.clearBtn}
                >
                  {mute ? (
                    <VolumeOff fontSize="small" />
                  ) : (
                    <VolumeUp fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </span>
          </Box>
          <Box>
            <Link to="/notifications" style={{ textDecoration: "none" }}>
              <Button
                className={classes.viewAllBtn}
                onClick={isShown.close}
                size="small"
              >
                View All
              </Button>
            </Link>
            <Tooltip title="Clear All Notification" arrow>
              <IconButton
                size="small"
                className={classes.clearBtn}
                onClick={() => setConfirmationDialog(true)}
              >
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          className={classes.TabMenu}
          onChange={tabOnChangeHandler}
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          {notificationOptionTabs}
        </Tabs>
        <Box className={classes.notifContainer} id="notificationScrollable">
          <InfiniteScroll
            dataLength={() => setupInfiniteScrollHandler().lengthOfData}
            next={() => setupInfiniteScrollHandler().next}
            hasMore={() => setupInfiniteScrollHandler().hasMore}
            scrollableTarget="notificationScrollable"
          >
            {showActiveTabNotificationsHandler()}
          </InfiniteScroll>
        </Box>
      </Container>
      <ConfirmationDialog
        toggle={confirmationDialog}
        close={() => setConfirmationDialog(false)}
        fn={() => clearNotificationHandler()}
        title="Clear All Notification"
        content="Are you sure you want to proceed with this action?"
      />
    </>
  );
};

// tab options
const notificationOptions = ["System Response", "Trip", "GPS Alert"];

// styling for ui components on notifications.
const useStyles = makeStyles(theme => ({
  headerBox: {
    padding: "1rem 1rem 0 1rem",
    marginBottom: "3%"
  },
  containerNotifHeader: {
    backgroundColor: "#FFFFFF"
  },
  titleNotif: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1
  },
  viewAllBtn: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 11,
    color: "#FFFFFF",
    borderRadius: 30,
    width: 90,
    textTransform: "none",
    transition: "1s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    }
  },
  tabOptions: {
    fontSize: 11,
    color: "#BEBEBE",
    letterSpacing: 1
  },
  tabBtn: {
    width: 120,
    minWidth: "auto",
    transition: "0.5s",
    backgroundColor: "transparent !important",
    borderBottom: "1px solid #F6F6F6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0"
  },
  tabLabel: {
    textTransform: "none",
    letterSpacing: 1,
    whiteSpace: "nowrap",
    fontSize: "500"
  },
  customBadgeInActive: {
    backgroundColor: "#F6F6F6",
    borderRadius: 5,
    padding: 3,
    fontSize: 9,
    color: "#B5b5b5",
    fontWeight: "400"
  },
  customBadgeActive: {
    backgroundColor: "#E52E2D",
    borderRadius: 5,
    padding: 3,
    fontSize: 9,
    color: "#FFFFFF",
    fontWeight: "400"
  },
  notifContainer: {
    maxHeight: "390px",
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "#F9F9F9",
    transition: "0.5s",
    "&:hover": {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5,
        backgroundColor: "#F9F9F9 !important",
        borderRadius: "0px !important"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F9F9F9 !important",
        border: "none !important"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
        border: "0px solid  rgba(0, 0, 0, 0) !important",
        borderRadius: 0
      }
    }
  },
  notifBox: {
    margin: "0.5rem",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: "rgba(229, 229, 229, 0.5)"
    }
  },
  unreadNotif: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,

    marginRight: 10
  },
  notificationDescription: {
    color: "#3e3e3e",
    fontSize: 12
  },
  notificationTime: {
    color: "rgba(190, 190, 190, 0.7)",
    fontSize: 11
  },
  coordinatesNotif: {
    color: "#4F9AFF",
    fontSize: 10
  },
  coordinatesNotifError: {
    color: "#E52E2D",
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    gap: 5
  },
  notificationMenuIcon: {
    marginRight: 5,
    color: theme.palette.primary.dark,
    fontSize: 18
  },
  clearBtn: {
    marginLeft: 5,
    color: "#B5B5B5",
    backgroundColor: "#F6F6F6",
    transition: "0.5s",
    opacity: "1",
    "&:hover": {
      opacity: "0.5"
    }
  },
  notif_vehicle_plate_no: {
    fontSize: 10,
    letterSpacing: 1,
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  dwell_text: {
    color: "#5F9DF7",
    fontSize: 11    
  },
}));

export default Notification;
