import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import moment from "moment";
import Cookie from "js-cookie";
import { useMutation } from "@apollo/client";
import { GET_BOOKINGS_v2 } from "../../../../api/bookingRepository";
import {
  MANUAL_COMPLETE,
  ADD_NOTIFICATION
} from "../../../../graphql/Mutations";
import { GET_NOTIFICATIONS_QUERY } from "../../../../graphql/Queries";
import closeTrip from "../functions/closeTrip";
import AddLogs from "../../../../utils/functions/AddLogs";
import useUserContext from "../../../../context/User/useUserContext";

const MySwal = withReactContent(swal);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px",
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px",    
  },
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13,
  },
}));

const CloseDialog = props => {
  const classes = useStyles();
  const { toggle, tripData, queryFilter, open, moduleName } = props;
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const [closeTripMutation] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      toggle(false);
      MySwal.fire({
        icon: "success",
        // title: <p>Hello World</p>,
        text: "Closed.",
        timer: 3000,
        showConfirmButton: false,
        // onOpen: () => {
        //   // `MySwal` is a subclass of `Swal`
        //   //   with all the same instance & static methods
        //   // MySwal.clickConfirm();
        // }
        onAfterClose: () => {
          // fn();
          window.location.reload();
        }
      });
      AddLogs(
        moduleName,
        'close_trip',
        tripData.rawTripData.key
      )
    },
    refetchQueries: () => {
      return [{ query: GET_BOOKINGS_v2, variables: queryFilter }];
    }
  });

  const [addNotif] = useMutation(ADD_NOTIFICATION);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.closeContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          <span  className={classes.closeTitle}>Close?</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={classes.closeDescription}>Are you sure you want to close this trip?</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.circularButtonNo}
            variant="outlined"
            color="secondary"
            onClick={() => toggle(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.circularButtonYes}
            onClick={() => closeTrip(tripData, closeTripMutation, addNotif)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CloseDialog;
