import React from "react";
import { Typography, Toolbar } from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";

export const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative",
    fontWeight: "600",
    letterSpacing: "1.25px"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { moduleName, children } = props;

  return (
    <Toolbar
      style={{ borderBottom: "2px solid #c9c9c930", boxSizing: "border-box", backgroundColor: "#FFFFFF" }}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {moduleName}
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
