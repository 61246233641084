/*
 * this custom hook is use for delaying an input when searching through a query.
 * searching would not be done with every keystroke but when the user stops typing and that is the only time the query will execute.
 */

import { useEffect, useState } from "react";
const useDebounce = (initialValue = "", delay) => {
  const [actualValue, setActualValue] = useState(initialValue);
  const [debounceValue, setDebounceValue] = useState(initialValue);

  useEffect(() => {
    const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
    return () => clearTimeout(debounceId);
  }, [actualValue, delay]);
  
  return [debounceValue, setActualValue];
};

export default useDebounce;
