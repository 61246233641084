import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { Typography, Box, makeStyles } from "@material-ui/core";


/*
*** create a pie chart that will represent the count on dashboard.
*/
const StatusChart = props => {
  const { dataValue, chartTitle } = props
  const classes = useStyles();

  return (
    <>
      <div className={classes.box}>
        <Typography className={classes.box__label}>{chartTitle}</Typography>
      </div>
      <div>
        <Doughnut
          data={{
            labels: dataValue.labels,
            datasets: [
              {
                data: dataValue.data,
                backgroundColor: dataValue.backgroundColor,
              }
            ],
            hoverOffset: 4
          }}
          height="150px"
          options={config}
        />
      </div>
    </>
  );
};

export default StatusChart;

// options for the chart
const config = {
  maintainAspectRatio: false,
  aspectRatio: 1,
  plugins: {
    legend: {
      align: "start",
      display: true,
      title: {
        text: "Legends",
        position: "start",
        display: true
      },
      labels: {
        color: "#3e3e3e",
        textAlign: "left",
        boxWidth: 15,
        boxHeight: 15,
        padding: 2,
        borderRadius: 5
      },
      position: "left"
    }
  }
};

// styling for this module.
const useStyles = makeStyles(theme => ({
  box: {
    margin: "0.5rem 0 1rem 0",
    padding: "0.5rem 1rem",
    backgroundColor: "#f6f6f6",
    borderRadius: 10
  },
  box__label: {
    color: theme.palette.primary.dark,
    fontSize: 12
  }
}));
