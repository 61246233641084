import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core";
import _ from "lodash";
import XLSX from "xlsx";
import {
  CloudUpload,
  Publish,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/styles";
import { useMutation, useQuery } from "@apollo/client";
import { UPLOAD_ALERT_DESCRIPTIONS } from "../../../../../graphql/Mutations";
import { GET_ALERT_DESCRIPTIONS } from "../../../../../graphql/Queries";
import AddLogs from "../../../../../utils/functions/AddLogs";
// import validate from "../../../../../utils/validation";

const useStyles = makeStyles({
  typography: {
    fontSize: 12
  }
});

const Upload = props => {
  const { open, getQueryConditions, setUploadOpen } = props;
  const classes = useStyles();
  const uploaderEl = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [varst, setVariable] = useState("");
  // const [initData, setInitData] = useState();

  const [addAlertDescription] = useMutation(UPLOAD_ALERT_DESCRIPTIONS, {
    onCompleted(res) {
      if (res.add_alert_descriptions[0].success) {
        AddLogs("Admin - Alert Descriptions", "upload", "");
      }
    },
    refetchQueries: () => {
      return [
        {
          query: GET_ALERT_DESCRIPTIONS,
          variables: getQueryConditions()
        }
      ];
    }
  });

  const handleUpload = e => {
    const { files: raw_files } = e.target;
    const uploaded_files = [];
    _.forEach(raw_files, file => {
      uploaded_files.push({ file: file, status: "", errors: [] });
    });
    setFiles(uploaded_files);
  };

  const getVariables = () => {
    const variables = {
      condition: [
        {
          field: "code",
          value: varst
        }
      ]
    };
    return variables;
  };

  const { data: check, refetch } = useQuery(GET_ALERT_DESCRIPTIONS, {
    variables: getVariables(),
    skip: !varst
  });

  // React.useEffect(() => {
  //   if (check) {
  //     setInitData(check.get_alert_descriptions.count);
  //   }
  // }, [check]);

  const parseRow = (data, headers) => {
    const errors = [];
    let temp = {};
    _.forEach(headers, (key, index) => {
      Object.assign(temp, { [key]: data[index] });
    });

    if (
      /[^a-zA-Z0-9\r\n ]/.test(temp.code) ||
      /[^a-zA-Z0-9\r\n ]/.test(temp.description)
    ) {
      setVariable(temp.parent);
      refetch(getVariables());
      if (check?.get_alert_descriptions.count === 0) {
        temp = {
          ...temp,
          errors: [...errors],
          is_valid: false,
          parent: "Parent Alert Description doesn't exist"
        };
      } else {
        temp = {
          ...temp,
          errors: [...errors],
          is_valid: false
        };
      }
    } else {
      temp = {
        ...temp,
        code: temp.code ? temp.code.toString() : "",
        description: temp.description ? temp.description.toString() : "",
        parent: temp.parent,
        is_valid: true
      };
    }

    if (errors.length > 0) {
      temp = {
        ...temp,
        errors: [...errors],
        is_valid: false
      };
    }
    return temp;
  };

  const submitFiles = async () => {
    setUploading(true);
    const expected_headers = [
      "Alert Description *",
      "Parent Alert Description",
      "Remarks"
    ];
    const parsed_headers = ["code", "parent", "description"];
    const temp = [...files];
    _.forEach(files, (file, index) => {
      const reader = new FileReader();
      reader.onload = async e => {
        const dataRes = new Uint8Array(e.target.result);
        const workbook = XLSX.read(dataRes, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false
        });

        if (data[0].join(",") === expected_headers.join(",")) {
          if (file.file.size / 1024 / 1024 > 5) {
            temp[index].status = "Failed";
            temp[index].errors = "File is too large";
            setFiles(temp);
            setUploading(false);
          } else if (data.length <= 1) {
            temp[index].status = "Failed";
            temp[index].errors = "File is empty";
            setFiles(temp);
            setUploading(false);
          } else if (data.length >= 1002) {
            temp[index].status = "Failed";
            temp[index].errors =
              "Maximum number of rows in a file should be 1000 only";
            setFiles(temp);
            setUploading(false);
          } else {
            const valid_data = [];
            const invalid_data = [];
            const invalid_rows = [];
            const valid_rows = [];
            _.forEach(data, (d, i) => {
              if (i > 0) {
                if (d[0] === undefined) {
                  temp[index].errors = [
                    ...temp[index].errors,
                    { message: "Alert Description is required", row: i + 1 }
                  ];
                } else {
                  const v = parseRow(d, parsed_headers, i);
                  if (v.is_valid) {
                    _.unset(v, "is_valid");
                    valid_data.push(v);
                    valid_rows.push(i + 1);
                  } else {
                    invalid_data.push(v);
                    invalid_rows.push(i + 1);
                    // temp[index].status = "Failed";
                    temp[index].errors = [...temp[index].errors, ...v.errors];
                  }
                }
              }
            });

            temp[index].errors = _.sortBy(temp[index].errors, ["row"]);
            if (valid_data.length > 0) {
              addAlertDescription({
                variables: {
                  alert_descriptions: valid_data
                }
              })
                .then(({ data: newData }) => {
                  const rl = _.filter(newData.add_alert_descriptions, {
                    success: false
                  }).length;
                  if (rl === invalid_data.length + valid_data.length) {
                    temp[index].status = "Failed";
                    _.forEach(newData.add_alert_descriptions, (nv, r) => {
                      _.forEach(nv.error, err => {
                        temp[index].errors.push({
                          message:
                            err.message === "Invalid Alert Description"
                              ? "Alert Description does not exist."
                              : err.message,
                          row: valid_rows[r]
                        });
                      });
                    });
                    temp[index].errors = _.sortBy(temp[index].errors, ["row"]);
                    setFiles(temp);
                    setUploading(false);
                  } else if (rl === 0 && invalid_data.length === 0) {
                    temp[index].status = "Success";
                    setFiles(temp);
                    setUploading(false);
                  } else {
                    if (data.length - 1 === temp[index].errors.length + rl) {
                      temp[index].status = "Failed";
                      _.forEach(newData.add_alert_descriptions, (nv, r) => {
                        _.forEach(nv.error, err => {
                          temp[index].errors.push({
                            message:
                              err.message === "Invalid Alert Description"
                                ? "Alert Description does not exist."
                                : err.message,
                            row: valid_rows[r]
                          });
                        });
                      });

                      for (let i = 0; i < invalid_data.length; i += 1) {
                        temp[index].errors.push({
                          message: "Numbers and Letters only",
                          row: invalid_rows[i]
                        });
                      }
                    } else {
                      _.forEach(newData.add_alert_descriptions, (nv, r) => {
                        _.forEach(nv.error, err => {
                          temp[index].errors.push({
                            message:
                              err.message === "Invalid Alert Description"
                                ? "Alert Description does not exist."
                                : err.message,
                            row: valid_rows[r]
                          });
                        });
                      });

                      for (let i = 0; i < invalid_data.length; i += 1) {
                        if (
                          invalid_data[i].parent ===
                          "Parent Alert Description doesn't exist"
                        ) {
                          temp[index].errors.push({
                            message: "Parent Alert Description doesn't exist",
                            row: invalid_rows[i]
                          });
                        }
                        temp[index].errors.push({
                          message: "Numbers and Letters only",
                          row: invalid_rows[i]
                        });
                      }

                      if (data.length - 1 <= temp[index].errors.length) {
                        temp[index].status = "Failed";
                      } else {
                        temp[index].status = "Partially Completed";
                      }
                    }

                    temp[index].errors = _.sortBy(
                      [...new Set(temp[index].errors)],
                      ["row"]
                    );

                    setFiles(temp);
                    setUploading(false);
                  }
                })
                .catch(() => {
                  Swal.fire({
                    icon: "error",
                    text: "Something went wrong"
                  });
                });
            } else if (valid_data.length === 0) {
              temp[index].status = "Failed";
              if (
                temp[index].errors.some(
                  x => x.message === "Alert Description is required"
                )
              ) {
                temp[index].errors = "Alert Description is required";
              } else {
                temp[index].errors = "Numbers and Letters only";
              }
              setFiles(temp);
              setUploading(false);
            }
          }
        } else {
          temp[index].status = "Failed";
          temp[index].errors = "Please use the template provided";
          setFiles(temp);
          setUploading(false);
        }
      };
      reader.readAsArrayBuffer(file.file);
    });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { width: 600, height: 405 } }}
      onClose={() => {
        if (!uploading) {
          setUploadOpen(false);
          setFiles([]);
        }
      }}
    >
      <DialogTitle>Upload</DialogTitle>
      <DialogContent dividers>
        {files.length > 0 &&
          files.map(f => (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={f.errors.length ? <ExpandMoreIcon /> : ""}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.typography}>
                      {f.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.typography}>{`${(
                      f.file.size /
                      1024 /
                      1024
                    ).toFixed(2)}mb`}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.typography}>
                      {f.status}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {typeof f.errors !== "string" ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "100px" }}>Row</TableCell>
                        <TableCell align="left">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {f.errors.map(error => (
                        <TableRow>
                          <TableCell>{error.row}</TableCell>
                          <TableCell>{error.message}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <List>
                    <ListItem>
                      <ListItemText primary={f.errors} />
                    </ListItem>
                  </List>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        <TextField
          fullWidth
          inputProps={{
            type: "file",
            multiple: false,
            accept: ".xlsx,.csv,.xls",
            ref: uploaderEl
          }}
          style={{ display: "none" }}
          onChange={handleUpload}
        />
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={() => uploaderEl.current.click()}
              startIcon={<Publish />}
              style={{
                backgroundColor: "#ffb677",
                color: "white",
                borderRadius: 32
              }}
            >
              ADD FILE
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => submitFiles()}
              disabled={uploading || files.length === 0}
              variant="contained"
              startIcon={<CloudUpload />}
              color="primary"
              style={{ float: "right", borderRadius: 50 }}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Upload;
