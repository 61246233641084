/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
  // TextField,
  Tooltip,
  InputLabel,
  Badge
} from "@material-ui/core";
import {
  // KeyboardArrowLeft as KeyboardArrowLeftIcon,
  // KeyboardArrowRight as KeyboardArrowRightIcon,
  Visibility as VisibilityIcon,
  FilterList as FilterListIcon,
  Notifications as NotificationsIcon
  // ArrowUpwardRounded as ArrowUpwardRoundedIcon
} from "@material-ui/icons";
// import { Autocomplete } from "@material-ui/lab";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
// import { LayoutContext } from "../../../Layout";
import SearchContext from "../../../../context/SearchContext";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
import AccessControl from "../../../../utils/AccessControl";
import Filter from "../../Filter";
import {
  // GET_ALERT_REFERENCES,
  GET_ADMIN_ALERT_REFERENCES
  // GET_ADMIN_CLIENTS
} from "../../../../graphql/Queries";
import { EDIT_ADMIN_CLIENT } from "../../../../graphql/Mutations";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";
import useFormStyles from "../../../../styles/useFormStyles";
import ClientAutocomplete from "../../../../utils/ClientAutocomplete";
import AlertSoundModal from "./AlertSoundModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import updateCookie from "../../../../utils/functions/updateCookie";

const Highlight = require("react-highlighter");

function createData(role, description) {
  return {
    role,
    description
  };
}

const rows = [
  createData("Cupcake", "Cupcake"),
  createData("Cupcake", "Donut"),
  createData("Cupcake", "Cupcake"),
  createData("Cupcake", "Donut")
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "alert_descriptions.code",
    numeric: false,
    disablePadding: false,
    label: "Alert Description"
  },
  {
    id: "alertmessage",
    numeric: false,
    disablePadding: false,
    label: "Message Display"
  },
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "Alert level"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, handleSort } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            align="left"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            // classes={{ icon: classes.icon }}
            // IconComponent={ArrowUpwardRoundedIcon}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    display: "flex",
    position: "relative"
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children, setGroupFilter, groupFilter } = props;

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Alerts
      </Typography>
      {/* <Autocomplete
        // multiple
        className={classes.autoComplete}
        style={{ height: 30, marginLeft: 30, width: 220 }}
        disableCloseOnSelect
        options={client}
        getOptionLabel={option => option.name}
        getOptionSelected={(o, v) => {
          if (v.name === o.name) return o.name;
          return {};
        }}
        renderInput={params => <TextField {...params} placeholder="All" />}
        disableClearable
        value={groupFilter}
        onChange={(e, nv) => {
          setGroupFilter(nv);
          setFilterGroupId(nv.group_ids);
        }}
      /> */}
      <ClientAutocomplete groupFilter={groupFilter} setter={setGroupFilter} />
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  icon: {
    opacity: 1
  },
  loading: {
    width: 300,
    height: 300
  },
  alert_sound: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  }
}));

const geofencesRelatedIds = [
  "83",
  "81",
  "97",
  "85",
  "84",
  "82",
  "98",
  "86",
  "137",
  "63",
  "60",
  "134",
  "136",
  "133",
  "58",
  "59"
]

export default function Alerts() {
  const classes = useStyles();
  const formStyles = useFormStyles();
  // const context = useContext(LayoutContext);
  const contextData = useContext(SearchContext);
  const user = JSON.parse(Cookie.get("user"));
  const history = useHistory()
  const filter_history = history?.location?.state?.params;
  const [clearFirst, setClearFirst] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("description");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const defaultGroupFilter = {
    id: user.group_ids[0],
    name: user.group_names[0],
    group_ids: user.group_ids[0]
  };
  const [groupFilter, setGroupFilter] = useState(defaultGroupFilter);
  const [filterGroupId, setFilterGroupId] = useState([]);
  const [filter, setFilter] = useState({
    level: null,
    alertType: ""
  });
  const [tempFilter, setTempFilter] = useState({
    level: "",
    alertType: ""
  });
  const [keyword, setKeyword] = useState("");

  // const [client, setClient] = useState([]);
  const [canDelete, setCanDelete] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  // const [keyLog, setKeyLog] = useState("Alert Level: All");
  // const [circularLoading, setCircularLoading] = useState(false);
  const [openSoundModal, setOpenSoundModal] = useState(false);

  const getVariables = () => {
    let variables = {};

    variables = {
      keyword: keyword,
      skip: 0,
      first: rowsPerPage,
      orderBy: { field: orderBy, direction: order }
    };

    if (filterGroupId.length > 0) {
      Object.assign(variables, {
        group_ids: parseInt(filterGroupId, 10)
      });
    } else {
      Object.assign(variables, {
        group_ids: parseInt(user.group_ids[0], 10)
      });
    }

    if (filter.level) {
      Object.assign(variables, {
        filter: [{ field: "level", value: filter.level }]
      });
    }

    if (filter.alertType === "0") {
      if (!Array.isArray(variables.filter)) variables.filter = [];

      geofencesRelatedIds.filter(id => {
        variables.filter.push({
          field: "alert_descriptions.id",
          value: id
        })
      })
    } else if (filter.alertType === "1") {
      if (!Array.isArray(variables.not)) variables.not = [];

      geofencesRelatedIds.filter(id => {
        variables.not.push({
          field: "alert_descriptions.id",
          value: id
        })
      })
    }

    return variables;
  };

  const [editClientSound] = useMutation(EDIT_ADMIN_CLIENT, {
    onCompleted: res => {
      setOpenSoundModal(false);
      if (res.edit_client.success) {
        const {
          alert_level0_sound,
          alert_level1_sound,
          alert_level2_sound,
          alert_level3_sound
        } = res.edit_client.client.alert_sounds;
        AddLogs("Admin - Alerts", "update", [
          `Discrete: ${alert_level0_sound || "No Sound"}`,
          `Normal: ${alert_level1_sound || "No Sound"}`,
          `Moderate: ${alert_level2_sound || "No Sound"}`,
          `Critical: ${alert_level3_sound || "No Sound"}`
        ]);
        updateCookie("user",
          {
            ...user,
            client: {
              ...user.client,
              alert_sounds: res.edit_client.client.alert_sounds
            }
          }
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  });

  const { loading: loadingData, error, data, fetchMore, refetch } = useQuery(
    GET_ADMIN_ALERT_REFERENCES,
    {
      variables: getVariables(),
      onCompleted: () => {
        setLoading(false);
        if (keyword) {
          AddLogs("Admin - Alerts", "search", searchKeyword.value);
        }
      },
      fetchPolicy: "cache-and-network"

    }
  );

  useEffect(() => {
    setFilterGroupId(groupFilter?.group_ids);
    setPage(1)
  }, [groupFilter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };



  const isSelected = name => selected.indexOf(name) !== -1;

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleFilters = e => {
    const { name, value } = e.target;
    setTempFilter({ ...tempFilter, [name]: value });
  };

  useEffect(() => {
    if (filter_history?.vars?.tempFilter) {
      setFilter(filter_history?.vars?.tempFilter)
      setTempFilter(filter_history?.vars?.tempFilter)
    }
  }, [])

  const handleSetFilters = () => {
    setFilter(tempFilter);
    closeFilterDrawer();
    let temp = "";
    if (tempFilter.level.toString() === "0") {
      temp = "Alert Level: Discrete";
    } else if (tempFilter.level.toString() === "1") {
      temp = "Alert Level: Normal";
    } else if (tempFilter.level.toString() === "2") {
      temp = "Alert Level: Moderate";
    } else if (tempFilter.level.toString() === "3") {
      temp = "Alert Level: Critical";
    } else {
      temp = "Alert Level: All";
    }

    let tempType = "";
    if (tempFilter.alertType.toString() === "0") {
      tempType = "Alert Type: Geofence";
    } else if (tempFilter.alertType.toString() === "1") {
      tempType = "Alert Type: GPS Related";
    } else {
      tempType = "Alert Type: All";
    }
    AddLogs(process.env.REACT_APP_ALERTS_MODULE, "filter", `${temp}, ${tempType}`);
  };

  const handleClearFilter = () => {
    setFilter({ level: "", alertType: "" });
    setTempFilter({ ...tempFilter, level: "", alertType: "" });
    AddLogs(process.env.REACT_APP_ALERTS_MODULE, "filter", "Alert Level: All, Alert Type: All");
  };

  const getTextual = id => {
    let text = "";
    switch (id) {
      case 0:
        text = (
          <span style={{ color: "#9e9e9e" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Discrete
              </Highlight>
            </b>
          </span>
        );
        break;
      case 1:
        text = (
          <span style={{ color: "#63F160" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Normal
              </Highlight>
            </b>
          </span>
        );
        break;
      case 2:
        text = (
          <span style={{ color: "#ffd600" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Moderate
              </Highlight>
            </b>
          </span>
        );
        break;
      case 3:
        text = (
          <span style={{ color: "#ff5722" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Critical
              </Highlight>
            </b>
          </span>
        );
        break;
      default:
        break;
    }
    return text;
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  useEffect(() => {
    if (data) {
      const { get_alert_references } = data;
      setTotalPage(
        get_alert_references.total
          ? Math.ceil(get_alert_references.total / rowsPerPage)
          : 1
      );
    }
  }, [data]);



  const fetchMoreData = nextPage => {
    if (
      nextPage * rowsPerPage >=
      data.get_alert_references.alert_references.length
    ) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          skip: nextPage * rowsPerPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            get_alert_references: {
              count: fetchMoreResult.get_alert_references.count,
              total: fetchMoreResult.get_alert_references.total,
              alert_references: [
                ...(prev?.get_alert_references
                  ? prev?.get_alert_references?.alert_references
                  : []),
                ...fetchMoreResult.get_alert_references.alert_references
              ]
            }
          };
        }
      })
        .then(() => setLoading(false))
        .catch(e => {
          Swal.fire({
            title: "Something went wrong",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              setPage(1);
              refetch(getVariables());
            }
          });
        });
    }
  };

  if (error) {
    return <ErrorPage errorCode={error.networkError.response} />;
  }
  return (
    <AccessControl
      resource="/admin/activity/alerts/"
      process="tables"
      setCanDelete={setCanDelete}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            setGroupFilter={setGroupFilter}
            groupFilter={groupFilter}
          >
            <Button
              startIcon={<NotificationsIcon />}
              variant="contained"
              className={classes.alert_sound}
              onClick={() => setOpenSoundModal(true)}
            >
              Alert Sound
            </Button>
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={openFilterDrawer}>
                <Badge
                  color="secondary"
                  variant={filter.level || filter.alertType ? "dot" : "standard"}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={
                data?.get_alert_references?.alert_references
                  ? data?.get_alert_references?.alert_references.length
                  : 0
              }
              fetchMoreData={fetchMoreData}
              totalRows={data?.get_alert_references?.total || 0}
              totalPage={totalPage}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setCircularLoading}
              setLoading={setLoading}
            />

          </EnhancedTableToolbar>
          {selected.length > 0 && (
            <Paper
              style={{
                display: "flex",
                backgroundColor: "gray",
                paddingLeft: 16,
                position: "relative",
                height: "40px",
                alignItems: "center"
              }}
              square
            >
              <Typography>
                {selected.length} user is selected on this page
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ position: "absolute", right: 0 }}
              >
                Delete Selected
              </Button>
            </Paper>
          )}
          {loadingData || loading ? (
            <Loading />
          ) : (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  handleSort={handleSort}
                />
                <TableBody>
                  {data?.get_alert_references?.alert_references
                    ? stableSort(
                      data?.get_alert_references?.alert_references,
                      getSorting(order, orderBy)
                    )
                      .slice(
                        Number(page - 1) * rowsPerPage,
                        Number(page - 1) * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row.alert_description.code}
                              </Highlight>
                            </TableCell>
                            <TableCell align="left">
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row.alertmessage}
                              </Highlight>
                            </TableCell>
                            <TableCell align="left">
                              {getTextual(row.level)}
                            </TableCell>
                            <TableCell
                              align="center"
                              onClick={() => {
                                setSearchKeyword({
                                  ...searchKeyword,
                                  value: ""
                                });
                                AddLogs(
                                  process.env.REACT_APP_ALERTS_MODULE,
                                  "view",
                                  row.alert_description.code
                                );
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  localStorage.setItem(
                                    "groupId",
                                    groupFilter.group_ids
                                  );
                                }}
                              >
                                <Link
                                  disabled={!canDelete}
                                  style={{ color: "#757575" }}
                                  aria-label="Back"
                                  to={{
                                    pathname: `/admin/activity/alerts/view/${row.id}`,
                                    state: {
                                      groupFilter,
                                      vars: {
                                        tempFilter
                                      }
                                    }
                                  }}
                                // onClick={() => {
                                //   localStorage.setItem(
                                //     "groupId",
                                //     groupFilter.id
                                //   );
                                // }}
                                >
                                  <VisibilityIcon />
                                </Link>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
              {!data.get_alert_references.alert_references && (
                <span
                  style={{
                    width: "100%",
                    height: 72,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography>No data found</Typography>
                </span>
              )}
            </TableContainer>
          )}
        </Paper>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_ALERTS_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink required>
              Alert Level
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.level}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="level"
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="0">Discrete</MenuItem>
              <MenuItem value="1">Normal</MenuItem>
              <MenuItem value="2">Moderate</MenuItem>
              <MenuItem value="3">Critical</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink required>
              Alert Type
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.alertType}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="alertType"
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="0">Geofence</MenuItem>
              <MenuItem value="1">GPS Related</MenuItem>
            </Select>
          </FormControl>
        </Filter>
        {openSoundModal && (
          <AlertSoundModal
            toggle={openSoundModal}
            close={() => setOpenSoundModal(false)}
            handleSubmit={editClientSound}
          />
        )}
      </div>
    </AccessControl>
  );
}
