/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { FormControl, Checkbox, Typography } from "@material-ui/core";

import useFormStyles from "../../../styles/useFormStyles";

const ActivityOnlyInput = React.forwardRef((props, ref) => {
  const { readOnly, initialValues } = props;
  const formStyles = useFormStyles();
  const [activityOnly, setActivityOnly] = useState(initialValues || false);
  return (
    <FormControl className={formStyles.formRoot}>
      <div className={formStyles.checkBox}>
        <Typography className={formStyles.checkBoxTitle}>
          Activity Only
        </Typography>
        <Checkbox
          color="primary"
          disabled={readOnly}
          checked={activityOnly}
          value={activityOnly}
          onChange={(_, val) => {
            ref.current = val;
            setActivityOnly(val);
          }}
          name="activityLogs"
        />
      </div>
    </FormControl>
  );
});

export default ActivityOnlyInput;
