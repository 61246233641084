import React, { useRef, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const SharelinkDialog = props => {
  const { toggle, title, content, generatedLink, fn, close } = props;
  const [copied, setCopied] = useState(false);
  const textfieldRef = useRef();
  const handleClose = () => {
    setCopied(false);
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
            <TextField
              style={{ padding: "16px 0" }}
              value={generatedLink}
              fullWidth
              readOnly
              variant="outlined"
              inputProps={{
                style: { fontSize: 20, padding: 4 },
                ref: textfieldRef
              }}
            />
            {copied && (
              <Alert variant="outlined" severity="success">
                Copied to clipboard!
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {generatedLink ? (
            <Button
              style={{
                borderRadius: 100,
                color: "white",
                width: 150
              }}
              variant="contained"
              onClick={() => {
                textfieldRef.current.select();
                document.execCommand("copy");
                setCopied(true);
              }}
              color="primary"
              autoFocus
            >
              Copy
            </Button>
          ) : (
            <Button
              style={{
                borderRadius: 100,
                color: "white",
                width: 150
              }}
              variant="contained"
              onClick={() => {
                fn();
              }}
              color="primary"
              autoFocus
            >
              Generate
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SharelinkDialog;
