
import React from "react";
import { Button, IconButton, Snackbar } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const UndoSnackbar = (props) => {
  const {
    anchorOrigin = { vertical: "bottom", horizontal: "right" },
    classes = {},
    handleClose = () => {},
    handleAction = () => {},
    ...other
  } = props;

  return (
    <Snackbar
      {...other}
      anchorOrigin={anchorOrigin}
      autoHideDuration={2500}
      onClose={handleClose}
      action={
        <React.Fragment>
          <Button
            className={classes.undoBtn}
            style={{ color: "white", textTransform: "uppercase" }}
            size="small"
            onClick={handleAction}
          >
            Undo
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
};

export default UndoSnackbar;