import React from "react";
import { CircularProgress } from "@material-ui/core";

const LoadingElement = () => {
  return (
    <div
      style={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingElement;
