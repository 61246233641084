/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Drawer,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Typography,
  Select,
  InputAdornment,
  MenuItem,
  Button,
  TextField,
  makeStyles,
  useTheme
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Close as CloseIcon,
  Search as SearchIcon,
  FilterList,
  ExpandMoreOutlined as ExpandMoreOutlinedIcon
} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useQuery } from "@apollo/client";
import {
  GET_BOOKING_GEOFENCES,
  GET_VEHICLES_LOOKUP,
  GET_BOOKING_HAULERS
} from "../../graphql/Queries";
import SearchContext from "../../context/SearchContext";
import useDebounce from "../../utils/hooks/useDebounce";
import _ from "lodash";
import Cookies from "js-cookie";
import useUserContext from "../../context/User/useUserContext";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 325,
    overflow: "hidden"
  },
  filterInput: {
    marginBottom: "20px",
    minWidth: "250px",
    width: "250px",
    "& .MuiAutocomplete-popupIndicator": {
      transform: "rotate(0) !important"
    },
    "& .MuiAutocomplete-inputRoot": {
      fontSize: 13,
      color: theme.palette.primary.dark
    }
  },
  textStyle: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  clearAllBtn: {
    color: "#808080",
    fontWeight: "600",
    textTransform: "none",
    fontSize: 13
  },
  titleField: {
    fontSize: 13,
    fontWeight: 600,
    fontFamily: "Nunito",
    lineHeight: "18px",
    color: theme.palette.primary.dark
  },
  applyBtn: {
    borderRadius: 32,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    }
  },
  formContainer: {
    overflow: "hidden",
    height: "75vh",
    padding: "0 2rem",
    "&:hover": {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5,
        backgroundColor: "#F9F9F9 !important",
        borderRadius: "0px !important"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F9F9F9 !important",
        border: "none !important"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
        border: "0px solid  rgba(0, 0, 0, 0) !important",
        borderRadius: 0
      }
    }
  }
}));

const Filter = props => {
  const theme = useTheme();
  const {
    drawer,
    toggleDrawer,
    applyFilter,
    loaded,
    serviceTypes,
    moduleName
  } = props;
  const classes = useStyles();
  const inititalFilterValues = {
    dateRange: {
      start: null,
      end: null,
      field: "pickups.arrival"
    },
    pickup_location: null,
    dropoff_location: null,
    hauler: null,
    service_type: "All",
    status: "All",
    vehicle_id: null,
    clear: false,
    dispatch_type: "All",
    groups: "",
    is_inter_island: "All"
  };

  const contextData = useContext(SearchContext);
  const user_context = useUserContext();

  const [pickups, setPickups] = useState([]);
  const [dropoffs, setDropoffs] = useState([]);
  // const [haulers, setHaulers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const debounceTime = 1500;
  const [pickupKeyword, setPickupKeyword] = useDebounce("", debounceTime);
  const [dropoffKeyword, setDropoffKeyword] = useDebounce("", debounceTime);
  const [haulerKeyword, setHaulerKeyword] = useDebounce("", debounceTime);
  const [vehicleKeyword, setVehicleKeyword] = useDebounce("", debounceTime);

  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(pickupKeyword ? { keyword: pickupKeyword } : {}),
      category: "pickup"
    },
    onCompleted: data => {
      const { get_booking_geofences } = data;
      setPickups(
        get_booking_geofences.map(g => {
          return {
            value: g.geofence_id,
            label: g.name
          };
        })
      );
    }
  });

  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(dropoffKeyword ? { keyword: dropoffKeyword } : {}),
      category: "dropoff"
    },
    onCompleted: data => {
      const { get_booking_geofences } = data;
      setDropoffs(
        get_booking_geofences.map(g => {
          return {
            value: g.geofence_id,
            label: g.name
          };
        })
      );
    }
  });
  // set status options for module type.
  const optionsStatus = useCallback(() => {
    let options = ["All"];

    if (moduleName === "Trips") {
      options.push(
        "Incoming",
        "Arrived at Pickup",
        "In Transit",
        "Arrived at Drop off",
        "Completed"
      );
    } else {
      options.push("Processing");
    }

    options.push("Closed", "Cancelled");

    return options;
  }, [moduleName]);
  const tripTypeOpt = [
    { name: "All", value: null },
    { name: "Standard", value: false },
    { name: "Interisland", value: true }
  ];

  // const { loading: hauler_loader } = useQuery(GET_BOOKING_HAULERS, {
  //   variables: {
  //     ...(haulerKeyword ? { keyword: haulerKeyword } : {}),
  //     first: 5
  //   },
  //   onCompleted: data => {
  //     const { get_booking_haulers } = data;
  //     setHaulers(
  //       get_booking_haulers.map(c => {
  //         return {
  //           value: c.client_name,
  //           label: c.client_name
  //         };
  //       })
  //     );
  //   },
  //   fetchPolicy: "network-only"
  // });

  const { loading: vehicle_loader } = useQuery(GET_VEHICLES_LOOKUP, {
    variables: {
      // keyword: vehicleKeyword,
      filter: [{ field: "vehicle_info.plateno", value: vehicleKeyword }],
      first: 15
    },
    onCompleted: data => {
      const { get_vehicles } = data;
      setVehicles(
        get_vehicles.vehicles.map(v => {
          return {
            label: v.plateno,
            value: v.device_id
          };
        })
      );
    },
    // fetchPolicy: "network-only"
  });

  const [filterValues, setFilterValues] = useState(inititalFilterValues);

  const handleFilter = e => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  const resetFilters = () => {
    setFilterValues(inititalFilterValues);
    applyFilter({ ...inititalFilterValues, clear: true });
  };

  useEffect(() => {
    if (
      _.isEqual(moduleName, "Prebooking") &&
      localStorage.getItem("prebooking_filter")
    ) {
      const savedFilter = JSON.parse(localStorage.getItem("prebooking_filter"));
      setFilterValues(savedFilter);
    }
    if (_.isEqual(moduleName, "Trips") && localStorage.getItem("filter")) {
      const savedFilter = JSON.parse(localStorage.getItem("filter"));
      setFilterValues(savedFilter);
    }
  }, []);

  // useEffect(() => {
  //   if (contextData.searchKeyword.submit === true && loaded) {
  //     setFilterValues(inititalFilterValues);
  //   }
  // }, [contextData]);

  const dateFilterOpt = [
    {
      field: "Pickup Date",
      value: "pickups.arrival",
      isShown: true
    },
    {
      field: "Delivery Date",
      value: "dropoffs.arrival",
      isShown: true
    },
    {
      field: "Dispatch Date",
      value: "dispatch_time",
      isShown: moduleName === "Trips" ? true : false
    }
  ];

  return (
    <Drawer
      open={drawer}
      onClose={() => toggleDrawer()}
      className={classes.drawer}
      classes={{
        paper: classes.drawer
      }}
      style={{ position: "relative" }}
    >
      <div style={{ flexGrow: 1 }}>
        <Toolbar style={{ backgroundColor: theme.palette.primary.main }}>
          <FilterList style={{ marginRight: "10px", color: "white" }} />
          <Typography
            variant="h5"
            color="inherit"
            category="span"
            style={{ flexGrow: 1, color: "white" }}
          >
            Filter
          </Typography>
          <IconButton style={{ color: "white" }} onClick={() => toggleDrawer()}>
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <div
          role="presentation"
          // onClick={toggleDrawer(false)}
          className={classes.list}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: "15px", padding: "0 2rem" }}
          >
            <Typography
              component="span"
              variant="body1"
              style={{ marginRight: "" }}
              className={classes.textStyle}
            >
              <Select
                value={filterValues.dateRange.field}
                onChange={e => {
                  setFilterValues({
                    ...filterValues,
                    dateRange: {
                      ...filterValues.dateRange,
                      field: e.target.value
                    }
                  });
                }}
                className={classes.textStyle}
                style={{ fontWeight: "600" }}
                IconComponent={() => (
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                )}
                disableUnderline
              >
                {dateFilterOpt.map(option => {
                  if (option.isShown) {
                    return (
                      <MenuItem
                        value={option.value}
                        className={classes.textStyle}
                      >
                        {option.field}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </Typography>
            <Button
              color="default"
              style={{ float: "right" }}
              onClick={() => resetFilters()}
              className={classes.clearAllBtn}
            >
              Clear all
            </Button>
          </Grid>
          <div className={classes.formContainer}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                className={classes.filterInput}
                id="date-from"
                name="date_from"
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                value={filterValues.dateRange.start}
                initialFocusedDate={moment().startOf("date")}
                onChange={date => {
                  if (date) {
                    const { end } = filterValues.dateRange;
                    setFilterValues({
                      ...filterValues,
                      dateRange: {
                        ...filterValues.dateRange,
                        start: date.format("YYYY-MM-DD HH:mm:ss"),
                        end: end || date.format("YYYY-MM-DD 23:59:59")
                      }
                    });
                  }
                }}
                label={<span className={classes.textStyle}>From</span>}
                InputProps={{
                  classes: {
                    input: classes.textStyle
                  }
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                className={classes.filterInput}
                id="date-to"
                name="date_to"
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                value={filterValues.dateRange.end}
                initialFocusedDate={moment().endOf("date")}
                onChange={date => {
                  if (date) {
                    const { start } = filterValues.dateRange;
                    setFilterValues({
                      ...filterValues,
                      dateRange: {
                        ...filterValues.dateRange,
                        end: date.format("YYYY-MM-DD HH:mm:ss"),
                        start: start || date.format("YYYY-MM-DD 00:00:00")
                      }
                    });
                  }
                }}
                label={<span className={classes.textStyle}>To</span>}
                InputProps={{
                  classes: {
                    input: classes.textStyle
                  }
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
            <TextField
              className={classes.filterInput}
              id="service-type-filter"
              name="service_type"
              value={filterValues.service_type || ""}
              onChange={handleFilter}
              label={<span className={classes.titleField}>Service Type</span>}
              select
              InputProps={{
                classes: {
                  input: classes.textStyle
                }
              }}
              // IconComponent={<ExpandMoreOutlinedIcon/>}
              SelectProps={{
                IconComponent: () => (
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                )
              }}
            >
              <MenuItem key="All" value="All">
                <span className={classes.textStyle}>All</span>
              </MenuItem>
              {serviceTypes &&
                serviceTypes.map((option, index) => {
                  const statusKey = `${option.value}-${index}`;
                  return (
                    <MenuItem
                      key={statusKey}
                      value={option.name}
                      className={classes.textStyle}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
            </TextField>
            {_.isEqual(moduleName, "Trips") && (
              <TextField
                className={classes.filterInput}
                id="service-type-filter"
                name="dispatch_type"
                value={filterValues.dispatch_type || ""}
                onChange={handleFilter}
                label={
                  <span className={classes.titleField}>Dispatch Type</span>
                }
                select
                InputProps={{
                  classes: {
                    input: classes.textStyle
                  }
                }}
                SelectProps={{
                  IconComponent: () => (
                    <ExpandMoreOutlinedIcon
                      style={{ color: "#808080", fontSize: 18 }}
                    />
                  )
                }}
              >
                <MenuItem
                  key="All"
                  value="All"
                  selected
                  className={classes.textStyle}
                >
                  All
                </MenuItem>
                <MenuItem
                  key="Automatic"
                  value="Automatic"
                  className={classes.textStyle}
                >
                  Auto Dispatched
                </MenuItem>
                <MenuItem
                  key="Manual"
                  value="Manual"
                  className={classes.textStyle}
                >
                  Manually Dispatched
                </MenuItem>
              </TextField>
            )}
            {/* <TextField
              className={classes.filterInput}
              id="service-type-filter"
              name="dispatch_type"
              value={filterValues.dispatch_type || ""}
              onChange={handleFilter}
              label="Dispatch Type"
              select
            >
              <MenuItem key="All" value="All" selected>
                All
              </MenuItem>
              <MenuItem key="Automatic" value="Automatic">
                Auto Dispatched
              </MenuItem>
              <MenuItem key="Manual" value="Manual">
                Manually Dispatched
              </MenuItem>
              <MenuItem key="Not Yet Dispatched" value="Not Yet Dispatched">
                Not Yet Dispatched
              </MenuItem>
            </TextField> */}
            <Autocomplete
              className={classes.filterInput}
              options={pickups}
              getOptionLabel={option => option.label}
              id="pickup-location-filter"
              onChange={(event, value) =>
                setFilterValues({
                  ...filterValues,
                  pickup_location: value
                })
              }
              popupIcon={
                <SearchIcon style={{ fontSize: 20, color: "#e9e9e9" }} />
              }
              renderOption={option => (
                <Typography className={classes.textStyle}>
                  {option.label}
                </Typography>
              )}
              value={filterValues.pickup_location}
              name="pickup_location"
              disableopenonfocus="true"
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <span className={classes.titleField}>Pickup Location</span>
                  }
                  className={classes.filterInput}
                  onChange={e => setPickupKeyword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Search"
                />
              )}
            />
            <Autocomplete
              className={classes.filterInput}
              options={dropoffs}
              popupIcon={
                <SearchIcon style={{ fontSize: 20, color: "#e9e9e9" }} />
              }
              getOptionLabel={option => option.label}
              id="delivery-location-filter"
              onChange={(event, value) =>
                setFilterValues({
                  ...filterValues,
                  dropoff_location: value
                })
              }
              renderOption={option => (
                <Typography className={classes.textStyle}>
                  {option.label}
                </Typography>
              )}
              value={filterValues.dropoff_location}
              disableopenonfocus="true"
              name="delivery_location"
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <span className={classes.titleField}>
                      Delivery Location
                    </span>
                  }
                  className={classes.filterInput}
                  onChange={e => setDropoffKeyword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Search"
                />
              )}
            />
            <Autocomplete
              className={classes.filterInput}
              // options={haulers}
              options={user_context.client.group_names}
              popupIcon={
                <SearchIcon style={{ fontSize: 20, color: "#e9e9e9" }} />
              }
              getOptionLabel={option => option}
              id="hauler-filter"
              value={filterValues.hauler}
              defaultValue=""
              // loading={hauler_loader}
              onChange={(event, value) => {
                setFilterValues({
                  ...filterValues,
                  hauler: value
                });
              }}
              renderOption={option => (
                <Typography className={classes.textStyle}>{option}</Typography>
              )}
              disableopenonfocus="true"
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <span className={classes.titleField}>Hauler/Groups</span>
                  }
                  className={classes.textStyle}
                  onChange={e => setHaulerKeyword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Search"
                />
              )}
            />
            {/* <Autocomplete
              className={classes.filterInput}
              options={user.client.group_names}
              popupIcon={
                <SearchIcon style={{ fontSize: 20, color: "#e9e9e9" }} />
              }
              getOptionLabel={option => option}
              id="vehicle-filter"
              value={filterValues.groups}
              loading={vehicle_loader}
              onChange={(event, value) =>
                setFilterValues({
                  ...filterValues,
                  groups: value
                })
              }
              renderOption={option => (
                <Typography className={classes.textStyle}>
                  {option}
                </Typography>
              )}
              disableopenonfocus="true"
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <span className={classes.titleField}>Groups</span>
                  }
                  className={classes.filterInput}
                  onChange={e => {
                    setGroupKeyword(e.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                  placeholder="All"
                />
              )}
            /> */}
            <Autocomplete
              className={classes.filterInput}
              options={vehicles}
              popupIcon={
                <SearchIcon style={{ fontSize: 20, color: "#e9e9e9" }} />
              }
              getOptionLabel={option => option.label}
              id="vehicle-filter"
              value={filterValues.vehicle_id}
              loading={vehicle_loader}
              onChange={(event, value) =>
                setFilterValues({
                  ...filterValues,
                  vehicle_id: value
                })
              }
              renderOption={option => (
                <Typography className={classes.textStyle}>
                  {option.label}
                </Typography>
              )}
              disableopenonfocus="true"
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <span className={classes.titleField}>Plate Number</span>
                  }
                  className={classes.filterInput}
                  onChange={e => setVehicleKeyword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="All"
                />
              )}
            />
            <TextField
              className={classes.filterInput}
              id="status-filter"
              name="status"
              value={filterValues.status || ""}
              onChange={handleFilter}
              label={<span className={classes.titleField}>Status</span>}
              select
              SelectProps={{
                IconComponent: () => (
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                )
              }}
              InputProps={{
                classes: {
                  input: classes.textStyle
                }
              }}
            >
              {optionsStatus().map((option, index) => {
                const statusKey = `${option}-${index}`;

                return (
                  <MenuItem
                    key={statusKey}
                    value={option}
                    className={classes.textStyle}
                  >
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              className={classes.filterInput}
              id="service-type-filter"
              name="is_inter_island"
              value={filterValues.is_inter_island || "All"}
              onChange={handleFilter}
              label={<span className={classes.titleField}>Trip Type</span>}
              select
              InputProps={{
                classes: {
                  input: classes.textStyle
                }
              }}
              // IconComponent={<ExpandMoreOutlinedIcon/>}
              SelectProps={{
                IconComponent: () => (
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                )
              }}
            >
              {tripTypeOpt &&
                tripTypeOpt.map((option, index) => {
                  const statusKey = `${option.value}-${index}`;
                  return (
                    <MenuItem
                      key={statusKey}
                      value={option.name}
                      className={classes.textStyle}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 32,
          paddingRight: 32
        }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => applyFilter({...filterValues, clear: false})}
          className={classes.applyBtn}
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default Filter;
