import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  styled
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
  Item,
  StyledTitle,
  useStyles
} from "../../TripAllocation.styles";
import {
  ExpandMore
} from "@material-ui/icons";
import _ from "lodash";

export const VehicleList = props => {
  const { selected, setHaulerFilter = () => {}, trips, haulerVTypes } = props;
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  const getCount = hauler => {
    if (!hauler) {
      return trips.filter(t => !t.assigned_hauler).length;
    }
    return trips.filter(t => t.assigned_hauler === hauler)?.length;
  };

  const getTotal = () => {
    return {
      selected: trips.filter(t => t.assigned_hauler).length,
      registered: _.sumBy(haulerVTypes, h => h.total_vehicles)
    };
  };

  // get haulers by allocation order
  const sortedHaulers = () => {
    return _.uniq(trips?.map(t => t.assigned_hauler));
  };

  const getActiveList = useCallback((tabValue) => {
    let displayList;
    switch (tabValue) {
      case 0:
      default:
        displayList = (
          <List>
            {getCount("") ? (
              <StyledListItem
                button
                key={0}
                value={""}
                onClick={() => setHaulerFilter("")}
                selected={selected === ""}
              >
                <ListItemText>Unallocated Trips</ListItemText>
                <StyledHaulerCount>
                  {getCount("")}
                </StyledHaulerCount>
              </StyledListItem>
            ) : (
              <></>
            )}
            {trips.length ? (
              sortedHaulers().map((hauler, idx) =>
                hauler && getCount(hauler) ? (
                  <StyledListItem
                    button
                    key={idx}
                    value={hauler}
                    onClick={() => setHaulerFilter(hauler)}
                    selected={selected === hauler}
                  >
                    <ListItemText>{hauler}</ListItemText>
                    <StyledHaulerCount>{getCount(hauler)}</StyledHaulerCount>
                  </StyledListItem>
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}
          </List>
        );
        break;
      case 1: 
        displayList = (
          <List>
            {
              haulerVTypes.map(hauler => (
                <StyledAccordion>
                  <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    <ListItemText>{hauler?.name}</ListItemText>
                    <StyledHaulerCount>{hauler?.total_vehicles}</StyledHaulerCount>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    {
                      hauler.vehicles.map(v => (
                        <StyledDetailsItem>
                          <Typography variant="body2">{v?.type}</Typography>
                          <StyledTitle variant="body2">{v?.count}</StyledTitle>
                        </StyledDetailsItem>

                      ))
                    }
                  </StyledAccordionDetails>
                </StyledAccordion>

              ))
            }
          </List>
        );
        break;
    }
    return displayList;
  }, [trips, tabValue, selected]);

  return (
    <Item padding="0">
      <Box className={classes.tabsContainer}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={(e, nv) => setTabValue(nv)}
        >
          <Tab
            className={classes.tab}
            value={0}
            label={
              <Box className={classes.tabLabel}>
                <Typography>Selected</Typography>
                <StyledTabBadge label={getTotal().selected} size="small" />
              </Box>
            }
          />
          <Tab
            className={classes.tab}
            value={1}
            label={
              <Box className={classes.tabLabel}>
                <Typography>Registered</Typography>
                <StyledTabBadge label={getTotal().registered} size="small" />
              </Box>
            }
          />
        </Tabs>
        <Button
          className={classes.tabLabel}
          variant="text"
          color="inherit"
          onClick={() => setHaulerFilter("all")}
        >
          View All
        </Button>
      </Box>
      <Box style={{ overflow: "auto" }}>{getActiveList(tabValue)}</Box>
    </Item>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: "1px solid #e4e4e4",
  "&.Mui-selected": {
    backgroundColor: "#ffb864",
    color: theme.palette.common.white
  },
  "&.MuiListItem-button:hover": {
    backgroundColor: "#ffb864",
    opacity: "0.85",
    color: theme.palette.common.white
  }
}));

const StyledHaulerCount = styled("div")(({ theme, ...props }) => ({
  ...props,
  display: "flex",
  backgroundColor: props?.unallocated ? "#ff845e" : "#fdefd9",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 8,
  padding: "6px 12px",
  color: props?.unallocated
    ? theme.palette.common.white
    : theme.palette.primary.dark,
  fontWeight: "bold"
}));

const StyledTabBadge = styled(Chip)(({ theme, ...props }) => ({
  backgroundColor: "#d1d1d1",
  color: "#fff",
  // borderRadius: 10,
  // padding: "0 9px",
  fontSize: "0.7rem"
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: 13
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderBottom: "1px solid #dcdcdc"
  },
  "&.MuiAccordion-root": {
    borderBottom: "1px solid #dcdcdc",
    "&.Mui-expanded": {
      margin: 0
    }
  }
}));

const StyledDetailsItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: 20,
  marginRight: 10
}));