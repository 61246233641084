import { OverlayView } from "@react-google-maps/api";
import { useStyles } from "./CustomMarkerStyle";
import React, { memo } from "react";
import { Box, Typography } from "@material-ui/core";
import _ from "lodash";

const CustomMarker = props => {
  const {
    getVehiclePosition,
    vehicleDirection,
    vehiclePlateNo,
    moduleType,
    zoom,
    isPause,
    alertLevel,
    isMoving
  } = props;
  const classes = useStyles({
    alert_level: alertLevel ? alertLevel.level : undefined
  });
  // set the overlayview centered according to its position.
  const getPixelPositionOffset = (width, height) => {
    if (moduleType !== "MAP_VIEW") {
      return { x: -(width / 2), y: -(height / 2) };
    } else {
      return { x: -(width + 7), y: -(height + 20) };
    }
  };

  return (
    <OverlayView
      position={getVehiclePosition()}
      mapPaneName={OverlayView.MAP_PANE}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <Box compoent={"div"} className={classes.iconContainer}>
        {/* <Box component={"div"} className={classes.vehicleContainer}>
          <Box
            component={"div"}
            className={classes.vehicleLightContainer}
            style={{ transform: `rotate(${vehicleDirection}deg)` }}
          >
            {(isMoving || (moduleType === "HISTORY_VIEW" && !isPause)) &&
              zoom >= 13 && (
                <Box
                  component={"div"}
                  className={
                    moduleType === "TRIPS_MAP_VIEW"
                      ? classes.vehicleLightTrips
                      : classes.vehicleLight
                  }
                />
              )}
          </Box>
        </Box> */}

        {/* {zoom >= 13 && moduleType !== "MAP_VIEW" && (
          <Typography
            className={classes.labelVehicleMapView}
            style={{
              backgroundColor: "rgba(196, 196, 196, 0.75)",
              color: "#000"
            }}
          >
            {vehiclePlateNo}
          </Typography>
        )} */}
        {moduleType !== "HISTORY_VIEW" && alertLevel && (
          <Box component={"div"} className={classes.iconBlinker} />
        )}
      </Box>
    </OverlayView>
  );
};

export default CustomMarker;
