/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@material-ui/core";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_FUEL_HISTORY } from "../../../../graphql/Queries";
import useStyles from "../styles";
import { etaConverter as durationConverter } from "../../../Utils";
import isWithinRange from "../helpers/isWithinRange";
import _ from "lodash";

const isOutExtension = (basis_date, date, isEntry = true) => {
  const dateToTest = moment(date);
  const basis = moment(
    isEntry ? basis_date.start : basis_date.selectedRange || basis_date.end
  ).subtract(8, "hours");

  if (_.isEqual(moment(dateToTest).year(), 1111)) return true;

  if (isEntry) {
    basis.subtract(96, "hours");
    return dateToTest.diff(basis, "hours", true) < 0;
  }

  basis.add(96, "hours");
  return basis.diff(dateToTest, "hours", true) < 0;
};

const StopDetails = React.memo(props => {
  const {
    itin,
    itin: { Entered, Left },
    deviceId,
    date,
    geofenceName,
    // vehicleData: { has_fuel_sensor, id: vehicle_id, plateno },
    isVisible,
    isCICO,
    primaryData
  } = props;
  const { has_fuel_sensor, id: vehicle_id, plateno } = primaryData || {};

  const classes = useStyles();
  const [fuelInfo, setFuelInfo] = useState(itin);
  const outOfRangeIcon = (
    <img
      alt="out of range"
      src={`${process.env.PUBLIC_URL}/assets/Icons/out_of_range.png`}
      className={classes.outOfRangeIcon}
    />
  );

  const getEntered = () => {
    const entered = itin?.ActualEntered || itin?.Entered;
    if (isCICO) {
      return moment(entered).subtract(8, "hours").format("YYYY-MM-DD HH:mm:ss");
    } else {
      return moment(entered).format("YYYY-MM-DD HH:mm:ss");
    }
  };

  const getLeft = () => {
    if (isCICO) {
      return moment(itin?.Left).subtract(8, "hours").format("YYYY-MM-DD HH:mm:ss");
    } else {
      return moment(itin?.Left).format("YYYY-MM-DD HH:mm:ss");
    }
  };

  const getDate = () => {
    if (!isCICO) {
      return {
        ...date,
        start: moment(date.start).subtract(8, "hours"),
        end: moment(date.end).subtract(8, "hours"),
        selectedRange: moment(date.selectedRange).subtract(8, "hours")
      }
    }
    return date;
  }

  const [getFuelInfo, { loading: fuelLoading }] = useLazyQuery(
    GET_FUEL_HISTORY,
    {
      onCompleted: data => {
        const { error, results } = data.get_fuel_history;
        setFuelInfo({ ...fuelInfo, ...results, error });
      },
      variables: {
        device_id: deviceId,
        vehicle_id: vehicle_id || "",
        plateno: plateno || "",
        entryTime:
          getEntered() ||
          moment(date.start)
            .subtract(8, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
        exitTime:
          getLeft() ||
          moment(date.end)
            .subtract(8, "hours")
            .format("YYYY-MM-DD HH:mm:ss")
      }
    }
  );

  useEffect(() => {
    /**
     * Get fuel info if stop is In Transit
     * If stop has geofence, check if vehicle has fuel sensor
     */
    if (isVisible && (geofenceName === "In Transit" || has_fuel_sensor)) {
      getFuelInfo();
    }
  }, []);

  const getDetails = () => {
    if (geofenceName === "In Transit") {
      return {
        stopInfo: [
          { label: "Distance Travelled", key: "distance_travelled" },
          { label: "Travel Time", key: "travelTime_mins" },
          { label: "Average Speed", key: "average_speed" }
        ],
        fuel: [
          { label: "Km per Liter", key: "fuel_efficiency" },
          { label: "Fuel Consumption", key: "consumed_fuel" }
        ]
      };
    }
    // Geofences
    return {
      stopInfo: [
        { label: "Entry Time", key: "Entered" },
        { label: "Exit Time", key: "Left" },
        { label: "Dwell Time", key: "dwellTime_mins" }
      ],
      fuel: [
        { label: "Fuel In", key: "fuel_in" },
        { label: "Fuel Out", key: "fuel_out" },
        { label: "Fuel Consumption", key: "consumed_fuel" }
      ]
    };
  };

  const formatData = key => {
    let val = "-";

    switch (key) {
      case "Entered":
      case "Left":
        if (fuelInfo[key]) {
          if (_.isEqual(moment(fuelInfo[key]).format("YYYY-MM-DD HH:mm:ss"), "1111-11-11 08:00:00")) {
            val = "-";
          } else {
            val = !isCICO ? moment(fuelInfo[key])
            .add(8, "hours")
            .format("MMMM DD, YYYY HH:mm")
            : moment(fuelInfo[key]).format("MMMM DD, YYYY HH:mm");
          }
          // val = moment(fuelInfo[key])
          //   .add(8, "hours")
          //   .format("MMMM DD, YYYY hh:mm A");
        }
        break;
      case "travelTime_mins":
      case "dwellTime_mins":
        const entered = fuelInfo?.ActualEntered || fuelInfo?.Entered;
        if (fuelInfo?.dwellTime) {
          val = durationConverter(fuelInfo.dwellTime * 60);
        } else if (fuelInfo?.Left && entered) {
          const secondsVal = moment(fuelInfo.Left).diff(
            moment(entered),
            "seconds"
          );
          val = durationConverter(secondsVal);
        }
        break;
      case "distance_travelled":
        if (fuelInfo[key]) {
          val = `${fuelInfo[key]} km`;
        }
        break;
      case "average_speed":
        if (fuelInfo[key]) {
          val = `${fuelInfo[key]} km/h`;
        }
        break;
      case "fuel_efficiency":
        if (fuelInfo[key]) {
          val = `${fuelInfo[key]} km/L`;
        }
        break;
      default:
        val = fuelInfo[key] || "-";
        break;
    }

    if (val === "-" && fuelInfo.error?.length) return "N/A";

    return val;
  };

  if (fuelLoading) {
    return <CircularProgress size={10} />
  }

  return (
    <Grid>
      <Grid item md={12} container direction="row">
        <Grid item md={6}>
          {getDetails().stopInfo.map(info => (
            <Grid container direction="row" key={info.key}>
              <Grid item md={4} container alignItems="center">
                {["Entry Time", "Exit Time"].includes(info.label) && (
                  <Grid item>
                    {itin?.[info.key] ? (
                      isWithinRange(getDate(), itin?.[info.key]) ? null : (
                        <Tooltip
                          title={`${
                            isOutExtension(date, itin?.[info.key], moment(itin?.[info.key]).isSame(itin?.["Entered"]))
                              ? "Out of Extension range"
                              : "Out of range"
                          }`}
                        >
                          {outOfRangeIcon}
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip title="Out of Extension range">
                        {outOfRangeIcon}
                      </Tooltip>
                    )}
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="caption">{info.label}:</Typography>
                </Grid>
              </Grid>
              <Grid item md={8}>
                <Typography variant="caption">
                  {geofenceName === "In Transit" && fuelLoading ? (
                    <CircularProgress disableShrink size={10} />
                  ) : (
                    formatData(info.key)
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {has_fuel_sensor && (
          <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {getDetails().fuel.map(info => (
                      <TableCell>
                        <Typography variant="caption">{info.label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {getDetails().fuel.map(info => (
                      <TableCell>
                        {fuelLoading ? (
                          <CircularProgress disableShrink size={10} />
                        ) : (
                          <Typography variant="caption">
                            {formatData(info.key)}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default StopDetails;
