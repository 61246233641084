/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* global google */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  makeStyles,
  Typography,
  Switch,
  Grid,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Accordion,
  FormControlLabel,
  FormGroup,
  Box,
  Icon,
  styled
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { createPortal } from "react-dom";
import {
  GoogleMap,
  TrafficLayer,
  Marker,
  Polyline,
  Rectangle,
  Circle,
  Polygon,
  InfoWindow,
  useJsApiLoader
} from "@react-google-maps/api";
import moment from "moment";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import Swal from "sweetalert2";
import { GET_TRIP_LOG_V2, GET_GEOFENCES } from "../../../graphql/Queries";
import isWithinRange from "./helpers/isWithinRange";
import Loading from "../../../utils/Loading";
import { center as DEFAULT_CENTER } from "../../../utils/Map/Inner";
import CustomMarker from "../../../utils/Map/Marker/CustomMarker";
import SvgIcons, {
  fillColor,
  strokeColor,
  alertColorCode
} from "../../../assets/SvgIcons/SvgIcons";
import { getGeofenceTypeColor } from "../../Utils";
import useUserContext from "../../../context/User/useUserContext";
import { GeofenceLegend } from "./ItineraryFooter";
import { wktToCoordinates } from "../../../utils/functions/coordinatesParserV2";
import SearchLocation from "../SearchLocation";

const pin_gray = require("../../../assets/pin-gray.png");
// const carIcon = require("../../../assets/car.png");
// import { GET_GEOFENCES } from "../../../graphql/Queries";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const useStyles = makeStyles(() => ({
  rootFilter: {
    cursor: "pointer",
    display: "flex",
    padding: "4px 12px",
    minWidth: "120px",
    borderRadius: "10px"
  },
  filterText: {
    fontSize: "14px"
  },
  filterList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  checked: {
    backgroundColor: "blue"
  },
  root: {
    background: "rgba(255,255,255)",
    boxShadow: "0px 0px 18px -8px #484848d4",
    width: "150px",
    "& .Mui-expanded": {
      minHeight: "auto"
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0
    },
    "& .MuiIconButton-edgeEnd": {
      margin: 0
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem"
    },
    top: "10px",
    right: "10px"
  },
  summary: {
    padding: "5px 8px",
    margin: 0,
    height: "auto",
    minHeight: "auto",
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  },
  details: {
    padding: "5px 8px",
    margin: 0,
    height: "auto"
  },
  switch: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%"
  }
}));

const alertTooltipStyles = makeStyles(() => ({
  type: {
    fontWeight: "bold"
  },
  datestamp: {
    color: "#878787"
  }
}));

const IntervalMarker = props => {
  const styles = alertTooltipStyles();
  const { log, iconOptions, setSelectedInfoVehicle } = props;
  const {
    location: { lat, lon },
    time: datestamp,
    alert_code,
    alert_levels
  } = log;

  const [showInfo, setShowInfo] = useState(false);
  const toggleInfoWindow = useCallback(() => {
    setShowInfo(prev => !prev);
  }, [setShowInfo]);

  useEffect(() => {
    if (showInfo) setSelectedInfoVehicle(log);
    else setSelectedInfoVehicle(null);
  }, [showInfo]);

  return (
    <React.Fragment>
      <Marker
        position={{ lat, lng: lon }}
        icon={iconOptions}
        onClick={toggleInfoWindow}
      >
        {showInfo && (
          <InfoWindow onCloseClick={toggleInfoWindow}>
            {/* <div>
              {moment(datestamp)
                // .add(8, "hours")
                .format("MMMM DD, YYYY HH:mm")}

              {console.log(log)}
            </div> */}
            <div>
              {log?.alert_code.map((alert, index) => (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    variant="caption"
                    style={{
                      color: markerColor(
                        log.alert_levels ? +log.alert_levels[index] : null
                      )
                    }}
                    className={styles.type}
                  >
                    {alert}
                  </Typography>
                  <Typography variant="caption" className={styles.datestamp}>
                    {/* {moment(log.datestamp)
                .add(8, "hours")
                .format("MMMM DD, YYYY HH:mm")} */}
                    {moment(datestamp).format("MMMM DD, YYYY HH:mm")}
                  </Typography>
                </div>
              ))}
            </div>
          </InfoWindow>
        )}
      </Marker>
    </React.Fragment>
  );
};

const CustomOverlay = props => {
  const {
    trip_logs,
    geofences: itinGeofences,
    filters,
    destinations,
    vehicleData,
    allGeofences,
    isCICO,
    alertCode,
    setSelectedInfoVehicle
  } = props;
  const startLocation = destinations[destinations.length - 1]?.location;
  const endLocation = destinations[0]?.location;
  const geofences = [
    ...(allGeofences?.get_geofences?.geofences || []),
    ...(itinGeofences || [])
  ];
  // allGeofences?.get_geofences?.geofences || itinGeofences || [];
  const [mapHover, setMapHover] = useState({
    id: "",
    isShow: false,
    name: "",
    position: {}
  });

  const [points, setPoints] = useState([]);

  useEffect(() => {
    let tempPoints = [];
    if (trip_logs.length > 0) {
      tempPoints = trip_logs.map(point => {
        const { lat, lon } = point.location;
        return { lat: lat, lng: lon };
      });
    }

    setPoints(tempPoints);
  }, [trip_logs]);

  // const handleFilter = () => {
  //   setExpanded(!expanded);
  // };

  const getStationTime = date => {
    if (_.isEqual(moment(date).year(), 1111)) return "-";
    if (!isCICO) {
      return moment(date)
        .add(8, "hours")
        .format("MMMM DD, YYYY HH:mm");
    }
    return moment(date).format("MMMM DD, YYYY HH:mm");
  };

  const renderAlertLocations = () => {
    const icon = `${process.env.PUBLIC_URL}/assets/Markers/alert_pin2.png`;
    return (
      <React.Fragment>
        {/* {destinations.map((destination, index) => (
          <AlertPosition
            key={`alert-marker-${index}`}
            entered={destination.Entered}
            left={destination.Left}
            vehicleData={vehicleData}
          />
        ))} */}
        {trip_logs
          .filter(log => log.alert_msgs.length)
          .map((log, index) => {
            const isLBSAlert = log?.alert_code.some(
              alert => alert === "LBS DATA"
            );

            return (
              log.alert_code.length > 0 &&
              log.alert_code[0] !== "LOCATION" && (
                <AlertMarker
                  icon={SvgIcons[isLBSAlert ? "LBS_vehicle" : "alert_marker"]}
                  log={log}
                  isLBS={isLBSAlert}
                  alertCode={
                    log?.alert_levels?.length
                      ? +Math.max(...log?.alert_levels)
                      : null
                  }
                  key={`alert-${index}`}
                  setSelectedInfoVehicle={setSelectedInfoVehicle}
                />
              )
            );
          })}
      </React.Fragment>
    );
  };

  const firstAndLastLocation = () => {
    return (
      startLocation &&
      endLocation && (
        <React.Fragment>
          <Marker
            position={{ lat: startLocation?.lat, lng: startLocation?.lon }}
            icon={{
              url: `${process.env.PUBLIC_URL}/assets/Markers/start-green.png`,
              scaledSize: new google.maps.Size(32, 32)
            }}
          />
          <Marker
            position={{ lat: endLocation?.lat, lng: endLocation?.lon }}
            icon={{
              url: `${process.env.PUBLIC_URL}/assets/Markers/End2.png`,
              scaledSize: new google.maps.Size(32, 32)
            }}
          />
        </React.Fragment>
      )
    );
  };

  const renderInterval = () => {
    return (
      <React.Fragment>
        {trip_logs.map((log, index) => {
          let intervalPin = "interval_pin";
          const alert = log?.alert_levels
            ? +Math.max(...log?.alert_levels)
            : null;
          if (alert >= 3) intervalPin = "alert_pin";
          else if (alert === 2) intervalPin = "interval_moderate_pin";

          const iconOptions = {
            url: `${process.env.PUBLIC_URL}/assets/Markers/${intervalPin}.png`
          };

          const isLBSAlert = log?.alert_code.some(
            alert => alert === "LBS DATA"
          );

          return (
            <IntervalMarker
              // eslint-disable-next-line react/no-array-index-key
              key={`interval-marker-${index}`}
              log={log}
              iconOptions={
                isLBSAlert
                  ? {
                      ...SvgIcons["LBS_vehicle"],
                      anchor: new google.maps.Point(24, 24)
                    }
                  : iconOptions
              }
              setSelectedInfoVehicle={setSelectedInfoVehicle}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const renderFilter = () => {
    return (
      <>
        {mapHover.isShow && (
          <InfoWindow position={mapHover.position}>
            <Grid
              container
              style={{
                backgroundColor: `white`,
                width: 250,
                color: "#646464"
              }}
            >
              <Typography variant="body2" gutterBottom>
                {mapHover.name}
              </Typography>
              {mapHover.stations.map((station, index) => (
                <Grid
                  item
                  md={12}
                  style={{ marginBottom: 3 }}
                  key={`station-${index}`}
                >
                  <Grid item md={12}>
                    <Typography variant="caption">
                      <strong>{station.geofenceCategory}</strong>
                    </Typography>
                  </Grid>
                  <Grid container item md={12}>
                    <Grid item md={4}>
                      <Typography variant="caption">Entry Time: </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="caption">
                        {station?.Entered
                          ? getStationTime(station.Entered)
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item md={12}>
                    <Grid item md={4}>
                      <Typography variant="caption">Exit Time: </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="caption">
                        {station?.Left ? getStationTime(station.Left) : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </InfoWindow>
        )}
        {geofences.length > 0 &&
          filters.geofences &&
          geofences.map(geofence => {
            const {
              id,
              opacity,
              geom,
              category,
              geofence_code,
              location
            } = geofence;
            const bounds = wktToCoordinates(geom);

            const name = `${geofence_code} - ${geofence.name}`;
            const stations = destinations.filter(d => d.geofenceName === name);

            const iconMarker = new window.google.maps.MarkerImage(
              pin_gray,
              null /* size is determined at runtime */,
              null /* origin is 0,0 */,
              null /* anchor is bottom center of the scaled image */,
              new window.google.maps.Size(24, 24)
            );
            return (
              <div key={`geofence-${id}`}>
                {!!stations.length && (
                  <Marker
                    key={{ id }}
                    icon={iconMarker}
                    position={{
                      lat: location.lat,
                      lng: location.lon
                    }}
                  />
                )}
                <Polygon
                  paths={bounds}
                  options={{
                    fillColor: getGeofenceTypeColor(category),
                    fillOpacity: opacity,
                    strokeWeight: 2,
                    zIndex: stations.length ? 1000 : 500
                  }}
                  onMouseOver={() => {
                    if (id !== mapHover.id && !mapHover.isShow) {
                      setMapHover({
                        isShow: true,
                        name: `${geofence.geofence_code}-${geofence.name}`,
                        position: {
                          lat: geofence.location.lat,
                          lng: geofence.location.lon
                        },
                        stations
                      });
                    }
                  }}
                  onMouseOut={() => {
                    setMapHover({
                      ...mapHover,
                      isShow: false
                    });
                  }}
                />
              </div>
            );
          })}
      </>
    );
  };

  // useEffect(() => {
  //   const controls = map.controls[google.maps.ControlPosition.RIGHT_TOP];
  //   const index = controls.length;
  //   controls.push(controlDiv);
  //   // return () => {
  //   //   controls.removeAt(index);
  //   // };
  // }, []);

  return (
    <div>
      {renderFilter()} {filters.traffic && <TrafficLayer autoUpdate />}
      {filters.assumedRoute && firstAndLastLocation()}
      {filters.assumedRoute && (
        <>
          <Polyline
            path={points}
            geodesic
            options={{
              strokeColor: "#000000",
              strokeOpacity: 1.0,
              strokeWeight: 6
            }}
          />
          <Polyline
            path={points}
            geodesic
            options={{
              strokeColor: "#7F00FF",
              strokeOpacity: 1.0,
              strokeWeight: 3
            }}
          />
        </>
      )}
      {filters.alerts && renderAlertLocations()}
      {filters.interval && renderInterval()}
    </div>
  );
};

const FilterOverlay = props => {
  const classes = useStyles();
  const {
    filters,
    setFilters,
    mapRef,
    paused,
    setPlaces,
    mapLocation,
    setCenter
  } = props;
  const map = mapRef.current;
  const controlDiv = document.createElement("div");
  const controls = map.controls[google.maps.ControlPosition.RIGHT_TOP];
  controls.push(controlDiv);

  const filterList = [
    { name: "Geofences", key: "geofences" },
    { name: "Traffic Overlay", key: "traffic" },
    { name: "Alerts", key: "alerts" },
    { name: "Assumed Route", key: "assumedRoute" },
    { name: "Interval", key: "interval" }
  ];

  const handleFilterToggle = e => {
    const { checked, name } = e.target;
    setFilters({ ...filters, [name]: checked });
    if (name === "geofences" && !checked) {
      setPlaces({
        isOpen: false,
        place: "google places",
        geofence: null,
        gmap: null
      });
      setCenter(mapLocation.location);
    }
  };

  const renderFilter = () => {
    if (paused) {
      return (
        <Accordion className={classes.root} elevation={0}>
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="caption">Map Filters</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <FormGroup style={{ width: "100%" }}>
              {filterList.length > 0 &&
                filterList.map(filter => (
                  <FormControlLabel
                    key={filter.key}
                    className={classes.switch}
                    control={
                      <AntSwitch
                        name={filter.key}
                        checked={filters[filter.key]}
                        onChange={handleFilterToggle}
                      />
                    }
                    size="small"
                    labelPlacement="start"
                    label={
                      <Typography variant="caption">{filter.name}</Typography>
                    }
                  />
                ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  return createPortal(renderFilter(), controlDiv);
};

const SearchPlacesHandler = props => {
  const { mapRef, setCenter, setHasSearched, setPlaces, places } = props;
  const map = mapRef.current;
  const controlDiv = document.createElement("div");
  const controls = map.controls[google.maps.ControlPosition.LEFT_TOP];
  controls.push(controlDiv);

  const renderFilter = () => {
    return (
      <div style={{ margin: 10 }}>
        <SearchLocation
          setCoordinates={coords => {
            setHasSearched(!!coords);
            setCenter(coords);
          }}
          setPlaces={setPlaces}
          places={places}
          component="historical"
        />
      </div>
    );
  };

  return createPortal(renderFilter(), controlDiv);
};

const markerColor = level => {
  let color = alertColorCode.normal;

  if (level >= 3) color = alertColorCode.critical;
  else if (level === 2) color = alertColorCode.moderate;

  return color;
};

const AlertMarker = props => {
  const styles = alertTooltipStyles();
  const { icon, log, alertCode, isLBS, setSelectedInfoVehicle } = props;
  const location = { lat: log.location.lat, lng: log.location.lon };
  const [showTooltip, setShowTooltip] = useState(false);
  const alertMessage = log.alert_msgs[0];

  useEffect(() => {
    if (showTooltip) setSelectedInfoVehicle(log);
    else setSelectedInfoVehicle(null);
  }, [showTooltip]);

  return (
    <Marker
      options={{
        icon: {
          path: icon.path,
          fillColor: isLBS ? icon.fill : markerColor(alertCode),
          strokeColor: "#000000",
          fillOpacity: 1,
          scale: 1.3,
          anchor: new google.maps.Point(0, 0)
        }
      }}
      position={location}
      onClick={() => setShowTooltip(!showTooltip)}
    >
      {showTooltip && (
        <InfoWindow
          onCloseClick={() => setShowTooltip(false)}
          position={location}
        >
          <div>
            {log?.alert_code.map((alert, index) => (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: markerColor(
                      log.alert_levels?.length
                        ? +log.alert_levels[
                            log.alert_levels !== log.alert_code
                              ? log.alert_levels.length - 1
                              : index
                          ]
                        : null
                    )
                  }}
                  className={styles.type}
                >
                  {log.alert_levels !== log.alert_code
                    ? log.alert_code[log.alert_code.length - 1]
                    : alert}
                </Typography>
                <Typography variant="caption" className={styles.datestamp}>
                  {/* {moment(log.datestamp)
                .add(8, "hours")
                .format("MMMM DD, YYYY HH:mm")} */}
                  {moment(log.time).format("MMMM DD, YYYY HH:mm")}
                </Typography>
              </div>
            ))}
          </div>
          {/* <Grid container direction="column">
            <Typography variant="caption" className={styles.type}>
              {alertMessage}
            </Typography>
            <Typography variant="caption" className={styles.datestamp}>
              {moment(log.time).format("MMMM DD, YYYY HH:mm")}
            </Typography>
          </Grid> */}
        </InfoWindow>
      )}
    </Marker>
  );
};

const AlertPosition = props => {
  const {
    entered,
    left,
    vehicleData
    // vehicleData: { vehicle_id, plateno }
  } = props;
  const { vehicle_id, plateno } = vehicleData || {};
  const { data, loading } = useQuery(GET_TRIP_LOG_V2, {
    variables: {
      first: 0,
      filter: [],
      or: [
        {
          field: "vehicle_id",
          value: vehicle_id || ""
        },
        {
          field: "plateno.keyword",
          value: plateno || ""
        }
      ],
      dateFilter: {
        field: "datestamp",
        gte: entered || left,
        lte: left || entered
      },
      exists: ["alert_msgs"]
    }
    // fetchPolicy: "cache-and-network"
  });
  if (loading) {
    return null;
  }
  if (data) {
    const icon = `${process.env.PUBLIC_URL}/assets/Markers/alert_pin2.png`;
    return data?.get_trip_log_v2.map(
      (log, index) =>
        log.alert_codes.length > 0 &&
        log.alert_codes[0] !== "LOCATION" && (
          <AlertMarker icon={icon} log={log} key={`alert-${index}`} />
        )
    );
  }
  return null;
};

const libraries = ["geometry", "drawing", "places"];

const MapComponent = props => {
  const {
    trip_logs,
    recenter,
    geofences,
    zoomProps,
    destinations,
    date,
    user,
    mapLocation,
    paused,
    vehicleDirectionCounter,
    center,
    setCenter,
    vehicleData,
    // vehicleData: {
    //   icon,
    //   plateno: vehiclePlateNo,
    //   id: vehicle_id,
    //   ignition_status
    // },
    children,
    primaryData,
    isCICO,
    setSelectedInfoVehicle,
    selectedInfoVehicle
  } = props;
  const {
    icon,
    plateno: vehiclePlateNo,
    id: vehicle_id,
    ignition_status,
    powersource
  } = primaryData || {};
  const { default_icon } = SvgIcons;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    channel: `tmsuite-portal-${user.client.name}`,
    version: "3.47",
    libraries: libraries
  });
  // const [center, setCenter] = useState({ lat: 14.5995, lng: 120.9842 });
  const [zoom, setZoom] = useState(15);
  const [showOverlay, setShowOverlay] = useState(false);
  const vehicleDirection = trip_logs[vehicleDirectionCounter]?.direction;
  const alertCode = trip_logs[vehicleDirectionCounter]?.alert_levels?.length
    ? Math.max(...trip_logs[vehicleDirectionCounter]?.alert_levels)
    : null;

  const user_context = useUserContext();
  const [bounds, setBounds] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  // const classes = useStyles();
  // const parsedTriplogs = trip_logs.reduce((accum, point) => {
  //   const { datestamp } = point;
  //   if (isWithinRange(date, datestamp)) {
  //     accum.push(point);
  //   }
  //   return accum;
  // }, []);
  const parsedDestinations = destinations.reduce(
    (accumulator, currentValue) => {
      const { Entered, Left } = currentValue;
      if (
        isWithinRange(date, Entered, isCICO) ||
        isWithinRange(date, Left, isCICO)
      ) {
        return [...accumulator, currentValue];
      }

      return accumulator;
    },
    []
  );

  const [filters, setFilters] = useState({
    geofences: false,
    traffic: false,
    alerts: false,
    assumedRoute: true,
    interval: false
  });

  const [places, setPlaces] = useState({
    isOpen: false,
    place: "google places",
    geofence: null,
    gmap: null
  });

  const getVehiclePosition = () => {
    if (mapLocation) {
      const { lat, lon } = mapLocation.location;
      return { lat: lat, lng: lon };
    }
  };

  const mapViewRef = useRef();

  const getGeoPaths = () => {
    const path = [];
    if (!mapViewRef?.current?.getBounds()) return path;

    const aNorth = mapViewRef.current
      .getBounds()
      .getNorthEast()
      .lat();
    const aEast = mapViewRef.current
      .getBounds()
      .getNorthEast()
      .lng();
    const aSouth = mapViewRef.current
      .getBounds()
      .getSouthWest()
      .lat();
    const aWest = mapViewRef.current
      .getBounds()
      .getSouthWest()
      .lng();

    path.push(
      { lat: aNorth, lon: aEast },
      { lat: aNorth, lon: aWest },
      { lat: aSouth, lon: aWest },
      { lat: aSouth, lon: aEast },
      { lat: aNorth, lon: aEast }
    );

    return path;
  };

  const handleZoom = () => {
    if (mapViewRef.current) {
      setZoom(mapViewRef.current.getZoom());
      setBounds(getGeoPaths());
    }
  };

  useEffect(() => {
    let newCenter = {};

    if (paused && (places.geofence || places.gmap)) {
      if (center && center?.lng) {
        newCenter = { lat: center?.lat, lon: center?.lng };
      } else if (center && center?.lat && center?.lon) {
        newCenter = center;
      } else {
        newCenter = selectedInfoVehicle
          ? selectedInfoVehicle.location
          : mapLocation?.location;
      }
    } else {
      newCenter = {
        lat: getVehiclePosition()?.lat || DEFAULT_CENTER.lat,
        lon: getVehiclePosition()?.lng || DEFAULT_CENTER.lng
      };
    };

    if (mapViewRef.current && newCenter) {
      const isWithinBounds = mapViewRef.current.getBounds()?.contains({
        lat: newCenter?.lat,
        lng: newCenter?.lon
      });
      if (!isWithinBounds) {
        mapViewRef.current.panTo({ lat: newCenter?.lat, lng: newCenter?.lon });
      }
    }
    if (!mapViewRef.current) {
      setCenter({
        lat: newCenter?.lat || DEFAULT_CENTER.lat,
        lng: newCenter?.lon || DEFAULT_CENTER.lng
      });
    }
  }, [mapLocation, center, selectedInfoVehicle, places]);

  useEffect(() => {
    if (zoomProps.zoom) {
      setZoom(19);
      zoomProps.setZoom(false);
    }
  }, [recenter]);

  useEffect(() => {
    setBounds(getGeoPaths());
  }, [center]);

  const { data: allGeofences } = useQuery(GET_GEOFENCES, {
    variables: {
      first: 100,
      // filter: [{ field: "name", value: places.geofence?.name }],
      group_ids: user_context.client.group_ids,
      ...(bounds.length && { bbox: bounds })
    },
    skip: !filters.geofences || zoom < 15,
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong"
      });
    }
  });

  if (isLoaded) {
    const DEFAULT_OPTIONS = {
      fullscreenControl: false,
      mapTypeControl: paused,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.LEFT_BOTTOM,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID
        ]
      }
    };

    const Icon = SvgIcons[icon ? icon?.split(".")?.[0] : "default_icon"];
    // const powersource = props.vehicleData?.device_info?.device_status?.powersource;

    return (
      <Grid container>
        <Grid item xs={3}>
          {children}
        </Grid>
        <Grid item xs={9}>
          <GoogleMap
            mapContainerStyle={{
              minHeight: "55vh"
            }}
            zoom={zoom}
            maxZoom={19}
            onZoomChanged={() => handleZoom()}
            center={center}
            options={DEFAULT_OPTIONS}
            onLoad={map => {
              mapViewRef.current = map;
              setShowOverlay(true);
            }}
            onDragEnd={() => setBounds(getGeoPaths())}
          >
            {trip_logs.length > 1 && (
              <Marker
                position={getVehiclePosition()}
                options={{
                  icon: {
                    // path: vehicleSvgIcons[icon.split(".")[0]].path,
                    path: Icon.path,
                    // fillColor: vehicleSvgIcons[icon.split(".")[0]].color,
                    fillColor: fillColor[powersource], // Icon.fill,
                    strokeColor: strokeColor[ignition_status], // Icon.stroke,
                    strokeWeight: 1.5,
                    fillOpacity: 1,
                    rotation: vehicleDirection,
                    scale: 1.2,
                    anchor: new google.maps.Point(0, 0)
                  }
                }}
              >
                <CustomMarker
                  getVehiclePosition={getVehiclePosition}
                  vehicleDirection={vehicleDirection}
                  vehiclePlateNo={vehiclePlateNo}
                  isPause={paused}
                  moduleType="HISTORY_VIEW"
                  zoom={zoom}
                />
              </Marker>
            )}

            {showOverlay && (
              <>
                <CustomOverlay
                  trip_logs={trip_logs}
                  geofences={geofences}
                  allGeofences={allGeofences}
                  destinations={parsedDestinations}
                  filters={filters}
                  vehicleData={{
                    plateno: vehiclePlateNo,
                    vehicle_id: vehicle_id
                  }}
                  isCICO={isCICO}
                  alertCode={alertCode}
                  setSelectedInfoVehicle={setSelectedInfoVehicle}
                />
                <FilterOverlay
                  filters={filters}
                  setFilters={setFilters}
                  mapRef={mapViewRef}
                  paused={paused}
                  setPlaces={setPlaces}
                  mapLocation={mapLocation}
                  setCenter={setCenter}
                />
                {filters.geofences && paused && (
                  <SearchPlacesHandler
                    mapRef={mapViewRef}
                    setCenter={setCenter}
                    setHasSearched={setHasSearched}
                    setPlaces={setPlaces}
                    places={places}
                  />
                )}
              </>
            )}
            {hasSearched && (
              <Marker
                position={center}
                icon={{
                  url: `${process.env.PUBLIC_URL}/assets/Markers/Dropoff 1.png`,
                  scaledSize: new google.maps.Size(45, 45)
                }}
                options={{
                  zIndex: 1000
                }}
              />
            )}
          </GoogleMap>
          <div style={{ width: "100%" }}>
            <Box display="flex" flexWrap="wrap" mt={1}>
              <GeofenceLegend alignLeft />
            </Box>
          </div>
        </Grid>
      </Grid>
    );
  }
  return <Loading />;
};

export default MapComponent;
