/* eslint-disable no-plusplus */
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  Checkbox,
  TableBody,
  Popover,
  Tooltip,
  Badge,
  MenuList,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Select,
  FormControl,
  InputLabel,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import {
  FilterList,
  GetApp,
  MoreVert,
  ErrorOutline,
  TripOrigin,
  Brightness1,
  AssignmentLate,
  Warning,
  Publish,
  Event as EventIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import UserContext from "../../context/UserContext";
import Filter from "../../common/Filter";
import AccessControl from "../../../AccessControl";
import Battery from "../../../Battery";
import { gpsStatus } from "../../../../components/Utils";
import Pagination from "../../../table/Pagination";
import useUserContext from "../../../../context/User/useUserContext";
import prebooking_data from "./prebooking.data";
import Focus, { useInfoBubbleStyles } from "../../common/Focus";
import Onboarding, { useOnboardingContext } from "../../index";
import prebooking_welcome from "../../images/prebooking-check-your-delivery-details.svg";
import Stage from "../../common/Stage/Stage";
import useFormStyles from "../../../../styles/useFormStyles";

const TripStatusLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Warning style={{ fontSize: 12, color: "#EA4335" }} />
        </span>
        <span>Hotload</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <AssignmentLate style={{ fontSize: 12, color: "#EA4335" }} />
        </span>
        <span>Overdue Trip</span>
      </li>
    </ul>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: {
    // marginTop: 64,
    // marginLeft: 70
  },
  multipleSelect: {
    marginLeft: 70,
    backgroundColor: "#EAEAEA",
    minHeight: "45px !important",
    height: 45
  },
  filterButton: {
    borderRadius: "25px",
    fontSize: "12px",
    marginRight: 8
  },
  downloadButton: {
    color: "white",
    borderRadius: 25,
    backgroundColor: "#EB825C",
    fontSize: 12,
    marginRight: 8
  },
  textField: {
    borderRadius: "32px !important"
  },
  tableRoot: {
    width: "100%"
    // marginTop: 64
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  container: {
    maxHeight: "calc(100vh - 128px)"
  },
  tableCell: {
    padding: 16
  },
  customBadgeAutomatic: {
    backgroundColor: "#bb6bd9",
    color: "white"
  },
  customBadgeManual: {
    backgroundColor: "#4e9fc0",
    color: "white"
  },
  customBadgeNotYetDispatch: {
    backgroundColor: "#b7b7b7",
    color: "white"
  },
  customBadgeUndefine: {
    backgroundColor: "#cab608",
    color: "white"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    // margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  statusUploading: {
    color: "#3f3f3f"
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white",
    backgroundColor: "#ffb677"
  },
  countSelectedTrips: {
    color: theme.palette.primary.main
  },
  popoverPaper: {
    right: 100,
    left: "auto !important",
    width: "fit-content"
  }
}));

const headCells = [
  {
    id: "dispatch_type",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "isHotload",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "isOverdue",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "trip_number",
    numeric: false,
    disablePadding: false,
    label: "Trip Number",
    sortable: true
  },
  {
    id: "hasEmptySo",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "service_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Service Type",
    sortable: true
  },
  {
    id: "pickups.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Pickup Location",
    sortable: true
  },
  {
    id: "pickups.arrival",
    numeric: false,
    disablePadding: false,
    label: "Pickup Date",
    sortable: true
  },
  {
    id: "dropoffs.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Delivery Location",
    sortable: true
  },
  {
    id: "dropoffs.arrival",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    sortable: true
  },
  {
    id: "client_name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Hauler",
    sortable: true
  },
  {
    id: "vehicle_plate_no.keyword",
    numeric: false,
    disablePadding: false,
    label: "Plate/ CV no.",
    sortable: true
  },
  {
    id: "status_code.keyword",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sortable: false
  }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy } = props;

  return (
    <Focus step={4} component={TableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary" />
        </TableCell>
        {headCells.map(headCell => (
          <Focus
            component={TableCell}
            style={{ fonwWeight: "bold", background: "transparent" }}
            step={5}
            showInfoBubble={false}
            on={headCell.label === "Action"}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </Focus>
        ))}
      </TableRow>
    </Focus>
  );
};

const Download = React.memo(props => {
  // const { csvLink, downloadBTN, keyLog } = props;
  const classes = useStyles();
  return (
    <PopupState variant="popper" popupId="vehicleCount-popup-popper">
      {popupState => (
        <div className={classes.alignment}>
          <Button
            variant="contained"
            className={classes.btn_download}
            // {...bindToggle(popupState)}
          >
            <GetApp /> Download
          </Button>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom"
            transition
            style={{ zIndex: "1100" }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener
              // onClickAway={() => {
              //   popupState.close();
              // }}
              >
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <MenuList id="menu-list-grow">
                      <MenuItem
                      // onClick={() => {
                      //   AddLogs("Prebooking", "download_template", "");
                      // }}
                      >
                        <Link
                          to="/assets/trips_upload_template.xls"
                          target="_blank"
                          download
                          underline="none"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Download Template
                        </Link>
                      </MenuItem>
                      <MenuItem
                        // onClick={() => {
                        //   csvLink.current.link.click();
                        //   AddLogs("Prebooking", "download_table", keyLog);
                        // }}
                        disabled={false}
                      >
                        Download Table
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
});

const CustomFilterHeader = ({ handleClearFilter }) => {
  const formStyles = useFormStyles();
  return (
    <div
      style={{
        width: "85%",
        textAlign: "right",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
        marginBottom: 25
      }}
    >
      <div>
        <Select
          value={0}
          onChange={() => {}}
          classes={{ root: formStyles.selectRoot }}
        >
          <MenuItem value={0} selected>
            Pickup Date
          </MenuItem>
          <MenuItem value={1}>Delivery Date</MenuItem>
        </Select>
      </div>
      <div>
        <Button onClick={handleClearFilter} size="small">
          <span
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              color: "black"
            }}
          >
            Clear All
          </span>
        </Button>
      </div>
    </div>
  );
};

const PreBooking = props => {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("pickups.arrival");
  const csvLink = useRef();
  const [downloadData, setDownloadData] = useState({
    headers: [
      { label: "Trip Number", key: "fo_number" },
      { label: "Service Type", key: "service_type" },
      { label: "Pickup Location", key: "pickup_location" },
      { label: "Pickup Date", key: "pickup_date" },
      { label: "Dropoff Location", key: "dropoff_location" },
      { label: "Delivery Date", key: "dropoff_date" },
      { label: "Hauler", key: "hauler" },
      { label: "Plate/ CV no", key: "plate_no" },
      { label: "Status", key: "status" },
      { label: "Dispatch Type", key: "dispatch_type" }
    ],
    body: []
  });
  const [activeRow, setActiveRow] = useState({});
  const [activeRawData, setActiveRawData] = useState({});
  const [permissions, setPermissions] = useState({
    change_truck: { view: false, edit: false },
    dispatch: { view: false, edit: false },
    manual_complete: { view: false, edit: false },
    split: { view: false, edit: false },
    close: { view: false, edit: false },
    upload: { view: false, edit: false }
  });
  const user_context = useUserContext();
  const [trip, setTrip] = useState();
  const [tripReassign, setTripReassign] = useState([]);
  const [dvar, setdvar] = useState([]);

  const [loaded, hasLoaded] = useState(false);
  const [openActions, setOpenActions] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const open = Boolean(openActions);
  const id = open ? "actions-popover" : undefined;

  const isSelected = name => selectedTrips.indexOf(name) !== -1;

  const getData = () => {
    return prebooking_data;
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedTrips.length) {
      if (!selectedTrips.length < getData().length) {
        const displayedData = getData().map(data => data.tripNumber);
        if (
          displayedData.every(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: true, indeterminate: false };
        else if (
          displayedData.some(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleSelectAllTrips = event => {
    const tempSelected = selectedTrips;
    const deselect = () => {
      const displayedData = getData()
        .filter(n => {
          if (
            n.status.toLowerCase() !== "closed" &&
            n.status.toLowerCase() !== "completed" &&
            n.status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
          return n;
        })
        .map(n => n.tripNumber);
      // const displayedData = getData().map(n => n.status);
      const newSelected = tempSelected
        .filter(tripNumber => displayedData.indexOf(tripNumber) < 0)
        .map(tripNumber => tripNumber);
      setSelectedTrips(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      // getData().map(n => tempSelected.push(n.tripNumber));
      getData()
        .filter(n => {
          if (
            n.status.toLowerCase() !== "closed" &&
            n.status.toLowerCase() !== "completed" &&
            n.status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
        })
        .map(n => tempSelected.push(n.tripNumber));
      setSelectedTrips(_.uniq(tempSelected));
      return;
    }
    deselect();
  };
  useEffect(() => {
    if (Object.keys(user_context.allowed_permissions).length) {
      const { allowed_permissions } = user_context;
      setPermissions({
        change_truck: allowed_permissions["/prebooking/change_truck/"],
        dispatch: allowed_permissions["/prebooking/dispatch/"],
        manual_complete: allowed_permissions["/prebooking/manual_complete/"],
        split: allowed_permissions["/prebooking/split/"],
        close: allowed_permissions["/prebooking/close/"],
        upload: allowed_permissions["/prebooking/manual_upload/"]
      });
    }
  }, [user_context.allowed_permissions]);

  const getEta = (pickups, dropoffs, out_of_route) => {
    let eta = false;

    const active_pu = _.filter(
      pickups,
      pu => pu.actual_eta && pu.status_code_id === 2
    );
    const active_do = _.filter(
      dropoffs,
      d => d.actual_eta && d.status_code_id === 2
    );

    if (active_do.length > 0) {
      const modifiedTime = moment(active_do[0].eta_modified).format("lll");
      const expected_time = `${moment(active_do[0].eta_modified)
        .add(active_do[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_do[0].arrival);
      eta =
        active_do[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    } else if (active_pu.length > 0) {
      const modifiedTime = moment(active_pu[0].eta_modified).format("lll");
      const expected_time = `${moment(active_pu[0].eta_modified)
        .add(active_pu[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_pu[0].arrival);
      eta =
        active_pu[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    }
    return moment().isBefore(eta.eta) && !out_of_route ? eta : false;
  };

  const dataParser = data => {
    const { bookings: trips, total } = data.get_bookings_per_trip;
    const sample = [];
    _.forEach(trips, _trip => {
      const pickups = [];
      const dropoffs = [];
      const raw_dropoffs = [];
      const raw_pickups = [];
      let parsedTrip = {};

      _.forEach(_trip.value, so => {
        _.forEach(so.pickups, pickup => {
          const exist = pickup.geofence_id
            ? _.findIndex(pickups, {
                geofence_id: pickup.geofence_id,
                arrival: pickup.arrival
              })
            : _.findIndex(pickups, {
                name: pickup.name,
                arrival: pickup.arrival
              });
          raw_pickups.push(pickup);
          if (exist === -1) {
            pickups.push({ ...pickup, is_pickup: true });
          }
        });
        _.forEach(so.dropoffs, (d, i) => {
          const exist = d.geofence_id
            ? _.findIndex(dropoffs, {
                geofence_id: d.geofence_id,
                arrival: d.arrival
              })
            : _.findIndex(dropoffs, {
                name: d.name,
                arrival: d.arrival
              });

          const items = so.items
            ? so.items.filter(item => item.dropoff_index === i)
            : [];
          raw_dropoffs.push({ ...d, items: items, trip_id: so.id });
          if (exist === -1) {
            dropoffs.push({ ...d, trip_id: so.id, is_pickup: false });
          }
        });

        if (so.dispatch_time) {
          if (!so.data_complete && !so.pre_dispatched_ready) {
            parsedTrip = {
              ...parsedTrip,
              vehicle_status: so.vehicle_status,
              vehicle_plate_no: so.vehicle_plate_no,
              service_type: so.service_type,
              vehicle_id: so.vehicle_id,
              vehicle_history: so.vehicle_history,
              status:
                so.status_code.toLowerCase() === "accepted" ||
                so.status_code.toLowerCase() === "scheduled"
                  ? "Processing"
                  : so.status_code,
              tripNumber: so.trip_number,
              is_urgent: so.is_urgent,
              raw_pickups: raw_pickups,
              raw_dropoffs: raw_dropoffs,
              hauler: so.client_name,
              total: _trip.value.length,
              has_split:
                so.status_code.toLowerCase() === "accepted" ||
                so.status_code.toLowerCase() === "scheduled" ||
                so.status_code.toLowerCase() === "arrived at pickup",
              has_no_dispatch:
                so.status_code.toLowerCase() !== "accepted" ||
                so.status_code.toLowerCase() !== "scheduled" ||
                so.status_code.toLowerCase() !== "incoming",
              raw_trip_status: so.status_code,
              out_of_route: so.out_of_route,
              data_completed: so.data_complete,
              is_overdue: so.overdue,
              pre_dispatched_ready: so.pre_dispatched_ready,
              dispatch_type: so.dispatch_type,
              trip_status:
                so.status_code.toLowerCase() === "accepted" ||
                so.status_code.toLowerCase() === "scheduled"
                  ? "Processing"
                  : so.status_code,
              is_split: so.is_split,
              status_code_id: so.status_code_id
            };
          }
        } else {
          parsedTrip = {
            ...parsedTrip,
            vehicle_status: so.vehicle_status,
            vehicle_plate_no: so.vehicle_plate_no,
            service_type: so.service_type,
            vehicle_id: so.vehicle_id,
            vehicle_history: so.vehicle_history,
            status:
              so.status_code.toLowerCase() === "accepted" ||
              so.status_code.toLowerCase() === "scheduled"
                ? "Processing"
                : so.status_code,
            tripNumber: so.trip_number,
            is_urgent: so.is_urgent,
            raw_pickups: raw_pickups,
            raw_dropoffs: raw_dropoffs,
            hauler: so.client_name,
            total: _trip.value.length,
            has_split:
              so.status_code.toLowerCase() === "accepted" ||
              so.status_code.toLowerCase() === "scheduled" ||
              so.status_code.toLowerCase() === "arrived at pickup",
            has_no_dispatch:
              so.status_code.toLowerCase() !== "accepted" ||
              so.status_code.toLowerCase() !== "scheduled" ||
              so.status_code.toLowerCase() !== "incoming",
            raw_trip_status: so.status_code,
            out_of_route: so.out_of_route,
            data_completed: so.data_complete,
            is_overdue: so.overdue,
            pre_dispatched_ready: so.pre_dispatched_ready,
            dispatch_type: so.dispatch_type,
            trip_status:
              so.status_code.toLowerCase() === "accepted" ||
              so.status_code.toLowerCase() === "scheduled"
                ? "Processing"
                : so.status_code,
            is_split: so.is_split,
            status_code_id: so.status_code_id
          };
        }
      });
      if (Object.keys(parsedTrip).length) {
        parsedTrip = {
          ...parsedTrip,

          dropoffs: dropoffs,
          pickups: pickups,
          first_pickup: pickups[0].name,
          first_pickup_arrival: pickups[0].arrival,
          first_dropoff: dropoffs[0].name,
          first_dropoff_arrival: dropoffs[0].arrival,
          eta: getEta(
            parsedTrip.raw_pickups,
            parsedTrip.raw_dropoffs,
            parsedTrip.out_of_route
          ),
          has_empty_so: _.filter(_trip.value, t => !t.so_number).length > 0
        };

        sample.push(parsedTrip);
      }
    });
    const toDisplayBookings = _.filter(
      sample,
      s => !s.data_completed || s.status === "Processing"
      // s => s.status === "Processing" || s.status === "Incoming"
    );
    return { toDisplayBookings, total, sample };
  };

  useEffect(() => {
    // check if the variable tripReassign is empty
    if (!_.isEmpty(tripReassign)) {
      // re-organize the data using dataParser function
      const { sample } = dataParser(tripReassign);
      const { get_bookings_per_trip } = tripReassign;
      const rawTripData = get_bookings_per_trip.bookings.filter(
        b => b.key === trip
      );
      const active_row = sample.filter(b => b.tripNumber === trip);

      setActiveRow(active_row[0]);
      setActiveRawData(rawTripData[0]);
    }
  }, [tripReassign]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setOpenActions(null);
    setActiveRow({});
  };

  const toggleDrawer = () => {
    setOpenFilter(!openFilter);
  };

  // Function used to display vehicle plate no, gps status and provider, and battery status
  const renderVehicleCell = useCallback(
    booking => {
      let cell = "";
      if (booking.vehicle_id) {
        let data = {
          vehicleType: "",
          deviceTypeId: 0,
          batteryLevel: 0,
          reportstampBatt: null
        };
        const checkBooking = dvar.filter(
          elem => elem.id === booking.vehicle_id
        );

        // checkBooking &&
        checkBooking.map(drow => {
          data = {
            vehicleType: drow.vehicle_info.vehicle_type,
            deviceTypeId: drow.device_type_id,
            batteryLevel: drow.device_status.battery_level,
            reportstampBatt: drow.device_status.reportstamp
          };
          return checkBooking;
        });
        cell = (
          <span>
            <Battery
              vehicleType={data.vehicleType}
              deviceTypeId={data.deviceTypeId}
              batteryLevel={data.batteryLevel}
              tooltipTitle={
                <Fragment>
                  <p style={{ margin: 0, padding: 0 }}>
                    Battery Life: {data.batteryLevel || "-"} %
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    As of{" "}
                    {data.reportstampBatt
                      ? moment(data.reportstampBatt).format("lll")
                      : "-"}
                  </p>
                </Fragment>
              }
            />
            <Tooltip
              title={
                <Fragment>
                  <p style={{ margin: 0, padding: 0 }}>
                    {gpsStatus(booking.vehicle_status?.toLowerCase()).label}
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    Last Report:{" "}
                    {// Check if the vehicle has a device
                    (dvar.some(elem => elem.id === booking.vehicle_id) &&
                      dvar.map(drow => {
                        let reportstamp = "";
                        // Check if the device id is matched in the vehicle id
                        if (drow.id === booking.vehicle_id) {
                          reportstamp =
                            (drow.device_status.reportstamp &&
                              moment(drow.device_status.reportstamp).format(
                                "lll"
                              )) ||
                            "-";
                          data = {
                            vehicleType: drow.vehicle_info.vehicle_type,
                            deviceTypeId: drow.device_type_id,
                            batteryLevel: drow.device_status.battery_level,
                            reportstampBatt: drow.device_status.reportstamp
                          };
                        }
                        return reportstamp;
                      })) ||
                      "-"}
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    GPS Provider:{" "}
                    {(dvar.some(elem => elem.id === booking.vehicle_id) &&
                      dvar.map(drow => {
                        let provider = "";
                        if (drow.id === booking.vehicle_id) {
                          provider = drow.device_config.gps_provider || "-";
                        }
                        return provider;
                      })) ||
                      "-"}
                  </p>
                </Fragment>
              }
            >
              <Typography
                style={{
                  // display: "inline-block",
                  fontSize: "0.875rem"
                }}
                component="span"
                noWrap
              >
                <TripOrigin
                  style={{
                    fontSize: "12px",
                    color: gpsStatus(booking.vehicle_status?.toLowerCase())
                      .color
                  }}
                />
                {booking.vehicle_plate_no || "-"}
              </Typography>
            </Tooltip>
          </span>
        );
      } else {
        const title = booking.vehicle_plate_no
          ? "Please set up this vehicle. Vehicle setup is required to start monitoring this trip"
          : "Please assign a vehicle";
        cell = (
          <Tooltip title={title}>
            <Typography
              style={{
                display: "block",
                fontSize: "0.875rem"
              }}
              component="span"
              noWrap
            >
              <ErrorOutline
                style={{
                  fontSize: "12px",
                  color: "red"
                }}
              />
              {booking.vehicle_plate_no || "-"}
            </Typography>
          </Tooltip>
        );
      }
      return cell;
    },
    [dvar]
  );

  const getDestinationStyle = stop => {
    let color = "";
    const { status_code, actual_arrival, manual_arrival, arrival } = stop;
    let time = "Vehicle not arrived yet";
    const isLate = arrival ? moment(arrival).isBefore(actual_arrival) : false;
    let textColor = "black";
    switch (status_code) {
      case "Manually Completed":
        color = "#219653";
        time = `Manual arrival time ${moment(manual_arrival).format("lll")}`;
        break;
      case "Completed":
        textColor = isLate ? "#EA4335" : "black";
        time = (
          <Fragment>
            <p style={{ margin: 0, padding: 0 }}>
              Actual Arrival Time{" "}
              <span
                style={{
                  color: isLate ? "#EA4335" : "#6DE243"
                }}
              >
                {isLate ? "(Late)" : "(On Time)"}
              </span>
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              {moment(actual_arrival).format("lll")}
            </p>
          </Fragment>
        );
        color = "#2dd112";
        break;
      case `Arrived at ${stop.is_pickup ? "Pickup" : "Drop off"}`:
        textColor = isLate ? "#EA4335" : "black";
        time = (
          <Fragment>
            <p style={{ margin: 0, padding: 0 }}>
              Actual Arrival Time{" "}
              <span
                style={{
                  color: isLate ? "#EA4335" : "#6DE243"
                }}
              >
                {isLate ? "(Late)" : "(On Time)"}
              </span>
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              {moment(actual_arrival).format("lll")}
            </p>
          </Fragment>
        );
        color = "#ffc207";
        break;
      default:
        color = "#c6c6c6";
        break;
    }
    return { color, textColor, isLate, time };
  };

  const renderDestination = (stop, index) => {
    let display = "";
    if (stop.geofence_id) {
      const { textColor, time, color } = getDestinationStyle(stop);
      display = (
        <Tooltip
          title={time}
          aria-label={stop.status_code}
          key={`${stop.name}-${index}`}
        >
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "block",
              color: textColor
            }}
          >
            <Brightness1
              style={{
                fontSize: "12px",
                color: color
              }}
            />
            {stop.geofence_code
              ? `${stop.geofence_code}-${stop.name}`
              : stop.name}
          </span>
        </Tooltip>
      );
    } else {
      const title =
        "Please set up the geofence. Geofence setup is required to start monitoring this trip";
      display = (
        <Tooltip title={title} key={`${stop.name}-${index}`}>
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "block"
            }}
          >
            <ErrorOutline
              style={{
                fontSize: "12px",
                color: "red"
              }}
            />
            {stop.geofence_code || stop.name || "-"}
          </span>
        </Tooltip>
      );
    }
    return display;
  };

  const renderDestinationArrival = (stop, index) => {
    const { arrival } = stop;

    let display = "";
    const title =
      "Please complete the trip details. This field is required to start monitoring the trip.";
    if (arrival && arrival !== "1111-11-11 11:11:00") {
      display = (
        <span
          style={{ display: "block" }}
          key={`${stop.geofence_id}-${stop.arrival}`}
        >
          {moment(stop.arrival).format("lll")}
        </span>
      );
    } else {
      display = (
        <Tooltip title={title} key={`${stop.name}-${index}`}>
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "block"
            }}
          >
            <ErrorOutline
              style={{
                fontSize: "12px",
                color: "red"
              }}
            />
            -
          </span>
        </Tooltip>
      );
    }
    return display;
  };

  const getDateRange = () => {
    let date_range = {};
    if (!_.isEmpty(activeRow)) {
      if (
        activeRow.raw_pickups[0].arrival &&
        activeRow.raw_dropoffs[activeRow.raw_dropoffs.length - 1].arrival
      ) {
        date_range = {
          start: activeRow.raw_pickups[0].arrival,
          end: activeRow.raw_dropoffs[activeRow.raw_dropoffs.length - 1].arrival
        };
      } else {
        date_range = false;
      }
    }
    return date_range;
  };

  // Function used to combine and remove duplicate betweeb two array and return a new array
  const combineArray = (firstArray, secondArray) => {
    // Concat the two given array to form a new single array
    const thirdArray = firstArray.concat(secondArray);
    // Convert the new array into a set to remove a duplicate data and sort into a new array
    const uniqueElements = new Set(thirdArray.sort());
    // Convert the new set into an array and Map the new array to display the desired output
    return Array.from(uniqueElements).map(el => {
      return el ? (
        <span style={{ display: "block" }}>{el}</span>
      ) : (
        "No SO Number Yet"
      );
    });
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectAllTrips: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  const {
    progress: { prebooking: currentStep },
    completeOnboarding
  } = useOnboardingContext();

  const stage = {
    0: {
      image: prebooking_welcome,
      title: "Prebooking: Check your Delivery Details",
      description: `Check your delivery's information before dispatching them in this module`,
      buttonText: "Got It"
    },
    7: {
      buttonText: "Start",
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };

  return (
    <AccessControl resource="/prebooking/" process="prebooking">
      <Fragment>
        <Focus
          step={[0, 7]}
          on={[0, 7].includes(currentStep)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: [0, 7].includes(currentStep) ? "block" : "none",
            background: "transparent"
          }}
          showInfoBubble={false}
        >
          <Stage {...stage[currentStep]} />
        </Focus>
        <div style={{ display: "none" }}>
          <CSVLink
            data={downloadData.body}
            headers={downloadData.headers}
            ref={csvLink}
            style={{ color: "black", textDecoration: "none" }}
          >
            Download Table
          </CSVLink>
        </div>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Focus
              step={1}
              on={!openFilter}
              indicator={currentStep === 1 && !openFilter}
              style={{ padding: 12 }}
              infoBubbleProps={{
                position: "right",
                title: "Filter",
                description:
                  "Allow to display certain data based on selected values"
                // body: <TripLegends />
              }}
            >
              <Button
                variant="contained"
                startIcon={<FilterList />}
                onClick={() => toggleDrawer()}
                style={{
                  borderRadius: "25px",
                  // paddingInline: "20px",
                  fontSize: "12px",
                  backgroundColor: "#808080"
                }}
              >
                Filter
              </Button>
            </Focus>
            <Focus
              step={2}
              style={{ padding: 12 }}
              infoBubbleProps={{
                position: "right",
                title: "Download",
                description:
                  "Allows the transfer of data from web to user's device"
              }}
            >
              <Download
                csvLink={csvLink}
                // downloadBTN={downloadBTN}
                // keyLog={keyLog}
              />
            </Focus>

            <Focus
              step={3}
              style={{ padding: 12 }}
              infoBubbleProps={{
                position: "right",
                title: "Upload",
                description:
                  "Allows the transfer of data from user's device to web"
                // body: <TripLegends />
              }}
            >
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<Publish />}
                // onClick={handleOpenUpload}
              >
                Upload
              </Button>
            </Focus>
            <div className={classes.root} />
            <Typography style={{ marginRight: 40 }}>
              As of {moment().format("lll")}
            </Typography>
            <Pagination rowsPerPage={10} total={100} currentPage={1} />
          </Toolbar>
          {selectedTrips.length > 0 && (
            <Toolbar className={classes.multipleSelect}>
              <Typography>
                <span className={classes.countSelectedTrips}>
                  {selectedTrips.length}
                </span>{" "}
                trip/s is selected on this page
              </Typography>
              <div className={classes.root} />
              {permissions?.close?.edit && (
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    borderRadius: 16,
                    backgroundColor: "red",
                    color: "white",
                    width: 150
                  }}
                >
                  Close
                </Button>
              )}
              &nbsp;
              {permissions?.dispatch?.edit && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 16, width: 150 }}
                >
                  Dispatch
                </Button>
              )}
              &nbsp;
              <Button
                variant="contained"
                style={{ borderRadius: 16, width: 150 }}
              >
                Refresh
              </Button>
            </Toolbar>
          )}
        </AppBar>
        <div
          className={classes.tableRoot}
          // style={{ marginTop: selectedTrips.length > 0 ? 109 : 64 }}
        >
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                onRequestSort={handleRequestSort}
                onSelectAllTrips={handleSelectAllTrips}
                isChecked={isChecked}
              />
              <TableBody>
                {getData().map((booking, index) => {
                  const isItemSelected = isSelected(booking.tripNumber);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    // <TableRow key={booking.tripNumber}>
                    <Focus
                      step={4}
                      component={TableRow}
                      on={index === 0}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${booking.tripNumber}-${index}`}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        {booking.status.toLowerCase() !== "closed" &&
                        booking.status.toLowerCase() !== "cancelled" &&
                        booking.status.toLowerCase() !== "completed" ? (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            s
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell style={{ paddingLeft: "30px" }}>
                        <Tooltip
                          title={
                            booking.dispatch_type &&
                            booking.dispatch_type.toLowerCase() !==
                              "not yet dispatched"
                              ? `${booking.dispatch_type} Dispatched`
                              : `Undefined`
                          }
                        >
                          <Badge
                            classes={{
                              badge: booking.dispatch_type
                                ? (booking.dispatch_type.toLowerCase() ===
                                    "manual" &&
                                    classes.customBadgeManual) ||
                                  (booking.dispatch_type.toLowerCase() ===
                                    "automatic" &&
                                    classes.customBadgeAutomatic) ||
                                  (booking.dispatch_type.toLowerCase() ===
                                    "not yet" &&
                                    classes.customBadgeNotYetDispatch) ||
                                  classes.customBadgeUndefine
                                : classes.customBadgeUndefine
                            }}
                            variant="dot"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Focus
                          step={6}
                          on={index === 0}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5,
                            width: "35px",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            boxSizing: "border-box"
                          }}
                          showInfoBubble={false}
                        >
                          {/* {index % 2 === 0 && ( */}
                          <Tooltip title="Hotload">
                            <Warning
                              style={{ fontSize: 12, color: "#EA4335" }}
                            />
                          </Tooltip>
                          {/* )} */}
                        </Focus>
                      </TableCell>
                      <TableCell>
                        <Focus
                          step={6}
                          on={index === 1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5,
                            width: "35px",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            boxSizing: "border-box"
                          }}
                          // showInfoBubble={false}
                          infoBubbleProps={{
                            title: "Indicators",
                            description:
                              "Indicates the status of the trip if hotload or overdue trip",
                            body: <TripStatusLegends />
                          }}
                        >
                          {/* {index % 2 === 1 && ( */}
                          <Tooltip title="It seems this trip is overdue. Would you like to close it?">
                            <AssignmentLate
                              style={{ fontSize: 12, color: "#EA4335" }}
                            />
                          </Tooltip>
                          {/* )} */}
                        </Focus>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={combineArray(
                            booking.raw_pickups.map(el => {
                              return el.so_number;
                            }),
                            booking.raw_dropoffs.map(el => {
                              return el.so_number;
                            })
                          )}
                        >
                          <span>{booking.tripNumber}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {booking.has_empty_so && (
                          <Tooltip title="No SO number yet">
                            <ErrorOutline
                              style={{
                                fontSize: "inherit",
                                color: "red "
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{booking.service_type || "N/A"}</TableCell>
                      <TableCell>
                        {booking.pickups.map((p, p_index) => {
                          return renderDestination(p, p_index);
                        })}
                      </TableCell>
                      <TableCell>
                        {booking.pickups.map((p, p_index) => {
                          return renderDestinationArrival(p, p_index);
                        })}
                      </TableCell>
                      <Focus
                        step={4}
                        on={index === 0}
                        infoBubbleProps={{
                          position: "bottom",
                          title: "Data Table",
                          description:
                            "Display details and status of a particular trip"
                        }}
                        component={TableCell}
                      >
                        {booking.dropoffs.map((p, p_index) => {
                          return renderDestination(p, p_index);
                        })}
                      </Focus>
                      <TableCell>
                        {booking.dropoffs.map((p, p_index) => {
                          return renderDestinationArrival(p, p_index);
                        })}
                      </TableCell>
                      <TableCell>{booking.hauler}</TableCell>
                      <TableCell>
                        <span
                          style={{ display: "inline-block", width: "100px" }}
                        >
                          {renderVehicleCell(booking)}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Fragment>
                          <span style={{ color: "#C4C4C4", display: "block" }}>
                            {booking.status}
                          </span>
                          {(booking.status.toLowerCase() === "incoming" ||
                            booking.status.toLowerCase() === "in transit") &&
                            booking.eta && (
                              <Tooltip title={`As of ${booking.eta.as_of}`}>
                                <span
                                  style={{
                                    display: "block",
                                    fontSize: 10,
                                    color: booking.eta.isLate ? "red" : "green"
                                  }}
                                >
                                  <ErrorOutline
                                    style={{
                                      fontSize: "inherit",
                                      color: "inherit"
                                    }}
                                  />
                                  {booking.eta.eta}
                                </span>
                              </Tooltip>
                            )}
                        </Fragment>
                      </TableCell>
                      <Focus
                        step={5}
                        on={index === 0}
                        indicator={currentStep === 5 && index === 0}
                        showInfoBubble={!open}
                        indicatorProps={{ placement: "top-left" }}
                        infoBubbleProps={{
                          position: "left",
                          title: "Action",
                          description:
                            "Shows available actions to be executed on a trip"
                        }}
                        component={TableCell}
                      >
                        <IconButton
                          aria-describedby={id}
                          onClick={event => {
                            if (openActions) {
                              setOpenActions(null);
                              return;
                            }
                            if (currentStep === 5)
                              setOpenActions(event.currentTarget);
                          }}
                        >
                          {booking.status.toLowerCase() !== "cancelled" ? (
                            <MoreVert />
                          ) : (
                            ""
                          )}
                        </IconButton>
                      </Focus>
                    </Focus>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={openActions}
          onClose={handleClose}
          classes={{
            paper: classes.popoverPaper
          }}
        >
          {activeRow && activeRow.status === "Closed" ? (
            <>
              {permissions?.manual_complete?.edit && (
                <MenuItem>Manual Complete</MenuItem>
              )}

              <MenuItem disabled={activeRow.data_completed}>Refresh</MenuItem>
            </>
          ) : (
            <>
              {permissions?.change_truck?.edit && (
                <MenuItem
                  disabled={!getDateRange() || activeRow?.status === "Closed"}
                  // onClick={() => {
                  //   setOpenVehicleModal(true);
                  //   setOpenActions(null);
                  // }}
                >
                  Change Truck
                </MenuItem>
              )}
              {permissions?.split?.edit && !activeRow?.is_split ? (
                <MenuItem
                  // component={Link}
                  disabled={!activeRow?.has_split}
                  // to={
                  //   activeRow?.data_completed && !activeRow?.has_empty_so
                  //     ? {
                  //         pathname: `/split`,
                  //         state: {
                  //           parsedTripData: activeRow,
                  //           rawTripData: activeRawData,
                  //           moduleName:
                  //             process.env.REACT_APP_PRE_DISPATCH_MODULE,
                  //           dateRange: getDateRange()
                  //         }
                  //       }
                  //     : {}
                  // }
                >
                  Split
                </MenuItem>
              ) : (
                ""
              )}

              {permissions?.dispatch?.edit && (
                <MenuItem disabled={activeRow?.status !== "Processing"}>
                  Dispatch
                </MenuItem>
              )}

              {permissions?.close?.edit && (
                <MenuItem
                  disabled={activeRow?.status === "Closed"}
                  // onClick={() => {
                  //   handleCloseClick(true);
                  //   setOpenActions(null);
                  // }}
                >
                  Close
                </MenuItem>
              )}
              <MenuItem
                disabled={activeRow?.data_completed}
                // onClick={() => setOpenRefreshSingle(true)}
              >
                Refresh
              </MenuItem>
            </>
          )}
        </Popover>
        <Filter
          isOpen={openFilter}
          closeFilterDrawer={() => setOpenFilter(false)}
          renderFilterHeader={({ handleClearFilter }) => (
            <CustomFilterHeader handleClearFilter={handleClearFilter} />
          )}
        >
          {[
            { label: "From" },
            { label: "To" },
            { label: "Service Type" },
            { label: "Pickup Location" },
            { label: "Delivery Location" },
            { label: "Hauler" },
            { label: "Plate Number" },
            { label: "Status" }
          ].map(item => (
            <FormControl
              classes={{ root: formStyles.formRoot }}
              style={{ width: "100%", minWidth: "auto" }}
            >
              <InputLabel shrink>{item.label}</InputLabel>
              <br />
              <Autocomplete
                options={["All"]}
                value="All"
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    classes={{ root: formStyles.textFieldRoot }}
                    value="All"
                    placeholder="All"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: "6px 0px"
                      },
                      endAdornment: (
                        <React.Fragment>
                          {["From", "To"].includes(item.label) ? (
                            <EventIcon htmlColor="#707070" fontSize="small" />
                          ) : [
                              "Pickup Location",
                              "Delivery Location",
                              "Hauler",
                              "Plate Number"
                            ].includes(item.label) ? (
                            <SearchIcon htmlColor="#707070" fontSize="small" />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                          {/* {params.InputProps.endAdornment} */}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </FormControl>
          ))}
        </Filter>
      </Fragment>
    </AccessControl>
  );
};

export default PreBooking;
