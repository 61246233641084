import React, { useCallback } from "react";
import { styled, Typography, useMediaQuery } from "@material-ui/core";
import { feedBackOpts } from "../../../../data/feedbackInfo";

const webcastContactLink = "https://webcast-inc.com.ph/contact/";

const Sidebar = props => {
  const { view, setView } = props;
  const isSmallScreen = useMediaQuery("(min-width:960px)");

  const OptionsViewHandler = (newWindow, opt) => {
    if (newWindow) window.open(webcastContactLink, "_blank");
    else setView(opt);
  };

  const MenuHandler = idx => {
    setView(0);
    const docElement = document.getElementById(`faq-${idx}`);
    if (docElement) docElement.scrollIntoView({ behavior: "smooth" });
  };

  // eslint-disable-next-line consistent-return
  const feedBackSubMenuOpt = opts => {
    if (opts.length) {
      return (
        <StyledMenuBox>
          {opts.map((menus, idx) => (
            <>
              <StyledOptTypography
                key={menus.id}
                style={{ marginLeft: 20 }}
                onClick={() => MenuHandler(idx)}
              >
                {menus.label}
              </StyledOptTypography>
            </>
          ))}
        </StyledMenuBox>
      );
    }
  };

  const feedBackOptions = useCallback(
    () =>
      feedBackOpts.map(opts => (
        <>
          {view === opts.id ? (
            <StyledActOptTypography>{opts.label}</StyledActOptTypography>
          ) : (
            <StyledOptTypography
              key={opts.name}
              onClick={() => OptionsViewHandler(opts.id === 2, opts.id)}
            >
              {opts.label}
            </StyledOptTypography>
          )}
          {!isSmallScreen &&
            opts.id === view &&
            feedBackSubMenuOpt(opts.subMenus)}
          {isSmallScreen && feedBackSubMenuOpt(opts.subMenus)}
        </>
      )),
    [isSmallScreen, view]
  );

  return <StyledFAQBox>{feedBackOptions()}</StyledFAQBox>;
};

const StyledFAQBox = styled("div")(({ theme }) => ({
  height: "100%",
  minHeight: "70vh",
  width: "auto",
  boxShadow: "none",
  backgroundColor: theme.palette.common.white,
  padding: "2rem",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    height: "auto",
    minHeight: "auto",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0.5rem",
    marginBottom: "3.5rem"
  }
}));

const StyledMenuBox = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    left: 0,
    bottom: "-42px",
    backgroundColor: theme.palette.common.white,
    padding: "2px 15px",
    display: "flex",
    justifyContent: "space-between",
    width: "96%",
    maxWidth: "100vw",
    borderTop: `2px solid ${theme.palette.primary.main}`
  }
}));

const StyledOptTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: "Nunito",
  fontWeight: 600,
  fontSize: 13,
  cursor: "pointer",
  margin: "0.5rem 0",
  "&:hover": {
    color: theme.palette.primary.main
  }
}));

const StyledActOptTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: "Nunito",
  fontWeight: 600,
  fontSize: 13,
  cursor: "pointer",
  margin: "0.5rem 0"
}));

export default Sidebar;
