/* eslint-disable no-unused-vars */
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Box,
  IconButton
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { HelpRounded } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import Swal from "sweetalert2";
import moment from "moment";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQuery } from "@apollo/client";
import Header from "../../Header";
import driverStyles from "./DriverInfo.style";
import validate from "../../../../utils/validation";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import {
  GET_DRIVER_INFO,
  GET_USERS
} from "../../../../graphql/Queries";
import {
  UPDATE_DRIVER_INFO,
  EDIT_DRIVER_VEHICLE
} from "../../../../graphql/Mutations";
import Loading from "../../../../utils/Loading";
import AddLogs from "../../../../utils/functions/AddLogs";
import PairDriverVehicle from "../../../../utils/modals/PairDriverVehicle";
import useUserContext from "../../../../context/User/useUserContext";
import SearchContext from "../../../../context/SearchContext";

const EditDriverInfo = () => {
  const classes = driverStyles();
  const { id } = useParams();
  const history = useHistory();
  const user = useUserContext();
  const contextData = useContext(SearchContext)
  const {searchKeyword, setSearchKeyword} = contextData

  const [state, setState] = useState({
    id: "",
    employeeid: "",
    name: "",
    mobileno: "",
    group_ids: "",
    supervisor: "",
    license_exp_notif_day: 0,
    license_expiration: moment().format("MM/DD/YYYY"),
    license_number: "",
    license_restriction: "",
    status_code: ""
  });

  const [error, setError] = useState({
    employeeid: "",
    name: "",
    mobileno: "",
    pairVehicle: "",
    supervisor: "",
    license_exp_notif_day: "",
    license_expiration: "",
    license_number: ""
  });
  const [initialState, setInitialState] = useState();
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [vehicleModal, setVehicleModal] = useState();
  const [editMode, setEditMode] = useState(false);
  const [save, setSave] = useState({ saved: false });
  const [forceRerender, setForceRerender] = useState(false);
  const [pairedVehicle, setPairedVehicle] = useState();
  const [initialPairedVehicle, setInitialPairedVehicle] = useState();

  // query for dropdown supervisor
  const { data: getUsers } = useQuery(GET_USERS, {
    variables: {
      condition: [
        {
          field: "user_levels.name",
          value: "Supervisor"
        }
      ]
    }
  });

  // mutation for pairing/unpairing (edit_vehicle)
  const { data: driverData, loading: driverLoading } = useQuery(
    GET_DRIVER_INFO,
    {
      variables: {
        condition: { field: "personnels.id", value: id }
      },
      onCompleted: data => {
        const temp = [];
        const tempDriver = [];
        Object.keys(data.get_personnel_by)
          .filter(element => element in state)
          .forEach(element => {
            temp.push({ [element]: data.get_personnel_by[element] });
          });

        Object.keys(data.get_personnel_by.driver_info)
          .filter(element => element in state)
          .forEach(element => {
            _.unset(element, id);
            tempDriver.push({
              [element]: data.get_personnel_by.driver_info[element]
            });
          });
        setState(Object.assign(state, ...tempDriver, ...temp));
        setInitialState(Object.assign(state, ...tempDriver, ...temp));
        
        // info of paired vehicle
        const vehicleData = data.get_personnel_by?.vehicle_info
          ? {
              ...data.get_personnel_by.vehicle_info,
              personnel_id: data.get_personnel_by.id
            }
          : null;
        setPairedVehicle(vehicleData);
        setInitialPairedVehicle(vehicleData);
        // setInitialPairedVehicle(data.get_personnel_by);
      },
      fetchPolicy: "network-only"
    }
  );

  // mutation for pairing/unpairng (edit_vehivle)
  const [editDriverInfo] = useMutation(EDIT_DRIVER_VEHICLE, {
    onCompleted: () => {
      // refetchVehicle();
      if (pairedVehicle)
        AddLogs("Admin - Driver Info", "pair", [
          state.name,
          pairedVehicle.plateno
        ]);
    }
  });

  // mutation for updating driver info
  const [updateDriver] = useMutation(UPDATE_DRIVER_INFO, {
    onCompleted: data => {
      if (data.update_personnel.success) {
        AddLogs("Admin - Driver Info", "update", state.name);
        Swal.fire({
          title: "Saved",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
          onCLose: () => {
            // redirect to table after saving and pass the save filter values
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_DRIVER_INFO_MODULE,
                  vars: history?.location?.state?.vars
                }
              }
            });
            // redirectToTable(history, process.env.REACT_APP_DRIVER_INFO_MODULE);
          }
        }).then(result => {
          // redirect to table after saving and pass the save filter values
          if (result.dismiss === Swal.DismissReason.timer) {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_DRIVER_INFO_MODULE,
                  vars: history?.location?.state?.vars
                }
              }
            });
            // redirectToTable(history, process.env.REACT_APP_DRIVER_INFO_MODULE);
          }
        });
      }
    }
  });

  // validating text field by their name
  const check = field => {
    if (!error[field]) {
      if (field === "employeeid") {
        return _.isEmpty(state.employeeid) ? "This field is required!" : "";
      }
      if (field === "mobileno") {
        return _.isEmpty(state.mobileno)
          ? "This field is required!"
          : !validate("alphanumeric", state.mobileno)
          ? "Invalid mobile number"
          : "";
      }
      if (field === "pairVehicle") {
        return error[field];
      }
      if (field === "supervisor") {
        return error[field];
      }
      if (field === "name") {
        return _.isEmpty(state.name) ? "This field is required!" : "";
      }
      if (field === "license_number") {
        return _.isEmpty(state.license_number) ? "This field is required!" : "";
      }
      if (field === "license_exp_notif_day") {
        return state.license_exp_notif_day <= 0
          ? "This field is required!"
          : "";
      }
      return state[field] === "" || state[field] === -1
        ? "This field is required"
        : "";
    }
    return error[field];
  };

  // set error messages under textdileds
  const validateFields = () => {
    const keys = Object.keys(error);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setError(Object.assign(error, ...temp));
    return keys.every(element => error[element] === "");
  };

  // modal for saving datas
  const handleOpenSave = name => {
    if (name === "saved") {
      if (validateFields()) setSave({ saved: true });
      else setForceRerender(!forceRerender);
    } else {
      setSave({ saved: true });
    }
  };

  // check if has a value a display error messages
  const handleBlur = e => {
    const { name, value } = e.target;
    let errorMsg;

    switch (name) {
      case "employeeid":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (
          !validate("alphanumeric_with", value.trim(), { chars: "-" })
        ) {
          errorMsg = "Alphanumeric characters only";
        } else if (e.target.value.length > 20) {
          errorMsg = "Maximum of 20 characters only";
        }
        break;
      case "name":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (
          !validate("alphanumeric_with", value.trim(), { chars: " ," })
        ) {
          errorMsg = "Alphanumeric characters only";
        }
        break;
      case "mobileno":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (!validate("mobile_number_ph", value.trim())) {
          errorMsg = "Invalid mobile number";
        }
        break;
      case "license_number":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (
          !validate("alphanumeric_with", value.trim(), { chars: "-" })
        ) {
          errorMsg = "Alphanumeric characters only";
        }
        break;
      case "license_exp_notif_day":
        if (value.trim() === "" || value.trim() <= 0) {
          errorMsg = "This field is required";
        } else if (+value > 90) {
          errorMsg = "Minimum of 1 maximum of 90 days only";
        }
        // else if (!validate("alphanumeric_default", value.trim())) {
        //   error = "Alphanumeric characters only";
        // }
        break;
      default:
        break;
    }

    setError({ ...error, [name]: errorMsg });
  };

  // updating state when changing
  const handleChange = e => {
    const { name, value } = e.target;
    const errors = "";

    setError({ ...error, [name]: errors });
    setState({ ...state, [name]: value });
  };

  // formatting selected date MM/DD/YYYY
  const handleDate = (val, name) => {
    setState({
      ...state,
      license_expiration: val
    });
  };

  // modal for selecting vehicle
  const openVehicleModal = () => {
    setVehicleModal(true);
  };

  // mutation of driver info
  function closeVehicleModal() {
    setVehicleModal(false);
  }

  // ON/OFF toggle button
  const toggleEditMode = event => {
    setEditMode(event.target.checked);
  };

  // mutation of driver info
  const handleSubmit = e => {
    e.preventDefault();

    updateDriver({
      variables: {
        id: +state.id,
        employeeid: state.employeeid,
        name: state.name,
        mobileno: state.mobileno,
        supervisor: state.supervisor,
        designation: "driver",
        group_ids: state.group_ids,
        client_id: +user.client.id,
        driver_info: {
          license_number: state.license_number,
          license_expiration: state.license_expiration,
          license_exp_notif_day: +state.license_exp_notif_day,
          status_code_id: 0
        }
      }
    });

    if (initialPairedVehicle?.personnel_id !== undefined) {
      // pair driver to vehicle
      editDriverInfo({
        variables: {
          id: initialPairedVehicle?.id,
          vehicle: {
            personnel_id: null,
            paired: 0
          }
        },
        onCompleted: () => {
          if (pairedVehicle) {
            editDriverInfo({
              variables: {
                id: pairedVehicle.id,
                vehicle: {
                  personnel_id: pairedVehicle ? state.id : null,
                  paired: pairedVehicle
                    ? +process.env.REACT_APP_STATUS_DRIVER_PAIRED
                    : 0
                }
              }
            });
          }
        }
      });
    } else {
      // pair driver to vehicle
      editDriverInfo({
        variables: {
          id: pairedVehicle ? pairedVehicle.id : initialState.id,
          vehicle: {
            personnel_id: pairedVehicle ? state.id : "",
            paired: pairedVehicle
              ? +process.env.REACT_APP_STATUS_DRIVER_PAIRED
              : 0
          }
        }
      });
    }
  };

  React.useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
    });
  }, []);

  if (driverLoading) return <Loading />;

  return (
    <div className={classes.root}>
      <Header
        process={editMode ? "Edit" : "View"}
        editPermission
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        moduleName={process.env.REACT_APP_DRIVER_INFO_MODULE}
        redirect={redirect}
        setRedirect={setRedirect}
        setDiscard={setDiscard}
        history={history?.location?.state}
        isDataNotChanged={
          _.isEqual(state, initialState) &&
          _.isEqual(
            pairedVehicle?.personnel_id,
            initialPairedVehicle?.personnel_id ||
              initialPairedVehicle?.personnel_id,
            undefined
          )
        }
      />
      <Paper variant="outlined" className={classes.container}>
        <div className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                All fields with <span style={{ color: "red" }}> * </span> are
                required.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Driver Information</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    className={classes.textfields}
                    name="employeeid"
                    label="Employee ID"
                    placeholder="Enter employee ID"
                    value={state && state.employeeid}
                    disabled={!editMode}
                    InputProps={{
                      readOnly: !editMode,
                      disableUnderline: !editMode,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.employeeid && (
                    <Alert severity="error" className={classes.alert}>
                      {error.employeeid}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    className={classes.textfields}
                    name="name"
                    label="Driver Name"
                    placeholder="Enter driver name"
                    value={state && state.name}
                    disabled={!editMode}
                    InputProps={{
                      readOnly: !editMode,
                      disableUnderline: !editMode,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.name && (
                    <Alert severity="error" className={classes.alert}>
                      {error.name}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    className={classes.textfields}
                    name="mobileno"
                    label="Contact Number"
                    placeholder="Enter contact number"
                    value={state && state.mobileno}
                    disabled={!editMode}
                    InputProps={{
                      readOnly: !editMode,
                      disableUnderline: !editMode,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onChange={e => handleChange(e)}
                    onBlur={e => {
                      e.persist();
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                      setState(prev => ({
                        ...prev,
                        mobileno: beginWith63
                      }));
                      e.target.value = beginWith63;
                      handleBlur(e);
                    }}
                  />
                  {error.mobileno && (
                    <Alert severity="error" className={classes.alert}>
                      {error.mobileno}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.unpairBox}>
                    <TextField
                      // fullWidth
                      readOnly
                      disabled={!editMode}
                      className={classes.textfields}
                      name="pairVehicle"
                      label="Vehicle"
                      placeholder="Select Vehicle"
                      value={pairedVehicle && pairedVehicle.plateno}
                      onClick={() =>
                        // vehicleData?.get_vehicles?.count === 0 &&
                        editMode && openVehicleModal()
                      }
                      // onChange={e => handleChange(e)}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "black",
                          fontWeight: "bold"
                        }
                      }}
                    />
                    <IconButton
                      className={classes.unpairIcon}
                      disabled={!pairedVehicle?.plateno || !editMode}
                      onClick={() => {
                        setPairedVehicle("");
                        AddLogs("Admin - Driver Info", "unpair", [
                          state.name,
                          pairedVehicle.plateno
                        ]);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 1024 1024"
                        fill={pairedVehicle?.plateno ? "#000000" : "none"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
                          strokeWidth="8"
                          stroke={
                            pairedVehicle?.plateno ? "#757575" : "#000000"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "13px"
                    }}
                  >
                    Supervisor
                  </InputLabel>
                  <Select
                    displayEmpty
                    fullWidth
                    disabled={!editMode}
                    name="supervisor"
                    placeholder="Select Supervisor"
                    className={classes.textfields}
                    value={state.supervisor}
                    onChange={e => handleChange(e)}
                  >
                    <MenuItem value="" disabled>
                      Select Supervisor
                    </MenuItem>
                    {getUsers &&
                      getUsers.get_users.users.map(sv => (
                        <MenuItem value={`${sv.first_name}  ${sv.last_name}`}>
                          {`${sv.first_name}  ${sv.last_name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    className={classes.textfields}
                    value={state && state.license_number}
                    name="license_number"
                    label="License No."
                    placeholder="Enter license no"
                    disabled={!editMode}
                    InputProps={{
                      readOnly: !editMode,
                      disableUnderline: !editMode,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.license_number && (
                    <Alert severity="error" className={classes.alert}>
                      {error.license_number}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {editMode ? (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          required
                          className={classes.textfields}
                          name="license_expiration"
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="License Expiration Date"
                          views={["year", "month", "date"]}
                          value={moment(state?.license_expiration).format(
                            "MM/DD/YYYY"
                          )}
                          onChange={e => {
                            handleDate(
                              moment(e).format("MM/DD/YYYY"),
                              "licenseExp"
                            );
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: "black",
                              fontWeight: "bold"
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  ) : (
                    <>
                      <TextField
                        required
                        className={classes.textfields}
                        placeholder="--/--/---"
                        defaultValue="--/--/----"
                        label="License Expiration Date"
                        value={moment(state?.license_expiration).format(
                          "MM/DD/YYYY"
                        )}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "black",
                            fontWeight: "bold"
                          }
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true
                        }}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end"
                    }}
                  >
                    <InputLabel
                      required
                      classes={{ root: classes.mediumTitle }}
                    >
                      Expiration Notification
                    </InputLabel>
                    <Tooltip
                      classes={{ tooltip: classes.toolTip }}
                      placement="top-start"
                      title="A notification to inform the driver's due date for license renewal"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end"
                    }}
                  >
                    <TextField
                      required
                      className={classes.textfields}
                      value={state && state.license_exp_notif_day}
                      placeholder="0"
                      type="number"
                      name="license_exp_notif_day"
                      onBlur={handleBlur}
                      onChange={e => handleChange(e)}
                      disabled={!editMode}
                      InputProps={{
                        readOnly: !editMode,
                        disableUnderline: !editMode,
                        style: { color: editMode ? "black" : "#808080" }
                      }}
                      style={{
                        width: "35px"
                      }}
                    />
                    <Typography variant="body2">
                      Days before license expiration
                    </Typography>
                  </div>
                  {error.license_exp_notif_day && (
                    <Alert severity="error" className={classes.alert}>
                      {error.license_exp_notif_day}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              {editMode && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenSave("saved")}
                >
                  Save Details
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
      <ConfirmationDialog
        toggle={save.saved}
        title="Save"
        content="Are you sure you want to save the changes made?"
        close={() => setSave({ saved: false })}
        fn={e => handleSubmit(e)}
      />
      <ConfirmationDialog
        toggle={discard}
        close={() => setDiscard(false)}
        fn={() =>
          Swal.fire({
            title: "Discarded",
            icon: "Success",
            showConfirmButton: false,
            timer: 3000,
            onCLose: () => {
              setRedirect(true);
            }
          })
        }
      />
      <PairDriverVehicle
        toggle={vehicleModal}
        closeVehicleModal={closeVehicleModal}
        setVehicleInfo={e => {
          setPairedVehicle(e);
          closeVehicleModal();
        }}
      />
    </div>
  );
};

export default EditDriverInfo;
