/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  Divider,
  Popover,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  Close,
  ExpandMore,
  Refresh,
  GpsFixed,
  ExpandLess
} from "@material-ui/icons";
// import MaterialAutoComplete from "@material-ui/lab/Autocomplete";
import { red } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { createPortal } from "react-dom";
import { DirectionsRenderer } from "@react-google-maps/api";
// import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import SearchBox from "./SearchBox";
import SearchBoxGeofence from "./SearchBoxGeofence";

const useStyles = makeStyles(theme => ({
  filterText: {
    fontSize: "14px"
  },
  searchText: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "350px"
  },
  directionsText: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "450px"
  },
  checked: {
    backgroundColor: "blue"
  },
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 25,
    margin: 4
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  location: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  locationText: {
    color: "grey",
    fontSize: "12px"
  }
}));

// const { google } = window;
const SearchOverlay = props => {
  const classes = useStyles();
  const {
    setCenter,
    dataGeo,
    valuePlaces,
    setValuePlaces,
    textValue,
    setTextValue,
    setDestination,
    setFitboundDestination,
    handleDrag,
    filters,
    directions,
    setDirections,
    mapRef,
    vehicle,
    setVehicle,
    vehiclesData = [],
    lastTextVal
  } = props;
  const map = mapRef.current;
  const controlDiv = document.createElement("div");
  const directionsRef = useRef();

  const [option, setOption] = useState([]);
  const [expanded, setExpanded] = useState(true);
  controlDiv.style.padding = "24px";
  controlDiv.style.left = "0";
  const UserPlacesOption = [];

  if (!_.isEmpty(dataGeo?.get_geofences?.geofences)) {
    for (const val of dataGeo.get_geofences.geofences) {
      if (val.name && val.location)
        UserPlacesOption.push({ title: val.name, location: val.location });
    }
  }

  const handleChange = event => {
    setValuePlaces(event.target.value);
    setDirections(null);
    lastTextVal.current.value = "";
  };

  useEffect(() => {
    if (vehiclesData && !vehicle) {
      setVehicle(vehiclesData[0]);
      setCenter({
        lat: vehiclesData[0]?.device_info?.device_status?.latitude,
        lng: vehiclesData[0]?.device_info?.device_status?.longitude
      });
    }
  }, [vehiclesData]);

  const renderFilter = () => {
    const date = new Date();
    let eta = "";

    const updatedVehicleInfo = vehiclesData.find(v => +v.id === +vehicle?.id);

    if (directions) {
      eta = new Date();
      eta.setSeconds(
        eta.getSeconds() + parseInt(directions.routes[0].legs[0].duration.value)
      );
    }

    return (
      <>
        {expanded ? (
          <Paper
            style={{
              minWidth: "500px",
              maxWidth: "500px",
              borderRadius: "10px",
              padding: "15px 10px 15px 10px"
            }}
          >
            <IconButton
              onClick={() => setExpanded(!expanded)}
              style={{ padding: "6px" }}
            >
              <ExpandLess />
            </IconButton>
            <Grid container spacing={0}>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography>Origin:</Typography>
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    className={classes.autoComplete}
                    style={{ height: 30, width: 200 }}
                    value={vehicle}
                    disableCloseOnSelect
                    options={vehiclesData || []}
                    getOptionSelected={(o, v) => {
                      return v?.plateno === o?.plateno;
                    }}
                    getOptionLabel={o => {
                      return o?.plateno || vehicle;
                    }}
                    renderInput={params => (
                      <TextField {...params} placeholder="Select vehicle" />
                    )}
                    disableClearable
                    onChange={(e, val) => {
                      setVehicle(val);
                      setCenter({
                        lat: val.device_info?.device_status?.latitude,
                        lng: val.device_info?.device_status?.longitude
                      });
                    }}
                  />
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                    onClick={async () => {
                      // await setCenter({
                      //   lat: vehicleLocation.device_status.latitude,
                      //   lng: vehicleLocation.device_status.longitude
                      // });
                      // setTimeout(() => {
                      //   if (filters.geofences) {
                      //     handleDrag();
                      //   }
                      // }, 800);
                      if (
                        vehicle?.device_info?.device_status?.latitude &&
                        vehicle?.device_info?.device_status?.longitude
                      ) {
                        await setCenter({
                          lat: vehicle?.device_info?.device_status.latitude,
                          lng: vehicle?.device_info?.device_status.longitude
                        });
                        setTimeout(() => {
                          if (filters.geofences) {
                            handleDrag();
                          }
                        }, 800);
                      }
                    }}
                  >
                    <GpsFixed color="primary" />
                  </IconButton>
                </Grid>
                <Grid item container>
                  <Grid item xs={6}>
                    <Typography>Current Location:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.location}
                  >
                    <Typography className={classes.locationText}>
                      {updatedVehicleInfo?.device_info?.device_status?.lastlocation || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Paper className={classes.root}>
                    {/* <Divider className={classes.divider} orientation="vertical" /> */}
                    {valuePlaces === "google" ? (
                      <SearchBox
                        setCenter={setCenter}
                        setDestination={setDestination}
                        setFitboundDestination={setFitboundDestination}
                        setTextValue={setTextValue}
                        textValue={textValue}
                        lastAddress={lastTextVal}
                      />
                    ) : (
                      <SearchBoxGeofence
                        setDestination={setDestination}
                        setFitboundDestination={setFitboundDestination}
                        setTextValue={setTextValue}
                        textValue={textValue}
                        option={option}
                        setOption={setOption}
                        setCenter={setCenter}
                        lastTextboxVal={lastTextVal}
                      />
                    )}
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {popupState => (
                        <Fragment>
                          <IconButton
                            id="expand-option"
                            type="submit"
                            className={classes.iconButton}
                            aria-label="search"
                            {...bindTrigger(popupState)}
                          >
                            <ExpandMore color="primary" />
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left"
                            }}
                          >
                            <Box p={2}>
                              <RadioGroup
                                aria-label="places"
                                name="places"
                                value={valuePlaces}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="google"
                                  control={
                                    <Radio
                                      color="primary"
                                      style={{
                                        height: 5,
                                        width: 5
                                      }}
                                    />
                                  }
                                  label="Google Places"
                                />
                                <FormControlLabel
                                  value="geofence"
                                  control={
                                    <Radio
                                      color="primary"
                                      style={{
                                        height: 5,
                                        width: 5
                                      }}
                                    />
                                  }
                                  label="User Places"
                                />
                              </RadioGroup>
                            </Box>
                          </Popover>
                        </Fragment>
                      )}
                    </PopupState>
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      className={classes.iconButton}
                      aria-label="directions"
                      onClick={() => {
                        setDestination(null);
                        setTextValue("");
                        lastTextVal.current.value = "";
                        setCenter({
                          lat: vehicle?.device_info?.device_status?.latitude,
                          lng: vehicle?.device_info?.device_status?.longitude
                        });
                        setDirections(null);
                      }}
                    >
                      <Close style={{ color: red[500] }} />
                    </IconButton>
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <IconButton
                      // color="primary"
                      className={classes.iconButton}
                      aria-label="directions"
                      onClick={() => {
                        setCenter({
                          lat: vehicle?.device_info.device_status.latitude,
                          lng: vehicle?.device_info.device_status.longitude
                        });
                        setVehicle(updatedVehicleInfo);
                      }}
                      style={{ color: "grey" }}
                    >
                      <Refresh />
                    </IconButton>
                  </Paper>
                </Grid>
                {directions && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.searchText}>
                        <Typography className={classes.filterText}>
                          As of{" "}
                          {date.toLocaleString("default", {
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric"
                          })}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.directionsText}>
                        <Grid item xs={6}>
                          <Typography className={classes.filterText}>
                            Via:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {directions.routes[0].summary}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.directionsText}>
                        <Grid item xs={6}>
                          <Typography className={classes.filterText}>
                            Travel Duration:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {directions.routes[0].legs[0].duration.text}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.directionsText}>
                        <Grid item xs={6}>
                          <Typography className={classes.filterText}>
                            ETA:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {moment(eta).format("LLL")}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className={classes.directionsText}>
                        <Grid item xs={6}>
                          <Typography className={classes.filterText}>
                            Distance:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {directions.routes[0].legs[0].distance.text}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper
            style={{
              borderRadius: "10px",
              padding: 2,
              textAlign: "center"
            }}
          >
            <IconButton onClick={() => setExpanded(!expanded)} >
              <ExpandMore />
            </IconButton>
          </Paper>
        )}
      </>
    );
  };

  useEffect(() => {
    const { google } = window;
    const controls = map.controls[google.maps.ControlPosition.TOP_LEFT];
    const index = controls.length;
    controls.push(controlDiv);

    return () => {
      controls.removeAt(index);
    };
  });

  return createPortal(
    <div>
      {renderFilter()}
      {directions && (
        <DirectionsRenderer
          options={{
            suppressMarkers: true,
            preserveViewport: true,
            draggable: true,
            polylineOptions: {
              strokeColor: "#7F00FF"
            }
          }}
          directions={directions}
          ref={directionsRef}
          onDirectionsChanged={() => {
            if (directionsRef.current) {
              setDirections(
                directionsRef.current.state.directionsRenderer.directions
              );
            }
          }}
        />
      )}
    </div>,
    controlDiv
  );
};

export default SearchOverlay;
