/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  makeStyles,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GET_GEOFENCES } from "../../../../graphql/Queries";

const useStyles = makeStyles(() => ({
  field: {
    margin: "12px 0",
    width: "90%"
  }
}));

export default function SearchGeofence(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [textboxValue, setTextBoxValue] = React.useState("");
  const { errors, setErrors, setGeofenceID, storeGeofence, type } = props;
  const loadingUI = open && options.length === 0;
  let typingTimer = null;
  const UserPlacesOption = [];
  useEffect(() => {
    let active = true; // eslint-disable-line no-unused-vars

    if (!loadingUI) {
      return undefined;
    }
    return () => {
      active = false;
    };
  }, [loadingUI]);
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    };
  }, []);

  const [getGeofence, { data }] = useLazyQuery(GET_GEOFENCES);
  if (data) {
    for (const val of data.get_geofences.geofences) {
      if (val.name && val.id)
        UserPlacesOption.push({ name: val.name, id: val.id });
    }
    UserPlacesOption.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  }

  return (
    <FormControl className={classes.field}>
      <InputLabel shrink required>
        Geofence
      </InputLabel>
      <Autocomplete
        id="asynchronous-demo"
        open={open}
        inputValue={textboxValue && textboxValue}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, values) => {
          if (values) {
            setGeofenceID(values.id);

            setTextBoxValue(values.name);
            setErrors({ ...errors, geofence_id: "" });
          } else {
            setGeofenceID(props.data1.get_user.geofence_id);
            setTextBoxValue("");
          }
        }}
        options={UserPlacesOption}
        getOptionLabel={option => option.name}
        loading={loadingUI}
        renderInput={params => (
          <TextField
            {...params}
            style={{ marginTop: 16 }}
            onChange={e => {
              const val = e.target.value;
              setTextBoxValue(val);
              clearTimeout(typingTimer);
              typingTimer = setTimeout(async () => {
                if (val) {
                  await getGeofence({
                    variables: {
                      keyword: val,
                      keyword_fields: [
                        "name",
                        "geofence_code",
                        "category",
                        "region",
                        "province",
                        "municipality"
                        // "city"
                      ],
                      first: 25
                    },
                    fetchPolicy: "network-only"
                  });
                }
              }, 800);
            }}
            placeholder={
              type === "add"
                ? "Select Geofence"
                : // : !_.isEmpty(props.data1.get_user.group_names) && storeGeofence
                // ? storeGeofence.name
                !_.isEmpty(props.data1.get_user.group_names) &&
                  props.data1.get_user.geofence_name &&
                  storeGeofence
                ? props.data1.get_user.geofence_name
                : "Select Geofence" || "Select Geofence"
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingUI ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </FormControl>
  );
}
