/* eslint-disable import/prefer-default-export */
import getSuffix from "./getSuffix";

export const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export const times = (() => {
  const tempTimes = [];
  for (let i = 0; i <= 23; i += 1) {
    const time = i === 12 || i === 0 ? 12 : i;
    let label = `${time} AM`;
    let value = `${i}:00:00`;

    if (i >= 12) {
      label = `${i !== 12 ? i - 12 : i} PM`;
    }

    if (i < 10) {
      value = `0${value}`;
    }

    tempTimes.push({
      value,
      label
    });
  }

  return tempTimes;
})();

export const dates = (() => {
  const tempDates = [];
  for (let i = 1; i <= 31; i += 1) {
    tempDates.push(getSuffix(i.toString()));
  }
  return tempDates;
})();
