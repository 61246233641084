import React, { memo } from "react";
import {
  Typography,
  makeStyles,
  Container,
  Box,
  Grid,
  Icon
} from "@material-ui/core";
/*
    Design: 
    1. create a reusable container for representing the dashboard container. 
*/
// list of categories for conditioning.
const categoriesArr = ["OTTA", "Late", "On Time", "OTD", "Land", "Customer"];
// redirect links on bookings.
const TRIPS_LINK = "/trips";
const PREBOOKING_LINK = "/prebooking";
// to trips directable.
const toTrips = [
  "Dispatch",
  "At Risk",
  "On Track",
  "Late",
  "On Time",
  "Completed"
];
// to prebooking directable.
const toPrebooking = ["Awaiting"];

const StatusContainer = props => {
  const { data, label, history, category } = props;
  const classes = useStyles();

  // check if the dashboard has the capability to redirect to bookings or trips.
  const redirectable = (label, tripNumbers, category) => {
    let isRedirectable = false;
    if (
      (toTrips.includes(label) || toPrebooking.includes(label)) &&
      tripNumbers.length > 0
    ) {
      if (
        category === categoriesArr[0] &&
        label !== categoriesArr[1] &&
        label !== categoriesArr[2]
      ) {
        isRedirectable = true;
      } else if (category === categoriesArr[3]) {
        isRedirectable = true;
      } else if (category === categoriesArr[4] && label === categoriesArr[5]) {
        isRedirectable = true;
      } else {
        isRedirectable = false;
      }
    }
    return isRedirectable;
  };
  // redirect status containers.
  function redirectTo(label, tripNumbers, category) {
    // check if it is included on the trips or prebookings redirect.
    // validate if link label is redirectable to trips or prebooking.
    if (redirectable(label, tripNumbers, category)) {
      history.push({
        pathname: toTrips.includes(label) ? TRIPS_LINK : PREBOOKING_LINK,
        state: {
          params: {
            trip_numbers: tripNumbers
          }
        }
      });
    }
  }

  return (
    <>
      <Container className={classes.container}>
        <Grid
          container
          spacing={0}
          style={{ minHeight: "31vh", padding: "0.5rem" }}
        >
          {data.map((info, index) => (
            <Grid
              item
              xs={data.length % 2 === 1 && data.length - 1 === index ? 12 : 6}
              className={classes.info_details}
              key={index}
              onClick={() =>
                redirectTo(info.label, info.tripNumbers, info.category)
              }
              style={{
                cursor: redirectable(
                  info.label,
                  info.tripNumbers,
                  info.category
                )
                  ? "pointer"
                  : ""
              }}
              id=""
            >
              <Typography className={classes.info_count}>
                {info.count}
              </Typography>
              <Box
                display="flex"
                gridGap={10}
                justifyContent="center"
                alignItems="center"
              >
                <img src={info.icon} style={{ height: 25, width: 25 }} />
                <Typography className={classes.info_label}>
                  {info.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <div className={classes.title_container}>
          <Typography className={classes.title}>{label}</Typography>
        </div>
      </Container>
    </>
  );
};

export default StatusContainer;

// styling for this module.
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    minHeight: 250,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    justifyContent: "space-between",
    textAlign: "center"
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: 1,
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    padding: "0.8rem",
    color: "#FFFFFF",
    fontSize: 14,
    letterSpacing: "0.1em",
    fontWeight: 700
  },
  info_details: {
    padding: "0.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.3s ease-in-out",
    "&:nth-child(odd)": {
      borderRight: "2px solid #F9F9F9"
    },
    "&:last-child": {
      borderRight: "none !important"
    },
    "&:nth-child(n + 3)": {
      borderTop: "2px solid #F9F9F9"
    }
  },
  title_container: {
    width: "100%",
    textAlign: "center",
    alignSelf: "flex-end"
  },
  info_count: {
    color: theme.palette.primary.dark,
    fontSize: 30,
    fontWeight: 700,
    letterSpacing: "0.1em"
  },
  info_icon: {
    color: "#bebebe"
  },
  info_label: {
    color: "#bebebe",
    fontSize: 11,
    fontWeight: 700,
    letterSpacing: "0.1em"
  }
}));
