/* global google */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Typography,
  Switch,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import { createPortal } from "react-dom";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
  StreetViewPanorama,
  TrafficLayer,
  DirectionsRenderer
} from "@react-google-maps/api";
import moment from "moment";
import Cookie from "js-cookie";
import CustomMarker from "../../../utils/Map/Marker/CustomMarker";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../assets/SvgIcons/SvgIcons";
import { relative } from "path";
import { vehicleSvgIcons } from "../../Utils";
import { wktToCoordinates } from "../../../utils/functions/coordinatesParserV2";

const libraries = ["geometry", "drawing", "places"];
const useStyles = makeStyles(() => ({
  rootFilter: {
    cursor: "pointer",
    display: "flex",
    padding: "4px 12px",
    minWidth: "120px",
    borderRadius: "10px"
  },
  filterText: {
    fontSize: "14px"
  },
  filterList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  checked: {
    backgroundColor: "blue"
  },
  labelStyle: {
    background: "#CCCCCC",
    padding: "4px 8px",
    borderRadius: "8px",
    letterSpacing: "1.1px",
    fontWeight: "600",
    fontSize: "12px"
  }
}));

const CustomOverlay = props => {
  const classes = useStyles();
  const {
    geofences,
    parsedTripData,
    vehicleInfo,
    handleStreetView,
    firstLastLocation,
    renderMarker,
    tempIds,
    filters,
    setFilters,
    directions,
    mapRef
  } = props;

  const map = mapRef.current;
  const { latitude, longitude, heading } = vehicleInfo.device_status;
  const controlDiv = document.createElement("div");
  const [expanded, setExpanded] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  // const [directions, setDirections] = useState(null);
  const filterList = [
    { name: "All Geofences", key: "geofences" },
    { name: "Stops", key: "stops" },
    { name: "Traffic Overlay", key: "traffic" },
    { name: "Road Network", key: "road" }
  ];

  controlDiv.style.padding = "24px";

  const handleFilter = () => {
    setExpanded(!expanded);
  };

  const handleFilterToggle = e => {
    const { checked, name } = e.target;
    setFilters({ ...filters, [name]: checked });
  };

  const renderFilter = () => {
    return (
      <>
        <Paper className={classes.rootFilter} onClick={() => handleFilter()}>
          <FilterListIcon />
          <div style={{ flexGrow: 1 }} />
          <Typography>Map Filters</Typography>
        </Paper>
        {expanded && (
          <Paper
            style={{
              minWidth: "120px",
              borderRadius: "10px",
              padding: "8px 0px 8px 8px"
            }}
          >
            {filterList.map(filter => (
              <div key={filter.key} className={classes.filterList}>
                <Typography className={classes.filterText}>
                  {filter.name}
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Switch
                  checked={filters[filter.key]}
                  color="primary"
                  onChange={handleFilterToggle}
                  name={filter.key}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            ))}
          </Paper>
        )}
      </>
    );
  };

  // const coordinatesParser = wkbGeometry => {
  //   const temp = [];
  //   const { coordinates } = wkbGeometry;
  //   coordinates[0].map(coords =>
  //     temp.push(new google.maps.LatLng(coords[1], coords[0]))
  //   );
  //   return temp;
  // };

  const [infoBox, setInfoBox] = useState(false);
  const [markerTooltip, setMarkerTooltip] = useState("");

  const renderByStatus = () => {
    let component = "";
    const { trip_status } = parsedTripData;

    component = geofences
      .filter(geofence => {
        if (filters.geofences) return true;
        if (filters.stops) {
          const index = tempIds.indexOf(geofence.id);
          if (index > -1) return true;
          return false;
        }
        return false;
      })
      .map((geofence, index) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <Marker
          // eslint-disable-next-line react/no-array-index-key
          key={`${geofence.geofence_id}--${index}`}
          position={{
            lat: geofence.location.lat,
            lng: geofence.location.lon
          }}
          icon={{
            url: renderMarker(geofence.name).marker,
            scaledSize: new google.maps.Size(32, 32)
          }}
          onMouseOver={() => setMarkerTooltip(geofence.id)}
          onMouseOut={() => setMarkerTooltip("")}
        >
          {markerTooltip === geofence.id && (
            <InfoWindow
              position={{
                lat: geofence.location.lat,
                lng: geofence.location.lon
              }}
              onCloseClick={() => setMarkerTooltip("")}
            >
              <div>
                <div style={{ display: "flex" }}>
                  <Typography>{`${geofence.geofence_code} - ${geofence.name}`}</Typography>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ));

    const isLBSVehicle = vehicleInfo?.device_status?.AlertReferences?.some(
      alert => alert.alert_code === "LBS DATA"
    );

    const Icon =
      SvgIcons[
        isLBSVehicle
          ? "LBS_vehicle"
          : vehicleInfo?.vehicle_info?.icon?.split(".")[0] &&
            vehicleInfo.vehicle_info
          ? vehicleInfo.vehicle_info.icon.split(".")[0]
          : "default_icon"
      ];

    const powersource = vehicleInfo?.vehicle_info?.powersource;
    const ignition_status = vehicleInfo?.vehicle_info?.ignition_status;

    const infoBubble = () => {
      const { plateno } = vehicleInfo.vehicle_info;
      const { gpsSpeed, temperature, reportstamp } = vehicleInfo.device_status;
      return (
        <InfoWindow
          key={plateno}
          position={{
            lat: latitude,
            lng: longitude
          }}
          onCloseClick={() => setInfoBox(false)}
        >
          <div style={{ width: 300 }}>
            <Typography style={{ marginBottom: 4, fontSize: 20 }}>
              {plateno}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography style={{ width: "40%", fontSize: 14 }}>
                Speed:
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {gpsSpeed ? `${gpsSpeed} kph` : "0 kph"}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography style={{ width: "40%", fontSize: 14 }}>
                Temperature:
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {temperature ? (
                  <p style={{ margin: 0 }}>{temperature} &#8451;</p>
                ) : (
                  "-"
                )}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography style={{ width: "40%", fontSize: 14 }}>
                Last Report:
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {` ${moment(reportstamp).format("MMM DD, YYYY hh:mm A")}`}
              </Typography>
            </div>
            <IconButton
              onClick={() =>
                handleStreetView({ lat: latitude, lng: longitude })
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/pegman.png`}
                alt=""
                width={25}
                height={50}
              />
            </IconButton>
          </div>
        </InfoWindow>
      );
    };
    if (
      latitude &&
      longitude &&
      trip_status !== "Completed" &&
      trip_status !== "Closed"
    ) {
      component.push(
        trip_status === "In Transit" || trip_status === "Incoming" ? (
          <>
          <Marker
            key={`${latitude}-${longitude}`}
            position={{
              lat: latitude,
              lng: longitude
            }}
            options={{
              icon: {
                // path:
                //   vehicleSvgIcons[vehicleInfo.vehicle_info.icon.split(".")[0]]
                //     .path,
                // // scale: 2,
                // fillColor:
                //   vehicleSvgIcons[vehicleInfo.vehicle_info.icon.split(".")[0]]
                //     .color,
                // fillOpacity: 0.8,
                // // strokeWeight: 2,
                rotation: isLBSVehicle? 0 :heading,
                // anchor: window.google.maps.Point(100, 100)
                path: Icon.path,
                scale: 1.2,
                fillColor: isLBSVehicle? Icon.fill: fillColor[powersource], // Icon.fill,
                strokeColor: isLBSVehicle? Icon.stroke: strokeColor[ignition_status], // Icon.stroke,
                strokeWeight: isLBSVehicle? 1.0: 1.5,
                fillOpacity: 1,
                labelOrigin: new window.google.maps.Point(10, 40)
              },
            }}
            // icon={{
            //   url: `${process.env.PUBLIC_URL}/assets/Vehicle Icons/${vehicleInfo.vehicle_info.icon}`,
            //   rotation: heading,
            //   scaledSize: new google.maps.Size(32, 32)
            // }}
            onClick={() => setInfoBox(true)}
          >
            <CustomMarker
              getVehiclePosition={() => {
                return {
                  lat: latitude,
                  lng: longitude
                };
              }}
            />
          </Marker>
          {infoBox && infoBubble()}
          {vehicleInfo?.vehicle_info?.plateno && <Marker
            position={{
              lat: latitude,
              lng: longitude
            }}
            options={{
              icon: {
                // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
                path: Icon.path,
                // .pathWithCircle,
                fillColor: fillColor[powersource], // Icon.fill,
                fillOpacity: 0,
                strokeColor: strokeColor[ignition_status], // Icon.stroke,
                strokeWeight: 0,
                scale: 1.2,
                size: new window.google.maps.Size(22, 17),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(5, 9),
                // labelOrigin: new google.maps.Point(8, 48)
                labelOrigin: new window.google.maps.Point(8, 45)
              },
              label: {
                text: vehicleInfo?.vehicle_info?.plateno,
                className: classes.labelStyle
              }
            }}
            />}
          
          </>
        ) : (
          <>
            <Marker
              key={`${latitude}-${longitude}`}
              position={{
                lat: latitude,
                lng: longitude
              }}
              onClick={() => setInfoBox(true)}
              options={{
                icon: {
                  // path:
                  //   vehicleSvgIcons[vehicleInfo.vehicle_info.icon.split(".")[0]]
                  //     .path,
                  // // scale: 2,
                  // fillColor:
                  //   vehicleSvgIcons[vehicleInfo.vehicle_info.icon.split(".")[0]]
                  //     .color,
                  // fillOpacity: 0.8,
                  // // strokeWeight: 2,
                  // rotation: heading
                  // anchor: window.google.maps.Point(100, 100)
                  rotation: isLBSVehicle? 0 :heading,
                  path: Icon.path,
                  scale: 1.2,
                  fillColor: isLBSVehicle? Icon.fill: fillColor[powersource], // Icon.fill,
                  strokeColor: isLBSVehicle? Icon.stroke: strokeColor[ignition_status], // Icon.stroke,
                  strokeWeight: isLBSVehicle? 1.0: 1.5,
                  fillOpacity: 1,
                  size: new window.google.maps.Size(22, 17),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(9, 13)
                }
              }}
            >
              <CustomMarker
                getVehiclePosition={() => {
                  return {
                    lat: latitude,
                    lng: longitude
                  };
                }}
                vehicleDirection={vehicleInfo.vehicle_info.heading}
                vehiclePlateNo={vehicleInfo.vehicle_info.plateno}
                zoom={map?.zoom}
                // alertLevel={
                //   vehicleInfo.device_status.AlertReferences?.reduce(
                //     (accum, alert) => {
                //       if (alert.level > accum.level) return alert;
                //       return accum;
                //     },
                //     { level: 0 }
                //   ) || null
                // }
                isMoving={vehicleInfo.device_status.gpsSpeed > 0}
                moduleType="TRIPS_MAP_VIEW"
              />
              {infoBox && infoBubble()}
            </Marker>
            {vehicleInfo?.vehicle_info?.plateno && <Marker
              position={{
                lat: latitude,
                lng: longitude
              }}
              options={{
                icon: {
                  // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
                  path: Icon.path,
                  // .pathWithCircle,
                  fillColor: fillColor[powersource], // Icon.fill,
                  fillOpacity: 0,
                  strokeColor: strokeColor[ignition_status], // Icon.stroke,
                  strokeWeight: 0,
                  scale: 1.2,
                  size: new window.google.maps.Size(22, 17),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(5, 9),
                  // labelOrigin: new google.maps.Point(8, 48)
                  labelOrigin: new window.google.maps.Point(8, 45)
                },
                label: {
                  text: vehicleInfo.vehicle_info.plateno,
                  className: classes.labelStyle
                }
              }}
            />}
            
          </>
        )
      );

      const { lastLocation, firstLocation } = firstLastLocation;
      if (trip_status === "Completed") {
        if (lastLocation) {
          const { lat: lastLocLat, lon: lastLocLng, datestamp } = lastLocation;
          component.push(
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <Marker
              key={`${lastLocLat}-${lastLocLng}`}
              position={{
                lat: lastLocLat,
                lng: lastLocLng
              }}
              icon={{
                url: `${process.env.PUBLIC_URL}/assets/Markers/End.png`,
                scaledSize: new google.maps.Size(32, 32)
              }}
              onMouseOver={() => setTooltip(true)}
              onMouseOut={() => setTooltip(false)}
            >
              {tooltip && (
                <InfoWindow
                  position={{
                    lat: lastLocLat,
                    lng: lastLocLng
                  }}
                >
                  <div style={{ width: 200 }}>
                    <div style={{ display: "flex" }}>
                      <Typography style={{ width: "40%", fontSize: 14 }}>
                        End time:
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>
                        {` ${moment(datestamp).format("MMM DD, YYYY hh:mm A")}`}
                      </Typography>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        }
      }
      if (
        trip_status === "Incoming" ||
        trip_status === "Arrived at Pickup" ||
        trip_status === "In Transit" ||
        trip_status === "Arrived at Drop Off"
      ) {
        if (firstLocation) {
          const {
            lat: firstLocLat,
            lon: firstLocLng,
            datestamp
          } = firstLocation;
          component.push(
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <Marker
              key={`${firstLocLat}-${firstLocLng}`}
              position={{
                lat: firstLocLat,
                lng: firstLocLng
              }}
              icon={{
                url: `${process.env.PUBLIC_URL}/assets/Markers/Start.png`,
                scaledSize: new google.maps.Size(32, 32)
              }}
              onMouseOver={() => setTooltip(true)}
              onMouseOut={() => setTooltip(false)}
            >
              {tooltip && (
                <InfoWindow
                  onCloseClick={() => setTooltip(false)}
                  position={{
                    lat: firstLocLat,
                    lng: firstLocLng
                  }}
                >
                  <div style={{ width: 200 }}>
                    <div style={{ display: "flex" }}>
                      <Typography style={{ width: "40%", fontSize: 14 }}>
                        Start time:
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>
                        {` ${moment(datestamp).format("ddd")}, ${moment(
                          datestamp
                        ).format("DD MMM YYYY")}`}
                      </Typography>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        }
      }
    }
    return component;
  };

  // const renderGeofences = () => {
  //   return geofences
  //     .filter(geofence => {
  //       if (filters.geofences) return true;
  //       if (filters.stops) {
  //         const index = tempIds.indexOf(geofence.id);
  //         if (index > -1) return true;
  //         return false;
  //       }
  //       return false;
  //     })
  //     .map(geofence => (
  //       <Polygon
  //         paths={coordinatesParser(geofence.wkb_geometry)}
  //         options={{
  //           fillColor: "#0080FF",
  //           fillOpacity: 0.5,
  //           strokeWeight: 2
  //         }}
  //       />
  //     ));
  // };

  useEffect(() => {
    if (map) {
      const controls = map.controls[google.maps.ControlPosition.TOP_RIGHT];
      const index = controls.length;
      controls.push(controlDiv);
      return () => {
        controls.removeAt(index);
      };
    }
  });

  return createPortal(
    <div>
      {renderFilter()}
      {/* {(filters.stops || filters.geofences) && renderGeofences()} */}
      {filters.traffic && <TrafficLayer autoUpdate />}
      {(parsedTripData.trip_status === "In Transit" ||
        parsedTripData.trip_status === "Incoming") &&
        directions && (
          <DirectionsRenderer
            options={{ suppressMarkers: true, preserveViewport: true,
              polylineOptions: {
                strokeColor: "#7F00FF",
                strokeWeight: 5
              } }}
            directions={directions}
          />
        )}
      {renderByStatus()}
    </div>,
    controlDiv
  );
};

const MapComponent = props => {
  const {
    parsedTripData,
    vehicleInfo,
    center,
    geofenceList,
    renderMarker,
    firstLastLocation,
    points,
    tempIds,
    handleBbox,
    handleCenterOnDrag,
    zoom,
    handleZoom,
    filters,
    setFilters,
    directions
  } = props;
  const mapRef = useRef(null);
  const { latitude, longitude } = center;
  const [view, setView] = useState({
    display: false,
    center: { lat: 14.5995, lng: 120.9842 }
  });
  const user = JSON.parse(Cookie.get("user"));
  // const [directions, setDirections] = useState(null);

  const handleStreetView = c => {
    setView({ display: true, center: { ...c } });
  };

  const { isLoaded } = useJsApiLoader({
    channel: `tmsuite-portal-${user.client.name}`,
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    version: "3.47",
    libraries
  });

  // const getNextStop = () => {
  //   const { pickups, dropoffs } = parsedTripData;
  //   let nextStop = new google.maps.LatLng(
  //     14.637041357136862,
  //     121.04160785047702
  //   );
  //   let geoId = null; // webcast lat long
  //   if (pickups.some(pickup => pickup.status_code_id === 2)) {
  //     geoId = pickups.filter(pickup => pickup.status_code_id === 2)[0]
  //       .geofence_id;
  //   } else if (dropoffs.some(dropoff => dropoff.status_code_id === 2)) {
  //     geoId = dropoffs.filter(dropoff => dropoff.status_code_id === 2)[0]
  //       .geofence_id;
  //   }

  //   if (geoId) {
  //     let temp = "";
  //     temp = geofenceList.filter(geofence => geofence.id === geoId);
  //     if (temp.length > 0) {
  //       nextStop = new google.maps.LatLng(
  //         temp[0].location.lat,
  //         temp[0].location.lon
  //       );
  //     }
  //   }

  //   return nextStop;
  // };

  // useEffect(() => {
  //   if (isLoaded) {
  //     if (
  //       vehicleInfo?.device_status?.latitude ||
  //       vehicleInfo?.device_status?.longitude
  //     ) {
  //       if (
  //         parsedTripData.trip_status === "Incoming" ||
  //         parsedTripData.trip_status === "In Transit"
  //       ) {
  //         if (!directions) {
  //           const DirectionsService = new google.maps.DirectionsService();
  //           DirectionsService.route(
  //             {
  //               origin: new google.maps.LatLng(
  //                 vehicleInfo.device_status.latitude,
  //                 vehicleInfo.device_status.longitude
  //               ),
  //               destination: getNextStop(),
  //               travelMode: google.maps.TravelMode.DRIVING
  //             },
  //             (result, status) => {
  //               if (status === google.maps.DirectionsStatus.OK) {
  //                 setDirections(result);
  //               }
  //             }
  //           );
  //         }
  //       }
  //     }
  //   }
  // }, [vehicleInfo, isLoaded]);

  // useEffect(() => {
  //   if (parsedTripData) {
  //     const { trip_status } = parsedTripData;
  //     if (trip_status !== "Incoming" || trip_status !== "In Transit") {
  //       setDirections(null);
  //     }
  //   }
  // }, [parsedTripData]);

  const handleBounds = () => {
    const paths = [];
    const aNorth = mapRef.current
      .getBounds()
      .getNorthEast()
      .lat();
    const aEast = mapRef.current
      .getBounds()
      .getNorthEast()
      .lng();
    const aSouth = mapRef.current
      .getBounds()
      .getSouthWest()
      .lat();
    const aWest = mapRef.current
      .getBounds()
      .getSouthWest()
      .lng();
    paths.push(
      { lat: aNorth, lon: aEast },
      { lat: aNorth, lon: aWest },
      { lat: aSouth, lon: aWest },
      { lat: aSouth, lon: aEast },
      { lat: aNorth, lon: aEast }
    );

    return paths;
  };

  const handleDrag = () => {
    const paths = handleBounds();

    const newCenter = mapRef.current.getCenter();
    handleBbox(paths);
    handleCenterOnDrag(newCenter.lat(), newCenter.lng());
  };

  const handleZoomChange = () => {
    if (mapRef.current) {
      const paths = handleBounds();
      const zoomlevel = mapRef.current.getZoom();
      handleBbox(paths);
      handleZoom(zoomlevel);
    }
  };

  const renderGeofences = () => {
    return geofenceList
      .filter(geofence => {
        if (filters.geofences) return true;
        if (filters.stops) {
          const index = tempIds.indexOf(geofence.id);
          if (index > -1) return true;
          return false;
        }
        return false;
      })
      .map((geofence, index) => (
        <Polygon
          key={`${geofence.geofence_id}--${index}`}
          paths={wktToCoordinates(geofence.geom)}
          options={{
            fillColor: "#0080FF",
            fillOpacity: 0.5,
            strokeWeight: 2
          }}
        />
      ));
  };

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={{ height: `100%`, width: `100%` }}
      center={{
        lat: latitude || 14.5995, // manila latitude
        lng: longitude || 120.9842 // manila longitude
      }}
      onLoad={maps => {
        maps.setZoom(zoom);
        mapRef.current = maps;
      }}
      onDragEnd={() => handleDrag()}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      }}
      onZoomChanged={() => handleZoomChange()}
    >
      {view.display && (
        <StreetViewPanorama position={{ ...view.center }} visible />
      )}
      {vehicleInfo && (
        <CustomOverlay
          filters={filters}
          setFilters={setFilters}
          geofences={geofenceList || []}
          tempIds={tempIds}
          parsedTripData={parsedTripData}
          vehicleInfo={vehicleInfo}
          renderMarker={renderMarker}
          handleStreetView={handleStreetView}
          firstLastLocation={firstLastLocation}
          center={center}
          directions={directions}
          mapRef={mapRef}
        />
      )}
      {(filters.stops || filters.geofences) && renderGeofences()}
      {parsedTripData.trip_status === "Completed" && (
        <Polyline
          path={points}
          geodesic
          options={{
            strokeColor: "#7F00FF",
            strokeOpacity: 0.75,
            strokeWeight: 4
          }}
        />
      )}
    </GoogleMap>
  );

  return isLoaded ? renderMap() : <>Loading...</>;
};

export default MapComponent;
