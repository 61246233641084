import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import StatusContainer from "../../../utils/UI/Container/StatusContainer";
import Bookings from "../Charts/Bookings";
import StatusChart from "../Charts/StatusChart";

const TruckAvailability = props => {
  const { chartData, history } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <StatusContainer
          data={chartData.ottaInc}
          label="Incoming"
          history={history}
          category="OTTA"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box className={classes.chart_container}>
          {chartData.chartValue[0].data && (
            <Grid item xs={12}>
              <StatusChart
                dataValue={chartData.chartValue[1]}
                chartTitle="On Time Truck Availability - Incoming"
              />
            </Grid>
          )}
        </Box>
      </Grid>
      <Box width="100%" />
      <Grid item xs={12} sm={6} md={3}>
        <StatusContainer
          data={chartData.ottaArr}
          label="Arrived"
          history={history}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={9}>
        <Box className={classes.chart_container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={chartData.chartValue[0].data ? 6 : 12}>
              <Bookings
                tripNumbers={chartData.tripNumbers}
                graphValue={chartData.bookingsChartValue[0]}
                history={history}
                chartTitle="On Time Truck Availability - Arrived"
              />
            </Grid>
            {chartData.chartValue[0].data && (
              <Grid item xs={12} sm={12} md={6}>
                <StatusChart
                  dataValue={chartData.chartValue[0]}
                  chartTitle="On Time Truck Availability - Arrived"
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default TruckAvailability;

const useStyles = makeStyles(theme => ({
  chart_container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    minHeight: 250,
    display: "flex",
    padding: "0.5rem 1rem",
    justifyContent: "center",
    alignItems: "center"
  }
}));
