/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    gap: "1rem",
    minHeight: "75vh"
  },
  box_noData: {
    minHeight: "75vh",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)"
  },
  no_data_link: {
    textDecoration: "none",
    color: theme.palette.secondary.main
  },
  no_data_btn: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "inherit !important",
    marginTop: 15,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  tab: {
    border: "none",
    borderRadius: 0,
    maxWidth: "100%",
    display: "block",
    minHeight: 12,
    padding: "3px 0.5rem",
    "& .MuiTab-wrapper": {
      border: "none",
      font: '400 13px "Nunito", sans-serif',
      textTransform: "initial !important",
      alignItems: "start",
      textAlign: "left"
    }
  },
  graph_container: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
    minHeight: "100%",
    width: "100%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)"
  },
  fleet_container: {
    padding: "1rem",
    minHeight: "75vh"
  },
  tab_box: {
    padding: "0.8rem 0",
    borderRadius: 10,
    minWidth: 200,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "100%"
  },
  tab_list: {},
  indicator: {
    backgroundColor: "#FDEFD9"
  },
  tabRoot: {
    color: "#3F3F3F",
    opacity: "1",
    "&:hover": {
      color: "#ED9421"
    }
  },
  selectedTab: {
    backgroundColor: "#FDEFD9 !important",
    color: "#3F3F3F !important",
  },
  header_title: {
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "25px",
    color: "#3F3F3F"
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 0.5rem",
    alignItems: "center",
    borderBottom: "1px solid #D1D1D1"
  },
  navbar_box: {
    display: "flex",
    gap: "0.25rem",
    alignItems: "center"
  },
  header_date: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    lineHeight: "18px",
    color: "#A2A2A2"
  },
  header_icon: {
    color: "#808080",
    padding: "0.5rem"
  },
  header_icon_not_fullscreen: {
    color: "#808080",
    padding: "0.5rem"
  },
  graph_container_max: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.secondary.main,
    overflow: "hidden",
    zIndex: 999
  },
  header_tab_label: {
    fontSize: 12, 
    textTransform: "Uppercase",
    fontWeight: 600,
    letterSpacing: 1,
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: "0.8rem",
  },
}));
