import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Divider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#EDD607 !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16,
    height: "85%"
  },
  input: {
    padding: 0
  }
});

const OTD = () => {
  const classes = useStyles();
  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title}>ON-TIME DELIVERY</Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography>Drop Off</Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                limitTags={2}
                getLimitTagsText={more => `+${more}`}
                disableCloseOnSelect
                options={[]}
                getOptionLabel={option => option.label.toString()}
                renderInput={params => (
                  <TextField {...params} placeholder="All" />
                )}
                disableClearable
                // value={filterValues[tabValue].dropoffs}
                // onChange={(e, nv) => {
                //   const temp = [...filterValues];
                //   temp[tabValue] = { ...temp[tabValue], dropoffs: nv };
                //   setFilterValues(temp);
                // }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        <Grid item xs={4} style={{ borderRight: "1px solid #D6D6D6" }}>
          <div style={{ paddingTop: 25 }} align="center">
            IN TRANSIT
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography align="center" variant="h4">
                  12
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Track</Typography>
                <Typography align="center" variant="h4">
                  7
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Late</Typography>
                <Typography align="center" variant="h4">
                  4
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Time</Typography>
                <Typography align="center" variant="h4">
                  16
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Completed</Typography>
                <Typography align="center" variant="h4">
                  11
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(OTD);
