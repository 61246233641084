import React, { useState, useRef, useEffect, Fragment, memo } from "react";
import { Tabs, Tab, Box, Typography, Button } from "@material-ui/core";
import NavFleet from "./NavFleet";
import { useStyles } from "./FleetStyle";
import FleetLink from "./Tableau/FleetLink";
import { GET_DASHBOARD_LINKS } from "../../../graphql/Queries";
import { useQuery } from "@apollo/client";
import Loading from "../../../utils/Loading";
import Cookie from "js-cookie";
import _ from "lodash";
import FleetNoData from "./FleetNoData";
import useUserContext from "../../../context/User/useUserContext";

const Fleet = props => {
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [links, setLinks] = useState([]);
  // graphql for fetching dashboard links.
  const { data: dashboardLinksData, loading: loadingDashboard } = useQuery(
    GET_DASHBOARD_LINKS,
    {
      variables: {
        group_ids: user.group_ids
      },
      onCompleted(data) {
        const { links: dataLinks } = data.get_dashboard_links;
        if (dataLinks) setLinks(dataLinks);
      },
      fetchPolicy: "network-only"
    }
  );
  //list of navigation tabs on sidebar.
  const tabList = links.map((link, index) => (
    <Tab
      label={link.name}
      value={index}
      key={link.id}
      className={classes.tab}
      classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
    />
  ));
  // change tab value
  const changeTabHandler = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Fragment>
      {loadingDashboard ? (
        <Loading />
      ) : (
        <div className={classes.fleet_container}>
          {links.length > 0 ? (
            <Box className={classes.box} lg={{ height: "100%" }}>
              <div className={classes.tab_box}>
                <Typography className={classes.header_tab_label}>List of Overviews</Typography>
                <Tabs
                  orientation="vertical"
                  className={classes.tab_list}
                  indicatorColor="primary"
                  value={activeTab}
                  onChange={changeTabHandler}
                  classes={{ indicator: classes.indicator }}
                >
                  {tabList} 
                </Tabs>
              </div>
              <div className={classes.graph_container}>
                <NavFleet
                  title={links[activeTab]?.name}
                  url={links[activeTab].link}
                  newTab={false}
                />
                <FleetLink tab={activeTab} linkData={links} />
              </div>
            </Box>
          ) : (
            <FleetNoData clientId={user.client?.id} />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default memo(Fleet);
