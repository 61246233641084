/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Collapse
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Styles
import useFormStyles from "../../../styles/useFormStyles";
// import useFormStyles from "../Automated/styles/useFormStyles";

export const batteryLevelOptions = [
  "All",
  "80% - 100%",
  "50% - 79%",
  "Below 50%"
];

const BatteryLevelInput = React.forwardRef((props, ref) => {
  const { initialValue = "All", readOnly = false } = props;
  const [selected, setSelected] = useState(initialValue);

  const formStyles = useFormStyles();

  useEffect(() => {
    ref.current = initialValue;
    return () => {
      ref.current = initialValue;
    };
  }, []);

  const handleSelectedChange = e => {
    ref.current = e.target.value;
    setSelected(e.target.value);
  };

  return (
    <FormControl required={!readOnly} classes={{ root: formStyles.formRoot }}>
      <InputLabel id="battery-level-label">Battery Level</InputLabel>
      <Select
        disableUnderline={readOnly}
        disabled={readOnly}
        classes={{ root: formStyles.selectRoot }}
        labelId="battery-level-id"
        id="battery-level-required"
        value={selected}
        onChange={handleSelectedChange}
      >
        {batteryLevelOptions.map((level, index) => (
          <MenuItem
            key={`battery-level-${index}`}
            classes={{ root: formStyles.menuItem }}
            value={level}
          >
            {level}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default BatteryLevelInput;
