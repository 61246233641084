/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Styles
import useFormStyles from "../../../styles/useFormStyles";

export const reportDelayOptions = [
  "All",
  "Less than 1 hr",
  "1 to 4 hrs",
  " 5 to 8 hrs",
  "9 to 12 hrs",
  "13 to 16 hrs",
  "17 to 20 hrs",
  "21 to 24 hrs",
  "Greater than 24 hrs"
];

const ReportDelaySearchInput = React.forwardRef((props, ref) => {
  const { initialValues = "All", readOnly } = props;
  const [selectedReportDelay, setSelectedReportDelay] = useState(initialValues);

  const formStyles = useFormStyles();

  useEffect(() => {
    ref.current = initialValues;
    return () => {
      ref.current = initialValues;
    };
  }, []);

  const handleSelectedChange = (_, nextValue) => {
    ref.current = nextValue;
    setSelectedReportDelay(nextValue);
  };

  return (
    <FormControl className={formStyles.formRoot}>
      <Autocomplete
        id="report-delay-select-input"
        options={reportDelayOptions}
        disableClearable
        disabled={readOnly}
        onChange={handleSelectedChange}
        value={selectedReportDelay}
        getOptionLabel={option => option.toString() || ""}
        getOptionSelected={(newVal, option) => newVal && newVal === option}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: formStyles.textFieldRoot }}
            label="Reporting Delay"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: {
                padding: "6px 0px"
              },
              ...params.InputProps,
              disableUnderline: readOnly,
              disabled: readOnly
            }}
            // required
          />
        )}
      />
    </FormControl>
  );
});

export default ReportDelaySearchInput;
