import React from "react";
import _ from "lodash";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Highlight from "react-highlighter";
import { useStyles } from "./AssignGeofence.styles";
import moment from "moment";

export const GeofenceTable = props => {
  const {
    geos,
    page,
    setPage,
    rowsPerPage,
    keyword,
    selected,
    setSelected,
    previewGeo,
    standardChecked,
    setStandardChecked,
    isRoute,
    showAssigned
  } = props;
  const classes = useStyles();

  const formatTime = (timeStart, timeEnd) => {
    const mStart = moment(timeStart, "YYYY-MM-DD HH:mm");
    const mEnd = moment(timeEnd, "YYYY-MM-DD HH:mm");
    return mStart.isValid() && mEnd.isValid()
      ? `${mStart.format("HH:mm") || ""} - ${mEnd.format("HH:mm")}`
      : "";
  };

  const handleCheckStandard = (v) => {
    // const selectedIndex = standardChecked.indexOf(id);
    const selectedIndex = standardChecked.findIndex(x => x.geofence_code === v.geofence_code);
    const geo = {
      geofence_id: +v.id || +v.geofence_id,
      geofence_name: v.name || v.geofence_name,
      geofence_code: v.geofence_code,
      geom: v.geom,
      location: v.location,
      window_time_start: v.window_time_start,
      window_time_end: v.window_time_end
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(standardChecked, geo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(standardChecked.slice(1));
    } else if (selectedIndex === standardChecked.length - 1) {
      newSelected = newSelected.concat(standardChecked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        standardChecked.slice(0, selectedIndex),
        standardChecked.slice(selectedIndex + 1)
      );
    }
    setStandardChecked(newSelected);
    if (showAssigned && page > 1 && newSelected.length <= rowsPerPage) {
      setPage(page - 1);
    }
  };

  // const isChecked = id => standardChecked.indexOf(Number(id)) !== -1;
  const isChecked = v => standardChecked.findIndex(x => x.geofence_code === v.geofence_code) !== -1;

  const isCheckedAll = () => {
    let checked = { all: false, indeterminate: false };
    const displayedGeo = geos
      .map(d => d.geofence_code)
      .slice((page - 1) * rowsPerPage, page * rowsPerPage);
    if (displayedGeo.length) {
      if (displayedGeo.every(v => standardChecked?.findIndex(x => x.geofence_code === v) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedGeo.some(v => standardChecked?.findIndex(x => x.geofence_code === v) > -1))
        checked = { all: false, indeterminate: true };
    }
    return checked;
  };

  // check all standard geofence in current page and show last in map
  const handleSelectAllClick = e => {
    let tempSelected = standardChecked;
    const deselect = () => {
      const displayGeo = geos
        .map(v => v.geofence_code)
        .slice((page - 1) * rowsPerPage, page * rowsPerPage);
      const newSelected = tempSelected
        .filter(v => displayGeo.findIndex(x => x === v.geofence_code) < 0)
        .map(id => id);
      setStandardChecked(newSelected);
      if (showAssigned && page > 1) {
        setPage(page - 1);
      }
    };

    if (e.target.checked) {
      if (isCheckedAll().indeterminate) {
        deselect();
        return;
      }
      const newSelected = geos
        .map(v => ({
          geofence_id: +v.id || +v.geofence_id,
          geofence_name: v.name || v.geofence_name,
          geofence_code: v.geofence_code,
          geom: v.geom,
          location: v.location,
          window_time_start: v.window_time_start,
          window_time_end: v.window_time_end
        }))
        .slice((page - 1) * rowsPerPage, page * rowsPerPage);
      setStandardChecked(_.uniq([...tempSelected, ...newSelected]));
      const lastItem = geos.filter(el => 
        el.geofence_code === newSelected[newSelected.length-1].geofence_code
      )[0];
      previewGeo(lastItem);
      return;
    }
    deselect();
  };

  const handleSelectRow = (geofence, geofenceId) => {
    previewGeo(geofence);
    if (isRoute) {
      setSelected(prev => ({
        ...selected,
        geofence_id: +geofenceId,
        geofence_name: geofence.name || geofence.geofence_name,
        geofence_code: geofence.geofence_code,
        geom: geofence.geom,
        line_geom: geofence.line_geom
      }));
    }
  }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table stickyHeader>
        <TableHead className={classes.thead}>
          <TableRow className={`${classes.trow} ${isRoute ? classes.trow_padded : ''}`}>
            <TableCell padding="checkbox">
              {!isRoute && (
                <Checkbox
                  color="primary"
                  onClick={handleSelectAllClick}
                  disabled={!geos.length}
                  indeterminate={isCheckedAll().indeterminate}
                  checked={isCheckedAll().all}
                />
              )}
            </TableCell>
            <TableCell>Geofence Name</TableCell>
            <TableCell>Geofence Code</TableCell>
            {!isRoute && (
              <TableCell>Window Time</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tbody}>
          {!_.isEmpty(geos) ? (
            geos
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((geofence, index) => {
                const geofenceId = geofence?.geofence_id || geofence?.id;
                const selectedId = selected?.geofence_id || selected?.id;
                const isItemChecked = !isRoute ? isChecked(geofence) : false;
                const isItemSelected = +selectedId === +geofenceId;

                return (
                  <TableRow
                    onClick={() => handleSelectRow(geofence, geofenceId)}
                    role="checkbox"
                    tabIndex={-1}
                    key={geofenceId}
                    selected={isItemSelected}
                    className={`${classes.trow} ${isRoute ? classes.trow_padded : ''}`}
                  >
                    <TableCell padding="checkbox">
                      {!isRoute && (
                        <Checkbox
                          color="primary"
                          onClick={() => handleCheckStandard(geofence)}
                          checked={isItemChecked}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Highlight search={keyword}>{!showAssigned ? geofence.name : geofence.geofence_name}</Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={keyword}>
                        {geofence.geofence_code}
                      </Highlight>
                    </TableCell>
                    {!isRoute && (
                      <TableCell>
                        {formatTime(
                          geofence.window_time_start,
                          geofence.window_time_end
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
          ) : (
            <TableRow>
              <TableCell colSpan={4} style={{ textAlign: "center" }}>
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};