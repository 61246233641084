import React, { useState } from "react";
import { Autocomplete as PlacesAutocomplete } from "@react-google-maps/api";
import { TextField } from "@material-ui/core";

const PlaceAutocomplete = React.forwardRef(({onPlaceChanged = () => {}}, ref) => {
  const autocompleteRef = React.useRef();
  const [selectedPlace, setSelectedPlace] = useState(() => {
    if (ref.current) return ref.current.getPlace()?.formatted_address;
    return "";
  });
  // const onPlacesAutocompleteLoad = autocomplete => {
  //   // eslint-disable-next-line no-param-reassign
  //   if (!ref.current) ref.current = autocomplete;
  // };

  // const onPlaceChange = () => {
  //   if (ref.current)
  //     setSelectedPlace(ref.current.getPlace()?.formatted_address);
  // };

  const onPlacesAutocompleteLoad = autocomplete => {
    // eslint-disable-next-line no-param-reassign
    if (!autocompleteRef.current) autocompleteRef.current = autocomplete;
  };

  const onPlaceChange = () => {
    if (autocompleteRef.current) {
      setSelectedPlace(
        autocompleteRef.current.getPlace()?.formatted_address
      );
      onPlaceChanged(autocompleteRef.current);
    }
  };

  const handleTextChange = e => {
    setSelectedPlace(e.target.value);
    if (e.target.value === "") {
      onPlaceChanged(null)
    }
  };

  return (
    <PlacesAutocomplete
      onLoad={onPlacesAutocompleteLoad}
      onPlaceChanged={onPlaceChange}
      restrictions={{ country: "ph" }}
    >
      <TextField
        style={{ width: "250px" }}
        label="Location"
        placeholder="All"
        fullWidth
        value={selectedPlace}
        onChange={handleTextChange}
        InputLabelProps={{
          shrink: true,
          style: { fontWeight: "bold" }
        }}
        margin="normal"
      />
    </PlacesAutocomplete>
  );
});

export default PlaceAutocomplete;
