/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  Grid,
  Badge,
  CircularProgress,
  Fab
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  Block as BlockIcon,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import AccessControl from "../../../AccessControl";
import SearchContext from "../../../../context/SearchContext";
import useFormStyles from "../../../../styles/useFormStyles";
import Pagination from "../../../table/Pagination";
import PaperCount from "../../../UI/Paper/PaperCount";
import users_data from "./users.data";
import Focus from "../../common/Focus";
import { useOnboardingContext } from "../..";

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username"
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "Full Name"
  },
  {
    id: "user_level_id",
    numeric: false,
    disablePadding: false,
    label: "Access Right"
  },
  {
    id: "email_address",
    numeric: false,
    disablePadding: false,
    label: "Email Address"
  },
  {
    id: "contact_number",
    numeric: true,
    disablePadding: false,
    label: "Contact Number"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: 20,
    right: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white",
    backgroundColor: "#ffb677"
  },

  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  icon: {
    opacity: 1
  },
  link: {
    color: "#757575"
  },
  dialog: {
    borderRadius: "10px"
  },
  loading: {
    width: 300,
    height: 300
  },
  statusUploading: {
    color: "#3f3f3f"
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  }
}));

function EnhancedTableHead(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;

  return (
    <TableHead
      style={{
        borderTop: "solid #e5e5e5",
        borderBottom: "solid #e5e5e5",
        borderWidth: "1px"
      }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // disabled={!canEdit}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            // indeterminate={isChecked().indeterminate}
            // checked={isChecked().all}
            // onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={() =>
              //   handleSort(
              //     headCell.id === "full_name" ? "first_name" : headCell.id
              //   )
              // }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children } = props;

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Users
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

function UploadDialog(props) {
  const classes = useStyles();
  const {
    uploadDialog,
    handleCloseUpload,
    refetch,
    getVariables,
    clientLimit,
    userTotal
  } = props;
  const [file, setFile] = useState([]);
  const [validIndex, setValidIndex] = useState([]);
  const [re, setRe] = useState(false);
  const [uploadBtn, setUploadBtn] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const [expanded, setExpanded] = useState(-1);

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleCloseUpload}
      aria-labelledby="customized-dialog-title"
      open={uploadDialog}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
        Upload
      </DialogTitle>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContent,
          dividers: classes.dialogContent
        }}
      >
        {file.length > 0 &&
          file.map((fileInfo, index) => (
            <ExpansionPanel
              key={index}
              square
              expanded={index === expanded}
              onClick={() => handleClick(index)}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  (fileInfo.status === "Failed" ||
                    fileInfo.status === "Partial Success") && <ExpandMoreIcon />
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography className={classes.typography}>
                      {fileInfo.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className={classes.typography}>
                      {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {isUploading && file.length - 1 === index ? (
                      <CircularProgress size={12} />
                    ) : (
                      <Typography
                        className={`${classes.typography} ${
                          fileInfo.status === "Partial Success" ||
                          fileInfo.status === "Failed"
                            ? classes.statusFailed
                            : classes.statusSuccessful
                        }`}
                      >
                        {fileInfo.status}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {(fileInfo.status === "Failed" ||
                fileInfo.status === "Partial Success") && (
                <ExpansionPanelDetails
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {typeof fileInfo.errors === "string" ? (
                    <Typography style={{ fontSize: 14, margin: 8 }}>
                      {fileInfo.errors}
                    </Typography>
                  ) : (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "100px" }}>Row</TableCell>
                          <TableCell align="left">Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fileInfo.errors.length > 0 &&
                          fileInfo.errors
                            .sort(reorder)
                            .filter(row => row.errors !== "")
                            .map(row => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  <Typography className={classes.typography}>
                                    {row.row}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.typography}>
                                    {row.errors}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  )}
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          ))}
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%" }}>
          <label htmlFor="icon-upload-file">
            {/* <input
              className="form-control"
              color="primary"
              accept=".csv, .xlsx, .xls"
              type="file"
              id="icon-upload-file"
              style={{ display: "none" }}
              // onChange={e => handleUpload(e)}
            /> */}
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              style={{ backgroundColor: "#ffb677" }}
              startIcon={<PublishIcon />}
              // disabled={pendingFile()}
            >
              Add a File
            </Button>
          </label>
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              autoFocus
              color="primary"
              startIcon={<CloudUploadIcon />}
              style={{ color: "white", borderRadius: 50 }}
              // onClick={submitUpload}
              disabled={uploadBtn}
            >
              Upload
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

function Users() {
  const classes = useStyles();
  // const context = useContext(LayoutContext);
  const contextData = useContext(SearchContext);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");
  const [uploadDialog, setUploadDialog] = useState(false);
  const {
    progress: { admin: currentStep }
  } = useOnboardingContext();

  const handleOpenUpload = () => {
    setUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setUploadDialog(false);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar>
          <Focus
            infoBubbleProps={{
              position: "left",
              title: "Download",
              description:
                "Allows the transfer of data from web to user's device"
            }}
            step={6}
          >
            <Button
              // ref={anchorEl}
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              // onClick={e => {
              //   setAnchorEl(e.currentTarget);
              //   handleToggle();
              // }}
            >
              Download
            </Button>
          </Focus>
          <Focus
            step={7}
            on={!uploadDialog}
            indicator={currentStep === 7 && !uploadDialog}
            indicatorProps={{ placement: "top-left" }}
            showInfoBubble={!uploadDialog}
            infoBubbleProps={{
              title: "Upload",
              description:
                "Allows the transfer of data from user's device to web"
            }}
          >
            <Button
              className={classes.btn_upload}
              variant="contained"
              startIcon={<PublishIcon />}
              onClick={handleOpenUpload}
            >
              Upload
            </Button>
          </Focus>
          <Focus
            step={8}
            infoBubbleProps={{
              position: "left",
              title: "Filter",
              description:
                "Allows to display certain data based on selected values"
            }}
          >
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Focus>
          <Pagination rowsPerPage={10} />
        </EnhancedTableToolbar>
        <Focus
          step={5}
          infoBubbleProps={{
            position: "bottom",
            title: "Data Table",
            description:
              "Manage and organize vehicle-related resources and geofences"
          }}
        >
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                // rowCount={data?.get_users?.total}
                handleSort={handleSort}
                // isChecked={isChecked}
                canEdit={true}
              />

              <TableBody>
                {users_data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={false}
                          // onClick={event => handleClick(event, row)}
                          inputProps={{ "aria-labelledby": labelId }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.username}
                        </Highlight>
                      </TableCell>
                      <TableCell>
                        <Highlight
                          search={contextData.searchKeyword.value}
                        >{`${row.first_name} ${row.last_name}`}</Highlight>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.user_level_name}
                        </Highlight>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.email_address}
                        </Highlight>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.contact_number}
                        </Highlight>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          style={{ paddingLeft: "0px" }}
                          onClick={
                            () => {}
                            // AddLogs("Admin - Users", "view", row.username)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton style={{ paddingLeft: "0px" }}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          style={{ paddingLeft: "0px", color: "red" }}
                        >
                          <BlockIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Focus>
      </Paper>
      <div className={classes.fab}>
        <Focus
          step={10}
          style={{ boxSizing: "border-box", padding: 8 }}
          infoBubbleProps={{
            position: "top",
            title: "Data Created",
            description: "Total count of resources registered"
          }}
        >
          <PaperCount label="users created" currentTotal={10} data={15} />
        </Focus>
        <Focus
          step={9}
          style={{ boxSizing: "border-box", padding: 8 }}
          infoBubbleProps={{
            position: "top",
            title: "New Data",
            description: "Allows user to add new data on this module"
          }}
        >
          <Fab size="large" color="primary">
            <AddIcon
              style={{
                width: "50px",
                height: "50px"
              }}
            />
          </Fab>
        </Focus>
      </div>
      <UploadDialog
        uploadDialog={uploadDialog}
        handleCloseUpload={handleCloseUpload}
        // refetch={() => {}}
        // getVariables={getVariables}
        // clientLimit={0}
        // userTotal={currentNumber}
      />
    </div>
  );
}

export default withRouter(Users);
