/* eslint-disable no-useless-constructor */
import React from "react";
import { createPortal } from "react-dom";
import { ViewQuilt } from "@material-ui/icons";
import { Box, Paper } from "@material-ui/core";

class AddControl extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { mapRef } = this.props;
    const { google } = window;
    const { RIGHT_BOTTOM } = google.maps.ControlPosition;
    this.map = mapRef.current;
    this.controlContainer = document.createElement("div");
    this.controlContainer.setAttribute(
      "style",
      `
                margin: 10px;
                height: auto;
                width: auto;
            `
    );
    this.map.controls[RIGHT_BOTTOM].push(this.controlContainer);
  }

  render() {
    const { bound } = this.props;

    return createPortal(
      <React.Fragment>
        {!!bound.length && (
          // <Tooltip>
          <Paper
            elevation={0}
            style={{
              // padding: "3px 6px",
              background: "rgba(255,255,255,0.9)",
              boxShadow: "0px 0px 18px -8px #484848d4"
              // cursor: "help"
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: "40px",
                height: "40px"
              }}
            >
              <ViewQuilt />
            </Box>
          </Paper>
          // </Tooltip>
        )}
      </React.Fragment>,
      this.controlContainer
    );
  }
}

export default AddControl;
