import React from "react";
import { makeStyles } from "@material-ui/core";

const useHeaderStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "8px 18px",
    // boxShadow: "0px 2px 5px #e8e8e8",
    borderBottom: "2px solid #efefef",
    minHeight: "62px",
    maxHeight: "62px",
    justifyContent: "space-between",
    boxSizing: "border-box"
  }
});

const Header = ({ children }) => {
  const styles = useHeaderStyles();
  return <div className={styles.root}>{children}</div>;
};

export default Header;
