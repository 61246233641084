/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Typography,
  Tooltip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Visibility, DeleteRounded } from "@material-ui/icons";

// Utility functions
import { formatEvery } from "../../utils/format";
import { getComparator, stableSort } from "./helpers";
// Components
import TableCellText from "../../common/TableCellText";
import Loading from "../../../../utils/Loading";

// Styles
import buttonStyles from "../../common/buttonStyles";
import useTableBodyStyles from "../styles/useTableBodyStyles";

// Utils
import parseEmails from "../../utils/parseEmails";

const CustomTableBody = props => {
  const {
    isDataLoading,
    tableInfo,
    toggleRowCheckbox,
    handleSingleDelete,
    permissions
  } = props;
  const styles = useTableBodyStyles();
  const btnStyles = buttonStyles();
  const isSelected = id => tableInfo.selectedRows.indexOf(id) !== -1;

  if (isDataLoading) {
    return <Loading />;
  }

  // const { rowsPerPage, sort, currentPage } = tableInfo;
  const { sort } = tableInfo;
  return (
    <TableBody>
      {stableSort(tableInfo.data, getComparator(sort.direction, sort.field))
        // .slice(
        //   currentPage * rowsPerPage,
        //   currentPage * rowsPerPage + rowsPerPage
        // )
        .map(row => {
          const isRowSelected = isSelected(row.id);
          const [type, day] = row.sked.split(":");
          const timeSked = row.timesked;
          const every = formatEvery(day, timeSked, type);
          const [emails, others] = parseEmails(row.email_add);
          return (
            <TableRow key={row.id}>
              {permissions.edit && (
                <TableCell classes={{ root: styles.cellRoot }}>
                  <Checkbox
                    color="primary"
                    checked={isRowSelected}
                    onClick={() => toggleRowCheckbox(row.id)}
                  />
                </TableCell>
              )}
              <TableCell classes={{ root: styles.cellRoot }}>
                <TableCellText
                  text={row.report_type}
                  capitalize
                  key={`report_type_${row.id}`}
                />
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }}>
                <TableCellText text={type} capitalize key={`type_${row.id}`} />
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }}>
                <TableCellText
                  text={every}
                  capitalize
                  key={`every_${row.id}`}
                />
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }}>
                {emails.map(email => (
                  <TableCellText text={email} key={`email_${row.id}`} />
                ))}
                {others.length > 0 && (
                  <Tooltip
                    classes={{ tooltip: styles.toolTip }}
                    title={others.map((email, index) => (
                      <Typography variant="body2" key={`other_${index}`}>
                        {email}
                      </Typography>
                    ))}
                    placement="right-start"
                  >
                    <Typography
                      variant="caption"
                      classes={{ root: styles.othersRoot }}
                    >
                      (+{others.length})
                    </Typography>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }} align="center">
                <Link
                  className={btnStyles.link}
                  to={{
                    pathname: `/reports/automated/view/${row.id}`,
                    state: {
                      id: row.id,
                      moduleName: process.env.REACT_APP_AUTOMATED_REPORTS_MODULE
                    }
                  }}
                >
                  <IconButton>
                    <Visibility />
                  </IconButton>
                </Link>
                {permissions.edit && (
                  <IconButton
                    onClick={() => {
                      handleSingleDelete([row.id]);
                    }}
                  >
                    <DeleteRounded />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default CustomTableBody;
