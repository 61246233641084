import React, { useMemo } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";
import { MoreVertRounded as MoreIcon } from "@material-ui/icons";

import accordionStyles from "./main-styles";

const NoChildAccessRight = React.memo(
  ({ accessRight, toggleAllAcessRights, toggleCheckbox, disabled = false, checkLandingPageHandler }) => {
    const {
      name,
      id,
      parent_id,
      permission: { edit: canEdit, view: canView },
      action_only
    } = accessRight;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const styles = accordionStyles();

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const actionOnly = useMemo(() => action_only > 0, [action_only]);
    return (
      <div className={styles.summaryRoot}>
        <div className={styles.summaryLabelWrapper}>
          <FormControlLabel
            classes={{ root: styles.summaryLabelRoot }}
            control={
              <Checkbox
                disabled={disabled}
                color="primary"
                classes={{ root: styles.checkboxRoot }}
                indeterminate={!(canEdit === canView)}
                checked={canEdit && canView}
                onChange={(_e, newChecked) =>{
                  toggleAllAcessRights({ parent: accessRight, newChecked })
                }}
              />
            }
          />
          <Typography classes={{ root: styles.typographyRoot }}>
            {name}
          </Typography>
        </div>
        <div>
          {!actionOnly && (
            <IconButton
              disabled={disabled}
              aria-label="more"
              aria-controls="access-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
            >
              <MoreIcon fontSize="small" />
            </IconButton>
          )}
          <Menu
            id="access-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <FormControlLabel
                classes={{ root: styles.summaryLabelRoot }}
                label={
                  <Typography classes={{ root: styles.typographyRoot }}>
                    Can View
                  </Typography>
                }
                control={
                  <Checkbox
                    name="view"
                    color="primary"
                    classes={{ root: styles.checkboxRoot }}
                    checked={canView}
                    onChange={(e, newChecked) => {
                      toggleCheckbox({
                        type: e.target.name,
                        accessRight,
                        newChecked
                      });
                    }}
                  />
                }
              />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <FormControlLabel
                classes={{ root: styles.summaryLabelRoot }}
                label={
                  <Typography classes={{ root: styles.typographyRoot }}>
                    Can Edit
                  </Typography>
                }
                control={
                  <Checkbox
                    name="edit"
                    color="primary"
                    classes={{ root: styles.checkboxRoot }}
                    checked={canEdit}
                    onChange={(e, newChecked) => {
                      toggleCheckbox({
                        type: e.target.name,
                        accessRight,
                        newChecked
                      });
                    }}
                  />
                }
              />
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
);

export default NoChildAccessRight;
