import intro from "../../images/Introductory/intro.svg";
import page1 from "../../images/Introductory/delivery-planning.svg";
import page2 from "../../images/Introductory/realtime-tracking.svg";
import page3 from "../../images/Introductory/advanced-analytics.svg";
import page4 from "../../images/Introductory/realtime-delivery-monitoring.svg";

const data = {
  [-1]: {
    image: intro,
    title: "TRACKme Suite Web",
    description: `Real-time vehicle location tracking and goods-level delivery
    monitoring. Advanced analytics to evaluate business performance.
    Anytime, anywhere.`
  },
  0: {
    image: page1,
    title: "Delivery Planning",
    description: "Plan your delivery and book a vehicle for your delivery"
  },
  1: {
    image: page2,
    title: "Real-time Tracking",
    description: "Track the real-time location of your vehicle and assets"
  },
  2: {
    image: page3,
    title: "Advanced Analytics",
    description: "View the real-time KPI of your deliveries"
  },
  3: {
    image: page4,
    title: "Real-time Delivery Monitoring",
    description: "Get real-time updates and status of your delivery"
  }
};

export default data;
