/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Paper,
  makeStyles,
  // withStyles,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Button,
  // ExpansionPanel as MuiExpansionPanel,
  // ExpansionPanelSummary as MuiExpansionPanelSummary,
  // ExpansionPanelDetails,
  // FormControlLabel,
  // Checkbox,
  MenuItem,
  // IconButton,
  // Grow,
  // Popper,
  // ClickAwayListener,
  // MenuList,
  FormControl,
  Select,
  Tooltip
} from "@material-ui/core";
import {
  // ExpandMore as ExpandMoreIcon,
  HelpRounded
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Swal from "sweetalert2";
import _ from "lodash";
import { ADD_USER_LEVEL } from "../../../../graphql/Mutations";
import { GET_ACCESS_OBJECTS } from "../../../../graphql/Queries";
import SearchContext from "../../../../context/SearchContext";
import AccessControl from "../../../../utils/AccessControl";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Header from "../../Header";
import redirectToTable from "../../../../utils/redirect";
import AddLogs from "../../../../utils/functions/AddLogs";
import ModuleTree from "../../../../utils/ModuleTree";
import useFormStyles, {
  useFormErrorStyles
} from "../../../../styles/useFormStyles";
// import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
import Loading from "../../../../utils/Loading";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    "& ::-webkit-scrollbar-thumb": {
      border: "5px solid #f8f8f8"
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      border: "4px solid #f8f8f8",
      background: "#969696"
    },
    "& ::-webkit-scrollbar-track": {
      border: "5px solid #f8f8f8"
    }
  },
  container: {
    height: "calc(100% - 66px)",
    // margin: theme.spacing(2, 4),
    flexGrow: 1,
    boxSizing: "border-box",
    padding: theme.spacing(3),
    overflow: "auto",
    background: "#F8F8F8"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  formLabelRoot: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#454545",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center"
  },
  formOutlined: {
    border: "2px solid #cbcbcb"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  column: {
    fontSize: "1.5rem"
  },
  buttonContainer: { position: "relative", bottom: theme.spacing(3) },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  details: {
    display: "block",
    borderTop: "solid",
    borderTopWidth: "1px",
    borderTopColor: "lightgray",
    padding: "8px 20px"
  },
  dialog: {
    borderRadius: "10px"
  }
}));

// const ExpansionPanel = withStyles({
//   root: {
//     "&$expanded": {
//       margin: "0 auto"
//     },
//     boxShadow: "none",
//     MozBoxShadow: "none",
//     WebkitBoxShadow: "none"
//   },
//   expanded: {}
// })(MuiExpansionPanel);

// const ExpansionPanelSummary = withStyles({
//   root: {
//     minHeight: 66,
//     height: 66,
//     "&$expanded": {
//       minHeight: 66
//     }
//   },
//   expanded: {}
// })(MuiExpansionPanelSummary);

// const EditViewMenuTest = props => {
//   const {
//     anchorEl,
//     open,
//     handleClosePopper,
//     handleListKeyDown,
//     access,
//     handleAccessView,
//     handleAccessEdit
//   } = props;

//   return (
//     <Popper
//       open={open}
//       anchorEl={anchorEl}
//       role={undefined}
//       transition
//       disablePortal
//     >
//       {({ TransitionProps, placement }) => (
//         <Grow
//           {...TransitionProps}
//           style={{
//             transformOrigin:
//               placement === "bottom" ? "center top" : "left bottom"
//           }}
//         >
//           <Paper>
//             <ClickAwayListener onClickAway={handleClosePopper}>
//               <MenuList
//                 autoFocusItem={open}
//                 id="menu-list-grow"
//                 onKeyDown={handleListKeyDown}
//               >
//                 <MenuItem style={{ padding: 0 }}>
//                   <FormControlLabel
//                     aria-label="Acknowledge"
//                     control={
//                       <Checkbox
//                         checked={access ? access.view : false}
//                         value={access || ""}
//                         onClick={e => handleAccessView(e)}
//                         name={anchorEl.name}
//                         color="primary"
//                       />
//                     }
//                     label="Can View"
//                     style={{ margin: "0 16px 0 0" }}
//                   />
//                 </MenuItem>
//                 <MenuItem style={{ padding: 0 }}>
//                   <FormControlLabel
//                     aria-label="Acknowledge"
//                     control={
//                       <Checkbox
//                         checked={access ? access.edit : false}
//                         value={access.edit || ""}
//                         onClick={e => handleAccessEdit(e)}
//                         name={anchorEl.name}
//                         color="primary"
//                       />
//                     }
//                     label="Can Edit"
//                     style={{ margin: "0 16px 0 0" }}
//                   />
//                 </MenuItem>
//               </MenuList>
//             </ClickAwayListener>
//           </Paper>
//         </Grow>
//       )}
//     </Popper>
//   );
// };

export default function AddAcessRight(props) {
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  const [state, setState] = useState({});
  const [landingPage, setLandingPage] = useState(0);
  const [textual, setTextual] = useState({
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({
    name: "",
    access_rights: "",
    landing_page: ""
  });
  const [initialState, setInitialState] = useState({
    state: _.cloneDeep(state),
    textual: _.cloneDeep(textual)
  });
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const history = useHistory();
  // const [anchorEl, setAnchorEl] = useState(false);
  // const [expanded, setExpanded] = useState("");
  const [open, setOpen] = React.useState(false);
  const [rerender, setRerender] = useState(false);
  const [parsing, setParsing] = useState(true);
  const [selectedModules, setSelectedModules] = useState([]);
  const [landingPageExist, setLandingPageExist] = useState(false);
  const [adminSelected, setAdminSelected] = useState(false);
  const { data: data1, loading } = useQuery(GET_ACCESS_OBJECTS);

  // const [popper, setPopper] = useState(false);
  const { location } = props;
  const { pathname } = location;
  const [addUserLevel] = useMutation(ADD_USER_LEVEL, {
    onCompleted(data) {
      if (data) {
        if (data.add_user_level.success) {
          AddLogs("Admin - Access Rights", "insert", textual.name);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
            }
          });
        } else {
          const temp = [];
          data.add_user_level.error.map(element =>
            temp.push({ name: "This role already exists" })
          );
          setErrors(Object.assign(errors, ...temp));
          setRerender(!rerender);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        });
      }
    }
  });

  const sort = (items = []) => {
    const temp = [...items];
    return temp.sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  };

  const clearErrors = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: "" }));
    setErrors(Object.assign(errors, ...temp));
  };

  const check = field => {
    if (!errors[field])
      return textual[field] === "" ? "This field is required" : "";
    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    // setErrors(Object.assign(errors, ...temp));
    const selected = Object.values(state).filter(el => el.view === true);
    if (landingPage === 0) {
      temp.push({ landing_page: "This field is required" });
    }
    if (selected.length <= 0) {
      temp.push({ access_rights: "This field is required" });
    }
    if (temp.length > 0) setErrors(Object.assign(errors, ...temp));

    return (
      keys
        .filter(element => element in textual)
        .every(element => errors[element] === "") &&
      keys.every(key => errors[key] === "")
    );
  };
  // const handleToggle = () => {
  //   setPopper(prevPopper => !prevPopper);
  // };

  // const handleClosePopper = event => {
  //   if (anchorEl.current && anchorEl.current.contains(event.target)) {
  //     return;
  //   }

  //   setPopper(false);
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setPopper(false);
  //   }
  // }

  const handleClickOpen = () => {
    if (validateFields()) setOpen(true);
    else setRerender(!rerender);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  // const handleAccessEdit = e => {
  //   const { name, checked } = e.target;
  //   setState({
  //     ...state,
  //     [name]: {
  //       ...state[name],
  //       view: checked ? true : state[name].view,
  //       edit: checked
  //     }
  //   });
  // };

  // const handleAccessView = e => {
  //   const { name, checked } = e.target;
  //   setState({
  //     ...state,
  //     [name]: {
  //       ...state[name],
  //       view: checked,
  //       edit: checked ? state[name].edit : checked
  //     }
  //   });
  // };

  const getName = resource => {
    let name = resource.split("/")[resource.split("/").length - 2];
    if (resource.split("/")[1] === "prebooking") {
      name = `${resource.split("/")[resource.split("/").length - 2]}_pre`;
    }
    return name;
  };

  // const handleChange = (event, parent) => {
  //   const { name, checked } = event.target;
  //   const parsedName = getName(name);
  //   const parentName = getName(parent.resource);
  //   const children = data1.get_access_objects
  //     .filter(ch => ch.parent_id === parent.id && ch.resource !== name)
  //     .map(ch => getName(ch.resource));
  //   const inState = children.map(c => state[c]);
  //   setState({
  //     ...state,
  //     [parsedName]: { ...state[parsedName], view: checked, edit: checked },
  //     [parentName]: {
  //       ...state[parentName],
  //       view: checked || inState.some(ins => ins.view === true),
  //       edit: checked
  //     }
  //   });
  //   setErrors({ ...errors, access_rights: "" });
  // };

  const handleOnSelectedModuleChanged = useCallback(
    (newModuleTree, isModuleEmpty) => {
      if (isModuleEmpty) {
        // setErrors({ ...errors, access_rights: "Please choose access rights" });
      } else {
        setErrors(prev => ({ ...prev, access_rights: "" }));
      }

      const permissions = newModuleTree.reduce((accum, current) => {
        const accumulatedPermissions = accum;
        accumulatedPermissions[getName(current.resource)] = {
          resources: current.resource,
          view: current.permission.view,
          edit: current.permission.edit
        };
        return accumulatedPermissions;
      }, {});
      /*
       *** fetch all the checked modules with the parent id of 0.
       */
      const checkModules = newModuleTree.filter(
        module =>
          module.permission.view === true &&
          module.parent_id === "0" &&
          process.env.REACT_APP_ACL_MODULE_STORE_APP !== module.id &&
          process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API !== module.id
      );
      // setSelectedModules(
      //   [
      //     {
      //       id: "0",
      //       name: "Select Landing Page",
      //       parent_id: "0",
      //       permissions: { edit: true, view: true }
      //     }
      //   ].concat(checkModules)
      // );
      setSelectedModules(checkModules);
      setState(permissions);
    },
    []
  );

  const handleChangeText = event => {
    const { name, value } = event.target;
    if (errors[name]) setErrors({ ...errors, [name]: "" });
    setTextual({
      ...textual,
      [name]: value
    });
  };

  // const handlePanel = panel => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  // const handleSelectAll = (event, id) => {
  //   const { checked } = event.target;
  //   const temp = [];
  //   if (data1) {
  //     data1.get_access_objects
  //       .filter(
  //         moduleName => moduleName.parent_id === id || moduleName.id === id
  //       )
  //       .map(moduleName =>
  //         temp.push({
  //           [getName(moduleName.resource)]: {
  //             ...state[getName(moduleName.resource)],
  //             view: checked,
  //             edit: false
  //           }
  //         })
  //       );
  //   }
  //   const newState = Object.assign(state, ...temp);

  //   // check landing page
  //   if (landingPage.toString() === id) setLandingPage(0);
  //   setState(newState);
  //   setErrors({ ...errors, access_rights: "" });
  //   setRerender(!rerender);
  // };

  // const hasChild = parent_id => {
  //   return Object.values(data1.get_access_objects).some(
  //     child => child.parent_id === parent_id
  //   );
  // };

  const handleSubmit = e => {
    e.preventDefault();
    clearErrors();
    addUserLevel({
      variables: {
        user_level: {
          name: textual.name,
          description: textual.description,
          acl_module_id: landingPage
        },
        permissions: Object.values(state)
      }
    });
  };

  // useEffect(() => {
  //   if (!parsing) {
  //     const {
  //       resources,
  //       trip,
  //       activity,
  //       users,
  //       geofences,
  //       user_levels,
  //       alerts
  //     } = state;

  //     const checkResources = () => {
  //       return users.view;
  //     };

  //     const checkTrip = () => {
  //       return geofences.view;
  //     };

  //     const checkActivity = () => {
  //       return user_levels.view || alerts.view;
  //     };
  //     setState({
  //       ...state,
  //       resources: { ...resources, view: checkResources() },
  //       trip: { ...trip, view: checkTrip() },
  //       activity: { ...activity, view: checkActivity() }
  //     });
  //   }
  // }, [state]);

  useEffect(() => {
    if (data1) {
      const temp = [];
      data1.get_access_objects.map(object =>
        temp.push({
          [getName(object.resource)]: {
            ...state[getName(object.resource)],
            resources: object.resource,
            view: false,
            edit: false
          }
        })
      );
      setState(Object.assign(state, ...temp));
      setInitialState({
        ...initialState,
        state: Object.assign(initialState.state, ...temp)
      });
      setParsing(false);
    }
  }, [data1]);
  // const isChecked = name => {
  //   let checkedBa = false;
  //   if (data1) {
  //     switch (name) {
  //       case "resources":
  //         checkedBa = data1.get_access_objects
  //           .filter(
  //             object =>
  //               object.parent_id.toString() ===
  //               process.env.REACT_APP_RESOURCES_OBJECT
  //           )
  //           .some(object => state[getName(object.resource)].view === true);
  //         break;
  //       case "trip":
  //         checkedBa = data1.get_access_objects
  //           .filter(
  //             object =>
  //               object.parent_id.toString() ===
  //               process.env.REACT_APP_TRIPS_OBJECT
  //           )
  //           .some(object => state[getName(object.resource)].view === true);
  //         break;
  //       case "activity":
  //         checkedBa = data1.get_access_objects
  //           .filter(
  //             object =>
  //               object.parent_id.toString() ===
  //               process.env.REACT_APP_ACTIVITY_OBJECT
  //           )
  //           .some(object => state[getName(object.resource)].view === true);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   return checkedBa;
  // };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: process.env.REACT_APP_RIGHTS_MODULE
    });
  }, []);

  const handleLandingPage = e => {
    setLandingPage(e.target.value);
    setErrors({ ...errors, landing_page: "" });
  };

  /*
   *** set landing page exist if the selected landing page exist in the list.
   */
  useEffect(() => {
    /*
     *** validations
     */
    const isExistModule = selectedModules.some(
      module => module.id === _.toString(landingPage) && module.id !== "0"
    );
    const isAdminFieldExist = selectedModules.some(
      module => module.name.trim().substring(0, 5) === "Admin"
    );
    /*
     *** validates if the landing page is Admin and currently checked on the modules set the landing page to id '1'.
     *** validates if the selected landing page exist on the selected modules if module does not exist set landing page to id '0'.
     */
    if (landingPage === 1 && isAdminFieldExist) setLandingPage(1);
    else if (!isExistModule) setLandingPage(0);

    setLandingPageExist(isExistModule);
    setAdminSelected(isAdminFieldExist);
  }, [selectedModules, landingPage]);

  const formStyles = useFormStyles();
  const formErrorStyles = useFormErrorStyles();
  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/activity/user_levels/"
  // );

  if (loading || parsing) return <Loading />;
  // if (!permissions.edit) return <Typography>403</Typography>;

  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          // process="Add"
          process="Add"
          moduleName={process.env.REACT_APP_RIGHTS_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={
            _.isEqual(initialState.state, state) &&
            _.isEqual(initialState.textual, textual)
          }
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            {/* <Grid item xs={12} style={{ display: "flex" }}> */}
            <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
              All fields with <span style={{ color: "red" }}>*</span> are
              required
            </Typography>
            {/* </Grid> */}
            <Grid container spacing={6}>
              <Grid item lg={3} md={6} xs={12}>
                <FormControl required classes={{ root: formStyles.formRoot }}>
                  <TextField
                    required
                    classes={{ root: formStyles.textFieldRoot }}
                    name="name"
                    label="Role"
                    placeholder="Enter Role"
                    value={textual.name}
                    onChange={e => handleChangeText(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                  />
                  {errors.name && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.name}
                    </Alert>
                  )}
                </FormControl>
                {/* <div className={classes.field}> */}
                <FormControl classes={{ root: formStyles.formRoot }}>
                  <Typography
                    classes={{ root: formStyles.formLabelRoot }}
                    variant="subtitle2"
                  >
                    Description
                  </Typography>
                  <TextField
                    name="description"
                    classes={{ root: formStyles.textFieldRoot }}
                    className="outlinedTextarea"
                    placeholder="Type here..."
                    multiline
                    rows="4"
                    value={textual.description}
                    onChange={e => handleChangeText(e)}
                    variant="outlined"
                    fullWidth
                    helperText={`${textual.description.length}/100`}
                    FormHelperTextProps={{ style: { textAlign: "right" } }}
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
                {/* </div> */}
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <div className={formStyles.formRoot}>
                  <Typography
                    classes={{ root: formStyles.formLabelRoot }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    <Tooltip
                      classes={{ tooltip: classes.toolTip }}
                      title="Set the modules that will be accessible to this role"
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    Modules &nbsp;<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Paper
                    variant="outlined"
                    classes={{ outlined: classes.formOutlined }}
                  >
                    <ModuleTree
                      updateSelectedModules={handleOnSelectedModuleChanged}
                    />
                    {/* {data1 &&
                  data1.get_access_objects
                    .filter(item => item.parent_id === "0")
                    // .filter(
                    //   object =>
                    //     object.parent_id === "0" &&
                    //     clientData.get_client.acl_modules.filter(
                    //       acl => acl.id === object.id
                    //     ).length > 0 &&
                    //     clientData.get_client.acl_modules.filter(
                    //       acl => acl.id === object.id
                    //     )[0].view
                    // )
                    .map(item => (
                      <ExpansionPanel
                        key={item.id}
                        elevation={0}
                        style={{
                          borderTop: "solid",
                          borderWidth: "1px",
                          borderColor: "lightgray"
                        }}
                        expanded={expanded === getName(item.resource)}
                        onChange={handlePanel(getName(item.resource))}
                      >
                        <ExpansionPanelSummary
                          expandIcon={hasChild(item.id) && <ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            key={item.name}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    state[getName(item.resource)].view || false
                                  }
                                  value={
                                    state[getName(item.resource)].view || false
                                  }
                                  onClick={e => {
                                    handleSelectAll(e, item.id);
                                  }}
                                  color="primary"
                                  name={getName(item.resource)}
                                />
                              }
                              label={item.name}
                            />
                            <div flexGrow={1} />
                            {!hasChild(item.id) && (
                              <IconButton
                                name={getName(item.resource)}
                                onClick={e => {
                                  setAnchorEl(e.currentTarget);
                                  handleToggle();
                                }}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        {hasChild(item.id) && (
                          <ExpansionPanelDetails className={classes.details}>
                            {data1.get_access_objects
                              .filter(object => object.parent_id === item.id)
                              .map(subM => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                  key={subM.name}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          state[getName(subM.resource)].view
                                        }
                                        value={
                                          state[getName(subM.resource)].view ||
                                          false
                                        }
                                        color="primary"
                                        onClick={e => handleChange(e, item)}
                                        name={subM.resource}
                                      />
                                    }
                                    label={subM.name}
                                    labelPlacement="end"
                                  />
                                  <div style={{ flexGrow: 1 }} />
                                  <IconButton
                                    name={getName(subM.resource)}
                                    onClick={e => {
                                      setAnchorEl(e.currentTarget);
                                      handleToggle();
                                    }}
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </div>
                              ))}
                          </ExpansionPanelDetails>
                        )}
                      </ExpansionPanel>
                    ))} */}
                  </Paper>
                  {errors.access_rights && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.access_rights}
                    </Alert>
                  )}
                </div>
                <FormControl required classes={{ root: formStyles.formRoot }}>
                  <InputLabel shrink>Landing Page</InputLabel>
                  <Select
                    classes={{ root: formStyles.selectRoot }}
                    value={
                      landingPageExist || (landingPage === 1 && adminSelected)
                        ? landingPage
                        : 0
                    }
                    onChange={e => handleLandingPage(e)}
                    name="device_type_id"
                    displayEmpty
                  >
                    <MenuItem value={0} disabled>
                      <span style={{ color: "#8e8e8e" }}>
                        Select Landing Page
                      </span>
                    </MenuItem>
                    {/* {data1.get_access_objects.length > 0 &&
                      data1.get_access_objects
                        .filter(
                          moduleRow =>
                            moduleRow.parent_id === "0" &&
                            // eslint-disable-next-line no-prototype-builtins
                            state.hasOwnProperty(getName(moduleRow.resource)) &&
                            state[getName(moduleRow.resource)].view && 
                            process.env.REACT_APP_ACL_MODULE_STORE_APP !== moduleRow.id && 
                            process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API !== moduleRow.id && 
                            moduleRow.name.trim().substring(0,5) !== 'Admin'
                        )
                        .map(moduleRow => (
                          <MenuItem
                            value={parseInt(moduleRow.id, 10)}
                            key={moduleRow.resource}
                          >
                            {moduleRow.name}
                          </MenuItem>
                        ))} */}
                    {adminSelected && <MenuItem value={1}> Admin </MenuItem>}
                    {sort(selectedModules)
                      .filter(
                        module => module.name.trim().substring(0, 5) !== "Admin"
                      )
                      .map(module => (
                        <MenuItem
                          value={parseInt(module.id, 10)}
                          key={module.id}
                          disabled={module.id === "0"}
                        >
                          {module.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.landing_page && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.landing_page}
                    </Alert>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textAlign: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleClickOpen}
                  >
                    Save Details
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* <EditViewMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            access={state[anchorEl.name]}
            handleAccessEdit={handleAccessEdit}
            handleAccessView={handleAccessView}
          /> */}

            {/* {anchorEl && (
              <EditViewMenuTest
                open={popper}
                anchorEl={anchorEl}
                access={state[anchorEl.name]}
                handleClosePopper={handleClosePopper}
                handleListKeyDown={handleListKeyDown}
                handleAccessEdit={handleAccessEdit}
                handleAccessView={handleAccessView}
              />
            )} */}
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={open}
          close={() => handleClickClose()}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save access right?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
}
