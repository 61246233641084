import React, { useState, useEffect } from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { AlarmSharp } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import useFormStyles from "../../Automated/styles/useFormStyles";
import ErrorMessage from "../ErrorMessage";

const isDisabled = (t1, t2) => {
  const timeValue = t1.substring(0, 2);
  const fromValue = t2.substring(0, 2);
  const disabled = parseInt(timeValue, 10) < parseInt(fromValue, 10);

  return disabled;
};

const useTimeBracketStyles = makeStyles({
  filterInput: {
    minWidth: "25%",
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    }
  }
});

export const defaultTimeBracket = {
  from: "00:00:00",
  to: "23:59:59"
};

const TimeBracket = React.forwardRef((props, ref) => {
  const {
    from = defaultTimeBracket.from,
    to = defaultTimeBracket.to,
    readOnly = false,
    onError = () => {},
    error = null
  } = props;
  const [time, setTime] = useState({
    from: moment(),
    to: moment()
  });
  const styles = useFormStyles();
  const timeBracketStyles = useTimeBracketStyles();

  useEffect(() => {
    if (from && to) {
      setTime({
        from: moment(`${moment().format("YYYY-MM-DD")} ${from}`),
        to: moment(`${moment().format("YYYY-MM-DD")} ${to}`)
      });
    }
  }, [from, to]);

  useEffect(() => {
    //const invalid = time.from.isAfter(time.to);

    // if (invalid) {
    //   ref.current.from = null;
    //   ref.current.to = null;
    //   onError("Invalid time range.");
    // } else {
    //   ref.current.from = time.from.format("HH:mm:ss");
    //   ref.current.to = time.to.format("HH:mm:ss");
    //   onError(null);
    // }

    ref.current.from = time.from.format("HH:mm:ss");
    ref.current.to = time.to.format("HH:mm:ss");

    return () => {
      onError(null);
    };
  }, [time.from, time.to]);

  return (
    <FormControl required={!readOnly} classes={{ root: styles.formRoot }}>
      <InputLabel id="time-bracket-label" shrink>
        Time Bracket
      </InputLabel>
      <div
        style={{
          marginTop: 18,
          display: "flex",
          alignItems: "center",
          gap: 12
        }}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker
            onKeyDown={e => e.preventDefault()}
            onChange={d => {
              setTime(prev => ({ ...prev, from: d }));
            }}
            className={timeBracketStyles.filterInput}
            classes={{ root: styles.textFieldRoot }}
            InputLabelProps={{
              shrink: true
            }}
            format="hh:mm A"
            mask="__:__ _M"
            id="date-from"
            name="time_from"
            value={time.from}
            keyboardIcon={<AlarmSharp fontSize="small" />}
            placeholder="Start time"
            InputProps={{
              disableUnderline: readOnly,
              disabled: readOnly,
              readOnly: readOnly
            }}
          />
        </MuiPickersUtilsProvider>
        <b style={{ fontSize: "0.8rem", color: "#454545", marginTop: -4 }}>
          to
        </b>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker
            onKeyDown={e => e.preventDefault()}
            onChange={d => {
              setTime(prev => ({ ...prev, to: d }));
            }}
            className={timeBracketStyles.filterInput}
            classes={{ root: styles.textFieldRoot }}
            InputLabelProps={{
              shrink: true
            }}
            format="hh:mm A"
            mask="__:__ _M"
            id="date-to"
            name="time_to"
            value={time.to}
            keyboardIcon={<AlarmSharp fontSize="small" />}
            placeholder="End time"
            InputProps={{
              disableUnderline: readOnly,
              disabled: readOnly,
              readOnly: readOnly
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      {error && <ErrorMessage message={`${error}`} />}
    </FormControl>
  );
});

export default TimeBracket;
