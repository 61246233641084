import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  FormHelperText,
  Grid,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";
import {ReactComponent as ManualAllocationIcon} from "../../../../../assets/manual-allocation.svg"
import {ReactComponent as AutoAllocationIcon} from "../../../../../assets/auto-allocation.svg"
import moment from "moment";
import {
  Item,
  StyledTitle,
  StyledTitleContainer,
  useStyles
} from "../../TripAllocation.styles";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DATE_FORMAT, DateTimeField, SelectedTrips } from "../../sharedComponents";
import _ from "lodash";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import VehiclesChart from "../Chart/VehiclesChart";
import TripsTable from "./TripsTable";
Chart.register(ChartDataLabels);


/**
 * Dashboard #1
 *
 */
const TripSelection = props => {
  const {
    trips,
    date,
    setDate = () => {},
    handleDelete = () => {},
    handlePrevStep = () => {},
    handleNextStep = () => {}
  } = props;
  const [filteredTrips, setFilteredTrips] = useState(trips);
  const [chartData, setChartData] = useState();
  const classes = useStyles();

  useEffect(() => {
    setFilteredTrips(trips);
  }, [trips]);

  const handleChartClick = segmentIdx => {
    const label =
      chartData.labels[segmentIdx] === "null"
        ? null
        : chartData.labels[segmentIdx];
    if (!_.isNull(segmentIdx)) {
      setFilteredTrips(trips.filter(t => t.vehicle_type === label));
    } else {
      setFilteredTrips(trips);
    }
  };

  /**
   * -> Sets chart data labels with vehicle summary data
   * data             label inside segments (vehicle type count)
   * labels           outer label (vehicle types)
   * backgroundColor  segment color
   */
  useEffect(() => {
    let data = [];
    let labels = [];
    let backgroundColor = [];
    if (trips.length) {
      // get vehicle types and total per type
      let groupedTrips = _.groupBy(trips, "vehicle_type");
      Object.entries(groupedTrips).forEach(([key, value]) => {
        labels.push(key);
        data.push(value.length);
      });
      
      backgroundColor = Array.apply(null, Array(data.length)).map(
        _ => "#ffb864"
      );
    }

    setChartData({
      ...chartData,
      labels: labels,
      data: data,
      backgroundColor: backgroundColor
    });
  }, [trips]);
  
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      spacing={3}
      style={{
        height: `${matchesSM ? "" : "95%"}`,
        width: "95%",
        margin: "0 auto"
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ height: `${matchesSM ? "" : "95%"}`, margin: 0 }}
      >
        <Grid item xs={12} md={4}>
          {chartData && (
            <VehicleSummary
              chartData={chartData}
              handleChartClick={handleChartClick}
            />
          )}
        </Grid>
        <Grid
          container
          item
          spacing={2}
          md={8}
          style={{ height: `${matchesSM ? "" : "100%"}` }}
        >
          <Grid item xs={12} md={7}>
            <PickupDates date={date} setDate={setDate} />
          </Grid>
          <Grid item xs={8} md={5}>
            <SelectedTrips trips={trips} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              maxHeight: `${matchesSM ? "400px" : ""}`,
              height: `${matchesSM ? "" : "calc(100% - 166px)"}`,
              width: "100%"
            }}
          >
            <TripsTable trips={filteredTrips} handleDelete={handleDelete} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent="center" gridGap={5}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.btnRounded}
            onClick={handlePrevStep}
          >
            Cancel
          </Button>
          <ProceedButton
            handleClick={handleNextStep}
            isDisabled={
              !trips.length ||
              moment(date?.from).isBefore(moment().format("lll"))
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const ProceedButton = ({ handleClick, isDisabled }) => {
  const classes = useStyles();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button 
            className={classes.btnRounded} 
            variant="contained" 
            color="primary" 
            disabled={isDisabled}
            {...bindTrigger(popupState)}
          >
            Proceed
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            marginThreshold={5}
            className={classes.popoverOverlay}
          >
            <Box p={2} display="flex" flexDirection="column" gridGap={8}>
              <ButtonBase className={classes.popoverOptions} onClick={() => handleClick({isManual: true})}>
                <Grid container style={{height: "100%"}}>
                  <Grid container item xs={9} className={classes.popoverItem} style={{ borderRight: "3px solid #dbdbdb" }}>
                    <Grid item>
                      <StyledTitle>Manual Allocation</StyledTitle>
                    </Grid>
                    <Grid item style={{textAlign: "left"}}>
                      <Typography variant="caption">Individually select a hauler for each trip</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} className={classes.popoverItem} style={{ color: "#fbb44c" }}>
                    <ManualAllocationIcon />
                  </Grid>
                </Grid>
              </ButtonBase>
              <ButtonBase className={classes.popoverOptions} onClick={() => handleClick({isManual: false})}>
                <Grid container style={{height: "100%"}}>
                  <Grid container item xs={9} className={classes.popoverItem} style={{ borderRight: "3px solid #dbdbdb" }}>
                    <Grid item>
                      <StyledTitle>Auto Allocation</StyledTitle>
                    </Grid>
                    <Grid item style={{textAlign: "left"}}>
                      <Typography variant="caption">An algorithm will automatically determine the hauler for each trip</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} className={classes.popoverItem} style={{ color: "#edd608" }}>
                    <AutoAllocationIcon />
                  </Grid>
                </Grid>
              </ButtonBase>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
};

const VehicleSummary = React.memo(props => {
  const { chartData, handleChartClick = () => {} } = props;
  const chartRef = useRef();
  const [segmentColors, setSegmentColors] = useState(
    chartData?.backgroundColor || []
  );
  const [total, setTotal] = useState(
    chartData?.data?.reduce((acc, val) => acc + val, 0) || 0
  );

  useEffect(() => {
    if (chartData) {
      setSegmentColors(chartData?.backgroundColor);
      setTotal(chartData?.data?.reduce((acc, val) => acc + val, 0));
    }
  }, [chartData]);

  const infoMsg = (
    <span>
      <strong>Click</strong> the <strong>parts of the chart</strong> to filter
      the list view with the desired vehicle type.
      <br />
      <strong>Click</strong> the <strong>total number of vehicles</strong> to
      display all the vehicles in the list view.
    </span>
  );

  return (
    <Item>
      <StyledTitleContainer>
        <StyledTitle variant="h6">Vehicle Summary</StyledTitle>
        <Tooltip title={infoMsg} placement="right-end">
          <InfoIcon color="disabled" fontSize="small" />
        </Tooltip>
      </StyledTitleContainer>
      <Typography variant="subtitle1">
        Required vehicles based on the selected pick up date range
      </Typography>

      <div
        style={{
          margin: "auto",
          marginTop: 25,
          height: 300,
          width: 450,
          maxWidth: "100%"
        }}
      >
        <VehiclesChart
          chartData={chartData}
          chartRef={chartRef}
          total={total}
          segmentColors={segmentColors}
          setSegmentColors={setSegmentColors}
          handleChartClick={handleChartClick}
        />
      </div>
    </Item>
  );
});

const PickupDates = props => {
  const { date, setDate } = props;
  const classes = useStyles();

  const infoMsg = (
    <span>Select the date range of trips you want to allocate.</span>
  );

  /**
   * Sets Pickup Date values. Handles date validation / correction
   * @param {*} d
   */
  const handleDateChanged = d => {
    let { name, value } = d;
    // date.to is set to end of day if:
    // -> from is set to date later than to
    // -> to is set to time before from
    if (_.isEqual(name, "from") && moment(value).isAfter(date.to) || _.isEqual(name, "to") && moment(value).isBefore(date.from)) {
      setDate(prev => ({
        ...prev,
        to: moment(value)
          .endOf("date")
          .format(DATE_FORMAT)
      }));
    } else {
      setDate(prev => ({
        ...prev,
        [name]: moment(value).format(DATE_FORMAT)
      }));
    }
  };

  return (
    <Item style={{ justifyContent: "center" }}>
      <StyledTitleContainer>
        <StyledTitle variant="h6">Pickup date range</StyledTitle>
        <Tooltip title={infoMsg} placement="right-end">
          <InfoIcon color="disabled" fontSize="small" />
        </Tooltip>
      </StyledTitleContainer>
      <Grid container style={{ marginTop: 2 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.datetimeLbl}>From</Typography>
            <DateTimeField
              dateTimePickerProps={{
                initialFocusedDate: moment(),
                value: date.from,
                minDate: moment().format(DATE_FORMAT),
                minDateMessage: "",
                disablePast: true,
                onChange: date =>
                  handleDateChanged({
                    name: "from",
                    value: date
                  }),
                InputProps: {
                  disableUnderline: true
                },
                TextFieldComponent: props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className={classes.datetimeField}
                  />
                )
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.datetimeLbl}>To</Typography>
            <DateTimeField
              dateTimePickerProps={{
                initialFocusedDate: moment().endOf("date"),
                value: date.to,
                minDate: date?.from || undefined,
                minDateMessage: "",
                disablePast: true,
                onChange: date =>
                  handleDateChanged({
                    name: "to",
                    value: date
                  }),
                InputProps: {
                  disableUnderline: true
                },
                TextFieldComponent: props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className={classes.datetimeField}
                  />
                )
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} spacing={0} style={{ padding: "0 10px" }}>
          <Box style={{ visibility: `${date?.from && moment(date?.from).isBefore(moment().format("lll")) ? "visible" : "hidden"}` }}>
            <FormHelperText error>
              Date and time should be later than the current date and time.
            </FormHelperText> 
          </Box>
        </Grid>
      </Grid>
    </Item>
  );
};

export default TripSelection;
