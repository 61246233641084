// import moment from "moment";
import Cookie from "js-cookie";
import _ from "lodash";
import { ADD_ACTIVITY_LOG } from "../../graphql/Mutations";
import client from "../../Client";
import { GET_USER } from "../../graphql/Queries";

// const publicIp = require("react-public-ip");

function AddActivityLog(data) {
  return new Promise(resolve => {
    client
      .mutate({
        mutation: ADD_ACTIVITY_LOG,
        variables: {
          activity_log: data
        }
      })
      .then(() => {
        resolve("success");
      });
  });
}

const AddLogs = async (
  moduleName = null,
  action = null,
  keyword = null,
  userData = null
) => {
  let data = [];
  let activity = "";
  let flag = 0;
  // const now = moment().format("YYYY-MM-DD HH:mm:ss");
  const user = JSON.parse(Cookie.get("user") || "{}"); // get the user credentials thru stored cookie

  if (_.isEmpty(user)) return;
  const completeUserInfo = await client.query({
    query: GET_USER,
    variables: {
      id: user.id
    }
  });

  // const ipv4 = await publicIp.v4() || ""; // need to get the public ip address of the current user. VPN is included
  /*
        List of actions
        search
        filter
        insert
        view
        update
        delete
        unblock
        download_table
        download_template
        upload
    */
  switch (action) {
    case "login":
      activity = `Login`;
      flag = 1;
      break;
    case "logout":
      activity = `Logout`;
      flag = 1;
      break;
    case "change_profile":
      activity = `Changed profile picture`;
      flag = 1;
      break;
    case "change_password":
      activity = `Changed password`;
      flag = 1;
      break;
    case "update_profile":
      activity = `Updated profile`;
      flag = 1;
      break;
    case "paired_device":
      activity = `GPS Device ${keyword[0].toString()} was paired to Vehicle/CV ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "unpaired_device":
      activity = `GPS Device ${keyword[0].toString()} was unpaired from Vehicle/CV ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "pair":
      activity = `Pair ${keyword[0].toString()} was paired to  ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "unpair":
      activity = `Unpair ${keyword[0].toString()} was unpaired to ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "search":
      activity = `Searched ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "filter":
      activity = `Filtered table ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "insert":
      activity = `Created the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "view":
      activity = `Viewed the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "update":
      activity = `Edited the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "change":
      activity = `Change the status of this Timesheet ${keyword[0].toString()}, ${keyword[1].toString()}, ${keyword[2].toString()}`;
      flag = 1;
      break;
    case "approve":
      activity = `Approved this Timesheet ${keyword[0].toString()}, ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "disapprove":
      activity = `Disapproved this Timesheet ${keyword[0].toString()}, ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "add_partners":
      activity = `Added the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "group_partners":
      activity = `Edited the ${moduleName.toLowerCase()}, added ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "delete_partners":
      activity = `Deleted the ${keyword
        .toString()
        .replace(/,/g, ", ")} ${moduleName.toLowerCase()}`;
      flag = 1;
      break;
    case "delete":
      activity = `Deleted the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "unblock":
      activity = `Unblocked the ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "download_table":
      activity = `Download ${moduleName.toLowerCase()} table ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "download_template":
      activity = `Download ${moduleName.toLowerCase()} template`;
      flag = 1;
      break;
    case "upload":
      activity = `Uploaded ${moduleName.toLowerCase()}`;
      flag = 1;
      break;
    case "change_truck":
      activity = `Change truck of trip ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "split_trip":
      activity = `Splitted the trip ${keyword.toString()}`;
      flag = 1;
      break;
    case "dispatch_trip":
      activity = `Dispatch the trip ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "close_trip":
      activity = `Closed the trip ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "refresh_trip":
      activity = `Refreshed the trip(s) ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "trip_dispatch":
      activity = `Manually Dispatched the trip ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "trip_complete":
      activity = `Manually Completed the trip ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "trip_close":
      activity = `Manually Closed the trip ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "view_map_fleet":
      activity = `Viewed the Map: ${keyword.toString()}`;
      flag = 1;
      break;
    case "view_trip":
      activity = `Viewed the trip ${keyword.toString()}`;
      flag = 1;
      break;
    case "view_map_trip":
      activity = `Viewed the map of the trip ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "manual_complete_trip":
      activity = `Manually completed the trip ${keyword.toString()}`;
      flag = 1;
      break;
    case "isolate_view":
      activity = `View isolate of vehicle/CV ${keyword.toString()}`;
      flag = 1;
      break;
    case "share_location":
      activity = `Share Location of vehicle/CV ${keyword.toString()}`;
      flag = 1;
      break;
    case "generate_share_location":
      activity = `Generated a share link location for vehicle/CV ${keyword.toString()}`;
      flag = 1;
      break;
    case "isolate_eta":
      activity = `Check ETA for vehicle/CV ${keyword[0].toString()} going to ${keyword[1].toString()} in Isolate`;
      flag = 1;
      break;
    case "assign":
      activity = `Assign the vehicle ${keyword[0].toString()} to trip ${keyword[1].toString()} for client ${keyword[2].toString()}`;
      flag = 1;
      break;
    case "decline":
      activity = `Decline the trip number ${keyword[0].toString()} for client ${keyword[1].toString()}`;
      flag = 1;
      break;
    case "history":
      activity = `View history of ${keyword.slice(0,1).toString()} ${keyword
        .slice(1)
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "view_vehicle":
      activity = `Clicked vehicle/CV ${keyword.toString()}`;
      flag = 1;
      break;
    case "filter_OTTA/OTSA/OTD":
      activity = `Filtered OTTA/OTSA/OTD ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "filter_demurrage":
      activity = `Filtered Demurrage ${moduleName.toLowerCase()} ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "save_filter_OTTA/OTSA/OTD":
      activity = `Saved filter ${keyword.toString()} in OTTA/OTSA/OTD`;
      flag = 1;
      break;
    case "save_filter_demurrage":
      activity = `Saved filter ${keyword.toString()} in Demurrage`;
      flag = 1;
      break;
    case "update_report":
    case "request_report":
    case "delete_report":
    case "add_scheduled_report":
    case "update_scheduled_report":
      activity = keyword;
      flag = 1;
      break;
    case "date_filter":
      activity = `Date Filter ${keyword.toString()} in Closed/cancelled`;
      flag = 1;
      break;
    case "link_vehicle":
      activity = `Shared vehicle ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "unlink_vehicle":
      activity = `Unlink shared vehicle ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "link_alert":
      activity = `Shared alert ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "unlink_alert":
      activity = `Unlink shared alert ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "link_device":
      activity = `Shared gps device ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "unlink_device":
      activity = `Unlink shared gps device ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "link_geofence":
      activity = `Shared geofence ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "view_dashboard_overview":
      activity = `Viewed ${keyword}.`;
      flag = 1;
      break;
    case "edit_dashboard_overview":
      activity = `Updated dashboard ${keyword}.`;
      flag = 1;
      break;
    case "add_dashboard_overview":
      activity = `Adedd dashboard ${keyword}.`;
      flag = 1;
      break;
    case "delete_dashboard_overview":
      activity = `Removed dashboard ${keyword}`;
      flag = 1;
      break;
    case "undo_remove_dashboard_overview":
      activity = `Undo removed dashboard ${keyword}.`;
      flag = 1;
      break;
    case "is_inter_island_update":
      activity = `Update inter island on trip ${keyword}.`;
      flag = 1;
      break;
    case "unlink_geofence":
      activity = `Unlink shared geofence ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "link_vehicle_type":
      activity = `Shared vehicle types ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "unlink_vehicle_type":
      activity = `Unlink shared vehicle types ${keyword
        .toString()
        .replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "login_attempt":
      activity = `Invalid login attempt.`;
      flag = 2;
      break;
    case "feedback":
      activity = `Submitted Feedback Message`;
      flag = 1;
      break;
    case "view_gps_alert":
      activity = `View GPS Alert for vehicle plate no ${keyword}`;
      flag = 1;
      break;
    case "geofence_sharing":
      activity = `Geofence ${keyword[0].toString()} from client ${keyword[1].toString()} has been shared to ${keyword[2].toString()}`;
      flag = 1;
      break;
    case "checklist_view":
      activity = `360 checklist viewed ${keyword.toString()}`;
      flag = 1;
      break;
    case "checklist_resolved":
      activity = `Vehicle ${keyword.toString()}`;
      flag = 1;
      break;
    case "vehicle_part_added":
      activity = `Added vehicle part ${keyword.toString()}`;
      flag = 1;
      break;
    case "vehicle_part_edited":
      activity = `Edited vehicle part ${keyword.toString()}`;
      flag = 1;
      break;
    case "vehicle_part_deleted":
      activity = `Deleted vehicle part ${keyword.toString()}`;
      flag = 1;
      break;
    case "checklist_initialized":
      activity = `Data has been successfully initialized for client ${keyword.toString()}`;
      flag = 1;
      break;
    case "view_assigned_geofences":
      activity = `Viewed the assigned geofences ${keyword}`;
      flag = 1;
      break;
    case "assign_geofence":
      activity = `Assigned the geofence ${keyword}`;
      flag = 1;
      break;
    case "edit_assigned_geofence":
      activity = `Edited the assigned geofence ${keyword}`;
      flag = 1;
      break;
    case "reorder":
      activity = `Reordered the trip ${keyword}`;
      flag = 1;
      break;
    case "filter_historical":
      activity = `Filtered historical timeline: ${keyword.toString().replace(/,/g, ", ")}`;
      flag = 1;
      break;
    case "categorized_geo":
      activity = "Create uncategorized geofence enabled";
      flag = 1;
      break;
    case "uncategorized_geo":
      activity = "Disable uncategorized geofence button";
      flag = 1;
      break;
    case "clear_notif":
      activity = "Clear Notifications";
      flag = 1;
      break;
    case "add_allocation":
      activity = `Assigned the ${keyword}`;
      flag = 1;
      break;
    case "view_allocation":
      activity = `Viewed the allocation ${keyword}`;
      flag = 1;
      break;
    default:
      flag = 0;
      break;
  }

  const userInfo = completeUserInfo?.data?.get_user ?? user;

  if (flag === 1) {
    data = {
      activity: activity,
      // remote_address: ipv4,
      module: moduleName,
      // datestamp: now,
      user: {
        id: Number(user.user_level_id),
        username: user.username
      },
      group_ids: userInfo.group_ids
    };
    AddActivityLog(data);
  } else if (flag === 2) {
    data = {
      activity: activity,
      // remote_address: ipv4,
      module: moduleName,
      // datestamp: now,
      user: {
        id: Number(userData.user_level_id),
        username: userData.username
      },
      group_ids: userData.group_ids
    };
    AddActivityLog(data);
  } else {
    // Not sure kung mag add pa nang dialogbox or console error nalang
  }
};

export default AddLogs;

/*
Usage

import the function
import AddLogs from "../../../../utils/functions/AddLogs";

call the function and pass the parameters

AddLogs(
    process.env.REACT_APP_VEHICLES_MODULE, Module name
    "trip_closed", Action
    [ 'Trip 1', 'ABC000'] Keyword
);
*/
