import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Grid,
  Checkbox,
  Divider,
  Chip
} from "@material-ui/core";
import { Event as EventIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#E96E3F !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  input: {
    padding: 0
  },
  dateFilterContainer: {
    display: "flex"
  },
  calendarIcon: {
    marginRight: 5,
    cursor: "pointer"
  },
  calendarInput: {
    display: "none"
  },
  calendarLabel: {
    fontSize: "0.85rem"
  }
});

const CloseCancel = () => {
  const classes = useStyles();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title}>CLOSED/CANCELLED</Typography>
          <div className={classes.dateFilterContainer}>
            <Typography className={classes.calendarIcon}>
              <EventIcon
                style={{ fontSize: "0.875rem" }}
                onClick={() => setOpenDatePicker(true)}
              />
            </Typography>
            <span className={classes.calendarInput}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // value={filterValues[0].dateRange.start}
                  open={openDatePicker}
                  onClose={() => {
                    setOpenDatePicker(false);
                  }}
                  disableFuture
                  showTodayButton
                  todayLabel="NOW"
                />
              </MuiPickersUtilsProvider>
            </span>
            <Typography className={classes.calendarLabel}>
              As of {moment().format("MMM DD, YYYY")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography>Location: </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={[]}
                limitTags={2}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField {...params} placeholder="All" />
                )}
                disableClearable
                value={[]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            style={{ padding: 50 }}
            justifyContent="center"
          >
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Closed</Typography>
                <Typography variant="h4" align="center">
                  12
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      3
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      2
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Cancelled</Typography>
                <Typography variant="h4" align="center">
                  13
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      3
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      2
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CloseCancel;
