/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Badge,
  Tooltip,
  IconButton,
  Checkbox,
  Box,
  Avatar,
  Button
} from "@material-ui/core";
import moment from "moment";
import {
  Assignment,
  Timer,
  TripOrigin,
  Visibility,
  History,
  BuildRounded as UnderRepairIcon,
  SettingsApplications as Settings
} from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";
import _, { isNull } from "lodash";
import { gpsStatus } from "../../../../../components/Utils";
import temp from "../../../../../assets/temp.svg";
import Battery from "../../../../Battery";
import not_in_use from "../../../../../assets/Not_in_Use.png";
import demo_data from "./Table.data";
import Focus, { useInfoBubbleStyles } from "../../../common/Focus";

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  root: {
    width: "100%"
    // marginTop: 64
  },
  tableCell: {
    position: "relative",
    padding: "8px !important"
  },
  tableIconCell: {
    position: "relative",
    padding: 0,
    height: 62,
    display: "flex"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  button: {
    borderRadius: "32px !important",
    color: "white !important",
    backgroundColor: "#EB825C !important"
  },
  buttonDisabled: {
    borderRadius: "32px !important",
    color: "white !important",
    backgroundColor: "#F3C0AE !important",
    cursor: "default !important",
    pointerEvents: "none !important"
  },
  container: {
    maxHeight: "calc(100vh - 128px)"
  },
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500]
  },
  undeRepairSize: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: "white"
  },
  multipleSelect: {
    backgroundColor: "#EAEAEA",
    minHeight: "45px !important",
    height: 45
  },
  title: {
    flex: "1 1 25%",
    color: "black"
    // color: "#000000"
  },
  iconActive: {
    color: theme.palette.primary.main
  },
  iconInactive: {
    color: "#999999"
  },
  modal_confirm_button: {
    float: "right",
    background: theme.palette.primary.main,
    color: "white",
    border: "none",
    position: "absolute",
    right: "10px",
    padding: "5px 25px",
    borderRadius: "100px",
    cursor: "pointer",
    fontWeight: "bold"
  }
}));

const headCells = [
  { id: "trip_count", numeric: false, disablePadding: true, label: "" },
  {
    id: "devices.device_config.underrepair",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: false
  },
  { id: "clients.name", numeric: false, disablePadding: true, label: "Hauler" },
  {
    id: "vehicle_info.plateno",
    numeric: true,
    disablePadding: false,
    label: "Plate/CV Number"
  },
  {
    id: "vehicle_types.name",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type"
  },
  { id: "speed", numeric: true, disablePadding: false, label: "" },
  {
    id: "lastlocation",
    numeric: false,
    disablePadding: true,
    label: "Location"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Action" }
];

const EnhancedTableHead = props => {
  const { classes, orderBy, order, handleSorting } = props;
  return (
    <Focus step={3} component={TableHead}>
      {/* <TableHead> */}
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary" />
        </TableCell>
        {headCells.map(headCell => (
          <Focus
            component={TableCell}
            padding={headCell.disablePadding ? "none" : "normal"}
            key={headCell.id}
            className={classes.tableCell}
            step={4}
            showInfoBubble={false}
            on={headCell.label === "Action"}
          >
            {headCell.label !== "" && headCell.label !== "Action" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </Focus>
        ))}
      </TableRow>
      {/* </TableHead> */}
    </Focus>
  );
};

const TripLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Badge variant="dot" color="secondary">
            <Assignment color="primary" fontSize="small" />
          </Badge>
        </span>
        <span>Booked</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Assignment fontSize="small" />
        </span>
        <span>Available</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <UnderRepairIcon
            htmlColor="#fff"
            className={classes.underrepairIcon}
          />
        </span>
        <span>Underrepair</span>
      </li>
      <li className={classes.legend}>
        <Avatar
          src={not_in_use}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 30,
            width: 30,
            left: 1
          }}
        />
        <span>Not in use</span>
      </li>
    </ul>
  );
};

const ActionLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Visibility fontSize="small" />
        </span>
        <span>Isolate</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <History fontSize="small" />
        </span>
        <span>View History</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Settings fontSize="small" />
        </span>
        <span>Vehicle Settings</span>
      </li>
    </ul>
  );
};

const MonitoringTable = props => {
  const classes = useStyles();
  const {
    vehicles = demo_data,
    history,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    filterValues,
    vars
  } = props;

  const redirectTo = (r, params = {}) => {
    history.push({
      pathname: r,
      state: {
        params: params,
        filterValues: filterValues,
        vars: vars,
        historyFilterVal: params.historyFilterVal
      }
    });
  };

  return (
    <div className={classes.root}>
      <TableContainer
        component={Paper}
        elevation={2}
        className={classes.container}
      >
        <Table stickyHeader>
          <EnhancedTableHead
            classes={classes}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          <TableBody>
            {vehicles.length ? (
              vehicles.map((vehicle, index) => {
                const title =
                  gpsStatus(vehicle.gps_status).label === "No GPS" ? (
                    <p className={classes.tooltipTitle}>No GPS</p>
                  ) : (
                    <Fragment>
                      <p
                        className={classes.tooltipTitle}
                        style={{ fontWeight: "bold" }}
                      >
                        {gpsStatus(vehicle.gps_status).label}
                      </p>
                      <p
                        className={classes.tooltipTitle}
                      >{`Last Report: ${moment(vehicle.reportstamp).format(
                        "lll"
                      )}`}</p>
                    </Fragment>
                  );
                return (
                  <Focus
                    step={3}
                    on={index === 0}
                    component={TableRow}
                    // showInfoBubble={true}
                    // infoBubbleProps={{ position: "bottom" }}
                  >
                    {/* <TableRow key={vehicle.id}> */}
                    <TableCell>
                      <Checkbox color="primary" />
                    </TableCell>
                    <TableCell align="center" className={classes.tableIconCell}>
                      <Focus
                        step={5}
                        on={index < 2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 5,
                          width: "35px",
                          justifyContent: "center"
                        }}
                        showInfoBubble={false}
                      >
                        <Badge
                          variant="dot"
                          color="secondary"
                          invisible={index !== 0 || index % 2 !== 0}
                        >
                          <Assignment
                            style={{
                              cursor: vehicle.trip_count.length > 0 && "hand"
                            }}
                            className={
                              index % 2 === 0 && index !== 2
                                ? classes.iconActive
                                : classes.iconInactive
                            }
                          />
                        </Badge>
                      </Focus>
                      {vehicle.under_repair && (
                        <Focus
                          step={5}
                          // on={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5,
                            width: 35,
                            justifyContent: "center"
                          }}
                          infoBubbleProps={{
                            // position: "right",
                            title: "Status",
                            description:
                              "Indicates the status of the fleet if it is available, booked, Under repair and/or not in use.",
                            body: <TripLegends />
                          }}
                        >
                          <Avatar className={classes.avatarSize}>
                            <UnderRepairIcon
                              className={classes.undeRepairSize}
                            />
                          </Avatar>
                        </Focus>
                      )}
                      {vehicle.not_in_use && (
                        <Focus
                          step={5}
                          // on={false}
                          // position="top"
                          showInfoBubble={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5
                          }}
                        >
                          <Avatar
                            src={not_in_use}
                            style={{ height: 35, width: 35 }}
                          />
                        </Focus>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>{vehicle.hauler}</TableCell>
                    <TableCell>
                      <Tooltip title={title}>
                        <TripOrigin
                          style={{
                            fontSize: 12,
                            color: gpsStatus(vehicle.gps_status).color
                          }}
                        />
                      </Tooltip>
                      {vehicle.plateno}
                    </TableCell>
                    <Focus
                      step={3}
                      on={index === 0}
                      component={TableCell}
                      infoBubbleProps={{
                        position: "bottom",
                        title: "Data Table",
                        description:
                          "Displays vehicle's information location and its current status"
                      }}
                    >
                      {vehicle.vehicle_type}
                    </Focus>
                    <TableCell>
                      <Tooltip title="Speed">
                        <Timer style={{ fontSize: 12 }} />
                      </Tooltip>
                      {gpsStatus(vehicle.gps_status).label.toLowerCase() !==
                        "active gps" &&
                      gpsStatus(vehicle.gps_status).label.toLowerCase() !==
                        "delayed reporting"
                        ? `-   `
                        : `${vehicle.speed} kph   `}
                      <Battery
                        vehicleType={vehicle.vehicle_type}
                        deviceTypeId={vehicle.device_type_id}
                        batteryLevel={vehicle.battery_level}
                        tooltipTitle={
                          <>
                            <p
                              className={classes.tooltipTitle}
                              style={{ fontWeight: "bold" }}
                            >{`Battery Life: ${
                              !isNull(vehicle.battery_level)
                                ? vehicle.battery_level
                                : `-`
                            }%`}</p>
                            <p className={classes.tooltipTitle}>
                              {`As of: ${moment(vehicle.reportstamp).format(
                                "lll"
                              )}
            `}
                            </p>
                          </>
                        }
                        showText
                      />
                      {vehicle.vehicle_type?.toLowerCase().trim() !==
                        "container van" && (
                        <Fragment>
                          <Tooltip title="Temperature">
                            <img src={temp} alt="" width={12} height={12} />
                          </Tooltip>
                          {vehicle.hasTempSensor ? (
                            <span>{vehicle.temperature || `-`} &#176;C</span>
                          ) : (
                            `-`
                          )}
                        </Fragment>
                      )}
                    </TableCell>
                    <TableCell>
                      <Fragment>
                        <Tooltip title={vehicle.lastlocation || ""}>
                          <div
                            style={{
                              width: "40ch",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <Box
                              component="div"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              bgcolor="background.paper"
                            >
                              <span>{vehicle.lastlocation}</span>
                            </Box>
                          </div>
                        </Tooltip>
                      </Fragment>
                    </TableCell>
                    <Focus
                      component={TableCell}
                      step={4}
                      on={index === 0}
                      infoBubbleProps={{
                        position: "left",
                        title: "Action",
                        description:
                          "Shows available actions to be executed on a vehicle",
                        body: <ActionLegends />
                      }}
                    >
                      <Tooltip title="Isolate">
                        <span>
                          <IconButton>
                            <Visibility />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="View history">
                        <span>
                          <IconButton>
                            <History />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Vehicle Settings">
                        <span>
                          <IconButton>
                            <Settings />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Focus>
                    {/* </TableRow> */}
                  </Focus>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={20}>
                  No data to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MonitoringTable;
