/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { HelpRounded } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import Swal from "sweetalert2";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../Header";
import driverStyles from "./DriverInfo.style";
import validate from "../../../../utils/validation";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import PairDriverVehicle from "../../../../utils/modals/PairDriverVehicle";

import {
  ADD_DRIVER_INFO,
  EDIT_DRIVER_VEHICLE
} from "../../../../graphql/Mutations";
import useUserContext from "../../../../context/User/useUserContext";
import redirectToTable from "../../../../utils/redirect";
import { GET_USERS } from "../../../../graphql/Queries";
import AddLogs from "../../../../utils/functions/AddLogs";
import SearchContext from "../../../../context/SearchContext";

const AddDriverInfo = () => {
  const classes = driverStyles();
  const user = useUserContext();
  const history = useHistory();
  const contextData = useContext(SearchContext)
  const {searchKeyword, setSearchKeyword} = contextData

  const [state, setState] = useState({
    employee: "",
    driver: "",
    contact: "",
    pairVehicle: "",
    supervisor: "",
    license: "",
    licenseExp: moment().format("MM-DD-YYYY"),
    expirationNotif: ""
  });
  const [error, setError] = useState({
    employee: "",
    driver: "",
    contact: "",
    pairVehicle: "",
    supervisor: "",
    license: "",
    licenseExp: "",
    expirationNotif: ""
  });
  const [initialState] = useState(_.cloneDeep(state));
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [save, setSave] = useState({ saved: false });
  const [vehicleModal, setVehicleModal] = useState();
  const [forceRerender, setForceRerender] = useState(false);

  // query for dropdown supervisor
  const { data: getUsers } = useQuery(GET_USERS, {
    variables: {
      condition: [
        {
          field: "user_levels.name",
          value: "Supervisor"
        }
      ]
    }
  });

  // mutation for pairing/unpairing (edit_vehicle)
  const [editDriverInfo] = useMutation(EDIT_DRIVER_VEHICLE, {
    onCompleted: () => {
      if (state.pairVehicle)
        AddLogs("Admin - Driver Info", "pair", [
          state.driver,
          state.pairVehicle.plateno
        ]);
    }
  });
  // driver info mutation
  const [addDriverInfo] = useMutation(ADD_DRIVER_INFO, {
    onCompleted: data => {
      if (data.add_personnel.success) {
        AddLogs("Admin - Driver Info", "insert", state.driver);
        Swal.fire({
          title: "Saved",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
          onCLose: () => {
            // redirect to table after saving and pass the save filter values
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_DRIVER_INFO_MODULE,
                  vars: history?.location?.state?.vars
                }
              }
            });
            // redirectToTable(history, process.env.REACT_APP_DRIVER_INFO_MODULE);
          }
        }).then(result => {
          // redirect to table after saving and pass the save filter values
          if (result.dismiss === Swal.DismissReason.timer) {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_DRIVER_INFO_MODULE,
                  vars: history?.location?.state?.vars
                }
              }
            });
            // redirectToTable(history, process.env.REACT_APP_DRIVER_INFO_MODULE);
          }
        });

        // pair driver to vehilce
        if (state.pairVehicle) {
          editDriverInfo({
            variables: {
              id: state.pairVehicle.id,
              vehicle: {
                personnel_id: data.add_personnel.id,
                paired: +process.env.REACT_APP_STATUS_DRIVER_PAIRED
              }
            }
          });
        }
      } else {
        const tempError = {};
        data.add_personnel.error.map(error => {
          Object.assign(tempError, { [error.field]: error.message });
        });
        setError({ ...error, ...tempError });
      }
    }
  });

  // validating text field by their name
  const check = field => {
    if (!error[field]) {
      if (field === "employee") {
        return _.isEmpty(state.employee) ? "This field is required" : "";
      }
      if (field === "contact") {
        return _.isEmpty(state.contact) ? "This field is required" : "";
      }
      if (field === "pairVehicle") {
        return error[field];
      }
      if (field === "supervisor") {
        return error[field];
      }
      if (field === "driver") {
        return _.isEmpty(state.driver) ? "This field is required" : "";
      }
      if (field === "license") {
        return _.isEmpty(state.license) ? "This field is required" : "";
      }
      if (field === "expirationNotif") {
        return _.isEmpty(state.expirationNotif) ? "This field is required" : "";
      }
      return state[field] === "" || state[field] === -1
        ? "This field is required"
        : "";
    }
    return error[field];
  };

  // set error messages under textdileds
  const validateFields = () => {
    const keys = Object.keys(error);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setError(Object.assign(error, ...temp));
    return keys.every(element => error[element] === "");
  };

  // modal for saving datas
  const handleOpenSave = name => {
    if (name === "saved") {
      if (validateFields()) setSave({ [name]: true });
      else setForceRerender(!forceRerender);
    } else {
      setSave({ saved: true });
    }
  };

  // check if has a value a display error messages
  const handleBlur = e => {
    const { name, value } = e.target;
    let errorMsg = "";

    switch (name) {
      case "employee":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (
          !validate("alphanumeric_with", value.trim(), { chars: "-" })
        ) {
          errorMsg = "Alphanumeric characters only";
        } else if (e.target.value.length > 20) {
          errorMsg = "Maximum of 20 characters only";
        }
        break;
      case "driver":
        if (!validate("alphanumeric_with", value.trim(), { chars: " ," })) {
          errorMsg = "Alphanumeric characters only";
        } else if (value.trim() === "") {
          errorMsg = "This field is required";
        }
        break;
      case "contact":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (!validate("mobile_number_ph", value.trim())) {
          errorMsg = "Invalid mobile number";
        }
        break;
      case "license":
        if (value.trim() === "") {
          errorMsg = "This field is required";
        } else if (
          !validate("alphanumeric_with", value.trim(), { chars: "-" })
        ) {
          errorMsg = "Alphanumeric characters only";
        }
        break;
      case "expirationNotif":
        if (value.trim() === "" || value.trim() <= 0) {
          errorMsg = "This field is required";
        } else if (+value > 90) {
          errorMsg = "Minimum of 1 maximum of 90 days only";
        }
        break;
      default:
        break;
    }

    setError({ ...error, [name]: errorMsg });
  };

  // updating state when changing
  const handleChange = e => {
    const { name, value } = e.target;
    const errors = "";

    setError({ ...error, [name]: errors });
    setState({ ...state, [name]: value });
  };

  // formatting selected date MM/DD/YYYY
  const handleDate = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  // modal for selecting vehicle
  const openVehicleModal = () => {
    setVehicleModal(true);
  };

  // closide modal for selecting vehicle
  function closeDriverModal() {
    setVehicleModal(false);
  }

  // mutation of driver info
  const handleSubmit = e => {
    e.preventDefault();

    addDriverInfo({
      variables: {
        employeeid: state.employee,
        name: state.driver,
        mobileno: state.contact,
        supervisor: state.supervisor,
        designation: "driver",
        group_ids: [...user.client.group_ids],
        client_id: +user.client.id,
        driver_info: {
          license_number: state.license,
          license_expiration: state.licenseExp,
          license_exp_notif_day: +state.expirationNotif,
          status_code_id: 0
        }
      }
    });
  };

  React.useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
    });
  }, []);

  return (
    <div className={classes.root}>
      <Header
        process="Add"
        moduleName={process.env.REACT_APP_DRIVER_INFO_MODULE}
        redirect={redirect}
        setRedirect={setRedirect}
        setDiscard={setDiscard}
        isDataNotChanged={_.isEqual(state, initialState)}
        history={history?.location?.state}
      />
      <Paper variant="outlined" className={classes.container}>
        <div className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                All fields with <span style={{ color: "red" }}> * </span> are
                required.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Driver Information</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    // fullWidth
                    required
                    className={classes.textfields}
                    name="employee"
                    label="Employee ID"
                    placeholder="Enter employee ID"
                    value={state.employee}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.employee && (
                    <Alert severity="error" className={classes.alert}>
                      {error.employee}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    className={classes.textfields}
                    name="driver"
                    label="Driver Name"
                    placeholder="Enter driver name"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.driver && (
                    <Alert severity="error" className={classes.alert}>
                      {error.driver}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    className={classes.textfields}
                    name="contact"
                    label="Contact Number"
                    placeholder="Enter contact number"
                    value={state.contact}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onChange={e => handleChange(e)}
                    onBlur={e => {
                      e.persist();
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                      setState(prev => ({
                        ...prev,
                        contact: beginWith63
                      }));
                      e.target.value = beginWith63;
                      handleBlur(e);
                    }}
                  />
                  {error.contact && (
                    <Alert severity="error" className={classes.alert}>
                      {error.contact}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.unpairBox}>
                    <TextField
                      readOnly
                      className={classes.textfields}
                      name="pairVehicle"
                      label="Vehicle"
                      placeholder="Select Vehicle"
                      value={state.pairVehicle ? state.pairVehicle.plateno : ""}
                      onClick={() => openVehicleModal()}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "black",
                          fontWeight: "bold"
                        }
                      }}
                    />
                    <IconButton
                      className={classes.unpairIcon}
                      disabled={!state?.pairVehicle?.plateno}
                      onClick={() => {
                        setState({ ...state, pairVehicle: null });
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 1024 1024"
                        fill={state?.pairVehicle?.plateno ? "#000000" : "none"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
                          strokeWidth="8"
                          stroke={
                            state?.pairVehicle?.plateno ? "#757575" : "#000000"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "13px"
                    }}
                  >
                    Supervisor
                  </InputLabel>
                  <Select
                    displayEmpty
                    fullWidth
                    name="supervisor"
                    placeholder="Select Supervisor"
                    className={classes.textfields}
                    value={state.supervisor}
                    onChange={e => handleChange(e)}
                  >
                    <MenuItem value="" disabled>
                      Select Supervisor
                    </MenuItem>
                    {getUsers &&
                      getUsers.get_users.users.map(sv => (
                        <MenuItem value={`${sv.first_name}  ${sv.last_name}`}>
                          {`${sv.first_name}  ${sv.last_name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    // fullWidth
                    required
                    className={classes.textfields}
                    name="license"
                    label="License No."
                    placeholder="Enter license no"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        fontWeight: "bold"
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                  />
                  {error.license && (
                    <Alert severity="error" className={classes.alert}>
                      {error.license}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      className={classes.textfields}
                      name="licenseExp"
                      openTo="date"
                      format="MM/dd/yyyy"
                      label="License Expiration Date"
                      views={["year", "month", "date"]}
                      value={state.licenseExp}
                      onChange={e => {
                        handleDate(
                          moment(e).format("MMMM/DD/YYYY"),
                          "licenseExp"
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "black",
                          fontWeight: "bold"
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end"
                    }}
                  >
                    <InputLabel
                      required
                      classes={{ root: classes.mediumTitle }}
                    >
                      Expiration Notification
                    </InputLabel>
                    <Tooltip
                      classes={{ tooltip: classes.toolTip }}
                      placement="top-start"
                      title="A notification to inform the driver's due date for license renewal"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end"
                    }}
                  >
                    <TextField
                      required
                      value={state.expirationNotif}
                      placeholder="0"
                      type="number"
                      name="expirationNotif"
                      onBlur={handleBlur}
                      onChange={e => handleChange(e)}
                      style={{
                        width: "35px"
                      }}
                    />
                    <Typography variant="body2">
                      Days before license expiration
                    </Typography>
                  </div>
                  {error.expirationNotif && (
                    <Alert severity="error" className={classes.alert}>
                      {error.expirationNotif}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handleOpenSave("saved")}
              >
                Save Details
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <ConfirmationDialog
        toggle={save.saved}
        title="Save"
        content="Are you sure you want to save the changes made?"
        close={() => setSave({ saved: false })}
        fn={e => handleSubmit(e)}
      />
      <ConfirmationDialog
        toggle={discard}
        close={() => setDiscard(false)}
        fn={() =>
          Swal.fire({
            title: "Discarded",
            icon: "Success",
            showConfirmButton: false,
            timer: 3000,
            onCLose: () => {
              setRedirect(true);
            }
          })
        }
      />
      <PairDriverVehicle
        toggle={vehicleModal}
        closeVehicleModal={closeDriverModal}
        setVehicleInfo={e => {
          setState({ ...state, pairVehicle: e });
          closeDriverModal();
        }}
      />
    </div>
  );
};

export default AddDriverInfo;
