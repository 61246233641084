/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton
} from "@material-ui/core";
import { ArrowDropDownRounded as ExpandMoreIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";

// Components
import NoChildAccessRight from "./NoChildAccessRight";
// Styles
import accordionStyles from "./main-styles";
// Hooks
import useAccordion from "./hooks/useAccordion";
import useAccessObjects from "./hooks/useAccessObjects";

// export const GET_ADMIN_CLIENT = gql`
//   query getAdminClient($id: ID!) {
//     get_client(id: $id) {
//       id
//       name
//       remarks
//       category
//       user_count
//       device_count
//       geofence_count
//       access_end_date
//       data_admin
//       acl_module_id
//       acl_modules {
//         id
//         resources
//         view
//         edit
//       }
//       client_preference {
//         id
//         client_id
//         welcome_logo
//         welcome_text
//         header_logo
//         header_text
//         primary_color
//       }
//       group_ids
//       group_names
//       sms_sending_type
//     }
//   }
// `;

const MainAccordion = React.memo(props => {
  const {
    updateSelectedModules,
    default_access = null,
    disabled = false
  } = props;
  const styles = accordionStyles(props);
  const {
    status,
    initializeAccessTree,
    loading,
    data: accessRightsTree,
    toggleAllAcessRights,
    toggleCheckbox
  } = useAccessObjects(default_access);
  const { openIndexes, toggleIndex, closeAll } = useAccordion();
  useEffect(() => {
    if (disabled && !status.initialLoad) {
      initializeAccessTree();
      closeAll();
    }
  }, [disabled]);

  useEffect(() => {
    if (accessRightsTree) {
      let isModuleEmpty = true;
      const findChildren = (accum, current) => {
        accum.push(current.parent);

        if (isModuleEmpty) {
          isModuleEmpty =
            isModuleEmpty &&
            !(current.parent.permission.view || current.parent.permission.edit);
        }

        if (current.child.length === 0) {
          return accum;
        }
        return current.child.reduce(findChildren, accum);
      };

      const flatAccessRights = accessRightsTree.reduce(findChildren, []);
      if (!status.initialLoad)
        updateSelectedModules(flatAccessRights, isModuleEmpty);
    }
  }, [accessRightsTree]);

  const collapseTree = tree => {
    const {
      parent: { id, name, parent_id },
      child
    } = tree;
    if (child.length === 0) {
      return (
        <NoChildAccessRight
          key={id}
          disabled={disabled}
          accessRight={tree.parent}
          toggleAllAcessRights={toggleAllAcessRights}
          toggleCheckbox={toggleCheckbox}
        />
      );
    }

    const findChildren = (accum, current) => {
      const { view, edit } = current.parent.permission;
      if (current.child.length === 0) {
        return {
          isChecked: accum.isChecked && view && edit,
          indeterminate: accum.indeterminate || view || edit
        };
      }

      return current.child.reduce(findChildren, accum);
    };
    const { isChecked, indeterminate } = child.reduce(findChildren, {
      isChecked: true,
      indeterminate: false
    });

    return (
      <ExpansionPanel
        // TransitionProps={{ unmountOnExit: true }}
        key={id}
        // classes={{ root: styles.root }}
        className={styles.root}
        onChange={() => toggleIndex(id)}
        expanded={openIndexes.includes(id)}
      >
        <ExpansionPanelSummary
          classes={{ root: styles.summaryRoot }}
          expandIcon={
            <IconButton size="small" disabled={disabled}>
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          }
        >
          <div className={styles.summaryLabelWrapper}>
            <FormControlLabel
              classes={{ root: styles.summaryLabelRoot }}
              control={
                <Checkbox
                  disabled={disabled}
                  indeterminate={isChecked ? false : indeterminate}
                  checked={isChecked}
                  classes={{ root: styles.checkboxRoot }}
                  value={id}
                  onChange={(_e, newChecked) => {
                    toggleAllAcessRights({ parent: tree.parent, newChecked });
                  }}
                  color="primary"
                />
              }
            />
            <Typography classes={{ root: styles.typographyRoot }}>
              {name}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: styles.detailsRoot }}>
          {child.map(c => collapseTree(c))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <Skeleton width={150} />
        <Skeleton width="65%" />
        <Skeleton width={150} />
        <Skeleton width="65%" />
        <Skeleton width="65%" />
        <Skeleton width={150} />
        <Skeleton width="65%" />
      </div>
    );
  }

  const sortedTree = accessRightsTree.sort((a, b) => {
    if (a.parent.name < b.parent.name) return -1;
    return 1;
  });

  return (
    <div className={styles.container}>
      {sortedTree?.map(tree => collapseTree(tree))}
    </div>
  );
});

export default MainAccordion;
