import { makeStyles } from "@material-ui/core";

export default makeStyles({
  selected: {
    width: "100%",
    height: "100%",
    borderStyle: "solid",
    borderRadius: "10px",
    border: "2px",
    borderColor: "#F49400"
  },
  unselected: {
    width: "100%",
    height: "100%",
    borderStyle: "solid",
    borderRadius: "10px",
    border: "2px",
    borderColor: "transparent"
  },
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    boxShadow: "10px 10px 30px rgba(0,0,0,0.1)",
    borderRadius: 8,
    padding: 8,
    boxSizing: "border-box",
    transition: "all 250ms ease-in",
    transformOrigin: "center",
    opacity: 1,
    "&:hover": {
      boxShadow: "10px 10px 30px #00000025",
      transform: "translateY(-1px)",
      opacity: 0.8
    },
    backgroundColor: "#FFFFFF",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: 30
  },
  map_container: {
    position: "relative",
    height: "calc(100% - 30px)",
    width: "100%"
  },
  close_btn: { marginRight: "-4px" },
  close_icon: { fontSize: 18 }
});
