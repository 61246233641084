import React, { useState, useRef, useEffect } from "react";
// this component is use for detecting outside clicked on custom dropdown. 
// this will help users to create a dropdown that is not listed on the react material ui. 
export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    !initialIsVisible
  );
  const ref = useRef(null);
  // function that handles click outside the dropdown component. 
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
