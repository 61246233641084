/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  IconButton,
  Grid
} from "@material-ui/core";
import {
  Attachment as AttachmentIcon,
  Share as ShareIcon
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import ListView from "./ListView";
import DraggableList from "./DraggableList";
import { statusColor } from "../../Utils";
import SharelinkDialog from "../../../utils/modals/SharelinkDialog";
import { GET_SHAREABLE_LINK } from "../../../graphql/Mutations";
import { AttachmentsDialog } from "./Dialogs";
import MapView from "./MapView";
import HistorySelector from "../../Monitoring/HistorySelector";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    //minHeight: 510,
    height: "calc(100vh - 180px)",
    margin: "10px 10px 10px 0",
    borderRadius: 12,
    boxShadow: "none",
    overflowY: "auto",
    position: "relative"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  expandable: {
    verticalAlign: "top"
  },
  subCell: {
    display: "block",
    marginTop: "20px"
  },
  button: {
    margin: 4,
    borderRadius: 32,
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`
  },
  titleText: {
    fontSize: 15,
    color: theme.palette.primary.dark,
    fontWeight: "600",
    fontFamily: "Nunito"
  },
  footerTitle: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontWeight: "700",
    fontFamily: "Nunito"
  },
  footerDescription: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    fontFamily: "Nunito",
    margin: "0 3px 0 6px"
  }
}));

const headCells = [
  {
    id: "row_num",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "so_number",
    numeric: false,
    disablePadding: false,
    label: "SO number"
  },
  {
    id: "stops",
    numeric: false,
    disablePadding: false,
    label: "Stops"
  },
  {
    id: "plan_arrival",
    numeric: false,
    disablePadding: false,
    label: "Plan Arrival Time"
  },
  {
    id: "actual_arrival",
    numeric: false,
    disablePadding: false,
    label: "Actual Arrival Time"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  { id: "action", numeric: false, disablePadding: false, label: "" }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: 13, fontWeight: "700", fontFamily: "Nunito" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const DetailsTable = props => {
  const classes = useStyles();
  const {
    rawTripData,
    parsedTripData,
    isEdit,
    setParsedTripData,
    manualCompleteMode,
    mapView,
    mode,
    setMode,
    getGroupIds,
    open,
    handleOpen,
    handleClose,
    stops,
    setStops
  } = props;

  const [generatedLink, setGeneratedLink] = useState("");
  const [getShareableLink] = useMutation(GET_SHAREABLE_LINK, {
    onCompleted(data) {
      setGeneratedLink(data.get_shareable_link);
    }
  });

  useEffect(() => {
    if (mapView && Object.entries(parsedTripData).length > 0) {
      setMode("map");
    }
  }, [mapView, parsedTripData]);

  const renderScreen = () => {
    let screen = "";
    switch (mode) {
      case "list":
        screen = (
          <ListView
            rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            isEdit={isEdit}
            setParsedTripData={setParsedTripData}
            manualCompleteMode={manualCompleteMode}
          />
        );
        break;
      case "map":
        screen = (
          <MapView
            rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            getGroupIds={getGroupIds}
          />
        );
        break;
      case "reorder":
        screen = (
          <DraggableList
            rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            isEdit={isEdit}
            stops={stops}
            setStops={setStops}
            setParsedTripData={setParsedTripData}
          />
        );
        break;
      default:
        break;
    }
    return screen;
  };

  const handleGenerate = () => {
    const {
      trip_number,
      group_ids,
      client_name,
      pickups,
      dispatch_time
    } = parsedTripData;

    const plannedArrival = moment(pickups[0].arrival).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const dispatchTime = moment(dispatch_time).format("YYYY-MM-DD HH:mm:ss");
    const start = dispatchTime > plannedArrival ? plannedArrival : dispatchTime;

    getShareableLink({
      variables: {
        dateRange: {
          start: start,
          end: moment(start)
            .add(30, "days")
            .format("YYYY-MM-DD HH:mm:ss")
        },
        tripNumber: trip_number,
        group_ids,
        client_name
      }
    });
  };

  const computeStartTime = time => {
    const { actual_arrival, manual_arrival, arrival } = time;
    const defaultArrivalVal = "1111-11-11 00:00:00";

    const isManArrDef = manual_arrival === defaultArrivalVal;
    const isActArrDef = actual_arrival === defaultArrivalVal;
    const isArrDef = arrival === defaultArrivalVal;

    if (manual_arrival && !isManArrDef) {
      return manual_arrival;
    } else if (actual_arrival && !isActArrDef) {
      return actual_arrival;
    } else if (arrival && !isArrDef) {
      return arrival;
    } else {
      return moment().format("YYYY-MM-DD hh:mm:ss");
    };
  };

  const getDateRange = () => {
    if (_.isEmpty(parsedTripData)) return null;
    // Get start date
    // Conditions are based on fleet history when trip number is selected in HistorySelector modal
    // const {
    //   actual_arrival,
    //   manual_arrival,
    //   arrival: plannedArrival
    // } = parsedTripData.pickups[0];
    // const pickup_arrival = moment(
    //   manual_arrival || actual_arrival || plannedArrival
    // ).format("YYYY-MM-DD HH:mm:ss");

    const pickup_arrival = computeStartTime(parsedTripData.pickups[0]);

    const dispatch_time = moment(parsedTripData.dispatch_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const start =
      dispatch_time > pickup_arrival ? pickup_arrival : dispatch_time;

    // Get end date
    let end;
    const isCompleted =
      +process.env.REACT_APP_REPORT_STATUS_COMPLETED ===
      +parsedTripData.status_code_id;

    if (isCompleted) {
      end = moment(
        parsedTripData.dropoffs[parsedTripData.dropoffs.length - 1]
          .actual_departure
      );
    } else if (
      [
        +process.env.REACT_APP_STATUS_TRIP_CLOSED,
        +process.env.REACT_APP_STATUS_CANCEL_ID
      ].includes(+parsedTripData.status_code_id)
    ) {
      // Get latest activity
      const tempEndDate = [
        ...parsedTripData.pickups,
        ...parsedTripData.dropoffs
      ].reduce((accum, current) => {
        const {
          arrival,
          actual_arrival,
          actual_departure,
          manual_arrival,
          manual_departure,
          departure
        } = current;

        return _.max([
          accum,
          new Date(arrival),
          new Date(actual_arrival),
          new Date(actual_departure),
          new Date(manual_arrival),
          new Date(manual_departure),
          new Date(departure)
        ]);
      }, "");

      // Limit date range to 14days
      const dateDiff = moment(tempEndDate).diff(moment(start), "days");
      end = dateDiff > 13 ? moment(start).add(13, "days") : moment(tempEndDate);
    } else {
      // End Date: 14 days from start date
      end = _.min([new Date(), moment(start).add(13, "days")]);
    }

    return {
      start,
      end: moment(end).format("YYYY-MM-DD HH:mm:ss"),
      tripNumber: parsedTripData.trip_number
    };
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          style={{
            alignItems: "center",
            borderBottom: "1px solid rgba(209, 209, 209, 0.58)",
            padding: 12
          }}
        >
          <Grid item xs={6} style={{ padding: "0 8px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Typography className={classes.titleText} id="tableTitle">
                ITINERARY
              </Typography>
              {/* <IconButton
                size="small"
                onClick={() => handleOpen("attachments")}
              >
                <AttachmentIcon style={{ fontSize: 18, color: "#c0c0c0" }} />
              </IconButton>
              <IconButton size="small" onClick={() => handleOpen("sharelink")}>
                <ShareIcon style={{ fontSize: 18, color: "#c0c0c0" }} />
              </IconButton> */}
            </div>
          </Grid>
        </Grid>
        {Object.entries(parsedTripData).length > 0 && renderScreen()}
      </Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 12,
          position: "sticky",
          bottom: 1,
          width: "95%"
        }}
      >
        <div>
          <Typography component="span" className={classes.footerTitle}>
            Stops:
          </Typography>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: "#8800A0",
              marginLeft: 20
            }}
          />
          <span className={classes.footerDescription}> Pick Up </span>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: "#1E80F0"
            }}
          />
          <span className={classes.footerDescription}> Drop Off </span>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(0)
            }}
          />
          <span className={classes.footerDescription}> Others </span>
        </div>
        <div>
          <Typography component="span" className={classes.footerTitle}>
            Status:
          </Typography>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(0),
              marginLeft: 20
            }}
          />
          <span className={classes.footerDescription}> Not yet arrived </span>
          &nbsp;
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(15)
            }}
          />
          <span className={classes.footerDescription}> Arrived </span>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(4)
            }}
          />
          <span className={classes.footerDescription}> Completed </span>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(28)
            }}
          />
          <span className={classes.footerDescription}>
            {" "}
            Manually Completed{" "}
          </span>
        </div>
      </div>
      <SharelinkDialog
        toggle={open.sharelink}
        close={() => handleClose("sharelink")}
        title="Share this trip"
        content="Generate link now and share real-time updates of this trip"
        generatedLink={generatedLink}
        fn={() => handleGenerate()}
      />

      <AttachmentsDialog
        tripNumber={parsedTripData.trip_number}
        close={() => handleClose("attachments")}
        open={open.attachments}
      />
      <HistorySelector
        open={open.historySelector}
        vehicle={{
          client_id: parsedTripData.client_id,
          device_id: parsedTripData.vehicle_id,
          plateno: parsedTripData.vehicle_plate_no,
          group_ids: parsedTripData.group_ids
        }}
        onClose={() => {
          handleClose("historySelector");
        }}
        title="History"
        initialFilterVal={!_.isEmpty(parsedTripData) ? getDateRange() : {}}
      />
    </div>
  );
};

export default DetailsTable;
