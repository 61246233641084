import { useState } from "react";
import Swal from "sweetalert2";
import { useQuery } from "@apollo/client";
import { GET_USER_EMAIL } from "../../graphql/Queries";

const useFetchUserEmail = (userVariables, hasNewEmail) => {
  const [userEmail, setUserEmail] = useState("");

  useQuery(GET_USER_EMAIL, {
    variables: userVariables,
    skip: hasNewEmail,
    onCompleted: res => setUserEmail(res.get_users.users),
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    },
    fetchPolicy: "cache-and-network"
  });

  return userEmail;
};

export default useFetchUserEmail;
