/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  Divider
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  TripOrigin,
  DepartureBoard
} from "@material-ui/icons";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _, { isNull } from "lodash";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import {
  GET_TRIP_LOG_V2,
  GET_GEOFENCES,
  GET_VEHICLE_INFO,
  GET_FUEL_HISTORY,
  GET_CICOS,
  GET_DEVICE
} from "../../../graphql/Queries";
import { getBooking } from "../../../api/bookingRepository";
import ErrorPage from "../../../utils/errorPage";
import Loading from "../../../utils/Loading";
import Itinerary from "./Itinerary";
import { etaConverter as durationConverter, geofenceTypes, gpsStatus } from "../../Utils";
import isWithinRange from "../helpers/isWithinRange";
import Header from "./Header";
import ItineraryFooter from "./ItineraryFooter";
import Battery from "../../../utils/Battery";
import VehicleSelector from "../HistorySelector";
import MapView from "./MapView";
import AddLogs from "../../../utils/functions/AddLogs";
import Filter from "./Filter/Filter";

const useStyles = makeStyles({
  main: {
    // position: "relative",
    height: "calc(100vh - 64px)",
    width: "100%"
    // overflow: "hidden"
  },
  headerText: {
    fontSize: 14,
    letterSpacing: 1.2,
    fontWeight: "bold"
  },
  headerPaper: {
    padding: 16,
    display: "flex",
    alignItems: "center"
  },
  detailsPaper: {
    // height: "calc(80vh)",
    // overflowY: "auto"
    position: "relative",
    height: "100%",
    overflow: "hidden"
  },
  label: {
    fontSize: 12,
    letterSpacing: 1.2,
    fontWeight: "bold"
  },
  info: {
    fontSize: 12,
    // letterSpacing: 1.2,
    color: "#646464"
    // fontWeight: "strong"
  },
  stopsPaper: {
    height: "50%"
  },
  graphPaper: {
    height: "30%"
  },
  historyContainer: {
    padding: "0",
    margin: 0,
    backgroundColor: "#f8f8f8",
    position: "relative"
    // height: "calc(100% - 64px)"
  }
});

const VehicleSection = ({
  vehicleData,
  getBatteryTooltip,
  getTooltip,
  getStatus,
  setSelectorOpen,
  thousands_separators
}) => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ minWidth: 170 }}>
          <Typography className={classes.label}>Plate/CV Number:</Typography>
          <span style={{ position: "relative", bottom: "-2px" }}>
            <Battery
              vehicleType={vehicleData.get_vehicle.vehicle_type}
              deviceTypeId={vehicleData.get_vehicle.device_info?.device_type_id}
              batteryLevel={
                vehicleData.get_vehicle.device_info?.device_status
                  ?.battery_level
              }
              tooltipTitle={getBatteryTooltip()}
              showText={false}
            />
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={getTooltip()}>
              <TripOrigin
                style={{
                  fontSize: 12,
                  color: gpsStatus(getStatus()).color,
                  marginRight: 8
                }}
              />
            </Tooltip>
            <Typography className={classes.info}>
              {vehicleData.get_vehicle ? vehicleData.get_vehicle.plateno : "-"}
            </Typography>
          </div>
        </div>
        <IconButton
          style={{ translate: "-25% 0px" }}
          onClick={() => setSelectorOpen(true)}
        >
          <Tooltip title="Vehicle Selection">
            <DepartureBoard fontSize="small" />
          </Tooltip>
        </IconButton>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ margin: "8px 0", minWidth: 170 }}>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Hauler:
          </Typography>
          <Typography className={classes.info}>
            {vehicleData.get_vehicle
              ? vehicleData.get_vehicle.client_name
              : "-"}
          </Typography>
        </div>
        <div style={{ margin: "8px 0" }}>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Vehicle Type:
          </Typography>
          <Typography className={classes.info}>
            {vehicleData.get_vehicle
              ? vehicleData.get_vehicle.vehicle_type
              : "-"}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className={classes.label}>Odometer Reading:</Typography>
        <Typography className={classes.info}>
          {" "}
          {vehicleData?.get_vehicle?.device_info?.device_status
            ?.distancetraveled
            ? `${thousands_separators(
                Math.round(
                  vehicleData?.get_vehicle?.device_info?.device_status
                    ?.distancetraveled / 1000
                )
              )} km`
            : "-"}
        </Typography>
      </div>
    </div>
  );
};
const DeviceSection = ({
  gpsData,
  getBatteryTooltip,
  getTooltip,
  getStatus,
  setSelectorOpen,
  isFromDevices
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label}>Device Alias:</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={getTooltip()}>
              <TripOrigin
                style={{
                  fontSize: 12,
                  color: gpsStatus(getStatus()).color,
                  marginRight: 8
                }}
              />
            </Tooltip>
            <Typography className={classes.info} variant="body2">
              {gpsData?.get_device ? gpsData?.get_device?.devicealias : "-"}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <IconButton
          style={{ translate: "-25% 0px" }}
          onClick={() => setSelectorOpen(true)}
        >
          <Tooltip title="Device Selection">
            <DepartureBoard fontSize="small" />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            IMEI/Serial Number:
          </Typography>
          <Typography className={classes.info}>
            {gpsData?.get_device?.name || "-"}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Device Type:
          </Typography>
          <Typography className={classes.info}>
            {gpsData?.get_device?.device_type || "-"}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Paired Vehicle/CV:
          </Typography>
          <Tooltip
            title={
              !gpsData?.get_device?.vehicle_info
                ? "Please assign a vehicle"
                : ""
            }
          >
            <Typography
              className={classes.info}
              style={{ width: "fit-content" }}
            >
              {gpsData?.get_device?.vehicle_info
                ? gpsData?.get_device?.vehicle_info?.plateno
                : "-"}
            </Typography>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            GPS Provider:
          </Typography>
          <Typography className={classes.info}>
            {gpsData?.get_device?.device_config?.gps_provider || "-"}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Battery:
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Battery
              batteryLevel={gpsData?.get_device?.device_status?.battery_level}
              tooltipTitle={getBatteryTooltip()}
              showText={false}
              historyFromDevices={isFromDevices}
            />
            <Typography className={classes.info}>
              {!isNull(gpsData?.get_device?.device_status?.battery_level)
                ? `${gpsData?.get_device?.device_status?.battery_level}%`
                : "-"}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            Status:
          </Typography>
          <Typography
            className={classes.info}
            style={{
              color: gpsStatus(gpsData?.get_device?.device_status?.status_code)
                .color
            }}
          >
            {gpsStatus(gpsData?.get_device?.device_status?.status_code).label ||
              "-"}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const Monitor = props => {
  const user = JSON.parse(Cookies.get("user"));
  // use URLSearchParams for looping on a link if any data is included on the link.
  // parse the data on the link.
  const queryParams = new URLSearchParams(window.location.search);
  const [historyDetails, setHistoryDetails] = useState(
    JSON.parse(queryParams.get("details"))
  );
  // const historyDetails = JSON.parse(queryParams.get("details"));
  // const vehicle_id = "17152"; // get from props
  const { location, history } = props;
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState({
    base: "",
    start: "",
    end: ""
  });
  const [deviceId, setDeviceId] = useState("");
  const [vehicleId, setVehicleId] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);
  const [recenter, setRecenter] = useState(false);
  const [hasProps, setHasProps] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [itinerary, setItinerary] = useState([]);
  const [itineraryCICO, setItineraryCICO] = useState([]);
  const [isPrimary, setIsPrimary] = useState(false);
  const [geofences, setGeofences] = useState([]);
  const [filters, setFilters] = useState({ filterValues: null, vars: null });
  const [tripNumber, setTripNumber] = useState(null);
  const [isMapView, setIsMapView] = useState(false);
  const [showLogs, setShowLogs] = useState({
    alerts: true,
    notifications: false,
    activityLogs: false
  });
  const [bookingInfo, setBookingInfo] = useState();
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [historyOverview, setHistoryOverview] = useState({
    consumed_fuel: null,
    distance_travelled: null
  });
  const [isCICO, setIsCICO] = useState(false);
  const [primaryData, setPrimaryData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const getCategories = () => {
    const excluded = ["Route"];
    return geofenceTypes
      .filter((t) => !excluded.includes(t.label))
      .map((t) => ({ label: t.label, isShown: true }));
  };
  const [categories, setCategories] = useState(getCategories());
  const [geoFilter, setGeoFilter] = useState({
    type: null,
    values: []
  });

  React.useEffect(() => {
    if (isMapView)
      AddLogs(
        process.env.REACT_APP_FLEET_MODULE,
        "view_map_fleet",
        "View History"
      );
  }, [isMapView]);

  const handleChangeView = () => {
    if (isCICO || !data) {
      getTripLogs({
        variables: getVariables()
      }).then(() => setIsMapView(prev => !prev));
    } else {
      setIsMapView(prev => !prev);
    }
  };

  const getDateFilter = () => {
    try {
      // const { state } = history.location;
      // const { dayType } = state?.historyFilterVal;
      const { dayType } = historyDetails.filteredValue;
      const UTC_HOURS = 8;
      // const ADDITIONAL_RANGE = 96;
      const additional_range = 96;
      // const INEXACT_RANGE = UTC_HOURS + ADDITIONAL_RANGE;
      // const subtracted_range = tripNumber ? UTC_HOURS : INEXACT_RANGE;
      // const additional_range = tripNumber ? 0 : ADDITIONAL_RANGE;
      // const daysExtension = 30;
      // const computedDaysExtension = tripNumber ? 0 : daysExtension;

      let startDate = new Date();
      let endDate = new Date();

      if (dayType === 24) {
        // 2hrs is selected from history selector
        if (tripNumber) {
          // with trip number
          startDate = date.start;
          endDate = date.end;
        } else {
          // without trip number
          startDate = date.start;
          endDate = date.selectedRange || date.end; // `${moment(date.selectedRange).format("YYYY-MM-DD")} 23:59:59`;
          // if (date.selectedRange) {
          //   endDate = date.selectedRange;
          // } else {
          //   endDate = `${moment(date.start).format("YYYY-MM-DD")} 23:59:59`;
          // }
        }
        // const subtracted_range = tripNumber ? UTC_HOURS : INEXACT_RANGE;
        // const additional_range = tripNumber ? 0 : ADDITIONAL_RANGE;
        // const result = {
        //   field: "datestamp",
        //   gte: `${moment(startDate)
        //     .subtract(subtracted_range, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`,
        //   lte: `${moment(endDate)
        //     .subtract(subtracted_range, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`
        // };

        // return result;
      }

      if (dayType === 1) {
        // Custom date is selected
        if (tripNumber) {
          // with trip number
          startDate = date.start;
          endDate = date.end;
        } else {
          // without trip number
          startDate = date.start;
          endDate = date?.selectedRange || date.end; // `${moment(date.selectedRange).format("YYYY-MM-DD")} 23:59:59`;
          // if (date.selectedRange) {
          //   endDate = date.selectedRange;
          // } else {
          //   endDate = `${moment(date.start).format("YYYY-MM-DD")} 23:59:59`;
          // }
        }

        // const subtracted_range = tripNumber ? UTC_HOURS : INEXACT_RANGE;
        // const additional_range = tripNumber ? 0 : ADDITIONAL_RANGE;
        // const result = {
        //   field: "datestamp",
        //   gte: `${moment(startDate)
        //     .subtract(subtracted_range, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`,
        //   lte: `${moment(endDate)
        //     .subtract(subtracted_range, "hours")
        //     .add(additional_range, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`
        // };

        // const result = {
        //   field: "datestamp",
        //   gte: `${moment(startDate)
        //     .subtract(8, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`,
        //   lte: `${moment(endDate)
        //     .subtract(8, "hours")
        //     .format("YYYY-MM-DD HH:mm:ss")}`
        // };

        // return result;
      }

      // Today, 4hrs, 8hrs, 12hrs scenario
      if ([0, 4, 8, 12].includes(dayType)) {
        startDate = date.start;
        endDate = date.end;
      }

      const gte = moment(startDate);
      const lte = moment(endDate);

      // +-4 days when trip_logs query is for list view
      if (!isCICO) {
        gte.subtract(additional_range, "hours");
        lte.add(additional_range, "hours");
      }

      return {
        field: "datestamp",
        gte: gte
          .subtract(UTC_HOURS, "hours")
          // .subtract(additional_range, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        lte: lte
          .subtract(UTC_HOURS, "hours")
          // .add(additional_range, "hours")
          .format("YYYY-MM-DD HH:mm:ss")
      };
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  };

  const getDateOrFilter = () => {
    try {
      const { dayType } = historyDetails.filteredValue;

      let startDate = new Date();
      let endDate = new Date();

      if (dayType === 24) {
        // 2hrs is selected from history selector
        if (tripNumber) {
          // with trip number
          startDate = date.start;
          endDate = date.end;
        } else {
          // without trip number
          startDate = date.start;
          endDate = date.selectedRange || date.end;
        }
      }

      if (dayType === 1) {
        // Custom date is selected
        if (tripNumber) {
          // with trip number
          startDate = date.start;
          endDate = date.end;
        } else {
          // without trip number
          startDate = date.start;
          endDate = date?.selectedRange || date.end;
        }
      }

      // Today, 4hrs, 8hrs, 12hrs scenario
      if ([0, 4, 8, 12].includes(dayType)) {
        startDate = date.start;
        endDate = date.end;
      }

      return {
        start: moment(startDate)
          .subtract(8, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(endDate)
          .subtract(8, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        field: ["entry_stamp", "left_stamp"]
      };
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  };

  // useEffect(() => {
  //   const currentFilter = history.location.state.historyFilterVal;
  //   const { dayType } = currentFilter;
  //   if (dayType === 24) {
  //     setDate(d => ({
  //       ...d,
  //       start: moment(d.start)
  //         .utc()
  //         .format("YYYY-MM-DD HH:mm:ss"),
  //       end: `${moment(d.end)
  //         .utc()
  //         .format("YYYY-MM-DD")} 23:59:00`
  //     }));
  //   }
  // }, [date.base]);

  const getVariables = () => {
    // const {
    //   vehicle_id,
    //   historyFilterVal: { plateno }
    // } = location.state.params;

    const { vehicle, filteredValue } = historyDetails;
    const vars = {
      // filter: {
      //   field: "device_id",
      //   value: deviceId
      // },
      filter: [],
      or: [
        {
          field: "vehicle_id",
          // value: vehicle_id
          value: vehicle?.id || ""
        },
        {
          field: "plateno.keyword",
          // value: plateno
          value: filteredValue?.plateno || ""
        }
      ],
      // exists: ["alert_msgs"],
      // dateFilter: {
      //   field: "datestamp",
      //   gte: moment(date.start)
      //     .utc()
      //     .format("YYYY-MM-DD HH:mm:ss"),
      //   lte: moment(date.end)
      //     .utc()
      //     .format("YYYY-MM-DD HH:mm:ss")
      // },
      dateFilter: getDateFilter(),
      // dateFilter: {
      //   field: "datestamp",
      //   gte: date.start,
      //   lte: date.end
      // },
      first: 10000
    };

    const dvar = {
      field: "device_id",
      value: deviceId || ""
    };

    if (vehicle?.id || filteredValue?.plateno) {
      vars.or.push(dvar);
    } else {
      Object.assign(vars, {
        filter: [dvar]
      });
    }

    return vars;
  };

  // const { data, loading, error } = useQuery(GET_TRIP_LOG_V2, {
  //   // onCompleted: response => console.log(response),
  //   // onError: err => console.log(err),
  //   variables: getVariables(),
  //   fetchPolicy: "network-only",
  //   // skip: !date.base
  //   onCompleted(data) {
  //     console.log({variables: getVariables(), data, date})
  //   }
  // });

  const [getTripLogs, { data, loading, error }] = useLazyQuery(GET_TRIP_LOG_V2);

  const willUseCICO = testDate => {
    return moment(testDate).isSameOrAfter("2023-01-01");
  };

  const [
    getCicos,
    { data: cicoData, loading: cicoLoading, error: errorCico }
  ] = useLazyQuery(GET_CICOS);

  const [getFuelInfo, { loading: fuelLoading }] = useLazyQuery(
    GET_FUEL_HISTORY,
    {
      onCompleted: e => {
        const { error: err, results } = e.get_fuel_history;
        setHistoryOverview({ ...historyOverview, ...results, error: err });
      },
      variables: {
        device_id: deviceId,
        vehicle_id: vehicleId,
        plateno: historyDetails?.vehicle?.plateno,
        entryTime: moment(date.start)
          .subtract(8, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        exitTime: moment(date.end)
          .subtract(8, "hours")
          .format("YYYY-MM-DD HH:mm:ss")
      }
    }
  );

  const redirect = (r = "/monitoring", params = {}) => {
    const { filterValues, vars } = filters;
    history.push({
      pathname: r,
      state: {
        params: params,
        filterValues: filterValues,
        vars: vars,
        historyFilterVal: params.historyFilterVal
      }
    });
    if (r === "/monitoring/history") {
      window.location.reload();
    }
  };

  const { data: vehicleData, loading: vehicleLoading } = useQuery(
    GET_VEHICLE_INFO,
    {
      variables: {
        id: vehicleId
      },
      skip: !vehicleId || historyDetails?.isFromDevices,
      onCompleted: d => {
        if (d) {
          if (!d.get_vehicle.id) {
            Swal.fire({
              title: "Vehicle not found",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                redirect();
              }
            }).then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                redirect();
              }
            });
          } else if (d.get_vehicle) {
            setIsPrimary(d.get_vehicle.client_id === user.client.id);

            const {
              has_fuel_sensor,
              has_temp_sensor,
              ignition_status,
              icon,
              plateno
            } = d.get_vehicle;
            const { powersource } = d.get_vehicle?.device_info?.device_status;
            setPrimaryData({
              ...primaryData,
              has_fuel_sensor: has_fuel_sensor,
              has_temp_sensor: has_temp_sensor,
              ignition_status: ignition_status,
              powersource: powersource,
              icon: icon,
              plateno: plateno
            });
          }
        }
      }
    }
  );

  const { data: gpsData, loading: gpsLoading } = useQuery(GET_DEVICE, {
    variables: {
      id: deviceId
    },
    skip: !deviceId || !historyDetails?.isFromDevices,
    onCompleted: d => {
      if (d) {
        if (!d.get_device.id) {
          Swal.fire({
            title: "Device not found",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              redirect();
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              redirect();
            }
          });
        } else if (d.get_device) {
          const { hasFuelSensor, hastempsensor } = d.get_device?.device_config;
          const { ignition_status, icon, plateno } = d.get_device?.vehicle_info;
          const { powersource } = d.get_device?.device_status;

          setPrimaryData({
            ...primaryData,
            has_fuel_sensor: hasFuelSensor,
            has_temp_sensor: hastempsensor,
            ignition_status: ignition_status,
            powersource: powersource,
            icon: icon,
            plateno: plateno
          });
        }
      }
    }
  });

  const { loading: geofenceLoading, data: geofenceData } = useQuery(
    GET_GEOFENCES,
    {
      variables: {
        filter: [
          { field: "name", value: addresses.filter(a => !_.isNull(a)) }
          // { field: "address.keyword", value: addresses },
          // { field: "name.keyword", value: addresses }
        ],
        first: 1000
      },
      skip: addresses.length < 1,
      onCompleted: d => {
        if (d) {
          setGeofences(d.get_geofences.geofences);
        }
      }
    }
  );

  const classes = useStyles();

  const getTravelDuration = () => {
    let travelDuration = "-";
    if (data.get_trip_log_v2.length > 1) {
      const startTime = moment(data.get_trip_log_v2[0].datestamp);
      const endTime = moment(
        data.get_trip_log_v2[data.get_trip_log_v2.length - 1].datestamp
      );
      const temp3 = endTime.diff(startTime, "seconds");
      travelDuration = moment("1900-01-01 00:00:00")
        .add(temp3, "seconds")
        .format("HH:mm:ss");
    }

    return travelDuration;
  };

  const thousands_separators = num => {
    const num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  const dateHandler = d => {
    setDate({
      base: moment(d).format("YYYY-MM-DD"),
      start: moment(d)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment(d)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
    });
  };

  const handleDate = direction => {
    let tempDate = date.base;
    if (direction === "up") {
      if (tempDate < moment().format("YYYY-MM-DD")) {
        tempDate = moment(tempDate)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
    } else {
      tempDate = moment(tempDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }

    setDate({
      base: tempDate,
      start: `${tempDate} 00:00:00`,
      end: `${tempDate} 23:59:59`
    });
  };

  const handleRecenter = () => {
    if (data.get_trip_log_v2?.length > 0) setRecenter(!recenter);
  };

  const getTooltip = () => {
    let tooltip = <p className={classes.tooltipTitle}>No GPS</p>;
    if (vehicleData?.get_vehicle?.device_info) {
      tooltip = (
        <>
          <p className={classes.tooltipTitle} style={{ fontWeight: "bold" }}>
            {
              gpsStatus(
                vehicleData.get_vehicle.device_info.device_status.status_code
                  ? vehicleData.get_vehicle.device_info.device_status
                      .status_code
                  : "active"
              ).label
            }
          </p>
          <p className={classes.tooltipTitle}>{`Last Report: ${moment(
            vehicleData.get_vehicle.device_info.device_status.reportstamp
          ).format("lll")}`}</p>
        </>
      );
    }
    if (gpsData?.get_device) {
      tooltip = (
        <>
          <p className={classes.tooltipTitle} style={{ fontWeight: "bold" }}>
            {
              gpsStatus(
                gpsData?.get_device.device_status.status_code
                  ? gpsData?.get_device?.device_status?.status_code
                  : "active"
              ).label
            }
          </p>
          <p className={classes.tooltipTitle}>{`Last Report: ${moment(
            gpsData?.get_device?.device_status?.reportstamp
          ).format("lll")}`}</p>
        </>
      );
    }

    return tooltip;
  };

  const getBatteryTooltip = () => {
    let tooltip = <p className={classes.tooltipTitle}>No Battery</p>;
    if (vehicleData?.get_vehicle?.device_info) {
      const deviceStatus = vehicleData.get_vehicle.device_info.device_status;
      // check if battery_level is not null or undefined, accepts zero as true
      const batteryLevel = !isNull(deviceStatus.battery_level)
        ? `${deviceStatus.battery_level}%`
        : `-`;
      tooltip = (
        <>
          <p>{`Battery Life: ${batteryLevel}`}</p>
          <p>{`As of: ${moment(deviceStatus.reportstamp).format("lll")}`}</p>
        </>
      );
    }
    if (gpsData?.get_device) {
      const deviceStatus = gpsData.get_device.device_status;
      // check if battery_level is not null or undefined, accepts zero as true
      const batteryLevel = !isNull(deviceStatus.battery_level)
        ? `${deviceStatus.battery_level}%`
        : `-`;
      tooltip = (
        <>
          <p>{`Battery Life: ${batteryLevel}`}</p>
          <p>{`As of: ${moment(deviceStatus.reportstamp).format("lll")}`}</p>
        </>
      );
    }

    return tooltip;
  };

  const getStatus = () => {
    let status = "no gps";
    if (vehicleData?.get_vehicle?.device_info) {
      status = vehicleData.get_vehicle.device_info.device_status.status_code
        ? vehicleData.get_vehicle.device_info.device_status.status_code
        : "active";
    }
    if (gpsData?.get_device) {
      status = gpsData.get_device.device_status.status_code
        ? gpsData.get_device.device_status.status_code
        : "active";
    }
    return status;
  };

  const renderDate = () => {
    const fromDate = moment(date.start).format("ll HH:mm");
    const toDate = moment(date.end).format("ll HH:mm");
    if (!isPrimary) {
      // put the code to get start time to end time here
    }
    return (
      <>
        <div style={{ margin: "8px 0" }}>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            From:
          </Typography>
          <Typography className={classes.info}>{fromDate}</Typography>
        </div>
        <div style={{ margin: "8px 0" }}>
          <Typography className={classes.label} style={{ fontWeight: "bold" }}>
            To:
          </Typography>
          <Typography className={classes.info}>{toDate}</Typography>
        </div>
      </>
    );
  };

  const handleTripNumber = async (tripInfo, selectedRange) => {
    setTripNumber(tripInfo.trip_number);
    const booking = await getBooking(tripInfo.trip_number, tripInfo.group_ids);
    let base = moment(booking.tripList[0].pickups[0].actual_arrival).format(
      "YYYY-MM-DD"
    );
    // const base = moment(booking.tripList[0].pickups[0].arrival).format(
    //   "YYYY-MM-DD"
    // );

    const {
      actual_arrival,
      manual_arrival,
      arrival: plannedArrival
    } = await booking?.tripList[0]?.pickups[0];
    // const { dispatch_time } = await booking?.tripList[0];

    const arrival = moment(
      manual_arrival || actual_arrival || plannedArrival
    ).format("YYYY-MM-DD HH:mm:ss");
    const dispatch_time = moment(booking.tripList[0].dispatch_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const start = dispatch_time > arrival ? arrival : dispatch_time;
    // const start = booking.tripList[0].dispatch_time
    //   ? moment(booking.tripList[0].dispatch_time).format("YYYY-MM-DD HH:mm:ss")
    //   : moment(booking.tripList[0].pickups[0].arrival)
    //       .subtract(2, "hours")
    //       .format("YYYY-MM-DD HH:mm:ss");

    // const start = booking.tripList[0].dispatch_time
    //   ? moment(booking.tripList[0].dispatch_time).format("YYYY-MM-DD HH:mm:ss")
    //   : moment(booking.tripList[0].pickups[0].arrival)
    //       .subtract(2, "hours")
    //       .format("YYYY-MM-DD HH:mm:ss");
    let end = "";
    if (booking.tripList[0].status.toLowerCase() === "completed") {
      // const lastDropoff =
      //   booking.tripList[0].deliveryLocation[
      //     booking.tripList[0].deliveryLocation.length - 1
      //   ];
      // const lastDropoff = booking.tripList[0].deliveryLocation[0];
      // let lastDropoff = null;
      end = booking.tripList[0].deliveryLocation.reduce(
        (accumulator, current) => {
          const {
            actual_departure: actual,
            manual_departure: manual
          } = current;
          const dropOff = manual || actual;
          const tempLastDropOff = moment(dropOff).format("YYYY-MM-DD HH:mm:ss");

          if (accumulator === null) {
            return tempLastDropOff;
          }

          if (tempLastDropOff > accumulator) {
            return tempLastDropOff;
          }

          return accumulator;
        },
        null
      );

      // end = lastDropoff.actual_departure
      //   ? lastDropoff.actual_departure
      //   : lastDropoff.manual_departure;
    } else {
      end = moment(selectedRange?.to)
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      // end = moment(start)
      //   .endOf("date")
      //   .format("YYYY-MM-DD HH:mm:ss");
      // end = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    }

    if (base.toLowerCase() === "invalid date") {
      base = moment(start)
        .endOf("date")
        .format("YYYY-MM-DD");
    }

    if (end.toLowerCase() === "invalid date") {
      end = moment(selectedRange.to)
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    setDate({ base: base, start: start, end: end, selectedRange: end });
    setBookingInfo({
      dispatch_time: dispatch_time,
      arrival_time: arrival,
      geofences: booking.tripGeofences
    });
  };

  // const handleGeofenceClick = time => {
  //   const index = logs.findIndex(
  //     log => log.time === moment(time).format("YYYY-MM-DD HH:mm:ss")
  //   );

  //   if (index > -1) {
  //     setTimeFrame(index);
  //     setZoom(true);
  //   }
  // };

  useEffect(() => {
    // if (location.state) {
    if (historyDetails) {
      // const { params, filterValues, vars } = location.state;
      // const { date: dateProps, trip_number, device_id, vehicle_id } = params;
      // const {
      //   date: dateProps,
      //   trip_number,
      //   device_id,
      //   vehicle_id,
      //   tripInfo
      // } = params;
      const { trip, date: dateProps, filteredValue, vehicle } = historyDetails;

      // setFilters({ filterValues: filterValues, vars: vars });
      if (trip.details && trip.id) {
        handleTripNumber(trip.details, dateProps);
      } else {
        setDate({
          base: dateProps.from,
          start: dateProps.from,
          end: dateProps.to,
          selectedRange: moment(dateProps.to).format("YYYY-MM-DD HH:mm:ss")
          // selectedRange: [0, 4, 8, 12].includes(dateProps.dayType)
          //   ? moment(dateProps.to).format("YYYY-MM-DD HH:mm:ss")
          //   : `${moment(dateProps.to).format("YYYY-MM-DD")} 23:59:59`
        });
      }
      // if (tripInfo && trip_number) {
      //   handleTripNumber(tripInfo, dateProps);
      // } else {
      //   setDate({
      //     base: dateProps.from,
      //     start: dateProps.from,
      //     end: dateProps.to,
      //     selectedRange: [0, 4, 8, 12].includes(dateProps.dayType)
      //       ? moment(dateProps.to).format("YYYY-MM-DD HH:mm:ss")
      //       : `${moment(dateProps.to).format("YYYY-MM-DD")} 23:59:59`
      //   });
      // }

      // if (true) handleTripNumber("dhltrip03220002");

      // setDate({
      //   base: `2021-03-21 21:30:44`,
      //   start: `2021-03-21 21:30:44`,
      //   end: `2021-03-22 09:30:44`
      // });

      // ? moment(dateProps.to).format("YYYY-MM-DD HH:mm:ss")
      // : moment(dateProps.from).endOf("day").format("YYYY-MM-DD HH:mm:ss"))

      // const isCico = willUseCICO(dateProps.from);
      // setIsCICO(willUseCICO(dateProps.from));

      setDeviceId(vehicle.device_id);
      setVehicleId(vehicle.id);
      // if (!isCico) {
      //   getTripLogs();
      // } else {
      //   getCicos();
      // }
      
      getFuelInfo();
      setHasProps(true);
      setPrimaryData({
        ...primaryData,
        client_id: filteredValue?.vehicleClientId,
        id: vehicle.id,
        plateno: vehicle.plateno
      });
    } else {
      history.push({
        pathname: "/monitoring"
      });
    }
  }, [historyDetails]);

  useEffect(() => {
    if (date?.base) {
      const isCico = willUseCICO(date.start);
      setIsCICO(isCico);

      if (!isCico || isMapView) {
        getTripLogs({
          variables: getVariables()
        });
      }

      if (isCico) {
        getCicos({
          variables: {
            first: 10000,
            inFilter: [
              {
                field: "device_id",
                value: deviceId || ""
              },
              {
                field: "vehicle_plate_no",
                value: historyDetails?.filteredValue?.plateno || ""
              }
            ],
            dateOrFilter: getDateOrFilter()
          }
        });
      }
      getFuelInfo();
    }
  }, [date]);

  useEffect(() => {
    setLogs([]);
    if (data) {
      // for graph
      if (data.get_trip_log_v2.length > 0) {
        // const tempLogs = data.get_trip_log_v2.map(trip_log => {
        //   return {
        //     time: moment(trip_log.datestamp)
        //       .add(8, "hours")
        //       .format("YYYY-MM-DD HH:mm:ss"),
        //     Temperature: trip_log.temperature,
        //     Speed: trip_log.speed,
        //     location: { lat: trip_log.location.lat, lon: trip_log.location.lon }
        //   };
        // });
        const tempLogs = data.get_trip_log_v2.reduce((accum, current) => {
          if (isWithinRange(date, current.datestamp)) {
            accum.push({
              time: moment(current.datestamp)
                .add(8, "hours")
                .format("YYYY-MM-DD HH:mm:ss"),
              Temperature: current.temperature,
              Speed: current.speed,
              location: {
                lat: current.location.lat,
                lon: current.location.lon
              },
              direction: current.direction,
              fuel: current.fuel_reading,
              alert_code: current.alert_codes,
              alert_msgs: current.alert_msgs,
              alert_levels: current.alert_levels
            });

            return accum;
          }

          return accum;
        }, []);

        setLogs(tempLogs);

        // if (tempLogs.length > 0) {
        //   // if more than 1 logs,
        //   // set initial value of slider and timepicker to last log time
        //   setTimeFrame(tempLogs.length - 1);
        //   handleDateChange(tempLogs[tempLogs.length - 1].time);
        // }
      }
    }
  }, [data]);

  useEffect(() => {
    if (cicoData) {
      const { cicos } = cicoData.get_cicos;
      const itin = cicos.map(c => ({
        Entered: c.entry_stamp,
        Left: c.left_stamp,
        code: c.geofence_code,
        geofenceName: `${c.geofence_code} - ${c.geofence_name}`,
        geofenceCategory: c.category,
        geofenceId: c.geofence_id,
        geofenceAddress: c.address,
        dwellTime: c.dwell_time
      }));
      setAddresses(_.uniq(cicos.map(c => c.geofence_name)));
      setItineraryCICO(itin.filter(i => i.geofenceCategory !== "Route"));
    }
  }, [cicoData]);

  const parseAlert = (alert, datestamp, alert_location, odometer_reading) => {
    const alertArray = alert.split(" ");
    const action = alertArray[0].toLowerCase().includes("left")
      ? "Left"
      : "Entered";
    alertArray.shift();
    const geofenceName = alertArray.join(" ");

    return {
      geofenceName: geofenceName,
      // [action]: moment(datestamp)
      //   .add(8, "hours")
      //   .format("YYYY-MM-DD hh:mm:ss A"),
      [action]: datestamp,
      location: alert_location,
      [action.toLowerCase()]: odometer_reading
    };
  };

  useEffect(() => {
    if (data && !isCICO) {
      setItinerary([]);
      if (data.get_trip_log_v2.length > 0) {
        const tempItinerary = [];
        const alertAddresses = [];
        data.get_trip_log_v2
          // .filter(trip_log => {
          //   return trip_log.group_names.includes(user.client.name);
          // })
          .filter(trip_log => {
            let needed = false;
            if (
              trip_log.alert_msgs.filter(
                alert_msg =>
                  alert_msg.toLowerCase().includes("left") ||
                  alert_msg.toLowerCase().includes("enter")
              ).length > 0
            ) {
              needed = true;
            }
            return needed && trip_log.alert_codes.length > 0;
          })
          .map(trip_log => {
            return trip_log.alert_msgs
              .filter(
                alert =>
                  alert.toLowerCase().includes("left") ||
                  alert.toLowerCase().includes("enter")
              )
              .map(alert => {
                tempItinerary.push(
                  parseAlert(
                    alert,
                    trip_log.datestamp,
                    trip_log.location,
                    trip_log.odometer_reading
                  )
                );
                const alertArray = alert.split(" ");
                alertArray.shift();

                if (isWithinRange(date, trip_log.datestamp)) {
                  alertAddresses.push(alertArray.join(" "));
                }
                // alertAddresses.push(
                //   alert.includes("Left")
                //     ? alert.replace("Left ", "")
                //     : alert.replace("Entered ", "")
                // );
                return true;
              });
          });
        const asd = [];
        _.uniq(alertAddresses).map(adr => {
          // band aid solution for trip logs error
          const item = [];
          tempItinerary
            .filter(i => i.geofenceName === adr)
            .map((i, index) => {
              if (index === 0) {
                item.push(i);
              } else {
                const alert =
                  "Left" in
                  tempItinerary.filter(g => g.geofenceName === adr)[index]
                    ? "left"
                    : "enter";
                const alertPrev =
                  "Left" in
                  tempItinerary.filter(g => g.geofenceName === adr)[index - 1]
                    ? "left"
                    : "enter";
                if (alert !== alertPrev) item.push(i);
              }
              return true;
            });
          // up to here
          // const item = tempItinerary.filter(i => i.geofenceName === adr);
          if (item.length > 1) {
            if (item.length % 2 === 0) {
              if ("Left" in item[0]) {
                asd.push({ ...item[0] });
                asd.push({ ...item[item.length - 1] });
                for (let i = 1; i < item.length - 2; i += 2) {
                  asd.push({ ...item[i], ...item[i + 1] });
                }
              } else {
                for (let i = 0; i < item.length; i += 2) {
                  asd.push({ ...item[i], ...item[i + 1] });
                }
              }
            } else if (item.length % 2 === 1) {
              if ("Left" in item[0]) {
                asd.push({ ...item[0] });
                for (let i = 1; i < item.length; i += 2) {
                  asd.push({ ...item[i], ...item[i + 1] });
                }
              } else {
                for (let i = 0; i < item.length - 1; i += 2) {
                  asd.push({ ...item[i], ...item[i + 1] });
                }
                asd.push({ ...item[item.length - 1] });
              }
            }
          } else {
            asd.push({ ...item[0] });
          }
          return true;
        });
        setItinerary(asd);
        setAddresses(_.uniq(alertAddresses));
      }
    }
  }, [data]);

  useEffect(() => {
    if (geofenceData && itinerary.length > 0) {
      const { geofences: geofencesList } = geofenceData.get_geofences;
      if (geofencesList.length > 0) {
        const tempItinerary = itinerary.map(item => {
          const { geofenceName } = item;
          const index = geofencesList.findIndex(
            geofence => geofence.name === item.geofenceName
          );
          if (index > -1) {
            const code = geofencesList[index].geofence_code;
            return {
              ...item,
              code: code,
              geofenceName: `${code} - ${geofenceName}`,
              geofenceCategory: geofencesList[index].category,
              geofenceAddress: geofencesList[index].address,
              geofenceId: geofencesList[index].id
            };
          }
          return item;
        });
        setItinerary(tempItinerary.filter(i => i.geofenceCategory !== "Route"));
      }
    }
  }, [geofenceData, addresses]);

  // useEffect(() => {
  //   if (data) {
  //     setItinerary([]);
  //     if (data.get_trip_log_v2.length > 0) {
  //       const tempItinerary = [];
  //       const alertAddresses = [];

  //       data.get_trip_log_v2
  //         .filter(trip_log => {
  //           let needed = false;
  //           if (
  //             trip_log.alert_msgs.filter(
  //               alert_msg =>
  //                 alert_msg.includes("Left") || alert_msg.includes("Entered")
  //             ).length > 0
  //           ) {
  //             needed = true;
  //           }
  //           return needed;
  //         })
  //         .map(trip_log =>
  //           trip_log.alert_msgs
  //             .filter(
  //               alert => alert.includes("Left") || alert.includes("Entered")
  //             )
  //             .map(alert => {
  //               tempItinerary.push(parseAlert(alert, trip_log.datestamp));
  //               alertAddresses.push(
  //                 alert.includes("Left")
  //                   ? alert.replace("Left ", "")
  //                   : alert.replace("Entered ", "")
  //               );
  //               return true;
  //             })
  //         );
  //       const asd = _.uniq(alertAddresses).map(adr => {
  //         const item = tempItinerary.filter(i => i.geofenceName === adr);
  //         if (item.length > 1) {
  //           return { ...item[0], ...item[1] };
  //         }
  //         return item[0];
  //       });
  //       setItinerary(asd);
  //       setAddresses(_.uniq(alertAddresses));
  //     }
  //   }
  // }, [data]);
  const [expandAllAlerts, setExpandAllAlerts] = useState(false);
  const handleExpandAllAlerts = () => {
    setExpandAllAlerts(prev => !prev);
  };

  if (
    loading ||
    vehicleLoading ||
    geofenceLoading ||
    !hasProps ||
    cicoLoading ||
    gpsLoading
  ) {
    return <Loading />;
  }

  if (error) return <ErrorPage />;

  return (
    <div className={classes.main}>
      {/* {!isPrimary && (
        <HistorySelector
          open={open}
          handleClose={handleClose}
          vehicle_plate_no={vehicleData?.get_vehicle?.plateno}
          handleTripNumber={handleTripNumber}
          // setDate={setDate}
          dateHandler={dateHandler}
          tripNumber={tripNumber}
          date={date}
        />
      )} */}
      <div style={{ display: "none" }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            onChange={dateHandler}
            open={dateOpen}
            onClose={() => {
              setDateOpen(false);
            }}
            orientation="landscape"
            disableFuture
            value={date.start}
          />
        </MuiPickersUtilsProvider>
      </div>
      {!historyDetails?.isFromDevices ? (
        <VehicleSelector
          open={selectorOpen}
          vehicle={vehicleData?.get_vehicle}
          onClose={() => setSelectorOpen(false)}
          title="Vehicle Selector"
          // redirectTo={redirect}
          isFromHistory
          onDataReprocess={selectedVehicleHistory => {
            setHistoryDetails(selectedVehicleHistory);
            // const vehicleHistoryEncoded = encodeURI(
            //   JSON.stringify(selectedVehicleHistory)
            // );
            // window.location.href = `/monitoring/:view/history?details=${vehicleHistoryEncoded}`
          }}
        />
      ) : (
        <VehicleSelector
          open={selectorOpen}
          // vehicle={vehicleData?.get_vehicle}
          onClose={() => setSelectorOpen(false)}
          title="Device Selector"
          // redirectTo={redirect}
          isFromHistory
          isFromDevices
          onDataReprocess={selectedVehicleHistory => {
            setHistoryDetails(selectedVehicleHistory);
          }}
        />
      )}
      {/* <Grid item xs={12}>
        <Paper className={classes.headerPaper}>
          <ArrowBackIcon onClick={() => redirect()} />
          <Typography style={{ fontSize: 24 }}>View History</Typography>
        </Paper>
      </Grid> */}
      <Grid
        container
        item
        md={12}
        spacing={!isMapView ? 3 : 0}
        className={classes.historyContainer}
      >
        {/* <Grid item xs={12}>
          <Paper className={classes.headerPaper}>
            <ArrowBackIcon onClick={() => redirect()} />
            <Typography style={{ fontSize: 24 }}>View History</Typography>
          </Paper>
        </Grid> */}
        {!isMapView && !historyDetails?.isFromDevices && (
          <Grid item md={3}>
            <Paper className={classes.detailsPaper}>
              <div style={{ padding: 24, height: "70vh", overflow: "auto" }}>
                <Typography className={classes.headerText}>
                  VEHICLE DETAILS
                </Typography>
                <div style={{ padding: "16px 12px" }}>
                  <div style={{ margin: "0 0 8px" }}>
                    <Typography
                      component="div"
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          Plate/CV Number:
                          <span
                            style={{ position: "relative", bottom: "-2px" }}
                          >
                            <Battery
                              vehicleType={
                                vehicleData?.get_vehicle?.vehicle_type
                              }
                              deviceTypeId={
                                vehicleData?.get_vehicle?.device_info
                                  ?.device_type_id
                              }
                              batteryLevel={
                                vehicleData?.get_vehicle?.device_info
                                  ?.device_status?.battery_level
                              }
                              tooltipTitle={getBatteryTooltip()}
                              showText={false}
                            />
                          </span>
                        </div>
                        <IconButton
                          style={{ marginLeft: "auto", fontStyle: "5px" }}
                          size="small"
                          onClick={() => setSelectorOpen(true)}
                        >
                          <Tooltip title="Vehicle Selection">
                            <DepartureBoard />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </Typography>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <Tooltip title={getTooltip()}>
                        <TripOrigin
                          style={{
                            fontSize: 12,
                            color: gpsStatus(getStatus()).color
                          }}
                        />
                      </Tooltip>
                      <Typography className={classes.info} variant="body2">
                        {vehicleData?.get_vehicle
                          ? vehicleData?.get_vehicle?.plateno
                          : "-"}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Hauler:
                    </Typography>
                    <Typography className={classes.info}>
                      {vehicleData?.get_vehicle
                        ? vehicleData?.get_vehicle?.client_name
                        : "-"}
                    </Typography>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Vehicle Type:
                    </Typography>
                    <Typography className={classes.info}>
                      {vehicleData?.get_vehicle
                        ? vehicleData?.get_vehicle?.vehicle_type
                        : "-"}
                    </Typography>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Odometer Reading:
                    </Typography>
                    <Typography className={classes.info}>
                      {" "}
                      {vehicleData?.get_vehicle?.device_info?.device_status
                        ?.distancetraveled
                        ? `${thousands_separators(
                            Math.round(
                              vehicleData?.get_vehicle?.device_info
                                ?.device_status?.distancetraveled / 1000
                            )
                          )} km`
                        : "-"}
                    </Typography>
                  </div>
                </div>
                <Typography className={classes.headerText}>
                  HISTORY OVERVIEW
                </Typography>
                <div style={{ padding: "16px 12px" }}>
                  <div style={{ margin: "0 0 8px" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Travel Duration:
                    </Typography>
                    {fuelLoading ? (
                      <Typography variant="caption">
                        <CircularProgress disableShrink size={10} />
                      </Typography>
                    ) : (
                      <Typography className={classes.info}>
                        {historyOverview.error?.length
                          ? "N/A"
                          : durationConverter(
                              historyOverview.duration_min * 60
                            )}
                      </Typography>
                    )}
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Distance Travelled:
                    </Typography>
                    {fuelLoading ? (
                      <Typography variant="caption">
                        <CircularProgress disableShrink size={10} />
                      </Typography>
                    ) : (
                      <Typography className={classes.info}>
                        {historyOverview.error?.length
                          ? "N/A"
                          : historyOverview.distance_travelled
                          ? `${historyOverview.distance_travelled} km`
                          : "-"}
                      </Typography>
                    )}
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Fuel Change:
                    </Typography>
                    {vehicleData?.get_vehicle?.has_fuel_sensor ? (
                      fuelLoading ? (
                        <Typography variant="caption">
                          <CircularProgress disableShrink size={10} />
                        </Typography>
                      ) : (
                        <Typography className={classes.info}>
                          {historyOverview.error?.length
                            ? "N/A"
                            : historyOverview.consumed_fuel || "-"}
                        </Typography>
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                  {renderDate()}
                </div>
              </div>
            </Paper>
          </Grid>
        )}
        {!isMapView && historyDetails?.isFromDevices && (
          <Grid item md={3}>
            <Paper className={classes.detailsPaper}>
              <div style={{ padding: 24, height: "70vh", overflow: "auto" }}>
                <Typography className={classes.headerText}>
                  GPS DEVICE DETAILS
                </Typography>
                <div style={{ padding: "16px 12px" }}>
                  <div style={{ margin: "0 0 8px" }}>
                    <Typography
                      component="div"
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.label}>
                          Device Alias:
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "auto", fontStyle: "5px" }}
                          size="small"
                          onClick={() => setSelectorOpen(true)}
                        >
                          <Tooltip title="Device Selection">
                            <DepartureBoard />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </Typography>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <Tooltip title={getTooltip()}>
                        <TripOrigin
                          style={{
                            fontSize: 12,
                            color: gpsStatus(getStatus()).color
                          }}
                        />
                      </Tooltip>
                      <Typography className={classes.info} variant="body2">
                        {gpsData?.get_device
                          ? gpsData?.get_device?.devicealias
                          : "-"}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      IMEI/Serial Number:
                    </Typography>
                    <Typography className={classes.info}>
                      {gpsData?.get_device?.name || "-"}
                    </Typography>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Device Type:
                    </Typography>
                    <Typography className={classes.info}>
                      {gpsData?.get_device?.device_type || "-"}
                    </Typography>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Paired Vehicle/CV:
                    </Typography>
                    <Tooltip
                      title={
                        !gpsData?.get_device?.vehicle_info
                          ? "Please assign a vehicle"
                          : ""
                      }
                    >
                      <Typography
                        className={classes.info}
                        style={{ width: "fit-content" }}
                      >
                        {gpsData?.get_device?.vehicle_info
                          ? gpsData?.get_device?.vehicle_info?.plateno
                          : "-"}
                      </Typography>
                    </Tooltip>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Battery:
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Battery
                        batteryLevel={
                          gpsData?.get_device?.device_status?.battery_level
                        }
                        tooltipTitle={getBatteryTooltip()}
                        showText={false}
                        historyFromDevices={historyDetails?.isFromDevices}
                      />
                      <Typography className={classes.info} variant="body2">
                        {!isNull(
                          gpsData?.get_device?.device_status?.battery_level
                        )
                          ? `${gpsData?.get_device?.device_status?.battery_level}%`
                          : "-"}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      GPS Provider:
                    </Typography>
                    <Typography className={classes.info}>
                      {gpsData?.get_device?.device_config?.gps_provider || "-"}
                    </Typography>
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Status:
                    </Typography>
                    <Typography
                      className={classes.info}
                      style={{
                        color: gpsStatus(
                          gpsData?.get_device?.device_status?.status_code
                        ).color
                      }}
                    >
                      {gpsStatus(
                        gpsData?.get_device?.device_status?.status_code
                      ).label || "-"}
                    </Typography>
                  </div>
                </div>
                <Typography className={classes.headerText}>
                  HISTORY OVERVIEW
                </Typography>
                <div style={{ padding: "16px 12px" }}>
                  <div style={{ margin: "0 0 8px" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Travel Duration:
                    </Typography>
                    {fuelLoading ? (
                      <Typography variant="caption">
                        <CircularProgress disableShrink size={10} />
                      </Typography>
                    ) : (
                      <Typography className={classes.info}>
                        {historyOverview.error?.length
                          ? "N/A"
                          : durationConverter(
                              historyOverview.duration_min * 60
                            )}
                      </Typography>
                    )}
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Distance Travelled:
                    </Typography>
                    {fuelLoading ? (
                      <Typography variant="caption">
                        <CircularProgress disableShrink size={10} />
                      </Typography>
                    ) : (
                      <Typography className={classes.info}>
                        {historyOverview.error?.length
                          ? "N/A"
                          : historyOverview.distance_travelled
                          ? `${historyOverview.distance_travelled} km`
                          : "-"}
                      </Typography>
                    )}
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <Typography
                      className={classes.label}
                      style={{ fontWeight: "bold" }}
                    >
                      Fuel Change:
                    </Typography>
                    {vehicleData?.get_vehicle?.has_fuel_sensor ? (
                      fuelLoading ? (
                        <Typography variant="caption">
                          <CircularProgress disableShrink size={10} />
                        </Typography>
                      ) : (
                        <Typography className={classes.info}>
                          {historyOverview.error?.length
                            ? "N/A"
                            : historyOverview.consumed_fuel || "-"}
                        </Typography>
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                  {renderDate()}
                </div>
              </div>
            </Paper>
          </Grid>
        )}
        <Grid
          item
          md={isMapView ? 12 : 9}
          style={{ height: "100vh", position: "relative" }}
        >
          <div className={classes.detailsPaper}>
            <div style={{ height: "100vh" }}>
              <Paper key={1} style={{ height: "100vh" }}>
                {/* <div style={{ width: "100%", padding: 16, overflowY: "auto" }}> */}
                <div
                  style={{
                    height: "96vh",
                    // padding: 16,
                    overflow: /* isMapView ? "hidden" : */ "auto",
                    boxSizing: "border-box"
                  }}
                >
                  {isMapView && data ? (
                    <MapView
                      renderVehicleSection={() => {
                        return (
                          <VehicleSection
                            vehicleData={vehicleData}
                            getBatteryTooltip={getBatteryTooltip}
                            getTooltip={getTooltip}
                            getStatus={getStatus}
                            setSelectorOpen={setSelectorOpen}
                            thousands_separators={thousands_separators}
                          />
                        );
                      }}
                      renderDeviceSection={() => {
                        return (
                          <DeviceSection
                            gpsData={gpsData}
                            getBatteryTooltip={getBatteryTooltip}
                            getTooltip={getTooltip}
                            getStatus={getStatus}
                            setSelectorOpen={setSelectorOpen}
                            thousands_separators={thousands_separators}
                            isFromDevices={historyDetails?.isFromDevices}
                          />
                        );
                      }}
                      isMapView={isMapView}
                      trip_logs={data.get_trip_log_v2}
                      recenter={recenter}
                      vehicleData={vehicleData}
                      primaryData={primaryData}
                      geofences={geofences}
                      itinerary={isCICO ? itineraryCICO : itinerary}
                      isCICO={isCICO}
                      deviceId={deviceId}
                      date={date}
                      setDate={setDate}
                      user={user}
                      location={location}
                      // logs={logs}
                      logs={_.uniqBy(logs, "time")}
                      handleChangeView={handleChangeView}
                    />
                  ) : (
                    <React.Fragment>
                      <Header
                        handleRecenter={handleRecenter}
                        date={date}
                        handleDate={handleDate}
                        setDateOpen={setDateOpen}
                        isPrimary={isPrimary}
                        // setOpen={setOpen}
                        locationDisabled={data?.get_trip_log_v2.length < 1}
                        handleExpandAllAlerts={handleExpandAllAlerts}
                        handleChangeView={handleChangeView}
                        isMapView={isMapView}
                        expandAllAlerts={expandAllAlerts}
                        showLogs={showLogs}
                        setShowLogs={e => setShowLogs(e)}
                        categories={categories}
                        setCategories={setCategories}
                        openFilter={openFilter}
                        setOpenFilter={setOpenFilter}
                        isFiltered={isFiltered}
                      />
                      <Itinerary
                        date={date}
                        itinerary={isCICO ? itineraryCICO : itinerary}
                        expandAllAlerts={expandAllAlerts}
                        setExpandAllAlerts={setExpandAllAlerts}
                        deviceId={deviceId}
                        historyFilterVal={
                          // history.location.state.historyFilterVal
                          // historyDetails
                          historyDetails.filteredValue
                        }
                        setDate={setDate}
                        tripNumber={tripNumber}
                        showLogs={showLogs}
                        bookingInfo={bookingInfo}
                        vehicleData={vehicleData}
                        categories={categories}
                        geoFilter={geoFilter}
                        primaryData={primaryData}
                        isCICO={isCICO}
                      />
                      <ItineraryFooter />
                    </React.Fragment>
                  )}
                </div>
                {/* <div style={{ width: "70%" }}>
                  <MapComponent
                    trip_logs={data.get_trip_log_v2}
                    timeFrame={timeFrame}
                    recenter={recenter}
                    icon={vehicleData.get_vehicle.icon}
                    heading={
                      vehicleData.get_vehicle.device_info
                        ? vehicleData.get_vehicle.device_info.device_status
                            .heading
                        : 0
                    }
                    geofences={geofences}
                    zoomProps={{ zoom: zoom, setZoom: setZoom }}
                  />
                </div> */}
                {openFilter && (
                  <Filter
                    filterDrawer={openFilter}
                    closeFilterDrawer={() => setOpenFilter(false)}
                    categories={categories}
                    setCategories={setCategories}
                    initialDate={historyDetails?.date}
                    date={date}
                    setDate={setDate}
                    geoFilter={geoFilter}
                    setGeoFilter={setGeoFilter}
                    setIsFiltered={setIsFiltered}
                  />
                )}
              </Paper>
              {/* <div style={{ height: "2%" }} /> */}
              {/* <Paper style={{ height: "43%" }}>
                <Grid container style={{ height: "100%" }}>
                  <Grid
                    item
                    xs={4}
                    style={{ padding: "24px", borderRight: "solid 1px gray" }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.graphText}>
                            At Time Selected:
                          </Typography>
                          <Typography style={{ fontSize: 14 }}>
                            ({logs.length > 0 ? logs[timeFrame].time : "-"})
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {data.get_trip_log_v2.length < 1 && (
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "red"
                            }}
                          >
                            No assigned trip during this time
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ margin: "8px 0 8px 0" }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.graphText}>
                              Speed:
                            </Typography>
                            <Typography>
                              {logs.length > 0 ? logs[timeFrame].Speed : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.graphText}>
                              Temperature:
                            </Typography>
                            <Typography>
                              {logs.length > 0
                                ? logs[timeFrame].Temperature
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "8px 0" }}>
                        <Typography className={classes.graphText}>
                          Location:
                        </Typography>
                        <Typography>-</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "8px 0" }}>
                        <Typography className={classes.graphText}>
                          Lat, Long:
                        </Typography>
                        <Typography>
                          {logs.length > 0
                            ? `${logs[timeFrame].location.lat},${logs[timeFrame].location.lon}`
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <div className={classes.graph}>
                      <Graph logs={logs} timeFrame={timeFrame} />
                    </div>
                    <div className={classes.graph}>
                      <Slider
                        style={{ width: "85%" }}
                        value={timeFrame}
                        onChange={(e, value) => handleSlider(value)}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="off"
                        step={1}
                        min={0}
                        max={logs.length > 0 ? logs.length - 1 : 0}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Monitor);
