/* global google */
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import Cookie from "js-cookie";
import InnerMap, {
  MAP_REFERENCE,
  DEFAULT_CENTER,
  MAX_ZOOM,
  DEFAULT_ZOOM
} from "./MainMap";
import Loading from "../../../../../utils/Loading";
import {
  FilterControls,
  MainControl,
  parents
} from "../../../../Monitoring/MapPartials/FilterControl";
import client from "../../../../../Client";
import {
  GET_DEVICES_MAPVIEW,
  GET_GEOFENCES,
  GET_INFO_BUBBLE
} from "../../../../../graphql/Queries";
import _, { isEmpty, isEqual } from "lodash";
import Geofences from "../../../../Monitoring/BaseMap/Geofences.map";
import {
  Marker,
  StreetViewPanorama,
  TrafficLayer
} from "@react-google-maps/api";
import SearchLocation from "../../../../Monitoring/SearchLocation";
import { IconButton } from "@material-ui/core";
import DrawingController from "../../../../Monitoring/MapPartials/DrawingController";
import Clusterer from "../../../../../utils/Map/Clusterer/Clusterer";
import DevicesContainer from "./Devices";
import Device from "./Device";

const getGeoPaths = () => {
  const path = [];
  const aNorth = MAP_REFERENCE.current
    .getBounds()
    .getNorthEast()
    .lat();
  const aEast = MAP_REFERENCE.current
    .getBounds()
    .getNorthEast()
    .lng();
  const aSouth = MAP_REFERENCE.current
    .getBounds()
    .getSouthWest()
    .lat();
  const aWest = MAP_REFERENCE.current
    .getBounds()
    .getSouthWest()
    .lng();

  path.push(
    { lat: aNorth, lon: aEast },
    { lat: aNorth, lon: aWest },
    { lat: aSouth, lon: aWest },
    { lat: aSouth, lon: aEast },
    { lat: aNorth, lon: aEast }
  );

  return path;
};

class OuterMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drawing: false,
      currentLoggedInUser: JSON.parse(Cookie.get("user")),
      geofences: [],
      devices: [],
      allDevices: [],
      isCriticalDeviceExists: false,
      clusterInstance: null,
      overlays: {
        showGeofences: false,
        showTrafficLayer: false,
        showClusterer: true,
        showVehicles: true
      },
      center: {
        lat: 12.248727944234455,
        lng: 121.95099687500002
      },
      bounds: [],
      searchedCoordinates: null,
      loading: true,
      streetView: {},
      infoBubble: []
    };
  }

  /**
   * Life-cycle methods
   */

  componentDidUpdate(prevProp, prevState) {
    const { filter } = this.props;
    const { center, allDevices, criticalVehiclesIsShown } = this.state;
    const isSameVars = isEqual(prevProp.vars, this.props.vars);
    const isSameProps = isEqual(prevProp, this.props);
    const isSameCenter = isEqual(center, this.setMapCenter());
    const isSameAllDevices = isEqual(allDevices, prevState.allDevices);
    const isSameCriticalStatus = isEqual(
      criticalVehiclesIsShown,
      prevState.criticalVehiclesIsShown
    );
    const updatedFilter = filter.condition.map(v => {
      if (v.field === "status_code_id") {
        return {
          field: "rover_status.status_code_id",
          value: v.value
        };
      } else return v;
    });

    // if (!isSameProps || !isSameAllDevices) {
    if (_.isEmpty(prevState.allDevices)) {
      this.fitVehicleMarkers(prevState.bounds);
    }

    if (!isSameCenter || !isSameProps || !isSameAllDevices) {
      this.setState({
        center: this.setMapCenter()
        // vehiclesCount: this.countVisibleVehicles()
      });
      this.setZoomSearched();
    }

    if (!isSameProps) {
      this.stopDevicesSubscription();
      this.getInfoBubble();
      this.startDevicesSubscription({
        ...updatedFilter
      });
    }

    if (!isSameCriticalStatus) {
      this.setState(
        {
          loading: true
        },
        () => {
          setTimeout(() => {
            if (criticalVehiclesIsShown) {
              const tempArray = [];
              allDevices.forEach(v => {
                if (
                  v.device_info?.device_status.AlertReferences?.some(
                    alert => alert.level >= 3
                  )
                ) {
                  tempArray.push(v);
                }
              });
              this.setState({ devices: tempArray, loading: false });
            } else {
              this.setState({ devices: allDevices, loading: false });
            }
          }, 5000);
        }
      );
    }

    if (!isSameVars) {
      // if new search keyword or apply filters, clear allDevices value
      this.setState({ allDevices: [], center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM });
    }
  }
  
  componentWillUnmount() {
    this.stopDevicesSubscription();
  }

  setMapCenter() {
    const { devices = [], searchedCoordinates } = this.state;
    // let center = { lat: 12.248727944234455, lng: 121.95099687500002 };
    let center = this.state.center;

    if (devices.length > 0) {
      const { device_status } = devices[0];
      if (!isEmpty(searchedCoordinates)) center = searchedCoordinates;
      else if (device_status) {
        const { latitude, longitude } = device_status;
        if (latitude && longitude && devices.length === 1) {
          center = { lat: latitude, lng: longitude };
        }
      } else {
        center = DEFAULT_CENTER;
      }
    } else if (!isEmpty(searchedCoordinates)) center = searchedCoordinates;

    return center;
  }

  setZoomSearched() {
    if (this.state.searchedCoordinates) {
      MAP_REFERENCE.current.setZoom(MAX_ZOOM);
    }
  }

  getGeoVars = () => {
    const { filterValues } = this.props;
    const { currentLoggedInUser } = this.state;
    const variables = {
      first: 100,
      group_ids:
        +currentLoggedInUser.user_level_id === +process.env.REACT_APP_WTI_ADMIN
          ? [] // filterValues.hauler.value
          : []
    };

    const zoom = MAP_REFERENCE?.current?.zoom;
    if (zoom >= 15) {
      Object.assign(variables, {
        bbox: getGeoPaths()
      });
    }

    return variables;
  };

  getGeofences = () => {
    return client.query({
      query: GET_GEOFENCES,
      variables: this.getGeoVars()
    });
  };

  onMapBoundsChanged = () => {
    const {
      overlays: { showGeofences }
    } = this.state;
    if (showGeofences) {
      // debounce(() => {
      // This debounce function doesn't work properly...
      this.getGeofences().then(result => {
        if (!result.loading) {
          const { geofences } = result.data.get_geofences;
          const { geofences: geofencesFromState } = this.state;
          this.setState({
            geofences
            // vehiclesCount: this.countVisibleVehicles()
          });
          // if (geofencesFromState) {
          //   if (!isEqual(geofences, geofencesFromState)) {
          //     this.setState({
          //       geofences
          //       // vehiclesCount: this.countVisibleVehicles()
          //     });
          //   }
          // } else {
          //   this.setState({
          //     geofences
          //     // vehiclesCount: this.countVisibleVehicles()
          //   });
          // }
        }
      });
      // }, 600)();
    } else {
      this.setState({
        // vehiclesCount: this.countVisibleVehicles()
      });
    }

    // if (MAP_REFERENCE?.current) {
    //   this.setState({
    //     bounds: MAP_REFERENCE.current.getBounds()
    //   });
    // }
  };

  handleChangeOverlay = e => {
    const { name, checked } = e.target;

    this.setState(state => {
      return {
        overlays: {
          ...state.overlays,
          [name]: checked
        }
      };
    });
    if (name === "showGeofences" && checked) {
      this.getGeofences().then(result => {
        if (!result.loading) {
          const { geofences } = result.data.get_geofences;
          const { geofences: geofencesFromState } = this.state;
          if (geofencesFromState) {
            if (!isEqual(geofences, geofencesFromState)) {
              this.setState(state => ({
                geofences,
                overlays: {
                  ...state.overlays,
                  [name]: checked
                }
              }));
            }
          } else {
            this.setState(state => ({
              geofences,
              overlays: {
                ...state.overlays,
                [name]: checked
              }
            }));
          }
        }
      });
    }
  };

  startDevicesSubscription = params => {
    const { clientSelection, bound, total, filter } = this.props;
    const { currentLoggedInUser } = this.state;
    this.devicesSubscription = client
      .watchQuery({
        query: GET_DEVICES_MAPVIEW,
        variables: {
          ...params,
          first: total, // total,
          keyword: filter.keyword,
          dateRange: {
            start: `${moment().format("YYYY-MM-DD")} 00:00:00`
          },
          // ...(params?.bounds ? { bounds: params.bounds } : { bounds: bound }),
          bounds: bound,
          ...(+currentLoggedInUser.user_level_id ===
            +process.env.REACT_APP_WTI_ADMIN && {
            group_ids: params?.group_ids
              ? params.group_ids
              : clientSelection.group_ids
          }),
          not: [
            {
              field: "rover_status.latitude",
              value: ["null", "", "0"]  
            },
            {
              field: "rover_status.longitude",
              value: ["null", "", "0"]  
            }
          ]
          // ...(+currentLoggedInUser.user_level_id ===
          //   +process.env.REACT_APP_WTI_ADMIN &&
          //   clientSelection.id && { group_ids: clientSelection.group_ids })
        },
        notifyOnNetworkStatusChange: true,
        pollInterval: 60 * 1000
      })
      .subscribe({
        next: result => {
          let center = { lat: 12.248727944234455, lng: 121.95099687500002 };
          if (MAP_REFERENCE.current?.getCenter()) {
            center = {
              lat: MAP_REFERENCE.current.getCenter().lat(),
              lng: MAP_REFERENCE.current.getCenter().lng()
            };
          }
          this.setState({
            loading: false,
            devices: [],
            isCriticalDeviceExists: false,
            center: center
          });
          const { devices } = result.data.get_devices;
          // eslint-disable-next-line no-shadow
          const { currentLoggedInUser, criticalVehiclesIsShown } = this.state;
          // decode the token from the cookie token and fetch the client type.
          const clientType = currentLoggedInUser.client.is_restricted;

          const filteredDevices = devices.filter(device => {
            if (device?.device_status) {
              // if (
              //   device?.device_status.AlertReferences?.some(
              //     alert => alert.level >= 3
              //   ) &&
              //   !this.state.isCriticalDeviceExists
              // ) {
              //   this.setState({ isCriticalDeviceExists: true });
              // }
              if (
                +device.client_id === +currentLoggedInUser.client.id ||
                +currentLoggedInUser.user_level_id ===
                  +process.env.REACT_APP_WTI_ADMIN
              ) {
                // If owner/primary, show all
                return true;
              }
              if (device.vehicle_info?.trip_number || !clientType) {
                // If partner/secondary, show vehicles with ongoing trip
                return true;
              }
              return false;
            }
            return false;
          });
          if (!isEqual(this.state.allDevices, filteredDevices)) {
            const tempArray = [];
            if (criticalVehiclesIsShown) {
              this.state.allDevices.forEach(d => {
                if (
                  d?.device_status.AlertReferences?.some(
                    alert => alert.level >= 3
                  )
                ) {
                  tempArray.push(d);
                }
              });
            }
            this.setState(
              {
                allDevices: filteredDevices,
                devices: criticalVehiclesIsShown ? tempArray : filteredDevices
                // vehiclesCount: this.countVisibleVehicles()
              },
              () => {
                this.setState({
                  loading: false,
                  criticalVehiclesIsShown: false
                });
              }
            );
          } else {
            this.setState({ loading: false, devices: filteredDevices });
          }
          // this.setState({
          //   loading: false,
          //   center: center,
          //   devices: filteredDevices
          // });
        },
        error: e => {
          return `message: ${e}`;
        }
      });
  };

  stopDevicesSubscription = () => {
    if (this.devicesSubscription) {
      // When map is unseen or unmounted this will stop requesting vehicle info.
      this.devicesSubscription.unsubscribe();
    }
  };

  UNSAFE_componentWillMount() {
    const { filter } = this.props;

    const updatedFilter = filter.condition.map(v => {
      if (v.field === "status_code_id") {
        return {
          field: "rover_status.status_code_id",
          value: v.value
        };
      } else return v;
    });

    this.startDevicesSubscription({
      ...updatedFilter
    });
    this.getInfoBubble();
  }

  toggleStreetView = position => {
    this.setState({ streetView: position });
  };

  getInfoBubble = () => {
    const { filterValues, clientSelection } = this.props;
    const { currentLoggedInUser } = this.state;
    client
      .query({
        query: GET_INFO_BUBBLE,
        variables: {
          client_id:
            // +currentLoggedInUser.user_level_id ===
            //   +process.env.REACT_APP_WTI_ADMIN && clientSelection?.id
            // ? `${clientSelection?.id}`
            // : currentLoggedInUser?.client?.id
            +currentLoggedInUser.user_level_id ===
            +process.env.REACT_APP_WTI_ADMIN
              ? clientSelection.id // filterValues.hauler.id
              : currentLoggedInUser?.client?.id
        }
      })
      .then(res => {
        this.setState({ infoBubble: res.data.get_info_bubble });
      });
  };

  fitVehicleMarkers = prevBounds => {
    const { allDevices } = this.state;
    const devices = allDevices;
    if (devices.length > 0) {
      let markerBounds = new google.maps.LatLngBounds();
      devices.forEach(dvc => {
        if (!dvc.device_status) {
          return null;
        }

        const { latitude, longitude } = dvc.device_status;
        if (latitude && longitude) {
          markerBounds.extend(new google.maps.LatLng(latitude, longitude));
        }
      });
      if (MAP_REFERENCE && markerBounds && !isEqual(markerBounds, prevBounds)) {
        // MAP_REFERENCE.current.setOptions({minZoom: DEFAULT_ZOOM});
        MAP_REFERENCE.current.fitBounds(markerBounds);
        this.setState({ bounds: markerBounds });
        if (MAP_REFERENCE.current.getZoom() < DEFAULT_ZOOM) {
          MAP_REFERENCE.current.setZoom(DEFAULT_ZOOM);
        }
        // MAP_REFERENCE.current.setOptions({minZoom: undefined});
      }
    }
  };

  render() {
    const { RIGHT_TOP, LEFT_TOP } = google.maps.ControlPosition;

    const {
      drawing,
      overlays,
      geofences,
      center,
      searchedCoordinates,
      devices,
      streetView,
      currentLoggedInUser,
      infoBubble,
      loading
    } = this.state;

    const { history, setDrawing, setBound } = this.props;

    return (
      <React.Fragment>
        {loading && <Loading />}
        <InnerMap
          onMapBoundsChanged={this.onMapBoundsChanged}
          devices={devices}
          center={center}
          currentLoggedInUser={currentLoggedInUser}
          searchedCoordinates={searchedCoordinates}
          fitVehicleMarkers={this.fitVehicleMarkers}
        >
          <FilterControls mapRef={MAP_REFERENCE} position={RIGHT_TOP}>
            <MainControl
              handleChangeOverlay={this.handleChangeOverlay}
              filters={overlays}
              parent={parents.DEVICES}
            />
          </FilterControls>

          <FilterControls mapRef={MAP_REFERENCE} position={LEFT_TOP}>
            <SearchLocation
              setCoordinates={coords =>
                this.setState({ searchedCoordinates: coords })
              }
            />
          </FilterControls>

          <IconButton
            onClick={() => {
              setDrawing(prev => {
                if (prev) {
                  setBound([]);
                }
                return !prev;
              });
              // this.setState(prev => ({ drawing: !prev.drawing }));
            }}
            style={{ visibility: "hidden" }}
          >
            <DrawingController
              drawing={drawing}
              mapRef={MAP_REFERENCE}
              // setBounds={setBounds}
              setBounds={() => {}}
            />
          </IconButton>
          {/*
          {permissions?.edit && (
            <IconButton
              onClick={() => {
                redirectTo(`/control_room/add`, {
                  source: `/monitoring`,
                  filterValues: filterValues,
                  vars: vars,
                  payload: {
                    zoom: MAP_REFERENCE.current.getZoom(),
                    center: {
                      lat: MAP_REFERENCE.current.getCenter().lat(),
                      lng: MAP_REFERENCE.current.getCenter().lng()
                    }
                  }
                });
              }}
              style={{ visibility: "hidden" }}
            >
              <AddControl bound={bound} mapRef={MAP_REFERENCE} />
            </IconButton>
          )}
            */}
          {searchedCoordinates && (
            <Marker
              position={searchedCoordinates}
              icon={{
                url: `${process.env.PUBLIC_URL}/assets/Markers/Dropoff 1.png`,
                scaledSize: new google.maps.Size(45, 45)
              }}
              options={{
                zIndex: 1000
              }}
            />
          )}

          {overlays.showGeofences && <Geofences geofencesData={geofences} />}
          {overlays.showTrafficLayer && <TrafficLayer autoUpdate />}
          {!!devices.length && (
            <Clusterer
              gridSize={20}
              onClusteringEnd={clusterInstance => {
                this.setState({ clusterInstance: { ...clusterInstance } });
              }}
            >
              {clusterer => (
                <DevicesContainer devices={devices}>
                  {({ id, ...otherProps }) => (
                    <Device
                      key={id}
                      {...otherProps}
                      clusterInstance={this.state.clusterInstance}
                      toggleStreetView={this.toggleStreetView}
                      history={history}
                      // filterValues={filterValues}
                      filterValues={{}}
                      // vars={vars}
                      vars={{}}
                      user={currentLoggedInUser}
                      clusterer={clusterer}
                      infoBubble={infoBubble}
                      zoom={MAP_REFERENCE.current?.zoom}
                    />
                  )}
                </DevicesContainer>
              )}
            </Clusterer>
          )}

          {/*
          {overlays.showClusterer && vehicles.length ? (
            <Clusterer
              gridSize={20}
              onClusteringEnd={clusterInstance => {
                this.setState({ clusterInstance: { ...clusterInstance } });
              }}
            >
              {clusterer => (
                <Vehicles vehicles={vehicles}>
                  {({ id, ...otherProps }) => (
                    <Vehicle
                      key={id}
                      {...otherProps}
                      clusterInstance={this.state.clusterInstance}
                      toggleStreetView={this.toggleStreetView}
                      history={history}
                      filterValues={filterValues}
                      vars={vars}
                      user={currentLoggedInUser}
                      clusterer={clusterer}
                      infoBubble={infoBubble}
                      zoom={MAP_REFERENCE.current?.zoom}
                      vehicles={vehicles}
                    />
                  )}
                </Vehicles>
              )}
            </Clusterer>
          ) : (
            overlays.showVehicles &&
            vehicles.length && (
              <Vehicles vehicles={vehicles}>
                {({ id, ...otherProps }) => (
                  <Vehicle
                    key={id}
                    {...otherProps}
                    toggleStreetView={this.toggleStreetView}
                    history={history}
                    filterValues={filterValues}
                    vars={vars}
                    user={currentLoggedInUser}
                    infoBubble={infoBubble}
                    zoom={MAP_REFERENCE.current?.zoom}
                    vehicles={vehicles}
                  />
                )}
              </Vehicles>
            )
          )}
*/}
          {!_.isEmpty(streetView) && (
            <StreetViewPanorama
              position={streetView}
              visible={!_.isEmpty(streetView)}
              onVisibleChanged={() => this.toggleStreetView({})}
            />
          )}
        </InnerMap>
      </React.Fragment>
    );
  }
}

export default withRouter(OuterMap);
