/* eslint-disable react/destructuring-assignment */
/* global google */
import React, { createRef } from "react";
import { GoogleMap } from "@react-google-maps/api";

export const mapContainerStyle = {
  position: "relative",
  width: "100%",
  height: "100%"
};

export const control_positions = {
  BOTTOM: 11,
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT: 7,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP: 2,
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3
};

export const mapRef = createRef();
export const getGeoPaths = (map_ref = mapRef) => {
  const path = [];
  if (!map_ref.current?.getBounds()) return path;
  const aNorth = map_ref.current
    .getBounds()
    .getNorthEast()
    .lat();
  const aEast = map_ref.current
    .getBounds()
    .getNorthEast()
    .lng();
  const aSouth = map_ref.current
    .getBounds()
    .getSouthWest()
    .lat();
  const aWest = map_ref.current
    .getBounds()
    .getSouthWest()
    .lng();

  path.push(
    { lat: aNorth, lon: aEast },
    { lat: aNorth, lon: aWest },
    { lat: aSouth, lon: aWest },
    { lat: aSouth, lon: aEast },
    { lat: aNorth, lon: aEast }
  );

  return path;
};

export const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%"
};

export const center = {
  lat: 12.248727944234455,
  lng: 121.95099687500002
};

export const loadOptions = (options = {}) => ({
  keyboardShortcuts: false,
  fullscreenControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: window?.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR || 1,
    position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM || 6,
    mapTypeIds: [
      window?.google?.maps?.MapTypeId?.ROADMAP || "roadmap",
      window?.google?.maps?.MapTypeId?.SATELLITE || "satellite",
      window?.google?.maps?.MapTypeId?.HYBRID || "hybrid"
    ]
  },
  ...options
});

class Inner extends React.Component {
  state = {
    isMapLoaded: false
  };

  render() {
    const { options: optionsFromProps, ...otherProps } = this.props;
    const options = { ...loadOptions(), ...optionsFromProps };
    return (
      <GoogleMap
        onLoad={ref => {
          mapRef.current = ref;
          this.setState({ isMapLoaded: true });
        }}
        options={options}
        mapContainerStyle={containerStyle}
        {...otherProps}
      >
        {this.state.isMapLoaded && this.props.children}
      </GoogleMap>
    );
  }
}

export default Inner;
