/*eslint-disable no-unused-vars*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import clsx from "clsx";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  alpha,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Switch
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LinkOff as LinkOffIcon,
  Search as SearchIcon,
  Room as MapIcon,
  InfoOutlined as InfoOutlinedIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import lodash from "lodash";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../../../../utils/Loading";
import {
  ADMIN_LINK,
  ADMIN_UNLINK,
  EDIT_ADMIN_CLIENT
} from "../../../../../graphql/Mutations";
import { GET_ADMIN_GEOFENCES } from "../../../../../graphql/Queries";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { VIEW_CLIENT_GEOFENCES_SUBSCRIPTION } from "../../../../../graphql/Subscription";
import AddLogs from "../../../../../utils/functions/AddLogs";
import ClientAutocomplete from "../../../../../utils/ClientAutocomplete";
import useUserContext from "../../../../../context/User/useUserContext";
import _ from "lodash";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// LIST GEOFENCE HEADERS
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Geofence ID"
  },
  { id: "name", numeric: true, disablePadding: false, label: "Geofence Name" },
  {
    id: "geofence_code",
    numeric: true,
    disablePadding: false,
    label: "Geofence Code"
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category"
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address"
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: "Map"
  }
];

// ADD ENTRY TABLE VEHICLE HEADERS
const headAddEntryCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Geofence ID"
  },
  { id: "name", numeric: true, disablePadding: false, label: "Geofence Name" },
  {
    id: "geofence_code",
    numeric: true,
    disablePadding: false,
    label: "Geofence Code"
  }
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    handleSort,
    editMode,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {editMode && (
            <Checkbox
              // disabled={!editMode}
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              // checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all geofences" }}
            />
          )}
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

// TABLE COLUMN HEADERS FOR ADD ENTRY VEHICLES
function EnhancedTableHeadAddEntry(props) {
  const {
    classes,
    addEntryOrder,
    addEntryOrderBy,
    selectAllEntries,
    sortEntries,
    // numSelected,
    // rowCount,
    editMode,
    isEntriesChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!editMode}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isEntriesChecked().indeterminate}
            checked={isEntriesChecked().all}
            onChange={selectAllEntries}
            inputProps={{ "aria-label": "select all vehicles" }}
          />
        </TableCell>
        {headAddEntryCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={
              addEntryOrderBy === headCell.id ? addEntryOrder : false
            }
          >
            <TableSortLabel
              active={addEntryOrderBy === headCell.id}
              direction={
                addEntryOrderBy === headCell.id ? addEntryOrder : "asc"
              }
              onClick={() => sortEntries(headCell.id)}
            >
              {headCell.label}
              {addEntryOrderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {addEntryOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadAddEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  selectAllEntries: PropTypes.func.isRequired,
  addEntryOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  addEntryOrderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

// TOOLBAR STYLES
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    padding: "0 25px 0 0"
  },
  toggle: {
    display: "flex",
    alignItems: "center"
  },
  headerdiv: {
    flex: "1 1 25%",
    color: "#000000",
    display: "flex",
    alignItems: "center"
  },
  btn_download: {
    background: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn_upload: {
    background: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  infoIcon: {
    paddingLeft: "10px",
    opacity: 0.6,
    width: "19px"
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginRight: 20,
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");
  const {
    editMode,
    numSelected,
    numberOfRows,
    rowsPerPage,
    page,
    handleUpPage,
    handleDownPage,
    handlePagination,
    // maxGeofences,
    totalRows,
    handleOpenUnlink,
    setGeofenceKeyword,
    setPage,
    user,
    uncatGeo,
    setUncatGeo
  } = props;

  return (
    <div>
      <Toolbar className={classes.root}>
        <div className={classes.headerdiv}>
          <Typography className={classes.title} id="tableTitle" component="div">
            Geofences: {totalRows}
          </Typography>
          {+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN && (
            <div className={classes.toggle}>
              <Typography id="tableTitle" component="div">
                Uncategorized Report:{" "}
              </Typography>
              <HtmlTooltip
                placement="top-start"
                title="System will create a geofence if the vehicle stays in one location in 5mins."
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </HtmlTooltip>
              <AntSwitch
                checked={uncatGeo}
                onChange={e => {
                  setUncatGeo(e.target.checked);
                }}
                name="uncategorized"
                color="primary"
                disabled={!editMode}
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #D2D2D2",
              boxSizing: "border-box",
              borderRadius: 23,
              padding: "4px 10px 3px 10px",
              marginRight: 20
            }}
          >
            <InputBase
              placeholder="Search…"
              style={{ width: 300 }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                setKeyword(e.target.value);
                // if (e.target.value.length > 3) {
                //   setGeofenceKeyword(e.target.value);
                // } else if (e.target.value.length === 0) {
                //   setGeofenceKeyword(e.target.value);
                // }
              }}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setGeofenceKeyword(keyword);
                  setPage(0);
                }
              }}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <Typography>Rows per page:</Typography>
          <FormControl className={classes.field}>
            <Select
              value={rowsPerPage}
              onChange={e => {
                handlePagination(e);
              }}
              displayEmpty
              name="rows_per_page"
              style={{ padding: 0, margin: "0 8px" }}
              disableUnderline={!editMode}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            name="down_page"
            disabled={page === 0}
            onClick={() => handleDownPage()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          {numberOfRows > 0 ? page + 1 : page}
          {`/ ${Math.ceil(numberOfRows / rowsPerPage)}`}
          <Button
            name="up_page"
            disabled={page + 1 === Math.ceil(numberOfRows / rowsPerPage)}
            onClick={() => handleUpPage()}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      </Toolbar>

      {editMode && (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
            [classes.invisible]: numSelected <= 0
          })}
        >
          {numSelected > 0 && (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              <span style={{ color: "#FF845E" }}>{numSelected}</span> Geofence/s
              selected
            </Typography>
          )}

          {numSelected > 0 && (
            <Tooltip title="Delete">
              <Button
                onClick={() => {
                  handleOpenUnlink();
                }}
                variant="contained"
                className={classes.btn}
                style={{ borderRadius: "17px" }}
              >
                Unlink Geofences
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

// MAIN STYLES
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "70%",
    border: "2px solid rgba(209, 209, 209, 0.5)",
    borderRadius: 23
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "800%",
      "&:focus": {
        width: "100"
      }
    }
  },
  addEntryBtn: {
    color: theme.palette.primary.main
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  savebtn: {
    margin: 20,
    width: "20%",
    marginTop: 10,
    borderRadius: "100px"
  },
  divbtn: {
    display: "flex",
    justifyContent: "center"
  }
}));

// MAIN
export default function Geofences(props) {
  const clientName = JSON.parse(localStorage.getItem("client_name"));
  const classes = useStyles();
  const user = useUserContext();
  const {
    // clientId,
    editMode,
    groupIds_int,
    groupIds_str,
    group_names,
    maxGeofences,
    adminClient,
    setEditMode,
    setFormEdit,
    setCheckChanges,
    refetchGeo
    // otherGroups
  } = props;
  const { id: clientId } = useParams();
  const subsfilter = [];
  groupIds_str.map(data => {
    subsfilter.push({ field: "group_ids", value: data });
    return true;
  });
  const [geofencesData, setGeofencesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [geofenceKeyword, setGeofenceKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [countRows, setCountRows] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [unlinkDialog, setUnlinkDialog] = useState(false);
  const [toUnlink, setToUnlink] = useState([]);
  const [openAddEntry, setOpenAddEntry] = useState(false);
  const [addEntryPage, setAddEntryPage] = useState(0);
  const [addEntryTotal, setAddEntryTotal] = useState(0);
  const [addEntryGroup, setAddEntryGroup] = useState(0);
  const [entries, setEntries] = useState([]);
  const [addEntryKeyword, setAddEntryKeyword] = useState("");
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [addEntryOrder, setAddEntryOrder] = useState("asc");
  const [addEntryOrderBy, setAddEntryOrderBy] = useState("created");
  const [clientGroup, setClientGroup] = useState(null);
  const [selectedGeofenceEntries, setSelectedGeofenceEntries] = useState([]);
  const [itemsToUnlink, setItemsToUnlink] = useState([]);
  const [itemToUnlink, setItemToUnlink] = useState();
  const [uncatGeo, setUncatGeo] = useState(
    adminClient.get_client.compute_stoppage
  );
  const [uncatModal, setUncatModal] = useState(false);

  const handleAddEntryOpen = () => {
    setOpenAddEntry(true);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setEntries([]);
  };

  const handleAddEntryClose = () => {
    setOpenAddEntry(false);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setEntries([]);
    setAddEntryKeyword("");
  };
  const handleOpenUnlink = () => {
    setUnlinkDialog(true);
  };

  const handleCloseUnlink = () => {
    setUnlinkDialog(false);
  };

  const handleCloseUncatModal = () => {
    setUncatModal(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };
  const sortEntries = field => {
    if (addEntryOrderBy === field) {
      setAddEntryOrder(addEntryOrder === "asc" ? "desc" : "asc");
    } else {
      setAddEntryOrderBy(field);
      setAddEntryOrder("asc");
    }
  };
  // const selectAllEntries = event => {
  //   if (event.target.checked) {
  //     const newSelecteds =
  //       entries && entries.geofences ? entries.geofences.map(n => n.id) : [];
  //     setSelectedEntries(newSelecteds);
  //     return;
  //   }
  //   setSelectedEntries([]);
  // };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = geofencesData.map(n => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const getVariables = React.useCallback(() => {
    const variables = {
      skip: page * rowsPerPage,
      keyword: geofenceKeyword,
      keyword_fields: ["id", "name", "geofence_code"],
      first: rowsPerPage,
      group_ids: groupIds_int,
      orderBy: [{ field: orderBy, direction: order }]
    };

    return variables;
  }, [orderBy, order, rowsPerPage, page, geofenceKeyword]);

  const { data: currentData, refetch, fetchMore, subscribeToMore } = useQuery(
    GET_ADMIN_GEOFENCES,
    {
      variables: getVariables(),
      // onCompleted: () => {
      //   setPage(0);
      // },
      onError: () => {
        Swal.fire({
          icon: "error",
          // title: "Success",
          text: "Something went wrong"
        });
      },
      fetchPolicy: "network-only"
    }
  );

  const getGroupIds = () => {
    const data = [];
    data.push({ field: "group_id", value: groupIds_str });
    return data;
  };

  const getGroupIdsForSubs = () => {
    const data = [];
    for (let index = 0; index < groupIds_str.length; index += 1) {
      const element = groupIds_str[index];
      data.push({ field: "group_id", value: element });
    }
    return data;
  };
  const subscribeToNewGeofences = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: VIEW_CLIENT_GEOFENCES_SUBSCRIPTION,
      variables: {
        filter: getGroupIdsForSubs()
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.data_updated.geofence) return prev;
        const newData = subscriptionData.data.data_updated.geofence;
        const mergedData = geofencesData;
        let subsData = {};
        // const common =
        //   newData && newData.group_ids
        //     ? newData.group_ids.filter(x => groupIds_int.includes(x))
        //     : [];
        // if (common.length) {
        mergedData.unshift(newData);
        setGeofencesData(mergedData);
        subsData = {
          count: prev.get_geofences.count + 1,
          total: prev.get_geofences.total + 1,
          geofences: mergedData
        };
        // } else {
        //   subsData = {
        //     count:
        //       prev && prev.get_geofences && prev.get_geofences.count
        //         ? prev.get_geofences.count
        //         : 0,
        //     total:
        //       prev && prev.get_geofences && prev.get_geofences.total
        //         ? prev.get_geofences.total
        //         : 0,
        //     geofences:
        //       prev && prev.get_geofences && prev.get_geofences.geofences
        //         ? prev.get_geofences.geofences
        //         : []
        //   };
        // }
        return {
          ...prev,
          get_geofences: subsData
        };
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToNewGeofences();
    return () => unsubscribe();
  }, [
    // geofencesData,
    // refetch(getVariables()),
    subscribeToNewGeofences
  ]);

  const [editClient] = useMutation(EDIT_ADMIN_CLIENT);

  const handleOpenUncatModal = () => {
    const variables = {
      id: adminClient.get_client.id,
      client: {
        compute_stoppage: uncatGeo
      }
    };

    if (!uncatGeo) {
      AddLogs("Admin - Geofence", "uncategorized_geo");
    }else {
      AddLogs("Admin - Geofence", "categorized_geo");
    }

    editClient({
      variables: variables,
      onCompleted: data => {
        if (data.edit_client.success) {
          Swal.fire({
            icon: "success",
            text: "Saved successfully",
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            setEditMode(false);
          });
          refetchGeo();
        }
      }
    });
  };

  const { data: entriesData, fetchMore: fetchMoreEntries } = useQuery(
    GET_ADMIN_GEOFENCES,
    {
      skip: !currentData,
      variables: {
        keyword: addEntryKeyword,
        keyword_fields: ["id", "name", "geofence_code"],
        skip: 0,
        not: getGroupIds(),
        first: rowsPerPage,
        group_ids: addEntryGroup,
        orderBy: [{ field: addEntryOrderBy, direction: addEntryOrder }]
      },
      // onCompleted: () => {
      //   setAddEntryPage(0);
      // },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (currentData) {
      setGeofencesData(currentData.get_geofences.geofences);
      setTotalRows(currentData.get_geofences.total);
      setCountRows(currentData.get_geofences.count);
      setLoading(false);
    }
    if (entriesData) {
      setEntries(entriesData.get_geofences);
      setAddEntryTotal(entriesData.get_geofences.total);
    }
  }, [currentData, entriesData]);

  const [adminUnlink] = useMutation(ADMIN_UNLINK, {
    onCompleted: () => {
      setSelected([]);
      setItemsToUnlink([]);
      setItemToUnlink();
      refetch(getVariables());
      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Unlinked successfully",
        showConfirmButton: false,
        timer: 1000
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  });
  const [adminLink] = useMutation(ADMIN_LINK, {
    onCompleted: () => {
      refetch(getVariables());
      handleAddEntryClose();
      AddLogs(
        "Admin - Clients",
        "link_geofence",
        `${selectedGeofenceEntries
          .toString()
          .replace(/,/g, ", ")} to ${clientName}`
      );
      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Linked successfully",
        showConfirmButton: false,
        timer: 1000
      });
      setLoading(false);
    },
    onError: () => {
      handleAddEntryClose();
      setLoading(false);
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
  });
  const unlinkGeofence = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);
    adminUnlink({
      variables: {
        target: toUnlink,
        group: { id: groupIds_int[0], name: group_names[0] },
        model: "geofences"
      }
    });
    AddLogs(
      "Admin - Clients",
      "unlink_geofence",
      `${itemToUnlink.toString().replace(/,/g, ", ")} to ${clientName}`
    );
  };

  const unlinkGeofences = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);
    adminUnlink({
      variables: {
        target: selected,
        group: { id: groupIds_int[0], name: group_names[0] },
        model: "geofences"
      }
    });
    AddLogs(
      "Admin - Clients",
      "unlink_geofence",
      `${itemsToUnlink
        .map(row => `${row.geofence_code} - ${row.name}`)
        .toString()
        .replace(/,/g, ", ")} to ${clientName}`
    );
  };
  const handleToUnlink = id => {
    if (!toUnlink.some(row_id => row_id === id)) setToUnlink([...toUnlink, id]);
  };
  const handlePagination = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    refetch(getVariables());
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const clickEntry = (event, name) => {
    const selectedIndex = selectedEntries.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEntries, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEntries.slice(1));
    } else if (selectedIndex === selectedEntries.length - 1) {
      newSelected = newSelected.concat(selectedEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEntries.slice(0, selectedIndex),
        selectedEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedEntries(newSelected);
  };

  const clickGeofenceEntry = (event, name) => {
    const selectedIndex = selectedGeofenceEntries.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGeofenceEntries, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGeofenceEntries.slice(1));
    } else if (selectedIndex === selectedGeofenceEntries.length - 1) {
      newSelected = newSelected.concat(selectedGeofenceEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGeofenceEntries.slice(0, selectedIndex),
        selectedGeofenceEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedGeofenceEntries(newSelected);
  };

  const onItemsToUnlinkChanged = (event = null, geofence) => {
    if (event) {
      const { checked } = event.target;
      if (lodash.some(itemsToUnlink, geofence)) {
        if (!checked) {
          const temp = itemsToUnlink.filter(
            geofences => !lodash.isEqual(geofences, geofence)
          );
          setItemsToUnlink(temp);
        }
      } else if (checked) {
        setItemsToUnlink(prev => [...prev, geofence]);
      }
    } else if (!lodash.some(itemsToUnlink, geofence)) {
      setItemsToUnlink(prev => [...prev, geofence]);
    }
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  const isEntrySelected = id => selectedEntries.indexOf(id) !== -1;

  const handleUpPage = () => {
    const nextPage = page + 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page + 1);
      //     setGeofencesData(fetchMoreResult.get_geofences.geofences);
      //     return {
      //       ...prev,
      //       get_geofences: {
      //         count: fetchMoreResult.get_geofences.count,
      //         total: fetchMoreResult.get_geofences.total,
      //         geofences: fetchMoreResult.get_geofences.geofences
      //       }
      //     };
      //   }
      // });
    }
  };

  const handleDownPage = () => {
    const nextPage = page - 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page - 1);
      //     setGeofencesData(fetchMoreResult.get_geofences.geofences);
      //     return {
      //       ...prev,
      //       get_geofences: {
      //         count: fetchMoreResult.get_geofences.count,
      //         total: fetchMoreResult.get_geofences.total,
      //         geofences: fetchMoreResult.get_geofences.geofences
      //       }
      //     };
      //   }
      // });
    }
  };

  const entriesNextPage = () => {
    const nextPage = addEntryPage + 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage + 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_geofences);
          return {
            ...prev,
            get_geofences: {
              count: fetchMoreResult.get_geofences.count,
              total: fetchMoreResult.get_geofences.total,
              vehicles: fetchMoreResult.get_geofences.geofences
            }
          };
        }
      });
    }
  };

  const entriesPreviousPage = () => {
    const nextPage = addEntryPage - 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage - 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_geofences);
          return {
            ...prev,
            get_geofences: {
              count: fetchMoreResult.get_geofences.count,
              total: fetchMoreResult.get_geofences.total,
              vehicles: fetchMoreResult.get_geofences.geofences
            }
          };
        }
      });
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }
  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    return geofencesData && geofencesData.length
      ? stableSort(geofencesData, getSorting(order, orderBy)).slice(
          0,
          rowsPerPage
        )
      : [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < getData().length) {
      const displayedData = getData().map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };
  const handleSelectAllClick = event => {
    const tempItemsToUnlink = itemsToUnlink;
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);

      const currentPageData = getData();
      const newItemsToUnlink = tempItemsToUnlink.filter(
        device => !lodash.some(currentPageData, device)
      );

      setItemsToUnlink(newItemsToUnlink);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => {
        tempSelected.push(n.id);
        tempItemsToUnlink.push(n);
        return n;
      });
      setSelected(lodash.uniq(tempSelected));
      setItemsToUnlink(lodash.uniq(tempItemsToUnlink));
      return;
    }
    deselect();
  };
  const getEntryData = () => {
    return entries && entries?.geofences?.length
      ? stableSort(entries.geofences, getSorting(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : [];
  };

  const isEntriesChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selectedEntries.length < getEntryData().length) {
      const displayedData = getEntryData().map(data => data.id);
      if (displayedData.every(data => selectedEntries.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selectedEntries.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const selectAllEntries = event => {
    const tempSelectedGeofence = selectedGeofenceEntries;
    const tempSelected = selectedEntries;
    const deselect = () => {
      const displayedData = getEntryData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelectedEntries(newSelected);

      const displayedGeofenceData = getEntryData().map(
        n => `${n.geofence_code} - ${n.name}`
      );
      const newSelectedDevice = tempSelectedGeofence
        .filter(name => displayedGeofenceData.indexOf(name) < 0)
        .map(name => name);
      setSelectedGeofenceEntries(newSelectedDevice);
    };

    if (event.target.checked) {
      if (isEntriesChecked().indeterminate) {
        deselect();
        return;
      }
      getEntryData().map(n => tempSelected.push(n.id));
      setSelectedEntries(lodash.uniq(tempSelected));

      getEntryData().map(n =>
        tempSelectedGeofence.push(`${n.geofence_code} - ${n.name}`)
      );
      setSelectedGeofenceEntries(lodash.uniq(tempSelectedGeofence));
      return;
    }
    deselect();
  };

  useEffect(() => {
    if (_.isEqual(uncatGeo, adminClient.get_client.compute_stoppage)) {
      setFormEdit(false);
      setCheckChanges(false);
    } else {
      setFormEdit(true);
      setCheckChanges(true);
    }
    if (!editMode) setUncatGeo(adminClient.get_client.compute_stoppage);
  }, [uncatGeo, editMode]);

  const addEntryBody = (
    <Paper
      style={{
        marginTop: "10%",
        marginBottom: "10%",
        marginLeft: "20%",
        marginRight: "20%",
        padding: 10
      }}
    >
      <Grid container>
        <Grid
          item
          xs={1}
          align="center"
          style={{ marginRight: 20, marginLeft: 10 }}
        >
          <Typography>
            <Box
              fontWeight="fontWeightBold"
              style={{ width: 100, padding: 10 }}
            >
              Add Entry
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
          {/* <Autocomplete
            options={otherGroups || []}
            getOptionLabel={option => option.name}
            renderOption={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black", fontWeight: "bold" }
                }}
                style={{ width: "40%", background: "#E9E9E9", borderRadius: 6 }}
                placeholder="Client name"
              />
            )}
            name="client_group"
            onChange={(e, nv) => {
              setClientGroup(nv);
              setAddEntryGroup(nv.id);
              setSelectedEntries([]);
            }}
            value={clientGroup}
            disableClearable
          /> */}
          <ClientAutocomplete
            setter={setClientGroup}
            onChange={(e, nv) => {
              setClientGroup(nv);
              setAddEntryGroup(nv.group_ids);
              setSelectedEntries([]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                if (e.target.value.length > 3) {
                  setAddEntryKeyword(e.target.value);
                } else if (e.target.value.length === 0) {
                  setAddEntryKeyword(e.target.value);
                }
              }}
              inputProps={{ "aria-label": "search" }}
            />
            <IconButton
              name="down_page"
              disabled={addEntryPage === 0}
              onClick={() => entriesPreviousPage()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            {addEntryTotal ? addEntryPage + 1 : 0}
            {` / ${Math.ceil(addEntryTotal / rowsPerPage)}`}
            <Button
              name="up_page"
              disabled={
                addEntryPage + 1 === Math.ceil(addEntryTotal / rowsPerPage)
              }
              onClick={() => entriesNextPage()}
            >
              <KeyboardArrowRightIcon />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            style={{
              height: 250,
              border: "1px solid rgba(209, 209, 209, 0.58)"
            }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHeadAddEntry
                classes={classes}
                numSelected={selectedEntries.length}
                addEntryOrder={addEntryOrder}
                addEntryOrderBy={addEntryOrderBy}
                selectAllEntries={selectAllEntries}
                editMode={editMode}
                rowCount={
                  entries && entries.geofences ? entries.geofences.length : 0
                }
                sortEntries={sortEntries}
                isEntriesChecked={isEntriesChecked}
              />
              <TableBody>
                {entries &&
                  entries.geofences &&
                  getEntryData().map((row, index) => {
                    const isItemSelected = isEntrySelected(row.id);
                    const labelId = `entry-checkbox-${index}`;
                    return (
                      <TableRow
                        key={`entries-${row.id}`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            disabled={!editMode}
                            onClick={event => {
                              clickEntry(event, row.id);
                              clickGeofenceEntry(
                                event,
                                `${row.geofence_code} - ${row.name}`
                              );
                            }}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id ? row.id : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name ? row.name : "-"}
                        </TableCell>
                        <TableCell>
                          {row.geofence_code ? row.geofence_code : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="contained"
            onClick={() => {
              handleAddEntryClose();
            }}
            style={{
              border: "1px solid  #FF845E",
              color: "#FF845E",
              background: "#FFFFFF",
              borderRadius: 17,
              margin: 10,
              width: 100
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            style={{
              // background: "#3CA4F7",
              // color: "#FFFFFF",
              borderRadius: 17,
              margin: 10,
              width: 100
            }}
            onClick={() => {
              if (selectedEntries.length) {
                adminLink({
                  variables: {
                    target: selectedEntries,
                    group: { id: groupIds_int[0], name: group_names[0] },
                    model: "geofences"
                  }
                });
                setLoading(true);
              } else {
                handleAddEntryClose();
                Swal.fire({
                  icon: "error",
                  // title: "Success",
                  text: "Please select geofences to Link!"
                });
              }
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
  const GeofencesTable = () => {
    return geofencesData && geofencesData.length ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              {row.client_id !== clientId && editMode ? (
                <TableCell
                  disabled={!editMode}
                  padding="checkbox"
                  onClick={event => {
                    handleClick(event, row.id);
                    onItemsToUnlinkChanged(event, row);
                  }}
                >
                  {editMode && (
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  )}
                </TableCell>
              ) : (
                <TableCell padding="checkbox" />
              )}
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.id ? row.id : "-"}
              </TableCell>
              <TableCell>{row.name ? row.name : "-"}</TableCell>
              <TableCell>
                {row.geofence_code ? row.geofence_code : "-"}
              </TableCell>
              <TableCell>{row.category ? row.category : "-"}</TableCell>
              <TableCell>{row.address ? row.address : "-"}</TableCell>
              <TableCell>
                <Link
                  style={{ color: "#B6B6B6" }}
                  to="route"
                  target="_blank"
                  onClick={event => {
                    event.preventDefault();
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${row.location.lat},${row.location.lon}`
                    );
                  }}
                >
                  <MapIcon />
                </Link>
              </TableCell>

              {row.client_id !== clientId ? (
                <TableCell component="th" scope="row">
                  <IconButton
                    disabled={!editMode}
                    aria-label="unlink"
                    onClick={() => {
                      handleToUnlink(row.id);
                      setItemToUnlink(`${row.geofence_code} - ${row.name}`);
                      // onItemsToUnlinkChanged(null, row);
                      handleOpen();
                    }}
                  >
                    <LinkOffIcon />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell component="th" scope="row">
                  -
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={7} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: " inherit"
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          padding: 20,
          background: "#FFFFFF",
          minHeight: "740px"
        }}
      >
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            editMode={editMode}
            handleOpenUnlink={handleOpenUnlink}
            totalRows={totalRows}
            maxGeofences={maxGeofences}
            numSelected={selected.length}
            numberOfRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePagination={handlePagination}
            handleUpPage={handleUpPage}
            handleDownPage={handleDownPage}
            setGeofenceKeyword={setGeofenceKeyword}
            setPage={setPage}
            user={user}
            uncatGeo={uncatGeo}
            setUncatGeo={setUncatGeo}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                editMode={editMode}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={geofencesData.length}
                handleSort={handleSort}
                isChecked={isChecked}
              />
              <GeofencesTable
                selected={selected}
                geofencesData={geofencesData}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>{" "}
          {editMode && (
            <div style={{ textAlign: "right" }}>
              <Button
                className={classes.addEntryBtn}
                style={{ fontWeight: "bold" }}
                align="right"
                onClick={handleAddEntryOpen}
              >
                Add Entry
              </Button>
            </div>
          )}
        </Paper>
        {editMode && +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN && (
          <div className={classes.divbtn}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.savebtn}
              onClick={() => setUncatModal(true)}
            >
              Save Details
            </Button>
          </div>
        )}
        <Modal
          open={openAddEntry}
          onClose={handleAddEntryClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {addEntryBody}
        </Modal>
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={() => unlinkGeofence()}
          title="Unlink Geofence"
          content="Are you sure you want to unlink this geofence?"
        />
        <ConfirmationDialog
          toggle={unlinkDialog}
          close={() => handleCloseUnlink()}
          fn={() => unlinkGeofences()}
          title="Unlink Geofences"
          content="Are you sure you want to unlink these geofences?"
        />
        <ConfirmationDialog
          toggle={uncatModal}
          close={() => handleCloseUncatModal()}
          fn={() => handleOpenUncatModal()}
          title="Save?"
          content="Are you sure you want to save the changes made?"
        />
      </Container>
    </div>
  );
}
