/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
  Button,
  styled
} from "@material-ui/core";
import Swal from "sweetalert2";
import {
  Map as MapIcon,
  List as ListIcon
} from "@material-ui/icons";
import _ from "lodash";
import ListView from "./ListView";
import { statusColor } from "../../Utils";
import MapView from "./MapView";
import AddLogs from "../../../utils/functions/AddLogs";
import useUserContext from "../../../context/User/useUserContext";

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  expandable: {
    verticalAlign: "top"
  },
  subCell: {
    display: "block",
    marginTop: "20px"
  },
  button: {
    margin: 4,
    borderRadius: 32
  },
  reorderButton: {
    margin: 4,
    color: "#F0AA8F",
    borderRadius: 32,
    border: "1px solid #F0AA8F"
  },
  iconBtn: {
    fontSize: 18,
    marginRight: 10
  }
}));

const headCells = [
  {
    id: "row_num",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "so_number",
    numeric: false,
    disablePadding: false,
    label: "SO number"
  },
  {
    id: "stops",
    numeric: false,
    disablePadding: false,
    label: "Stops"
  },
  {
    id: "plan_arrival",
    numeric: false,
    disablePadding: false,
    label: "Plan Arrival Time"
  },
  {
    id: "actual_arrival",
    numeric: false,
    disablePadding: false,
    label: "Actual Arrival Time"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  { id: "action", numeric: false, disablePadding: false, label: "" }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const DetailsTable = props => {
  const classes = useStyles();
  const {
    rawTripData,
    parsedTripData,
    isEdit,
    setParsedTripData,
    manualCompleteMode,
    mapView,
    mode,
    setMode,
    getGroupIds
  } = props;

  useEffect(() => {
    if (mapView && Object.entries(parsedTripData).length > 0) {
      setMode("map");
    }
  }, [mapView, parsedTripData]);

  const renderScreen = () => {
    let screen = "";
    switch (mode) {
      case "list":
        screen = (
          <ListView
            // rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            isEdit={isEdit}
            setParsedTripData={setParsedTripData}
            manualCompleteMode={manualCompleteMode}
          />
        );
        break;
      case "map":
        screen = (
          <MapView
            rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            getGroupIds={getGroupIds}
          />
        );
        break;
      default:
        break;
    }
    return screen;
  };

  const toggleMode = () => {
    let newMode = "";
    if (mode === "list" || mode === "reorder") {
      newMode = "map";
    } else {
      newMode = "list";
    }

    setMode(newMode);
  };

  return (
    <ItineraryContainer>
      <ItineraryDetailBox
        container
        style={mode === "map" ? {overflow: "hidden"} : {overflowX: "hidden"}}
      >
        <Grid item xs={6}>
          <ItineraryHeader>
            ITINERARY
          </ItineraryHeader>
        </Grid>
        {/* {!_.isEmpty(user) && ( */}
          <Grid item xs={6}>
            <div style={{ float: "right", margin: "10px 10px 8px 0" }}>
              {mode === "list" ? (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    if (
                      rawTripData &&
                      [null, "null", 0, "0"].includes(
                        rawTripData.bookings[0].value[0].vehicle_id
                      )
                    ) {
                      Swal.fire({
                        html: `${"<p class='custom-title'>Map View Unavailable</p>" +
                          "<p class='custom-subtitle'><small>No GPS paired with the assigned vehicle</small></p>"}`,
                        // icon: "success",
                        showConfirmButton: true,
                        width: "28rem",
                        buttonsStyling: false,
                        focusConfirm: false,
                        confirmButtonText: "Ok",
                        customClass: {
                          confirmButton: "custom-confirm-btn",
                          container: "custom-container"
                        }
                      });
                      return;
                    }
                    toggleMode();
                    AddLogs(
                      process.env.REACT_APP_DISPATCH_MODULE,
                      "view_map_trip",
                      parsedTripData.trip_number
                    );
                  }}
                >
                  <MapIcon className={classes.iconBtn}/>
                  Map View
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={toggleMode}
                >
                  <ListIcon className={classes.iconBtn} />
                  List View
                </Button>
              )}
            </div>
          </Grid>
        {/* )} */}
        <IteneraryItems>
          {Object.entries(parsedTripData).length > 0 && renderScreen()}
        </IteneraryItems>
      </ItineraryDetailBox>
      <br />
      <Grid container style={{ padding: "0px 8px" }}>
        <Grid item xs={4}>
          <Typography component="span" style={{ fontWeight: "bold" }}>
            STOPS:
          </Typography>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: "#8800A0",
              marginLeft: 20
            }}
          />
          <span> Pick Up </span>
          &nbsp;
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: "#1E80F0"
            }}
          />
          <span> Drop Off </span>
          &nbsp;
        </Grid>
        <Grid item xs={8}>
          <Typography component="span" style={{ fontWeight: "bold" }}>
            STATUS:
          </Typography>
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(0),
              marginLeft: 20
            }}
          />
          <span> Not yet arrived </span>
          &nbsp;
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(15)
            }}
          />
          <span> Arrived </span>
          &nbsp;
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(4),
              marginLeft: 20
            }}
          />
          <span> Completed </span>
          &nbsp;
          <div
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              background: statusColor(28)
            }}
          />
          <span> Manually Completed </span>
          &nbsp;
        </Grid>
      </Grid>
    </ItineraryContainer>
  );
};

const ItineraryContainer = styled("div")(({ theme }) => ({
  padding: "10px"
}));

const ItineraryDetailBox = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "10px"
}));

const IteneraryItems = styled("div")(({ theme }) => ({
  height: "calc(100vh - 255px)",
  borderTop: `1px solid ${theme.palette.divider}`,
  width: "100%"
}));

const ItineraryHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  padding: "1rem",
  fontSize: "15px",
  color: theme.palette.primary.dark,
}))
export default DetailsTable;
