import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Grid,
  styled
} from "@material-ui/core";
import Swal from "sweetalert2";
import _ from "lodash";
import querystring from "query-string";
import DetailsTable from "./DetailsTable";
import ListTripDetails from "../utils/paper/ListTripDetails";
// import { GET_BOOKINGS } from "../../../api/bookingRepository";
import { VALIDATE_DIMS } from "../../../graphql/Queries";
// import { BOOKINGS_SUBSCRIPTION } from "../../../graphql/Subscription";
import AccessControl from "../../../utils/AccessControl";
import Loading from "../../../utils/Loading";
// import CloseDialog from "../utils/modal/CloseDialog";
// import AddLogs from "../../../utils/functions/AddLogs";
import useUserContext from "../../../context/User/useUserContext";
// import MapView from "./MapView";
import { getTripStatus } from "../../Utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100vw",
    marginBottom: theme.spacing(2),
    paddingLeft: 12
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  }
}));
const TripDetails = props => {
  const classes = useStyles();
  const { location } = props;
  const [manualCompleteMode, setManualCompleteMode] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [group_ids, setGroup_ids] = useState(null);
  const [token, setToken] = useState("");
  const [urlState, setUrlState] = useState({
    trip_numbers: "",
    code: "",
    group_ids: [],
  });
  const [parsing, setParsing] = useState(true);
  // const [permissions, setPermissions] = useState({
  //   change_truck: { view: false, edit: false },
  //   dispatch: { view: false, edit: false },
  //   manual_complete: { view: false, edit: false },
  //   map_view: { view: true, edit: true },
  //   reorder: { view: false, edit: false },
  //   split: { view: false, edit: false },
  //   close: { view: false, edit: false }
  // });
  // const { data: permission_data } = useQuery(DISPATCH_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id
  //   },
  //   skip: _.isEmpty(user),
  //   fetchPolicy: "network-only"
  // });

  const user_context = useUserContext();
  const default_permissions = {
    change_truck: { view: false, edit: false },
    dispatch: { view: false, edit: false },
    manual_complete: { view: false, edit: false },
    map_view: { view: true, edit: true },
    reorder: { view: false, edit: false },
    split: { view: false, edit: false },
    close: { view: false, edit: false }
  };
  const permissions = React.useMemo(() => {
    if (!Object.keys(user_context.allowed_permissions || {}).length) {
      return default_permissions;
    }

    const { allowed_permissions } = user_context;
    return {
      change_truck: allowed_permissions["/trips/change_truck/"],
      dispatch: allowed_permissions["/trips/dispatch/"],
      manual_complete: allowed_permissions["/trips/manual_complete/"],
      map_view: allowed_permissions["/trips/map_view/"],
      reorder: allowed_permissions["/trips/reorder/"],
      split: allowed_permissions["/trips/split/"],
      close: allowed_permissions["/trips/close/"]
    };
  }, [user_context.allowed_permissions]);

  // useEffect(() => {
  //   if (permission_data) {
  //     setPermissions(permission_data);
  //   }
  // }, [permission_data]);
  useEffect(() => {
    if (!_.isEmpty(location.state)) {
      const {
        rawTripData: propRawTripData,
        isEdit,
        mapView: mapViewProps,
        tripData: tripDataProps
      } = location.state;

      setManualCompleteMode(isEdit);
      // set_trip_number(propRawTripData.key);
      setMapView(mapViewProps);
      // setTripData(tripDataProps);
      setParsing(false);
      // setRawTripData(propRawTripData);
    } else {
      const locationParams = querystring.parse(location.search);
      const {
        trip_number: tripNumberParams,
        token: tokenProps,
        group_ids: groupProps
      } = locationParams;
      setUrlState({
        trip_numbers: tripNumberParams,
        code: tokenProps,
        group_ids: groupProps.split(",").map(Number)
      });
      // set_trip_number(tripNumberParams);
      setToken(tokenProps);
      setGroup_ids(groupProps);
      setParsing(false);
    }
  }, []);

  const [editPermission, setEditPermission] = useState(false); // eslint-disable-line
  const [confirmOpen, setConfirmOpen] = useState(false);
  const start = "1970-01-01 00:00:00";
  const end = "2100-01-01 23:59:59";
  const uploaderEl = useRef(null);
  const [parsedTripData, setParsedTripData] = useState({});

  const [originalCount, setOriginalCount] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [mode, setMode] = useState("list");

  const parseData = async ({ get_bookings_with_ongoing_per_trip }) => {
    const { bookings: trips } = get_bookings_with_ongoing_per_trip;
    const response = {
      rawTripData: trips
    };
    let parsedTrip = {};
    let orig_count = 0;
    _.forEach(trips, trip => {
      const status_ids = [];
      const pickups = [];
      const dropoffs = [];
      const so_numbers = [];
      const raw_pickups = [];
      const raw_dropoffs = [];
      _.forEach(trip.value, so => {
        _.forEach(so.pickups, pickup => {
          const exist = _.findIndex(pickups, {
            geofence_id: pickup.geofence_id,
            arrival: pickup.arrival
          });
          raw_pickups.push(pickup);
          if (exist === -1) {
            const pu = {
              ...pickup,
              is_pickup: true,
              so_numbers: [...so_numbers, pickup.so_number]
            };
            pickups.push(pu);
          } else {
            pickups[exist].so_numbers.push(pickup.so_number);
            pickups[exist].is_pickup = true;
          }
        });
        _.forEach(so.dropoffs, (dropoff, index) => {
          // const items = so.items
          //   ? so.items.filter(item => item.dropoff_index === index)
          //   : [];
          // const drop = {
          //   ...dropoff,
          //   items: items,
          //   trip_id: so.id,
          //   is_pickup: false
          // };
          // dropoffs.push(drop);
          const exist = _.findIndex(dropoffs, {
            geofence_id: dropoff.geofence_id,
            arrival: dropoff.arrival
          });
          raw_dropoffs.push({ ...dropoff, items: so.items, trip_id: so.id });
          if (exist === -1) {
            const drop = {
              ...dropoff,
              so_numbers: [...so_numbers, dropoff.so_number]
            };
            dropoffs.push(drop);
          } else {
            dropoffs[exist].so_numbers.push(dropoff.so_number);
          }
        });
        status_ids.push(so.status_code_id);
        parsedTrip = {
          ...parsedTrip,
          client_id: so.client_id,
          hauler: so.client_name,
          vehicle_status: so.vehicle_status,
          vehicle_history: so.vehicle_history,
          vehicle_type: so.vehicle_type,
          vehicle_plate_no: so.vehicle_plate_no,
          service_type: so.service_type,
          vehicle_id: so.vehicle_id,
          driver: so.personnels && so.personnels[0].name,
          // trip_status:
          //   so.status_code.toLowerCase() === "accepted" ||
          //   so.status_code.toLowerCase() === "scheduled"
          //     ? "Processing"
          //     : so.status_code,
          has_split:
            so.status_code.toLowerCase() === "accepted" ||
            so.status_code.toLowerCase() === "scheduled" ||
            so.status_code.toLowerCase() === "arrived at pickup",
          raw_trip_status: so.status_code,
          out_of_route: so.out_of_route,
          dispatch_type: so.dispatch_type,
          status_code_id: so.status_code_id
        };
      });
      parsedTrip = {
        ...parsedTrip,
        trip_number: trip.key,
        raw_dropoffs: raw_dropoffs,
        pickups: pickups,
        dropoffs: dropoffs,
        raw_pickups: raw_pickups,
        has_empty_so: _.filter(trip.value, t => !t.so_number).length > 0,
        trip_status: getTripStatus(trip.value)
      };
      orig_count =
        _.filter(dropoffs, d => {
          return (
            (d.manual_arrival === null && d.actual_arrival === null) ||
            (d.manual_departure === null && d.actual_departure === null)
          );
        }).length +
        _.filter(pickups, p => {
          return (
            (p.manual_arrival === null && p.actual_arrival === null) ||
            (p.manual_departure === null && p.actual_departure === null)
          );
        }).length;
    });

    // setRawTripData(response.rawTripData[0]);
    setParsedTripData(parsedTrip);
    setOriginalCount(orig_count);
  };

  // const { history: refresher } = useHistory();

  const getGroupIds = () => {
    return group_ids
      ? group_ids.split(",").map(group_id => parseInt(group_id, 10))
      : [];
  };

  // const {
  //   data: booking_data,
  //   refetch,
  //   subscribeToMore,
  //   loading: bookingsLoading
  // } = useQuery(GET_BOOKINGS, {
  //   variables: {
  //     skip: 0,
  //     rows_per_page: tripData ? tripData.total : 1000,
  //     date_range: {
  //       field: "pickups.arrival",
  //       start: start,
  //       end: end
  //     },
  //     conditions: [
  //       {
  //         field: "trip_number.keyword",
  //         value: trip_number
  //       }
  //     ],
  //     group_ids: getGroupIds()
  //   },
  //   // onCompleted: data => {
  //   //   parseData(data);
  //   // },
  //   skip: !trip_number,
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: "network-only"
  // });

  const {
    data: allBookingInfo,
    loading: bookingsLoading
  } = useQuery(VALIDATE_DIMS, {
    variables: urlState,
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Token or Trip number."
      }).then(() => {
        window.location.replace("/");
      })
    },
    skip: !urlState.trip_numbers || !urlState.code || !urlState.group_ids.length
  });

  useEffect(() => {
    if (allBookingInfo) {
      parseData({get_bookings_with_ongoing_per_trip: allBookingInfo.validate_dims});
    }
  }, [allBookingInfo]);

  // const subscribeToNewNotifications = () => {
  //   const unsubscribe = subscribeToMore({
  //     document: BOOKINGS_SUBSCRIPTION,
  //     variables: {
  //       filter: [
  //         {
  //           field: "trip_number",
  //           value: trip_number
  //         }
  //       ]
  //     },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;

  //       const { booking_per_trip_updated } = subscriptionData.data;
  //       const { get_bookings_with_ongoing_per_trip } = prev;
  //       const { bookings: newData } = booking_per_trip_updated;
  //       const { bookings: oldData } = get_bookings_with_ongoing_per_trip;

  //       const updateStop = newData[0].value[0];
  //       const oldStops = oldData[0].value;

  //       const index = _.findIndex(oldStops, [
  //         "so_number",
  //         updateStop.so_number
  //       ]);

  //       const oldDataCopy = _.cloneDeep([...oldData]);
  //       oldDataCopy[0].value[index] = updateStop;

  //       const newTrip = {
  //         ...prev,
  //         get_bookings_with_ongoing_per_trip: {
  //           ...prev.get_bookings_with_ongoing_per_trip,
  //           bookings: oldDataCopy
  //         }
  //       };
  //       parseData(newTrip);
  //       // refetch();
  //       return newTrip;
  //     }
  //   });
  //   return unsubscribe;
  // };

  // useEffect(() => {
  //   const unsubscribe = subscribeToNewNotifications();
  //   return () => unsubscribe();
  // });

  // useEffect(() => {
  //   if (booking_data) {
  //     parseData(booking_data);
  //   }
  // }, [booking_data, refetch]);

  if (parsing) return <Loading />;

  return (
    <AccessControl
      resource="/trips/"
      process="dispatch"
      // setEditPermission={setEditPermission}
      shareable={token}
    >
      <div className="TripDetails">
        {bookingsLoading && <Loading />}
        <ToolBar variant="h5">View Trip Details</ToolBar>
        <GridTripDetails container>
          <Grid item xs={3}>
            {/* TRIP DETAILS */}
            <ListTripDetails
              parsedTripData={parsedTripData}
              deviceInfo={allBookingInfo?.validate_dims?.devices?.[0]}
            />
          </Grid>
          <Grid item xs={9}>
            <DetailsTable
              rawTripData={allBookingInfo?.validate_dims}
              // allBookingInfo={allBookingInfo}
              parsedTripData={parsedTripData}
              setParsedTripData={setParsedTripData}
              isEdit={isEdit}
              confirmOpen={confirmOpen}
              setConfirmOpen={setConfirmOpen}
              manualCompleteMode={manualCompleteMode}
              mapView={mapView}
              mode={mode}
              setMode={setMode}
              token={token}
              getGroupIds={getGroupIds}
              permissions={permissions}
            />
            <Paper className={classes.paper} />
          </Grid>
        </GridTripDetails>
      </div>
    </AccessControl>
  );
};

const ToolBar = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: "1rem",
}));

const GridTripDetails = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.divider
}));


export default TripDetails;