import React, { useState, useEffect } from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core";

const cardFont = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(",")
  }
});

// inLine styling
const styles = {
  property_big: {
    width: "108px",
    color: "#585858",
    fontSize: "12px",
    fontWeight: "bold"
  },
  property_small: { width: "108px", color: "#585858", fontSize: "10px" },
  value_big: {
    width: "108px",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold"
  },
  value_small: { width: "108px", color: "white", fontSize: "10px" }
};

// useStyles styling
const useStyles = makeStyles(() => ({
  selected: {
    height: "306px",
    width: "248px",
    background: "#F49400",
    borderRadius: "16px",
    marginLeft: "24px",
    marginBottom: "24px",
    border: "2px solid",
    borderColor: "#535353",
    cursor: "pointer"
  },
  deselected: {
    height: "306px",
    width: "248px",
    background: "#F49400",
    borderRadius: "16px",
    marginLeft: "24px",
    marginBottom: "24px",
    border: "2px solid",
    borderColor: "#F49400",
    cursor: "pointer"
  },
  selectedMark: {
    height: "40px",
    width: "40px",
    background: "linear-gradient(130deg, #535353 30%, #F49400 50%)",
    color: "white"
  },
  deselectedMark: {
    height: "40px",
    width: "40px",
    background: "#F49400",
    color: "#F49400"
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  cardFooter: {
    color: "red",
    textAlign: "center",
    margin: "50px 0 0 0"
  }
}));

const displayLabels = [
  "personnel_employeeid",
  "personnel_name",
  "plateno",
  "status_code",
  "time_in",
  "time_out"
];

const formatProperty = property => {
  switch (property) {
    case "personnel_employeeid":
      return "Employee ID";
    case "personnel_name":
      return "Driver Name";
    case "plateno":
      return "Vehicle";
    case "status_code":
      return "Status";
    case "time_in":
      return "Time in";
    case "time_out":
      return "Time Out";
    default:
      break;
  }
};
// function to render the properties of timesheet card
// @param (props) - the array of objects containing properties and value.
// @param (division) - a number for separating the properties with a space
const displayProperties = (props, division) => {
  const propsArray = [];
  for (const property in props) {
    // add each property and value to array of string
    const formattedProperty = formatProperty(property);
    let propsObject = `${formattedProperty}:+${props[property]}`;
    if (displayLabels.includes(property)) {
      //format date and time in/out
      if (property === "time_in") {
        let dateObject = `Date:+${moment(props[property]).format(
          "MMM DD, YYYY"
        )}`;
        propsObject = `${formatProperty(property)}:+${moment(
          props[property]
        ).format("h:mm a")}`;
        propsArray.push(dateObject);
        propsObject += ` ${
          props.time_in_activity ? `(${props.time_in_activity})` : ""
        }`;
      }
      if (property === "status_code") {
        propsObject = `${formattedProperty}:+${props[property].toUpperCase()}`;
      } else if (property === "time_out") {
        propsObject = `${formatProperty(property)}:+${moment(
          props[property]
        ).format("h:mm a")}`;
        propsObject += ` ${
          props.time_out_activity ? `(${props.time_out_activity})` : ""
        }`;
        //COMPUTE WORKING DURATION HERE??
      }
      propsArray.push(propsObject);
    }
  }

  // map the properties and values
  return propsArray.map((property, index) => {
    // const splitStr = property.split(/(?=[A-Z])/)
    // split property with underscore??

    const splitStr = property.split("+");
    return (
      <div
        style={
          index === division
            ? { display: "flex", marginTop: "20px" }
            : { display: "flex" }
        }
      >
        <ThemeProvider theme={cardFont}>
          <Typography
            style={
              index < division ? styles.property_big : styles.property_small
            }
          >
            {splitStr[0]}
          </Typography>
          <Typography
            style={index < division ? styles.value_big : styles.value_small}
          >
            {splitStr[1] === "DISAPPROVED" ? (
              <span style={{ color: "red" }}>{splitStr[1]}</span>
            ) : splitStr[1] === "APPROVED" ? (
              <span style={{ color: "green" }}>{splitStr[1]}</span>
            ) : (
              splitStr[1]
            )}
          </Typography>
        </ThemeProvider>
      </div>
    );
  });
};

export default function TimeSheetCard(props) {
  const { timesheet, isSelectAll, approvalList, setApprovalList, editPermission } = props;
  const [isSelected, setIsSelected] = useState(isSelectAll);
  const classes = useStyles();

  useEffect(() => {
    if (isSelectAll) {
      setIsSelected(true);
    } else setIsSelected(false);
  }, [isSelectAll]);

  const handleSelect = () => {
    if (isSelected) {
      setIsSelected(false);
      const newArray = approvalList.filter(
        card => card.id !== timesheet.id // depending on data use "id"
      );
      // remove from array
      setApprovalList(newArray);
    } else {
      // add to array
      setApprovalList(prev => [...prev, timesheet]);
      setIsSelected(true);
    }
  };

  return (
    <Card
      onClick={
        timesheet.status_code_id !==
          +process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE && editPermission && handleSelect
      }
      className={isSelected ? classes.selected : classes.deselected}
    >
      <div
        className={isSelected ? classes.selectedMark : classes.deselectedMark}
      >
        <CheckIcon fontSize="small" />
      </div>
      <div className={classes.cardBody}>
        {displayProperties(props.timesheet, 4)}
        {timesheet.status_code_id ===
          +process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE && (
          <Typography className={classes.cardFooter}>
            Please inform the Driver to complete this Timesheet
          </Typography>
        )}
      </div>
    </Card>
  );
}
