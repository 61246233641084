/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Drawer,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemSecondaryAction,
  InputAdornment,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FilterList, Close, Event, Help } from "@material-ui/icons";
import moment from "moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_VEHICLE_TYPE_LIST, GET_GROUPS } from "../../../../graphql/Queries";
import AddLogs from "../../../../utils/functions/AddLogs";
import useFormStyles from "../../../../styles/useFormStyles";
// import Cookie from "js-cookie";
import useUserContext from "../../../../context/User/useUserContext";
import useFilterHauler from "../../../../utils/hooks/useFilterHauler";
import useDebounce from "../../../../utils/hooks/useDebounce";
import SwitchHelper from "../../../../utils/UI/Switch/SwitchHelper";

const useStyles = makeStyles(theme => ({
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: "23%",
      width: "23%"
    }
  },
  list: {
    padding: 16,
    boxSizing: "border-box",
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    overflowY: "auto",
    position: "relative"
  },
  fullList: {
    width: "auto"
  },
  filter: {
    backgroundColor: theme.palette.primary.main
  },
  helpIcon: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const Filter = props => {
  const {
    isWTIAdmin,
    open,
    setOpenFilter,
    setVtypeFilter,
    vtypeFilter,
    setDateRangeFilter,
    dateRangeFilter,
    setGStatusFilter,
    gStatusFilter,
    setVStatusFilter,
    vStatusFilter,
    setCurrentPage,
    setKeyLog,
    setHaulerFilter,
    haulerFilter,
    initialValueHauler,
    history,
    handleClearFilter,
    hasTripPrebookingAccess,
    isLogistics,
    setIsLogistics
  } = props;
  const classes = useStyles();
  const formStyles = useFormStyles();
  // const user = JSON.parse(Cookie.get("user"))
  const user = useUserContext();
  const { data: vtypes } = useQuery(GET_VEHICLE_TYPE_LIST);
  const [vType, setVtype] = useState(vtypeFilter);
  const [status, setStatus] = useState(gStatusFilter);
  const [vstatus, setVStatus] = useState(vStatusFilter);
  const [dateRange, setDateRange] = useState(dateRangeFilter);
  const [hauler, setHauler] = useState(haulerFilter);
  const [isLogisticsFilter, setIsLogisticsFilter] = useState(isLogistics);
  const [haulerData, setHaulerData] = useState([]);
  const DEBOUNCE_TIME = 800;
  const [haulerKeyword, setHaulerKeyword] = useDebounce("", DEBOUNCE_TIME);

  // const [get_haulers] = useLazyQuery(GET_GROUPS, {
  //   onCompleted: haulerData => {
  //     if (haulerData) {
  //       setHaulerData(haulerData);
  //     }
  //   }
  // });

  const vehicleStatusOption = [
    {
      value: process.env.REACT_APP_STATUS_AVAILABLE,
      label: "Available",
      isShown: (isWTIAdmin && isLogisticsFilter) || (!isWTIAdmin && hasTripPrebookingAccess)
    },
    {
      value: process.env.REACT_APP_VEHICLE_STATUS_BOOKED,
      label: "Booked",
      isShown: (isWTIAdmin && isLogisticsFilter) || (!isWTIAdmin && hasTripPrebookingAccess)
    },
    {
      value: process.env.REACT_APP_STATUS_ACTIVE,
      label: "Active",
      isShown: (isWTIAdmin && !isLogisticsFilter) || (!isWTIAdmin && !hasTripPrebookingAccess)
    },
    { value: "1", label: "Under Repair", isShown: true },
    { value: "2", label: "Not in use", isShown: true }
  ]

  useEffect(() => {
    if (vtypeFilter.id) {
      setVtype(vtypeFilter);
    } else if (vStatusFilter) {
      setVStatus(vStatusFilter);
    } else if (gStatusFilter) {
      setStatus(gStatusFilter);
    } else if (haulerFilter.id) {
      setHauler(haulerFilter);
    } else if (dateRangeFilter.start) {
      setDateRange(dateRangeFilter);
    }
  }, [
    vtypeFilter,
    vStatusFilter,
    gStatusFilter,
    haulerFilter,
    dateRangeFilter
  ]);

  // useEffect(() => {
  //   get_haulers({
  //     variables: {
  //       first: 5
  //     }
  //   });
  // }, []);
  const haulerGroupOptions = useFilterHauler(haulerKeyword);
  function haulerOptionHandler() {
    let tempHauler = [{ id: "all", name: "All" }];
    user.client.group_ids.forEach((haulerId, index) => {
      tempHauler.push({ id: haulerId, name: user.client.group_names[index] });
    });
    return tempHauler;
  }

  return (
    <Drawer
      open={open}
      className={classes.drawer}
      onClose={() => setOpenFilter(false)}
    >
      <div style={{ position: "absolute", height: "100%", width: "100%" }}>
        <Toolbar
          className={classes.filter}
          // style={{ backgroundColor: "red" }}
        >
          <FilterList style={{ color: "white", marginRight: 16 }} />
          <Typography variant="h6" style={{ color: "white", flexGrow: 1 }}>
            Filter
          </Typography>
          <IconButton
            style={{ color: "white" }}
            onClick={() => setOpenFilter(false)}
          >
            <Close />
          </IconButton>
        </Toolbar>
        <div className={classes.list}>
          <List style={{ minHeight: "460px" }}>
            <ListItem style={{ marginBottom: 15 }}>
              <ListItemText primary="Registry Expiration" />
              <ListItemSecondaryAction>
                <Button
                  style={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    color: "black"
                  }}
                  onClick={() => {
                    // setDateRange({ ...dateRange, start: null, end: null });
                    // setVtype({ id: "all", name: "All" });
                    // setStatus("all");
                    // setVStatus("all");
                    // setHauler(initialValueHauler);
                    // setKeyLog(`Vehicle Type: All, GPS Status: All`);
                    // clearHistory()
                    handleClearFilter()
                    history();
                  }}
                >
                  Clear All
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    classes={{ root: formStyles.textFieldRoot }}
                    disabled={
                      vstatus === process.env.REACT_APP_VEHICLE_STATUS_BOOKED
                    }
                    fullWidth
                    //disableFuture
                    value={dateRange.start}
                    label="From"
                    format="MM/DD/YYYY HH:mm"
                    ampm={false}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                        color: "black",
                        letterSpacing: 1
                      }
                    }}
                    onChange={date => {
                      setDateRange({
                        ...dateRange,
                        start: moment(date).format("YYYY-MM-DD HH:mm:ss")
                      });
                      // clearHistory()
                      history();
                    }}
                    InputProps={{
                      placeholder: "Select Date/Time",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Event fontSize="small" style={{ color: "#ccc" }} />
                        </InputAdornment>
                      )
                    }}
                    showTodayButton
                    todayLabel="NOW"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    disabled={
                      vstatus === process.env.REACT_APP_VEHICLE_STATUS_BOOKED
                    }
                    classes={{ root: formStyles.textFieldRoot }}
                    fullWidth
                    //disableFuture
                    label="To"
                    format="MM/DD/YYYY HH:mm"
                    ampm={false}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                        color: "black",
                        letterSpacing: 1
                      }
                    }}
                    onChange={date => {
                      setDateRange({
                        ...dateRange,
                        end: moment(date).format("YYYY-MM-DD HH:mm:ss")
                      });
                      // clearHistory()
                      history();
                    }}
                    InputProps={{
                      placeholder: "Select Date/Time",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Event fontSize="small" style={{ color: "#ccc" }} />
                        </InputAdornment>
                      )
                    }}
                    showTodayButton
                    value={dateRange.end}
                    todayLabel="NOW"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </ListItem>
            {isWTIAdmin &&
            <ListItem>
              <SwitchHelper
                label="Use Logistics Status"
                name="is_logistics"
                tooltipPlacement="top-start"
                checkedValue={isLogisticsFilter}
                icon={<Help className={classes.helpIcon} />}
                customStyle={{ padding: 0, margin: "16px 0 8px 0" }}
                handleChangeHandler={() => {
                  setIsLogisticsFilter(!isLogisticsFilter)
                  setVStatus("all")
                }}
                tooltipTitle={
                  <>
                    Toggle on: report that will be generated will display
                    logistics (Booked/Available) status <br />
                    <br />
                    Toggle off: report that will be based on non-logistics
                    (Active) status
                  </>
                }
              />
            </ListItem>
            }
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <InputLabel shrink>Vehicle Status</InputLabel>
                <Select
                  classes={{ root: formStyles.selectRoot }}
                  // select
                  fullWidth
                  label="Vehicle Status"
                  value={vstatus}
                  onChange={e => {
                    setVStatus(e.target.value);
                    // clearHistory()
                    history();
                  }}
                  inputlabelprops={{
                    shrink: true,
                    style: { fontWeight: "bold", color: "black" }
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {vehicleStatusOption.map(opt => (
                    <MenuItem
                      value={opt.value}
                      key={opt.value}
                      style={{ display: opt.isShown ? "block" : "none" }}
                      disabled={
                        dateRange.start || dateRange.end
                          ? opt.value ===
                            process.env.REACT_APP_VEHICLE_STATUS_BOOKED
                          : false
                      }
                    >
                      {opt.label}
                    </MenuItem>
                  ))}
                  {/* {vehicleStatusOptions.map(vStat => (
                   <MenuItem key={vStat.value} value={vStat.value}>
                      {vStat.label}
                    </MenuItem> 
                  ))} */}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <Autocomplete
                  options={
                    vtypes
                      ? [
                          { id: "all", name: "All" },
                          ...vtypes.get_vehicle_types.vehicle_types
                        ]
                      : []
                  }
                  getOptionSelected={(o, v) => o.id === v.id}
                  getOptionLabel={option => option.name}
                  renderOption={option => option.name}
                  style={{ width: "100%" }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      classes={{ root: formStyles.textFieldRoot }}
                      label="Vehicle Type"
                      inputlabelprops={{
                        shrink: true,
                        style: {
                          color: "black",
                          fontWeight: "bold",
                          letterSpacing: 1
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          padding: "6px 0px"
                        }
                      }}
                    />
                  )}
                  value={vType}
                  onChange={(e, nv) => {
                    setVtype(nv);
                    // clearHistory()
                    history();
                  }}
                  disableClearable
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <Autocomplete
                  options={
                    haulerGroupOptions
                    // haulerOptionHandler()
                    // user.client.group_names
                    // haulerData && haulerData?.get_groups?.groups
                    //   ? [
                    //       { id: "all", name: "All" },
                    //       ...haulerData.get_groups.groups
                    //     ]
                    //   : []
                  }
                  getOptionSelected={(o, v) => o.value === v.value}
                  getOptionLabel={option => option.label}
                  renderOption={option => option.label}
                  style={{ width: "100%" }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      classes={{ root: formStyles.textFieldRoot }}
                      label="Hauler/Groups"
                      inputlabelprops={{
                        shrink: true,
                        style: { color: "black", fontWeight: "bold" }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          padding: "6px 0px"
                        }
                      }}
                      onChange={e => {
                        setHaulerKeyword(e.target.value);
                        // clearHistory()
                        history();
                      }}
                      // onChange={e => {
                      // get_haulers({
                      //   variables: {
                      //     first: 5,
                      //     keyword: e.target.value
                      //   }
                      // });
                      // }}
                    />
                  )}
                  value={hauler}
                  onChange={(e, nv) => {
                    setHauler(nv);
                    // clearHistory()
                    history();
                  }}
                  disableClearable
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl
                style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
                classes={{ root: formStyles.formRoot }}
              >
                <InputLabel shrink>GPS Status</InputLabel>
                <Select
                  classes={{ root: formStyles.selectRoot }}
                  // select
                  fullWidth
                  label="GPS Status"
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value);
                    // clearHistory()
                    history();
                  }}
                  inputlabelprops={{
                    shrink: true,
                    style: { fontWeight: "bold", color: "black" }
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {[
                    { value: "999", label: "Online" },
                    { value: "777", label: "Offline" },
                    { value: "888", label: "Delayed Reporting" },
                    { value: "0", label: "No GPS" }
                  ].map(opt => (
                    <MenuItem value={opt.value} key={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
          </List>
          <div
            style={{
              padding: 8,
              position: "relative",
              bottom: 0,
              width: "80%",
              left: "50%",
              transform: "translateX(-50%)"
            }}
          >
            <Button
              style={{ borderRadius: 32 }}
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                // setVtypeFilter(vType);
                // Start Activity logs status
                let selected_status = "";
                let filter = "";
                if (status === "999") {
                  selected_status = `Active`;
                } else if (status === "888") {
                  selected_status = `Delayed Reporting`;
                } else if (status === "777") {
                  selected_status = `Inactive`;
                } else if (status === "0") {
                  selected_status = `No GPS`;
                } else {
                  selected_status = `All`;
                }

                let selected_vstatus = "";
                if (vstatus === "18") {
                  selected_vstatus = `Available`;
                } else if (vstatus === "20") {
                  selected_vstatus = `Booked`;
                } else if (vstatus === "1") {
                  selected_vstatus = `Under Repair`;
                } else if (vstatus === "2") {
                  selected_vstatus = `Not in use`;
                } else {
                  selected_vstatus = `All`;
                }

                AddLogs(
                  "Admin - Vehicles/CV",
                  "filter",
                  `${filter || ``}Vehicle Type: ${vType.name ||
                    `All`}, GPS Status: ${status && selected_vstatus}`
                );
                // End Activity logs status

                if (dateRange.start && dateRange.end) {
                  filter = `Date Range: ${moment(dateRange.start).format(
                    "lll"
                  )} - ${moment(dateRange.end).format("lll")}, `;
                }

                setVtypeFilter(vType);
                setDateRangeFilter(dateRange);
                setOpenFilter(false);
                setGStatusFilter(status);
                setVStatusFilter(vstatus);
                setCurrentPage(0);
                setKeyLog(
                  `${filter || ``}Vehicle Type: ${vType.name ||
                    `All`}, GPS Status: ${status && selected_status}`
                );
                setHaulerFilter(hauler);
                setIsLogistics(isLogisticsFilter)
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Filter;
