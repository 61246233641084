/* eslint-disable no-unused-vars */
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const headCells = [
  {
    id: "employeeid",
    numeric: true,
    disablePadding: true,
    label: "Employee ID"
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Driver Name"
  },
  {
    id: "mobileno",
    numeric: true,
    disablePadding: true,
    label: "Contact Number"
  },
  {
    id: "supervisor",
    numeric: false,
    disablePadding: true,
    label: "Supervisor"
  },
  {
    id: "vehicle_id",
    numeric: false,
    disablePadding: true,
    label: "Paired Vehicle"
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, handleSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            padding="normal"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ padding: index ? "12px auto" : "12px 12px 12px 20px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="normal" style={{ padding: "12px auto" }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default EnhancedTableHead;
