import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
    no_Data_Container: {
        border: "1px dashed #D1D1D1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 350,
        flexDirection: "column",
        borderRadius: 10,
    },
    no_Data_Content: {
        textAlign: "center",
        marginTop: "0.5rem"
    }
}));
