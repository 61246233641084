/*eslint-disable no-unused-vars*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  alpha,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LinkOff as LinkOffIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import lodash from "lodash";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../../../../utils/Loading";
import { ADMIN_LINK, ADMIN_UNLINK } from "../../../../../graphql/Mutations";
import { GET_ADMIN_ALERT_REFERENCES } from "../../../../../graphql/Queries";
import { VIEW_CLIENT_ALERT_SUBSCRIPTION } from "../../../../../graphql/Subscription";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../../utils/functions/AddLogs";
import ClientAutocomplete from "../../../../../utils/ClientAutocomplete";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "alert_descriptions.description",
    numeric: false,
    disablePadding: true,
    label: "Alert Message"
  },
  {
    id: "alert_descriptions.code",
    numeric: false,
    disablePadding: false,
    label: "Alert Description"
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Alert Code",
    colSpan: 3
  },
  {
    id: "alertmessage",
    numeric: false,
    disablePadding: false,
    label: "Message Display"
  },
  {
    id: "level",
    numeric: true,
    disablePadding: false,
    label: "Alert Level"
  },
  {
    id: "device_types.name",
    disablePadding: false,
    label: "GPS Device Type",
    orderable: false
  }
  // { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

// TABLE COLUMN HEADERS FOR LIST ALERTS
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    handleSort,
    editMode,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {editMode && (
            <Checkbox
              // disabled={!editMode}
              color="primary"
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all alerts" }}
            />
          )}
        </TableCell>
        {headCells.map(headCell =>
          !headCell.id ? (
            <TableCell key={headCell.id}>{headCell.label}</TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              colSpan={headCell?.colSpan}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};
// ADD ENTRY TABLE ALERTS HEADERS
const headAddEntryCells = [
  {
    // id: "alert_references.alert_description.description",
    id: "alert_references.description",
    numeric: false,
    disablePadding: false,
    label: "Alert Message"
  },
  {
    id: "alert_references.code",
    numeric: false,
    disablePadding: false,
    label: "Alert Description"
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Alert Code",
    colSpan: 3
  },
  {
    id: "alertmessage",
    numeric: false,
    disablePadding: false,
    label: "Message Display"
  }
];
// TABLE COLUMN HEADERS FOR ADD ENTRY ALERTS
function EnhancedTableHeadAddEntry(props) {
  const {
    classes,
    addEntryOrder,
    addEntryOrderBy,
    selectAllEntries,
    sortEntries,
    // numSelected,
    // rowCount,
    editMode,
    isEntriesChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!editMode}
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isEntriesChecked().indeterminate}
            checked={isEntriesChecked().all}
            onChange={selectAllEntries}
            inputProps={{ "aria-label": "select all alerts" }}
          />
        </TableCell>
        {headAddEntryCells.map(headCell => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={
                addEntryOrderBy === headCell.id ? addEntryOrder : false
              }
              colSpan={headCell?.colSpan}
            >
              <TableSortLabel
                active={addEntryOrderBy === headCell.id}
                direction={
                  addEntryOrderBy === headCell.id ? addEntryOrder : "asc"
                }
                onClick={() => sortEntries(headCell.id)}
              >
                {headCell.label}
                {addEntryOrderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {addEntryOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadAddEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  selectAllEntries: PropTypes.func.isRequired,
  addEntryOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  addEntryOrderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

// TOOLBAR STYLES
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_download: {
    background: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn_upload: {
    background: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main
    }
  }
}));

// TOOLBAR ABOVE TABLE
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");
  const {
    editMode,
    numSelected,
    numberOfRows,
    rowsPerPage,
    page,
    handleUpPage,
    handleDownPage,
    handlePagination,
    totalRows,
    handleOpenUnlink,
    setAlertKeyword,
    setPage
  } = props;

  return (
    <div>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} id="tableTitle" component="div">
          Alerts: {totalRows}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #D2D2D2",
              boxSizing: "border-box",
              borderRadius: 23,
              padding: "4px 10px 3px 10px",
              marginRight: 20
            }}
          >
            <InputBase
              placeholder="Search…"
              style={{ width: 300 }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                setKeyword(e.target.value);
                // if (e.target.value.length >= 2) {
                //   setAlertKeyword(e.target.value);
                // } else if (e.target.value.length === 0) {
                //   setAlertKeyword(e.target.value);
                // }
              }}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setAlertKeyword(keyword);
                  setPage(0);
                }
              }}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <Typography>Rows per page:</Typography>
          <FormControl className={classes.field}>
            <Select
              value={rowsPerPage}
              onChange={e => {
                handlePagination(e);
              }}
              displayEmpty
              name="rows_per_page"
              style={{ padding: 0, margin: "0 8px" }}
              disableUnderline
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            name="down_page"
            disabled={page === 0}
            onClick={() => handleDownPage()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          {numberOfRows > 0 ? page + 1 : 0}
          {`/ ${Math.ceil(numberOfRows / rowsPerPage)}`}
          <Button
            name="up_page"
            disabled={page + 1 === Math.ceil(numberOfRows / rowsPerPage)}
            onClick={() => handleUpPage()}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      </Toolbar>
      {editMode && (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
            [classes.invisible]: numSelected <= 0
          })}
        >
          {numSelected > 0 && (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              <span style={{ color: "#FF845E" }}>{numSelected}</span> Alerts/s
              selected
            </Typography>
          )}

          {numSelected > 0 && (
            <Tooltip title="Delete">
              <Button
                onClick={() => {
                  handleOpenUnlink();
                }}
                variant="contained"
                className={classes.btn}
                style={{ borderRadius: "17px" }}
              >
                Unlink Alerts
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

// MAIN STYLES
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "70%",
    // width: "100%",
    border: "2px solid rgba(209, 209, 209, 0.5)",
    borderRadius: 23
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "800%",
      "&:focus": {
        width: "100"
      }
    }
  },
  addEntryBtn: {
    color: theme.palette.primary.main
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.main
    }
  }
}));

export default function Alerts(props) {
  const clientName = JSON.parse(localStorage.getItem("name"));
  const classes = useStyles();
  const {
    // clientId,
    editMode,
    groupIds_int,
    groupIds_str,
    group_names,
    // otherGroups
  } = props;
  const { id: clientId } = useParams();
  const [alertsData, setAlertsData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0); // setPage
  const [alertKeyword, setAlertKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // setRowsPerPage
  const [totalRows, setTotalRows] = React.useState(0);

  const [countRows, setCountRows] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [unlinkDialog, setUnlinkDialog] = useState(false);
  const [toUnlink, setToUnlink] = useState([]);

  const [openAddEntry, setOpenAddEntry] = useState(false);
  const [addEntryPage, setAddEntryPage] = useState(0);
  const [addEntryTotal, setAddEntryTotal] = useState(0);
  const [addEntryGroup, setAddEntryGroup] = useState(0);
  const [entries, setEntries] = useState([]);
  const [addEntryKeyword, setAddEntryKeyword] = useState("");
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [addEntryOrder, setAddEntryOrder] = useState("asc");
  const [addEntryOrderBy, setAddEntryOrderBy] = useState(
    "alert_references.description"
  );
  const [clientGroup, setClientGroup] = useState(null);
  const [selectedAlertEntries, setSelectedAlertEntries] = useState([]);
  const [itemsToUnlink, setItemsToUnlink] = useState([]);
  const [itemToUnlink, setItemToUnlink] = useState();

  const handleAddEntryOpen = () => {
    setOpenAddEntry(true);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setAddEntryPage(0);
    setAddEntryTotal(0);
    setEntries([]);
  };

  const handleAddEntryClose = () => {
    setOpenAddEntry(false);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setEntries([]);
  };
  const handleOpenUnlink = () => {
    setUnlinkDialog(true);
  };

  const handleCloseUnlink = () => {
    setUnlinkDialog(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const sortEntries = field => {
    if (addEntryOrderBy === field) {
      setAddEntryOrder(addEntryOrder === "asc" ? "desc" : "asc");
    } else {
      setAddEntryOrderBy(field);
      setAddEntryOrder("desc");
    }
  };

  const getVariables = React.useCallback(() => {
    const variables = {
      keyword: alertKeyword,
      skip: page * rowsPerPage,
      first: rowsPerPage,
      group_ids: groupIds_int,
      orderBy: { field: orderBy, direction: order }
    };

    return variables;
  }, [orderBy, order, rowsPerPage, page, alertKeyword]);

  const { data: currentData, refetch, fetchMore, subscribeToMore } = useQuery(
    GET_ADMIN_ALERT_REFERENCES,
    {
      variables: getVariables(),
      // onCompleted: () => {
      //   setPage(0);
      // },
      fetchPolicy: "network-only"
    }
  );

  const getGroupIds = () => {
    const data = [];
    data.push({ field: "group_id", value: groupIds_str });
    return data;
  };
  const { data: entriesData, fetchMore: fetchMoreEntries } = useQuery(
    GET_ADMIN_ALERT_REFERENCES,
    {
      skip: !currentData,
      variables: {
        keyword: addEntryKeyword,
        // filter: [
        //   {
        //     field: "alert_references.alertmessage",
        //     value: addEntryKeyword
        //   },
        //   {
        //     field: "alert_references.description",
        //     value: addEntryKeyword
        //   },
        //   {
        //     field: "alert_references.code",
        //     value: addEntryKeyword
        //   }
        // ],
        skip: 0,
        not: getGroupIds(),
        first: rowsPerPage,
        group_ids: addEntryGroup,
        orderBy: [{ field: addEntryOrderBy, direction: addEntryOrder }]
      },
      // onCompleted: () => {
      //   setAddEntryPage(0);
      // },
      onError: () => {
        setLoading(false);
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (currentData) {
      setAlertsData(currentData.get_alert_references.alert_references);
      setTotalRows(currentData.get_alert_references.total);
      setCountRows(currentData.get_alert_references.count);
      setLoading(false);
    }
    if (entriesData) {
      setEntries(entriesData.get_alert_references);
      setAddEntryTotal(entriesData.get_alert_references.total);
    }
  }, [currentData, entriesData]);
  const subscribeToNewAlerts = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: VIEW_CLIENT_ALERT_SUBSCRIPTION,
      variables: {
        filter: getGroupIds
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data)
          return prev.get_alert_references.alert_references;
        const newData = subscriptionData.data.data_updated.alert;
        const mergedData = alertsData;
        let subsData = {
          count:
            prev && prev.get_alert_references && prev.get_alert_references.count
              ? prev.get_alert_references.count
              : 0,
          total:
            prev && prev.get_alert_references && prev.get_alert_references.total
              ? prev.get_alert_references.total
              : 0,
          alert_references:
            prev &&
            prev.get_alert_references &&
            prev.get_alert_references.alert_references
              ? prev.get_alert_references.alert_references
              : []
        };
        if (newData && newData.client_id) {
          if (newData.client_id.toString() === clientId.toString()) {
            mergedData.unshift(newData);
            setAlertsData(mergedData);
            subsData = {
              count: prev.get_alert_references.count + 1,
              total: prev.get_alert_references.total + 1,
              alert_references: mergedData
            };
          }
        }
        return {
          ...prev,
          get_alert_references: subsData
        };
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToNewAlerts();
    return () => unsubscribe();
  }, [
    // alertsData,
    // refetch(getVariables()),
    subscribeToNewAlerts
  ]);

  const [adminUnlink] = useMutation(ADMIN_UNLINK, {
    onCompleted: () => {
      setSelected([]);
      setItemsToUnlink([]);
      refetch(getVariables());
      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Unlinked successfully",
        showConfirmButton: false,
        timer: 1000
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  });
  const [adminLink] = useMutation(ADMIN_LINK, {
    onCompleted: () => {
      refetch(getVariables());
      handleAddEntryClose();
      AddLogs(
        "Admin - Clients",
        "link_alert",
        `${selectedAlertEntries
          .toString()
          .replace(/,/g, ", ")} to ${clientName}`
      );
      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Linked successfully",
        showConfirmButton: false,
        timer: 1000
      });
    },
    onError: () => {
      handleAddEntryClose();
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
  });
  const unlinkAlert = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);
    adminUnlink({
      variables: {
        target: toUnlink,
        group: { id: groupIds_int[0], name: group_names[0] },
        model: "alert_references"
      }
    });
    AddLogs(
      "Admin - Clients",
      "unlink_alert",
      `${itemToUnlink.toString().replace(/,/g, ", ")} to ${clientName}`
    );
  };

  const unlinkAlerts = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);
    adminUnlink({
      variables: {
        target: selected,
        group: { id: groupIds_int[0], name: group_names[0] },
        model: "alert_references"
      }
    });
    AddLogs(
      "Admin - Clients",
      "unlink_alert",
      `${itemsToUnlink
        .map(alert => alert.alert_description.description)
        .toString()
        .replace(/,/g, ", ")} to ${clientName}`
    );
  };

  const handleToUnlink = id => {
    if (!toUnlink.some(row_id => row_id === id)) setToUnlink([...toUnlink, id]);
  };

  const handlePagination = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    refetch(getVariables());
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const clickEntry = (event, name) => {
    const selectedIndex = selectedEntries.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEntries, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEntries.slice(1));
    } else if (selectedIndex === selectedEntries.length - 1) {
      newSelected = newSelected.concat(selectedEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEntries.slice(0, selectedIndex),
        selectedEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedEntries(newSelected);
  };

  const clickAlertEntry = (event, name) => {
    const selectedIndex = selectedAlertEntries.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAlertEntries, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAlertEntries.slice(1));
    } else if (selectedIndex === selectedAlertEntries.length - 1) {
      newSelected = newSelected.concat(selectedAlertEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAlertEntries.slice(0, selectedIndex),
        selectedAlertEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedAlertEntries(newSelected);
  };

  const onItemsToUnlinkChanged = (event = null, alert) => {
    if (event) {
      const { checked } = event.target;
      if (lodash.some(itemsToUnlink, alert)) {
        if (!checked) {
          const temp = itemsToUnlink.filter(
            alerts => !lodash.isEqual(alerts, alert)
          );
          setItemsToUnlink(temp);
        }
      } else if (checked) {
        setItemsToUnlink(prev => [...prev, alert]);
      }
    } else if (!lodash.some(itemsToUnlink, alert)) {
      setItemsToUnlink(prev => [...prev, alert]);
    }
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  const isEntrySelected = id => selectedEntries.indexOf(id) !== -1;

  const handleUpPage = () => {
    const nextPage = page + 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page + 1);
      //     setAlertsData(fetchMoreResult.get_alert_references.alert_references);
      //     return {
      //       ...prev,
      //       get_alert_references: {
      //         count: fetchMoreResult.get_alert_references.count,
      //         total: fetchMoreResult.get_alert_references.total,
      //         alert_references:
      //           fetchMoreResult.get_alert_references.alert_references
      //       }
      //     };
      //   }
      // });
    }
  };
  // const handleUpPage = () => {
  //   setPage(page + 1);
  //   const nextPage = page + 1;
  //   if (nextPage * rowsPerPage <= totalRows) {
  //     fetchMore({
  //       variables: {
  //         skip: nextPage * rowsPerPage
  //       },
  //       updateQuery: (prev, { fetchMoreResult }) => {
  //         if (!fetchMoreResult) {
  //           return prev;
  //         }
  //         setAlertsData(fetchMoreResult.get_alert_references.alert_references);
  //         // setPage(nextPage);
  //         return {
  //           ...prev,
  //           get_alert_references: {
  //             count: fetchMoreResult.get_alert_references.count,
  //             total: fetchMoreResult.get_alert_references.total,
  //             alert_references: prev.get_alert_references.alert_references.concat(
  //               fetchMoreResult.get_alert_references.alert_references
  //             )
  //           }
  //         };
  //       }
  //     }).catch(() => {
  //       Swal.fire({
  //         title: "Something went wrong",
  //         icon: "error",
  //         showConfirmButton: false,
  //         timer: 3000,
  //         onClose: () => {
  //           setPage(0);
  //           refetch(getVariables());
  //         }
  //       });
  //     });
  //   }
  // };

  const handleDownPage = () => {
    const nextPage = page - 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page - 1);
      //     setAlertsData(fetchMoreResult.get_alert_references.alert_references);
      //     return {
      //       ...prev,
      //       get_alert_references: {
      //         count: fetchMoreResult.get_alert_references.count,
      //         total: fetchMoreResult.get_alert_references.total,
      //         alert_references:
      //           fetchMoreResult.get_alert_references.alert_references
      //       }
      //     };
      //   }
      // });
    }
  };

  // const handleDownPage = () => {
  //   setPage(page - 1);
  //   const nextPage = page - 1;
  //   if (nextPage * rowsPerPage <= totalRows) {
  //     fetchMore({
  //       variables: {
  //         skip: nextPage * rowsPerPage
  //       },
  //       updateQuery: (prev, { fetchMoreResult }) => {
  //         if (!fetchMoreResult) {
  //           return prev;
  //         }
  //         setAlertsData(fetchMoreResult.get_alert_references.alert_references);
  //         // setPage(nextPage);
  //         return {
  //           ...prev,
  //           get_alert_references: {
  //             count: fetchMoreResult.get_alert_references.count,
  //             total: fetchMoreResult.get_alert_references.total,
  //             alert_references: prev.get_alert_references.alert_references.concat(
  //               fetchMoreResult.get_alert_references.alert_references
  //             )
  //           }
  //         };
  //       }
  //     });
  //   }
  // };

  const entriesNextPage = () => {
    const nextPage = addEntryPage + 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage + 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_alert_references);
          return {
            ...prev,
            get_alert_references: {
              count: fetchMoreResult.get_alert_references.count,
              total: fetchMoreResult.get_alert_references.total,
              alert_references:
                fetchMoreResult.get_alert_references.alert_references
            }
          };
        }
      });
    }
  };

  const entriesPreviousPage = () => {
    const nextPage = addEntryPage - 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage - 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_alert_references);
          return {
            ...prev,
            get_alert_references: {
              count: fetchMoreResult.get_alert_references.count,
              total: fetchMoreResult.get_alert_references.total,
              alert_references:
                fetchMoreResult.get_alert_references.alert_references
            }
          };
        }
      });
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }
  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    return alertsData
      ? stableSort(alertsData, getSorting(order, orderBy)).slice(0, rowsPerPage)
      : [];
  };

  const getEntryData = () => {
    return entries && entries.alert_references
      ? stableSort(
          entries.alert_references,
          getSorting(addEntryOrder, addEntryOrderBy)
        ).slice(0, 10)
      : [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < getData().length) {
      const displayedData = getData().map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempItemsToUnlink = itemsToUnlink;
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);

      const currentPageData = getData();
      const newItemsToUnlink = tempItemsToUnlink.filter(
        device => !lodash.some(currentPageData, device)
      );

      setItemsToUnlink(newItemsToUnlink);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => {
        tempSelected.push(n.id);
        tempItemsToUnlink.push(n);
        return n;
      });
      setSelected(lodash.uniq(tempSelected));
      setItemsToUnlink(lodash.uniq(tempItemsToUnlink));
      return;
    }
    deselect();
  };

  const isEntriesChecked = () => {
    let checked = { all: false, indeterminate: false };
    const entriesLength =
      entries && entries.alert_references ? getEntryData().length : 0;
    if (!selected.length < entriesLength) {
      const displayedData = getEntryData().map(data => data.id);
      if (displayedData.every(data => selectedEntries.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selectedEntries.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };
  const selectAllEntries = event => {
    const tempSelectedAlert = selectedAlertEntries;
    const tempSelected = selectedEntries;
    const deselect = () => {
      const displayedData = getEntryData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelectedEntries(newSelected);

      const displayedAlertData = getEntryData().map(
        n => n.alert_description.description
      );
      const newSelectedDevice = tempSelectedAlert
        .filter(name => displayedAlertData.indexOf(name) < 0)
        .map(name => name);
      setSelectedAlertEntries(newSelectedDevice);
    };

    if (event.target.checked) {
      if (isEntriesChecked().indeterminate) {
        deselect();
        return;
      }
      getEntryData().map(n => tempSelected.push(n.id));
      setSelectedEntries(lodash.uniq(tempSelected));
      getEntryData().map(n =>
        tempSelectedAlert.push(n.alert_description.description)
      );
      setSelectedAlertEntries(lodash.uniq(tempSelectedAlert));
      return;
    }
    deselect();
  };

  const getTextual = id => {
    let text = "";
    switch (id) {
      case 0:
        text = (
          <span style={{ color: "#9e9e9e" }}>
            <b>Discrete</b>
          </span>
        );
        break;
      case 1:
        text = (
          <span style={{ color: "#63F160" }}>
            <b>Normal</b>
          </span>
        );
        break;
      case 2:
        text = (
          <span style={{ color: "#ffd600" }}>
            <b>Moderate</b>
          </span>
        );
        break;
      case 3:
        text = (
          <span style={{ color: "#ff5722" }}>
            <b>Critical</b>
          </span>
        );
        break;
      default:
        break;
    }
    return text;
  };

  const addEntryBody = (
    <Paper
      style={{
        marginTop: "10%",
        marginBottom: "10%",
        marginLeft: "20%",
        marginRight: "20%",
        padding: 10
      }}
    >
      <Grid container>
          <Grid
            item
            xs={1}
            align="center"
            style={{ marginRight: 20, marginLeft: 10 }}
          >
            <Typography>
              <Box
                fontWeight="fontWeightBold"
                style={{ width: 100, padding: 10 }}
              >
                Add Entry
              </Box>
            </Typography>
          </Grid>
          <Grid item md={10} style={{ display: "flex", alignItems: "center" }}>
            {/* <Autocomplete
              options={otherGroups || []}
              getOptionLabel={option => option.name}
              renderOption={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  style={{
                    width: "55%",
                    background: "#E9E9E9",
                    borderRadius: 6
                  }}
                  placeholder="Client name"
                />
              )}
              name="client_group"
              onChange={(e, nv) => {
                setClientGroup(nv);
                setAddEntryGroup(nv.id);
                setSelectedEntries([]);
              }}
              value={clientGroup}
              disableClearable
            /> */}
            <ClientAutocomplete
              setter={setClientGroup}
              onChange={(e, nv) => {
                setClientGroup(nv);
                setAddEntryGroup(nv.group_ids);
                setSelectedEntries([]);
              }}
            />
          </Grid>
            <Grid item xs={12}>
              <div className={classes.search}
              >
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  onChange={e => {
                    if (e.target.value.length >= 2) {
                      setAddEntryKeyword(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setAddEntryKeyword(e.target.value);
                    }
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <IconButton
                name="down_page"
                disabled={addEntryPage === 0}
                onClick={() => entriesPreviousPage()}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              {addEntryTotal ? addEntryPage + 1 : 0}
              {` / ${Math.ceil(addEntryTotal / rowsPerPage)}`}
              <Button
                name="up_page"
                disabled={
                  addEntryPage + 1 === Math.ceil(addEntryTotal / rowsPerPage)
                }
                onClick={() => entriesNextPage()}
              >
                <KeyboardArrowRightIcon />
              </Button>
              </div>
            </Grid>
          <Grid item xs={12}>
            <TableContainer
              style={{
                height: 250,
                border: "1px solid rgba(209, 209, 209, 0.58)"
              }}
            >
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHeadAddEntry
                  classes={classes}
                  numSelected={selectedEntries.length}
                  addEntryOrder={addEntryOrder}
                  addEntryOrderBy={addEntryOrderBy}
                  selectAllEntries={selectAllEntries}
                  editMode={editMode}
                  rowCount={
                    entries && entries.alert_references
                      ? entries.alert_references.length
                      : 0
                  }
                  sortEntries={sortEntries}
                  isEntriesChecked={isEntriesChecked}
                />
                <TableBody>
                  {entries &&
                  entries.alert_references &&
                  entries.alert_references.length > 0 ? (
                    getEntryData().map((row, index) => {
                      const isItemSelected = isEntrySelected(row.id);
                      const labelId = `entry-checkbox-${index}`;
                      return (
                        <TableRow
                          key={`entries-${row.id}`}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              disabled={!editMode}
                              onClick={event => {
                                clickEntry(event, row.id);
                                clickAlertEntry(
                                  event,
                                  row.alert_description.description
                                );
                              }}
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {/* {row.alert_type && row.alert_type.length
                            ? row.alert_type.toString()
                            : "-"} */}
                            {/* {row?.alert_description?.description || "" } */}
                            {row?.alert_description.description || ""}
                          </TableCell>
                          <TableCell>
                            {/* { row.code && row.code } */}
                            {row?.alert_description.code || ""}
                          </TableCell>
                          <TableCell>{row.code || "-"}</TableCell>
                          <TableCell>{row.operator || "-"}</TableCell>
                          <TableCell>{row.event_value || "-"}</TableCell>
                          <TableCell>
                            {/* {row.level ? getTextual(row.level) : "-"} */}

                            <Tooltip title={row?.alertmessage || ""}>
                              <div
                                style={{
                                  width: "35ch",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                <Box
                                  component="div"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                >
                                  <span>{row?.alertmessage}</span>
                                </Box>
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={20}>
                        No data to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              variant="contained"
              onClick={() => {
                handleAddEntryClose();
              }}
              style={{
                border: "1px solid  #FF845E",
                color: "#FF845E",
                background: "#FFFFFF",
                borderRadius: 17,
                margin: 10,
                width: 100
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.btn}
              style={{
                // background: "#3CA4F7",
                // color: "#FFFFFF",
                borderRadius: 17,
                margin: 10,
                width: 100
              }}
              onClick={() => {
                if (selectedEntries.length) {
                  adminLink({
                    variables: {
                      target: selectedEntries,
                      group: { id: groupIds_int[0], name: group_names[0] },
                      model: "alert_references"
                    }
                  });
                } else {
                  handleAddEntryClose();
                  Swal.fire({
                    icon: "error",
                    // title: "Success",
                    text: "Please select alerts to Link!"
                  });
                }
              }}
            >
              Add
            </Button>
          </Grid>
      </Grid>
    </Paper>
  );

  const AlertsTable = () => {
    return alertsData && alertsData.length ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              {row.client_id !== clientId && editMode ? (
                <TableCell padding="checkbox">
                  {editMode && (
                    <Checkbox
                      color="primary"
                      // disabled={!editMode}
                      onClick={event => {
                        handleClick(event, row.id);
                        onItemsToUnlinkChanged(event, row);
                      }}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  )}
                </TableCell>
              ) : (
                <TableCell padding="checkbox" />
              )}
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {/* {row.alert_description && row.alert_description.description
                  ? row.alert_description.description
                  : "-"} */}
                {row?.alert_description.description || "-"}
              </TableCell>
              <TableCell>{row?.alert_description.code || "-"}</TableCell>
              <TableCell>{row.code || "-"}</TableCell>
              <TableCell>{row.operator || "-"}</TableCell>
              <TableCell>{row.event_value || "-"}</TableCell>
              <TableCell>{row?.alertmessage || "-"}</TableCell>
              <TableCell>{row.level ? getTextual(row.level) : "-"}</TableCell>
              {/* <TableCell>{row.device_type ? row.device_type : "-"}</TableCell> */}
              <TableCell>{row?.device_type?.name || "-"}</TableCell>

              {row.client_id !== clientId ? (
                <TableCell component="th" scope="row">
                  <IconButton
                    disabled={!editMode}
                    aria-label="unlink"
                    onClick={() => {
                      handleToUnlink(parseInt(row.id, 0));
                      handleOpen();
                      setItemToUnlink(row.alert_description.description);
                      // onItemsToUnlinkChanged(null, row);
                    }}
                  >
                    <LinkOffIcon />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell component="th" scope="row">
                  -
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={20} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: " inherit"
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          padding: 20,
          background: "#FFFFFF",
          minHeight: "740px"
        }}
      >
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            editMode={editMode}
            handleOpenUnlink={handleOpenUnlink}
            totalRows={totalRows}
            numSelected={selected.length}
            numberOfRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePagination={handlePagination}
            handleUpPage={handleUpPage}
            handleDownPage={handleDownPage}
            setAlertKeyword={setAlertKeyword}
            setPage={setPage}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                editMode={editMode}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                // rowCount={alertsData.length}
                handleSort={handleSort}
                isChecked={isChecked}
              />
              <AlertsTable
                selected={selected}
                alertsData={alertsData}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>
          {editMode && (
            <div style={{ textAlign: "right" }}>
              <Button
                className={classes.addEntryBtn}
                style={{ fontWeight: "bold" }}
                align="right"
                onClick={() => {
                  handleAddEntryOpen();
                }}
              >
                Add Entry
              </Button>
            </div>
          )}
        </Paper>
        <Modal
          open={openAddEntry}
          onClose={() => {
            handleAddEntryClose();
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {addEntryBody}
        </Modal>
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={() => unlinkAlert()}
          title="Unlink Alert"
          content="Are you sure you want to unlink this alert?"
        />
        <ConfirmationDialog
          toggle={unlinkDialog}
          close={() => handleCloseUnlink()}
          fn={() => unlinkAlerts()}
          title="Unlink Alerts"
          content="Are you sure you want to unlink these alerts?"
        />
      </Container>
    </div>
  );
}
