import React from "react";
import {
  Drawer,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import { FilterList, Close, ClearAllRounded } from "@material-ui/icons";

// keep this as a dumb component for reusable purpose
const useStyles = makeStyles(theme => ({
  list: {},
  fullList: {
    width: "auto"
  },
  toolbar: {
    background: theme.palette.primary.main
  },
  drawer: {
    width: 325,
    overflow: "hidden"
  },
  textStyle: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: "500"
  },
  clearBtn: {
    color: "#B5B5B5",
    backgroundColor: "#F6F6F6",
    boxShadow: "none",
    fontSize: 13,
    opacity: 1,
    transition: "0.3s",
    "&:hover": {
      opacity: "0.7"
    }
  },
  applyBtn: {
    borderRadius: 32, 
    textTransform: "none",
    opacity: 1,
    transition: "0.3s",
    "&:hover": {
      opacity: "0.7",
      backgroundColor: theme.palette.primary.main,
    },
  }
}));

const Filter = props => {
  const {
    toggleFilter,
    setToggleFilter,
    primaryName,
    clearAllAction,
    applyEvent,
    isDisabled,
    children
  } = props;
  const classes = useStyles();

  return (
    <Drawer
      open={toggleFilter}
      className={classes.list}
      onBackdropClick={() => setToggleFilter(false)}
      classes={{
        paper: classes.drawer
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <Toolbar className={classes.toolbar}>
          <FilterList style={{ color: "white", marginRight: 16 }} />
          <Typography variant="h6" style={{ color: "white", flexGrow: 1 }}>
            Filter
          </Typography>
          <IconButton
            style={{ color: "white" }}
            onClick={() => setToggleFilter(false)}
          >
            <Close />
          </IconButton>
        </Toolbar>
        <div className={classes.list} style={{ padding: "1rem" }}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <span className={classes.textStyle}>{primaryName}</span>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Clear Notification Settings" placement="bottom" arrow>
                <IconButton
                  onClick={clearAllAction}
                  size="small"
                  className={classes.clearBtn}
                  disabled={isDisabled}
                >
                  <ClearAllRounded />
                </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            {children}
          </List>
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => applyEvent()}
          disabled={isDisabled}
          className={classes.applyBtn}
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default Filter;
