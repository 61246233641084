import React, { useContext } from "react";
import { isNull } from "lodash";
import { Tooltip, Typography } from "@material-ui/core";
import { BatteryChargingFull } from "@material-ui/icons";
import { batteryStatus } from "../components/Utils";
import SearchContext from "../context/SearchContext";
const Highlight = require("react-highlighter");

export default function Battery({
  vehicleType,
  deviceTypeId,
  batteryLevel,
  tooltipTitle,
  showText,
  showNAText,
  historyFromDevices
}) {
  const contextData = useContext(SearchContext);
  if (
    vehicleType?.toLowerCase().trim() === "container van" &&
    deviceTypeId === 27 || historyFromDevices
  ) {
    // Show battery level if vehicle type is Container Van and device type is AMX
    return (
      <>
        <Tooltip title={tooltipTitle}>
          <Typography style={{ fontSize: "0.875rem" }} component="span" noWrap>
            <BatteryChargingFull
              style={{
                fontSize: "13px",
                color: !isNull(batteryLevel) ? batteryStatus(batteryLevel) : ""
              }}
            />
          </Typography>
        </Tooltip>
        {showText &&
          (!isNull(batteryLevel) ? (
            <span>
              <Highlight search={contextData?.searchKeyword?.value}>
                {batteryLevel}%
              </Highlight>
            </span>
          ) : (
            `-`
          ))}
      </>
    );
  }
  return showNAText ? (
    <span>
      <Highlight search={contextData?.searchKeyword?.value}>N/A</Highlight>
    </span>
  ) : null;
}
