import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TextField,
  Button
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";

const useFuelCalibration = makeStyles({
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  labelSave: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "#f2f2f2"
  },
  table: {
    height: "35vh"
  },
  head: {
    backgroundColor: "#f2f2f2"
  },
  saveBtn: {
    fontSize: "16px",
    letterSpacing: 0.7,
    fontWeight: 600,
    margin: "0px 20px 0 0",
    textTransform: "capitalize"
  }
});

const headCells = [
  {
    id: "liters",
    numeric: false,
    disablePadding: true,
    label: "Liters",
    align: "left"
  },
  {
    id: "voltage",
    numeric: false,
    disablePadding: false,
    label: "Voltage",
    align: "left"
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center"
  }
];

const FuelCalibration = props => {
  const { edit, fuelCalib, setFuelCalib, handleSave } = props;
  // const [fuelCalib, setFuelCalib] = useState([]);
  const [selected, setSelected] = useState([]);
  const styles = useFuelCalibration();

  useEffect(() => {
    setSelected([]);
  }, [edit]);

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < fuelCalib?.length) {
      const displayedData = fuelCalib.map(data => data.calibration_id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }
    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = fuelCalib.map(n => n.calibration_id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      fuelCalib.map(n => tempSelected.push(n.calibration_id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleClick = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChange = (id, event) => {
    const { name, value } = event.target;
    const temp = fuelCalib;
    let rgx;
    let val;
    temp.map(d => {
      if (d.calibration_id === id) {
        switch (name) {
          case "voltage":
            rgx = /^[0-9]{0,9}?$/;
            val = rgx.test(value);
            if (!val) return;
            return (d[name] = value ? parseInt(value, 10) : null);
          case "liters":
            rgx = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
            // const rgx = /^[0-9]{0,3}\.?([0-9]{0,2})?$/;
            // const rgx = /^[0-9]*(\.[0-9]{0,2})?$/;
            val = rgx.test(value);
            if (!val) return;
            return (d[name] = value || null);
        }
      }
    });
    setFuelCalib([...temp]);
  };

  const handleDelete = id => {
    const temp = fuelCalib;
    const tempSelected = selected;
    const index = fuelCalib.findIndex(i => i.calibration_id === id);
    const indexSelected = selected.findIndex(i => i === id);
    if (index > -1) {
      temp.splice(index, 1);
    }
    if (indexSelected > -1) {
      tempSelected.splice(indexSelected, 1);
    }
    setSelected([...tempSelected]);
    setFuelCalib([...temp]);
  };

  const handleDeleteAll = () => {
    const temp = fuelCalib;
    if (selected.length) {
      _.forEach(selected, id => {
        const index = fuelCalib.findIndex(i => i.calibration_id === id);
        if (index > -1) {
          temp.splice(index, 1);
        }
      });
    }
    setSelected([]);
    setFuelCalib([...temp]);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper variant="outlined" square elevation={0}>
        <div className={styles.label}>
          <Typography style={{ padding: "16px" }}>
            Fuel Sensor Calibration
          </Typography>
          <IconButton
            className={styles.btn}
            color="primary"
            disabled={!edit}
            onClick={() => {
              const epoch_time = moment().valueOf();
              setFuelCalib([
                ...fuelCalib,
                {
                  calibration_id: epoch_time,
                  liters: null,
                  voltage: null
                }
              ]);
            }}
          >
            <Add />
          </IconButton>
        </div>
        {!!selected.length && (
          <div className={styles.label} style={{ backgroundColor: "#e9e9e9" }}>
            <Typography
              style={{ padding: "0px 16px" }}
            >{`${selected.length} item is selected`}</Typography>
            <Button
              // size="small"
              // color="primary"
              disabled={!edit}
              style={{ color: "red" }}
              classes={{ root: styles.saveBtn }}
              onClick={() => handleDeleteAll()}
            >
              Delete
            </Button>
          </div>
        )}
        <TableContainer className={styles.table}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead className={styles.head}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={!edit}
                    color="primary"
                    indeterminate={isChecked().indeterminate}
                    checked={isChecked().all}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
                {headCells.map(headCell => (
                  <TableCell align={headCell.align} key={headCell.id}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fuelCalib?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell
                    padding="checkbox"
                    disabled={!edit}
                    onClick={event => {
                      if (edit) handleClick(data.calibration_id);
                    }}
                  >
                    <Checkbox
                      disabled={!edit}
                      color="primary"
                      checked={isSelected(data.calibration_id)}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      disabled={!edit}
                      name="liters"
                      size="small"
                      placeholder="Input"
                      variant="outlined"
                      onChange={e => handleChange(data.calibration_id, e)}
                      value={
                        data.liters || data.liters === 0 ? data.liters : ""
                      }
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      disabled={!edit}
                      name="voltage"
                      size="small"
                      placeholder="Input"
                      variant="outlined"
                      onChange={e => handleChange(data.calibration_id, e)}
                      value={
                        data.voltage || data.voltage === 0 ? data.voltage : ""
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      disabled={!edit}
                      className={styles.btn}
                      color="primary"
                      onClick={() => handleDelete(data.calibration_id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.labelSave}>
          <Button
            // size="small"
            disabled={!edit}
            color="primary"
            classes={{ root: styles.saveBtn }}
            onClick={() => {
              handleSave();
              setSelected([])
            }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default FuelCalibration;
