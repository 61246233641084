import React, { useState, useContext } from "react";
import FormContext from "../../../../context/formContext";

import { Avatar, styled, Box, IconButton, Tooltip } from "@material-ui/core";
import { AddPhotoAlternate as AddPhotoAlternateIcon } from "@material-ui/icons";

const UserImage = props => {
  const { profileImg, setHasUploaded } = props;
  const formCtx = useContext(FormContext);
  const [uploadFile, setUploadFile] = useState({});
  const [imageUpload, setImageUpload] = useState(null);
  const profileImage = imageUpload
    ? URL.createObjectURL(imageUpload)
    : process.env.REACT_APP_CLOUD_STORAGE_BASE_URL + profileImg;

  const handleUploadedImage = event => {
    const {
      target: {
        files: [file]
      }
    } = event;
    setHasUploaded({ image: file, status: true });
    setImageUpload(file);
    formCtx.setUpdatedUserInfo(prev => ({ ...prev, profile_image: file?.name }));

  };

  return (
    <>
      <StyleImgBorder>
        <StyledAvatarContainer src={profileImage} />
        <Tooltip title="Upload Image" placement="right" arrow>
          <StyledUploadImgBtn size="small" onClick={() => uploadFile.click()}>
            <AddPhotoAlternateIcon fontSize="small" />
          </StyledUploadImgBtn>
        </Tooltip>
        <StyledInput
          type="file"
          ref={fileUpload => setUploadFile(fileUpload)}
          onChange={e => handleUploadedImage(e)}
          accept=".jpg,.jpeg,.png"
        />
      </StyleImgBorder>
    </>
  );
};

export default UserImage;

const StyledInput = styled("input")(() => ({
  visibility: "hidden",
  display: "block"
}));

const StyledAvatarContainer = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(17),
  height: theme.spacing(17)
}));

const StyleImgBorder = styled(Box)(({ theme }) => ({
  width: theme.spacing(17),
  height: theme.spacing(17),
  margin: "2rem auto 0 auto",
  border: `3px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
  padding: "0.25rem",
  position: "relative"
}));

const StyledUploadImgBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 10,
  bottom: 5,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.main
  }
}));
