import React from "react";
import AdminDemo from "../../utils/Onboarding/modules/Admin";
import Admin from "./Index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../utils/Onboarding";
import useUserContext from "../../context/User/useUserContext";
import Introductory from "../../utils/Onboarding/pages/Introductory";

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.admin,
    allowContextToChange: true
  });
  const adminProgress = progress[Onboarding.modules.admin];
  const isDemoCompleted =
    adminProgress === onboarding_max[Onboarding.modules.admin];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Admin /> : <AdminDemo />;
};

export default Main;
