import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  DialogTitle,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

import { useMutation } from "@apollo/client";
import ErrorIcon from "@material-ui/icons/Error";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoIcon from "@material-ui/icons/Info";

import Swal from "sweetalert2";
import { CHANGE_PASSWORD_MUTATION } from "../../../../graphql/Mutations";
import AddLogs from "../../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  },
  warningBox: {
    width: "100%",
    marginBottom: 10,
    color: "#EA7879",
    background: "#FAE5E5",
    marginTop: 0,
    display: "inline-flex"
  },
  spanBox: {
    padding: 10,
    display: "inline-flex"
  },
  svgBackground: {
    backgroundColor: theme.palette.primary.main
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#C0C0C0",
    color: "#FFFFFF",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12)
  }
}))(Tooltip);

const ChangePasswordDialog = props => {
  const classes = useStyles();
  const { toggle } = props;
  const [showOldPass, setShowOldPass] = useState("");
  const [showNewPass, setShowNewPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState("");
  const [old_password, setOld_password] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passwordError] = useState("");
  const [errorOldPass, setErrorOldPass] = useState("");
  const [errorNewPass, setErrorNewPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");

  function handleClose() {
    // setOpen(false);
    props.closeChangePasswordModal();
  }

  const [changepassword] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted: data => {
      const { change_password } = data;
      setOld_password("");
      setPassword("");
      setConfirmPass("");
      if (change_password.success) {
        handleClose();
        AddLogs(
          'Profile',
          'change_password',
          ''
        )
        Swal.fire({
          icon: "success",
          text: "Password updated successfully",
          showConfirmButton: false,
          timer: 2500
        });
      } else {
        const { error } = change_password;
        let errorMessage = "";
        error.forEach(element => {
          errorMessage += element;
        });
        setErrorOldPass(errorMessage);
        setErrorNewPass(errorMessage);
        setErrorConfirmPass(errorMessage);
      }
    },
    onError: () => {
      setErrorOldPass("Something went wrong!");
      setErrorNewPass("Something went wrong!");
      setErrorConfirmPass("Something went wrong!");
    }
  });

  const handleChangePassword = () => {
    let oldPasswordError = "";
    let newPasswordError = "";
    let confirmPasswordError = "";

    if (
      old_password.trim() === "" ||
      password.trim() === "" ||
      confirmPass.trim() === "" ||
      old_password.trim().length <= 7 ||
      password.trim().length <= 7 ||
      confirmPass.trim().length <= 7
    ) {
      if (old_password.trim().length <= 7)
        oldPasswordError = "Password must at least be 8 characters";
      if (password.trim().length <= 7)
        newPasswordError = "Password must at least be 8 characters";
      if (confirmPass.trim().length <= 7)
        confirmPasswordError = "Password must at least be 8 characters";
      if (old_password.trim() === "")
        oldPasswordError = "This field is required";
      if (password.trim() === "") newPasswordError = "This field is required";
      if (confirmPass.trim() === "")
        confirmPasswordError = "This field is required";

      setErrorOldPass(oldPasswordError);
      setErrorNewPass(newPasswordError);
      setErrorConfirmPass(confirmPasswordError);
    } else if (password !== confirmPass) {
      setErrorNewPass("Passwords do not match");
      setErrorConfirmPass("Passwords do not match");
    } else {
      changepassword({
        variables: {
          old_password: old_password,
          password: password
        }
      });
    }
  };
  return (
    <Dialog
      open={toggle}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Change password?</DialogTitle>

      <DialogContent style={{ overflow: "hidden" }}>
        <TextField
          fullWidth
          onChange={e => setOld_password(e.target.value)}
          placeholder="Enter your old password"
          margin="dense"
          id="name"
          label="Old password *"
          type={showOldPass ? "text" : "password"}
          value={old_password}
          InputProps={{
            startAdornment: (
              <LockIcon style={{ padding: 10 }} color="primary" />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0, marginRight: "-14px" }}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowOldPass(!showOldPass);
                  }}
                  onMouseDown={e => {
                    e.preventDefault();
                  }}
                >
                  {showOldPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Password must be at least 8 characters
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <InfoIcon
                    fontSize="small"
                    style={{ color: "#808080", fontSize: 14, marginBottom: 60 }}
                  />
                </HtmlTooltip>
              </InputAdornment>
            )
          }}
        />
        {errorOldPass && (
          <p className={classes.warningBox}>
            <span className={classes.spanBox}>
              <ErrorIcon style={{ marginRight: 10 }} />
              {errorOldPass}
            </span>
          </p>
        )}
        <TextField
          required
          fullWidth
          onChange={e => setPassword(e.target.value)}
          placeholder="Enter your new password"
          margin="dense"
          id="password"
          label="New password"
          type={showNewPass ? "text" : "password"}
          value={password}
          InputProps={{
            startAdornment: (
              <LockIcon style={{ padding: 10 }}className={classes.svgBackground} />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0, marginRight: "-14px" }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPass(!showNewPass)}
                  onMouseDown={e => {
                    e.preventDefault();
                  }}
                >
                  {showNewPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Password must be at least 8 characters
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <InfoIcon
                    fontSize="small"
                    style={{ color: "#808080", fontSize: 14, marginBottom: 60 }}
                  />
                </HtmlTooltip>
              </InputAdornment>
            )
          }}
        />
        {errorNewPass && (
          <p className={classes.warningBox}>
            <span className={classes.spanBox}>
              <ErrorIcon style={{ marginRight: 10 }} />
              {errorNewPass}
            </span>
          </p>
        )}
        <TextField
          required
          fullWidth
          onChange={e => setConfirmPass(e.target.value)}
          placeholder="Re-enter password"
          margin="dense"
          id="confirmPassword"
          label="Confirm password"
          type={showConfirmPass ? "text" : "password"}
          value={confirmPass}
          InputProps={{
            startAdornment: (
              <LockIcon style={{ padding: 10 }} color="primary" />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0, marginRight: "-14px" }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  onMouseDown={e => {
                    e.preventDefault();
                  }}
                >
                  {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Password must be at least 8 characters
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <InfoIcon
                    fontSize="small"
                    style={{ color: "#808080", fontSize: 14, marginBottom: 60 }}
                  />
                </HtmlTooltip>
              </InputAdornment>
            )
          }}
        />
        {errorConfirmPass && (
          <p className={classes.warningBox}>
            <span className={classes.spanBox}>
              <ErrorIcon style={{ marginRight: 10 }} />
              {errorConfirmPass}
            </span>
          </p>
        )}
        <div style={{ color: "red" }}>{passwordError} </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            color: "#FF7043",
            borderColor: "#FFB74D",
            margin: 1,
            borderRadius: 20
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleChangePassword();
          }}
          variant="contained"
          color="primary"
          style={{
            margin: 10,
            borderRadius: 20
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
