import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { GET_STATUS_CODES } from "../../../graphql/Queries";
import ChipInput from "./ChipInput/ChipInput";

const logisticsStatus = [
  "Available",
  "In Transit",
  "Incoming",
  "Arrived at Pickup",
  "Arrived at Drop off",
  "Under Repair",
  "Not in use"
];

const nonLogisticsStatus = ["Active", "Under Repair", "Not in use"];

const variables = {
  filter: {
    field: "name",
    value: [...logisticsStatus, ...nonLogisticsStatus]
  }
};

export let vehicleStatusOptions = [];

const VehicleStatus = React.forwardRef((props, ref) => {
  const { initialValues = [], readOnly = false, isLogitics = {} } = props;

  const getInitialValues = statusCodes => {
    const vehicleStatusOptions = isLogitics.value
      ? logisticsStatus
      : nonLogisticsStatus;
    const values = initialValues.reduce((accum, id) => {
      const options = statusCodes.filter(option => +option.id === id);
      if (options.length) {
        return [...accum, ...options];
      }
      return accum;
    }, []);

    // eslint-disable-next-line no-param-reassign
    ref.current = values.length ? values : [];
    if (
      values.length === vehicleStatusOptions.length &&
      values.every(st => vehicleStatusOptions.includes(st.label))
    ) {
      setSelectedValues([]);
    } else {
      setSelectedValues(values);
    }
  };
  const { loading, data } = useQuery(GET_STATUS_CODES, {
    variables,
    onCompleted(res) {
      if (initialValues.length && !isLogitics.isTriggered) {
        getInitialValues(res.get_status_codes);
      }
    }
  });

  const defaultOptions = React.useMemo(() => {
    if (!loading && data) {
      vehicleStatusOptions = data.get_status_codes;
      const statuses = isLogitics.value ? logisticsStatus : nonLogisticsStatus;
      return data.get_status_codes.filter(st => statuses.includes(st.label));
    }
    return [];
  }, [data, loading, isLogitics.value]);

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (isLogitics.isTriggered) {
      setSelectedValues([]);
      ref.current = defaultOptions;
    }

    return () => {
      // eslint-disable-next-line no-param-reassign
      ref.current = [];
    };
  }, [defaultOptions]);

  useEffect(() => {
    // To reset values if edit mode is turned off
    if (!isLogitics.isTriggered && data?.get_status_codes) {
      getInitialValues(data.get_status_codes);
    }
  }, [data, isLogitics]);

  // useEffect(() => {
  //   const values = initialValues.reduce((accum, id) => {
  //     const options = defaultOptions.filter(option => +option.id === id);
  //     if (options.length) {
  //       return [...accum, ...options];
  //     }
  //     return accum;
  //   }, []);

  //   // eslint-disable-next-line no-param-reassign
  //   ref.current = values.length ? values : defaultOptions;
  //   setSelectedValues(values);

  //   return () => {
  //     // eslint-disable-next-line no-param-reassign
  //     ref.current = values;
  //   };
  // }, [defaultOptions]);

  const handleOnSelectedValues = changes => {
    const newSelectedValues = [...changes.selectedValues];
    // eslint-disable-next-line no-param-reassign
    ref.current = newSelectedValues.length
      ? newSelectedValues
      : [...defaultOptions];
    setSelectedValues(newSelectedValues);
  };

  const autocompleteProps = {
    // 💡 Override Autocomplete props
    renderInput: ({ getTextfieldProps }) => ({
      ...getTextfieldProps({
        label: "Vehicle Status",
        placeholder: selectedValues?.length > 0 ? "" : "All",
        InputProps: {
          ...(loading && { endAdornment : <CircularProgress size={12} /> }),
          disableUnderline: readOnly,
          disabled: readOnly
        }
      })
    }),
    disabled: readOnly
  };

  return (
    <ChipInput
      selectedValues={selectedValues}
      onSelectedChanged={handleOnSelectedValues}
      options={defaultOptions}
      loading={loading}
      autocompleteProps={autocompleteProps}
    />
  );
});

export default VehicleStatus;
