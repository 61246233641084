import React, { memo } from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Popover,
  Box,
  Badge,
  CircularProgress
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UseMapViewStyles from "../../../components/Monitoring/Share Location/MapView.style";
import { countVehicles } from "../../../data/vehicleCountData";
import useUserContext from "../../../context/User/useUserContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import _ from "lodash";
// For vehicles status and counts in header
const VehicleCount = memo(props => {
  const {
    vehicleCount,
    hasAccessPrebookingTrips,
    filterValues,
    setFilterValues,
    isFiltered,
    isMapView,
    module
  } = props;
  const userCtx = useUserContext();
  const userLevelId = userCtx.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const hideLogisticsAdmin =
    isWTIAdmin &&
    !filterValues?.isLogistic &&
    ["monitoring-table"].includes(module);
  const hideLogisticsClient = !hasAccessPrebookingTrips && !isWTIAdmin;
  const classes = UseMapViewStyles();
  const location = useLocation();
  const isShareLocation = location?.pathname === "/map_view";

  const vehicleStatusFilterHandler = info => {
    if (
      vehicleCount?.get_vehicles_count?.status_count?.[info.dataHeader] &&
      !isShareLocation
    ) {
      if (info.filterType === "Vehicle") {
        setFilterValues({
          ...filterValues,
          vehicle_status: info.condition,
          isCountEffect: true
        });
      } else if (info.filterType === "GPS") {
        setFilterValues({
          ...filterValues,
          gps_status: info.condition,
          isCountEffect: true
        });
      } else {
        setFilterValues({
          ...filterValues,
          additionalCondition: info.condition,
          isCountEffect: true
        });
      }
    }
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div className={classes.alignment}>
          <Typography className={classes.padding}>Count: </Typography>
          <Button
            className={classes.btn}
            variant="outlined"
            color="primary"
            classes={{
              outlined: classes.outlined,
              outlinedPrimary: classes.outlinedPrimary
            }}
            {...bindTrigger(popupState)}
          >
            {vehicleCount ? (
              <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                {vehicleCount?.get_vehicles_count?.total}
                <ExpandMore fontSize="small" />
              </Badge>
            ) : (
              <CircularProgress size="1rem" color="primary" />
            )}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            placement={isMapView ? "bottom-end" : "bottom"}
          >
            <Box>
              <List className={classes.listItem}>
                {countVehicles.map((info, index) => (
                  <>
                    {info.isHeader && index > 1 && <Divider variant="middle" />}
                    {!info.isTotal && (
                      <ListItem
                        key={info.label}
                        dense
                        className={
                          !info.isHeader &&
                          vehicleCount?.get_vehicles_count?.status_count?.[
                            info.dataHeader
                          ] &&
                          !isShareLocation
                            ? classes.vehicleCountBox
                            : ""
                        }
                      >
                        {["Available", "Booked"].includes(info.label) ? (
                          <ListItemText
                            id={info.label}
                            primary={info.label}
                            className={info.isHeader ? classes.text_count : ""}
                            style={{
                              display: hideLogisticsAdmin
                                ? "none"
                                : hideLogisticsClient
                                ? "none"
                                : "block"
                            }}
                            onClick={() => vehicleStatusFilterHandler(info)}
                          />
                        ) : (
                          <ListItemText
                            id={info.label}
                            primary={info.label}
                            className={info.isHeader ? classes.text_count : ""}
                            onClick={() => vehicleStatusFilterHandler(info)}
                            style={{
                              display:
                                hideLogisticsAdmin && info.label === "Active"
                                  ? "block"
                                  : hideLogisticsClient &&
                                    info.label === "Active"
                                  ? "block"
                                  : info.label !== "Active"
                                  ? "block"
                                  : "none"
                            }}
                          />
                        )}
                        {!info.isHeader && (
                          <ListItemSecondaryAction>
                            {["Available", "Booked"].includes(info.label) ? (
                              <Typography
                                edge="end"
                                className={classes.countResult}
                                style={{
                                  display: hideLogisticsAdmin
                                    ? "none"
                                    : hideLogisticsClient
                                    ? "none"
                                    : "block"
                                }}
                              >
                                {info.isTotal
                                  ? vehicleCount.length
                                  : vehicleCount?.get_vehicles_count
                                      ?.status_count?.[info.dataHeader] || 0}
                              </Typography>
                            ) : (
                              <Typography
                                edge="end"
                                className={classes.countResult}
                                style={{
                                  display:
                                    hideLogisticsAdmin &&
                                    info.label === "Active"
                                      ? "block"
                                      : hideLogisticsClient &&
                                        info.label === "Active"
                                      ? "block"
                                      : info.label !== "Active"
                                      ? "block"
                                      : "none"
                                }}
                              >
                                {info.isTotal
                                  ? vehicleCount.length
                                  : vehicleCount?.get_vehicles_count
                                      ?.status_count?.[info.dataHeader] || 0}
                              </Typography>
                            )}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )}
                    {info.subCategory.length > 0 &&
                      info.subCategory.map(subInfo => {
                        return ["Under Repair", "Not In Use"].includes(
                          subInfo.label
                        ) ? (
                          <ListItem
                            className={
                              vehicleCount?.get_vehicles_count?.status_count?.[
                                subInfo.dataHeader
                              ]
                                ? classes.nestedActive
                                : classes.nestedDisabled
                            }
                            key={subInfo.label}
                            dense
                            style={{
                              display: hideLogisticsAdmin
                                ? "none"
                                : hideLogisticsClient
                                ? "none"
                                : "block"
                            }}
                          >
                            <ListItemText
                              id={subInfo.label}
                              primary={`${info.label}${
                                subInfo.label === "Under Repair" ? " " : "-"
                              }${subInfo.label}`}
                              onClick={() =>
                                vehicleStatusFilterHandler(subInfo)
                              }
                            />
                            <ListItemSecondaryAction
                              style={{
                                color: "gray",
                                display: hideLogisticsAdmin
                                  ? "none"
                                  : hideLogisticsClient
                                  ? "none"
                                  : "block"
                              }}
                            >
                              <Typography
                                edge="end"
                                className={classes.countResult}
                              >
                                {vehicleCount?.get_vehicles_count
                                  ?.status_count?.[subInfo.dataHeader] || 0}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : (
                          <ListItem
                            className={classes.nested}
                            key={subInfo.label}
                            dense
                          >
                            <ListItemText
                              id={subInfo.label}
                              primary={`${info.label}${
                                subInfo.label === "Under Repair" ? " " : "-"
                              }${subInfo.label}`}
                            />
                            <ListItemSecondaryAction style={{ color: "gray" }}>
                              <Typography
                                edge="end"
                                className={classes.countResult}
                              >
                                {vehicleCount?.get_vehicles_count
                                  ?.status_count?.[subInfo.dataHeader] || 0}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                  </>
                ))}
              </List>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
});

export default VehicleCount;
