import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, TextField, Grid, Divider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  input: {
    padding: 0
  }
});

const Land = () => {
  const classes = useStyles();
  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4" className={classes.title} color="primary">
            LAND
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ padding: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography>Location: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={[]}
                limitTags={2}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField {...params} placeholder="All" />
                )}
                disableClearable
                value={[]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          {/* <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div> */}
          <Grid container spacing={3} style={{ padding: 50 }}>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography variant="h4" align="center">
                  12
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      6
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      2
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">Resupply</Typography>
                <Typography variant="h4" align="center">
                  7
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      9
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      1
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">DC Pickup</Typography>
                <Typography variant="h4" align="center">
                  16
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      5
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      5
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">Customer</Typography>
                <Typography variant="h4" align="center">
                  1
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Land;
