/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { ArrowLeft, ArrowRight, Clear, Search } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { useParams } from "react-router-dom";
import {
  GET_ASSIGNED_GEOFENCE,
  GET_ASSIGNED_ROUTE,
  GET_GEOFENCES_TABLE,
  GET_VEHICLES_LOOKUP
} from "../../../../../graphql/Queries";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import useUserContext from "../../../../../context/User/useUserContext";
import _ from "lodash";
import CustomDateRange from "./CustomDateRange";
import Loading from "../../../../../utils/Loading";
import { ASSIGN_ROUTE_GEOFENCE, ASSIGN_STANDARD_GEOFENCE } from "../../../../../graphql/Mutations";
import MapComponent from "./MapComponent";
import useDebounce from "../../../../../utils/hooks/useDebounce";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import useSearchGeofence from "../../../../../utils/hooks/useSearchGeofence";
import { AntSwitch, useStyles } from "./AssignGeofence.styles";
import AddLogs from "../../../../../utils/functions/AddLogs";
import { GeofenceTable } from "./GeofenceTables";

const AssignGeofence = props => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState({
    save: false,
    cancel: false,
    searchbar: false,
    switchAlert: false,
    unassign: false
  });
  const [standardChecked, setStandardChecked] = useState([]);
  const [selected, setSelected] = useState();
  const [initialState, setInitialState] = useState({
    standard: [],
    route: null
  });
  const [plateno, setPlateno] = useState("");
  const [errors, setErrors] = useState({
    date: "",
    show: false
  });

  const [selectedFromDate, handleFromDateChange] = useState(null);
  const [selectedToDate, handleToDateChange] = useState(null);
  const [selectGeo, setSelectGeo] = useState(null);

  const user = useUserContext();
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [hauler, setHauler] = useState();
  
  const [geos, setGeos] = useState({
    standard: [],
    route: [],
    assignedStandard: [],
    assignedRoute: []
  });
  const [showAssigned, setShowAssigned] = useState(false);

  const [assignStandardGeofence] = useMutation(ASSIGN_STANDARD_GEOFENCE);
  const [assignRouteGeofence] = useMutation(ASSIGN_ROUTE_GEOFENCE);

  const getVariables = () => {
    const variables = {
      keyword: keyword,
      keyword_fields: ["name", "geofence_code", "category", "region", "province", "municipality"],
      skip: 0,
      first: rowsPerPage,
      orderBy: [{ field: "name", direction: "asc" }]
    };
    
    Object.assign(variables, {
      group_ids: hauler
    });

    return variables;
  };

  useEffect(() => {
    if (user.client.group_ids) {
      setHauler([user.client.group_ids[_.findIndex(user.client.group_names, gn => gn === user.client.name)]]);
    }
  }, [user.client.group_ids]);
  
  const DEBOUNCE_TIME = 800;
  const [searchKeyword, setSearchKeyword] = useDebounce("", DEBOUNCE_TIME);
  const geofenceOptions = useSearchGeofence(searchKeyword, hauler, tabValue);

  const previewGeo = geofence => {
    if (geofence) {
      setSelected(geofence);
      setSelectGeo(geofence);
    }
  };

  const { loading: loadingAssignedRoute, data: assignedRoute } = useQuery(GET_ASSIGNED_ROUTE, {
    variables: {
      vehicle_id: id
    },
    onCompleted: data => {
      const { route } = data?.get_assigned_route;
      if (route) {
        const routeGeo = {
          ...route,
          monitor_route: !!route.monitor_route,
          is_permanent: !!route.is_permanent
        };
        setGeos({...geos, assignedRoute: [routeGeo]});
        // previewGeo(route[0]);
        setInitialState({...initialState, route: _.cloneDeep(routeGeo)});
        handleFromDateChange(route?.start_stamp === "1111-11-11 08:00:00" ? null : route.start_stamp);
        handleToDateChange(route?.end_stamp === "1111-11-11 08:00:00" ? null : route.end_stamp);
      }
      setLoading(false);
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hauler
  });

  const { loading: loadingAssignedStandard, data: assignedStandard, fetchMore: fetchMoreAssigned } = useQuery(GET_ASSIGNED_GEOFENCE, {
    variables: {
      vehicle_id: id
    },
    onCompleted: data => {
      const { geofences } = data?.get_assigned_geofence;
      if (geofences) {
        const geos = _.sortBy(geofences, "geofence_name");
        setGeos(prev => ({...prev, assignedStandard: geos}));
        setStandardChecked(geos);
        setInitialState({...initialState, standard: _.cloneDeep(geos)});
        previewGeo(geos[0]);
      }
      setLoading(false);
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hauler
  });

  const {
    loading: loadingStandard,
    data: standardData,
    fetchMore: fetchMoreStandard
  } = useQuery(GET_GEOFENCES_TABLE, {
    variables: {
      ...getVariables(),
      keyword: tabValue === 0 ? keyword : "",
      not: {
        field: "category",
        value: "Route"
      }
    },
    onCompleted: data => {
      if (data) {
        setGeos({...geos, standard: data?.get_geofences?.geofences});
        tabValue === 0 && !showAssigned && setTotalPage(data?.get_geofences?.total ? Math.ceil(data?.get_geofences?.total / rowsPerPage) : 1);
      }
      setLoading(false);
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hauler
  });

  const {
    loading: loadingRoutes,
    error: errorRoute,
    data: routeData,
    fetchMore: fetchMoreRoute
  } = useQuery(GET_GEOFENCES_TABLE, {
    variables: {
      ...getVariables(),
      keyword: tabValue === 1 ? keyword : "",
      condition: {
        field: "category",
        value: "Route"
      }
    },
    onCompleted: data => {
      if (data) {
        setGeos({...geos, route: data?.get_geofences?.geofences});
        tabValue === 1 && setTotalPage(data?.get_geofences?.total ? Math.ceil(data?.get_geofences?.total / rowsPerPage) : 1);
      }
      setLoading(false);
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !hauler
  });

  const fetchMoreData = nextPage => {
    const fetchQry = {
      variables: {
        first: rowsPerPage,
        skip: (nextPage - 1) * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_geofences: {
            count: fetchMoreResult.get_geofences.count,
            total: fetchMoreResult.get_geofences.total,
            geofences: [
              ...prev.get_geofences.geofences,
              ...fetchMoreResult.get_geofences.geofences
            ]
          }
        };
      }
    };
    
    if (!showAssigned) {
      if (tabValue === 0) {
        fetchMoreStandard(fetchQry);
      } else {
        fetchMoreRoute(fetchQry);
      }
    } 
  };

  const { data: vehicle_data } = useQuery(GET_VEHICLES_LOOKUP, {
    variables: {
      filter: {
        field: "vehicle_info.id",
        value: id
      }
    },
    onCompleted: data => {
      if (data) {
        const vehicle_info = data.get_vehicles?.vehicles[0];
        setPlateno(vehicle_info.plateno.trim());
      }
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  const handleDownPage = () => {
    setPage(page - 1);
  };

  const handleUpPage = () => {
    setPage(page + 1);
    const nextPage = page;
    let geosTotal;
    if (showAssigned) {
      geosTotal = geos.assignedStandard.length;
    } else {
      geosTotal = tabValue === 0 ? geos.standard.length : geos.route.length;
    }
    const dataLength = geos ? geosTotal : 0;
    // check if the requested page data is already fetched
    if (
      nextPage * rowsPerPage === dataLength ||
      nextPage * rowsPerPage >= dataLength
    ) {
      !showAssigned && setLoading(true);
      fetchMoreData(page + 1);
    }
  };

  const handleOpen = type => {
    setOpen(prev => ({ ...prev, [type]: true }));
  };

  const handleClose = type => {
    setOpen(prev => ({ ...prev, [type]: false }));
  };

  const handleTabChange = (newValue) => {
    // setLoading(true);
    clearStates();
    setShowAssigned(false);
    let geoCount;
    if (newValue === 1) {
      geoCount = routeData?.get_geofences?.total;
      if (!_.isEmpty(geos.assignedRoute)) {
        previewGeo(geos.assignedRoute[0]);
      }
    } else {
      geoCount = standardData?.get_geofences?.total;
      if (!_.isEmpty(geos.assignedStandard)) {
        previewGeo(geos.assignedStandard[0]);
      }
    }
    setTabValue(newValue);
    setTotalPage(geoCount ? Math.ceil(geoCount / rowsPerPage) : 1);
  };

  const clearStates = () => {
    setSearchKeyword("");
    setKeyword("");
    setPage(1);
    setSelected();
    setSelectGeo();
    setStandardChecked(initialState.standard);
  };

  // modify route object
  const handleEditRoute = (e, nv) => {
    const { name } = e.target;
    setSelected(prev => ({...prev, [name]: nv}));
  };

  // geofence table search
  const handleSearch = e => {
    if (e.key === "Enter") {
      setPage(1);
      setKeyword(e.target.value);
    }
  };

  const getCheckedGeos = () => {
    if (tabValue === 1) {
      if (selected) {
        return [selected];
      }
      return [];
    }
    if (keyword) {
      const filtered = standardChecked.filter(x => x.geofence_name.includes(keyword) || x.geofence_code.includes(keyword));
      return _.sortBy(filtered, "geofence_name");
    }
    return _.sortBy(standardChecked, "geofence_name");
  }

  const handleDiscard = () => {
    if (open.cancel) {
      redirectToAdmin();
    } else if (open.switchAlert) {
      handleTabChange(+(!tabValue));
    }
  };

  const redirectToAdmin = () => {
    history.push({
      pathname: "/admin",
      state: {
        params: {
          moduleName: process.env.REACT_APP_VEHICLES_MODULE
        }
      }
    });
  };
  
  const validateRoute = () => {
    let isValid = false;

    if (selected?.geofence_id && !selected?.is_permanent && (moment(selectedFromDate).format() > moment(selectedToDate).format())) {
      setErrors(prev => ({ ...prev, date: "To date should not be before From date" }));
    } else if (selected?.geofence_id && !selected?.is_permanent && (!selectedFromDate || !selectedToDate)) {
      setErrors(prev => ({ ...prev, date: "Date is required" }));
    } else {
      isValid = true;
      setErrors(prev => ({ ...prev, date: "" }));
    }
    setErrors(prev => ({ ...prev, show: false }));
    return isValid;
  };

  const handleToggleAssigned = () => {
    setShowAssigned(!showAssigned);
    setPage(1);
    let geoCount;
    if (tabValue === 1) {
      geoCount = !showAssigned ? initialState.route?.length : routeData?.get_geofences?.total;
    } else {
      geoCount = !showAssigned ? standardChecked.length : standardData?.get_geofences?.total;
    }
    setTotalPage(geoCount ? Math.ceil(geoCount / rowsPerPage) : 1);
  }

  const handleSave = () => {
    if (tabValue === 1 && !validateRoute()) {
      setErrors(prev => ({...prev, show: true}));
    } else {
      handleOpen("save");
    }
  };

  const handleSubmit = () => {
    if (tabValue === 0) {
      const standardCheckedIds = standardChecked.map(g => +g.geofence_id);
      assignStandardGeofence({
        variables: {
          vehicle_id: Number(id),
          geofence_ids: standardCheckedIds
        },
        onCompleted: data => {
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 2500
          }).then(() => {
            if (!initialState.length) {
              AddLogs(
                "Admin - Vehicles/CV",
                "assign_geofence",
                plateno
              );
            } else {
              AddLogs(
                "Admin - Vehicles/CV",
                "edit_assigned_geofence",
                plateno
              );
            }
            redirectToAdmin();
          });
        },
        onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
      });
    } else {
      assignRouteGeofence({
        variables: {
          assign_route: {
            vehicle_id: +id,
            geofence_id: +selected?.geofence_id || +selected?.id || 0,
            monitor_route: +selected?.monitor_route || 0,
            is_permanent: +selected?.is_permanent || 0,
            start_stamp: selected?.geofence_id && !selected?.is_permanent ? moment(selectedFromDate).set({"hour": 0, "minute": 0}).format() : null,
            end_stamp: selected?.geofence_id && !selected?.is_permanent ? moment(selectedToDate).set({"hour": 23, "minute": 59}).format() : null
          }
        },
        onCompleted: data => {
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 2500
          }).then(() => {
            if (!initialState) {
              AddLogs(
                "Admin - Vehicles/CV",
                "assign_geofence",
                plateno
              );
            } else {
              AddLogs(
                "Admin - Vehicles/CV",
                "edit_assigned_geofence",
                plateno
              );
            }
            redirectToAdmin();
          });
        },
        onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
      });
      
    }
  };

  const getTotalPage = () => {
    if (showAssigned) {
      return getCheckedGeos().length ? Math.ceil(getCheckedGeos().length / rowsPerPage) : 1;
    }
    return totalPage;
  }

  const geoMap = useMemo(() => {
    return (
      <MapComponent
        location={selectGeo?.location || { lat: "", lon: "" }}
        selectGeo={selectGeo}
        isRoute={tabValue}
      />
    );
  }, [selectGeo]);

  return (
    <div className={classes.container}>
      <Grid container spacing={6} style={{ height: "100%", overflowY: "auto" }}>
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "500px"
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography className={classes.label} variant="h5">
              Assign Geofence
            </Typography>
            <div className={classes.vehicle_plate}>
              <Typography>{plateno}</Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: "0.8rem",
              marginTop: "1rem"
            }}
          >
            <Tabs
              className={classes.tabs}
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, nv) => {
                // check for changes made
                const checked = tabValue === 0 ? standardChecked : selected;
                const initialData = tabValue === 0 ? initialState.standard : initialState.route;
                if ((!_.isEmpty(initialData) || !_.isEmpty(checked)) && !_.isEqual(initialData, checked)) {
                  handleOpen("switchAlert");
                } else {
                  handleTabChange(nv);
                }
              }}
            >
              <Tab
                className={classes.tab}
                label="Standard Geofence"
                value={0}
                disableRipple
              />
              <Tab
                className={classes.tab}
                label="Route Geofence"
                value={1}
                disableRipple
              />
            </Tabs>
            {loading || loadingStandard || loadingRoutes || loadingAssignedStandard || loadingAssignedRoute ? (
              <Loading />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div style={{display: "flex"}}>
                    <Typography className={classes.label} variant="h6">
                      Geofence
                    </Typography>
                    {tabValue === 1 && (
                      <Button 
                        className={classes.btn_unassign}
                        disabled={!selected?.geofence_id}
                        onClick={() => handleOpen("unassign")}
                      >
                        Unassign
                      </Button>
                    )}
                  </div>
                  {open.searchbar ? (
                    <Autocomplete
                      freeSolo
                      options={geofenceOptions}
                      getOptionSelected={(o, v) => o === v}
                      getOptionLabel={option => option || ""}
                      value={keyword}
                      renderInput={(params) => (
                        <div className={classes.search_box} ref={params.InputProps.ref}>
                          <Search />
                          <InputBase
                            {...params}
                            placeholder="Search Geofence"
                            autoFocus
                            InputProps={{ ...params.InputProps, type: 'search' }}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            onKeyDown={handleSearch}
                          />
                          <Clear
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClose("searchbar")}
                          />
                        </div>
                      )}
                    />
                  ) : (
                    <Search
                      onClick={() => handleOpen("searchbar")}
                      style={{ color: "#a2a2a2", cursor: "pointer" }}
                    />
                  )}
                </div>
                {tabValue === 0 ? (
                  <GeofenceTable 
                    geos={!showAssigned ? geos?.standard : getCheckedGeos()}
                    selected={selected}
                    setSelected={setSelected}
                    previewGeo={previewGeo}
                    standardChecked={standardChecked}
                    setStandardChecked={setStandardChecked}
                    keyword={keyword}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    isRoute={false}
                    showAssigned={showAssigned}
                  />
                ) : (
                  <GeofenceTable 
                    geos={!showAssigned ? geos?.route : getCheckedGeos()}
                    selected={selected}
                    setSelected={setSelected}
                    previewGeo={previewGeo}
                    keyword={keyword}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    errors={errors}
                    setErrors={setErrors}
                    isRoute={true}
                    showAssigned={showAssigned}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Checkbox
                        color="primary"
                        name="is_permanent"
                        checked={showAssigned}
                        onChange={handleToggleAssigned}
                      />
                    }
                    labelPlacement="end"
                    label={
                      <Typography>
                        {tabValue === 0 ? standardChecked.length : +!_.isEmpty(selected)} Geofences selected
                      </Typography>
                    }
                  />
                  <div>
                    <IconButton
                      disabled={page === 1}
                      onClick={handleDownPage}
                      style={{ cursor: "pointer" }}
                    >
                      <ArrowLeft />
                    </IconButton>
                    {page} / {getTotalPage()}
                    <IconButton
                      disabled={page === getTotalPage()}
                      onClick={handleUpPage}
                      style={{ cursor: "pointer" }}
                    >
                      <ArrowRight />
                    </IconButton>
                  </div>
                </div>
                {tabValue === 1 && (
                  <>
                  {errors.geofence && errors.show && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.geofence}
                    </Alert>
                  )}
                    <div>
                      <Typography>Notifications:</Typography>
                      <FormControlLabel
                        className={classes.oor_label}
                        control={
                          <AntSwitch
                            name="monitor_route"
                            checked={selected?.monitor_route || false}
                            onChange={(e) => handleEditRoute(e, !selected?.monitor_route)}
                            disabled={!selected?.geofence_id}
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label={
                          <Typography
                            style={{
                              fontSize: 14,
                              letterSpacing: "1px"
                            }}
                          >
                            Out of Route
                          </Typography>
                        }
                      />
                    </div>
                    <Divider light style={{ marginTop: 5, marginBottom: 5 }} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>Date Range:</Typography>
                      <FormControlLabel
                        style={{ marginLeft: "4rem" }}
                        control={
                          <Checkbox
                            color="primary"
                            name="is_permanent"
                            checked={selected?.is_permanent || false}
                            disabled={!selected?.geofence_id}
                            onChange={(e) => {
                              handleEditRoute(e, !selected?.is_permanent)
                              validateRoute();
                            }}
                          />
                        }
                        labelPlacement="end"
                        label={
                          <Typography
                            style={{
                              fontSize: 14,
                              letterSpacing: "1px"
                            }}
                          >
                            Permanent
                          </Typography>
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "2rem"
                      }}
                    >
                      <CustomDateRange
                        disabled={!selected?.geofence_id || !!selected?.is_permanent}
                        selectedFromDate={selectedFromDate}
                        handleFromDateChange={handleFromDateChange}
                        selectedToDate={selectedToDate}
                        handleToDateChange={handleToDateChange}
                        handleBlur={validateRoute}
                      />
                    </div>
                    {errors.date && errors.show && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.date}
                      </Alert>
                    )}
                  </>
                )}
              </>
            )}

            <div className={classes.bottom}>
              <Button
                onClick={() => {
                  const checked = tabValue === 0 ? standardChecked : selected;
                  const initialData = tabValue === 0 ? initialState.standard : initialState.route;
                  if ((!_.isEmpty(initialData) || !_.isEmpty(checked)) && !_.isEqual(initialData, checked)) {
                    handleOpen("cancel");
                  } else {
                    redirectToAdmin();
                  }
                }}
                className={classes.btn_rounded}
                style={{
                  color: "#F49400",
                  background: "#ffffff",
                  border: "1px solid #F49400",
                  marginRight: "1rem"
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                className={classes.btn_rounded}
              >
                Save Details
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} style={{ minHeight: "500px" }}>
          {geoMap}
        </Grid>
      </Grid>
      <ConfirmationDialog
        toggle={open.unassign}
        close={() => handleClose("unassign")}
        fn={clearStates}
        title="Unassign Geofence"
        content="Are you sure you want to unassign this route geofence?"
      />
      <ConfirmationDialog
        toggle={open.cancel || open.switchAlert}
        close={() => {
          const modal = open.cancel ? "cancel" : "switchAlert";
          handleClose(modal);
        }}
        fn={handleDiscard}
        title="Discard Changes?"
        content="Are you sure you want to leave this page? Unsaved changes will be discarded."
      />
      <ConfirmationDialog
        toggle={open.save}
        close={() => handleClose("save")}
        fn={handleSubmit}
        title="Save?"
        content="Are you sure you want to save the changes made?"
      />
    </div>
  );
};

export default AssignGeofence;