import { Box, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../Admin/Sidebar";
import TripAllocation from './TripAllocation';
import _ from "lodash";
import useUserContext from "../../context/User/useUserContext";
import VehicleAllocation from "./VehicleAllocation";
import AccessControl from "../../utils/AccessControl";

const PARENT_ID_MOD = 0;
const ACT_MODULE = "allocation";
const INIT_EXP_TAB = "ALLOCATION";

const Allocation = props => {
  const userCtx = useUserContext();
  const classes = useStyles();
  const { location } = props;
  const [expandedTab, setExpandedTab] = useState(INIT_EXP_TAB);
  const [sidebarVal, setSidebarVal] = useState("");
  const [resource, setResource] = useState("");
  const accessRightsCtx = userCtx.access_rights;

  const handleChanges = (text, res) => {
    setSidebarVal(text);
    setResource(res);
  };

  const getMenuHandler = useCallback(submodule => {
    let displayComponent;
    switch (submodule) {
      case "Trip Allocation":
        displayComponent = <TripAllocation />;
        break;
      case "Vehicle Allocation":
        displayComponent = <VehicleAllocation />;
        break;
      default:
        break;
    }
    return displayComponent;
  }, []);

  const setLandingPage = () => {
    if (accessRightsCtx) {
      accessRightsCtx
        .filter(
          modules =>
            _.isEqual(+modules.parent_id, PARENT_ID_MOD) &&
            _.isEqual(modules.resource.split("/")[1], ACT_MODULE) &&
            modules.permission.view
        )
        .some(modules => {
          const submodule = accessRightsCtx.filter(
            v => _.isEqual(v.parent_id, modules.id) && v.permission.view
          );

          handleChanges(submodule[0].name, submodule[0].resource);
          return true;
        });
    }
  };

  /**
   * retain selected submodule on reload
   */
  useEffect(() => {
    if (_.isEmpty(location?.state)) {
      setLandingPage();
    } else {
      if (accessRightsCtx) {
        const prevSubmodule = accessRightsCtx.filter(module =>
          _.isEqual(module.name, location.state.params.moduleName)
        );
        handleChanges(prevSubmodule[0]?.name, prevSubmodule[0]?.resource);
      }
    }
  }, [accessRightsCtx]);

  return (
    <AccessControl
      resource="/allocation/"
      process="allocation"
    >
      <Box className={classes.root}>
        <Sidebar
          sidebarVal={sidebarVal}
          handleChanges={handleChanges}
          expanded={expandedTab}
          setExpanded={setExpandedTab}
          resource={resource}
          setResource={setResource}
          activeTab={ACT_MODULE}
        />
        <Box className={classes.content}>{getMenuHandler(sidebarVal)}</Box>
      </Box>
    </AccessControl>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "100%"
  },
  sidebar: {
    flexShrink: 0,
    height: "100%"
  }
}));

export default Allocation;
