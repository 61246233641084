/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Container, Tab, Tabs } from "@material-ui/core";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Client from "./Client";
import Users from "./Users";
import Vehicles from "./Vehicles";
import VehicleTypes from "./VehicleTypes";
import Geofences from "./Geofences";
import Alerts from "./Alerts";
import Devices from "./Devices";
import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import {
  GET_ADMIN_GROUPS,
  GET_ADMIN_CLIENT
} from "../../../../../graphql/Queries";
import AccessControl from "../../../../../utils/AccessControl";
import Dashboard from "./Dashboard/Dashboard";
import SearchContext from "../../../../../context/SearchContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0, margin: 0 }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#EAEAEA",
    maxWidth: "calc(100vw - 70px)"
  },
  container: {
    height: "calc(100% - 40px)", // 40 for header
    flexGrow: 1,
    padding: "30px",
    overflowX: "hidden",
    overflowY: "auto",
    boxSizing: "border-box",
    background: "#EAEAEA",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
        color: "red"
      }
    }
  },
  paper: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    boxSizing: "border-box"
  },
  inputLabel: {
    marginLeft: 10
  },
  tabs: {
    minWidth: 150
  }
}));
// tab redirection
const indexToTabName = {
  details: 0,
  users: 1,
  vehicles: 2,
  vehicleType: 3,
  geofences: 4,
  gpsDevices: 5,
  alerts: 6,
  dashboard: 7
};

const tabNameToIndex = {
  0: "details",
  1: "users",
  2: "vehicles",
  3: "vehicleType",
  4: "geofences",
  5: "gpsDevices",
  6: "alerts",
  7: "dashboard"
};

export default function ViewClient(props) {
  const { history, location } = props;
  const { id: clientId, page } = useParams();
  const contextData = useContext(SearchContext)
  const {searchKeyword, setSearchKeyword} = contextData
  // const { id: clientId, group_ids, group_names } = location.state.params;
  // const groupIds = [];
  const classes = useStyles();
  const [loading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [maxUsers, setMaxUsers] = useState(0);
  const [maxGeofences, setMaxGeofences] = useState(0);
  const [maxDevices, setMaxDevices] = useState(0);
  const [value, setValue] = useState(0);
  const [otherGroups, setOtherGroups] = useState([]);
  const [formEdit, setFormEdit] = useState(false);
  const [checkChanges, setCheckChanges] = useState(false);
  const [handleOpen, setHandleOpen] = useState();
  const [editPermission, setEditPermission] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [group_ids, setGroup_ids] = useState([]);
  const [group_names, setGroupNames] = useState([]);
  const removedIdRef = useRef([]);

  const { data: currentData, refetch: refetchGeo } = useQuery(
    GET_ADMIN_CLIENT,
    {
      variables: { id: clientId },
      fetchPolicy: "network-only"
    }
  );
  // set the set value of the active tab if redirected to this page.
  useEffect(() => {
    if (page) setValue(indexToTabName[page]);
    else
    // setting values for table filter values
      history.push({
        pathname: `/admin/wti/clients/view/${clientId}/${tabNameToIndex[value]}`,
        state: {
          params: {
            moduleName: process.env.REACT_APP_CLIENTS_MODULE
          },
          vars: history?.location?.state?.vars,
          filter: history?.location?.state?.filter
        }
      });
  }, [page]);

  useEffect(() => {
    if (currentData) {
      // setGroup_ids(currentData.get_client.group_ids);
      // setGroupNames(currentData.get_client.group_names);
      // setGroupIds(currentData.get_client.group_ids.map(row => row.toString()));

      const { group_ids: gIds, group_names: gNames } = currentData.get_client;
      const clientGrpId = [
        gIds[
          _.findIndex(gNames, name =>
            _.isEqual(name, currentData.get_client.name)
          )
        ]
      ];
      setGroup_ids(clientGrpId);
      setGroupNames([currentData.get_client.name]);
      setGroupIds(clientGrpId.map(row => row?.toString()));
    }
  }, [currentData]);

  // if (group_ids) {
  //   group_ids.map(row => {
  //     groupIds.push(row.toString());
  //     return true;
  //   });
  // }

  // const { data: groups_data } = useQuery(GET_ADMIN_GROUPS, {
  //   variables: {
  //     skip: 0,
  //     first: 10000,
  //     not: [
  //       {
  //         field: "id",
  //         value: groupIds
  //       }
  //     ]
  //   }
  // });

  // useEffect(() => {
  //   if (groups_data) {
  //     const { get_groups } = groups_data;
  //     setOtherGroups(get_groups.groups);
  //   }
  // }, [groups_data, canDelete]);
  // const [dialog, setDialog] = React.useState({
  //   discard: false,
  //   save: false
  // });
  const handleChange = (event, newValue) => {
    // setting values for table filter values
    history.push({
      pathname: `/admin/wti/clients/view/${clientId}/${tabNameToIndex[newValue]}`,
      state: {
        params: {
          moduleName: process.env.REACT_APP_CLIENTS_MODULE
        },
        vars: history?.location?.state?.vars,
        filter: history?.location?.state?.filter
      }
    });
    setValue(newValue);
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges) {
        setDiscard(true);
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const handleCloseDialog = () => {
    setDiscard(false);
  };

  const discarded = () => {
    setEditMode(false);
    handleCloseDialog();
    setRedirect(true);
  };
  const discarded1 = () => {
    setEditMode(false);
    handleCloseDialog();
  };

  React.useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
    });
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <AccessControl
      resource="/admin/wti/clients/"
      process="wti_clients"
      setCanDelete={setCanDelete}
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        <Header
          toggleEditMode={toggleEditMode}
          moduleName={process.env.REACT_APP_CLIENTS_MODULE}
          history={history?.location?.state}
          setRedirect={setRedirect}
          // process={formEdit ? "Edit" : "View"}
          process={editMode ? "Edit" : "View"}
          editMode={editMode}
          editPermission={editPermission}
          redirect={redirect}
          setDiscard={setDiscard}
          isDataNotChanged={!formEdit}
        />
        <div className={classes.container}>
          <Container style={{ width: "fit-content" }}>
            <div className={classes.paper}>
              <AppBar
                position="static"
                style={{ color: "#000000", boxShadow: "none" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  style={{ background: "#EAEAEA" }}
                  indicatorColor="primary"
                  // scrollButtons="auto"
                  // variant="scrollable"
                  // TabIndicatorProps={{ style: { background: "green" } }}
                >
                  <Tab value={0} label="Details" className={classes.tabs} />
                  <Tab value={1} label="Users" className={classes.tabs} />
                  <Tab value={2} label="Vehicles" className={classes.tabs} />
                  <Tab
                    value={3}
                    label="Vehicle Type"
                    className={classes.tabs}
                  />
                  <Tab value={4} label="Geofences" className={classes.tabs} />
                  <Tab value={5} label="GPS Devices" className={classes.tabs} />
                  <Tab value={6} label="Alerts" className={classes.tabs} />
                  <Tab value={7} label="Dashboard" className={classes.tabs} />
                </Tabs>
              </AppBar>
              <TabPanel
                style={{ border: "1px solid #D1D1D1", padding: 0 }}
                value={value}
                index={0}
              >
                <Client
                  setMaxUsers={setMaxUsers}
                  setMaxGeofences={setMaxGeofences}
                  setMaxDevices={setMaxDevices}
                  location={location}
                  history={history}
                  clientId={clientId}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setFormEdit={setFormEdit}
                  setCheckChanges={setCheckChanges}
                  setHandleOpen={setHandleOpen}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded1()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Users
                  clientId={clientId}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  maxUsers={maxUsers}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Vehicles
                  clientId={clientId}
                  editMode={editMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  group_names={group_names}
                  maxDevices={maxDevices}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <VehicleTypes
                  clientId={clientId}
                  editMode={editMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  group_names={group_names}
                  maxDevices={maxDevices}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Geofences
                  clientId={clientId}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  group_names={group_names}
                  maxGeofences={maxGeofences}
                  setFormEdit={setFormEdit}
                  setCheckChanges={setCheckChanges}
                  adminClient={currentData}
                  refetchGeo={() => refetchGeo()}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded1()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Devices
                  group_names={group_names}
                  maxDevices={maxDevices}
                  clientId={clientId}
                  editMode={editMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Alerts
                  clientId={clientId}
                  editMode={editMode}
                  groupIds_int={group_ids}
                  groupIds_str={groupIds}
                  group_names={group_names}
                  // otherGroups={otherGroups}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <Dashboard
                  mode={editMode}
                  groupIds={group_ids}
                  removedIdRef={removedIdRef}
                />
                <ConfirmationDialog
                  toggle={discard}
                  close={() => handleCloseDialog()}
                  fn={() => discarded()}
                  title="Discard Changes?"
                  content="Are you sure you want to leave this page and discard changes?"
                />
              </TabPanel>
            </div>
          </Container>
        </div>
      </div>
    </AccessControl>
  );
}
