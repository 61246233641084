import React from "react";
import { Typography } from "@material-ui/core";

import useFormStyles from "../styles/useFormStyles";

const DivisionTitle = ({ label }) => {
  const styles = useFormStyles();
  return (
    <Typography variant="subtitle1" classes={{ root: styles.divisionTitle }}>
      {label}
    </Typography>
  );
};

export default DivisionTitle;
