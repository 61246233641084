import React, { cloneElement, useEffect } from "react";
// import { useQuery } from "@apollo/client";
// import { Typography } from "@material-ui/core";
// import Cookie from "js-cookie";
// import { ALLOWED_PERMISSIONS } from "../graphql/Queries";
import Loading from "./Loading";
import Forbidden from "./Errors/403/Forbidden";
import useUserContext from "../context/User/useUserContext";

const AccessControl = ({
  resource,
  process,
  children,
  setEditPermission = () => {},
  setCanDelete = () => {},
  shareable,
  renderNoAccess
}) => {
  // const user = shareable ? "" : JSON.parse(Cookie.get("user"));
  // const user = "";
  const getResource = () => {
    let res = "";
    if (
      process === "tables" ||
      process === "dispatch" ||
      process === "reports" ||
      process === "dashboard" ||
      process === "monitoring" ||
      process === "prebooking" ||
      process === "wti_clients" ||
      process === "feedback" ||
      process === "allocation"
    ) {
      res = resource;
    } else if (process !== "add") {
      res = resource.substring(
        0,
        resource.length -
          (resource.split("/")[resource.split("/").length - 1].length +
            resource.split("/")[resource.split("/").length - 2].length +
            1)
      );
    } else {
      res = resource.substring(
        0,
        resource.length -
          resource.split("/")[resource.split("/").length - 1].length
      );
    }
    return res;
  };
  // const { data, loading } = useQuery(ALLOWED_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id,
  //     resource: getResource()
  //   },
  //   fetchPolicy: "network-only",
  //   skip: !user
  // });

  const user_context = useUserContext();

  useEffect(() => {
    if (shareable) return;
    if (/* data */ user_context) {
      if (
        process !== "add" &&
        process !== "tables" /* && process !== "dispatch" */ &&
        process !== "reports" &&
        process !== "dashboard" &&
        process !== "monitoring" &&
        process !== "prebooking" &&
        process !== "feedback"
      )
        // setEditPermission(data.allowed_permissions.edit);
        setEditPermission(
          user_context?.allowed_permissions?.[getResource()]?.edit
        );

      if (process === "tables") {
        // setCanDelete(data.allowed_permissions.edit);
        setCanDelete(user_context?.allowed_permissions?.[getResource()]?.edit);
      }
    }
  }, [/* data, */ user_context, process, setEditPermission]);

  if (user_context.loading) return <Loading />;

  if (shareable) {
    return cloneElement(children, { test: "hehe" });
  }
  if (user_context) {
    if (process === "tables") {
      if (
        user_context.allowed_permissions[getResource()]?.view ||
        user_context.allowed_permissions[getResource()]?.edit
      ) {
        if (typeof children === "function")
          return children({
            permissions: user_context.allowed_permissions[getResource()]
          });
        return cloneElement(children, { test: "hehe" });
      }
      // return <Typography>403!!!!!!!!!!!!!!!!!!!!!!!!!</Typography>;
      return renderNoAccess ? renderNoAccess() : <Forbidden />;
    }
    if (process === "add") {
      if (user_context.allowed_permissions[getResource()]?.edit) {
        return children;
      }
      return renderNoAccess ? renderNoAccess() : <Forbidden />;
    }
    if (user_context.allowed_permissions[getResource()]?.view) {
      if (typeof children === "function")
        return children({
          permissions: user_context.allowed_permissions[getResource()]
        });
      return cloneElement(children, { test: "hehe" });
    }
  }
  return renderNoAccess ? renderNoAccess() : <Forbidden />;
};

AccessControl.defaultProps = {
  user: "",
  moduleName: "",
  mainModule: "",
  renderNoAccess: null
};

export default AccessControl;
