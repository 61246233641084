/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ACCESS_OBJECTS } from "../../../graphql/Queries";

const useAccessObjects = (default_modules = null) => {
  const [accessRightsTree, setAccessRightsTree] = useState([]);
  const { loading, data: accessRightsData } = useQuery(GET_ACCESS_OBJECTS);
  const statusRef = useRef({ initialLoad: true });

  useEffect(() => {
    if (!loading) {
      statusRef.current.initialLoad = false;
    } else {
      statusRef.current.initialLoad = true;
    }
  }, [loading]);

  const initializeAccessTree = () => {
    if (accessRightsData) {
      let accessObjects = accessRightsData?.get_access_objects;
      if (default_modules) {
        const modules = default_modules;
        accessObjects = accessObjects.map(accessObject => {
          let tempModule = { ...accessObject };
          modules.forEach(moduleItem => {
            if (moduleItem.id === accessObject.id) {
              tempModule = {
                ...accessObject,
                permission: {
                  edit: moduleItem.edit,
                  view: moduleItem.view
                }
              };
            }
          });
          return tempModule;
        });
      }

      if (accessObjects) {
        const parents = accessObjects.filter(a => a.parent_id === "0");
        const getAncestors = parent => {
          return accessObjects.reduce((accum, currentChild) => {
            if (currentChild.parent_id === parent.id) {
              const childList = getAncestors(currentChild);
              accum.push({ parent: currentChild, child: childList });
            }

            return accum;
          }, []);
        };
        const tree = parents.map(parent => {
          return { parent, child: getAncestors(parent) };
        });
        setAccessRightsTree(tree);
      }
    }
  };

  useEffect(() => {
    initializeAccessTree();
  }, [loading, accessRightsData, default_modules]);

  const toggleAllAcessRights = eventInfo => {
    const findChildren = (branch, force = false) => {
      if (branch.parent.id === eventInfo.parent.id || force) {
        const branchInstance = branch;
        if (branchInstance.child.length === 0) {
          return {
            ...branchInstance,
            parent: {
              ...branchInstance.parent,
              permission: {
                view: eventInfo.newChecked,
                edit: eventInfo.newChecked
              }
            }
          };
        } else {
          return {
            parent: {
              ...branchInstance.parent,
              permission: {
                edit: eventInfo.newChecked,
                view: eventInfo.newChecked
              }
            },
            child: branchInstance.child.map(branch =>
              findChildren(branch, true)
            )
          };
        }
      } else {
        const branchInstance = branch;
        if (branchInstance.child.length === 0) {
          return branchInstance;
        }

        const findChild = (accum, current) => {
          const { parent, child } = current;
          if (child.length === 0) {
            // Check if current instance is equal to the object(access right) that we want to update
            if (parent.id === eventInfo.parent.id) {
              // If "true" we then use the new accessRight we passed in
              return {
                edit: accum.edit || eventInfo.newChecked,
                view: accum.view || eventInfo.newChecked
              };
            }

            // If "false" we then use the current access right in the state
            return {
              edit: accum.edit || parent.permission.edit,
              view: accum.view || parent.permission.view
              // edit: accum.edit || eventInfo.newChecked,
              // view: accum.view || eventInfo.newChecked,
            };
          } else {
            if (parent.id === eventInfo.parent.id) {
              return {
                edit: accum.edit || eventInfo.newChecked,
                view: accum.view || eventInfo.newChecked
              };
            }

            // return child.reduce(findChild, {
            //   edit: eventInfo.newChecked,
            //   view: eventInfo.newChecked
            // });

            return child.reduce(findChild, {
              edit: parent.permission.edit,
              view: parent.permission.view
            });
          }
        };

        const newPermission = branchInstance.child.reduce(findChild, {
          view: false,
          edit: false
        });

        return {
          parent: {
            ...branchInstance.parent,
            permission: newPermission
          },
          child: branchInstance.child.map(branch => findChildren(branch))
        };
      }
      // return branch;
    };
    const tree = accessRightsTree?.map(branch => findChildren(branch));
    setAccessRightsTree(tree);
  };

  const toggleCheckbox = eventInfo => {
    const { type, accessRight, newChecked } = eventInfo;

    const parsePermission = newPermission => {
      let permission = { ...newPermission };
      if (type === "view") {
        if (newChecked) {
          permission.view = newChecked;
        } else {
          permission.view = newChecked;
          permission.edit = newChecked;
        }
      } else {
        if (newChecked) {
          permission.view = newChecked;
          permission.edit = newChecked;
        } else {
          permission.edit = newChecked;
        }
      }
      return permission;
    };

    const findChildren = branch => {
      const { parent, child } = branch;
      if (parent.id === accessRight.id) {
        let permission = parsePermission(parent.permission);
        return {
          parent: {
            ...parent,
            permission
          },
          child
        };
      } else {
        const branchInstance = branch;
        // const permission = parsePermission(branchInstance.parent.permission);

        if (branchInstance.child.length === 0) {
          return branchInstance;
        }

        const findChild = (accum, current) => {
          const { parent, child } = current;
          if (child.length === 0) {
            // Check if current instance is equal to the object(access right) that we want to update
            if (parent.id === accessRight.id) {
              const currentParentPermissions = parsePermission(
                parent.permission
              );
              // If "true" we then use the new accessRight we passed in
              return {
                edit: accum.edit || currentParentPermissions.edit,
                view: accum.view || currentParentPermissions.view
              };
            }

            // If "false" we then use the current access right in the state
            return {
              edit: accum.edit || parent.permission.edit,
              view: accum.view || parent.permission.view
            };
          }
          return child.reduce(findChild, accum);
        };

        const newPermission = branchInstance.child.reduce(findChild, {
          edit: false,
          view: false
        });

        return {
          parent: {
            ...branchInstance.parent,
            permission: {
              ...branchInstance.parent.permission,
              ...newPermission
            }
          },
          child: branchInstance.child.map(branch => findChildren(branch))
        };
      }
    };

    const tree = accessRightsTree.map(branch => findChildren(branch));
    setAccessRightsTree(tree);
  };

  return {
    status: statusRef.current,
    initializeAccessTree,
    loading,
    data: accessRightsTree,
    toggleAllAcessRights,
    toggleCheckbox
  };
};

export default useAccessObjects;
