import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles(theme => ({
  root: {
    minWidth: "1000px"
  },
  table_cell: {
    paddingLeft: "24px"
  },
  table_checkBox: {
    paddingLeft: "24px"
  },
  dot_enabled: {
    fontSize: "12px",
    color: "#95FC44",
    paddingRight: "0.5rem"
  },
  dot_disabled: {
    fontSize: "12px",
    paddingRight: "0.5rem",
    color: "#808080"
  },
  geom_cell: {
    maxWidth: "400px"
  },
  geom_div: {
    maxWidth: "85ch",
    whiteSpace: "nowrap"
  },
  info_icon: {
    color: "#808080",
    marginLeft: "5px",
    marginTop: "5px",
    fontSize: "0.8rem"
  },
  info_label: {
    fontSize: "0.6rem",
    maxWidth: "200px"
  },
  info_title: {
    fontWeight: "bold"
  },
  modal_content: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 12
  },
  modal_header: { position: "relative", display: "flex" },
  owned_legend: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginRight: 8
  },
  universal_legend: { display: "flex", alignItems: "center", gap: 4 },
  universal_info: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    gap: "8px",
    padding: "12px",
    background: "#DBF1FE"
  },
  map_container: {
    position: "relative",
    height: "100%",
    width: "100%"
  },
  use_universal_button: {
    backgroundColor: "#53A4F7",
    color: "#FFFFFF",
    lineHeight: 1.5,
    margin: 0,
    textTransform: "none",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#5389f7"
    }
  },
  unuse_universal_button: {
    backgroundColor: "#A2A2A2",
    color: "#FFFFFF",
    lineHeight: 1.5,
    margin: 0,
    textTransform: "none",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#5389f7"
    }
  }
}));
