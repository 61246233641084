import React, { useState, useCallback } from "react";
import Header from "./Header";
import Fleet from "./Fleet/Fleet";
import Demurrage from "./Demurrage";
import OnTime from "./OnTime";
import {
  GET_SERVICE_TYPES,
  GET_CLIENTS_DASHBOARDS,
  GET_CHART_DATA,
  GET_ACCOUNTS,
  GET_BOOKING_GEOFENCES,
  GET_DASHBOARDS_FILTERS,
  GET_DASHBOARDS_FILTER,
  GET_VEHICLE_TYPE_LIST,
  GET_DEMURRAGE_DATA,
  GET_ADMIN_CLIENT,
  GET_ACCESS_OBJECTS
} from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import Filter from "./Filter";

const Dashboard = props => {
  const { history } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [vars, setVars] = useState({
    dateRange: {
      start: moment().format("YYYY-MM-DD 00:00:00"),
      end: moment().format("YYYY-MM-DD 23:59:59")
    }
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const filterHandler = () => setIsOpenFilter(!isOpenFilter);

  // displays the active tab.
  const displayActiveTab = useCallback(() => {
    let activeTabTemp;
    switch (activeTab) {
      case 1:
        activeTabTemp = <Demurrage variables={vars} history={history} />;
        break;
      case 2:
        activeTabTemp = <Fleet />;
        break;
      default:
        activeTabTemp = <OnTime variables={vars} history={history} />;
        break;
    }
    return activeTabTemp;
  }, [activeTab, vars]);

  return (
    <>
      <Header
        setTab={setActiveTab}
        tab={activeTab}
        filterHandler={filterHandler}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
      />
      <div className={classes.box_chart}>{displayActiveTab()}</div>
      {activeTab !== 2 && (
        <Filter
          isOpen={isOpenFilter}
          tab={activeTab}
          fn={filterHandler}
          setVars={setVars}
          vars={vars}
          setIsFiltered={setIsFiltered}
        />
      )}
    </>
  );
};

export default Dashboard;

const useStyles = makeStyles(theme => ({
  box_chart: {
    overflowY: "auto",
    height: "100%",
    transition: "0.5s",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  }
}));
