// fetch trip numbers correspond to the chart that has been clicked.
function fetchTripNumbers(toPath, values, bookingType, trips) {
  let tripNumbers = [];
  switch (bookingType) {
    case "On Time Truck Availability - Arrived":
      if (values.index === 0) {
        tripNumbers = toPath
          ? trips[0].late.prebooking.trip_numbers
          : trips[0].late.trips.trip_numbers;
      } else if (values.index === 1) {
        tripNumbers = toPath
          ? trips[0].onTime.prebooking.trip_numbers
          : trips[0].onTime.trips.trip_numbers;
      }
      break;
    case "Closed/Cancelled":
      if (values.index === 0) {
        tripNumbers = toPath
          ? trips[1].closed.prebooking.trip_numbers
          : trips[1].closed.trips.trip_numbers;
      } else if (values.index === 1) {
        tripNumbers = toPath
          ? trips[1].cancelled.prebooking.trip_numbers
          : trips[1].cancelled.trips.trip_numbers;
      }
      break;
    case "Land":
      if (values.index === 0) {
        tripNumbers = toPath
          ? trips[0].reSupply.prebooking.trip_numbers
          : trips[0].reSupply.trips.trip_numbers;
      } else if (values.index === 1) {
        tripNumbers = toPath
          ? trips[0].dcPickup.prebooking.trip_numbers
          : trips[0].dcPickup.trips.trip_numbers;
      } else if (values.index === 2) {
        tripNumbers = toPath
          ? trips[0].atRisk.prebooking.trip_numbers
          : trips[0].atRisk.trips.trip_numbers;
      }
      break;
    default:
      // default value.
      break;
  }

  return tripNumbers;
}

// redirects user to trips or bookings module with the trip number on dashboard module.
const redirectBookings = (
  history,
  toPath,
  values,
  bookingType,
  tripNumbers
) => {
  history.push({
    pathname: toPath ? "/prebooking" : "/trips",
    state: {
      params: {
        trip_numbers: fetchTripNumbers(toPath, values, bookingType, tripNumbers)
      }
    }
  });
};

export default redirectBookings;
