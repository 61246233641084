import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import Cookie from "js-cookie";
import { useMutation } from "@apollo/client";
import {
  GET_BOOKINGS_v2,
  getBookingViaVehicle
} from "../../../../api/bookingRepository";
import {
  MANUAL_COMPLETE,
  ADD_NOTIFICATION
} from "../../../../graphql/Mutations";
import { getLocations, getDistance } from "../../../Utils";
import { GET_NOTIFICATIONS_QUERY } from "../../../../graphql/Queries";
import closeTrip from "../functions/closeTrip";
import AddLogs from "../../../../utils/functions/AddLogs";
// import resetTrip from "../functions/resetTrip";
import {
  useJsApiLoader,
} from "@react-google-maps/api";
import useUserContext from "../../../../context/User/useUserContext";
import Loading from "../../../../utils/Loading";

const MySwal = withReactContent(swal);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  }
}));

const libraries = ["geometry", "drawing", "places"];

const DispatchDialog = props => {
  const classes = useStyles();
  const { toggle, tripData, queryFilter, moduleName } = props;
  const [trackingInfo, setTrackingInfo] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();

  const handleClose = () => {
    props.closeDispatchModal();
  };

  const [dispatchMutation] = useMutation(MANUAL_COMPLETE, {
    onCompleted: async res => {
      if (res?.update_booking_many?.every(element => element.success)) {
        await addNotif({
          variables: {
            notification: {
              client_id: [tripData?.rawTripData?.value?.[0]?.client_id],
              trip_number: trackingInfo?.trip_number || "",
              vehicle_plate_no: trackingInfo?.truck || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Dispatch the trip ${trackingInfo?.trip_number}, ${trackingInfo?.truck}`,
              category: "User Activity",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: tripData?.rawTripData?.value?.[0]?.group_ids || [],
              group_names: tripData?.rawTripData?.value?.[0]?.group_names || []
            }
          }
        });

        addNotif({
          variables: {
            notification: {
              client_id: [tripData?.rawTripData?.value?.[0]?.client_id],
              trip_number: trackingInfo?.trip_number || "",
              vehicle_plate_no: trackingInfo?.truck || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Trip ${trackingInfo?.trip_number} is currently incoming`,
              category: "Trip",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: tripData?.rawTripData?.value?.[0]?.group_ids || [],
              group_names: tripData?.rawTripData?.value?.[0]?.group_names || []
            }
          }
        });

        AddLogs(
          moduleName,
          "dispatch_trip",
          `${trackingInfo?.trip_number}, ${trackingInfo?.truck}`
        );
        setLoading(false);
        handleClose();
        MySwal.fire({
          icon: "success",
          text: "The trip was successfully dispatched",
          timer: 3000,
          showConfirmButton: false
        });
      } else {
        MySwal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 3000,
          showConfirmButton: false
        }); 
        setLoading(false);
        handleClose();
      };
    },
    onError: () => {
      setLoading(false);
      handleClose();
      MySwal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 3000,
        showConfirmButton: false
      }); 
    },
    refetchQueries: () => {
      return [{ query: GET_BOOKINGS_v2, variables: queryFilter }];
    }
  });

  const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [resetTripMutation] = useMutation(MANUAL_COMPLETE);

  useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    channel: `tmsuite-portal-${user.client.name}`,
    version: "3.47",
    libraries
  });

  const parsePickups = async () => {
    if (!_.isEmpty(tripData.rawTripData)) {
      setLoading(true);
      const { rawTripData } = tripData;
      const {
        status_code: trip_status,
        vehicle_status,
        vehicle_id
      } = rawTripData.value[0];
      const { trip_number } = trackingInfo;
      const pus = [];
      const dos = [];

      _.forEach(tripData.rawTripData.value, so => {
        _.forEach(so.pickups, pu => {
          pus.push({
            ...pu,
            trip_id: so.id,
            ordinal: so.ordinal,
            dispatch_gps_status: so.vehicle_status,
            vehicle_id: so.vehicle_id,
            status_code_id: 2,
            status_code: "Scheduled"
          });
        });
        _.forEach(so.dropoffs, d => {
          dos.push({
            ...d,
            trip_id: so.id,
            status_code_id: 2,
            status_code: "Scheduled"
          });
        });
      });

      const { get_device, get_geofence } = await getLocations(
        pus[0].vehicle_id,
        pus[0].geofence_id
      );
      const origins = [
        `${get_device.device_status?.latitude},${get_device.device_status?.longitude}`
      ];
      const destinations = [
        `${get_geofence.location.lat},${get_geofence.location.lon}`
      ];
      const service = new window.google.maps.DistanceMatrixService();
      const eta =
        vehicle_status === "Active" && get_geofence?.id !== "0"
          ? await getDistance(service, origins, destinations)
          : {};
      const etaObj = !_.isEmpty(eta)
        ? {
            eta: eta.rows[0].elements[0].duration
              ? eta.rows[0].elements[0].duration.value.toString()
              : "",
            actual_eta: eta.rows[0].elements[0].duration
              ? eta.rows[0].elements[0].duration.value.toString()
              : "",
            eta_modified: moment().format("YYYY-MM-DD HH:mm:ss")
          }
        : { eta: null, actual_eta: null, eta_modified: null };
      const for_saving = [];
      _.forEach(pus, pu => {
        const gps_status = pu.dispatch_gps_status;
        const p =
          pus[0].geofence_id === pu.geofence_id
            ? { ...pu, ...etaObj }
            : { ...pu };
        _.unset(p, "vehicle_id");
        _.unset(p, "trip_id");
        _.unset(p, "ordinal");
        _.unset(p, "dispatch_gps_status");
        const dropoff_index = _.findIndex(dos, d => {
          return d.trip_id === pu.trip_id;
        });
        const dr = { ...dos[dropoff_index] };
        _.unset(dr, "trip_id");
        for_saving.push({
          id: pu.trip_id,
          fields: {
            pickups: p,
            status_code: "Incoming",
            status_code_id: 27,
            dropoffs: dr,
            dispatch_time: moment().format("YYYY-MM-DD HH:mm:ss"),
            dispatch_type: "Manual", // Manual or Auto
            dispatch_gps_status: gps_status, // Dispatch GPS Status
            dispatcher: user.username // Username
          }
        });
      });

      const bookingViaVehicle = await getBookingViaVehicle(
        vehicle_id,
        trip_number
      );

      // assign the value from variable bookingViaVehicle to a new variable
      const { rawTripData: dataViaVehicle } = bookingViaVehicle;
      if (dataViaVehicle.length !== 0) {
        const existingTrip = bookingViaVehicle;
        closeTrip(existingTrip, resetTripMutation, addNotif);
        // resetTrip(existingTrip, resetTripMutation, addNotif);
      }

      // check if trip_status is equal to accepted
      if (trip_status === "Accepted") {
        _.forEach(dos, d => {
          addNotif({
            variables: {
              notification: {
                client_id: [tripData.rawTripData.value[0].client_id],
                trip_number: trackingInfo.trip_number,
                vehicle_plate_no: trackingInfo.truck,
                datestamp: moment()
                  .format("YYYY-MM-DD HH:mm:ss"),
                notification: `Good day! Please be informed that your order ${d.so_number} is now being processed`,
                category: "Trip",
                is_archived: false,
                do_number: d.so_number,
                geofence_id: [d.geofence_id],
                creator: "Tmsuite-web",
                group_ids: tripData.rawTripData.value[0].group_ids,
                group_names: tripData.rawTripData.value[0].group_names,
                booking_id: d.trip_id
              }
            }
          });
        });
      }
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/trackingInfo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(trackingInfo)
      });
      dispatchMutation({ variables: { bookings: for_saving } });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(tripData.rawTripData)) {
      setTrackingInfo({
        group_id: tripData.rawTripData.value[0].group_ids,
        trip_number: tripData.rawTripData.key,
        status_code_id: 27,
        status_code: "Incoming",
        truck: tripData.rawTripData.value[0].vehicle_plate_no,
        is_archived: false
      });
    }
  }, [tripData.rawTripData]);

  if (loading) return <Loading />;

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Dispatch?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to dispatch this trip?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.circularButton}
            variant="outlined"
            color="primary"
            onClick={handleClose}
            disabled={loading}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.circularButton}
            onClick={parsePickups}
            disabled={loading}
            // onClick={async () => {
            //   const res = await DISPATCH_BOOKING(booking, pickups);
            //   const r = await fetch(
            //     "https://staging-tmsuite-api.trackme.com.ph/trackingInfo",
            //     {
            //       method: "POST",
            //       headers: {
            //         "Content-Type": "application/json"
            //       },
            //       body: JSON.stringify(trackingInfo)
            //     }
            //   );

            //   if (res === "success") {
            //     handleClose();
            //     MySwal.fire({
            //       icon: "success",
            //       // title: <p>Hello World</p>,
            //       text: "The trip was successfully dispatched",
            //       timer: 3000,
            //       showConfirmButton: false,
            //       // onOpen: () => {
            //       //   // `MySwal` is a subclass of `Swal`
            //       //   //   with all the same instance & static methods
            //       //   // MySwal.clickConfirm();
            //       // }
            //       onAfterClose: () => {
            //         fn();
            //         // window.location.reload();
            //       }
            //     });
            //   }
            // }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DispatchDialog;
