/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Popper
} from "@material-ui/core";
import { KeyboardReturn, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/client";
import {
  ADD_CHECKLIST_HISTORY,
  EDIT_VEHICLE
} from "../../../graphql/Mutations";
import useUserContext from "../../../context/User/useUserContext";
import AddLogs from "../../../utils/functions/AddLogs";
import useHistoryDetailsStyles from "./HistoryDetails.styles";

const HistoryDetails = ({
  checklistInfo,
  showHistoryList,
  refetch,
  toResolve
}) => {
  const user_context = useUserContext();
  const classes = useHistoryDetailsStyles({
    toResolve
  });
  const [resolveItem, setResolveItem] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [initialState] = useState(_.cloneDeep(checklistInfo));
  const [editVehicle] = useMutation(EDIT_VEHICLE, {
    onCompleted: res => {
      if (!res.edit_vehicle.vehicle.is_for_repair) {
        AddLogs(
          "PMS - 360 Checklist",
          "checklist_resolved",
          `${res.edit_vehicle.vehicle.plateno} status changed to resolved`
        );
      }
      refetch();
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  });

  const getResolvedParts = () => {
    const getDefectiveParts = lists => {
      const tempArray = [];
      lists.forEach(list => {
        list.parts.forEach(part => {
          if (part.status.toLowerCase() === "defective") {
            tempArray.push(part.name);
          }
        });
      });
      return tempArray;
    };
    const initialDefectiveList = getDefectiveParts(initialState.checklists);
    const finalDefectiveList = getDefectiveParts(checklistInfo.checklists);
    return initialDefectiveList.filter(e => !finalDefectiveList.includes(e));
  };

  const [addChecklistHistory] = useMutation(ADD_CHECKLIST_HISTORY, {
    onCompleted: () => {
      const resolvedDefectiveList = getResolvedParts();

      let logString;
      if (resolvedDefectiveList.length > 1) {
        logString = `parts ${resolvedDefectiveList.toString()} were resolved`;
      } else {
        logString = `part ${resolvedDefectiveList.toString()} was resolved`;
      }
      AddLogs("PMS - 360 Checklist", "checklist_resolved", logString);

      editVehicle({
        variables: {
          id: checklistInfo.vehicle_id,
          vehicle: {
            last_repair_date: moment(new Date()).format("YYYY-MM-DD")
          }
        }
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  });

  const getPartStatus = partInfo => {
    const { status, category, index } = partInfo;
    const initialPartStatus = initialState?.checklists?.find(
      c => c.category_name === category
    )?.parts?.[index]?.status;
    let style = {};
    switch (status.toLowerCase()) {
      case "resolved":
        style = {
          color: "black"
        };
        break;
      case "not applicable":
        style = {
          color: "black"
        };
        break;
      case "defective":
        style = {
          color: "red",
          fontWeight: "bold"
        };
        break;
      default:
        style = {
          color: "black"
        };
        break;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        :
        <span style={{ ...style, margin: "0 10px 0 5px" }}>
          {_.startCase(status)}
        </span>
        {["defective", "resolved"].includes(status.toLowerCase()) &&
          initialPartStatus.toLowerCase() !== "resolved" &&
          toResolve && (
            <ExpandMore
              style={{ cursor: "pointer", height: "16px" }}
              fontSize="small"
              onClick={e => {
                if (resolveItem && resolveItem.id === partInfo.id) {
                  setResolveItem();
                  setAnchorEl();
                } else {
                  setResolveItem(partInfo);
                  setAnchorEl(e.currentTarget);
                }
              }}
            />
          )}
      </div>
    );
  };

  const getDetailedChecklistPerCategory = category => {
    const formatCategory = val =>
      val
        .split(" ")
        .join("")
        .toLowerCase();

    return (
      <Grid item>
        <Popper
          open={resolveItem}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
        >
          <Paper>
            <div
              style={{ padding: "5px 10px", cursor: "pointer" }}
              onClick={() => {
                const categoryIndex = _.findIndex(
                  checklistInfo.checklists,
                  e => {
                    return (
                      formatCategory(e.category_name) ===
                      formatCategory(resolveItem.category)
                    );
                  }
                );

                let newStatus = "";
                if (
                  checklistInfo.checklists[categoryIndex].parts[
                    +resolveItem.index
                  ].status.toLowerCase() === "defective"
                ) {
                  newStatus = "Resolved";
                } else {
                  newStatus = "Defective";
                }
                checklistInfo.checklists[categoryIndex].parts[
                  +resolveItem.index
                ].status = newStatus;
                setResolveItem();
                setAnchorEl();
              }}
            >
              {resolveItem?.status === "Resolved" ? (
                <Typography
                  className={classes.partLabel}
                  // style={{ color: "red" }}
                >
                  Defective
                </Typography>
              ) : (
                <Typography
                  className={classes.partLabel}
                  // style={{ color: "#63F160" }}
                >
                  Resolved
                </Typography>
              )}
            </div>
          </Paper>
        </Popper>
        <Typography className={classes.partCategory}>{category}</Typography>
        <Divider style={{ marginBottom: "1.5vh" }} />
        {checklistInfo.checklists
          ?.find(
            e => formatCategory(e.category_name) === formatCategory(category)
          )
          ?.parts?.map((part, index) => (
            <Grid container>
              <Grid item sm={6}>
                <Typography className={classes.partLabel}>
                  {part.name}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography className={classes.partLabel}>
                  {getPartStatus({
                    ...part,
                    index,
                    category,
                    id: `${category}-${index}`
                  })}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  const handleSubmit = () => {
    addChecklistHistory({
      variables: {
        datestamp: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        vehicle_id: checklistInfo.vehicle_id,
        vehicle_plate_no: checklistInfo.vehicle_plate_no,
        checklists: JSON.stringify(checklistInfo.checklists),
        // odometer_reading: checklistInfo.odometer_reading,
        user_id: +user_context.id,
        user_name: `${user_context.first_name} ${user_context.last_name}`
      }
    });
  };

  return (
    <Grid item sm={12}>
      <Paper className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.return}>
            <Button
              onClick={() => showHistoryList()}
              className={classes.buttonReturn}
              startIcon={
                <KeyboardReturn style={{ fontSize: "calc(0.5em + 1vh)" }} />
              }
            >
              Return
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <button className={classes.buttonDate} type="button">
              {moment(checklistInfo.datestamp).format("LL HH:mm:ss")}
            </button>
            <button className={classes.buttonDriver} type="button">
              {checklistInfo.driver_name || checklistInfo.user_name}
            </button>
          </div>
          <div /> {/** for styling (justifyContent: "space-between") */}
        </div>
        <div
          className={classes.partsContainer}
          style={!toResolve ? { height: "58vh" } : {}}
        >
          <Grid container spacing={4} justifyContent="space-around">
            <Grid item sm={4}>
              <Grid container direction="column">
                {getDetailedChecklistPerCategory("Lights")}
              </Grid>
            </Grid>

            <Grid item sm={4}>
              <Grid container direction="column" spacing={2}>
                {getDetailedChecklistPerCategory("Emergency Kit")}
                {getDetailedChecklistPerCategory("Glass and Wipers")}
              </Grid>
            </Grid>

            <Grid item sm={4}>
              <Grid container direction="column" spacing={2}>
                {getDetailedChecklistPerCategory("Tires")}
                {getDetailedChecklistPerCategory("Fluids")}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {toResolve && (
          <div className={classes.buttonContainer}>
            <button
              type="button"
              className={classes.buttonDone}
              onClick={handleSubmit}
              disabled={!getResolvedParts().length}
            >
              Done
            </button>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default HistoryDetails;
