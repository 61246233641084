import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "25px",
    minWidth: "25%",
    margin: "0 15px"
  }
}));
const ErrorDialog = props => {
  const { toggle, title, content, close } = props;
  const classes = useStyles();
  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Array.isArray(content) ? (
              content.map(x => (
                <Typography
                  variant="h6"
                  component="span"
                  style={{ display: "block" }}
                >
                  {x}
                </Typography>
              ))
            ) : (
              <Typography variant="h6" component="span">
                {content}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
