/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useQuery } from "@apollo/client";
import {
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  InputBase,
  Paper,
  IconButton
} from "@material-ui/core";
import {
  UnfoldMore as UnfoldMoreIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { GET_GROUPS } from "../../graphql/Queries";
import debounce from "../functions/debounce.util";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  },
  table: {
    marginTop: "16px",
    border: "solid #ebebeb"
  }
}));

const SelectGroup = props => {
  const classes = useStyles();
  const { toggle, closeGroupModal, handleGroupList, groupList } = props;
  const [groups, setGroups] = useState();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [keyword, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [render, setRender] = useState(false);

  const getVariables = () => {
    const variables = {
      // keyword: keyword,
      skip: 0,
      keyword: keyword,
      first: rowsPerPage,
      not: [
        {
          field: "id",
          value: groupList.map(g => {
            return g.id.toString();
          })
        }
      ]
    };

    return variables;
  };

  const { data: groupsData, fetchMore } = useQuery(GET_GROUPS, {
    variables: getVariables(),
    fetchPolicy: "network-only"
  });

  function handleClose() {
    // setOpen(false);
    setGroups(groupList);
    setSelectedGroups([])
    closeGroupModal();
  }

  useEffect(() => {
    setGroups(groupList);
    setRender(!render);
  }, [groupList]);

  const isChecked = id => {
    const checked =
      groups.map(g => g.id.toString()).indexOf(id.toString()) > -1;
    return checked;
  };

  const handleGroupSelect = (e, group) => {
    const { checked } = e.target;
    const temp = [...groups];

    if (checked) {
      temp.push({ id: +group.id, name: group.name });
    } else {
      const index = temp.map(g => g.id).indexOf(group.id);
      temp.splice(index, 1);
    }
    setGroups(temp);
    setSelectedGroups(temp);
    setRender(!render);
  };

  const onSelectAllClick = e => {
    const { get_groups } = groupsData;
    if (!get_groups?.groups) return;
    if (e.target.checked) {
      let temp = get_groups.groups.map(g => {
        return {
          id: g.id,
          name: g.name
        };
      });
      setSelectedGroups(temp);
      setGroups([...groupList, ...temp]);
      return;
    }
    setGroups([]);
    setSelectedGroups([]);

    // if (groups.length === groupsData.get_groups.groups.length) {
    //   temp = []
    // } else {
    //   groupsData.get_groups.groups.map(g => {
    //     return temp.push({ id: +g.id, name: g.name })
    //   })
    // }
    // setGroups(temp);
  };

  const handleUpPage = () => {
    setPage(page + 1);
    const nextPage = page + 1;
    if (nextPage * rowsPerPage === groupsData.get_groups?.groups?.length) {
      fetchMore({
        variables: {
          skip: groupsData.get_groups.groups.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            get_groups: {
              ...prev.get_groups,
              count: fetchMoreResult.get_groups.count,
              total: fetchMoreResult.get_groups.total,
              groups: [
                ...prev.get_groups.groups,
                ...fetchMoreResult.get_groups.groups
              ]
            }
          };
        }
      });
    }
  };

  const handleDownPage = () => {
    setPage(page - 1);
  };

  const inputRef = React.useRef();
  const keywordChanged = e => {
    e.persist();
    setSearch(inputRef.current.value);
  };

  return (
    <div>
      <Dialog
        open={!!toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add Entry</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Paper component="div" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search Client"
                inputProps={{ "aria-label": "plate #" }}
                inputRef={inputRef}
                onChange={debounce(keywordChanged, 250)}
              />
              <IconButton
                // type="submit"
                // onClick={() => setSearch(tempKeyword)}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <IconButton
              name="down_page"
              style={{ padding: 0 }}
              disabled={page === 0}
              onClick={() => handleDownPage()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography style={{ width: 50 }}>
              {`${
                groupsData?.get_groups?.total < 1 ? 0 : page + 1
              } / ${Math.ceil(groupsData?.get_groups?.total / rowsPerPage)}`}
            </Typography>

            <IconButton
              name="up_page"
              style={{ padding: 0 }}
              disabled={
                page + 1 ===
                Math.ceil(
                  groupsData?.get_groups?.total / rowsPerPage ||
                    groupsData?.get_groups?.total === 0
                )
              }
              onClick={() => handleUpPage()}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                      // checked={rowCount > 0 && numSelected === rowCount}
                      // onChange={onSelectAllClick}
                      onClick={e => onSelectAllClick(e)}
                      checked={
                        groupsData &&
                        groupsData.get_groups?.groups?.length === selectedGroups.length
                      }
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  <TableCell
                    padding="normal"
                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      // classes={{ icon: classes.icon }}
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : "asc"}
                      // onClick={createSortHandler(headCell.id)}
                      // onClick={() => handleSort(headCell.id)}
                      IconComponent={UnfoldMoreIcon}
                    >
                      Client
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupsData?.get_groups?.groups &&
                  groupsData?.get_groups?.groups
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((row, index) => {
                      // const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          // selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              // onClick={event => handleClick(event, row.id)}
                              // checked={isItemSelected}
                              onClick={e => handleGroupSelect(e, row)}
                              checked={isChecked(row.id)}
                              inputProps={{ "aria-labelledby": labelId }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                          >
                            {row.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            {groupsData?.get_groups?.total === 0 && (
              <div
                style={{
                  width: "100%",
                  height: 72,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>No data found</Typography>
              </div>
            )}
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            color="primary"
            className={classes.circularButton}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            className={classes.circularButton}
            // disabled={groups.length < 1}
            onClick={() => {
              handleGroupList(groups);
              setSelectedGroups([])
              handleClose();
            }}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectGroup;
