import React from "react";
import Cookie from "js-cookie";
import { LoadScript, useJsApiLoader } from "@react-google-maps/api";
import { createPortal } from "react-dom";
import { CircularProgress } from "@material-ui/core";
import InnerMap, { mapRef } from "./Inner";

const libraries = ["geometry", "drawing", "places"];

export const LoadingElement = () => {
  return (
    <div
      style={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <CircularProgress />
    </div>
  );
};
const Map = props => {
  const user = JSON.parse(Cookie.get("user") || "{}");

  useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
    version: "3.47",
    channel: `tmsuite-portal-${user?.client?.name || "webcast-public"}`
  });

  // return user?.client?.name ? (
  //   <LoadScript
  //     channel={`tmsuite-portal-${user.client.name}`}
  //     googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
  //     libraries={libraries}
  //     loadingElement={<LoadingElement />}
  //   >
  //     <InnerMap {...props} />
  //   </LoadScript>
  // ) : (
  return <InnerMap {...props} />;
  // );
};

export class Control extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.map = mapRef.current;
    this.controlContainer = document.createElement("div");
    this.map.controls[this.props.position].push(this.controlContainer);
  }

  render() {
    const { children } = this.props;
    return createPortal(children, this.controlContainer);
  }
}

Map.Control = Control;

export default Map;
