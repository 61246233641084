const data = [
  {
    id: "153",
    username: "User 1",
    first_name: "John",
    last_name: "Doe",
    email_addess: "sample@email.com",
    user_level_id: "5",
    user_level_name: "Data Admin",
    contact_number: "+639123456789",
    account_expiration: "2020-05-11 08:00:00",
    modified: "2022-09-07 16:33:20",
    geofence_id: null,
    geofence_name: null,
    client: {
      id: "30",
      name: "Webcast",
      is_restricted: null
    },
    is_blocked: false,
    group_names: ["Webcast"]
  },
  {
    id: "712",
    username: "User 2",
    first_name: "Jane",
    last_name: "Doe",
    email_addess: "sample@email.com",
    user_level_id: "1",
    user_level_name: "WTI Admin",
    contact_number: "+639123456789",
    account_expiration: "2023-09-08 08:00:00",
    modified: "2022-10-12 10:13:45",
    geofence_id: "370",
    geofence_name: "Puregold Price Club -Buhay na Tubig",
    client: {
      id: "30",
      name: "Webcast",
      is_restricted: null
    },
    is_blocked: false,
    group_names: [
      "Nestle",
      "Webcast",
      "Puregold Price Club -Buhay na Tubig",
      "HAVI"
    ]
  },
  // {
  //   id: "679",
  //   username: "appletest-storeapp",
  //   first_name: "Apple",
  //   last_name: "Test",
  //   email_address: "ric.monterozo@gmail.com",
  //   user_level_id: "37",
  //   user_level_name: "store app",
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-08-31 08:00:00",
  //   modified: "2022-07-28 01:03:01",
  //   geofence_id: "sGtLw34BgzVLn5ftt_eo",
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Nestle", "HAVI", "Webcast", "geotest01"]
  // },
  // {
  //   id: "738",
  //   username: "asd001",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd001@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-09-08 10:31:03",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "739",
  //   username: "asd002",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd002@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-09-08 10:35:00",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "740",
  //   username: "asd003",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd003@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-09-08 11:35:55",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "741",
  //   username: "asd004",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd004@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-09-16 10:20:54",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "723",
  //   username: "asd1",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd1@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-08-10 17:30:13",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "724",
  //   username: "asd2",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd2@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2023-06-01 08:00:00",
  //   modified: "2022-08-10 17:37:21",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // },
  // {
  //   id: "725",
  //   username: "asd3",
  //   first_name: "asd",
  //   last_name: "asd",
  //   email_address: "asd3@asd.com",
  //   user_level_id: null,
  //   user_level_name: null,
  //   contact_number: "+639123456789",
  //   account_expiration: "2022-09-09 17:44:11",
  //   modified: "2022-09-08 10:23:42",
  //   geofence_id: null,
  //   geofence_name: null,
  //   client: {
  //     id: "30",
  //     name: "Webcast",
  //     is_restricted: null
  //   },
  //   is_blocked: false,
  //   group_names: ["Webcast"]
  // }
];

export default data;