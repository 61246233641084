import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Collapse,
  styled,
  IconButton
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import useStyles from "./styles";
import { ActivityLogo, AlertLogo } from "./Stops/Icons";
import { activityTypes, getGeofenceTypeColor } from "../../Utils";
import DoubleArrowRoundedIcon from "@material-ui/icons/DoubleArrowRounded";

export const legends = [
  {
    label: "Stops"
  },
  {
    label: "Homebase"
  },
  {
    label: "Pickup/Dropoff"
  },
  {
    label: "Dropoff"
  },
  {
    label: "Pickup"
  },
  {
    label: "Port"
  },
  {
    label: "Gas Station"
  },
  {
    label: "Container Yard"
  },
  {
    label: "Route"
  },
  {
    label: "Hot spot"
  },
  {
    label: "Stop point"
  },
  {
    label: "Crossdock Hub"
  },
  {
    label: "Others"
  }
];

const activityLegends = [
  {
    label: "Activities",
    color: null
  },
  {
    label: "Alerts",
    color: activityTypes.find(e => e.type === "Alert").color
  },
  {
    label: "Notifications",
    color: activityTypes.find(e => e.type === "Notification").color
  },
  {
    label: "Activity Logs",
    color: activityTypes.find(e => e.type === "Activity Log").color
  }
];

export const GeofenceLegend = ({ alignLeft }) => {
  const styles = useStyles();
  return (
    <>
      {legends.map((legend, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={index} variant="caption">
          <Grid container alignItems="center" justifyContent="center">
            {legend.label !== "Stops" && (
              <Box
                borderRadius={50}
                style={{
                  background: getGeofenceTypeColor(legend.label),
                  marginLeft: 15
                }}
                className={styles.legend}
              />
            )}
            {legend.label === "Stops" ? (
              <b style={{ marginLeft: alignLeft ? 0 : 15 }}>{legend.label}: </b>
            ) : (
              legend.label
            )}
          </Grid>
        </Typography>
      ))}
    </>
  );
};

const ItineraryFooter = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const styles = useStyles();
  return (
    <Box style={{ position: "relative", bottom: 0 }}>
      <StyledBtnDivider>
        <IconButton
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{
            position: "absolute",
            top: "-25px",
            left: "50%",
            boxShadow: "0px 3px 8px 0px rgba(207,207,207,1)",
            zIndex: 2,
            backgroundColor: "#FFF"
          }}
        >
          <DoubleArrowRoundedIcon
            style={{ rotate: isCollapsed ? "270deg" : "-270deg" }}
          />
        </IconButton>
      </StyledBtnDivider>

      <Collapse in={!isCollapsed} collapsedSize={30} style={{ backgroundColor: "#FFF" }}>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <div style={{ width: "100%", padding: 8 }}>
              <Box display="flex" flexWrap="wrap" pb={1} m={1}>
                <GeofenceLegend />
              </Box>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ width: "100%", paddingBottom: "20px" }}>
              <Box display="flex" flexWrap="wrap" p={1} m={1}>
                {activityLegends.map((activity, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={index} variant="caption">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginLeft: "15px" }}
                    >
                      <Typography
                        variant="subtitle2"
                        className={styles.activitylegend}
                      >
                        {activity.label === "Alerts" && (
                          <AlertLogo size="16" color={activity.color} />
                        )}
                        {activity.label === "Notifications" && (
                          <NotificationsIcon
                            style={{ color: activity.color, fontSize: "16" }}
                          />
                        )}
                        {activity.label === "Activity Logs" && (
                          <ActivityLogo size="16" color={activity.color} />
                        )}
                      </Typography>
                      {activity.label === "Activities" ? (
                        <b>{activity.label}: </b>
                      ) : (
                        activity.label
                      )}
                    </Grid>
                  </Typography>
                ))}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default ItineraryFooter;

const StyledBtnDivider = styled("div")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  textAlign: "center",
  position: "relative",
  zIndex: 1
}));
