/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  svgIcon: {
    width: "43px",
    height: "40px"
  },
  button: {
    width: "40px",
    height: "40px",
    padding: 0,
    margin: theme.spacing(1)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  input: {
    fontSize: "14px",
    padding: "4px 8px"
  },
  radiusInput: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: 8,
      fontSize: 12
    }
  }
}));
