/* global google */
// New Coordinate Parser to handle multipolygon
import wkt from "wkt";
import _ from "lodash";

export const wktToGeojson = wktGeom => {
  // Input: Reversed Wkt (WTI format)
  // Output: Reverse Geojson (type: multipolygon)
  return wkt.parse(wktGeom)
};

export const wktToCoordinates = wktGeom => {
  // Input: Reversed Wkt (WTI format)
  // Output: Array of polygon coordinates
  const geojson = wktToGeojson(wktGeom);
  const { coordinates } = geojson;

  const array = coordinates.map(polygon => {
    return polygon[0].map(coords => new google.maps.LatLng(coords[0], coords[1]));
  })
  return array;
};

export const reverseLatLng = geojson => {
  // Input: standard geojson (type: multipolygon)
  // Output: Reversed geojson (type: multipolygon)
  const geomCopy = { ...geojson };
  geomCopy.coordinates = [
    geomCopy.coordinates.map(polygon => {
      return polygon[0].map(coords => [coords[1], coords[0]])
    })
  ];
  return geomCopy;
};

export const polygonToMultiPolygon = geojson => {
  // Converts polygon (drawing) to multipolygon
  // Input: standard geojson (type: polygon)
  // Output: standard geojson (type: multipolygon)
  const geomCopy = { ...geojson };
  geomCopy.type = "MultiPolygon";
  geomCopy.coordinates = [geomCopy.coordinates];
  return geomCopy;
};

export const geojsonToWkt = geojson => {
  // Input: standard geojson / reverse geojson (type: multipolygon)
  // Output: standard Wkt (WTI format) / reverse Wkt
  return wkt.stringify(geojson);
};

export const stdGeojsonToWkt = geojson => {
  // Input: standard geojson (type: multipolygon)
  // Output: Reversed Wkt (WTI format)
  const reverse = reverseLatLng(geojson);
  return geojsonToWkt(reverse);
};

export const isValidGeom = wkt => {
  // Input: Reversed Wkt (WTI format)
  
  // range for the multi polygon lat and lang for philippine area
  const multiPolLatHighstVal = 22.0;
  const multiPolLatLwstVal = 5.0;
  const multiPolLngHighstVal = 128.0;
  const multiPolLngLwstVal = 116.0;

  // range for acceptable vertices limit
  const minVertices = 4;
  const maxVertices = 2000;

  const multiPolygon = wktToGeojson(wkt);
  let totalVertices = 0;
  let validPolygon = true;
  let outOfRange = false;
  let isWithinVerticesLimit = true;

  if (
    !multiPolygon ||
    !_.isEqual(multiPolygon.type, "MultiPolygon") ||
    !multiPolygon?.coordinates?.length
  ) {
    validPolygon = false;
  } else {
    _.forEach(multiPolygon.coordinates, polygon => {
      if (validPolygon || !outOfRange || isWithinVerticesLimit) {
        totalVertices = totalVertices + polygon[0].length;
        const firstValue = polygon[0][0];
        const lastValue = _.last(polygon[0]);

        if (polygon[0].length < 4) {
          isWithinVerticesLimit = false;
        } else if (!_.isEqual(_.difference(firstValue, lastValue).length, 0)) {
          // check first and last coordinates per polygon
          validPolygon = false;
        } else {
          _.forEach(polygon[0], coordinates => {
            if (validPolygon || !outOfRange || isWithinVerticesLimit) {
              if (coordinates.length !== 2) {
                // check if coordinates are in pairs
                validPolygon = false;
              } else if (!_.isNumber(coordinates[0]) || !_.isNumber(coordinates[1]) ) {
                // check if each value is a number
                validPolygon = false;
              };
              if (
                !_.inRange(
                  coordinates[0],
                  multiPolLatLwstVal,
                  multiPolLatHighstVal
                ) ||
                !_.inRange(
                  coordinates[1],
                  multiPolLngLwstVal,
                  multiPolLngHighstVal
                )
              ) {
                // validate if latitude & longitude is within range.
                outOfRange = true;
              };
            } else {
              // break loop if polygon is not valid or out of range already
              return false;
            };
          });
        };
      } else {
        // break loop if polygon is not valid or out of range already
        return false;
      };
    });
  };

  if (totalVertices && !_.inRange(totalVertices, minVertices, maxVertices + 1)) {
    isWithinVerticesLimit = false;
  };

  return {
    totalVertices,
    validPolygon,
    outOfRange,
    isWithinVerticesLimit
  };
};

export const getBounds = wktGeom => {
  // Input: Reversed Wkt (WTI format)
  // Output: polygon bounds
  const bounds = new window.google.maps.LatLngBounds();
  const paths = wktToCoordinates(wktGeom);
  paths.forEach(path => {
    path.forEach(coor => bounds.extend(coor));
  });

  return bounds;
};


export const getCenter = wktGeom => {
  // Input: Reversed Wkt (WTI format)
  // Output: polygon center
  const polygonBounds = getBounds(wktGeom);

  return {
    lat: polygonBounds.getCenter().lat(),
    lon: polygonBounds.getCenter().lng()
  };
};