import moment from "moment";

const isWithinRange = (basis_date, date, isCICO = true) => {
  if (!date) return;
  const testing_date = moment(date);
  let start_date = moment(basis_date.start);
  let end_date = moment(basis_date.selectedRange || basis_date.end);

  if (!isCICO) {
    start_date = moment(start_date).subtract(8, "hours");
    end_date = moment(end_date).subtract(8, "hours");
  }

  return testing_date.isBetween(start_date, end_date, undefined, "[]");
};

export default isWithinRange;
