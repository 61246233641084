import React, { useState, useEffect } from "react";
import {
  Chip,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  makeStyles,
  Box,
  Typography,
  NativeSelect,
  withStyles,
  InputBase,
  TextField,
  TextareaAutosize,
  Tooltip,
  IconButton
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import SpeedRoundedIcon from "@material-ui/icons/SpeedRounded";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import _ from "lodash";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { EDIT_VEHICLE } from "../../graphql/Mutations";
import { GET_VEHICLE_INFOS } from "../../graphql/Queries";
import validate from "../validation";
import AddLogs from "../functions/AddLogs";
/*
 *** this module serve as a modal for the mechanic.
 *** currently this is use on vehicle information and vehicle issues.
 *** note: if the values on table is not updating possible is that you forgot to include the vars for the refetch query.
 */
// constant values;
const ERROR_MSGS = [
  `This field is required.`,
  `This field only accepts numeric values.`,
  `This field requires a minimum of 1 month.`,
  `Minimum of 1 km.`,
  `Maximum of 999,999 km.`,
  `Maximum of 24 months.`
];
const CUSTOM_OPT = "Custom";
const KILOMETER_OPTS = [
  "Select kilometer",
  "5,000",
  "10,000",
  "15,000",
  "Custom"
];
const MONTHS_OPTS = ["Select months", "6", "12", "24", "Custom"];
const ODOM_BASIS_OPTS = [
  "Select odometer basis",
  "GPS Reading (Auto)",
  "Custom "
];
const INITIAL_VEHICLE_INFO = {
  last_maintenance_date: "",
  odom_basis: "",
  odom_reading: "",
  kilometers: "",
  months: "",
  maintenance_remarks: ""
};
const LABELS_OPT = ["km", "months"];
const INITIAL_CUSTOM_VAL = {
  kilometers: false,
  months: false,
  odom_basis: false
};
const FIELD_NAMES = [
  {
    id: "kilometers-field",
    name: "kilometers",
    placeholder: "Enter kilometers",
    label: "Per Kilometer"
  },
  {
    id: "months-field",
    name: "months",
    placeholder: "Enter months",
    label: "Per Months"
  },
  {
    id: "odom-reading-field",
    name: "odom_reading",
    placeholder: "Enter odometer reading",
    label: "Odometer Reading (km)"
  },
  {
    id: "odom-basis-field",
    name: "odom_basis",
    placeholder: "Select odometer basis",
    label: "Odometer Basis"
  }
];
const INITIAL_FIELD_ERRORS = [
  { field: "kilometers", msg: "", triggered: false },
  { field: "months", msg: "", triggered: false },
  { field: "odom_reading", msg: "", triggered: false },
  { field: "odom_basis", msg: "", triggered: false }
];

const MAX_KM = 999999;
const MIN_KM = 1;

const MechanicModal = props => {
  const {
    toggle,
    title,
    content,
    fn,
    close,
    vehicle,
    type,
    vars,
    defaultLabel,
    defaultContent,
    resetDropdownStatus,
    refetchVehicle
  } = props;
  const gpsOdomReading = Math.ceil(Number(
    vehicle?.device_info?.device_status?.distancetraveled / 1000
  )).toLocaleString("en-US");
  const forMaintenance = vehicle.is_for_maintenance;
  const forRepair = vehicle.is_for_repair;
  const forMaintenanceRepair =
    _.isEqual(+forMaintenance, 1) && _.isEqual(+forRepair, 1);
  const [maintenance, setMaintenance] = useState(false);

  const classes = useStyles();
  // states.
  const [vehicleInfoModalVal, setVehicleInfoModalVal] = useState(
    INITIAL_VEHICLE_INFO
  );
  const [customOpt, setCustomOpt] = useState(INITIAL_CUSTOM_VAL);
  const [hasError, setHasError] = useState(INITIAL_FIELD_ERRORS);
  const [remarksOpen, setRemarksOpen] = useState(false);
  // mutation
  // update vehicle information.
  const [editVehicleMutation] = useMutation(EDIT_VEHICLE, {
    refetchQueries: [
      {
        query: GET_VEHICLE_INFOS,
        variables: vars,
        awaitRefetchQueries: true
      }
    ],
    onCompleted: data => {
      refetchVehicle(vars);
      Swal.fire({
        icon: "success",
        text: "Saved",
        timer: 1500,
        showConfirmButton: false
      });
    },
    onError: err => {
      console.log(err);
      Swal.fire({
        icon: "Error",
        text: "Something went wrong.",
        timer: 1500,
        showConfirmButton: false
      });
    }
  });
  // set remarks function.
  const remarkHandler = () => setRemarksOpen(!remarksOpen);
  // reset values
  function resetValuesHandler() {
    setVehicleInfoModalVal(INITIAL_VEHICLE_INFO);
    setCustomOpt(INITIAL_CUSTOM_VAL);
    setHasError(INITIAL_FIELD_ERRORS);
    setRemarksOpen(false);
  }
  // reset odometer reading error value.
  function resetOdomReadingErr() {
    const errorTemp = hasError.filter(err => err.field !== "odom_reading");
    errorTemp.push({ field: "odom_reading", message: "", triggered: false });
    setHasError(errorTemp);
  }
  // close function.
  function handleClose() {
    close();
    resetValuesHandler();
  }
  // validate if the selected option is custom.
  function validateIfCustomOpt(value, name) {
    let isCustom = false;
    // check if custom option is selected.
    if (_.isEqual(value, CUSTOM_OPT)) isCustom = true;
    else isCustom = false;
    setVehicleInfoModalVal(prevState => ({ ...prevState, [name]: "" }));
    setCustomOpt(prevState => ({ ...prevState, [name]: isCustom }));
    return isCustom;
  }
  // validate if first option is selected.
  function firstOptionSelected(value) {
    let isFirstOpt = false;
    const isFound = value.search("Select");
    isFirstOpt = !_.isEqual(isFound, -1);
    return isFirstOpt;
  }
  // validate input on custom option.
  function validateCustomOptionValue(name, value) {
    // filter out the error that is not equals to the field name.
    const errorTemp = hasError.filter(err => !_.isEqual(err.field, name));
    let customValue = "";
    if (!value) {
      errorTemp.push({ field: name, message: ERROR_MSGS[0], triggered: true });
    } else {
      switch (name) {
        case "kilometers":
          if (validate("number", value)) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[1],
              triggered: true
            });
          } else if (value < MIN_KM) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[3],
              triggered: true
            });
          } else if (value > MAX_KM) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[4],
              triggered: true
            });
          } else {
            customValue = `${Number(value).toLocaleString("en-US")}`;
          }
          break;
        case "months":
          if (validate("number", value)) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[1],
              triggered: true
            });
          } else if (value <= 0) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[2],
              triggered: true
            });
          } else if (value > 24) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[5],
              triggered: true
            });
          } else {
            customValue = `${value}`;
          }
          break;
        case "odom_reading":
          if (validate("number", value)) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[1],
              triggered: true
            });
          } else if (value < MIN_KM) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[3],
              triggered: true
            });
          } else if (value > MAX_KM) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[4],
              triggered: true
            });
          } else {
            customValue = `${Number(value).toLocaleString("en-US")}`;
          }
          break;
        case "odom_basis":
          if (validate("number", value) && customOpt.odom_basis) {
            errorTemp.push({
              field: name,
              message: ERROR_MSGS[1],
              triggered: true
            });
          } else {
            customValue = `${Number(value).toLocaleString("en-US")}`;
          }
          break;
        default: // do nothing
      }

      errorTemp.push({ field: name, message: "", triggered: false });
    }

    return { errorTemp, customValue };
  }
  // on change text field handler function.
  function onChangeHandler(event) {
    const { name, value } = event.target;
    setVehicleInfoModalVal(prevState => {
      return { ...prevState, [name]: value };
    });
  }
  // on change handler function.
  function onChangeOptionHandler(event) {
    const { value, name } = event.target;
    // end process.
    if (validateIfCustomOpt(value, name) || firstOptionSelected(value)) return;
    setVehicleInfoModalVal(prevState => {
      if (_.isEqual(name, "odom_basis") && !_.isEqual(value.trim(), "Custom")) {
        resetOdomReadingErr();
        return {
          ...prevState,
          [name]: value,
          odom_reading: _.isEqual(gpsOdomReading, "NaN") ? "0" : gpsOdomReading
        };
      }
      return { ...prevState, [name]: value };
    });
  }
  // on blur handler function
  function onBlurHandler(event) {
    const { name, value } = event.target;
    const { errorTemp, customValue } = validateCustomOptionValue(name, value);
    // set value after user has clicked away on the field.
    setVehicleInfoModalVal(prevState => {
      return { ...prevState, [name]: customValue };
    });
    setHasError(errorTemp);
  }
  // convert options.
  function convertOptions(options, type, name) {
    return options.map((option, index) => (
      <option
        value={option}
        key={index}
        style={{ color: !index ? "#bebebe" : "#3e3e3e" }}
      >
        {option}
      </option>
    ));
  }
  // function fetch error message.
  function errorMsgGetter(fieldName) {
    const errors = hasError.filter(error => _.isEqual(error.field, fieldName));
    const msg = errors[0].message || "";
    const onBlurTriggered = errors[0].triggered || false;

    return { msg, onBlurTriggered };
  }
  // save function & resolve vehicle function.
  function saveHandler() {
    const {
      last_maintenance_date,
      odom_reading,
      kilometers,
      months,
      maintenance_remarks
    } = vehicleInfoModalVal;
    // remove this character on the km "," before storing.
    const odomReading = odom_reading ? odom_reading.replaceAll(",", "") : "";
    const maintenanceOdom = kilometers ? kilometers.replaceAll(",", "") : "";
    let variables = {};
    if (_.isEqual(type, "Vehicle Information")) {
      variables = {
        id: vehicle.id,
        vehicle: {
          ...vehicle,
          last_maintenance_date: last_maintenance_date
            ? `${last_maintenance_date} 00:00:00`
            : "",
          last_maintenance_km: +Math.ceil(odomReading),
          maintenance_months: +Math.ceil(months),
          maintenance_km: +Math.ceil(maintenanceOdom)
        }
      };
      AddLogs(
        `PMS - ${process.env.REACT_APP_VEHICLE_INFO}`,
        "update",
        vehicle.plateno
      );
    } else if (_.isEqual(type, "Vehicle Issues")) {
      variables = {
        id: vehicle.id,
        vehicle: {
          ...vehicle,
          maintenance_remarks: maintenance_remarks,
          is_for_maintenance: 0,
          // is_for_repair: 0,
          last_maintenance_date: moment(new Date()).format("YYYY-MM-DD")
        }
      };
      AddLogs(
        `PMS - ${process.env.REACT_APP_VEHICLE_ISSUES}`,
        "update",
        `Vehicle ${vehicle.plateno} status changed to resolved`
      );
    }
    // remove not needed on saving edited edit vehicle.
    const { vehicle: vehicleTemp } = variables;
    _.unset(vehicleTemp, ["id"]);
    _.unset(vehicleTemp, ["device_info"]);
    _.unset(vehicleTemp, ["status_code"]);
    _.unset(vehicleTemp, ["client_name"]);
    _.unset(vehicleTemp, ["status_code_id"]);
    _.unset(vehicleTemp, ["vehicle_type"]);
    _.unset(vehicleTemp, ["group_names"]);
    _.unset(vehicleTemp, ["trip_numbers"]);
    _.unset(vehicleTemp, ["personnel_name"]);

    editVehicleMutation({ variables: variables });

    if (_.isEqual(type, "Vehicle Issues")) {
      resetDropdownStatus();
    }
  }
  // disable save button is not met in this condition.
  function disableSaveHandler() {
    let isDisabled = true;
    const {
      last_maintenance_date,
      odom_basis,
      odom_reading,
      kilometers,
      months
    } = vehicleInfoModalVal;
    // checks if value of the info modal is empty.
    isDisabled =
      _.isEmpty(last_maintenance_date, "") ||
      _.isEmpty(odom_basis) ||
      _.isEmpty(odom_reading) ||
      _.isEmpty(kilometers) ||
      !months;

    return isDisabled;
  }
  // reusable component for dropdown and custom value.
  function dropDownCustomUI(
    initialValue,
    id,
    name,
    placeholder,
    isCustom,
    opts,
    type,
    label
  ) {
    // get the error of the specific field.
    const { msg, onBlurTriggered } = errorMsgGetter(name);

    return (
      <>
        <Typography className={classes.label_field}>
          {label}
          {!_.isEqual(name, "odom_basis") || customOpt.odom_basis
            ? `(${type})`
            : ""}
        </Typography>
        {isCustom && !_.isEqual(name, "odom_basis") ? (
          <TextField
            id={id}
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            value={vehicleInfoModalVal[name]}
            placeholder={placeholder}
            name={name}
            InputProps={{ classes: { input: classes.input_field } }}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            error={onBlurTriggered && !_.isEqual(msg, "")}
            helperText={msg}
          />
        ) : (
          <NativeSelect
            id={id}
            value={vehicleInfoModalVal[name]}
            onChange={onChangeOptionHandler}
            input={
              _.isEqual(initialValue, "") ? (
                <BootstrapInputPlaceHolder />
              ) : (
                <BootstrapInput />
              )
            }
            fullWidth
            IconComponent={ExpandMoreIcon}
            name={name}
          >
            {convertOptions(opts, type, name)}
          </NativeSelect>
        )}
      </>
    );
  }
  // vehicle information UI
  function VehicleInformationModalUI() {
    return (
      <>
        <Typography className={classes.label_field}>
          Last Maintenance Date
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className={classes.filterInput}
            id="date-from"
            name="date_from"
            format="MM/DD/YYYY"
            inputVariant="outlined"
            size="small"
            value={vehicleInfoModalVal.last_maintenance_date}
            onError={false}
            variant="outline"
            initialFocusedDate={moment().startOf("date")}
            fullWidth
            onChange={date => {
              setVehicleInfoModalVal(prevState => ({
                ...prevState,
                last_maintenance_date: moment(date).format("YYYY-MM-DD")
              }));
            }}
            InputProps={{
              classes: {
                input: classes.textStyle
              },
              style: { padding: 0 },
              disableUnderline: false
            }}
            showTodayButton
            todayLabel="NOW"
          />
        </MuiPickersUtilsProvider>
        {dropDownCustomUI(
          vehicleInfoModalVal.odom_basis,
          FIELD_NAMES[3].id,
          FIELD_NAMES[3].name,
          FIELD_NAMES[3].placeholder,
          customOpt.odom_basis,
          ODOM_BASIS_OPTS,
          LABELS_OPT[0],
          FIELD_NAMES[3].label
        )}
        <Typography className={classes.label_field}>
          {FIELD_NAMES[2].label}
        </Typography>
        <TextField
          id={FIELD_NAMES[2].id}
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          value={
            _.isEqual(vehicleInfoModalVal.odom_basis, "GPS Reading (Auto)")
              ? `${vehicleInfoModalVal.odom_reading} (GPS Generated Value)`
              : vehicleInfoModalVal.odom_reading
          }
          className={
            _.isEqual(vehicleInfoModalVal.odom_basis, "GPS Reading (Auto)")
              ? classes.textfield_disabled
              : ""
          }
          placeholder={FIELD_NAMES[2].placeholder}
          name={FIELD_NAMES[2].name}
          InputProps={{ classes: { input: classes.input_field } }}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          disabled={
            _.isEqual(vehicleInfoModalVal.odom_basis, "GPS Reading (Auto)") ||
            !vehicleInfoModalVal.odom_basis
          }
          error={
            errorMsgGetter(FIELD_NAMES[2].name).onBlurTriggered &&
            !_.isEqual(errorMsgGetter(FIELD_NAMES[2].name).msg, "")
          }
          helperText={errorMsgGetter(FIELD_NAMES[2].name).msg}
        />
        <Typography className={classes.title_field}>
          Maintenance Trigger
        </Typography>
        {dropDownCustomUI(
          vehicleInfoModalVal.kilometers,
          FIELD_NAMES[0].id,
          FIELD_NAMES[0].name,
          FIELD_NAMES[0].placeholder,
          customOpt.kilometers,
          KILOMETER_OPTS,
          LABELS_OPT[0],
          FIELD_NAMES[0].label
        )}
        {dropDownCustomUI(
          vehicleInfoModalVal.months,
          FIELD_NAMES[1].id,
          FIELD_NAMES[1].name,
          FIELD_NAMES[1].placeholder,
          customOpt.months,
          MONTHS_OPTS,
          LABELS_OPT[1],
          FIELD_NAMES[1].label
        )}
      </>
    );
  }
  // vehicle issues UI.
  function VehicleIssuesModalUI() {
    return (
      <div>
        {!forMaintenanceRepair || maintenance ? (
          <>
            <Typography className={classes.title_field}>
              Maintenance Trigger
            </Typography>
            <Box display="flex" alignItems="center" gridGap={10}>
              <div className={classes.details_maintenance_container}>
                <Box display="flex" gridGap={5} alignItems="center">
                  {vehicle.maintenance_km ? (
                    <>
                      <Typography className={classes.label_field_value}>
                        {Number(vehicle.maintenance_km).toLocaleString("en-US")}
                      </Typography>
                      <Typography className={classes.label_measurement_value}>
                        km
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.not_applicable_value}>
                      Not Applicable
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center" gridGap={10}>
                  <SpeedRoundedIcon className={classes.label_icon} />
                  <Typography className={classes.label_header_value}>
                    Odometer
                  </Typography>
                </Box>
              </div>
              <div className={classes.details_maintenance_container}>
                <Box display="flex" gridGap={5} alignItems="center">
                  {vehicle.maintenance_months ? (
                    <>
                      <Typography className={classes.label_field_value}>
                        {vehicle.maintenance_months}
                      </Typography>
                      <Typography className={classes.label_measurement_value}>
                        months
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.not_applicable_value}>
                      Not Applicable
                    </Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center" gridGap={10}>
                  <CalendarTodayRoundedIcon className={classes.label_icon} />
                  <Typography className={classes.label_header_value}>
                    No. of months
                  </Typography>
                </Box>
              </div>
            </Box>
            {!remarksOpen && !vehicle.maintenance_remarks ? (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  style={{
                    border: "1px dashed #d9d9d9",
                    minHeight: 150,
                    marginTop: 10,
                    borderRadius: 15
                  }}
                >
                  <Tooltip placement="right" title="Add Vehicle Remarks">
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={remarkHandler}
                    >
                      <PostAddRoundedIcon className={classes.add_remarks_btn} />
                    </IconButton>
                  </Tooltip>
                  <Typography className={classes.textStyle}>
                    Optional: Indicate the repairs done to the vehicle.
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center" gridGap={5}>
                  <Typography className={classes.label_field}>
                    Remarks
                  </Typography>
                </Box>
                <TextareaAutosize
                  minRows={3}
                  style={{
                    width: "100%",
                    height: 150,
                    resize: "none",
                    padding: "0.2rem"
                  }}
                  value={vehicleInfoModalVal.maintenance_remarks}
                  name="maintenance_remarks"
                  onChange={onChangeHandler}
                  placeholder="Enter vehicle remark"
                  className={classes.textArea_remarks}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Button
              onClick={event => setMaintenance(true)}
              color="primary"
              fullWidth
              autoFocus
              className={classes.maintenance_btn}
            >
              Maintenance
            </Button>
            <Button
              onClick={event => {
                // saveHandler();
                handleClose();
                AddLogs("PMS - 360 Checklist", "checklist_view", vehicle.plateno);
                window.open(`/pms/view?vehicle_id=${vehicle.id}`);
              }}
              color="primary"
              fullWidth
              autoFocus
              className={classes.maintenance_btn}
            >
              Repair
            </Button>
          </>
        )}
      </div>
    );
  }

  // useEffect
  // run only once the vehicle data has changed this will initialize and setup the vehicle modal info with the exisiting value.
  useEffect(() => {
    if (!vehicle) return;
    // set vehicle information modal to the selected vehicle.
    const {
      last_maintenance_date,
      maintenance_months,
      maintenance_km,
      device_info,
      last_maintenance_km,
      maintenance_remarks
    } = vehicle;
    const odomReading = device_info?.device_status?.distancetraveled;

    setVehicleInfoModalVal({
      last_maintenance_date: last_maintenance_date,
      odom_basis: last_maintenance_km,
      //   odom_reading: Number(last_maintenance_km).toLocaleString("en-US"),
      odom_reading: "",
      kilometers: Number(maintenance_km).toLocaleString("en-US"),
      months: maintenance_months,
      maintenance_remarks: maintenance_remarks
    });
  }, [vehicle]);

  return (
    <Dialog
      open={toggle}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.closeContainer }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <span className={classes.closeTitle}>
            {maintenance ? defaultLabel : title}
          </span>
          <Box display="flex" gridGap={10} alignItems="center">
            <Chip
              color="primary"
              label={vehicle.plateno}
              size="small"
              className={classes.vehicle_plate_no}
            />
          </Box>
        </Box>
        <Typography className={classes.closeDescription}>
          {maintenance ? defaultContent : content}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialog_content}>
          {_.isEqual(type, "Vehicle Information")
            ? VehicleInformationModalUI()
            : VehicleIssuesModalUI()}
        </Box>
      </DialogContent>
      <Box className={classes.action_container}>
        {_.isEqual(type, "Vehicle Information") ? (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              className={classes.circularButtonNo}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={event => {
                saveHandler();
                handleClose();
              }}
              color="primary"
              fullWidth
              autoFocus
              className={classes.circularButtonYes}
              disabled={disableSaveHandler()}
            >
              Save
            </Button>
          </>
        ) : (
          (!forMaintenanceRepair || maintenance) && (
            <Button
              variant="contained"
              onClick={event => {
                saveHandler();
                handleClose();
              }}
              color="primary"
              fullWidth
              autoFocus
              className={classes.circularButtonYes}
            >
              Resolve Vehicle
            </Button>
          )
        )}
      </Box>
    </Dialog>
  );
};

export default MechanicModal;

// styling for this module.
const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 429,
    padding: 13
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px"
  },
  vehicle_plate_no: {
    fontSize: 11,
    letterSpacing: 1,
    textTransform: "uppercase"
  },
  input_field: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    marginLeft: 5
  },
  label_field: {
    fontSize: 13,
    fontFamily: "Nunito",
    color: theme.palette.primary.dark,
    marginBottom: "0.25rem",
    fontWeight: "600",
    marginTop: "0.5rem"
  },
  title_field: {
    fontSize: 14,
    fontFamily: "Nunito",
    color: theme.palette.primary.main,
    marginBottom: "0.25rem",
    fontWeight: "800",
    marginTop: "0.5rem"
  },
  textStyle: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  filterInput: {
    "& .MuiAutocomplete-popupIndicator": {
      transform: "rotate(0) !important"
    },
    "& .MuiAutocomplete-inputRoot": {
      fontSize: 13,
      color: theme.palette.primary.dark
    }
  },
  action_container: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 24px",
    margin: "0.5rem 0"
  },
  maintenance_btn: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    transition: "0.3s",
    margin: "0.5rem 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF"
    }
  },
  disabled_btn: {
    borderRadius: "22px",
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    transition: "0.3s",
    margin: "0.5rem 0"
  },

  dialog_content: {
    maxHeight: 400,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "1rem",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  },
  label_field_value: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontWeight: "bold"
  },
  label_header_value: {
    fontSize: 12,
    color: "#bebebe",
    letterSpacing: 1,
    fontWeight: "bold"
  },
  label_measurement_value: {
    fontSize: 12,
    color: theme.palette.primary.dark,
    fontWeight: "bold"
  },
  not_applicable_value: {
    fontSize: 12,
    color: theme.palette.error.dark,
    fontWeight: "bold"
  },
  label_icon: {
    color: "#bebebe"
  },
  info_icon: {
    color: "#2D87E2",
    fontSize: 17
  },
  add_remarks_btn: {
    fontSize: 40
  },
  details_maintenance_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(217, 217, 217, 10%)",
    borderRadius: 10,
    gap: 10,
    padding: "1rem",
    margin: "0.5rem 0",
    width: "max-content"
  },
  textfield_disabled: {
    backgroundColor: "rgba(170,175,180,0.2)"
  }
}));

const BootstrapInputPlaceHolder = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#bebebe",
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "8px 12px",
    height: 15,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "8px 12px",
    height: 15,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);
