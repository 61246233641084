import React, { useState, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { AntSwitch, useStyles } from "../StandardGeofence.styles";
import validate from "../../../../../../utils/validation";
import { geofenceTypes } from "../../../../../Utils";
import { GET_REVERSE_GEOCODE } from "../../../../../../graphql/Queries";

let timeout = null;
const GeofenceDetails = props => {
  const classes = useStyles();
  const {
    state,
    setState,
    errors,
    setErrors,
    mapStateRef,
    editMode = false
  } = props;
  const [autoGenerate, setAutoGenerate] = useState(false);

  const [getAddress] = useLazyQuery(GET_REVERSE_GEOCODE, {
    onCompleted(response) {
      if (response.get_reverse_geocode) {
        const geoAddress = response.get_reverse_geocode
          ? Object.values(response.get_reverse_geocode)
              .join(" ")
              ?.toString()
              ?.trim()
          : "";
        setState(prev => ({
          ...prev,
          address: geoAddress,
          ...response.get_reverse_geocode
        }));
        if (geoAddress && errors.address) {
          setErrors(prev => ({ ...prev, address: "" }));
        }
      }
    }
  });

  useEffect(() => {
    if (state.location.lat && state.location.lon && editMode) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        getAddress({
          variables: {
            latitude: +state.location.lat,
            longitude: +state.location.lon
          }
        });
      }, 1000);
    }
  }, [state.location]);

  const handleChange = useCallback(event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      case "name":
      case "geofence_code":
      case "address":
        if (value?.toString()?.trim() === "") {
          error = "This field is required";
        } else if (!validate("alphanumeric", value)) {
          error = "Alphanumeric characters only";
        } else {
          error = "";
        }
        break;
      case "zoom":
        if (value === "") {
          error = "";
        } else if (!validate("numeric", value)) {
          error = "Numeric only";
        } else if (+value < 1 || +value > 22) {
          error = "Zoom level value min: 1, max: 22";
        }
        break;
      case "lat":
      case "lon":
        if (value === "") {
          error = "This field is required";
        } else if (validate("number", value)) {
          error = "Numeric only";
        }
        break;
      default:
        break;
    }

    setErrors(prev => ({ ...prev, [name]: error }));
    if (["lat", "lon"].includes(name)) {
      setState(prev => ({
        ...prev,
        location: {
          ...prev.location,
          [name]: +value
        }
      }));
    } else {
      setState(prev => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value
      }));
    }
  }, []);

  const handleSpeedLimit = event => {
    event.preventDefault();
    const value = event.target?.value;
    let customSpeedLimit = value;
    if (value >= 0 && value <= 200 && Number.isInteger(value)) {
      customSpeedLimit = value;
    } else if (+value < 0) {
      customSpeedLimit = 0;
    } else if (+value > 200){
      customSpeedLimit = 200;
    };

    setState(prev => ({
      ...prev,
      speedLimit: {
        ...prev.speedLimit,
        custom: customSpeedLimit
      }
    }));
  };

  const handleDate = (time, field) => {
    setState({
      ...state,
      [field]: time
    });
  };

  const handleToggle = () => {
    if (!autoGenerate) {
      setState({
        ...state,
        geofence_code: Math.random()
          .toString(36)
          .slice(-5)
      });
      setErrors({ ...errors, geofence_code: "" });
    } else {
      setState({ ...state, geofence_code: "" });
    }
    setAutoGenerate(prevAuto => !prevAuto);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        {errors.geofence && (
          <Alert severity="error" className={classes.alert}>
            {errors.geofence}
          </Alert>
        )}
        <TextField
          required
          name="name"
          label="Geofence"
          placeholder="Enter Geofence"
          value={state.name}
          onChange={handleChange}
          onBlur={handleChange}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: !editMode,
            readOnly: !editMode
          }}
          className={classes.field}
        />
        {errors.name && (
          <Alert severity="error" className={classes.alert}>
            {errors.name}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} xs={12} style={{ position: "relative" }}>
        {editMode && (
          <AntSwitch
            checked={autoGenerate}
            onChange={handleToggle}
            classes={{ root: classes.switch }}
          />
        )}
        <TextField
          required
          name="geofence_code"
          label="Geofence Code"
          placeholder="Enter Geofence Code"
          value={state.geofence_code}
          onChange={handleChange}
          onBlurCapture={() => {
            setState(prev => ({
              ...prev,
              geofence_code: prev.geofence_code?.toString()?.trim()
            }));
          }}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: !editMode,
            readOnly: !editMode
          }}
          className={classes.field}
        />
        {errors.geofence_code && (
          <Alert severity="error" className={classes.alert}>
            {errors.geofence_code}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} xs={12}>
        <Typography
          style={{ marginTop: "10px", marginBottom: "5px", fontSize: "12px" }}
        >
          Window Time
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              height: "35px"
            }}
          >
            <KeyboardTimePicker
              readOnly={!editMode}
              style={{ width: "40%" }}
              margin="normal"
              id="time-picker"
              ampm={false}
              value={state.window_time_start}
              error={false}
              helperText={null}
              onChange={e =>
                handleDate(
                  moment(e).format("YYYY-MM-DD HH:mm:ss"),
                  "window_time_start"
                )
              }
              inputProps={{
                readOnly: !editMode
              }}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
            <div style={{ margin: "8px" }}>
              <Typography> to </Typography>
            </div>
            <KeyboardTimePicker
              readOnly={!editMode}
              style={{ width: "40%" }}
              margin="normal"
              id="time-picker"
              ampm={false}
              value={state.window_time_end}
              error={false}
              helperText={null}
              onChange={e =>
                handleDate(
                  moment(e).format("YYYY-MM-DD HH:mm:ss"),
                  "window_time_end"
                )
              }
              inputProps={{
                readOnly: !editMode
              }}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={6} xs={12}>
        <FormControl className={classes.field}>
          <InputLabel shrink required style={{ color: "black" }}>
            Category
          </InputLabel>
          <Select
            value={state.category}
            onChange={handleChange}
            displayEmpty
            name="category"
            disabled={!editMode}
            disableUnderline={!editMode}
          >
            <MenuItem value="" disabled>
              <em style={{ color: "#8e8e8e" }}>Select Category</em>
            </MenuItem>
            {geofenceTypes.map(category => (
              <MenuItem key={category.label} value={category.label}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {errors.category && (
          <Alert severity="error" className={classes.alert}>
            {errors.category}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          required
          type="number"
          label="Latitude"
          name="lat"
          placeholder="Enter Latitude"
          value={state.location.lat}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: !editMode,
            readOnly: !editMode,
            style: { color: "#808080" }
          }}
          className={classes.numeric_field}
          onBlur={e => {
            if (mapStateRef.current?.geom) return;
            handleChange(e);
          }}
          onChange={e => {
            if (mapStateRef.current?.geom) return;
            handleChange(e);
          }}
        />
        {errors.lat && (
          <Alert severity="error" className={classes.alert}>
            {errors.lat}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          required
          type="number"
          label="Longitude"
          name="lon"
          placeholder="Enter Longitude"
          value={state.location.lon}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: !editMode,
            readOnly: !editMode,
            style: { color: "#808080" }
          }}
          className={classes.numeric_field}
          onBlur={e => {
            if (mapStateRef.current?.geom) return;
            handleChange(e);
          }}
          onChange={e => {
            if (mapStateRef.current?.geom) return;
            handleChange(e);
          }}
        />
        {errors.lon && (
          <Alert severity="error" className={classes.alert}>
            {errors.lon}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          name="zoom"
          type="number"
          min={0}
          className={classes.field}
          label="Zoom Level"
          placeholder="Zoom Level"
          value={state.zoom}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            inputProps: { min: 0 },
            disableUnderline: !editMode,
            readOnly: !editMode,
            style: { color: "#808080" }
          }}
          onChange={handleChange}
          onBlur={handleChange}
        />
        {errors.zoom && (
          <Alert severity="error" className={classes.alert}>
            {errors.zoom}
          </Alert>
        )}
      </Grid>
      <Grid item lg={4} xs={10}>
        <FormControl className={classes.custom_field}>
          <InputLabel shrink style={{ color: "black" }}>
            Speed Limit(kph)
          </InputLabel>
          <Select
            value={state.speedLimit.value === 0 ? "" : state.speedLimit.value}
            defaultValue=""
            disabled={!editMode}
            disableUnderline={!editMode}
            displayEmpty
            name="speedLimit"
            onChange={e => {
              setState(prev => ({
                ...prev,
                speedLimit: {
                  value: e.target?.value,
                  custom: 0
                }
              }));
            }}
          >
            <MenuItem value="" disabled>
              <em style={{ color: "#8e8e8e" }}>Select Speed Limit</em>
            </MenuItem>
            {[70, 80, 90, 100, "Custom"].map(limit => (
              <MenuItem key={limit} value={limit}>
                {limit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={2} xs={2}>
        {state.speedLimit.value === "Custom" && (
          <div className={classes.custom_limit}>
            <TextField
              name="custom"
              InputProps={{
                disableUnderline: !editMode,
                readOnly: !editMode
              }}
              inputProps={{ min: 0, max: 200 }}
              type="number"
              value={state.speedLimit.custom}
              onChange={e => {
                setState(prev => ({
                  ...prev,
                  speedLimit: {
                    ...prev.speedLimit,
                    custom: e.target?.value
                  }
                }));
              }}
              onBlur={handleSpeedLimit}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          name="address"
          label="Address"
          placeholder="Enter Bldg No., Street name"
          value={state.address}
          onChange={handleChange}
          onBlur={handleChange}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: !editMode,
            readOnly: !editMode
          }}
          className={classes.field}
        />
        {errors.address && (
          <Alert severity="error" className={classes.alert}>
            {errors.address}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Region"
          placeholder="--"
          value={state.region || "--"}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Province"
          placeholder="--"
          value={state.province || "--"}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Municipality/City"
          placeholder="--"
          value={state.municipality || "--"}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Barangay"
          placeholder="--"
          value={state.barangay || "--"}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />
      </Grid>
    </Grid>
  );
};

export default GeofenceDetails;
