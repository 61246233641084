import React, { useState, useEffect, useLayoutEffect } from "react";

/*
*** custom hook for fetching the position of scrollbar on a container. 
*/

const useScrollPosition = () => {
  const [scrollPosition, setPosition] = useState(0);
  const [containerElementId, setContainerElementId] = useState(null);

  useLayoutEffect(() => {
    if(!containerElementId) return;
    const element = document.getElementById(containerElementId)
    element.addEventListener("scroll", evt => setPosition(evt.target.scrollTop));
  }, [containerElementId]);

  return [scrollPosition, setContainerElementId]
};

export default useScrollPosition;
