import { gql } from "@apollo/client";

export const SAMPLE = "";

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription NotificationsSubscription(
    $filter: [DynamicSearchInput]
    $dateRange: DateRangeInput
  ) {
    notification_added(conditions: $filter, dateRange: $dateRange) {
      id
      notification
      trip_number
      is_urgent
      is_read
      is_archived
      client_id
      category
      vehicle_plate_no
      datestamp
      created
      alert_level
      trucker_id
      do_number
      location {
        lat
        lon
      }
      driver_name
      temperature
      speed
      user_level_id
      group_names
      vehicle_id
      geofence_id
      booking_id
      group_ids
      alert_code
    }
  }
`;

export const REPORT_REQUESTS_SUBSCRIPTION = gql`
  subscription report_subscribe($filter: [SearchInput]) {
    report_request_updated(filter: $filter) {
      id
      user_id
      user_name
      created
      report_type
      report_type_id
      startstamp
      endstamp
      group_ids
      status_code
      status_code_id
      filename
      is_downloaded
      remarks
      start_destination_id
      end_destination_id
    }
  }
`;

export const BOOKINGS_SUBSCRIPTION = gql`
  subscription booking_subscribe($filter: [SearchInput]) {
    booking_per_trip_updated(filter: $filter) {
      count
      total
      bookings {
        key
        value {
          id
          data_complete
          do_stamp
          do_number
          so_number
          trip_number
          out_of_route
          ref_number
          transport_mode
          transport_mode_id
          service_type
          service_type_id
          total_weight
          total_volume
          bill_to_id
          is_urgent
          bill_to_name
          client_name
          client_id
          group_names
          ordinal
          group_ids
          is_urgent
          is_split
          reason
          reason_id
          createdby {
            user_id
            username
            client_id
            client_name
          }
          notification_status
          dr_number
          remarks
          overdue
          vehicle_id
          vehicle_plate_no
          vehicle_status
          vehicle_type
          vehicle_type_id
          status_code
          status_code_id
          personnels {
            id
            name
          }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          items {
            pickup_index
            dropoff_index
            name
            qty
            dr_count
            volume
            weight
            uom_id
            uom
            sub_items {
              name
            }
            reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          pre_dispatched_ready
          dispatch_type
        }
      }
    }
  }
`;

export const VEHICLE_SUBSCRIPTION = gql`
  subscription vehicleLocation($filter: [SearchInput]) {
    device_updated(filter: $filter) {
      id
      name
      devicealias
      mobile_imei
      device_type
      vehicle_info {
        plateno
        icon
        powersource
        ignition_status
      }
      device_status {
        latitude
        longitude
        reportstamp
        heading
        status_code
        status_code_id
        temperature
        gpsSpeed
        lastlocation
        AlertReferences {
          alert_code
          alertmessage 
          level
        }
      }
      device_config {
        gps_provider
        gps_provider_id
        hastempsensor
      }
    }
  }
`;

export const ADMIN_CLIENTS_SUBSCRIPTION = gql`
  subscription clientUpdated($filter: [SearchInput], $not: [SearchInput]) {
    client_updated(filter: $filter, not: $not) {
      id
      name
      category
      access_end_date
      is_restricted
      client_preference {
        primary_color
      }
      alert_sounds {
        alert_level0_sound
        alert_level1_sound
        alert_level2_sound
        alert_level3_sound
      }
      group_ids
      group_names
    }
  }
`;

export const VIEW_CLIENT_USERS_SUBSCRIPTION = gql`
  subscription clientUserSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      user {
        id
        username
        first_name
        last_name
        email_address
        user_level_id
        contact_number
        account_expiration
        modified
        client {
          id
          name
        }
      }
    }
  }
`;

export const VIEW_CLIENT_VEHICLES_SUBSCRIPTION = gql`
  subscription clientVehiclesSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      vehicle {
        id
        device_id
        icon
        plateno
        device_info {
          devicealias
        }
        client_name
        vehicle_type
        vehicle_type_id
        client_name
        client_id
        group_names
        group_ids
        status_code
      }
    }
  }
`;
export const VIEW_CLIENT_DEVICES_SUBSCRIPTION = gql`
  subscription clientDevicesSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      device {
        id
        devicealias
        mobile_imei
        device_type
        client_id
        vehicle_info {
          plateno
        }
        device_status {
          latitude
          longitude
          reportstamp
          heading
          status_code
          status_code_id
        }
        device_config {
          gps_provider
          gps_provider_id
        }
      }
    }
  }
`;
export const VIEW_CLIENT_GEOFENCES_SUBSCRIPTION = gql`
  subscription clientGeofenceSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      geofence {
        id
        name
        address
        geofence_code
        location {
          lat
          lon
        }
        type
        category
        group_ids
        group_names
        window_time_end
        window_time_start
        radius
      }
    }
  }
`;

export const SIGNUP_CLIENT_USER = gql`
  mutation signup_client_user(
    $username: String!
    $password: String!
    $first_name: String!
    $last_name: String!
    $email_address: String!
    $contact_number: String!
  ) {
    signup_client_user(
      username: $username
      password: $password
      first_name: $first_name
      last_name: $last_name
      email_address: $email_address
      contact_number: $contact_number
    ) {
      success
      error {
        field
        message
      }
    }
  }
`;

export const VIEW_CLIENT_ALERT_SUBSCRIPTION = gql`
  subscription clientAlertSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      alert {
        id
        level
        alertmessage
        alert_description {
          id
          description
        }
        alert_type
        description
      }
    }
  }
`;

export const ALERT_REFERENCE_NOTIF_SUBSCRIPTION = gql`
  subscription clientAlertSubs($filter: [SearchInput], $not: [SearchInput]) {
    data_updated(filter: $filter, not: $not) {
      alert_reference_count {
        group_ids
        count
      }
      alert {
        id
        group_ids
        group_names
        device_type {
          id
          name
        }
        alert_type
        alertmessage
        level
        description
        code
      }
    }
  }
`;

export const GET_ACTIVE_SESSION = gql`
  subscription sessionUpdated($filters: [SearchInput]) {
    session_updated(filter: $filters) {
      id
      session_id
    }
  }
`;
