import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20
  },
  fabIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    height: 35,
    margin: 5,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    height: 35,
    margin: 5,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  typography_red: {
    fontSize: 12,
    color: "red"
  },
  icon: {
    opacity: 1
  },
  dialog: {
    borderRadius: "10px",
    width: 600
  },
  verified: {
    fontSize: 12,
    color: "#53a4f7",
    marginInlineEnd: 12
  },
  unverified: {
    fontSize: 12,
    color: "#c4c4c4",
    marginInlineEnd: 12
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  },
  statusValidating: {
    color: "#c4c4c4"
  },
  standardRoute: {
    "&.MuiPopover-paper": {
      overflow: "hidden",
      borderRadius: "10px",
      width: "calc(45% - 200px)"
    }
  },
  standardGeofence: {
    width: "55%",
    backgroundSize: "cover",
  },
  card: {
    display: "flex",
    width: "100%"
  },
  routeGeofence: {
    width: "59%",
    backgroundSize: "cover"
  },
  divCard: {
    display: "flex",
    flexDirection: "column"
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "10px"
    },
    "&.MuiCardContent-root:last-child": {
      padding: "10px"
    }
  },
  cloneTitle: {
    textAlign: "left",
    padding: "10px 0",
    fontWeight: "bold"
  },
  InputLabel: {
    color: "black"
  },
  field: {
    margin: "8px 0",
    width: "100%"
  },
  textFieldRoot: {
    "& input": {
      fontSize: "14px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "14px",
      padding: "5px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "14px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    }
  },
  tableContainer: {
    height: "50vh",
    border: "solid 3px #f2f2f2"
  },
  existingCloneContainer: {
    height: "30vh",
    border: "solid 3px #f2f2f2",
    marginTop: "18px"
  },
  existingCloneHead: {
    backgroundColor: "#f2f2f2"
  },
  tableHead: {
    backgroundColor: "#f2f2f2"
  },
  tableHead: {
    backgroundColor: "#f2f2f2"
  },
  clonePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#f2f2f2",
    border: "solid 3px #f2f2f2"
  },
  searchRoot: {
    display: "flex",
    alignItems: "center",
    width: 400,
    height: 30,
    // backgroundColor: "#f0f0f0",
    margin: "25px 0 0 24px"
  },
  closeSearch: {
    display: "flex",
    alignItems: "center",
    height: 30,
    // backgroundColor: "#f0f0f0",
    margin: "25px 0 5px 24px"
  },
  searchInput: {
    // marginLeft: "12px",
    flex: 1
  },
  my_swal: {
    zIndex: 10000
  },
  cloneDialogContainer: {
    "&.MuiDialog-paper": {
      width: "30%",
    }
  },
  btn_okay: {
    borderRadius: "17px",
    color: "#FFFFFF",
    height: 35,
    margin: 5,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  table_modal: {
    border: "1px solid gray",
    fontSize: "13px",
    marginTop: "15px"
  },
  tablebody_modal: {
    fontSize: "13px",
    overflow: "auto",
    maxHeight: "125px",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "orange",
      borderRadius: "20px"
    }
  },
  tablehead_modal: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "13px"
  }
}));
