import React from "react";
import { IconButton, Menu, FormControl } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import useSearchStyles from "./Search.styles";

const SearchMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles = useSearchStyles();

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={styles.seachMenu}>
        <IconButton onClick={handleMenuClick} size="small">
          <ExpandMore fontSize="medium" color="primary" />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        getContentAnchorEl={null}
      >
        <FormControl
          component="fieldset"
          style={{ padding: "0px 15px 0px 5px", outline: "none" }}
        >
          {children}
        </FormControl>
      </Menu>
    </React.Fragment>
  );
};

export default SearchMenu;
