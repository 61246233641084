import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Badge,
  useTheme,
  Link,
  IconButton
} from "@material-ui/core";
import _, { filter } from "lodash";
import {
  GetApp,
  FilterList,
  Edit,
  Publish as PublishIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../table/Pagination";
import CustomDropdown from "../Dropdown/CustomDropdown";
import useUserContext from "../../../context/User/useUserContext";
import ClientGroupAutocomplete from "../Dropdown/ClientGroupAutocomplete";
import moment from "moment";

const Header = props => {
  const theme = useTheme();
  const user_context = useUserContext();
  const history = useHistory();
  const {
    label,
    page,
    setPage,
    totalPage,
    hasDownload = false,
    hasFilter = false,
    hasTime = false,
    isFilterDate = false,
    hasUpload = false,
    toggleEditMode,
    // setFiltered,
    isFiltered,
    setSelectedData,
    selectedData = [],
    vars,
    refetchVehicle = () => {},
    setSelectedStatus,
    selectedStatus,
    statusOpts,
    deleteSelected,
    respondTimesheets,
    setFilterDrawer,
    children,
    getVariables = () => {},
    tempFilter,
    editPermission
  } = props;
  const [groupFilter, setGroupFilter] = useState({
    id: user_context.client.id,
    name: user_context.client.name,
    group_names: [user_context.client.name],
    group_ids: user_context.client.group_ids && [
      user_context?.client?.group_ids[
        _.findIndex(
          user_context?.client?.group_names,
          gn => gn === user_context?.client.name
        )
      ]
    ]
  });

  const selectedIDs = selectedData.map(i => i.id);

  // const [modalFilter, setModalFilter] = useState(false);
  const classes = useStyles();
  // filter modal handler.
  // const toggleModalFilter = () => setModalFilter(!modalFilter);
  // filter button handler applying filter to table.
  const filterHandler = () => {
    setFilterDrawer(true);
    // setFiltered(!filtered);
    setSelectedData([]);
  };
  const isWTIAdmin =
    user_context.user_level_id === process.env.REACT_APP_WTI_ADMIN;
  // filter status on custom dropdown in header component for mechanic.
  // this will only execute for vehicle issues filter status.

  return (
    <>
      <Box className={classes.header_container}>
        <Box display="flex" gridGap={30} alignItems="center">
          <Typography className={classes.label} variant="h6">
            {label}
          </Typography>
          {_.isEqual(label, "Vehicle Issues") && (
            <CustomDropdown
              options={statusOpts}
              setChoice={setSelectedStatus}
              choice={selectedStatus}
            />
          )}
          {_.isEqual(label, "360 Checklist") && isWTIAdmin && (
            <ClientGroupAutocomplete
              groupFilter={groupFilter}
              onChange={(e, nv) => {
                setGroupFilter(nv);
                refetchVehicle({
                  ...vars,
                  group_ids: [
                    nv.group_ids[
                      _.findIndex(nv.group_names, gn => gn === nv.name)
                    ]
                  ]
                });
              }}
            />
          )}
        </Box>
        <Box display="flex" gridGap={10} alignItems="center">
          {children}
          {_.isEqual(label, "360 Checklist") && (
            <Button
              variant="contained"
              startIcon={<Edit />}
              className={classes.btn_edit}
              onClick={() => {
                const group_id =
                  groupFilter.group_ids &&
                  groupFilter.group_ids[
                    _.findIndex(
                      groupFilter.group_names,
                      gn => gn === groupFilter.name
                    )
                  ];

                toggleEditMode({
                  ...groupFilter,
                  group_id: group_id
                });
              }}
            >
              Edit 360 Checklist
            </Button>
          )}
          {hasFilter && (
            <IconButton onClick={filterHandler} className={classes.btn_filter}>
              <Badge
                color="secondary"
                variant={
                  isFiltered ||
                  isFilterDate ||
                  getVariables()?.conditions?.length ||
                  getVariables()?.condition?.length ||
                  getVariables()?.dateFilter?.field ===
                    "driver_attendance.time_in"
                    ? "dot"
                    : "standard"
                }
              >
                <FilterList />
              </Badge>
            </IconButton>
          )}
          {hasTime && (
            <Typography className={classes.headerTime}>
              As of <span>{moment().format("MMMM DD YYYY hh:mm A")}</span>
            </Typography>
          )}
          <Pagination
            rowsPerPage={page.rows}
            total={totalPage}
            currentPage={page.currentPage}
            onRowsPerPageChange={n => {
              setPage(pages => ({ ...pages, rows: n }));
            }}
            onPageChange={n => setPage(pages => ({ ...pages, currentPage: n }))}
            onPageUp={n => setPage(pages => ({ ...pages, currentPage: n }))}
            onPageDown={n => setPage(pages => ({ ...pages, currentPage: n }))}
          />
        </Box>
      </Box>
      {selectedData.length > 0 && (
        <Box className={classes.headerInfo} padding={1}>
          <Typography className={classes.selected_timesheets}>
            <span className={classes.selected_count}>
              {`${selectedData.length} `}
            </span>
            timesheet/s are selected on this page.
          </Typography>
          <Box>
            <Button
              disabled={!editPermission}
              size="small"
              className={classes.headerBtn}
              variant="contained"
              color="primary"
              onClick={() => deleteSelected()}
            >
              Delete Selected
            </Button>
            <Button
              disabled={
                tempFilter.status === "all" ||
                tempFilter.status ===
                  process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE
              }
              size="small"
              className={classes.headerBtn}
              variant="contained"
              color="primary"
              onClick={e => {
                history.push({
                  pathname: `/admin/driver_management/for_review/${selectedIDs}`,
                  state: {
                    params: {
                      response_status_code_id: tempFilter?.status
                    }
                  }
                });
                respondTimesheets();
              }}
            >
              Respond Timesheets
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;

export const useStyles = makeStyles(theme => ({
  header_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    padding: "0.5rem 1rem"
  },
  headerInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  headerBtn: {
    borderRadius: "20px",
    margin: "0 10px 0 0",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: "600",
    letterSpacing: 1,
    whiteSpace: "nowrap"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    fontSize: 12,
    margin: "10px 5px",
    "&:hover": {
      backgroundColor: "#ff5723"
    },
    textTransform: "none"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    fontSize: 12,
    margin: "10px 5px",
    "&:hover": {
      backgroundColor: "#fe9700"
    },
    textTransform: "none"
  },
  btn_filter: {
    borderRadius: "25px",
    margin: "0 5px",
    fontSize: "12px",
    textTransform: "none"
  },
  btn_edit: {
    background: "#FFB74D",
    fontSize: "14px",
    color: "white",
    borderRadius: "30px",
    textTransform: "none",
    "&:hover": {
      background: "#FFB74D"
    }
  },
  selected_timesheets: {
    fontSize: 12,
    color: "rgba(63, 63, 63, 50%)",
    fontWeight: "bold"
  },
  selected_count: {
    color: theme.palette.primary.main
  },
  headerTime: {
    color: theme.palette.primary.dark,
    fontSize: 13
  }
}));
