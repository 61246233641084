import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  svg: {
    fill: theme.palette.primary.main
  },
  svgPrimaryDark: {
    fill: alpha(theme.palette.primary.main, 0.8)
  },
  lightFill: {
    fill: "white"
  },
  svgPrimaryStroke: {
    stroke: theme.palette.primary.main
  },
  contrastFill: {
    fill: "white"
  }
}));

export const SVGError = () => {
  const classes = useStyles();

  return (
    <svg
      width="587"
      height="334"
      viewBox="0 0 587 334"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.1016 119.444C50.67 113.7 61.6775 63.5597 94.2635 60.605C162.502 54.4177 272.584 68.1141 450.082 68.1141H542.585C592.735 68.1141 592.131 114.935 542.585 114.935H464.037C431.941 114.935 441.996 138.1 456.787 138.1C512.375 138.1 530.501 134.996 530.501 161.588C530.501 184.381 469.255 179.692 456.787 179.692C440.616 179.692 406 221.478 510.758 215.635C615.516 209.792 600.59 262.052 530.501 256.292C500.317 253.812 518.359 257.21 503.916 257.21C472.777 257.21 482.484 290.728 503.916 290.728C575.213 290.728 617.508 340.212 417.879 332.763C354.624 334.592 217.063 329.106 172.526 332.763C59.772 342.022 67.6721 296.763 104.529 281.269C141.386 265.774 167.5 277 161 262C115.684 267.637 97.8378 270.277 50.8246 260.224C30.531 255.885 25.7384 224.493 59.772 223C178.848 217.776 141.21 207.463 120.132 207.463C95.5969 207.463 66.6803 200.288 38.6938 200.288C-7.77405 200.288 -25.0198 151.495 59.772 151.495C120.784 151.495 120.53 147.502 122.527 138.1C125.881 122.314 101.025 125.539 75.1016 119.444Z"
        className={classes.svg}
        fillOpacity="0.15"
      />
      <circle cx="474" cy="127" r="12" className={classes.svg} />
      <mask id="path-3-inside-1_223_467" className={classes.contrastFill}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M520 235.568C523.34 233.949 526.497 231.733 529.303 228.927C533.106 225.124 535.826 220.675 537.443 216C539.061 220.675 541.78 225.124 545.584 228.927C548.39 231.733 551.547 233.949 554.887 235.568C551.547 237.186 548.39 239.402 545.584 242.208C541.78 246.011 539.061 250.461 537.443 255.135C535.826 250.461 533.106 246.011 529.303 242.208C526.497 239.402 523.34 237.186 520 235.568Z"
        />
      </mask>
      <path
        d="M529.303 228.927L531.424 231.049V231.049L529.303 228.927ZM520 235.568L518.692 232.868L513.122 235.568L518.692 238.267L520 235.568ZM537.443 216L540.278 215.019L537.443 206.827L534.608 215.019L537.443 216ZM554.887 235.568L556.195 238.267L561.765 235.568L556.195 232.868L554.887 235.568ZM537.443 255.135L534.608 256.116L537.443 264.308L540.278 256.116L537.443 255.135ZM529.303 242.208L531.424 240.087L529.303 242.208ZM527.182 226.806C524.614 229.374 521.732 231.394 518.692 232.868L521.308 238.267C524.948 236.503 528.38 234.093 531.424 231.049L527.182 226.806ZM534.608 215.019C533.138 219.267 530.662 223.326 527.182 226.806L531.424 231.049C535.551 226.922 538.513 222.082 540.278 216.981L534.608 215.019ZM547.705 226.806C544.225 223.326 541.749 219.267 540.278 215.019L534.608 216.981C536.374 222.082 539.336 226.922 543.462 231.049L547.705 226.806ZM556.195 232.868C553.155 231.394 550.273 229.374 547.705 226.806L543.462 231.049C546.506 234.093 549.939 236.503 553.578 238.267L556.195 232.868ZM547.705 244.329C550.273 241.762 553.155 239.741 556.195 238.267L553.578 232.868C549.939 234.632 546.506 237.043 543.462 240.087L547.705 244.329ZM540.278 256.116C541.749 251.868 544.225 247.809 547.705 244.329L543.462 240.087C539.336 244.213 536.374 249.053 534.608 254.154L540.278 256.116ZM527.182 244.329C530.662 247.809 533.138 251.868 534.608 256.116L540.278 254.154C538.513 249.053 535.551 244.213 531.424 240.087L527.182 244.329ZM518.692 238.267C521.732 239.741 524.614 241.762 527.182 244.329L531.424 240.087C528.38 237.043 524.948 234.632 521.308 232.868L518.692 238.267Z"
        className={classes.svg}
        mask="url(#path-3-inside-1_223_467)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 283.568C43.3399 281.949 46.4972 279.733 49.303 276.927C53.1065 273.124 55.8256 268.675 57.4434 264C59.0612 268.675 61.7803 273.124 65.5838 276.927C68.3896 279.733 71.5469 281.949 74.8868 283.568C71.5469 285.186 68.3896 287.402 65.5838 290.208C61.7803 294.011 59.0612 298.461 57.4434 303.135C55.8256 298.461 53.1065 294.011 49.303 290.208C46.4972 287.402 43.3399 285.186 40 283.568Z"
        className={classes.svg}
      />
      <mask id="path-6-inside-2_223_467" className={classes.contrastFill}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103 22.5676C106.34 20.9488 109.497 18.7331 112.303 15.9273C116.106 12.1239 118.826 7.67455 120.443 3C122.061 7.67455 124.78 12.1239 128.584 15.9273C131.39 18.7331 134.547 20.9488 137.887 22.5676C134.547 24.1864 131.39 26.4022 128.584 29.208C124.78 33.0114 122.061 37.4607 120.443 42.1353C118.826 37.4607 116.106 33.0114 112.303 29.208C109.497 26.4022 106.34 24.1864 103 22.5676Z"
        />
      </mask>
      <path
        d="M112.303 15.9273L114.424 18.0486V18.0486L112.303 15.9273ZM103 22.5676L101.692 19.868L96.1216 22.5676L101.692 25.2673L103 22.5676ZM120.443 3L123.278 2.01885L120.443 -6.17287L117.608 2.01885L120.443 3ZM137.887 22.5676L139.195 25.2673L144.765 22.5676L139.195 19.868L137.887 22.5676ZM120.443 42.1353L117.608 43.1164L120.443 51.3081L123.278 43.1164L120.443 42.1353ZM112.303 29.208L114.424 27.0867L112.303 29.208ZM110.182 13.806C107.614 16.3737 104.732 18.3945 101.692 19.868L104.308 25.2673C107.948 23.5032 111.38 21.0925 114.424 18.0486L110.182 13.806ZM117.608 2.01885C116.138 6.26704 113.662 10.3258 110.182 13.806L114.424 18.0486C118.551 13.922 121.513 9.08206 123.278 3.98115L117.608 2.01885ZM130.705 13.806C127.225 10.3258 124.749 6.26704 123.278 2.01885L117.608 3.98115C119.374 9.08206 122.336 13.922 126.462 18.0486L130.705 13.806ZM139.195 19.868C136.155 18.3945 133.273 16.3737 130.705 13.806L126.462 18.0486C129.506 21.0925 132.939 23.5032 136.578 25.2673L139.195 19.868ZM130.705 31.3293C133.273 28.7616 136.155 26.7408 139.195 25.2673L136.578 19.868C132.939 21.6321 129.506 24.0428 126.462 27.0867L130.705 31.3293ZM123.278 43.1164C124.749 38.8682 127.225 34.8095 130.705 31.3293L126.462 27.0867C122.336 31.2133 119.374 36.0532 117.608 41.1541L123.278 43.1164ZM110.182 31.3293C113.662 34.8095 116.138 38.8682 117.608 43.1164L123.278 41.1541C121.513 36.0532 118.551 31.2133 114.424 27.0867L110.182 31.3293ZM101.692 25.2673C104.732 26.7408 107.614 28.7616 110.182 31.3293L114.424 27.0867C111.38 24.0428 107.948 21.6321 104.308 19.868L101.692 25.2673Z"
        className={classes.svg}
        mask="url(#path-6-inside-2_223_467)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.255 22.8256C407.052 20.9313 410.633 18.357 413.804 15.1127C418.103 10.7148 421.154 5.59266 422.942 0.22737C424.853 5.55047 428.019 10.6017 432.417 14.9004C435.662 18.0716 439.3 20.5635 443.14 22.3707C439.342 24.2649 435.762 26.8392 432.59 30.0835C428.292 34.4815 425.241 39.6037 423.453 44.969C421.542 39.6459 418.375 34.5945 413.977 30.2958C410.733 27.1247 407.095 24.6327 403.255 22.8256Z"
        className={classes.svg}
      />
      <mask id="path-9-inside-3_223_467" className={classes.contrastFill}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M370 91C372.219 89.9245 374.317 88.4525 376.181 86.5884C378.707 84.0615 380.514 81.1056 381.589 78C382.664 81.1056 384.47 84.0615 386.997 86.5884C388.861 88.4525 390.959 89.9245 393.177 91C390.959 92.0755 388.861 93.5475 386.997 95.4116C384.47 97.9384 382.664 100.894 381.589 104C380.514 100.894 378.707 97.9384 376.181 95.4116C374.317 93.5475 372.219 92.0755 370 91Z"
        />
      </mask>
      <path
        d="M370 91L369.128 89.2002L365.414 91L369.128 92.7997L370 91ZM381.589 78L383.479 77.3459L381.589 71.8847L379.699 77.3459L381.589 78ZM393.177 91L394.05 92.7997L397.763 91L394.05 89.2002L393.177 91ZM386.997 95.4116L388.411 96.8258L386.997 95.4116ZM381.589 104L379.699 104.654L381.589 110.115L383.479 104.654L381.589 104ZM374.766 85.1742C373.061 86.8795 371.147 88.2216 369.128 89.2002L370.872 92.7997C373.291 91.6274 375.572 90.0254 377.595 88.0026L374.766 85.1742ZM379.699 77.3459C378.722 80.1672 377.078 82.8628 374.766 85.1742L377.595 88.0026C380.337 85.2603 382.306 82.0439 383.479 78.6541L379.699 77.3459ZM388.411 85.1742C386.1 82.8628 384.455 80.1672 383.479 77.3459L379.699 78.6541C380.872 82.0439 382.84 85.2603 385.583 88.0026L388.411 85.1742ZM394.05 89.2002C392.031 88.2216 390.116 86.8795 388.411 85.1742L385.583 88.0026C387.605 90.0254 389.886 91.6274 392.305 92.7997L394.05 89.2002ZM388.411 96.8258C390.116 95.1205 392.031 93.7784 394.05 92.7997L392.305 89.2002C389.886 90.3725 387.605 91.9746 385.583 93.9974L388.411 96.8258ZM383.479 104.654C384.455 101.833 386.1 99.1371 388.411 96.8258L385.583 93.9974C382.84 96.7397 380.872 99.9561 379.699 103.346L383.479 104.654ZM374.766 96.8258C377.078 99.1371 378.722 101.833 379.699 104.654L383.479 103.346C382.306 99.9561 380.337 96.7397 377.595 93.9974L374.766 96.8258ZM369.128 92.7997C371.147 93.7784 373.061 95.1205 374.766 96.8258L377.595 93.9974C375.572 91.9746 373.291 90.3725 370.872 89.2002L369.128 92.7997Z"
        className={classes.svg}
        mask="url(#path-9-inside-3_223_467)"
      />
      <circle cx="196.5" cy="78.5" r="8.5" className={classes.svg} />
      <circle
        cx="17.5"
        cy="183.5"
        r="14"
        className={classes.svgPrimaryStroke}
        strokeWidth="3"
      />
      <circle
        cx="180.5"
        cy="273.5"
        r="6.5"
        className={classes.svgPrimaryStroke}
        strokeWidth="2"
      />
      <circle
        cx="539.5"
        cy="108.5"
        r="6.5"
        className={classes.svgPrimaryStroke}
        strokeWidth="2"
      />
      <rect
        x="574.364"
        y="289"
        width="2.86364"
        height="18"
        className={classes.svg}
      />
      <rect
        x="585"
        y="296.364"
        width="2.86364"
        height="18"
        transform="rotate(90 585 296.364)"
        className={classes.svg}
      />
      <rect x="54.75" y="95" width="5.25" height="24" className={classes.svg} />
      <rect
        x="69"
        y="104"
        width="5.25"
        height="24"
        transform="rotate(90 69 104)"
        className={classes.svg}
      />
      <rect
        x="502.33"
        y="49.2268"
        width="2.54545"
        height="16"
        transform="rotate(40.2218 502.33 49.2268)"
        className={classes.svg}
      />
      <rect
        x="505.322"
        y="60.3298"
        width="2.54545"
        height="16"
        transform="rotate(130.222 505.322 60.3298)"
        className={classes.svg}
      />
      <rect
        x="109.364"
        y="227"
        width="2.86364"
        height="18"
        className={classes.svg}
      />
      <rect
        x="120"
        y="234.364"
        width="2.86364"
        height="18"
        transform="rotate(90 120 234.364)"
        className={classes.svg}
      />
      <rect
        x="246.878"
        y="308.795"
        width="255.626"
        height="16.5991"
        rx="8.29955"
        fill="#5C5C5C"
      />
      <path
        d="M314.382 308.784C321.771 308.374 327.697 296.398 327.697 281.683C327.697 266.969 321.771 254.992 314.382 254.582V254.571H434.61C427.04 254.571 420.903 266.71 420.903 281.683C420.903 296.657 427.04 308.795 434.61 308.795H314.382V308.784Z"
        fill="#C4C4C4"
      />
      <path
        d="M434.61 308.795C434.741 308.795 434.872 308.791 435.002 308.784V308.795H434.61Z"
        fill="#C4C4C4"
      />
      <path
        d="M434.61 254.571H435.002V254.582C434.872 254.575 434.741 254.571 434.61 254.571Z"
        fill="#C4C4C4"
      />
      <rect
        x="235.39"
        y="101.86"
        width="298.784"
        height="162.671"
        rx="6"
        transform="rotate(6.49119 235.39 101.86)"
        fill="#5C5C5C"
      />
      <rect
        x="246.549"
        y="115.884"
        width="273.438"
        height="137.326"
        rx="5.5"
        transform="rotate(6.49119 246.549 115.884)"
        fill="#716F6F"
        stroke="#5C5C5C"
      />
      <path
        d="M220.002 237.1L516.871 270.878L514.547 291.304C514.172 294.597 511.199 296.962 507.907 296.587L222.962 264.166C219.669 263.792 217.304 260.819 217.678 257.527L220.002 237.1Z"
        className={classes.contrastFill}
      />
      <rect
        width="42.234"
        height="4.44568"
        rx="2.22284"
        transform="matrix(-0.782512 0.622635 0.622635 0.782512 343.522 149.803)"
        className={classes.lightFill}
      />
      <rect
        x="317.163"
        y="146.804"
        width="42.234"
        height="4.44568"
        rx="2.22284"
        transform="rotate(51.4912 317.163 146.804)"
        className={classes.lightFill}
      />
      <rect
        width="42.234"
        height="4.44568"
        rx="2.22284"
        transform="matrix(-0.782512 0.622635 0.622635 0.782512 441.378 160.938)"
        className={classes.lightFill}
      />
      <rect
        x="415.019"
        y="157.938"
        width="42.234"
        height="4.44568"
        rx="2.22284"
        transform="rotate(51.4912 415.019 157.938)"
        className={classes.lightFill}
      />
      <path
        d="M348.938 217.803C348.483 219.106 349.171 220.532 350.474 220.987C351.778 221.442 353.203 220.755 353.659 219.451L348.938 217.803ZM389.48 223.255C389.48 224.636 390.599 225.756 391.98 225.756C393.361 225.756 394.48 224.637 394.48 223.256L389.48 223.255ZM353.659 219.451C358.29 206.188 367.415 202.189 374.906 203.567C382.568 204.976 389.482 212.124 389.48 223.255L394.48 223.256C394.482 209.885 386.012 200.526 375.81 198.649C365.437 196.742 354.219 202.68 348.938 217.803L353.659 219.451Z"
        className={classes.lightFill}
      />
      <rect
        x="24"
        y="323"
        width="138"
        height="9"
        rx="4.5"
        className={classes.svgPrimaryDark}
      />
      <rect
        x="214"
        y="323"
        width="349"
        height="9"
        rx="4.5"
        className={classes.svgPrimaryDark}
      />
      <path
        d="M157.671 294.561C103.802 294.561 85.3937 315.329 78 325H85.2428C101.086 305.976 135.942 299.361 157.671 299.793C301.168 302.646 294.831 271.732 283.967 247H278.082C304.79 294.561 220.14 294.561 157.671 294.561Z"
        className={classes.svg}
      />
      <path
        d="M268.059 242.433C267.335 240.845 266.864 239.206 266.632 237.591L283.593 228.315C284.621 229.481 285.526 230.868 286.245 232.444C287.807 235.868 288.188 239.525 287.549 242.667C286.91 245.81 285.255 248.418 282.762 249.782C280.272 251.144 277.388 251.024 274.724 249.703C272.058 248.38 269.62 245.856 268.059 242.433Z"
        className={classes.contrastFill}
        stroke="#EDEDED"
        strokeWidth="0.5"
      />
      <path
        d="M266.884 228.388C266.595 227.747 266.86 226.953 267.476 226.614C268.092 226.275 268.826 226.52 269.115 227.161L272.469 234.592L270.238 235.82L266.884 228.388Z"
        fill="#C4C4C4"
      />
      <path
        d="M274.11 224.353C273.831 223.708 274.109 222.92 274.73 222.593C275.351 222.265 276.08 222.523 276.359 223.167L279.599 230.664L277.35 231.849L274.11 224.353Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

function ErrorPage(props) {
  const { errorCode } = props;

  const renderScreen = () => {
    let component = "";
    switch (errorCode) {
      case 400:
        component = {
          img: <SVGError />,
          headerText: "Sorry, something went wrong...",
          description:
            "An error has occured and we're working to fix the problem."
        };
        break;
      case 401:
        component = {
          img: <SVGError />,
          headerText: "Access denied",
          description:
            "Sorry but you don't have the permission to access this page."
        };
        break;
      case 404:
        component = {
          img: <SVGError />,
          headerText: "Page not found :(",
          description:
            "Looks like the page you requested for could not be found.",
          secondary:
            "For immediate help from our customer service, please contact us"
        };
        break;
      case 500:
        component = {
          img: <SVGError />,
          headerText: "Sorry, something went wrong...",
          description:
            "An error has occured and we're working to fix the problem.",
          secondary:
            "For immediate help from our customer service, please contact us"
        };
        break;
      default:
        component = {
          img: <SVGError />,
          headerText: "Sorry, something went wrong...",
          description:
            "An error has occured and we're working to fix the problem."
        };
    }

    return component;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          width: "100%",
          margin: "40px 0",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {renderScreen().img}
      </div>
      <div
        style={{
          width: "100%",
          margin: "40px 0",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography style={{ fontSize: 32, fontWeight: "bold" }}>
          {renderScreen().headerText}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          margin: "40px 0 0",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography style={{ fontSize: 24 }}>
          {renderScreen().description}
        </Typography>
      </div>
      {renderScreen().secondary && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Typography style={{ fontSize: 24 }}>
            {renderScreen().secondary}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ErrorPage;
