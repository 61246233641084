const createBookingsData = (
  id,
  deliveryDate,
  tripNumber,
  serviceType,
  pickupLocation,
  deliveryLocation,
  hauler,
  plateNumber,
  status,
  vehicleType,
  driver,
  pickups,
  dispatch_time
) => {
  return {
    id,
    deliveryDate,
    tripNumber,
    serviceType,
    pickupLocation,
    deliveryLocation,
    hauler,
    plateNumber,
    status,
    vehicleType,
    driver,
    pickups,
    dispatch_time
  };
};

export default createBookingsData;
