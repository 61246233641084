import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    padding: "10px 16px",
    height: "12%"
  },
  headerInner: {
    height: "100%"
  },
  headerTitle: {
    fontWeight: "bold",
    marginRight: 12,
    letterSpacing: 1.2
  },
  targetIcon: {
    fontSize: 14,
    cursor: "pointer",
    color: "#2a9df4"
  },
  targetIconDisabled: {
    color: "black"
  },
  headerDate: {
    fontSize: 12,
    fontWeight: "bold"
  },
  headerCalendar: { fontSize: 16, fontWeight: "bold", marginLeft: 14 },
  headerToggleButton: {
    borderRadius: "16px"
  },
  toggleButtonLabel: {
    fontSize: 10
  },
  alertsToggle: {
    marginTop: 4,
    marginRight: 12
  },
  alertsToggleLabel: {
    fontSize: 10,
    cursor: "pointer",
    textDecoration: "underline"
  },
  innerContainer: {
    marginBottom: 15,
    display: "flex"
  },
  alertMessagesContainer: {
    width: "100%",
    marginLeft: "20px"
  },
  alertHeading: {
    color: "#e40000",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  },
  alertTime: {
    color: "#646464",
    fontSize: 10,
    fontWeight: theme.typography.fontWeightLight
  },
  alertDetails: {
    color: "#646464",
    fontSize: 11,
    fontWeight: theme.typography.fontWeightLight
  },
  notifHeading: {
    color: "#53a4f7",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  },
  activityHeading: {
    color: "#f2bd61",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  },
  logo: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 10
  },
  legend: {
    height: 10,
    width: 10,
    marginRight: 5
  },
  activitylegend: {
    fontSize: 16,
    marginRight: 5
  },
  container: { height: "7%", marginLeft: "16px" },
  outOfRangeIcon: {
    marginLeft: -20,
    marginTop: 6
  },
  activityHeading: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
    display: "flex"
  },
  geofenceFilter: {
    fontSize: 12,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "20px",
    cursor: "pointer"
  },
  geoPopover: {
    "& .MuiPopover-paper" : {
      width: "150px",
      padding: "10px"
    }
  },
  geoCheckbox: {
    padding: "0px",
    "& .MuiSvgIcon-root": {
      fontSize: "0.9rem"
    }
  },
  applyButton: {
    borderRadius: 32,
    textTransform: "none",
    padding: "0px",
    margin: "5px 0px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    }
  },
}));

export default useStyles;
