export const poly_options = {
  fillColor: "lightblue",
  fillOpacity: 0.5,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  // editable: true, //if polygon geofence can be dragged
  geodesic: false,
  zIndex: 1
};

export const notifPoly_options = {
  fillColor: "#7F00FF",
  fillOpacity: 1,
  strokeColor: "#7F00FF",
  // strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  // editable: true, //if polygon geofence can be dragged
  geodesic: false,
  zIndex: 1
}

export const buffers = [
  {
    value: 10,
    label: "10"
  },
  {
    value: 30,
    label: "30"
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 70,
    label: "70"
  },
  {
    value: 100,
    label: "100"
  }
];
