import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  styled,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";

const FormField = props => {
  const { fields, Controller, control, errors, fieldCategory } = props;
  const [showPassword, setShowPassword] = useState({
    old_password: false,
    password: false,
    confirm_password: false
  });

  // eslint-disable-next-line array-callback-return, consistent-return
  return fields.map(param => {
    if (fieldCategory === param.category) {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={
            param.name === "username" || param.category === "securityInfo"
              ? 12
              : 6
          }
        >
          <Controller
            key={param.name}
            name={param.name}
            control={control}
            render={({ field }) => (
              <StyledFormField
                name={param.name}
                value={field?.value}
                label={
                  <Typography>
                    {param.label}
                    <StyledRequired> *</StyledRequired>
                  </Typography>
                }
                type={
                  showPassword?.[param.name] ||
                  param.category !== "securityInfo"
                    ? "text"
                    : "password"
                }
                placeholder={param.placeholder}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    backgroundColor: "#F9f9f9",
                    borderRadius: 10,
                    paddingLeft: "0.5rem",
                    fontSize: 14,
                    color: "#3f3f3f"
                  },
                  readOnly: param.isReadOnly,
                  inputProps: {
                    maxLength: param.name === "contact_number" && 13
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {param.category === "securityInfo" && (
                        <IconButton
                          style={{ padding: 0, marginRight: 10 }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(prev => {
                              return {
                                ...prev,
                                [param.name]: !showPassword?.[param.name]
                              };
                            });
                          }}
                          onMouseDown={e => e.preventDefault()}
                        >
                          {showPassword?.[param.name] ? (
                            <LockOpenIcon />
                          ) : (
                            <LockIcon />
                          )}
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )}
          />
          {errors?.[param.name] && (
            <StyledTypographyError>
              {errors?.[param.name].message}
            </StyledTypographyError>
          )}
        </Grid>
      );
    }
  });
};

const StyledFormField = styled(TextField)(() => ({
  width: "100%"
}));

const StyledRequired = styled("span")(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "1rem"
}));

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem"
}));

export default FormField;
