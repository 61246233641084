/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useRef
} from "react";
import {
  Avatar,
  TableHead,
  makeStyles,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer,
  Table,
  TableSortLabel,
  TableBody,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Visibility,
  BuildRounded as UnderRepairIcon,
  MoreVert,
  Room
} from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";
import _ from "lodash";
import Cookie from "js-cookie";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { DeleteConfirmation } from "./Dialogs";
import { gpsStatus } from "../../../Utils";
import Battery from "../../../../utils/Battery";
import AddLogs from "../../../../utils/functions/AddLogs";
import not_in_use from "../../../../assets/Not_in_Use.png";
import SearchContext from "../../../../context/SearchContext";
import SvgIcons from "../../../../assets/SvgIcons/SvgIcons";
import PairDeviceModal from "../../../../utils/modals/PairDeviceModal";
import { EDIT_VEHICLE, ADD_NOTIFICATION } from "../../../../graphql/Mutations";
import UnpairingDialog from "../../../../utils/modals/UnpairingDialog";
import {
  getBookingsOfVehicle,
  resetTrips
} from "../../../../utils/functions/unpairingFunctions";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  thead: {
    borderTop: "solid #e5e5e5",
    borderBottom: "solid #e5e5e5",
    borderWidth: "1px"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500]
  },
  undeRepairSize: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: "white"
  },
  swal: {
    zIndex: 10000000000
  },
  optionsItem: {
    fontSize: 12,
    color: theme.palette.primary.dark
  },
  optionsIcon: {
    fontSize: 18,
    color: "#878787",
    marginRight: 10
  }
}));

const headCells = [
  // {
  //   id: "vehicle_icon",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Vehicle Icon",
  //   sortable: false
  // },
  // {
  //   // id: "device_config.underrepair",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "",
  //   sortable: false
  // },
  // {
  //   id: "status_code_id",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Vehicle Status",
  //   sortable: true
  // },
  {
    id: "devices.name",
    numeric: false,
    disablePadding: true,
    label: "IMEI/Serial Number",
    sortable: true
  },
  {
    id: "vehicle_info.plateno",
    numeric: false,
    disablePadding: true,
    label: "Plate/CV Number",
    sortable: true
  },
  {
    id: "vehicle_types.name",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Type",
    sortable: true
  },
  {
    id: "devices.devicealias",
    numeric: false,
    disablePadding: true,
    label: "Paired Device",
    sortable: true
  },
  {
    id: "clients.name",
    numeric: false,
    disablePadding: true,
    label: "Hauler",
    sortable: true
  },
  {
    id: "battery_level",
    numeric: true,
    disablePadding: true,
    label: "Battery",
    sortable: true
  },
  {
    id: "rover_status.status_code_id",
    numeric: false,
    disablePadding: true,
    label: "GPS Status",
    sortable: true
  }
  // {
  //   id: "actions",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Actions",
  //   sortable: false
  // }
];

const booked = [
  process.env.REACT_APP_STATUS_IN_TRANSIT,
  process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
  process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
  process.env.REACT_APP_INCOMING_STATUS
];
const available = [process.env.REACT_APP_STATUS_AVAILABLE];

const EnhancedTableHead = props => {
  const {
    canEdit,
    order,
    orderBy,
    classes,
    handleSorting,
    rowCount,
    selectedCount,
    handleSelectAllClick
  } = props;

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!canEdit}
            color="primary"
            indeterminate={selectedCount > 0 && selectedCount < rowCount}
            onChange={handleSelectAllClick}
            checked={selectedCount > 0 && selectedCount === rowCount}
          />
        </TableCell>
        <TableCell>Vehicle Icon</TableCell>
        <TableCell></TableCell>
        <TableCell>Vehicle Status</TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.id === "actions" ? "center" : "left"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSorting(order, headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

const VehiclesTable = props => {
  const bookingRef = useRef();
  const newDeviceRef = useRef();
  const [deleteData, setDeleteData] = useState({
    open: false,
    data: null
  });
  const [pairing, setPairing] = useState({
    open: false,
    data: null
  });
  const classes = useStyles();
  const {
    canEdit,
    vehicles,
    currentPage,
    rowsPerPage,
    selected,
    setSelected,
    getQueryConditions,
    handlePaginator,
    history,
    // canDelete,
    order,
    orderBy,
    handleSorting,
    setSearchKeyword,
    searchKeyword,
    updateTable,
    vtypeFilter,
    haulerFilter,
    isLogistics
  } = props;
  const contextData = useContext(SearchContext);

  const [unpairModal, setUnpairModal] = useState(false);

  // const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [editMutation] = useMutation(EDIT_VEHICLE, {
    onCompleted(data) {
      if (data.edit_vehicle.success) {
        if (+pairing.data?.device_id && !+newDeviceRef.current?.id) {
          // Before: with device, after: no device
          AddLogs("Admin - Vehicles/CV", "unpaired_device", [
            pairing.data.device_info?.name,
            pairing.data.plateno
          ]);
          // addNotif({
          //   variables: {
          //     notification: {
          //       client_id: [data.edit_vehicle.vehicle.client_id],
          //       vehicle_plate_no: pairing.data.plateno,
          //       datestamp: moment()
          //         .format("YYYY-MM-DD HH:mm:ss"),
          //       notification: `GPS Device ${pairing.data.device_info?.name} was unpaired to Vehicle/CV ${pairing.data.plateno}`,
          //       category: "User Activity",
          //       is_archived: false,
          //       creator: "Tmsuite-web",
          //       group_ids: data.edit_vehicle.vehicle.group_ids,
          //       group_names: data.edit_vehicle.vehicle.group_names
          //     }
          //   }
          // });
        } else if (+pairing.data?.device_id !== +newDeviceRef.current?.id) {
          // Change device
          AddLogs("Admin - Vehicles/CV", "paired_device", [
            newDeviceRef.current?.name,
            pairing.data?.plateno
          ]);

          // addNotif({
          //   variables: {
          //     notification: {
          //       client_id: [data.edit_vehicle.vehicle.client_id],
          //       vehicle_plate_no: pairing.data?.plateno,
          //       datestamp: moment()
          //         .format("YYYY-MM-DD HH:mm:ss"),
          //       notification: `GPS Device ${newDeviceRef.current?.name} was paired to Vehicle/CV ${pairing.data?.plateno}`,
          //       category: "User Activity",
          //       is_archived: false,
          //       creator: "Tmsuite-web",
          //       group_ids: data.edit_vehicle.vehicle.group_ids,
          //       group_names: data.edit_vehicle.vehicle.group_names
          //     }
          //   }
          // });
        }

        setPairing({
          open: false,
          data: null
        });
        Swal.fire({
          icon: "success",
          text: "Update successful",
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            container: `${classes.swal}`
          },
          onClose: () => {
            bookingRef.current = null;
            newDeviceRef.current = null;
            updateTable();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            container: `${classes.swal}`
          }
        });
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false,
        customClass: {
          container: `${classes.swal}`
        }
      });
    }
  });

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
  }, []);

  const user = JSON.parse(Cookie.get("user"));

  const isSelected = id => selected.indexOf(id) !== -1;
  const handleClick = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const processUpdateVehicle = async (trips, device_id) => {
    // If vehicle has future trips, reset trips first before updating vehicle
    if (trips?.futureTrips?.forReset.length) {
      const resetResult = await resetTrips(trips.futureTrips.forReset);

      // If all future trips has successfully reset, trigger editMutation
      if (resetResult.success) {
        editMutation({
          variables: {
            id: pairing.data.id,
            vehicle: { device_id: device_id || 0 }
          }
        });
      } else {
        bookingRef.current = null;
        newDeviceRef.current = null;
        setUnpairModal(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            container: `${classes.swal}`
          }
        });
      }
    } else {
      editMutation({
        variables: {
          id: pairing.data.id,
          vehicle: { device_id: device_id || 0 }
        }
      });
    }
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < vehicles.vehicles.length) {
        const displayedData = vehicles.vehicles.map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    // if (event.target.checked) {
    //   const newSelecteds = vehicles.vehicles.map(v => v.id);
    //   setSelected(newSelecteds);
    //   return;
    // }
    // setSelected([]);

    const tempSelected = selected;
    const deselect = () => {
      const displayedData = vehicles.vehicles.map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      vehicles.vehicles.map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const vehicleRowRef = useRef({});

  const options = [
    {
      label: "View/Edit",
      icon: <Visibility className={classes.optionsIcon} />,
      fnHandler: e => {
        e.stopPropagation();
        // setSearchKeyword({ ...searchKeyword, value: "" });
        AddLogs("Admin - Vehicles/CV", "view", vehicleRowRef.current.plateno);
        // set values for vehicles table filter
        history.push({
          pathname: `/admin/resources/vehicles/view/${vehicleRowRef.current.id}`,
          state: {
            vehicle_id: vehicleRowRef.current.id,
            vars: getQueryConditions(),
            vTypeName: vtypeFilter.name,
            group: haulerFilter,
            isLogistics: isLogistics
          }
        });
      }
    },
    {
      label: "Delete",
      icon: <DeleteIcon className={classes.optionsIcon} />,
      hasAccess:
        user.user_level_id === process.env.REACT_APP_WTI_ADMIN ||
        user.client.id === vehicleRowRef.current.client_id,
      fnHandler: e => {
        setAnchorEl(null);
        e.stopPropagation();
        setDeleteData({ open: true, data: vehicleRowRef.current });
      }
    },
    {
      label: "Pair/Unpair",
      icon: <PairUnpairIcon className={classes.optionsIcon} />,
      fnHandler: () => {
        setAnchorEl(null);
        setPairing({
          open: true,
          data: vehicleRowRef.current
        });
      }
    },
    {
      label: "Assign Geofence",
      icon: <Room className={classes.optionsIcon} />,
      hasAccess: user.user_level_id !== process.env.REACT_APP_WTI_ADMIN,
      fnHandler: e => {
        e.stopPropagation();
        AddLogs(
          "Admin - Vehicles/CV",
          "view_assigned_geofences",
          vehicleRowRef.current.plateno
        );
        window.open(
          `/admin/resources/assign_geofence/${vehicleRowRef.current.id}`
        );
      }
    }
  ];

  const muiMenu = (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => {
        setAnchorEl(null);
        vehicleRowRef.current = {};
      }}
      PaperProps={{
        style: {
          width: "20ch",
          boxShadow: "0px 6px 16px -2px rgba(140,140,140,0.75)"
        }
      }}
    >
      {options.map((option, index) => {
        return (
          <>
            {option.hasAccess || typeof option.hasAccess === "undefined" ? (
              <MenuItem
                onClick={option.fnHandler}
                disabled={option.label === "Delete" && !canEdit}
                key={index}
                style={{ display: "flex" }}
              >
                {option.icon}
                <Typography className={classes.optionsItem}>
                  {option.label}
                </Typography>
              </MenuItem>
            ) : (
              ""
            )}
          </>
        );
      })}
    </Menu>
  );

  return (
    <Fragment>
      <DeleteConfirmation
        data={deleteData}
        toggle={setDeleteData}
        getQueryConditions={getQueryConditions}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handlePaginator={handlePaginator}
        updateTable={updateTable}
      />
      <TableContainer>
        <Table>
          <EnhancedTableHead
            canEdit={canEdit}
            classes={classes}
            order={order}
            orderBy={orderBy}
            handleSorting={handleSorting}
            handleSelectAllClick={handleSelectAllClick}
            rowCount={vehicles.count}
            selectedCount={selected.length}
          />

          <TableBody>
            {vehicles.count > 0 ? (
              vehicles.vehicles.map(vehicle => {
                const isRowSelected = isSelected(vehicle.id);
                const labelId = `enhanced-table-checkbox-${vehicle.id}`;

                return (
                  <TableRow
                    key={vehicle.id}
                    hover
                    role="checkbox"
                    aria-checked={isRowSelected}
                    tabIndex={-1}
                    selected={isRowSelected}
                  >
                    <TableCell padding="checkbox">
                      {(user.user_level_id ===
                        process.env.REACT_APP_WTI_ADMIN && (
                        <Checkbox
                          onClick={() => handleClick(vehicle.id)}
                          disabled={!canEdit}
                          checked={isRowSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          color="primary"
                        />
                      )) ||
                        (user.client.id === vehicle.client_id && (
                          <Checkbox
                            onClick={() => handleClick(vehicle.id)}
                            disabled={!canEdit}
                            checked={isRowSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            color="primary"
                          />
                        ))}
                    </TableCell>
                    <TableCell>
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        // viewBox="0 0 16.96 16.96"
                        width="45px"
                        height="45px"
                        style={{ paddingTop: 22 }}
                      >
                        <g
                          fill={"#00000"}
                          stroke={SvgIcons.stroke}
                          strokeWidth={0.5}
                        >
                          <path
                            d={SvgIcons[`${vehicle.icon.split(".")[0]}`].path}
                          />
                        </g>
                      </svg>
                      {/* <img
                          src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${vehicle.icon}`}
                          srcSet
                          alt=""
                          width={25}
                          height={25}
                          style={{ paddingTop: 5 }}
                      /> */}
                    </TableCell>
                    <TableCell>
                      {vehicle.under_repair && (
                        <Tooltip title={`Under Repair`} placement="right-start">
                          <Avatar className={classes.avatarSize}>
                            <UnderRepairIcon
                              className={classes.undeRepairSize}
                            />
                          </Avatar>
                        </Tooltip>
                      )}
                      {vehicle.not_in_use && (
                        <Tooltip title={`Not in use`} placement="right-start">
                          <Avatar src={not_in_use}></Avatar>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {booked.includes(vehicle.status_code_id) && isLogistics
                        ? "Booked"
                        : vehicle.under_repair === true
                        ? "Under Repair"
                        : vehicle.not_in_use === true
                        ? "Not in use"
                        : available.includes(vehicle.status_code_id) && isLogistics
                        ? "Available"
                        : available.includes(vehicle.status_code_id) && !isLogistics
                        ? "Active"
                        : "-"
                        }
                      {/* {vehicle.status_code} */}
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.imei}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.plateno}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.vehicle_type}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.paired_device}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.client_name}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Battery
                        vehicleType={vehicle.vehicle_type}
                        deviceTypeId={vehicle.device_type_id}
                        batteryLevel={vehicle.battery_level}
                        tooltipTitle={
                          <>
                            <p
                              className={classes.tooltipTitle}
                              style={{ fontWeight: "bold" }}
                            >
                              {`Battery Life: ${vehicle?.battery_level}%`}
                            </p>
                            <p className={classes.tooltipTitle}>
                              {`As of: ${moment(vehicle.reportstamp).format(
                                "lll"
                              )}
                                  `}
                            </p>
                          </>
                        }
                        showText
                        showNAText
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: gpsStatus(vehicle.status_code).color
                      }}
                    >
                      <Highlight search={contextData.searchKeyword.value}>
                        {gpsStatus(vehicle.status_code).label}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={event => {
                          setAnchorEl(event.currentTarget);
                          vehicleRowRef.current = vehicle;
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          setSearchKeyword({ ...searchKeyword, value: "" });
                          AddLogs(
                            "Admin - Vehicles/CV",
                            "view",
                            vehicle.plateno
                          );
                          history.push(
                            `/admin/resources/vehicles/view/${vehicle.id}`,
                            {
                              vehicle_id: vehicle.id,
                              queryVars: getQueryConditions()
                            }
                          );
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      {(user.user_level_id ===
                        process.env.REACT_APP_WTI_ADMIN && (
                        <IconButton
                          disabled={!canEdit}
                          onClick={e => {
                            e.stopPropagation();
                            setDeleteData({ open: true, data: vehicle });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )) ||
                        (user.client.id === vehicle.client_id && (
                          <IconButton
                            disabled={!canEdit}
                            onClick={e => {
                              e.stopPropagation();
                              setDeleteData({ open: true, data: vehicle });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ))}
                      <IconButton
                        onClick={() => {
                          setPairing({
                            open: true,
                            data: vehicle
                          })
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 1024 1024"
                          fill="#000000"
                          opacity="0.9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path 
                            d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z" 
                            stroke-width="8"  
                            stroke="#000000"
                            opacity="0.9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={20}>
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {muiMenu}
      {pairing.open && (
        <PairDeviceModal
          toggle={pairing.open}
          initialData={pairing.data}
          closeVehicleModal={forRefetch => {
            setPairing({
              ...pairing,
              open: false
            });

            if (forRefetch) updateTable();
          }}
          showUnpairButton={+pairing.data?.device_id}
          setDeviceInfo={async e => {
            const { device_id, plateno } = pairing.data;
            const trips = await getBookingsOfVehicle(device_id, plateno);
            if (trips.error) {
              Swal.fire({
                icon: "error",
                text: "Something went wrong",
                timer: 1500,
                showConfirmButton: false,
                customClass: {
                  container: `${classes.swal}`
                }
              });
              return;
            }

            bookingRef.current = trips;
            newDeviceRef.current = e;

            if (!e || trips?.ongoingTrips?.tripNumbers?.length) {
              setUnpairModal(true);
            } else {
              processUpdateVehicle(trips, e?.id);
            }
          }}
        />
      )}
      <UnpairingDialog
        toggle={unpairModal}
        close={() => setUnpairModal(false)}
        fn={() => {
          processUpdateVehicle(bookingRef.current, newDeviceRef.current?.id);
        }}
        referenceData="vehicle"
        tripInfo={{
          trips: bookingRef.current,
          devicealias: pairing.data?.device_info?.devicealias || "",
          plateno: pairing.data?.plateno || ""
        }}
      />
    </Fragment>
  );
};

export default withRouter(VehiclesTable);

const PairUnpairIcon = () => {
  const classes = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 1024 1024"
      fill="#000000"
      opacity="0.9"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.optionsIcon}
    >
      <path
        d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
        stroke-width="8"
        stroke="#000000"
        opacity="0.9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
