import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  makeStyles,
  Drawer,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  TextField,
  Box,
  Tooltip,
  Chip,
  Checkbox,
  Button
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  ClearAllRounded as ClearAllRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  ClearRounded as ClearRoundedIcon,
  RemoveRounded as RemoveRoundedIcon,
  CheckBoxOutlineBlank,
  CheckBox,
  Event as EventIcon
} from "@material-ui/icons";
import {
  GET_DASHBOARDS_FILTERS,
  GET_DASHBOARDS_FILTER,
  GET_SERVICE_TYPES,
  GET_VEHICLE_TYPE_LIST,
  GET_CLIENTS_DASHBOARDS,
  GET_ACCOUNTS,
  GET_BOOKING_GEOFENCES,
  GET_ADMIN_CLIENT
} from "../../../graphql/Queries";
import { DELETE_DASHBOARD_FILTER } from "../../../graphql/Mutations";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import _ from "lodash";
import Swal from "sweetalert2";
import useFetchDashboardFilters from "../../../utils/hooks/useFetchDashboardFilters";
import useFetchVehicleServiceType from "../../../utils/hooks/useFetchVehicleServiceType";
import ConfirmationDialog from "../../../utils/modals/ConfirmationDialog";
import moment from "moment";
import useDebounce from "../../../utils/hooks/useDebounce";
import AddLogs from "../../../utils/functions/AddLogs";
import Form from "./Form";
import client from "../../../Client";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const PICKUPS_PROP = "pickups";
const LOCATION_PROP = "locations";

const Filter = props => {
  const classes = useStyles();
  const user = JSON.parse(Cookies.get("user"));
  const { fn, isOpen, tab, setVars, vars, setIsFiltered } = props;
  // states
  const removedFilterRef = useRef("");
  const saveInitialFilter = { value: 0, label: "Select Filter" };
  const tripTypeFilter = [
    { value: "0", label: "All" },
    { value: "1", label: "Standard" },
    { value: "2", label: "InterIsland" }
  ];
  const initialFilters = [
    {
      serviceType: { value: "0", label: "All" },
      client: { value: "0", label: "All" },
      account: { value: "0", label: "All" },
      pickups: [],
      dropoffs: [],
      locations: [],
      dateRange: {
        start: moment().format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59")
      },
      tripType: { value: "0", label: "All" }
    },
    {
      vehicleType: { value: "0", label: "All" },
      client: { value: "0", label: "All" },
      account: { value: "0", label: "All" },
      locations: [],
      tripType: { value: "0", label: "All" }
    }
  ];
  const debounceTime = 800;
  const [clients, setClients] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [pickups, setPickups] = useState([]);
  const [dropoffs, setDropoffs] = useState([]);
  const [location, setLocation] = useState([]);
  const [pickupKeyword, setPickupKeyword] = useDebounce("", debounceTime);
  const [dropoffKeyword, setDropoffKeyword] = useDebounce("", debounceTime);
  const [clientKeyword, setClientKeyword] = useDebounce("", debounceTime);
  const [selectedFilter, setSelectedFilter] = useState([saveInitialFilter]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilters);
  const [formOpen, setFormOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  // initial variables for fetch dashboard filters.
  const initialVars = {
    user_id: user.id,
    dashboard_type: _.isEqual(tab, 0) ? "ontime" : "demurrage"
  };
  // custom hooks preparing the values needed for the dropdown saved filters.
  const {
    saveFilter,
    getFilter,
    fetchedSavedFilter
  } = useFetchDashboardFilters(initialVars);
  // custom hooks preparing the values for vehicle and service types.
  const { filterTypes } = useFetchVehicleServiceType(tab);
  // fetch clients or shipping line values options.
  const [getClientsOptions] = useLazyQuery(GET_CLIENTS_DASHBOARDS, {
    onCompleted: data => {
      if (data) {
        setClients([{ value: "0", label: "All" }, ...data.get_clients.clients]);
      }
    }
  });
  // get available accounts for accounts field.
  useQuery(GET_ACCOUNTS, {
    onCompleted: data => {
      if (data) {
        const accts = data.get_booking_accounts.map(el => {
          return { value: el, label: el };
        });
        setAccounts([{ value: "0", label: "All" }, ...accts]);
      }
    }
  });
  // get pickup options for pickup field.
  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(pickupKeyword ? { keyword: pickupKeyword } : {}),
      category: "pickup"
    },
    onCompleted: data => {
      if (data) {
        const pickupOptions = data.get_booking_geofences.map(geo => {
          return { value: geo.geofence_id, label: geo.name };
        });
        setPickups(pickupOptions);
      }
    }
  });
  // get dropoff options dor dropoff field.
  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(dropoffKeyword ? { keyword: dropoffKeyword } : {}),
      category: "dropoff"
    },
    onCompleted: data => {
      if (data) {
        const dropoffs_opts = data.get_booking_geofences.map(geo => {
          return { value: geo.geofence_id, label: geo.name };
        });
        setDropoffs(dropoffs_opts);
      }
    }
  });
  // fetch client data's
  const fetchClientHandler = async data => {
    const clientInfo = await client.query({
      query: GET_ADMIN_CLIENT,
      variables: {
        id: data.client_id
      },
      fetchPolicy: "network-only"
    });
    if (clientInfo?.data?.get_client) {
      const { id, name } = clientInfo.data.get_client;
      setClients(prevClients => [...prevClients, { value: id, label: name }]);
    }
    return clientInfo.data.get_client;
  };
  // convert options handler.
  function convertSelectOptionsHandler(
    data,
    filterType,
    filterTypeStr,
    arryTemp
  ) {
    if (!data) return;
    let tempVars = [];
    const STORE_TO = (filterTypeStr, selectedIdx) => {
      switch (filterTypeStr) {
        case "PICKUPS_FILTER":
          return pickups[selectedIdx];
        case "DROPOFFS_FILTER":
          return dropoffs[selectedIdx];
        case "LOCATIONS_FILTER":
          return location[selectedIdx];
        default: // do none;
      }
    };

    data.forEach(storedValue => {
      const selectedIdx = _.findIndex(arryTemp, selectedData =>
        _.isEqual(selectedData.value, storedValue)
      );

      if (!_.isEqual(selectedIdx, -1)) {
        filterType.push(STORE_TO(filterTypeStr, selectedIdx));
      }

      tempVars = filterType.map(value => value);
    });

    return tempVars;
  }
  // saved filters.
  function prepareSelectedSavedFilter(data) {
    const PICKUPS_FILTER = [];
    const DROPOFFS_FILTER = [];
    const LOCATIONS_FILTER = [];
    const filterValues = [...initialFilters];
    const DROPOFF_LABEL = "DROPOFFS_FILTER";
    const LOCATION_LABEL = "LOCATIONS_FILTER";

    let tempVars = {};
    let temp_filters_values = {
      pickups: PICKUPS_FILTER,
      dropoffs: DROPOFFS_FILTER,
      locations: LOCATIONS_FILTER
    };
    // prepare data for pickups or locations.
    if (data.pickup_ids.length > 0) {
      const propertyName = _.isEqual(tab, 0) ? PICKUPS_PROP : LOCATION_PROP;
      data.pickup_ids.forEach(pickupStored => {
        const pickupIdx = _.findIndex(
          _.isEqual(tab, 0) ? pickups : location,
          pickupData => _.isEqual(pickupData.value, pickupStored)
        );

        if (!_.isEqual(pickupIdx, -1)) {
          PICKUPS_FILTER.push(
            _.isEqual(tab, 0) ? pickups[pickupIdx] : location[pickupIdx]
          );
        }
      });

      tempVars = {
        ...tempVars,
        [propertyName]: PICKUPS_FILTER.map(pickup => pickup.value)
      };

      temp_filters_values = {
        ...temp_filters_values,
        [propertyName]: PICKUPS_FILTER.map(pickup => pickup)
      };
    }
    // prepare data for dropoffs.
    const dropoffIds = data.dropoff_ids;
    const dropoffsValues = convertSelectOptionsHandler(
      dropoffIds,
      DROPOFFS_FILTER,
      DROPOFF_LABEL,
      dropoffs
    );
    // get the dropoff ids from the convertSelectOptionsHandler.
    const dropoffIdsValues = dropoffsValues.map(dropoff => dropoff.value);
    tempVars = { ...tempVars, dropoffs: dropoffIdsValues };

    temp_filters_values = {
      ...temp_filters_values,
      dropoffs: dropoffsValues
    };

    // prepare data for location.
    const locationIds = data.location_ids;
    const locationValues = convertSelectOptionsHandler(
      locationIds,
      LOCATIONS_FILTER,
      LOCATION_LABEL,
      location
    );
    // get the dropoff ids from the convertSelectOptionsHandler.
    const locationIdsValues = locationValues.map(location => location.value);
    tempVars = { ...tempVars, locations: locationIdsValues };

    temp_filters_values = {
      ...temp_filters_values,
      locations: locationValues
    };
    // prepare the values for vehicle or service type.
    if (_.isEqual(tab, 0)) {
      const serviceTypeIds = _.findIndex(filterTypes, srvcType =>
        _.isEqual(+srvcType.value, data.service_type_id)
      );
      if (!_.isEqual(serviceTypeIds, -1)) {
        const serviceTypeId = filterTypes[serviceTypeIds];
        tempVars = {
          ...tempVars,
          service_type_id: +serviceTypeId.value
        };

        temp_filters_values = {
          ...temp_filters_values,
          serviceType: serviceTypeId
        };
      }
    } else {
      const vehicleTypeIds = _.findIndex(filterTypes, vehicleType =>
        _.isEqual(+vehicleType.value, data.vehicle_type_id)
      );
      if (!_.isEqual(vehicleTypeIds, -1)) {
        const vehicleTypeId = filterTypes[vehicleTypeIds];
        tempVars = {
          ...tempVars,
          vehicle_type_id: +vehicleTypeId.value
        };

        temp_filters_values = {
          ...temp_filters_values,
          vehicleType: vehicleTypeId
        };
      } else {
        temp_filters_values = {
          ...temp_filters_values,
          vehicleType: filterTypes[0]
        };
      }
    }

    if (data.is_interisland !== null) {
      tempVars = {
        ...tempVars,
        is_inter_island: data.is_interisland === 1 ? true : false
      };
      temp_filters_values = {
        ...temp_filters_values,
        tripType:
          data.is_interisland === 1
            ? { value: "2", label: "InterIsland" }
            : { value: "1", label: "Standard" }
      };
    }

    // prepare value for clients.
    const clientIdx = _.findIndex(clients, client =>
      _.isEqual(+client.value, data.client_id)
    );

    if (!_.isEqual(clientIdx, -1)) {
      tempVars = { ...tempVars, client_id: +clients[clientIdx].value };
      temp_filters_values = {
        ...temp_filters_values,
        client: clients[clientIdx]
      };
    } else {
      fetchClientHandler(data)
        .then(clnt => {
          if (clnt) {
            tempVars = { ...tempVars, client_id: +clnt.id };
            temp_filters_values = {
              ...temp_filters_values,
              client: { value: clnt.id, label: clnt.name }
            };
          } else {
            temp_filters_values = {
              ...temp_filters_values,
              client: clients[0]
            };
          }
          filterValues[tab] = { ...filterValues[tab], ...temp_filters_values };
          setFilterValues(filterValues);
          setVars({ ...tempVars, dateRange: vars.dateRange });
        })
        .catch(console.error);
    }
    // prepare accounts value.
    const accountIdx = _.findIndex(accounts, account =>
      _.isEqual(account, data.account)
    );

    if (!_.isEqual(accountIdx, -1)) {
      tempVars = { ...tempVars, account: accounts[accountIdx].label };
      temp_filters_values = {
        ...temp_filters_values,
        account: accounts[accountIdx]
      };
    } else {
      temp_filters_values = {
        ...temp_filters_values,
        account: accounts[0]
      };
    }

    filterValues[tab] = { ...filterValues[tab], ...temp_filters_values };
    setFilterValues(filterValues);
    setVars({ ...tempVars, dateRange: vars.dateRange });
  }
  // useEffect for rerendering components.
  // clients useEffect.
  useEffect(() => {
    getClientsOptions({
      variables: { keyword: clientKeyword, first: 10 }
    });
  }, [clientKeyword]);
  // merge useEffect for location.
  useEffect(() => {
    const merged = _.unionBy(pickups, dropoffs, "value");
    setLocation(merged);
  }, [pickups, dropoffs]);
  // use effect for preparetion of to be saved data on filter forms.
  useEffect(() => {
    if (!fetchedSavedFilter) return;
    prepareSelectedSavedFilter(fetchedSavedFilter);
  }, [fetchedSavedFilter]);
  // initialized upon changing active tab value.
  useEffect(() => resetFilterHandler(), [tab]);
  // mutation for delete filter on dashboard.
  const [deleteFilterMutation] = useMutation(DELETE_DASHBOARD_FILTER, {
    onCompleted: data => {
      if (data.delete_dashboard_filter.success) {
        Swal.fire({
          icon: "success",
          text: "Successfully Deleted Saved Filter.",
          timer: 3000
        });
      }
    },
    refetchQueries: () => {
      return [{ query: GET_DASHBOARDS_FILTERS, variables: initialVars }];
    }
  });
  // select function when user select a save filter values.
  function selectFilterHandler(event, filterValue) {
    // const filterTemp = [...selectedFilter];
    // filterTemp[tab] = filterValue;
    // setSelectedFilter(filterTemp);
    setSelectedFilter([filterValue]);
    getFilter({ variables: { id: filterValue.value } });
  }
  // open modal function.
  function openDeleteModalHandler(event, value) {
    removedFilterRef.current = value;
    setIsDeleteModalOpen(true);
  }
  // close modal function.
  function closeDeleteModalHandler() {
    removedFilterRef.current = "";
    setIsDeleteModalOpen(false);
  }
  // process logs function.
  function processLogsHandler(type) {
    // checks if their is a value for type process log.
    if (_.isEqual(type, "dateOnly")) {
      AddLogs(
        process.env.REACT_APP_DASHBOARD_MODULE,
        "date_filter",
        moment(filterValues[0].dateRange.start).format("YYYY-MM-DD")
      );
      return;
    }
    // else condition if no type has been provided by user.
    let typeText, pickupText, dropOffText, locationText;
    const { serviceType, vehicleType, account, locations } = filterValues[tab];
    // fetch the available array values on pickups and dropoffs.
    const pickupArr = filterValues[tab].pickups;
    const dropOffArr = filterValues[tab].dropoffs;
    // validates each filter field if it is undefined or null value.
    if (!serviceType || !vehicleType) typeText = "";
    if (!pickupArr) pickupText = "";
    if (!dropOffArr) dropOffText = "";
    if (!locationText) locationText = "";
    // set value text on each field.
    typeText = _.isEqual(tab, 0)
      ? `Service Type: ${serviceType.label}`
      : `Vehicle Type: ${vehicleType.label}`;
    pickupText = `Pickup: ${
      pickupArr ? pickupArr.forEach(pickup => pickup.label) : "All"
    }`;
    dropOffText = `Dropoff: ${
      dropOffArr ? dropOffArr.forEach(dropoff => dropoff.label) : "All"
    }`;
    locationText = `Locations: ${
      locations.length > 0 ? locations.map(l => l.label) : "All"
    }`;
    // log values.
    const textLogs = `${typeText},Hauler/Shipping Line: ${filterValues[tab]?.client?.label},Account: ${account?.label},${pickupText}${dropOffText}Location: ${locationText}`;
    // set logs and determine the type of filter to log.
    const logTab = _.isEqual(tab, 0)
      ? "filter_OTTA/OTSA/OTD"
      : "filter_demurrage";
    AddLogs(process.env.REACT_APP_DASHBOARD_MODULE, logTab, textLogs);
  }
  // prepare filter data for mutations.
  function filterDataHandler() {
    const {
      serviceType,
      vehicleType,
      account,
      client,
      dateRange,
      dropoffs,
      locations,
      pickups,
      tripType
    } = filterValues[tab];

    let filterDataTemp = {
      pickups: [],
      dropoffs: [],
      locations: []
    };
    // checks if the user has entered a filter for client.
    if (!_.isEqual(+client?.value, 0)) {
      filterDataTemp = { ...filterDataTemp, client_id: Number(client.value) };
    }
    // checks if the user has entered a filter for account.
    if (!_.isEqual(+account?.value, 0) && account?.value) {
      filterDataTemp = { ...filterDataTemp, account: Number(account.value) };
    }
    // checks if the user has entered a filter for trip type.
    if (!_.isEqual(+tripType?.value, 0)) {
      filterDataTemp = {
        ...filterDataTemp,
        is_inter_island: +tripType?.value === 2 ? true : false
      };
    }

    switch (tab) {
      case 0:
        // checks if the user has entered a filter for service types.
        if (serviceType?.value) {
          filterDataTemp = {
            ...filterDataTemp,
            service_type_id: Number(serviceType.value)
          };
        }
        // checks if the user has entered a filter for pickups.
        if (pickups) {
          filterDataTemp = {
            ...filterDataTemp,
            pickups: pickups.map(pickup => pickup.value)
          };
        }
        // checks if the user has entered a filter for dropoffs.
        if (dropoffs) {
          filterDataTemp = {
            ...filterDataTemp,
            dropoffs: dropoffs.map(dropoff => dropoff.value)
          };
        }
        // checks if the user has entered a filter for locations.
        if (locations) {
          filterDataTemp = {
            ...filterDataTemp,
            locations: locations.map(location => location.value)
          };
        }
        // checks if the user has entered a filter for date range.
        if (dateRange) {
          filterDataTemp = {
            ...filterDataTemp,
            dateRange: dateRange
          };
        }
        break;
      case 1:
        // checks if the user has entered a filter for vehicle types.
        if (vehicleType?.value) {
          filterDataTemp = {
            ...filterDataTemp,
            vehicle_type_id: Number(vehicleType.value)
          };
        }
        // checks if the user has entered a filter for locations.
        if (locations) {
          filterDataTemp = {
            ...filterDataTemp,
            locations: locations.map(location => location.value)
          };
        }
        break;
      default:
    }

    return filterDataTemp;
  }
  // function for the form handler.
  const formHandler = () => setFormOpen(!formOpen);
  // apply filter function.
  function applyFilterHandler(type) {
    setVars(filterDataHandler());
    processLogsHandler(type);
    setIsFiltered(true);
  }
  // onChange handler function for the filter values.
  function onChangeFilterHandler(event, value, fieldName) {
    const temp = [...filterValues];
    // validates whether the tab that is active is OTTA or demurrage.
    if (_.isEqual(tab, 0)) {
      temp[tab] = { ...temp[tab], [fieldName]: value };
    } else {
      temp[tab] = { ...temp[tab], [fieldName]: value };
    }

    setFilterValues(temp);
  }
  // resets filter dashboard values to the default initialized.
  function resetFilterHandler() {
    setSelectedFilter([saveInitialFilter]);
    setFilterValues(initialFilters);
    setVars({
      dateRange: {
        start: moment().format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59")
      }
    });
    setIsFiltered(false);
  }
  // function for deleting or removing saved filters.
  function deleteFilterHandler() {
    deleteFilterMutation({
      variables: {
        id: removedFilterRef.current.value
      }
    });
    removedFilterRef.current = "";
    resetFilterHandler();
  }
  // dynamic load of fields for filtering.
  const AutoCompleteDataFields = [
    {
      label: tab ? "Vehicle Types" : "Service Types",
      dataOptions: filterTypes,
      value: _.isEqual(tab, 0)
        ? filterValues[tab].serviceType
        : filterValues[tab].vehicleType,
      defaulOptValue: { value: "0", label: "All" },
      fieldName: _.isEqual(tab, 0) ? "serviceType" : "vehicleType",
      hasKeyword: false,
      placeholder: `Select ${tab ? "vehicle" : "service"} filter`
    },
    {
      label: "Hauler/Shipping Line",
      dataOptions: clients,
      value: filterValues[tab].client,
      defaulOptValue: { value: "0", label: "All" },
      fieldName: "client",
      hasKeyword: true,
      placeholder: "Select hauler/shipping line"
    },
    {
      label: "Accounts",
      dataOptions: accounts,
      value: filterValues[tab].account,
      defaulOptValue: { value: "0", label: "All" },
      fieldName: "accounts",
      hasKeyword: false,
      placeholder: "Select account"
    },
    {
      label: "Trip Type",
      dataOptions: tripTypeFilter,
      value: filterValues[tab].tripType,
      defaulOptValue: { value: "0", label: "All" },
      fieldName: "tripType",
      hasKeyword: false,
      placeholder: "Select Trip Type"
    }
  ];
  // ui for filter fields.
  const AutoCompleteUI = AutoCompleteDataFields.map((info, index) => {
    return (
      <div className={classes.filter_body_container} key={index}>
        <Autocomplete
          options={info.dataOptions}
          getOptionLabel={option => option.label.toString()}
          defaultValue={info.defaulOptValue}
          value={info.value}
          onChange={(event, value) => {
            onChangeFilterHandler(event, value, info.fieldName);
          }}
          getOptionDisabled={option => option.value === "0"}
          getOptionSelected={selected => selected}
          popupIcon={
            <ExpandMoreRoundedIcon style={{ color: "#808080", fontSize: 18 }} />
          }
          disableopenonfocus="true"
          renderOption={option => {
            return (
              <Typography className={classes.textStyle}>
                {option.label}
              </Typography>
            );
          }}
          renderInput={params => (
            <>
              {info.hasKeyword ? (
                <TextField
                  {...params}
                  label={info.label}
                  placeholder={info.placeholder}
                  className={classes.text_field_select_filter}
                  onChange={e => {
                    setClientKeyword(e.target.value);
                  }}
                />
              ) : (
                <TextField
                  {...params}
                  label={info.label}
                  placeholder={info.placeholder}
                  className={classes.text_field_select_filter}
                />
              )}
            </>
          )}
          disableClearable
        />
      </div>
    );
  });

  const calendarUI = () => {
    const isFilteredDate = selectedDate !== moment().format("YYYY-MM-DD");

    return (
      <>
        <IconButton size="small">
          <EventIcon
            className={
              isFilteredDate
                ? classes.calendar_icon_active
                : classes.calendar_icon
            }
            onClick={() => setOpenDatePicker(true)}
          />
        </IconButton>
        <span className={classes.calendarInput}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={filterValues[0].dateRange.start}
              onChange={nv => {
                setOpenDatePicker(false);
                const temp = [...filterValues];
                temp[tab] = {
                  ...temp[tab],
                  dateRange: {
                    start: moment(nv).format("YYYY-MM-DD 00:00:00"),
                    end: moment(nv).format("YYYY-MM-DD 23:59:59")
                  }
                };
                setFilterValues(temp);
                setSelectedDate(moment(nv).format("YYYY-MM-DD"));
              }}
              open={openDatePicker}
              onClose={() => setOpenDatePicker(false)}
              disableFuture
              showTodayButton
              todayLabel="NOW"
            />
          </MuiPickersUtilsProvider>
        </span>
      </>
    );
  };
  // dynamic value for pickups, dropoffs, location.
  const AutoCompleteStatusFields = [
    {
      label: "Pickups (OTTA)",
      dataOptions: pickups,
      value: filterValues[tab].pickups,
      defaulOptValue: [],
      fieldName: "pickups",
      hasKeyword: true,
      placeholder: "Select pickup/s",
      isVisible: _.isEqual(tab, 0) ? true : false
    },
    {
      label: "Dropoffs (OTD)",
      dataOptions: dropoffs,
      value: filterValues[tab].dropoffs,
      defaulOptValue: [],
      fieldName: "dropoffs",
      hasKeyword: true,
      placeholder: "Select dropoff/s",
      isVisible: _.isEqual(tab, 0) ? true : false
    },
    {
      label: `Location (${_.isEqual(tab, 0) ? "Closed/Cancelled" : "Land"})`,
      dataOptions: location,
      value: filterValues[tab].locations,
      defaulOptValue: [],
      fieldName: "locations",
      hasKeyword: false,
      placeholder: "Select location/s",
      isVisible: true
    }
  ];
  // dynamic ui for pickups, dropoffs and locations.
  const AutoCompleteStatusUI = AutoCompleteStatusFields.map((info, index) => {
    return (
      <Fragment>
        {info.isVisible ? (
          <div className={classes.filter_body_container} key={index}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '0.25rem' }}
            >
              <div>
                <Typography className={classes.label_autocomplete}>
                  {info.label}
                </Typography>
                {info.fieldName === "locations" && _.isEqual(tab, 0) && (
                  <Typography className={classes.label_autocomplete}>
                    {moment(filterValues[0].dateRange.start).format(
                      "MMM DD, YYYY"
                    )}
                  </Typography>
                )}
              </div>
              {info.fieldName === "locations" &&
                _.isEqual(tab, 0) &&
                calendarUI()}
            </Box>
            <Autocomplete
              multiple
              limitTags={2}
              getLimitTagsText={more => `+${more}`}
              disableCloseOnSelect
              defaultValue={info.defaulOptValue}
              options={info.dataOptions}
              getOptionLabel={option => option.label.toString()}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      size="small"
                      color="primary"
                      label={option.label}
                    />
                  );
                })
              }
              popupIcon={
                <ExpandMoreRoundedIcon
                  style={{ color: "#808080", fontSize: 18 }}
                />
              }
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 5 }}
                    checked={selected}
                  />
                  <Typography className={classes.textStyle}>
                    {option.label}
                  </Typography>
                </Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={filterValues[0][info.fieldName]?.length ? "" : info.placeholder}
                  className={classes.text_field_select_filter}
                  onChange={e => {
                    if (info.fieldName === "pickups")
                      setPickupKeyword(e.target.value);
                    else if (info.fieldName === "dropoffs")
                      setDropoffKeyword(e.target.value);
                  }}
                />
              )}
              disableClearable
              value={info.value}
              onChange={(event, value) => {
                onChangeFilterHandler(event, value, info.fieldName);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  });

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationDialog
          toggle={isDeleteModalOpen}
          title={`Delete Saved Filter?`}
          content={`Are you sure you want to delete ${removedFilterRef.current.label}?`}
          fn={deleteFilterHandler}
          close={closeDeleteModalHandler}
          type="DELETE_FILTER_MODAL"
        />
      )}
      {formOpen && (
        <Form
          closeFormHandler={formHandler}
          toggle={formOpen}
          filters={filterValues[tab]}
          saveFilter={saveFilter}
          tab={tab}
          setFilterValues={setFilterValues}
          resetFilterHandler={resetFilterHandler}
        />
      )}

      <Drawer open={isOpen} onClose={fn} className={classes.filter_container}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography style={{ fontSize: 24 }}>Filter</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.filter_container_box}>
          <div className={classes.filter_header_container}>
            <Typography className={classes.filter_header_container__label}>
              Filter Dashboard
            </Typography>
            <Tooltip placement="bottom" title="Reset Dashboard Filter" arrow>
              <IconButton
                size="small"
                onClick={resetFilterHandler}
                disabled={_.isEqual(initialFilters, filterValues)}
              >
                <ClearAllRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.filter_body_container}>
            <Autocomplete
              options={saveFilter}
              getOptionLabel={option => option.label.toString()}
              defaultValue={{ value: "0", label: "Select Filter" }}
              value={selectedFilter[0]}
              onChange={selectFilterHandler}
              getOptionDisabled={option => option.value === 0}
              getOptionSelected={selected => selected}
              popupIcon={
                <ExpandMoreRoundedIcon
                  style={{ color: "#808080", fontSize: 18 }}
                />
              }
              disableopenonfocus="true"
              renderOption={option => {
                return (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    className={classes.opt_container}
                  >
                    <Typography className={classes.textStyle}>
                      {option.label}
                    </Typography>
                    {option.value !== 0 && (
                      <IconButton
                        edge="end"
                        onClick={e => {
                          //   deleteFilter(e, option.value);
                          openDeleteModalHandler(e, option);
                        }}
                        size="small"
                      >
                        <RemoveRoundedIcon
                          style={{ color: "red", fontSize: 18 }}
                        />
                      </IconButton>
                    )}
                  </Box>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Saved Filters"
                  placeholder="Select Filter"
                  className={classes.text_field_select_filter}
                />
              )}
              disableClearable
            />
          </div>
          <Box style={{ margin: "2rem 0" }}>{AutoCompleteUI}</Box>
          {AutoCompleteStatusUI}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            className={classes.apply_btn}
            disabled={_.isEqual(initialFilters, filterValues)}
            onClick={applyFilterHandler}
          >
            Apply
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            disableElevation
            className={classes.save_btn}
            disabled={_.isEqual(initialFilters, filterValues)}
            onClick={formHandler}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default Filter;

const useStyles = makeStyles(theme => ({
  filter_container: {
    "& .MuiDrawer-paper": {
      width: "23%"
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDrawer-paper": {
        width: "90%"
      }
    }
  },
  filter_header_container: {
    padding: "0 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  filter_header_container__label: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600
  },
  filter_body_container: {
    padding: "0.5rem"
  },
  textStyle: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  opt_container: {},
  text_field_select_filter: {
    color: theme.palette.primary.dark,
    fontSize: 14,
    "& .MuiAutocomplete-inputRoot": {
      fontSize: 13,
      color: theme.palette.primary.dark
    }
  },
  label_autocomplete: {
    fontSize: 12,
    color: theme.palette.primary.dark,
  },
  filter_container_box: {
    marginTop: "0.5rem",
    padding: "1rem",
    overflowY: "auto",
    height: "100%",
    transition: "0.5s",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  },
  apply_btn: {
    borderRadius: 30,
    textTransform: "none",
    marginTop: "1rem",
    opacity: 1,
    transition: "0.3s ease-in",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7
    }
  },
  save_btn: {
    borderRadius: 30,
    textTransform: "none",
    margin: "0.5rem 0",
    opacity: 1,
    transition: "0.3s ease-in",
    "&:hover": {
      opacity: 0.7
    }
  },
  calendar_icon: {
    cursor: "pointer",
    fontSize: 18,
    color: theme.palette.primary.dark,
    transition: "0.3s",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  calendar_icon_active: {
    cursor: "pointer",
    fontSize: 18,
    color: theme.palette.primary.main,
    transition: "0.3s",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  calendarInput: {
    display: "none"
  }
}));
