import React from "react";
import { withRouter } from "react-router-dom";
import AutomatedDemo from "../../../utils/Onboarding/modules/Reports/Automated";
import Automated from "./index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../../utils/Onboarding";

const Main = () => {
  const { progress } = useOnboardingContext({
    module: Onboarding.modules.reports,
    allowContextToChange: true,
    stepStartsIn: 7
  });
  const reportsProgress = progress[Onboarding.modules.reports];
  const isDemoCompleted =
    reportsProgress >= onboarding_max[Onboarding.modules.reports];
  return isDemoCompleted ? <Automated /> : <AutomatedDemo />;
};

export default withRouter(Main);
