import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "25px",
    minWidth: "25%",
    margin: "0 15px"
  },
  no_button: {
    borderRadius: "25px",
    minWidth: "25%",
    margin: "0 15px",
    color: "red",
    borderColor: "red"
  }
}));
const ConfirmationDialog = props => {
  const { toggle, title, content, fn, close } = props;
  const classes = useStyles();
  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.no_button}
            variant="outlined"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => {
              fn();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
