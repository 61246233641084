import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Person as PersonIcon,
  AssignmentInd as NameIcon,
  Call as CallIcon,
  Lock as LockIcon,
  Email as EmailIcon,
  VisibilityOff as VisibilityIcon,
  Visibility as VisibilityOffIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import background from "../../../assets/Background-with-Text.png";
// import LoginLogo from "../../../assets/Login logo.png";
import LoginLogo from "../../../assets/Seal-Original_4x_1.ico";

import validate from "../../../utils/validation";
import { SIGNUP_CLIENT_USER } from "../../../graphql/Mutations";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),

      "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
        color: "red"
      }
    }
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  }
}));
export default function Signup(props) {
  const { history } = props;
  const classes = useStyles();
  const [password, setPassword] = useState({
    value: "",
    visibility: false
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    visibility: false
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    username: "",
    contact_number: "",
    password: "",
    confirm_password: "",
    email_address: "",
    general: ""
  });
  const [tempAddData, setTempAddData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    contact_number: "",
    password: "",
    confirm_password: "",
    email_address: "",
    general: ""
  });

  const areRequiredFieldsFilled = [
    tempAddData.first_name,
    tempAddData.last_name,
    tempAddData.username,
    tempAddData.contact_number,
    tempAddData.password,
    tempAddData.confirm_password,
    tempAddData.email_address
  ].some(e => e === "" || e === null || e === " " || e === "0" || e === 0);

  const withErrors = [
    errors.first_name,
    errors.last_name,
    errors.username,
    errors.contact_number,
    errors.password,
    errors.confirm_password,
    errors.email_address,
    errors.general
  ].some(e => e !== "");
  const handleAddValues = e => {
    const { name, value } = e.target;
    let errorMessage;
    switch (name) {
      case "first_name":
        errorMessage = validate("letters", value) ? "" : "Invalid Format";
        break;
      case "last_name":
        errorMessage = validate("letters", value) ? "" : "Invalid Format";
        break;
      case "username":
        errorMessage = validate("alphanumeric", value) ? "" : "Invalid Format";
        break;
      case "contact_number":
        errorMessage = validate("numeric", value) ? "" : "Invalid Format";
        break;
      case "password":
        // errorMessage = validate("alphanumeric", value)
        //   ? ""
        //   : "Alphanumeric characters only";
        errorMessage = value.length >= 8 ? "" : "Minimum of 8 Characters";
        break;
      case "confirm_password":
        errorMessage =
          tempAddData.password === value ? "" : "Passwords do not match";
        break;
      case "email_address":
        errorMessage = validate("email", value) ? "" : "Invalid Format";
        break;
      default:
        break;
    }

    if (name === "password") {
      setTempAddData({
        ...tempAddData,
        [name]: value,
        confirm_password: ""
      });
    } else {
      setTempAddData({
        ...tempAddData,
        [name]: value
      });
    }
    setErrors({
      ...errors,
      [name]: errorMessage,
      general: ""
    });
    if (!withErrors) {
      setDisableSubmit(false);
    }
  };

  const [signup] = useMutation(SIGNUP_CLIENT_USER, {
    onCompleted: data => {
      const { signup_client_user } = data;
      if (signup_client_user) {
        const { success, error } = signup_client_user;
        if (success === true) {
          Swal.fire({
            icon: "success",
            // title: "Success",
            text: "Add successful",
            timer: 3000,
            onClose: () => {
              history.push({
                pathname: "/",
                state: {
                  params: { moduleName: process.env.REACT_APP_CLIENTS_MODULE }
                }
              });
            }
          });
        } else {
          const errorList = {};
          for (let index = 0; index < error.length; index += 1) {
            const element = error[index];
            const { field, message } = element;
            errorList[field] = message;
          }
          setErrors(errorList);
        }
        setDisableSubmit(false);
      } else {
        Swal.fire({
          icon: "error",
          // title: "Success",
          text: "Something went wrong"
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);

      // Swal.fire({
      //   icon: "error",
      //   // title: "Success",
      //   text: "Something went wrong"
      // });
    }
  });
  return (
    <>
      <Grid container style={{ height: "100vh" }}>
        <Hidden smDown>
          <Grid
            item
            lg={6}
            style={{
              height: "100vh",
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              position: "relative"
            }}
          />
        </Hidden>
        <Grid item lg={6} xs={12} style={{ marginTop: "30px" }}>
          <Typography align="center">
            <img alt="" src={LoginLogo} style={{ height: 95 }} />
          </Typography>

          <Typography align="center" variant="h4" color="primary">
            Sign Up
          </Typography>

          <Container
            style={{ paddingLeft: 80, paddingRight: 80, marginTop: 20 }}
          >
            <form
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="firstName"
                    name="first_name"
                    placeholder="Enter first name *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <NameIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />

                  {errors.first_name && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.first_name}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="last_name"
                    id="filled-required"
                    placeholder="Enter last name *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <NameIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.last_name && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.last_name}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="username"
                    id="filled-required"
                    placeholder="Enter username *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.username && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.username}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder="Enter your password *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    name="password"
                    type={!password.visibility ? `password` : `text`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setPassword({
                                ...password,
                                visibility: !password.visibility
                              })
                            }
                          >
                            {!password.visibility ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.password && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.password}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="email_address"
                    id="filled-required"
                    placeholder="Enter email address *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.email_address && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.email_address}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder="Confirm your password *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    name="confirm_password"
                    value={tempAddData.confirm_password}
                    type={!confirmPassword.visibility ? `password` : `text`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setConfirmPassword({
                                ...confirmPassword,
                                visibility: !confirmPassword.visibility
                              })
                            }
                          >
                            {!confirmPassword.visibility ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.confirm_password && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.confirm_password}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="contact_number"
                    id="filled-required"
                    type="number"
                    placeholder="Enter contact number *"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      inputProps: {
                        step: 1,
                        min: 0
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <CallIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.contact_number && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.contact_number}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {errors.general && (
                    <Alert
                      severity="error"
                      style={{ justifyContent: "center" }}
                      className={classes.alert}
                    >
                      {errors.general}
                    </Alert>
                  )}
                  <Button
                    fullWidth
                    type="submit"
                    disabled={disableSubmit}
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      if (areRequiredFieldsFilled) {
                        setErrors({
                          ...errors,
                          general: "Please fill all required fields!"
                        });
                        setDisableSubmit(true);
                      } else if (
                        tempAddData.password !== tempAddData.confirm_password
                      ) {
                        setDisableSubmit(true);
                        setErrors({
                          ...errors,
                          general: "Passwords do not match"
                        });
                      } else {
                        signup({
                          variables: {
                            username: tempAddData.username,
                            password: tempAddData.password,
                            first_name: tempAddData.first_name,
                            last_name: tempAddData.last_name,
                            email_address: tempAddData.email_address,
                            contact_number: tempAddData.contact_number
                          }
                        });
                      }
                      return true;
                    }}
                  >
                    CREATE USER
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
