/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stepper,
  Slide,
  Box
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_BOOKINGS } from "../../../graphql/Queries";

import isWithinRange from "./helpers/isWithinRange";

import TripDetails from "./TripDetails";
import StopComponent from "./Stops/StopComponent";

import { useStyles, loadmoreStyles } from "./Itinerary.styles";
import noDataLogo from "../../../assets/No Data.png";

const shouldDisplayLoadMore = n => {
  let display = false;
  switch (n) {
    case 1:
    case 24:
      display = true;
      break;
    default:
      break;
  }
  return display;
};

const validLoadMoreRange = (d1, d2) => {
  return !_.isEqual(d1, d2);
};

const LoadMore = prop => {
  const styles = loadmoreStyles();
  const {
    historyFilterVal: { dayType },
    date,
    setDate
  } = prop;

  const [validRange, setValidRange] = useState(true);

  useEffect(() => {
    setValidRange(
      validLoadMoreRange(
        moment(date.start).format("YYYY-MM-DD"),
        moment(date.end).format("YYYY-MM-DD")
      ) &&
        (dayType === 24
          ? validLoadMoreRange(
              moment(date.end).format("YYYY-MM-DD HH:mm:ss"),
              // moment(date?.selectedRange || date.start).format(
              moment(date.selectedRange).format("YYYY-MM-DD HH:mm:ss")
            )
          : validLoadMoreRange(
              moment(date.end).format("YYYY-MM-DD"),
              // moment(date?.selectedRange || date.start).format("YYYY-MM-DD")
              moment(date.selectedRange).format("YYYY-MM-DD")
            ))
    );
  }, []);

  const onRefetchTimeline = () => {
    if (dayType === 24) {
      const selectedRange = date.end;

      setDate(d => ({
        ...d,
        selectedRange
      }));
    }

    if (dayType === 1) {
      const selectedRange = moment(date.selectedRange)
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss");

      setDate(d => ({
        ...d,
        selectedRange
      }));
    }
  };

  const shouldDisplay = shouldDisplayLoadMore(dayType);

  if (shouldDisplay && validRange) {
    return (
      <div className={styles.root} onClick={onRefetchTimeline} aria-hidden>
        <Box fontWeight="fontWeightMedium" fontSize={12}>
          Load more
        </Box>
      </div>
    );
  }
  return null;
};

const LoadingHistory = () => {
  return (
    <Grid
      container
      style={{
        position: "relative",
        height: "80%",
        maxHeight: "80%"
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body1">Loading Vehicle History...</Typography>
    </Grid>
  );
};

const Itinerary = React.memo(props => {
  const classes = useStyles();
  const {
    date,
    itinerary,
    expandAllAlerts,
    deviceId,
    historyFilterVal,
    setDate,
    setExpandAllAlerts,
    tripNumber,
    showLogs,
    bookingInfo,
    vehicleData,
    categories,
    geoFilter,
    primaryData,
    isCICO
  } = props;

  const parsedItinerary = itinerary.reduce((accumulator, currentValue) => {
    const { Entered, Left, geofenceName } = currentValue;
    if (isWithinRange(date, Entered) || isWithinRange(date, Left)) {
      if (bookingInfo) {
        if (bookingInfo.geofences?.includes(geofenceName)) {
          return [...accumulator, currentValue];
        }
        return accumulator;
      }
      return [...accumulator, currentValue];
    }

    return accumulator;
  }, []);

  function getCondition() {
    if (tripNumber === null) {
      return [
        {
          field: "vehicle_id",
          value: deviceId
        }
      ];
    }

    return [
      {
        field: "vehicle_id",
        value: deviceId
      },
      {
        field: "trip_number",
        value: tripNumber
      }
    ];
  }

  const { data: bookings_data, loading: isBookingsLoading } = useQuery(
    GET_BOOKINGS,
    {
      variables: {
        first: 0,
        dateRange: {
          start: date.start,
          end: date.end,
          field: "pickups.actual_arrival"
        },
        condition: getCondition()
      },
      skip: !deviceId
    }
  );

  const [stops, setStops] = useState([]);

  useEffect(() => {
    if (!isBookingsLoading && bookings_data) {
      const { bookings } = bookings_data.get_bookings;
      const tempGeofences = [];
      bookings.forEach(booking => {
        const {
          pickups,
          dropoffs,
          trip_number,
          so_number,
          service_type
        } = booking;
        tempGeofences.push({
          geofenceId: pickups[0].geofence_id,
          geofenceTripNumber: trip_number,
          so_number,
          service_type,
          actual_arrival: pickups[0].actual_arrival,
          actual_departure: pickups[0].actual_departure
        });
        tempGeofences.push({
          geofenceId: dropoffs[0].geofence_id,
          geofenceTripNumber: trip_number,
          so_number,
          service_type,
          actual_arrival: dropoffs[0].actual_arrival,
          actual_departure: dropoffs[0].actual_departure
        });
      });

      setStops(_.uniqWith(tempGeofences, _.isEqual));
    }
  }, [bookings_data]);

  // This function will process Pre-Trip and In Transit stops
  const getItinerary = () => {
    const tempArray = _.sortBy(parsedItinerary, ["Entered", "Left"]);
    let dateStart = moment(date.start).format("YYYY-MM-DD HH:mm:ss");
    let dateEnd = moment(date.end).format("YYYY-MM-DD HH:mm:ss");
    if (!isCICO) {
      dateStart = moment(date.start).subtract(8, "hours").format("YYYY-MM-DD HH:mm:ss");
      dateEnd = moment(date.end).subtract(8, "hours").format("YYYY-MM-DD HH:mm:ss");
    }

    // If no geofence is created, add a stop that will cover whole date range
    if (!tempArray.length) {
      // TO DO: improve UI, how to align label content to top, not center
      return [
        {
          Entered: dateStart,
          Left: dateEnd,
          geofenceCategory: "Others",
          geofenceId: "",
          geofenceName: "Initial"
        }
      ];
    }

    // Process In Transit stop
    const parsedItinWithInTransit = [];
    for (let index = 0; index < tempArray.length; index++) {
      const itin = tempArray[index];
      const next = tempArray[index + 1];
      parsedItinWithInTransit.push(itin);

      // If current itin.Entered has earlier date than next.Entered, create In Transit stop
      // ActualEntered -> get_fuel_history adjustment; Entered -> get_trip_log
      if (
        next &&
        // moment(itin.Left).format("YYYY") !== "1111" &&
        itin.Entered < next.Entered
      ) {
        parsedItinWithInTransit.push({
          Entered: itin.Left,
          ActualEntered: itin.Entered,
          Left: next.Entered,
          geofenceCategory: "Others",
          geofenceId: "",
          geofenceName: "In Transit"
        });
      }
    }

    if (!tempArray.length) return [];

    // Process Pre-Trip stop
    const preTrip = [];
    if (bookingInfo) {
      const { dispatch_time, arrival_time } = bookingInfo;
      // Pre-trip stop will be displayed if there is selected trip number,
      // And if dispatch_time has earlier date than arrival_time (first stop)
      if (
        tripNumber &&
        dispatch_time &&
        arrival_time &&
        moment(dispatch_time).isBefore(arrival_time)
      ) {
        preTrip.push({
          Entered:
            dispatch_time &&
            moment(dispatch_time)
              .subtract(8, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          Left:
            arrival_time &&
            moment(arrival_time)
              .subtract(8, "hours")
              .subtract(1, "seconds")
              .format("YYYY-MM-DD HH:mm:ss"),
          geofenceCategory: "Others",
          geofenceId: "",
          geofenceName: "Pre - Trip"
        });
      }
    }

    // Process initialStop, before Pre-Trip stop
    const startItin = [];
    if (
      !bookingInfo &&
      parsedItinWithInTransit.length &&
      parsedItinWithInTransit[0].Entered
    ) {
      startItin.push({
        Entered: dateStart,
        Left: moment(parsedItinWithInTransit[0].Entered)
          .subtract(1, "seconds")
          .format("YYYY-MM-DD HH:mm:ss"),
        geofenceCategory: "Others",
        geofenceId: "",
        geofenceName: "Initial"
      });
    }

    // Process last stop, after last geofence
    const lastItin = [];
    const itinCount = parsedItinWithInTransit.length;
    if (
      !bookingInfo &&
      itinCount > 0 &&
      parsedItinWithInTransit[itinCount - 1].Left &&
      moment(parsedItinWithInTransit[itinCount - 1].Left).isBefore(dateEnd)
    ) {
      lastItin.push({
        Entered: moment(parsedItinWithInTransit[itinCount - 1].Left)
          .add(1, "seconds")
          .format("YYYY-MM-DD HH:mm:ss"),
        Left: dateEnd,
        geofenceCategory: "Others",
        geofenceId: "",
        geofenceName: "Last"
      });
    }

    return [...startItin, ...preTrip, ...parsedItinWithInTransit, ...lastItin];
  };

  const getFilteredItin = () => {
    const filteredByCat = newItinerary.filter(itin => showStop(itin));
    if (geoFilter.values?.length) {
      return filteredByCat.filter(itin => showStopByName(itin));
    }
    return filteredByCat;
  };

  const showStopByName = itin => {
    // geofence name/code filter value
    const type = geoFilter.type?.split(" ")[1].toLowerCase();
    const values = geoFilter.values.map(item => item.name);

    // stop's name/code
    const itinGeo = _.isEqual(type, "name")
      ? itin.geofenceName.replace(itin.code, "").replace(/^\s+-\s*|\s+$/, "")
      : itin.code;

    const showAll = !geoFilter.values.length;
    if (showAll) return true;
    return values?.includes(itinGeo);
  };

  const showStop = itin => {
    const showAll = categories.every(cat => cat.isShown);
    const selectedCategories = categories
      .filter(cat => cat.isShown)
      .map(cat => cat.label);
    if (showAll) return true;
    // exclude Pre - trip, Initial, In transit, and Last stops when category is others
    if (
      ["Pre - Trip", "In Transit", "Initial", "Last"].includes(
        itin.geofenceName
      )
    )
      return showAll;
    return selectedCategories.includes(itin.geofenceCategory);
  };

  const [newItinerary, setNewItinerary] = useState([]);

  useEffect(() => {
    setNewItinerary(getItinerary());
  }, [itinerary]);

  const resetPanels = () => {
    let panels = {};
    newItinerary.forEach((_item, index) => {
      panels = { ...panels, [index]: expandAllAlerts };
    });
    return panels;
  };

  const [selectedTrip, setSelectedTrip] = useState(null);
  const [expandedPanels, setExpandedPanels] = useState(() => resetPanels());

  useEffect(() => {
    setExpandedPanels(resetPanels());
  }, [expandAllAlerts, categories]);

  useEffect(() => {
    const panelCount = newItinerary.length;
    const expandedPanelCount = Object.values(expandedPanels).filter(
      value => value
    ).length;
    const isToggle = panelCount === expandedPanelCount;
    if (isToggle) {
      setExpandAllAlerts(true);
    }
    if (expandedPanelCount === 0) {
      setExpandAllAlerts(false);
    }
  }, [expandedPanels]);

  const handleSelectedTrip = tripData => {
    setSelectedTrip(tripData);
  };

  const toggleAccordion = index => {
    setExpandedPanels(panels => ({
      ...panels,
      [index]: !expandedPanels[index]
    }));
  };

  if (isBookingsLoading) {
    return <LoadingHistory />;
  }

  return (
    <Grid
      container
      style={{
        position: "relative",
        height: "100%",
        maxHeight: "100%"
      }}
    >
      <Grid
        item
        md={12}
        style={{
          minHeight: "100%",
          maxHeight: "100%",
          overflow: "hidden"
        }}
        container
        direction="row"
      >
        <Grid
          style={{
            minHeight: "100%",
            maxHeight: "100%",
            overflowY: "auto"
          }}
          item
          md={selectedTrip !== null ? 9 : 12}
        >
          {newItinerary.length > 0 ? (
            <Stepper
              className={classes.stepperRoot}
              orientation="vertical"
              style={{
                position: "relative",
                height: "100%",
                padding: "12px 24px"
              }}
            >
              {getFilteredItin().length > 0 ? (
                getFilteredItin().map((itin, index) => (
                  (
                    <StopComponent
                      active
                      key={`${itin.geofenceName}-${itin.Entered}-${index}`}
                      itin={itin}
                      index={index}
                      handleSelectedTrip={handleSelectedTrip}
                      stops={stops}
                      expandedPanels={expandedPanels}
                      deviceId={deviceId}
                      date={date}
                      vehicleData={vehicleData}
                      bookingInfo={bookingInfo}
                      showLogs={showLogs}
                      tripNumber={tripNumber}
                      toggleAccordion={() => toggleAccordion(index)}
                      isCICO={isCICO}
                      primaryData={primaryData}
                    />
                  )
                /* eslint-disable react/no-array-index-key */
                // <Step active key={`${itin.geofenceName}-${index}`}>
                //   <StepLabel
                //     icon={<FiberManualRecordIcon />}
                //     style={{
                //       color: getCategoryColor(
                //         itin?.geofenceCategory?.toLowerCase()
                //       )
                //     }}
                //   >
                //     {!["Initial", "Last"].includes(itin.geofenceName) ? (
                //       <>
                //         <Typography
                //           variant="subtitle2"
                //           style={{ letterSpacing: 1.2 }}
                //         >
                //           <strong>{itin.geofenceName}</strong>
                //         </Typography>
                //         <Typography variant="caption">
                //           {itin.geofenceAddress || <br />}
                //         </Typography>
                //       </>
                //     ) : (
                //       <AccordionSummary
                //         key={`summary-${index}`}
                //         expandIcon={<ExpandMoreIcon />}
                //         aria-controls={`panel${index}a-content`}
                //         id={`panel${index}a-header`}
                //       >
                //         <Typography className={classes.heading}>
                //           {expandedPanels[index] ? "Hide Activity" : "Show Activity"}
                //         </Typography>
                //       </AccordionSummary>
                //     )}
                //   </StepLabel>
                //   <StepContent>
                //     <TripLine
                //       handleSelectedTrip={handleSelectedTrip}
                //       start_odometer_reading={itin?.entered || 0}
                //       end_odometer_reading={itin?.left || 0}
                //       // Band aid solution [start]
                //       stop={stops.filter(
                //         stop =>
                //           stop.geofenceId === itin.geofenceId &&
                //           identifyDates(
                //             moment(stop.actual_arrival).format(
                //               "YYYY-MM-DD HH:mm:ss"
                //             ),
                //             moment(itin?.Entered)
                //               .add(8, "hours")
                //               .format("YYYY-MM-DD HH:mm:ss")
                //           ) &&
                //           identifyDates(
                //             moment(stop.actual_departure).format(
                //               "YYYY-MM-DD HH:mm:ss"
                //             ),
                //             moment(itin?.Left)
                //               .add(8, "hours")
                //               .format("YYYY-MM-DD HH:mm:ss")
                //           )
                //       )}
                //       // Band aid solution [end]
                //     />
                //     {!["Pre - Trip", "Initial", "Last"].includes(
                //       itin.geofenceName
                //     ) && (
                //       <StopDetails
                //         itin={itin}
                //         deviceId={deviceId}
                //         date={date}
                //         geofenceName={itin.geofenceName}
                //         vehicleData={vehicleData.get_vehicle}
                //       />
                //     )}
                //     {/* {getActivities(itin, index)} */}
                //     {!["Initial", "Last"].includes(itin.geofenceName) && getActivities(itin, index)}
                //   </StepContent>
                // </Step>
                /* eslint-enable react/no-array-index-key */
                ))
              ) : (
                <div className={classes.emptyData}>
                  <img src={noDataLogo} alt="" />
                  <Typography>No data found</Typography>
                </div>
              )}
            </Stepper>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
              }}
            >
              <Typography variant="caption">No Vehicle History</Typography>
            </div>
          )}
        </Grid>

        <Grid
          item
          md={selectedTrip !== null ? 3 : false}
          style={{
            minHeight: "100%",
            maxHeight: "100%"
          }}
          container
          direction="column"
        >
          <Slide
            in={selectedTrip !== null}
            direction="left"
            style={{ height: "100%" }}
          >
            <div style={{ height: "100%" }}>
              {selectedTrip && (
                <TripDetails
                  selectedTrip={selectedTrip}
                  handleSelectedTrip={handleSelectedTrip}
                />
              )}
            </div>
          </Slide>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <LoadMore
          historyFilterVal={historyFilterVal}
          setDate={setDate}
          date={date}
        />
      </Grid>
    </Grid>
  );
});

export default Itinerary;
