/* eslint-disable no-nested-ternary */
/* global google */
import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Polygon,
  StreetViewPanorama
} from "@react-google-maps/api";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
// import wkt from "wkt";
import _ from "lodash";
import moment from "moment";
import Loading from "../../../utils/Loading";
import {
  GET_GEOFENCES,
  GET_VEHICLE_INFOS,
  GET_INFO_BUBBLE
} from "../../../graphql/Queries";
import { VEHICLE_SUBSCRIPTION } from "../../../graphql/Subscription";
import SearchOverlay from "./SearchOverlay";
import FilterOverlay from "./FilterOverlay";
import AddLogs from "../../../utils/functions/AddLogs";
import CustomMarker from "../../../utils/Map/Marker/CustomMarker";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../assets/SvgIcons/SvgIcons";
import InfoBubble from "../MapPartials/CustomInfoBubble.js/InfoBubble";
import greenPulseImg from "../../../assets/Images/Indicators/blinking-green.gif";
import redPulseImg from "../../../assets/Images/Indicators/blinking-red.gif";
import yellowPulseImg from "../../../assets/Images/Indicators/blinking-yellow.gif";
import { getGeofenceTypeColor } from "../../Utils";
import { FilterControls } from "../MapPartials/FilterControl";
import BlinkingAlertsLegend from "../../../utils/BlinkingAlertsLegend";
import useUserContext from "../../../context/User/useUserContext";
import { wktToCoordinates } from "../../../utils/functions/coordinatesParserV2";

const pulseImages = {
  3: redPulseImg,
  2: yellowPulseImg,
  1: greenPulseImg
};

const libraries = ["geometry", "drawing", "places"];

const VehicleMarker = props => {
  const { default_icon } = SvgIcons;
  const {
    zoom,
    streetViewHandler,
    filterVal,
    vars,
    history,
    vehicleData
  } = props;

  // fetch info bubble restrictions.
  const { data: infoBubbleRestrictionData } = useQuery(GET_INFO_BUBBLE, {
    // variables: { client_id: data?.client_id },
    variables: { client_id: +vehicleData.client_id },
    fetchPolicy: "cache-and-network"
  });

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState({
    display: false,
    vehicleInfo: {}
  });

  // show and configure modal for history modal.
  const toggleInfoWindow = () => {
    // setActiveVId(data);
    setIsInfoVisible(prev => !prev);
  };
  // set position for custom marker.
  const positionHandler = () => {
    return {
      lat: vehicleData.device_info?.device_status.latitude,
      lng: vehicleData.device_info?.device_status.longitude
    };
  };
  // history modal handler.
  const historyHandler = () => {
    setShowHistoryModal({
      display: !showHistoryModal.display,
      vehicleInfo: {
        vehicle_id: vehicleData.id,
        client_id: vehicleData.client_id,
        device_id: vehicleData.device_info?.id
      }
    });
  };

  const isLBSVehicle = vehicleData.device_info?.device_status?.AlertReferences?.some(
    alert => alert.alert_code === "LBS DATA"
  );

  const Icon =
    SvgIcons[
      isLBSVehicle
        ? "LBS_vehicle"
        : vehicleData.icon.split(".")[0]
        ? vehicleData.icon.split(".")[0]
        : "default_icon"
    ];

  const currentAlert = vehicleData.device_info?.device_status?.AlertReferences?.reduce(
    (accum, alert) => {
      if (alert.level > accum) return alert.level;
      return accum;
    },
    0
  );

  const powersource = vehicleData.device_info?.device_status?.powersource;
  const ignition_status = vehicleData?.ignition_status;
  const position = {
    lat: vehicleData.device_info?.device_status.latitude,
    lng: vehicleData.device_info?.device_status.longitude
  };

  return (
    <React.Fragment>
      <Marker
        key={`id-${vehicleData.id}`}
        onClick={() => {
          toggleInfoWindow();
          AddLogs(
            process.env.REACT_APP_FLEET_MODULE,
            "view_vehicle",
            vehicleData.plateno
          );
        }}
        position={position}
        options={{
          icon: {
            // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
            path: Icon.path,
            fillOpacity: 1,
            rotation: isLBSVehicle
              ? 0
              : vehicleData?.device_info?.device_status.heading,
            // scaledSize: new google.maps.Size(32, 32),
            scale: 1.2,
            fillColor: isLBSVehicle ? Icon.fill : fillColor[powersource], // Icon.fill,
            strokeColor: isLBSVehicle
              ? Icon.stroke
              : strokeColor[ignition_status], // Icon.stroke,
            strokeWeight: isLBSVehicle ? 1.0 : 1.5,
            // size: new google.maps.Size(22, 17),
            // origin: new google.maps.Point(0, 0),
            // anchor: new google.maps.Point(-5, -2),
            size: new google.maps.Size(22, 17),
            origin: new google.maps.Point(0, 0),
            // anchor: new google.maps.Point(5, 9),
            anchor: isLBSVehicle
              ? new google.maps.Point(14, 15)
              : new google.maps.Point(9, 13)
          },
          zIndex: 2147483647
        }}
      >
        {isInfoVisible && (
          <InfoWindow
            key={vehicleData.id}
            position={{
              lat: vehicleData.device_info?.device_status.latitude,
              lng: vehicleData.device_info?.device_status.longitude
            }}
            onCloseClick={toggleInfoWindow}
          >
            <InfoBubble
              componentType="Isolate"
              vehicleInfo={vehicleData}
              historyHandler={historyHandler}
              isOpenHistoryModal={showHistoryModal}
              streetViewHandler={streetViewHandler}
              infoBubble={infoBubbleRestrictionData?.get_info_bubble}
              filterVal={filterVal}
              vars={vars}
              history={history}
            />
          </InfoWindow>
        )}
        {/* <CustomMarker
          getVehiclePosition={positionHandler}
          vehicleDirection={vehicleData.device_info?.device_status.heading}
          vehiclePlateNo={vehicleData.plateno}
          zoom={zoom}
          isMoving={vehicleData.device_info?.device_status.gpsSpeed}
          alertLevel={
            vehicleData.device_info?.device_status?.AlertReferences?.reduce(
              (accum, alert) => {
                if (alert.level > accum.level) return alert;
                return accum;
              },
              { level: 0 }
            ) || null
          }
          moduleType="ISOLATE_VIEW"
        /> */}
      </Marker>

      {pulseImages[currentAlert] && (
        <Marker
          position={position}
          options={{
            zIndex: -1,
            icon: {
              url: pulseImages[currentAlert],
              scaledSize: new google.maps.Size(45, 45),
              // anchor: new google.maps.Point(18, 20),
              anchor: new google.maps.Point(22, 24),
              opacity: 0.5
            }
          }}
          opacity={0.5}
        />
      )}

      <Marker
        key={vehicleData.id}
        position={{
          lat: vehicleData.device_info?.device_status.latitude,
          lng: vehicleData.device_info?.device_status.longitude
        }}
        options={{
          icon: {
            path: Icon.path,
            fillColor: fillColor[powersource], // Icon.fill,
            fillOpacity: 0,
            scale: 1.2,
            strokeColor: strokeColor[ignition_status], // Icon.stroke,
            strokeWeight: 0,
            size: new google.maps.Size(22, 17),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 9),
            // labelOrigin: new google.maps.Point(8, 48)
            labelOrigin: new google.maps.Point(8, 37)
          },
          label: {
            color: "#3A3A3A",
            fontWeight: "bold",
            text: vehicleData.plateno,
            fontSize: "12px",
            className: "map-label"
          }
        }}
      />
    </React.Fragment>
  );
};

const Geofences = props => {
  const { geofences, loading } = props;
  const [polygonHover, setPolygonHover] = useState({
    id: "",
    isShow: false,
    name: "",
    position: {}
  });

  const coordinatesParser = wkbGeometry => {
    const { google } = window;
    const temp = [];
    // if (!_.isEmpty(wkbGeometry)) {
    const { coordinates } = wkbGeometry;
    // if (type === "polygon") {
    coordinates[0][0].map(coords =>
      temp.push(new google.maps.LatLng(coords[0], coords[1]))
    );
    // } else if (type === "rectangle") {
    //   const coords = coordinates[0];
    //   temp = new google.maps.LatLngBounds(
    //     new google.maps.LatLng(coords[3][1], coords[3][0]),
    //     new google.maps.LatLng(coords[1][1], coords[1][0])
    //   );
    // }
    // }
    return temp;
  };

  const renderGeofences = () => {
    if (!_.isEmpty(geofences)) {
      return geofences.map(geofence => {
        const {
          id,
          type,
          geom,
          // radius,
          // location
          // color,
          opacity,
          category
        } = geofence;

        // const bounds = coordinatesParser(wkt.parse(geom), type);
        const bounds = wktToCoordinates(geom);

        // if (type === "polygon") {
        return (
          <Polygon
            key={`polygon-${id}`}
            paths={bounds}
            options={{
              fillColor: getGeofenceTypeColor(category),
              fillOpacity: 0.5,
              strokeWeight: 2
            }}
            onMouseOver={() => {
              if (id !== polygonHover.id && !polygonHover.isShow) {
                setPolygonHover({
                  isShow: true,
                  name: `${geofence.geofence_code}-${geofence.name}`,
                  position: {
                    lat: geofence.location.lat,
                    lng: geofence.location.lon
                  }
                });
              }
            }}
            onMouseOut={() => {
              setPolygonHover({
                ...polygonHover,
                isShow: false
              });
            }}
          />
        );
        // }
        // if (type === "rectangle") {
        //   return (
        //     <Rectangle
        //       key={`rectangle-${id}`}
        //       bounds={bounds}
        //       options={{
        //         fillColor: "#0080FF",
        //         fillOpacity: 0.5,
        //         strokeWeight: 2
        //       }}
        //       onMouseOver={() => {
        //         if (id !== polygonHover.id && !polygonHover.isShow) {
        //           setPolygonHover({
        //             isShow: true,
        //             name: `${geofence.geofence_code}-${geofence.name}`,
        //             position: {
        //               lat: geofence.location.lat,
        //               lng: geofence.location.lon
        //             }
        //           });
        //         }
        //       }}
        //       onMouseOut={() => {
        //         setPolygonHover({
        //           ...polygonHover,
        //           isShow: false
        //         });
        //       }}
        //     />
        //   );
        // }

        // return (
        //   <Circle
        //     // eslint-disable-next-line react/no-array-index-key
        //     key={`circle-${id}`}
        //     center={{ lat: location.lat, lng: location.lon }}
        //     radius={radius}
        //     options={{
        //       fillColor: "#0080FF",
        //       fillOpacity: 0.5,
        //       strokeWeight: 2
        //     }}
        //     onMouseOver={() => {
        //       if (id !== polygonHover.id && !polygonHover.isShow) {
        //         setPolygonHover({
        //           isShow: true,
        //           name: `${geofence.geofence_code}-${geofence.name}`,
        //           position: {
        //             lat: geofence.location.lat,
        //             lng: geofence.location.lon
        //           }
        //         });
        //       }
        //     }}
        //     onMouseOut={() => {
        //       setPolygonHover({
        //         ...polygonHover,
        //         isShow: false
        //       });
        //     }}
        //   />
        // );
      });
    }
    return null;
  };

  return (
    <>
      {!loading && renderGeofences()}
      {polygonHover.isShow && (
        <InfoWindow position={polygonHover.position}>
          <div
            style={{
              backgroundColor: `white`,
              width: 200
            }}
          >
            {polygonHover.name}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

const MyMapComponent = props => {
  const { paramData, history, vehicleIds } = props;
  const { vehicle_ids } = paramData;
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const mapRef = useRef(null);

  // const [center, setCenter] = useState(null);
  const [center, setCenter] = useState({
    lat: 14.5995,
    lng: 120.9842
  });
  const [destination, setDestination] = useState(null);
  const [fitboundDestination, setFitboundDestination] = useState(null);
  const [geoPath, setGeoPath] = useState(null);
  const [valuePlaces, setValuePlaces] = useState("google");
  const [textValue, setTextValue] = useState("");
  const [vehicle, setVehicle] = useState();
  const [streetViewSelected, setStreetViewSelected] = useState({});
  const [zoom, setZoom] = useState(15);
  const [directions, setDirections] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [filters, setFilters] = useState({
    geofences: false,
    traffic: false,
    road: false
  });
  const lastTextVal = useRef({});

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    channel: `tmsuite-portal-${user.client.name}`,
    version: "3.47",
    libraries
  });
  // const navigationMarker = new window.google.maps.MarkerImage(
  //   navigationIcon,
  //   null /* size is determined at runtime */,
  //   null /* origin is 0,0 */,
  //   null /* anchor is bottom center of the scaled image */,
  //   new window.google.maps.Size(32, 32)
  // );

  const handleDrag = () => {
    const paths = [];
    const aNorth = mapRef.current
      .getBounds()
      .getNorthEast()
      .lat();
    const aEast = mapRef.current
      .getBounds()
      .getNorthEast()
      .lng();
    const aSouth = mapRef.current
      .getBounds()
      .getSouthWest()
      .lat();
    const aWest = mapRef.current
      .getBounds()
      .getSouthWest()
      .lng();
    paths.push(
      { lat: aNorth, lon: aEast },
      { lat: aNorth, lon: aWest },
      { lat: aSouth, lon: aWest },
      { lat: aSouth, lon: aEast },
      { lat: aNorth, lon: aEast }
    );
    const newCenter = mapRef.current.getCenter();
    setGeoPath(paths);
    setCenter({
      lat: newCenter.lat(),
      lng: newCenter.lng()
    });
  };

  const [getGeofences, { loading, data: dataGeo }] = useLazyQuery(
    GET_GEOFENCES,
    {
      fetchPolicy: "cache-first"
    }
  );

  useEffect(() => {
    if (geoPath) {
      let geoVars = {
        first: 100,
        group_ids:
          +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN
            ? []
            : user.client.group_ids
      };

      if (mapRef.current.getZoom() >= 15)
        geoVars = { ...geoVars, bbox: geoPath };
      getGeofences({ variables: geoVars });
    }
  }, [geoPath]);

  // toggle street view in tool.
  const streetViewHandler = vehiclePosition =>
    setStreetViewSelected(vehiclePosition);

  const {
    subscribeToMore,
    loading: dataLoading,
    data: vehiclesData
  } = useQuery(GET_VEHICLE_INFOS, {
    variables: {
      condition: [
        {
          field: "vehicle_info.id",
          value: vehicleIds
          // value: vehicle_ids
        }
      ],
      dateRange: { start: `${moment().format("YYYY-MM-DD")} 00:00:00` }
    },
    fetchPolicy: "cache-and-network"
  });
  const subscribeVehicleLocation = () => {
    const unsubscribe = subscribeToMore({
      document: VEHICLE_SUBSCRIPTION,
      variables: {
        filter: [
          {
            field: "id",
            value: vehicle?.device_id
          }
        ]
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedDevice = subscriptionData.data.device_updated;

        if (
          !lastTextVal.current.value &&
          vehicle.device_id === updatedDevice.id
        ) {
          setCenter({
            lat: updatedDevice.device_status.latitude,
            lng: updatedDevice.device_status.longitude
          });
        }
        if (!_.isEmpty(destination) && !_.isEmpty(fitboundDestination)) {
          setFitboundDestination(null);
        }
        return {
          get_vehicles: {
            ...prev.get_vehicles,
            vehicles: prev.get_vehicles.vehicles.map(v => {
              if (+v.device_id === +updatedDevice.id) {
                const clonedVehicle = _.cloneDeep(v);
                clonedVehicle.device_info.device_status =
                  updatedDevice.device_status;
                return clonedVehicle;
              }
              return v;
            })
          }
        };
      }
    });
    return unsubscribe;
  };

  const handleBoundChange = map => {
    const centerCurrent = map.current.getCenter();
    if (destination && vehicle?.device_info?.device_status) {
      const itemBounds = new window.google.maps.LatLngBounds();
      if (fitboundDestination) {
        const latLng = new window.google.maps.LatLng(
          fitboundDestination.lat,
          fitboundDestination.lng
        );
        itemBounds.extend(latLng);
      }
      const latLng2 = new window.google.maps.LatLng(
        vehicle?.device_info?.device_status?.latitude,
        vehicle?.device_info?.device_status?.longitude
      );
      itemBounds.extend(latLng2);
      map.current.fitBounds(itemBounds);
      setCenter(null);
      // setFitboundDestination(null);
    }
    if (!_.isEmpty(center) && centerCurrent) {
      if (
        centerCurrent.lat() !== center.lat &&
        centerCurrent.lng() !== center.lng
      ) {
        map.current.setCenter({ lat: center.lat, lng: center.lng });
      }
    }
  };

  const handleZoom = () => {
    // setZoom(mapRef.current.getZoom());
    if (!fitboundDestination && mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
    if (filters.geofences && !destination) handleDrag();
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (vehicle) {
      const unsubscribe = subscribeVehicleLocation();
      setCenter({
        lat: vehicle?.device_info?.device_status?.latitude,
        lng: vehicle?.device_info?.device_status?.longitude
      });

      return () => unsubscribe();
    }
  }, [vehicle]);

  useEffect(() => {
    if (textValue && vehicle) {
      AddLogs(process.env.REACT_APP_FLEET_MODULE, "isolate_eta", [
        vehicle.plateno,
        textValue
      ]);
    }
  }, [textValue]);

  useEffect(() => {
    if (
      vehicle &&
      vehicle?.device_info?.device_status?.longitude &&
      vehicle?.device_info?.device_status?.latitude &&
      destination
    ) {
      const { google } = window;
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new google.maps.LatLng(
            vehicle.device_info?.device_status.latitude,
            vehicle.device_info?.device_status.longitude
          ),
          destination: new google.maps.LatLng(destination.lat, destination.lng),
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            if (lastTextVal.current.value && lastTextVal.current.submit) {
              mapRef.current.fitBounds(result.routes[0].bounds);
              lastTextVal.current.submit = false;
            }
          } else {
            Swal.fire({
              title: "Something went wrong",
              text: "Please check your internet",
              icon: "error",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      );
    }
  }, [destination, vehicle]);

  // useEffect(() => {
  //   if (isLoaded && mapRef.current) {
  //     const bounds = new google.maps.LatLngBounds();
  //     vehiclesData &&
  //       vehiclesData.get_vehicles.vehicles.map(data => {
  //         if (data.device_info?.device_status?.latitude && data.device_info?.device_status?.longitude) {
  //           bounds.extend(
  //             new google.maps.LatLng(
  //               data.device_info.device_status.latitude,
  //               data.device_info.device_status.longitude
  //             )
  //           );
  //         }
  //       });

  //     vehiclesData?.get_vehicles.vehicles.length > 1 && mapRef.current.fitBounds(bounds);
  //   }
  // }, [vehiclesData, isLoaded]);

  if (dataLoading) {
    return <Loading />;
  }

  if (isLoaded) {
    const { google } = window;
    const DEFAULT_OPTIONS = {
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.LEFT_BOTTOM,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID
        ]
      }
    };

    return (
      <GoogleMap
        onLoad={map => {
          mapRef.current = map;
          setShowOverlay(true);
        }}
        mapContainerStyle={{
          width: "100%",
          height: "100%"
        }}
        zoom={zoom}
        maxZoom={19}
        onZoomChanged={() => handleZoom()}
        center={center}
        // center={{
        //   lat: center
        //     ? center.lat
        //     : vehiclesData
        //     ? vehiclesData.get_vehicles.vehicles[0].device_info?.device_status
        //         ?.latitude
        //     : 14.5995, // manila latitude
        //   lng: center
        //     ? center.lng
        //     : vehiclesData
        //     ? vehiclesData.get_vehicles.vehicles[0].device_info?.device_status
        //         ?.longitude
        //     : 120.9842 // manila longitude
        // }}
        onDragEnd={() => {
          if (filters.geofences) {
            if (!destination) {
              handleDrag();
            }
          }
        }}
        onProjectionChanged={() => {
          return handleBoundChange(mapRef);
        }}
        options={DEFAULT_OPTIONS}
      >
        {showOverlay && (
          <>
            <FilterOverlay
              geofences={dataGeo?.get_geofences.geofences}
              filters={filters}
              setFilters={setFilters}
              setGeoPath={setGeoPath}
              mapRef={mapRef}
              handleDrag={handleDrag}
            />
            <SearchOverlay
              setCenter={setCenter}
              filters={filters}
              setDestination={setDestination}
              setFitboundDestination={setFitboundDestination}
              dataGeo={dataGeo}
              setValuePlaces={setValuePlaces}
              valuePlaces={valuePlaces}
              setTextValue={setTextValue}
              textValue={textValue}
              handleDrag={handleDrag}
              directions={directions}
              setDirections={setDirections}
              mapRef={mapRef}
              vehicle={vehicle}
              setVehicle={setVehicle}
              vehiclesData={vehiclesData?.get_vehicles?.vehicles}
              lastTextVal={lastTextVal}
            />
            <FilterControls
              mapRef={mapRef}
              position={google.maps.ControlPosition.RIGHT_BOTTOM}
            >
              <BlinkingAlertsLegend showAlerts showOutlines />
            </FilterControls>
          </>
        )}
        {vehiclesData &&
          vehiclesData.get_vehicles.vehicles.map((data, index) => (
            <VehicleMarker
              key={index}
              vehicleData={data}
              zoom={zoom}
              streetViewHandler={streetViewHandler}
              filterVal={paramData?.state?.filterValues}
              vars={paramData?.state?.vars}
              history={history}
            />
          ))}
        {destination && (
          <Marker
            position={{
              lat: destination.lat,
              lng: destination.lng
            }}
            icon="https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png"
          />
        )}
        {filters.geofences && (
          <Geofences
            geofences={dataGeo?.get_geofences.geofences}
            loading={loading}
          />
        )}
        {!_.isEmpty(streetViewSelected) && (
          <StreetViewPanorama
            position={streetViewSelected}
            visible={!_.isEmpty(streetViewSelected)}
            onVisibleChanged={() => setStreetViewSelected({})}
          />
        )}
      </GoogleMap>
    );
  }
};

const MapComponent = props => {
  const { history, location } = props;
  let filterVal = [];
  let varData = [];
  let paramData = [];
  // const {
  //   state,
  //   state: { filterValues, vars, params }
  // } = location;
  // if (state) {
  //   filterVal = filterValues;
  //   varData = vars;
  //   paramData = params;
  // } else {
  //   history.push({
  //     pathname: "/monitoring"
  //   });
  // }

  // use URLSearchParams for looping on a link if any data is included on the link.
  // parse the data on the link.
  // enhance code structure remove unnecessary use of location since we are fetching the data from a new window.
  const queryParams = new URLSearchParams(window.location.search);
  const vehicleIds = JSON.parse(queryParams.get("vehicle_id"));
  // validates if vehicleIds is null then process to monitoring.
  if (!vehicleIds) {
    history.push({
      pathname: "/monitoring"
    });
  }
  const redirectTo = (r, param = {}) => {
    history.push({
      pathname: r,
      state: {
        params: param,
        filterValues: filterVal,
        vars: varData
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ minHeight: 50 }}>
        <Typography variant="h4" style={{ margin: "10px 18px 5px 18px" }}>
          <IconButton onClick={() => redirectTo(`/monitoring/`)}>
            <ArrowBack />
          </IconButton>
          Isolate
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ minHeight: "80.7vh", minWidth: "100%" }}>
        <MyMapComponent
          paramData={paramData}
          history={history}
          vehicleIds={vehicleIds}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(MapComponent);
