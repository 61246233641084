/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useQuery } from "@apollo/client";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  GET_NOTIFICATIONS_PAGE,
  GET_REVERSE_GEOCODE,
  GET_TRIP_LOG_V2
} from "../../../../graphql/Queries";
import useStyles from "../styles";
import { ActivityLogo, AlertLogo } from "./Icons";
import { activityTypes } from "../../../Utils";
import _ from "lodash";
import client from "../../../../Client";
import Loading from "../../../../utils/Loading";

const categoryTypes = {
  alert: activityTypes.find(e => e.type === "Alert").category,
  notif: activityTypes.find(e => e.type === "Notification").category,
  actLogs: activityTypes.find(e => e.type === "Activity Log").category
};

const subtractDate = date => {
  if (date) {
    return moment(date)
      .subtract(8, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
  }
  return null;
};

const Activities = React.memo(props => {
  const {
    entered,
    left,
    deviceId,
    geofenceAddress,
    showLogs,
    geofenceName,
    tripNumber,
    hidden,
    // vehicleData: { id: vehicle_id, plateno },
    primaryData
  } = props;
  const { id: vehicle_id, plateno } = primaryData || {};
  const styles = useStyles();
  const { alert, notif, actLogs } = categoryTypes;
  const [showActivities, setShowActivities] = useState([
    ...alert,
    ...notif,
    ...actLogs
  ]);
  const [activityList, setActivityList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVariables = () => {
    const vars = {
      filter: [],
      or: [
        {
          field: "vehicle_id",
          value: vehicle_id || ""
        },
        {
          field: "plateno.keyword",
          value: plateno || ""
        }
      ]
    };

    const dvar = {
      field: "device_id",
      value: deviceId || ""
    };

    if (vehicle_id || plateno) {
      vars.or.push(dvar)
    } else {
      Object.assign(vars, {
        filter: [dvar]
      })
    }

    return vars;
  };

  const { data: tripData, loading: tripLoading } = useQuery(GET_TRIP_LOG_V2, {
    variables: {
      first: 10000,
      // filter: {
      //   field: "device_id",
      //   value: deviceId
      // },
      // filter: [],
      // or: [
      //   {
      //     field: "vehicle_id",
      //     value: vehicle_id
      //   },
      //   {
      //     field: "plateno.keyword",
      //     value: plateno
      //   }
      // ],
      ...getVariables(),
      dateFilter: {
        field: "datestamp",
        gte: subtractDate(entered || left),
        lte: subtractDate(left || entered)
      },
      not: [
        {
          field: "alert_codes",
          value: "LOCATION"
        }
      ],
      orderBy: [{ field: "datestamp", direction: "desc" }],
      exists: ["alert_msgs"]
    },
    skip: hidden
  });

  const { data: notifData, loading: notifLoading } = useQuery(
    GET_NOTIFICATIONS_PAGE,
    {
      variables: {
        first: 10000,
        filter: [
          {
            field: "vehicle_id",
            value: deviceId
          },
          {
            field: "category.keyword",
            value: [
              // "GPS Alert",
              "Trip Upload",
              "Dwell Time",
              "Trip",
              "User Activity"
            ]
          }
        ],
        dateRange: {
          field: "datestamp",
          start: entered || left,
          end: left || entered
        },
        orderBy: [{ field: "datestamp", direction: "desc" }]
      },
      skip: hidden
    }
  );

  const computeGeos = async (logs) => {
    setLoading(true);
    let addresses = [];
    for (const [i, v] of logs.entries()) {
      // Skip query if stop is In Transit or Pre-Trip, with "left" or "enter" text in notification
      if (v.location && !(["In Transit", "Pre - Trip"].includes(geofenceName) && ["left", "enter"].some(text => v.notification.toLowerCase().includes(text)))) {
        const { data } = await client.query({
          query: GET_REVERSE_GEOCODE,
          variables: {
            latitude: v.location.lat,
            longitude: v.location.lon
          }
        });
        addresses.push({
          id: i,
          name: _.values(data.get_reverse_geocode).join(", ")
        });
      }
    }
    return addresses;
  };

  useEffect(() => {
    if (tripData && notifData) {
      // Convert trip logs similar with notifications data
      const tripList = [];
      tripData.get_trip_log_v2.map(log => {
        if (log.alert_codes.length > 0 && log.alert_codes[0] !== "LOCATION") {
          log.alert_codes.map((a, index) => {
            tripList.push({
              category: "GPS Alert",
              alert_codes: a,
              created: log.created,
              datestamp: moment(log.datestamp).format("YYYY-MM-DD HH:mm:ss"),
              notification: log.alert_msgs[index],
              vehicle_id: log.device_id,
              vehicle_plate_no: log.plateno,
              location: log.location
            });
          });
        }
      });

      // Combine notifications and trip logs
      const tempArray = [
        ...tripList,
        ...notifData.get_notifications.notifications
      ];
      // Sort by datestamp
      const activities = tempArray.sort((a, b) =>
        b.datestamp.localeCompare(a.datestamp)
      );
      // Get first 5 recent activities
      // setActivityList(activities.slice(0, 5));

      if (["In Transit", "Pre - Trip", "Initial", "Last"].includes(geofenceName)) {
        computeGeos(activities.filter(l => showActivities.includes(l?.category))).then(res => {
          if (res) {
            setAddressList(res);
            setLoading(false);
          }
        });
      }
      // Show all activities
      setActivityList(activities);
    }
  }, [tripData, notifData]);

  useEffect(() => {
    if (showLogs) {
      let tempArray = [];
      if (showLogs.alerts) tempArray = [...tempArray, ...alert];
      if (showLogs.notifications) tempArray = [...tempArray, ...notif];
      if (showLogs.activityLogs) tempArray = [...tempArray, ...actLogs];
      if (tempArray !== showActivities) setShowActivities(tempArray);
    }
  }, [showLogs]);

  const getActivityType = category => {
    return activityTypes.find(e => e.category.includes(category));
  };

  const getAddress = idx => {
    if (addressList) {
      return addressList.find(x => x.id === idx)?.name;
    }
    return;
  };

  const getAlertInfo = (log, index) => {
    let showAlert = false;
    const fetchAddress = ["In Transit", "Pre - Trip", "Initial", "Last"].includes(geofenceName);
    
    if (["In Transit", "Pre - Trip"].includes(geofenceName)) {
      // If stop is In Transit or Pre-Trip, show alert if no "left" and "enter" text in notification
      // Notification with "left" or "enter" should be under a geofence
      showAlert = !["left", "enter"].some(text =>
        log.notification?.toLowerCase().includes(text)
      );
    } else {
      // If stop is not In Transit or Pre-Trip, show alert
      showAlert = true;
    }

    if (showAlert) {
      return (
        <Grid className={styles.innerContainer} key={index}>
          <Typography variant="subtitle2" className={styles.logo}>
            <AlertLogo size="16" color={getActivityType(log.category)?.color} />
          </Typography>
          <div>
            <Typography variant="subtitle2" className={styles.alertHeading}>
              {log.notification}
            </Typography>
            <Typography className={styles.alertTime}>
              {log.datestamp &&
                moment(log.datestamp)
                  .add(8, "hours")
                  .format("ll HH:mm")}
            </Typography>
            {!fetchAddress ? (
              <Typography className={styles.alertDetails}>
              { geofenceAddress }
              </Typography>
            ) : (
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Typography className={styles.alertDetails}>
                  { getAddress(index) || 'Loading...' }
                </Typography>
                { !getAddress(index) && (
                  <CircularProgress size={10} />
                )}
              </div>
            )}
          </div>
        </Grid>
      );
    }
  };

  const getNotifInfo = (log, index) => {
    if (tripNumber && log.trip_number && log.trip_number !== tripNumber) return;

    let showNotif = false;
    if (["In Transit", "Pre - Trip"].includes(geofenceName)) {
      // If stop is In Transit or Pre-Trip, show notif if no "arrived" and "completed" text in notification
      // Notification with "arrived" or "completed" should be under a geofence
      showNotif = !["arrived", "completed"].some(text =>
        log.notification?.toLowerCase().includes(text)
      );
    } else {
      // If stop is not In Transit or Pre-Trip, show notif if no "transit" text in notification
      // Notification with "transit" should be under In Transit or Pre-Trip
      showNotif = !log.notification?.toLowerCase().includes("transit");
    }

    if (showNotif) {
      return (
        <Grid className={styles.innerContainer} key={index}>
          <NotificationsIcon
            className={styles.logo}
            style={{ color: getActivityType(log.category)?.color }}
          />
          <div>
            <Typography variant="subtitle2" className={styles.notifHeading}>
              {log.category}
            </Typography>
            <Typography className={styles.alertTime}>
              {log.datestamp &&
                moment(log.datestamp)
                  .add(8, "hours")
                  .format("ll HH:mm")}
            </Typography>
            <Typography className={styles.alertDetails}>
              {log.notification}
            </Typography>
          </div>
        </Grid>
      );
    }
  };

  const getActLogInfo = (log, index) => {
    if (tripNumber && log.trip_number && log.trip_number !== tripNumber) return;

    if (
      [
        "dispatched",
        "closed",
        "completed"
        // "split"
      ].some(text => log.notification?.toLowerCase().includes(text))
    ) {
      return (
        <Grid className={styles.innerContainer} key={index}>
          <Typography variant="subtitle2" className={styles.logo}>
            <ActivityLogo
              size="16"
              color={getActivityType(log.category)?.color}
            />
          </Typography>
          <div>
            <Typography variant="subtitle2" className={styles.activityHeading}>
              {log.category}
            </Typography>
            <Typography className={styles.alertTime}>
              {log.datestamp &&
                moment(log.datestamp)
                  .add(8, "hours")
                  .format("ll HH:mm")}
            </Typography>
            <Typography className={styles.alertDetails}>
              {log.notification}
            </Typography>
          </div>
        </Grid>
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (notifLoading || tripLoading) {
    return <Typography variant="caption">Loading...</Typography>;
  }

  return (
    <Grid className={styles.alertMessagesContainer}>
      {!hidden &&
        activityList.length > 0 &&
        activityList
          .filter(l => showActivities.includes(l?.category))
          .map(
            (log, index) => {
              return (
                <>
                  {getActivityType(log.category)?.type === "Alert" &&
                    getAlertInfo(log, index)}
                  {getActivityType(log.category)?.type === "Notification" &&
                    getNotifInfo(log, index)}
                  {getActivityType(log.category)?.type === "Activity Log" &&
                    getActLogInfo(log, index)}
                </>
              );
            }

            // getActivityType(log.category)?.type === "Alert" ? (
            //   getAlertInfo(log, index)
            // <Grid className={styles.innerContainer} key={index}>
            //   <Typography variant="subtitle2" className={styles.logo}>
            //     <AlertLogo
            //       size="16"
            //       color={getActivityType(log.category)?.color}
            //     />
            //   </Typography>
            //   <div>
            //     <Typography
            //       variant="subtitle2"
            //       className={styles.alertHeading}
            //     >
            //       {log.notification}
            //     </Typography>
            //     <Typography className={styles.alertTime}>
            //       {log.datestamp &&
            //         moment(log.datestamp)
            //           .add(8, "hours")
            //           .format("lll")}
            //     </Typography>
            //     <Typography className={styles.alertDetails}>
            //       {geofenceAddress}
            //     </Typography>
            //   </div>
            // </Grid>
            // ) : (
            // getActivityInfo(log, index)
            // {geofenceName === "In Transit" && log.notification?.toLowerCase().includes("transit") && (
            // <Grid className={styles.innerContainer} key={index}>
            //   {getActivityType(log.category)?.type === "Notification" ? (
            //     <NotificationsIcon
            //       className={styles.logo}
            //       style={{ color: getActivityType(log.category)?.color }}
            //     />
            //   ) : (
            //     <Typography variant="subtitle2" className={styles.logo}>
            //       <ActivityLogo
            //         size="16"
            //         color={getActivityType(log.category)?.color}
            //       />
            //     </Typography>
            //   )}
            //   <div>
            //     <Typography
            //       variant="subtitle2"
            //       className={
            //         getActivityType(log.category)?.type === "Notification"
            //           ? styles.notifHeading
            //           : styles.activityHeading
            //       }
            //     >
            //       {log.category}
            //     </Typography>
            //     <Typography className={styles.alertTime}>
            //       {log.datestamp &&
            //         moment(log.datestamp)
            //           .add(8, "hours")
            //           .format("lll")}
            //     </Typography>
            //     <Typography className={styles.alertDetails}>
            //       {log.notification}
            //     </Typography>
            //   </div>
            // </Grid>
            // )}
            // )
          )
          .reverse()}
    </Grid>
  );
});

export default Activities;
