import React from "react";
import {
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "../ExpansionPanel";
import useUploadStyles from "../upload.styles";
import useGroupSelect from "../../hooks/useGroupSelect";
import { sortByRow } from "..";

const Document = props => {
  const { fileInfo, overrideValidity, activeDocument } = props;
  const { file: fileObj } = fileInfo;
  const { 0: file } = fileObj;
  const classes = useUploadStyles();

  const errorsWithOverride = fileInfo?.errors?.fields?.filter(
    error => error.override
  );

  const {
    selected,
    indeterminate,
    checked,
    selectAll,
    unselectAll,
    toggleSelection
  } = useGroupSelect(errorsWithOverride);

  const handleSelectAll = (event, nextValue) => {
    if (!nextValue) {
      unselectAll();
      return props?.onSelectedChanged?.([]);
    }

    selectAll();
    return props?.onSelectedChanged?.(errorsWithOverride);
  };

  const handleSingleSelect = item => {
    toggleSelection(item, props.onSelectedChanged);
  };

  return (
    <React.Fragment>
      <ExpansionPanel
        classes={{
          root: classes.expansionPanelRoot
        }}
        square
        //   expanded={index === expanded}
        //   onClick={() => handleClick(index)}
      >
        <ExpansionPanelSummary
          style={{
            top: 0,
            left: 0,
            zIndex: 2,
            position: "sticky",
            background: "white"
          }}
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            (fileInfo.status === "Failed" ||
              fileInfo.status === "Partial Success") && <ExpandMoreIcon />
          }
        >
          <Grid container spacing={2} style={{ alignItems: "center", gap: 8 }}>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2">{file.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {(file.size / 1024 / 1024).toFixed(2)}mb
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                minWidth:
                  fileInfo.status === "Partial Success" ||
                  fileInfo.status === "Failed"
                    ? 75
                    : 110
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  letterSpacing: "0.75px"
                }}
                className={`${
                  fileInfo.status === "Partial Success" ||
                  fileInfo.status === "Failed"
                    ? classes.statusFailed
                    : classes.statusSuccessful
                }`}
              >
                {fileInfo.status}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {(fileInfo.status === "Failed" ||
          fileInfo.status === "Partial Success") && (
          <ExpansionPanelDetails
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            {fileInfo.errors.file ? (
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  padding: 8,
                  boxSizing: "border-box",
                  letterSpacing: "0.25px"
                }}
              >
                {fileInfo.errors.file}
              </Typography>
            ) : (
              <Table
                aria-label="simple table"
                style={{ background: "#F1F1F1" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }} align="center">
                      Row
                    </TableCell>
                    <TableCell align="left">Reason</TableCell>
                    <TableCell align="center">
                      {overrideValidity && activeDocument && (
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={checked}
                          indeterminate={indeterminate}
                          onChange={handleSelectAll}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortByRow(fileInfo.errors.fields).map((error, index) => {
                    // const error = fileInfo.errors.fields[errorKey];
                    return (
                      <TableRow key={`${error.row}-${error.field}-${index}`}>
                        <TableCell align="center">
                          <Typography className={classes.typography}>
                            {error.row + 2}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {/* {error?.map(message => (
                                    <Chip
                                      key={message}
                                      label={message}
                                      size="small"
                                      // variant="outlined"
                                      color="primary"
                                      style={{
                                        margin: 2
                                        // background: "#F7C400",
                                        // color: "white"
                                      }}
                                    />
                                  ))} */}

                          <Typography className={classes.typography}>
                            {error.message}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {overrideValidity &&
                          error.override &&
                          activeDocument ? (
                            <Checkbox
                              color="primary"
                              size="small"
                              style={{ padding: 4 }}
                              checked={selected.indexOf(error) !== -1}
                              onChange={() => handleSingleSelect(error)}
                            />
                          ) : (
                            <span style={{ display: "block", height: 28 }} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
      <LinearProgress
        variant="determinate"
        value={fileInfo.progress}
        color="primary"
        // color={index === documents.length - 1 ? "primary" : "secondary"}
      />
    </React.Fragment>
  );
};

export default Document;
