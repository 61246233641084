/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import {
  Typography,
  Paper,
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import SplitTable from "./SplitTable";
import VehicleAssignDialog from "../utils/modal/VehicleAssign";

const SPLIT_COUNT = 2;
const alphabet = "abcdefghijklmnopqrstuvwxyz";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 12,
    width: "max-content",
  },
  headingActive: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 12,
    width: "max-content",
    color: theme.palette.info.main,    
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    height: "85vh",
    padding: "10px 5px"
  },
  expansionPanel: {
    backgroundColor: theme.palette.primary.light,
  },
  btnAssignTruck: {
    backgroundColor: theme.palette.info.main,
    borderRadius: "25px 25px",
    width: 140,
    '&:hover':{
      backgroundColor: theme.palette.info.main,
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  containerBox: {
    alignItems: "center",
    flexWrap: "inherit",
  },
}));

function createData(data) {
  const {
    so_number,
    items,
    arrival,
    do_status,
    trip_number,
    ...do_data
  } = data;
  const { items_qty, dr_count } = items[0] || {};
  return {
    so_number,
    items,
    items_qty,
    dr_count,
    arrival,
    do_status,
    trip_number,
    ...do_data
  };
}

const SplitContainer = props => {
  const classes = useStyles();
  const { parsedTripData, splitInfo } = props;
  const [stops, setStops] = useState([]);
  const [subTrips, setSubTrips] = useState([]);
  const [vehicleModal, toggleVehicleModal] = useState(false);
  const [tripInAction, setTripInAction] = useState(null);

  function openVehicleModal(trip_number) {
    setTripInAction(trip_number);
    toggleVehicleModal(true);
  }

  function closeVehicleModal() {
    toggleVehicleModal(false);
  }

  const handleCheckAll = (event, trip_number) => {
    const data = [...stops];
    data.forEach((el, i) => {
      if (data[i].trip_number === trip_number) {
        data[i].trip_number = "";
      } else if (!data[i].trip_number) {
        data[i].trip_number = trip_number;
      }
    });
    setStops(data);
    props.handleSubTrips(subTrips, data);
  };

  const handleCheckBox = (event, so_number, trip_number) => {
    // stops.map((el, i) => Object.values(el).indexOf(trip_number))
    const data = [...stops];
    const index = _.findIndex(data, stop => {
      return stop.so_number === so_number;
    });
    if (data[index].trip_number === trip_number) {
      data[index].trip_number = "";
    } else {
      data[index].trip_number = trip_number;
    }

    setStops(data);

    props.handleSubTrips(subTrips, data);
  };

  const subTripsHandler = (trip_number, vehicle, forReprocess, message) => {
    const data = [...subTrips];
    const index = _.findIndex(data, subTrip => {
      return subTrip.trip_number === trip_number;
    });
    const trip = data[index];
    const {
      vehicle_id,
      vehicle_plate_no,
      vehicle_type,
      vehicle_type_id
    } = vehicle;
    trip.vehicle_id = vehicle_id;
    trip.vehicle_plate_no = vehicle_plate_no;
    trip.vehicle_type = vehicle_type;
    trip.vehicle_type_id = vehicle_type_id;
    trip.forReprocess = forReprocess;
    trip.trip = vehicle.trip;
    trip.message = message;
    setSubTrips(data);
    props.handleSubTrips(data, stops);
  };

  function createSubTripData(trip_number) {
    const res = [];
    const alpha = alphabet.split("");
    for (let i = 0; i < SPLIT_COUNT; i += 1) {
      const obj = {};
      obj.trip_number = `${trip_number}${alpha[i].toUpperCase()}`;
      obj.vehicle_id = null;
      obj.vehicle_plate_no = null;
      obj.vehicle_type = null;
      obj.vehicle_type_id = null;
      obj.dropoffs = [];
      res.push(obj);
      setSubTrips(res);
    }
  }

  const parseTripData = () => {
    if (parsedTripData) {
      const { raw_dropoffs } = parsedTripData;
      const dropoffArray = [];
      const data = raw_dropoffs.map(el => {
        const r = {};
        r.so_number = el.so_number;
        const items = el.items
          ? el.items.map(item => {
              return {
                name: item.name,
                items_qty: item.qty,
                dr_count: item.dr_count,
                pickup_index: item.pickup_index,
                dropoff_index: item.dropoff_index,
                volume: item.volume,
                weight: item.weight,
                uom_id: item.uom_id,
                uom: item.uom,
                sub_items: item.sub_items,
                reference_no: item.reference_no
              };
            })
          : [];
        r.items = items;
        r.arrival = el.arrival;
        r.destination = el.name;
        r.status = el.status_code;
        r.geofence_id = el.geofence_id;
        r.address = el.address;
        r.actual_arrival = el.actual_arrival;
        r.manual_arrival = el.manual_arrival;
        r.departure = el.departure;
        r.actual_departure = el.actual_departure;
        r.manual_departure = el.manual_departure;
        r.consignee = el.consignee;
        r.contact_no = el.contact_no;
        r.location_details = el.location_details;
        r.so_number = el.so_number;
        r.issue = el.issue;
        r.eta = el.eta;
        r.actual_eta = el.actual_eta;
        r.status_code = el.status_code;
        r.status_code_id = el.status_code_id;
        r.trip_number = "";
        // const parsed = createData(
        //   el.so_number,
        //   items,
        //   el.arrival,
        //   el.status_code,
        //   ""
        // );
        const parsed = createData(r);
        dropoffArray.push(parsed);
        return parsed;
      });

      setStops(data);
      createSubTripData(
        parsedTripData.trip_number || parsedTripData.tripNumber
      );
    }
  };

  useEffect(() => {
    parseTripData();
  }, []);

  return (
    <div id="split-table" className={classes.root}>
      <Paper className={classes.paper}>
        {subTrips.map(trip => {
          return (
            <ExpansionPanel className={classes.expansionPanel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore className={classes.expandIcon}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container className={classes.containerBox}>
                  <Grid item xs={3}>
                    <Typography className={classes.heading}>
                      {trip.trip_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs>
                    <Typography className={
                      !_.isEmpty(trip.vehicle_plate_no)
                      ? classes.headingActive
                      : classes.heading
                    }>
                      {trip.vehicle_plate_no || "No Vehicle Assigned"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="contained"
                      className={classes.btnAssignTruck}
                      onClick={() => openVehicleModal(trip.trip_number)}
                    >
                      Assign Truck
                    </Button>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: "0px" }}>
                <SplitTable
                  rows={stops}
                  trip_number={trip.trip_number}
                  handleCheckBox={handleCheckBox}
                  handleCheckAll={handleCheckAll}
                  subTripsHandler={subTripsHandler}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Paper>
      <VehicleAssignDialog
        toggle={vehicleModal}
        closeVehicleModal={closeVehicleModal}
        splitHandler={subTripsHandler}
        splitTripNumber={tripInAction}
        tripData={props}
        updateSplitVehicleInfo={props.updateVehicleInfo}
        splitInfo={splitInfo}
        subTrips={subTrips}
        moduleName={props.moduleName}
      />
    </div>
  );
};

export default SplitContainer;
