import React, { useContext } from "react";
import { ButtonBase, Typography, Icon } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Map from "../../../../../utils/Map";
import useCardStyles from "./Card.styles";
import SearchContext from "../../../../../context/SearchContext";
import AddLogs from "../../../../../utils/functions/AddLogs";

const Highlight = require("react-highlighter");

const features =
  "menubar=yes,loaction=yes,resizaable=no,scrollbars=yes,status=no,noopener";

const mapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  draggable: false,
  zoomControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  streetViewControl: false,
  keyboardShortcuts: false
};

const Card = ({
  room,
  rooms,
  name,
  position,
  zoom_level,
  onDelete = () => {},
  permissions = {}
}) => {
  const { searchKeyword } = useContext(SearchContext);
  const styles = useCardStyles();
  return (
    <ButtonBase
      className={rooms.find((element) => element.id === room.id) ? styles.selected : styles.unselected}
      onClick={() => {
        window.open(
          `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/view/${room.id}`,
          "_blank",
          features
        );
        AddLogs(
          process.env.REACT_APP_CONTROL_ROOM_MODULE,
          "view",
          name
        );
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant="caption">
            <Highlight search={searchKeyword.value}>
              {name}
            </Highlight>
          </Typography>
          {permissions.edit && (
            <Icon
              size="small"
              className={styles.close_btn}
              onClick={onDelete}
            >
              <Close size="small" className={styles.close_icon} />
            </Icon>
          )}
        </div>
        <div className={styles.map_container}>
          <Map
            zoom={zoom_level}
            mapContainerStyle={{
              width: "100%",
              height: "100%"
            }}
            center={position}
            options={mapOptions}
          />
        </div>
      </div>
    </ButtonBase>
  );
};

export default Card;
