/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Badge,
  Popper,
  ClickAwayListener,
  Grid,
  Fade,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  IconButton
} from "@material-ui/core";
import {
  FilterList,
  ExpandMore,
  MapTwoTone,
  ListTwoTone,
  MoreVert
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import MonitoringTable from "./Table";
// import Filter from "./Filter";
import Pagination from "../../../table/Pagination";
import Focus, { Indicator } from "../../common/Focus";
import Onboarding, { useOnboardingContext } from "../../index";
import Filter from "../../../../components/Admin/Filter";
import useFormStyles from "../../../../styles/useFormStyles";
import fleet_welcome_image from "../../images/fleet-track-your-assets.svg";
import take_a_tour from "../../images/take-a-tour.svg";
import you_are_read from "../../images/you-are-ready.svg";
import Stage from "../../common/Stage/Stage";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  filterButton: {
    borderRadius: "25px",
    fontSize: "12px"
  },
  textField: {
    borderRadius: "32px !important"
  },
  container: {
    maxHeight: "calc(100vh - 128px)"
  },
  tablePagination: {
    overflow: "hidden",
    "& .MuiIconButton-root": {
      padding: 5
    }
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  alignment: {
    display: "flex",
    alignItems: "center",
    paddingRight: 15
  },
  padding: {
    padding: "0px 15px"
  },
  btn: {
    border: "none",
    borderRadius: "50px",
    backgroundColor: "#dedede",
    color: "black",
    fontWeight: "bold"
  },
  outlined: {
    color: "#545454 !important",
    border: "1px solid #d1d1d1 !important"
  },
  outlinedPrimary: {
    "&:hover": {
      backgroundColor: "#f9f9f9"
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "gray"
  },
  listItem: {
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiListItemText-primary": {
      fontSize: "16px"
    }
  },
  listItemTextRoot: {
    color: theme.palette.primary.main
  },
  activeButton: {
    background: theme.palette.primary.main
  },
  inactiveButton: {
    background: "#808080"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      letterSpacing: 1
    }
  }
}));

const VehicleCounts = React.memo(props => {
  const classes = useStyles();
  const {
    progress: { monitoring: currentStep }
  } = useOnboardingContext();
  const { dataCount, isMapView } = props;

  return (
    <PopupState variant="popper" popupId="vehicleCount-popup-popper">
      {popupState => (
        <Focus
          step={6}
          on={!popupState.isOpen}
          className={classes.alignment}
          infoBubbleProps={{
            position: "left",
            title: "Fleet Count",
            description: "Shows the real time count of your assets"
          }}
        >
          <Typography className={classes.padding}> Count: </Typography>
          <Indicator show={currentStep === 6 && !popupState.isOpen}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              classes={{
                outlined: classes.outlined,
                outlinedPrimary: classes.outlinedPrimary
              }}
              {...bindToggle(popupState)}
            >
              {dataCount?.total || 0}
              <ExpandMore />
            </Button>
          </Indicator>
          <Popper
            {...bindPopper(popupState)}
            placement={isMapView ? "bottom-end" : "bottom"}
            transition
            style={{ zIndex: "1301" }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener
                onClickAway={() => {
                  popupState.close();
                }}
              >
                <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{ width: "250px" }}>
                    <List className={classes.listItem}>
                      <ListItem key="count" dense>
                        <ListItemText
                          id="count"
                          primary="Count"
                          classes={{
                            root: classes.listItemTextRoot
                          }}
                        />
                      </ListItem>
                      <ListItem key="hauler" dense>
                        <ListItemText id="hauler" primary={`Hauler's`} />
                        <ListItemSecondaryAction>
                          <Typography edge="end" aria-label="comments">
                            10
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem key="owned" dense>
                        <ListItemText id="owned" primary="Owned" />
                        <ListItemSecondaryAction>
                          <Typography edge="end" aria-label="comments">
                            9
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="middle" />
                      <ListItem key="available" dense>
                        <ListItemText id="Available" primary="Available" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.available || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem key="booked" dense>
                        <ListItemText id="Booked" primary="Booked" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.booked || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem
                        className={classes.nested}
                        key="booked_underrepair"
                        dense
                      >
                        <ListItemText
                          id="booked_underrepair"
                          primary="Booked-Under Repair"
                        />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "gray" }}
                          >
                            {dataCount?.status_count?.booked_underrepair || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem
                        className={classes.nested}
                        key="booked_not_in_use"
                        dense
                      >
                        <ListItemText
                          id="booked_not_in_use"
                          primary="Booked-Not in use"
                        />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "gray" }}
                          >
                            {dataCount?.status_count?.booked_not_in_use || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem key="underrepair" dense>
                        <ListItemText id="underrepair" primary="Under Repair" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.underrepair || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem key="not_in_use" dense>
                        <ListItemText id="not_in_use" primary="Not in use" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.not_in_use || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider variant="middle" />
                      <ListItem key="gps_status" dense>
                        <ListItemText
                          id="gps_status"
                          primary="GPS Status"
                          classes={{
                            root: classes.listItemTextRoot
                          }}
                        />
                      </ListItem>

                      <ListItem key="inactive" dense>
                        <ListItemText id="inactive" primary="Inactive" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.inactive || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem key="active" dense>
                        <ListItemText id="active" primary="Active" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.active || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem key="no_gps" dense>
                        <ListItemText id="no_gps" primary="No GPS" />
                        <ListItemSecondaryAction>
                          <Typography
                            edge="end"
                            aria-label="comments"
                            style={{ color: "black" }}
                          >
                            {dataCount?.status_count?.no_gps || 0}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Focus>
      )}
    </PopupState>
  );
});

const Monitoring = () => {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [isMapView, setIsMapView] = useState(false);
  const {
    // module,
    progress: { monitoring: currentStep },
    nextStep,
    jumpToStep,
    completeOnboarding
  } = useOnboardingContext();

  const stage = {
    0: {
      image: fleet_welcome_image,
      title: "Fleet: Track your Assets",
      description: `Check your vehicle's GPS status and track their real-time
          location in this module`,
      buttonText: "Got It"
    },
    7: {
      image: take_a_tour,
      title: "Take a tour anytime!",
      description: `Feel free to toggle the tutorial on/off anytime you may want to review TM suite features.`,
      renderActions: () => (
        <div className={classes.footer}>
          <Button
            onClick={() =>
              jumpToStep({
                module: Onboarding.modules.monitoring,
                step: 9
              })
            }
          >
            Skip
          </Button>
          <Button
            onClick={() => nextStep(Onboarding.modules.monitoring)}
            color="primary"
          >
            <b>Next</b>
          </Button>
        </div>
      )
    },
    9: {
      image: you_are_read,
      buttonText: "Start",
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };

  return (
    <div
      style={{
        position: "abasolute",
        top: "64px",
        left: "70px",
        width: "calc(100vw - 70px)",
        height: "calc(100vh - 64px)"
      }}
    >
      <Focus
        step={[0, 7, 9]}
        on={[0, 7, 9].includes(currentStep)}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: [0, 7, 9].includes(currentStep) ? "block" : "none",
          background: "transparent"
        }}
        showInfoBubble={false}
      >
        <Stage {...stage[currentStep]} />
      </Focus>
      <Filter
        filterDrawer={openFilter}
        closeFilterDrawer={() => setOpenFilter(false)}
      >
        {[
          { label: "Hauler" },
          { label: "Vehicle Status" },
          { label: "GPS Status" },
          { label: "Vehicle State" },
          { label: "Vehicle Type" },
          { label: "Temperature" },
          {
            label: "Location",
            renderLabel: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Nunito",
                    color: "#454545"
                  }}
                >
                  Location
                </span>
                <IconButton size="small">
                  <MoreVert fontSize="small" />
                </IconButton>
              </div>
            )
          }
        ].map(item => (
          <FormControl
            classes={{ root: formStyles.formRoot }}
            style={{ width: "100%", minWidth: "auto" }}
          >
            {typeof item.renderLabel === "function" ? (
              item.renderLabel()
            ) : (
              <React.Fragment>
                <InputLabel shrink>{item.label}</InputLabel>
                <br />
              </React.Fragment>
            )}
            <Autocomplete
              options={["All"]}
              value="All"
              disableClearable
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  value="All"
                  placeholder="All"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        ))}
      </Filter>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{
          // position: "relative",
          zIndex: "auto"
        }}
      >
        <Toolbar>
          <Grid container item md={12}>
            <Grid item md={isMapView ? 10 : 6} container alignItems="center">
              <Focus
                // on={currentStep === 0}
                step={1}
                style={{ paddingBlock: 12 }}
                // showInfoBubble={currentStep === 0}
                // module="monitoring"
                // step={1}
                infoBubbleProps={{
                  position: "right",
                  title: "Map View",
                  description:
                    "Displays the current location of your owned vehicles."
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    borderRadius: "25px",
                    margin: "0 20px 0 20px",
                    fontSize: "12px"
                  }}
                  startIcon={isMapView ? <ListTwoTone /> : <MapTwoTone />}
                  // onClick={() => setIsMapView(!isMapView)}
                >
                  {isMapView ? "List View" : "Map View"}
                </Button>
              </Focus>
              <Focus
                step={2} // Implement this: Show on step N, instead of explicitly say "currentStep === 1"
                on={!openFilter}
                style={{ padding: 12 }}
                showInfoBubble={currentStep === 2 && !openFilter}
                indicator={currentStep === 2 && !openFilter}
                infoBubbleProps={{
                  position: "right",
                  title: "Filter",
                  description:
                    "Allow to display certain data based on selected values"
                  // body: <TripLegends />
                }}
              >
                <Button
                  variant="contained"
                  className={classes.inactiveButton}
                  style={{
                    borderRadius: "25px",
                    // margin: "0 20px 0 20px",
                    fontSize: "12px"
                  }}
                  startIcon={<FilterList />}
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <Badge color="secondary" variant="dot" invisible>
                    Filter
                  </Badge>
                </Button>
              </Focus>
            </Grid>
            <Grid item md={2} container justifyContent="flex-end" spacing={2}>
              <VehicleCounts dataCount={10} isMapView={false} />
            </Grid>
            {!isMapView && (
              <React.Fragment>
                <Grid item md={4}>
                  <Pagination
                    rowsPerPage={10}
                    total={5}
                    currentPage={1}
                    startsWithZero={false}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {isMapView ? (
        <div>Base Map</div>
      ) : (
        // <BaseMap
        //   vars={vars}
        //   filterValues={filterValues}
        //   clientSelection={clientSelection}
        //   bound={bound}
        //   setBounds={setBounds}
        //   drawing={drawing}
        //   setDrawing={setDrawing}
        //   permissions={permissions_control_room}
        // />
        <MonitoringTable />
      )}
    </div>
  );
};

export default Monitoring;
