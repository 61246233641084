
import { useState } from 'react'
import useUserContext from '../../context/User/useUserContext';
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_GEOFENCES_TABLE } from '../../graphql/Queries';
import Swal from 'sweetalert2';

const useSearchGeofence = (keyword, hauler, type) => {
  const [options, setOptions] = useState([]);
  const userCtx = useUserContext();
  const userLevelId = userCtx.user_level_id;
  // const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  // const groupIds = isWTIAdmin
  //   ? { value: [30], label: "Webcast", id: 30 }
  //   : { value: userCtx.client.group_ids, label: "All" };

  const getVariables = () => {
    let variables = {
      keyword: keyword,
      keyword_fields: ["name", "geofence_code", "category", "region", "province", "municipality"],
      first: 5,
      group_ids: hauler,
      orderBy: [{ field: "name", direction: "asc" }]
    }
    
    if (type === 0) {
      Object.assign(variables, {
        not: {
          field: "category",
          value: "Route"
        }
      });
    } else {
      Object.assign(variables, {
        condition: {
          field: "category",
          value: "Route"
        }
      });
    }
    return variables;
  }

  // convert data values to an option values for select or auto complete.
  function formatQryOpt(values) {
    let tempOptions = [];
    
    if (keyword) {
      values.forEach((geo) => {
        if (geo.name.includes(keyword)) {
          tempOptions.push(geo.name);
        }
        if (geo.geofence_code.includes(keyword)) {
          tempOptions.push(geo.geofence_code);
        }
      });
      tempOptions = tempOptions.slice(0,5);
    }
    setOptions(tempOptions);
  }
  useQuery(GET_GEOFENCES_TABLE, {
    variables: getVariables(),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !keyword || !hauler,
    onCompleted: data => {
      if (!data) return;
      formatQryOpt(data.get_geofences.geofences);
    },
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" })
  });
  return options;
};

export default useSearchGeofence;