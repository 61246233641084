import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import moment from "moment";

import LOGO from "../../../assets/Images/logo.png";
import { fetchToken } from "../../../api/VSS/fetchToken";
import { ADD_EXPO_TOKEN, UPDATE_EXPO_TOKEN } from "../../../graphql/Mutations";
import { GET_EXPO_TOKENS } from "../../../graphql/Queries";
import {
  AppBar,
  Toolbar,
  styled,
  Container,
  Divider,
  Grid
} from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const VssCamera = () => {
  const tokenId = localStorage.getItem("vssTokenId");
  const params = useParams();
  const qryVariable = { field: "remarks", value: "VSS TOKEN" };
  const [vssUrl, setVssUrl] = useState("");
  const [tokenDetails, setTokenDetails] = useState({
    tokenId: tokenId,
    token: "",
    modifiedDate: "",
    isCurrentTime: "",
    channels: ""
  });

  const [getTokenDetails, { data, loading }] = useLazyQuery(GET_EXPO_TOKENS, {
    variables: {
      filter: [qryVariable]
    },
    onCompleted: res => {
      if (res?.get_expo_tokens.length > 0) {
        const { id, token, modified } = res.get_expo_tokens[0];
        const today = moment().format("YYYY-MM-DD HH:mm");
        const time = moment
          .utc(+modified)
          .add("8", "hours")
          .format("YYYY-MM-DD HH:mm");
        const iscurrentDate = moment(today).isSame(time, "day");

        setTokenDetails(prev => ({
          ...prev,
          tokenId: id,
          token: token,
          modifiedDate: time,
          isCurrentTime: iscurrentDate
        }));
        localStorage.setItem("vssTokenId", id);
      }
    },
    onError: err => console.log(err),
    fetchPolicy: "cache-and-network"
  });

  const [addToken] = useMutation(ADD_EXPO_TOKEN, {
    onCompleted: res => {
      if (res) {
        setTokenDetails(prev => ({
          ...prev,
          tokenId: res?.add_expo_token
        }));
        localStorage.setItem("vssTokenId", res?.add_expo_token);
      }
    },
    onError: err => console.log(err)
  });

  const [updateToken] = useMutation(UPDATE_EXPO_TOKEN, {
    onCompleted: res => {
      if (res && res?.update_expo_token?.success) {
        getTokenDetails({
          variables: { filter: [qryVariable] }
        });
      }
    },
    onError: err => console.log(err)
  });

  const channelHandler = () => {
    let counter = 1;
    let channelTemp = "";
    for (counter; counter <= +params.channel_count; counter++) {
      channelTemp = channelTemp.concat(
        `${counter === 1 ? "" : "_"}`,
        `${counter}`
      );
    }
    setTokenDetails(prev => ({ ...prev, channels: channelTemp }));
  };

  useEffect(() => {
    channelHandler();
    getTokenDetails({
      variables: { filter: [qryVariable] }
    });

    if (data?.get_expo_tokens?.length <= 0 || !tokenDetails.isCurrentTime) {
      fetchToken().then(res => {
        const { status, request, data } = res;
        if (status === 200 || request?.readyState === 4) {
          if ((data.status === 10082 || data.msg === "Success") && data.data) {
            setTokenDetails(prev => ({ ...prev, token: data.data.token }));
            if (!tokenDetails.isCurrentTime && +tokenId && data.data.token) {
              updateToken({
                variables: {
                  id: +tokenId,
                  token: data.data.token,
                  reference_id: "vss token id"
                }
              });
            } else if (_.isEmpty(tokenId)) {
              addToken({
                variables: {
                  token: data.data.token,
                  reference_id: "vss token id",
                  remarks: "VSS TOKEN"
                }
              });
            }
          }
        }
      });
    }
  }, [tokenId]);

  useEffect(() => {
    setVssUrl(
      `${process.env.REACT_APP_VSS_URL}/vss/apiPage/RealVideo.html?token=${tokenDetails.token}&deviceId=${params.id}&chs=${tokenDetails.channels}&stream=0&panel=1&buffer=2000`
    );
  }, [tokenDetails.token]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ height: "89.5vh", minWidth: "100%" }}>
        <HeaderStyled>
          <Toolbar>
            <ImgLogoStyled
              src={LOGO}
              alt="tmsuite_logo"
              style={{ height: 70 }}
            />
          </Toolbar>
        </HeaderStyled>
        <iframe
          src={vssUrl}
          id="vssCamera"
          height={"100%"}
          width={"100%"}
          aria-hidden
          frameBorder={"0"}
          allowFullScreen
        />
      </Grid>
    </Grid>
  );
};

export default VssCamera;

const HeaderStyled = styled(AppBar)(({ theme }) => ({
  zIndex: () => {
    const url = window.location.pathname.replace(/\//, "").split("/")[0];
    if (["notifications"].includes(url)) return 1300;
    return "auto";
  },
  boxShadow: "none",
  backgroundColor: theme.palette.secondary.main,
  border: "1px solid #e9e9e9",
  position: "relative"
}));

const ImgLogoStyled = styled("img")(({ theme }) => ({
  position: "absolute",
}));
