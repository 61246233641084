import React, { useEffect, useImperativeHandle, useState } from "react";
import ChipInput from "../../../Report/common/ChipInput/ChipInput";
import { useFilterStyles } from "./Filter.styles";

// geofence types chip input
const CategoryChipInput = React.forwardRef((props, ref) => {
  const { categories = [], readOnly = false } = props;
  const initialSelected = categories
    .filter(cat => cat.isShown)
    .map(cat => cat.label);
  const [selectedCategories, setSelectedCategories] = useState(
    initialSelected.length < categories.length ? initialSelected : []
  );

  const styles = useFilterStyles();
  const options = categories.map(cat => cat.label) || [];

  useImperativeHandle(ref, () => ({
    clearSelected: () => setSelectedCategories([]),
    getSelected: () => selectedCategories
  }));

  const autocompleteProps = {
    // 💡 Override Autocomplete props
    renderInput: ({ getTextfieldProps }) => ({
      ...getTextfieldProps({
        label: "Geofence Type",
        placeholder: selectedCategories?.length > 0 ? "" : "All",
        InputProps: {
          disableUnderline: readOnly,
          disabled: readOnly
        }
      })
    }),
    disabled: readOnly,
    getOptionLabel: option => option,
    getOptionSelected: (option, value) => option === value
  };

  const handleSelectedChange = changes => {
    const newSelectedValues = [...changes.selectedValues];
    ref.current = newSelectedValues;
    setSelectedCategories(newSelectedValues);
  };

  useEffect(() => {
    return () => {
      ref.current = categories;
    };
  }, []);

  return (
    <ChipInput
      classes={styles}
      options={options}
      selectedValues={selectedCategories}
      onSelectedChanged={handleSelectedChange}
      autocompleteProps={autocompleteProps}
    />
  );
});

export default CategoryChipInput;
