import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import Shape from "./Shapes";
import { getGeofenceTypeColor } from "../../Utils";

const GeofenceWithInfo = props => {
  const {
    icon,
    data: { location, geofence_code, name, category, opacity },
    data
  } = props;

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const toggleInfoWindow = () => {
    setIsInfoVisible(prev => !prev);
  };
  const DEFAULT_OPTIONS = {
    fillColor: getGeofenceTypeColor(category),
    fillOpacity: isInfoVisible ? 0.4 : 0.3,
    strokeWeight: 2,
    // strokeColor: "rgba(0, 128, 255, 0.7)",
    strokeColor: "black",
    cursor: "help"
  };

  return (
    <React.Fragment>
      {/* <Circle
        center={{ lat: location.lat, lng: location.lon }}
        radius={radius}
        options={DEFAULT_OPTIONS}
        onMouseOver={toggleInfoWindow}
        onMouseOut={toggleInfoWindow}
      /> */}

      <Shape
        toggleInfoWindow={toggleInfoWindow}
        options={DEFAULT_OPTIONS}
        data={data}
      />

      <Marker
        // animation={google.maps.Animation.Up}
        icon={icon}
        position={{ lat: location.lat, lng: location.lon }}
        // onMouseOver={toggleInfoWindow}
        // onMouseOut={toggleInfoWindow}
        // onClick={toggleInfoWindow}
      />

      {isInfoVisible && (
        <InfoWindow position={{ lat: location.lat, lng: location.lon }}>
          <div>
            <Typography variant="caption">
              {`${geofence_code}-${name}`}
              <p>{`${location.lat}, ${location.lon}`}</p>
            </Typography>
          </div>
        </InfoWindow>
      )}
    </React.Fragment>
  );
};

export default GeofenceWithInfo;
