/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  memo,
  useEffect,
  useRef,
  Fragment,
  useReducer
} from "react";
import {
  AddRounded as AddRoundedIcon,
  EditRounded as EditRoundedIcon,
  DeleteRounded as DeleteIcon,
  Save as SaveIcon,
  Close as CloseIcon
} from "@material-ui/icons";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField
} from "@material-ui/core";
import AlertComponent from "./Alert";
import { useStyles } from "./DashboardStyle";
import NoData from "../NoData";
import ActionButton from "./Buttons";
import validate from "../../../../../../utils/validation";
import _ from "lodash";
import {
  ADD_DASHBOARD_LINK,
  DELETE_DASHBOARD_LINK,
  EDIT_DASHBOARD_LINK
} from "../../../../../../graphql/Mutations";
import { GET_DASHBOARD_LINKS } from "../../../../../../graphql/Queries";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../../../../utils/Loading";
import { useParams } from "react-router-dom";
import AddLogs from "../../../../../../utils/functions/AddLogs";
// list of messages for alert status.
const messages = [
  { id: 1, note: "Action Cancelled" },
  { id: 2, note: "Link removed" },
  { id: 3, note: "Successfully Added Link" },
  { id: 4, note: "Successfully Updated Link" },
  { id: 5, note: "Removed link recovered" }
];

/*
  🚩 Validations: 🚩
  _links
    ✅ check if "." is included.
    ✅ check if link field is not null.
    ✅ check for "http:// || https://".
    TABLEAU:  
    ✅ checks if "tableau.com" is included on the url.
    ✅ checks for "views" on the tableau link.
    DATASTUDIO: 
    ✅ checks for "datastudio.google.com/embed/" on the link.
*/
// reducers list
function inputReducer(state, action) {
  // remove white spaces
  let enteredValue = action.val? action.val : "";
  // name
  if (action.type === "USER_INPUT_NAME") {
    return { value: enteredValue, isValid: enteredValue.length > 0 };
  }
  // url
  if (action.type === "USER_INPUT_URL") {
    return {
      value: enteredValue.trim(),
      isValid:
        enteredValue.length > 0 &&
        validate("url", enteredValue) &&
        ((enteredValue.includes("tableau.com") &&
          enteredValue.includes("views")) ||
          enteredValue.includes("datastudio.google.com/embed/")) &&
        (enteredValue.includes("http://") || enteredValue.includes("https://"))
    };
  }
  // default value
  return { value: "", isValid: null };
}
// temp for action type and values.
function tempReducer(state, action) {
  switch (action.type) {
    case "ADD_LINK":
      return { action: action.type, index: 0 };
    case "EDIT_LINK":
      return { id: action.id, action: action.type, index: action.index };
    case "DELETE_LINK":
      return {
        id: action.id,
        action: action.type,
        data: action.data,
        index: action.index
      };
    case "UNDO_LINK":
      return { action: action.type };
    default:
      return { id: null, action: null };
  }
}

const Dashboard = props => {
  const { id: groupId } = useParams();
  const { mode, groupIds, removedIdRef } = props;
  const classes = useStyles();
  const [links, setLinks] = useState([]);
  const timerRef = useRef(0);
  const [removedId, setRemovedId] = useState([]);
  const [nameState, dispatchName] = useReducer(inputReducer, {
    value: "",
    isValid: null
  });
  const [urlState, dispatchUrl] = useReducer(inputReducer, {
    value: "",
    isValid: null
  });
  const [tempState, dispatchTemp] = useReducer(tempReducer, {
    id: null,
    action: null,
    data: [],
    index: null
  });
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    message: "",
    type: ""
  });
  // graphql for fetching dashboard links.
  const { data: dashboardLinksData, loading: loadingDashboard } = useQuery(
    GET_DASHBOARD_LINKS,
    {
      variables: {
        group_ids: !_.isEmpty(groupIds) ? groupIds : groupId
      },
      onCompleted(data) {
        const { links } = data.get_dashboard_links;
        setLinks(links);
      },
      fetchPolicy: "cache and network"
    }
  );
  // add dashboard link mutation.
  const [addDashboardLink] = useMutation(ADD_DASHBOARD_LINK, {
    onCompleted(data) {
      const { success, error } = data.add_dashboard_link;
      if (!success) {
        setAlertDetails({ open: true, message: error[0].message, type: "" });
      }
    },
    refetchQueries: [
      { query: GET_DASHBOARD_LINKS, variables: { group_ids: groupIds } }
    ]
  });
  // edit link mutation.
  const [editDashboardLink] = useMutation(EDIT_DASHBOARD_LINK, {
    onCompleted(data) {
      const { success, error } = data.edit_dashboard_link;
      if (success) {
        setAlertDetails({ open: true, message: messages[3].note, type: "" });
      } else {
        setAlertDetails({
          open: true,
          message: error[0].message,
          type: ""
        });
      }
    },
    refetchQueries: [
      { query: GET_DASHBOARD_LINKS, variables: { group_ids: groupIds } }
    ]
  });
  // delete link mutation.
  const [deleteDashboardLink] = useMutation(DELETE_DASHBOARD_LINK, {
    refetchQueries: [
      { query: GET_DASHBOARD_LINKS, variables: { group_ids: groupIds } }
    ]
  });
  // checks if the form is valid for the entered user inputs.
  const formIsValid = nameState.isValid && urlState.isValid;
  // reset dispatch state.
  function resetDispatch() {
    const resetValue = { type: "", val: "" };
    // dispatch.
    dispatchName(resetValue);
    dispatchUrl(resetValue);
    dispatchTemp({ id: null, action: null, data: [], index: null });
    return;
  }
  // resets alert values.
  const resetAlert = () =>
    setAlertDetails({ open: false, message: "", type: "" });
  // read only input
  const readOnly = index =>
    tempState.index !== index || tempState.action === "DELETE_LINK";
  // a function that verifies the last action initialized by user.
  function lastActionHandler() {
    const isAddLink = _.isEqual(tempState.action, "ADD_LINK");
    // execute and remove first element on links array
    if (isAddLink) links.shift();
    // return value
    return isAddLink;
  }
  // TODO: clean up removedLinks.
  function cleanUpLinks(value) {
    if (_.isEmpty(value)) return;
    // loop through the given value array.
    value.forEach((id, index) => {
      deleteDashboardLink({ variables: { id: value[index] } });
    });
    removedIdRef.current = [];
    setRemovedId([]);
  }
  // snackbar timer
  function timerLogger() {
    // this will prevent the infinite loop when using setTimeout on useEffect.
    if (!timerRef.current) return;
    // set removedIdRef value.
    removedIdRef.current = removedId;
    // timer in removing links.
    const statusTime = setTimeout(() => {
      let removedIdTemp = removedId;
      if (removedId.length) {
        if (_.isEqual(tempState.action, "UNDO_LINK") || removedId.length > 1){
          removedIdTemp.pop();
          cleanUpLinks(removedIdTemp)
        }else cleanUpLinks(removedIdTemp);
      }
      // reset dispatch will not be applicable on add link unless action is not add.
      if (!_.isEqual(tempState.action, "ADD_LINK")) resetDispatch();
      // resetter.
      resetAlert();
      timerRef.current = 0;
    }, timerRef.current);
    // clear setTimeout value.
    return () => clearTimeout(statusTime);
  }
  // close button for snackbar
  const closeAlertHandler = () => {
    resetAlert();
    resetDispatch();
  };
  // add link function
  const addLinkHandler = event => {
    // resets value of dispatch.
    resetDispatch();
    // on change values.
    const tempData = { name: "", link: "" };
    dispatchTemp({ type: "ADD_LINK" });
    // set the new added links
    setLinks(links => [tempData, ...links]);
    // reset timer.
    // timerRef.current = 0;
  };
  // remove specific link from the list
  const removeLinkHandler = (event, idx) => {
    const { id } = event.currentTarget;
    // if last action was add a link then subtract 1 from the edit index.
    const linkId = parseInt(lastActionHandler() ? id - 1 : id);
    // temporary store the links.
    const linkDataTemp = links;
    // remove the link that has been selected for deletion and set link id of the deleted link.
    const tempLink = linkDataTemp.filter(link => parseInt(link.id) === linkId);
    dispatchTemp({
      type: "DELETE_LINK",
      id: linkId,
      data: tempLink,
      index: idx
    });
    AddLogs("Client - Dashboard", "delete_dashboard_overview", tempLink[0].name);
    // sets the new list of links.
    setLinks(linkDataTemp.filter(link => parseInt(link.id) !== linkId));
    setAlertDetails({ open: true, message: messages[1].note, type: "error" });
    setRemovedId(prevState => [...prevState, linkId]);
    // remove link
    timerRef.current = 2000;
  };
  // edit specific link from the list
  const editLinkHandler = (event, idx) => {
    const { id } = event.currentTarget;
    // if last action was add a link then subtract 1 from the edit index.
    const idxTemp = parseInt(lastActionHandler() ? idx - 1 : idx);
    // temporary for storing the links.
    const linkDataTemp = links;
    // filter out the user's selected dashboard to be edit.
    const editInfoTemp = linkDataTemp.filter(
      (link, index) => index === idxTemp
    );
    // dispatch
    dispatchTemp({ type: "EDIT_LINK", id: id, index: idxTemp });
    dispatchName({ type: "USER_INPUT_NAME", val: editInfoTemp[0]?.name });
    dispatchUrl({ type: "USER_INPUT_URL", val: editInfoTemp[0]?.link });
  };
  // add link handler with add dashboard link mutation
  function saveLinkHandler() {
    AddLogs("Client - Dashboard", "add_dashboard_overview", nameState.value);
    addDashboardLink({
      variables: {
        name: nameState.value,
        link: urlState.value,
        group_ids: groupIds
      }
    });
  }
  // update link handler with udpate dashboard link mutation
  function updateLinkHandler() {
    AddLogs("Client - Dashboard", "edit_dashboard_overview", nameState.value);
    editDashboardLink({
      variables: {
        id: tempState.id,
        name: nameState.value,
        link: urlState.value
      }
    });
  }
  // a function that will update the link that the user clicked to be edit.
  const submitLinkHandler = event => {
    if (lastActionHandler()) saveLinkHandler();
    else updateLinkHandler();
    // reset function
    resetDispatch();
    timerRef.current = 3000;
  };
  // on change field function
  const onChangeHandler = event => {
    // get the field value and name from the event target.
    const { value, name } = event.target;
    // temporary store value fields.
    switch (name) {
      case "name":
        return dispatchName({ type: "USER_INPUT_NAME", val: value });
      case "link":
        return dispatchUrl({ type: "USER_INPUT_URL", val: value });
      default:
    }
  };
  // cancel button when link is on edit mode.
  const cancelHandler = event => {
    // if entered link is a new link delete from array if cancelled.
    if (tempState.action === "ADD_LINK") links.splice(0, 1);
    // reset alert
    setAlertDetails({ open: true, message: messages[0].note, type: "success" });
    // resetter
    resetDispatch();
    timerRef.current = 3000;
  };
  // this will undo the deleted item on the links table.
  const undoHandler = () => {
    // return the link to the location where it was stored on the array.
    const { index, data } = tempState;
    links.splice(index, 0, data[0]);
    // filter out links that has been undone for deletion.
    let removedTemp = removedId.filter(
      (id, index) => removedId[index] !== data[0].id
    );
    setRemovedId(removedTemp);
    removedIdRef.current = removedTemp;
    AddLogs("Client - Dashboard", "undo_remove_dashboard_overview", data[0].name);
    // reset the values
    setAlertDetails({ open: true, message: messages[4].note, type: "" });
    // closeAlertHandler();
    dispatchTemp({ type: "UNDO_LINK" });
    timerRef.current = 500;
  };
  // this executes the snackbar.
  useEffect(() => timerLogger(), [
    removeLinkHandler,
    submitLinkHandler,
    undoHandler,
  ]);
  // this only execute once upon arriving page to check if removedId array is empty or has no pending removal upon leaving component.
  useEffect(() => cleanUpLinks(removedIdRef.current), []);
  // check if mode has changed after the action of add link or edit link. 
  useEffect(() => {
    if (_.isEqual(tempState.action, "ADD_LINK") || _.isEqual(tempState.action, "EDIT_LINK")) cancelHandler();
  },[mode]);

  return (
    <Fragment>
      {loadingDashboard ? (
        <Loading />
      ) : (
        <>
          <div className={classes.dashboard}>
            <AlertComponent
              message={alertDetails.message}
              alignment={{ vertical: "bottom", horizontal: "right" }}
              isOpen={alertDetails.open}
              type={alertDetails.type}
              undoFn={undoHandler}
              closeFn={closeAlertHandler}
            />
            <Container className={classes.dashboard_container}>
              <Typography variant="h6" color="inherit">
                Dashboard Links
              </Typography>
              {links.length > 0 && (
                <ActionButton
                  functionHandler={addLinkHandler}
                  editMode={!mode || tempState.action === "ADD_LINK"}
                  title="Add New Link"
                  icon={
                    <AddRoundedIcon
                      color={
                        !mode || tempState.action === "ADD_LINK"
                          ? "disabled"
                          : "primary"
                      }
                    />
                  }
                />
              )}
            </Container>
            {!links.length > 0 ? (
              <NoData
                mode={mode}
                functionHandler={addLinkHandler}
                title="No Data Found"
                subtitle="Add an entry link for Data
        Representation."
              />
            ) : (
              <TableContainer className={classes.table_container}>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.table_header_name}>
                        Name
                      </TableCell>
                      <TableCell className={classes.table_header_name}>
                        URL
                      </TableCell>
                      <TableCell
                        className={classes.table_header_name}
                        style={{ width: 160 }}
                        align="right"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {links.map((link, index) => (
                      <TableRow key={`tbr-${link.id}`}>
                        <TableCell width="30%" padding="checkbox">
                          <TextField
                            name="name"
                            fullWidth
                            value={
                              readOnly(index)? link.name : nameState.value
                            }
                            type="text"
                            variant={readOnly(index) ? "standard" : "outlined"}
                            error={
                              !_.isNull(nameState.isValid) && !nameState.isValid
                            }
                            size="small"
                            style={{ padding: 0 }}
                            InputProps={{
                              readOnly: readOnly(index),
                              // disableUnderline: readOnly(index),
                              style: { fontSize: 14 }
                            }}
                            onChange={onChangeHandler}
                            placeholder="Enter name"
                            // onKeyUp={checkInputHandler}
                          />
                        </TableCell>
                        <TableCell
                          style={{ width: "300px", maxWidth: "320px" }}
                          padding="checkbox"
                        >
                          <TextField
                            name="link"
                            fullWidth
                            value={readOnly(index) ? link.link : urlState.value}
                            type="url"
                            variant={readOnly(index) ? "standard" : "outlined"}
                            error={
                              !_.isNull(urlState.isValid) && !urlState.isValid
                            }
                            size="small"
                            style={{ padding: 0 }}
                            InputProps={{
                              readOnly: readOnly(index),
                              // disableUnderline: readOnly(index),
                              style: { fontSize: 14 }
                            }}
                            onChange={onChangeHandler}
                            placeholder="Enter link"
                            // onKeyUp={checkInputHandler}
                          />
                        </TableCell>
                        <TableCell
                          style={{ width: 160 }}
                          align="right"
                          padding="checkbox"
                        >
                          {readOnly(index) ? (
                            <ActionButton
                              functionHandler={event =>
                                editLinkHandler(event, index)
                              }
                              editMode={!mode}
                              title="Edit"
                              icon={
                                <EditRoundedIcon
                                  className={classes.process_icon}
                                  color={!mode ? "disabled" : "action"}
                                />
                              }
                              id={link.id}
                            />
                          ) : (
                            <ActionButton
                              functionHandler={event =>
                                submitLinkHandler(event)
                              }
                              editMode={!formIsValid}
                              title="Save"
                              icon={
                                <SaveIcon
                                  className={classes.process_icon}
                                  color={formIsValid ? "action" : "disabled"}
                                />
                              }
                              id={index}
                            />
                          )}
                          {readOnly(index) ? (
                            <ActionButton
                              functionHandler={event => {
                                removeLinkHandler(event, index);
                              }}
                              editMode={!mode}
                              title="Delete"
                              icon={
                                <DeleteIcon
                                  className={classes.process_icon}
                                  color={!mode ? "disabled" : "action"}
                                />
                              }
                              id={link.id}
                            />
                          ) : (
                            <ActionButton
                              functionHandler={cancelHandler}
                              editMode={!mode}
                              title="Cancel"
                              icon={
                                <CloseIcon
                                  className={classes.process_icon}
                                  color={!mode ? "disabled" : "action"}
                                />
                              }
                              id={index}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};

export default memo(Dashboard);
