/* eslint-disable import/prefer-default-export */
import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    margin: "12px 0px 0px 0px"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#FF7043",
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#FF7043"
  },
  checked: {}
}))(Switch);
