import Swal from "sweetalert2";

export default (redirect, message = "Saved", success = true) => {
  Swal.fire({
    title: message,
    icon: success ? "success" : "error",
    showConfirmButton: false,
    timer: 3000,
    onClose: () => {
      redirect();
    }
  });
};
