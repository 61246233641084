export const actionTypes = {
  load_data: "load_data",
  sort_table: "sort_table",
  toggle_select_all: "toggle_select_all",
  reset_selected_rows: "reset_selected_rows",
  // toggle_select_row: "toggle_select_row",
  change_page: "change_page",
  change_rows_per_page: "change_rows_per_page",
  reset_current_page: "reset_current_page"
};

export const defaultReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.load_data: {
      const { tableData, total, count } = action.payload;
      return {
        ...state,
        tableState: {
          ...state.tableState,
          data: tableData,
          tableItemsCount: count,
          tableItemsTotal: total
          // selectedRows: []
        }
      };
    }
    case actionTypes.toggle_select_all: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          selectedRows: action.payload.selectedRows
        }
      };
    }
    case actionTypes.sort_table: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          sort: action.payload.orderBy
        }
      };
    }
    case actionTypes.change_page: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          currentPage: action.payload.newPage
        }
      };
    }
    case actionTypes.change_rows_per_page: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          currentPage: 0,
          rowsPerPage: action.payload.rowsPerPage
        }
      };
    }
    case actionTypes.reset_selected_rows: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          selectedRows: []
        }
      };
    }
    case actionTypes.reset_current_page: {
      return {
        ...state,
        tableState: {
          ...state.tableState,
          currentPage: 0
        }
      };
    }
    default:
      return state;
  }
};
