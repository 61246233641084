import React, { useState, useContext, useEffect, useRef } from "react";
import Highlight from "react-highlighter";
import {
  makeStyles,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TableBody,
  TextField
} from "@material-ui/core";
import moment from "moment";
import {
  PlaylistAddCheck as PlaylistAddCheckRoundedIcon,
  Visibility as VisibilityRoundedIcon,
  Delete as DeleteIcon,
  GetApp,
  Publish
} from "@material-ui/icons/";
import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useMutation, useQuery } from "@apollo/client";
import _, { result } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import Table from "../../../../utils/UI/Table/CustomTable";
import Header, {
  useStyles as useHeaderStyles
} from "../../../../utils/UI/Table/Header";
import SearchContext from "../../../../context/SearchContext";
import ConfirmationDialogTimeSheet from "../../../../utils/modals/ConfirmationDialogTimeSheet";
import Filter from "../../Filter";
import useFormStyles from "../../../../styles/useFormStyles";
import Upload from "../../../../utils/Upload";
import validate from "../../../../utils/validation";
import {
  DELETE_DRIVERS_ATTENDANCE,
  EDIT_DRIVERS_ATTENDANCE,
  UPLOAD_WORKING_HOURS
} from "../../../../graphql/Mutations";
import DownloadMenu from "./DownloadMenu";

import {
  GET_DRIVER_ATTENDANCES,
  GET_DRIVER_INFOS
} from "../../../../graphql/Queries";
import Loading from "../../../../utils/Loading";
import useDebounce from "../../../../utils/hooks/useDebounce";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../utils/functions/AddLogs";
import MomentUtils from "@date-io/moment/";
import AccessControl from "../../../../utils/AccessControl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUserContext from "../../../../context/User/useUserContext";

const STATUS_COLORS = ["#E3E3E3", "#9DF69C", "#E85F5E"];

// const values.
const Headers = [
  { id: "personnel_employeeid", label: "Employee ID", isSortable: true },
  { id: "personnel_name", label: "Driver Name", isSortable: true },
  {
    id: "id",
    label: "Date",
    isSortable: true
  },
  { id: "time_in", label: "Time in", isSortable: true },
  {
    id: "time_out",
    label: "Time out",
    isSortable: true
  },
  { id: "status_code", label: "Status", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false }
];
const INITIAL_FIELD = "personnels.employeeid";
const INITIAL_DIRECTION = "asc";
const INITIAL_ORDER = { field: INITIAL_FIELD, direction: INITIAL_DIRECTION };
const HEADER_LABEL = "Working Hours";
const INITIAL_PAGE = { rows: 10, currentPage: 0 };

const columns = {
  "Employee ID*": "personnel_employeeid",
  "Driver's Name*": "personnel_name",
  "Vehicle*": "plateno",
  "Time In*": "time_in",
  "Time Out*": "time_out",
  Status: "status_code"
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const WorkingHours = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useUserContext();
  const headerClasses = useHeaderStyles();
  const formStyles = useFormStyles();
  const triggerRef = useRef(true);
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [page, setPage] = useState(INITIAL_PAGE);
  const contextData = useContext(SearchContext);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [selectedData, setSelectedData] = useState([]);
  const [workHours, setWorkHours] = useState();
  const [filtered, setFiltered] = useState(false);
  const [status, setStatus] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filter, setFilter] = useState([]);
  const [appModal, setAppModal] = useState(false);
  const [disModal, setDisModal] = useState(false);
  const [forModal, setForModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState();
  const [multipleDel, setMultipleDel] = useState();
  const [keyword, setKeyword] = useState("");
  const [clearFirst, setClearFirst] = useState(false);
  const [tempFilter, setTempFilter] = useState({
    from: null,
    to: null,
    status: "all",
    driver: { employeeid: "All" }
  });
  const [uploadOpen, setUploadOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editPermission, setEditPermission] = useState(false);

  const DEBOUNCE_TIME = 500;
  const [driverKeyword, setDriverKeyword] = useDebounce("", DEBOUNCE_TIME);

  const getVariables = () => {
    const variables = {
      first: page.rows,
      skip: page.rows * page.currentPage,
      keyword: keyword,
      orderBy: [order],
      condition: []
    };

    if (filter?.length > 0) {
      filter.forEach(element => {
        switch (element?.field) {
          case "from":
            Object.assign(variables, {
              dateFilter: {
                field: element.value.field,
                start: element.value.start,
                end: element.value.end
              }
            });
            break;
          case "status":
            {
              variables.condition.push({
                field: "driver_attendance.status_code_id",
                value: element?.value
              });
            }
            break;
          case "driver":
            {
              variables.condition.push({
                field: "personnels.employeeid",
                value: element.value.toString()
              });
            }
            break;
          default:
            break;
        }
      });
    }
    return variables;
  };

  const { data: attendanceData, loading, refetch } = useQuery(
    GET_DRIVER_ATTENDANCES,
    {
      variables: getVariables(),
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (keyword) {
          AddLogs("Admin - Working Hours", "search", searchKeyword.value);
        }
      }
    }
  );

  const [deleteAttendance] = useMutation(DELETE_DRIVERS_ATTENDANCE, {
    onCompleted: data => {
      if (multipleDel === selectedData.length) {
        Swal.fire({
          title: "Delete",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          allowOutsideClick: false,
          onClose: () => {}
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setFilter([]);
            handleClearFilter();
            setSelectedData([]);
            refetch();
          }
        });
      } else {
        Swal.fire({
          title: "Delete",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          allowOutsideClick: false,
          onClose: () => {}
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) refetch();
        });
      }
    }
  });

  const [editAttendance] = useMutation(EDIT_DRIVERS_ATTENDANCE, {
    onCompleted: data => {
      setStatus(false);
      if (data.edit_driver_attendance.success) {
        Swal.fire({
          title: "Success",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          allowOutsideClick: false,
          onClose: () => {}
        }).then(result => {
          triggerRef.current = true;
          if (result.dismiss === Swal.DismissReason.timer) refetch();
        });
      }
    }
  });

  const { data: driverData } = useQuery(GET_DRIVER_INFOS, {
    fetchPolicy: "network-only",
    variables: {
      first: 5,
      keyword: driverKeyword,
      condition: [
        {
          field: "personnels.designation",
          value: "driver"
        }
      ]
    }
  });

  // function
  // handle table data and validate its checkbox if already selected.
  const handleClick = (event, data) => {
    const tempId = selectedData.map(n => n.id);
    const selectedIndex = tempId.indexOf(data.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1));
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      );
    }
    setSelectedData(newSelected);
  };
  // get table data and validate if it is selected
  const isSelected = value => {
    const tempSelectedIds = selectedData.map(n => n.id);
    return tempSelectedIds.indexOf(value) !== -1;
  };
  // function get status color.
  function statusColorHandler(value) {
    let color = STATUS_COLORS[0];
    switch (value.toUpperCase()) {
      case "APPROVED":
        color = STATUS_COLORS[1];
        break;
      case "DISAPPROVED":
        color = STATUS_COLORS[2];
        break;
      default:
    }

    return color;
  }

  const headerStat = stat => {
    let status;
    switch (stat) {
      case "Incomplete":
      case "Disapproved":
        {
          status = "Please review this timesheet";
        }
        break;
      case "Approved":
      case "For Approval":
        {
          status = "Timesheet";
        }
        break;
      default:
        break;
    }
    return status;
  };

  const handleClose = () => {
    setStatus(false);
  };

  const deleteSelected = () => {
    setDelModal(true);
  };

  const respondTimesheets = () => {
    const logs = selectedData.map(n => n.personnel_name);
    AddLogs("Admin - Working Hours", "view", logs);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleSetFilters = () => {
    const temp = [];
    let start = "";
    let end = "";
    // setting filter value from filter fields
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        switch (key) {
          case "from":
            {
              start = tempFilter.from;
              end = tempFilter.to;
              temp.push({
                field: key,
                value: {
                  start: tempFilter.from,
                  end: tempFilter.to,
                  field: "driver_attendance.time_in"
                }
              });
            }
            break;
          case "status":
            {
              if (tempFilter.status !== "all") {
                temp.push({
                  field: "status",
                  value: tempFilter?.status
                });
              }
            }
            break;
          case "driver":
            {
              if (tempFilter?.driver.employeeid !== "All") {
                temp.push({
                  field: "driver",
                  value: tempFilter?.driver?.employeeid?.toString()
                });
              }
            }
            break;
          default:
            break;
        }
        return temp;
      });
    setPage(INITIAL_PAGE);
    setFilter(temp);
    closeFilterDrawer();
    AddLogs(
      "Admin - Working Hours",
      "filter",
      `${start} ${end} 
      Status: ${
        tempFilter.status === "39"
          ? "For Approval"
          : tempFilter.status === "40"
          ? "Approved"
          : tempFilter.status === "41"
          ? "Disapproved"
          : tempFilter.status === "42"
          ? "Incomplete"
          : ""
      } 
    Driver: ${tempFilter.driver.employeeid}`
    );
  };

  const handleClearFilter = () => {
    setTempFilter({
      from: null,
      to: null,
      status: "all",
      driver: { employeeid: "All" }
    });
    setPage(INITIAL_PAGE);
    setFilter([]);
    AddLogs(
      "Admin - Working Hours",
      "filter",
      `Status: All,
      Driver: All`
    );
  };

  const [uploadWorkingHours, { loading: uploading }] = useMutation(
    UPLOAD_WORKING_HOURS
  );

  const validateColumn = itemData => {
    const result = {
      errors: {},
      data: itemData
    };
    const {
      personnel_employeeid,
      personnel_name,
      plateno,
      // status_code,
      time_in,
      time_out
    } = itemData;

    if (!personnel_employeeid)
      result.errors.personnel_employeeid = "Employee ID is required.";
    if (!personnel_name)
      result.errors.personnel_name = "Employee name is required.";
    if (!plateno) result.errors.plateno = "Plate number is requred.";
    if (!time_out) result.errors.time_out = "Time out is required.";
    if (!time_in) result.errors.time_in = "Time in is required.";

    if (!validate("alphanumeric", personnel_employeeid))
      result.errors.personnel_employeeid = "Employees ID does not exist.";
    if (!validate("alphanumeric", personnel_name))
      result.errors.personnel_name = "Driver's Name does not exist.";

    if (time_in && !moment(time_in, ["MM/DD/YYYY h:m a"]).isValid())
      result.errors.time_in = "Time In is invalid Format.";
    if (time_out && !moment(time_out, ["MM/DD/YYYY h:m a"]).isValid())
      result.errors.time_out = "Time Out is invalid Format.";

    if (time_in && time_out && moment(time_out).isBefore(moment(time_in)))
      result.errors.time_out = "Time Out is earlier than Time In.";

    return result;
  };

  const handleClosePopper = event => {
    if (menuAnchorEl.current && menuAnchorEl.current.contains(event.target)) {
      return;
    }

    setMenuAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setMenuAnchorEl(null);
    }
  }

  const handleTimeStatus = e => {
    if (e.currentTarget.id === "yesBtn") {
      setAppModal(true);
    } else if (e.currentTarget.id === "noBtn") {
      setDisModal(true);
    } else if (e.currentTarget.id === "extraBtn") {
      setForModal(true);
    }
    setStatus(false);
  };

  function handleOfficialBusiness() {
    triggerRef.current = false;
    if (appModal) {
      editAttendance({
        variables: {
          id: updateStatus.id,
          attendance: {
            status_code_id: Number(
              process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED
            )
          }
        }
      });
      AddLogs("Admin - Working Hours", "approve", [
        updateStatus.personnel_name,
        updateStatus.time_in
      ]);
    }

    if (disModal) {
      editAttendance({
        variables: {
          id: updateStatus.id,
          attendance: {
            status_code_id: Number(
              process.env.REACT_APP_DRIVER_STATUS_CODE_DISAPPROVED
            )
          }
        }
      });
      AddLogs("Admin - Working Hours", "disapprove", [
        updateStatus.personnel_name,
        updateStatus.time_in
      ]);
    }

    if (forModal) {
      editAttendance({
        variables: {
          id: updateStatus.id,
          attendance: {
            status_code_id: Number(
              process.env.REACT_APP_DRIVER_STATUS_CODE_FOR_APPROVAL
            )
          }
        }
      });
      AddLogs("Admin - Working Hours", "change", [
        updateStatus.personnel_name,
        updateStatus.time_in,
        updateStatus.status_code
      ]);
    }
  }

  function handleCloseOB() {
    if (appModal) {
      setAppModal(false);
    } else if (disModal) {
      setDisModal(false);
    } else if (forModal) {
      setForModal(false);
    }
    setTimeout(() => {
      if (triggerRef.current === true) setStatus(true);
    }, 300);
  }

  function handleDeleteAttendance() {
    const multi = selectedData.map(n => n);
    let counter = 0;
    if (selectedData.length) {
      for (let i = 0; i < multi?.length; i++) {
        counter++;
        deleteAttendance({
          variables: {
            id: multi[i].id
          }
        });
        setMultipleDel(counter);
        AddLogs(
          "Timesheet",
          "delete",
          `${multi[i].personnel_name}, ${multi[i].time_in}, ${multi[i].status_code}`
        );
      }
    } else {
      deleteAttendance({
        variables: {
          id: updateStatus.id
        }
      });
      AddLogs(
        "Timesheet",
        "delete",
        `${updateStatus.personnel_name}, ${updateStatus.time_in}, ${updateStatus.status_code}`
      );
    }
    setDelModal(false);
  }

  function handleCloseDel() {
    setDelModal(false);
  }

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  // Function to sort and prepare the data to display in data table
  const getData = () => {
    return attendanceData?.get_driver_attendances?.attendances
      ? stableSort(
          attendanceData?.get_driver_attendances?.attendances,
          getSorting(order.direction, order.field)
        ).slice(
          Number(page.currentPage) * page.rows,
          Number(page.currentPage) * page.rows + page.rows
        )
      : // return empty array if currentData didn't have a value
        [];
  };

  const dataToArray = () => {
    const temp = [
      ["Employee ID", "Driver's Name", "Date", "Time In", "Time Out", "Status"]
    ];

    getData().map(row =>
      temp.push([
        row.personnel_employeeid,
        row.personnel_name,
        row.time_in,
        row.time_in,
        row.time_out,
        row.status_code
      ])
    );
    return temp;
  };

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      //to not clear the filter when searching
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(INITIAL_PAGE);
    }
  }, [searchKeyword, clearFirst]);

  if (loading) return <Loading />;

  return (
    <AccessControl
      process="tables"
      setCanDelete={setEditPermission}
      resource="/admin/driver_management/working_hours/"
    >
      <>
        <Header
          label={HEADER_LABEL}
          page={page}
          setPage={setPage}
          totalPage={Math.ceil(
            attendanceData?.get_driver_attendances?.total / page.rows
          )}
          hasDownload
          hasFilter
          hasUpload
          setFiltered={setFiltered}
          filtered={filtered}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          deleteSelected={deleteSelected}
          respondTimesheets={respondTimesheets}
          setFilterDrawer={setFilterDrawer}
          getVariables={getVariables}
          tempFilter={tempFilter}
          editPermission={editPermission}
        >
          <Button
            ref={menuAnchorEl}
            variant="contained"
            startIcon={<GetApp />}
            className={headerClasses.btn_download}
            onClick={event => setMenuAnchorEl(event.currentTarget)}
          >
            Download
          </Button>
          {editPermission && (
            <Button
              variant="contained"
              startIcon={<Publish />}
              className={headerClasses.btn_upload}
              onClick={() => setUploadOpen(true)}
            >
              Upload
            </Button>
          )}
        </Header>
        <Table
          headers={Headers}
          order={order}
          setOrder={setOrder}
          data={attendanceData?.get_driver_attendances?.attendances}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          // filtered={filtered}
          getVariables={getVariables}
        >
          {attendanceData?.get_driver_attendances?.count ? (
            <>
              {attendanceData?.get_driver_attendances?.attendances &&
                stableSort(
                  attendanceData?.get_driver_attendances?.attendances,
                  getComparator(order.direction, order.field)
                ).map((info, index) => {
                  const isItemSelected = isSelected(info.id);
                  return (
                    <TableRow
                      key={info.id}
                      className={classes.table_data_container}
                    >
                      {(getVariables()?.condition?.length > 0 ||
                        getVariables().dateFilter?.field ===
                          "driver_attendance.time_in") && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={event => handleClick(event, info)}
                          />
                        </TableCell>
                      )}
                      <TableCell className={classes.tableColumn}>
                        <span className={classes.table_cell_value}>
                          <Highlight search={searchKeyword.value}>
                            {info.personnel_employeeid}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={classes.table_cell_value}>
                          <Highlight search={contextData.searchKeyword.value}>
                            {info.personnel_name}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={classes.table_cell_value}>
                          <Highlight search={contextData.searchKeyword.value}>
                            {moment(info.time_in).format("ll")}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={classes.table_cell_value}>
                          <Highlight search={contextData.searchKeyword.value}>
                            {moment(info.time_in).format("LT")}
                            {info.time_out_activity
                              ? info.time_out_activity
                              : ""}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={classes.table_cell_value}>
                          <Highlight search={contextData.searchKeyword.value}>
                            {moment(info.time_out).format("LT")}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            fontSize: 12,
                            color: statusColorHandler(info.status_code)
                          }}
                        >
                          <Highlight search={contextData.searchKeyword.value}>
                            {info.status_code}
                          </Highlight>
                        </span>
                      </TableCell>
                      <TableCell>
                        {info.status_code.toUpperCase() === "FOR APPROVAL" ||
                        info.status_code.toUpperCase() === "INCOMPLETE" ? (
                          <IconButton
                            className={classes.timesheet_icon}
                            onClick={() => {
                              setUpdateStatus(info);
                              setWorkHours({
                                "Employee ID": info.personnel_employeeid,
                                "Driver Name": info.personnel_name,
                                Vehicle: info.plateno,
                                Status: info.status_code,
                                Date: info.time_in,
                                "Time in": info.time_in,
                                "Time out": info.time_out
                              });
                              setStatus(true);
                              AddLogs(
                                "Admin - Working Hours",
                                "view",
                                info.personnel_name
                              );
                            }}
                          >
                            <PlaylistAddCheckRoundedIcon />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <VisibilityRoundedIcon
                              onClick={() => {
                                setUpdateStatus(info);
                                setWorkHours({
                                  "Employee ID": info.personnel_employeeid,
                                  "Driver Name": info.personnel_name,
                                  Vehicle: info.plateno,
                                  Status: info.status_code,
                                  Date: info.time_in,
                                  "Time in": info.time_in,
                                  "Time out": info.time_out
                                });
                                setStatus(true);
                                AddLogs(
                                  "Admin - Working Hours",
                                  "view",
                                  info.personnel_name
                                );
                              }}
                            />
                          </IconButton>
                        )}
                        <IconButton
                          disabled={!editPermission}
                          onClick={() => {
                            setDelModal(true);
                            setUpdateStatus(info);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </>
          ) : (
            <TableRow style={{ backgroundColor: "white", height: 60 * 2 }}>
              <TableCell colSpan={8} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </Table>
        <ConfirmationDialogTimeSheet
          toggle={status}
          title={headerStat(workHours?.Status)}
          content={workHours}
          editPermission={editPermission}
          yesBtn="Approve"
          noBtn="Disapprove"
          extraBtn="For Approval"
          fn={e => handleTimeStatus(e)}
          close={() => handleClose()}
        />
        <ConfirmationDialog
          toggle={appModal}
          title="Approve this Timesheet"
          content={
            updateStatus?.status_code_id ===
              Number(process.env.REACT_APP_DRIVER_STATUS_CODE_DISAPPROVED) ||
            updateStatus?.status_code_id ===
              Number(process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED) ? (
              <p>
                You are about to approved a Disapprove timesheet. Status will
                change to
                <span style={{ color: "green" }}> APPROVED</span>. Are you sure
                you want to proceed?
              </p>
            ) : (
              "Are you sure you want to approve this timesheet?"
            )
          }
          fn={() => handleOfficialBusiness()}
          close={() => handleCloseOB()}
        />
        <ConfirmationDialog
          toggle={forModal}
          title="Change this Timesheet"
          content={
            updateStatus?.status_code_id ===
              Number(process.env.REACT_APP_DRIVER_STATUS_CODE_DISAPPROVED) ||
            updateStatus?.status_code_id ===
              Number(process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED) ? (
              <p>
                You are about to change an Approve/Disapprove timesheet. Status
                will change to
                <span style={{ color: "orange" }}> FOR APPROVAL</span>. Are you
                sure you want to proceed?
              </p>
            ) : (
              "Are you sure you want to approve this timesheet?"
            )
          }
          fn={() => handleOfficialBusiness()}
          close={() => handleCloseOB()}
        />
        <ConfirmationDialog
          toggle={disModal}
          title={
            updateStatus?.status_code_id ===
            Number(process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED)
              ? "Change this Timesheet"
              : "Disapprove this Timesheet"
          }
          content={
            updateStatus?.status_code_id ===
            Number(process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED) ? (
              <p>
                You are about to disapprove an Approve timesheet. Status will
                change to
                <span style={{ color: "red" }}> DISAPPROVED</span>. Are you sure
                you want to proceed?
              </p>
            ) : (
              "Are you sure you want to disapprove this timesheet?"
            )
          }
          fn={() => handleOfficialBusiness()}
          close={() => handleCloseOB()}
        />

        <ConfirmationDialog
          toggle={delModal}
          title="Delete?"
          content="Are you sure you want to delete this timesheet?"
          fn={() => handleDeleteAttendance()}
          close={() => handleCloseDel()}
        />
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_WORKING_HOURS}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                classes={{ root: formStyles.textFieldRoot }}
                showTodayButton
                label="From"
                openTo="date"
                placeholder="Select Date/Time"
                value={tempFilter.from}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    from: moment(date).format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                classes={{ root: formStyles.textFieldRoot }}
                showTodayButton
                openTo="date"
                label="To"
                placeholder="Select Date/Time"
                value={tempFilter.to}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    to: moment(date).format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>Status</InputLabel>
            <Select
              fullWidth
              classes={{ root: formStyles.selectRoot }}
              inputlabelprops={{
                shrink: true,
                style: { fontWeight: "bold", color: "black" }
              }}
              value={tempFilter.status}
              onChange={e => {
                setTempFilter({
                  ...tempFilter,
                  status: e.target.value
                });
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {[
                { id: "39", value: "For Approval" },
                { id: "40", value: "Approved" },
                { id: "41", value: "Disapproved" },
                { id: "42", value: "Incomplete" }
              ].map(opt => (
                <MenuItem value={opt.id} key={opt.value}>
                  {opt.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Autocomplete
              disableClearable
              options={driverData?.get_personnels?.personnels || []}
              getOptionSelected={(o, v) => o.employeeid === v.employeeid}
              getOptionLabel={option => option.employeeid}
              renderOption={option => option.employeeid}
              value={tempFilter.driver}
              onChange={(e, nv) => {
                setTempFilter({ ...tempFilter, driver: nv });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Driver's Name"
                  onChange={e => setDriverKeyword(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Filter>
        {menuAnchorEl && (
          <DownloadMenu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            tableData={dataToArray()}
            handleClosePopper={handleClosePopper}
            handleListKeyDown={handleListKeyDown}
          />
        )}
        <Upload
          loading={uploading}
          columns={columns}
          open={uploadOpen}
          onDialogClose={() => setUploadOpen(false)}
          validateTemplate={(col1, col2) => {
            return (
              col1.join(",") === col2.join(",") &&
              col1.length > 0 &&
              col2.length > 0
            );
          }}
          fileRules={{
            minLength: 1,
            maxLength: 1002
          }}
          validate={columnValues => validateColumn(columnValues.data)}
          validForOverride={false}
          onSubmit={async ({ validRowsObject }) => {
            if (validRowsObject.length <= 0) return { errors: [] };

            const payload = validRowsObject.map(({ data }) => ({
              ...data
            }));

            const response = await uploadWorkingHours({
              variables: {
                working_hours: payload
              },
              onCompleted(data) {
                refetch();
                AddLogs("Admin - Working Hours", "upload", ``);
              }
            });

            // const response = { data: { add_driver_attendances: [] } }

            const { data } = response;
            const { add_driver_attendances } = data;

            // ⚠️ Make this block of code reusable
            //    - Ex: getErrors(mutationResponse): Array<UploadErrors>
            const errors = add_driver_attendances.reduce(
              (accum, current, index) => {
                current.error.forEach(error => {
                  const { field, message } = error;
                  const { row, data: payloadData } = validRowsObject[index];

                  accum.push({
                    row,
                    field,
                    message,
                    data: payloadData,
                    override: false,
                    success: false
                  });
                });

                return accum;
              },
              []
            );

            return {
              errors
            };
          }}
        />
      </>
    </AccessControl>
  );
};

export default WorkingHours;

const useStyles = makeStyles(theme => ({
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  table_cell_value: {
    color: theme.palette.primary.dark,
    fontSize: 12
  },
  timesheet_icon: {
    color: theme.palette.primary.main
  },
  tableColumn: {
    padding: "12px 12px 12px 20px"
  }
}));
