/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment } from "react";
import { useQuery } from "@apollo/client";
import {
  DialogTitle,
  InputBase,
  Paper,
  IconButton,
  ListItem,
  // ListItemText,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Grid,
  Container
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FixedSizeList } from "react-window";
import Cookie from "js-cookie";
import _ from "lodash";
import { GET_DEVICES } from "../../graphql/Queries";
import Loading from "../Loading";
import moment from "moment";
import Swal from "sweetalert2";
import UnpairingDialog from "./UnpairingDialog";
import {
  getBookingsOfVehicle,
  unpairDeviceFromVehicle,
  resetTrips
} from "../functions/unpairingFunctions";
import useDebounce from "../hooks/useDebounce";
import { useRef } from "react";
import useUserContext from "../../context/User/useUserContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  title: {
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  listPrimary: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    minHeight: 24
  },
  inline: {
    display: "inline",
    marginRight: "50px",
  },
  circularButton: {
    borderRadius: "25px"
  },
  swal: {
    zIndex: 10000000000
  },
  info: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px"
  }
}));

const PairDeviceModal = props => {
  const classes = useStyles();
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const refetchRef = useRef();
  const { toggle, initialData, closeVehicleModal, setDeviceInfo } = props;
  const [unpairModal, setUnpairModal] = useState(false);
  const debounceTime = 800;
  const [keyword, setSearch] = useDebounce("", debounceTime);
  const [chosenDevice, setChosenDevice] = useState({ devicealias: null });
  const [bookings, setBookings] = useState({});
  const [loading, setLoading] = useState(false);

  const { data, loading: loadingDevices, refetch } = useQuery(GET_DEVICES, {
    variables: {
      first: 8,
      group_ids: user.group_ids,
      keyword: keyword,
      dateRange: { start: moment().format("YYYY-MM-DD 00:00:00") },
      not: [
        { field: "rover_status.id", value: "" },
        { field: "rover_configs.id", value: "" },
        { field: "devices.id", value: +initialData?.device_id ? initialData?.device_id.toString() : "" }
      ]
    },
    skip: !toggle,
    fetchPolicy: "network-only"
  });

  const handleClose = () => {
    setSearch("");
    closeVehicleModal(refetchRef.current);
  }

  const processUnpairVehicle = async () => {
    const updateVehicleResult = await unpairDeviceFromVehicle({
      vehicle_id: chosenDevice.vehicle_info.id,
      name: chosenDevice.name
    });
    if (updateVehicleResult.success) {
      Swal.fire({
        icon: "success",
        text: "Device was unpaired",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: `${classes.swal}`
        }
      });
      setChosenDevice({ devicealias: null });
      refetch();
      refetchRef.current = true;
    } else {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false,
        customClass: {
          container: `${classes.swal}`
        }
      });
    }
    setLoading(false);
  }

  const handleUnpairing = async () => {
    setLoading(true);
    // check if device has trip, process reset trips and edit vehicle
    if (bookings?.futureTrips?.forReset.length) {
      const resetResult = await resetTrips(bookings.futureTrips.forReset);
      if (resetResult.success) {
        processUnpairVehicle();
      } else {
        setLoading(false);
        setChosenDevice({ devicealias: null });
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            container: `${classes.swal}`
          }
        });
      }
    } else {
      processUnpairVehicle();
    }
  };

  const getBookings = async (device_id, plateno) => {
    setLoading(true);
    const trips = await getBookingsOfVehicle(device_id, plateno);
    if (trips.error) {
      setChosenDevice({ devicealias: null });
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false,
        customClass: {
          container: `${classes.swal}`
        }
      });
      return;
    };
    setBookings(trips);
    setUnpairModal(true);
    setLoading(false);
  };

  function renderRow(prop) {
    const { index, style, data } = prop;
    const device = data[index];
    let styling = { ...style };
    styling = { ...styling, marginTop: "5px" };
    return (
      <Fragment>
        <ListItem
          button
          style={styling}
          key={index}
          onClick={() => {
            setChosenDevice(device);
            if (device.vehicle_info?.id) {
              getBookings(device.id, device.vehicle_info.plateno);
            } else {
              setBookings({});
            }
          }}
          selected={+chosenDevice.id === +device.id}
        >
          <Grid container>
            <Grid item xs={8}>
              <div>
                <Typography
                  component="div"
                  className={classes.listPrimary}
                >
                  {device.devicealias}
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                >
                  Alias Name:
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Typography
                  component="div"
                >
                  {!device.vehicle_info.id
                    ? "UNPAIRED"
                    : "PAIRED"}
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                >
                  Status:
                </Typography>
              </div>
            </Grid>
          </Grid>
        </ListItem>
      </Fragment>
    );
  }

  return (
    <div>
      {loading && <Loading />}
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.title}>
            <span>
              Select GPS Device
            </span>
            {!!+initialData?.device_id && (
              <Button
                onClick={() => setDeviceInfo()}
                variant="outlined"
                color="primary"
                className={classes.circularButton}
              >
                Unpair
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Select Device Alias"
              inputProps={{ "aria-label": "plate #" }}
              onChange={e => setSearch(e.target.value)}
            />
            <IconButton
              disabled={true}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          {loadingDevices ? <CircularProgress size={40} /> : (
            <DialogContentText
              id="alert-dialog-description"
              component="span"
              style={{ textAlign: "center" }}
            >
              {data?.get_devices?.devices?.length ? (
                <FixedSizeList
                  height={400}
                  width="auto"
                  itemSize={56}
                  itemCount={data.get_devices.devices.length}
                  itemData={data.get_devices.devices}
                >
                  {renderRow}
                </FixedSizeList>
              ) : (
                <Typography
                  variant="h6"
                  component="span"
                  style={{ marginTop: "20px" }}
                >
                  No Available Devices
                </Typography>
              )}
              <Container className={classes.infoContainer}>
                <div>
                  Vehicle: <span className={classes.info}>{initialData?.plateno}</span>
                </div>
                <div>
                  Device: 
                  {initialData?.device_info?.devicealias ? (
                    <span className={classes.info}> {initialData.device_info.devicealias}</span>
                  ) : " No GPS Device"}
                </div>
              </Container>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            color="primary"
            className={classes.circularButton}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            className={classes.circularButton}
            disabled={!(chosenDevice?.devicealias && !chosenDevice?.vehicle_info?.id)}
            onClick={() => setDeviceInfo(chosenDevice)}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <UnpairingDialog
        toggle={unpairModal}
        close={() => setUnpairModal(false)}
        fn={handleUnpairing}
        referenceData="device"
        tripInfo={{
          trips: bookings,
          devicealias: chosenDevice?.devicealias || "",
          plateno: chosenDevice.vehicle_info?.plateno || initialData.plateno
        }}
      />
    </div>
  );
};

export default PairDeviceModal;
