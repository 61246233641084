import { Button } from "@material-ui/core";
import React, { useState } from "react";
import ArrowRightAltSharpIcon from "@material-ui/icons/ArrowRightAltSharp";
import { modalRef, useOnboardingContext } from "../..";
import pagesInfo from "./pages.data";
import curves from "../../images/Introductory/curve-swirls.svg";
import useIntroductoryStyles from "./introductory.styles";

const Introductory = () => {
  const [page, setPage] = useState(-1);
  const { completeIntro } = useOnboardingContext();
  const isIntro = page === -1;
  const { image, title, description } = pagesInfo[page];
  const classes = useIntroductoryStyles({ page });

  const handlePageChange = nextPage => {
    if (nextPage === undefined) {
      setPage(prev => {
        const tempPage = prev + 1;
        if (tempPage <= 3) return tempPage;
        return prev;
      });
      return;
    }
    setPage(nextPage);
  };
  if (modalRef.current) modalRef.current.openModal();
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.heading}>
          <img src={curves} alt="curves" className={classes.curves} />
        </div>
        <div className={classes.image_container}>
          <img src={image} alt="introductory" className={classes.image} />
        </div>
        <div className={classes.body}>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{description}</p>
          <div className={classes.pages}>
            {!isIntro &&
              [0, 1, 2, 3].map(dotPage => (
                <button
                  type="button"
                  onClick={() => handlePageChange(dotPage)}
                  className={`${classes.dot} ${page === dotPage &&
                    classes.activeDot}`}
                />
              ))}
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            classes={{ label: classes.button_label }}
            color="primary"
            endIcon={isIntro && <ArrowRightAltSharpIcon />}
            onClick={() => {
              if (page + 1 > 3) {
                completeIntro();
                return;
              }
              handlePageChange();
            }}
          >
            {isIntro ? "Get Started" : "Next"}
          </Button>
          {!isIntro && page < 3 && (
            <Button
              classes={{ label: classes.button_label }}
              style={{ color: "#a1a1a1" }}
              onClick={completeIntro}
            >
              Skip
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Introductory;
