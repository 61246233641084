import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  "@keyframes animEntry": {
    "0%": {
      transform: "translateX(-100%)"
    },
    "50%": {
      transform: "translateX(0%)"
    }
  },
  "@keyframes animExit": {
    "0%": {
      transform: "translateX(20%)"
    },
    "50%": {
      transform: "translateX(0%)"
    }
  },
  addItem: {
    transformOrigin: "left",
    transition: "all .5s linear",
    animation: `$animEntry .5s forwards`
  },
  removeItem: {
    transformOrigin: "right",
    transition: "all .5s linear",
    animation: `$animExit .5s forwards`
  },
  container: {
    background: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "2rem 0 0 2rem",
    position: "relative"
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  items: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    marginTop: "2rem",
    height: "80%"
  },
  paper: {
    width: "300px",
    height: "220px",
    display: "flex",
    gap: "1rem",
    padding: "5px",
    boxShadow: "none",
    border: "none"
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem"
  },
  badge: {
    width: "25px",
    height: "25px",
    background: "#ff8f00",
    borderRadius: "50%",
    textAlign: "center",
    color: "white",
    padding: 2
  },
  icon: {
    color: "#ff8f00",
    cursor: "pointer"
  },
  remarks_txt: {
    padding: "8px",
    fontSize: "small",
    width: "90%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start"
  },
  btn: {
    background: "#ff8f00",
    color: "white",
    borderRadius: "30px",
    width: "250px",
    textAlign: "center",
    "&:hover": {
      background: "#ff8f00"
    },
    "&:disabled": {
      color: "white"
    }
  },
  btn_back: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: "2rem",
    "& p": {
      fontSize: "1.25rem",
      fontWeight: 600,
      marginLeft: "0.75rem",
      textTransform: "none",
      letterSpacing: 1
    }
  },
  bottom: {
    textAlign: "center",
    marginBottom: "3rem",
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%, 50%)",
    width: "100%"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dropdown_btn: {
    width: "200px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #F49400",
    padding: "3px 10px",
    borderRadius: "3px",
    cursor: "pointer"
  },
  dropdown_menu: {
    width: "220px",
    position: "absolute",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 16%)",
    borderRadius: "5px",
    border: "1px solid #ddd",
    background: "white",
    marginTop: "4px",
    zIndex: "10",
    "& ul": {
      padding: "0",
      margin: "0",
      listStyleType: "none"
    },
    "& ul li": {
      padding: "5px 8px"
    },
    "& ul li:hover": {
      cursor: "pointer",
      background: "#e4e1e1"
    }
  }
});