import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./SeeMoreStyle";
import _ from "lodash";

const SeeMore = ({ children }) => {
  const classes = useStyles();
  const text = children;
  let btnShowMoreVisible = !_.isEqual(text, "-") && text.length > 70;
  const [isSeeMore, setIsSeeMore] = useState(true);
  const seeMoreHandler = () => setIsSeeMore(!isSeeMore);

  return (
    <p className={classes.typography}>
      {isSeeMore ? `${text.slice(0, 70).trim(" ")}${btnShowMoreVisible? '...': ''}` : text}
      {btnShowMoreVisible && (
        <span className={classes.showOrHide} onClick={seeMoreHandler}>
          {isSeeMore ? "see more" : "see less"}
        </span>
      )}
    </p>
  );
};

export default SeeMore;
