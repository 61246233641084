import React, { useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { GetApp as GetAppIcon } from "@material-ui/icons";
import buttonStyles from "./buttonStyles";
import AddLogs from "../../../utils/functions/AddLogs";
import SearchContext from "../../../context/SearchContext";

const moduleName = process.env.REACT_APP_AUTOMATED_REPORTS_MODULE;

const DownloadButton = ({ data }) => {
  const { searchKeyword } = useContext(SearchContext);

  const styles = buttonStyles({
    buttonType: "download",
    maxWidth: 150,
    minWidth: 150
  });

  const handleAddLoggings = () => {
    AddLogs(
      `${moduleName}`,
      "download_table",
      `Searched: ${searchKeyword.value || "<empty>"}`
    );
  };

  return (
    <CSVLink
      data={data}
      className={styles.link}
      filename="Automated Reports.csv"
      onClick={handleAddLoggings}
    >
      <Button
        className={styles.button}
        variant="contained"
        startIcon={<GetAppIcon />}
      >
        <Typography variant="subtitle1">Download</Typography>
      </Button>
    </CSVLink>
  );
};

export default DownloadButton;
