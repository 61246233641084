import React from "react";
import { withRouter } from "react-router-dom";
import ReportsDemo from "../../utils/Onboarding/modules/Reports/Manual";
import Reports from "./Create";
import Onboarding, {
  useOnboardingContext,
  onboarding_max,
  current_module_ref
} from "../../utils/Onboarding";
import useUserContext from "../../context/User/useUserContext";
import Introductory from "../../utils/Onboarding/pages/Introductory";

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.reports,
    allowContextToChange: true
    // stepStartsIn: 0
  });

  const reportsProgress = progress[Onboarding.modules.reports];
  const isDemoCompleted =
    reportsProgress >= onboarding_max[Onboarding.modules.reports];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Reports /> : <ReportsDemo />;
};

export default withRouter(Main);
