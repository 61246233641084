/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import {
  Badge,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  FormControl,
  Select,
  IconButton,
  TextField,
  Tooltip,
  InputLabel,
  TableContainer,
  Table,
  TableBody,
  // CircularProgress,
  InputAdornment
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  FilterList as FilterListIcon,
  Event as EventIcon
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import Loading from "../../../../utils/Loading";
import Filter from "../../Filter";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import {
  GET_SMS_REQUESTS,
  GET_CLIENTS_QUERY_WO_GROUPS,
  GET_MOBILE_NUMBERS
} from "../../../../graphql/Queries";
import AddLogs from "../../../../utils/functions/AddLogs";
import useFormStyles from "../../../../styles/useFormStyles";
import Pagination from "../../../../utils/table/Pagination";
import ClientAutocomplete from "../../../../utils/ClientAutocomplete";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  // {
  //   id: "checkbox",
  //   numeric: false,
  //   disablePadding: false,
  //   label: ""
  // },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created Date"
  },
  { id: "message", numeric: false, disablePadding: false, label: "Message" },
  {
    id: "use_globe_api",
    numeric: false,
    disablePadding: false,
    label: "Is_Globe"
  },
  {
    id: "mobile_number",
    numeric: false,
    disablePadding: false,
    label: "Recipient"
  },
  {
    id: "sent",
    numeric: false,
    disablePadding: false,
    label: "Status"
  }
];

function TableHeadCells(props) {
  const { classes, order, orderBy, handleSort } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000",
    display: "flex",
    alignItems: "center"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

export default function SMSSending() {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const user = JSON.parse(Cookies.get("user"))
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [canDelete, setCanDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [smsData, setSMSData] = useState([]); // set initial data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(1); // setPage
  const [filterDrawer, setFilterDrawer] = useState(false); // set to open filter drawer
  const [smsKeyword, setSMSKeyword] = useState(""); // set to user search
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created");
  const [filter, setFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [clearFirst, setClearFirst] = useState(false);
  const [tempFilter, setTempFilter] = useState({
    dateFrom: null,
    dateTo: null,
    mobile_number: "",
    sent: ""
  });
  const [tempMobile, setTempMobile] = useState("");
  const [groupFilter, setGroupFilter] = useState({ 
    id: user.group_ids[0], 
    name: user.group_names[0],
    group_ids: [user.group_ids[0]]
   });
  const [groupFilterOptions, setGroupFilterOptions] = useState([
    { id: "", name: "All" }
  ]);
  const [disableButton, setDisableButton] = useState(false);
  // const [circularLoading, setCircularLoading] = useState(false);
  const fixedStartDate = moment()
    .subtract(30, "day")
    .format("YYYY-MM-DD 00:00:00");
  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (smsKeyword) {
      AddLogs(process.env.REACT_APP_SMS_SENDING_MODULE, "search", smsKeyword);
      setLoading(true);
    }
  }, [smsKeyword]);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setLoading(true);
      setSMSKeyword(searchKeyword.value);
    }
  }, [searchKeyword, clearFirst]);

  const getVariables = () => {
    const variables = {
      keyword: smsKeyword,
      first: rowsPerPage,
      skip: Number(page - 1) * rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }],
      dateFilter: { field: "created", gte: fixedStartDate },
      condition: []
    };

    if (groupFilter.id) {
      Object.assign(variables, {
        condition: [
          ...variables.condition,
          {
            field: "group_ids",
            value: [groupFilter.group_ids[0].toString()]
          }
        ]
      });
    }

    if (filter.length > 0) {
      const tempArray = [];
      filter.forEach(element => {
        switch (element.field) {
          case "dateFilter":
            variables.dateFilter = {};
            Object.assign(variables, { dateFilter: element.value });
            break;
          default:
            tempArray.push(element);
            break;
        }
      });

      if (tempArray.length > 0) {
        Object.assign(variables, {
          condition: [...variables.condition, ...tempArray]
        });
      }
    }

    return variables;
  };

  const {
    data: currentData,
    refetch,
    fetchMore,
    loading: smsLoading
  } = useQuery(GET_SMS_REQUESTS, {
    variables: getVariables(),
    onCompleted: () => {
      setLoading(false);
      setDisableButton(false);
      // setCircularLoading(false);
      setPage(Number(page));
      setSelected([]);
    },
    fetchPolicy: "network-only"
  });

  // const { data: groupsData } = useQuery(GET_CLIENTS_QUERY, {
  //   variables: { first: 10000 }
  // });

  const { data: mobileNumberData } = useQuery(GET_MOBILE_NUMBERS);

  useEffect(() => {
    if (currentData) {
      setSMSData(currentData.get_sms_requests.sms_requests);
      setTotalRows(currentData.get_sms_requests.total);
    }
  }, [currentData]);

  // useEffect(() => {
  //   if (groupsData) {
  //     setGroupFilterOptions([
  //       ...groupFilterOptions,
  //       ...groupsData.get_clients.clients
  //     ]);
  //   }
  // }, [groupsData]);

  const handlePagination = event => {
    setDisableButton(true);
    // setCircularLoading(true);
    setLoading(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    refetch(getVariables());
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  // eslint-disable-next-line no-unused-vars
  const getData = () => {
    if (smsData) {
      const x = stableSort(smsData, getSorting(order, orderBy)).slice(
        (Number(page) - 1) * rowsPerPage,
        (Number(page) - 1) * rowsPerPage + rowsPerPage
      );
      return x;
    }
    return null;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setDisableButton(false);
        // setCircularLoading(false);
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_sms_requests: {
            count: fetchMoreResult.get_sms_requests.count,
            total: fetchMoreResult.get_sms_requests.total,
            sms_requests: [
              // ...prev.get_sms_requests.sms_requests,
              ...fetchMoreResult.get_sms_requests.sms_requests
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(1);
          refetch(getVariables());
        }
      });
    });
  };

  // Function: Block other inputs beside number/integer
  const handleKeyPress = event => {
    // get the event value based on keyCode or which
    const keyCode = event.keyCode || event.which;
    // convert variable keyCode into a string
    const keyValue = String.fromCharCode(keyCode);
    // check if variable keyValue is NOT number or integer
    if (/[^0-9]/g.test(keyValue)) {
      // if true
      // blocked the value of keyValue
      event.preventDefault();
    }
  };

  // Function used to navigate using input value
  const handlePage = e => {
    setLoading(true);
    const value = Number(e.target.value);
    // check if page is less than the inputted value
    if (page < value) {
      // setCircularLoading(true);
      setLoading(true);
      setDisableButton(true);
      // check if the input value is greater than the total page
      if (value > Math.ceil(totalRows / rowsPerPage)) {
        // if true
        setPage(1);
        setDisableButton(false);
        // setCircularLoading(false);
        setLoading(false);
      } else {
        // if true
        // loop the input value to request the data without skipping other page
        for (let i = 1; i <= value; i += 1) {
          // check if the value is already fetch to the server
          if (
            i * rowsPerPage > smsData.length &&
            (i - 1) * rowsPerPage >= smsData.length
          ) {
            fetchMoreData(i - 1);
          } else {
            setDisableButton(false);
            // setCircularLoading(false);
            setLoading(false);
          }
        }
        // set page based on user's input
        setPage(value);
        // setDisableButton(false);
        // setCircularLoading(false);
        setLoading(false);
      }
    }
    // check if the input value is zero
    else if (Number(value) === 0) {
      // set page to default
      setPage(1);
      setDisableButton(false);
      // setCircularLoading(false);
      setLoading(false);
    } else {
      // set page based on user's input
      setPage(value);
      setDisableButton(false);
      // setCircularLoading(false);
      setLoading(false);
    }
  };

  // Function used to navigate to previous page
  const handleDownPage = () => {
    // set page to previous page value
    setDisableButton(false);
    // setCircularLoading(false);
    // setLoading(false);
    setPage(Number(page) - 1);
  };

  // Function used to navigate to next page
  const handleUpPage = () => {
    // set page to current page incremented by 1
    setPage(Number(page) + 1);
    // check if the requested page data is already fetch
    if (
      page * rowsPerPage === smsData.length ||
      page * rowsPerPage >= smsData.length
    ) {
      // if true
      // fetch more data from gql useQuery
      fetchMoreData(page);
    } else {
      // if false
      // set loading to false
      setDisableButton(false);
      // setCircularLoading(false);
      setLoading(false);
    }
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleClearFilter = () => {
    setFilter([]);
    setTempFilter({
      dateFrom: null,
      dateTo: null,
      mobile_number: "",
      sent: ""
    });
    setTempMobile("");
    // setLoading(true);
    setFilterDrawer(false);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const handleFilters = e => {
    const { name, value } = e.target;

    switch (name) {
      case "sent":
        setTempFilter({
          ...tempFilter,
          sent: value !== "All" ? value : ""
        });
        break;
      case "recipient":
        setTempFilter({
          ...tempFilter,
          mobile_number: value
        });
        break;
      default:
        break;
    }
  };

  const getSentStatus = (val, type) => {
    let color = "";
    let text = "";
    switch (Number(val)) {
      case 0:
        color = "#808080";
        text = "Pending";
        break;
      case 1:
        color = "#34CB1B";
        text = "Sent";
        break;
      case 2:
        color = "#F2C94C";
        text = "Ongoing";
        break;
      case 3:
        color = "#FF6363";
        text = "Failed";
        break;
      default:
        break;
    }
    if (type === "text") {
      // For csv download
      return text;
    }
    // For table display
    return (
      <p style={{ fontWeight: "bold", color: color }}>
        <Highlight search={contextData.searchKeyword.value}>{text}</Highlight>
      </p>
    );
  };

  const SMSSendingTable = () => {
    if (loading || smsLoading) {
      return (
        <Loading />
        // <TableBody>
        //   <TableRow style={{ height: 53 * 2 }}>
        //     <TableCell colSpan={6} align="center">
        //       <CircularProgress />
        //     </TableCell>
        //   </TableRow>
        // </TableBody>
      );
    }
    return smsData ? (
      <TableBody>
        {smsData.map(row => {
          return (
            <TableRow key={`row-${row.id}`} hover role="checkbox" tabIndex={-1}>
              <TableCell />
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {moment(row.created)
                    .subtract(8, "hours")
                    .format("lll")}
                </Highlight>
              </TableCell>
              <TableCell width="50%">
                <Highlight search={contextData.searchKeyword.value}>
                  {row.message}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.use_globe_api ? "1" : "0"}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.mobile_number}
                </Highlight>
              </TableCell>
              <TableCell>{getSentStatus(row.sent)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const handleSetFilters = () => {
    // setLoading(true);
    const temp = [];
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        if (key === "dateFrom" || key === "dateTo") {
          temp.push({
            field: "dateFilter",
            value: {
              field: "created",
              gte: tempFilter.dateFrom
                ? moment(tempFilter.dateFrom)
                    .add(8, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
                : fixedStartDate,
              lte: tempFilter.dateTo
                ? moment(tempFilter.dateTo)
                    .add(8, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
                : null
            }
          });
        }
        if (key !== "dateFrom" && key !== "dateTo") {
          temp.push({ field: key, value: tempFilter[key] });
        }
        return temp;
      });
    setFilter(temp);
    closeFilterDrawer();
    setPage(1);

    // Activity Logs
    const { dateFrom, dateTo, mobile_number, sent } = tempFilter;
    if (dateFrom || dateTo || mobile_number || sent) {
      AddLogs(
        process.env.REACT_APP_SMS_SENDING_MODULE,
        "filter",
        `created date range from: ${dateFrom || "All"} to: ${dateTo ||
          "All"},recipient: ${mobile_number || "All"},status: ${
          sent ? getSentStatus(Number(sent), "text") : "All"
        }`
      );
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [
      ["Created Date *", "Message *", "Is_Globe *", "Recipient *", "Status *"]
    ];

    smsData.map(row =>
      temp.push([
        moment(row.created)
          .subtract(8, "hours")
          .format("YYYY-MM-DD HH:mm:ss") || "",
        row.message || "",
        row.use_globe_api ? "1" : "0",
        row.mobile_number || "",
        getSentStatus(row.sent, "text") || ""
      ])
    );

    return temp;
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          style={{ textDecoration: "none", color: "black" }}
          data={tableData}
          onClick={() => {
            // Activity Logs
            const { dateFrom, dateTo, mobile_number, sent } = tempFilter;
            AddLogs(
              process.env.REACT_APP_SMS_SENDING_MODULE,
              "download_table",
              `created date range from: ${dateFrom || "All"} to: ${dateTo ||
                "All"},recipient: ${mobile_number || "All"},status: ${
                sent ? getSentStatus(Number(sent), "text") : "All"
              }`
            );
          }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <Link
                      to="/assets/sms_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem>{tableDownload()}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  const getNoDataText = () => {
    if (filter.length) {
      return "No items on table matched the filter results";
    }
    if (smsKeyword) {
      return "No items on table matched the search";
    }
    return "No data found";
  };

  // if (loading) return <Loading />;

  return (
    <AccessControl
      resource="/admin/sms_sending/"
      process="tables"
      setCanDelete={setCanDelete}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              SMS Sending
              {/* <Autocomplete
                className={classes.autoComplete}
                style={{ height: 30, marginLeft: 30, width: 220 }}
                disableCloseOnSelect
                options={groupFilterOptions}
                getOptionSelected={(o, v) => v.name === o.name}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField {...params} placeholder="All" />
                )}
                disableClearable
                value={groupFilter}
                onChange={(e, nv) => {
                  setSearchKeyword(prev => ({ ...prev, group: nv }));
                  setGroupFilter(nv);
                  // setLoading(true);
                  setPage(1);
                }}
              /> */}
              <ClientAutocomplete 
                groupFilter={groupFilter}
                setter={setGroupFilter}
              />
            </Typography>
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
              disabled={disableButton}
            >
              Download
            </Button>
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                onClick={openFilterDrawer}
                disabled={disableButton}
              >
                <Badge
                  color="secondary"
                  variant={filter.length ? "dot" : "standard"}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Pagination
                startsWithZero={false}
                rowsPerPage={rowsPerPage}
                total={Math.ceil(totalRows / rowsPerPage)}
                currentPage={page}
                onRowsPerPageChange={n => {
                  setRowsPerPage(n);
                  setPage(1);
                }}
                onPageChange={n => setPage(n)}
                onPageUp={n => setPage(n)}
                onPageDown={n => setPage(n)}
              />
              {/* <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                  disabled={disableButton}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) <= 1 || disableButton}
                onClick={() => {
                  // setCircularLoading(true);
                  // setLoading(true);
                  setDisableButton(true);
                  handleDownPage();
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                disabled={disableButton}
                onFocus={e => {
                  e.target.select();
                }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                value={Number(page)}
                style={{ width: "32px", marginRight: "8px" }}
              />
              {`/ ${Math.ceil(totalRows / rowsPerPage)}`}
              <Button
                name="up_page"
                disabled={
                  Number(page) === Math.ceil(totalRows / rowsPerPage) ||
                  disableButton
                }
                onClick={() => {
                  // setCircularLoading(true);
                  setLoading(true);
                  setDisableButton(true);
                  handleUpPage();
                }}
              >
                <KeyboardArrowRightIcon />
              </Button> */}
            </div>
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                handleSort={handleSort}
              />
              <SMSSendingTable
                selected={selected}
                smsData={smsData}
                classes={classes}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
            {currentData && currentData.get_sms_requests.count === 0 && (
              <div
                style={{
                  width: "100%",
                  height: 72,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>{getNoDataText()}</Typography>
              </div>
            )}
          </TableContainer>
        </Paper>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_SMS_SENDING_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <span
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginBottom: "14px"
              }}
            >
              Created Date
            </span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                disableFuture
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: "bold",
                    color: "black",
                    letterSpacing: 1
                  }
                }}
                InputProps={{
                  style: { marginTop: 16 },
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                    </InputAdornment>
                  ),
                  name: "dateFrom"
                }}
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                showTodayButton
                value={tempFilter.dateFrom}
                minDate={fixedStartDate}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateFrom: date.format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                label="From"
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                disableFuture
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: "bold",
                    color: "black",
                    letterSpacing: 1
                  }
                }}
                InputProps={{
                  style: { marginTop: 16 },
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                    </InputAdornment>
                  ),
                  name: "dateTo"
                }}
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                showTodayButton
                value={tempFilter.dateTo}
                minDate={
                  tempFilter.dateFrom ? tempFilter.dateFrom : fixedStartDate
                }
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateTo: date.format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                label="To"
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Autocomplete
              fullWidth
              name="recipient"
              disableCloseOnSelect
              options={mobileNumberData?.get_unique_number}
              getOptionLabel={option => option.mobile_number}
              getOptionSelected={(o, v) => {
                if (v.mobile_number === o.mobile_number) return o.mobile_number;
                return { mobile_number: "" };
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Recipient"
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold",
                      letterSpacing: 1
                    }
                  }}
                  placeholder="Search recipient"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
              // value={
              //   tempFilter.mobile_number
              //     ? { mobile_number: tempFilter.mobile_number }
              //     : { mobile_number: "" }
              // }
              value={tempMobile}
              onChange={(e, nv) => {
                setTempMobile(nv);
                if (nv) {
                  setTempFilter({
                    ...tempFilter,
                    ...nv
                  });
                } else {
                  setTempFilter({
                    ...tempFilter,
                    mobile_number: ""
                  });
                }
              }}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink style={{ color: "black" }}>
              <b>Status</b>
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.sent ? tempFilter.sent.toString() : "All"}
              onChange={e => handleFilters(e)}
              name="sent"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
              <MenuItem value="1">Sent</MenuItem>
              <MenuItem value="2">Ongoing</MenuItem>
              <MenuItem value="3">Failed</MenuItem>
            </Select>
          </FormControl>
        </Filter>
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}
      </div>
    </AccessControl>
  );
}
