/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */

/**
 * 💡 Abstraction layer for Multiple Select (ChipInput)
 */
import React from "react";
import {
  FormControl,
  Chip,
  TextField,
  Typography,
  Checkbox
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import combineStyles, {
  useChipInputStyles,
  useFormStyles
} from "./ChipInput.styles";

const combinedStyles = combineStyles(useFormStyles, useChipInputStyles);

class ChipInput extends React.Component {
  static changesType = {
    changed: "__selected_changed__",
    add: "__add__",
    remove: "__remove__"
  };

  static defaultProps = {
    autocompleteProps: {},
    onSelectedChanged: () => {}
  };

  state = {
    loading: false,
    options: [],
    selectedValues: [],
    readOnly: false,
    autocompleteProps: {}
  };

  isControlled(prop) {
    /**
     * @function isControlled
     * 👉 This function returns 'true 👍' if prop is not undefined
     *    and returns 'false 👎' if prop is defined by consumer, user or implementor.
     * 👉 'undefined 👽' means, the consumer doesn't want to control the state of this component
     *
     * returns @boolean
     */
    return this.props[prop] !== undefined;
  }

  dynamicSetState(changes, callback) {
    /**
     * @function dynamicSetState
     * 👉 This setter function, dynamically set the state.
     * returns @void
     */
    let allChanges;
    this.setState(
      state => {
        const combinedState = this.getState(state);
        const changesObject =
          typeof changes === "function" ? changes(combinedState) : changes;
        const { type: IgnoredType, ...onlyStateChanges } = changesObject;

        allChanges = changesObject;

        // 👉 Filter the non-controlled state change
        const nonControlledChanges = Object.entries(onlyStateChanges).reduce(
          (newChanges, [key, value]) => {
            if (!this.isControlled(key)) {
              newChanges[key] = value;
            }

            return newChanges;
          },
          {}
        );

        // If non-controlled state(s) doesn't changed, it will return 'null' to prevent re-rendering.
        return Object.keys(nonControlledChanges).length
          ? nonControlledChanges
          : null;
      },
      () => {
        /**
         * This function will invoked after state has changed 👌
         */
        callback(allChanges);
      }
    );
  }

  getState(state = this.state) {
    /**
     * @function getState
     * 👉 This function returns a combined state (controlled and non-controlled state)
     * returns @object combinedState
     */
    return Object.entries(state).reduce((combinedState, [key, value]) => {
      if (this.isControlled(key)) {
        combinedState[key] = this.props[key];
      } else {
        combinedState[key] = value;
      }

      return combinedState;
    }, {});
  }

  handleSelectedChange = (_, newSelectedValues) => {
    this.dynamicSetState(
      _state => {
        return {
          type: ChipInput.changesType.changed,
          selectedValues: newSelectedValues
        };
      },
      changes => {
        /**
         * 👌 Here we invoked the onSelcetedChanged callback function
         *    including the state changes.
         */
        const { type, selectedValues } = changes;
        this.props.onSelectedChanged({ type, selectedValues });
      }
    );
  };

  /**
   * Task 📝
   *    + Create @handleOnDelete function that will invoked when
   *      user deletes an option whether the event fires on <Chip /> or <Checkbox />
   * @returns void
   */

  // 🙌 This function receives any number of functions and
  // arguments, then call those.
  compose = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

  getTextfieldProps = params => {
    return ({ /* onClick = (...args) => {}, */ InputProps, ...props } = {}) => {
      return {
        ...params,
        // onClick: this.compose(onClick, params.onClick);
        InputProps: {
          ...params.InputProps,
          ...InputProps
        },
        ...props
      };
    };
  };

  render() {
    const { options, selectedValues, loading } = this.getState();
    const {
      classes,
      autocompleteProps: { renderInput, ...autocompleteProps }
    } = this.props;

    return (
      <FormControl classes={{ root: classes.formRoot }}>
        <Autocomplete
          loading={loading}
          fullWidth
          multiple
          id="multiple-limit-tags"
          limitTags={2}
          disableClearable
          options={options}
          disableCloseOnSelect
          getOptionLabel={option => option.label?.toString() || ""}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={this.handleSelectedChange}
          value={selectedValues}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return (
                <Chip
                  {...getTagProps({ index })}
                  size="small"
                  color="primary"
                  label={
                    autocompleteProps?.getOptionLabel?.(option) || option.label
                  }
                  disabled={autocompleteProps.disabled}
                  /**
                   * ⚠ Implement onDelete function
                   *
                   * onDelete={(...args) => {
                   *   console.log("onDelete")
                   *   getTagProps({ index }).onDelete(...args);
                   * }}
                   *
                   */
                />
              );
            })
          }
          renderOption={(option, { selected: selected_status }) => (
            <React.Fragment>
              <Checkbox
                color="primary"
                icon={
                  <CheckBoxOutlineBlankIcon className={classes.iconStyle} />
                }
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.iconStyle}
                  />
                }
                checked={selected_status}
              />
              <Typography variant="body2">
                {autocompleteProps?.getOptionLabel?.(option) || option.label}
              </Typography>
            </React.Fragment>
          )}
          renderInput={params => {
            const textfieldParams = {
              ...params,
              classes: { root: classes.textFieldRoot },
              className: classes.filterInput,
              label: "Chip Input",
              placeholder: "Select",
              InputLabelProps: {
                shrink: true
              },
              InputProps: {
                style: {
                  padding: "6px 0px"
                },
                ...params.InputProps
              }
            };
            return (
              <TextField
                {...textfieldParams}
                {...renderInput?.({
                  textfieldProps: params,
                  getTextfieldProps: this.getTextfieldProps(textfieldParams)
                })}
              />
            );
          }}
          {...autocompleteProps}
        />
      </FormControl>
    );
  }
}

export default withStyles(combinedStyles)(ChipInput);
