import axios from "axios";
import md5 from "md5";

export const fetchToken = async () => {
  let tokenDetails = {};
  await axios
    .post(`${process.env.REACT_APP_VSS_URL}/vss/user/apiLogin.action`, {
      username: process.env.REACT_APP_VSS_USERNAME,
      password: md5(process.env.REACT_APP_VSS_PASSWORD)
    })
    .then(res => {
      if (res?.status === 200) {
        tokenDetails = res;
      }
    })
    .catch(err => console.log(err));

  return tokenDetails;
};
