/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  Fab
  // CircularProgress
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibleIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import Loading from "../../../../utils/Loading";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../utils/functions/AddLogs";
// import Upload from "./View/Upload";
import UploadDialog from "./View/UploadDialog";
import Pagination from "../../Pagination";

import { GET_GPS_PROVIDERS } from "../../../../graphql/Queries";
import {
  DELETE_GPS_PROVIDER,
  DELETE_MULTIPLE_GPS_PROVIDER
} from "../../../../graphql/Mutations";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "GPS Providers"
  },
  {
    id: "registered_IPS",
    numeric: false,
    disablePadding: true,
    label: "IP Address"
  },
  {
    id: "api_key",
    numeric: false,
    disablePadding: true,
    label: "API Key"
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks"
  }
  // { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

function TableHeadCells(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked,
    canEdit
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={!canEdit}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function GPSProviders() {
  const classes = useStyles();
  const history = useHistory();
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [canEdit, setcanEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [providersData, setProvidersData] = useState([]); // set initial data from backend
  const [downloadData, setDownloadData] = useState([]); // set download data from backend
  //   const [filterData, setFilterData] = useState([]); // set filter data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(1); // setPage
  const [keyword, setKeyword] = useState(""); // set to user search
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [clearFirst, setClearFirst] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [keyDel, setKeyDel] = useState();
  const [selDel, setSelDel] = useState([]);
  // const [circularLoading, setCircularLoading] = useState(false);

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [keyword]);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  const handleClose = () => {
    setDialog(false);
  };

  const getVariables = () => {
    const variables = {
      skip: 0,
      keyword: keyword,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }]
    };

    return variables;
  };

  // const { data: filterDataQuery } = useQuery(GET_GPS_PROVIDERS, {
  //   variables: {
  //     first: totalRows
  //     // first: 10000
  //   },
  //   fetchPolicy: "network-only"
  // });

  const handleCloseUpload = () => {
    setUploadOpen(false);
  };

  // useEffect(() => {
  //   if (filterDataQuery) {
  //     setDownloadData(filterDataQuery.get_gps_providers);
  //   }
  // }, [filterDataQuery]);

  const { data: currentData, refetch, fetchMore } = useQuery(
    GET_GPS_PROVIDERS,
    {
      variables: getVariables(),
      onCompleted: data => {
        setLoading(false);
        // setPage(1);
        setSelected([]);
        if (keyword) {
          AddLogs("Admin - GPS Providers", "search", searchKeyword.value);
        }
      },
      fetchPolicy: "cache-and-network"
    }
  );

  useEffect(() => {
    if (currentData) {
      setDownloadData(currentData.get_gps_providers);
    }
  }, [currentData]);

  const [getSelected] = useLazyQuery(GET_GPS_PROVIDERS, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_gps_providers.gps_providers.map(val => {
          return temp.push(val.name);
        });
        setSelDel(temp);

        mutationDeleteMultipleGPSProvider({
          variables: {
            condition: {
              field: "id",
              value: selected
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (currentData) {
      setProvidersData(currentData.get_gps_providers.gps_providers);
      setTotalRows(currentData.get_gps_providers.total);
      setDataCount(currentData.get_gps_providers.count);
      // setPage(0);
      // setLoading(false);
    }
  }, [currentData]);

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const [mutationDeleteGPSProvider] = useMutation(DELETE_GPS_PROVIDER, {
    onCompleted: response => {
      if (response.delete_gps_provider.success) {
        AddLogs("Admin - GPS Providers", "delete", keyDel);
        refetch(getVariables());
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setSelected([]);
            setToDelete([]);
            setPage(1);
          } else {
            setSelected([]);
            setToDelete([]);
            setPage(1);
          }
        });
      } else {
        handleClose();
        Swal.fire({
          title: response.delete_gps_provider.error,
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          } else {
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (
      currentData.get_gps_providers.count === 1 &&
      currentData.get_gps_providers.total > 1
    )
      setPage(page - 1);
    mutationDeleteGPSProvider({
      variables: { id: toDelete.toString() }
    });
    setToDelete([]);
  };

  const errorMessageDialog = (error, deleted) => {
    refetch(getVariables());
    handleCloseDeleteAll();
    Swal.fire({
      // title: "<i>Warning</i>",
      // icon: "info",
      html: `
        <p style="text-align: left; font-size: 1.5rem; color: #ff7043;"><b>Warning!</b></p>
        <p style="text-align: left; font-size: 1.2rem">${error[0]}</p>
        <p style="text-align: left; margin: 0;">Deleted: <b style="color: #10e23f;">${deleted}</b></p>
        <p style="text-align: left; margin: 0;">Not Deleted: <b style="color: #ff7043;">${error.length}</b></p>
      `,
      showConfirmButton: true,
      timer: 3000
    }).then(result => {
      setSelected([]);
      setToDelete([]);
      setPage(1);
      return result;
    });
  };
  const [mutationDeleteMultipleGPSProvider] = useMutation(
    DELETE_MULTIPLE_GPS_PROVIDER,
    {
      onCompleted: response => {
        const { success, error, deleted } = response.delete_gps_provider_all;
        if (success) {
          AddLogs("Admin - GPS Providers", "delete", selDel);
          if (error) {
            errorMessageDialog(error, deleted);
            return;
          }
          refetch(getVariables());
          handleCloseDeleteAll();
          Swal.fire({
            title: "Deleted",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          }).then(result => {
            setSelected([]);
            setToDelete([]);
            setPage(1);
            return result;
          });
        } else {
          errorMessageDialog(error, deleted);
        }
      }
    }
  );

  const handleDeleteMultiple = () => {
    const temp = [""];
    if (selected.length > 0) {
      selected.map(id => temp.push(id));
      getSelected({
        variables: {
          filter: { field: "id", value: temp },
          first: selected.length
        }
      });
    }
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (providersData) {
      return stableSort(providersData, getSorting(order, orderBy)).slice(
        (Number(page) - 1) * rowsPerPage,
        (Number(page) - 1) * rowsPerPage + rowsPerPage
      );
    }
    return null;
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const fetchMoreData = page => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        const {
          gps_providers,
          count,
          total
        } = fetchMoreResult.get_gps_providers;
        return {
          ...prev,
          get_gps_providers: {
            count: count,
            total: total,
            gps_providers: [
              ...prev.get_gps_providers.gps_providers,
              ...gps_providers
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(1);
          refetch(getVariables());
        }
      });
    });
  };

  // Function used to navigate using input value
  // const handlePage = e => {
  //   const { value } = e.target;
  //   // check if page is less than the inputted value
  //   if (page < value) {
  //     // check if the input value is greater than the total page
  //     if (value > Math.ceil(totalRows / rowsPerPage)) {
  //       // if true
  //       setPage(1);
  //       setLoading(false);
  //     } else {
  //       // if true
  //       // loop the input value to request the data without skipping other page
  //       for (let i = 1; i <= value; i += 1) {
  //         // check if the value is already fetch to the server

  //         // if (i * rowsPerPage > currentData.get_clients.clients.length && (i-1) * rowsPerPage >= currentData.get_clients.clients.length) {
  //         if (
  //           i * rowsPerPage > providersData.length &&
  //           (i - 1) * rowsPerPage >= providersData.length
  //         ) {
  //           fetchMoreData(i - 1);
  //         }
  //       }
  //       // set page based on user's input
  //       setPage(value);
  //       setLoading(false);
  //     }
  //   }
  //   // check if the input value is zero
  //   else if (Number(value) === 0) {
  //     // set page to default
  //     setPage(1);
  //     setLoading(false);
  //   } else {
  //     // set page based on user's input
  //     setPage(value);
  //     setLoading(false);
  //   }
  // };

  // // Function used to navigate to previous page
  // const handleDownPage = () => {
  //   // set page to previous page value
  //   setPage(Number(page) - 1);
  //   setLoading(false);
  // };

  // // Function used to navigate to next page
  // const handleUpPage = () => {
  //   // set page to current page incremented by 1
  //   setPage(Number(page) + 1);
  //   // check if the requested page data is already fetch
  //   if (
  //     page * rowsPerPage === providersData.length ||
  //     page * rowsPerPage >= providersData.length
  //   ) {
  //     // if true
  //     // fetch more data from gql useQuery
  //     fetchMoreData(page);
  //   } else {
  //     // if false
  //     // set loading to false
  //     setLoading(false);
  //   }
  // };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const ProvidersTable = props => {
    const { canEdit } = props;
    // if (loading) {
    //   return (
    //     <Loading />
    //     // <TableBody>
    //     //   <TableRow style={{ height: 100 }}>
    //     //     <TableCell colSpan={5} align="center">
    //     //       <CircularProgress />
    //     //     </TableCell>
    //     //   </TableRow>
    //     // </TableBody>
    //   );
    // }
    const { rowCount } = props;
    if (rowCount > 0) {
      return providersData ? (
        <TableBody>
          {getData().map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                key={`row-${row.id}`}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    disabled={!canEdit}
                    onClick={event => handleClick(event, row.id)}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  <Highlight search={contextData.searchKeyword.value}>
                    {row?.name || ""}
                  </Highlight>
                </TableCell>
                <TableCell style={{ width: "15%" }}>
                  <Highlight search={contextData.searchKeyword.value}>
                    {row?.registered_IPS
                      ? row?.registered_IPS.split(",").join(`\n`)
                      : ""}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={contextData.searchKeyword.value}>
                    {row?.api_key || ""}
                  </Highlight>
                </TableCell>
                <TableCell style={{ width: "30%" }}>
                  <Highlight search={contextData.searchKeyword.value}>
                    {row?.remarks || ""}
                  </Highlight>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    aria-label="view"
                    onClick={() => {
                      setSearchKeyword({ ...searchKeyword, value: "" });
                      AddLogs("Admin - GPS Providers", "view", row.name);
                      history.push({
                        pathname: `/admin/wti/gps_providers/view/${row.id}`,
                        state: {
                          params: {
                            id: row.id,
                            moduleName:
                              process.env.REACT_APP_GPS_PROVIDER_MODULE
                          },
                          queryVars: getVariables()
                        }
                      });
                    }}
                  >
                    <VisibleIcon />
                  </IconButton>
                  {canEdit ? (
                    <IconButton
                      // disabled={!canEdit}
                      aria-label="delete"
                      onClick={() => {
                        handleToDelete(row.id);
                        handleOpen();
                        setKeyDel(row.name);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={6} align="center">
              No data found
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [["GPS Providers", "IP Address", "API Key", "Remarks"]];

    downloadData.gps_providers.map(row =>
      temp.push([
        row.name || "",
        row.registered_IPS ? row.registered_IPS.split(",") : "",
        row.api_key || "",
        row.remarks || ""
      ])
    );

    return temp;
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          data={tableData}
          style={{ textDecoration: "none", color: "black" }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - GPS Providers", "download_template", "")
                    }
                  >
                    <Link
                      to="/assets/gps_providers_upload_template.xls"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - GPS Providers", "download_table", "")
                    }
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  if (loading) return <Loading />;

  return (
    <AccessControl
      resource="/admin/wti/gps_providers/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              GPS Providers
            </Typography>
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={() => setUploadOpen(true)}
              >
                Upload
              </Button>
            )}
            <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={providersData.length}
              fetchMoreData={fetchMoreData}
              totalRows={totalRows}
              totalPage={Math.ceil(totalRows / rowsPerPage)}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setCircularLoading}
              setLoading={setLoading}
            />
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) <= 1}
                onClick={() => handleDownPage()}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                readOnly
                onFocus={e => {
                  e.target.select();
                }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                value={Number(page)}
                style={{ width: "32px", marginRight: "8px" }}
              />
              {`/ ${Math.ceil(totalRows / rowsPerPage)}`}
              <Button
                name="up_page"
                disabled={Number(page) >= Math.ceil(totalRows / rowsPerPage)}
                onClick={() => handleUpPage()}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div> */}
          </Toolbar>
          <Toolbar
            className={clsx(classes.toolbar_root, {
              [classes.highlight]: selected.length > 0,
              [classes.invisible]: selected.length <= 0
            })}
          >
            {selected.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} GPS provider/s selected on this page
              </Typography>
            )}

            {selected.length > 0 &&
              (canEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    position: "absolute",
                    right: 0,
                    borderRadius: 100,
                    color: "white"
                  }}
                  onClick={handleOpenDeleteAll}
                >
                  Delete Selected
                </Button>
              ) : null)}
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleSort={handleSort}
                isChecked={isChecked}
                canEdit={canEdit}
              />
              <ProvidersTable
                selected={selected}
                providersData={providersData}
                classes={classes}
                rowCount={dataCount}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                canEdit={canEdit}
              />
            </Table>
          </TableContainer>
        </Paper>
        {/* <Upload
          open={uploadOpen}
          setUploadOpen={setUploadOpen}
          getQueryConditions={getVariables}
          // currentPage={currentPage}
        /> */}
        <UploadDialog
          uploadDialog={uploadOpen}
          handleCloseUpload={handleCloseUpload}
          refetch={refetch}
          dataCount={totalRows}
          getVariables={getVariables()}
        />
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            style={{
              margin: 0,
              top: "auto",
              right: 40,
              bottom: 40,
              left: "auto",
              position: "fixed",
              fontSize: "200px"
            }}
            // onClick={e => {
            //   e.stopPropagation();
            //   history.push("/admin/wti/providers/add");
            // }}
            //   disabled={
            //     clientData
            //       ? currentNumber >= clientData.get_client.user_count
            //       : false
            //   }
            onClick={() => {
              history.push({
                pathname: "/admin/wti/gps_providers/add"
                // state: {
                //   params: {
                //     moduleName: process.env.REACT_APP_GPS_PROVIDER_MODULE
                //   },
                //   queryVars: getVariables()
                // }
              });
            }}
          >
            <AddIcon
              style={{ fontSize: 50 }}
              onClick={() => setSearchKeyword({ ...searchKeyword, value: "" })}
            />
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this GPS Provider?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteMultiple(e)}
          title="Delete?"
          content="Are you sure you want to delete these GPS Providers?"
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}
      </div>
    </AccessControl>
  );
}
