import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LinkIcon from "@material-ui/icons/Link";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import validate from "../validation";
import { ADD_SHARE_LOCATION } from "../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import Cookie from "js-cookie";
import moment from "moment";
import AddLogs from "../functions/AddLogs";

// time limit options.
const timeLimitOptions = [
  "1 hour",
  "2 hours",
  "4 hours",
  "8 hours",
  "12 hours",
  "24 hours",
  "Custom Time"
];

const shareModeOptions = ["Basic", "Advance"];

const ShareLocation = props => {
  const codeRef = useRef();
  const textfieldRef = useRef();
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));
  const { title, description, toggle, closeHandler, vehicleInfo } = props;
  // initial value for link.
  const initialLinkInfo = {
    mode: vehicleInfo.length > 1 ? "Advance" : "Basic",
    timeLimit: "",
    link: ""
  };
  const [linkInfo, setLinkInfo] = useState(initialLinkInfo);
  const [copied, setCopied] = useState(false);
  const [customTime, setCustomTime] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [customTimeValue, setCustomTimeValue] = useState(linkInfo.timeLimit);

  // graphql
  const [addShareLocation] = useMutation(ADD_SHARE_LOCATION, {
    onCompleted(data) {
      if (data.add_share_location.success) {
        setLinkInfo(prevState => {
          return {
            ...prevState,
            link: `${window.location.origin}/map_view?code=${codeRef.current}`
          };
        });
      }
    }
  });

  // function use to generate basic link for basic mode modal.
  function generateBasicLinkHandler() {
    if (vehicleInfo[0].has_gps || vehicleInfo[0].device_info) {
      const { latitude, longitude } = 
      vehicleInfo[0].has_gps ? vehicleInfo[0].has_gps.device_status : vehicleInfo[0].device_info.device_status;
      let generatedLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      setLinkInfo(prevState => {
        return {
          ...prevState,
          link: generatedLink
        };
      });
      // add log
      AddLogs(
        process.env.REACT_APP_FLEET_MODULE,
        "generate_share_location",
        vehicleInfo[0].id
      );
    }
  }
  // function use to generate advance link for advance mode modal.
  function generateAdvanceLinkHandler() {
    if (_.isEmpty(vehicleInfo)) return;

    let vehicleIdsTemp = [];
    let expirationTimeTemp;
    let shareLocationInfo = {
      code: "",
      vehicle_ids: [],
      expiration_date: "",
      client_id: ""
    };
    // get the time in hours.
    if (linkInfo.timeLimit) {
      expirationTimeTemp = parseInt(linkInfo.timeLimit.substring(0, 2).trim());
    }
    // set default value for time limit to 1.
    let timeLimitTemp = _.isEmpty(linkInfo.timeLimit) ? 1 : expirationTimeTemp;
    vehicleInfo.forEach(vehicle => vehicleIdsTemp.push(parseInt(vehicle.id)));
    // generate code for link url.
    shareLocationInfo.code = Math.random()
      .toString(36)
      .slice(-5);
    shareLocationInfo.vehicle_ids = vehicleIdsTemp;
    shareLocationInfo.client_id = parseInt(user.client.id);
    shareLocationInfo.expiration_date = _.toString(
      moment(new Date())
        .add(timeLimitTemp, "hours")
        .format("YYYY-MM-DD HH:mm:ss")
    );
    codeRef.current = shareLocationInfo.code;
    addShareLocation({ variables: { share_location: shareLocationInfo } });
    AddLogs(
      process.env.REACT_APP_FLEET_MODULE,
      "generate_share_location",
      vehicleIdsTemp.join(", ")
    );
  }
  // reset values of use states.
  function resetHandler() {
    setCopied(false);
    setHasError(false);
    setCustomTime(false);
    codeRef.current = ""
  }
  // close modal handler
  function handleClose() {
    closeHandler();
    setLinkInfo(initialLinkInfo);
    resetHandler();
  }

  function onChangeShareModeHandler(event) {
    setLinkInfo({ mode: event.target.value, link: "", timeLimit: "" });
    resetHandler();
  }

  function onChangeTimeLimitHandler(event) {
    const { value } = event.target;
    setLinkInfo(prevState => {
      return { ...prevState, timeLimit: value };
    });

    if (_.isEqual(value, "Custom Time")) setCustomTime(true);
  }
  // function for validating custom time inputs.
  function checkCustomTimeInputHandler(enteredTimeExp) {
    let error = "";
    let enteredValue = enteredTimeExp.trim().replace(/ +/g, "");
    let slicedValue = enteredValue.substring(0, 2);
    let timeExpiredTemp = "";
    // validate if the first character on the input is a non-numeric value and validate the input if it is compose of only alphanumeric values
    if (
      !validate("alphanumeric_default", enteredValue) ||
      !validate("numeric", slicedValue[0])
    ) {
      error = "Invalid entered time expiration.";
      timeExpiredTemp = "";
    } else if (
      validate("numeric", slicedValue[0]) &&
      !validate("numeric", slicedValue[1])
    ) {
      if (parseInt(slicedValue[0]) < 1) {
        error = "Minimum hour for location sharing is 1 hour.";
        timeExpiredTemp = "";
      } else {
        error = "";
        timeExpiredTemp = `${slicedValue[0]} ${
          parseInt(slicedValue[0]) > 1 ? "hours" : "hour"
        }`;
      }
    } else if (
      validate("numeric", slicedValue[0]) &&
      validate("numeric", slicedValue[1])
    ) {
      // validates if the users input first and second character is a number and not maximum 24 hours or less than 0.
      if (parseInt(slicedValue) > 24) {
        error = "Maximum hours for location sharing is 24 hours.";
        timeExpiredTemp = `${parseInt(slicedValue)} hours`;
      } else {
        error = "";
        timeExpiredTemp = `${
          parseInt(slicedValue[0]) < 1
            ? `${slicedValue[1]} hour`
            : `${slicedValue} hours`
        }`;
      }
    }

    return { error, timeExpiredTemp };
  }

  function onChangeCustomTimeHandler(event) {
    setCustomTimeValue(event.target.value);
  }
  // set on blur for user focus on field and after clicking set error if user input is invalid.
  function onBlurCustomTimeHandler(event) {
    const { value } = event.target;
    const { error, timeExpiredTemp } = checkCustomTimeInputHandler(value);

    if (_.isEmpty(error)) {
      setLinkInfo(prevState => {
        return {
          ...prevState,
          timeLimit: timeExpiredTemp
        };
      });
      setCustomTimeValue(timeExpiredTemp);
      setHasError(error);
    } else {
      setCustomTimeValue(timeExpiredTemp);
      setHasError(error);
    }
  }
  // share link mode radio buttons.
  function shareLinkMode() {
    return shareModeOptions.map((mode, index) => (
      <FormControlLabel
        key={index}
        value={mode}
        control={<Radio size="small" color="primary" />}
        label={<span className={classes.radioLabel}>{mode}</span>}
        labelPlacement="start"
        disabled={vehicleInfo.length > 1 && mode === shareModeOptions[0]}
      />
    ));
  }

  return (
    <>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="share-location-dialog"
        aria-describedby="share-location-description"
        maxWidth="xs"
        classes={{ paper: classes.dialogContainer }}
      >
        <div className={classes.dialogHeaderContainer}>
          <p className={classes.dialogTitle}>{title}</p>
          <p className={classes.dialogDesctiption}>{description}</p>
        </div>
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.shareModeLabel}>
              Select how do you want the location to be shared?
            </Typography>
            <RadioGroup
              value={linkInfo.mode}
              onChange={onChangeShareModeHandler}
            >
              <div className={classes.radioBtnContainer}>{shareLinkMode()}</div>
            </RadioGroup>
            {_.isEqual(linkInfo.mode, "Advance") && (
              <>
                <Typography className={classes.timeLimitLabel}>
                  Time Limit
                </Typography>
                {customTime ? (
                  <TextField
                    id="timelimit-field"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    value={customTimeValue}
                    placeholder="Enter an time expiration"
                    InputProps={{
                      classes: {
                        input: hasError
                          ? classes.errorTextfield
                          : classes.timeLimitInputField
                      },
                      readOnly: !_.isEmpty(linkInfo.link)
                    }}
                    onChange={onChangeCustomTimeHandler}
                    onBlur={onBlurCustomTimeHandler}
                    disabled={!_.isEmpty(linkInfo.link)}
                  />
                ) : (
                  <NativeSelect
                    id="share-link-mode"
                    value={linkInfo.timeLimit}
                    onChange={onChangeTimeLimitHandler}
                    input={<BootstrapInput />}
                    fullWidth
                    IconComponent={ExpandMoreIcon}
                    disabled={!_.isEmpty(linkInfo.link)}
                  >
                    {timeLimit()}
                  </NativeSelect>
                )}
              </>
            )}
            {hasError && (
              <div className={classes.errorContainer}>
                <Typography className={classes.errorMessage}>
                  {hasError}
                </Typography>
              </div>
            )}
            <Typography className={classes.urlLabel}>
              Share Location read-only link
            </Typography>
            <div className={classes.generatedLinkContainer}>
              <TextField
                id="outlined-basic"
                variant="standard"
                fullWidth
                size="small"
                inputProps={{
                  disableUnderline: true,
                  classes: { input: classes.inputField },
                  readOnly: true,
                  ref: textfieldRef
                }}
                value={linkInfo?.link}
                placeholder="https://tmsuite.trackme.com.ph/"
              />
              <Button
                size="small"
                className={
                  _.isEmpty(linkInfo.link)
                    ? classes.copyLinkBtnDisabled
                    : classes.copyLinkBtnEnabled
                }
                disabled={_.isEmpty(linkInfo.link)}
                onClick={() => {
                  // navigator.clipboard.writeText(linkInfo.link);
                  textfieldRef.current.select();
                  document.execCommand("copy");
                  setCopied(true);
                }}
              >
                Copy Link
              </Button>
            </div>
            {copied && (
              <Alert
                variant="outlined"
                severity="success"
                className={classes.successAlert}
                classes={{ root: classes.alertPaper }}
              >
                <span className={classes.copiedSuccess}>
                  Copied to clipboard!
                </span>
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionBtnContainer}>
            <Button
              size="small"
              className={classes.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className={
                _.isEmpty(hasError) && !_.isEmpty(linkInfo.link)
                  ? classes.generateLinkBtnDisabled
                  : classes.generateLinkBtnEnabled
              }
              onClick={() =>
                _.isEqual(linkInfo.mode, "Basic")
                  ? generateBasicLinkHandler()
                  : generateAdvanceLinkHandler()
              }
              disabled={!_.isEmpty(hasError) || !_.isEmpty(linkInfo.link)}
            >
              <LinkIcon className={classes.generateLinkIcon} /> Generate Link
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareLocation;

// functions
function timeLimit() {
  return timeLimitOptions.map((time, index) => (
    <option value={time} key={index}>
      {time}
    </option>
  ));
}

// styles
const useStyles = makeStyles(theme => ({
  dialogTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    marginBottom: 0
  },
  dialogDesctiption: {
    fontSize: 13,
    color: "#808080",
    marginTop: "0.25rem"
  },
  dialogContainer: {
    borderRadius: 8,
    minHeight: 450
  },
  radioBtnContainer: {
    display: "flex",
    width: "100%",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  radioLabel: {
    fontSize: 13,
    fontFamily: "Nunito",
    color: "#808080",
    marginBottom: "0.5rem",
    fontWeight: "500"
  },
  dialogHeaderContainer: {
    padding: "1rem",
    borderBottom: "1px solid #c4c4c4"
  },
  inputField: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    padding: 0,
    marginLeft: 5
  },
  timeLimitInputField: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    marginLeft: 5
  },
  generatedLinkContainer: {
    display: "flex",
    border: "1px solid #F6F6F6",
    borderRadius: 30,
    alignItems: "center",
    padding: 5,
    backgroundColor: "#F6F6F6",
    marginBottom: "1rem"
  },
  copyLinkBtnEnabled: {
    textTransform: "none",
    fontSize: 10,
    width: "100px",
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    opacity: "1.0",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    },
    marginLeft: 10
  },
  copyLinkBtnDisabled: {
    textTransform: "none",
    fontSize: 10,
    width: "100px",
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff !important",
    opacity: "0.7",
    marginLeft: 10
  },
  shareModeLabel: {
    fontSize: 13,
    fontFamily: "Nunito",
    marginLeft: 5,
    color: theme.palette.primary.dark,
    marginTop: 10,
    fontWeight: "500"
  },
  urlLabel: {
    fontSize: 13,
    fontFamily: "Nunito",
    marginLeft: 5,
    color: theme.palette.primary.dark,
    marginBottom: "0.5rem",
    fontWeight: "500",
    marginTop: "2rem"
  },
  timeLimitLabel: {
    fontSize: 13,
    fontFamily: "Nunito",
    marginLeft: 5,
    color: theme.palette.primary.dark,
    marginBottom: "0.5rem",
    fontWeight: "500",
    marginTop: "0.5rem"
  },
  copiedSuccess: {
    fontSize: 13,
    fontFamily: "Nunito",
    color: theme.palette.success.main,
    fontWeight: "400"
  },
  generateLinkBtnEnabled: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "0.25rem",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: "0.7"
    },
    minWidth: 150
  },
  generateLinkBtnDisabled: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff !important",
    padding: "0.25rem",
    opacity: "0.7",
    borderRadius: 20,
    minWidth: 150,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  },
  cancelBtn: {
    textTransform: "none",
    color: theme.palette.error.main,
    padding: "0.25rem",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#FFFFFF"
    },
    minWidth: 80,
    marginRight: 10
  },
  generateLinkIcon: {
    fontSize: 18,
    marginRight: 5
  },
  actionBtnContainer: {
    padding: "0.25rem 0.5rem"
  },
  errorMessage: {
    fontSize: 11,
    fontFamily: "Nunito",
    marginLeft: 5,
    color: "#FFFFFF"
  },
  errorContainer: {
    backgroundColor: theme.palette.error.main,
    padding: "0.5rem",
    marginTop: "0.5rem",
    borderRadius: 4
  },
  errorTextfield: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    borderRadius: 4,
    border: `1px solid ${theme.palette.error.main}`
  },
  successAlert: {
    "& .MuiAlert-icon": {
      fontSize: 18
    }
  },
  alertPaper: {
    padding: "0px 4px"
  }
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "8px 12px",
    height: 15,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);
