import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  alignment: {
    display: "flex",
    alignItems: "center",
    background: "white",
    borderRadius: 5,
    minHeight: "28px"
  },
  typography: {
    fontSize: "0.75rem",
    padding: "0px 8px",
  },
  vehicle_count: {
    color: theme.palette.primary.main
  },
  btn: {
    border: "none",
    // borderRadius: "50px",
    backgroundColor: "inherit",
    color: "black",
    fontWeight: "bold",
    padding: 2,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    minWidth: "auto",
    "&.MuiButton-outlinedPrimary:hover": {
      border: "none",
      background: "inherit"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "0.9rem"
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "gray"
  },
  listItem: {
    marginBottom: 0,
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiListItemText-primary": {
      fontSize: "16px"
    }
  },
  listItemHidden: {
    visibility: "hidden",
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0",
      fontSize: "0px"
    },
    "& .MuiListItemText-primary": {
      fontSize: "0px"
    }
  },
  infoLabelBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5px",
    padding: "3px",
    paddingBottom: "0px",
    marginBottom: "0px",
    cursor: "pointer",
    "&:hover": {
      color: "#F49400"
    }
},
labelIconStatus: {
    width: "0.7em",
    height: "0.7em",
    marginRight: 6,
    cursor: "pointer"
},
label: {
    fontSize: 14,  
    color: "black",
    cursor: "pointer",
    "&:hover": {
      color: "#F49400"
    },
    textDecoration: "underline"
},
}));
