/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { TextField, FormControl, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery } from "@apollo/client";
import debounce from "../../../utils/functions/debounce.util";

// Queries
import { GET_VEHICLE_INFOS } from "../../../graphql/Queries";

// Styles
import useFormStyles from "../../../styles/useFormStyles";

// Components
import ErrorMessage from "./ErrorMessage";

const VehicleSearchInput = React.forwardRef((props, ref) => {
  const { initialValues, readOnly, group_ids, vehicleError } = props;
  const [selectedVehicles, setSelectedVehicles] = useState([0]);

  const { data, loading, refetch } = useQuery(GET_VEHICLE_INFOS, {
    variables: {
      first: 15,
      not: [{ field: "devices.id", value: "0" }],
      group_ids: group_ids
    }
  });

  const onKeywordChanged = event => {
    refetch({
      first: 15,
      not: [{ field: "devices.id", value: "0" }],
      group_ids: group_ids,
      keyword: event.target.value
    });
  };

  const debouncedKeywordChanged = debounce(onKeywordChanged, 250);

  useEffect(() => {
    if (data && !loading && selectedVehicles.length > 0) {
      /** This function will check if selected vehicle is owned by the selected hauler
       *  ref.current and selectedVehicles possible values:
       *  1. [] - no selected vehicle
       *  2. [0] - initial value, means ALL
       *  3. [{...}] - with selected vehicle
       */

      // Initialize vehicle value (for checking with plate number options)
      let testVehicle;
      if (selectedVehicles[0] !== 0) {
        // If with selected vehicle, set testVehicle to currently selected vehicle
        testVehicle = selectedVehicles[0];
      } else if (initialValues) {
        // If no selected vehicle but with initial value (for automated reports only), set testVehicle to initialValues
        testVehicle = initialValues[0];
      }

      // Get vehicle info from updated plate number options
      // const initVal = data.get_vehicles.vehicles.find(
      //   val => testVehicle === val.device_id
      // );
      if (testVehicle?.device_id) {
        // If vehicle exists, update states
        ref.current = [testVehicle];
        setSelectedVehicles([testVehicle]);
      } else {
        // If vehicle does not exists, clear states
        ref.current = [];
        setSelectedVehicles([]);
      }
    }
  }, [data, group_ids]);

  let vehiclesOptions = [];
  if (data) {
    vehiclesOptions = [...data.get_vehicles.vehicles];
  }

  const formStyles = useFormStyles();

  const handleSelectedChange = (_, newSelectedVehicles) => {
    if (!newSelectedVehicles) {
      ref.current = [];
      setSelectedVehicles([]);
    } else {
      ref.current = [newSelectedVehicles];
      setSelectedVehicles([newSelectedVehicles]);
    }
  };

  return (
    <FormControl className={formStyles.formRoot}>
      <Autocomplete
        loading={!data || loading}
        id="vehicle-select-input"
        options={vehiclesOptions}
        // disableClearable
        disabled={readOnly}
        onChange={handleSelectedChange}
        value={selectedVehicles[0] || null}
        getOptionLabel={option => option.plateno?.toString() || ""}
        getOptionSelected={(newVal, option) =>
          newVal && newVal?.device_id === option?.device_id
        }
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: formStyles.textFieldRoot }}
            onChange={debouncedKeywordChanged}
            label="Plate Number"
            placeholder="Select Plate Number"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: {
                padding: "6px 0px"
              },
              ...params.InputProps,
              disableUnderline: readOnly,
              disabled: readOnly,
              endAdornment:
                !data || loading ? <CircularProgress size={12} /> : ""
            }}
            required
          />
        )}
      />
      {vehicleError && selectedVehicles.length === 0 && (
        <ErrorMessage message="Plate number is required." />
      )}
    </FormControl>
  );
});

export default VehicleSearchInput;
