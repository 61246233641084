export const uploadHeaderRows = [
  {
    id: "geofences",
    disablePadding: true,
    label: null,
    hasCheckbox: true
  },
  {
    id: "geofencesStatus",
    disablePadding: true,
    label: null,
    hasCheckbox: false
  },
  {
    id: "geofenceName",
    disablePadding: true,
    label: "Geofence Name",
    hasCheckbox: false
  },
  {
    id: "latitude",
    disablePadding: true,
    label: "Latitude",
    hasCheckbox: false
  },
  {
    id: "longitude",
    disablePadding: true,
    label: "Longitude",
    hasCheckbox: false
  },
  {
    id: "polygon",
    disablePadding: true,
    label: "Polygon",
    hasCheckbox: false
  },
  {
    id: "radius",
    disablePadding: true,
    label: "Radius",
    hasCheckbox: false
  },
  {
    id: "universal",
    disablePadding: true,
    label: "Universal",
    hasCheckbox: true
  },
  {
    id: "action",
    disablePadding: true,
    label: "Action",
    hasCheckbox: false
  }
];
