import {
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
  makeStyles
} from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import React from "react";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import SvgIcons from "../assets/SvgIcons/SvgIcons";

const blinking_alerts = [
  {
    label: "Yellow Blinking",
    subtitle: "Moderate Alert",
    color: "#EDD608"
  },
  {
    label: "Green Blinking",
    subtitle: "Normal Alert",
    color: "#63F160"
  },
  {
    label: "Red Blinking",
    subtitle: "Critical Alert",
    color: "#FF0202"
  }
];

const vehicle_outlines = [
  {
    label: "Red Outline",
    subtitle: "Ignition Off",
    color: "transparent",
    outline: "#FF0202"
  },
  {
    label: "Green Outline",
    subtitle: "Ignition On",
    color: "transparent",
    outline: "#63F160"
  },
  {
    label: "Green Fill",
    subtitle: "Running in Main Power",
    color: "#63F160",
    outline: "#63F160"
  },
  {
    label: "Blue Fill",
    subtitle: "Running in Aux Power",
    color: "#3CA4F7",
    outline: "#3CA4F7"
  }
];

export const BlinkingAlertsLegend = props => {
  const { showAlerts, showOutlines, btnSize, margin, placement } = props;
  const { default_icon: VehicleIcon } = SvgIcons;
  const iconSize = 16;
  const classes = useStyles({ btnSize });

  return (
    <PopupState variant="popper" popupId="legend-popper">
      {popupState => (
        <div style={{margin: margin}}>
          <IconButton
            {...bindToggle(popupState)}
            classes={{
              root: classes.legend_btn
            }}
          >
            <InfoOutlinedIcon fontSize={btnSize} />
          </IconButton>
          <Popper
            placement={placement || "left-start"}
            popperOptions={{positionFixed: true}}
            modifiers={{
              offset: {
                enabled: true,
                offset: '0, 10'
              },
              preventOverflow: {
                padding: 20
              }
            }}
            {...bindPopper(popupState)}
          >
            <Paper className={classes.legend_overlay}>
              <Grid container spacing={2}>
                {showAlerts && (
                  <Grid item>
                    {blinking_alerts.map(alert => (
                      <Grid container item spacing={1} key={alert.label}>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 4 18 20"
                            height={iconSize}
                            width={iconSize}
                            className={classes.blinking_icon}
                            style={{ backgroundColor: `${alert.color}` }}
                          >
                            <g fill="#808080">
                              <path d={VehicleIcon.path} />
                            </g>
                          </svg>
                        </Grid>
                        <Grid item>
                          <Grid item container direction="column">
                            <Grid item>
                              <Typography className={classes.text} style={{fontWeight: 600}}>
                                {alert.label}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.text}>
                                {alert.subtitle}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {showOutlines && (
                  <Grid item>
                    {vehicle_outlines.map(vehicle => (
                      <Grid container item spacing={1} key={vehicle.label}>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 4 18 20"
                            height={iconSize}
                            width={iconSize}
                          >
                            <g fill={vehicle.color} stroke={vehicle.outline}>
                              <path d={VehicleIcon.path} />
                            </g>
                          </svg>
                        </Grid>
                        <Grid item>
                          <Grid item container direction="column">
                            <Grid item>
                              <Typography className={classes.text} style={{fontWeight: 600}}>
                                {vehicle.label}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.text}>
                                {vehicle.subtitle}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

const useStyles = makeStyles(theme => ({
  legend_btn: {
    background: "#FFFFFF",
    // width: 40,
    // height: 40,
    width: ({ btnSize }) => (btnSize === "small" ? 30 : 40),
    height: ({ btnSize }) => (btnSize === "small" ? 30 : 40),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    minWidth: 10,
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  legend_overlay: {
    background: "#808080",
    padding: 10,
    color: "#FFFFFF",
    "&.MuiTypography-body1": {
      letterSpacing: "0.25px"
    }
  },
  rounded: {
    borderRadius: 8
  },
  blinking_icon: {
    display: "inline-block",
    width: "1em",
    height: "1em",
    strokeWidth: 0,
    stroke: "black",
    borderRadius: "24px",
    padding: "8px"
  },
  text: {
    fontSize: 12,
    color: "#FFFFFF",
    letterSpacing: "0.4px"
  }
}));

export default BlinkingAlertsLegend;