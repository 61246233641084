import { makeStyles } from "@material-ui/styles";
import backgroundVehicleDetails from "../../../assets/checklist-vehicle-details.png";
import backgroundForRepair from "../../../assets/checklist-for-repair.png";

export default makeStyles(theme => ({
  container: {
    padding: 20,
    overflow: "auto",
    maxHeight: "95%",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  },
  title: {
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold"
  },
  iconCheck: {
    color: "#63F160",
    fontSize: "2.5vh"
  },
  iconCancel: {
    color: "red",
    fontSize: "2.5vh"
  },
  card: {
    padding: "1vw 1.5vw 0.9vw 1.5vw",
    minHeight: "30vh",
    borderRadius: 12,
    textAlign: "center",
    cursor: "pointer",
    border: `3px solid #FEFEFE`,
    "&:hover": {
      border: `3px solid #63F160`
    }
  },
  cardForRepair: {
    padding: "1vw 1.5vw 0.9vw 1.5vw",
    minHeight: "30vh",
    borderRadius: 12,
    textAlign: "center",
    cursor: "pointer",
    border: `3px solid #FEFEFE`,
    "&:hover": {
      border: `3px solid #63F160`
    },
    backgroundImage: `url(${backgroundForRepair})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  cardButtonDate: {
    // backgroundColor: theme.palette.primary.main,
    fontSize: "1.5vh",
    border: "none",
    color: "white",
    padding: "5px 12px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: "16px"
  },
  cardButtonPersonnel: {
    // backgroundColor: theme.palette.primary.main,
    fontSize: "1.5vh",
    border: "none",
    color: "white",
    padding: "5px 12px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: "5px",
    // opacity: "0.75"
  },
  cardCaption: {
    marginBottom: "0.5vh",
    fontSize: "calc(0.3em + 0.8vh)",
    color: "grey"
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.8vh"
  },
  cardContentLabel: {
    fontWeight: "bold",
    fontSize: "1.8vh",
    textAlign: "left"
  },
  paper: {
    padding: 25,
    height: "100%",
    minHeight: "60vh",
    boxSizing: "border-box",
    marginRight: 20,
    borderRadius: 12,
    overflowY: "auto",
    backgroundImage: `url(${backgroundVehicleDetails})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundPositionX: "right"
  },
  paperTitle: {
    fontSize: "calc(0.5em + 1.5vh)",
    fontWeight: "bold",
    marginBottom: 20
  },
  paperLabel: {
    color: theme.palette.primary.main,
    fontSize: "2vh"
  },
  paperLabelValue: {
    fontWeight: "bold",
    marginBottom: "1vh",
    fontSize: "2vh"
  },
  page: {
    "&.MuiIconButton-root": {
      color: theme.palette.primary.main
    },
    "&.MuiIconButton-root.Mui-disabled": {
      opacity: 0.5
    }
  }
}));
