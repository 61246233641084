/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from "react";
import { Typography, Grid, Box, Badge, Avatar } from "@material-ui/core";
import {
  TripOrigin,
  Visibility,
  History,
  Assignment,
  Share as ShareIcon,
  BuildRounded as UnderRepairIcon,
  SettingsApplications as Settings
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { useStyles } from "./InfoBubbleStyle";
import ActionButton from "./ActionButton";
import not_in_use_icon from "../../../../assets/Not_in_Use.png";
import AddLogs from "../../../../utils/functions/AddLogs";
import InfoBubbleDetail from "./InfoBubbleDetail";
import Battery from "../../../../utils/Battery";
import HistorySelector from "../../HistorySelector";
import SharelinkDialog from "../../../../utils/modals/SharelinkDialog";
import { getLastAlertMsg, getLastAlertStamp, gpsStatus } from "../../../Utils";
import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
import ShareLocation from "../../../../utils/modals/ShareLocation";
import Swal from "sweetalert2";

/* make this info window dynamic for all components can use */
const InfoBubble = props => {
  const vehicleRowRef = useRef({});
  // external styles
  const classes = useStyles();
  // props values
  const {
    componentType,
    vehicleInfo,
    historyHandler,
    isOpenHistoryModal,
    streetViewHandler,
    infoBubble,
    filterVal,
    vars,
    history
  } = props;

  const {
    under_repair,
    not_in_use,
    ignition_status,
    door_status,
    has_fuel_sensor,
    has_temp_sensor,
    plateno,
    trip_numbers,
    client_id,
    device_info,
    personnel_name
  } = vehicleInfo;
  const [shareDialogDetails, setShareDialogDetails] = useState({
    isOpen: false,
    vehicle: {}
  });

  const features =
  "menubar=yes,loaction=yes,resizaable=no,scrollbars=yes,status=no,noopener";
  const [generatedLink, setGeneratedLink] = useState("");
  const user = JSON.parse(Cookie.get("user"));
  const clientType = user?.client.is_restricted;
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const { permissions } = useAllowedPermissions("/admin/resources/vehicles/");

  // update or edit vehicle settings redirect to vehicle settings component.
  const vehicleSettingsHandler = () =>
    AddLogs("Admin - Vehicles/CV", "view", plateno);
  // trip handler tool.
  const withTrip = !_.isEmpty(trip_numbers) ? trip_numbers?.length > 0 : false;
  const withTripHandler = () => {
    if (withTrip)
      history.push({ pathname: "/trips", state: { params: { trip_numbers } } });
  };

  const svHandler = () => {
    const sv = new window.google.maps.StreetViewService();
    const position = {
      lat: device_info?.device_status.latitude,
      lng: device_info?.device_status.longitude,
    };
    sv.getPanorama({
      location: position
    }, (data, status) => {
      if (status === "OK") {
        streetViewHandler(position);
      }
      else {
        Swal.fire({
          title: "Streetview not available",
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        });
      }
    })
  };

  const redirectTo = (r, params = {}) => {
    history.push({
      pathname: r,
      state: {
        params: params,
        filterValues: filterVal,
        vars: vars,
        historyFilterVal: params.historyFilterVal
      }
    });
  };

  let isIsolateViewDisabled = false;
  // set isolate view disabled
  if (componentType === "Isolate" || vehicleInfo.device_id === "0")
    isIsolateViewDisabled = true;

  /*
   *** share link function for opening and closing modal share.
   */
  function shareModalHandler() {
    setShareDialogDetails({
      isOpen: !shareDialogDetails.isOpen,
      vehicle: device_info
    });
    vehicleRowRef.current = vehicleInfo
  }
  /*
   *** generate the link for the user with the specific vehicle redirected to google maps.
   */
  function generateShareLinkHandler() {
    const { latitude, longitude } = shareDialogDetails.vehicle.device_status;
    setGeneratedLink(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    );
  }

  const alertLength = device_info?.device_status?.AlertReferences?.length || 0;

  return (
    <>
      <Grid container item md={12}>
        <Box component="div" className={classes.infoHeader}>
          <Box component="div" className={classes.infoLabelBox}>
            <TripOrigin
              className={classes.labelIconStatus}
              style={{
                color: gpsStatus(
                  device_info?.device_status.status_code.toLowerCase()
                ).color
              }}
            />
            <Typography
              variant="body2"
              className={classes.label}
              style={{
                color: gpsStatus(
                  device_info?.device_status.status_code.toLowerCase()
                ).color
              }}
            >
              {plateno}
            </Typography>
          </Box>
          <Box component="div" className={classes.infoToolHeader}>
            {under_repair && (
              <ActionButton title="Under Repair">
                <Avatar className={classes.avatar}>
                  <UnderRepairIcon className={classes.underRepair} />
                </Avatar>
              </ActionButton>
            )}
            {not_in_use && (
              <ActionButton avatarSrc={not_in_use_icon} title="Not in use">
                <Avatar src={not_in_use_icon} />
              </ActionButton>
            )}
            {(user.client.id === client_id ||
              isWTIAdmin ||
              (user.client.id !== client_id && !clientType)) && (
              <ActionButton
                isDisabled={isIsolateViewDisabled}
                title="Isolate"
                onClickHandler={() => {
                  // redirectTo("/monitoring/isolate", {
                  //   device_ids: [vehicleInfo.device_id],
                  //   vehicle_ids: [vehicleInfo.id]
                  // });
                  const vehicleIds = JSON.stringify(vehicleInfo.id);
                  window.open(
                    `/monitoring/:view/isolate?vehicle_id=${vehicleIds}`,
                    "_blank",
                    features
                  );
                }}
              >
                <Avatar className={classes.avatar}>
                  <Visibility
                    className={classes.actionIcon}
                    style={{ opacity: isIsolateViewDisabled ? 0.5 : 1 }}
                  />
                </Avatar>
              </ActionButton>
            )}
            <ActionButton
              onClickHandler={historyHandler}
              isDisabled={
                !!(
                  !device_info?.device_type_id ||
                  device_info?.device_status.status_code_id === 0
                )
              }
              title={
                !device_info?.device_type_id ||
                device_info?.device_status.status_code_id === 0
                  ? "No paired device"
                  : "View History"
              }
            >
              <Avatar className={classes.avatar}>
                <History
                  className={classes.actionIcon}
                  style={{
                    opacity:
                      !device_info?.device_type_id ||
                      device_info?.device_status.status_code_id
                        ? 1
                        : 0.5
                  }}
                />
              </Avatar>
            </ActionButton>
            {isOpenHistoryModal.display && (
              <HistorySelector
                open={isOpenHistoryModal.display}
                vehicle={vehicleInfo}
                onClose={() => {
                  historyHandler();
                }}
                title="History"
                redirectTo={redirectTo}
              />
            )}

            {permissions?.view && (
              <ActionButton
                onClickHandler={vehicleSettingsHandler}
                isDisabled={false}
                title="Vehicle Settings"
              >
                <Avatar className={classes.avatar}>
                  <Link
                    to={{
                      pathname: `/admin/resources/vehicles/view/${vehicleInfo?.id}`,
                      state: {
                        source: `/monitoring`,
                        filterValues: filterVal,
                        vars: vars
                      }
                    }}
                  >
                    <Settings className={classes.actionIcon} />
                  </Link>
                </Avatar>
              </ActionButton>
            )}
            <ActionButton
              onClickHandler={shareModalHandler}
              isDisabled={
                !!(
                  !device_info?.device_type_id ||
                  device_info?.device_status.status_code_id === 0
                )
              }
              title={
                !device_info?.device_type_id ||
                device_info?.device_status.status_code_id === 0
                  ? "No paired device"
                  : "Share Link"
              }
            >
              <Avatar className={classes.avatar}>
                <ShareIcon
                  className={classes.actionIcon}
                  style={{
                    opacity:
                      !device_info?.device_type_id ||
                      device_info?.device_status.status_code_id
                        ? 1
                        : 0.5
                  }}
                />
              </Avatar>
            </ActionButton>
            <ActionButton
              onClickHandler={withTripHandler}
              isDisabled={false}
              title="Trips"
            >
              <Badge variant="dot" color="secondary" invisible={!withTrip}>
                <Assignment
                  className={withTrip ? classes.withTrip : classes.actionIcon}
                />
              </Badge>
            </ActionButton>
            <ActionButton
              onClickHandler={svHandler}
              isDisabled={false}
              title="Street View"
            >
              <Avatar className={classes.avatar}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/pegman.png`}
                  className={classes.pegman}
                  alt="pegman icon"
                />
              </Avatar>
            </ActionButton>
          </Box>
        </Box>
      </Grid>
      <InfoBubbleDetail
        label="Hauler Name:"
        content={vehicleInfo.client_name || "-"}
      />
      <InfoBubbleDetail
        label="Vehicle Type:"
        content={vehicleInfo.vehicle_type}
      />
      <InfoBubbleDetail
        label="Last Report:"
        content={`${moment(device_info?.device_status.reportstamp).format(
          "LT ll"
        )}`}
      />
      <InfoBubbleDetail
        label="Last Alert:"
        content={getLastAlertMsg(device_info?.device_status.last_alert_msg) || "-"}
      />
      <InfoBubbleDetail
        label="Last Alert Timestamp:"
        content={getLastAlertStamp(device_info?.device_status.last_alert_stamp) || "-"}
      />
      {/* <InfoBubbleDetail
        label="Alert Message:"
        content={
          alertLength ?
            device_info?.device_status?.AlertReferences.map((alert, index) => (
              <React.Fragment>
                {alert.alertmessage}{index !== alertLength - 1 && ","}<br />
              </React.Fragment>
            )) :
            "-"
        }
      /> */}
      {infoBubble?.ignition ? (
        <InfoBubbleDetail label="Ignition:" content={ignition_status || "-"} />
      ) : (
        ""
      )}
      {infoBubble?.driver ? (
        <InfoBubbleDetail label="Driver Name:" content={personnel_name || "-"} />
      ) : (
        ""
      )}
      {infoBubble?.battery ? (
        <InfoBubbleDetail 
          label="Battery:"
          content={
            <Battery
              vehicleType={vehicleInfo.vehicle_type}
              deviceTypeId={device_info?.device_type_id}
              batteryLevel={device_info?.device_status.battery_level}
              tooltipTitle={
                <>
                  <Typography className={classes.batteryLevel}>
                    {`Battery Life: ${device_info?.device_status.battery_level}%`}
                  </Typography>
                  <Typography className={classes.batteryReportStamp}>
                    {`As of: ${moment(
                      device_info?.device_status.reportstamp
                    ).format("lll")}`}
                  </Typography>
                </>
              }
              showText
              showNAText
            />
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.door ? (
        <InfoBubbleDetail label="Door:" content={door_status || "-"} />
      ) : (
        ""
      )}
      {infoBubble?.fuel && has_fuel_sensor ? (
        <InfoBubbleDetail
          label="Fuel:"
          content={
            device_info?.device_status?.fuelconsumption >= 0
              ? device_info?.device_status?.fuelconsumption
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.odometer_reading ? (
        <InfoBubbleDetail
          label="Odometer Reading:"
          content={`${
            device_info?.device_status.distancetraveled >= 0
              ? Math.round(device_info?.device_status.distancetraveled / 1000).toLocaleString("en-US")
              : "-"
          } km`}
        />
      ) : (
        ""
      )}
      {infoBubble?.power_source ? (
        <InfoBubbleDetail
          label="Running in auxiliary:"
          content={
            !_.isEmpty(device_info?.device_status.powersource)
              ? device_info?.device_status.powersource === "AUX"
                ? "Yes"
                : "No"
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.speed ? (
        <InfoBubbleDetail
          label="Speed:"
          content={
            device_info?.device_status.gpsSpeed >= 0
              ? `${device_info?.device_status.gpsSpeed} kph`
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.temperature && has_temp_sensor ? (
        <InfoBubbleDetail 
          label="Temperature:"
          content={<>{device_info?.device_status.temperature >= 0 ? device_info?.device_status.temperature : "-"}&deg;C</>}
        />
      ) : (
        ""
      )}
      <InfoBubbleDetail
        label="Current Location:"
        content={device_info?.device_status.lastlocation}
      />

        {infoBubble?.coordinates ? (
          <InfoBubbleDetail
            label="Coordinates:"
            content={
              (device_info?.device_status.latitude && device_info?.device_status.longitude)
                ? `${device_info?.device_status.latitude},${device_info?.device_status.longitude}`
                : "-"
            }
          />
      ) : (
        ""
      )}

      <ShareLocation  
        title="Share Location"
        description="Generate link and share the location of your vehicle/s"
        toggle={shareDialogDetails.isOpen}
        closeHandler={() => {
          setShareDialogDetails({ isOpen: false, vehicle: {} });
        }}
        vehicleInfo={vehicleRowRef.current && [vehicleRowRef.current]}
      />
      {/* <SharelinkDialog
        toggle={shareDialogDetails.isOpen}
        close={() => {
          setShareDialogDetails({ isOpen: false, vehicle: {} });
          setGeneratedLink("");
        }}
        title="Share Location"
        content="Generate Link and share the location of your vehicle"
        generatedLink={generatedLink}
        fn={generateShareLinkHandler}
      /> */}
    </>
  );
};

export default InfoBubble;
