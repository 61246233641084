/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TableHead,
  TableContainer,
  InputLabel,
  Tooltip,
  Button,
  Switch,
  InputBase,
  Checkbox,
  Toolbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  HelpRounded,
  Search as SearchIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import useFormStyles from "../../../../../../styles/useFormStyles";
import validate from "../../../../../../utils/validation";
import useViewChannelStyles from "./ViewChannel.form.styles";
import { mapRef } from "../../../../../../utils/Map/Inner";
import SelectGroup from "../../../../../../utils/modals/SelectGroup";
import ConfirmationDialog from "../../../../../../utils/modals/ConfirmationDialog";
import { EDIT_CONTROL_ROOM } from "../../../../../../graphql/Mutations";
import AddLogs from "../../../../../../utils/functions/AddLogs";
import Swal from "sweetalert2";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const ViewChannelForm = props => {
  const { id: room_id } = useParams();
  const {
    mapState,
    onDataViewChanged = () => {},
    changesRef,
    editMode
  } = props;
  const styles = useViewChannelStyles();
  const formStyles = useFormStyles();
  const [state, setState] = useState({
    clientName: "",
    zoom: 0,
    latitude: 0,
    longitude: 0,
    dataView: false,
    partners: []
  });
  const [errors, setErrors] = useState({
    clientName: "",
    zoom: "",
    latitude: "",
    longitude: ""
  });

  React.useEffect(() => {
    changesRef.current = { ...state };
  }, [state]);

  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      clientName: mapState?.name,
      dataView: mapState?.dataView,
      partners: mapState?.partners
    }));
  }, [mapState?.name]);

  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      zoom: mapState?.zoom,
      latitude: mapState?.center?.lat,
      longitude: mapState?.center?.lng
    }));
    setErrors(prev => ({
      ...prev,
      zoom: "",
      latitude: "",
      longitude: ""
    }));
  }, [mapState?.center?.lng, mapState?.center?.lat, mapState?.zoom]);

  React.useEffect(() => {
    if (!editMode) {
      changesRef.current = {};
      setState({
        clientName: mapState?.name,
        dataView: mapState?.dataView,
        partners: mapState?.partners,
        zoom: mapState?.zoom,
        latitude: mapState?.center?.lat,
        longitude: mapState?.center?.lng
      });
      setErrors({
        clientName: "",
        zoom: "",
        latitude: "",
        longitude: ""
      });
    }
  }, [editMode]);

  React.useEffect(() => {
    if (state.zoom < 1 || state.zoom > 22) {
      setErrors({ ...errors, zoom: "Zoom level value min: 1, max: 22" });
    } else {
      setErrors({ ...errors, zoom: "" });
    }
  }, [state.zoom]);

  const handleToggle = e => {
    setState(prev => ({ ...prev, dataView: e.target.checked }));
    onDataViewChanged(e.target.checked);
  };

  const handleBlur = event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      case "clientName":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("geofence", value.trim())) {
          error = `The following characters are not allowed: & _ + { } [ ] | \ ; " < > ' ? /`;
        } else {
          error = "";
        }
        break;
      case "zoom":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Numeric only";
        } else if (+value < 1 || +value > 22) {
          error = "Zoom level value min: 1, max: 22";
        } else {
          error = "";
        }
        if (error === "" && value !== "") {
          mapRef.current.setZoom(+value);
        }
        break;
      case "latitude":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("coords", value)) {
          error = "Numeric only";
        } else {
          error = "";
        }
        if (error === "" && value !== "")
          mapRef.current.setCenter({ lat: +value, lng: state.longitude });
        break;
      case "longitude":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("coords", value)) {
          error = "Numeric only";
        } else {
          error = "";
        }
        if (error === "" && value !== "")
          mapRef.current.setCenter({ lat: state.latitude, lng: +value });
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const zoomRef = React.useRef(1);
  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    switch (name) {
      case "zoom":
        if (value !== "") {
          let timeout = null;
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(() => {
            if (+zoomRef.current.value >= 1 && +zoomRef.current.value <= 22) {
              mapRef.current.setZoom(+zoomRef.current.value);
            }
          }, 700);
        }
        break;
      case "latitude":
        if (value !== "" && validate("coords", value))
          mapRef.current.setCenter({ lat: +value, lng: state.longitude });
        break;
      case "longitude":
        if (value !== "" && validate("coords", value))
          mapRef.current.setCenter({ lat: state.latitude, lng: +value });
        break;
      default:
        break;
    }
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const [selectedPartners, setSelectedPartners] = React.useState([]);
  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedPartners.length) {
      if (!selectedPartners.length < state.partners.length) {
        const displayedData = state.partners.map(data => data.id);
        if (displayedData.every(data => selectedPartners.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (
          displayedData.some(data => selectedPartners.indexOf(data) > -1)
        )
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };
  const onSelectAllClick = event => {
    const tempSelected = selectedPartners;
    const deselect = () => {
      const displayedData = state.partners.map(n => n.id);
      // Deselects all items(clients)
      const newSelected = tempSelected
        .filter(clientId => displayedData.indexOf(clientId) < 0)
        .map(clientSelectedId => clientSelectedId);
      // Set new selected items(clients)
      setSelectedPartners(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      state.partners.map(n => tempSelected.push(n.id));
      setSelectedPartners(_.uniq(tempSelected));
      return;
    }
    deselect();
  };
  const isSelected = id => selectedPartners.indexOf(id) !== -1;
  const handleClick = (_event, partnerId) => {
    const selectedIndex = selectedPartners.indexOf(partnerId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPartners, partnerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPartners.slice(1));
    } else if (selectedIndex === selectedPartners.length - 1) {
      newSelected = newSelected.concat(selectedPartners.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPartners.slice(0, selectedIndex),
        selectedPartners.slice(selectedIndex + 1)
      );
    }
    setSelectedPartners(newSelected);
  };
  const [groupModal, setGroupModal] = React.useState();
  const openModal = () => {
    setGroupModal(true);
  };
  const closeModal = () => {
    setGroupModal(false);
  };
  const handleGroupList = array => {
    setState(prev => ({
      ...prev,
      partners: [...array]
    }));
    setErrors({ ...errors, groupList: "" });
  };
  const [keyword, setKeyword] = React.useState("");
  const handleRemoveGroup = grp_id => {
    const temp = state.partners;
    const index = temp.map(g => g.id).indexOf(grp_id);
    temp.splice(index, 1);
    setState(prev => ({ ...prev, partners: temp }));
  };
  const [saveDialog, setSaveDialog] = React.useState(false);
  const openSaveDialog = () => {
    const showDialog = Object.values(errors).reduce((acc, value) => {
      return acc && value === "";
    }, true);
    if (!showDialog) return;

    setSaveDialog(true);
  };
  const closeSaveDialog = () => setSaveDialog(false);
  const [update_control_room] = useMutation(EDIT_CONTROL_ROOM);
  const handleSubmit = () => {
    const updated_partners_ids = state.partners.map(partner => +partner.id);
    update_control_room({
      variables: {
        id: room_id,
        data: {
          name: state.clientName,
          zoom_level: +state.zoom,
          location: {
            lat: parseCoordinate(state.latitude),
            lon: parseCoordinate(state.longitude)
          },
          data_view: state.dataView,
          group_ids: updated_partners_ids
        }
      }
    }).then(response => {
      const { error, success } = response.data.edit_control_room;
      if (success) {
        AddLogs(process.env.REACT_APP_CONTROL_ROOM_MODULE, "update", state.clientName);
        Swal.fire({
          title: "Updated",
          icon: "success",
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          window.location.reload();
        });
      } else if (error.length > 0) {
        // Swal.fire({
        //   title: error[0].message,
        //   icon: "error",
        //   showConfirmButton: false,
        //   timer: 2500
        // });
        const tempObj = {};
        error.map(err => Object.assign(tempObj, { [err.field]: err.message }));
        setErrors({
          ...errors,
          clientName: tempObj.name
        });
      }
    });
  };

  const parseCoordinate = (n = 0) => {
    if (n === "") return n;
    if (isNaN(n)) return 0;
    return +(n.toString().match(/^\d+.\d{6}/) || [(+(n || 0)).toFixed(6)])[0];
  };

  const handleKeypress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9.-]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <Grid item xs={12}>
      <Typography
        variant="subtitle2"
        style={{ marginBottom: 24, marginLeft: 20 }}
      >
        All fields with <span style={{ color: "red" }}>*</span> are required
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            classes={{ root: formStyles.textFieldRoot }}
            required
            name="clientName"
            label="Name"
            placeholder="Enter Name"
            value={state.clientName}
            className={styles.field}
            onChange={e => handleChange(e)}
            onBlur={e => handleBlur(e)}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black"
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            classes={{ root: formStyles.textFieldRoot }}
            required
            type="number"
            name="zoom"
            label="Zoom Level"
            value={state.zoom}
            className={styles.field}
            inputRef={zoomRef}
            onChange={e => {
              if (!mapRef.current.disableDoubleClickZoom) handleChange(e);
            }}
            onBlur={e => handleBlur(e)}
            InputProps={{ inputProps: { min: 1, max: 22 } }}
            InputLabelProps={{
              shrink: true,
              style: { color: "black" }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {errors.clientName && (
            <Alert severity="error" className={styles.alert}>
              {errors.clientName}
            </Alert>
          )}
        </Grid>
        <Grid item xs={6}>
          {errors.zoom && (
            <Alert severity="error" className={styles.alert}>
              {errors.zoom}
            </Alert>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            classes={{ root: formStyles.textFieldRoot }}
            required
            name="latitude"
            label="Latitude"
            placeholder="Enter Latitude"
            value={parseCoordinate(state?.latitude)}
            className={styles.field}
            onKeyPress={handleKeypress}
            onChange={e => handleChange(e)}
            onBlur={e => handleBlur(e)}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black"
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            classes={{ root: formStyles.textFieldRoot }}
            required
            name="longitude"
            label="Longitude"
            placeholder="Enter Longitude"
            value={parseCoordinate(state?.longitude)}
            className={styles.field}
            onKeyPress={handleKeypress}
            onChange={e => handleChange(e)}
            onBlur={e => handleBlur(e)}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black"
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {errors.latitude && (
            <Alert severity="error" className={styles.alert}>
              {errors.latitude}
            </Alert>
          )}
        </Grid>
        <Grid item xs={6}>
          {errors.longitude && (
            <Alert severity="error" className={styles.alert}>
              {errors.longitude}
            </Alert>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <div className={styles.switch}>
            <Tooltip
              classes={{ tooltip: formStyles.toolTip }}
              title="Turn on: Shows the count of vehicle"
              placement="top-start"
            >
              <HelpRounded className={styles.helpIcon} />
            </Tooltip>
            <Typography color="inherit" className={styles.typography}>
              &nbsp;Data view&nbsp;
            </Typography>
            <AntSwitch
              color="primary"
              name="data_view"
              checked={state.dataView}
              onChange={handleToggle}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={styles.partners}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Tooltip
                classes={{ tooltip: formStyles.toolTip }}
                title="Data of primary client will be linked to all selected partners(and vise versa)"
                placement="top-start"
              >
                <HelpRounded className={styles.helpIcon} />
              </Tooltip>
              <InputLabel classes={{ root: styles.mediumTitle }}>
                Partners
              </InputLabel>
            </div>
            <Paper component="form" className={styles.searchRoot}>
              <InputBase
                className={styles.searchInput}
                placeholder="Search Client"
                // inputProps={{ "aria-label": "plate #" }}
                onChange={e => setKeyword(e.target.value)}
                // value={keyword}
              />
              <IconButton
                // type="submit"
                className={styles.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.tablePaper} variant="outlined">
            {selectedPartners.length > 0 && (
              <Toolbar
              // className={clsx(classes.toolbar_root, {
              //   [classes.highlight]: selected.length > 0,
              //   [classes.invisible]: selected.length <= 0
              // })}
              >
                {selectedPartners.length > 0 && (
                  <Typography
                    // className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    <span style={{ color: "#FF845E" }}>
                      {selectedPartners.length}
                    </span>{" "}
                    device group/s selected
                  </Typography>
                )}

                {selectedPartners.length > 0 && (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setState(prev => ({
                          ...prev,
                          partners: []
                        }));
                        setSelectedPartners([]);
                      }}
                      // disabled={!canDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Toolbar>
            )}
            <TableContainer className={styles.table}>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={isChecked().indeterminate}
                        checked={isChecked().all}
                        onChange={onSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts"
                        }}
                      />
                    </TableCell>
                    <TableCell
                      padding="normal"
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel>Client</TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        color="primary"
                        classes={{ root: styles.addEntryButton }}
                        onClick={openModal}
                      >
                        Add Entry
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.partners
                    .filter(
                      row =>
                        row.name.toUpperCase().indexOf("".toUpperCase()) > -1 &&
                        row.name.toLowerCase().indexOf(keyword.toLowerCase()) >
                          -1
                    )
                    .map(partner => {
                      const isItemSelected = isSelected(partner.id);
                      return (
                        <TableRow hover key={partner.id} role="checkbox" tabIndex={-1}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={event => handleClick(event, partner.id)}
                              checked={isItemSelected}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            align="left"
                          >
                            {partner.name}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            align="right"
                          >
                            <IconButton
                              onClick={() => handleRemoveGroup(partner.id)}
                            >
                              <DeleteIcon
                                style={{ marginRight: 10 }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <SelectGroup
              toggle={groupModal}
              closeGroupModal={closeModal}
              handleGroupList={handleGroupList}
              groupList={state.partners}
            />
            <ConfirmationDialog
              toggle={saveDialog}
              close={() => closeSaveDialog("save")}
              fn={e => handleSubmit(e)}
              title="Save"
              content="Are you sure you want to save channel?"
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "30px" }}>
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={openSaveDialog}
          >
            Save Details
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewChannelForm;
