import React, { useState } from "react";
import {
  Box,
  Fab,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import moment from "moment";
import { TableWrapper, useStyles } from "./TripAllocation.styles";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import DetailedViewModal from "./DetailedViewModal";
import Header from "../../../utils/UI/Table/Header";
import Table from "../../../utils/UI/Table/CustomTable";
import Filter from "../../Admin/Filter";
import useFormStyles from "../../../styles/useFormStyles";
import EmptyState from "../../../assets/Images/EmptyState.png";
import { ReactComponent as AllocateIcon } from "../../../assets/human-dolly.svg";
import { useQuery } from "@apollo/client";
import {
  GET_TRIP_ALLOCATIONS
} from "../../../graphql/Queries";
import Loading from "../../../utils/Loading";
import _ from "lodash";
import AddLogs from "../../../utils/functions/AddLogs";
import AccessControl from "../../../utils/AccessControl";
import useUserContext from "../../../context/User/useUserContext";
import { DATE_FORMAT, DateTimeField, DateField } from "./sharedComponents";

const Headers = [
  { id: "allocation_date", label: "Allocation Date", isSortable: true },
  {
    id: "confirmation_duedate",
    label: "Confirmation Due Date",
    isSortable: true
  },
  { id: "pickup_date", label: "Pickup Date", isSortable: true },
  { id: "trips_count", label: "Number of Trips", isSortable: true },
  { id: "confirm_count", label: "Confirmed", isSortable: true },
  { id: "pending_count", label: "Waiting", isSortable: true },
  { id: "declined_count", label: "Declined", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false }
];

const INITIAL_ORDER = { field: "allocation_date", direction: "asc" };
const INITIAL_ROWS = 10;
const HEADER_LABEL = "Trip Allocation";

const INITIAL_FILTER = {
  dateFrom: null,
  dateTo: null,
  allocationDate: null,
  confirmationDueDate: null
};

const TripAllocation = () => {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const userCtx = useUserContext();
  const isWTIAdmin = _.isEqual(+userCtx.user_level_id, +process.env.REACT_APP_WTI_ADMIN);
  const [page, setPage] = useState({
    rows: INITIAL_ROWS,
    total: 0,
    currentPage: 0
  });
  const [total, setTotal] = useState();
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [viewedData, setViewedData] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  // retain filter values
  const history = useHistory();
  const savedFilter = history?.location?.state?.params?.vars;
  const isFromNotif = history?.location?.state?.params?.isFromNotif;
  const [tempFilter, setTempFilter] = useState(savedFilter || INITIAL_FILTER);
  const [filter, setFilter] = useState(savedFilter || INITIAL_FILTER);
  const [isFiltered, setIsFiltered] = useState(
    savedFilter && !_.isEqual(savedFilter, INITIAL_FILTER) && !isFromNotif
  );

  const handleSetFilters = () => {
    if (Object.values(tempFilter).filter(v => !_.isNull(v)).length) {
      setIsFiltered(true);
      let dateFilters = [];

      if (tempFilter?.dateFrom) {
        dateFilters = [...dateFilters, `Pickup Date: ${tempFilter?.dateFrom} - ${tempFilter?.dateTo}`];
      }

      if (tempFilter?.allocationDate) {
        dateFilters = [...dateFilters, `Allocation Date: ${moment(tempFilter?.allocationDate).format("ll")}`];
      }

      if (tempFilter?.confirmationDueDate) {
        dateFilters = [...dateFilters, `Confirmation Due Date: ${moment(tempFilter?.confirmationDueDate).format("ll")}`];
      }

      AddLogs(
        process.env.REACT_APP_TRIP_ALLOCATION_MODULE,
        "filter",
        dateFilters.join(", ")
      );
    }
    setPage({ rows: INITIAL_ROWS, currentPage: 0 });
    setFilter(tempFilter);
    setIsFilterOpen(false);
  };

  const handleClearFilter = () => {
    setPage({ rows: INITIAL_ROWS, currentPage: 0 });
    setIsFiltered(false);
    setTempFilter(INITIAL_FILTER);
    setFilter(INITIAL_FILTER);
  };

  const getQueryVars = () => {
    let variables = {
      orderBy: [order],
      dateFilter: [
        {
          field: "allocation_date",
          start: moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD 00:00:00"),
          end: moment()
            .add(7, "days")
            .format("YYYY-MM-DD 23:59:59")
        }
      ],
      first: page?.rows,
      skip: page?.rows * page?.currentPage
    };

    if (filter?.allocationDate) {
      variables.dateFilter = [{
        field: "allocation_date",
        start: moment(filter?.allocationDate).format("YYYY-MM-DD 00:00:00"),
        end: moment(filter?.allocationDate).format("YYYY-MM-DD 23:59:59")
      }];
    } else if (!_.isEqual(filter, INITIAL_FILTER)) {
      // disregard +- 7 days when using filter w/ no alloc date set
      variables.dateFilter = [];
    }

    if (filter?.confirmationDueDate) {
      variables.dateFilter.push({
        field: "confirmation_duedate",
        start: moment(filter?.confirmationDueDate).format(
          "YYYY-MM-DD 00:00:00"
        ),
        end: moment(filter?.confirmationDueDate).format("YYYY-MM-DD 23:59:59")
      });
    }

    if (filter?.dateFrom && filter?.dateTo) {
      variables.dateFilter.push({
        field: "pickup_date",
        start: moment(filter?.dateFrom).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(filter?.dateTo).format("YYYY-MM-DD HH:mm:ss")
      });
    }

    if (!isWTIAdmin) {
      Object.assign(variables, {
        filter: [{
          field: "clients.id",
          value: userCtx?.client?.id
        }]
      });
    }

    if (savedFilter?.conditions) {
      Object.assign(variables, {
        conditions: savedFilter.conditions
      });
    }

    return variables;
  };

  const { loading, refetch } = useQuery(GET_TRIP_ALLOCATIONS, {
    variables: getQueryVars(),
    onCompleted: data => {
      if (data) {
        const { trip_allocations, total } = data?.get_trip_allocations;
        setAllocations(trip_allocations);
        setTotal(total);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network"
  });

  const handleViewAllocation = selected => {
    const { allocation_date } = selected;
    setViewedData(selected);
    setIsDetailsOpen(true);

    AddLogs(
      `${process.env.REACT_APP_ALLOCATION_MODULE} - ${process.env.REACT_APP_TRIP_ALLOCATION_MODULE}`,
      "view_allocation",
      moment(allocation_date).format(DATE_FORMAT)
    );
  };

  const handleClose = (hasUpdates = false) => {
    setIsDetailsOpen(false);
    if (hasUpdates) {
      refetch();
    }
  };

  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: process.env.REACT_APP_TRIP_ALLOCATION_MODULE
        }
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AccessControl
      resource="/allocation/trip/"
      process="tables"
      setCanDelete={setCanEdit}
    >
      <Box style={{ height: "calc(100% - 64px)" }}>
        <Header
          label={HEADER_LABEL}
          total={total}
          page={page}
          setPage={setPage}
          totalPage={Math.ceil(total / page.rows)}
          isFiltered={isFiltered}
          setFilterDrawer={setIsFilterOpen}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          hasFilter
        />
        <TableWrapper
          defaultHeader
          style={{ height: !allocations.length && "auto" }}
        >
          <Table
            headers={Headers}
            order={order}
            setOrder={setOrder}
            data={allocations}
          >
            {!loading && allocations.length ? (
              allocations.map((row, index) => (
                <TableRow key={index} className={classes.table}>
                  <TableCell>
                    {moment(row?.allocation_date).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell>
                    {moment(row?.confirmation_duedate).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell>
                    {`${moment(row?.min_pickup_date).format(
                      DATE_FORMAT
                    )} - ${moment(row?.max_pickup_date).format(DATE_FORMAT)}`}
                  </TableCell>
                  <TableCell>{row?.trips_count}</TableCell>
                  <TableCell>{row?.confirm_count}</TableCell>
                  <TableCell>{row?.pending_count}</TableCell>
                  <TableCell>{row?.declined_count}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewAllocation(row)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </Table>
        </TableWrapper>
        {!loading && !allocations.length && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={"calc(100% - 50px)"}
            gridGap={20}
          >
            <img src={EmptyState} style={{ maxHeight: "80%" }} />
            <Typography style={{ fontWeight: 600 }}>
              No data to display
            </Typography>
          </Box>
        )}

        {!isWTIAdmin && canEdit && (
          <Fab
            className={classes.fab}
            size="large"
            variant="extended"
            component={Link}
            to={{
              pathname: `/allocation/add`,
              state: {
                params: {
                  moduleName: process.env.REACT_APP_TRIP_ALLOCATION_MODULE,
                  vars: filter
                }
              }
            }}
          >
            <Typography className={classes.fabText}>Allocate</Typography>
            <AllocateIcon fill="white" width={25} />
          </Fab>
        )}

        <Filter
          filterDrawer={isFilterOpen}
          closeFilterDrawer={() => setIsFilterOpen(false)}
          moduleName={process.env.REACT_APP_ALLOCATION_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <Typography
            style={{
              position: "absolute",
              top: 20,
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 1
            }}
          >
            Pickup date
          </Typography>

          <FormControl
            style={{ width: "100%", minWidth: "auto", marginTop: 18 }}
            classes={{ root: formStyles.formRoot }}
          >
            <DateTimeField
              dateTimePickerProps={{
                classes: { root: formStyles.textFieldRoot },
                label: "From",
                InputProps: {
                  placeholder: "From",
                  name: "dateFrom"
                },
                initialFocusedDate: moment().startOf("date"),
                value: tempFilter.dateFrom,
                onChange: date => {
                  setTempFilter({
                    ...tempFilter,
                    dateFrom: date.format(DATE_FORMAT),
                    dateTo: date.endOf("date").format(DATE_FORMAT)
                  });
                }
              }}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <DateTimeField
              dateTimePickerProps={{
                classes: { root: formStyles.textFieldRoot },
                label: "To",
                InputProps: {
                  placeholder: "To",
                  name: "dateTo"
                },
                minDate: tempFilter.dateFrom || undefined,
                initialFocusedDate: moment().endOf("date"),
                value: tempFilter.dateTo,
                onChange: date => {
                  setTempFilter({
                    ...tempFilter,
                    dateFrom: tempFilter?.dateFrom || date.startOf("date").format(DATE_FORMAT),
                    dateTo: date.format(DATE_FORMAT)
                  });
                }
              }}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <DateField
              dateTimePickerProps={{
                classes: { root: formStyles.textFieldRoot },
                label: "Allocation Date",
                InputProps: {
                  name: "allocationDate"
                },
                value: tempFilter.allocationDate,
                onChange: date => {
                  setTempFilter({
                    ...tempFilter,
                    allocationDate: date.format(DATE_FORMAT)
                  });
                }
              }}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <DateField
              dateTimePickerProps={{
                classes: { root: formStyles.textFieldRoot },
                label: "Confirmation Due Date",
                InputProps: {
                  name: "confirmationDueDate"
                },
                value: tempFilter.confirmationDueDate,
                onChange: date => {
                  setTempFilter({
                    ...tempFilter,
                    confirmationDueDate: date.format(DATE_FORMAT)
                  });
                }
              }}
            />
          </FormControl>
        </Filter>

        {isDetailsOpen && (
          <DetailedViewModal
            open={isDetailsOpen}
            handleClose={handleClose}
            data={viewedData}
          />
        )}
      </Box>
    </AccessControl>
  );
};

export default TripAllocation;
