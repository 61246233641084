import { makeStyles } from "@material-ui/core";

export default makeStyles({
  container: {
    width: "100%",
    height: "auto",
    // border: "1px solid #ccc",
    padding: "12px calc(24px - 8px) 12px 8px",
    boxSizing: "border-box",
    // margin: 8,
    "& .MuiSvgIcon-root": {
      // height: "0.75em !important",
      // fontSize: "1.25rem !important",
    }
  },
  root: {
    position: "static",
    margin: 0,
    border: "none",
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: 0
    }
  },
  detailsRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "4px 0px 0px 24px"
  },
  summaryRoot: {
    display: "flex",
    alignItems: "center",
    minHeight: "0",
    height: "29px",
    padding: "0px 0px 0px 24px",
    pointerEvents: props => (props.disabled ? "none" : null),
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      margin: "0"
    },
    "&.Mui-expanded": {
      minHeight: "29px"
    }
    //  "& .MuiIconButton-label": {
    //    height: "24px",
    //    width: "24px",
    //  },
  },
  summaryLabelWrapper: {
    flex: 1,
    display: "flex"
  },
  summaryLabelRoot: {
    marginRight: 4,
    "& .MuiButtonBase-root": {
      padding: 0
    }
  },
  noChildSummary: {
    // Styles base on  .MuiAccordionSummary-root
    // padding: "0px 24px",
    // margin: "12px 0",
    // display: "flex",
    // "&:first-child": {
    //    margin: "0 0 12px 0"
    // }
  },
  typographyRoot: {
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "0.30px",
    color: "hsl(0deg 0% 0% / 76%)"
  },
  checkboxRoot: {
    "& .MuiSvgIcon-root": {
      height: "0.8em !important",
      fontSize: "1.25rem !important"
    }
  }
});
