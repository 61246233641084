import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import _ from "lodash";
import useFormStyles from "../../../../styles/useFormStyles";
import useFilterHauler from "../../../../utils/hooks/useFilterHauler";
import useDebounce from "../../../../utils/hooks/useDebounce";

const HaulerGroupSelector = ({
  hauler,
  setHauler,
  styleProperty = { padding: "6px 0px" },
  isLabelShown = true
}) => {
  const formStyles = useFormStyles();
  const DEBOUNCE_TIME = 800;
  const [haulerKeyword, setHaulerKeyword] = useDebounce("", DEBOUNCE_TIME);
  const haulerGroupOptions = useFilterHauler(haulerKeyword);

  return (
    <Autocomplete
      options={_.uniqBy(haulerGroupOptions, "id")}
      getOptionSelected={(o, v) => o.label === v.label}
      getOptionLabel={option => option.label}
      renderOption={option => option.label}
      popupIcon={<ExpandMoreRoundedIcon />}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: formStyles.textFieldRoot }}
          label={
            isLabelShown ? (
              <>
                Hauler/Groups <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              ""
            )
          }
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold", letterSpacing: 1 }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: !isLabelShown,
            style: styleProperty
          }}
          onChange={e => setHaulerKeyword(e.target.value)}
        />
      )}
      value={hauler}
      onChange={(e, nv) => {
        setHauler(nv);
      }}
      disableClearable
    />
  );
};

export default HaulerGroupSelector;
