/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";
import moment from "moment";
import { getComparator, stableSort } from "../../ReportsTable/helpers";

// Components
import TableCellText from "../../../common/TableCellText";

// Styles
import useTableBodyStyles from "../../styles/useTableBodyStyles";

// Utils
import parseEmails from "../../../utils/parseEmails";

const getStatusInfo = isSent => {
  switch (isSent) {
    case 0: // pending
      return {
        label: "Pending",
        color: "#808080"
      };
    case 1: // sent
      return {
        label: "Sent",
        color: "#10E23F"
      };
    case 2: // ongoing
      return {
        label: "On Going",
        color: "#EDD608"
      };
    case 3: // failed
      return {
        label: "Failed",
        color: "#E40000"
      };     
    default:
      return {
        label: "Pending",
        color: "#808080"
      };
  };
};

const useStatusStyles = makeStyles({
  root: {
    fontSize: 13,
    fontWeight: 600,
    // color: props => (props.is_sent ? "#00E965" : "#FF2A2B")
    color: props => getStatusInfo(props.is_sent).color
  }
});
const Status = props => {
  const { is_sent } = props;
  const styles = useStatusStyles(props);
  return (
    <Typography variant="subtitle2" classes={{ root: styles.root }}>
      {getStatusInfo(is_sent).label}
    </Typography>
  );
};

const CustomTableBody = props => {
  const { tableRows, sort, currentPage, rowsPerPage } = props;
  const styles = useTableBodyStyles();
  return (
    <TableBody>
      {/* stableSort(tableRows, getComparator(sort.direction, sort.field))
        .slice(
          currentPage * rowsPerPage,
          currentPage * rowsPerPage + rowsPerPage
        ) */}
        {tableRows.map((row, index) => {
          const { datesent, email_to, issent } = row;
          // const isSent = issent > 0;
          const [emails, others] = parseEmails(email_to);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`row-${index}`}>
              <TableCell classes={{ root: styles.cellRoot }}>
                <TableCellText
                  size={13}
                  text={moment(datesent).format("MMM DD, YYYY hh:mm A")}
                />
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }}>
                {emails.map((email, eIndex) => (
                  <TableCellText
                    size={13}
                    text={email}
                    key={`email_${eIndex}`}
                  />
                ))}
                {others.length > 0 && (
                  <Tooltip
                    classes={{ tooltip: styles.toolTip }}
                    title={others.map((email, oIndex) => (
                      <Typography variant="body2" key={`other_${oIndex}`}>
                        {email}
                      </Typography>
                    ))}
                    placement="right-start"
                  >
                    <Typography
                      variant="caption"
                      classes={{ root: styles.othersRoot }}
                    >
                      (+{others.length})
                    </Typography>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell classes={{ root: styles.cellRoot }}>
                <TableCellText text={<Status is_sent={issent} />} />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default CustomTableBody;
