const data = [
  {
    id: "1",
    name: "Admin - Resources",
    resource: "/admin/resources/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "2",
    name: "Admin - Trips",
    resource: "/admin/trip/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "3",
    name: "Admin - Activity Settings",
    resource: "/admin/activity/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "4",
    name: "Admin - WTI",
    resource: "/admin/wti/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "5",
    name: "Users",
    resource: "/admin/resources/users/",
    parent_id: "1",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "6",
    name: "Geofences",
    resource: "/admin/trip/geofences/",
    parent_id: "2",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "7",
    name: "Access Rights",
    resource: "/admin/activity/user_levels/",
    parent_id: "3",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "8",
    name: "Alerts",
    resource: "/admin/activity/alerts/",
    parent_id: "3",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "9",
    name: "Trips",
    resource: "/trips/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "10",
    name: "Reports",
    resource: "/reports/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "11",
    name: "Vehicles/CV",
    resource: "/admin/resources/vehicles/",
    parent_id: "1",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "12",
    name: "Clients",
    resource: "/admin/wti/clients/",
    parent_id: "4",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "13",
    name: "GPS Devices",
    resource: "/admin/wti/devices/",
    parent_id: "1",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "14",
    name: "Dashboard",
    resource: "/dashboard/",
    parent_id: "0",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "15",
    name: "Monitoring",
    resource: "/monitoring/",
    parent_id: "0",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "16",
    name: "Change Truck",
    resource: "/trips/change_truck/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "17",
    name: "Split",
    resource: "/trips/split/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "18",
    name: "Dispatch",
    resource: "/trips/dispatch/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "19",
    name: "Manual Complete",
    resource: "/trips/manual_complete/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "20",
    name: "Reorder",
    resource: "/trips/reorder/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "21",
    name: "Map View",
    resource: "/trips/map_view/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "22",
    name: "Store App",
    resource: "/store_app/",
    parent_id: "0",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "23",
    name: "Close",
    resource: "/trips/close/",
    parent_id: "9",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "24",
    name: "Pre booking",
    resource: "/prebooking/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "25",
    name: "Change Truck",
    resource: "/prebooking/change_truck/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "26",
    name: "Split",
    resource: "/prebooking/split/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "27",
    name: "Dispatch",
    resource: "/prebooking/dispatch/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "28",
    name: "Manual Complete",
    resource: "/prebooking/manual_complete/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "29",
    name: "Close",
    resource: "/prebooking/close/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "31",
    name: "Vehicle Types",
    resource: "/admin/resources/vehicle_types/",
    parent_id: "1",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "32",
    name: "GPS Providers",
    resource: "/admin/wti/gps_providers/",
    parent_id: "4",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "33",
    name: "Alert Descriptions",
    resource: "/admin/wti/alert_descriptions/",
    parent_id: "4",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "34",
    name: "Alert References",
    resource: "/admin/wti/alert_references/",
    parent_id: "4",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "35",
    name: "Error Logs",
    resource: "/reports/report_types/error_logs/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "36",
    name: "Trip Summary",
    resource: "/reports/report_types/trip_summary/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "45",
    name: "Vehicle utilization",
    resource: "/reports/report_types/vehicle_utilization/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "46",
    name: "Automated Reports",
    resource: "/reports/automated/",
    parent_id: "10",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "47",
    name: "Report Types",
    resource: "/reports/report_types/",
    parent_id: "10",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "48",
    name: "Activity Logs",
    resource: "/admin/acitivity_logs/",
    parent_id: "4",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "49",
    name: "SMS Sending",
    resource: "/admin/sms_sending/",
    parent_id: "4",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "51",
    name: "Vehicle Alerts",
    resource: "/reports/report_types/vehicle_alerts/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "52",
    name: "Full Movement",
    resource: "/reports/report_types/full_movement/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "53",
    name: "Alert Count Dashboard",
    resource: "/reports/report_types/alert_count_dashboard/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "54",
    name: "Help & Feedback",
    resource: "/feedback/",
    parent_id: "0",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "55",
    name: "Feedback Message",
    resource: "/admin/activity/admin_feedback/",
    parent_id: "3",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "56",
    name: "Vehicle Status",
    resource: "/reports/report_types/vehicle_status/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "57",
    name: "Standard Idling",
    resource: "/reports/report_types/standard_idling/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "59",
    name: "SMS Sending API",
    resource: "/sms_sending_api/",
    parent_id: "0",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "60",
    name: "Control Room",
    resource: "/admin/activity/control_room/",
    parent_id: "3",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "61",
    name: "Prebooking Manual Upload",
    resource: "/prebooking/manual_upload/",
    parent_id: "24",
    action_only: 1,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "62",
    name: "Trips Check-in Check-out(CICO)",
    resource: "/reports/report_types/trips-checkin-checkout/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "63",
    name: "Complete Log",
    resource: "/reports/report_types/complete_log/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "64",
    name: "Speeding",
    resource: "/reports/report_types/speeding/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "65",
    name: "Geofence In and Out",
    resource: "/reports/report_types/geofence_in_and_out/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "66",
    name: "Ignition",
    resource: "/reports/report_types/ignition/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  },
  {
    id: "67",
    name: "Check-in Check-out(CICO)",
    resource: "/reports/report_types/checkin-checkout/",
    parent_id: "47",
    action_only: 0,
    permission: {
      view: true,
      edit: true
    }
  }
];

export default data;
