import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery } from "@apollo/client";
import Swal from "sweetalert2";

// images icons for dashboard;
import AwaitingImg from "../../assets/awaiting.svg";
import AtRiskImg from "../../assets/atRisk.svg";
import OnTrackImg from "../../assets/onTrack.svg";
import OnTimeImg from "../../assets/onTime.svg";
import LateImg from "../../assets/late.svg";
import DispatchImg from "../../assets/dispatch.svg";
import CompletedImg from "../../assets/completed.svg";
import CancelledImg from "../../assets/cancel.svg";
import ResupplyImg from "../../assets/resupply.svg";
import DCPickupImg from "../../assets/dcpickup.svg";
import CustomerImg from "../../assets/customer.svg";

/*
 *** a use custom hook for fetching demurrage and OTTA/OTSA/OTD data.
 *** this hook will remove the unecessary fetching  on dashboards module and in the network. ex. Overviews module.
 */

// default values.
const OTTA_ARRIVED = "OTTA-Arrived";
const OTTA_INCOMING = "OTTA-Incoming";
const OTD_ARRIVED = "OTD-Arrived";
const OTD_IN_TRANSIT = "OTD-In-Transit";
const CLOSED_CANCELLED = "OTD-Cancel-Closed";
const LAND = "Land";
const DEFAULT_COLOR = ["#ED9521", "#FFE15D", "#5F9DF7"];
const CTGRY_DEMURRAGE = "Demurrage";
const CTGRY_ON_TIME = "OnTime";
const OTTA_ARRIVED_LABEL = ["Late", "On Time", "Dispatch"];
const OTTA_INCOMING_LABEL = ["Awaiting", "At Risk", "On Track"];
const OTD_ARRIVED_LABEL = ["Late", "On Time", "Completed"];
const OTD_IN_TRANSIT_LABEL = ["At Risk", "On Track"];
const CLOSED_CANCELLED_LABEL = ["Closed", "Cancelled"];
const LAND_LABEL = ["Resupply", "DC Pickup", "At Risk", "Customer"];

const useFetchChartData = (qry, vars, ctgry) => {
  const [ottaArr, setOttaArr] = useState([]);
  const [ottaInc, setOttaInc] = useState([]);
  const [otdArr, setOtdArr] = useState([]);
  const [otdInTransit, setOtdInTransit] = useState([]);
  const [otdClosedCancelled, setClosedCancelled] = useState([]);
  const [demurrageData, setDemurrageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingsChartValue, setBookingsChartValue] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [tripNumbers, setTripNumbers] = useState([]);
  // checks if this will show the status chart module if data count presented is greated than 0.
  const prepareChartValueHandler = (datas, typeOfChart, index) => {
    let initialDataTemp = [];
    let tempData = {
      data: [],
      backgroundColor: DEFAULT_COLOR,
      labels: []
    };

    datas.forEach(data => initialDataTemp.push(data.count));
    // present the type of chart 
    switch (typeOfChart) {
      case OTTA_ARRIVED:
        tempData.data = initialDataTemp;
        tempData.labels = OTTA_ARRIVED_LABEL;
        break;
      case OTTA_INCOMING:
        tempData.data = initialDataTemp;
        tempData.labels = OTTA_INCOMING_LABEL;
        break;
      case OTD_ARRIVED:
        tempData.data = initialDataTemp;
        tempData.labels = OTD_ARRIVED_LABEL;
        break;
      case OTD_IN_TRANSIT:
        tempData.data = initialDataTemp;
        tempData.backgroundColor = [DEFAULT_COLOR[0], DEFAULT_COLOR[1]];
        tempData.labels = OTD_IN_TRANSIT_LABEL;
        break;
      case CLOSED_CANCELLED:
        tempData.data = initialDataTemp;
        tempData.backgroundColor = [DEFAULT_COLOR[0], DEFAULT_COLOR[1]];
        tempData.labels = CLOSED_CANCELLED_LABEL;
        break;
      case LAND:
        tempData.data = initialDataTemp;
        tempData.backgroundColor = [...DEFAULT_COLOR, "#5D3891"];
        tempData.labels = LAND_LABEL;
        break;
    }

    chartValue[index] = tempData;
    // setChartValue(prev => [...prev, tempData]);
  };
  // prepare the data's needed for the pie chart on the status chart module.
  function prepareBookingsChartHandler(datas, typeOfChart, index) {
    const excludeLabel = ["Customer", "Dispatch"];
    let tripsTemp = [];
    let bookingsTemp = [];
    let chartTemp = {
      trips: {
        label: "Trips",
        data: [],
        color: "#5E9BF7"
      },
      prebooking: {
        label: "Prebooking",
        data: [],
        color: "#ED9521"
      },
      labels: []
    };
    // distribute trips and prebooking to each array.
    datas.forEach(data => {
      if (!excludeLabel.includes(data.label)) {
        tripsTemp.push(data.trips.count);
        bookingsTemp.push(data.bookings.count);
      }
    });

    if (typeOfChart === OTTA_ARRIVED) {
      chartTemp.labels = OTTA_ARRIVED_LABEL.filter(
        label => label !== "Dispatch"
      );
    } else if (typeOfChart === CLOSED_CANCELLED) {
      chartTemp.labels = CLOSED_CANCELLED_LABEL;
    } else if (typeOfChart === LAND) {
      chartTemp.labels = LAND_LABEL.filter(land => land !== "Customer");
    }

    chartTemp.prebooking.data = bookingsTemp;
    chartTemp.trips.data = tripsTemp;

    bookingsChartValue[index] = chartTemp;
    // setBookingsChartValue(prev => [...prev, chartTemp]);
  }
  // otta preparation data.
  const prepareOnTimeHandler = data => {
    const { get_otta, get_otd, get_closed_trip, get_cancelled_trip } = data;
    const { arrived: ottaArr, incoming: ottaInc } = get_otta;
    const { arrived: otdArr, in_transit: otdInTran } = get_otd;
    const {
      closed_trip: otdClosed,
      closed_trip_pre_dispatch,
      closed_trip_trip
    } = get_closed_trip;
    const {
      cancelled_trip: otdCancelled,
      cancelled_trip_pre_dispatch,
      cancelled_trip_trip
    } = get_cancelled_trip;
    // otta - arrived preparation of data for the charts and containers.
    let ottaArrDataTemp,
      ottaArrTripNumbers,
      ottaIncDataTemp,
      otdArrDataTemp,
      otdInTranDataTemp,
      otdClsCancelledDataTemp,
      ClosedCancelTripNumbers;
    ottaArrDataTemp = [
      {
        label: "Late",
        count: ottaArr.late.count,
        tripNumbers: ottaArr.late.trip_numbers,
        bookings: ottaArr.late_pre_dispatch,
        trips: ottaArr.late_trip,
        icon: LateImg,
        category: "OTTA"
      },
      {
        label: "On Time",
        count: ottaArr.on_time.count,
        tripNumbers: ottaArr.on_time.trip_numbers,
        bookings: ottaArr.on_time_pre_dispatch,
        trips: ottaArr.on_time_trip,
        icon: OnTimeImg,
        category: "OTTA"
      },
      {
        label: "Dispatch",
        count: ottaArr.dispatched.count,
        tripNumbers: ottaArr.dispatched.trip_numbers,
        bookings: [],
        trips: [],
        icon: DispatchImg,
        category: "OTTA"
      }
    ];
    // otta incoming preparation values.
    ottaIncDataTemp = [
      {
        label: "Awaiting",
        count: ottaInc.awaiting.count,
        tripNumbers: ottaInc.awaiting.trip_numbers,
        bookings: [],
        trips: [],
        icon: AwaitingImg,
        category: "OTTA"
      },
      {
        label: "At Risk",
        count: ottaInc.at_risk.count,
        tripNumbers: ottaInc.at_risk.trip_numbers,
        bookings: ottaInc.at_risk_pre_dispatch,
        trips: ottaInc.at_risk_trip,
        icon: AtRiskImg,
        category: "OTTA"
      },
      {
        label: "On Track",
        count: ottaInc.on_track.count,
        tripNumbers: ottaInc.on_track.trip_numbers,
        bookings: ottaInc.on_track_pre_dispatch,
        trips: ottaInc.on_track_trip,
        icon: OnTrackImg,
        category: "OTTA"
      }
    ];
    // otd arrived preparation values.
    otdArrDataTemp = [
      {
        label: "Late",
        count: otdArr.late.count,
        tripNumbers: otdArr.late.trip_numbers,
        bookings: [],
        trips: [],
        icon: LateImg,
        category: "OTD"
      },
      {
        label: "On Time",
        count: otdArr.on_time.count,
        tripNumbers: otdArr.on_time.trip_numbers,
        bookings: [],
        trips: [],
        icon: OnTimeImg,
        category: "OTD"
      },
      {
        label: "Completed",
        count: otdArr.completed.count,
        tripNumbers: otdArr.completed.trip_numbers,
        bookings: [],
        trips: [],
        icon: CompletedImg,
        category: "OTD"
      }
    ];
    // otd in transit preparation values.
    otdInTranDataTemp = [
      {
        label: "At Risk",
        count: otdInTran.at_risk.count,
        tripNumbers: otdInTran.at_risk.trip_numbers,
        bookings: [],
        trips: [],
        icon: AtRiskImg,
        category: "OTD"
      },
      {
        label: "On Track",
        count: otdInTran.on_track.count,
        tripNumbers: otdInTran.on_track.trip_numbers,
        bookings: [],
        trips: [],
        icon: OnTrackImg,
        category: "OTD"
      }
    ];
    // otd closed cancelled preparation values.
    otdClsCancelledDataTemp = [
      {
        label: "Closed",
        count: otdClosed.count,
        tripNumbers: otdClosed.trip_numbers,
        bookings: closed_trip_pre_dispatch,
        trips: closed_trip_trip,
        icon: AtRiskImg,
        category: "Closed/Cancelled"
      },
      {
        label: "Cancelled",
        count: otdCancelled.count,
        tripNumbers: otdCancelled.trip_numbers,
        bookings: cancelled_trip_pre_dispatch,
        trips: cancelled_trip_trip,
        icon: CancelledImg,
        category: "Closed/Cancelled"
      }
    ];

    // otta arrive list of trip numbers.
    ottaArrTripNumbers = {
      late: {
        trips: ottaArr.late_trip,
        prebooking: ottaArr.late_pre_dispatch
      },
      onTime: {
        trips: ottaArr.on_time_trip,
        prebooking: ottaArr.on_time_pre_dispatch
      }
    };
    // otta arrive list of trip numbers.
    ClosedCancelTripNumbers = {
      closed: {
        trips: closed_trip_trip,
        prebooking: closed_trip_pre_dispatch
      },
      cancelled: {
        trips: cancelled_trip_trip,
        prebooking: cancelled_trip_pre_dispatch
      }
    };
    // line graph presentation on dashboard.
    prepareBookingsChartHandler(ottaArrDataTemp, OTTA_ARRIVED, 0);
    prepareBookingsChartHandler(otdClsCancelledDataTemp, CLOSED_CANCELLED, 1);
    // pie graph representation on dashboard.
    prepareChartValueHandler(ottaArrDataTemp, OTTA_ARRIVED, 0);
    prepareChartValueHandler(ottaIncDataTemp, OTTA_INCOMING, 1);
    prepareChartValueHandler(otdArrDataTemp, OTD_ARRIVED, 2);
    prepareChartValueHandler(otdInTranDataTemp, OTD_IN_TRANSIT, 3);
    prepareChartValueHandler(otdClsCancelledDataTemp, CLOSED_CANCELLED, 4);

    setTripNumbers(prev => [
      // ...prev,
      ottaArrTripNumbers,
      ClosedCancelTripNumbers
    ]);
    // status containers values.
    setOttaArr(ottaArrDataTemp);
    setOttaInc(ottaIncDataTemp);
    setOtdArr(otdArrDataTemp);
    setOtdInTransit(otdInTranDataTemp);
    setClosedCancelled(otdClsCancelledDataTemp);
  };

  function prepareDemurrageHandler(data) {
    const {
      at_risk,
      at_risk_pre_dispatch,
      at_risk_trip,
      customer,
      dc_pickup,
      dc_pickup_pre_dispatch,
      dc_pickup_trip,
      resupply,
      resupply_pre_dispatch,
      resupply_trip
    } = data.get_demurrage_land;

    let demurrageTempData = [
      {
        label: "Re supply",
        count: resupply.count,
        tripNumbers: resupply.trip_numbers,
        bookings: resupply_pre_dispatch,
        trips: resupply_trip,
        icon: ResupplyImg,
        category: LAND
      },
      {
        label: "DC Pickup",
        count: dc_pickup.count,
        tripNumbers: dc_pickup.trip_numbers,
        bookings: dc_pickup_pre_dispatch,
        trips: dc_pickup_trip,
        icon: DCPickupImg,
        category: LAND
      },
      {
        label: "At Risk",
        count: at_risk.count,
        tripNumbers: at_risk.trip_numbers,
        bookings: at_risk_pre_dispatch,
        trips: at_risk_trip,
        icon: AtRiskImg,
        category: LAND
      },
      {
        label: "Customer",
        count: customer.count,
        tripNumbers: customer.trip_numbers,
        icon: CustomerImg,
        category: LAND
      }
    ];

    const landTripNumbers = {
      reSupply: {
        trips: resupply_trip,
        prebooking: resupply_pre_dispatch
      },
      dcPickup: {
        trips: dc_pickup_trip,
        prebooking: dc_pickup_pre_dispatch
      },
      atRisk: {
        trips: at_risk_trip,
        prebooking: at_risk_pre_dispatch
      }
    };

    // setTripNumbers(prev => [...prev, landTripNumbers]);
    setTripNumbers([landTripNumbers]);
    prepareBookingsChartHandler(demurrageTempData, LAND, 0);
    prepareChartValueHandler(demurrageTempData, LAND, 0);
    setDemurrageData(demurrageTempData);
  }

  const { data, loading } = useQuery(qry, {
    variables: vars,
    fetchPolicy: "cache-and-network",
    pollInterval: 60000,
    onError: () => {
      Swal.fire({
        text: "Something went wrong",
        icon: "error",
        timer: 3000
      });
    }
  });

  useEffect(() => {
    if (!data) return;
    // validates type of query used for fetching data.
    if (ctgry === CTGRY_DEMURRAGE) prepareDemurrageHandler(data);
    else if (ctgry === CTGRY_ON_TIME) prepareOnTimeHandler(data);
    setIsLoading(false);
  }, [data, vars, ctgry]);

  return {
    data: {
      ottaArr,
      ottaInc,
      otdArr,
      otdInTransit,
      demurrageData,
      otdClosedCancelled,
      bookingsChartValue,
      chartValue,
      tripNumbers
    },
    isLoading
  };
};

export default useFetchChartData;
