import React from "react";
import UpcomingFeatureBg from "../assets/Images/UpcomingFeatureBg.png";

const UpcomingFeature = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${UpcomingFeatureBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      ></div>
    </div>
  );
};

export default UpcomingFeature;
