// eslint-disable-next-line import/prefer-default-export
export const feedBackOpts = [
  {
    id: 0,
    name: "fb_faqs",
    label: "FAQs",
    subMenus: [
      {
        id: "access_account",
        label: "Access & Account"
      },
      {
        id: "trip_settings",
        label: "Trip Settings"
      },
      {
        id: "fleet_monitoring",
        label: "Fleet Monitoring"
      },
      {
        id: "reports",
        label: "Reports"
      }
    ]
  },
  {
    id: 1,
    name: "fb_Feedback",
    label: "Feedback",
    subMenus: []
  },
  {
    id: 2,
    name: "fb_contactUs",
    label: "Contact Us",
    subMenus: []
  }
];
