import React, { useState, useCallback } from "react";
import {
  makeStyles,
  Typography,
  Switch,
  Grid,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Accordion,
  FormControlLabel,
  FormGroup,
  styled
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const filterList = [
  { name: "Geofences", key: "geofences" },
  { name: "Traffic Overlay", key: "traffic" },
];

const MapFilter = ({ setFilters, filters}) => {
  const handleFilterToggle = e => {
    const { checked, name } = e.target;
    setFilters({ ...filters, [name]: checked });
  };

  const renderFilterOptions = useCallback(() => {
    return filterList.map(filter => (
      <FormControlFilter
        key={filter.key}
        control={
          <FilterSwitch
            name={filter.key}
            checked={filters[filter.key]}
            onChange={handleFilterToggle}
          />
        }
        size="small"
        labelPlacement="start"
        label={<Typography variant="caption">{filter.name}</Typography>}
      />
    ));
  }, [filterList, filters]);

  return (
    <MapFilterContainer>
      <AccordionFilter elevation={0}>
        <AccordionSummaryFilter expandIcon={<ExpandMoreIcon />}>
          <Typography variant="caption">Map Filters</Typography>
        </AccordionSummaryFilter>
        <AccordionDetailsFilter>
          <FormGroupFilter>{renderFilterOptions()}</FormGroupFilter>
        </AccordionDetailsFilter>
      </AccordionFilter>
    </MapFilterContainer>
  );
};

const MapFilterContainer = styled("div")(({ theme }) => ({}));
const AccordionFilter = styled(Accordion)(({ theme }) => ({
  background: "rgba(255,255,255)",
  boxShadow: "0px 0px 18px -8px #484848d4",
  width: "150px",
  "& .Mui-expanded": {
    minHeight: "auto"
  },
  "& .MuiAccordionSummary-expandIcon": {
    padding: 0
  },
  "& .MuiIconButton-edgeEnd": {
    margin: 0
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem"
  }
}));
const AccordionSummaryFilter = styled(AccordionSummary)(({ theme }) => ({
  padding: "5px 8px",
  margin: 0,
  height: "auto",
  minHeight: "auto",
  "& .MuiAccordionSummary-content": {
    margin: 0
  }
}));
const AccordionDetailsFilter = styled(AccordionDetails)(({ theme }) => ({
  padding: "5px 8px",
  margin: 0,
  height: "auto"
}));
const FilterSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);
const FormControlFilter = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  justifyContent: "space-between",
  width: "100%"
}));
const FormGroupFilter = styled(FormGroup)(({ theme }) => ({
  width: "100%"
}));

export default MapFilter;
