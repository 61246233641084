import React from "react";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as MuiTableCell
} from "@material-ui/core";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    width: "170px"
  }
})(MuiTableCell);

const UnpairingDialog = props => {
  const {
    toggle,
    referenceData, // value: device or vehicle
    fn,
    close,
    tripInfo: { trips, devicealias, plateno }
  } = props;
  const showTripNumbers = !!trips?.ongoingTrips?.tripNumbers.length;
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => {
    close();
  };

  const title =
    referenceData === "device" ? "Unpair Device?" : "Unpair Vehicle?";
  const contentText =
    referenceData === "device"
      ? "This device is currently paired with vehicle"
      : "This vehicle is currently paired with device";

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.closeContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          <span className={classes.closeTitle}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <span className={classes.closeDescription}>
                {showTripNumbers ? (
                  <>
                    {contentText}{" "}
                    <b>{referenceData === "device" ? plateno : devicealias}</b>{" "}
                    and has an active trip. To unpair the device please close
                    the following trips:
                  </>
                ) : (
                  "Are you sure you want to unpair the device to vehicle?"
                )}
              </span>
            </div>
            {!!trips?.ongoingTrips?.tripNumbers?.length && (
              <div className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tablehead}>
                        Trip Number
                      </TableCell>
                      <TableCell className={classes.tablehead}>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <div className={classes.tablebody}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableBody>
                      {[
                        ...trips.ongoingTrips.forClose,
                        ...trips.ongoingTrips.forReset
                      ].map(trip => (
                        <TableRow>
                          <TableCell>{trip.key}</TableCell>
                          <TableCell>{trip.value[0].status_code}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className={classes.circularButtonNo}
          >
            Cancel
          </Button>
          {showTripNumbers ? (
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                history.push({
                  pathname: "/trips",
                  state: {
                    params: {
                      plateno,
                      trip_numbers: trips?.ongoingTrips?.tripNumbers,
                      ongoingTrips: trips?.ongoingTrips,
                      forUnpairing: true
                    }
                  }
                });
              }}
              color="primary"
              autoFocus
              className={classes.circularButtonYes}
            >
              Review Trips
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={event => {
                fn(event);
                handleClose();
              }}
              color="primary"
              autoFocus
              className={classes.circularButtonYes}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 120,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },
  table: {
    border: "1px solid gray"
  },
  tablebody: {
    overflow: "auto",
    maxHeight: "125px",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "orange",
      borderRadius: "20px"
    }
  },
  tablehead: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
}));

export default UnpairingDialog;
