export const countVehicles = [
  {
    isHeader: true,
    label: "Count",
    dataHeader: "count",
    subCategory: [],
    isTotal: false,
    filterType: "",
    condition: {}
  },
  {
    isHeader: false,
    label: "Vehicles in View",
    dataHeader: "totalVehicles",
    subCategory: [],
    isTotal: true,
    filterType: "",
    condition: {}
  },
  {
    isHeader: false,
    label: "Available",
    dataHeader: "available",
    subCategory: [],
    filterType: "Vehicle",
    condition: {
      value: process.env.REACT_APP_STATUS_AVAILABLE,
      label: "Available"
    }
  },
  {
    isHeader: false,
    label: "Booked",
    dataHeader: "booked",
    filterType: "Vehicle",
    condition: {
      value: process.env.REACT_APP_VEHICLE_STATUS_BOOKED,
      label: "Booked"
    },
    subCategory: [
      {
        label: "Under Repair",
        dataHeader: "booked_underrepair",
        filterType: "Booked",
        condition: {
          value: process.env.REACT_APP_VEHICLE_STATUS_UNDER_REPAIR,
          label: "Under Repair"
        }
      },
      {
        label: "Not In Use",
        dataHeader: "booked_not_in_use",
        filterType: "Booked",
        condition: {
          value: process.env.REACT_APP_VEHICLE_STATUS_BOOKED,
          label: "Booked"
        }
      }
    ]
  },
  {
    isHeader: false,
    label: "Active",
    dataHeader: "active",
    subCategory: [],
    isTotal: false,
    filterType: "Vehicle",
    condition: {
      value: process.env.REACT_APP_STATUS_ACTIVE,
      label: "Active"
    }
  },
  {
    isHeader: false,
    label: "Under Repair",
    dataHeader: "underrepair",
    subCategory: [],
    isTotal: false,
    filterType: "Vehicle",
    condition: {
      value: process.env.REACT_APP_VEHICLE_STATUS_UNDER_REPAIR,
      label: "Under Repair"
    }
  },
  {
    isHeader: false,
    label: "Not in use",
    dataHeader: "not_in_use",
    subCategory: [],
    isTotal: false,
    filterType: "Vehicle",
    condition: {
      value: process.env.REACT_APP_VEHICLE_STATUS_NOT_IN_USE,
      label: "Not in use"
    }
  },
  {
    isHeader: true,
    label: "GPS Status",
    dataHeader: "gps_status",
    subCategory: [],
    isTotal: false,
    filterType: "",
    condition: {}
  },
  {
    isHeader: false,
    label: "Online",
    dataHeader: "online",
    subCategory: [],
    isTotal: false,
    filterType: "GPS",
    condition: {
      value: process.env.REACT_APP_STATUS_ONLINE,
      label: "online"
    }
  },
  {
    isHeader: false,
    label: "Offline",
    dataHeader: "offline",
    subCategory: [],
    isTotal: false,
    filterType: "GPS",
    condition: {
      value: process.env.REACT_APP_STATUS_OFFLINE,
      label: "Offline"
    }
  },
  {
    isHeader: false,
    label: "No GPS",
    dataHeader: "no_gps",
    subCategory: [],
    isTotal: false,
    filterType: "GPS",
    condition: {
      value: +process.env.REACT_APP_STATUS_NO_GPS,
      label: "No GPS"
    }
  },
  {
    isHeader: false,
    label: "Delayed Reporting",
    dataHeader: "delay_reporting",
    subCategory: [],
    isTotal: false,
    filterType: "GPS",
    condition: {
      value: process.env.REACT_APP_STATUS_DELAY_REPORTING,
      label: "Delayed Reporting"
    }
  }
];
