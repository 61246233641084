import React, { useState, Fragment } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Button,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import EmailLogin from "./EmailLogin";
import CookieLogin from "./CookieLogin";
import userLogo from "../../../assets/Profile-pic-Small.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360
    // backgroundColor: theme.palette.background.paper
  },
  listItem: {
    border: "1px solid rgba(63, 63, 63, 0.16)",
    borderRadius: 32
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16
  },
  usersListContainer: {
    height: "45vh",
    padding: 5,
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    marginBottom: "5px"
  }
}));

const Users = props => {
  const { users, handleDeleteUser, setMode } = props;
  const [listMode, setListMode] = useState(true);
  const [cookieUser, setCookieUser] = useState({});
  const classes = useStyles();

  return listMode ? (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          style={{
            fontSize: 14,
            color: "#989898",
            marginTop: "5%"
          }}
        >
          Choose your account to continue
        </Typography>
      </div>

      <div className={classes.usersListContainer}>
        <List className={classes.root}>
          {users.map(user => (
            <ListItem
              key={user.username}
              className={classes.listItem}
              style={{
                borderRadius: 32,
                border: `1px solid rgba(63, 63, 63, 0.16)`,
                margin: `5px 0`
              }}
              button
              onClick={() => {
                setCookieUser(user);
                setListMode(false);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt=""
                  src={
                    user.profile_image
                      ? `${process.env.REACT_APP_CLOUD_STORAGE_BASE_URL +
                          user.profile_image}`
                      : userLogo
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={user.username}
                style={{ color: "#989898" }}
              />
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDeleteUser(user.username);
                }}
              >
                <CloseIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className={classes.button}
        onClick={() => setMode(false)}
      >
        Use another account
      </Button>
    </Fragment>
  ) : (
    <CookieLogin setListMode={setListMode} user={cookieUser} />
  );
};

const UsersList = props => {
  const { users, handleDeleteUser } = props;
  const [mode, setMode] = useState(true);

  return (
    <div style={{  }}>
      {mode ? (
        <Users
          users={users}
          handleDeleteUser={handleDeleteUser}
          setMode={setMode}
        />
      ) : (
        <EmailLogin setMode={setMode} fromCookie />
      )}
    </div>
  );
};

export default UsersList;
