import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  ListItemText,
  Typography,
  InputAdornment
} from "@material-ui/core";
import {
  Brightness1,
  ErrorOutline,
  Event as EventIcon,
  Reorder
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { statusColor } from "../../Utils";
import { ItemsDialog, IssuesDialog } from "./Dialogs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    // width: "100%",
    marginBottom: theme.spacing(2),
    padding: 8
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  expandable: {
    verticalAlign: "top"
  },
  subCell: {
    display: "block",
    marginTop: "20px"
  },
  button: {
    margin: 4
  },
  link: {
    margin: 5,
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer"
  }
}));

const headCells = [
  { id: "draggable", numeric: false, disablePadding: false, label: "" },
  {
    id: "row_num",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "so_number",
    numeric: false,
    disablePadding: false,
    label: "SO number"
  },
  {
    id: "stops",
    numeric: false,
    disablePadding: false,
    label: "Stops"
  },
  {
    id: "plan_arrival",
    numeric: false,
    disablePadding: false,
    label: "Plan Arrival Time"
  },
  {
    id: "actual_arrival",
    numeric: false,
    disablePadding: false,
    label: "Actual Arrival Time"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const DraggableList = props => {
  const classes = useStyles();
  const { parsedTripData, isEdit, setParsedTripData, stops, setStops } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("so_number");

  const [itemsDialogOpen, setItemsDialogOpen] = useState({
    items: false,
    issues: {
      open: false,
      mode: "edit",
      activeIndex: undefined
    }
  });
  const [activeItems, setActiveItems] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSoClick = so_number => {
    const { raw_dropoffs } = parsedTripData;

    const items = raw_dropoffs.filter(
      dropoff => dropoff.so_number === so_number
    );
    setActiveItems(items);
    setItemsDialogOpen({ ...itemsDialogOpen, items: true });
  };

  const addIssue = (issue, so_number, is_pickup) => {
    const { dropoffs, pickups, raw_pickups, raw_dropoffs } = parsedTripData;
    if (!is_pickup) {
      const do_index = pickups.length - so_number;
      dropoffs[do_index] = { ...dropoffs[do_index], issue: issue };
      _.forEach(raw_dropoffs, rdo => {
        const rdo_index = _.findIndex(dropoffs[so_number].so_numbers, so => {
          return rdo.so_number === so;
        });
        if (rdo_index !== -1) {
          raw_dropoffs[rdo_index] = {
            ...raw_dropoffs[rdo_index],
            issue: issue
          };
        }
      });
    } else {
      pickups[so_number] = { ...pickups[so_number], issue: issue };
      _.forEach(raw_pickups, rpu => {
        const rpu_index = _.findIndex(pickups[so_number].so_numbers, so => {
          return rpu.so_number === so;
        });
        if (rpu_index !== -1) {
          raw_pickups[rpu_index] = {
            ...raw_pickups[rpu_index],
            issue: issue
          };
        }
      });
    }
    setParsedTripData({
      ...parsedTripData,
      dropoffs: dropoffs,
      pickups: pickups,
      raw_pickups: raw_pickups,
      raw_dropoffs: raw_dropoffs
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = ({ source, destination }) => {
    if (destination) {
      if (source.index !== destination.index) {
        const newStops = reorder(stops, source.index, destination.index);
        setStops(newStops);
      }
    }
  };

  const renderDateInput = (stop, index) => {
    let display = "-";

    const handleArrivalChange = date => {
      const { dropoffs, pickups, raw_pickups, raw_dropoffs } = parsedTripData;
      if (stop.is_pickup) {
        pickups[index] = {
          ...pickups[index],
          manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss")
        };

        _.forEach(pickups[index].so_numbers, so => {
          const rpu_index = _.findIndex(
            raw_pickups,
            rpu => rpu.so_number === so
          );
          if (rpu_index !== -1) {
            raw_pickups[rpu_index] = {
              ...raw_pickups[rpu_index],
              manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              status_code: "Manually Completed",
              status_code_id: 28
            };
          }
        });
      } else {
        const do_index = index - pickups.length;
        dropoffs[do_index] = {
          ...dropoffs[do_index],
          manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss")
        };

        _.forEach(dropoffs[do_index].so_numbers, so => {
          const rdo_index = _.findIndex(
            raw_dropoffs,
            rdo => rdo.so_number === so
          );
          if (rdo_index !== -1) {
            raw_dropoffs[rdo_index] = {
              ...raw_dropoffs[rdo_index],
              manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              status_code: "Manually Completed",
              status_code_id: 28
            };
          }
        });
      }
      setParsedTripData({
        ...parsedTripData,
        dropoffs: dropoffs,
        pickups: pickups,
        raw_pickups: raw_pickups
      });
    };

    if (stop.status_code_id === 20 || stop.status_code_id === 2) {
      display = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            disableFuture
            InputProps={{
              placeholder: "Actual Arrival Time",
              endAdornment: (
                <InputAdornment position="end">
                  <EventIcon />
                </InputAdornment>
              ),
              name: "actual_arrival"
            }}
            value={stop.manual_arrival}
            showTodayButton
            todayLabel="NOW"
            onChange={handleArrivalChange}
          />
        </MuiPickersUtilsProvider>
      );
    } else if (stop.status_code_id === 28) {
      display = moment(stop.manual_arrival).format("lll");
    } else if (stop.status_code === 15 || stop.status_code === 24) {
      display = moment(stop.actual_arrival).format("lll");
    } else if (stop.is_pickup && stop.actual_arrival) {
      display = moment(stop.actual_arrival).format("lll");
    } else if (stop.status_code_id === 4) {
      display = moment(stop.actual_arrival).format("lll");
    }
    return display;
  };

  return (
    <Fragment>
      <ItemsDialog
        open={itemsDialogOpen}
        items={activeItems}
        setItemsDialogOpen={setItemsDialogOpen}
      />
      <IssuesDialog
        open={itemsDialogOpen}
        setItemsDialogOpen={setItemsDialogOpen}
        addIssue={addIssue}
      />
      <Table className={classes.table} size="medium">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="sample">
            {drop => (
              <TableBody ref={drop.innerRef}>
                {drop.placeholder}
                {stops.length &&
                  stops.map((stop, index) => {
                    let display = "";
                    let actual_arrival = "-";
                    const so_numbers = stop.so_numbers.map(so_number => (
                      <Typography
                        className={classes.link}
                        onClick={() => {
                          handleSoClick(so_number);
                        }}
                        key={so_number}
                      >
                        {so_number}
                      </Typography>
                    ));
                    let { status_code } = stop;
                    if (stop.status_code_id === 4) {
                      actual_arrival = moment(stop.actual_arrival).format(
                        "lll"
                      );
                    } else if (stop.status_code_id === 28) {
                      actual_arrival = moment(stop.manual_arrival).format(
                        "lll"
                      );
                    } else if (
                      stop.status_code_id === 15 ||
                      stop.status_code_id === 24
                    ) {
                      actual_arrival = moment(stop.actual_arrival).format(
                        "lll"
                      );
                      status_code = "Arrived";
                    } else {
                      status_code = "Not yet arrived";
                    }
                    const key = `SO-${index}`;
                    const isDraggable = ![
                      +process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF, //24
                      +process.env.REACT_APP_STATUS_COMPLETED //4
                    ].includes(+stop.status_code_id);
                    if (
                      stop.is_pickup ||
                      stop.status_code_id === 4 ||
                      stop.status_code_id === 15 ||
                      stop.status_code_id === 24 ||
                      stop.status_code_id === 27
                    ) {
                      display = (
                        <TableRow key={key}>
                          <TableCell>
                            {!stop.is_pickup && (
                              <Reorder
                                style={{
                                  cursor: isDraggable ? "not-allowed" : "pointer",
                                  color: isDraggable ? "grey" : "black"
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                float: "none",
                                backgroundColor: stop.is_pickup
                                  ? "purple"
                                  : "#4380F0",
                                borderRadius: 4,
                                color: "white"
                              }}
                            >
                              {index + 1}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {so_numbers || (
                              <Typography
                                className={classes.link}
                                onClick={() => handleSoClick(stop.so_number)}
                              >
                                {stop.so_number}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            <ListItemText
                              primary={stop.name}
                              secondary={stop.location_details}
                            />
                          </TableCell>
                          <TableCell>
                            {moment(stop.arrival).format("lll")}
                          </TableCell>
                          <TableCell>
                            {!isEdit ? actual_arrival : renderDateInput(stop)}
                          </TableCell>
                          <TableCell align="center">
                            {((stop.issue && stop.issue !== "null") || stop.is_late > 0) && (
                              <Tooltip title={(stop.issue !== "null" && stop.issue) || "Late Delivery"}>
                                <ErrorOutline
                                  style={{ color: "red", fontSize: 20 }}
                                />
                              </Tooltip>
                            )}
                            <Tooltip title={status_code}>
                              <Brightness1
                                style={{
                                  color: statusColor(stop.status_code_id),
                                  fontSize: 20
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      display = (
                        <Draggable
                          draggableId={index.toString()}
                          index={index}
                          key={key}
                        >
                          {drag => {
                            // const key = `SO-${index}`;
                            return (
                              <TableRow
                                ref={drag.innerRef}
                                {...drag.dragHandleProps}
                                {...drag.draggableProps}
                              >
                                <TableCell>
                                  <Reorder style={{ cursor: "pointer" }} />
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    style={{
                                      float: "none",
                                      backgroundColor: stop.is_pickup
                                        ? "purple"
                                        : "#4380F0",
                                      borderRadius: 4,
                                      color: "white"
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {so_numbers || (
                                    <Typography
                                      className={classes.link}
                                      onClick={() =>
                                        handleSoClick(stop.so_number)
                                      }
                                    >
                                      {stop.so_number}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <ListItemText
                                    primary={stop.name}
                                    secondary={stop.location_details}
                                  />
                                </TableCell>
                                <TableCell>
                                  {moment(stop.arrival).format("lll")}
                                </TableCell>
                                <TableCell>
                                  {!isEdit
                                    ? actual_arrival
                                    : renderDateInput(stop, index)}
                                </TableCell>
                                <TableCell align="center">
                                  {((stop.issue && stop.issue !== "null") || stop.is_late > 0) && (
                                    <Tooltip title={(stop.issue !== "null" && stop.issue) || "Late Delivery"}>
                                      <ErrorOutline
                                        style={{ color: "red", fontSize: 20 }}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip title={status_code}>
                                    <Brightness1
                                      style={{
                                        color: statusColor(stop.status_code_id),
                                        fontSize: 20
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      );
                    }
                    return display;
                  })}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </Fragment>
  );
};

export default DraggableList;
