import React from "react";
import { useLazyQuery } from "@apollo/client";
import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Place } from "@material-ui/icons";
import useSearchStyles from "./Search.styles";
import { GET_GEOFENCES } from "../../../graphql/Queries";
// import UserContext from "../../../context/UserContext";
import useUserContext from "../../../context/User/useUserContext";

const SearchGeofences = React.forwardRef(
  (
    {
      onPlaceChanged = () => {},
      placeText = "",
      onTextSearchChanged = () => {}
    },
    ref
  ) => {
    const [selectedGeofence, setSelectedGeofence] = React.useState();
    // const user = React.useContext(UserContext);
    const user = useUserContext();
    const [get_geofences, { loading, data }] = useLazyQuery(GET_GEOFENCES, {
      variables: {
        first: 50
      },
      fetchPolicy: "network-only"
    });

    React.useEffect(() => {
      if (!placeText) {
        setSelectedGeofence(null);
      }
    }, [placeText]);

    const styles = useSearchStyles();

    return (
      <Autocomplete
        key={placeText}
        value={selectedGeofence}
        id="places"
        fullWidth
        options={data?.get_geofences?.geofences || []}
        style={{ width: "250px" }}
        onChange={(e, val) => {
          if (val) {
            setSelectedGeofence(val);
            onPlaceChanged(val);
            onTextSearchChanged(`${val.geofence_code}-${val.name}`);
          }
        }}
        getOptionLabel={place => {
          return `${place.geofence_code}-${place.name}`;
        }}
        renderOption={option => (
          <React.Fragment>
            <Place fontSize="small" />
            {`${option.geofence_code}-${option.name}`}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            // inputRef={ref}
            value={placeText}
            placeholder="Search Places"
            fullWidth
            style={{ background: "white", margin: 0 }}
            margin="normal"
            InputProps={{
              ...params.InputProps,
              classes: {
                root: styles.textField
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {/* {params.InputProps.endAdornment} */}
                </React.Fragment>
              )
            }}
            onChange={event => {
              // onTextSearchChanged(event.target.value);
              if (event.target.value !== "") {
                get_geofences({
                  variables: {
                    first: 50,
                    keyword: event.target.value,
                    filter: [
                      {
                        field: "group_id",
                        value: user.group_ids.map(id => id.toString())
                      }
                    ]
                  }
                });
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: "bold" }
            }}
          />
        )}
      />
    );
  }
);

export default SearchGeofences;
