import React, { useReducer } from "react";
import { withRouter } from "react-router-dom";
import {
  Fab,
  Button,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  TableSortLabel,
  Tooltip,
  IconButton,
  TableBody
} from "@material-ui/core";
import {
  Add as AddIcon,
  GetApp as GetAppIcon,
  Visibility,
  DeleteRounded
} from "@material-ui/icons";

// Components
import ButtonLink from "../../../../../components/Report/common/ButtonLink";
import TableCellText from "../../../../../components/Report/common/TableCellText";
import Header from "../../../../../components/Report/common/Header";
import { formatEvery } from "../../../../../components/Report/utils/format";
import parseEmails from "../../../../../components/Report/utils/parseEmails";
import {
  getComparator,
  stableSort
} from "../../../../../components/Report/Automated/ReportsTable/helpers";
// import Pagination from "../common/Pagination";
import Table from "../../../../../components/Report/Automated/ReportsTable";
import {
  tableHeadStyles as useTableHeadStyles,
  headerCells
} from "../../../../../components/Report/Automated/ReportsTable/TableHead";

// Styles
import useAutomatedStyles from "../../../../../components/Report/Automated/styles/automatedReports";
import buttonStyles from "../../../../../components/Report/common/buttonStyles";
import useTableBodyStyles from "../../../../../components/Report/Automated/styles/useTableBodyStyles";
import { defaultReducer } from "../../../../../components/Report/Automated/reducers";

// Hooks
import useAllowedPermissions from "../../../../hooks/useAllowedPermissions";
import Pagination from "../../../../table/Pagination";

import Focus, { useInfoBubbleStyles } from "../../../common/Focus";
import Stage from "../../../common/Stage/Stage";
import { useOnboardingContext } from "../../../index";
import data from "./automated.data";

const ActionLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Visibility fontSize="small" />
        </span>
        <span>View</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <DeleteRounded fontSize="small" />
        </span>
        <span>Delete</span>
      </li>
    </ul>
  );
};

const Reports = () => {
  const [state] = useReducer(defaultReducer, {
    tableState: {
      data: data,
      rowsPerPage: 10,
      currentPage: 0,
      tableItemsCount: undefined,
      tableItemsTotal: 0,
      sort: {
        field: "id",
        direction: "desc"
      },
      selectedRows: []
    }
  });
  const styles = useAutomatedStyles({
    selectedRows: state.tableState.selectedRows
  });

  React.useEffect(() => {
    window.location.hash = "no-back-button";

    // Again because Google Chrome doesn't insert
    // the first hash into the history
    window.location.hash = "Again-No-back-button";

    window.onhashchange = function() {
      window.location.hash = "no-back-button";
    };
  }, []);

  const { loading: isPermissionLoading } = useAllowedPermissions(
    "/reports/automated/"
  );

  const tableHeadStyles = useTableHeadStyles();
  const tableBodyStyles = useTableBodyStyles();
  const btnStyles = buttonStyles({
    buttonType: "download",
    maxWidth: 150,
    minWidth: 150
  });
  const {
    progress: { reports: currentStep },
    completeOnboarding
  } = useOnboardingContext();

  if (isPermissionLoading) {
    return <React.Fragment />;
  }

  // if (!permissions?.view) {
  //   // return <Typography>403!!!!</Typography>;
  //   return <Forbidden />;
  // }
  const stage = {
    12: {
      buttonText: "Start",
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };
  return (
    <div className={styles.root}>
      {/* {!permissions?.view ? (
        <Forbidden />
      ) : ( */}
      <>
        <Focus
          step={12}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: [12].includes(currentStep) ? "block" : "none",
            background: "transparent !important"
          }}
          showInfoBubble={false}
        >
          <Stage {...stage[currentStep]} />
        </Focus>
        <Header>
          <div style={{ flex: 1, display: "flex" }}>
            <Focus
              step={7}
              // indicator={currentStep === 7}
              // indicatorProps={{
              //   yOffset: -7,
              //   xOffset: -7
              // }}
              infoBubbleProps={{
                title: "Manual Report",
                description: "Displays the manual generation of report"
              }}
              style={{ padding: 8, width: "fit-content" }}
            >
              {/* <ButtonLink text="Manual Reports" resource="/reports/" /> */}
              <Button
                // className={btnStyles.button}
                style={{
                  padding: "5px 20px",
                  borderRadius: 20,
                  textTransform: "capitalize",
                  letterSpacing: 0.25
                }}
                variant="contained"
                color="primary"
              >
                Manual Reports
              </Button>
            </Focus>

            <Focus
              step={8}
              infoBubbleProps={{
                title: "Download",
                description:
                  "Allows the transfer of data from web to user's device"
              }}
              style={{ padding: 8, width: "fit-content" }}
            >
              <Button
                className={btnStyles.button}
                variant="contained"
                startIcon={<GetAppIcon />}
              >
                <Typography variant="subtitle1">Download</Typography>
              </Button>
            </Focus>
          </div>
          <div>
            <Pagination rowsPerPage={10} total={100} currentPage={1} />
          </div>
        </Header>
        <Table>
          <Focus
            step={9}
            // on={true}
            component={TableHead}
            classes={{ root: tableHeadStyles.root }}
          >
            <TableRow>
              <TableCell
                padding="checkbox"
                classes={{ root: tableHeadStyles.cellRoot }}
              >
                <Checkbox color="primary" />
              </TableCell>
              {headerCells.map(cell => {
                return (
                  <Focus
                    step={10}
                    on={cell.label === "Action"}
                    component={TableCell}
                    classes={{ root: styles.cellRoot }}
                    // padding={cell.disablePadding ? "none" : "normal"}
                    padding="checkbox"
                    key={cell.id}
                    align={cell.id === "actions" ? "center" : "left"}
                  >
                    {cell.label !== "" && cell.label !== "Action" ? (
                      <TableSortLabel>{cell.label}</TableSortLabel>
                    ) : (
                      cell.label
                    )}
                  </Focus>
                );
              })}
            </TableRow>
          </Focus>
          <TableBody>
            {stableSort(
              state.tableState.data,
              getComparator(
                state.tableState.sort.direction,
                state.tableState.sort.field
              )
            ).map((row, rowIndex) => {
              const isRowSelected = false;
              const [type, day] = row.sked.split(":");
              const timeSked = row.timesked;
              const every = formatEvery(day, timeSked, type);
              const [emails, others] = parseEmails(row.email_add);
              return (
                <Focus
                  step={9}
                  on={rowIndex === 0}
                  component={TableRow}
                  showInfoBubble={false}
                  key={row.id}
                >
                  <TableCell classes={{ root: tableBodyStyles.cellRoot }}>
                    <Checkbox color="primary" checked={isRowSelected} />
                  </TableCell>
                  <TableCell classes={{ root: tableBodyStyles.cellRoot }}>
                    <TableCellText
                      text={row.report_type}
                      capitalize
                      key={`report_type_${row.id}`}
                    />
                  </TableCell>
                  <TableCell classes={{ root: tableBodyStyles.cellRoot }}>
                    <TableCellText
                      text={type}
                      capitalize
                      key={`type_${row.id}`}
                    />
                  </TableCell>
                  <Focus
                    step={9}
                    on={rowIndex === 0}
                    infoBubbleProps={{
                      position: "bottom",
                      title: "Data Table",
                      description: "Displays details of the scheduled reports"
                    }}
                    component={TableCell}
                    classes={{ root: tableBodyStyles.cellRoot }}
                  >
                    <TableCellText
                      text={every}
                      capitalize
                      key={`every_${row.id}`}
                    />
                  </Focus>
                  <TableCell classes={{ root: tableBodyStyles.cellRoot }}>
                    {emails.map(email => (
                      <TableCellText text={email} key={`email_${row.id}`} />
                    ))}
                    {others.length > 0 && (
                      <Tooltip
                        classes={{ tooltip: tableBodyStyles.toolTip }}
                        title={others.map((email, index) => (
                          <Typography variant="body2" key={`other_${index}`}>
                            {email}
                          </Typography>
                        ))}
                        placement="right-start"
                      >
                        <Typography
                          variant="caption"
                          classes={{ root: tableBodyStyles.othersRoot }}
                        >
                          (+{others.length})
                        </Typography>
                      </Tooltip>
                    )}
                  </TableCell>
                  <Focus
                    step={10}
                    on={rowIndex === 0}
                    component={TableCell}
                    infoBubbleProps={{
                      position: "left",
                      title: "Action",
                      description:
                        "Shows available actions to be executed on a report",
                      body: <ActionLegends />
                    }}
                    classes={{ root: tableBodyStyles.cellRoot }}
                    align="center"
                  >
                    <IconButton>
                      <Visibility />
                    </IconButton>
                    <IconButton>
                      <DeleteRounded />
                    </IconButton>
                  </Focus>
                </Focus>
              );
            })}
          </TableBody>
        </Table>
      </>
      {/* )} */}
      <Focus
        step={11}
        style={{
          position: "absolute",
          bottom: 20,
          right: 90,
          padding: 10
        }}
        infoBubbleProps={{
          position: "top",
          title: "New Report",
          description: "Allows the user to add new scheduled report"
        }}
      >
        <Fab color="primary" aria-label="add">
          <AddIcon fontSize="large" htmlColor="#fff" />
        </Fab>
      </Focus>
    </div>
  );
};

export default withRouter(Reports);
