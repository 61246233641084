/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles, CircularProgress, TextField } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GET_GEOFENCES_V2 } from "../../../graphql/Queries";

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
}));

export default function SearchBoxGeofence(props) {
  const classes = useStyles();
  const {
    setCenter,
    setDestination,
    setFitboundDestination,
    setTextValue,
    textValue,
    lastTextboxVal
  } = props;
  const [open, setOpen] = React.useState(false);
  const [textboxValue, setTextBoxValue] = React.useState(lastTextboxVal?.current.value || "");
  // const [options, setOptions] = React.useState([]);
  let typingTimer = null;
  const UserPlacesOption = [];
  const [getGeofence, { loading: loadingUI, data }] = useLazyQuery(
    GET_GEOFENCES_V2
  );
  useEffect(() => {
    let active = true; // eslint-disable-line no-unused-vars

    if (!loadingUI) {
      return undefined;
    }
    return () => {
      active = false;
    };
  }, [loadingUI]);
  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    };
  }, []);

  const handleTextVal = val => {
    setTextBoxValue(val);
    lastTextboxVal.current.value = val;
    lastTextboxVal.current.submit = true;
  };

  if (data) {
    for (const val of data.get_geofences_v2.geofences) {
      if (val.name && val.location)
        UserPlacesOption.push({ name: val.name, location: val.location });
    }
    UserPlacesOption.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  }

  return (
    <Autocomplete
      id="asynchronous-demo"
      inputValue={textboxValue && textboxValue}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, values) => {
        setTextValue(values.name);
        handleTextVal(values.name);
        setDestination({
          lat: values.location.lat,
          lng: values.location.lon
        });

        setFitboundDestination({
          lat: values.location.lat,
          lng: values.location.lon
        });
        setCenter({
          lat: values.location.lat,
          lng: values.location.lon
        });
      }}
      options={UserPlacesOption}
      getOptionLabel={option => option.name}
      noOptionsText="No Items matched the searched"
      loading={loadingUI}
      style={{
        border: `0px solid transparent`,
        width: `300px`,
        borderRadius: `0px`,
        fontSize: `14px`,
        outline: `none`,
        textOverflow: `ellipses`,
        flex: "1"
      }}
      renderInput={params => (
        <TextField
          {...params}
          style={{
            width: `95%`
          }}
          fullWidth
          className={classes.input}
          onChange={e => {
            const val = e.target.value;
            handleTextVal(val);
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
              if (val) {
                await getGeofence({
                  variables: {
                    keyword: val,
                    first: 10,
                    keyword_fields: ["name"]
                  },
                  fetchPolicy: "cache-and-network"
                });
              }
            }, 500);
          }}
          placeholder={textValue || "Search User Places"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingUI ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
