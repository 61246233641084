import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { Fragment, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { FixedSizeList } from "react-window";
import useDebounce from "../../../utils/hooks/useDebounce";
import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_VEHICLES_QUERY } from "../../../graphql/Queries";
import moment from "moment";
import { TripOrigin } from "@material-ui/icons";
import Swal from "sweetalert2";
import AddLogs from "../../../utils/functions/AddLogs";

let debounceTimer = 500;
const AllocationVehicleAssign = props => {
  const {
    toggle,
    moduleName,
    closeVehicleModal,
    editAllocation,
    selectedData,
    refetch,
    handleClearFilter
  } = props;

  const classes = useStyles();

  //  states to get selected vehicle
  const [vehicles, setVehicles] = useState([]);
  const [keyword, setSearch] = useDebounce("", debounceTimer);
  const [chosenVehicle, setChosenVehicle] = useState(null);

  //   initial date varibales value
  const initialVariables = {
    start:
      selectedData?.pickup_date &&
      moment(selectedData?.pickup_date).format("YYYY-MM-DD  HH:mm:ss"),
    end:
      selectedData?.delivery_date &&
      moment(selectedData?.delivery_date).format("YYYY-MM-DD HH:mm:ss")
  };

  //   variables for available vehicles
  const vehicleVariables = () => {
    const variable = {
      filter: [],
      first: 10,
      dateRange: { ...initialVariables },
      orderBy: [
        {
          field: "vehicle_info.plateno",
          direction: "asc"
        }
      ]
    };

    if (keyword) {
      variable.filter.push({
        field: "vehicle_info.plateno",
        value: keyword
      });
    }
    return variable;
  };

  //   query for availble vehicles
  const { data: availableVehicle, loading: loadingVehicle } = useQuery(
    GET_AVAILABLE_VEHICLES_QUERY,
    {
      variables: vehicleVariables(),
      onCompleted(data) {
        setVehicles(parseVehicleData(data.get_available_vehicles?.vehicles));
      }
    }
  );

  //   mutations for assigning vehicle
  const submitVehicle = () => {
    closeVehicleModal();
    editAllocation({
      variables: {
        id: selectedData.id,
        allocation: {
          vehicle_id: Number(chosenVehicle.id),
          // vehicle_type_id: Number(chosenVehicle.vehicle_type_id),
          status_code_id: Number(process.env.REACT_APP_STATUS_ASSIGNED_ID)
        }
      },
      onCompleted(data) {
        if (data.edit_allocation.success) {
          Swal.fire({
            title: "The vehicle was successfully assgined",
            icon: "success",
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
            onClose: () => {}
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              setChosenVehicle(null);
              handleClearFilter();
              refetch();
            }
          });
        }
        AddLogs(process.env.REACT_APP_VEHICLE_ALLOCATION, "assign", [
          chosenVehicle.plateno,
          selectedData.trip_number,
          selectedData.client_name
        ]);
      }
    });
  };

  const selectVehicle = (e, vehicle) => {
    setChosenVehicle(vehicle);
  };

  // functions for color dependes on vehicle status
  const vehicleStatus = status => {
    const s = status?.toLowerCase();
    let data = {};

    switch (s) {
      case "active":
      case "available":
      case "inactive":
      case "delayed":
      case "no gps":
        data = {
          color: "#63F160"
        };
        break;
      default:
        data = {
          color: "#63F160"
        };
    }
    return data;
  };

  // render the values of available vehicles
  function renderRow(props) {
    const { index, style, data } = props;
    const vehicle = data[index];
    let styling = { ...style };
    styling = { ...styling, marginTop: "5px" };
    return (
      <Fragment>
        <ListItem
          button
          style={styling}
          key={index}
          onClick={e => selectVehicle(e, vehicle)}
          selected={chosenVehicle?.id === vehicle?.id}
        >
          <ListItemIcon>
            <TripOrigin
              style={{
                color:
                  vehicle.status === "BOOKED"
                    ? "#F4E665"
                    : vehicleStatus(vehicle.status_code).color,
                fontSize: 16,
                margin: "auto"
              }}
            />
          </ListItemIcon>
          <ListItemText
            id={`vehciles${index}`}
            primary={
              <Typography
                component="div"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {vehicle.plateno}
              </Typography>
            }
            component="span"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  className={useStyles.inline}
                  color="textPrimary"
                  style={{ marginRight: "50px" }}
                >
                  Vehicle Type:
                </Typography>
                {vehicle.vehicle_type}
              </>
            }
          />
          <Typography
            style={{ float: "right" }}
            variant="body2"
            component="span"
          >
            {vehicle.status.toUpperCase()}
          </Typography>
        </ListItem>
      </Fragment>
    );
  }

  // parse vehilces selected data
  const parseVehicleData = data => {
    return data.map(vehicle => {
      return {
        id: vehicle.vehicle_info.id,
        plateno: vehicle.vehicle_info.plateno,
        status: vehicle.trips ? "BOOKED" : "AVAILABLE",
        status_code_id: vehicle.vehicle_info.status_code_id,
        status_code: vehicle.vehicle_info.status_code,
        vehicle_type: vehicle.vehicle_info.vehicle_type,
        vehicle_type_id: vehicle.vehicle_info.vehicle_type_id
      };
    });
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={toggle} onClose={closeVehicleModal}>
        <DialogTitle>Select Vehicle/CV</DialogTitle>
        <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Plate #"
              onChange={e => setSearch(e.target.value)}
            />
            <IconButton disabled className={classes.iconButton}>
              <SearchIcon />
            </IconButton>
          </Paper>
          {loadingVehicle ? (
            <CircularProgress size={40} />
          ) : (
            <DialogContentText component="span" style={{ textAlign: "center" }}>
              <FixedSizeList
                height={400}
                width="auto"
                itemSize={56}
                itemCount={vehicles?.length}
                itemData={vehicles}
              >
                {renderRow}
              </FixedSizeList>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.circularButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              closeVehicleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.circularButton}
            variant="outlined"
            color="primary"
            disabled={chosenVehicle === null}
            onClick={() => {
              submitVehicle();
            }}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  }
}));

export default AllocationVehicleAssign;
