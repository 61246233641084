/* eslint-disable react-hooks/exhaustive-deps */
/* global google */
import React, { useState } from "react";
import {
  Drawer,
  Toolbar,
  Typography,
  IconButton,
  Button,
  TextField,
  Menu,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  CircularProgress
} from "@material-ui/core";
import Cookie from "js-cookie";
import wkt from "wkt";
import { Autocomplete } from "@material-ui/lab";
import {
  FilterList,
  Close,
  MoreVert,
  Place,
  Help as HelpIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  // GET_VEHICLE_HAULERS,
  GET_GEOFENCES,
  GET_VEHICLE_TYPE_LIST
} from "../../graphql/Queries";
// import UserContext from "../../context/UserContext";
import useUserContext from "../../context/User/useUserContext";
import PlaceAutocomplete from "./PlaceAutocomplete";
import useFilterHauler from "../../utils/hooks/useFilterHauler";
import useDebounce from "../../utils/hooks/useDebounce";
import { GET_ACL_MODULES_CLIENT } from "../../graphql/Queries";
import SwitchHelper from "../../utils/UI/Switch/SwitchHelper";

const useStyles = makeStyles(theme => ({
  list: {
    width: 300,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 32,
    paddingRight: 32
  },
  toolbar: {
    background: theme.palette.primary.main
  },
  helpIcon: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const libraries = ["geometry", "drawing", "places"];

const Filter = props => {
  const {
    openFilter,
    setOpenFilter,
    filterValues,
    setFilterValues,
    applyFilter,
    clearFilter,
    setIsFiltered,
    isAdmin,
    hasAccessPrebookingTrips,
    isLogistics,
    setIsLogistics
  } = props;
  const classes = useStyles();
  // const [clientOptions, setClientOptions] = useState([]);

  // const userContext = useContext(UserContext);
  const userContext = useUserContext();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
    version: "3.47",
    channel: `tmsuite-portal-${userContext.client.name}`
  });
  
  const vehicleStatusOptions = [
    { value: 0, label: "All", isShown: true },
    {
      value: "18",
      label: "Available",
      isShown:
        (isAdmin && isLogistics) || (!isAdmin && hasAccessPrebookingTrips)
    },
    {
      value: "20",
      label: "Booked",
      isShown:
        (isAdmin && isLogistics) || (!isAdmin && hasAccessPrebookingTrips)
    },
    {
      value: process.env.REACT_APP_STATUS_ACTIVE,
      label: "Active",
      isShown:
        (isAdmin && !isLogistics) || (!isAdmin && !hasAccessPrebookingTrips)
    },
    { value: "1", label: "Under Repair", isShown: true },
    { value: "2", label: "Not in use", isShown: true }
  ];

  const [haulerKeyword, setHaulerKeyword] = useDebounce("", 800);
  const haulerGroupOptions = useFilterHauler(haulerKeyword);

  const [anchorEl, setAnchorEl] = useState(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([
    { value: 0, label: "All" }
  ]);
  const [vehicleTypeValue, setVehicleTypeValue] = useState(
    vehicleTypeOptions[0]
  );

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [places, setPlaces] = useState([]);
  // const [locationType, setLocationType] = useState("user_place");
  const [locationType, setLocationType] = useState(
    filterValues?.location_type || "user_place"
  );
  // const user = JSON.parse(Cookie.get("user"));
  const [get_geofences, { loading }] = useLazyQuery(GET_GEOFENCES, {
    onCompleted: data => {
      if (data) {
        setPlaces([...data.get_geofences.geofences]);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  const handleRadioChange = event => {
    setLocationType(event.target.value);
    setPlaces([]);
    placesRef.current = null;
    setFilterValues({
      ...filterValues,
      location: null,
      location_type: event.target.value
    });
  };

  {
    /* 
  useQuery(GET_VEHICLE_HAULERS, {
    variables: {
      ...(haulerKeyword ? { keyword: haulerKeyword } : {})
    },
    onCompleted: data => {
      let tempHauler = [{ id: 0, label: "All" }];
      userContext.client.group_ids.forEach((haulerId, index) => {
        tempHauler.push({
          value: haulerId,
          label: userContext.client.group_names[index]
        });
      });
      setClientOptions(tempHauler);
      //   if (data) {
      //     const { get_vehicle_haulers } = data;
      //     const c = get_vehicle_haulers.map(cl => {
      //       return {
      //         value: cl.client_id,
      //         label: cl.client_name
      //       };
      //     });
      //     setClientOptions([{ value: 0, label: "All" }, ...c]);
      //   }
    }
  }); */
  }

  useQuery(GET_VEHICLE_TYPE_LIST, {
    variables: {
      ...(+userContext.user_level_id !== +process.env.REACT_APP_WTI_ADMIN && {
        group_ids: userContext.group_ids
      })
    },
    skip: !openFilter,
    onCompleted: data => {
      if (data) {
        const { get_vehicle_types } = data;
        const tempList = vehicleTypeOptions;
        get_vehicle_types.vehicle_types.map(cl => {
          tempList.push({
            value: cl.id,
            label: cl.name
          });
          return cl;
        });
        setVehicleTypeOptions(tempList);
      }
    }
  });

  const handleFilterChange = (e, nv) => {
    const newValue = {
      value: e.target.value,
      label: nv.props.children
    };
    setFilterValues({
      ...filterValues,
      [e.target.name]: newValue,
      isCountEffect: false
    });
  };

  const circleBoundsParser = (lat, lng, radius = null) => {
    const circle = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0,
      // center: searchLatLng || { lat, lng },
      // radius: searchRadius || 250
      center: { lat, lng },
      radius: radius || 250
    });
    const numberOfPoints = 180;
    const points = [];
    const steps = 360 / numberOfPoints;
    for (let i = 0; i < numberOfPoints; i += 1) {
      const gpos = window.google.maps.geometry.spherical.computeOffset(
        circle.center,
        circle.radius, // meter,
        steps * i
      );
      points.push(`${gpos.lat()} ${gpos.lng()}`);
    }
    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    // points.map(point => {
    //   const [lat, lng] = point.split(" ");
    // })
    return points;
  };

  const coordinatesParser = wkbGeometry => {
    const temp = [];
    // if (!_.isEmpty(wkbGeometry)) {
    const { coordinates } = wkbGeometry;
    // if (type === "polygon") {
    coordinates[0][0].map(coords =>
      temp.push(new google.maps.LatLng(coords[0], coords[1]))
    );
    // } else if (type === "rectangle") {
    //   const coords = coordinates[0];
    //   temp = new google.maps.LatLngBounds(
    //     new google.maps.LatLng(coords[3][1], coords[3][0]),
    //     new google.maps.LatLng(coords[1][1], coords[1][0])
    //   );
    // }
    // }
    return temp;
  };

  const placesRef = React.useRef(
    filterValues?.location_type === "google_place"
      ? filterValues?.location?.current
      : null
  );

  const onPlaceChanged = place => {
    placesRef.current = place;
    setFilterValues({
      ...filterValues,
      location: place ? placesRef : null
    });
  };
  return (
    <Drawer open={openFilter} onClose={() => setOpenFilter(false)}>
      <div style={{ flexGrow: 1 }}>
        <Toolbar
          classes={{
            root: classes.toolbar
          }}
        >
          <FilterList style={{ color: "white", marginRight: 16 }} />
          <Typography variant="h6" style={{ color: "white", flexGrow: 1 }}>
            Filter
          </Typography>
          <IconButton
            style={{ color: "white" }}
            onClick={() => setOpenFilter(false)}
          >
            <Close />
          </IconButton>
        </Toolbar>
        <div className={classes.list}>
          <div style={{ float: "right" }}>
            <Button
              onClick={() => {
                clearFilter();
                setIsFiltered(false);
                setPlaces([]);
                setLocationType("user_place");
                setVehicleTypeValue(vehicleStatusOptions[0]);
                placesRef.current = null;
              }}
            >
              Clear All
            </Button>
          </div>
          <Autocomplete
            options={haulerGroupOptions}
            getOptionLabel={option => option.label}
            renderOption={option => option.label}
            getOptionSelected={(o, v) => v.value === o.value}
            renderInput={params => (
              <TextField
                {...params}
                label="Hauler/Groups"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: "bold" }
                }}
                onChange={e => setHaulerKeyword(e.target.value)}
              />
            )}
            value={filterValues?.hauler}
            onChange={(e, nv) =>
              setFilterValues(prevFilter => {
                return {
                  ...prevFilter,
                  hauler: nv
                };
              })
            }
            disableClearable
          />
          {isAdmin && (
            <SwitchHelper
              handleChangeHandler={() => {
                setIsLogistics(!isLogistics);
                setFilterValues(prev => ({
                  ...prev,
                  isLogistic: !isLogistics,
                  vehicle_status: {
                    value: 0,
                    label: "All"
                  }
                }));
                // applyFilter();
              }}
              checkedValue={isLogistics}
              label="Use Logistics Status"
              name="is_logistics"
              tooltipPlacement="top-start"
              tooltipTitle={
                <>
                  Toggle on: report that will be generated will display
                  logistics (Booked/Available) status <br />
                  <br />
                  Toggle off: report that will be based on non-logistics
                  (Active) status
                </>
              }
              icon={<HelpIcon className={classes.helpIcon} />}
              customStyle={{ padding: 0, margin: "16px 0 8px 0" }}
            />
          )}
          <TextField
            value={filterValues?.vehicle_status?.value}
            margin="normal"
            fullWidth
            label="Vehicle Status"
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: "bold" }
            }}
            select
            onChange={handleFilterChange}
            name="vehicle_status"
          >
            {vehicleStatusOptions.map(vStat => (
              <MenuItem
                key={vStat.value}
                value={vStat.value}
                style={{ display: vStat.isShown ? "block" : "none" }}
              >
                {vStat.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            label="GPS Status"
            value={filterValues?.gps_status?.value}
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: "bold" }
            }}
            margin="normal"
            onChange={handleFilterChange}
            name="gps_status"
          >
            {[
              { value: 1010, label: "All" },
              { value: process.env.REACT_APP_STATUS_ONLINE, label: "Online" },
              { value: process.env.REACT_APP_STATUS_OFFLINE, label: "Offline" },
              {
                value: process.env.REACT_APP_STATUS_DELAY_REPORTING,
                label: "Delayed Reporting"
              },
              { value: process.env.REACT_APP_STATUS_NO_GPS, label: "No GPS" }
            ].map(opt => (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            label="Vehicle State"
            value={filterValues?.vehicle_state?.value}
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: "bold" }
            }}
            margin="normal"
            onChange={handleFilterChange}
            name="vehicle_state"
          >
            {[
              { value: 0, label: "All" },
              { value: 1, label: "Moving" },
              { value: 2, label: "Not Moving" }
            ].map(opt => (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            options={vehicleTypeOptions}
            getOptionLabel={option => option.label}
            renderOption={option => option.label}
            getOptionSelected={(o, v) => v.value === o.value}
            renderInput={params => (
              <TextField
                {...params}
                label="Vehicle Type"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: "bold" }
                }}
              />
            )}
            value={vehicleTypeValue}
            onChange={(e, nv) => {
              setVehicleTypeValue(nv);
              setFilterValues(prevFilter => {
                return {
                  ...prevFilter,
                  vehicle_type: nv
                };
              });
            }}
            disableClearable
          />
          <TextField
            select
            fullWidth
            label="Temperature"
            value={filterValues?.temperature?.value}
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: "bold" }
            }}
            margin="normal"
            onChange={handleFilterChange}
            name="temperature"
          >
            {[
              { value: 0, label: "All" },
              { value: 1, label: "With Temp Sensor" },
              { value: 2, label: "Without Temp Sensor" }
            ].map(opt => (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {locationType === "google_place" ? (
              isLoaded && (
                <PlaceAutocomplete
                  ref={placesRef}
                  onPlaceChanged={onPlaceChanged}
                />
              )
            ) : (
              <Autocomplete
                style={{ flex: 1 }}
                id="places"
                options={places}
                value={filterValues?.location}
                getOptionLabel={place => {
                  return `${place.geofence_code}-${place.name}`;
                }}
                renderOption={option => (
                  <React.Fragment>
                    <Place fontSize="small" />
                    {`${option.geofence_code}-${option.name}`}
                  </React.Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="All"
                    label="Location"
                    margin="normal"
                    onChange={event => {
                      if (event.target.value !== "") {
                        get_geofences({
                          variables: {
                            first: 50,
                            keyword: event.target.value,
                            group_ids: userContext.group_ids.map(id => +id),
                            keyword_fields: [
                              "name",
                              "geofence_code",
                              "category",
                              "region",
                              "province",
                              "municipality"
                            ],
                            orderBy: [
                              {
                                field: "name",
                                direction: "asc"
                              }
                            ]
                            // filter: [
                            //   {
                            //     field: "group_ids",
                            //     value: user.group_ids.map(id => id.toString())
                            //   }
                            // ]
                          }
                        });
                      } else {
                        setPlaces([]);
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontWeight: "bold" }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={15} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                onChange={(e, val) => {
                  // setValue(val);
                  setFilterValues(prevFilter => {
                    return {
                      ...prevFilter,
                      location_type: locationType,
                      location: val
                    };
                  });
                }}
              />
            )}
            <IconButton onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <FormControl
                component="fieldset"
                style={{ padding: "0px 15px", outline: "none" }}
              >
                <RadioGroup value={locationType} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="user_place"
                    control={<Radio color="primary" />}
                    label="User Places"
                  />
                  <FormControlLabel
                    value="google_place"
                    control={<Radio color="primary" />}
                    label="Google Places"
                  />
                </RadioGroup>
              </FormControl>
            </Menu>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 32,
          paddingRight: 32
        }}
      >
        <Button
          style={{ borderRadius: 32 }}
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => {
            if (locationType === "user_place") {
              if (!filterValues.location) {
                filterValues.bounds = [];
                applyFilter(true);
                setOpenFilter(false);
                return;
              }
              const { geom } = filterValues.location;
              const bounds = coordinatesParser(wkt.parse(geom));
              const points = bounds.map(
                point => `${point.lat()} ${point.lng()}`
              );
              filterValues.bounds = points;
              applyFilter(true);
              setOpenFilter(false);
            } else if (locationType === "google_place") {
              if (!placesRef?.current?.getPlace()) {
                filterValues.bounds = [];
                applyFilter(true);
                setOpenFilter(false);
                return;
              }
              const {
                lat,
                lng
              } = placesRef.current.getPlace().geometry.location;
              filterValues.bounds = circleBoundsParser(lat(), lng(), 1200);
              setFilterValues({
                ...filterValues,
                location_type: locationType,
                location: placesRef,
                center: {
                  lat: lat,
                  long: lng
                },
              });
              applyFilter(true);
              setOpenFilter(false);
            }
          }}
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default Filter;
