import React, { memo } from "react";
import { Add as AddIcon } from "@material-ui/icons";
import { useStyles } from "./NoDataStyle";
import { Typography, IconButton } from "@material-ui/core";
/*
 *** This will show when no data was found on the client module.
 */

const NoData = (props) => {
  const { functionHandler, title, subtitle, mode } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.no_Data_Container}>
        <IconButton
          color="inherit"
          onClick={functionHandler}
          style={{ padding: 6 }}
          disabled={!mode}
        >
          <AddIcon fontSize="large" color={mode? "primary": "inherit"}/>
        </IconButton>
        <div className={classes.no_Data_Content}>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {subtitle}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default memo(NoData);
