import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import {
  Person as PersonIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import lodash from "lodash";
import Cookie from "js-cookie";
import { useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { LOGIN_MUTATION } from "../../../graphql/Mutations";
// import userImage from "../../../assets/user.svg";
import userImage from "../../../assets/Profile-pic-Small.png";
import { HelperCount } from "../../Utils";
import {
  GET_USER_LEVEL,
  GET_USERS_INFO,
  GET_GROUPS
} from "../../../graphql/Queries";
import AddLogs from "../../../utils/functions/AddLogs";
import { makeStyles } from "@material-ui/styles";
import updateCookie from "../../../utils/functions/updateCookie";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16
  },
  svgBackground: {
    color: theme.palette.primary.main
  },
  forgotPasswordColor: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
}));

let timeout;

const logInLock = [
  { attempts: 4, waitTime: 5, timeString: "5 seconds" }, // 1st attempt
  { attempts: 3, waitTime: 5, timeString: "5 seconds" }, // 2nd attempt
  { attempts: 2, waitTime: 60, timeString: "1 minute" }, // 3rd attempt
  { attempts: 1, waitTime: 60, timeString: "1 minute" }, // 4th attempt
  { attempts: 0, waitTime: 60, timeString: "1 minute" }
];

const Timer = (time, RESET_INTERVAL_S) => {
  const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);
  return timeRemain;
};

const IntervalTimer = props => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(t => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);
  return Timer(time, props.waitTime);
};

const EmailLogin = props => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState({
    value: "",
    visibility: false
  });
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true); // Clickable signin button

  const lockAccounts = localStorage.getItem("lock")
    ? JSON.parse(localStorage.getItem("lock"))
    : [];

  // const { loading, data: user_level, refetch } = useQuery(GET_USER_LEVEL, {
  //   fetchPolicy: "network-only",
  //   skip: !success
  // });

  const [getUserLevel, { loading, data: user_level }] = useLazyQuery(
    GET_USER_LEVEL
  );

  const [getUser] = useLazyQuery(GET_USERS_INFO);

  const [getGroups] = useLazyQuery(GET_GROUPS);

  const redirect = id => {
    setSuccess(true);
    getUserLevel({
      variables: {
        id: id
      }
    });
  };

  const getErrorMessage = (timeString, attempts, waitTime) => {
    // if (attempts === 0) {
    //   return [
    //     "Your account has been locked due to consecutive failed login attempts. Please contact your Administrator"
    //   ];
    // }

    return [
      `Error: Invalid Credentials. Try again in `,
      <IntervalTimer waitTime={waitTime} />
    ];
  };

  useEffect(() => {
    // Clear current timeout value
    clearTimeout(timeout);

    // Find index of username in list of locked accounts
    const userIndex = lockAccounts.findIndex(acc => acc.username === username);

    // If account exists, get and compare data
    if (userIndex > -1) {
      const { expiration, attempts } = lockAccounts[userIndex];
      const { timeString } = logInLock.find(
        a => a.attempts === Number(attempts)
      );

      if (attempts === 0) {
        // If no attempts remaining, disable sign in button
        setIsAllowed(false);
        // If waiting time is not yet expired, disable sign in button
        if (moment().format() < expiration) {
          // Compute for remaining wait time
          const waitTime = moment.duration(moment().diff(expiration));
          // Convert waitTime to seconds
          const timeRemain = Math.abs(waitTime / 1000).toFixed();
          setErrors(getErrorMessage(timeString, Number(attempts), timeRemain));
          timeout = setTimeout(() => {
            setIsAllowed(true);
            setErrors([]);
          }, timeRemain * 1000);
        } else {
          // If no restrictions, set sign in button clickable and empty error message
          setIsAllowed(true);
          setErrors([]);
        }
        // Set error message based on time and attempts values
        // setErrors(getErrorMessage(timeString, Number(attempts)));
      } else if (moment().format() < expiration) {
        // If waiting time is not yet expired, disable sign in button
        setIsAllowed(false);

        // Compute for remaining wait time
        const waitTime = moment.duration(moment().diff(expiration));

        const timeRemain = Math.abs(waitTime / 1000).toFixed();

        // Set error message based on time and attempts
        setErrors(getErrorMessage(timeString, Number(attempts), timeRemain));

        // Allow sign in button clickable after waitTime
        timeout = setTimeout(() => {
          setIsAllowed(true);
          setErrors([]);
        }, Math.abs(waitTime.asMilliseconds()));
      } else {
        // If no restrictions, set sign in button clickable and empty error message
        setIsAllowed(true);
        setErrors([]);
      }

      // If username does not exists, set sign in button clickable and empty error message
    } else {
      setIsAllowed(true);
      setErrors([]);
    }
  }, [username]);

  useEffect(() => {
    const { history } = props;
    let link = "/";
    if (success) {
      if (!loading) {
        if (user_level.get_user_level.acl_module_resource) {
          link = user_level.get_user_level.acl_module_resource;
        }
      }
      history.push(link);
    }
  }, [user_level]);

  const processFailedAttempt = returnData => {
    if (returnData?.length > 0) {
      // Find index of username in list of locked accounts
      const userIndex = lockAccounts.findIndex(
        acc => acc.username === username
      );
      // If account does not exist, add/update localStorage
      if (returnData[0].includes("did not match our records")) {
        let timer = logInLock[0].waitTime;
        let timerString = logInLock[0].timeString;
        let countdownAttempts = logInLock[0].attempts;

        if (userIndex > -1) {
          // If username exists, update data
          const { attempts } = lockAccounts[userIndex];
          const { waitTime, timeString } = logInLock.find(
            a => a.attempts === Number(attempts !== 0 ? attempts : 1) - 1
          );

          // Update data in list of locked accounts
          lockAccounts[userIndex] = {
            ...lockAccounts[userIndex],
            attempts: Number(attempts !== 0 ? attempts : 1) - 1,
            expiration: moment()
              .add(waitTime, "seconds")
              .format()
          };

          // set new values for error message and timer
          timerString = timeString;
          countdownAttempts = Number(attempts) - 1;
          timer = waitTime;
        } else {
          // If username does not exists, create new
          const newLockAccount = {
            username: username,
            attempts: countdownAttempts,
            expiration: moment()
              .add(5, "seconds")
              .format()
          };
          lockAccounts.push(newLockAccount);
        }

        // Rephrase error message
        setErrors(
          getErrorMessage(timerString, Number(countdownAttempts), timer)
        );

        // allow clickable sign in button after wait time
        // if (countdownAttempts !== 0) {
        timeout = setTimeout(() => {
          setIsAllowed(true);
          setErrors([]);
        }, timer * 1000);
        // }
        localStorage.setItem("lock", JSON.stringify(lockAccounts));

        // if non-existing users get group id for webcast
        getGroups({
          variables: {
            condition: { field: "name", value: "Webcast" }
          }
        }).then(response => {
          if (response.data.get_groups.count > 0) {
            const data = {
              group_ids: [Number(response.data.get_groups.groups[0].id)],
              user_level_id: "",
              username: "Unknown"
            };
            // then add logs for invalid login
            AddLogs("Login", "login_attempt", "", data);
          }
        });

        // If account exists in database and has remaining attempts
      } else if (returnData[0].includes("Attempts remaining")) {
        // Get remaining attempt value
        const attempts = returnData[0].split("Attempts remaining: ").pop();
        const { waitTime, timeString } = logInLock.find(
          a => a.attempts === Number(attempts)
        );

        // Save login lock details
        const newLockAccount = {
          username: username,
          attempts: attempts,
          expiration: moment()
            .add(waitTime, "seconds")
            .format()
        };
        if (userIndex > -1) {
          // If username exists in locked accounts, update data
          lockAccounts[userIndex] = newLockAccount;
        } else {
          // If username does not exists, create new
          lockAccounts.push(newLockAccount);
        }
        localStorage.setItem("lock", JSON.stringify(lockAccounts));

        // Rephrase error message
        setErrors(getErrorMessage(timeString, Number(attempts), waitTime));

        // allow clickable sign in button after wait time
        timeout = setTimeout(() => {
          setIsAllowed(true);
          setErrors([]);
        }, waitTime * 1000);

        // if users password is incorrect get username, userlevel, group_ids
        getUser({
          variables: {
            condition: { field: "username", value: username }
          }
        }).then(response => {
          if (response.data.get_users.count > 0) {
            // then add logs
            AddLogs(
              "Login",
              "login_attempt",
              "",
              response.data.get_users.users[0]
            );
          }
        });
      } else if (returnData[0].includes("Your account has been locked")) {
        const { waitTime, timeString, attempts } = logInLock.find(
          a => a.attempts === Number(0)
        );

        const newLockAccount = {
          username: username,
          attempts: attempts,
          expiration: moment()
            .add(waitTime, "seconds")
            .format()
        };

        if (userIndex > -1) {
          // If username exists in locked accounts, update data
          lockAccounts[userIndex] = newLockAccount;
        } else {
          // If username does not exists, create new
          lockAccounts.push(newLockAccount);
        }
        localStorage.setItem("lock", JSON.stringify(lockAccounts));

        setErrors(getErrorMessage(timeString, Number(attempts), waitTime));

        timeout = setTimeout(() => {
          setIsAllowed(true);
          setErrors([]);
        }, waitTime * 1000);
        // setErrors(returnData);
        // setIsAllowed(true);
      } else {
        setErrors(returnData);
        setIsAllowed(true);
      }
    } else {
      setErrors(returnData);
      setIsAllowed(true);
    }
  };

  const { fromCookie, setMode } = props;
  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: data => {
      if (data.login) {
        const { login: userData } = data;
        if (moment(userData.user.account_expiration) < moment()) {
          setErrors([
            "Your account has expired. Please contact your Administrator."
          ]);
        } else {
          let users = Cookie.get("users") ? Cookie.get("users") : [];
          if (typeof users === "string") {
            users = JSON.parse(users);
          }

          // Cookie.set("token", userData.token);
          // Cookie.set("user", userData.user);
          updateCookie("token", userData.token_V2);
          updateCookie("user",userData.user);
          const newUsers = lodash.find(users, [
            "username",
            userData.user.username
          ]);
          if (typeof newUsers === "undefined") {
            if (users.length >= 10) {
              users.pop();
              users.unshift({
                username: userData.user.username,
                profile_image: userData.user.profile_image
              });
              Cookie.set("users", users);
            } else {
              users.unshift({
                username: userData.user.username,
                profile_image: userData.user.profile_image
              });
              Cookie.set("users", users);
            }
          } else {
            const userFilter = users.filter(
              user => user.username !== userData.user.username
            );
            userFilter.unshift({
              username: userData.user.username,
              profile_image: userData.user.profile_image
            });
            Cookie.set("users", userFilter);
          }
          AddLogs("Login", "login", "");
          localStorage.clear();
          // history.push("/");
          redirect(userData.user.user_level_id);
        }
      }
      setIsAllowed(true);
    },
    onError: ApolloError => {
      const { graphQLErrors: error } = JSON.parse(JSON.stringify(ApolloError));
      const returnData = error.map(el => {
        return el.message;
      });
      // setErrors(returnData);
      processFailedAttempt(returnData);
    }
    // errorPolicy: "none"
  });

  const triggerLogin = () => {
    if (!username) {
      setErrors(["Please enter your username/email"]);
    } else if (!password.value) {
      setErrors(["Please enter your password"]);
    } else {
      setIsAllowed(false);
      setErrors([]);
      login({
        variables: {
          username: username,
          password: password.value
        }
      });
    }
  };

  const signInButtonRef = React.useRef(null);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: 14,
            color: "#989898",
            marginTop: "5%"
          }}
        >
          Let&#39;s track and manage your trips
        </Typography>
        {fromCookie && (
          <Typography component="div">
            <IconButton onClick={() => setMode(true)}>
              <ArrowBackIcon />
            </IconButton>
          </Typography>
        )}
        <Typography align="center" component="div">
          <img alt="" src={userImage} width={92.5} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="username"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon className={classes.svgBackground} />
              </InputAdornment>
            )
          }}
          placeholder="Enter your username or email address *"
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Enter your password *"
          name="password"
          type={!password.visibility ? `password` : `text`}
          onChange={e => setPassword({ ...password, value: e.target.value })}
          onKeyDown={e => {
            if (e.key === "Enter") {
              signInButtonRef.current.click();
            }
          }}
          value={password.value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon className={classes.svgBackground} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setPassword({
                      ...password,
                      visibility: !password.visibility
                    })
                  }
                >
                  {!password.visibility ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          disabled={!isAllowed}
        />
      </Grid>
      <Grid item xs={12}>
        {errors.length ? (
          <HelperCount message={errors[0]} count={errors[1]} />
        ) : (
          // errors.map(el => {
          //   return <HelperCount message={el} />;
          // })
          <React.Fragment />
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          ref={signInButtonRef}
          variant="contained"
          color="primary"
          // style={{ borderRadius: 16 }}
          fullWidth
          onClick={triggerLogin}
          disabled={!isAllowed}
          className={classes.button}
        >
          Sign in
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Link to="/forgot_password" style={{ textDecoration: "none" }}>
          <Typography
            align="center"
            className={classes.forgotPasswordColor}
            // style={{ color: "#808080" }}
          >
            Forgot Password?
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default withRouter(EmailLogin);
