/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  AppBar
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close as CloseIcon, FilterList } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useQuery } from "@apollo/client";
import {
  GET_BOOKING_GEOFENCES,
  GET_VEHICLE_TYPE_LIST
} from "../../../graphql/Queries";

import { Helper } from "../../Utils";
import useDebounce from "../../../utils/hooks/useDebounce";
import useFormStyles from "../../../styles/useFormStyles";
import useUserContext from "../../../context/User/useUserContext";
import _ from "lodash";

const Filter = props => {
  const user = useUserContext();
  const {
    filterDrawer,
    classes,
    handleClearFilter,
    handleSetFilters,
    closeFilterDrawer,
    tempFilter,
    setTempFilter
  } = props;
  const inititalFilterValues = {
    dateRange: {
      start: null,
      end: null,
      field: "pickups.arrival"
    },
    pickup_location: null,
    dropoff_location: null,
    hauler: null,
    service_type: "All",
    status: "All",
    vehicle_id: null,
    clear: false,
    dispatch_type: "All"
  };

  const formStyles = useFormStyles();
  const debounceTimer = 1500;
  const [pickupKeyword, setPickupKeyword] = useDebounce("", debounceTimer);
  const [dropoffKeyword, setDropoffKeyword] = useDebounce("", debounceTimer);
  const [vtypeFilter, setVtypeFilter] = useDebounce("", debounceTimer);
  const [error, setError] = useState({
    start: null,
    end: null
  });

  const [filterValues, setFilterValues] = useState(inititalFilterValues);

  const { data: vtypes } = useQuery(GET_VEHICLE_TYPE_LIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      ...(vtypeFilter ? { keyword: vtypeFilter } : {}),
      group_ids: [
        user.group_ids[
          _.findIndex(user.group_names, gn => {
            return gn === user.client.name;
          })
        ]
      ]
    }
  });

  const { data: pickupLoc } = useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(pickupKeyword ? { keyword: pickupKeyword } : {}),
      category: "pickup"
    }
  });

  const { data: dropoffLoc } = useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(dropoffKeyword ? { keyword: dropoffKeyword } : {}),
      category: "dropoff"
    }
  });

  const handleValidate = () => {
    if (
      filterValues.dateRange.start !== null &&
      filterValues.dateRange.end === null
    ) {
      setError({
        end: <Helper message="To Date should have a value" />
      });
    } else if (
      filterValues.dateRange.start === null &&
      filterValues.dateRange.end !== null
    ) {
      setError({
        start: <Helper message="From Date should have a value" />
      });
    } else {
      handleSetFilters(filterValues);
      setError({
        start: null,
        end: null
      });
    }
  };

  return (
    <Drawer
      open={filterDrawer}
      onClose={closeFilterDrawer}
      className={classes.drawer}
    >
      <AppBar position="static">
        <Toolbar>
          <FilterList style={{ color: "white", marginRight: "16px" }} />
          <Typography
            variant="h6"
            color="inherit"
            style={{ color: "white", flexGrow: 1 }}
          >
            Filter
          </Typography>
          <IconButton style={{ color: "white" }} onClick={closeFilterDrawer}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className={classes.container}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ margin: "15px 0 15px 0" }}
        >
          <Select
            style={{
              marginRight: "",
              fontSize: "12px",
              color: "black"
            }}
            value={tempFilter?.dateRange?.field}
            onChange={e => {
              setTempFilter({
                ...tempFilter,
                dateRange: {
                  ...tempFilter.dateRange,
                  field: e.target.value
                }
              });
            }}
          >
            <MenuItem value="pickup_date">Pickup Date</MenuItem>
            <MenuItem value="allocation_date">Delivery Date</MenuItem>
          </Select>
          <Button
            size="small"
            color="default"
            style={{ float: "right" }}
            onClick={() => {
              handleClearFilter();
            }}
          >
            <span
              style={{
                fontSize: "12px",
                textTransform: "capitalize",
                color: "black"
              }}
            >
              Clear All
            </span>
          </Button>
        </Grid>

        <div style={{ width: "100%", textAlign: "center", minHeight: "85%" }}>
          <div style={{ width: "100%" }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                fullWidth
                className={classes.filterInput}
                format="MM/DD/YYYY HH:mm"
                id="date-from"
                name="date_from"
                value={tempFilter.dateRange.start}
                initialFocusedDate={moment().startOf("date")}
                label="From"
                placeholder="From"
                showTodayButton
                todayLabel="NOW"
                // helperText={error.start}
                onChange={date => {
                  if (date) {
                    const { end } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        start: date.format("YYYY-MM-DD HH:mm:ss")
                      }
                    });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  placeholder: "From",
                  style: { color: "black", fontWeight: "bold" }
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                fullWidth
                className={classes.filterInput}
                id="date-to"
                name="date_to"
                format="MM/DD/YYYY HH:mm"
                value={tempFilter.dateRange.end}
                initialFocusedDate={moment().endOf("date")}
                label="To"
                placeholder="To"
                showTodayButton
                todayLabel="NOW"
                minDate={tempFilter?.dateRange?.start || ""}
                // helperText={error.end}
                onChange={date => {
                  if (date) {
                    const { start } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        end: date.format("YYYY-MM-DD HH:mm:ss")
                      }
                    });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  placeholder: "From",
                  style: { color: "black", fontWeight: "bold" }
                }}
              />
            </MuiPickersUtilsProvider>
            <TextField
              fullWidth
              id="status-filter"
              name="status"
              className={classes.filterInput}
              value={tempFilter.status.value}
              select
              label="Status"
              InputProps={{ style: { fontSize: "12px" } }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: 1
                }
              }}
              onChange={e => setTempFilter({ ...tempFilter, status: e.target })}
            >
              <MenuItem key="0" value="0">
                All
              </MenuItem>
              {[
                { value: process.env.REACT_APP_STATUS_PENDING_ID, label: "Pending" },
                { value: process.env.REACT_APP_STATUS_DECLINED_ID, label: "Declined" },
                { value: process.env.REACT_APP_STATUS_ASSIGNED_ID, label: "Assigned" },
                { value: process.env.REACT_APP_STATUS_CANCEL_ID, label: "Cancelled" },
                { value: process.env.REACT_APP_STATUS_EXPIRED_ID, label: "Expired" },
              ].map(opt => (
                <MenuItem value={opt.value} key={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              fullWidth
              disableClearable
              className={classes.filterInput}
              options={
                vtypes
                  ? [
                      { id: "all", name: "All" },
                      ...vtypes.get_vehicle_types.vehicle_types
                    ]
                  : []
              }
              getOptionSelected={(o, v) => o.id === v.id}
              getOptionLabel={option => option.name}
              renderOption={option => option.name}
              style={{ width: "100%" }}
              value={tempFilter.vehicle}
              onChange={(e, nv) =>
                setTempFilter({ ...tempFilter, vehicle: nv })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Vehicle Type"
                  onChange={e => setVtypeFilter(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold",
                      letterSpacing: 1
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              disableClearable
              className={classes.filterInput}
              options={
                pickupLoc
                  ? [
                      { id: "all", name: "All" },
                      ...pickupLoc.get_booking_geofences
                    ]
                  : []
              }
              getOptionSelected={(o, v) => o.id === v.id}
              getOptionLabel={option => option.name}
              renderOption={option => option.name}
              style={{ width: "100%" }}
              value={tempFilter.pickup}
              onChange={(e, nv) => setTempFilter({ ...tempFilter, pickup: nv })}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Pick Up Location"
                  onChange={e => setPickupKeyword(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold",
                      letterSpacing: 1
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              disableClearable
              className={classes.filterInput}
              options={
                dropoffLoc
                  ? [
                      { id: "all", name: "All" },
                      ...dropoffLoc.get_booking_geofences
                    ]
                  : []
              }
              getOptionSelected={(o, v) => o.id === v.id}
              getOptionLabel={option => option.name}
              renderOption={option => option.name}
              style={{ width: "100%" }}
              value={tempFilter.dropoff}
              onChange={(e, nv) =>
                setTempFilter({ ...tempFilter, dropoff: nv })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Delivery Location"
                  onChange={e => setDropoffKeyword(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold",
                      letterSpacing: 1
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            right: 0,
            bottom: "0px",
            marginTop: 10
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSetFilters}
            style={{
              borderRadius: 100,
              width: "85%",
              color: "white"
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Filter;
