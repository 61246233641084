import React, { useContext, useEffect, useState } from "react";
import Header from "../../utils/UI/Table/Header";
import Table from "../../utils/UI/Table/CustomTable";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  Badge,
  Tooltip
} from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons/";
import { useHistory } from "react-router-dom";
import Filter from "./Utils/Filter";
import moment from "moment";
import AllocationVehicleAssign from "./Utils/AllocationVehicleAssign";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_TRIP_ALLOCATION_BOOKING,
  GET_VEHICLE_ALLOCATIONS
} from "../../graphql/Queries";
import Loading from "../../utils/Loading";
import { EDIT_VEHICLE_ALLOCATION } from "../../graphql/Mutations";
import Swal from "sweetalert2";
import ConfirmationDialog from "../../utils/modals/ConfirmationDialog";
import SearchContext from "../../context/SearchContext";
import AddLogs from "../../utils/functions/AddLogs";
import AccessControl from "../../utils/AccessControl";
import useUserContext from "../../context/User/useUserContext";
import _ from "lodash";
import { TableWrapper } from "./TripAllocation/TripAllocation.styles";
const Highlight = require("react-highlighter");

const HeadersTable = [
  { id: "status", label: "", isSortable: false },
  { id: "trip_number", label: "Trip Number", isSortable: true },
  { id: "pickup.name", label: "Pickup Location", isSortable: true },
  { id: "pickup_date", label: "Pickup Date", isSortable: true },
  { id: "dropoff.name", label: "Drop Off Location", isSortable: true },
  { id: "delivery_date", label: "Delivery Date", isSortable: true },
  { id: "vehicle_types.name", label: "Vehicle Type", isSortable: true },
  {
    id: "confirmation_duedate",
    label: "Confirmation Due Date",
    isSortable: true
  },
  { id: "plateno", label: "Plate Number", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false }
];

const HEADER_LABEL = "Vehicle Allocation";
const INITIAL_PAGE = { rows: 10, currentPage: 0 };
const INITIAL_FIELD = "allocation.id";
const INITIAL_DIRECTION = "asc";
const INITIAL_ORDER = { field: INITIAL_FIELD, direction: INITIAL_DIRECTION };
const INITIAL_DATERANGE = {
  field: "allocation_date",
  start: moment()
    .subtract(1, "w")
    .format("YYYY-MM-DD"),
  end: moment()
    .add(1, "w")
    .format("YYYY-MM-DD")
};

export default function VehicleAllocations() {
  const classes = useStyles();
  const user = useUserContext();
  const contextData = useContext(SearchContext);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [vehicleModal, setVehicleModal] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const history = useHistory();
  const savedFilter = history?.location?.state?.params?.vars;
  const isFromNotif = history?.location?.state?.params?.isFromNotif;
  const searchedTripNumber = savedFilter?.conditions?.find(
    cond => cond.field === "trip_number"
  )?.value;
  const initKeyword =
    (isFromNotif && searchedTripNumber) ? searchedTripNumber : "";
  const [keyword, setKeyword] = useState(initKeyword);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [declineModal, setDeclineModal] = useState(false);
  const [clearFirst, setClearFirst] = useState(false);
  const [editPermission, setEditPermission] = useState(false);

  // filter states
  const [tempFilter, setTempFilter] = useState({
    dateRange: { start: null, end: null, field: "pickup_date" },
    status: { value: "0", label: "All" },
    vehicle: { id: "all", name: "All" },
    pickup: { id: "all", name: "All" },
    dropoff: { id: "all", name: "All" }
  });
  const [filter, setFilter] = useState([]);

  // state for Actions column to trigger option buttons
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // initial value of variables in query
  const getQueryConditions = () => {
    const removeDefaultDateFilter = 
      isFromNotif &&
      searchedTripNumber &&
      searchedTripNumber === keyword;
    let filter_val = {
      filter: [
        {
          field: "allocation.group_id",
          value: [
            user.group_ids[
              _.findIndex(user.group_names, gn => {
                return gn === user.client.name;
              })
            ].toString()
          ]
        }
      ],
      conditions: [],
      ...(!removeDefaultDateFilter && { dateFilter: INITIAL_DATERANGE }),
      keyword: keyword,
      orderBy: [order],
      first: page.rows,
      skip: page.currentPage * rowsPerPage
    };

    // setting up the field and value for query variables
    if (filter?.length > 0) {
      filter.forEach(el => {
        switch (el.field) {
          case "dateRange":
            {
              filter_val.dateFilter = { ...el.value };
            }
            break;
          case "status":
            {
              filter_val.conditions.push({
                field: "allocation.status_code_id",
                value: el.value.toString()
              });
            }
            break;
          case "vehicle":
            {
              filter_val.conditions.push({
                field: "vehicle_types.name",
                value: el.value.toString()
              });
            }
            break;
          case "pickup":
            {
              filter_val.conditions.push({
                field: "pickup.name",
                value: el.value.toString()
              });
            }
            break;
          case "dropoff":
            {
              filter_val.conditions.push({
                field: "dropoff.name",
                value: el.value.toString()
              });
            }
            break;
          default:
            break;
        }
      });
    }
    return filter_val;
  };

  const [bookingsAllocation] = useLazyQuery(GET_TRIP_ALLOCATION_BOOKING, {
    onCompleted(bookings) {
      // add multiple dropoff value in alloc table data
      const updatedVehicleData = vehicleData.map(vh => {
        const bookingInfo = bookings.get_bookings_per_trip.bookings.find(
          book => book.key === vh.trip_number
        );
        return {
          ...vh,
          dropoffs: bookingInfo?.value.map(val => val.dropoffs[0].name),
          delivery_dates: bookingInfo?.value.map(
            val => val.dropoffs[0].arrival
          ),
          pickup_date: bookingInfo?.value[0].pickups[0].arrival,
          pickup_location_name: bookingInfo?.value[0].pickups[0].name
        };
      });
      setVehicleData(updatedVehicleData);
    }
  });

  // query allocations table
  const { data: allocData, loading, refetch } = useQuery(
    GET_VEHICLE_ALLOCATIONS,
    {
      variables: getQueryConditions(),
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        const dataAlloc = data.get_allocations.allocations.map(val => {
          return val.trip_number;
        });
        setVehicleData(data.get_allocations.allocations);
        bookingsAllocation({
          variables: {
            first: 50,
            trip_numbers: dataAlloc
          }
        });
      }
    }
  );

  // mutation for allocatons
  const [editAllocation] = useMutation(EDIT_VEHICLE_ALLOCATION);

  // label and function for actions
  const options = [
    { label: "Assign Vehicle", fn: e => openVehicleModal() },
    { label: "Decline", fn: e => openDeclineVehicleModal() }
  ];

  //  tirgger the delcine modal
  const openDeclineVehicleModal = () => {
    setDeclineModal(true);
    setAnchorEl(null);
  };

  // trigger to close the modal
  const closeDeclineVehicleModal = () => {
    setDeclineModal(false);
  };

  // state to close drawer
  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  // mutation for decline modal
  const declineFunction = () => {
    editAllocation({
      variables: {
        id: selectedData.id,
        allocation: {
          vehicle_id: null,
          // vehicle_type: null,
          status_code_id: Number(process.env.REACT_APP_STATUS_DECLINED_ID)
        }
      },
      onCompleted(data) {
        if (data.edit_allocation.success) {
          Swal.fire({
            title: "The trip has been declined.",
            icon: "error",
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
            onClose: () => {}
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              setSelectedData();
              handleClearFilter();
              refetch();
            }
          });
        }
        AddLogs(process.env.REACT_APP_VEHICLE_ALLOCATION, "decline", [
          selectedData.trip_number,
          selectedData.client_name
        ]);
      }
    });
  };

  //setting values from drawer
  const handleSetFilters = () => {
    const temp = [];
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        switch (key) {
          case "dateRange":
            {
              if (
                tempFilter.dateRange.start !== null ||
                tempFilter.dateRange.end !== null
              ) {
                temp.push({
                  field: "dateRange",
                  value: {
                    field: tempFilter.dateRange.field,
                    start: tempFilter.dateRange.start,
                    end: tempFilter.dateRange.end
                  }
                });
              }
            }
            break;
          case "status":
            {
              if (tempFilter.status.value !== "0") {
                temp.push({
                  field: "status",
                  value: tempFilter.status.value
                });
              }
            }
            break;
          case "vehicle":
            {
              if (tempFilter.vehicle.id !== "all") {
                temp.push({
                  field: "vehicle",
                  value: tempFilter.vehicle.name
                });
              }
            }
            break;
          case "pickup":
            {
              if (tempFilter.pickup.id !== "all") {
                temp.push({
                  field: "pickup",
                  value: tempFilter.pickup.name
                });
              }
            }
            break;
          case "dropoff":
            {
              if (tempFilter.dropoff.id !== "all") {
                temp.push({
                  field: "dropoff",
                  value: tempFilter.dropoff.name
                });
              }
            }
            break;
          default:
            break;
        }
        return temp;
      });
    setPage({ rows: 10, currentPage: 0 });
    setFilter(temp);
    setFilterDrawer(false);
  };

  //reset the values of filter
  const handleClearFilter = () => {
    setTempFilter({
      dateRange: { start: null, end: null, field: "pickup_date" },
      status: { value: "0", label: "All" },
      vehicle: { id: "all", name: "All" },
      pickup: { id: "all", name: "All" },
      dropoff: { id: "all", name: "All" }
    });
    setSelectedData();
    setPage({ rows: 10, currentPage: 0 });
    setFilter([]);
    setFilterDrawer(false);
  };

  // trigger actions modal
  const openVehicleModal = () => {
    setAnchorEl(null);
    setVehicleModal(true);
  };

  // trigger actions modal
  const closeVehicleModal = () => {
    setAnchorEl(null);
    setVehicleModal(false);
  };

  // menu for actions button
  const menuActions = (
    <Menu
      keepMounted
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        style: {
          width: "20ch",
          boxShadow: "0px 6px 16px -2px rgba(140,140,140,0.75)"
        }
      }}
    >
      {options.map((opt, index) => (
        <MenuItem key={index} onClick={opt.fn} style={{ display: "flex" }}>
          <Typography>{opt.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  // for search textfield
  useEffect(() => {
    // if (!searchKeyword.moduleName) {
    //   //to not clear the filter when searching
    //   setSearchKeyword({ ...searchKeyword, value: "" });
    // }
    setSearchKeyword({
      ...searchKeyword,
      value: initKeyword,
      submit: false
    });
    setClearFirst(true);
  }, []);

  // for search textfield
  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(INITIAL_PAGE);
      if (isFromNotif) {
        history.push({
          ...history?.location,
          state: {
            params: {
              ...history?.location?.state?.params,
              isFromNotif: false
            }
          }
        });
      }
    }
  }, [searchKeyword, clearFirst]);

  if (loading) return <Loading />;

  return (
    <AccessControl
      process="tables"
      setCanDelete={setEditPermission}
      resource="/allocation/vehicle/"
    >
      <>
        <Header
          label={HEADER_LABEL}
          totalPage={Math.ceil(allocData?.get_allocations.total / page.rows)}
          page={page}
          setPage={setPage}
          hasFilter
          hasTime
          setFilterDrawer={setFilterDrawer}
          setSelectedData={setSelectedData}
          getVariables={getQueryConditions}
          isFilterDate={
            getQueryConditions().dateFilter?.start ===
              tempFilter.dateRange?.start ||
            getQueryConditions().dateFilter?.end === tempFilter.dateRange?.end
          }
        />
        <TableWrapper>
          <Table
            headers={HeadersTable}
            order={order}
            setOrder={setOrder}
            data={vehicleData}
          >
            {allocData?.get_allocations?.count ? (
              <>
                {vehicleData.map(data => (
                  <TableRow className={classes.table_data_container}>
                    <TableCell className={classes.tableColumn}>
                      <Tooltip
                        title={
                          data.status_code === "Assigned"
                            ? "Assigned"
                            : data.status_code === "Declined"
                            ? "Rejected"
                            : data.status_code === "Expired"
                            ? "Expired"
                            : data.status_code === "Cancelled"
                            ? "Cancelled"
                            : data.status_code === "Pending"
                            ? "Pending"
                            : ""
                        }
                      >
                        <Badge
                          variant="dot"
                          classes={{
                            badge:
                              data.status_code &&
                              data.status_code === "Declined"
                                ? classes.customBadgeDeclined
                                : data.status_code === "Assigned"
                                ? classes.customBadgeAssigned
                                : data.status_code === "Pending"
                                ? classes.customBadgePending
                                : data.status_code === "Expired"
                                ? classes.customBadgeExpired
                                : data.status_code === "Cancelled"
                                ? classes.customBadgeCancelled
                                : data.status_code === "Declined"
                                ? classes.customBadgeDeclined
                                : ""
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Highlight search={searchKeyword.value}>
                        {data.trip_number}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={searchKeyword.value}>
                        {data.pickup_location_name}
                      </Highlight>
                    </TableCell>
                    <TableCell>{data.pickup_date}</TableCell>
                    <TableCell style={{ whiteSpace: "pre" }}>
                      <Highlight search={searchKeyword.value}>
                        {data?.dropoffs
                          ? data.dropoffs.join("\r\n")
                          : data.dropoff_location_name}
                      </Highlight>
                    </TableCell>
                    <TableCell style={{ whiteSpace: "pre" }}>
                      {data.delivery_dates
                        ? data.delivery_dates.join("\r\n")
                        : data.delivery_date}
                    </TableCell>
                    <TableCell>
                      <Highlight search={searchKeyword.value}>
                        {data.vehicle_type}
                      </Highlight>
                    </TableCell>
                    <TableCell>{data.confirmation_duedate}</TableCell>
                    <TableCell>
                      <Highlight search={searchKeyword.value}>
                        {data.plateno ? data.plateno : "-"}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        disabled={
                          !editPermission ||
                          user.user_level_id ===
                            process.env.REACT_APP_WTI_ADMIN ||
                          data.status_code_id !=
                            process.env.REACT_APP_STATUS_PENDING_ID
                        }
                        onClick={e => {
                          setAnchorEl(e.currentTarget);
                          setSelectedData(data);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow style={{ backgroundColor: "white", height: 60 * 2 }}>
                <TableCell colSpan={10} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableWrapper>
        {menuActions}
        {filterDrawer && (
          <Filter
            classes={classes}
            filterDrawer={filterDrawer}
            moduleName={process.env.REACT_APP_VEHICLE_ALLOCATION}
            closeFilterDrawer={closeFilterDrawer}
            handleSetFilters={handleSetFilters}
            handleClearFilter={() => handleClearFilter()}
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
          />
        )}
        <AllocationVehicleAssign
          toggle={vehicleModal}
          moduleName={process.env.REACT_APP_VEHICLE_ALLOCATION}
          selectedData={selectedData}
          editAllocation={editAllocation}
          refetch={() => refetch()}
          closeVehicleModal={closeVehicleModal}
          handleClearFilter={() => handleClearFilter()}
        />

        <ConfirmationDialog
          toggle={declineModal}
          title="Decline Trip Request"
          content="Are you sure you want to decline this trip?"
          fn={() => declineFunction()}
          close={() => closeDeclineVehicleModal()}
        />
      </>
    </AccessControl>
  );
}
const drawerWidth = 325;
const useStyles = makeStyles(theme => ({
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  table_cell_value: {
    color: theme.palette.primary.dark,
    fontSize: 12
  },
  timesheet_icon: {
    color: theme.palette.primary.main
  },
  tableColumn: {
    padding: "12px 12px 12px 20px"
  },
  container: {
    width: "auto",
    position: "relative",
    minHeight: "calc(100% - 64px)",
    padding: theme.spacing(2),
    textAlign: "center",
    boxSizing: "border-box",
    overflowY: "auto"
  },
  root: {
    width: "100%"
  },
  paper: {
    //   width: "100%",
    //   marginBottom: theme.spacing(2),
    //   paddingTop: 64
    width: "100%",
    marginBottom: theme.spacing(20),
    borderRadius: 0,
    boxShadow: "none"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      // minWidth: drawerWidth,
      // width: drawerWidth
      minWidth: "23%",
      width: "23%"
    }
  },
  filterInput: {
    marginBottom: "20px",
    minWidth: "250px",
    // width: "250px",
    textAlign: "left",
    "& input": {
      fontSize: "12px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "12px",
      padding: "12px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "12px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "rotate(0) !important"
    }
  },
  customBadgeDeclined: {
    backgroundColor: "red",
    color: "white"
  },
  customBadgeAssigned: {
    backgroundColor: "#EDD608",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#D1D1D1",
    color: "white"
  },
  customBadgeCancelled: {
    backgroundColor: "#808080",
    color: "white"
  },
  customBadgeExpired: {
    backgroundColor: "#FF845E",
    color: "white"
  },
}));
