/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  Button,
  Toolbar,
  InputLabel,
  Tooltip,
  Switch,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  TableSortLabel
  // ButtonGroup,
  // Box
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  HelpRounded,
  Add,
  Remove
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import { Marker } from "@react-google-maps/api";
import Swal from "sweetalert2";
import useFormStyles from "../../../../../styles/useFormStyles";
import { GET_VEHICLE_INFOS } from "../../../../../graphql/Queries";
import { ADD_CONTROL_ROOM } from "../../../../../graphql/Mutations";
import redirectToTable from "../../../../../utils/redirect";
import AddLogs from "../../../../../utils/functions/AddLogs";
// import { vehicleSvgIcons } from "../../../../Utils";
import Draw from "../../../../../utils/Map/DrawingManager/DrawingManager";
import DrawingModeIcon from "../../../../../utils/Map/Icons/DrawingModeIcon.icon";

import Header from "../../../Header";
import AccessControl from "../../../../../utils/AccessControl";
import validate from "../../../../../utils/validation";
import Map from "../../../../../utils/Map/index";
import {
  control_positions,
  mapRef,
  getGeoPaths,
  center as DEFAULT_CENTER
} from "../../../../../utils/Map/Inner";
import useAddControlRoomStyles from "./AddControlRoom.styles";
import Search from "../../../../../utils/Map/Search/Search";
// import SelectionTool from "../../../../../utils/Map/DrawingCotroller/SelectionTool";
import SelectGroup from "../../../../../utils/modals/SelectGroup";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import Clusterer from "../../../../../utils/Map/Clusterer/Clusterer";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../../../assets/SvgIcons/SvgIcons";
import Vehicles from "../Vehicles/Vehicles";
import BlinkingAlertsLegend from "../../../../../utils/BlinkingAlertsLegend";
import useUserContext from "../../../../../context/User/useUserContext";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

// const Vehicles = () => {
//   const { data, loading } = useQuery(GET_VEHICLE_INFOS, {
//     variables: {
//       first: 10000
//     }
//   });

//   if (loading) return null;
//   return (
//     <Clusterer>
//       {clusterer => {
//         return data?.get_vehicles?.vehicles.map(vehicle => {
//           if (vehicle?.device_info?.device_status) {
//             const { latitude, longitude, powersource } = vehicle.device_info.device_status;
//             if (latitude && longitude) {
//               const position = { lat: latitude, lng: longitude };
//               const {
//                 id,
//                 icon,
//                 ignition_status,
//                 device_info: {
//                   device_status: { heading } // eslint-disable-line no-unused-vars
//                 }
//               } = vehicle;
//               const Icon = SvgIcons[
//                 icon.split(".")[0] ? icon.split(".")[0] : "default_icon"
//               ]
//               return (
//                 <Marker
//                   options={{
//                     zIndex: 2147483647
//                   }}
//                   // icon={{
//                   //   path: vehicleSvgIcons[icon.split(".")[0]].path,
//                   //   fillColor: vehicleSvgIcons[icon.split(".")[0]].color,
//                   //   fillOpacity: 0.8,
//                   //   rotation: heading
//                   // }}
//                   icon={{
//                     path:
//                       Icon.path,
//                     fillColor: fillColor[powersource], // Icon.fill,
//                     fillOpacity: 1,
//                     strokeWeight: 1.5,
//                     strokeColor: strokeColor[ignition_status], // Icon.stroke,
//                     scale: 1.2
//                   }}
//                   key={id}
//                   position={position}
//                   clusterer={clusterer}
//                 />
//               );
//             }
//           }
//         });
//       }}
//     </Clusterer>
//   );
// };

const DEFAULT_ZOOM = 12;

function AddControlRoom() {
  const classes = useAddControlRoomStyles();
  const formStyles = useFormStyles();
  const user_cotext = useUserContext();
  const groupIds = user_cotext?.client?.group_ids || [];
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("asc");
  const [dialog, setDialog] = useState(false);
  const [groupModal, toggleGroupModal] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [render, setRender] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const history = useHistory();
  // const payload = history?.location?.params?.payload;

  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false); // eslint-disable-line no-unused-vars
  const [dataView, setDataView] = useState(false);
  const [bound, setBounds] = useState([]); // eslint-disable-line no-unused-vars
  const [center, setCenter] = useState({
    lat: 12.248727944234455,
    lon: 121.95099687500002
  });
  const [drawing, setDrawing] = useState(false);
  const [state, setState] = useState({
    clientName: "",
    zoom: DEFAULT_ZOOM
  });
  const [initialState] = useState(_.cloneDeep(state));
  const [errors, setErrors] = useState({
    clientName: "",
    zoom: "",
    lat: "",
    lon: ""
  });

  const [controlRoom] = useMutation(ADD_CONTROL_ROOM);

  const handleAddControlRoom = e => {
    e.preventDefault();
    const { clientName, zoom } = state;
    if (clientName === "") {
      setErrors({
        ...errors,
        clientName: "This field is required"
      });
    } else {
      controlRoom({
        variables: {
          data: {
            name: clientName,
            zoom_level: +zoom,
            data_view: dataView,
            location: {
              lat: parseCoordinate(center.lat),
              lon: parseCoordinate(center.lon)
            },
            group_ids: groupList.map(grp => +grp.id)
          }
        }
      }).then(response => {
        const { error, success } = response.data.add_control_room;
        if (success) {
          AddLogs(
            process.env.REACT_APP_CONTROL_ROOM_MODULE,
            "insert",
            state.clientName
          );
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
            onClose: () => {
              history.push({
                pathname: `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/`
              });
              // redirectToTable(
              //   history,
              //   process.env.REACT_APP_CONTROL_ROOM_MODULE
              // );
            }
          });
        } else if (error.length > 0) {
          //   Swal.fire({
          //     title: error[0].message,
          //     icon: "error",
          //     showConfirmButton: false,
          //     timer: 2500
          //   });
          const tempObj = {};
          error.map(err =>
            Object.assign(tempObj, { [err.field]: err.message })
          );
          setErrors({
            ...errors,
            clientName: tempObj.name
          });
        }
      });
    }
  };

  function openGroupModal() {
    toggleGroupModal(true);
  }

  function closeGroupModal() {
    toggleGroupModal(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleGroupList = array => {
    setGroupList(array);
    setErrors({ ...errors, groupList: "" });
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleRemoveGroup = grp_id => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(grp_id);
    temp.splice(index, 1);
    setGroupList(temp);
    setRender(!render);
  };

  const handleMultipleDeletePartner = () => {
    let temp = [...groupList];
    temp = temp.filter(item => !selected.includes(item.id));
    setGroupList([...temp]);
    setSelected([]);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < groupList.length) {
        const displayedData = groupList.map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const onSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = groupList.map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      groupList.map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleToggle = e => {
    setDataView(e.target.checked);
  };

  const validateZoomLevel = n => {
    // if (n < min_zoom) return min_zoom;
    // if (n > max_zoom) return max_zoom;
    return n;
  };

  const handleBlur = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let error;
    switch (name) {
      case "clientName":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("geofence", value.trim())) {
          error = `The following characters are not allowed: & _ + { } [ ] | \ ; " < > ' ? /`;
        } else {
          error = "";
        }
        break;
      case "zoom":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Numeric only";
        } else if (+value < 1 || +value > 22) {
          error = "Zoom level value min: 1, max: 22";
        } else {
          error = "";
        }
        // if (error === "" && value !== "") {
        //   mapRef.current.setZoom(+value);
        // }
        break;
      case "lat":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("coords", value)) {
          error = "Numeric only";
        } else {
          error = "";
        }
        if (error === "" && value !== "")
          mapRef.current.setCenter({ lat: +value, lon: state.longitude });
        break;
      case "lon":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("coords", value)) {
          error = "Numeric only";
        } else {
          error = "";
        }
        if (error === "" && value !== "")
          mapRef.current.setCenter({ lat: center.latitude, lon: +value });
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setCenter({
      ...center,
      [name]: value
    });
  };

  const zoomRef = useRef(1);
  const handleChange = event => {
    const { name, value } = event.target;
    let timeout = null;
    const debounce = 700;
    switch (name) {
      case "zoom":
        if (value !== "") {
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(() => {
            if (+zoomRef.current.value >= 1 && +zoomRef.current.value <= 22) {
              mapRef.current.setZoom(+zoomRef.current.value);
            }
          }, debounce);
        }
        break;
      case "lat":
        if (value !== "" && validate("coords", value))
          mapRef.current.setCenter({ lat: +value, lon: center.lon });
        break;
      case "lon":
        if (value !== "" && validate("coords", value))
          mapRef.current.setCenter({ lat: center.lat, lon: +value });
        break;
      default:
        break;
    }
    setState({
      ...state,
      [name]: value
    });
    setCenter({
      ...center,
      [name]: value
    });
  };

  const drawingManagerRef = React.useRef();
  const setCurrentBounds = () => {
    const tempBounds = getGeoPaths();
    const boundingBox = tempBounds.map(coords => {
      return `${coords.lat} ${coords.lon}`;
    });
    setBounds(boundingBox);
  };
  const handleDrawingCompleted = d => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }

    drawingManagerRef.current = d;
    mapRef.current.fitBounds(d.getBounds());
    setCurrentBounds();
    d.setMap(null);
  };

  React.useEffect(() => {
    if (!drawing) {
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setMap(null);
      }
      setBounds(null);
      setCenter({
        lat: DEFAULT_CENTER.lat,
        lon: DEFAULT_CENTER.lng
      });
      setState({
        ...state,
        zoom: validateZoomLevel(DEFAULT_ZOOM)
      });
    }
  }, [drawing]);

  const memoizedCenter = React.useMemo(() => {
    return {
      lat: center.lat,
      lng: center.lon
    };
  }, [center?.lat, center?.lon]);

  const getCurrentCenter = () =>
    mapRef.current?.getCenter?.() || DEFAULT_CENTER;
  const handleIdle = () => {
    if (!mapRef.current) return;
    const { lat, lng } = getCurrentCenter();
    setCenter({
      lat: typeof lat === "function" ? lat() : lat,
      lon: typeof lng === "function" ? lng() : lng
    });
    setState({
      ...state,
      zoom: validateZoomLevel(mapRef.current.getZoom())
    });
  };

  useEffect(() => {
    if (history?.location?.params?.payload) {
      setCenter({
        lat: history?.location?.params?.payload.center.lat,
        lon: history?.location?.params?.payload.center.lng
      });
      setState({
        ...state,
        zoom: history?.location?.params?.payload?.zoom
      });

      setState({
        ...state,
        zoom: validateZoomLevel(history?.location?.params?.payload?.zoom)
      });
    }
  }, [history?.location?.params?.payload]);

  useEffect(() => {
    if (state.zoom < 1 || state.zoom > 22) {
      setErrors({ ...errors, zoom: "Zoom level value min: 1, max: 22" });
    } else {
      setErrors({ ...errors, zoom: "" });
    }
  }, [state.zoom]);

  const options = React.useMemo(
    () => ({
      zoomControl: false,
      gestureHandling: "greedy"
    }),
    []
  );

  const parseCoordinate = (n = 0) => {
    if (n === "") return n;
    if (isNaN(n)) return 0;
    return +(n.toString().match(/^\d+.\d{6}/) || [(+(n || 0)).toFixed(6)])[0];
  };

  return (
    <AccessControl
      process="add"
      resource={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/add`}
    >
      <div className={classes.wrapper}>
        <Header
          process="Add"
          locationState={{
            source: `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/`
          }}
          moduleName={process.env.REACT_APP_CONTROL_ROOM_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={_.isEqual(initialState, state)}
        />
        <div className={classes.container_body}>
          <div className={classes.map_body}>
            <div className={classes.map_container}>
              <Map
                defaultZoom={state.zoom}
                zoom={state.zoom}
                onIdle={handleIdle}
                center={memoizedCenter}
                options={options}
              >
                <Vehicles groupIds={groupIds} />
                <Map.Control position={control_positions.LEFT_TOP}>
                  <Search
                    onCenterChanged={newCenter => {
                      const lat = newCenter.lat();
                      const lon = newCenter.lng();
                      setCenter({ lat, lon });
                    }}
                  />
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <BlinkingAlertsLegend
                    showAlerts
                    showOutlines
                    btnSize="small"
                    margin={15}
                    placement="left"
                  />
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <div
                    // orientation="vertical"
                    // aria-label="vertical contained primary button group"
                    // variant="text"
                    className={classes.zoom_control_btn_grp}
                  >
                    <IconButton
                      size="small"
                      className={classes.zoom_add_btn}
                      onClick={() => {
                        mapRef.current.setZoom(
                          // mapRef.current.zoom + 1
                          validateZoomLevel(mapRef.current.zoom + 1)
                        );
                      }}
                    >
                      <Add size="small" />
                    </IconButton>
                    <div
                      style={{
                        backgroundColor: "rgb(230, 230, 230)",
                        height: "1px"
                      }}
                    />
                    <IconButton
                      size="small"
                      className={classes.zoom_minus_btn}
                      onClick={() => {
                        mapRef.current.setZoom(
                          // mapRef.current.zoom - 1
                          validateZoomLevel(mapRef.current.zoom - 1)
                        );
                      }}
                    >
                      <Remove size="small" />
                    </IconButton>
                  </div>
                </Map.Control>
                <Draw
                  active={drawing}
                  drawingCompled={handleDrawingCompleted}
                />
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <Button
                    className={classes.drawing_mode_btn}
                    onClick={() => {
                      setDrawing(prev => {
                        return !prev;
                      });
                    }}
                  >
                    <DrawingModeIcon active={drawing} />
                  </Button>
                </Map.Control>
              </Map>
            </div>
            <div className={classes.form_container}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: 24, marginLeft: 20 }}
                  >
                    All fields with <span style={{ color: "red" }}>*</span> are
                    required
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="clientName"
                    label="Name"
                    placeholder="Enter Name"
                    className={classes.field}
                    onChange={e => handleChange(e)}
                    onBlur={e => handleBlur(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    type="number"
                    name="zoom"
                    label="Zoom Level"
                    value={state.zoom}
                    inputRef={zoomRef}
                    className={classes.field}
                    onChange={e => handleChange(e)}
                    onBlur={e => handleBlur(e)}
                    InputProps={{ inputProps: { min: 1, max: 22 } }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {errors.clientName && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.clientName}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {errors.zoom && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.zoom}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="lat"
                    label="Latitude"
                    placeholder="Enter Latitude"
                    value={parseCoordinate(center?.lat)}
                    className={classes.field}
                    onChange={e => handleChange(e)}
                    onBlur={e => handleBlur(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="lon"
                    label="Longitude"
                    placeholder="Enter Longitude"
                    value={parseCoordinate(center?.lon)}
                    className={classes.field}
                    onChange={e => handleChange(e)}
                    onBlur={e => handleBlur(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {errors.lat && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.lat}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {errors.lon && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.lon}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.switch}>
                    <Tooltip
                      classes={{ tooltip: formStyles.toolTip }}
                      title="Turn on: Shows the count of vehicle"
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    <Typography color="inherit" className={classes.mediumTitle}>
                      &nbsp;Data view&nbsp;
                    </Typography>
                    <AntSwitch
                      color="primary"
                      name="data_view"
                      checked={dataView}
                      onChange={handleToggle}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.partners}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Tooltip
                        classes={{ tooltip: formStyles.toolTip }}
                        title="Data of primary client will be linked to all selected partners(and vise versa)"
                        placement="top-start"
                      >
                        <HelpRounded className={classes.helpIcon} />
                      </Tooltip>
                      <InputLabel classes={{ root: classes.mediumTitle }}>
                        Partners
                      </InputLabel>
                    </div>
                    <Paper component="form" className={classes.searchRoot}>
                      <InputBase
                        className={classes.searchInput}
                        placeholder="Search Client"
                        // inputProps={{ "aria-label": "plate #" }}
                        onChange={e => setKeyword(e.target.value)}
                        value={keyword}
                      />
                      <IconButton
                        // type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {selected.length > 0 ? (
                    <Toolbar
                      className={clsx(classes.TBroot, {
                        [classes.highlight]: selected.length > 0,
                        [classes.invisible]: selected.length <= 0
                      })}
                    >
                      {selected.length > 0 && (
                        <Typography
                          className={classes.title}
                          color="inherit"
                          variant="subtitle1"
                          component="div"
                        >
                          <span>{selected.length}</span> client/s selected
                        </Typography>
                      )}
                      {selected.length > 0 && (
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleOpenDeleteAll();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Toolbar>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.tablePaper} variant="outlined">
                    <TableContainer className={classes.table}>
                      <Table
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                indeterminate={isChecked().indeterminate}
                                checked={isChecked().all}
                                onChange={onSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all desserts"
                                }}
                              />
                            </TableCell>
                            <TableCell
                              padding="normal"
                              // sortDirection={orderBy === headCell.id ? order : false}
                            >
                              <TableSortLabel
                                active
                                direction={orderBy}
                                onClick={() =>
                                  setOrderBy(prev =>
                                    prev === "asc" ? "desc" : "asc"
                                  )
                                }
                              >
                                Client
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                size="small"
                                color="primary"
                                classes={{ root: classes.addEntryButton }}
                                onClick={() => openGroupModal()}
                              >
                                Add Entry
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupList &&
                            stableSort(
                              groupList,
                              getComparator(orderBy, "name")
                            )
                              .filter(
                                row =>
                                  row.name
                                    .toUpperCase()
                                    .indexOf("".toUpperCase()) > -1 &&
                                  row.name
                                    .toLowerCase()
                                    .indexOf(keyword.toLowerCase()) > -1
                              )
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={event =>
                                          handleClick(event, row.id)
                                        }
                                        checked={isItemSelected}
                                        // onClick={e => handleGroupSelect(e, row)}
                                        // checked={isChecked(row.id)}
                                        inputProps={{
                                          "aria-labelledby": labelId
                                        }}
                                        color="primary"
                                      />
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="left"
                                    >
                                      {row.name}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="right"
                                    >
                                      <DeleteIcon
                                        onClick={() =>
                                          handleRemoveGroup(row.id)
                                        }
                                        style={{ paddingRight: 8 }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  margin: "20px 0px 20px 0px",
                  transform: "translateY(30px)"
                }}
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleAddControlRoom}
                >
                  Save Details
                </Button>
              </Grid>
            </div>
          </div>
        </div>
        {/* <Grid container>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", margin: "20px 0px 20px 0px" }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleAddControlRoom}
            >
              Save Details
            </Button>
          </Grid>
        </Grid> */}
        <ConfirmationDialog
          toggle={open}
          close={() => handleClose()}
          fn={e => handleAddControlRoom(e)}
          title="Save"
          content="Are you sure you want to save this data?"
        />
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleCloseDialog()}
          fn={() => handleCloseDialog()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleMultipleDeletePartner(e)}
          title="Delete?"
          content="Are you sure you want to delete these partners?"
        />
        <SelectGroup
          toggle={groupModal}
          closeGroupModal={closeGroupModal}
          handleGroupList={handleGroupList}
          groupList={groupList}
        />
      </div>
    </AccessControl>
  );
}

export default AddControlRoom;
