export const frequencyData = [
  {
    id: 1,
    label: "Every day",
    day: null
  },
  {
    id: 2,
    label: "Sunday",
    day: 0
  },
  {
    id: 3,
    label: "Monday",
    day: 1
  },
  {
    id: 4,
    label: "Tuesday",
    day: 2
  },
  {
    id: 5,
    label: "Wednesday",
    day: 3
  },
  {
    id: 6,
    label: "Thursday",
    day: 4
  },
  {
    id: 7,
    label: "Friday",
    day: 5
  },
  {
    id: 8,
    label: "Saturday",
    day: 6
  }
];
