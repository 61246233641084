import React, { memo } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useStyles } from './PaperCountStyle';

const PaperCount = (props) => {
    const classes = useStyles();
    const { label, currentTotal, data } = props
    const total = data? data : '-'; 

    return(
        <Paper variant="outlined" className={classes.paper}>
            <Typography className={classes.typography}>
                {`${currentTotal? currentTotal+' /':''} ${total} ${label}`}
            </Typography>
        </Paper>
    )
};

export default memo(PaperCount);