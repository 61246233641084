import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  ListItemText,
  CircularProgress,
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import {
  Brightness1,
  ErrorOutline,
  FileCopyOutlined,
  Event as EventIcon
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { statusColor, StatusCode } from "../../Utils";
import { ItemsDialog, IssuesDialog } from "./Dialogs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    // width: "100%",
    marginBottom: theme.spacing(2),
    padding: 8
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  expandable: {
    verticalAlign: "top"
  },
  subCell: {
    display: "block",
    marginTop: "20px"
  },
  button: {
    margin: 4
  },
  link: {
    margin: 5,
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: 13,
    textDecoration: "none"
  },
  textStyle: {
    color: theme.palette.primary.dark,
    fontSize: 13,
    fontFamily: "Nunito"
  },
  textLocation: {
    color: "#808080",
    fontSize: 9,
    fontFamily: "Nunito"    
  },
}));

const headCells = [
  {
    id: "row_num",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "so_number",
    numeric: false,
    disablePadding: false,
    label: "SO number"
  },
  {
    id: "stops",
    numeric: false,
    disablePadding: false,
    label: "Stops"
  },
  {
    id: "plan_arrival",
    numeric: false,
    disablePadding: false,
    label: "Planned Arrival"
  },
  {
    id: "actual_arrival",
    numeric: false,
    disablePadding: false,
    label: "Actual Arrival"
  },
  {
    id: "actual_departure",
    numeric: false,
    disablePadding: false,
    label: "Actual Departure"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  { id: "action", numeric: false, disablePadding: false, label: "" }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: 13, fontWeight: "700", fontFamily: "Nunito" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const ListView = props => {
  const classes = useStyles();
  const {
    parsedTripData,
    isEdit,
    setParsedTripData,
    manualCompleteMode
  } = props;
  const [stops, setStops] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("so_number");
  const [itemsDialogOpen, setItemsDialogOpen] = useState({
    items: false,
    issues: {
      open: false,
      mode: "edit",
      activeIndex: undefined
    }
  });

  const [activeItems, setActiveItems] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const desc = (a, b, oB) => {
    if (b[oB] < a[oB]) {
      return -1;
    }
    if (b[oB] > a[oB]) {
      return 1;
    }
    return 0;
  };

  const getSorting = (o, oB) => {
    return o === "desc" ? (a, b) => desc(a, b, oB) : (a, b) => -desc(a, b, oB);
  };
  useEffect(() => {
    if (parsedTripData) {
      const { pickups, dropoffs } = parsedTripData;
      const newStops = _.union(pickups, dropoffs);
      setStops(newStops);
    }
  }, [parsedTripData]);

  const handleSoClick = so_number => {
    const { raw_dropoffs } = parsedTripData;

    const items = raw_dropoffs.filter(
      dropoff => dropoff.so_number === so_number
    );
    setActiveItems(items);
    setItemsDialogOpen({ ...itemsDialogOpen, items: true });
  };

  const addIssue = (issue, so_number, is_pickup) => {
    const { dropoffs, pickups, raw_pickups, raw_dropoffs } = parsedTripData;
    if (!is_pickup) {
      const do_index = pickups.length - so_number;
      dropoffs[do_index] = { ...dropoffs[do_index], issue: issue };
      _.forEach(raw_dropoffs, rdo => {
        const rdo_index = _.findIndex(dropoffs[so_number]?.so_numbers, so => {
          return rdo.so_number === so;
        });
        if (rdo_index !== -1) {
          raw_dropoffs[rdo_index] = {
            ...raw_dropoffs[rdo_index],
            issue: issue
          };
        }
      });
    } else {
      pickups[so_number] = { ...pickups[so_number], issue: issue };
      _.forEach(raw_pickups, rpu => {
        const rpu_index = _.findIndex(pickups[so_number]?.so_numbers, so => {
          return rpu.so_number === so;
        });
        if (rpu_index !== -1) {
          raw_pickups[rpu_index] = {
            ...raw_pickups[rpu_index],
            issue: issue
          };
        }
      });
    }
    setParsedTripData({
      ...parsedTripData,
      dropoffs: dropoffs,
      pickups: pickups,
      raw_pickups: raw_pickups,
      raw_dropoffs: raw_dropoffs
    });
  };

  const renderDateInput = (stop, index, type) => {
    let display = "-";
    const isArrival = type === "arrival";

    const isArrivedButNoDepature = currentStop => {
      return (
        !isArrival &&
        (currentStop.status_code_id === StatusCode.ARRIVED_AT_PICKUP ||
          currentStop.status_code_id === StatusCode.ARRIVED_AT_DROPOFF) &&
        currentStop.actual_departure === null
      );
    };

    const dateFor = date => {
      const { dropoffs, pickups } = parsedTripData;
      const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      if (isArrival) {
        return { manual_arrival: formattedDate };
      }
      const basis = moment(
        pickups[0]?.manual_arrival ||
          dropoffs[index]?.manual_arrival ||
          new Date()
      ).format("YYYY-MM-DD HH:mm:ss");

      if (
        moment(formattedDate).format("YYYY-MM-DD HH:mm") >=
        moment(basis).format("YYYY-MM-DD HH:mm")
      ) {
        return { manual_departure: formattedDate };
      }
      Swal.fire({
        icon: "error",
        title: "Invalid date",
        text: "Arrival time should be earlier than depature time"
      });
      return { manual_departure: basis };
    };

    const handleArrivalChange = date => {
      const { dropoffs, pickups, raw_pickups, raw_dropoffs } = parsedTripData;
      if (stop.is_pickup) {
        pickups[index] = {
          ...pickups[index],
          // manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          // manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss")
          ...dateFor(date)
        };

        _.forEach(pickups[index].so_numbers, so => {
          const rpu_index = _.findIndex(
            raw_pickups,
            rpu => rpu.so_number === so
          );
          if (rpu_index !== -1) {
            raw_pickups[rpu_index] = {
              ...raw_pickups[rpu_index],
              // manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              // manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              ...dateFor(date),
              status_code: "Manually Completed",
              status_code_id: StatusCode.MANUALLY_COMPLETED
            };
          }
        });
      } else {
        const do_index = index - pickups.length;
        dropoffs[do_index] = {
          ...dropoffs[do_index],
          // manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          // manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss")
          ...dateFor(date)
        };
        // const do_index = _.findIndex(dropoffs, ["so_number", stop.so_number]);
        // if (do_index !== -1) {
        //   dropoffs[do_index] = {
        //     ...dropoffs[do_index],
        //     manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        //     manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss")
        //   };
        // }
        _.forEach(dropoffs[do_index].so_numbers, so => {
          const rdo_index = _.findIndex(
            raw_dropoffs,
            rdo => rdo.so_number === so
          );
          if (rdo_index !== -1) {
            raw_dropoffs[rdo_index] = {
              ...raw_dropoffs[rdo_index],
              // manual_arrival: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              // manual_departure: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              ...dateFor(date),
              status_code: "Manually Completed",
              status_code_id: StatusCode.MANUALLY_COMPLETED
            };
          }
        });
      }
      setParsedTripData({
        ...parsedTripData,
        dropoffs: dropoffs,
        pickups: pickups,
        raw_pickups: raw_pickups
      });
    };

    if (
      stop.status_code_id === StatusCode.IN_TRANSIT ||
      stop.status_code_id === StatusCode.SCHEDULED ||
      isArrivedButNoDepature(stop)
    ) {
      display = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            disableFuture
            InputProps={{
              placeholder: isArrival
                ? "Actual Arrival Time"
                : "Actual Departure Time",
              endAdornment: (
                <InputAdornment position="end">
                  <EventIcon />
                </InputAdornment>
              )
              // name: "actual_arrival"
            }}
            value={isArrival ? stop.manual_arrival : stop.manual_departure}
            showTodayButton
            todayLabel="NOW"
            onChange={handleArrivalChange}
          />
        </MuiPickersUtilsProvider>
      );
    } else if (stop.status_code_id === StatusCode.MANUALLY_COMPLETED) {
      if (isArrival) {
        display = moment(stop.manual_arrival || stop.actual_arrival).format(
          "lll"
        );
      } else {
        display = moment(stop.manual_departure || stop.actual_departure).format(
          "lll"
        );
      }
    } else if (
      stop.status_code === StatusCode.ARRIVED_AT_PICKUP ||
      stop.status_code === StatusCode.ARRIVED_AT_DROPOFF
    ) {
      display = moment(stop.actual_arrival).format("lll");
    } else if (stop.is_pickup && stop.actual_arrival) {
      display = moment(stop.actual_arrival).format("lll");
    } else if (stop.status_code_id === StatusCode.COMPLETED) {
      display = moment(stop.actual_arrival).format("lll");
    }
    return display;
  };

  return (
    <Fragment>
      <ItemsDialog
        open={itemsDialogOpen}
        items={activeItems}
        setItemsDialogOpen={setItemsDialogOpen}
      />
      <IssuesDialog
        open={itemsDialogOpen}
        setItemsDialogOpen={setItemsDialogOpen}
        addIssue={addIssue}
      />
      <Table className={classes.table} size="medium">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stops.length ? (
            stableSort(stops, getSorting(order, orderBy)).map((stop, index) => {
              let so_numbers = null;
              so_numbers = stop.so_numbers.map(so_number => (
                <Typography
                  className={classes.link}
                  onClick={() => {
                    handleSoClick(so_number);
                  }}
                  key={so_number}
                >
                  {so_number}
                </Typography>
              ));
              let actual_arrival = "-";
              let actual_departure = "-";
              let { status_code } = stop;
              if (stop.status_code_id === StatusCode.COMPLETED) {
                actual_arrival =
                  (stop.actual_arrival &&
                    moment(stop.actual_arrival).format("lll")) ||
                  "-";

                actual_departure =
                  stop.actual_departure &&
                  moment(stop.actual_departure).format("YYYY") !== "1111"
                    ? moment(stop.actual_departure).format("lll")
                    : stop.manual_departure &&
                      moment(stop.manual_departure).format("YYYY") !== "1111"
                    ? moment(stop.manual_departure).format("lll")
                    : "-";
                // (stop.actual_departure &&
                //   moment(stop.actual_departure).format("lll")) ||
                // (stop.manual_departure &&
                //   moment(stop.manual_departure).format("lll")) ||
                // "-";
              } else if (
                stop.status_code_id === StatusCode.MANUALLY_COMPLETED
              ) {
                const {
                  manual_arrival: stopManualArrival,
                  actual_arrival: stopActualArrival,
                  manual_departure: stopManualDeparture,
                  actual_departure: stopActualDeparture
                } = stop;
                const arrival = stopManualArrival || stopActualArrival;
                const departure = stopManualDeparture || stopActualDeparture;
                actual_arrival =
                  (arrival && moment(arrival).format("lll")) || "-";
                actual_departure =
                  departure && moment(departure).format("YYYY") !== "1111"
                    ? moment(departure).format("lll")
                    : "-";
                // (departure && moment(departure).format("lll")) || "-";
              } else if (
                stop.status_code_id === StatusCode.ARRIVED_AT_PICKUP ||
                stop.status_code_id === StatusCode.ARRIVED_AT_DROPOFF
              ) {
                // actual_arrival = moment(stop.actual_arrival).format("lll");
                // actual_departure = moment(stop.manual_departure).format("lll");
                actual_arrival = moment(stop.actual_arrival).format("lll");
                actual_departure =
                  stop.actual_departure &&
                  moment(stop.actual_departure).format("YYYY") !== "1111"
                    ? moment(stop.actual_departure).format("lll")
                    : stop.manual_departure &&
                      moment(stop.manual_departure).format("YYYY") !== "1111"
                    ? moment(stop.manual_departure).format("lll")
                    : "-";
                // (stop.actual_departure &&
                //   moment(stop.actual_departure).format("lll")) ||
                // (stop.manual_departure &&
                //   moment(stop.manual_departure).format("lll")) ||
                // "-";

                status_code = "Arrived";
              } else {
                status_code = "Not yet arrived";
              }
              // const isLate = moment(stop.actual_arrival).isAfter(stop.arrival);

              const key = `SO-${index}`;
              return (
                <TableRow key={key}>
                  <TableCell align="center" style={{ width: 70 }}>
                    <div
                      style={{
                        float: "none",
                        backgroundColor: stop.is_pickup ? "purple" : "#4380F0",
                        borderRadius: 4,
                        color: "white",
                        width: "max-content",
                        fontSize: 13,
                        padding: "2px 6px",
                        margin: "0 0 0 auto"
                      }}
                    >
                      {index + 1}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {so_numbers || (
                      <Typography
                        className={classes.link}
                        onClick={() => handleSoClick(stop.so_number)}
                      >
                        {stop.so_number}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.textStyle}>
                      {stop.geofence_code
                        ? `${stop.geofence_code}-${stop.name}`
                        : stop.name}
                    </Typography>
                    <Typography className={classes.textLocation}>
                      {stop.location_details}
                    </Typography>
                    {/* <ListItemText
                      primary={
                        stop.geofence_code
                          ? `${stop.geofence_code}-${stop.name}`
                          : stop.name
                      }
                      secondary={stop.location_details}
                    /> */}
                  </TableCell>
                  <TableCell
                    style={{ width: 150 }}
                    className={classes.textStyle}
                  >
                    {moment(stop.arrival).format("lll")}
                  </TableCell>
                  <TableCell
                    style={{
                      color: stop.is_late > 0 ? "red" : "black",
                      width: 150
                    }}
                    className={classes.textStyle}
                  >
                    {!isEdit /* && !manualCompleteMode */
                      ? actual_arrival
                      : renderDateInput(stop, index, "arrival")}
                  </TableCell>
                  <TableCell
                    style={{
                      color: stop.is_late > 0 ? "red" : "black",
                      width: 150
                    }}
                    className={classes.textStyle}
                  >
                    {!isEdit /* && !manualCompleteMode */
                      ? // ? stop.actual_departure
                        //   ? stop.actual_departure
                        //   : stop.manual_departure
                        actual_departure
                      : renderDateInput(stop, index, "departure")}
                    {/* {stop.actual_departure
                      ? moment(stop.actual_departure).format("lll")
                      : "-"} */}
                  </TableCell>
                  <TableCell align="center">
                    {/* {(stop.issue || isLate) && ( */}
                    {((stop.issue && stop.issue !== "null") ||
                      stop.is_late > 0) && (
                      <Tooltip
                        title={
                          (stop.issue !== "null" && stop.issue) ||
                          "Late Delivery"
                        }
                      >
                        <ErrorOutline style={{ color: "red", fontSize: 20 }} />
                      </Tooltip>
                    )}
                    <Tooltip title={status_code}>
                      <Brightness1
                        style={{
                          color: statusColor(stop.status_code_id),
                          fontSize: 20
                        }}
                      />
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    {(isEdit || manualCompleteMode) &&
                      (stop.status_code_id === StatusCode.IN_TRANSIT ||
                        stop.status_code_id === StatusCode.SCHEDULED) && (
                        <IconButton
                          style={{ padding: 10, paddingBottom: 12 }}
                          onClick={() =>
                            setItemsDialogOpen({
                              ...itemsDialogOpen,
                              issues: {
                                open: true,
                                mode: "edit",
                                activeIndex: index,
                                issue: stop.issue,
                                is_pickup: stop.is_pickup
                              }
                            })
                          }
                        >
                          <FileCopyOutlined
                            style={{
                              color: "orange",
                              fontSize: 20
                            }}
                          />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={20} align="center">
                <CircularProgress
                  size={60}
                  style={{ display: "inline-block" }}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default ListView;
