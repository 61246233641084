/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable global-require */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
  MenuItem,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  DialogContentText,
  Grid,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  Fab,
  Popover,
  Card,
  CardContent,
  CardMedia,
  Badge
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  ExpandMore,
  Brightness1,
  FileCopy
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import moment from "moment";
import _ from "lodash";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
import SearchContext from "../../../../context/SearchContext";
import Filter from "../../Filter";
import validate from "../../../../utils/validation";
import {
  GET_POLITICAL_BOUNDS,
  GET_TEXTUAL_LOCATION,
  GET_GEOFENCES_TABLE,
  // GET_GEOFENCES,
  // GET_ADMIN_CLIENTS,
  CHECK_GEOFENCE_ASSIGNMENT
} from "../../../../graphql/Queries";
import { DELETE_GEOFENCE } from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import DeleteDialog from "../../../../utils/modal/DeleteDialog";
import client from "../../../../Client";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";
import useFormStyles from "../../../../styles/useFormStyles";
import useUserContext from "../../../../context/User/useUserContext";
import { checkTemplate } from "../../../../utils/functions/CheckTemplate";

import { ConvertTimeExcel } from "../../../../utils/functions/ConvertExcelTime";
import HaulerGroupSelector from "./HaulerGroupSelector";
import CloneGeofences from "./CloneGeofences";
import geofenceStyles from "./styles/Geofences.style";
import {
  getBounds,
  getCenter,
  isValidGeom
} from "../../../../utils/functions/coordinatesParserV2";
import { geofenceTypes } from "../../../Utils";

const Highlight = require("react-highlighter");

function desc(a, b, orderBy) {
  const first =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const second =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const first = Object.assign({ ...a[0], name: a[0].name.toLowerCase() });
    const second = Object.assign({ ...b[0], name: b[0].name.toLowerCase() });
    const order = cmp(first, second);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
    sortable: true
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Geofence Name",
    sortable: true
  },
  {
    id: "geofence_code",
    numeric: false,
    disablePadding: true,
    label: "Geofence Code",
    sortable: true
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    sortable: true
  },
  {
    id: "region",
    numeric: false,
    disablePadding: false,
    label: "Region",
    sortable: true
  },
  {
    id: "province",
    numeric: false,
    disablePadding: false,
    label: "Province",
    sortable: true
  },
  {
    id: "municipality",
    numeric: true,
    disablePadding: false,
    label: "Municipality / City",
    sortable: true
  }
];

function EnhancedTableHead(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!canEdit}
            color="primary"
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative"
  },
  autoComplete: {
    height: 30,
    marginLeft: 10,
    width: 180,
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children } = props;
  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Geofences
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const DlMenu = props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    tableData,
    keyLog
  } = props;

  const tableDownload = () => {
    return (
      <CSVLink
        data={tableData}
        style={{ textDecoration: "none", color: "black" }}
      >
        Download Table
      </CSVLink>
    );
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  onClick={() =>
                    AddLogs("Admin - Geofences", "download_template", ``)
                  }
                >
                  <Link
                    to="/assets/geofence_upload_template.xlsx"
                    target="_blank"
                    download
                    underline="none"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Download Template
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    AddLogs("Admin - Geofences", "download_table", keyLog)
                  }
                >
                  {tableDownload()}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const RegionSelect = props => {
  const formStyles = useFormStyles();
  const { tempFilter, setTempFilter, clearHistory } = props;
  const { data, loading } = useQuery(GET_POLITICAL_BOUNDS, {
    onCompleted(response) {
      if (response.get_political_boundaries.length === 1) {
        setTempFilter({
          ...tempFilter,
          region: response.get_political_boundaries[0].name
        });
      }
    },
    variables: { type: "region" }
  });

  return (
    <Autocomplete
      loading={loading}
      options={data ? data.get_political_boundaries : []}
      getOptionSelected={(o, v) => {
        if (v.name === o.name) return o.name;
        return {};
      }}
      getOptionLabel={option => option.name || ""}
      renderOption={option => option.name || ""}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: formStyles.textFieldRoot }}
          label={
            <>
              Region <span style={{ color: "red" }}>*</span>
            </>
          }
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold", letterSpacing: 1 }
          }}
          placeholder="All"
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "6px 0px"
            }
          }}
        />
      )}
      name="region"
      onChange={(e, nv) => {
        setTempFilter({ ...tempFilter, region: nv });
        clearHistory();
      }}
      value={tempFilter.region}
      disableClearable
    />
  );
};

function ProvinceSelect(props) {
  const { tempFilter, setTempFilter, clearHistory } = props;
  const formStyles = useFormStyles();
  const [vars, setVars] = useState({ type: "province" });
  const { data, loading } = useQuery(GET_POLITICAL_BOUNDS, {
    onCompleted(response) {
      if (response.get_political_boundaries.length >= 0) {
        setTempFilter({
          ...tempFilter,
          province: tempFilter.province
        });
      }
    },
    variables: vars
  });

  useEffect(() => {
    const condition = {};
    if (
      Object.keys(tempFilter)
        .filter(
          key =>
            tempFilter[key] &&
            ![
              "province",
              "municipality",
              "hauler",
              "category",
              "is_verified"
            ].includes(key)
        )
        .some(key => tempFilter[key] !== "")
    ) {
      Object.keys(tempFilter)
        .filter(
          key =>
            tempFilter[key] &&
            ![
              "province",
              "municipality",
              "hauler",
              "category",
              "is_verified"
            ].includes(key)
        )
        .forEach(key => {
          Object.assign(condition, {
            field: key,
            value: [tempFilter[key].name]
          });
        });
      setVars({ ...vars, condition: [condition] });
    }

    return () => {
      setVars({ type: "province" });
    };
  }, [tempFilter]);

  return (
    <Autocomplete
      loading={loading}
      options={data ? data.get_political_boundaries : []}
      getOptionLabel={option => option.name || ""}
      getOptionSelected={(o, v) => {
        if (v.name === o.name) return o.name;
        return {};
      }}
      renderOption={option => option.name || ""}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: formStyles.textFieldRoot }}
          label={
            <>
              Province <span style={{ color: "red" }}>*</span>
            </>
          }
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold", letterSpacing: 1 }
          }}
          placeholder="All"
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "6px 0px"
            }
          }}
        />
      )}
      name="province"
      onChange={(e, nv) => {
        setTempFilter({ ...tempFilter, province: nv });
        clearHistory();
      }}
      value={tempFilter.province}
      disableClearable
    />
  );
}

function MunicipalitySelect(props) {
  const formStyles = useFormStyles();
  const { tempFilter, setTempFilter, clearHistory } = props;
  const [vars, setVars] = useState({ type: "municipal" });
  const { data, loading } = useQuery(GET_POLITICAL_BOUNDS, {
    onCompleted(response) {
      if (response.get_political_boundaries.length >= 0) {
        setTempFilter({
          ...tempFilter,
          municipality: tempFilter.municipality
        });
      }
    },
    variables: vars
  });

  useEffect(() => {
    const condition = {};
    if (
      Object.keys(tempFilter)
        .filter(
          key =>
            tempFilter[key] &&
            !["municipality", "hauler", "category", "is_verified"].includes(key)
        )
        .some(key => tempFilter[key] !== "")
    ) {
      Object.keys(tempFilter)
        .filter(
          key =>
            tempFilter[key] &&
            !["municipality", "hauler", "category", "is_verified"].includes(key)
        )
        .forEach(key => {
          Object.assign(condition, {
            field: key,
            value: [tempFilter[key].name]
          });
        });
      setVars({ ...vars, condition: [condition] });
    }
    return () => {
      setVars({ type: "municipal" });
    };
  }, [tempFilter]);

  return (
    <Autocomplete
      loading={loading}
      options={data ? data.get_political_boundaries : []}
      getOptionLabel={option => option.name || ""}
      renderOption={option => option.name || ""}
      getOptionSelected={(o, v) => {
        if (v.name === o.name) return o.name;
        return {};
      }}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: formStyles.textFieldRoot }}
          label={
            <>
              Municipality <span style={{ color: "red" }}>*</span>
            </>
          }
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold", letterSpacing: 1 }
          }}
          placeholder="All"
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "6px 0px"
            }
          }}
        />
      )}
      name="municipality"
      onChange={(e, nv) => {
        setTempFilter({ ...tempFilter, municipality: nv });
        clearHistory();
      }}
      value={tempFilter.municipality}
      disableClearable
    />
  );
}

function GeofenceStatus(props) {
  const formStyles = useFormStyles();
  const {
    tempFilter,
    setTempFilter,
    statusProp,
    setStatusProps,
    clearHistory
  } = props;
  const data = [
    { name: "Verified", value: "1" },
    { name: "Not Yet Verified", value: "0" }
  ];

  return (
    <Autocomplete
      options={data || []}
      getOptionLabel={option => option.name || ""}
      renderOption={option => option.name || ""}
      getOptionSelected={(o, v) => {
        if (v.name === o.name) return o.name;
        return {};
      }}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: formStyles.textFieldRoot }}
          label={
            <>
              Geofence Status <span style={{ color: "red" }}>*</span>
            </>
          }
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold", letterSpacing: 1 }
          }}
          placeholder="All"
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "6px 0px"
            }
          }}
        />
      )}
      name="is_verified"
      onChange={(e, nv) => {
        setStatusProps(nv);
        setTempFilter({ ...tempFilter, is_verified: nv.value });
        clearHistory();
      }}
      value={statusProp}
      disableClearable
    />
  );
}

function UploadDialog(props) {
  const history = useHistory();
  const classes = geofenceStyles();
  const user = JSON.parse(Cookie.get("user"));
  const {
    uploadDialog,
    setUploadDialog,
    handleCloseUpload,
    state,
    file,
    setFile
  } = props;
  const [summary, setSummary] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  // object ready data
  const [excelData, setExcelData] = useState([]);
  const [proceed, setProceed] = useState(false);
  const [geofenceCodes, setGeofenceCodes] = useState([]);
  const [re, setRe] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [getTextualLocation] = useLazyQuery(GET_TEXTUAL_LOCATION);
  const { data } = useQuery(GET_GEOFENCES_TABLE, {
    variables: {
      filter: { field: "geofence_code", value: geofenceCodes },
      first: 1000
    },
    skip: geofenceCodes.length === 0,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (summary) {
      if (fileErrors.length > 0) {
        const temp = file;
        let status = "";

        if (fileErrors.some(error => error.errors !== "")) status = "Failed";

        temp[temp.length - 1] = {
          ...temp[temp.length - 1],
          status: status,
          errors: fileErrors
        };
        setFile(temp);
        setRe(!re);
        setSummary(false);
        setFileErrors([]);
      } else {
        setUploadDialog(false);
        // proceed to the upload details page for saving the saving geofences.
        history.push({
          pathname: "/admin/trip/geofences/upload/details",
          state: { uploadedData: excelData, fileInfo: file }
        });
      }
    }
  }, [summary, fileErrors]);

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const validateCell = (key, value) => {
    let val = "";
    const tempId = [];
    let index = -1;

    switch (key) {
      case "group_ids":
        if (!value) {
          val = { error: "Client is required" };
        } else {
          index = user.group_names.indexOf(value);
          if (index > -1) {
            tempId.push(user.group_ids[index]);
            val = tempId;
          } else {
            val = { error: "Client doesn't exist" };
          }
        }
        break;
      case "name":
        if (_.isEmpty(value.toString().trim())) {
          val = { error: "Geofence name is required" };
        } else {
          val = value.toString();
        }

        break;
      case "geofence_code":
        if (_.isEmpty(_.toString(value).trim())) {
          val = Math.random()
            .toString(36)
            .slice(-5);
        } else {
          val = value?.toString()?.trim();
        }
        break;

      case "category":
        if (!value) {
          val = { error: "Category is required" };
        } else if (validate("category", value)) {
          if (geofenceTypes.map(geo => geo.label).includes(value)) {
            val = value;
          } else {
            val = { error: "Category doesn't exist" };
          }
        } else {
          val = { error: "Category doesn't exist" };
        }
        break;
      case "latitude":
        if (!value) {
          val = { error: "Latitude is required" };
        } else if (!_.inRange(value, 5, 22)) {
          // From -85.05112878, 85.05112878
          val = { error: "Latitude is out of range " };
        } else {
          val = validate("coordinates", value)
            ? parseFloat(value)
            : { error: "Latitude is invalid format" };
        }
        break;
      case "longitude":
        if (!value) {
          val = { error: "Longitude is required" };
        } else if (!_.inRange(value, 116, 128)) {
          // From -180, 180
          val = { error: "Longitude is out of range " };
        } else {
          val = validate("coordinates", value)
            ? parseFloat(value)
            : { error: "Longitude is invalid format" };
        }
        break;
      // polygon validation
      case "polygon":
        if (
          _.isEqual(typeof value, "string") &&
          (validate("polygon", value) || _.isEmpty(value))
        ) {
          if (_.isEmpty(value.trim()) && _.isEqual(typeof value, "string")) {
            val = "";
          } else if (
            // old and new polygon template (without space)
            [
              "MULTIPOLYGON(((5116,5116,5116,5116)))",
              "MULTIPOLYGON(((<lat><lon>,<lat><lon>,<lat><lon>,<lat><lon>)))"
            ].includes(value.replace(/\s+/g, "")) ||
            value?.trim()?.includes("<lat>") ||
            value?.trim()?.includes("<lon>")
          ) {
            // disregard polygon value if value is the same as template
            val = "";
          } else {
            const {
              validPolygon,
              outOfRange,
              isWithinVerticesLimit
            } = isValidGeom(value);
            if (!isWithinVerticesLimit) {
              val = { error: "Polygon vertices value min: 4, max: 2000" };
            } else if (!validPolygon) {
              val = { error: "Polygon is invalid format" };
            } else if (outOfRange) {
              val = { error: "Polygon is out of range" };
            } else {
              val = value;
            }
          }
        } else {
          val = { error: "Polygon is invalid format" };
        }
        break;

      // radius validation set val if has value.
      case "radius":
        let trimmedValue;
        // trim the value for " " for determining if the user inputs only a whitespace, alphabet or number.
        if (_.isEqual(typeof value, "string")) trimmedValue = value.trim();

        // validate if radius input is non numerical
        if (!_.isEmpty(trimmedValue) && _.isEqual(typeof value, "string")) {
          val = { error: "Radius is invalid format" };
        } else if (
          !_.isEqual(typeof value, "string") &&
          !_.inRange(value, 50, 1000000)
        ) {
          val = { error: "Radius value min: 50, max: 1,000,000" };
        } else if (value && _.isEqual(typeof value, "number")) {
          val = value;
        }
        break;
      case "window_time_start":
        const convertedTimeStart = ConvertTimeExcel(value);
        if (!value) {
          val = "1990-01-01 00:00:00";
        } else {
          /*  08:00:00 00:00:01
            validate the time on a format of HH:mm:ss with the converted time of the
            time start & end on the excel file. 
          */

          val = validate("time", convertedTimeStart)
            ? `1990-01-01 ${convertedTimeStart}`
            : { error: "Window time start is invalid format" };
        }
        break;
      case "window_time_end":
        const convertedTimeEnd = ConvertTimeExcel(value);
        if (!value) {
          val = "1990-01-01 00:00:00";
        } else {
          /* 
            validate the time on a format of HH:mm:ss with the converted time of the
            time start & end on the excel file. 
          */
          val = validate("time", convertedTimeEnd)
            ? `1990-01-01 ${convertedTimeEnd}`
            : { error: "Window time end is invalid format" };
        }
        break;
      case "address":
        if (validate("alphanumeric", value)) val = value.trim();
        else val = { error: "Address is invalid format" };
        break;
      default:
        break;
    }
    return val;
  };

  const parseRow = async (row, typeOfUpload) => {
    let isExistingGeofenceName;
    let geofenceArr;
    let isExistingGeofenceCode;
    let geofenceCodeArr;
    const temp = { location: {} };
    const array = row.filter(r => typeof r !== "object").map(r => r);
    const id = row.filter(r => typeof r === "object");
    // const add = "";
    process.env.REACT_APP_GEOFENCES_KEYS.split(",").forEach((key, index) => {
      // key === "latitude" || key === "longitude"
      //   ? Object.assign(temp.location, {
      //       [key.substring(0, 3)]: validateCell(key, array[index])
      //     })
      //   : _.isEqual(key, "polygon") && _.isEqual(typeof array[index], "string")
      //   ? Object.assign(temp, {
      //       geom: validateCell(key, array[index].trim())
      //     })
      //   : Object.assign(temp, { [key]: validateCell(key, array[index]) });
      switch (key) {
        case "latitude":
        case "longitude":
          Object.assign(temp.location, {
            [key.substring(0, 3)]: validateCell(key, array[index])
          });
          break;
        case "polygon":
          const geom = validateCell(key, array[index]?.toString()?.trim());
          if (geom.error) {
            Object.assign(temp, {
              geom: validateCell(key, array[index].trim())
            });
            return;
          }
          const latIndex = process.env.REACT_APP_GEOFENCES_KEYS.split(
            ","
          ).findIndex(col => col === "latitude");
          const lonIndex = process.env.REACT_APP_GEOFENCES_KEYS.split(
            ","
          ).findIndex(col => col === "longitude");

          const centerWithinBounds = geom
            ? getBounds(geom).contains({
                lat: +array[latIndex],
                lng: +array[lonIndex]
              })
            : false;

          // Get new center if lat/lon is outside geom bounds
          const newCenter =
            geom && !centerWithinBounds ? getCenter(geom) : null;

          Object.assign(temp, {
            geom: geom,
            ...(geom && {
              radius: "",
              ...(newCenter && { location: newCenter })
            })
          });
          break;
        default:
          Object.assign(temp, { [key]: validateCell(key, array[index]) });
          break;
      }
    });

    // checking of geofence name if already exist.
    if (
      !_.isEqual(typeof temp.name, "object") &&
      !_.isEqual(typeOfUpload, "update")
    ) {
      isExistingGeofenceName = await client.query({
        query: GET_GEOFENCES_TABLE,
        variables: {
          filter: { field: "name", value: [temp.name] },
          first: 1000
        },
        fetchPolicy: "network-only"
      });
      // fetch geofences key
      geofenceArr = isExistingGeofenceName.data.get_geofences.geofences;
      // validation for geofence name
      if (!_.isEmpty(geofenceArr)) {
        Object.assign(temp, { name: { error: "Geofence name already exist" } });
      }
    }

    if (
      !_.isEqual(typeof temp.geofence_code, "object") &&
      !_.isEqual(typeOfUpload, "update")
    ) {
      isExistingGeofenceCode = await client.query({
        query: GET_GEOFENCES_TABLE,
        variables: {
          filter: { field: "geofence_code", value: [temp.geofence_code] },
          first: 1000
        },
        fetchPolicy: "network-only"
      });
      // fetch geofences key
      geofenceCodeArr = isExistingGeofenceCode.data.get_geofences.geofences;
      // validation for geofence code
      if (!_.isEmpty(geofenceCodeArr)) {
        Object.assign(temp, {
          geofence_code: { error: "Geofence code already exist" }
        });
      }
    }

    // set error for polygon if radius & polygon is null, blank or zero.
    if (!temp.radius && !temp.geom) {
      Object.assign(temp, {
        polygon: { error: "Must have values either polygon or radius" }
      });
    }

    if (
      !_.isEqual(typeof temp.address, "object") &&
      !_.isEqual(typeof temp.location.lat, "object") &&
      !_.isEqual(typeof temp.location.lon, "object") &&
      _.isEmpty(temp.address)
    ) {
      let res = null;
      res = await getTextualLocation({
        variables: {
          points: [temp.location],
          group_ids: user.group_ids
        }
      }).then(response => {
        return response.data.get_textual_location;
      });
      if (res) {
        Object.assign(temp, { address: res });
      }
      // Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
      // res = await Geocode.fromLatLng(temp.location.lat, temp.location.lon).then(
      //   response => {
      //     return response;
      //   }
      // );
      // if (res && res.status === "OK") {
      //   Object.assign(temp, { address: res.results[0].formatted_address });
      // }
    }

    const { group_names, group_ids } = user;
    Object.assign(temp, { type: "multipolygon", opacity: 0.5 });
    if (id.length > 0) {
      Object.assign(temp, id[0]);
    }
    if (Array.isArray(temp.group_ids)) {
      Object.assign(temp, {
        group_names: group_names[group_ids.indexOf(temp.group_ids[0])]
      });
    }
    return temp;
  };

  const handleUpload = e => {
    const { files } = e.target;
    setFile([...file, { file: files[0], status: "", errors: [] }]);
    e.target.value = null;
  };

  const uploadFile = async (batchData, typeOfUpload) => {
    const tempFile = file;

    tempFile[file.length - 1] = {
      ...tempFile[file.length - 1],
      status: "Validating"
    };

    setFile(tempFile);
    setForceRerender(!forceRerender);
    const tempBatchData = [];
    let validData = false;
    const fileError = [];
    const interval = 10;

    for (let i = 1; i <= batchData.length - 1; i += interval) {
      const validIndex = [];
      const tempDatz = batchData.slice(i, i + interval);

      for (let u = 0; u <= tempDatz.length - 1; u += 1) {
        // eslint-disable-next-line no-await-in-loop
        const temp = await parseRow(tempDatz[u], typeOfUpload);
        const tempErrors = [];
        if (
          Object.values(temp).every(
            value => Object.keys(value)[0] !== "error"
          ) &&
          Object.values(temp.location).every(
            value => Object.keys(value)[0] !== "error"
          ) &&
          _.isEmpty(fileError)
        ) {
          tempBatchData.push(temp);
          validIndex.push(i + u + 1);
          validData = true;
        } else {
          Object.values(temp)
            .filter(value => Object.keys(value)[0] === "error")
            .map(value => tempErrors.push(value.error));
          Object.values(temp.location)
            .filter(value => Object.keys(value)[0] === "error")
            .map(value => tempErrors.push(value.error));

          fileError.push({
            row: i + u + 1,
            errors: _.join(tempErrors, ", ")
          });
          validData = false;
        }
      }

      if (i + interval > batchData.length - 1) {
        if (!validData) {
          setFileErrors(fileError);
          setSummary(true);
        } else {
          setExcelData(tempBatchData);
          setSummary(true);
        }
      }
    }
    setGeofenceCodes([]);
  };

  const submitUpload = async () => {
    const tempFile = file;
    const f = file[file.length - 1].file;
    const reader = new FileReader();
    const envDataHeaders = process.env.REACT_APP_GEOFENCES_KEYS.split(",");

    reader.onload = async e => {
      const dataRes = new Uint8Array(e.target.result);
      const workbook = XLSX.read(dataRes, { type: "array" });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const raw_data = XLSX.utils.sheet_to_json(first_worksheet, {
        header: 1,
        blankrows: false,
        defval: ""
      });
      const datz = raw_data.map(r => r.slice(0, envDataHeaders.length));

      if (f.size / 1024 / 1024 > 5) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is too large"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      // validate if the file uploaded is the template.
      const isTemplate = _.last(checkTemplate(datz[0], envDataHeaders));
      if (!isTemplate) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "Please use the template provided"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (datz.length === 1) {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Failed",
          errors: "File is empty"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      const geo_codes = datz
        .filter((d, index) => index > 0)
        .map(d => {
          if (_.isEqual(_.toString(d[2]).replace(" ", ""), ""))
            return Math.random()
              .toString(36)
              .slice(-5);
          return _.toString(d[2]);
        });
      setUploadData(datz);
      /*
       *** fetch all the geofence codes and store only unique geofence codes.
       *** validates if their is a duplicate of geofence code within the uploaded file.
       */
      const noDupGeoCodeTemp = new Set(geo_codes);
      if (geo_codes.length !== noDupGeoCodeTemp.size) {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Failed",
          errors: "Duplicate geofence code exists within the same file."
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (
        geofenceCodes.length > 0 &&
        geofenceCodes.length === geo_codes.length
      ) {
        setProceed(true);
      } else {
        setGeofenceCodes(geo_codes);
      }
    };
    reader.readAsArrayBuffer(f);
  };

  useEffect(() => {
    if (data) {
      const { geofences } = data.get_geofences;
      const geo_codes = geofences.map(g => g.geofence_code);
      if (geo_codes.length > 0) {
        setProceed(true);
      } else {
        uploadFile(uploadData);
      }
    }
  }, [data]);

  const pendingFile = () => {
    if (file.length > 0) {
      if (!file[file.length - 1].status) {
        return true;
      }
    }
    return false;
  };

  const [expanded, setExpanded] = useState(-1);

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  const handleSkip = () => {
    let newData = uploadData;
    const tempFile = file;
    const existingCode = data
      ? data.get_geofences.geofences.map(g => g.geofence_code.toString())
      : [];

    if (existingCode.length > 0) {
      newData = uploadData
        // .filter(ud => existingCode.indexOf(ud[2].toString()) < 0)
        /* 
          ud[2] - validates only the last row on the excel file. 
          ud[3] - validates all the rows on the excel file. 
        */
        .filter(ud => existingCode.indexOf(ud[3].toString()) < 0)
        .map(ud => ud);
    }

    if (newData.length === 1) {
      tempFile[file.length - 1] = {
        ...file[file.length - 1],
        status: "Failed",
        errors: "Geofence code/s already exists."
      };
      setFile(tempFile);
      setRe(!re);
      setGeofenceCodes([]);
    } else {
      uploadFile(newData);
    }
    setProceed(false);
  };

  const handleReplace = () => {
    let newData = uploadData;
    let newTempData; // eslint-disable-line no-unused-vars
    const existingCode = data
      ? data.get_geofences.geofences.map(g => g.geofence_code.toString())
      : [];
    if (existingCode.length > 0) {
      newData = uploadData.map((ud, indexRow) => {
        const tempRow = ud;
        const index = existingCode.indexOf(ud[2].toString());
        if (index > -1) {
          tempRow.push({ id: data.get_geofences.geofences[index].id });
          return tempRow;
        }
        tempRow.push({ row: indexRow + 1 });
        // }else if(indexRow === 0){
        //   // return the header table of the excel upload data.
        //   return tempRow;
        // }
        return tempRow;
      });
      /*
       *** handles non existing data.
       *** this will only include data's that currently exist to be send on the upload details page.
       */
      newTempData = newData.filter(row => !_.isEmpty(row));
    }

    uploadFile(newData, "update");
    setProceed(false);
  };

  useEffect(() => {
    if (state?.hasUploaded) {
      setFile(state.fileInfo);
      setUploadDialog(true);
    }
  }, [uploadDialog]);

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={handleCloseUpload}
        aria-labelledby="customized-dialog-title"
        open={uploadDialog}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
          Uploads
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            root: classes.dialogContent,
            dividers: classes.dialogContent
          }}
        >
          {file.length > 0 &&
            file.map((fileInfo, index) => (
              <ExpansionPanel
                key={index}
                square
                expanded={index === expanded}
                onClick={() => handleClick(index)}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    (_.isEqual(fileInfo.status, "Failed") ||
                      _.isEqual(fileInfo.status, "Partial Success")) && (
                      <ExpandMore />
                    )
                  }
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <Typography className={classes.typography}>
                        {fileInfo.file.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.typography}>
                        {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Typography
                            className={`${classes.typography} ${
                              _.isEqual(fileInfo.status, "Success")
                                ? classes.statusSuccessful
                                : _.isEqual(fileInfo.status, "Validating")
                                ? classes.statusValidating
                                : classes.statusFailed
                            }`}
                          >
                            {_.isEqual(fileInfo.status, "Validating")
                              ? `${fileInfo.status}...`
                              : fileInfo.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                {fileInfo.status === "Partial Success" && (
                  <ExpansionPanelDetails>
                    <Typography
                      className={classes.statusFailed}
                      style={{ fontSize: 12, margin: 8 }}
                    >
                      {fileInfo.errors.length <= 0
                        ? `${fileInfo.noOfNotSaved} geofence/s were not selected to be included in the upload.`
                        : fileInfo.noOfNotSaved > 0
                        ? `${fileInfo.noOfNotSaved} geofence/s were not selected to be included in the upload &
                          ${fileInfo.errors.length} geofence/s were failed to upload.`
                        : `${fileInfo.errors.length} geofence/s were failed to upload.`}
                    </Typography>
                  </ExpansionPanelDetails>
                )}
                {(fileInfo.status === "Failed" ||
                  fileInfo.errors.length > 0) && (
                  <ExpansionPanelDetails>
                    {typeof fileInfo.errors === "string" ? (
                      <Typography
                        className={classes.statusFailed}
                        style={{ fontSize: 12, margin: 8 }}
                      >
                        {fileInfo.errors}
                      </Typography>
                    ) : (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "100px" }}>
                              Row
                            </TableCell>
                            <TableCell align="left">Reason</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fileInfo.errors.length > 0 &&
                            fileInfo.errors
                              .filter(row => row.errors !== "")
                              .sort(reorder)
                              .map(row => (
                                <TableRow key={row.name}>
                                  <TableCell component="th" scope="row">
                                    <Typography className={classes.typography}>
                                      {row.row}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography className={classes.typography}>
                                      {row.errors}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    )}
                  </ExpansionPanelDetails>
                )}
              </ExpansionPanel>
            ))}
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", width: "100%" }}>
            <label htmlFor="icon-upload-file">
              <input
                className="form-control"
                color="primary"
                accept=".csv, .xlsx, .xls"
                type="file"
                id="icon-upload-file"
                style={{ display: "none" }}
                onChange={e => handleUpload(e)}
                disabled={pendingFile()}
              />
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                style={{ backgroundColor: "#ffb677" }}
                startIcon={<PublishIcon />}
                disabled={pendingFile()}
              >
                Add a File
              </Button>
            </label>
            <div style={{ flexGrow: 1 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                variant="contained"
                autoFocus
                color="primary"
                startIcon={<CloudUploadIcon />}
                style={{ color: "white", borderRadius: 50 }}
                onClick={submitUpload}
                disabled={
                  file.length === 0 ||
                  file[file.length - 1].status.trim() !== ""
                }
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={proceed}
        onClose={() => setProceed(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`We've detected ${
              data ? data.get_geofences.geofences.length : 0
            } geofence codes that already exist in your data, how would you like to continue?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button
            variant="outlined"
            onClick={() => setProceed(false)}
            style={{
              color: "#ffb677",
              borderRadius: 50,
              outlineColor: "#ffb677"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSkip()}
            style={{ color: "white", borderRadius: 50 }}
            variant="contained"
            color="primary"
          >
            Upload and Skip
          </Button>
          <Button
            onClick={() => {
              handleReplace();
            }}
            style={{ color: "white", borderRadius: 50 }}
            variant="contained"
            color="primary"
          >
            Upload and Replace
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function Geofences() {
  // const userDetails = useUserContext()
  // const userCookie = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const userLevelId = user.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const history = useHistory();
  const classes = geofenceStyles();
  const formStyles = useFormStyles();
  const contextData = useContext(SearchContext);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [clearFirst, setClearFirst] = useState(false);
  const [order, setOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [vars, setVars] = useState({});
  const [geofence, setGeofence] = useState([]);
  const [file, setFile] = useState([]);
  const initialValueHauler = isWTIAdmin
    ? { value: [30], label: "Webcast", id: 30 }
    : { value: [], label: "All" };
  const [tempFilter, setTempFilter] = useState({
    category: "",
    region: "",
    province: "",
    municipality: "",
    is_verified: "",
    hauler: initialValueHauler
  });
  const [statusProp, setStatusProps] = useState([]);
  const [filter, setFilter] = useState([]);
  const [canEdit, setcanEdit] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const location = useLocation();
  const { state } = location;
  const [keyLog, setKeyLog] = useState(
    "Category: All, Region: All, Province: All, Municipality: All, Geofence Status: All"
  );

  // const [clientFilter, setClientFilter] = useState({});
  // const [filterGroupId, setFilterGroupId] = useState([]);
  // const defaultGroupFilter = {
  //   id: user.group_ids[0],
  //   name: user.group_names[0],
  //   group_ids: [user.group_ids[0]]
  // };
  // const [groupFilter, setGroupFilter] = React.useState(defaultGroupFilter);
  // const [client, setClient] = React.useState([]);

  const haulerDefaultVal = isWTIAdmin
    ? { value: [30], label: "Webcast", id: 30 }
    : { value: user.client.group_ids, label: "All" };

  const [hauler, setHauler] = useState(haulerDefaultVal);
  const [haulerFilter, setHaulerFilter] = useState(haulerDefaultVal);

  // staste for standard/route geofence options
  const [openSR, setOpenSR] = React.useState(false);

  // // state for geofence_clone
  const [cloneDrawer, setCloneDrawer] = React.useState(false);

  // get the saved filter val
  const variable = history?.location?.state?.params;

  useEffect(() => {
    if (!isWTIAdmin) {
      setHaulerFilter(haulerDefaultVal);
      setHauler(haulerDefaultVal);
    }
  }, [user.client.group_ids]);

  const getVariables = () => {
    // let selectedHauler = { value: haulerDefaultVal };
    // if (isWTIAdmin) {
    //   const filterCopy = [...filter];
    //   const index = filterCopy.findIndex(f => f.field === "client_ids");
    //   if (index > -1) {
    //     selectedHauler = filterCopy.splice(index, 1)[0];
    //   }
    // }

    const variables = {
      keyword: keyword,
      keyword_fields: [
        "name",
        "geofence_code",
        "category",
        "region",
        "province",
        "municipality"
      ],
      skip: 0,
      first: rowsPerPage,
      // group_ids: clientFilter.group_ids || [],
      // group_ids: filterGroupId || [],
      orderBy: [{ field: orderBy, direction: order }],
      group_ids: haulerDefaultVal.value
      // group_ids:
      //   isWTIAdmin && selectedHauler?.value?.value
      //     ? [...selectedHauler.value.value]
      //     : userCookie.group_ids
    };

    // if (filterGroupId.length > 0) {
    //   Object.assign(variables, {
    //     group_ids: filterGroupId
    //   });
    // } else {
    //   Object.assign(variables, {
    //     group_ids: user.group_ids[0]
    //   });
    // }

    if (filter.length > 0) {
      const temp = [];
      const not = [];
      filter.map(value => {
        if (value?.field === "is_verified") {
          if (value.value === `${process.env.REACT_APP_STATUS_IS_VERIFIED}`) {
            temp.push(value);
          } else {
            not.push({
              field: "is_verified",
              value: `${process.env.REACT_APP_STATUS_IS_VERIFIED}`
            });
          }
        } else if (value?.field === "client_ids") {
          Object.assign(variables, {
            group_ids: value.value.value
          });
          // if (!isWTIAdmin)
          //   temp.push({ field: "client_ids", value: [`${value.value.id}`] });
        } else {
          temp.push(value);
        }
        return temp;
      });
      Object.assign(variables, { condition: temp, not: not });
    }
    return variables;
  };

  // useEffect(() => {
  //   if (userDetails.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
  //     console.log("isa lang dapat");
  //     getDropdownClients({
  //       variables: {
  //         keyword: userDetails.client.name,
  //         first: 5
  //       }
  //     });
  //   }
  // }, []);

  // const { loading: loadingClient, data: dataClient } = useQuery(
  //   GET_CLIENTS_QUERY,
  //   {
  //     variables: {
  //       first: 1
  //     }
  //     // onCompleted: data => {
  //     //   let index = -1;
  //     //   const clients = data.get_clients.clients.map(client => client.name);
  //     //   index = clients.indexOf("Webcast");
  //     //   if (index > -1) {
  //     //     setClientFilter(data.get_clients.clients[index]);
  //     //   }
  //     // }
  //   }
  // );

  const { loading: loadingData, error, data, fetchMore, refetch } = useQuery(
    GET_GEOFENCES_TABLE,
    {
      variables: getVariables(),
      onCompleted: () => {
        setLoading(false);
        if (keyword) {
          AddLogs("Admin - Geofences", "search", searchKeyword.value);
        }

        // for variables condition value in query
        const temp = variable?.vars?.condition;
        let category;
        let region;
        let province;
        let municipality;
        let verified;
        let notverified;
        let group;
        temp.forEach(geofil => {
          switch (geofil.field) {
            case "category":
              {
                category = { field: "category", value: geofil.value };
              }
              break;
            case "region":
              {
                region = { field: "region", value: geofil.value };
              }
              break;
            case "province":
              {
                province = { field: "province", value: geofil.value };
              }
              break;
            case "municipality":
              {
                municipality = { field: "municipality", value: geofil.value };
              }
              break;
            case "is_verified":
              {
                verified = {
                  field: "is_verified",
                  value: geofil.value
                };
              }
              break;
            default:
              break;
          }
        });

        // for variables group_ids value in query
        const tempGroup = variable?.filter;
        tempGroup.forEach(geogroup => {
          switch (geogroup.field) {
            case "client_ids":
              {
                group = {
                  field: geogroup.field,
                  value: geogroup.value
                  // geogroup.value[0] ||
                };
              }
              break;
            default:
              break;
          }
        });

        // for not verified status
        const notGroup = variable?.vars?.not;
        notGroup.forEach(notGroup => {
          notverified = "0";
          switch (notGroup.field) {
            case "is_verified":
              {
                verified = {
                  field: "is_verified",
                  value: notverified
                };
              }
              break;
            default:
              break;
          }
        });

        const removeNull = [
          category,
          group,
          region,
          province,
          municipality,
          verified
        ];
        // remove the null/undefined value for array
        setFilter(removeNull.filter(el => el !== null && el !== undefined));

        // set the value of filed in filters
        setTempFilter({
          category: category ? category.value : tempFilter.category,
          hauler: group ? group.value : tempFilter.hauler,
          region: region
            ? { ...region, name: region.value }
            : tempFilter.region,
          province: province
            ? { ...region, name: province.value }
            : tempFilter.province,
          municipality: municipality
            ? { ...region, name: municipality.value }
            : tempFilter.municipality,
          is_verified: verified
            ? verified.value
            : // {
              //     ...verified,
              //     name: verified.value
              //   }
              tempFilter.verified
        });

        // status value for geofence
        setStatusProps({
          name: notverified === "0" ? "Not Yet Verified" : "Verified"
        });
      },
      onError: () => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000
        });
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first"
    }
  );

  // reset the default value of filters
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: "Geofences"
        }
      }
    });
  };

  useEffect(() => {
    if (data) {
      setGeofence(data.get_geofences.geofences);
    }
  }, [data]);

  const handleOpenUpload = () => {
    setUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setUploadDialog(false);
    // fetch the updated geofences.
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_geofences: {
            count: fetchMoreResult.get_geofences.count,
            total: fetchMoreResult.get_geofences.total,
            geofences: [...fetchMoreResult.get_geofences.geofences]
          }
        };
      }
    });

    history.push({
      state: {
        hasUploaded: false,
        fileInfo: null,
        refresh: false,
        params: { moduleName: "Geofences" }
      }
    });
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_geofences: {
            count: fetchMoreResult.get_geofences.count,
            total: fetchMoreResult.get_geofences.total,
            geofences: [
              ...prev.get_geofences.geofences,
              ...fetchMoreResult.get_geofences.geofences
            ]
          }
        };
      }
    });
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpenDeleteAll = async () => {
    const assignedGeofence = await client.query({
      query: CHECK_GEOFENCE_ASSIGNMENT,
      variables: {
        geofence_ids: selected.map(geo => +geo.id)
      }
    });

    if (assignedGeofence?.data?.check_geofence_assignment?.error?.length) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Something went wrong."
      });
    } else if (
      assignedGeofence?.data?.check_geofence_assignment?.assigned.length
    ) {
      const { assigned } = assignedGeofence.data.check_geofence_assignment;
      const tempSelected = selected.map(geo => {
        return {
          ...geo,
          plateno_assigned:
            assigned.find(a => +a.geofence_id === +geo.id)?.vehicles || []
        };
      });

      setToDelete(tempSelected);
      setDialogDeleteAll(true);
    } else {
      setToDelete([]);
      setDialogDeleteAll(true);
    }
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleSingleDelete = async row => {
    const assignedGeofence = await client.query({
      query: CHECK_GEOFENCE_ASSIGNMENT,
      variables: {
        geofence_ids: [+row.id]
      }
    });

    if (assignedGeofence?.data?.check_geofence_assignment?.error?.length) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Something went wrong."
      });
    } else if (
      assignedGeofence?.data?.check_geofence_assignment?.assigned.length
    ) {
      const { assigned } = assignedGeofence.data.check_geofence_assignment;
      setToDelete([
        {
          id: row.id,
          name: row.name,
          plateno_assigned: assigned[0]?.vehicles
        }
      ]);
      handleOpen();
    } else {
      setToDelete([{ id: row.id, name: row.name }]);
      handleOpen();
    }
  };

  const handleFilters = e => {
    const { name, value } = e.target;

    switch (name) {
      case "region":
        setTempFilter({
          ...tempFilter,
          province: "",
          municipality: "",
          [name]: value
        });
        return;
      case "province":
        setTempFilter({ ...tempFilter, municipality: "", [name]: value });
        return;
      default:
        break;
    }
    setTempFilter({ ...tempFilter, [name]: value });
  };

  const handleSetFilters = () => {
    const {
      category,
      region,
      province,
      municipality,
      is_verified
    } = tempFilter;
    let g_status = "";
    if (is_verified === "1") {
      g_status = `Verified`;
    } else if (is_verified === "0") {
      g_status = `Not Yet Verified`;
    } else {
      g_status = `All`;
    }
    const filters = `Category: ${category ? `${category}` : `All`}, Region: ${
      region ? region.name : `All`
    }, Province: ${province ? province.name : `All`}, Municipality: ${
      municipality ? municipality.name : `All`
    }, Geofence Status: ${g_status}`;
    AddLogs("Admin - Geofences", "filter", filters);
    setKeyLog(filters);
    const temp = [];
    if (
      Object.keys(tempFilter)
        .filter(key => tempFilter[key])
        .some(key => {
          if (typeof tempFilter[key] === "string")
            return tempFilter[key] !== "";
          return Object.keys(tempFilter[key]).length !== 0;
        })
    ) {
      Object.keys(tempFilter)
        .filter(key => tempFilter[key])
        .filter(
          key => {
            if (key === "is_verified") {
              temp.push({
                field: `${key}`,
                value:
                  typeof tempFilter[key] === "string"
                    ? tempFilter[key]
                    : tempFilter[key].name
              });
            } else if (key === "hauler") {
              if (!isWTIAdmin) {
                if (tempFilter[key].label !== "All")
                  temp.push({
                    field: "client_ids",
                    value: tempFilter.hauler
                  });
              } else {
                temp.push({
                  field: "client_ids",
                  value: tempFilter.hauler
                });
              }
            } else {
              temp.push({
                field: key,
                value:
                  typeof tempFilter[key] === "string"
                    ? tempFilter[key]
                    : tempFilter[key].name
              });
            }
          }
          // (key === "is_verified" &&
          //   temp.push({
          //     field: `${key}`,
          //     value:
          //       typeof tempFilter[key] === "string"
          //         ? tempFilter[key]
          //         : tempFilter[key].name
          //   })) ||
          // temp.push({
          //   field: key,
          //   value:
          //     typeof tempFilter[key] === "string"
          //       ? tempFilter[key]
          //       : tempFilter[key].name
          // })
        );
    }
    setPage(1)
    setFilter(temp);
    closeFilterDrawer();
    // setHaulerFilter(hauler);
  };

  const handleClearFilter = () => {
    setFilter([]);
    setTempFilter({
      ...tempFilter,
      category: "",
      region: "",
      province: "",
      municipality: "",
      is_verified: "",
      hauler: initialValueHauler
    });
    setStatusProps([]);
    setHaulerFilter(haulerDefaultVal);
    setHauler(haulerDefaultVal);
  };

  const [deleteGeofence] = useMutation(DELETE_GEOFENCE, {
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  });

  const [deleteAllResponse, setDeleteAllResponse] = useState({
    deleted: 0,
    total: 0,
    notDeleted: []
  });

  const handleDelete = async deleteList => {
    const tempList = [];
    const notDeleted = [];

    // Delete geofence
    const deleteResult = await Promise.all(
      deleteList.map(row => {
        return deleteGeofence({
          variables: { id: row.id }
          // refetchQueries: ["GeofencesQuery"]
        }).then(res => {
          if (res.data) {
            const { delete_geofence } = res.data;

            // Get all names of successfully deleted geofences
            if (delete_geofence.success) {
              tempList.push(row.name);
            } else {
              notDeleted.push(row.name);
            }
            return delete_geofence;
          }
          notDeleted.push(row.name);
        });
      })
    );

    // If at least 1 geofence deleted, add activity logs
    if (tempList.length) {
      AddLogs("Admin - Geofences", "delete", tempList);
    }

    // Clear states
    handleClose();
    setSelected([]);
    setPage(1);

    // If all are deleted successfully, show success alert
    if (deleteResult.every(val => val?.success)) {
      Swal.fire({
        title: "Deleted",
        icon: "success",
        showConfirmButton: false,
        timer: 3000
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          setLoading(true);
          refetch(getVariables());
        } else {
          setLoading(true);
          refetch(getVariables());
        }
      });
    } else {
      // If some cannot be deleted, show delete dialog
      setDeleteAllResponse({
        total: deleteList.length,
        deleted: deleteResult.filter(e => e?.success).length,
        notDeleted
      });
      setDeleteDialog(true);
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteAllResponse({ total: 0, deleted: 0 });
    refetch(getVariables());
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const getData = () => {
    if (geofence) {
      return stableSort(geofence, getSorting(order, orderBy)).slice(
        Number(page - 1) * rowsPerPage,
        Number(page - 1) * rowsPerPage + rowsPerPage
      );
    }
    return stableSort(
      data.get_geofences.geofences,
      getSorting(order, orderBy)
    ).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData();
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData();
      const newSelected = tempSelected.filter(
        row => displayedData.indexOf(row) < 0
      );
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleStandardRouteModal = () => {
    setOpenSR(false);
  };

  const openCloneDrawer = () => {
    setCloneDrawer(true);
  };

  const standardRoute = openSR ? "Standard - Route" : undefined;

  const [rend, setRend] = useState(false);

  useEffect(() => {
    setRend(!rend);
  }, [selected]);

  const dataToArray = () => {
    const temp = [
      [
        "Client",
        "Geofence Name",
        "Geofence Code",
        "Category",
        "Latitude",
        "Longitude",
        "Radius",
        "Address",
        "Window time start",
        "Window time end"
      ]
    ];
    getData().map(row =>
      temp.push([
        row.group_names[0],
        row.name,
        row.geofence_code,
        row.category,
        row.location.lat,
        row.location.lon,
        row.radius || "",
        row.address,
        row.window_time_start,
        row.window_time_end
      ])
    );

    return temp;
  };

  useEffect(() => {
    setSearchKeyword(prev => ({
      ...prev,
      orderBy: [{ field: orderBy, direction: order }]
    }));
  }, [orderBy, order]);
  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({
        ...searchKeyword,
        value: "",
        group: { group_ids: [user.group_ids[0]] },
        orderBy: [{ field: orderBy, direction: order }]
      });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      // retain filter values when searching
      // setFilter([]);
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  useEffect(() => {
    if (data) {
      const { get_geofences } = data;
      setLoading(false);
      setTotalPage(
        get_geofences.total ? Math.ceil(get_geofences.total / rowsPerPage) : 1
      );
    }
  }, [data]);

  if (loadingData) {
    return <Loading />;
  }

  if (error?.networkError?.response) {
    return <ErrorPage errorCode={error?.networkError?.response} />;
  }
  return (
    <AccessControl
      resource="/admin/trip/geofences/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar>
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<FileCopy />}
              onClick={openCloneDrawer}
              style={{ backgroundColor: "#808080" }}
            >
              Clone
            </Button>
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                variant="contained"
                component="span"
                className={classes.btn_upload}
                startIcon={<PublishIcon />}
                onClick={handleOpenUpload}
              >
                Upload
              </Button>
            )}
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={openFilterDrawer}>
                <Badge
                  color="secondary"
                  variant={
                    getVariables()?.condition?.length ? "dot" : "standard"
                  }
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={
                data?.get_geofences?.geofences
                  ? data?.get_geofences?.geofences.length
                  : 0
              }
              fetchMoreData={fetchMoreData}
              totalRows={data?.get_geofences?.total || 0}
              totalPage={totalPage}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              setLoading={setLoading}
            />
          </EnhancedTableToolbar>
          {selected.length > 0 && (
            <Paper
              style={{
                display: "flex",
                backgroundColor: "#eaeaea",
                paddingLeft: 16,
                position: "relative",
                minHeight: "64px",
                alignItems: "center"
              }}
              elevation={0}
              square
            >
              <Typography>
                {selected.length} geofence/s is selected on this page
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  borderRadius: 100,
                  color: "white"
                }}
                onClick={handleOpenDeleteAll}
              >
                Delete Selected
              </Button>
            </Paper>
          )}
          {/* {loadingClient || loading ? ( */}
          {loading ? (
            <Loading />
          ) : (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data?.get_geofences?.total}
                  handleSort={handleSort}
                  data={data?.get_geofences?.geofences}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  selected={selected}
                  isChecked={isChecked}
                  canEdit={canEdit}
                />
                <TableBody>
                  {geofence &&
                    getData().map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const isRouteGeofence = row.category === "Route";

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              disabled={!canEdit}
                              onClick={event => handleClick(event, row)}
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                            width={120}
                          >
                            <Tooltip
                              title={
                                row.is_verified > 0 ? "Verified" : " Unverified"
                              }
                              placement="top"
                            >
                              <Brightness1
                                className={
                                  row.is_verified > 0
                                    ? classes.verified
                                    : classes.unverified
                                }
                              />
                            </Tooltip>
                            {moment(row.created).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell>
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.name}
                            </Highlight>
                          </TableCell>
                          <TableCell>
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.geofence_code}
                            </Highlight>
                          </TableCell>
                          <TableCell>{row.category}</TableCell>
                          <TableCell>
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.region}
                            </Highlight>
                          </TableCell>
                          <TableCell>
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.province}
                            </Highlight>
                          </TableCell>
                          <TableCell>
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.municipality}
                            </Highlight>
                          </TableCell>
                          <TableCell>
                            <Link
                              style={{ color: "#757575" }}
                              aria-label="Back"
                              to={{
                                // save the filter values
                                pathname: isRouteGeofence
                                  ? `/admin/trip/routegeofences/view/${row.id}`
                                  : `/admin/trip/geofences/view/${row.id}`,
                                state: {
                                  vars: getVariables(),
                                  filter: filter
                                }
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  // setSearchKeyword({
                                  //   ...searchKeyword,
                                  //   value: ""
                                  // });
                                  AddLogs(
                                    "Admin - Geofences",
                                    "view",
                                    row.name
                                  );
                                  // AddLogs(
                                  //   isRouteGeofence
                                  //     ? "geofence"
                                  //     : "Admin - Geofences",
                                  //   "view",
                                  //   row.name
                                  // );
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                            <IconButton
                              disabled={!canEdit}
                              onClick={() => handleSingleDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {data?.get_geofences?.count === 0 && (
                <div
                  style={{
                    width: "100%",
                    height: 72,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography>No data found</Typography>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>
        {canEdit && (
          <>
            <Fab size="large" color="primary" className={classes.fab}>
              <AddIcon
                aria-describedby={standardRoute}
                style={{
                  width: "50px",
                  height: "50px",
                  color: "white"
                }}
                onClick={e => {
                  // setSearchKeyword({ ...searchKeyword, value: "" });
                  setOpenSR(e.currentTarget);
                }}
              />
            </Fab>
            <Popover
              classes={{ paper: classes.standardRoute }}
              id={standardRoute}
              open={openSR}
              anchorEl={openSR}
              onClose={handleStandardRouteModal}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <Grid container spacing={2} style={{ padding: "15px" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    style={{ lineHeight: 1, fontWeight: "bold" }}
                  >
                    Select Geofence
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 0 }}>
                    Select the type of geofence you want to create.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link
                    className={classes.fabIcon}
                    to={{
                      // save the filter values
                      pathname: "/admin/trip/geofences/add",
                      state: {
                        vars: getVariables(),
                        filter: filter
                      }
                    }}
                  >
                    <Card className={classes.card}>
                      <div className={classes.divCard}>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            variant="subtitle1"
                            style={{ lineHeight: 1, fontWeight: "bold" }}
                          >
                            Standard Geofence
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ lineHeight: 0 }}
                          >
                            Establish and define geofence boundaries to specify
                            the desired point of interest and speed limits for
                            vehicles.
                            {/* Set an area in the map where you want to monitor the
                            vehicle's location. */}
                          </Typography>
                        </CardContent>
                      </div>
                      <CardMedia
                        className={classes.standardGeofence}
                        image={require("../../../../assets/Standard.png")}
                        title="Standard Geofence"
                      />
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link
                    className={classes.fabIcon}
                    // Route for route geofence module
                    to={{
                      // save the filter values
                      pathname: "/admin/trip/geofences/addRouteGeofence",
                      state: {
                        vars: getVariables(),
                        filter: filter
                      }
                    }}
                  >
                    <Card className={classes.card}>
                      <div className={classes.divCard}>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            variant="subtitle1"
                            style={{ lineHeight: 1, fontWeight: "bold" }}
                          >
                            Route Geofence
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ lineHeight: 0 }}
                          >
                            Create a route geofence that specifies the start and
                            end points of vehicles, including speed, and date
                            range.
                            {/* Set a start/end point in the map where you want to
                            monitor the vehicle's speed. */}
                          </Typography>
                        </CardContent>
                      </div>
                      <CardMedia
                        className={classes.routeGeofence}
                        image={require("../../../../assets/Route.png")}
                        title="Route Geofence"
                      />
                    </Card>
                  </Link>
                </Grid>
              </Grid>
            </Popover>
          </>
        )}
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <Autocomplete
            options={geofenceTypes.map(geofence => geofence.label)}
            getOptionLabel={option => option || ""}
            getOptionSelected={(o, v) => {
              if (v === o) return o;
              return { category: "" };
            }}
            style={{ width: "100%" }}
            renderInput={params => (
              <TextField
                {...params}
                classes={{ root: formStyles.textFieldRoot }}
                label={
                  <>
                    Category <span style={{ color: "red" }}>*</span>
                  </>
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                placeholder="All"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    padding: "6px 0px"
                  }
                }}
              />
            )}
            name="category"
            onChange={(e, nv) => {
              setTempFilter({ ...tempFilter, category: nv });
              clearHistory();
            }}
            value={tempFilter.category}
            disableClearable
          />
          <RegionSelect
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
            handleFilters={handleFilters}
            clearHistory={() => clearHistory()}
          />
          <ProvinceSelect
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
            handleFilters={handleFilters}
            clearHistory={() => clearHistory()}
          />
          <MunicipalitySelect
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
            handleFilters={handleFilters}
            clearHistory={() => clearHistory()}
          />
          <GeofenceStatus
            tempFilter={tempFilter}
            setTempFilter={setTempFilter}
            handleFilters={handleFilters}
            statusProp={statusProp}
            setStatusProps={setStatusProps}
            clearHistory={() => clearHistory()}
          />
          <HaulerGroupSelector
            hauler={tempFilter.hauler}
            setHauler={newHauler => {
              // console.log(newHauler)
              setTempFilter(prev => ({ ...prev, hauler: newHauler }));
            }} /* hauler={hauler} setHauler={setHauler} */
          />
        </Filter>
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={() => handleDelete(toDelete)}
          title="Delete?"
          content={
            toDelete[0]?.plateno_assigned
              ? `This geofence is currently assigned to vehicle (${toDelete[0]?.plateno_assigned?.join(
                  ", "
                )}). Are you sure you want to delete this geofence?`
              : "Are you sure you want to delete this geofence?"
          }
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={() => handleDelete(selected)}
          title="Delete?"
          content={
            !toDelete.length ? (
              "Are you sure you want to delete these geofences?"
            ) : (
              <>
                The following geofences are assigned to a vehicle. Are you sure
                you want to delete these geofences?
                <div className={classes.table_modal}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tablehead_modal}>
                          Geofence
                        </TableCell>
                        <TableCell className={classes.tablehead_modal}>
                          Vehicle Assigned
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  <div className={classes.tablebody_modal}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableBody>
                        {toDelete
                          .filter(s => s.plateno_assigned?.length)
                          .map(geo => {
                            return geo.plateno_assigned.map(vehicle => (
                              <TableRow>
                                <TableCell
                                  style={{ width: "132px", fontSize: "13px" }}
                                >
                                  {geo.name}
                                </TableCell>
                                <TableCell style={{ fontSize: "13px" }}>
                                  {vehicle}
                                </TableCell>
                              </TableRow>
                            ));
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </>
            )
          }
        />
        <DeleteDialog
          toggle={deleteDialog}
          close={() => handleDeleteDialogClose()}
          title="Warning!"
          // content="Cannot delete. This geofence is currently in use."
          content="The following geofences have ongoing trips and cannot be deleted."
          deleteAllResponse={deleteAllResponse}
        />
        <UploadDialog
          uploadDialog={uploadDialog}
          setUploadDialog={setUploadDialog}
          handleCloseUpload={handleCloseUpload}
          refetch={refetch}
          getVariables={vars}
          setVars={setVars}
          state={state}
          file={file}
          setFile={setFile}
        />
        <CloneGeofences
          cloneDrawer={cloneDrawer}
          setCloneDrawer={setCloneDrawer}
          orderBy={orderBy}
          isChecked={() => isChecked}
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleClosePopper={handleClosePopper}
            handleListKeyDown={handleListKeyDown}
            tableData={dataToArray()}
            keyLog={keyLog}
          />
        )}
      </div>
    </AccessControl>
  );
}
