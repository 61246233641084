import React, { useEffect, useImperativeHandle, useState } from "react";
import ChipInput from "../../../Report/common/ChipInput/ChipInput";
import { GET_GEOFENCE_QUERY } from "../../../../graphql/Queries";
import { useQuery } from "@apollo/client";
import debounce from "../../../../utils/functions/debounce.util";
import { useFilterStyles } from "./Filter.styles";
import _ from "lodash";

// geofence name/code chip input
const SelectGeoChipInput = React.forwardRef((props, ref) => {
  const { initialValues = [], type, groups = [] } = props;
  const readOnly = !type;
  const [selectedItems, setSelectedItems] = useState(initialValues);
  const [keyword, setKeyword] = useState("");
  const styles = useFilterStyles();

  useImperativeHandle(ref, () => ({
    clearSelected: () => setSelectedItems([]),
    getSelected: () => selectedItems
  }));

  const queryType = type => {
    if (_.isEqual(type, "Geofence Code")) return "geofence_code";
    else if (_.isEqual(type, "Geofence Name")) return "name";
    return null;
  };

  const getVars = () => {
    let vars = {
      first: 20,
      group_ids: groups
    };
    if (keyword) {
      vars = {
        ...vars,
        keyword: keyword,
        keyword_fields: [queryType(type)]
      };
    }
    return vars;
  };

  const { loading, data, refetch } = useQuery(GET_GEOFENCE_QUERY, {
    variables: getVars(),
    skip: !type
  });

  const options =
    data?.get_geofences?.geofences.map(geo => ({
      id: geo.id,
      name: geo[queryType(type)]
    })) || [];

  const handleKeywordChanged = event => setKeyword(event.target.value);
  const debouncedKeywordChanged = debounce(handleKeywordChanged, 250);

  const autocompleteProps = {
    // 💡 Override Autocomplete props
    renderInput: ({ getTextfieldProps }) => ({
      ...getTextfieldProps({
        label: "",
        placeholder: selectedItems?.length > 0 ? "" : "All",
        onChange: debouncedKeywordChanged,
        onBlur: () => setKeyword(""),
        InputProps: {
          disabled: readOnly
        }
      })
    }),
    disabled: readOnly,
    getOptionLabel: option => option.name,
    getOptionSelected: (option, value) => option.id === value.id,
    getOptionDisabled: option => {
      return (
        selectedItems.length >= 20 &&
        selectedItems.findIndex(element => element.id === option.id) === -1
      );
    }
  };

  const handleSelectedChange = changes => {
    const newSelectedValues = [...changes.selectedValues];
    ref.current = newSelectedValues;
    setSelectedItems(newSelectedValues);
  };

  useEffect(() => {
    return () => {
      ref.current = initialValues;
    };
  }, []);

  return (
    <ChipInput
      classes={styles}
      loading={loading}
      options={options}
      selectedValues={selectedItems}
      onSelectedChanged={handleSelectedChange}
      autocompleteProps={autocompleteProps}
    />
  );
});

export default SelectGeoChipInput;
