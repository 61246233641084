import { makeStyles } from "@material-ui/core";

export default makeStyles({
  root: {},
  containerRoot: {
    border: "1px solid #ccc",
    borderRadius: "12px",
    marginTop: "12px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box"
  }
});
