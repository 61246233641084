import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  CircularProgress
} from "@material-ui/core";
import {
  AttachFile as AttachFileIcon,
  CloudUpload as CloudUploadIcon,
  Publish as PublishIcon
} from "@material-ui/icons";
import React, { useState, useRef } from "react";
import xlsx from "xlsx";
import Document from "./Document";
import useUploadStyles from "./upload.styles";

export const isValidFileSize = (fileSize = 0) => {
  return !(fileSize / 1024 / 1024 > 5);
};

export const isValidTemplate = (col1, col2) => {
  return col1.length === col2.length;
};

export const rules = {
  minLength: 0,
  maxLength: Infinity
};

export const status = {
  READING: "Reading",
  OVERRIDING: "Overriding",
  SCANNED: "Scanned",
  WARNING: "Warning",
  FAILED: "Failed",
  PARTIAL_SUCCESS: "Partial Success",
  SUCCESS: "Success",
  UPLOADING: "Uploading"
};

export const parseExcelData = (dataRes, columns) => {
  const workbook = xlsx.read(dataRes, { type: "array" });
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  // https://github.com/SheetJS/sheetjs/issues/1078#issuecomment-1340167505
  const rawData = xlsx.utils.sheet_to_json(worksheet, {
    // header: 1,
    blankrows: true,
    defval: ""
  });

  const filtered = rawData.filter(row =>
    Object.values(row).some(v => v !== "")
  );

  const rows = filtered.map(row => {
    const temp = {};
    Object.keys(row).forEach(col => {
      if (Object.keys(columns).includes(col)) {
        temp[col] = row[col];
      }
    });

    return temp;
  });

  return rows;
};

export const sortByRow = documents => {
  return documents.sort((a, b) => {
    if (a.row < b.row) return -1;
    if (a.row > b.row) return 1;
    return 0;
  });
};

const Upload = props => {
  const {
    open,
    columns = {},
    validateTemplate = isValidTemplate,
    fileRules = rules,
    onDialogClose,
    loading = false
  } = props;
  const [documents, setDocuments] = useState([]);
  const [overrideValidity, setOverrideValidity] = useState(false);
  const [forOverride, setForOverride] = useState([]);
  const inputElementRef = useRef(null);
  const classes = useUploadStyles();

  const handleUploadFile = event => {
    const currentFile = [...event.target.files];
    event.target.value = null; // This line of code is allowing us to add same file.
    // const { files: currentFile } = currentTarget;
    const blob = currentFile[0];
    if (!blob) return;
    setOverrideValidity(false);
    setForOverride([]);
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadstart = async () => {
      setDocuments(prevDocs => [
        ...prevDocs,
        {
          file: currentFile,
          status: status.READING,
          progress: 0,
          errors: { file: "", rows: {} },
          completed: false
        }
      ]);
    };
    reader.onload = async e => {
      // When you experience lagging/hanging while uploading a large file,
      // please refer to this documentation:
      // https://docs.sheetjs.com/docs/demos/worker/
      const dataRes = new Uint8Array(e.target.result);
      const workbook = xlsx.read(dataRes, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false
      });

      const fileCols = data[0] || [];
      let fileError = "";
      if (!isValidFileSize(blob.size)) {
        fileError = "File is too large.";
      }
      if (!validateTemplate(fileCols, Object.keys(columns))) {
        fileError = "Invalid Template. Please use the template provided";
      }
      if (data.length - 1 < fileRules.minLength) {
        fileError = "Invalid Template. Please use the template provided";
      }
      if (data.length - 1 > fileRules.maxLength) {
        fileError = `Maximum number of rows in a file should be ${1000} only`;
      }

      setDocuments(prevDocs => {
        const lastDocument = prevDocs[prevDocs.length - 1];
        return prevDocs.map(doc => {
          if (doc === lastDocument) {
            Object.assign(doc, {
              status: fileError ? status.FAILED : status.SCANNED,
              errors: {
                file: fileError
              }
            });
          }

          return doc;
        });
      });
    };

    reader.onprogress = async progressEvent => {
      if (progressEvent.lengthComputable) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setDocuments(prevDocs => {
          const lastDocument = prevDocs[prevDocs.length - 1];
          return prevDocs.map(doc => {
            if (doc === lastDocument) {
              Object.assign(doc, {
                progress
              });
            }

            return doc;
          });
        });
      }
    };
    reader.onerror = async e => {};
  };

  const submitUpload = () => {
    const reader = new FileReader();
    const blob = documents[documents.length - 1].file[0];
    reader.readAsArrayBuffer(blob);
    reader.onloadstart = async () => {
      setDocuments(prevDocs => {
        const lastDocument = prevDocs[prevDocs.length - 1];
        return prevDocs.map(doc => {
          if (doc === lastDocument) {
            lastDocument.status = status.UPLOADING;
          }

          return doc;
        });
      });
    };
    reader.onload = async e => {
      // When you experience lagging/hanging while uploading a large file,
      // please refer to this documentation:
      // https://docs.sheetjs.com/docs/demos/worker/
      const dataRes = new Uint8Array(e.target.result);
      const rows = parseExcelData(dataRes, columns);

      // Field validations here!
      const validRowsObject = [];
      const fieldErrors = [];
      // const columnsArray = rawData[0];

      for (let row = 0; row <= rows.length - 1; row++) {
        // Gets the column values (Array)
        const cols = rows[row];
        const result = props.validate({
          row,
          data: Object.keys(cols).reduce((accum, colKey) => {
            // Column name based in file
            // const columnName = columnsArray[index];
            // Key based on mutation(?)
            const key = columns[colKey];
            accum[key] = cols[colKey];
            return accum;
          }, {})
        });

        const { errors, data } = result;
        if (!Object.keys(errors).length) validRowsObject.push({ row, data });

        Object.keys(errors).forEach(key => {
          fieldErrors.push({
            row,
            data,
            field: key,
            message: errors[key],
            override: false
          });
        });
      }

      const { errors: mutationErrors } = await props.onSubmit({
        validRowsObject
      });

      // Merge `field errors (local)` and `mutation errors (server response)`.
      const newFieldErrors = [...fieldErrors, ...mutationErrors];

      setOverrideValidity(() => {
        if (props.validForOverride) {
          return typeof props.validForOverride === "function"
            ? props.validForOverride({ mutationReponse: newFieldErrors })
            : props.validForOverride;
        }

        return newFieldErrors.some(error => error.override);
      });

      setDocuments(prevDocs => {
        // Gets the last document
        const lastDocument = prevDocs[prevDocs.length - 1];
        // Sets the errors of the last document
        lastDocument.errors.fields = newFieldErrors;

        return prevDocs.map(doc => {
          // If the current value of `doc` from the `map iterator` is equal
          // to the last document in the list of `documents`
          // then apply necessary changes.
          if (doc === lastDocument) {
            // Sets the default status to `scanned`
            let documentStatus = status.SCANNED;
            // Triggers `getDocumentStatus` if user/dev implemented it.
            if (typeof props.getDocumentStatus === "function")
              documentStatus = props.getDocumentStatus({
                document: lastDocument,
                rawData: rows
              });
            else {
              // Default handling of status
              // Checking the number of errors
              const numberOfErrors = Object.keys(
                lastDocument.errors.fields.reduce((accum, current) => {
                  if (!accum[current.row]) {
                    accum[current.row] = "__placeholder__";
                  }
                  return accum;
                }, {})
              ).length;

              if (numberOfErrors === 0) documentStatus = status.SUCCESS;
              else if (rows.length === numberOfErrors)
                documentStatus = status.FAILED;
              else if (rows.length !== numberOfErrors)
                documentStatus = status.PARTIAL_SUCCESS;
            }

            // Updates the `last document`
            Object.assign(doc, {
              status: documentStatus,
              errors: lastDocument.errors,
              completed: lastDocument.errors.fields.length === 0
            });
          }
          return doc;
        });
      });
    };
  };

  const handleOverride = () => {
    const reader = new FileReader();
    const blob = documents[documents.length - 1].file[0];
    reader.readAsArrayBuffer(blob);
    reader.onloadstart = () => {
      setDocuments(prevDocs => {
        const lastDocument = prevDocs[prevDocs.length - 1];
        return prevDocs.map(doc => {
          if (doc === lastDocument) {
            lastDocument.status = status.OVERRIDING;
          }

          return doc;
        });
      });
    };
    reader.onload = async e => {
      const dataRes = new Uint8Array(e.target.result);
      const rows = parseExcelData(dataRes, columns);

      // eslint-disable-next-line no-unused-expressions
      const { errors } = await props?.onOverride?.(forOverride);

      setDocuments(prevDocuments => {
        const lastDocument = prevDocuments[prevDocuments.length - 1];
        return prevDocuments.map(doc => {
          if (doc === lastDocument) {
            // This block of code is for updating the list of errors
            const currentFieldErr = [...doc.errors.fields];
            let parsedErrors = [];

            if (errors.length === 0) {
              // Errors from override mutation doesn't return any error
              parsedErrors = currentFieldErr.filter(currentError => {
                // Searching for rows to remove in previous errors
                const index = forOverride.findIndex(o => {
                  return (
                    o.row === currentError.row // && o.field === currentError.field
                  );
                });

                // if `currentError.row` is existing in previous errors (or previous request payload))
                // it will be removed in the error list.
                return !(index > -1);
              });
            } else {
              // If override mutation returns list of errors
              // This block of code will removes errors from the override mutation when it matches a row
              // with any of the items in the `errors` array
              const remainingErrors = currentFieldErr.filter(fieldErr => {
                const index = errors.findIndex(
                  error => error.row === fieldErr.row
                );
                const overrideIndex = forOverride.findIndex(
                  fo => fo.row === fieldErr.row
                );
                return index === -1 && overrideIndex === -1;
              });

              // Merges the remaining errors and errors from mutation
              parsedErrors = [...remainingErrors, ...errors];
            }

            doc.errors.fields = parsedErrors;

            let documentStatus = status.OVERRIDING;
            if (typeof props.getDocumentStatus === "function")
              documentStatus = props.getDocumentStatus({
                document: doc,
                rawData: rows
              });
            else {
              // Default handling of status
              // Checking the number of errors
              const numberOfErrors = Object.keys(
                lastDocument.errors.fields.reduce((accum, current) => {
                  if (!accum[current.row]) {
                    accum[current.row] = "__placeholder__";
                  }
                  return accum;
                }, {})
              ).length;

              if (numberOfErrors === 0) documentStatus = status.SUCCESS;
              else if (rows.length === numberOfErrors)
                documentStatus = status.FAILED;
              else if (rows.length !== numberOfErrors)
                documentStatus = status.PARTIAL_SUCCESS;
            }

            doc.completed = true;
            doc.status = documentStatus;
          }
          return doc;
        });
      });
      setOverrideValidity(false);
      setForOverride([]);
    };
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="customized-dialog-title"
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title">Upload</DialogTitle>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContent,
          dividers: classes.dialogContent
        }}
      >
        {documents.length === 0 ? (
          <PublishIcon className={classes.publishIcon} />
        ) : (
          documents.map((fileInfo, index) => {
            const isLastDocument = index === documents.length - 1;
            if (props.renderDocument) {
              return props.renderDocument({
                fileInfo,
                overrideValidity,
                index,
                forOverride,
                setForOverride,
                getForOverrides: props.getForOverrides
              });
            }

            return (
              <Document
                key={index}
                fileInfo={fileInfo}
                overrideValidity={overrideValidity}
                onSelectedChanged={newSelected => {
                  setForOverride(() => {
                    if (props.getForOverrides)
                      return props.getForOverrides(newSelected);

                    return newSelected;
                  });
                }}
                activeDocument={isLastDocument}
              />
            );
          })
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%" }}>
          <label htmlFor="icon-upload-file">
            <input
              ref={inputElementRef}
              className="form-control"
              color="primary"
              accept=".csv, .xlsx, .xls"
              multiple={false}
              type="file"
              id="icon-upload-file-v2"
              style={{ display: "none" }}
              onChange={e => handleUploadFile(e)}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              // startIcon={<AttachFileIcon fontSize="small" />}
              onClick={() => inputElementRef.current.click()}
              disabled={loading}
            >
              <Typography variant="body2">Add a File</Typography>
            </Button>
          </label>
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {overrideValidity ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={
                  loading ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : (
                    <CloudUploadIcon fontSize="small" />
                  )
                }
                onClick={handleOverride}
                disabled={!forOverride?.length || loading}
              >
                Override
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={
                  loading ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : (
                    <CloudUploadIcon fontSize="small" />
                  )
                }
                onClick={submitUpload}
                disabled={
                  documents.length === 0 ||
                  loading ||
                  documents[documents.length - 1]?.status === status.FAILED ||
                  documents[documents.length - 1]?.completed
                }
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Upload;
