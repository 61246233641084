import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  body: {
    height: "calc(100% - 64px)",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 220px))",
    gridGap: "34px",
    padding: 15,
    gridAutoRows: "minmax(220px, 230px)",
    boxSizing: "border-box",
    overflow: "auto",
    paddingLeft: "35px",
    "&::-webkit-scrollbar-track": {
      display: "none"
    },
    "&::-webkit-scrollbar-thumb": {
      borderColor: "#EAEAEA"
    },
  },
  footer: {
    //margin: theme.spacing(1),
    position: "fixed",
    bottom: 0,
    width: "calc(100% - 90px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    //backgroundColor: "none",
    paddingLeft: "20px",
    paddingBottom: "5px"
  },
  no_item_container: {
    height: "calc(100% - 64px)"
  },
  no_item: {
    position: "relative",
    height: "fit-content",
    width: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 14,
    fontWeight: 600,
    color: "#b8b8b8",
    letterSpacing: "0.25px"
  },
  addIcon: {
    width: "50px",
    height: "50px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  viewButton: {
    borderRadius: "20px",
    textTransform: "none",
    padding: "0px 50px",
    marginRight: "200px"
  },
  selected: {
    color: "black",
    textTransform: "none",
    borderStyle: "solid",
    borderRadius: "4px",
    border: "1px",
    borderColor: "black",
    padding: "5px 10px"
  },
  checkbox: {
    marginBottom: "-8px",
    marginLeft: "-28px",
  },
}));
