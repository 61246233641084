/* eslint-disable no-nested-ternary */
import React from "react";
import { DragHandleRounded } from "@material-ui/icons";
import DraggableListItem from "./DraggableItem";
// Styles
import useDraggableListStyles from "./DraggableList.styles";

/**
 * @todo
 * 1. Convert this component into Controlled Props.
 */

const compose = (...fns) => (...args) => fns.forEach(fn => fn && fn(args));

const DraggableList = props => {
  const {
    values = [],
    onArrangementChanged = () => {},
    getItemDisabled = () => false,
    getOptionLabel = () => undefined,
    renderItem,
    renderEmptyField,
    label,
    readOnly = false
  } = props;

  const styles = useDraggableListStyles({ values });
  const [items, setItems] = React.useState(values);
  const dragStart = React.useRef(null);

  const onDragStartHandler = React.useCallback(index => {
    dragStart.current = index;
  }, []);

  React.useEffect(() => {
    // const sameLength = items.length === values.length;
    // if (!sameLength) setItems(values);
    // if (sameLength) {
    //   const noChanges = items.every(
    //     (item, index) => getOptionLabel(item) === getOptionLabel(values[index])
    //   );

    //   if (!noChanges) setItems(values);

    // }

    setItems(values);
  }, [values]);
  React.useEffect(() => onArrangementChanged(items), [items]);

  const onDropHandler = React.useCallback(
    dropIndex => {
      /**
       * @params dropIndex - The index where the item is dropped
       * @return void
       */

      setItems(prevItems => {
        // Gets the draged item
        const draggedItem = prevItems[dragStart.current];
        // Duplicates the items from state
        const list = [...prevItems];
        // Delete draged item in list
        list.splice(dragStart.current, 1);
        // Update list
        if (dragStart.current < dropIndex) {
          return [
            ...list.slice(0, dropIndex - 1),
            draggedItem,
            ...list.slice(dropIndex - 1, list.length)
          ];
        }
        return [
          ...list.slice(0, dropIndex),
          draggedItem,
          ...list.slice(dropIndex, list.length)
        ];
      });
    },
    [setItems, dragStart.current]
  );

  const getDraggableItemProps = (item, index) => {
    return ({
      onDragStartHandler: onDragStartHandlerProp,
      onDropHandler: onDropHandlerProp,
      ...otherProps
    } = {}) => ({
      index,
      key: index,
      styles,
      draggable: !getItemDisabled(item), // true
      hidden: false,
      onDragStartHandler: compose(onDragStartHandler, onDragStartHandlerProp),
      onDropHandler: compose(onDropHandler, onDropHandlerProp),
      ...otherProps
    });
  };

  const getDraggableListHelpers = (item, index) => {
    return {
      getDraggableItemProps: getDraggableItemProps(item, index)
    };
  };

  const getLabelProps = ({ classNames = [] }) => ({
    className: `${styles.label} ${classNames.join(" ")}`
  });

  const getEmptyComponentProps = ({
    classes = { emptyContainerClasses: [], emptyTextClasses: [] }
  } = {}) => ({
    emptyContainerClasses: {
      className: `${styles.emptyContainer} ${classes.emptyContainerClasses.join(
        " "
      )}`
    },
    emptyTextClasses: {
      className: `${styles.emptyText} ${classes.emptyTextClasses.join(" ")}`
    }
  });

  const renderLabel = () => {
    return typeof label === "function" ? label({ getLabelProps }) : label;
  };

  return (
    <div className={`${styles.wrapper} ${readOnly && styles.readOnly}`}>
      <div className={styles.title}>
        {renderLabel() || <span className={styles.label}>Reorder Column</span>}
      </div>
      {items.length === 0 ? (
        renderEmptyField ? (
          renderEmptyField({ getEmptyComponentProps })
        ) : (
          <div className={styles.emptyContainer}>
            <span className={styles.emptyText}>This field is empty</span>
          </div>
        )
      ) : (
        items.map((item, index) => {
          const defaultLabel = item[Object.keys(item)[0]];
          return renderItem ? (
            <DraggableListItem
              key={`${defaultLabel}-${index}`}
              {...getDraggableItemProps(item, index)()}
            >
              {renderItem(item, { ...getDraggableListHelpers(item, index) })}
            </DraggableListItem>
          ) : (
            <DraggableListItem {...getDraggableItemProps(item, index)()}>
              <span>{item.label || getOptionLabel(item) || defaultLabel}</span>
              <DragHandleRounded
                classes={{
                  root: styles.draggableIcon
                }}
              />
            </DraggableListItem>
          );
        })
      )}
      {/* {items.map((item, index) => {
        const defaultLabel = item[Object.keys(item)[0]];
        return renderItem ? (
          <DraggableListItem
            key={`${defaultLabel}-${index}`}
            {...getDraggableItemProps(item, index)()}
          >
            {renderItem(item, { ...getDraggableListHelpers(item, index) })}
          </DraggableListItem>
        ) : (
          <DraggableListItem {...getDraggableItemProps(item, index)()}>
            <span>{item.label || getOptionLabel(item) || defaultLabel}</span>
            <DragHandleRounded
              classes={{
                root: styles.draggableIcon
              }}
            />
          </DraggableListItem>
        );
      })} */}

      <DraggableListItem
        draggable={false}
        // eslint-disable-next-line react/jsx-boolean-value
        hidden={true}
        key={items.length}
        index={items.length}
        onDropHandler={onDropHandler}
      />
    </div>
  );
};

export default DraggableList;
