import React, { useState } from "react";
import {
  Typography,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  TextField,
  Button
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from "@material-ui/icons";

export default function Pagination(props) {
  const {
    rowsPerPage,
    page,
    setPage,
    dataLength,
    fetchMoreData,
    totalRows,
    totalPage,
    refetch,
    getVariables,
    setRowsPerPage,
    // setCircularLoading
    setLoading
  } = props;

  const [tempPage, setTempPage] = useState(page);

  React.useEffect(() => {
    setTempPage(page);
  }, [page]);

  // Function used to select the number of data to be display in the data table [10, 20, 30, 40, 50]
  const handlePagination = event => {
    // set value for rows per page
    setRowsPerPage(event.target.value);
    // set page to default value
    setPage(1);
    // refetch the query with the given variables
    setLoading(true);
    refetch(getVariables());
  };

  // Function used to block other inputs beside number/integer
  const handleKeyPress = event => {
    // get the event value based on keyCode or which
    const keyCode = event.keyCode || event.which;
    // convert variable keyCode into a string
    const keyValue = String.fromCharCode(keyCode);
    // check if variable keyValue is NOT number or integer
    if (/[^0-9]/g.test(keyValue)) {
      // if true
      // blocked the value of keyValue
      event.preventDefault();
      if (keyCode === 13) {
        const { value } = event.target;
        if (+value >= totalPage) handlePage(totalPage);
        else if (+value <= 0) handlePage(1);
        else handlePage(value);
      }
    }
  };

  const handlePageChange = e => {
    setTempPage(+e.target.value);
  };

  // Function used to navigate using input value
  const handlePage = value => {
    // const { value } = e.target;
    // check if page is less than the inputted value
    if (page < value) {
      // check if the input value is greater than the total page
      if (value > Math.ceil(totalRows / rowsPerPage)) {
        // if true
        setPage(1);
      } else {
        // if false
        // loop the input value to request the data without skipping other page
        for (let i = 1; i <= value; i += 1) {
          // check if the value is already fetch to the server
          if (
            i * rowsPerPage > dataLength &&
            (i - 1) * rowsPerPage >= dataLength
          ) {
            // setCircularLoading(true);
            setLoading(true);
            fetchMoreData(i - 1);
          }
        }
        // set page based on user's input
        setPage(value);
      }
    } else {
      // check if the input value is zero
      if (Number(value) === 0) {
        // set page to default
        setPage(1);
      } else {
        // set page based on user's input
        setPage(value);
      }
    }
  };

  // Function used to navigate to next page
  const handleUpPage = () => {
    // set page to current page incremented by 1
    setPage(Number(page) + 1);
    const nextPage = page;
    // check if the requested page data is already fetch
    if (
      nextPage * rowsPerPage === dataLength ||
      nextPage * rowsPerPage >= dataLength
    ) {
      // if true
      // call the function fetchMoreData
      // setCircularLoading(true);
      setLoading(true);
      fetchMoreData(nextPage);
    }
  };

  // Function used to navigate to previous page
  const handleDownPage = () => {
    // set page to previous page value
    setPage(page - 1);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography>Rows per page:</Typography>
      <FormControl>
        <Select
          value={rowsPerPage}
          onChange={e => handlePagination(e)}
          displayEmpty
          name="rows_per_page"
          style={{ padding: 0, margin: "0 8px" }}
          disableUnderline
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        name="down_page"
        disabled={Number(page) <= 1}
        onClick={() => handleDownPage()}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <TextField
        name="page"
        readOnly
        onFocus={e => {
          e.target.select();
        }}
        // onChange={e => handlePage(e)}
        onBlur={() => setTempPage(page)}
        onChange={handlePageChange}
        onKeyPress={e => handleKeyPress(e)}
        variant="outlined"
        inputProps={{ style: { padding: 0, textAlign: "center" } }}
        value={tempPage}
        style={{ width: "50px", marginRight: "8px" }}
      />
      {`/ ${totalPage || 1}`}
      <Button
        name="up_page"
        disabled={Number(page) >= totalPage}
        onClick={() => handleUpPage()}
      >
        <KeyboardArrowRightIcon />
      </Button>
    </div>
  );
}
