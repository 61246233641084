import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";

const SearchBox = props => {
  const {
    mapState: { geom },
    setMapState,
    state,
    setState
  } = props;
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState(state?.address);

  useEffect(() => {
    setAddress(state.address);
  }, [state.address]);

  const handleChange = e => {
    setAddress(e.target.value);
  };

  const getInfo = place => {
    if (!place?.geometry) return;
    if (!geom) {
      setState(prev => ({
        ...prev,
        location: {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
        }
      }));
    } else {
      setMapState(prev => ({
        ...prev,
        mapCenter: {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
        }
      }));
    }
  };

  return (
    <>
      <Autocomplete
        onLoad={e => {
          setAutocomplete(e);
        }}
        onPlaceChanged={() => {
          getInfo(autocomplete.getPlace());
          setAddress(autocomplete.getPlace().formatted_address);
        }}
        fields={["formatted_address", "geometry"]}
        componentRestrictions={{ country: "ph" }}
        restrictions={{ country: "ph" }}
      >
        <input
          type="text"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`
          }}
          placeholder="Search Address"
          value={address}
          name="address"
          onChange={e => handleChange(e)}
        />
      </Autocomplete>
    </>
  );
};

export default SearchBox;
