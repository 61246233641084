import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loading: {
    width: 300,
    height: 300
  }
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.loading }}
    >
      <DialogContent>
        <Grid container style={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress size={100} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            Wait for a few seconds...
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
