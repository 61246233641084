import _ from "lodash";
import moment from "moment";

// CloseTrip serves reset trip details to default
const closeTrip = (tripData, closeTripMutation, addNotif) => {
  if (!_.isEmpty(tripData.rawTripData)) {
    const for_saving = [];
    const { rawTripData } = tripData;
    if( rawTripData.length >= 0 ) {
      // undefined
      _.forEach(rawTripData, trips => {
        _.forEach(trips.value, trip => {
          if(trip.id) {
            for_saving.push({
              id: trip.id,
              fields: {
                trip_number: trip.trip_number,
                status_code_id: process.env.REACT_APP_STATUS_TRIP_CLOSED,
                status_code: "Closed",
                closed_stamp: moment()
                  .utc(+8)
                  .format("YYYY-MM-DD HH:mm:ss"),
              }
            });
          }
        });
      });
    } else {
      _.forEach(rawTripData, trips => {
        _.forEach(trips, trip => {
          if(trip.id) {
            for_saving.push({
              id: trip.id,
              fields: {
                trip_number: trip.trip_number,
                status_code_id: process.env.REACT_APP_STATUS_TRIP_CLOSED,
                status_code: "Closed",
                closed_stamp: moment()
                  .utc(+8)
                  .format("YYYY-MM-DD HH:mm:ss"),
              }
            });
          }
        });
      });
    }

    closeTripMutation({ variables: { bookings: for_saving } });
    
    if( rawTripData.length >= 0 ) {
      _.forEach(rawTripData, trips => {
        addNotif({
          variables: {
            notification: {
              client_id: [trips.value[0].client_id],
              trip_number: trips.value[0].trip_number,
              vehicle_plate_no: trips.value[0].vehicle_plate_no || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Trip ${trips.value[0].trip_number} has been closed.`,
              category: "Trip",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: trips.value[0].group_ids,
              group_names: trips.value[0].group_names
            }
          }
        });
        addNotif({
          variables: {
            notification: {
              client_id: [trips.value[0].client_id],
              trip_number: trips.value[0].trip_number,
              vehicle_plate_no: trips.value[0].vehicle_plate_no || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Closed ${trips.value[0].trip_number}`,
              category: "User Activity",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: trips.value[0].group_ids,
              group_names: trips.value[0].group_names
            }
          }
        });
      });
    } else {
      _.forEach(rawTripData, trip => {
        addNotif({
          variables: {
            notification: {
              client_id: [trip[0].client_id],
              trip_number: trip[0].trip_number,
              vehicle_plate_no: trip[0].vehicle_plate_no || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Trip ${trip[0].trip_number} has been closed.`,
              category: "Trip",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: trip[0].group_ids,
              group_names: trip[0].group_names
            }
          }
        });
        addNotif({
          variables: {
            notification: {
              client_id: [trip[0].client_id],
              trip_number: trip[0].trip_number,
              vehicle_plate_no: trip[0].vehicle_plate_no || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Closed ${trip[0].trip_number}`,
              category: "User Activity",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: trip[0].group_ids,
              group_names: trip[0].group_names
            }
          }
        });
      });
    }
    // addNotif({
    //   variables: {
    //     notification: {
    //       client_id: [tripData.rawTripData.value[0].client_id],
    //       trip_number: tripData.rawTripData.value[0].trip_number,
    //       vehicle_plate_no:
    //         tripData.rawTripData.value[0].vehicle_plate_no || "",
    //       datestamp: moment()
    //         .format("YYYY-MM-DD HH:mm:ss"),
    //       notification: `Trip ${tripData.rawTripData.value[0].trip_number} has been closed.`,
    //       category: "Trip",
    //       is_archived: false,
    //       creator: "Tmsuite-web",
    //       group_ids: rawTripData.value[0].group_ids,
    //       group_names: rawTripData.value[0].group_names
    //     }
    //   }
    // });
  }
};

export default closeTrip;
