import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  typography: {
    fontSize: "12px",
    margin: "0",
  },
  showOrHide: {
    cursor: "pointer",
    color: "#c4c4c4",
    marginLeft: "0.25rem",
  }
}));
