import { makeStyles } from "@material-ui/core";

export default makeStyles({
  link: {
    position: "fixed",
    bottom: "30px",
    right: "30px"
  },
  button: {
    background: "#3ba4f7",
    padding: "20px",
    "& .MuiIconButton-label": {
      color: "white"
    }
  }
});
