import React, { useState, useEffect } from "react";
import {
  Modal,
  styled,
  Fade,
  Typography,
  IconButton,
  Box,
  Button
} from "@material-ui/core";
import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon
} from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { GET_VEHICLE_INFOS } from "../../../../../../graphql/Queries";
import SearchVehicles from "./SearchVehicles";
import VehicleTable from "./VehicleTable";
import useDebounce from "../../../../../../utils/hooks/useDebounce";

const ModalVehicles = props => {
  const { isOpen, modalFn, setVehicles, setSelected, vehicles } = props;
  const groupId = +localStorage.getItem("groupId");
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [order, setOrder] = useState(false);
  const [searchedVehicle, setSearchedVehicle] = useDebounce("", 800);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehiclePlateno, setVehiclePlateno] = useState([]);
  const rowsPerpage = 5;
  const qryVariables = {
    first: rowsPerpage,
    keyword: searchedVehicle,
    orderBy: [
      { field: "vehicle_info.plateno", direction: order ? "desc" : "asc" }
    ],
    not: [{ field: "vehicle_info.plateno", value: vehiclePlateno }],
    group_ids: [groupId]
  };

  const { data, loading, fetchMore, refetch } = useQuery(GET_VEHICLE_INFOS, {
    variables: qryVariables,
    onCompleted: res => {
      if (res) {
        const vehiclesTemp = [];
        const { vehicles } = res.get_vehicles;
        vehicles.forEach(item =>
          vehiclesTemp.push({ id: item.id, plateno: item.plateno })
        );
        setAvailableVehicles(vehiclesTemp);
        setTotalPage(Math.ceil(res.get_vehicles.total / rowsPerpage));
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const tblPageHandler = isNext => {
    fetchMore({
      variables: {
        skip: isNext ? (currentPage + 1) * 5 : (currentPage - 1) * 5,
        ...qryVariables
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const vehiclesTemp = [];
        fetchMoreResult.get_vehicles.vehicles.forEach(item =>
          vehiclesTemp.push({ id: item.id, plateno: item.plateno })
        );

        setAvailableVehicles(vehiclesTemp);
        setCurrentPage(prev => (isNext ? prev + 1 : prev - 1));
      }
    });
  };

  const addVehicleHandler = () => {
    setVehicles([...vehicles, ...selectedVehicles]);
    setSelected([...vehicles, ...selectedVehicles]);
    modalFn();
  };

  useEffect(() => {
    const vehiclesPlateNo = [];
    vehicles.map(vehicle => vehiclesPlateNo.push(vehicle.plateno));
    setVehiclePlateno(vehiclesPlateNo);
  }, [vehicles]);

  return (
    <>
      <ModalStyled open={isOpen} onClose={modalFn}>
        <Fade in={isOpen}>
          <ModalBody>
            <ModalHeaderTitle>Add Entry</ModalHeaderTitle>
            <ContainerStyled style={{ gap: 10 }}>
              <SearchVehicles setSearchedVehicle={setSearchedVehicle} />
              <ContainerStyled style={{ gap: 5 }}>
                <IconButton
                  disabled={!currentPage}
                  onClick={() => tblPageHandler(false)}
                  size="small"
                >
                  <ArrowBackIosRoundedIcon style={{ fontSize: "1rem" }} />
                </IconButton>
                <TypographyStyled>
                  {currentPage + 1} / {totalPage}
                </TypographyStyled>
                <IconButton onClick={() => tblPageHandler(true)} size="small">
                  <ArrowForwardIosRoundedIcon style={{ fontSize: "1rem" }} />
                </IconButton>
              </ContainerStyled>
            </ContainerStyled>

            <VehicleTable
              data={availableVehicles}
              isAddEntryShown={false}
              order={order}
              setOrder={setOrder}
              isLoading={loading}
              setVehicles
              currentPage={currentPage}
              selectedVehicles={selectedVehicles}
              setSelectedVehicles={setSelectedVehicles}
              isEditMode={true}
            />
            <ButtonContainerStyled gripGap={3}>
              <ErrButtonStyled onClick={modalFn} size="small" disableElevation>
                Cancel
              </ErrButtonStyled>
              <ButtonStyled
                onClick={addVehicleHandler}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
              >
                Add
              </ButtonStyled>
            </ButtonContainerStyled>
          </ModalBody>
        </Fade>
      </ModalStyled>
    </>
  );
};

export default ModalVehicles;

const ModalStyled = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 12,
  whiteSpace: "nowrap"
}));

const ModalBody = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  minWidth: "30%",
  minHeight: 300
}));

const ModalHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 17,
  fontWeight: 600,
  letterSpacing: "1.25px"
}));

const ErrButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 50,
  marginLeft: 10,
  minWidth: 100,
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 50,
  marginLeft: 10,
  minWidth: 100,
  "&:hover": {
    backgroundColor: theme.palette.primary.main
  }
}));

const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: 20
}));
