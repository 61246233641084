/* global google */
import { property } from "lodash";
import React, { useEffect } from "react";

const SmartDeadspot = props => {
  const { mapRef, overlays } = props;
  var EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];

  const xyzToBounds = (x, y, z) => {
    var tileSize = (EXTENT[1] * 2) / Math.pow(2, z);
    var minx = EXTENT[0] + x * tileSize;
    var maxx = EXTENT[0] + (x + 1) * tileSize;
    // remember y orig in starts at top
    var miny = EXTENT[1] - (y + 1) * tileSize;
    var maxy = EXTENT[1] - y * tileSize;

    return [minx, miny, maxx, maxy];
  };

  var getTilesGeo = function(coordinates, zoom) {
    const geo = process.env.REACT_APP_GEOSERVER_URL;
    const authkey =
      "access=" +
      process.env.REACT_APP_GEOSERVER_ACCESS_LAYER +
      "&SERVICE=WMS" +
      "&VERSION=1.1.1" +
      "&REQUEST=GetMap" +
      "&FORMAT=image/png" +
      "&TRANSPARENT=true" +
      "&STYLES" +
      "&LAYERS=" +
      process.env.REACT_APP_GEOSERVER_SMART_LAYER +
      "&SRS=EPSG:3857" +
      "&WIDTH=768&HEIGHT=378" +
      "&BBOX=" +
      xyzToBounds(coordinates.x, coordinates.y, zoom).join(",");
    const request = new URLSearchParams(authkey).toString();
    const encryptAuth = geo + request;

    return encryptAuth;
  };

  useEffect(() => {
    let deadSpot;
    if (mapRef) {
      deadSpot = new google.maps.ImageMapType({
        getTileUrl: getTilesGeo,
        name: "Smart Deadspot",
        alt: "Deadspot Signal",
        minZoom: 0,
        maxZoom: 19,
        opacity: 0.75
      });
      mapRef.current.overlayMapTypes.push(deadSpot);
    }
    return () => {
      const deadSpotIndex = mapRef.current.overlayMapTypes
        .getArray()
        .findIndex(layer => layer.name === "Smart Deadspot");
      mapRef.current.overlayMapTypes.removeAt(deadSpotIndex);
    };
  }, []);

  return null;
};

export default SmartDeadspot;
