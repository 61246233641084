/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import Sidebar from "./Sidebar";
import Users from "./Users";
// import { GET_ACCESS_OBJECTS } from "../../graphql/Queries";
import SearchContext from "../../../../context/SearchContext";
import data from "./modules.data";
import Focus from "../../common/Focus";
import Stage from "../../common/Stage/Stage";
import { useOnboardingContext } from "../..";
import admin_welcome from "../../images/admin-generate-vehicle-data.svg";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "100%"
  },
  sidebar: {
    flexShrink: 0,
    height: "100%"
  }
}));

export default function Index() {
  const classes = useStyles();
  // const history = useHistory();
  // const user = JSON.parse(Cookie.get("user"));
  const {
    progress: { admin: currentStep },
    completeOnboarding
  } = useOnboardingContext();
  const [sidebarVal, setSidebarVal] = useState("");
  const [expanded, setExpanded] = useState("RESOURCES SETTINGS");
  const [resource, setResource] = useState("");
  const { setSearchKeyword } = useContext(SearchContext);

  const stage = {
    0: {
      image: admin_welcome,
      title: "Admin: Generate Vehicle Data",
      description:
        "Manage and organized vehicle-related resources and geofences",
      buttonText: "Got It"
    },
    11: {
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };

  const handleChanges = (text, res) => {
    setSearchKeyword(prev => {
      const isDifferentModule = prev.moduleName !== text;
      if (isDifferentModule) {
        return {
          ...prev,
          moduleName: text,
          value: "",
          submit: false
        };
      }

      return prev;
    });
    // setSearchKeyword(prev => ({ ...prev, moduleName: text }));
    setSidebarVal(text);
    // history.push({pathname: res});
    setResource(res);
  };

  const setAdminLandingPage = () => {
    if (data) {
      data
        .filter(
          modules =>
            modules.parent_id === "0" &&
            modules.resource.split("/")[1] === "admin" &&
            modules.permission.view
        )
        .some(modules => {
          const submodule = data.filter(
            v => v.parent_id === modules.id && v.permission.view
          );
          // console.log(submodule[0].name, submodule[0].resource);
          handleChanges(submodule[0].name, submodule[0].resource);
          return true;
        });
    }
  };

  useEffect(() => {
    setAdminLandingPage();
  }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(location.state)) {
  //     setSidebarVal(location.state.params.moduleName);
  //     if (
  //       location.state.params.moduleName === process.env.REACT_APP_USERS_MODULE
  //     ) {
  //       setExpanded("RESOURCES SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_VEHICLES_MODULE
  //     ) {
  //       setExpanded("RESOURCES SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_VEHICLETYPES_MODULE
  //     ) {
  //       setExpanded("RESOURCES SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_PERSONNEL_MODULE
  //     ) {
  //       setExpanded("RESOURCES SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_HAULERS_MODULE
  //     ) {
  //       setExpanded("TRIPS SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_SERVICETYPES_MODULE
  //     ) {
  //       setExpanded("TRIPS SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName === process.env.REACT_APP_ISSUES_MODULE
  //     ) {
  //       setExpanded("TRIPS SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_CLIENTS_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_GPSDEVICES_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName === process.env.REACT_APP_RIGHTS_MODULE
  //     ) {
  //       setExpanded("ACTIVITY SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName === process.env.REACT_APP_ALERTS_MODULE
  //     ) {
  //       setExpanded("ACTIVITY SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_FEEDBACK_MESSAGE
  //     ) {
  //       setExpanded("ACTIVITY SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_CONTROL_ROOM_MODULE
  //     ) {
  //       setExpanded("ACTIVITY SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_GEOFENCES_MODULE
  //     ) {
  //       setExpanded("TRIPS SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_GPS_PROVIDER_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_ACTIVITY_LOGS_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //     if (
  //       location.state.params.moduleName ===
  //       process.env.REACT_APP_ALERT_REFERENCES_MODULE
  //     ) {
  //       setExpanded("WTI SETTINGS");
  //     }
  //   }
  // }, [location.state]);

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <Sidebar
          sidebarVal={sidebarVal}
          handleChanges={handleChanges}
          expanded={expanded}
          setExpanded={setExpanded}
          resource={resource}
          setResource={setResource}
        />
      </div>
      <div className={classes.content}>
        <Users />
      </div>
      <Focus
        step={[0, 11]}
        on={[0, 11].includes(currentStep)}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: [0, 11].includes(currentStep) ? "block" : "none",
          background: "transparent"
        }}
        showInfoBubble={false}
      >
        <Stage {...stage[currentStep]} />
      </Focus>
    </div>
  );
}
