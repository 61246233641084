// import { gql } from "apollo-boost";
import { gql } from "@apollo/client";

export const SAMPLE_QUERY = gql`
  {
    get_vehicles_count(
      dateRange: { start: "2019-12-16 00:00:00", end: "2019-12-16 23:59:59" }
    ) {
      total
      per_type {
        total
      }
    }
  }
`;

export const GET_AVAILABLE_VEHICLES_QUERY = gql`
  query get_available_vehicles(
    $dateRange: DateRangeInput
    $orderBy: [OrderByInput]
    $filter: [VehicleSearchInput]
    $first: Int
    $skip: Int
  ) {
    get_available_vehicles(
      dateRange: $dateRange
      orderBy: $orderBy
      first: $first
      filter: $filter
      skip: $skip
    ) {
      total
      count
      vehicles {
        id
        #name
        devicealias
        client_name
        device_type
        device_type_id
        device_status {
          status_code
          status_code_id
          reportstamp
          battery_level
        }
        device_config {
          gps_provider
        }
        vehicle_info {
          id
          vehicle_type
          vehicle_type_id
          status_code
          status_code_id
          plateno
        }
        trips {
          trip_number
          status_code
        }
      }
    }
  }
`;

export const GET_GEOFENCE_QUERY = gql`
  query geofence(
    $keyword_fields: [String]
    $first: Int
    $filter: [DynamicSearchInput]
    $group_ids: [Int]
    $keyword: String
  ) {
    get_geofences(
      keyword_fields: $keyword_fields
      first: $first
      filter: $filter
      group_ids: $group_ids
      keyword: $keyword
    ) {
      geofences {
        id
        name
        address
        geofence_code
        location {
          lat
          lon
        }
      }
    }
  }
`;

export const GET_CLIENTS_QUERY = gql`
  query clients(
    $condition: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
    $first: Int
    $keyword: String
  ) {
    get_clients(
      condition: $condition
      first: $first
      keyword: $keyword
      filter: $filter
    ) {
      clients {
        id
        name
        client_type
        group_ids
        group_names
      }
    }
  }
`;

export const GET_CLIENTS_QUERY_WO_GROUPS = gql`
  query clients(
    $condition: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
    $first: Int
    $keyword: String
  ) {
    get_clients(
      condition: $condition
      first: $first
      keyword: $keyword
      filter: $filter
    ) {
      clients {
        id
        name
        client_type
        clients_codes{
          id
          code
          remarks
        }
      }
    }
  }
`;

export const CHECK_TOKEN_QUERY = gql`
  query CheckToken($token: String!) {
    check_reset_token(token: $token) {
      success
      error
    }
  }
`;

export const GET_BOOKING_GROUPS = gql`
  query getBookingGroups($dateRange: DateRangeInputV2, $group_ids: [Int]) {
    get_booking_groups(dateRange: $dateRange, group_ids: $group_ids) {
      group_id
      group_name
    }
  }
`;

export const GET_USER = gql`
  query get_user($id: ID!) {
    get_user(id: $id) {
      profile_image
      id
      username
      first_name
      last_name
      remarks
      email_address
      contact_number
      group_ids
      group_names
      profile_image
      is_blocked
      created
      activity_start
      activity_end
      user_level_id
      user_level_name
      password_expiry
      account_expiration
      is_auto_logout
      created
      modified
      client {
        id
        name
        is_restricted
        alert_sounds {
          alert_level0_sound
          alert_level1_sound
          alert_level2_sound
          alert_level3_sound
        }
        group_ids
        group_names
      }
      geofence_id
      geofence_name
      onboarding {
        monitoring
        prebooking
        trips
        dashboard
        reports
        feedback
        admin
      }
    }
  }
`;

export const GET_REPORT_TYPES_QUERY = gql`
  query reports($filter: [DynamicSearchInput]) {
    get_report_types(filter: $filter) {
      id
      name
      headers
    }
  }
`;

export const GET_HAULER_GROUPS = gql`
  query {
    get_booking_groups {
      group_id
      group_name
    }
  }
`;

export const GET_REPORT_REQUESTS_QUERY = gql`
  query getReportRequests(
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInput
  ) {
    get_report_requests(
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      total
      count
      report_requests {
        id
        user_id
        user_name
        created
        report_type
        report_type_id
        startstamp
        endstamp
        group_ids
        status_code
        status_code_id
        filename
        is_downloaded
        remarks
        start_destination_id
        end_destination_id
      }
    }
  }
`;

export const GET_NOTIFICATIONS_QUERY = gql`
  query GetNotifications(
    $filter: [DynamicSearchInput]
    $dateRange: DateRangeInput
    $system_notifs: [DynamicSearchInput]
    $gps_notifs: [DynamicSearchInput]
    $unread_filter: [DynamicSearchInput]
    $skip: Int
  ) {
    get_notifications(
      filter: $filter
      first: 10
      skip: $skip
      dateRange: $dateRange
      orderBy: [
        { field: "created", direction: "desc" }
        { field: "is_urgent", direction: "desc" }
      ]
    ) {
      count
      total
      notifications {
        id
        created
        notification
        trip_number
        is_urgent
        is_read
        is_archived
        client_id
        category
        vehicle_plate_no
        datestamp
      }
    }
    system_notifications: get_notifications(
      filter: $system_notifs
      first: 10
      skip: $skip
      dateRange: $dateRange
      orderBy: [
        { field: "created", direction: "desc" }
        { field: "is_urgent", direction: "desc" }
      ]
    ) {
      count
      total
      notifications {
        id
        notification
        created
        trip_number
        is_urgent
        is_read
        is_archived
        client_id
        category
        vehicle_plate_no
        datestamp
      }
    }
    gps_notifications: get_notifications(
      filter: $gps_notifs
      first: 10
      skip: $skip
      dateRange: $dateRange
      orderBy: [
        { field: "created", direction: "desc" }
        { field: "is_urgent", direction: "desc" }
      ]
    ) {
      count
      total
      notifications {
        id
        notification
        created
        trip_number
        is_urgent
        is_read
        is_archived
        client_id
        category
        vehicle_plate_no
        datestamp
        trucker_id
        booking_id
        geofence_id
        do_number
        vehicle_id
        group_ids
        group_names
        user_level_id
        location {
          lat
          lon
        }
        driver_name
        temperature
        speed
        alert_level
        alert_code
      }
    }

    total_unread: get_notifications(
      # filter: [...$filter, { field: "is_read", value: "false" }]
      filter: $unread_filter
      dateRange: $dateRange
    ) {
      total
    }
  }
`;

// start of admin

export const GET_ACCESS_OBJECTS = gql`
  query GetAccessObjects($user_level_id: ID) {
    get_access_objects(user_level_id: $user_level_id) {
      id
      name
      resource
      parent_id
      action_only
      permission {
        view
        edit
      }
    }
  }
`;

export const GET_USERS = gql`
  query UsersQuery(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateFilterAnd: [DateFilterInput]
    $not_like: [DynamicSearchInput]
  ) {
    get_users(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateFilterAnd: $dateFilterAnd
      not_like: $not_like
    ) {
      users {
        id
        username
        first_name
        last_name
        email_address
        user_level_id
        user_level_name
        contact_number
        account_expiration
        modified
        geofence_id
        geofence_name
        client {
          id
          name
          is_restricted
        }
        is_blocked
        group_names
      }
      total
      count
    }
  }
`;

export const GET_USER_LEVELS = gql`
  query UserLevelsQuery(
    $first: Int
    $skip: Int
    $condition: [SearchInput]
    $keyword: String
    $orderBy: [OrderByInput]
    $group_ids: [Int]
    $not: [DynamicSearchInput]
  ) {
    get_user_levels(
      first: $first
      skip: $skip
      condition: $condition
      keyword: $keyword
      orderBy: $orderBy
      group_ids: $group_ids
      not: $not
    ) {
      userlevels {
        id
        name
        description
        created
      }
      total
      count
    }
  }
`;

export const GET_USER_LEVEL = gql`
  query UserLevelQuery($id: ID) {
    get_user_level(id: $id) {
      id
      name
      description
      created
      acl_module_id
      acl_module
      acl_module_resource
      client_id
    }
  }
`;

export const CHECK_PASSWORD = gql`
  query checkPass($username: String!, $password: String!) {
    check_password(username: $username, password: $password)
  }
`;

export const GET_GEOFENCES = gql`
  query GeofencesQuery(
    $filter: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $condition: [DynamicSearchInput]
    $orderBy: [OrderByInput]
    $keyword: String
    $bbox: [PointGeometryInput]
    $not: [DynamicSearchInput]
    $group_ids: [Int]
    $keyword_fields: [String]
  ) {
    get_geofences(
      filter: $filter
      skip: $skip
      first: $first
      condition: $condition
      orderBy: $orderBy
      keyword: $keyword
      bbox: $bbox
      not: $not
      group_ids: $group_ids
      keyword_fields: $keyword_fields
    ) {
      geofences {
        id
        name
        address
        geofence_code
        geom
        # wkb_geometry {
        #   type
        #   coordinates
        # }
        location {
          lat
          lon
        }
        type
        category
        barangay
        municipality
        province
        region
        group_ids
        group_names
        window_time_end
        window_time_start
        radius
        client_ids
        client_names
        color
        opacity
        is_verified
        geofence_geom_id
        created
      }
      total
      count
    }
  }
`;

export const GET_GEOFENCES_TABLE = gql`
  query GeofencesQuery(
    $filter: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $condition: [DynamicSearchInput]
    $orderBy: [OrderByInput]
    $keyword: String
    $bbox: [PointGeometryInput]
    $not: [DynamicSearchInput]
    $group_ids: [Int]
    $keyword_fields: [String]
  ) {
    get_geofences(
      filter: $filter
      skip: $skip
      first: $first
      condition: $condition
      orderBy: $orderBy
      keyword: $keyword
      bbox: $bbox
      not: $not
      group_ids: $group_ids
      keyword_fields: $keyword_fields
    ) {
      geofences {
        id
        name
        #address
        geofence_code
        geom
        # wkb_geometry {
        #   type
        #   coordinates
        # }
        location {
          lat
          lon
        }
        type
        category
        barangay
        municipality
        province
        region
        group_ids
        group_names
        window_time_end
        window_time_start
        radius
        client_ids
        client_names
        color
        opacity
        is_verified
        geofence_geom_id
        created
        line_geom
      }
      total
      count
    }
  }
`;

export const GET_GEOFENCE = gql`
  query GetGeofenceQuery($id: ID!) {
    get_geofence(id: $id) {
      id
      name
      category
      barangay
      municipality
      province
      region
      address
      type
      radius
      window_time_start
      window_time_end
      group_ids
      group_names
      geom
      # wkb_geometry {
      #   type
      #   coordinates
      # }
      geofence_code
      location {
        lat
        lon
      }
      opacity
      color
      is_verified
      geofence_geom_id
      speed_limit
    }
  }
`;

export const GET_ROUTEGEOFENCE = gql`
  query GetGeofenceQuery($id: ID!) {
    get_geofence(id: $id) {
      id
      name
      category
      address
      group_ids
      geom
      line_geom
      line_buffer
      speed_limit
      geofence_code
    }
  }
`;

export const GET_REVERSE_GEOCODE = gql`
  query GetReverseGeocode($latitude: Float!, $longitude: Float) {
    get_reverse_geocode(latitude: $latitude, longitude: $longitude) {
      barangay
      municipality
      province
      region
    }
  }
`;

export const IS_ALLOWED = gql`
  query isAllowed($user_id: ID!, $resource: String!, $permission: String) {
    is_allowed(user_id: $user_id, resource: $resource, permission: $permission)
  }
`;

export const ALLOWED_PERMISSIONS = gql`
  query isAllowed($user_id: ID!, $resource: String!) {
    allowed_permissions(user_id: $user_id, resource: $resource) {
      view
      edit
    }
  }
`;

export const GET_ALERT_REFERENCES = gql`
  query getAlerts(
    $keyword: String
    $first: Int
    $skip: Int
    $filter: [SearchInput]
    $orderBy: [OrderByInput]
    $condition: [SearchInput]
  ) {
    get_alert_references(
      keyword: $keyword
      first: $first
      skip: $skip
      filter: $filter
      orderBy: $orderBy
      condition: $condition
    ) {
      count
      total
      alert_references {
        id
        level
        alertmessage
        alert_description {
          id
          description
          code
        }
        code
        alert_type
        description
        group_ids
        device_type {
          id
          parent_id
          name
          description
        }
      }
    }
  }
`;

export const NOTIF_ALERT_REFERENCES = gql`
  query getAlerts(
    $keyword: String
    $first: Int
    $skip: Int
    $filter: [SearchInput]
    $orderBy: [OrderByInput]
    $condition: [SearchInput]
  ) {
    get_alert_references(
      keyword: $keyword
      first: $first
      skip: $skip
      filter: $filter
      orderBy: $orderBy
      condition: $condition
    ) {
      count
    }
  }
`;

export const GET_STATUS_CODES = gql`
  query GetStatusCodes($filter: [DynamicSearchInput]) {
    get_status_codes(filter: $filter) {
      id
      name
      label: name
    }
  }
`;

export const GET_ALERT_REFERENCE = gql`
  query getAlert($id: ID, $group_ids: String) {
    get_alert_reference(id: $id, group_ids: $group_ids) {
      id
      alert_description {
        id
        description
        code
      }
      code
      description
      level
      alertmessage
      group_ids
      group_names
      alert_type
      sound_on
      recipients {
        user_level_id
        mobile_number
        email_address
        group_ids
      }
      device_type {
        id
        parent_id
        name
        description
      }
      operator
      event_value
      frequency
      time_start
      time_end
      vehicles {
        id
        plateno
      }
    }
  }
`;

export const GET_POLITICAL_BOUNDS = gql`
  query getPolBounds(
    $type: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
  ) {
    get_political_boundaries(
      type: $type
      filter: $filter
      condition: $condition
    ) {
      code
      name
    }
  }
`;

export const GET_CLIENTS = gql`
  query getClients {
    get_clients {
      id
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroups(
    $first: Int
    $skip: Int
    $keyword: String
    $filter: [SearchInput]
    $not: [DynamicSearchInput]
    $condition: [SearchInput]
    $or: [DynamicSearchInput]
  ) {
    get_groups(
      first: $first
      skip: $skip
      keyword: $keyword
      not: $not
      filter: $filter
      condition: $condition
      or: $or
    ) {
      groups {
        id
        name
      }
      count
      total
    }
  }
`;

export const CHECK_TOKEN = gql`
  query checkToken($token: String!) {
    check_reset_token(token: $token) {
      success
      error
    }
  }
`;

export const GET_DEVICES = gql`
  query getDevices(
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $keyword: String
    $group_ids: [Int]
    $dateRange: DateRangeInput
    $not: [VehicleSearchInput]
  ) {
    get_devices(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
      keyword: $keyword
      group_ids: $group_ids
      dateRange: $dateRange
      not: $not
    ) {
      devices {
        id
        name
        devicealias
        mobile_imei
        device_type
        device_type_id
        client_id
        vehicle_info {
          id
          plateno
          icon
          vehicle_type
          trip_number
          under_repair
          not_in_use
          ignition_status
          door_status
          has_fuel_sensor
          has_temp_sensor
          client_id
          personnel_name
        }
        device_status {
          latitude
          longitude
          reportstamp
          heading
          status_code
          status_code_id
          temperature
          gpsSpeed
          battery_level
          AlertReferences {
            level
            alert_code
          }
          lastlocation
        }
        device_config {
          gps_provider
          gps_provider_id
          hastempsensor
          aux_voltage
          intervalreporting
          battery_intervalreporting
        }
        mirror_to_ngt
      }
      total
      count
    }
  }
`;

export const NEW_GET_DEVICES = gql`
  query getDevices(
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $keyword: String
    $group_ids: [Int]
    $dateRange: DateRangeInput
    $not: [VehicleSearchInput]
  ) {
    get_devices(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
      keyword: $keyword
      group_ids: $group_ids
      dateRange: $dateRange
      not: $not
    ) {
      devices {
        name
      }
    }
  }
`;

export const GET_DEVICES_MAPVIEW = gql`
  query getDevices(
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $keyword: String
    $group_ids: [Int]
    $dateRange: DateRangeInput
    $not: [VehicleSearchInput]
  ) {
    get_devices(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
      keyword: $keyword
      group_ids: $group_ids
      dateRange: $dateRange
      not: $not
    ) {
      devices {
        id
        name
        device_status {
          heading
          latitude
          longitude
          powersource
          #status_code
          #status_code_id
          #reportstamp
          #battery_level
          #distancetraveled
          #gpsSpeed
          #temperature
          #lastlocation
          AlertReferences {
            level
            alert_code
            alertmessage
          }
        }
        vehicle_info {
          #id
          #under_repair
          #not_in_use
          ignition_status
          #door_status
          #has_fuel_sensor
          #has_temp_sensor
          #client_id
          #personnel_name
          #plateno
          trip_number
        }
        #device_type_id
        client_id
        group_ids
        #devicealias
      }
      total
      count
    }
  }
`;

export const GET_INFO_BUBBLE_DEVICES_MAPVIEW = gql`
  query getDevices(
    $condition: [VehicleSearchInput]
    $dateRange: DateRangeInput
  ) {
    get_devices(condition: $condition, dateRange: $dateRange) {
      devices {
        id
        name
        device_status {
          latitude
          longitude
          powersource
          status_code
          status_code_id
          reportstamp
          battery_level
          distancetraveled
          gpsSpeed
          temperature
          lastlocation
          AlertReferences {
            level
            alert_code
            alertmessage
          }
        }
        vehicle_info {
          id
          under_repair
          not_in_use
          ignition_status
          door_status
          has_fuel_sensor
          has_temp_sensor
          client_id
          personnel_name
          plateno
          trip_number
        }
        device_type_id
        client_id
        group_ids
        devicealias
      }
    }
  }
`;

export const GET_DEVICES_TRIPS = gql`
  query getDevices($condition: [VehicleSearchInput], $first: Int) {
    get_devices(condition: $condition, first: $first) {
      devices {
        id
        device_type_id
        vehicle_info {
          id
          plateno
          vehicle_type
        }
        device_status {
          reportstamp
          battery_level
          status_code
        }
        device_config {
          gps_provider
        }
      }
    }
  }
`;

export const GET_SERVICE_TYPES_QUERY = gql`
  query get_service_types {
    get_service_types {
      id
      name
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query getAttachments($condition: [DynamicSearchInput]) {
    get_trip_attachments(condition: $condition) {
      data {
        id
        name
        size
        trip_number
      }
      error
      success
    }
  }
`;

export const GET_LOCATIONS = gql`
  query getLocations($vehicle_id: ID!, $geofence_id: ID!) {
    get_device(id: $vehicle_id) {
      device_status {
        latitude
        longitude
      }
    }
    get_geofence(id: $geofence_id) {
      id
      location {
        lat
        lon
      }
    }
  }
`;

export const GET_VEHICLES_TO_PAIR = gql`
  query getVehicles(
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
  ) {
    get_vehicles(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      vehicles {
        id
        device_id
        plateno
        bodyno
        vehicle_type_id
        capacity
        unit
        has_container
        status_code_id
        status_code
        model
        manufacture_year
        registration_number
        expiration
        client_id
        vehicle_type
        personnel_id
      }
      total
      count
    }
  }
`;
export const GET_VEHICLE_INFOS = gql`
  query getVehicleInfos(
    $code: String
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $keyword: String
    $orderBy: [OrderByInput]
    $bounds: [String!]
    $filter: [VehicleSearchInput]
    $group_ids: [Int]
    $specialCondition: [SpecialConditionInput]
    $is_logistics: Boolean
  ) {
    get_vehicles(
      code: $code
      condition: $condition
      first: $first
      skip: $skip
      dateRange: $dateRange
      orderBy: $orderBy
      keyword: $keyword
      not: $not
      bounds: $bounds
      filter: $filter
      group_ids: $group_ids
      specialCondition: $specialCondition
      is_logistics: $is_logistics
    ) {
      total
      count
      vehicles {
        id
        plateno
        remarks
        device_id
        status_code
        status_code_id
        vehicle_type_id
        vehicle_type
        icon
        # group_ids
        # group_names
        avefuelconsumption
        client_id
        under_repair
        has_fuel_sensor
        has_temp_sensor
        device_info {
          devicealias
          device_type_id
          device_status {
            status_code
            status_code_id
            gpsSpeed
            temperature
            reportstamp
            lastlocation
            latitude
            longitude
            heading
            battery_level
            distancetraveled
            fuelconsumption
            powersource
            AlertReferences {
              level
              alertmessage
              alert_code
            }
            last_alert_msg
            last_alert_stamp
            powersource
          }
          device_config {
            intervalreporting
            battery_intervalreporting
            aux_voltage
            gps_provider
            hastempsensor
            underrepair
          }
          mobile_imei
          name
        }
        model
        manufacture_year
        expiration
        client_id
        client_name
        bodyno
        trip_numbers
        not_in_use
        door_status
        door_status_stamp
        ignition_status
        ignition_status_stamp
        personnel_id
        personnel_name
        paired
        paired
        maintenance_km
        maintenance_months
        maintenance_remarks
        last_maintenance_date
        last_maintenance_km
        last_360_check
        is_for_repair
        is_for_maintenance
        auto_unpair
        has_vss_camera
        channel_count
      }
    }
  }
`;

export const DOWNLOAD_GET_VEHICLE_INFOS = gql`
  query getVehicleInfos(
    $code: String
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $keyword: String
    $orderBy: [OrderByInput]
    $bounds: [String!]
    $filter: [VehicleSearchInput]
    $group_ids: [Int]
  ) {
    get_vehicles(
      code: $code
      condition: $condition
      first: $first
      skip: $skip
      dateRange: $dateRange
      orderBy: $orderBy
      keyword: $keyword
      not: $not
      bounds: $bounds
      filter: $filter
      group_ids: $group_ids
    ) {
      vehicles {
        under_repair
        not_in_use
        plateno
        status_code_id
        vehicle_type
        device_info {
          name
          devicealias
          device_status {
            status_code
            battery_level
          }
        }
        client_id
        client_name
        make
        area
        heads
        region
        assignment
      }
    }
  }
`;

export const GET_VEHICLE_TYPE_LIST = gql`
  query getVehicleTypes($group_ids: [Int], $keyword: String) {
    get_vehicle_types(
      first: 10000
      orderBy: [{ field: "vehicle_types.name", direction: "asc" }]
      group_ids: $group_ids,
      keyword: $keyword
    ) {
      total
      count
      vehicle_types {
        id
        name
      }
    }
  }
`;

export const GET_ADMIN_CLIENTS = gql`
  query getAdminClients(
    $keyword: String
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $access_status: String
    $dateRange: DateRangeInput
    $orderBy: [OrderByInput]
    $group_ids: [Int]
    $filter: [DynamicSearchInput]
  ) {
    get_clients(
      keyword: $keyword
      condition: $condition
      skip: $skip
      first: $first
      access_status: $access_status
      dateRange: $dateRange
      orderBy: $orderBy
      group_ids: $group_ids
      filter: $filter
    ) {
      total
      count
      clients {
        id
        name
        category
        user_count
        device_count
        geofence_count
        access_end_date
        data_admin
        remarks
        group_ids
        group_names
      }
    }
  }
`;

export const GET_ADMIN_CLIENTS_WO_GROUPS = gql`
  query getAdminClients(
    $keyword: String
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $access_status: String
    $dateRange: DateRangeInput
    $orderBy: [OrderByInput]
    $group_ids: [Int]
    $filter: [DynamicSearchInput]
  ) {
    get_clients(
      keyword: $keyword
      condition: $condition
      skip: $skip
      first: $first
      access_status: $access_status
      dateRange: $dateRange
      orderBy: $orderBy
      group_ids: $group_ids
      filter: $filter
    ) {
      total
      count
      clients {
        id
        name
        category
        user_count
        device_count
        geofence_count
        access_end_date
        data_admin
        remarks
      }
    }
  }
`;

export const GET_ACL_MODULES_CLIENT = gql`
  query getAdminClient($id: ID!, $code: String) {
    get_client(id: $id, code: $code) {
      acl_modules {
        id
        resources
        view
        edit
      }
    }
  }
`;

export const GET_ADMIN_CLIENT = gql`
  query getAdminClient($id: ID!) {
    get_client(id: $id) {
      id
      name
      remarks
      client_type
      category
      user_count
      device_count
      geofence_count
      access_end_date
      data_admin
      acl_module_id
      acl_modules {
        id
        resources
        view
        edit
      }
      client_preference {
        id
        client_id
        welcome_logo
        welcome_text
        header_logo
        header_text
        primary_color
      }
      group_ids
      group_names
      sms_sending_type
      eta_enabled
      is_restricted
      compute_stoppage
    }
  }
`;

export const GET_ADMIN_CLIENT_WO_GROUPS = gql`
  query getAdminClient($id: ID!) {
    get_client(id: $id) {
      id
      name
      remarks
      client_type
      category
      user_count
      device_count
      geofence_count
      access_end_date
      data_admin
      acl_module_id
      acl_modules {
        id
        resources
        view
        edit
      }
      client_preference {
        id
        client_id
        welcome_logo
        welcome_text
        header_logo
        header_text
        primary_color
      }
      sms_sending_type
      eta_enabled
      is_restricted
    }
  }
`;

export const GET_DEVICE = gql`
  query get_device($id: ID!) {
    get_device(id: $id) {
      id
      name
      remarks
      devicealias
      mobile_imei
      device_type_id
      device_type
      client_id
      client_name
      vehicle_info {
        id
        device_id
        plateno
        icon
        client_id
        vehicle_type
        trip_number
        powersource
        ignition_status
      }
      group_ids
      group_names
      device_config {
        mobileno
        underrepair
        intervalreporting
        battery_intervalreporting
        idlesetting
        aux_voltage
        gps_provider_id
        gps_provider
        expirationdate
        warranty
        dateinstalled
        speedlimit
        highwayspeedlimit
        maxvalidspeed
        warningspeed
        hasFuelSensor
        fuelrefill
        fuelpilferage
        hastempsensor
        mintemp
        maxtemp
        monitordooropenning
        checkhomebasestatus
        use_start_end_idle
        ha_min
        ha_max
        hb_min
        hb_max
      }
      device_status {
        latitude
        longitude
        reportstamp
        heading
        status_code
        status_code_id
        gpsSpeed
        temperature
        battery_level
        powersource
        AlertReferences {
          level
          alert_code
        }
      }
      mirror_to_ngt
      contract_ref_no
      reserve_field
      clients_codes{
          id
          code
          remarks
        }
    }
  }
`;

export const GET_VEHICLE_INFO = gql`
  query getVehicle($id: ID!) {
    get_vehicle(id: $id) {
      id
      plateno
      remarks
      device_id
      status_code
      status_code_id
      vehicle_type_id
      vehicle_type
      icon
      avefuelconsumption
      trip_numbers
      group_ids
      group_names
      under_repair
      has_fuel_sensor
      has_temp_sensor
      device_info {
        id
        name
        devicealias
        device_type_id
        device_status {
          status_code
          status_code_id
          latitude
          longitude
          reportstamp
          heading
          battery_level
          powersource
          distancetraveled
          fuelconsumption
          lastlocation
        }
        device_config {
          intervalreporting
          battery_intervalreporting
          aux_voltage
          gps_provider
          underrepair
          odom_diff
          odom_diff_changed
        }
      }
      model
      manufacture_year
      expiration
      client_id
      client_name
      bodyno
      not_in_use
      door_status
      door_status_stamp
      ignition_status
      ignition_status_stamp
      auto_unpair
      make
      area
      heads
      region
      assignment
      personnel_id
      personnel_name
      has_vss_camera
      channel_count
    }
  }
`;

export const GET_CHECKLIST_VEHICLE_INFO = gql`
  query getVehicle($id: ID!) {
    get_vehicle(id: $id) {
      id
      plateno
      vehicle_type
      client_name
      device_info {
        device_status {
          distancetraveled
        }
      }
      is_for_repair
      last_repair_date
      last_360_check
    }
  }
`;

export const GET_ADMIN_ACCESS_OBJECTS = gql`
  query GetAdminAccessObjects {
    get_access_objects {
      id
      name
      resource
      parent_id
      permission {
        view
        edit
      }
    }
  }
`;

export const GET_ADMIN_VEHICLES = gql`
  query getAdminVehicles(
    $filter: [VehicleSearchInput!]
    $condition: [VehicleSearchInput!]
    $not: [VehicleSearchInput!]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $group_ids: [Int]
    $keyword: String
    $orderBy: [OrderByInput]
  ) {
    get_vehicles(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      keyword: $keyword
      not: $not
      dateRange: $dateRange
      group_ids: $group_ids
      orderBy: $orderBy
    ) {
      total
      count
      vehicles {
        id
        device_id
        icon
        plateno
        device_info {
          devicealias
          device_status {
            reportstamp
            status_code
            status_code_id
          }
        }
        client_name
        vehicle_type
        vehicle_type_id
        client_name
        client_id
        # group_names
        # group_ids
        status_code
      }
    }
  }
`;

export const GET_ADMIN_DEVICES = gql`
  query getAdminDevices(
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $group_ids: [Int]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $keyword: String
  ) {
    get_devices(
      not: $not
      keyword: $keyword
      group_ids: $group_ids
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      devices {
        id
        devicealias
        mobile_imei
        name
        client_name
        device_type
        client_id
        vehicle_info {
          id
          plateno
        }
        device_status {
          latitude
          longitude
          reportstamp
          heading
          status_code
          status_code_id
        }
        device_config {
          gps_provider
          gps_provider_id
        }
      }
      total
      count
    }
  }
`;

export const GET_DEVICE_TYPES = gql`
  query getDeviceTypes(
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
  ) {
    get_device_types(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      device_types {
        id
        parent_id
        name
        description
      }
      total
      count
    }
  }
`;

export const GET_GPS_PROVIDER = gql`
  query getGPSProvider($id: ID!) {
    get_gps_provider(id: $id) {
      id
      name
      remarks
      api_key
      registered_IPS
      group_ids
      group_names
    }
  }
`;

export const GET_GPS_PROVIDERS = gql`
  query getGPSProviders(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
  ) {
    get_gps_providers(
      keyword: $keyword
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      gps_providers {
        id
        name
        remarks
        api_key
        registered_IPS
        group_ids
        group_names
      }
      total
      count
    }
  }
`;

export const GET_VEHICLES_LOOKUP = gql`
  query getVehicleInfos(
    $condition: [VehicleSearchInput!]
    $filter: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $group_ids: [Int]
  ) {
    get_vehicles(
      condition: $condition
      filter: $filter
      first: $first
      skip: $skip
      dateRange: $dateRange
      group_ids: $group_ids
    ) {
      total
      count
      vehicles {
        id
        plateno
        device_id
        vehicle_type
        vehicle_type_id
        capacity
        unit
        has_container
        model
        manufacture_year
        registration_number
        expiration
        bodyno
      }
    }
  }
`;

export const GET_PARTNERS = gql`
  query getGroups($skip: Int, $keyword: String, $not: [DynamicSearchInput]) {
    get_groups(skip: $skip, first: 5, keyword: $keyword, not: $not) {
      count
      total
      groups {
        id
        name
      }
    }
  }
`;

export const GET_ADMIN_USERS = gql`
  query getAdminUsers(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
  ) {
    get_users(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      users {
        id
        username
        first_name
        last_name
        email_address
        user_level_id
        user_level_name
        contact_number
        account_expiration
        modified
        client {
          id
          name
          is_restricted
        }
      }
      total
      count
    }
  }
`;

export const GET_ADMIN_GROUPS = gql`
  query getGroups(
    $skip: Int
    $keyword: String
    $first: Int
    $not: [DynamicSearchInput]
  ) {
    get_groups(skip: $skip, first: $first, keyword: $keyword, not: $not) {
      count
      total
      groups {
        id
        name
      }
    }
  }
`;
export const GET_ADMIN_GEOFENCES = gql`
  query getAdminGeofences(
    $filter: [DynamicSearchInput]
    $first: Int
    $not: [DynamicSearchInput]
    $group_ids: [Int]
    $skip: Int
    $condition: [DynamicSearchInput]
    $orderBy: [OrderByInput]
    $keyword: String
    $keyword_fields: [String]
  ) {
    get_geofences(
      group_ids: $group_ids
      not: $not
      filter: $filter
      skip: $skip
      first: $first
      condition: $condition
      orderBy: $orderBy
      keyword: $keyword
      keyword_fields: $keyword_fields
    ) {
      geofences {
        id
        name
        address
        geofence_code
        location {
          lat
          lon
        }
        type
        category
        group_ids
        group_names
        window_time_end
        window_time_start
        radius
      }
      total
      count
    }
  }
`;

export const GET_SERVICE_TYPES = gql`
  {
    get_service_types {
      value: id
      label: name
    }
  }
`;

export const GET_CLIENTS_DASHBOARDS = gql`
  query getClientsDashboards($first: Int, $keyword: String) {
    get_clients(first: $first, keyword: $keyword) {
      clients {
        value: id
        label: name
      }
    }
  }
`;

export const GET_CHART_DATA = gql`
  query getOtta(
    $service_type_id: Int
    $client_id: Int
    $pickups: [ID]
    $group_ids: [Int]
    $account: String
    $dropoffs: [ID]
    $locations: [ID]
    $dateRange: DateRangeInputV2
    $is_inter_island: Boolean
  ) {
    get_otta(
      service_type_id: $service_type_id
      client_id: $client_id
      geofence_ids: $pickups
      group_ids: $group_ids
      account: $account
      is_inter_island: $is_inter_island
    ) {
      incoming {
        awaiting {
          count
          trip_numbers
        }
        at_risk {
          count
          trip_numbers
        }
        at_risk_pre_dispatch {
          count
          trip_numbers
        }
        at_risk_trip {
          count
          trip_numbers
        }
        on_track {
          count
          trip_numbers
        }
        on_track_pre_dispatch {
          count
          trip_numbers
        }
        on_track_trip {
          count
          trip_numbers
        }
      }
      arrived {
        late {
          count
          trip_numbers
        }
        late_pre_dispatch {
          count
          trip_numbers
        }
        late_trip {
          count
          trip_numbers
        }
        on_time {
          count
          trip_numbers
        }
        on_time_pre_dispatch {
          count
          trip_numbers
        }
        on_time_trip {
          count
          trip_numbers
        }
        dispatched {
          count
          trip_numbers
        }
      }
    }
    get_otd(
      service_type_id: $service_type_id
      client_id: $client_id
      geofence_ids: $dropoffs
      group_ids: $group_ids
      account: $account
      is_inter_island: $is_inter_island
    ) {
      in_transit {
        at_risk {
          count
          trip_numbers
        }
        on_track {
          count
          trip_numbers
        }
      }
      arrived {
        late {
          count
          trip_numbers
        }
        on_time {
          count
          trip_numbers
        }
        completed {
          count
          trip_numbers
        }
      }
    }
    get_closed_trip(
      service_type_id: $service_type_id
      client_id: $client_id
      geofence_ids: $locations
      group_ids: $group_ids
      account: $account
      dateRange: $dateRange
      is_inter_island: $is_inter_island
    ) {
      closed_trip {
        count
        trip_numbers
      }
      closed_trip_pre_dispatch {
        count
        trip_numbers
      }
      closed_trip_trip {
        count
        trip_numbers
      }
    }
    get_cancelled_trip(
      service_type_id: $service_type_id
      client_id: $client_id
      geofence_ids: $locations
      group_ids: $group_ids
      account: $account
      dateRange: $dateRange
      is_inter_island: $is_inter_island
    ) {
      cancelled_trip {
        count
        trip_numbers
      }
      cancelled_trip_pre_dispatch {
        count
        trip_numbers
      }
      cancelled_trip_trip {
        count
        trip_numbers
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  {
    get_booking_accounts
  }
`;

export const GET_BOOKING_GEOFENCES = gql`
  query getBookingGeofences($category: String, $keyword: String) {
    get_booking_geofences(category: $category, first: 5, keyword: $keyword) {
      geofence_id
      name
    }
  }
`;

export const GET_DASHBOARDS_FILTERS = gql`
  query getFilters($user_id: ID, $dashboard_type: String) {
    get_dashboard_filters(user_id: $user_id, dashboard_type: $dashboard_type) {
      value: id
      label: name
    }
  }
`;

export const GET_DASHBOARDS_FILTER = gql`
  query getFilter($id: ID!) {
    get_dashboard_filter(id: $id) {
      name
      pickup_ids
      user_id
      dashboard_type
      group_ids
      service_type_id
      client_id
      account
      port_cy_ids
      dropoff_ids
      vehicle_type_id
      location_ids
      is_interisland
    }
  }
`;

export const GET_DEMURRAGE_DATA = gql`
  query getDemurrageData(
    $vehicle_type_id: Int
    $client_id: Int
    $locations: [ID]
    $account: String
    $is_inter_island: Boolean
  ) {
    get_demurrage_land(
      vehicle_type_id: $vehicle_type_id
      client_id: $client_id
      geofence_ids: $locations
      account: $account
      is_inter_island: $is_inter_island
    ) {
      at_risk {
        count
        trip_numbers
      }
      at_risk_pre_dispatch {
        count
        trip_numbers
      }
      at_risk_trip {
        count
        trip_numbers
      }
      resupply {
        count
        trip_numbers
      }
      resupply_pre_dispatch {
        count
        trip_numbers
      }
      resupply_trip {
        count
        trip_numbers
      }
      dc_pickup {
        count
        trip_numbers
      }
      dc_pickup_pre_dispatch {
        count
        trip_numbers
      }
      dc_pickup_trip {
        count
        trip_numbers
      }
      customer {
        count
        trip_numbers
      }
    }
  }
`;

export const GET_TRIP_LOG = gql`
  query getTripLog(
    $filter: [SearchInput]!
    $or: [SearchInput]
    $skip: Int
    $first: Int
    $dateFilter: DateFilterInput
  ) {
    get_trip_log(
      filter: $filter
      or: $or
      skip: $skip
      first: $first
      dateFilter: $dateFilter
      orderBy: { field: "datestamp", direction: "asc" }
    ) {
      id
      device_id
      device_alias
      plateno
      datestamp
      gps_received
      gps_delay
      alert_codes
      alert_ids
      alert_msgs
      direction
      fuel_reading
      temperature
      group_ids
      group_names
      location {
        lat
        lon
      }
      odometer_reading
      speed
      created
      modified
      creator
    }
  }
`;

export const GET_TRIP_LOG_V2 = gql`
  query getTripLog(
    $exists: [String]
    $filter: [SearchInput]!
    $or: [SearchInput]
    $skip: Int
    $first: Int
    $dateFilter: DateFilterInput
    $not: [SearchInput]
  ) {
    get_trip_log_v2(
      exists: $exists
      filter: $filter
      or: $or
      skip: $skip
      first: $first
      dateFilter: $dateFilter
      orderBy: { field: "datestamp", direction: "asc" }
      not: $not
    ) {
      #id
      device_id
      #device_alias
      plateno
      datestamp
      #gps_received
      #gps_delay
      alert_codes
      #alert_ids
      alert_levels
      alert_msgs
      direction
      fuel_reading
      temperature
      #group_ids
      #group_names
      location {
        lat
        lon
      }
      odometer_reading
      speed
      created
      #modified
      #creator
    }
  }
`;

export const GET_CICOS_NO_GEOFENCE = gql`
  query getCicos(
    $keyword: String
    $filter: [SearchInput]
    $inFilter: [ArraySearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInput
  ) {
    get_cicos(
      keyword: $keyword
      filter: $filter
      inFilter: $inFilter
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      total
      cicos {
        id
        device_alias
        entry_stamp
        left_stamp
        geofence_id
        geofence_name
      }
    }
  }
`;

export const GET_UNIQUE_TRIP_NUMBERS = gql`
  query getUniqueTrip($keyword: String!, $group_ids: [Int], $first: Int) {
    get_unique_trip_numbers(
      keyword: $keyword
      group_ids: $group_ids
      first: $first
    )
  }
`;

export const GET_BOOKING_TRIP_NUMBER = gql`
  query getTripNumber(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $not: [SearchInput]
    $or: [SearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInputV2
  ) {
    get_bookings(
      keyword: $keyword
      filter: $filter
      condition: $condition
      not: $not
      or: $or
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      bookings {
        id
        trip_number
        status_code_id
        data_complete
        pickups {
          arrival
        }
        vehicle_history {
          vehicle_id
          vehicle_plate_no
          user_id
          user_full_name
          datestamp
        }
      }
    }
  }
`;

export const GET_BOOKINGS = gql`
  query getBookings(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $not: [SearchInput]
    $or: [SearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInputV2
  ) {
    get_bookings(
      keyword: $keyword
      filter: $filter
      condition: $condition
      not: $not
      or: $or
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      count
      total
      bookings {
        id
        trip_number
        status_code
        ordinal
        status_code_id
        vehicle_id
        data_complete
        so_number
        service_type
        pickups {
          geofence_id
          name
          arrival
          actual_arrival
          manual_arrival
          departure
          actual_departure
          manual_departure
          status_code
          status_code_id
        }
        dropoffs {
          geofence_id
          name
          arrival
          actual_arrival
          manual_arrival
          departure
          actual_departure
          manual_departure
          status_code
          status_code_id
        }
        group_ids
        group_names
        client_id
        client_name
        vehicle_history {
          vehicle_id
          vehicle_plate_no
          user_id
          user_full_name
          datestamp
        }
      }
    }
  }
`;

export const DISPATCH_PERMISSIONS = gql`
  query dispatchPermissions($user_id: ID!) {
    change_truck: allowed_permissions(
      user_id: $user_id
      resource: "/trips/change_truck/"
    ) {
      view
      edit
    }
    split: allowed_permissions(user_id: $user_id, resource: "/trips/split/") {
      view
      edit
    }
    dispatch: allowed_permissions(
      user_id: $user_id
      resource: "/trips/dispatch/"
    ) {
      view
      edit
    }
    manual_complete: allowed_permissions(
      user_id: $user_id
      resource: "/trips/manual_complete/"
    ) {
      view
      edit
    }
    reorder: allowed_permissions(
      user_id: $user_id
      resource: "/trips/reorder/"
    ) {
      view
      edit
    }
    map_view: allowed_permissions(
      user_id: $user_id
      resource: "/trips/map_view/"
    ) {
      view
      edit
    }
    close: allowed_permissions(user_id: $user_id, resource: "/trips/close/") {
      view
      edit
    }
  }
`;

export const GET_ADMIN_ALERT_REFERENCES = gql`
  query getAlerts(
    $keyword: String
    $first: Int
    $skip: Int
    $not: [DynamicSearchInput]
    $filter: [SearchInput]
    $orderBy: [OrderByInput]
    $group_ids: [Int]
  ) {
    get_alert_references(
      keyword: $keyword
      first: $first
      skip: $skip
      filter: $filter
      orderBy: $orderBy
      group_ids: $group_ids
      not: $not
    ) {
      count
      total
      alert_references {
        id
        level
        alertmessage
        code
        description
        device_type {
          description
        }
        alert_description {
          id
          description
          code
        }
        alert_type
        description
        group_ids
        group_names
        code
        device_type {
          name
        }
        operator
        event_value
      }
    }
  }
`;

export const GET_ALERT_DESCRIPTIONS = gql`
  query getAlertDescriptions(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $not: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $group_ids: [Int]
  ) {
    get_alert_descriptions(
      keyword: $keyword
      filter: $filter
      condition: $condition
      not: $not
      skip: $skip
      first: $first
      orderBy: $orderBy
      group_ids: $group_ids
    ) {
      count
      total
      alert_descriptions {
        id
        description
        code
        parent_id
        parent {
          id
          description
          code
        }
      }
    }
  }
`;

export const GET_ALERT_DESCRIPTION = gql`
  query getAlertDescription($id: ID) {
    get_alert_description(id: $id) {
      id
      description
      code
      parent_id
      parent {
        id
        code
        description
      }
    }
  }
`;

export const GET_NOTIFICATIONS_PAGE = gql`
  query getNotifPage(
    $keyword: String
    $filter: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInput
  ) {
    get_notifications(
      filter: $filter
      keyword: $keyword
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      notifications {
        id
        datestamp
        is_urgent
        created
        notification
        vehicle_plate_no
        trip_number
        category
        vehicle_id
        alert_level
        alert_code
        booking_id
        client_id
        do_number
        driver_name
        geofence_id
        group_ids
        group_names
        is_archived
        is_read
        location {
          lat
          lon
        }
        speed
        temperature
        trucker_id
        user_level_id
        alert_code
      }
      count
      total
    }
  }
`;

export const PREBOOKING_PERMISSIONS = gql`
  query dispatchPermissions($user_id: ID!) {
    change_truck: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/change_truck/"
    ) {
      view
      edit
    }
    split: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/split/"
    ) {
      view
      edit
    }
    dispatch: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/dispatch/"
    ) {
      view
      edit
    }
    manual_complete: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/manual_complete/"
    ) {
      view
      edit
    }
    close: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/close/"
    ) {
      view
      edit
    }
    upload: allowed_permissions(
      user_id: $user_id
      resource: "/prebooking/manual_upload/"
    ) {
      view
      edit
    }
  }
`;

export const GET_BOOKING_HAULERS = gql`
  query getBookingHaulers($keyword: String) {
    get_booking_haulers(keyword: $keyword, first: 5) {
      client_id
      client_name
    }
  }
`;

export const GET_VEHICLE_HAULERS = gql`
  query getBookingHaulers($keyword: String) {
    get_vehicle_haulers(keyword: $keyword, first: 5) {
      client_id
      client_name
    }
  }
`;

export const GET_CLIENTS_CODES = gql`
  query get_clients_codes(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $first: Int
    $orderBy: [OrderByInput]
  ) {
    get_clients_codes(
      keyword: $keyword
      filter: $filter
      condition: $condition
      first: $first
      orderBy: $orderBy
    ) {
      id
      client_id
      code
      remarks
    }
  }
`;

export const GET_ADMIN_VEHICLE_TYPES = gql`
  query getVehicleTypes(
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $group_ids: [Int]
    $keyword: String
    $not: [DynamicSearchInput]
  ) {
    get_vehicle_types(
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      group_ids: $group_ids
      keyword: $keyword
      not: $not
    ) {
      count
      total
      vehicle_types {
        id
        name
        capacity_weight
        capacity_weight_unit
        capacity_volume
        capacity_volume_unit
        remarks
        groups {
          id
          name
        }
        number_of_wheels
        #number_of_vehicles
        client_id
        client_name
      }
    }
  }
`;

export const GET_ADMIN_VEHICLE_TYPE = gql`
  query getVehicleType($id: ID!) {
    get_vehicle_type(id: $id) {
      id
      name
      capacity_weight
      capacity_weight_unit
      capacity_volume
      capacity_volume_unit
      remarks
      groups {
        id
        name
      }
      number_of_wheels
      number_of_vehicles
      client_id
      client_name
    }
  }
`;

export const GET_INSIDE_GEOFENCES = gql`
  query getInsideGeofences($points: [PointGeometryInput], $group_ids: [Int!]) {
    get_inside_geofence(points: $points, group_ids: $group_ids) {
      id
      is_inside
      address
      geofence_names
    }
  }
`;

export const GET_SCHEDULED_REPORT = gql`
  query getScheduledReport($id: ID!) {
    get_scheduled_report(id: $id) {
      id
      user_id
      reporttype_id
      sked
      timesked
      group_ids
      device_id
      personnel_id
      status
      created
      filename
      is_sent
      time_checked
      process_tries
      checked
      time_sent
      email_add
      email_body
      emailer
      interval
      emailer_pw
      is_blank_report
      report_type
      alert_description_ids
      trip_status_id
      activity_only
      vehicle_status
      gps_status
      reporting_delay
      duration
      headers
      sftp {
        sftp_link
        username
        password
        key
        port
      }
      via_email
      via_sftp
      geofences: geofence_ids {
        id
        name
        geofence_code
      }
      startstamp
      endstamp
      battery_level
      use_logistics_status
    }
  }
`;

export const GET_SCHEDULED_REPORTS = gql`
  query get_scheduled_reports(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateFilter: DateFilterInput
    $client_ids: [Int]
  ) {
    get_scheduled_reports(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateFilter: $dateFilter
      client_ids: $client_ids
    ) {
      total
      count
      scheduled_reports {
        id
        user_id
        report_type
        reporttype_id
        sked
        timesked
        group_ids
        device_id
        personnel_id
        status
        created
        filename
        is_sent
        time_checked
        process_tries
        checked
        time_sent
        email_add
        email_body
        emailer
        interval
        emailer_pw
        is_blank_report
        alert_description_ids
        trip_status_id
        activity_only
      }
    }
  }
`;

export const GET_ACTIVITY_LOGS = gql`
  query getActivityLogs(
    $keyword: String
    $filter: [SearchInput]
    $condition: [SearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateFilter: DateFilterInput
  ) {
    get_activity_logs(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateFilter: $dateFilter
    ) {
      count
      total
      activitylogs {
        id
        activity
        remote_address
        datestamp
        user {
          id
          username
        }
        module
      }
    }
  }
`;

export const GET_ACTIVITY_LOG = gql`
  query getActivityLog($id: ID) {
    get_activity_log(id: $id) {
      id
      activity
      remote_address
      datestamp
      user {
        id
        username
      }
      module
    }
  }
`;

export const GET_SMS_REQUESTS = gql`
  query getSMSRequests(
    $keyword: String
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $filter: [DynamicSearchInput]
    $dateFilter: DateFilterInput
    $condition: [DynamicSearchInput]
  ) {
    get_sms_requests(
      keyword: $keyword
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
      dateFilter: $dateFilter
      condition: $condition
    ) {
      total
      count
      sms_requests {
        id
        mobile_number
        message
        sent
        use_globe_api
        created
      }
    }
  }
`;

export const GET_MOBILE_NUMBERS = gql`
  query getMobileNumbers {
    get_unique_number {
      mobile_number
    }
  }
`;

export const GET_FOR_EMAILING = gql`
  query getForEmailings(
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInput
  ) {
    get_foremailings(
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateRange: $dateRange
    ) {
      total
      count
      for_emailing {
        id
        email_to
        subject
        # email_body
        attachment_directory
        emailer
        emailer_pw
        smtp_host
        smtp_factory_port
        smtp_factory_class
        smtp_auth
        smtp_port
        bcc
        scheduled_report_id
        date_start
        date_end
        issent
        datesent
      }
    }
  }
`;

export const GET_USERS_INFO = gql`
  query UsersQuery(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateFilterAnd: [DateFilterInput]
  ) {
    get_users(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateFilterAnd: $dateFilterAnd
    ) {
      users {
        username
        user_level_id
        group_ids
      }
      total
      count
    }
  }
`;

export const GET_FEEDBACKS = gql`
  query GetFeedbacksQuery(
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $keyword: String
    $username: String
    $client_id: ID
    $dateRange: DateRangeInput
  ) {
    get_feedback(
      first: $first
      skip: $skip
      orderBy: $orderBy
      keyword: $keyword
      username: $username
      client_id: $client_id
      dateRange: $dateRange
    ) {
      total
      feedback {
        id
        datestamp
        user_id
        message
        username
        client_name
      }
    }
  }
`;

export const GET_CLIENTS_OPTIONS = gql`
  query GetClientOptions($first: Int, $keyword: String) {
    get_clients(first: $first, keyword: $keyword) {
      clients {
        value: id
        label: name
      }
    }
  }
`;

export const GET_USERS_OPTIONS = gql`
  query getUsersQuery(
    $condition: [DynamicSearchInput]
    $first: Int
    $keyword: String
  ) {
    get_users(condition: $condition, first: $first, keyword: $keyword) {
      users {
        value: id
        label: username
      }
    }
  }
`;

export const GET_GROUPS_OPTIONS = gql`
  query getGroupsOptions(
    $keyword: String
    $first: Int
    $condition: [SearchInput]
    $or: [DynamicSearchInput]
  ) {
    get_groups(
      keyword: $keyword
      first: $first
      condition: $condition
      or: $or
    ) {
      groups {
        group_id: id
        group_name: name
      }
    }
  }
`;

export const GET_VEHICLES_COUNT = gql`
  query VehiclesCount(
    $code: String
    $keyword: String
    $filter: [VehicleSearchInput]
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $dateRange: DateRangeInput
    $group_ids: [Int]
    $bounds: [String!]
  ) {
    get_vehicles_count(
      code: $code
      keyword: $keyword
      filter: $filter
      condition: $condition
      not: $not
      dateRange: $dateRange
      group_ids: $group_ids
      bounds: $bounds
    ) {
      total
      status_count {
        available
        booked
        booked_underrepair
        booked_not_in_use
        underrepair
        not_in_use
        online
        offline
        active
        no_gps
        owned
        shared
        delay_reporting
      }
    }
  }
`;

export const GET_INFO_BUBBLE = gql`
  query InfoBubble($client_id: ID!, $code: String) {
    get_info_bubble(client_id: $client_id, code: $code) {
      client_id
      ignition
      battery
      door
      fuel
      odometer_reading
      power_source
      speed
      temperature
      driver
      coordinates
    }
  }
`;
export const GET_TEXTUAL_LOCATION = gql`
  query getTextualLocation($points: [PointGeometryInput], $group_ids: [Int!]) {
    get_textual_location(points: $points, group_ids: $group_ids)
  }
`;

export const GET_CONTROL_ROOMS = gql`
  query getControlRooms($query: ControlRoomQuery) {
    get_control_rooms(query: $query) {
      total
      count
      control_rooms {
        id
        name
        zoom_level
        data_view
        location {
          lat
          lon
        }
        group_ids
        group_names
        user_id
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const GET_INTERSECT_UNIVERSAL_GEOFENCE = gql`
  query getIntersectUniversalGeofence(
    $centroid: String
    $geom_type: Geom_type!
    $geom: String!
    $intersect_percent: Int
  ) {
    get_intersect_universal_geofence(
      geom_type: $geom_type
      centroid: $centroid
      geom: $geom
      intersect_percent: $intersect_percent
    ) {
      universal_geofences {
        id
        name
        address
        window_time_start
        window_time_end
        type
        location {
          lat
          lon
        }
        group_ids
        group_names
        geom
        geofence_code
        radius
        geofence_geom_id
        region
        province
        municipality
        barangay
      }
      count
    }
  }
`;

export const GET_BOOKINGS_PER_TRIP = gql`
  query get_bookings_per_trip(
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInputV2
    $keyword: String
    $pickups: [String]
    $dropoffs: [String]
    $dropoff_match: SearchInput
    $pickup_match: SearchInput
    $nested_match: NestedInput
    $conditions: [DynamicSearchInput]
    $or: [DynamicSearchInput]
    $not: [SearchInput]
    $filter: [DynamicSearchInput]
    $group_ids: [Int]
    $trip_numbers: [String]
    $is_inter_island: Boolean
  ) {
    get_bookings_per_trip(
      first: $first
      skip: $skip
      orderBy: $orderBy
      dateRange: $dateRange
      keyword: $keyword
      pickups: $pickups
      dropoffs: $dropoffs
      dropoff_match: $dropoff_match
      pickup_match: $pickup_match
      nested_match: $nested_match
      conditions: $conditions
      or: $or
      not: $not
      filter: $filter
      group_ids: $group_ids
      trip_numbers: $trip_numbers
      is_inter_island: $is_inter_island
    ) {
      count
      total
      bookings {
        key
        value {
          id
          trip_number
          status_code
          ordinal
          status_code_id
          vehicle_id
          data_complete
          so_number
          service_type
          pickups {
            geofence_id
            name
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            status_code
            status_code_id
            geofence_code
          }
          dropoffs {
            geofence_id
            name
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            status_code
            status_code_id
            geofence_code
          }
          group_ids
          group_names
          client_id
          client_name
          dispatch_time
        }
      }
    }
  }
`;

export const GET_BOOKINGS_TO_RESET = gql`
  query get_bookings_per_trip(
    $first: Int
    $orderBy: [OrderByInput]
    $conditions: [DynamicSearchInput]
    $not: [SearchInput]
  ) {
    get_bookings_per_trip(
      first: $first
      orderBy: $orderBy
      conditions: $conditions
      not: $not
    ) {
      count
      total
      bookings {
        key
        value {
          id
          trip_number
          status_code
          status_code_id
          vehicle_id
          so_number
          group_ids
          group_names
          client_id
          client_name
          vehicle_plate_no
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
        }
      }
    }
  }
`;

// exports fuel reading from the database.
export const GET_FUEL_READING = gql`
  query getFuelReading(
    $device_id: ID
    $startstamp: DateTime!
    $endstamp: DateTime!
    $vehicle_id: ID
    $plateno: String
  ) {
    get_fuel_reading(
      device_id: $device_id
      startstamp: $startstamp
      endstamp: $endstamp
      vehicle_id: $vehicle_id
      plateno: $plateno
    ) {
      results {
        timestamp
        reading
      }
      success
      error
    }
  }
`;

export const CHECK_SESSION = gql`
  query checkSession($token: String!) {
    check_session(token: $token) {
      id
      session_id
    }
  }
`;

export const GET_DOWNLOAD_REPORT = gql`
  query getDownloadReport($name: String!) {
    download_report(name: $name) {
      content
      fileName
    }
  }
`;

export const GET_FUEL_CALIBRATION = gql`
  query getFuelCalibration($vehicleModel_id: Int!) {
    get_fuel_calibration(vehicleModel_id: $vehicleModel_id) {
      results {
        calibration_id
        liters
        voltage
      }
      success
      error
    }
  }
`;

export const GET_FUEL_HISTORY = gql`
  query getFuelHistory(
    $device_id: ID
    $entryTime: TimeStamp!
    $exitTime: TimeStamp!
    $vehicle_id: ID
    $plateno: String
  ) {
    get_fuel_history(
      device_id: $device_id
      entryTime: $entryTime
      exitTime: $exitTime
      vehicle_id: $vehicle_id
      plateno: $plateno
    ) {
      results {
        distance_travelled
        average_speed
        refuelled_amount
        consumed_fuel
        fuel_in
        fuel_out
        fuel_efficiency
        duration_min
      }
      success
      error
    }
  }
`;

export const GET_DASHBOARD_LINKS = gql`
  query getDashboardLinks($group_ids: [Int]) {
    get_dashboard_links(group_ids: $group_ids) {
      links {
        id
        name
        link
        group_ids
      }
      total
      count
    }
  }
`;

export const GET_DASHBOARD_LINK = gql`
  query getDashboardLink($id: ID!) {
    get_dashboard_link(id: $id) {
      id
      name
      link
      group_ids
    }
  }
`;

export const GET_DEVICE_INFO = gql`
  query get_device_info($id: ID!) {
    get_device_info(id: $id) {
      id
      name
      devicealias
      device_type
      client_id
      vehicle_info {
        id
        device_id
        plateno
      }
      device_config {
        gps_provider
        hasFuelSensor
        hastempsensor
      }
      device_status {
        latitude
        longitude
        reportstamp
        status_code
        battery_level
        lastlocation
      }
    }
  }
`;

export const GET_SHARE_LOCATION_V2 = gql`
  query get_share_location($code: String!) {
    get_share_location(code: $code) {
      id
      code
      vehicle_ids
      expiration_date
      client_id
    }
  }
`;

export const GET_CLIENT_GROUP = gql`
  query get_client_group($client_id: Int!) {
    get_client_group(client_id: $client_id) {
      group_ids
      client_id
      group_names
    }
  }
`;

export const GET_DRIVER_INFOS = gql`
  query get_personnels(
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $dateRange: DateRangeInput
    $dateFilter: [DateRangeInput]
    $keyword: String
    $is_paired: Boolean
  ) {
    get_personnels(
      filter: $filter
      condition: $condition
      first: $first
      skip: $skip
      orderBy: $orderBy
      dateRange: $dateRange
      dateFilter: $dateFilter
      keyword: $keyword
      is_paired: $is_paired
    ) {
      total
      count
      personnels {
        id
        name
        employeeid
        supervisoremail
        supervisor
        designation
        driver_mobileno
        mobileno
        remarks
        client_id
        driver_info {
          id
          license_number
          license_expiration
          license_restriction
          status_code_id
          status_code
          license_exp_notif_day
        }
        group_ids
        group_names
        gps_code
        vehicle_info {
          paired
          plateno
          id
        }
      }
    }
  }
`;

export const GET_DRIVER_INFO = gql`
  query get_personnel_by($condition: DynamicSearchInput!) {
    get_personnel_by(condition: $condition) {
      id
      name
      employeeid
      supervisoremail
      supervisor
      designation
      driver_mobileno
      mobileno
      remarks
      client_id
      driver_info {
        id
        license_number
        license_expiration
        license_restriction
        status_code_id
        status_code
        license_exp_notif_day
      }
      group_ids
      group_names
      gps_code
      vehicle_info {
        paired
        plateno
        id
      }
    }
  }
`;

export const GET_CHECKLIST_360 = gql`
  query($value: [String!]) {
    get_checklists_360(condition: { field: "group_id", value: $value }) {
      total
      count
      checklists360 {
        id
        group_id
        checklist
      }
    }
  }
`;

export const GET_ASSIGNED_GEOFENCE = gql`
  query($vehicle_id: ID!) {
    get_assigned_geofence(vehicle_id: $vehicle_id) {
      geofences {
        geofence_id
        geofence_name
        geofence_code
        geom
        location {
          lat
          lon
        }
        window_time_start
        window_time_end
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const GET_ASSIGNED_ROUTE = gql`
  query($vehicle_id: ID!) {
    get_assigned_route(vehicle_id: $vehicle_id) {
      route {
        id
        geofence_id
        geofence_name
        geofence_code
        geom
        location {
          lat
          lon
        }
        line_geom
        monitor_route
        is_permanent
        start_stamp
        end_stamp
      }
      success
      error {
        field
        message
      }
    }
  }
`;
export const GET_CHECKLIST_HISTORY = gql`
  query get_checklists_history(
    $first: Int!
    $dateRange: DateRangeInput
    $condition: [DynamicSearchInput]
    $orderBy: [OrderByInput]
    $skip: Int
  ) {
    get_checklists_history(
      first: $first
      dateRange: $dateRange
      condition: $condition
      orderBy: $orderBy
      skip: $skip
    ) {
      total
      count
      checklists360 {
        id
        datestamp
        driver_id
        driver_name
        vehicle_id
        vehicle_plate_no
        checklists
        odometer_reading
        user_id
        user_name
      }
    }
  }
`;

export const CHECK_GEOFENCE_ASSIGNMENT = gql`
  query checkGeofenceAssignment($geofence_ids: [Int]) {
    check_geofence_assignment(geofence_ids: $geofence_ids) {
      assigned {
        geofence_id
        vehicles
      }
      success
      error {
        field
        message
      }
    }
  }
`;

export const GET_CICOS = gql`
  query CICOs(
    $filter: [SearchInput]
    $inFilter: [ArraySearchInput]
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $dateOrFilter: DateRangeOrInput
  ) {
    get_cicos(
      filter: $filter
      inFilter: $inFilter
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateOrFilter: $dateOrFilter
    ) {
      total
      count
      cicos {
        id
        vehicle_plate_no
        device_id
        geofence_name
        geofence_code
        geofence_id
        category
        address
        entry_stamp
        dwell_time
        left_stamp
      }
    }
  }
`;

export const VALIDATE_DIMS = gql`
  query validateDims($trip_numbers: String, $code: String, $group_ids: [Int]) {
    validate_dims(
      trip_numbers: $trip_numbers
      code: $code
      group_ids: $group_ids
    ) {
      bookings {
        key
        value {
          is_late
          id
          do_stamp
          data_complete
          do_number
          so_number
          out_of_route
          trip_number
          ref_number
          transport_mode
          transport_mode_id
          service_type
          service_type_id
          total_weight
          total_volume
          bill_to_id
          bill_to_name
          client_name
          client_id
          group_names
          ordinal
          is_urgent
          group_ids
          is_urgent
          is_split
          reason
          reason_id
          createdby {
            user_id
            username
            client_id
            client_name
          }
          notification_status
          dr_number
          remarks
          vehicle_id
          vehicle_plate_no
          vehicle_status
          vehicle_type
          vehicle_type_id
          status_code
          status_code_id
          personnels {
            id
            name
          }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          items {
            pickup_index
            dropoff_index
            name
            qty
            dr_count
            volume
            weight
            uom_id
            uom
            sub_items {
              name
            }
            reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          dispatch_type
        }
      }
      geofences {
        id
        name
        geofence_code
        geom
        location {
          lat
          lon
        }
      }
      devices {
        device_status {
          status_code
          status_code_id
          powersource
          heading
          AlertReferences {
            alert_code
          }
          latitude
          longitude
          reportstamp
          lastlocation
        }
        device_config {
          gps_provider
        }
        vehicle_info {
          icon
          ignition_status
          plateno
        }
      }
    }
  }
`;

export const GET_VEHICLES_CONTROL_ROOM = gql`
  query getVehiclesControlRoom(
    $code: String
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $keyword: String
    $orderBy: [OrderByInput]
    $bounds: [String!]
    $filter: [VehicleSearchInput]
    $group_ids: [Int]
  ) {
    get_vehicles(
      code: $code
      condition: $condition
      first: $first
      skip: $skip
      dateRange: $dateRange
      orderBy: $orderBy
      keyword: $keyword
      not: $not
      bounds: $bounds
      filter: $filter
      group_ids: $group_ids
    ) {
      total
      count
      vehicles {
        id
        plateno
        icon
        device_info {
          device_status {
            latitude
            longitude
            heading
            status_code
            reportstamp
            AlertReferences {
              level
              alertmessage
              alert_code
            }
            powersource
          }
        }
        ignition_status
      }
    }
  }
`;

export const GET_VEHICLES_FLEET_MAPVIEW = gql`
  query getVehiclesFleetMapview(
    $code: String
    $condition: [VehicleSearchInput]
    $not: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $keyword: String
    $orderBy: [OrderByInput]
    $bounds: [String!]
    $filter: [VehicleSearchInput]
    $group_ids: [Int]
  ) {
    get_vehicles(
      code: $code
      condition: $condition
      first: $first
      skip: $skip
      dateRange: $dateRange
      orderBy: $orderBy
      keyword: $keyword
      not: $not
      bounds: $bounds
      filter: $filter
      group_ids: $group_ids
    ) {
      total
      count
      vehicles {
        id
        plateno
        #device_id
        #vehicle_type
        icon
        #group_ids
        client_id
        #under_repair
        #has_fuel_sensor
        #has_temp_sensor
        device_info {
          #devicealias
          #device_type_id
          device_status {
            #status_code
            #status_code_id
            #gpsSpeed
            #reportstamp
            latitude
            longitude
            heading
            #battery_level
            powersource
            AlertReferences {
              level
              alertmessage
              alert_code
            }
          }
        }
        trip_numbers
        #not_in_use
        #door_status
        ignition_status
        #personnel_name
      }
    }
  }
`;

export const GET_INFO_BUBBLE_FLEET_MAPVIEW = gql`
  query getVehiclesFleetMapview(
    $condition: [VehicleSearchInput]
    $dateRange: DateRangeInput
  ) {
    get_vehicles(condition: $condition, dateRange: $dateRange) {
      total
      count
      vehicles {
        id
        plateno
        device_id
        vehicle_type
        #icon
        #group_ids
        client_id
        client_name
        under_repair
        has_fuel_sensor
        has_temp_sensor
        device_info {
          device_type_id
          device_status {
            status_code
            status_code_id
            gpsSpeed
            temperature
            reportstamp
            latitude
            longitude
            lastlocation
            battery_level
            powersource
            AlertReferences {
              level
              alertmessage
              alert_code
            }
            distancetraveled
            last_alert_msg
            last_alert_stamp
          }
        }
        trip_numbers
        not_in_use
        door_status
        ignition_status
        personnel_name
      }
    }
  }
`;

export const GET_USER_EMAIL = gql`
  query UsersQuery(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
    $dateFilterAnd: [DateFilterInput]
    $not_like: [DynamicSearchInput]
  ) {
    get_users(
      keyword: $keyword
      filter: $filter
      condition: $condition
      skip: $skip
      first: $first
      orderBy: $orderBy
      dateFilterAnd: $dateFilterAnd
      not_like: $not_like
    ) {
      users {
        email_address
        id
      }
    }
  }
`;

export const GET_BOOKINGS_FOR_SEARCH = gql`
  query get_bookings_per_trip(
    $first: Int
    $conditions: [DynamicSearchInput]
    $not: [SearchInput]
    $or: [DynamicSearchInput]
    $date_range: DateRangeInputV2
    $keyword: String
  ) {
    get_bookings_per_trip(
      first: $first
      conditions: $conditions
      not: $not
      or: $or
      keyword: $keyword
      dateRange: $date_range
    ) {
      bookings {
        key
      }
    }
  }
`;

export const GET_DRIVER_ATTENDANCES = gql`
  query get_driver_attendances(
    $keyword: String
    $filter: [DynamicSearchInput]
    $condition: [DynamicSearchInput]
    $dateFilter: DateRangeInput
    $skip: Int
    $first: Int
    $orderBy: [OrderByInput]
  ) {
    get_driver_attendances(
      keyword: $keyword
      filter: $filter
      condition: $condition
      dateFilter: $dateFilter
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      attendances {
        id
        employee_id
        vehicle_id
        status_code_id
        personnel_employeeid
        personnel_name
        plateno
        status_code
        time_in
        time_in_activity
        time_out
        time_out_activity
      }
      count
      total
    }
  }
`;

export const GET_VEHICLE_ALLOCATIONS = gql`
  query get_allocations(
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $dateFilter: DateRangeInputV2
    $keyword: String
    $conditions: [DynamicSearchInput]
    $not: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
  ) {
    get_allocations(
      first: $first
      skip: $skip
      orderBy: $orderBy
      dateFilter: $dateFilter
      keyword: $keyword
      conditions: $conditions
      not: $not
      filter: $filter
    ) {
      count
      total
      allocations {
        id
        allocation_date
        trip_number
        client_id
        client_name
        group_id
        group_name
        vehicle_id
        vehicle_type
        #vehicle_type_id
        plateno
        pickup_location_id
        pickup_date
        pickup_location_code
        pickup_location_name
        dropoff_location_id
        dropoff_location_code
        dropoff_location_name
        delivery_date
        confirmation_duedate
        status_code_id
        status_code
      }
    }
  }
`;

export const GET_BOOKINGS_NO_HAULERS = gql`
  query getbookingsv2(
    $date_range: DateRangeInputV2
    $group_ids: [Int]
    $orderBy: [OrderByInput]
    $conditions: [DynamicSearchInput]
    $or: [DynamicSearchInput]
    $is_single_hauler: Boolean
    $first: Int
  ) {
    get_bookings_per_trip(
      dateRange: $date_range
      group_ids: $group_ids
      orderBy: $orderBy
      conditions: $conditions
      or: $or
      is_single_hauler: $is_single_hauler
      first: $first
    ) {
      count
      total
      bookings {
        key
        value {
          id
          trip_number
          client_name
          client_id
          group_names
          group_ids
          vehicle_type
          vehicle_plate_no
          dropoffs {
            geofence_id
            name
            arrival
            departure
            geofence_code
          }
          pickups {
            geofence_id
            name
            arrival
            departure
            geofence_code
          }
          dispatch_type
        }
      }
    }
  }
`;

export const GET_AVAILABLE_VEHICLES = gql`
  query VehiclesCount($dateRange: DateRangeInput, $group_ids: [Int]) {
    get_vehicles_count(dateRange: $dateRange, group_ids: $group_ids) {
      total
      status_count {
        available
      }
    }
  }
`;

export const GET_ALLOCATION_VEHICLES = gql`
query getVehicleInfos(
    $condition: [VehicleSearchInput]
    $first: Int
    $skip: Int
    $dateRange: DateRangeInput
    $orderBy: [OrderByInput]
    $group_ids: [Int]
  ) {
    get_vehicles(
      condition: $condition
      first: $first
      skip: $skip
      dateRange: $dateRange
      orderBy: $orderBy
      group_ids: $group_ids
    ) {
      total
      count
      vehicles {
        id
        plateno
        vehicle_type
        group_ids
        group_names
      }
    }
  }
`;

export const GET_TRIP_ALLOCATIONS = gql`
  query get_trip_allocations(
    $first: Int
    $skip: Int
    $orderBy: [OrderByInput]
    $dateFilter: [DateRangeInputV2]
    $keyword: String
    $conditions: [DynamicSearchInput]
    $not: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
  ) {
    get_trip_allocations(
      first: $first
      skip: $skip
      orderBy: $orderBy
      dateFilter: $dateFilter
      keyword: $keyword
      conditions: $conditions
      not: $not
      filter: $filter
    ) {
      trip_allocations {
        allocation_date
        confirmation_duedate
        trips_count
        confirm_count
        pending_count
        declined_count
        max_pickup_date
        min_pickup_date
      }
      total
      count
    }
  }
`;

export const GET_TRIP_ALLOCATION_BOOKING = gql`
  query getTripAllocBookings(
    $group_ids: [Int]
    $trip_numbers: [String!]
    $orderBy: [OrderByInput]
    $first: Int
  ) {
    get_bookings_per_trip(
      group_ids: $group_ids
      orderBy: $orderBy
      trip_numbers: $trip_numbers
      first: $first
    ) {
      bookings {
        key
        value {
          id
          pickups {
            geofence_id
            name
            arrival
          }
          dropoffs {
            geofence_id
            name
            arrival
          }
        }
      }
    }
  }
`;

export const GET_EXPO_TOKENS = gql`
  query getExpoTokens(
    $filter: [DynamicSearchInput]
    $condition: [SearchInput]
  ) {
    get_expo_tokens(filter: $filter, condition: $condition) {
      id
      token
      reference_id
      reference_name
      remarks
      is_login
      modified
      created
    }
  }
`;

export const GET_GEOFENCES_V2 = gql`
  query GeofencesQueryV2(
    $filter: [DynamicSearchInput]
    $first: Int
    $skip: Int
    $condition: [DynamicSearchInput]
    $orderBy: [OrderByInput]
    $keyword: String
    $bbox: [PointGeometryInput]
    $not: [DynamicSearchInput]
    $group_ids: [Int]
    $keyword_fields: [String]
  ) {
    get_geofences_v2(
      filter: $filter
      skip: $skip
      first: $first
      condition: $condition
      orderBy: $orderBy
      keyword: $keyword
      bbox: $bbox
      not: $not
      group_ids: $group_ids
      keyword_fields: $keyword_fields
    ) {
      geofences {
        id
        name
        address
        geofence_code
        geom
        # wkb_geometry {
        #   type
        #   coordinates
        # }
        location {
          lat
          lon
        }
        type
        category
        barangay
        municipality
        province
        region
        group_ids
        group_names
        window_time_end
        window_time_start
        radius
        client_ids
        client_names
        color
        opacity
        is_verified
        geofence_geom_id
        created
      }
      total
      count
    }
  }
`;