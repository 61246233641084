import React, {
  useContext,
  useReducer,
  useEffect,
  useState,
  useRef,
  useCallback
} from "react";
import { Link, withRouter } from "react-router-dom";
import { Fab, Collapse, Typography, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useQuery, useMutation } from "@apollo/client";
import Cookie from "js-cookie";
import SearchContext from "../../../context/SearchContext";

// Queries
import { GET_SCHEDULED_REPORTS } from "../../../graphql/Queries";
import { DELETE_SCHEDULE_REPORTS } from "../../../graphql/Mutations";

// Components
import ButtonLink from "../common/ButtonLink";
import Header from "../common/Header";
// import Pagination from "../common/Pagination";
import Table from "./ReportsTable";
import TableHead from "./ReportsTable/TableHead";
import TableBody from "./ReportsTable/TableBody";
import alertFire from "../utils/alertFire";
import ConfirmationDialog from "../../../utils/modals/ConfirmationDialog";
import DownloadButton from "../common/DownloadButton";

// Utility functions
import { getComparator, stableSort } from "./ReportsTable/helpers";
import AddLogs from "../../../utils/functions/AddLogs";

// Styles
import useAutomatedStyles from "./styles/automatedReports";
import useIconStyles from "./styles/iconStyles";
import { formatEvery } from "../utils/format";
import { defaultReducer, actionTypes } from "./reducers";

// Hooks
import useAllowedPermissions from "../../../utils/hooks/useAllowedPermissions";
import Forbidden from "../../../utils/Errors/403/Forbidden";
import Pagination from "../../../utils/table/Pagination";
import useUserContext from "../../../context/User/useUserContext";

const moduleName = process.env.REACT_APP_AUTOMATED_REPORTS_MODULE;

const Reports = () => {
  // const user = JSON.parse(Cookie.get("user") || "{}");
  const user = useUserContext();
  const group_ids = user.group_ids.map(id => id.toString());
  const _is_user_wti_admin =
    +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;

  const { searchKeyword } = useContext(SearchContext);
  const [state, dispatch] = useReducer(defaultReducer, {
    tableState: {
      data: [],
      rowsPerPage: 10,
      currentPage: 0,
      tableItemsCount: undefined,
      tableItemsTotal: 0,
      sort: {
        field: "id",
        direction: "desc"
      },
      selectedRows: []
    }
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const singleDeleteRef = useRef([]);

  const updateDownloadData = useCallback(() => {
    const { tableState } = state;
    const {
      data,
      // currentPage,
      // rowsPerPage,
      sort: { direction, field }
    } = tableState;

    const tableCurrentData = stableSort(data, getComparator(direction, field));
    // .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    return tableCurrentData.reduce(
      (accum, current) => {
        const reportType = current.report_type;
        const [generatedInterval, day] = current.sked.split(":");
        const timeSked = current.timesked;
        const every = formatEvery(day, timeSked, generatedInterval);
        const recipients = current.email_add.toString();

        accum.push([reportType, generatedInterval, every, recipients]);
        return accum;
      },
      [["Report Type", "Generated Interval", "Every", "Recipients"]]
    );
  }, [state.tableState]);

  const handleToggleDialog = () => {
    setDialogOpen(prev => !prev);
  };

  const handleSelectAllClick = event => {
    const newSelectedRows = state.tableState.data.map(row => row.id);
    if (event.target.checked) {
      dispatch({
        type: actionTypes.toggle_select_all,
        payload: {
          selectedRows: [...newSelectedRows]
        }
      });
      return;
    }
    dispatch({
      type: actionTypes.toggle_select_all,
      payload: { selectedRows: [] }
    });
  };

  const toggleRowCheckbox = id => {
    const checkedRows = state.tableState.selectedRows;
    const selectedIndex = checkedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(checkedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(checkedRows.slice(1));
    } else if (selectedIndex === checkedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(checkedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        checkedRows.slice(0, selectedIndex),
        checkedRows.slice(selectedIndex + 1)
      );
    }
    dispatch({
      type: actionTypes.toggle_select_all,
      payload: { selectedRows: newSelectedRows }
    });
  };

  const handleSort = (fieldName, dir) => {
    let direction = "asc";
    if (fieldName === state.tableState.sort.field) {
      direction = dir === "asc" ? "desc" : "asc";
    }
    dispatch({
      type: actionTypes.sort_table,
      payload: {
        orderBy: {
          direction,
          field: fieldName
        }
      }
    });
  };

  const handlePageChange = newPage => {
    dispatch({ type: actionTypes.change_page, payload: { newPage } });
  };

  const handleChangeRowsPerPage = rowsPerPage => {
    dispatch({
      type: actionTypes.change_rows_per_page,
      payload: { rowsPerPage }
    });
  };

  const styles = useAutomatedStyles({
    selectedRows: state.tableState.selectedRows
  });
  const iconStyles = useIconStyles();

  const { loading, refetch } = useQuery(GET_SCHEDULED_REPORTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      keyword:
        searchKeyword.value && searchKeyword.submit ? searchKeyword.value : "",
      first: state.tableState.rowsPerPage,
      orderBy: state.tableState.sort,
      client_ids: _is_user_wti_admin ? [] : [+user.client.id],
      // ...(!_is_user_wti_admin && {
      //   condition: [{ field: "group_ids", value: group_ids }]
      // }),
      skip: state.tableState.rowsPerPage * state.tableState.currentPage
    },
    onCompleted: response => {
      const {
        total,
        count,
        scheduled_reports
      } = response.get_scheduled_reports;
      dispatch({
        type: actionTypes.load_data,
        payload: {
          tableData: scheduled_reports,
          count,
          total
        }
      });
      // updateDownloadData();
    }
  });

  const [deleteScheduleReports] = useMutation(DELETE_SCHEDULE_REPORTS);

  const handleDelete = () => {
    const deletedItems =
      singleDeleteRef.current.length > 0
        ? singleDeleteRef.current
        : state.tableState.selectedRows;

    deleteScheduleReports({
      variables: {
        condition: [
          {
            field: "id",
            value: deletedItems
            // value: state.tableState.selectedRows
          }
        ]
      }
    })
      .then(response => {
        if (response.data.delete_scheduled_report_all.success) {
          const deletedLength = deletedItems.length;
          AddLogs(
            moduleName,
            "delete_report",
            `Report ID: ${deletedItems.toString()}, Deleted ${deletedLength} report${deletedLength > 1 ? "s" : ""}`
          );
          alertFire(() => {
            refetch({
              keyword: searchKeyword.value,
              first: state.tableState.rowsPerPage,
              orderBy: state.tableState.sort,
              skip: state.tableState.rowsPerPage * state.tableState.currentPage
            });

            if (state.tableState.selectedRows.length > 0) {
              dispatch({ type: actionTypes.reset_selected_rows });
            }

            singleDeleteRef.current = [];
          }, "Deleted");
        }
      })
      .catch(() => {
        alertFire(() => {}, "Error", false);
      });
  };

  const handleMultipleDelete = () => {
    singleDeleteRef.current = [];
    handleToggleDialog();
  };

  const handleSingleDelete = id => {
    dispatch({ type: actionTypes.reset_selected_rows });
    singleDeleteRef.current = id;
    handleToggleDialog();
  };

  useEffect(() => {
    if (searchKeyword.submit) {
      refetch({
        keyword: searchKeyword.value,
        first: state.tableState.rowsPerPage,
        orderBy: state.tableState.sort,
        skip: state.tableState.rowsPerPage * state.tableState.currentPage
      }).then(() => {
        AddLogs(moduleName, "search", searchKeyword.value);
        dispatch({ type: actionTypes.reset_current_page });
      });
    }
  }, [searchKeyword.submit]);

  const { loading: isPermissionLoading, permissions } = useAllowedPermissions(
    "/reports/automated/"
  );

  if (isPermissionLoading) {
    return <React.Fragment />;
  }

  // if (!permissions?.view) {
  //   // return <Typography>403!!!!</Typography>;
  //   return <Forbidden />;
  // }

  return (
    <div className={styles.root}>
      {!permissions?.view ? (
        <Forbidden />
      ) : (
        <>
          <Header>
            <div style={{ flex: 1 }}>
              <ButtonLink text="Manual Reports" resource="/reports/" />
              <DownloadButton data={updateDownloadData()} />
            </div>
            <div>
              <Pagination
                rowsPerPage={state.tableState.rowsPerPage}
                total={Math.ceil(
                  state.tableState.tableItemsTotal /
                    state.tableState.rowsPerPage
                )}
                currentPage={state.tableState.currentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handlePageChange}
                onPageUp={handlePageChange}
                onPageDown={handlePageChange}
              />
              {/* <Pagination
                count={state.tableState.tableItemsTotal}
                page={state.tableState.currentPage}
                rowsPerPage={state.tableState.rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </div>
          </Header>
          <Collapse
            in={state.tableState.selectedRows.length > 0}
            classes={{ wrapper: styles.collapseContainer }}
          >
            <Typography
              variant="subtitle2"
              classes={{ root: styles.collapseText }}
            >
              Selected {state.tableState.selectedRows.length}{" "}
              {state.tableState.selectedRows.length > 1 ? `items` : `item`}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              classes={{ root: styles.collapseDeleteButton }}
              onClick={handleMultipleDelete}
            >
              Delete Selected
            </Button>
          </Collapse>
          <Table>
            <TableHead
              selectedRows={state.tableState.selectedRows}
              toggleSelectAll={handleSelectAllClick}
              tableItemsCount={state.tableState.tableItemsCount}
              sort={state.tableState.sort}
              handleSort={handleSort}
              permissions={permissions}
            />
            <TableBody
              isDataLoading={loading}
              tableInfo={state.tableState}
              toggleRowCheckbox={toggleRowCheckbox}
              handleSingleDelete={handleSingleDelete}
              permissions={permissions}
            />
          </Table>
          {permissions?.edit && (
            <Link
              className={iconStyles.link}
              to={{
                pathname: "/reports/automated/add",
                state: {
                  moduleName
                }
              }}
            >
              <Fab color="primary" aria-label="add">
                <AddIcon fontSize="large" htmlColor="#fff" />
              </Fab>
            </Link>
          )}

          <ConfirmationDialog
            toggle={dialogOpen}
            close={() => handleToggleDialog()}
            fn={() => handleDelete()}
            title="Delete"
            content={`Are you sure you want to delete ${
              state.tableState.selectedRows.length > 1 ? "these" : "this"
            } data?`}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(Reports);
