import React, { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Grid,
  Checkbox,
  Chip
} from "@material-ui/core";
import Swal from "sweetalert2";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import { Helper } from "../Utils";
import { ADD_DASHBOARD_FILTER } from "../../graphql/Mutations";
import { GET_DASHBOARDS_FILTERS } from "../../graphql/Queries";
import validate from "../../validation";
import AddLogs from "../../utils/functions/AddLogs";

const FilterForm = props => {
  const {
    openFilterForm,
    classes,
    setOpenFilterForm,
    filterValues,
    pickups,
    dropoffs,
    clients,
    accounts,
    serviceTypes,
    tabValue,
    user,
    saveFilters,
    setDynamicDialog,
    vehicleTypes
  } = props;

  const [filterData, setFilterData] = useState({});
  const [nameHelper, setNameHelper] = useState("");
  const [locations, setLocations] = useState([]);

  const [filterMutation, { loading }] = useMutation(ADD_DASHBOARD_FILTER, {
    onCompleted: data => {
      if (data.add_dashboard_filter.success) {
        setOpenFilterForm(false);
        setFilterData({
          ...filterData,
          name: ""
        });
        Swal.fire({
          icon: "success",
          text: "Saved.",
          timer: 3000
        });
      }
    },
    update: (cache, { data: { add_dashboard_filter } }) => {
      const { get_dashboard_filters } = cache.readQuery({
        query: GET_DASHBOARDS_FILTERS,
        variables: {
          user_id: user.id,
          dashboard_type: tabValue === 0 ? "ontime" : "demurrage"
        }
      });
      const data = {
        label: filterData.name,
        value: add_dashboard_filter.id
      };
      cache.writeQuery({
        query: GET_DASHBOARDS_FILTERS,
        variables: {
          user_id: user.id,
          dashboard_type: tabValue === 0 ? "ontime" : "demurrage"
        },
        data: {
          get_dashboard_filters: get_dashboard_filters.concat(data)
        }
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong"
      });
    }
  });

  const saveFilter = () => {
    if (filterData.name === "") {
      setNameHelper(<Helper message="This field is required" />);
    } else if (
      _.findIndex(saveFilters, f => {
        return f.label === filterData.name;
      }) !== -1
    ) {
      setNameHelper(<Helper message="Filter name already exist" />);
    } else if (saveFilters.length >= 20) {
      setOpenFilterForm(false);
      // Swal.fire({
      //   icon: "error",
      //   text: "Maximum number of saved filter has been reached."
      // });
      setDynamicDialog({
        title: "Warning!",
        open: true,
        content:
          "Maximum number of saved filters is reached. Please delete and try again.",
        data: null,
        yesFn: null
      });
    } else if (filterData.name.length > 100) {
      setNameHelper(<Helper message="Maximum characters length is 100" />);
    } else if (!validate("alphanumeric", filterData.name)) {
      setNameHelper(<Helper message="Alphanumeric characters only." />);
    } else {
      let variables = {
        name: filterData.name,

        client_id: Number(filterData.client.value),
        account: filterData.account.value,

        dashboard_type: tabValue === 0 ? "ontime" : "demurrage",
        user_id: user.id,
        group_ids: user.group_ids
      };
      if (tabValue === 0) {
        variables = {
          ...variables,
          pickup_ids: filterData.pickups.map(pu => {
            return pu.value;
          }),
          dropoff_ids: filterData.dropoffs.map(dropoff => {
            return dropoff.value;
          }),
          service_type_id: Number(filterData.serviceType.value),
          location_ids: filterData.locations.map(loc => {
            return loc.value;
          })
        };

        AddLogs(
          process.env.REACT_APP_DASHBOARD_MODULE,
          "save_filter_OTTA/OTSA/OTD",
          filterData.name
        );
      } else {
        variables = {
          ...variables,
          pickup_ids: filterData.locations.map(loc => {
            return loc.value;
          }),

          vehicle_type_id: Number(filterData.vehicleType.value)
        };

        AddLogs(
          process.env.REACT_APP_DASHBOARD_MODULE,
          "save_filter_demurrage",
          filterData.name
        );
      }

      filterMutation({ variables: variables });
    }
  };

  useEffect(() => {
    setFilterData({ ...filterValues, name: "" });
  }, [filterValues]);

  useEffect(() => {
    const merged = _.unionBy(pickups, dropoffs, "value");
    setLocations(merged);
  }, [pickups, dropoffs]);

  return (
    <Dialog
      open={openFilterForm}
      PaperProps={{ style: { width: 600 } }}
      // onBackdropClick={() => setOpenFilterForm(false)}
      onClose={() => setOpenFilterForm(false)}
    >
      <DialogTitle>Save Filter?</DialogTitle>

      <DialogContent>
        <TextField
          label="Filter Name"
          placeholder="Enter filter name"
          fullWidth
          margin="normal"
          value={filterData.name}
          onChange={e => {
            setFilterData({ ...filterData, name: e.target.value });
            setNameHelper("");
          }}
          helperText={nameHelper}
          InputLabelProps={{
            shrink: true,
            style: { color: "black", fontWeight: "bold" },
            required: true
          }}
        />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Summary:</Typography>
          </Grid>
          <Grid item xs={6}>
            {tabValue === 0 ? (
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={pickups}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={filterData.pickups.length === 0 ? "All" : ""}
                    label="Pickup"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                    margin="normal"
                  />
                )}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      pickups: nv
                    };
                  })
                }
                disableClearable
                value={filterData.pickups}
              />
            ) : (
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={locations}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={filterData.locations.length === 0 ? "All" : ""}
                    label="Location (Land)"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                    margin="normal"
                  />
                )}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      locations: nv
                    };
                  })
                }
                disableClearable
                value={filterData.locations}
              />
            )}
            {tabValue === 0 ? (
              <TextField
                label="Port/CY"
                placeholder="Enter Port/CY"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black", fontWeight: "bold" }
                }}
                margin="normal"
              />
            ) : (
              <Autocomplete
                options={vehicleTypes}
                getOptionLabel={option => option.label}
                renderOption={option => option.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Vehicle Type"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                  />
                )}
                value={filterData.vehicleType}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      vehicleType: nv
                    };
                  })
                }
                disableClearable
              />
            )}
            {tabValue === 0 && (
              <Autocomplete
                options={serviceTypes}
                getOptionLabel={option => option.label}
                renderOption={option => option.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Service Types"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                  />
                )}
                value={filterData.serviceType}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      serviceType: nv
                    };
                  })
                }
                disableClearable
              />
            )}
            <Autocomplete
              options={accounts}
              getOptionLabel={option => option.label}
              renderOption={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Account"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  placeholder="Enter account"
                  margin="normal"
                />
              )}
              value={filterData.account}
              onChange={(e, nv) =>
                setFilterData(prevFilter => {
                  return {
                    ...prevFilter,
                    account: nv
                  };
                })
              }
              disableClearable
            />
          </Grid>
          <Grid item xs={6}>
            {tabValue === 0 ? (
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={dropoffs}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={filterData.dropoffs.length === 0 ? "All" : ""}
                    label="Dropoff"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                    margin="normal"
                  />
                )}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      dropoffs: nv
                    };
                  })
                }
                disableClearable
                value={filterData.dropoffs}
              />
            ) : (
              <TextField
                label="Locations (Port/CY)"
                placeholder="Enter Port/CY"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black", fontWeight: "bold" }
                }}
                margin="normal"
              />
            )}
            {tabValue === 0 && (
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={locations}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={filterData.locations.length === 0 ? "All" : ""}
                    label="Location"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black", fontWeight: "bold" }
                    }}
                    margin="normal"
                  />
                )}
                onChange={(e, nv) =>
                  setFilterData(prevFilter => {
                    return {
                      ...prevFilter,
                      locations: nv
                    };
                  })
                }
                disableClearable
                value={filterData.locations}
              />
            )}
            <Autocomplete
              options={clients}
              getOptionLabel={option => option.label}
              renderOption={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Hauler/Shipping Line"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                />
              )}
              value={filterData.client}
              onChange={(e, nv) =>
                setFilterData(prevFilter => {
                  return {
                    ...prevFilter,
                    client: nv
                  };
                })
              }
              disableClearable
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.secButton}
          onClick={() => setOpenFilterForm(false)}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.primButton}
          onClick={saveFilter}
          disabled={loading}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterForm;
