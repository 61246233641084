import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  FormHelperText
} from "@material-ui/core";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { useLazyQuery } from "@apollo/client";
import Cookie from "js-cookie";
import _, { isNull } from "lodash";
import {
  GET_VEHICLES_LOOKUP,
  GET_BOOKING_TRIP_NUMBER,
  GET_DEVICES
} from "../../graphql/Queries";
import AddLogs from "../../utils/functions/AddLogs";
import client from "../../Client";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CalendarToday as DateIcon } from "@material-ui/icons";
import useUserContext from "../../context/User/useUserContext";

let timeout = null;
const cicoStartDate = "2023-01-01";

const HistorySelector = props => {
  const {
    open,
    vehicle: initVehicle,
    onClose,
    title,
    // redirectTo,
    isFromHistory,
    initialFilterVal,
    onDataReprocess,
    isFromDevices
  } = props;

  const user = JSON.parse(Cookie.get("user") || "{}");
  const userCtx = useUserContext();
  const [tripNumbers, setTripNumbers] = useState([]);
  const [vehicle, setVehicle] = useState(initVehicle);
  // const [vehicles, setVehicles] = useState([]);
  const [options, setOptions] = useState([]);
  const [activeTripNumber, setActiveTripNumber] = useState(null);
  const isWTIAdmin = +user?.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const clientType = user?.client?.is_restricted;
  const inputRef = useRef();
  const features =
    "menubar=yes,loaction=yes,resizaable=no,scrollbars=yes,status=no,noopener";

  const [getVehicleOptions, { loading: vehicle_loader }] = useLazyQuery(
    GET_VEHICLES_LOOKUP,
    {
      onCompleted: data => {
        const { get_vehicles } = data;
        setOptions(get_vehicles.vehicles);
      }
    }
  );
  const [getDeviceOptions, { loading: device_loader }] = useLazyQuery(
    GET_DEVICES,
    {
      onCompleted: data => {
        const { get_devices } = data;
        setOptions(get_devices.devices);
      }
    }
  );

  const getVehicleInfo = async () => {
    const vehicleInfo = await client.query({
      query: GET_VEHICLES_LOOKUP,
      variables: {
        filter: [
          {
            field: "plateno",
            value: initVehicle?.plateno
          },
          // {
          //   field: "devices.id",
          //   value: initVehicle?.device_id?.toString()
          // }
        ],
        group_ids: initVehicle.group_ids,
        first: 100
      },
    });
    if (vehicleInfo?.data?.get_vehicles?.vehicles?.length) {
      const similarVehicles = vehicleInfo.data.get_vehicles.vehicles;
      const vehicleMatch = similarVehicles.find(
        v => v.plateno.toLowerCase() === initVehicle.plateno.toLowerCase()
      );

      setVehicle({
        ...initVehicle,
        id: vehicleMatch?.id
      });
    }
  };

  useEffect(() => {
    if (initVehicle && !isFromHistory) {
      if (initVehicle.id || isFromDevices) {
        setVehicle(initVehicle);
      } else if (
        initVehicle.plateno &&
        initVehicle.device_id &&
        initVehicle?.group_ids
      ) {
        getVehicleInfo();
      }
    }
  }, [initVehicle]);

  const [historyFilterVal, setHistoryFilterVal] = useState({
    dayType: 0,
    from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
    to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
    tripNumber: null,
    isValid: true,
    vehicleClientId: null,
    activeVehicle: null
  });

  const maxDate = date => {
    const dateToday = moment(new Date());
    const dateSelected = moment(date);
    const diff = dateToday.diff(dateSelected, "days");
    if (diff === 0 || diff === 1) {
      return dateToday.endOf("day").format("YYYY-MM-DD HH:mm:ss");
    }

    const max = dateSelected.add(13, "days");
    if (
      moment(date).isBefore(cicoStartDate) &&
      max.isSameOrAfter(cicoStartDate)
    ) {
      return "2022-12-31 23:59:00";
    }

    return max.format("YYYY-MM-DD HH:mm:ss");
  };

  const [queryTripNumbers, { data: filteredTrips, loading }] = useLazyQuery(
    GET_BOOKING_TRIP_NUMBER,
    {
      onCompleted: data => {
        if (data) {
          const trip_numbers = data.get_bookings.bookings
            // .filter(b => b.ordinal === 1)
            .filter(t => {
              if (t.status_code_id === process.env.REACT_APP_INCOMING_STATUS) {
                if (t.data_complete) {
                  // return true;
                  return (
                    t.status_code_id !==
                      process.env.REACT_APP_ACCEPTED_STATUS ||
                    moment(t.pickups[0].arrival).isBefore(
                      moment().add(2, "hours")
                    )
                  );
                }
                return false;
              }

              return (
                t.status_code_id !== process.env.REACT_APP_ACCEPTED_STATUS ||
                moment(t.pickups[0].arrival).isBefore(moment().add(2, "hours"))
              );
            })
            .map(t => t.trip_number);
          setTripNumbers(_.uniq(trip_numbers));
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (vehicle) {
      setHistoryFilterVal(prev => ({
        ...prev,
        isValid:
          (user?.client?.id === vehicle.client_id || isWTIAdmin || !clientType) &&
          moment(initialFilterVal?.end).isBefore(
            moment
              .min(
                moment(maxDate(initialFilterVal?.start)).endOf("day"),
                moment(new Date()).endOf("day")
              )
              .format("YYYY-MM-DD HH:mm:ss")
          ),
        vehicleClientId: vehicle.client_id,
        activeVehicle: {
          id: vehicle.id,
          device_id: vehicle.device_id
        },
        plateno: vehicle?.plateno,
        ...(!_.isEmpty(initialFilterVal) && {
          from: initialFilterVal.start,
          to: initialFilterVal.end,
          dayType: 1
        })
      }));
      if (
        !initialFilterVal?.tripNumber &&
        (vehicle.plateno || vehicle.device_id)
      ) {
        queryTripNumbers({
          variables: {
            first: 500,
            dateRange: {
              start: isFromHistory
                ? historyFilterVal.from
                : `${moment().format("YYYY-MM-DD")} 00:00:00`,
              end: isFromHistory
                ? historyFilterVal.to
                : `${moment().format("YYYY-MM-DD")} 23:59:59`,
              field: "pickups.arrival"
            },
            or: [
              {
                field: "vehicle_id",
                value: vehicle?.device_id?.toString()
              },
              {
                field: "vehicle_plate_no",
                value: vehicle?.plateno || ""
              }
            ],
            not: [
              {
                field: "status_code_id",
                value: `${process.env.REACT_APP_ACCEPTED_STATUS}`
              },
              {
                field: "status_code_id",
                value: `${process.env.REACT_APP_SCHEDULED_STATUS}`
              }
            ]
          }
        });
      }
    }
  }, [vehicle]);

  const refetchTripNumbers = (dateFrom, dateTo) => {
    setTripNumbers([]);
    queryTripNumbers({
      variables: {
        first: 500,
        dateRange: {
          start: dateFrom,
          end: dateTo,
          field: "pickups.arrival"
        },
        or: [
          {
            field: "vehicle_id",
            value: vehicle?.device_id?.toString()
          },
          {
            field: "vehicle_plate_no",
            value: vehicle?.plateno || ""
          }
        ],
        not: [
          {
            field: "status_code_id",
            value: `${process.env.REACT_APP_ACCEPTED_STATUS}`
          },
          {
            field: "status_code_id",
            value: `${process.env.REACT_APP_SCHEDULED_STATUS}`
          }
        ]
      }
    });
  };

  const handleDaySelectorChanged = event => {
    const { name, value } = event?.target || event;

    if (name === "dayType") {
      if (value === 0) {
        const date1 = `${moment().format("YYYY-MM-DD")} 00:00:00`;
        const date2 = `${moment().format("YYYY-MM-DD")} 23:59:59`;
        setHistoryFilterVal(prev => ({
          ...prev,
          dayType: value,
          from: date1,
          to: date2,
          isValid:
            user?.client?.id === prev.vehicleClientId || isWTIAdmin || !clientType
              ? true
              : !isNull(prev.tripNumber)
        }));
        refetchTripNumbers(date1, date2);
      } else if (value === 1) {
        setHistoryFilterVal(prev => ({
          ...prev,
          dayType: value,
          isValid:
            user?.client?.id === prev.vehicleClientId || isWTIAdmin || !clientType
              ? true
              : !isNull(prev.tripNumber)
        }));
        refetchTripNumbers(historyFilterVal.from, historyFilterVal.to);
      } else {
        const date1 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const date2 = moment(date1, "YYYY-MM-DD HH:mm:ss").subtract({
          hours: value
        });
        setHistoryFilterVal(prev => ({
          ...prev,
          dayType: value,
          from: `${date2.format("YYYY-MM-DD HH:mm:ss")}`,
          to: `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
          isValid:
            user?.client?.id === prev.vehicleClientId || isWTIAdmin || !clientType
              ? true
              : !isNull(prev.tripNumber)
        }));
        refetchTripNumbers(date2.format("YYYY-MM-DD HH:mm:ss"), date1);
      }
    } else {
      // case 'Custom'
      let date1 = moment(historyFilterVal.from);
      let date2 = moment(historyFilterVal.to);
      if (name === "from") {
        date1 = moment(value);
        const diffDate = date1.diff(date2, "days");
        const isValid = diffDate < 1 && diffDate > -14;
        if (!isValid) {
          date2 = moment(value, "YYYY-MM-DD HH:mm:ss").add({
            days: 13
          });
          if (date2.isAfter(moment(new Date()))) {
            date2 = moment(new Date());
          }
          // when date1 is in 2022 and default date2 is past 2023, date2 is set as dec 31, 2022
          if (
            date1.isBefore(cicoStartDate) &&
            date2.isSameOrAfter(cicoStartDate)
          ) {
            date2 = "2022-12-31 23:59:00";
          }
        }
      } else {
        date2 = moment(value);
      }
      const diff = date1.diff(date2, "days");
      const isValidDateRange =
        diff < 1 && diff > -14 && moment(date2).isAfter(moment(date1));
      const newDateFrom = `${moment(date1).format("YYYY-MM-DD HH:mm:ss")}`;
      const newDateTo = `${moment(date2).format("YYYY-MM-DD HH:mm:ss")}`;
      setHistoryFilterVal(prev => ({
        ...prev,
        from: newDateFrom,
        to: newDateTo,
        isValid:
          user?.client?.id === prev.vehicleClientId || isWTIAdmin || !clientType
            ? isValidDateRange
            : isValidDateRange && prev.tripNumber
      }));
      refetchTripNumbers(newDateFrom, newDateTo);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setVehicle(null);
        setHistoryFilterVal({
          dayType: 0,
          from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
          to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
          tripNumber: null,
          isValid: true,
          vehicleClientId: null
        });
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      style={{
        width: 350,
        margin: "0 auto"
      }}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {isFromHistory && (
          <Autocomplete
            options={options}
            getOptionLabel={option =>
              isFromDevices ? option.devicealias : option.plateno
            }
            loading={loading || vehicle_loader || device_loader}
            value={vehicle}
            // loading={vehicle_loader}
            renderInput={params => (
              <TextField
                {...params}
                label={isFromDevices ? "Device Alias" : "Plate Number"}
                InputLabelProps={{
                  required: true,
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold"
                  }
                }}
                margin="normal"
                placeholder={isFromDevices ? "Select Device Alias" : "Select Plate Number"}
                onChange={e => {
                  inputRef.current = e.target.value;
                  if (timeout) clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    if (!isFromDevices) {
                      getVehicleOptions({
                        variables: {
                          // keyword: vehicleRef.current,
                          filter: [
                            {
                              field: "vehicle_info.plateno",
                              value: inputRef.current
                            }
                          ],
                          group_ids: isWTIAdmin ? [] : userCtx.client.group_ids,
                          first: 15
                        }
                      });
                    } else {
                      getDeviceOptions({
                        variables: {
                          // keyword: vehicleRef.current,
                          filter: [
                            {
                              field: "devicealias",
                              value: inputRef.current
                            }
                          ],
                          first: 5
                        }
                      });
                    }
                  }, 1000);
                }}
              />
            )}
            name="plateNumber"
            onChange={(event, value) => {
              if (isFromDevices) {
                setVehicle({
                  // ...value,
                  id: value?.vehicle_info?.id,
                  client_id: value?.client_id,
                  plateno: value?.vehicle_info?.plateno,
                  device_id: value?.id,
                  devicealias: value?.devicealias
                })
              } else {
                setVehicle(value);
              }
            }}
          />
        )}
        <TextField
          InputLabelProps={{
            required: true,
            shrink: true,
            style: {
              color: "black",
              fontWeight: "bold"
            }
          }}
          autoFocus
          margin="dense"
          id="name"
          label="Date"
          placeholder="Select"
          type="email"
          fullWidth
          value={historyFilterVal.dayType}
          name="dayType"
          select
          onChange={e => handleDaySelectorChanged(e)}
        >
          {[
            { value: 0, label: "Today" },
            { value: 4, label: "4 hours" },
            { value: 8, label: "8 hours" },
            { value: 12, label: "12 hours" },
            { value: 24, label: "24 hours" },
            { value: 1, label: "Custom" }
          ].map(opt => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </TextField>
        {historyFilterVal.dayType === 1 ? (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                InputLabelProps={{
                  required: true,
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold"
                  }
                }}
                disableFuture
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateIcon style={{ fontSize: "1rem" }} />
                    </InputAdornment>
                  )
                }}
                value={historyFilterVal.from}
                format="L HH:mm"
                ampm={false}
                onChange={date => {
                  handleDaySelectorChanged({
                    name: "from",
                    value: moment(date).format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                label="From"
                todayLabel="NOW"
                showTodayButton
                emptyLabel="Select Date/Time"
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                InputLabelProps={{
                  required: true,
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold"
                  }
                }}
                disableFuture
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DateIcon style={{ fontSize: "1rem" }} />
                    </InputAdornment>
                  )
                }}
                value={historyFilterVal.to}
                minDate={moment(historyFilterVal.from).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                minDateMessage={""}
                maxDate={moment
                  .min(
                    moment(maxDate(historyFilterVal.from)),
                    moment(new Date())
                  )
                  .format("YYYY-MM-DD HH:mm:ss")}
                format="L HH:mm"
                ampm={false}
                onChange={date => {
                  handleDaySelectorChanged({
                    name: "to",
                    value: moment(date).format("YYYY-MM-DD HH:mm:ss")
                  });
                }}
                label="To"
                todayLabel="NOW"
                showTodayButton
                emptyLabel="Select Date/Time"
                fullWidth
              />
            </MuiPickersUtilsProvider>
            {moment(historyFilterVal.from).isAfter(
              moment(historyFilterVal.to)
            ) && (
              <FormHelperText error>
                Date should not be before minimal date
              </FormHelperText>
            )}
          </>
        ) : null}
        {initialFilterVal?.tripNumber ? (
          <TextField
            value={initialFilterVal.tripNumber}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black",
                fontWeight: "bold"
              }
            }}
            margin="dense"
            label="Trip Number"
            fullWidth
            disabled
          />
        ) : (
          <Autocomplete
            options={tripNumbers}
            getOptionLabel={option => option}
            loading={loading}
            noOptionsText="No trips available"
            value={historyFilterVal.tripNumber}
            renderInput={params => (
              <TextField
                {...params}
                name="tripNumber"
                label="Trip Number"
                InputLabelProps={{
                  required:
                    user?.client?.id !== historyFilterVal.vehicleClientId &&
                    clientType,
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold"
                  }
                }}
                margin="normal"
              />
            )}
            name="tripNumber"
            onChange={(event, value) => {
              setActiveTripNumber(value);
              const tripIndex = filteredTrips.get_bookings.bookings.findIndex(
                trip => trip.trip_number === value
              );
              const tripInfo = filteredTrips.get_bookings.bookings[tripIndex];
              const date1 = moment(historyFilterVal.from);
              const date2 = moment(historyFilterVal.to);
              const diff = date1.diff(date2, "days");

              if (historyFilterVal.dayType === 1) {
                const isValidDateRange = diff < 1 && diff > -14;
                setHistoryFilterVal(prev => ({
                  ...prev,
                  tripInfo,
                  tripNumber: value,
                  isValid:
                    user?.client?.id === prev.vehicleClientId ||
                    isWTIAdmin ||
                    !clientType
                      ? isValidDateRange
                      : isValidDateRange && value
                }));
              } else {
                setHistoryFilterVal(prev => ({
                  ...prev,
                  tripInfo,
                  tripNumber: value,
                  isValid:
                    user?.client?.id === prev.vehicleClientId ||
                    value ||
                    isWTIAdmin ||
                    !clientType
                }));
              }
            }}
          />
        )}
      </DialogContent>
      <DialogContent>
        <Button
          disabled={
            !historyFilterVal.isValid ||
            (isFromHistory && !vehicle) ||
            (clientType &&
              _.isEmpty(activeTripNumber) &&
              !_.isEqual(vehicle?.client_id, user?.client?.id))
          }
          onClick={() => {
            if (historyFilterVal.isValid) {
              const { plateno, from, to, tripInfo } = historyFilterVal;
              let newLogs = [from, to];
              if (isFromDevices) {
                newLogs = ["device", vehicle?.devicealias, ...newLogs];
              } else {
                newLogs = ["vehicle", plateno, ...newLogs];
              }
              if (activeTripNumber) newLogs.push(activeTripNumber);
              AddLogs(process.env.REACT_APP_FLEET_MODULE, "history", newLogs);
              onClose();
              // redirectTo("/monitoring/history", {
              //   tripInfo,
              //   vehicle_id: vehicle.id,
              //   device_id: vehicle.device_id,
              //   date: {
              //     from: historyFilterVal.from,
              //     to: historyFilterVal.to,
              //     dayType: historyFilterVal.dayType
              //   },
              //   trip_number: activeTripNumber,
              //   historyFilterVal
              // });
              const selectedVehicleHistory = {
                trip: {
                  id: activeTripNumber,
                  details: tripInfo
                },
                vehicle: {
                  id: vehicle?.id || null,
                  device_id: vehicle.device_id,
                  plateno: vehicle.plateno
                },
                date: {
                  from: historyFilterVal.from,
                  to: historyFilterVal.to,
                  dayType: historyFilterVal.dayType
                },
                filteredValue: historyFilterVal,
                isFromDevices
              };

              if (onDataReprocess) {
                onDataReprocess(selectedVehicleHistory);
              } else {
                const vehicleHistoryEncoded = encodeURI(
                  JSON.stringify(selectedVehicleHistory)
                );
                window.open(
                  `/monitoring/:view/history?details=${vehicleHistoryEncoded}`,
                  "_blank",
                  features
                );
              }
            }
          }}
          style={{
            borderRadius: 15
          }}
          size="small"
          color="primary"
          variant="contained"
          fullWidth
        >
          {isFromHistory ? "Update" : "View"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default HistorySelector;
