import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./InfoBubbleDetailStyle";

const InfoBubbleDetail = (props) => {
    const classes = useStyles();
    const { label, content } = props;

    return <Box component={"div"} className={classes.infoDetailBox}>  
        <Typography variant="caption" className={classes.label}>{label}</Typography>
        <Typography variant="caption" className={classes.content}>{content}</Typography>
        {props.children}
    </Box>      
};

export default InfoBubbleDetail;

