/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from "react";
import {
  Chip,
  TextField,
  Checkbox,
  FormControl,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLazyQuery } from "@apollo/client";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import UserContext from "../../../../context/UserContext";

// Styles
import useFormStyles from "../../../../styles/useFormStyles";
import useChipInputStyle from "./HaulerChipInput.styles";

import { GET_GROUPS_OPTIONS } from "../../../../graphql/Queries";
import useUserContext from "../../../../context/User/useUserContext";

const HaulersChipInput = React.forwardRef((props, ref) => {
  const {
    initialValues = [],
    loading: initialValuesLoading,
    setSelectedHaulers: setHaulers,
    readOnly
  } = props;
  const [selectedHaulers, setSelectedHaulers] = useState(initialValues);
  const { client: { group_ids, group_names }, user_level_id } = useUserContext();
  const isWTIAdmin = +user_level_id === +process.env.REACT_APP_WTI_ADMIN;

  const [fetchHaulers, { data, called, loading }] = useLazyQuery(
    GET_GROUPS_OPTIONS,
    {
      notifyOnNetworkStatusChange: true
    }
  );
  const [haulersOptions, setHaulersOptions] = useState(() =>
    group_ids.map((id, index) => {
      return {
        group_id: +id,
        group_name: group_names[index]
      };
    })
  );

  useEffect(() => {
    if (+user_level_id === +process.env.REACT_APP_WTI_ADMIN) {
      fetchHaulers({
        variables: {
          first: 10
        }
      });
    }
  }, []);

  useEffect(() => {
    const defaultHaulers = isWTIAdmin ? [] : [...haulersOptions];
    const initialHaulers =
      selectedHaulers.length > 0 ? selectedHaulers : defaultHaulers;
    ref.current = selectedHaulers.length > 0 ? selectedHaulers : [];

    setHaulers(() => {
      return initialHaulers.map(({ group_id }) => +group_id);
    });

    return () => {
      ref.current = initialHaulers;
    };
  }, []);

  const formStyles = useFormStyles();
  const chipInputStyles = useChipInputStyle();

  const handleSelectedChange = (e, newSelectedHaulers) => {
    const defaultHaulers = isWTIAdmin ? [] : [...haulersOptions];

    ref.current = newSelectedHaulers.length === 0 ? [] : newSelectedHaulers;
    setSelectedHaulers([...newSelectedHaulers]);
    setHaulers(() => {
      if (newSelectedHaulers.length === 0) {
        return defaultHaulers.map(({ group_id }) => +group_id);
      }

      return newSelectedHaulers.map(({ group_id }) => +group_id);
    });
  };

  React.useEffect(() => {
    if (called && !loading && data && data.get_groups.groups) {
      const { groups } = data.get_groups;
      setHaulersOptions(
        groups.map(({ group_id, group_name }) => ({
          group_id: +group_id,
          group_name
        }))
      );
    }
  }, [called, data, loading]);

  const keywordChange = React.useCallback(
    event => {
      fetchHaulers({
        variables: {
          keyword: event.target.value,
          first: 10
        }
      });
    },
    [fetchHaulers, setHaulersOptions]
  );

  const debounceKeywordChange = (func, delay) => {
    let timeout = null;

    return (...args) => {
      args[0].persist();

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleTextChange = React.useMemo(() => {
    if (isWTIAdmin) return debounceKeywordChange(keywordChange, 600);
    return () => {};
  }, [keywordChange]);

  return (
    <FormControl className={formStyles.formRoot}>
      <Autocomplete
        loading={loading || initialValuesLoading}
        loadingText="Loading Haulers..."
        multiple
        limitTags={2}
        id="haulers-chip-input"
        options={haulersOptions}
        disableCloseOnSelect
        disableClearable
        disabled={readOnly}
        onChange={handleSelectedChange}
        value={selectedHaulers}
        getOptionLabel={option => option.group_name?.toString() || ""}
        getOptionSelected={(option, value) =>
          option.group_id === value.group_id
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            if (option === 0) return null;
            return (
              <Chip
                {...getTagProps({ index })}
                size="small"
                color="primary"
                label={option.group_name}
              />
            );
          })
        }
        renderOption={(option, { selected }) => {
          return (
            <React.Fragment>
              <Checkbox
                color="primary"
                icon={
                  <CheckBoxOutlineBlankIcon
                    className={chipInputStyles.iconStyle}
                  />
                }
                checkedIcon={
                  <CheckBoxIcon className={chipInputStyles.iconStyle} />
                }
                checked={selected}
              />
              <Typography variant="body2">{option.group_name}</Typography>
            </React.Fragment>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: formStyles.textFieldRoot }}
            className={chipInputStyles.rootInput}
            onChange={handleTextChange}
            label="Haulers/Groups"
            placeholder={
              loading ? "Loading..." : selectedHaulers.length > 0 ? "" : "All"
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: {
                padding: "6px 0px"
              },
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress size={12} /> : "",
              disableUnderline: readOnly,
              disabled: readOnly
            }}
          />
        )}
      />
    </FormControl>
  );
});

export default HaulersChipInput;
