import { useState, useEffect, useMemo } from "react";

const useMultipleAudio = (
  urls,
  { pauseWhenDoubleTrigger = true, mute: propsMute = false }
) => {
  const [mute, setMute] = useState(() => {
    return propsMute;
  });
  const sources = useMemo(
    () =>
      urls.map(url => {
        return {
          url,
          audio: new Audio(url)
        };
      }),
    [JSON.stringify(urls)]
  );

  const [players, setPlayers] = useState(
    urls.map(url => {
      return {
        url,
        playing: false
      };
    })
  );

  const toggleMute = (callback = () => {}) => {
    if (!mute) {
      setPlayers(prevPlayers => {
        return prevPlayers.map(players => ({ ...players, playing: false }));
      });
    }

    setMute(prev => !prev);
    callback(!mute);
  };

  const toggle = targetIndex => () => {
    if (mute) return;
    const newPlayers = [...players];
    const currentIndex = players.findIndex(p => p.playing === true);
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[currentIndex].playing = false;
      newPlayers[targetIndex].playing = true;
    } else if (currentIndex !== -1) {
      if (pauseWhenDoubleTrigger) newPlayers[targetIndex].playing = false;
    } else {
      newPlayers[targetIndex].playing = true;
    }
    setPlayers(newPlayers);
  };

  useEffect(() => {
    sources.forEach((source, i) => {
      // eslint-disable-next-line no-unused-expressions
      players[i].playing ? source.audio.play() : source.audio.pause();
    });
  }, [sources, players]);

  useEffect(() => {
    sources.forEach((source, i) => {
      source.audio.addEventListener("ended", () => {
        const newPlayers = [...players];
        newPlayers[i].playing = false;
        setPlayers(newPlayers);
      });
    });
    return () => {
      sources.forEach((source, i) => {
        source.audio.removeEventListener("ended", () => {
          const newPlayers = [...players];
          newPlayers[i].playing = false;
          setPlayers(newPlayers);
        });
      });
    };
  }, [sources]);

  return { players, toggle, toggleMute, mute, setPlayers };
};

export default useMultipleAudio;
