/* eslint-disable no-useless-constructor */
/* global google */
import React from "react";
import { createPortal } from "react-dom";
import { PhotoSizeSelectSmall } from "@material-ui/icons";
import { Box, Tooltip, Paper, SvgIcon } from "@material-ui/core";
import { DrawingManager } from "@react-google-maps/api";
import _ from "lodash";

class DrawingController extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { mapRef } = this.props;
    const { google } = window;
    const { RIGHT_BOTTOM } = google.maps.ControlPosition;
    this.map = mapRef.current;
    this.controlContainer = document.createElement("div");
    this.controlContainer.setAttribute(
      "style",
      `
                margin: 10px;
                height: auto;
                width: auto;
            `
    );
    this.map.controls[RIGHT_BOTTOM].push(this.controlContainer);
  }

  render() {
    const { mapRef, drawing, setBounds } = this.props;
    const coordinatesParser = e => {
      const { type: type2, overlay } = e;
      const tempCoords = [];
      // let lat;
      // let long;
      if (type2 === "rectangle") {
        const bounds = overlay.getBounds();
        // const center = bounds.getCenter();
        const NE = bounds.getNorthEast();
        const SW = bounds.getSouthWest();
        tempCoords.push(`${SW.lat()} ${NE.lng()}`);
        tempCoords.push(
          `${bounds.getNorthEast().lat()} ${bounds.getNorthEast().lng()}`
        );
        tempCoords.push(`${NE.lat()} ${SW.lng()}`);
        tempCoords.push(
          `${bounds.getSouthWest().lat()} ${bounds.getSouthWest().lng()}`
        );
        tempCoords.push(`${SW.lat()} ${NE.lng()}`);
        // lat = center.lat();
        // long = center.lng();
      }
      return tempCoords;
    };

    return createPortal(
      <React.Fragment>
        {drawing && (
          <DrawingManager
            defaultDrawingMode={google.maps.drawing.OverlayType.RECTANGLE}
            options={{
              drawingControl: false,
              rectangleOptions: {
                fillColor: "gray",
                fillOpacity: ".5",
                strokeWeight: 0
              }
            }}
            drawingMode="rectangle"
            onOverlayComplete={e => {
              setBounds(coordinatesParser(e));
              mapRef.current.fitBounds(e.overlay.getBounds());
              e.overlay.setMap(null);
            }}
          />
        )}
        <Tooltip title="Map Selection Tool" placement="right">
          <Paper
            elevation={0}
            style={{
              // padding: "3px 6px",
              background: "rgba(255,255,255,0.9)",
              boxShadow: "0px 0px 18px -8px #484848d4"
              // cursor: "help"
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: "40px",
                height: "40px"
              }}
            >
              {drawing ? (
                <SvgIcon>
                  <path d="M3 13h2v-2H3v2zm4 8h2v-2H7v2zm6-18h-2v2h2V3zm6 0v2h2c0-1.1-.9-2-2-2zM5 21v-2H3c0 1.1.9 2 2 2zm-2-4h2v-2H3v2zm8 4h2v-2h-2v2zm8-8h2v-2h-2v2zm0-4h2V7h-2v2zm-4-4h2V3h-2v2zM7.83 5 7 4.17V3h2v2H7.83zm12 12-.83-.83V15h2v2h-1.17zm1.36 4.19L2.81 2.81 1.39 4.22 4.17 7H3v2h2V7.83l2 2V17h7.17l2 2H15v2h2v-1.17l2.78 2.78 1.41-1.42zM9 15v-3.17L12.17 15H9zm6-2.83V9h-3.17l-2-2H17v7.17l-2-2z" />
                </SvgIcon>
              ) : (
                <PhotoSizeSelectSmall />
              )}
            </Box>
          </Paper>
        </Tooltip>
      </React.Fragment>,
      this.controlContainer
    );
  }
}

export default DrawingController;
