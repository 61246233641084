import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { StyledTitle, TableWrapper, useStyles } from "./TripAllocation.styles";
import Table from "../../../utils/UI/Table/CustomTable";
import ConfirmationDialog from "../../../utils/modals/ConfirmationDialog";
import { NotInterested as CancelIcon } from "@material-ui/icons";
import { useMutation, useQuery } from "@apollo/client";
import { EDIT_VEHICLE_ALLOCATION } from "../../../graphql/Mutations";
import { GET_VEHICLE_ALLOCATIONS as GET_ALLOCATIONS } from "../../../graphql/Queries";
import _ from "lodash";

const HEADERS = [
  { id: "trip_number", label: "Trip Number" },
  { id: "hauler", label: "Hauler" },
  { id: "plate_number", label: "Plate Number" },
  { id: "status", label: "Status" },
  { id: "action", label: "" },
];

const DetailedViewModal = props => {
  const { open, handleClose, data } = props;
  const classes = useStyles();

  const [cancelDialog, setCancelDialog] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [allocationList, setAllocationList] = useState([]);
  const [initialState, setInitialState] = useState([]);

  const [editAllocation, { loading: editLoading }] = useMutation(EDIT_VEHICLE_ALLOCATION);

  const { loading: allocLoading, refetch } = useQuery(GET_ALLOCATIONS, {
    variables: {
      conditions: [
        {
          field: "allocation_date",
          value: data?.allocation_date
        }
      ],
      first: 10000
    },
    onCompleted: res => {
      const { allocations } = res?.get_allocations;
      if (!allocationList.length) {
        setInitialState(allocations);
      }
      setAllocationList(allocations);
    },
    skip: !open,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true
  });

  const getTripCount = () => {
    const count = data?.trips_count;
    return `${count} Trip${count !== 1 ? "s" : ""}`;
  };

  const cancelTrip = () => {
    editAllocation({
      variables: {
        id: selectedTrip.id,
        allocation: {
          vehicle_id: null,
          status_code_id: +process.env.REACT_APP_STATUS_CANCEL_ID
        }
      },
      onCompleted: () => {
        refetch();
      }
    });
  };

  const getStatus = (trip) => {
    // cancelled trip
    if (_.isEqual(+trip.id, +selectedTrip.id) && (editLoading || allocLoading)) {
      return <CircularProgress size={10} />;
    } else {
      return trip.status_code;
    }
  };

  const isStatusPending = trip => _.isEqual(+trip.status_code_id, +process.env.REACT_APP_STATUS_PENDING_ID);
  
  const isUpdating = editLoading || (allocationList.length && allocLoading);
  const hasUpdates = !_.isEqual(initialState, allocationList);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        // disable close on click outside while cancelling
        if (reason === "backdropClick" && isUpdating) {
          return;
        }
        // check if table data needs refetch
        handleClose(hasUpdates);
      }}
      maxWidth="sm"
      fullWidth
    >
      <Box display="flex" flexDirection="column" padding={2} gridGap={2}>
        <StyledTitle variant="h6">Detailed View</StyledTitle>
        <Typography>
          List of trips allocated per hauler along its current status.
        </Typography>
        <TableWrapper className={classes.detailedViewTable} tdPadding={8}>
          <Table headers={HEADERS}>
            {allocationList.length ? (
              allocationList?.map((trip, index) => (
                <TableRow key={index}>
                  <TableCell>{trip.trip_number}</TableCell>
                  <TableCell>{trip.group_name}</TableCell>
                  <TableCell style={{ textTransform: "uppercase" }}>
                    {trip.plateno || "-"}
                  </TableCell>
                  <TableCell>{getStatus(trip)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setCancelDialog(true);
                        setSelectedTrip(trip);
                      }}
                      style={{
                        opacity: `${isStatusPending(trip) ? "1" : "0"}`,
                        color: "red"
                      }}
                      disabled={!isStatusPending(trip)}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    rowSpan={2}
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <Box display="flex" justifyContent="center" gridGap={2}>
                      <CircularProgress size={12} />
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            )}
          </Table>
        </TableWrapper>
        <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
          { getTripCount() }
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{
            borderRadius: 8,
            alignSelf: "end",
            textTransform: "capitalize",
            padding: "4px 24px"
          }}
          onClick={() => handleClose(hasUpdates)}
          disabled={isUpdating}
        >
          Okay
        </Button>
      </Box>

      <ConfirmationDialog
        toggle={cancelDialog}
        close={() => setCancelDialog(false)}
        fn={cancelTrip}
        title="Cancel Trip Request"
        content="Are you sure you want to cancel this trip request?"
      />
    </Dialog>
  );
};

export default DetailedViewModal;
