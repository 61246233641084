/* eslint-disable no-unused-vars */ 
import React, { memo } from "react";
import { IconButton, Icon, Tooltip } from "@material-ui/core";

const Buttons = props => {
  const { functionHandler, editMode, icon, id, title } = props;

  return (
    <>
      <Tooltip placement="bottom" title={title} arrow>
        <div style={{ width: "max-content", margin: 0, display: "inline-block" }}>
          <IconButton
            id={id}
            onClick={functionHandler}
            disabled={editMode}
            style={{ padding: 6 }}
            color="inherit"
          >
            {icon}
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default memo(Buttons);
