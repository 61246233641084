import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Box,
  makeStyles,
  OutlinedInput,
  InputAdornment
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect, useRef } from "react";
import { GET_VEHICLES_TO_PAIR_REPO } from "../../../../api/vehicleRepository";
import { GET_VEHICLES_TO_PAIR } from "../../../../graphql/Queries";
import { useMutation, useQuery } from "@apollo/client";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {
  EDIT_DRIVER_VEHICLE,
  UPDATE_DRIVER_INFO
} from "../../../../graphql/Mutations";
import driverStyles from "./DriverInfo.style";
import AddLogs from "../../../../utils/functions/AddLogs";
import Swal from "sweetalert2";
import useUserContext from "../../../../context/User/useUserContext";

let timeout = null;

const PairDriver = props => {
  const {
    toggle,
    closeDriverModal,
    setPairModal,
    selectedDriver,
    refetchPersonnel
  } = props;
  const classes = driverStyles();
  const codeRef = useRef();
  const user = useUserContext();

  const [keyword, setKeyword] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [driver, setDriver] = useState({
    id: selectedDriver.vehicle_info?.id,
    plateno: selectedDriver.vehicle_info?.plateno
  });
  const [generatedCode, setGeneratedCode] = useState();
  const [copied, setCopied] = useState(false);

  // mutation for pairing/unpairng (edit_vehicle)
  const [editDriverInfo] = useMutation(EDIT_DRIVER_VEHICLE);

  // mutation for updating driver info
  const [updateDriver] = useMutation(UPDATE_DRIVER_INFO);

  // timer for searching vehicle modal
  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleVehicles();
    }, 1000);
  }, [keyword]);

  // query for searching vehicles
  const handleVehicles = async () => {
    const vehiclesData = await GET_VEHICLES_TO_PAIR_REPO(keyword);
    setVehicles(parseVehicleData(vehiclesData));
  };

  // new value if driver is selected
  const handleSelectedDriver = (e, nv) => {
    setDriver(nv);
    setGeneratedCode("");
    setCopied(false);
  };

  // query for search platno
  const handleSearchPlate = e => {
    setKeyword(e.target.value);
    setGeneratedCode("");
  };

  // spread vehicle data into objects
  const parseVehicleData = data => {
    return data.map(vehicle => {
      return {
        id: vehicle.id,
        device_id: vehicle.device_id,
        plateno: vehicle.plateno,
        bodyno: vehicle.bodyno,
        vehicle_type_id: vehicle.vehicle_type_id,
        capacity: vehicle.capacity,
        unit: vehicle.unit,
        has_container: vehicle.has_container,
        status_code_id: vehicle.status_code_id,
        model: vehicle.model,
        manufacture_year: vehicle.manufacture_year,
        registration_number: vehicle.registration_number,
        expiration: vehicle.expiration,
        vehicle_type: vehicle.vehicle_type,
        personnel_id: vehicle.personnel_id
      };
    });
  };

  // for closing modal
  function hanldeClose() {
    refetchPersonnel();
    setGeneratedCode("");
    setDriver("");
    setCopied(false);
    closeDriverModal();
  }

  //mutation to pair
  const pairingMutation = (code, status) => {
    if (
      !selectedDriver.vehicle_info?.id ||
      driver?.id === selectedDriver?.vehicle_info?.id
    ) {
      editDriverInfo({
        variables: {
          id: driver.id,
          vehicle: {
            personnel_id: selectedDriver.id,
            paired: +status,
            gps_code: code ? code.toString() : selectedDriver.gps_code
          }
        },
        onCompleted: () => {
          if (process.env.REACT_APP_STATUS_DRIVER_PAIRED === status) {
            Swal.fire({
              title: "Driver Paired",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              onClose: () => {}
            }).then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                refetchPersonnel();
              }
            });
          }
        }
      });
    } else {
      editDriverInfo({
        variables: {
          id: selectedDriver.vehicle_info?.id,
          vehicle: {
            personnel_id: null,
            paired: 0,
            gps_code: ""
          }
        },
        onCompleted: () => {
          editDriverInfo({
            variables: {
              id: driver.id,
              vehicle: {
                personnel_id: selectedDriver.id,
                paired: +status,
                gps_code: code ? code.toString() : selectedDriver.gps_code
              }
            },
            onCompleted: () => {
              if (process.env.REACT_APP_STATUS_DRIVER_PAIRED === status) {
                Swal.fire({
                  title: "Driver Paired",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                  onClose: () => {}
                }).then(result => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    refetchPersonnel();
                  }
                });
              }
            }
          });
        }
      });
    }

    AddLogs("Admin - Driver Info", "pair", [
      selectedDriver?.name,
      driver?.id ? driver?.plateno : selectedDriver?.vehicle_info?.plateno
    ]);

    //mutation to update personnel
    updateDriver({
      variables: {
        employeeid: selectedDriver.employeeid,
        id: selectedDriver.id,
        gps_code: code ? code.toString() : selectedDriver.gps_code,
        name: selectedDriver.name,
        mobileno: selectedDriver.mobileno,
        supervisor: selectedDriver.supervisor,
        designation: "driver",
        group_ids: selectedDriver.group_ids,
        client_id: +user.client.id,
        driver_info: {
          license_number: selectedDriver.driver_info.license_number,
          license_expiration: selectedDriver.driver_info.license_expiration,
          license_exp_notif_day: +selectedDriver.driver_info
            .license_exp_notif_day,
          status_code_id: 0
        }
      }
    });
  };

  // generating random 4 digit code for gps_code
  function handleGenerateCode() {
    const randomCode = Math.floor(Math.random() * 9000 + 1000);
    setGeneratedCode(randomCode);
    pairingMutation(
      randomCode,
      process.env.REACT_APP_STATUS_DRIVER_GENERATED_CODE
    );
  }

  // Yes button for pairing driver and vehicle and resetting field value
  function handlePairNow() {
    pairingMutation(generatedCode, process.env.REACT_APP_STATUS_DRIVER_PAIRED);
    setGeneratedCode("");
    setDriver("");
    setCopied(false);
    setPairModal(false);
  }

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialogContainer }}
        maxWidth="xs"
        open={toggle}
        onClose={hanldeClose}
      >
        <DialogTitle>Driver Pairing</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.contentText}>
            Click "Pair now" to instanly pair the driver to the vehicle or
            generate code to be provided to the driver.
          </DialogContentText>
          <FormControl className={classes.field}>
            <InputLabel shrink>Vehicle</InputLabel>
            <Autocomplete
              name="pair_driver"
              options={vehicles || []}
              getOptionLabel={option => option.plateno}
              renderOption={option => option.plateno}
              getOptionDisabled={option => !!+option.personnel_id}
              value={
                selectedDriver?.paired ===
                  +process.env.REACT_APP_STATUS_DRIVER_GENERATED_CODE &&
                driver?.plateno === selectedDriver?.vehicle_info?.plateno
                  ? selectedDriver
                  : driver
              }
              onChange={handleSelectedDriver}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={handleSearchPlate}
                  size="small"
                  classes={{ root: classes.textFieldRoot }}
                  name="pair_driver"
                  fullWidth
                  value={selectedDriver?.vehicle_info?.plateno}
                  placeholder="Select Vehicle"
                  style={{ marginTop: 16 }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black" }
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formCode}>
            <InputLabel shrink className={classes.codeLabel}>
              Code
            </InputLabel>
            <OutlinedInput
              fullWidth
              size="small"
              value={
                selectedDriver?.vehicle_info?.paired ===
                  +process.env.REACT_APP_STATUS_DRIVER_GENERATED_CODE &&
                driver?.plateno === selectedDriver?.vehicle_info?.plateno
                  ? selectedDriver?.gps_code
                  : generatedCode
              }
              className={classes.outlinedInput}
              classes={{ root: classes.textFieldRoot }}
              inputProps={{
                readOnly: true,
                ref: codeRef
              }}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    // disabled={!generatedCode || selectedDriver.gps_code}
                    onClick={() => {
                      if (codeRef.current.value) {
                        codeRef.current.select();
                        document.execCommand("copy");
                        setCopied(true);
                      }
                    }}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className={classes.codeGenerated}
              size="small"
              // disabled={!driver?.id || generatedCode}
              disabled={
                driver?.plateno === selectedDriver?.vehicle_info?.plateno || generatedCode
              }
              variant="contained"
              color="primary"
              onClick={handleGenerateCode}
            >
              Generate Code
            </Button>
            {copied && (
              <Alert
                severity="success"
                variant="outlined"
                className={classes.successAlert}
                classes={{ root: classes.alertPaper }}
              >
                Copied to clipboard!
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            className={classes.closeBtn}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => hanldeClose()}
          >
            Close
          </Button>
          <Button
            disabled={!driver?.id}
            className={classes.codeGenerated}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handlePairNow()}
          >
            Pair Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PairDriver;
