import Cookie from "js-cookie";
import _ from "lodash";

const updateCookie = (name, data) => {
  switch (name) {
    case "user":
      // eslint-disable-next-line no-case-declarations
      const clonedData = _.cloneDeep(data);
      // Remove client groups
      // Cookies cannot handle high number of client groups
      delete clonedData.client.group_ids;
      delete clonedData.client.group_names;

      // Save group of client only
      // Get complete group info in User Context
      clonedData.group_ids = [
        clonedData.group_ids[
          clonedData.group_names.findIndex(
            group_name => group_name === clonedData.client.name
          )
        ]
      ];
      clonedData.group_names = [clonedData.client.name];

      Cookie.set("user", clonedData);
      break;

    case "token":
      Cookie.set("token", data);
      break;
    default:
      break;
  }
};

export default updateCookie;
