import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    infoHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 350,
        "& .MuiButtonBase-root.Mui-disabled": {
            pointerEvents: "auto",
        },
        padding: "4px 2px",
        overflow: "hidden",
    },
    infoLabelBox: {
        display: "flex",
        alignItems: "center",
    },
    labelIconStatus: {
        width: "0.5em",
        height: "0.5em",
        marginRight: 6,
    },
    label: {
        fontSize: 12,  
    },
    underRepair: {
        width: "0.7em",
        height: "0.7em",
        color: "#FFF",
        backgroundColor: "#F1B74B",
        padding: "4px",
        borderRadius: "50%",        
    },
    actionIcon: {
        width: "0.7em",
        height: "0.7em",
        color: "#757575",
        padding: "4px",
        borderRadius: "50%",
    },
    avatar: {
        backgroundColor: "transparent",
        width: 40,
        height: 40,
    },
    pegman: {
        transform: "translateY(-2px)",
        width: 9,
    },
    batteryLevel: {
        fontWeight: "bold",
        fontSize: 10,
    },
    batteryReportStamp: {
        fontSize: 10,
    },
    withTrip: {
        width: "0.7em",
        height: "0.7em",
        color: "#757575",
        padding: "4px",
        borderRadius: "50%",
        color: theme.palette.primary.main,
    },
}));
