import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  TextField,
  Slider,
  Select,
  InputLabel,
  MenuItem,
  Switch
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
import {
  Marker,
  DirectionsRenderer,
  Polygon,
  Polyline
} from "@react-google-maps/api";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import { useStyles } from "./RouteGeoStyles";
import { getDirections } from "./RouteGeofence";
import Map from "../../../../../utils/Map/index";
import {
  control_positions,
  mapRef,
  getGeoPaths
} from "../../../../../utils/Map/Inner";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../../utils/functions/AddLogs";
import redirectToTable from "../../../../../utils/redirect";
import PlaceAutocomplete from "./SearchInput";
import { EDIT_ROUTEGEOFENCE } from "../../../../../graphql/Mutations";
import { GET_ROUTEGEOFENCE } from "../../../../../graphql/Queries";
import {
  getMultiPolygonStr,
  getWaypoints,
  getOverviewPath
} from "./utils";
import { poly_options, buffers } from "./options";
import useUserContext from "../../../../../context/User/useUserContext";
import AccessControl from "../../../../../utils/AccessControl";
import Header from "../../../Header";
import { wktToCoordinates } from "../../../../../utils/functions/coordinatesParserV2";

const DEFAULT_ZOOM = 14;

// const user = JSON.parse(Cookie.get("user"));
// const { group_ids } = user;

const RouteGeofence = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const user = useUserContext();
  const { group_ids } = user;
  const { state: locationState } = history.location;

  const [geofenceDetails, setGeofenceDetails] = useState({
    name: "",
    code: "",
    speedLimit: 0,
    customSpeedLimit: 0
  });

  const [directionsDetails, setDirectionsDetails] = useState({
    origin: null,
    destination: null,
    directions: null,
    multiPolygon: null,
    polyPath: null,
    line_geom: null,
    buffer: 50,
    startAddress: "",
    endAddress: ""
  });

  const directionsRenderRef = useRef(); // REF FOR DIRECTIONS RENDERER

  const [booleans, setBooleans] = useState({
    autoGenerate: false,
    isDirectionsDisabled: true,
    open: false,
    isStartPoint: false,
    isEndPoint: false,
    nameErr: false,
    geocodeErr: false,
    isReadOnly: true,
    isDraggable: true, //directions renderer draggable,
    speedLimitErr: false
  });

  const [editPermission, setEditPermission] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [initialValue, setInitialValue] = useState({
    form: _.cloneDeep(geofenceDetails),
    places: _.cloneDeep(directionsDetails)
  });

  const [center, setCenter] = useState({
    lat: 12.248727944234455,
    lon: 121.95099687500002
  });

  const memoizedCenter = React.useMemo(() => {
    return {
      lat: center.lat,
      lng: center.lon
    };
  }, [center?.lat, center?.lon]);

  const options = React.useMemo(
    () => ({
      controlSize: 20,
      mapTypeControlOptions: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT
      }
    }),
    []
  );

  const input_props = {
    disableUnderline: booleans.isReadOnly,
    classes: {
      input: classes.input_fontSize
    }
  };

  const speedLimit_props = {
    classes: {
      input: classes.input_fontSize
    },
    max: 200,
    min: 0
  };

  const startMarkerSVG = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 20)
  };

  const endMarkerSVG = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#F49400",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 20)
  };

  // GET ROUTE GEO QUERY
  // sets the necessary fields
  // calls getDirections with respect to waypoints provided previously
  const { loading } = useQuery(GET_ROUTEGEOFENCE, {
    variables: {
      id: id
    },
    onCompleted(geoFenceInfo) {
      const {
        name,
        geofence_code,
        geom,
        line_geom,
        line_buffer,
        speed_limit
      } = geoFenceInfo.get_geofence;

      const line_geom_parsed = JSON.parse(JSON.parse(line_geom));
      const overview_path = line_geom_parsed?.overview_polyline
        ? window.google.maps.geometry.encoding.decodePath(
            line_geom_parsed?.overview_polyline
          )
        : null;
      setGeofenceDetails({
        ...geofenceDetails,
        name: name,
        code: geofence_code,
        speedLimit:
          speed_limit === 0 ||
          speed_limit === 70 ||
          speed_limit === 80 ||
          speed_limit === 90 ||
          speed_limit === 100
            ? speed_limit
            : "Custom",
        customSpeedLimit: speed_limit
      });
      
      setDirectionsDetails({
        ...directionsDetails,
        origin: {
          lat:line_geom_parsed?.start?.coordinates?.lat,
          lng:line_geom_parsed?.start?.coordinates?.lng
        },
        destination: {
          lat: line_geom_parsed?.end?.coordinates?.lat,
          lng: line_geom_parsed?.end?.coordinates?.lng
        },
        multiPolygon: geom,
        line_geom: line_geom,
        buffer: line_buffer,
        startAddress: line_geom_parsed?.start?.name,
        endAddress: line_geom_parsed?.end?.name,
        polyPath: overview_path
      });

      // setCenter({
      //   lat: line_geom_parsed?.start?.coordinates?.lat,
      //   lon: line_geom_parsed?.start?.coordinates?.lng
      // });

    },
    fetchPolicy: "network-only",
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        });
      }
    }
  });

  // EDIT/UPDATE ROUTE GEOFENCE MUTATION
  const [editGeofence, { error }] = useMutation(EDIT_ROUTEGEOFENCE, {
    onCompleted(data) {
      if (data) {
        if (data.update_geofence.success) {
          AddLogs("geofence", "update", geofenceDetails.name);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              if (locationState?.source) {
                history.push({
                  pathname: locationState.source,
                  state: locationState
                });
              } else {
                redirectToTable(
                  history,
                  process.env.REACT_APP_GEOFENCES_MODULE
                );
              }
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              if (locationState?.source) {
                history.push({
                  pathname: locationState.source,
                  state: locationState
                });
              } else {
                redirectToTable(
                  history,
                  process.env.REACT_APP_GEOFENCES_MODULE
                );
              }
            }
          });
        } else {
          Swal.fire({
            title: "Something went wrong",
            title: "Error",
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });

          if (data.add_geofence.error.some(e => e.field === "name")) {
            setBooleans({ ...booleans, nameErr: true });
          }
          if (data.add_geofence.error.some(e => e.field === "geofence_code")) {
            setBooleans({ ...booleans, geocodeErr: true });
          }
        }
      }
    },
    onError: ApolloError => {
      setBooleans({ ...booleans, nameErr: true, geocodeErr: true });
      Swal.fire({
        title: "Geofece name or code exists",
        icon: "error",
        showConfirmButton: false,
        timer: 3000
      });
    }
  });

  // Modal handling
  const handleOpen = () => {
    // setOpen(true);
    setBooleans({ ...booleans, open: true });
  };
  const handleClose = () => {
    // setOpen(false);
    setBooleans({ ...booleans, open: false });
  };

  // handles the autogenerated geofence code
  const handleToggle = () => {
    if (!booleans.autoGenerate) {
      const randomCode = Math.random()
        .toString(36)
        .slice(-5);
      setGeofenceDetails({
        ...geofenceDetails,
        code: randomCode.toString(36).slice(-5)
      });
    } else {
      setGeofenceDetails({
        ...geofenceDetails,
        code: ""
      });
    }
    setBooleans(prev => {
      return {
        ...prev,
        autoGenerate: !prev.autoGenerate,
        isStartPoint: false,
        isEndPoint: false,
        geocodeErr: false
      };
    });
  };

  // disable plotting points on the map
  const setPointsFalse = () => {
    setBooleans({
      ...booleans,
      isStartPoint: false,
      isEndPoint: false
    });
  };

  const handleMode = () => {
    setBooleans(prev => {
      return {
        isReadOnly: !prev.isReadOnly,
        isDraggable: !prev.isDraggable
      };
    });
  };

  // function to be called when an autocomplete place is chosen
  // sets the origin and destination
  // handles changes to autocomplete values
  const onPlaceChanged = ({ newPlace, formattedAddress, start }) => {
    setDirectionsDetails({
      ...directionsDetails,
      origin: start
        ? {
            lat: newPlace?.location.lat(),
            lng: newPlace?.location.lng()
          }
        : directionsDetails.origin,
      destination: !start
        ? {
            lat: newPlace?.location.lat(),
            lng: newPlace?.location.lng()
          }
        : directionsDetails.destination,
      startAddress: start ? formattedAddress : directionsDetails.startAddress,
      endAddress: !start ? formattedAddress : directionsDetails.endAddress,
      directions:
        directionsDetails.origin && directionsDetails.destination
          ? null
          : directionsDetails.directions,
      multiPolygon:
        directionsDetails.origin && directionsDetails.destination
          ? null
          : directionsDetails.multiPolygon
    });

    setCenter({
      lat: newPlace?.location.lat(),
      lon: newPlace?.location.lng()
    });
    if (booleans.isStartPoint || booleans.isEndPoint) setPointsFalse();
  };

  // SETS BUFFER IN SLIDER
  const sliderOnChange = (event, value) => {
    setPointsFalse();
    setDirectionsDetails({
      ...directionsDetails,
      multiPolygon: getMultiPolygonStr(directionsDetails?.polyPath, value),
      buffer: value
    });
  };

  const handleSpeedChange = value => {
    if (value >= 0 && value <= 200 && Number.isInteger(value)) {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: value
      });
    } else if (value < 0) {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: 0
      });
    } else {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: 200
      });
    }
    if (value < 0 || value > 200 || !Number.isInteger(value)) {
      setBooleans(prev => {
        return { ...prev, speedLimitErr: true };
      });
    }
    setPointsFalse();
  };



  // MUTATION TO UPDATE GEOFENCE
  const saveDetails = event => {
    event.preventDefault();
    const location = {
      lat: directionsDetails.origin?.lat,
      lon: directionsDetails.origin?.lng
    };
    editGeofence({
      variables: {
        id: id,
        name: geofenceDetails.name,
        address: directionsDetails.startAddress,
        geofence_code: geofenceDetails.code?.toString()?.trim(),
        category: "Route",
        location: null,
        group_ids: group_ids,
        geom: directionsDetails.multiPolygon,
        line_geom: directionsDetails.line_geom,
        line_buffer: directionsDetails.buffer,
        speed_limit:
          geofenceDetails.speedLimit === "Custom"
            ? geofenceDetails.customSpeedLimit
            : geofenceDetails.speedLimit
      }
    });
  };

  const discardChanges = () => {
    setGeofenceDetails({ ...geofenceDetails, ...initialValue.form });
    setDirectionsDetails({ ...directionsDetails, ...initialValue.places });
  };

  const handleDiscardChanges = () => {
    discardChanges();
    setEditMode(false);
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (
        _.isEqual(geofenceDetails, initialValue.form) &&
        _.isEqual(directionsDetails, initialValue.places)
      ) {
        setEditMode(event.target.checked);
      } else {
        setDiscard(true);
      }
      setBooleans({ ...booleans, isReadOnly: true });
    } else {
      setEditMode(event.target.checked);
      setBooleans({ ...booleans, isReadOnly: false });
    }
  };

  useEffect(() => {
    setInitialValue({
      ...initialValue,
      form: geofenceDetails,
      places: directionsDetails
    });
  }, [editMode]);


  return (
    <AccessControl
      resource="/admin/trip/geofences/addRouteGeofence"
      setEditPermission={setEditPermission}
      process="view"
    >
      <div
        style={{
          height: "calc(100% - 64px)",
          width: "100%"
        }}
      >
        <Header
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          history={history?.location?.state}
          editPermission={editPermission}
          editMode={editMode}
          setDiscard={setDiscard}
          redirect={redirect}
          setRedirect={setRedirect}
          toggleEditMode={toggleEditMode}
          isDataNotChanged={
            _.isEqual(geofenceDetails, initialValue.form) &&
            _.isEqual(directionsDetails, initialValue.places)
          }
        />

        <Map
          options={options}
          defaultZoom={DEFAULT_ZOOM}
          zoom={DEFAULT_ZOOM}
          center={memoizedCenter}
        >
          <Marker
            draggable={false}
            animation={window.google.maps.Animation.DROP}
            icon={startMarkerSVG}
            position={{
              lat: Number(directionsDetails.origin?.lat),
              lng: Number(directionsDetails.origin?.lng)
            }}
          />

          <Marker
            draggable={false}
            animation={window.google.maps.Animation.DROP}
            icon={endMarkerSVG}
            position={{
              lat: Number(directionsDetails.destination?.lat),
              lng: Number(directionsDetails.destination?.lng)
            }}
          />

          <Map.Control position={control_positions.LEFT_TOP}>
            <Paper className={classes.paper_inputCard}>
              <div style={{ width: "100%" }}>
                <TextField
                  error={booleans.nameErr}
                  helperText={booleans.nameErr ? "The name already exists" : ""}
                  fullWidth
                  required
                  label="Geofence Name"
                  placeholder="Enter Geofence name"
                  InputLabelProps={{
                    classes: { root: classes.label },
                    shrink: true
                  }}
                  InputProps={input_props}
                  inputProps={{
                    readOnly: booleans.isReadOnly,
                    maxLength: 50
                  }}
                  style={{ marginTop: "8px", marginBottom: "8px" }}
                  value={geofenceDetails.name}
                  onChange={e => {
                    setGeofenceDetails({
                      ...geofenceDetails,
                      name: e.target.value
                    });
                    setPointsFalse();
                    if (booleans.nameErr) {
                      setBooleans({
                        ...booleans,
                        nameErr: false
                      });
                    }
                  }}
                />

                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <InputLabel
                      required
                      shrink
                      id="toggle-code"
                      className={classes.label}
                    >
                      Geofence Code
                    </InputLabel>
                    <Switch
                      disabled={booleans.isReadOnly}
                      size="small"
                      color="primary"
                      onChange={handleToggle}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track
                      }}
                    />
                  </div>

                  <TextField
                    error={booleans.geocodeErr}
                    helperText={
                      booleans.geocodeErr
                        ? "The geofence code already exists"
                        : ""
                    }
                    fullWidth
                    aria-labelledby="toggle-code"
                    required
                    placeholder="Enter Geofence code"
                    InputProps={input_props}
                    inputProps={{
                      readOnly: booleans.autoGenerate || booleans.isReadOnly,
                      maxLength: 30
                    }}
                    value={geofenceDetails.code}
                    onChange={event => {
                      setGeofenceDetails({
                        ...geofenceDetails,
                        code: event.target.value
                      });
                      if (booleans.geocodeErr) {
                        setBooleans({
                          ...booleans,
                          geocodeErr: false
                        });
                      }
                    }}
                    onBlurCapture={() => {
                      setGeofenceDetails(prev =>({
                        ...prev,
                        code: prev.code?.toString()?.trim()
                      }));
                    }}
                  />
                </div>
                <div
                  className={booleans.isStartPoint ? classes.start_active : ""}
                >
                  <PlaceAutocomplete
                    start
                    onPlaceChanged={onPlaceChanged}
                    setDirectionsDetails={setDirectionsDetails}
                    startAddress={directionsDetails.startAddress}
                    setPointBoolean={setBooleans}
                    isDisabled={true}
                  />
                </div>
                <div
                  className={booleans.isEndPoint ? classes.start_active : ""}
                >
                  <PlaceAutocomplete
                    start={false}
                    setDirectionsDetails={setDirectionsDetails}
                    onPlaceChanged={onPlaceChanged}
                    endAddress={directionsDetails.endAddress}
                    setPointBoolean={setBooleans}
                    isDisabled={true}
                  />
                </div>
                <Button
                  className={
                    booleans.isDirectionsDisabled
                      ? classes.disabled_button
                      : classes.button_getDirections
                  }
                  variant="contained"
                  color="secondary"
                  style={{ marginBottom: "8px" }}
                  disabled={booleans.isDirectionsDisabled}
                  // onClick={() =>
                  //   getDirections(
                  //     directionsDetails,
                  //     setDirectionsDetails,
                  //     getMultiPolygonStr,
                  //     getWaypoints
                  //   )
                  // }
                >
                  Get Directions
                </Button>
                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <InputLabel
                    className={classes.slider_label}
                    id="buffer-slider-label"
                  >
                    Apply Buffer(meters)
                  </InputLabel>
                  <Slider
                    disabled={!directionsDetails.polyPath || booleans.isReadOnly}
                    defaultValue={50}
                    aria-labelledby="buffer-slider-label"
                    step={null}
                    valueLabelDisplay="off"
                    marks={buffers}
                    classes={{ markLabel: classes.slider_label }}
                    value={directionsDetails.buffer}
                    onChange={sliderOnChange}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <InputLabel
                    className={classes.slider_label}
                    id="speed-select-label"
                  >
                    Speed Limit(kph)
                  </InputLabel>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Select
                      disabled={booleans.isReadOnly}
                      fullWidth
                      labelId="speed-select-label"
                      id="speed-limit-select"
                      value={geofenceDetails.speedLimit}
                      defaultValue="none"
                      onChange={event => {
                        setGeofenceDetails({
                          ...geofenceDetails,
                          speedLimit: event.target.value,
                          customSpeedLimit: 0
                        });
                        setPointsFalse();
                      }}
                      style={{ fontSize: "13px", color: "#808080" }}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={70}>70</MenuItem>
                      <MenuItem value={80}>80</MenuItem>
                      <MenuItem value={90}>90</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                    {geofenceDetails.speedLimit === "Custom" && (
                      <div style={{ marginLeft: "10px" }}>
                        <TextField
                          error={booleans.speedLimitErr}
                          InputProps={speedLimit_props}
                          inputProps={{
                            min: 0,
                            max: 200,
                            readOnly: booleans.isReadOnly
                          }}
                          type="number"
                          value={geofenceDetails.customSpeedLimit}
                          onChange={e =>
                            setGeofenceDetails({
                              ...geofenceDetails,
                              customSpeedLimit: e.target.value
                            })
                          }
                          onBlur={e =>
                            handleSpeedChange(Number(e.target.value))
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Button
                disabled={
                  !(
                    geofenceDetails.name !== "" &&
                    geofenceDetails.code !== "" &&
                    !booleans.isReadOnly
                  )
                }
                className={classes.button_getDirections}
                variant="contained"
                color="primary"
                onClick={handleOpen}
              >
                Save
              </Button>
            </Paper>
          </Map.Control>

          {booleans.open && (
            <ConfirmationDialog
              toggle={handleOpen}
              title="Save?"
              content="Are you sure you want to save this geofence?"
              fn={saveDetails}
              close={handleClose}
              type=""
            />
          )}

          {directionsDetails.multiPolygon && (
            <>
              <Polygon
                paths={wktToCoordinates(directionsDetails.multiPolygon)}
                options={poly_options}
                onLoad={()=> {
                  //Auto zoom to view whole route
                  var bounds = new window.google.maps.LatLngBounds();
                  const paths = wktToCoordinates(directionsDetails.multiPolygon);
                  paths.forEach(path => {
                    path.forEach(coor => bounds.extend(coor))
                  });
                  var extendPoint = new window.google.maps.LatLng(
                    bounds.getNorthEast().lat(),
                    bounds.getNorthEast().lng()
                  );
                  bounds.extend(extendPoint);
                  mapRef.current.fitBounds(bounds);
                }}
              />
            </>
          )}
          {directionsDetails.polyPath && (
            <>
              <Polyline
                path={getOverviewPath(directionsDetails.polyPath)}
                options={{ strokeColor: "#7F00FF", strokeWeight: 15 }}
              />
            </>
          )}
        </Map>

        <ConfirmationDialog
          toggle={discard}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
          close={() => setDiscard(false)}
          fn={handleDiscardChanges}
        />
      </div>
    </AccessControl>
  );
};

export default RouteGeofence;
