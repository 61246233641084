const data = [
  {
    id: "406",
    user_id: 1,
    report_type: "Ignition",
    reporttype_id: 25,
    sked: "daily",
    timesked: "07:00:00",
    group_ids: [30],
    device_id: ["17154"],
    personnel_id: 0,
    status: 4,
    created: "2022-09-01 19:32:10",
    filename: "ignition_report_2022_09_16_070015_8093.xlsx",
    is_sent: 4,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 0,
    checked: 0,
    time_sent: "2022-09-16 07:00:17",
    email_add: "email@sample.com",
    email_body:
      '<html><head></head><body><p><font color="#000000">Good Day,<br><br><br>Attached is the  from 2022-09-15 00:00 to 2022-09-15 23:59 as requested.</font></p><p><font color="#000000">Please do not reply. This is a computer generated email. If you have concerns or feedback, email us at service@findme.com.ph.</font><br><br><br><font color="#000000">Sincerely,</font><br><br><font color="#000000">WTI Customer Support</font><br>trackme.com.ph</font></p></body></html>',
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "403",
    user_id: 1,
    report_type: "Trips Check-in Check-out(CICO)",
    reporttype_id: 21,
    sked: "daily",
    timesked: "08:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 4,
    created: "2022-09-01 10:18:56",
    filename: "trip_cico_report_2022_09_16_080056_17854.xlsx",
    is_sent: 4,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 0,
    checked: 0,
    time_sent: "2022-09-16 08:00:59",
    email_add: "email@sample.com",
    email_body:
      '<html><head></head><body><p><font color="#000000">Good Day,<br><br><br>Attached is the Trip CICO Report from 2022-09-15 00:00 to 2022-09-15 23:59 as requested.</font></p><p><font color="#000000">Please do not reply. This is a computer generated email. If you have concerns or feedback, email us at service@findme.com.ph.</font><br><br><br><font color="#000000">Sincerely,</font><br><br><font color="#000000">WTI Customer Support</font><br>trackme.com.ph</font></p></body></html>',
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "399",
    user_id: 1,
    report_type: "Alert Count Dashboard",
    reporttype_id: 17,
    sked: "daily",
    timesked: "09:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 26,
    created: "2022-08-30 10:19:43",
    filename: "alert_count_dashboard_2022_09_16_091302_76507.xlsx",
    is_sent: 25,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 3,
    checked: 0,
    time_sent: "2022-09-05 09:00:38",
    email_add: "email@sample.com",
    email_body: "",
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "396",
    user_id: 1,
    report_type: "Alert Count Dashboard",
    reporttype_id: 17,
    sked: "daily",
    timesked: "09:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 26,
    created: "2022-08-25 18:45:05",
    filename: "alert_count_dashboard_2022_09_16_093333_95708.xlsx",
    is_sent: 25,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 3,
    checked: 0,
    time_sent: "1111-11-11 19:11:11",
    email_add: "email@sample.com",
    email_body: "",
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "395",
    user_id: 1,
    report_type: "Alert Count Dashboard",
    reporttype_id: 17,
    sked: "daily",
    timesked: "09:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 4,
    created: "2022-08-25 18:44:56",
    filename: "alert_count_dashboard_2022_09_16_092639_94387.xlsx",
    is_sent: 4,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 0,
    checked: 0,
    time_sent: "2022-09-16 09:29:06",
    email_add: "email@sample.com",
    email_body:
      '<html><head></head><body><p><font color="#000000">Good Day,<br><br><br>Attached is the Detailed from 2022-09-15 00:00 to 2022-09-15 23:59 as requested.</font></p><p><font color="#000000">Please do not reply. This is a computer generated email. If you have concerns or feedback, email us at service@findme.com.ph.</font><br><br><br><font color="#000000">Sincerely,</font><br><br><font color="#000000">WTI Customer Support</font><br>trackme.com.ph</font></p></body></html>',
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "394",
    user_id: 1,
    report_type: "Alert Count Dashboard",
    reporttype_id: 17,
    sked: "daily",
    timesked: "09:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 26,
    created: "2022-08-25 18:16:50",
    filename: "alert_count_dashboard_2022_09_16_092408_50727.xlsx",
    is_sent: 25,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 3,
    checked: 0,
    time_sent: "1111-11-11 19:11:11",
    email_add: "email@sample.com",
    email_body: "",
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "393",
    user_id: 1,
    report_type: "Alert Count Dashboard",
    reporttype_id: 17,
    sked: "daily",
    timesked: "09:00:00",
    group_ids: [30],
    device_id: ["0"],
    personnel_id: 0,
    status: 4,
    created: "2022-08-25 18:15:03",
    filename: "alert_count_dashboard_2022_09_16_091748_964.xlsx",
    is_sent: 4,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 0,
    checked: 0,
    time_sent: "2022-09-16 09:20:21",
    email_add: "email@sample.com",
    email_body:
      '<html><head></head><body><p><font color="#000000">Good Day,<br><br><br>Attached is the Detailed from 2022-09-15 00:00 to 2022-09-15 23:59 as requested.</font></p><p><font color="#000000">Please do not reply. This is a computer generated email. If you have concerns or feedback, email us at service@findme.com.ph.</font><br><br><br><font color="#000000">Sincerely,</font><br><br><font color="#000000">WTI Customer Support</font><br>trackme.com.ph</font></p></body></html>',
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  },
  {
    id: "374",
    user_id: 1,
    report_type: "Trips Check-in Check-out(CICO)",
    reporttype_id: 21,
    sked: "monthly:14",
    timesked: "13:00:00",
    group_ids: [30],
    device_id: ["17151"],
    personnel_id: 0,
    status: 2,
    created: "2022-08-12 14:49:45",
    filename: "null",
    is_sent: 2,
    time_checked: "1111-11-11 19:11:11",
    process_tries: 3,
    checked: 0,
    time_sent: "2022-09-16 00:10:09",
    email_add: "email@sample.com",
    email_body: "",
    emailer: "trackme-mailer-02@findme.com.ph",
    interval: 0,
    emailer_pw: "wt1tr@ckm3\n",
    is_blank_report: 0,
    alert_description_ids: ["0"],
    trip_status_id: [0],
    activity_only: 0
  }
];

export default data;
