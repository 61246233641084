import { useState } from "react";

const useGroupSelect = options => {
  const [selected, setSelected] = useState([]);

  const indeterminate =
    selected.length > 0 && selected.length !== options.length;
  const checked = selected.length > 0 && selected.length === options.length;

  const selectAll = callback => {
    setSelected(options);

    if (typeof callback === "function") callback(options);
  };
  const unselectAll = callback => {
    setSelected([]);
    if (typeof callback === "function") callback([]);
  };
  const toggleSelection = (item, callback) => {
    let newSelected = [];
    const index = selected.indexOf(item);
    if (index < 0)
      setSelected(prev => {
        prev.push(item);
        newSelected = [...prev];
        return [...prev];
      });
    else
      setSelected(prev => {
        prev.splice(index, 1);
        newSelected = [...prev];
        return [...prev];
      });

    if (typeof callback === "function") callback(newSelected);
  };

  return {
    selected,
    indeterminate,
    checked,
    selectAll,
    unselectAll,
    toggleSelection
  };
};

export default useGroupSelect;
