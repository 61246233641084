import React from "react";

const useIsMarkerClustered = (clusterInstance, needle) => {
  return React.useMemo(() => {
    const clusters = clusterInstance?.getClusters?.() || [];

    for (let i = 0; i < clusters.length; i++) {
      const markers = clusters[i].getMarkers();
      if (markers.length === 1) {
        if (markers[0].label.text === needle) {
          return true;
        }
      }
    }

    return false;
  }, [clusterInstance]);
};

export default useIsMarkerClustered;
