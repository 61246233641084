/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import {
  DialogTitle,
  InputBase,
  Paper,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  List,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  ListItemIcon,
  CircularProgress
} from "@material-ui/core";
import Cookies from "js-cookie";
import withReactContent from "sweetalert2-react-content";
import InfiniteScroll from "react-infinite-scroll-component";
import swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import {
  Search as SearchIcon,
  HelpOutline,
  TripOrigin
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import client from "../../../../Client";
import { GET_AVAILABLE_VEHICLES_QUERY, GET_NOTIFICATIONS_QUERY } from "../../../../graphql/Queries";
import {
  MANUAL_COMPLETE,
  CHANGE_VEHICLE_MUTATION,
  ADD_NOTIFICATION
} from "../../../../graphql/Mutations";
import {
  GET_BOOKINGS_v2,
  UPDATE_SPLIT_BOOKING,
  GET_BOOKING_LIST_V2
} from "../../../../api/bookingRepository";
import { isTripDataCompleted, gpsStatus } from "../../../Utils";
import Battery from "../../../../utils/Battery";
import AddLogs from "../../../../utils/functions/AddLogs";

const MySwal = withReactContent(swal);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
    boxShadow: "none",
  },
  muiListContainer: {
    overflow: "auto"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13,
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px",
  },
  muiListItem: {
    fontSize: 13,
    color: theme.palette.primary.dark,
  }
}));


const VehicleAssignDialog = props => {
  const classes = useStyles();
  const user = JSON.parse(Cookies.get("user"));
  const {
    toggle,
    tripData,
    queryFilter,
    closeVehicleModal,
    openVehicleModal,
    setTrip,
    moduleName,
    splitHandler,
    splitTripNumber,
    updateSplitVehicleInfo,
    splitInfo,
    subTrips
  } = props;
  const [tripDetail, setTripDetail] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [search, setSearch] = useState("");
  const [forReprocess, setForReprocess] = useState(true);
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [chosenVehicle, setChosenVehicle] = useState({
    vehicle_id: null,
    vehicle_type: null,
    vehicle_type_id: null,
    vehicle_plate_no: null
  });
  const [tripNumber, setTripNumber] = useState(null);
  const [oldTrip, setOldTrip] = useState([]);
  const [ongoingTripModal, setOngoingTripModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newTrips, setNewTrips] = useState();
  const [updateSplitList, setUpdateSplitList] = useState([]);
  const PRE_DISPATCH_MODULE = process.env.REACT_APP_PRE_DISPATCH_MODULE;

  const { data: vehicles_data, fetchMore, loading: loader, refetch } = useQuery(
    GET_AVAILABLE_VEHICLES_QUERY,
    {
      skip: _.isEmpty(tripDetail),
      variables: {
        dateRange: _.isEmpty(tripDetail) ? {} : { ...tripDetail.dateRange },
        // filter: [{ field: "vehicle_info.plateno", value: [keyword] }],
        first: 10,
        skip: 0,
        orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
      }
    }
  );

  useEffect(() => {
    if(tripData) {
      setTripDetail(tripData);
    }
  }, [tripData]);

  const updateBooking = () => {
    _.forEach(splitInfo, (variables, index) => {
      setTimeout(() => {
        UPDATE_SPLIT_BOOKING(variables)
        .then(result => {
          splitInfo[index].splitStatus = result.status;
          setNewTrips(splitInfo[index])
        })
      }, index * 1000)
    });
  }

  useEffect(() => {
    if(updateSplitVehicleInfo && splitInfo.length > 0) {
      const updateVehicle = subTrips.map((trip, index) => {
        const splitTripValue = tripDetail.rawTripData.value.filter(so => splitInfo.find(i => i.id === so.id).vehicle_id === trip.vehicle_id)
        handleChangeVehicle(subTrips[index], splitTripValue)
      })

      if (updateVehicle) {
        updateBooking();
      }
    }
  }, [updateSplitVehicleInfo, splitInfo]);

  const [changeTruck] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      closeVehicleModal();
      if (!splitTripNumber) {
        MySwal.fire({
          icon: "success",
          text: "The vehicle was successfully changed",
          timer: 3000,
          showConfirmButton: false,
          onClose: () => {
            props.fn();
            if(openModal) {
              setOngoingTripModal(true);
            }
          }
        });
      }
    },
    refetchQueries: () => {
      return [
        {
          query: GET_BOOKINGS_v2,
          variables: queryFilter
        },
        {
          query: GET_AVAILABLE_VEHICLES_QUERY,
          variables: {
            dateRange: _.isEmpty(tripDetail) ? {} : { ...tripDetail.dateRange },
            // filter: [{ field: "vehicle_info.plateno", value: [keyword] }],
            first: 10,
            skip: 0,
            orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
          }
        }
      ];
    }
  });

  const [removeVehicle] = useMutation(CHANGE_VEHICLE_MUTATION, {
    refetchQueries: () => {
      return [
        {
          query: GET_BOOKINGS_v2,
          variables: queryFilter
        },
        {
          query: GET_AVAILABLE_VEHICLES_QUERY,
          variables: {
            dateRange: _.isEmpty(tripDetail) ? {} : { ...tripDetail.dateRange },
            // filter: [{ field: "vehicle_info.plateno", value: [keyword] }],
            first: 10,
            skip: 0,
            orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
          }
        }
      ];
    }
  });

  const getStatus = status => {
    return status.toLowerCase() === "accepted" ||
      status.toLowerCase() === "scheduled"
      ? "Processing"
      : status;
  };

  const parseVehicleData = data => {
    const list = data.map(vehicle => {
      return {
        vehicle_id: vehicle.id,
        vehicle_type: vehicle.vehicle_info.vehicle_type,
        vehicle_type_id: vehicle.vehicle_info.vehicle_type_id,
        vehicle_plate_no: vehicle.vehicle_info.plateno,
        hauler: vehicle.client_name || "-",
        status: vehicle.trips ? "BOOKED" : "AVAILABLE",
        device_status: vehicle.device_status?.status_code,
        reportstamp: vehicle.device_status?.reportstamp,
        trip: vehicle.trips
          ? {
              trip_number: vehicle.trips[0].trip_number,
              trip_status: getStatus(vehicle.trips[0].status_code)
            }
          : null,
        provider: vehicle.device_config?.gps_provider || "-",
        battery_level: vehicle.device_status?.battery_level,
        device_type_id: vehicle.device_type_id,
        device_type: vehicle.device_type
      };
    });
    return _.orderBy(list, [l => l.vehicle_plate_no.toLowerCase()], ["asc"]);
  };

  useEffect(() => {
    if (vehicles_data) {
      const { get_available_vehicles } = vehicles_data;
      setVehicles(parseVehicleData(get_available_vehicles.vehicles));
    }
  }, [vehicles_data]);

  useEffect(() => {
    return () => {
      setSearch("");
      setChosenVehicle({
        vehicle_id: null,
        vehicle_type: null,
        vehicle_type_id: null,
        vehicle_plate_no: null
      });
      setMessage("");
      setIsDisabled(true);
    };
  }, [toggle]);

  useEffect(() => {
    if(toggle) {
      // setForReprocess(true);

      // For Split Trip, check if trip number has assigned vehicle
      const splitVehicle = subTrips?.find(t => t.trip_number === splitTripNumber);
      if (splitVehicle?.vehicle_id) {
        // If true, update states
        setForReprocess(splitVehicle.forReprocess);
        setChosenVehicle({
          vehicle_id: splitVehicle.vehicle_id,
          vehicle_type: splitVehicle.vehicle_type,
          vehicle_type_id: splitVehicle.vehicle_type_id,
          vehicle_plate_no: splitVehicle.vehicle_plate_no
        });
        setSearch(splitVehicle.vehicle_plate_no);
        setMessage(splitVehicle.message);

        // Show assigned vehicle only
        refetch({
          filter: [{ field: "vehicle_info.plateno", value: [splitVehicle.vehicle_plate_no] }]
        });
        setIsDisabled(false);
      } else {
        // If false (no assigned vehicle or not a split process), reset states
        setForReprocess(true);
        setSearch("");
        setChosenVehicle({
          vehicle_id: null,
          vehicle_type: null,
          vehicle_type_id: null,
          vehicle_plate_no: null
        });
        setMessage("");
        setIsDisabled(true);

        // Show all vehicles
        refetch({
          filter: [],
          first: 10,
          skip: 0,
          orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
        });
      }
    }
  }, [toggle]);

  function handleClose() {
    // setOpen(false);
    props.closeVehicleModal();
  }

  const handleFilter = event => {
    setSearch(event.target.value);
    setChosenVehicle({
      vehicle_id: null,
      vehicle_type: null,
      vehicle_type_id: null,
      vehicle_plate_no: null
    });
    setMessage("");
    setIsDisabled(true);
  };

  // Function used to check if selected vehicle has ongoing trip
  const selectVehicle = (event, vehicle) => {
    // assign the value of selected vehicle to setOldTrip variable
    setOldTrip(vehicle);
    // check if vehicle.trip has a valid value
    if (vehicle.trip) {
      // if true
      // get the propertis of vehicle array
      const { trip } = vehicle;
      // check if the trip number is not equal to the trip number in parsedTripData
      if (trip.trip_number !== tripDetail.parsedTripData.tripNumber) {
        // if true
        // check if trip status is equal to processing
        if (trip.trip_status === "Processing") {
          // if true
          // set variable setIsDisabled to false
          setIsDisabled(false);
          // assign value to setMessage variable
          setMessage(`Are you sure you want to reassign
          this to another trip? This vehicle is
          currently booked to trip number
          ${trip.trip_number}. Also, please assign 
          another vehicle to ${trip.trip_number} 
          to continue tracking`);
        } else {
          // if false
          // assign trip number to setTripNumber variable
          setTripNumber(trip.trip_number);
          // set variable setIsDisabled to false
          setIsDisabled(false);
          // assign value to setMessage variable
          setMessage(`This vehicle currently has an 
          ongoing trip, ${trip.trip_number}. 
          If you wish to continue, click Assign to 
          reassign this vehicle to a new trip. Also, 
          please assign another vehicle to 
          ${trip.trip_number} to continue tracking.`)
        }
      } else {
        // if false
        // set value of setMessage to default or empty string
        setMessage("");
        // set variable setIsDisabled to false
        setIsDisabled(false);
      }
      // assign vehicle array to variable setChosenVehicle
      setChosenVehicle(vehicle);
    } else {
      // if false
      // set variable setIsDisabled to false
      setIsDisabled(false);
      // assign vehicle array to variable setChosenVehicle
      setChosenVehicle(vehicle);
      // set value of setMessage to default or empty string
      setMessage("");
    }
  };

  
  const [addNotif] = useMutation(ADD_NOTIFICATION);

  const resetVehicle = () => {
    setChosenVehicle({
      vehicle_id: null,
      vehicle_type: null,
      vehicle_type_id: null,
      vehicle_plate_no: null
    });
    setMessage("");
    setIsDisabled(true);
  };

  useEffect(() => {
    if (newTrips) {
      setUpdateSplitList([...updateSplitList, newTrips])
    }
  }, [newTrips])

  useEffect(() => {
    if (updateSplitVehicleInfo && splitInfo.length > 0) {
      const { parsedTripData, rawTripData } = tripDetail;
      if (updateSplitList.length === rawTripData.value.length) {
        const isSuccess = updateSplitList.every(trip => trip.splitStatus === "success");
        const hasError = updateSplitList.filter(trip => trip.splitStatus === "error");
        if (isSuccess) {
          const tripNumber = parsedTripData.tripNumber || rawTripData.key;
          AddLogs(moduleName, "split_trip", tripNumber);
          MySwal.fire({
            icon: "success",
            text: "The trip was successfully splitted",
            timer: 3000,
            showConfirmButton: false,
            allowOutsideClick: false,
            onOpen: () => {
              addNotif({
                variables: {
                  notification: {
                    client_id: [rawTripData.value[0].client_id],
                    trip_number: tripNumber,
                    vehicle_plate_no: rawTripData.value[0].vehicle_plate_no,
                    vehicle_id: rawTripData.value[0].vehicle_id,
                    datestamp: moment()
                      .format("YYYY-MM-DD HH:mm:ss"),
                    notification: `Trip ${tripNumber} has been splitted to ${tripNumber}A and ${tripNumber}B`,
                    category: "User Activity",
                    is_archived: false,
                    creator: "Tmsuite-web",
                    group_ids: rawTripData.value[0].group_ids,
                    group_names: rawTripData.value[0].group_names
                  }
                }
              });
            },
            onAfterClose: () => {
              window.location.href =
                moduleName === PRE_DISPATCH_MODULE ? "prebooking" : "trips";
            }
          });
        } else {
          MySwal.fire({
            icon: "error",
            text: `${hasError.map(trip => trip.trip_number).toLocaleString()} encountered an error`,
            timer: 3000,
            showConfirmButton: false,
            onAfterClose: () => {
              window.location.href =
                moduleName === PRE_DISPATCH_MODULE ? "prebooking" : "trips";
            }
          });
        }
      }
    }
  }, [updateSplitList])

  const handleChangeVehicle = async (splitVehicle, splitTripValue) => {
    const { parsedTripData, rawTripData } = tripDetail;
    const { vehicle_history, raw_trip_status } = parsedTripData;
    const newVehicleHistory = vehicle_history ? [...vehicle_history] : [];
    const user = JSON.parse(Cookies.get("user"));
    const rawTrip = splitTripValue ? splitTripValue : rawTripData.value;
    const newVehicle = splitTripValue ? splitVehicle : chosenVehicle;
    const tripForReprocess = splitTripValue ? splitVehicle.forReprocess : forReprocess;
    const oldTripDetails = splitTripValue ? splitVehicle : oldTrip;
    if (oldTripDetails?.trip?.trip_number) {
      const bookingResponse = await client.query({
        query: GET_BOOKING_LIST_V2,
        variables: {
          trip_numbers: oldTripDetails.trip.trip_number
        },
        fetchPolicy: "network-only",
      });
      oldTripDetails.vehicle_history = bookingResponse?.data?.get_bookings_per_trip?.bookings[0]?.value[0]?.vehicle_history || [];
      oldTripDetails.booking_ids = bookingResponse?.data?.get_bookings_per_trip?.bookings[0]?.value.map(b => b.id) || [];
    }

    newVehicleHistory.push({
      vehicle_id: splitTripValue ? splitVehicle.vehicle_id : chosenVehicle.vehicle_id,
      vehicle_plate_no: splitTripValue ? splitVehicle.vehicle_plate_no : chosenVehicle.vehicle_plate_no,
      user_id: user.id,
      user_full_name: `${user.first_name} ${user.last_name}`,
      datestamp: moment().format("YYYY-MM-DD HH:mm:ss")
    });
    const is_completed = rawTrip.map(trip =>
      isTripDataCompleted(trip)
    );

    const for_saving = rawTrip.map(trip => {
      if(raw_trip_status !== "Accepted" && tripForReprocess) {
          return {
            id: trip.id,
            fields: {
              vehicle_id: newVehicle.vehicle_id,
              vehicle_plate_no: newVehicle.vehicle_plate_no,
              vehicle_type: newVehicle.vehicle_type,
              vehicle_type_id: newVehicle.vehicle_type_id,
              for_reprocess: tripForReprocess,
              vehicle_history: newVehicleHistory,
              data_complete: _.filter(is_completed, c => !c).length === 0,
              pickups: {
                arrival: trip.pickups[0].arrival,
                departure: trip.pickups[0].departure,
                geofence_code: trip.pickups[0].geofence_code,
                geofence_id: trip.pickups[0].geofence_id,
                location_details: trip.pickups[0].location_details,
                name: trip.pickups[0].name,
                so_number: trip.pickups[0].so_number,
                status_code: trip.pickups[0].status_code,
                status_code_id: trip.pickups[0].status_code_id
              },
              dropoffs: {
                arrival: trip.dropoffs[0].arrival,
                departure: trip.dropoffs[0].departure,
                geofence_code: trip.dropoffs[0].geofence_code,
                geofence_id: trip.dropoffs[0].geofence_id,
                location_details: trip.dropoffs[0].location_details,
                name: trip.dropoffs[0].name,
                so_number: trip.dropoffs[0].so_number,
                status_code: trip.dropoffs[0].status_code,
                status_code_id: trip.dropoffs[0].status_code_id
              },
              // status_code: trip.status_code,
              // status_code_id: trip.status_code_id
            }
          };
      } else {
        return {
          id: trip.id,
          fields: {
            vehicle_id: newVehicle.vehicle_id,
            vehicle_plate_no: newVehicle.vehicle_plate_no,
            vehicle_type: newVehicle.vehicle_type,
            vehicle_type_id: newVehicle.vehicle_type_id,
            for_reprocess: raw_trip_status !== "Accepted" && tripForReprocess,
            vehicle_history: newVehicleHistory,
            data_complete: _.filter(is_completed, c => !c).length === 0,
            ...(trip.dispatch_type?.toLowerCase() === "not yet" && {
              dispatch_time: null
            })
          }
        };
      }
    });

    const { trip } = newVehicle;
    if (trip) {
      if (trip.trip_number !== tripDetail.parsedTripData.tripNumber) {
        // fire mutation
        if (trip.trip_status === "Processing") {
          changeTruck({
            variables: {
              bookings: oldTripDetails.booking_ids.map(bid => {
                return {
                  id: bid,
                  fields: {
                    vehicle_id: "",
                    vehicle_plate_no: "",
                    vehicle_history: [
                      ...oldTripDetails.vehicle_history,
                      {
                        vehicle_id: "",
                        vehicle_plate_no: "",
                        user_id: user.id,
                        user_full_name: `${user.first_name} ${user.last_name}`,
                        datestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                      }
                    ],
                  data_complete: false
                }
                }
              })
            }
          });
          // removeVehicle({
          //   variables: {
          //     fields: [
          //       { field: "vehicle_plate_no", value: "" },
          //       { field: "vehicle_id", value: "" },
          //       { field: "data_complete", value: "false" },
          //       // { field: "pre_dispatched_ready", value: "false" },

          //       // { field: "dispatch_time", value: ""},
          //       // { field: "dispatch_type", value: ""},
          //       // { field: "vehicle_history.vehicle_id", value: ""},
          //       // { field: "vehicle_history.vehicle_plate_no", value: ""}
          //     ],
          //     conditions: [
          //       { field: "trip_number", value: trip.trip_number },
          //       { field: "vehicle_plate_no", value: oldTripDetails.vehicle_plate_no },
          //       { field: "pickups.arrival", value: tripDetail.dateRange.start},
          //       { field: "dropoffs.arrival", value: tripDetail.dateRange.end}
          //     ]
          //   }
          // });
          changeTruck({ variables: { bookings: for_saving } });
          if (!splitTripValue) {
            addNotif({
              variables: {
                notification: {
                  // client_id: [tripData.rawTripData.value[0].client_id],
                  trip_number: rawTripData.key,
                  vehicle_plate_no: oldTripDetails.vehicle_plate_no || "",
                  datestamp: moment()
                    .format("YYYY-MM-DD HH:mm:ss"),
                  notification: `Change truck of trip ${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${chosenVehicle.vehicle_plate_no || `-`}`,
                  category: "User Activity",
                  is_archived: false,
                  creator: "Tmsuite-web",
                  group_ids: tripDetail.rawTripData.value[0].group_ids,
                  group_names: tripDetail.rawTripData.value[0].group_names
                }
              }
            });

            parsedTripData.raw_dropoffs.map(dropoff => {
              addNotif({
                variables: {
                  notification: {
                    // client_id: [tripData.rawTripData.value[0].client_id],
                    trip_number: rawTripData.key,
                    vehicle_plate_no: newVehicle.vehicle_plate_no || "",
                    vehicle_id: newVehicle.vehicle_id || "",
                    datestamp: moment()
                      .format("YYYY-MM-DD HH:mm:ss"),
                    notification: `The assigned vehicle for Trip ${rawTripData.key} with SO ${dropoff.so_number} has been changed from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}.`,
                    category: "Store App",
                    is_archived: false,
                    creator: "Tmsuite-web",
                    group_ids: tripDetail.rawTripData.value[0].group_ids,
                    group_names: tripDetail.rawTripData.value[0].group_names,
                    geofence_id: dropoff.geofence_id,
                    do_number: dropoff.so_number,
                    booking_id: dropoff.trip_id
                  }
                }
              });
            })

            AddLogs(
              moduleName,
              'change_truck',
              `${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}`
            );

            AddLogs(
              moduleName,
              'change_truck',
              `${oldTripDetails.trip.trip_number} from ${oldTripDetails.vehicle_plate_no || `-`} to -`
            );
          }
          // set variable setOpenModal to true
          setOpenModal(true);
          // run the closeVehicleModal function
          closeVehicleModal();
        } else {
          // if false
          // re-organize the variable for_saving to a new array
          const forSaving = for_saving.map( row => {
            return ({
              id: row.id,
              fields: {
                vehicle_id: row.fields.vehicle_id,
                vehicle_plate_no: row.fields.vehicle_plate_no,
                vehicle_type: row.fields.vehicle_type,
                vehicle_type_id: row.fields.vehicle_type_id,
                for_reprocess: raw_trip_status !== "Accepted" && tripForReprocess,
                vehicle_history: row.fields.vehicle_history,
                data_complete: row.fields.data_complete
              }
            });
          });
          // remove the vehicle record of the old trip
          changeTruck({
            variables: {
              bookings: oldTripDetails.booking_ids.map(bid => {
                return {
                  id: bid,
                  fields: {
                    vehicle_id: "",
                    vehicle_plate_no: "",
                    vehicle_history: [
                      ...oldTripDetails.vehicle_history,
                      {
                        vehicle_id: "",
                        vehicle_plate_no: "",
                        user_id: user.id,
                        user_full_name: `${user.first_name} ${user.last_name}`,
                        datestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                      }
                    ],
                    data_complete: false,
                    dispatch_type: "Not Yet",
                    status_code: "Accepted",
                    status_code_id: process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
                    for_reprocess: true
                  }
                }
              })
            }
          });
          // removeVehicle({
          //   variables: {
          //     fields: [
          //       { field: "vehicle_plate_no", value: "" },
          //       { field: "vehicle_id", value: "" },
          //       { field: "data_complete", value: "false" },
          //       // { field: "pre_dispatched_ready", value: "false" },
          //       // { field: "dispatch_time", value: ""},
          //       { field: "dispatch_type", value: "Not Yet"},
          //       { field: "status_code", value: "Accepted"},
          //       { field: "status_code_id", value: process.env.REACT_APP_STATUS_TRIP_ACCEPTED},
          //       { field: "for_reprocess", value: "true"}
          //       // { field: "vehicle_history.vehicle_id", value: ""},
          //       // { field: "vehicle_history.vehicle_plate_no", value: ""}
          //     ],
          //     conditions: [
          //       { field: "trip_number", value: oldTripDetails.trip.trip_number },
          //       { field: "vehicle_plate_no", value: oldTripDetails.vehicle_plate_no },
          //       { field: "pickups.arrival", value: tripDetail.dateRange.start},
          //       { field: "dropoffs.arrival", value: tripDetail.dateRange.end}
          //     ]
          //   }
          // });
          // Push notification
          addNotif({
            variables: {
              notification: {
                client_id: [tripData.rawTripData.value[0].client_id],
                trip_number: oldTripDetails.trip.trip_number || "",
                vehicle_plate_no: oldTripDetails.vehicle_plate_no || "",
                datestamp: moment()
                  .format("YYYY-MM-DD HH:mm:ss"),
                notification: `This trip ${oldTripDetails.trip.trip_number} has been reset.`,
                category: "Trip",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: tripDetail.rawTripData.value[0].group_ids,
                group_names: tripDetail.rawTripData.value[0].group_names
              }
            }
          });
          // pass the forSaving variable to prepared mutation
          changeTruck({ variables: { bookings: forSaving } });
          // set variable setOpenModal to true
          setOpenModal(true);
          // Add Activity Logs
          if (!splitTripValue) {
            addNotif({
              variables: {
                notification: {
                  client_id: [tripData.rawTripData.value[0].client_id],
                  trip_number: rawTripData.key,
                  vehicle_plate_no: oldTripDetails.vehicle_plate_no || "",
                  datestamp: moment()
                    .format("YYYY-MM-DD HH:mm:ss"),
                  notification: `Change truck of trip ${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}`,
                  category: "User Activity",
                  is_archived: false,
                  creator: "Tmsuite-web",
                  group_ids: tripDetail.rawTripData.value[0].group_ids,
                  group_names: tripDetail.rawTripData.value[0].group_names
                }
              }
            });

            parsedTripData.raw_dropoffs.map(dropoff => {
              addNotif({
                variables: {
                  notification: {
                    client_id: [tripData.rawTripData.value[0].client_id],
                    trip_number: rawTripData.key,
                    vehicle_plate_no: newVehicle.vehicle_plate_no || "",
                    vehicle_id: newVehicle.vehicle_id || "",
                    datestamp: moment()
                      .format("YYYY-MM-DD HH:mm:ss"),
                    notification: `The assigned vehicle for Trip ${rawTripData.key} with SO ${dropoff.so_number} has been changed from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}.`,
                    category: "Store App",
                    is_archived: false,
                    creator: "Tmsuite-web",
                    group_ids: tripDetail.rawTripData.value[0].group_ids,
                    group_names: tripDetail.rawTripData.value[0].group_names,
                    geofence_id: dropoff.geofence_id,
                    do_number: dropoff.so_number,
                    booking_id: dropoff.trip_id
                  }
                }
              });
            })

            AddLogs(
              moduleName,
              'change_truck',
              `${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}`
            );

            AddLogs(
              moduleName,
              'change_truck',
              `${oldTripDetails.trip.trip_number} from ${oldTripDetails.vehicle_plate_no || `-`} to - `
            );
          }
          // run the closeVehicleModal function
          closeVehicleModal();
        }
      } else {
        // if false
        // run the closeVehicleModal function
        closeVehicleModal();
        // set variable setOpenModal to false
        setOpenModal(false);
      }
    } else {
      // pass the forSaving variable to prepared mutation
      changeTruck({ variables: { bookings: for_saving } });
      // set variable setOpenModal to false
      setOpenModal(false);
      if (!splitTripValue) {
        addNotif({
          variables: {
            notification: {
              client_id: [tripData.rawTripData.value[0].client_id],
              trip_number: rawTripData.key,
              vehicle_plate_no: oldTripDetails.vehicle_plate_no || "",
              datestamp: moment()
                .format("YYYY-MM-DD HH:mm:ss"),
              notification: `Change truck of trip ${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}`,
              category: "User Activity",
              is_archived: false,
              creator: "Tmsuite-web",
              group_ids: tripDetail.rawTripData.value[0].group_ids,
              group_names: tripDetail.rawTripData.value[0].group_names
            }
          }
        });

        parsedTripData.raw_dropoffs.map(dropoff => {
          addNotif({
            variables: {
              notification: {
                client_id: [tripData.rawTripData.value[0].client_id],
                trip_number: rawTripData.key,
                vehicle_plate_no: newVehicle.vehicle_plate_no || "",
                vehicle_id: newVehicle.vehicle_id || "",
                datestamp: moment()
                  .format("YYYY-MM-DD HH:mm:ss"),
                notification: `The assigned vehicle for Trip ${rawTripData.key} with SO ${dropoff.so_number} has been changed from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}.`,
                category: "Store App",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: tripDetail.rawTripData.value[0].group_ids,
                group_names: tripDetail.rawTripData.value[0].group_names,
                geofence_id: dropoff.geofence_id,
                do_number: dropoff.so_number,
                booking_id: dropoff.trip_id
              }
            }
          });
        })

        // Add Activity Logs
        AddLogs(
          moduleName,
          'change_truck',
          `${rawTripData.key} from ${parsedTripData.vehicle_plate_no || `-`} to ${newVehicle.vehicle_plate_no || `-`}`
        )
      }
    }
  };

  const fetchMoreVehicles = () => {
    fetchMore({
      variables: {
        skip: vehicles_data.get_available_vehicles.count
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!fetchMoreResult.get_available_vehicles) return prev;

        return {
          ...prev,
          get_available_vehicles: {
            ...prev.get_available_vehicles,
            total: fetchMoreResult.get_available_vehicles.total,
            count:
              fetchMoreResult.get_available_vehicles.count +
              prev.get_available_vehicles.count,
            vehicles: prev.get_available_vehicles.vehicles.concat(
              fetchMoreResult.get_available_vehicles.vehicles
            )
          }
        };
      }
    });
  };

  // Function used to close reassign modal
  const handleCloseModal = () => {
    // set variable setOngoingTripModal to false
    setOngoingTripModal(false);
    // run the closeVehicleModal function
    closeVehicleModal();
  }

  // Function used to open reassign modal
  const handleSetVehicle = () => {
    // assign old trip's trip number to variable setTrip
    setTrip(oldTrip.trip.trip_number);
    // set variable setOngoingTrip to false
    setOngoingTripModal(false);
    // run the openVehicleModal function
    openVehicleModal();
  }

  return (
    <div>
    <Dialog aria-labelledby="simple-dialog-title" open={ongoingTripModal}>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <h2 style={{ color: "black", fontWeight: "bold" }}>Assign ?</h2>
          Do you want to reassign {tripNumber} to another vehicle.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          style={{ borderRadius: "17px", margin: 10, width: 100 }}
          variant="outlined"
        >
          No
        </Button>
        <Button
          onClick={handleSetVehicle}
          style={{ borderRadius: "17px", margin: 10, width: 100 }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
      <Dialog
        open={toggle}
        onClose={() => {
          handleClose();
          setSearch("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        classes={{paper: classes.closeContainer}}
      >
        <DialogTitle id="alert-dialog-title"><span className={classes.closeTitle}>Select Truck</span></DialogTitle>
        <div style={{ padding: "6px 24px" }}>
        <form
              onSubmit={e => {
                e.preventDefault();
                // setVehicles([]);
                refetch({
                  dateRange: _.isEmpty(tripDetail)
                    ? {}
                    : { ...tripDetail.dateRange },
                  filter: [{ field: "vehicle_info.plateno", value: [search] }],
                  first: 10,
                  skip: 0,
                  orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
                });
              }}
            >
              <Paper className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Plate #"
                  inputProps={{ "aria-label": "plate #" }}
                  onChange={handleFilter}
                  value={search}
                  style={{ fontSize: 13 }}
                />
                <IconButton
                  // type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </form>
        </div>
        <DialogContent style={{ textAlign: "center" }} id="scrollableDiv">
          <InfiniteScroll
            dataLength={vehicles.length}
            next={fetchMoreVehicles}
            hasMore={
              vehicles_data &&
              vehicles_data.get_available_vehicles.count !==
                vehicles_data.get_available_vehicles.total
            }
            scrollableTarget="scrollableDiv"
            // style={{ height: 300, width: 400, overflow: "auto" }}
          >
            {/* <DialogContentText
            id="alert-dialog-description"
            component="span"
            style={{ textAlign: "center" }}
          > */}
            <div style={{ marginTop: 5 }}>
              <CircularProgress
                size={60}
                style={loader ? {} : { display: "none" }}
              />
            </div>
            {vehicles.length && !loader ? (
              <List>
                {vehicles.map((vehicle, index) => {
                  const title =
                    gpsStatus(vehicle.device_status).label === "No GPS" ? (
                      <p className={classes.tooltipTitle}>No GPS</p>
                    ) : (
                      <Fragment>
                        <p
                          className={classes.tooltipTitle}
                          style={{ fontWeight: "bold" }}
                        >
                          {gpsStatus(vehicle.device_status).label}
                        </p>
                        <p className={classes.tooltipTitle}>
                          {`Last Report: ${moment(vehicle.reportstamp).format("lll")}`}<br/>
                          {`GPS Provider: ${vehicle.provider}`}
                        </p>
                      </Fragment>
                    );
                  return (
                    <Tooltip
                      title={title}
                      key={`${vehicle.id}-${index}`}
                      placement="bottom-start"
                    >
                      <div>
                        <ListItem
                          key={vehicle.id}
                          button
                          onClick={event => selectVehicle(event, vehicle)}
                          // data-vehicle={vehicle.platenum}
                          selected={
                            chosenVehicle.vehicle_plate_no ===
                            vehicle.vehicle_plate_no
                          }
                        >
                          {(vehicle.device_type_id.toString() === process.env.REACT_APP_DEVICE_TYPE_ID && vehicle.vehicle_type.toLowerCase() === "container van") ? 
                            (
                              <ListItemIcon>
                                <Battery
                                  vehicleType={vehicle.vehicle_type}
                                  deviceTypeId={vehicle.device_type_id}
                                  batteryLevel={vehicle.battery_level}
                                  tooltipTitle={
                                    <Fragment>
                                      <p style={{ margin: 0, padding: 0 }}>Battery Life: {vehicle.battery_level ? vehicle.battery_level : 0} %</p>
                                      <p style={{ margin: 0, padding: 0 }}>As of {(vehicle.reportstamp) ? moment(vehicle.reportstamp).format("lll") : "-"}</p>
                                    </Fragment>}
                                />
                              </ListItemIcon>
                            ) : null
                          }
                          <ListItemIcon>
                            <TripOrigin
                              style={{
                                color: gpsStatus(vehicle.device_status).color,
                                fontSize: 16,
                                margin: "auto"
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`vehicle-${vehicle.id}`}
                            primary={<span className={classes.muiListItem}>{vehicle.vehicle_plate_no}</span>}
                            component="span"
                            secondary={
                              <Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.muiListItem}
                                  color="textPrimary"
                                  style={{ marginRight: "50px", }}
                                
                                >
                                  {`Truck Type: ${vehicle.vehicle_type}`}
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.muiListItem}
                                  color="textPrimary"
                                  style={{ marginRight: "50px" }}
                                >
                                  {`Hauler: ${vehicle.hauler}`}
                                </Typography>
                              </Fragment>
                            }
                          />
                          <Typography
                            style={{ float: "right" }}
                            variant="body2"
                            component="span"
                            className={classes.muiListItem}
                          >
                            {vehicle.status.toUpperCase()}
                          </Typography>
                        </ListItem>
                        <Divider light />
                      </div>
                    </Tooltip>
                  );
                })}
              </List>
            ) : (
              <Typography
                variant="h6"
                component="span"
                style={{ marginTop: "20px" }}
              >
                {!loader ? "No Available Vehicles" : "Loading"}
              </Typography>
            )}
            {/* </DialogContentText> */}
          </InfiniteScroll>
        </DialogContent>
        <div style={{ paddingRight: 24, paddingLeft: 24 }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={
                  tripDetail.parsedTripData &&
                  tripDetail.parsedTripData.raw_trip_status === "Accepted"
                }
                checked={
                  tripDetail?.parsedTripData?.status === "Processing" ? false :
                  forReprocess
                }
                onChange={e => setForReprocess(e.target.checked)}
              />
            }
            label={<span className={classes.muiListItem}>Overwrite previous vehicle data</span>}
          />
          <Tooltip
            title={
              <ul
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 10,
                  paddingLeft: 10,
                  margin: 0
                }}
              >
                <li>
                  If checked, the system will replaced the arrival and departure
                  time based on the newest vehicle
                </li>
                <li>
                  If unchecked, the system will only continue to monitor the
                  newest vehicle
                </li>
              </ul>
            }
          >
            <HelpOutline
              style={{
                float: "right",
                color: "red",
                fontSize: 16,
                marginTop: 15
              }}
            />
          </Tooltip>
          <p>{message}</p>
        </div>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              resetVehicle();
            }}
            variant="outlined"
            color="primary"
            className={classes.circularButtonNo}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            className={classes.circularButtonYes}
            disabled={isDisabled}
            onClick={() => {
              if (splitHandler && splitInfo.length === 0) {
                splitHandler(splitTripNumber, chosenVehicle, forReprocess, message);
                closeVehicleModal();
                setSearch("");
                refetch({
                  filter: [],
                  first: 10,
                  skip: 0,
                  orderBy: [{ field: "vehicle_info.plateno", direction: "asc" }]
                });
              } else {
                handleChangeVehicle()
              }
            }}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VehicleAssignDialog;
