import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  InputBase,
  Snackbar,
  IconButton
} from "@material-ui/core";
import { AddCircle, ArrowBack, Close, Delete, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import ConfirmationDialog from "../../../utils/modals/ConfirmationDialog";
import {
  ADD_CHECKLIST_360,
  EDIT_CHECKLIST_360
} from "../../../graphql/Mutations";
import { GET_CHECKLIST_360 } from "../../../graphql/Queries";
import Loading from "../../../utils/Loading";
import _ from "lodash";
import InitializeImg from "../../../assets/initialize.svg";
import { useStyles } from "./EditChecklist.styles";
import AddLogs from "../../../utils/functions/AddLogs";

const useClickOutside = handler => {
  const domNode = useRef();
  useEffect(() => {
    const eventHandler = event => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("click", eventHandler);
    return () => {
      document.removeEventListener("click", eventHandler);
    };
  });
  return domNode;
};

const WelcomePage = props => {
  const classes = useStyles();
  const { handleClick, handleBack } = props;

  return (
    <Container className={classes.container} style={{ justifyContent: "center", alignItems: "center", position: "relative" }} maxWidth={false}>
      <Button className={classes.btn_back} onClick={handleBack}>
        <ArrowBack/>
        <Typography>Back</Typography>
      </Button>
      <img src={InitializeImg} style={{ width: "40%" }}/>
      <Typography
        variant="h4"
        style={{
          fontWeight: 600,
          fontFamily: "sans-serif",
          marginTop: 10
        }}
      >
        Welcome to Edit 360 Checklist
      </Typography>
      <Typography style={{ fontWeight: 500, color: "#ACACAC" }}>
        Click here to initialize your data.
      </Typography>
      <Button
        className={classes.btn}
        style={{ textTransform: "none", marginTop: 10 }}
        onClick={handleClick}
      >
        Let's Start
      </Button>
    </Container>
  );
};

const Edit360Checklist = props => {
  const classes = useStyles();
  const { toggleEditMode, group } = props;

  const scrollToRef = useRef(null);
  const scrollToBottom = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  // string to array
  const parseData = data => {
    let text = data;
    text = `[${text.replace(/'/g, '"')}]`;
    return JSON.parse(text);
  };

  // array to checklist string
  const arrToString = data => {
    let text = JSON.stringify(data);
    text = text.slice(1, -1).replace(/"/g, "'");
    return text;
  };

  const { data, loading, refetch: refetchChecklist } = useQuery(
    GET_CHECKLIST_360,
    {
      variables: { value: String(group?.group_id) },
      onCompleted: (data) => {
        if (!data.get_checklists_360.total) {
          setShowWelcome(true);
        } else {
          const { id, checklist } = data?.get_checklists_360.checklists360[0];
          initList(id, checklist);
        }
        setCategoryIdx(0);
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false
        });
      },
      fetchPolicy: "network-only"
    }
  );

  const [createChecklist, { loading: initLoading, data: initData }] = useMutation(ADD_CHECKLIST_360);
  const [updateChecklist] = useMutation(EDIT_CHECKLIST_360);

  const [checklistId, setChecklistId] = useState();
  const [list, setList] = useState();
  const [categoryIdx, setCategoryIdx] = useState();
  const [itemModified, setItemModified] = useState();
  const [open, setOpen] = useState({
    delete: false,
    save: false,
    cancel: false,
    alert: false,
    error: false
  });
  const [dropdown, setDropdown] = useState();
  const dropdownNode = useClickOutside(() => setDropdown(false));
  const [errors, setErrors] = useState([""]);
  const [showErrors, setShowErrors] = useState(false);
  const [transition, setTransition] = useState({ add: false, remove: false });
  const [duplicates, setDuplicates] = useState([]);
  const [initialState, setInitialState] = useState();
  const [showWelcome, setShowWelcome] = useState(false);
  const [changes, setChanges] = useState({
    added: [],
    edited: [],
    deleted: []
  });

  // initialize list state and checklist id
  const initList = (id, list) => {
    setChecklistId(id);
    const newList = parseData(list);
    setInitialState(newList);
    setList(newList);
  };

  const handleInit = () => {
    createChecklist({
      variables: {
        group_id: Number(group?.group_id)
      },
      onCompleted: (data) => {
        if (data) {
          const { id, checklist } = data?.initialize_checklist_360.checklists360;
          initList(id, checklist);
          setShowWelcome(false);
          AddLogs("PMS - 360 Checklist", "checklist_initialized", group?.name);
        }
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false
        });
      }
    });
  }

  useEffect(() => {
    if (list) {
      scrollToBottom();
    }
  }, [list, categoryIdx]);

  // validate part name fields of selected category
  const validateFields = () => {
    const errors = list[categoryIdx]?.parts?.map(item =>
      item.name !== "" ? "" : "This field is required"
    );
    const isValid = errors.every(el => el === "");
    setErrors(errors);
    setShowErrors(!isValid);
    return isValid;
  };

  const updateParts = updatedParts => {
    setList(prev => [
      ...prev.slice(0, categoryIdx),
      { ...list[categoryIdx], parts: updatedParts },
      ...prev.slice(categoryIdx + 1)
    ]);
  };

  // input change
  const handleFormChange = (e, idx) => {
    const { name, value } = e.target;
    const parts = list[categoryIdx]?.parts.map((item, itemIdx) => {
      return itemIdx === idx ? { ...item, [name]: value } : item;
    });
    updateParts(parts);
  };

  // category dropdown
  const handleChange = val => {
    setTransition({ add: false, remove: false });
    if (validateFields()) {
      setCategoryIdx(
        list.findIndex(({ category_name }) => category_name === val)
      );
    }
    setDropdown(false);
  };

  // opens save/delete/cancel modal and delete snackbar
  const handleOpen = type => {
    let val = true;
    if (type === "save") {
      if (!validateFields()) {
        val = false;
      }
      // checks for duplicates in each category
      const duplicates = list.map(category =>
        category.parts
          ?.filter(
            (el, idx, arr) =>
              arr.findIndex(part => part.name === el.name) !== idx
          )
          .map(item => item.name)
      );

      if (duplicates.some(arr => arr.length !== 0)) {
        const allDuplicates = duplicates.reduce((flatten, arr) => [
          ...flatten,
          ...arr
        ]);
        setDuplicates([...new Set(allDuplicates)]);
        type = "error";
      }
    }
    setOpen(prev => ({ ...prev, [type]: val }));
  };

  // closes modals and snackbar
  const handleClose = type => {
    setOpen(prev => ({ ...prev, [type]: false }));
  };

  // adds new part form when previous fields are valid
  const handleAdd = () => {
    if (validateFields()) {
      setTransition({ add: true, remove: false });
      const updatedParts = [
        ...list[categoryIdx].parts,
        { name: "", remarks: "" }
      ];
      updateParts(updatedParts);
    }
  };

  // removes part from array
  const handleDelete = () => {
    setTransition(prev => ({ ...prev, remove: true }));
    const updatedParts = list[categoryIdx]?.parts.filter(
      (_, idx) => idx !== itemModified?.idx
    );
    updateParts(updatedParts);
    
    const fromInitialState = initialState[categoryIdx]?.parts.find(o => o.name === itemModified?.name);
    const fromEdited = changes.edited.find(o => o.name === itemModified?.name);
    if ((fromInitialState || fromEdited) && itemModified?.name) {
      setChanges(prev => ({ ...prev, deleted: [ ...prev.deleted, itemModified ] }));
    }
    handleOpen("alert");
  };

  // adds itemToDelete back to array
  const handleUndoDelete = () => {
    setTransition({ add: false, remove: false });
    const { idx, name, remarks } = itemModified;
    const updatedParts = [
      ...list[categoryIdx]?.parts.slice(0, idx),
      { name: name, remarks: remarks },
      ...list[categoryIdx]?.parts.slice(idx)
    ];
    updateParts(updatedParts);
    setChanges(prev => ({ ...prev, deleted: prev.deleted.filter(e => e.name !== name) }));
    handleClose("alert");
  };

  // handles mutation of checklist
  const handleSave = () => {
    let addedItems = list.map((category, idx) => category.parts.filter(x => !initialState[idx]?.parts.includes(x))).reduce((flatten, arr) => [
      ...flatten,
      ...arr
    ]);
    let editedItems = list.map((category) => category.parts.filter(x => changes.edited.includes(x))).reduce((flatten, arr) => [
      ...flatten,
      ...arr
    ]);
    editedItems = editedItems.map(o => o.name);
    addedItems = addedItems.map(o => o.name).filter(x => !editedItems.includes(x));
    let deletedItems = changes.deleted.map(o => o.name);

    updateChecklist({
      variables: {
        id: Number(checklistId),
        group_id: Number(group?.group_id),
        checklist: arrToString(list)
      },
      onCompleted: (data) => {
        if (data?.edit_checklist_360.success) {
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 2500
          }).then(() => {
            if (addedItems.length) {
              AddLogs("PMS - 360 Checklist", "vehicle_part_added", `${addedItems.join(", ")} for client ${group?.name}`);
            }
            if (editedItems.length) {
              AddLogs("PMS - 360 Checklist", "vehicle_part_edited", `${editedItems.join(", ")} for client ${group?.name}`);
            }
            if (deletedItems.length) {
              AddLogs("PMS - 360 Checklist", "vehicle_part_deleted", `${deletedItems.join(", ")} for client ${group?.name}`);
            }
            refetchChecklist().then(() => {
              toggleEditMode();
            });
          });
        }
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false
        });
      }
    });
  };

  if (loading || initLoading) {
    return <Loading />;
  }

  if (data && showWelcome) {
    return <WelcomePage handleClick={handleInit} handleBack={toggleEditMode} />;
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <div className={classes.row}>
        <Typography
          variant="h5"
          style={{
            marginRight: "2rem",
            fontWeight: "600",
            letterSpacing: 1,
            whiteSpace: "nowrap"
          }}
        >
          Edit 360 Checklist
        </Typography>
        <div className={classes.row}>
          <Typography component="h3">Category: </Typography>
          <div style={{ userSelect: "none" }} ref={dropdownNode}>
            <div
              className={classes.dropdown_btn}
              onClick={() => setDropdown(!dropdown)}
            >
              {categories[categoryIdx] || categories[0]}
              <ExpandMore className={classes.icon} />
            </div>
            {dropdown && (
              <div className={classes.dropdown_menu}>
                <ul>
                  {categories.map((category, idx) => (
                    <li
                      key={idx}
                      value={category}
                      onClick={() => handleChange(category)}
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={classes.items}>
        {list &&
          list[categoryIdx]?.parts.map((part, idx) => (
            <Paper
              className={`${classes.paper} ${
                transition.remove && idx >= itemModified?.idx
                  ? classes.removeItem
                  : ""
              }`}
              key={idx}
            >
              <div className={classes.col}>
                <div className={classes.badge}>{idx + 1}</div>
                <Delete
                  className={classes.icon}
                  onClick={() => {
                    setItemModified({ idx: idx, ...part });
                    handleOpen("delete");
                    setTransition({ add: false, remove: false });
                  }}
                />
              </div>
              <div className={classes.col} style={{ width: "100%" }}>
                <Box style={{ width: "100%", borderBottom: "1px solid" }}>
                  <Typography style={{ userSelect: "none" }}>
                    Part name
                  </Typography>
                  <InputBase
                    name="name"
                    value={part.name || ""}
                    onChange={e => handleFormChange(e, idx)}
                    placeholder="Input part name"
                    style={{ width: "100%" }}
                    inputProps={{ maxLength: 50 }}
                    onFocus={() => setItemModified(initialState[categoryIdx].parts[idx])}
                    onBlur={() => {
                      const fromInitialState = initialState[categoryIdx]?.parts?.includes(itemModified);
                      if (fromInitialState && itemModified.name !== part.name) {
                        setChanges(prev => ({ ...prev, edited: [ ...prev.edited, part ] }));
                      }
                      validateFields();
                      setShowErrors(false);
                      setTransition({ remove: false, add: false });
                    }}
                    required
                  />
                </Box>
                {errors[idx] && showErrors && (
                  <Alert severity="error" className={classes.alert}>
                    {errors[idx]}
                  </Alert>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: "1",
                    width: "100%"
                  }}
                >
                  <Typography style={{ userSelect: "none" }}>
                    Remarks
                  </Typography>
                  <div
                    style={{
                      border: "1px solid",
                      height: "100%",
                      flexGrow: "1"
                    }}
                  >
                    <InputBase
                      className={classes.remarks_txt}
                      name="remarks"
                      value={part.remarks || ""}
                      onChange={e => handleFormChange(e, idx)}
                      placeholder="Input remarks"
                      multiline
                      inputProps={{ maxLength: 100 }}
                      onFocus={() => setItemModified(initialState[categoryIdx].parts[idx])}
                      onBlur={() => {
                        const fromInitialState = initialState[categoryIdx]?.parts?.includes(itemModified)
                        if (fromInitialState && itemModified.remarks !== part.remarks) {
                          setChanges(prev => ({ ...prev, edited: [ ...prev.edited, part ] }));
                        }
                      }}
                    />
                  </div>
                </Box>
              </div>
            </Paper>
          ))}
        <Paper
          className={`${classes.paper} ${
            transition.add ? classes.addItem : ""
          } ${transition.remove ? classes.removeItem : ""}`}
          style={{ alignItems: "center", justifyContent: "center" }}
          ref={scrollToRef}
        >
          <AddCircle
            className={classes.icon}
            style={{ width: "80px", height: "80px" }}
            onClick={handleAdd}
          />
        </Paper>
      </div>
      <Box className={classes.bottom}>
        <Button
          className={classes.btn}
          style={{
            marginRight: "1rem",
            color: "#F49400",
            background: "white",
            border: "1px solid #F49400"
          }}
          onClick={() => {
            if (!_.isEqual(initialState, list)) {
              handleOpen("cancel");
            } else {
              if (initData) {
                refetchChecklist();
              }
              toggleEditMode();
            }
          }}
        >
          Cancel
        </Button>
        <Button classes={{root: classes.btn}} onClick={() => handleOpen("save")} disabled={list && _.isEqual(initialState, list)}>
          Save Details
        </Button>
      </Box>
      <ConfirmationDialog
        toggle={open.error}
        close={() => handleClose("error")}
        fn={() => handleClose("error")}
        title="Existing Vehicle Part"
        content={
          <>
            <strong>({duplicates.join(", ")})</strong> already exists and cannot
            be saved.
          </>
        }
        type="basic_confirm_modal"
      />
      <ConfirmationDialog
        toggle={open.delete}
        close={() => handleClose("delete")}
        fn={handleDelete}
        title="Delete?"
        content="Are you sure you want to delete this?"
      />
      <ConfirmationDialog
        toggle={open.cancel}
        close={() => handleClose("cancel")}
        fn={() => {
          refetchChecklist().then(() => toggleEditMode());
        }}
        title="Discard Changes?"
        content="Are you sure you want to leave this page? Unsaved changes will be discarded."
      />
      <ConfirmationDialog
        toggle={open.save}
        close={() => handleClose("save")}
        fn={handleSave}
        title="Save?"
        content="Are you sure you want to save the changes made? Adjustments done on the current checklist will reflect on future checklists."
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open.alert}
        onClose={() => handleClose("alert")}
        message="Part Deleted"
        autoHideDuration={5000}
        action={
          <React.Fragment>
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={handleUndoDelete}
            >
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose("alert")}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};

export default Edit360Checklist;

const categories = [
  "Lights",
  "Emergency Kit",
  "Tires",
  "Glass and Wipers",
  "Fluids"
];