import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  Typography,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  IconButton
} from "@material-ui/core";
import _ from "lodash";
import Cookie from "js-cookie";
import XLSX from "xlsx";
import {
  CloudUpload,
  Publish,
  ExpandMore,
  ExpandLess
} from "@material-ui/icons";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/client";
import { UPLOAD_ALERT_REFERENCES } from "../../../../../graphql/Mutations";
import { GET_ALERT_REFERENCES } from "../../../../../graphql/Queries";
import validate from "../../../../../utils/validation";
import AddLogs from "../../../../../utils/functions/AddLogs";
import useUserContext from "../../../../../context/User/useUserContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  typography_red: {
    fontSize: 12,
    color: "red"
  },
  typography_failed: {
    fontSize: 12,
    color: "#FF6363"
  },
  typography_success: {
    fontSize: 12,
    color: "#10E23F"
  },
  typography_partial: {
    fontSize: 12,
    color: "#FF7043"
  },
  typography_uploading: {
    fontSize: 12,
    color: "#3f3f3f"
  },
  icon: {
    opacity: 1
  },
  dialog: {
    borderRadius: "10px",
    width: 600
  }
});

const ExpansionPanel = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    // boxShadow: "none",
    // "&:not(:last-child)": {
    //   borderBottom: 0
    // },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const Upload = props => {
  const { open, getQueryConditions, setUploadOpen } = props;
  const classes = useStyles();
  const uploaderEl = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const [expanded, setExpanded] = useState(-1);
  // const [initData, setInitData] = useState();

  const [addAlertReferences] = useMutation(UPLOAD_ALERT_REFERENCES, {
    onCompleted(res) {
      AddLogs("Admin - Alert References", "upload", "");
    },
    refetchQueries: () => {
      return [
        {
          query: GET_ALERT_REFERENCES,
          variables: getQueryConditions()
        }
      ];
    }
  });

  const pendingFile = () => {
    if (files.length > 0) {
      if (!files[files.length - 1].status) {
        return true;
      }
    }
    return false;
  };

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }
    return comparison;
  };

  const handleUpload = e => {
    const { files: raw_files } = e.target;
    let uploaded_files = [];
    _.forEach(raw_files, file => {
      uploaded_files = [...files, { file: file, status: "", errors: [] }];
    });
    setFiles(uploaded_files);
    e.target.value = null;
  };

  const formatLevel = level => {
    let text = "";
    switch (level) {
      case "Discrete":
        text = 0;
        break;
      case "Normal":
        text = 1;
        break;
      case "Moderate":
        text = 2;
        break;
      case "Critical":
        text = 3;
        break;
      default:
        break;
    }
    return text;
  };

  function check_status(status) {
    if (status) {
      if (status === "Uploading") {
        return (
          <Typography className={classes.typography_uploading}>
            {status}
          </Typography>
        );
      }
      if (status === "Failed") {
        return (
          <Typography className={classes.typography_failed}>
            {status}
          </Typography>
        );
      }
      if (status === "Success") {
        return (
          <Typography className={classes.typography_success}>
            {status}
          </Typography>
        );
      }
      if (status === "Partially Completed") {
        return (
          <Typography className={classes.typography_partial}>
            {status}
          </Typography>
        );
      }
    } else {
      return (
        <Typography className={classes.typography_uploading}>
          {status}
        </Typography>
      );
    }
  }

  const parseRow = (data, headers, i) => {
    const errors = [];
    let temp = {};
    _.forEach(headers, (key, index) => {
      Object.assign(temp, { [key]: data[index] });
    });

    temp = {
      ...temp,
      alert_description: temp.alert_description
        ? temp.alert_description.toString()
        : "",
      code: temp.code ? temp.code.toString() : "",
      alertmessage: temp.alertmessage ? temp.alertmessage.toString() : "",
      level: temp.level ? formatLevel(temp.level) : null,
      device_type: temp.device_type ? temp.device_type.toString() : "",
      description: temp.description ? temp.description.toString() : "",
      group_ids: user.group_ids ? user.group_ids : [],
      is_valid: true
    };

    if (temp.alert_description === "") {
      errors.push({ message: "Alert Description is required", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.alert_description)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }
    if (temp.code === "") {
      errors.push({ message: "Alert Code is required", row: i + 1 });
    }
    // if (validate("noSpecialChar", temp.code)) {
    //   errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    // }
    if (temp.alertmessage === "") {
      errors.push({ message: "Message Display is required", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.alertmessage)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.alertmessage)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }
    if (temp.level === "" || temp.level === null) {
      errors.push({ message: "Alert level doesn't exist", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.level)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }
    if (temp.device_type === "") {
      errors.push({ message: "GPS Device Type is required", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.device_type)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }
    if (validate("noSpecialChar", temp.description)) {
      errors.push({ message: "No Special Characters Allowed", row: i + 1 });
    }

    if (errors.length > 0) {
      temp = {
        ...temp,
        errors: [...errors],
        is_valid: false
      };
    }
    return temp;
  };

  const submitFiles = async () => {
    setUploading(true);
    const expected_headers = [
      "Alert Description *",
      "Alert Code *",
      "Message Display *",
      "Alert Level *",
      "GPS Device Type *",
      "Remarks"
    ];

    const parsed_headers = [
      "alert_description",
      "code",
      "alertmessage",
      "level",
      "device_type",
      "description"
    ];
    const temp = [...files];
    _.forEach(files, (file, index) => {
      if (file.status === "") {
        const reader = new FileReader();
        reader.onload = async e => {
          const dataRes = new Uint8Array(e.target.result);
          const workbook = XLSX.read(dataRes, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false
          });

          if (data[0].join(",") === expected_headers.join(",")) {
            if (file.file.size / 1024 / 1024 > 5) {
              temp[index].status = "Failed";
              temp[index].errors = "File is too large";
              setFiles(temp);
              setUploading(false);
            } else if (data.length <= 1) {
              temp[index].status = "Failed";
              temp[index].errors = "File is empty";
              setFiles(temp);
              setUploading(false);
            } else if (data.length >= 1002) {
              temp[index].status = "Failed";
              temp[index].errors =
                "Maximum number of rows in a file should be 1000 only";
              setFiles(temp);
              setUploading(false);
            } else {
              const valid_data = [];
              const invalid_data = [];
              const invalid_rows = [];
              const valid_rows = [];
              _.forEach(data, (d, i) => {
                if (i > 0) {
                  const v = parseRow(d, parsed_headers, i);
                  if (v.is_valid) {
                    _.unset(v, "is_valid");
                    valid_data.push(v);
                    valid_rows.push(i + 1);
                  } else {
                    invalid_data.push(v);
                    invalid_rows.push(i + 1);
                    // temp[index].status = "Failed";
                    temp[index].errors = [...temp[index].errors, ...v.errors];
                  }
                }
              });

              temp[index].errors = _.sortBy(temp[index].errors, ["row"]);
              if (valid_data.length > 0) {
                addAlertReferences({
                  variables: {
                    alert_references: valid_data
                  }
                })
                  .then(({ data: newData }) => {
                    const rl = _.filter(newData.add_alert_references, {
                      success: false
                    }).length;
                    if (rl === invalid_data.length + valid_data.length) {
                      temp[index].status = "Failed";
                      _.forEach(newData.add_alert_references, (nv, r) => {
                        _.forEach(nv.error, err => {
                          temp[index].errors.push({
                            message:
                              err.message === "Invalid Alert Description"
                                ? "Alert Description does not exist."
                                : err.message,
                            row: valid_rows[r]
                          });
                        });
                      });
                      temp[index].errors = _.sortBy(temp[index].errors, [
                        "row"
                      ]);
                      setFiles(temp);
                      setUploading(false);
                    } else if (rl === 0 && invalid_data.length === 0) {
                      temp[index].status = "Success";
                      setFiles(temp);
                      setUploading(false);
                    } else {
                      if (data.length - 1 === invalid_data.length + rl) {
                        temp[index].status = "Failed";
                        _.forEach(newData.add_alert_references, (nv, r) => {
                          _.forEach(nv.error, err => {
                            temp[index].errors.push({
                              message:
                                err.message === "Invalid Alert Description"
                                  ? "Alert Description does not exist."
                                  : err.message,
                              row: valid_rows[r]
                            });
                          });
                        });
                      } else {
                        temp[index].status = "Partially Completed";
                        _.forEach(newData.add_alert_references, (nv, r) => {
                          _.forEach(nv.error, err => {
                            temp[index].errors.push({
                              message:
                                err.message === "Invalid Alert Description"
                                  ? "Alert Description does not exist."
                                  : err.message,
                              row: valid_rows[r]
                            });
                          });
                        });
                      }

                      temp[index].errors = _.sortBy(
                        [...new Set(temp[index].errors)],
                        ["row"]
                      );

                      setFiles(temp);
                      setUploading(false);
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: "error",
                      text: "Something went wrong"
                    });
                  });
              } else if (valid_data.length === 0) {
                temp[index].status = "Failed";
                setFiles(temp);
                setUploading(false);
              }
            }
          } else {
            temp[index].status = "Failed";
            temp[index].errors = "Please use the template provided";
            setFiles(temp);
            setUploading(false);
          }
        };
        reader.readAsArrayBuffer(file.file);
      }
    });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { width: 600, height: 405 } }}
      onClose={() => {
        if (!uploading) {
          setUploadOpen(false);
          setFiles([]);
        }
      }}
    >
      <DialogTitle>Upload</DialogTitle>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContent,
          dividers: classes.dialogContent
        }}
      >
        {files.length > 0 &&
          files.map((fileInfo, index) => (
            <ExpansionPanel
              key={index}
              square
              expanded={index === expanded}
              onClick={() => handleClick(index)}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.typography}>
                      {fileInfo.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.typography}>
                      {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        {check_status(fileInfo.status)}
                        {/* <Typography className={classes.typography_red}> 
                          {fileInfo.status}
                        </Typography>*/}
                      </Grid>
                      {fileInfo.status && (
                        <Grid item>
                          <IconButton size="small">
                            {index === expanded ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {(fileInfo.status === "Failed" ||
                fileInfo.status === "Partially Completed") && (
                <ExpansionPanelDetails>
                  {typeof fileInfo.errors === "string" ? (
                    <Typography style={{ fontSize: 14, margin: 8 }}>
                      {fileInfo.errors}
                    </Typography>
                  ) : (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "100px" }}>Row</TableCell>
                          <TableCell align="left">Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fileInfo.errors.length > 0 &&
                          fileInfo.errors
                            .filter(row => row.errors !== "")
                            .sort(reorder)
                            .map(row => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  <Typography className={classes.typography}>
                                    {row.row}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {" "}
                                  <Typography className={classes.typography}>
                                    {row.message}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  )}
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          ))}
        {/* {files.length > 0 &&
          files.map(f => (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={f.errors.length ? <ExpandMoreIcon /> : ""}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.typography}>
                      {f.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.typography}>{`${(
                      f.file.size /
                      1024 /
                      1024
                    ).toFixed(2)}mb`}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.typography}>
                      {f.status}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {typeof f.errors !== "string" ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "100px" }}>Row</TableCell>
                        <TableCell align="left">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {f.errors.map(error => (
                        <TableRow>
                          <TableCell>{error.row}</TableCell>
                          <TableCell>{error.message}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <List>
                    <ListItem>
                      <ListItemText primary={f.errors} />
                    </ListItem>
                  </List>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))} */}
        <TextField
          fullWidth
          inputProps={{
            type: "file",
            multiple: false,
            accept: ".xlsx,.csv,.xls",
            ref: uploaderEl
          }}
          style={{ display: "none" }}
          onChange={handleUpload}
        />
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={() => uploaderEl.current.click()}
              startIcon={<Publish />}
              style={{
                backgroundColor: "#ffb677",
                color: "white",
                borderRadius: 32
              }}
              disabled={pendingFile()}
            >
              ADD FILE
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => submitFiles()}
              disabled={
                files.length === 0 || files[files.length - 1].status !== ""
              }
              variant="contained"
              startIcon={<CloudUpload />}
              color="primary"
              style={{ float: "right", borderRadius: 50 }}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Upload;
