import React from "react";
import useAllowedPermissions from "../../../utils/hooks/useAllowedPermissions";

const Vehicles = props => {
  // const USER = JSON.parse(Cookie.get("user"));
  const { permissions } = useAllowedPermissions("/admin/resources/vehicles/");
  const { vehicles, children } = props;
  
  return (
    <React.Fragment>
      {vehicles.length > 0 &&
        vehicles.map(vehicle => {
          if (vehicle.device_info) {
            const { latitude, longitude } = vehicle.device_info.device_status;
            if (latitude && longitude) {
              return children({ id: vehicle.id, data: vehicle, permissions });
              // return (
              //   <Vehicle
              //     key={vehicle.id}
              //     data={vehicle}
              //     toggleStreetView={toggleStreetView}
              //     history={history}
              //     filterValues={filterValues}
              //     vars={vars}
              //     user={currentLoggedInUser}
              //     toggleHistoryPickerDialog={toggleHistoryPickerDialog}
              //     clusterer={clusterer}
              //     infoBubble={infoBubble}
              //     permissions={permissions}
              //   />
              // );
            }
          }
          return null;
        })}
    </React.Fragment>
  );
};

export default Vehicles;
