import React, { useState } from "react";
import {
  Typography,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon
} from "@material-ui/icons";

const normalizePage = (page, startsWithZero) => {
  if (startsWithZero) {
    return page + 1;
  }

  return page;
};

export default function Pagination(props) {
  const {
    rowsPerPage,
    total,
    onRowsPerPageChange = () => {},
    currentPage = 0,
    onPageChange = () => {},
    onPageUp = () => {},
    onPageDown = () => {},
    startsWithZero = true
  } = props;

  const classes = useStyles();

  const [tempPage, setTempPage] = useState(
    normalizePage(currentPage, startsWithZero)
  );

  React.useEffect(() => {
    setTempPage(normalizePage(currentPage, startsWithZero));
  }, [currentPage]);

  const handleRowsPerPageChanged = event => {
    const newRowsPerPage = event.target.value;
    onRowsPerPageChange(newRowsPerPage);
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9]/g.test(keyValue)) {
      event.preventDefault();
      if (keyCode === 13) {
        if (tempPage >= total) {
          onPageChange(startsWithZero ? total - 1 : total);
        } else if (tempPage <= 0) {
          onPageChange(() => (startsWithZero ? 0 : 1));
        } else {
          onPageChange(startsWithZero ? tempPage - 1 : tempPage);
        }
      }
    }
  };

  const handlePageChange = e => {
    const { value } = e.target;
    setTempPage(+value);
  };

  const handleUpPage = () => {
    const nextPage = currentPage + 1;
    // setTempPage(nextPage);
    onPageUp(nextPage);
  };

  const handleDownPage = () => {
    const nextPage = currentPage - 1;
    // setTempPage(nextPage);
    onPageDown(nextPage);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <Typography variant="subtitle2" className={classes.muiPagination}>Rows per page:</Typography>
      <FormControl>
        <Select
          value={rowsPerPage}
          onChange={handleRowsPerPageChanged}
          displayEmpty
          name="rows_per_page"
          disableUnderline
          className={classes.muiSelectDropdown}
          IconComponent={KeyboardArrowDownOutlinedIcon}
          inputProps={{ style: { padding: "0px 6px !important" } }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        size="small"
        name="down_page"
        disabled={tempPage <= 1}
        onClick={handleDownPage}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <TextField
        name="page"
        readOnly
        onFocus={e => {
          e.target.select();
        }}
        onChange={handlePageChange}
        onKeyPress={handleKeyPress}
        onBlur={() => {
          setTempPage(normalizePage(currentPage, startsWithZero));
        }}
        variant="outlined"
        inputProps={{ style: { padding: 0, textAlign: "center", fontSize: 11 } }}
        value={tempPage}
        className={classes.muiInputPageRow}
      />
      <span className={classes.muiPageRowTotal}>{`/ ${total || 1}`}</span>
      <IconButton
        size="small"
        name="up_page"
        disabled={tempPage === total}
        onClick={handleUpPage}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  muiPagination: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  muiSelectDropdown: {
    color: theme.palette.primary.dark,
    fontSize: 14,
    padding: 0, 
    margin: "0 8px",
  },
  muiInputPageRow: {
    width: 30,
    minWidth: 20,
    maxWidth: 50, 
    marginRight: 8
  },
  muiPageRowTotal: {
    fontSize: 12, 
    color: "#A2A2A2"
  }
}));