import React, { useEffect, useState } from "react";
import NavFleet from "./NavFleet";
import FleetLink from "./Tableau/FleetLink";
import _ from "lodash";

// transfer user to a new tab when dashboard link is clicked. 
// do not close the previous page but open a new tab. 
// clicked dashboard should be displayed.
// minimize and maximize dashboard will not be available on new tab. 
const FleetView = () => {
  const [dashboard, setDashboard] = useState({});
  // get the dashboard information for new tab. 
  // fetch from the local storage. 
  useEffect(() => {
    const localDataDashboard = localStorage.getItem("dashboard");
    setDashboard(JSON.parse(localDataDashboard));
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <NavFleet
        title={_.startCase(_.toLower(dashboard?.title))}
        newTab={true}
      />
      {dashboard?.url && (
        <FleetLink newTab={true} tab={0} linkData={dashboard?.url} />
      )}
    </div>
  );
};

export default FleetView;
