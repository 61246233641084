import { lighten, makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative",
    fontWeight: "600",
    letterSpacing: "1.25px"
  }
}));
