/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Badge,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  FilterList as FilterListIcon,
  Event as EventIcon
} from "@material-ui/icons";
import AccessControl from "../../../../utils/AccessControl";
import Pagination from "../../../../utils/table/Pagination";
import EnhancedTableToolbar from "./EnhancedToolbarandTable/EnhancedToolbar";
import EnhancedTableHead from "./EnhancedToolbarandTable/EnhancedTableHead";
import driverStyles from "./DriverInfo.style";
import { Link } from "react-router-dom";
import { GET_DRIVER_INFOS, GET_USERS } from "../../../../graphql/Queries";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../../utils/Loading";
import PairDriver from "./PairDriver";
import _, { isBoolean } from "lodash";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import {
  EDIT_DRIVER_VEHICLE,
  DELETE_DRIVER_INFO,
  UPDATE_DRIVER_INFO
} from "../../../../graphql/Mutations";
import Swal from "sweetalert2";
import SearchContext from "../../../../context/SearchContext";
import Highlight from "react-highlighter";
import AddLogs from "../../../../utils/functions/AddLogs";
import useUserContext from "../../../../context/User/useUserContext";
import Filter from "../../Filter";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useFormStyles from "../../../../styles/useFormStyles";
import useFilterHauler from "../../../../utils/hooks/useFilterHauler";
import { Autocomplete } from "@material-ui/lab";
import useDebounce from "../../../../utils/hooks/useDebounce";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const DriverInfos = props => {
  const classes = driverStyles();
  const formStyles = useFormStyles();
  const contextData = useContext(SearchContext);
  const user = useUserContext();
  const history = useHistory();

  const [driverInfo, setDriverInfo] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeID");
  const [pairModal, setPairModal] = useState(false);
  const [unpairModal, setUnpairModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteDriver, setDeleteDriver] = useState();
  const [selectedDriver, setSelectedDriver] = useState();
  const [keyword, setKeyword] = useState("");
  const [clearFirst, setClearFirst] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const { setSearchKeyword, searchKeyword } = contextData;

  // add delay for searching
  const DEBOUNCE_TIME = 800;
  const [haulerKeyword, setHaulerKeyword] = useDebounce("", DEBOUNCE_TIME);
  const [supervisorKeyword, setSupervisorKeyword] = useDebounce(
    "",
    DEBOUNCE_TIME
  );

  // for setting filter
  const [filter, setFilter] = useState();
  const [tempFilter, setTempFilter] = useState({
    hauler: { label: "All", id: 0 },
    supervisor: { first_name: "All", last_name: "" },
    pairStatus: "all",
    dateFrom: null,
    dateTo: null
  });

  // for searching clients
  const haulerGroupOptions = useFilterHauler(haulerKeyword);

  // query for dropdown supervisor
  const { data: getUsers } = useQuery(GET_USERS, {
    variables: {
      first: 5,
      keyword: supervisorKeyword,
      condition: [
        {
          field: "user_levels.name",
          value: "Supervisor"
        }
      ]
    }
  });

  const [driverDelete] = useMutation(DELETE_DRIVER_INFO, {
    onCompleted: data => {
      if (data.delete_personnels.success) {
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          onClose: () => {}
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            refetch();
          }
        });
      }
    }
  });

  // mutation for updating driver info
  const [updateDriver] = useMutation(UPDATE_DRIVER_INFO);

  const [editDriverVehicle] = useMutation(EDIT_DRIVER_VEHICLE, {
    onCompleted: data => {
      refetch();
    }
  });

  // values in saved variables
  const temp = history?.location?.state?.params?.vars;
  const getVariables = () => {
    const variables = {
      first: rowsPerPage,
      skip: rowsPerPage * page,
      keyword: keyword,
      condition: [
        {
          field: "personnels.designation",
          value: "driver"
        }
      ]
    };
    // from filter (state) values place in variable
    if (filter?.length > 0) {
      filter.forEach(el => {
        switch (el?.field) {
          case "dateFrom":
            {
              Object.assign(variables, { dateFilter: el.value });
            }
            break;
          case "hauler":
            {
              if (el.value.length)
                variables.condition.push({
                  field: "personnels_groups.group_id",
                  value: el.value
                });
            }
            break;
          case "supervisor":
            {
              if (el.value.trim() !== "All")
                variables.condition.push({
                  field: "personnels.supervisor",
                  value: el.value
                });
            }
            break;
          case "pairStatus":
            {
              if (el.value !== "all")
                Object.assign(variables, {
                  is_paired: el.value === 1 ? true : false
                });
            }
            break;
          default:
            break;
        }
      });
    } else {
      // saved variables from history
      if (temp) {
        Object.keys(temp).forEach(key => {
          // remove null values in object
          if (temp[key] == null) {
            delete temp[key];
          }
          switch (key) {
            case "hauler":
              {
                if (temp?.hauler?.value?.length)
                  // setting the query variable from saved history
                  variables.condition.push({
                    field: "personnels_groups.group_id",
                    value: temp.hauler.value[0].toString()
                  });
              }
              break;
            case "dateFrom":
            case "dateTo":
              {
                if (temp.dateFrom || temp.dateTo)
                  // setting the query variable from saved history
                  Object.assign(variables, {
                    dateFilter: {
                      start: temp.dateFrom,
                      end: temp.dateTo,
                      field: "drivers.license_expiration"
                    }
                  });
              }
              break;
            case "supervisor":
              {
                if (temp.supervisor.first_name !== "All") {
                  // setting the query variable from saved history
                  variables.condition.push({
                    field: "personnels.supervisor",
                    value: `${temp.supervisor.first_name}  ${temp.supervisor.last_name}`
                  });
                }
              }
              break;
            case "pairStatus":
              {
                if (temp.pairStatus !== "all")
                  // setting the query variable from saved history
                  Object.assign(variables, {
                    is_paired: temp.pairStatus === 1 ? true : false
                  });
              }
              break;
            default:
              break;
          }
        });
      }
    }
    return variables;
  };

  const { data: driverData, loading, refetch } = useQuery(GET_DRIVER_INFOS, {
    fetchPolicy: "cache-and-network",
    variables: getVariables(),
    // variables: {
    //   first: rowsPerPage,
    //   skip: rowsPerPage * page,
    //   keyword: keyword,
    //   condition: [
    //     {
    //       field: "personnels.designation",
    //       value: "driver"
    //     }
    //   ]
    // },
    onCompleted: data => {
      if (keyword) {
        AddLogs("Admin - Driver Info", "search", searchKeyword.value);
      }
      setDriverInfo(data.get_personnels?.personnels);

      // set the values of filter field from saved history/query
      if (temp) {
        setTempFilter({
          ...temp,
          dateFrom: temp.dateFrom ? temp.dateFrom : tempFilter.dateFrom,
          dateTo: temp.dateTo ? temp.dateTo : tempFilter.dateTo,
          hauler: temp?.hauler?.value?.length ? temp?.hauler : tempFilter.hauler
        });
      }
    }
  });

  const handleSort = field => {
    if (orderBy == field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const closePairModal = () => {
    setPairModal(false);
    setSelectedDriver("");
  };

  const closeUnpairModal = () => {
    setUnpairModal(false);
    setSelectedDriver("");
  };

  // filter drawer
  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  // clear the value of history
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: process.env.REACT_APP_DRIVER_INFO_MODULE
        }
      }
    });
  };

  // for applying the values of filter drawer
  const handleSetFilters = () => {
    const temp = [];
    let start = "";
    let end = "";
    // setting filter value from filter fields
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        switch (key) {
          case "dateFrom":
            {
              start = tempFilter.dateFrom;
              end = tempFilter.dateTo;
              temp.push({
                field: key,
                value: {
                  start,
                  end,
                  field: "drivers.license_expiration"
                }
              });
            }
            break;
          case "hauler":
            {
              temp.push({
                field: key,
                value: tempFilter?.hauler?.value?.[0]?.toString() || []
              });
            }
            break;
          case "supervisor":
            {
              temp.push({
                field: key,
                value: `${tempFilter.supervisor.first_name}  ${tempFilter.supervisor.last_name}`
              });
            }
            break;
          case "pairStatus":
            {
              temp.push({
                field: key,
                value: tempFilter.pairStatus
              });
            }
            break;
          default:
            break;
        }
        return temp;
      });

    setPage(0);
    setFilter(temp);
    closeFilterDrawer();
    clearHistory();
  };

  // reset value / for clear all button inside filter drawer
  const handleClearFilter = () => {
    setTempFilter({
      hauler: { label: "All", id: 0 },
      supervisor: { first_name: "All", last_name: "" },
      pairStatus: "all",
      dateFrom: null,
      dateTo: null
    });
    setFilter({
      hauler: { label: "All", id: 0 },
      supervisor: { first_name: "All", last_name: "" },
      pairStatus: "all",
      dateFrom: null,
      dateTo: null
    });
  };

  const handleUnpairing = () => {
    updateDriver({
      variables: {
        employeeid: selectedDriver.employeeid,
        id: selectedDriver.id,
        gps_code: "0",
        client_id: +user.client.id,
        driver_info: {
          license_number: selectedDriver.driver_info.license_number,
          status_code_id: 0
        }
      }
    });

    editDriverVehicle({
      variables: {
        id: selectedDriver?.vehicle_info?.id,
        vehicle: {
          personnel_id: null,
          paired: 0,
          gps_code: ""
        }
      },
      onCompleted: data => {
        if (data.edit_vehicle.success) {
          Swal.fire({
            title: "Driver Unpaired",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            onClose: () => {}
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              // refetchVehicle();
              refetch();
            }
          });
        }
      }
    });
    AddLogs("Admin - Driver Info", "unpair", [
      selectedDriver.name,
      selectedDriver.vehicle_info?.plateno
    ]);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteDriver("");
  };

  const handleDelete = () => {
    driverDelete({
      variables: {
        id: deleteDriver.id
      }
    });
    AddLogs("Admin - Driver Info", "delete", deleteDriver?.name);
  };

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      //to not clear the filter when searching
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(0);
    }
  }, [searchKeyword, clearFirst]);

  if (loading) return <Loading />;

  return (
    <AccessControl
      resource="/admin/driver_management/driver_info/"
      process="tables"
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar>
            <IconButton onClick={openFilterDrawer}>
              <Badge
                color="secondary"
                variant={
                  getVariables().condition.length > 1 ||
                  getVariables().dateFilter ||
                  isBoolean(getVariables().is_paired)
                    ? "dot"
                    : "standard"
                }
              >
                <FilterListIcon />
              </Badge>
            </IconButton>
            <Pagination
              rowsPerPage={rowsPerPage}
              total={Math.ceil(driverData?.get_personnels?.total / rowsPerPage)}
              currentPage={page}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                setPage(0);
              }}
              onPageChange={n => setPage(n)}
              onPageUp={n => setPage(n)}
              onPageDown={n => setPage(n)}
            />
          </EnhancedTableToolbar>
        </Paper>
        <TableContainer style={{ backgroundColor: "white" }}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              rowCount={driverData?.get_personnels?.length}
              onRequestSort={handleRequestSort}
              handleSort={handleSort}
            />
            {driverData.get_personnels.count ? (
              <TableBody>
                {driverInfo &&
                  stableSort(driverInfo, getComparator(order, orderBy)).map(
                    row => (
                      <TableRow key={row.id} tabIndex={-1}>
                        <TableCell style={{ padding: "20px 25px" }}>
                          {row.employeeid}
                        </TableCell>
                        <TableCell>
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.name}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.mobileno}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.supervisor}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          {row.vehicle_info?.paired ===
                          +process.env.REACT_APP_STATUS_DRIVER_PAIRED ? (
                            row.vehicle_info?.plateno
                          ) : row.vehicle_info?.paired ===
                            +process.env
                              .REACT_APP_STATUS_DRIVER_GENERATED_CODE ? (
                            <Highlight search={contextData.searchKeyword.value}>
                              <span style={{ color: "#F7AF40" }}>
                                Pairing Code Generated
                              </span>
                            </Highlight>
                          ) : (
                            <Highlight search={contextData.searchKeyword.value}>
                              <span style={{ color: "red" }}>
                                No vehicle paired
                              </span>
                            </Highlight>
                          )}
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ color: "#757575" }}
                            aria-label="Back"
                            to={{
                              // to save the filter values
                              pathname: `/admin/driver_management/driver_info/view/${row.id}`,
                              state: {
                                vars: tempFilter
                              }
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                AddLogs("Admin - Driver Info", "view", row.name)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                          <IconButton
                            onClick={() => {
                              setDeleteDriver(row);
                              setDeleteModal(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (
                                row.vehicle_info?.paired ===
                                +process.env.REACT_APP_STATUS_DRIVER_PAIRED
                              )
                                setUnpairModal(true);
                              else setPairModal(true);
                              setSelectedDriver(row);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 1024 1024"
                              fill="#000000"
                              // {row.plateno ? "#000000" : "none"}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
                                stroke-width="8"
                                stroke="#757575"
                                // {row.plateno ? "#757575" : "#000000"}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow style={{ height: 53 * 2 }}>
                  <TableCell colSpan={6} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Fab size="large" color="primary" className={classes.fab}>
          <Link
            to={{
              // to save the filter values
              pathname: "/admin/driver_management/driver_info/add/",
              state: {
                vars: tempFilter
              }
            }}
          >
            <AddIcon className={classes.fabIcon} />
          </Link>
        </Fab>
        {pairModal && (
          <PairDriver
            toggle={pairModal}
            selectedDriver={selectedDriver}
            setPairModal={setPairModal}
            closeDriverModal={closePairModal}
            refetchPersonnel={refetch}
          />
        )}
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_DRIVER_INFO_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Typography className={classes.license}>
              License Expiration
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // disableFuture
                showTodayButton
                classes={{ root: formStyles.textFieldRoot }}
                openTo="date"
                format="MM/dd/yyyy"
                label="From"
                views={["year", "month", "date"]}
                value={tempFilter.dateFrom}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateFrom: moment(date).format("YYYY-MM-DD")
                  });
                }}
                placeholder="Select Date"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // disableFuture
                showTodayButton
                label="To"
                openTo="date"
                format="MM/dd/yyyy"
                views={["year", "month", "date"]}
                value={tempFilter.dateTo}
                classes={{ root: formStyles.textFieldRoot }}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateTo: moment(date).format("YYYY-MM-DD")
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                placeholder="Select Date"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Autocomplete
              disableClearable
              options={haulerGroupOptions}
              getOptionSelected={(o, v) => o.value === v.value}
              getOptionLabel={option => option.label}
              renderOption={option => option.label}
              value={tempFilter.hauler}
              onChange={(e, nv) => {
                setTempFilter({ ...tempFilter, hauler: nv });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Hauler/Groups"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                  onChange={e => {
                    setHaulerKeyword(e.target.value);
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Autocomplete
              disableClearable
              options={getUsers?.get_users?.users || []}
              getOptionSelected={(o, v) =>
                `${o?.first_name} ${o?.last_name}` ===
                `${o?.first_name} ${o?.last_name}`
              }
              getOptionLabel={option =>
                `${option?.first_name} ${option?.last_name}`
              }
              renderOption={option =>
                `${option?.first_name} ${option?.last_name}`
              }
              value={tempFilter.supervisor}
              onChange={(e, nv) => {
                setTempFilter({ ...tempFilter, supervisor: nv });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Supervisor"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                  onChange={e => {
                    setSupervisorKeyword(e.target.value);
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto", marginBottom: 0 }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>Paired Vehicle Status</InputLabel>
            <Select
              fullWidth
              classes={{ root: formStyles.selectRoot }}
              label="Paired Vehicle Status"
              value={tempFilter.pairStatus}
              inputlabelprops={{
                shrink: true,
                style: { fontWeight: "bold", color: "black" }
              }}
              onChange={e => {
                setTempFilter({ ...tempFilter, pairStatus: e.target.value });
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {[
                { status: 1, value: "Paired" },
                { status: 2, value: "Unpaired" }
              ].map(opt => (
                <MenuItem value={opt.status} key={opt.status}>
                  {opt.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Filter>
        <ConfirmationDialog
          toggle={unpairModal}
          title="Unpair Vehicle"
          content={
            <p>
              Are you sure you want to unpair the driver to vehicle{" "}
              <span style={{ fontWeight: "bold" }}>
                {selectedDriver?.vehicle_info?.plateno}
              </span>
              ?
            </p>
          }
          fn={() => handleUnpairing()}
          close={() => closeUnpairModal()}
        />
        <ConfirmationDialog
          toggle={deleteModal}
          title="Delete Driver"
          content="Are you sure you want to delete this driver?"
          fn={() => handleDelete()}
          close={() => closeDeleteModal()}
        />
      </div>
    </AccessControl>
  );
};

export default DriverInfos;
