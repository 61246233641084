/* eslint-disable no-param-reassign */
import React, { useState, Fragment } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
import _ from "lodash";
// import SearchContext from "../../../../../context/SearchContext";
import {
  EDIT_VEHICLE_TYPES,
  EDIT_FUEL_CALIBRATION
} from "../../../../../graphql/Mutations";
import {
  GET_ADMIN_VEHICLE_TYPE,
  GET_FUEL_CALIBRATION
} from "../../../../../graphql/Queries";

import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../../utils/validation";
import { Helper } from "../../../../Utils";
import AccessControl from "../../../../../utils/AccessControl";
import redirectToTable from "../../../../../utils/redirect";
import ErrorPage from "../../../../../utils/errorPage";
import AddLogs from "../../../../../utils/functions/AddLogs";
import FuelCalibration from "../utils/FuelCalibration";

// const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header, 32 for top + bottom margins
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  paper: {
    boxSizing: "border-box",
    minHeight: "100%",
    height: "auto",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  inputLabel: {
    // marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  },
  customAlert: {
    "& .MuiAlert-icon": {
      color: theme.palette.primary.main
    },
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  }
}));

export default function ViewAlertDescriptions(props) {
  const { history, location } = props;
  const { id } = useParams();
  const { pathname } = location;
  const user = JSON.parse(Cookie.get("user"));
  const classes = useStyles();
  // const contextData = useContext(SearchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [toOpen, setToOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [changes, setChanges] = useState(false);
  const [edit, setEdit] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [state, setState] = useState({
    name: ""
  });
  const [errors, setErrors] = useState({
    code: ""
  });
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });
  const [initialFuelData, setInitialFuelData] = useState([]);
  const [fuelCalib, setFuelCalib] = useState([]);

  React.useMemo(() => {
    if (initialData) {
      if (initialData.groups) {
        if (editMode) {
          if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
            setEditMode(true);
            setEdit(true);
          } else if (user.client.id === initialData.client_id) {
            setEdit(true);
          } else {
            setEditMode(false);
            setEdit(false);
          }
        } else {
          setEdit(false);
        }
      }
    }
  }, [edit, initialData, editMode]);

  const { data: data1 } = useQuery(GET_ADMIN_VEHICLE_TYPE, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(
              history,
              process.env.REACT_APP_VEHICLE_TYPES_MODULE
            );
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(
              history,
              process.env.REACT_APP_VEHICLE_TYPES_MODULE
            );
          }
        });
      }
    }
  });

  const { data: fuelCalibration } = useQuery(GET_FUEL_CALIBRATION, {
    fetchPolicy: "network-only",
    variables: { vehicleModel_id: parseInt(id, 10) }
  });

  const [editFuelCalibration] = useMutation(EDIT_FUEL_CALIBRATION, {
    onCompleted: res => {
      if (res.edit_fuel_calibration.success) {
        Swal.fire({
          icon: "success",
          // title: "Success",
          html: `Fuel Calibration was saved <br>successfully`,
          timer: 3000,
          showConfirmButton: false
        });
        setInitialFuelData(fuelCalib);
      }
    }
  });

  React.useEffect(() => {
    setFuelCalib(fuelCalibration?.get_fuel_calibration?.results);
    setInitialFuelData(fuelCalibration?.get_fuel_calibration?.results);
  }, [fuelCalibration]);

  React.useEffect(() => {
    setFuelCalib(_.cloneDeep(fuelCalibration?.get_fuel_calibration?.results));
    setInitialFuelData(
      _.cloneDeep(fuelCalibration?.get_fuel_calibration?.results)
    );
  }, [fuelCalibration]);

  React.useEffect(() => {
    if (data1) {
      setInitialData(data1.get_vehicle_type);
      setState(data1.get_vehicle_type);
      setIsLoading(false);
    }
  }, [data1, discard]);
  const handleAddValues = e => {
    let errorMessage;
    if (e.target.name === "code") {
      if (e.target.value.length === 0) {
        errorMessage = validate("alphanumeric", e.target.value) ? (
          ""
        ) : (
          <Helper message="This field is required" />
        );
      } else {
        errorMessage = validate("alphanumeric", e.target.value) ? (
          ""
        ) : (
          <Helper message="Alphanumeric characters only" />
        );
      }
    }

    setErrors({
      ...errors,
      [e.target.name]: errorMessage
    });

    if (
      _.isEqual(e.target.name, "capacity_volume") ||
      _.isEqual(e.target.name, "capacity_weight") ||
      _.isEqual(e.target.name, "number_of_wheels")
    ) {
      setState({
        ...state,
        [e.target.name]: _.toNumber(e.target.value)
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSave = async () => {
    const temp = fuelCalib.filter((d, i) => {
      if (typeof d.liters === "string") {
        d.liters = parseFloat(d.liters);
      }
      // if(d.liters === null && d.voltage === null) fuelCalib.splice(i, 1);
      if (d.liters === null && d.voltage === null) return false;
      if (d.liters !== null && d.voltage === null) d.voltage = 0;
      if (d.liters === null && d.voltage !== null) d.liters = 0;
      return d;
    });
    await setFuelCalib([...temp]);
    editFuelCalibration({
      variables: {
        vehicleModel_id: parseInt(id, 10),
        calibrations: temp
      }
    });
    setErrors([]);
  };

  const [editVehicleType] = useMutation(EDIT_VEHICLE_TYPES, {
    onCompleted: res => {
      setDisableSubmit(false);
      setIsLoading(false);
      if (res.edit_vehicle_type.success) {
        AddLogs("Admin - Vehicle Types", "update", state.name);
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Edit successful",
          timer: 1500,
          showConfirmButton: false,
          onClose: () => {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_VEHICLE_TYPES_MODULE
                }
              }
            });
          }
        });
      } else {
        setErrors({
          name: <Helper message="Vehicle Type already exists" />
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      setIsLoading(false);
    }
  });

  const handleCloseDialog = dialog_name => {
    setToOpen(false);
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const discarded = () => {
    handleCloseDialog("discard");
  };

  const validateType = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^a-zA-Z0-9 ]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const validateNumber = event => {
    const regExp = /^\d{5}$/;

    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9.]/g.test(keyValue)) {
      event.preventDefault();
    } else if (event.target.value.match(regExp)) {
      event.preventDefault();
      event.target.value += ".";
    } else {
      const temp = event.target.value;
      if (/[.]/g.test(temp)) {
        if (event.target.value.match(/^[0-9]*\.[0-9][0-9]$/)) {
          event.preventDefault();
        }
      }
    }
  };

  const saveEdit = () => {
    editVehicleType({
      variables: {
        id: id,
        vehicle_type: {
          name: state.name ? state.name : "",
          capacity_weight: state.capacity_weight
            ? Number(state.capacity_weight)
            : 0,
          capacity_weight_unit: state.capacity_weight_unit
            ? state.capacity_weight_unit
            : "",
          capacity_volume: state.capacity_volume
            ? Number(state.capacity_volume)
            : 0,
          capacity_volume_unit: state.capacity_volume_unit
            ? state.capacity_volume_unit
            : "",
          remarks: state.remarks ? state.remarks : "",
          groups: state.groups,
          client_id: Number(user.client.id),
          number_of_wheels: state.number_of_wheels
            ? Number(state.number_of_wheels)
            : 0
        }
      }
    });
    setErrors([]);
    setDisableSubmit(false);
  };

  const handleOpen = () => {
    setToOpen(true);
  };

  const checkChanges = () => {
    return (
      !_.isEqual(initialData, state) || !_.isEqual(initialFuelData, fuelCalib)
    );
    // return Object.keys(data1.get_alert_description)
    //   .filter(element => element in state)
    //   .some(element => state[element] !== data1.get_alert_description[element]);
  };

  const toggleEditMode = event => {
    // if(edit) {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpen("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
    // }
  };

  if (isLoading) return <Loading />;

  if (initialData.name === null) {
    return <ErrorPage />;
  }
  return (
    <AccessControl
      resource={pathname}
      setEditPermission={setEditPermission}
      process="view"
    >
      <div className={classes.root}>
        <Header
          process={editMode ? "Edit" : "View"}
          // process={!_.isEqual(initialData, state) ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_VEHICLE_TYPES_MODULE}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={
            _.isEqual(initialData, state) &&
            _.isEqual(initialFuelData, fuelCalib)
          }
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
                <br />
                <Grid container spacing={3}>
                  <Fragment>
                    <Grid item xs={3}>
                      <List key={state.name}>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={
                              <b>
                                Vehicle Type&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            }
                            secondary={
                              edit ? (
                                <TextField
                                  fullWidth
                                  id="name"
                                  placeholder="Enter vehicle type"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  autoFocus
                                  value={state.name}
                                  name="name"
                                  onKeyPress={validateType}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                  helperText={errors.name ? errors.name : ""}
                                />
                              ) : (
                                state.name
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Volume Capacity</b>}
                            secondary={
                              edit ? (
                                <TextField
                                  fullWidth
                                  id="capacity_volume"
                                  placeholder="0"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={state.capacity_volume}
                                  name="capacity_volume"
                                  onKeyPress={validateNumber}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                />
                              ) : (
                                state.capacity_volume || 0
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Volume Capacity</b>}
                            secondary={
                              edit ? (
                                <TextField
                                  fullWidth
                                  id="capacity_weight"
                                  placeholder="0"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={state.capacity_weight}
                                  name="capacity_weight"
                                  onKeyPress={validateNumber}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                />
                              ) : (
                                state.capacity_weight || 0
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Remarks</b>}
                            secondary={
                              edit ? (
                                <TextField
                                  fullWidth
                                  multiline
                                  minRows={4}
                                  variant="outlined"
                                  placeholder="Type Here..."
                                  name="remarks"
                                  id="remarks"
                                  value={state.remarks || ""}
                                  onKeyPress={validateType}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                />
                              ) : (
                                state.remarks
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3}>
                      <List key={state.name}>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Number of Wheels</b>}
                            secondary={
                              edit ? (
                                <Select
                                  value={state.number_of_wheels || "0"}
                                  onChange={e => handleAddValues(e)}
                                  displayEmpty
                                  name="number_of_wheels"
                                  fullWidth
                                >
                                  <MenuItem value="0">0</MenuItem>
                                  <MenuItem value="2">2</MenuItem>
                                  <MenuItem value="3">3</MenuItem>
                                  <MenuItem value="4">4</MenuItem>
                                  <MenuItem value="6">6</MenuItem>
                                  <MenuItem value="8">8</MenuItem>
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="12">12</MenuItem>
                                  <MenuItem value="14">14</MenuItem>
                                  <MenuItem value="16">16</MenuItem>
                                  <MenuItem value="18">18</MenuItem>
                                  <MenuItem value="20">20</MenuItem>
                                  <MenuItem value="24">24</MenuItem>
                                  <MenuItem value="26">26</MenuItem>
                                  <MenuItem value="28">28</MenuItem>
                                  <MenuItem value="34">34</MenuItem>
                                </Select>
                              ) : (
                                state.number_of_wheels || 0
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Unit of Volume</b>}
                            secondary={
                              edit ? (
                                <Select
                                  value={state.capacity_volume_unit || "CBM"}
                                  onChange={e => handleAddValues(e)}
                                  displayEmpty
                                  name="capacity_volume_unit"
                                  fullWidth
                                >
                                  <MenuItem value="CBM">CBM</MenuItem>
                                  <MenuItem value="Liters">Liters</MenuItem>
                                </Select>
                              ) : (
                                state.capacity_volume_unit || ""
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={edit}
                            primary={<b>Unit of Weight</b>}
                            secondary={
                              edit ? (
                                <Select
                                  value={state.capacity_weight_unit || "Kg"}
                                  onChange={e => handleAddValues(e)}
                                  displayEmpty
                                  name="capacity_weight_unit"
                                  fullWidth
                                >
                                  <MenuItem value="Kg">Kg</MenuItem>
                                  <MenuItem value="Tons">Tons</MenuItem>
                                </Select>
                              ) : (
                                state.capacity_weight_unit || ""
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FuelCalibration
                        edit={edit}
                        fuelCalib={fuelCalib}
                        setFuelCalib={setFuelCalib}
                        handleSave={handleSave}
                      />
                      {errors.fuel_calib && (
                        <Alert
                          severity="error"
                          // style={{ color: "yellow" }}
                          className={classes.customAlert}
                        >
                          {errors.fuel_calib}
                        </Alert>
                      )}
                    </Grid>
                  </Fragment>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                {errors.general && (
                  <Alert
                    severity="error"
                    style={{ justifyContent: "center" }}
                    className={classes.alert}
                  >
                    {errors.general}
                  </Alert>
                )}
                {edit ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    fullWidth
                    style={{
                      margin: 20,
                      maxWidth: "30%",
                      marginTop: 10,
                      borderRadius: "17px"
                    }}
                    onClick={() => {
                      let temp = {};
                      if (state.name === undefined || state.name === "") {
                        temp = {
                          ...temp,
                          name: <Helper message="This field is required" />
                        };
                      } else if (!_.isEqual(fuelCalib, initialFuelData)) {
                        temp = {
                          ...temp,
                          fuel_calib: <b>Please save the fuel calibration</b>
                        };
                      } else if (!_.isEqual(state, initialData)) {
                        setDialog({ ...dialog, save: true });
                      } else {
                        setState(initialData);
                        setEditMode(false);
                        // Swal.fire({
                        //   title: "No Changes have been made",
                        //   icon: "success",
                        //   showConfirmButton: false,
                        //   timer: 10000,
                        //   onClose: () => {
                        //     setState(initialData);
                        //     setEditMode(false);
                        //   }
                        // })
                      }

                      setErrors(temp);
                      return null;
                    }}
                  >
                    Save Details
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            </div>
          </Paper>
          {/* </form> */}
          <ConfirmationDialog
            toggle={dialog.save}
            close={() => handleCloseDialog("save")}
            fn={() => saveEdit()}
            title="Save Changes?"
            content="Are you sure you want to save this changes?"
          />

          <ConfirmationDialog
            toggle={dialog.discard}
            close={() => handleCloseDialog("discard")}
            fn={() => discarded()}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />

          <ConfirmationDialog
            toggle={toOpen}
            close={() => setToOpen(false)}
            fn={() => {
              setState(initialData);
              setFuelCalib(initialFuelData);
              setEditMode(false);
              setErrors([]);
            }}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />
      </div>
    </AccessControl>
  );
}
