/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
// import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  IconButton,
  Grid,
  Paper,
  Typography,
  Badge,
  useTheme
} from "@material-ui/core";
import {
  CheckCircleOutline,
  Cancel,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FilterList
} from "@material-ui/icons";
import moment from "moment";
import Swal from "sweetalert2";
import _, { initial } from "lodash";
import HistoryDetails from "./HistoryDetails";
import {
  GET_CHECKLIST_HISTORY,
  GET_CHECKLIST_VEHICLE_INFO
} from "../../../graphql/Queries";
import Loading from "../../../utils/Loading";
import FilterDialog from "./FilterDialog";
import AccessControl from "../../../utils/AccessControl";
import useHistoryStyles from "./History.styles";

const Checklist360History = () => {
  const classes = useHistoryStyles();
  const theme = useTheme();
  // const { id, } = useParams();
  const [historyDetails, setHistoryDetails] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [page, setPage] = useState(1);
  const [resolveChecklist, setResolveChecklist] = useState();
  const [date, setDate] = useState({
    start: "",
    end: ""
  });
  const [initialDate, setInitialDate] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams?.get("vehicle_id")
    ? JSON.parse(queryParams.get("vehicle_id"))
    : "";

  const {
    data: vehicleData,
    loading: vehicleLoading,
    refetch: refetchVehicleData
  } = useQuery(GET_CHECKLIST_VEHICLE_INFO, {
    variables: { id: id },
    skip: !id,
    onCompleted: res => {
      const endDate = res?.get_vehicle?.last_360_check
        ? `${moment(res.get_vehicle.last_360_check).format(
            "YYYY-MM-DD"
          )} 23:59:59`
        : `${moment().format("YYYY-MM-DD")} 23:59:59`;
      const startDate = `${moment(endDate)
        .subtract(30, "days")
        .format("YYYY-MM-DD")} 00:00:00`;
      setDate({
        start: startDate,
        end: endDate
      });
      setInitialDate({
        start: startDate,
        end: endDate
      });
    }
  });

  const vars = {
    first: 6,
    skip: (page - 1) * 6,
    dateRange: {
      start: date.start,
      end: date.end,
      field: "datestamp"
    },
    condition: [
      {
        field: "vehicle_id",
        value: id?.toString()
      }
    ],
    orderBy: [
      {
        field: "datestamp",
        direction: "desc"
      }
    ]
  };

  const { data, loading, refetch: refetchChecklistHistory } = useQuery(
    GET_CHECKLIST_HISTORY,
    {
      variables: vars,
      skip: !date.start || !id
    }
  );

  const getHistory = () => {
    try {
      if (data?.get_checklists_history?.checklists360.length) {
        const cardArray = data.get_checklists_history.checklists360.map(
          (checklist, index) => {
            try {
              const tempChecklist = JSON.parse(checklist.checklists).map(
                categoryInfo => {
                  return {
                    ...categoryInfo,
                    forRepair: categoryInfo.parts.some(
                      element => element.status.toLowerCase() === "defective"
                    )
                  };
                }
              );
              if (
                // vehicleData.get_vehicle.is_for_repair &&
                // index === 0 &&
                // tempChecklist.some(element => element.forRepair) &&
                !resolveChecklist ||
                moment(resolveChecklist.datestamp).isBefore(
                  moment(checklist.datestamp)
                )
              ) {
                // Save latest checklist in history, to identify which history should be resolved
                setResolveChecklist({
                  ...checklist,
                  checklists: tempChecklist,
                  withDefective: tempChecklist.some(
                    element => element.forRepair
                  )
                });
              }
              return {
                ...checklist,
                checklists: tempChecklist,
                withDefective:
                  index === 0 &&
                  tempChecklist.some(element => element.forRepair)
              };
            } catch (error) {
            console.log(error); // eslint-disable-line
            }
          }
        );

        return cardArray.map(list => {
          const checkStatus = category => {
            const categoryInfo = list.checklists.find(
              c =>
                c.category_name
                  .split(" ")
                  .join("")
                  .toLowerCase() === category
            );
            if (!categoryInfo.forRepair) {
              return <CheckCircleOutline className={classes.iconCheck} />;
            }
            return <Cancel className={classes.iconCancel} />;
          };
          return (
            <Grid item sm={4} onClick={() => setHistoryDetails(list)}>
              <Paper
                className={
                  vehicleData.get_vehicle.is_for_repair &&
                  resolveChecklist?.id === list?.id
                    ? classes.cardForRepair
                    : classes.card
                }
              >
                <button
                  className={classes.cardButtonDate}
                  type="button"
                  style={
                    resolveChecklist?.id === list?.id &&
                    resolveChecklist?.withDefective
                      ? {
                          backgroundColor: theme.palette.primary.main
                        }
                      : {
                          backgroundColor: "#D1D1D1"
                        }
                  }
                >
                  {list?.datestamp
                    ? moment(list.datestamp).format("LL HH:mm:ss")
                    : "No Date"}
                </button>
                <Typography className={classes.cardCaption}>
                  Date Checked
                </Typography>

                <div className={classes.cardContent}>
                  <Typography className={classes.cardContentLabel}>
                    Lights
                  </Typography>
                  {checkStatus("lights")}
                </div>
                <div className={classes.cardContent}>
                  <Typography className={classes.cardContentLabel}>
                    Glass and Wipers
                  </Typography>
                  {checkStatus("glassandwipers")}
                </div>
                <div className={classes.cardContent}>
                  <Typography className={classes.cardContentLabel}>
                    Tires
                  </Typography>
                  {checkStatus("tires")}
                </div>
                <div className={classes.cardContent}>
                  <Typography className={classes.cardContentLabel}>
                    Emergency Kit
                  </Typography>
                  {checkStatus("emergencykit")}
                </div>
                <div className={classes.cardContent}>
                  <Typography className={classes.cardContentLabel}>
                    Fluids
                  </Typography>
                  {checkStatus("fluids")}
                </div>

                <button
                  className={classes.cardButtonPersonnel}
                  type="button"
                  style={
                    resolveChecklist?.id === list?.id &&
                    resolveChecklist?.withDefective
                      ? {
                          backgroundColor: theme.palette.primary.main,
                          opacity: "0.75"
                        }
                      : {
                          backgroundColor: "#D1D1D1",
                          opacity: 1
                        }
                  }
                >
                  {list?.driver_name || list?.user_name}
                </button>
                <Typography className={classes.cardCaption}>
                  Personnel
                </Typography>
              </Paper>
            </Grid>
          );
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  };

  const getVehicleInfo = () => {
    if (vehicleData?.get_vehicle) {
      const vehicleInfo = vehicleData.get_vehicle;
      const fields = [
        {
          label: "Plate Number",
          value: vehicleInfo.plateno
        },
        {
          label: "Vehicle Type",
          value: vehicleInfo.vehicle_type
        },
        {
          label: "Hauler",
          value: vehicleInfo.client_name
        },
        {
          label: "Current Odometer Reading",
          value: `${vehicleInfo.device_info?.device_status?.distancetraveled /
            1000 || 0} km`
        },
        {
          label: "Last Repair Date",
          value: vehicleInfo.last_repair_date
            ? moment(vehicleInfo.last_repair_date).format("ll")
            : "-"
        },
        {
          label: "Status",
          value: vehicleInfo.is_for_repair ? "FOR REPAIR" : "RESOLVED"
        }
      ];

      return fields.map(field => (
        <>
          <Typography className={classes.paperLabel}>{field.label}</Typography>
          <Typography className={classes.paperLabelValue}>
            {field.value}
          </Typography>
        </>
      ));
    }
  };

  const handleDateFilter = dateRange => {
    setDate({
      start: `${dateRange.start} 00:00:00`,
      end: `${dateRange.end} 23:59:59`
    });
    setPage(1);
  };

  if (loading || vehicleLoading) return <Loading />;

  const totalPage = data?.get_checklists_history
    ? Math.ceil(data.get_checklists_history.total / 6)
    : 1;

  return (
    <AccessControl resource="/pms/360_checklist" process="tables">
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between"
          }}
        >
          <Typography variant="h5" className={classes.title}>
            360 Checklist History
          </Typography>
          {!historyDetails && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="filter list"
                onClick={() => setOpenFilterModal(true)}
              >
                <Badge
                  color="secondary"
                  variant={_.isEqual(initialDate, date) ? "standard" : "dot"}
                >
                  <FilterList />
                </Badge>
              </IconButton>

              <IconButton
                name="down_page"
                disabled={Number(page) <= 1}
                onClick={() => setPage(page - 1)}
                className={classes.page}
              >
                <KeyboardArrowLeft fontSize="large" />
              </IconButton>
              <Typography style={{ fontSize: "calc(0.8em + 0.5vh)" }}>
                {`${page} / ${totalPage}`}
              </Typography>
              <IconButton
                name="up_page"
                disabled={Number(page) >= totalPage}
                onClick={() => setPage(page + 1)}
                className={classes.page}
              >
                <KeyboardArrowRight fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
        <div>
          <Grid container>
            <Grid item sm={3} alignItems="stretch">
              <Paper className={classes.paper}>
                <Typography className={classes.paperTitle}>
                  Vehicle Details
                </Typography>
                {getVehicleInfo()}
              </Paper>
            </Grid>
            <Grid item sm={9} alignItems="stretch">
              <Grid container spacing={2}>
                {historyDetails ? (
                  <HistoryDetails
                    checklistInfo={historyDetails}
                    refetch={() => {
                      setHistoryDetails();
                      refetchChecklistHistory(vars);
                      refetchVehicleData();
                    }}
                    showHistoryList={() => setHistoryDetails()}
                    toResolve={
                      historyDetails.withDefective &&
                      resolveChecklist?.id === historyDetails.id
                    }
                  />
                ) : (
                  getHistory()
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <FilterDialog
          toggle={openFilterModal}
          close={() => setOpenFilterModal(false)}
          fn={e => handleDateFilter(e)}
          value={date}
        />
      </div>
    </AccessControl>
  );
};

export default Checklist360History;
