/* global google */
/* eslint-disable no-case-declarations */
import React, { useRef, createRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { DrawingManager } from "@react-google-maps/api";
import {
  Paper,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { DoubleArrow as DoubleArrowIcon } from "@material-ui/icons";
import { getGeofenceTypeColor } from "../../../../../../Utils";
import {
  getBounds,
  polygonToMultiPolygon,
  stdGeojsonToWkt
} from "../../../../../../../utils/functions/coordinatesParserV2";
import { GET_INTERSECT_UNIVERSAL_GEOFENCE } from "../../../../../../../graphql/Queries";
import BasicModal from "../../../../../../../utils/modal/BasicModal";
import SearchBox from "./SearchBox";
import CustomDrawing from "./CustomDrawing";

const drawRef = createRef();

const CustomOverlays = props => {
  const {
    expanded,
    setExpanded,
    mapState,
    setMapState,
    state,
    setState,
    univGeofences
  } = props;
  const { drawingMode, opacity } = mapState;
  const geofenceColor = getGeofenceTypeColor(state.category);
  const univGeoModalRef = useRef();
  const modalRef = useRef();

  const [getIntersectUnivGeo, { data: univGeoData }] = useLazyQuery(
    GET_INTERSECT_UNIVERSAL_GEOFENCE,
    {
      onCompleted: res => {
        if (res.get_intersect_universal_geofence.count) {
          univGeoModalRef.current.openModal();
        } else if (drawRef.current) {
          drawRef.current.overlay.setMap(null);
          drawRef.current = null;
        }
      }
    }
  );

  const handleGeofence = e => {
    const { type: drawingType, overlay } = e;
    let drawingCoords = [];
    let lat;
    let lon;
    let area;

    if (drawRef.current) {
      drawRef.current.overlay.setMap(null);
    }
    drawRef.current = e;

    switch (drawingType) {
      case "polygon":
        const boundsPolygon = new google.maps.LatLngBounds();
        const polygonCoords = overlay.getPath().getArray();

        drawingCoords = polygonCoords.map(point => {
          boundsPolygon.extend({ lat: point.lat(), lng: point.lng() });
          return [point.lng(), point.lat()];
        });

        lat = boundsPolygon?.getCenter()?.lat();
        lon = boundsPolygon?.getCenter()?.lng();
        area = google.maps.geometry.spherical.computeArea(boundsPolygon);

        // Duplicate the last point to close the geojson ring
        drawingCoords.push(drawingCoords[0]);

        break;
      case "rectangle":
        const boundsRectangle = overlay.getBounds();
        const NE = boundsRectangle.getNorthEast();
        const SW = boundsRectangle.getSouthWest();

        drawingCoords.push([NE.lng(), SW.lat()]);
        drawingCoords.push([
          boundsRectangle.getNorthEast().lng(),
          boundsRectangle.getNorthEast().lat()
        ]);
        drawingCoords.push([SW.lng(), NE.lat()]);
        drawingCoords.push([
          boundsRectangle.getSouthWest().lng(),
          boundsRectangle.getSouthWest().lat()
        ]);
        drawingCoords.push([NE.lng(), SW.lat()]);

        lat = boundsRectangle.getCenter().lat();
        lon = boundsRectangle.getCenter().lng();
        area = google.maps.geometry.spherical.computeArea(boundsRectangle);

        break;
      case "circle":
        lat = overlay.getCenter().lat();
        lon = overlay.getCenter().lng();
        area = google.maps.geometry.spherical.computeArea(overlay.getBounds());

        const numSides = 48;
        const degreeStep = 360 / numSides;

        for (let i = 0; i < numSides; i += 1) {
          const gpos = google.maps.geometry.spherical.computeOffset(
            new google.maps.LatLng(lat, lon),
            overlay.getRadius(),
            degreeStep * i
          );
          drawingCoords.push([gpos.lng(), gpos.lat()]);
        }

        // Duplicate the last point to close the geojson ring
        drawingCoords.push(drawingCoords[0]);
        break;
      default:
        break;
    }

    const polygonGeom = { type: "polygon", coordinates: [drawingCoords] };
    const wktgeom = stdGeojsonToWkt(polygonToMultiPolygon(polygonGeom));

    setState(prev => ({
      ...prev,
      location: { lat, lon }
    }));
    setMapState(prev => ({
      ...prev,
      geom: wktgeom,
      radius: drawingType === "circle" ? overlay.getRadius() : 0,
      area: area,
      geofence_geom_id: null
    }));

    // if (drawRef.current) {
    //   drawRef.current.overlay.setMap(null);
    // }

    // check if the multipolygon has intersected other universal geofence.
    getIntersectUnivGeo({
      variables: {
        centroid: `POINT(${lat} ${lon})`,
        geom_type: drawingType === "circle" ? "circle" : "multipolygon",
        geom: wktgeom,
        intersect_percent: 60
      }
    });
  };
  return (
    <div style={{ width: "80%" }}>
      <div style={{ display: "flex" }}>
        <DrawingManager
          defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
          options={{
            drawingControl: false,
            circleOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity,
              strokeWeight: 2
            },
            polygonOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity,
              strokeWeight: 2
            },
            rectangleOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity,
              strokeWeight: 2
            }
          }}
          drawingMode={drawingMode === "select" ? null : drawingMode}
          onOverlayComplete={e => handleGeofence(e)}
        />
        {expanded && (
          <div style={{ marginLeft: 16, marginTop: 16 }}>
            <Paper>
              <Grid container>
                <Grid item xs={12} style={{ padding: 16 }}>
                  <SearchBox
                    state={state}
                    setState={setState}
                    mapState={mapState}
                    setMapState={setMapState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomDrawing
                    state={state}
                    mapState={mapState}
                    setMapState={setMapState}
                    univGeofences={univGeofences}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
        <div>
          <IconButton
            style={{ backgroundColor: "white", margin: 16 }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <DoubleArrowIcon
              style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
            />
          </IconButton>
        </div>
      </div>
      <BasicModal ref={modalRef}>
        <div>
          <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Select Geofence</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to select geofence? Your drawn shape will
                be remove?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  borderRadius: 100,
                  width: 150,
                  borderColor: "#f44336",
                  color: "#f44336"
                }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  modalRef.current.closeModal();
                }}
              >
                No
              </Button>
              <Button
                style={{ borderRadius: 100, color: "white", width: 150 }}
                variant="contained"
                onClick={() => {
                  drawRef.current.overlay.setMap(null);
                  drawRef.current = null;
                  const {
                    location: univLocation,
                    geom: univGeom,
                    geofence_geom_id: geomId
                  } = univGeoData.get_intersect_universal_geofence.universal_geofences[0];
                  setState(prev => ({
                    ...prev,
                    location: {
                      lat: univLocation.lat,
                      lon: univLocation.lon
                    }
                  }));

                  setMapState(prev => ({
                    ...prev,
                    geom: univGeom,
                    geofence_geom_id: geomId,
                    area: google.maps.geometry.spherical.computeArea(
                      getBounds(univGeom)
                    )
                  }));
                  modalRef.current.closeModal();
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </BasicModal>
      <BasicModal ref={univGeoModalRef}>
        <div>
          <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Intersecting Geofence
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your created geofence overlaps with an existing universal
                geofence. Would you like to select the universal goefence?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  borderRadius: 100,
                  width: 150,
                  borderColor: "#f44336",
                  color: "#f44336"
                }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  univGeoModalRef.current.closeModal();
                }}
              >
                No
              </Button>
              <Button
                style={{ borderRadius: 100, color: "white", width: 150 }}
                variant="contained"
                onClick={() => {
                  univGeoModalRef.current.closeModal();
                  modalRef.current.openModal();
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </BasicModal>
    </div>
  );
};

export default CustomOverlays;
