/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Cookie from "js-cookie";
import moment from "moment";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  Badge,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  FormControl,
  Select,
  IconButton,
  TextField,
  Tooltip,
  InputLabel,
  TableContainer,
  Table,
  TableBody
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  FilterList as FilterListIcon,
  Event as EventIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import _ from "lodash";
import Swal from "sweetalert2";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../../../utils/Loading";
import Filter from "../../Filter";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../../../utils/table/Pagination";
import useFormStyles from "../../../../styles/useFormStyles";
import ClientAutocomplete from "../../../../utils/ClientAutocomplete";
import useFilterHauler from "../../../../utils/hooks/useFilterHauler";
import useDebounce from "../../../../utils/hooks/useDebounce";

import {
  GET_ACTIVITY_LOGS,
  GET_ADMIN_CLIENTS,
  GET_USERS
} from "../../../../graphql/Queries";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "datestamp",
    numeric: false,
    disablePadding: false,
    label: "Created Date"
  },
  { id: "activity", numeric: false, disablePadding: false, label: "Activity" },
  { id: "module", numeric: false, disablePadding: false, label: "Module" },
  {
    id: "remote_address",
    numeric: true,
    disablePadding: false,
    label: "Remote Address"
  },
  { id: "username", numeric: false, disablePadding: false, label: "User" }
];

function TableHeadCells(props) {
  const { classes, order, orderBy, handleSort } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000",
    display: "flex",
    alignItems: "center"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

export default function ActivityLogs() {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const user = JSON.parse(Cookie.get("user"));
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [activityData, setActivityData] = useState([]); // set initial data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(0);
  const [filterDrawer, setFilterDrawer] = useState(false); // set to open filter drawer
  const [alertKeyword, setAlertKeyword] = useState(""); // set to user search
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datestamp");
  const [filter, setFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [clearFirst, setClearFirst] = useState(false);
  const userLevelId = user.user_level_id;
  // const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const DEBOUNCE_TIME = 800;
  const [haulerKeyword, setHaulerKeyword] = useDebounce("", DEBOUNCE_TIME);
  const [tempFilter, setTempFilter] = useState({
    dateRange: {
      gte: null,
      lte: null,
      field: "datestamp"
    },
    hauler: {
      label: user.group_names[0],
      value: user.group_ids
    },
    user: "All",
    module: "All"
  });
  const [disableButton, setDisableButton] = useState(false);
  const [filterValue, setFilterValue] = useState({
    dateRange: {
      gte: null,
      lte: null,
      field: "datestamp"
    },
    user: "All",
    module: "All"
  });
  const [client, setClient] = useState([]);
  const defaultGroupFilter = {
    id: user.group_ids[0],
    name: user.group_names[0]
  };
  const [groupFilter, setGroupFilter] = useState(defaultGroupFilter);
  const [filterGroupId, setFilterGroupId] = useState([user.group_ids[0]]);
  const [users, setUsers] = useState([]);

  const { setSearchKeyword, searchKeyword } = contextData;
  const haulerGroupOptions = useFilterHauler(haulerKeyword)
    .filter(hauler => hauler.label !== "All");
  // const haulerGroupOptions = useFilterHauler(haulerKeyword)

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setAlertKeyword(searchKeyword.value);
      setPage(0);
    }
  }, [searchKeyword, clearFirst, canDelete]);

  const getVariables = () => {
    const condition = [];

    // try {
    //   filterGroupId.map(id => {
    //     return condition.push({ field: "group_ids", value: id.toString() });
    //   });
    // } catch (error) {
    //   Swal.fire({
    //     title: "Something went wrong",
    //     icon: "error",
    //     showConfirmButton: false,
    //     timer: 3000
    //   });
    // }

    if(filterValue?.hauler){
      condition.push({ field: "group_ids", value: filterValue?.hauler?.value.toString() });
    } else condition.push({ field: "group_ids", value: user?.group_ids[0].toString() });

    const variables = {
      skip: rowsPerPage * page,
      keyword: alertKeyword,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }],
      condition: condition,
      // filter: filters,
      dateFilter: {}
    };

    if (filter.length > 0) {
      filter.map(row => {
        if (row.field === "dateRange" && row.value.gte) {
          return Object.assign(variables, {
            dateFilter: {
              field: row.value.field,
              gte: moment(row.value.gte)
                .subtract(8, "hours")
                .format("YYYY-MM-DD HH:mm"),
              lte: moment(row.value.lte)
                .subtract(8, "hours")
                .format("YYYY-MM-DD HH:mm")
            }
          });
        }
        if (row.field === "module" && row.value !== "All") {
          condition.push({ field: "module.keyword", value: row.value });
          // return Object.assign(variables, {
          //   condition: {field: "module.keyword", value: row.value}
          // });
        } else if (row.field === "user" && row?.value?.id) {
          condition.push({ field: "user.username", value: row.value.id });
        }
        return condition;
      });
    }
    return variables;
  };

  // useEffect(() => {
  //   if (groupFilter?.group_ids) {
  //     const groupIdx = _.findIndex(
  //       groupFilter.group_names,
  //       gn => gn === groupFilter.name
  //     );
  //     setLoading(true);
  //     // setFilterGroupId(groupFilter?.group_ids);
  //     setFilterGroupId([groupFilter.group_ids[groupIdx]]);
  //     setSearchKeyword(prev => ({ ...prev, group: groupFilter?.group_ids }));
  //   }
  // }, [groupFilter]);

  const {
    data: currentData,
    loading: loadingActivity,
    refetch,
    fetchMore
  } = useQuery(GET_ACTIVITY_LOGS, {
    variables: getVariables(),
    onCompleted: () => {
      if (searchKeyword.value) {
        AddLogs("Admin - Activity Logs", "search", `${searchKeyword.value}`);
      }
      setDisableButton(false);
      setLoading(false);
      // setPage(1);
      setSelected([]);
    },
    fetchPolicy: "cache-and-network"
  });

  const [get_users] = useLazyQuery(GET_USERS, {
    onCompleted: result => {
      if (result) {
        const temp = [];
        result.get_users.users.map(res => {
          return temp.push({ id: res.username, name: res.username });
        });
        setUsers(temp);
      }
    },
    variables: {
      first: 10
    }
  });

  // const [get_clients] = useLazyQuery(GET_ADMIN_CLIENTS, {
  //   onCompleted: data => {
  //     if (data) {
  //       setClient(data.get_clients.clients);
  //     }
  //   },
  //   variables: { first: 10 },
  //   fetchPolicy: "network-only"
  // });

  // React.useMemo(() => {
  //   get_users({ variables: { first: 10 } });
  //   get_clients({ variables: { first: 10000 } });
  // }, []);

  useEffect(() => {
    if (currentData) {
      setActivityData(currentData.get_activity_logs.activitylogs);
      setTotalRows(currentData.get_activity_logs.total);
    }
  }, [currentData]);

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (activityData) {
      return stableSort(activityData, getSorting(order, orderBy));
    }
    return null;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setDisableButton(false);
        // setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_activity_logs: {
            count: fetchMoreResult.get_activity_logs.count,
            total: fetchMoreResult.get_activity_logs.total,
            activitylogs: [
              ...prev.get_activity_logs.activitylogs,
              ...fetchMoreResult.get_activity_logs.activitylogs
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(0);
          refetch(getVariables());
        }
      });
    });
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleClearFilter = () => {
    setFilterValue({
      dateRange: {
        gte: null,
        lte: null,
        field: "datestamp"
      },
      user: "All",
      module: "All"
    });
    setFilter([]);
    setTempFilter({
      dateRange: {
        gte: null,
        lte: null,
        field: "datestamp"
      },
      user: "All",
      module: "All",
      hauler: {
        label: user.group_names[0],
        value: user.group_ids
      },
    });
    setHaulerKeyword("");
    setFilterDrawer(false);
    setPage(0);
    AddLogs(
      "Admin - Activity Logs",
      "filter",
      `User: All, Hauler: ${user.group_names[0]}  Module: All`
    );
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const handleFilters = e => {
    const { name, value } = e.target;

    switch (name) {
      case "code":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "parent_id":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      default:
        break;
    }
    setTempFilter({ ...tempFilter, [name]: value });
  };

  const ActivityLogsTable = () => {
    if (loading || loadingActivity) {
      return (
        <Loading />
        // <TableBody>
        //   <TableRow style={{ height: 53 * 2 }}>
        //     <TableCell colSpan={6} align="center">
        //       <CircularProgress />
        //     </TableCell>
        //   </TableRow>
        // </TableBody>
      );
    }
    return activityData.length > 0 ? (
      <TableBody>
        {stableSort(activityData, getSorting(order, orderBy)).map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow key={`row-${row.id}`} hover role="checkbox" tabIndex={-1}>
              <TableCell padding="checkbox" />
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <Highlight search={contextData.searchKeyword.value}>
                  {row.datestamp ? moment(row.datestamp).format("lll") : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.activity ? row.activity : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.module ? row.module : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.remote_address ? row.remote_address : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.user ? row.user.username : ""}
                </Highlight>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      (searchKeyword.value && (
        <TableBody>
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={5} align="center">
              No items matched the search
            </TableCell>
          </TableRow>
        </TableBody>
      )) || (
        <TableBody>
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={5} align="center">
              No data found
            </TableCell>
          </TableRow>
        </TableBody>
      )
    );
  };

  // useEffect(() => {
  //   setLoading(false);
  // });

  const handleSetFilters = () => {
    const temp = [];
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        temp.push({ field: key, value: tempFilter[key] });
        return temp;
      });
    // setLoading(true);
    setFilter(temp);
    setFilterValue(tempFilter);
    setHaulerKeyword("");
    AddLogs(
      "Admin - Activity Logs",
      "filter",
      `${
        filterValue.dateRange.gte
          ? `Date start: ${moment(tempFilter.dateRange.gte).format("lll")} - `
          : ""
      }${
        tempFilter.dateRange.lte
          ? `end: ${moment(tempFilter.dateRange.lte).format("lll")}, `
          : ""
      }User: ${tempFilter.user.id || `All`}, Hauler: ${
        tempFilter.hauler.label
      }, Module: ${tempFilter.module || `All`} `
    );
    setPage(0);
    closeFilterDrawer();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [
      ["Created Date", "Activity", "Module", "remote_address", "User"]
    ];

    getData().map(row => {
      const datestamp = moment(row.datestamp).format("lll");
      temp.push([
        datestamp || "",
        row.activity || "",
        row.module || "",
        row.remote_address || "",
        row.user.username || ""
      ]);
      return temp;
    });

    return temp;
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          data={tableData}
          style={{ textDecoration: "none", color: "black" }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      AddLogs(
                        "Admin - Activity Logs",
                        "download_table",
                        `${
                          filterValue.dateRange.gte
                            ? `Date start: ${moment(
                                filterValue.dateRange.gte
                              ).format("lll")} - `
                            : ""
                        }${
                          filterValue.dateRange.lte
                            ? `end: ${moment(filterValue.dateRange.lte).format(
                                "lll"
                              )}, `
                            : ""
                        }User: ${filterValue.user.id ||
                          `All`}, Module: ${filterValue.module || `All`} `
                      );
                    }}
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  if (loading || loadingActivity) return <Loading />;

  return (
    <AccessControl
      resource="/admin/acitivity_logs/"
      process="tables"
      setCanDelete={setCanDelete}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              {process.env.REACT_APP_ACTIVITY_LOGS_MODULE}
              {/* <ClientAutocomplete
                setter={setGroupFilter}
                groupFilter={groupFilter}
              /> */}
            </Typography>
            {/* <Autocomplete
              className={classes.autoComplete}
              style={{ height: 30, marginLeft: 30, width: 220 }}
              disableCloseOnSelect
              options={client}
              getOptionSelected={(o, v) => v.name === o.name}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="All"
                  // onChange={event => {
                  //   if (event.target.value.length >= 1) {
                  //     get_clients({
                  //       variables: {
                  //         first: 10,
                  //         filter: {
                  //           field: "name",
                  //           value: event.target.value
                  //         }
                  //       }
                  //     });
                  //   }
                  // }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              disableClearable
              value={groupFilter}
              onChange={(e, nv) => {
                setSearchKeyword(prev => ({ ...prev, group: nv.group_ids }));
                // setLoading(true);
                setGroupFilter(nv);
                setFilterGroupId(nv.group_ids);
              }}
            /> */}
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
              disabled={disableButton}
            >
              Download
            </Button>
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                onClick={openFilterDrawer}
                disabled={disableButton}
              >
                <Badge
                  color="secondary"
                  variant={filter.length ? "dot" : "standard"}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Pagination
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                dataLength={activityData.length}
                fetchMoreData={fetchMoreData}
                totalRows={totalRows}
                totalPage={Math.ceil(totalRows / rowsPerPage)}
                refetch={refetch}
                getVariables={getVariables}
                setRowsPerPage={setRowsPerPage}
                setLoading={setLoading}
              /> */}
              <Pagination
                rowsPerPage={rowsPerPage}
                total={Math.ceil(totalRows / rowsPerPage)}
                currentPage={page}
                onRowsPerPageChange={n => {
                  setRowsPerPage(n);
                  setPage(0);
                }}
                onPageChange={n => setPage(n)}
                onPageUp={n => setPage(n)}
                onPageDown={n => setPage(n)}
              />
            </div>
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                handleSort={handleSort}
              />
              <ActivityLogsTable
                alertData={activityData}
                classes={classes}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>
        </Paper>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_ACTIVITY_LOGS_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <span
              style={{
                textAlign: "left",
                fontSize: "14px"
              }}
            >
              Date
            </span>
            <br />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                className={classes.filterInput}
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                id="date-from"
                name="date_from"
                value={tempFilter.dateRange?.gte}
                initialFocusedDate={moment().startOf("date")}
                onChange={date => {
                  if (date) {
                    const { lte } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        gte: date.format("YYYY-MM-DD HH:mm:ss"),
                        lte: lte
                          ? moment(lte).format("YYYY-MM-DD HH:mm:ss")
                          : date.format("YYYY-MM-DD 23:59:59")
                      }
                    });
                  } else {
                    const { lte } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        gte: null,
                        lte: lte
                          ? moment(lte).format("YYYY-MM-DD HH:mm:ss")
                          : null
                      }
                    });
                  }
                }}
                label="From"
                InputProps={{
                  placeholder: "Select Date/Time"
                }}
                keyboardIcon={
                  <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                }
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
            <br />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                className={classes.filterInput}
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                id="date-to"
                name="date_to"
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                value={tempFilter.dateRange?.lte}
                initialFocusedDate={moment().endOf("date")}
                onChange={date => {
                  if (date) {
                    const { gte } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        lte: date.format("YYYY-MM-DD HH:mm:ss"),
                        gte: gte
                          ? moment(gte).format("YYYY-MM-DD HH:mm:ss")
                          : date.format("YYYY-MM-DD 00:00:00")
                      }
                    });
                  } else {
                    const { gte } = tempFilter.dateRange;
                    setTempFilter({
                      ...tempFilter,
                      dateRange: {
                        ...tempFilter.dateRange,
                        lte: null,
                        gte: gte
                          ? moment(gte).format("YYYY-MM-DD HH:mm:ss")
                          : null
                      }
                    });
                  }
                }}
                label="To"
                InputProps={{
                  placeholder: "Select Date/Time"
                }}
                keyboardIcon={
                  <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                }
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>
              <b>User</b>
            </InputLabel>
            <br />
            <Autocomplete
              id="parent_id"
              options={users}
              value={tempFilter.user}
              getOptionLabel={option => option.name || ""}
              getOptionSelected={(o, v) => o.name === v.name}
              renderOption={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  placeholder="All"
                  onChange={event => {
                    if (event.target.value.length >= 2) {
                      get_users({
                        variables: {
                          first: 10,
                          filter: {
                            field: "users.username",
                            value: event.target.value
                          }
                        }
                      });
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                />
              )}
              onChange={(e, val) => {
                if (val) {
                  setTempFilter({
                    ...tempFilter,
                    user: val
                  });
                }
              }}
              disableClearable
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <Autocomplete
              options={haulerGroupOptions}
              value={tempFilter.hauler}
              getOptionSelected={(o, v) => o?.value === v?.value}
              getOptionLabel={option => option?.label}
              renderOption={option => option?.label}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  label="Hauler/Groups"
                  inputlabelprops={{
                    shrink: false,
                    style: { color: "black", fontWeight: "bold" }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    }
                  }}
                  onChange={e => {
                    setHaulerKeyword(e.target.value);
                  }}
                />
              )}
              onChange={(e, val) => {
                if (val) {
                  setTempFilter({
                    ...tempFilter,
                    hauler: {
                      label: val.label,
                      value: val.value[0]
                    }
                  })
                }
              }}
              disableClearable
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>
              <b>Module</b>
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.module}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="module"
              fullWidth
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Admin - Users">Admin - Users</MenuItem>
              <MenuItem value="Admin - Vehicles/CV">
                Admin - Vehicles/CV
              </MenuItem>
              <MenuItem value="Admin - Vehicle Types">
                Admin - Vehicle Types
              </MenuItem>
              <MenuItem value="Admin - Geofences">Admin - Geofences</MenuItem>
              <MenuItem value="Admin - Access Rights">
                Admin - Access Rights
              </MenuItem>
              <MenuItem value="Admin - Alerts">Admin - Alerts</MenuItem>
              <MenuItem value="Admin - Clients">Admin - Clients</MenuItem>
              <MenuItem value="Admin - GPS Devices">
                Admin - GPS Devices
              </MenuItem>
              <MenuItem value={process.env.REACT_APP_FEEDBACK_MESSAGE}>
                Admin - {process.env.REACT_APP_FEEDBACK_MESSAGE}
              </MenuItem>
              <MenuItem value={process.env.REACT_APP_CONTROL_ROOM_MODULE}>
                Admin - {process.env.REACT_APP_CONTROL_ROOM_MODULE}
              </MenuItem>
              <MenuItem value="Admin - GPS Providers">
                Admin - GPS Providers
              </MenuItem>
              <MenuItem value="Admin - Alert Descriptions">
                Admin - Alert Descriptions
              </MenuItem>
              <MenuItem value="Admin - Alert References">
                Admin - Alert References
              </MenuItem>
              <MenuItem value="Admin - Activity Logs">
                Admin - Activity Logs
              </MenuItem>
              <MenuItem value="Admin - SMS Sending">
                Admin - SMS Sending
              </MenuItem>
              <MenuItem value="Admin - Driver Info">
                Admin - Driver Info
              </MenuItem>
              <MenuItem value="Dashboard">Dashboard</MenuItem>
              <MenuItem value="Fleet">Fleet</MenuItem>
              <MenuItem value="Login">Login</MenuItem>
              <MenuItem value="Notification">Notification</MenuItem>
              <MenuItem value="Pre-Dispatch">Pre-dispatch</MenuItem>
              <MenuItem value="Profile">Profile</MenuItem>
              <MenuItem value={process.env.REACT_APP_AUTOMATED_REPORTS_MODULE}>
                {process.env.REACT_APP_AUTOMATED_REPORTS_MODULE}
              </MenuItem>
              <MenuItem value={process.env.REACT_APP_REPORT_MODULE}>
                {process.env.REACT_APP_REPORT_MODULE}
              </MenuItem>
              <MenuItem value="TM Suite API">TM Suite API</MenuItem>
              <MenuItem value="TM Suite Bookings">TM Suite Bookings</MenuItem>
              <MenuItem value="Trips">Trips</MenuItem>
              <MenuItem value="PMS - Vehicle Information">
                PMS - Vehicle Information
              </MenuItem>
              <MenuItem value="PMS - Vehicle Issues">
                PMS - Vehicle Issues
              </MenuItem>
              <MenuItem value="PMS - 360 Checklist">
                PMS - 360 Checklist
              </MenuItem>
              <MenuItem value="Allocation - Trip Allocation">
                Allocation - Trip Allocation
              </MenuItem>
              <MenuItem value="Vehicle Allocation">
                Allocation - Vehicle Allocation
              </MenuItem>
            </Select>
          </FormControl>
        </Filter>
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}
      </div>
    </AccessControl>
  );
}
