/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
  MenuItem,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  Grid,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  CircularProgress,
  Fab,
  FormControl,
  Select,
  InputLabel,
  Badge,
  Menu
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  // ExpandMore as ExpandMoreIcon
  ExpandLess,
  ExpandMore,
  MoreVert,
  ListTwoTone,
  MapTwoTone,
  History as HistoryIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import lodash from "lodash";
import Cookie from "js-cookie";
import moment from "moment";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
// import { LayoutContext } from "../../../Layout";
import SearchContext from "../../../../context/SearchContext";
import Filter from "../../Filter";
import validate from "../../../../utils/validation";
import {
  GET_DEVICES,
  GET_GPS_PROVIDERS,
  GET_DEVICE_TYPES,
  GET_GROUPS,
  GET_ADMIN_CLIENT,
  GET_CLIENTS_QUERY_WO_GROUPS,
  GET_CLIENTS_QUERY
} from "../../../../graphql/Queries";
import {
  DELETE_DEVICE,
  DELETE_DEVICE_ALL,
  ADD_DEVICES,
  EDIT_DEVICES
} from "../../../../graphql/Mutations";
// import { EDIT_DEVICE } from "../../../../graphql/Mutations";
import client from "../../../../Client";
import { gpsStatus, parseDateExcel } from "../../../Utils";
import { getVehiclesToPair } from "../../../../api/vehicleRepository";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Battery from "../../../../utils/Battery";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";
import useFormStyles from "../../../../styles/useFormStyles";

import ClientAutocomplete from "../../../../utils/ClientAutocomplete";
import Upload, { status } from "../../../../utils/Upload";
import {
  getBookingsOfVehicle,
  resetTrips,
  unpairDeviceFromVehicle
} from "../../../../utils/functions/unpairingFunctions";
import UnpairingDialog from "../../../../utils/modals/UnpairingDialog";
import Map from "./Map";
import HistorySelector from "../../../Monitoring/HistorySelector";

const Highlight = require("react-highlighter");

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "IMEI/ Serial Number"
  },
  {
    id: "devicealias",
    numeric: false,
    disablePadding: false,
    label: "Device Alias"
  },
  {
    id: "device_type",
    numeric: false,
    disablePadding: false,
    label: "Device Type"
  },
  {
    id: "plateno",
    numeric: false,
    disablePadding: false,
    label: "Paired Vehicle/CV"
  },
  {
    id: "battery_level",
    numeric: true,
    disablePadding: false,
    label: "Battery"
  },
  {
    id: "gps_providers.name",
    numeric: true,
    disablePadding: false,
    label: "GPS Provider"
  },
  {
    id: "rover_status.status_code_id",
    numeric: true,
    disablePadding: false,
    label: "GPS Status"
  },
  {
    id: "rover_status.lastlocation",
    numeric: false,
    disablePadding: false,
    label: "Last Known Location"
  }
];

function EnhancedTableHead(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    handleSort,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!canEdit}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative"
  },
  autoComplete: {
    height: 30,
    marginLeft: 10,
    width: 180,
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  swal: {
    zIndex: 10000000000
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children, setGroupFilter, groupFilter, user, view, setView } = props;
  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        GPS Devices
      </Typography>
      <Button
        color="primary"
        variant="contained"
        style={{
          borderRadius: "25px",
          margin: "0 20px 0 20px",
          fontSize: "12px"
        }}
        startIcon={view === viewType.MAP ? <ListTwoTone /> : <MapTwoTone />}
        onClick={() =>
          setView(prev =>
            prev === viewType.MAP ? viewType.LIST : viewType.MAP
          )
        }
      >
        {view === viewType.LIST ? "Map View" : "List View"}
      </Button>
      {/* <Select
      <Select
        onChange={e => handleGroupFilter(e)}
        value={groupFilter}
        displayEmpty
        name="rows_per_page"
        disableUnderline
        variant="outlined"
        style={{ height: 30, marginLeft: 30 }}
      >
        <MenuItem value="">Select Client</MenuItem>
        {groupsData.get_groups.groups.map(group => (
          <MenuItem value={group.id} key={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </Select> */}
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 5,
    height: 35,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    height: 35,
    margin: 5,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  icon: {
    opacity: 1
  },
  dialog: {
    borderRadius: "10px"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  },
  actionIcons: {
    fontSize: "20px",
    color: "#878787",
    marginRight: "10px"
  },
  optionsItem: {
    fontSize: "14px"
  }
}));

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const DlMenu = props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    tableData,
    keyLog
  } = props;

  const tableDownload = () => {
    return (
      <CSVLink
        data={tableData}
        style={{ textDecoration: "none", color: "black" }}
      >
        Download Table
      </CSVLink>
    );
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  onClick={() =>
                    AddLogs("Admin - GPS Devices", "download_template", "")
                  }
                >
                  <Link
                    to="/assets/gps_upload_template.xlsx"
                    target="_blank"
                    download
                    underline="none"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Download Template
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    AddLogs("Admin - GPS Devices", "download_table", keyLog)
                  }
                >
                  {tableDownload()}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

// function UploadDialog(props) {
//   const classes = useStyles();
//   const {
//     user,
//     uploadDialog,
//     handleCloseUpload,
//     // refetch,
//     // getVariables,
//     // groupsData,
//     deviceList,
//     setDeviceList
//     // devicesData,
//     // loadingDevicesData
//   } = props;
//   const [file, setFile] = useState([]);
//   const [summary, setSummary] = useState(false);
//   const [fileErrors, setFileErrors] = useState([]);
//   const [counter, setCounter] = useState({
//     previous: 0
//   });
//   // const [count, setCount] = useState();
//   // const [result, setResult] = useState([]);
//   const [re, setRe] = useState(false);
//   const [forceRerender, setForceRerender] = useState(false);
//   const [load, setLoad] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);

//   const vehiclesData = useRef(null);
//   const clientsData = useRef(null);
//   const deviceTypeData = useRef(null);
//   const gpsProviderData = useRef(null);
//   const groupsIDData = useRef(null);

//   // const { loading: deviceTypesLoading, data: deviceTypesData } = useQuery(
//   //   GET_DEVICE_TYPES
//   // );

//   // const {
//   //   loading: gpsProvidersLoading,
//   //   data: gpsProvidersData
//   // } = useQuery(GET_GPS_PROVIDERS, { variables: { first: 100 } });

//   // const { loading: groupsLoading, data: groupsData } = useQuery(GET_GROUPS, {
//   //   variables: { first: 10000 }
//   // });
//   // const { data: clientsData } = useQuery(GET_CLIENTS_QUERY, {
//   //   variables: { first: 10000 }
//   // });

//   useEffect(() => {
//     if (summary) {
//       if (fileErrors.length > 0) {
//         const temp = file;
//         let status = "";
//         if (fileErrors.every(error => !error.errors && !error.warnings)) {
//           status = "Success";
//         } else if (fileErrors.every(error => error.errors !== "")) {
//           status = "Failed";
//         } else if (
//           fileErrors.some(error => error.errors !== "") ||
//           fileErrors.every(error => error.warnings !== "")
//         ) {
//           status = "Partial Success";
//         }

//         temp[temp.length - 1] = {
//           ...temp[temp.length - 1],
//           status: status,
//           errors: fileErrors
//         };
//         setFile(temp);
//         setRe(!re);
//         // refetch(getVariables);
//         setSummary(false);
//         setFileErrors([]);
//       }
//     }
//   }, [summary]);

//   const reorder = (a, b) => {
//     const first = a.row;
//     const second = b.row;

//     let comparison = 0;
//     if (first > second) {
//       comparison = 1;
//     } else if (first < second) {
//       comparison = -1;
//     }

//     return comparison;
//   };

//   const validateCell = async (key, value) => {
//     const { vehicles } = vehiclesData.current?.data.get_vehicles;
//     // const { device_types } = deviceTypesData.get_device_types;
//     // const { gps_providers } = gpsProvidersData.get_gps_providers;

//     // const { devices } = devicesData?.get_devices || [];
//     let val = "";
//     // const tempArr = [];
//     const index = -1;
//     let vehicleIndex = -1;
//     let vehiclePlatenos = [];
//     switch (key) {
//       case "client_id":
//         if (!value) {
//           val = { error: "Client is required" };
//         } else {
//           const checkClients = _.filter(
//             clientsData.current,
//             obj => obj.name.toLowerCase() === value.toLowerCase()
//           );

//           if (checkClients.length) {
//             val = checkClients[0].id;
//           } else {
//             val = { error: "Client doesn't exist" };
//           }
//           // index = clients.indexOf(value.toLowerCase());
//           // if (index > -1) {
//           //   val = parseInt(clientsData.get_clients.clients[index].id, 10);
//           // } else {
//           //   val = { error: "Client doesn't exist" };
//           // }
//         }
//         break;
//       case "devicealias":
//         if (!value) {
//           val = { error: "Device Alias is required" };
//         } else {
//           // val = value.toString();
//           return validate("alphanumeric_default", value.toString())
//             ? value.toString()
//             : { error: "Device Alias invalid format" };
//         }
//         break;
//       case "device_type":
//         if (!value) {
//           val = { error: "Device Type is required" };
//         } else {
//           const checkDevType = _.filter(
//             deviceTypeData.current,
//             obj => obj.name.toLowerCase() === value.toLowerCase()
//           );
//           if (checkDevType.length) {
//             val = checkDevType[0].name;
//           } else {
//             val = { error: "Device type doesn't exist" };
//           }

//           // const deviceTypes = device_types.map(device_type => device_type.name);
//           // const deviceTypeIndex = deviceTypes.indexOf(value);
//           // if (deviceTyepIndex > -1) {
//           //   val = validate("alphanumeric", value)
//           //     ? device_types[deviceTypeIndex].name
//           //     : { error: "Mobile Imei invalid format" };
//           // } else {
//           //   val = { error: "Device type doesn't exist" };
//         }
//         break;
//       case "name":
//         if (!value) {
//           val = { error: "IMEI/Serial number is required" };
//         } else {
//           const devicesMap = [];
//           const devices = await client
//             .query({
//               query: GET_DEVICES,
//               variables: {
//                 keyword: value.toString().toLowerCase()
//               }
//             })
//             .then(result => {
//               setDeviceList([...deviceList, result.data.get_devices.devices]);
//               return [...result.data.get_devices.devices];
//             });

//           devices.forEach(element => {
//             if (
//               element?.name?.toLowerCase() === value.toString().toLowerCase()
//             ) {
//               devicesMap.push({
//                 plateno: element?.vehicle_info?.plateno || "-",
//                 imei: element?.name || "-"
//               });
//             }
//           });
//           if (vehicleIndex > -1) {
//             val = validate("alphanumeric_default", value)
//               ? value.toString()
//               : { error: "IMEI/Serial number is invalid format" };
//           } else if (
//             devicesMap.some(
//               x =>
//                 x.imei.toLowerCase() === value.toString().toLowerCase() &&
//                 x.plateno !== "-"
//             )
//           ) {
//             val = {
//               error: `IMEI/Serial number ${devicesMap[0].imei} is already paired with plate number ${devicesMap[0].plateno}`
//             };
//           } else {
//             val = value.toString();
//           }
//         }
//         break;
//       case "vehicle_info":
//         if (!value) {
//           val = {};
//         } else {
//           // val = validate("alphanumeric", `${value}`)
//           //   ? vehicles[vehicleIndex] || []
//           //   : { error: "Paired vehicle/cv is invalid format" };
//           vehiclePlatenos = vehicles.map(vehicle => vehicle.plateno);
//           vehicleIndex = vehiclePlatenos.indexOf(`${value}`);
//           if (vehicleIndex > -1) {
//             val = validate("alphanumeric", `${value}`)
//               ? vehicles[vehicleIndex] || []
//               : { error: "Paired vehicle/cv is invalid format" };
//           } else {
//             val = [{ plateno: null }];
//             // val = { error: "Paired Vehicle/cv doesn't exist" };
//           }
//         }
//         break;
//       case "intervalreporting":
//         if (!value && value !== 0) {
//           val = { error: "Main Interval reporting (min) is required" };
//         } else if (validate("numeric", value)) {
//           if (value > 0 && value <= 10_080) {
//             val = parseInt(value, 10);
//           } else {
//             val = {
//               error: "Main Interval Reporting (min) value min: 1, max: 10,080"
//             };
//           }
//         } else {
//           val = { error: "Main Interval reporting (min) invalid format" };
//         }
//         break;
//       case "battery_intervalreporting":
//         if (!value && value !== 0) {
//           val = { error: "Auxiliary Interval reporting (min) is required" };
//         } else if (validate("numeric", value)) {
//           if (value > 0 && value <= 10_080) {
//             val = parseInt(value, 10);
//           } else {
//             val = {
//               error:
//                 "Auxiliary Interval Reporting (min) value min: 1, max: 10,080"
//             };
//           }
//         } else {
//           val = {
//             error: "Auxiliary Interval Reporting (min) is invalid format"
//           };
//         }
//         break;
//       case "idlesetting":
//         if (!value && value !== 0) {
//           val = { error: "Idle Setting (min) is required" };
//         } else if (validate("numeric", value)) {
//           if (value > 0 && value <= 10_080) {
//             val = parseInt(value, 10);
//           } else {
//             val = {
//               error: "Idle Setting (min) value min: 1, max: 10,080"
//             };
//           }
//         } else {
//           val = { error: "Idle Setting (min) is invalid format" };
//         }
//         break;
//       case "aux_voltage":
//         if (value !== null) {
//           if (validate("numeric", value)) {
//             if (value > 0 && value <= 10_080) {
//               val = parseInt(value, 10);
//             } else {
//               val = {
//                 error: "Auxiliary Voltage (mV) value min: 1, max: 10,080"
//               };
//             }
//           } else {
//             val = { error: "Auxiliary Voltage (mV) is invalid format" };
//           }
//         } else {
//           val = "";
//         }
//         break;
//       case "gps_provider_id":
//         if (!value) {
//           val = { error: "GPS Provider is required" };
//         } else {
//           const gpsProv = _.filter(
//             gpsProviderData.current,
//             obj => obj.name.toLowerCase() === value.toLowerCase()
//           );
//           if (gpsProv.length) {
//             val = +gpsProv[0].id;
//           } else {
//             val = { error: "GPS Provider doesn't exist" };
//           }

//           //   const gpsProviders = gps_providers.map(
//           //     gps_provider => gps_provider.name
//           //   );
//           //   const gpsProviderIndex = gpsProviders.indexOf(value);
//           //   if (gpsProviderIndex > -1) {
//           //     val = validate("alphanumeric", value)
//           //       ? +gps_providers[gpsProviderIndex].id
//           //       : { error: "GPS Provider invalid format" };
//           //   } else {
//           //     val = { error: "GPS Provider doesn't exist" };
//           //   }
//         }
//         break;
//       case "dateinstalled":
//         if (value?.trim() === "") {
//           val = moment().format("YYYY-MM-DD");
//         } else {
//           const temp = moment(parseDateExcel(value?.trim())).format(
//             "YYYY-MM-DD"
//           );
//           if (temp.toLowerCase() === "invalid date") {
//             val = { error: "Date installed is invalid format" };
//           } else {
//             val = temp;
//           }
//         }
//         break;
//       case "expirationdate":
//         if (value?.trim() === "") {
//           val = moment().format("YYYY-MM-DD");
//         } else {
//           const temp = moment(parseDateExcel(value?.trim())).format(
//             "YYYY-MM-DD"
//           );
//           if (temp.toLowerCase() === "invalid date") {
//             val = { error: "Expiration Date is invalid format" };
//           } else {
//             val = temp;
//           }
//         }
//         break;
//       case "warranty":
//         if (value?.trim() === "") {
//           val = moment().format("YYYY-MM-DD");
//         } else {
//           const temp = moment(parseDateExcel(value?.trim())).format(
//             "YYYY-MM-DD"
//           );
//           if (temp.toLowerCase() === "invalid date") {
//             val = { error: "Warranty is invalid format" };
//           } else {
//             val = temp;
//           }
//         }
//         break;
//       case "remarks":
//         if (value) {
//           val = validate("alphanumeric", value)
//             ? value
//             : { error: "Remarks is invalid format" };
//         } else {
//           val = "";
//         }
//         break;
//       case "group_ids":
//         const arr = [];
//         const missing = [];
//         if (value) {
//           const arrData = value
//             .toLowerCase()
//             .replaceAll(/, /g, "^ ")
//             .split(",")
//             .map(data => data.replaceAll("^", ","));

//           const groupids = _.filter(groupsIDData.current, obj => {
//             return arrData.includes(obj.name.toLowerCase());
//           });

//           if (groupids.length) {
//             for (let i = 0; i < groupids.length; i++) {
//               if (groupids[i]) {
//                 arr.push(+groupids[i].id);
//               }
//             }
//             val = arr;
//           } else {
//             val = { error: "Secondary client doesn't exist" };
//           }
//         } else {
//           val = [];
//         }
//         break;
//       case "mirror_to_ngt":
//         if (value) {
//           if (value.toLowerCase() === "yes") {
//             val = true;
//           } else if (value.toLowerCase() === "no") {
//             val = false;
//           } else {
//             val = { error: "Mirror to NGT is invalid" };
//           }
//         } else {
//           val = false;
//         }
//         break;
//       case "mobileno":
//         if (value) {
//           const mobilenoStr = value.toString();
//           const mobilenoStart = mobilenoStr.startsWith("+639");
//           const beginWith63 = mobilenoStart
//             ? mobilenoStr
//             : mobilenoStr.replace(/^(0?9|639)/, "+639");
//           val = validate("mobile_number_ph", beginWith63)
//             ? beginWith63
//             : { error: "Invalid Mobile Number" };
//         } else {
//           val = "";
//         }
//         break;
//       default:
//         break;
//     }
//     return val;
//   };

//   const parseRow = async array => {
//     const temp = { vehicle_info: [], device_config: {} };
//     await Promise.all(
//       process.env.REACT_APP_DEVICES_KEY.split(",").map(async (key, index) => {
//         if (
//           key === "intervalreporting" ||
//           key === "battery_intervalreporting" ||
//           key === "idlesetting" ||
//           key === "aux_voltage" ||
//           key === "gps_provider_id" ||
//           key === "mobileno" ||
//           key === "dateinstalled" ||
//           key === "expirationdate" ||
//           key === "warranty"
//         ) {
//           const data = await validateCell(key, array[index]);
//           Object.assign(temp.device_config, {
//             [key]: data
//           });
//         } else {
//           const data = await validateCell(key, array[index]);
//           Object.assign(temp, { [key]: data });
//         }
//       })
//     );
//     return temp;
//   };

//   const handleUpload = e => {
//     const { files } = e.target;
//     setFile([...file, { file: files[0], status: "", errors: [] }]);
//     e.target.value = null;
//   };

//   const AddDevices = batchData => {
//     return new Promise(resolve => {
//       client
//         .mutate({
//           mutation: ADD_DEVICES,
//           variables: {
//             devices: batchData
//           }
//         })
//         .then(data => {
//           AddLogs("Admin - GPS Devices", "upload", "");
//           resolve(data);
//         });
//     });
//   };

//   const parseResponse = (res, count, fileErrors, validIndex) => {
//     const { add_devices } = res.data;
//     const errors = add_devices.map(val => val.error);
//     if (errors.length > 0) {
//       const errorMsgs = errors.map(val => {
//         const temp = val.map(errormsg => {
//           if (errormsg.field === "name") {
//             return "IMEI/Serial Number already exists";
//           }
//           return Object.values(errormsg)[1];
//         });
//         return temp;
//       });
//       errorMsgs.map((val, index) =>
//         fileErrors.push({
//           row: validIndex[index],
//           errors: lodash.join(val, ", ")
//         })
//       );
//       // setFileErrors(tempFileErrors);
//       setCounter({ ...counter, previous: add_devices.length });
//     }
//     const warningCount = fileErrors.filter(error => error.warnings);
//     if (fileErrors.length - warningCount.length === count) {
//       setFileErrors(fileErrors);
//       setSummary(true);
//     }
//   };

//   useEffect(() => {
//     // Check if the file to be uploaded is already set.
//     if (file.length) {
//       // Gets the last file in the list.
//       const f = file[file.length - 1].file;
//       // Initialize file reader.
//       const reader = new FileReader();

//       // Triggers when reader.readAsArrayBuffer(...) invoke.
//       reader.onload = async e => {
//         // Converts a File object to a Uint8Array.
//         const dataRes = new Uint8Array(e.target.result);
//         // Gets list of file sheets.
//         const workbook = XLSX.read(dataRes, { type: "array" });
//         // Gets the first sheet.
//         const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
//         // Converts first sheet to json object.
//         const datz = XLSX.utils.sheet_to_json(first_worksheet, {
//           header: 1,
//           blankrows: false
//         });

//         // Initialize vehicles to pair
//         const vehiclesToPair = [];
//         // Initialize clients
//         const clientsToValidate = [];
//         // Initialize device type
//         const devTypeToValidate = [];
//         // Initialize gps provider
//         const gpsToValidate = [];
//         // Initialize groups
//         const groupsToValidate = [];
//         for (let i = 1; i <= datz.length - 1; i += 1) {
//           // Get all data of pair vehicle column
//           if (datz[i][4]) {
//             vehiclesToPair.push(datz[i][4].toString());
//           }
//           // Get all data of clients column
//           if (datz[i][0]) {
//             clientsToValidate.push(datz[i][0]);
//           }
//           // Get all data of device type column
//           if (datz[i][3]) {
//             devTypeToValidate.push(datz[i][3]);
//           }
//           // Get all data of gps provider column
//           if (datz[i][10]) {
//             gpsToValidate.push(datz[i][10]);
//           }
//           // Get all data of secondary client column
//           if (datz[i][14]) {
//             // Seperated by comma
//             let arrList;
//             const arrData = datz[i][14]
//               .replaceAll(/, /g, "^ ")
//               .split(",")
//               .map(data => data.replaceAll("^", ","));

//             for (let i = 0; i < arrData.length; i++) {
//               if (arrData[i]) {
//                 arrList = arrData[i];
//               }
//               groupsToValidate.push(arrList);
//             }
//           }
//         }

//         // Query to check if the clients are existing
//         client
//           .query({
//             query: GET_CLIENTS_QUERY,
//             variables: {
//               filter: [
//                 {
//                   field: "clients.name",
//                   value: clientsToValidate
//                 }
//               ]
//             }
//           })
//           .then(result => {
//             // Result of existing client store to useRef
//             clientsData.current = result.data.get_clients.clients;
//           });

//         // Query to check if the device types are existing
//         client
//           .query({
//             query: GET_DEVICE_TYPES,
//             variables: {
//               filter: [
//                 {
//                   field: "device_types.name",
//                   value: devTypeToValidate
//                 }
//               ]
//             }
//           })
//           .then(result => {
//             // Result of existing device type store to useRef
//             deviceTypeData.current = result.data.get_device_types.device_types;
//           });

//         // Query to check if the gps provider are existing
//         client
//           .query({
//             query: GET_GPS_PROVIDERS,
//             variables: {
//               filter: [
//                 {
//                   field: "gps_providers.name",
//                   value: gpsToValidate
//                 }
//               ]
//             }
//           })
//           .then(result => {
//             // Result of existing gps provider store to useRef
//             gpsProviderData.current =
//               result.data.get_gps_providers.gps_providers;
//           });

//         // Query to check if the groups are existing
//         client
//           .query({
//             query: GET_GROUPS,
//             variables: {
//               or: [
//                 {
//                   field: "groups.name",
//                   value: [...groupsToValidate, ...clientsToValidate]
//                 }
//               ]
//             }
//           })
//           .then(result => {
//             // Result of existing gps provider store to useRef
//             groupsIDData.current = result.data.get_groups.groups;
//           });

//         // Gets the list of vehicles (to check if vehicle(s) exists)
//         setLoad(true);
//         getVehiclesToPair(vehiclesToPair).then(result => {
//           vehiclesData.current = result;
//           setLoad(false);
//         });
//       };

//       // Invoke reader
//       reader.readAsArrayBuffer(f);
//     }

//     return () => {
//       // Reset the list of vehicle results
//       vehiclesData.current = null;
//     };
//   }, [file]);

//   const submitUpload = () => {
//     // const { devices } = devicesData?.get_devices || [];
//     setIsUploading(true);
//     const devicesMap = [];
//     deviceList.forEach(element => {
//       if (element.vehicle_info && element.vehicle_info.plateno) {
//         devicesMap.push({
//           plateno: element?.vehicle_info?.plateno,
//           imei: element?.name
//         });
//       }
//     });
//     const tempFile = file;
//     const f = file[file.length - 1].file;
//     const reader = new FileReader();
//     reader.onload = async e => {
//       const dataRes = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(dataRes, { type: "array" });
//       const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
//       const datz = XLSX.utils.sheet_to_json(first_worksheet, {
//         header: 1,
//         blankrows: false
//       });
//       let tempBatchData = [];
//       let validData = true;
//       const fileError = [];

//       if (f.size / 1024 / 1024 > 5) {
//         tempFile[file.length - 1] = {
//           ...tempFile[file.length - 1],
//           status: "Failed",
//           errors: "File is too large"
//         };
//         setIsUploading(false);
//         setFile(tempFile);
//         setRe(!re);
//         return;
//       }

//       if (
//         datz.length === 0 ||
//         datz[0].length !== process.env.REACT_APP_DEVICES_KEY.split(",").length
//       ) {
//         tempFile[file.length - 1] = {
//           ...tempFile[file.length - 1],
//           status: "Failed",
//           errors: "Please use the template provided"
//         };
//         setIsUploading(false);
//         setFile(tempFile);
//         setRe(!re);
//         return;
//       }

//       if (datz.length === 1) {
//         tempFile[file.length - 1] = {
//           ...file[file.length - 1],
//           status: "Failed",
//           errors: "File is empty"
//         };
//         setIsUploading(false);
//         setFile(tempFile);
//         setRe(!re);
//         return;
//       }

//       if (datz.length > 1) {
//         tempFile[file.length - 1] = {
//           ...tempFile[file.length - 1],
//           status: "Uploading"
//         };
//         setIsUploading(false);
//         setFile(tempFile);
//         setForceRerender(!forceRerender);
//       }
//       const interval = datz.length - 1;
//       for (let i = 1; i <= datz.length - 1; i += interval) {
//         const validIndex = [];
//         const tempDatz = datz.slice(i, i + interval);
//         for (let u = 0; u <= tempDatz.length - 1; u += 1) {
//           // eslint-disable-next-line no-await-in-loop
//           const temp = await parseRow(tempDatz[u]);
//           const tempErrors = [];
//           // const tempWarning = { ...temp.group_ids };
//           // temp.group_ids = temp.group_ids.id;
//           if (
//             Object.values(temp).every(
//               value => Object.keys(value)[0] !== "error"
//             ) &&
//             Object.values(temp.device_config).every(
//               value => Object.keys(value)[0] !== "error"
//             )
//           ) {
//             // Object.assign(temp, { client_id: user.client.id });
//             // let indexOfClient = -1;
//             // let indexOfGroup = -1;
//             // let clientsQuery;

//             // clientsQuery = await clientsquery({
//             //   variables: {
//             //     filter: [
//             //       {
//             //         field: "clients.id",
//             //         value: temp.client_id.toString()
//             //       }
//             //     ]
//             //   }
//             // })
//             // const clients = clientsQuery.data.get_clients.clients.map(client => (
//             //   parseInt(client.id, 10)
//             // ))
//             // // const clients = clientsData.get_clients.clients.map(client =>
//             // //   parseInt(client.id, 10)
//             // // );

//             // // indexOfClient = clients.indexOf(temp.client_id);
//             // indexOfClient = clients.indexOf(temp.client_id);

//             // if (indexOfClient > -1) {
//             //   // const group = groupsData.get_groups.groups.map(
//             //   //   group => group.name
//             //   // );
//             //   const group = clientsQuery.data.get_clients.clients.map(
//             //     group => group.name
//             //   );

//             //   // indexOfGroup = group.indexOf(
//             //   //   clientsData.get_clients.clients[indexOfClient].name
//             //   // );
//             //   indexOfGroup = group.indexOf(
//             //     clientsQuery.data.get_clients.clients[indexOfClient].name
//             //   );

//             //   if (indexOfGroup > -1) {
//             //     // temp.group_ids.push(
//             //     //   parseInt(groupsData.get_groups.groups[indexOfGroup].id, 10)
//             //     // );
//             //     temp.group_ids.push(
//             //       parseInt( clientsQuery.data.get_clients.clients[indexOfGroup].id, 10)
//             //     );
//             //     temp.group_ids = lodash.uniq(temp.group_ids);
//             //   }
//             // }

//             const arrData = _.uniq(
//               tempDatz[u][14]
//                 ?.toLowerCase()
//                 ?.replaceAll(/, /g, "^ ")
//                 ?.split(",")
//                 ?.map(data => data?.replaceAll("^", ","))
//             );

//             if (
//               Array.isArray(arrData) &&
//               temp?.group_ids?.length !== arrData?.length
//             ) {
//               fileError.push({
//                 row: i + u + 1,
//                 warnings: `Secondary client doesn't exist.`
//               });
//             }
//             temp.group_ids.unshift(
//               +groupsIDData.current.find(
//                 g => g.name.toLowerCase() === tempDatz[u][0].toLowerCase()
//               ).id
//             );
//             // temp.group_ids.unshift(clientsData.current.find(c => +c.id === +temp.client_id).group_ids[0])
//             temp.group_ids = lodash.uniq(temp.group_ids);

//             if (!temp.vehicle_info) {
//               tempBatchData.push(temp);
//             } else if (!lodash.isEmpty(temp.vehicle_info)) {
//               if (temp.vehicle_info?.plateno) {
//                 if (
//                   devicesMap.some(
//                     x =>
//                       x.plateno.toLowerCase() ===
//                       temp.vehicle_info.plateno.toLowerCase()
//                   )
//                 ) {
//                   const dataWarning = devicesMap.find(obj => {
//                     return (
//                       obj.plateno.toLowerCase() ===
//                       temp.vehicle_info.plateno.toLowerCase()
//                     );
//                   });
//                   fileError.push({
//                     row: i + u + 1,
//                     warnings: `IMEI/Serial number ${
//                       dataWarning.imei
//                     } is already paired with plate number ${dataWarning.plateno ||
//                       "-"}.`
//                   });
//                   tempBatchData.push({ ...temp, vehicle_info: [] });
//                 } else {
//                   tempBatchData.push(temp);
//                 }
//               } else {
//                 fileError.push({
//                   row: i + u + 1,
//                   warnings: "Paired Vehicle/cv doesn't exist."
//                 });
//                 tempBatchData.push({ ...temp, vehicle_info: [] });
//               }
//             } else {
//               tempBatchData.push({ ...temp, vehicle_info: [] });
//             }
//             validIndex.push(i + u + 1);
//             // validData = true;
//           } else {
//             validData = false;
//             Object.values(temp)
//               .filter(value => Object.keys(value)[0] === "error")
//               .map(value => tempErrors.push(value.error));
//             Object.values(temp.device_config)
//               .filter(value => Object.keys(value)[0] === "error")
//               .map(value => tempErrors.push(value.error));
//             fileError.push({
//               row: i + u + 1,
//               errors: lodash.join(tempErrors, ", ")
//             });
//           }
//         }
//         if (tempBatchData.length > 0) {
//           const response = await AddDevices(tempBatchData);
//           parseResponse(response, datz.length - 1, fileError, validIndex);
//           tempBatchData = [];
//         }
//         if (i + interval > datz.length - 1) {
//           if (!validData) {
//             setFileErrors(fileError);
//             setSummary(true);
//           }
//         }
//       }
//     };
//     setDeviceList([]);
//     reader.readAsArrayBuffer(f);
//   };

//   const pendingFile = () => {
//     if (file.length > 0) {
//       if (!file[file.length - 1].status) {
//         return true;
//       }
//     }
//     return false;
//   };

//   const [expanded, setExpanded] = useState(-1);

//   const handleClick = index => {
//     setExpanded(index === expanded ? -1 : index);
//   };

//   const statusColor = status => {
//     let style = {
//       fontSize: 12,
//       color: "#3f3f3f"
//     };
//     switch (status) {
//       case "Failed":
//       case "Partial Success":
//         style = {
//           fontSize: 12,
//           color: "#ff7043"
//         };
//         break;
//       case "Success":
//         style = {
//           fontSize: 12,
//           color: "#10e23f"
//         };
//         break;
//       default:
//         break;
//     }

//     return style;
//   };

//   // deviceTypesLoading || gpsProvidersLoading ? (
//   //   <Loading />
//   // ) :
//   return (
//     <Dialog
//       classes={{ paper: classes.dialogPaper }}
//       onClose={handleCloseUpload}
//       aria-labelledby="customized-dialog-title"
//       open={uploadDialog}
//       maxWidth={false}
//     >
//       <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
//         Upload
//       </DialogTitle>
//       <DialogContent
//         dividers
//         classes={{
//           root: classes.dialogContent,
//           dividers: classes.dialogContent
//         }}
//       >
//         {file.length > 0 &&
//           file.map((fileInfo, index) => {
//             // const withWarning = fileInfo?.errors?.some(error => error?.warnings);
//             return (
//               <ExpansionPanel
//                 key={index}
//                 square
//                 expanded={index === expanded}
//                 onClick={() => handleClick(index)}
//               >
//                 <ExpansionPanelSummary
//                   aria-controls="panel1d-content"
//                   id="panel1d-header"
//                 >
//                   <Grid container spacing={2} alignItems="center">
//                     <Grid item xs={8}>
//                       <Typography className={classes.typography}>
//                         {fileInfo.file.name}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={2}>
//                       <Typography className={classes.typography}>
//                         {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={2}>
//                       <Grid container spacing={2} alignItems="center">
//                         <Grid item>
//                           {isUploading && file.length - 1 === index ? (
//                             <CircularProgress size={12} />
//                           ) : (
//                             <Typography
//                               className={`${classes.typography} ${
//                                 fileInfo.status === "Partial Success" ||
//                                 fileInfo.status === "Failed"
//                                   ? classes.statusFailed
//                                   : classes.statusSuccessful
//                               }`}
//                             >
//                               {fileInfo.status}
//                             </Typography>
//                           )}
//                           {/* <Typography style={statusColor(fileInfo.status)}>
//                             {fileInfo.status}
//                           </Typography> */}
//                         </Grid>
//                         {(fileInfo.status === "Failed" ||
//                           fileInfo.status === "Partial Success") && (
//                           // || withWarning
//                           <Grid item>
//                             <IconButton size="small">
//                               {index === expanded ? (
//                                 <ExpandLess />
//                               ) : (
//                                 <ExpandMore />
//                               )}
//                             </IconButton>
//                           </Grid>
//                         )}
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </ExpansionPanelSummary>
//                 {(fileInfo.status === "Failed" ||
//                   fileInfo.status === "Partial Success") && (
//                   // || withWarning
//                   <ExpansionPanelDetails>
//                     {typeof fileInfo.errors === "string" ? (
//                       <Typography style={{ fontSize: 14, margin: 8 }}>
//                         {fileInfo.errors}
//                       </Typography>
//                     ) : (
//                       <Table aria-label="simple table">
//                         <TableHead>
//                           <TableRow>
//                             <TableCell style={{ width: "100px" }}>
//                               Row
//                             </TableCell>
//                             <TableCell align="left">Reason</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {fileInfo.errors.length > 0 &&
//                             fileInfo.errors
//                               .filter(row => row.errors !== "")
//                               .sort(reorder)
//                               .map(row => (
//                                 <TableRow key={row.name}>
//                                   <TableCell component="th" scope="row">
//                                     <Typography className={classes.typography}>
//                                       {row.row}
//                                     </Typography>
//                                   </TableCell>
//                                   <TableCell align="left">
//                                     {" "}
//                                     <Typography className={classes.typography}>
//                                       {row.errors || row.warnings}
//                                     </Typography>
//                                   </TableCell>
//                                 </TableRow>
//                               ))}
//                         </TableBody>
//                       </Table>
//                     )}
//                   </ExpansionPanelDetails>
//                 )}
//               </ExpansionPanel>
//               // <ExpansionPanel
//               //   square
//               //   expanded={index === expanded}
//               //   onClick={() => handleClick(index)}
//               // >
//               //   <ExpansionPanelSummary
//               //     aria-controls="panel1d-content"
//               //     id="panel1d-header"
//               //     expandIcon={
//               //       (fileInfo.status === "Failed" ||
//               //         fileInfo.status === "Partial Success" ||
//               //         withWarning) && <ExpandMoreIcon />
//               //     }
//               //   >
//               //     <Grid container spacing={2}>
//               //       <Grid item xs={8}>
//               //         <Typography className={classes.typography}>
//               //           {fileInfo.file.name}
//               //         </Typography>
//               //       </Grid>
//               //       <Grid item xs={2}>
//               //         <Typography className={classes.typography}>
//               //           {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
//               //         </Typography>
//               //       </Grid>
//               //       <Grid item xs={2}>
//               //         <Typography className={classes.typography}>
//               //           {fileInfo.status}
//               //         </Typography>
//               //       </Grid>
//               //     </Grid>
//               //   </ExpansionPanelSummary>
//               //   {(fileInfo.status === "Failed" ||
//               //     fileInfo.status === "Partial Success" ||
//               //     withWarning) && (
//               //     <ExpansionPanelDetails>
//               //       {typeof fileInfo.errors === "string" ? (
//               //         <Typography style={{ fontSize: 14, margin: 8 }}>
//               //           {fileInfo.errors}
//               //         </Typography>
//               //       ) : (
//               //         <Table aria-label="simple table">
//               //           <TableHead>
//               //             <TableRow>
//               //               <TableCell style={{ width: "100px" }}>
//               //                 Row
//               //               </TableCell>
//               //               <TableCell align="left">Reason</TableCell>
//               //             </TableRow>
//               //           </TableHead>
//               //           <TableBody>
//               //             {fileInfo.errors.length > 0 &&
//               //               fileInfo.errors
//               //                 .filter(row => row.errors !== "")
//               //                 .sort(reorder)
//               //                 .map(row => (
//               //                   <TableRow key={row.name}>
//               //                     <TableCell component="th" scope="row">
//               //                       <Typography className={classes.typography}>
//               //                         {row.row}
//               //                       </Typography>
//               //                     </TableCell>
//               //                     <TableCell align="left">
//               //                       {" "}
//               //                       <Typography className={classes.typography}>
//               //                         {row.errors || row.warnings}
//               //                       </Typography>
//               //                     </TableCell>
//               //                   </TableRow>
//               //                 ))}
//               //           </TableBody>
//               //         </Table>
//               //       )}
//               //     </ExpansionPanelDetails>
//               //   )}
//               // </ExpansionPanel>
//             );
//           })}
//       </DialogContent>
//       <DialogActions>
//         <div style={{ display: "flex", width: "100%" }}>
//           <label htmlFor="icon-upload-file">
//             <input
//               className="form-control"
//               color="primary"
//               accept=".csv, .xlsx, .xls"
//               type="file"
//               id="icon-upload-file"
//               style={{ display: "none" }}
//               onChange={e => handleUpload(e)}
//             />
//             <Button
//               variant="contained"
//               component="span"
//               className={classes.button}
//               style={{ backgroundColor: "#ffb677" }}
//               startIcon={<PublishIcon />}
//               disabled={pendingFile()}
//             >
//               Add a File
//             </Button>
//           </label>
//           <div style={{ flexGrow: 1 }} />
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center"
//             }}
//           >
//             <Button
//               variant="contained"
//               autoFocus
//               color="primary"
//               startIcon={<CloudUploadIcon />}
//               disabled={
//                 file.length === 0 || file[file.length - 1].status !== "" || load
//                 // ||
//                 // lodash.isEmpty(devicesData)
//               }
//               style={{ color: "white", borderRadius: 50 }}
//               onClick={submitUpload}
//             >
//               {file.length > 0 &&
//               // lodash.isEmpty(devicesData) &&
//               load ? (
//                 <CircularProgress size={12} />
//               ) : (
//                 "Upload"
//               )}
//             </Button>
//           </div>
//         </div>
//       </DialogActions>
//     </Dialog>
//   );
// }

const viewType = {
  LIST: "list",
  MAP: "map"
};

function Devices(props) {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const { history } = props;
  // const context = useContext(LayoutContext);
  const user = JSON.parse(Cookie.get("user"));
  const [view, setView] = useState(viewType.LIST);
  const contextData = useContext(SearchContext);
  const [clearFirst, setClearFirst] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [menuEl, setMenuEl] = useState(false);
  const menuOpen = Boolean(menuEl);
  const [keyword, setKeyword] = useState("");
  const [vars, setVars] = useState({});
  const [bound, setBound] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const defaultGroupFilter = {
    id: user.group_ids[0],
    name: user.group_names[0],
    group_ids: [user.group_ids[0]]
  };

  const [keyDel, setKeyDel] = useState();
  const [keyLog, setKeyLog] = useState("");
  const [selDel, setSelDel] = useState([]);
  const [circularLoading, setCircularLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [unpairData, setUnpairData] = useState();
  const [dialogUnpair, setDialogUnpair] = useState(false);
  const [historySelector, setHistorySelector] = useState(false);

  const { client, group_names, group_ids } = user;
  if (
    group_ids.length > 1 &&
    client &&
    client.name &&
    client.name &&
    +user.user_level_id !== +process.env.REACT_APP_WTI_ADMIN
  ) {
    const index = group_names.indexOf(client.name);
    if (index >= 0) {
      defaultGroupFilter.id = group_ids[index].toString();
      defaultGroupFilter.name = group_names[index];
    }
  }

  // get the saved filter value
  const variable = history?.location?.state?.params;
  const [groupFilter, setGroupFilter] = useState(
    variable?.filter?.groupFilter ?? defaultGroupFilter
  );
  const [filter, setFilter] = useState(() => {
    return ["device_type_id", "gps_provider_id", "status_code_id"].reduce(
      (accum, currKey) => {
        if (variable?.filter?.[currKey]) {
          if (
            currKey === "status_code_id" &&
            variable?.filter?.[currKey] === -1
          )
            return accum;
          accum.push({
            field: currKey,
            value: `${variable?.filter?.[currKey]?.id ??
              variable?.filter?.[currKey]}`
          });
        }

        return accum;
      },
      []
    );
  });
  const [tempFilter, setTempFilter] = useState(() => {
    return (
      variable?.filter ?? {
        device_type_id: "",
        gps_provider_id: "",
        status_code_id: -1,
        groupFilter: defaultGroupFilter
      }
    );
  });
  const [canEdit, setcanEdit] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const bookingRef = useRef();

  const getVariables = () => {
    const { client, group_names, group_ids } = user;
    let defaultGroup = "";
    if (
      group_ids.length > 1 &&
      client &&
      client.name &&
      +user.user_level_id !== +process.env.REACT_APP_WTI_ADMIN
    ) {
      const index = group_names.indexOf(client.name);
      if (index >= 0) defaultGroup = `${group_ids[index]}`;
    }
    const variables = {
      // keyword: keyword,
      skip: 0,
      keyword: keyword,
      first: rowsPerPage,
      // first: rowsPerPage * (page + 1),
      ...(+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN &&
        groupFilter.id && {
          condition: [{ field: "devices_groups.group_id", value: defaultGroup }]
        }),
      dateRange: {
        start: moment().format("YYYY-MM-DD 00:00:00")
      },
      // condition: [{ field: "devices.id", value:  }],
      orderBy: [{ field: orderBy, direction: order }]
    };
    if (groupFilter.id) {
      Object.assign(variables, {
        condition: [
          {
            field: "devices_groups.group_id",
            value:
              variable?.filter?.groupFilter?.group_ids[0]?.toString() ??
              groupFilter.group_ids[0].toString()
          }
        ]
      });
    }

    if (filter.length > 0) {
      if (groupFilter.id) {
        filter.forEach(f => {
          switch (f.field) {
            case "status_code_id":
              {
                variables.condition.push({
                  field: "rover_status.status_code_id",
                  value: f.value
                });
              }
              break;
            default:
              variables.condition.push(f);
              break;
          }
          // variables.condition.push(f);
        });
      } else {
        Object.assign(variables, { condition: filter });
      }
    }
    return variables;
  };

  const { loading, data, fetchMore, refetch } = useQuery(GET_DEVICES, {
    variables: getVariables(),
    onCompleted: () => {
      if (keyword) {
        AddLogs("Admin - GPS Devices", "search", searchKeyword.value);
      }

      // set the saved filter values from history
      // const temp = variable?.vars?.condition;
      // let gtype;
      // let gprovider;
      // let gstat;
      // let group;
      // temp.forEach(gpsfil => {
      //   switch (gpsfil.field) {
      //     case "device_type_id":
      //       gtype = {
      //         field: "device_type_id",
      //         value: gpsfil.value
      //       };
      //       break;
      //     case "gps_provider_id":
      //       gprovider = {
      //         field: "gps_provider_id",
      //         value: gpsfil.value
      //       };
      //       break;
      //     case "rover_status.status_code_id":
      //       gstat = {
      //         field: "rover_status.status_code_id",
      //         value: gpsfil.value
      //       };
      //       break;
      //     default:
      //       break;
      //   }
      // });

      // const removeNull = [gtype, gstat, gprovider, group];
      // const tempVal = variable?.filter;

      // set the values that is not null/undefined
      // setFilter(removeNull.filter(el => el !== null && el !== undefined));
      // set the saved values to the filter field
      // setTempFilter(tempVal);
    },
    fetchPolicy: "cache-and-network",
    // onCompleted: () => {
    //   setPage(0);
    // },
    onError: () => {
      // console.error(err);
    }
  });
  const base_columns = [
    "Client*",
    "IMEI/Serial Number*",
    "Device Alias*",
    "Device Type*",
    "Pair Vehicle/CV",
    "Mobile Number",
    "Main Interval Reporting (min)*",
    "Auxiliary Interval Reporting (min)*",
    "Idle Setting (min)*",
    "Auxiliary Voltage (mV)*",
    "GPS Provider*",
    "Date Installed",
    "Warranty",
    "Expiration Date",
    "Secondary Client",
    "Mirror to NGT",
    "Remarks"
  ];
  const columns = process.env.REACT_APP_DEVICES_KEY.split(",").reduce(
    (accumulated, current, index) => {
      // eslint-disable-next-line no-param-reassign
      accumulated[base_columns[index]] = current;

      return accumulated;
    },
    {}
  );

  const [uploadDevices, { loading: uploading }] = useMutation(ADD_DEVICES);
  const [overrideDevices, { loading: overriding }] = useMutation(EDIT_DEVICES);

  // const { data: devicesData, loading: loadingDevicesData } = useQuery(
  //   GET_DEVICES,
  //   {
  //     variables: { first: 10000 },
  //     fetchPolicy: "network-only"
  //   }
  // );

  const [getSelected] = useLazyQuery(GET_DEVICES, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_devices.devices.map(val => {
          temp.push(val.name);
          return temp;
        });
        setSelDel(temp);
        deleteDeviceAll({
          variables: { condition: { field: "id", value: selected } }
        });
      }
    }
  });

  // const { loading: groupsLoading, data: groupsData } = useQuery(GET_GROUPS, {
  //   // variables: { first: 10000 },
  //   onCompleted: res => {
  //     // to set WTI admin default groupFilter to webcast
  //     let index = -1;
  //     const groups = res.get_groups.groups.map(groups => groups.name);
  //     index = groups.indexOf("Webcast");
  //     if (
  //       index > -1 &&
  //       user.user_level_id === process.env.REACT_APP_WTI_ADMIN
  //     ) {
  //       setGroupFilter(res.get_groups.groups[index]);
  //     }
  //   }
  // });

  const { loading: deviceTypesLoading, data: deviceTypesData } = useQuery(
    GET_DEVICE_TYPES
  );

  const {
    loading: gpsProvidersLoading,
    data: gpsProvidersData
  } = useQuery(GET_GPS_PROVIDERS, { variables: { first: 50 } });

  const { loading: clientLoading, data: clientData } = useQuery(
    GET_ADMIN_CLIENT,
    {
      variables: { id: user.client.id },
      fetchPolicy: "network-only"
    }
  );

  const handleOpenUpload = () => {
    setUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setUploadDialog(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_devices: {
            count: fetchMoreResult.get_devices.count,
            total: fetchMoreResult.get_devices.total,
            devices: [
              ...prev.get_devices.devices,
              ...fetchMoreResult.get_devices.devices
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(1);
          refetch(getVariables());
        }
      });
    });
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    // if (event.target.checked) {
    //   const newSelecteds = data.get_devices.devices.map(n => n.id);
    //   setSelected(newSelecteds);
    //   return;
    // }
    // setSelected([]);

    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleCloseUnpair = () => {
    setDialogUnpair(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleSetFilters = () => {
    const temp = [];
    let device = "";
    let provider = "";
    let gps_status = "";

    const { groupFilter, ...tempFilterCopy } = tempFilter;
    if (
      Object.keys(tempFilterCopy)
        .filter(key => tempFilterCopy[key])
        .some(key => tempFilterCopy[key] !== "")
    ) {
      Object.keys(tempFilterCopy)
        .filter(key => tempFilterCopy[key])
        .map(key => {
          if (key === "device_type_id") {
            device = tempFilterCopy[key].name;
          } else if (key === "status_code_id") {
            if (tempFilterCopy[key] === -1) return undefined;
            gps_status = tempFilterCopy[key];
          } else {
            provider = tempFilterCopy[key].name;
          }
          temp.push({
            field: `${key}`,
            value:
              typeof tempFilterCopy[key] !== "object"
                ? tempFilterCopy[key]
                : tempFilterCopy[key].id
          });
          return temp;
        });
    }
    setFilter(temp);
    if (variable?.filter?.groupFilter)
      variable.filter.groupFilter = groupFilter;
    setGroupFilter(groupFilter);
    setKeyLog(
      `${device ? `Device Type: ${device}, ` : ""}
       ${provider ? `GPS Provider: ${provider}` : ""}
       ${gps_status ? `GPS Status: ${gps_status}` : ""}`
    );
    AddLogs(
      "Admin - GPS Devices",
      "filter",
      `${device ? `Device Type: ${device}, ` : ""}${
        provider ? `GPS Provider: ${provider}` : ""
      }`
    );
    setPage(1);
    closeFilterDrawer();
  };

  const handleClearFilter = () => {
    if (variable?.filter?.groupFilter)
      variable.filter.groupFilter = defaultGroupFilter;
    setFilter([]);
    setTempFilter({
      ...tempFilter,
      device_type_id: "",
      gps_provider_id: "",
      status_code_id: -1,
      groupFilter: defaultGroupFilter
    });
    setGroupFilter(defaultGroupFilter);
  };

  // const [editDevice] = useMutation(EDIT_DEVICE, {
  //   onCompleted(data) {
  //     setUnpairData();
  //     setDialogUnpair(false);
  //     if (data.edit_device.success) {
  //       AddLogs(
  //         "Admin - GPS Devices",
  //         "unpaired_device",
  //         [unpairData.devicealias, unpairData.vehicle_info.plateno]
  //       );

  //       Swal.fire({
  //         title: "Device was unpaired",
  //         icon: "success",
  //         showConfirmButton: false,
  //         timer: 3000,
  //         onClose: () => {
  //           refetch(getVariables());
  //         }
  //       });
  //     } else {
  //       refetch(getVariables());
  //     }
  //   }
  // });

  const processUnpairVehicle = async () => {
    const updateVehicleResult = await unpairDeviceFromVehicle({
      vehicle_id: unpairData.vehicle_info.id,
      name: unpairData.name
    });
    setUnpairData();

    if (updateVehicleResult.success) {
      Swal.fire({
        icon: "success",
        text: "Device was unpaired",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: `${classes.swal}`
        },
        onClose: () => {
          refetch(getVariables());
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false,
        customClass: {
          container: `${classes.swal}`
        }
      });
    }
  };

  const handleUnpaired = async () => {
    setDialogUnpair(false);
    // Reset future trips before editing vehicle
    if (bookingRef.current?.futureTrips?.forReset.length) {
      const resetResult = await resetTrips(
        bookingRef.current.futureTrips.forReset
      );

      if (resetResult.success) {
        processUnpairVehicle();
        setMenuEl(null);
      } else {
        Swal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            container: `${classes.swal}`
          }
        });
      }
    } else {
      processUnpairVehicle();
      setMenuEl(null);
    }
  };

  const [deleteDevice] = useMutation(DELETE_DEVICE, {
    onCompleted(data1) {
      if (data1.delete_device.success) {
        AddLogs("Admin - GPS Devices", "delete", keyDel);
        setMenuEl(null);
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setPage(1);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    toDelete.map(row_id =>
      deleteDevice({
        variables: { id: row_id },
        refetchQueries: ["deleteDevice"]
      })
    );
  };

  const [deleteDeviceAll] = useMutation(DELETE_DEVICE_ALL, {
    onCompleted(data1) {
      if (data1.delete_device_all.success) {
        AddLogs("Admin - GPS Devices", "delete", selDel);
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setSelected([]);
            setPage(1);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setSelected([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDeleteAll = () => {
    const temp = [];
    if (selected.length > 0) {
      selected.map(id => temp.push(id));
      getSelected({
        variables: {
          filter: { field: "devices.id", value: temp },
          first: selected.length
        }
      });
    }
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const getData = () => {
    return stableSort(
      data.get_devices.devices,
      getSorting(order, orderBy)
    ).slice(
      (Number(page) - 1) * rowsPerPage,
      (Number(page) - 1) * rowsPerPage + rowsPerPage
    );
  };

  const dataToArray = () => {
    // Excel column names (downloaded file)
    const temp = [
      [
        "IMEI/Serial Number",
        "Device Alias",
        "Device Type",
        "Pair Vehicle/CV",
        "Battery level",
        "GPS Provider",
        "Status"
      ]
    ];

    // Parsing gps devices data (Excel data)
    return getData().reduce((accum, row) => {
      const { vehicle_info, device_type_id, device_status } = row;
      const battery_level =
        vehicle_info?.vehicle_type &&
        vehicle_info?.vehicle_type.toLowerCase() === "container van" &&
        device_type_id === 27
          ? `${device_status.battery_level}%`
          : "N/A";

      const gps_status = gpsStatus(
        device_status?.status_code ? device_status.status_code : "no gps"
      ).label;

      return [
        ...accum,
        [
          row.name,
          row.devicealias,
          row.device_type,
          row?.vehicle_info?.plateno,
          battery_level,
          row.device_config?.gps_provider,
          gps_status
        ]
      ];
    }, temp);
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    // if (searchKeyword.moduleName) {
    //   // setSearchKeyword({ ...searchKeyword, moduleName: "" });
    // } else {
    //   setSearchKeyword({ ...searchKeyword, value: "" });
    // }
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      // retaing the filter value when searching
      // setFilter({ device_type_id: "", gps_provider_id: "" });
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  useEffect(() => {
    if (data) {
      const { get_devices } = data;
      setTotalPage(
        get_devices.total ? Math.ceil(get_devices.total / rowsPerPage) : 1
      );
    }
  }, [data, circularLoading]);

  const validateCell = (key, value) => {
    let val = "";
    switch (key) {
      case "client_name":
        if (!value) {
          val = { error: "Client is required" };
        } else {
          val = value;
        }
        break;
      case "devicealias":
        if (!value.toString().trim()) {
          val = { error: "Device Alias is required" };
        } else {
          val = value.toString().trim();
        }
        break;
      case "device_type":
        if (!value) {
          val = { error: "Device Type is required" };
        } else {
          val = value;
        }
        break;
      case "name":
        if (!value.toString().trim()) {
          val = { error: "IMEI/Serial number is required" };
        } else {
          val = value.toString().trim();
        }
        break;
      case "vehicle_info":
        if (!value) {
          val = "";
        } else {
          val = validate("alphanumeric", `${value}`)
            ? value
            : { error: "Paired vehicle/cv is invalid format" };
        }
        break;
      case "intervalreporting":
        if (!value && value !== 0) {
          val = { error: "Main Interval reporting (min) is required" };
        } else if (validate("numeric", value)) {
          if (value > 0 && value <= 10_080) {
            val = parseInt(value, 10);
          } else {
            val = {
              error: "Main Interval Reporting (min) value min: 1, max: 10,080"
            };
          }
        } else {
          val = { error: "Main Interval reporting (min) invalid format" };
        }
        break;
      case "battery_intervalreporting":
        if (!value && value !== 0) {
          val = { error: "Auxiliary Interval reporting (min) is required" };
        } else if (validate("numeric", value)) {
          if (value > 0 && value <= 10_080) {
            val = parseInt(value, 10);
          } else {
            val = {
              error:
                "Auxiliary Interval Reporting (min) value min: 1, max: 10,080"
            };
          }
        } else {
          val = {
            error: "Auxiliary Interval Reporting (min) is invalid format"
          };
        }
        break;
      case "idlesetting":
        if (!value && value !== 0) {
          val = { error: "Idle Setting (min) is required" };
        } else if (validate("numeric", value)) {
          if (value > 0 && value <= 10_080) {
            val = parseInt(value, 10);
          } else {
            val = {
              error: "Idle Setting (min) value min: 1, max: 10,080"
            };
          }
        } else {
          val = { error: "Idle Setting (min) is invalid format" };
        }
        break;
      case "aux_voltage":
        if (value !== null) {
          if (validate("numeric", value)) {
            if (value > 0 && value <= 10_080) {
              val = parseInt(value, 10);
            } else {
              val = {
                error: "Auxiliary Voltage (mV) value min: 1, max: 10,080"
              };
            }
          } else {
            val = { error: "Auxiliary Voltage (mV) is invalid format" };
          }
        } else {
          val = "";
        }
        break;
      case "gps_provider":
        if (!value) {
          val = { error: "GPS Provider is required" };
        } else {
          val = value;
        }
        break;
      case "dateinstalled":
        if (value?.trim() === "") {
          val = moment().format("YYYY-MM-DD");
        } else {
          const temp = moment(parseDateExcel(value?.trim())).format(
            "YYYY-MM-DD"
          );
          if (temp.toLowerCase() === "invalid date") {
            val = { error: "Date installed is invalid format" };
          } else {
            val = temp;
          }
        }
        break;
      case "expirationdate":
        if (value?.trim() === "") {
          val = moment().format("YYYY-MM-DD");
        } else {
          const temp = moment(parseDateExcel(value?.trim())).format(
            "YYYY-MM-DD"
          );
          if (temp.toLowerCase() === "invalid date") {
            val = { error: "Expiration Date is invalid format" };
          } else {
            val = temp;
          }
        }
        break;
      case "warranty":
        if (value?.trim() === "") {
          val = moment().format("YYYY-MM-DD");
        } else {
          const temp = moment(parseDateExcel(value?.trim())).format(
            "YYYY-MM-DD"
          );
          if (temp.toLowerCase() === "invalid date") {
            val = { error: "Warranty is invalid format" };
          } else {
            val = temp;
          }
        }
        break;
      case "remarks":
        if (value) {
          val = validate("alphanumeric_default_with_space", value)
            ? value.toString().trim()
            : { error: "Remarks is invalid format" };
        } else {
          val = "";
        }
        break;
      case "group_names":
        if (value) {
          const arrData = value
            .toString()
            .toLowerCase()
            .replaceAll(/, /g, "^ ")
            .split(",")
            .map(data => data.replaceAll("^", ","));
          val = arrData;
        } else {
          val = [];
        }
        break;
      case "mirror_to_ngt":
        if (value) {
          const mirrorToNgt = value.toString().toLowerCase();
          if (mirrorToNgt === "yes") {
            val = true;
          } else if (mirrorToNgt === "no") {
            val = false;
          } else {
            val = { error: "Mirror to NGT is invalid" };
          }
        } else {
          val = false;
        }
        break;
      case "mobileno":
        if (value) {
          const mobilenoStr = value.toString();
          const mobilenoStart = mobilenoStr.startsWith("+639");
          const beginWith63 = mobilenoStart
            ? mobilenoStr
            : mobilenoStr.replace(/^(0?9|639)/, "+639");
          val = validate("mobile_number_ph", beginWith63)
            ? beginWith63
            : { error: "Invalid Mobile Number" };
        } else {
          val = "";
        }
        break;
      default:
        break;
    }
    return val;
  };

  // reset the default values of the filter field
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: "GPS Devices"
        }
      }
    });
  };

  const currentDeviceRef = useRef({});
  const muiMenu = () => {
    if (!currentDeviceRef.current) return null;

    return (
      <Menu
        id="long-menu"
        anchorEl={menuEl}
        keepMounted
        open={menuOpen}
        onClose={() => {
          setMenuEl(null);
          currentDeviceRef.current = null;
        }}
        PaperProps={{
          style: {
            width: "20ch",
            boxShadow: "0px 6px 16px -2px rgba(140,140,140,0.75)"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            setSearchKeyword({
              ...searchKeyword,
              value: ""
            });
            AddLogs(
              "Admin - GPS Devices",
              "view",
              currentDeviceRef.current.name || "-"
            );
            // save the current filter values before redirecting
            history.push({
              pathname: `/admin/resources/devices/view/${currentDeviceRef.current.id}`,
              state: {
                vars: getVariables(),
                filter: tempFilter
              }
            });
          }}
        >
          <VisibilityIcon className={classes.actionIcons} />
          <Typography className={classes.optionsItem}>View</Typography>
        </MenuItem>
        <MenuItem
          disabled={!canEdit}
          onClick={() => {
            handleToDelete(currentDeviceRef.current.id);
            handleOpen();
            setKeyDel(currentDeviceRef.current.name);
          }}
        >
          <DeleteIcon className={classes.actionIcons} />
          <Typography className={classes.optionsItem}>Delete</Typography>
        </MenuItem>
        <MenuItem onClick={() => setHistorySelector(true)}>
          <HistoryIcon className={classes.actionIcons} />
          <Typography className={classes.optionsItem}>View History</Typography>
        </MenuItem>
        <MenuItem
          disabled={!currentDeviceRef.current.vehicle_info?.plateno}
          onClick={async () => {
            const trips = await getBookingsOfVehicle(
              currentDeviceRef.current.id,
              currentDeviceRef.current.vehicle_info.plateno
            );
            bookingRef.current = trips;

            if (trips.error) {
              Swal.fire({
                icon: "error",
                text: "Something went wrong",
                timer: 1500,
                showConfirmButton: false
              });
            } else {
              setUnpairData(currentDeviceRef.current);
              setDialogUnpair(true);
            }
          }}
        >
          <svg
            className={classes.actionIcons}
            width="20"
            height="20"
            viewBox="0 0 1024 1024"
            fill={
              currentDeviceRef.current.vehicle_info?.plateno
                ? "#000000"
                : "none"
            }
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
              strokeWidth="8"
              stroke={
                currentDeviceRef.current.vehicle_info?.plateno
                  ? "#757575"
                  : "#000000"
              }
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography className={classes.optionsItem}>Unpair</Typography>
        </MenuItem>
      </Menu>
    );
  };

  // useEffect(() => {
  //   if (contextData.searchKeyword.submit) {
  //     setKeyword(contextData.searchKeyword.value);
  //   }
  // }, [contextData]);

  return loading ||
    gpsProvidersLoading ||
    deviceTypesLoading ||
    // groupsLoading ||
    clientLoading ? (
    <Loading />
  ) : (
    <AccessControl
      resource="/admin/wti/devices/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            // groupsData={groupsData}
            setGroupFilter={setGroupFilter}
            groupFilter={groupFilter}
            user={user}
            view={view}
            setView={setView}
          >
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                variant="contained"
                component="span"
                className={classes.btn_upload}
                startIcon={<PublishIcon />}
                onClick={handleOpenUpload}
              >
                Upload
              </Button>
            )}
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={openFilterDrawer}>
                <Badge
                  color="secondary"
                  variant={
                    getVariables()?.condition?.length > 1 ? "dot" : "standard"
                  }
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            {view === viewType.LIST && (
              <Pagination
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                dataLength={data?.get_devices.devices.length}
                fetchMoreData={fetchMoreData}
                totalRows={data?.get_devices.total}
                totalPage={totalPage}
                refetch={refetch}
                getVariables={getVariables}
                setRowsPerPage={setRowsPerPage}
                // setCircularLoading={setCircularLoading}
                setLoading={setCircularLoading}
              />
            )}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) === 1}
                onClick={() => handleDownPage()}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                readOnly
                onFocus={e => {
                  e.target.select();
                }}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                // value={!data ? 0 : page + 1}
                value={page}
                style={{ width: "32px", marginRight: "8px" }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
              />
              {`/ ${totalPage}`}
              {/* {`/ ${
                data ? Math.ceil(data.get_devices.total / rowsPerPage) : 0
              }`} *
              <Button
                name="up_page"
                // disabled={
                //   data
                //     ? page + 1 ===
                //       Math.ceil(
                //         data.get_devices.total / rowsPerPage ||
                //           data.get_devices.total === 0
                //       )
                //     : true
                // }
                disabled={Number(page) === totalPage}
                onClick={() => handleUpPage()}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div> */}
          </EnhancedTableToolbar>
          {selected.length > 0 && (
            <Paper
              style={{
                display: "flex",
                backgroundColor: "#eaeaea",
                paddingLeft: 16,
                position: "relative",
                minHeight: "64px",
                alignItems: "center"
              }}
              elevation={0}
              square
            >
              <Typography>
                {selected.length} device/s is selected on this page
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  borderRadius: 100,
                  color: "white"
                }}
                onClick={handleOpenDeleteAll}
              >
                Delete Selected
              </Button>
            </Paper>
          )}
          {view === viewType.MAP ? (
            <Map
              filter={{ condition: filter, keyword }}
              bound={bound}
              setBound={setBound}
              drawing={drawing}
              setDrawing={setDrawing}
              clientSelection={groupFilter}
              total={data?.get_devices?.total ?? 0}
            />
          ) : (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data ? data.get_devices.total : 0}
                  handleSort={handleSort}
                  canEdit={canEdit}
                  isChecked={isChecked}
                />
                <TableBody>
                  {data &&
                    data.get_devices.devices &&
                    getData().map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        row && (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                disabled={!canEdit}
                                onClick={event => handleClick(event, row.id)}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align="left"
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row?.name ? row.name : ""}
                              </Highlight>
                            </TableCell>
                            <TableCell>
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row?.devicealias ? row.devicealias : ""}
                              </Highlight>
                            </TableCell>
                            <TableCell>
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row?.device_type ? row.device_type : ""}
                              </Highlight>
                            </TableCell>
                            <TableCell>
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row?.vehicle_info
                                  ? row.vehicle_info.plateno
                                  : ""}
                              </Highlight>
                            </TableCell>
                            <TableCell>
                              <Battery
                                vehicleType={row.vehicle_info?.vehicle_type}
                                deviceTypeId={row.device_type_id}
                                batteryLevel={row.device_status?.battery_level}
                                tooltipTitle={
                                  <>
                                    <p
                                      className={classes.tooltipTitle}
                                      style={{ fontWeight: "bold" }}
                                    >{`Battery Life: ${row.device_status?.battery_level}%`}</p>
                                    <p className={classes.tooltipTitle}>
                                      {`As of: ${moment(
                                        row.device_status?.reportstamp
                                      ).format("lll")}
                                  `}
                                    </p>
                                  </>
                                }
                                showText
                                showNAText
                              />
                            </TableCell>
                            <TableCell>
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row?.device_config?.gps_provider
                                  ? row.device_config.gps_provider
                                  : ""}
                              </Highlight>
                            </TableCell>
                            <TableCell
                              style={{
                                color: gpsStatus(
                                  row?.device_status?.status_code
                                    ? row.device_status.status_code
                                    : "no gps"
                                ).color
                              }}
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {
                                  gpsStatus(
                                    row?.device_status?.status_code
                                      ? row.device_status.status_code
                                      : "no gps"
                                  ).label
                                }
                              </Highlight>
                            </TableCell>
                            <TableCell>
                              {row?.device_status?.lastlocation}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={event => {
                                  setMenuEl(event.currentTarget);
                                  currentDeviceRef.current = row;
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                              {/* <IconButton
                              onClick={() => {
                                setSearchKeyword({
                                  ...searchKeyword,
                                  value: ""
                                });
                                AddLogs(
                                  "Admin - GPS Devices",
                                  "view",
                                  row.name || "-"
                                );
                                // save the current filter values before redirecting
                                history.push({
                                  pathname: `/admin/wti/devices/view/${row.id}`,
                                  state: {
                                    vars: getVariables(),
                                    filter: tempFilter
                                  }
                                });
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              disabled={!canEdit}
                              onClick={() => {
                                handleToDelete(row.id);
                                handleOpen();
                                setKeyDel(row.name);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              disabled={!row.vehicle_info?.plateno}
                              onClick={async () => {
                                const trips = await getBookingsOfVehicle(
                                  row.id,
                                  row.vehicle_info.plateno
                                );
                                bookingRef.current = trips;

                                if (trips.error) {
                                  Swal.fire({
                                    icon: "error",
                                    text: "Something went wrong",
                                    timer: 1500,
                                    showConfirmButton: false
                                  });
                                  return;
                                } else {
                                  setUnpairData(row);
                                  setDialogUnpair(true);
                                }
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 1024 1024"
                                fill={
                                  row.vehicle_info?.plateno ? "#000000" : "none"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"
                                  stroke-width="8"
                                  stroke={
                                    row.vehicle_info?.plateno
                                      ? "#757575"
                                      : "#000000"
                                  }
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton> */}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                </TableBody>
              </Table>
              {muiMenu()}
              {data && data.get_devices.count === 0 && (
                <div
                  style={{
                    width: "100%",
                    height: 72,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography>No data found</Typography>
                </div>
              )}
            </TableContainer>
          )}
        </Paper>
        {view === viewType.LIST && (
          <div className={classes.fab}>
            <Paper
              variant="outlined"
              style={{
                height: "50px",
                width: "200px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>{`${
                data ? data.get_devices.total : "-"
              } / ${
                clientData ? clientData.get_client.device_count : "-"
              } devices created`}</Typography>
            </Paper>
            {canEdit && (
              <Fab
                size="large"
                style={{
                  right: 10
                }}
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  setSearchKeyword({ ...searchKeyword, value: "" });
                  // save the current filter values before redirecting
                  history.push({
                    pathname: "/admin/resources/devices/add",
                    state: {
                      vars: getVariables(),
                      filter: tempFilter
                    }
                  });
                }}
                disabled={
                  data && clientData
                    ? data.get_devices.total >=
                      clientData.get_client.device_count
                    : false
                }
              >
                <AddIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "white"
                  }}
                />
              </Fab>
            )}
          </div>
        )}

        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_GPSDEVICES_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          {/* <FormControl style={{ width: "85%" }}>
            <InputLabel shrink required>
              Device Type
            </InputLabel>
            <Select
              value={tempFilter.device_type_id}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="device_type_id"
              fullWidth
            >
              <MenuItem value="" disabled>
                <em style={{ color: "#8e8e8e" }}>Select Device Type</em>
              </MenuItem>
              {deviceTypesData.get_device_types.device_types.map(
                gps_provider => (
                  <MenuItem value={gps_provider.id} key={gps_provider.id}>
                    {gps_provider.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl> */}
          <Autocomplete
            options={
              deviceTypesData
                ? deviceTypesData.get_device_types.device_types
                : []
            }
            getOptionLabel={option => option.name}
            getOptionSelected={(o, v) => {
              // if (o.name === v.name) return o.name;
              // return {};
              return o.name === v.name;
            }}
            renderOption={option => option.name}
            style={{ width: "100%" }}
            renderInput={params => (
              <TextField
                {...params}
                classes={{ root: formStyles.textFieldRoot }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    padding: "6px 0px"
                  }
                }}
                label={
                  <>
                    Device Type <span style={{ color: "red" }}>*</span>
                  </>
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                placeholder="Select Device Type"
              />
            )}
            name="device_type_id"
            // onChange={(e, nv) => handleFilters(e)}
            onChange={(e, nv) => {
              setTempFilter({ ...tempFilter, device_type_id: nv });
              // clearHistory();
            }}
            value={tempFilter.device_type_id ? tempFilter.device_type_id : null}
            disableClearable
          />
          {/* <FormControl style={{ width: "85%" }}>
            <InputLabel shrink required>
              GPS Provider
            </InputLabel>
            <Select
              value={tempFilter.gps_provider_id}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="gps_provider_id"
              fullWidth
            >
              <MenuItem value="" disabled>
                <em style={{ color: "#8e8e8e" }}>Select GPS Provider</em>
              </MenuItem>
              {gpsProvidersData.get_gps_providers.gps_providers.map(
                gps_provider => (
                  <MenuItem value={gps_provider.id} key={gps_provider.id}>
                    {gps_provider.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl> */}
          <Autocomplete
            options={
              gpsProvidersData
                ? gpsProvidersData.get_gps_providers.gps_providers
                : []
            }
            getOptionLabel={option => option.name}
            getOptionSelected={(o, v) => {
              // if (o.name === v.name) return o.name;
              // return {};
              return o.name === v.name;
            }}
            renderOption={option => option.name}
            style={{ width: "100%" }}
            renderInput={params => (
              <TextField
                {...params}
                classes={{ root: formStyles.textFieldRoot }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    padding: "6px 0px"
                  }
                }}
                label={
                  <>
                    GPS Provider <span style={{ color: "red" }}>*</span>
                  </>
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                placeholder="Select GPS Provider"
              />
            )}
            name="gps_provider_id"
            // onChange={(e, nv) => handleFilters(e)}
            onChange={(e, nv) => {
              setTempFilter({ ...tempFilter, gps_provider_id: nv });
              // clearHistory();
            }}
            value={
              tempFilter.gps_provider_id ? tempFilter.gps_provider_id : null
            }
            // value={vType}
            // onChange={(e, nv) => setVtype(nv)}
            disableClearable
          />
          <FormControl
            style={{ width: "100%", minWidth: "auto", marginTop: 16 }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>GPS Status</InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.status_code_id}
              onChange={e => {
                setTempFilter(prev => ({
                  ...prev,
                  status_code_id: e.target.value
                }));
                // clearHistory();
              }}
              displayEmpty
              name="status_code_id"
              fullWidth
            >
              {[
                { value: -1, label: "All" },
                { value: "999", label: "Online" },
                { value: "777", label: "Offline" },
                { value: "888", label: "Delayed Reporting" }
                // { value: "0", label: "No GPS" }
              ].map(opt => (
                <MenuItem value={opt.value} key={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {user.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
            <ClientAutocomplete
              setter={v => setTempFilter(prev => ({ ...prev, groupFilter: v }))}
              groupFilter={tempFilter.groupFilter}
              style={{ width: "100%" }}
              className={formStyles.textFieldRoot}
              fieldName={
                <p>
                  Client <span style={{ color: "red" }}>*</span>
                </p>
              }
              inputLabelStyle={{
                shrink: true,
                style: {
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: 1
                }
              }}
            />
          )}
        </Filter>
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this device?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteAll(e)}
          title="Delete?"
          content="Are you sure you want to delete these devices?"
        />
        <UnpairingDialog
          toggle={dialogUnpair}
          close={() => handleCloseUnpair()}
          fn={e => handleUnpaired(e)}
          referenceData="device"
          tripInfo={{
            trips: bookingRef.current,
            devicealias: unpairData?.devicealias || "",
            plateno: unpairData?.vehicle_info?.plateno || ""
          }}
        />
        <HistorySelector
          open={historySelector}
          vehicle={{
            client_id: currentDeviceRef?.current?.client_id,
            device_id: currentDeviceRef?.current?.id,
            plateno: currentDeviceRef?.current?.vehicle_info?.plateno,
            group_ids: currentDeviceRef?.current?.group_ids,
            id: currentDeviceRef?.current?.vehicle_info?.id,
            devicealias: currentDeviceRef?.current?.devicealias
          }}
          onClose={() => {
            setHistorySelector(false);
          }}
          title="History"
          isFromDevices
        />

        <Upload
          open={uploadDialog}
          onDialogClose={handleCloseUpload}
          loading={uploading || overriding}
          columns={columns}
          validateTemplate={(col1, col2) => {
            return (
              col1.join(",") === col2.join(",") &&
              col1.length > 0 &&
              col2.length > 0
            );
          }}
          validate={columnValues => {
            const validateResult = {
              valid: true,
              data: {},
              errors: {}
            };
            Object.keys(columnValues.data).forEach(key => {
              const result = validateCell(key, columnValues.data[key]);
              if (typeof result === "object" && !Array.isArray(result)) {
                validateResult.data[key] = columnValues.data[key];
                validateResult.errors[key] = result.error;
              } else {
                validateResult.data[key] = result;
              }
            });
            return validateResult;
          }}
          fileRules={{
            minLength: 1,
            maxLength: 1001
          }}
          getDocumentStatus={({ document, rawData: rows }) => {
            let isPartialSuccess = false;
            const numberOfErrors = Object.keys(
              document.errors.fields.reduce((accum, current) => {
                if (!accum[current.row]) {
                  if (current.success === null || !current.success) {
                    accum[current.row] = "__placeholder__";
                  } else if (current.success && current.message) {
                    accum[current.row] = "__placeholder__";
                    isPartialSuccess = true;
                  }
                }
                return accum;
              }, {})
            ).length;

            if (isPartialSuccess) return status.PARTIAL_SUCCESS;

            if (numberOfErrors === 0) return status.SUCCESS;
            if (rows.length !== numberOfErrors) return status.PARTIAL_SUCCESS;
            if (rows.length === numberOfErrors) return status.FAILED;

            return status.FAILED;
          }}
          onSubmit={async ({ validRowsObject }) => {
            const payload = validRowsObject.map(({ data }) => {
              const {
                client_name,
                name,
                remarks,
                devicealias,
                device_type,
                mirror_to_ngt,
                group_names,
                vehicle_info: plateno,
                ...device_config
              } = data;

              return {
                client_name,
                name: name.toString(),
                remarks,
                devicealias,
                device_type,
                mirror_to_ngt,
                group_names,
                vehicle_info: {
                  plateno
                },
                device_config
              };
            });

            const response = await uploadDevices({
              variables: {
                devices: payload
              }
            });

            const { data } = response;

            const { add_devices } = data;
            const errors = add_devices.reduce(
              (accum, mutation, currentIndex) => {
                mutation.error.forEach(error => {
                  const { field, message, override, id } = error;
                  const { row, data: payloadData } = validRowsObject[
                    currentIndex
                  ];
                  accum.push({
                    id,
                    row,
                    data: payloadData,
                    field,
                    message,
                    override,
                    success: mutation.success
                  });
                });
                return accum;
              },
              []
            );

            return {
              errors
            };
          }}
          onOverride={async forOverrideItems => {
            const overrideItems = forOverrideItems.map(({ id, data }) => {
              const {
                client_name,
                name,
                remarks,
                devicealias,
                device_type,
                mirror_to_ngt,
                group_names,
                vehicle_info: plateno,
                ...device_config
              } = data;
              return {
                id,
                device: {
                  client_name,
                  name: name.toString(),
                  remarks,
                  devicealias,
                  device_type,
                  mirror_to_ngt,
                  group_names,
                  vehicle_info: {
                    plateno
                  },
                  device_config
                }
              };
            });
            const response = await overrideDevices({
              variables: {
                devices: overrideItems
              }
            });

            const { data } = response;
            const { edit_devices: overrideResponse } = data;
            const errors = overrideResponse.reduce(
              (accum, mutation, currentIndex) => {
                const { error } = mutation;
                error.forEach(e => {
                  const { row } = forOverrideItems[currentIndex];
                  accum.push({ row, success: mutation.success, ...e });
                });
                return accum;
              },
              []
            );

            return {
              errors
            };
          }}
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleClosePopper={handleClosePopper}
            handleListKeyDown={handleListKeyDown}
            tableData={dataToArray()}
            keyLog={keyLog}
          />
        )}
      </div>
    </AccessControl>
  );
}

export default withRouter(Devices);
