import { makeStyles } from "@material-ui/core";

const getCurvesStyles = key => {
  const origs = {
    left: "-40%",
    top: "45%",
    // right: "auto",
    // bottom: "auto"
  };
  const styles = [
    {
      ...origs,
      top: "15%"
    },
    {
      ...origs,
      top: "65%",
      // right: "-40%",
      // bottom: "15%",
      left: "70%"
    },
    {
      ...origs,
      top: "15%"
    },
    {
      ...origs,
      top: "30%",
      // right: "-45%",
      left: "70%"
    }
  ];

  return styles[key] || origs;
};
const getBeforeStyles = key => {
  const origs = {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  };
  const styles = [
    {
      ...origs
    },
    {
      ...origs,
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%"
    },
    {
      ...origs
    },
    {
      ...origs
    }
  ];

  return styles[key] || origs;
};
const getAfterStyles = key => {
  const origs = {
    bottom: -50,
    borderTopLeftRadius: "50%",
    borderTopRightRadius: "50%",
    transform: "scale(1.05)",
    background: "#ffffff"
  };
  const styles = [
    {
      ...origs,
      bottom: -60,
      borderTopLeftRadius: 0,
      transform: "scale(1.5) rotate(7deg)"
    },
    {
      ...origs,
      background: "transparent"
    },
    {
      ...origs,
      bottom: -60,
      borderTopLeftRadius: 0,
      transform: "scale(1.5) rotate(-7deg)"
    },
    {
      ...origs,
      borderTopLeftRadius: "0%",
      borderTopRightRadius: "150%"
    }
  ];

  return styles[key] || origs;
};

const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "100%"
  },
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1301,
    minHeight: "500px",
    height: "500px",
    minWidth: "350px",
    maxWidth: "350px",
    // padding: 14,
    background: "white",
    borderRadius: 12,
    overflow: "hidden",
    isolation: "isolate"
  },
  heading: {
    position: "absolute",
    height: "40%",
    width: "100%",
    transition: "background-color 500ms ease-in",
    backgroundColor: props => {
      const primary = props.page + 1;
      return `rgba(244, 148, 0, 0.${5 + primary})`;
    },
    // backgroundImage: props => {
    //   const primary = props.page + 1;
    //   const primaryColor = primary <= 0 ? 1 : primary;
    //   return `linear-gradient(129deg, rgb(244 148 0 / 37%) , rgb(244 148 0 / ${25 *
    //     primaryColor}%))`;
    // },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: props => getAfterStyles(props.page).bottom,
      width: "100%",
      height: "50px",
      background: props => getAfterStyles(props.page).background,
      transition: "border-radius 500ms ease-in",
      borderTopLeftRadius: props =>
        getAfterStyles(props.page).borderTopLeftRadius,
      borderTopRightRadius: props =>
        getAfterStyles(props.page).borderTopRightRadius,
      transform: props => getAfterStyles(props.page).transform
    },
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: -50,
      width: "100%",
      height: "50px",
      transition: "border-radius 500ms ease-in, background-color 500ms ease-in",
      backgroundColor: props => {
        const primary = props.page + 1;
        return `rgba(244, 148, 0, 0.${5 + primary})`;
      },
      // backgroundImage: "linear-gradient(18deg, #f494005e , #F49400)",
      borderBottomLeftRadius: props =>
        getBeforeStyles(props.page).borderBottomLeftRadius,
      borderBottomRightRadius: props =>
        getBeforeStyles(props.page).borderBottomRightRadius
    }
  },
  curves: {
    position: "absolute",
    transition: "all 200ms ease-out",
    top: props => getCurvesStyles(props.page).top,
    // right: props => getCurvesStyles(props.page).right,
    // bottom: props => getCurvesStyles(props.page).bottom,
    left: props => getCurvesStyles(props.page).left
  },
  image_container: {
    position: "relative",
    height: "40%"
  },
  image: {
    position: "absolute",
    left: "50%",
    height: props => (props.page === -1 ? 250 : 220),
    width: props => (props.page === -1 ? 250 : 220),
    transform: "translate(-50%, 50px)" // x, y
  },
  body: {
    paddingInline: 18,
    paddingTop: "20%",
    textAlign: "center"
  },
  title: {
    fontWeight: 600
  },
  description: {
    fontSize: 13,
    color: "#686868"
  },
  pages: {
    positon: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    paddingBlock: 12
  },
  dot: {
    height: 8,
    width: 8,
    padding: 0,
    borderRadius: "50%",
    background: "#dddddd",
    border: "1px solid transparent",
    cursor: "pointer",
    transition: "all 250ms ease-in",
    "&:hover": {
      borderColor: "#a7a7a7"
    }
  },
  activeDot: {
    background: "#2B5A63",
    borderColor: "transparent"
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: 18,
    boxSizing: "border-box",
    flexDirection: "row-reverse"
  },
  button_label: {
    fontWeight: 600,
    letterSpacing: 1.2
  }
});

export default useStyles;
