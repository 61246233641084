import React, { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  InputLabel,
  Typography,
  TextField,
  Paper
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";

import Swal from "sweetalert2";
import { ADD_ALERT_DESCRIPTION } from "../../../../../graphql/Mutations";
import { GET_ALERT_DESCRIPTIONS } from "../../../../../graphql/Queries";
import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import { Helper } from "../../../../Utils";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../../utils/functions/AddLogs";
import AccessControl from "../../../../../utils/AccessControl";
import _ from 'lodash';
import validate from "../../../../../utils/validation";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    // height: "calc(100% - 66px)", // 66 for header, 32 for top + bottom margins
    minHeight: "100%",
    height: "auto",
    margin: theme.spacing(2, 4),
    flexGrow: 1,
    padding: theme.spacing(5),
    boxSizing: "border-box"
    // overflow: "auto"
  },
  scrollable: {
    height: "calc(100% - 66px)",
    boxSizing: "border-box",
    overflow: "auto"
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  }
}));

export default function AddAlertDescriptions(props) {
  const { history, location } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [tempAddData, setTempAddData] = useState({
    code: "",
    description: "",
    parent_id: ""
  });
  const [initialState] = useState(_.cloneDeep(tempAddData));
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [filterData, setFilterData] = useState([]); // set filter data from backend
  const [errors, setErrors] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });

  const [get_description] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        // setPlaces([...data.get_alert_descriptions.alert_descriptions.code]);
        setFilterData(data.get_alert_descriptions.alert_descriptions);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  // const { data: filterDataQuery } = useQuery(GET_ALERT_DESCRIPTIONS, {
  //   variables: { first: 10000 },
  //   fetchPolicy: "network-only"
  // });

  React.useEffect(() => {
    // if (filterDataQuery) {
      // setFilterData(filterDataQuery.get_alert_descriptions);
      setIsLoading(false);
    // }
  }, [discard]);

  const handleCloseDialog = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleBlur = (e) => {
    const {name, value} = e.target
    let err;
    switch(name){
      case "code":
        value === "" 
        ? err = <Helper message="This field is required!" />
        : err = !validate("alphanumeric", value)
        ? err = <Helper message="Alphanumeric characters only" />
        : err = ""
      break;
      default:
      break
    }
    setErrors({...errors, code: err})
  }

  const handleAddValues = e => {
    setTempAddData({
      ...tempAddData,
      [e.target.name]: e.target.value
    });
  };

  const [addAlertDescription] = useMutation(ADD_ALERT_DESCRIPTION, {
    onCompleted: res => {
      setDisableSubmit(false);
      setIsLoading(false);
      if (res.add_alert_description.success) {
        AddLogs("Admin - Alert Descriptions", "insert", tempAddData.code);
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Add successful",
          timer: 3000,
          onClose: () => {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
                  filter: history?.location?.state?.params?.filter
                }
              }
            });
          }
        });
      } else {
        setErrors({
          ...errors,
          code: <Helper message="Alert description already exists" />
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      setIsLoading(false);
    }
  });

  const saveAdd = () => {
    addAlertDescription({
      variables: {
        description: tempAddData.description, // remarks
        code: tempAddData.code, // description name
        parent_id: Number(tempAddData.parent_id)
      }
    });
    setErrors([]);
    setDisableSubmit(false);
  };

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/wti/alert_descriptions/"
  // );

  if (isLoading) return <Loading />;
  // if (!permissions.edit) return <Typography>403</Typography>;

  const { pathname } = location;

  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={_.isEqual(tempAddData, initialState)}
          history={history?.location?.state?.params}
        />
        <div className={classes.scrollable}>
          {/* <form
          onSubmit={e => {
            e.preventDefault();
          }}
          className={classes.root}
          autoComplete="off"
        > */}
          <Paper className={classes.container} variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="code"
                      placeholder="Enter Alert Description"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={
                        <b>
                          Alert Description{" "}
                          <span style={{ color: "red" }}>*</span>
                        </b>
                      }
                      value={tempAddData.code}
                      name="code"
                      onBlur={e => handleBlur(e)}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      FormHelperTextProps={{ component: 'div' }}
                      helperText={errors.code ? errors.code : ""}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel shrink>
                      <b>Parent Description</b>
                    </InputLabel>
                    <Autocomplete
                      id="parent_id"
                      options={filterData}
                      value={filterValue}
                      getOptionLabel={option => option.code || ""}
                      getOptionSelected={(o, v) => v.value === o.value}
                      renderOption={option => option.code}
                      renderInput={params => (
                        <TextField
                          {...params}
                          value={filterValue.code}
                          placeholder="Select Parent"
                          onChange={event => {
                            if (event.target.value.length > 0) {
                              get_description({
                                variables: {
                                  first: 10,
                                  filter: {
                                    field: "code",
                                    value: event.target.value
                                  }
                                }
                              });
                            } else {
                              setFilterValue([]);
                              setTempAddData({
                                ...tempAddData,
                                parent_id: null
                              });
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                      onChange={(e, val) => {
                        if (val) {
                          setTempAddData({
                            ...tempAddData,
                            parent_id: val.id
                          });
                          setFilterValue(val);
                        }
                      }}
                      disableClearable
                    />
                    {/* <Select
                      value={tempAddData.parent_id}
                      onChange={e => handleAddValues(e)}
                      displayEmpty
                      name="parent_id"
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Parent
                      </MenuItem>
                      {filterData.alert_descriptions
                        ? filterData.alert_descriptions.map(row => (
                            <MenuItem key={`${row.id}1`} value={row.id}>
                              {row.code}
                            </MenuItem>
                          ))
                        : null}
                    </Select> */}
                  </Grid>

                  <Grid>
                    <InputLabel shrink className={classes.inputLabel}>
                      <br />
                      <br />
                      <b>Remarks</b>
                    </InputLabel>
                    <TextField
                      style={{ width: "110%" }}
                      multiline
                      minRows={4}
                      maxRows={4}
                      variant="outlined"
                      placeholder="Type Here..."
                      name="description"
                      id="description"
                      value={tempAddData.description}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      helperText={errors.description ? errors.description : ""}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                {errors.general && (
                  <Alert
                    severity="error"
                    style={{ justifyContent: "center" }}
                    className={classes.alert}
                  >
                    {errors.general}
                  </Alert>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit}
                  fullWidth
                  style={{
                    margin: 20,
                    maxWidth: "30%",
                    marginTop: 10,
                    borderRadius: "17px"
                  }}
                  onClick={() => {
                    let temp = {};

                    if (tempAddData.code.length === 0) {
                      temp = {
                        ...temp,
                        code: <Helper message="This field is required" />
                      };
                    }

                    if (/[^a-zA-Z0-9 ]/.test(tempAddData.description)) {
                      temp = {
                        ...temp,
                        description: (
                          <Helper message="Numbers and Letters only" />
                        )
                      };
                    }

                    if (/[^a-zA-Z0-9 ]/.test(tempAddData.code)) {
                      temp = {
                        ...temp,
                        code: <Helper message="Numbers and Letters only" />
                      };
                    }

                    setErrors(temp);
                    if (
                      !/[^a-zA-Z0-9 ]/.test(tempAddData.code) &&
                      !/[^a-zA-Z0-9 ]/.test(tempAddData.description)
                    ) {
                      if (tempAddData.code.length === 0) {
                        temp = {
                          ...temp,
                          code: <Helper message="This field is required" />
                        };
                      } else {
                        setDialog({ ...dialog, save: true });
                      }
                    }
                    return null;
                  }}
                >
                  Save Details
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* </form> */}
          <ConfirmationDialog
            toggle={dialog.save}
            close={() => handleCloseDialog("save")}
            fn={() => saveAdd()}
            title="Save Changes?"
            content="Are you sure you want to save this data?"
          />
        </div>
      </div>
    </AccessControl>
  );
}
