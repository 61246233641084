import React, { useState, useEffect } from "react";
import StatusContainer from "../../../utils/UI/Container/StatusContainer";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import Bookings from "../Charts/Bookings";
import StatusChart from "../Charts/StatusChart";
import useFetchChartData from "../../../utils/hooks/useFetchChartData";
import { GET_DEMURRAGE_DATA } from "../../../graphql/Queries";
import Loading from "../../../utils/Loading";
import DashboardContainer from "../../../utils/UI/Container/DashboardContainer";

const Demurrage = props => {
  const { variables, history } = props;
  const classes = useStyles();
  const chartData = useFetchChartData(
    GET_DEMURRAGE_DATA,
    variables,
    "Demurrage"
  );

  return (
    <>
      {chartData.isLoading ? (
        <Loading />
      ) : (
        <DashboardContainer label="Demurrage">
          <Grid item xs={12} md={3}>
            <StatusContainer
              data={chartData.data.demurrageData}
              label="Land"
              history={history}
              category="Land"
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box className={classes.chart_container}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Bookings
                    tripNumbers={chartData.data.tripNumbers}
                    graphValue={chartData.data.bookingsChartValue[0]}
                    history={history}
                    chartTitle="Land"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StatusChart
                    dataValue={chartData.data.chartValue[0]}
                    chartTitle="Land"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </DashboardContainer>
      )}
    </>
  );
};

export default Demurrage;

const useStyles = makeStyles(theme => ({
  chart_container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    minHeight: 250,
    display: "flex",
    padding: "0 1rem",
    justifyContent: "center",
    alignItems: "center"
  }
}));
