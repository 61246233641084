import React, { useState, useEffect } from "react";
import {
  GET_DASHBOARDS_FILTERS,
  GET_DASHBOARDS_FILTER
} from "../../graphql/Queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import _ from "lodash";

// this custom hook is use for fetching dashboard filters on filter module.
const saveInitialFilter = { value: 0, label: "Select Filter" };
const useFetchDashboardFilters = (vars) => {
  // states.
  const [saveFilter, setSaveFilter] = useState([saveInitialFilter]);
  const [fetchedSavedFilter, setFetchedSavedFilter] = useState();
  // dashboard filters for dropdown.
  const { data: dashboardsFilters, loading } = useQuery(
    GET_DASHBOARDS_FILTERS,
    {
      variables: vars,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      onError: err => {
        console.log(err);
      }
    }
  );
  // upon selecting from the dashboards filters get the saved dashboard filter data.
  const [getFilter] = useLazyQuery(GET_DASHBOARDS_FILTER, {
    onCompleted: data => {
      if (!data) return;
      setFetchedSavedFilter(data.get_dashboard_filter);
    },
    fetchPolicy: "network-only",
  });
  

  useEffect(() => {
    if (!dashboardsFilters) return;
    const filtersTemp = _.sortBy(dashboardsFilters.get_dashboard_filters, [
      "label"
    ]);
    // combine filters temp array with save initial filter.
    setSaveFilter([saveInitialFilter, ...filtersTemp]);
  }, [dashboardsFilters]);

  return { saveFilter, getFilter, fetchedSavedFilter };
};

export default useFetchDashboardFilters;
