const data = [
  {
    id: "2052",
    report_type: "Vehicle Alerts",
    date_from: "2022-09-16 00:00:00",
    date_to: "2022-09-16 23:59:00",
    date_requested: "2022-09-16 10:55:56",
    requested_by: "WTI-Admin",
    status: "Error",
    status_code_id: 26,
    filename: "vehicle_alerts_report_2022_09_16_105603_90987.xlsx"
  }
];

export default data;
