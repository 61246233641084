/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  TextField
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete as DeleteIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
  Cancel as CancelIcon
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccessControl from "../../../../AccessControl";
import Header from "../../../../../components/Report/common/Header";
import ButtonLink from "../../../../../components/Report/common/ButtonLink";
import useFormStyles from "../../../../../styles/useFormStyles";
import Pagination from "../../../../table/Pagination";
import reports_welcome_image from "../../../images/report-generate-vehicle-data.svg";
import data from "./reports.data";
import Focus, { useInfoBubbleStyles } from "../../../common/Focus";
import Stage from "../../../common/Stage/Stage";
import Onboarding, { useOnboardingContext } from "../../../index";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    height: "100%"
  },
  root: {
    margin: 0,
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    padding: 15
  },
  paper: {
    width: "100%",
    padding: "18px !important",
    minHeight: "85vh",
    height: "auto",
    position: "relative",
    borderRadius: "15px",
    background: "#fff",
    boxShadow: "0px 0px 5px #cccccc73",
    marginRight: "15px !important",
    boxSizing: "border-box",
    flex: 2.5,
    display: "flex",
    flexDirection: "column"
  },
  reportsWrapper: {
    flex: 10.5,
    width: "100%",
    background: "#fff",
    boxShadow: "0px 0px 5px #cccccc73",
    height: "auto",
    minHeight: "85vh",
    position: "relative",
    borderRadius: "15px",
    padding: "0px !important",
    "& .MuiTableCell-stickyHeader": {
      background: "#fff",
      padding: "8px 5px"
    }
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  filterInput: {
    minWidth: "100%",
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    }
  },
  iconStyle: { width: 16, height: 16 },
  generateButton: {
    borderRadius: "25px",
    position: "relative",
    width: "100%"
  },
  listUnordered: {
    paddingInline: 0,
    listStyle: "none"
  },
  listItem: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: 12,
    color: "#999",
    "&::before": {
      content: "''",
      height: 6,
      width: 6,
      background: "#999",
      position: "relative",
      left: 0,
      top: -1,
      borderRadius: 8
    }
  },
  popper: {
    zIndex: 1500
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const data_stable = stabilizedThis.map(el => el[0]);
  return data_stable;
}

const headCells = [
  {
    id: "report_type",
    numeric: false,
    disablePadding: true,
    label: "Report"
  },
  { id: "startstamp", numeric: false, disablePadding: false, label: "From" },
  { id: "endstamp", numeric: false, disablePadding: false, label: "To" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Date Requested"
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    label: "Requested By"
  },
  { id: "status_code", numeric: false, disablePadding: false, label: "Status" },
  { id: "id", numeric: false, disablePadding: false, label: "Action" }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, handleSort } = props;
  const {
    progress: { reports: currentStep }
  } = useOnboardingContext();

  return (
    <Focus step={2} component={TableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={isChecked().indeterminate}
            // checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all reports" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <Focus
            step={[3, 5]}
            on={
              currentStep === 3
                ? headCell.label === "Status"
                : headCell.label === "Action"
            }
            showInfoBubble={false}
            component={TableCell}
            align="center"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </Focus>
        ))}
      </TableRow>
    </Focus>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const stage = {
  0: {
    image: reports_welcome_image,
    title: "Report: Generate Vehicle Data",
    description: `Generate different vehicle and trip data for analysis`,
    buttonText: "Got It"
  }
};

const StatusList = () => {
  const { listUnordered, listItem } = useStyles();
  return (
    <ul className={listUnordered}>
      <li className={listItem}>Preparing</li>
      <li className={listItem}>Cancelled</li>
      <li className={listItem}>Download</li>
    </ul>
  );
};

const ActionLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <CancelIcon fontSize="small" htmlColor="#FF0000" />
        </span>
        <span>Cancel</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <SettingsBackupRestoreIcon fontSize="small" htmlColor="#999" />
        </span>
        <span>Regenerate</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <DeleteIcon fontSize="small" htmlColor="#0088C4" />
        </span>
        <span>Delete</span>
      </li>
    </ul>
  );
};

const Reports = () => {
  const VEHICLE_ALERT = process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT;
  const formStyles = useFormStyles();
  const order = "desc";
  const orderBy = "id";
  // const [haulerInput, setHaulerInput] = useState(false);
  const reportRequest = {
    report_type: VEHICLE_ALERT,
    date_reference: "",
    date_range: "",
    hauler_option: "current_day"
  };
  const classes = useStyles();
  const history = useHistory();
  const {
    progress: { reports: currentStep }
  } = useOnboardingContext();

  return (
    <React.Fragment>
      <AccessControl resource="/reports/" process="reports">
        <div className={classes.wrapper}>
          <Focus
            step={0}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: [0].includes(currentStep) ? "block" : "none",
              background: "transparent !important"
            }}
            showInfoBubble={false}
          >
            <Stage {...stage[currentStep]} />
          </Focus>
          <Header>
            <div style={{ flex: 1 }}>
              <Focus
                step={6}
                indicator={currentStep === 6}
                infoBubbleProps={{
                  title: "Automated Report",
                  description:
                    "Allows user to view all created scheduled reports",
                  onNext: () => {
                    history.push({
                      pathname: "/reports/automated"
                      // state: {
                      //   params: {
                      //     moduleName: link
                      //   }
                      // }
                    });
                  }
                }}
                style={{ padding: 8, width: "fit-content" }}
              >
                <ButtonLink
                  text="Automated Reports"
                  resource="/reports/automated/"
                />
              </Focus>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" style={{ marginRight: 32 }}>
                As of {moment().format("MMM D YYYY HH:mm A")}
              </Typography>
              <Pagination
                rowsPerPage={10}
                total={100}
                currentPage={1}
                onRowsPerPageChange={() => {}}
                onPageChange={() => {}}
                onPageUp={() => {}}
                onPageDown={() => {}}
              />
            </div>
          </Header>
          <div className={classes.root} style={{ background: "#F8F8F8" }}>
            <Focus
              step={1}
              infoBubbleProps={{
                title: "Filter",
                description:
                  "Generate different vehicle and trip data for analysis"
              }}
              className={classes.paper}
            >
              {/* ADD REPORT */}
              <div style={{ position: "relative", height: "100%" }}>
                <Typography
                  variant="h6"
                  component="span"
                  style={{
                    marginBottom: "20px",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    letterSpacing: "1.25px",
                    display: "block"
                  }}
                  gutterBottom
                >
                  REPORT
                </Typography>
                <FormControl
                  className={classes.filterInput}
                  classes={{ root: formStyles.formRoot }}
                >
                  <InputLabel>Report Type</InputLabel>
                  <Select
                    classes={{ root: formStyles.selectRoot }}
                    open={false}
                    id="report-type"
                    name="report_type"
                    value={1}
                    // onChange={handleChange}
                    // label="Report Type"
                    // select
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    <MenuItem value={-1} disabled>
                      Select Report type
                    </MenuItem>
                    <MenuItem value={1}>Vehicle Alerts Report</MenuItem>
                  </Select>
                </FormControl>
                {[
                  { label: "Haulers/Subgroups" },
                  { label: "Plate Number" },
                  { label: "Alert Description" },
                  { label: "Date Range" }
                ].map(item => (
                  <FormControl
                    classes={{ root: formStyles.formRoot }}
                    style={{ width: "100%", minWidth: "auto" }}
                  >
                    <InputLabel shrink>{item.label}</InputLabel>
                    <br />
                    <Autocomplete
                      options={["All"]}
                      // value="All"
                      disableClearable
                      autoSelect
                      classes={{
                        popper: classes.popper
                      }}
                      open={false}
                      renderInput={params => (
                        <TextField
                          {...params}
                          classes={{ root: formStyles.textFieldRoot }}
                          value="All"
                          placeholder={
                            item.label === "Date Range" ? "Current Day" : "All"
                          }
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              padding: "6px 0px"
                            },
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </FormControl>
                ))}
                {/* <FormControl className={formStyles.formRoot}>
                  <Autocomplete
                    loadingText="Loading Haulers..."
                    multiple
                    limitTags={2}
                    id="haulers-chip-input"
                    options={[]}
                    disableCloseOnSelect
                    disableClearable
                    // disabled={readOnly}
                    value={[]}
                    getOptionSelected={(option, value) => option === value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        classes={{ root: formStyles.textFieldRoot }}
                        label="Haulers/Subgroups"
                        placeholder="All"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          style: {
                            padding: "6px 0px"
                          },
                          ...params.InputProps
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl className={formStyles.formRoot}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="vehicle-chip-input"
                    options={[]}
                    disableCloseOnSelect
                    disableClearable
                    value={[]}
                    // defaultValue={vehiclesOptions}
                    getOptionLabel={option => option}
                    renderInput={params => (
                      <Tooltip title="Max of 20 plate numbers only">
                        <TextField
                          {...params}
                          classes={{ root: formStyles.textFieldRoot }}
                          // className={chipInputStyles.rootInput}
                          // variant="outlined"
                          label="Plate Number"
                          placeholder="All"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            style: {
                              padding: "6px 0px"
                            },
                            ...params.InputProps
                          }}
                        />
                      </Tooltip>
                    )}
                  />
                </FormControl>
                <FormControl className={formStyles.formRoot}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="alert-type-chip-input"
                    options={[]}
                    disableCloseOnSelect
                    disableClearable
                    value={[]}
                    getOptionLabel={option => option}
                    renderInput={params => (
                      <TextField
                        {...params}
                        classes={{ root: formStyles.textFieldRoot }}
                        // variant="outlined"
                        label="Alert Description"
                        placeholder="All"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          style: {
                            padding: "6px 0px"
                          },
                          ...params.InputProps
                        }}
                      />
                    )}
                  />
                </FormControl>
                {[VEHICLE_ALERT].includes(reportRequest.report_type) && (
                  <FormControl
                    className={classes.filterInput}
                    classes={{ root: formStyles.formRoot }}
                  >
                    <InputLabel>Date Range</InputLabel>
                    <Select
                      // className={classes.filterInput}
                      classes={{ root: formStyles.selectRoot }}
                      id="hauler_option"
                      name="hauler_option"
                      value="current_day"
                      label="Date Range"
                      // select
                      style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      <MenuItem value="current_day">Current day</MenuItem>
                    </Select>
                  </FormControl>
                )} */}
              </div>
              <Button
                fullWidth
                className={classes.generateButton}
                variant="contained"
                color="primary"
              >
                Generate
              </Button>
            </Focus>
            <div className={classes.reportsWrapper}>
              <TableContainer
                style={{
                  height: "100%",
                  maxHeight: "100%",
                  borderRadius: "15px 15px 0px 0px"
                }}
              >
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="report-table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={() => {}}
                    handleSort={() => {}}
                    isChecked={() => {}}
                  />
                  {/* {selected.length > 0 && ( */}
                  <tbody>
                    <tr rowSpan="2">
                      <td
                        colSpan="4"
                        align="right"
                        className={classes.deleteColumnHeight}
                      >
                        <Collapse in={false}>
                          <Tooltip title="Delete">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                // background: "#3CA4F7",
                                borderRadius: "17px",
                                marginRight: 20
                              }}
                            >
                              Delete Selected
                            </Button>
                          </Tooltip>
                        </Collapse>
                      </td>
                    </tr>
                  </tbody>
                  {/* )} */}
                  <TableBody>
                    {data ? (
                      stableSort(data, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const labelId = `entry-checkbox-${index}`;
                          const reportKey = `${row.report_type}-${index}`;
                          let color = "";
                          let icon = "";
                          let status = "";
                          const root = `${process.env.PUBLIC_URL}/reports`;
                          const downloadLink = `${root}/${row.filename}`;
                          switch (row.status) {
                            case "Cancelled":
                              color = "#ACACAC";
                              icon = (
                                <SettingsBackupRestoreIcon htmlColor={color} />
                              );
                              break;
                            case "Ongoing":
                              color = "#757575";
                              icon = <CancelIcon htmlColor={color} />;
                              break;
                            case "Completed":
                              color = "#2286C3";
                              icon = <DeleteIcon htmlColor={color} />;
                              break;
                            default:
                              color = "#757575";
                              icon = <CancelIcon htmlColor={color} />;
                              break;
                          }
                          if (row.status === "Completed") {
                            status = (
                              <a
                                href={downloadLink}
                                style={{ color: "#2286C3" }}
                                download
                              >
                                Download
                              </a>
                            );
                          } else if (
                            row.status === "Ongoing" ||
                            row.status === "Pending"
                          ) {
                            status = "Preparing";
                          } else {
                            // eslint-disable-next-line prefer-destructuring
                            status = row.status;
                          }
                          return (
                            <Focus
                              step={2}
                              component={TableRow}
                              showInfoBubble={false}
                              key={reportKey}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {row.report_type}
                              </TableCell>
                              <TableCell align="center">
                                {row.date_from.includes("1111-11-11")
                                  ? "-"
                                  : row.date_from}
                              </TableCell>
                              <TableCell align="center">
                                {row.date_to.includes("1111-11-11")
                                  ? "-"
                                  : row.date_to}
                              </TableCell>
                              <Focus
                                step={2}
                                component={TableCell}
                                infoBubbleProps={{
                                  position: "bottom",
                                  title: "Data Table",
                                  description: "Displays user-generated report"
                                }}
                                align="center"
                              >
                                {moment(row.date_requested).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </Focus>
                              <TableCell align="center">
                                {row.requested_by}
                              </TableCell>
                              <Focus
                                step={3}
                                component={TableCell}
                                infoBubbleProps={{
                                  position: "left",
                                  title: "Status",
                                  description:
                                    "Indicates the status of the report to be generate",
                                  body: <StatusList />
                                }}
                                align="center"
                              >
                                <Focus
                                  component={Typography}
                                  style={{ color: color }}
                                  step={4}
                                  infoBubbleProps={{
                                    position: "left",
                                    title: "Download",
                                    description:
                                      "Allows to transfer data from web to user's device"
                                  }}
                                >
                                  Download
                                </Focus>
                              </Focus>
                              <Focus
                                step={5}
                                component={TableCell}
                                infoBubbleProps={{
                                  position: "left",
                                  title: "Action",
                                  description:
                                    "Shows available actions to be executed on a report",
                                  body: <ActionLegends />
                                }}
                                align="center"
                              >
                                <IconButton edge="end" aria-label="delete">
                                  {icon}
                                </IconButton>
                              </Focus>
                            </Focus>
                          );
                        }
                      )
                    ) : (
                      <TableCell align="center" colSpan={7}>
                        <Typography variant="h5" align="center">
                          No Reports
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </AccessControl>
    </React.Fragment>
  );
};

export default Reports;
