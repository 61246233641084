import React, { useRef } from "react";
import Chart from "chart.js/auto";
import {
  Bar,
  getElementAtEvent,
  getElementsAtEvent,
  getDatasetAtEvent
} from "react-chartjs-2";
import redirectBookings from "../../../utils/functions/redirectBookings";

const Bookings = props => {
  const { tripNumbers, graphValue, history, chartTitle } = props;
  const chartRef = useRef();

  return (
    <Bar
      ref={chartRef}
      data={{
        labels: graphValue.labels,
        datasets: [
          {
            label: graphValue.trips.label,
            data: graphValue.trips.data,
            borderColor: graphValue.trips.color,
            backgroundColor: graphValue.trips.color
          },
          {
            label: graphValue.prebooking.label,
            data: graphValue.prebooking.data,
            borderColor: graphValue.prebooking.color,
            backgroundColor: graphValue.prebooking.color
          }
        ]
      }}
      height="150px"
      width="auto"
      options={config}
      onClick={event => {
        const chartElement = getElementAtEvent(chartRef.current, event);
        if (chartElement[0]) {
          redirectBookings(
            history,
            chartElement[0].datasetIndex,
            chartElement[0],
            chartTitle,
            tripNumbers
          );
        }
      }}
    />
  );
};

export default Bookings;

const config = {
  scales: {
    x: {
      ticks: {
        stepSize: 1
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#3e3e3e",
        boxWidth: 15,
        boxHeight: 15
      },
      position: "bottom"
    }
  },
  indexAxis: "y"
};
