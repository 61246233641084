/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Hidden,
  Grid,
  Typography,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import {
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import querystring from "query-string";
import { useQuery, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
// import background from "../../../assets/Background-no-footer.png";
import background from "../../../assets/Background-with-Text.png";
// import LoginLogo from "../../../assets/Login logo.png";
import LoginLogo from "../../../assets/Seal-Original_4x_1.ico";
// import ResetLogo from "../../../assets/Reset Pass.svg";
import ResetLogo from "../../../assets/Password.png";
// import ResetLink from "../../../assets/Reset Link.svg";
import ResetLink from "../../../assets/Reset-Link.png";
import { CHECK_TOKEN_QUERY } from "../../../graphql/Queries";
import { RESET_PASSWORD_MUTATION } from "../../../graphql/Mutations";
import { Helper } from "../../Utils";
import PasswordStrength from "../../../utils/PasswordStrength";

const webcastLink = "https://webcast-inc.com.ph/";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 16
  },
  svgBackground: {
    color: theme.palette.primary.main
  },
  themeTypography: {
    color: theme.palette.primary.main
  },
  desktop: {
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  mobile: {
    paddingLeft: "30px",
    paddingRight: "30px",
  }
}));

const ExpiredLink = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography align="center">
          It seems that this link has expired. Please try again to get a new
          password reset link.
        </Typography>
      </Grid>
    </Grid>
  );
};

const ResetSuccess = (props) => {
  const classes = useStyles()
  const {router} = props
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography align="center">
          Password reset was successful. You may now login your account with the
          new password provided.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => router.push("/login")}
          fullWidth
          className={classes.button}
        >
          RETURN TO SIGN IN
        </Button>
      </Grid>
    </Grid>
  );
};

const ResetPasswordForm = props => {
  const { token, setMode } = props;
  const [passwords, setPasswords] = useState({
    password1: "",
    password2: ""
  });

  const [helperText, setHelperText] = useState("");

  const [passwordVisibility, setPasswordVisibility] = useState({
    password1: true,
    password2: true
  });

  const handlePasswordChange = e => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
  };

  const handleVisibilityClick = name => {
    setPasswordVisibility({
      ...passwordVisibility,
      [name]: !passwordVisibility[name]
    });
  };

  const [reset_password] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: {
      token: token.token,
      password: passwords.password1
    },
    onCompleted: data => {
      const res = data.reset_password;
      if (res.success) {
        setMode("success");
      } else if (res.error) {
        setHelperText(<Helper message={res.error} />);
      }
    },
    onError: () => {
      setHelperText(<Helper message="Something went wrong!" />);
    }
  });

  const validatePassword = () => {
    const { password1, password2 } = passwords;
    if (password1 === password2) {
      return true;
    }
    return false;
  };

  const classes = useStyles();

  const handleSubmit = e => {
    e.preventDefault();
    if (validatePassword()) {
      if (passwords.password1.length < 8) {
        setHelperText(
          <Helper message="Password should be atleast 8 characters." />
        );
      } else {
        reset_password();
      }
    } else {
      setHelperText(<Helper message="Password do not match." />);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="New Password"
            name="password1"
            value={passwords.password1}
            helperText={helperText}
            onChange={handlePasswordChange}
            required
            type={passwordVisibility.password1 ? `password` : `text`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className={classes.svgBackground} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={() => handleVisibilityClick("password1")}>
                  {!passwordVisibility.password1 ? (
                    <VisibilityIcon style={{ color: "#CBCBCB" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "#CBCBCB" }} />
                  )}
                </IconButton>
              ),
              inputProps: {
                maxLength: 128
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordStrength password={passwords.password1} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Confirm Password"
            name="password2"
            required
            value={passwords.password2}
            helperText={helperText}
            onChange={handlePasswordChange}
            type={passwordVisibility.password2 ? `password` : `text`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className={classes.svgBackground} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={() => handleVisibilityClick("password2")}>
                  {!passwordVisibility.password2 ? (
                    <VisibilityIcon style={{ color: "#CBCBCB" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "#CBCBCB" }} />
                  )}
                </IconButton>
              ),
              inputProps: {
                maxLength: 128
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            type="submit"
          >
            RESET NOW
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ResetPassword = props => {
  const classes = useStyles();
  const screenSize = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [mode, setMode] = useState("link");
  const { history, location } = props;
  const locationParams = querystring.parse(location.search);
  const { data, loading } = useQuery(CHECK_TOKEN_QUERY, {
    variables: locationParams
  });

  const checkToken = () => {
    if (!loading) {
      const { check_reset_token } = data;
      if (check_reset_token.success) {
        setMode("form");
      } else {
        setMode("link");
      }
    }
  };

  useEffect(() => {
    checkToken();
  }, [loading]);

  const getLogo = () => {
    if (mode === "link") {
      return ResetLink;
    }
    return ResetLogo;
  };

  const getHeader = () => {
    let header = "";
    if (mode === "link") {
      header = "Expired link :(";
    } else if (mode === "success") {
      header = "Reset was Successful";
    } else {
      header = "Reset your password";
    }
    return header;
  };

  const getScreen = () => {
    let screen = "";
    if (mode === "link") {
      screen = <ExpiredLink router={history} />;
    } else if (mode === "success") {
      screen = <ResetSuccess router={history} />;
    } else {
      screen = <ResetPasswordForm setMode={setMode} token={locationParams} />;
    }
    return screen;
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Hidden mdDown>
        <Grid
          item
          lg={6}
          style={{
            width: "50%",
            // height: "100vh",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            position: "relative"
          }}
        >
          <Typography
            onClick={() => window.open(webcastLink, "_blank")}
            variant="h5"
            style={{
              position: "absolute",
              bottom: "30px",
              textAlign: "center",
              width: "100%"
            }}
            color="secondary"
          >
            Contact us{" "}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              position: "absolute",
              bottom: "5px",
              textAlign: "center",
              width: "100%"
            }}
            color="secondary"
          >
            Powered by Webcast Technologies Inc.
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={6} style={{ marginTop: 30, marginBottom: 20 }}>
        <Typography align="center" component="div">
          <img alt="" src={LoginLogo} style={{ height: 95 }} />
        </Typography>
        <Typography
          align="center"
          variant="h4"
          className={classes.themeTypography}
        >
          {getHeader()}
        </Typography>
        <div style={{ padding: 16 }}>
          <Typography align="center" component="div" style={{}}>
            <img alt="" src={getLogo()} />
          </Typography>
        </div>
        <Container className={screenSize ? classes.desktop : classes.mobile}>
          {getScreen()}
        </Container>
      </Grid>
    </Grid>
  );
};

export default withRouter(ResetPassword);
