import { makeStyles } from "@material-ui/core";

export default makeStyles({
  rootInput: {
    // width: "100"
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    },
    "& ::placeholder": {
      /* Firefox */
      color: "#000 !important",
      opacity: 1
    },
    "& :-ms-input-placeholder": {
      /* Internet Explorer 10-11 */
      color: "#000 !important",
      opacity: 1
    },
    "& ::-ms-input-placeholder": {
      /* Microsoft Edge */
      color: "#000 !important",
      opacity: 1
    }
  },
  iconStyle: { width: 16, height: 16 }
});
