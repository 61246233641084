import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  List,
  ListItem,
  Typography,
  Grid,
  Divider,
  Button,
  Tooltip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ListSubheader,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { VolumeOff, VolumeUp } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";
import { READ_NOTIFICATION } from "../../api/notificationRepository";
import { getBooking } from "../../api/bookingRepository";
import ConfirmationDialog from "../../utils/modal/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16
  },
  readBg: {
    backgroundColor: "#F8F8F8",
    display: "flex"
  },
  panelRootSys: {
    backgroundColor: "#EB825C"
  },
  panelRootTrip: {
    backgroundColor: "#CFECFD"
  },
  panelRootGps: {
    backgroundColor: "#EDD608"
  },
  panelContent: {
    margin: 4
  },
  listItem: { borderLeft: `5px solid ${theme.palette.primary.main}` }
}));

const Notifications = props => {
  const { notifications, refetch, fetchMore, fn, popupState, mute, toggleMute } = props;
  const classes = useStyles();
  const { get_notifications, system_notifications, gps_notifications } = notifications;
  const {
    notifications: notificationsData,
    count: tripNotifCount,
    total: tripNotifTotal
  } = get_notifications;
  const {
    notifications: system_notifs,
    count: systemNotifCount,
    total: systemNotifTotal
  } = system_notifications;
  const {
    notifications: gpsNotificationData,
    count: gpsNotifCount,
    total: gpsNotifTotal
  } = gps_notifications;
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [sortedNotifications, setSortedNotifications] = useState([]);
  const [systemNotifs, setSystemNotifs] = useState([]);
  const [gpsNotifs, setGpsNotifs] = useState([])
  const [expanded, setExpanded] = useState("panel1");
  // let sortedNotifications;

  // if(notificationsData) {
  //   sortedNotifications = _.orderBy(
  //     notificationsData,
  //     ["is_urgent", "created"],
  //     ["asc", "desc"]
  //   );
  // }

  useEffect(() => {
    if (notificationsData) {
      setSortedNotifications(
        _.orderBy(notificationsData, ["created"], ["desc"])
      );
    }
  }, [notificationsData]);

  useEffect(() => {
    if (system_notifs) {
      setSystemNotifs(
        _.orderBy(system_notifs, ["is_urgent", "created "], ["asc", "desc"])
      );
    }
  }, [system_notifs]);

  useEffect(() => {
    if (gpsNotificationData) {
      setGpsNotifs(
        _.orderBy(gpsNotificationData, ["is_urgent", "created "], ["asc", "desc"])
      );
    }
  }, [gpsNotificationData]);

  const handleClick = async data => {
    const notif_input = { ...data, is_read: true };
    _.unset(notif_input, "id");
    _.unset(notif_input, "created");
    const res = await READ_NOTIFICATION({
      id: data.id,
      notification: notif_input
    });
    if (res === "success") {
      refetch();
    }
    const booking = await getBooking(data.trip_number);

    const state = {
      tripData: booking.tripList[0],
      rawTripData: booking.rawTripData[0]
    };
    if (booking.tripList.length === 1) {
      if (booking.tripList[0].tripNumber === data.trip_number) {
        const to = {
          pathname: "/details",
          state: state
        };
        props.history.push("/temp");
        props.history.push(to);
      }
    }
  };

  // const handleClickAll = async () => {
  //   fn();
  //   const res = await READ_ALL_NOTIFICATION(sortedNotifications);
  //   if (res === "success") {
  //     refetch();
  //   }
  // };

  const loadMore = category => {
    const is_system = _.findIndex(category, ["Trip Upload"]) !== -1;

    fetchMore({
      variables: {
        skip: is_system ? systemNotifCount : tripNotifCount,
        first: 10
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        let data = {};
        if (is_system) {
          data = {
            ...prev,
            system_notifications: {
              ...prev.system_notifications,
              count:
                fetchMoreResult.system_notifications.count +
                prev.system_notifications.count,
              notifications: [
                ...prev.system_notifications.notifications,
                ...fetchMoreResult.system_notifications.notifications
              ]
            }
          };
        } else {
          data = {
            ...prev,
            get_notifications: {
              ...prev.get_notifications,
              count:
                fetchMoreResult.get_notifications.count +
                prev.get_notifications.count,
              notifications: [
                ...prev.get_notifications.notifications,
                ...fetchMoreResult.get_notifications.notifications
              ]
            }
          };
        }
        return data;
        // return {
        //   ...prev,
        //   get_notifications: {
        //     ...prev.get_notifications,
        //     notifications: [
        //       ...prev.get_notifications.notifications,
        //       ...fetchMoreResult.get_notifications.notifications
        //     ]
        //   }
        // };
      }
    }).catch(() => {
      console.log("error"); // eslint-disable-line
    });
  };

  const handleChange = panel => (event, exp) => {
    setExpanded(exp ? panel : false);
  };

  return (
    <Fragment>
      <ListSubheader component="div" className={classes.readBg}>
        <div style={{ display: "flex", alignItems: "center", gap: 8, flex: 1 }}>
          <Typography variant="subtitle2">Notifications</Typography>
          <span>
            <Tooltip title={`${mute ? "Unmute alerts" : "Mute alerts"}`}>
              <IconButton
                size="small"
                onClick={() => {
                  toggleMute(currentMute => {
                    localStorage.setItem("mute_notifications", currentMute);
                  });
                }}
              >
                {mute ? (
                  <VolumeOff fontSize="small" />
                ) : (
                  <VolumeUp fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </span>
        </div>
        <Link
          to="/notifications"
          style={{ /*float: "right",*/ textDecoration: "none" }}
        >
          <Button color="primary" onClick={popupState.close}>
            View All
          </Button>
        </Link>
      </ListSubheader>
      <ExpansionPanel
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.panelRootSys,
            content: classes.panelContent
          }}
        >
          <Typography className={classes.heading}>
            System Response Notification
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <List
            className={classes.root}
            style={{ minWidth: "400px", padding: 0 }}
          >
            {systemNotifs.length ? (
              systemNotifs.map(notification => (
                <Fragment>
                  <ListItem
                    key={notification.id}
                    button
                    onClick={async () => handleClick(notification)}
                    className={!notification.is_read ? classes.listItem : ""}
                    // style={
                    //   !notification.is_read
                    //     ? { borderLeft: "5px solid #3ba4f7" }
                    //     : {}
                    // }
                  >
                    <Grid container>
                      <Grid item xs={9} style={{ paddingTop: 10 }}>
                        <Typography>{notification.notification}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          style={{
                            fontSize: 9,
                            paddingTop: "10px"
                          }}
                        >
                          {moment(
                            notification.created,
                            "YYYY-MM-DD HH:mm:ss"
                          ).fromNow()}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        {notification.is_urgent ? (
                          <Tooltip
                            title="An error occurred.
                          Please contact your IT
                          Administrator."
                          >
                            <InfoIcon
                              style={{ paddingTop: "5px", color: "#ff5555" }}
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                style={{ margin: "15px" }}
              >
                No Notification Available
              </Typography>
            )}
            {systemNotifs.length > 0 && systemNotifCount !== systemNotifTotal && (
              <Button fullWidth onClick={() => loadMore(["Trip Upload"])}>
                Load more
              </Button>
            )}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.panelRootTrip,
            content: classes.panelContent
          }}
        >
          <Typography className={classes.heading}>
            Trip Notifications
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <List
            className={classes.root}
            style={{ minWidth: "400px", padding: 0 }}
          >
            {sortedNotifications.length ? (
              sortedNotifications.map(notification => (
                <Fragment key={notification.id}>
                  <ListItem
                    key={notification.id}
                    button
                    onClick={async () => handleClick(notification)}
                    className={!notification.is_read ? classes.listItem : ""}
                    // style={
                    //   !notification.is_read
                    //     ? { borderLeft: "5px solid #3ba4f7" }
                    //     : {}
                    // }
                  >
                    <Grid container>
                      <Grid item xs={9} style={{ paddingTop: 10 }}>
                        <Typography>{notification.notification}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          style={{
                            fontSize: 9,
                            paddingTop: "10px"
                          }}
                        >
                          {moment(
                            notification.created,
                            "YYYY-MM-DD HH:mm:ss"
                          ).fromNow()}
                        </Typography>
                      </Grid>
                      <Grid xs={1} item>
                        {notification.is_urgent ? (
                          <Tooltip
                            title="An error occurred.
                          Please contact your IT
                          Administrator."
                          >
                            <InfoIcon
                              style={{ paddingTop: "5px", color: "#ff5555" }}
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                style={{ margin: "15px" }}
              >
                No Notification Available
              </Typography>
            )}
            {sortedNotifications.length > 0 &&
              tripNotifCount !== tripNotifTotal && (
                <Button
                  fullWidth
                  onClick={() => loadMore(["Dwell Time", "Trip"])}
                >
                  Load more
                </Button>
              )}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.panelRootGps,
            content: classes.panelContent
          }}
        >
          <Typography className={classes.heading}>
            GPS Notifications
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <List
            className={classes.root}
            style={{ minWidth: "400px", padding: 0 }}
          >
            {gpsNotifs.length ? (
              gpsNotifs.map(notification => (
                <Fragment key={notification.id}>
                  <ListItem
                    key={notification.id}
                    button
                    onClick={async () => handleClick(notification)}
                    className={!notification.is_read ? classes.listItem : ""}
                    // style={
                    //   !notification.is_read
                    //     ? { borderLeft: "5px solid #3ba4f7" }
                    //     : {}
                    // }
                  >
                    <Grid container>
                      <Grid item xs={9} style={{ paddingTop: 10 }}>
                        <Typography>{notification.notification}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          style={{
                            fontSize: 9,
                            paddingTop: "10px"
                          }}
                        >
                          {moment(
                            notification.created,
                            "YYYY-MM-DD HH:mm:ss"
                          ).fromNow()}
                        </Typography>
                      </Grid>
                      <Grid xs={1} item>
                        {notification.is_urgent ? (
                          <Tooltip
                            title="An error occurred.
                          Please contact your IT
                          Administrator."
                          >
                            <InfoIcon
                              style={{ paddingTop: "5px", color: "#ff5555" }}
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                style={{ margin: "15px" }}
              >
                No GPS Notification Available
              </Typography>
            )}
            {gpsNotifs.length > 0 &&
              gpsNotifCount !== gpsNotifTotal && (
                <Button
                  fullWidth
                  onClick={() => loadMore(["GPS Alert"])}
                >
                  Load more
                </Button>
              )}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Button fullWidth onClick={() => setConfirmationDialog(true)}>
        Clear All
      </Button>
      <ConfirmationDialog
        toggle={confirmationDialog}
        close={() => setConfirmationDialog(null)}
        fn={() => fn()}
        title="Clear All?"
        content="Are you sure you want to clear all notifications?"
      />
    </Fragment>
  );
};

export default Notifications;
