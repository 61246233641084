import React from "react";
import { Button, Typography, Zoom } from "@material-ui/core";
import { Link } from "react-router-dom";
import buttonStyles from "./buttonStyles";

// Hooks
import useAllowedPermissions from "../../../utils/hooks/useAllowedPermissions";

const ButtonLink = React.memo(props => {
  const { text, resource } = props;
  const { loading, permissions } = useAllowedPermissions(resource);
  const styles = buttonStyles({ loading, permissions });
  return (
    <Zoom
      in={!loading && permissions?.view}
      classes={{ root: styles.rootZoom }}
    >
      <Link
        className={styles.link}
        to={{
          pathname: resource
        }}
      >
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          // onClick={onToggleView}
        >
          <Typography variant="subtitle1">{text}</Typography>
        </Button>
      </Link>
    </Zoom>
  );
});

export default ButtonLink;
