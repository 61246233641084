import React, { useState, useEffect } from "react";
import { GET_ADMIN_CLIENTS } from "../../graphql/Queries";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import useUserContext from "../../context/User/useUserContext";

// custom hook that returns a value for hauler/group options.
// this will validate if the currently login user is a admin or client.
const WEBCAST_ID = 30;
const DEFAULT_ADMIN_NAME = "Webcast";
const DEFAULT_CLIENT_LABEL = "All";
const DEFAULT_CLIENT_VALUE = [];

const useFilterHauler = keyword => {
  const [groupOptions, setGroupOptions] = useState([]);
  const userCtx = useUserContext();
  const userLevelId = userCtx.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const userClientGroupId = userCtx.client.group_ids;
  const userClientGroupNames = userCtx.client.group_names;

  // convert data values to an option values for select or auto complete.
  function formatQryOpt(values) {
    const tempOptions = [];
    // set default option value.
    if (!isWTIAdmin)
      tempOptions.push({
        id: DEFAULT_CLIENT_VALUE,
        label: DEFAULT_CLIENT_LABEL,
        value: userClientGroupId
      });
    else
      tempOptions.push({
        value: [WEBCAST_ID],
        label: DEFAULT_ADMIN_NAME,
        id: WEBCAST_ID
      });

    // const tempValues = isWTIAdmin ? values : userClientGroupNames;
    const tempValues = values;
    if (!tempValues) return;
    tempValues.forEach((info, index) => {
      let groupIdIdx;
      // get group id index.
      // if(isWTIAdmin){
      groupIdIdx = info.group_names.findIndex(
        groupName => groupName === info.name
      );
      // }

      tempOptions.push(
        { value: [info.group_ids[groupIdIdx]], label: info.name, id: +info.id }
        // isWTIAdmin
        //   ? { value: [info.group_ids[groupIdIdx]], label: info.name, id: +info.id }
        //   : { value: [userClientGroupId[index]], label: info }
      );
    });

    setGroupOptions(tempOptions);
  }
  // use query in the get admin clients options.
  useQuery(GET_ADMIN_CLIENTS, {
    variables: { first: 15, keyword },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isWTIAdmin,
    onCompleted: data => {
      if (!data) return;
      formatQryOpt(data.get_clients.clients);
    },
    onError: err => {
      console.log(err);
    }
  });

  useQuery(GET_ADMIN_CLIENTS, {
    variables: { first: 10000, keyword, group_ids: userCtx.client.group_ids },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: isWTIAdmin && groupOptions.length !== 0,
    onCompleted: data => {
      if (!data) return;
      const clientList = data.get_clients.clients.filter(client =>
        userClientGroupNames.includes(client.name)
      );
      formatQryOpt(clientList);
    },
    onError: err => {
      console.log(err);
    }
  });

  // execute only once if the account is not wti admin.
  // useEffect(() => {
  //   if (!isWTIAdmin) formatQryOpt(userClientGroupNames);
  // }, [userClientGroupNames]);

  return groupOptions;
};

export default useFilterHauler;
