//* CHART PLUGINS

/**
 * Renders stacked labels in the center of the doughnut chart.
 */
export const innerText = {
  id: "innerText",
  beforeDatasetsDraw: (chart, args, options) => {
    const {
      ctx,
      data,
      chartArea: { top, left, width, height }
    } = chart;
    ctx.save();
    let prevTextSize = 0;
    let innerRadius = chart._metasets[0].controller.innerRadius;
    if (options?.labels?.length) {
      options.labels.forEach(label => {
        let { text, fontWeight, fontSize, color } = label;

        // initial font (for measuring text width & height for adjustment)
        ctx.font = `${fontWeight} ${fontSize}px Nunito`;
        ctx.fillStyle = `${color}`;

        // adjusted font
        fontSize = textSize(ctx, text, fontSize, innerRadius, 0.65);
        ctx.font = `${fontWeight} ${fontSize}px Nunito`;
        let textWidth = ctx.measureText(text).width;

        // center text inside chart
        ctx.fillText(
          `${text}`,
          width / 2 + left - textWidth / 2,
          height / 2 + top + prevTextSize / 2
        );
        
        // offset subsequent labels using previous text's height
        prevTextSize = fontSize;
        ctx.restore();
      });
    }
  }
};

/**
 * Renders the empty doughnut chart state
 */
export const emptyDoughnut = {
  id: "emptyDoughnut",
  afterDraw: (chart, args, options) => {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;

    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      hasData |= dataset.data.length > 0;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
      const rad = r - radiusDecrease >= 0 ? r - radiusDecrease : 0;
      ctx.arc(centerX, centerY, rad, 0, 2 * Math.PI);
      ctx.stroke();
    }
  }
};


//* CHART HELPER FUNCTIONS

/**
 * Returns the max font size that would fit a chart label's area width.
 * The given minFontSize is returned if the calculated size exceeds it.
 * 
 * @param {*} ctx         canvas context object 
 * @param {*} text        chart label 
 * @param {*} fontSize    default font size 
 * @param {*} areaWidth   calculated width 
 * @param {*} fillRatio   % of space text would occupy 
 * @param {*} offset      textWidth & textHeight offset (outer labels' offset) 
 * @param {*} minFontSize minimum font size to be applied 
 * @returns               adjusted font size 
 */
export const textSize = (
  ctx,
  text,
  fontSize,
  areaWidth,
  fillRatio = 1,
  offset = 0,
  minFontSize = 0
) => {
  let textWidth = ctx.measureText(text).width + offset;
  let textHeight = ctx.measureText(text).fontBoundingBoxAscent + ctx.measureText(text).fontBoundingBoxDescent + offset;
  const hyp = Math.sqrt(
    Math.pow(textWidth / 2, 2) + Math.pow(textHeight / 2, 2)
  );
  
  const fit = (areaWidth * fillRatio) / hyp;
  if (fit < 1) {
    fontSize = Math.floor(fontSize * fit);
  }
  
  return fontSize > minFontSize ? fontSize : minFontSize;
};

String.prototype.replaceAfter = function (search, replace) {
  let from = Math.floor(this.length / 2);
  if (this.indexOf(" ", from) !== -1) {
    return this.slice(0, from) + this.slice(from).replace(search, replace);
  }
  return this;
};

/**
 * Split lengthy labels and return as array 
 */
export const wrapText = (text, maxLen = 6) => {
  let len = text.length;
  if (len <= maxLen) return text;

  let arr = text.replaceAfter(" ", "\n");

  return arr;
};

/**
  * Checks whether click event occurs inside
  * the doughnut chart's inner circle.
  *
  * @param  clickCoords   cursor position
  * @param  circleCenter  circle center point
  * @param  radius        circle radius
  * @return {boolean}
  */
export const pointInCircle = (clickCoords, circleCenter, radius) => {
  const distanceSquared =
    Math.pow(clickCoords.x - circleCenter.x, 2) +
    Math.pow(clickCoords.y - circleCenter.y, 2);
  return distanceSquared <= Math.pow(radius, 2);
};

export const chartColors = {
  default: "#A2A2A2",
  selected: "#FFB864",
  clicked: "#F49400"
};
