import React, { useState, useEffect } from "react";
import { GET_NOTIFICATIONS_PAGE } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import moment from "moment";

const useFetchNotifications = props => {
  const { initVariables, initOrderOfData } = props;
  const [notifications, setNotifications] = useState([]);

  const {
    data: notifications_data,
    loading: loadNotifications,
    refetch,
    fetchMore
  } = useQuery(GET_NOTIFICATIONS_PAGE, {
    variables: {
      ...initVariables,
      ...initOrderOfData
    },
    onCompleted: data => {
      if (data.get_notifications) {
        setNotifications(data.get_notifications.notifications);
      }
    },
    onerror: error => {
      console.log(error);
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  function getMoreNotification() {
    if (!notifications_data) return;
    fetchMore({
      variables: {
        skip: notifications_data.get_notifications.count
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setNotifications([
          ...prev.get_notifications.notifications,
          ...fetchMoreResult.get_notifications.notifications
        ])
        return {
          ...prev,
          get_notifications: {
            ...prev.get_notifications,
            count:
              fetchMoreResult.get_notifications.count +
              prev.get_notifications.count,
            notifications: [
              ...prev.get_notifications.notifications,
              ...fetchMoreResult.get_notifications.notifications
            ]
          }
        };
      }
    });
  }

  useEffect(() => {
    refetch();
  }, [notifications_data?.get_notifications?.total]);

  return {
    data: notifications,
    hasLoaded: loadNotifications,
    count: notifications_data?.get_notifications?.count,
    total: notifications_data?.get_notifications?.total,
    fetchMoreHandler: getMoreNotification
  };
};

export default useFetchNotifications;
