/* 
    this function will convert the time from the excel file to a readable format of time. 
    * example: 
    excel time receive : 633758410000
    converted time: Wed Jan 31 1990 12:00:10
*/

export const ConvertTimeExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1990, 1, 1);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;

    if(isNaN(parsed)) return null
    else{
        let date = new Date(parsed)
        let arrayDateInfo = date.toString().split(" ");
        return arrayDateInfo[4]
    }
}