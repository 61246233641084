import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import {
  SearchIcon,
  Delete as DeleteIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";
import { GET_PARTNERS } from "../../../../../graphql/Queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
  partners: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "18px 0 0 0px"
  },
  partnersContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  partnersTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#565656",
    letterSpacing: 0.7
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  search: {
    padding: "0px 9px",
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 190px)",
    height: 35,
    backgroundColor: "#fafafa",
    borderRadius: "25px",
    boxShadow: "none",
    border: "1px solid #ccc"
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 600,
    fontSize: 14,
    "& .MuiInputBase-input": {
      letterSpacing: 0.7
    }
  },
  addEntryButton: {
    fontSize: 12,
    letterSpacing: 0.7,
    fontWeight: 600,
    margin: "0px 20px 0 0",
    textTransform: "capitalize"
  },
  dialogStyle: {
    height: 405,
    width: 600
  },
  iconButton: {
    padding: 0
  },
  paperGroup: {
    display: "flex",
    backgroundColor: "#eaeaea",
    padding: "0px 16px 0 16px",
    position: "relative",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px"
  }
}));

const PartnersTable = ({ setPartners }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [orderBy, setOrderBy] = useState("asc");
  const [searchPartner, setSearchPartner] = useState("");
  const [keyword, setKeyword] = useState("");
  const [render, setRender] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [ids, setIDs] = useState();

  const { data: groups_data, fetchMore, refetch } = useQuery(GET_PARTNERS, {
    variables: {
      skip: 0,
      keyword: keyword
    }
  });

  useEffect(() => {
    if (groups_data) {
      const { get_groups } = groups_data;
      setTotalPage(get_groups.total ? Math.ceil(get_groups.total / 5) : 1);
    }
  }, [groups_data]);

  function handleOpenModal() {
    refetch({
      not: [
        {
          field: "id",
          value: groupList.map(g => g.id)
        }
      ]
  });
    // refetch();
    setOpen(true);
  }

  const handlePage = page => {
    if (page) {
      setCurrentPage(currentPage + 1);
      const totalLoaded = (currentPage + 1) * 5;
      if (
        groups_data.get_groups.total > groups_data.get_groups.count &&
        totalLoaded <= groups_data.get_groups.count
      ) {
        fetchMore({
          variables: {
            keyword: keyword,
            skip: groups_data.get_groups.count
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              get_groups: {
                ...prev.get_groups,
                total: fetchMoreResult.get_groups.total,
                count: fetchMoreResult.get_groups.count + prev.get_groups.count,
                groups: prev.get_groups.groups.concat(
                  fetchMoreResult.get_groups.groups
                )
              }
            };
          }
        });
      }
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSelectAll = e => {
    const { get_groups } = groups_data;
    if (e.target.checked) {
      const temp = get_groups.groups.map(group => {
        return {
          id: group.id,
          name: group.name
        };
      });
      setSelected(temp);
      return;
    }
    setSelected([]);
  };

  const handleCheck = (id, name, e) => {
    let temp = [...selected];
    if (e.target.checked) {
      temp.push({
        id: id,
        name: name
      });
    } else {
      temp = _.filter(temp, sg => {
        return sg.id !== id;
      });
    }
    setSelected(temp);
  };

  //Hanlde check all boxes in edit table
  const handleSelectDelete = e => {
    if (e.target.checked) {
      const temp = groupList
        .filter(
          row =>
            row.name.toLowerCase().indexOf("".toLowerCase()) > -1 &&
            row.name.toLowerCase().indexOf(searchPartner.toLowerCase()) > -1
        )
        .map(group => {
          return {
            id: group.id,
            name: group.name
          };
        });
      // const index = temp
      //   .map(g => g.id)
      //   .indexOf(deleteGroup[0]);
      // temp.splice(index, 1);
      setDeleteGroup(temp);
      return;
    } else {
      setDeleteGroup([]);
    }
  };

  // Single select checkbox in edit table
  const handleCheckDelete = (id, name, e) => {
    let temp = [...deleteGroup];

    if (e.target.checked) {
      temp.push({ id: id, name: name });
    } else {
      temp = _.filter(temp, t => {
        return t.id !== id;
      });
    }
    setDeleteGroup(temp);
    setRender(!render);
  };

  //Modal function of multiple delete
  const handleMultipleDelete = () => {
    const temp = groupList;
    const selection = deleteGroup.map(g => g.id);
    for (var i = selection.length - 1; i >= 0; i--) {
      temp.splice(groupList.map(p => p.id).indexOf(selection[i]), 1);
    }
    setDeleteGroup([]);
    setSearchPartner("");
  };

  const handleRemoveGroup = id => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(id);
    temp.splice(index, 1);
    setPartners(temp);
    setRender(!render);
    setDeleteGroup([]);
    setSearchPartner("");
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      <Dialog open={open} PaperProps={{ style: { width: 435, height: 450 } }}>
        <DialogTitle>Add Entry</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search Client"
                onChange={e => {
                  setKeyword(e.target.value);
                  setCurrentPage(0);
                }}
                InputProps={{
                  style: { borderRadius: 32 },
                  inputProps: {
                    style: { padding: "5px 16px" }
                  }
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Search />
                  //   </InputAdornment>
                  // )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                onClick={() => handlePage(false)}
                disabled={currentPage + 1 === 1}
                className={classes.iconButton}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <TextField
                inputProps={{
                  style: { padding: 0, textAlign: "center" },
                  readOnly: true
                }}
                style={{ width: "32px", marginRight: "2px", marginTop: 4 }}
                variant="outlined"
                value={currentPage + 1}
              />
              {` / ${totalPage}`}
              <IconButton
                onClick={() => handlePage(true)}
                disabled={currentPage + 1 === totalPage}
                className={classes.iconButton}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < groups_data?.get_groups?.count
                      }
                      color="primary"
                      onChange={handleSelectAll}
                      checked={
                        groups_data &&
                        groups_data?.get_groups?.count === selected.length
                      }
                    />
                  </TableCell>
                  <TableCell>Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups_data &&
                  groups_data?.get_groups?.groups
                    .slice(currentPage * 5, currentPage * 5 + 5)
                    .map(group => (
                      <TableRow hover role="checkbox" key={group.id}>
                        <TableCell
                          padding="checkbox"
                          key={`${group.id}-checkBox`}
                        >
                          <Checkbox
                            color="primary"
                            onChange={e => handleCheck(group.id, group.name, e)}
                            checked={
                              _.findIndex(selected, sg => {
                                return sg.id === group.id;
                              }) !== -1
                            }
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          key={`${group.id}-id`}
                        >
                          {group.name}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setSelected([]);
              setKeyword("");
              setCurrentPage(0);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selected.length}
            onClick={() => {
              // refetch({
              //     not: [
              //       {
              //         field: "id",
              //         value: groupList.map(g => g.id)
              //       }
              //     ]
              // });
              // setGroupList(selected);
              setPartners(selected);
              groupList.push(...selected)
              setOpen(false);
              setSelected([]);
              setKeyword("");
              setCurrentPage(0)
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Grid container className={classes.partners}>
          <Grid item xs={12} className={classes.partnersContent}>
            <div style={{ display: "flex" }}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title="Data client will be linked to all selected partners(and vise versa)"
                placement="top-start"
              >
                <HelpRounded className={classes.helpIcon} />
              </Tooltip>
              <InputLabel classes={{ root: classes.partnersTitle }}>
                Hauler/Groups
              </InputLabel>
            </div>
            <Paper component="form" className={classes.search}>
              <InputBase
                placeholder="Search Client"
                className={classes.searchInput}
                onChange={e => setSearchPartner(e.target.value)}
                value={searchPartner}
              />
              {/* <IconButton>
                <SearchIcon />
              </IconButton> */}
            </Paper>
          </Grid>
          {deleteGroup?.length > 0 && (
            <Grid item xs={12}>
              <Paper className={classes.paperGroup}>
                <Typography color="inherit" variant="subtitle1" component="div">
                  {`${deleteGroup.length} item selected`}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setModalDelete(true)}
                  style={{
                    // position: "absolute",
                    // right: 0,
                    borderRadius: 36,
                    color: "white"
                  }}
                >
                  Delete Selected{" "}
                </Button>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              style={{ margin: deleteGroup.length ? "0px 0" : "15px 0" }}
            >
              <TableContainer style={{ height: "35vh" }}>
                <Table stickyHeader size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onChange={handleSelectDelete}
                          indeterminate={
                            deleteGroup?.length > 0 &&
                            deleteGroup?.length < groupList?.length
                          }
                          checked={
                            groupList?.length > 0 &&
                            groupList?.length === deleteGroup?.length
                          }
                        />
                      </TableCell>
                      <TableCell padding="normal">
                        <TableSortLabel
                          active
                          direction={orderBy}
                          onClick={() =>
                            setOrderBy(prev =>
                              prev === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          Groups
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size="small"
                          color="primary"
                          classes={{ root: classes.addEntryButton }}
                          onClick={handleOpenModal}
                        >
                          Add Entry
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupList &&
                      stableSort(groupList, getComparator(orderBy, "name"))
                        .filter(
                          row =>
                            row.name.toUpperCase().indexOf("".toUpperCase()) >
                              -1 &&
                            row.name
                              .toLowerCase()
                              .indexOf(searchPartner.toLowerCase()) > -1
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={
                                    _.findIndex(deleteGroup, sg => {
                                      return sg.id === row.id;
                                    }) !== -1
                                  }
                                  onChange={e =>
                                    handleCheckDelete(row.id, row.name, e)
                                  }
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                align="left"
                                scope="row"
                                padding="none"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                id={labelId}
                                component="th"
                                align="right"
                                scope="row"
                                padding="none"
                              >
                                <DeleteIcon
                                  onClick={() => {
                                    setModal(true);
                                    setIDs(row.id);
                                  }}
                                  style={{ paddingRight: 8 }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        <ConfirmationDialog
          toggle={modalDelete}
          close={() => setModalDelete(false)}
          fn={() => handleMultipleDelete()}
          title="Delete Partner?"
          content="Are you sure you want to delete this entries?"
        />
        <ConfirmationDialog
          toggle={modal}
          close={() => setModal(false)}
          fn={() => handleRemoveGroup(ids)}
          title="Delete Partner?"
          content="Are you sure you want to delete this entry?"
        />
      </div>
    </>
  );
};

export default PartnersTable;
