import React, { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Hidden,
  Container,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import Cookie from "js-cookie";
// import background from "../../../assets/Main.png";
// import background from "../../../assets/Background-no-footer.png";
import background from "../../../assets/Background-with-Text.png";
// import LoginLogo from "../../../assets/Login logo.png";
import LoginLogo from "../../../assets/Seal-Original_4x_1.ico";
import EmailLogin from "./EmailLogin";
import UsersList from "./UsersList";

const useStyles = makeStyles(theme => ({
  themeTypography: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  desktop: {
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  mobile: {
    // paddingLeft: "30px",
    // paddingRight: "30px",
    
  }
}));

const Login = () => {
  const classes = useStyles();
  const screenSize = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [webcastLink] = useState("https://trackme.com.ph/contact-us/  ");
  const [usersSaved, setUsersSaved] = useState(
    Cookie.get("users") ? Cookie.get("users") : []
  );

  const handleDeleteUser = username => {
    const users = usersSaved;
    const newUsers = users.filter(user => user.username !== username);
    Cookie.set("users", newUsers);
    setUsersSaved(newUsers);
  };
  if (typeof usersSaved === "string") {
    const temp = JSON.parse(usersSaved);
    setUsersSaved(temp);
  }

  return (
    <Fragment>
      <Grid container style={{ height: "100vh" }}>
        <Hidden smDown>
          <Grid
            item
            lg={6}
            style={{
              // height: "100vh",
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              position: "relative"
            }}
          >
            {" "}
            <Typography
              onClick={() => window.open(webcastLink, "_blank")}
              variant="h5"
              style={{
                position: "absolute",
                bottom: "30px",
                textAlign: "center",
                width: "100%",
                cursor: "pointer"
              }}
              color="secondary"
            >
              Contact us{" "}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                position: "absolute",
                bottom: "5px",
                textAlign: "center",
                width: "100%"
              }}
              color="secondary"
            >
              Powered by Webcast Technologies Inc.
            </Typography>
          </Grid>
        </Hidden>
        <Grid
          item
          lg={6}
          xs={12}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Typography align="center">
            <img alt="" src={LoginLogo} style={{ height: 95 }} />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            className={classes.themeTypography} /*style={{ color: "#53A4F7" }}*/
          >
            Sign in account
          </Typography>

          <Container className={screenSize ? classes.desktop : classes.mobile}>
            <Typography component="div">
              {usersSaved.length > 0 ? (
                <UsersList
                  users={usersSaved}
                  handleDeleteUser={handleDeleteUser}
                />
              ) : (
                <EmailLogin />
              )}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Login;
