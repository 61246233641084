/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Badge,
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  FormControl,
  Select,
  IconButton,
  TextField,
  Tooltip,
  InputLabel,
  TableContainer,
  Table,
  TableBody,
  Fab,
  CircularProgress
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  FilterList as FilterListIcon,
  Visibility as VisibleIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  FileCopy,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import Loading from "../../../../utils/Loading";
import Filter from "../../Filter";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Upload from "./View/Upload";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../../../utils/table/Pagination";
import useFormStyles from "../../../../styles/useFormStyles";
import CloneDrawer from "./utils/CloneDrawer";

import {
  GET_ALERT_DESCRIPTIONS,
  GET_ALERT_REFERENCES,
  GET_GROUPS
} from "../../../../graphql/Queries";
import {
  DELETE_ALERT_REFERENCE,
  DELETE_ALERT_REFERENCE_MULTIPLE,
  CLONE_ALERT_DESCRIPTIONS
} from "../../../../graphql/Mutations";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "alert_descriptions.code",
    numeric: false,
    disablePadding: true,
    label: "Alert Description"
  },
  { id: "code", numeric: true, disablePadding: false, label: "Alert Code" },
  {
    id: "alertmessage",
    numeric: true,
    disablePadding: false,
    label: "Message Display"
  },
  {
    id: "level",
    numeric: true,
    disablePadding: false,
    label: "Alert Level"
  }
  // { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

const headCellsClone = [
  {
    id: "alerts",
    numeric: false,
    disablePadding: true,
    label: "Alerts",
    align: "left"
  }
];

function TableHeadCells(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!canEdit}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  field: {
    margin: "8px 0",
    width: "100%"
  },
  InputLabel: {
    color: "black"
  },
  tableCustom: {
    height: "50vh",
    border: "solid 3px #f2f2f2"
  },
  head: {
    backgroundColor: "#f2f2f2"
  },
  textFieldRoot: {
    "& input": {
      fontSize: "14px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "14px",
      padding: "5px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "14px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    }
  },
  clonePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#f2f2f2",
    border: "solid 3px #f2f2f2"
  },
  swal: {
    zIndex: 10000000000
  }
}));

export default function AlertReferences() {
  const formStyles = useFormStyles();
  const classes = useStyles();
  const history = useHistory();
  const vars = history?.location?.state?.params;
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [canEdit, setcanEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [alertData, setAlertData] = useState([]); // set initial data from backend
  const [filterData, setFilterData] = useState([]); // set filter data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(0); // setPage
  const [filterDrawer, setFilterDrawer] = useState(false); // set to open filter drawer
  const [alertKeyword, setAlertKeyword] = useState(""); // set to user search
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("alert_descriptions.code");
  const [filter, setFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [clearFirst, setClearFirst] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [tempFilter, setTempFilter] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  // const [circularLoading, setCircularLoading] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [toLogDelete, setToLogDelete] = useState([]);
  const [keyLog, setKeyLog] = useState(
    "Alert Description: All, Alert Level: All"
  );
  const [selDel, setSelDel] = useState([]);

  const { setSearchKeyword, searchKeyword } = contextData;
  const user = JSON.parse(Cookie.get("user"));
  const [cloneDrawer, setCloneDrawer] = useState(false); // set to open clone drawer
  const [groups, setGroups] = useState({});
  const [alertList, setAlertList] = useState([]);
  const [selectedClone, setSelectedClone] = useState([]);
  const [clonePage, setClonePage] = useState(1);
  const [cloneTotalPage, setCloneTotalPage] = useState(0);
  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    applySavedFilter();
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      // setLoading(true);
      setAlertKeyword(searchKeyword.value);
      setPage(0);
    }
  }, [searchKeyword, clearFirst]);

  useEffect(() => {
    if (groups?.from?.id && groups?.to?.id) {
      const varaibles = {
        first: 10,
        skip: clonePage === 1 ? 0 : clonePage * 10 - 10,
        group_ids: [parseInt(groups?.from?.id, 10)],
        orderBy: [{ field: "code", direction: "asc" }]
        // not: [
        //   {
        //     field: "group_id",
        //     value: [groups?.to?.id]
        //   }
        // ]
      };
      getAlerts({ variables: varaibles });
    }
    if (!groups?.from?.id || !groups?.to?.id) {
      setAlertList([]);
      setClonePage(1);
      setCloneTotalPage(0);
      setSelectedClone([]);
    }
  }, [groups, clonePage]);

  const handleClose = () => {
    setDialog(false);
  };

  const getVariables = () => {
    let variables = {
      skip: rowsPerPage * page,
      keyword: alertKeyword,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }],
      condition: []
    };

    const tempCondition = [];
    if (filter.length > 0) {
      // Object.assign(variables, {
      //   condition: filter
      // });
      filter.map(row => {
        if (row.field === "level") {
          return tempCondition.push(row)
        } else {
          return tempCondition.push({ field: "alert_descriptions.code", value: row?.value })
        }
      });
    }

    Object.assign(variables, {
      condition: tempCondition
    });
    return variables;
  };

  const {
    data: currentData,
    loading: alertRefLoading,
    refetch,
    fetchMore
  } = useQuery(GET_ALERT_REFERENCES, {
    variables: getVariables(),
    onCompleted: () => {
      if (alertKeyword) {
        AddLogs("Admin - Alert References", "search", `${searchKeyword.value}`);
      }
      setLoading(false);
      setDisableButton(false);
      // setCircularLoading(false);
      // setPage(0);
      setSelected([]);
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  const [
    getGroups1,
    { data: groupsData1, loading: groupsLoading1 }
  ] = useLazyQuery(GET_GROUPS);

  const [
    getGroups2,
    { data: groupsData2, loading: groupsLoading2 }
  ] = useLazyQuery(GET_GROUPS);

  const [cloneAlertDescriptions] = useMutation(CLONE_ALERT_DESCRIPTIONS, {
    onCompleted: res => {
      if (res.clone_alert_descriptions.success) {
        Swal.fire({
          // title: "Deleted",
          icon: "success",
          html: "Clone was successful",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            container: `${classes.swal}`
          }
        }).then(async result => closeCloneDrawer());
      } else {
        Swal.fire({
          // title: "Deleted",
          icon: "error",
          html: res.clone_alert_descriptions.error
            ? res.clone_alert_descriptions.error
            : "",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            container: `${classes.swal}`
          }
        });
      }
    }
  });

  const [getAlerts, { loading: alertsLoading }] = useLazyQuery(
    GET_ALERT_DESCRIPTIONS,
    {
      onCompleted: data => {
        setAlertList(data.get_alert_descriptions.alert_descriptions);
        setCloneTotalPage(data.get_alert_descriptions.total);
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  );

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const [deleteAlertReferenceMultiple] = useMutation(
    DELETE_ALERT_REFERENCE_MULTIPLE,
    {
      onCompleted(res) {
        setLoading(false);
        if (res.delete_alert_reference_all.success) {
          AddLogs("Admin - Alert Descriptions", "delete", selDel);
          handleCloseDeleteAll();
          Swal.fire({
            title: "Deleted",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          }).then(result => {
            setSelected([]);
            setToDelete([]);
            setPage(0);
            setLoading(true);
            refetch(getVariables());
            return result;
          });
        }
      }
    }
  );

  const [getSelected] = useLazyQuery(GET_ALERT_REFERENCES, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_alert_references.alert_references.map(val => {
          temp.push(val.code);
          return temp;
        });
        setSelDel(temp);
        deleteAlertReferenceMultiple({
          variables: {
            condition: {
              field: "id",
              value: selected
            }
          }
        });
      }
    }
  });

  const [get_description] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        setFilterData(data.get_alert_descriptions.alert_descriptions);
      }
    },
    variables: {
      first: 50
    }
  });

  useEffect(() => {
    if (currentData) {
      setLoading(false);
      setAlertData(currentData.get_alert_references.alert_references);
      setTotalRows(currentData.get_alert_references.total);
    }
  }, [currentData]);

  const [deleteAlertReference] = useMutation(DELETE_ALERT_REFERENCE, {
    onCompleted(res) {
      if (res.delete_alert_reference.success) {
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          } else {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (
      currentData.get_alert_references.count === 1 &&
      currentData.get_alert_references.total > 1
    )
      setPage(page - 1);
    deleteAlertReference({
      variables: { id: toDelete.toString() }
    });
    AddLogs("Admin - Alert References", "delete", toLogDelete);
    setToDelete([]);
  };

  const handleDeleteAlert = () => {
    const temp = [];
    if (selected.length > 0) {
      selected.map(id =>
        temp.push({ field: "alert_references.id", value: id })
      );
      getSelected({
        variables: {
          filter: temp,
          first: selected.length
        }
      });
    }
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (alertData) {
      return stableSort(alertData, getSorting(order, orderBy));
    }
    return null;
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const isSelectedClone = name => selectedClone.indexOf(name) !== -1;

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setDisableButton(false);
        // setCircularLoading(false);
        setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_alert_references: {
            count: fetchMoreResult.get_alert_references.count,
            total: fetchMoreResult.get_alert_references.total,
            alert_references: [
              ...prev.get_alert_references.alert_references,
              ...fetchMoreResult.get_alert_references.alert_references
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(0);
          refetch(getVariables());
        }
      });
    });
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const openCloneDrawer = () => {
    setCloneDrawer(true);
  };

  const closeCloneDrawer = async () => {
    setCloneDrawer(false);
    setGroups({});
    setAlertList([]);
    setCloneTotalPage(0);
    setClonePage(1);
    setSelectedClone([]);
  };

  const handleGroups = async (name, values) => {
    setGroups({ ...groups, [name]: values });
    setClonePage(1);
    setSelectedClone([]);
  };

  const handleClickClone = id => {
    const selectedIndex = selectedClone.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedClone, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedClone.slice(1));
    } else if (selectedIndex === selectedClone.length - 1) {
      newSelected = newSelected.concat(selectedClone.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelectedClone(newSelected);
  };

  const isCheckedClone = () => {
    let checked = { all: false, indeterminate: false };
    if (!selectedClone.length < alertList?.length) {
      const displayedData = alertList.map(data => parseInt(data.id, 10));
      if (displayedData.every(data => selectedClone.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selectedClone.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }
    return checked;
  };

  const handleSelectAllClickClone = event => {
    const tempSelected = selectedClone;
    const deselect = () => {
      const displayedData = alertList.map(n => parseInt(n.id, 10));
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(parseInt(id, 10)) < 0)
        .map(id => id);
      setSelectedClone(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      alertList.map(n => tempSelected.push(parseInt(n.id, 10)));
      setSelectedClone(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleSave = () => {
    cloneAlertDescriptions({
      variables: {
        group_id_source: parseInt(groups?.from?.id, 10),
        group_id_destination: parseInt(groups?.to?.id, 10),
        alert_description_ids: selectedClone
      }
    });
  };

  const handleClearFilter = () => {
    // setCircularLoading(true);
    // setLoading(true);
    setAlertData([]);
    setFilterValue([]);
    setFilter([]);
    setTempFilter([]);
    setFilterDrawer(false);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const getTextual = id => {
    let text = "";
    switch (id) {
      case 0:
        text = (
          <span style={{ color: "#9e9e9e" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Discrete
              </Highlight>
            </b>
          </span>
        );
        break;
      case 1:
        text = (
          <span style={{ color: "#63F160" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Normal
              </Highlight>
            </b>
          </span>
        );
        break;
      case 2:
        text = (
          <span style={{ color: "#ffd600" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Moderate
              </Highlight>
            </b>
          </span>
        );
        break;
      case 3:
        text = (
          <span style={{ color: "#ff5722" }}>
            <b>
              <Highlight search={contextData.searchKeyword.value}>
                Critical
              </Highlight>
            </b>
          </span>
        );
        break;
      default:
        break;
    }
    return text;
  };

  const handleFilters = e => {
    const { name, value } = e.target;
    switch (name) {
      case "level":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "code":
        setTempFilter({
          ...tempFilter,
          "alert_descriptions.id": value
        });
        return;
      default:
        break;
    }
    setTempFilter({ ...tempFilter, [name]: value });
  };

  const AlertReferenceTable = props => {
    // eslint-disable-next-line no-shadow
    const { canEdit } = props;
    return alertRefLoading || loading ? (
      <Loading />
    ) : (
      <TableBody>
        {!currentData.get_alert_references.count ? (
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={6} align="center">
              No data found
            </TableCell>
          </TableRow>
        ) : (alertData && 
          stableSort(alertData, getSorting(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                key={`row-${row.id}`}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    disabled={!canEdit}
                    onClick={event => handleClick(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  <Highlight search={contextData.searchKeyword.value}>
                    {row.alert_description.code}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={contextData.searchKeyword.value}>
                    {row.code}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={contextData.searchKeyword.value}>
                    {row.alertmessage}
                  </Highlight>
                </TableCell>
                <TableCell>{getTextual(row.level) || "-"}</TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    aria-label="view"
                    onClick={() => {
                      // setSearchKeyword({ ...searchKeyword, value: "" });
                      AddLogs("Admin - Alert References", "view", row.code);
                      history.push({
                        pathname: `/admin/wti/alert_references/view/${row.id}`,
                        state: {
                          params: {
                            id: row.id,
                            moduleName:
                              process.env.REACT_APP_ALERT_REFERENCES_MODULE,
                            filter: tempFilter
                          },
                          // queryVars: getVariables()
                        }
                      });
                    }}
                  >
                    <VisibleIcon />
                  </IconButton>
                  <IconButton
                    disabled={!canEdit}
                    aria-label="delete"
                    onClick={() => {
                      handleToDelete(row.id);
                      handleOpen();
                      setToLogDelete(row.code);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    );
  };

  const getTextualExcel = id => {
    let text = "";
    switch (id) {
      case 0:
        text = "Discrete";
        break;
      case 1:
        text = "Normal";
        break;
      case 2:
        text = "Moderate";
        break;
      case 3:
        text = "Critical";
        break;
      default:
        break;
    }
    return text;
  };

  const applySavedFilter = () => {
    const temp = [];
    const filterVal = vars?.filter;
    if (filterVal) {
      Object.keys(filterVal)
      .filter(key => filterVal[key])
      .map(key => {
        // temp.push({ field: key, value: filterVal[key] });
        if (key === "alert_description") {
          temp.push({ field: "alert_descriptions.code", value: filterVal[key].code });
        } else {
          temp.push({ field: key, value: filterVal[key] });
        }
        return temp;
      });
      setFilter(temp);
      setTempFilter(filterVal);
      setFilterValue(filterVal?.alert_description);
      setKeyLog(
        `Alert Description: ${filterVal?.code || `All`}, Alert Level: ${filterVal?.level || `All`}`
      );
    }
  }

  const handleSetFilters = () => {
    // setCircularLoading(true);
    // setLoading(true);
    // setAlertData([]);
    const temp = [];
    let code = "";
    let level = "";
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        if (key === "level") {
          level = getTextualExcel(Number(tempFilter[key]));
          temp.push({ field: key, value: tempFilter[key] });
        } else {
          code = tempFilter[key];
          temp.push({ field: "alert_descriptions.code", value: tempFilter[key].code });
        }
        // temp.push({ field: key, value: tempFilter[key] });
        // code =
        return temp;
      });
    setFilter(temp);
    setKeyLog(
      `Alert Description: ${code || `All`}, Alert Level: ${level || `All`}`
    );
    AddLogs(
      "Admin - Alert References",
      "filter",
      `Alert Description: ${code || `All`}, Alert Level: ${level || `All`}`
    );
    setPage(0);
    closeFilterDrawer();
  };

  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: process.env.REACT_APP_ALERT_REFERENCES_MODULE,
        }
      }
    });
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [
      [
        "Alert Description *",
        "Alert Code *",
        "Message Display *",
        "Alert Level *",
        "GPS Device Type *",
        "Remarks"
      ]
    ];
    // setLimit()

    getData().map(row =>
      temp.push([
        row.alert_description.code || "",
        row.code || "",
        row.alertmessage || "",
        getTextualExcel(row.level) || "",
        row.device_type ? row.device_type.name : "",
        row.description || ""
      ])
    );

    return temp;
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          data={tableData}
          style={{ textDecoration: "none", color: "black" }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      AddLogs(
                        "Admin - Alert References",
                        "download_template",
                        ""
                      )
                    }
                  >
                    <Link
                      to="/assets/alert_references_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      AddLogs(
                        "Admin - Alert References",
                        "download_table",
                        keyLog
                      )
                    }
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  // if (loading) return <Loading />;

  return (
    <AccessControl
      resource="/admin/wti/alert_references/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              Alert References
            </Typography>
            {user?.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
              <Button
                className={classes.btn_download}
                variant="contained"
                startIcon={<FileCopy />}
                onClick={openCloneDrawer}
                style={{ backgroundColor: "#808080" }}
              >
                Clone
              </Button>
            )}
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
              disabled={disableButton}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={() => setUploadOpen(true)}
                disabled={disableButton}
              >
                Upload
              </Button>
            )}
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                onClick={openFilterDrawer}
                disabled={disableButton}
              >
                <Badge
                  color="secondary"
                  variant={filter.length ? "dot" : "standard"}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination 
              rowsPerPage={rowsPerPage}
              currentPage={page}
              total={Math.ceil(totalRows / rowsPerPage)}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                setPage(0);
              }}
              onPageChange={n => setPage(n)}
              onPageUp={n => setPage(n)}
              onPageDown={n => setPage(n)}
            />
            {/* <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={alertData?.length}
              fetchMoreData={fetchMoreData}
              totalRows={totalRows}
              totalPage={Math.ceil(totalRows / rowsPerPage)}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setLoading}
              setLoading={setLoading}
            /> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                  disabled={disableButton}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) <= 1 || disableButton}
                onClick={() => {
                  setCircularLoading(true);
                  setDisableButton(true);
                  handleDownPage();
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                disabled={disableButton}
                onFocus={e => {
                  e.target.select();
                }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                value={Number(page)}
                style={{ width: "32px", marginRight: "8px" }}
              />
              {`/ ${Math.ceil(totalRows / rowsPerPage)}`}
              <Button
                name="up_page"
                disabled={
                  Number(page) === Math.ceil(totalRows / rowsPerPage) ||
                  disableButton
                }
                onClick={() => {
                  setCircularLoading(true);
                  setDisableButton(true);
                  handleUpPage();
                }}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div> */}
          </Toolbar>
          <Toolbar
            className={clsx(classes.toolbar_root, {
              [classes.highlight]: selected.length > 0,
              [classes.invisible]: selected.length <= 0
            })}
          >
            {selected.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {/* <span style={{ color: "#FF845E" }}>{selected.length}</span>{" "} */}
                {selected.length} alert reference/s selected on this page
              </Typography>
            )}

            {selected.length > 0 &&
              (canEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    position: "absolute",
                    right: 0,
                    borderRadius: 100,
                    color: "white"
                  }}
                  onClick={handleOpenDeleteAll}
                >
                  Delete Selected
                </Button>
              ) : null)}
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                // rowCount={alertData.count}
                handleSort={handleSort}
                isChecked={isChecked}
                canEdit={canEdit}
              />
              <AlertReferenceTable
                selected={selected}
                alertData={alertData}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                canEdit={canEdit}
              />
            </Table>
          </TableContainer>
        </Paper>
        <Upload
          open={uploadOpen}
          setUploadOpen={setUploadOpen}
          getQueryConditions={getVariables}
          // currentPage={currentPage}
        />
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_ALERT_REFERENCES_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>
              <b>Alert Description</b>
            </InputLabel>
            <br />
            <Autocomplete
              id="code"
              options={filterData}
              value={filterValue}
              getOptionLabel={option => option.code || ""}
              getOptionSelected={(o, v) => v.value === o.value}
              renderOption={option => option.code}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  placeholder="All"
                  onChange={event => {
                    if (event.target.value.length >= 2) {
                      get_description({
                        variables: {
                          first: 10,
                          filter: {
                            field: "code",
                            value: event.target.value
                          }
                        }
                      });
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              onChange={(e, val) => {
                // if (val) {
                //   setTempFilter({
                //     ...tempFilter,
                //     // "alert_descriptions.code": val.code
                //     alert_description: val
                //   });
                //   setFilterValue(val);
                //   setFilterData([]);
                // }
                setTempFilter({
                  ...tempFilter,
                  // "alert_descriptions.code": val.code
                  alert_description: val
                });
                setFilterValue(val);
                setFilterData([]);
              }}
              // disableClearable
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>
              <b>Alert Level</b>
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter?.level ? tempFilter.level.toString() : ""}
              onChange={e => handleFilters(e)}
              name="level"
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="0">Discrete</MenuItem>
              <MenuItem value="1">Normal</MenuItem>
              <MenuItem value="2">Moderate</MenuItem>
              <MenuItem value="3">Critical</MenuItem>
            </Select>
          </FormControl>
        </Filter>
        <CloneDrawer
          cloneDrawer={cloneDrawer}
          closeCloneDrawer={closeCloneDrawer}
          moduleName={process.env.REACT_APP_ALERT_REFERENCES_MODULE}
          handleSave={handleSave}
          selectedClone={selectedClone}
        >
          <Typography
            style={{ textAlign: "left", padding: "10px 0", fontWeight: "Bold" }}
          >
            Client
          </Typography>
          <FormControl className={classes.field}>
            <InputLabel shrink required className={classes.InputLabel}>
              From
            </InputLabel>
            <Autocomplete
              options={groupsData1?.get_groups.groups || []}
              getOptionLabel={option => option.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
              name="from"
              onChange={(e, val) => {
                handleGroups("from", val);
              }}
              // onBlur={handleBlur}
              value={groups?.from?.name}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ marginTop: 16 }}
                  classes={{ root: classes.textFieldRoot }}
                  placeholder="Select Client"
                  value={groups?.from}
                  // fullWidth
                  onClick={() => {
                    const variables = {
                      first: 15,
                      keyword: null,
                      not: [
                        {
                          field: "id",
                          value: [
                            groups?.to?.id ? groups?.to?.id.toString() : ""
                          ]
                        }
                      ]
                    };
                    getGroups1({ variables });
                  }}
                  onChange={e => {
                    const variables = {
                      first: 15,
                      keyword: e.target.value,
                      not: [
                        {
                          field: "id",
                          value: [
                            groups?.to?.id ? groups?.to?.id.toString() : ""
                          ]
                        }
                      ]
                    };
                    getGroups1({ variables });
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {groupsLoading1 ? (
                          <CircularProgress
                            disableShrink
                            color="inherit"
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.field}>
            <InputLabel shrink required className={classes.InputLabel}>
              To
            </InputLabel>
            <Autocomplete
              options={groupsData2?.get_groups.groups || []}
              getOptionLabel={option => option.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
              name="to"
              onChange={(e, val) => {
                handleGroups("to", val);
              }}
              // onBlur={handleBlur}
              value={groups?.to?.name}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ marginTop: 16 }}
                  classes={{ root: classes.textFieldRoot }}
                  placeholder="Select Client"
                  value={groups?.to}
                  fullWidth
                  onClick={() => {
                    const variables = {
                      first: 15,
                      keyword: null,
                      not: [
                        {
                          field: "id",
                          value: [
                            groups?.from?.id ? groups?.from?.id.toString() : ""
                          ]
                        }
                      ]
                    };
                    getGroups2({ variables });
                  }}
                  onChange={e => {
                    const variables = {
                      first: 15,
                      keyword: e.target.value,
                      not: [
                        {
                          field: "id",
                          value: [
                            groups?.from?.id ? groups?.from?.id.toString() : ""
                          ]
                        }
                      ]
                    };
                    getGroups2({ variables: variables });
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {groupsLoading2 ? (
                          <CircularProgress
                            disableShrink
                            color="inherit"
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </FormControl>
          <div>
            <Typography style={{ textAlign: "left", paddingTop: "20px" }}>
              List of alerts
            </Typography>
            <TableContainer className={classes.tableCustom}>
              <Table stickyHeader>
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={isCheckedClone().indeterminate}
                        checked={isCheckedClone().all}
                        onChange={handleSelectAllClickClone}
                        inputProps={{ "aria-label": "select all" }}
                      />
                    </TableCell>
                    {headCellsClone.map(headCell => (
                      <TableCell align={headCell.align} key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertsLoading ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : alertList?.length ? (
                    alertList?.map(data => (
                      <TableRow>
                        <TableCell
                          padding="checkbox"
                          // disabled={!edit}
                          onClick={event => {
                            // if (edit)
                            handleClickClone(parseInt(data.id, 10));
                          }}
                        >
                          <Checkbox
                            // disabled={!edit}
                            color="primary"
                            checked={isSelectedClone(parseInt(data.id, 10))}
                            inputProps={{ "aria-label": "select all desserts" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={data.id}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          {data.code}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.clonePagination}>
              <Typography
                // className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
                // style={{padding: "0px 10px"}}
              >
                {!!selectedClone.length &&
                  `${selectedClone.length} alert reference/s selected`}
              </Typography>
              <div>
                <IconButton
                  name="down_page"
                  disabled={clonePage === 1}
                  onClick={() => setClonePage(clonePage - 1)}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                {`${Math.ceil(cloneTotalPage / 10) ? clonePage : 0} / ${
                  Math.ceil(cloneTotalPage / 10)
                    ? Math.ceil(cloneTotalPage / 10)
                    : 0
                }`}
                <IconButton
                  name="up_page"
                  disabled={clonePage === Math.ceil(cloneTotalPage / 10)}
                  onClick={() => setClonePage(clonePage + 1)}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </CloneDrawer>
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            style={{
              margin: 0,
              top: "auto",
              right: 40,
              bottom: 30,
              left: "auto",
              position: "fixed",
              fontSize: "200px"
            }}
            onClick={() => {
              history.push({
                pathname: "/admin/wti/alert_references/add",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_ALERT_REFERENCES_MODULE,
                    filter: tempFilter
                  },
                  // queryVars: getVariables()
                }
              });
            }}
          >
            <AddIcon
              style={{
                width: "50px",
                height: "50px"
              }}
              // onClick={() => setSearchKeyword({ ...searchKeyword, value: "" })}
            />
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this alert reference?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteAlert(e)}
          title="Delete?"
          content="Are you sure you want to delete these alert references?"
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}
      </div>
    </AccessControl>
  );
}
