import React from "react";
import { Tooltip, Typography, makeStyles } from "@material-ui/core";

const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const useStyles = makeStyles(theme => ({
  line: {
    position: "absolute",
    top: 0,
    left: "-10px",
    height: "100%",
    width: "20px",
    padding: "5px",
    cursor: "pointer",
    "&:hover div": {
      borderColor: "#606060 !important"
    }
  },
  innerLine: {
    position: "absolute",
    top: 0,
    left: 9,
    height: "100%",
    borderLeft: "2px solid #bdbdbd"
  },
  solidBorder: {
    borderLeftStyle: "solid"
  },
  dashedBorder: {
    borderLeftStyle: "dashed"
  }
}));

const TripLine = prop => {
  const {
    // start_odometer_reading,
    // end_odometer_reading,
    handleSelectedTrip,
    stop
  } = prop;
  const withTripNumber = stop.length > 0;
  const classes = useStyles();

  return (
    // <Tooltip
    //   title={
    //     <div>
    //       <Typography variant="caption">{`Distance Travelled`}</Typography>
    //       <br />
    //       <Typography variant="caption">
    //         {`${numberWithCommas(
    //           end_odometer_reading - start_odometer_reading
    //         )} km`}
    //       </Typography>
    //     </div>
    //   }
    //   placement="left"
    // >
      <div
        className={classes.line}
        onClick={() => {
          handleSelectedTrip(stop);
        }}
      >
        <div
          className={`
              ${classes.innerLine} ${
            withTripNumber ? classes.solidBorder : classes.dashedBorder
          }`}
        />
      </div>
    // </Tooltip>
  );
};

export default TripLine;
