/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Switch,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Cookie from "js-cookie";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import Header from "../../Header";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import { ADD_USER } from "../../../../graphql/Mutations";
import {
  GET_USER_LEVELS,
  GET_ACCESS_OBJECTS,
  GET_ADMIN_CLIENT,
  GET_ADMIN_CLIENT_WO_GROUPS
} from "../../../../graphql/Queries";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../utils/validation";
import redirectToTable from "../../../../utils/redirect";
import SearchGeofence from "./SearchGeofence";
import AddLogs from "../../../../utils/functions/AddLogs";
import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
import debounce from "../../../../utils/functions/debounce.util";
import useUserContext from "../../../../context/User/useUserContext";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header, 32 for top + bottom margins
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  multiline: {
    margin: "12px 0"
  },
  column: {
    fontSize: "1.5rem"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto 90px 0",
    width: "200px"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dialog: {
    borderRadius: "10px"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const getMaxLength = (n = 0) => {
  if (/^\+639/.test(n)) return 13;
  if (/^639/.test(n)) return 12;
  if (/^9/.test(n)) return 10;
  return 11;
};

export default function AddUser(props) {
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const [forceRerender, setForceRerender] = useState(false);
  const [state, setState] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email_address: "",
    contact_number: "",
    access_rights: "",
    password_expiry: 0,
    account_expiration: moment().format("MM-DD-YYYY"),
    remarks: "",
    user_level_id: 20
  });
  // const [initialState] = useState(_.cloneDeep(state));
  const [initialState, setInitialState] = useState({});

  const [userlevel, setUserlevel] = useState({});
  const [errors, setErrors] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email_address: "",
    contact_number: "",
    user_level_id: "",
    account_expiration: ""
  });
  const [geofenceID, setGeofenceID] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [is_auto_logout, setIsAutoLogout] = React.useState(false);
  const { location } = props;
  const { pathname } = location;
  const [open, setOpen] = React.useState(false);
  const [keyLog, setKeyLog] = useState([]);
  const [userPrimaryGroup, setUserPrimaryGroup] = useState([]);
  const history = useHistory();
  // graphql
  // const getVariableUserLevel = () => {
  //   let variables = {};
  //   if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
  //     variables = {
  //       group_ids: user.group_ids
  //     };
  //   } else {
  //     variables = {
  //       not: [
  //         { field: "user_levels.name", value: ["Data Admin", "WTI Admin"] }
  //       ],
  //       group_ids: user.group_ids
  //     };
  //   }
  //   return variables;
  // };

  const [getUserLevels, { data: data2, loading: loading2 }] = useLazyQuery(
    GET_USER_LEVELS
  );

  const { loading: clientLoading, data: clientData } = useQuery(
    GET_ADMIN_CLIENT_WO_GROUPS,
    {
      variables: { id: user.client.id }
    }
  );

  useEffect(() => {
    if (clientData) {
      const { access_end_date } = clientData.get_client;
      setState({
        ...state,
        account_expiration: moment(access_end_date).format("MM-DD-YYYY")
      });
      setInitialState(
        _.cloneDeep({
          ...state,
          account_expiration: moment(access_end_date).format("MM-DD-YYYY")
        })
      );
    }
  }, [clientData]);

  const toggleChecked = () => {
    setIsAutoLogout(prev => !prev);
  };
  const [addUser] = useMutation(ADD_USER, {
    onCompleted(data) {
      if (data) {
        if (data.add_user.success) {
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              // set values again for users table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_USERS_MODULE,
                    vars: history?.location?.state.params.vars,
                    filter: history?.location.state.params.filter
                  }
                }
              });
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              // set values again for users table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_USERS_MODULE,
                    vars: history?.location?.state?.params?.vars,
                    filter: history?.location?.state?.params?.filter
                  }
                }
              });
            }
          });
          AddLogs("Admin - Users", "insert", keyLog);
        } else {
          const temp = [];
          data.add_user.error.map(element => {
            temp.push({ [element.field]: element.message });
            if (element.message === "Username already in use") {
              temp.push({
                ["username"]: "Username already exists"
              });
            }
            if (element.message === "Email address already in use") {
              temp.push({
                ["email_address"]: "Email address already exists"
              });
            }
            if (element.message === "Contact number already in use") {
              temp.push({
                ["contact_number"]: "Contact number already exists"
              });
            }
          });
          setErrors(Object.assign(errors, ...temp));
          setForceRerender(!forceRerender);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        });
      }
    }
  });

  // validation
  const check = field => {
    if (!errors[field])
      if (field === "user_level_id") {
        // if (field === "contact_number") {
        //   // if (state[field].length < 10) {
        //   //   return "Contact number must be 10 digits";
        //   // }
        // } else
        return Object.keys(userlevel).length > 0
          ? ""
          : "This field is required";
      } else if (field === "contact_number") {
        return errors[field];
      } else {
        return state[field] === "" ? "This field is required" : "";
      }
    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setErrors(Object.assign(errors, ...temp));
    const isGeofence = checkIfStoreApp();
    if (isGeofence) {
      if (!geofenceID) {
        const temp = errors;
        temp.geofence_id = "This field is required";
        setErrors(temp);
      }
    }
    const flag = keys
      .filter(element => element in state)
      .every(
        element => errors[element] === "" || errors[element] === undefined
      );
    const isStoreApp = isGeofence ? geofenceID : true;
    return flag && isStoreApp;
  };

  const clearErrors = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: "" }));
    setErrors(Object.assign(errors, ...temp));
  };

  // handlers
  const handleChange = event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      // case "contact_number":
      //   if (!validate("mobile_number_ph", value)) {
      //     error = "Invalid Mobile Number";
      //   }
      //   break;
      //   case "first_name":
      //     error = validate("letters", value) ? "" : "Alphabet characters only";
      //     break;
      //   case "last_name":
      //     error = validate("letters", value) ? "" : "Alphabet characters only";
      //     break;
      //   // case "contact_number":
      //   //   error = validate("numeric", value) ? "" : "Numeric characters only";
      //   //   break;
      //   case "username":
      //     error = validate("alphanumeric", value)
      //       ? ""
      //       : `The following characters are not allowed: " ' "`;
      //     break;
      //   case "email_address":
      //     error = validate("email", value) ? "" : "Invalid Email Address";
      //     break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleBlur = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let error;
    switch (name) {
      case "first_name":
        error = validate("letters", value) ? "" : "Alphabet characters only";
        break;
      case "last_name":
        error = validate("letters", value) ? "" : "Alphabet characters only";
        break;
      // case "contact_number":
      //   if (value === "") {
      //     error = "";
      //   } else if (!validate("mobile_number_ph", value)) {
      //     error = "Invalid Mobile Number";
      //   }
      //   break;
      case "username":
        error = validate("alphanumeric", value)
          ? ""
          : `The following characters are not allowed: " ' "`;
        break;
      case "email_address":
        if (value === "") error = "This field is required";
        else if (!validate("email", value)) error = "Invalid Email Address";
        break;
      case "account_expiration":
        if (value === "") {
          error = "This field is required";
        }
      default:
        break;
    }

    setErrors({ ...errors, [name]: error });
  };

  const handleDate = date => {
    setState({
      ...state,
      account_expiration: date
    });
    setErrors({ ...errors, account_expiration: "" });
  };

  const handleSubmit = event => {
    event.preventDefault();
    clearErrors();
    const groups = [];
    const { group_ids, group_names } = user;
    for (let i = 0; i < group_ids.length; i += 1) {
      groups.push({ id: group_ids[i], name: group_names[i] });
    }

    const objParams = {
      username: state.username,
      first_name: state.first_name,
      last_name: state.last_name,
      email_address: state.email_address,
      password_expiry: state.password_expiry,
      password_expiration: moment()
        .add(state.password_expiry, "m")
        .format("MM-DD-YYYY 00:00:00"),
      account_expiration: state.account_expiration,
      contact_number: state.contact_number,
      user_level_id: userlevel.id,
      groups: groups,
      remarks: state.remarks,
      is_auto_logout: is_auto_logout
    };
    if (checkIfStoreApp()) {
      objParams.geofence_id = geofenceID;
    }
    addUser({
      variables: objParams
    });
  };

  const handleClickOpen = () => {
    if (validateFields()) {
      setOpen(true);
      setKeyLog(state.username);
    } else {
      setForceRerender(!forceRerender);
    }
  };

  const handleUserlevels = async (event, values) => {
    setErrors({ ...errors, user_level_id: "" });
    setUserlevel(values);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkIfStoreApp = () => {
    if (!_.isEmpty(accessObject)) {
      const isExist = accessObject.get_access_objects.filter(obj => {
        return obj.name.toLowerCase() === "store app";
      });
      if (!_.isEmpty(isExist)) {
        return Object.keys(isExist[0].permission).some(function(k) {
          return isExist[0].permission[k] === true;
        });
      }
    }
    return false;
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  let { data: accessObject } = useQuery(GET_ACCESS_OBJECTS, {
    variables: {
      user_level_id: !_.isEmpty(userlevel) ? userlevel.id : null
    },
    skip: _.isEmpty(userlevel)
  });

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: process.env.REACT_APP_USERS_MODULE
    });
  }, []);

  const userlevelTextFieldRef = React.useRef();

  const userLevelChanged = e => {
    let variables = {};
    if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
      variables = {
        first: 5,
        keyword: userlevelTextFieldRef.current.value,
        group_ids: user.group_ids
      };
    } else {
      variables = {
        first: 5,
        keyword: userlevelTextFieldRef.current.value,
        not: [
          {
            field: "user_levels.name",
            value: ["Data Admin", "WTI Admin"]
          }
        ],
        group_ids: user.group_ids
      };
    }
    getUserLevels({
      variables: variables
    });
  };

  const debouncedUserLevelChanged = React.useMemo(
    () => debounce(userLevelChanged, 250),
    []
  );

  if (clientLoading) return <Typography>Loading...</Typography>;

  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_USERS_MODULE}
          history={history?.location?.state?.params}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={_.isEqual(initialState, state)}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Typography>All fields with * are required</Typography>
            </Grid>
            <Grid
              container
              spacing={6}
              style={{ height: "calc(100% - 150px)" }}
            >
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.column}>
                  Personal Information
                </Typography>
                <TextField
                  required
                  name="username"
                  label="Username"
                  placeholder="Enter Username"
                  value={state.username}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  className={classes.field}
                />
                {errors.username && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.username}
                  </Alert>
                )}
                <TextField
                  required
                  name="first_name"
                  label="First Name"
                  placeholder="Enter First Name"
                  value={state.first_name}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  className={classes.field}
                />
                {errors.first_name && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.first_name}
                  </Alert>
                )}
                <TextField
                  required
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={state.last_name}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  className={classes.field}
                />
                {errors.last_name && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.last_name}
                  </Alert>
                )}
                <TextField
                  required
                  name="email_address"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  value={state.email_address}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  className={classes.field}
                />
                {errors.email_address && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.email_address}
                  </Alert>
                )}
                <TextField
                  name="contact_number"
                  label="Contact Number"
                  placeholder="+63----------"
                  value={state.contact_number}
                  onChange={e => handleChange(e)}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  onBlur={e => {
                    e.persist();
                    const { value } = e.target;
                    const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                    setState(prev => ({
                      ...prev,
                      contact_number: beginWith63
                    }));
                    e.target.value = beginWith63;
                    handleBlur(e);
                  }}
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start">+(63)</InputAdornment>
                    // ),
                    inputProps: {
                      // maxLength: 10,
                      maxLength: getMaxLength(state.contact_number)
                    }
                  }}
                  className={classes.field}
                />
                {errors.contact_number && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.contact_number}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.column}>
                  User Information
                </Typography>
                <FormControl className={classes.field}>
                  <InputLabel shrink required>
                    Access Rights
                  </InputLabel>
                  <Autocomplete
                    id="combo-box-demo"
                    options={data2?.get_user_levels.userlevels || []}
                    // options={[]}
                    getOptionLabel={option => option.name || ""}
                    getOptionSelected={(o, v) => {
                      if (v.name === o.name) return o.name;
                      return {};
                    }}
                    onChange={handleUserlevels}
                    onBlur={handleBlur}
                    value={userlevel}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputRef={userlevelTextFieldRef}
                        style={{ marginTop: 16 }}
                        placeholder="Select User Level"
                        // value={userlevel}
                        fullWidth
                        onClick={() => {
                          let variables = {};
                          if (
                            user.user_level_id ===
                            process.env.REACT_APP_WTI_ADMIN
                          ) {
                            variables = {
                              first: 5,
                              keyword: null,
                              group_ids: user.group_ids
                            };
                          } else {
                            variables = {
                              first: 5,
                              keyword: null,
                              not: [
                                {
                                  field: "user_levels.name",
                                  value: ["Data Admin", "WTI Admin"]
                                }
                              ],
                              group_ids: user.group_ids
                            };
                          }

                          getUserLevels({
                            variables: variables
                          });
                        }}
                        onChange={debouncedUserLevelChanged}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading2 ? (
                                <CircularProgress
                                  disableShrink
                                  color="inherit"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                {errors.user_level_id && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.user_level_id}
                  </Alert>
                )}
                <FormControl className={classes.field}>
                  <InputLabel shrink>Password Duration</InputLabel>
                  <Select
                    value={state.password_expiry}
                    onChange={e => handleChange(e)}
                    displayEmpty
                    name="password_expiry"
                  >
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>1 month</MenuItem>
                    <MenuItem value={2}>2 months</MenuItem>
                    <MenuItem value={4}>4 months</MenuItem>
                    <MenuItem value={6}>6 months</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.field}
                    required
                    name="account_expiration"
                    openTo="date"
                    format="MM/dd/yyyy"
                    label="Access End Date"
                    views={["year", "month", "date"]}
                    value={state.account_expiration}
                    onChange={e => handleDate(moment(e).format("MMM DD YYYY"))}
                    minDate={moment().format("MM/DD/YYYY")}
                    minDateMessage={false}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    onBlur={handleBlur}
                  />
                </MuiPickersUtilsProvider>
                {errors.account_expiration && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.account_expiration}
                  </Alert>
                )}
                <FormControl className={classes.field}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      <Tooltip
                        classes={{ tooltip: classes.toolTip }}
                        placement="top-start"
                        title={
                          <div
                          // style={{
                          //   width: 150,
                          //   whiteSpace: "normal",
                          //   textAlign: "left"
                          // }}
                          >
                            Turn on: Auto logout user after
                            <br />
                            15 minutes of inactivity.
                            <br />
                            Turn off: User will be stayed login
                          </div>
                        }
                      >
                        {/* <IconButton aria-label="helpbutton"> */}
                        <Help className={classes.helpIcon} />
                        {/* </IconButton> */}
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      Auto Logout
                    </Grid>
                    <Grid>
                      <Switch
                        checked={is_auto_logout}
                        onChange={toggleChecked}
                        name="checkedNotif"
                        value="checkedNotif"
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                {!_.isEmpty(userlevel) ? (
                  checkIfStoreApp() ? (
                    <SearchGeofence
                      setGeofenceID={setGeofenceID}
                      errors={errors}
                      setErrors={setErrors}
                      type="add"
                    />
                  ) : null
                ) : null}
                {errors.geofence_id && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.geofence_id}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Typography className={classes.column}>Remarks</Typography>
                <TextField
                  name="remarks"
                  placeholder="Type here..."
                  multiline
                  minRows="4"
                  value={state.remarks}
                  onChange={e => handleChange(e)}
                  variant="outlined"
                  fullWidth
                  className={classes.multiline}
                  helperText={`${state.remarks.length}/100`}
                  FormHelperTextProps={{ style: { textAlign: "right" } }}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <div
                style={{
                  textAlign: "center",
                  width: "100%"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleClickOpen}
                >
                  Save Details
                </Button>
              </div>
            </Grid>
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={open}
          close={() => handleClose()}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save user?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
}
