import React, { useEffect } from "react";
import { FormControl, IconButton, MenuItem, Select } from "@material-ui/core";
import {
  DragHandleRounded,
  Add,
  Close,
  ExpandMoreRounded
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import DraggableList from "./DraggableList/DraggableList";

const useReorderStyles = makeStyles({
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  text: { marginRight: 3 },
  formControlRoot: {
    flex: 1,
    "& .MuiInput-root:before": {
      border: "none !important"
    },
    "& .MuiInput-root:after": {
      border: "none !important"
    }
  },
  selectRoot: {
    paddingBlock: 0,
    fontSize: 14
  }
});

const parseReorderItems = (arr, label) => {
  if (!Array.isArray(arr)) return { label: arr[label] };
  return arr.map(a => {
    if (typeof a === "object") return { label: a[label] };

    return { label: a, disabled: false };
  });
};

export const parseHeader = (headers) => {
  let formattedHeader = {};
  if(headers) {
    JSON.parse(headers).map(row => {
      formattedHeader = {...formattedHeader, [row.tab_name]: row.columns}
    });
  }
  return formattedHeader
}

const ReorderList = React.forwardRef((props, ref) => {
  const {
    readOnly = false,
    defaultHeaders = {},
    initialValues = {},
    nonpermanentItems = {},
    onSelectedAlertTypeChanged = () => {}
  } = props;

  const [tab, setTab] = React.useState(0);
  const [items, setItems] = React.useState(initialValues);
  const styles = useReorderStyles();

  // eslint-disable-next-line no-prototype-builtins
  const isPermanent = item => item.hasOwnProperty("disabled");
  // eslint-disable-next-line no-prototype-builtins
  const isNonpermanent = item => !item.hasOwnProperty("disabled");
  /**
   * @todo
   * 1. After deleting an item, order of remaining items should not "reset". [COMPLETED]
   * 2. Optimized state changes
   * 3. Fix dragging raw element [COMPLETED]
   *     - Solution 1: Wrap custom element in <DraggableItem >...</DraggableItem>
   *     - Solution 2: Apply prop collection (get all event from Draggable Item component)
   * 4. Test add and delete functionalities [COMPLETE]
   *    (Specially to report types that are using Alert Description field)
   */

  // Refs
  const firstRender = React.useRef(true);
  const initialAutomatedHeaders = React.useRef();

  const firstRenderDefault = () => {
    firstRender.current = false;
    setItems(() => {
      const init = Object.keys(initialValues).map(item => {
        return [
          ...parseReorderItems(initialValues[item]),
          ...parseReorderItems(nonpermanentItems, "code")
        ];
      });
      return init;
    });
  };

  const mountedOnAutomated = () => {
    initialAutomatedHeaders.current = initialValues;
    const disabledPemanentItems = [];
    Object.keys(initialValues).forEach((header, index) => {
      initialValues[header].forEach(head => {
        if (!defaultHeaders[header].includes(head)) {
          if (!disabledPemanentItems[index]) disabledPemanentItems[index] = [];
          disabledPemanentItems[index].push(head);
        }
      });
    });

    const defaultHeadersCopy = Object.keys(defaultHeaders).map((headers) => {
      return defaultHeaders[headers].map(head => {
        if (initialValues[headers].includes(head))
          return { label: head, disabled: false };

        return { label: head };
      });
    });

    disabledPemanentItems.forEach((disabledItems, index) => {
      disabledItems.forEach(item => {
        defaultHeadersCopy[index].push({ label: item, disabled: true });
      });
    });

    setItems(defaultHeadersCopy);
    firstRender.current = false;
  };

  const isValueReset = () => {
    if (!initialAutomatedHeaders.current && readOnly) return true;
    return readOnly || _.isEqual(defaultHeaders, initialValues);
  };

  // Effects
  React.useEffect(() => {
    return () => {
      firstRender.current = true;
      initialAutomatedHeaders.current = undefined;
    };
  }, []);
  React.useEffect(() => {
    const initialValuesFormatted = Object.keys(initialValues).map((tab) => {
      return initialValues[tab]
    })
    if (!_.isEqual(initialValuesFormatted, items)) {
      setTab(0);
      firstRender.current = true;
    }
    if (!firstRender.current && readOnly) {
      firstRender.current = true;
    }
    // if (
    //   firstRender.current &&
    //   !nonpermanentItems.length &&
    //   defaultHeaders.length
    // )
    //   return;
    if (firstRender.current && Object.keys(defaultHeaders).length && isValueReset()) {
      // Reorder items according to scheduled report data
      mountedOnAutomated();
      return;
    }

    if (firstRender.current) {
      firstRenderDefault();
      return;
    }

    setItems(prev => {
      const prevCopy = [...prev];
      prevCopy.forEach((tabInstance, tabIndex) => {
        const currentTab = tabInstance;
        const codes = nonpermanentItems.map(item => item.code);
        const prevNonPermanentItems = currentTab.filter(isNonpermanent);
        const permanentItems = currentTab.filter(isPermanent).map(p => p.label);
        let tempItems = [];
        if (nonpermanentItems.length > prevNonPermanentItems.length) {
          const head = currentTab.filter(item => !item.disabled);
          const tail = currentTab.filter(item => item.disabled);
          const newItem = nonpermanentItems[nonpermanentItems.length - 1];
          tempItems = [...head, parseReorderItems(newItem, "code"), ...tail];
        } else {
          tempItems = [...currentTab];
        }

        const newItems = tempItems.filter(p => {
          return codes.includes(p.label) || permanentItems.includes(p.label);
        });

        prevCopy[tabIndex] = newItems;
      });
      return prevCopy;
    });
  }, [nonpermanentItems, initialValues]);
  // End Effects

  // Event handlers
  const handleDelete = item => {
    if (!nonpermanentItems.some(i => i.code === item.label)) {
      const tabItemsCopy = [...items[tab]];
      const index = tabItemsCopy.findIndex(i => i.label === item.label);
      const filteredItems = tabItemsCopy.filter(i => i.label !== item.label);
      setItems(prev => {
        const prevCopy = [...prev];
        const currentTab = prevCopy[tab];
        prevCopy[tab] = [
          ...filteredItems,
          { ...currentTab[index], disabled: true }
        ];
        return prevCopy;
      });
      return;
    }

    onSelectedAlertTypeChanged(
      nonpermanentItems.filter(i => i.code !== item.label)
    );
  };

  const handleAdd = item => {
    const itemsCopy = [...items];
    const tabItemsCopy = [...items[tab]];
    const head = tabItemsCopy.filter(i => !i.disabled);
    const tail = tabItemsCopy.filter(i => i.disabled);
    const index = tail.findIndex(i => i.label === item.label);
    const filteredTail = tail.filter(i => i.label !== item.label);
    const newItem = tail[index];
    newItem.disabled = false;

    itemsCopy[tab] = [...head, newItem, ...filteredTail];

    setItems(itemsCopy);
  };

  const handleArrangementChange = newArragement => {
    if(firstRender.current) return;
    setItems(prev => {
      const prevCopy = prev;
      prevCopy[tab] = newArragement;

      ref.current = Object.keys(initialValues).map((tab, index)=> {
        return {
          tab_name: tab,
          columns: prevCopy[index]?.filter(tabItem => !tabItem.disabled).map(item => item.label)
        }
      });
      return prevCopy;
    });
  };
  // End Event handlers

  return (
    <DraggableList
      readOnly={readOnly}
      values={items[tab]}
      getOptionLabel={item => item.label}
      onArrangementChanged={handleArrangementChange}
      getItemDisabled={item => item.disabled}
      renderEmptyField={({ getEmptyComponentProps }) => {
        const {
          emptyContainerClasses,
          emptyTextClasses
        } = getEmptyComponentProps();
        return (
          <div {...emptyContainerClasses}>
            <span {...emptyTextClasses}>This field is empty</span>
          </div>
        );
      }}
      label={({ getLabelProps }) => {
        return (
          <div className={styles.label}>
            <span {...getLabelProps({ classNames: [styles.text] })}>
              Reorder Column:
            </span>
            {Object.keys(initialValues).length > 1 && (
              <FormControl classes={{ root: styles.formControlRoot }}>
                <Select
                  id="order-type-select"
                  // defaultValue="detailed"
                  value={tab}
                  IconComponent={ExpandMoreRounded}
                  classes={{
                    root: styles.selectRoot
                  }}
                  onChange={event => setTab(event.target.value)}
                >
                  {Object.keys(initialValues).map((tabName, index) => (
                    <MenuItem key={index} value={index}>{tabName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        );
      }}
      renderItem={(item, { getDraggableItemProps }) => {
        const { draggable: isDraggable } = getDraggableItemProps();
        return (
          <React.Fragment>
            <DragHandleRounded
              style={{
                fontSize: 16,
                padding: 5,
                color: isDraggable ? "inherit" : "#959595"
              }}
            />
            <span
              style={{
                flex: 1,
                color: isDraggable ? "inherit" : "#959595"
              }}
            >
              {item.label}
            </span>
            {item.disabled ? (
              <IconButton size="small" onClick={() => handleAdd(item)}>
                <Add style={{ fontSize: 16 }} htmlColor="#4BE195" />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={() => handleDelete(item)}>
                <Close style={{ fontSize: 14 }} htmlColor="#ff0000" />
              </IconButton>
            )}
          </React.Fragment>
        );
      }}
    />
  );
});

export default ReorderList;
