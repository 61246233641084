import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import StatusContainer from "../../../utils/UI/Container/StatusContainer";
import StatusChart from "../Charts/StatusChart";


// on time delivery (otd) design.
const Delivery = props => {
  const { chartData, history } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={3}>
        <StatusContainer
          data={chartData.otdInTransit}
          label="In Transit"
          history={history}
          category="OTD"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Box className={classes.chart_container}>
          {chartData.chartValue[0].data && (
            <Grid item xs={12}>
              <StatusChart
                dataValue={chartData.chartValue[3]}
                chartTitle="On Time Delivery - In Transit"
              />
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <StatusContainer
          data={chartData.otdArr}
          label="Arrived"
          history={history}
          category="OTD"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Box className={classes.chart_container}>
          {chartData.chartValue[0].data && (
            <Grid item xs={12}>
              <StatusChart
                dataValue={chartData.chartValue[2]}
                chartTitle="On Time Delivery - Arrived"
              />
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default Delivery;

const useStyles = makeStyles(theme => ({
  chart_container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    minHeight: 250,
    display: "flex",
    padding: "0 1rem",
    justifyContent: "center",
    alignItems: "center"
  }
}));
