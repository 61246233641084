const data = [
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Resupply",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0001",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "hrAMnnIBNasz_0cS04ef",
        name: "Location",
        address: null,
        arrival: "2022-09-04 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "PrDgnXIBNasz_0cSk4Iz",
        name: "Location",
        address: null,
        arrival: "2022-09-05 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-05 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "jnxM7IIB5__gxClf_adD"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: null,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "PrDgnXIBNasz_0cSk4Iz",
        name: "Location",
        address: null,
        arrival: "2022-09-05 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-05 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "jnxM7IIB5__gxClf_adD",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "hrAMnnIBNasz_0cS04ef",
        name: "Location",
        address: null,
        arrival: "2022-09-04 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Cofipack - Source",
    first_pickup_arrival: "2022-09-04 02:00:00",
    first_dropoff: "PH Cagayan Grocery - Destination",
    first_dropoff_arrival: "2022-09-05 00:00:00",
    eta: false,
    has_empty_so: true
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Resupply",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0002",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "iLAMnnIBNasz_0cS04fp",
        name: "Location",
        address: null,
        arrival: "2022-09-03 10:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "SOtDinQBpNiR7a8JI7Do",
        name: "Location",
        address: null,
        arrival: "2022-09-04 23:59:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 23:59:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "Dl-S7IIBKzLDlwB9BkKM"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: null,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "SOtDinQBpNiR7a8JI7Do",
        name: "Location",
        address: null,
        arrival: "2022-09-04 23:59:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 23:59:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "Dl-S7IIBKzLDlwB9BkKM",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "iLAMnnIBNasz_0cS04fp",
        name: "Location",
        address: null,
        arrival: "2022-09-03 10:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Antonina - Source",
    first_pickup_arrival: "2022-09-03 10:00:00",
    first_dropoff: "PH Batino Grocery - Destination",
    first_dropoff_arrival: "2022-09-04 23:59:00",
    eta: false,
    has_empty_so: true
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0003",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839926530",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 22:25:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 22:25:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839926530",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "jETg0oIBKzLDlwB9-HEQ"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 22:25:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 22:25:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839926530",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "jETg0oIBKzLDlwB9-HEQ",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839926530",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Batino Grocery - Source",
    first_pickup_arrival: "2022-09-02 23:00:00",
    first_dropoff: null,
    first_dropoff_arrival: "2022-09-04 22:25:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0004",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 22:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839928449",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 22:24:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 22:24:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839928449",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "subg0oIB5__gxClf7lGq"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 22:24:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 22:24:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839928449",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "subg0oIB5__gxClf7lGq",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 22:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839928449",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Batino Grocery - Source",
    first_pickup_arrival: "2022-09-02 22:00:00",
    first_dropoff: null,
    first_dropoff_arrival: "2022-09-04 22:24:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Resupply",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0005",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839910996",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 08:24:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 08:24:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839910996",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "NUTs0oIBKzLDlwB9xnLt"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-04 08:24:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-04 08:24:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839910996",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "NUTs0oIBKzLDlwB9xnLt",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-02 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839910996",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Batino Grocery - Source",
    first_pickup_arrival: "2022-09-02 02:00:00",
    first_dropoff: null,
    first_dropoff_arrival: "2022-09-04 08:24:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0006",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-01 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839920976",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-03 22:23:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-03 22:23:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839920976",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "h0Tg0oIBKzLDlwB973Ha"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: null,
        name: null,
        address: null,
        arrival: "2022-09-03 22:23:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-03 22:23:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839920976",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "h0Tg0oIBKzLDlwB973Ha",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: "2022-09-01 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839920976",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Batino Grocery - Source",
    first_pickup_arrival: "2022-09-01 23:00:00",
    first_dropoff: null,
    first_dropoff_arrival: "2022-09-03 22:23:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0007",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "hLAMnnIBNasz_0cSzodD",
        name: "Location",
        address: null,
        arrival: "2022-09-01 17:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "JbDgnXIBNasz_0cSaoJR",
        name: "Location",
        address: null,
        arrival: "2022-09-15 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-15 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "BURD1IIBKzLDlwB9wYhp"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "JbDgnXIBNasz_0cSaoJR",
        name: "Location",
        address: null,
        arrival: "2022-09-15 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-15 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "BURD1IIBKzLDlwB9wYhp",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "hLAMnnIBNasz_0cSzodD",
        name: "Location",
        address: null,
        arrival: "2022-09-01 17:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: null,
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Cagayan De Oro Fac FG - Source",
    first_pickup_arrival: "2022-09-01 17:00:00",
    first_dropoff: "Optimized Customer Solutions, Inc.-",
    first_dropoff_arrival: "2022-09-15 00:00:00",
    eta: false,
    has_empty_so: true
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0008",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042181",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041869",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042369",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041558",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041632",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041546",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042542",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042859",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042422",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042439",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042620",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042783",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042829",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043057",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042181",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "p6zZ3n8BkQB_nEmR-uBv"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041869",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "t6zZ3n8BkQB_nEmR_eBq"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042369",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "qKzZ3n8BkQB_nEmR-uDR"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041558",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "Hz7Z3n8BYbPFl0RH_O3l"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041632",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "mqzZ3n8BkQB_nEmR9-Au"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041546",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "3FXZ3n8BFB-E8KQq_Y4e"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042542",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "FT7Z3n8BYbPFl0RH--2C"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042859",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "Dz7Z3n8BYbPFl0RH-u06"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042422",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "zFXZ3n8BFB-E8KQq-45J"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042439",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "Fz7Z3n8BYbPFl0RH--3n"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042620",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "q6zZ3n8BkQB_nEmR_OAk"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042783",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "xVXZ3n8BFB-E8KQq-I68"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042829",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "sqzZ3n8BkQB_nEmR_OBw"
      },
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043057",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "oKzZ3n8BkQB_nEmR9-D9"
      }
    ],
    hauler: "WTI Logistics",
    total: 14,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "1LDVnXIBNasz_0cSc37t",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042181",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "p6zZ3n8BkQB_nEmR-uBv",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042181",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Meycauayan Grocery - Source",
    first_pickup_arrival: null,
    first_dropoff: "SM Supermarket - Masinag",
    first_dropoff_arrival: "2022-04-02 06:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "TRIP0009",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042858",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041564",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042384",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043323",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042694",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041922",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042324",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042701",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041638",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042169",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042191",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043342",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042709",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042858",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "tVXn3n8BFB-E8KQqX7gX"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041564",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "qFXn3n8BFB-E8KQqXLgN"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042384",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "kT_n3n8BYbPFl0RHWhXc"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043323",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "6q3n3n8BkQB_nEmRWzq2"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042694",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "q1Xn3n8BFB-E8KQqXLiv"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041922",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "oFXn3n8BFB-E8KQqWrgu"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042324",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "mj_n3n8BYbPFl0RHXhUd"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042701",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "lz_n3n8BYbPFl0RHXRWP"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056041638",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "mVXn3n8BFB-E8KQqWLgQ"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042169",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "7q3n3n8BkQB_nEmRXToC"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042191",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "qlXn3n8BFB-E8KQqXLhP"
      },
      {
        geofence_id: "BrDTnXIBNasz_0cSUX5R",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:02:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:02:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043342",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "oz_n3n8BYbPFl0RHXxXK"
      },
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042709",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "pFXn3n8BFB-E8KQqW7hF"
      }
    ],
    hauler: "WTI Logistics",
    total: 13,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "OnHSnXIBcGKWaoLd7LPn",
        name: "Location",
        address: null,
        arrival: "2022-04-02 05:16:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 05:16:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042858",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "tVXn3n8BFB-E8KQqX7gX",
        is_pickup: false
      },
      {
        geofence_id: "BrDTnXIBNasz_0cSUX5R",
        name: "Location",
        address: null,
        arrival: "2022-04-02 06:02:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-04-02 06:02:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056043342",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "oz_n3n8BYbPFl0RHXxXK",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7056042858",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Meycauayan Grocery - Source",
    first_pickup_arrival: null,
    first_dropoff: "SM Supermarket - Sta. Mesa",
    first_dropoff_arrival: "2022-04-02 05:16:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: null,
    service_type: "Direct to customer",
    vehicle_id: null,
    vehicle_history: null,
    status: "Processing",
    tripNumber: "fojas03312022",
    is_urgent: false,
    raw_pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054257116",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054336607",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054336601",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      },
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054145664",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "9LQhxXIBcGKWaoLdLtGk",
        name: "Location",
        address: null,
        arrival: "2022-02-01 17:09:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-01 17:09:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054257116",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "kVLE3X8BFB-E8KQqA8VW"
      },
      {
        geofence_id: "9LQhxXIBcGKWaoLdLtGk",
        name: "Location",
        address: null,
        arrival: "2022-02-01 17:09:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-01 17:09:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054336607",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "jKbE3X8BkQB_nEmRAhvS"
      },
      {
        geofence_id: "_7DTnXIBNasz_0cSQH0P",
        name: "Location",
        address: null,
        arrival: "2022-02-04 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-04 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054336601",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "mlLE3X8BFB-E8KQqBMVV"
      },
      {
        geofence_id: "9LQhxXIBcGKWaoLdLtGk",
        name: "Location",
        address: null,
        arrival: "2022-02-01 17:09:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-01 17:09:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054145664",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        items: [],
        trip_id: "i6bE3X8BkQB_nEmRAhtP"
      }
    ],
    hauler: "WTI Logistics",
    total: 4,
    has_split: true,
    has_no_dispatch: true,
    raw_trip_status: "Accepted",
    out_of_route: false,
    data_completed: false,
    is_overdue: true,
    pre_dispatched_ready: false,
    dispatch_type: "Not Yet",
    trip_status: "Processing",
    is_split: null,
    status_code_id: 1,
    dropoffs: [
      {
        geofence_id: "9LQhxXIBcGKWaoLdLtGk",
        name: "Location",
        address: null,
        arrival: "2022-02-01 17:09:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-01 17:09:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054257116",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "kVLE3X8BFB-E8KQqA8VW",
        is_pickup: false
      },
      {
        geofence_id: "_7DTnXIBNasz_0cSQH0P",
        name: "Location",
        address: null,
        arrival: "2022-02-04 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-02-04 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054336601",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        trip_id: "mlLE3X8BFB-E8KQqBMVV",
        is_pickup: false
      }
    ],
    pickups: [
      {
        geofence_id: "FXEMnnIBcGKWaoLdzblA",
        name: "Location",
        address: null,
        arrival: null,
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "7054257116",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Accepted",
        status_code_id: 1,
        geofence_code: "GEO",
        is_pickup: true
      }
    ],
    first_pickup: "PH Batino Grocery - Source",
    first_pickup_arrival: null,
    first_dropoff: "FDC - Borongan Abacca Traders Warehouse",
    first_dropoff_arrival: "2022-02-01 17:09:00",
    eta: false,
    has_empty_so: false
  }
];

export default data;
