/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* global google */
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useLazyQuery } from "@apollo/client";
import { Marker, Polygon, Circle } from "@react-google-maps/api";
import {
  Paper,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { stringify } from "wkt";
import _ from "lodash";
import { GET_INTERSECT_UNIVERSAL_GEOFENCE } from "../../../../../../graphql/Queries";
import BasicModal from "../../../../../../utils/modal/BasicModal";
import Map from "../../../../../../utils/Map/index";
import {
  control_positions,
  getGeoPaths,
  mapRef as mapViewRef
} from "../../../../../../utils/Map/Inner";
import CustomOverlays from "./MapComponents/CustomOverlays";
import { AntSwitch } from "./MapComponent.styles";
import { getGeofenceTypeColor } from "../../../../../Utils";
import {
  getBounds,
  wktToCoordinates
} from "../../../../../../utils/functions/coordinatesParserV2";

const UniversalGeofence = props => {
  const { universalGeo, handleToggle } = props;

  const textmsg = (
    <span>
      Universal geofence will only be visible once: <br />
      <br />
      Toggle is on <br />
      Map reached the zoom level of 17-22
    </span>
  );

  return (
    <div style={{ marginRight: 10 }}>
      <Tooltip title={textmsg} placement="left">
        <Paper style={{ height: "40px", width: "40px", textAlign: "center" }}>
          <AntSwitch
            color="primary"
            name="universal_geofence"
            checked={universalGeo}
            onChange={handleToggle}
          />
        </Paper>
      </Tooltip>
    </div>
  );
};

let timeout;
const MapComponent = props => {
  const {
    editMode,
    state,
    state: { location, category, zoom },
    setState,
    mapStateRef
  } = props;

  const [mapState, setMapState] = useState({
    mapCenter: location,
    drawingMode: null,
    geom: "",
    geofence_geom_id: null,
    opacity: 0.5,
    radius: 0,
    area: "",
    bounds: [],
    showUniversalGeo: true
  });
  const [expanded, setExpanded] = useState(true);
  const [univGeofences, setUnivGeofences] = useState([]);
  const modalRef = useRef();

  useEffect(() => {
    mapStateRef.current = mapState;
  }, [mapState]);

  useEffect(() => {
    // set initial value (in edit view)
    if (state.geom) {
      setMapState(prev => ({
        ...prev,
        geom: state.geom,
        geofence_geom_id: state.geofence_geom_id,
        opacity: state.opacity || 0.5,
        area: google.maps.geometry.spherical.computeArea(getBounds(state.geom))
      }));
    }
  }, [state.geom]);

  useEffect(() => {
    setMapState(prev => ({
      ...prev,
      mapCenter: location
    }));
  }, [location]);

  useEffect(() => {
    if (!editMode) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [editMode]);

  const [getUniversalGeo] = useLazyQuery(GET_INTERSECT_UNIVERSAL_GEOFENCE, {
    onCompleted: res => {
      if (res?.get_intersect_universal_geofence?.universal_geofences) {
        setUnivGeofences(
          _.uniqWith(
            res?.get_intersect_universal_geofence?.universal_geofences,
            (a, b) => a.geofence_geom_id === b.geofence_geom_id
          )
        );
      }
    }
  });

  useEffect(() => {
    if (
      zoom >= 17 &&
      zoom <= 22 &&
      mapState.showUniversalGeo &&
      mapState.bounds.length
    ) {
      const mapBounds = mapState.bounds.map(point => [point.lat, point.lon]);
      getUniversalGeo({
        variables: {
          geom_type: "viewport",
          geom: stringify({
            type: "MultiPolygon",
            coordinates: [[mapBounds]]
          })
        }
      });
    }
  }, [mapState.bounds, mapState.showUniversalGeo]);

  useEffect(() => {
    if (mapViewRef.current) {
      setMapState(prev => ({
        ...prev,
        bounds: prev.showUniversalGeo ? getGeoPaths(mapViewRef) : []
      }));
    }
  }, [mapState.mapCenter]);

  const handleIdle = () => {
    if (mapViewRef.current) {
      const tempZoom = mapViewRef.current.getZoom();
      if (tempZoom === zoom) return;

      const newCenter = mapViewRef.current.getCenter().toJSON();
      setMapState(prev => ({
        ...prev,
        mapCenter: {
          lat: newCenter.lat,
          lon: newCenter.lng
        }
      }));

      setState(prev => ({
        ...prev,
        zoom: tempZoom
      }));
    }
  };

  const handleCenter = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (mapViewRef.current) {
        const newCenter = mapViewRef.current.getCenter().toJSON();
        setMapState(prev => ({
          ...prev,
          mapCenter: {
            lat: newCenter.lat,
            lon: newCenter.lng
          }
        }));
      }
    }, 500);
  };

  const handleOnMakerDragEnd = event => {
    const { lat, lng } = event.latLng;
    setState(prev => ({
      ...prev,
      location: { lat: lat(), lon: lng() }
    }));
  };

  const handleToggle = e => {
    if (!mapViewRef.current) return;
    setMapState(prev => ({
      ...prev,
      showUniversalGeo: e.target.checked,
      bounds: e.target.checked ? getGeoPaths(mapViewRef) : []
    }));
  };

  return (
    <Fragment>
      <Map
        center={{ lat: +mapState.mapCenter.lat, lng: +mapState.mapCenter.lon }}
        defaultZoom={+zoom}
        zoom={+zoom}
        onIdle={handleIdle}
        mapContainerStyle={{ height: `100%`, width: `100%` }}
        onDragEnd={() => handleCenter()}
        options={{
          draggable: editMode,
          mapTypeControl: true,
          gestureHandling: "greedy",
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", /* "terrain", */ "satellite", "hybrid"],
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.LEFT_BOTTOM
          }
        }}
      >
        <Map.Control position={control_positions.TOP_LEFT}>
          {editMode && (
            <CustomOverlays
              expanded={expanded}
              setExpanded={setExpanded}
              mapState={mapState}
              setMapState={setMapState}
              state={state}
              setState={setState}
              univGeofences={univGeofences}
            />
          )}
        </Map.Control>
        {editMode && (
          <Map.Control position={control_positions.RIGHT_BOTTOM}>
            <UniversalGeofence
              mapViewRef={mapViewRef}
              universalGeo={mapState.showUniversalGeo}
              handleToggle={handleToggle}
            />
          </Map.Control>
        )}
        {mapState.showUniversalGeo &&
          zoom >= 17 &&
          univGeofences.map(universal => {
            if (+universal?.geofence_geom_id !== +mapState.geofence_geom_id) {
              return (
                <Polygon
                  key={universal.geofence_geom_id}
                  paths={wktToCoordinates(universal.geom)}
                  onMouseDown={() => {
                    if (mapState.geom && !mapState.geofence_geom_id) {
                      modalRef.current.geom = universal;
                      modalRef.current.openModal();
                    } else {
                      setState(prev => ({
                        ...prev,
                        location: {
                          lat: universal.location.lat,
                          lon: universal.location.lon
                        }
                      }));
                      const area = google.maps.geometry.spherical.computeArea(
                        getBounds(universal.geom)
                      );

                      setMapState(prev => ({
                        ...prev,
                        geom: universal.geom,
                        geofence_geom_id: universal.geofence_geom_id,
                        area: area
                      }));
                    }
                  }}
                  options={{
                    fillColor: "yellow",
                    fillOpacity: 0.5,
                    strokeWeight: 2,
                    clickable: mapState.drawingMode === "select"
                  }}
                />
              );
            }
          })}
        {mapState.geom &&
          (mapState.geofence_geom_id || mapState.drawingMode !== "circle") && (
            <Polygon
              paths={wktToCoordinates(mapState.geom)}
              options={{
                fillColor: getGeofenceTypeColor(category),
                fillOpacity: mapState.opacity,
                strokeColor: "red",
                clickable: true,
                zIndex: 1000
              }}
              onLoad={() => {
                // auto adjust zoom level based on shape
                if (
                  mapState.geom &&
                  !mapState.drawingMode &&
                  mapState.geom === state.geom
                ) {
                  const bounds = getBounds(mapState.geom);
                  mapViewRef.current.fitBounds(bounds);
                }
              }}
            />
          )}
        {mapState.geom &&
          !mapState.geofence_geom_id &&
          mapState.drawingMode === "circle" && (
            <Circle
              center={{ lat: location.lat, lng: location.lon }}
              radius={mapState.radius}
              options={{
                fillColor: getGeofenceTypeColor(category),
                fillOpacity: mapState.opacity,
                strokeColor: "red",
                clickable: true,
                zIndex: 1000
              }}
            />
          )}
        <Marker
          draggable={editMode && !mapState.geom}
          onDragEnd={handleOnMakerDragEnd}
          position={{
            lat: location.lat,
            lng: location.lon
          }}
        />
        <BasicModal ref={modalRef}>
          <div>
            <Dialog
              open
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Select Geofence</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to select geofence? Your drawn shape
                  will be remove?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    borderRadius: 100,
                    width: 150,
                    borderColor: "#f44336",
                    color: "#f44336"
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    modalRef.current.closeModal();
                  }}
                >
                  No
                </Button>
                <Button
                  style={{ borderRadius: 100, color: "white", width: 150 }}
                  variant="contained"
                  onClick={() => {
                    const {
                      location: univLocation,
                      geom: univGeom,
                      geofence_geom_id: geomId
                    } = modalRef.current.geom;
                    setState(prev => ({
                      ...prev,
                      location: {
                        lat: univLocation.lat,
                        lon: univLocation.lon
                      }
                    }));

                    setMapState(prev => ({
                      ...prev,
                      geom: univGeom,
                      geofence_geom_id: geomId,
                      area: google.maps.geometry.spherical.computeArea(
                        getBounds(univGeom)
                      )
                    }));
                    modalRef.current.closeModal();
                  }}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </BasicModal>
      </Map>
    </Fragment>
  );
};

export default MapComponent;
