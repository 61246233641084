export default [
  {
    id: "21955",
    device_id: "21208",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE001",
    has_gps: {
      devicealias: "DEVICE001",
      device_type_id: 27,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 240,
        aux_voltage: 5000,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: true
      },
      mobile_imei: "",
      name: "ALIASTEST010203"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 27,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "32057",
    device_id: "25602",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE002",
    has_gps: {
      devicealias: "DEVICE002",
      device_type_id: 17,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: 0,
        temperature: 0,
        reportstamp: "2021-10-22 02:52:12",
        lastlocation:
          "National Highway 160m , Brgy  Dologon, Maramag, Bukidnon, Landmarks Mt  Musuan Zoological And Botanical Garden 60m ",
        latitude: 7.882038,
        longitude: 125.06307,
        heading: 125.2,
        battery_level: 0,
        distancetraveled: 0,
        fuelconsumption: 0,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 0,
        aux_voltage: 0,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "869153049968743"
    },
    vehicle_type: "Company Car",
    speed: 0,
    temperature: 0,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "2021-10-22 02:52:12",
    client_id: "127",
    hasTempSensor: false,
    lastlocation:
      "National Highway 160m , Brgy  Dologon, Maramag, Bukidnon, Landmarks Mt  Musuan Zoological And Botanical Garden 60m ",
    battery_level: 0,
    device_type_id: 17,
    under_repair: true,
    not_in_use: false
  },
  {
    id: "35725",
    device_id: "0",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE003",
    has_gps: false,
    vehicle_type: "Company Car",
    speed: 0,
    temperature: 0,
    trip_count: [],
    gps_status: "no gps",
    reportstamp: null,
    client_id: "300",
    hasTempSensor: false,
    under_repair: false,
    not_in_use: true
  },
  {
    id: "22051",
    device_id: "21209",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE004",
    has_gps: {
      devicealias: "DEVICE004",
      device_type_id: 27,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 240,
        aux_voltage: 5000,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "ALIASTEST010204"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 27,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "22052",
    device_id: "21467",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE005",
    has_gps: {
      devicealias: "DEVICE005",
      device_type_id: 21,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 0,
        aux_voltage: 0,
        gps_provider: "qa-gps",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "test123"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 21,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "22053",
    device_id: "21468",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE006",
    has_gps: {
      devicealias: "DEVICE006",
      device_type_id: 18,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 127,
        battery_intervalreporting: 127,
        aux_voltage: 127,
        gps_provider: "qa-gps",
        hastempsensor: true,
        underrepair: false
      },
      mobile_imei: "",
      name: "127NCT023"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: true,
    lastlocation: null,
    battery_level: null,
    device_type_id: 18,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "22258",
    device_id: "21533",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE007",
    has_gps: {
      devicealias: "DEVICE007",
      device_type_id: 27,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 240,
        aux_voltage: 5000,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "860037007130001"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 27,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "22259",
    device_id: "21534",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE008",
    has_gps: {
      devicealias: "DEVICE008",
      device_type_id: 27,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 240,
        aux_voltage: 5000,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "860037007130002"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 27,
    under_repair: false,
    not_in_use: false
  },
  {
    id: "22260",
    device_id: "21535",
    icon: "default_icon.png",
    hauler: "Webcast",
    plateno: "PLATE009",
    has_gps: {
      devicealias: "DEVICE009",
      device_type_id: 27,
      device_status: {
        status_code: "Inactive",
        status_code_id: "777",
        gpsSpeed: null,
        temperature: null,
        reportstamp: "1111-11-11 00:00:00",
        lastlocation: null,
        latitude: null,
        longitude: null,
        heading: null,
        battery_level: null,
        distancetraveled: null,
        fuelconsumption: null,
        powersource: ""
      },
      device_config: {
        intervalreporting: 1,
        battery_intervalreporting: 240,
        aux_voltage: 5000,
        gps_provider: "Webcast Technologies, Inc",
        hastempsensor: false,
        underrepair: false
      },
      mobile_imei: "",
      name: "860037007130003"
    },
    vehicle_type: "Company Car",
    speed: null,
    temperature: null,
    trip_count: [],
    gps_status: "Inactive",
    reportstamp: "1111-11-11 00:00:00",
    client_id: "148",
    hasTempSensor: false,
    lastlocation: null,
    battery_level: null,
    device_type_id: 27,
    under_repair: false,
    not_in_use: false
  }
];
