import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";

function SearchBox(props) {
  const {
    // location,
    setCenter,
    setDestination,
    textValue,
    setTextValue,
    setFitboundDestination,
    lastAddress
  } = props;
  const [address, setAddress] = useState(lastAddress?.current.value || "");

  const handleChange = e => {
    setAddress(e.target.value);
    lastAddress.current.value = e.target.value;
    lastAddress.current.submit = true;
  };

  return (
    <>
      <Autocomplete
        style={{
          border: `0px solid transparent`,
          width: `100%`,
          height: `32px`,
          padding: `0 12px`,
          borderRadius: `0px`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          flex:"1"
        }}
        onPlaceSelected={place => {
          try {
            setDestination({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            });
            setFitboundDestination({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            });
            setCenter({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            });
            // setAddress(place.formatted_address);
            lastAddress.current.value = place.formatted_address;
            setTextValue(place.formatted_address);
          } catch (error) {
            console.log(error); // eslint-disable-line
          }
        }}
        placeholder={textValue || "Search Places"}
        types={[]}
        value={address}
        onChange={e => handleChange(e)}
        componentRestrictions={{ country: "ph" }}
      />
    </>
  );
}

export default SearchBox;
