import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import _ from "lodash";
import client from "../Client";
import Layout from "./Layout";
import TripList from "./Trip/List/main";
import TripDetails from "./Trip/Details";
import Shareable from "./Trip/Shareable/share.js";
import TripSplit from "./Trip/Split";
import Report from "./Report";
import Login from "./Users/Login/Login";
import ForgotPassword from "./Users/ForgotPassword/ForgotPassword";
// import { UserProvider } from "../context/UserContext";
import UserProvider from "../context/User/User.provider";
import ProfileEdit from "./Profile/Edit";
import ResetPassword from "./Users/ResetPassword/ResetPassword";

// nestle
import AdminIndex from "./Admin/main";
import AddUser from "./Admin/Resources/Users/AddUser";
import User from "./Admin/Resources/Users/User";
import AddAccessRight from "./Admin/Activity/AccessRights/AddAccessRight";
import AccessRight from "./Admin/Activity/AccessRights/AccessRight";
import Alert from "./Admin/Activity/Alerts/Alert";
import AddGeofence from "./Admin/Trip/Geofences/AddGeofence";
import { RouteGeofence } from "./Admin/Trip/Geofences/RouteGeofence/RouteGeofence";
import EditRoute from "./Admin/Trip/Geofences/RouteGeofence/EditRoute";
import Geofence from "./Admin/Trip/Geofences/Geofence";
import AddDevice from "./Admin/WTI/Devices/AddDevice";
import Device from "./Admin/WTI/Devices/Device";
import VehicleDetails from "./Admin/Resources/Vehicles/VehicleDetails";
import AddVehicle from "./Admin/Resources/Vehicles/VehicleDetails/AddVehicle";

import ViewVehicleType from "./Admin/Resources/VehicleType/View/ViewVehicleType";
import AddVehicleType from "./Admin/Resources/VehicleType/Add/AddVehicleType";

import Clients from "./Admin/WTI/Clients/Clients";
import AddClient from "./Admin/WTI/Clients/Add/Index";
import ViewClient from "./Admin/WTI/Clients/View/Index";

import AddAlertDescriptions from "./Admin/WTI/AlertDescriptions/Add/AlertDescription";
import ViewAlertDescriptions from "./Admin/WTI/AlertDescriptions/View/ViewAlertDescription";
import AddAlertReferences from "./Admin/WTI/AlertReferences/Add/AlertReference";
import ViewAlertReferences from "./Admin/WTI/AlertReferences/View/ViewAlertReference";

import AddGPSProviders from "./Admin/WTI/GPSProviders/Add/AddGPSProvider";
import ViewGPSProvider from "./Admin/WTI/GPSProviders/View/ViewGPSProvider";

import ActivityLogs from "./Admin/WTI/ActivityLogs/ActivityLogs";
import Dashboards from "./Dashboards/main";
// import Monitoring from "./Monitoring";
// import Monitoring from "./Monitoring/Demo";
import Monitoring from "./Monitoring/main";
import Historical from "./Monitoring/Historical";
import MonitoringIsolate from "./Monitoring/Isolate/Index";
import Signup from "./Auth/Signup/Signup";
import FleetView from "./Dashboards/Fleet/FleetView";
import NotificationPage from "./Notifications/AllNotifications";
import PreBooking from "./Prebooking/main";
import Feedback from "./Feedback/main";
import ViewControlRoom from "./Admin/Activity/ControlRoom/ViewChannel/ViewControlRoom";
import AddControlRoom from "./Admin/Activity/ControlRoom/AddChannel/AddControlRoom";
import UploadDetails from "./Admin/Trip/Geofences/UploadDetails";
// import Users from "./Admin/Resources/Users/Users";
import { useNavigatorOnLine } from "../utils/hooks/useNavigatorOnline";
import GpsNotification from "./Notifications/GpsNotification";

import AddDriverInfo from "./Admin/DriverManagement/DriverInfo/AddDriverInfo";
import EditDriverInfo from "./Admin/DriverManagement/DriverInfo/EditDriverInfo";

// Custom Provider
import ThemeProvider from "../context/Theme/Theme.provider";
import Onboarding from "../utils/Onboarding";
import MapView from "./Monitoring/Share Location/MapView";
import Dashboard from "../components/Dashboards/Dashboard";
import Mechanic from "../components/Mechanic";
import AssignGeofence from "./Admin/Resources/Vehicles/AssignGeofence/AssignGeofence";
// import WorkingHours from "./Admin/DriverManagement/WorkingHours";
import ReviewCard from "./Admin/DriverManagement/WorkingHours/TimeSheets/ReviewCard.js";
import Checklist360History from "./Mechanic/Checklist360/History";
import ControlRoom from "./Admin/Activity/ControlRoom/ControlRoom";
import UserProfile from "./UserProfile";
import StandardGeofence from "./Admin/Trip/Geofences/StandardGeofence/StandardGeofence";
import EditStandardGeofence from "./Admin/Trip/Geofences/StandardGeofence/EditStandardGeofence";
import Allocation from "./Allocation/index.js";
import AddAllocation from "./Allocation/TripAllocation/AddAllocation/index.jsx";
import VssCamera from "./Monitoring/Camera/index.jsx";

const { HeaderSidenav } = Layout;
// const { List } = Trip;
const { Create, Automated, AddGeneratedReport, ViewGeneratedReport } = Report;

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = Cookie.get("token");
  useNavigatorOnLine();

  const [signoutTime /* setSignoutTime */] = useState(900000);
  let logoutTimeout;

  const logout = () => {
    try {
      const user = JSON.parse(Cookie.get("user"));
      if (user.is_auto_logout) {
        Cookie.remove("token");
        Cookie.remove("user");
        Swal.fire({
          title: "Session Expired",
          icon: "Info",
          showConfirmButton: true
        }).then(result => {
          if (result) {
            client.clearStore();
            // Redirect to login Page
            history.push("/");
          }
        });
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = ["mousemove", "mousedown", "click", "scroll"];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    // eslint-disable-next-line
    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      // eslint-disable-next-line
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  const isAnotherWindow = /control_room\/view/g.test(window.location.pathname);
  const isLiveCamera = /monitoring\/live-camera/g.test(window.location.pathname);
  const isViewTrip = /view_trip/g.test(window.location.pathname);
  const query_params = new URLSearchParams(window.location.search);
  const valid_parameters = Object.keys(
    Object.fromEntries(query_params)
  ).some(prm =>
    ["token", "start", "trip_number", "group_ids", "client_name"].includes(prm)
  );
  const is_signup = /signup/g.test(window.location.pathname);
  const is_forgot_password = /forgot_password/g.test(window.location.pathname);
  const is_reset_password = /reset_password/g.test(window.location.pathname);

  const not_maintenance = process.env.REACT_APP_ONGOING_MAINTENANCE !== "true";
  // const uploadDetailsLink = "/admin/trip/geofences/upload/details";
  const linkSidebarNotShown = [
    "/admin/trip/geofences/upload/details",
    "/monitoring/:view/isolate",
    "/monitoring/:view/history",
    "/notifications",
    "/pms/view"
  ];

  const headerNotShown = ["/monitoring/:view/history"];

  function isNewTabHandler() {
    const newTab = false;
    if (/dashboard\/view/g.test(window.location.pathname)) return !newTab;
    if (/notification\/view/g.test(window.location.pathname)) return !newTab;
    // else if(/monitoring\/isolate/g.test(window.location.pathname)) return !newTab;
    if (/map_view/g.test(window.location.pathname)) return !newTab;
    return newTab;
  }

  return (
    <div>
      {isLoggedIn ? (
        <React.Fragment>
          <UserProvider value={JSON.parse(Cookie.get("user"))}>
            <ThemeProvider>
              {isAnotherWindow || isLiveCamera || isNewTabHandler() ? (
                <Switch>
                  <Route
                    exact
                    path={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/view/:id`}
                    component={ViewControlRoom}
                  />
                  <Route
                    exact
                    path="/dashboard/view/:id"
                    component={FleetView}
                  />
                  <Route exact path="/map_view/" component={MapView} />
                  <Route
                    exact
                    path="/notification/view/:id"
                    component={GpsNotification}
                  />
                  <Route
                    exact
                    path="/monitoring/live-camera/:id/:channel_count"
                    component={VssCamera}
                  />
                </Switch>
              ) : not_maintenance ? (
                <Switch>
                  <Onboarding>
                    {/* {location.pathname === uploadDetailsLink? : */}
                    <HeaderSidenav
                      // isShown={_.isEqual(location.pathname, uploadDetailsLink)}
                      isShown={linkSidebarNotShown.includes(location.pathname)}
                      isHeaderShown={
                        !headerNotShown.includes(location.pathname)
                      }
                    >
                      {/* <Route path="/admin/resources/users/" component={AdminIndex} /> */}
                      <Route
                        exact
                        path={linkSidebarNotShown[0]}
                        component={UploadDetails}
                      />
                      <Route path="/trips" component={TripList} />
                      <Route path="/split" component={TripSplit} />
                      <Route exact path="/reports" component={Create} />
                      <Route
                        exact
                        path="/reports/automated"
                        component={Automated}
                      />
                      <Route
                        exact
                        path="/reports/automated/add"
                        component={AddGeneratedReport}
                      />
                      <Route
                        path="/reports/automated/view/:id"
                        component={ViewGeneratedReport}
                      />
                      <Route exact path="/details" component={TripDetails} />
                      <Route path="/profile/edit" component={UserProfile} />
                      <Route path="/profile/edits" component={ProfileEdit} />
                      <Route path="/empty" component={null} key="empty" />
                      <Route exact path="/admin" component={AdminIndex} />
                      <Route
                        exact
                        path="/admin/resources/users/add"
                        component={AddUser}
                      />
                      <Route
                        path="/admin/resources/users/view/:id"
                        component={User}
                      />
                      <Route
                        exact
                        path="/admin/activity/user_levels/add"
                        component={AddAccessRight}
                      />
                      <Route
                        exact
                        path="/admin/activity/user_levels/view/:id"
                        component={AccessRight}
                      />
                      {/* <Route
                        exact
                        path="/admin/trip/geofences/add"
                        component={AddGeofence}
                      /> */}
                      <Route
                        exact
                        path="/admin/trip/geofences/add"
                        component={StandardGeofence}
                      />
                      <Route
                        exact
                        path="/admin/trip/geofences/addRouteGeofence"
                        component={RouteGeofence}
                      />
                      <Route
                        exact
                        path="/admin/trip/routegeofences/view/:id"
                        component={EditRoute}
                      />
                      {/* <Route
                        exact
                        path="/admin/trip/geofences/view/:id"
                        component={Geofence}
                      /> */}
                      <Route
                        exact
                        path="/admin/trip/geofences/view/:id"
                        component={EditStandardGeofence}
                      />
                      <Route
                        exact
                        path="/admin/activity/alerts/view/:id"
                        component={Alert}
                      />
                      <Route
                        exact
                        path="/admin/resources/devices/add"
                        component={AddDevice}
                      />
                      <Route
                        exact
                        path="/admin/resources/devices/view/:id"
                        component={Device}
                      />
                      <Route
                        exact
                        path="/admin/wti/gps_providers/add"
                        component={AddGPSProviders}
                      />
                      <Route
                        exact
                        path="/admin/wti/gps_providers/view/:id"
                        component={ViewGPSProvider}
                      />
                      <Route
                        exact
                        path="/admin/wti/alert_descriptions/add"
                        component={AddAlertDescriptions}
                      />
                      <Route
                        exact
                        path="/admin/wti/alert_descriptions/view/:id"
                        component={ViewAlertDescriptions}
                      />
                      <Route
                        exact
                        path="/admin/resources/vehicle_types/add"
                        component={AddVehicleType}
                      />
                      <Route
                        exact
                        path="/admin/resources/vehicle_types/view/:id"
                        component={ViewVehicleType}
                      />
                      <Route
                        exact
                        path="/admin/wti/alert_references/add"
                        component={AddAlertReferences}
                      />
                      <Route
                        exact
                        path="/admin/wti/alert_references/view/:id"
                        component={ViewAlertReferences}
                      />
                      <Route
                        exact
                        path="/admin/resources/vehicles/view/:id"
                        component={VehicleDetails}
                      />
                      <Route
                        exact
                        path="/admin/resources/vehicles/add"
                        component={AddVehicle}
                      />
                      <Route
                        exact
                        path="/admin/wti/clients"
                        component={Clients}
                      />
                      <Route
                        exact
                        path="/admin/wti/clients/add"
                        component={AddClient}
                      />
                      <Route
                        exact
                        path="/admin/wti/clients/view/:id"
                        component={ViewClient}
                      />
                      <Route
                        exact
                        path="/admin/wti/clients/view/:id/:page?"
                        component={ViewClient}
                      />
                      <Route exact path="/view_trip" component={TripDetails} />
                      <Route exact path="/dashboards" component={Dashboards} />
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route
                        exact
                        path={["/monitoring", "/monitoring/:view?"]}
                        component={Monitoring}
                      />
                      {/* <Route
                        exact
                        path="/monitoring/:view?"
                        component={Monitoring}
                      /> */}
                      <Route exact path="/pms" component={Mechanic} />
                      <Route
                        exact
                        path={`${linkSidebarNotShown[2]}`}
                        component={Historical}
                      />
                      <Route
                        exact
                        path="/admin/acitivity_logs"
                        component={ActivityLogs}
                      />
                      <Route
                        path={`${linkSidebarNotShown[1]}`}
                        component={MonitoringIsolate}
                      />
                      <Route
                        exact
                        path={linkSidebarNotShown[3]}
                        component={NotificationPage}
                      />
                      <Route exact path="/prebooking" component={PreBooking} />
                      <Route
                        exact
                        path="/admin/resources"
                        component={AdminIndex}
                      />
                      <Route exact path="/feedback" component={Feedback} />
                      <Route
                        exact
                        path={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/add`}
                        component={AddControlRoom}
                      />
                      {/* <Route exact path="/" component={Monitoring} /> */}
                      <Route
                        exact
                        path="/admin/driver_management/driver_info/add/"
                        component={AddDriverInfo}
                      />
                      <Route
                        exact
                        path="/admin/driver_management/driver_info/view/:id"
                        component={EditDriverInfo}
                      />
                      <Route
                        exact
                        path={linkSidebarNotShown[4]}
                        component={Checklist360History}
                      />
                      <Route
                        exact
                        path="/admin/resources/assign_geofence/:id"
                        component={AssignGeofence}
                      />
                      <Route
                        exact
                        path={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/`}
                        component={ControlRoom}
                      />
                      <Route
                        exact
                        path="/admin/driver_management/for_review/:id"
                        component={ReviewCard}
                      />
                      <Route exact path="/allocation" component={Allocation} />
                      <Route
                        exact
                        path="/allocation/add"
                        component={AddAllocation}
                      />
                    </HeaderSidenav>
                  </Onboarding>
                </Switch>
              ) : (
                <div
                  style={{
                    background: `url(${process.env.PUBLIC_URL}/maintenance.png)`,
                    height: "100vh",
                    width: "100vw",
                    backgroundSize: "cover"
                  }}
                />
              )}
            </ThemeProvider>
          </UserProvider>
        </React.Fragment>
      ) : (
        // <Switch>
        //   <ThemeProvider>
        //     <Route exact path="/" component={Login} />
        //     <Route exact path="/login" component={Login} />
        //     <Route exact path="/signup" component={Signup} />
        //     <Route exact path="/forgot_password" component={ForgotPassword} />
        //     <Route exact path="/reset_password" component={ResetPassword} />
        //     <Route exact path="/view_trip" component={Shareable} />
        //   </ThemeProvider>
        //   <Redirect to="/login" />
        // </Switch>
        <Switch>
          <ThemeProvider>
            {(isViewTrip && valid_parameters) || isNewTabHandler() ? (
              <>
                <Route exact path="/map_view" component={MapView} />
                <Route exact path="/view_trip" component={Shareable} />
              </>
            ) : (
              <>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route
                  exact
                  path="/forgot_password"
                  component={ForgotPassword}
                />
                <Route exact path="/reset_password" component={ResetPassword} />
                {!(is_signup || is_forgot_password || is_reset_password) && (
                  <Redirect to="/login" />
                )}
              </>
            )}
          </ThemeProvider>
        </Switch>
      )}
    </div>
  );
};

export default withRouter(App);
