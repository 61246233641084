import React, { useEffect, useState } from "react";
import AddLogs from "../../../../utils/functions/AddLogs";

const { tableau } = window;
// This will display the links on tableau and show the graph it contains.
function EmbedTableau({ newTab, tab, linkData }) {
  const [viz, setViz] = useState(null);
  // initialization of tableau visuals
  const initViz = () => {
    // target div container
    let vizDiv = document.getElementById("vizContainer");
    // url
    let vizURL = linkData[tab].link;
    // styles
    const options = {
      hideTabs: true,
      hideToolbar: true,
      height: "100%",
      width: "100%"
    };

    if (viz) {
      viz.dispose();
      setViz(null);
    }
    setViz(new tableau.Viz(vizDiv, vizURL, options));
  };
  // initialize i frame for links representation.
  useEffect(() => {
    AddLogs("Dashboard - Overview", "view_dashboard_overview", linkData[tab].name);
    initViz()
  }, [tab, linkData]);

  return (
    <div
      id="vizContainer"
      style={{
        width: newTab? "100vw" : "77vw",
        height: newTab? "92%" : "67vh",
        overflow: "auto"
      }}
    />
  );
}

export default EmbedTableau;
