/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Badge,
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  FormControl,
  // Select,
  IconButton,
  TextField,
  Tooltip,
  InputLabel,
  TableContainer,
  Table,
  TableBody,
  Fab,
  // CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  FilterList as FilterListIcon,
  // KeyboardArrowRight as KeyboardArrowRightIcon,
  // KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Visibility as VisibleIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../../../utils/Loading";
import Filter from "../../Filter";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Upload from "./View/Upload";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../../../utils/table/Pagination";
import useFormStyles from "../../../../styles/useFormStyles";

import { GET_ALERT_DESCRIPTIONS } from "../../../../graphql/Queries";
import {
  DELETE_ALERT_DESCRIPTION,
  DELETE_ALERT_DESCRIPTION_MULTIPLE
} from "../../../../graphql/Mutations";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "code",
    numeric: false,
    disablePadding: true,
    label: "Alert Description"
  },
  { id: "parent_id", numeric: true, disablePadding: false, label: "Parent" },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Remarks"
  }
  // { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

function TableHeadCells(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked,
    canEdit
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!canEdit}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function AlertDescriptions() {
  const formStyles = useFormStyles();
  const classes = useStyles();
  const history = useHistory();
  const vars = history?.location?.state?.params;
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [canEdit, setcanEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [alertData, setAlertData] = useState([]); // set initial data from backend
  const [filterData, setFilterData] = useState([]); // set filter data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(0); // setPage
  const [filterDrawer, setFilterDrawer] = useState(false); // set to open filter drawer
  const [alertKeyword, setAlertKeyword] = useState(""); // set to user search
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("code");
  const [filter, setFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [clearFirst, setClearFirst] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [tempFilter, setTempFilter] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [mddopen, setMddopen] = useState(false);
  const [deleteRes, setDeleteRes] = useState([]);
  const [keyLog, setKeyLog] = useState("");
  const [keyDel, setKeyDel] = useState();
  const [selDel, setSelDel] = useState([]);

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    applySavedFilter();
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      // setLoading(true);
      setAlertKeyword(searchKeyword.value);
      setPage(0);
    }
  }, [searchKeyword, clearFirst]);

  const handleClose = () => {
    setDialog(false);
    setMddopen(false);
  };

  const getVariables = () => {
    let variables = {
      skip: rowsPerPage * page,
      keyword: alertKeyword,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }],
      filter: [],
      condition: []
    };

    if (filter.length > 0) {
      filter.map(row => {
        if (row.field === "code") {
          return Object.assign(variables, {
            filter: row
          });
        }
        return Object.assign(variables, {
          condition: row
        });
      });
    }
    return variables;
  };

  const { data: currentData, loading: loadingData, refetch, fetchMore } = useQuery(
    GET_ALERT_DESCRIPTIONS,
    {
      variables: getVariables(),
      onCompleted: () => {
        if (alertKeyword) {
          AddLogs("Admin - Alert Descriptions", "search", alertKeyword);
        }
        // setLoading(false);
        setDisableButton(false);
        // setCircularLoading(false);
        // setPage(1);
        setSelected([]);
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  );

  const [getSelected] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_alert_descriptions.alert_descriptions.map(val => {
          temp.push(val.code);
          return temp;
        });
        setSelDel(temp);
        deleteAlertDescriptionMultiple({
          variables: {
            condition: {
              field: "id",
              value: selected
            }
          }
        });
      }
    }
  });

  const [get_description] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        // setPlaces([...data.get_alert_descriptions.alert_descriptions.code]);
        setFilterData(data.get_alert_descriptions.alert_descriptions);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (currentData) {
      setAlertData(currentData?.get_alert_descriptions?.alert_descriptions);
      setTotalRows(currentData?.get_alert_descriptions?.total);
      // setPage(0);
      // setLoading(false);
    }
  }, [currentData]);

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const [deleteAlertDescription] = useMutation(DELETE_ALERT_DESCRIPTION, {
    onCompleted(res) {
      if (res.delete_alert_description.success) {
        AddLogs("Admin - Alert Descriptions", "delete", keyDel);
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            // setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          } else {
            // setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          }
        });
      } else {
        handleClose();
        Swal.fire({
          // title: "Cannot Delete. This Alert Description is currently in used",
          title: res.delete_alert_description.error,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            // setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          } else {
            // setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (
      currentData.get_alert_descriptions.count === 1 &&
      currentData.get_alert_descriptions.total > 1
    )
      setPage(page - 1);
    deleteAlertDescription({
      variables: { id: toDelete.toString() }
    });
    setToDelete([]);
  };

  const [deleteAlertDescriptionMultiple] = useMutation(
    DELETE_ALERT_DESCRIPTION_MULTIPLE,
    {
      onCompleted(res) {
        if (res.delete_alert_description_all.success) {
          AddLogs("Admin - Alert Descriptions", "delete", selDel);
          handleCloseDeleteAll();
          if (
            res.delete_alert_description_all.deleted ===
            res.delete_alert_description_all.total
          ) {
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            }).then(result => {
              // setLoading(true);
              setSelected([]);
              setToDelete([]);
              setPage(0);
              refetch(getVariables());
              return result;
            });
          } else {
            setDeleteRes(res.delete_alert_description_all);
            setMddopen(true);
            setTimeout(() => {
              setMddopen(false);
            }, 1500);
            // setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(0);
            refetch(getVariables());
          }
        } else {
          setDeleteRes(res.delete_alert_description_all);
          setMddopen(true);
          setTimeout(() => {
            setMddopen(false);
          }, 1500);
          // setLoading(true);
          setSelected([]);
          setToDelete([]);
          setPage(0);
          refetch(getVariables());
        }
      }
    }
  );

  const handleDeleteAlert = () => {
    const temp = [""];
    if (selected.length > 0) {
      selected.map(id => temp.push(id));
      getSelected({
        variables: {
          filter: { field: "id", value: temp },
          first: selected.length
        }
      });
    }
    // deleteAlertDescriptionMultiple({
    //   variables: {
    //     condition: {
    //       field: "id",
    //       value: selected
    //     }
    //   }
    // });
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (alertData) {
      return stableSort(alertData, getSorting(order, orderBy));
    }
    return null;
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setDisableButton(false);
        // setCircularLoading(false);
        // setLoading(false);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_alert_descriptions: {
            count: fetchMoreResult.get_alert_descriptions.count,
            total: fetchMoreResult.get_alert_descriptions.total,
            alert_descriptions: [
              ...prev.get_alert_descriptions.alert_descriptions,
              ...fetchMoreResult.get_alert_descriptions.alert_descriptions
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(0);
          refetch(getVariables());
        }
      });
    });
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleClearFilter = () => {
    setFilterValue([]);
    setFilter([]);
    setTempFilter([]);
    // setLoading(true);
    setFilterDrawer(false);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const handleFilters = e => {
    const { name, value } = e.target;

    switch (name) {
      case "code":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "parent_id":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      default:
        break;
    }
    setTempFilter({ ...tempFilter, [name]: value });
  };

  const AlertDescriptionTable = props => {
    // const { rowCount } = props;
    const { canEdit } = props;
    if (loadingData) {
      return (
        <Loading />
        // <TableBody>
        //   <TableRow style={{ height: 53 * 2 }}>
        //     <TableCell colSpan={6} align="center">
        //       <CircularProgress />
        //     </TableCell>
        //   </TableRow>
        // </TableBody>
      );
    }
    // if (rowCount) {
    return alertData?.length > 0 ? (
      <TableBody>
        {stableSort(alertData, getSorting(order, orderBy)).map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  disabled={!canEdit}
                  checked={isItemSelected}
                  onClick={event => handleClick(event, row.id)}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <Highlight search={contextData.searchKeyword.value}>
                  {row.code ? row.code : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.parent ? row.parent.code : ""}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.description ? row.description : ""}
                </Highlight>
              </TableCell>
              <TableCell component="th" scope="row">
                <IconButton
                  aria-label="view"
                  onClick={() => {
                    AddLogs("Admin - Alert Descriptions", "view", row.code);
                    // setSearchKeyword({ ...searchKeyword, value: "" });
                    history.push({
                      // pathname: `/admin/wti/alert_descriptions/view/${row.id}`,
                      pathname: `/admin/wti/alert_descriptions/view/${row.id}`,
                      state: {
                        params: {
                          id: row.id,
                          moduleName:
                            process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
                          filter: tempFilter
                        },
                        // queryVars: getVariables()
                      }
                    });
                  }}
                >
                  <VisibleIcon />
                </IconButton>
                {canEdit ? (
                  <IconButton
                    // disabled={!canEdit}
                    aria-label="delete"
                    onClick={() => {
                      handleToDelete(row.id);
                      handleOpen();
                      setKeyDel(row.code);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
    // }
    // return (
    //   <TableBody>
    //     <TableRow style={{ height: 53 * 2 }}>
    //       <TableCell colSpan={6} align="center">
    //         No data found
    //       </TableCell>
    //     </TableRow>
    //   </TableBody>
    // );
  };

  // useEffect(() => {
  //   setLoading(false);
  // });

  const applySavedFilter = () => {
    const temp = [];
    const filterVal = vars?.filter;
    if (filterVal) {
      Object.keys(filterVal)
      .filter(key => filterVal[key])
      .map(key => {
        if (key === "parent_id") {
          temp.push({ field: key, value: filterVal[key].id });
        } else {
          temp.push({ field: key, value: filterVal[key] });
        }
        return temp;
      });

      setFilter(temp);
      setTempFilter(filterVal);
      setFilterValue(filterVal?.parent_id)
      setKeyLog(
        `${
          filterVal?.code ? `Alert Description: ${filterVal?.code}` : ``
        }, Parent Description: ${filterVal?.parent_id?.code || `All`}`
      );
    }
  }

  const handleSetFilters = () => {
    // setLoading(true);
    const temp = [];
    let code = "";
    let parent = "";
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        if (key === "parent_id") {
          parent = tempFilter[key].code;
          temp.push({ field: key, value: tempFilter[key].id });
        } else {
          code = tempFilter[key];
          temp.push({ field: key, value: tempFilter[key] });
        }
        return temp;
      });

    setFilter(temp);
    setKeyLog(
      `${
        code ? `Alert Description: ${code}` : ``
      }, Parent Description: ${parent || `All`}`
    );
    AddLogs(
      "Admin - Alert Descriptions",
      "filter",
      `${
        code ? `Alert Description: ${code}, ` : ``
      }Parent Description: ${parent || `All`}`
    );
    setPage(0);
    closeFilterDrawer();
  };

  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
        }
      }
    });
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [["Alert Description", "Parent Description", "Remarks"]];
    // alertData.alert_descriptions.map(row =>
    //   temp.push([row.code || "", row.parent.code || "", row.description || ""])
    // );

    getData().map(row =>
      temp.push([row.code || "", row.parent.code || "", row.description || ""])
    );

    return temp;
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          data={tableData}
          style={{ textDecoration: "none", color: "black" }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      AddLogs(
                        "Admin - Alert Descriptions",
                        "download_template",
                        ""
                      )
                    }
                  >
                    <Link
                      to="/assets/alert_description_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      AddLogs(
                        "Admin - Alert Descriptions",
                        "download_table",
                        keyLog
                      )
                    }
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  // if (loadingData) return <Loading />;

  return (
    <AccessControl
      resource="/admin/wti/alert_descriptions/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              Alert Descriptions
            </Typography>
            <Button
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
              disabled={disableButton}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={() => setUploadOpen(true)}
                disabled={disableButton}
              >
                Upload
              </Button>
            )}
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                onClick={openFilterDrawer}
                disabled={disableButton}
              >
                <Badge
                  color="secondary"
                  variant={filter.length ? "dot" : "standard"}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination 
              rowsPerPage={rowsPerPage}
              currentPage={page}
              total={Math.ceil(totalRows / rowsPerPage)}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                setPage(0);
              }}
              onPageChange={n => setPage(n)}
              onPageUp={n => setPage(n)}
              onPageDown={n => setPage(n)}
            />
            {/* <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={alertData?.length || 0}
              fetchMoreData={fetchMoreData}
              totalRows={totalRows}
              totalPage={Math.ceil(totalRows / rowsPerPage)}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setCircularLoading}
              setLoading={setLoading}
            /> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                  disabled={disableButton}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) <= 1 || disableButton}
                onClick={() => {
                  setCircularLoading(true);
                  setDisableButton(true);
                  handleDownPage();
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                disabled={disableButton}
                onFocus={e => {
                  e.target.select();
                }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                value={Number(page)}
                style={{ width: "32px", marginRight: "8px" }}
              />
              {`/ ${Math.ceil(totalRows / rowsPerPage)}`}
              <Button
                name="up_page"
                disabled={
                  Number(page) === Math.ceil(totalRows / rowsPerPage) ||
                  disableButton
                }
                onClick={() => {
                  setCircularLoading(true);
                  setDisableButton(true);
                  handleUpPage();
                }}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div> */}
          </Toolbar>
          <Toolbar
            className={clsx(classes.toolbar_root, {
              [classes.highlight]: selected.length > 0,
              [classes.invisible]: selected.length <= 0
            })}
          >
            {selected.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {/* <span style={{ color: "#FF845E" }}>{selected.length}</span>{" "} */}
                {selected.length} alert description/s selected on this page
              </Typography>
            )}

            {selected.length > 0 &&
              (canEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    position: "absolute",
                    right: 0,
                    borderRadius: 100,
                    color: "white"
                  }}
                  onClick={handleOpenDeleteAll}
                >
                  Delete Selected
                </Button>
              ) : null)}
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleSort={handleSort}
                isChecked={isChecked}
                canEdit={canEdit}
              />
              <AlertDescriptionTable
                selected={selected}
                alertData={alertData}
                classes={classes}
                // rowCount={dataCount}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                canEdit={canEdit}
              />
            </Table>
          </TableContainer>
        </Paper>
        <Upload
          open={uploadOpen}
          setUploadOpen={setUploadOpen}
          getQueryConditions={getVariables}
          // currentPage={currentPage}
        />
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <TextField
              classes={{ root: formStyles.textFieldRoot }}
              fullWidth
              name="code"
              label="Alert Description"
              placeholder="Alert Description"
              onChange={e => handleFilters(e)}
              value={tempFilter?.code ? tempFilter.code : ""}
              InputLabelProps={{
                shrink: true,
                style: { fontWeight: "bold", color: "black", letterSpacing: 1 }
              }}
            />
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink>Parent Description</InputLabel>
            <br />
            <Autocomplete
              id="parent_id"
              options={filterData}
              value={filterValue}
              getOptionLabel={option => option.code || ""}
              getOptionSelected={(o, v) => v.value === o.value}
              renderOption={option => option.code}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  placeholder="All"
                  onChange={event => {
                    if (event.target.value.length >= 2) {
                      get_description({
                        variables: {
                          first: 10,
                          filter: {
                            field: "code",
                            value: event.target.value
                          }
                        }
                      });
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              onChange={(e, val) => {
                // if (val) {
                //   setTempFilter({
                //     ...tempFilter,
                //     parent_id: val
                //   });
                //   setFilterValue(val);
                // }
                setTempFilter({
                  ...tempFilter,
                  parent_id: val
                });
                setFilterValue(val);
              }}
              // disableClearable
            />
          </FormControl>
        </Filter>
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            style={{
              margin: 0,
              top: "auto",
              right: 40,
              bottom: 30,
              left: "auto",
              position: "fixed",
              fontSize: "200px"
            }}
            onClick={() => {
              history.push({
                pathname: "/admin/wti/alert_descriptions/add",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
                    filter: tempFilter
                  },
                  // queryVars: getVariables()
                }
              });
            }}
          >
            <AddIcon
              style={{
                width: "50px",
                height: "50px"
              }}
              // onClick={() => setSearchKeyword({ ...searchKeyword, value: "" })}
            />
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this alert description?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteAlert(e)}
          title="Delete?"
          content="Are you sure you want to delete these alert descriptions?"
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}

        <Dialog aria-labelledby="simple-dialog-title" open={mddopen}>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <h2 style={{ color: "red", fontWeight: "bold" }}>Warning !</h2>
              Cannot delete. This Alert Description is currently in used.
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Deleted:{" "}
              <span style={{ color: "green" }}>{deleteRes.deleted}</span>
              <br />
              Not Deleted:{" "}
              <span style={{ color: "orange" }}>
                {deleteRes.total - deleteRes.deleted}
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ borderRadius: "17px", margin: 10, width: 100 }}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </AccessControl>
  );
}
