import React, { useState, useEffect } from "react";
import { TableRow, TableCell, IconButton, makeStyles } from "@material-ui/core";
import moment from "moment";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import _ from "lodash";
import Highlight from "react-highlighter";
import MechanicModal from "../../utils/modals/MechanicModal";
import Header from "../../utils/UI/Table/Header";
import Table from "../../utils/UI/Table/CustomTable";
import AddLogs from "../../utils/functions/AddLogs";

// constant values.
const Headers = [
  { id: "vehicle_info.plateno", label: "Plate Number", isSortable: true },
  { id: "vehicle_types.name", label: "Vehicle Type", isSortable: true },
  {
    id: "distancetraveled",
    label: "Current Odometer Reading (km)",
    isSortable: true
  },
  {
    id: "vehicle_info.last_maintenance_date",
    label: "Last Maintenance Date",
    isSortable: true
  },
  { id: "rover_status.status_code_id", label: "Status", isSortable: false },
  { id: "actions", label: "Actions", isSortable: false }
];
const HEADER_LABEL = "Vehicle Issues";
const INITIAL_FORMAT_DATE = "LL";
const MODAL_LABEL = "Maintenance Info";
const MODAL_LABEL_FOR_MAINTENANCE_REPAIR = "Select an Action";
const MODAL_DESCRIPTION = `This vehicle is scheduled for maintenance. Click Resolve if the vehicle has been successfully maintained.`;
const MODAL_DESCRIPTION_FOR_MAINTENANCE_REPAIR = `This vehicle is schedule for maintenance and has been identified with issues. Select an action to be done.`;
let status = "";
const statusOpts = [
  { id: 1, label: "View All" },
  { id: 2, label: "For Repair" },
  { id: 3, label: "For Maintenance" },
  { id: 4, label: "Resolved" },
  { id: 5, label: "For Repair & Maintenance" }
];

const VehicleIssues = props => {
  const classes = useStyles();
  const {
    page,
    setPage,
    vehicles,
    count,
    total,
    order,
    setOrder,
    totalPage,
    vars,
    refetchVehicle
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statusOpts[0]);

  // modal functions.
  function closeModalHandler() {
    setOpenModal(false);
    setSelectedVehicle([]);
  }

  const resetDropdownStatus = () => setSelectedStatus(statusOpts[0]);

  function openModalHandler(data) {
    status = data.is_for_maintenance === 1 && data.is_for_repair === 0;
    setSelectedVehicle(data);
    if (data.is_for_maintenance) {
      AddLogs("PMS - Vehicle Issues", "view", data.plateno);
      setOpenModal(true);
    } else {
      AddLogs("PMS - 360 Checklist", "checklist_view", data.plateno);
      window.open(`/pms/view?vehicle_id=${data.id}`);
    }
  }
  // set status label and color.
  function statusHandler(forRepair, forMaintenance) {
    let status = { label: "Resolved", color: "#3e3e3e" };

    if (+forMaintenance === 1 && +forRepair === 1) {
      status = { label: "For Repair & Maintenance", color: "#FF845E" };
    } else if (+forMaintenance === 1) {
      status = { label: "For Maintenance", color: "#FF845E" };
    } else if (+forRepair === 1) {
      status = { label: "For Repair", color: "#FF845E" };
    }

    return <span style={{ color: `${status.color}` }}>{status.label}</span>;
  }

  useEffect(() => {
    let filter = [];
    let tempVars = vars;
    // for repair
    if (+selectedStatus.id === 2) {
      filter.push({ field: "is_for_repair", value: "1" });
      filter.push({ field: "is_for_maintenance", value: "0" });
    }
    // for maintenance
    else if (+selectedStatus.id === 3) {
      filter.push({ field: "is_for_repair", value: "0" });
      filter.push({ field: "is_for_maintenance", value: "1" });
    }
    // resolved status
    else if (+selectedStatus.id === 4) {
      filter.push({ field: "is_for_repair", value: "0" });
      filter.push({ field: "is_for_maintenance", value: "0" });
    }
    // for maintenance and repair
    else if (+selectedStatus.id === 5) {
      filter.push({ field: "is_for_repair", value: "1" });
      filter.push({ field: "is_for_maintenance", value: "1" });
    }
    // empty filter condition
    else {
      filter = [];
    }

    tempVars.condition = filter;
    tempVars = {...tempVars, group_ids: []}
    refetchVehicle(tempVars);
  }, [selectedStatus]);

  function saveHandler() {}
  return (
    <>
      <Header
        label={HEADER_LABEL}
        total={total}
        page={page}
        setPage={setPage}
        totalPage={totalPage}
        vars={vars}
        refetchVehicle={refetchVehicle}
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        statusOpts={statusOpts}
      />
      <Table headers={Headers} order={order} setOrder={setOrder}>
        {count > 0 ? (
          vehicles.map((data, index) => {
            const forRepair = _.isEqual(+data.is_for_repair, 0);
            const forMaintenance = _.isEqual(+data.is_for_maintenance, 0);
            const resolved = forRepair && forMaintenance;
            const distanceTraveled =
              data.device_info?.device_status?.distancetraveled / 1000;
            const { last_maintenance_date, maintenance_remarks } = data;

            return (
              <TableRow
                key={index}
                hover
                className={classes.table_data_container}
              >
                <TableCell className={classes.table_data}>
                  <Highlight search={vars.keyword}>{data.plateno}</Highlight>
                </TableCell>
                <TableCell>
                  <span className={classes.table_data}>
                    <Highlight search={vars.keyword}>
                      {data.vehicle_type}
                    </Highlight>
                  </span>
                </TableCell>
                <TableCell>
                  {distanceTraveled > 0 ? (
                    <span className={classes.table_data}>
                      {Number(distanceTraveled).toLocaleString("en-US")}
                    </span>
                  ) : (
                    <span className={classes.error_data}>Not Applicable</span>
                  )}
                </TableCell>
                <TableCell>
                  {last_maintenance_date ? (
                    <span className={classes.table_data}>
                      {moment(last_maintenance_date).format(
                        INITIAL_FORMAT_DATE
                      )}
                    </span>
                  ) : (
                    <span className={classes.error_data}>Not Applicable</span>
                  )}
                </TableCell>
                <TableCell>
                  {statusHandler(data.is_for_repair, data.is_for_maintenance)}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => openModalHandler(data)}
                    disabled={resolved}
                  >
                    <BuildRoundedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={20}>
              No Records Found
            </TableCell>
          </TableRow>
        )}
      </Table>
      {openModal && (
        <MechanicModal
          toggle={openModal}
          title={status ? MODAL_LABEL : MODAL_LABEL_FOR_MAINTENANCE_REPAIR}
          content={
            status
              ? MODAL_DESCRIPTION
              : MODAL_DESCRIPTION_FOR_MAINTENANCE_REPAIR
          }
          fn={saveHandler}
          close={closeModalHandler}
          vehicle={selectedVehicle}
          type={HEADER_LABEL}
          vars={vars}
          defaultLabel={MODAL_LABEL}
          defaultContent={MODAL_DESCRIPTION}
          resetDropdownStatus={resetDropdownStatus}
          refetchVehicle={refetchVehicle}
        />
      )}
    </>
  );
};

export default VehicleIssues;

const useStyles = makeStyles(theme => ({
  table_data: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  error_data: {
    color: theme.palette.error.dark,
    fontSize: 13
  }
}));
