/**
 * @description Onboarding wrapper module
 */

import React, {
  useRef,
  createContext,
  useContext,
  useMemo,
  useState,
  createRef
} from "react";
import Cookie from "js-cookie";
import BasicModal from "../modal/BasicModal";
import useUserContext from "../../context/User/useUserContext";

export const current_module_initialized_ref = React.createRef();
const __starts_in__ = React.createRef();
const stepStartsInRef = React.createRef();
export const onboarding_ctx_ref = React.createRef(/* "monitoring" */);
export const OnboardingContext = createContext({});
export const useOnboardingContext = ({
  module = Onboarding.modules.monitoring,
  allowContextToChange = false,
  stepStartsIn
} = {}) => {
  const context = useContext(OnboardingContext);

  if (!context)
    throw new Error(
      `Can't use this useOnboardingContext hook outside OnboardingProvider.`
    );

  if (!onboarding_ctx_ref.current) onboarding_ctx_ref.current = module;

  // If 'allowContextToChange' is equal to true, onboarding "module context" will be change
  if (allowContextToChange)
    onboarding_ctx_ref.current = Onboarding.modules[module];

  if (!__starts_in__.current) __starts_in__.current = INITIAL_STATE;
  if (current_module_initialized_ref.current === null) {
    current_module_initialized_ref.current = false;
  }
  if (stepStartsIn >= 0) stepStartsInRef.current = stepStartsIn;

  if (stepStartsInRef.current && !current_module_initialized_ref.current) {
    if (context.progress[module] < onboarding_max[module]) {
      context.jumpToStep({
        module: module,
        step: stepStartsIn
      });

      current_module_initialized_ref.current = true;
      __starts_in__.current = {
        ...__starts_in__.current,
        [module]: stepStartsIn
      };
    }
  }

  return {
    ...context,
    module: onboarding_ctx_ref.current
  };
};

const getCurrentModuleByURL = () =>
  window.location.pathname.replace(/\//, "").split("/")[0];
const INITIAL_STATE = {
  monitoring: 0,
  prebooking: 0,
  trips: 0,
  dashboard: 0,
  reports: 0,
  admin: 0,
  feedback: 0
};
export const onboarding_max = {
  monitoring: 10,
  prebooking: 8,
  trips: 7,
  dashboard: 6,
  reports: 13,
  feedback: 5,
  admin: 12,
  undefined: 0
};
export const onboarding_enum = {
  false: false,
  true: true,
  undefined: false
};

export const modalRef = createRef();
const Onboarding = ({ children }) => {
  const user_context = useUserContext();
  /**
   * Tasks
   *    - added onboarding context ✅
   *    - structured data for onboarding pages (OB) ✅
   *    - created function for updating state of every OB pages ✅
   *    - added condition on when to show the blur effect ✅
   */
  const [progress, setProgress] = useState(INITIAL_STATE);
  const [skippedIntro, setSkippedIntro] = useState(false);
  const isModuleKeyExists = module =>
    Object.keys(progress).some(key => key === module);

  const nextStep = module => {
    // setCurrentStep(prev => prev + 1);
    if (isModuleKeyExists(module))
      setProgress(prev => ({ ...prev, [module]: prev[module] + 1 }));
  };
  const jumpToStep = ({ module, step }) => {
    // setCurrentStep(n);
    if (isModuleKeyExists(module))
      setProgress(prev => ({
        ...prev,
        [module]: step || prev[module] + 1
      }));
  };
  const completeIntro = () => {
    setSkippedIntro(true);
  };

  const handleModalClose = (_, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    modalRef.current.close();
  };

  const completeOnboarding = () => {
    // 1️⃣ Triggers mutation that will update the onboarding status
    // 2️⃣ Redirect user or Refresh page

    // completeOnboarding(module)
    // window.location.reload();
    const currentModule = onboarding_ctx_ref.current;
    user_context
      .updateUser({
        variables: {
          id: user_context.id,
          onboarding: {
            [currentModule]:
              !onboarding_max[currentModule] >= progress[currentModule]
          }
        }
      })
      .then(() => {
        const onboardingUsers = JSON.parse(
          Cookie.get("onboardingUsers") || "{}"
        );
        const userOnboardingStatus = onboardingUsers[user_context.id] ?? {};
        Cookie.set(
          "onboardingUsers",
          JSON.stringify({
            ...onboardingUsers,
            [user_context.id]: {
              ...userOnboardingStatus,
              [onboarding_ctx_ref.current]: false
            }
          })
        );
        nextStep(onboarding_ctx_ref.current);
      });
  };

  React.useEffect(() => {
    return () => {
      __starts_in__.current = undefined;
      stepStartsInRef.current = undefined;
      onboarding_ctx_ref.current = undefined;
      current_module_initialized_ref.current = undefined;
    };
  }, []);

  const value = useMemo(() => {
    return {
      // module,
      // currentStep,
      progress,
      skippedIntro,
      nextStep,
      jumpToStep,
      completeOnboarding,
      completeIntro
    };
  }, [module, progress, skippedIntro]);

  React.useEffect(() => {
    const currentModule = getCurrentModuleByURL();
    if (progress[currentModule] !== undefined) {
      if (progress[currentModule] >= onboarding_max[currentModule] || currentModule === "dashboard")
        modalRef.current.closeModal();
      else modalRef.current.openModal();
      // switch (getCurrentModuleByURL()) {
      //   case Onboarding.modules.monitoring:
      //     if (progress[Onboarding.modules.monitoring] > 9)
      //       modalRef.current.closeModal();
      //     else modalRef.current.openModal();
      //     break;
      //   case Onboarding.modules.prebooking:
      //     if (progress[Onboarding.modules.prebooking] > 7)
      //       modalRef.current.closeModal();
      //     else modalRef.current.openModal();
      //     break;
      //   case Onboarding.modules.trips:
      //     if (progress[Onboarding.modules.trips] > 6)
      //       modalRef.current.closeModal();
      //     else modalRef.current.openModal();
      //     break;
      //   default:
      //     modalRef.current.closeModal();
      // }
    }
  }, [
    progress[Onboarding.modules.monitoring],
    progress[Onboarding.modules.prebooking],
    progress[Onboarding.modules.trips],
    progress[Onboarding.modules.dashboard],
    progress[Onboarding.modules.reports],
    progress[Onboarding.modules.feedback],
    progress[Onboarding.modules.admin],
    getCurrentModuleByURL()
  ]);

  React.useEffect(() => {
    if (user_context?.onboarding) {
      const onboardingUsers = JSON.parse(Cookie.get("onboardingUsers") || "{}");
      const userOnboardingStatus = onboardingUsers[user_context.id] ?? {};
      const state = Object.keys(user_context?.onboarding).reduce(
        (accum, currentKey) => {
          if (user_context.onboarding[currentKey]) {
            return { ...accum, [currentKey]: 0 };
          }

          return { ...accum, [currentKey]: onboarding_max[currentKey] };
        },
        INITIAL_STATE
      );
      setProgress(() => {
        return {
          ...state,
          ...Object.entries(userOnboardingStatus).reduce(
            (accum, [key, val]) => {
              return {
                ...accum,
                [key]: onboarding_enum[val]
                  ? __starts_in__.current[key] // 0
                  : onboarding_max[key]
              };
            },
            {}
          )
        };
      });
    }
    return () => {};
  }, [user_context?.onboarding]);

  return (
    <div>
      <OnboardingContext.Provider value={value}>
        {children}
      </OnboardingContext.Provider>
      <BasicModal open={false} ref={modalRef} onClose={handleModalClose}>
        <div></div>
      </BasicModal>
    </div>
  );
};

Onboarding.modules = {
  monitoring: "monitoring",
  prebooking: "prebooking",
  trips: "trips",
  dashboard: "dashboard",
  reports: "reports",
  feedback: "feedback",
  admin: "admin"
};

export default Onboarding;
