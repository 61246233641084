import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  no_access_container: { height: "100vh" },
  wrapper: { height: "100vh" },
  body: {
    position: "relative",
    height: "calc(100% - 66px)",
    width: "100%",
    display: "flex",
    overflow: "hidden"
  },
  map_container: {
    position: "relative",
    height: "100%",
    width: state => (state.editMode ? "50%" : "100%"),
    transition: "all 250ms ease-in"
  },
  area_text_container: {
    position: "relative",
    // width: "max-content",
    top: "-42px",
    right: "65px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    width: 200
  },
  area_typography: {
    flex: 1,
    textShadow: "0px 0px 10px #0000001c",
    textAlign: "right",
    marginRight: 8
  },
  drawing_mode_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  drawing_mode_btn: {
    minWidth: "auto",
    margin: "0px 15px 10px 0px",
    background: "white",
    "&:hover": {
      background: "#fafafaba"
    },
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px"
  },
  zoom_toggle_btn: {
    minWidth: "fit-content",
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    background: state => (state.enableZoom ? "white" : "#FFB74D"),
    "&:hover": {
      background: state => (state.enableZoom ? "#fafafaba" : "#ffb74db3")
    }
  },
  zoom_control_btn_grp: {
    background: "white",
    marginRight: 15,
    transform: "translate(0px, -4px)",
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px"
  },
  zoom_add_btn: {
    minWidth: "auto"
  },
  zoom_minus_btn: {
    minWidth: "auto"
  },
  form_container: {
    position: "relative",
    height: "100%",
    width: state => (state.editMode ? "50%" : "0%"),
    transition: "all 250ms ease-in",
    padding: state => (state.editMode ? theme.spacing(3) : 0),
    boxSizing: "border-box",
    overflowY: "auto"
  },
  vehicle_count_container: {
    // transform: "translate(-160px, 115px)",
    transform: "translate(-160px, 175px)",
    position: "absolute",
    top: 0,
    bottom: 0,
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px"
  },
  label: {
    fontSize: 14,  
    color: "black",
    cursor: "pointer",
  },
}));
