import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Popover,
  Switch,
  MenuItem,
  Select,
  Checkbox,
  Badge,
  IconButton
} from "@material-ui/core";
import {
  MapTwoTone as MapIcon,
  List as ListIcon,
  MyLocation as MyLocationIcon,
  CalendarToday as CalendarTodayIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FilterList as FilterListIcon
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import useStyles from "./styles";
import AddLogs from "../../../utils/functions/AddLogs";

const Header = React.memo(props => {
  const styles = useStyles();
  const {
    handleRecenter,
    date,
    handleDate,
    setDateOpen,
    isPrimary,
    // setOpen,
    locationDisabled,
    handleExpandAllAlerts,
    handleChangeView,
    isMapView,
    expandAllAlerts,
    showLogs,
    setShowLogs,
    setOpenFilter,
    isFiltered
  } = props;
  const [showToggle, setShowToggle] = React.useState(null);
  // const [showToggleGeofence, setShowToggleGeofence] = React.useState(null);
  // const [tempCategories, setTempCategories] = React.useState([]);

  const handleToggleChange = event => {
    setShowLogs({ ...showLogs, [event.target.name]: event.target.checked });
  };

  // const handleToggleGeofences = event => {
  //   const { name, checked } = event.target;
  //   const catIdx = tempCategories.findIndex(tmp => tmp.label === name);
  //   let tempList = _.cloneDeep(tempCategories);
  //   tempList[catIdx].isShown = checked;
  //   setTempCategories(tempList);
  // };

  const labels = [
    {
      name: "Alerts",
      value: "alerts"
    },
    {
      name: "Notifications",
      value: "notifications"
    },
    {
      name: "Activity Logs",
      value: "activityLogs"
    }
  ];

  const open = Boolean(showToggle);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <Grid
        container
        item
        md={12}
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid
          container
          item
          md={6}
          alignItems="center"
          justifyContent="center"
          className={styles.headerInner}
        >
          <Grid container item direction="row" alignItems="center">
            <Typography variant="subtitle2" className={styles.headerTitle}>
              STOPS
            </Typography>
            {isMapView && (
              <MyLocationIcon
                onClick={() => handleRecenter()}
                className={`${styles.targetIcon} ${locationDisabled &&
                  styles.targetIconDisabled}`}
              />
            )}
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Typography variant="caption" className={styles.headerDate}>
              {/* {date
                ? ` ${moment(date.base).format("ddd")}, ${moment(
                    date.base
                  ).format("DD MMM YYYY")}`
                : "Tue, 26 May 2020"} */}
              {_.isEqual(
                moment(date.start).format("MMM DD YYYY"),
                moment(date.end).format("MMM DD YYYY")
              )
                ? moment(date?.start).format("MMMM DD, YYYY")
                : `${moment(date?.start).format("MMMM DD, YYYY")} - ${moment(
                    date?.end
                  ).format("MMMM DD, YYYY")}`}
            </Typography>
            {/* <CalendarTodayIcon
              className={styles.headerCalendar}
              // onClick={() => (isPrimary ? setDateOpen(true) : setOpen(true))}
              onClick={() => setDateOpen(true)}
            /> */}
            {/* {isPrimary && (
              <>
                <KeyboardArrowLeftIcon onClick={() => handleDate("down")} />
                <KeyboardArrowRightIcon onClick={() => handleDate("up")} />
              </>
            )} */}
            {/* <Typography
              variant="caption"
              className={styles.geofenceFilter}
              onClick={event => {
                setTempCategories(_.cloneDeep(categories))
                setShowToggleGeofence(event.currentTarget);
              }}
            >
              Geofence Filter
              <ArrowDropDownIcon
                style={{ fontSize: "20px", marginLeft: "3px" }}
              />
            </Typography> */}
            <IconButton
              style={{ cursor: "pointer" }}
              onClick={() => setOpenFilter(true)}
            >
              <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                <FilterListIcon />
              </Badge>
            </IconButton>
            {/* <Popover
              className={styles.geoPopover}
              open={showToggleGeofence}
              anchorEl={showToggleGeofence}
              onClose={() => setShowToggleGeofence(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="caption">All</Typography>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <Checkbox
                    className={styles.geoCheckbox}
                    color="primary"
                    checked={tempCategories.every(cat => cat.isShown)}
                    onChange={e => {
                      setTempCategories(prev =>
                        prev.map(cat => {
                          return { ...cat, isShown: e.target.checked };
                        })
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {tempCategories.map(cat => (
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="caption">{cat.label}</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right" }}>
                    <Checkbox
                      name={cat.label}
                      className={styles.geoCheckbox}
                      color="primary"
                      checked={cat.isShown}
                      onChange={handleToggleGeofences}
                    />
                  </Grid>
                </Grid>
              ))}
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  setCategories(tempCategories);
                  const logsValue = tempCategories.every(cat => cat.isShown)
                    ? "All"
                    : tempCategories
                        .filter(c => c.isShown)
                        .map(cat => cat.label)
                        .join(", ");

                  AddLogs(
                    process.env.REACT_APP_FLEET_MODULE,
                    "filter_historical",
                    logsValue
                  );
                }}
                className={styles.applyButton}
                disabled={_.isEqual(categories, tempCategories)}
              >
                Apply
              </Button>
            </Popover> */}
          </Grid>
        </Grid>
        <Grid container item md={6} alignItems="center" justifyContent="center">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              startIcon={isMapView ? <ListIcon /> : <MapIcon />}
              color="primary"
              variant="contained"
              size="small"
              className={styles.headerToggleButton}
              onClick={handleChangeView}
            >
              <Typography
                className={styles.toggleButtonLabel}
                variant="caption"
              >
                {isMapView ? "List View" : "Map View"}
              </Typography>
            </Button>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            className={styles.alertsToggle}
          >
            {!isMapView && (
              <>
                <Typography
                  color="primary"
                  className={styles.alertsToggleLabel}
                  variant="caption"
                  onClick={handleExpandAllAlerts}
                >
                  {expandAllAlerts
                    ? "Hide All Activities"
                    : "Show All Activities"}
                </Typography>
                <Typography
                  color="primary"
                  className={styles.alertsToggleLabel}
                  variant="caption"
                >
                  <ExpandMoreIcon
                    aria-describedby={id}
                    style={{ fontSize: "1rem", marginLeft: "0.5rem" }}
                    onClick={event => setShowToggle(event.currentTarget)}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={showToggle}
                    onClose={() => setShowToggle(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      {labels.map(label => (
                        <Grid container key={label}>
                          <Grid item xs={8}>
                            <Typography variant="caption">
                              {label.name}:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Switch
                              checked={showLogs[`${label.value}`]}
                              onChange={handleToggleChange}
                              name={label.value}
                              color="primary"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </Popover>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
    </React.Fragment>
  );
});

export default Header;
