import React from "react";
import { TablePagination, makeStyles } from "@material-ui/core";

const paginationStyles = makeStyles({
  root: {
    "& .MuiTablePagination-actions": {
      marginLeft: 0
    },
    "& .MuiTablePagination-actions button": {
      padding: 5
    }
  },
  selectRoot: {
    border: "1px solid #ccc",
    // padding: "2px 12px",
    background: "#F1F1F1",
    borderRadius: "24px",
    color: "#00A7F7",
    fontWeight: 600,
    margin: "0px 12px"
  },
  select: {
    borderRadius: "24px",
    padding: "8px 16px"
  },
  selectIcon: {
    color: "#00A7F7",
    fontWeight: 600
  },
  labelPrimary: {
    color: "#00A7F7",
    fontWeight: 600
  },
  labelSecondary: {
    color: "#b1b1b1"
  }
});

const Pagination = props => {
  const styles = paginationStyles();
  return (
    <TablePagination
      className={styles.root}
      classes={{
        selectRoot: styles.selectRoot,
        select: styles.select,
        selectIcon: styles.selectIcon
      }}
      labelRowsPerPage={
        <span className={styles.labelSecondary}>Rows per page:</span>
      }
      labelDisplayedRows={({ from, to, count }) => (
        <React.Fragment>
          <span className={styles.labelPrimary}>{`${from}-${to} `}</span>
          <span className={styles.labelSecondary}>{`of ${
            count !== -1 ? count : `more than ${to}`
          }`}</span>
        </React.Fragment>
      )}
      size="medium"
      component="div"
      rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
      {...props}
      // page={0}
      // onChangePage={onPageChange}
      // rowsPerPage={10}
      // onChangeRowsPerPage={() => console.log("rows per page changed")}
    />
  );
};

export default Pagination;
