/* global google */
import React, { useState } from "react";
import {
  Marker,
  Polygon,
  InfoWindow,
  TrafficLayer
} from "@react-google-maps/api";
import {
  Grid,
  Typography,
  Paper,
  Switch,
  makeStyles
} from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import Map from "../../../utils/Map";
import {
  control_positions,
  mapRef,
  center as DEFAULT_CENTER
} from "../../../utils/Map/Inner";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../assets/SvgIcons/SvgIcons";
import BlinkingAlertsLegend from "../../../utils/BlinkingAlertsLegend";
import { wktToCoordinates } from "../../../utils/functions/coordinatesParserV2";

const DEFAULT_ZOOM = 13;

const useStyles = makeStyles(() => ({
  rootFilter: {
    cursor: "pointer",
    display: "flex",
    padding: "4px 12px",
    minWidth: "120px",
    borderRadius: "10px",
    marginTop: "15px",
    marginRight: "15px"
  },
  filterText: {
    fontSize: "14px"
  },
  filterList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  mapContainer: {
    height: "calc(100vh - 255px)",
    width: "calc(75vw - 20px)"
  },
  filterPaper: {
    minWidth: "120px",
    borderRadius: "10px",
    padding: "8px 0px 8px 8px"
  }
}));

const VehicleMarker = ({ deviceData }) => {
  const {
    device_status: {
      powersource,
      heading,
      AlertReferences,
      latitude,
      longitude
    },
    vehicle_info: {
      icon,
      plateno,
      ignition_status
    }
  } = deviceData[0];

  const isLBSVehicle = AlertReferences?.some(
    alert => alert.alert_code === "LBS DATA"
  );

  const Icon =
    SvgIcons[
    isLBSVehicle
      ? "LBS_vehicle"
      : icon.split(".")[0]
        ? icon.split(".")[0]
        : "default_icon"
    ];

  return (
    <>
      <Marker
        position={{
          lat: latitude,
          lng: longitude
        }}
        options={{
          icon: {
            path: Icon.path,
            fillColor: isLBSVehicle ? Icon.fill : fillColor[powersource],
            fillOpacity: 1,
            strokeColor: isLBSVehicle ? Icon.stroke : strokeColor[ignition_status],
            strokeWeight: isLBSVehicle ? 1.0 : 1.5,
            scale: 1.2,
            rotation: isLBSVehicle ? 0 : heading,
            size: new window.google.maps.Size(22, 17),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(9, 13)
          }
        }}
      />
      <Marker
        options={{
          icon: {
            path: Icon.path,
            fillColor: fillColor[powersource],
            fillOpacity: 0,
            strokeColor: strokeColor[ignition_status],
            strokeWeight: 0,
            scale: 1.2,
            size: new window.google.maps.Size(22, 17),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(5, 9),
            labelOrigin: new window.google.maps.Point(8, 45)
          },
          zIndex: 214748364,
          label: {
            color: "#3A3A3A",
            fontWeight: "bold",
            text: plateno,
            fontSize: "12px",
            className: "map-label"
          }
        }}
        position={{
          lat: latitude,
          lng: longitude
        }}
      />
    </>
  );
};

const GeofenceLayer = ({ geofencesStops, parsedTripData, fitToScreen }) => {
  const [mapHover, setMapHover] = useState({
    id: "",
    isShow: false,
    name: "",
    position: {}
  });

  const toggleInfoWindow = geofence => {
    if (geofence.id !== mapHover.id) {
      setMapHover({
        id: geofence.id,
        isShow: true,
        name: `${geofence.geofence_code}-${geofence.name}`,
        position: {
          lat: geofence.location.lat,
          lng: geofence.location.lon
        }
      });
    }
  }

  return geofencesStops.map((geofence, index) => {
    const isPickup = +parsedTripData.pickups[0].geofence_id === +geofence.id;
    const iconUrl = `${process.env.PUBLIC_URL}/assets/Markers/${isPickup ? "Pickup" : "Dropoff 1"}.png`;
    const geofenceColor = isPickup ? "#8800A0" : "#0080FF";
    return <>
      <Polygon
        key={`${geofence.id}--${index}`}
        paths={wktToCoordinates(geofence.geom)}
        options={{
          fillColor: geofenceColor,
          fillOpacity: 0.5,
          strokeWeight: 2
        }}
        onClick={() => toggleInfoWindow(geofence)}
        onLoad={() => {
          if (index === 0) {
            fitToScreen();
          }
        }}
      />
      {mapHover.isShow && (
        <InfoWindow
          position={mapHover.position}
          onCloseClick={() => {
            setMapHover({
              ...mapHover,
              id: "",
              isShow: false
            });
          }}
        >
          <Grid
            container
            style={{
              backgroundColor: `white`,
              width: 250,
              color: "#646464"
            }}
          >
            <Typography variant="body2" gutterBottom>
              {mapHover.name}
            </Typography>
          </Grid>
        </InfoWindow>
      )}
      <Marker
        key={`${geofence.id}--${index}`}
        position={{
          lat: geofence.location.lat,
          lng: geofence.location.lon
        }}
        icon={{
          url: iconUrl,
          scaledSize: new google.maps.Size(32, 32)
        }}
        onClick={() => toggleInfoWindow(geofence)}
      />
    </>
  })
}

const CustomOverlay = props => {
  const {
    filters,
    setFilters
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const filterList = [
    // { name: "All Geofences", key: "geofences" },
    { name: "Stops", key: "stops" },
    { name: "Traffic Overlay", key: "traffic" },
    // { name: "Road Network", key: "road" }
  ];

  const handleFilter = () => {
    setExpanded(!expanded);
  };

  const handleFilterToggle = e => {
    const { checked, name } = e.target;
    setFilters({ ...filters, [name]: checked });
  };

  return (
    <div>
      <Paper className={classes.rootFilter} onClick={() => handleFilter()}>
        <FilterListIcon />
        <div style={{ flexGrow: 1 }} />
        <Typography>Map Filters</Typography>
      </Paper>
      {expanded && (
        <Paper className={classes.filterPaper}>
          {filterList.map(filter => (
            <div key={filter.key} className={classes.filterList}>
              <Typography className={classes.filterText}>
                {filter.name}
              </Typography>
              <div style={{ flexGrow: 1 }} />
              <Switch
                checked={filters[filter.key]}
                color="primary"
                onChange={handleFilterToggle}
                name={filter.key}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          ))}
        </Paper>
      )}
    </div>
  )
}

const BaseMap = ({
  parsedTripData,
  rawTripData: {
    geofences: geofencesStops,
    devices: deviceData
  }
}) => {
  const showVehicle = ![
    +process.env.REACT_APP_STATUS_TRIP_CLOSED,
    +process.env.REACT_APP_STATUS_CANCEL_ID,
    +process.env.REACT_APP_STATUS_COMPLETED
  ].includes(+parsedTripData.status_code_id);
  const classes = useStyles();
  const [filters, setFilters] = useState({
    // geofences: false,
    stops: true,
    traffic: false,
    // road: false
  })

  const fitToScreen = () => {
    if (geofencesStops.length) {
      const bounds = new window.google.maps.LatLngBounds();
      geofencesStops.forEach(geofence => {
        const paths = wktToCoordinates(geofence.geom);
        paths.forEach(path => {
          path.forEach(coor => bounds.extend(coor));
        });
      })
      const { device_status } = deviceData[0];
      if (device_status.latitude && device_status.longitude && showVehicle) {
        bounds.extend({
          lat: device_status.latitude,
          lng: device_status.longitude
        })
      }
      const extendPoint = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getNorthEast().lng() + 0.005);
      bounds.extend(extendPoint);
      mapRef.current.fitBounds(bounds);
    }
  }

  return (
    <div className={classes.mapContainer}>
      <Map
        zoom={DEFAULT_ZOOM}
        center={() => {
          const { device_status } = deviceData[0];
          if (device_status.latitude && device_status.longitude) {
            return {
              lat: device_status.latitude,
              lng: device_status.longitude
            }
          }
          return DEFAULT_CENTER;
        }}
        options={{
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          }
        }}
      >
        {parsedTripData.vehicle_id && showVehicle && (
          <VehicleMarker deviceData={deviceData} />
        )}
        {geofencesStops.length && filters.stops && (
          <GeofenceLayer
            fitToScreen={() => fitToScreen()}
            geofencesStops={geofencesStops}
            parsedTripData={{
              pickups: parsedTripData.pickups
            }}
          />
        )}
        <Map.Control position={control_positions.TOP_RIGHT}>
          <CustomOverlay
            filters={filters}
            setFilters={setFilters}
          />
        </Map.Control>
        <Map.Control position={control_positions.RIGHT_BOTTOM}>
          <BlinkingAlertsLegend margin={10} showOutlines />
        </Map.Control>
        {filters.traffic && <TrafficLayer autoUpdate />}
      </Map>
    </div>
  );
};

export default BaseMap;