import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { DateRange } from "@material-ui/icons";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#f49400",
      contrastText: "#ffffff"
    }
  },
  overrides: {
    MuiFormControl: {
      root: {
        cursor: "pointer",
        flex: 1,
        zIndex: 1,
        "& input": {
          cursor: "pointer",
          color: "#5e5e5e"
        },
        "&.Mui-disabled": {
          color: "#C4C4C4"
        },
        "& label": {
          color: "#3F3F3F"
        }
      }
    },
    MuiInput: {
      underline: {
        borderBottom: "1px solid #c4c4c4",
        "&::before, &::after": {
          borderBottom: "1px solid #c4c4c4"
        }
      },
      input: {
        "&.Mui-disabled": {
          color: "#C4C4C4"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#3F3F3F"
        }
      }
    },
    MuiPickersToolbar: {
      toolbarLandscape: {
        maxWidth: "none"
      }
    },
    MuiDialogActions: {
      root: {
        display: "none"
      }
    }
  }
});

const CustomDateRange = props => {
  const {
    disabled,
    selectedFromDate,
    handleFromDateChange,
    selectedToDate,
    handleToDateChange,
    handleBlur
  } = props;

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            disabled={disabled}
            disablePast={true}
            value={selectedFromDate}
            variant="dialog"
            format="MM/dd/yyyy"
            label="From"
            minDateMessage=""
            onChange={handleFromDateChange}
            onClose={handleBlur}
            orientation="landscape"
            emptyLabel="Select Date"
            autoOk
          />
          <DateRange
            style={{
              transform: "translate(-60px, 18px)",
              zIndex: -1,
              color: disabled ? "#C4C4C4" : "#5e5e5e"
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            disabled={disabled}
            disablePast={true}
            value={selectedToDate}
            variant="dialog"
            format="MM/dd/yyyy"
            label="To"
            onChange={handleToDateChange}
            onClose={handleBlur}
            minDate={selectedFromDate}
            minDateMessage=""
            orientation="landscape"
            emptyLabel="Select Date"
            autoOk
          />
          <DateRange
            style={{
              transform: "translate(-60px, 18px)",
              zIndex: -1,
              color: disabled ? "#C4C4C4" : "#5e5e5e"
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default CustomDateRange;
