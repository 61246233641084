/* eslint-disable no-console */
import { gql } from "@apollo/client";
import moment from "moment";
import { GET_BOOKINGS_PER_TRIP } from "../graphql/Queries";
import {
  // UPDATE_BOOKING,
  DISPATCH_MUTATION,
  ADD_BOOKING_MUTATION,
  UPDATE_SPLIT_BOOKING_MUTATION
} from "../graphql/Mutations";
import client from "../Client";
import createBookingsData from "../utils/parse";

/* 
// trip level
conditions:[{field:"status_code.keyword" value:"In Transit"}] 
conditions:[{field:"client_name.keyword" value:"Havi Dispatcher"}]
dateRange: { 
      field: "do_stamp",
      start:"2020-02-06 13:35:30",
      end:"2020-02-07 13:45:30"
    }
dropoffs: ["_yeSD3ABwkBKOSFjwjuW"]
pickups: ["_yeSD3ABwkBKOSFjwjuW"]
*/

export const GET_BOOKINGS_v2 = gql`
  query getbookingsv2(
    $skip: Int
    $rows_per_page: Int
    $date_range: DateRangeInputV2
    $keyword: String
    $pickups: [String]
    $dropoffs: [String]
    $conditions: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
    $group_ids: [Int]
    $trip_numbers: [String!]
    $not: [SearchInput]
    $orderBy: [OrderByInput]
    $or: [DynamicSearchInput]
    $is_inter_island: Boolean
  ) {
    get_bookings_per_trip(
      group_ids: $group_ids
      skip: $skip
      first: $rows_per_page
      orderBy: $orderBy
      dateRange: $date_range
      conditions: $conditions
      keyword: $keyword
      filter: $filter
      pickups: $pickups
      dropoffs: $dropoffs
      trip_numbers: $trip_numbers
      not: $not
      or: $or,
      is_inter_island: $is_inter_island
    ) {
      bookings {
        key
        value {
          id
          do_stamp
          data_complete
          do_number
          so_number
          out_of_route
          trip_number
          ref_number
          pre_dispatched_ready
          transport_mode
          transport_mode_id
          service_type
          service_type_id
          total_weight
          total_volume
          bill_to_id
          bill_to_name
          client_name
          client_id
          group_names
          ordinal
          is_inter_island
          is_urgent
          group_ids
          is_urgent
          is_split
          reason
          reason_id
          createdby {
            user_id
            username
            client_id
            client_name
          }
          notification_status
          dr_number
          remarks
          vehicle_id
          overdue
          vehicle_plate_no
          vehicle_status
          vehicle_type
          vehicle_type_id
          status_code
          status_code_id
          personnels {
            id
            name
          }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          items {
            pickup_index
            dropoff_index
            name
            qty
            dr_count
            volume
            weight
            uom_id
            uom
            sub_items {
              name
            }
            reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          dispatch_type
        }
      }
      count
      total
    }
  }
`;

export const GET_BOOKINGS_DOWNLOADABLE_TABLE_DATA = gql`
  query getbookingsv2(
    $skip: Int
    $rows_per_page: Int
    $date_range: DateRangeInputV2
    $keyword: String
    $pickups: [String]
    $dropoffs: [String]
    $conditions: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
    $group_ids: [Int]
    $trip_numbers: [String!]
    $not: [SearchInput]
    $orderBy: [OrderByInput]
    $or: [DynamicSearchInput]
    $is_inter_island: Boolean
  ) {
    get_bookings_per_trip(
      group_ids: $group_ids
      skip: $skip
      first: $rows_per_page
      orderBy: $orderBy
      dateRange: $date_range
      conditions: $conditions
      keyword: $keyword
      filter: $filter
      pickups: $pickups
      dropoffs: $dropoffs
      trip_numbers: $trip_numbers
      not: $not
      or: $or
      is_inter_island: $is_inter_island
    ) {
      bookings {
        # -key
        value {
          id
          # -do_stamp
          data_complete
          # -do_number
          # -so_number
          out_of_route
          trip_number
          # -ref_number
          pre_dispatched_ready
          # -transport_mode
          # -transport_mode_id
          service_type
          # -service_type_id
          # -total_weight
          # -total_volume
          # -bill_to_id
          # -bill_to_name
          client_name
          client_id
          # -group_names
          # -ordinal
          is_urgent
          # -group_ids
          is_urgent
          is_split
          # -reason
          # -reason_id
          createdby {
            # -user_id
            # -username
            client_id
            client_name
          }
          # -notification_status
          # -dr_number
          # -remarks
          vehicle_id
          overdue
          vehicle_plate_no
          vehicle_status
          # -vehicle_type
          # -vehicle_type_id
          status_code
          status_code_id
          # -personnels {
          #   id
          #   name
          # }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          items {
            # -pickup_index
            dropoff_index
            # -name
            # -qty
            # -dr_count
            # -volume
            # -weight
            # -uom_id
            # -uom
            # -sub_items {
            #   -name
            # }
            # -reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          dispatch_type
          is_inter_island
        }
      }
      # -count
      total
    }
  }
`;

export const GET_BOOKINGS = gql`
  query getbookings(
    $skip: Int
    $rows_per_page: Int
    $date_range: DateRangeInputV2
    $keyword: String
    $pickups: [String]
    $dropoffs: [String]
    $conditions: [DynamicSearchInput]
    $filter: [DynamicSearchInput]
    $group_ids: [Int]
    $trip_numbers: [String!]
    $not: [SearchInput]
  ) {
    get_bookings_with_ongoing_per_trip(
      group_ids: $group_ids
      skip: $skip
      first: $rows_per_page
      orderBy: [{ field: "ordinal", direction: "asc" }, { field: "pickups.arrival", direction: "asc" }, { field: "dropoffs.arrival", direction: "asc" }]
      dateRange: $date_range
      conditions: $conditions
      keyword: $keyword
      filter: $filter
      pickups: $pickups
      dropoffs: $dropoffs
      trip_numbers: $trip_numbers
      not: $not
    ) {
      bookings {
        key
        value {
          is_late
          id
          do_stamp
          data_complete
          do_number
          so_number
          out_of_route
          trip_number
          ref_number
          transport_mode
          transport_mode_id
          service_type
          service_type_id
          total_weight
          total_volume
          bill_to_id
          bill_to_name
          client_name
          client_id
          group_names
          ordinal
          is_urgent
          group_ids
          is_urgent
          is_split
          reason
          reason_id
          createdby {
            user_id
            username
            client_id
            client_name
          }
          notification_status
          dr_number
          remarks
          vehicle_id
          vehicle_plate_no
          vehicle_status
          vehicle_type
          vehicle_type_id
          status_code
          status_code_id
          personnels {
            id
            name
          }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          items {
            pickup_index
            dropoff_index
            name
            qty
            dr_count
            volume
            weight
            uom_id
            uom
            sub_items {
              name
            }
            reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          dispatch_type
        }
      }
      count
      total
    }
  }
`;

export const GET_DISPATCH_BOOKINGS = gql`
  query getbookings(
    $skip: Int
    $rows_per_page: Int
    $conditions: [DynamicSearchInput]
    $not: [SearchInput]
  ) {
    get_bookings_with_ongoing_per_trip(
      skip: $skip
      first: $rows_per_page
      orderBy: { field: "ordinal", direction: "asc" }
      conditions: $conditions
      not: $not
    ) {
      bookings {
        key
        value {
          id
          trip_number
          ref_number
          client_name
          client_id
          group_names
          group_ids
          vehicle_plate_no
          vehicle_type
          personnels {
            id
            name
          }
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
            is_late
          }
          items {
            pickup_index
            dropoff_index
            name
            qty
            dr_count
            volume
            weight
            uom_id
            uom
            sub_items {
              name
            }
            reference_number
          }
        }
      }
    }
  }
`;

export const GET_STATUS_CODES = gql`
  query get_status_codes {
    get_status_codes(
      filter: [
        {
          field: "name"
          value: [
            "Arrived at Pickup"
            "Arrived at Drop off"
            "In Transit"
            "Completed"
            "Incoming"
            "Closed"
          ]
        }
      ]
    ) {
      id
      name
      description
    }
  }
`;

export const DISPATCH_BOOKING = (booking_id, pickups) => {
  /* 2020-02-05 13:00:00 */
  const data = [...pickups];
  const now = moment().format("YYYY-MM-DD HH:mm:ss");
  return new Promise(resolve => {
    data.forEach((el, index) => {
      // change manual_departire
      data[index].manual_departure = now;
      el.forEach((e, i) => {
        data[index][i].manual_departure = now;
      });
    });
    if (typeof booking_id === "string") {
      client
        .mutate({
          mutation: DISPATCH_MUTATION,
          variables: {
            id: booking_id,
            status_code: "In Transit",
            status_code_id: "20",
            pickups: data
            // manual_departure: now
          }
        })
        .then(result => {
          // console.log(result);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (Array.isArray(booking_id)) {
      booking_id.forEach((booking, index) => {
        client
          .mutate({
            mutation: DISPATCH_MUTATION,
            variables: {
              id: booking,
              status_code: "In Transit",
              status_code_id: "20",
              pickups: data[index]
              // manual_departure: now
            }
          })
          .then(result => {
            // console.log(result);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
    resolve("success");
  });
};

export const ADD_BOOKING = data => {
  const { id, ...res } = data;
  client
    .mutate({
      mutation: ADD_BOOKING_MUTATION,
      variables: res
    })
    .then(result => {
      // console.log(result);
    })
    .catch(err => console.log(err));
};

export const UPDATE_SPLIT_BOOKING = data => {
  // const { id, ...res } = data;
  return client
    .mutate({
      mutation: UPDATE_SPLIT_BOOKING_MUTATION,
      variables: data
    })
    .then(() => {
      // console.log(result);
      return {
        status: "success",
        data: data
      };
    })
    .catch(err => {
      console.log(err);
      return {
        status: "error",
        data: data
      };
    });
};

export const getBooking = (trip_number, group_ids = []) => {
  const tripList = [];
  const start = "1970-01-01 00:00:00";
  const end = "2100-01-01 23:59:59";
  const variables = {
    ...(group_ids?.length ? { group_ids } : {}),
    skip: 0,
    first: 100,
    orderBy: { field: "do_stamp", direction: "asc" },
    date_range: {
      field: "do_stamp",
      start: start,
      end: end
    },
    conditions: [
      {
        field: "trip_number",
        value: trip_number
      }
    ]
  };
  return new Promise(resolve => {
    client
      .query({
        // query: GET_BOOKINGS,
        query: GET_BOOKINGS_PER_TRIP,
        variables: variables
      })
      .then(data => {
        const { bookings: results } = data.data.get_bookings_per_trip;
        // const {
        //   bookings: results
        // } = data.data.get_bookings_with_ongoing_per_trip;
        const response = {
          rawTripData: results
        };
        const geofences = [];
        results.map(result => {
          const dropoffArray = [];
          let tripStatus = "";
          const tripStatusArray = [];
          const trip = result.value.map(stop => {
            stop.pickups.forEach(p => {
              geofences.push(p.name);
              geofences.push(`${p.geofence_code} - ${p.name}`);
            });
            stop.dropoffs.forEach(d => {
              geofences.push(d.name);
              geofences.push(`${d.geofence_code} - ${d.name}`);
            });
            // stopIndex
            tripStatusArray.push(stop.status_code);
            // const dropoffs =
            stop.dropoffs.map((do_el, do_i) => {
              const items = stop.items
                ? stop.items.filter(item => item.dropoff_index === do_i)
                : null;
              const status =
                do_el.status_code === "Accepted" ||
                do_el.status_code === "Scheduled"
                  ? "Processing"
                  : do_el.status_code;
              const json = {
                geofence_id: do_el.geofence_id,
                so_number: do_el.so_number,
                arrival: do_el.arrival,
                name: do_el.name,
                eta: do_el.eta,
                actual_eta: do_el.actual_eta,
                eta_modified: do_el.eta_modified,
                status_code: status,
                status_code_id: do_el.status_code_id,
                items,
                address: do_el.address,
                actual_arrival: do_el.actual_arrival,
                manual_arrival: do_el.manual_arrival,
                departure: do_el.departure,
                actual_departure: do_el.actual_departure,
                manual_departure: do_el.manual_departure,
                consignee: do_el.consignee,
                contact_no: do_el.contact_no,
                location_details: do_el.location_details,
                issue: do_el.issue
              };
              dropoffArray.push(json);
              return json;
            });
            if (tripStatusArray.indexOf("Arrived at Pickup") >= 0) {
              tripStatus = "Arrived at Pickup";
            } else if (tripStatusArray.indexOf("Arrived at Drop off") >= 0) {
              tripStatus = "Arrived at Drop off";
            } else if (tripStatusArray.indexOf("In Transit") >= 0) {
              tripStatus = "In transit";
            } else if (tripStatusArray.indexOf("Scheduled") >= 0) {
              tripStatus = "Processing";
            } else if (tripStatusArray.indexOf("Accepted") >= 0) {
              tripStatus = "Processing";
            } else if (tripStatusArray.indexOf("Completed") >= 0) {
              tripStatus = "Completed";
            }
            const personnels = stop.personnels ? stop.personnels[0].name : null;
            const status_code =
              tripStatus === "Accepted" || tripStatus === "Scheduled"
                ? "Processing"
                : tripStatus;
            return createBookingsData(
              stop.id,
              stop.pickups[0].arrival,
              stop.trip_number,
              stop.ref_number,
              stop.pickups[0].name,
              // dropoffs,
              dropoffArray,
              stop.client_name,
              stop.vehicle_plate_no,
              status_code,
              stop.vehicle_type,
              personnels,
              stop.pickups,
              stop.dispatch_time
            );
          });
          tripList.push(trip[0]);
          return true;
        });
        response.tripList = tripList;
        response.tripGeofences = geofences;
        resolve(response);
        /* TRIPLIST */
      });
  });
};

// Function used to getBooking details via vehicle and trip number input
export const getBookingViaVehicle = (vehicle_id, trip_number) => {
  const tripList = [];
  const variables = {
    skip: 0,
    rows_per_page: 100,
    orderBy: { field: "do_stamp", direction: "asc" },
    conditions: [
      {
        field: "vehicle_id",
        value: vehicle_id
      }
    ],
    not: [
      { field: "status_code", value: "Completed" },
      { field: "status_code", value: "Closed" },
      { field: "status_code", value: "Cancelled" },
      { field: "status_code", value: "Manually Completed" }
    ]
  };
  return new Promise(resolve => {
    client
      .query({
        // query: GET_BOOKINGS,
        query: GET_DISPATCH_BOOKINGS,
        variables: variables
      })
      .then(data => {
        const {
          bookings: results
        } = data.data.get_bookings_with_ongoing_per_trip;

        //  filter the results if the results key is equal to given trip number
        const toDispatch = results.filter(res => res.key === trip_number);

        // filter the results array based on trip status code id
        const dispatchedTrips = results.filter(
          res =>
            res.value[0].status_code_id >
              process.env.REACT_APP_STATUS_TRIP_ACCEPTED &&
            res.value[0].status_code_id !==
              process.env.REACT_APP_STATUS_TRIP_COMPLETED &&
            res.value[0].status_code_id !==
              process.env.REACT_APP_STATUS_TRIP_MANUAL_COMPLETE &&
            res.value[0].status_code_id !==
              process.env.REACT_APP_STATUS_TRIP_CLOSED &&
            res.key !== trip_number
        );

        // set value for response array
        const response = {
          rawTripData: dispatchedTrips,
          toDispatchData: toDispatch
        };

        // map the results array
        results.map(result => {
          const dropoffArray = [];
          let tripStatus = "";
          const tripStatusArray = [];
          const trip = result.value.map(stop => {
            tripStatusArray.push(stop.status_code);
            stop.dropoffs.map((do_el, do_i) => {
              const items = stop.items
                ? stop.items.filter(item => item.dropoff_index === do_i)
                : null;
              const status =
                do_el.status_code === "Accepted" ||
                do_el.status_code === "Scheduled"
                  ? "Processing"
                  : do_el.status_code;
              const json = {
                geofence_id: do_el.geofence_id,
                so_number: do_el.so_number,
                arrival: do_el.arrival,
                name: do_el.name,
                eta: do_el.eta,
                actual_eta: do_el.actual_eta,
                eta_modified: do_el.eta_modified,
                status_code: status,
                status_code_id: do_el.status_code_id,
                items,
                address: do_el.address,
                actual_arrival: do_el.actual_arrival,
                manual_arrival: do_el.manual_arrival,
                departure: do_el.departure,
                actual_departure: do_el.actual_departure,
                manual_departure: do_el.manual_departure,
                consignee: do_el.consignee,
                contact_no: do_el.contact_no,
                location_details: do_el.location_details,
                issue: do_el.issue
              };
              dropoffArray.push(json);
              return json;
            });
            if (tripStatusArray.indexOf("Arrived at Pickup") >= 0) {
              tripStatus = "Arrived at Pickup";
            } else if (tripStatusArray.indexOf("Arrived at Drop off") >= 0) {
              tripStatus = "Arrived at Drop off";
            } else if (tripStatusArray.indexOf("In Transit") >= 0) {
              tripStatus = "In transit";
            } else if (tripStatusArray.indexOf("Scheduled") >= 0) {
              tripStatus = "Processing";
            } else if (tripStatusArray.indexOf("Accepted") >= 0) {
              tripStatus = "Processing";
            } else if (tripStatusArray.indexOf("Completed") >= 0) {
              tripStatus = "Completed";
            }
            const personnels = stop.personnels ? stop.personnels[0].name : null;
            const status_code =
              tripStatus === "Accepted" || tripStatus === "Scheduled"
                ? "Processing"
                : tripStatus;
            return createBookingsData(
              stop.id,
              stop.pickups[0].arrival,
              stop.trip_number,
              stop.ref_number,
              stop.pickups[0].name,
              dropoffArray,
              stop.client_name,
              stop.vehicle_plate_no,
              status_code,
              stop.vehicle_type,
              personnels,
              stop.pickups
            );
          });
          tripList.push(trip[0]);
          return true;
        });
        response.tripList = tripList;
        resolve(response);
        /* TRIPLIST */
      });
  });
};

export const GET_DISPATCH_BOOKINGS_V2 = gql`
  query getbookingsv2(
    $trip_numbers: [String!]
  ) {
    get_bookings_per_trip(
      trip_numbers: $trip_numbers
    ) {
      bookings {
        key
        value {
          id
          client_id
          trip_number
          group_names
          group_ids
          createdby {
            username
            client_id
          }
          vehicle_plate_no
          vehicle_status
          dropoffs {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          pickups {
            geofence_id
            name
            address
            arrival
            actual_arrival
            manual_arrival
            departure
            actual_departure
            manual_departure
            consignee
            contact_no
            location_details
            so_number
            issue
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
        }
      }
    }
  }
`;


export const GET_TABLEDATA_BOOKINGS_V2 = gql`
  query getbookingsv2(
    $skip: Int
    $rows_per_page: Int
    $keyword: String
    $conditions: [DynamicSearchInput]
    $trip_numbers: [String!]
    $not: [SearchInput]
    $orderBy: [OrderByInput]
    $or: [DynamicSearchInput]
    $date_range: DateRangeInputV2
    $pickups: [String]
    $dropoffs: [String]
    $is_inter_island: Boolean
  ) {
    get_bookings_per_trip(
      skip: $skip
      first: $rows_per_page
      orderBy: $orderBy
      conditions: $conditions
      keyword: $keyword
      trip_numbers: $trip_numbers
      not: $not
      or: $or
      dateRange: $date_range
      pickups: $pickups
      dropoffs: $dropoffs
      is_inter_island: $is_inter_island
    ) {
      bookings {
        key
        value {
          id
          data_complete
          so_number
          out_of_route
          trip_number
          ref_number
          pre_dispatched_ready
          service_type
          client_name
          client_id
          group_names
          ordinal
          is_urgent
          group_ids
          is_split
          dr_number
          remarks
          vehicle_id
          overdue
          vehicle_plate_no
          vehicle_status
          vehicle_type
          status_code
          status_code_id
          personnels {
            name
          }
          dropoffs {
            geofence_id
            name
            arrival
            actual_arrival
            manual_arrival
            location_details
            so_number
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          pickups {
            geofence_id
            name
            arrival
            actual_arrival
            manual_arrival
            location_details
            so_number
            eta
            actual_eta
            eta_modified
            status_code
            status_code_id
            geofence_code
          }
          items {
            dropoff_index
            name
            qty
            dr_count
            volume
            uom
            reference_number
          }
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
          dispatch_time
          dispatch_type
          is_inter_island
        }
      }
      count
      total
    }
  }
`;

export const GET_CLOSED_BOOKINGS_v2 = gql`
  query getbookingsv2(
    $trip_numbers: [String!]
  ) {
    get_bookings_per_trip(
      trip_numbers: $trip_numbers
    ) {
      bookings {
        key
        value {
          id
          trip_number
          client_id
          group_names
          group_ids
          vehicle_plate_no
        }
      }
    }
  }
`;

export const GET_BOOKING_LIST_V2 = gql`
  query getbookingsv2(
    $trip_numbers: [String!]
    $orderBy: [OrderByInput]
    $first: Int
  ) {
    get_bookings_per_trip(
      orderBy: $orderBy
      trip_numbers: $trip_numbers
      first: $first
    ) {
      bookings {
        key
        value {
          id
          status_code
          status_code_id
          so_number
          ref_number
          vehicle_history {
            vehicle_id
            vehicle_plate_no
            user_id
            user_full_name
            datestamp
          }
        }
      }
    }
  }
`;
