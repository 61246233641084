import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import AddLogs from "../../../../utils/functions/AddLogs";
import { CSVLink } from "react-csv";

const DownloadMenu = React.memo(props => {
    const {
      anchorEl,
      open,
      handleClosePopper,
      handleListKeyDown,
      tableData,
    //   tempFilter
    } = props;
  
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
            zIndex: 3
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Working Hours", "download_template", "")
                    }
                  >
                    <Link
                      to="/assets/working_hours_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black", fontSize: 11.5 }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Working Hours", "download_table", "")
                    }
                  >
                    <CSVLink
                      data={tableData}
                      style={{ textDecoration: "none", color: "black", fontSize: 11.5 }}
                    >
                      Download Table
                    </CSVLink>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  });

  export default DownloadMenu;