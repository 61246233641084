/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import ChipInput from "./ChipInput/ChipInput";

export const gpsStatusOptions = [
  // { id: 0, label: "All" },
  { id: +process.env.REACT_APP_STATUS_ONLINE, label: "Online" },
  { id: +process.env.REACT_APP_STATUS_OFFLINE, label: "Offline" },
  {
    id: +process.env.REACT_APP_STATUS_DELAY_REPORTING,
    label: "Delayed Reporting"
  },
  { id: +process.env.REACT_APP_STATUS_NO_GPS, label: "No GPS" }
];

export const defaultValue = [];

const parsedValues = (values = []) => {
  const refValues = values.map(({ id }) => id);
  return refValues.length ? refValues : defaultValue;
};

export const valuesToSting = (arr = []) => {
  if (arr.length === 0) return "All";
  if (arr.length === gpsStatusOptions.length) return "All";
  return gpsStatusOptions
    .reduce((acc, { id, label }) => {
      if (arr.includes(id)) return [...acc, label];
      return acc;
    }, [])
    .join(", ");
};

const GPSStatus = React.forwardRef((props, ref) => {
  const { initialValues = [], readOnly = false } = props;
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    // This side effect runs every time 'initialValues' changed
    const value = gpsStatusOptions.filter(option =>
      initialValues.includes(option.id)
    );
    // eslint-disable-next-line no-param-reassign
    ref.current = parsedValues(value);
    setSelectedStatus(value);

    return () => {
      // eslint-disable-next-line no-param-reassign
      ref.current = parsedValues(value);
    };
  }, [initialValues.length]);

  const handleSelectedChange = changes => {
    const newSelectedValues = [...changes.selectedValues];
    ref.current = parsedValues(newSelectedValues);
    setSelectedStatus(newSelectedValues);
  };

  const autocompleteProps = {
    // 💡 Override Autocomplete props
    renderInput: ({ getTextfieldProps }) => ({
      ...getTextfieldProps({
        label: "GPS Status",
        placeholder: selectedStatus?.length > 0 ? "" : "All",
        InputProps: {
          disableUnderline: readOnly,
          disabled: readOnly
        }
      })
    }),
    disabled: readOnly
  };

  return (
    <ChipInput
      selectedValues={selectedStatus}
      onSelectedChanged={handleSelectedChange}
      options={gpsStatusOptions}
      autocompleteProps={autocompleteProps}
    />
  );
});

export default GPSStatus;
