/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Chip,
  TextField,
  Checkbox,
  makeStyles,
  FormControl,
  Typography,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useQuery } from "@apollo/client";
import debounce from "../../../utils/functions/debounce.util";

// Queries
import { GET_VEHICLE_INFOS } from "../../../graphql/Queries";

// Styles
import useFormStyles from "../../../styles/useFormStyles";

const useChipInputStyle = makeStyles({
  rootInput: {
    // width: "100"
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    },
    "& ::placeholder": {
      /* Firefox */
      color: "#000 !important",
      opacity: 1
    },
    "& :-ms-input-placeholder": {
      /* Internet Explorer 10-11 */
      color: "#000 !important",
      opacity: 1
    },
    "& ::-ms-input-placeholder": {
      /* Microsoft Edge */
      color: "#000 !important",
      opacity: 1
    }
  },
  iconStyle: { width: 16, height: 16 }
});

const VehicleChipInput = React.forwardRef((props, ref) => {
  const { initialValues = [], readOnly, group_ids, selectedReportType } = props;
  const inConvanReport =
    selectedReportType === process.env.REACT_APP_REPORT_CONVAN_INVENTORY;

  const [selectedVehicles, setSelectedVehicles] = useState(initialValues);

  const { data, loading, refetch } = useQuery(GET_VEHICLE_INFOS, {
    variables: {
      first: 15,
      not: [{ field: "devices.id", value: "0" }],
      condition: inConvanReport
        ? [{ field: "vehicle_types.name", value: "Container Van" }]
        : [],
      group_ids: group_ids
    }
  });

  const onKeywordChanged = event => {
    refetch({
      first: 15,
      not: [{ field: "devices.id", value: "0" }],
      condition: inConvanReport
        ? [{ field: "vehicle_types.name", value: "Container Van" }]
        : [],
      group_ids: group_ids,
      keyword: event.target.value
    });
  };

  const debouncedKeywordChanged = debounce(onKeywordChanged, 250);

  const onOptionsClosed = () => {
    refetch({
      first: 15,
      not: [{ field: "devices.id", value: "0" }],
      condition: inConvanReport
        ? [{ field: "vehicle_types.name", value: "Container Van" }]
        : [],
      group_ids: group_ids
    });
  };

  useEffect(() => {
    setSelectedVehicles(initialValues);
    ref.current = initialValues.length ? initialValues : [0];

    return () => {
      ref.current = [0];
    };
  }, [group_ids.length]);

  // useEffect(() => {
  //   if (data && !loading && selectedVehicles.length > 0) {
  //     /** This function will check if selected vehicles are owned by the selected hauler
  //      *  ref.current and selectedVehicles possible values:
  //      *  1. [] - no selected vehicle
  //      *  2. [0] - initial value, means ALL
  //      *  3. [{...}] - with selected vehicle/s
  //      */

  //     // Initialize vehicle value (for checking with plate number options)
  //     let testVehicles = [];
  //     if (selectedVehicles[0] !== 0) {
  //       // If with selected vehicles, set testVehicles to currently selected vehicle
  //       testVehicles = selectedVehicles.map(v => v.device_id);
  //       // testVehicles = [];
  //     } else if (initialValues) {
  //       // If no selected vehicle but with initial value (for automated reports only), set testVehicles to initialValues
  //       testVehicles = initialValues;
  //     }

  //     // Get vehicle info from updated plate number options
  //     const initVal = data.get_vehicles.vehicles.filter(val =>
  //       testVehicles.includes(val.device_id)
  //     );

  //     if (initVal && initVal.length <= 20 && initVal.length > 0) {
  //       // limited to 20 chips only
  //       // If selected vehicles exist and are less than 20, update states
  //       ref.current = initVal;
  //       setSelectedVehicles(initVal);
  //     } else {
  //       // If selected vehicles do not exist, set ref to all ([0]) and empty selected vehicles
  //       ref.current = [0];
  //       setSelectedVehicles([]);
  //     }
  //   }
  // }, [data, group_ids]);

  let vehiclesOptions = [];
  if (data) {
    vehiclesOptions = [...data.get_vehicles.vehicles, 0];
  }

  const formStyles = useFormStyles();
  const chipInputStyles = useChipInputStyle();

  const handleSelectedChange = (_, newSelectedVehicles) => {
    ref.current = newSelectedVehicles.length === 0 ? [0] : newSelectedVehicles;
    setSelectedVehicles(newSelectedVehicles);
  };

  return (
    <FormControl className={formStyles.formRoot}>
      <Autocomplete
        loading={!data || loading}
        multiple
        limitTags={2}
        id="vehicle-chip-input"
        options={vehiclesOptions}
        disableCloseOnSelect
        disableClearable
        disabled={readOnly}
        onChange={handleSelectedChange}
        onClose={onOptionsClosed}
        value={selectedVehicles}
        getOptionSelected={(o, v) => o.id === v.id}
        // defaultValue={vehiclesOptions}
        filterOptions={option => {
          return option.filter(opt => opt !== 0);
        }}
        getOptionLabel={option => option.plateno?.toString() || ""}
        getOptionDisabled={option =>
          selectedVehicles.length >= 20 && !selectedVehicles.includes(option)
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            if (option === 0) return null;
            return (
              <Chip
                {...getTagProps({ index })}
                size="small"
                color="primary"
                // variant="outlined"
                label={option.plateno}
                // onDelete={() => onRemove([], option)}
              />
            );
          })
        }
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              color="primary"
              icon={
                <CheckBoxOutlineBlankIcon
                  className={chipInputStyles.iconStyle}
                />
              }
              checkedIcon={
                <CheckBoxIcon className={chipInputStyles.iconStyle} />
              }
              checked={selected}
              disabled={!selected && selectedVehicles.length >= 20}
            />
            <Typography variant="body2">{option.plateno}</Typography>
          </React.Fragment>
        )}
        renderInput={params => (
          <Tooltip title="Max of 20 plate numbers only">
            <TextField
              {...params}
              classes={{ root: formStyles.textFieldRoot }}
              className={chipInputStyles.rootInput}
              // variant="outlined"
              onChange={debouncedKeywordChanged}
              label="Plate Number"
              placeholder={
                loading
                  ? "Loading..."
                  : selectedVehicles.length > 0
                  ? ""
                  : "All"
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                style: {
                  padding: "6px 0px"
                },
                ...params.InputProps,
                disableUnderline: readOnly,
                disabled: readOnly,
                endAdornment:
                  !data || loading ? <CircularProgress size={12} /> : ""
              }}
            />
          </Tooltip>
        )}
      />
    </FormControl>
  );
});

export default VehicleChipInput;
