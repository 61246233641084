import React, { memo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { OpenInNew as OpenInNewIcon, AccessTimeRounded as AccessTimeRoundedIcon } from "@material-ui/icons";
import { Typography, IconButton, Box } from "@material-ui/core";
import { useStyles } from "./FleetStyle";

const NavFleet = ({ title, url, newTab }) => {
  const classes = useStyles();
  // function for new tab on dashboard.
  const newTabHandler = () => {
    return localStorage.setItem(
      "dashboard",
      JSON.stringify({ url: [{ link: url }], title: title })
    );
  };

  return (
    <>
      <div className={classes.navbar}>
        <div className={classes.navbar_box}>
          {!newTab && (
            <IconButton className={classes.header_icon}>
              <Link
                to={`/dashboard/view/${title}`}
                target="_blank"
                style={{ color: "#3F3F3F", height: "23px" }}
                onClick={newTabHandler}
              >
                <OpenInNewIcon fontSize="small" />
              </Link>
            </IconButton>
          )}
          <Typography variant="h6" className={classes.header_title}>
            {title}
          </Typography>
        </div>
        {newTab && (
          <Box
            display="flex"
            gridGap={10}
            alignItems="center"
            className={classes.date_time_box}
          >
            <AccessTimeRoundedIcon className={classes.time__icon} />
            <Typography className={classes.header__current_date_time}>
              As of {moment().format("LLL")}
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

export default memo(NavFleet);
