import Cookie from "js-cookie";
import { useQuery } from "@apollo/client";
import { ALLOWED_PERMISSIONS } from "../../graphql/Queries";
import useUserContext from "../../context/User/useUserContext";

const default_permissions = {
  edit: false,
  view: false
};
const useAllowedPermissions = resource => {
  // const user = JSON.parse(Cookie.get("user"));

  // const { loading, data } = useQuery(ALLOWED_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id,
  //     resource
  //   }
  // });

  const user_context = useUserContext();
  return {
    loading: false,
    permissions:
      user_context?.allowed_permissions[resource] || default_permissions
  };
};

export default useAllowedPermissions;
