import React, { memo, Fragment } from "react";
import {
  Button,
  Snackbar,
  IconButton,
  makeStyles,
  SnackbarContent
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
/*
    🚩 Alert Component. 🚩
    -> assign the following state for alert component.. 
    ✅ open: false || true 
    ✅ message: depends on the user what message will be shown upon alert. 
    ✅ type: '' || success 
  */

const useStyles = makeStyles(theme => ({
  snackBarContent: {
    backgroundColor: `${theme.palette.primary} !important`
  }
}));

const AlertComponent = props => {
  const classes = useStyles();
  const { message, alignment, isOpen, type, undoFn, closeFn } = props;

  return (
    <Snackbar
      anchorOrigin={alignment}
      open={isOpen}
      message={message}
      severity="success"
      ContentProps={{
        classes: {
          root: classes.root
        }
      }}
    >
      <SnackbarContent
        message={message}
        action={
          <Fragment>
            {type === "error" && (
              <Button color="secondary" size="small" onClick={undoFn}>
                UNDO
              </Button>
            )}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeFn}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
        className={classes.snackBarContent}
      />
    </Snackbar>
  );
};

export default memo(AlertComponent);
