/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  alpha,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LinkOff as LinkOffIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import lodash from "lodash";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../../../../utils/Loading";

import { ADMIN_LINK, ADMIN_UNLINK } from "../../../../../graphql/Mutations";
import {
  GET_ADMIN_VEHICLES,
  GET_ADMIN_VEHICLE_TYPES
} from "../../../../../graphql/Queries";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { gpsStatus } from "../../../../Utils";
import { VIEW_CLIENT_VEHICLES_SUBSCRIPTION } from "../../../../../graphql/Subscription";
import AddLogs from "../../../../../utils/functions/AddLogs";
import ClientAutocomplete from "../../../../../utils/ClientAutocomplete";
import { add } from "date-fns/esm";

// FOR SORTING
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// LIST VEHICLES HEADERS
const headCells = [
  {
    id: "client_name",
    numeric: false,
    disablePadding: true,
    label: "Client"
  },
  {
    id: "plateno",
    numeric: true,
    disablePadding: false,
    label: "Plate Number"
  },
  {
    id: "vehicle_type",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type"
  },
  {
    id: "devicealias",
    numeric: true,
    disablePadding: false,
    label: "Paired Device"
  },
  {
    id: "status_code",
    numeric: true,
    disablePadding: false,
    label: "GPS Status"
  }
];

// ADD ENTRY TABLE VEHICLE HEADERS
const headAddEntryCells = [
  {
    id: "client_name",
    numeric: false,
    disablePadding: true,
    label: "Client"
  },
  { id: "plateno", numeric: true, disablePadding: false, label: "Plate Number" }
];

// TABLE COLUMN HEADERS FOR LIST VEHICLES
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    handleSort,
    editMode,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {editMode && (
            <Checkbox
              color="primary"
              // disabled={!editMode}
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all vehicles" }}
            />
          )}
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

// TABLE COLUMN HEADERS FOR ADD ENTRY VEHICLES
function EnhancedTableHeadAddEntry(props) {
  const {
    classes,
    addEntryOrder,
    addEntryOrderBy,
    selectAllEntries,
    sortEntries,
    // numSelected,
    // rowCount,
    editMode,
    isEntriesChecked
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!editMode}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isEntriesChecked().indeterminate}
            checked={isEntriesChecked().all}
            onChange={selectAllEntries}
            inputProps={{ "aria-label": "select all vehicles" }}
          />
        </TableCell>
        {headAddEntryCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={
              addEntryOrderBy === headCell.id ? addEntryOrder : false
            }
          >
            <TableSortLabel
              active={addEntryOrderBy === headCell.id}
              direction={
                addEntryOrderBy === headCell.id ? addEntryOrder : "asc"
              }
              onClick={() => sortEntries(headCell.id)}
            >
              {headCell.label}
              {addEntryOrderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {addEntryOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadAddEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  selectAllEntries: PropTypes.func.isRequired,
  addEntryOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  addEntryOrderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

// TOOLBAR STYLES
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_download: {
    background: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn_upload: {
    background: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background:theme.palette.primary.main
    }
  }
}));

// TOOLBAR ABOVE TABLE
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");
  const {
    editMode,
    numSelected,
    numberOfRows,
    rowsPerPage,
    page,
    handleUpPage,
    handleDownPage,
    handlePagination,
    // maxDevices,
    totalRows,
    handleOpenUnlink,
    setVehicleKeyword,
    setPage
  } = props;

  return (
    <div>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} id="tableTitle" component="div">
          Vehicles: {totalRows}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #D2D2D2",
              boxSizing: "border-box",
              borderRadius: 23,
              padding: "4px 10px 3px 10px",
              marginRight: 20
            }}
          >
            <InputBase
              placeholder="Search…"
              style={{ width: 300 }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                setKeyword(e.target.value);
                // if (e.target.value.length > 0) {
                //   setVehicleKeyword(e.target.value);
                // } else if (e.target.value.length === 0) {
                //   setVehicleKeyword(e.target.value);
                // }
              }}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setVehicleKeyword(keyword);
                  setPage(0);
                }
              }}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <Typography>Rows per page:</Typography>
          <FormControl className={classes.field}>
            <Select
              value={rowsPerPage}
              onChange={e => {
                handlePagination(e);
              }}
              displayEmpty
              name="rows_per_page"
              style={{ padding: 0, margin: "0 8px" }}
              disableUnderline
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            name="down_page"
            disabled={page === 0}
            onClick={() => handleDownPage()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          {numberOfRows > 0 ? page + 1 : 0}
          {`/ ${Math.ceil(numberOfRows / rowsPerPage)}`}
          <Button
            name="up_page"
            disabled={page + 1 === Math.ceil(numberOfRows / rowsPerPage)}
            onClick={() => handleUpPage()}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      </Toolbar>
      {editMode && (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
            [classes.invisible]: numSelected <= 0
          })}
        >
          {numSelected > 0 && (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              <span style={{ color: "#FF845E" }}>{numSelected}</span> Vehicle/s
              selected
            </Typography>
          )}

          {numSelected > 0 && (
            <Tooltip title="Delete">
              <Button
                onClick={() => {
                  handleOpenUnlink();
                }}
                variant="contained"
                className={classes.btn}
                style={{ borderRadius: "17px" }}
              >
                Unlink Vehicles
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

// MAIN STYLES
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "70%",
    border: "2px solid rgba(209, 209, 209, 0.5)",
    borderRadius: 23
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "800%",
      "&:focus": {
        width: "100"
      }
    }
  },
  addEntryBtn: {
    color: theme.palette.primary.main
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background:theme.palette.primary.main
    }
  }
}));

// MAIN
export default function Vehicles(props) {
  const clientName = JSON.parse(localStorage.getItem("client_name"));
  const classes = useStyles();
  const {
    // clientId,
    editMode,
    groupIds_int,
    groupIds_str,
    group_names,
    maxDevices,
    // otherGroups
  } = props;
  const { id: clientId } = useParams();

  const [vehiclesData, setVehiclesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [vehicleKeyword, setVehicleKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [countRows, setCountRows] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [unlinkDialog, setUnlinkDialog] = useState(false);
  const [toUnlink, setToUnlink] = useState([]);

  const [openAddEntry, setOpenAddEntry] = useState(false);
  const [addEntryPage, setAddEntryPage] = useState(0);
  const [addEntryTotal, setAddEntryTotal] = useState(0);
  const [addEntryGroup, setAddEntryGroup] = useState(0);
  const [entries, setEntries] = useState([]);
  const [addEntryKeyword, setAddEntryKeyword] = useState("");
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectedVehicleEntries, setSelectedVehicleEntries] = useState([]);
  const [addEntryOrder, setAddEntryOrder] = useState("asc");
  const [addEntryOrderBy, setAddEntryOrderBy] = useState("vehicle_info.id");
  const [clientGroup, setClientGroup] = useState(null);

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [itemsToUnlink, setItemsToUnlink] = useState([]);
  const [itemToUnlink, setItemToUnlink] = useState([]);
  const [vehicleTypesToUnlink, setVehicleTypesToUnlink] = useState([]);

  const [adminUnlink] = useMutation(ADMIN_UNLINK, {
    onCompleted: () => {
      setSelected([]);
      setItemsToUnlink([]);
      refetch(getVariables());
      if (vehicleTypesToUnlink.length > 0) {
        queryVehicleTypes({
          variables: {
            condition: [
              {
                field: "vehicle_types.id",
                value: vehicleTypesToUnlink
              }
            ],
            group_ids: groupIds_int
          }
        });
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
  });

  const [queryVehicleTypes] = useLazyQuery(GET_ADMIN_VEHICLE_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: response => {
      const { vehicle_types } = response.get_vehicle_types;
      const vehicleTypesId = [];
      if (vehicle_types) {
        vehicle_types.forEach(type => {
          const { id, number_of_vehicles } = type;
          if (number_of_vehicles === 0) {
            vehicleTypesId.push(id);
          }
        });

        if (vehicleTypesId.length > 0) {
          adminUnlink({
            variables: {
              target: vehicleTypesId,
              group: { id: groupIds_int[0], name: group_names[0] },
              model: "vehicle_types"
            }
          });
        }

        setVehicleTypesToUnlink([]);
      }

      Swal.fire({
        icon: "success",
        text: "Unlinked successfully",
        showConfirmButton: false,
        timer: 1000
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 1000
      });
    }
  });

  const onSelectedVehicleChanged = (event, vehicle) => {
    const { checked } = event.target;

    if (lodash.some(selectedVehicles, vehicle)) {
      if (!checked) {
        const tempVehicles = selectedVehicles;
        const index = lodash.indexOf(tempVehicles, vehicle);
        tempVehicles.splice(index, 1);
        setSelectedVehicles(tempVehicles);
      }
    } else if (checked) {
      setSelectedVehicles(prev => [...prev, vehicle]);
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }
  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const onItemsToUnlinkChanged = (event = null, vehicle) => {
    if (event) {
      const { checked } = event.target;
      if (lodash.some(itemsToUnlink, vehicle)) {
        if (!checked) {
          const temp = itemsToUnlink.filter(
            vehicles => !lodash.isEqual(vehicles, vehicle)
          );
          setItemsToUnlink(temp);
        }
      } else if (checked) {
        setItemsToUnlink(prev => [...prev, vehicle]);
      }
    } else if (!lodash.some(itemsToUnlink, vehicle)) {
      setItemsToUnlink(prev => [...prev, vehicle]);
    }
  };

  const handleAddEntryOpen = () => {
    setOpenAddEntry(true);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setEntries([]);
  };

  const handleAddEntryClose = () => {
    setOpenAddEntry(false);
    setSelectedEntries([]);
    setAddEntryGroup(" ");
    setClientGroup(null);
    setEntries([]);
    // setDevices([]);
    setSelectedVehicles([]);
  };
  const handleOpenUnlink = () => {
    setUnlinkDialog(true);
  };

  const handleCloseUnlink = () => {
    setUnlinkDialog(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("desc");
    }
  };

  const sortEntries = field => {
    if (addEntryOrderBy === field) {
      setAddEntryOrder(addEntryOrder === "asc" ? "desc" : "asc");
    } else {
      setAddEntryOrderBy(field);
      setAddEntryOrder("desc");
    }
  };
  // const selectAllEntries = event => {
  //   if (event.target.checked) {
  //     const newSelecteds =
  //       entries && entries.vehicles ? entries.vehicles.map(n => n.id) : [];
  //     setSelectedEntries(newSelecteds);
  //     return;
  //   }
  //   setSelectedEntries([]);
  // };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = vehiclesData.map(n => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const getVariables = React.useCallback(() => {
    const variables = {
      keyword: vehicleKeyword,
      skip: page * rowsPerPage,
      first: rowsPerPage,
      group_ids: groupIds_int,
      orderBy: [
        {
          field:
            orderBy === "status_code" || orderBy === "status_code_id"
              ? "rover_status.reportstamp"
              : orderBy,
          direction: order
        }
      ]
    };

    return variables;
  }, [orderBy, order, rowsPerPage, page, vehicleKeyword]);

  const { data: currentData, refetch, fetchMore, subscribeToMore } = useQuery(
    GET_ADMIN_VEHICLES,
    {
      variables: getVariables(),
      // onCompleted: () => {
      //   setPage(0);
      // },
      onError: () => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          // title: "Success",
          text: "Something went wrong"
        });
      },
      fetchPolicy: "network-only"
    }
  );

  const getGroupIds = () => {
    const data = [];
    data.push({ field: "group_id", value: groupIds_str });
    return data;
  };
  const { data: entriesData, fetchMore: fetchMoreEntries } = useQuery(
    GET_ADMIN_VEHICLES,
    {
      skip: !currentData,
      variables: {
        keyword: addEntryKeyword,
        skip: 0,
        not: getGroupIds(),
        first: rowsPerPage,
        group_ids: addEntryGroup,
        orderBy: [{ field: addEntryOrderBy, direction: addEntryOrder }]
      },
      // onCompleted: () => {
      //   // setAddEntryPage(0);
      // },
      onError: () => {
        setLoading(false);
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (currentData) {
      setVehiclesData(currentData.get_vehicles.vehicles);
      setTotalRows(currentData.get_vehicles.total);
      setCountRows(currentData.get_vehicles.count);
      setLoading(false);
    }
    if (entriesData) {
      setEntries(entriesData.get_vehicles);
      setAddEntryTotal(entriesData.get_vehicles.total);
    }
  }, [currentData, entriesData]);

  const getGroupIdsForSubs = () => {
    const data = [];
    for (let index = 0; index < groupIds_str.length; index += 1) {
      const element = groupIds_str[index];
      data.push({ field: "group_ids", value: element });
    }
    return data;
  };

  const subscribeToNewVehicles = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: VIEW_CLIENT_VEHICLES_SUBSCRIPTION,
      variables: {
        filter: getGroupIdsForSubs()
      },
      updateQuery: (prev, { subscriptionData }) => {
        return;
        if (!subscriptionData.data) return prev;
        const newData = subscriptionData.data.data_updated.vehicle;
        const mergedData = vehiclesData;
        let subsData = {
          count:
            prev && prev.get_vehicles && prev.get_vehicles.count
              ? prev.get_vehicles.count
              : 0,
          total:
            prev && prev.get_vehicles && prev.get_vehicles.total
              ? prev.get_vehicles.total
              : 0,
          vehicles:
            prev && prev.get_vehicles && prev.get_vehicles.vehicles
              ? prev.get_vehicles.vehicles
              : []
        };
        if (newData) {
          mergedData.unshift(newData);
          setVehiclesData(mergedData);
          subsData = {
            count: prev.get_vehicles.count + 1,
            total: prev.get_vehicles.total + 1,
            vehicles: mergedData
          };
        }
        return {
          ...prev,
          get_vehicles: subsData
        };
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToNewVehicles();
    return () => unsubscribe();
  }, [
    // vehiclesData,
    // refetch(getVariables()),
    subscribeToNewVehicles
  ]);

  const [adminLink] = useMutation(ADMIN_LINK, {
    onCompleted: () => {
      // if (devices.length > 0) {}
      if (selectedVehicles.length > 0) {
        const deviceIds = [];
        const vehicleIds = [];
        selectedVehicles.forEach(vehicle => {
          if (vehicle.device_id !== "0") {
            deviceIds.push(vehicle.device_id);
          }
          vehicleIds.push(vehicle.vehicle_type_id);
        });

        adminLink({
          variables: {
            target: deviceIds,
            group: { id: groupIds_int[0], name: group_names[0] },
            model: "devices"
          }
        });

        adminLink({
          variables: {
            target: lodash.uniq(vehicleIds),
            group: { id: groupIds_int[0], name: group_names[0] },
            model: "vehicle_types"
          }
        });
      }

      if (selectedEntries.length > 0) {
        // AddLogs("Admin - Clients", "link_vehicle", selectedVehicleEntries);
        AddLogs(
          "Admin - Clients",
          "link_vehicle",
          `${selectedVehicleEntries
            .toString()
            .replace(/,/g, ", ")} to ${clientName}`
        );
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Linked successfully",
          showConfirmButton: false,
          timer: 1000
        });

        refetch(getVariables());
        handleAddEntryClose();
      }
    },
    onError: () => {
      handleAddEntryClose();
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
  });

  const unlinkItemsParser = vehicles => {
    const tempDevices = [];
    const tempVehicles = [];
    vehicles.forEach(vehicle => {
      const { device_id, vehicle_type_id } = vehicle;
      if (device_id !== "0") {
        tempDevices.push(device_id);
      }
      tempVehicles.push(vehicle_type_id);
    });
    return {
      devicesId: lodash.uniq(tempDevices),
      vehicleTypesId: lodash.uniq(tempVehicles)
    };
  };

  const unlinkVehicle = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);

    if (toUnlink.length > 0) {
      setVehicleTypesToUnlink(unlinkItemsParser(toUnlink).vehicleTypesId);
      adminUnlink({
        variables: {
          target: unlinkItemsParser(toUnlink).devicesId,
          group: { id: groupIds_int[0], name: group_names[0] },
          model: "devices"
        }
      });
      adminUnlink({
        variables: {
          target: toUnlink.map(vehicle => vehicle.id),
          group: { id: groupIds_int[0], name: group_names[0] },
          model: "vehicles"
        }
      });
      AddLogs(
        "Admin - Clients",
        "unlink_vehicle",
        `${itemToUnlink.toString().replace(/,/g, ", ")} to ${clientName}`
      );
      setToUnlink([]);
    }
  };

  const unlinkVehicles = () => {
    if (countRows === 1 && totalRows > 1) setPage(page - 1);

    if (selected.length > 0) {
      // unlinkItems(itemsToUnlink);
      setVehicleTypesToUnlink(unlinkItemsParser(itemsToUnlink).vehicleTypesId);
      adminUnlink({
        variables: {
          target: unlinkItemsParser(itemsToUnlink).devicesId,
          group: { id: groupIds_int[0], name: group_names[0] },
          model: "devices"
        }
      });
      adminUnlink({
        variables: {
          target: selected,
          group: { id: groupIds_int[0], name: group_names[0] },
          model: "vehicles"
        }
      });
      AddLogs(
        "Admin - Clients",
        "unlink_vehicle",
        `${itemsToUnlink
          .map(vehicle => vehicle.plateno)
          .toString()
          .replace(/,/g, ", ")} to ${clientName}`
      );
      setSelected([]);
    }
  };

  const handleToUnlink = vehicle => {
    // if (!toUnlink.some(row_id => row_id === id)) {
    //   setToUnlink([...toUnlink, id])
    // };
    setToUnlink([vehicle]);
  };

  const handlePagination = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    refetch(getVariables());
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const clickEntry = (event, name) => {
    const selectedIndex = selectedEntries.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEntries, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEntries.slice(1));
    } else if (selectedIndex === selectedEntries.length - 1) {
      newSelected = newSelected.concat(selectedEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEntries.slice(0, selectedIndex),
        selectedEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedEntries(newSelected);
  };

  const clickVehicleEntry = (event, plateno) => {
    const selectedIndex = selectedVehicleEntries.indexOf(plateno);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedVehicleEntries, plateno);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedVehicleEntries.slice(1));
    } else if (selectedIndex === selectedVehicleEntries.length - 1) {
      newSelected = newSelected.concat(selectedVehicleEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedVehicleEntries.slice(0, selectedIndex),
        selectedVehicleEntries.slice(selectedIndex + 1)
      );
    }

    setSelectedVehicleEntries(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  const isEntrySelected = id => selectedEntries.indexOf(id) !== -1;

  const handleUpPage = () => {
    const nextPage = page + 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page + 1);
      //     setVehiclesData(fetchMoreResult.get_vehicles.vehicles);
      //     return {
      //       ...prev,
      //       get_vehicles: {
      //         count: fetchMoreResult.get_vehicles.count,
      //         total: fetchMoreResult.get_vehicles.total,
      //         vehicles: fetchMoreResult.get_vehicles.vehicles
      //       }
      //     };
      //   }
      // });
    }
  };

  const handleDownPage = () => {
    const nextPage = page - 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page - 1);
      //     setVehiclesData(fetchMoreResult.get_vehicles.vehicles);
      //     return {
      //       ...prev,
      //       get_vehicles: {
      //         count: fetchMoreResult.get_vehicles.count,
      //         total: fetchMoreResult.get_vehicles.total,
      //         vehicles: fetchMoreResult.get_vehicles.vehicles
      //       }
      //     };
      //   }
      // });
    }
  };

  const entriesNextPage = () => {
    const nextPage = addEntryPage + 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage + 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_vehicles);
          return {
            ...prev,
            get_vehicles: {
              count: fetchMoreResult.get_vehicles.count,
              total: fetchMoreResult.get_vehicles.total,
              vehicles: fetchMoreResult.get_vehicles.vehicles
            }
          };
        }
      });
    }
  };

  const entriesPreviousPage = () => {
    const nextPage = addEntryPage - 1;
    if (nextPage * 10 <= addEntryTotal) {
      setAddEntryPage(addEntryPage - 1);
      fetchMoreEntries({
        variables: {
          skip: nextPage * 10
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setEntries(fetchMoreResult.get_vehicles);
          return {
            ...prev,
            get_vehicles: {
              count: fetchMoreResult.get_vehicles.count,
              total: fetchMoreResult.get_vehicles.total,
              vehicles: fetchMoreResult.get_vehicles.vehicles
            }
          };
        }
      });
    }
  };

  const getData = () => {
    return vehiclesData && vehiclesData.length
      ? stableSort(vehiclesData, getSorting(order, orderBy)).slice(
          0,
          rowsPerPage
        )
      : [];
  };

  const getEntryData = () => {
    return entries && entries.vehicles && entries.vehicles.length
      ? stableSort(entries.vehicles, getSorting(order, orderBy)).slice(0, 10)
      : [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
        else checked = { all: false, indeterminate: false };
      }
    }
    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    // const tempDevicesToUnlink = devicesToUnlink;
    const tempItemsToUnlink = itemsToUnlink;
    const deselect = () => {
      const displayedData = [];
      getData().map(data => {
        if (data.client_id !== clientId) {
          displayedData.push(data.id);
        }
        return data;
      });

      // const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);

      const currentPageData = getData();
      const newItemsToUnlink = tempItemsToUnlink.filter(
        vehicle => !lodash.some(currentPageData, vehicle)
      );

      setItemsToUnlink(newItemsToUnlink);

      // const currentPageData = getData().map(({ id, device_id }) => ({
      //   [id]: device_id
      // }));
      // const newDevicesToUnlink = tempDevicesToUnlink
      //   .filter(device => {
      //     return !lodash.some(currentPageData, device);
      //   })
      //   .map(device => device);

      // setDevicesToUnlink(newDevicesToUnlink);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      // const tempData = [];
      getData().map(n => {
        if (n.client_id !== clientId) {
          tempSelected.push(n.id);
          tempItemsToUnlink.push(n);
        }
        return n;
      });
      // const displayedData = tempData;
      // getData().map(n => tempSelected.push(n.id));
      setSelected(lodash.uniq(tempSelected));

      // getData().map(({ id, device_id }) =>
      //   tempDevicesToUnlink.push({ [id]: device_id })
      // );
      // setDevicesToUnlink(lodash.uniq(tempDevicesToUnlink));

      // getData().map(vehicle => tempItemsToUnlink.push(vehicle));
      setItemsToUnlink(lodash.uniq(tempItemsToUnlink));
      return;
    }
    deselect();
  };

  const isEntriesChecked = () => {
    let checked = { all: false, indeterminate: false };
    const entriesLength =
      entries && entries.vehicles ? getEntryData().length : 0;
    if (!selected.length < entriesLength) {
      const displayedData = getEntryData().map(data => data.id);
      if (displayedData.every(data => selectedEntries.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selectedEntries.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const selectAllEntries = event => {
    const tempSelectedVehicle = selectedVehicleEntries;
    const tempSelected = selectedEntries;
    const currentSelectedVechicles = selectedVehicles;

    const deselect = () => {
      const displayedData = getEntryData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);

      getData().forEach(vehicle => {
        if (lodash.some(currentSelectedVechicles, vehicle)) {
          const index = lodash.indexOf(currentSelectedVechicles, vehicle);
          currentSelectedVechicles.splice(index, 1);
        }
        // const { device_id } = vehicle;
        // if (currentSelectedDevices.includes(device_id)) {
        //   const index = devices.indexOf(device_id);
        //   currentSelectedDevices.splice(index, 1);
        // }
      });
      // setDevices(currentSelectedDevices);
      setSelectedVehicles(currentSelectedVechicles);
      setSelectedEntries(newSelected);
    };

    if (event.target.checked) {
      if (isEntriesChecked().indeterminate) {
        deselect();
        return;
      }
      getEntryData().map(n => tempSelected.push(n.id));
      setSelectedEntries(lodash.uniq(tempSelected));

      getEntryData().map(n => tempSelectedVehicle.push(n.plateno));
      setSelectedVehicleEntries(lodash.uniq(tempSelectedVehicle));

      getData().forEach(vehicle => {
        currentSelectedVechicles.push(vehicle);
        // const { device_id } = vehicle;
        // currentSelectedDevices.push(device_id);
      });
      setSelectedVehicles(lodash.uniq(currentSelectedVechicles));
      // setDevices(lodash.uniq(currentSelectedDevices));

      return;
    }
    deselect();
  };

  const addEntryBody = (
    <Paper
      style={{
        marginTop: "10%",
        marginBottom: "10%",
        marginLeft: "20%",
        marginRight: "20%",
        padding: 10
      }}
    >
      <Grid container>
        <Grid
          item
          xs={1}
          align="center"
          style={{ marginRight: 20, marginLeft: 10 }}
        >
          <Typography>
            <Box 
            fontWeight="fontWeightBold"
            style={{ width: 100, padding: 10 }}
            >
              Add Entry
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={10}
        style={{ display: "flex", alignItems: "center" }}
        >
          {/* <Autocomplete
            options={otherGroups || []}
            getOptionLabel={option => option.name}
            renderOption={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black", fontWeight: "bold" }
                }}
                style={{ width: "40%", background: "#E9E9E9", borderRadius: 6 }}
                placeholder="Client name"
              />
            )}
            name="client_group"
            onChange={(e, nv) => {
              setClientGroup(nv);
              setAddEntryGroup(nv.id);
              setSelectedEntries([]);
            }}
            value={clientGroup}
            disableClearable
          /> */}
          <ClientAutocomplete 
            setter={setClientGroup}
            // groupFilter={clientGroup}
            onChange={(e, nv) => {
              setClientGroup(nv);
              setAddEntryGroup(nv.group_ids);
              setSelectedEntries([]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                if (e.target.value.length > 0) {
                  setAddEntryKeyword(e.target.value);
                } else if (e.target.value.length === 0) {
                  setAddEntryKeyword(e.target.value);
                }
              }}
              inputProps={{ "aria-label": "search" }}
            />
            <IconButton
              name="down_page"
              disabled={addEntryPage === 0}
              onClick={() => entriesPreviousPage()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            {addEntryTotal ? addEntryPage + 1 : 0}
            {` / ${Math.ceil(addEntryTotal / rowsPerPage)}`}
            <Button
              name="up_page"
              disabled={
                addEntryPage + 1 === Math.ceil(addEntryTotal / rowsPerPage)
              }
              onClick={() => entriesNextPage()}
            >
              <KeyboardArrowRightIcon />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            style={{
              height: 250,
              border: "1px solid rgba(209, 209, 209, 0.58)"
            }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHeadAddEntry
                classes={classes}
                numSelected={selectedEntries.length}
                addEntryOrder={addEntryOrder}
                addEntryOrderBy={addEntryOrderBy}
                selectAllEntries={selectAllEntries}
                editMode={editMode}
                rowCount={
                  entries && entries.vehicles ? entries.vehicles.length : 0
                }
                sortEntries={sortEntries}
                isEntriesChecked={isEntriesChecked}
              />
              <TableBody>
                {entries &&
                  entries.vehicles &&
                  getEntryData().map((row, index) => {
                    const isItemSelected = isEntrySelected(row.id);
                    const labelId = `entry-checkbox-${index}`;
                    return (
                      <TableRow
                        key={`entries-${row.id}`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            disabled={!editMode}
                            onClick={event => {
                              clickEntry(event, row.id);
                              clickVehicleEntry(event, row.plateno);
                              // onDevicesChanged(event, row);
                              onSelectedVehicleChanged(event, row);
                            }}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.client_name ? row.client_name : "-"}
                        </TableCell>
                        <TableCell>{row.plateno ? row.plateno : "-"}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="contained"
            onClick={() => {
              handleAddEntryClose();
            }}
            style={{
              border: "1px solid  #FF845E",
              color: "#FF845E",
              background: "#FFFFFF",
              borderRadius: 17,
              margin: 10,
              width: 100
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            style={{
              // background: "#3CA4F7",
              // color: "#FFFFFF",
              borderRadius: 17,
              margin: 10,
              width: 100
            }}
            onClick={() => {
              if (selectedEntries.length) {
                adminLink({
                  variables: {
                    target: selectedEntries,
                    group: { id: groupIds_int[0], name: group_names[0] },
                    model: "vehicles"
                  }
                });
              } else {
                handleAddEntryClose();
                Swal.fire({
                  icon: "error",
                  // title: "Success",
                  text: "Please select vehicles to Link!"
                });
              }
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
  const VehiclesTable = () => {
    return vehiclesData && vehiclesData.length ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              {row.client_id !== clientId && editMode ? (
                <TableCell padding="checkbox">
                  {editMode && (
                    <Checkbox
                      color="primary"
                      // disabled={!editMode}
                      onClick={event => {
                        handleClick(event, row.id);
                        onItemsToUnlinkChanged(event, row);
                        // onDevicesToUnlinkChange(event, row);
                      }}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  )}
                </TableCell>
              ) : (
                <TableCell padding="checkbox">&nbsp;</TableCell>
              )}

              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.client_name ? row.client_name : "-"}
              </TableCell>
              <TableCell>{row.plateno ? row.plateno : "-"}</TableCell>
              <TableCell>{row.vehicle_type ? row.vehicle_type : "-"}</TableCell>
              <TableCell>
                {row.device_info && row.device_info?.devicealias
                  ? row.device_info.devicealias
                  : "-"}
              </TableCell>
              <TableCell
                style={{
                  color: gpsStatus(
                    row.device_info && row.device_info?.device_status?.status_code
                      ? row.device_info.device_status.status_code
                      : "no gps"
                  ).color
                }}
              >
                {
                  gpsStatus(
                    row.device_info && row.device_info?.device_status?.status_code
                      ? row.device_info.device_status.status_code
                      : "no gps"
                  ).label
                }
              </TableCell>

              {row.client_id !== clientId ? (
                <TableCell component="th" scope="row">
                  <IconButton
                    disabled={!editMode}
                    aria-label="unlink"
                    onClick={() => {
                      // handleToUnlink(parseInt(row.id, 0));
                      handleToUnlink(row);
                      setItemToUnlink(row.plateno);
                      // onItemsToUnlinkChanged(null, row);
                      // onDevicesToUnlinkChange(null, row);
                      handleOpen();
                    }}
                  >
                    <LinkOffIcon />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell component="th" scope="row">
                  -
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: " inherit"
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          padding: 20,
          background: "#FFFFFF",
          minHeight: "740px"
        }}
      >
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            editMode={editMode}
            handleOpenUnlink={handleOpenUnlink}
            totalRows={totalRows}
            maxDevices={maxDevices}
            numSelected={selected.length}
            numberOfRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePagination={handlePagination}
            handleUpPage={handleUpPage}
            handleDownPage={handleDownPage}
            setVehicleKeyword={setVehicleKeyword}
            setPage={setPage}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                editMode={editMode}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={vehiclesData.length}
                handleSort={handleSort}
                isChecked={isChecked}
              />
              <VehiclesTable
                selected={selected}
                vehiclesData={vehiclesData}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>

          {editMode && (
            <div style={{ textAlign: "right" }}>
              <Button
                className={classes.addEntryBtn}
                style={{ fontWeight: "bold" }}
                align="right"
                onClick={() => {
                  handleAddEntryOpen();
                }}
              >
                Add Entry
              </Button>
            </div>
          )}
        </Paper>
        <Modal
          open={openAddEntry}
          onClose={() => {
            handleAddEntryClose();
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {addEntryBody}
        </Modal>
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={() => {
            unlinkVehicle();
          }}
          title="Unlink Vehicle"
          content="Are you sure you want to unlink this vehicle?"
        />
        <ConfirmationDialog
          toggle={unlinkDialog}
          close={() => handleCloseUnlink()}
          fn={() => {
            // return;
            unlinkVehicles();
          }}
          title="Unlink Vehicles"
          content="Are you sure you want to unlink these vehicles?"
        />
      </Container>
    </div>
  );
}
