const debounceKeywordChange = (func, delay) => {
  let timeout = null;

  return (...args) => {
    if (timeout) clearTimeout(timeout);
    if (args[0] && args[0].persist) args[0].persist();

    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default debounceKeywordChange;
