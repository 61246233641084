/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Chip,
  TextField,
  Checkbox,
  makeStyles,
  FormControl,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useQuery } from "@apollo/client";
import { GET_ALERT_DESCRIPTIONS } from "../../../graphql/Queries";
import DraggableList from "./DraggableList/DraggableList";

// Styles
import useFormStyles from "../../../styles/useFormStyles";

const useChipInputStyle = makeStyles({
  rootInput: {
    // width: "100"
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    },
    "& ::placeholder": {
      /* Firefox */
      color: "#000 !important",
      opacity: 1
    },
    "& :-ms-input-placeholder": {
      /* Internet Explorer 10-11 */
      color: "#000 !important",
      opacity: 1
    },
    "& ::-ms-input-placeholder": {
      /* Microsoft Edge */
      color: "#000 !important",
      opacity: 1
    }
  },
  iconStyle: { width: 16, height: 16 }
});

const AlertTypeChipInput = React.forwardRef((props, ref) => {
  const {
    values,
    initialValues,
    readOnly,
    updateFieldData,
    onSelectedAlertTypeChanged = () => {},
    params
  } = props;
  const { haulers } = params;
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([]);
  const { data, loading } = useQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: result => {
      ref.current = [0];
      if (updateFieldData) {
        updateFieldData({
          field: "alert_descriptions",
          data: result?.get_alert_descriptions?.alert_descriptions
        });
      }
      const alerts = result?.get_alert_descriptions?.alert_descriptions;
      const alertsIds = alerts.map(alert => alert.id);
      onSelectedAlertTypeChanged(
        values.filter(alert => alertsIds.includes(alert.id))
      );
    },
    variables: {
      first: 1000,
      group_ids: haulers
    },
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    return () => {
      ref.current = [0];
      onSelectedAlertTypeChanged([]);
    };
  }, []);

  useEffect(() => {
    if (values.length !== selectedAlertTypes) {
      ref.current = values.length > 0 ? values : [0];
      setSelectedAlertTypes(values);
    }
  }, [values]);

  useEffect(() => {
    if (data && initialValues) {
      if (readOnly) {
        const initVal = data.get_alert_descriptions.alert_descriptions.filter(
          val => initialValues.includes(val.id)
        );
        onSelectedAlertTypeChanged(initVal);
        setSelectedAlertTypes(initVal);
        ref.current = initVal;
        return;
      }
      if (
        data.get_alert_descriptions.alert_descriptions.length !==
          initialValues.length &&
        Number(initialValues[0]) !== 0 &&
        initialValues.length > 0
      ) {
        const initVal = data.get_alert_descriptions.alert_descriptions.filter(
          val => initialValues.includes(val.id)
        );
        onSelectedAlertTypeChanged(initVal);
        setSelectedAlertTypes(initVal);
        ref.current = initVal;
      }
    }
  }, [data, readOnly]);

  const formStyles = useFormStyles();
  const chipInputStyles = useChipInputStyle();

  let alertTypes = [];
  if (data) {
    // Exclude Location alert
    alertTypes = [
      ...data.get_alert_descriptions.alert_descriptions.filter(
        a => Number(a.id) !== 1
      )
    ];
  }

  const handleSelectedChange = (_, newSelectedAlertTypes) => {
    ref.current =
      newSelectedAlertTypes.length > 0 ? newSelectedAlertTypes : [0];
    setSelectedAlertTypes(newSelectedAlertTypes);
    onSelectedAlertTypeChanged(newSelectedAlertTypes);
  };

  // const arrangeAlerts = React.useCallback(
  //   orderedAlerts => {
  //     ref.current = orderedAlerts.length > 0 ? orderedAlerts : [0];
  //   },
  //   [setSelectedAlertTypes]
  // );

  return (
    <>
      <FormControl className={formStyles.formRoot}>
        <Autocomplete
          loading={loading}
          multiple
          limitTags={2}
          id="alert-type-chip-input"
          options={alertTypes}
          disableCloseOnSelect
          disableClearable
          disabled={readOnly}
          onChange={handleSelectedChange}
          value={selectedAlertTypes}
          getOptionLabel={option => option.code?.toString()}
          getOptionDisabled={option =>
            selectedAlertTypes.length >= 20 &&
            !selectedAlertTypes.includes(option)
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                color="primary"
                // variant="outlined"
                style={{ textTransform: "capitalize" }}
                label={option?.code
                  ?.toLowerCase()
                  .replace(/\b\w/g, c => c.toUpperCase())}
                // onDelete={() => onRemove([], option)}
              />
            ))
          }
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                disabled={!selected && selectedAlertTypes.length >= 20}
                color="primary"
                icon={
                  <CheckBoxOutlineBlankIcon
                    className={chipInputStyles.iconStyle}
                  />
                }
                checkedIcon={
                  <CheckBoxIcon className={chipInputStyles.iconStyle} />
                }
                checked={selected}
              />
              <Typography variant="body2">{option.code}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField
              {...params}
              classes={{ root: formStyles.textFieldRoot }}
              className={chipInputStyles.rootInput}
              // variant="outlined"
              label="Alert Description"
              placeholder={
                loading
                  ? "Loading..."
                  : selectedAlertTypes.length > 0
                  ? ""
                  : "All"
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                style: {
                  padding: "6px 0px"
                },
                ...params.InputProps,
                disableUnderline: readOnly,
                disabled: readOnly,
                endAdornment: loading ? <CircularProgress size={12} /> : ""
              }}
            />
          )}
        />
      </FormControl>
      {/* {selectedAlertTypes.length >= 2 && !readOnly && (
        <DraggableList
          initialValues={selectedAlertTypes}
          onArrangementChanged={arrangeAlerts}
        />
      )} */}
    </>
  );
});

export default AlertTypeChipInput;
