/* global google */
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";

import { GET_GEOFENCES } from "../../../graphql/Queries";
import useUserContext from "../../../context/User/useUserContext";
import Geofences from "../../../components/Monitoring/BaseMap/Geofences.map";

const GeofencesFilter = props => {
  const { zoomLevel, mapRef } = props;
  const userCtx = useUserContext();
  const [geofences, setGeofences] = useState([]);

  const getGeoPaths = () => {
    const path = [];
    const aNorth = mapRef.current
      .getBounds()
      .getNorthEast()
      .lat();
    const aEast = mapRef.current
      .getBounds()
      .getNorthEast()
      .lng();
    const aSouth = mapRef.current
      .getBounds()
      .getSouthWest()
      .lat();
    const aWest = mapRef.current
      .getBounds()
      .getSouthWest()
      .lng();

    path.push(
      { lat: aNorth, lon: aEast },
      { lat: aNorth, lon: aWest },
      { lat: aSouth, lon: aWest },
      { lat: aSouth, lon: aEast },
      { lat: aNorth, lon: aEast }
    );

    return path;
  };

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCES, {
    onCompleted: res => {
      if (res.get_geofences.count > 0) {
        setGeofences(res.get_geofences.geofences);
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong"
      });
    }
  });

  useEffect(() => {
    let geofenceVars = {
      first: 100,
      group_ids: userCtx.client.group_ids,
    };
    if (zoomLevel >= 15) geofenceVars = { ...geofenceVars, bbox: getGeoPaths() } 

    fetchGeofences({ variables: geofenceVars });
  }, [zoomLevel]);

  return <>{geofences.length > 0 && <Geofences geofencesData={geofences}/>}</>;
};

export default GeofencesFilter;
