/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { Box } from "@material-ui/core";

const FilterDialog = props => {
  const { toggle, fn, close, value: initialVal } = props;
  const classes = useStyles();
  const [date, setDate] = useState({
    start: moment(initialVal.start).format("YYYY-MM-DD"),
    end: moment(initialVal.end).format("YYYY-MM-DD")
  });

  const handleClose = () => {
    close();
  };

  const handleDaySelectorChanged = event => {
    const { name, value } = event.target;
    if (name === "from") {
      setDate(prev => ({ ...prev, start: value }));
    } else {
      setDate(prev => ({ ...prev, end: value }));
    }
  };

  return (
    <Dialog
      open={toggle}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.closeContainer }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className={classes.closeTitle}>360 Checklist Filter</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            value={date.start}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black",
                fontWeight: "bold"
              }
            }}
            inputProps={{
              max: date.end
            }}
            autoFocus
            margin="dense"
            name="from"
            label="From"
            placeholder="Select date"
            type="date"
            fullWidth
            onChange={e => handleDaySelectorChanged(e)}
          />
          <TextField
            value={date.end}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "black",
                fontWeight: "bold"
              }
            }}
            inputProps={{
              min: date.start,
              max: moment(new Date()).format("YYYY-MM-DD")
            }}
            autoFocus
            margin="dense"
            name="to"
            label="To"
            placeholder="Select date"
            type="date"
            fullWidth
            onChange={e => handleDaySelectorChanged(e)}
          />
        </DialogContentText>
      </DialogContent>
      <Box className={classes.action_container}>
        <Button
          variant="contained"
          onClick={() => {
            fn(date);
            handleClose();
          }}
          color="primary"
          autoFocus
          className={classes.circularButtonYes}
          disabled={!(date.start && date.end)}
        >
          Filter
        </Button>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 300
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },
  action_container: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 24px",
    margin: "0.5rem 0"
  }
}));

export default FilterDialog;
