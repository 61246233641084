/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Button,
  IconButton,
  TextField,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  Fab,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress
} from "@material-ui/core";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibleIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import Swal from "sweetalert2";
import Cookie from "js-cookie";
import Loading from "../../../../utils/Loading";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
// import Upload from "./View/Upload";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";

import {
  GET_ADMIN_VEHICLE_TYPES,
  GET_GROUPS
} from "../../../../graphql/Queries";
import {
  DELETE_VEHICLE_TYPE,
  DELETE_MULTIPLE_VEHICLE_TYPE,
  DELETE_FUEL_CALIBRATION,
  UPLOAD_VEHICLE_TYPES
} from "../../../../graphql/Mutations";
import useUserContext from "../../../../context/User/useUserContext";
import Upload from "../../../../utils/Upload";
import validate from "../../../../utils/validation";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Type"
  },
  {
    id: "capacity_volume",
    numeric: true,
    disablePadding: false,
    label: "Volume Capacity"
  },
  {
    id: "capacity_weight",
    numeric: true,
    disablePadding: false,
    label: "Weight Capacity"
  },
  {
    id: "number_of_vehicles",
    numeric: true,
    disablePadding: false,
    label: "Number of Vehicles"
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

function TableHeadCells(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!canEdit}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeadCells.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  toolbar_sub_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: "space-between"
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    // flex: "1 1 25%",
    // color: "#000000"
    display: "flex",
    position: "relative",
    color: "black"
  },
  // title: {
  //   flex: "1 1 25%",
  //   color: "#000000"
  // },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const columns = {
  "Vehicle Type *": "name",
  "Volume Capacity": "capacity_volume",
  "Unit of Volume": "capacity_volume_unit",
  "Weight Capacity": "capacity_weight",
  "Unit of Weight": "capacity_weight_unit",
  "Number of Wheels": "number_of_wheels",
  Description: "remarks"
};

export default function AlertDescriptions() {
  const classes = useStyles();
  const history = useHistory();
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const contextData = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [canEdit, setcanEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState([]); // set initial data from backend
  const [downloadData, setDownloadData] = useState([]); // set download data from backend
  const [totalRows, setTotalRows] = useState(0); // set total rows
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [page, setPage] = useState(1); // setPage
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("vehicle_types.name");
  const [selected, setSelected] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(Number(user.group_ids[0]));
  const [groupFilter, setGroupFilter] = useState({
    field: "vehicles_types.groups.id",
    value: user.group_ids[0].toString(),
    name: user.group_names[0],
    index: 0
  });
  const [dataCount, setDataCount] = useState(0);
  const [mddopen, setMddopen] = useState(false);
  const [deleteRes, setDeleteRes] = useState([]);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [keyDel, setKeyDel] = useState(); // set selected vehicle types to delete to delete (single)
  const [selDel, setSelDel] = useState([]); // set selected vehicle types to delete (multiple)
  // const [circularLoading, setCircularLoading] = useState(false);
  const [openGroupDropdown, setOpenGroupDropdown] = useState(false);

  const [
    getDropdownGroup,
    { loading: groupsLoading, data: groupsData }
  ] = useLazyQuery(GET_GROUPS);

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
  }, []);

  const handleClose = () => {
    setDialog(false);
  };

  const getVariables = () => {
    const variables = {
      skip: 0,
      // keyword: searchKeyword.value,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }],
      group_ids: [selectedGroup]
    };
    return variables;
  };

  const {
    loading: loadingData,
    data: currentData,
    refetch,
    fetchMore
  } = useQuery(GET_ADMIN_VEHICLE_TYPES, {
    variables: getVariables(),
    onCompleted: () => {
      if (searchKeyword.value) {
        AddLogs("Admin - Vehicle Types", "search", searchKeyword.value);
      }
      setLoading(false);
      // setPage(1);
      setSelected([]);
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first"
  });

  const [getMultipleDelete] = useLazyQuery(GET_ADMIN_VEHICLE_TYPES, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_vehicle_types.vehicle_types.map(val => {
          temp.push(val.name);
          return temp;
        });
        setSelDel(temp);
      }
    }
  });

  useEffect(() => {
    if (searchKeyword.submit) {
      refetch({
        skip: 0,
        keyword: searchKeyword.value,
        first: rowsPerPage,
        orderBy: [{ field: orderBy, direction: order }],
        group_ids: [selectedGroup]
      });
      setPage(1);
    }
  }, [searchKeyword.submit]);

  useEffect(() => {
    if (currentData) {
      setDownloadData(currentData.get_vehicle_types);
      setVehicleTypeData(currentData.get_vehicle_types.vehicle_types);
      setTotalRows(currentData.get_vehicle_types.total);
      setDataCount(currentData.get_vehicle_types.count);
    }
  }, [currentData]);

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };
  const [deleteFuelCalibration] = useMutation(DELETE_FUEL_CALIBRATION);

  const [deleteVehicleType] = useMutation(DELETE_VEHICLE_TYPE, {
    onCompleted(res) {
      if (res.delete_vehicle_type.success) {
        AddLogs("Admin - Vehicle Types", "delete", keyDel);
        handleClose();
        deleteFuelCalibration({
          variables: { vehicleModel_id: [parseInt(toDelete, 10)] }
        });
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          } else {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      } else {
        handleClose();
        Swal.fire({
          title: res.delete_vehicle_type.error,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          } else {
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (
      currentData.get_vehicle_types.count === 1 &&
      currentData.get_vehicle_types.total > 1
    )
      setPage(page - 1);
    deleteVehicleType({
      variables: { id: toDelete.toString() }
    }).then(() => {
      setToDelete([]);
    });
  };

  const [deleteVehicleTypeMultiple] = useMutation(
    DELETE_MULTIPLE_VEHICLE_TYPE,
    {
      onCompleted(res) {
        if (res.delete_vehicle_type_all.success) {
          AddLogs("Admin - Vehicle Types", "delete", selDel);
          handleCloseDeleteAll();
          deleteFuelCalibration({
            variables: { vehicleModel_id: selected.map(i => Number(i)) }
          });
          if (
            res.delete_vehicle_type_all.deleted ===
            res.delete_vehicle_type_all.total
          ) {
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            }).then(result => {
              setLoading(true);
              setSelected([]);
              setToDelete([]);
              setPage(1);
              refetch(getVariables());
              return result;
            });
          } else {
            setDeleteRes(res.delete_vehicle_type_all);
            setMddopen(true);
            setTimeout(() => {
              setMddopen(false);
            }, 1500);
            setLoading(true);
            setSelected([]);
            setToDelete([]);
            setPage(1);
            refetch(getVariables());
          }
        } else {
          setDeleteRes(res.delete_vehicle_type_all);
          setMddopen(true);
          setTimeout(() => {
            setMddopen(false);
          }, 1500);
          setLoading(true);
          setSelected([]);
          setToDelete([]);
          setPage(1);
          refetch(getVariables());
        }
      }
    }
  );

  const handleDeleteAlert = () => {
    const selectedName = [];
    if (selected.length > 0) {
      selected.map(id =>
        selectedName.push({ field: "vehicle_types.id", value: id })
      );
    }
    getMultipleDelete({
      variables: {
        filter: selectedName
      }
    });
    deleteVehicleTypeMultiple({
      variables: {
        condition: {
          field: "vehicle_types.id",
          value: selected
        }
      }
    });
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (vehicleTypeData) {
      return stableSort(vehicleTypeData, getSorting(order, orderBy)).slice(
        Number(page - 1) * rowsPerPage,
        Number(page - 1) * rowsPerPage + rowsPerPage
      );
    }
    return null;
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(
        n =>
          (parseInt(n.client_id, 10) === parseInt(user.client.id, 10) ||
            user.user_level_id === process.env.REACT_APP_WTI_ADMIN) &&
          tempSelected.push(n.id)
      );
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_vehicle_types: {
            count: fetchMoreResult.get_vehicle_types.count,
            total: fetchMoreResult.get_vehicle_types.total,
            vehicle_types: [
              ...prev.get_vehicle_types.vehicle_types,
              ...fetchMoreResult.get_vehicle_types.vehicle_types
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(1);
          refetch(getVariables());
        }
      });
    });
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  const VehicleTypeTable = props => {
    const { rowCount, canEdit } = props;
    if (rowCount > 0) {
      return vehicleTypeData ? (
        <TableBody>
          {getData().map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                key={`row-${row.id}`}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onClick={event => handleClick(event, row.id)}
                    disabled={
                      !canEdit ||
                      (parseInt(row.client_id, 10) !==
                        parseInt(user.client.id, 10) &&
                        user.user_level_id !== process.env.REACT_APP_WTI_ADMIN)
                    }
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  <Highlight search={searchKeyword.value}>
                    {row.name ? row.name : ""}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={searchKeyword.value}>
                    {row.capacity_volume ? row.capacity_volume.toString() : "0"}
                  </Highlight>
                  &nbsp;
                  <Highlight search={searchKeyword.value}>
                    {row.capacity_volume_unit ? row.capacity_volume_unit : "0"}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={searchKeyword.value}>
                    {row.capacity_weight ? row.capacity_weight.toString() : "0"}
                  </Highlight>
                  &nbsp;
                  <Highlight search={searchKeyword.value}>
                    {row.capacity_weight_unit ? row.capacity_weight_unit : "0"}
                  </Highlight>
                </TableCell>
                <TableCell>
                  <Highlight search={searchKeyword.value}>
                    {row.number_of_vehicles
                      ? row.number_of_vehicles.toString()
                      : "0"}
                  </Highlight>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    aria-label="view"
                    onClick={() => {
                      setSearchKeyword({ ...searchKeyword, value: "" });
                      AddLogs("Admin - Vehicle Types", "view", row.name);
                      history.push({
                        pathname: `/admin/resources/vehicle_types/view/${row.id}`,
                        state: {
                          params: {
                            id: row.id,
                            moduleName:
                              process.env.REACT_APP_VEHICLE_TYPES_MODULE
                          },
                          queryVars: getVariables()
                        }
                      });
                    }}
                  >
                    <VisibleIcon />
                  </IconButton>
                  {user.user_level_id === process.env.REACT_APP_WTI_ADMIN ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleToDelete(row.id);
                        handleOpen();
                        setKeyDel(row.name);
                      }}
                      disabled={!canEdit}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : user.client.id === row.client_id ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleToDelete(row.id);
                        handleOpen();
                        setKeyDel(row.name);
                      }}
                      disabled={!canEdit}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                  { }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={6} align="center">
              No data found
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  useEffect(() => {
    setLoading(false);
  });

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [
      [
        "Vehicle Type *",
        "Volume Capacity",
        "Unit of Volume",
        "Weight Capacity",
        "Unit of Weight",
        "Number of Wheels",
        "Description"
      ]
    ];

    downloadData.vehicle_types.map(row =>
      temp.push([
        row.name || "",
        row.capacity_volume || 0,
        row.capacity_volume_unit || "",
        row.capacity_weight || 0,
        row.capacity_weight_unit || "",
        row.number_of_wheels || 0,
        row.remarks || ""
      ])
    );

    return temp;
  };

  const validateColumn = temp => {
    const result = {
      data: temp,
      errors: {}
    };

    if (!temp.name) {
      result.errors.name = "Vehicle Type is required";
    } else if (validate("noSpecialChar", temp.name)) {
      result.errors.name = "No Special Characters Allowed";
    }

    if (Number.isNaN(temp.capacity_volume)) {
      if (validate("number", temp.capacity_volume)) {
        result.errors.capacity_volume = "Numeric input only";
      }

      if (validate("noSpecialChar", temp.capacity_volume)) {
        result.errors.capacity_volume = "No Special Characters Allowed";
      }
    } else if (
      Math.round((temp.capacity_volume + Number.EPSILON) * 100) / 100 >
      99999.99
    ) {
      result.errors.capacity_volume = "Exceeded maximum value: 99,999.99";
    } else if (temp.capacity_volume === "") {
      result.data.capacity_volume = 0
    }

    if (temp.capacity_volume_unit) {
      const arrayVUnit = ["CBM", "Liters"];
      if (!arrayVUnit.includes(temp.capacity_volume_unit)) {
        result.errors.capacity_volume_unit = `Only "CBM" and "Liters" are accepted`
      }

      if (validate("noSpecialChar", temp.capacity_volume_unit)) {
        result.errors.capacity_volume_unit = "No Special Characters Allowed";
      }
    }

    if (Number.isNaN(temp.capacity_weight)) {
      if (validate("number", temp.capacity_weight)) {
        result.errors.capacity_weight = "Numeric input only";
      }

      if (validate("noSpecialChar", temp.capacity_weight)) {
        result.errors.capacity_weight = "No Special Characters Allowed";
      }
    } else if (
      Math.round((temp.capacity_weight + Number.EPSILON) * 100) / 100 >
      99999.99
    ) {
      result.errors.capacity_weight = "Exceeded maximum value: 99,999.99";
    } else if (temp.capacity_weight === "") {
      result.data.capacity_weight = 0
    }
    if (temp.capacity_weight_unit) {
      const arrayWUnit = ["Kg", "Tons"];
      if (!arrayWUnit.includes(temp.capacity_weight_unit)) {
        result.errors.capacity_weight_unit = `Only "Kg" and "Tons" are accepted`
      }

      if (validate("noSpecialChar", temp.capacity_weight_unit)) {
        result.errors.capacity_weight_unit = "No Special Characters Allowed";
      }
    }
    if (temp.number_of_wheels) {
      const arrayWheel = [
        2, 3, 4,
        6, 8, 10,
        12, 14, 16,
        18, 20, 24,
        26, 28, 34
      ];
      if (!arrayWheel.includes(temp.number_of_wheels)) {
        result.errors.number_of_wheels = `Only 2, 3, 4, 6, 8,  10, 12, 14, 16, 18, 20, 24, 26, 28, 34 is accepted`;
      }
    } else if (temp.number_of_wheels === "") {
      result.data.number_of_wheels = 0
    }
    if (validate("noSpecialChar", temp.remarks)) {
      result.errors.remarks = "No Special Characters Allowed";
    }
    return result;
  }

  const [addVehicleTypes] = useMutation(UPLOAD_VEHICLE_TYPES, {
    onCompleted: () => {
      AddLogs("Admin - Vehicle Types", "upload", "");
    },
    refetchQueries: () => {
      return [
        {
          query: GET_ADMIN_VEHICLE_TYPES,
          variables: getVariables()
        }
      ];
    }
  });

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          data={tableData}
          style={{ textDecoration: "none", color: "black" }}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Vehicle Types", "download_template", "")
                    }
                  >
                    <Link
                      to="/assets/vehicle_types_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Download Template
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Vehicle Types", "download_table", "")
                    }
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  if (loadingData || loading) return <Loading />;
  return (
    <AccessControl
      resource="/admin/resources/vehicle_types/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              Vehicle Types
              {/* <TextField
                  select
                  style={{ marginLeft: 50, width: 200 }}
                  className={classes.field}
                  // variant="outlined"
                  InputProps={{
                    style: {
                      height: 30
                    }
                  }}
                  value={groupFilter.index}
                  onChange={async e => {
                    setLoading(true);
                    await setGroupFilter({
                      ...groupFilter,
                      value: user.group_ids[e.target.value],
                      name: user.group_names[e.target.value],
                      index: e.target.value
                    });
                    setSelectedGroup(Number(user.group_ids[e.target.value]));
                  }}
                >
                  {
                  // (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) ? 
                  // groupsDatum.map(option => (
                  //   <MenuItem value={option.id} key={option.id}>
                  //     {option.name}
                  //   </MenuItem>
                  // )) : 
                    user.group_names.map((option, index) => (
                      <MenuItem value={index} key={option}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </TextField> */}
            </Typography>
            {(user.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
              <Autocomplete
                className={classes.autoComplete}
                disableCloseOnSelect
                style={{ marginLeft: 10, width: 200 }}
                options={groupsData?.get_groups.groups || []}
                getOptionSelected={(o, v) => {
                  if (v.name === o.name) return o.name;
                  return {};
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="All"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {groupsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                    onChange={async e => {
                      getDropdownGroup({
                        variables: {
                          first: 5,
                          keyword: e.target.value
                        }
                      });
                    }}
                  />
                )}
                loading={groupsLoading}
                disableClearable
                value={groupFilter}
                open={openGroupDropdown}
                onOpen={() => {
                  setOpenGroupDropdown(true);
                  getDropdownGroup({
                    variables: {
                      first: 5
                    }
                  });
                }}
                onClose={() => setOpenGroupDropdown(false)}
                onChange={async (e, nv) => {
                  await setGroupFilter({
                    ...groupFilter,
                    value: nv.id,
                    name: nv.name,
                    index: nv.id
                  });
                  refetch(getVariables());
                  setSelectedGroup(Number(nv.id));
                  setOpenGroupDropdown(false);
                  setPage(1);
                }}
              />
            )) || (
                <TextField
                  select
                  style={{ marginLeft: 50, width: 200 }}
                  className={classes.field}
                  // variant="outlined"
                  InputProps={{
                    style: {
                      height: 30
                    }
                  }}
                  value={groupFilter.index}
                  onChange={async e => {
                    setLoading(true);
                    await setGroupFilter({
                      ...groupFilter,
                      value: user.group_ids[e.target.value],
                      name: user.group_names[e.target.value],
                      index: e.target.value
                    });
                    setSelectedGroup(Number(user.group_ids[e.target.value]));
                  }}
                >
                  {user.group_names.map((option, index) => (
                    <MenuItem value={index} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            <div
              style={{ display: "flex", position: "absolute", right: "10px" }}
            >
              <Button
                className={classes.btn_download}
                // style={{ backgroundColor: "#ff8364" }}
                variant="contained"
                startIcon={<GetAppIcon />}
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  handleToggle();
                }}
              >
                Download
              </Button>
              {canEdit && (
                <Button
                  className={classes.btn_upload}
                  variant="contained"
                  startIcon={<PublishIcon />}
                  onClick={() => setUploadOpen(true)}
                >
                  Upload
                </Button>
              )}
              <Pagination
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                dataLength={vehicleTypeData.length}
                fetchMoreData={fetchMoreData}
                totalRows={totalRows}
                totalPage={Math.ceil(totalRows / rowsPerPage)}
                refetch={refetch}
                getVariables={getVariables}
                setRowsPerPage={setRowsPerPage}
                // setCircularLoading={setCircularLoading}
                setLoading={setLoading}
              />
            </div>
          </Toolbar>
          <Toolbar
            className={clsx(classes.toolbar_sub_root, {
              [classes.highlight]: selected.length > 0,
              [classes.invisible]: selected.length <= 0
            })}
          >
            {selected.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} vehicle type/s selected on this page
              </Typography>
            )}

            {selected.length > 0 &&
              (canEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    position: "absolute",
                    right: 0,
                    borderRadius: 36,
                    color: "white"
                  }}
                  onClick={() => {
                    handleOpenDeleteAll();
                  }}
                >
                  Delete Selected
                </Button>
              ) : // <Tooltip title="Delete">
                //   <IconButton
                //     aria-label="delete"
                //     onClick={() => {
                //       handleOpenDeleteAll();
                //     }}
                //   >
                //     <DeleteIcon />
                //   </IconButton>
                // </Tooltip>
                null)}
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeadCells
                canEdit={canEdit}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleSort={handleSort}
                isChecked={isChecked}
              />
              <VehicleTypeTable
                canEdit={canEdit}
                selected={selected}
                vehicleTypeData={vehicleTypeData}
                classes={classes}
                rowCount={dataCount}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>
        </Paper>
        <Upload
          columns={columns}
          open={uploadOpen}
          onDialogClose={() => setUploadOpen(false)}
          validateTemplate={(col1, col2) => {
            return (
              col1.join(",") === col2.join(",") &&
              col1.length > 0 &&
              col2.length > 0
            );
          }}
          fileRules={{
            minLength: 1,
            maxLength: 1002
          }}
          validate={columnValues => {
            return validateColumn(columnValues.data);
          }}
          validForOverride={false}
          onSubmit={async ({ validRowsObject }) => {
            if (validRowsObject.length <= 0) return { errors: [] };

            const payload = validRowsObject.map(({ data }) => ({
              ...data,
              groups: {
                id: user.group_ids[0],
                name: user.group_names[0]
              },
              client_id: Number(user.client.id),
            }));

            const response = await addVehicleTypes({
              variables: {
                vehicle_types: payload
              }
            });

            const { data } = response;
            const { add_vehicle_types } = data;
            const errors = add_vehicle_types.reduce((accum, current, index) => {
              current.error.forEach(error => {
                const { field, message } = error;
                const { row, data: payloadData } = validRowsObject[index];

                accum.push({
                  row,
                  field,
                  message,
                  data: payloadData,
                  override: false,
                  success: false
                })
              })

              return accum;
            }, []);

            return {
              errors
            };

          }}
        />
        {/* <Upload
          group={groupFilter}
          open={uploadOpen}
          setUploadOpen={setUploadOpen}
          getQueryConditions={getVariables}
        /> */}
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            style={{
              margin: 0,
              top: "auto",
              right: 40,
              bottom: 30,
              left: "auto",
              position: "fixed",
              fontSize: "200px"
            }}
            onClick={() => {
              history.push({
                pathname: "/admin/resources/vehicle_types/add",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_VEHICLE_TYPES_MODULE
                  },
                  queryVars: getVariables()
                }
              });
            }}
          >
            <AddIcon
              style={{
                width: "50px",
                height: "50px"
              }}
              onClick={() => setSearchKeyword({ ...searchKeyword, value: "" })}
            />
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this vehicle type?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteAlert(e)}
          title="Delete?"
          content="Are you sure you want to delete these vehicle types?"
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}

        <Dialog aria-labelledby="simple-dialog-title" open={mddopen}>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <h2 style={{ color: "red", fontWeight: "bold" }}>Warning !</h2>
              Cannot delete. This Vehicle Type is currently in used.
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Deleted:{" "}
              <span style={{ color: "green" }}>{deleteRes.deleted}</span>
              <br />
              Not Deleted:{" "}
              <span style={{ color: "orange" }}>
                {deleteRes.total - deleteRes.deleted}
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ borderRadius: "17px", margin: 10, width: 100 }}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </AccessControl>
  );
}
