/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react";
import { withRouter, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Swal from "sweetalert2";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Badge,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  // DialogContentText,
  // MenuItem,
  Box,
  Avatar,
  Toolbar,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";
import moment from "moment";
import {
  Assignment,
  Timer,
  TripOrigin,
  Visibility,
  History,
  Share as ShareIcon,
  BuildRounded as UnderRepairIcon,
  SettingsApplications as Settings,
  MoreVert as MoreVertIcon,
  VideocamRounded as VideocamRoundedIcon
} from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";
import Cookie from "js-cookie";
import _, { head, isNull } from "lodash";
import { useLazyQuery } from "@apollo/client";
import {
  MuiPickersUtilsProvider,
  // DatePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import MomentUtils from "@date-io/moment";
import { gpsStatus } from "../Utils";
import temp from "../../assets/temp.svg";
import { GET_BOOKINGS } from "../../graphql/Queries";
import Battery from "../../utils/Battery";
import AddLogs from "../../utils/functions/AddLogs";
import not_in_use from "../../assets/Not_in_Use.png";
import useAllowedPermissions from "../../utils/hooks/useAllowedPermissions";
import HistorySelector from "./HistorySelector";
import SearchContext from "../../context/SearchContext";
import SharelinkDialog from "../../utils/modals/SharelinkDialog";
import ShareLocation from "../../utils/modals/ShareLocation";
import VssCamera from "./Camera";
import NewWindow from "../../utils/UI/NewWindow";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  root: {
    width: "100%"
    // marginTop: 64
  },
  tableCell: {
    padding: "8px !important"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  button: {
    borderRadius: "32px !important",
    color: "white !important",
    backgroundColor: "#EB825C !important"
  },
  buttonDisabled: {
    borderRadius: "32px !important",
    color: "white !important",
    backgroundColor: "#F3C0AE !important",
    cursor: "default !important",
    pointerEvents: "none !important"
  },
  container: {
    maxHeight: "calc(100vh - 128px)"
  },
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500]
  },
  undeRepairSize: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: "white"
  },
  multipleSelect: {
    backgroundColor: "#EAEAEA",
    minHeight: "45px !important",
    height: 45
  },
  title: {
    flex: "1 1 25%",
    color: "black"
    // color: "#000000"
  },
  iconActive: {
    color: theme.palette.primary.main
  },
  iconInactive: {
    color: "gray"
  },
  modal_confirm_button: {
    float: "right",
    background: theme.palette.primary.main,
    color: "white",
    border: "none",
    position: "absolute",
    right: "10px",
    padding: "5px 25px",
    borderRadius: "100px",
    cursor: "pointer",
    fontWeight: "bold"
  },
  optionsItem: {
    fontSize: 12,
    color: theme.palette.primary.dark
  },
  optionsIcon: {
    fontSize: 18,
    color: "#878787",
    marginRight: 10
  },
  shareLocationBtn: {
    textTransform: "none",
    opacity: "1",
    borderRadius: 30,
    minWidth: 156,
    margin: 10,
    fontSize: 14,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    }
  },
  shareLocationIcon: {
    fontSize: 17,
    color: "#FFFFFF",
    marginRight: 10
  }
}));

const headCells = [
  { id: "trip_count", numeric: false, disablePadding: true, label: "" },
  {
    id: "devices.device_config.underrepair",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: false
  },
  { id: "clients.name", numeric: false, disablePadding: true, label: "Hauler" },
  {
    id: "vehicle_info.plateno",
    numeric: true,
    disablePadding: false,
    label: "Plate/CV Number"
  },
  {
    id: "vehicle_types.name",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Type"
  },
  { id: "speed", numeric: true, disablePadding: false, label: "" },
  {
    id: "lastlocation",
    numeric: false,
    disablePadding: true,
    label: "Location"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Action" }
];

const EnhancedTableHead = props => {
  const {
    classes,
    orderBy,
    order,
    handleSorting,
    isChecked,
    handleSelectAllVehicle
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={handleSelectAllVehicle}
            color="primary"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            padding={headCell.disablePadding ? "none" : "normal"}
            key={headCell.id}
            className={classes.tableCell}
          >
            {headCell.label !== "" && headCell.label !== "Action" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSorting(order, headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const MonitoringTable = props => {
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));
  const clientType = user?.client.is_restricted;
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const {
    vehicles,
    history,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    filterValues,
    vars
  } = props;
  const contextData = useContext(SearchContext);
  const [dateOpen, setDateOpen] = useState(false);
  const [activeVId, setActiveVId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [tripNumbers, setTripNumbers] = useState([]);
  const [activeTripNumber, setActiveTripNumber] = useState(null);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [shareDialogDetails, setShareDialogDetails] = useState({
    isOpen: false,
    vehicle: {}
  });
  const [generatedLink, setGeneratedLink] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const vehicleRowRef = useRef({});
  const open = Boolean(anchorEl);
  const [isOpenVSS, setIsOpenVSS] = useState(false);
  const close = () => setIsOpenVSS(false);
  const features =
    "menubar=yes,loaction=yes,resizaable=no,scrollbars=yes,status=no,noopener";
  const [multiShareLocation, setMultiShareLocation] = useState(false);
  const [queryTripNumbers, { loading }] = useLazyQuery(GET_BOOKINGS, {
    onCompleted: data => {
      if (data) {
        const trip_numbers = data.get_bookings.bookings
          // .filter(b => b.ordinal === 1)
          .filter(t => {
            if (t.status_code_id === process.env.REACT_APP_INCOMING_STATUS) {
              if (t.data_complete) {
                // return true;
                return (
                  t.status_code_id !== process.env.REACT_APP_ACCEPTED_STATUS ||
                  moment(t.pickups[0].arrival).isBefore(
                    moment().add(2, "hours")
                  )
                );
              }
              return false;
            }

            return (
              t.status_code_id !== process.env.REACT_APP_ACCEPTED_STATUS ||
              moment(t.pickups[0].arrival).isBefore(moment().add(2, "hours"))
            );
          })
          .map(t => t.trip_number);

        // setActiveTripNumber(
        //   _.uniq(trip_numbers).length > 0 ? _.uniq(trip_numbers)[0] : null
        // );

        setTripNumbers(_.uniq(trip_numbers));
      }
    },
    fetchPolicy: "network-only"
  });

  const { permissions } = useAllowedPermissions("/admin/resources/vehicles/");

  const [historyFilterVal, setHistoryFilterVal] = useState({
    dayType: 0,
    from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
    to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
    tripNumber: null,
    isValid: true,
    vehicleClientId: null,
    activeVehicle: null
  });

  const redirectTo = (r, params = {}) => {
    history.push({
      pathname: r,
      state: {
        params: params,
        filterValues: filterValues,
        vars: vars,
        historyFilterVal: params.historyFilterVal
      }
    });
  };

  const noLastLocationPromt = (d = "", errHead) => {
    Swal.fire({
      html: `<p class='custom-title'>${errHead}</p>
        <p class='custom-subtitle'><small>No location report received from this vehicle yet</small></p>
        ${d ? `<p class='custom-subtitle'><small>${d}.</small></p>` : ""}`,
      // icon: "success",
      showConfirmButton: true,
      width: "28rem",
      buttonsStyling: false,
      focusConfirm: false,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: `${classes.modal_confirm_button}`,
        container: "custom-container"
      }
    });
  };
  //isolate handler function
  function isolateHandler() {
    let message = "Isolate Mode Unavailable";
    if (
      !vehicleRowRef.current.has_gps?.device_status?.latitude ||
      !vehicleRowRef.current.has_gps?.device_status?.longitude
    ) {
      noLastLocationPromt(_, message);
      return;
    }
    // add logs
    AddLogs(
      process.env.REACT_APP_FLEET_MODULE,
      "isolate_view",
      vehicleRowRef.current.plateno
    );
    //
    let vehicleIds = JSON.stringify([vehicleRowRef.current.id]);
    window.open(
      `/monitoring/:view/isolate?vehicle_id=${vehicleIds}`,
      "_blank",
      features
    );
  }
  // view history handler function
  function viewHistoryHandler() {
    setHistoryFilterVal(prev => ({
      ...prev,
      isValid:
        _.isEqual(user.client.id, vehicleRowRef.current.client_id) ||
        isWTIAdmin,
      vehicleClientId: vehicleRowRef.current.client_id,
      activeVehicle: {
        id: vehicleRowRef.current.id,
        device_id: vehicleRowRef.current.device_id
      },
      plateno: vehicleRowRef.current.plateno
    }));
    setActiveVId(vehicleRowRef.current);
    setDateOpen(true);
  }
  // vehicle setting handler function
  function vehicleSettingsHandler() {
    AddLogs("Admin - Vehicles/CV", "view", vehicleRowRef.current.plateno);
  }
  function shareLinkHandler() {
    shareModalHandler(vehicleRowRef.current);
  }

  // kebab item options.
  // this will provide all the options needed for the different action that will be available on the monitoring table.
  // provided the different object entities on each array object value for different cases.
  const options = [
    {
      label: "Isolate",
      icon: <Visibility className={classes.optionsIcon} />,
      isDisabled: _.isEqual(vehicleRowRef.current.device_id, "0"),
      isolateShown: vehicleRowRef.current
        ? _.isEqual(user.client.id, vehicleRowRef.current.client_id) ||
          isWTIAdmin ||
          (!_.isEqual(user.client.id, vehicleRowRef.current.client_id) &&
            !clientType)
        : true,
      fnHandler: isolateHandler
    },
    {
      label: "Vehicle Settings",
      icon: <Settings className={classes.optionsIcon} />,
      hasAccess: permissions.view,
      fnHandler: vehicleSettingsHandler,
      hasLink: {
        path: `/admin/resources/vehicles/view/${vehicleRowRef.current.id}`,
        stateValue: {
          source: `/monitoring`,
          filterValues: filterValues,
          vars: vars
        }
      }
    },
    {
      label: "View History",
      icon: <History className={classes.optionsIcon} />,
      isDisabled: _.isEqual(vehicleRowRef.current.device_id, "0"),
      fnHandler: viewHistoryHandler
    },
    {
      label: "Share Location",
      icon: <ShareIcon className={classes.optionsIcon} />,
      isDisabled: _.isEqual(vehicleRowRef.current.device_id, "0"),
      fnHandler: shareLinkHandler
    },
    {
      label: "View Live Camera",
      icon: <VideocamRoundedIcon className={classes.optionsIcon} />,
      isDisabled: !vehicleRowRef?.current?.has_vss_camera,
      fnHandler: () =>
        window.open(
          `/monitoring/live-camera/${vehicleRowRef?.current?.has_gps?.name}/${vehicleRowRef?.current?.channel_count}`,
          "_blank",
          `width=1000,height=700,left=${window.screen.availWidth / 2 -
            200},top=${window.screen.availHeight / 2 - 150}`
        )
    }
  ];
  // this will display all the actions on the monitoring table.
  const muiMenu = (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => {
        setAnchorEl(null);
        vehicleRowRef.current = {};
      }}
      PaperProps={{
        style: {
          width: "20ch",
          boxShadow: "0px 6px 16px -2px rgba(140,140,140,0.75)"
        }
      }}
    >
      {options.map((option, index) => {
        switch (option.label) {
          case "Isolate": {
            return (
              <MenuItem
                onClick={option.fnHandler}
                disabled={option.isDisabled}
                key={index}
                style={{ display: option.isolateShown ? "flex" : "none" }}
              >
                {option.icon}
                <Typography className={classes.optionsItem}>
                  {option.label}
                </Typography>
              </MenuItem>
            );
          }
          case "Vehicle Settings": {
            return (
              <>
                {option.hasAccess ? (
                  <MenuItem onClick={option.fnHandler} key={index}>
                    <Link
                      to={{
                        pathname: option.hasLink.path,
                        state: option.hasLink.stateValue
                      }}
                      style={{ textDecoration: "none", display: "flex" }}
                    >
                      {option.icon}
                      <Typography className={classes.optionsItem}>
                        {option.label}
                      </Typography>
                    </Link>
                  </MenuItem>
                ) : (
                  ""
                )}
              </>
            );
          }
          case "View History": {
            return (
              <MenuItem
                onClick={option.fnHandler}
                disabled={option.isDisabled}
                key={index}
              >
                {option.icon}
                <Typography className={classes.optionsItem}>
                  {option.label}
                </Typography>
              </MenuItem>
            );
          }
          case "Share Location": {
            return (
              <MenuItem
                onClick={option.fnHandler}
                disabled={option.isDisabled}
                key={index}
                style={{ display: "flex" }}
              >
                {option.icon}
                <Typography className={classes.optionsItem}>
                  {option.label}
                </Typography>
              </MenuItem>
            );
          }
          case "View Live Camera": {
            return (
              <>
                {!option.isDisabled ? (
                  <MenuItem
                    onClick={() =>
                      option.fnHandler(vehicleRowRef?.current?.has_gps?.name)
                    }
                    disabled={option.isDisabled}
                    key={index}
                    style={{ display: "flex" }}
                  >
                    {option.icon}
                    <Typography className={classes.optionsItem}>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ) : (
                  ""
                )}
              </>
            );
          }
          default: // do nothing
        }
      })}
    </Menu>
  );

  const isSelected = name => {
    const tempSelectedIds = selectedVehicles.map(n => n.device_id);
    return tempSelectedIds.indexOf(name) !== -1;
    // return selectedVehicles.indexOf(name) !== -1
  };
  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedVehicles.length) {
      if (!selectedVehicles.length < vehicles?.length) {
        const displayedData = vehicles.map(data => data.device_id);
        const tempSelectedVehicle = selectedVehicles.map(
          vehicle => vehicle.device_id
        );
        if (displayedData.every(id => tempSelectedVehicle.indexOf(id) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(id => tempSelectedVehicle.indexOf(id) > -1))
          checked = { all: false, indeterminate: true };
      }
    }
    return checked;
  };

  const handleSelectAllVehicle = event => {
    // const tempSelected = selectedVehicles;
    const tempSelected = selectedVehicles.map(vehicle => vehicle);
    const deselect = () => {
      const displayedData = vehicles.filter(n => {
        return n;
      });
      // .map(n => n.device_id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map((id, index) => selectedVehicles[index]);
      // .map(id => {
      //   return id;
      // });
      setSelectedVehicles(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }

      vehicles
        .filter(n => {
          if (
            n.device_id !== "0" &&
            (user.client.id === n.client_id ||
              isWTIAdmin ||
              (user.client.id !== n.client_id && !clientType))
          ) {
            // return n.device_id;
            return n;
          }
        })
        .map(n => tempSelected.push(n));
      // .map(n => tempSelected.push(n.device_id));
      // setSelectedVehicles(_.uniq(tempSelected));
      setSelectedVehicles(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleClick = (event, vehicle) => {
    // const selectedIndex = selectedVehicles.indexOf(id);
    const tempId = selectedVehicles.map(n => n.device_id);
    const selectedIndex = tempId.indexOf(vehicle.device_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      // newSelected = newSelected.concat(selectedVehicles, id);
      newSelected = newSelected.concat(selectedVehicles, vehicle);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedVehicles.slice(1));
    } else if (selectedIndex === selectedVehicles.length - 1) {
      newSelected = newSelected.concat(selectedVehicles.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedVehicles.slice(0, selectedIndex),
        selectedVehicles.slice(selectedIndex + 1)
      );
    }
    setSelectedVehicles(newSelected);
  };
  // const dateChangeHandler = date => {
  //   const d = moment(date).format("YYYY-MM-DD");
  //   const { id, device_id } = activeVId;
  //   redirectTo("/monitoring/history", {
  //     vehicle_id id,
  //     device_id: device_id,
  //     date: d
  //   });
  // };

  const handleSorting = (o, oBy) => {
    const activeColumn = oBy;
    let dir = "asc";
    if (oBy === orderBy) {
      dir = o === "asc" ? "desc" : "asc";
    }
    setOrder(dir);
    setOrderBy(activeColumn);
  };

  const { setSearchKeyword, searchKeyword } = contextData;
  /*
   *** share link function for opening and closing modal share.
   */
  function shareModalHandler(vehicleInfo) {
    // prompt if no latitude and longitude value.
    if (
      !vehicleInfo.has_gps.device_status?.latitude ||
      !vehicleInfo.has_gps.device_status?.longitude
    ) {
      const err = "Share Link Unavailable";
      noLastLocationPromt(_, err);
      return;
    }
    setShareDialogDetails({
      isOpen: !shareDialogDetails.isOpen,
      vehicle: vehicleInfo
    });
  }
  /*
   *** generate the link for the user with the specific vehicle redirected to google maps.
   */
  function generateShareLinkHandler() {
    if (shareDialogDetails.vehicle.has_gps) {
      const {
        latitude,
        longitude
      } = shareDialogDetails.vehicle.has_gps.device_status;
      setGeneratedLink(
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      );
    }
  }

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
  }, []);

  // const maxDate = date => {
  //   const dateToday = moment(new Date());
  //   const dateSelected = moment(date);
  //   const diff = dateToday.diff(dateSelected, "days");
  //   if (diff === 0 || diff === 1) {
  //     return dateToday.format("YYYY-MM-DD");
  //   }

  //   const max = dateSelected.add(13, "days");
  //   return max.format("YYYY-MM-DD");
  // };

  // const refetchTripNumbers = (dateFrom, dateTo) => {
  //   setTripNumbers([]);
  //   queryTripNumbers({
  //     variables: {
  //       first: 500,
  //       dateRange: {
  //         start: dateFrom,
  //         end: dateTo,
  //         field: "pickups.arrival"
  //       },
  //       condition: [
  //         {
  //           field: "vehicle_id",
  //           value: historyFilterVal.activeVehicle.device_id.toString()
  //         }
  //       ],
  //       not: [
  //         {
  //           field: "status_code_id",
  //           value: `${process.env.REACT_APP_ACCEPTED_STATUS}`
  //         },
  //         {
  //           field: "status_code_id",
  //           value: `${process.env.REACT_APP_SCHEDULED_STATUS}`
  //         }
  //       ]
  //     }
  //   });
  // };
  // const handleDaySelectorChanged = event => {
  //   const { name, value } = event.target;
  //   if (name === "dayType") {
  //     if (value === 0) {
  //       const date1 = `${moment().format("YYYY-MM-DD")} 00:00:00`;
  //       const date2 = `${moment().format("YYYY-MM-DD")} 23:59:59`;
  //       setHistoryFilterVal(prev => ({
  //         ...prev,
  //         dayType: value,
  //         from: date1,
  //         to: date2,
  //         isValid:
  //           user.client.id === prev.vehicleClientId || isWTIAdmin
  //             ? true
  //             : !isNull(prev.tripNumber)
  //       }));
  //       refetchTripNumbers(date1, date2);
  //     } else if (value === 1) {
  //       setHistoryFilterVal(prev => ({
  //         ...prev,
  //         dayType: value,
  //         isValid:
  //           user.client.id === prev.vehicleClientId || isWTIAdmin
  //             ? true
  //             : !isNull(prev.tripNumber)
  //       }));
  //       // setTripNumbers([]);
  //       refetchTripNumbers(historyFilterVal.from, historyFilterVal.to);
  //     } else {
  //       const date1 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  //       const date2 = moment(date1, "YYYY-MM-DD HH:mm:ss").subtract({
  //         hours: value
  //       });
  //       setHistoryFilterVal(prev => ({
  //         ...prev,
  //         dayType: value,
  //         from: `${date2.format("YYYY-MM-DD HH:mm:ss")}`,
  //         to: `${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}`,
  //         isValid:
  //           user.client.id === prev.vehicleClientId || isWTIAdmin
  //             ? true
  //             : !isNull(prev.tripNumber)
  //       }));
  //       refetchTripNumbers(date2.format("YYYY-MM-DD HH:mm:ss"), date1);
  //     }
  //   } else {
  //     // case 'Custom'
  //     let date1 = moment(historyFilterVal.from);
  //     let date2 = moment(historyFilterVal.to);
  //     if (name === "from") {
  //       date1 = moment(value);
  //     } else {
  //       date2 = moment(value);
  //     }
  //     const diff = date1.diff(date2, "days");
  //     const isValidDateRange = diff < 1 && diff > -14;
  //     const newDateFrom = `${moment(date1).format("YYYY-MM-DD")} 00:00:00`;
  //     const newDateTo = `${moment(date2).format("YYYY-MM-DD")} 23:59:59`;
  //     setHistoryFilterVal(prev => ({
  //       ...prev,
  //       from: newDateFrom,
  //       to: newDateTo,
  //       isValid:
  //         user.client.id === prev.vehicleClientId || isWTIAdmin
  //           ? isValidDateRange
  //           : isValidDateRange && prev.tripNumber
  //     }));
  //     refetchTripNumbers(newDateFrom, newDateTo);
  //   }
  // };

  return (
    <div className={classes.root}>
      <div style={{ display: "none" }}>
        {/* <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            onChange={dateChangeHandler}
            open={dateOpen}
            onClose={() => {
              setDateOpen(false);
              setActiveVId(null);
            }}
            orientation="landscape"
            disableFuture
          />
        </MuiPickersUtilsProvider> */}
        <HistorySelector
          open={dateOpen}
          vehicle={activeVId}
          onClose={() => {
            setDateOpen(false);
            setActiveVId(null);
            setHistoryFilterVal({
              dayType: 0,
              from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
              to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
              tripNumber: null,
              isValid: true,
              vehicleClientId: null
            });
          }}
          title="History"
          redirectTo={redirectTo}
        />
        {/* <Dialog
          open={dateOpen}
          onClose={() => {
            setDateOpen(false);
            setActiveVId(null);
            setHistoryFilterVal({
              dayType: 0,
              from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
              to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
              tripNumber: null,
              isValid: true,
              vehicleClientId: null
            });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="xs"
          style={{
            width: 350,
            margin: "0 auto"
          }}
        >
          <DialogTitle id="form-dialog-title">History</DialogTitle>
          <DialogContent>
            <TextField
              InputLabelProps={{
                required: true,
                shrink: true,
                style: {
                  color: "black",
                  fontWeight: "bold"
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Date"
              placeholder="Select"
              type="email"
              fullWidth
              value={historyFilterVal.dayType}
              name="dayType"
              select
              onChange={e => handleDaySelectorChanged(e)}
            >
              {[
                { value: 0, label: "Today" },
                { value: 4, label: "4 hours" },
                { value: 8, label: "8 hours" },
                { value: 12, label: "12 hours" },
                { value: 24, label: "24 hours" },
                { value: 1, label: "Custom" }
              ].map(opt => (
                <MenuItem value={opt.value} key={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>
            {historyFilterVal.dayType === 1 ? (
              <>
                <TextField
                  // defaultValue={moment(historyFilterVal.from).format(
                  //   "YYYY-MM-DD"
                  // )}
                  value={moment(historyFilterVal.from).format("YYYY-MM-DD")}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold"
                    }
                  }}
                  inputProps={{
                    max: moment(new Date()).format("YYYY-MM-DD")
                  }}
                  autoFocus
                  margin="dense"
                  name="from"
                  label="From"
                  placeholder="Select date"
                  type="date"
                  fullWidth
                  onChange={e => handleDaySelectorChanged(e)}
                />
                <TextField
                  // defaultValue={moment(historyFilterVal.to).format(
                  //   "YYYY-MM-DD"
                  // )}
                  value={moment(historyFilterVal.to).format("YYYY-MM-DD")}
                  // value={maxDate(historyFilterVal.from)}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold"
                    }
                  }}
                  inputProps={{
                    min: moment(historyFilterVal.from).format("YYYY-MM-DD"),
                    max: moment
                      .min(
                        moment(maxDate(historyFilterVal.from)),
                        moment(new Date())
                      )
                      .format("YYYY-MM-DD")
                  }}
                  autoFocus
                  margin="dense"
                  name="to"
                  label="To"
                  placeholder="Select date"
                  type="date"
                  fullWidth
                  onChange={e => handleDaySelectorChanged(e)}
                />
              </>
            ) : null}
            <Autocomplete
              options={tripNumbers}
              getOptionLabel={option => option}
              loading={loading}
              noOptionsText="No trips available"
              value={historyFilterVal.tripNumber}
              renderInput={params => (
                <TextField
                  {...params}
                  name="tripNumber"
                  label="Trip Number"
                  InputLabelProps={{
                    required: !(
                      user.client.id === historyFilterVal.vehicleClientId
                    ),
                    shrink: true,
                    style: {
                      color: "black",
                      fontWeight: "bold"
                    }
                  }}
                  margin="normal"
                />
              )}
              name="tripNumber"
              onChange={(event, value) => {
                setActiveTripNumber(value);
                const date1 = moment(historyFilterVal.from);
                const date2 = moment(historyFilterVal.to);
                const diff = date1.diff(date2, "days");

                if (historyFilterVal.dayType === 1) {
                  const isValidDateRange = diff < 1 && diff > -14;
                  setHistoryFilterVal(prev => ({
                    ...prev,
                    tripNumber: value,
                    isValid:
                      user.client.id === prev.vehicleClientId || isWTIAdmin
                        ? isValidDateRange
                        : isValidDateRange && value
                  }));
                } else {
                  setHistoryFilterVal(prev => ({
                    ...prev,
                    tripNumber: value,
                    isValid:
                      user.client.id === prev.vehicleClientId ||
                      value ||
                      isWTIAdmin
                  }));
                }
              }}
            />
            {/* <TextField
              autoFocus
              margin="dense"
              name="tripNumber"
              label="Trip number"
              type="select"
              fullWidth
              onChange={e => }
            /> */}
        {/* </DialogContent>
          <DialogContent>
            <Button
              disabled={!historyFilterVal.isValid}
              onClick={() => {
                if (historyFilterVal.isValid) {
                  const { plateno, from, to } = historyFilterVal;
                  const newLogs = [plateno, from, to];
                  if (activeTripNumber) newLogs.push(activeTripNumber);
                  AddLogs(
                    process.env.REACT_APP_FLEET_MODULE,
                    "history",
                    newLogs
                  );

                  setDateOpen(false);
                  setActiveVId(null);
                  redirectTo("/monitoring/history", {
                    vehicle_id: historyFilterVal.activeVehicle.id,
                    device_id: historyFilterVal.activeVehicle.device_id,
                    // date: moment(activeDate).format("YYYY-MM-DD"),
                    date: {
                      from: historyFilterVal.from,
                      to: historyFilterVal.to,
                      dayType: historyFilterVal.dayType
                    },
                    trip_number: activeTripNumber
                  });
                }
              }}
              style={{
                borderRadius: 15
              }}
              size="small"
              color="primary"
              variant="contained"
              fullWidth
            >
              View
            </Button>
          </DialogContent>
        </Dialog> */}
      </div>
      <Dialog
        open={openDialog}
        onClose={() => {
          setActiveDate(moment());
          setOpenDialog(false);
          setActiveVId(null);
          setActiveTripNumber(null);
        }}
      >
        <DialogTitle>History</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <DatePicker
              label="Pickup Date"
              orientation="landscape"
              disableFuture
              format="ll"
              value={activeDate}
              InputLabelProps={{
                required: true,
                shrink: true,
                style: {
                  color: "black",
                  fontWeight: "bold"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarToday style={{ fontSize: 16 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={date => {
                setActiveDate(moment(date));
                queryTripNumbers({
                  variables: {
                    first: 500,
                    dateRange: {
                      start: `${moment(date).format("YYYY-MM-DD")} 00:00:00`,
                      end: `${moment(date).format("YYYY-MM-DD")} 23:59:59`,
                      field: "pickups.arrival"
                    },
                    condition: [
                      {
                        field: "vehicle_id",
                        value: activeVId.device_id.toString()
                      }
                    ]
                  }
                });
              }}
            /> */}
            <KeyboardDatePicker
              label="Pickup Date"
              orientation="landscape"
              disableFuture
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              value={activeDate}
              InputLabelProps={{
                required: true,
                shrink: true,
                style: {
                  color: "black",
                  fontWeight: "bold"
                }
              }}
              invalidDateMessage="Date is required"
              onChange={date => {
                const chosenDate = moment(date);
                setActiveDate(chosenDate);
                if (chosenDate.isValid()) {
                  queryTripNumbers({
                    variables: {
                      first: 500,
                      dateRange: {
                        start: `${moment(date).format("YYYY-MM-DD")} 00:00:00`,
                        end: `${moment(date).format("YYYY-MM-DD")} 23:59:59`,
                        field: "pickups.arrival"
                      },
                      condition: [
                        {
                          field: "vehicle_id",
                          value: activeVId.device_id.toString()
                        }
                      ]
                    }
                  });
                }
              }}
            />
          </MuiPickersUtilsProvider>
          <Autocomplete
            options={tripNumbers}
            disableClearable
            getOptionLabel={option => option}
            loading={loading}
            noOptionsText="No trips available"
            value={tripNumbers.length > 0 ? tripNumbers[0] : ""}
            renderInput={params => (
              <TextField
                {...params}
                label="Trip Number"
                InputLabelProps={{
                  required: true,
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold"
                  }
                }}
                margin="normal"
              />
            )}
            onChange={(event, value) => setActiveTripNumber(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            disabled={activeTripNumber === null}
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled
            }}
            onClick={() => {
              redirectTo("/monitoring/history", {
                vehicle_id: activeVId.id,
                device_id: activeVId.device_id,
                date: moment(activeDate).format("YYYY-MM-DD"),
                trip_number: activeTripNumber
              });
            }}
          >
            View
          </Button>
        </DialogActions>
      </Dialog>
      {selectedVehicles.length > 0 && (
        <Toolbar className={classes.multipleSelect}>
          <Typography className={classes.title}>
            {/* {selectedVehicles.length > 1 ? "All " : ""} */}
            {selectedVehicles.length} vehicle/s is selected on this page
          </Typography>
          <div className={classes.root} />

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const data = selectedVehicles.reduce((d, vehicle) => {
                if (
                  !vehicle.has_gps.device_status?.latitude ||
                  !vehicle.has_gps.device_status?.longitude
                ) {
                  return [...d, vehicle.plateno];
                }
                return d;
              }, []);

              const plateno = selectedVehicles.map((d, index) => {
                return vehicles[index]?.plateno;
              });

              AddLogs(
                process.env.REACT_APP_FLEET_MODULE,
                "share_location",
                plateno.join(", ")
              );

              if (data.length > 0) {
                const err = "Share Location Unavailable";
                const d = data.join(", ");
                noLastLocationPromt(d, err);
                return;
              } else {
                setMultiShareLocation(true);
                setShareDialogDetails(prevState => {
                  return { ...prevState, isOpen: true };
                });
              }
            }}
            className={classes.shareLocationBtn}
          >
            <ShareIcon className={classes.shareLocationIcon} /> Share Location
          </Button>

          <Button
            color="primary"
            variant="contained"
            style={{ borderRadius: 15, width: 200 }}
            // disabled={ongoingLoading}
            onClick={() => {
              const selectedVehiclesTemp = selectedVehicles.map(
                vehicle => vehicle.device_id
              );
              // const data = selectedVehicles.reduce((d, current) => {
              //   const index = _.findIndex(vehicles, { device_id: current });
              //   if (
              //     !vehicles[index]?.has_gps?.device_status?.latitude ||
              //     !vehicles[index]?.has_gps?.device_status?.longitude
              //   ) {
              //     return [...d, vehicles[index]?.plateno];
              //   }
              //   return d;
              // }, []);

              // if (data.length > 0) {
              //   const d = data.join(", ");
              //   noLastLocationPromt(d);
              //   return;
              // }

              const data = selectedVehicles.reduce((d, vehicle) => {
                if (
                  !vehicle.has_gps.device_status?.latitude ||
                  !vehicle.has_gps.device_status?.longitude
                ) {
                  return [...d, vehicle.plateno];
                }
                return d;
              }, []);

              if (data.length > 0) {
                const err = "Isolate Mode Unavailable";
                const d = data.join(", ");
                noLastLocationPromt(d, err);
                return;
              }

              // const plateno = selectedVehicles.map(d => {
              //   const index = _.findIndex(vehicles, { device_id: d });
              //   return vehicles[index]?.plateno;
              // });

              const plateno = selectedVehicles.map((d, index) => {
                return vehicles[index]?.plateno;
              });

              AddLogs(
                process.env.REACT_APP_FLEET_MODULE,
                "isolate_view",
                plateno.join(", ")
              );

              // redirectTo(`/monitoring/isolate`, {
              //   device_ids: selectedVehiclesTemp,
              //   vehicle_ids: selectedVehicles.map(vehicle => vehicle.id),
              //   state: {
              //     filterValues: filterValues,
              //     vars: vars
              //   }
              //   // plateno: vehicle.plateno
              // });
              let vehicleIds = JSON.stringify(
                selectedVehicles.map(vehicle => vehicle.id)
              );
              window.open(
                `/monitoring/:view/isolate?vehicle_id=${vehicleIds}`,
                "_blank",
                features
              );
            }}
          >
            Isolate View
          </Button>
        </Toolbar>
      )}
      <TableContainer
        component={Paper}
        elevation={2}
        className={classes.container}
      >
        <Table stickyHeader>
          <EnhancedTableHead
            classes={classes}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleSorting={handleSorting}
            isChecked={isChecked}
            handleSelectAllVehicle={handleSelectAllVehicle}
          />
          <TableBody>
            {vehicles.length ? (
              vehicles.map(vehicle => {
                // const isItemSelected = isSelected(vehicle.device_id);
                const isItemSelected = isSelected(vehicle.device_id);
                const title =
                  gpsStatus(vehicle.gps_status).label === "No GPS" ? (
                    <p className={classes.tooltipTitle}>No GPS</p>
                  ) : (
                    <Fragment>
                      <p
                        className={classes.tooltipTitle}
                        style={{ fontWeight: "bold" }}
                      >
                        {gpsStatus(vehicle.gps_status).label}
                      </p>
                      <p
                        className={classes.tooltipTitle}
                      >{`Last Report: ${moment(vehicle.reportstamp).format(
                        "lll"
                      )}`}</p>
                    </Fragment>
                  );
                return (
                  <TableRow key={vehicle.id}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        disabled={
                          vehicle?.device_id === "0" ||
                          !(
                            user.client.id === vehicle.client_id ||
                            isWTIAdmin ||
                            (user.client.id !== vehicle.client_id &&
                              !clientType)
                          )
                        }
                        checked={isItemSelected}
                        onClick={event => handleClick(event, vehicle)}
                        // onClick={event => handleClick(event, vehicle.device_id)}
                        // inputProps={{ "aria-labelledby": labelId }}
                        // disabled={booking.data_completed}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip
                        title={
                          vehicle.trip_count.length > 0
                            ? `${vehicle.trip_count.length} trip/s assigned`
                            : "No assigned trip"
                        }
                      >
                        <Badge
                          variant="dot"
                          color="secondary"
                          invisible={vehicle.trip_count.length === 0}
                        >
                          <Assignment
                            style={{
                              cursor: vehicle.trip_count.length > 0 && "hand"
                            }}
                            className={
                              vehicle.trip_count.length > 0
                                ? classes.iconActive
                                : classes.iconInactive
                            }
                            onClick={() => {
                              if (vehicle.trip_count.length) {
                                redirectTo("/trips", {
                                  trip_numbers: vehicle.trip_count
                                });
                              }
                            }}
                          />
                        </Badge>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {vehicle.under_repair && (
                        <Tooltip title="Under Repair" placement="top">
                          <Avatar className={classes.avatarSize}>
                            <UnderRepairIcon
                              className={classes.undeRepairSize}
                            />
                          </Avatar>
                        </Tooltip>
                      )}
                      {vehicle.not_in_use && (
                        <Tooltip title="Not in use" placement="top">
                          <Avatar src={not_in_use} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.hauler}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={title}>
                        <TripOrigin
                          style={{
                            fontSize: 12,
                            color: gpsStatus(vehicle.gps_status).color
                          }}
                        />
                      </Tooltip>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.plateno}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Highlight search={contextData.searchKeyword.value}>
                        {vehicle.vehicle_type}
                      </Highlight>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Speed">
                        <Timer style={{ fontSize: 12 }} />
                      </Tooltip>
                      {/* {vehicle.speed ? `${vehicle.speed} kph   ` : `-    `} */}
                      <Highlight search={contextData.searchKeyword.value}>
                        {gpsStatus(vehicle.gps_status).label.toLowerCase() !==
                          "active gps" &&
                        gpsStatus(vehicle.gps_status).label.toLowerCase() !==
                          "online gps" &&
                        gpsStatus(vehicle.gps_status).label.toLowerCase() !==
                          "delayed reporting"
                          ? `-   `
                          : `${vehicle.speed} kph   `}
                      </Highlight>
                      <Battery
                        vehicleType={vehicle.vehicle_type}
                        deviceTypeId={vehicle.device_type_id}
                        batteryLevel={vehicle.battery_level}
                        tooltipTitle={
                          <>
                            <p
                              className={classes.tooltipTitle}
                              style={{ fontWeight: "bold" }}
                            >{`Battery Life: ${
                              !isNull(vehicle.battery_level)
                                ? vehicle.battery_level
                                : `-`
                            }%`}</p>
                            <p className={classes.tooltipTitle}>
                              {`As of: ${moment(vehicle.reportstamp).format(
                                "lll"
                              )}
                            `}
                            </p>
                          </>
                        }
                        showText
                      />
                      {vehicle.vehicle_type?.toLowerCase().trim() !==
                        "container van" &&
                        vehicle.hasTemperatureSensor && (
                          <Fragment>
                            <Tooltip title="Temperature">
                              <img src={temp} alt="" width={12} height={12} />
                            </Tooltip>
                            <Highlight search={contextData.searchKeyword.value}>
                              {vehicle.hasTempSensor ? (
                                <span>
                                  {vehicle.temperature || `-`} &#176;C
                                </span>
                              ) : (
                                `-`
                              )}
                            </Highlight>
                          </Fragment>
                        )}
                    </TableCell>
                    <TableCell>
                      <Fragment>
                        <Tooltip title={vehicle.lastlocation || ""}>
                          <div
                            style={{
                              width: "40ch",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <Box
                              component="div"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              bgcolor="background.paper"
                            >
                              <span>
                                <Highlight
                                  search={contextData.searchKeyword.value}
                                >
                                  {vehicle.lastlocation}
                                </Highlight>
                              </span>
                            </Box>
                          </div>
                        </Tooltip>
                      </Fragment>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={event => {
                          setAnchorEl(event.currentTarget);
                          vehicleRowRef.current = vehicle;
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {/* {(user.client.id === vehicle.client_id ||
                        isWTIAdmin ||
                        (user.client.id !== vehicle.client_id &&
                          !clientType)) && (
                        <Tooltip title="Isolate">
                          <span>
                            <IconButton
                              onClick={() => {
                                if (
                                  !vehicle?.has_gps?.device_status?.latitude ||
                                  !vehicle?.has_gps?.device_status?.longitude
                                ) {
                                  noLastLocationPromt(
                                    _,
                                    "Isolate Mode Unavailable"
                                  );
                                  return;
                                }
                                AddLogs(
                                  process.env.REACT_APP_FLEET_MODULE,
                                  "isolate_view",
                                  vehicle.plateno
                                );
                                // redirectTo(`/monitoring/isolate`, {
                                //   device_ids: [vehicle.device_id],
                                //   vehicle_ids: [vehicle?.id],
                                //   state: {
                                //     filterValues: filterValues,
                                //     vars: vars
                                //   }
                                // });
                                let vehicleIds = JSON.stringify([vehicle?.id]);
                                window.open(
                                  `/monitoring/isolate?vehicle_id=${vehicleIds}`,
                                  "_blank",
                                  features
                                );
                              }}
                              disabled={vehicle.device_id === "0"}
                            >
                              <Visibility />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )} */}
                      {/* <Tooltip
                        title={
                          vehicle.device_id === "0" ||
                          _.isEqual(
                            vehicle.has_gps.device_status?.status_code_id,
                            0
                          )
                            ? "No paired device"
                            : "View history"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              setHistoryFilterVal(prev => ({
                                ...prev,
                                isValid:
                                  user.client.id === vehicle.client_id ||
                                  isWTIAdmin,
                                vehicleClientId: vehicle.client_id,
                                activeVehicle: {
                                  id: vehicle.id,
                                  device_id: vehicle.device_id
                                },
                                plateno: vehicle.plateno
                              }));
                              setActiveVId(vehicle);
                              setDateOpen(true);

                              // setActiveVId({
                              //   id: vehicle.id,
                              //   device_id: vehicle.device_id
                              // });

                              // if (user.client.id === vehicle.client_id) {
                              //   setDateOpen(true);
                              // } else {
                              // queryTripNumbers({
                              //   variables: {
                              //     first: 500,
                              //     dateRange: {
                              //       start: `${moment().format(
                              //         "YYYY-MM-DD"
                              //       )} 00:00:00`,
                              //       end: `${moment().format(
                              //         "YYYY-MM-DD"
                              //       )} 23:59:59`,
                              //       field: "pickups.arrival"
                              //     },
                              //     condition: [
                              //       {
                              //         field: "vehicle_id",
                              //         value: vehicle.device_id.toString()
                              //       }
                              //     ],
                              //     not: [
                              //       {
                              //         field: "status_code_id",
                              //         value: `${process.env.REACT_APP_ACCEPTED_STATUS}`
                              //       },
                              //       {
                              //         field: "status_code_id",
                              //         value: `${process.env.REACT_APP_SCHEDULED_STATUS}`
                              //       }
                              //     ]
                              //   }
                              // });
                              //   setOpenDialog(true);
                              // }
                            }}
                            disabled={_.isEqual(vehicle.device_id, "0")}
                          >
                            <History />
                          </IconButton>
                        </span>
                      </Tooltip> */}
                      {/* {permissions.view && (
                        <Tooltip title="Vehicle Settings">
                          <span>
                            <IconButton
                              onClick={() => {
                                AddLogs(
                                  "Admin - Vehicles/CV",
                                  "view",
                                  vehicle.plateno
                                );
                              }}
                            >
                              <Link
                                to={{
                                  pathname: `/admin/resources/vehicles/view/${vehicle.id}`,
                                  state: {
                                    source: `/monitoring`,
                                    filterValues: filterValues,
                                    vars: vars
                                  }
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  height: 24
                                }}
                              >
                                <Settings />
                              </Link>
                            </IconButton>
                          </span>
                        </Tooltip>
                      )} */}
                      {/* <Tooltip
                        title={
                          vehicle.device_id === "0" ||
                          _.isEqual(
                            vehicle.has_gps.device_status?.status_code_id,
                            0
                          )
                            ? "No paired device"
                            : "Share Link"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              shareModalHandler(vehicle)
                              vehicleRowRef.current = vehicle
                            }}
                            disabled={_.isEqual(vehicle.device_id, "0")}
                          >
                            <ShareIcon className={classes.share_icon} />
                          </IconButton>
                        </span>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={20}>
                  No data to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {muiMenu}
      {/* {isOpenVSS && (
        <NewWindow closeFn={close}>
          <VssCamera
            deviceName={vehicleRowRef?.current?.has_gps?.name}
            channelCount={vehicleRowRef?.current?.channel_count}
          />
        </NewWindow>
      )} */}
      {shareDialogDetails.isOpen && (
        <ShareLocation
          title="Share Location"
          description="Generate Link and share the location of your vehicle/s."
          toggle={shareDialogDetails.isOpen}
          closeHandler={() => {
            setShareDialogDetails({ isOpen: false, vehicle: {} });
          }}
          vehicleInfo={
            multiShareLocation ? selectedVehicles : [vehicleRowRef.current]
          }
        />
      )}
      {/* <SharelinkDialog
        toggle={shareDialogDetails.isOpen}
        close={() => {
          setShareDialogDetails({ isOpen: false, vehicle: {} });
          setGeneratedLink("");
        }}
        title="Share Location"
        content="Generate Link and share the location of your vehicle"
        generatedLink={generatedLink}
        fn={generateShareLinkHandler}
      /> */}
    </div>
  );
};

export default withRouter(MonitoringTable);
