import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import _ from "lodash";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";

// constant values.
const PICKUPS_LABEL = "Pickups";
const DROPOFF_LABEL = "Dropoff";
const LOCATION_LABEL = "Location";
const SERVICE_TYPE_LABEL = "Service Type";
const HAULER_LABEL = "Hauler/Shipping Line";
const ACCOUNT_LABEL = "Account";
const VEHICLE_TYPE_LABEL = "Vehicle Type";
const TRIP_TYPE = "Trip Type";

const Summary = props => {
  const { data, tab } = props;
  const {
    pickups,
    account,
    client,
    dateRange,
    dropoffs,
    locations,
    serviceType,
    vehicleType,
    tripType,
  } = data;

  const classes = useStyles();
  // item summary of an array initial values.
  function itemSummaryArr(isShown, intialValues, label) {
    return (
      <>
        {isShown && (
          <Grid item xs={6}>
            <Typography className={classes.item_summary_header}>
              {label}
            </Typography>
            {intialValues.map((value, index) => {
              return (
                <Typography key={index} className={classes.item_summary}>
                  - {value.label}
                </Typography>
              );
            })}
          </Grid>
        )}
      </>
    );
  }
  // item summary for single line initial values.
  function itemSummary(isShown, intialValues, label) {
    return (
      <>
        {isShown && (
          <Grid item xs={6}>
            <Box>
              <Typography className={classes.item_summary_header}>
                {label}
              </Typography>
              <Typography className={classes.item_summary}>
                - {intialValues.label}
              </Typography>
            </Box>
          </Grid>
        )}
      </>
    );
  }

  return (
    <Box>
      <Typography className={classes.summar_container__title}>
        <ListAltRoundedIcon />
        <span>Summary</span>
      </Typography>
      <div className={classes.summary_container}>
        <Grid container spacing={3}>
          {itemSummaryArr(pickups?.length > 0, pickups, PICKUPS_LABEL)}
          {itemSummaryArr(dropoffs?.length > 0, dropoffs, DROPOFF_LABEL)}
          {itemSummaryArr(locations?.length > 0, locations, LOCATION_LABEL)}
          {_.isEqual(tab, 0) &&
            itemSummary(
              serviceType?.value > 0,
              serviceType,
              SERVICE_TYPE_LABEL
            )}
          {_.isEqual(tab, 1) &&
            itemSummary(
              vehicleType?.value > 0,
              vehicleType,
              VEHICLE_TYPE_LABEL
            )}
          {itemSummary(client?.value > 0, client, HAULER_LABEL)}
          {itemSummary(account?.value > 0, account, ACCOUNT_LABEL)}
          {itemSummary(tripType?.value > 0, tripType, TRIP_TYPE)}
        </Grid>
      </div>
    </Box>
  );
};

export default Summary;

const useStyles = makeStyles(theme => ({
  summary_container: {
    overflowY: "auto",
    overflowX: "hidden",
    height: 250,
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  },
  summar_container__title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 1,
    padding: "1rem 0",
    display: "flex",
    alignItems: "center",
    gap: 5
  },
  item_summary: {
    fontSize: 12,
    color: theme.palette.primary.dark
  },
  item_summary_header: {
    fontSize: 11,
    color: theme.palette.primary.main,
    fontWeight: 300,
    textTransform: "Uppercase",
    letterSpacing: 1,
    padding: "0.25rem 0"
  }
}));
