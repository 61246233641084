import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Checkbox,
  TextField,
  Divider,
  Chip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#EDD607 !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16,
    height: "85%"
  },
  input: {
    padding: 0
  }
});

const OTD = props => {
  const classes = useStyles();
  const {
    dropoffs,
    filterValues,
    data,
    redirectToTrips,
    setFilterValues,
    tabValue,
    getCursor,
    setDropoffKeyword
  } = props;
  const [otd, setOtd] = useState({
    in_transit: {
      at_risk: {
        count: 0,
        trip_numbers: []
      },
      on_track: {
        count: 0,
        trip_numbers: []
      }
    },
    arrived: {
      late: {
        count: 0,
        trip_numbers: []
      },
      on_time: {
        count: 0,
        trip_numbers: []
      },
      completed: {
        count: 0,
        trip_numbers: []
      }
    }
  });

  useEffect(() => {
    if (data) {
      setOtd(prevOtd => {
        return {
          ...prevOtd,
          ...data.get_otd
        };
      });
    }
  }, [data]);

  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title}>ON-TIME DELIVERY</Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography>Drop Off</Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                limitTags={2}
                getLimitTagsText={more => `+${more}`}
                disableCloseOnSelect
                options={dropoffs}
                getOptionLabel={option => option.label.toString()}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={
                      filterValues[tabValue].dropoffs.length === 0 ? "All" : ""
                    }
                    onChange={e => setDropoffKeyword(e.target.value)}
                  />
                )}
                disableClearable
                value={filterValues[tabValue].dropoffs}
                onChange={(e, nv) => {
                  const temp = [...filterValues];
                  temp[tabValue] = { ...temp[tabValue], dropoffs: nv };
                  setFilterValues(temp);
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        <Grid item xs={4} style={{ borderRight: "1px solid #D6D6D6" }}>
          <div style={{ paddingTop: 25 }} align="center">
            IN TRANSIT
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ cursor: getCursor(otd.in_transit.at_risk.count) }}
                  onClick={() => {
                    if (otd.in_transit.at_risk.count > 0) {
                      redirectToTrips(otd.in_transit.at_risk.trip_numbers);
                    }
                  }}
                >
                  {otd.in_transit.at_risk.count > 0
                    ? otd.in_transit.at_risk.count
                    : 0}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Track</Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ cursor: getCursor(otd.in_transit.on_track.count) }}
                  onClick={() => {
                    if (otd.in_transit.on_track.count > 0) {
                      redirectToTrips(otd.in_transit.on_track.trip_numbers);
                    }
                  }}
                >
                  {otd.in_transit.on_track.count > 0
                    ? otd.in_transit.on_track.count
                    : 0}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Late</Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ cursor: getCursor(otd.arrived.late.count) }}
                  onClick={() => {
                    if (otd.arrived.late.count > 0) {
                      redirectToTrips(otd.arrived.late.trip_numbers);
                    }
                  }}
                >
                  {otd.arrived.late.count > 0 ? otd.arrived.late.count : 0}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Time</Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ cursor: getCursor(otd.arrived.on_time.count) }}
                  onClick={() => {
                    if (otd.arrived.on_time.count > 0) {
                      redirectToTrips(otd.arrived.on_time.trip_numbers);
                    }
                  }}
                >
                  {otd.arrived.on_time.count > 0
                    ? otd.arrived.on_time.count
                    : 0}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Completed</Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ cursor: getCursor(otd.arrived.completed.count) }}
                  onClick={() => {
                    if (otd.arrived.completed.count > 0) {
                      redirectToTrips(otd.arrived.completed.trip_numbers);
                    }
                  }}
                >
                  {otd.arrived.completed.count > 0
                    ? otd.arrived.completed.count
                    : 0}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(OTD);
