import React, { useState, useEffect } from "react";
import { GET_VEHICLE_INFOS } from "../../graphql/Queries";
import { useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import AddLogs from "../functions/AddLogs";

/*
 *** create a custom hook that will be use for fetching vehicles.
 *** this hook will be use for different fetch vehicles depending on the variable conditions.
 */

// constant values.
const qry = GET_VEHICLE_INFOS;
const INITIAL_PAGE_TOTAL = 1;

const useFetchVehicles = (vars, setPage, page, searchKeyword) => {
  // states.
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(INITIAL_PAGE_TOTAL);
  const [totalPage, setTotalPage] = useState(0);
  // fetch query.
  const { data: vehiclesData, loading: vehiclesLoading, refetch: refetchData } = useQuery(qry, {
    variables: vars,
    onError: () => Swal.fire({ icon: "error", text: "Something went wrong" }),
    onCompleted: data => {
      if(searchKeyword.value){
        AddLogs(`PMS - ${searchKeyword.moduleName}`, "search", searchKeyword.value);
      }
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (vehiclesLoading) return;
    const { vehicles, total, count } = vehiclesData.get_vehicles;
    // get total count of pages in the table.
    const totalCountOfVehicles = Math.ceil(total / page.rows);
    setVehicles(vehicles);
    setCount(count);
    setTotal(total);
    setPage(page => ({ ...page, total: totalCountOfVehicles }));
    setTotalPage(totalCountOfVehicles);
  }, [vehiclesLoading, vehiclesData]);

  return { vehicles, count, total, loading: vehiclesLoading, totalPage, refetchData };
};

export default useFetchVehicles;
