import { makeStyles } from "@material-ui/core";

const useStageStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: "450px",
    maxWidth: "350px",
    alignItems: "center",
    gap: 15,
    padding: 15,
    borderRadius: 14,
    background: "white",
    opacity: 0,
    animation: "$grow 250ms ease-in-out 250ms forwards"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flex: "3",
    alignItems: "center",
    justifyContent: "center",
    gap: "28px"
  },
  image: { width: "140" },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    maxWidth: "80%",
    textAlign: "center"
  },
  description: {
    fontSize: "14px",
    marginInline: "32px",
    textAlign: "center",
    color: "#686868"
  },
  footer: { flex: "2", width: "85%" },
  button: { borderRadius: 20 },
  "@keyframes grow": {
    "0%": {
      opacity: 0,
      transform: "scale(0)"
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)"
    }
  }
}));

export default useStageStyles;
