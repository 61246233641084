/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { Fragment, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Select,
  MenuItem,
  ListItemSecondaryAction,
  InputAdornment,
  Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // InputLabel,
  Tooltip,
  // Switch,
  // SvgIcon,
  Box
} from "@material-ui/core";
import { Event, Help as HelpIcon, Brightness1 } from "@material-ui/icons";
// import { Autocomplete } from "@material-ui/lab";
// import Cookie from "js-cookie";
import Swal from "sweetalert2";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import _ from "lodash";
import MomentUtils from "@date-io/moment";
import moment from "moment";
// import Header from "../Header";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import AccessControl from "../../../../../utils/AccessControl";
import { vehicleIcons } from "../../../../Utils";
import {
  GET_VEHICLE_TYPE_LIST,
  GET_VEHICLE_INFOS,
  GET_DEVICES
} from "../../../../../graphql/Queries";
import {
  ADD_VEHICLE
  // EDIT_DEVICE,
  // ADD_NOTIFICATION
} from "../../../../../graphql/Mutations";
import AddLogs from "../../../../../utils/functions/AddLogs";
// import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";
// import validate from "../../../../../utils/validation";
import SwitchHelper from "../../../../../utils/UI/Switch/SwitchHelper";
import SvgIcons from "../../../../../assets/SvgIcons/SvgIcons";
import PairDeviceModal from "../../../../../utils/modals/PairDeviceModal";
import useUserContext from "../../../../../context/User/useUserContext";
import { Alert } from "@material-ui/lab";
import SearchContext from "../../../../../context/SearchContext";

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  paper: {
    padding: "24px",
    width: "100%",
    boxSizing: "border-box"
  },
  red: {
    color: "red"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    margin: "0 0 0 14px"
  }
}));

// const HtmlTooltip = withStyles(theme => ({
//   tooltip: {
//     background: "#0000008f",
//     maxWidth: "40ch",
//     borderRadius: "4px",
//     letterSpacing: "0.5px"
//   }
// }))(Tooltip);

const autoUnpairCondition = [
  {
    id: process.env.REACT_APP_STATUS_ARRIVAL_AT_PORT,
    label: "Arrival at Port"
  },
  {
    id: process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
    label: "Arrival at Dropoff Point"
  },
  {
    id: process.env.REACT_APP_STATUS_ARRIVAL_AT_CONTAINER_YARD,
    label: "Arrival at Container Yard"
  }
];

const AddVehicle = props => {
  const { location, history } = props;
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  const { searchKeyword, setSearchKeyword } = contextData;

  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [deviceModal, toggleDeviceModal] = useState(false);
  const [autoUnpairStatus, setAutounpairStatus] = useState(false);

  const year = [];
  for (let index = 1980; index <= 2030; index += 1) {
    year.push(index);
  }

  const g_index = _.findIndex(user.client.group_names, g => {
    return g === user.client.name;
  });

  const [vehicleData, setVehicleData] = useState({
    plateno: null,
    icon: "default_icon.png",
    client_id: user.client.id,
    // group_ids: [g_index !== -1 ? user.client.group_ids[g_index] : 0],
    expiration: null,
    bodyno: null,
    model: null,
    avefuelconsumption: 0,
    remarks: "",
    manufacture_year: new Date().getFullYear().toString(),
    vehicle_type_id: 0,
    vehicle_type: "",
    imei: "",
    under_repair: false,
    not_in_use: false,
    has_temp_sensor: false,
    has_fuel_sensor: false,
    device_id: "0",
    device_info: null,
    auto_unpair: null,
    make: "",
    area: "",
    heads: "",
    region: "",
    assignment: "",
    has_vss_camera: 0,
    channel_count: null
  });

  // const [imei, setImei] = useState([]);
  // const [imeiSelect, setImeiSelect] = useState([]);
  const [initialState] = useState(_.cloneDeep(vehicleData));

  const [helpers, setHelper] = useState({
    plateno: null,
    vehicle_type_id: null,
    avefuelconsumption: null,
    bodyno: null,
    auto_unpair: null,
    make: null,
    area: null,
    heads: null,
    region: null,
    assignment: null,
    channel_count: null
  });

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleBlur = e => {
    // if (
    //   vehicleData.avefuelconsumption < 0 ||
    //   vehicleData.avefuelconsumption > 99999
    // ) {
    //   temp = {
    //     ...helpers,
    //     avefuelconsumption: (
    //       <Helper message="Invalid value. Min 0, Max: 99,999" />
    //     )
    //   };
    //   setHelper(temp);
    // } else {
    //   temp = {
    //     ...helpers,
    //     avefuelconsumption: null
    //   };
    //   setHelper(temp);
    // }

    const { name, value } = e.target;
    let error;
    switch (name) {
      case "plateno":
        // error = !validate("alphanumeric", value) ? (
        //   <Helper message="Alphanumeric characters only" />
        // ) : value === "" ? (
        //   <Helper message="This field is required" />
        // ) : (
        //   ""
        // );

        error = !value && "This field is required";
        break;
      case "avefuelconsumption":
        error =
          (vehicleData.avefuelconsumption < 0 ||
            vehicleData.avefuelconsumption > 99999) &&
          "Invalid value. Min 0, Max: 99,999";
        break;
      case "make":
        {
          error = value.length > 80 && "Only 80 characters allowed";
        }
        break;
      case "area":
        {
          error = value.length > 100 && "Only 100 characters allowed";
        }
        break;
      case "heads":
        {
          error = value.length > 150 && "Only 150 characters allowed";
        }
        break;
      case "region":
        {
          error = value.length > 80 && "Only 80 characters allowed";
        }
        break;
      case "assignment":
        {
          error = value.length > 100 && "Only 100 characters allowed";
        }
        break;
      // case "channel_count":
      //   const channelCountTemp = parseInt(value);
      //   if (
      //     (channelCountTemp <= 0 || channelCountTemp >= 9) &&
      //     value !== null
      //   ) {
      //     error = "This field only accepts numbers 1 to 8";
      //   }
      //   break;
      default:
        break;
    }
    setHelper({ ...helpers, [name]: error });
  };

  const validateData = async () => {
    let valid = true;
    let temp = {};
    // if (/[^a-zA-Z0-9 ]/.test(vehicleData.plateno)) {
    //   temp = {
    //     ...temp,
    //     plateno: <Helper message="Alpha numeric character only" />
    //   };
    //   if (valid) valid = false;
    // }
    if (vehicleData.plateno !== null) {
      if (vehicleData.plateno.length > 50) {
        temp = {
          ...temp,
          plateno: "Only 50 characters is allowed"
        };
      }
    }

    if (vehicleData.plateno === null || vehicleData.plateno === "") {
      temp = { ...temp, plateno: "This field is required" };
      if (valid) valid = false;
    }
    if (vehicleData.vehicle_type_id === 0) {
      temp = {
        ...temp,
        vehicle_type_id: "This field is required"
      };
      if (valid) valid = false;
    }
    if (
      vehicleData.avefuelconsumption < 0 ||
      vehicleData.avefuelconsumption > 99999
    ) {
      temp = {
        ...temp,
        avefuelconsumption: "Invalid value. Min 0, Max: 99,999"
      };
      if (valid) valid = false;
    }

    if (autoUnpairStatus && vehicleData.auto_unpair === null) {
      temp = {
        ...temp,
        auto_unpair: "This field is required!"
      };
      valid = false;
    } else {
      temp = {
        ...temp,
        auto_unpair: ""
      };
    }

    if (vehicleData.make?.length > 80) {
      temp = {
        ...temp,
        make: "Only 80 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicleData.area?.length > 100) {
      temp = {
        ...temp,
        area: "Only 100 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicleData.heads?.length > 150) {
      temp = {
        ...temp,
        heads: "Only 150 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicleData.region?.length > 80) {
      temp = {
        ...temp,
        region: "Only 80 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicleData.assignment?.length > 100) {
      temp = {
        ...temp,
        assignment: "Only 100 characters allowed"
      };
      if (valid) valid = false;
    }

    // if (
    //   (vehicleData.channel_count <= 0 ||
    //     vehicleData.channel_count >= 9) &&
    //   vehicleData.channel_count !== null
    // ) {
    //   temp = {
    //     ...temp,
    //     channel_count: "This field only accepts numbers 1 to 8"
    //   };
    //   if (valid) valid = false;
    // }

    setHelper(temp);

    return valid;
  };

  // const [updateDevicePartner] = useMutation(EDIT_DEVICE);
  // const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [addVehicle] = useMutation(ADD_VEHICLE, {
    variables: {
      ...vehicleData,
      plateno: vehicleData.plateno?.toString()?.trim(),
      group_ids: [g_index !== -1 ? user.client.group_ids[g_index] : 0]
    },
    refetchQueries: () => {
      return [
        {
          query: GET_VEHICLE_INFOS,
          variables: location.state.queryVars
        }
      ];
    },
    onCompleted: data => {
      const { add_vehicle } = data;
      if (add_vehicle.success) {
        // if(+vehicleData.device_id !== 0) {
        //   updateDevicePartner({
        //     variables: {
        //       id: vehicleData.device_id,
        //       device: {
        //         devicealias: vehicleData.device_info.devicealias,
        //         group_ids: vehicleData.group_ids,
        //         vehicle_info: {
        //           id: add_vehicle.id,
        //           device_id: vehicleData.device_id,
        //           plateno: vehicleData.plateno,
        //           vehicle_type_id: vehicleData.vehicle_type_id
        //         }
        //       }
        //     }
        //   });
        // }
        if (+vehicleData.device_id !== 0) {
          AddLogs("Admin - Vehicles/CV", "paired_device", [
            vehicleData.device_info.name,
            vehicleData.plateno
          ]);

          // addNotif({
          //   variables: {
          //     notification: {
          //       client_id: [user.client.id],
          //       vehicle_plate_no: vehicleData.plateno,
          //       datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          //       notification: `GPS Device ${vehicleData.device_info.name} was paired to Vehicle/CV ${vehicleData.plateno}`,
          //       category: "User Activity",
          //       is_archived: false,
          //       creator: "Tmsuite-web",
          //       group_ids: [user.client.group_ids[g_index]],
          //       group_names: user.client.name
          //     }
          //   }
          // });
        }
        AddLogs("Admin - Vehicles/CV", "insert", vehicleData.plateno);
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Add successful",
          timer: 1500,
          showConfirmButton: false
        });
        setTimeout(() => {
          // set values again for vehicles table filter after adding
          history.push({
            pathname: "/admin",
            state: {
              params: {
                moduleName: process.env.REACT_APP_VEHICLES_MODULE,
                vars: history?.location?.state?.vars,
                vTypeName: history?.location?.state?.vTypeName,
                group: history?.location?.state?.group,
                isLogistics: history?.location?.state?.isLogistics
              }
            }
          });
        }, 1500);
      } else {
        let temp = { ...helpers };
        _.forEach(add_vehicle.error, er => {
          // temp = { ...helpers, [er.field]: <Helper message={er.message} /> };
          temp = {
            ...helpers,
            [er.field]: er.message
          };

          if (er.message.includes("bodyno")) {
            temp = {
              ...helpers,
              bodyno: er.message
            };
          }
        });
        setHelper(temp);
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
  });

  const handleChange = e => {
    let temp = {};
    const { name, checked } = e.target;
    const objNameChecked = _.isEqual(name, "not_in_use")
      ? "under_repair"
      : "not_in_use";

    if (checked && vehicleData[objNameChecked]) {
      setVehicleData({
        ...vehicleData,
        [name]: checked,
        [objNameChecked]: !vehicleData[objNameChecked]
      });
    } else if (name === "autoUnpairStatus") {
      setAutounpairStatus(checked);
      setVehicleData({
        ...vehicleData,
        auto_unpair: null
      });

      if (!e.target.checked) {
        temp = {
          ...helpers,
          auto_unpair: ""
        };
        setHelper(temp);
      }
    } else if (name === "has_vss_camera") {
      setVehicleData({ ...vehicleData, [name]: checked ? 1 : 0 });
    } else {
      setVehicleData({ ...vehicleData, [name]: checked });
    }
  };

  const { data: vtypes } = useQuery(GET_VEHICLE_TYPE_LIST);
  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "plateno") {
      setVehicleData({
        ...vehicleData,
        [name]: _.isEmpty(value) ? null : value.toUpperCase()
      });
    } else if (name === "vehicle_type_id") {
      const index = _.findIndex(
        vtypes.get_vehicle_types.vehicle_types,
        types => {
          return types.id === value;
        }
      );
      setVehicleData({
        ...vehicleData,
        [name]: _.isEmpty(value) ? null : value,
        vehicle_type: vtypes.get_vehicle_types.vehicle_types[index].name,
        auto_unpair: null
      });
      setAutounpairStatus(false);
    } else if (name === "channel_count") {
      setVehicleData({
        ...vehicleData,
        [name]: +value >= 1 && +value <= 8 ? +value : null
      });
    } else {
      setVehicleData({
        ...vehicleData,
        [name]: _.isEmpty(value) ? null : value
      });
    }
    // setHelper({
    //   plateno: null,
    //   vehicle_type_id: null,
    //   avefuelconsumption: null
    // });
  };
  const [get_imei] = useLazyQuery(GET_DEVICES, {
    onCompleted: imeiData => {
      if (imeiData) {
        const temp = [];
        imeiData.get_devices.devices.forEach(value => {
          if (!value.vehicle_info || value.vehicle_info.plateno === "") {
            temp.push({
              id: value.id,
              name: value.name
            });
          }
        });
        // setImeiSelect(temp);
      }
    },
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    get_imei({
      variables: {
        first: 8,
        group_ids: user.client.group_ids,
        keyword: "",
        condition: [{ field: "vehicle_info.plateno", value: "" }]
      }
    });

    setSearchKeyword({
      ...searchKeyword,
      submit: false
    });
  }, []);

  // Check if input is valid
  // const handleKeyPress = event => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   if (/[^a-zA-Z0-9]/g.test(keyValue)) {
  //     event.preventDefault();
  //   }
  // };

  const gpsStatus = vehicle => {
    const s = vehicle?.device_info?.device_status?.status_code?.toLowerCase();
    let data = {};
    switch (s) {
      case "available":
      case "online":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#34CB1b" }}
              >
                Online GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#34CB1b"
        };
        break;
      case "no gps":
        data = {
          label: "No GPS",
          color: "#e40000"
        };
        break;
      case "delayed":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#FEC702" }}
              >
                Delayed Reporting
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#FEC702"
        };
        break;
      case "offline":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#C4C4C4" }}
              >
                Offline GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#C4C4C4"
        };
        break;
      default:
        data = {
          label: "No GPS",
          color: "#e40000"
        };
    }
    return data;
  };
  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/resources/vehicles/"
  // );

  // if (loadingPermissions) return <Typography>Loading...</Typography>;

  // if (!permissions.edit) return <Typography>403</Typography>;

  return (
    <AccessControl process="add" resource={location.pathname}>
      <Fragment>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_VEHICLES_MODULE}
          history={history?.location?.state}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          // process="Add New"
          isDataNotChanged={_.isEqual(initialState, vehicleData)}
        />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Typography>
                All fields with <span style={{ color: "red" }}>*</span> are
                required
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography variant="h6">Vehicle / CV Information</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <span>
                          Plate/CV Number <span className={classes.red}>*</span>
                        </span>
                      }
                      secondary={
                        <TextField
                          // onKeyPress={handleKeyPress}
                          name="plateno"
                          placeholder="Enter Plate/CV Number"
                          fullWidth
                          value={vehicleData.plateno || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          // FormHelperTextProps={{
                          //   component: "div"
                          // }}
                          // helperText={helpers.plateno}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.plateno && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.plateno}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Paired Device
                        </span>
                      }
                      secondary={
                        <span>
                          <TextField
                            placeholder="Enter Pair Device"
                            value={vehicleData.device_info?.devicealias || ""}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "black" }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Tooltip title={gpsStatus(vehicleData).label}>
                                    <Brightness1
                                      style={{
                                        fontSize: 14,
                                        color: gpsStatus(vehicleData).color
                                      }}
                                    />
                                  </Tooltip>
                                </InputAdornment>
                              )
                            }}
                            fullWidth
                            readOnly
                            onClick={() => toggleDeviceModal(true)}
                          />
                          {deviceModal && (
                            <PairDeviceModal
                              toggle={deviceModal}
                              initialData={vehicleData}
                              closeVehicleModal={() => toggleDeviceModal(false)}
                              setDeviceInfo={e => {
                                setVehicleData({
                                  ...vehicleData,
                                  device_id: e?.id || 0,
                                  device_info: e || null
                                });
                                toggleDeviceModal(false);
                              }}
                            />
                          )}
                        </span>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <span>
                          Vehicle Icon
                          {/* <span className={classes.red}>*</span> */}
                        </span>
                      }
                      secondary={
                        <Select
                          value={vehicleData.icon}
                          renderValue={selected => selected.split(".")[0]}
                          onChange={handleInputChange}
                          name="icon"
                          fullWidth
                        >
                          {vehicleIcons.map(icon => (
                            <MenuItem key={icon.value} value={icon.value}>
                              <svg
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                // viewBox="0 0 16.96 16.96"
                                width="45px"
                                height="45px"
                              >
                                <g
                                  fill="#00000"
                                  stroke={SvgIcons.stroke}
                                  strokeWidth={0.3}
                                >
                                  <path
                                    d={
                                      SvgIcons[`${icon.value.split(".")[0]}`]
                                        .path
                                    }
                                  />
                                </g>
                              </svg>
                              {/* <img
                                src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${icon.value}`}
                                alt=""
                                width={25}
                                height={25}
                                style={{marginRight: "15px"}}
                                /> */}
                              <ListItemText primary={icon.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                    <ListItemSecondaryAction>
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        // viewBox="0 0 16.96 16.96"
                        width="45px"
                        height="45px"
                        style={{ margin: -29 }}
                      >
                        <g
                          fill="#00000"
                          stroke={SvgIcons.stroke}
                          strokeWidth={0.3}
                        >
                          <path
                            d={
                              SvgIcons[`${vehicleData.icon.split(".")[0]}`].path
                            }
                          />
                        </g>
                      </svg>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${vehicleData.icon}`}
                        width={25}
                        height={25}
                        alt=""
                      /> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <span>
                          Hauler <span className={classes.red}>*</span>
                        </span>
                      }
                      secondary={user.client.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <span>
                          Vehicle Type <span className={classes.red}>*</span>
                        </span>
                      }
                      secondary={
                        <TextField
                          name="vehicle_type_id"
                          placeholder="Select Vehicle Type"
                          fullWidth
                          value={vehicleData.vehicle_type_id}
                          onChange={handleInputChange}
                          select
                          // helperText={helpers.vehicle_type_id}
                        >
                          <MenuItem disabled value={0}>
                            Select Vehicle Type
                          </MenuItem>
                          {vtypes &&
                            vtypes.get_vehicle_types.vehicle_types.map(
                              vtype => (
                                <MenuItem key={vtype.id} value={vtype.id}>
                                  {vtype.name}
                                </MenuItem>
                              )
                            )}
                        </TextField>
                      }
                    />
                  </ListItem>
                  {helpers.vehicle_type_id && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.vehicle_type_id}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="KML (Standard)"
                      secondary={
                        <TextField
                          defaultValue={vehicleData.avefuelconsumption}
                          name="avefuelconsumption"
                          type="number"
                          min={0}
                          max={99999}
                          InputProps={{ inputProps: { min: 0, max: 99999 } }}
                          onBlur={handleBlur}
                          onChange={e =>
                            setVehicleData({
                              ...vehicleData,
                              avefuelconsumption: Number(e.target.value)
                            })
                          }
                          // helperText={helpers.avefuelconsumption}
                          fullWidth
                        />
                      }
                    />
                  </ListItem>
                  {helpers.avefuelconsumption && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.avefuelconsumption}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Registry Expiration"
                      secondary={
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            format="MM/DD/YYYY"
                            InputProps={{
                              placeholder: "Select Expiration Date",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Event />
                                </InputAdornment>
                              ),
                              name: "expiration"
                            }}
                            showTodayButton
                            value={vehicleData.expiration}
                            todayLabel="NOW"
                            onChange={date =>
                              setVehicleData({
                                ...vehicleData,
                                expiration: moment(date).format("YYYY-MM-DD")
                              })
                            }
                          />
                        </MuiPickersUtilsProvider>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Model"
                      secondary={
                        <TextField
                          placeholder="Enter Model"
                          name="model"
                          value={vehicleData.model || ""}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Body Number"
                      secondary={
                        <TextField
                          name="bodyno"
                          value={vehicleData.bodyno || ""}
                          onChange={handleInputChange}
                          fullWidth
                          placeholder="Enter Body Number"
                          // helperText={helpers.bodyno}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.bodyno && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.bodyno}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Manufacture Year"
                      secondary={
                        <TextField
                          name="manufacture_year"
                          placeholder="Select Manufacture Year"
                          type="tel"
                          value={vehicleData.manufacture_year}
                          onChange={handleInputChange}
                          fullWidth
                          select
                        >
                          {year.map(y => (
                            <MenuItem key={y} value={y.toString()}>
                              {y}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Make"
                      secondary={
                        <TextField
                          name="make"
                          value={vehicleData.make || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Make"
                          // helperText={helpers.make}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.make && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.make}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Area"
                      secondary={
                        <TextField
                          name="area"
                          value={vehicleData.area || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Area"
                          // FormHelperTextProps={{
                          //   component: "div"
                          // }}
                          // helperText={helpers.area}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.area && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.area}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Heads"
                      secondary={
                        <TextField
                          name="heads"
                          value={vehicleData.heads || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Heads"
                          // FormHelperTextProps={{
                          //   component: "div"
                          // }}
                          // helperText={helpers.heads}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.heads && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.heads}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Region"
                      secondary={
                        <TextField
                          name="region"
                          value={vehicleData.region || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Region"
                          // FormHelperTextProps={{
                          //   component: "div"
                          // }}
                          // helperText={helpers.region}
                        />
                      }
                    />
                  </ListItem>
                  {helpers.region && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.region}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary="Assignment"
                      secondary={
                        <TextField
                          name="assignment"
                          value={vehicleData.assignment || ""}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Assignment"
                          // FormHelperTextProps={{
                          //   component: "div"
                          // }}
                          // helperText={helpers.assignment}
                        />
                      }
                    />
                  </ListItem>

                  {helpers.assignment && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.assignment}
                    </Alert>
                  )}
                  {vehicleData.has_vss_camera !== 0 && (
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              placement="top"
                              title="Provide the no. of selected channel/s for the VSS Live Cam (Max 8)"
                            >
                              <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                            <Typography>Selected Channel (VSS)</Typography>
                          </Box>
                        }
                        secondary={
                          <Select
                            displayEmpty
                            fullWidth
                            value={vehicleData.channel_count}
                            name="channel_count"
                            placeholder="Select channel"
                            onChange={handleInputChange}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          // <TextField
                          //   name="channel_count"
                          //   value={vehicleData.channel_count}
                          //   onChange={handleInputChange}
                          //   onBlur={handleBlur}
                          //   fullWidth
                          //   type="number"
                          //   InputProps={{ inputProps: { min: 1, max: 8 } }}
                          // />
                        }
                      />
                    </ListItem>
                  )}
                  {/* {helpers.channel_count && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.channel_count}
                    </Alert>
                  )} */}
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={!!vehicleData.under_repair}
                    label="Under Repair"
                    name="under_repair"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Under Repair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={!!vehicleData.not_in_use}
                    label="Not in use vehicle"
                    name="not_in_use"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Not in use vehicle"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={!!vehicleData.has_temp_sensor}
                    label="Temperature Sensor"
                    name="has_temp_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a temperature sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={!!vehicleData.has_fuel_sensor}
                    label="Fuel Sensor"
                    name="has_fuel_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a fuel sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={autoUnpairStatus}
                    label="Auto Unpair"
                    name="autoUnpairStatus"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: Enable GPS auto unpair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />

                  {autoUnpairStatus && (
                    <ListItem>
                      <ListItemText
                        secondary={
                          <Select
                            fullWidth
                            displayEmpty
                            value={vehicleData.auto_unpair}
                            name="auto_unpair"
                            placeholder="Select condition"
                            onChange={handleInputChange}
                          >
                            <MenuItem value={null} disabled>
                              Select condition
                            </MenuItem>
                            {autoUnpairCondition.map(option => (
                              <MenuItem
                                disabled={
                                  vehicleData.vehicle_type
                                    .toLowerCase()
                                    .trim() !== "container van" &&
                                  option.id !==
                                    process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF.toString()
                                }
                                key={option.id}
                                value={option.id}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </ListItem>
                  )}
                  {helpers.auto_unpair && helpers.auto_unpair}
                  <SwitchHelper
                    handleChangeHandler={handleChange}
                    checkedValue={!!vehicleData.has_vss_camera}
                    label="VSS Camera"
                    name="has_vss_camera"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as vehicle with VSS Compatibility"
                    icon={<HelpIcon className={classes.helpIcon} />}
                  />
                </List>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Remarks</Typography>
                <TextField
                  value={vehicleData.remarks}
                  placeholder="Type here..."
                  multiline
                  fullWidth
                  variant="outlined"
                  onChange={e => {
                    if (vehicleData.remarks.length < 100) {
                      setVehicleData({
                        ...vehicleData,
                        remarks: e.target.value
                      });
                    }
                  }}
                />
                <div align="right">
                  <small>{`${vehicleData.remarks.length}/100`}</small>
                </div>
              </Grid>
            </Grid>
            <ConfirmationDialog
              toggle={openConfirm}
              close={() => setOpenConfirm(false)}
              fn={() => {
                addVehicle();
                setOpenConfirm(false);
              }}
              title="Save?"
              content="Are you want to save changes made?"
            />
            {/* <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
              <DialogTitle>Save?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you want to save changes made?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  // className={classes.noButton}
                  style={{
                    borderRadius: 36,
                    borderColor: "#f44336",
                    color: "#f44336"
                  }}
                  variant="outlined"
                  onClick={() => setOpenConfirm(false)}
                >
                  NO
                </Button>
                <Button
                  style={{
                    borderRadius: 36,
                    color: "white"
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    addVehicle();
                    setOpenConfirm(false);
                  }}
                >
                  YES
                </Button>
              </DialogActions>
            </Dialog> */}
            <div align="center">
              <Button
                color="primary"
                style={{ borderRadius: 32, width: 350 }}
                variant="contained"
                onClick={async () => {
                  if (await validateData()) {
                    setOpenConfirm(true);
                  }
                }}
              >
                Save Details
              </Button>
            </div>
          </Paper>
        </div>
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() => setRedirect(true)}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </Fragment>
    </AccessControl>
  );
};

export default AddVehicle;
