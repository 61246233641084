import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

function PasswordStrength(props) {
  const { password } = props;
  const [strength, setStrength] = useState({
    strength: "",
    color: ["#e8e8e8", "#e8e8e8", "#e8e8e8"]
  });

  useEffect(() => {
    const numeric = /[0-9]/.test(password) ? 1 : 0;
    const lowercase = /[a-z]/.test(password) ? 1 : 0;
    const uppercase = /[A-Z]/.test(password) ? 1 : 0;
    const special = /[!@$#&*,=.+;'/(){}[\]\- ]/.test(password) ? 1 : 0;
    const totalStrength = numeric + lowercase + uppercase + special;
    if (password.length >= 8) {
      if (totalStrength === 4)
        setStrength({
          strength: "Strong",
          color: ["#65d209", "#65d209", "#65d209"]
        });
      else if (totalStrength === 3)
        setStrength({
          strength: "Medium",
          color: ["#efcd5a", "#efcd5a", "#e8e8e8"]
        });
      else if (totalStrength === 1 || totalStrength === 2)
        setStrength({
          strength: "Weak",
          color: ["#e85656", "#e8e8e8", "#e8e8e8"]
        });
    } else {
      setStrength({
        strength: "",
        color: ["#e8e8e8", "#e8e8e8", "#e8e8e8"]
      });
    }
  }, [password]);

  return (
    <Grid container style={{ width: "100%" }} spacing={2}>
      {strength.color.map(col => (
        <Grid
          item
          xs={4}
          style={{
            backgroundColor: col,
            height: "12px",
            borderRadius: 12
          }}
        />
      ))}

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography style={{ color: strength.color[0], fontWeight: "bold" }}>
          {strength.strength}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PasswordStrength;
