/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Button,
  Fab
  // FormControl,
  // Select,
  // MenuItem,
  // TextField
} from "@material-ui/core";
import {
  // KeyboardArrowLeft as KeyboardArrowLeftIcon,
  // KeyboardArrowRight as KeyboardArrowRightIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import lodash from "lodash";
import Cookie from "js-cookie";
import SearchContext from "../../../../context/SearchContext";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
import AccessControl from "../../../../utils/AccessControl";
import { GET_USER_LEVELS } from "../../../../graphql/Queries";
import {
  DELETE_USER_LEVEL,
  DELETE_USER_LEVEL_ALL
} from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import DeleteDialog from "../../../../utils/modal/DeleteDialog";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";

const Highlight = require("react-highlighter");

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Role"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked,
    canEdit
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!canEdit}
            color="primary"
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            align="left"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              hideSortIcon={false}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children } = props;

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Access Rights
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20
  },
  fabIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  icon: {
    opacity: 1
  },
  dialog: {
    borderRadius: "10px"
  }
}));

export default function AccessRights() {
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  const user = JSON.parse(Cookie.get("user"));
  const [clearFirst, setClearFirst] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [canEdit, setcanEdit] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [deleteAllResponse, setDeleteAllResponse] = useState({
    deleted: 0,
    total: 0
  });
  const [keyDel, setKeyDel] = useState();
  const [selDel, setSelDel] = useState([]);
  // const [circularLoading, setCircularLoading] = useState(false);
  const getVariables = () => {
    let variables = {};

    if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
      variables = {
        keyword: keyword,
        skip: 0,
        first: rowsPerPage,
        orderBy: { field: orderBy, direction: order }
      };
    } else {
      variables = {
        keyword: keyword,
        skip: 0,
        first: rowsPerPage,
        orderBy: { field: orderBy, direction: order },
        filter: { field: "client_id", value: user.client.id }
      };
    }
    return variables;
  };

  const { loading: loadingData, error, data, fetchMore, refetch } = useQuery(
    GET_USER_LEVELS,
    {
      variables: getVariables(),
      onCompleted: () => {
        setLoading(false);
        if (keyword) {
          AddLogs("Admin - Access Rights", "search", searchKeyword.value);
        }
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first"
    }
  );

  const [getSelected] = useLazyQuery(GET_USER_LEVELS, {
    onCompleted: data => {
      if (data);
      {
        const temp = [];
        data.get_user_levels.userlevels.map(val => {
          temp.push(val.name);
          return temp;
        });
        setSelDel(temp);
      }
    }
  });

  const [toDelete, setToDelete] = useState([]);

  function desc(a, b, orderBy) {
    const first =
      typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
    const second =
      typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const getData = () => {
    return data?.get_user_levels?.userlevels
      ? stableSort(
          data.get_user_levels.userlevels,
          getSorting(order, orderBy)
        ).slice(
          Number(page - 1) * rowsPerPage,
          Number(page - 1) * rowsPerPage + rowsPerPage
        )
      : [];
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (
      !selected.length <
      getData().filter(u => u.name.toLowerCase() !== "data admin").length
    ) {
      const displayedData = getData()
        .filter(u => u.name.toLowerCase() !== "data admin")
        .map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const tempBlock = selectedBlock;
    const deselect = () => {
      const displayedData = getData()
        .filter(u => u.name.toLowerCase() !== "data admin")
        .map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);

      const displayedBlocked = getData()
        // .filter(u => u.is_blocked)
        .filter(u => u.name.toLowerCase() !== "data admin")
        .map(u => u.id);
      const newBlocked = tempBlock
        .filter(tb => displayedBlocked.indexOf(tb) < 0)
        .map(id => id);

      setSelectedBlock(newBlocked);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      // getData().filter(u => u.name.toLowerCase() !== "data admin").map(n => tempSelected.push(n.id));
      getData()
        .filter(u => u.name.toLowerCase() !== "data admin")
        .map(u => {
          tempBlock.push(u.id);
          tempSelected.push(u.id);
          return null;
        });
      setSelected(lodash.uniq(tempSelected));
      setSelectedBlock(lodash.uniq(tempBlock));
      return;
    }
    deselect();
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const [deleteUserLevel] = useMutation(DELETE_USER_LEVEL, {
    onCompleted(data1) {
      if (data1.delete_user_level.success) {
        handleClose();
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 3000
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            refetch(getVariables());
          } else {
            setLoading(true);
            refetch(getVariables());
          }
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      const isFKContraint =
        graphQLErrors[0].message ===
        "ER_ROW_IS_REFERENCED: Cannot delete or update a parent row: a foreign key constraint fails";
      Swal.fire({
        icon: isFKContraint ? "warning" : "error",
        title: isFKContraint ? "Warning!" : "Error!",
        text: isFKContraint
          ? "Cannot delete. This access right is currently in used."
          : "Something went wrong",
        timer: 3000
      });
    }
    // refetchQueries: () => {
    //   return [
    //     {
    //       query: GET_USER_LEVELS,
    //       variables: getVariables()
    //     }
    //   ];
    // }
  });

  const handleDelete = () => {
    if (data.get_user_levels.count === 1 && data.get_user_levels.total > 1)
      setPage(page - 1);
    toDelete.map(row_id =>
      deleteUserLevel({
        variables: { id: row_id }
      }).then(() => AddLogs("Admin - Access Rights", "delete", keyDel))
    );
  };

  const [deleteUserLevelAll] = useMutation(DELETE_USER_LEVEL_ALL, {
    onCompleted: data1 => {
      if (data1) {
        const {
          success,
          error: err,
          total,
          deleted
        } = data1.delete_user_level_all;
        handleClose();
        setSelected([]);
        setPage(1);
        AddLogs("Admin - Access Rights", "delete", selDel);
        if (success && err.length === 0) {
          Swal.fire({
            title: "Deleted",
            icon: "success",
            showConfirmButton: false,
            timer: 3000
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              setLoading(true);
              refetch(getVariables());
            } else {
              setLoading(true);
              refetch(getVariables());
            }
          });
        } else {
          setDeleteAllResponse({ total: total, deleted: deleted });
          setDeleteDialog(true);
        }
      }
    },
    onError: ({ graphQLErrors }) => {
      const isFKContraint =
        graphQLErrors[0].message ===
        "ER_ROW_IS_REFERENCED: Cannot delete or update a parent row: a foreign key constraint fails";
      Swal.fire({
        icon: isFKContraint ? "warning" : "error",
        title: isFKContraint ? "Warning!" : "Error!",
        text: isFKContraint
          ? "Cannot delete. This access right is currently in used."
          : "Something went wrong",
        timer: 3000
      });
    }
    // refetchQueries: () => {
    //   return [
    //     {
    //       query: GET_USER_LEVELS,
    //       variables: getVariables()
    //     }
    //   ];
    // }
  });

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
    setDeleteAllResponse({ total: 0, deleted: 0 });
    refetch(getVariables());
  };

  const handleDeleteAll = () => {
    const temp = [];
    if (selected.length > 0) {
      selected.map(id => temp.push({ field: "user_levels.id", value: id }));
      getSelected({
        variables: {
          filter: temp,
          first: selected.length
        }
      });
    }
    deleteUserLevelAll({
      variables: { condition: { field: "id", value: selected } }
    });
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (data) {
      const { get_user_levels } = data;
      setLoading(false);
      setTotalPage(
        get_user_levels.total
          ? Math.ceil(get_user_levels.total / rowsPerPage)
          : 1
      );
    }
  }, [data]);

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_user_levels: {
            count: fetchMoreResult.get_user_levels.count,
            total: fetchMoreResult.get_user_levels.total,
            userlevels: [
              ...prev.get_user_levels.userlevels,
              ...fetchMoreResult.get_user_levels.userlevels
            ]
          }
        };
      }
    }).catch(() => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(1);
          refetch(getVariables());
        }
      });
    });
  };

  if (error) {
    return <ErrorPage errorCode={error.networkError.response} />;
  }

  return (
    <AccessControl
      resource="/admin/activity/user_levels/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length}>
            <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={
                data?.get_user_levels?.userlevels
                  ? data?.get_user_levels?.userlevels.length
                  : 0
              }
              fetchMoreData={fetchMoreData}
              totalRows={data?.get_user_levels.total || 0}
              totalPage={totalPage}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setCircularLoading}
              setLoading={setLoading}
            />
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Rows per page:</Typography>
              <FormControl className={classes.field}>
                <Select
                  value={rowsPerPage}
                  onChange={e => {
                    handlePagination(e);
                  }}
                  displayEmpty
                  name="rows_per_page"
                  style={{ padding: 0, margin: "0 8px" }}
                  disableUnderline
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                name="down_page"
                disabled={Number(page) === 0}
                onClick={() => handleDownPage()}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <TextField
                name="page"
                readOnly
                onFocus={e => {
                  e.target.select();
                }}
                variant="outlined"
                inputProps={{ style: { padding: 0, textAlign: "center" } }}
                value={Number(page) + 1}
                style={{ width: "32px", marginRight: "8px" }}
                onChange={e => handlePage(e)}
                onKeyPress={e => handleKeyPress(e)}
              />
              {`/ ${totalPage}`}
              <Button
                name="up_page"
                disabled={Number(page) + 1 === totalPage}
                onClick={() => handleUpPage()}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div> */}
          </EnhancedTableToolbar>
          {selected.length > 0 && (
            <Paper
              style={{
                display: "flex",
                backgroundColor: "#eaeaea",
                paddingLeft: 16,
                position: "relative",
                minHeight: "64px",
                alignItems: "center"
              }}
              elevation={0}
              square
            >
              <Typography>
                {selected.length} Access right/s is selected on this page
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  borderRadius: 100,
                  color: "white"
                }}
                onClick={handleOpenDeleteAll}
              >
                Delete Selected
              </Button>
            </Paper>
          )}
          {loadingData || loading ? (
            <Loading />
          ) : (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data?.get_user_levels?.total}
                  handleSort={handleSort}
                  isChecked={isChecked}
                  canEdit={canEdit}
                />
                <TableBody>
                  {data?.get_user_levels?.userlevels &&
                    getData().map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {user.user_level_id ===
                            process.env.REACT_APP_WTI_ADMIN ? (
                              <Checkbox
                                onClick={event => handleClick(event, row.id)}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                disabled={
                                  row.id === process.env.REACT_APP_WTI_ADMIN
                                }
                              />
                            ) : (
                              <Checkbox
                                onClick={event => handleClick(event, row.id)}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                disabled={
                                  row.name.toLowerCase() === "data admin" ||
                                  row.id === process.env.REACT_APP_WTI_ADMIN
                                    ? canEdit
                                    : !canEdit
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.name}
                            </Highlight>
                          </TableCell>
                          <TableCell align="left">
                            <Highlight search={contextData.searchKeyword.value}>
                              {row.description}
                            </Highlight>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                setSearchKeyword({
                                  ...searchKeyword,
                                  value: ""
                                });
                                AddLogs(
                                  "Admin - Access Rights",
                                  "view",
                                  row.name
                                );
                              }}
                            >
                              <Link
                                style={{ color: "#757575" }}
                                aria-label="Back"
                                to={{
                                  pathname: `/admin/activity/user_levels/view/${row.id}`
                                }}
                              >
                                <VisibilityIcon />
                              </Link>
                            </IconButton>
                            {user.user_level_id ===
                            process.env.REACT_APP_WTI_ADMIN ? (
                              row.id !== process.env.REACT_APP_WTI_ADMIN && (
                                <IconButton
                                  disabled={!canEdit}
                                  onClick={() => {
                                    handleToDelete(row.id);
                                    handleOpen();
                                    setKeyDel(row.name);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            ) : row.name !== "Data Admin" &&
                              row.id !== process.env.REACT_APP_WTI_ADMIN ? (
                              <IconButton
                                disabled={!canEdit}
                                onClick={() => {
                                  handleToDelete(row.id);
                                  handleOpen();
                                  setKeyDel(row.name);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null
                            // (row.name !== "Data Admin" || user.user_level_id === process.env.REACT_APP_WTI_ADMIN || row.name.toLowerCase() !== "WTI Admin") && (
                            //   <IconButton
                            //     disabled={!canEdit}
                            //     onClick={() => {
                            //       handleToDelete(row.id);
                            //       handleOpen();
                            //       setKeyDel(row.name);
                            //     }}
                            //   >
                            //     <DeleteIcon />
                            //   </IconButton>
                            // )
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {!data?.get_user_levels?.userlevels && (
                <span
                  style={{
                    width: "100%",
                    height: 72,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography>No data found</Typography>
                </span>
              )}
            </TableContainer>
          )}
        </Paper>
        {canEdit && (
          <Fab size="large" color="primary" className={classes.fab}>
            <Link to="/admin/activity/user_levels/add">
              <AddIcon
                className={classes.fabIcon}
                style={{
                  width: "50px",
                  height: "50px",
                  color: "white"
                }}
                onClick={() =>
                  setSearchKeyword({ ...searchKeyword, value: "" })
                }
              />
            </Link>
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this access right?"
        />
        <DeleteDialog
          toggle={deleteDialog}
          close={() => handleDeleteDialogClose()}
          title="Warning!"
          content="Cannot delete. This access right is currently in use."
          deleteAllResponse={deleteAllResponse}
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteAll(e)}
          title="Delete?"
          content="Are you sure you want to delete these access rights?"
        />
      </div>
    </AccessControl>
  );
}
