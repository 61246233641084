/* eslint-disable react/no-unescaped-entities */
import React, { memo } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./FleetStyle";
import EmptyImg from "../../../assets/empty.svg";

// this will display a no data found if fleet is null.
// user could also setup the dashboard by clicking the button setup client.
const FleetNoData = ({ clientId }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box_noData}>
      <img
        src={EmptyImg}
        alt="empty"
        style={{ width: "130px", margin: "0.8rem" }}
      />
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        style={{ fontWeight: "bold" }}
      >
        No Data Found
      </Typography>
      <Typography variant="caption" display="block" color="textSecondary">
        It looks like you don't have a fleet link available for display.
      </Typography>
      <Typography variant="caption" display="block" color="textSecondary">
        You can add it by proceeding to the Client &#x3e; Dashboard.
      </Typography>
      <Button className={classes.no_data_btn} size="small">
        <Link
          to={`/admin/wti/clients/view/${clientId}/dashboard`}
          className={classes.no_data_link}
        >
          Setup Dashboard
        </Link>
      </Button>
    </Box>
  );
};

export default memo(FleetNoData);
