import React, { useMemo } from "react";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
// Custom Context
import useUserContext from "../User/useUserContext";

import defaultTheme, { theme1 } from "./themes";

const DEFAULT_COLOR = 0xf49400;

const ThemeProvider = ({ children }) => {
  const user_context = useUserContext();
  const client_preference = user_context?.client?.client_preference;

  const onThemeChanged = (baseColor = DEFAULT_COLOR) => {
    /**
     * @function
     * - base color
     * - process base color
     * - text color
     *
     * @return new theme
     */
  };

  const theme = useMemo(() => {
    return user_context ? theme1 : theme1;
  }, [client_preference?.client?.primary_color, user_context]);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
