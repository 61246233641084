import React, { useState, createRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Button as MuiButton,
  // ButtonGroup,
  IconButton,
  Typography,
  Tooltip,
  Box
} from "@material-ui/core";
import { TrafficLayer, InfoWindow } from "@react-google-maps/api";
import { withStyles } from "@material-ui/styles";
import { Add, Remove, TripOrigin } from "@material-ui/icons";
import _ from "lodash";
import {
  control_positions,
  mapContainerStyle,
  getGeoPaths,
  mapRef,
  center as DEFAULT_CENTER
} from "../../../../../utils/Map/Inner";
import Map from "../../../../../utils/Map";
import Search from "../../../../../utils/Map/Search/Search";
import AccessControl from "../../../../../utils/AccessControl";
import Header from "../../../Header";
import useViewControlRoomStyles from "./ViewControlRoom.styles";
import Vehicles from "../Vehicles/Vehicles";
import VehicleCount from "../../../../../utils/Map/VehicleCount/VehicleCount";
import ViewChannelForm from "./Form/ViewChannel.form";
import Draw from "../../../../../utils/Map/DrawingManager/DrawingManager";
import {
  GET_CONTROL_ROOMS,
  GET_ACL_MODULES_CLIENT
} from "../../../../../graphql/Queries";
import Forbidden from "../../../../../utils/Errors/403/Forbidden";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import DrawingModeIcon from "../../../../../utils/Map/Icons/DrawingModeIcon.icon";
import MapFilter from "../../../../../utils/Map/Filters";
import GeofencesFilter from "../../../../../utils/Map/Filters/GeofencesFilter";
import BlinkingAlertsLegend from "../../../../../utils/BlinkingAlertsLegend";
import useUserContext from "../../../../../context/User/useUserContext";
import { gpsStatus } from "../../../../Utils";
import moment from "moment";

export const max_zoom = 22;
export const min_zoom = 1;

const Button = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto"
    }
  }
})(MuiButton);

const SwipeSVG = props => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8592 9.3575L11.3604 12.8883C11.2496 13.6721 10.5767 14.25 9.79292 14.25H4.91625C4.49667 14.25 3.895 14.0837 3.60208 13.7829L0 9.99083L0.657083 9.32583C0.847083 9.13583 1.11625 9.04875 1.38542 9.10417L3.95833 9.69V1.1875C3.95833 0.530417 4.48875 0 5.14583 0C5.80292 0 6.33333 0.530417 6.33333 1.1875V5.9375H7.05375C7.29917 5.9375 7.54458 5.99292 7.75833 6.10375L10.9963 7.71875C11.6058 8.0275 11.9542 8.68458 11.8592 9.3575Z"
        fill={props.enableZoom ? "#FFB74D" : "white"}
      />
    </svg>
  );
};

const ViewControlRoom = props => {
  const { location } = props;
  const { pathname } = location;
  const { id: room_id } = useParams();
  const user_cotext = useUserContext();
  const groupIds = user_cotext?.client?.group_ids || [];
  const userLevelId = user_cotext.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  // Component state
  const [editMode, setEditMode] = React.useState(false);
  // Map states
  const [name, setName] = React.useState("");
  const [zoom, setZoom] = React.useState(6);
  const [dataView, setDataView] = React.useState(false);
  const [partners, setPartners] = React.useState([]);
  const [enableZoom, setEnableZoom] = React.useState(false);
  const [center, setCenter] = React.useState({
    lat: 12.248727944234455,
    lng: 121.95099687500002
  });
  const [drawingMode, setDrawingMode] = React.useState(false);
  const [bounds, setBounds] = React.useState(null);
  const [filters, setFilters] = useState({
    geofences: false,
    traffic: false
  });
  const [hauler, setHauler] = useState({
    hauler: {
      value: [+process.env.REACT_APP_WTI_CLIENT_ID],
      label: "Webcast",
      id: +process.env.REACT_APP_WTI_CLIENT_ID
    }
  });

  const clientId = user_cotext?.client?.id;
  const [hasTripPrebookingAccess, setHasTripPrebookingAccess] = useState(false);
  useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: clientId },
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setHasTripPrebookingAccess(hasTripsPrebooking);
      }
    },
    onError: err => console.log(err),
    fetchPolicy: "cache-and-network"
  });
  const { data, loading } = useQuery(GET_CONTROL_ROOMS, {
    variables: {
      query: {
        condition: [
          {
            field: "id",
            value: room_id
          }
        ]
      }
    }
  });
  const initialState = React.useMemo(() => {
    if (!loading && data?.get_control_rooms?.control_rooms?.length) {
      const {
        zoom_level,
        location: initialCenter,
        name: initialName,
        data_view,
        group_ids,
        group_names
      } = data?.get_control_rooms?.control_rooms[0];

      const accum_partners = group_ids.reduce((accum, current, index) => {
        accum.push({ id: current, name: group_names[index] });
        return accum;
      }, []);

      return {
        zoom: zoom_level,
        center: { lat: initialCenter.lat, lng: initialCenter.lon },
        name: initialName,
        data_view,
        partners: accum_partners
      };
    }

    return {};
  }, [loading, data?.get_control_rooms?.control_rooms[0]?.name]);

  React.useEffect(() => {
    if (Object.keys(initialState).length) {
      setZoom(initialState.zoom);
      setCenter(initialState.center);
      setName(initialState.name);
      setDataView(initialState.data_view);
      setPartners(initialState.partners);
      setDisplayVehicleCount(initialState.data_view);
    }
  }, [initialState.name]);

  const validateZoomLevel = n => {
    // if (n < min_zoom) return n;
    // if (n > max_zoom) return n;
    return n;
  };

  const drawingManagerRef = React.useRef();
  const setCurrentBounds = () => {
    if (!mapRef.current) return;
    const tempBounds = getGeoPaths();
    const boundingBox = tempBounds.map(coords => {
      return `${coords.lat} ${coords.lon}`;
    });
    setBounds(boundingBox);
  };
  const handleDrawingCompleted = d => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }

    drawingManagerRef.current = d;
    // const tempBounds = getGeoPaths(drawingManagerRef);
    // const boundingBox = tempBounds.map(coords => {
    //   return `${coords.lat} ${coords.lon}`;
    // });
    // const nextCenter = d.bounds.getCenter();
    // mapRef.current.setCenter(nextCenter);
    // mapRef.current.setZoom(validateZoomLevel(mapRef.current.getZoom() + 1));
    mapRef.current.fitBounds(d.getBounds());
    // setCurrentBounds();
    d.setMap(null);
    // setBounds(boundingBox);
  };

  React.useEffect(() => {
    if (!drawingMode) {
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setMap(null);
      }
      setBounds(null);
    }
  }, [drawingMode]);

  // Stylings
  const styles = useViewControlRoomStyles({ editMode, enableZoom });

  const handleCenterChanged = newCenter => {
    const lat = newCenter.lat();
    const lng = newCenter.lng();
    setCenter({ lat, lng });
  };

  const handleResetLocation = () => {
    setZoom(initialState.zoom);
    setCenter(initialState.center);
  };

  const getCurrentCenter = () => mapRef.current.getCenter() || DEFAULT_CENTER;
  const handleIdle = () => {
    const { lat, lng } = getCurrentCenter();
    // if (drawingMode) setCurrentBounds();
    setCurrentBounds();
    setCenter({
      lat: typeof lat === "function" ? lat() : lat,
      lng: typeof lng === "function" ? lng() : lng
    });
    setZoom(validateZoomLevel(mapRef.current.getZoom()));
  };

  const memoizedCenter = React.useMemo(() => center, [
    center?.lat,
    center?.lng
  ]);
  const options = React.useMemo(
    () => ({
      zoomControl: false,
      // scrollWheel: false
      // gestureHandling: "none",
      panControl: true,
      draggable: true,
      scrollwheel: enableZoom,
      disableDoubleClickZoom: !enableZoom
    }),
    [enableZoom]
  );

  const [displayVehicleCount, setDisplayVehicleCount] = React.useState(false);
  const handleVehicleCountToggle = newChecked => {
    setDisplayVehicleCount(newChecked);
  };

  const [discardDialog, setDiscardDialog] = React.useState(false);
  const closeDiscardDialog = () => setDiscardDialog(false);
  const openDiscardDialog = () => setDiscardDialog(true);
  const discardChanges = () => {
    setZoom(initialState.zoom);
    setCenter(initialState.center);
    setName(initialState.name);
    setDataView(initialState.data_view);
    setPartners(initialState.partners);
    setDisplayVehicleCount(initialState.data_view);
  };
  const handleDiscardChanges = () => {
    discardChanges();
    setEditMode(false);
  };
  const changesRef = React.useRef({});
  const handleToggleEditMode = event => {
    if (!event.target.checked) {
      if (
        initialState.name !== changesRef.current.clientName ||
        initialState.zoom !== changesRef.current.zoom ||
        initialState.center.lat !== changesRef.current.latitude ||
        initialState.center.lng !== changesRef.current.longitude ||
        initialState.data_view !== changesRef.current.dataView ||
        !_.isEqual(
          initialState.partners.map(partner => +partner.id),
          changesRef.current.partners.map(partner => +partner.id)
        )
      ) {
        openDiscardDialog();
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  React.useEffect(() => {
    if (editMode) discardChanges();
  }, [editMode]);

  React.useEffect(() => {
    if (!drawingMode || !enableZoom) handleResetLocation();
  }, [drawingMode, enableZoom]);

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const onInfoWindowClose = () => {
    setSelectedVehicle();
    setShowInfoWindow(prev => !prev);
  };

  React.useEffect(() => {
    if (selectedVehicle) {
      setShowInfoWindow(true);
    }
  }, [selectedVehicle]);

  return (
    <AccessControl
      resource={pathname}
      process="view"
      setEditPermission={() => {}}
      renderNoAccess={() => (
        <div className={styles.no_access_container}>
          <Forbidden />
        </div>
      )}
    >
      {({ permissions }) => (
        <div className={styles.wrapper}>
          <Header
            process={editMode ? "Edit" : "View"}
            moduleName={process.env.REACT_APP_CONTROL_ROOM_MODULE}
            editMode={editMode}
            toggleEditMode={handleToggleEditMode}
            editPermission={permissions.edit}
            redirect={false}
            setRedirect={() => {}}
            setDiscard={() => {}}
            disableBackButton
            label={initialState?.name}
            filter={hauler}
            setFilter={setHauler}
            isWTIAdmin={isWTIAdmin}
          />
          <div className={styles.body}>
            <div className={styles.map_container}>
              <Map
                defaultZoom={zoom}
                zoom={zoom}
                mapContainerStyle={mapContainerStyle}
                center={memoizedCenter}
                onIdle={handleIdle}
                options={options}
              >
                {showInfoWindow && (
                  <InfoWindow
                    onCloseClick={onInfoWindowClose}
                    position={{
                      lat: Number(
                        selectedVehicle?.device_info?.device_status?.latitude
                      ),
                      lng: Number(
                        selectedVehicle?.device_info?.device_status?.longitude
                      )
                    }}
                  >
                    <Box
                      component="div"
                      style={{
                        width: "350px",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <TripOrigin
                          style={{
                            color: gpsStatus(
                              selectedVehicle?.device_info?.device_status.status_code.toLowerCase()
                            ).color,
                            width: "0.7em",
                            height: "0.7em",
                            marginRight: 6,
                            paddingTop: 2
                          }}
                        />
                        <Typography className={styles.label}>
                          {selectedVehicle?.plateno}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>Last Report:</span>
                        <span>
                          {`${moment(
                            selectedVehicle?.device_info?.device_status
                              .reportstamp
                          ).format("hh:mm A MMM DD, YYYY")}`}
                        </span>
                      </div>
                    </Box>
                  </InfoWindow>
                )}
                <Draw
                  active={drawingMode}
                  drawingCompled={handleDrawingCompleted}
                />
                <Vehicles
                  clustererOptions={{ zoomOnClick: enableZoom }}
                  zoomLevel={zoom}
                  groupIds={isWTIAdmin ? hauler.hauler.value : groupIds}
                  setVehicles={setVehicles}
                  setSelectedVehicle={setSelectedVehicle}
                  bounds={bounds}
                />
                {filters.geofences && (
                  <GeofencesFilter zoomLevel={zoom} mapRef={mapRef} />
                )}
                {filters.traffic && <TrafficLayer autoUpdate />}
                <Map.Control position={control_positions.TOP_LEFT}>
                  <Search
                    onCenterChanged={handleCenterChanged}
                    onResetLocation={handleResetLocation}
                  />
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_TOP}>
                  <div className={styles.area_text_container}>
                    {/* <Typography
                      variant="subtitle2"
                      className={styles.area_typography}
                    >
                      {name}
                    </Typography> */}
                    <MapFilter setFilters={setFilters} filters={filters} />
                    <Tooltip title="Enable/Disable Zoom" placement="left">
                      <Button
                        className={styles.zoom_toggle_btn}
                        onClick={() => {
                          setEnableZoom(p => !p);
                          setDrawingMode(false);
                        }}
                      >
                        <SwipeSVG enableZoom={enableZoom} />
                      </Button>
                    </Tooltip>
                  </div>
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <BlinkingAlertsLegend
                    showAlerts
                    showOutlines
                    btnSize="small"
                    margin={15}
                    placement={displayVehicleCount ? "left-end" : ""}
                  />
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <div className={styles.drawing_mode_container}>
                    <Tooltip
                      title={
                        enableZoom
                          ? `Map selection tool`
                          : "Zoom in must be enabled to use this feature"
                      }
                      placement="left"
                    >
                      <Button
                        size="small"
                        className={styles.drawing_mode_btn}
                        disabled={!enableZoom}
                        component={!enableZoom ? "div" : undefined}
                        onClick={
                          !enableZoom
                            ? undefined
                            : () => setDrawingMode(mode => !mode)
                        }
                      >
                        <DrawingModeIcon active={drawingMode} />
                      </Button>
                    </Tooltip>
                    <div
                      // orientation="vertical"
                      // aria-label="vertical contained primary button group"
                      // variant="text"
                      className={styles.zoom_control_btn_grp}
                    >
                      <IconButton
                        size="small"
                        className={styles.zoom_add_btn}
                        disabled={!enableZoom}
                        onClick={() => {
                          mapRef.current.setZoom(
                            validateZoomLevel(mapRef.current.zoom + 1)
                          );
                        }}
                      >
                        <Add size="small" />
                      </IconButton>
                      <div
                        style={{
                          backgroundColor: "rgb(230, 230, 230)",
                          height: "1px"
                        }}
                      ></div>
                      <IconButton
                        size="small"
                        className={styles.zoom_minus_btn}
                        disabled={!enableZoom}
                        onClick={() => {
                          mapRef.current.setZoom(
                            validateZoomLevel(mapRef.current.zoom - 1)
                          );
                        }}
                      >
                        <Remove size="small" />
                      </IconButton>
                    </div>
                  </div>
                </Map.Control>
                <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <div className={styles.vehicle_count_container}>
                    {displayVehicleCount && (
                      <VehicleCount
                        vehicles={vehicles}
                        setSelectedVehicle={setSelectedVehicle}
                        bounds={bounds}
                        groupIds={isWTIAdmin ? hauler.hauler.value : groupIds}
                        hasAccessPrebookingTrips={hasTripPrebookingAccess}
                      />
                    )}
                  </div>
                </Map.Control>
              </Map>
            </div>
            <div className={styles.form_container}>
              <ViewChannelForm
                onDataViewChanged={handleVehicleCountToggle}
                mapState={{ zoom, name, center, dataView, partners }}
                changesRef={changesRef}
                editMode={editMode}
              />
            </div>
          </div>

          <ConfirmationDialog
            toggle={discardDialog}
            close={closeDiscardDialog}
            fn={handleDiscardChanges}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />
        </div>
      )}
    </AccessControl>
  );
};

export default ViewControlRoom;
