/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import {
  Paper,
  makeStyles,
  withStyles,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Switch
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Swal from "sweetalert2";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import _ from "lodash";
import Cookie from "js-cookie";
import DateFnsUtils from "@date-io/date-fns";
import Geocode from "react-geocode";
// import wkt from "wkt";
import Header from "../../Header";
import MapComponent from "./MapComponent";
import validate from "../../../../utils/validation";
// import SearchContext from "../../../../context/SearchContext";
import { ADD_GEOFENCE } from "../../../../graphql/Mutations";
import {
  GET_REVERSE_GEOCODE,
  GET_TEXTUAL_LOCATION
} from "../../../../graphql/Queries";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import redirectToTable from "../../../../utils/redirect";
import AddLogs from "../../../../utils/functions/AddLogs";
import AccessControl from "../../../../utils/AccessControl";
import Cookies from "js-cookie";
import useUserContext from "../../../../context/User/useUserContext";
// import Swal from "sweetalert2";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
// import Forbidden from "../../../../utils/Errors/403/Forbidden";
import {
  stdGeojsonToWkt,
  polygonToMultiPolygon
} from "../../../../utils/functions/coordinatesParserV2";
import { geofenceTypes } from "../../../Utils";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  custom_field: {
    margin: "12px 0",
    width: "100%"
  },
  custom_limit: {
    padding: "28px 0",
    width: "100%",
  },
  multiline: {
    margin: "12px 0"
  },
  column: {
    fontSize: "1.5rem"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dialog: {
    borderRadius: "10px"
  },
  switch: { position: "absolute", right: 50, top: 0 }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    position: "absolute",
    right: 50,
    top: 0
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const DEFAULT_ZOOM = 17;

function ReverseGeocode(props) {
  const classes = useStyles();
  const {
    location,
    errors,
    setGeocode,
    selectDrawing,
    state,
    setState
  } = props;
  const { data, loading } = useQuery(GET_REVERSE_GEOCODE, {
    onCompleted(response) {
      if (response.get_reverse_geocode) {
        let geoAddress = Object.values(response.get_reverse_geocode).join(" ");
        setGeocode(response.get_reverse_geocode);
        setState({
          ...state,
          address: selectDrawing
            ? selectDrawing.address
            : _.isNull(response.get_reverse_geocode.region)
            ? state.name
            : geoAddress
        });
      }
    },
    variables: {
      latitude: +location.lat || 0,
      longitude: +location.lon || 0
    }
  });
  if (loading) return <Typography>Loading...</Typography>;
  return (
    <Grid container>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Region"
          placeholder="--"
          value={
            selectDrawing
              ? selectDrawing.region
              : data?.get_reverse_geocode?.region || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />{" "}
        {errors.region && (
          <Alert severity="error" className={classes.alert}>
            {errors.region}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Province"
          placeholder="--"
          value={
            selectDrawing
              ? selectDrawing.province
              : data.get_reverse_geocode.province || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />{" "}
        {errors.province && (
          <Alert severity="error" className={classes.alert}>
            {errors.province}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Municipality/City"
          placeholder="--"
          value={
            selectDrawing
              ? selectDrawing.municipality
              : data.get_reverse_geocode.municipality || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />{" "}
        {errors.municipality && (
          <Alert severity="error" className={classes.alert}>
            {errors.municipality}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          label="Barangay"
          placeholder="--"
          value={
            selectDrawing
              ? selectDrawing.barangay
              : data.get_reverse_geocode.barangay || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            style: { color: "#808080" }
          }}
          className={classes.field}
        />{" "}
        {errors.barangay && (
          <Alert severity="error" className={classes.alert}>
            {errors.barangay}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

function GeofenceDetails(props) {
  const classes = useStyles();
  const {
    geofenceNameRef,
    geofenceCodeRef,
    addressRef,
    categoryRef,
    geocodeRef,
    errorRef,
    submitted,
    location,
    windowTimeStartRef,
    windowTimeEndRef,
    resErrors,
    wkbGeometry,
    setLocationProps,
    setFormEdit,
    hasDrawn,
    // mapViewRef,
    zoom,
    setZoom,
    zoomLevelRef,
    selectDrawing,
    state,
    setState,
    speedLimit,
    setSpeedLimit
  } = props;
  // const user = JSON.parse(Cookies.get("user"))
  const user = useUserContext();
  const [autoGenerate, setAutoGenerate] = useState(false);
  // const [state, setState] = useState({
  //   name: "",
  //   geofence_code: "",
  //   window_time_start: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
  //   window_time_end: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
  //   category: "",
  //   address: "",
  //   location: {}
  // });
  const [initialState, setInitialState] = useState(_.cloneDeep(state));
  const [geocode, setGeocode] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    category: "",
    geofence_code: "",
    address: "",
    // location: { lat: "", lon: "" },
    lat: "",
    lon: "",
    region: "",
    province: "",
    municipality: "",
    barangay: "",
    geofence: "",
    zoom: ""
  });
  const [rerender, setRerender] = useState(false);

  // useQuery(GET_TEXTUAL_LOCATION, {
  //   variables: {
  //     points: [location],
  //     group_ids: user.group_ids
  //   },
  //   onCompleted: data => {
  //     setInitialState({
  //       ...initialState,
  //       address: selectDrawing
  //         ? selectDrawing.address
  //         : data.get_textual_location
  //     });
  //     setState({
  //       ...state,
  //       address: selectDrawing
  //         ? selectDrawing.address
  //         : data.get_textual_location
  //     });
  //   }
  // });

  const handleDate = (time, field) => {
    setState({
      ...state,
      [field]: time
    });
  };

  const handleToggle = () => {
    if (!autoGenerate) {
      setState({
        ...state,
        geofence_code: Math.random()
          .toString(36)
          .slice(-5)
      });
      setErrors({ ...errors, geofence_code: "" });
    } else {
      setState({ ...state, geofence_code: "" });
    }
    setAutoGenerate(prevAuto => !prevAuto);
  };

  useEffect(() => {
    setErrors({ ...errors, ...resErrors });
  }, [resErrors]);

  useEffect(() => {
    if (wkbGeometry.type) {
      setErrors({ ...errors, geofence: "" });
    }

    if (location.lat !== "" || location.lon !== "")
      setErrors({ ...errors, lat: "", lon: "" });
  }, [wkbGeometry]);

  useEffect(
    e => {
      if (selectDrawing !== null) {
        setErrors({ ...errors, lat: "", lon: "" });
      }
    },
    [selectDrawing]
  );

  // useEffect(() => {
  //   Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
  //   if (location.lat && location.lon) {
  //     Geocode.fromLatLng(+location.lat, +location.lon).then(response => {
  //       const address = response.results[0].formatted_address;
  //       setInitialState({ ...initialState, address: address });
  //       setState({ ...state, address: address });
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    if (_.isEqual(initialState, state) && !hasDrawn) setFormEdit(false);
    else setFormEdit(true);
  }, [state]);

  const handleChange = event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      case "zoom":
        if (value !== "") {
          // if (timeout) clearTimeout();
          // timeout = setTimeout(() => {
          if (
            +zoomLevelRef.current.value >= 1 &&
            +zoomLevelRef.current.value <= 22
          ) {
            setZoom(+zoomLevelRef.current.value);
          }
          // }, debounce);
        }
        // setZoom(value);
        break;
      case "address":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("alphanumeric", value)) {
          error = "Alphanumeric characters only";
        } else {
          error = "";
        }
        break;
      // else {
      //   error = ""
      // }
      // case "name":
      // error = validate("geofence", value)
      //   ? ""
      //   : `The following characters are not allowed: & _ + { } [ ] | ; " < > ' ? /`;
      // break;
      // case "geofence_code":
      //   error = validate("geofence", value)
      //     ? ""
      //     : `The following characters are not allowed: & _ + { } [ ] | ; " < > ' ? /`;
      //   break;
      // case "address":
      //   error = validate("alphanumeric", value)
      //     ? ""
      //     : "Alphanumeric characters only";
      //   break;
      // case "category":
      //   error = "";
      //   break;
      // if(!validate("number", +value)){
      //   error = "Numeric only"
      // } else if(+value < 1 || +value > 22){
      //   error = "Zoom level value min: 1, max: 22"
      // } else {
      //   error = ""
      // }
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleBlur = event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      // case "name":
      //   error = validate("geofence", value)
      //     ? ""
      //     : `The following characters are not allowed: & _ + { } [ ] | ; " < > ' ? /`;
      //   break;
      // case "geofence_code":
      //   error = validate("geofence", value)
      //     ? ""
      //     : `The following characters are not allowed: & _ + { } [ ] | ; " < > ' ? /`;
      //   break;
      case "address":
        if (value === "") {
          error = "This field is required";
        } else {
          error = "";
        }
        // else if (!validate("alphanumeric", value)) {
        //   error = "Alphanumeric characters only";
        // }
        break;
      case "category":
        error = "";
        break;
      case "zoom":
        if (value === "") {
          error = "";
        } else if (!validate("numeric", value)) {
          error = "Numeric only";
        } else if (+value < 1 || +value > 22) {
          error = "Zoom level value min: 1, max: 22";
        }
        break;
      case "lat":
      case "lon":
        if (value === "") {
          error = "This field is required";
        } else if (validate("numeric", value)) {
          error = "Numeric only";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleSpeedChange = value => {
    if (value >= 0 && value <= 200 && Number.isInteger(value)) {
      setSpeedLimit({
        ...speedLimit,
        custom_limit: value
      });
    } else if (value < 0) {
      setSpeedLimit({
        ...speedLimit,
        custom_limit: 0
      });
    } else {
      setSpeedLimit({
        ...speedLimit,
        custom_limit: 200
      });
    }
    // if (value < 0 || value > 200 || !Number.isInteger(value)) {
    //   setErrors(prev => {
    //     return { ...prev, speed: true };
    //   });
    // }
  };


  const check = field => {
    if (!errors[field]) {
      if (field in state) {
        return !state[field] ? "This field is required" : "";
      }
      // if (field in geocode) {
      //   return !geocode[field] ? "This field is required" : "";
      // }
      return errors[field];
    }
    return errors[field];
  };

  useEffect(() => {
    errorRef.current = errors;
  }, [errors]);

  useEffect(() => {
    if (submitted > 0) {
      const keys = Object.keys(errors);
      const temp = [];
      keys.map(element => temp.push({ [element]: check(element) }));
      errorRef.current = errors;
      if (selectDrawing || wkbGeometry.type) {
        temp.push({ geofence: "" });
      } else {
        temp.push({ geofence: "Geofence drawing is required" });
      }
      windowTimeStartRef.current = state.window_time_start;
      windowTimeEndRef.current = state.window_time_end;
      setErrors(Object.assign(errors, ...temp));
      setRerender(!rerender);
    }
  }, [submitted]);

  useEffect(() => {
    if (geocode) {
      geocodeRef.current = geocode;
      if (Object.values(geocode).every(element => element)) {
        const temp = [];
        Object.keys(geocode).forEach(key => temp.push({ [key]: "" }));
        setErrors(Object.assign(errors, ...temp));
        setRerender(!rerender);
      }
    }
  }, [geocode]);

  useEffect(() => {
    if (zoom < 1 || zoom > 22) {
      setErrors({ ...errors, zoom: "Zoom level value min: 1, max: 22" });
    } else {
      setErrors({ ...errors, zoom: "" });
    }
  }, [zoom]);

  useEffect(() => {
    if (state.address !== "") {
      setErrors({ ...errors, address: "" });
    } else {
      setErrors({ ...errors, address: "This field is required" });
    }
  }, [state.address]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        {errors.geofence && (
          <Alert severity="error" className={classes.alert}>
            {errors.geofence}
          </Alert>
        )}
        <TextField
          required
          name="name"
          label="Geofence"
          placeholder="Enter Geofence"
          value={state.name}
          onChange={e => handleChange(e)}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          inputProps={{ ref: geofenceNameRef }}
          className={classes.field}
        />
        {errors.name && (
          <Alert severity="error" className={classes.alert}>
            {errors.name}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} xs={12} style={{ position: "relative" }}>
        <AntSwitch
          checked={autoGenerate}
          onChange={handleToggle}
          // classes={{ root: classes.switch }}
          // color="primary"
        />
        <TextField
          required
          name="geofence_code"
          label="Geofence Code"
          placeholder="Enter Geofence Code"
          value={state.geofence_code}
          onChange={e => handleChange(e)}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          inputProps={{ ref: geofenceCodeRef, readOnly: autoGenerate }}
          className={classes.field}
        />
        {errors.geofence_code && (
          <Alert severity="error" className={classes.alert}>
            {errors.geofence_code}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} xs={12}>
        Window Time
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            <KeyboardTimePicker
              style={{ width: "40%" }}
              margin="normal"
              id="time-picker"
              ampm={false}
              value={state.window_time_start}
              error={false}
              helperText={null}
              onChange={e =>
                handleDate(
                  moment(e).format("YYYY-MM-DD HH:mm:ss"),
                  "window_time_start"
                )
              }
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
            <div style={{ margin: "8px" }}>
              <Typography> to </Typography>
            </div>
            <KeyboardTimePicker
              style={{ width: "40%" }}
              margin="normal"
              id="time-picker"
              ampm={false}
              value={state.window_time_end}
              onChange={e =>
                handleDate(
                  moment(e).format("YYYY-MM-DD HH:mm:ss"),
                  "window_time_end"
                )
              }
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={6} xs={12}>
        <FormControl className={classes.field}>
          <InputLabel shrink required>
            Category
          </InputLabel>
          <Select
            value={state.category}
            onChange={e => handleChange(e)}
            onBlur={handleBlur}
            displayEmpty
            name="category"
            inputProps={{ ref: categoryRef }}
          >
            <MenuItem value="" disabled>
              <em style={{ color: "#8e8e8e" }}>Select Category</em>
            </MenuItem>
            {geofenceTypes.map(category => (
              <MenuItem key={category.label} value={category.label}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {errors.category && (
          <Alert severity="error" className={classes.alert}>
            {errors.category}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          required
          label="Latitude"
          name="lat"
          placeholder="Enter Latitude"
          value={
            selectDrawing ? selectDrawing?.location?.lat : location.lat || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: false,
            readOnly: false,
            style: { color: "#808080" }
          }}
          className={classes.field}
          onBlur={handleBlur}
          onChange={e => {
            if (
              wkbGeometry.coordinates[0][0].length ||
              selectDrawing?.location?.lat
            )
              return;
            // if (validate("number", +e.target.value)) {
            //   setErrors(prev => ({
            //     ...prev,
            //     location: {
            //       lon: prev.location.lon,
            //       lat: "Numeric characters only"
            //     }
            //   }));
            //   return;
            // }
            // Update latitude value (map and text)
            setLocationProps(prev => ({
              ...prev,
              lat: e.target.value
            }));
            setErrors(prev => ({
              ...prev,
              location: {
                lon: prev.lon,
                lat: ""
              }
            }));
          }}
        />
        {errors.lat && (
          <Alert severity="error" className={classes.alert}>
            {errors.lat}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          required
          label="Longitude"
          name="lon"
          placeholder="Enter Longitude"
          value={
            selectDrawing ? selectDrawing?.location?.lon : location.lon || ""
          }
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: false,
            readOnly: false,
            style: { color: "#808080" }
          }}
          className={classes.field}
          onBlur={handleBlur}
          onChange={e => {
            if (
              wkbGeometry.coordinates[0][0].length ||
              selectDrawing?.location?.lat
            )
              return;
            // if (validate("number", +e.target.value)) {
            //   setErrors(prev => ({
            //     ...prev,
            //     location: {
            //       lat: prev.location.lat,
            //       lon: "Numeric characters only"
            //     }
            //   }));
            //   return;
            // }
            // Update longitude value (map and text)
            setLocationProps(prev => ({
              ...prev,
              lon: e.target.value
            }));
            setErrors(prev => ({
              ...prev,
              location: {
                lat: prev.lat,
                lon: ""
              }
            }));
          }}
        />
        {errors.lon && (
          <Alert severity="error" className={classes.alert}>
            {errors.lon}
          </Alert>
        )}
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          name="zoom"
          type="number"
          className={classes.field}
          label="Zoom Level"
          placeholder="Zoom Level"
          value={zoom}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          InputProps={{
            disableUnderline: false,
            readOnly: false,
            style: { color: "#808080" },
            inputProps: { min: 1, max: 22, ref: zoomLevelRef }
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.zoom && (
          <Alert severity="error" className={classes.alert}>
            {errors.zoom}
          </Alert>
        )}
      </Grid>
      <Grid item lg={5} xs={11}>
        <FormControl className={classes.custom_field}>
          <InputLabel shrink style={{ color: "black"}}>
            Speed Limit(kph)
          </InputLabel>
          <Select
            value={speedLimit.speed_limit}
            defaultValue=""
            onChange={event => {
              setSpeedLimit({
                speed_limit: event.target.value,
                custom_limit: 0
              });
            }}
            onBlur={handleBlur}
            displayEmpty
            name="speedLimit"
          >
            <MenuItem value="" disabled>
              <em style={{ color: "#8e8e8e" }}>Select Speed Limit</em>
            </MenuItem>
            {[
              70,
              80,
              90,
              100,
              "Custom"
            ].map(limit => (
              <MenuItem key={limit} value={limit}>
                {limit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* {errors.category && (
          <Alert severity="error" className={classes.alert}>
            {errors.category}
          </Alert>
        )} */}
      </Grid>
      <Grid item lg={1}>
        {speedLimit.speed_limit === "Custom" && (
            <div className={classes.custom_limit}>
                <TextField
                //error={errors.speed}
                //InputProps={speedLimit_props}
                inputProps={{ min: 0, max: 200 }}
                type="number"
                value={speedLimit.custom_limit}
                onChange={e =>
                  setSpeedLimit({
                    ...speedLimit,
                    custom_limit: e.target.value
                  })
                }
                onBlur={e =>
                  handleSpeedChange(Number(e.target.value))
                }
              />
              
            </div>
            )}
          </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          name="address"
          label="Address"
          placeholder="Enter Bldg No., Street name"
          value={selectDrawing ? selectDrawing?.address : state.address}
          onChange={e => handleChange(e)}
          onBlur={e => handleBlur(e)}
          InputLabelProps={{
            shrink: true,
            style: { color: "black" }
          }}
          inputProps={{ ref: addressRef }}
          className={classes.field}
        />
        {errors.address && (
          <Alert severity="error" className={classes.alert}>
            {errors.address}
          </Alert>
        )}
        <ReverseGeocode
          location={location}
          errors={errors}
          setGeocode={setGeocode}
          selectDrawing={selectDrawing}
          state={state}
          setState={setState}
        />
      </Grid>
    </Grid>
  );
}

export default function AddGeofence(props) {
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));
  // const contextData = useContext(SearchContext);
  // const mapViewRef = useRef();
  const geofenceNameRef = useRef();
  const windowTimeStartRef = useRef();
  const windowTimeEndRef = useRef();
  const geofenceCodeRef = useRef();
  const addressRef = useRef();
  const categoryRef = useRef();
  const geocodeRef = useRef();
  const errorRef = useRef();
  const zoomLevelRef = useRef();
  const [speedLimit, setSpeedLimit] = useState({
    speed_limit: 0,
    custom_limit: 0
  })
  const [dialog, setDialog] = useState({
    save: false
  });
  const [resErrors, setResErrors] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [wkbGeometry, setWkbGeometry] = useState({
    type: "",
    coordinates: [[[]]]
  });
  const history = useHistory();
  const { state: locationState } = history.location;
  const [location, setLocation] = useState(
    locationState?.location || { lat: 14.5995, lon: 120.9842 }
  );
  const [hasDrawn, setHasDrawn] = useState(false);
  const [opacity, setOpacity] = useState({
    circle: 0.5,
    polygon: 0.5,
    rectangle: 0.5,
    select: 0.5
  });
  const [color, setColor] = useState({
    circle: "#3CA4F7",
    polygon: "#3CA4F7",
    rectangle: "#3CA4F7",
    select: "#3CA4F7"
  });
  const [radius, setRadius] = useState(null);
  const [type, setType] = useState("");
  const [submitted, setSubmitted] = useState(0);
  const [formEdit, setFormEdit] = useState(false);
  const [universalGeo, setUniversalGeo] = useState(true);
  const [selectDrawing, setSelectDrawing] = useState(null);
  const [state, setState] = useState({
    name: "",
    geofence_code: "",
    window_time_start: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    window_time_end: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    category: "",
    address: "",
    location: {},
    zoom: DEFAULT_ZOOM.toString()
  });
  const [addGeofence] = useMutation(ADD_GEOFENCE, {
    onCompleted(data) {
      if (data) {
        if (data.add_geofence.success) {
          AddLogs("Admin - Geofences", "insert", geofenceNameRef.current.value);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              if (locationState?.source) {
                // set values again for vehicles table filter after adding
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });
              } else {
                // set values again for vehicles table filter after adding
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });
                // redirectToTable(
                //   history,
                //   process.env.REACT_APP_GEOFENCES_MODULE
                // );
              }
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              if (locationState?.source) {
                // set values again for vehicles table filter after adding
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });
              } else {
                // set values again for vehicles table filter after adding
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });
                // redirectToTable(
                //   history,
                //   process.env.REACT_APP_GEOFENCES_MODULE
                // );
              }
            }
          });
        } else {
          const temp = {};
          data.add_geofence.error.map(err => {
            Object.assign(temp, { [err.field]: err.message });
            if (err.message === "The name already exists") {
              temp.name = "Geofence name already exists";
            }
          });
          setResErrors(temp);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            if (locationState?.source) {
              history.push({
                pathname: locationState.source,
                state: locationState
              });
            } else {
              // redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
              // set values again for vehicles table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState.vars,
                    filter: locationState.filter
                  }
                }
              });
            }
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            if (locationState?.source) {
              history.push({
                pathname: locationState.source,
                state: locationState
              });
            } else {
              // redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
              // set values again for vehicles table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState.vars,
                    filter: locationState.filter
                  }
                }
              });
            }
          }
        });
      }
    }
  });

  const validateErrors = errors => {
    const keys = Object.keys(errors);

    return keys
      .filter(key => typeof errors[key] !== "object")
      .every(element => errors[element] === "");
  };

  const handleOpen = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: true });
  };

  const handleClose = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const { group_ids } = user;
    addGeofence({
      variables: {
        address: addressRef.current.value,
        name: geofenceNameRef.current.value,
        geofence_code: geofenceCodeRef.current.value,
        category: categoryRef.current.value,
        barangay: geocodeRef.current.barangay,
        municipality: geocodeRef.current.municipality,
        province: geocodeRef.current.province,
        region: geocodeRef.current.region,
        window_time_start: windowTimeStartRef.current,
        window_time_end: windowTimeEndRef.current,
        type: "multipolygon",
        location: selectDrawing ? selectDrawing.location : location,
        radius: radius,
        group_ids: group_ids,
        // group_names: group_names,
        wkb_geometry: wkbGeometry,
        opacity: opacity[type],
        color: color[type],
        geom: selectDrawing ? selectDrawing.geom : stdGeojsonToWkt(polygonToMultiPolygon(wkbGeometry)),
        geofence_geom_id: selectDrawing ? +selectDrawing.geofence_geom_id : null,
        speed_limit:
          speedLimit.speed_limit === "Custom"
            ? speedLimit.custom_limit
            : speedLimit.speed_limit
      }
    });
  };

  useEffect(() => {
    if (submitted > 0) {
      if (validateErrors(errorRef.current)) handleOpen("save");
    }
  }, [submitted]);

  // const { setSearchKeyword, searchKeyword } = contextData;

  // useEffect(() => {
  //   setSearchKeyword({
  //     ...searchKeyword,
  //     submit: false,
  //     moduleName: process.env.REACT_APP_GEOFENCES_MODULE
  //   });
  // }, []);

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/trip/geofences/"
  // );

  // if (loadingPermissions) return <Typography>Loading...</Typography>;
  // if (!permissions.edit) return <Typography>403</Typography>;
  const { pathname } = props.location;
  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          history={history?.location?.state}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          locationState={locationState}
          isDataNotChanged={!formEdit}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid container spacing={6} style={{ height: "calc(100% - 38px)" }}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ minHeight: "500px", maxHeight: "690px" }}
              >
                {/* minWidth: "500px", maxWidth: "500px"  */}
                <MapComponent
                  wkbGeometry={wkbGeometry}
                  setWkbGeometryProps={setWkbGeometry}
                  setLocationProps={setLocation}
                  radius={radius}
                  setRadius={setRadius}
                  location={location}
                  type={type}
                  setType={setType}
                  editMode
                  opacity={opacity}
                  setOpacity={setOpacity}
                  color={color}
                  setColor={setColor}
                  submitted={submitted}
                  setHasDrawn={setHasDrawn}
                  // mapViewRef={mapViewRef}
                  zoom={zoom}
                  setZoom={setZoom}
                  universalGeo={universalGeo}
                  setUniversalGeo={setUniversalGeo}
                  selectDrawing={selectDrawing}
                  setSelectDrawing={setSelectDrawing}
                  state={state}
                  setState={setState}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", marginBottom: "16px" }}
                >
                  <Typography>All fields with * are required</Typography>
                </Grid>
                <GeofenceDetails
                  geofenceNameRef={geofenceNameRef}
                  geofenceCodeRef={geofenceCodeRef}
                  addressRef={addressRef}
                  categoryRef={categoryRef}
                  geocodeRef={geocodeRef}
                  errorRef={errorRef}
                  location={location}
                  submitted={submitted}
                  windowTimeEndRef={windowTimeEndRef}
                  windowTimeStartRef={windowTimeStartRef}
                  resErrors={resErrors}
                  wkbGeometry={wkbGeometry}
                  setLocationProps={setLocation}
                  setFormEdit={setFormEdit}
                  hasDrawn={hasDrawn}
                  // mapViewRef={mapViewRef}
                  zoom={zoom}
                  setZoom={setZoom}
                  zoomLevelRef={zoomLevelRef}
                  selectDrawing={selectDrawing}
                  state={state}
                  setState={setState}
                  speedLimit={speedLimit}
                  setSpeedLimit={setSpeedLimit}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      setSubmitted(submitted + 1);
                    }}
                  >
                    Save Details
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={handleSubmit}
          title="Save"
          content="Are you sure you want to save geofence?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                if (locationState?.source) {
                  history.push({
                    pathname: locationState.source,
                    state: locationState
                  });
                } else {
                  setRedirect(true);
                }
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
}
