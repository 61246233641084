export default function validate(type, string) {
  let isCorrect = false;
  switch (type) {
    case "numeric":
      isCorrect = /^\d*$/.test(string);
      break;
    case "letters":
      isCorrect = /^[a-zA-Z\s]*$/.test(string);
      break;
    case "category":
      isCorrect = /^[a-zA-Z\s]*(\/[a-zA-Z\s]*)?$/.test(string);
      break;
    case "email":
      isCorrect = /^\w+@[a-zA-Z_\s]+?\.[a-zA-Z]{2,3}(.[a-zA-Z]{2,3})?$/.test(
        string
      );
      break;
    case "alphanumeric":
      isCorrect = /^[a-zA-Z0-9\s\s@$#&*{}[\],=-\\.+;'/\\(\\)]*$/.test(string);
      break;
    case "coordinates":
      isCorrect = /^\d*(\.\d*)?$/.test(string);
      break;
    case "time":
      isCorrect = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(string);
      break;
    default:
      break;
  }

  return isCorrect;
}
