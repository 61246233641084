const redirectToTable = (history, link) => {
  history.push({
    pathname: "/admin",
    state: {
      params: {
        moduleName: link
      }
    }
  });
};

export default redirectToTable;
