import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Item,
  StyledTitle,
  StyledTitleContainer,
  useStyles
} from "../../TripAllocation.styles";
import { SelectedTrips } from "../../sharedComponents";
import {
  Check as CheckIcon,
  InfoOutlined as InfoIcon,
  PriorityHigh as WarningIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import { DATE_FORMAT, DateTimeField } from "../../sharedComponents";
import moment from "moment/moment";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  GET_VEHICLE_ALLOCATIONS as GET_ALLOCATIONS,
  GET_ALLOCATION_VEHICLES
} from "../../../../../graphql/Queries";
import client from "../../../../../Client";
import useUserContext from "../../../../../context/User/useUserContext";
import { useMutation, useQuery } from "@apollo/client";
import { VehicleList } from "./VehicleList";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { ADD_ALLOCATIONS } from "../../../../../graphql/Mutations";
import AddLogs from "../../../../../utils/functions/AddLogs";
import AllocationsTable from "./AllocationsTable";
import useDialog from "../useDialog";
import UndoSnackbar from "../../../../../utils/UndoSnackbar";
import Loading from "../../../../../utils/Loading";

const PROGRESS_INTERVAL = 100;

/**
 * Dashboard #2
 * 
 */
const HaulerAllocation = props => {
  const {
    trips,
    setTrips,
    date,
    allocationsRef,
    isManual = false,
    handlePrevStep = () => {},
    redirectToTable = () => {}
  } = props;

  const classes = useStyles();
  const userCtx = useUserContext();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [haulerFilter, setHaulerFilter] = useState("all");
  
  const [confirmationDate, setConfirmationDate] = useState(allocationsRef.current.confirmationDate || null);
  const [isAllocating, setIsAllocating] = useState(true);
  const [progressPct, setProgressPct] = useState(0);
  const [actualPct, setActualPct] = useState(0);
  const [haulers, setHaulers] = useState([]);
  const [haulerVehicles, setHaulerVehicles] = useState([]);
  const [allocatedTrips, setAllocatedTrips] = useState([]);
  
  const { dialog, setDialog, handleDialog, handleCloseDialog } = useDialog();

  /**
   * group vehicle data by hauler/group_id
   * @returns {{id, name, vehicles: {type, count}}}
   */
  const groupByKeys = (arr, prop) => {
    const groupIds = userCtx?.client.group_ids;
    const grouped = arr.reduce((acc, curVal) => {
      let keys = curVal[prop];
      keys = keys.forEach((key) => {
        if (!groupIds.includes(key)) return;
        if (!acc[key]) {
          acc[key] = {
            id: key,
            name:
              curVal.group_names[_.findIndex(curVal.group_ids, (g) => g === key)],
            vehicles: [],
          };
        }

        // get # of vehicles by type
        let vTypeKey = curVal["vehicle_type"];
        if (!acc[key].vehicles[vTypeKey]) {
          acc[key].vehicles[vTypeKey] = {
            type: vTypeKey,
            count: 0,
          };
        }
        acc[key].vehicles[vTypeKey].count =
          acc[key].vehicles[vTypeKey].count + 1;
      });
      return acc;
    }, {});
    return grouped;
  };

  // get registered vehicles
  const { data, loading: vehiclesLoading } = useQuery(GET_ALLOCATION_VEHICLES, {
    variables: {
      group_ids: userCtx?.client.group_ids
    },
    onCompleted: res => {
      const vData = res.get_vehicles.vehicles;
      const vDataByType = groupByKeys(vData, "group_ids");
      
      const haulerVehiclesList = Object.values(vDataByType).map((v) => {
        const vehicles = Object.values(v.vehicles);
        return {
          ...v,
          vehicles: vehicles,
          total_vehicles: _.sumBy(vehicles, t => t.count)
        }
      });
      setHaulerVehicles(haulerVehiclesList);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const getPrevAllocations = async () => {
    const res = await client.query({
      query: GET_ALLOCATIONS,
      variables: {
        dateFilter: {
          field: "pickup_date",
          start: date.from,
          end: date.to
        },
        first: 10000
      },
      fetchPolicy: "network-only"
    });
    return res.data?.get_allocations?.allocations;
  };

  /**
   * Fetches haulers and their total available vehicles for the 
   * selected pickup dates. Count is then decreased by # of previous 
   * allocations w/ 'pending' and 'confirmed' status.
   * 
   */
  const getAvailableHaulers = async () => {
    // get allocations from selected pickup dates
    const prevAllocations = await getPrevAllocations();

    const client = userCtx?.client;
    const groups = client.group_names;

    // get vehicle count of each hauler
    let haulers = [];
    for (let group of groups) {
      const groupId = client.group_ids[_.findIndex(groups, g => g === group)];

      // get # of hauler's available vehicles
      // let count = await getVehicleCount(groupId);
      let count = haulerVehicles.find(h => +h.id === +groupId)?.total_vehicles

      // get # of hauler's allocations w/ pending/confirmed status
      const activeAllocationsCount = prevAllocations?.filter(
        p =>
          _.isEqual(p.group_name, group) &&
          [
            +process.env.REACT_APP_STATUS_ASSIGNED_ID,
            +process.env.REACT_APP_STATUS_PENDING_ID
          ].includes(p.status_code_id)
      ).length;
      count = count - activeAllocationsCount;

      // get trips that hauler has already declined 
      const declinedTrips = prevAllocations
        ?.filter(
          p =>
            _.isEqual(p.group_name, group) &&
            p.status_code_id === +process.env.REACT_APP_STATUS_DECLINED_ID
        )
        .map(p => p.trip_number);

      haulers.push({
        name: group,
        group_id: groupId,
        vehicle_count: +count || 0,
        declined_trips: declinedTrips
      });
    }
    return haulers;
  };

  /**
   * Returns next hauler w/ available vehicles. List is sorted by name
   * and hauler is skipped when it has declined the trip before.
   *
   * @param {*} haulers
   * @param {*} lastHaulerIdx   index of last assigned hauler
   * @param {*} tripNumber      trip no. to check for previous declines
   * @returns updatedHaulers -> haulers w/ updated vehicle count
   * @returns next           -> next hauler
   * @returns lastIdx        -> index of `next`
   */
  const getNextHauler = (haulers, lastHaulerIdx, tripNumber) => {
    let updatedHaulers = [];
    let availableHaulers = _.sortBy(haulers, [h => h.name.toLowerCase()]);

    // haulers next in line after last assigned
    let prio = availableHaulers.slice(lastHaulerIdx + 1);

    // get 1st hauler from prio w/ available vehicle + has not yet declined trip no.
    let nextIdx = prio.findIndex(
      h => h.vehicle_count >= 1 && !h.declined_trips.includes(tripNumber)
    );
    if (nextIdx !== -1) {
      let actualIdx = availableHaulers.findIndex(h =>
        _.isEqual(h, prio[nextIdx])
      );
      updatedHaulers = availableHaulers.map((h, idx) =>
        idx === actualIdx ? { ...h, vehicle_count: h.vehicle_count - 1 } : h
      );
      return {
        updatedHaulers: updatedHaulers,
        next: prio[nextIdx],
        lastIdx: actualIdx
      };
    }

    // if no hauler from prio w/ vehicle
    // find any hauler w/ vehicle (disregard order/last assigned hauler)
    nextIdx = availableHaulers.findIndex(h => h.vehicle_count >= 1);
    
    // no hauler w/ available vehicle
    if (nextIdx === -1) {
      return {
        updatedHaulers: [],
        next: {},
        lastIdx: -1
      };
    }

    // next hauler
    updatedHaulers = availableHaulers.map((h, idx) =>
      idx === nextIdx ? { ...h, vehicle_count: h.vehicle_count - 1 } : h
    );
    return {
      updatedHaulers: updatedHaulers,
      next: availableHaulers[nextIdx],
      lastIdx: nextIdx
    };
  };

  const handleDelete = (trip) => {
    setAllocatedTrips(prev => prev.filter((t) => t.id !== trip.id));
    handleDialog("deleteTrip");
  };

  const handleUndoDelete = () => {
    setAllocatedTrips(allocationsRef.current.trips);
    handleCloseDialog("deleteTrip");
  };

  useEffect(() => {
    const tripsWithHauler = trips.filter(t => t.assigned_hauler);
    let availableHaulers = [];
    let loadingHaulers = true;

    if (!vehiclesLoading) {
      // get hauler options
      getAvailableHaulers().then(res => {
        availableHaulers = res;
        setHaulers(res);
        loadingHaulers = false;
      });

      if (tripsWithHauler.length || (isManual && !tripsWithHauler.length)) {
        setAllocatedTrips(trips);
        setIsAllocating(false);
      } else if (!tripsWithHauler.length && !isManual && isAllocating) {
        let cur = 0;  // allocation item
        let allocated = cur; // successfully allocated item/count
        let total = trips.length;
        
        let curPct = 0;
        let allocatedPct = 0;
        let lastHaulerIdx = -1;
        
        const allocate = setInterval(() => {
          if (!loadingHaulers && cur < total) {
            // get next hauler and assign to trip
            const { updatedHaulers, next, lastIdx } =
              getNextHauler(
                availableHaulers,
                lastHaulerIdx,
                trips[cur].trip_number
              ) || null;
              
            if (next?.name) {
              setAllocatedTrips(prev => {
                const updatedTrips = [
                  ...prev,
                  { ...trips[cur], assigned_hauler: next?.name || "" }
                ];
                allocationsRef.current.trips = updatedTrips;
                return updatedTrips;
              });
  
              // update hauler options
              availableHaulers = updatedHaulers;
              lastHaulerIdx = lastIdx;
    
              // set progress
              cur = cur + 1;
              allocated = allocated + 1;
  
              curPct = _.round((cur / total) * 100);
              allocatedPct = _.round((allocated / total) * 100);
              setActualPct(allocatedPct);
            } else {
              // skip to end if there are no available vehicles left
              setAllocatedTrips(prev => {
                const updatedTrips = [
                  ...prev,
                  ...trips.slice(cur)
                ];
                allocationsRef.current.trips = updatedTrips;
                return updatedTrips;
              });
              cur = total;
              curPct = 100;
            }
  
            setProgressPct(curPct);
  
            if (curPct === 100) {
              setIsAllocating(false);
              if (curPct !== allocatedPct) {
                Swal.fire({
                  text:
                    "Selected trips exceed vehicle capacity.",
                  icon: "warning",
                  showConfirmButton: true,
                  confirmButtonText: "Okay",
                  customClass: {
                    confirmButton: `${classes.swalBtn}`,
                    icon: `${classes.swalIcon}`
                  }
                });
              }
            }
          }
        }, PROGRESS_INTERVAL);
  
        return () => {
          clearInterval(allocate);
        };
      }
    }
  }, [trips, haulerVehicles]);


  useEffect(() => {
    const tripsWithHauler = allocatedTrips?.filter(t => t.assigned_hauler);
    const pct = _.round((tripsWithHauler.length / allocatedTrips.length) * 100);
    setActualPct(pct);
    setProgressPct(pct);
  }, [allocatedTrips]);

  const handleChangeHauler = (e, trip) => {
    setAllocatedTrips(prev => {
      const updatedTrips = prev.map(t =>
        t.id === trip.id ? { ...t, assigned_hauler: e.target.value } : t
      );
      allocationsRef.current.trips = updatedTrips;
      return updatedTrips;
    });
  };

  const [addAllocations, { loading: addAllocationsLoading }] = useMutation(ADD_ALLOCATIONS);

  const handleSubmit = () => {
    const dateNow = moment().format("YYYY-MM-DD HH:mm:ss");
    let allocations = allocatedTrips?.map(t => ({
      allocation_date: dateNow,
      trip_number: t.trip_number,
      group_id: haulers.find(h => h.name === t.assigned_hauler)?.group_id,
      vehicle_type: t.vehicle_type || null,
      confirmation_duedate: moment(confirmationDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      client_id: t.client_id,
      pickup_location_id: +t.pickups[0].geofence_id,
      pickup_date: t.pickups[0].arrival,
      dropoff_location_id: +t.dropoffs[0].geofence_id,
      delivery_date: t.dropoffs[0].arrival,
      status_code_id: +process.env.REACT_APP_STATUS_PENDING_ID
    })).filter(t => t.group_id);

    const groupedByHauler = _.groupBy(allocatedTrips, "assigned_hauler");
    const allocLogs = Object.entries(groupedByHauler).map(([key, value]) => {
      const tripNumbers = value.map(v => v.trip_number);
      return (`trip ${tripNumbers.join(", ")} to ${key}`)
    });

    addAllocations({
      variables: {
        allocations: allocations
      }
    }).then(res => {
      const hasError = res.data.add_allocations.some(r => !r.success);
      if (!hasError) {
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          allowOutsideClick: false,
          onClose: () => {}
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            AddLogs(
              `${process.env.REACT_APP_ALLOCATION_MODULE} - ${process.env.REACT_APP_TRIP_ALLOCATION_MODULE}`,
              "add_allocation",
              `${allocLogs.join("; ")} by ${userCtx?.client?.name}`
            );
            redirectToTable();
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          icon: "error",
          showConfirmButton: true,
          timer: 3000
        });
      }
    });
  };

  /**
   * 
   * @returns {string[]} hauler names w/o enough vehicles 
   */
  const insufficientHaulers = () => {
    // get haulers that have been allocated and their total # of trips
    const tripsWithHauler = allocatedTrips.filter(t => t.assigned_hauler);
    const assignedHaulers = Object.entries(_.groupBy(tripsWithHauler, "assigned_hauler")).map(([key, value]) => ({
      name: key,
      trips_count: value.length
    }));
    
    // get haulers with trips_count higher than their vehicle_count
    const insufficientHaulers = assignedHaulers.filter(a => {
      const vehicles = haulers.find(h => a.name === h.name)?.vehicle_count;
      return a?.trips_count > vehicles;
    }).map(i => i.name);

    return insufficientHaulers;
  };

  if (vehiclesLoading) return <Loading />;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: 10,
        width: "95%",
        margin: "0 auto"
      }}
    >
      <Grid container item spacing={3}>
        <Grid item xs={12} md={5}>
          <ProgressBar progressPct={progressPct} actualPct={actualPct} />
        </Grid>
        <Grid item xs={8} sm={6} md={2}>
          <SelectedTrips trips={allocatedTrips} />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Item>
            <StyledTitle variant="h6">Pickup Date</StyledTitle>
            <Typography variant="body2" style={{ textWrap: "wrap" }}>
              {moment(date.from).format(DATE_FORMAT)} -
              <br />
              {moment(date.to).format(DATE_FORMAT)}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <ConfirmationDueDate
            confirmationDate={confirmationDate}
            setConfirmationDate={setConfirmationDate}
            pickupDate={date}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        style={{
          height: `${matchesSM ? "auto" : "100%"}`,
          maxHeight: `${matchesSM ? "480px" : "calc(100% - 180px)"}`
        }}
      >
        <Grid item xs={12} md={3} style={{ height: "100%" }}>
          <VehicleList
            selected={haulerFilter}
            setHaulerFilter={setHaulerFilter}
            trips={allocatedTrips}
            haulerVTypes={haulerVehicles}
          />
        </Grid>
        <Grid item xs={12} md={9} style={{ height: "100%" }}>
          <AllocationsTable
            trips={allocatedTrips}
            client={userCtx?.client}
            isAllocating={isAllocating}
            haulerFilter={haulerFilter}
            handleChangeHauler={handleChangeHauler}
            handleDelete={handleDelete}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent="center" gridGap={5}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.btnRounded}
              onClick={() => {
                allocationsRef.current.confirmationDate = confirmationDate;
                allocationsRef.current.trips = allocatedTrips;
                setTrips(allocatedTrips);
                handlePrevStep();
              }}
              disabled={isAllocating}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnRounded}
              onClick={() => {
                if (insufficientHaulers().length) {
                  handleDialog("insufficient");
                } else {
                  handleDialog("save");
                }
              }}
              disabled={
                isAllocating ||
                !confirmationDate ||
                moment(confirmationDate).isBefore(moment().format("lll")) ||
                moment(confirmationDate).isSameOrAfter(
                  moment(date?.from).format("lll")
                ) ||
                addAllocationsLoading
              }
            >
              Allocate
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ConfirmationDialog
        toggle={dialog.insufficient}
        close={() => handleCloseDialog("insufficient")}
        fn={handleSubmit}
        title="Insufficient Vehicle"
        content={
          <>
            <Typography>
              The following haulers have insufficient vehicles:
            </Typography>
            <List>
              {insufficientHaulers().map(hauler => (
                <ListItem disableGutters dense>
                  <StyledTitle variant="body">{hauler}</StyledTitle>
                </ListItem>
              ))}
            </List>
            <Box paddingTop={2}>
              <Typography>
                Do you still want to proceed with the allocation?
              </Typography>
            </Box>
          </>
        }
      />

      <ConfirmationDialog
        toggle={dialog.save}
        close={() => handleCloseDialog("save")}
        fn={handleSubmit}
        title="Save?"
        content="Are you sure you want to save the changes made?"
      />
      <UndoSnackbar
        classes={classes}
        open={dialog.deleteTrip}
        handleClose={(evt, reason) => {
          if (reason === "clickaway") {
            return;
          }
          handleCloseDialog("deleteTrip");
        }}
        handleAction={handleUndoDelete}
        message="Trip has been removed"
      />
      {addAllocationsLoading && (
        <Loading />
      )}
    </Box>
  );
};

/**
 *
 * @param {*} progressPct  % of total attempted allocations
 * @param {*} actualPct    % of successful allocations
 * @returns
 */
const ProgressBar = props => {
  const { progressPct, actualPct } = props;

  const getProgressDesc = () => {
    if (progressPct === 100) {
      if (actualPct < 100) {
        return "Selected trips exceed vehicle capacity.";
      }
      return "Allocation complete!";
    } else if (_.inRange(progressPct, 79, 99)) {
      return "Almost done...";
    } else if (progressPct > 49) {
      return "Distributing trips...";
    }
    return "Allocating vehicles...";
  };

  const getProgressIcon = () => {
    if (progressPct === 100) {
      if (actualPct < 100) {
        return <WarningIcon fontSize="large" />;
      }
      return <CheckIcon fontSize="large" />;
    }
    return <SearchIcon fontSize="large" />;
  };

  return (
    <Item style={{ justifyContent: "center" }}>
      <Grid container style={{ padding: "20px 30px" }}>
        <Grid container item sm={2} alignItems="center">
          <StyledIconCircle
            done={actualPct === 100}
            incomplete={progressPct === 100 && actualPct < progressPct}
          >
            {getProgressIcon()}
          </StyledIconCircle>
        </Grid>
        <Grid container item xs={12} sm={9}>
          <Grid container xs={12} item style={{ gap: 8 }} alignItems="center">
            <StyledTitle>{+actualPct || 0}%</StyledTitle>
            <Typography>{getProgressDesc()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledProgressBar value={actualPct} max={100} />
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

const MIN_TIME_DIFF = 15;
const ConfirmationDueDate = props => {
  const { confirmationDate, setConfirmationDate, pickupDate } = props;
  const styles = useStyles();

  const infoMsg = <span>Alloted time for the hauler to respond to the request</span>;

  const handleConfirmationDate = date => {
    // check if time selected is 15 minutes after current time
    const minsDiff = moment(date).diff(moment(), "minutes");
    // if not, change to valid minimum time
    if (minsDiff < MIN_TIME_DIFF) {
      setConfirmationDate(moment(date).add((MIN_TIME_DIFF - minsDiff), "minutes"));
    } else {
      setConfirmationDate(date);
    }
  };

  return (
    <Item>
      <StyledTitleContainer>
        <StyledTitle variant="h6">Confirmation Due Date</StyledTitle>
        <Tooltip title={infoMsg} placement="right-end">
          <InfoIcon color="disabled" fontSize="small" />
        </Tooltip>
      </StyledTitleContainer>
      <DateTimeField
        dateTimePickerProps={{
          value: confirmationDate,
          disablePast: true,
          initialFocusedDate: moment().add(MIN_TIME_DIFF, "minutes"),
          minDate: moment().format(DATE_FORMAT),
          maxDate: pickupDate.from,
          onChange: date => handleConfirmationDate(date),
          InputProps: {
            disableUnderline: true
          },
          TextFieldComponent: props => (
            <TextField
              {...props}
              variant="standard"
              className={styles.datetimeField}
            />
          )
        }}
      />
      {moment(confirmationDate).isBefore(moment().format("lll")) && (
        <FormHelperText error>
          Date and time should be later than the current date and time.
        </FormHelperText>
      )}
      {moment(confirmationDate).isSameOrAfter(moment(pickupDate.from).format("lll")) && (
        <FormHelperText error>
          Date and time should be before pickup date and time.
        </FormHelperText>
      )}
    </Item>
  );
};

const StyledProgressBar = styled("progress")(() => ({
  appearance: "none",
  height: "25px",
  width: "100%",
  overflow: "clip",
  /* Chrome and Safari */
  "&::-webkit-progress-bar": {
    backgroundColor: "#f4f4f4",
    borderRadius: "4px",
    transition: "width 0.5s"
  },
  "&::-webkit-progress-value": {
    backgroundColor: "#f49400",
    borderRadius: "4px",
    transition: "width 0.5s"
  },
  /* Firefox */
  "&::-moz-progress-bar": {
    backgroundColor: "#f49400",
    borderRadius: "4px",
    transition: "width 0.5s"
  }
}));

const StyledIconCircle = styled("div")(({ theme, ...props }) => ({
  width: 40,
  padding: 7,
  backgroundColor: props.done
    ? "#f49400"
    : props.incomplete
    ? "#ff845e"
    : "#fb5b5b",
  color: "white",
  borderRadius: "50%",
  textAlign: "center"
}));

export default HaulerAllocation;
