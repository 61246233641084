import {
  Box,
  Paper,
  TextField,
  Typography,
  makeStyles,
  styled
} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  dateInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem"
    }
  },
  table: {
    backgroundColor: "#FFFFFF"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  },
  fabText: {
    fontWeight: "bold",
    marginRight: 8,
    letterSpacing: 1.2,
    textTransform: "capitalize"
  },
  container: {
    width: "100%",
    height: "calc(100% - 64px)",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  gridWrapper: {
    width: "90%",
    margin: "0 auto",
    height: "90%"
  },
  btnContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 24px",
    margin: "0.5rem 0",
    marginBottom: 0
  },
  btnRounded: {
    borderRadius: 100,
    width: 200
  },
  iconContainer: {
    backgroundColor: "#ffb864",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    // width: "100%",
    width: 70,
    height: 70,
    borderRadius: 10
  },
  datetimeField: {
    background: "#f4f4f4",
    padding: "5px 15px",
    borderRadius: 4,
    marginTop: 4
  },
  datetimeLbl: {
    fontSize: 14
  },
  detailedViewTable: {
    "& .MuiTableCell-stickyHeader": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      borderTop: "none !important"
    },
    "& .MuiTableBody-root .MuiTableCell-root": {
      borderBottom: "none"
    },
    height: "250px !important",
    border: "1px solid rgb(224, 224, 224)",
    borderRadius: 8,
    overflow: "clip"
  },
  swalBtn: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "100px !important"
  },
  swalIcon: {
    color: "#ffb864 !important",
    borderColor: "#ffb864 !important",
    border: "0.5em solid",
    fontWeight: "600 !important"
  },
  undoBtn: {
    textTransform: "capitalize !important"
  },
  popoverOverlay: {
    background: "#00000030",
    "& .MuiPopover-paper": {
      marginTop: "-8px",
      background: "transparent",
      boxShadow: "none"
    }
  },
  popoverOptions: {
    background: "#fff",
    "&:hover": {
      background: "#fff",
      outline: "2px solid #fbbf4c"
    },
    padding: "0 10px",
    maxWidth: 350,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: 5,
    height: 90
  },
  popoverItem: {
    alignContent: "center"
  },
  haulerDropdown: {
    fontSize: 13,
    "& .MuiSelect-select:focus": {
      background: "transparent"
    }
  },
  haulerDropdownMenu: {
    marginTop: "7px",
    marginLeft: "-3px",
    fontSize: 13
  },
  tabLabel: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    "& > *": {
      fontSize: 13,
      fontWeight: 700,
      letterSpacing: 0.5,
      textTransform: "capitalize"
    }
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-around",
    boxShadow: "0 2px 3px -3px #a2a2a2",
    fontSize: 13,
    color: "#a2a2a2",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      flexWrap: 'wrap',
    },
    "&.MuiTabs-root": {
      boxShadow: "none"
    },
    "&.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
      height: 3
    }
  },
  tab: {
    color: "inherit",
    "&.MuiTab-root": {
      border: 0,
      borderRadius: 0,
      fontSize: 13,
      minWidth: 120
    },
    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "transparent"
    },
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "transparent",
      color: "#f49400",
      "& .MuiChip-root": {
        backgroundColor: "#f49400",
        color: "#fff"
      }
    },
    "& span": {
      textTransform: "none",
      fontWeight: 500
    }
  }
}));

export const StyledDateTimePicker = styled(TextField)(
  ({ theme, ...styles }) => ({
    ...styles,
    background: "#f4f4f4",
    padding: "5px 15px",
    borderRadius: 4,
    marginTop: 4
  })
);

export const StyledTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8
}));

export const StyledTitle = styled(Typography)(({ theme, ...props }) => ({
  fontWeight: "bold",
  letterSpacing: 0.25
}));

export const Item = styled(Paper)(({ theme, ...props }) => ({
  padding: props.padding ? `${props?.padding}px` : theme.spacing(2),
  boxSizing: "border-box",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  border: "1px solid #D4D4D4",
  borderRadius: 8,
  boxShadow: "0px 5px 5px #BCBCBC",
  overflow: "hidden"
  // justifyContent: "center"
}));

export const TableWrapper = styled(Box)(({ theme, ...props }) => ({
  height: "100%",
  "& .MuiTableCell-stickyHeader": {
    background: props?.defaultHeader ? "" : "#fff",
    // borderTop: "1px solid rgba(224, 224, 224, 1)",
    padding: "8px 5px"
  },
  "& .MuiTableContainer-root": {
    height: "100% !important",
    overflow: "auto",
    "& > .MuiTable-root": {
      height: props.fullHeight ? "100% !important" : "auto",
      "& > .MuiTableBody-root": {
        height: "100%"
      },
      "& .MuiTableCell-root": {
        padding: props?.tdPadding,
        fontSize: 13
      }
    }
  },
  "& ::-webkit-scrollbar-thumb": {
    border: "5px solid white",
    borderRadius: 8,
    background: "rgba(150, 150, 150, 0.4)",
    width: 15,
    height: 15
  }
}));
