import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Styles
import useFormStyles from "../Automated/styles/useFormStyles";

// Queries
import { GET_REPORT_TYPES_QUERY } from "../../../graphql/Queries";

const ReportTypeInput = React.forwardRef((props, ref) => {
  const {
    initialReportType = null,
    readOnly = false,
    onChange,
    onLoad = () => {}
  } = props;
  const { loading, data } = useQuery(GET_REPORT_TYPES_QUERY, {
    onCompleted: response => {
      onLoad(response);
      // onLoad(
      //   new Promise(resolve => {
      //     resolve(response);
      //   })
      // );
    }
  });
  const styles = useFormStyles();

  const [type, setType] = useState({
    id: -1,
    name: "",
    headers: [[]]
  });

  useEffect(() => {
    if (initialReportType) {
      setType(initialReportType);
    }
  }, [initialReportType]);

  useEffect(() => {
    if (readOnly) {
      setType(initialReportType);
    }
  }, [readOnly]);

  useEffect(() => {
    if (type?.id !== "")
      // eslint-disable-next-line no-param-reassign
      ref.current = type;
  }, [type]);

  const options = React.useMemo(() => {
    if (!data?.get_report_types) return [];
    return [
      ...data?.get_report_types,
      {
        id: -1,
        name: "",
        headers: [[]]
      }
    ];
  }, [data?.get_report_types]);

  return (
    <div className={styles.formRoot}>
      <Autocomplete
        filterOptions={options => {
          return options.filter(option => option.id !== -1);
        }}
        style={{ width: "250px" }}
        loading={loading}
        options={options}
        getOptionLabel={option => option.name?.toString() || ""}
        getOptionSelected={(o, v) => +v.id === +o.id}
        value={type}
        // defaultValue={type}
        onChange={(_, newValue) => {
          setType(newValue);
          onChange(newValue);
        }}
        disabled={readOnly}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: styles.textFieldRoot }}
            required={!readOnly}
            placeholder="Select Report Type"
            label="Report Type"
            margin="none"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: readOnly,
              disabled: readOnly
            }}
          />
        )}
      />
    </div>
  );
});

export default ReportTypeInput;
