import React, { useState, useCallback, useEffect } from "react";
import { Box, Tooltip, Typography, styled } from "@material-ui/core";
import _ from "lodash";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import SearchVehicles from "./SearchVehicles";
import VehicleTable from "./VehicleTable";
import ModalVehicles from "./ModalVehicles";

const Vehicles = props => {
  const { isEditMode, setState, fetchedData } = props;
  const [vehicles, setVehicles] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [order, setOrder] = useState(false);
  const [searchedVehicle, setSearchedVehicle] = useState("");

  const onModalHandler = () => setIsOpenModal(!isOpenModal);

  useEffect(() => {
    setVehicles(_.orderBy(vehicles, ["plateno"], order ? "desc" : "asc"));
  }, [order]);

  useEffect(() => {
    setVehicles(fetchedData);
    setSelectedVehicles(fetchedData);
  }, []);

  useEffect(() => {
    if (!isEditMode) {
      setVehicles(fetchedData);
      setSelectedVehicles(fetchedData)
    }
  }, [isEditMode]);

  useEffect(() => {
    const vehiclesTemp = [];
    if (vehicles.length > 0) {
      selectedVehicles.forEach(item => {
        vehiclesTemp.push(+item.id);
      });
      setState(prev => ({ ...prev, vehicle_id: vehiclesTemp }));
    } else {
      setState(prev => ({ ...prev, vehicle_id: [] }));
    }
  }, [selectedVehicles, vehicles]);

  return (
    <>
      <ContainerStyled style={{ justifyContent: "space-between" }}>
        <ContainerStyled style={{ gap: 7 }}>
          <Tooltip
            title="Select specific vehicle/s to be notified from"
            placement="top-start"
          >
            <HelpRoundedIcon fontSize="small" color="disabled" />
          </Tooltip>
          <HeaderTitleStyled> Vehicles </HeaderTitleStyled>
        </ContainerStyled>
        <SearchVehicles setSearchedVehicle={setSearchedVehicle} />
      </ContainerStyled>
      <VehicleTable
        data={
          searchedVehicle
            ? vehicles.filter(
                vehicle => vehicle.plateno.trim() === searchedVehicle
              )
            : vehicles
        }
        setVehicles={setVehicles}
        modalFn={onModalHandler}
        isAddEntryShown={true}
        selectedVehicles={selectedVehicles}
        setSelectedVehicles={setSelectedVehicles}
        setOrder={setOrder}
        order={order}
        isEditMode={isEditMode}
        searchedVehicle={searchedVehicle}
      />
      {isOpenModal && (
        <ModalVehicles
          isOpen={isOpenModal}
          modalFn={onModalHandler}
          setVehicles={setVehicles}
          setSelected={setSelectedVehicles}
          vehicles={vehicles}
        />
      )}
    </>
  );
};

export default Vehicles;

const ContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%"
}));

const HeaderTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 17,
  fontWeight: 600,
  letterSpacing: "1.25px"
}));
