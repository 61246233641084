import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  DialogContentText,
  TextField,
  Box,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import { ADD_DASHBOARD_FILTER } from "../../../graphql/Mutations";
import { GET_DASHBOARDS_FILTERS } from "../../../graphql/Queries";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import validate from "../../../utils/validation";
import AddLogs from "../../../utils/functions/AddLogs";
import useUserContext from "../../../context/User/useUserContext";
import Summary from "./Summary";
// constant values that doesn't change. 
const DEBOUNCE_TIME = 800;
const ADD_LOG_OTSA = "save_filter_OTTA/OTSA/OTD";
const ADD_LOG_DEMURRAGE = "save_filter_demurrage";
const ONTIME_DASHBOARD_TYPE = "ontime";
const DEMURRAGE_DASHBOARD_TYPE = "demurrage";
const ONTIME_ID = 0;
const ERROR_LOGS = [
  {
    label: "NO_VALUE",
    description: "This field is required."
  },
  {
    label: "MAX_CHAR_LENGTH",
    description: "Maximum characters length is 100."
  },
  {
    label: "INVALID_VALUE",
    description: "Alphanumeric characters only."
  },
  {
    label: "VALUE_EXISTS",
    description: "Filter name already exist"
  }
];

const Form = props => {
  const classes = useStyles();
  const {
    closeFormHandler,
    toggle,
    filters,
    saveFilter,
    tab,
    resetFilterHandler
  } = props;

  const user = useUserContext();
  const [filter, setFilter] = useState(filters);
  const [hasError, setHasError] = useState("");

  const [filterMutation, { loading }] = useMutation(ADD_DASHBOARD_FILTER, {
    onCompleted: data => {
      if (data.add_dashboard_filter.success) {
        closeFormHandler();
        resetFilterHandler();
        Swal.fire({
          icon: "success",
          text: "Saved.",
          timer: 3000
        });
      }
    },
    update: (cache, { data: { add_dashboard_filter } }) => {
      const { get_dashboard_filters } = cache.readQuery({
        query: GET_DASHBOARDS_FILTERS,
        variables: {
          user_id: user.id,
          dashboard_type: _.isEqual(tab, ONTIME_ID)
            ? ONTIME_DASHBOARD_TYPE
            : DEMURRAGE_DASHBOARD_TYPE
        }
      });
      const data = {
        label: filter.name,
        value: add_dashboard_filter.id
      };
      cache.writeQuery({
        query: GET_DASHBOARDS_FILTERS,
        variables: {
          user_id: user.id,
          dashboard_type: _.isEqual(tab, 0)
            ? ONTIME_DASHBOARD_TYPE
            : DEMURRAGE_DASHBOARD_TYPE
        },
        data: {
          get_dashboard_filters: get_dashboard_filters.concat(data)
        }
      });
    },
    onError: error => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong"
      });
    }
  });
  // validate enter filter name by user.
  function validateFilterName(value) {
    let tempError = "";
    if (!value) tempError = ERROR_LOGS[0].description;
    else if (value.length > 100) {
      tempError = ERROR_LOGS[1].description;
    } else if (_.isEqual(validate("alphanumeric_default", value), false)) {
      tempError = ERROR_LOGS[2].description;
    } else {
      const isExisting = saveFilter.some(filter =>
        _.isEqual(filter.label.toUpperCase(), value.toUpperCase())
      );
      // check if entered currently exist.
      if (isExisting) {
        tempError = ERROR_LOGS[3].description;
      } else {
        tempError = "";
      }
    }

    setHasError(tempError);
    return _.isEqual(tempError, "");
  }
  function checkInterIsland(value) {
    switch(value) {
      case 'Standard': return 0;
      case 'InterIsland': return 1;
      default: return null;
    }
  }

  // prepare filter values.
  function prepareFilterValues() {
    let vars = {};
    vars = {
      name: filter.name,
      client_id: +filter.client.value,
      account: filter.account?.value ? filter.account?.value : "",
      dashboard_type: _.isEqual(tab, ONTIME_ID)
        ? ONTIME_DASHBOARD_TYPE
        : DEMURRAGE_DASHBOARD_TYPE,
      user_id: user.id,
      group_ids: user.group_ids,
      is_interisland: checkInterIsland(filter.tripType.label),
    };

    switch (tab) {
      case 0:
        vars = {
          ...vars,
          pickup_ids: filter.pickups.map(pu => pu.value),
          dropoff_ids: filter.dropoffs.map(dropoff => dropoff.value),
          service_type_id: +filter.serviceType.value,
          location_ids: filter.locations.map(loc => loc.value)
        };
        break;
      case 1:
        vars = {
          ...vars,
          location_ids: filter.locations.map(loc => loc.value),
          vehicle_type_id: +filter.vehicleType.value
        };
        break;
      default: // default value
    }

    return vars;
  }
  // save filter function.
  function saveFilterHandler() {
    if (validateFilterName(filter.name)) {
      const log = _.isEqual(tab, ONTIME_ID) ? ADD_LOG_OTSA : ADD_LOG_DEMURRAGE;
      // save filter values on the database.
      filterMutation({ variables: prepareFilterValues() });
      // activity logs.
      AddLogs(process.env.REACT_APP_DASHBOARD_MODULE, log, filter.name);
    }
  }
  // function on change for text fields with debounce after user has typed.
  const onChangeHandler = _.debounce((name, value) => {
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  }, DEBOUNCE_TIME);

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={closeFormHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.closeContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          <span className={classes.closeTitle}>Filter Form</span>
          <Typography className={classes.subTitle}>
            Please check details before saving this filter
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            gridGap={10}
            justifyContent="space-around"
            style={{ borderBottom: "1px solid #f9f9f9", padding: "1rem 0" }}
          >
            <TextField
              label="Filter Name"
              placeholder="Enter filter name"
              size="small"
              fullWidth
              required
              defaultValue=" "
              onChange={event =>
                onChangeHandler(event.target.name, event.target.value.trim())
              }
              error={!_.isEqual(hasError, "")}
              helperText={hasError}
              autoFocus
              className={classes.text_field_select_filter}
              name="name"
              variant="outlined"
            />
            <TextField
              label={_.isEqual(tab, ONTIME_ID) ? "Port/CY" : "Location(Port/CY)"}
              placeholder="Enter port/cy"
              size="small"
              fullWidth
              defaultValue=" "
              className={classes.text_field_select_filter}
              name="port_cy_ids"
              onChange={event =>
                onChangeHandler(event.target.name, event.target.value.trim())
              }
              variant="outlined"
            />
          </Box>
          <Box>
            <Summary data={filter} tab={tab} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeFormHandler}
            className={classes.circularButtonNo}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={saveFilterHandler}
            color="primary"
            autoFocus
            className={classes.circularButtonYes}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Form;

const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 500,
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  subTitle: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },
  text_field_select_filter: {
    color: theme.palette.primary.dark,
    fontSize: 11,
    marginBottom: 10,
    "& .MuiAutocomplete-inputRoot": {
      fontSize: 11,
      color: theme.palette.primary.dark
    },
    "& input::placeholder": {
      fontSize: 11
    }
  }
}));
