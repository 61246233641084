import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
    paper:{
        height: "50px",
        width: "200px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    typography:{
        fontWeight: " bold"
    }
}));