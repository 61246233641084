import React, { useCallback } from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  TableHead,
  makeStyles,
  Checkbox
} from "@material-ui/core";
import _ from "lodash";
// create a table that will be reusable to different parts of the module.
const INITIAL_DIRECTION = "asc";
const DESCENDING = "desc";

const CustomTable = props => {
  const {
    headers,
    order,
    setOrder,
    hasCheckbox = false,
    data,
    selectedData,
    setSelectedData,
    filtered,
    getVariables = () => {}
  } = props;
  const classes = useStyles();
  // table data sort handler.
  function sortHandler(orderDirection, field) {
    const activeColumn = field;
    let direction = INITIAL_DIRECTION;
    // check if the field is same with the active field.
    if (_.isEqual(order.field, field)) {
      direction = _.isEqual(orderDirection, INITIAL_DIRECTION)
        ? DESCENDING
        : INITIAL_DIRECTION;
    }

    setOrder({ field: activeColumn, direction: direction });
  }
  // function handler validate is checked table cell.
  const isChecked = () => {
    let checked = { all: false, indeterminate: false };

    if (selectedData.length) {
      if (!selectedData.length < data?.length) {
        const displayedData = data.map(data => data.employeeID);
        const tempSelectedEmp = selectedData.map(info => info.employeeID);
        if (displayedData.every(id => tempSelectedEmp.indexOf(id) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(id => tempSelectedEmp.indexOf(id) > -1))
          checked = { all: false, indeterminate: true };
      }
    }
    return checked;
  };

  // function handler select all vehicles
  function selectAllHandler(event) {
    const tempSelected = selectedData.map(info => info);
    const deselect = () => {
      const displayedData = data.filter(n => n);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map((id, index) => selectedData[index]);
      setSelectedData(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }

      data.map(n => tempSelected.push(n));
      setSelectedData(_.uniq(tempSelected));
      return;
    }
    deselect();
  }

  // custom header for table.
  const customTableHeader = useCallback(() => {
    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {(getVariables()?.condition?.length > 0 ||
            getVariables()?.dateFilter?.field ===
              "driver_attendance.time_in") && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isChecked().indeterminate}
                  checked={isChecked().all}
                  onChange={selectAllHandler}
                  color="primary"
                />
              </TableCell>
            )}
          {headers.map(header => (
            <TableCell
              key={header.id}
              // align={header.label === "Actions" ? "left" : "left"}
              sortDirection="desc"
              style={{
                padding:
                  header.id === "personnel_employeeid" && "12px 12px 12px 20px"
              }}
            >
              {!_.isEqual(header.id, "actions") && header.isSortable ? (
                <TableSortLabel
                  active={_.isEqual(order.field, header.id)}
                  direction={order.direction}
                  onClick={() => sortHandler(order.direction, header.id)}
                >
                  <span className={classes.label}>{header.label}</span>
                </TableSortLabel>
              ) : (
                <span className={classes.label}>{header.label}</span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, [headers, selectedData, filtered]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        {customTableHeader()}
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

const useStyles = makeStyles(theme => ({
  label: {
    whiteSpace: "nowrap",
    color: theme.palette.primary.dark,
    fontSize: 13,
    fontWeight: "bold"
  },
  table_head: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #e3e3e3",
    borderBottom: "1px solid #e3e3e3"
  },
  table_data: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  tableContainer: {
    overflowY: "auto",
    // maxHeight: "calc(100vh - 110px)",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  }
}));
