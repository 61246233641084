import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  link: {
    textDecoration: "none !important",
    color: "inherit"
  },
  button: {
    padding: "5px 20px",
    borderRadius: 20, // (50px)
    boxShadow: "0px 5px 10px -5px #b3b3b3",
    textTransform: "capitalize",
    letterSpacing: 0.25,
    margin: "auto 5px",
    maxWidth: props => props?.maxWidth ?? 200,
    minWidth: props => props?.minWidth ?? 200,
    background: props =>
      props?.buttonType === "download" ? "#FF7B5A" : "auto",
    "&:hover": {
      background: props =>
        props?.buttonType === "download" ? "#ec6846" : "auto"
    },
    color: theme.palette.primary.contrastText
  },
  rootZoom: {
    transitionDelay: props =>
      !props?.loading && props?.permissions?.view ? "500ms" : "0ms"
  }
}));
