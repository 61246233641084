import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    fontFamily: "nunito"
  },
  header: {
    position: "relative",
    height: "8%",
    width: "100%",
    overflow: "hidden"
  },
  logo: {
    position: "absolute",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: 150,
      position: "relative",
      height: "auto",
      marginTop: 30,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  appbar: {
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  logo_header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  timer_header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  timerHeaderText: {
    fontSize: 11,
    color: theme.palette.grey[400]
  },
  timer: {
    color: theme.palette.primary.main,
    padding: "5px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center"
    // borderRadius: "8px"
  },
  info_box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 25px 12px 25px"
  },
  left_header: {
    display: "flex",
    alignItems: "center",
    gap: 10
  },
  alignment: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
      alignItems: "flex-start"
    }
  },
  btn: {
    border: "none",
    borderRadius: "50px",
    backgroundColor: "#dedede",
    color: "black",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      fontSize: 12
    }
  },
  outlined: {
    color: "#545454 !important",
    border: "1px solid #d1d1d1 !important"
  },
  outlinedPrimary: {
    "&:hover": {
      backgroundColor: "#f9f9f9"
    }
  },
  padding: {
    padding: "0px 15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      color: theme.palette.grey[400]
    }
  },
  text_count: {
    color: theme.palette.primary.main
  },
  vehicleCountBox: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      transition: "0.3s ease-in"
    }
  },
  gps_count: {
    color: theme.palette.primary.main
  },
  listItem: {
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiListItemText-primary": {
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  countResult: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    }
  },
  nestedActive: {
    paddingLeft: theme.spacing(4),
    color: "gray",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      transition: "0.3s ease-in"
    }
  },
  nestedDisabled: {
    paddingLeft: theme.spacing(4),
    color: "gray"
  },
  map_container: {
    position: "relative",
    height: "calc(100vh - 119px)",
    // width: "100vw",
    overflow: "hidden"
  },
  infoWindowContainer: {
    position: "absolute",
    bottom: 0,
    maxWidth: "300px"
  },
  labelIconStatus: {
    width: "0.5em",
    height: "0.5em",
    marginRight: 6
  },
  info_plate: {
    display: "flex",
    alignItems: "center"
  },
  info_content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dialog_paper: {
    borderRadius: 12,
    paddingBottom: 5,
    paddingRight: 5
  },
  dialog_title: {
    color: theme.palette.primary.dark,
    fontWeight: "bold"
  },
  dialog_content: {
    color: theme.palette.primary.dark,
    fontSize: "15px"
  },
  refresh: {
    borderRadius: "22px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: 110,
    fontSize: 14,
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  refreshIcon: {
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: 14,
    width: "10px"
  },
  batteryLevel: {
    fontWeight: "bold",
    fontSize: 10
  },
  batteryReportStamp: {
    fontSize: 10
  },
  timerBox: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      justifyContent: "center",
      padding: 0
    }
  },
  timerContainer: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem 0.5rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    minWidth: 20,
    [theme.breakpoints.down("xs")]: {
      // "&:first-child": {
      //   borderLeft: "none"
      // },
      // borderLeft: `1px solid ${theme.palette.common.white}`,
      borderRadius: 0,
      flexFlow: "row",
      gap: 3
    }
  },
  timerBoxText: {
    fontSize: 9,
    color: theme.palette.common.white
  },
  timerCount: {
    fontSize: 13,
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11
    }
  },
  timerLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      color: theme.palette.common.white
    }
  },
  locationTitle: {
    color: theme.palette.grey[400],
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    }
  },
  locationDateTime: {
    color: theme.palette.primary.dark,
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    }
  },
  locationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  locationBox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
      justifyContent: "center"
    }
  }
}));
