import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const SearchVehicles = props => {
  const { setSearchedVehicle } = props;

  return (
    <>
      <TextField
        placeholder="Search Vehicle"
        margin="normal"
        variant="outlined"
        fontSize="12px"
        fullWidth
        size="small"
        onChange={event => setSearchedVehicle(event.target.value)}
        InputProps={{
          style: {
            borderRadius: 50,
            fontSize: 14
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="disabled" fontSize="small" />
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default SearchVehicles;
