import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  Box
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { Close } from "@material-ui/icons";

import OTTA from "./Charts/OTTA";
import Land from "./Charts/Land";
import CloseCancel from "./Charts/CloseCancel";
import AccessControl from "../../../AccessControl";
import OTD from "./Charts/OTD";
import { useOnboardingContext } from "../../index";
import Focus from "../../common/Focus";
import Stage from "../../common/Stage/Stage";
import dashboard_welcome from "../../images/dashboard-check-your-vehicles-performance.svg";
// import FilterForm from "./FilterForm";

const useStyles = makeStyles({
  root: {
    margin: 0
  },
  appBar: {
    backgroundColor: "white"
  },
  panelRoot: {
    border: "1px solid #D6D6D6"
    // padding: 8
  },
  select: {
    height: 30
  },
  filterContainer: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  primButton: {
    borderRadius: "16px !important",
    textTransform: "capitalize !important"
  },
  secButton: {
    // border: "1px solid red !important",
    // color: "red !important",
    borderRadius: "16px !important",
    textTransform: "capitalize !important"
  },
  disabledButton: {
    // border: "1px solid rgba(0, 0, 0, 0.26)",
    // color: "rgba(0, 0, 0, 0.26)",
    // border: "1px solid #F49400",
    // color: "#F49400",
    // opacity: 0.5,
    borderRadius: "16px !important",
    textTransform: "capitalize !important",
    cursor: "default",
    pointerEvents: "none"
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  autoRoot: {
    width: 350,
    margin: 0
  },
  tabsRoot: {
    width: "fit-content",
    overflow: "visible !important",
    paddingInline: 8,
    "& .MuiTabs-scroller.MuiTabs-fixed": {
      overflow: "visible !important",
      whiteSpace: "unset"
    }
    // "&::after": {
    //   content: "''",
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   height: "100%",
    //   width: "100%",
    //   background: "transparent"
    // }
  }
});

const Dashboards = () => {
  const classes = useStyles();
  // const [tabValue, setTabValue] = useState(0);
  const [tabValue] = useState(0);
  // const [filterValues, setFilterValues] = useState([
  //   {
  //     serviceType: [{ value: 0, label: "All" }],
  //     client: [{ value: 0, label: "All" }],
  //     account: [{ value: "", label: "All" }],
  //     pickups: [],
  //     dropoffs: [],
  //     locations: [],
  //     dateRange: {
  //       start: moment().format("YYYY-MM-DD 00:00:00"),
  //       end: moment().format("YYYY-MM-DD 23:59:59")
  //     }
  //   }
  // ]);
  const [filterValues] = useState([
    {
      serviceType: [{ value: 0, label: "All" }],
      client: [{ value: 0, label: "All" }],
      account: [{ value: "", label: "All" }],
      pickups: [],
      dropoffs: [],
      locations: [],
      dateRange: {
        start: moment().format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59")
      }
    }
  ]);

  const {
    progress: { dashboard: currentStep },
    completeOnboarding
  } = useOnboardingContext();

  const stage = {
    0: {
      image: dashboard_welcome,
      title: "Dashboard: Check your Delivery Performance",
      description: `Monitor your delivery performance metrics in this module`,
      buttonText: "Got It"
    },
    5: {
      buttonText: "Start",
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };

  return (
    <AccessControl process="dashboard" resource="/dashboard/">
      <div className={classes.root}>
        <Focus
          step={[0, 5]}
          on={[0, 5].includes(currentStep)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: [0, 5].includes(currentStep) ? "block" : "none",
            background: "transparent"
          }}
          showInfoBubble={false}
        >
          <Stage {...stage[currentStep]} />
        </Focus>
        <AppBar position="static" elevation={0} className={classes.appBar}>
          <Focus
            step={1}
            component={Tabs}
            value={0}
            // indicatorColor="primary"
            // onChange={handleTabChange}
            className={classes.tabsRoot}
            infoBubbleProps={{
              title: "Menu Tab",
              description:
                "Displays the performance metrics of Active and in Demurrage vehicles"
            }}
          >
            <Tab
              selected
              color="primary"
              value={0}
              label="OTTA/OTSA/OTD"
              // style={{ color: "black", fontWeight: "bold" }}
            />
            <Tab value={1} label="Demurrage" />
            <Tab value={2} label="Overview" />
          </Focus>
        </AppBar>
        <div className={classes.panelRoot}>
          {" "}
          {/* Filter start */}
          <Focus
            step={2}
            infoBubbleProps={{
              position: "bottom",
              title: "Filter",
              description:
                "Allows to display certain data based on selected values"
            }}
            style={{ padding: 8 }}
          >
            <React.Fragment>
              <div style={{ display: "inline" }}>
                Saved Filters:
                <Autocomplete
                  options={[]}
                  getOptionLabel={option => option.label?.toString()}
                  value={{
                    value: 0,
                    label: "Select Filter"
                  }}
                  // onChange={selectFilter}
                  getOptionDisabled={option => option.value === 0}
                  getOptionSelected={selected => selected}
                  renderOption={option => {
                    return (
                      <Box
                        fontSize="caption.fontSize"
                        fontWeight="fontWeightBold"
                      >
                        {option.label}
                        {option.value !== 0 && (
                          <IconButton edge="end" style={{ color: "red" }}>
                            <Close />
                          </IconButton>
                        )}
                      </Box>
                    );
                  }}
                  classes={{ root: classes.autoRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      style={{ width: 350 }}
                      placeholder="Select Filter"
                    />
                  )}
                  disableClearable
                />
              </div>
              <div className={classes.filterContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        {tabValue === 0 ? (
                          <Autocomplete
                            options={[]}
                            getOptionLabel={option => option.label?.toString()}
                            // renderOption={option => option.label}
                            renderInput={params => (
                              <TextField {...params} label="Service Types" />
                            )}
                            value={filterValues[tabValue].serviceType}
                            // onChange={(e, nv) => {
                            //   const temp = [...filterValues];
                            //   temp[tabValue] = {
                            //     ...temp[tabValue],
                            //     serviceType: nv
                            //   };
                            //   setFilterValues(temp);
                            // }}
                            disableClearable
                          />
                        ) : (
                          <Autocomplete
                            options={[]}
                            getOptionLabel={option => option.label?.toString()}
                            renderOption={option => option.label}
                            renderInput={params => (
                              <TextField {...params} label="Vehicle Type" />
                            )}
                            value={filterValues[tabValue].vehicleType}
                            // onChange={(e, nv) => {
                            //   const temp = [...filterValues];
                            //   temp[tabValue] = {
                            //     ...temp[tabValue],
                            //     vehicleType: nv
                            //   };
                            //   setFilterValues(temp);
                            // }}
                            disableClearable
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          options={[]}
                          getOptionLabel={option => option.label}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Hauler/Shipping Line"
                            />
                          )}
                          value={filterValues[tabValue].client}
                          // onChange={(e, nv) => {
                          //   const temp = [...filterValues];
                          //   temp[tabValue] = {
                          //     ...temp[tabValue],
                          //     client: nv
                          //   };
                          //   setFilterValues(temp);
                          // }}
                          disableClearable
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          options={[]}
                          // getOptionLabel={option => option.label.toString()}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField {...params} label="Accounts" />
                          )}
                          value={filterValues[tabValue].account}
                          // onChange={(e, nv) => {
                          //   const temp = [...filterValues];
                          //   temp[tabValue] = {
                          //     ...temp[tabValue],
                          //     account: nv
                          //   };
                          //   setFilterValues(temp);
                          // }}
                          disableClearable
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: 5 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={3} />
                      <Grid item xs={3}>
                        <Button
                          variant="outlined"
                          fullWidth
                          className={classes.secButton}
                        >
                          Reset
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.primButton}
                        >
                          Apply
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.primButton}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          </Focus>
          <div style={{ padding: 8 }}>
            <Typography>As of {moment().format("lll")}</Typography>
            <Grid container spacing={1}>
              {tabValue === 0 ? (
                <Fragment>
                  <Grid item xs={6}>
                    <OTTA />
                  </Grid>
                  <Grid item xs={6}>
                    <OTD />
                  </Grid>
                  <Grid item xs={6}>
                    <CloseCancel />
                  </Grid>
                </Fragment>
              ) : (
                <Grid item xs={6}>
                  <Land />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default withRouter(Dashboards);
