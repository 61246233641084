import React, { useRef } from "react";

import useDraggableItemStyles from "./DraggableItem.styles";

const DraggableItem = props => {
  const {
    index,
    draggable,
    onDropHandler,
    onDragStartHandler,
    children,
    ...elementProps
  } = props;
  const styles = useDraggableItemStyles(props);
  const currentItem = useRef(null);

  function onDragStart() {
    // Prevent dragging if item is not draggable.
    if (!draggable) return;
    // Triggers when user start dragging an item.
    onDragStartHandler(index);
  }

  function onDragEnter() {
    // Triggers when user drag the item over other item in the list.
    currentItem.current.classList.add("drag-over");
  }

  function onDragOver(e) {
    // Triggers when user drag the item over other item in the list.
    // The only diffence of dragOver from onDragEnter is that,
    // dragOver is running 'continuously' while the dragging item is over the other item.
    e.preventDefault();
  }

  function onDragLeave() {
    // Triggers when the dragging item is leave over another item.
    currentItem.current.classList.remove("drag-over");
  }

  function onDrop() {
    // Triggers when user released the item.
    currentItem.current.classList.remove("drag-over");
    // If item is not draggable, it prevents other item to be dropped on it
    if (!draggable) return;
    // Invoke onDropHandler listener
    onDropHandler(index);
  }

  return (
    <div
      ref={currentItem}
      className={styles.draggableItem}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      {...elementProps}
    >
      {children}
    </div>
  );
};

export default DraggableItem;
