import React from "react";
import { MarkerClusterer } from "@react-google-maps/api";

const Clusterer = ({ children, ...clustererOptions }) => {
  return (
    <MarkerClusterer
      options={{
        // imageSizes: [12, 12, 12],
        // imagePath: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m`,
        styles: [
          {
            height: 50,
            textColor: "white",
            textSize: 12,
            width: 50,
            anchorIcon: [25, 25],
            anchorText: [0, 0],
            url: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m1.png`
          },
          {
            height: 55,
            textColor: "white",
            textSize: 12,
            width: 55,
            anchorIcon: [27, 27],
            anchorText: [0, 0],
            url: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m2.png`
          },
          {
            height: 65,
            textColor: "white",
            textSize: 12,
            width: 65,
            anchorIcon: [32, 32],
            anchorText: [0, 0],
            url: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m3.png`
          },
          {
            height: 75,
            textColor: "white",
            textSize: 12,
            width: 75,
            anchorIcon: [37, 37],
            anchorText: [0, 0],
            url: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m5.png`
          },
          {
            height: 90,
            textColor: "white",
            textSize: 12,
            width: 90,
            anchorIcon: [45, 45],
            anchorText: [0, 0],
            url: `${process.env.PUBLIC_URL}/assets/MarkerClusterer/m5.png`
          }
        ]
      }}
      {...clustererOptions}
    >
      {cluster => children(cluster)}
    </MarkerClusterer>
  );
};

export default Clusterer;
