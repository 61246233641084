import ManualMain from "./main";
import AutomatedMain from "./Automated/main";
import AddGeneratedReport from "./Automated/Add";
import ViewGeneratedReport from "./Automated/View";

export default {
  Create: ManualMain,
  Automated: AutomatedMain,
  AddGeneratedReport,
  ViewGeneratedReport
};
