import React, { useState, useRef, useEffect } from "react";
import { Button, Box, makeStyles } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import useComponentVisible from "../../hooks/useComponentVisible";

// create a custom dropdown navigation.
const CustomDropdown = props => {
  const { options, choice, setChoice } = props;
  const classes = useStyles();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
  // functions 
  const toggleHandler = () =>  {
    setIsComponentVisible(!isComponentVisible);
  };

  const optionClickHandler = value => {
    setChoice(value);
    setIsComponentVisible(false);
  };


  return (
    <div className={classes.custom_dropdown_container} ref={ref}>
      <div className={classes.dropdown_btn} onClick={toggleHandler}>
        {choice.label}
        {isComponentVisible ? (
          <ExpandLessRoundedIcon className={classes.icon_btn} />
        ) : (
          <ExpandMoreRoundedIcon className={classes.icon_btn} />
        )}
      </div>
      {isComponentVisible && (
        <div className={classes.container_options}>
          {options.map(option => {
            return (
              <div
                key={option.id}
                value={option.id}
                className={classes.options_values}
                onClick={() => optionClickHandler(option)}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;

const useStyles = makeStyles(theme => ({
  custom_dropdown_container: {
    position: "relative"
  },
  dropdown_btn: {
    textTransform: "none",
    backgroundColor: "#FFB74D",
    width: `clamp(140px, 100%, 200px)`,
    borderRadius: 7,
    color: "#FFFFFF",
    transition: "0.3s",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.35rem 0.75rem",
    cursor: "pointer",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "rgba(244,148,0, 65%)"
    }
  },
  icon_btn: {
    fontSize: 20
  },
  container_options: {
    position: "absolute",
    width: `clamp(140px, 100%, 200px)`,
    zIndex: 999,
    boxShadow: "-1px 6px 10px 0px rgba(0,0,0,0.49)",
    borderRadius: 7,
    backgroundColor: "#FFFFFF",
    marginTop: "0.5rem"
  },
  options_values: {
    fontSize: 13,
    padding: "0.4rem 1rem",
    transition: "0.3s",
    backgroundColor: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f6f6f6"
    }
  }
}));
