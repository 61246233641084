/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, Fragment, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import { Close } from "@material-ui/icons";
import _ from "lodash";
import {
  GET_SERVICE_TYPES,
  GET_CLIENTS_DASHBOARDS,
  GET_CHART_DATA,
  GET_ACCOUNTS,
  GET_BOOKING_GEOFENCES,
  GET_DASHBOARDS_FILTERS,
  GET_DASHBOARDS_FILTER,
  GET_VEHICLE_TYPE_LIST,
  GET_DEMURRAGE_DATA,
  GET_ADMIN_CLIENT,
  GET_ACCESS_OBJECTS
} from "../../graphql/Queries";

import OTTA from "./Charts/OTTA";
import Land from "./Charts/Land";
import CloseCancel from "./Charts/CloseCancel";
import Loading from "../../utils/Loading";
import AccessControl from "../../utils/AccessControl";
import OTD from "./Charts/OTD";
import FilterForm from "./FilterForm";
import { DELETE_DASHBOARD_FILTER } from "../../graphql/Mutations";
import AddLogs from "../../utils/functions/AddLogs";
import Fleet from "./Fleet/Fleet";
import client from "../../Client";
import useUserContext from "../../context/User/useUserContext";

const useStyles = makeStyles({
  root: {
    margin: 0
  },
  appBar: {
    // backgroundColor: "white"
    backgroundColor: "#EAEAEA",
    marginTop: "1rem"
  },
  panelRoot: {
    // border: "1px solid #D6D6D6",
    padding: 8,
    backgroundColor: "#FFFFFF",
    maxHeight: "80vh",
    overflowY: "auto"
  },
  select: {
    height: 30
  },
  filterContainer: {
    padding: 8,
    // border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  primButton: {
    borderRadius: "16px !important",
    textTransform: "capitalize !important"
  },
  secButton: {
    // border: "1px solid red !important",
    // color: "red !important",
    borderRadius: "16px !important",
    textTransform: "capitalize !important"
  },
  disabledButton: {
    // border: "1px solid rgba(0, 0, 0, 0.26)",
    // color: "rgba(0, 0, 0, 0.26)",
    // border: "1px solid #F49400",
    // color: "#F49400",
    // opacity: 0.5,
    borderRadius: "16px !important",
    textTransform: "capitalize !important",
    cursor: "default",
    pointerEvents: "none"
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  autoRoot: {
    width: 350,
    margin: 0
  }
});

// options for tabs.
const tabOptions = [
  {
    id: 1,
    label: "OTTA/OTSA/OTD",
    value: 0
  },
  {
    id: 2,
    label: "Demmurage",
    value: 1
  },
  {
    id: 3,
    label: "Overview",
    value: 2
  }
];

// const StyledTab = withStyles(theme => ({
//   root: {
//     backgroundColor: "white",
//     border: "1px solid #D6D6D6",
//     borderTopLeftRadius: 16,
//     borderTopRightRadius: 16,
//     fontSize: 14
//   }

//   // selected: {
//   //   color: "white !important",
//   //   backgroundColor: "#53A4F7"
//   // color: theme.palette.primary.main,
//   // backgroundColor: theme.palette.primary.main
//   // }
// }))(Tab);


const OVERVIEW_OPT = "Overview";
const DEMURRAGE_OPT = "Demmurage";
const ONTIME_OPT = "OTTA/OTSA/OTD";

const DynamicDialog = props => {
  const { dynamicDialog, setDynamicDialog, classes } = props;
  const { title, content, yesFn, data, open } = dynamicDialog;
  return (
    <Dialog open={open}>
      <DialogTitle style={{ color: data ? "black" : "red" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {data && (
          <Button
            variant="outlined"
            className={classes.secButton}
            onClick={() => setDynamicDialog({ ...dynamicDialog, open: false })}
          >
            No
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.primButton}
          onClick={() => {
            if (data) {
              yesFn(data);
            }
            setDynamicDialog({ ...dynamicDialog, open: false });
          }}
        >
          {!data ? "OK" : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// set tab value for selected tabs.
function tabValueHandler(initialValue) {
  let tabValue = 0;
  switch (initialValue) {
    case "Demmurage":
      tabValue = 1;
      break;
    case "Overview":
      tabValue = 2;
      break;
    default:
      tabValue = 0;
      break;
  }

  return tabValue;
}

const Dashboards = props => {
  const { history } = props;
  // const user = JSON.parse(Cookie.get("user"));
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [serviceTypes, setServiceTypes] = useState([
    { value: 0, label: "All" }
  ]);
  const [clients, setClients] = useState([{ value: 0, label: "All" }]);
  const [accounts, setAccounts] = useState([{ value: "", label: "All" }]);
  const [vehicleTypes, setVehicleTypes] = useState([
    { value: 0, label: "All" }
  ]);
  const [pickups, setPickups] = useState([]);
  const [dropoffs, setDropoffs] = useState([]);
  const [pickupKeyword, setPickupKeyword] = useState("");
  const [dropoffKeyword, setDropoffKeyword] = useState("");
  const [clientKeyword, setClientKeyword] = useState("");
  const [dashBoardAccess, setDashboardAccess] = useState([]);
  const [openFilterForm, setOpenFilterForm] = useState(false);
  const [saveFilters, setSaveFilters] = useState([
    { value: 0, label: "Select Filter" }
  ]);

  const [selectedFilter, setSelectedFilter] = useState([
    {
      value: 0,
      label: "Select Filter"
    },
    {
      value: 0,
      label: "Select Filter"
    }
  ]);

  const [dynamicDialog, setDynamicDialog] = useState({
    open: false,
    title: "",
    content: "",
    yesFn: null,
    data: null
  });
  const _user_context = useUserContext();
  const [deleteFilterMutation] = useMutation(DELETE_DASHBOARD_FILTER, {
    onCompleted: data => {
      if (data.delete_dashboard_filter.success) {
        Swal.fire({
          icon: "success",
          text: "Deleted",
          timer: 3000
        });
      }
    },
    refetchQueries: () => {
      return [
        {
          query: GET_DASHBOARDS_FILTERS,
          variables: {
            user_id: _user_context.id,
            dashboard_type: tabValue === 0 ? "ontime" : "demurrage"
          }
        }
      ];
    }
  });

  const { data: filters_data } = useQuery(GET_DASHBOARDS_FILTERS, {
    variables: {
      user_id: _user_context.id,
      dashboard_type: tabValue === 0 ? "ontime" : "demurrage"
    }
  });

  useEffect(() => {
    if (filters_data) {
      const filters = _.sortBy(filters_data.get_dashboard_filters, ["label"]);
      setSaveFilters([{ value: 0, label: "Select Filter" }, ...filters]);
    }
  }, [filters_data]);

  /*
    - get access objects of dashboards. 
    - set default tab value to the most available tabs. 
    - set this fetch policy to cache first until further changes on data. 
  */
  const getTabDashboards = useCallback(() => {
    const tabs = [];
    _user_context.access_rights.map(object => {
      if (
        +object.parent_id === +process.env.REACT_APP_ACCESS_OBJECT_DASHBOARD &&
        object.permission.view
      ) {
        tabs.push(object);
      }
    });
    
    return tabs;
  }, [_user_context.access_rights]);

   useEffect(() => {
    const dashboardCtx = _user_context.access_rights.filter(
      acc => +acc.parent_id === +process.env.REACT_APP_ACCESS_OBJECT_DASHBOARD
    );
    if(dashboardCtx.length > 0){
      if (_.isEqual(dashboardCtx[0].name, ONTIME_OPT) && dashboardCtx[0].permission.view) setTabValue(0);
      else if (_.isEqual(dashboardCtx[0].name, DEMURRAGE_OPT) && dashboardCtx[0].permission.view) setTabValue(1);
      else if (_.isEqual(dashboardCtx[0].name, OVERVIEW_OPT) && dashboardCtx[0].permission.view) setTabValue(2);
      setDashboardAccess(dashboardCtx)
    }
   } ,[_user_context.access_rights]);

  useQuery(tabValue === 0 ? GET_SERVICE_TYPES : GET_VEHICLE_TYPE_LIST, {
    onCompleted: data => {
      if (data?.get_service_types) {
        if (tabValue === 0) {
          setServiceTypes(prevServiceTypes => [
            ...prevServiceTypes,
            ...data.get_service_types
          ]);
        } else {
          const vt = data?.get_vehicle_types?.vehicle_types
            ? data.get_vehicle_types.vehicle_types.map(vtype => {
                return {
                  value: vtype.id,
                  label: vtype.name
                };
              })
            : [{ value: 0, label: "All" }];
          setVehicleTypes(prevVehicleTypes => [...prevVehicleTypes, ...vt]);
        }
      }
    }
  });

  const [getClientsOptions] = useLazyQuery(GET_CLIENTS_DASHBOARDS, {
    onCompleted: data => {
      if (data) {
        setClients([{ value: 0, label: "All" }, ...data.get_clients.clients]);
      }
    }
  });

  useEffect(() => {
    getClientsOptions({
      variables: {
        keyword: clientKeyword,
        first: 10
      }
    });
  }, [clientKeyword]);

  useQuery(GET_ACCOUNTS, {
    onCompleted: data => {
      if (data) {
        const accts = data.get_booking_accounts.map(el => {
          return {
            value: el,
            label: el
          };
        });
        setAccounts(prevAccounts => [...prevAccounts, ...accts]);
      }
    }
  });
  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(pickupKeyword ? { keyword: pickupKeyword } : {}),
      category: "pickup"
    },
    onCompleted: data => {
      if (data) {
        const pickups_opts = data.get_booking_geofences.map(geo => {
          return {
            value: geo.geofence_id,
            label: geo.name
          };
        });
        setPickups(pickups_opts);
      }
    }
  });
  useQuery(GET_BOOKING_GEOFENCES, {
    variables: {
      ...(dropoffKeyword ? { keyword: dropoffKeyword } : {}),
      category: "dropoff"
    },
    onCompleted: data => {
      if (data) {
        const dropoffs_opts = data.get_booking_geofences.map(geo => {
          return {
            value: geo.geofence_id,
            label: geo.name
          };
        });
        setDropoffs(dropoffs_opts);
      }
    }
  });

  const [vars, setVars] = useState({
    dateRange: {
      start: moment().format("YYYY-MM-DD 00:00:00"),
      end: moment().format("YYYY-MM-DD 23:59:59")
    }
  });

  const [filterValues, setFilterValues] = useState([
    {
      serviceType: serviceTypes[0],
      client: clients[0],
      account: accounts[0],
      pickups: [],
      dropoffs: [],
      locations: [],
      dateRange: {
        start: moment().format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59")
      }
    },
    {
      vehicleType: vehicleTypes[0],
      client: clients[0],
      account: accounts[0],
      locations: []
    }
  ]);

  const [
    getFilter,
    { data: filter_data, loading: filter_loader }
  ] = useLazyQuery(GET_DASHBOARDS_FILTER, {
    fetchPolicy: "cache-and-network"
  });

  const getQueryVars = (mode = "filter", activeTab) => {
    let temp_vars = {
      pickups: [],
      dropoffs: [],
      locations: []
    };
    const active_filter = filterValues[activeTab];

    if (mode === "reset") {
      if (activeTab === 0) {
        temp_vars = {
          ...temp_vars,
          dateRange: {
            start: moment().format("YYYY-MM-DD 00:00:00"),
            end: moment().format("YYYY-MM-DD 23:59:59")
          }
        };
      }
      return temp_vars;
    }

    if (active_filter?.client.value !== 0) {
      temp_vars = {
        ...temp_vars,
        client_id: Number(active_filter?.client.value)
      };
    }
    if (active_filter?.account.value !== "") {
      temp_vars = { ...temp_vars, account: active_filter?.account.value };
    }
    if (activeTab === 0) {
      // OTTA/OTSA/OTD

      if (active_filter?.serviceType.value !== 0) {
        temp_vars = {
          ...temp_vars,
          service_type_id: Number(active_filter?.serviceType.value)
        };
      }

      if (active_filter.pickups.length > 0) {
        temp_vars = {
          ...temp_vars,
          pickups: active_filter.pickups.map(sg => {
            return sg.value;
          })
        };
      }
      if (active_filter.dropoffs.length > 0) {
        temp_vars = {
          ...temp_vars,
          dropoffs: active_filter.dropoffs.map(dropoff => {
            return dropoff.value;
          })
        };
      }
      if (active_filter.locations.length > 0) {
        temp_vars = {
          ...temp_vars,
          locations: active_filter.locations.map(l => {
            return l.value;
          })
        };
      }
      if (active_filter.dateRange) {
        temp_vars = {
          ...temp_vars,
          dateRange: active_filter.dateRange
        };
      }
      // temp_vars = {
      //   ...temp_vars,
      //   pickups: [
      //     ...temp_vars.pickups,
      //     // ...temp_vars.dropoffs,
      //     // ...temp_vars.locations
      //   ],
      //   dropoffs: [
      //     // ...temp_vars.pickups,
      //     ...temp_vars.dropoffs,
      //     // ...temp_vars.locations
      //   ],
      //   locations: [
      //     // ...temp_vars.pickups,
      //     // ...temp_vars.dropoffs,
      //     ...temp_vars.locations
      //   ]
      // };
    } else if (activeTab === 1) {
      // Demurrage
      if (active_filter.locations.length > 0) {
        temp_vars = {
          ...temp_vars,
          locations: active_filter.locations.map(l => {
            return l.value;
          })
        };
      }
      if (active_filter.vehicleType.value !== 0) {
        temp_vars = {
          ...temp_vars,
          vehicle_type_id: Number(active_filter.vehicleType.value)
        };
      }
    }

    return temp_vars;
  };

  const { data: chart_data, loading } = useQuery(
    tabValue === 0 ? GET_CHART_DATA : GET_DEMURRAGE_DATA,
    {
      variables: vars,
      fetchPolicy: "cache-and-network",
      pollInterval: 60000,
      onError: () => {
        Swal.fire({
          text: "Something went wrong",
          icon: "error",
          timer: 3000
        });
      }
    }
  );

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
    setVars(getQueryVars("apply", newValue));
  };

  const processLogs = type => {
    if (type === "dateOnly") {
      AddLogs(
        process.env.REACT_APP_DASHBOARD_MODULE,
        "date_filter",
        moment(filterValues[0].dateRange.start).format("YYYY-MM-DD")
      );
      return;
    }

    const {
      serviceType, // exists in tabValue === 0
      vehicleType, // exists in tabValue === 1
      // client,
      account,
      locations
    } = filterValues[tabValue];

    const serviceText = serviceType ? `Service Type: ${serviceType.label}` : "";
    const vehicleText = vehicleType ? `Vehicle Type: ${vehicleType.label}` : "";
    const pickupArray = filterValues[tabValue].pickups;
    const pickupText = pickupArray
      ? `Pickup: ${
          pickupArray.length > 0 ? pickupArray.map(p => p.label) : "All"
        },`
      : "";
    const dropoffArray = filterValues[tabValue].pickups;
    const dropoffText = dropoffArray
      ? `Drop off: ${
          dropoffArray.length > 0 ? dropoffArray.map(d => d.label) : "All"
        },`
      : "";
    const locationText =
      locations.length > 0 ? locations.map(l => l.label) : "All";

    const textLogs = `${serviceText}${vehicleText},Hauler/Shipping Line: ${filterValues[tabValue]?.client.label},Account: ${account.label},${pickupText}${dropoffText}Location: ${locationText}`;

    if (tabValue === 0) {
      AddLogs(
        process.env.REACT_APP_DASHBOARD_MODULE,
        "filter_OTTA/OTSA/OTD",
        textLogs
      );
    } else if (tabValue === 1) {
      AddLogs(
        process.env.REACT_APP_DASHBOARD_MODULE,
        "filter_demurrage",
        textLogs
      );
    }
  };

  const applyFilter = type => {
    setVars(getQueryVars("apply", tabValue));
    processLogs(type);
  };

  const resetFilter = () => {
    const temp = [...filterValues];
    const temp_filter = [...selectedFilter];
    if (tabValue === 0) {
      temp[tabValue] = {
        ...temp[0],
        serviceType: serviceTypes[0],
        client: clients[0],
        account: accounts[0],
        pickups: [],
        dropoffs: [],
        locations: [],
        dateRange: {
          start: moment().format("YYYY-MM-DD 00:00:00"),
          end: moment().format("YYYY-MM-DD 23:59:59")
        }
      };
    } else {
      temp[tabValue] = {
        ...temp[tabValue],
        vehicleType: vehicleTypes[0],
        client: clients[0],
        account: accounts[0],
        locations: []
      };
    }
    temp_filter[tabValue] = {
      value: 0,
      label: "Select Filter"
    };

    setFilterValues(temp);
    setSelectedFilter(temp_filter);
    setVars(getQueryVars("reset", tabValue));
  };

  const deleteFilter = (e, id) => {
    e.stopPropagation();
    setDynamicDialog({
      title: "Delete?",
      content: "Are you sure you want to delete this saved filter?",
      yesFn: deleteFilterMutation,
      data: {
        variables: {
          id: id
        }
      },
      open: true
    });
    // Swal.fire({
    //   title: "Are you sure?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "red",
    //   confirmButtonText: "Yes"
    // }).then(result => {
    //   if (result.value) {
    //     deleteFilterMutation({ variables: { id: id } });
    //   }
    // });
  };

  const selectFilter = (e, nv) => {
    const temp = [...selectedFilter];
    temp[tabValue] = nv;
    setSelectedFilter(temp);
    getFilter({ variables: { id: nv.value } });
  };

  const merged = _.unionBy(pickups, dropoffs, "value");

  useEffect(() => {
    if (filter_data) {
      const { get_dashboard_filter } = filter_data;
      const pickups_filter = [];
      const dropoffs_filter = [];
      const locations_filter = [];
      let temp_vars = {};
      let temp_filters_values = {
        pickups: pickups_filter,
        dropoffs: dropoffs_filter,
        locations: locations_filter
      };
      const fv = [...filterValues];
      if (get_dashboard_filter.pickup_ids.length > 0) {
        const prop_name = tabValue === 0 ? "pickups" : "locations";
        _.forEach(get_dashboard_filter.pickup_ids, pu => {
          const pu_i = _.findIndex(tabValue === 0 ? pickups : merged, p => {
            return p.value === pu;
          });
          if (pu_i !== -1) {
            if (tabValue === 0) {
              pickups_filter.push(pickups[pu_i]);
            } else {
              pickups_filter.push(merged[pu_i]);
            }
          }
        });
        temp_vars = {
          ...temp_vars,
          [prop_name]: pickups_filter.map(p => {
            return p.value;
          })
        };
        temp_filters_values = {
          ...temp_filters_values,
          [prop_name]: pickups_filter.map(p => {
            return p;
          })
        };
      }
      if (get_dashboard_filter.dropoff_ids.length > 0) {
        _.forEach(get_dashboard_filter.dropoff_ids, dropoff => {
          const do_i = _.findIndex(dropoffs, d => {
            return d.value === dropoff;
          });
          if (do_i !== -1) {
            dropoffs_filter.push(dropoffs[do_i]);
          }
        });
        temp_vars = {
          ...temp_vars,
          dropoffs: dropoffs_filter.map(d => {
            return d.value;
          })
        };
        temp_filters_values = {
          ...temp_filters_values,
          dropoffs: dropoffs_filter.map(d => {
            return d;
          })
        };
      }
      if (get_dashboard_filter.location_ids.length > 0) {
        _.forEach(get_dashboard_filter.location_ids, location => {
          const loc_i = _.findIndex(merged, d => {
            return d.value === location;
          });
          if (loc_i !== -1) {
            locations_filter.push(merged[loc_i]);
          }
        });
        temp_vars = {
          ...temp_vars,
          locations: locations_filter.map(d => {
            return d.value;
          })
        };
        temp_filters_values = {
          ...temp_filters_values,
          locations: locations_filter.map(d => {
            return d;
          })
        };
      }

      if (tabValue === 0) {
        const st_i = _.findIndex(serviceTypes, st => {
          return st.value === get_dashboard_filter.service_type_id?.toString();
        });
        if (st_i !== -1) {
          temp_vars = {
            ...temp_vars,
            service_type_id: Number(serviceTypes[st_i].value)
          };
          temp_filters_values = {
            ...temp_filters_values,
            serviceType: serviceTypes[st_i]
          };
        } else {
          temp_filters_values = {
            ...temp_filters_values,
            serviceType: serviceTypes[0]
          };
        }
      } else {
        const vt_i = _.findIndex(vehicleTypes, vt => {
          return vt.value === get_dashboard_filter.vehicle_type_id?.toString();
        });
        if (vt_i !== -1) {
          temp_vars = {
            ...temp_vars,
            vehicle_type_id: Number(vehicleTypes[vt_i].value)
          };
          temp_filters_values = {
            ...temp_filters_values,
            vehicleType: vehicleTypes[vt_i]
          };
        } else {
          temp_filters_values = {
            ...temp_filters_values,
            vehicleType: vehicleTypes[0]
          };
        }
      }
      const fetchClientData = async () => {
        const clientInfo = await client.query({
          query: GET_ADMIN_CLIENT,
          variables: {
            id: get_dashboard_filter.client_id
          },
          fetchPolicy: "network-only"
        });
        if (clientInfo?.data?.get_client) {
          const { id, name } = clientInfo.data.get_client;
          setClients(prevClients => [
            ...prevClients,
            { value: id, label: name }
          ]);
        }
        return clientInfo.data.get_client;
      };

      const cl_i = _.findIndex(clients, c => {
        return c.value === get_dashboard_filter.client_id?.toString();
      });
      if (cl_i !== -1) {
        temp_vars = { ...temp_vars, client_id: Number(clients[cl_i].value) };
        temp_filters_values = {
          ...temp_filters_values,
          client: clients[cl_i]
        };
      } else {
        fetchClientData()
          .then(c => {
            if (c) {
              temp_vars = { ...temp_vars, client_id: +c.id };
              temp_filters_values = {
                ...temp_filters_values,
                client: { value: c.id, label: c.name }
              };
            } else {
              temp_filters_values = {
                ...temp_filters_values,
                client: clients[0]
              };
            }
            fv[tabValue] = { ...fv[tabValue], ...temp_filters_values };
            setFilterValues(fv);
            setVars({ ...temp_vars, dateRange: vars.dateRange });
          })
          .catch(console.error);
      }
      const acc_i = _.findIndex(accounts, ac => {
        return ac === get_dashboard_filter.account;
      });

      if (acc_i !== -1) {
        temp_vars = { ...temp_vars, account: accounts[acc_i].label };
        temp_filters_values = {
          ...temp_filters_values,
          account: accounts[acc_i]
        };
      } else {
        temp_filters_values = {
          ...temp_filters_values,
          account: accounts[0]
        };
      }

      fv[tabValue] = { ...fv[tabValue], ...temp_filters_values };

      setFilterValues(fv);
      setVars({ ...temp_vars, dateRange: vars.dateRange });
    }
  }, [filter_data, filter_loader]);

  const redirectToTrips = trip_numbers => {
    history.push({
      pathname: "/trips",
      state: {
        params: {
          trip_numbers: trip_numbers
        }
      }
    });
  };

  const redirectToPrebooking = trip_numbers => {
    history.push({
      pathname: "/prebooking",
      state: {
        params: {
          trip_numbers: trip_numbers
        }
      }
    });
  };

  const getCursor = count => {
    if (count > 0) {
      return "pointer";
    }
    return "hand";
  };

  const disabledCondition = () => {
    if (tabValue === 0) {
      if (
        !_.isEmpty(filterValues[0].serviceType.value) ||
        !_.isEmpty(filterValues[tabValue].client.value) ||
        !_.isEmpty(filterValues[tabValue].account.value) ||
        selectedFilter[tabValue].value !== 0 ||
        filterValues[0].pickups.length > 0 ||
        filterValues[0].dropoffs.length > 0 ||
        filterValues[0].locations.length > 0
      ) {
        return false;
      }
    } else if (tabValue === 1) {
      if (
        !_.isEmpty(filterValues[tabValue].client.value) ||
        !_.isEmpty(filterValues[tabValue].account.value) ||
        selectedFilter[tabValue].value !== 0 ||
        !_.isEmpty(filterValues[1].vehicleType.value) ||
        filterValues[1].locations.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  // ui tab options for dashboard.
  const tabOptionsLayout = getTabDashboards().map((option, index) => {
    return (
      <Tab
        key={option.id}
        value={tabValueHandler(option.name)}
        label={option.name}
      />
    );
  });

  return (
    <AccessControl process="dashboard" resource="/dashboard/">
      <div className={classes.root}>
        <DynamicDialog
          dynamicDialog={dynamicDialog}
          setDynamicDialog={setDynamicDialog}
          classes={classes}
        />
        <FilterForm
          openFilterForm={openFilterForm}
          setOpenFilterForm={setOpenFilterForm}
          classes={classes}
          clients={clients}
          accounts={accounts}
          pickups={pickups}
          dropoffs={dropoffs}
          filterValues={filterValues[tabValue]}
          serviceTypes={serviceTypes}
          vehicleTypes={vehicleTypes}
          tabValue={tabValue}
          user={_user_context}
          saveFilters={saveFilters}
          setDynamicDialog={setDynamicDialog}
        />
        {loading && <Loading />}
        <AppBar position="static" elevation={0} className={classes.appBar}>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            onChange={handleTabChange}
          >
            {/* <Tab
              value={0}
              label="OTTA/OTSA/OTD"
              // style={{ color: "black", fontWeight: "bold" }}
            />
            <Tab
              value={1}
              label="Demurrage"
              // style={{
              //   color: "black",
              //   fontWeight: "bold",
              //   textTransform: "capitalize"
              // }}
            />
            <Tab value={2} label="Overview" /> */}
            {tabOptionsLayout}
          </Tabs>
        </AppBar>
        {tabValue === 2 ? (
          <Fleet />
        ) : (
          <div className={classes.panelRoot}>
            {" "}
            {/* Filter start */}
            <div style={{ display: "inline" }}>
              Saved Filters:
              <Autocomplete
                options={saveFilters}
                getOptionLabel={option => option.label.toString()}
                value={selectedFilter[tabValue]}
                onChange={selectFilter}
                getOptionDisabled={option => option.value === 0}
                getOptionSelected={selected => selected}
                renderOption={option => {
                  return (
                    <Box
                      fontSize="caption.fontSize"
                      fontWeight="fontWeightBold"
                    >
                      {option.label}
                      {option.value !== 0 && (
                        <IconButton
                          edge="end"
                          onClick={e => {
                            deleteFilter(e, option.value);
                          }}
                          style={{ color: "red" }}
                        >
                          <Close />
                        </IconButton>
                      )}
                    </Box>
                  );
                }}
                classes={{ root: classes.autoRoot }}
                renderInput={params => (
                  <TextField
                    {...params}
                    style={{ width: 350 }}
                    placeholder="Select Filter"
                  />
                )}
                disableClearable
              />
            </div>
            <div className={classes.filterContainer}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      {tabValue === 0 ? (
                        <Autocomplete
                          options={serviceTypes}
                          getOptionLabel={option => option.label.toString()}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField {...params} label="Service Types" />
                          )}
                          value={filterValues[tabValue].serviceType}
                          onChange={(e, nv) => {
                            const temp = [...filterValues];
                            temp[tabValue] = {
                              ...temp[tabValue],
                              serviceType: nv
                            };
                            setFilterValues(temp);
                          }}
                          disableClearable
                        />
                      ) : (
                        <Autocomplete
                          options={vehicleTypes}
                          getOptionLabel={option => option.label.toString()}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField {...params} label="Vehicle Type" />
                          )}
                          value={filterValues[tabValue].vehicleType}
                          onChange={(e, nv) => {
                            const temp = [...filterValues];
                            temp[tabValue] = {
                              ...temp[tabValue],
                              vehicleType: nv
                            };
                            setFilterValues(temp);
                          }}
                          disableClearable
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={clients}
                        getOptionLabel={option => option.label}
                        renderOption={option => option.label}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Hauler/Shipping Line"
                            onChange={e => {
                              setClientKeyword(e.target.value);
                            }}
                          />
                        )}
                        value={filterValues[tabValue].client}
                        onChange={(e, nv) => {
                          const temp = [...filterValues];
                          temp[tabValue] = {
                            ...temp[tabValue],
                            client: nv
                          };
                          setFilterValues(temp);
                        }}
                        disableClearable
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={accounts}
                        getOptionLabel={option => option.label.toString()}
                        renderOption={option => option.label}
                        renderInput={params => (
                          <TextField {...params} label="Accounts" />
                        )}
                        value={filterValues[tabValue].account}
                        onChange={(e, nv) => {
                          const temp = [...filterValues];
                          temp[tabValue] = {
                            ...temp[tabValue],
                            account: nv
                          };
                          setFilterValues(temp);
                        }}
                        disableClearable
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 5 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className={
                          disabledCondition()
                            ? classes.disabledButton
                            : classes.secButton
                        }
                        onClick={resetFilter}
                        disabled={disabledCondition()}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.primButton}
                        onClick={applyFilter}
                        disabled={disabledCondition()}
                      >
                        Apply
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.primButton}
                        onClick={() => setOpenFilterForm(true)}
                        disabled={disabledCondition()}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Typography>As of {moment().format("lll")}</Typography>
            <Grid container spacing={1}>
              {tabValue === 0 ? (
                <Fragment>
                  <Grid item xs={6}>
                    <OTTA
                      data={chart_data}
                      pickups={pickups}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      tabValue={tabValue}
                      setPickupKeyword={setPickupKeyword}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <OTD
                      data={chart_data}
                      dropoffs={dropoffs}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      tabValue={tabValue}
                      redirectToTrips={redirectToTrips}
                      getCursor={getCursor}
                      setDropoffKeyword={setDropoffKeyword}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CloseCancel
                      data={chart_data}
                      pickups={pickups}
                      dropoffs={dropoffs}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      tabValue={tabValue}
                      redirectToTrips={redirectToTrips}
                      redirectToPrebooking={redirectToPrebooking}
                      getCursor={getCursor}
                      applyFilter={() => applyFilter("dateOnly")}
                    />
                  </Grid>
                </Fragment>
              ) : (
                <Grid item xs={6}>
                  <Land
                    data={chart_data}
                    pickups={pickups}
                    dropoffs={dropoffs}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    tabValue={tabValue}
                    redirectToTrips={redirectToTrips}
                    redirectToPrebooking={redirectToPrebooking}
                    getCursor={getCursor}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
    </AccessControl>
  );
};

export default withRouter(Dashboards);
