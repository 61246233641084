/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  DialogTitle,
  InputBase,
  Paper,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { FixedSizeList } from "react-window";
import { GET_VEHICLES_TO_PAIR_REPO } from "../../api/vehicleRepository";

let timeout = null;
const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px"
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  inline: {
    display: "inline",
    marginRight: "50px"
  },
  circularButton: {
    borderRadius: "25px"
  }
}));

// const jsonData = [
//   {
//     vehicle_id: 15701,
//     vehicle_plate_no: "tmex0093",
//     vehicle_type: "AUV",
//     vehicle_type_id: 4,
//     status: "available"
//   },
//   {
//     vehicle_id: 15650,
//     vehicle_plate_no: "tmex6969",
//     vehicle_type: "AUV",
//     vehicle_type_id: 4,
//     status: "available"
//   },
//   {
//     vehicle_id: 15659,
//     vehicle_plate_no: "tmex1111",
//     vehicle_type: "10W",
//     vehicle_type_id: 2,
//     status: "available"
//   }
// ];

const PairDriverVehicle = props => {
  const classes = useStyles();
  const { toggle, closeVehicleModal, setVehicleInfo } = props;
  const [vehicles, setVehicles] = useState([]);
  const [keyword, setSearch] = useState("");
  const [loadingSearch, setLoading] = useState(true);
  const [chosenVehicle, setChosenVehicle] = useState({ plateno: null });

  function handleClose() {
    // setOpen(false);
    closeVehicleModal();
  }

  const handleFilter = event => {
    setSearch(event.target.value);
  };
  
  useEffect(() => {
    setLoading(true);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleVehicles();
    }, 1000);
  }, [keyword]);

  const selectVehicle = (event, vehicle) => {
    // setChosenVehicle(event.target.getAttribute("data-vehicle"));
    setChosenVehicle(vehicle);
  };
  function renderRow(prop) {
    const { index, style, data } = prop;
    const vehicle = data[index];
    let styling = { ...style };
    styling = { ...styling, marginTop: "5px" };
    return (
      <Fragment>
        <ListItem
          button
          style={styling}
          key={index}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          // style={styling}
          disabled={!!+vehicle.personnel_id}
          onClick={event => selectVehicle(event, vehicle)}
          // data-vehicle={vehicle.platenum}
          selected={chosenVehicle.id === vehicle.id}
        >
          <ListItemText
            id={`vehicle${index}`}
            primary={
              <Typography
                component="div"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {vehicle.plateno}
              </Typography>
            }
            component="span"
            secondary={
              <Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={useStyles.inline}
                  color="textPrimary"
                  style={{ marginRight: "50px" }}
                >
                  Truck Type:
                </Typography>
                {vehicle.vehicle_type}
              </Fragment>
            }
          />
          <Typography
            style={{ float: "right" }}
            variant="body2"
            component="span"
          >
            {!+vehicle.personnel_id
              ? "UNPAIRED"
              : "PAIRED"}
          </Typography>
        </ListItem>
      </Fragment>
    );
  }

  const parseVehicleData = data => {
    return data.map(vehicle => {
      return {
        id: vehicle.id,
        device_id: vehicle.device_id,
        plateno: vehicle.plateno,
        bodyno: vehicle.bodyno,
        vehicle_type_id: vehicle.vehicle_type_id,
        capacity: vehicle.capacity,
        unit: vehicle.unit,
        has_container: vehicle.has_container,
        status_code_id: vehicle.status_code_id,
        model: vehicle.model,
        manufacture_year: vehicle.manufacture_year,
        registration_number: vehicle.registration_number,
        expiration: vehicle.expiration,
        vehicle_type: vehicle.vehicle_type,
        personnel_id: vehicle.personnel_id
        // client_id: vehicle.client_id
      };
    });
  };

  const handleVehicles = async () => {
    const vehiclesData = await GET_VEHICLES_TO_PAIR_REPO(keyword);
    setVehicles(parseVehicleData(vehiclesData));
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Select Truck</DialogTitle>
        <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Plate #"
              inputProps={{ "aria-label": "plate #" }}
              onChange={handleFilter}
              value={keyword}
            />
            <IconButton
              // type="submit"
              disabled={true}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          {loadingSearch ? <CircularProgress size={40} /> : (
            <DialogContentText
              id="alert-dialog-description"
              component="span"
              style={{ textAlign: "center" }}
            >
              {vehicles.length ? (
                <FixedSizeList
                  height={400}
                  width="auto"
                  itemSize={56}
                  itemCount={vehicles.length}
                  itemData={vehicles}
                >
                  {renderRow}
                </FixedSizeList>
              ) : (
                <Typography
                  variant="h6"
                  component="span"
                  style={{ marginTop: "20px" }}
                >
                  No Available Vehicles
                </Typography>
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            color="primary"
            className={classes.circularButton}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            className={classes.circularButton}
            disabled={chosenVehicle ? !chosenVehicle.plateno : false}
            onClick={() => setVehicleInfo(chosenVehicle)}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PairDriverVehicle;
