/* eslint-disable no-control-regex */

/*
  regex included for validating inputs: 
  ✅ numeric
  ✅ Philippine mobile number 
  ✅ letters 
  ✅ category 
  ✅ email 
  ✅ alphanumeric || default  
  ✅ alphanumeric_dash 
  ✅ coordinates || coords 
  ✅ no special characters 
  ✅ number
  ✅ time 
  ✅ geofence 
  ✅ polygon
  ✅ url 
*/
export default function validate(type, string, {chars} = {chars: ""}) {
  let isCorrect = false;
  switch (type) {
    case "numeric":
      isCorrect = /^\d*$/.test(string);
      break;
    case "decimal":
      isCorrect = /^-?(0|[1-9]\d*)$/.test(string);
      break;
    case "mobile_number_ph":
      isCorrect = /^(09|\+?639)\d{9}$/.test(string);
      break;
    case "letters":
      isCorrect = /^[a-zA-Z\s]*$/.test(string);
      break;
    case "category":
      isCorrect = /^[a-zA-Z\s]*(\/[a-zA-Z\s]*)?$/.test(string);
      break;
    case "email":
      isCorrect = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        string
      );
      break;
    case "alphanumeric":
      isCorrect = /^[-ña-zA-Z0-9\s\s@!%^$#&*{}[_\],=-\\.+;:/\\(\\)Ññ]*$/.test(
        string
      );
      break;
    case "alphanumeric_default":
      isCorrect = /^[a-zA-Z0-9]*$/.test(string);
      break;
      case "alphanumeric_default_with_space":
        isCorrect = /^[a-zA-Z0-9_ ]*$/.test(string);
        break;
    case "alphanumeric_with":
     const regExp = new RegExp(`^[a-zA-Z0-9${chars}]*$`)
      isCorrect = regExp.test(string);
      break;
    case "coordinates":
      isCorrect = /^\d*(\.\d*)?$/.test(string);
      break;
    case "coords":
      isCorrect = /^(-?\d+(\.\d+)?)$/.test(string);
      break;
    case "noSpecialChar":
      isCorrect = /[^ña-zA-Z0-9 ]/.test(string);
      break;
    case "number":
      isCorrect = /[^0-9.]/.test(string);
      break;
    case "time":
      isCorrect = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(string);
      break;
    case "geofence":
      isCorrect = /^[-ñÑa-zA-Z0-9\s\s()\.@!%^$#*:,=-]*$/.test(string);
      break;
    case "polygon": 
      isCorrect = /\([^)]*\)/g.test(string);
      break
    case "url": 
      isCorrect = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(string);
      break;
    default:
      break;
  }

  return isCorrect;
}
