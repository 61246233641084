import { useContext } from "react";
import UserContext from "./User.context";

const useUserContext = () => {
  const context = useContext(UserContext);

  // if (!context) throw new Error("Can't use this hook outside UserProvider");
  if (!context) return undefined;

  return context;
};

export default useUserContext;
