import React, { useRef } from "react";
import {
  Step,
  StepLabel,
  StepContent,
  Typography,
  CircularProgress
} from "@material-ui/core";
import {
  FiberManualRecord as FiberManualRecordIcon,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import moment from "moment";
import useStyles from "../styles";
import Activities from "./Activities";
import TripLine from "./TripLine";
import StopDetails from "./StopDetails";
import useIsVisible from "../helpers/useIsVisible";
import _ from "lodash";
import { getGeofenceTypeColor } from "../../../Utils";

// const getCategoryColor = category => {
//   switch (category) {
//     case "homebase":
//       return "#A802A1";
//     case "dropoff":
//     case "pickup":
//     case "pickup/dropoff":
//     case "dropoff/pickup":
//       return "#2286C3";
//     case "stop point":
//       return "#F309DC";
//     case "hot spot":
//       return "#E02D26";
//     case "port":
//       return "#050FF7";
//     case "container yard":
//       return "#FFD67E";
//     case "crossdock hub":
//       return "#0BBD1A";
//     case "gas station":
//       return "#80461B";
//     default:
//       return "#808080";
//   }
// };

function identifyDates(d1, d2) {
  let startTime = null;
  let endTime = null;
  if (d1 > d2) {
    endTime = moment(d1);
    startTime = moment(d2);
  } else if (d2 > d1) {
    endTime = moment(d2);
    startTime = moment(d1);
  }
  if (startTime === null || endTime === null) {
    return true;
  }
  const diffInMinute = endTime.diff(startTime, "minutes", true);
  return diffInMinute <= 5;
}

const StopComponent = React.memo(props => {
  const {
    itin,
    index,
    handleSelectedTrip,
    stops,
    expandedPanels,
    deviceId,
    date,
    vehicleData,
    bookingInfo,
    showLogs,
    tripNumber,
    toggleAccordion,
    isCICO,
    primaryData,
    ...other
  } = props;
  const classes = useStyles();

  const getEntered = () => {
    if (isCICO) {
      return moment(itin?.Entered).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return moment(itin?.Entered)
        .add(8, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }
  };

  const getLeft = () => {
    if (isCICO) {
      return moment(itin?.Left).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return moment(itin?.Left)
        .add(8, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }
  };

  const getActivityHeader = (
    <Typography
      className={classes.activityHeading}
      onClick={() => toggleAccordion()}
    >
      {expandedPanels[index] ? "Hide Activity" : "Show Activity"}
      <ExpandMoreIcon
        style={{
          transform: expandedPanels[index] ? "rotate(180deg)" : "rotate(0deg)",
          fontSize: 20,
          marginLeft: "10px"
        }}
      />
    </Typography>
  );

  const stepRef = useRef();
  const isVisible = useIsVisible(stepRef);

  return (
    <Step {...other} ref={stepRef}>
      <StepLabel
        icon={<FiberManualRecordIcon />}
        style={{
          color: getGeofenceTypeColor(itin?.geofenceCategory)
        }}
      >
        {!["Initial", "Last"].includes(itin.geofenceName) ? (
          <>
            <Typography variant="subtitle2" style={{ letterSpacing: 1.2 }}>
              <strong>{itin.geofenceName}</strong>
            </Typography>
            <Typography variant="caption">
              {itin.geofenceAddress || <br />}
            </Typography>
          </>
        ) : (
          <>
            {getActivityHeader}
            <br />
          </>
        )}
      </StepLabel>
      {isVisible ? (
        <StepContent>
          <TripLine
            handleSelectedTrip={handleSelectedTrip}
            // start_odometer_reading={itin?.entered || 0}
            // end_odometer_reading={itin?.left || 0}
            // Band aid solution [start]
            stop={stops.filter(
              stop =>
                stop.geofenceId === itin.geofenceId &&
                identifyDates(
                  moment(stop.actual_arrival).format("YYYY-MM-DD HH:mm:ss"),
                  getEntered()
                  // moment(itin?.Entered)
                  //   .add(8, "hours")
                  //   .format("YYYY-MM-DD HH:mm:ss")
                ) &&
                identifyDates(
                  moment(stop.actual_departure).format("YYYY-MM-DD HH:mm:ss"),
                  getLeft()
                  // moment(itin?.Left)
                  //   .add(8, "hours")
                  //   .format("YYYY-MM-DD HH:mm:ss")
                )
            )}
            // Band aid solution [end]
          />
          {!["Pre - Trip", "Initial", "Last"].includes(itin.geofenceName) && (
            <StopDetails
              itin={itin}
              deviceId={deviceId}
              date={date}
              geofenceName={itin.geofenceName}
              vehicleData={vehicleData?.get_vehicle}
              isCICO={isCICO}
              isVisible={isVisible}
              primaryData={primaryData}
            />
          )}
          {!["Initial", "Last"].includes(itin.geofenceName) && (
            <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
              {getActivityHeader}
            </div>
          )}
          <Activities
            // entered={
            //   itin?.Entered
            //     ? moment(itin?.Entered)
            //         .add(8, "hours")
            //         .format("YYYY-MM-DD HH:mm:ss")
            //     : bookingInfo?.arrival_time
            // }
            // left={
            //   itin?.Left
            //     ? moment(itin?.Left)
            //         .add(8, "hours")
            //         .format("YYYY-MM-DD HH:mm:ss")
            //     : date?.end
            // }
            entered={itin?.Entered && !_.isEqual(moment(itin?.Entered).year(), 1111) ? getEntered() : bookingInfo?.arrival_time}
            left={itin?.Left && !_.isEqual(moment(itin?.Left).year(), 1111) ? getLeft() : date?.end}
            deviceId={deviceId}
            geofenceAddress={itin.geofenceAddress}
            showLogs={showLogs}
            geofenceName={itin.geofenceName}
            tripNumber={tripNumber}
            hidden={!expandedPanels[index]}
            vehicleData={vehicleData?.get_vehicle}
            primaryData={primaryData}
          />
        </StepContent>
      ) : (
        <StepContent>
          <CircularProgress size={10} />
        </StepContent>
      )}
    </Step>
  );
});

export default StopComponent;
