/* global google */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from "react";
import {
  // Paper,
  Typography,
  makeStyles,
  Switch,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Accordion,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { createPortal } from "react-dom";
import {
  Polygon,
  TrafficLayer,
  InfoWindow,
  Rectangle,
  Circle
} from "@react-google-maps/api";
import _ from "lodash";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const useStyles = makeStyles(theme => ({
  root: {
    background: "rgba(255,255,255)",
    boxShadow: "0px 0px 18px -8px #484848d4",
    width: "150px",
    "& .Mui-expanded": {
      minHeight: "auto"
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0
    },
    "& .MuiIconButton-edgeEnd": {
      margin: 0
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem"
    }
  },
  summary: {
    padding: "5px 8px",
    margin: 0,
    height: "auto",
    minHeight: "auto",
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  },
  details: {
    padding: "5px 8px",
    margin: 0,
    height: "auto"
  },
  switch: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%"
  },
  rootFilter: {
    cursor: "pointer",
    display: "flex",
    padding: "4px 12px",
    minWidth: "120px",
    borderRadius: "10px"
  },
  filterText: {
    fontSize: "14px"
  },
  filterList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  searchText: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "350px"
  },
  directionsText: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "450px"
  },
  checked: {
    backgroundColor: "blue"
  },
  // root: {
  //   padding: "2px 2px",
  //   display: "flex",
  //   alignItems: "center"
  // },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 25,
    margin: 4
  }
}));

// const { google } = window;
const FilterOverlay = props => {
  const classes = useStyles();
  const { geofences, filters, setFilters, handleDrag, mapRef } = props;
  const map = mapRef.current;
  const controlDiv = document.createElement("div");
  controlDiv.style.padding = "24px";

  // const [expanded, setExpanded] = useState(false);
  const [polygonHover, setPolygonHover] = useState({
    id: "",
    isShow: false,
    name: "",
    position: {}
  });
  const filterList = [
    { name: "Geofences", key: "geofences" },
    { name: "Traffic Overlay", key: "traffic" }
  ];

  // const handleFilter = () => {
  //   setExpanded(!expanded);
  // };

  useEffect(() => {
    const { google } = window;
    const controls = map.controls[google.maps.ControlPosition.RIGHT_TOP];
    const index = controls.length;
    controls.push(controlDiv);

    return () => {
      controls.removeAt(index);
    };
  });

  const handleFilterToggle = async e => {
    const { checked, name } = e.target;
    if (name === "geofences" && checked) {
      await handleDrag();
    }
    setFilters({ ...filters, [name]: checked });
  };

  const renderFilter = () => {
    return (
      <>
        <Accordion className={classes.root} elevation={0}>
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="caption">Map Filters</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <FormGroup style={{ width: "100%" }}>
              {filterList.length > 0 &&
                filterList.map(filter => (
                  <FormControlLabel
                    key={filter.key}
                    className={classes.switch}
                    control={
                      <AntSwitch
                        name={filter.key}
                        checked={filters[filter.key]}
                        onChange={handleFilterToggle}
                      />
                    }
                    size="small"
                    labelPlacement="start"
                    label={
                      <Typography variant="caption">{filter.name}</Typography>
                    }
                  />
                ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  // const coordinatesParser = wkbGeometry => {
  //   const temp = [];
  //   if (!_.isEmpty(wkbGeometry)) {
  //     const { coordinates } = wkbGeometry;
  //     if (coordinates) {
  //       coordinates[0].map(coords =>
  //         temp.push(new google.maps.LatLng(coords[1], coords[0]))
  //       );
  //     }
  //   }

  //   return temp;
  // };

  // const coordinatesParser = (wkbGeometry, type) => {
  //   const { google } = window;
  //   let temp = [];
  //   if (!_.isEmpty(wkbGeometry)) {
  //     const { coordinates } = wkbGeometry;
  //     if (type === "polygon") {
  //       coordinates[0].map(coords =>
  //         temp.push(new google.maps.LatLng(coords[1], coords[0]))
  //       );
  //     } else if (type === "rectangle") {
  //       const coords = coordinates[0];
  //       temp = new google.maps.LatLngBounds(
  //         new google.maps.LatLng(coords[3][1], coords[3][0]),
  //         new google.maps.LatLng(coords[1][1], coords[1][0])
  //       );
  //     }
  //   }
  //   return temp;
  // };

  // const renderGeofences = () => {
  //   if (!_.isEmpty(geofences)) {
  //     return geofences.map(geofence => {
  //       const {
  //         id,
  //         type,
  //         wkb_geometry,
  //         radius,
  //         location
  //         // color,
  //         // opacity
  //       } = geofence;

  //       const bounds = coordinatesParser(wkb_geometry, type);

  //       if (type === "polygon") {
  //         return (
  //           <Polygon
  //             key={`polygon-${id}`}
  //             paths={bounds}
  //             options={{
  //               fillColor: "#0080FF",
  //               fillOpacity: 0.5,
  //               strokeWeight: 2
  //             }}
  //             onMouseOver={() => {
  //               if (id !== polygonHover.id && !polygonHover.isShow) {
  //                 setPolygonHover({
  //                   isShow: true,
  //                   name: `${geofence.geofence_code}-${geofence.name}`,
  //                   position: {
  //                     lat: geofence.location.lat,
  //                     lng: geofence.location.lon
  //                   }
  //                 });
  //               }
  //             }}
  //             onMouseOut={() => {
  //               setPolygonHover({
  //                 ...polygonHover,
  //                 isShow: false
  //               });
  //             }}
  //           />
  //         );
  //       }
  //       if (type === "rectangle") {
  //         return (
  //           <Rectangle
  //             key={`rectangle-${id}`}
  //             bounds={bounds}
  //             options={{
  //               fillColor: "#0080FF",
  //               fillOpacity: 0.5,
  //               strokeWeight: 2
  //             }}
  //             onMouseOver={() => {
  //               if (id !== polygonHover.id && !polygonHover.isShow) {
  //                 setPolygonHover({
  //                   isShow: true,
  //                   name: `${geofence.geofence_code}-${geofence.name}`,
  //                   position: {
  //                     lat: geofence.location.lat,
  //                     lng: geofence.location.lon
  //                   }
  //                 });
  //               }
  //             }}
  //             onMouseOut={() => {
  //               setPolygonHover({
  //                 ...polygonHover,
  //                 isShow: false
  //               });
  //             }}
  //           />
  //         );
  //       }

  //       return (
  //         <Circle
  //           // eslint-disable-next-line react/no-array-index-key
  //           key={`circle-${id}`}
  //           center={{ lat: location.lat, lng: location.lon }}
  //           radius={radius}
  //           options={{
  //             fillColor: "#0080FF",
  //             fillOpacity: 0.5,
  //             strokeWeight: 2
  //           }}
  //           onMouseOver={() => {
  //             if (id !== polygonHover.id && !polygonHover.isShow) {
  //               setPolygonHover({
  //                 isShow: true,
  //                 name: `${geofence.geofence_code}-${geofence.name}`,
  //                 position: {
  //                   lat: geofence.location.lat,
  //                   lng: geofence.location.lon
  //                 }
  //               });
  //             }
  //           }}
  //           onMouseOut={() => {
  //             setPolygonHover({
  //               ...polygonHover,
  //               isShow: false
  //             });
  //           }}
  //         />
  //       );
  //     });
  //   }
  //   return null;
  // };

  return createPortal(
    <div>
      {renderFilter()}

      {/* {filters.geofences && renderGeofences()} */}
      {filters.traffic && <TrafficLayer autoUpdate />}
      {/* {polygonHover.isShow && (
        <InfoWindow position={polygonHover.position}>
          <div
            style={{
              backgroundColor: `white`,
              width: 200
            }}
          >
            {polygonHover.name}
          </div>
        </InfoWindow>
      )} */}
    </div>,
    controlDiv
  );
};

export default FilterOverlay;
