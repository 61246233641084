import React from "react";
import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";

const Devices = props => {
  const { permissions } = useAllowedPermissions("/admin/wti/devices/");
  const { devices, children } = props;

  return (
    <React.Fragment>
      {devices.length > 0 &&
        devices.map(device => {
          const { latitude, longitude } = device.device_status;
          if (latitude && longitude) {
            return children({ id: device.id, data: device, permissions });
          }
          return null;
        })}
    </React.Fragment>
  );
};

export default Devices;
