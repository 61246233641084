/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef
} from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Fade,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
  CircularProgress,
  Badge,
  Checkbox,
  Toolbar,
  useTheme
} from "@material-ui/core";

import {
  FilterList,
  GetApp,
  Brightness1,
  MoreVert,
  ErrorOutline,
  TripOrigin,
  AssignmentLate,
  Warning
} from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import {
  GET_BOOKINGS_v2,
  GET_BOOKINGS_DOWNLOADABLE_TABLE_DATA
  // GET_TABLEDATA_BOOKINGS_V2
} from "../../../api/bookingRepository";
import { BOOKINGS_SUBSCRIPTION } from "../../../graphql/Subscription";
// import createBookingsData from "../../../utils/parse";
import SearchContext from "../../../context/SearchContext";
// import UserContext from "../../../context/UserContext";
import VehicleAssignDialog from "../utils/modal/VehicleAssign";
import DispatchDialog from "../utils/modal/DispatchDialog";
// import ConfirmationDialog from "../utils/modal/ConfirmationDialog";
import AccessControl from "../../../utils/AccessControl";
import Loading from "../../../utils/Loading";
import {
  // DISPATCH_PERMISSIONS,
  GET_DEVICES_TRIPS,
  GET_SERVICE_TYPES_QUERY
} from "../../../graphql/Queries";
// import Filter from "./Filter";
import CloseDialog from "../utils/modal/CloseDialog";
import Battery from "../../../utils/Battery";
import AddLogs from "../../../utils/functions/AddLogs";
import {
  ADD_NOTIFICATION,
  MANUAL_COMPLETE,
  UPDATE_INTERISLAND_BOOKING
} from "../../../graphql/Mutations";
import { gpsStatus, getTripStatus } from "../../Utils";
import Pagination from "../../../utils/table/Pagination";
import useUserContext from "../../../context/User/useUserContext";
import Filter from "../../Prebooking/Filter";
import ConfirmationDialog from "../../../utils/modals/ConfirmationDialog";
import { resetTrips } from "../../../utils/functions/unpairingFunctions";

const Highlight = require("react-highlighter");

const MySwal = withReactContent(swal);

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const TableCell = withStyles({
  root: {
    textDecoration: "none",
    padding: 5
  }
})(MuiTableCell);

const headCells = [
  // {
  //   id: "dispatch_type",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  // {
  //   id: "isHotload",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  // {
  //   id: "isOverdue",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  {
    id: "ref_number",
    numeric: false,
    disablePadding: false,
    label: "Trip Number",
    sortable: true
  },
  {
    id: "service_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Service Type",
    sortable: true
  },
  {
    id: "pickups.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Pickup Location",
    sortable: true
  },
  {
    id: "pickups.arrival",
    numeric: false,
    disablePadding: false,
    label: "Pickup Date",
    sortable: true
  },
  {
    id: "dropoffs.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Delivery Location",
    sortable: true
  },
  {
    id: "dropoffs.arrival",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    sortable: true
  },
  {
    id: "group_names",
    numeric: false,
    disablePadding: false,
    label: "Hauler",
    sortable: true
  },
  {
    id: "vehicle_plate_no.keyword",
    numeric: false,
    disablePadding: false,
    label: "Plate/ CV no.",
    sortable: true
  },
  {
    id: "trip_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Trip Type",
    sortable: true
  },
  {
    id: "status_code",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sortable: false
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    component,
    onSelectAllTrips,
    isChecked,
    permissions
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead component={component}>
      <TableRow component={component}>
        {permissions?.close?.edit && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllTrips}
            />
          </TableCell>
        )}
        <TableCell />
        <TableCell />
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            component={component}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableHeadCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllTrips: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};
const drawerWidth = 325;
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    //   width: "100%",
    //   marginBottom: theme.spacing(2),
    //   paddingTop: 64
    width: "100%",
    marginBottom: theme.spacing(20),
    borderRadius: 0,
    boxShadow: "none"
  },
  paperToolbar: {
    marginBottom: theme.spacing(2),
    position: "fixed",
    right: 24,
    left: 94
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  multipleSelect: {
    // marginLeft: 70,
    backgroundColor: "#EAEAEA",
    minHeight: "45px !important",
    height: 45
  },
  title: {
    flex: "1 1 25%",
    color: "rgba(63, 63, 63, 0.5)",
    fontSize: 13,
    fontWeight: "600"
    // color: "#000000"
  },
  checkedCount: {
    color: theme.palette.primary.main
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawer: {
    width: drawerWidth
  },
  filterInput: {
    marginBottom: "20px",
    minWidth: "250px",
    width: "250px",
    "& .MuiAutocomplete-popupIndicator": {
      transform: "rotate(0) !important"
    }
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 128px)"
  },
  customBadgeAutomatic: {
    backgroundColor: "#bb6bd9",
    color: "white"
  },
  customBadgeManual: {
    backgroundColor: "#4e9fc0",
    color: "white"
  },
  customBadgeUndefine: {
    backgroundColor: "#cab608",
    color: "white"
  },
  selectedTripsCount: {
    color: theme.palette.primary.main
  },
  textStyle: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center"
  },
  tableHeadCell: {
    whiteSpace: "nowrap",
    fontWeight: "600",
    fontSize: 13,
    color: theme.palette.primary.dark
  }
}));

export default function TripsTable(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { history } = props;
  const location = useLocation();
  const contextData = useContext(SearchContext);
  // const userDetails = useContext(UserContext);
  // const firstUpdate = useRef(true);

  /* STATES */
  const [keyLog, setKeyLog] = useState(
    `Service Type: All, Dispatch Type All, Status: All`
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("pickups.arrival");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [downloadData, setDownloadData] = useState({
    headers: [
      { label: "Trip Number", key: "fo_number" },
      { label: "Service Type", key: "service_type" },
      { label: "Pickup Location", key: "pickup_location" },
      { label: "Pickup Date", key: "pickup_date" },
      { label: "Dropoff Location", key: "dropoff_location" },
      { label: "Delivery Date", key: "dropoff_date" },
      { label: "Hauler", key: "hauler" },
      { label: "Plate/ CV no", key: "plate_no" },
      { label: "Trip Type", key: "trip_type" },
      { label: "Status", key: "status" },
      { label: "Dispatch Type", key: "dispatch_type" }
    ],
    body: []
  });
  const csvLink = useRef();
  // const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [optionsMenuData, setOptionsMenuData] = useState(null);
  const [chosenRowData, setChosenRowData] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [vehicleModal, toggleVehicleModal] = useState(false);
  const [dispatchModal, toggleDispatchModal] = useState(false);
  const [closeModal, toggleCloseModal] = useState(false);
  const [editPermission, setEditPermission] = useState(false); // eslint-disable-line
  const [parsedTrips, setParsedTrips] = useState([]);
  const [keyword, setKeyword] = useState(
    (contextData?.searchKeyword?.moduleName ===
      process.env.REACT_APP_DISPATCH_MODULE &&
      contextData?.searchKeyword?.value) ||
      ""
  );
  const [downloadBTN, setDownloadBTN] = useState(false);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [openClose, setOpenClose] = useState(false);
  const [closeData, setCloseData] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [openInterIsland, setOpenInterIsland] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const DEFAULT_DATE_RANGE = {
    field: "pickups.arrival",
    start: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD 00:00:00"),
    end: moment()
      .add(60, "days")
      .format("YYYY-MM-DD 23:59:59")
  };
  const initialFilter = {
    date_range: {
      field: "pickups.arrival",
      start: location?.state?.params?.trip_numbers?.length
        ? moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD 00:00:00")
        : moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD 00:00:00"),
      end: moment()
        .add(60, "days")
        .format("YYYY-MM-DD 23:59:59")
    },
    orderBy: !isFiltered
      ? [
          { field: "is_urgent", direction: "desc" },
          { field: "overdue", direction: "desc" },
          { field: orderBy, direction: order }
        ]
      : [{ field: orderBy, direction: order }],
    conditions: [
      // {
      //   field: "client_id",
      //   value: userDetails.client.id.toString()
      // },
      // { field: "data_complete", value: ["true"] }
      // {
      //   field: "status_code",
      //   value: [
      //     "Incoming",
      //     "Arrived at Pickup",
      //     "Arrived at Drop off",
      //     "In Transit"
      //   ]
      // }
    ],
    not: [
      // { field: "status_code.keyword", value: "Accepted" },
      // { field: "status_code.keyword", value: "Scheduled" }
      // { field: "data_complete", value: "false" }
      { field: "dispatch_type", value: "Not Yet" }
    ],
    or: [
      {
        field: "status_code_id",
        value: [
          process.env.REACT_APP_INCOMING_STATUS,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
          process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
          process.env.REACT_APP_STATUS_IN_TRANSIT,
          // process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
          ...(location?.state?.params?.trip_numbers?.length
            ? [
                process.env.REACT_APP_STATUS_TRIP_CLOSED,
                process.env.REACT_APP_STATUS_CANCEL_ID
              ]
            : [])
        ]
      }
    ]
  };
  const [queryFilter, setQueryFilter] = useState(initialFilter);
  const [currentTime, setCurrentTime] = useState(
    moment().format("MMM D YYYY h:mm A")
  );
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  // useEffect(() => {
  //   setPage(0);
  // }, [queryFilter]);

  // const user = JSON.parse(Cookie.get("user"));
  const user_context = useUserContext();
  // const [permissions, setPermissions] = useState(null);
  // const { data: permission_data } = useQuery(DISPATCH_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id
  //   },
  //   fetchPolicy: "network-only"
  // });
  const [trip, setTrip] = useState();
  const [tripReassign, setTripReassign] = useState([]);
  const permissions = React.useMemo(() => {
    if (!user_context.allowed_permissions) return null;

    const { allowed_permissions } = user_context;

    return {
      change_truck: allowed_permissions["/trips/change_truck/"],
      dispatch: allowed_permissions["/trips/dispatch/"],
      manual_complete: allowed_permissions["/trips/manual_complete/"],
      map_view: allowed_permissions["/trips/map_view/"],
      reorder: allowed_permissions["/trips/reorder/"],
      split: allowed_permissions["/trips/split/"],
      close: allowed_permissions["/trips/close/"]
    };
  }, [user_context.allowed_permissions]);

  // useEffect(() => {
  //   if (permission_data) {
  //     setPermissions(permission_data);
  //   }
  // }, [permission_data]);

  const getEta = (pickups, dropoffs, out_of_route) => {
    let eta = false;

    const active_pu = _.filter(
      pickups,
      pu => pu.actual_eta && pu.status_code_id === 2
    );
    const active_do = _.filter(
      dropoffs,
      d => d.actual_eta && d.status_code_id === 2
    );

    if (active_do.length > 0) {
      const modifiedTime = moment(active_do[0].eta_modified).format("lll");
      const expected_time = `${moment(active_do[0].eta_modified)
        .add(active_do[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_do[0].arrival);
      eta =
        active_do[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    } else if (active_pu.length > 0) {
      const modifiedTime = moment(active_pu[0].eta_modified).format("lll");
      const expected_time = `${moment(active_pu[0].eta_modified)
        .add(active_pu[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_pu[0].arrival);
      eta =
        active_pu[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    }
    return moment().isBefore(eta.eta) && !out_of_route ? eta : false;
  };

  const [dvar, setdvar] = useState([]);

  const [get_devices] = useLazyQuery(GET_DEVICES_TRIPS, {
    onCompleted: data => {
      if (data) {
        setdvar(data.get_devices.devices);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  const dataParser = data => {
    const { bookings: trips, total } = data.get_bookings_per_trip;

    const sample = [];
    _.forEach(trips, _trip => {
      const pickups = [];
      const dropoffs = [];
      const raw_dropoffs = [];
      const raw_pickups = [];
      let parsedTrip = {};
      _.forEach(_trip.value, so => {
        _.forEach(so.pickups, pickup => {
          const exist = _.findIndex(pickups, {
            geofence_id: pickup.geofence_id,
            arrival: pickup.arrival
          });
          raw_pickups.push(pickup);
          if (exist === -1) {
            pickups.push(pickup);
          }
        });
        _.forEach(so.dropoffs, d => {
          const exist = _.findIndex(dropoffs, {
            geofence_id: d.geofence_id,
            arrival: d.arrival
          });

          // const items = so.items
          //   ? so.items.filter(item => item.dropoff_index === i)
          //   : [];
          raw_dropoffs.push({ ...d, items: so.items, trip_id: so.id });
          if (exist === -1) {
            dropoffs.push({ ...d, trip_id: so.id });
          }
        });

        parsedTrip = {
          ...parsedTrip,
          vehicle_status: so.vehicle_status,
          vehicle_plate_no: so.vehicle_plate_no,
          service_type: so.service_type,
          vehicle_id: so.vehicle_id,
          vehicle_history: so.vehicle_history,
          vehicle_type: so.vehicle_type,
          driver: so.personnels && so.personnels[0]?.name,
          is_urgent: so.is_urgent,
          // trip_status:
          //   so.status_code.toLowerCase() === "accepted" ||
          //   so.status_code.toLowerCase() === "scheduled"
          //     ? "Processing"
          //     : so.status_code,
          tripNumber: so.trip_number,
          raw_pickups: raw_pickups,
          raw_dropoffs: raw_dropoffs,
          hauler: so.client_name,
          total: _trip.value.length,
          has_split:
            so.status_code.toLowerCase() === "accepted" ||
            so.status_code.toLowerCase() === "scheduled" ||
            so.status_code.toLowerCase() === "arrived at pickup",
          raw_trip_status: so.status_code,
          out_of_route: so.out_of_route,
          is_overdue: so.overdue,
          dispatch_type: so.dispatch_type,
          status_code_id: so.status_code_id,
          is_inter_island: so.is_inter_island
        };
      });
      parsedTrip = {
        ...parsedTrip,
        dropoffs: dropoffs,
        pickups: pickups,
        first_pickup: pickups[0].name,
        first_pickup_arrival: pickups[0].arrival,
        first_dropoff: dropoffs[0].name,
        first_dropoff_arrival: dropoffs[0].arrival,
        eta: getEta(
          parsedTrip.raw_pickups,
          parsedTrip.raw_dropoffs,
          parsedTrip.out_of_route
        ),
        has_empty_so: _.filter(_trip.value, t => !t.so_number).length > 0,
        trip_status: getTripStatus(_trip.value)
      };

      sample.push(parsedTrip);
    });
    return { sample, total, trips };
  };

  const {
    data: bookings_data,
    refetch,
    fetchMore,
    subscribeToMore,
    loading
  } = useQuery(GET_BOOKINGS_v2, {
    variables: {
      ...queryFilter,
      skip: page * rowsPerPage,
      rows_per_page: page * rowsPerPage + rowsPerPage
    },
    onCompleted: result => {
      if (contextData.searchKeyword.value) {
        AddLogs(
          process.env.REACT_APP_DISPATCH_MODULE,
          "search",
          contextData.searchKeyword.value
        );
      }
      // const plate_no = [];
      const device_ids = [];
      if (result) {
        result.get_bookings_per_trip.bookings.map(row => {
          return row.value.map(drow => {
            if (drow.vehicle_id) {
              return device_ids.push(drow.vehicle_id);
            }
            // return plate_no.push(drow.vehicle_plate_no);
          });
        });
      }
      if (_.uniq(device_ids).length) {
        get_devices({
          variables: {
            first: 100,
            // condition: [{ field: "vehicle_info.plateno", value: plate_no }]
            condition: [{ field: "devices.id", value: _.uniq(device_ids) }]
          }
        });
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [closeTripMutation] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      setSelectedTrips([]);
      MySwal.fire({
        icon: "success",
        text: "Closed.",
        timer: 3000,
        showConfirmButton: false,
        onAfterClose: () => {}
      }).then(result => {
        if (result.dismiss === MySwal.DismissReason.timer) {
          refetch();
        } else {
          refetch();
        }
      });
      AddLogs(
        process.env.REACT_APP_PRE_DISPATCH_MODULE,
        "close_trip",
        selectedTrips
      );
    },
    refetchQueries: () => {
      return [{ query: GET_BOOKINGS_v2, variables: queryFilter }];
    }
  });

  const [get_selectedBookings] = useLazyQuery(GET_BOOKINGS_v2, {
    onCompleted: data => {
      if (data) {
        setCloseData(data.get_bookings_per_trip.bookings);
      }
    }
  });

  // mutation for interisland
  const [updateInterisland] = useMutation(UPDATE_INTERISLAND_BOOKING, {
    onCompleted: data => {
      if (!data.update_booking_all) return;
      const { trip_number, is_inter_island } = data.update_booking_all[0];

      // update logs for updated trip inter island.
      AddLogs(
        process.env.REACT_APP_DISPATCH_MODULE,
        "is_inter_island_update",
        `${trip_number} to ${
          is_inter_island ? "inter island" : "standard"
        } trip`
      );
      setOpenConfirm(true);
    }
  });

  function closeOptionsMenu() {
    // setOptionsMenuData(null);
    setAnchorEl(null);
  }

  // function to update trip type and set trip to interisland or untag trip interisland;
  async function interIslandHandler() {
    const interIslandIds = [];
    if (chosenRowData.rawTripData && chosenRowData.rawTripData.value) {
      chosenRowData.rawTripData.value.forEach(data =>
        interIslandIds.push(data.id)
      );
      await updateInterisland({
        variables: {
          fields: [
            {
              field: "is_inter_island",
              value: chosenRowData.parsedTripData.is_inter_island
                ? "false"
                : "true"
            }
          ],
          conditions: [{ field: "id", value: interIslandIds }]
        }
      });
      closeOptionsMenu();
    }
  }

  useQuery(GET_SERVICE_TYPES_QUERY, {
    onCompleted: data => {
      const { get_service_types } = data;
      setServiceTypes(get_service_types);
    },
    skip: !drawer
    // fetchPolicy: "network-only"
  });

  // Function prepared to fetch the selected trip
  const [get_booking_details] = useLazyQuery(GET_BOOKINGS_v2, {
    onCompleted: res => setTripReassign(res)
  });

  useEffect(() => {
    // Check if variable trip has a vallid value
    if (trip) {
      // run the function prepared to fetch the selected trip
      get_booking_details({
        variables: {
          trip_numbers: [trip]
        }
      });
    }
  }, [trip]);

  useEffect(() => {
    // check if the variable tripReassign is empty
    if (!_.isEmpty(tripReassign)) {
      // re-organize the data using dataParser function
      const { sample } = dataParser(tripReassign);
      const { get_bookings_per_trip } = tripReassign;
      const rawTripData = get_bookings_per_trip.bookings.filter(
        b => b.key === trip
      );
      const activeRow = sample.filter(b => b.tripNumber === trip);
      setChosenRowData({
        parsedTripData: activeRow[0],
        rawTripData: rawTripData[0],
        booking_id: activeRow[0].id,
        pickups: activeRow[0].pickups,
        trip_number: activeRow[0].tripNumber,
        is_split: rawTripData[0].value.is_split || null,
        key: activeRow[0].tripNumber,
        dateRange: {
          start: activeRow[0].pickups[0].arrival,
          end: activeRow[0].dropoffs[activeRow[0].dropoffs.length - 1].arrival
        }
      });
    }
  }, [tripReassign]);

  const checkDispatchStatus = type => {
    let tempType = "";
    if (type) {
      if (type.toLowerCase() === "automatic") {
        tempType = "Automatic Dispatched";
      } else if (type.toLowerCase() === "manual") {
        tempType = "Manually Dispatched";
        // } else if(type.toLowerCase() === 'not yet') {
        //   tempType = 'Not Yet Dispatched';
      } else {
        tempType = "Undefined";
      }
    } else {
      tempType = "Undefined";
    }
    return tempType;
  };

  const [getDownloadData] = useLazyQuery(GET_BOOKINGS_DOWNLOADABLE_TABLE_DATA, {
    fetchPolicy: "network-only",
    onCompleted: d => {
      // setDownloadBTN(false);
      const { sample } = dataParser(d);
      const downloadTrips = sample.map(trips => {
        return {
          fo_number: trips.tripNumber,
          service_type: trips.service_type || "N/A",
          pickup_location: trips.pickups
            .map(pickup =>
              pickup.geofence_code
                ? `${pickup.geofence_code}-${pickup.name}`
                : pickup.name
            )
            .join("\n"),
          pickup_date: trips.pickups
            .map(pickup => moment(pickup.arrival).format("lll"))
            .join("\n"),
          dropoff_location: trips.dropoffs
            .map(dropoff =>
              dropoff.geofence_code
                ? `${dropoff.geofence_code}-${dropoff.name}`
                : dropoff.name
            )
            .join("\n"),
          dropoff_date: trips.dropoffs
            .map(dropoff => moment(dropoff.arrival).format("lll"))
            .join("\n"),
          hauler: trips.hauler,
          plate_no: trips.vehicle_plate_no,
          status: trips.trip_status,
          dispatch_type: checkDispatchStatus(trips.dispatch_type),
          trip_type: trips.is_inter_island ? "Interisland trip" : "Standard trip"
        };
      });
      setDownloadData({ ...downloadData, body: downloadTrips });
    },
    onError: () => {
      MySwal.fire({
        icon: "error",
        title: "Too many data to prepare!",
        text: "Please use filter before downloading",
        timer: 3000
      });
      setDownloadBTN(false);
    }
  });

  // useEffect(() => {
  //   if (bookings_data?.get_bookings_per_trip?.total) {
  //     getDownloadData({
  //       variables: {
  //         ...queryFilter,
  //         rows_per_page: bookings_data?.get_bookings_per_trip?.total
  //       }
  //     });
  //   } else {
  //     setDownloadBTN(false);
  //     setDownloadData({ ...downloadData, body: [] });
  //   }
  // }, [bookings_data]);

  // useQuery(GET_BOOKINGS_v2, {
  //   variables: {
  //     ...queryFilter,
  //     skip: page * rowsPerPage,
  //     rows_per_page: page * rowsPerPage + rowsPerPage
  //   },
  //   onCompleted: d => {
  //     setDownloadBTN(false);
  //     const { sample } = dataParser(d);
  //     const downloadTrips = sample.map(trips => {
  //       return {
  //         fo_number: trips.tripNumber,
  //         service_type: trips.service_type || "N/A",
  //         pickup_location: trips.pickups
  //           .map(pickup =>
  //             pickup.geofence_code
  //               ? `${pickup.geofence_code}-${pickup.name}`
  //               : pickup.name
  //           )
  //           .join("\n"),
  //         pickup_date: trips.pickups
  //           .map(pickup => moment(pickup.arrival).format("lll"))
  //           .join("\n"),
  //         dropoff_location: trips.dropoffs
  //           .map(dropoff =>
  //             dropoff.geofence_code
  //               ? `${dropoff.geofence_code}-${dropoff.name}`
  //               : dropoff.name
  //           )
  //           .join("\n"),
  //         dropoff_date: trips.dropoffs
  //           .map(dropoff => moment(dropoff.arrival).format("lll"))
  //           .join("\n"),
  //         hauler: trips.hauler,
  //         plate_no: trips.vehicle_plate_no,
  //         status: trips.trip_status,
  //         dispatch_type: checkDispatchStatus(trips.dispatch_type)
  //       };
  //     });
  //     setDownloadData({ ...downloadData, body: downloadTrips });
  //   },
  //   fetchPolicy: "network-only"
  // });

  useEffect(() => {
    setLoader(loading);
  }, [loader, loading]);

  const subscribeToNewNotifications = () => {
    const unsubscribe = subscribeToMore({
      document: BOOKINGS_SUBSCRIPTION,
      // variables: {
      //   conditions: [
      //     {
      //       field: "client_id",
      //       value: userDetails.client.id.toString()
      //     }
      //   ]
      // },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (!prev) return false;
        if (!prev?.get_bookings_per_trip) return false;

        const {
          bookings: newData,
          count: newCount,
          total: newTotal
        } = subscriptionData.data.booking_per_trip_updated;
        const {
          bookings: prevBookings,
          count,
          total
        } = prev.get_bookings_per_trip;

        if (!newData.length) return prev;
        const parsedNewData = newData[0];
        const newDataValue = parsedNewData.value[0];
        const newDataTripNumber = parsedNewData.key;
        const oldTripNumberIndex = _.findIndex(prevBookings, function a(o) {
          return o.key === newDataTripNumber;
        });
        if (oldTripNumberIndex !== -1) {
          const oldSOIndex = _.findIndex(
            prevBookings[oldTripNumberIndex].value,
            function a(o) {
              return o.id === newDataValue.id;
            }
          );
          if (oldSOIndex !== -1) {
            const prevBookingsCopy = _.cloneDeep([...prevBookings]);
            prevBookingsCopy[oldTripNumberIndex].value.splice(
              oldSOIndex,
              1,
              newDataValue
            );

            const data = {
              bookings: prevBookingsCopy,
              count: count + newCount,
              total: total + newTotal
            };
            return {
              get_bookings_per_trip: data
            };
          }
        }

        return prev;
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = subscribeToNewNotifications();
    return () => unsubscribe();
  });

  const applyFilter = filterValues => {
    const {
      dateRange,
      dropoff_location,
      pickup_location,
      status,
      hauler,
      vehicle_id,
      service_type,
      clear,
      dispatch_type,
      groups,
      is_inter_island
    } = filterValues;

    setKeyLog(
      `${
        dateRange.start
          ? `Date Range: ${moment(dateRange?.start).format("lll")} - ${moment(
              dateRange?.end
            ).format("lll")}, `
          : ``
      }Service Type: ${service_type}, Dispatch Type ${dispatch_type}, ${
        pickup_location?.label
          ? `Pickup Location: ${pickup_location?.label}, `
          : ``
      }${
        dropoff_location?.label
          ? `Delivery Location: ${dropoff_location?.label}, `
          : ``
      }${hauler?.label ? `Hauler: ${hauler?.label}, ` : ``}${
        vehicle_id?.label ? `Plate Number: ${vehicle_id?.label}, ` : ``
      }Status: ${status}`,
      `${groups ? `Groups: ${groups}, ` : ``} ${
        is_inter_island !== "All" ? `Trip Type: ${is_inter_island}` : ``
      }`
    );

    AddLogs(
      process.env.REACT_APP_DISPATCH_MODULE,
      "filter",
      `${
        dateRange.start
          ? `Date Range: ${moment(dateRange?.start).format("lll")} - ${moment(
              dateRange?.end
            ).format("lll")}, `
          : ``
      }Service Type: ${service_type}, Dispatch Type ${dispatch_type}, ${
        pickup_location?.label
          ? `Pickup Location: ${pickup_location?.label}, `
          : ``
      }${
        dropoff_location?.label
          ? `Delivery Location: ${dropoff_location?.label}, `
          : ``
      }${hauler?.label ? `Hauler: ${hauler?.label}, ` : ``}${
        vehicle_id?.label ? `Plate Number: ${vehicle_id?.label}, ` : ``
      }Status: ${status}`,
      `${groups ? `Groups: ${groups}, ` : ``} ${
        is_inter_island !== "All" ? `Trip Type: ${is_inter_island}` : ``
      }`
    );

    // setDownloadBTN(true);
    let vars = {
      skip: 0 * rowsPerPage,
      rows_per_page: 0 * rowsPerPage + rowsPerPage,
      // date_range: {
      //   field: dateRange.field
      // },
      orderBy: { field: "ordinal", direction: "asc" },
      conditions: [
        // {
        //   field: "client_id",
        //   value: userDetails.client.id.toString()
        // }
        // { field: "data_complete", value: "true" }
      ],
      or: [],
      not: [
        // { field: "status_code.keyword", value: "Accepted" },
        // { field: "status_code.keyword", value: "Scheduled" },
        // { field: "data_complete", value: "false" }
        { field: "dispatch_type", value: "Not Yet" }
      ]
    };

    if (keyword !== "") {
      vars = { ...vars, keyword: keyword };
    }
    if (is_inter_island !== "All") {
      vars = {
        ...vars,
        is_inter_island: is_inter_island === "Interisland"
      };
    }
    if (pickup_location !== null) {
      vars = { ...vars, pickups: [pickup_location.value] };
    }
    if (dropoff_location !== null) {
      vars = { ...vars, dropoffs: [dropoff_location.value] };
    }
    if (hauler !== null) {
      vars.conditions.push({
        // field: "client_name.keyword",
        field: "group_names.keyword",
        value: [hauler]
      });
    }
    if (service_type !== "All") {
      vars.conditions.push({
        field: "service_type.keyword",
        value: [service_type]
      });
    }
    if (dispatch_type !== "All") {
      vars.conditions.push({
        field: "dispatch_type",
        value: [dispatch_type]
      });
    }
    if (status !== "All") {
      if (status === "Processing") {
        // vars.conditions.push({
        //   field: "status_code.keyword",
        //   value: ["Accepted", "Scheduled"]
        // });
        vars.or.push({
          field: "status_code_id",
          value: [
            process.env.REACT_APP_ACCEPTED_STATUS,
            process.env.REACT_APP_SCHEDULED_STATUS
          ]
        });
      } else if (
        status === "Completed" ||
        status === "Closed" ||
        status === "Cancelled"
      ) {
        vars.conditions.push({ field: "status_code.keyword", value: status });
      } else {
        vars.conditions.push({ field: "status_code.keyword", value: status });
      }
    } else if (clear && !keyword) {
      // vars.conditions.push({
      //   field: "status_code",
      //   value: [
      //     "Incoming",
      //     "Arrived at Pickup",
      //     "Arrived at Drop off",
      //     "In Transit"
      //   ]
      // });
      vars.or.push({
        field: "status_code_id",
        value: [
          process.env.REACT_APP_STATUS_TRIP_INCOMING,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
          process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
          process.env.REACT_APP_STATUS_IN_TRANSIT
        ]
      });
    } else {
      vars.or.push({
        field: "status_code_id",
        value: [
          process.env.REACT_APP_STATUS_TRIP_INCOMING, // Incoming
          process.env.REACT_APP_STATUS_COMPLETED, // Completed
          process.env.REACT_APP_STATUS_TRIP_CLOSED, // Closed
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP, // Arrived at Pickup
          process.env.REACT_APP_STATUS_IN_TRANSIT, // In Transit
          process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF, // Arrived at Drop off
          process.env.REACT_APP_STATUS_CANCEL_ID, // "Cancelled"
          process.env.REACT_APP_STATUS_MANUALLY_COMPLETED
        ]
      });
    }
    if (vehicle_id !== null) {
      vars.conditions.push({ field: "vehicle_id", value: [vehicle_id.value] });
    }

    if (dateRange.start !== null && dateRange.end !== null) {
      vars = { ...vars, date_range: { ...dateRange } };
    } else if (!keyword) {
      vars = { ...vars, date_range: DEFAULT_DATE_RANGE };
    } else {
      vars = {
        ...vars,
        date_range: {
          field: "pickups.arrival",
          start: moment()
            .subtract(6, "months")
            .format("YYYY-MM-DD 00:00:00"),
          end: moment()
            .add(60, "days")
            .format("YYYY-MM-DD 23:59:59")
        }
      };
    }

    const filter =
      vehicle_id !== null ||
      status !== "All" ||
      service_type !== "All" ||
      hauler !== null ||
      dropoff_location !== null ||
      pickup_location !== null ||
      dispatch_type !== "All" ||
      (dateRange.start !== null && dateRange.end !== null) ||
      is_inter_island !== "All";
    setIsFiltered(filter);
    vars = {
      ...vars,
      orderBy: !filter
        ? [
            { field: "is_urgent", direction: "desc" },
            { field: "overdue", direction: "desc" },
            { field: orderBy, direction: order }
          ]
        : [{ field: orderBy, direction: order }]
    };

    setPage(0);
    localStorage.setItem("filter", JSON.stringify(filterValues));
    setQueryFilter(vars);
  };

  useEffect(() => {
    if (!_.isEmpty(location?.state)) {
      if (location.state.fromDetails) {
        const savedFilter = JSON.parse(localStorage.getItem("filter"));
        if (!savedFilter) return;
        applyFilter(savedFilter);
      }
    }

    // eslint-disable-next-line react/destructuring-assignment
  }, [history, location?.state]);

  useEffect(() => {
    if (bookings_data && !_.isEmpty(bookings_data.get_bookings_per_trip)) {
      // parseBookingResults(bookings_data);
      const { sample, total, trips } = dataParser(bookings_data);
      setBookings(trips);
      setParsedTrips(sample);
      setTotalRows(total);
      setCurrentTime(moment().format("MMM D YYYY h:mm A"));
    }
  }, [queryFilter, bookings_data, refetch]);

  const open = Boolean(anchorEl);

  function openVehicleModal() {
    toggleVehicleModal(true);
  }
  function closeVehicleModal() {
    setChosenRowData({});
    toggleVehicleModal(false);
  }
  function openDispatchModal() {
    toggleDispatchModal(true);
  }

  function closeDispatchModal() {
    setChosenRowData({});
    toggleDispatchModal(false);
  }

  const handleCloseClick = val => {
    if (!val) {
      setChosenRowData({});
    }
    toggleCloseModal(val);
  };

  function toggleOptionsMenu(event, tripData, rawTripData) {
    setChosenRowData({
      parsedTripData: tripData,
      rawTripData,
      booking_id: tripData.id,
      pickups: tripData.pickups,
      trip_number: tripData.tripNumber,
      is_split: rawTripData.value[0].is_split || null,
      key: tripData.tripNumber,
      dateRange: {
        start: tripData.pickups[0].arrival,
        end: tripData.dropoffs[tripData.dropoffs.length - 1].arrival
      }
    });
    setAnchorEl(event.currentTarget);
  }

  const toggleDrawer = () => {
    // setDrawer(openDrawer);
    setDrawer(!drawer);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQueryFilter({
      ...queryFilter,
      orderBy: !isFiltered
        ? [
            { field: "is_urgent", direction: "desc" },
            { field: "overdue", direction: "desc" },
            { field: orderBy, direction: isAsc ? "desc" : "asc" }
          ]
        : [{ field: orderBy, direction: isAsc ? "desc" : "asc" }]
    });
  };

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        rows_per_page: nextPage * rowsPerPage + rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return fetchMoreResult;
      }
    }).catch(() => {
      MySwal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(0);
          refetch();
        }
      });
    });
  };

  // const handlePageChanged = (_event, newPage) => {
  //   localStorage.setItem("page", newPage);
  //   setPage(newPage);
  //   fetchMoreData(newPage);
  // };

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (event, newPage) => {
    // setCurrentPage(newPage);
    if (page > newPage) {
      localStorage.setItem("page", page - 1);
      setPage(page - 1);
    } else {
      // check if page is less than the inputted value
      // eslint-disable-next-line no-lonely-if
      if (page < newPage) {
        // check if the input value is greater than the total page
        if (newPage > Math.ceil(totalRows / rowsPerPage)) {
          // if true
          setPage(0);
        } else {
          // if false
          // loop the input value to request the data without skipping other page
          for (let i = 0; i <= newPage; i += 1) {
            // check if the value is already fetch to the server
            if (
              i * rowsPerPage >=
              bookings.length // &&
              // (i - 1) * rowsPerPage >= bookings.length
            ) {
              fetchMoreData(i);
            }
          }
          // set page based on user's input
          setPage(newPage);
        }
      } else {
        // check if the input value is zero
        // eslint-disable-next-line no-lonely-if
        if (Number(newPage) === 0) {
          // set page to default
          setPage(0);
        } else {
          // set page based on user's input
          setPage(newPage);
        }
      }
    }
  };

  const handleCloseDialog = type => {
    switch (type) {
      case "close":
        setOpenClose(false);
        break;
      default:
        break;
    }
  };

  const handleSubmit = type => {
    switch (type) {
      case "close":
        // eslint-disable-next-line no-case-declarations
        const forClose = [];
        _.forEach(closeData, key => {
          _.forEach(key.value, values => {
            forClose.push({
              id: values.id,
              fields: {
                trip_number: values.trip_number,
                status_code_id: process.env.REACT_APP_STATUS_TRIP_CLOSED,
                status_code: "Closed",
                closed_stamp: moment()
                  .utc(+8)
                  .format("YYYY-MM-DD HH:mm:ss")
              }
            });
          });

          addNotif({
            variables: {
              notification: {
                client_id: [key.value[0].client_id],
                trip_number: key.value[0].trip_number,
                vehicle_plate_no: key.value[0].vehicle_plate_no || "",
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                notification: `Trip ${key.value[0].trip_number} has been closed.`,
                category: "Trip",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: key.value[0].group_ids,
                group_names: key.value[0].group_names
              }
            }
          });
        });
        closeTripMutation({ variables: { bookings: forClose } });
        break;
      default:
        break;
    }
  };

  const processTripsForReset = async () => {
    /**
     * This function is for reseting trips related to unpairing vehicle/devices only,
     * do not use this function to reset trips not related to unpairing
     */

    const { ongoingTrips } = location.state.params;
    /**
     * From the list of selected trips, filter out trips for closing and reset
     * For Closing: In Transit and Arrived at Dropoff
     * For Reset: Incoming and Arrived at Pickup
     */
    const selectedTripsForClosing = ongoingTrips.forClose.filter(t =>
      selectedTrips.includes(t.key)
    );
    const selectedTripsForReset = ongoingTrips.forReset.filter(t =>
      selectedTrips.includes(t.key)
    );

    if (selectedTripsForReset.length) {
      const resetResult = await resetTrips(selectedTripsForReset);
      if (resetResult.success) {
        if (selectedTripsForClosing.length) {
          handleSubmit("close");
        } else {
          setSelectedTrips([]);
          refetch();
        }
      } else {
        MySwal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 1500,
          showConfirmButton: false
        });
      }
    } else if (selectedTripsForClosing.length) {
      handleSubmit("close");
    }
  };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   localStorage.setItem("page", 0);
  //   setPage(0);
  // };

  useEffect(() => {
    const queryPlaceholder = { ...queryFilter };
    if (location?.state?.params?.trip_numbers?.length) {
      queryPlaceholder.trip_numbers = location.state.params.trip_numbers;
      // queryPlaceholder.date_range = {
      //   ...queryPlaceholder.date_range,
      //   start: "1999-01-01 00:00:00",
      //   end: "2030-01-01 23:59:59"
      // };
      queryPlaceholder.or = [];
      queryPlaceholder.conditions = [];
      setQueryFilter(queryPlaceholder);
    } else {
      if (localStorage.getItem("filter")) {
        const savedFilter = JSON.parse(localStorage.getItem("filter"));
        setIsFiltered(true);
        applyFilter(savedFilter);
      }

      if (localStorage.getItem("page") === null) {
        setPage(0);
      } else {
        setPage(Number(localStorage.getItem("page")));
      }
    }
  }, []);
  const [loaded, hasLoaded] = useState(false);

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    if (contextData.searchKeyword.submit === true && loaded) {
      setKeyword(contextData.searchKeyword.value);
      setSearchKeyword(prev => ({
        ...prev,
        submit: false
      }));
      const newConditions = [
        // { field: "client_id", value: userDetails.client.id.toString() },
        {
          field: "status_code_id",
          value: [
            process.env.REACT_APP_STATUS_TRIP_INCOMING, // Incoming
            process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP, // Arrived at Pickup
            process.env.REACT_APP_STATUS_IN_TRANSIT, // In Transit
            process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF, // Arrived at Drop off
            process.env.REACT_APP_STATUS_COMPLETED, // Completed
            process.env.REACT_APP_STATUS_TRIP_CLOSED, // Closed
            process.env.REACT_APP_STATUS_CANCEL_ID, // "Cancelled"
            process.env.REACT_APP_STATUS_MANUALLY_COMPLETED
          ]
        }
        // { field: "data_complete", value: "true" }
      ];
      setPage(0);
      // setQueryFilter({
      //   ...initialFilter,
      //   // ...queryFilter,
      //   keyword: contextData.searchKeyword.value,
      //   // conditions:
      //   //   contextData.searchKeyword.value !== ""
      //   //     ? newConditions
      //   //     : initialFilter.conditions,
      //   // conditions: [{ field: "data_complete", value: "true" }],
      //   or:
      //     contextData.searchKeyword.value !== ""
      //       ? newConditions
      //       : initialFilter.conditions,
      //   // : queryFilter.conditions,
      //   not: [
      //     { field: "status_code.keyword", value: "Accepted" },
      //     { field: "status_code.keyword", value: "Scheduled" },
      //     { field: "dispatch_type", value: "Not Yet" }
      //   ]
      // });
      setQueryFilter(prev => {
        let tempDateRange = {};
        const savedFilter = JSON.parse(localStorage.getItem("filter"));

        if (contextData.searchKeyword.value) {
          if (_.isEqual(prev.date_range, DEFAULT_DATE_RANGE)) {
            // If no daterange is set on filter drawer, set dateRange -6months to +2months
            tempDateRange = {
              date_range: {
                field: "pickups.arrival",
                start: moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD 00:00:00"),
                end: moment()
                  .add(60, "days")
                  .format("YYYY-MM-DD 23:59:59")
              }
            };
          }
        } else if (
          !savedFilter?.dateRange?.start ||
          !savedFilter?.dateRange?.end
        ) {
          tempDateRange = { date_range: DEFAULT_DATE_RANGE };
        }
        return {
          ...prev,
          keyword: contextData.searchKeyword.value,
          ...((!savedFilter || savedFilter?.status === "All") && {
            or:
              contextData.searchKeyword.value !== ""
                ? newConditions
                : initialFilter.or
          }),
          ...tempDateRange
        };
      });
    }
    // if (
    //   !loaded &&
    //   contextData.searchKeyword.moduleName !== process.env.REACT_APP_DISPATCH_MODULE
    // ) {
    //   contextData.setSearchKeyword({
    //     ...contextData.searchKeyword,
    //     submit: false,
    //     value: ""
    //   });
    // }
    hasLoaded(true);
  }, [contextData.searchKeyword.submit]);

  useEffect(() => {
    if (searchKeyword.moduleName !== process.env.REACT_APP_DISPATCH_MODULE) {
      setSearchKeyword({
        ...searchKeyword,
        value: "",
        moduleName: process.env.REACT_APP_DISPATCH_MODULE
      });
    }
  }, []);

  // Function used to combine and remove duplicate betweeb two array and return a new array
  const combineArray = (firstArray, secondArray) => {
    // Concat the two given array to form a new single array
    const thirdArray = firstArray.concat(secondArray);
    // Convert the new array into a set to remove a duplicate data and sort into a new array
    const uniqueElements = new Set(thirdArray.sort());
    // Convert the new set into an array and Map the new array to display the desired output
    return Array.from(uniqueElements).map(el => {
      return el ? (
        <span key={el} style={{ display: "block" }}>
          {el}
        </span>
      ) : (
        "No SO Number Yet"
      );
    });
  };

  // Function used to display vehicle plate no, gps status and provider, and battery status
  const renderVehicleCell = booking => {
    let cell = "";
    let data = {
      vehicleType: "",
      deviceTypeId: 0,
      batteryLevel: 0,
      reportstampBatt: null,
      vehicle_status: null
    };
    // dvar.some(elem => elem.id === booking.vehicle_id) &&
    dvar
      .filter(elem => elem.id === booking.vehicle_id)
      .map(drow => {
        data = {
          vehicleType: drow.vehicle_info?.vehicle_type,
          deviceTypeId: drow.device_type_id,
          batteryLevel: drow.device_status?.battery_level,
          reportstampBatt: drow.device_status?.reportstamp,
          vehicle_status: drow.device_status?.status_code
        };
        return data;
      });
    cell = (
      <div style={{ display: "flex" }}>
        <Battery
          vehicleType={data.vehicleType}
          deviceTypeId={data.deviceTypeId}
          batteryLevel={data.batteryLevel}
          tooltipTitle={
            <Fragment>
              <p style={{ margin: 0, padding: 0 }}>
                Battery Life: {data.batteryLevel} %
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                As of{" "}
                {data.reportstampBatt
                  ? moment(data.reportstampBatt).format("lll")
                  : "-"}
              </p>
            </Fragment>
          }
        />
        <Tooltip
          title={
            <Fragment>
              <p style={{ margin: 0, padding: 0 }}>
                {gpsStatus(data.vehicle_status?.toLowerCase()).label}
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                Last Report:{" "}
                {// Check if the vehicle has a device
                (dvar.some(elem => elem.id === booking.vehicle_id) &&
                  dvar.map(drow => {
                    let reportstamp = "";
                    if (drow.id === booking.vehicle_id) {
                      reportstamp =
                        (drow.device_status?.reportstamp &&
                          moment(drow.device_status.reportstamp).format(
                            "lll"
                          )) ||
                        "-";
                      // data = {
                      //   vehicleType: drow.vehicle_info.vehicle_type,
                      //   deviceTypeId: drow.device_type_id,
                      //   batteryLevel: drow.device_status?.battery_level,
                      //   reportstampBatt: drow.device_status?.reportstamp
                      // };
                    }
                    return reportstamp;
                  })) ||
                  "-"}
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                GPS Provider:{" "}
                {(dvar.some(elem => elem.id === booking.vehicle_id) &&
                  dvar.map(drow => {
                    let provider = "";
                    if (drow.id === booking.vehicle_id) {
                      provider = drow.device_config?.gps_provider || "-";
                    }
                    return provider;
                  })) ||
                  "-"}
              </p>
            </Fragment>
          }
        >
          <Typography
            style={{
              // display: "inline-block",
              display: "flex",
              gap: 5,
              fontSize: "0.875rem"
            }}
            component="span"
            noWrap
          >
            <TripOrigin
              style={{
                fontSize: "12px",
                marginTop: 2,
                color: gpsStatus(data.vehicle_status?.toLowerCase()).color
              }}
            />
            <Highlight
              search={contextData.searchKeyword.value}
              className={classes.textStyle}
            >
              {booking.vehicle_plate_no || "-"}
            </Highlight>
          </Typography>
        </Tooltip>
      </div>
    );
    return cell;
  };

  const handleAddLogs = trip_number => {
    if (trip_number) {
      AddLogs(process.env.REACT_APP_DISPATCH_MODULE, "view_trip", trip_number);
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  const isSelected = name => selectedTrips.indexOf(name) !== -1;

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    if (parsedTrips) {
      return stableSort(parsedTrips, getSorting(order, orderBy));
    }
    return null;
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedTrips.length) {
      if (!selectedTrips.length < getData().length) {
        const displayedData = getData().map(data => data.tripNumber);
        if (
          displayedData.every(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: true, indeterminate: false };
        else if (
          displayedData.some(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  // eslint-disable-next-line no-shadow
  const handleClick = (event, id) => {
    const selectedIndex = selectedTrips.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTrips, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTrips.slice(1));
    } else if (selectedIndex === selectedTrips.length - 1) {
      newSelected = newSelected.concat(selectedTrips.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTrips.slice(0, selectedIndex),
        selectedTrips.slice(selectedIndex + 1)
      );
    }

    setSelectedTrips(newSelected);
  };

  const handleSelectAllTrips = event => {
    const tempSelected = selectedTrips;
    const deselect = () => {
      const displayedData = getData()
        .filter(n => {
          if (
            n.trip_status.toLowerCase() !== "closed" &&
            n.trip_status.toLowerCase() !== "completed" &&
            n.trip_status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
          return n;
        })
        .map(n => n.tripNumber);
      // const displayedData = getData().map(n => {
      //   if(n.trip_status.toLowerCase() !== "closed" && n.trip_status.toLowerCase() !== "completed" && n.trip_status.toLowerCase() !== "cancelled") {
      //     return n.tripNumber;
      //   }
      // });
      const newSelected = tempSelected
        .filter(tripNumber => displayedData.indexOf(tripNumber) < 0)
        .map(tripNumber => tripNumber);
      setSelectedTrips(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData()
        .filter(n => {
          if (
            n.trip_status.toLowerCase() !== "closed" &&
            n.trip_status.toLowerCase() !== "completed" &&
            n.trip_status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
        })
        .map(n => tempSelected.push(n.tripNumber));
      setSelectedTrips(_.uniq(tempSelected));
      return;
    }
    deselect();

    // if (e.target.checked) {
    //   setSelectedTrips(
    //     bookings.filter(b => !b.data_completed).map(b => b.tripNumber)
    //   );
    // } else {
    //   setSelectedTrips([]);
    // }
  };

  const limitFeaturesForUnpairing =
    location?.state?.params?.forUnpairing &&
    parsedTrips.every(booking =>
      location?.state?.params.trip_numbers.includes(booking.tripNumber)
    );

  return (
    <AccessControl
      resource="/trips/"
      process="dispatch"
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        {loading && <Loading />}

        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <div className={classes.headerContainer}>
              <div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "25px",
                    margin: "0 10px",
                    fontSize: "13px",
                    textTransform: "none",
                    backgroundColor: isFiltered
                      ? theme.palette.primary.main
                      : "#808080"
                  }}
                  onClick={() => toggleDrawer(true)}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!isFiltered}
                  >
                    <FilterList style={{ marginRight: "5px" }} /> Filter
                  </Badge>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "25px",
                    // margin: "0",
                    fontSize: "13px",
                    backgroundColor: downloadBTN ? "#808080" : "#ff8364",
                    textTransform: "none"
                  }}
                  disabled={downloadBTN}
                  onClick={() => {
                    setDownloadBTN(true);
                    getDownloadData({
                      variables: {
                        ...queryFilter,
                        rows_per_page:
                          bookings_data?.get_bookings_per_trip?.total
                      }
                    }).then(() => {
                      csvLink.current.link.click();
                      AddLogs(
                        process.env.REACT_APP_DISPATCH_MODULE,
                        "download_table",
                        keyLog
                      );
                      setDownloadBTN(false);
                    });
                  }}
                  startIcon={
                    downloadBTN ? <CircularProgress size={15} /> : <GetApp />
                  }
                >
                  {downloadBTN ? `Preparing...` : `Download`}
                  {/* <Refresh style={{ marginRight: "5px" }} /> */}
                  {/* Download */}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <span
                  className={classes.textStyle}
                  style={{ margin: "0 15px 2px 15px" }}
                >
                  As of {currentTime}
                </span>
                <Pagination
                  rowsPerPage={rowsPerPage}
                  total={Math.ceil(totalRows / rowsPerPage)}
                  currentPage={page}
                  onRowsPerPageChange={n => {
                    setRowsPerPage(n);
                    setPage(0);
                  }}
                  onPageChange={n => {
                    setPage(n);
                    localStorage.setItem("page", n);
                  }}
                  onPageUp={n => setPage(n)}
                  onPageDown={n => setPage(n)}
                />
              </div>
            </div>
          </Toolbar>
          {selectedTrips.length > 0 ? (
            <Toolbar className={classes.multipleSelect}>
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {/* <span className={classes.selectedTripsCount}>{selectedTrips.length}</span> */}
                <span className={classes.checkedCount}>
                  {selectedTrips.length}
                </span>{" "}
                trip/s is selected on this page
              </Typography>
              <div className={classes.root} />
              <Button
                color="secondary"
                variant="contained"
                style={{
                  borderRadius: 16,
                  backgroundColor: "#C80202",
                  color: "white",
                  width: 150,
                  fontSize: 13,
                  textTransform: "none"
                }}
                onClick={() => {
                  if (limitFeaturesForUnpairing) {
                    const { ongoingTrips } = location.state.params;
                    /**
                     * Filter out trips for closing from the selected trips
                     * For Closing: In Transit and Arrived at Dropoff
                     */
                    const selectedTripsForClosing = selectedTrips.filter(t =>
                      ongoingTrips.forClose.map(tr => tr.key).includes(t)
                    );
                    if (selectedTripsForClosing.length) {
                      get_selectedBookings({
                        variables: {
                          trip_numbers: selectedTripsForClosing
                        }
                      });
                    }
                  } else {
                    get_selectedBookings({
                      variables: {
                        trip_numbers: selectedTrips
                      }
                    });
                  }
                  setOpenClose(true);
                }}
              >
                Close
              </Button>
            </Toolbar>
          ) : null}
          {/* <Paper className={classes.paper}> */}

          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={parsedTrips.length}
                onSelectAllTrips={handleSelectAllTrips}
                isChecked={isChecked}
                permissions={permissions}
              />
              <TableBody>
                {parsedTrips.length ? (
                  getData().map((row, index) => {
                    const isItemSelected = isSelected(row.tripNumber);
                    const bookingIndex = _.findIndex(bookings, function a(
                      booking
                    ) {
                      return booking.key === row.tripNumber;
                    });
                    const rawTripData = bookings[bookingIndex];
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const detailLink = "/details";
                    const detailState = {
                      tripNumber: row.tripNumber,
                      tripData: row,
                      rawTripData
                    };
                    const redirectToDetails = label => {
                      return (
                        <div>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "#000000",
                              paddingTop: 10,
                              paddingBottom: 10
                            }}
                            to={{
                              pathname: detailLink,
                              state: detailState
                            }}
                          >
                            <Highlight search={contextData.searchKeyword.value}>
                              <span className={classes.textStyle}>{label}</span>
                            </Highlight>
                          </Link>
                        </div>
                      );
                    };
                    const key = `${row.tripNumber}-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={key}
                        style={{ textDecoration: "none" }}
                      >
                        {permissions?.close?.edit && (
                          <TableCell style={{ verticalAlign: "text-top" }}>
                            {row.trip_status.toLowerCase() !== "closed" &&
                            row.trip_status.toLowerCase() !== "cancelled" &&
                            row.trip_status.toLowerCase() !== "completed" ? (
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={event =>
                                  handleClick(event, row.tripNumber)
                                }
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            ) : null}
                          </TableCell>
                        )}
                        <TableCell
                          style={{
                            paddingLeft: "30px",
                            verticalAlign: "text-top"
                          }}
                        >
                          <Tooltip
                            title={
                              row.dispatch_type &&
                              row.dispatch_type.toLowerCase() !==
                                "not yet dispatched"
                                ? `${row.dispatch_type} Dispatched`
                                : "Undefined"
                            }
                          >
                            <Badge
                              classes={{
                                badge: row.dispatch_type
                                  ? (row.dispatch_type.toLowerCase() ===
                                      "manual" &&
                                      classes.customBadgeManual) ||
                                    (row.dispatch_type.toLowerCase() ===
                                      "automatic" &&
                                      classes.customBadgeAutomatic) ||
                                    classes.customBadgeUndefine
                                  : classes.customBadgeUndefine
                              }}
                              variant="dot"
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ verticalAlign: "text-top" }}
                        >
                          {row.is_urgent &&
                            redirectToDetails(
                              <Tooltip title="Hotload">
                                <Warning
                                  style={{ fontSize: 12, color: "#EA4335" }}
                                />
                              </Tooltip>,
                              row.trip_number
                            )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ verticalAlign: "text-top" }}
                        >
                          {row.is_overdue &&
                            redirectToDetails(
                              <Tooltip
                                key="overdue-key-1"
                                title="It seems this trip is overdue. Would you like to close it?"
                              >
                                <AssignmentLate
                                  style={{ fontSize: 12, color: "#EA4335" }}
                                />
                              </Tooltip>
                            )}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          padding="normal"
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 110, verticalAlign: "text-top" }}
                        >
                          <Fragment>
                            {redirectToDetails(
                              <Tooltip
                                title={combineArray(
                                  row.raw_pickups.map(el => {
                                    return el.so_number;
                                  }),
                                  row.raw_dropoffs.map(el => {
                                    return el.so_number;
                                  })
                                )}
                              >
                                <span>
                                  <Highlight
                                    search={contextData.searchKeyword.value}
                                    className={classes.textStyle}
                                  >
                                    {row.tripNumber}
                                  </Highlight>
                                </span>
                              </Tooltip>
                            )}
                          </Fragment>
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 100, verticalAlign: "text-top" }}
                        >
                          <span className={classes.textStyle}>
                            {redirectToDetails(row.service_type || "N/A")}
                          </span>
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ verticalAlign: "text-top" }}
                        >
                          {redirectToDetails(
                            row.pickups.map((el, dIndex) => {
                              const deliveryKey = `${el.id}-${dIndex}`;
                              let color = "";
                              const {
                                status_code,
                                actual_arrival,
                                manual_arrival,
                                arrival
                              } = el;
                              let time = "Vehicle not arrived yet";
                              const isLate = moment(arrival).isBefore(
                                actual_arrival
                              );
                              let textColor = "black";
                              switch (status_code) {
                                case "Manually Completed":
                                  color = "#219653";
                                  time = `Manual arrival time ${moment(
                                    manual_arrival
                                  ).format("lll")}`;
                                  break;
                                case "Completed":
                                  textColor = isLate ? "#EA4335" : "black";
                                  time = (
                                    <Fragment>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        Actual Arrival Time{" "}
                                        <span
                                          style={{
                                            color: isLate
                                              ? "#EA4335"
                                              : "#6DE243"
                                          }}
                                        >
                                          {isLate ? "(Late)" : "(On Time)"}
                                        </span>
                                      </p>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        <Highlight
                                          search={
                                            contextData.searchKeyword.value
                                          }
                                        >
                                          {moment(actual_arrival).format("lll")}
                                        </Highlight>
                                      </p>
                                    </Fragment>
                                  );
                                  color = "#2dd112";
                                  break;
                                case "Arrived at Pickup":
                                  textColor = isLate ? "#EA4335" : "black";
                                  time = (
                                    <Fragment>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        Actual Arrival Time{" "}
                                        <span
                                          style={{
                                            color: isLate
                                              ? "#EA4335"
                                              : "#6DE243"
                                          }}
                                        >
                                          {isLate ? "(Late)" : "(On Time)"}
                                        </span>
                                      </p>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        {moment(actual_arrival).format("lll")}
                                      </p>
                                    </Fragment>
                                  );
                                  color = "#ffc207";
                                  break;
                                default:
                                  color = "#c6c6c6";
                                  break;
                              }

                              return (
                                <Tooltip
                                  title={time}
                                  aria-label={status_code}
                                  key={deliveryKey}
                                >
                                  <span
                                    key={deliveryKey}
                                    style={{
                                      display: "flex",
                                      color: textColor,
                                      gap: 5
                                    }}
                                  >
                                    <Brightness1
                                      style={{
                                        fontSize: 12,
                                        color: color,
                                        marginTop: 3
                                      }}
                                    />
                                    <Highlight
                                      search={contextData.searchKeyword.value}
                                      className={classes.textStyle}
                                    >
                                      {el.geofence_code
                                        ? `${el.geofence_code}-${el.name}`
                                        : el.name}
                                    </Highlight>
                                  </span>
                                </Tooltip>
                              );
                            })
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 130, verticalAlign: "text-top" }}
                        >
                          {redirectToDetails(
                            row.pickups.map(d => (
                              <span
                                style={{ display: "block" }}
                                key={`${d.geofence_id}-${d.arrival}`}
                              >
                                <Highlight
                                  search={contextData.searchKeyword.value}
                                  className={classes.textStyle}
                                >
                                  {moment(d.arrival).format("lll")}
                                </Highlight>
                              </span>
                            ))
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ verticalAlign: "text-top" }}
                        >
                          {redirectToDetails(
                            row.dropoffs.map((el, dIndex) => {
                              const deliveryKey = `${el.id}-${dIndex}`;
                              let color = "";
                              const {
                                status_code,
                                actual_arrival,
                                manual_arrival,
                                arrival
                              } = el;
                              const isLate = moment(arrival).isBefore(
                                actual_arrival
                              );
                              let textColor = "black";
                              let time = "Vehicle not arrived yet";
                              switch (status_code) {
                                case "Manually Completed":
                                  color = "#219653";
                                  time = `Manual arrival time ${moment(
                                    manual_arrival
                                  ).format("lll")}`;
                                  break;
                                case "Completed":
                                  textColor = isLate ? "#EA4335" : "black";
                                  time = (
                                    <Fragment>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        Actual Arrival Time{" "}
                                        <span
                                          style={{
                                            color: isLate
                                              ? "#EA4335"
                                              : "#6DE243"
                                          }}
                                        >
                                          {isLate ? "(Late)" : "(On Time)"}
                                        </span>
                                      </p>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        {moment(actual_arrival).format("lll")}
                                      </p>
                                    </Fragment>
                                  );
                                  color = "#2dd112";
                                  break;
                                case "Arrived at Drop off":
                                  textColor = isLate ? "#EA4335" : "black";
                                  time = (
                                    <Fragment>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        Actual Arrival Time{" "}
                                        <span
                                          style={{
                                            color: isLate
                                              ? "#EA4335"
                                              : "#6DE243"
                                          }}
                                        >
                                          {isLate ? "(Late)" : "(On Time)"}
                                        </span>
                                      </p>
                                      <p style={{ margin: 0, padding: 0 }}>
                                        {moment(actual_arrival).format("lll")}
                                      </p>
                                    </Fragment>
                                  );
                                  color = "#ffc207";
                                  break;
                                default:
                                  color = "#c6c6c6";
                                  break;
                              }

                              return (
                                <Tooltip
                                  title={time}
                                  aria-label={status_code}
                                  key={deliveryKey}
                                >
                                  <span
                                    key={deliveryKey}
                                    style={{
                                      display: "flex",
                                      color: textColor,
                                      gap: 5
                                    }}
                                  >
                                    <Brightness1
                                      style={{
                                        fontSize: 12,
                                        color: color,
                                        marginTop: 3
                                      }}
                                    />
                                    <Highlight
                                      search={contextData.searchKeyword.value}
                                      className={classes.textStyle}
                                    >
                                      {el.geofence_code
                                        ? `${el.geofence_code}-${el.name}`
                                        : el.name}
                                    </Highlight>
                                  </span>
                                </Tooltip>
                              );
                            })
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 130, verticalAlign: "text-top" }}
                        >
                          {redirectToDetails(
                            row.dropoffs.map(d => (
                              <span
                                style={{ display: "block" }}
                                key={`${d.geofence_id}-${d.arrival}`}
                              >
                                <Highlight
                                  search={contextData.searchKeyword.value}
                                  className={classes.textStyle}
                                >
                                  {moment(d.arrival).format("lll")}
                                </Highlight>
                              </span>
                            ))
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 70, verticalAlign: "text-top" }}
                        >
                          <span className={classes.textStyle}>
                            {redirectToDetails(row.hauler)}
                          </span>
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ width: 110, verticalAlign: "text-top" }}
                        >
                          <span>
                            {redirectToDetails(renderVehicleCell(row))}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ width: "100px", verticalAlign: "text-top" }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "100px",
                              color: row.is_inter_island ? "#66347F" : "#2B3467"
                            }}
                            className={classes.muiTableCellData}
                          >
                            {row.is_inter_island
                              ? "Interisland trip"
                              : "Standard trip"}
                          </span>
                        </TableCell>
                        <TableCell
                          onClick={() => handleAddLogs(row.tripNumber)}
                          style={{ verticalAlign: "text-top" }}
                        >
                          {redirectToDetails(
                            <Fragment>
                              <span
                                style={{ color: "#C4C4C4", display: "block" }}
                              >
                                <Highlight
                                  search={contextData.searchKeyword.value}
                                >
                                  {row.trip_status}
                                </Highlight>
                              </span>
                              {(row.trip_status.toLowerCase() === "incoming" ||
                                row.trip_status.toLowerCase() ===
                                  "in transit") &&
                                row.eta && (
                                  <Tooltip title={`As of ${row.eta.as_of}`}>
                                    <span
                                      style={{
                                        display: "block",
                                        fontSize: 10,
                                        color: row.eta.isLate ? "red" : "green"
                                      }}
                                    >
                                      <ErrorOutline
                                        style={{
                                          fontSize: "inherit",
                                          color: "inherit"
                                        }}
                                      />
                                      {row.eta.eta}
                                    </span>
                                  </Tooltip>
                                )}
                            </Fragment>
                          )}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          {row.trip_status.toLowerCase() !== "completed" &&
                          row.trip_status.toLowerCase() !== "cancelled" ? (
                            <IconButton
                              aria-label="action"
                              onClick={event =>
                                toggleOptionsMenu(event, row, rawTripData)
                              }
                            >
                              <MoreVert />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow
                    // style={{ height: 53 * emptyRows }}
                    style={{ height: 100 }}
                  >
                    <TableCell
                      colSpan={20}
                      align="center"
                      style={{ background: "#F8F8F8" }}
                    >
                      <Fade in={parsedTrips.length === 0}>
                        <Typography
                          align="center"
                          variant="h6"
                          style={
                            !loader ? { margin: "15px" } : { display: "none" }
                          }
                          className={classes.textStyle}
                        >
                          No data to display
                        </Typography>
                      </Fade>
                      <CircularProgress
                        size={60}
                        style={loader ? {} : { display: "none" }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div style={{ display: "none" }}>
          <CSVLink
            data={downloadData.body}
            headers={downloadData.headers}
            ref={csvLink}
            style={{ color: "black", textDecoration: "none" }}
          >
            Download Table
          </CSVLink>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={closeOptionsMenu}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: 200
            }
          }}
        >
          {limitFeaturesForUnpairing ? (
            <MenuItem
              onClick={async () => {
                closeOptionsMenu();

                /**
                 * Check if selected trip is for closing or reset
                 * For Closing: In Transit and Arrived at Dropoff
                 * For Reset: Incoming and Arrived at Pickup
                 */
                const { ongoingTrips } = location.state.params;
                if (
                  ongoingTrips.forReset
                    .map(t => t.key)
                    .includes(chosenRowData.trip_number)
                ) {
                  const resetResult = await resetTrips([
                    chosenRowData.rawTripData
                  ]);
                  if (!resetResult.success) {
                    MySwal.fire({
                      icon: "error",
                      text: "Something went wrong",
                      timer: 1500,
                      showConfirmButton: false
                    });
                  } else {
                    refetch();
                  }
                } else if (
                  ongoingTrips.forClose
                    .map(t => t.key)
                    .includes(chosenRowData.trip_number)
                ) {
                  handleCloseClick(true);
                }
              }}
              className={classes.textStyle}
            >
              Close
            </MenuItem>
          ) : (
            <Fragment>
              {permissions?.change_truck?.edit && (
                <MenuItem
                  onClick={() => {
                    openVehicleModal();
                    closeOptionsMenu();
                  }}
                  disabled={
                    !_.isEmpty(chosenRowData) &&
                    (chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                      "completed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "closed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "cancelled")
                  }
                  className={classes.textStyle}
                >
                  Change Truck
                </MenuItem>
              )}
              {permissions?.split?.edit && !chosenRowData?.is_split ? (
                <MenuItem
                  component={Link}
                  to={
                    !chosenRowData?.parsedTripData?.has_empty_so
                      ? {
                          pathname: `/split`,
                          state: {
                            ...chosenRowData,
                            moduleName: process.env.REACT_APP_DISPATCH_MODULE
                          }
                        }
                      : {}
                  }
                  disabled={
                    !_.isEmpty(chosenRowData) &&
                    (!chosenRowData.parsedTripData.has_split ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "incoming" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "completed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "closed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "cancelled")
                  }
                  onClick={() => {
                    if (chosenRowData?.parsedTripData?.has_empty_so) {
                      MySwal.fire({
                        icon: "warning",
                        title: "Warning!",
                        text: "Please complete trip details, no SO number",
                        timer: 3000
                      });
                    }
                  }}
                  className={classes.textStyle}
                >
                  Split
                </MenuItem>
              ) : (
                ""
              )}

              {permissions?.dispatch?.edit && (
                <MenuItem
                  onClick={() => {
                    openDispatchModal();
                    closeOptionsMenu();
                  }}
                  disabled={
                    !_.isEmpty(chosenRowData) &&
                    chosenRowData.parsedTripData.trip_status.toLowerCase() !==
                      "processing"
                  }
                  className={classes.textStyle}
                >
                  Dispatch
                </MenuItem>
              )}
              {permissions?.manual_complete?.edit && (
                <MenuItem
                  component={Link}
                  to={{
                    pathname: `/details`,
                    state: {
                      rawTripData: chosenRowData.rawTripData,
                      tripData: chosenRowData.parsedTripData,
                      isEdit: true
                      // key: chosenRowData.trip_number
                    }
                  }}
                  disabled={
                    !_.isEmpty(chosenRowData) &&
                    chosenRowData.parsedTripData.trip_status.toLowerCase() !==
                      "closed"
                  }
                  className={classes.textStyle}
                >
                  Manual Complete
                </MenuItem>
              )}
              {permissions?.close?.edit && (
                <MenuItem
                  disabled={
                    !_.isEmpty(chosenRowData) &&
                    (chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                      "completed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "closed" ||
                      chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                        "cancelled")
                  }
                  onClick={() => {
                    handleCloseClick(true);
                    closeOptionsMenu();
                  }}
                  className={classes.textStyle}
                >
                  Close
                </MenuItem>
              )}
              {permissions?.map_view?.edit && (
                <MenuItem
                  component={Link}
                  // disabled={permissions && !permissions.map_view.edit}
                  to={{
                    pathname: `/details`,
                    state: {
                      rawTripData: chosenRowData.rawTripData,
                      isEdit: false,
                      mapView: true
                      // key: chosenRowData.trip_number
                    }
                  }}
                  className={classes.textStyle}
                >
                  Map View
                </MenuItem>
              )}
              <MenuItem
                onClick={() => setOpenInterIsland(true)}
                className={classes.textStyle}
              >
                {chosenRowData.parsedTripData?.is_inter_island
                  ? "Untag as Interisland"
                  : "Tag as Interisland"}
              </MenuItem>
            </Fragment>
          )}
        </Menu>

        {drawer && (
          <Filter
            drawer={drawer}
            toggleDrawer={toggleDrawer}
            // classes={classes}
            applyFilter={applyFilter}
            loaded={loaded}
            serviceTypes={serviceTypes}
            moduleName="Trips"
          />
        )}
        <VehicleAssignDialog
          moduleName={process.env.REACT_APP_DISPATCH_MODULE}
          toggle={vehicleModal}
          closeVehicleModal={closeVehicleModal}
          openVehicleModal={openVehicleModal}
          setTrip={setTrip}
          tripData={chosenRowData}
          // booking_id={chosenRowData.booking_id}
          // trip_number={chosenRowData.trip_number}
          queryFilter={{
            ...queryFilter,
            skip: page * rowsPerPage,
            rows_per_page: page * rowsPerPage + rowsPerPage
          }}
          fn={() => {
            refetch();
          }}
          // history={history}
        />
        <DispatchDialog
          moduleName={process.env.REACT_APP_DISPATCH_MODULE}
          toggle={dispatchModal}
          closeDispatchModal={closeDispatchModal}
          tripData={chosenRowData}
          queryFilter={{
            ...queryFilter,
            skip: page * rowsPerPage,
            rows_per_page: page * rowsPerPage + rowsPerPage
          }}
          fn={() => {
            refetch();
          }}
        />
        <CloseDialog
          moduleName={process.env.REACT_APP_DISPATCH_MODULE}
          open={closeModal}
          toggle={handleCloseClick}
          queryFilter={{
            ...queryFilter,
            skip: page * rowsPerPage,
            rows_per_page: page * rowsPerPage + rowsPerPage
          }}
          tripData={chosenRowData}
        />
        <ConfirmationDialog
          toggle={confirmationDialog}
          close={() => setConfirmationDialog(null)}
          fn={() => {
            refetch();
          }}
          title="Refresh list?"
          content="Are you sure you want to refresh the list of the trips?"
        />
        <ConfirmationDialog
          toggle={openClose}
          close={() => handleCloseDialog("close")}
          fn={() =>
            limitFeaturesForUnpairing
              ? processTripsForReset()
              : handleSubmit("close")
          }
          title="Close All Selected?"
          content="You cannot undo this action. Are you sure you want to close the selected items?"
        />
        {openInterIsland && (
          <ConfirmationDialog
            toggle={openInterIsland}
            close={() => setOpenInterIsland(false)}
            fn={() => interIslandHandler()}
            title={
              chosenRowData?.parsedTripData?.is_inter_island
                ? "Untag Trip as Interisland"
                : "Tag Trip as Interisland?"
            }
            content={`Are you sure you want to ${
              chosenRowData?.parsedTripData?.is_inter_island ? "untag" : "tag"
            } this trip as interisland?`}
          />
        )}
        {openConfirm && (
          <ConfirmationDialog
            toggle={openConfirm}
            close={() => setOpenConfirm(false)}
            fn={() => refetch()}
            title="Success"
            content="Trip type updated successfully."
            type="basic_confirm_modal"
          />
        )}
      </div>
    </AccessControl>
  );
}
