/*eslint-disable no-unused-vars*/
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import ChipInput from "material-ui-chip-input";
// import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
  // InputLabel,
  Tooltip,
  Switch,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  Toolbar,
  FormControl
} from "@material-ui/core";
import {
  // Help as HelpIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  HelpRounded
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Swal from "sweetalert2";
import _ from "lodash";
import Header from "../../../Header";
import AccessControl from "../../../../../utils/AccessControl";
import { ADD_GPS_PROVIDERS } from "../../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../../utils/validation";
import redirectToTable from "../../../../../utils/redirect";
import SelectGroup from "../../../../../utils/modals/SelectGroup";
import AddLogs from "../../../../../utils/functions/AddLogs";
import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";

import useFormStyles, {
  useFormErrorStyles
} from "../../../../../styles/useFormStyles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    // height: "calc(100% - 66px)",
    // position: "relative"
  },
  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    height: 30,
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
    marginLeft: theme.spacing(3)
  },
  searchInput: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  container: {
    minHeight: "100%",
    height: "auto",
    // position: "relative",
    margin: "25px",
    padding: "25px",
    // overflow: "auto",
    flexGrow: "1",
    boxSizing: "border-box",
  },
  scrollable: {
    height: "calc(100% - 66px)",
    boxSizing: "border-box",
    overflow: "auto"
  },
  field: {
    marginTop: "25px"
  },
  multiline: {
    marginTop: "25px"
  },
  column: {
    fontSize: "1rem"
  },
  button: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    // bottom: "25px",
    // top: "50px",
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    width: "350px"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dialog: {
    borderRadius: "10px"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  center: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

// const HtmlTooltip = withStyles(theme => ({
//   tooltip: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9"
//   }
// }))(Tooltip);

export default function AddGPSProvider(props) {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const formErrorStyles = useFormErrorStyles();
  const [state, setState] = useState({
    provider: "",
    remarks: "",
    api_key: "",
    ip_address: []
  });
  const [checkAPI, setCheckAPI] = useState({ status: false, readOnly: false });
  const [initialState] = useState(_.cloneDeep(state));
  const [errors, setErrors] = useState({
    provider: "",
    remarks: ""
  });
  const [orderBy, setOrderBy] = useState("asc");
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const { location } = props;
  const { pathname } = location;
  const [open, setOpen] = React.useState(false);
  const [keyword, setKeyword] = useState("");
  const history = useHistory();

  const [dialog, setDialog] = useState(false);
  const [groupModal, toggleGroupModal] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [render, setRender] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);

  const [mutationAddProvider] = useMutation(ADD_GPS_PROVIDERS, {
    onCompleted: data => {
      const { success, error } = data.add_gps_provider;
      if (success) {
        AddLogs("Admin - GPS Providers", "insert", state.provider);
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            setState({ provider: "", remarks: "" });
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_GPS_PROVIDER_MODULE
                }
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: error[0].message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            // redirectToTable(history, process.env.REACT_APP_GPS_PROVIDER_MODULE);
          }
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_GPS_PROVIDER_MODULE);
          }
        });
      }
    }
  });

  const handleBlur = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let error;
    switch (name) {
      case "provider":
        if (value.trim() === "") {
          error = "This field is required";
        } else {
          // error = /^[a-z 0-9]+$/i.test(value) ? "" : "Alphanumeric characters only";
          error = validate("alphanumeric", value)
            ? ""
            : "Alphanumeric characters only";
        }
        break;
      case "remarks":
        if (value.trim() !== "") {
          // error = /^[a-z 0-9]+$/i.test(value)
          error = validate("alphanumeric", value)
            ? ""
            : "Alphanumeric characters only";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let error;
    // switch (name) {
    //   case "provider":
    //     if (value.trim() === "") {
    //       error = "GPS provider name is Required.";
    //     } else {
    //       // error = /^[a-z 0-9]+$/i.test(value) ? "" : "Alphanumeric characters only";
    //       error = validate("alphanumeric", value)
    //         ? ""
    //         : "Alphanumeric characters only";
    //     }
    //     break;
    //   case "remarks":
    //     if (value.trim() !== "") {
    //       // error = /^[a-z 0-9]+$/i.test(value)
    //       error = validate("alphanumeric", value)
    //         ? ""
    //         : "Alphanumeric characters only";
    //     }
    //     break;
    //   default:
    //     break;
    // }
    setErrors({ ...errors, [name]: error });
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveProvider = event => {
    event.preventDefault();
    const { provider, remarks, ip_address, api_key } = state;
    mutationAddProvider({
      variables: {
        name: provider,
        remarks: remarks,
        api_key: api_key,
        registered_IPS: ip_address ? ip_address.toString() : "",
        group_ids: groupList ? groupList.map(item => Number(item.id)) : []
      }
    });
  };

  const handleClickOpen = () => {
    const { provider } = state;
    if (
      errors.provider === "" &&
      errors.remarks === "" &&
      provider.trim() !== ""
    ) {
      setOpen(true);
    } else if (provider.trim() === "") {
      setErrors(errors => ({
        ...errors,
        provider: "This field is required"
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < groupList.length) {
        const displayedData = groupList.map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const onSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = groupList.map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      groupList.map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const generateAPI = event => {
    if (event.target.checked) {
      setCheckAPI({ status: true, readOnly: true });
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
      }
      const uuid = "yxxxxyxxxxyxxxxy".replace(/[xy]/g, function(c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line eqeqeq
      });

      setState({
        ...state,
        api_key: uuid
      });
    } else {
      setCheckAPI({ status: false, readOnly: false });
      setState({
        ...state,
        api_key: ""
      });
    }
  };
  function ValidateIPaddress(ipaddress) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    return false;
  }

  function openGroupModal() {
    toggleGroupModal(true);
  }

  function closeGroupModal() {
    toggleGroupModal(false);
  }

  const handleGroupList = array => {
    setGroupList(array);
    setErrors({ ...errors, groupList: "" });
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleRemoveGroup = grp_id => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(grp_id);
    temp.splice(index, 1);
    setGroupList(temp);
    setRender(!render);
  };

  const handleMultipleDeletePartner = () => {
    let temp = [...groupList];
    temp = temp.filter(item => !selected.includes(item.id));
    setGroupList([...temp]);
    setSelected([]);
  };

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/wti/gps_providers/"
  // );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // if (loadingPermissions) return <Typography>Loading...</Typography>;
  // if (!permissions.edit) return <Typography>403</Typography>;

  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          // process={_.isEqual(initialState, state) ? "View" : "Add"}
          process="Add"
          moduleName={process.env.REACT_APP_GPS_PROVIDER_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={
            _.isEqual(initialState, state) &&
            _.isEqual(groupList, [])

          }
        />
        <div className={classes.scrollable}>
        <Paper className={classes.container}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", marginBottom: "16px" }}
            >
              <Typography>
                All fields with <span style={{ color: "red" }}>*</span> are
                required
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} style={{ marginRight: "25px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl classes={{ root: formStyles.formRoot }}>
                    <TextField
                      required
                      fullWidth
                      classes={{ root: formStyles.textFieldRoot }}
                      id="provider"
                      name="provider"
                      label="GPS Provider"
                      placeholder="Enter GPS provider"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={state.provider}
                      onChange={e => handleChange(e)}
                      onBlur={handleBlur}
                    />
                    {errors.provider && (
                      <Alert
                        severity="error"
                        classes={{
                          root: formErrorStyles.root,
                          standardError: formErrorStyles.standardError,
                          icon: formErrorStyles.icon
                        }}
                      >
                        {errors.provider}
                      </Alert>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl classes={{ root: formStyles.formRoot }}>
                    <ChipInput
                      // InputProps={{
                      //   className: classes.inputLabel,
                      //   height: "50px",
                      //   width: "100%",
                      //   placeholder: "Type here..."
                      // }}
                      fullWidth
                      label="IP Address"
                      classes={{ root: formStyles.textFieldRoot }}
                      style={{ padding: "6px 0px" }}
                      placeholder="Enter IP Address"
                      value={state.ip_address ? state.ip_address : null}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onAdd={chip => {
                        const validate = ValidateIPaddress(chip.toString());
                        if (validate) {
                          let joined = state.ip_address.concat(chip);
                          setState({ ...state, ip_address: joined });
                        }
                      }}
                      onDelete={chip =>
                        setState({
                          ...state,
                          ip_address: state.ip_address.filter(
                            item => !chip.includes(item)
                          )
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl classes={{ root: formStyles.formRoot }}>
                    <div className={classes.center}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#454545",
                          letterSpacing: "1px"
                        }}
                      >
                        API Key
                      </span>
                      <span className={classes.center}>
                        <Tooltip
                          placement="top-start"
                          classes={{ tooltip: formStyles.toolTip }}
                          title={
                            <React.Fragment>
                              If turned on, code will be generated.
                              <br />A unique API Key for a GPS Integrator that
                              sends data to WTI
                            </React.Fragment>
                          }
                        >
                          <HelpRounded className={classes.helpIcon} />
                        </Tooltip>
                        <Switch
                          size="small"
                          color="primary"
                          checked={checkAPI.status}
                          onChange={e => generateAPI(e)}
                        />
                      </span>
                    </div>
                    <TextField
                      fullWidth
                      classes={{ root: formStyles.textFieldRoot }}
                      InputProps={{
                        readOnly: checkAPI.readOnly
                      }}
                      // style={{ width: "85%" }}
                      // id="standard-required"
                      type="text"
                      placeholder="Enter API Key"
                      InputLabelProps={{
                        shrink: true
                      }}
                      name="api_key"
                      value={state.api_key}
                      onChange={e => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl classes={{ root: formStyles.formRoot }}>
                    <Typography
                      classes={{ root: formStyles.formLabelRoot }}
                      variant="subtitle2"
                    >
                      Remarks
                    </Typography>
                    <TextField
                      fullWidth
                      // style={{ width: "100%" }}
                      classes={{ root: formStyles.textFieldRoot }}
                      className="outlinedTextarea"
                      multiline
                      rows="4"
                      variant="outlined"
                      placeholder="Type Here..."
                      name="remarks"
                      id="remarks"
                      value={state.remarks}
                      onChange={e => handleChange(e)}
                      onBlur={handleBlur}
                    />
                    {errors.remarks && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.remarks}
                      </Alert>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              {/* <div style={{ display: "flex" }}> */}
              <Toolbar style={{ padding: 0 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip
                    classes={{ tooltip: formStyles.toolTip }}
                    title="Data of primary client will be linked to all selected partners(and vise versa)"
                    placement="top-start"
                  >
                    <HelpRounded className={classes.helpIcon} />
                  </Tooltip>
                  <Typography className={classes.column}>Partners</Typography>
                </div>
                {/* <div style={{ flexGrow: 1 }} /> */}
                <Paper className={classes.searchRoot}>
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Search Partner"
                    inputProps={{ "aria-label": "partner" }}
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Toolbar>
              {/* </div>
              <br /> */}
              {selected.length > 0 ? (
                <Toolbar
                  className={clsx(classes.TBroot, {
                    [classes.highlight]: selected.length > 0,
                    [classes.invisible]: selected.length <= 0
                  })}
                >
                  {selected.length > 0 && (
                    <Typography
                      className={classes.title}
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                    >
                      <span style={{ color: "#FF845E" }}>
                        {selected.length}
                      </span>{" "}
                      client/s selected
                    </Typography>
                  )}

                  {selected.length > 0 && (
                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          handleOpenDeleteAll();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Toolbar>
              ) : null}
              <Paper
                style={{
                  paddingTop: 16,
                  minHeight: "50%",
                  maxHeight: "290px",
                  overflow: "auto"
                }}
                variant="outlined"
              >
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={isChecked().indeterminate}
                            checked={isChecked().all}
                            onChange={onSelectAllClick}
                            inputProps={{
                              "aria-label": "select all partners"
                            }}
                          />
                        </TableCell>
                        <TableCell
                          padding="normal"
                          // sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            // classes={{ icon: classes.icon }}
                            // active={orderBy === headCell.id}
                            // direction={orderBy === headCell.id ? order : "asc"}
                            // onClick={createSortHandler(headCell.id)}
                            // onClick={() => handleSort(headCell.id)}
                            // IconComponent={UnfoldMoreIcon}
                            active
                            direction={orderBy}
                            onClick={() =>
                              setOrderBy(prev =>
                                prev === "asc" ? "desc" : "asc"
                              )
                            }
                          >
                            Client
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <Button color="primary" onClick={() => openGroupModal()}>
                            Add Entry
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupList &&
                        stableSort(groupList, getComparator(orderBy, "name"))
                          .filter(
                            row =>
                              row.name.toUpperCase().indexOf("".toUpperCase()) >
                                -1 &&
                              row.name
                                .toLowerCase()
                                .indexOf(keyword.toLowerCase()) > -1
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={event =>
                                      handleClick(event, row.id)
                                    }
                                    checked={isItemSelected}
                                    // onClick={e => handleGroupSelect(e, row)}
                                    // checked={isChecked(row.id)}
                                    inputProps={{
                                      "aria-labelledby": labelId
                                    }}
                                    color="primary"
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="left"
                                >
                                  {row.name}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="right"
                                >
                                  <DeleteIcon
                                    onClick={() => handleRemoveGroup(row.id)}
                                    style={{ paddingRight: 8 }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClickOpen}
          >
            Save Details
          </Button>
        </Paper>
        </div>
        <ConfirmationDialog
          toggle={open}
          close={() => handleClose()}
          fn={e => handleSaveProvider(e)}
          title="Save"
          content="Are you sure you want to save this data?"
        />
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleCloseDialog()}
          fn={() => handleCloseDialog()}
          // toggle={discard}
          // close={() => setDiscard(false)}
          // fn={() => setRedirect(true)}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleMultipleDeletePartner(e)}
          title="Delete?"
          content="Are you sure you want to delete these partners?"
        />
        <SelectGroup
          toggle={groupModal}
          closeGroupModal={closeGroupModal}
          handleGroupList={handleGroupList}
          groupList={groupList}
        />
      </div>
    </AccessControl>
  );
}
