import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";

const useTableCellText = makeStyles({
  label: ({ capitalize }) =>
    capitalize
      ? {
          textTransform: "capitalize"
        }
      : null
});

const TableCellText = ({ text, capitalize, size = 16 }) => {
  const styles = useTableCellText({ text, capitalize });
  return (
    <Typography variant="body1" component="div">
      <Box fontWeight={500} fontSize={size} className={styles.label}>
        {text}
      </Box>
    </Typography>
  );
};

export default TableCellText;
