/**
 * Tasks
 *    - handled renderInfoBubble props ✅
 *    - InfoBubble prop getters/collection ✅
 *    - Animation positions
 *      - Top ✅
 *      - Right ✅
 *      - Bottom ✅
 *      - Left ✅
 *    - Implement compound component in InfoBubble
 *      - Title        -> <Title /> ❗
 *      - Body         -> <Body /> ❗
 *      - Description  -> <Description /> ❗
 *      - Actions      -> <Actions /> ❗
 */
import React, { createElement } from "react";
import { alpha, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Onboarding, { useOnboardingContext } from "../index";

const defaultInfoBubbleProps = {
  position: "right",
  title: "",
  description: "",
  body: "",
  disableAction: false,
  onNext: undefined,
  module: Onboarding.modules.monitoring
};

export const useInfoBubbleStyles = makeStyles(theme => ({
  infoBubble: {
    position: "absolute",
    boxShadow: "0px 0px 20px #5a5a5ab5",
    top: props =>
      // eslint-disable-next-line no-nested-ternary
      props.position === "bottom"
        ? "calc(100% + 25px)"
        : props.position === "top"
        ? "auto"
        : "0px",
    bottom: props =>
      // eslint-disable-next-line no-nested-ternary
      props.position === "top"
        ? "calc(100% + 25px)"
        : props.position === "bottom"
        ? "auto"
        : "auto",
    // left: "auto",
    left: props =>
      // eslint-disable-next-line no-nested-ternary
      props.position === "bottom" || props.position === "top"
        ? "auto"
        : props.position === "right"
        ? "calc(100% + 25px)"
        : "auto",
    // right: props => (props.position === "left" ? "calc(100% + 25px)" : "auto"),
    right: props =>
      // eslint-disable-next-line no-nested-ternary
      props.position === "top" || props.position === "bottom"
        ? "calc(50% - 125px)"
        : props.position === "left"
        ? "calc(100% + 25px)"
        : "auto",
    background: "white",
    padding: 18,
    borderRadius: 4,
    // height: 250,
    minHeight: props => (props.disableAction ? "fit-content" : 220),
    minWidth: 250,
    width: 250,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    boxSizing: "border-box",
    animation: "$grow 200ms cubic-bezier(0, 0.02, 0.4, 1.58) 450ms 1 forwards",
    transformOrigin: props =>
      // eslint-disable-next-line no-nested-ternary
      props.position === "bottom"
        ? "top center"
        : // eslint-disable-next-line no-nested-ternary
        props.position === "left"
        ? "right 25px"
        : props.position === "top"
        ? "bottom center"
        : "left 25px", // X, Y,
    transform: "scale(0)",
    "&::after": {
      content: "''",
      position: "absolute",
      height: "20px",
      width: "20px",
      background: "#fff",
      left: props =>
        // eslint-disable-next-line no-nested-ternary
        props.position === "bottom" || props.position === "top"
          ? "50%"
          : props.position === "left"
          ? "auto"
          : "-10px",
      right: props => (props.position === "left" ? "-10px" : "auto"),
      transform: props =>
        props.position === "bottom" || props.position === "top"
          ? "rotate(45deg) translateX(-50%)"
          : "rotate(45deg)",
      zIndex: -1,
      top: props =>
        // eslint-disable-next-line no-nested-ternary
        props.position === "bottom"
          ? 0
          : props.position === "top"
          ? "auto"
          : 25,
      bottom: props => (props.position === "top" ? "-17px" : "auto")
    }
  },
  "@keyframes grow": {
    "0%": {
      transform: "scale(0)"
    },
    "100%": {
      transform: "scale(1)"
    }
  },
  infoBubbleTitle: {
    fontWeight: 600,
    color: "#000"
  },
  infoBubbleDescription: {
    fontSize: 12,
    color: "#999"
  },
  infoBubbleBody: {
    flex: 1
  },
  infoBubbleFooter: {
    bottom: "10%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%"
  },
  infoBubbleLegends: {
    listStyleType: "none",
    padding: 0,
    fontSize: 12,
    color: "#999",
    margin: 0
  },
  legend: {
    display: "flex",
    alignItems: "center",
    gap: 15
  },
  underrepairIcon: {
    fontSize: "13px",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 3
  },
  icon: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const InfoBubble = props => {
  // Q 💭 Can we make this component unreliant on Focus component?
  // A 💡  Maybe? Actually, the reason why we setup the InfoBubble like this
  //       is: If we make this component unreliant on Focus component, what will
  //       be the basis of it's position?
  //
  // Conclusion 📑 I'm sure that composing the InfoBubble separately in Focus is possible.
  //               We should try that.
  //
  // +============================================================+
  //
  // 👉 We should add support for positions:
  //      - 👆 top-[right 👉, center 🙌, left 👈]
  //      - 👈 left-[top 👆, center 🙌, bottom 👇]
  //      - 👇 bottom-[right 👉, center 🙌, left 👈]
  //      - 👈 left-[top 👆, center 🙌, bottom 👇]
  //
  // 💡 Currently have support for position: top, right, bottom and left
  //

  const {
    title = "",
    description = "",
    body = "",
    disableAction = false,
    onNext,
    classes
  } = props;
  const context = useOnboardingContext();
  // const classes = useInfoBubbleStyles(props);
  return (
    <div className={classes.infoBubble}>
      <div className={classes.infoBubbleTitle}>{title}</div>
      <div className={classes.infoBubbleDescription}>{description}</div>
      <div className={classes.infoBubbleBody}>{body}</div>
      {!disableAction && (
        <div className={classes.infoBubbleFooter}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{ borderRadius: 20 }}
            onClick={() => {
              if (typeof onNext === "function") {
                onNext({ ...context });
                return;
              }
              context.nextStep(context.module);
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

const useIndicatorStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "fit-content",
    // display: props => (props.show ? "block" : "none"),
    "&::before": {
      content: "''",
      position: "absolute",
      top: props => 0 + props.yOffset,
      right: props =>
        props.placement === "top-right" ? 0 + props.xOffset : "auto",
      left: props =>
        props.placement === "top-left" ? 0 + props.xOffset : "auto",
      width: 14,
      height: 14,
      borderRadius: "6px",
      background: theme.palette.primary.main,
      zIndex: 1,
      boxShadow: `0px 0px 12px ${alpha(theme.palette.primary.main, 0.7)}`
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 22,
      height: 22,
      borderRadius: "50%",
      background: "transparent",
      border: `1px solid ${alpha(theme.palette.primary.main, 0.7)}`,
      // transform: "translate(50%, 50%)",
      zIndex: 1,
      top: props => -5 + props.yOffset,
      // right: -5,
      right: props =>
        props.placement === "top-right" ? -5 + props.xOffset : "auto",
      left: props =>
        props.placement === "top-left" ? -5 + props.xOffset : "auto",
      animation: "$aura 2s ease-in-out infinite"
    }
  },
  "@keyframes aura": {
    "0%": {
      transform: "scale(1)",
      opacity: 0
    },
    "75%": {
      opacity: 1
    },
    "100%": {
      transform: "scale(1.2)",
      opacity: 0
    }
  }
}));

export const Indicator = ({
  children,
  show = true,
  placement = "top-right",
  yOffset = 0,
  xOffset = 0
}) => {
  const classes = useIndicatorStyles({ show, placement, yOffset, xOffset });
  const { progress } = useOnboardingContext();
  let shouldMount = show;
  if (typeof show === "function") {
    shouldMount = show({ progress });
  }
  return <div className={shouldMount ? classes.root : ""}>{children}</div>;
};

const useFocusStyles = makeStyles(() => ({
  root: {
    position: "relative",
    zIndex: props => (props.activeStep && props.on ? 1301 : "auto"),
    background: "rgba(255,255,255, 0)",
    transition: "all 500ms ease-in"
  },
  activeFocus: {
    background: "rgba(255,255,255,1) !important"
  }
}));

/**
 * @Focus [Component Props 😑]
 * @step (Int | Array) - Detemines when the focus should activate. @default 0
 * @on (Boolean) - Allows the developer to manually on and off the focus. @default true
 * @infoBubbleProps (Object) - Allows the developer to override the props of InfoBubble. @default {}
 * @indicator (Boolean) - If 'true', it will add Press indicator to the
 *              children component/element of Focus component. @default false
 * @renderInfoBubble (React Element) - Allows the developer to render a customized InfoBubble. @default InfoBubble
 * @showInfoBubble (Boolean) - If 'true', it will display the InfoBubble component. @default true
 * @component (React Element) - Allows the developer to change the root element of Focus component or
 *                              mimic an existing element. @default div
 */

const Focus = ({
  children,
  showInfoBubble = true,
  renderInfoBubble = InfoBubble,
  className = "",
  style,
  on = true,
  infoBubbleProps = {},
  indicator = false,
  indicatorProps = {},
  step = 0,
  component = "div",
  ...componentProps
}) => {
  const { progress, module } = useOnboardingContext();
  const currentStep = progress[module];
  const activeStep = Array.isArray(step)
    ? step.includes(currentStep)
    : step === currentStep;
  const classes = useFocusStyles({ on, activeStep });
  const infoBubbleClasses = useInfoBubbleStyles({
    ...defaultInfoBubbleProps,
    ...infoBubbleProps
  });
  const Element = createElement(
    component,
    {
      className: `${classes.root} ${className} ${activeStep &&
        classes.activeFocus}`,
      style,
      ...componentProps
    },
    <>
      {indicator ? (
        <Indicator {...indicatorProps}>{children}</Indicator>
      ) : (
        children
      )}
      {showInfoBubble &&
        activeStep &&
        on &&
        renderInfoBubble({
          ...defaultInfoBubbleProps,
          ...infoBubbleProps,
          classes: infoBubbleClasses
        })}
    </>
  );
  return Element;
};

export default Focus;
