import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import _ from "lodash";
import {
  DELETE_VEHICLE,
  DELETE_VEHICLE_MANY
} from "../../../../graphql/Mutations";
import { GET_VEHICLE_INFOS } from "../../../../graphql/Queries";
import AddLogs from "../../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  noButton: {
    borderRadius: 36,
    borderColor: "#f44336",
    color: "#f44336"
  },
  yesButton: {
    borderRadius: 100,
    color: "white"
  },
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },

  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export const DeleteManyConfirmation = props => {
  const classes = useStyles();
  const {
    confirmDeleteMany,
    setConfirmDeleteMany,
    // rowsPerPage,
    // currentPage,
    // getQueryConditions,
    selected,
    // handlePaginator,
    setSelected,
    selDel,
    updateTable
  } = props;
  const [deleteVehicleMany] = useMutation(DELETE_VEHICLE_MANY, {
    variables: {
      condition: [
        {
          field: "id",
          value: selected
        }
      ]
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    },
    onCompleted: e => {
      const { success } = e.delete_vehicle_all;

      if (success) {
        updateTable();
        setSelected([]);
        AddLogs("Admin - Vehicles/CV", "delete", selDel);
        Swal.fire({
          icon: "success",
          text: "Deleted",
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed",
          timer: 1500,
          showConfirmButton: false
        });
      }
    }
    // update: cache => {
    //   const c = cache.readQuery({
    //     query: GET_VEHICLE_INFOS,
    //     variables: getQueryConditions()
    //   });

    //   const newList = _.filter(c.get_vehicles.vehicles, v => {
    //     return !selected.includes(v.id);
    //   });

    //   const newData = {
    //     ...c,
    //     get_vehicles: {
    //       ...c.get_vehicles,
    //       total: c.get_vehicles.total - selected.length,
    //       count: c.get_vehicles.count - selected.length,
    //       vehicles: newList
    //     }
    //   };

    //   const total = Math.ceil(newData.get_vehicles.total / rowsPerPage);

    //   if (total > 0) {
    //     if (total < currentPage + 1) {
    //       handlePaginator(false);
    //     }
    //   }

    //   setSelected([]);
    // },
    // refetchQueries: () => {
    //   return [
    //     {
    //       query: GET_VEHICLE_INFOS,
    //       variables: {
    //         ...getQueryConditions()
    //       }
    //     }
    //   ];
    // }
  });
  return (
    <Dialog
      open={confirmDeleteMany}
      classes={{ paper: classes.closeContainer }}
    >
      <DialogTitle>
        <span className={classes.closeTitle}>Delete?</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className={classes.closeDescription}>
            Are you sure you want to delete these vehicles?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          // className={classes.noButton}
          className={classes.circularButtonNo}
          variant="outlined"
          onClick={() => setConfirmDeleteMany(false)}
        >
          NO
        </Button>
        <Button
          // className={classes.yesButton}
          className={classes.circularButtonYes}
          color="primary"
          variant="contained"
          onClick={async () => {
            await deleteVehicleMany();
            setConfirmDeleteMany(false);
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteConfirmation = props => {
  const {
    toggle,
    data,
    // getQueryConditions,
    // currentPage,
    // rowsPerPage,
    // handlePaginator,
    updateTable
  } = props;
  const classes = useStyles();

  const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
    variables: {
      id: data.data?.id
    },
    onCompleted: e => {
      const { success } = e.delete_vehicle;

      if (success) {
        updateTable();
        AddLogs("Admin - Vehicles/CV", "delete", data.data.plateno);
        Swal.fire({
          icon: "success",
          text: "Deleted",
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed",
          timer: 1500,
          showConfirmButton: false
        });
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong"
      });
    }
    // update: cache => {
    //   const c = cache.readQuery({
    //     query: GET_VEHICLE_INFOS,
    //     variables: getQueryConditions()
    //   });

    //   const newList = _.filter(c.get_vehicles.vehicles, v => {
    //     return v.id !== data.data;
    //   });

    //   const newData = {
    //     ...c,
    //     get_vehicles: {
    //       ...c.get_vehicles,
    //       total: c.get_vehicles.total - 1,
    //       count: c.get_vehicles.count - 1,
    //       vehicles: newList
    //     }
    //   };

    //   const total = Math.ceil(newData.get_vehicles.total / rowsPerPage);

    //   if (total > 0) {
    //     if (total < currentPage + 1) {
    //       handlePaginator(false);
    //     }
    //   }
    // },
    // refetchQueries: () => {
    //   return [
    //     {
    //       query: GET_VEHICLE_INFOS,
    //       variables: {
    //         ...getQueryConditions()
    //       }
    //     }
    //   ];
    // }
  });
  return (
    <Dialog
      open={data.open}
      onClose={() => toggle(false)}
      classes={{ paper: classes.closeContainer }}
    >
      <DialogTitle>
        <span className={classes.closeTitle}>Delete?</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className={classes.closeDescription}>
            Are you sure you want to delete this vehicle?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          // className={classes.noButton}
          className={classes.circularButtonNo}
          variant="outlined"
          onClick={() => toggle(false)}
        >
          NO
        </Button>
        <Button
          // className={classes.yesButton}
          className={classes.circularButtonYes}
          color="primary"
          variant="contained"
          onClick={async () => {
            await deleteVehicle();
            toggle(false);
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};
