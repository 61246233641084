/* eslint-disable no-useless-constructor */
/* global google */
import React, { useEffect, memo } from "react";
import { createPortal } from "react-dom";
import { WarningRounded } from "@material-ui/icons";
import { Box, Tooltip, Paper, SvgIcon, useTheme } from "@material-ui/core";
import _ from "lodash";

const AlertVehicleReference = props => {
  const { mapRef, criticalVehiclesIsShown } = props;
  const theme = useTheme();
  /**
   *** declare window to access google functions.
   *** controlPosition - getting the right_bottom position for the created portal position on the map.
   *** controlContainer - div element where the createPortal would be placed.
   */
  const { google } = window;
  const { RIGHT_BOTTOM } = google.maps.ControlPosition;
  const controlContainer = document.createElement("div");

  /*
   *** rendering the created portal.
   *** setting up the created portal position with the control position.
   */
  useEffect(() => {
    const map = mapRef.current;
    controlContainer.setAttribute(
      "style",
      `
        margin: 0px 10px;
        height: auto;
        width: auto;
    `
    );
    map.controls[RIGHT_BOTTOM].push(controlContainer);
  });

  return createPortal(
    <>
      <Tooltip
        title={
          criticalVehiclesIsShown
            ? "Click to show all vehicles"
            : "Click to filter vehicle with critical alert" 
        }
        placement="right"
      >
        <Paper
          elevation={0}
          style={{
            background: "rgba(255,255,255,0.9)",
            boxShadow: "0px 0px 18px -8px #484848d4",
            cursor: "pointer",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "40px",
              height: "40px",
              backgroundColor: criticalVehiclesIsShown? theme.palette.primary.main: "#E62E2D",
              color: "#FFFFFF",
              borderRadius: 5
            }}
          >
            <WarningRounded />
          </Box>
        </Paper>
      </Tooltip>
    </>,
    controlContainer
  );
};

export default memo(AlertVehicleReference);
