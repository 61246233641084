/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, Fragment } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  Popover,
  Badge
} from "@material-ui/core";
import {
  Brightness1,
  History as HistoryIcon,
  AssignmentLate
} from "@material-ui/icons";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { etaConverter, gpsStatus } from "../../../Utils";
import { useLazyQuery } from "@apollo/client";
import Cookie from "js-cookie";
import {
  GET_ADMIN_CLIENT_WO_GROUPS,
  GET_DEVICE_INFO
} from "../../../../graphql/Queries";
const STATUS_SCHEDULED_ID = Number(process.env.REACT_APP_STATUS_SCHEDULED_ID);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    minWidth: "300px",
    padding: 12,
    height: "calc(100vh - 170px)",
    margin: 10,
    borderRadius: 12,
    boxShadow: "none",
    overflowY: "auto"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  customBadgeAutomatic: {
    backgroundColor: "#bb6bd9",
    color: "white"
  },
  customBadgeManual: {
    backgroundColor: "#4e9fc0",
    color: "white"
  },
  customBadgeNotYetDispatch: {
    backgroundColor: "#b7b7b7",
    color: "white"
  },
  customBadgeUndefine: {
    backgroundColor: "#cab608",
    color: "white"
  },
  etaContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  badgeLabel: {
    fontSize: "8px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: "0.20rem",
    borderRadius: "5px"
  },
  label: {
    width: "max-content"
  },
  titleText: {
    fontSize: 15,
    fontWeight: "700",
    fontFamily: "Nunito",
    color: theme.palette.primary.dark,
    textTransform: "uppercase"
  },
  descriptionText: {
    fontSize: 13,
    fontFamily: "Nunito",
    color: theme.palette.primary.dark
  },
  tripStatus: {
    color: "#c4c4c4",
    fontSize: 13,
    fontFamily: "Nunito"
  },
  labelTrips: {
    fontWeight: "700",
    fontSize: 13,
    fontFamily: "Nunito",
    color: theme.palette.primary.dark
  },
  descriptionTrips: {
    fontSize: 13,
    fontFamily: "Nunito",
    color: theme.palette.primary.dark,
    marginBottom: 7
  }
}));

const ListTripDetails = props => {
  const theme = useTheme();
  const { primary, error } = theme.palette;
  const classes = useStyles();
  const user =
    Cookie.get("user") === undefined ? {} : JSON.parse(Cookie.get("user"));
  const { parsedTripData, deviceInfo={} } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [vehicleDetailsData, setVehicleDetails] = useState({
    vehicleType: null,
    plateNumber: null,
    driver: null,
    // status: "Inactive",
    vehicle_history: []
  });
  const tripOverdue = (pickups, dropoffs, status) => {
    const stops = [...pickups, ...dropoffs];
    const activeStop = _.filter(stops, stop => stop.status_code_id !== 4);
    const has_arrived =
      status.toLowerCase() === "arrived at pickup" ||
      status.toLowerCase() === "arrived at drop off";
    const duration =
      activeStop.length > 0
        ? moment.duration(moment().diff(moment(activeStop[0].arrival)))
        : null;
    return duration && !has_arrived ? duration.asHours() >= 24 : false;
  };
  const [tripDetailsData, setTripDetails] = useState({
    nextStop: "-",
    eta: null,
    isLate: false,
    time: null,
    modified: null,
    is_overdue: false,
    show_eta: false
  });

  const [dvar, setdvar] = useState([]);

  const [get_device_info] = useLazyQuery(GET_DEVICE_INFO, {
    onCompleted: data => {
      if (data) {
        setdvar(data.get_device_info);
      }
    },
    fetchPolicy: "network-only"
  });

  // const [get_devices] = useLazyQuery(GET_DEVICES, {
  //   onCompleted: data => {
  //     if (data) {
  //       setdvar(data.get_devices.devices);
  //     }
  //   },
  //   variables: {
  //     first: 1
  //   },
  //   fetchPolicy: "network-only"
  // });

  const [get_client, { data: clientData }] = useLazyQuery(
    GET_ADMIN_CLIENT_WO_GROUPS,
    {
      fetchPolicy: "network-only"
    }
  );

  const parseTripData = () => {
    if (!_.isEmpty(parsedTripData)) {
      const tData = { ...parsedTripData };

      const {
        dropoffs,
        trip_number,
        trip_status,
        service_type,
        pickups,
        vehicle_plate_no,
        // vehicle_status,
        vehicle_type,
        driver,
        hauler,
        vehicle_history,
        out_of_route,
        dispatch_type,
        client_id,
        tripNumber: splitTripNumber,
        vehicle_id
      } = tData;

      // get_client({
      //   variables: { id: client_id }
      // });

      const is_moving =
        trip_status.toLowerCase() === "in transit" ||
        trip_status.toLowerCase() === "incoming";
      let activeIndex = null;

      for (let index = 0; index < dropoffs.length; index += 1) {
        if (
          dropoffs[index].actual_eta &&
          dropoffs[index].status_code_id === 2
        ) {
          activeIndex = index;
          break;
        }
      }

      let activePu = null;

      for (let index = 0; index < pickups.length; index += 1) {
        // if (pickups[index].actual_eta && pickups[index].status_code_id === 2) {
        if (pickups[index].status_code_id === STATUS_SCHEDULED_ID) {
          activePu = index;
          break;
        }
      }
      let tripDetails = {
        ...tripDetailsData,
        status: trip_status,
        trip_number: trip_number || splitTripNumber,
        service_type: service_type,
        hauler: hauler,
        pickup_length: _.uniqBy(pickups, "geofence_id").length,
        dropoff_length: _.uniqBy(dropoffs, "geofence_id").length,
        is_overdue: tripOverdue(pickups, dropoffs, trip_status),
        show_eta: is_moving,
        dispatch_type: dispatch_type
      };

      if (activeIndex !== null && is_moving) {
        const time = etaConverter(dropoffs[activeIndex].actual_eta);
        const modifiedTime = moment(dropoffs[activeIndex].eta_modified).format(
          "lll"
        );
        const eta = `${moment(dropoffs[activeIndex].eta_modified)
          .add(dropoffs[activeIndex].actual_eta, "seconds")
          .format("lll")}`;
        const plan_arrival = moment(dropoffs[activeIndex].arrival).format(
          "lll"
        );
        const isLate = moment(plan_arrival).isBefore(eta);

        tripDetails = {
          ...tripDetails,
          nextStop: dropoffs[activeIndex].geofence_code
            ? `${dropoffs[activeIndex].geofence_code}-${dropoffs[activeIndex].name}`
            : dropoffs[activeIndex].name,
          modified: `As of ${modifiedTime}`,
          eta: eta,
          isLate: isLate,
          time: time,
          show_eta: moment().isBefore(eta) && !out_of_route
        };
      } else if (activePu !== null && is_moving) {
        const time = etaConverter(pickups[activePu].actual_eta);
        const modifiedTime = moment(pickups[activePu].eta_modified).format(
          "lll"
        );
        const eta = `${moment(pickups[activePu].eta_modified)
          .add(pickups[activePu].actual_eta, "seconds")
          .format("lll")}`;
        const plan_arrival = moment(pickups[activePu].arrival).format("lll");
        const isLate = moment(plan_arrival).isBefore(eta);

        tripDetails = {
          ...tripDetails,
          nextStop: pickups[activePu].geofence_code
            ? `${pickups[activePu].geofence_code}-${pickups[activePu].name}`
            : pickups[activePu].name,
          modified: `As of ${modifiedTime}`,
          eta: eta,
          isLate: isLate,
          time: time,
          show_eta: moment().isBefore(eta) && !out_of_route
        };
      }

      // get_devices({
      //   variables: {
      //     first: 50,
      //     condition: [
      //       { field: "vehicle_info.plateno", value: vehicle_plate_no }
      //     ]
      //   }
      // });

      if (!_.isEmpty(user)) {
        get_device_info({
          variables: {
            id: vehicle_id
          }
        });
      } else if (!_.isEmpty(deviceInfo)) {
        setdvar(deviceInfo);
      }

      setVehicleDetails({
        vehicleType: vehicle_type,
        plateNumber: vehicle_plate_no,
        driver: driver || "-",
        // status: vehicle_status,
        vehicle_history: vehicle_history || []
      });
      setTripDetails(tripDetails);
    }
  };

  const handleHistory = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    parseTripData();
    get_client({variables: {
      id: parsedTripData.client_id
    }})
    return () => {
      setTripDetails({});
      setVehicleDetails({});
    };
  }, [parsedTripData]);

  const isShareTrip = window.location.pathname?.includes("view_trip");

  return (
    <Paper className={classes.paper}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Typography className={classes.titleText}>Trip Details</Typography>
          <Tooltip
            title={
              tripDetailsData.dispatch_type &&
              tripDetailsData.dispatch_type.toLowerCase() !==
                "not yet dispatched"
                ? `${tripDetailsData.dispatch_type} Dispatched`
                : `Undefined`
            }
          >
            <Badge
              classes={{
                badge: tripDetailsData.dispatch_type
                  ? (tripDetailsData.dispatch_type.toLowerCase() === "manual" &&
                      classes.customBadgeManual) ||
                    (tripDetailsData.dispatch_type.toLowerCase() ===
                      "automatic" &&
                      classes.customBadgeAutomatic) ||
                    (tripDetailsData.dispatch_type.toLowerCase() ===
                      "not yet" &&
                      classes.customBadgeNotYetDispatch) ||
                    classes.customBadgeUndefine
                  : classes.customBadgeUndefine
              }}
              variant="dot"
              style={{ fontSize: 30 }}
            ></Badge>
          </Tooltip>
          {tripDetailsData.is_overdue && (
            <Tooltip
              key="overdue-key-2"
              title="It seems this trip is overdue. Would you like to close it?"
            >
              <AssignmentLate style={{ fontSize: 12, color: "#EA4335" }} />
            </Tooltip>
          )}
        </div>
        <Typography className={classes.tripStatus}>
          {tripDetailsData.status}
        </Typography>
      </div>
      <Typography className={classes.labelTrips}>Trip Number</Typography>
      <Typography className={classes.descriptionTrips}>
        {tripDetailsData.trip_number}
      </Typography>
      <Typography className={classes.labelTrips}>Service Type</Typography>
      <Typography className={classes.descriptionTrips}>
        {tripDetailsData.service_type || "-"}
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography className={classes.labelTrips}>No. of Pickup</Typography>
          <Typography className={classes.descriptionTrips}>
            {tripDetailsData.pickup_length}
          </Typography>
        </div>
        <div>
          <Typography className={classes.labelTrips}>
            No. of Drop Off
          </Typography>
          <Typography className={classes.descriptionTrips}>
            {tripDetailsData.dropoff_length}
          </Typography>
        </div>
      </div>
      <Typography className={classes.labelTrips}>Next stop:</Typography>
      <Typography className={classes.descriptionTrips}>
        {tripDetailsData.nextStop}
      </Typography>
      <div className={classes.etaContainer}>
        <Typography className={classes.labelTrips}>
          Estimated Time of Arrival (ETA):
        </Typography>
        <Typography size="small" className={classes.badgeLabel}>
          ETA
        </Typography>
      </div>
      {tripDetailsData.show_eta && clientData?.get_client?.eta_enabled > 0 ? (
        <Fragment>
          <span
            style={{
              display: "block",
              color: tripDetailsData.isLate ? error.main : primary.main
            }}
          >
            {tripDetailsData.eta &&
              tripDetailsData.eta.toLowerCase() !== "invalid date" &&
              `${tripDetailsData.eta}`}
          </span>
          <span>
            {tripDetailsData.modified && `${tripDetailsData.modified}`}
          </span>
        </Fragment>
      ) : (
        "-"
      )}
      {isShareTrip && (
        <Fragment>
          <Typography className={classes.labelTrips}>Current Location:</Typography>
          <Typography className={classes.descriptionTrips}>
            {dvar?.device_status?.lastlocation}
          </Typography>
        </Fragment>
      )}
      <Typography className={classes.titleText} style={{ margin: "10px 0" }}>
        VEHICLE DETAILS
      </Typography>
      <Typography className={classes.labelTrips}>Vehicle type:</Typography>
      <Typography className={classes.descriptionTrips}>
        {vehicleDetailsData.vehicleType || "-"}
      </Typography>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <Typography className={classes.labelTrips}>Plate/CV Number</Typography>
        <HistoryIcon
          style={{ fontSize: "0.9rem", cursor: "pointer" }}
          onClick={handleHistory}
          className={classes.labelTrips}
        />
      </div>
      <Typography className={classes.descriptionTrips}>
        {!_.isEmpty(dvar) && (
          <Tooltip
            title={
              <Fragment>
                <p style={{ margin: 0, padding: 0 }}>
                  {
                    gpsStatus(dvar?.device_status?.status_code?.toLowerCase())
                      .label
                  }
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  Last Report:{" "}
                  {dvar?.device_status?.reportstamp
                    ? moment(dvar?.device_status?.reportstamp).format("lll")
                    : "-"}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  GPS Provider: {dvar?.device_config?.gps_provider || "-"}
                </p>
              </Fragment>
            }
          >
            <Brightness1
              style={{
                fontSize: 10,
                color: gpsStatus(dvar?.device_status?.status_code?.toLowerCase())
                  .color,
                marginRight: 5,
              }}
            />
          </Tooltip>
        )}
        {vehicleDetailsData.plateNumber || "-"}
      </Typography>
      <Typography className={classes.labelTrips}>Driver</Typography>
      <Typography className={classes.descriptionTrips}>
        {vehicleDetailsData.driver}
      </Typography>
      <Typography className={classes.labelTrips}>Hauler</Typography>
      <Typography className={classes.descriptionTrips}>
        {tripDetailsData.hauler}
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <List style={{ maxWidth: 300 }}>
          {_.orderBy(
            vehicleDetailsData.vehicle_history,
            ["datestamp"],
            ["desc"]
          ).map(history => (
            <ListItem key={history.vehicle_id}>
              <ListItemText
                id="history"
                primary={
                  <Typography className={classes.labelTrips}>
                    {history.vehicle_plate_no ? history.vehicle_plate_no : "No Vehicle Assigned"}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography component="span" className={classes.descriptionTrips}>
                      Assigned by: {history.user_full_name}
                    </Typography>
                    <br />
                    <Typography component="span" className={classes.descriptionTrips}>
                      Changed on: {moment(history.datestamp).format("lll")}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>
      {/* <List>
        <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary={
            }
            primary={
              !tripDetailsData.is_overdue ? (
                <Typography>TRIP DETAILS</Typography>
              ) : (
                <Typography>
                  TRIP DETAILS{" "}
                  <Tooltip title="It seems this trip is overdue. Would you like to close it?">
                    <AssignmentLate
                      style={{ fontSize: 12, color: "#EA4335" }}
                    />
                  </Tooltip>
                </Typography>
              )
            }
            secondary={}
          /> */}
      {/* <ListItemSecondaryAction>
            <ListItemText
              id="switch-list-label-wifi"
              primary={tripDetailsData.status}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Trip Number:"
            secondary={tripDetailsData.trip_number}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Service Type:"
            secondary={tripDetailsData.service_type || "-"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary="No. of Pickup:"
            secondary={tripDetailsData.pickup_length}
          />
          <ListItemSecondaryAction>
            <ListItemText
              id="switch-list-label-wifi"
              primary="No. of Drop off:"
              secondary={tripDetailsData.dropoff_length}
            />
          </ListItemSecondaryAction> */}
      {/* </ListItem> */}
      {/* <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Next stop:"
            secondary={tripDetailsData.nextStop}
          />
        </ListItem> */}
      {/* <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary={
              <div className={classes.etaContainer}>
                <Typography className={classes.label}>
                  Estimated Time of Arrival (ETA):
                </Typography>
                <Typography size="small" className={classes.badgeLabel}>
                  ETA
                </Typography>
              </div>
            }
            secondary={
              tripDetailsData.show_eta &&
              clientData?.get_client?.eta_enabled > 0 ? (
                <Fragment>
                  <span
                    style={{
                      display: "block",
                      color: tripDetailsData.isLate ? error.main : primary.main
                    }}
                  >
                    {tripDetailsData.eta &&
                      tripDetailsData.eta.toLowerCase() !== "invalid date" &&
                      `${tripDetailsData.eta}`}
                  </span>
                  <span>
                    {tripDetailsData.modified && `${tripDetailsData.modified}`}
                  </span>
                </Fragment>
              ) : (
                "-"
              )
            }
          />
        </ListItem> */}
      {/* {listTripDetails()} */}
      {/* <ListItem style={{ marginTop: "10px" }}>
          <ListItemText id="switch-list-label-wifi" primary="VEHICLE DETAILS" />
        </ListItem>
        <ListItem style={{ marginTop: "10px" }}>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Vehicle type:"
            secondary={vehicleDetailsData.vehicleType}
          />
        </ListItem>
        <ListItem style={{ marginTop: "10px" }}>
          <ListItemText
            id="switch-list-label-wifi"
            primary={
              <div style={{ display: "flex" }}>
                <Typography>Plate Number:</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <HistoryIcon
                    style={{ fontSize: "1rem", cursor: "pointer" }}
                    onClick={handleHistory}
                  />
                </div>
              </div>
            }
            secondary={
              vehicleDetailsData.plateNumber && (
                <Fragment>
                  <span>
                    <Tooltip
                      title={
                        <Fragment>
                          <p style={{ margin: 0, padding: 0 }}>
                            {
                              gpsStatus(
                                dvar?.device_status?.status_code?.toLowerCase()
                              ).label
                            }
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            Last Report:{" "}
                            {dvar?.device_status?.reportstamp
                              ? moment(dvar?.device_status?.reportstamp).format(
                                  "lll"
                                )
                              : "-"}
                          </p>
                          <p style={{ margin: 0, padding: 0 }}>
                            GPS Provider:{" "}
                            {dvar?.device_config?.gps_provider || "-"}
                          </p>
                        </Fragment>
                      }
                    >
                      <Brightness1
                        style={{
                          fontSize: 10,
                          color: gpsStatus(
                            dvar?.device_status?.status_code?.toLowerCase()
                          ).color
                        }}
                      />
                    </Tooltip>{" "}
                    {vehicleDetailsData.plateNumber}
                  </span>
                </Fragment>
              )
            }
          />
        </ListItem>
        <ListItem style={{ marginTop: "10px" }}>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Driver:"
            secondary={vehicleDetailsData.driver}
          />
        </ListItem>
        <ListItem style={{ marginTop: "10px" }}>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Hauler:"
            secondary={tripDetailsData.hauler}
          />
        </ListItem> */}
      {/* </List> */}
    </Paper>
  );
};

export default ListTripDetails;
