import React from "react";
import MonitoringDemo from "../../utils/Onboarding/modules/Fleet";
import Monitoring from "./index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../utils/Onboarding";
import useUserContext from "../../context/User/useUserContext";
import Introductory from "../../utils/Onboarding/pages/Introductory";

/**
 * Task 📝
 *   1️⃣ use UserContext to detemine if the user should see Demo or Actual monitoring.
 */

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.monitoring,
    allowContextToChange: true
  });
  const monitoringProgress = progress[Onboarding.modules.monitoring];
  const isDemoCompleted =
    monitoringProgress === onboarding_max[Onboarding.modules.monitoring];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Monitoring /> : <MonitoringDemo />;
};

export default Main;
