/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  Link,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import _ from "lodash";
// import { onError } from "apollo-link-error";
import Header from "../../Header";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
// import { LayoutContext } from "../../../Layout";
import SearchContext from "../../../../context/SearchContext";
import {
  GET_USER,
  GET_USER_LEVELS,
  CHECK_PASSWORD,
  GET_ACCESS_OBJECTS,
  GET_GEOFENCE_QUERY
} from "../../../../graphql/Queries";
import {
  EDIT_USER,
  FORGOT_PASSWORD_MUTATION
} from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../utils/validation";
import SearchGeofence from "./SearchGeofence";
import redirectToTable from "../../../../utils/redirect";
import AddLogs from "../../../../utils/functions/AddLogs";
import debounce from "../../../../utils/functions/debounce.util";
import updateCookie from "../../../../utils/functions/updateCookie";

const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  multiline: {
    margin: "12px 0"
  },
  column: {
    fontSize: "1.5rem",
    margin: "16px 0"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto 50px 0",
    width: "200px"
  },
  dialog: {
    width: "250px"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  dialogDisc: {
    borderRadius: "10px"
  },
  dialogRoot: {
    width: "500px"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const getMaxLength = (n = 0) => {
  if (/^\+639/.test(n)) return 13;
  if (/^639/.test(n)) return 12;
  if (/^9/.test(n)) return 10;
  return 11;
};

export default function User(props) {
  const classes = useStyles();
  // const context = useContext(LayoutContext);
  const contextData = useContext(SearchContext);
  const user = JSON.parse(Cookie.get("user"));
  const [submit, setSubmit] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [dialog, setDialog] = useState({
    resetPassword: false,
    discard: false,
    save: false
  });
  const [initialData, setInitialData] = useState({});
  const [state, setState] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email_address: "",
    contact_number: "",
    access_rights: "",
    password_expiry: 0,
    account_expiration: moment().format("MM-DD-YYYY"),
    remarks: "",
    password: "",
    user_level_id: "",
    is_auto_logout: null
  });

  const [geofenceID, setGeofenceID] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [defaultAccessRight, setDefaultAccessRight] = useState([]);
  const [userlevel, setUserlevel] = useState({});
  const [initialUserLevel, setInitialUserLevel] = useState({});
  const [errors, setErrors] = useState({
    email_address: "",
    contact_number: "",
    user_level_id: "",
    account_expiration: ""
  });
  const [resetError, setResetError] = useState(null);
  const {
    username,
    first_name,
    last_name,
    email_address,
    contact_number,
    password_expiry,
    account_expiration,
    remarks,
    password,
    is_auto_logout
  } = state;
  const history = useHistory();
  const [rerender, setRerender] = useState(false);
  const [dropdownLevel, setDropdownLevel] = useState([]);

  const [storeGeofence, setStoreGeofence] = useState(false);
  // const [submit, setSubmit] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const { id } = useParams();
  const { location } = props;
  const { pathname } = location;
  // graphql
  const { data: data1, loading: loading1 } = useQuery(GET_USER, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onCompleted: data => {
      setDefaultAccessRight({
        id: data.get_user.user_level_id,
        name: data.get_user.user_level_name
      });
      setUserlevel({
        id: data.get_user.user_level_id,
        name: data.get_user.user_level_name
      });
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        });
      }
    }
  });
  const [userPrimaryGroup, setUserPrimaryGroup] = useState([]);

  // const { data: data2, loading: loading2 } = useQuery(GET_USER_LEVELS, {
  //   variables: {
  //     group_ids: userPrimaryGroup
  //   },
  //   fetchPolicy: "network-only"
  // });

  const [getUserLevels, { data: data2, loading: loading2 }] = useLazyQuery(
    GET_USER_LEVELS
  );

  const { data: data3, loading: loading3, refetch } = useQuery(CHECK_PASSWORD, {
    variables: { username: "", password: "" }
  });

  const { data: accessObject, refetch: refetchAccessObject } = useQuery(
    GET_ACCESS_OBJECTS,
    {
      // fetchPolicy: "network-only",
      variables: {
        user_level_id: ""
      },
      skip: true
    }
  );

  const { data: geofenceStoreApp, refetch: refetchGeofence } = useQuery(
    GET_GEOFENCE_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        filter: []
      }
    }
  );

  const [editUser] = useMutation(EDIT_USER, {
    onCompleted(data) {
      if (data) {
        if (data.edit_user.success) {
          AddLogs("Admin - Users", "update", state.username);
          if (user.id === id) {
            user.is_auto_logout = state.is_auto_logout;
            // Cookie.set("user", user);
            updateCookie("user",user);
          }

          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              // set values again for users table filter
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_USERS_MODULE,
                    vars: history?.location?.state?.params?.vars,
                    filter: history?.location?.state?.params?.filter
                  }
                }
              });
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              // set values again for users table filter
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_USERS_MODULE,
                    vars: history?.location?.state?.params?.vars,
                    filter: history?.location?.state?.params?.filter
                  }
                }
              });
            }
          });
        } else {
          const temp = [];
          data.edit_user.error.map(element => {
            temp.push({
              [element.field]: element.message
            });
            if (element.message === "Username already in use") {
              temp.push({
                username: "Username already exists"
              });
            }
            if (element.message === "Email address already in use") {
              temp.push({
                email_address: "Email address already exists"
              });
            }
            if (element.message === "Contact number already in use") {
              temp.push({
                contact_number: "Contact number already exists"
              });
            }
            return temp;
          });
          setErrors(Object.assign(errors, ...temp));
          setForceRerender(!forceRerender);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_USERS_MODULE);
          }
        });
      }
    }
  });

  // validation
  const checkIfStoreApp = () => {
    if (!_.isEmpty(accessObject)) {
      const isExist = accessObject.get_access_objects.filter(obj => {
        return obj.name.toLowerCase() === "store app";
      });
      if (isExist.length > 0) {
        return Object.keys(isExist[0].permission).some(k => {
          return isExist[0].permission[k] === true;
        });
      }
    }
    return false;
  };

  const checkChanges = () => {
    return Object.keys(data1.get_user)
      .filter(element => element in state)
      .some(element => {
        if (element === "account_expiration") {
          return (
            moment(state[element]).format("YYYY-MM-DD") !==
            moment(data1.get_user[element]).format("YYYY-MM-DD")
          );
        }
        return state[element] !== data1.get_user[element];
      });
  };

  const clearErrors = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: "" }));
    setErrors(Object.assign(errors, ...temp));
  };

  const check = field => {
    if (!errors[field])
      if (field === "user_level_id") {
        return !userlevel || Object.keys(userlevel).length < 0
          ? "This field is required"
          : "";
      } else if (field === "contact_number") {
        return errors[field];
      } else {
        return state[field] === "" ? "This field is required" : "";
      }

    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setErrors(Object.assign(errors, ...temp));
    const isGeofence = checkIfStoreApp();
    if (isGeofence) {
      if (!geofenceID) {
        const temp = errors;
        temp.geofence_id = "This field is required";
        setErrors(temp);
      }
    }
    const flag = keys
      .filter(element => element in state)
      .every(
        element => errors[element] === "" || errors[element] === undefined
      );
    const isStoreApp = isGeofence ? geofenceID : true;
    return flag && isStoreApp;
  };

  const handleChange = event => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    let error;
    switch (name) {
      // case "contact_number":
      //   error = !validate("mobile_number_ph", value)
      //     ? ""
      //     : "Invalid Mobile Number";
      //   break;
      //   case "email_address":
      //     error = validate("email", value) ? "" : "Invalid Email Address";
      //     break;
      //   case "username":
      //     error = validate("alphanumeric", value)
      //       ? ""
      //       : `The following characters are not allowed: " ' "`;
      //     break;
      //   case "first_name":
      //     error = validate("alphanumeric", value)
      //       ? ""
      //       : "Alphanumeric characters only";
      //     break;
      //   case "last_name":
      //     error = validate("alphanumeric", value)
      //       ? ""
      //       : "Alphanumeric characters only";
      //     break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleBlur = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let error;

    switch (name) {
      case "contact_number":
        if (value === "") {
          error = "";
        } else if (!validate("mobile_number_ph", value)) {
          error = "Invalid Mobile Number";
        }
        break;
      case "email_address":
        if (value === "") error = "This field is required";
        else if (!validate("email", value)) error = "Invalid Email Address";
        break;
      case "username":
        error = validate("alphanumeric", value)
          ? ""
          : `The following characters are not allowed: " ' "`;
        break;
      case "first_name":
        error = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "last_name":
        error = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "account_expiration":
        if (value === "") {
          error = "This field is required";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
  };

  const handleDate = date => {
    setState({
      ...state,
      account_expiration: date
    });
    setErrors({ ...errors, account_expiration: "" });
  };

  const handleSubmit = event => {
    event.preventDefault();
    clearErrors();
    const objParams = {
      id: id,
      username: state.username,
      first_name: state.first_name,
      last_name: state.last_name,
      email_address: state.email_address,
      contact_number: `${state.contact_number}`,
      user_level_id: userlevel.id,
      password_expiry: state.password_expiry,
      remarks: state.remarks,
      account_expiration: state.account_expiration,
      is_auto_logout: state.is_auto_logout
    };

    if (checkIfStoreApp()) {
      objParams.geofence_id = geofenceID;
    }
    editUser({
      variables: objParams
    });
  };

  const handleOpen = dialog_name => {
    if (dialog_name === "save") {
      if (validateFields()) setDialog({ ...dialog, [dialog_name]: true });
      else setForceRerender(!forceRerender);
    } else {
      setDialog({ ...dialog, [dialog_name]: true });
    }
  };

  const handleUserlevels = (event, values) => {
    setErrors({ ...errors, user_level_id: "" });
    setUserlevel(values);
    refetchAccessObject({ user_level_id: !_.isEmpty(values) ? values.id : "" });
  };

  const handleClose = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleResetPassword = () => {
    refetch({ username: user.username, password: password }).then(res => {
      if (res.data.check_password) handleClose("resetPassword");
      if (!submit) {
        setSubmit(true);
      } else {
        setRerender(!rerender);
      }
    });
  };

  useEffect(() => {
    if (!loading3) {
      if (data3) {
        if (data3.check_password) {
          forgotPassword({ variables: { email: email_address } });
          handleClose("resetPassword");
        }
      }
    }
  }, [data3]);

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges() || !_.isMatch(initialUserLevel, userlevel)) {
        handleOpen("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const get_geofence = async () => {
    if (data1) {
      await refetchGeofence({
        filter: [
          {
            field: "name",
            value: data1.get_user.group_names.filter(x => {
              return x !== null;
            })
          }
        ]
      });
      setForceRerender(!forceRerender);
    }
  };

  const initialState = () => {
    const temp = [];
    Object.keys(data1.get_user)
      .filter(element => element in state)
      .forEach(element => {
        if (element in state) {
          if (element === "account_expiration") {
            temp.push({
              [element]: moment(data1.get_user[element]).format("MM/DD/YYYY")
            });
          } else {
            temp.push({ [element]: data1.get_user[element] });
          }
        }
      });
    setState(Object.assign(state, ...temp));
    setInitialData(Object.assign(state, ...temp));
    if (data2) {
      const user_level_arr = data2.get_user_levels.userlevels || [];
      setUserlevel(
        user_level_arr.filter(
          userl => userl?.id === data1.get_user.user_level_id
        )[0]
      );
      setInitialUserLevel(
        user_level_arr.filter(
          userl => userl?.id === data1.get_user.user_level_id
        )[0]
      );
    }
    refetchAccessObject({ user_level_id: state.user_level_id });
    if (data1) {
      refetchGeofence({
        filter: [
          {
            field: "name",
            value: data1.get_user.group_names.filter(x => {
              return x !== null;
            })
          }
        ]
      });
      if (
        !_.isEmpty(geofenceStoreApp) &&
        geofenceStoreApp.get_geofences &&
        geofenceStoreApp.get_geofences.geofences.length
      ) {
        setStoreGeofence(geofenceStoreApp.get_geofences.geofences[0]);
        setGeofenceID(geofenceStoreApp.get_geofences.geofences[0].id);
      }
      // for groups filter of user levels
      const { client, group_names, group_ids } = data1.get_user;
      if (group_ids.length > 1 && client) {
        const index = group_names.indexOf(client.name);
        if (index >= 0) setUserPrimaryGroup([group_ids[index]]);
      } else {
        setUserPrimaryGroup(group_ids);
      }
    }
    refetchAccessObject({ user_level_id: state.user_level_id });
    setRerender(!rerender);
  };

  const discarded = () => {
    initialState();
    get_geofence();
    setEditMode(false);
    handleClose("discard");
    setUserlevel(defaultAccessRight);
    // Swal.fire({
    //   title: "Discarded",
    //   icon: "success",
    //   showConfirmButton: false,
    //   timer: 3000
    // });
  };

  // lifecycle
  useEffect(() => {
    if (data2) {
      if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
        // if (userlevel && userlevel.name) {
        setDropdownLevel(data2.get_user_levels.userlevels);
        // }
      } else if (
        userlevel &&
        userlevel.name &&
        userlevel.name.toLowerCase() !== "data admin"
      ) {
        const temp = data2.get_user_levels.userlevels.filter(
          el => el.name.toLowerCase() !== "data admin"
        );
        setDropdownLevel(temp);
      }
    }
  }, [data2]);

  useEffect(() => {
    if (data1) {
      initialState();
      get_geofence();
    }
  }, [data1]);
  // useEffect(() => {
  //   context.setModuleName(process.env.REACT_APP_USERS_MODULE);
  // }, []);

  const { setSearchKeyword, searchKeyword } = contextData;
  if (!storeGeofence && !_.isEmpty(geofenceStoreApp)) {
    if (_.isEmpty(geofenceStoreApp.get_geofences.geofences[0])) {
      setStoreGeofence({ name: "Search Geofence" });
    } else {
      setStoreGeofence(geofenceStoreApp.get_geofences.geofences[0]);
      setGeofenceID(geofenceStoreApp.get_geofences.geofences[0].id);
    }
  }

  const [forgotPassword] = useMutation(FORGOT_PASSWORD_MUTATION, {
    onCompleted(data) {
      if (data) {
        if (data.forgot_password.success) {
          Swal.fire({
            title: "Reset Password",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              // set values again for users table filter
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_USERS_MODULE,
                    vars: history?.location?.state?.params?.vars
                  }
                }
              });
            }
          });
        } else {
          Swal.fire({
            title: "Failed",
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: error } = JSON.parse(JSON.stringify(ApolloError));
      const returnData = error.map(el => {
        return el.message;
      });
      setResetError(returnData[0]);
      handleOpen("resetPassword");
    }
  });

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: process.env.REACT_APP_USERS_MODULE
    });
  }, []);

  const checkRights = editMode => {
    let temp = true;
    if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
      if (!editMode) {
        temp = true;
      } else {
        temp = false;
      }
    }
    return temp;
  };

  const userlevelTextFieldRef = React.useRef();
  const userLevelChanged = () => {
    getUserLevels({
      variables: {
        first: 5,
        keyword: userlevelTextFieldRef.current.value,
        group_ids: userPrimaryGroup
      }
    });
  };

  const debouncedUserLevelChanged = React.useMemo(
    () => debounce(userLevelChanged, 250),
    []
  );

  if (loading1 || loading3) return <Loading />;

  if (!data1) return <ErrorPage />;

  return (
    <AccessControl
      resource={pathname}
      setEditPermission={setEditPermission}
      process="view"
    >
      <div className={classes.root}>
        <Header
          process={editMode ? "Edit" : "View"}
          // process={!_.isEqual(initialData, state) ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_USERS_MODULE}
          history={history?.location?.state?.params}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={_.isEqual(initialData, state)}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Typography>All fields with * are required</Typography>
            </Grid>
            <Grid
              container
              spacing={6}
              style={{ height: "calc(100% - 150px)" }}
            >
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.column}>
                  Personal Information
                </Typography>
                <TextField
                  required
                  name="username"
                  label="Username"
                  placeholder="Enter Username"
                  value={username}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  InputProps={{
                    disableUnderline: checkRights(editMode),
                    readOnly: checkRights(editMode),
                    style: {
                      color: checkRights(editMode) ? "#808080" : "black"
                    }
                  }}
                  className={classes.field}
                />
                {errors.username && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.username}
                  </Alert>
                )}
                <TextField
                  required
                  name="first_name"
                  label="First Name"
                  placeholder="Enter First Name"
                  value={first_name}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  InputProps={{
                    disableUnderline: checkRights(editMode),
                    readOnly: checkRights(editMode),
                    style: {
                      color: checkRights(editMode) ? "#808080" : "black"
                    }
                  }}
                  className={classes.field}
                />
                {errors.first_name && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.first_name}
                  </Alert>
                )}
                <TextField
                  required
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={last_name}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  InputProps={{
                    disableUnderline: checkRights(editMode),
                    readOnly: checkRights(editMode),
                    style: {
                      color: checkRights(editMode) ? "#808080" : "black"
                    }
                  }}
                  className={classes.field}
                />
                {errors.last_name && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.last_name}
                  </Alert>
                )}
                <TextField
                  required
                  name="email_address"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  value={email_address}
                  onChange={e => handleChange(e)}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  InputProps={{
                    disableUnderline: checkRights(editMode),
                    readOnly: checkRights(editMode),
                    style: {
                      color: checkRights(editMode) ? "#808080" : "black"
                    }
                  }}
                  className={classes.field}
                />
                {errors.email_address && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.email_address}
                  </Alert>
                )}
                {editMode ? (
                  <Typography>
                    <Link
                      href="#"
                      onClick={() => handleOpen("resetPassword")}
                      underline="always"
                      disabled
                    >
                      Reset Password
                    </Link>
                  </Typography>
                ) : null}
                <TextField
                  // required
                  name="contact_number"
                  label="Contact Number"
                  placeholder="+63----------"
                  value={contact_number}
                  onChange={e => handleChange(e)}
                  onBlur={e => {
                    e.persist();
                    const { value } = e.target;
                    const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                    setState(prev => ({
                      ...prev,
                      contact_number: beginWith63
                    }));
                    e.target.value = beginWith63;
                    handleBlur(e);
                  }}
                  InputLabelProps={{ shrink: true, style: { color: "black" } }}
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start">+(63)</InputAdornment>
                    // ),
                    disableUnderline: checkRights(editMode),
                    readOnly: checkRights(editMode),
                    // maxLength: 10,
                    style: {
                      color: checkRights(editMode) ? "#808080" : "black"
                    },
                    inputProps: {
                      maxLength: getMaxLength(state.contact_number)
                    }
                  }}
                  className={classes.field}
                />
                {errors.contact_number && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.contact_number}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.column}>
                  User Information
                </Typography>
                <FormControl className={classes.field}>
                  <InputLabel shrink required>
                    Access Rights
                  </InputLabel>
                  {/* {userlevel && ( */}
                  <Autocomplete
                    id="combo-box-demo"
                    getOptionSelected={(o, v) => {
                      if (v.name === o.name) return o.name;
                      return {};
                    }}
                    options={
                      user.user_level_id === process.env.REACT_APP_WTI_ADMIN &&
                      dropdownLevel
                        ? dropdownLevel
                        : dropdownLevel
                        ? dropdownLevel.filter(
                            x => x.name.toLowerCase() !== "wti admin"
                          )
                        : []
                    }
                    disabled={
                      // checkRights(editMode)
                      user.user_level_id === process.env.REACT_APP_WTI_ADMIN
                        ? false
                        : !!(
                            (userlevel &&
                              userlevel.name &&
                              userlevel?.name.toLowerCase() === "data admin") ||
                            (userlevel &&
                              userlevel.name &&
                              userlevel?.name.toLowerCase() === "wti admin")
                          )
                    }
                    disableClearable
                    getOptionLabel={option => option.name || ""}
                    onChange={handleUserlevels}
                    onBlur={handleBlur}
                    value={userlevel}
                    renderInput={params =>
                      editMode ? (
                        <TextField
                          {...params}
                          inputRef={userlevelTextFieldRef}
                          style={{ marginTop: 16 }}
                          placeholder="Select User Level"
                          value={userlevel}
                          fullWidth
                          onClick={() => {
                            getUserLevels({
                              variables: {
                                first: 5,
                                keyword: null,
                                group_ids: userPrimaryGroup
                              }
                            });
                          }}
                          onChange={debouncedUserLevelChanged}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading2 ? (
                                  <CircularProgress
                                    disableShrink
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      ) : (
                        <TextField
                          {...params}
                          style={{ marginTop: 16 }}
                          placeholder="Select User Level"
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true
                          }}
                        />
                      )
                    }
                  />
                  {/* )} */}
                </FormControl>
                {errors.user_level_id && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.user_level_id}
                  </Alert>
                )}
                <FormControl className={classes.field}>
                  <InputLabel shrink>Password Duration</InputLabel>
                  <Select
                    value={password_expiry}
                    onChange={e => handleChange(e)}
                    displayEmpty
                    name="password_expiry"
                    disableUnderline={!editMode}
                    disabled={!editMode}
                  >
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>1 month</MenuItem>
                    <MenuItem value={2}>2 months</MenuItem>
                    <MenuItem value={4}>4 months</MenuItem>
                    <MenuItem value={6}>6 months</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {editMode ? (
                    <KeyboardDatePicker
                      className={classes.field}
                      required
                      name="account_expiration"
                      openTo="date"
                      format="MM/dd/yyyy"
                      label="Access End Date"
                      views={["year", "month", "date"]}
                      value={account_expiration}
                      onChange={e =>
                        handleDate(moment(e).format("MMM DD YYYY"))
                      }
                      minDate={moment().format("MM/DD/YYYY")}
                      minDateMessage={false}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "black" }
                      }}
                      onBlur={handleBlur}
                    />
                  ) : (
                    <TextField
                      required
                      name="account_expiration"
                      label="Access End Date"
                      value={account_expiration}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: "black" }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true
                      }}
                      className={classes.field}
                    />
                  )}
                </MuiPickersUtilsProvider>
                {errors.account_expiration && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.account_expiration}
                  </Alert>
                )}
                <FormControl className={classes.field}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      <Tooltip
                        classes={{ tooltip: classes.toolTip }}
                        placement="top-start"
                        title={
                          <div
                          // style={{
                          //   width: 150,
                          //   whiteSpace: "normal",
                          //   textAlign: "left"
                          // }}
                          >
                            Turn on: Auto logout user after
                            <br />
                            15 minutes of inactivity.
                            <br />
                            Turn off: User will be stayed login
                          </div>
                        }
                      >
                        {/* <IconButton aria-label="helpbutton"> */}
                        <Help className={classes.helpIcon} />
                        {/* </IconButton> */}
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      Auto Logout
                    </Grid>
                    <Grid>
                      <Switch
                        checked={is_auto_logout}
                        name="is_auto_logout"
                        value="is_auto_logout"
                        color="primary"
                        disabled={!editMode}
                        onChange={e => handleChange(e)}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                {editMode ? (
                  !_.isEmpty(userlevel) ? (
                    checkIfStoreApp() ? (
                      <SearchGeofence
                        setGeofenceID={setGeofenceID}
                        errors={errors}
                        setErrors={setErrors}
                        type="edit"
                        data1={data1}
                        storeGeofence={storeGeofence}
                      />
                    ) : null
                  ) : null
                ) : null}
                {errors.geofence_id && (
                  <Alert severity="error" className={classes.alert}>
                    {errors.geofence_id}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Typography className={classes.column}>Remarks</Typography>
                <TextField
                  name="remarks"
                  placeholder="Type here..."
                  multiline
                  minRows="4"
                  value={remarks || ""}
                  onChange={e => handleChange(e)}
                  variant="outlined"
                  fullWidth
                  className={classes.multiline}
                  helperText={remarks ? `${remarks.length}/100` : `${0}/100`}
                  FormHelperTextProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    readOnly: !editMode,
                    maxLength: 100
                  }}
                />
              </Grid>
              {editMode && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleOpen("save")}
                  >
                    Save Details
                  </Button>
                </div>
              )}
            </Grid>
          </div>
        </Paper>
        <Dialog
          open={dialog.resetPassword}
          classes={{ paper: classes.dialogRoot }}
          onClose={() => handleClose("resetPassword")}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Input Your Password</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={e => handleChange(e)}
              InputLabelProps={{ shrink: true, style: { color: "black" } }}
              fullWidth
            />
            {!data3.check_password && submit && (
              <Alert severity="error" className={classes.alert}>
                Invalid password
              </Alert>
            )}
            {resetError && (
              <Alert severity="error" className={classes.alert}>
                {resetError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              style={{ borderRadius: 100, width: 100, color: "red" }}
              onClick={() => handleClose("resetPassword")}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              style={{ borderRadius: 100, width: 100, color: "white" }}
              onClick={handleResetPassword}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save user?"
        />
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleClose("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        {/* <Dialog
          open={dialog.discard}
          onClose={() => handleClose("discard")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Discard Changes</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to leave this page? Unsaved changes will be
              discarded.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose("discard")} color="primary">
              No
            </Button>
            <Button onClick={discarded} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialog.save}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Save?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to save the new user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose("save")} color="primary">
              No
            </Button>
            <Button
              onClick={e => {
                handleClose("save");
                handleSubmit(e);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog> */}
        {/* <Dialog
          open={discard}
          classes={{ paper: classes.dialogDisc }}
          onClose={() => setDiscard(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Discard Changes?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to leave this page? Unsaved changes will be
              discarded.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: 16 }}>
            <Button
              style={{ borderRadius: 100, width: 150 }}
              variant="outlined"
              onClick={() => setDiscard(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                setDiscard(false);
                Swal.fire({
                  title: "Discarded",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  onClose: () => {
                    setRedirect(true);
                  }
                });
              }}
              style={{ borderRadius: 100, color: "white", width: 150 }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </AccessControl>
  );
}
