/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import moment from "moment";
import Cookies from "js-cookie";
import _ from "lodash";
import client from "../../Client";
import { GET_BOOKINGS_TO_RESET } from "../../graphql/Queries";
import {
  EDIT_VEHICLE,
  ADD_NOTIFICATION,
  MANUAL_COMPLETE
} from "../../graphql/Mutations";
import AddLogs from "./AddLogs";

const ongoingTripStatuses = {
  forReset: [
    process.env.REACT_APP_INCOMING_STATUS,
    process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP
  ],
  forClose: [
    process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
    process.env.REACT_APP_STATUS_IN_TRANSIT
  ]
};

export const getBookingsOfVehicle = (device_id, plateno) => {
  const user = JSON.parse(Cookies.get("user"));
  return new Promise(resolve => {
    client
      .query({
        query: GET_BOOKINGS_TO_RESET,
        fetchPolicy: "network-only",
        variables: {
          conditions: [
            {
              field: "vehicle_id",
              value: device_id.toString()
            },
            {
              field: "vehicle_plate_no",
              value: plateno
            }
          ],
          not: [
            {
              field: "status_code_id",
              value: process.env.REACT_APP_STATUS_TRIP_CLOSED
            },
            {
              field: "status_code_id",
              value: process.env.REACT_APP_STATUS_CANCEL_ID
            },
            {
              field: "status_code_id",
              value: process.env.REACT_APP_STATUS_COMPLETED
            }
          ],
          first: 10000,
          orderBy: [{ field: "trip_number", direction: "asc" }]
        }
      })
      .then(result => {
        let trips = {};
        if (result?.data?.get_bookings_per_trip?.count) {
          const { bookings } = result.data.get_bookings_per_trip;
          trips = {
            futureTrips: {
              tripNumbers: [],
              forReset: []
            },
            ongoingTrips: {
              tripNumbers: [],
              forReset: [],
              forClose: []
            }
          };

          bookings.forEach(trip => {
            if (
              ongoingTripStatuses.forReset.includes(
                trip.value[0].status_code_id.toString()
              )
            ) {
              trips.ongoingTrips.forReset.push(trip);
              trips.ongoingTrips.tripNumbers.push(trip.key);
            } else if (
              ongoingTripStatuses.forClose.includes(
                trip.value[0].status_code_id.toString()
              )
            ) {
              trips.ongoingTrips.forClose.push(trip);
              trips.ongoingTrips.tripNumbers.push(trip.key);
            } else {
              trips.futureTrips.forReset.push(trip);
              trips.futureTrips.tripNumbers.push(trip.key);
            }
          });
        }

        resolve(trips);
      })
      .catch(error => {
        console.log(error);
        resolve({ error });
      });
  });
};

export const resetTrips = async trips => {
  const user = JSON.parse(Cookies.get("user"));
  if (trips?.length) {
    const forSaving = [];
    trips.forEach(trip => {
      trip.value.forEach(so => {
        forSaving.push({
          id: so.id,
          fields: {
            vehicle_id: "",
            vehicle_plate_no: "",
            vehicle_type: "",
            vehicle_history: [
              ...so.vehicle_history,
              {
                vehicle_id: "",
                vehicle_plate_no: "",
                user_id: user.id,
                user_full_name: `${user.first_name} ${user.last_name}`,
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss")
              }
            ],
            data_complete: false,
            // pre_dispatched_ready: false,
            status_code: "Accepted",
            status_code_id: process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
            dispatch_type: "Not Yet",
            dispatch_time: null,
            for_reprocess: true
          }
        });
      });
    });

    return new Promise(resolve => {
      client
        .mutate({
          mutation: MANUAL_COMPLETE,
          variables: {
            bookings: forSaving
          }
        })
        .then(result => {
          if (result.data.update_booking_many.every(res => res.success)) {
            // If all bookings is updated, add activity logs and notification per trip number
            trips.map(trip => {
              AddLogs(
                "Admin - Vehicles/CV",
                "change_truck",
                `${trip.key} from ${trip.value[0].vehicle_plate_no ||
                  `-`} to - `
              );

              client
                .mutate({
                  mutation: ADD_NOTIFICATION,
                  variables: {
                    notification: {
                      client_id: trip.value[0].client_id,
                      trip_number: trip.key,
                      vehicle_plate_no: trip.value[0].vehicle_plate_no,
                      datestamp: moment()
                        .format("YYYY-MM-DD HH:mm:ss"),
                      notification: `This trip ${trip.key} has been reset.`,
                      category: "Trip",
                      is_archived: false,
                      creator: "Tmsuite-web",
                      group_ids: trip.value[0].group_ids,
                      group_names: trip.value[0].group_names
                    }
                  }
                })
                .catch(err => console.log(err));
            });

            resolve({ success: true, error: null });
          } else {
            resolve({ success: false, error: "Something went wrong" });
          }
        })
        .catch(error => {
          console.log(error);
          resolve({ success: false, error });
        });
    });
  }
  return [
    {
      success: false,
      error: "No trip to reset",
      data: null
    }
  ];
};

export const unpairDeviceFromVehicle = ({ vehicle_id, name }) => {
  return new Promise(resolve => {
    client
      .mutate({
        mutation: EDIT_VEHICLE,
        variables: {
          id: vehicle_id,
          vehicle: {
            device_id: 0
          }
        }
      })
      .then(result => {
        if (result.data?.edit_vehicle?.success) {
          const {
            plateno,
            // client_id,
            // group_ids,
            // group_names
          } = result.data.edit_vehicle.vehicle;
          AddLogs("Admin - Vehicles/CV", "unpaired_device", [
            name,
            plateno
          ]);
          // client
          //   .mutate({
          //     mutation: ADD_NOTIFICATION,
          //     variables: {
          //       notification: {
          //         client_id: [client_id],
          //         vehicle_plate_no: plateno,
          //         datestamp: moment()
          //           .format("YYYY-MM-DD HH:mm:ss"),
          //         notification: `GPS Device ${name} was unpaired to Vehicle/CV ${plateno}`,
          //         category: "User Activity",
          //         is_archived: false,
          //         creator: "Tmsuite-web",
          //         group_ids: group_ids,
          //         group_names: group_names
          //       }
          //     }
          //   })
          //   .catch(err => console.log(err));
          resolve({ success: true });
        } else {
          resolve({
            success: false,
            error: result.data?.edit_vehicle?.error[0]
          });
        }
      })
      .catch(error => {
        console.log(error);
        resolve({ success: false, error });
      });
  });
};
