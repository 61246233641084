import { Switch } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

export const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginRight: 20,
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

export const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  container: {
    height: "100%",
    padding: theme.spacing(3),
    overflow: "hidden",
    backgroundColor: "white"
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: "600",
    letterSpacing: 1,
    whiteSpace: "nowrap"
  },
  tabs: {
    "&.MuiTabs-root": {
      boxShadow: "0 2px 3px -3px #a2a2a2"
    },
    "&.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
      height: 3
    }
  },
  tab: {
    color: "#a2a2a2",
    "&.MuiTab-root": {
      border: 0,
      borderRadius: 0,
      fontSize: 16
    },
    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "transparent"
    },
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "transparent",
      color: "#000000"
    },
    "& span": {
      textTransform: "none",
      letterSpacing: 0.75,
      fontWeight: 500
    }
  },
  bottom: {
    textAlign: "center",
    marginTop: "auto",
    width: "100%",
    paddingTop: 20,
    paddingBottom: 16
  },
  btn_rounded: {
    background: "#ff8f00",
    color: "#ffffff",
    borderRadius: "30px",
    width: "250px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      background: "#ff8f00"
    }
  },
  btn_unassign: {
    marginLeft: 10,
    background: "#ff8f00",
    color: "#ffffff",
    borderRadius: "30px",
    width: "80px",
    fontSize: 12,
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      background: "#ff8f00"
    },
    "&:disabled": {
      color: "white",
      background: "#ffb866"
    }
  },
  vehicle_plate: {
    textAlign: "center",
    width: "100px",
    color: "#ffffff",
    fontWeight: "600",
    padding: "0.2rem",
    backgroundColor: "#ff8f00",
    borderRadius: "8px",
    letterSpacing: "1px"
  },
  table: {
    // maxHeight: 220,
    height: 220,
    border: "1px solid #d5d5d5"
  },
  thead: {
    "& tr > th": {
      color: "#a2a2a2",
      background: "#ffffff"
    }
  },
  tbody: {
    "& tr > td": {
      fontWeight: 600
    }
  },
  trow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      background: "#f2f2f2"
    }
  },
  trow_padded: {
    "& td, & th": {
      padding: 10
    }
  },
  search_box: {
    display: "flex",
    gap: "0.2rem",
    alignItems: "center",
    border: "1px solid rgb(162, 162, 162, 0.5)",
    padding: "0 4px",
    color: "#a2a2a2",
    "& input": {
      fontSize: "14px"
    }
  },
  oor_label: {
    "&.MuiFormControlLabel-labelPlacementStart, &.MuiFormControlLabel-root": {
      marginLeft: 0
    }
  }
}));