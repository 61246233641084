import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Clear as DeleteIcon, Search } from "@material-ui/icons";
import {
  Item,
  TableWrapper,
} from "../../TripAllocation.styles";
import Table from "../../../../../utils/UI/Table/CustomTable";
import _ from "lodash";

const HEADERS = [
  { id: "trip_number", label: "Trip Number" },
  { id: "pickup_location", label: "Pickup Location" },
  { id: "delivery_location", label: "Delivery Location" },
  { id: "vehicle_type", label: "Vehicle Type" },
  { id: "action", label: "" },
];

const Row = (props) => {
  const {
    trip,
    handleDelete = () => {}
  } = props;

  return (
    <TableRow>
      <TableCell>{trip.trip_number}</TableCell>
      <TableCell>
        <span>{`${trip.pickups[0].geofence_code}-${trip.pickups[0].name}`}</span>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          {trip.dropoffs.map(
            p => p && <span>{`${p.geofence_code}-${p.name}`}</span>
          )}
        </Box>
      </TableCell>
      <TableCell>{trip.vehicle_type}</TableCell>
      <TableCell>
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const isSame = (prevProps, nextProps) => {
  return nextProps.trip.id === prevProps.trip.id;
};

const MemoizedRow = React.memo(Row, isSame);

/**
 * Table for bookings without assigned hauler
 */
const TripsTable = React.memo((props) => {
  const {
    trips,
    handleDelete = () => {}
  } = props;

  return (
    <Item padding={"0"}>
      <TableWrapper
        fullHeight={trips.length ? false : true}
        tdPadding={10}
      >
        <Table headers={HEADERS}>
          {trips.length ? (
            trips?.map((trip, idx) => (
              <MemoizedRow key={trip.id} trip={trip} handleDelete={() => handleDelete(trip, idx)} />
            ))
          ) : (
            <>
              <TableRow>
                <TableCell
                  colSpan={4}
                  rowSpan={2}
                  style={{ textAlign: "center", borderBottom: "none" }}
                >
                  <Box display="flex" justifyContent="center" gridGap={2}>
                    <Search />
                    <Typography>No Trips Selected.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </>
          )}
        </Table>
      </TableWrapper>
    </Item>
  );
});

export default TripsTable;
