import React, { useEffect } from "react";
import { Modal } from "@material-ui/core";

// Styles
import useBasicModalStyles from "./basic.styles";

const BasicModal = React.forwardRef(
  ({ children, open: propOpen = false, ...modalProps }, ref) => {
    // const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    // const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(propOpen);
    const styles = useBasicModalStyles();

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      ref.current = { openModal: handleOpen, closeModal: handleClose };
    }, []);

    const getContainerClassName = (className = []) => {
      return [styles.main, ...className].join(" ");
    };

    return (
      <div>
        <Modal
          className={styles.root}
          // BackdropProps={{
          //   classes: {
          //     root: styles.backdropRoot
          //   }
          // }}
          // disableEscapeKeyDown
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          {...modalProps}
        >
          {typeof children === "function"
            ? children({ getContainerClassName })
            : children}
        </Modal>
      </div>
    );
  }
);

export default BasicModal;
