import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  makeStyles
} from "@material-ui/core";
import moment from "moment";
import Highlight from "react-highlighter";
import Table from "../../../utils/UI/Table/CustomTable";
import Header from "../../../utils/UI/Table/Header";
import ChecklistIcon from "../../../assets/360ChecklistIcon.svg";
import EditChecklist from "./EditChecklist";
import AddLogs from "../../../utils/functions/AddLogs";

// constant values.
const Headers = [
  { id: "vehicle_info.plateno", label: "Plate Number", isSortable: true },
  { id: "vehicle_types.name", label: "Vehicle Type", isSortable: true },
  { id: "clients.name", label: "Hauler", isSortable: true },
  {
    id: "rover_status.distancetraveled",
    label: "Current Odometer Reading (km)",
    isSortable: true
  },
  { id: "last_360_check", label: "Last Checked", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false }
];
const HEADER_LABEL = "360 Checklist";
const INITIAL_FORMAT_DATE = "LL";

const Checklist360 = props => {
  const classes = useStyles();
  const {
    page,
    setPage,
    vehicles,
    count,
    total,
    order,
    setOrder,
    totalPage,
    vars,
    refetchVehicle
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [group, setGroup] = useState();

  const toggleEditMode = selected => {
    if (selected) {
      setGroup(selected);
    }
    setEditMode(!editMode);
  };

  if (editMode) {
    return (
      <EditChecklist toggleEditMode={toggleEditMode} group={group} />
    );
  }

  return (
    <>
      <Header
        label={HEADER_LABEL}
        total={total}
        page={page}
        setPage={setPage}
        toggleEditMode={toggleEditMode}
        totalPage={totalPage}
        vars={vars}
        refetchVehicle={refetchVehicle}
      />
      <Table headers={Headers} order={order} setOrder={setOrder}>
        {count > 0 ? (
          vehicles.map((data, index) => {
            const distanceTraveled =
              data.device_info?.device_status?.distancetraveled / 1000;
            const { last_360_check } = data;

            return (
              <TableRow
                key={index}
                hover
                className={classes.table_data_container}
              >
                <TableCell>
                  <span className={classes.table_data}>
                    <Highlight search={vars.keyword}>
                      {data.plateno}
                    </Highlight>
                  </span>
                </TableCell>
                <TableCell>
                  <span className={classes.table_data}>
                    <Highlight search={vars.keyword}>
                      {data.vehicle_type}
                    </Highlight>
                  </span>
                </TableCell>
                <TableCell>
                  <span className={classes.table_data}>
                    <Highlight search={vars.keyword}>
                      {data.client_name}
                    </Highlight>
                  </span>
                </TableCell>
                <TableCell>
                  {distanceTraveled > 0 ? (
                    <span className={classes.table_data}>
                      {distanceTraveled}
                    </span>
                  ) : (
                    <span className={classes.error_data}>Not Applicable</span>
                  )}
                </TableCell>
                <TableCell>
                  {last_360_check ? (
                    <span className={classes.table_data}>
                      {moment(last_360_check).format(INITIAL_FORMAT_DATE)}
                    </span>
                  ) : (
                    <span className={classes.error_data}>Not Applicable</span>
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => {
                      AddLogs("PMS - 360 Checklist", "checklist_view", data.plateno);
                      window.open(`/pms/view?vehicle_id=${data.id}`);
                    }}
                  >
                    <img src={ChecklistIcon} alt="" width={14} height={14} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={20}>
              No Records Found
            </TableCell>
          </TableRow>
        )}
      </Table>
    </>
  );
};

export default Checklist360;

const useStyles = makeStyles(theme => ({
  table_data: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  error_data: {
    color: theme.palette.error.dark,
    fontSize: 13
  }
}));
