/* eslint-disable import/prefer-default-export */
import moment from "moment";
import getSuffix from "./getSuffix";
import { days } from "./rawData";

export const formatEvery = (day, time, type) => {
  if (day === undefined) {
    return moment(`2021-01-01 ${time}`).format("hh:mm A");
  }

  if (type === "weekly") {
    return `${days[+day - 1]}, ${moment(`2021-01-01 ${time}`).format(
      "hh:mm A"
    )}`;
  }

  return `${getSuffix(day)}, ${moment(`2021-01-01 ${time}`).format("hh:mm A")}`;
};
