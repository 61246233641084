import React from "react";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";

// this ui provides a design on reusable container for dashboards.
const DashboardContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.container}> 
      <Typography className={classes.container_title}>{props.label}</Typography>
      <Grid container spacing={2}>
        {props.children}
      </Grid>
    </div>
  );
};

export default DashboardContainer;

const useStyles = makeStyles(theme => ({
  container_title: {
    color: theme.palette.primary.dark,
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 14,
    margin: "1rem 0",
  },
  container: {
    padding: "0 1.5rem",
  }
}));
