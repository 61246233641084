/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useParams } from "react-router-dom";
// import ChipInput from "material-ui-chip-input";
import {
  // Box,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Container,
  Checkbox,
  ClickAwayListener,
  // ExpansionPanel as Accordion,
  // ExpansionPanelSummary as AccordionSummary,
  // ExpansionPanelDetails as AccordionDetails,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  RadioGroup,
  Radio,
  Switch,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextareaAutosize,
  Toolbar,
  Tooltip,
  Typography,
  TextField
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import {
  Help as HelpIcon,
  // ExpandMore as ExpandMoreIcon,
  Event as EventIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  AlternateEmail as EmailIcon
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import _ from "lodash";
import { SketchPicker } from "react-color";
import Swal from "sweetalert2";
import {
  GET_ADMIN_ACCESS_OBJECTS,
  GET_ADMIN_CLIENT,
  GET_CLIENTS_CODES,
  GET_ADMIN_USERS,
  GET_INFO_BUBBLE
} from "../../../../../graphql/Queries";
import {
  EDIT_ADMIN_CLIENT,
  RESEND_ADMIN_LINK,
  EDIT_INFO_BUBBLE
} from "../../../../../graphql/Mutations";
import Loading from "../../../../../utils/Loading";
import validate from "../../../../../utils/validation";
import SearchContext from "../../../../../context/SearchContext";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { Helper } from "../../../../Utils";
import AddLogs from "../../../../../utils/functions/AddLogs";
import EditPartnersTable from "./EditPartnersTable";

import ModuleTree from "../../../../../utils/ModuleTree";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),

      "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
        color: "red"
      }
    }
  },
  TBroot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  registeredBadge: {
    cursor: "help",
    backgroundColor: "#34CB1B"
  },
  pendingBadge: {
    cursor: "help",
    backgroundColor: "#FEC702"
  },
  expiredBadge: {
    cursor: "help",
    backgroundColor: "#C4C4C4"
  },
  switch: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  typographyRoot: {
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "0.30px",
    color: "hsl(0deg 0% 0% / 76%)"
  },
  checkboxRoot: {
    "& .MuiSvgIcon-root": {
      height: "0.8em !important",
      fontSize: "1.25rem !important"
    }
  },
  summaryLabelWrapper: {
    flex: 1,
    display: "flex",
    padding: "2px"
  },
  summaryLabelRoot: {
    marginRight: 4,
    "& .MuiButtonBase-root": {
      padding: 0
    }
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  helpIconForm: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

const sort = (items = []) => {
  const temp = [...items];
  return temp.sort((a, b) => {
    if (a.label < b.label) return -1;
    return 1;
  });
};

const EditViewMenuTest = props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    access,
    handleAccessView,
    handleAccessEdit,
    editMode
  } = props;
  const modules = access.modules.filter(obj => obj.resource === anchorEl.name);
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "left bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        disabled={!editMode}
                        checked={
                          modules && modules[0] && modules[0].permission.view
                            ? modules[0].permission.view
                            : false
                        }
                        onClick={e => handleAccessView(e)}
                        name={anchorEl.name}
                        color="primary"
                      />
                    }
                    label="Can View"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        disabled={!editMode}
                        checked={
                          modules && modules[0] && modules[0].permission.edit
                            ? modules[0].permission.edit
                            : false
                        }
                        onClick={e => handleAccessEdit(e)}
                        name={anchorEl.name}
                        color="primary"
                      />
                    }
                    label="Can Edit"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default function Client(props) {
  const {
    history,
    editMode,
    // clientId,
    setMaxUsers,
    setMaxGeofences,
    setMaxDevices,
    setFormEdit,
    setCheckChanges
  } = props;
  const { id: clientId } = useParams();
  const classes = useStyles();
  const landingPageExist = useRef(false);
  const [loading, setLoading] = useState(true);
  const [tempAddData, setTempAddData] = useState({
    id: null,
    name: "",
    client_type: "",
    client_category: " ",
    no_of_users: 1,
    no_of_devices: 0,
    no_of_geofences: 0,
    access_end_date: null,
    data_admin: "",
    remarks: "",
    welcome_logo: null,
    welcome_logo_filename: "welcome_logo.png",
    welcome_text: "Welcome to Track Me Suite",
    tagline_text: "Transform your Logistics",
    header_logo: null,
    header_logo_filename: "header_logo.png",
    header_text: "trackMe Suite",
    theme_color: "#3CA4F7",
    modules: [],
    landing_page: 0,
    client_code: [],
    sms_sending_type: "",
    eta_enabled: 0
    // registered_IPS: []
  });
  const [initialState, setInitialState] = useState(_.cloneDeep(tempAddData));
  const [listModules, setListModules] = useState({
    modules: [],
    mobile: [{ id: 1, name: "TmSuite Mobile", is_checked: false }]
  });
  const [landingPage, setLandingPage] = useState("0");
  const [welcomeLogoDefault, setWelcomeLogoDefault] = useState(true);
  const [welcomeTextDefault, setWelcomeTextDefault] = useState(true);
  const [taglineTextDefault, setTaglineTextDefault] = useState(true);
  const [headerLogoDefault, setHeaderLogoDefault] = useState(true);
  const [headerTextDefault, setHeaderTextDefault] = useState(true);
  const [themeDefault, setThemeDefault] = useState(true);
  const [error, setError] = useState(false);
  const [modulesError, setModulesError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [popper, setPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [colorPickerAnchor, setColorPickerAnchor] = useState(false);
  const [viewColorPicker, setViewColorPicker] = useState(false);
  const contextData = useContext(SearchContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState();
  const [toOpen, setToOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [userKeyword, setUserKeyword] = useState("");
  const [check, setCheck] = useState({ status: null, readOnly: false });
  // const [checkAPI, setCheckAPI] = useState({ status: false, readOnly: false });
  const [generatedCC, setGeneratedCC] = useState();
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [dialogResend, setDialogResend] = useState(false);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [metaData, setMetaData] = useState({
    code: "",
    remarks: ""
  });
  const [clientCodeData, setClientCodeData] = React.useState({
    code: "",
    remarks: ""
  });
  const [helpers, setHelper] = useState({ code: null });
  const [checkCC1, setCheckCC1] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    no_of_users: "",
    no_of_devices: "",
    no_of_geofences: "",
    data_admin: "",
    welcome_text: "",
    tagline_text: "",
    header_text: "",
    category: "",
    landing_page: "",
    general: ""
  });

  const handleOpen = () => {
    setToOpen(true);
  };

  const [infoBubble, setInfoBubble] = useState({});

  const { loading: infoBubbleLoading, data: infoBubbleData } = useQuery(
    GET_INFO_BUBBLE,
    {
      fetchPolicy: "network-only",
      variables: { client_id: clientId },
      onCompleted: d => {
        setInfoBubble({ ...d.get_info_bubble });
      }
    }
  );

  const { data: currentData } = useQuery(GET_ADMIN_CLIENT, {
    variables: { id: clientId },
    onError() {
      setLoading(false);
    },
    fetchPolicy: "network-only"
  });

  const handleUpdateSelectedModules = useCallback(
    newModules => {
      // const landingPageExist = newModules.reduce((accum, current) => {
      //   if (+current.id === +tempAddData.landing_page) {
      //     return current.permission.view;
      //   }
      //   return accum;
      // }, false);
      const tempModules = newModules?.filter(
        module =>
          module.permission.view === true &&
          module.parent_id === "0" &&
          module.id !== process.env.REACT_APP_ACL_MODULE_ &&
          module.id !== process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API
      );
      // setCheckList([{id: '0', name: 'Select Landing Page'}].concat(tempModules));
      setCheckList(tempModules);

      setModulesError(prev => {
        if (prev) {
          return !prev;
        }
      });

      setTempAddData(prev => ({
        ...prev
        // landing_page: landingPageExist ? tempAddData.landing_page : "0"
        // landing_page: "0"
      }));

      setListModules(prev => ({
        ...prev,
        modules: newModules
      }));
    },
    // [setListModules, setTempAddData, tempAddData.landing_page]
    [setListModules, setTempAddData, setModulesError]
  );

  // const handleToggle = () => {
  //   setPopper(prevPopper => !prevPopper);
  // };

  const checkCCInput = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^a-zA-Z0-9.]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const ccOnChange = e => {
    if (e.target.name === "code") {
      setClientCodeData({
        ...clientCodeData,
        [e.target.name]: e.target.value.toUpperCase()
      });
      setGeneratedCC(e.target.value.toUpperCase());
    } else {
      setClientCodeData({
        ...clientCodeData,
        [e.target.name]: e.target.value
      });
      setGeneratedCC(null);
    }
  };

  const handleDialogClose = () => {
    setHelper({ code: "" });
    setDialogOpen(false);
    setCheck(false);
    setClientCodeData({
      code: "",
      remarks: ""
    });
    setGeneratedCC("");
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setClientCodeData({
      code: "",
      remarks: ""
    });
    setGeneratedCC("");
    setCheck(false);
  };

  const handleToDelete = index => {
    setToDelete(index);
  };

  const isSelected = clientCode => selected.indexOf(clientCode) !== -1;

  const handleClick = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setPopper(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopper(false);
    }
  }

  const handleAccessEdit = e => {
    const { name, checked } = e.target;
    const newModules = listModules.modules;
    newModules.map(row => {
      const module = row;
      if (module.resource === name) {
        module.permission.edit = checked;
        if (checked) {
          module.permission.view = true;
        }
      }
      return module;
    });
    setTempAddData({
      ...tempAddData,
      landing_page: "0"
    });
    setListModules({
      ...listModules,
      modules: newModules
    });
  };

  const handleAccessView = e => {
    const { name, checked } = e.target;
    const newModules = listModules.modules;
    newModules.map(row => {
      const module = row;
      if (module.resource === name) {
        module.permission.view = checked;
        if (!checked) {
          module.permission.edit = false;
        }
      }
      return module;
    });
    setTempAddData({
      ...tempAddData,
      landing_page: "0"
    });
    setListModules({
      ...listModules,
      modules: newModules
    });
  };
  // const [expanded, setExpanded] = React.useState("panel1");
  // const handleChange = panel => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const getVariables = () => {
    const variables = {
      keyword: userKeyword.toUpperCase(),
      // skip: 0,
      // first: rowsPerPage,
      condition: [{ field: "client_id", value: tempAddData.id }]
      // orderBy: [{ field: orderBy, direction: order }]
    };

    return variables;
  };

  const { data: checkCode } = useQuery(GET_CLIENTS_CODES);
  const { data: gCode } = useQuery(GET_CLIENTS_CODES, {
    variables: getVariables()
  });

  const { data: userData, refetch: refetchUserData } = useQuery(
    GET_ADMIN_USERS,
    {
      variables: {
        condition: [
          { field: "users.email_address", value: initialState.data_admin }
        ]
      }
    }
  );

  // useEffect(() => {
  //   if (editMode) {
  //     setCheckAPI({
  //       status: false,
  //       readOnly: false
  //     });
  //   }
  // }, [editMode]);

  useEffect(() => {
    if (checkCode) {
      const temp = [];
      checkCode.get_clients_codes.map(row => {
        temp.push(row.code);
        return null;
      });
      setCheckCC1(temp);
    }

    const getCode = [];
    if (gCode) {
      gCode.get_clients_codes.map(row => {
        return getCode.push({
          code: row.code,
          remarks: row.remarks
        });
      });
    }
    setMetaData(getCode);

    if (currentData) {
      const getData = currentData.get_client;
      const getPref = getData.client_preference;
      setLandingPage(getData.acl_module_id);
      const obj = {
        ...tempAddData,
        id: getData.id,
        name: getData.name || "",
        client_type: getData.is_restricted ? "Restricted" : "Non-restricted",
        client_category: getData.category || " ",
        no_of_users: getData.user_count || 1,
        no_of_devices: getData.device_count,
        no_of_geofences: getData.geofence_count,
        access_end_date:
          getData.access_end_date !== "1111-11-11 00:00:00" &&
          getData.access_end_date !== "1111-11-11 08:00:00"
            ? getData.access_end_date
            : null,
        data_admin: getData.data_admin || "",
        remarks: getData.remarks || "",
        welcome_logo: null,
        welcome_logo_filename: getPref.welcome_logo || "",
        welcome_text: getPref.welcome_text || "",
        tagline_text: getPref.tagline_text || "",
        header_logo: null,
        header_logo_filename: getPref.header_logo || "",
        header_text: getPref.header_text || "",
        theme_color: getPref.primary_color,
        modules: getData.acl_modules,
        landing_page: "0",
        client_code: getCode,
        sms_sending_type: getData.sms_sending_type,
        eta_enabled: getData.eta_enabled
        // api_key: getData.api_key,
        // registered_IPS: getData.registered_IPS
        //   ? getData.registered_IPS.split(",")
        //   : []
      };

      localStorage.setItem("name", JSON.stringify(obj.name));
      setTempAddData(obj);
      setInitialState(obj);
      refetchUserData();

      setMaxUsers(getData.user_count);
      setMaxGeofences(getData.geofence_count);
      setMaxDevices(getData.device_count);
    }
  }, [currentData, gCode, editMode]);

  const { data } = useQuery(GET_ADMIN_ACCESS_OBJECTS, {
    skip: !currentData,
    onCompleted() {
      const currentModules =
        currentData && currentData.get_client ? currentData.get_client : [];
      const parsedObjects = [];
      if (data && data.get_access_objects) {
        const accessObjects = data.get_access_objects;
        accessObjects.map(row => {
          const objectRow = row;
          objectRow.is_checked = false;
          for (let i = 0; i < currentModules.acl_modules.length; i++) {
            if (currentModules.acl_modules[i].id === row.id) {
              const loopData = currentModules.acl_modules[i];
              objectRow.permission.view = loopData.view;
              objectRow.permission.edit = loopData.edit;
              break;
            }
          }
          parsedObjects.push(objectRow);
          return true;
        });
      }
      setListModules({
        ...listModules,
        modules: parsedObjects
      });
    },
    onError() {
      setLoading(false);
    },
    fetchPolicy: "network-only"
  });

  // stores the checked landing pages
  const [checkList, setCheckList] = useState([]);
  const [adminFieldExist, setAdminFieldExist] = useState(false);
  useEffect(() => {
    if (listModules && listModules.modules.length) {
      let listModulesTemp = listModules.modules
        .filter(
          data =>
            data.permission.view &&
            data.parent_id === "0" &&
            process.env.REACT_APP_ACL_MODULE_STORE_APP !== data.id &&
            process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API !== data.id
        )
        .map(data => {
          return { id: data.id, label: data.name };
        });
      const isAdminExist = listModulesTemp.some(
        module => module.label.trim().substring(0, 5) === "Admin"
      );

      // setCheckList([{id: '0', label: 'Select Landing Page'}].concat(listModulesTemp));
      setCheckList(listModulesTemp);
      setAdminFieldExist(isAdminExist);
    }
  }, [listModules]);

  // setting landing page according to the selected and checked
  useEffect(() => {
    if (editMode) {
      const landingPageExist = checkList.some(
        list => _.toString(list?.id) === _.toString(tempAddData.landing_page)
      );
      setTempAddData(prev => ({
        ...prev,
        landing_page:
          landingPageExist ||
          (tempAddData.landing_page === 1 && adminFieldExist)
            ? tempAddData.landing_page
            : 0
      }));
    }
  }, [checkList, adminFieldExist]);

  // const handleKeyPress = event => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   if (/[0-9.]/g.test(keyValue)) {
  //     if (event.target.value.length === 15) {
  //       event.preventDefault();
  //     }
  //   } else event.preventDefault();
  // };
  const handleBlur = e => {
    const { name, value } = e.target;
    let errorMessage;

    switch (name) {
      case "name":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (!validate("alphanumeric", value.trim())) {
          errorMessage = "Alphanumeric only";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_users":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 1) {
          errorMessage = "Invalid value. Min: 1, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_devices":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 0) {
          errorMessage = "Invalid value. Min: 0, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_geofences":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 0) {
          errorMessage = "Invalid value. Min: 0, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: errorMessage
    });

    setTempAddData({
      ...tempAddData,
      [name]: value
    });

    if (name === "no_of_users") {
      setTempAddData({
        ...tempAddData,
        no_of_users: parseInt(value)
      });
    } else if (name === "no_of_devices") {
      setTempAddData({
        ...tempAddData,
        no_of_devices: parseInt(value)
      });
    } else if (name === "no_of_geofences") {
      setTempAddData({
        ...tempAddData,
        no_of_geofences: parseInt(value)
      });
    }
  };

  const handleAddValues = e => {
    const { name, value } = e.target;
    let errorMessage;
    switch (name) {
      case "name":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "welcome_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "tagline_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "header_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      // case "no_of_users":
      // errorMessage = validate("numeric", value)
      //   ? ""
      //   : "Numeric characters only";
      // break;
      // case "no_of_devices":
      //   errorMessage = validate("numeric", value)
      //     ? ""
      //     : "Numeric characters only";
      //   break;
      // case "no_of_geofences":
      //   errorMessage = validate("numeric", value)
      //     ? ""
      //     : "Numeric characters only";
      //   break;
      case "data_admin":
        errorMessage = validate("email", value) ? "" : "Invalid Email Address";
        break;
      case "sms_sending_type":
        errorMessage = value ? "" : "This field is required.";
        // case "api_key":
        //   errorMessage = validate("email", value) ? "" : "Invalid Email Address";
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: errorMessage
    });

    setTempAddData({
      ...tempAddData,
      [name]: value
    });

    // if (_.isEqual(tempAddData, initialState)) {
    //   setFormEdit(false);
    //   setCheckChanges(false);
    // } else {
    //   setFormEdit(true);
    //   setCheckChanges(true);
    // }
  };

  const handleOnchange = e => {
    setTempAddData({
      ...tempAddData,
      [e.currentTarget.name]: e.currentTarget.checked ? 1 : 0
    });
  };

  // const generateAPI = event => {
  //   if (event.target.checked) {
  //     setCheckAPI({ status: true, readOnly: true });
  //     let d = new Date().getTime();
  //     if (window.performance && typeof window.performance.now === "function") {
  //       d += performance.now();
  //     }
  //     const uuid = "yxxxxyxxxxyxxxxy".replace(/[xy]/g, function(c) {
  //       const r = (d + Math.random() * 16) % 16 | 0;
  //       d = Math.floor(d / 16);
  //       return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  //     });

  //     setTempAddData({
  //       ...tempAddData,
  //       api_key: uuid
  //     });
  //   } else {
  //     setCheckAPI({ status: false, readOnly: false });
  //     setTempAddData({
  //       ...tempAddData,
  //       api_key: ""
  //     });
  //   }
  // };

  const [resendAdminLink] = useMutation(RESEND_ADMIN_LINK, {
    onCompleted: res => {
      if (res.resend_data_admin_link.success) {
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Link sent successfully",
          timer: 3000
          // onClose: () => {
          //   history.push({
          //     pathname: "/admin",
          //     state: {
          //       params: { moduleName: process.env.REACT_APP_CLIENTS_MODULE }
          //     }
          //   });
          //   window.location.reload();
          // }
        });
      }
      //  else {
      //   Swal.fire({
      //     icon: "error",
      //     text: "Something went wrong. Try again.",
      //     timer: 3000
      //   });
      //   setErrors({
      //     ...errors,
      //     api_key: "API Key already exists."
      //   });
      // }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      return returnData;
    }
  });

  const handleResend = () => {
    setDialogResend(false);
    resendAdminLink({
      variables: {
        client_id: tempAddData.id
      }
    });
  };

  const [editAdminClient] = useMutation(EDIT_ADMIN_CLIENT, {
    onCompleted: res => {
      setDisableSubmit(false);
      setLoading(false);
      setModulesError(false);
      refetchUserData();
      if (res.edit_client.success) {
        AddLogs("Admin - Clients", "update", tempAddData.name);
        editInfoBubble({
          variables: {
            client_id: +clientId,
            info_bubble: infoBubble
          }
        });
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Edit successfully",
          timer: 3000,
          onClose: () => {
            // setting values for table filter values
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_CLIENTS_MODULE,
                  vars: history?.location?.state?.vars,
                  filter: history?.location?.state?.filter
                }
              }
            });
            // window.location.reload();
          }
        });
      } else {
        // Swal.fire({
        //   icon: "error",
        //   text: "API Key already Exists",
        //   timer: 3000
        // });
        // setErrors({
        //   ...errors,
        //   api_key: "API Key already exists."
        // });
        let tempObj = {};
        res.edit_client.error.forEach(er => {
          const { field, message } = er;
          tempObj = {
            ...tempObj,
            [field]: message
          };
        });
        setErrors({
          ...errors,
          ...tempObj
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }
      setDisableSubmit(false);
      setError(true);
      setLoading(false);
      setModulesError(false);
    }
  });

  const [editInfoBubble] = useMutation(EDIT_INFO_BUBBLE);

  const handleMultipleDeleteCode = () => {
    let temp = [...metaData];
    temp = temp.filter(item => !selected.includes(item.code));
    setMetaData([...temp]);
    setSelected([]);
  };

  const clearErrors = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => {
      temp.push({ [element]: "" });
      return true;
    });
    setErrors(Object.assign(errors, ...temp));
  };

  const areRequiredFieldsFilled = [
    tempAddData.name,
    tempAddData.client_category,
    tempAddData.no_of_users,
    tempAddData.access_end_date,
    tempAddData.data_admin,
    tempAddData.landing_page
  ].some(e => e === "" || e === null || e === " " || e === "0" || e === 0);

  useEffect(() => {
    setInitialState({
      ...initialState,
      client_type: _.capitalize(initialState.client_type)
    });
    if (tempAddData.landing_page === "0" && landingPage !== "0") {
      setTempAddData({
        ...tempAddData,
        landing_page: parseInt(landingPage, 0)
      });
      setInitialState({
        ...initialState,
        landing_page: parseInt(landingPage, 0)
      });
      setLandingPage("0");
      setTimeout(() => setLoading(false), 0);
    }

    if (_.isEqual(tempAddData, initialState)) {
      setFormEdit(false);
      setCheckChanges(false);
    } else {
      setFormEdit(true);
      setCheckChanges(true);
    }
  }, [tempAddData]);

  const checkValid = (arr, val) => {
    return arr.some(arrVal => val === arrVal);
  };

  const handleAddCC = async () => {
    const valid = true;
    let temp = {};
    if (clientCodeData.code === "") {
      temp = { ...helpers, code: "This field is required" };
    } else if (checkValid(checkCC1, clientCodeData.code) === true) {
      temp = { ...helpers, code: "Client Code Already Exist" };
    } else if (metaData.length === 0) {
      temp = { ...helpers, code: "" };
      const temp1 = [...metaData];
      temp1.push({
        code: clientCodeData.code,
        remarks: clientCodeData.remarks
      });
      setMetaData(temp1);
      setDialogOpen(false);
      setGeneratedCC("");
      setClientCodeData({
        code: "",
        remarks: ""
      });
    } else {
      const tempMeta = [];
      metaData.map(row => {
        return tempMeta.push(row.code);
      });
      if (checkValid(tempMeta, clientCodeData.code) === true) {
        temp = { ...helpers, code: "Client Code Already Exist" };
      } else {
        temp = { ...helpers, code: "" };
        const temp1 = [...metaData];
        temp1.push({
          code: clientCodeData.code,
          remarks: clientCodeData.remarks
        });
        setMetaData(temp1);
        setDialogOpen(false);
        setGeneratedCC("");
        setClientCodeData({
          code: "",
          remarks: ""
        });
      }
    }
    setHelper(temp);
    return valid;
  };

  const handleDelete = () => {
    const temp = [...metaData];
    temp.splice(toDelete, 1);
    setMetaData([...temp]);
    setSelected([]);
  };

  const handleClose = () => {
    setToOpen(false);
  };

  const headCells = [
    {
      id: "tblCode",
      numeric: false,
      disablePadding: true,
      label: "Client Code"
    },
    {
      id: "tblRemarks",
      numeric: false,
      disablePadding: false,
      label: "Remarks"
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: (
        <Button
          disabled={!editMode}
          hidden={!editMode}
          color="primary"
          onClick={handleDialogOpen}
        >
          Add Entry
        </Button>
      ),
      sortable: false
    }
  ];
  const isDisabled = !editMode ? "false" : "true";
  let checker = false;

  const { data: gcdCode } = useQuery(GET_CLIENTS_CODES);

  React.useEffect(() => {
    if (gcdCode) {
      const temp = [];
      gcdCode.get_clients_codes.map(row => {
        temp.push(row.code);
        return temp;
      });
    }
  }, [gcdCode]);

  const validateCode = () => {
    const temp = tempAddData.name.toString().toUpperCase();
    const holder = temp.replace(/ /g, "");
    const random_number = Math.floor(Math.random() * 99999);
    const tn_to_string = random_number.toString().padStart(5, "0");

    if (holder === "" || holder === null) {
      let random_string = "";
      let random_ascii;
      for (let i = 0; i < 3; i += 1) {
        random_ascii = Math.floor(Math.random() * 25 + 97);
        random_string += String.fromCharCode(random_ascii).toUpperCase();
      }
      return random_string + tn_to_string;
    }
    return holder.substring(0, 3) + tn_to_string;
  };

  const generateClientCode = event => {
    if (event.target.checked) {
      const tempGCC = validateCode();
      setClientCodeData({
        ...clientCodeData,
        code: tempGCC
      });
      setGeneratedCC(tempGCC);
      setCheck({ status: true, readOnly: true });
    } else {
      setClientCodeData({
        ...clientCodeData,
        code: generatedCC
      });
      setCheck({ status: false, readOnly: false });
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = metaData.map(v => v.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  listModules.modules
    .filter(obj => obj.parent_id === "0")
    .map(row => {
      if (parseInt(row.id, 0) === parseInt(tempAddData.landing_page, 0)) {
        landingPageExist.current = true;
      }
      return row;
    });

  const handleSave = () => {
    setDisableSubmit(true);
    // e.preventDefault();
    const modulesData = [];
    const tempParents = [];
    listModules.modules.map(row => {
      if (row.permission.view) tempParents.push(row.parent_id);
      return true;
    });
    const parentIds = [...new Set(tempParents)];
    listModules.modules.map(row => {
      const module = row;
      if (parentIds.includes(row.id)) {
        module.permission.view = true;
        module.permission.edit = true;
      }

      if (module.permission.view) {
        modulesData.push({
          id: module.id,
          resources: module.resource,
          view: module.permission.view,
          edit: module.permission.edit
        });
      }
      return module;
    });

    if (modulesData.length) {
      // clearErrors();
      const errorMessage = "This field is required";
      const errorMessage2 = "Invalid value. Min: 1, Max: 99,999";
      const errorMessage3 = "Invalid value. Min: 0, Max: 99,999";

      setModulesError(false);
      if (tempAddData.client_category === " ") {
        setErrors({
          ...errors,
          category: "This field is required"
        });
        setDisableSubmit(false);
        return true;
      }

      if (!parseInt(tempAddData.landing_page, 0)) {
        setErrors({
          ...errors,
          landing_page: "This field is required"
        });
        setDisableSubmit(false);
        return true;
      }

      setErrors({
        ...errors,
        name: _.isEmpty(tempAddData.name) ? errorMessage : "",
        // category: tempAddData.client_category === " " ? errorMessage : "",
        data_admin: _.isEmpty(tempAddData.data_admin) ? errorMessage : "",
        landing_page: tempAddData.landing_page === "" ? errorMessage : "",
        no_of_devices: _.isNaN(parseInt(tempAddData.no_of_devices))
          ? errorMessage
          : tempAddData.no_of_devices > 99999 || tempAddData.no_of_devices < 0
          ? errorMessage3
          : "",
        no_of_users: _.isNaN(parseInt(tempAddData.no_of_users))
          ? errorMessage
          : tempAddData.no_of_users > 99999 || tempAddData.no_of_users < 1
          ? errorMessage2
          : "",
        no_of_geofences: _.isNaN(parseInt(tempAddData.no_of_geofences))
          ? errorMessage
          : tempAddData.no_of_geofences > 99999 ||
            tempAddData.no_of_geofences < 0
          ? errorMessage3
          : ""
      });
      if (areRequiredFieldsFilled) {
        //   setErrors({
        //     ...errors,
        //     general: "Please fill all required fields!"
        //   });
        setDisableSubmit(false);
        return true;
      }

      editAdminClient({
        variables: {
          id: clientId,
          client: {
            name: tempAddData.name,
            category: tempAddData.client_category,
            user_count: parseInt(tempAddData.no_of_users, 0),
            device_count: parseInt(tempAddData.no_of_devices, 0),
            geofence_count: parseInt(tempAddData.no_of_geofences, 0),
            access_end_date: tempAddData.access_end_date,
            data_admin: tempAddData.data_admin,
            remarks: tempAddData.remarks,
            acl_module_id: parseInt(tempAddData.landing_page, 0),
            acl_modules: modulesData,
            client_preference: {
              welcome_logo: tempAddData.welcome_logo,
              welcome_text: tempAddData.welcome_text,
              tagline_text: tempAddData.tagline_text,
              header_logo: tempAddData.header_logo,
              header_text: tempAddData.header_text,
              primary_color: tempAddData.theme_color
            },
            client_codes: metaData,
            sms_sending_type: tempAddData.sms_sending_type,
            eta_enabled: tempAddData.eta_enabled,
            is_restricted: _.isEqual(tempAddData.client_type, "Restricted")
            // api_key: tempAddData.api_key,
            // registered_IPS: tempAddData.registered_IPS
            //   ? tempAddData.registered_IPS.toString()
            //   : ""
          }
        }
      });
    } else {
      setError(true);
      setDisableSubmit(false);
      setModulesError(true);
    }
    return true;
  };

  return loading || infoBubbleLoading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: "inherit"
      }}
    >
      <Container
        style={{
          background: "#FFFFFF",
          maxHeight: "100%",
          overflow: "auto",
          padding: "20px"
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
          className={classes.root}
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                All fields with <span style={{ color: "red" }}>*</span> are
                required
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">General Information</Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={!tempAddData.name && error}
                    // required
                    id="client-name"
                    placeholder="Enter Client Name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                    label={
                      <span style={{ color: "black" }}>
                        Client Name <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value={tempAddData.name}
                    name="name"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    onBlur={
                      handleBlur
                      //   e => {
                      //   const attribute = e.target.getAttribute("name");
                      //   setTempAddData({
                      //     ...tempAddData,
                      //     [attribute]: e.target.value.trim()
                      //   });
                      // }
                    }
                  />
                  {errors.name && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.name}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink>&nbsp;Client Type&nbsp;</InputLabel>
                  <TextField
                    disabled={!editMode}
                    fullWidth
                    id="client_type_id"
                    select
                    value={tempAddData.client_type}
                    name="client_type"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                  >
                    <MenuItem value={tempAddData.client_type}>
                      {tempAddData.client_type}
                    </MenuItem>
                    {_.isEqual(tempAddData.client_type, "Non-restricted") ? (
                      <MenuItem value="Restricted"> Restricted</MenuItem>
                    ) : (
                      <MenuItem value="Non-restricted">
                        {" "}
                        Non-restricted
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={tempAddData.client_category === " " && error}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                    required
                    id="select-category"
                    select
                    name="client_category"
                    label="Category"
                    value={tempAddData.client_category}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                  >
                    <MenuItem value=" ">Select Category</MenuItem>
                    {[
                      "Division",
                      "Business Unit",
                      "Terminal",
                      "Distribution Center",
                      "Hardware"
                    ].map(category => (
                      <MenuItem key={`${category}1`} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.category && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.category}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Set maximum number of users for this client
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title="Set maximum number of users for this client"
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;No. of users&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    error={tempAddData.no_of_users < 1 && error}
                    fullWidth
                    id="number-of-users"
                    type="number"
                    inputProps={{
                      // min: "1",
                      // step: "1",
                      // max: "99999",
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                    value={tempAddData.no_of_users}
                    name="no_of_users"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.no_of_users && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.no_of_users}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Set maximum number of GPS devices for this client
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title="Set maximum number of GPS devices for this client"
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;No. of Devices&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={tempAddData.no_of_devices < 0 && error}
                    inputProps={{
                      // min: "0",
                      // step: "1",
                      // max: "99999",
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                    id="number-of-devices"
                    type="number"
                    value={tempAddData.no_of_devices}
                    name="no_of_devices"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.no_of_devices && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.no_of_devices}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Set maximum number of geofences for this client
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title="Set maximum number of geofences for this client"
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;No. of Geofences&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={tempAddData.no_of_geofences < 0 && error}
                    inputProps={{
                      // min: "0",
                      // step: "1",
                      // max: "10000",
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                    id="number-of-geofences"
                    type="number"
                    value={tempAddData.no_of_geofences}
                    name="no_of_geofences"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.no_of_geofences && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.no_of_geofences}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Access End Date&nbsp;
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </InputLabel>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      required
                      format="MM/DD/YYYY"
                      disabled={!editMode}
                      error={!tempAddData.access_end_date && error}
                      value={tempAddData.access_end_date || null}
                      name="access_end_date"
                      onChange={date =>
                        setTempAddData({
                          ...tempAddData,
                          access_end_date: moment(date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        })
                      }
                      InputProps={{
                        placeholder: "Select Date/Time",
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        )
                      }}
                      showTodayButton
                      todayLabel="NOW"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    // required
                    id="standard-required"
                    error={!tempAddData.data_admin && error}
                    type="email"
                    placeholder="Enter Email Address"
                    InputLabelProps={{
                      shrink: true
                    }}
                    // label="Data Admin"
                    label={
                      <span style={{ color: "black" }}>
                        Data Admin <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name="data_admin"
                    value={tempAddData.data_admin}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                  />
                  {errors.data_admin && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.data_admin}
                    </Alert>
                  )}
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          {userData?.get_users.count > 0
                            ? "Registered"
                            : "Pending"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Badge
                      variant="dot"
                      classes={{
                        badge:
                          userData?.get_users.count > 0
                            ? classes.registeredBadge
                            : classes.pendingBadge
                      }}
                    />
                  </HtmlTooltip>
                </Grid>
                {editMode &&
                  (userData?.get_users.count > 0 ? null : (
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          setDialogResend(true);
                        }}
                      >
                        <u>Resend Link</u>
                      </Button>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    disabled={!editMode}
                    id="sms-sending-combo-box"
                    value={tempAddData.sms_sending_type || "Not Applicable"}
                    getOptionLabel={option => option}
                    options={["Not Applicable", "Globe labs", "Text me"]}
                    onSelect={e => handleAddValues(e)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="sms_sending_type"
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="Select sms sending"
                        label="SMS Sending"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.switch}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Turn on: Allow ETA calculations in trip update
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Turn on: Allow ETA calculations in trip update"
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;ETA&nbsp;
                    </InputLabel>
                    <AntSwitch
                      color="primary"
                      name="eta_enabled"
                      disabled={!editMode}
                      checked={!!tempAddData.eta_enabled}
                      onChange={e => handleOnchange(e)}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    IP Address
                  </InputLabel>
                  <div style={{ paddingLeft: 10, width: "100%" }}>
                    {editMode ? (
                      <ChipInput
                        InputProps={{
                          className: classes.inputLabel,
                          height: "50px",
                          width: "100%",
                          placeholder: "Type here..."
                        }}
                        value={
                          tempAddData.registered_IPS
                            ? tempAddData.registered_IPS
                            : null
                        }
                        onKeyPress={handleKeyPress}
                        onAdd={chip => tempAddData.registered_IPS.push(chip)}
                        onDelete={chip =>
                          setTempAddData({
                            ...tempAddData,
                            registered_IPS: tempAddData.registered_IPS.filter(
                              item => !chip.includes(item)
                            )
                          })
                        }
                      />
                    ) : (
                      <ChipInput
                        InputProps={{
                          className: classes.inputLabel,
                          height: "50px",
                          width: "100%",
                          readOnly: !editMode
                        }}
                        value={
                          tempAddData.registered_IPS
                            ? tempAddData.registered_IPS
                            : null
                        }
                      />
                    )}
                  </div>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <br />
                  <InputLabel
                    shrink
                    className={classes.inputLabel}
                    style={{ float: "left" }}
                  >
                    API Key&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <InputLabel
                    shrink
                    className={classes.inputLabel}
                    style={{ float: "right" }}
                  >
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            if turned on, code will be generated.
                            <br />
                            &nbsp;A unique API Key for a client that has ERP
                            integration to TM Suite.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <HelpIcon />
                    </HtmlTooltip>
                    &nbsp; &nbsp;
                    <Switch
                      size="small"
                      color="primary"
                      checked={checkAPI.status}
                      disabled={!editMode}
                      onChange={e => generateAPI(e)}
                    />
                  </InputLabel>
                  <TextField
                    required
                    fullWidth
                    inputProps={{
                      readOnly: checkAPI.readOnly || !editMode
                    }}
                    style={{ width: "85%" }}
                    id="standard-required"
                    error={!tempAddData.api_key && error}
                    type="text"
                    placeholder="Enter API Key"
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="api_key"
                    value={tempAddData.api_key}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Remarks
                  </InputLabel>
                  <TextareaAutosize
                    disabled={!editMode}
                    className={classes.inputLabel}
                    aria-label="minimum height"
                    minRows={3}
                    maxRows={6}
                    placeholder="Minimum 3 rows"
                    name="remarks"
                    value={tempAddData.remarks}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <HtmlTooltip
                  // title={
                  //   <React.Fragment>
                  //     <Typography color="inherit">
                  //       Customize theme, welcome text and logo, tagline text,
                  //       and header text and logo
                  //     </Typography>
                  //   </React.Fragment>
                  // }
                  placement="top-start"
                  title="Customize theme, welcome text and logo, tagline text, and header text and logo"
                >
                  <HelpIcon className={classes.helpIcon} />
                </HtmlTooltip>
                &nbsp;Other Information
              </Typography>
              <br />
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Image specifications:
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Dimension: 100x100 px
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Format: .jpg, .png, .gif
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Preferably no background
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title={
                        <span>
                          Image specifications:
                          <br />
                          Dimension: 100x100 px
                          <br />
                          Format: .jpg, .png, .gif
                          <br />
                          Preferably no background
                        </span>
                      }
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;Welcome Logo&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  {welcomeLogoDefault ? (
                    <TextField
                      fullWidth
                      required
                      id="welcome-logo"
                      name="welcome_logo"
                      InputProps={{
                        readOnly: welcomeLogoDefault,
                        disableUnderline: welcomeLogoDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    disabled={!editMode}
                                    checked={welcomeLogoDefault}
                                    onChange={() => {
                                      setWelcomeLogoDefault(
                                        !welcomeLogoDefault
                                      );
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedWelcomeLogo"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder={
                        tempAddData.welcome_logo_filename || "welcome_logo.png"
                      }
                    />
                  ) : (
                    <div>
                      <input
                        required
                        style={{
                          margin: 15,
                          width: "70%",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
                        }}
                        // ref={fileUpload => {
                        //   setTemp
                        // }}
                        type="file"
                        name="welcome_logo"
                        onChange={event => {
                          const { name } = event.target;
                          const {
                            target: {
                              files: [file]
                            }
                          } = event;

                          setTempAddData({
                            ...tempAddData,
                            [name]: file
                          });
                        }}
                        accept=".jpg,.png,.gif"
                        multiple
                      />
                      <Checkbox
                        color="primary"
                        disabled={!editMode}
                        checked={welcomeLogoDefault}
                        onChange={() => {
                          setWelcomeLogoDefault(!welcomeLogoDefault);
                        }}
                        style={{ padding: 0, marginLeft: 12 }}
                        name="checkedWelcomeLogo"
                      />
                      <span style={{ fontSize: 12 }}>Use Default</span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    Welcome Text&nbsp;<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    id="welcome-text"
                    name="welcome_text"
                    value={tempAddData.welcome_text}
                    error={!tempAddData.welcome_text && error}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      readOnly: welcomeTextDefault,
                      disableUnderline: welcomeTextDefault,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            control={
                              <div>
                                <Checkbox
                                  color="primary"
                                  disabled={!editMode}
                                  checked={welcomeTextDefault}
                                  onChange={() => {
                                    setWelcomeTextDefault(!welcomeTextDefault);
                                  }}
                                  style={{ padding: 0 }}
                                  name="checkedWelcomeText"
                                />
                                <span style={{ fontSize: 12 }}>
                                  Use Default
                                </span>
                              </div>
                            }
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Welcome to Track Me Suite"
                  />
                  {errors.welcome_text && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.welcome_text}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    Tagline Text
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    id="tagline-text"
                    name="tagline_text"
                    value={tempAddData.tagline_text}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      readOnly: taglineTextDefault,
                      disableUnderline: taglineTextDefault,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            control={
                              <div>
                                <Checkbox
                                  color="primary"
                                  disabled={!editMode}
                                  checked={taglineTextDefault}
                                  onChange={() => {
                                    setTaglineTextDefault(!taglineTextDefault);
                                  }}
                                  style={{ padding: 0 }}
                                  name="checkedTaglineText"
                                />
                                <span style={{ fontSize: 12 }}>
                                  Use Default
                                </span>
                              </div>
                            }
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Transform your Logistics"
                  />
                  {errors.tagline_text && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.tagline_text}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Image specifications:
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Dimension: 100x100 px
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Format: .jpg, .png, .gif
                      //     </Typography>
                      //     <Typography color="inherit">
                      //       Preferably no background
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title={
                        <span>
                          Image specifications:
                          <br />
                          Dimension: 100x100 px
                          <br />
                          Format: .jpg, .png, .gif
                          <br />
                          Preferably no background
                        </span>
                      }
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;Header Logo&nbsp;
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  {headerLogoDefault ? (
                    <TextField
                      fullWidth
                      required
                      id="header-logo"
                      name="header_logo"
                      InputProps={{
                        readOnly: headerLogoDefault,
                        disableUnderline: headerLogoDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    disabled={!editMode}
                                    checked={headerLogoDefault}
                                    onChange={() => {
                                      setHeaderLogoDefault(!headerLogoDefault);
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedHeaderLogo"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder={
                        tempAddData.header_logo_filename || "header_logo.png"
                      }
                    />
                  ) : (
                    <div>
                      <input
                        required
                        style={{
                          margin: 15,
                          width: "70%",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
                        }}
                        type="file"
                        name="header_logo"
                        onChange={event => {
                          const { name } = event.target;
                          const {
                            target: {
                              files: [file]
                            }
                          } = event;
                          setTempAddData({
                            ...tempAddData,
                            [name]: file
                          });
                        }}
                        accept=".jpg,.png,.gif"
                        multiple
                      />
                      <Checkbox
                        color="primary"
                        disabled={!editMode}
                        checked={headerLogoDefault}
                        onChange={() => {
                          setHeaderLogoDefault(!headerLogoDefault);
                        }}
                        style={{ padding: 0, marginLeft: 12 }}
                        name="checkedHeaderLogo"
                      />
                      <span style={{ fontSize: 12 }}>Use Default</span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    Header Text&nbsp;<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    id="header-text"
                    name="header_text"
                    value={tempAddData.header_text}
                    error={!tempAddData.header_text && error}
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    InputProps={{
                      readOnly: headerTextDefault,
                      disableUnderline: headerTextDefault,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            control={
                              <div>
                                <Checkbox
                                  color="primary"
                                  disabled={!editMode}
                                  checked={headerTextDefault}
                                  onChange={() => {
                                    setHeaderTextDefault(!headerTextDefault);
                                  }}
                                  style={{ padding: 0 }}
                                  name="checkedHeaderText"
                                />
                                <span style={{ fontSize: 12 }}>
                                  Use Default
                                </span>
                              </div>
                            }
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="trackMe Suite"
                  />
                  {errors.header_text && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.header_text}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel} shrink>
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Set the primary theme of the trackMe Suite
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title="Set the primary theme of the trackMe Suite"
                    >
                      <HelpIcon className={classes.helpIconForm} />
                    </HtmlTooltip>
                    &nbsp;Themes&nbsp;<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    error={!tempAddData.theme_color && error}
                    id="theme-color"
                    name="theme_color"
                    value={tempAddData.theme_color}
                    InputProps={{
                      readOnly: themeDefault,
                      disableUnderline: themeDefault,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            control={
                              <div>
                                <Checkbox
                                  color="primary"
                                  disabled={!editMode}
                                  checked={themeDefault}
                                  onChange={() => {
                                    setThemeDefault(!themeDefault);
                                  }}
                                  style={{ padding: 0 }}
                                  name="checkedHeaderText"
                                />
                                <span style={{ fontSize: 12 }}>
                                  Use Default
                                </span>
                              </div>
                            }
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="#3CA4F7"
                  />
                  <Grid container>
                    <Grid item xs={12} style={{ border: "1px solid #000000" }}>
                      <p style={{ marginLeft: 10, fontWeight: "bold" }}>Web</p>
                    </Grid>
                    <Grid item xs={12} style={{ border: "1px solid #000000" }}>
                      <p style={{ marginLeft: "10%", marginBottom: 0 }}>
                        Presets
                      </p>

                      <RadioGroup
                        align="center"
                        aria-label="theme_color"
                        name="theme_color"
                        value={tempAddData.theme_color}
                        style={{
                          display: "block",
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingBottom: 10
                        }}
                        // value={value}
                        onChange={e => {
                          handleAddValues(e);
                        }}
                      >
                        <FormControlLabel
                          value="#3CA4F7"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#3CA4F7",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#FFB74D"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#FFB74D",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#FFA1D4"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#FFA1D4",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#01BABD"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#01BABD",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#586E87"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#586E87",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#6D62E9"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#6D62E9",
                                height: 5,
                                width: 5
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          value="#000000"
                          disabled={themeDefault}
                          control={
                            <Radio
                              style={{
                                background: "#000000",
                                height: 5,
                                width: 5
                              }}
                              onClick={e => {
                                setColorPickerAnchor(e.currentTarget);
                                setViewColorPicker(true);
                              }}
                            />
                          }
                        />
                      </RadioGroup>

                      <Popper
                        open={viewColorPicker}
                        anchorEl={colorPickerAnchor}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        <ClickAwayListener
                          onClickAway={() => {
                            setViewColorPicker(false);
                          }}
                        >
                          <SketchPicker
                            color={tempAddData.theme_color}
                            onChangeComplete={col =>
                              setTempAddData({
                                ...tempAddData,
                                theme_color: col.hex
                              })
                            }
                          />
                        </ClickAwayListener>
                      </Popper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <EditPartnersTable
                    editMode={editMode}
                    currentData={currentData}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography
                variant="h6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <HtmlTooltip
                  // title={
                  //   <React.Fragment>
                  //     <Typography color="inherit">
                  //       Set modules that will be accessible for the client
                  //     </Typography>
                  //   </React.Fragment>
                  // }
                  placement="top-start"
                  title="Set modules that will be accessible for the client"
                >
                  <HelpIcon className={classes.helpIcon} />
                </HtmlTooltip>
                &nbsp;Modules&nbsp;<span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "auto",
                  border: "1px solid #cccccc80"
                }}
              >
                <ModuleTree
                  disabled={!editMode}
                  default_access={currentData?.get_client.acl_modules}
                  updateSelectedModules={handleUpdateSelectedModules}
                />
                {/* {listModules &&
                  listModules.modules
                    .filter(obj => obj.parent_id === "0")
                    .map(row => {
                      const children = [];
                      const mainCheckbox = [];
                      if (
                        parseInt(row.id, 0) ===
                        parseInt(tempAddData.landing_page, 0)
                      ) {
                        landingPageExist = true;
                      }
                      listModules.modules
                        .filter(obj => obj.parent_id === row.id)
                        .map(c_row => {
                          children.push(c_row.id);
                          if (c_row.permission.view || c_row.permission.edit) {
                            mainCheckbox.push(true);
                          }
                          return true;
                        });
                      return (
                        <Accordion
                          square
                          key={row.id}
                          expanded={expanded === row.resource}
                          onChange={handleChange(row.resource)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            expandIcon={
                              <IconButton
                                name={row.resource}
                                onClick={e => {
                                  if (!children.length) {
                                    setAnchorEl(e.currentTarget);
                                    handleToggle();
                                  }
                                }}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            }
                            // id="panel1d-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                              key={row.id}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={!editMode}
                                    checked={
                                      children.length > 0
                                        ? mainCheckbox.length !== 0
                                        : row.permission.view
                                    }
                                    onClick={e => {
                                      const newModules = listModules.modules;

                                      newModules.map(r => {
                                        const module = r;
                                        if (
                                          module.parent_id === row.id ||
                                          module.id === row.id
                                        ) {
                                          if (e.target.checked) {
                                            module.permission.view = true;
                                            module.permission.edit = true;
                                          } else {
                                            module.permission.view = false;
                                            module.permission.edit = false;
                                          }
                                        }
                                        return module;
                                      });

                                      setTempAddData({
                                        ...tempAddData,
                                        landing_page: "0"
                                      });
                                      setListModules({
                                        ...listModules,
                                        modules: newModules
                                      });
                                    }}
                                    color="primary"
                                    name={row.resource}
                                  />
                                }
                                label={row.name}
                              />
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "block",
                              borderTop: "solid",
                              borderTopWidth: "1px",
                              borderTopColor: "lightgray",
                              padding: "8px 20px"
                            }}
                          >
                            {listModules &&
                              listModules.modules
                                .filter(obj => obj.parent_id === row.id)
                                .map(c_row => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                      key={c_row.id}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disabled={!editMode}
                                            checked={c_row.permission.view}
                                            color="primary"
                                            onClick={e => handleChange(e)}
                                            onChange={() => {
                                              const newModules =
                                                listModules.modules;
                                              newModules.map(r => {
                                                const module = r;

                                                if (
                                                  module.id ===
                                                    c_row.parent_id ||
                                                  module.id === c_row.id
                                                ) {
                                                  module.permission.view = !c_row
                                                    .permission.view;
                                                  if (!c_row.permission.view) {
                                                    module.permission.edit = false;
                                                  }
                                                }
                                                return module;
                                              });
                                              setTempAddData({
                                                ...tempAddData,
                                                landing_page: "0"
                                              });
                                              setListModules({
                                                ...listModules,
                                                modules: newModules
                                              });
                                            }}
                                            name={c_row.resource}
                                          />
                                        }
                                        label={c_row.name}
                                        labelPlacement="end"
                                      />
                                      <div style={{ flexGrow: 1 }} />
                                      <IconButton
                                        name={c_row.resource}
                                        onClick={e => {
                                          setAnchorEl(e.currentTarget);
                                          handleToggle();
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })} */}
              </Grid>
              {modulesError && (
                <Alert severity="error" className={classes.alert}>
                  Please select atleast one module
                </Alert>
              )}
              <Grid item xs={12} md={12}>
                {listModules && listModules.modules.length && (
                  <TextField
                    required
                    id="landing-page"
                    fullWidth
                    select
                    label="Landing Page"
                    error={!tempAddData.landing_page && error}
                    value={landingPageExist ? tempAddData.landing_page : 0}
                    name="landing_page"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    style={{ marginTop: 30 }}
                    InputProps={{
                      readOnly: !editMode,
                      disableunderline: isDisabled
                    }}
                  >
                    <MenuItem value={0} disabled>
                      <span style={{ color: "#8e8e8e" }}>
                        Select Landing Page
                      </span>
                    </MenuItem>
                    {adminFieldExist && <MenuItem value={1}> Admin </MenuItem>}
                    {sort(
                      checkList.filter(
                        list => list?.label?.trim().substring(0, 5) !== "Admin"
                      )
                    ).map(list => {
                      return (
                        <MenuItem
                          value={list.id}
                          key={list.id}
                          disabled={!editMode || list.id === "0" ? true : false}
                        >
                          {list.label}
                        </MenuItem>
                      );
                    })}
                    {/* {listModules && listModules.modules.length
                      ? listModules.modules
                          .filter(obj => obj.parent_id === "0")
                          .map(row => {
                            let isVisible = 0;
                            if (row.permission.view) {
                              isVisible = 1;
                              // return (
                              //   <MenuItem key={row.id} value={row.id}>
                              //     {row.name}
                              //   </MenuItem>
                              // )
                            }
                            listModules.modules
                              .filter(obj => obj.parent_id === row.id)
                              .map(row2 => {
                                if (row2.permission.view) isVisible = 1;
                                return isVisible;
                              });

                            if (isVisible) {
                              if (row.resource.split("/")[1] !== "admin") {
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                );
                              }
                              if (!checker) {
                                checker = true;
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    Admin
                                  </MenuItem>
                                );
                              }
                            }

                            return "";
                          })
                      : ""} */}
                  </TextField>
                )}
                {errors.landing_page && (
                  <Alert
                    severity="error"
                    // style={{ justifyContent: "center" }}
                    className={classes.alert}
                  >
                    {errors.landing_page}
                  </Alert>
                )}
              </Grid>
              <br />
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Toolbar className={classes.TBroot}>
                      <Typography
                        variant="h6"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: "1"
                        }}
                      >
                        <HtmlTooltip
                          // title={
                          //   <React.Fragment>
                          //     <Typography color="inherit">
                          //       Used as reference code for ERP integration to TM
                          //       Suite
                          //     </Typography>
                          //   </React.Fragment>
                          // }
                          placement="top-start"
                          title="Used as reference code for ERP integration to TM Suite"
                        >
                          <HelpIcon className={classes.helpIcon} />
                        </HtmlTooltip>
                        &nbsp;Client Code&nbsp;&nbsp;
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "2px solid #D2D2D2",
                            boxSizing: "border-box",
                            borderRadius: 23,
                            padding: "4px 10px 3px 10px",
                            marginRight: 20
                          }}
                        >
                          <InputBase
                            placeholder="Search…"
                            style={{ width: "100%" }}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput
                            }}
                            onChange={e => {
                              setSearchKeyword({
                                ...searchKeyword,
                                value: e.target.value,
                                submit: false,
                                moduleName: process.env.REACT_APP_USERS_MODULE
                              });
                              setUserKeyword(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                          />
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                        </div>
                      </div>
                    </Toolbar>
                    {editMode ? (
                      <Toolbar
                        className={clsx(classes.TBroot, {
                          [classes.highlight]: selected.length > 0,
                          [classes.invisible]: selected.length <= 0
                        })}
                      >
                        {selected.length > 0 && (
                          <Tooltip title="Delete" style={{ float: "left" }}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleOpenDeleteAll();
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {selected.length > 0 && (
                          <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                            style={{ color: "black" }}
                          >
                            <span style={{ color: "#FF845E" }}>
                              {selected.length}
                            </span>{" "}
                            client/s selected
                          </Typography>
                        )}
                      </Toolbar>
                    ) : null}
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < metaData.length
                                }
                                onChange={handleSelectAllClick}
                                checked={
                                  selected.length > 0 &&
                                  selected.length === metaData.length
                                }
                              />
                            </TableCell>
                            {headCells.map(headCell => (
                              <TableCell
                                key={headCell.id}
                                // sortDirection={orderBy === headCell.id ? order : false}
                              >
                                <TableSortLabel
                                // active={orderBy === headCell.id}
                                // direction={orderBy === headCell.id ? order : "asc"}
                                // onClick={() => handleSort(headCell.id)}
                                >
                                  {headCell.label}
                                  {/* {orderBy === headCell.id ? (
                                      <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                      </span>
                                    ) : null} */}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {metaData.length > 0 ? (
                            metaData.map((row, index) => {
                              const isItemSelected = isSelected(row.code);
                              const labelId = `enhanced-table-checkbox-${row.code}`;
                              return (
                                <TableRow
                                  key={`row-${row.code}`}
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  selected={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    {editMode ? (
                                      <Checkbox
                                        checked={isItemSelected}
                                        onClick={() => handleClick(row.code)}
                                        inputProps={{
                                          "aria-labelledby": labelId
                                        }}
                                        color="primary"
                                      />
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    <Highlight
                                      search={contextData.searchKeyword.value}
                                    >
                                      {row.code}
                                    </Highlight>
                                  </TableCell>
                                  <TableCell>
                                    <Highlight
                                      search={contextData.searchKeyword.value}
                                    >
                                      {row.remarks}
                                    </Highlight>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {editMode ? (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleToDelete(index);
                                          handleOpen();
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow style={{ height: 53 * 2 }}>
                              <TableCell colSpan={4} align="center">
                                No data found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Toolbar className={classes.TBroot}>
                  <Typography
                    variant="h5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <HtmlTooltip
                      // title={
                      //   <React.Fragment>
                      //     <Typography color="inherit">
                      //       Set the alerts that should appear in the info bubble.
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                      placement="top-start"
                      title="Set the alerts that should appear in the info bubble."
                    >
                      <HelpIcon className={classes.helpIcon} />
                    </HtmlTooltip>
                    &nbsp;Vehicle Info Bubble&nbsp;&nbsp;
                  </Typography>
                </Toolbar>
                <div style={{ border: "1px solid rgba(204, 204, 204, 0.5)" }}>
                  {infoBubbleData &&
                    Object.keys(infoBubbleData?.get_info_bubble).map((d, i) => {
                      if (d !== "client_id")
                        return (
                          <div key={d} className={classes.summaryLabelWrapper}>
                            <FormControlLabel
                              classes={{ root: classes.summaryLabelRoot }}
                              control={
                                <Checkbox
                                  color="primary"
                                  disabled={!editMode}
                                  checked={!!infoBubble[d]}
                                  classes={{ root: classes.checkboxRoot }}
                                  onChange={(e, val) => {
                                    setInfoBubble({ ...infoBubble, [d]: +val });
                                  }}
                                  style={{ padding: 0, marginLeft: 15 }}
                                  name={d}
                                />
                              }
                            />
                            <Typography
                              classes={{ root: classes.typographyRoot }}
                            >
                              &nbsp;&nbsp;
                              {d === "power_source"
                                ? "Running in auxiliary"
                                : _.startCase(d)}
                            </Typography>
                          </div>
                        );
                      return null;
                    })}
                </div>
                <br />
              </Grid>
            </Grid>
          </Grid>
          {editMode && (
            <Grid item xs={12} align="center">
              {/* {errors.general && (
                <Alert
                  severity="error"
                  style={{ justifyContent: "center" }}
                  className={classes.alert}
                >
                  {errors.general}
                </Alert>
              )} */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                style={{
                  margin: 20,
                  width: "30%",
                  marginTop: 10,
                  borderRadius: "100px"
                }}
                onClick={handleSave}
              >
                Save Details
              </Button>
            </Grid>
          )}
        </form>
        {anchorEl && (
          <EditViewMenuTest
            editMode={editMode}
            open={popper}
            anchorEl={anchorEl}
            access={listModules}
            handleClosePopper={handleClosePopper}
            handleListKeyDown={handleListKeyDown}
            handleAccessEdit={handleAccessEdit}
            handleAccessView={handleAccessView}
          />
        )}
      </Container>
      <ConfirmationDialog
        toggle={toOpen}
        close={() => handleClose()}
        fn={e => handleDelete(e)}
        title="Delete?"
        content="Are you sure you want to delete this client code?"
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Client Code</DialogTitle>
        <DialogContent>
          <InputLabel
            shrink
            className={classes.inputLabel}
            style={{ float: "left" }}
          >
            Client Code&nbsp;
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputLabel
            shrink
            className={classes.inputLabel}
            style={{ float: "right" }}
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    if turned on, code will be generated
                  </Typography>
                </React.Fragment>
              }
            >
              <HelpIcon className={classes.helpIconForm} />
            </HtmlTooltip>
            &nbsp; &nbsp;
            <Switch
              size="small"
              color="primary"
              checked={check.status}
              onChange={generateClientCode}
            />
          </InputLabel>
          <TextField
            minRows={3}
            autoFocus
            margin="dense"
            id="code"
            name="code"
            placeholder="Enter Client Code"
            type="text"
            fullWidth
            inputProps={{
              readOnly: check.readOnly
            }}
            value={generatedCC || null}
            onChange={ccOnChange}
            onKeyPress={checkCCInput}
            helperText={helpers.code ? <Helper message={helpers.code} /> : null}
          />
          <InputLabel shrink className={classes.inputLabel}>
            Remarks
          </InputLabel>
          <TextareaAutosize
            minRows={3}
            aria-label="minimum height"
            style={{ height: "50px", width: "100%" }}
            autoFocus
            margin="dense"
            id="remarks"
            name="remarks"
            placeholder="Type Here..."
            type="text"
            fullWidth
            onChange={ccOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleAddCC}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        toggle={dialogDeleteAll}
        close={() => handleCloseDeleteAll()}
        fn={e => handleMultipleDeleteCode(e)}
        title="Delete?"
        content="Are you sure you want to delete these client codes?"
      />
      <Dialog
        open={dialogResend}
        onClose={() => setDialogResend(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <h3>Resend Link?</h3>
          <p>Are you sure you want to resend link?</p>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tempAddData.data_admin} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogResend(false)} color="primary">
            No
          </Button>
          <Button color="primary" onClick={handleResend}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
