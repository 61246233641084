/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { useParams, withRouter } from "react-router-dom";
import AccessControl from "../../../../../utils/AccessControl";
// import Header from "../Header";
import Header from "../../../Header";
import ViewVehicle from "./ViewVehicle";
import EditVehicle from "./EditVehicle";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { GET_VEHICLE_INFO } from "../../../../../graphql/Queries";
import redirectToTable from "../../../../../utils/redirect";

const VehicleDetails = props => {
  const { id } = useParams();
  const { location, history } = props;
  const [initialState, setInitialState] = useState();
  const [editMode, setEditMode] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [editPermission, setEditPermission] = useState(false);
  const [queryVars, setQueryVars] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [dialog, setDialog] = useState({
    discard: false
  });
  const { state: locationState } = history.location;

  const { data: vehicle_data } = useQuery(GET_VEHICLE_INFO, {
    variables: {
      // id: vehicleId
      id: id
    },
    onError: () => {
      Swal.fire({
        title: "Something went wrong",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          redirectToTable(history, process.env.REACT_APP_VEHICLES_MODULE);
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          redirectToTable(history, process.env.REACT_APP_VEHICLES_MODULE);
        }
      });
    },
    fetchPolicy: "network-only"
    // skip: vehicleId === null
  });

  const toggleEditMode = event => {
    if (!event.target.checked) {  
      if (formEdit) {
        handleOpen("discard");
        // setDiscard(true);
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const discarded = () => {
    setEditMode(false);
    handleClose("discard");

    // Swal.fire({
    //   title: "Discarded",
    //   icon: "success",
    //   showConfirmButton: false,
    //   timer: 3000
    // });
  };

  const handleClose = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleOpen = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: true });
  };

  useEffect(() => {
    if (location.state) {
      setVehicleId(location.state.vehicle_id);
      setQueryVars(location.state.queryVars);
    } else {
      history.push({
        // pathname: "/admin",
        state: { params: { moduleName: process.env.REACT_APP_VEHICLES_MODULE } }
      });
    }
  }, [location]);

  useEffect(() => {
    setInitialState(vehicle_data);
  }, [vehicle_data, initialState]);
  return (
    <AccessControl
      resource={location.pathname}
      process="view"
      setEditPermission={setEditPermission}
    >
      <Fragment>
        {/* <Header
          editMode={editMode}
          setEditMode={setEditMode}
          editPermission={editPermission}
          moduleName={process.env.REACT_APP_VEHICLES_MODULE}
          toggleMode={toggleMode}
          process={editMode ? "Edit" : "View"}
        /> */}
        <Header
          // process={formEdit ? "Edit" : "View"}
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_VEHICLES_MODULE}
          history={history?.location?.state}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          locationState={locationState}
          isDataNotChanged={!formEdit}
        />

        {editMode ? (
          <EditVehicle
            setFormEdit={setFormEdit}
            vehicle_data={vehicle_data}
            queryVars={queryVars}
          />
        ) : (
          <ViewVehicle vehicle_data={vehicle_data} vehicleId={vehicleId} />
        )}
        <ConfirmationDialog
          toggle={dialog.discard}
          close={e => handleClose("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </Fragment>
    </AccessControl>
  );
};

export default withRouter(VehicleDetails);
