import React from "react";
import ReactDOM from "react-dom";
import { LoadScript } from "@react-google-maps/api";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "./index.css";
import App from "./components/App";
import client from "./Client";
import * as serviceWorker from "./serviceWorker";
import LoadingElement from "./utils/Map/LoadingElement/LoadingElement";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
Chart.unregister(ChartDataLabels);
const libraries = ["places", "geometry", "drawing"];

ReactDOM.render(
  <BrowserRouter forceRefresh={false}>
    <ApolloProvider client={client}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
        libraries={libraries}
        loadingElement={<LoadingElement />}
      >
        <App />
      </LoadScript>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
