export const getMaxLength = (n = 0) => {
  if (/^\+639/.test(n)) return 13;
  if (/^639/.test(n)) return 12;
  if (/^9/.test(n)) return 10;
  return 11;
};

export default {
  getMaxLength
};
