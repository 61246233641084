import {
  GET_REPORT_TYPES_QUERY,
  GET_REPORT_REQUESTS_QUERY
} from "../graphql/Queries";
import {
  ADD_REPORT_REQUEST_MUTATION,
  UPDATE_REPORT_REQUEST_MUTATION,
  DELETE_REPORT_REQUEST_MUTATION
} from "../graphql/Mutations";
import client from "../Client";

export const GET_REPORT_TYPES = () => {
  return new Promise(resolve => {
    client
      .query({
        query: GET_REPORT_TYPES_QUERY
      })
      .then(data => {
        resolve(data.data.get_report_types);
      })
      .catch(err => console.log(err));
  });
};

export const GET_REPORT_REQUESTS = () => {
  return new Promise(resolve => {
    client
      .query({
        query: GET_REPORT_REQUESTS_QUERY
      })
      .then(data => {
        resolve(data.data.get_report_requests);
      })
      .catch(err => console.log(err));
  });
};

export const ADD_REPORT_REQUEST = variables => {
  return new Promise(resolve => {
    client
      .mutate({
        mutation: ADD_REPORT_REQUEST_MUTATION,
        variables
      })
      .then(data => {
        // console.log('data');
        // console.log(data);
        // resolve(data.data.get_report_requests);
      })
      .catch(err => console.log(err));
  });
};

export const UPDATE_REPORT_REQUEST = variables => {
  // console.log(variables);
  return new Promise(resolve => {
    client
      .mutate({
        mutation: UPDATE_REPORT_REQUEST_MUTATION,
        variables
      })
      .then(data => {
        // console.log(data);
        resolve(data);
      })
      .catch(err => console.log(err));
  });
};

export const DELETE_REPORT_REQUEST = variables => {
  // console.log(variables);
  return new Promise(resolve => {
    client
      .mutate({
        mutation: DELETE_REPORT_REQUEST_MUTATION,
        variables
      })
      .then(data => {
        // console.log(data);
        resolve(data.data.delete_report_request);
        // resolve(data.data.get_report_requests);
      })
      .catch(err => console.log(err));
  });
};

export const TEST = () => {
  //  test
};
