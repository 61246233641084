import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import NoNotificationImg from "../../assets/noNotifications.svg";

// no notification design for representation of no fetch data.
const NoNotification = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap="10px"
        className={classes.noNotifContainer}
      >
        <img
          src={NoNotificationImg}
          alt="no notifications available"
          className={classes.noNotifImg}
        />
        <Typography className={classes.noNotifMsg}>
          No Notifications Available
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  noNotifContainer: {
    height: "54.5vh",
    padding: "1rem",
    backgroundColor: "#FFFFFF"
  },
  noNotifImg: {
    width: 200,
    height: "auto"
  },
  noNotifMsg: {
    fontSize: 12,
    color: "#BEBEBE"
  }
}));

export default memo(NoNotification);
