import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  TextField,
  Slider,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  Tooltip
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import Cookie from "js-cookie";
import Swal from "sweetalert2";
import { Marker, DirectionsRenderer, Polygon } from "@react-google-maps/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _, { min } from "lodash";
import { useStyles } from "./RouteGeoStyles";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import Map from "../../../../../utils/Map/index";
import { control_positions } from "../../../../../utils/Map/Inner";
import AddLogs from "../../../../../utils/functions/AddLogs";
import redirectToTable from "../../../../../utils/redirect";
import PlaceAutocomplete from "./SearchInput";
import { ADD_ROUTEGEOFENCE } from "../../../../../graphql/Mutations";
import { getMultiPolygonStr, getWaypoints } from "./utils";
import { wktToCoordinates } from "../../../../../utils/functions/coordinatesParserV2";
import { poly_options, buffers } from "./options";
import useUserContext from "../../../../../context/User/useUserContext";
import Header from "../../../Header";
import AccessControl from "../../../../../utils/AccessControl";

const DEFAULT_ZOOM = 6;


// function to get the directions from start and end location (uses Google DirectionsService)
// renders direction (purple line) and sets the multipolygon
// also sets line_geom
function getDirections(
  directionsDetails,
  setDirectionsDetails,
  getMultiPolygonStr,
  getWaypoints,
  savedWaypoints
) {
  const { google } = window;
  const DirectionsService = new google.maps.DirectionsService();
  if (directionsDetails.origin && directionsDetails.destination) {
    DirectionsService.route(
      {
        origin: new google.maps.LatLng(
          directionsDetails.origin?.lat,
          directionsDetails.origin?.lng
        ),
        destination: new google.maps.LatLng(
          directionsDetails.destination?.lat,
          directionsDetails.destination?.lng
        ),
        //waypoints: savedWaypoints,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const line_geom_object = {
            start: {
              name: directionsDetails.startAddress,
              coordinates: {
                lat: directionsDetails.origin?.lat,
                lng: directionsDetails.origin?.lng
              }
            },
            end: {
              name: directionsDetails.endAddress,
              coordinates: {
                lat: directionsDetails.destination?.lat,
                lng: directionsDetails.destination?.lng
              }
            },
            waypoints: getWaypoints(result.routes[0]?.legs[0]?.steps),
            overview_polyline: result.routes[0].overview_polyline
          };

          setDirectionsDetails({
            ...directionsDetails,
            directions: result,
            multiPolygon: getMultiPolygonStr(
              result.routes[0].overview_path,
              directionsDetails.buffer
            ),
            polyPath: result.routes[0].overview_path,
            line_geom: JSON.stringify(JSON.stringify(line_geom_object))
          });
        } else {
          Swal.fire({
            title: "Something went wrong",
            text: "An error occured",
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    );
  }
}

const RouteGeofence = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useUserContext();
  const { group_ids } = user;
  const { state: locationState } = history.location;

  const [geofenceDetails, setGeofenceDetails] = useState({
    name: "",
    code: "",
    speedLimit: 0,
    customSpeedLimit: 0
  });

  const [directionsDetails, setDirectionsDetails] = useState({
    origin: null,
    destination: null,
    directions: null,
    multiPolygon: null,
    polyPath: null,
    line_geom: null,
    buffer: 50,
    startAddress: "",
    endAddress: ""
  });

  const directionsRenderRef = useRef(); // REF FOR DIRECTIONS RENDERER

  const [booleans, setBooleans] = useState({
    autoGenerate: false,
    isDirectionsDisabled: true,
    open: false,
    isStartPoint: false,
    isEndPoint: false,
    nameErr: false,
    geocodeErr: false,
    speedLimitErr: false
  });

  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [initialValue] = useState({
    form: _.cloneDeep(geofenceDetails),
    places: _.cloneDeep(directionsDetails)
  });

  const [center, setCenter] = useState({
    lat: 12.248727944234455,
    lon: 121.95099687500002
  });

  const memoizedCenter = React.useMemo(() => {
    return {
      lat: center.lat,
      lng: center.lon
    };
  }, [center?.lat, center?.lon]);

  const options = React.useMemo(
    () => ({
      controlSize: 20,
      mapTypeControlOptions: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT
      }
    }),
    []
  );

  const input_props = {
    classes: {
      input: classes.input_fontSize
    }
  };

  const speedLimit_props = {
    classes: {
      input: classes.input_fontSize
    },
    max: 200,
    min: 0
  };

  const startMarkerSVG = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 20)
  };

  const endMarkerSVG = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#F49400",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 20)
  };

  // Modal handling
  const handleOpen = () => {
    // setOpen(true);
    setBooleans({ ...booleans, open: true });
  };
  const handleClose = () => {
    // setOpen(false);
    setBooleans({ ...booleans, open: false });
  };

  // handles the autogenerated geofence code
  const handleToggle = () => {
    if (!booleans.autoGenerate) {
      const randomCode = Math.random()
        .toString(36)
        .slice(-5);
      setGeofenceDetails({
        ...geofenceDetails,
        code: randomCode.toString(36).slice(-5)
      });
    } else {
      setGeofenceDetails({
        ...geofenceDetails,
        code: ""
      });
    }
    setBooleans(prev => {
      return {
        ...prev,
        autoGenerate: !prev.autoGenerate,
        isStartPoint: false,
        isEndPoint: false,
        geocodeErr: false
      };
    });
  };

  // disable plotting points on the map
  const setPointsFalse = () => {
    setBooleans({
      ...booleans,
      isStartPoint: false,
      isEndPoint: false
    });
  };

  // function to be called when an autocomplete place is chosen
  // sets the origin / destination
  // handles changes to autocomplete values
  const onPlaceChanged = ({ newPlace, formattedAddress, start }) => {
    setDirectionsDetails({
      ...directionsDetails,
      origin: start
        ? {
            lat: newPlace?.location.lat(),
            lng: newPlace?.location.lng()
          }
        : directionsDetails.origin,
      destination: !start
        ? {
            lat: newPlace?.location.lat(),
            lng: newPlace?.location.lng()
          }
        : directionsDetails.destination,
      startAddress: start ? formattedAddress : directionsDetails.startAddress,
      endAddress: !start ? formattedAddress : directionsDetails.endAddress,
      directions:
        directionsDetails.origin && directionsDetails.destination
          ? null
          : directionsDetails.directions,
      multiPolygon:
        directionsDetails.origin && directionsDetails.destination
          ? null
          : directionsDetails.multiPolygon
    });

    setCenter({
      lat: newPlace?.location.lat(),
      lon: newPlace?.location.lng()
    });
    if (booleans.isStartPoint || booleans.isEndPoint) setPointsFalse();
  };

  // SETS BUFFER IN SLIDER
  const sliderOnChange = (event, value) => {
    setPointsFalse();
    setDirectionsDetails({ ...directionsDetails, buffer: value });

    if (directionsDetails.directions) {
      setDirectionsDetails({
        ...directionsDetails,
        multiPolygon: getMultiPolygonStr(directionsDetails?.polyPath, value),
        buffer: value
      });
    }
  };

  // function called when dragging (onMouseUp) start and end markers on the map
  // also called when a point is set as marker on the map
  // sets the name for location in line_geom if coordinates are used instead of autocomplete
  const adjustLocation = (latLng, start) => {
    setPointsFalse();
    setDirectionsDetails(prev => {
      return {
        ...prev,
        origin: start
          ? {
              lat: latLng.lat(),
              lng: latLng.lng()
            }
          : prev.origin,
        destination: !start
          ? {
              lat: latLng.lat(),
              lng: latLng.lng()
            }
          : prev.destination,
        startAddress: start
          ? `${latLng.lat()}, ${latLng.lng()}`
          : prev.startAddress,
        endAddress: !start
          ? `${latLng.lat()}, ${latLng.lng()}`
          : prev.endAddress,
        directions: null,
        multiPolygon: null
      };
    });
  };

  // handles onClick in map to plot start or end point
  const handleSetPoint = latLng => {
    if (booleans.isStartPoint) {
      adjustLocation(latLng, true);
      setPointsFalse();
    } else if (booleans.isEndPoint) {
      adjustLocation(latLng, false);
      setPointsFalse();
    }
  };

  // ADD ROUTE GEOFENCE MUTATION
  const [addGeofence, { error }] = useMutation(ADD_ROUTEGEOFENCE, {
    onCompleted(data) {
      if (data) {
        if (data.add_geofence.success) {
          AddLogs("geofence", "insert", geofenceDetails.name);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              if (locationState?.source) {
                // set values again for vehicles table filter after saving
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });
              } else {
                // set values again for vehicles table filter after saving
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });

              }
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              if (locationState?.source) {
                // set values again for vehicles table filter after saving
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });

              } else {
                // set values again for vehicles table filter after saving
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                      vars: locationState.vars,
                      filter: locationState.filter
                    }
                  }
                });

              }
            }
          });
        } else {
          Swal.fire({
            title: "Something went wrong",
            title: "Error",
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
          if (data.add_geofence.error.some(e => e.field === "name")) {
            setBooleans({ ...booleans, nameErr: true });
          }
          if (data.add_geofence.error.some(e => e.field === "geofence_code")) {
            setBooleans({ ...booleans, geocodeErr: true });
          }
        }
      }
    },
    onError: ApolloError => {
      Swal.fire({
        title: "Something went wrong",
        title: "Error",
        icon: "error",
        showConfirmButton: false,
        timer: 3000
      });
    }
  });

  // function called when adding a new route geofence
  const saveDetails = event => {
    event.preventDefault();

    const location = {
      lat: directionsDetails.origin?.lat,
      lon: directionsDetails.origin?.lng
    };

    // ADD GEOFENCE
    addGeofence({
      variables: {
        name: geofenceDetails.name,
        address: directionsDetails.startAddress,
        geofence_code: geofenceDetails.code?.toString()?.trim(),
        category: "Route",
        location: location,
        group_ids: group_ids,
        geom: directionsDetails.multiPolygon,
        line_geom: directionsDetails.line_geom,
        line_buffer: directionsDetails.buffer,
        speed_limit:
          geofenceDetails.speedLimit === "Custom"
            ? geofenceDetails.customSpeedLimit
            : geofenceDetails.speedLimit
      }
    });
  };

  const handleSpeedChange = value => {
    if (value >= 0 && value <= 200 && Number.isInteger(value)) {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: value
      });
    } else if (value < 0) {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: 0
      });
    } else {
      setGeofenceDetails({
        ...geofenceDetails,
        customSpeedLimit: 200
      });
    }
    if (value < 0 || value > 200 || !Number.isInteger(value)) {
      setBooleans(prev => {
        return { ...prev, speedLimitErr: true };
      });
    }
    setPointsFalse();
  };

  // handles disabling get directions button if start/end are empty
  useEffect(() => {
    if (directionsDetails.origin && directionsDetails.destination) {
      setBooleans({ ...booleans, isDirectionsDisabled: false });
    } else {
      setBooleans({ ...booleans, isDirectionsDisabled: true });
    }
  }, [directionsDetails.origin, directionsDetails.destination]);

  const { pathname } = history.location;

  return (
    <AccessControl resource={pathname} process="Add">
      <div
        style={{
          height: "calc(100% - 64px)",
          width: "100%"
        }}
      >
        
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          history={history?.location?.state}
          locationState={history?.location}
          setDiscard={setDiscard}
          redirect={redirect}
          setRedirect={setRedirect}
          isDataNotChanged={
            _.isEqual(geofenceDetails, initialValue.form) &&
            _.isEqual(directionsDetails, initialValue.places)
          }
        />
        <Map
          options={options}
          defaultZoom={DEFAULT_ZOOM}
          zoom={DEFAULT_ZOOM}
          center={memoizedCenter}
          onClick={e => handleSetPoint(e.latLng)}
        >
          <Marker
            draggable
            animation={window.google.maps.Animation.DROP}
            onMouseUp={e => adjustLocation(e.latLng, true)}
            icon={startMarkerSVG}
            position={{
              lat: Number(directionsDetails.origin?.lat),
              lng: Number(directionsDetails.origin?.lng)
            }}
          />

          <Marker
            draggable
            animation={window.google.maps.Animation.DROP}
            onMouseUp={e => adjustLocation(e.latLng, false)}
            icon={endMarkerSVG}
            position={{
              lat: Number(directionsDetails.destination?.lat),
              lng: Number(directionsDetails.destination?.lng)
            }}
          />

          <Map.Control position={control_positions.LEFT_TOP}>
            <Paper className={classes.paper_inputCard}>
              <div style={{ width: "100%" }}>
                <TextField
                  error={booleans.nameErr}
                  helperText={booleans.nameErr ? "The name already exists" : ""}
                  fullWidth
                  required
                  label="Geofence Name"
                  placeholder="Enter Geofence name"
                  InputLabelProps={{
                    classes: { root: classes.label },
                    shrink: true
                  }}
                  InputProps={input_props}
                  inputProps={{ maxLength: 50 }}
                  style={{ marginTop: "8px", marginBottom: "8px" }}
                  value={geofenceDetails.name}
                  onChange={e => {
                    setGeofenceDetails({
                      ...geofenceDetails,
                      name: e.target.value
                    });
                    setPointsFalse();
                    if (booleans.nameErr) {
                      setBooleans({
                        ...booleans,
                        nameErr: false
                      });
                    }
                  }}
                />

                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <InputLabel
                      required
                      shrink
                      id="toggle-code"
                      className={classes.label}
                    >
                      Geofence Code
                    </InputLabel>
                    <Switch
                      size="small"
                      color="primary"
                      onChange={handleToggle}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track
                      }}
                    />
                  </div>

                  <TextField
                    error={booleans.geocodeErr}
                    helperText={
                      booleans.geocodeErr
                        ? "The geofence code already exists"
                        : ""
                    }
                    fullWidth
                    aria-labelledby="toggle-code"
                    required
                    placeholder="Enter Geofence code"
                    InputProps={input_props}
                    inputProps={{
                      readOnly: booleans.autoGenerate,
                      maxLength: 30
                    }}
                    value={geofenceDetails.code}
                    onChange={event => {
                      setGeofenceDetails({
                        ...geofenceDetails,
                        code: event.target.value
                      });

                      if (booleans.geocodeErr) {
                        setBooleans({
                          ...booleans,
                          geocodeErr: false
                        });
                      }
                    }}
                    onBlurCapture={() => {
                      setGeofenceDetails(prev =>({
                        ...prev,
                        code: prev.code?.toString()?.trim()
                      }));
                    }}
                  />
                </div>
                <div
                  className={booleans.isStartPoint ? classes.start_active : ""}
                >
                  <PlaceAutocomplete
                    start
                    onPlaceChanged={onPlaceChanged}
                    setDirectionsDetails={setDirectionsDetails}
                    startAddress={directionsDetails.startAddress}
                    setPointBoolean={setBooleans}
                  />
                </div>
                <div
                  className={booleans.isEndPoint ? classes.start_active : ""}
                >
                  <PlaceAutocomplete
                    start={false}
                    setDirectionsDetails={setDirectionsDetails}
                    onPlaceChanged={onPlaceChanged}
                    endAddress={directionsDetails.endAddress}
                    setPointBoolean={setBooleans}
                  />
                </div>
                <Button
                  className={
                    booleans.isDirectionsDisabled
                      ? classes.disabled_button
                      : classes.button_getDirections
                  }
                  variant="contained"
                  color="secondary"
                  style={{ marginBottom: "8px" }}
                  disabled={booleans.isDirectionsDisabled}
                  onClick={() =>
                    getDirections(
                      directionsDetails,
                      setDirectionsDetails,
                      getMultiPolygonStr,
                      getWaypoints
                    )
                  }
                >
                  Get Directions
                </Button>
                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <InputLabel
                    className={classes.slider_label}
                    id="buffer-slider-label"
                  >
                    Apply Buffer(meters)
                  </InputLabel>
                  <Slider
                    defaultValue={50}
                    aria-labelledby="buffer-slider-label"
                    step={null}
                    valueLabelDisplay="off"
                    marks={buffers}
                    classes={{ markLabel: classes.slider_label }}
                    value={directionsDetails.buffer}
                    onChange={sliderOnChange}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "8px" }}>
                  <InputLabel
                    className={classes.slider_label}
                    id="speed-select-label"
                  >
                    Speed Limit(kph)
                  </InputLabel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Select
                      fullWidth
                      labelId="speed-select-label"
                      id="speed-limit-select"
                      value={geofenceDetails.speedLimit}
                      defaultValue="none"
                      onChange={event => {
                        setGeofenceDetails({
                          ...geofenceDetails,
                          speedLimit: event.target.value,
                          customSpeedLimit: 0
                        });
                        setPointsFalse();
                      }}
                      style={{ fontSize: "13px", color: "#808080" }}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={70}>70</MenuItem>
                      <MenuItem value={80}>80</MenuItem>
                      <MenuItem value={90}>90</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                    {geofenceDetails.speedLimit === "Custom" && (
                      <div style={{ marginLeft: "10px" }}>
                        <TextField
                          error={booleans.speedLimitErr}
                          InputProps={speedLimit_props}
                          inputProps={{ min: 0, max: 200 }}
                          type="number"
                          value={geofenceDetails.customSpeedLimit}
                          onChange={e =>
                            setGeofenceDetails({
                              ...geofenceDetails,
                              customSpeedLimit: e.target.value
                            })
                          }
                          onBlur={e =>
                            handleSpeedChange(Number(e.target.value))
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Button
                disabled={
                  !(
                    geofenceDetails.name !== "" &&
                    geofenceDetails.code !== "" &&
                    directionsDetails.startAddress !== "" &&
                    directionsDetails.directions !== null
                  )
                }
                className={classes.button_getDirections}
                variant="contained"
                color="primary"
                onClick={handleOpen}
              >
                Save
              </Button>
            </Paper>
          </Map.Control>

          {booleans.open && (
            <ConfirmationDialog
              toggle={handleOpen}
              title="Save?"
              content="Are you sure you want to save this geofence?"
              fn={saveDetails}
              close={handleClose}
              type=""
            />
          )}

          {directionsDetails.directions && (
            <DirectionsRenderer
              options={{
                suppressMarkers: true,
                preserveViewport: false,
                polylineOptions: {
                  strokeColor: "#7F00FF", // CHANGE COLOR OF DIRECTION LINE
                  // strokeWeight: bufferValue * 0.5 //CHANGE WEIGHT OF DIRECTION LINE
                  strokeWeight: 15
                },
                draggable: true
              }}
              ref={directionsRenderRef}
              directions={directionsDetails.directions}
              onDirectionsChanged={() => {
                if (directionsRenderRef.current) {
                  const newLine_geom_object = {
                    start: {
                      name: directionsDetails.startAddress,
                      coordinates: {
                        lat: directionsDetails.origin?.lat,
                        lng: directionsDetails.origin?.lng
                      }
                    },
                    end: {
                      name: directionsDetails.endAddress,
                      coordinates: {
                        lat: directionsDetails.destination?.lat,
                        lng: directionsDetails.destination?.lng
                      }
                    },
                    waypoints: getWaypoints(
                      directionsRenderRef.current.state.directionsRenderer
                        .directions.routes[0].legs[0].steps
                    ),
                    overview_polyline:
                      directionsRenderRef.current.state.directionsRenderer
                        .directions.routes[0].overview_polyline
                  };

                  setDirectionsDetails(prev => {
                    return {
                      ...prev,
                      multiPolygon: getMultiPolygonStr(
                        directionsRenderRef.current.state.directionsRenderer
                          .directions.routes[0].overview_path,
                        directionsDetails.buffer
                      ),
                      polyPath:
                        directionsRenderRef.current.state.directionsRenderer
                          .directions.routes[0].overview_path,
                      line_geom: JSON.stringify(
                        JSON.stringify(newLine_geom_object)
                      )
                    };
                  });
                }
              }}
            />
          )}

          {directionsDetails.multiPolygon && (
            <Polygon
              paths={wktToCoordinates(directionsDetails.multiPolygon)}
              options={poly_options}
            />
          )}
        </Map>

        <ConfirmationDialog
          toggle={discard}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "Success",
              showConfirmButton: false,
              timer: 3000,
              onCLose: () => {
                setRedirect(true);
              }
            })
          }
        />
      </div>
    </AccessControl>
  );
};

export { RouteGeofence, getDirections };
