import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  makeStyles
} from "@material-ui/core";
import { UnfoldMore } from "@material-ui/icons";

const tableHeadStyles = makeStyles({
  root: {
    fontWeight: 600,
    "& .MuiTableCell-root": {
      letterSpacing: 0.25,
      fontWeight: 600
    }
  },
  cellRoot: {
    paddingLeft: "18px !important",
    paddingTop: "16px !important",
    paddingBottom: "16px !important"
  }
});

const headerCells = [
  {
    id: "datesent",
    numeric: false,
    disablePadding: true,
    label: "Created Date"
  },
  {
    id: "email_to",
    numeric: false,
    disablePadding: true,
    label: "Recipients"
  },
  {
    id: "issent",
    numeric: true,
    disablePadding: true,
    label: "Status"
  }
];

const CustomTableHead = props => {
  const styles = tableHeadStyles();
  const { handleSort, sort = { field: "datesent", direction: "desc" } } = props;

  return (
    <TableHead classes={{ root: styles.root }}>
      <TableRow>
        {headerCells.map(cell => {
          return (
            <TableCell
              classes={{ root: styles.cellRoot }}
              padding={cell.disablePadding ? "none" : "default"}
              key={cell.id}
              align={cell.id === "actions" ? "center" : "left"}
            >
              {cell.label !== "" && cell.label !== "Action" ? (
                <TableSortLabel
                  active={sort.field === cell.id}
                  direction={sort.field === cell.id ? sort.direction : "asc"}
                  IconComponent={UnfoldMore}
                  onClick={() => handleSort(cell.id, sort.direction)}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
