import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_SERVICE_TYPES,
  GET_VEHICLE_TYPE_LIST
} from "../../graphql/Queries";
import _ from "lodash";
// this hook returns the value of  the services types or vehicle type list.
// this will depend on the tab that is active on the dashboards module. 
// '0' - OTTA/OTSA/OTD  && '1' - Demurrage.
const useFetchVehicleServiceType = activeTab => {
  const initialTypeFilter = { value: '0', label: "All" };
  const [filterTypes, setFilterTypes] = useState([initialTypeFilter]);
  // selected query.
  const qry = _.isEqual(activeTab, 0)
    ? GET_SERVICE_TYPES
    : GET_VEHICLE_TYPE_LIST;

  const { loading } = useQuery(qry, {
    onCompleted: data => {
      if (!data) return;
      if (_.isEqual(activeTab, 0))
        setFilterTypes([initialTypeFilter, ...data.get_service_types]);
      else {
        let tempVehicles = [];
        const { vehicle_types } = data.get_vehicle_types;
        vehicle_types.forEach(vehicle => tempVehicles.push({ value: vehicle.id, label: vehicle.name }));
        setFilterTypes([initialTypeFilter, ...tempVehicles]);
      }
    }
  });
  
  return { filterTypes };
};

export default useFetchVehicleServiceType;
