import React from "react";
import CircleGeofence from "./CircleGeofence";
import RectangleGeofence from "./RectangleGeofence";
import PolygonGeofence from "./PolygonGeofence";
// import coordinatesParser from "../Helper/coordinatesParser";
import { wktToCoordinates } from "../../../../utils/functions/coordinatesParserV2";
// import wkt from "wkt";

const Shape = props => {
  const {
    data: { type, geom, radius, location },
    options,
    toggleInfoWindow
  } = props;

  // const bounds = coordinatesParser(wkt.parse(geom), type);
  const bounds = wktToCoordinates(geom);

  // if (type === "polygon") {
  //   return (
  return (
    <PolygonGeofence
      paths={bounds}
      options={options}
      onMouseOver={toggleInfoWindow}
      onMouseOut={toggleInfoWindow}
    />
  );
  //   );
  // }

  // if (type === "rectangle") {
  //   return (
  //     <RectangleGeofence
  //       bounds={bounds}
  //       options={options}
  //       onMouseOver={toggleInfoWindow}
  //       onMouseOut={toggleInfoWindow}
  //     />
  //   );
  // }

  // return (
  //   <CircleGeofence
  //     center={{ lat: location.lat, lng: location.lon }}
  //     radius={radius}
  //     options={options}
  //     onMouseOver={toggleInfoWindow}
  //     onMouseOut={toggleInfoWindow}
  //   />
  // );
};

export default Shape;
