import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  ThumbUpOutlined as ThumbUpAltOutlinedIcon,
  ThumbDownOutlined as ThumbDownAltOutlinedIcon,
  Clear as ClearRoundedIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useEffect } from "react";

// inLine styling
const styles = {
  property_big: {
    width: "150px",
    color: "black",
    fontSize: "14px",
    fontWeight: "bold"
  },
  property_small: { width: "150px", color: "black", fontSize: "12px" },
  value_big: {
    width: "130px",
    // color: "white",
    color: "orange",
    fontSize: "14px",
    fontWeight: "bold"
  },
  value_small: { width: "130px", color: "orange", fontSize: "12px" }
};

const displayContent = (props, division) => {
  const contentArray = [];

  for (const property in props) {
    const contentObject = `${property}+${props[property]}`;
    contentArray.push(contentObject);
  }

  return contentArray.map((prop, index) => {
    const splitStr = prop.split("+");

    return (
      <div
        style={
          index === division
            ? { display: "flex", marginTop: "20px" }
            : { display: "flex" }
        }
      >
        <div
          style={index < division ? styles.property_big : styles.property_small}
        >
          {`${splitStr[0]}:`}
        </div>
        <div style={index < division ? styles.value_big : styles.value_small}>
          {splitStr[1] === "Approved" ? (
            <span style={{ color: "#9DF69C" }}>{splitStr[1]}</span>
          ) : splitStr[1] === "Disapproved" ? (
            <span style={{ color: "red" }}>{splitStr[1]}</span>
          ) : (
            splitStr[1]
          )}
        </div>
      </div>
    );
  });
};

const ConfirmationDialogTimeSheet = props => {
  const {
    toggle,
    title,
    fn = e => {},
    editPermission,
    content,
    yesBtn,
    noBtn,
    extraBtn,
    close
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle classes={{ root: classes.dialogHeader }}>
          <div className={classes.dialogHeaderDiv}>
            <Typography className={classes.dialogTitle}>{title}</Typography>
            <IconButton
              style={{ color: "white" }}
              onClick={() => handleClose()}
            >
              <ClearRoundedIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText>
            <div className={classes.divContentText}>
              {displayContent(content, 4)}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogAction }}>
          {content?.Status !== "Incomplete" && editPermission && (
            <Button
              disabled={content?.Status === "Disapproved"}
              id="noBtn"
              className={classes.circularButtonNo}
              startIcon={
                <ThumbDownAltOutlinedIcon style={{ fontSize: "13px" }} />
              }
              onClick={e => {
                fn(e);
                handleClose();
              }}
            >
              {noBtn}
            </Button>
          )}
          {content?.Status !== "Incomplete" && editPermission  && (
            <Button
              disabled={content?.Status === "Approved"}
              id="yesBtn"
              className={classes.circularButtonYes}
              startIcon={
                <ThumbUpAltOutlinedIcon style={{ fontSize: "13px" }} />
              }
              onClick={e => {
                fn(e);
                handleClose();
              }}
            >
              {yesBtn}
            </Button>
          )}
          {content?.Status !== "For Approval" && editPermission  &&
            content?.Status !== "Incomplete" && (
              <Button
                disabled={content?.Status === "For Approval"}
                id="extraBtn"
                className={classes.circularButtonYes}
                onClick={e => {
                  fn(e);
                  handleClose();
                }}
              >
                {extraBtn}
              </Button>
            )}
          {content?.Status === "Incomplete" && (
            <Typography style={{ color: "red", textAlign: "center" }}>
              Please inform the Driver to complete this Timesheet
            </Typography>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    borderRadius: 12,
    width: "calc(40% - 150px)"
    // paddingBottom: 13,
    // paddingRight: 13
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    color: "white",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  dialogContent: {
    padding: "30px 0px"
  },
  // divContentText: {
  //   display: "flex",
  //   justifyContent: "space-evenly"
  // },
  divContentText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  dialogAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "0 0 30px 0"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 24px"
    // display: "flex",
    // justifyContent: "space-between"
  },
  dialogHeaderDiv: {
    display: "flex",
    justifyContent: "space-between"
  },
  contentTitle: {
    fontWeight: "bold"
  },
  content: {
    color: theme.palette.primary.main
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    color: theme.palette.primary.main,
    width: 110,
    fontSize: 14,
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.8",
      color: "white",
      backgroundColor: theme.palette.primary.main
    }
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "white",
    width: 110,
    fontSize: 14,
    lineHeight: "15px",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      opacity: "0.8",
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default ConfirmationDialogTimeSheet;
