/* eslint-disable eqeqeq */
/* eslint-disable func-names */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Box
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import {
  Brightness1,
  Event,
  Help as HelpIcon,
  Cached as CachedRoundedIcon
} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Cookie from "js-cookie";
import _ from "lodash";
import Swal from "sweetalert2";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { vehicleIcons, Helper } from "../../../../Utils";
import {
  GET_VEHICLE_TYPE_LIST,
  GET_VEHICLE_INFOS,
  // GET_DEVICES,
  GET_ADMIN_CLIENTS_WO_GROUPS
} from "../../../../../graphql/Queries";
import {
  EDIT_VEHICLE,
  EDIT_DEVICE,
  SYNC_VEHICLE_ODOMETER
  // ADD_NOTIFICATION
} from "../../../../../graphql/Mutations";
import GroupsTable from "./GroupsTable";
import Battery from "../../../../../utils/Battery";
import AddLogs from "../../../../../utils/functions/AddLogs";
import validate from "../../../../../utils/validation";
import SwitchHelper from "../../../../../utils/UI/Switch/SwitchHelper";
import SvgIcons from "../../../../../assets/SvgIcons/SvgIcons";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import UnpairingDialog from "../../../../../utils/modals/UnpairingDialog";
import PairDeviceModal from "../../../../../utils/modals/PairDeviceModal";
import {
  getBookingsOfVehicle,
  resetTrips
} from "../../../../../utils/functions/unpairingFunctions";
import SearchContext from "../../../../../context/SearchContext";

const filterOptions = (options, keyword, key = "name") => {
  const optionsObj = options.reduce((accum, current) => {
    if (accum[current.id]) return accum;
    if (!options.some(option => option.id === current.id)) {
      return accum;
    }
    return {
      ...accum,
      ...{ [current.id]: current.name }
    };
  }, {});
  const filteredOptions = Object.keys(optionsObj).map(optionKey => ({
    id: optionKey,
    name: optionsObj[optionKey]
  }));
  return filteredOptions.filter(option =>
    option[key]?.toLowerCase().includes(keyword.toLowerCase())
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"

    // "& .MuiAlert-root": {
    //   padding: "2px 5px"
    // }
  },
  paper: {
    padding: "24px",
    width: "100%",
    boxSizing: "border-box"
  },
  red: {
    color: "red"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  odom_text: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  odom: {
    padding: 0
  },
  odomAlert: {
    width: "100%",
    border: "2px solid #4caf50",
    color: "#4caf50"
  },
  odomAlertModal: {
    padding: "2px 5px",
    border: "2px solid #4caf50",
    color: "#4caf50"
  },
  odometerContainer: {
    paddingBottom: 13,
    paddingRight: 13,
    borderRadius: 12
  },

  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  },
  swal: {
    zIndex: 10000000000
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    margin: "0 0 0 14px"
  },
  alertOdom: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "100%"
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

const autoUnpairCondition = [
  {
    id: process.env.REACT_APP_STATUS_ARRIVAL_AT_PORT,
    label: "Arrival at Port"
  },
  {
    id: process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
    label: "Arrival at Dropoff Point"
  },
  {
    id: process.env.REACT_APP_STATUS_ARRIVAL_AT_CONTAINER_YARD,
    label: "Arrival at Container Yard"
  }
];

const EditVehicle = props => {
  const classes = useStyles();
  const odomRef = useRef();
  const bookingRef = useRef();
  const newDeviceRef = useRef();
  const contextData = useContext(SearchContext);
  const { vehicle_data, history, queryVars, setFormEdit } = props;
  const { state: locationState } = history.location;
  const [vehicle, setVehicle] = useState();
  const [initialState] = useState(_.cloneDeep(vehicle_data));
  const { data: vtypes } = useQuery(GET_VEHICLE_TYPE_LIST);
  const [openConfirm, setOpenConfirm] = useState(false);
  const user = JSON.parse(Cookie.get("user"));
  const [edit, setEdit] = useState(null);
  const [helpers, setHelper] = useState({
    plateno: null,
    vehicle_type_id: null,
    avefuelconsumption: null,
    bodyno: null,
    odometer: null,
    auto_unpair: null,
    make: null,
    area: null,
    heads: null,
    region: null,
    assignment: null,
    channel_count: null
  });
  const [hauler, setHauler] = useState({
    id: vehicle_data?.get_vehicle?.client_id || null,
    name: vehicle_data?.get_vehicle?.client_name || null
  });
  const { setSearchKeyword, searchKeyword } = contextData;
  const [haulerSelect, setHaulerSelect] = useState([]);
  const [toLink, setToLink] = useState([]);
  const [toUnlink, setToUnlink] = useState([]);
  const [odometer, setOdometer] = useState(false);
  const [odometerValue, setOdometerValue] = useState({
    odom:
      (vehicle_data?.get_vehicle?.device_info?.device_status
        ?.distancetraveled || 0) / 1000,
    updatedOdom: 0,
    newOdom: 0
  });
  const [deviceModal, toggleDeviceModal] = useState(false);
  const [unpairModal, setUnpairModal] = useState(false);
  const [autoUnpair, setAutoUnpair] = useState(
    vehicle_data?.get_vehicle?.auto_unpair
      ? vehicle_data?.get_vehicle?.auto_unpair
      : null
  );
  const [autoUnpairToggle, setAutoUnpairToggle] = useState(
    vehicle_data?.get_vehicle?.auto_unpair ? true : false
  );

  const [updateDevicePartner] = useMutation(EDIT_DEVICE);
  const [editOdometer] = useMutation(SYNC_VEHICLE_ODOMETER, {
    onCompleted(data) {
      if (!data.sync_odometer.success) {
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
          timer: 1500,
          showConfirmButton: false
        });
      }
    }
  });

  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const isPrimaryClient =
    +user.client.id === +vehicle_data?.get_vehicle?.client_id;
  const initState =
    initialState.get_vehicle?.device_info?.device_status?.distancetraveled;

  const [get_hauler] = useLazyQuery(GET_ADMIN_CLIENTS_WO_GROUPS, {
    onCompleted: haulerData => {
      const temp = [];
      haulerData.get_clients.clients.forEach(element => {
        temp.push({
          id: element.id,
          name: element.name
        });
      });
      setHaulerSelect(temp);
    }
  });

  React.useEffect(() => {
    if (hauler.id) {
      get_hauler({
        variables: {
          first: 8,
          not: [{ field: "clients.id", value: hauler.id }]
        }
      });
    } else {
      get_hauler({
        variables: {
          first: 8
        }
      });
    }
  }, [hauler]);

  // const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [editMutation] = useMutation(EDIT_VEHICLE, {
    refetchQueries: () => {
      return [
        {
          query: GET_VEHICLE_INFOS,
          variables: {
            condition: {
              field: "plateno",
              value: vehicle.get_vehicle.plateno
            }
          }
        }
      ];
    },
    onCompleted(data) {
      // const edit_vehicle = data;
      if (data.edit_vehicle.success) {
        // const {
        //   plateno,
        //   device_id,
        //   id,
        //   vehicle_type_id
        // } = data.edit_vehicle.vehicle;
        if (toLink.length > 0) {
          AddLogs(
            "Admin - Vehicles/CV",
            "link_vehicle",
            `${vehicle.get_vehicle.plateno} to ${toLink
              .toString()
              .replace(/,/g, ", ")}`
          );
        }
        if (toUnlink.length > 0) {
          AddLogs(
            "Admin - Vehicles/CV",
            "unlink_vehicle",
            `${vehicle.get_vehicle.plateno} to ${toUnlink
              .toString()
              .replace(/,/g, ", ")}`
          );
        }
        Swal.fire({
          icon: "success",
          text: "Update successful",
          timer: 1500,
          showConfirmButton: false
        });
        if (+vehicle.get_vehicle.device_id !== 0) {
          updateDevicePartner({
            variables: {
              id: vehicle.get_vehicle.device_id,
              device: {
                devicealias: vehicle.get_vehicle.device_info.devicealias,
                group_ids: vehicle.get_vehicle.group_ids,
                // device_config: {
                //   //   underrepair: underRepair
                // },
                vehicle_info: {
                  id: vehicle.get_vehicle.id,
                  device_id: vehicle.get_vehicle.device_id,
                  plateno: vehicle.get_vehicle.plateno,
                  vehicle_type_id: vehicle.get_vehicle.vehicle_type_id
                }
              }
            }
          });
        }

        if (
          +initialState.get_vehicle?.device_id &&
          !+vehicle.get_vehicle.device_id
        ) {
          // Before: with device, after: no device
          AddLogs("Admin - Vehicles/CV", "unpaired_device", [
            initialState.get_vehicle.device_info?.name,
            initialState.get_vehicle.plateno
          ]);

          // addNotif({
          //   variables: {
          //     notification: {
          //       client_id: [vehicle.get_vehicle.client_id],
          //       vehicle_plate_no: initialState.get_vehicle.plateno,
          //       datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          //       notification: `GPS Device ${initialState.get_vehicle.device_info?.name} was unpaired to Vehicle/CV ${initialState.get_vehicle.plateno}`,
          //       category: "User Activity",
          //       is_archived: false,
          //       creator: "Tmsuite-web",
          //       group_ids: vehicle.get_vehicle.group_ids,
          //       group_names: vehicle.get_vehicle.group_names
          //     }
          //   }
          // });
        } else if (
          +initialState.get_vehicle?.device_id !==
          +vehicle.get_vehicle.device_id
        ) {
          // Change device
          AddLogs("Admin - Vehicles/CV", "paired_device", [
            vehicle.get_vehicle.device_info.name,
            initialState.get_vehicle.plateno
          ]);

          // addNotif({
          //   variables: {
          //     notification: {
          //       client_id: [vehicle.get_vehicle.client_id],
          //       vehicle_plate_no: initialState.get_vehicle.plateno,
          //       datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          //       notification: `GPS Device ${vehicle.get_vehicle.device_info.name} was paired to Vehicle/CV ${initialState.get_vehicle.plateno}`,
          //       category: "User Activity",
          //       is_archived: false,
          //       creator: "Tmsuite-web",
          //       group_ids: vehicle.get_vehicle.group_ids,
          //       group_names: vehicle.get_vehicle.group_names
          //     }
          //   }
          // });
        }
        
        setTimeout(() => {
          if (locationState?.source) {
            // set values again for vehicles table filter after saving
            history.push({
              pathname: locationState.source,
              vars: history?.location?.state?.vars,
              vTypeName: history?.location?.state?.vTypeName,
              group: history?.location?.state?.group,
              isLogistics: history?.location?.state?.isLogistics
            });
          } else {
            // set values again for vehicles table filter after saving
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_VEHICLES_MODULE,
                  vars: history?.location?.state?.vars,
                  vTypeName: history?.location?.state?.vTypeName,
                  group: history?.location?.state?.group,
                  isLogistics: history?.location?.state?.isLogistics
                }
              }
            });
          }
        }, 1500);
      } else {
        let temp = { ...helpers };
        _.forEach(data.edit_vehicle.error, er => {
          temp = {
            ...helpers,
            [er.field]: er.message
          };

          if (er.message.includes("bodyno")) {
            temp = {
              ...helpers,
              bodyno: er.message
            };
          }
        });
        setHelper(temp);

        // let temp = { ...helpers };
        // temp = {
        //   ...helpers,
        //   plateno: <Helper message="Plate number already exists." />
        // };
        // setHelper(temp);
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        // title: "Success",
        text: "Something went wrong",
        showConfirmButton: false
      });
    },
    update: (cache, { data: v_data }) => {
      const { edit_vehicle } = v_data;

      let c = false;

      try {
        c = cache.readQuery({
          query: GET_VEHICLE_INFOS,
          variables: queryVars
        });

        if (c) {
          const v_index = _.findIndex(c.get_vehicles.vehicles, v => {
            return v.id === edit_vehicle.vehicle.id;
          });

          if (v_index !== -1) {
            c.get_vehicles.vehicles[v_index] = edit_vehicle.vehicle;
          }

          cache.writeQuery({
            query: GET_VEHICLE_INFOS,
            variables: queryVars,
            data: c
          });
        }
      } catch {} // eslint-disable-line
    }
  });

  React.useMemo(() => {
    if (initialState) {
      if (user.client.id !== initialState.get_vehicle.client_id) {
        if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
          setEdit(true);
        } else {
          setEdit(false);
        }
      } else if (
        user.user_level_id === process.env.REACT_APP_WTI_ADMIN ||
        user.client.id === initialState.get_vehicle.client_id
      ) {
        setEdit(true);
      } else {
        setEdit(false);
      }
    }
  }, [edit]);

  const handleBlur = e => {
    let temp = {};
    if (
      vehicle.get_vehicle.avefuelconsumption < 0 ||
      vehicle.get_vehicle.avefuelconsumption > 99999
    ) {
      temp = {
        ...helpers,
        avefuelconsumption: "Invalid value. Min 0, Max: 99,999"
      };
      setHelper(temp);
    } else {
      temp = {
        ...helpers,
        avefuelconsumption: null
      };
      setHelper(temp);
    }

    const { name, value } = e.target;
    let error = "";
    switch (name) {
      case "plateno":
        // accept special characters
        // error = !validate("alphanumeric_default", value) ? (
        //   <Helper message="Alphanumeric characters only" />
        // ) : value === "" ? (
        //   <Helper message="This field is required" />
        // ) : (
        //   ""
        // );

        error = !value && "This field is required";
        break;
      case "odometer":
        error = validate("number", value) ? "Numeric characters only" : "";
        break;
      case "make":
        {
          error = value.length > 80 && "Only 80 characters allowed";
        }
        break;
      case "area":
        {
          error = value.length > 100 && "Only 100 characters allowed";
        }
        break;
      case "heads":
        {
          error = value.length > 150 && "Only 150 characters allowed";
        }
        break;
      case "region":
        {
          error = value.length > 80 && "Only 80 characters allowed";
        }
        break;
      case "assignment":
        {
          error = value.length > 100 && "Only 100 characters allowed";
        }
        break;
      // case "channel_count":
      //   const channelCountTemp = parseInt(value);
      //   if (
      //     (channelCountTemp <= 0 || channelCountTemp >= 9) &&
      //     value !== null
      //   ) {
      //     error = "This field only accepts numbers 1 to 8";
      //   }
      //   break;
      default:
        break;
    }
    setHelper({ ...helpers, [name]: error });
  };

  const validateData = () => {
    let valid = true;
    let temp = {};
    if (
      vehicle.get_vehicle.avefuelconsumption < 0 ||
      vehicle.get_vehicle.avefuelconsumption > 99999
    ) {
      temp = {
        ...temp,
        avefuelconsumption: (
          <Helper message="Invalid value. Min 0, Max: 99,999" />
        )
      };
      valid = false;
    }
    // if (/[^a-zA-Z0-9 ]/.test(vehicle.get_vehicle.plateno)) {
    //   temp = {
    //     ...temp,
    //     plateno: <Helper message="Alpha numeric character only" />
    //   };
    //   valid = false;
    // }
    // if (
    //   vehicle.get_vehicle.plateno === null ||
    //   vehicle.get_vehicle.plateno === ""
    // ) {
    //   if (vehicle.get_vehicle.plateno.length > 15) {
    //     temp = {
    //       ...temp,
    //       plateno: <Helper message="Only 15 characters is allowed" />
    //     };
    //   } else {
    //     temp = {
    //       ...temp,
    //       plateno: <Helper message="This field is required" />
    //     };
    //   }
    //   if (valid) valid = false;
    // }

    if (_.isEmpty(vehicle.get_vehicle.plateno)) {
      temp = {
        ...temp,
        plateno: "This field is required"
      };
      valid = false;
    } else if (vehicle.get_vehicle.plateno.length > 50) {
      temp = {
        ...temp,
        plateno: "Only 50 characters is allowed"
      };
      valid = false;
    }
    // else if (!validate("alphanumeric_default", vehicle.get_vehicle.plateno)) {
    //   temp = {
    //     ...temp,
    //     plateno: <Helper message="Alphanumeric characters only" />
    //   };
    //   valid = false;
    // }
    else {
      temp = {
        ...temp,
        plateno: ""
      };
      valid = true;
    }

    if (autoUnpairToggle && autoUnpair === null) {
      temp = {
        ...temp,
        auto_unpair: "This field is required!"
      };
      valid = false;
    } else {
      temp = {
        ...temp,
        auto_unpair: ""
      };
    }
    // if (
    //   (vehicle.get_vehicle.channel_count <= 0 ||
    //     vehicle.get_vehicle.channel_count >= 9) &&
    //   vehicle.get_vehicle.channel_count !== null
    // ) {
    //   temp = {
    //     ...temp,
    //     channel_count: "This field only accepts numbers 1 to 8"
    //   };
    //   if (valid) valid = false;
    // }

    if (vehicle.get_vehicle.make?.length > 80) {
      temp = {
        ...temp,
        make: "Only 80 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicle.get_vehicle.area?.length > 100) {
      temp = {
        ...temp,
        area: "Only 100 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicle.get_vehicle.heads?.length > 150) {
      temp = {
        ...temp,
        heads: "Only 150 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicle.get_vehicle.region?.length > 80) {
      temp = {
        ...temp,
        region: "Only 80 characters allowed"
      };
      if (valid) valid = false;
    }

    if (vehicle.get_vehicle.assignment?.length > 100) {
      temp = {
        ...temp,
        assignment: "Only 100 characters allowed"
      };
      if (valid) valid = false;
    }

    // if (valid) valid = false;
    // if (vehicle.get_vehicle.plateno.length > 15) {
    //   temp = {
    //     ...temp,
    //     plateno: <Helper message="Only 15 characters is allowed" />
    //   };
    //   if (valid) valid = false;
    // }
    setHelper(temp);
    return valid;
  };
  useEffect(() => {
    if (vehicle_data) {
      setVehicle(vehicle_data);
    }
  }, [vehicle_data]);

  const year = [];
  for (let index = 1980; index <= 2030; index += 1) {
    year.push(index);
  }

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^a-zA-Z0-9]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (_.isEqual(vehicle, initialState)) setFormEdit(false);
    else setFormEdit(true);
  }, [vehicle]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    let temp = [];
    if (name === "plateno") {
      temp = {
        get_vehicle: {
          ...vehicle.get_vehicle,
          [name]: _.isEmpty(value) ? null : value.toUpperCase()
        }
      };
    } else if (name === "avefuelconsumption") {
      temp = {
        get_vehicle: {
          ...vehicle.get_vehicle,
          [name]: _.isEmpty(value) ? 0 : +value
        }
      };
    } else if (name === "channel_count") {
      temp = {
        get_vehicle: {
          ...vehicle.get_vehicle,
          [name]: +value
        }
      };
    } else {
      temp = {
        get_vehicle: {
          ...vehicle.get_vehicle,
          [name]: _.isEmpty(value) ? null : value
        }
      };
    }

    setVehicle(temp);
    // setFormEdit(_.isEqual(temp, initialState));
  };

  const gpsStatus = vehicle => {
    const s = vehicle?.device_info?.device_status?.status_code?.toLowerCase();
    let data = {};
    switch (s) {
      case "available":
      case "online":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#34CB1b" }}
              >
                Online GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#34CB1b"
        };
        break;
      case "no gps":
        data = {
          label: "No GPS",
          color: "#e40000"
        };
        break;
      case "delayed":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#FEC702" }}
              >
                Delayed Reporting
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#FEC702"
        };
        break;
      case "offline":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#C4C4C4" }}
              >
                Offline GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#C4C4C4"
        };
        break;
      default:
        data = {
          label: "No GPS",
          color: "#e40000"
        };
      // data = {
      //   label: (
      //     <Fragment>
      //       <p
      //         className={classes.tooltipTitle}
      //         style={{ fontWeight: "bold", color: "#34CB1b" }}
      //       >
      //         Active GPS
      //       </p>
      //       <p className={classes.tooltipTitle}>{`Last Report: ${moment(
      //         vehicle.device_info.device_status.reportstamp
      //       ).format("lll")}`}</p>
      //     </Fragment>
      //   ),
      //   color: "#34CB1b"
      // };
    }
    return data;
  };

  const odomOnChangeHandler = e => {
    const { name, value } = e.target;
    let error = "";
    switch (name) {
      case "odometer":
        if (_.isEqual(value, _.toString(0)) && !_.isEmpty(value)) {
          error = "Odometer value is not acceptable";
          // (
          //   <Helper
          //     message={
          //       <span style={{ fontSize: "13px", marginLeft: 5 }}>
          //         Odometer value is not acceptable
          //       </span>
          //     }
          //   />
          // );
        } else if (validate("number", value) && !_.isEmpty(value)) {
          error = "Numeric characters only";
          // (
          //   <Helper
          //     message={
          //       <span style={{ fontSize: "13px", marginLeft: 5 }}>
          //         Numeric characters only
          //       </span>
          //     }
          //   />
          // );
        } else if (!validate("decimal", value) && !_.isEmpty(value)) {
          error = "Only whole numbers are accepted for the odometer.";
          // (
          //   <Helper
          //     message={
          //       <span style={{ fontSize: "13px", marginLeft: 5 }}>
          //         Only whole numbers are accepted for the odometer.
          //       </span>
          //     }
          //   />
          // );
        } else {
          error = null;
        }
        // error = validate("number", value) ? (
        //   <Helper message={<span style={{ fontSize: "13px", marginLeft: 5 }}>Numeric characters only</span>} />
        // ) : (
        //   (error = !validate("decimal", value) ? (
        //     <Helper message={<span style={{ fontSize: "13px", marginLeft: 5 }}>Only whole numbers are accepted for the odometer.</span>}/>
        //   ) : null)
        // );
        break;
      default:
        break;
    }
    setHelper({ ...helpers, [name]: error });
    setOdometerValue({ ...odometerValue, newOdom: value });
    // odomRef.current = value;
  };
  // set the current value of the odometer field.
  // const odomOnChangeHandler = e =>
  //   setOdometerValue({ ...odometerValue, newOdom: e.target.value });

  const handleVehicleOdo = () => {
    if (helpers.odometer === null && parseInt(odometerValue.odom) !== 0) {
      // setOdometerValue(odomRef.current.value);
      setOdometer(false);
      editOdometer({
        variables: {
          device_id: vehicle.get_vehicle.device_id,
          odometer: odometerValue.newOdom * 1000
        },
        onCompleted: data => {
          if (data.sync_odometer.success) {
            setOdometerValue({
              ...odometerValue,
              updatedOdom: data.sync_odometer.distance_travelled
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went wrong",
              showConfirmButton: false
            });
          }
        },
        onError: () => {
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
            showConfirmButton: false
          });
        }
      });
    }
  };

  const haulerInputRef = React.useRef(null);

  const getBookings = async () => {
    // Get all bookings(ongoing and future)
    const { device_id, plateno } = initialState.get_vehicle;
    const trips = await getBookingsOfVehicle(device_id, plateno);
    if (trips.error) {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false,
        customClass: {
          container: `${classes.swal}`
        }
      });
      return;
    }

    bookingRef.current = trips;
    return trips;
  };

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false
    });
  }, []);

  const saveHandler = async () => {
    let vehicleInfos = vehicle.get_vehicle;
    const variables = {
      id: vehicleInfos.id,
      vehicle: {
        // ...vehicle.get_vehicle,
        avefuelconsumption: Number(vehicleInfos.avefuelconsumption),
        client_id: hauler.id,
        auto_unpair: autoUnpair,
        plateno: vehicleInfos.plateno?.toString()?.trim(),
        remarks: vehicleInfos.remarks,
        device_id: vehicleInfos.device_id,
        vehicle_type_id: vehicleInfos.vehicle_type_id,
        icon: vehicleInfos.icon,
        avefuelconsumption: vehicleInfos.avefuelconsumption,
        group_ids: vehicleInfos.group_ids,
        under_repair: vehicleInfos.under_repair,
        has_fuel_sensor: vehicleInfos.has_fuel_sensor,
        has_temp_sensor: vehicleInfos.has_temp_sensor,
        model: vehicleInfos.model,
        manufacture_year: vehicleInfos.manufacture_year,
        expiration: vehicleInfos.expiration,
        bodyno: vehicleInfos.bodyno,
        not_in_use: vehicleInfos.not_in_use,
        door_status: vehicleInfos.door_status,
        door_status_stamp: vehicleInfos.door_status_stamp,
        ignition_status: vehicleInfos.ignition_status,
        ignition_status_stamp: vehicleInfos.ignition_status_stamp,
        make: vehicleInfos.make,
        area: vehicleInfos.area,
        heads: vehicleInfos.heads,
        region: vehicleInfos.region,
        assignment: vehicleInfos.assignment,
        personnel_id: vehicleInfos.personnel_id,
        has_vss_camera: vehicleInfos.has_vss_camera,
        channel_count: vehicleInfos.channel_count
      }
    };
    const initialPartner = initialState?.get_vehicle?.group_names || [];
    const modifiedPartner = variables?.vehicle?.group_names || [];

    // _.unset(variables.vehicle, ["id"]);
    // _.unset(variables.vehicle, ["device_info"]);
    // _.unset(variables.vehicle, ["status_code"]);
    // _.unset(variables.vehicle, ["client_name"]);
    // _.unset(variables.vehicle, ["status_code_id"]);
    // _.unset(variables.vehicle, ["vehicle_type"]);
    // _.unset(variables.vehicle, ["group_names"]);
    // _.unset(variables.vehicle, ["trip_numbers"]);
    // _.unset(variables.vehicle, ["personnel_name"]);

    AddLogs("Admin - Vehicles/CV", "update", variables.vehicle.plateno);
    await setToUnlink(
      initialPartner.filter(function(val) {
        return modifiedPartner.indexOf(val) == -1;
      })
    );
    await setToLink(
      modifiedPartner.filter(function(val) {
        return initialPartner.indexOf(val) == -1;
      })
    );
    editMutation({
      variables: variables
    });
    setOpenConfirm(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <Typography>
            All fields with <span style={{ color: "red" }}>*</span> are required
          </Typography>
        </Grid>
        <br />
        <Grid container spacing={1}>
          {vehicle && (
            <Fragment>
              <Grid item xs={3}>
                <Typography variant="h6">Vehicle / CV Information</Typography>
                <List key={vehicle.plateno}>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      // disableTypography
                      primary={
                        <span>
                          Plate/CV Number<span className={classes.red}>*</span>
                        </span>
                      }
                      secondary={
                        (edit &&
                          user.user_level_id ===
                            process.env.REACT_APP_WTI_ADMIN) ||
                        user.client.id === vehicle.get_vehicle.client_id ? (
                          <TextField
                            value={vehicle.get_vehicle.plateno || null}
                            name="plateno"
                            type="text"
                            placeholder="Enter Plate/CV Number"
                            fullWidth
                            // onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            // helperText={helpers.plateno}
                          />
                        ) : (
                          vehicle.get_vehicle.plateno
                        )
                      }
                    />
                  </ListItem>
                  {helpers.plateno && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.plateno}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        <Fragment>
                          <span>Vehicle Icon</span>
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Fragment>
                      }
                      secondary={
                        !edit ? (
                          vehicle_data.get_vehicle.icon.split(".")[0]
                        ) : (
                          <Select
                            value={vehicle.get_vehicle.icon}
                            fullWidth
                            renderValue={selected => selected.split(".")[0]}
                            onChange={handleInputChange}
                            name="icon"
                          >
                            {vehicleIcons.map(icon => (
                              <MenuItem key={icon.value} value={icon.value}>
                                <svg
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  // viewBox="0 0 16.96 16.96"
                                  width="45px"
                                  height="45px"
                                >
                                  <g
                                    fill="#00000"
                                    stroke={SvgIcons.stroke}
                                    strokeWidth={0.3}
                                  >
                                    <path
                                      d={
                                        SvgIcons[`${icon.value.split(".")[0]}`]
                                          .path
                                      }
                                    />
                                  </g>
                                </svg>
                                {/* <img
                                  src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${icon.value}`}
                                  alt=""
                                  width={25}
                                  height={25}
                                  style={{marginRight: "15px"}}
                                /> */}
                                <ListItemText primary={icon.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }
                    />
                    <ListItemSecondaryAction>
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        // viewBox="0 0 16.96 16.96"
                        width="45px"
                        height="45px"
                        style={{ margin: -29 }}
                      >
                        <g
                          fill="#00000"
                          stroke={SvgIcons.stroke}
                          strokeWidth={0.3}
                        >
                          <path
                            d={
                              SvgIcons[
                                `${vehicle.get_vehicle.icon.split(".")[0]}`
                              ].path
                            }
                          />
                        </g>
                      </svg>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${vehicle.get_vehicle.icon}`}
                        width={25}
                        height={25}
                        alt=""
                      /> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        <Fragment>
                          <span>Hauler</span>
                          <span style={{ color: "red" }}>*</span>
                        </Fragment>
                      }
                      // secondary={vehicle.get_vehicle.client_name || null}
                      secondary={
                        edit &&
                        user.user_level_id ===
                          process.env.REACT_APP_WTI_ADMIN ? (
                          <Autocomplete
                            filterOptions={options =>
                              filterOptions(
                                options,
                                haulerInputRef.current.value
                              )
                            }
                            getOptionSelected={(o, v) => o.id === v.id}
                            options={[hauler, ...haulerSelect] || []}
                            getOptionLabel={option =>
                              (hauler && option.name) || ""
                            }
                            renderOption={option =>
                              (hauler && option.name) || ""
                            }
                            getOptionDisabled={option => option === hauler}
                            // getOptionSelected={(o, v) => {
                            //   if (o.name === v.name) return o.name;
                            //   return {};
                            // }}
                            style={{ width: "100%" }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                inputRef={haulerInputRef}
                                margin="normal"
                                placeholder="Select Hauler"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: "black", fontWeight: "bold" }
                                }}
                                onChange={e => {
                                  get_hauler({
                                    variables: {
                                      first: 8,
                                      keyword: e.target.value
                                    }
                                  });
                                }}
                              />
                            )}
                            value={hauler}
                            onChange={(e, nv) => {
                              setHauler(nv);
                            }}
                            disableClearable
                          />
                        ) : (
                          vehicle.get_vehicle.client_name || null
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        <Fragment>
                          <span>Vehicle Type</span>
                          <span style={{ color: "red" }}>*</span>
                        </Fragment>
                      }
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.vehicle_type
                        ) : (
                          <TextField
                            select
                            value={
                              vtypes ? vehicle.get_vehicle.vehicle_type_id : ""
                            }
                            fullWidth
                            defaultValue=""
                            onChange={e => {
                              const index = _.findIndex(
                                vtypes.get_vehicle_types.vehicle_types,
                                vt => {
                                  return vt.id === e.target.value;
                                }
                              );

                              setVehicle({
                                get_vehicle: {
                                  ...vehicle.get_vehicle,
                                  vehicle_type_id: e.target.value,
                                  vehicle_type:
                                    vtypes.get_vehicle_types.vehicle_types[
                                      index
                                    ].name
                                }
                              });

                              if (autoUnpairToggle) {
                                setAutoUnpairToggle(false);
                                setAutoUnpair(null);
                              }
                              setFormEdit(true);
                            }}
                          >
                            {vtypes ? (
                              vtypes.get_vehicle_types.vehicle_types.map(
                                vtype => (
                                  <MenuItem key={vtype.id} value={vtype.id}>
                                    {vtype.name}
                                  </MenuItem>
                                )
                              )
                            ) : (
                              <MenuItem value="" />
                            )}
                          </TextField>
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="KML (Standard)"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.avefuelconsumption
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.avefuelconsumption}
                            name="avefuelconsumption"
                            type="number"
                            min={0}
                            max={99999}
                            inputProps={{ min: 0, max: 99999 }}
                            fullWidth
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            helperText={helpers.avefuelconsumption}
                          />
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Registry Expiration"
                      secondary={
                        !edit ? (
                          moment(vehicle_data.get_vehicle.expiration).format(
                            "DD/MM/YYYY"
                          ) || "-"
                        ) : (
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              format="MM/DD/YYYY"
                              InputProps={{
                                placeholder: "Registration Expiration",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Event />
                                  </InputAdornment>
                                ),
                                name: "expiration"
                              }}
                              showTodayButton
                              value={vehicle.get_vehicle.expiration || null}
                              todayLabel="NOW"
                              onChange={date => {
                                setVehicle({
                                  get_vehicle: {
                                    ...vehicle.get_vehicle,
                                    expiration: moment(date).format(
                                      "YYYY-MM-DD"
                                    )
                                  }
                                });
                                setFormEdit(true);
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Model"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.model
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.model || ""}
                            name="model"
                            onChange={handleInputChange}
                            placeholder="Model"
                            fullWidth
                          />
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Body Number"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.bodyno
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.bodyno}
                            name="bodyno"
                            onChange={handleInputChange}
                            placeholder="Body Number"
                            fullWidth
                            helperText={helpers.bodyno}
                          />
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Manufacture Year"
                      secondary={
                        !edit ? (
                          vehicle_data.get_vehicle.manufacture_year
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.manufacture_year || ""}
                            name="manufacture_year"
                            onChange={handleInputChange}
                            placeholder="Manufacture Year"
                            fullWidth
                            select
                          >
                            {year.map(y => (
                              <MenuItem key={y} value={y.toString()}>
                                {y}
                              </MenuItem>
                            ))}
                          </TextField>
                        )
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Make"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.make
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.make}
                            name="make"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="Make"
                            fullWidth
                            // helperText={helpers.make}
                          />
                        )
                      }
                    />
                  </ListItem>
                  {helpers.make && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.make}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Area"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.area
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.area}
                            name="area"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="Area"
                            fullWidth
                            // helperText={helpers.are}
                          />
                        )
                      }
                    />
                  </ListItem>
                  {helpers.area && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.area}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Region"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.region
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.region}
                            name="region"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="Region"
                            fullWidth
                            // helperText={helpers.bodyno}
                          />
                        )
                      }
                    />
                  </ListItem>
                  {helpers.region && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.region}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Heads"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.heads
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.heads}
                            name="heads"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="Heads"
                            fullWidth
                            // helperText={helpers.bodyno}
                          />
                        )
                      }
                    />
                  </ListItem>
                  {helpers.heads && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.heads}
                    </Alert>
                  )}
                  <ListItem>
                    <ListItemText
                      // disableTypography
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Assignment"
                      secondary={
                        !edit ? (
                          vehicle.get_vehicle.assignment
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.assignment}
                            name="assignment"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder="Assignment"
                            fullWidth
                            // helperText={helpers.bodyno}
                          />
                        )
                      }
                    />
                  </ListItem>
                  {helpers.assignment && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.assignment}
                    </Alert>
                  )}
                  {/* <ListItem>
                    <ListItemText
                      // disableTypography
                      primary={
                        <InputLabel
                          style={{
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <HtmlTooltip
                            placement="top-start"
                            title="Turn on: This vehicle will be tagged as Under Repair"
                            // title={
                            //   <React.Fragment>
                            //     <Typography color="inherit">
                            //       Turn on: This vehicle will be tagged as Under
                            //       Repair
                            //     </Typography>
                            //   </React.Fragment>
                            // }
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </HtmlTooltip>
                          &nbsp;Under Repair&nbsp;
                          <Switch
                            size="small"
                            color="primary"
                            disabled={!edit}
                            checked={!!underRepair}
                            onChange={e => {
                              if (e.target.checked && notInUse) {
                                setUnderRepair(e.target.checked);
                                setnotInUse(!notInUse);
                              }
                              setUnderRepair(e.target.checked);
                            }}
                          />
                        </InputLabel>
                      }
                    />
                  </ListItem> */}
                  {/* <ListItem>
                    <ListItemText
                      // disableTypography
                      primary={
                        <InputLabel
                          style={{
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <HtmlTooltip
                            placement="top-start"
                            // title={
                            //   <React.Fragment>
                            //     <Typography color="inherit">
                            //       Turn on: This vehicle will be tagged as Not in use vehicle
                            //     </Typography>
                            //   </React.Fragment>
                            // }
                            title="Turn on: This vehicle will be tagged as Not in use vehicle"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </HtmlTooltip>
                          &nbsp; Not in use vehicle&nbsp;
                          <Switch
                            size="small"
                            color="primary"
                            disabled={!edit}
                            checked={!!notInUse}
                            onChange={e => {
                              if (e.target.checked && underRepair) {
                                setnotInUse(e.target.checked);
                                setUnderRepair(!underRepair);
                              }
                              setnotInUse(e.target.checked);
                              // setUnderRepair(e.target.checked);
                            }}
                          />
                        </InputLabel>
                      }
                    />
                  </ListItem> */}
                  {vehicle.get_vehicle.has_vss_camera !== 0 && <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            placement="top"
                            title="Provide the no. of selected channel/s for the VSS Live Cam (Max 8)"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </Tooltip>
                          <Typography>Selected Channel (VSS)</Typography>
                        </Box>
                      }
                      secondary={
                        <Select
                          displayEmpty
                          fullWidth
                          value={vehicle.get_vehicle.channel_count}
                          name="channel_count"
                          placeholder="Select channel"
                          onChange={handleInputChange}
                        >
                          {/* <MenuItem value={null} disabled>
                            Select Channel
                          </MenuItem> */}
                          {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        // <TextField
                        //   name="channel_count"
                        //   value={vehicle.get_vehicle.channel_count}
                        //   onChange={handleInputChange}
                        //   onBlur={handleBlur}
                        //   fullWidth
                        //   type
                        //   InputProps={{ inputProps: { min: 1, max: 8 } }}
                        // />
                      }
                    />
                  </ListItem>}
                  {/* {helpers.channel_count && (
                    <Alert severity="error" className={classes.alert}>
                      {helpers.channel_count}
                    </Alert>
                  )} */}
                  <SwitchHelper
                    handleChangeHandler={event => {
                      if (
                        event.target.checked &&
                        vehicle.get_vehicle.not_in_use
                      ) {
                        setVehicle({
                          get_vehicle: {
                            ...vehicle.get_vehicle,
                            under_repair: event.target.checked,
                            not_in_use: !vehicle.get_vehicle.not_in_use
                          }
                        });
                      } else {
                        setVehicle({
                          get_vehicle: {
                            ...vehicle.get_vehicle,
                            under_repair: event.target.checked
                          }
                        });
                      }
                    }}
                    checkedValue={vehicle.get_vehicle.under_repair}
                    label="Under Repair"
                    name="under_repair"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Under Repair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                  <SwitchHelper
                    handleChangeHandler={event => {
                      if (
                        event.target.checked &&
                        vehicle.get_vehicle.under_repair
                      ) {
                        setVehicle({
                          get_vehicle: {
                            ...vehicle.get_vehicle,
                            not_in_use: event.target.checked,
                            under_repair: !vehicle.get_vehicle.under_repair
                          }
                        });
                      } else {
                        setVehicle({
                          get_vehicle: {
                            ...vehicle.get_vehicle,
                            not_in_use: event.target.checked
                          }
                        });
                      }
                    }}
                    checkedValue={vehicle.get_vehicle.not_in_use}
                    label="Not in use vehicle"
                    name="not_in_use_vehicle"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Not in use vehicle"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                  <SwitchHelper
                    handleChangeHandler={event =>
                      setVehicle({
                        get_vehicle: {
                          ...vehicle.get_vehicle,
                          has_temp_sensor: event.target.checked
                        }
                      })
                    }
                    checkedValue={vehicle?.get_vehicle?.has_temp_sensor}
                    label="Temperature Sensor"
                    name="has_temp_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a temperature sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                  <SwitchHelper
                    handleChangeHandler={event =>
                      setVehicle({
                        get_vehicle: {
                          ...vehicle.get_vehicle,
                          has_fuel_sensor: event.target.checked
                        }
                      })
                    }
                    checkedValue={vehicle?.get_vehicle?.has_fuel_sensor}
                    label="Fuel Sensor"
                    name="has_fuel_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a fuel sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                  <SwitchHelper
                    handleChangeHandler={event => {
                      let temp = {};

                      setAutoUnpairToggle(event.target.checked);
                      if (!event.target.checked) {
                        setAutoUnpair(null);
                      }

                      if (!event.target.checked) {
                        temp = {
                          ...helpers,
                          auto_unpair: ""
                        };
                        setHelper(temp);
                      }
                    }}
                    checkedValue={autoUnpairToggle}
                    label="Auto Unpair"
                    name="auto_unpair"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: Enable GPS auto unpair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                  {autoUnpairToggle && (
                    <ListItem>
                      <ListItemText
                        secondary={
                          <Select
                            displayEmpty
                            fullWidth
                            value={autoUnpair}
                            // value={vehicle.get_vehicle.auto_unpair}
                            name="auto_unpair"
                            placeholder="Select condition"
                            onChange={e => {
                              setAutoUnpair(e.target.value);
                            }}
                          >
                            <MenuItem value={null} disabled>
                              Select condition
                            </MenuItem>
                            {autoUnpairCondition.map(option => (
                              <MenuItem
                                disabled={
                                  vehicle.get_vehicle.vehicle_type
                                    .toLowerCase()
                                    .trim() !== "container van" &&
                                  option.id !==
                                    process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF.toString()
                                }
                                key={option.id}
                                value={option.id}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </ListItem>
                  )}
                  {helpers.auto_unpair && helpers.auto_unpair}

                  <SwitchHelper
                    handleChangeHandler={event => {
                      setVehicle({
                        get_vehicle: {
                          ...vehicle.get_vehicle,
                          has_vss_camera: event.target.checked ? 1 : 0
                        }
                      });
                    }}
                    checkedValue={!!vehicle.get_vehicle.has_vss_camera}
                    label="VSS Camera"
                    name="has_vss_camera"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as vehicle with VSS Compatibility"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled={!edit}
                  />
                </List>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">GPS Information</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <HtmlTooltip
                            placement="top-start"
                            title="For GPS pairing concerns please contact WTI"
                          >
                            <HelpIcon className={classes.helpIcon} />
                            {/* <Error style={{ fontSize: 14 }} /> */}
                          </HtmlTooltip>
                          {"  "}Paired Device
                        </span>
                      }
                      secondary={
                        <span>
                          <Battery
                            vehicleType={vehicle_data.get_vehicle.vehicle_type}
                            deviceTypeId={
                              vehicle_data.get_vehicle.device_info
                                ?.device_type_id
                            }
                            batteryLevel={
                              vehicle_data.get_vehicle.device_info
                                ?.device_status?.battery_level
                            }
                            tooltipTitle={
                              <>
                                <p
                                  className={classes.tooltipTitle}
                                  style={{ fontWeight: "bold" }}
                                >{`Battery Life: ${vehicle_data.get_vehicle.device_info?.device_status?.battery_level}%`}</p>
                                <p className={classes.tooltipTitle}>
                                  {`As of: ${moment(
                                    vehicle_data.get_vehicle.device_info
                                      ?.device_status?.reportstamp
                                  ).format("lll")}
                                  `}
                                </p>
                              </>
                            }
                          />{" "}
                          <TextField
                            placeholder="Enter Pair Device"
                            value={
                              vehicle.get_vehicle.device_info?.devicealias || ""
                            }
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "black" }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Tooltip
                                    title={gpsStatus(vehicle.get_vehicle).label}
                                  >
                                    <Brightness1
                                      style={{
                                        fontSize: 14,
                                        color: gpsStatus(vehicle.get_vehicle)
                                          .color
                                      }}
                                    />
                                  </Tooltip>
                                </InputAdornment>
                              )
                            }}
                            fullWidth
                            readOnly
                            onClick={() => toggleDeviceModal(true)}
                          />
                          <PairDeviceModal
                            toggle={deviceModal}
                            initialData={vehicle.get_vehicle}
                            closeVehicleModal={() => toggleDeviceModal(false)}
                            setDeviceInfo={async e => {
                              // Check if value already changed before selecting new device
                              if (
                                +initialState.get_vehicle.device_id &&
                                !_.isEqual(
                                  initialState.get_vehicle.device_id,
                                  vehicle.get_vehicle.device_id
                                )
                              ) {
                                toggleDeviceModal(false);
                                setVehicle({
                                  get_vehicle: {
                                    ...vehicle.get_vehicle,
                                    device_id: e?.id || 0,
                                    device_info: e || null
                                  }
                                });
                              } else {
                                const trips = await getBookings();
                                if (!trips) return;

                                newDeviceRef.current = e;
                                if (
                                  trips?.ongoingTrips?.tripNumbers?.length ||
                                  !e
                                ) {
                                  setUnpairModal(true);
                                } else {
                                  toggleDeviceModal(false);
                                  setVehicle({
                                    get_vehicle: {
                                      ...vehicle.get_vehicle,
                                      device_id: e?.id || 0,
                                      device_info: e || null
                                    }
                                  });
                                }
                              }
                            }}
                          />
                          <UnpairingDialog
                            toggle={unpairModal}
                            close={() => setUnpairModal(false)}
                            fn={() => {
                              setVehicle({
                                get_vehicle: {
                                  ...vehicle.get_vehicle,
                                  device_id: newDeviceRef.current?.id || 0,
                                  device_info: newDeviceRef.current
                                }
                              });
                              toggleDeviceModal(false);
                              newDeviceRef.current = null;
                            }}
                            referenceData="vehicle"
                            tripInfo={{
                              trips: bookingRef.current,
                              devicealias:
                                initialState.get_vehicle.device_info
                                  ?.devicealias || "",
                              plateno: initialState.get_vehicle.plateno || ""
                            }}
                          />
                        </span>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="IMEI/Serial Number"
                      secondary={vehicle.get_vehicle.device_info?.name || "-"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="GPS Provider"
                      secondary={
                        vehicle.get_vehicle.device_info
                          ? vehicle.get_vehicle.device_info.device_config
                              ?.gps_provider
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Auxiliary Voltage(mV)"
                      secondary={
                        vehicle.get_vehicle.device_info
                          ? vehicle.get_vehicle.device_info.device_config
                              ?.aux_voltage
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Main Power Interval Report(min)"
                      secondary={
                        vehicle.get_vehicle.device_info
                          ? vehicle.get_vehicle.device_info.device_config
                              ?.intervalreporting
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary="Auxiliary Mode Interval Report(min)"
                      secondary={
                        vehicle.get_vehicle.device_info?.device_config
                          ?.battery_intervalreporting || "-"
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                {vehicle_data.get_vehicle.device_id > 0 &&
                (isWTIAdmin || isPrimaryClient) ? (
                  <>
                    <div className={classes.odom_text}>
                      <Typography variant="h6">
                        Sync Vehicle Odometer
                      </Typography>
                      {odometerValue?.odom * 1000 === initState ? (
                        <Typography>
                          As of{" "}
                          {moment(
                            vehicle_data.get_vehicle.device_info.device_status
                              .reportstamp
                          ).format("lll")}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </div>
                    <List>
                      <ListItem className={classes.odom}>
                        <ListItemText
                          secondaryTypographyProps={{
                            component: "div"
                          }}
                          primary={
                            <TextField
                              fullWidth
                              disabled
                              variant="filled"
                              value={Math.round(
                                odometerValue?.updatedOdom === 0
                                  ? odometerValue.odom
                                  : odometerValue.updatedOdom / 1000
                              )}
                              label="Current Odometer (km)"
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setOdometer(true)}
                                    >
                                      <CachedRoundedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          }
                        />
                      </ListItem>
                      <ListItem className={classes.odom}>
                        <Alert
                          className={classes.odomAlert}
                          severity="info"
                          color="success"
                          variant="outlined"
                        >
                          Amended vehicle odometer will reflect once your
                          vehicle's location update
                        </Alert>
                      </ListItem>
                    </List>
                  </>
                ) : null}
                <Typography variant="h6">Remarks</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={{
                        component: "div"
                      }}
                      primary={
                        !edit ? (
                          vehicle.get_vehicle.remarks
                        ) : (
                          <TextField
                            value={vehicle.get_vehicle.remarks || ""}
                            multiline
                            fullWidth
                            name="remarks"
                            onChange={handleInputChange}
                          />
                        )
                      }
                    />
                  </ListItem>
                </List>
                {user.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
                  <GroupsTable
                    isEdit
                    vehicle={vehicle.get_vehicle}
                    setVehicle={setVehicle}
                  />
                )}
              </Grid>
            </Fragment>
          )}
        </Grid>
        <ConfirmationDialog
          toggle={openConfirm}
          close={() => setOpenConfirm(false)}
          fn={async () => {
            // Check if device info is changed
            if (
              +initialState.get_vehicle.device_id &&
              +initialState.get_vehicle.device_id !==
                +vehicle.get_vehicle.device_id
            ) {
              // Reset future trips before editing vehicle
              if (bookingRef.current?.futureTrips?.forReset.length) {
                const resetResult = await resetTrips(
                  bookingRef.current.futureTrips.forReset
                );
                if (resetResult.success) {
                  saveHandler();
                } else {
                  Swal.fire({
                    icon: "error",
                    text: "Something went wrong",
                    timer: 1500,
                    showConfirmButton: false,
                    customClass: {
                      container: `${classes.swal}`
                    }
                  });
                }
              } else {
                saveHandler();
              }
            } else {
              saveHandler();
            }
          }}
          title="Save?"
          content="Are you want to save changes made?"
        />
        {/* <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
          <DialogTitle>Save?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you want to save changes made?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // className={classes.noButton}
              style={{
                borderRadius: 36,
                borderColor: "#f44336",
                color: "#f44336"
              }}
              variant="outlined"
              onClick={() => setOpenConfirm(false)}
            >
              NO
            </Button>
            <Button
              style={{
                borderRadius: 36,
                color: "white"
              }}
              color="primary"
              variant="contained"
              onClick={}
            >
              YES
            </Button>
          </DialogActions>
        </Dialog> */}
        {/* {edit ? ( */}
        <div align="center">
          <Button
            color="primary"
            style={{ borderRadius: 32, width: 350 }}
            variant="contained"
            onClick={() => {
              if (validateData()) {
                setOpenConfirm(true);
              }
            }}
          >
            Save Details
          </Button>
        </div>
        {/* ) : (
          ""
        )} */}
      </Paper>
      <Dialog
        open={odometer}
        onClose={() => {
          setOdometer(false);
          setHelper({ ...helpers, odometer: null });
        }}
        classes={{ paper: classes.odometerContainer }}
      >
        <DialogTitle>
          <span className={classes.closeTitle}>
            Vehicle Odometer Reading (km){" "}
            <span style={{ color: "red" }}>*</span>
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <TextField
              inputRef={odomRef}
              name="odometer"
              fullWidth
              // type="number"
              FormHelperTextProps={{
                component: "div"
              }}
              // value={odometerValue.odom}
              // helperText={helpers.odometer}
              // onBlur={e => handleBlurOdom(e)}
              onChange={e => odomOnChangeHandler(e)}
            />
            {helpers.odometer && (
              <Alert severity="error" className={classes.alertOdom}>
                {helpers.odometer}
              </Alert>
            )}
          </DialogContentText>
          <Alert
            className={classes.odomAlertModal}
            severity="info"
            color="success"
            variant="outlined"
            style={{ marginBottom: 20 }}
          >
            Amended vehicle odometer will reflect once your vehicle's location
            update
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setHelper({ ...helpers, odometer: null });
              setOdometer(false);
              setOdometerValue({
                ...odometer,
                odom:
                  (vehicle_data?.get_vehicle?.device_info?.device_status
                    ?.distancetraveled || 0) / 1000,
                updatedOdom: 0,
                newOdom: 0
              });
            }}
            className={classes.circularButtonNo}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onMouseDown={handleVehicleOdo}
            className={classes.circularButtonYes}
            disabled={
              !odometerValue.newOdom ||
              (odometerValue.newOdom && helpers.odometer)
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(EditVehicle);
