/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* global google */
import React from "react";
import { withRouter } from "react-router-dom";
import {
  TrafficLayer,
  StreetViewPanorama,
  Marker
} from "@react-google-maps/api";
import _, { isEqual } from "lodash";
import Cookie from "js-cookie";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import {
  GET_GEOFENCES,
  GET_VEHICLES_FLEET_MAPVIEW,
  GET_INFO_BUBBLE
} from "../../graphql/Queries";
import client from "../../Client";
import { FilterControls, MainControl } from "./MapPartials/FilterControl";
import Loading from "../../utils/Loading";
import DrawingController from "./MapPartials/DrawingController";
import AddControl from "./MapPartials/AddControl";
import InnerMap, {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  MAP_REFERENCE,
  MAX_ZOOM
} from "./BaseMap/Inner.map";
import Vehicles from "./BaseMap/Vehicles.map";
import Geofences from "./BaseMap/Geofences.map";
import Vehicle from "./MapPartials/Vehicle";
import Clusterer from "../../utils/Map/Clusterer/Clusterer";
import AlertVehicleReference from "./MapPartials/AlertVehicleReference";
import SearchLocation from "./SearchLocation";
import { isEmpty } from "lodash";
import BlinkingAlertsLegend from "../../utils/BlinkingAlertsLegend";
import { wktToCoordinates } from "../../utils/functions/coordinatesParserV2";
import SmartDeadspot from "./BaseMap/SmartDeadspot.map";
import GlobeDeadspot from "./BaseMap/GlobeDeadspot.map";

// const RECENTER = null;
// const CENTER = { lat: 12.248727944234455, lng: 121.95099687500002 };
// const DEFAULT_OPTIONS = {
//   fullscreenControl: false,
//   mapTypeControl: true,
//   mapTypeControlOptions: {
//     style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//     position: google.maps.ControlPosition.LEFT_BOTTOM,
//     mapTypeIds: [
//       google.maps.MapTypeId.ROADMAP,
//       google.maps.MapTypeId.SATELLITE,
//       google.maps.MapTypeId.HYBRID
//     ]
//   }
// };

const getGeoPaths = () => {
  const path = [];
  const aNorth = MAP_REFERENCE.current
    .getBounds()
    .getNorthEast()
    .lat();
  const aEast = MAP_REFERENCE.current
    .getBounds()
    .getNorthEast()
    .lng();
  const aSouth = MAP_REFERENCE.current
    .getBounds()
    .getSouthWest()
    .lat();
  const aWest = MAP_REFERENCE.current
    .getBounds()
    .getSouthWest()
    .lng();

  path.push(
    { lat: aNorth, lon: aEast },
    { lat: aNorth, lon: aWest },
    { lat: aSouth, lon: aWest },
    { lat: aSouth, lon: aEast },
    { lat: aNorth, lon: aEast }
  );

  return path;
};

// const debounce = (func, delay) => {
//   let timeout;
//   return function executedFunction(...args) {
//     const later = () => {
//       timeout = null;

//       func(...args);
//     };
//     clearTimeout(timeout);

//     timeout = setTimeout(later, delay);
//   };
// };
class OuterMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isComponentMounted: false,
      activeVId: null,
      historyFilterVal: {
        dayType: 0,
        from: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
        to: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
        tripNumber: null,
        isValid: true,
        vehicleClientId: null,
        activeVehicle: null
      },
      geofences: [],
      vehicles: [],
      allVehicles: [],
      vehiclesCount: 0,
      center: {
        lat: 12.248727944234455,
        lng: 121.95099687500002
      },
      overlays: {
        showGeofences: false,
        showTrafficLayer: false,
        showClusterer: true,
        showVehicles: true,
        smartShowDeadspot: false, 
        globeShowDeadspot: false 
      },
      streetView: {},
      currentLoggedInUser: JSON.parse(Cookie.get("user")),
      loading: true,
      infoBubble: [],
      criticalVehiclesIsShown: false,
      isCriticalVehicleExist: false,
      clusterInstance: null,
      searchedCoordinates: null,
      searchedPlace: false,
      bounds: null
    };
    this.onMapBoundsChanged = this.onMapBoundsChanged.bind(this);
    this.handleChangeOverlay = this.handleChangeOverlay.bind(this);
    this.getMapConditions = this.getMapConditions.bind(this);
    // this.countVisibleVehicles = this.countVisibleVehicles.bind(this);
    this.setMapCenter = this.setMapCenter.bind(this);
    this.toggleStreetView = this.toggleStreetView.bind(this);

    this.startVehicleSubscription = this.startVehicleSubscription.bind(this);
    this.stopVehicleSubscription = this.stopVehicleSubscription.bind(this);

    this.setZoomDefault = this.setZoomDefault.bind(this);
    this.fitVehicleMarkers = this.fitVehicleMarkers.bind(this);
    this.zoomToLocation = this.zoomToLocation.bind(this);
  }

  getGeoVars = () => {
    const { filterValues } = this.props;
    const { currentLoggedInUser } = this.state;
    const variables = {
      first: 100,
      group_ids:
        +currentLoggedInUser.user_level_id === +process.env.REACT_APP_WTI_ADMIN
          ? filterValues?.hauler?.value
          : []
    };

    const zoom = MAP_REFERENCE?.current?.zoom;
    if (zoom >= 15) {
      Object.assign(variables, {
        bbox: getGeoPaths()
      });
    }

    return variables;
  };

  getGeofences() {
    return client.query({
      query: GET_GEOFENCES,
      variables: this.getGeoVars()
    });
  }

  setZoomDefault() {
    if (this.state.vehicles.length > 1) {
      MAP_REFERENCE.current.setZoom(DEFAULT_ZOOM);
    }
  }

  setZoomSearched() {
    if (this.state.searchedCoordinates) {
      MAP_REFERENCE.current.setZoom(MAX_ZOOM);
    }
  }

  getInfoBubble = () => {
    const { clientSelection, filterValues } = this.props;
    const { currentLoggedInUser } = this.state;
    client
      .query({
        query: GET_INFO_BUBBLE,
        variables: {
          client_id:
            // +currentLoggedInUser.user_level_id ===
            //   +process.env.REACT_APP_WTI_ADMIN && clientSelection?.id
            // ? `${clientSelection?.id}`
            // : currentLoggedInUser?.client?.id
            +currentLoggedInUser.user_level_id ===
            +process.env.REACT_APP_WTI_ADMIN
              ? filterValues?.hauler?.id
              : currentLoggedInUser?.client?.id
        }
      })
      .then(res => {
        this.setState({ infoBubble: res.data.get_info_bubble });
      });
  };

  startVehicleSubscription(params) {
    const { clientSelection, bound, vehicles } = this.props;
    const { currentLoggedInUser } = this.state;

    this.vehicleSubscription = client
      .watchQuery({
        query: GET_VEHICLES_FLEET_MAPVIEW,
        variables: {
          ...params,
          first: vehicles.total,
          dateRange: {
            start: `${moment().format("YYYY-MM-DD")} 00:00:00`
          },
          ...(params?.bounds ? { bounds: params.bounds } : { bounds: bound }),
          ...(+currentLoggedInUser.user_level_id ===
            +process.env.REACT_APP_WTI_ADMIN && {
            group_ids: params?.group_ids
              ? params.group_ids
              : clientSelection.group_ids
          }),
          not: [
            {
              field: "rover_status.latitude",
              value: ["null", "", "0"]  
            },
            {
              field: "rover_status.longitude",
              value: ["null", "", "0"]  
            }
          ]
          // ...(+currentLoggedInUser.user_level_id ===
          //   +process.env.REACT_APP_WTI_ADMIN &&
          //   clientSelection.id && { group_ids: clientSelection.group_ids })
        },
        notifyOnNetworkStatusChange: true,
        pollInterval: 60 * 1000
      })
      .subscribe({
        next: result => {
          let center = { lat: 12.248727944234455, lng: 121.95099687500002 };
          if (MAP_REFERENCE.current?.getCenter()) {
            center = {
              lat: MAP_REFERENCE.current.getCenter().lat(),
              lng: MAP_REFERENCE.current.getCenter().lng()
            };
          }
          this.setState({
            loading: true,
            vehicles: [],
            isCriticalVehicleExist: false,
            center: center
          });
          const { vehicles } = result.data.get_vehicles;
          // eslint-disable-next-line no-shadow
          const { currentLoggedInUser, criticalVehiclesIsShown } = this.state;
          // decode the token from the cookie token and fetch the client type.
          const clientType = currentLoggedInUser.client.is_restricted;

          const filteredVehicles = vehicles.filter(vehicle => {
            if (vehicle.device_info?.device_status) {
              // if (
              //   vehicle.device_info?.device_status.AlertReferences?.some(
              //     alert => alert.level >= 3
              //   ) &&
              //   !this.state.isCriticalVehicleExist
              // ) {
              //   this.setState({ isCriticalVehicleExist: true });
              // }
              if (
                vehicle.client_id === currentLoggedInUser.client.id ||
                +currentLoggedInUser.user_level_id ===
                  +process.env.REACT_APP_WTI_ADMIN
              ) {
                // If owner/primary, show all
                return true;
              }
              if (vehicle.trip_numbers?.length > 0 || !clientType) {
                // If partner/secondary, show vehicles with ongoing trip
                return true;
              }
              return false;
            }
            return false;
          });
          if (!isEqual(this.state.allVehicles, filteredVehicles)) {
            const tempArray = [];
            if (criticalVehiclesIsShown) {
              this.state.allVehicles.forEach(v => {
                if (
                  v.device_info?.device_status.AlertReferences?.some(
                    alert => alert.level >= 3
                  )
                ) {
                  tempArray.push(v);
                }
              });
            }
            this.setState(
              {
                allVehicles: filteredVehicles,
                vehicles: criticalVehiclesIsShown
                  ? tempArray
                  : filteredVehicles,
                // vehiclesCount: this.countVisibleVehicles()
              },
              () => {
                this.setState({
                  loading: false,
                  criticalVehiclesIsShown: false
                });
              }
            );
          } else {
            this.setState({ loading: false, vehicles: filteredVehicles });
          }
        },
        error: e => {
          return `message: ${e}`;
        }
        // console.error("message: ", e)
      });
  }

  stopVehicleSubscription() {
    if (this.vehicleSubscription) {
      // When map is unseen or unmounted this will stop requesting vehicle info.
      this.vehicleSubscription.unsubscribe();
    }
  }

  // componentDidMount() {
  // console.log("did mount");
  // }

  UNSAFE_componentWillMount() {
    // console.log("Will Mount!");
    const { vars } = this.props;
    this.startVehicleSubscription({
      ...vars
    });
    this.getInfoBubble();
  }

  getMapConditions() {
    const { vars } = this.props;
    const { currentLoggedInUser } = this.state;
    const defaultClient = {
      field: "clients.id",
      value: currentLoggedInUser.client.id.toString()
    };
    const conditions = [];
    if (vars) {
      if (vars.condition) {
        const tempConditions = vars.condition.map(cond => {
          if (cond.field === "clients.id") {
            if (cond.value === currentLoggedInUser.client.id.toString()) {
              return cond;
            }
            return { field: "clients.id", value: "0" };
          }
          return cond;
        });
        conditions.condition = tempConditions;
        if (
          _.find(conditions.condition, { field: "clients.id" }) === undefined
        ) {
          conditions.condition.push(defaultClient);
        }
      } else {
        conditions.condition = [defaultClient];
      }
    }
    return conditions;
  }

  setMapCenter() {
    const { vehicles, searchedCoordinates } = this.state;
    // let center = { lat: 12.248727944234455, lng: 121.95099687500002 };
    let center = this.state.center;

    if (vehicles.length > 0) {
      const { device_info } = vehicles[0];
      if (!isEmpty(searchedCoordinates)) center = searchedCoordinates;
      else if (device_info) {
        const { latitude, longitude } = device_info.device_status;
        if (latitude && longitude && vehicles.length === 1) {
          center = { lat: latitude, lng: longitude };
        }
      } else {
        center = DEFAULT_CENTER;
      }
    } else if (!isEmpty(searchedCoordinates)) center = searchedCoordinates;

    return center;
  }

  fitVehicleMarkers(prevBounds) {
    const { allVehicles } = this.state;
    const vehicles = allVehicles;
    if (vehicles.length > 0) {
      let markerBounds = new google.maps.LatLngBounds();
      vehicles.forEach(vehicle => {
        if (!vehicle.device_info) return null;

        const { latitude, longitude } = vehicle.device_info.device_status;
        if (latitude && longitude) {
          markerBounds.extend(new google.maps.LatLng(latitude, longitude));
        }
      });
      
      if (MAP_REFERENCE && markerBounds && !isEqual(markerBounds, prevBounds)) {
        // MAP_REFERENCE.current.setOptions({minZoom: DEFAULT_ZOOM});
        MAP_REFERENCE.current.fitBounds(markerBounds);
        this.setState({ bounds: markerBounds })
        if (MAP_REFERENCE.current.getZoom() < DEFAULT_ZOOM) {
          MAP_REFERENCE.current.setZoom(DEFAULT_ZOOM);
        }
        // MAP_REFERENCE.current.setOptions({minZoom: undefined});
      }
    }
  }

  // zoom to filter location (user/google places)
  zoomToLocation(prevBounds) {
    const { filterValues } = this.props;
    
    // user places
    if (filterValues?.location?.geom) {
      const { geom } = filterValues.location;
      this.setState({searchedCoordinates: null })
      let bounds = new google.maps.LatLngBounds();
      const paths = wktToCoordinates(geom);
      paths.forEach(path => {
        path.forEach(coor => bounds.extend(coor));
      });
      var extendPoint = new window.google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
      bounds.extend(extendPoint);
      
      if (MAP_REFERENCE && bounds && !isEqual(bounds, prevBounds)) {
        MAP_REFERENCE.current.fitBounds(bounds);
        this.setState({ bounds: bounds })
      }
    }
    // google places 
    else if (filterValues.center?.lat() && filterValues.center?.long()) {
      this.setState({ searchedCoordinates: {lat: filterValues.center?.lat(), lng: filterValues.center?.long()} });
      this.setState({ searchedPlace: true });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    // console.log("did update");
    const { vars, filterValues, vehicles } = this.props;
    const { center, allVehicles, criticalVehiclesIsShown } = this.state;
    const isSameVars = isEqual(prevProp.vars, this.props.vars);
    const isSameProps = isEqual(prevProp, this.props);
    const isSameCenter = isEqual(center, this.setMapCenter());
    const isSameAllVehicles = isEqual(allVehicles, prevState.allVehicles);
    const isSameCriticalStatus = isEqual(
      criticalVehiclesIsShown,
      prevState.criticalVehiclesIsShown
    );

    if (!isSameProps || !isSameAllVehicles) {
      if (!vehicles?.total && (filterValues.location || filterValues.center)) {
        this.zoomToLocation(prevState.bounds);
      } else if (!vehicles?.total && !filterValues.location && this.state.searchedPlace) {
        this.setState({ searchedCoordinates: null })
      } else if (_.isEmpty(prevState.allVehicles)) {
        this.fitVehicleMarkers(prevState.bounds);
      }
    }

    if (!isSameCenter || !isSameProps || !isSameAllVehicles) {
      // console.log("map center updated");
      this.setState({
        center: this.setMapCenter(),
        // vehiclesCount: this.countVisibleVehicles()
      });
      this.setZoomSearched();
    }

    if (!isSameProps) {
      // console.log("subscription updated");
      // this.setZoomDefault();
      this.stopVehicleSubscription();
      this.getInfoBubble();
      this.startVehicleSubscription({
        ...vars
      });
    }

    if (!isSameCriticalStatus) {
      this.setState(
        {
          loading: true
        },
        () => {
          setTimeout(() => {
            if (criticalVehiclesIsShown) {
              const tempArray = [];
              allVehicles.forEach(v => {
                if (
                  v.device_info?.device_status.AlertReferences?.some(
                    alert => alert.level >= 3
                  )
                ) {
                  tempArray.push(v);
                }
              });
              this.setState({ vehicles: tempArray, loading: false });
            } else {
              this.setState({ vehicles: allVehicles, loading: false });
            }
          }, 5000);
        }
      );
    }

    if (!isSameVars) {
      // if new search keyword or apply filters, clear allVehicles value
      this.setState({ allVehicles: [], center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM });
    }
  }

  componentWillUnmount() {
    this.stopVehicleSubscription();
  }

  // countVisibleVehicles() {
  //   let count = 0;
  //   if (MAP_REFERENCE.current && MAP_MARKERS.current) {
  //     const bounds = MAP_REFERENCE.current.getBounds();
  //     for (let i = 0; i < MAP_MARKERS.current.length; i += 1) {
  //       if (MAP_MARKERS.current[i] !== null) {
  //         if (bounds?.contains(MAP_MARKERS.current[i].getPosition()) === true) {
  //           count += 1;
  //         }
  //       }
  //     }
  //   }

  //   return count;
  // }

  toggleStreetView(position) {
    this.setState({ streetView: position });
  }

  onMapBoundsChanged() {
    // console.log("onMapBoundsChanged");
    const {
      overlays: { showGeofences }
    } = this.state;
    if (showGeofences) {
      // debounce(() => {
      // This debounce function doesn't work properly...
      this.getGeofences().then(result => {
        if (!result.loading) {
          const { geofences } = result.data.get_geofences;
          const { geofences: geofencesFromState } = this.state;
          if (geofencesFromState) {
            if (!isEqual(geofences, geofencesFromState)) {
              this.setState({
                geofences,
                // vehiclesCount: this.countVisibleVehicles()
              });
            }
          } else {
            this.setState({
              geofences,
              // vehiclesCount: this.countVisibleVehicles()
            });
          }
        }
      });
      // }, 600)();
    }
    // else {
    //   this.setState({
    //     vehiclesCount: this.countVisibleVehicles()
    //   });
    // }
    
    if (MAP_REFERENCE?.current) {
      this.setState({
        bounds: MAP_REFERENCE.current.getBounds()
      });
    }
  }

  handleChangeOverlay(e) {
    const { name, checked } = e.target;

    this.setState(state => {
      return {
        overlays: {
          ...state.overlays,
          [name]: checked
        }
      };
    });
    if (name === "showGeofences" && checked) {
      this.getGeofences().then(result => {
        if (!result.loading) {
          const { geofences } = result.data.get_geofences;
          const { geofences: geofencesFromState } = this.state;
          if (geofencesFromState) {
            if (!isEqual(geofences, geofencesFromState)) {
              this.setState(state => ({
                geofences,
                overlays: {
                  ...state.overlays,
                  [name]: checked
                }
              }));
            }
          } else {
            this.setState(state => ({
              geofences,
              overlays: {
                ...state.overlays,
                [name]: checked
              }
            }));
          }
        }
      });
    }
  }

  render() {
    const {
      geofences,
      overlays,
      vehicles,
      center,
      streetView,
      currentLoggedInUser,
      loading,
      infoBubble,
      criticalVehiclesIsShown,
      searchedCoordinates,
      searchedPlace
      // criticalVehicles
    } = this.state;

    const {
      history,
      filterValues,
      vars,
      setBounds,
      drawing,
      setDrawing,
      bound,
      permissions
    } = this.props;

    const redirectTo = (r, params = {}) => {
      history.push({
        pathname: r,
        params
      });
    };

    const { RIGHT_TOP, LEFT_TOP, RIGHT_BOTTOM } = google.maps.ControlPosition;
    const isWTIAdmin = +currentLoggedInUser.user_level_id === +process.env.REACT_APP_WTI_ADMIN
    // if (loading) return <Loading/>;
    return (
      <React.Fragment>
        {loading && <Loading />}
        <InnerMap
          onMapBoundsChanged={this.onMapBoundsChanged}
          vehicles={vehicles}
          center={center}
          currentLoggedInUser={currentLoggedInUser}
          searchedCoordinates={searchedCoordinates}
          fitVehicleMarkers={this.fitVehicleMarkers}
          filterValues={this.props?.filterValues}
          zoomToLocation={this.zoomToLocation}
        >
          <FilterControls mapRef={MAP_REFERENCE} position={RIGHT_TOP}>
            <MainControl
              handleChangeOverlay={this.handleChangeOverlay}
              filters={overlays}
            />
          </FilterControls>
          <FilterControls mapRef={MAP_REFERENCE} position={LEFT_TOP}>
            <SearchLocation
              setCoordinates={coords => {
                this.setState({ searchedCoordinates: coords })
                this.setState({ searchedPlace: false })
              }}
            />
          </FilterControls>
          <FilterControls mapRef={MAP_REFERENCE} position={RIGHT_BOTTOM}>
            <BlinkingAlertsLegend showAlerts showOutlines />
          </FilterControls>
          <IconButton
            onClick={() => {
              setDrawing(prev => {
                if (prev) {
                  setBounds([]);
                }
                return !prev;
              });
            }}
            style={{ visibility: "hidden" }}
          >
            <DrawingController
              drawing={drawing}
              mapRef={MAP_REFERENCE}
              setBounds={setBounds}
            />
          </IconButton>
          {/* {this.state.isCriticalVehicleExist && (
            <IconButton
              onClick={() => {
                this.setState({
                  criticalVehiclesIsShown: !criticalVehiclesIsShown,
                  vehicles: []
                });
              }}
              style={{ visibility: "hidden" }}
            >
              <AlertVehicleReference
                criticalVehiclesIsShown={this.state.criticalVehiclesIsShown}
                mapRef={MAP_REFERENCE}
              />
            </IconButton>
          )} */}
          {permissions?.edit && (
            <IconButton
              onClick={() => {
                redirectTo(`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/add`, {
                  source: `/monitoring`,
                  filterValues: filterValues,
                  vars: vars,
                  payload: {
                    zoom: MAP_REFERENCE.current.getZoom(),
                    center: {
                      lat: MAP_REFERENCE.current.getCenter().lat(),
                      lng: MAP_REFERENCE.current.getCenter().lng()
                    }
                  }
                });
              }}
              style={{ visibility: "hidden" }}
            >
              <AddControl bound={bound} mapRef={MAP_REFERENCE} />
            </IconButton>
          )}
          {searchedCoordinates && !searchedPlace && (
            <Marker
              position={searchedCoordinates}
              icon={{
                url: `${process.env.PUBLIC_URL}/assets/Markers/Dropoff 1.png`,
                scaledSize: new google.maps.Size(45, 45)
              }}
              options={{
                zIndex: 1000
              }}
            />
          )}
          {overlays.showGeofences && <Geofences geofencesData={geofences} />}
          {overlays.showTrafficLayer &&  <TrafficLayer autoUpdate />}
          {(overlays.smartShowDeadspot && isWTIAdmin) && <SmartDeadspot mapRef={MAP_REFERENCE} />}
          {(overlays.globeShowDeadspot && isWTIAdmin) && <GlobeDeadspot mapRef={MAP_REFERENCE} />}
          {overlays.showClusterer && vehicles.length ? (
            <Clusterer
              gridSize={20}
              onClusteringEnd={clusterInstance => {
                this.setState({ clusterInstance: { ...clusterInstance } });
              }}
            >
              {clusterer => (
                <Vehicles vehicles={vehicles}>
                  {({ id, ...otherProps }) => (
                    <Vehicle
                      key={id}
                      {...otherProps}
                      clusterInstance={this.state.clusterInstance}
                      toggleStreetView={this.toggleStreetView}
                      history={history}
                      filterValues={filterValues}
                      vars={vars}
                      user={currentLoggedInUser}
                      clusterer={clusterer}
                      infoBubble={infoBubble}
                      zoom={MAP_REFERENCE.current?.zoom}
                      vehicles={vehicles}
                    />
                  )}
                </Vehicles>
              )}
            </Clusterer>
          ) : (
            overlays.showVehicles &&
            vehicles.length && (
              <Vehicles vehicles={vehicles}>
                {({ id, ...otherProps }) => (
                  <Vehicle
                    key={id}
                    {...otherProps}
                    toggleStreetView={this.toggleStreetView}
                    history={history}
                    filterValues={filterValues}
                    vars={vars}
                    user={currentLoggedInUser}
                    infoBubble={infoBubble}
                    zoom={MAP_REFERENCE.current?.zoom}
                    vehicles={vehicles}
                  />
                )}
              </Vehicles>
            )
          )}

          {!_.isEmpty(streetView) && (
            <StreetViewPanorama
              position={streetView}
              visible={!_.isEmpty(streetView)}
              onVisibleChanged={() => this.toggleStreetView({})}
            />
          )}
        </InnerMap>
      </React.Fragment>
    );
  }
}

export default withRouter(OuterMap);
