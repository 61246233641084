import React from "react";
import { withRouter } from "react-router-dom";
import TripsDemo from "../../../utils/Onboarding/modules/Trips/Trips";
import Trips from "./index";
import Onboarding, {
  useOnboardingContext,
  onboarding_max
} from "../../../utils/Onboarding";
import useUserContext from "../../../context/User/useUserContext";
import Introductory from "../../../utils/Onboarding/pages/Introductory";

const Main = () => {
  const user_context = useUserContext();
  const { progress, skippedIntro } = useOnboardingContext({
    module: Onboarding.modules.trips,
    allowContextToChange: true
  });
  const tripsProgress = progress[Onboarding.modules.trips];
  const isDemoCompleted =
    tripsProgress === onboarding_max[Onboarding.modules.trips];

  if (!user_context?.onboarding) return <div>loading...</div>;
  const firstTime = Object.entries(user_context.onboarding).every(
    ([_, val]) => val
  );
  if (firstTime && !skippedIntro) {
    return <Introductory />;
  }
  return isDemoCompleted ? <Trips /> : <TripsDemo />;
};

export default withRouter(Main);
