/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { useHistory /* , Link */ } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import clsx from "clsx";
import _ from "lodash";
import {
  Button,
  ClickAwayListener,
  Checkbox,
  Fab,
  FormControl,
  Grow,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Badge
} from "@material-ui/core";
import {
  // KeyboardArrowLeft as KeyboardArrowLeftIcon,
  // KeyboardArrowRight as KeyboardArrowRightIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  // Publish as PublishIcon,
  Delete as DeleteIcon,
  Visibility as VisibleIcon,
  Event as EventIcon,
  Add as AddIcon
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "../../../../utils/Loading";
import Filter from "../../Filter";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import AddLogs from "../../../../utils/functions/AddLogs";

import { GET_ADMIN_CLIENTS } from "../../../../graphql/Queries";
import { DELETE_ADMIN_CLIENTS } from "../../../../graphql/Mutations";
// import { ADMIN_CLIENTS_SUBSCRIPTION } from "../../../../graphql/Subscription";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Pagination from "../../Pagination";
import useFormStyles from "../../../../styles/useFormStyles";

const Highlight = require("react-highlighter");

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Client Name"
  },
  { id: "category", numeric: true, disablePadding: false, label: "Category" },
  {
    id: "access_end_date",
    numeric: true,
    disablePadding: false,
    label: "Access End Date"
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  }
  // {
  //   id: "actions",
  //   numeric: false,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "Actions"
  // }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked,
    canEdit
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            color="primary"
            disabled={!canEdit}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: "#EAEAEA"
  },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    // borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    numberOfRows,
    rowsPerPage,
    page,
    // handleUpPage,
    // handleDownPage,
    // handlePagination,
    openFilterDrawer,
    handleOpenDeleteAll,
    // canEdit,
    setAnchorEl,
    handleToggle,
    // handleKeyPress,
    // handlePage,
    setRowsPerPage,
    setPage,
    getVariables,
    refetch,
    // totalPage,
    // totalRows,
    fetchMoreData,
    dataLength,
    // setCircularLoading,
    setLoading
  } = props;

  return (
    <div>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Clients
        </Typography>

        <Button
          className={classes.btn_download}
          variant="contained"
          startIcon={<GetAppIcon />}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            handleToggle();
          }}
        >
          Download
        </Button>
        {/* {canEdit && (
          <Button
            className={classes.btn_upload}
            variant="contained"
            endIcon={<PublishIcon />}
            onClick={() => {
              // setAnchorEl(e.currentTarget);
              // handleToggle();
            }}
          >
            Upload
          </Button>
        )} */}
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" onClick={openFilterDrawer}>
            <Badge
              color="secondary"
              variant={
                getVariables()?.condition ||
                getVariables()?.access_status ||
                getVariables().dateRange
                  ? "dot"
                  : "standard"
              }
            >
              <FilterListIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Pagination
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          dataLength={dataLength}
          fetchMoreData={fetchMoreData}
          totalRows={numberOfRows}
          totalPage={Math.floor(numberOfRows / rowsPerPage)}
          refetch={refetch}
          getVariables={getVariables}
          setRowsPerPage={setRowsPerPage}
          // setCircularLoading={setCircularLoading}
          setLoading={setLoading}
        />
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <FormControl className={classes.field}>
            <Select
              value={rowsPerPage}
              onChange={e => {
                handlePagination(e);
              }}
              displayEmpty
              name="rows_per_page"
              style={{ padding: 0, margin: "0 8px" }}
              disableUnderline
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            name="down_page"
            disabled={Number(page) <= 1}
            onClick={() => handleDownPage()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <TextField
            name="page"
            readOnly
            onFocus={e => {
              e.target.select();
            }}
            variant="outlined"
            inputProps={{ style: { padding: 0, textAlign: "center" } }}
            value={Number(page)}
            style={{ width: "32px", marginRight: "8px" }}
            onChange={e => handlePage(e)}
            onKeyPress={e => handleKeyPress(e)}
          />
          {`/ ${Math.floor(numberOfRows / rowsPerPage)}`}
          <Button
            name="up_page"
            disabled={Number(page) >= Math.floor(numberOfRows / rowsPerPage)}
            onClick={() => handleUpPage()}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div> */}
      </Toolbar>

      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
          [classes.invisible]: numSelected <= 0
        })}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {/* <span style={{ color: "#FF845E" }}>{numSelected}</span> client/s */}
            {numSelected} client/s selected on this page
          </Typography>
        )}

        {numSelected > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{
              position: "absolute",
              right: 0,
              borderRadius: 100,
              color: "white"
            }}
            onClick={handleOpenDeleteAll}
          >
            Delete Selected
          </Button>
          // <Tooltip title="Delete">
          //   <IconButton
          //     aria-label="delete"
          //     onClick={() => {
          //       handleOpenDeleteAll();
          //     }}
          //     disabled={!canEdit}
          //   >
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
        )}
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  modal_confirm_button: {
    float: "right",
    background: theme.palette.primary.main,
    color: "white",
    border: "none",
    position: "absolute",
    right: "14px",
    padding: "5px 25px",
    borderRadius: "100px",
    cursor: "pointer",
    fontWeight: "bold"
  }
}));

// MAIN FUNCTION
export default function Clients() {
  const formStyles = useFormStyles();
  const classes = useStyles();
  const history = useHistory();
  const contextData = useContext(SearchContext);
  const dateTimeToday = moment();
  const [clearFirst, setClearFirst] = useState(false);
  // const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1); // setPage
  const [clientKeyword, setClientKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filter, setFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [canEdit, setcanEdit] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [anchorEl, setAnchorEl] = useState(false);
  const [tempFilter, setTempFilter] = useState({
    category: "",
    status: "",
    dateFrom: null,
    dateTo: null
  });
  const [keyLog, setKeyLog] = useState("");
  const [keyDel, setKeyDel] = useState([]);
  // const [selDel, setSelDel] = useState([]);
  // const [circularLoading, setCircularLoading] = useState(false);
  const { location } = history;
  const { setSearchKeyword, searchKeyword } = contextData;

  // get the save filter values
  let variable = history?.location?.state?.params;

  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setClientKeyword(searchKeyword.value);
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  // Function to sort and prepare the data to display in data table
  const getData = () => {
    return currentData?.get_clients.clients
      ? // return sorted data if currentData have a value
        stableSort(
          currentData.get_clients.clients,
          getSorting(order, orderBy)
        ).slice(
          (Number(page) - 1) * rowsPerPage,
          (Number(page) - 1) * rowsPerPage + rowsPerPage
        )
      : // return empty array if currentData didn't have a value
        [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < getData().length) {
      const displayedData = getData().map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  // clear the value of history
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: "Clients"
        }
      }
    });
  };

  const handleFilters = e => {
    const { name, value } = e.target;

    switch (name) {
      case "category":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "dateFrom":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "dateTo":
        setTempFilter({
          ...tempFilter,
          [name]: value
        });
        return;
      case "status":
        setTempFilter({ ...tempFilter, municipality: "", [name]: value });
        return;
      default:
        break;
    }
    setTempFilter({ ...tempFilter, [name]: value });
  };
  const getVariables = () => {
    const variables = {
      skip: 0,
      keyword: clientKeyword,
      first: rowsPerPage,
      orderBy: [{ field: orderBy, direction: order }]
    };

    if (filter.length > 0) {
      filter.forEach(element => {
        switch (element?.field) {
          case "dateFrom":
            Object.assign(variables, { dateRange: element.value });
            break;

          case "status":
            Object.assign(variables, { access_status: element.value });
            break;

          case "category":
            Object.assign(variables, { condition: element });
            break;

          default:
            break;
        }
      });
    }
    return variables;
  };

  const {
    data: currentData,
    refetch,
    fetchMore /* , subscribeToMore */
  } = useQuery(GET_ADMIN_CLIENTS, {
    variables: getVariables(),
    onCompleted: () => {
      setLoading(false);
      if (clientKeyword) {
        AddLogs("Admin - Clients", "search", searchKeyword.value);
      }

      // get the value from history
      let accstat = variable.vars?.access_status;
      let catfil = variable.vars?.condition;
      let filterval = variable?.filter;
      let dateRange = variable?.vars?.dateRange;

      const removeNull = [...filterval, dateRange];

      // set the value from history for query variables
      setFilter(removeNull.filter(el => el !== null && el !== undefined));

      // set the value of field in the filters
      setTempFilter({
        ...tempFilter,
        status: accstat ? accstat : tempFilter?.status,
        category: catfil ? catfil?.value : tempFilter?.category,
        dateFrom: dateRange?.start ? dateRange?.start : tempFilter?.dateFrom,
        dateTo: dateRange?.end ? dateRange?.end : tempFilter?.dateTo
      });
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  const [getSelected] = useLazyQuery(GET_ADMIN_CLIENTS, {
    onCompleted: data => {
      if (data) {
        const temp = [];
        data.get_clients.clients.map(val => {
          return temp.push(val.name);
        });
        setKeyDel(temp);
      }
    }
  });

  useEffect(() => {
    if (currentData) {
      setTotalRows(currentData.get_clients.total);
      setLoading(false);
    }
  }, [currentData]);

  // const subscribeToNewClients = useCallback(() => {
  //   const unsubscribe = subscribeToMore({
  //     document: ADMIN_CLIENTS_SUBSCRIPTION,
  //     variables: {
  //       conditions: [],
  //       not: [],
  //       dateRange: null
  //     },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev.get_clients.clients;
  //       const newData = subscriptionData.data.client_updated;
  //       // const currentData = clientData;
  //       // currentData.push(newData);
  //       // setClientData(newData);
  //       return {
  //         ...prev,
  //         // get_clients: {
  //         //   clients: prev.get_clients.clients.concat(newData)
  //         // }
  //         // get_clients: {
  //         //   count: fetchMoreResult.get_clients.count,
  //         //   total: fetchMoreResult.get_clients.total,
  //         //   clients: [
  //         //     ...prev.get_clients.clients,
  //         //     ...newData
  //         //   ]
  //         // }
  //       };
  //     }
  //   });
  //   return unsubscribe;
  // }, [currentData]);

  // useEffect(() => {
  //   const unsubscribe = subscribeToNewClients();
  //   return () => unsubscribe();
  // }, [subscribeToNewClients]);

  const handlePagination = event => {
    setRowsPerPage(event.target.value);
    setPage(1);
    refetch(getVariables());
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // Function used to refetch data from pagination input and navigation
  const fetchMoreData = nextPage => {
    // use to fetch more data to gql useQuery
    setLoading(true);
    fetchMore({
      variables: {
        first: rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_clients: {
            count: fetchMoreResult.get_clients.count,
            total: fetchMoreResult.get_clients.total,
            clients: [
              ...prev.get_clients.clients,
              ...fetchMoreResult.get_clients.clients
            ]
          }
        };
      }
    })
      // return sweet alert if error occured while fetching the data
      .catch(() => {
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            refetch(getVariables());
          }
        });
      });
  };

  // Function used to navigate to next page
  const handleUpPage = () => {
    // set page to current page incremented by 1
    setPage(page + 1);
    // check if the requested page data is already fetch
    if (
      page * rowsPerPage === currentData.get_clients.clients.length ||
      page * rowsPerPage >= currentData.get_clients.clients.length
    ) {
      fetchMoreData(page);
    }
  };

  // Function used to navigate using input value
  const handlePage = e => {
    const { value } = e.target;
    // check if page is less than the inputted value
    if (page < value) {
      // check if the input value is greater than the total page
      if (value > Math.floor(currentData.get_clients.total / rowsPerPage)) {
        // if false
        setPage(1);
      } else {
        // if true
        // loop the input value to request the data without skipping other page
        for (let i = 1; i <= value; i += 1) {
          // check if the value is already fetch to the server
          if (
            i * rowsPerPage > currentData.get_clients.clients.length &&
            (i - 1) * rowsPerPage >= currentData.get_clients.clients.length
          ) {
            fetchMoreData(i - 1);
          }
        }
        // set page based on user's input
        setPage(value);
      }
    }
    // check if the input value is zero
    else if (Number(value) === 0) {
      // set page to default
      setPage(1);
    } else {
      // set page based on user's input
      setPage(value);
    }
  };

  // Function used to navigate to previous page
  const handleDownPage = () => {
    // set page to previous page value
    setPage(page - 1);
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
  };

  const ClientsTable = props => {
    const { canEdit } = props;
    return currentData && currentData?.get_clients?.clients.length ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  disabled={!canEdit}
                  onClick={event => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <Highlight search={contextData.searchKeyword.value}>
                  {row.name}
                </Highlight>
              </TableCell>
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {row.category}
                </Highlight>
              </TableCell>
              {/* <TableCell>{row.no_of_partners}</TableCell> */}
              <TableCell>
                <Highlight search={contextData.searchKeyword.value}>
                  {moment(row.access_end_date).format("MMM DD, YYYY")}
                </Highlight>
              </TableCell>
              <TableCell>
                {dateTimeToday.isAfter(row.access_end_date) ? (
                  <p style={{ fontWeight: "bold", color: "#FF6363" }}>
                    <Highlight search={contextData.searchKeyword.value}>
                      Expired
                    </Highlight>
                  </p>
                ) : (
                  <p style={{ fontWeight: "bold", color: "#0FCC0B" }}>
                    <Highlight search={contextData.searchKeyword.value}>
                      Active
                    </Highlight>
                  </p>
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                <IconButton
                  aria-label="view"
                  onClick={() => {
                    // setSearchKeyword({ ...searchKeyword, value: "" });
                    AddLogs("Admin - Clients", "view", row.name);
                    // setting values of current filter values
                    history.push({
                      // pathname: "/admin/wti/clients/view",
                      pathname: `/admin/wti/clients/view/${row.id}`,
                      state: {
                        params: {
                          id: row.id,
                          group_ids: row.group_ids,
                          group_names: row.group_names,
                          moduleName: process.env.REACT_APP_CLIENTS_MODULE
                        },
                        vars: getVariables(),
                        filter: filter
                      }
                    });
                  }}
                >
                  <VisibleIcon />
                </IconButton>
                <IconButton
                  disabled={!canEdit}
                  aria-label="delete"
                  onClick={() => {
                    handleToDelete(row.id);
                    handleOpen();
                    setKeyDel(row.name);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const handleSetFilters = () => {
    const temp = [];
    let category = "";
    let start = "";
    let end = "";
    let status = "";
    Object.keys(tempFilter)
      .filter(key => tempFilter[key])
      .map(key => {
        if (key === "dateFrom") {
          start = tempFilter.dateFrom;
          end = tempFilter.dateTo;
          temp.push({
            field: key,
            value: {
              start: tempFilter.dateFrom,
              end: tempFilter.dateTo,
              field: "access_end_date"
            }
          });
        } else {
          if (key === "category") {
            category = tempFilter[key];
          } else if (key === "status") {
            status = tempFilter[key];
          }
          temp.push({ field: key, value: tempFilter[key] });
        }
        return temp;
      });
    setFilter(temp);
    setKeyLog(
      `${category ? `Category: ${category}, ` : ""}${
        start && end ? `Access End Date: ${start} - ${end}, ` : ""
      }${status ? `Status: ${status}` : ""}`
    );
    AddLogs(
      "Admin - Clients",
      "filter",
      `${category ? `Category: ${category}, ` : ""}${
        start && end ? `Access End Date: ${start} - ${end}, ` : ""
      }${status ? `Status: ${status}` : ""}`
    );
    setPage(1)
    setLoading(true);
    closeFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilter({
      category: "",
      dateFrom: null,
      dateTo: null,
      status: ""
    });
    setTempFilter({
      ...tempFilter,
      category: "",
      dateFrom: null,
      dateTo: null,
      status: ""
    });
  };

  const [deleteAdminClients] = useMutation(DELETE_ADMIN_CLIENTS, {
    onCompleted(res) {
      const [success, errors] = res.delete_client.reduce(
        (accum, current) => {
          if (current.success) accum[0].push(current);
          else accum[1].push(current);

          return accum;
        },
        [[], []]
      );

      if (keyDel) {
        AddLogs("Admin - Clients", "delete", keyDel);
      }

      handleClose();
      if (errors.length === 0) {
        Swal.fire({
          title: "Delete",
          icon: "success",
          showConfirmButton: false,
          timer: 3000
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            setSelected([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      } else {
        Swal.fire({
          html: `<p class='custom-title'>Unable to delete</p>
            <p class='custom-subtitle'><small>${errors[0].error[0]}</small></p>`,
          // icon: "success",
          showConfirmButton: true,
          width: "28rem",
          buttonsStyling: false,
          focusConfirm: false,
          confirmButtonText: `<span class="custom-alert-button">Okay</span>`,
          customClass: {
            confirmButton: `${classes.modal_confirm_button}`,
            container: "custom-container"
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setLoading(true);
            setSelected([]);
            setPage(1);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (
      currentData.get_clients.count === 1 &&
      currentData.get_clients.total > 1
    )
      setPage(page - 1);
    deleteAdminClients({
      variables: { ids: toDelete }
    });
  };
  const handleDeleteClients = () => {
    const selectedName = [];
    if (selected.length > 0) {
      if (selected.length > 0) {
        selected.map(id =>
          selectedName.push({ field: "clients.id", value: id })
        );
      }
      getSelected({
        variables: {
          first: selected.length,
          filter: selectedName
        }
      });
    }
    deleteAdminClients({
      variables: { ids: selected }
    });
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dataToArray = () => {
    const temp = [
      [
        "Client Name",
        "Category",
        "No. of Users",
        "No. of Devices",
        "No. of Geofences",
        "Access End Date",
        "Data Admin Email",
        "Remarks"
      ]
    ];
    getData().map(row =>
      temp.push([
        row.name || "",
        row.category || "",
        row.user_count || 0,
        row.device_count || 0,
        row.geofence_count || 0,
        row.access_end_date || "",
        row.data_admin || "",
        row.remarks || ""
      ])
    );

    return temp;
  };

  // Function: Block other inputs beside number/integer
  const handleKeyPress = event => {
    // get the event value based on keyCode or which
    const keyCode = event.keyCode || event.which;
    // convert variable keyCode into a string
    const keyValue = String.fromCharCode(keyCode);
    // check if variable keyValue is NOT number or integer
    if (/[^0-9]/g.test(keyValue)) {
      // if true
      // blocked the value of keyValue
      event.preventDefault();
    }
  };

  const DlMenu = props => {
    const { tableData } = props;

    const tableDownload = () => {
      return (
        <CSVLink
          style={{ textDecoration: "none", color: "black" }}
          data={tableData}
        >
          Download Table
        </CSVLink>
      );
    };

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {/* <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Clients", "download_template", "")
                    }
                  >
                    <Link
                      to="/assets/client_upload_template.xlsx"
                      target="_blank"
                      download
                      underline="none"
                    >
                      Download Template
                    </Link>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() =>
                      AddLogs("Admin - Clients", "download_table", keyLog)
                    }
                  >
                    {tableDownload()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <AccessControl
      resource="/admin/wti/clients/"
      process="tables"
      setCanDelete={setcanEdit}
    >
      <div>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            setAnchorEl={setAnchorEl}
            handleToggle={handleToggle}
            handleOpenDeleteAll={handleOpenDeleteAll}
            numSelected={selected.length}
            numberOfRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePagination={handlePagination}
            openFilterDrawer={openFilterDrawer}
            handleUpPage={handleUpPage}
            handleDownPage={handleDownPage}
            handleKeyPress={handleKeyPress}
            handlePage={handlePage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            getVariables={getVariables}
            refetch={refetch}
            totalRows={currentData?.get_clients.total}
            fetchMoreData={fetchMoreData}
            dataLength={currentData?.get_clients?.clients.length || 0}
            canEdit={canEdit}
            // setCircularLoading={setCircularLoading}
            setLoading={setLoading}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={currentData?.get_clients.clients.length}
                handleSort={handleSort}
                isChecked={isChecked}
                canEdit={canEdit}
              />
              <ClientsTable
                selected={selected}
                clientData={currentData?.get_clients.clients}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                canEdit={canEdit}
              />
            </Table>
          </TableContainer>
        </Paper>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_CLIENTS_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
          history={() => clearHistory()}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink required>
              Category
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.category}
              onChange={e => {
                handleFilters(e);
                clearHistory();
              }}
              displayEmpty
              name="category"
              fullWidth
            >
              <MenuItem value="" disabled>
                <em style={{ color: "#8e8e8e" }}>Select Category</em>
              </MenuItem>
              {[
                "Division",
                "Business Unit",
                "Terminal",
                "Distribution Center",
                "Hardware"
              ].map(category => (
                <MenuItem key={`${category}1`} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <span
              style={{
                textAlign: "left",
                marginBottom: "20px",
                fontSize: "14px"
              }}
            >
              Access End Date
            </span>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                //disableFuture
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                    </InputAdornment>
                  ),
                  name: "dateFrom"
                }}
                value={tempFilter.dateFrom}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateFrom: date.format("YYYY-MM-DD HH:mm:ss")
                  });
                  clearHistory();
                }}
                label="From"
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                classes={{ root: formStyles.textFieldRoot }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "black",
                    fontWeight: "bold",
                    letterSpacing: 1
                  }
                }}
                format="MM/DD/YYYY HH:mm"
                ampm={false}
                //disableFuture
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon fontSize="small" style={{ color: "#ccc" }} />
                    </InputAdornment>
                  ),
                  name: "dateTo"
                }}
                showTodayButton
                todayLabel="NOW"
                value={tempFilter.dateTo}
                onChange={date => {
                  setTempFilter({
                    ...tempFilter,
                    dateTo: date.format("YYYY-MM-DD HH:mm:ss")
                  });
                  clearHistory();
                }}
                label="To"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto" }}
            classes={{ root: formStyles.formRoot }}
          >
            <InputLabel shrink required>
              Status
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.status}
              onChange={e => {
                handleFilters(e);
                clearHistory();
              }}
              displayEmpty
              name="status"
              fullWidth
            >
              <MenuItem value="" disabled>
                <em style={{ color: "#8e8e8e" }}>Select Status</em>
              </MenuItem>
              {["All", "Active", "Expired"].map(category => (
                <MenuItem key={`${category}1`} value={category.toLowerCase()}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Filter>
        {canEdit && (
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            style={{
              margin: 0,
              top: "auto",
              right: 40,
              bottom: 30,
              left: "auto",
              position: "fixed",
              fontSize: "200px"
            }}
            onClick={() => {
              // setting values of current filter values
              history.push({
                pathname: "/admin/wti/clients/add",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_CLIENTS_MODULE
                  },
                  vars: getVariables(),
                  filter: filter
                }
              });
            }}
          >
            <AddIcon
              style={{
                width: "50px",
                height: "50px"
              }}
              // onClick={() => setSearchKeyword({ ...searchKeyword, value: "" })}
            />
          </Fab>
        )}
        <ConfirmationDialog
          toggle={dialog}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this client?"
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteClients(e)}
          title="Delete?"
          content="Are you sure you want to delete these clients?"
        />
        <ConfirmationDialog
          toggle={!!location?.state?.params?.newClient}
          close={() => {
            const state = { ...location.state };
            delete state.params.newClient;
            history.replace({ ...history.location, state });
          }}
          fn={e => {
            const state = { ...location.state };
            state.params.moduleName =
              process.env.REACT_APP_ALERT_REFERENCES_MODULE;
            history.push({ ...history.location, state });
          }}
          title="No Alert Setup"
          content="There are no alerts set up yet. Do you want to set up alerts?"
        />
        {open && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleListKeyDown={handleListKeyDown}
            handleClosePopper={handleClosePopper}
            tableData={dataToArray()}
          />
        )}
      </div>
    </AccessControl>
  );
}
