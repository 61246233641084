/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import Cookie from "js-cookie";
import AccessControl from "../../../AccessControl";
import Focus from "../../common/Focus";
import Stage from "../../common/Stage/Stage";
import { useOnboardingContext } from "../..";
import feedback_welcome_image from "../../images/help-and-feedback.svg";

const questions = require("../../../../data/questions.json");

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    display: "flex",
    height: "calc(100vh - 100px)"
  },
  gridContainer: {
    margin: "20px",
    position: "sticky",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  gridContainerContent: {
    margin: "20px",
    height: "100%"
  },
  panel: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  panelSummary: {
    color: theme.palette.primary.main
  },
  detailsPaper: {
    position: "fixed",
    width: "20%",
    height: "calc(100vh - 150px)"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  menuText: {
    fontSize: 14,
    letterSpacing: 1.2,
    fontWeight: "bold",
    cursor: "pointer",
    margin: "10px"
  },
  menuTextActive: {
    fontSize: 14,
    letterSpacing: 1.2,
    fontWeight: "bold",
    cursor: "pointer",
    color: theme.palette.primary.main,
    margin: "10px"
  },
  subMenuText: {
    fontSize: 14,
    letterSpacing: 1.2,
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft: "35px",
    margin: "10px"
  },
  title: {
    textAlign: "center",
    marginBottom: "50px",
    fontWeight: "bold"
  },
  moduleName: {
    marginTop: "30px",
    ontSize: 14,
    letterSpacing: 1.2,
    fontWeight: "bold"
  }
}));

const Feedback = () => {
  const classes = useStyles();
  const [view, setView] = useState(0); // eslint-disable-line no-unused-vars
  const [expanded, setExpanded] = useState(false);
  const user = JSON.parse(Cookie.get("user")); // eslint-disable-line no-unused-vars
  const {
    // module,
    progress: { feedback: currentStep },
    completeOnboarding
  } = useOnboardingContext();

  const stage = {
    0: {
      image: feedback_welcome_image,
      title: "Help and Feedback:",
      description: "Discover and learn information how things work",
      buttonText: "Got It"
    },
    4: {
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };
  return (
    <AccessControl process="feedback" resource="/feedback/">
      <div className={classes.root} md={12}>
        <Focus
          step={[0, 4]}
          on={[0, 4].includes(currentStep)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: [0, 4].includes(currentStep) ? "block" : "none",
            background: "transparent"
          }}
          showInfoBubble={false}
        >
          <Stage {...stage[currentStep]} />
        </Focus>
        <Focus
          step={1}
          infoBubbleProps={{
            title: "Menu",
            description: "Learn and find out how things work in TM Suite"
          }}
          component={Grid}
          item
          md={3}
          className={classes.gridContainer}
        >
          <Paper className={classes.detailsPaper}>
            <div style={{ padding: 24 }} id="faq-container">
              <Typography
                className={
                  view === 0 ? classes.menuTextActive : classes.menuText
                }
                // onClick={() => setView(0)}
                noWrap
              >
                FAQs
              </Typography>
              {questions.data.map((list, index) => (
                <Typography
                  key={`menu-module-${index}`}
                  className={classes.subMenuText}
                  noWrap
                  // onClick={() => {
                  //   setView(0);
                  //   const targetElemet = document.getElementById(
                  //     `faq-${index}`
                  //   );
                  //   if (targetElemet) {
                  //     const headerOffset = 80;
                  //     const elementPosition = targetElemet.getBoundingClientRect()
                  //       .top;
                  //     const offsetPosition =
                  //       elementPosition + window.pageYOffset - headerOffset;

                  //     window.document.querySelector("#root main").scrollTo({
                  //       behavior: "smooth",
                  //       top: offsetPosition
                  //     });
                  //   }
                  // }}
                >
                  {list.module}
                </Typography>
              ))}
              <Typography
                className={
                  view === 1 ? classes.menuTextActive : classes.menuText
                }
                // onClick={() => setView(1)}
                noWrap
              >
                Feedback
              </Typography>
              <Typography
                className={classes.menuText}
                noWrap
                // onClick={() => window.open(webcastContactLink, "_blank")}
              >
                Contact Us
              </Typography>
            </div>
          </Paper>
        </Focus>
        <Grid item md={9} className={classes.gridContainerContent}>
          {view === 0 ? (
            <>
              <Typography className={classes.title} variant="h5" noWrap>
                Frequently Asked Questions?
              </Typography>
              <div style={{ paddingBottom: "40px" }}>
                {questions.data.map((list, index) => (
                  <div key={`module-${index}`} id={`faq-${index}`}>
                    <Typography className={classes.moduleName} noWrap>
                      {list.module}
                    </Typography>
                    {list.questions.map((q, i) => {
                      return (
                        <Focus
                          step={[2, 3]}
                          on={
                            (index === 0 && i === 1 && currentStep === 2) ||
                            (expanded &&
                              currentStep === 3 &&
                              index === 0 &&
                              i === 1)
                          }
                          showInfoBubble={currentStep === 2}
                          infoBubbleProps={{
                            position: "left",
                            title: "Frequent Questions",
                            description: "Common questions you might ask about"
                          }}
                        >
                          <Accordion
                            key={`question-${i}`}
                            expanded={
                              expanded &&
                              index === 0 &&
                              i === 1 &&
                              currentStep === 3
                            }
                            className={classes.panel}
                            style={{
                              pointerEvents: currentStep === 3 ? "all" : "none"
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={
                              //   <ArrowDropDownCircleOutlined
                              //     className={classes.panelSummary}
                              //   />
                              // }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              // className={classes.panelSummary}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%"
                                }}
                              >
                                <span className={classes.panelSummary}>
                                  {q.question}
                                </span>
                                <Focus
                                  step={3}
                                  on={index === 0 && i === 1}
                                  showInfoBubble={!expanded}
                                  indicator={
                                    index === 0 &&
                                    i === 1 &&
                                    // !expanded &&
                                    currentStep === 3
                                  }
                                  indicatorProps={{
                                    yOffset: -5,
                                    xOffset: -5
                                  }}
                                  infoBubbleProps={{
                                    position: "left",
                                    title: "Expand",
                                    description:
                                      "Click to see the answer of the specific question"
                                  }}
                                >
                                  <IconButton
                                    className={classes.panelSummary}
                                    onClick={() => setExpanded(prev => !prev)}
                                  >
                                    <ArrowDropDownCircleOutlined
                                      style={{
                                        transform: expanded
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)"
                                      }}
                                    />
                                  </IconButton>
                                </Focus>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>{q.answer}</AccordionDetails>
                          </Accordion>
                        </Focus>
                      );
                    })}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <Typography className={classes.title} variant="h5" noWrap>
                How was your experience?
              </Typography>
              <div>
                <TextField
                  placeholder="Type your review and suggestions here."
                  multiline
                  rows="8"
                  value=""
                  // onChange={e => setMessage(e.target.value)}
                  variant="outlined"
                  fullWidth
                  className={classes.multiline}
                  helperText="0/500"
                  FormHelperTextProps={{ style: { textAlign: "right" } }}
                  inputProps={{ maxLength: 500 }}
                />
              </div>

              <div style={{ textAlign: "center", width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  // onClick={() => setShowDialog(true)}
                  disabled
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </Grid>
      </div>
    </AccessControl>
  );
};

export default withRouter(Feedback);
