/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { FormControl, InputLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useFormStyles from "../../../../styles/useFormStyles";
import { GET_CLIENTS_OPTIONS } from "../../../../graphql/Queries";

const ClientsSearchableInput = React.forwardRef((_, ref) => {
  const [selectedClient, setSelectedClient] = useState(ref.current);
  const [keyword, setKeyword] = useState("");
  const { data, loading } = useQuery(GET_CLIENTS_OPTIONS, {
    variables: {
      first: 5,
      keyword
    }
  });

  React.useEffect(() => {
    if (!ref.current) {
      setSelectedClient(null);
    }
  }, [ref.current]);

  const handleOnChanged = (_props_, newValue) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = newValue;
    setSelectedClient(newValue);
  };

  const formStyles = useFormStyles();
  return (
    <FormControl
      classes={{ root: formStyles.formRoot }}
      style={{ width: "100%", minWidth: "auto" }}
    >
      <InputLabel shrink>Client</InputLabel>
      <br />
      <Autocomplete
        loading={loading}
        id="clients"
        options={data?.get_clients?.clients || []}
        value={selectedClient}
        getOptionLabel={option => option.label || ""}
        renderOption={option => option.label}
        onChange={handleOnChanged}
        // disableClearable
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: formStyles.textFieldRoot }}
            placeholder="All"
            onChange={(e) => {setKeyword(e.target.value)}}
            InputProps={{
              ...params.InputProps,
              style: {
                padding: "6px 0px"
              },
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </FormControl>
  );
});

export default ClientsSearchableInput;
