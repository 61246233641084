import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  input_fontSize: {
    fontSize: "13px"
  },
  label: {
    color: "black"
  },
  paper_inputCard: {
    backgroundColor: "#FFFFFF",
    height: "calc(100vh - 180px)",
    maxHeight: "532px",
    width: "320px",
    maxWidth: "320px",
    borderRadius: "15px",
    marginLeft: "24px",
    marginTop: "6px",
    padding: "8px 31px",
    boxShadow: "0px 4px 4px 0px #00000040",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      display: "none"
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece:end": {
      marginBottom: "10px"
    },
    "&::-webkit-scrollbar-track-piece:start": {
      marginTop: "10px"
    }
  },
  button_getDirections: {
    height: "32px",
    width: "100%",
    border: "1px",
    borderRadius: "20px",
    borderStyle: "solid",
    borderColor: "#F49400",
    textTransform: "none",
    fontSize: "12px",
    padding: "0px 0px",
    "&:hover": {
      backgroundColor: "#F49400"
    }
  },

  disabled_button: {
    height: "32px",
    width: "100%",
    border: "1px",
    borderRadius: "20px",
    borderStyle: "solid",
    borderColor: "#F49400",
    textTransform: "none",
    fontSize: "12px",
    padding: "0px 0px",
    "&.Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
      borderColor: "gray",
      opacity: 0.8
    }
  },

  slider_label: {
    color: "black",
    fontSize: "12px"
  },
  root: {
    marginBottom: "5px"
  },
  switchBase: {
    padding: 1,
    color: "white",
    "&$checked": {
      transform: "translateX(5px)",
      color: "blue",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#F49400",
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    maxHeight: 11,
    maxWidth: 11,
    marginTop: "3px",
    left: 3
  },
  track: {
    borderRadius: 19,
    backgroundColor: "#E9E9E9",
    opacity: 1,
    height: 12,
    width: 22
  },

  start_active: {
    // backgroundColor: "rgba(69, 167, 217, 0.6)",
    borderRadius: "5px",
    boxShadow: "1px 2px 40px 22px rgba(0,0,0,0.15)",
    "&::-webkit-box-shadow": "1px 2px 40px 22px rgba(0,0,0,0.15)",
    "&::-moz-box-shadow": "1px 2px 40px 22px rgba(0,0,0,0.15)",
    padding: "5px",
    marginBottom: "5px"
  },



  edit_root: {
    width: 36,
    height: 17,
    padding: 0,
    top: 0
  },
  edit_switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  edit_thumb: {
    width: 13,
    height: 13,
    boxShadow: "none",
    marginTop: "-4px"
  },
  edit_track: {
    background: "#aaa",
    borderRadius: 8
  },
}));
