import React from "react";
import { TableContainer, Table } from "@material-ui/core";

const CustomTable = ({ children }) => {
  return (
    <TableContainer>
      <Table>{children}</Table>
    </TableContainer>
  );
};

export default CustomTable;
