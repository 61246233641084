const data = [
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE001",
    service_type: "Resupply",
    vehicle_id: "26036",
    vehicle_history: [
      {
        vehicle_id: "26036",
        vehicle_plate_no: "PLATE001",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-05 20:06:58"
      }
    ],
    vehicle_type: "",
    driver: "MARK PAUL BAGUL BAGUL",
    is_urgent: false,
    trip_status: "Incoming",
    tripNumber: "TRIP0001",
    raw_pickups: [
      {
        geofence_id: "329",
        name: "Location",
        address: null,
        arrival: "2022-09-05 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839870435",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "329",
        name: "Location",
        address: null,
        arrival: "2022-09-05 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839885233",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "30",
        name: "Location",
        address: null,
        arrival: "2022-08-14 14:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 14:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839870435",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "NHBhcIIBNvdaHUgMZ4ZO"
      },
      {
        geofence_id: "30",
        name: "Location",
        address: null,
        arrival: "2022-08-14 14:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 14:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839885233",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "W2FhcIIBq6WfgOn8aHdM"
      }
    ],
    hauler: "WTI Logistics",
    total: 2,
    has_split: false,
    raw_trip_status: "Incoming",
    out_of_route: false,
    is_overdue: true,
    dispatch_type: "Automatic",
    status_code_id: 27,
    dropoffs: [
      {
        geofence_id: "30",
        name: "Location",
        address: null,
        arrival: "2022-08-14 14:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 14:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839870435",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "NHBhcIIBNvdaHUgMZ4ZO"
      }
    ],
    pickups: [
      {
        geofence_id: "329",
        name: "Location",
        address: null,
        arrival: "2022-09-05 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "839870435",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Cabuyao FG UHT - Source",
    first_pickup_arrival: "2022-09-05 00:00:00",
    first_dropoff: "PH Batino Grocery - Destination",
    first_dropoff_arrival: "2022-08-14 14:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "Inactive",
    vehicle_plate_no: "PLATE002",
    service_type: null,
    vehicle_id: "17154",
    vehicle_history: [
      {
        vehicle_id: "17154",
        vehicle_plate_no: "PLATE002",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-09-05 17:46:45"
      }
    ],
    vehicle_type: "4W",
    driver: "",
    is_urgent: false,
    trip_status: "Incoming",
    tripNumber: "TRIP0002",
    raw_pickups: [
      {
        geofence_id: "8083",
        name: "Location",
        address: null,
        arrival: "2022-09-06 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-06 17:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "test",
        so_number: "SO0905newstg002",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "8084",
        name: "Location",
        address: null,
        arrival: "2022-09-06 18:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-06 18:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "SO0905newstg002",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "CoYHDYMBKzLDlwB9-cqK"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: false,
    raw_trip_status: "Incoming",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Manual",
    status_code_id: 27,
    dropoffs: [
      {
        geofence_id: "8084",
        name: "Location",
        address: null,
        arrival: "2022-09-06 18:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-06 18:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "",
        so_number: "SO0905newstg002",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "CoYHDYMBKzLDlwB9-cqK"
      }
    ],
    pickups: [
      {
        geofence_id: "8083",
        name: "Location",
        address: null,
        arrival: "2022-09-06 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-09-06 17:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: null,
        contact_no: null,
        location_details: "test",
        so_number: "SO0905newstg002",
        issue: null,
        eta: null,
        actual_eta: null,
        eta_modified: null,
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "stagingeo01",
    first_pickup_arrival: "2022-09-06 08:00:00",
    first_dropoff: "stagingeo02",
    first_dropoff_arrival: "2022-09-06 18:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE003",
    service_type: "Direct to customer",
    vehicle_id: "21310",
    vehicle_history: [
      {
        vehicle_id: "21310",
        vehicle_plate_no: "PLATE003",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 13:55:41"
      }
    ],
    vehicle_type: "",
    driver: "JOSE ELEAZAR",
    is_urgent: false,
    trip_status: "Arrived at Pickup",
    tripNumber: "TRIP0003",
    raw_pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710772",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710773",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710777",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710775",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710774",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681046",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710776",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681044",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710778",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710772",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "Fdd2k4IBNvdaHUgMBvFl"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710773",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "Edd2k4IBNvdaHUgMBPGK"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710777",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "egx2k4IBKzLDlwB9B09X"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710775",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "Etd2k4IBNvdaHUgMBPG-"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710774",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "BXh2k4IB5__gxClfBgSY"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681046",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "eAx2k4IBKzLDlwB9BU9Z"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710776",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "CXh2k4IB5__gxClfCQQB"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681044",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "dgx2k4IBKzLDlwB9BU8q"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710778",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "CHh2k4IB5__gxClfBwT5"
      }
    ],
    hauler: "WTI Logistics",
    total: 9,
    has_split: true,
    raw_trip_status: "Arrived at Pickup",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 15,
    dropoffs: [
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-17 03:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-17 03:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710772",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "Fdd2k4IBNvdaHUgMBvFl"
      }
    ],
    pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-15 11:00:00",
        actual_arrival: "2022-08-15 10:08:43",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710772",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Bacolod - Mansilingan DC - Source",
    first_pickup_arrival: "2022-08-15 11:00:00",
    first_dropoff: "PH Transshipment Hub Panay",
    first_dropoff_arrival: "2022-08-17 03:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE004",
    service_type: "Direct to customer",
    vehicle_id: "25510",
    vehicle_history: [
      {
        vehicle_id: "25510",
        vehicle_plate_no: "PLATE004",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 22:52:16"
      }
    ],
    vehicle_type: "",
    driver: "BENJAMIN MAHILUM",
    is_urgent: false,
    trip_status: "Arrived at Drop off",
    tripNumber: "TRIP0004",
    raw_pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: "",
        arrival: "2022-08-15 01:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710631",
        issue: "",
        eta: "1874",
        actual_eta: "1874",
        eta_modified: "2022-08-15 10:17:04",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "GF565XIBp1pll5ObQGo7",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:17:04",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710631",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "09illIIBNvdaHUgMFAnB"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: false,
    raw_trip_status: "Arrived at Drop off",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 24,
    dropoffs: [
      {
        geofence_id: "GF565XIBp1pll5ObQGo7",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:17:04",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710631",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        trip_id: "09illIIBNvdaHUgMFAnB"
      }
    ],
    pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: "",
        arrival: "2022-08-15 01:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710631",
        issue: "",
        eta: "1874",
        actual_eta: "1874",
        eta_modified: "2022-08-15 10:17:04",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Meycauayan Grocery - Source",
    first_pickup_arrival: "2022-08-15 01:00:00",
    first_dropoff: "AFPCES - Med. Admin.",
    first_dropoff_arrival: "2022-08-15 08:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE005",
    service_type: "Direct to customer",
    vehicle_id: "21104",
    vehicle_history: [
      {
        vehicle_id: "21104",
        vehicle_plate_no: "PLATE005",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 12:24:06"
      }
    ],
    vehicle_type: "",
    driver: "REYNALDO CASTRO",
    is_urgent: false,
    trip_status: "Arrived at Pickup",
    tripNumber: "TRIP0005",
    raw_pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: "",
        arrival: "2022-08-14 13:00:00",
        actual_arrival: "2022-08-15 10:12:25",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059524844",
        issue: "",
        eta: "164",
        actual_eta: "164",
        eta_modified: "2022-08-15 10:09:59",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "lXHdnXIBcGKWaoLd7rWT",
        name: "Location",
        address: "",
        arrival: "2022-08-15 09:13:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 09:13:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059524844",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "agzskoIBKzLDlwB9VEgZ"
      }
    ],
    hauler: "WTI Logistics",
    total: 1,
    has_split: true,
    raw_trip_status: "Arrived at Pickup",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 15,
    dropoffs: [
      {
        geofence_id: "lXHdnXIBcGKWaoLd7rWT",
        name: "Location",
        address: "",
        arrival: "2022-08-15 09:13:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 09:13:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059524844",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "agzskoIBKzLDlwB9VEgZ"
      }
    ],
    pickups: [
      {
        geofence_id: "FnEMnnIBcGKWaoLdzblp",
        name: "Location",
        address: "",
        arrival: "2022-08-14 13:00:00",
        actual_arrival: "2022-08-15 10:12:25",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059524844",
        issue: "",
        eta: "164",
        actual_eta: "164",
        eta_modified: "2022-08-15 10:09:59",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Meycauayan Grocery - Source",
    first_pickup_arrival: "2022-08-14 13:00:00",
    first_dropoff: "Grand Chamonix Marketing Inc.",
    first_dropoff_arrival: "2022-08-15 09:13:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE006",
    service_type: "Direct to customer",
    vehicle_id: "21267",
    vehicle_history: [
      {
        vehicle_id: "21267",
        vehicle_plate_no: "PLATE006",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 13:54:29"
      }
    ],
    vehicle_type: "",
    driver: "JOHNSON ESPINOSA",
    is_urgent: false,
    trip_status: "Arrived at Drop off",
    tripNumber: "TRIP0006",
    raw_pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649719",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059701108",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682155",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059690101",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649720",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681157",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649719",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "pdhDlIIBNvdaHUgM1wAF"
      },
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059701108",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "nHtDlIIB5__gxClf1_zO"
      },
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682155",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "o9hDlIIBNvdaHUgM1gDQ"
      },
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059690101",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "m3tDlIIB5__gxClf1_w0"
      },
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649720",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "pthDlIIBNvdaHUgM1wCK"
      },
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059681157",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "oAxDlIIBKzLDlwB92Fl5"
      }
    ],
    hauler: "WTI Logistics",
    total: 6,
    has_split: false,
    raw_trip_status: "Arrived at Drop off",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 24,
    dropoffs: [
      {
        geofence_id: "63HfnXIBcGKWaoLdGrU0",
        name: "Location",
        address: "",
        arrival: "2022-08-15 00:32:00",
        actual_arrival: "2022-08-15 10:09:08",
        manual_arrival: null,
        departure: "2022-08-15 00:32:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649719",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        trip_id: "pdhDlIIBNvdaHUgM1wAF"
      }
    ],
    pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-14 02:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059649719",
        issue: "",
        eta: "138",
        actual_eta: "138",
        eta_modified: "2022-08-15 10:09:08",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Bacolod - Mansilingan DC - Source",
    first_pickup_arrival: "2022-08-14 02:00:00",
    first_dropoff: "No. 1 Supplier Inc. - Bacolod",
    first_dropoff_arrival: "2022-08-15 00:32:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE007",
    service_type: "Direct to customer",
    vehicle_id: "21304",
    vehicle_history: [
      {
        vehicle_id: "21304",
        vehicle_plate_no: "PLATE007",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 13:54:38"
      }
    ],
    vehicle_type: "",
    driver: "RICO PUGOY",
    is_urgent: false,
    trip_status: "Arrived at Drop off",
    tripNumber: "TRIP0007",
    raw_pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710764",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704680",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704675",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710669",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704672",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704673",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704679",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710764",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "ngx2k4IBKzLDlwB9KU9d"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704680",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "Ptd2k4IBNvdaHUgMKfG0"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704675",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "4nh2k4IB5__gxClfJgTu"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710669",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "53h2k4IB5__gxClfKgQl"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704672",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "QNd2k4IBNvdaHUgMK_G8"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704673",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "oQx2k4IBKzLDlwB9Kk_Q"
      },
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059704679",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "5Xh2k4IB5__gxClfKAR8"
      }
    ],
    hauler: "WTI Logistics",
    total: 7,
    has_split: false,
    raw_trip_status: "Arrived at Drop off",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 24,
    dropoffs: [
      {
        geofence_id: "NHHSnXIBcGKWaoLd0LN4",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: "2022-08-15 10:07:54",
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710764",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        trip_id: "ngx2k4IBKzLDlwB9KU9d"
      }
    ],
    pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 23:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059710764",
        issue: "",
        eta: "1290",
        actual_eta: "1290",
        eta_modified: "2022-08-15 10:07:54",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Bacolod - Mansilingan DC - Source",
    first_pickup_arrival: "2022-08-13 23:00:00",
    first_dropoff: "Gaisano City - Bacolod",
    first_dropoff_arrival: "2022-08-15 08:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE008",
    service_type: "Direct to customer",
    vehicle_id: "21218",
    vehicle_history: [
      {
        vehicle_id: "21218",
        vehicle_plate_no: "PLATE008",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-12 16:40:07"
      }
    ],
    vehicle_type: "",
    driver: "GLENN ENDERES",
    is_urgent: false,
    trip_status: "Arrived at Pickup",
    tripNumber: "TRIP0008",
    raw_pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059614749",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059683220",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059595520",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059657340",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059663373",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059614749",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "jmcHkIIB5__gxClfcQaw"
      },
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059683220",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "FNcHkIIBNvdaHUgMcqQv"
      },
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059595520",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "DtcHkIIBNvdaHUgMcKQf"
      },
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059657340",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "jGcHkIIB5__gxClfcAZK"
      },
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059663373",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "j2cHkIIB5__gxClfcgav"
      }
    ],
    hauler: "WTI Logistics",
    total: 5,
    has_split: true,
    raw_trip_status: "Arrived at Pickup",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 15,
    dropoffs: [
      {
        geofence_id: "ZbDfnXIBNasz_0cSJIEe",
        name: "Location",
        address: "",
        arrival: "2022-08-15 08:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-15 08:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059614749",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "jmcHkIIB5__gxClfcQaw"
      }
    ],
    pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 22:00:00",
        actual_arrival: "2022-08-15 10:07:36",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059614749",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Bacolod - Mansilingan DC - Source",
    first_pickup_arrival: "2022-08-13 22:00:00",
    first_dropoff: "No. 1 Supplier Inc. - Dumaguete",
    first_dropoff_arrival: "2022-08-15 08:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE009",
    service_type: "Direct to customer",
    vehicle_id: "21259",
    vehicle_history: [
      {
        vehicle_id: "25419",
        vehicle_plate_no: "PLATE009",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 08:55:08"
      },
      {
        vehicle_id: "21259",
        vehicle_plate_no: "PLATE009",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-13 10:55:40"
      }
    ],
    vehicle_type: "",
    driver: "RENATO NATIAL",
    is_urgent: false,
    trip_status: "Arrived at Drop off",
    tripNumber: "TRIP0009",
    raw_pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682483",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682622",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682494",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682488",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682497",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682482",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682780",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682630",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682491",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682643",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059441239",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682489",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      },
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682490",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682483",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "RFbGjYIB5__gxClf74bK"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682622",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "Z1bGjYIB5__gxClf9oZl"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682494",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "TgbGjYIBKzLDlwB98aFr"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682488",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "T1bGjYIB5__gxClf8oZj"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682497",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "QwbGjYIBKzLDlwB98KEy"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682482",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "QVbGjYIB5__gxClf74Zo"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682780",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "S1bGjYIB5__gxClf8YbP"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682630",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "RVbGjYIB5__gxClf8IZ9"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682491",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "HdLGjYIBNvdaHUgM9BxE"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682643",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "TwbGjYIBKzLDlwB986ET"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059441239",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "F9LGjYIBNvdaHUgM8xyb"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682489",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "SwbGjYIBKzLDlwB98aEA"
      },
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682490",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        items: [],
        trip_id: "Y1bGjYIB5__gxClf9YZ0"
      }
    ],
    hauler: "WTI Logistics",
    total: 13,
    has_split: false,
    raw_trip_status: "Arrived at Drop off",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 24,
    dropoffs: [
      {
        geofence_id: "ORKT3nQBNC5655PQTUDi",
        name: "Location",
        address: "",
        arrival: "2022-08-16 01:00:00",
        actual_arrival: "2022-08-15 10:09:18",
        manual_arrival: null,
        departure: "2022-08-16 01:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682483",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Arrived at Drop off",
        status_code_id: 24,
        geofence_code: "GEO",
        trip_id: "RFbGjYIB5__gxClf74bK"
      }
    ],
    pickups: [
      {
        geofence_id: "g7AMnnIBNasz_0cSzYfG",
        name: "Location",
        address: "",
        arrival: "2022-08-13 21:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059682483",
        issue: "",
        eta: "13437",
        actual_eta: "13437",
        eta_modified: "2022-08-15 10:09:18",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Bacolod - Mansilingan DC - Source",
    first_pickup_arrival: "2022-08-13 21:00:00",
    first_dropoff: "PH Transshipment Hub Panay",
    first_dropoff_arrival: "2022-08-16 01:00:00",
    eta: false,
    has_empty_so: false
  },
  {
    vehicle_status: "",
    vehicle_plate_no: "PLATE010",
    service_type: "Direct to customer",
    vehicle_id: "26871",
    vehicle_history: [
      {
        vehicle_id: "26871",
        vehicle_plate_no: "PLATE010",
        user_id: null,
        user_full_name: "Location",
        datestamp: "2022-08-12 11:24:30"
      }
    ],
    vehicle_type: "",
    driver: "Alexander CARIN",
    is_urgent: false,
    trip_status: "Arrived at Pickup",
    tripNumber: "TRIP0010",
    raw_pickups: [
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652447",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059605143",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652469",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652463",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059605138",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652593",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      },
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652468",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    raw_dropoffs: [
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652447",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "1T3yiIIBweCm2D-Vu7eC"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059605143",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "yr_yiIIBNvdaHUgMvWUS"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652469",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "4z3yiIIBweCm2D-Vvrey"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652463",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "1D3yiIIBweCm2D-Vurep"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059605138",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "3j3yiIIBweCm2D-VvrcN"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652593",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "7D3yiIIBweCm2D-VwLc4"
      },
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652468",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        items: [],
        trip_id: "E6_yiIIBq6WfgOn8waQS"
      }
    ],
    hauler: "WTI Logistics",
    total: 7,
    has_split: true,
    raw_trip_status: "Arrived at Pickup",
    out_of_route: false,
    is_overdue: false,
    dispatch_type: "Automatic",
    status_code_id: 15,
    dropoffs: [
      {
        geofence_id: "mFJ31XYBqz5fwwqFC7hE",
        name: "Location",
        address: "",
        arrival: "2022-08-14 00:00:00",
        actual_arrival: null,
        manual_arrival: null,
        departure: "2022-08-14 00:00:00",
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652447",
        issue: "",
        eta: "",
        actual_eta: "",
        eta_modified: "Invalid date",
        status_code: "Scheduled",
        status_code_id: 2,
        geofence_code: "GEO",
        trip_id: "1T3yiIIBweCm2D-Vu7eC"
      }
    ],
    pickups: [
      {
        geofence_id: "_ZwwoXwBUVOAUHM0z-ft",
        name: "Location",
        address: "",
        arrival: "2022-08-13 19:00:00",
        actual_arrival: "2022-08-15 10:12:28",
        manual_arrival: null,
        departure: null,
        actual_departure: null,
        manual_departure: null,
        consignee: "",
        contact_no: "",
        location_details: "",
        so_number: "7059652447",
        issue: "",
        eta: "250",
        actual_eta: "250",
        eta_modified: "2022-08-15 10:09:20",
        status_code: "Arrived at Pickup",
        status_code_id: 15,
        geofence_code: "GEO"
      }
    ],
    first_pickup: "PH Cebu Grocery - Source",
    first_pickup_arrival: "2022-08-13 19:00:00",
    first_dropoff: "Gaisano Grand - Oslob",
    first_dropoff_arrival: "2022-08-14 00:00:00",
    eta: false,
    has_empty_so: false
  }
];

export default data;
