/*eslint-disable no-unused-vars*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  alpha,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  // Tooltip,
  Typography
} from "@material-ui/core";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Search as SearchIcon
  // Delete as DeleteIcon
} from "@material-ui/icons";
import lodash from "lodash";
import Loading from "../../../../../utils/Loading";

import { GET_ADMIN_USERS } from "../../../../../graphql/Queries";
import { VIEW_CLIENT_USERS_SUBSCRIPTION } from "../../../../../graphql/Subscription";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username"
  },
  {
    id: "first_name",
    numeric: true,
    disablePadding: false,
    label: "Full Name"
  },
  {
    id: "user_level_id",
    numeric: true,
    disablePadding: false,
    label: "Access Right"
  },
  {
    id: "email_address",
    numeric: true,
    disablePadding: false,
    label: "Email Address"
  },
  {
    id: "contact_number",
    numeric: true,
    disablePadding: false,
    label: "Contact Number"
  },
  {
    id: "account_expiration",
    numeric: true,
    disablePadding: false,
    label: "Access End Date"
  },
  {
    id: "modified",
    numeric: true,
    disablePadding: false,
    label: "Last Login"
  }
  // { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    handleSort,
    editMode,
    isChecked
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {editMode && (
            <Checkbox
              color="primary"
              // disabled={!editMode}
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          )}
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  invisible: {
    display: "none"
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 25%",
    color: "#000000"
  },
  btn_download: {
    background: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  },
  btn_upload: {
    background: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");
  const {
    editMode,
    numSelected,
    numberOfRows,
    rowsPerPage,
    page,
    handleUpPage,
    handleDownPage,
    handlePagination,
    // maxUsers,
    totalRows,
    setUserKeyword,
    setPage
  } = props;

  return (
    <div>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} id="tableTitle" component="div">
          Users: {totalRows}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #D2D2D2",
              boxSizing: "border-box",
              borderRadius: 23,
              padding: "4px 10px 3px 10px",
              marginRight: 20
            }}
          >
            <InputBase
              placeholder="Search…"
              style={{ width: 300 }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={e => {
                setKeyword(e.target.value);
                // if (e.target.value.length > 3) {
                //   setUserKeyword(e.target.value);
                // } else if (e.target.value.length === 0) {
                //   setUserKeyword(e.target.value);
                // }
              }}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setUserKeyword(keyword);
                  setPage(0);
                }
              }}
            />
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <Typography>Rows per page:</Typography>
          <FormControl className={classes.field}>
            <Select
              value={rowsPerPage}
              onChange={e => {
                handlePagination(e);
              }}
              displayEmpty
              name="rows_per_page"
              style={{ padding: 0, margin: "0 8px" }}
              disableUnderline={!editMode}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            name="down_page"
            disabled={page === 0}
            onClick={() => handleDownPage()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          {numberOfRows > 0 ? page + 1 : page}
          {` / ${Math.ceil(numberOfRows / rowsPerPage)}`}
          <Button
            name="up_page"
            disabled={page + 1 === Math.ceil(numberOfRows / rowsPerPage)}
            onClick={() => handleUpPage()}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      </Toolbar>

      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
          [classes.invisible]: numSelected <= 0
        })}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            <span style={{ color: "#FF845E" }}>{numSelected}</span> user/s
            selected
          </Typography>
        )}

        {/* {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "70%",
    border: "2px solid rgba(209, 209, 209, 0.5)",
    borderRadius: 23
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "800%",
      "&:focus": {
        width: "100"
      }
    }
  }
}));

// MAIN FUNCTION
export default function Users(props) {
  const classes = useStyles();
  const { /* clientId, */ editMode, maxUsers, groupIds_str } = props;
  const { id: clientId } = useParams();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0); // setPage
  const [userKeyword, setUserKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10); // setRowsPerPage

  const [totalRows, setTotalRows] = useState(0);
  // const [countRows, setCountRows] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = usersData.map(n => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const getVariables = React.useCallback(() => {
    const variables = {
      keyword: userKeyword,
      skip: page * rowsPerPage,
      first: rowsPerPage,
      condition: [{ field: "users.client_id", value: clientId }],
      orderBy: [{ field: orderBy, direction: order }]
    };

    return variables;
  }, [orderBy, order, rowsPerPage, page, userKeyword]);

  const { data: currentData, refetch, fetchMore, subscribeToMore } = useQuery(
    GET_ADMIN_USERS,
    {
      variables: getVariables(),
      onCompleted: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (currentData) {
      setUsersData(currentData.get_users.users || []);
      setTotalRows(currentData.get_users.total);
      // setCountRows(currentData.get_users.count);
      setLoading(false);
      // setPage(0);
    }
  }, [currentData]);
  // if (error) {
  // setLoading(false);
  // }

  const getGroupIdsForSubs = () => {
    const data = [];
    for (let index = 0; index < groupIds_str.length; index += 1) {
      const element = groupIds_str[index];
      data.push({ field: "group_ids", value: element });
    }
    return data;
  };

  const subscribeToNewUsers = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: VIEW_CLIENT_USERS_SUBSCRIPTION,
      variables: {
        filter: getGroupIdsForSubs()
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newData = subscriptionData.data.data_updated.user;
        const mergedData = usersData;
        let subsData = {
          count: prev && prev.get_users ? prev.get_users.count : 0,
          total: prev && prev.get_users ? prev.get_users.total : 0,
          users: prev && prev.get_users ? prev.get_users.users : []
        };
        if (newData && newData.client) {
          mergedData.unshift(newData);
          setUsersData(mergedData);
          subsData = {
            count: prev.get_users.count + 1,
            total: prev.get_users.total + 1,
            users: mergedData
          };
        }
        return {
          ...prev,
          get_users: subsData
        };
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToNewUsers();
    return () => unsubscribe();
  }, [
    // usersData,
    // refetch(getVariables()),
    subscribeToNewUsers
  ]);

  const handlePagination = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    refetch(getVariables());
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  // const isSelected = name => selected.indexOf(name) !== -1;

  const handleUpPage = () => {
    const nextPage = page + 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page + 1);
      //     setUsersData(fetchMoreResult.get_users.users);
      //     // setPage(nextPage);
      //     return {
      //       ...prev,
      //       get_users: {
      //         count: fetchMoreResult.get_users.count,
      //         total: fetchMoreResult.get_users.total,
      //         users: fetchMoreResult.get_users.users
      //       }
      //     };
      //   }
      // });
    }
  };

  const handleDownPage = () => {
    const nextPage = page - 1;
    if (nextPage * rowsPerPage <= totalRows) {
      setPage(nextPage);
      // fetchMore({
      //   variables: {
      //     skip: nextPage * rowsPerPage
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     if (!fetchMoreResult) {
      //       return prev;
      //     }
      //     setPage(page - 1);
      //     setUsersData(fetchMoreResult.get_users.users);
      //     // setPage(nextPage);
      //     return {
      //       ...prev,
      //       get_users: {
      //         count: fetchMoreResult.get_users.count,
      //         total: fetchMoreResult.get_users.total,
      //         users: fetchMoreResult.get_users.users
      //       }
      //     };
      //   }
      // });
    }
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }
  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = () => {
    return usersData && usersData.length
      ? stableSort(usersData, getSorting(order, orderBy)).slice(0, rowsPerPage)
      : [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < getData().length) {
      const displayedData = getData().map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };
  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(lodash.uniq(tempSelected));
      return;
    }
    deselect();
  };
  const UsersTable = () => {
    return usersData && usersData.length ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                {editMode && (
                  <Checkbox
                    color="primary"
                    // disabled={!editMode}
                    onClick={event => handleClick(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                )}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.username ? row.username : "-"}
              </TableCell>
              <TableCell>
                {row.first_name ? `${row.first_name}, ${row.last_name}` : "-"}
              </TableCell>
              <TableCell>{row.user_level_name}</TableCell>
              <TableCell>
                {row.email_address ? row.email_address : "-"}
              </TableCell>
              <TableCell>
                {row.contact_number ? row.contact_number : "-"}
              </TableCell>
              <TableCell>
                {row.account_expiration ? row.account_expiration : "-"}
              </TableCell>
              <TableCell>{row.modified ? row.modified : "-"}</TableCell>

              {/* <TableCell component="th" scope="row">
                <IconButton aria-label="view">
                  <VisibleIcon />
                </IconButton>
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell> */}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={8} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: " inherit"
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          padding: 20,
          background: "#FFFFFF",
          minHeight: "740px"
        }}
      >
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            totalRows={totalRows}
            maxUsers={maxUsers}
            numSelected={selected.length}
            numberOfRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePagination={handlePagination}
            handleUpPage={handleUpPage}
            handleDownPage={handleDownPage}
            setUserKeyword={setUserKeyword}
            setPage={setPage}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                editMode={editMode}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={usersData.length}
                handleSort={handleSort}
                isChecked={isChecked}
              />
              <UsersTable
                selected={selected}
                usersData={usersData}
                classes={classes}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </div>
  );
}
