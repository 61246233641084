import React from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from "@material-ui/core";

const headCells = [
  {
    id: "datestamp",
    numeric: false,
    disablePadding: true,
    label: "Created Date"
  },
  {
    id: "client_name",
    numeric: false,
    disablePadding: false,
    label: "Client"
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username"
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, handleSort } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            style={{ padding: index ? "12px auto" : "12px 12px 12px 20px" }}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() =>
                handleSort(
                  headCell.id === "full_name" ? "first_name" : headCell.id
                )
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
