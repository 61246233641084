import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import ChipInput from "./ChipInput/ChipInput";
import { GET_GEOFENCE_QUERY } from "../../../graphql/Queries";
import debounce from "../../../utils/functions/debounce.util";
import useUserContext from "../../../context/User/useUserContext";

const GeofenceChipInput = React.forwardRef((props, ref) => {
  const { initialValues = [], readOnly = false, groups = [] } = props;
  const userCtx = useUserContext();
  const [selectedGeofences, setSelectedGeofences] = useState(initialValues);
  const { loading, data, refetch } = useQuery(GET_GEOFENCE_QUERY, {
    variables: {
      first: 15,
      group_ids: groups
    }
  });

  const options = data?.get_geofences?.geofences || [];

  const handleKeywordChanged = event => {
    refetch({
      first: 15,
      group_ids: groups,
      keyword: event.target.value,
      keyword_fields: ["name", "geofence_code"]
    });
  };
  const debouncedKeywordChanged = debounce(handleKeywordChanged, 250);

  const autocompleteProps = {
    // 💡 Override Autocomplete props
    renderInput: ({ getTextfieldProps }) => ({
      ...getTextfieldProps({
        label: "Geofences",
        onChange: debouncedKeywordChanged,
        placeholder: selectedGeofences?.length > 0 ? "" : "All",
        InputProps: {
          disableUnderline: readOnly,
          disabled: readOnly
        }
      })
    }),
    disabled: readOnly,
    getOptionLabel: option => option.name,
    getOptionSelected: (option, value) => option.id === value.id,
    getOptionDisabled: option => {
      return (
        selectedGeofences.length >= 20 &&
        selectedGeofences.findIndex(element => element.id === option.id) === -1
      );
    }
  };

  const handleSelectedChange = changes => {
    const newSelectedValues = [...changes.selectedValues];
    ref.current = newSelectedValues;
    setSelectedGeofences(newSelectedValues);
  };

  useEffect(() => {
    return () => {
      ref.current = initialValues;
    };
  }, []);

  return (
    <ChipInput
      loading={loading}
      options={options}
      selectedValues={selectedGeofences}
      onSelectedChanged={handleSelectedChange}
      autocompleteProps={autocompleteProps}
    />
  );
});

export default GeofenceChipInput;
