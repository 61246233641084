import React, { useCallback, useMemo } from "react";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from "@material-ui/core";
import { Item, TableWrapper, useStyles } from "../../TripAllocation.styles";
import {
  Clear as DeleteIcon
} from "@material-ui/icons";
import Table from "../../../../../utils/UI/Table/CustomTable";
import _ from "lodash";

const HEADERS = [
  { id: "trip_number", label: "Trip Number", isSortable: false },
  { id: "pickup_location", label: "Pickup Location", isSortable: false },
  { id: "delivery_location", label: "Delivery Location", isSortable: false },
  { id: "vehicle_type", label: "Vehicle Type", isSortable: false },
  { id: "hauler", label: "Hauler", isSortable: false },
  { id: "action", label: "" }
];

const Row = (props) => {
  const {
    trip,
    isAllocating,
    handleChangeHauler,
    handleDelete = () => {},
    haulers
  } = props;
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>{trip.trip_number}</TableCell>
      <TableCell>
        <span>{`${trip.pickups[0]?.geofence_code}-${trip.pickups[0]?.name}`}</span>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          {trip.dropoffs?.map(
            p => p && <span>{`${p.geofence_code}-${p.name}`}</span>
          )}
        </Box>
      </TableCell>
      <TableCell>{trip.vehicle_type}</TableCell>
      <TableCell>
        <Select
          displayEmpty
          disableUnderline
          name="hauler"
          value={trip?.assigned_hauler || null}
          renderValue={val => val || "-"}
          onChange={e => handleChangeHauler(e, trip)}
          inputProps={{ "aria-label": "hauler" }}
          className={classes.haulerDropdown}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            className: classes.haulerDropdownMenu
          }}
          style={{
            minWidth: 120,
            outline: "1px solid #e0e0e0",
            padding: 3
          }}
        >
          {haulers.map(({ name }) => (
            <MenuItem value={name}>{name}</MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={handleDelete}
          // disabled={isAllocating}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const isSame = (prevProps, nextProps) => {
  return (nextProps.trip === prevProps.trip);
};

const MemoizedRow = React.memo(Row, isSame);

const AllocationsTable = props => {
  const {
    trips,
    isAllocating,
    haulerFilter,
    handleChangeHauler,
    client,
    handleDelete = () => {}
  } = props;

  // get hauler options for manual allocation
  const haulerOptions = client.group_names.map(group => ({
    name: group,
    group_id:
      client.group_ids[_.findIndex(client.group_names, g => g === group)]
  }));

  const getFilteredTrips = () => {
    let tempFiltered = trips;
    if (!haulerFilter) {
      // "Unallocated Trips" item
      tempFiltered = trips.filter(t => !t.assigned_hauler);
    } else if (haulerFilter !== "all") {
      tempFiltered = trips.filter(t => t.assigned_hauler === haulerFilter);
    }
    return tempFiltered;
  };

  return (
    <Item padding={"0"}>
      <TableWrapper tdPadding={10}>
        <Table headers={HEADERS}>
          {getFilteredTrips()?.map((trip, idx) => (
            <MemoizedRow 
              key={trip.id}
              trip={trip} 
              isAllocating={isAllocating}
              handleChangeHauler={handleChangeHauler}
              haulers={haulerOptions}
              handleDelete={() => handleDelete(trip, idx)}
            />
          ))}
        </Table>
      </TableWrapper>
    </Item>
  );
};


export default AllocationsTable;
