import { makeStyles } from "@material-ui/core";

export default makeStyles({
  wrapper: {
    border: "1px solid rgb(0 0 0 / 15%)",
    borderBottom: props =>
      props.values.length > 0 ? "none" : "1px solid rgb(0 0 0 / 15%)",
    marginBlockEnd: 24
  },
  draggableIcon: {
    pointerEvents: "none",
    color: "#a1a1a1"
  },
  title: {
    padding: "8px 10px",
    borderBottom: "1px solid rgb(0 0 0 / 15%)",
    backgroundColor: "#f1f1f1",
    display: "flex",
    alignItems: "center"
  },
  label: {
    fontWeight: "bold",
    fontSize: 12,
    letterSpacing: "1px",
    fontFamily: "Nunito"
  },
  emptyContainer: {
    position: "relative",
    minHeight: "100px"
  },
  emptyText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "0.8rem",
    color: "#848484"
  },
  readOnly: {
    pointerEvents: "none",
    background: "#dfdfdf"
  }
});
