/* global google */
import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import AddLogs from "../../../../../utils/functions/AddLogs";
import CustomMarker from "../../../../../utils/Map/Marker/CustomMarker";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../../../assets/SvgIcons/SvgIcons";
import SharelinkDialog from "../../../../../utils/modals/SharelinkDialog";
import greenPulseImg from "../../../../../assets/Images/Indicators/blinking-green.gif";
import redPulseImg from "../../../../../assets/Images/Indicators/blinking-red.gif";
import yellowPulseImg from "../../../../../assets/Images/Indicators/blinking-yellow.gif";
import useIsMarkerClustered from "../../../../../utils/hooks/useIsMarkerClustered";
import InfoBubble from "./InfoBubble";
import { GET_INFO_BUBBLE_DEVICES_MAPVIEW } from "../../../../../graphql/Queries";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  infoWindowContainer: {
    position: "absolute",
    bottom: 0
  },
  mainInfoWindow: {
    maxWidth: "300px"
  },
  headerInfoWindow: {
    marginBottom: "8px"
  },
  status: { fontSize: 12, color: "#34CB1b" },
  actions: {
    "& .MuiIconButton-root": {
      padding: "5px"
    },
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButtonBase-root.Mui-disabled": {
      pointerEvents: "auto"
    }
  },
  withTrip: {
    color: "#3ba4f7"
  },
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500]
  },
  undeRepairSize: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: "white"
  }
}));

const setScale = (outer, scale) => {
  const icono = outer.get("icon");
  // icono.strokeOpacity = Math.cos((Math.PI / 2) * scale);
  // icono.fillColor = "#FBBC49";
  icono.fillOpacity = Math.cos((Math.PI / 2) * scale) * 0.9;
  /* for valentines */
  // var from = new Date("February 06, 2017");
  // var to = new Date("February 28, 2017");
  // var now = new Date();
  // if (now > from && now < to) {
  //   icono.scale = Math.sin((Math.PI / 2) * scale) * 2;
  // } else {
  icono.scale = Math.sin((Math.PI / 2) * scale) * 30;
  // }
  outer.set("icon", icono);
};

const pulseImages = {
  3: redPulseImg,
  2: yellowPulseImg,
  1: greenPulseImg
};

const Device = prop => {
  const { default_icon } = SvgIcons;
  // const google = window.google;
  const classes = useStyles();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [shareDialogDetails, setShareDialogDetails] = useState({
    isOpen: false,
    vehicle: {}
  });
  const [generatedLink, setGeneratedLink] = useState("");
  const [historyPickerDialog, setHistoryPickerDialog] = useState({
    display: false,
    vehicleInfo: {}
  });

  const onInfoWindowClose = () => {
    setShowInfoWindow(prev => !prev);
  };
  const {
    vars,
    history,
    filterValues,
    toggleStreetView,
    data: {
      id,
      name,
      device_status: {
        heading,
        latitude,
        longitude,
        AlertReferences,
        powersource
      },
      vehicle_info
    },
    clusterer,
    infoBubble,
    clusterInstance
  } = prop;

  const ignition_status = vehicle_info?.ignition_status;

  const [getInfoBubbleData, {data: gpsData, loading}] = useLazyQuery(
    GET_INFO_BUBBLE_DEVICES_MAPVIEW,
    {
      variables: {
        condition: [{
          field: "devices.id",
          value: [id]
        }],
        dateRange: { start: `${moment().format("YYYY-MM-DD")} 00:00:00` }
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const onMarkerClick = () => {
    setShowInfoWindow(prev => !prev);
    if (!showInfoWindow) {
      getInfoBubbleData();
    }
    AddLogs(process.env.REACT_APP_FLEET_MODULE, "view_device", name);
  };

  const position = { lat: latitude, lng: longitude };

  /*
   *** share link function for opening and closing modal share.
   */
  // function shareModalHandler() {
  //   setShareDialogDetails({
  //     isOpen: !shareDialogDetails.isOpen
  //   });
  // }
  /*
   *** generate the link for the user with the specific vehicle redirected to google maps.
   */
  function generateShareLinkHandler() {
    setGeneratedLink(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    );
  }

  // const blinkingRef = React.useRef(null);
  const currentAlert = AlertReferences?.reduce((accum, alert) => {
    if (alert.level > accum) return alert.level;
    return accum;
  }, 0);

  const isLBSVehicle = AlertReferences?.some(
    alert => alert.alert_code === "LBS DATA"
  );

  // const lonerMarker = React.useMemo(() => {
  //   if (!clusterInstance) return true;

  //   const clusters = clusterInstance.getClusters();

  //   let isNotClustered = true;
  //   for (let i = 0; i < clusters.length; i++) {
  //     for (let j = 0; j < clusters[i].markers.length; j++) {
  //       if (
  //         clusters[i].markers[j].label.text === plateno &&
  //         clusters[i].markers.length !== 1
  //       ) {
  //         isNotClustered = false;
  //         break;
  //       }
  //     }
  //   }

  //   return isNotClustered;
  // }, [clusterInstance]);

  // React.useEffect(() => {
  //   if (!AlertReferences?.filter(alert => alert.level >= 2).length) return;
  //   if (!blinkingRef?.current?.marker) return;
  //   let blinkCount = 1;
  //   const interval = setInterval(() => {
  //     for (let i = 0; i <= 10; i++) {
  //       setTimeout(() => {
  //         if (!blinkingRef.current?.marker) return;
  //         setScale(blinkingRef.current.marker, i / 10);
  //       }, i * 60);
  //     }

  //     if (blinkCount >= 30) {
  //       // blinkingRef.current.marker.setMap(null);
  //       clearInterval(interval);
  //     }
  //     blinkCount += 1;
  //   }, 1500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const Icon = SvgIcons[isLBSVehicle ? "LBS_vehicle" : "default_icon"];

  const showLabel = useIsMarkerClustered(clusterInstance, name);

  return (
    <React.Fragment>
      <Marker
        options={{
          icon: {
            // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
            path: Icon.path,
            // .pathWithCircle,
            fillColor: isLBSVehicle ? Icon.fill : fillColor[powersource || ""], // Icon.fill,
            fillOpacity: 1,
            strokeColor: isLBSVehicle
              ? Icon.stroke
              : strokeColor[ignition_status || ""], // Icon.stroke,
            rotation: isLBSVehicle ? 0 : heading,
            strokeWeight: isLBSVehicle ? 1.0 : 1.5,
            scale: 1.2,
            size: new google.maps.Size(22, 17),
            origin: new google.maps.Point(0, 0),
            // anchor: new google.maps.Point(5, 9)
            anchor: isLBSVehicle
              ? new google.maps.Point(14, 15)
              : new google.maps.Point(9, 13)
          },
          label: {
            text: name?.toString(),
            fontSize: "0px"
          },
          zIndex: 2147483647
        }}
        key={id}
        position={position}
        onClick={onMarkerClick}
        clusterer={clusterer}
        opacity={1}
      >
        {showInfoWindow && (
          <InfoWindow
            onCloseClick={onInfoWindowClose}
            position={position}
            className={classes.infoWindowContainer}
          >
            {gpsData && !loading ?
              <InfoBubble
                // componentType="MapView"
                data={gpsData.get_devices?.devices[0]}
                historyHandler={() => {
                  setHistoryPickerDialog({
                    display: !historyPickerDialog.display,
                    vehicleInfo: gpsData.get_devices?.devices[0]
                  });
                }}
                isOpenHistoryModal={historyPickerDialog}
                streetViewHandler={toggleStreetView}
                infoBubble={infoBubble}
                // filterVal={filterValues}
                // vars={vars}
                history={history}
              />
              :
              <CircularProgress size={10} />
            }
          </InfoWindow>
        )}
        <SharelinkDialog
          toggle={shareDialogDetails.isOpen}
          close={() => {
            setShareDialogDetails({ isOpen: false, vehicle: {} });
            setGeneratedLink("");
          }}
          title="Share Location"
          content="Generate Link and share the location of your vehicle"
          generatedLink={generatedLink}
          fn={generateShareLinkHandler}
        />
        {/* {vehicles.length === 1 && (
        <CustomMarker
          getVehiclePosition={positionHandler}
          vehicleDirection={heading}
          vehiclePlateNo={plateno}
          zoom={zoom}
          alertLevel={
            AlertReferences?.filter(alert => alert.level >= 3) || null
          }
          isMoving={gpsSpeed > 0}
          moduleType="MAP_VIEW"
        />
      )} */}
        {/* {lonerMarker && gpsSpeed > 0 && zoom >= 13 && (
        <Marker
          position={position}
          options={{
            zIndex: -1,
            icon: {
              path:
                "M2 19.5V19.5C2 17.558 1.33908 15.6738 0.125955 14.1574L0 13.9999L22 -4.19617e-05L22 38.9711L0 24.9999L0.125874 24.8426C1.33905 23.3262 2 21.442 2 19.5V19.5Z",
              fillColor: "#3CA5F7",
              fillOpacity: 0.5,
              strokeWeight: 0,
              scale: 1.5,
              strokeOpacity: 0,
              rotation: heading - 90,
              anchor: new google.maps.Point(-8, 18)
            }
          }}
        />
      )} */}

        {/* {currentAlerts?.length && (
        <Marker
          ref={blinkingRef}
          position={position}
          options={{
            zIndex: -1,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor:
                currentAlerts[currentAlerts.length - 1].level === 2
                  ? "#FBBC49"
                  : "#FF0202",
              fillOpacity: 0.8,
              strokeWeight: 0.8,
              strokeOpacity: 0,
              scale: 0
            }
          }}
        />
      )} */}

        {pulseImages[currentAlert] && (
          <Marker
            position={position}
            options={{
              zIndex: -1,
              icon: {
                url: pulseImages[currentAlert],
                scaledSize: new google.maps.Size(45, 45),
                // anchor: new google.maps.Point(18, 20),
                anchor: new google.maps.Point(22, 24),
                opacity: 0.5
              }
            }}
            opacity={0.5}
          />
        )}
      </Marker>
      {showLabel && (
        <Marker
          options={{
            icon: {
              // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
              path: Icon.path,
              // .pathWithCircle,
              fillColor: fillColor[powersource], // Icon.fill,
              fillOpacity: 0,
              // strokeColor: strokeColor[ignition_status], // Icon.stroke,
              strokeWeight: 0,
              scale: 1.2,
              size: new google.maps.Size(22, 17),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(5, 9),
              // labelOrigin: new google.maps.Point(8, 48)
              labelOrigin: new google.maps.Point(8, 45)
            },
            zIndex: 214748364,
            label: {
              color: "#3A3A3A",
              fontWeight: "bold",
              text: name?.toString(),
              fontSize: "12px",
              className: "map-label"
            }
          }}
          position={position}
        />
      )}
    </React.Fragment>
  );
};

export default Device;
