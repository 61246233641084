import React from "react";
import { Typography, Toolbar } from "@material-ui/core";
import useToolbarStyles from "./EnhancedToolbar.styles";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children } = props;

  return (
    <Toolbar style={{ borderBottom: "2px solid #c9c9c9" }}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Feedback Message
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;