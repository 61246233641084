import React, { useEffect } from "react";

import { Box, styled, Typography } from "@material-ui/core";
import _ from "lodash";

import UserProfileForm from "./UserProfileForm";
import UserImage from "./UserImage";

const UserProfileMainInfoPanel = props => {
  const {
    userInfo,
    hasUploaded,
    setHasUploaded,
  } = props;
  const { profile_image } = userInfo;

  return (
    <StyledProfileContainer>
      <StyledBox>
        <UserImage
          profileImg={profile_image}
          setHasUploaded={setHasUploaded}
        />
        <StyledTypography>{`Hello, ${_.startCase(
          userInfo.first_name
        )} ${_.startCase(userInfo.last_name)}`}</StyledTypography>
        <UserProfileForm
          defaultUserInfo={userInfo}
          hasUploaded={hasUploaded}
          setHasUploaded={setHasUploaded}
        />
      </StyledBox>
    </StyledProfileContainer>
  );
};

export default UserProfileMainInfoPanel;

const StyledProfileContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#f9f9f9",
  height: "100%",
  paddingTop: "2rem",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 5,
    backgroundColor: "#F9F9F9 !important",
    borderRadius: "0px !important"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F9F9F9 !important",
    border: "none !important"
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    border: "0px solid  rgba(0, 0, 0, 0) !important",
    borderRadius: 0
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 600,
  fontSize: 20,
  margin: "1rem",
  textAlign: "center"
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: "60%",
  margin: "0 auto",
  // height: "100%",
  borderRadius: "30px",
  padding: "2rem 2rem 5rem 2rem"
}));
