import { useState } from "react";
import Swal from "sweetalert2";
import { useQuery } from "@apollo/client";
import { GET_USER_LEVEL } from "../../graphql/Queries";

const useFetchUserLevel = UserLevelId => {
  const [landingPageOpt, setLandingPageOpt] = useState("");

  useQuery(GET_USER_LEVEL, {
    variables: { id: UserLevelId },
    onCompleted: res =>
      setLandingPageOpt(res.get_user_level.acl_module_resource),
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    },
    fetchPolicy: "cache-and-network"
  });

  return landingPageOpt;
};

export default useFetchUserLevel;
