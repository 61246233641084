import React, { Fragment, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  CircularProgress,
  TextField,
  Box
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Brightness1, Help as HelpIcon } from "@material-ui/icons";
import Cookie from "js-cookie";
import moment from "moment";
import GroupsTable from "./GroupsTable";
import Battery from "../../../../../utils/Battery";
import SwitchHelper from "../../../../../utils/UI/Switch/SwitchHelper";
import SvgIcons from "../../../../../assets/SvgIcons/SvgIcons";
import SearchContext from "../../../../../context/SearchContext";

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box",

    "& .MuiAlert-root": {
      padding: "2px 5px"
    }
  },
  paper: {
    padding: "24px",
    width: "100%",
    boxSizing: "border-box"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  odom_text: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  odom: {
    padding: 0
  },
  odomAlert: {
    width: "100%",
    border: "2px solid #4caf50",
    color: "#4caf50"
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

const ViewVehicle = props => {
  const classes = useStyles();
  const { vehicle_data } = props;
  const user = JSON.parse(Cookie.get("user"));
  const contextData = useContext(SearchContext);
  const { searchKeyword, setSearchKeyword } = contextData;

  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const isPrimaryClient =
    +user.client.id === +vehicle_data?.get_vehicle?.client_id;

  React.useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false
    });
  }, []);

  const gpsStatus = vehicle => {
    const s = vehicle?.device_info?.device_status?.status_code?.toLowerCase();
    let data = {};

    switch (s) {
      case "available":
      case "online":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#34CB1b" }}
              >
                Online GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#34CB1b"
        };
        break;
      case "no gps":
        data = {
          label: "No GPS",
          color: "#e40000"
        };
        break;
      case "delayed":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#FEC702" }}
              >
                Delayed Reporting
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#FEC702"
        };
        break;
      case "offline":
        data = {
          label: (
            <Fragment>
              <p
                className={classes.tooltipTitle}
                style={{ fontWeight: "bold", color: "#C4C4C4" }}
              >
                Offline GPS
              </p>
              <p className={classes.tooltipTitle}>{`Last Report: ${moment(
                vehicle.device_info.device_status.reportstamp
              ).format("lll")}`}</p>
            </Fragment>
          ),
          color: "#C4C4C4"
        };
        break;
      default:
        data = {
          label: "No GPS",
          color: "#e40000"
        };
    }
    return data;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <Typography>
            All fields with <span style={{ color: "red" }}>*</span> are required
          </Typography>
        </Grid>
        <br />
        <Grid container spacing={1}>
          {(vehicle_data && (
            <Fragment>
              <Grid item xs={3}>
                <Typography variant="h6">Vehicle / CV Information</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Fragment>
                          <span>Plate/CV Number</span>
                          <span style={{ color: "red" }}>*</span>
                        </Fragment>
                      }
                      secondary={
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {vehicle_data.get_vehicle.plateno}
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Fragment>
                          <span>Vehicle Icon</span>
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Fragment>
                      }
                      secondary={vehicle_data.get_vehicle.icon.split(".")[0]}
                    />
                    <ListItemSecondaryAction>
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        // viewBox="0 0 16.96 16.96"
                        width="45px"
                        height="45px"
                        style={{ margin: -29 }}
                      >
                        <g
                          fill="#00000"
                          stroke={SvgIcons.stroke}
                          strokeWidth={0.3}
                        >
                          <path
                            d={
                              SvgIcons[
                                `${vehicle_data.get_vehicle.icon.split(".")[0]}`
                              ].path
                            }
                          />
                        </g>
                      </svg>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/assets/Vehicle Icons/${vehicle_data.get_vehicle.icon}`}
                        width={25}
                        height={25}
                        alt=""
                      /> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Fragment>
                          <span>Hauler</span>
                          <span style={{ color: "red" }}>*</span>
                        </Fragment>
                      }
                      secondary={vehicle_data.get_vehicle.client_name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Fragment>
                          <span>Vehicle Type</span>
                          <span style={{ color: "red" }}>*</span>
                        </Fragment>
                      }
                      secondary={vehicle_data.get_vehicle.vehicle_type}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="KML (Standard)"
                      secondary={vehicle_data.get_vehicle.avefuelconsumption}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Registry Expiration"
                      secondary={
                        vehicle_data.get_vehicle.expiration
                          ? moment(vehicle_data.get_vehicle.expiration).format(
                              "DD/MM/YYYY"
                            )
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Model"
                      secondary={vehicle_data.get_vehicle.model}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Body Number"
                      secondary={vehicle_data.get_vehicle.bodyno}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Manufacture Year"
                      secondary={vehicle_data.get_vehicle.manufacture_year}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Make"
                      secondary={vehicle_data.get_vehicle.make}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Area"
                      secondary={vehicle_data.get_vehicle.area}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Heads"
                      secondary={vehicle_data.get_vehicle.heads}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Region"
                      secondary={vehicle_data.get_vehicle.region}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Assignment"
                      secondary={vehicle_data.get_vehicle.assignment}
                    />
                  </ListItem>
                  {vehicle_data.get_vehicle.has_vss_camera !== 0 && <ListItem>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            placement="top"
                            title="Provide the no. of selected channel/s for the VSS Live Cam (Max 8)"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </Tooltip>
                          <Typography>Selected Channel (VSS)</Typography>
                        </Box>
                      }
                      secondary={vehicle_data.get_vehicle.channel_count}
                    />
                  </ListItem>}
                  {/* <ListItem>
                    <ListItemText
                      primary={
                        <InputLabel
                          style={{
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <HtmlTooltip
                            placement="top-start"
                            // title={
                            //   <React.Fragment>
                            //     <Typography color="inherit">
                            //       Turn on: This vehicle will be tagged as Under
                            //       Repair
                            //     </Typography>
                            //   </React.Fragment>
                            // }
                            title="Turn on: This vehicle will be tagged as Under Repair"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </HtmlTooltip>
                          &nbsp;Under Repair&nbsp;
                          <Switch
                            disabled
                            size="small"
                            color="primary"
                            checked={
                              // !!vehicle_data.get_vehicle?.device_info
                              //   ?.device_config?.underrepair
                              !!vehicle_data.get_vehicle?.under_repair
                            }
                          />
                        </InputLabel>
                      }
                      // primary="Manufacture Year"
                      // secondary={vehicle_data.get_vehicle.manufacture_year}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <InputLabel
                          style={{
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <HtmlTooltip
                            placement="top-start"
                            // title={
                            //   <React.Fragment>
                            //     <Typography color="inherit">
                            //       Turn on: This vehicle will be tagged as Not in use vehicle
                            //     </Typography>
                            //   </React.Fragment>
                            // }
                            title=" Turn on: This vehicle will be tagged as Not in use vehicle"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </HtmlTooltip>
                          &nbsp;Not in use vehicle&nbsp;
                          <Switch
                            disabled
                            size="small"
                            color="primary"
                            checked={
                              // !!vehicle_data.get_vehicle?.device_info
                              //   ?.device_config?.underrepair
                              !!vehicle_data.get_vehicle?.not_in_use
                            }
                          />
                        </InputLabel>
                      }
                      // primary="Manufacture Year"
                      // secondary={vehicle_data.get_vehicle.manufacture_year}
                    />
                  </ListItem> */}
                  <SwitchHelper
                    checkedValue={vehicle_data.get_vehicle?.under_repair}
                    label="Under Repair"
                    name="under_repair"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Under Repair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                  <SwitchHelper
                    checkedValue={vehicle_data.get_vehicle?.not_in_use}
                    label="Not in use vehicle"
                    name="not_in_use_vehicle"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as Not in use vehicle"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                  <SwitchHelper
                    checkedValue={vehicle_data.get_vehicle?.has_temp_sensor}
                    label="Temperature Sensor"
                    name="has_temp_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a temperature sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                  <SwitchHelper
                    checkedValue={vehicle_data.get_vehicle?.has_fuel_sensor}
                    label="Fuel Sensor"
                    name="has_fuel_sensor"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle has a fuel sensor"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                  <SwitchHelper
                    checkedValue={vehicle_data.get_vehicle?.auto_unpair}
                    label="Auto Unpair"
                    name="auto_unpair"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: Enable GPS auto unpair"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                  <SwitchHelper
                    checkedValue={!!vehicle_data.get_vehicle?.has_vss_camera}
                    label="VSS Camera"
                    name="has_vss_camera"
                    tooltipPlacement="top-start"
                    tooltipTitle="Turn on: This vehicle will be tagged as vehicle with VSS Compatibility"
                    icon={<HelpIcon className={classes.helpIcon} />}
                    disabled
                  />
                </List>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">GPS Information</Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <HtmlTooltip
                            placement="top-start"
                            title="For GPS pairing concerns please contact WTI"
                          >
                            <HelpIcon className={classes.helpIcon} />
                            {/* <Error style={{ fontSize: 14 }} /> */}
                          </HtmlTooltip>
                          {"  "}Paired Device
                        </span>
                      }
                      secondary={
                        <span>
                          <Battery
                            vehicleType={vehicle_data.get_vehicle.vehicle_type}
                            deviceTypeId={
                              vehicle_data.get_vehicle.device_info
                                ?.device_type_id
                            }
                            batteryLevel={
                              vehicle_data.get_vehicle.device_info
                                ?.device_status?.battery_level
                            }
                            tooltipTitle={
                              <>
                                <p
                                  className={classes.tooltipTitle}
                                  style={{ fontWeight: "bold" }}
                                >{`Battery Life: ${vehicle_data.get_vehicle.device_info?.device_status?.battery_level}%`}</p>
                                <p className={classes.tooltipTitle}>
                                  {`As of: ${moment(
                                    vehicle_data.get_vehicle.device_info
                                      ?.device_status?.reportstamp
                                  ).format("lll")}
                                  `}
                                </p>
                              </>
                            }
                          />{" "}
                          <Tooltip
                            title={
                              gpsStatus(
                                vehicle_data.get_vehicle.device_info
                                  ? vehicle_data.get_vehicle
                                  : { status_code: "no gps" }
                              ).label
                            }
                          >
                            <Brightness1
                              style={{
                                fontSize: 14,
                                color: gpsStatus(
                                  vehicle_data.get_vehicle.device_info
                                    ? vehicle_data.get_vehicle
                                    : { status_code: "no gps" }
                                ).color
                              }}
                            />
                          </Tooltip>
                          {"  "}
                          {vehicle_data.get_vehicle.device_info
                            ? vehicle_data.get_vehicle.device_info.devicealias
                            : "-"}
                        </span>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="IMEI/Serial Number"
                      secondary={
                        vehicle_data.get_vehicle?.device_info
                          ? vehicle_data.get_vehicle.device_info.name
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="GPS Provider"
                      secondary={
                        vehicle_data.get_vehicle.device_info
                          ? vehicle_data.get_vehicle.device_info.device_config
                              .gps_provider
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Auxiliary Voltage(mV)"
                      secondary={
                        vehicle_data.get_vehicle.device_info
                          ? vehicle_data.get_vehicle.device_info.device_config
                              .aux_voltage
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Main Power Interval Report(min)"
                      secondary={
                        vehicle_data.get_vehicle.device_info
                          ? vehicle_data.get_vehicle.device_info.device_config
                              .intervalreporting
                          : "-"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Auxiliary Mode Interval Report(min)"
                      secondary={
                        vehicle_data.get_vehicle.device_info
                          ? vehicle_data.get_vehicle.device_info.device_config
                              .battery_intervalreporting
                          : "-"
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                {vehicle_data.get_vehicle.device_id > 0 &&
                (isWTIAdmin || isPrimaryClient) ? (
                  <>
                    <div className={classes.odom_text}>
                      <Typography variant="h6">
                        Sync Vehicle Odometer
                      </Typography>
                      <Typography>
                        As of{" "}
                        {moment(
                          vehicle_data.get_vehicle.device_info.device_status
                            .reportstamp
                        ).format("lll")}
                      </Typography>
                    </div>
                    <List>
                      <ListItem className={classes.odom}>
                        <ListItemText
                          primary={
                            <TextField
                              fullWidth
                              disabled
                              variant="filled"
                              value={Math.round(
                                (vehicle_data?.get_vehicle?.device_info
                                  ?.device_status?.distancetraveled || 0) / 1000
                              )}
                              label="Current Odometer (km)"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          }
                        />
                      </ListItem>
                      <ListItem className={classes.odom}>
                        <Alert
                          className={classes.odomAlert}
                          severity="info"
                          color="success"
                          variant="outlined"
                        >
                          Amended vehicle odometer will reflect once your
                          vehicle's location update
                        </Alert>
                      </ListItem>
                    </List>
                  </>
                ) : null}

                <Typography variant="h6">Remarks</Typography>
                <List>
                  <ListItem>
                    <ListItemText primary={vehicle_data.get_vehicle.remarks} />
                  </ListItem>
                </List>

                {user.user_level_id === "1" && (
                  <GroupsTable
                    vehicle={vehicle_data.get_vehicle}
                    isEdit={false}
                  />
                )}
              </Grid>
            </Fragment>
          )) || (
            <Fragment>
              <Grid item xs={6} />
              <Grid item xs={1}>
                <CircularProgress />
              </Grid>
              <Grid item xs={5} />
            </Fragment>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewVehicle;
