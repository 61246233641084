import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Box,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  ListItem,
  Divider,
  Popover,
  IconButton,
  Badge
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { ALERT_REFERENCE_NOTIF_SUBSCRIPTION } from "../../graphql/Subscription";
import { NOTIF_ALERT_REFERENCES } from "../../graphql/Queries";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Cookies from "js-cookie";

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    minWidth: "400px"
  },
  alertItem: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    margin: "10px",
    height: "50px",
    backgroundColor: "#FFFFFF"
  }
}));

const AlertNotif = props => {
  const { classesAlert } = props;
  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(Cookies.get("user") || "{}");

  const [alertData, setAlertData] = useState([]);

  const { subscribeToMore } = useQuery(NOTIF_ALERT_REFERENCES
    , {
    variables: {
      first: 1
    },
    onCompleted(e) {
      if (e?.get_alert_references.count === 0) {
        setAlertData([
          ...alertData,
          {
            alertType: "alert reference",
            message: "Please set your alerts on alert reference"
          }
        ]);
      }
    }
  });

  const subscribeToNewAlertNotrif = () => {
    const subs = subscribeToMore({
      document: ALERT_REFERENCE_NOTIF_SUBSCRIPTION,
      variables: {
        filter: {
          field: "group_ids",
          value: user?.group_ids?.[0]?.toString()
        }
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const newData = subscriptionData.data.data_updated;

        if(newData?.alert_reference_count?.count === 0){
          setAlertData([
            ...alertData,
            {
              alertType: "alert reference",
              message: "Please set your alerts on alert reference"
            }
          ]);
        } else {
          setAlertData([])
        }
      }
    });
    return subs;
  };

  useEffect(() => {
    const subscribe = subscribeToNewAlertNotrif();
    return () => subscribe();
  });

  const handleClick = () => {
    history.push({
      pathname: "/admin",
      state: {
        params: {
          moduleName: process.env.REACT_APP_ALERT_REFERENCES_MODULE
        }
      }
    });
  };

  return (
    <Fragment>
      <PopupState variant="popover">
        {popupStateAlert => (
          <Fragment>
            <IconButton color="primary" {...bindTrigger(popupStateAlert)}>
              <Badge
                badgeContent={alertData.length}
                color="error"
              >
                <WarningRoundedIcon />
              </Badge>
            </IconButton>
            <Popover
              {...bindPopover(popupStateAlert)}
              classes={{ paper: classesAlert.paper }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right"
              }}
            >
              <List
                className={classes.header}
                subheader={
                  <ListSubheader style={{ fontSize: "16px" }}>
                    Alerts
                  </ListSubheader>
                }
              >
                <Divider />
                {alertData.length !== 0 ||
                alertData.length === null
                 ? (
                  alertData.map(data => (
                    <ListItem
                      button
                      className={classes.alertItem}
                      onClick={() => {
                        popupStateAlert.close();
                        handleClick();
                      }}
                    >
                      {data.message}
                    </ListItem>
                  ))
                ) : (
                  <Typography
                    align="center"
                    variant="subtitle1"
                    style={{ margin: "15px" }}
                  >
                    No Alert Notifications Available
                  </Typography>
                )}
              </List>
            </Popover>
          </Fragment>
        )}
      </PopupState>
    </Fragment>
  );
};

export default AlertNotif;
