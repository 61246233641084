import {
  makeStyles,
  withStyles,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails
} from "@material-ui/core"; 
import themes from "../../context/Theme/themes";

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: {},
  multipleSelect: {
    backgroundColor: "#e9e9e9",
    minHeight: "45px !important",
    height: 45
  },
  filterButton: {
    borderRadius: "25px",
    fontSize: 13,
    marginRight: 8
  },
  downloadButton: {
    color: "white",
    borderRadius: 25,
    backgroundColor: "#EB825C",
    fontSize: 12,
    marginRight: 8
  },
  textField: {
    borderRadius: "32px !important"
  },
  tableRoot: {
    width: "100%"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  container: {
    maxHeight: "calc(100vh - 128px)",
    borderTop: "1px solid #e9e9e9"
  },
  tableCell: {
    padding: 16
  },
  customBadgeAutomatic: {
    backgroundColor: "#bb6bd9",
    color: "white"
  },
  customBadgeManual: {
    backgroundColor: "#4e9fc0",
    color: "white"
  },
  customBadgeNotYetDispatch: {
    backgroundColor: "#b7b7b7",
    color: "white"
  },
  customBadgeUndefine: {
    backgroundColor: "#cab608",
    color: "white"
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    fontSize: 13,
    margin: "10px 5px",
    "&:hover": {
      backgroundColor: "#ff5723"
    },
    textTransform: "none",
  },
  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    fontSize: 13,
    margin: "10px 5px",
    "&:hover": {
      backgroundColor: "#fe9700"
    },
    textTransform: "none",
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  statusUploading: {
    color: "#3f3f3f"
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white",
    backgroundColor: "#ffb677"
  },
  countSelectedTrips: {
    color: theme.palette.primary.main
  },
  dateToday: {
    color: theme.palette.primary.dark,
    marginRight: 20, 
    marginTop: 3,
    fontSize: 13
  },
  muiTableHeader: {
    fontWeight: "600", 
    fontSize: 13,
    color: "#3F3F3F",
    backgroundColor: "#FFFFFF",
  },
  muiTableCellData: {
    fontSize: 13,
    color: theme.palette.primary.dark,
  },
  muiMenuCell: {
    fontSize: 12,
    color: theme.palette.primary.dark,    
  },
  muiButtonSelectedCheckBox: {
    width: 110,
    fontSize: 13,
    textTransform: "none",
    borderRadius: 16,
    color: theme.palette.secondary.main,
  },
  countSelected: {
    fontSize: 13, 
    color: "rgba(63, 63, 63, 50%)",
    fontWeight: "600",
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px",
  },  
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13,
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px",    
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
}));

export const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);
