import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownCircleOutlined";
import SearchContext from "../../../../context/SearchContext";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "inherit"
  },
  accordionRoot: {
    background: "transparent",
    "& .MuiAccordionSummary-root": {
      paddingLeft: 0
    }
  }
}));

export default function AccordionCell({ content, defaultExpanded = false }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const searchContext = useContext(SearchContext);

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded}
        elevation={0}
        classes={{ root: classes.accordionRoot }}
        onChange={(_, newValue) => setExpanded(newValue)}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "60ch"
            }}
          >
            <Typography
              style={{
                wordWrap: "break-word"
              }}
            >
              <Highlight search={searchContext.searchKeyword.value}>
                {!expanded ? `${content.toString().substring(0, 60)}` : content}
              </Highlight>
            </Typography>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}
