import React from "react";
import {
  makeStyles,
  Toolbar,
  Typography,
  Drawer,
  AppBar,
  IconButton,
  Button
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: "25%",
      width: "23%"
    }
  },
  container: {
    width: "auto%",
    position: "relative",
    minHeight: "calc(100% - 64px)",
    padding: "5px 16px",
    textAlign: "center",
    boxSizing: "border-box",
    overflowY: "auto"
  },
  toolbar: {
    background: theme.palette.primary.main
  }
}));

export default function CloneDrawer(props) {
  const {
    cloneDrawer,
    closeCloneDrawer,
    children,
    handleSave,
    selectedClone
  } = props;
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      open={cloneDrawer}
      onClose={closeCloneDrawer}
    >
      <AppBar style={{ backgroundColor: "#f2f2f2" }} position="static">
        <Toolbar
           classes={{
            root: classes.toolbar
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            style={{ color: "white", flexGrow: 1}}
          >
            Clone
          </Typography>
          <IconButton style={{ color: "white" }} onClick={closeCloneDrawer}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <div style={{ width: "100%", textAlign: "center", minHeight: "90%" }}>
          <div>{children}</div>
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            right: 0,
            bottom: "0px",
            marginTop: 10
          }}
        >
          <Button
            disabled={!selectedClone.length}
            color="primary"
            variant="contained"
            onClick={handleSave}
            style={{
              borderRadius: 100,
              width: "85%",
              color: "white"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
