import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    infoDetailBox: {
        display: "flex",
        marginLeft: 20,
    },
    label: {
        width: 140,
    },
    content: {
        width: 200,
        whiteSpace: "pre-line"
    },
}));