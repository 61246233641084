import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";

import Header from "./components/Header";
import useFetchUserProfile from "../../utils/hooks/useFetchUserProfile";
import useFetchUserLevel from "../../utils/hooks/useFetchUserLevel";
import UserProfileMainInfoPanel from "./components/UserProfileMainInfoPanel";
import Loading from "../../utils/Loading";
import FormContext from "../../context/formContext";

const UserProfile = props => {
  const { history } = props;
  const user = JSON.parse(Cookies.get("user"));
  const userInfo = useFetchUserProfile(user.id, user);
  const userRedirectPage = useFetchUserLevel(user.user_level_id);
  const [updatedUserInfo, setUpdatedUserInfo] = useState(userInfo);
  const [hasUploaded, setHasUploaded] = useState({
    image: "",
    status: false
  });

  useEffect(() => setUpdatedUserInfo(userInfo), [userInfo]);

  return (
    <FormContext.Provider
      value={{
        hasUpdate: false,
        latestUserInfo: updatedUserInfo,
        setUpdatedUserInfo: setUpdatedUserInfo
      }}
    >
      {!userInfo ? (
        <Loading />
      ) : (
        <>
          <Header
            label="Edit Profile"
            redirectPage={userRedirectPage}
            history={history}
            hasChangedProfile={hasUploaded.status}
          />
          <UserProfileMainInfoPanel
            userInfo={userInfo}
            hasUploaded={hasUploaded}
            setHasUploaded={setHasUploaded}
            updatedUserInfo={updatedUserInfo}
          />
        </>
      )}
    </FormContext.Provider>
  );
};

export default UserProfile;
