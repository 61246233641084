export const ClassificationOptions = [
  {
    id: 0,
    label: "All",
    value: [
      "Trip",
      "Dwell Time",
      "GPS Alert",
      "Trip Upload",
      "Activity Logs",
      "User Activity",
      "System Response"
    ]
  },
  {
    id: 1,
    label: "System Response",
    value: ["Trip Upload", "System Response", "User Activity"]
  },
  { id: 2, label: "Trip-related", value: ["Dwell Time", "Trip"] },
  { id: 3, label: "GPS-related", value: ["GPS Alert"] }
];
