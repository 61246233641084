/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Badge,
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Button,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
  List,
  Divider,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  ListItemText,
  InputAdornment,
  Box
} from "@material-ui/core";
import { Alert as AlertPrompt } from "@material-ui/lab";
import Swal from "sweetalert2";
import {
  Help as HelpIcon,
  Delete as DeleteIcon,
  ArrowDropDown,
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
  CalendarToday as DateIcon,
  ScheduleRounded as ScheduleRoundedIcon
} from "@material-ui/icons";
import {
  DateTimePicker,
  TimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Cookie from "js-cookie";
import AccessControl from "../../../../utils/AccessControl";
import SearchContext from "../../../../context/SearchContext";
import {
  GET_ALERT_REFERENCE,
  GET_USER_LEVELS
} from "../../../../graphql/Queries";
import { EDIT_ALERT_REFERENCE } from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Header from "../../Header";
import Loading from "../../../../utils/Loading";
import validate from "../../../../utils/validation";
import ErrorPage from "../../../../utils/errorPage";
import AddLogs from "../../../../utils/functions/AddLogs";
import useUserContext from "../../../../context/User/useUserContext";
import { frequencyData } from "../../../../data/alertData";
import Vehicles from "./components/Vehicles";
import { data } from "browserslist";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)",
    // margin: theme.spacing(2, 4),
    flexGrow: 1,
    padding: 24,
    boxSizing: "border-box",
    // padding: theme.spacing(5)
    overflow: "auto"
  },
  field: {
    marginBottom: 18,
    width: "90%"
  },
  column: {
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 1.25,
    margin: "20px 0px 24px 0px"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  details: {
    display: "block",
    borderTop: "solid",
    borderTopWidth: "1px",
    borderTopColor: "lightgray",
    padding: "8px 20px"
  },
  selected: {
    backgroundColor: "#cfecfd !important"
  },
  dialog: {
    borderRadius: "10px"
  },
  alert: {
    width: "auto"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

function AlertType(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const {
    recipients,
    dataRec,
    editMode,
    handleChange,
    addRecipient,
    deleteRecipient,
    alertType,
    handleAlertType,
    errors,
    openPortal,
    openEmail,
    openSMS,
    alertDetails
  } = props;
  const isDisabled =
    !editMode || alertDetails?.alert_description?.code === "LOCATION";
  const { data, loading } = useQuery(GET_USER_LEVELS, {
    variables: {
      first: 10000
    }
  });
  const handleSelected = id => {
    setSelected(id);
  };

  const [render, setRender] = useState(false);

  const handleRerender = () => {
    setRender(!render);
  };

  const allSelected = () => {
    return recipients.user_level_id.length === data.get_user_levels.total;
    // const temp = [];
    // const temp2 = lodash.cloneDeep(recipients.user_level_id);
    // data.get_user_levels.userlevels.map(lev => temp.push(lev.id));
    // return lodash.isEqual(temp2.sort(), lodash.cloneDeep(temp).sort())
  };

  const user_level_id = () => {
    return (
      <>
        <Typography style={{ padding: "16px" }}>Recipients</Typography>
        <Divider />
        <div
          style={{
            width: "100%",
            minHeight: "70%",
            position: "relative",
            overflowY: "auto"
          }}
        >
          {recipients.user_level_id.length > 0 &&
            recipients.user_level_id.map((id, index) => (
              <Fragment key={id}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: 12,
                    boxSizing: "border-box"
                  }}
                >
                  <Select
                    style={{ flex: 1 }}
                    value={id}
                    onChange={e => handleChange(e, index)}
                    displayEmpty
                    name="user_level_id"
                    disabled={isDisabled}
                    disableUnderline={isDisabled}
                    IconComponent={iconProps => (
                      <ArrowDropDown
                        {...iconProps}
                        style={{ display: !isDisabled ? "block" : "none" }}
                      />
                    )}
                  >
                    <MenuItem value={0} disabled>
                      Enter User Level
                    </MenuItem>
                    {data.get_user_levels.userlevels.map(user_level => (
                      <MenuItem
                        key={user_level.id}
                        value={user_level.id}
                        disabled={
                          _.indexOf(recipients.user_level_id, user_level.id) >
                          -1
                        }
                      >
                        {user_level.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!isDisabled && (
                    <IconButton
                      onClick={() => deleteRecipient("user_level_id", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                <Divider />
              </Fragment>
            ))}
          {errors.user_level_id ? errors.user_level_id : null}
        </div>
        {!isDisabled && (
          <div
            style={{
              width: "100%",
              // height: "15%",
              padding: "8px",
              boxSizing: "border-box",
              // marginTop: 410,
              position: "relative"
              // bottom: 0
            }}
          >
            {/* <br /> */}
            <Button
              onClick={() => addRecipient("user_level_id")}
              variant="outlined"
              color="primary"
              style={{ width: "100%", height: "100%" }}
              // disabled={allSelected()}
              disabled={openPortal || allSelected()}
            >
              Add
            </Button>
          </div>
        )}
      </>
    );
  };

  const email_address = () => {
    return (
      <>
        <Typography style={{ padding: "16px" }}>Recipients</Typography>
        <Divider />
        <div
          style={{
            width: "100%",
            minHeight: "70%",
            position: "relative",
            overflowY: "auto"
          }}
        >
          {recipients.email_address.length > 0 &&
            recipients.email_address.map((email_address_add, index) => (
              <Fragment key={`email_address_add-${index}`}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    style={{ margin: "12px", flex: 9999 }}
                    value={email_address_add}
                    onChange={e => handleChange(e, index)}
                    placeholder="Enter Email Address"
                    name="email_address"
                    type="email"
                    InputProps={{
                      // disabled:
                      //   dataRec.email_address.indexOf(email_address_add) > -1,
                      // disableUnderline:
                      //   dataRec.email_address.indexOf(email_address_add) > -1
                      disabled: dataRec.email_address
                        ? dataRec.email_address.indexOf(email_address_add) > -1
                        : null,
                      disableUnderline: dataRec.email_address
                        ? dataRec.email_address.indexOf(email_address_add) > -1
                        : null
                    }}
                  />
                  {!isDisabled && (
                    <IconButton
                      onClick={() => deleteRecipient("email_address", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                <Divider />
              </Fragment>
            ))}
          {errors.email_address ? errors.email_address : null}
        </div>

        {!isDisabled && (
          <div
            style={{
              width: "100%",
              // height: "15%",
              padding: "8px",
              boxSizing: "border-box",
              // marginTop: 410,
              position: "relative"
              // bottom: 0
            }}
          >
            {/* <br /> */}
            <Button
              onClick={() => addRecipient("email_address")}
              variant="outlined"
              color="primary"
              style={{ width: "100%", height: "100%" }}
              disabled={openEmail}
            >
              Add
            </Button>
          </div>
        )}
      </>
    );
  };

  const mobile_number = () => {
    return (
      <>
        <Typography style={{ padding: "16px" }}>Recipients</Typography>
        <Divider />
        <div
          style={{
            width: "100%",
            minHeight: "70%",
            position: "relative",
            overflowY: "auto"
          }}
        >
          {recipients.mobile_number.length > 0 &&
            recipients.mobile_number.map((mobile_num, index) => (
              <Fragment key={`mobile_num-${index}`}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    style={{ margin: "12px", flex: 9999 }}
                    value={mobile_num}
                    onChange={e => handleChange(e, index)}
                    onBlur={e => {
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^0?9/, "+639");
                      e.target.value = beginWith63;
                      handleChange(e, index);
                    }}
                    placeholder="63 --- --- ----"
                    name="mobile_number"
                    format="(###) ###-####"
                    mask=""
                    InputProps={{
                      disabled: dataRec.mobile_number.indexOf(mobile_num) > -1,
                      disableUnderline:
                        dataRec.mobile_number.indexOf(mobile_num) > -1,
                      inputProps: {
                        maxLength: /^\+639/.test(mobile_num) ? 13 : 11
                      }
                    }}
                  />
                  {!isDisabled && (
                    <IconButton
                      onClick={() => deleteRecipient("mobile_number", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                <Divider />
              </Fragment>
            ))}
          {errors.mobile_number ? errors.mobile_number : null}
        </div>

        {!isDisabled && (
          <div
            style={{
              width: "100%",
              // height: "15%",
              padding: "8px",
              boxSizing: "border-box",
              // marginTop: 410,
              position: "relative"
              // bottom: 0
            }}
          >
            {/* <br /> */}
            <Button
              onClick={() => addRecipient("mobile_number")}
              variant="outlined"
              color="primary"
              style={{ width: "100%", height: "100%" }}
              disabled={openSMS}
            >
              Add
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderAlert = () => {
    let component;
    switch (selected) {
      case "user_level_id":
        component = user_level_id();
        break;
      case "email_address":
        component = email_address();
        break;
      case "mobile_number":
        component = mobile_number();
        break;
      default:
        break;
    }
    return component;
  };

  if (loading) return <Loading />;
  return (
    <>
      <Typography className={classes.column}>Alert Type</Typography>
      <div
        style={{
          position: "relative",
          // display: "flex",
          // height: "100%",
          marginTop: 12,
          height: "80%"
          // maxHeight: "70%"
        }}
      >
        <Paper
          variant="outlined"
          square
          style={{
            width: "100%",
            height: "auto",
            minHeight: "100%",
            display: "flex"
          }}
        >
          <div
            style={{
              position: "relative",
              width: "50",
              // height: "100%",
              borderRight: "solid #e0e0e0",
              borderWidth: "1px"
            }}
          >
            <List>
              {[
                {
                  id: "user_level_id",
                  // display: "Via Portal",
                  display: errors.user_level_id ? (
                    <Badge variant="dot" color="secondary">
                      Via Portal
                    </Badge>
                  ) : (
                    "Via Portal"
                  ),
                  alert: "via_portal"
                },
                {
                  id: "email_address",
                  // display: "Via Email",
                  display: errors.email_address ? (
                    <Badge variant="dot" color="secondary">
                      Via Email
                    </Badge>
                  ) : (
                    "Via Email"
                  ),
                  alert: "via_email"
                },
                {
                  id: "mobile_number",
                  // display: "Via SMS",
                  display: errors.mobile_number ? (
                    <Badge variant="dot" color="secondary">
                      Via SMS
                    </Badge>
                  ) : (
                    "Via SMS"
                  ),
                  alert: "via_sms"
                }
              ].map(tab => (
                <MenuItem
                  key={tab.id}
                  selected={tab.id === selected}
                  classes={{ selected: classes.selected }}
                  onClick={() => {
                    handleSelected(tab.id);
                  }}
                >
                  <Checkbox
                    disabled={isDisabled}
                    color="primary"
                    style={{ zIndex: 999 }}
                    checked={alertType.indexOf(tab.alert) > -1}
                    onClick={() => {
                      handleAlertType(tab.alert);
                      handleRerender();
                    }}
                  />
                  <Typography>{tab.display}</Typography>
                </MenuItem>
              ))}
            </List>
          </div>
          <div
            style={{
              width: "70%",
              // height: "100%",
              position: "relative"
            }}
          >
            {renderAlert()}
          </div>
        </Paper>
      </div>
    </>
  );
}

export default function Alert(props) {
  const classes = useStyles();
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const isWTIAdmin = _.isEqual(+user?.user_level_id, +process.env.REACT_APP_WTI_ADMIN);
  const contextData = useContext(SearchContext);
  const [state, setState] = useState({
    id: 0,
    alert: "",
    description: "",
    // alert_frequency: "Always",
    alertmessage: "",
    sound_on: false,
    level: 0,
    frequency: [],
    time_start: "",
    time_end: "",
    vehicle_id: []
  });
  const [recipients, setRecipients] = useState({
    user_level_id: [],
    email_address: [],
    mobile_number: [],
    group_ids: user.group_ids
  });
  const [alertType, setAlertType] = useState([]);
  const [initialData, setInitialData] = useState({
    state: _.cloneDeep(state),
    recipients: _.cloneDeep(recipients),
    alertType: _.cloneDeep(alertType),
    group_ids: []
  });
  const [errors, setErrors] = useState({
    email_address: "",
    mobile_number: "",
    recipients: "",
    user_level_id: ""
  });
  const currDate = moment().format("YYYY-MM-DD");
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });
  const [editPermission, setEditPermission] = useState(false);
  const { location } = props;
  const { pathname, state: propState } = location;
  // const [groupId, setGroupId] = useState(localStorage.getItem("groupId")); // eslint-disable-line no-unused-vars
  const groupId = localStorage.getItem("groupId")
    ? localStorage.getItem("groupId")?.split(",")?.map(Number)
    : isWTIAdmin ? null : user.client?.group_ids;
  const history = useHistory();
  const { id } = useParams();
  const { data: data2, loading } = useQuery(GET_ALERT_REFERENCE, {
    variables: {
      id: id,
      // group_ids: propState?.groupFilter?.group_ids?.toString() /* groupId.toString() */
      group_ids: groupId?.toString()
    },
    fetchPolicy: "network-only"
  });
  const [openPortal, setOpenPortal] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);
  const [selectedFreq, setSelectedFreq] = useState([]);

  const [editAlert] = useMutation(EDIT_ALERT_REFERENCE, {
    onCompleted(data) {
      if (data) {
        if (data.edit_alert_reference.success) {
          AddLogs(process.env.REACT_APP_ALERTS_MODULE, "update", state.alert);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_ALERTS_MODULE
                  }
                }
              });
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_ALERTS_MODULE
                  }
                }
              });
            }
          });
        } else {
          const temp = [];
          data.edit_user_level.error.map(element =>
            temp.push({ [element.field]: element.message })
          );
          setErrors(Object.assign(errors, ...temp));
          setRerender(!rerender);
        }
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        showConfirmButton: false,
        timer: 3000
      });
    }
  });

  const initialState = () => {
    const temp = [];
    Object.keys(state).forEach(key => {
      if (key === "alert")
        temp.push({
          [key]: data2.get_alert_reference.alert_description.description
        });
      else if (key === "id")
        temp.push({
          [key]: data2.get_alert_reference.alert_description.id
        });
      else temp.push({ [key]: data2.get_alert_reference[key] });
    });
    setState(Object.assign(state, ...temp));
    const newData = {};
    newData.state = Object.assign({}, ...temp);
    if (data2.get_alert_reference.alert_type) {
      setAlertType(_.cloneDeep(data2.get_alert_reference.alert_type));
      newData.alertType = data2.get_alert_reference.alert_type;
    } else {
      setAlertType([]);
      newData.alertType = _.cloneDeep(alertType);
    }
    if (data2.get_alert_reference.recipients) {
      setRecipients(
        _.cloneDeep({
          user_level_id: data2.get_alert_reference.recipients.user_level_id,
          email_address: data2.get_alert_reference.recipients.email_address,
          mobile_number: data2.get_alert_reference.recipients.mobile_number,
          // group_ids: user.group_ids
          // group_ids: Number(groupId)
          group_ids: groupId
        })
      );
      newData.recipients = _.cloneDeep({
        user_level_id: data2.get_alert_reference.recipients.user_level_id,
        email_address: data2.get_alert_reference.recipients.email_address,
        mobile_number: data2.get_alert_reference.recipients.mobile_number,
        // group_ids: user.group_ids
        // group_ids: Number(groupId)
        group_ids: groupId
      });
      // setRecipients(lodash.cloneDeep(data2.get_alert_reference.recipients));
      // newData.recipients = lodash.cloneDeep(
      //   data2.get_alert_reference.recipients
      // );
    } else {
      setRecipients({
        user_level_id: [],
        email_address: [],
        mobile_number: [],
        // group_ids: user.group_ids
        // group_ids: Number(groupId)
        group_ids: groupId
      });
      newData.recipients = {
        user_level_id: [],
        email_address: [],
        mobile_number: [],
        // group_ids: user.group_ids
        // group_ids: Number(groupId)
        group_ids: groupId
      };
    }
    const freqTemp = [];

    frequencyData.forEach(item => {
      if (state.frequency?.includes(item.label)) {
        freqTemp.push(item);
      }
    });

    setSelectedFreq(freqTemp);
    setInitialData(newData);
    setRerender(!rerender);
    setErrors({
      email_address: "",
      mobile_number: "",
      recipients: "",
      user_level_id: ""
    });
  };

  useEffect(() => {
    if (data2) {
      initialState();
    }
  }, [data2]);

  const checkChanges = () => {
    const tempRecipients = { ...recipients };
    tempRecipients.group_ids = [];
    const isRecipientsChanged =
      !(
        data2.get_alert_reference.recipients === null &&
        Object.values(tempRecipients).every(rec => rec.length === 0)
      ) && !_.isEqual(recipients, data2.get_alert_reference.recipients);

    const isReportTypesChanged = !(
      (data2.get_alert_reference.alert_type === null ||
        data2.get_alert_reference.alert_type.length <= 0) &&
      _.isEqual(alertType, data2.get_alert_reference.alert_type)
    );

    const isDesciptionsChanged = Object.keys(state).some(key => {
      if (key === "alert") {
        return (
          state[key] !== data2.get_alert_reference.alert_description.description
        );
      }
      if (key === "id") {
        return state[key] !== data2.get_alert_reference.alert_description.id;
      }

      return state[key] !== data2.get_alert_reference[key];
    });

    return isRecipientsChanged || isReportTypesChanged || isDesciptionsChanged;
  };

  const check = field => {
    if (!errors[field]) {
      let err = "";
      switch (field) {
        // case "recipients":
        //   if (
        //     recipients.user_level_id.length +
        //       recipients.email_address.length +
        //       recipients.mobile_number.length <
        //     1
        //   ) {
        //     // err = "At least one recipient is required";
        //     err = (
        //       <AlertPrompt severity="error" className={classes.alert}>
        //         At least one recipient is required
        //       </AlertPrompt>
        //     );
        //   }
        //   break;
        case "user_level_id":
          if (recipients.user_level_id.some(userlevelid => userlevelid === 0)) {
            // err = "Invalid User Level";
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                Invalid User Level
              </AlertPrompt>
            );
          }
          break;
        case "email_address":
          if (
            recipients.email_address.some(
              email_address_add => email_address_add === ""
            )
          ) {
            // err = "This field is required";
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                This field is required
              </AlertPrompt>
            );
          } else if (
            recipients.email_address.some(
              email_address_add => !validate("email", email_address_add)
            )
          ) {
            // err = "Invalid email_address Address";
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                Invalid email address
              </AlertPrompt>
            );
          }
          break;
        case "mobile_number":
          if (recipients.mobile_number.some(mobile_num => mobile_num === "")) {
            // err = "This field is required";
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                This field is required
              </AlertPrompt>
            );
          } else if (
            recipients.mobile_number.some(mobile_num => {
              return !validate(
                "numeric",
                mobile_num.replace(/^(09|\+639)/, "")
              );
            })
          ) {
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                Numeric characters only
              </AlertPrompt>
            );
          } else if (
            recipients.mobile_number.some(mobile_num => {
              return (
                !validate("mobile_number_ph", mobile_num) ||
                // mobile_num.toString().substring(0, 3) !== "+63" ||
                !/^\+?63/.test(mobile_num.toString()) ||
                mobile_num.replace(/^(09|\+639)/, "").toString().length !== 9
              );
            })
          ) {
            // err = "Invalid Mobile Number. Should include (63)";
            err = (
              <AlertPrompt severity="error" className={classes.alert}>
                Invalid Mobile Number. Should include prefix (63)
              </AlertPrompt>
            );
          }
          break;
        default:
          break;
      }
      return err;
    }

    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setErrors(Object.assign(errors, ...temp));
    return keys.every(element => errors[element] === "");
  };

  const handleOpenDialog = dialog_name => {
    if (dialog_name === "save") {
      if (validateFields()) setDialog({ ...dialog, [dialog_name]: true });
      else {
        setRerender(!rerender);
      }
    } else {
      setDialog({ ...dialog, [dialog_name]: true });
    }
  };

  const handleCloseDialog = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleChangeRec = (e, index) => {
    const { name, value } = e.target;
    if (name === "user_level_id") {
      setOpenPortal(false);
    }
    if (name === "email_address") {
      setOpenEmail(false);
    }
    if (name === "mobile_number") {
      setOpenSMS(false);
    }
    const temp = recipients[name];
    temp[index] = value;
    setErrors({ ...errors, [name]: "" });
    setRecipients({ ...recipients, [name]: temp });
  };

  const handleAlertType = alert => {
    const temp = alertType;
    const index = temp.indexOf(alert);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(alert);
    }
    setAlertType(temp);
  };

  const addRecipient = recipient => {
    if (recipient === "user_level_id") {
      if (recipients.user_level_id.some(userlevelid => userlevelid === 0)) {
        setOpenPortal(false);
      } else {
        setOpenPortal(true);
      }
    }
    if (recipient === "email_address") {
      if (
        recipients.email_address.some(
          email_address_add => email_address_add === ""
        )
      ) {
        setOpenEmail(false);
      } else {
        setOpenEmail(true);
      }
    }
    if (recipient === "mobile_number") {
      if (recipients.mobile_number.some(mobile_num => mobile_num === "")) {
        setOpenSMS(false);
      } else {
        setOpenSMS(true);
      }
    }

    let temp = [];
    if (recipients[recipient]) temp = recipients[recipient];
    temp.push(recipient === "user_level_id" ? 0 : "");
    setErrors({ ...errors, recipients: "" });
    setRecipients({
      ...recipients,
      [recipient]: temp
    });
  };

  const deleteRecipient = (recipient, index) => {
    const temp = recipients[recipient];
    temp.splice(index, 1);
    setErrors({ ...errors, [recipient]: "" });
    setRecipients({
      ...recipients,
      [recipient]: temp
    });

    if (recipient === "user_level_id") {
      if (recipients.user_level_id.some(userlevelid => userlevelid === 0)) {
        setOpenPortal(true);
      } else {
        setOpenPortal(false);
      }
    }
    if (recipient === "email_address") {
      if (
        recipients.email_address.some(
          email_address_add => email_address_add === ""
        )
      ) {
        setOpenEmail(true);
      } else {
        setOpenEmail(false);
      }
    }
    if (recipient === "mobile_number") {
      if (recipients.mobile_number.some(mobile_num => mobile_num === "")) {
        setOpenSMS(true);
      } else {
        setOpenSMS(false);
      }
    }
  };

  const discarded = () => {
    initialState();
    handleCloseDialog("discard");
    setEditMode(false);
    // Swal.fire({
    //   title: "Discarded",
    //   icon: "success",
    //   showConfirmButton: false,
    //   timer: 3000
    // });
  };

  const handleChange = event => {
    const { name, value, checked, type } = event.target;
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    // trimEmptyFields(); // for now, habang di pa alam kung pano ihahandle to
    const { code, group_ids, device_type } = data2.get_alert_reference;
    editAlert({
      variables: {
        id: id,
        alert_reference: {
          alert_description_id: Number(state.id),
          alert_type: alertType,
          recipients: recipients,
          level: state.level,
          sound_on: state.sound_on,
          device_type_id: device_type === null ? 0 : Number(device_type?.id),
          group_ids,
          code,
          frequency: state.frequency === null ? [] : state.frequency,
          time_start: state.time_start,
          time_end: state.time_end,
          vehicle_id: state.vehicle_id
        }
      }
    });
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpenDialog("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const onFrequencyChangeHandler = event => {
    const { value } = event.target;
    let removedDuplicateFreq = [];
    let freqTemp = [];
    const isEveryday = value.filter(item => item.id === 1).length > 0;
    if (!isEveryday) {
      value.forEach(item => {
        if (removedDuplicateFreq.forEach(freq => freq.id !== item.id) >= 0) {
          removedDuplicateFreq = removedDuplicateFreq.filter(
            freq => freq.id === value[0].id
          );
        } else {
          removedDuplicateFreq.push(item);
        }
      });
    } else {
      removedDuplicateFreq = frequencyData;
    }

    removedDuplicateFreq.forEach(freq => {
      freqTemp.push(freq.label);
    });
    setState(prev => ({ ...prev, frequency: freqTemp }));
    setSelectedFreq(_.sortBy(removedDuplicateFreq, "id"));
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: process.env.REACT_APP_ALERTS_MODULE
    });
  }, []);

  if (loading) return <Loading />;

  if (!data2) return <ErrorPage />;
  return (
    <AccessControl
      resource={pathname}
      process="view"
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        <Header
          // process={
          //   !_.isEqual(initialData.state, state) ||
          //   !_.isEqual(initialData.alertType, alertType) ||
          //   !_.isEqual(initialData.recipients, recipients)
          //     ? "Edit"
          //     : "View"
          // }
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_ALERTS_MODULE}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={_.isEqual(initialData.state, state)}
          history={propState}
        />
        <div className={classes.container}>
          <Paper
            variant="outlined"
            style={{ padding: 32, boxSizing: "border-box" }}
          >
            <Grid item xs={12} style={{ display: "flex", marginBottom: 12 }}>
              <Typography>
                All fields with <span style={{ color: "red" }}>*</span> are
                required
              </Typography>
            </Grid>
            <Grid
              container
              spacing={6}
              style={{ height: "100%", position: "relative" }}
            >
              <Grid item xs={12} sm={3}>
                <Typography className={classes.column}>
                  Alert Details
                </Typography>
                <TextField
                  // required
                  name="alert"
                  label={<b>Alert Description</b>}
                  value={state.alert}
                  onChange={e => handleChange(e)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "#1b1b1b",
                      letterSpacing: 1.05,
                      fontSize: 18
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: { color: "#808080" },
                    inputProps: {
                      style: {
                        margin: "4px auto"
                      }
                    }
                  }}
                  className={classes.field}
                />
                <TextField
                  // required
                  name="message_display"
                  label={<b>Message Display</b>}
                  value={state.alertmessage}
                  multiline
                  rows="4"
                  onChange={e => handleChange(e)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "#1b1b1b",
                      letterSpacing: 1.05,
                      fontSize: 18
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: { color: "#808080" },
                    inputProps: {
                      style: {
                        height: 20,
                        margin: "4px auto"
                      }
                    }
                  }}
                  className={classes.field}
                />
                <FormControl className={classes.field}>
                  <InputLabel
                    shrink
                    // required
                    style={{
                      color: "#1b1b1b",
                      letterSpacing: 1.05,
                      fontSize: 18,
                      fontWeight: 600
                    }}
                  >
                    Alert Level
                  </InputLabel>
                  <Select
                    value={state.level}
                    onChange={e => handleChange(e)}
                    displayEmpty
                    name="level"
                    disabled={!editMode}
                    disableUnderline={!editMode}
                    IconComponent={iconProps => (
                      <ArrowDropDown
                        {...iconProps}
                        style={{ display: editMode ? "block" : "none" }}
                      />
                    )}
                    style={{
                      padding: "8px 0px",
                      textTransform: "capitalize"
                    }}
                  >
                    <MenuItem value={0}>
                      <span style={{ color: "#9e9e9e" }}>Discrete</span>
                    </MenuItem>
                    <MenuItem value={1}>
                      <span style={{ color: "#63F160" }}>Normal</span>
                    </MenuItem>
                    <MenuItem value={2}>
                      <span style={{ color: "#ffd600" }}>Moderate</span>
                    </MenuItem>
                    <MenuItem value={3}>
                      <span style={{ color: "#ff5722" }}>Critical</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.field}>
                  <InputLabel
                    shrink
                    // required
                    style={{
                      color: "#1b1b1b",
                      letterSpacing: 1.05,
                      fontSize: 18,
                      fontWeight: 600
                    }}
                  >
                    Frequency
                  </InputLabel>
                  <Select
                    value={selectedFreq}
                    onChange={onFrequencyChangeHandler}
                    displayEmpty
                    name="frequency"
                    disabled={!editMode}
                    disableUnderline={!editMode}
                    IconComponent={iconProps => (
                      <KeyboardArrowDownRoundedIcon
                        {...iconProps}
                        style={{ display: editMode ? "block" : "none" }}
                      />
                    )}
                    style={{
                      padding: "8px 0px",
                      textTransform: "capitalize"
                    }}
                    renderValue={selected => {
                      const isEveryday =
                        selected.filter(item => item.id === 1).length > 0;
                      return isEveryday
                        ? frequencyData[0].label
                        : selected
                            .map(freq => freq.label.slice(0, 3))
                            .join(", ");
                    }}
                    multiple
                  >
                    {frequencyData.map(freq => {
                      const isChecked = state.frequency
                        ? state.frequency.filter(item => item === freq.label)
                            .length > 0
                        : false;
                      return (
                        <MenuItem key={freq.id} value={freq}>
                          <Checkbox
                            checked={isChecked}
                            color="primary"
                            disabled={
                              selectedFreq.filter(item => item.id === 1)
                                .length > 0 && freq.id !== 1
                            }
                          />
                          <ListItemText primary={freq.label} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    fontFamily: "nunito"
                  }}
                >
                  Time Range
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={3}
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      style={{ width: 100 }}
                      margin="normal"
                      id="time-picker"
                      ampm={false}
                      keyboardIcon={editMode && <ScheduleRoundedIcon />}
                      disabled={!editMode}
                      value={
                        moment(currDate + " " + state.time_start).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ) || null
                      }
                      error={false}
                      helperText={null}
                      onChange={e =>
                        setState(prev => ({
                          ...prev,
                          time_start: moment(e).format("HH:mm:ss")
                        }))
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                      InputProps={{
                        disableUnderline: !editMode
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography>to</Typography>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      style={{ width: 100 }}
                      margin="normal"
                      id="time-picker"
                      ampm={false}
                      keyboardIcon={editMode && <ScheduleRoundedIcon />}
                      disabled={!editMode}
                      value={
                        moment(currDate + " " + state.time_end).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ) || null
                      }
                      error={false}
                      format={"HH:mm"}
                      helperText={null}
                      onChange={e =>
                        setState(prev => ({
                          ...prev,
                          time_end: moment(e).format("HH:mm:ss")
                        }))
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                      InputProps={{
                        disableUnderline: !editMode
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                {/* <TextField
                // required
                name="alert_frequency"
                label="Alert Frequency"
                value={state.alert_frequency}
                onChange={e => handleChange(e)}
                InputLabelProps={{ shrink: true, style: { color: "black" } }}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  style: { color: "#808080" }
                }}
                className={classes.field}
              /> */}
                <TextField
                  // required
                  name="description"
                  label={<b>Remarks</b>}
                  value={state.description || ""}
                  multiline
                  rows="4"
                  onChange={e => handleChange(e)}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "#1b1b1b",
                      letterSpacing: 1.05,
                      fontSize: 18
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: { color: "#808080" }
                  }}
                  className={classes.field}
                />
                {/* <Tooltip
                  classes={{ tooltip: classes.toolTip }}
                  placement="top-start"
                  title="Turn on or turn off the sound of the alert on your web portal"
                  aria-label="sound"
                >
                  <div
                    style={{
                      margin: "12px 0",
                      width: "90%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <HelpIcon className={classes.helpIcon} />

                    <FormControlLabel
                      control={
                        <Switch
                          name="sound_on"
                          color="primary"
                          checked={!!state.sound_on}
                          onChange={e => handleChange(e)}
                          disabled={!editMode}
                        />
                      }
                      style={{ width: "100%" }}
                      labelPlacement="start"
                      label="Sound"
                    />
                  </div>
                </Tooltip> */}
              </Grid>
              <Grid item xs={12} sm={9} lg={5}>
                {/* {errors.recipients && (
                <AlertPrompt severity="error" className={classes.alert}>
                  {errors.recipients}
                </AlertPrompt>
              )}
              {errors.user_level_id && (
                <AlertPrompt severity="error" className={classes.alert}>
                  {errors.user_level_id}
                </AlertPrompt>
              )}
              {errors.email_address && (
                <AlertPrompt severity="error" className={classes.alert}>
                  {errors.email_address}
                </AlertPrompt>
              )}
              {errors.mobile_number && (
                <AlertPrompt severity="error" className={classes.alert}>
                  {errors.mobile_number}
                </AlertPrompt>
              )} */}
                <AlertType
                  recipients={recipients}
                  dataRec={
                    data2.get_alert_reference.recipients
                      ? data2.get_alert_reference.recipients
                      : {
                          email_address: [],
                          mobile_number: [],
                          user_level_id: []
                        }
                  }
                  handleChange={handleChangeRec}
                  addRecipient={addRecipient}
                  editMode={editMode}
                  errors={errors}
                  deleteRecipient={deleteRecipient}
                  alertType={alertType}
                  handleAlertType={handleAlertType}
                  openPortal={openPortal}
                  openEmail={openEmail}
                  openSMS={openSMS}
                  alertDetails={data2?.get_alert_reference}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Vehicles
                  isEditMode={editMode}
                  setState={setState}
                  state={state}
                  fetchedData={data2?.get_alert_reference?.vehicles || []}
                />
              </Grid>
            </Grid>
            {editMode && (
              <div
                style={{
                  position: "relative",
                  bottom: -15,
                  right: 0,
                  left: 0,
                  textAlign: "center"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleOpenDialog("save")}
                >
                  Save Details
                </Button>
              </div>
            )}
          </Paper>
        </div>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleCloseDialog("save")}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save alerts?"
        />
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleCloseDialog("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() => setRedirect(true)}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
}
