import { createTheme, alpha } from "@material-ui/core/styles";

// Theme instance
const theme = createTheme();

// console.log(theme)
const ColorPrimary = "#F49400";

export const theme1 = createTheme({
  palette: {
    primary: {
      // main: "#48E194",
      main: `${ColorPrimary}`,
      contrastText: "#ffffff",
      light: alpha("#F49400", 0.2),
      dark: "#3F3F3F",
    },
    secondary: { 
      main: "#ffffff",
      light: "#FF845E",
    },
    // contrastThreshold: 3
  },
  status: {
    danger: { main: "#ff1744" },
    error: { main: "#fa4a4a" },
    neutral: { main: "#808080" }
  },
  props: {
    MuiBadge: {
      overlap: "rectangular"
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        color: "white",
        "&$disabled": {
          color: theme.palette.primary.contrastText,
          // backgroundColor: "#F49400",
          backgroundColor: `${ColorPrimary}`,
          opacity: 0.5
        }
      },
      outlined: {
        // color: "#F49400",
        // border: "1px solid #F49400",
        color: `${ColorPrimary} !important`,
        border: `1px solid ${ColorPrimary} !important`,
        "&$disabled": {
          // color: "#F49400",
          color: `1px solid ${ColorPrimary}`,
          // border: "1px solid #F49400",
          border: `1px solid ${ColorPrimary}`,
          opacity: 0.5
        }
      }
    },
    outlinedSecondary: {
      borderRadius: 32,
      border: "1px solid #F0AA8F",
      color: "#F0AA8F"
    },
    MuiTableCell: {
      root: {
        padding: 4
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "rgba(0, 0, 0, 0.54)"
        }
      }
    },
    MuiBadge: {
      colorSecondary: {
        backgroundColor: "red"
      }
    },
    MuiInputLabel: {
      asterisk: {
        color: "red",
        fontWeight: "bold"
      }
    },
    MuiTab: {
      root: {
        backgroundColor: "white",
        border: "1px solid #D6D6D6",
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        fontSize: 14,
        "&$selected": {
          color: theme.palette.primary.contrastText,
          // backgroundColor: "#F49400"
          // backgroundColor: theme.palette.primary.main
          backgroundColor: ColorPrimary
        }
      },
      textColorInherit: {
        color: "#000000"
      },
      wrapper: {
        fontWeight: "bold",
        textTransform: "uppercase"
      }
    }
  },
  textDecoration: "none",
  typography: {
    fontFamily: "Nunito"
  }
  // shape: {
  //   borderRadius: "25px"
  // }
});

export default createTheme({
  palette: {
    primary: { main: "#3ba4f7", contrastText: "#ffffff" },
    secondary: { main: "#ffffff" }
  },
  status: {
    danger: { main: "#ff1744" },
    error: { main: "#fa4a4a" },
    neutral: { main: "#808080" }
  },
  overrides: {
    MuiButton: {
      contained: {
        color: "#ffffff",
        backgroundColor: "#808080"
      }
    },
    outlinedSecondary: {
      borderRadius: 32,
      border: "1px solid #F0AA8F",
      color: "#F0AA8F"
    },
    MuiTableCell: {
      root: {
        padding: 4
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "rgba(0, 0, 0, 0.54)"
        }
      }
    },
    MuiBadge: {
      colorSecondary: {
        backgroundColor: "red"
      }
    },
    MuiInputLabel: {
      asterisk: {
        color: "red",
        fontWeight: "bold"
      }
    }
  },
  textDecoration: "none",
  typography: {
    fontFamily: "Nunito"
  }
  // shape: {
  //   borderRadius: "25px"
  // }
});
