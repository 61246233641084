import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  wrapper: {
    display: "flex",
    background: "white",
    width: "343px",
    margin: 10,
    boxShadow: "0px 5px 30px -10px #0000008a",
    position: "relative",
    top: 6
  },
  seachMenu: {
    border: "1px solid #aaaaaa70",
    borderLeft: "none",
    display: "flex",
    alignItems: "center",
    padding: "0px 2px"
  },
  button: {
    border: "1px solid #aaaaaa70",
    borderLeft: "none",
    display: "flex",
    alignItems: "center",
    padding: "0px 2px"
  },
  textField: {
    fontSize: "12px",
    border: "1px solid #aaaaaa70",
    padding: "2px 8px",
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      paddingBottom: "3px"
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    "&::before": {
      border: "none"
    },
    "&::after": {
      border: "none"
    }
  }
});
