import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmationDialog = props => {
  const { toggle, title, content, fn, close, type } = props;
  const classes = useStyles();
  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.closeContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          <span className={classes.closeTitle}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={classes.closeDescription}>{content}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {type !== "basic_confirm_modal" && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              className={classes.circularButtonNo}
            >
              No
            </Button>
          )}
          <Button
            variant="contained"
            onClick={event => {
              fn(event);
              handleClose();
            }}
            color="primary"
            autoFocus
            className={classes.circularButtonYes}
          >
            {type === "basic_confirm_modal" ? "Ok" : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  closeContainer: {
    borderRadius: 12,
    width: 429,
    paddingBottom: 13,
    paddingRight: 13
  },
  circularButtonNo: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.secondary.light}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none"
  },
  circularButtonYes: {
    borderRadius: "22px",
    border: `1px solid ${theme.palette.primary.main}`,
    width: 110,
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      opacity: "0.75",
      backgroundColor: theme.palette.primary.main
    }
  },
  closeTitle: {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  closeDescription: {
    color: theme.palette.primary.dark,
    fontSize: "13px",
    lineHeight: "25px"
  }
}));

export default ConfirmationDialog;
