/* global google */
import React from "react";
import { DrawingManager } from "@react-google-maps/api";

const Draw = ({ drawingCompled = () => {}, active = true, ...otherProps }) => {
  return (
    <DrawingManager
      options={{
        drawingMode: active ? google.maps.drawing.OverlayType.RECTANGLE : null,
        drawingControl: false,
        drawingControlOptions: {
          drawingModes: active
            ? [google.maps.drawing.OverlayType.RECTANGLE]
            : []
          // position: google.maps.ControlPosition.BOTTOM_RIGHT
        },
        rectangleOptions: {
          fillColor: "gray",
          fillOpacity: ".5",
          strokeWeight: 0
        }
      }}
      onRectangleComplete={args => {
        drawingCompled(args);
      }}
      {...otherProps}
    />
  );
};

export default Draw;
