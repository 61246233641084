import { makeStyles } from "@material-ui/styles";

const useBasicModalStyles = makeStyles({
  root: {
    backdropFilter: "blur(0.5px)",
    background: "#ffffff4f !important"
  },
  main: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "550px",
    height: "550px",
    minWidth: "585px",
    width: "585px",
    background: "white",
    borderRadius: 18,
    padding: 18,
    boxSizing: "border-box"
  }
});

export default useBasicModalStyles;
