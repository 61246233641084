/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GET_ADMIN_CLIENTS } from "../graphql/Queries";
import _ from "lodash";

const useToolbarStyles = makeStyles(theme => ({
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const ClientAutocomplete = props => {
  const {
    groupFilter,
    setter,
    fieldName = "",
    inputLabelStyle,
    ...autocompleteProps
  } = props;
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");

  const [data, setData] = useState([]);
  const { data: clients } = useQuery(GET_ADMIN_CLIENTS, {
    variables: { first: 5, keyword }
  });

  useEffect(() => {
    if (clients?.get_clients?.clients) {
      const arr = clients?.get_clients?.clients.filter(
        d => d?.name?.toLowerCase() !== groupFilter?.name?.toLowerCase()
      );
      setData(_.without([groupFilter?.name && groupFilter, ...arr], undefined));
      // setData(clients?.get_clients?.clients);
    }
  }, [clients, keyword]);

  return (
    <Autocomplete
      // multiple
      className={classes.autoComplete}
      style={{ height: 30, marginLeft: 30, width: 220 }}
      // disableCloseOnSelect
      options={data || []}
      getOptionLabel={option => option?.name}
      getOptionSelected={(o, v) => {
        if (v.name === o.name) return o.name;
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="All"
          onChange={e => setKeyword(e.target.value)}
          onBlur={() => setKeyword("")}
          label={fieldName}
          InputLabelProps={inputLabelStyle}
          InputProps={{
            ...params.InputProps,
            style: { padding: fieldName !== "" ? "13px 0" : "0" }
          }}
        />
      )}
      disableClearable
      value={groupFilter}
      onChange={(e, nv) => {
        setter(nv);
      }}
      {...autocompleteProps}
    />
  );
};

export default ClientAutocomplete;
