/* eslint-disable no-lonely-if */
import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider
} from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Slider,
  Tooltip,
  Box,
  IconButton,
  Button,
  Menu,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useTheme,
  Select,
  MenuItem,
  CircularProgress,
  Divider
} from "@material-ui/core";
import {
  PlayArrowRounded,
  PauseRounded,
  Replay as ReplayIcon,
  Autorenew,
  ZoomIn,
  ZoomOut,
  List as ListIcon,
  MoreVertRounded as MoreIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  GpsFixed,
  CheckRounded,
  CalendarToday as CalendarTodayIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon
} from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  InteractionItem
} from "chart.js";
import { Line, getElementAtEvent } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import _, { isNull } from "lodash";
import Swal from "sweetalert2";
import {
  GET_TEXTUAL_LOCATION,
  GET_FUEL_READING
} from "../../../graphql/Queries";
import MapComponent from "./MapComponent";
import useAllowedPermissions from "../../../utils/hooks/useAllowedPermissions";
import { SkipPrevious, SkipNext } from "./Icons";
import SeeMore from "../../../utils/UI/SeeMore/SeeMore";
import { center as DEFAULT_CENTER } from "../../../utils/Map/Inner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  zoomPlugin
);

const themeStyles = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: "10px",
        textAlign: "center"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  graphText: {
    fontSize: 12,
    fontWeight: "bold"
  },
  graph: {
    // width: "95%",
    // paddingLeft: "20px"
    display: "block",
    width: "99%",
    height: "250"
  },
  graphChartContainer: {
    position: "relative",
    "&::after": {
      lineHeight: "1.5",
      fontWeight: "400",
      marginTop: -60,
      content: '"Time"',
      position: "absolute",
      bottom: "5%",
      left: "50%",
      fontSize: "12px",
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
      letterSpacing: "0.00938em"
    }
    // "&::before": {
    //   lineHeight: "1.5",
    //   fontWeight: "400",
    //   marginTop: -60,
    //   content: '"Speed"',
    //   position: "absolute",
    //   top: "70%",
    //   left: "-5px",
    //   transform: "rotate(270deg)",
    //   fontSize: "12px",
    //   fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    //   letterSpacing: "0.00938em"
    // }
  },
  graphSlider: {
    paddingLeft: "25px",
    width: "97.5%"
  },
  timePicker: {
    width: "48px",
    border: "1px solid grey",
    marginRight: "7.5px",
    height: "100%"
  },
  timePickerField: {
    fontSize: "10px !important"
  },
  graphValue: {
    fontSize: "12px"
  },
  graphInfo: {
    maxHeight: "55vh",
    overflowY: "auto",
    minWidth: "85%",
    paddingInline: "1.5rem",
    display: "flex",
    flexDirection: "column",
    gap: 24
  },
  graphBtn: {
    "&:hover, &:focus": {
      color: "#54A5F7",
      transition: "0.3s ease"
    },
    display: "flex"
  },
  activeLegend: {
    display: "flex",
    color: "#54A5F7"
  },
  graphCreateBtn: {
    "&:hover, &:focus": {
      color: "#3A73AC",
      transition: "0.3s ease"
    },
    color: "#54A5F7",
    textAlign: "right"
  },
  graphBtnContainer: {
    padding: "0.5rem 0 0.5rem 0",
    margin: "0.5rem 0 1rem 1.5rem",
    // borderBottom: "2px solid #f1f1f1",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  sliderRoot: {
    color: theme.palette.primary.main
  },
  create_geofence_btn: {
    color: theme.palette.primary.main
  },
  toggleButtonLabel: {
    fontSize: 10
  },
  headerToggleButton: {
    borderRadius: "16px",
    color: "#ffffff",
    // backgroundColor: "#3ba4f7",
    "&:hover, &:focus": {
      // backgroundColor: "rgb(41, 114, 172)",
      transition: "0.3s ease"
    },
    margin: "18px"
  },
  graphMenuOption: {
    margin: "0",
    height: "1.5rem",
    paddingRight: "8px"
  },
  graphMenuLabel: {
    fontSize: "12px"
  },
  expandIcon: {
    border: "5px solid #FFF",
    borderRadius: "50%",
    color: "#FFF",
    cursor: "pointer",
    position: "absolute",
    // backgroundColor: "rgba(84, 165, 247, 1)",
    left: "-13px",
    top: "50%",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      transition: "0.3s ease"
    }
  },
  gpsFixed: {
    color: theme.palette.primary.main,
    fontSize: 14,
    textAlign: "center"
  },
  box: {
    "& .MuiInput-underline:before, & .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after": {
      border: "none"
    }
  },
  select: {
    fontSize: 12,
    fontWeight: 600,
    background: "#eee",
    paddingLeft: 8
  },
  menuItemPaper: {
    "& li": {
      fontSize: 12,
      textAlign: "right"
    }
  },
  headerDate: {
    fontSize: 12,
    fontWeight: "bold"
  },
  headerCalendar: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 14
  }
}));

let playInterval;
function LineChart(props) {
  const {
    isMapView,
    date,
    user,
    location,
    logs,
    mapLocationProps,
    pausedProps: { paused, setPaused },
    setVehicleDirectionCounter,
    handleChangeView,
    fullScreenHandler,
    isFullScreen,
    vehicleData,
    fuelReading,
    setCenter,
    center,
    timeFrame,
    setTimeFrame,
    textLocations,
    setTextLocations,
    fuelReadingLoading,
    primaryData
  } = props;
  // default value for null data's
  const defaultValueGraph = () => {
    const defaultValue = [];
    if (logs) logs.forEach(() => defaultValue.push(0));
    return defaultValue;
  };
  // dataset shown in graph.
  const defaultGraphDatasets = [
    {
      label: "Speed (kph)",
      data: _.isEmpty(logs)
        ? defaultValueGraph()
        : logs.map(data => data.Speed),
      borderColor: "rgba(71, 192, 28, 1)",
      backgroundColor: "rgba(71, 192, 28, 1)",
      fontSize: "12px",
      showTooltip: true
    },
    {
      label: "Temperature (C)",
      data: _.isEmpty(logs)
        ? defaultValueGraph()
        : logs.map(data => data.Temperature),
      borderColor: "rgba(60, 164, 247, 0.2)",
      backgroundColor: "rgba(60, 164, 247, 0.2)",
      fontSize: "12px",
      showTooltip: true
    },
    {
      label: "Fuel (L)",
      // data: logs.map(data => data.fuel),
      data: _.isEmpty(fuelReading)
        ? defaultValueGraph()
        : fuelReading.map(data => data.reading),
      borderColor: "rgba(187,107,217,1)",
      backgroundColor: "rgba(187,107,217,1)",
      fontSize: "12px",
      showTooltip: true
    }
  ];

  const theme = useTheme();
  const [selectedDate, handleDateChange] = useState("2021-01-01 00:00:00");
  const speedOptions = [2, 1, 0.5];
  // const speedSettings = [1, 2];
  const [speed, setSpeed] = useState(1); // speedOptions index for playInterval
  // const [legend, setLegend] = useState("speed");
  const classes = useStyles();
  const graphRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [selectedLegend, setSelectedLegend] = useState([0]);
  const [legends, setLegends] = useState();
  const [graphDataset, setGraphDataset] = useState([defaultGraphDatasets[0]]);
  const tempDatasets = [];
  const [legendDisabled, setLegendDisabled] = useState(null);
  const { has_fuel_sensor, has_temp_sensor } = primaryData || {};

  useEffect(() => {
    if (playInterval || paused) {
      clearInterval(playInterval);
      setPaused(true);
    }
  }, [date, isMapView]);

  useEffect(() => {
    setTimeFrame(0);
    if (logs.length) {
      handleDateChange(logs[0].time);
    }
  }, [logs]);

  const menuOpenHandler = event => setAnchorEl(event.currentTarget);
  const menuCloseHandler = () => setAnchorEl(null);

  const handleTimeSelect = value => {
    const baseDate = moment(logs[logs.length - 1].time).format("YYYY-MM-DD");
    // Check if no blank values

    if (!isNull(value) && !value.includes("_")) {
      // Convert string to array
      const timeArray = value.split(":");
      // Validate input values
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < timeArray.length; i++) {
        if (i === 0 && Number(timeArray[0]) > 23) {
          // For index 0 (hours), max value = 23
          timeArray[0] = "23";
        } else if (Number(timeArray[i]) > 59) {
          // For other indices (min,sec), max value = 59
          timeArray[i] = "59";
        }
      }

      // Join array elements to return to HH:mm:ss format
      const timeString = timeArray.join(":");
      // Set timepicker value to validated values (max 23:59:59)
      handleDateChange(`${baseDate} ${timeString}`);

      // Compare input time to other time in logs, get closest value
      const baseTime = moment(timeString, "HH:mm:ss");
      let diff;
      let closestIndex = 0;
      logs.forEach((log, index) => {
        // Format log time
        const formatLogTime = moment(log.time).format("HH:mm:ss");
        const logTime = moment(formatLogTime, "HH:mm:ss");
        // Calculate time difference between input (baseTime) and log time
        const timeDiff = moment.duration(baseTime.diff(logTime));

        // Convert timeDiff from milliseconds to minutes
        // eslint-disable-next-line no-underscore-dangle
        const timeDiffMinutes = Math.abs(timeDiff._milliseconds / (60 * 1000));

        if (index === 0) {
          // set initial value for diff
          diff = timeDiffMinutes;
        } else if (timeDiffMinutes < diff) {
          // if log time is closer to input time than current diff
          // change diff and index value to new log time
          diff = timeDiffMinutes;
          closestIndex = index;
        }
      });

      // set slider value to closest log time
      setTimeFrame(closestIndex);
    }
  };

  const [getTextualLocation] = useLazyQuery(GET_TEXTUAL_LOCATION, {
    onCompleted(data) {
      const tempArray = textLocations.length ? [...textLocations] : [...logs];
      tempArray.forEach((log, index) => {
        if (_.isEqual(logs[timeFrame].location, log.location)) {
          tempArray[index].textualLoc = data.get_textual_location || "-";
        }
      });
      setTextLocations(tempArray);
    }
  });

  const processTextualLocation = logIndex => {
    if (!logs.length || textLocations[logIndex]?.textualLoc || !paused) return;

    getTextualLocation({
      variables: {
        points: logs[logIndex].location,
        group_ids: user.group_ids
      }
    });
  };

  useEffect(() => {
    if (logs.length) {
      // Check if textLocations[timeFrame] has textualLoc
      if (!textLocations[timeFrame]?.textualLoc) {
        // if no textualLoc field in current timeFrame, get textual location and add textualLoc field in textLocations
        processTextualLocation(timeFrame);
      }

      // Update timepicker value if timeFrame (slider value / logs index) is updated
      handleDateChange(logs[timeFrame].time);

      // If timeFrame reached end of slider, stop playInterval and show play button
      if (timeFrame >= logs.length - 1) {
        clearInterval(playInterval);
        setPaused(true);
      }

      const { mapLocation, setMapLocation } = mapLocationProps;
      // If coordinates of map and graph do not match, update map location (marker coordinates)
      if (!_.isEqual(mapLocation.location, logs[timeFrame].location)) {
        setMapLocation(logs[timeFrame]);
      }
    }
    setVehicleDirectionCounter(timeFrame);
  }, [timeFrame]);

  useEffect(() => {
    if (graphRef.current) {
      try {
        // get active graphDataset (lines)
        const graphLines = graphRef.current.data.datasets.filter(
          d => d.label !== "activePoint"
        );
        // create new datasets (points) to represent active timeFrame, copy line styles on new points
        const graphPoints = graphLines.map(line => {
          const lineIndex = graphRef.current.data.datasets.findIndex(
            d => d.label === line.label
          );
          const activePointData = [];

          // get the active point value of each line based on timeFrame
          activePointData[timeFrame] =
            graphRef.current.data.datasets[lineIndex].data[timeFrame];
          return {
            ...line,
            data: activePointData,
            pointRadius: 7,
            label: "activePoint",
            showTooltip: false
          };
        });

        // update graphRef datasets
        graphRef.current.data.datasets = [...graphLines, ...graphPoints];
        graphRef.current.update();
      } catch (error) {
        console.log(error); // eslint-disable-line
        Swal.fire({
          icon: "error",
          text: "Something went wrong"
        });
        clearInterval(playInterval);
        setPaused(true);
      }
    }
  }, [graphDataset, timeFrame]);

  // sets the view on the map to the current location of the marker.
  const markerLocationHandler = () => {
    setCenter(!center);
  };

  const handlePlayButton = (speedIndex = 1) => {
    setSpeed(speedIndex);
    setPaused(false);

    // Clear interval and set to null, before setting new interval speed
    clearInterval(playInterval);
    playInterval = null;

    // if(timeFrame >= logs.length - 1){
    //   setTimeFrame(0);
    // }

    if (!playInterval && timeFrame < logs.length - 1) {
      playInterval = setInterval(() => {
        setTimeFrame(t => t + 1);
      }, speedOptions[speedIndex] * 1000);
    }
  };

  useEffect(() => {
    if (paused && !textLocations[timeFrame]?.textualLoc) {
      processTextualLocation(timeFrame);
    }
  }, [paused]);
  // Y axis label currently replace by 'speed' instead of the this data.
  // const formatYAxis = tickItem => {
  //   return `${Math.round(tickItem)} ${legend === "speed" ? "kph" : "C"}`;
  // };
  // data for the graph.
  const chartData = React.useMemo(() => {
    return {
      labels: logs.map(({ time }) => moment(time).format("HH:mm")),
      datasets: graphDataset
    };
  }, [logs, graphDataset]);

  // styling the graph data's.
  const options = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false
      },
      plugins: {
        legend: {
          // position: "hidden",
          display: false
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy"
          },
          zoom: {
            mode: "x"
          }
        },
        tooltip: {
          callbacks: {
            title(value) {
              const { dataIndex } = value[0];
              return logs[dataIndex].time;
            },
            label(value) {
              if (value.dataset.showTooltip) {
                return `${value.dataset.label}: ${value.raw}`;
              }
            }
          }
        }
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 7,
          display: true,
          borderColor: "#1b6ca8"
        }
      },
      scales: {
        y: {
          ticks: {
            callback(value) {
              // return formatYAxis(value);
              return Math.round(value);
            },
            display: true
          }
        },
        x: {
          ticks: {
            color: "#ffffff"
          }
        }
      },
      animation: false
    };
  }, [logs]);

  // prints the element of the chosen point.
  const printElementAtEvent = (element: InteractionItem[]) => {
    if (_.isEmpty(element)) return;
    // fetch the vehicles index
    const vehiclePointIndex = element[0].index;
    // get the time on of the vehicle.
    // const selectedTime = logs[vehiclePointIndex].time.slice(11, 19);
    // handleTimeSelect(logs[vehiclePointIndex].time);
    // handleDateChange(logs[vehiclePointIndex].time);
    setTimeFrame(vehiclePointIndex);
  };

  // get the current element when points has been clicked on the graph.
  const getCurrentData = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = graphRef;
    if (!chart) return;
    printElementAtEvent(getElementAtEvent(chart, event));
  };

  const { permissions } = useAllowedPermissions("/admin/trip/geofences/");
  // menu graph selection.
  const isSelected = index => !_.isEqual(selectedLegend.indexOf(index), -1);
  // legend selector.
  const legendHandler = (event, index) => {
    let newSelectedTemp = [];
    const selectedIndex = selectedLegend.indexOf(index);

    if (_.isEqual(selectedIndex, -1)) {
      if (_.isEqual(index, 0)) {
        newSelectedTemp = newSelectedTemp.concat(selectedLegend, index);
      } else {
        // validates temperature and fuel cannot be selected at the same time.
        if (!selectedLegend.includes(_.isEqual(index, 1) ? 2 : 1)) {
          newSelectedTemp = newSelectedTemp.concat(selectedLegend, index);
        } else newSelectedTemp = selectedLegend;
      }
    } else if (_.isEqual(selectedIndex, 0)) {
      newSelectedTemp = newSelectedTemp.concat(selectedLegend.slice(1));
    } else if (_.isEqual(selectedIndex, selectedLegend.length - 1)) {
      newSelectedTemp = newSelectedTemp.concat(selectedLegend.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedTemp = newSelectedTemp.concat(
        selectedLegend.slice(0, selectedIndex),
        selectedLegend.slice(selectedIndex + 1)
      );
    }

    // check if the remaining legend is only 1 else it will set to the last selected legend.
    // selected legend should not be blank.
    if (_.isEqual(selectedLegend[0], index) && selectedLegend.length <= 1) {
      newSelectedTemp.push(index);
    }

    // set the legend temperature or fuel disabled checkbox.
    if (newSelectedTemp.length > 1)
      setLegendDisabled(newSelectedTemp.includes(1) ? 2 : 1);
    else if (!_.isEqual(index, 0) && !newSelectedTemp.includes(index))
      setLegendDisabled(null);

    setSelectedLegend(newSelectedTemp);
  };

  // update legend to be shown on the graph.
  const updateLegend = (data, index) => {
    if (selectedLegend.includes(index)) {
      tempDatasets.push(data);
    }
    setGraphDataset(tempDatasets);
  };

  // shows all legends available
  useEffect(() => {
    const legendOptions = defaultGraphDatasets.map((data, index) => {
      const itemSelected = isSelected(index);
      let colorCheck = "#47C01C";
      // set color for checkbox
      if (_.isEqual(data.label.toUpperCase(), "TEMPERATURE (C)"))
        colorCheck = "#3CA4F7";
      else if (_.isEqual(data.label.toUpperCase(), "FUEL (L)"))
        colorCheck = "#BB6BD9";
      else colorCheck = "#47C01C";
      // custom checkbox
      const checkBoxStyles = () => ({
        root: {
          "&$checked": {
            color: colorCheck
          }
        },
        checked: {}
      });

      const CustomCheckBox = withStyles(checkBoxStyles)(Checkbox);
      // updates the data shown on the graph
      updateLegend(data, index);
      const isLoading = _.isEqual(data.label, "Fuel (L)") && fuelReadingLoading;
      return (
        <div key={index}>
          {(!has_temp_sensor && _.isEqual(data.label, "Temperature (C)")) ||
          (!has_fuel_sensor && _.isEqual(data.label, "Fuel (L)")) ? (
            " "
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomCheckBox
                    onClick={event => legendHandler(event, index)}
                    checked={itemSelected}
                    size="small"
                    disabled={!!_.isEqual(legendDisabled, index) || isLoading}
                  />
                }
                className={classes.graphMenuOption}
                label={
                  <Typography className={classes.graphMenuLabel}>
                    {data.label}
                    {isLoading && <CircularProgress size={10} />}
                  </Typography>
                }
              />
            </FormGroup>
          )}
        </div>
      );
    });

    setLegends(legendOptions);
  }, [selectedLegend, fuelReadingLoading, logs]);

  return (
    <Grid style={{ padding: "0px 10px" }}>
      <Grid item xs={12}>
        <div className={classes.graph}>
          <div
            style={{
              display: "flex",
              margin: "8px 0",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <div>
              <IconButton
                aria-label="more"
                aria-controls={menuOpen ? "access-menu" : null}
                aria-haspopup="true"
                aria-expanded={menuOpen ? true : null}
                onClick={menuOpenHandler}
                size="small"
              >
                <MoreIcon fontSize="small" />
              </IconButton>
              <Menu
                id="access-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={menuCloseHandler}
              >
                {legends}
              </Menu>
            </div>
            {logs[0] && permissions?.edit && (
              <div className={classes.graphCreateBtn}>
                <Link
                  to={{
                    pathname: `/admin/trip/geofences/add`,
                    state: {
                      ...location.state,
                      source: `/monitoring/:view/history${window.location.search}`,
                      location: logs[timeFrame].location
                    }
                  }}
                  style={{ textDecoration: "none" }}
                  className={classes.create_geofence_btn}
                >
                  <Typography style={{ fontSize: "12px" }}>
                    Create geofence
                  </Typography>
                </Link>
              </div>
            )}
          </div>
          <div
            className={classes.graphChartContainer}
            style={{ height: "25vh" }}
          >
            <Line
              ref={graph => {
                graphRef.current = graph;
              }}
              data={chartData}
              options={options}
              height={100}
              onClick={getCurrentData}
            />
          </div>
          <div className={classes.graphSlider}>
            <Slider
              disabled={!(logs.length > 0)}
              // style={{ width: "100%", padding: "10px 5px" }}
              value={timeFrame}
              onChange={(e, value) => setTimeFrame(value)}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              min={0}
              max={logs.length > 0 ? logs.length - 1 : 0}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Box
          className={classes.box}
          sx={{
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            marginBottom: "12px",
            marginLeft: "20px",
            paddingRight: "20px"
          }}
        >
          <div className={classes.timePicker}>
            <MUIThemeProvider theme={themeStyles}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  disabled={!(logs.length > 0)}
                  ampm={false}
                  variant="dialog"
                  value={selectedDate}
                  format="HH:mm:ss"
                  onChange={(e, value) => {
                    handleTimeSelect(value);
                  }}
                  InputAdornmentProps={{
                    style: { display: "none" }
                  }}
                />
              </MuiPickersUtilsProvider>
            </MUIThemeProvider>
          </div>
          <Select
            value={speed}
            onChange={event => handlePlayButton(event.target.value)}
            renderValue={value => (
              <span>Play Speed &nbsp;&nbsp;&nbsp;{value}x</span>
            )}
            classes={{
              root: classes.select
            }}
            MenuProps={{
              classes: {
                paper: classes.menuItemPaper
              }
            }}
          >
            <MenuItem value={1} dense>
              <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <CheckRounded
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: 12, opacity: Number(speed === 1) }}
                />
                1x
              </span>
            </MenuItem>
            <MenuItem value={2}>
              <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <CheckRounded
                  fontSize="small"
                  color="primary"
                  style={{ fontSize: 12, opacity: Number(speed === 2) }}
                />
                2x
              </span>
            </MenuItem>
          </Select>
          <Tooltip title="Back" placement="bottom">
            <>
              <IconButton
                size="small"
                disabled={!timeFrame || !logs[0]}
                onClick={() => {
                  setTimeFrame(timeFrame - 1);
                  clearInterval(playInterval);
                  setPaused(true);
                }}
              >
                <SkipPrevious />
              </IconButton>
            </>
          </Tooltip>
          <IconButton
            size="small"
            onClick={() => setPaused(!paused)}
            disabled={!logs[0]}
          >
            {paused ? (
              <Tooltip title="Play" placement="bottom">
                <PlayArrowRounded
                  onClick={() => {
                    if (timeFrame >= logs.length - 1) {
                      setTimeFrame(0);
                    }
                    handlePlayButton();
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Pause" placement="bottom">
                <PauseRounded
                  onClick={() => {
                    clearInterval(playInterval);
                    setPaused(true);
                  }}
                />
              </Tooltip>
            )}
          </IconButton>
          <Tooltip title="Next" placement="bottom">
            <>
              <IconButton
                size="small"
                disabled={logs.length - 1 === timeFrame || !logs[0]}
                onClick={() => {
                  setTimeFrame(timeFrame + 1);
                  clearInterval(playInterval);
                  setPaused(true);
                }}
              >
                <SkipNext />
              </IconButton>
            </>
          </Tooltip>
          <IconButton
            size="small"
            onClick={() => {
              setTimeFrame(0);
              handlePlayButton(speed);
              clearInterval(playInterval);
              setPaused(true);
            }}
            disabled={!logs[0]}
          >
            <Tooltip title="Rewind" placement="bottom">
              <ReplayIcon />
            </Tooltip>
          </IconButton>

          <div style={{ marginLeft: "auto" }}>
            <IconButton
              size="small"
              onClick={() => {
                graphRef.current.resetZoom();
              }}
              disabled={!logs[0]}
            >
              <Tooltip title="Restart" placement="bottom">
                <Autorenew />
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                graphRef.current.zoom(1.1);
              }}
              disabled={!logs[0]}
            >
              <Tooltip title="Zoom In" placement="bottom">
                <ZoomIn />
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                graphRef.current.zoom(0.9);
              }}
              disabled={!logs[0]}
            >
              <Tooltip title="Zoom Out" placement="bottom">
                <ZoomOut />
              </Tooltip>
            </IconButton>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}

export default function MapView(props) {
  const {
    isMapView,
    vehicleData,
    geofences,
    itinerary,
    date,
    setDate,
    user,
    recenter,
    deviceId,
    location,
    logs,
    handleChangeView,
    renderVehicleSection = () => {},
    renderDeviceSection = () => {},
    primaryData,
    isCICO,
    trip_logs
  } = props;
  const [zoom, setZoom] = useState(false);
  const [mapLocation, setMapLocation] = useState(logs[0]);
  const [paused, setPaused] = useState(true);
  const [vehicleDirectionCounter, setVehicleDirectionCounter] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const { has_fuel_sensor } = primaryData || {};
  const [timeFrame, setTimeFrame] = useState(0);
  const [textLocations, setTextLocations] = useState([...logs]);
  const classes = useStyles();
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedInfoVehicle, setSelectedInfoVehicle] = useState(null);
  useEffect(() => {
    if (logs.length) {
      setMapLocation(logs[0]);
    }
  }, [logs]);

  const fullScreenHandler = () => setIsFullScreen(!isFullScreen);

  const queryParams = new URLSearchParams(window.location.search);
  const historyDetails = JSON.parse(queryParams.get("details"));

  const [
    getFuelReadingData,
    { data: fuelReadingData, loading: fuelReadingLoading }
  ] = useLazyQuery(GET_FUEL_READING, {
    variables: {
      device_id: deviceId,
      vehicle_id: historyDetails?.vehicle?.id,
      plateno: historyDetails?.vehicle?.plateno,
      startstamp: date?.start,
      endstamp: date?.end
    }
  });

  useEffect(() => {
    if (has_fuel_sensor) {
      getFuelReadingData();
    }
  }, []);

  const handleDate = direction => {
    let tempDate = date.base;
    if (direction === "up") {
      if (tempDate < moment().format("YYYY-MM-DD")) {
        tempDate = moment(tempDate)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
    } else {
      tempDate = moment(tempDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }

    setDate({
      base: tempDate,
      start: `${tempDate} 00:00:00`,
      end: `${tempDate} 23:59:59`
    });
  };

  const dateHandler = d => {
    setDate({
      base: moment(d).format("YYYY-MM-DD"),
      start: moment(d)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment(d)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
    });
  };

  const isPrimary = +primaryData?.client_id === +user.client.id;

  const fuelReading = fuelReadingData?.get_fuel_reading?.results || [];
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={3} style={{ padding: 18, borderBottom: "#eee" }}>
        <Typography variant="h6">View History</Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid container item md={12} justifyContent="space-between">
          <div style={{ display: "flex", alignItems: "end" }}>
            <Grid container item direction="row" alignItems="center">
              <Typography variant="caption" className={classes.headerDate}>
                {_.isEqual(
                  moment(date.start).format("MMM DD YYYY"),
                  moment(date.end).format("MMM DD YYYY")
                )
                  ? moment(date?.start).format("MMMM DD, YYYY")
                  : `${moment(date?.start).format("MMMM DD, YYYY")} - ${moment(
                      date?.end
                    ).format("MMMM DD, YYYY")}`}
              </Typography>
              <CalendarTodayIcon
                className={classes.headerCalendar}
                onClick={() => setDateOpen(true)}
              />
              {isPrimary && (
                <>
                  <KeyboardArrowLeftIcon onClick={() => handleDate("down")} />
                  <KeyboardArrowRightIcon onClick={() => handleDate("up")} />
                </>
              )}
            </Grid>
            <div style={{ display: "none" }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  onChange={dateHandler}
                  open={dateOpen}
                  onClose={() => {
                    setDateOpen(false);
                  }}
                  orientation="landscape"
                  disableFuture
                  value={date.start}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <Button
            startIcon={<ListIcon />}
            style={{ backgroundColor: theme.palette.primary.main }}
            variant="contained"
            size="small"
            className={classes.headerToggleButton}
            onClick={handleChangeView}
          >
            <Typography className={classes.toggleButtonLabel} variant="caption">
              {isMapView ? "List View" : "Map View"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        // md={isFullScreen ? 2 : 7}
        xs={12}
        style={{
          transition: themeStyles.transitions.create("all", {
            easing: themeStyles.transitions.easing.easeOut,
            duration: themeStyles.transitions.duration.leavingScreen
          })
        }}
      >
        <MapComponent
          vehicleDirectionCounter={vehicleDirectionCounter}
          vehicleData={vehicleData?.get_vehicle}
          trip_logs={logs}
          recenter={recenter}
          heading={
            vehicleData?.get_vehicle.device_info
              ? vehicleData?.get_vehicle?.device_info?.device_status?.heading
              : 0
          }
          geofences={geofences}
          zoomProps={{ zoom: zoom, setZoom: setZoom }}
          destinations={itinerary}
          date={date}
          user={user}
          mapLocation={mapLocation}
          paused={paused}
          center={center}
          setCenter={setCenter}
          primaryData={primaryData}
          isCICO={isCICO}
          setSelectedInfoVehicle={setSelectedInfoVehicle}
          selectedInfoVehicle={selectedInfoVehicle}
        >
          <div className={classes.graphInfo}>
            <div>
              {vehicleData ? renderVehicleSection() : renderDeviceSection()}
            </div>
            <Divider variant="middle" />
            <div>
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography component="div" className={classes.graphText}>
                    At Time Selected:
                  </Typography>
                  <Typography
                    component="div"
                    style={{ fontSize: 12, margin: "0px 5px" }}
                  >
                    (
                    {selectedInfoVehicle
                      ? selectedInfoVehicle?.time
                      : logs.length > 0
                      ? logs[timeFrame].time
                      : "-"}
                    )
                  </Typography>
                  <GpsFixed
                    className={classes.gpsFixed}
                    // onClick={markerLocationHandler}
                    onClick={() => setCenter(!center)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {!logs.length && (
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "red"
                    }}
                  >
                    No assigned trip during this time
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} style={{ margin: "8px 0" }}>
                <Typography className={classes.graphText}>Speed:</Typography>
                <Typography className={classes.graphValue}>
                  {selectedInfoVehicle
                    ? selectedInfoVehicle?.Speed
                    : logs.length > 0
                    ? logs[timeFrame].Speed
                    : "-"}
                </Typography>
              </Grid>
              {vehicleData?.get_vehicle?.has_temp_sensor && (
                <Grid item xs={12} style={{ margin: "8px 0" }}>
                  <Typography className={classes.graphText}>
                    Temperature:
                  </Typography>
                  <Typography className={classes.graphValue}>
                    {selectedInfoVehicle
                      ? selectedInfoVehicle?.Temperature
                      : logs.length > 0
                      ? logs[timeFrame].Temperature
                      : "-"}
                  </Typography>
                </Grid>
              )}
              {has_fuel_sensor && (
                <Grid item xs={12} style={{ margin: "8px 0" }}>
                  <Typography className={classes.graphText}>Fuel:</Typography>
                  {fuelReadingLoading ? (
                    <CircularProgress size={12} />
                  ) : (
                    <Typography className={classes.graphValue}>
                      {selectedInfoVehicle
                        ? selectedInfoVehicle?.fuel
                        : fuelReading.length > 0
                        ? fuelReading[timeFrame]?.reading
                        : "-"}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12} style={{ margin: "8px 0" }}>
                <Typography className={classes.graphText}>Location:</Typography>
                <SeeMore>
                  {selectedInfoVehicle
                    ? selectedInfoVehicle?.textualLoc?.length
                      ? selectedInfoVehicle.textualLoc
                      : "-"
                    : textLocations.length &&
                      textLocations[timeFrame].textualLoc
                    ? textLocations[timeFrame].textualLoc
                    : "-"}
                </SeeMore>
              </Grid>
              <Grid item xs={12} style={{ margin: "8px 0" }}>
                <Typography className={classes.graphText}>
                  Lat, Long:
                </Typography>
                <Typography className={classes.graphValue}>
                  {selectedInfoVehicle
                    ? `${selectedInfoVehicle?.location.lat}, ${selectedInfoVehicle?.location.lon}`
                    : logs.length > 0
                    ? `${logs[timeFrame].location.lat}, ${logs[timeFrame].location.lon}`
                    : "-"}
                </Typography>
              </Grid>
            </div>
          </div>
        </MapComponent>
      </Grid>
      <Grid
        item
        // md={isFullScreen ? 10 : 5}
        xs={12}
        style={{
          transition: themeStyles.transitions.create("all", {
            easing: themeStyles.transitions.easing.easeOut,
            duration: themeStyles.transitions.duration.standard
          })
        }}
      >
        <LineChart
          isMapView={isMapView}
          date={date}
          user={user}
          location={location}
          logs={logs}
          mapLocationProps={{ mapLocation, setMapLocation }}
          pausedProps={{ paused, setPaused }}
          setVehicleDirectionCounter={setVehicleDirectionCounter}
          handleChangeView={handleChangeView}
          fullScreenHandler={fullScreenHandler}
          isFullScreen={isFullScreen}
          vehicleData={vehicleData}
          primaryData={primaryData}
          fuelReading={fuelReadingData?.get_fuel_reading?.results || []}
          fuelReadingLoading={fuelReadingLoading}
          setCenter={setCenter}
          center={center}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          textLocations={textLocations}
          setTextLocations={setTextLocations}
        />
      </Grid>
    </Grid>
  );
}
