/* global google */
/* eslint-disable array-callback-return */
import { useQuery } from "@apollo/client";
import { Marker } from "@react-google-maps/api";
import React, { useState, useEffect, useCallback } from "react";
import { GET_VEHICLES_CONTROL_ROOM } from "../../../../../graphql/Queries";
import Clusterer from "../../../../../utils/Map/Clusterer/Clusterer";
// import { vehicleSvgIcons } from "../../../../Utils";
import { mapRef } from "../../../../../utils/Map/Inner";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../../../assets/SvgIcons/SvgIcons";
import client from "../../../../../Client";

import useIsMarkerClustered from "../../../../../utils/hooks/useIsMarkerClustered";

const Vehicle = ({
  clusterer,
  clusterInstance,
  position,
  plateno,
  Icon,
  heading,
  powersource,
  ignition_status,
  isLBSVehicle,
  vehicle,
  setSelectedVehicle
}) => {
  const showLabel = useIsMarkerClustered(clusterInstance, plateno);

  return (
    <React.Fragment>
      <Marker
        onClick={() => setSelectedVehicle(vehicle)}
        options={{
          zIndex: 2147483647,
          label: {
            text: plateno,
            fontSize: "0px"
          }
          // title: plateno,
        }}
        // key={id}
        icon={{
          // path: vehicleSvgIcons[icon.split(".")[0]].path,
          // fillColor: vehicleSvgIcons[icon.split(".")[0]].color,
          path: Icon.path,
          fillColor: isLBSVehicle? Icon.fill: fillColor[powersource], // Icon.fill,
          strokeColor: isLBSVehicle? Icon.stroke: strokeColor[ignition_status], // Icon.stroke,
          strokeWeight: isLBSVehicle? 1.0: 1.5,
          fillOpacity: 1,
          rotation: isLBSVehicle? 0 :heading,
          scale: 1.2,
          size: new google.maps.Size(22, 17),
          origin: new google.maps.Point(0, 0),
          // anchor: new google.maps.Point(5, 9)
          anchor: new google.maps.Point(9, 13)
        }}
        position={position}
        // onClick={onMarkerClick}
        clusterer={clusterer}
      />
      {showLabel && (
        <Marker
          onClick={() => setSelectedVehicle(vehicle)}
          options={{
            zIndex: 2147483647,
            // title: plateno,
            label: {
              // color: "#3A3A3A",
              fontWeight: "bold",
              text: plateno,
              fontSize: "12px",
              className: "map-label"
            }
          }}
          // key={id}
          icon={{
            // path: vehicleSvgIcons[icon.split(".")[0]].path,
            // fillColor: vehicleSvgIcons[icon.split(".")[0]].color,
            path: Icon.path,
            fillColor: fillColor[powersource], // Icon.fill,
            fillOpacity: 0,
            // rotation: heading,
            strokeWeight: 0,
            strokeColor: strokeColor[ignition_status], // Icon.stroke,
            scale: 1.2,
            size: new google.maps.Size(22, 17),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 9),
            // labelOrigin: new google.maps.Point(8, 48)
            labelOrigin: new google.maps.Point(8, 45)
          }}
          position={position}
        />
      )}
    </React.Fragment>
  );
};

const Vehicles = ({
  clustererOptions = {},
  groupIds = [],
  setVehicles,
  bounds,
  setSelectedVehicle
}) => {
  const [clusterInstance, setClusterInstance] = useState(null);
  const [fetchedVehicles, setFetchedVehicles] = useState([]);
  const { subscribeToMore, data, loading } = useQuery(
    GET_VEHICLES_CONTROL_ROOM,
    {
      variables: {
        first: 10000,
        group_ids: groupIds
      },
      notifyOnNetworkStatusChange: true,
      pollInterval: 60 * 1000
    }
  );

  
  useEffect(() => {
    if(setVehicles){
      setVehicles(vehiclesInBounds(fetchedVehicles?.get_vehicles?.vehicles))
    }
  }, [bounds]);

  const subscribeToNewVehicles = useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: GET_VEHICLES_CONTROL_ROOM,
      variables: { first: 10000, group_ids: groupIds },
      updateQuery: (prev, { subscriptionData }) => {
        if(!subscriptionData?.data) return;
        setFetchedVehicles(subscriptionData.data);
        if(setVehicles){
          setVehicles(
            vehiclesInBounds(subscriptionData?.data.get_vehicles?.vehicles)
          )
        }
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  const vehiclesInBounds = data => {
    if (!data) return [];
    return data.filter(vehicle => {
      if (vehicle?.device_info?.device_status) {
        const { latitude, longitude } = vehicle.device_info.device_status;
        if (latitude && longitude) {
          const vehicle_as_marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(latitude, longitude),
            title: vehicle.client_id
          });
          const map_current_bounds = mapRef.current.getBounds();
          const is_in_bounds = map_current_bounds?.contains(
            vehicle_as_marker.getPosition()
          );

          if (is_in_bounds) {
            return true;
          }
        }
      }
    });
  };

  useEffect(() => {
    const unsubscribe = subscribeToNewVehicles();
    return () => unsubscribe();
  }, [subscribeToNewVehicles]);

  useEffect(() => {
    if (!loading){
       setFetchedVehicles(data)
       if(setVehicles){
        setVehicles(
          vehiclesInBounds(data?.get_vehicles?.vehicles)
        )
      }
    };
  }, [data]);

  if (loading) return null;

  return (
    <Clusterer
      {...clustererOptions}
      onClusteringEnd={clusterInstance => {
        setClusterInstance({ ...clusterInstance });
      }}
    >
      {clusterer => {
        return fetchedVehicles?.get_vehicles?.vehicles.map(vehicle => {
          // const { plateno } = vehicle;
          if (vehicle.device_info?.device_status) {
            const {
              latitude,
              longitude,
              // AlertReferences,
              // gpsSpeed
            } = vehicle.device_info.device_status; // eslint-disable-line no-unused-vars
            // const vehicle_as_marker = new window.google.maps.Marker({
            //   position: new window.google.maps.LatLng(latitude, longitude),
            //   title: vehicle.client_id
            // });
            // const map_current_bounds = mapRef.current.getBounds();
            // const is_in_bounds = map_current_bounds?.contains(
            //   vehicle_as_marker.getPosition()
            // );
            if (latitude && longitude /* && is_in_bounds */) {
              const position = { lat: latitude, lng: longitude };
              const {
                // id,
                icon,
                ignition_status,
                device_info: {
                  device_status: { heading, powersource, AlertReferences } // eslint-disable-line no-unused-vars
                },
                plateno
              } = vehicle;

              const isLBSVehicle = AlertReferences?.some(alert => alert.alert_code === "LBS DATA");

              const Icon =
                SvgIcons[isLBSVehicle? "LBS_vehicle":
                  icon.split(".")[0] ? icon.split(".")[0] : "default_icon"
                ];

              return (
                <Vehicle
                  clusterer={clusterer}
                  clusterInstance={clusterInstance}
                  position={position}
                  plateno={plateno}
                  Icon={Icon}
                  heading={heading}
                  powersource={powersource}
                  ignition_status={ignition_status}
                  isLBSVehicle={isLBSVehicle}
                  setSelectedVehicle={setSelectedVehicle}
                  vehicle={vehicle}
                />
              );
            }
          }
        });
      }}
    </Clusterer>
  );
};

export default Vehicles;
