/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import {
  FormControl,
  Chip,
  TextField,
  Typography,
  Checkbox,
  makeStyles
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { GET_STATUS_CODES } from "../../../graphql/Queries";

import useFormStyles from "../../../styles/useFormStyles";

const localStyles = makeStyles({
  filterInput: {
    // marginTop: "20px",
    minWidth: "100%",
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em"
    },
    "& ::placeholder": {
      /* Firefox */
      color: "#000 !important",
      opacity: 1
    },
    "& :-ms-input-placeholder": {
      /* Internet Explorer 10-11 */
      color: "#000 !important",
      opacity: 1
    },
    "& ::-ms-input-placeholder": {
      /* Microsoft Edge */
      color: "#000 !important",
      opacity: 1
    }
  },
  iconStyle: { width: 16, height: 16 }
});

const TripStatusChipInput = React.forwardRef((props, ref) => {
  const { initialValues, readOnly, updateFieldData } = props;
  const [selectedTripStatus, setSelectedTripStatus] = useState([]);
  const { data, loading } = useQuery(GET_STATUS_CODES, {
    variables: {
      filter: {
        field: "name",
        value: [
          "Incoming",
          "Completed",
          "Arrived at Pickup",
          "In transit",
          "Arrived at Drop Off",
          "Completed",
          "Closed",
          "Cancelled"
        ]
      }
    },
    onCompleted: response => {
      ref.current = response.get_status_codes;
      if (updateFieldData) {
        updateFieldData({
          field: "trip_status",
          data: response.get_status_codes
        });
      }
    }
  });

  useEffect(() => {
    if (data && initialValues) {
      if (
        data.get_status_codes.length !== initialValues.length &&
        Number(initialValues[0]) !== 0 &&
        initialValues.length > 0
      ) {
        const initVal = data.get_status_codes.filter(val =>
          initialValues.includes(val.id)
        );
        setSelectedTripStatus(initVal);
        ref.current = initVal;
      }
    }
  // }, [data]);
  }, [data, initialValues, ref]);

  const formStyles = useFormStyles();
  const classes = localStyles();

  const handleSelectedChange = (_, newSelectedTripStatus) => {
    ref.current =
      newSelectedTripStatus.length > 0
        ? newSelectedTripStatus
        : data.get_status_codes;
    setSelectedTripStatus(newSelectedTripStatus);
  };

  return (
    <FormControl classes={{ root: formStyles.formRoot }}>
      <Autocomplete
        loading={loading}
        multiple
        id="multiple-limit-tags"
        limitTags={2}
        disableClearable
        options={data?.get_status_codes || []}
        disableCloseOnSelect
        disabled={readOnly}
        getOptionLabel={option => option.name?.toString() || ""}
        onChange={handleSelectedChange}
        value={selectedTripStatus}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              size="small"
              color="primary"
              // variant="outlined"
              label={option.name}
              // onDelete={() => onRemove([], option)}
            />
          ))
        }
        renderOption={(option, { selected: selected_status }) => (
          <React.Fragment>
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon className={classes.iconStyle} />}
              checkedIcon={
                <CheckBoxIcon fontSize="small" className={classes.iconStyle} />
              }
              checked={selected_status}
            />
            <Typography variant="body2">{option.name}</Typography>
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            // variant="outlined"
            classes={{ root: formStyles.textFieldRoot }}
            className={classes.filterInput}
            label="Trip Status"
            placeholder={selectedTripStatus?.length > 0 ? "" : "All"}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: {
                padding: "6px 0px"
              },
              ...params.InputProps,
              disableUnderline: readOnly,
              disabled: readOnly
            }}
          />
        )}
      />
    </FormControl>
  );
});

export default TripStatusChipInput;
