import React from "react";
import HeaderSidenav from "./HeaderSidenav";
import TripDetails from "./index";

function Shareable(props) {
  const { location, history } = props;
  return (
    <HeaderSidenav>
      <div style={{ marginTop: 64 }}>
        <TripDetails location={location} history={history} />
      </div>
    </HeaderSidenav>
  );
}

export default Shareable;
