/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Tooltip,
  InputAdornment,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  DialogActions,
  Paper
} from "@material-ui/core";
import _ from "lodash";
import Cookie from "js-cookie";
import {
  Search,
  Delete,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Help as HelpIcon
} from "@material-ui/icons";
import Swal from "sweetalert2";
import { useQuery } from "@apollo/client";
import { GET_PARTNERS } from "../../../../../graphql/Queries";

const useStyles = makeStyles({
  iconButton: {
    padding: 0
  },
  paper: {
    display: "flex",
    backgroundColor: "#eaeaea",
    paddingLeft: 16,
    position: "relative",
    height: "40px",
    alignItems: "center"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
});

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};
const descendingComparator = (a, b) => {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};
const getComparator = order => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
};

const GroupsTable = props => {
  const classes = useStyles();
  const { vehicle, isEdit, setVehicle } = props;
  const [orderBy, setOrderBy] = useState("group_names");
  const [order, setOrder] = useState("asc");
  const [open, setOpen] = useState(false);
  const [gCurrentPage, setGCurrentPage] = useState(0);
  const [gTotalPage, setGTotalPage] = useState(0);
  const user = JSON.parse(Cookie.get("user"));
  const [keyword, setKeyword] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [defaultGroup] = useState({
    id:
      vehicle.group_ids[
        _.findIndex(vehicle.group_names, gn => {
          return gn === user.client.name;
        })
      ],
    name: user.client.name
  });

  const { data: groups_data, fetchMore } = useQuery(GET_PARTNERS, {
    variables: {
      skip: 0,
      keyword: keyword,
      not: [
        {
          field: "id",
          value: vehicle.group_ids.map(gId => {
            return gId.toString();
          })
        }
      ]
    }
  });

  const handleAddGroups = () => {
    const group_ids = [...vehicle.group_ids];
    const group_names = [...vehicle.group_names];

    _.forEach(selectedGroups, sg => {
      group_ids.push(Number(sg.id));
      group_names.push(sg.name);
    });

    setVehicle({
      get_vehicle: {
        ...vehicle,
        group_names: group_names,
        group_ids: group_ids
      }
    });
    setOpen(false);
  };

  // const hand

  const handleCheckGroup = (id, name, e) => {
    let temp = [...groups];

    if (e.target.checked) {
      temp.push({ id: id, name: name });
    } else {
      temp = _.filter(temp, t => {
        return t.id !== id;
      });
    }
    setGroups(temp);
  };

  const handleDeleteGroup = (id, name) => {
    const group_names = _.filter(vehicleGroups, vg => vg.id !== id).map(vg => {
      return vg.name;
    });
    const group_ids = _.filter(vehicleGroups, vg => vg.name !== name).map(
      vg => {
        return vg.id;
      }
    );
    return { group_ids, group_names };
  };

  const handleCheck = (id, name, e) => {
    let temp = [...selectedGroups];
    if (e.target.checked) {
      temp.push({
        id: id,
        name: name
      });
    } else {
      temp = _.filter(temp, sg => {
        return sg.id !== id;
      });
    }
    setSelectedGroups(temp);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    if (groups_data) {
      const { get_groups } = groups_data;
      setGTotalPage(get_groups.total ? Math.ceil(get_groups.total / 5) : 1);
    }
  }, [groups_data]);

  useEffect(() => {
    if (vehicle) {
      const { group_ids, group_names } = vehicle;
      const g = group_names.map((name, index) => {
        return {
          id: group_ids[index],
          name
        };
      });
      // setVehicleGroups(_.filter(g, e => e.name !== user.client.name));
      setVehicleGroups(g);
    }
  }, [vehicle]);

  const handlePaginator = pageUp => {
    if (pageUp) {
      // console.log("up");
      setGCurrentPage(gCurrentPage + 1);
      const totalLoaded = (gCurrentPage + 1) * 5;
      if (
        groups_data.get_groups.total > groups_data.get_groups.count &&
        totalLoaded <= groups_data.get_groups.count
      ) {
        fetchMore({
          variables: {
            keyword: keyword,
            skip: groups_data.get_groups.count
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              get_groups: {
                ...prev.get_groups,
                total: fetchMoreResult.get_groups.total,
                count: fetchMoreResult.get_groups.count + prev.get_groups.count,
                groups: prev.get_groups.groups.concat(
                  fetchMoreResult.get_groups.groups
                )
              }
            };
          }
        });
      }
    } else {
      setGCurrentPage(gCurrentPage - 1);
    }
  };

  const handleCheckAllGroup = e => {
    if (e.target.checked) {
      const group_ids = _.filter(vehicle.group_ids, gid => {
        return gid !== defaultGroup.id;
      });
      const group_names = _.filter(vehicle.group_names, gn => {
        return gn !== defaultGroup.name;
      });

      const temp = group_ids.map((gid, index) => {
        return {
          id: gid,
          name: group_names[index]
        };
      });

      setGroups(temp);
    } else {
      setGroups([]);
    }
  };

  const handleSelectAllNG = e => {
    const { get_groups } = groups_data;
    if (e.target.checked) {
      const temp = get_groups.groups.map(g => {
        return {
          id: g.id,
          name: g.name
        };
      });
      setSelectedGroups(temp);
      return;
    }
    setSelectedGroups([]);
  };

  return (
    <Fragment>
      <Dialog open={open} PaperProps={{ style: { width: 600, height: 405 } }}>
        <DialogTitle>Add Entry</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                placeholder="search clients"
                value={keyword}
                fullWidth
                variant="outlined"
                onChange={e => {
                  setKeyword(e.target.value);
                  setGCurrentPage(0);
                }}
                InputProps={{
                  style: { borderRadius: 32 },
                  inputProps: {
                    style: { padding: 4, paddingRight: 8, paddingLeft: 8 }
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                onClick={() => handlePaginator(false)}
                disabled={gCurrentPage + 1 === 1}
                className={classes.iconButton}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <TextField
                inputProps={{
                  style: { padding: 0, textAlign: "center" },
                  readOnly: true
                }}
                style={{ width: "32px", marginRight: "8px", marginTop: 4 }}
                variant="outlined"
                value={gCurrentPage + 1}
              />
              {`/${gTotalPage}`}
              <IconButton
                onClick={() => handlePaginator(true)}
                disabled={gCurrentPage + 1 === gTotalPage}
                className={classes.iconButton}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      onChange={handleSelectAllNG}
                      checked={
                        groups_data &&
                        groups_data.get_groups.count === selectedGroups.length
                      }
                    />
                  </TableCell>
                  <TableCell>Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups_data &&
                  groups_data.get_groups.groups
                    ?.slice(gCurrentPage * 5, gCurrentPage * 5 + 5)
                    ?.map(group => (
                      <TableRow key={group.name}>
                        <TableCell key={`${group.name}-checkBox`}>
                          <Checkbox
                            color="primary"
                            onChange={e => handleCheck(group.id, group.name, e)}
                            checked={
                              _.findIndex(selectedGroups, sg => {
                                return sg.id === group.id;
                              }) !== -1
                            }
                          />
                        </TableCell>
                        <TableCell key={`${group.name}-name`}>{group.name}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setSelectedGroups([]);
              setKeyword("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleAddGroups();
              setSelectedGroups([]);
              setKeyword("");
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        <Tooltip 
          classes={{ tooltip: classes.toolTip }}
          placement="top-start"
          title="Data of primary client will be link to all selected partners (and vice versa)"
        >
          {/* <Error style={{ fontSize: 12 }} /> */}
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
        {"     "}<span style={{flex: "1"}}>Partners</span>
        <TextField
          style={{ float: "right" }}
          variant="outlined"
          placeholder="Search Clients"
          InputProps={{
            style: { borderRadius: 32 },
            inputProps: {
              style: { padding: 4, paddingRight: 8, paddingLeft: 8 }
            },
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </Typography>
      {groups.length > 0 && (
        <Paper className={classes.paper}>
          <Typography>{`${groups.length} item selected`}</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{
              position: "absolute",
              right: 0,
              borderRadius: 36,
              color: "white"
            }}
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No"
              }).then(result => {
                if (result.value) {
                  const newGroups = _.differenceBy(vehicleGroups, groups, "id");
                  const group_names = newGroups.map(ng => ng.name);
                  const group_ids = newGroups.map(ng => ng.id);
                  setGroups([]);
                  setVehicle({
                    get_vehicle: {
                      ...vehicle,
                      group_ids: group_ids,
                      group_names: group_names
                    }
                  });
                }
              });
            }}
          >
            Delete Selected
          </Button>
        </Paper>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {isEdit && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={
                      groups.length > 0 &&
                      groups.length === vehicle.group_ids.length - 1
                    }
                    onChange={handleCheckAllGroup}
                  />
                </TableCell>
              )}
              <TableCell sortDirection={order}>
                <TableSortLabel
                  active
                  direction={order}
                  onClick={createSortHandler("group_names")}
                >
                  Clients
                </TableSortLabel>
              </TableCell>
              {isEdit && (
                <TableCell>
                  <Button color="primary" onClick={() => setOpen(true)}>
                    Add Entry
                  </Button>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleGroups.length > 0 ? (
              stableSort(
                _.filter(vehicleGroups, vg => vg.name !== user.client.name),
                getComparator(order)
              ).map(g => (
                <TableRow key={`${g.name}-tRow`}>
                  {isEdit && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        key={g.name}
                        color="primary"
                        checked={
                          _.findIndex(groups, gr => g.id === gr.id) !== -1
                        }
                        onChange={e => handleCheckGroup(g.id, g.name, e)}
                      />
                    </TableCell>
                  )}
                  <TableCell key={`${g.name}-name`} >{g.name}</TableCell>
                  {isEdit && (
                    <TableCell key={`${g.name}-action`}>
                      <IconButton
                        onClick={() =>
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No"
                          }).then(result => {
                            if (result.value) {
                              const {
                                group_ids,
                                group_names
                              } = handleDeleteGroup(g.id, g.name);
                              setVehicle({
                                get_vehicle: {
                                  ...vehicle,
                                  group_ids: group_ids,
                                  group_names: group_names
                                }
                              });
                            }
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={20} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default GroupsTable;
