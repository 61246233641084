import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiAccordionSummary-root": {
      width: 150,
      padding: 0
    },
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "auto"
    },
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  },
  stepperRoot: {
    "& .MuiStepContent-root": {
      borderLeft: "0px dashed #bdbdbd !important",
      position: "relative"
    },
    "& .MuiStepConnector-line.MuiStepConnector-lineVertical": {
      borderLeft: "2px dashed #bdbdbd !important",
      display: "none"
    },
    "& .MuiStepConnector-vertical": {
      display: "none"
    },
    "& .MuiStepLabel-labelContainer": {
      paddingTop: 15
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      paddingLeft: "15px"
    },
    "& .MuiCollapse-entered": {
      paddingLeft: "15px"
    }
  },
  stepperContent: {},
  alertHeading: {
    color: "#e40000",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  },
  alertTime: {
    color: "#646464",
    fontSize: 10,
    fontWeight: theme.typography.fontWeightLight
  },
  alertDetails: {
    color: "#646464",
    fontSize: 11,
    fontWeight: theme.typography.fontWeightLight
  },
  line: {
    position: "absolute",
    top: 0,
    left: "-10px",
    height: "100%",
    width: "20px",
    padding: "5px",
    cursor: "pointer",
    "&:hover div": {
      borderColor: "#606060 !important"
    }
  },
  innerLine: {
    position: "absolute",
    top: 0,
    left: 9,
    height: "100%",
    borderLeft: "2px solid #bdbdbd"
  },
  solidBorder: {
    borderLeftStyle: "solid"
  },
  dashedBorder: {
    borderLeftStyle: "dashed"
  },
  tripDetailsContainer: {
    padding: "10px",
    height: "100%",
    "& .MuiTypography-subtitle2": {
      fontSize: "0.75rem",
      letterSpacing: 1.2
    }
  },
  tripDetailsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  tripDetailsStatus: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tripDetailsBody: {
    padding: "10px"
  },
  emptyData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
    gap: 10
  }
}));

export const loadmoreStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "underline",
    color: "#aaa",
    background: "#eeeeee42",
    width: "100%"
  }
}));