/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* global google */
import React, { useState, useEffect, useRef, Fragment, createRef } from "react";
import { useLazyQuery } from "@apollo/client";
// NEW MAP
import {
  Marker,
  Polygon,
  Autocomplete,
  DrawingManager
} from "@react-google-maps/api";
// import Autocomplete from "react-google-autocomplete";
import {
  Paper,
  IconButton,
  SvgIcon,
  makeStyles,
  Grid,
  Slider,
  OutlinedInput,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  Button,
  Tooltip,
  TextField,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import Swal from "sweetalert2";
import {
  DoubleArrow as DoubleArrowIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import { SketchPicker } from "react-color";
import { withStyles } from "@material-ui/core/styles";
import wkt, { parse, stringify } from "wkt";
import _ from "lodash";
import { GET_INTERSECT_UNIVERSAL_GEOFENCE } from "../../../../graphql/Queries";
import BasicModal from "../../../../utils/modal/BasicModal";
import client from "../../../../Client";

import Map from "../../../../utils/Map/index";
import {
  control_positions,
  mapRef as mapViewRef
} from "../../../../utils/Map/Inner";
import { getGeofenceTypeColor } from "../../../Utils";
import {
  wktToCoordinates as wktCoordinates
} from "../../../../utils/functions/coordinatesParserV2"

const modalRef = createRef();
const geofenceRef = createRef();
const drawRef = createRef();

const useStyles = makeStyles(theme => ({
  svgIcon: {
    width: "43px",
    height: "40px"
  },
  button: {
    width: "40px",
    height: "40px",
    padding: 0,
    margin: theme.spacing(1)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  input: {
    fontSize: "14px",
    padding: "4px 8px"
  },
  tool_drawer: {
    // position: "absolute",
    // right: "-20%"
  },
  radiusInput: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: 8,
      fontSize: 12
    }
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    margin: "12px 0px 0px 0px"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#FF7043",
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#FF7043"
  },
  checked: {}
}))(Switch);

function ColorPicker(props) {
  const {
    anchorEl,
    open,
    handleClosePopper,
    colour,
    setColour,
    drawingMode,
    handleColor
  } = props;

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <SketchPicker
                color={colour[drawingMode]}
                onChangeComplete={col =>
                  setColour({ ...colour, [drawingMode]: col.hex })
                }
              />
            </ClickAwayListener>
            <div style={{ width: "100%", padding: 8 }}>
              <Button
                style={{ width: "100%", color: "white" }}
                onClick={() => handleColor()}
                variant="contained"
                color="primary"
              >
                SET COLOR
              </Button>
            </div>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

function SearchBox(props) {
  const {
    setLocationProps,
    setCenter,
    state,
    selectDrawing,
    wkbGeometry
  } = props;
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState(state?.address);

  // const onPlacesChange = () => {
  //   const places = searchBoxRef.current.getPlaces();
  //   const bounds = new google.maps.LatLngBounds();
  //   places.forEach(place => {
  //     if (place.geometry.viewport) {
  //       bounds.union(place.geometry.viewport);
  //     } else {
  //       bounds.extend(place.geometry.location);
  //     }
  //   });
  //   const nextMarkers = places.map(place => ({
  //     position: place.geometry.location
  //   }));
  //   // console.log(nextMarkers);
  //   const nextCenter = lodash.get(nextMarkers, "0.position", center);
  //   console.log(nextCenter);
  //   setLocationProps({ lat: nextCenter.lat(), lon: nextCenter.lng() });
  //   setCenter(nextCenter);
  // };

  // useEffect(() => {
  //   Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
  //   if (location.lat && location.lon) {
  //     Geocode.fromLatLng(+location.lat, +location.lon).then(response => {
  //       const address = response.results[0].formatted_address;
  //       setAddress(address);
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    setAddress(state.address);
  }, [state.address]);

  const handleChange = e => {
    setAddress(e.target.value);
  };

  const getInfo = place => {
    if (place.geometry) {
      if (!(selectDrawing?.id || wkbGeometry?.coordinates?.[0]?.[0].length)) {
        setLocationProps({
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
        });
      }
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    }
  };

  return (
    <>
      <Autocomplete
        onLoad={autocomplete => {
          setAutocomplete(autocomplete);
        }}
        onPlaceChanged={() => {
          // setState({...state, ['address']: address});
          getInfo(autocomplete.getPlace());
          setAddress(autocomplete.getPlace().formatted_address);
        }}
        fields={["formatted_address", "geometry"]}
        componentRestrictions={{ country: "ph" }}
        restrictions={{ country: "ph" }}
      >
        <input
          type="text"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`
          }}
          placeholder="Search Address"
          value={address}
          name="address"
          onChange={e => handleChange(e)}
        />
      </Autocomplete>
      {/* <StandaloneSearchBox
        bounds={
          new google.maps.LatLngBounds(
            new google.maps.LatLng(4.701938083625501, 118.4986595985061),
            new google.maps.LatLng(19.108444751833066, 128.2105736610061)
          )
        }
        ref={searchBoxRef}
        onPlacesChanged={onPlacesChange}
      >
        <input
          type="text"
          value={address}
          placeholder="Search Address"
          onChange={e => handleChange(e)}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`
          }}
        />
      </StandaloneSearchBox> */}
    </>
  );
}

function CustomDrawing(props) {
  const classes = useStyles();
  const {
    type,
    radiusProps,
    opacityRef,
    setOpacityProps,
    opacityProps,
    // color,
    // setColor,
    wkbGeometry,
    setRadiusProps,
    drawingMode,
    setDrawingMode,
    mapViewRef,
    setType,
    setCenter,
    geoDrawing,
    selectDrawing
    // setSelectDrawing,
  } = props;
  const [opacity, setOpacity] = useState({ ...opacityProps });
  const [radius, setRadius] = useState(0);
  const [area, setArea] = useState();
  // const [popper, setPopper] = useState(false);
  // const [colour, setColour] = useState({ ...color });
  // const [anchorEl, setAnchorEl] = useState(false);

  // const handleToggle = () => {
  //   setPopper(prevPopper => !prevPopper);
  // };

  // const handleClosePopper = event => {
  //   if (anchorEl.current && anchorEl.current.contains(event.target)) {
  //     return;
  //   }

  //   setPopper(false);
  // };

  // const handleColor = () => {
  //   setColor(colour);
  //   if (drawRef.current) {
  //     drawRef.current.overlay.setOptions({ fillColor: colour[drawingMode] });
  //   }
  // };

  useEffect(() => {
    if (
      wkbGeometry.type !== "" &&
      (type === "rectangle" || type === "polygon")
    ) {
      const tempBounds = { ...wkbGeometry.coordinates };
      const tempArr = [];
      tempBounds[0].map(bound =>
        tempArr.push(new google.maps.LatLng(bound[1], bound[0]))
      );
      const sqm = google.maps.geometry.spherical.computeArea(tempArr);
      setArea(sqm);
    }
  }, [wkbGeometry]);

  useEffect(() => {
    if (selectDrawing?.geom) {
      const tempBounds = parse(selectDrawing?.geom);
      if (tempBounds.type === "MultiPolygon") {
        const tempArr = [];
        tempBounds.coordinates[0][0].map(bound =>
          tempArr.push(new google.maps.LatLng(bound[0], bound[1]))
        );
        const sqm = google.maps.geometry.spherical.computeArea(tempArr);
        setArea(sqm);
      }
    }
  }, [selectDrawing]);

  useEffect(() => {
    if (radiusProps) {
      setRadius(radiusProps);
    }
  }, []);

  return (
    <>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Navigate">
            <IconButton
              className={classes.button}
              onClick={() => {
                setDrawingMode(null);
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === null ? "#FF845E" : "#EAEAEA"}
                  fillOpacity={drawingMode === null ? "1" : "0.45"}
                />
                <path
                  d="M23.6867 7.17917L5.38336 17.5346C4.52692 18.0191 4.81408 19.32 5.79545 19.4014L15.1224 20.1755C15.4623 20.2037 15.7647 20.4032 15.9245 20.7047L20.6935 29.7013C21.1518 30.566 22.4504 30.3279 22.5696 29.3573L25.1728 8.17285C25.273 7.35758 24.4014 6.77481 23.6867 7.17917Z"
                  fill={drawingMode === null ? "#FFFFFF" : "#FF845E"}
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Circle">
            <IconButton
              className={classes.button}
              onClick={() => {
                const mapRef = mapViewRef.current.getCenter().toJSON();
                const { lat, lng } = mapRef;
                setCenter({ lat, lng });
                setRadiusProps(50);
                setType("circle");
                // setSelectDrawing(null);

                // parse
                const tempCoords = [];
                const numSides = 48;
                const degreeStep = 360 / numSides;

                for (let i = 0; i < numSides; i += 1) {
                  const gpos = google.maps.geometry.spherical.computeOffset(
                    new google.maps.LatLng(lat, lng),
                    50,
                    degreeStep * i
                  );
                  tempCoords.push([gpos.lng(), gpos.lat()]);
                }

                // Duplicate the last point to close the geojson ring
                tempCoords.push(tempCoords[0]);

                // setWkbGeometryProps({
                //   coordinates: [tempCoords],
                //   type: "polygon"
                // });
                setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
                opacityRef.current =
                  opacity[google.maps.drawing.OverlayType.CIRCLE];
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "circle" ? "#FFB74D" : "#D1D1D1"}
                  fillOpacity={drawingMode === "circle" ? "1" : "0.45"}
                />
                <path
                  d="M27.6841 17.501C27.6841 22.5899 23.5095 26.751 18.3157 26.751C13.1219 26.751 8.94727 22.5899 8.94727 17.501C8.94727 12.412 13.1219 8.25098 18.3157 8.25098C23.5095 8.25098 27.6841 12.412 27.6841 17.501Z"
                  stroke={drawingMode !== "circle" ? "#FFB74D" : "#D1D1D1"}
                  strokeWidth="4"
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Rectangle">
            <IconButton
              className={classes.button}
              onClick={() => {
                setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
                opacityRef.current =
                  opacity[google.maps.drawing.OverlayType.RECTANGLE];
                // setSelectDrawing(null);
                // setWkbGeometryProps({
                //   type: "",
                //   coordinates: [[[]]]
                // });
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "rectangle" ? "#BB6BD9" : "#D1D1D1"}
                  fillOpacity={drawingMode === "rectangle" ? "1" : "0.45"}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 8C8.44772 8 8 8.44771 8 9V26C8 26.5523 8.44772 27 9 27H27C27.5523 27 28 26.5523 28 26V9C28 8.44772 27.5523 8 27 8H9ZM12.5312 11.3533C11.979 11.3533 11.5312 11.801 11.5312 12.3533V22.6474C11.5312 23.1997 11.979 23.6474 12.5313 23.6474H23.4724C24.0247 23.6474 24.4724 23.1997 24.4724 22.6474V12.3533C24.4724 11.801 24.0247 11.3533 23.4724 11.3533H12.5312Z"
                  fill={drawingMode !== "rectangle" ? "#BB6BD9" : "#D1D1D1"}
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Tooltip title="Polyline">
            <IconButton
              className={classes.button}
              onClick={() => {
                setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
                opacityRef.current =
                  opacity[google.maps.drawing.OverlayType.POLYGON];
                // setSelectDrawing(null);
                // setWkbGeometryProps({
                //   type: "",
                //   coordinates: [[[]]]
                // });
              }}
            >
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={drawingMode === "polygon" ? "#63F160" : "#D1D1D1"}
                  fillOpacity={drawingMode === "polygon" ? "1" : "0.45"}
                />
                <path
                  d="M11 11.5184C15.3077 7.34537 21.0513 12.6568 18.1795 16.8297C15.3076 21.0027 18.1795 27.4521 25 24.0377"
                  // stroke="#63F160"
                  stroke={drawingMode !== "polygon" ? "#63F160" : "#D1D1D1"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <IconButton
            className={classes.button}
            disabled={!geoDrawing.length}
            onClick={e => {
              if (wkbGeometry.type !== "" && selectDrawing) {
                modalRef.current.openModal();
              }
              opacityRef.current = opacity.select;
              setDrawingMode("select");
              setType("select");
            }}
          >
            <Tooltip title="Select">
              <SvgIcon className={classes.svgIcon} viewBox="0 0 35 35">
                <ellipse
                  cx="18"
                  cy="17.5"
                  rx="18"
                  ry="17.5"
                  fill={
                    drawingMode === "select"
                      ? "#3CA4F7"
                      : geoDrawing.length === 0
                      ? "#F5F5F5"
                      : "#D1D1D1"
                  }
                  fillOpacity={drawingMode === "select" ? "1" : "0.45"}
                />
                <path
                  d="M9 9.5L9.36858 7.53426C8.67869 7.4049 7.97148 7.64667 7.50516 8.17129C7.03885 8.69591 6.88168 9.42659 7.09104 10.0965L9 9.5ZM14 25.5L12.091 26.0965C12.3467 26.9147 13.0954 27.479 13.9524 27.4994C14.8093 27.5198 15.584 26.9919 15.8784 26.1868L14 25.5ZM17.3228 16.4118L16.4148 14.6298C15.9646 14.8591 15.6179 15.2504 15.4444 15.725L17.3228 16.4118ZM25 12.5L25.908 14.282C26.6707 13.8934 27.1016 13.0624 26.9796 12.2151C26.8576 11.3678 26.21 10.692 25.3686 10.5343L25 12.5ZM13.8227 10.9998C12.9942 10.2693 11.7303 10.3488 10.9998 11.1773C10.2693 12.0058 10.3488 13.2697 11.1773 14.0002L13.8227 10.9998ZM26.3896 27.413C27.2182 28.1435 28.482 28.064 29.2125 27.2355C29.943 26.407 29.8635 25.1432 29.035 24.4126L26.3896 27.413ZM14.5 18L12.6085 18.6497C12.847 19.3442 13.4469 19.8521 14.1713 19.9728C14.8956 20.0935 15.6279 19.8075 16.0787 19.2279L14.5 18ZM18 13.5L19.5787 14.7279C20.0125 14.1701 20.1198 13.4248 19.861 12.7673C19.6021 12.1098 19.0155 11.6377 18.3178 11.5254L18 13.5ZM7.09104 10.0965L12.091 26.0965L15.909 24.9035L10.909 8.90345L7.09104 10.0965ZM15.8784 26.1868L19.2012 17.0985L15.4444 15.725L12.1216 24.8132L15.8784 26.1868ZM18.2308 18.1938L25.908 14.282L24.092 10.718L16.4148 14.6298L18.2308 18.1938ZM25.3686 10.5343L9.36858 7.53426L8.63142 11.4657L24.6314 14.4657L25.3686 10.5343ZM11.1773 14.0002L11.3365 14.1405L13.9819 11.1402L13.8227 10.9998L11.1773 14.0002ZM11.3365 14.1405L26.3896 27.413L29.035 24.4126L13.9819 11.1402L11.3365 14.1405ZM10.7676 13.29L12.6085 18.6497L16.3915 17.3503L14.5507 11.9907L10.7676 13.29ZM16.0787 19.2279L19.5787 14.7279L16.4213 12.2721L12.9213 16.7721L16.0787 19.2279ZM18.3178 11.5254L12.977 10.6658L12.3414 14.6149L17.6822 15.4746L18.3178 11.5254Z"
                  fill={
                    drawingMode === "select"
                      ? "#FFFFFF"
                      : geoDrawing.length === 0
                      ? "#9DD1FB"
                      : "#3CA4F7"
                  }
                />
              </SvgIcon>
            </Tooltip>
          </IconButton>
        </Grid>
        {drawingMode && (
          <Grid item xs={12}>
            <Grid container style={{ padding: 8 }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {drawingMode === "circle" && (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex" }}
                        // alignItems="center"
                      >
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Radius
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        {/* <Typography style={{ fontSize: 12 }}>
                          {radius && radius.toFixed(2)}
                        </Typography> */}
                        <TextField
                          value={radius}
                          size="small"
                          variant="outlined"
                          type="number"
                          className={classes.radiusInput}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: 100
                            }
                          }}
                          onChange={event => {
                            const tempValue = parseFloat(event.target.value);
                            setRadius(tempValue >= 100 ? 100 : tempValue);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Slider
                          value={radius || 0}
                          min={1}
                          max={100}
                          onChange={(_, value) => {
                            setRadius(value);
                            if (drawRef.current) {
                              drawRef.current.overlay.setRadius(value);
                            }
                          }}
                          onMouseUp={() => {
                            setRadiusProps(radius);
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {(drawingMode === "rectangle" ||
                    drawingMode === "polygon" ||
                    drawingMode === "select") && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Area
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {area && (
                          <Typography style={{ fontSize: 12 }}>{`${area.toFixed(
                            2
                          )} sqm | ${(area * 10.764).toFixed(2)} sq ft | ${(
                            area / 4047
                          ).toFixed(2)} Acres`}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  {drawingMode !== null && (
                    <>
                      {/* <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Color
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <CheckBoxOutlineBlankIcon
                          onClick={e => {
                            handleToggle();
                            setAnchorEl(e.currentTarget);
                          }}
                          style={{
                            backgroundColor: colour[drawingMode],
                            color: colour[drawingMode]
                          }}
                        />
                        <OutlinedInput
                          fullWidth
                          classes={{ input: classes.input }}
                          value={colour[drawingMode]}
                        />
                      </Grid> */}
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Typography
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          Opacity
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography style={{ fontSize: 12 }}>
                          {`${Math.round(opacity[drawingMode] * 100)}%`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Slider
                          value={opacity[drawingMode] * 100}
                          min={1}
                          max={100}
                          step={1}
                          onChange={(_, value) => {
                            opacityRef.current = value / 100;
                            setOpacity({
                              ...opacity,
                              [drawingMode]: value / 100
                            });
                            if (drawRef.current) {
                              drawRef.current.overlay.setOptions({
                                fillOpacity: value / 100
                              });
                            }
                          }}
                          onMouseUp={(_, value) => {
                            setOpacity({
                              ...opacity,
                              [drawingMode]: opacityRef.current
                            });
                            setOpacityProps({
                              ...opacity,
                              [drawingMode]: opacityRef.current
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <ColorPicker
        open={popper}
        colour={colour}
        drawingMode={drawingMode}
        setColour={setColour}
        anchorEl={anchorEl}
        handleColor={handleColor}
        handleClosePopper={handleClosePopper}
      /> */}
    </>
  );
}

function CustomOverlays(props) {
  const {
    handleGeofence,
    center,
    setCenter,
    setLocationProps,
    editMode,
    location,
    type,
    radius,
    opacityRef,
    expanded,
    setExpanded,
    submitted,
    setOpacityProps,
    opacity,
    color,
    setColor,
    wkbGeometry,
    setRadius,
    handleCenter,
    drawingMode,
    setDrawingMode,
    mapViewRef,
    setType,
    geoDrawing,
    setWkbGeometryProps,
    selectDrawing,
    setSelectDrawing,
    state,
    setState
  } = props;
  const classes = useStyles();
  const geofenceColor = getGeofenceTypeColor(state.category);
  return (
    <div style={{ width: "80%" }}>
      <div style={{ display: "flex" }}>
        <DrawingManager
          defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
          options={{
            drawingControl: false,
            circleOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity.circle,
              strokeWeight: 2
            },
            polygonOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity.polygon,
              strokeWeight: 2
            },
            rectangleOptions: {
              zIndex: 20000,
              fillColor: geofenceColor,
              fillOpacity: opacity.rectangle,
              strokeWeight: 2
            }
          }}
          drawingMode={drawingMode === "select" ? null : drawingMode}
          onOverlayComplete={e => handleGeofence(e)}
        />
        {expanded && (
          <div style={{ marginLeft: 16, marginTop: 16 }}>
            <Paper>
              <Grid container>
                <Grid item xs={12} style={{ padding: 16 }}>
                  <SearchBox
                    center={center}
                    setCenter={setCenter}
                    location={location}
                    setLocationProps={setLocationProps}
                    state={state}
                    setState={setState}
                    selectDrawing={selectDrawing}
                    wkbGeometry={wkbGeometry}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomDrawing
                    handleGeofence={handleGeofence}
                    type={type}
                    editMode={editMode}
                    location={location}
                    radiusProps={radius}
                    opacityRef={opacityRef}
                    submitted={submitted}
                    setOpacityProps={setOpacityProps}
                    opacityProps={opacity}
                    // color={color}
                    // setColor={setColor}
                    wkbGeometry={wkbGeometry}
                    setRadiusProps={setRadius}
                    drawingMode={drawingMode}
                    setDrawingMode={setDrawingMode}
                    mapViewRef={mapViewRef}
                    setType={setType}
                    setWkbGeometryProps={setWkbGeometryProps}
                    setLocationProps={setLocationProps}
                    geoDrawing={geoDrawing}
                    setCenter={setCenter}
                    selectDrawing={selectDrawing}
                    setSelectDrawing={setSelectDrawing}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
        <div className={classes.tool_drawer}>
          <IconButton
            style={{ backgroundColor: "white", margin: 16 }}
            onClick={() => {
              handleCenter();
              setExpanded(!expanded);
            }}
          >
            <DoubleArrowIcon
              style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

const UniversalGeofence = props => {
  const { universalGeo, handleToggle } = props;

  const textmsg = (
    <span>
      Universal geofence will only be visible once: <br />
      <br />
      Toggle is on <br />
      Map reached the zoom level of 17-22
    </span>
  );

  return (
    <div style={{ marginRight: 10 }}>
      <Tooltip title={textmsg} placement="left">
        <Paper style={{ height: "40px", width: "40px", textAlign: "center" }}>
          <AntSwitch
            color="primary"
            name="universal_geofence"
            checked={universalGeo}
            onChange={handleToggle}
          />
        </Paper>
      </Tooltip>
    </div>
  );
};

const getGeoPaths = () => {
  const path = [];
  const aNorth = mapViewRef?.current
    ?.getBounds()
    ?.getNorthEast()
    ?.lat();
  const aEast = mapViewRef?.current
    ?.getBounds()
    ?.getNorthEast()
    ?.lng();
  const aSouth = mapViewRef?.current
    ?.getBounds()
    ?.getSouthWest()
    ?.lat();
  const aWest = mapViewRef?.current
    ?.getBounds()
    ?.getSouthWest()
    ?.lng();

  path.push(
    [aNorth, aEast],
    [aNorth, aWest],
    [aSouth, aWest],
    [aSouth, aEast],
    [aNorth, aEast]
  );

  return path;
};

const MapComponent = props => {
  const {
    wkbGeometry,
    editMode,
    setWkbGeometryProps,
    setLocationProps,
    location,
    radius,
    setRadius,
    type,
    setType,
    opacity,
    setOpacity,
    color,
    setColor,
    submitted,
    setHasDrawn,
    zoom,
    setZoom,
    universalGeo,
    setUniversalGeo,
    selectDrawing,
    setSelectDrawing,
    state,
    setState
  } = props;

  const opacityRef = useRef();
  const [bounds, setBounds] = useState([]);
  const [center, setCenter] = useState({ lat: 14.5995, lng: 120.9842 });
  const [expanded, setExpanded] = useState(true);
  const [render, setRerender] = useState(false);
  const [drawingMode, setDrawingMode] = useState(null);
  const [geoDrawing, setGeoDrawing] = useState([]); // list of universal geo
  const [tempDrawing, setTempDrawing] = useState();

  useEffect(() => {
    opacityRef.current = opacity[type];
  }, []);

  useEffect(() => {
    if (drawRef.current) {
      drawRef.current.overlay.setOptions({ fillColor: getGeofenceTypeColor(state.category) });
    }
  }, [state.category]);

  useEffect(() => {
    if (drawingMode !== null) setHasDrawn(true);
  }, [drawingMode]);

  useEffect(() => {
    if (!editMode) {
      setExpanded(false);
      !!drawRef?.current?.overlay && drawRef.current.overlay.setMap(null);
    } else {
      setExpanded(true);
    }
  }, [editMode]);

  useEffect(() => {
    if (location.lat !== "" && location.lon !== "") {
      setCenter({
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lon)
      });
    }
  }, [location]);

  const handleGeofence = e => {
    const { type: type2, overlay } = e;
    const wkbGeomTempCoords = [];
    let centroid = "";
    let lat;
    let long;
    const bounds = [];
    const center = new google.maps.LatLngBounds();
    if (drawRef.current) {
      drawRef.current.overlay.setMap(null);
    }
    drawRef.current = e;

    if (type2 === "polygon") {
      const test = overlay.getPath().getArray();
      test.forEach(point => {
        wkbGeomTempCoords.push([point.lng(), point.lat()]);
        bounds.push(new google.maps.LatLng(point.lat(), point.lng()));
        center.extend({ lat: point.lat(), lng: point.lng() });
      });
      if (test.length < 3) {
        Swal.fire({
          title: "Polygon must have at least 3 points",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setRerender(!render);
          }
        });
        return;
      }
      wkbGeomTempCoords.push([test[0].lng(), test[0].lat()]);
      lat = center.getCenter().lat();
      long = center.getCenter().lng();
      setRadius(0);
    } else if (type2 === "rectangle") {
      const bounds = overlay.getBounds();
      const center = bounds.getCenter();
      const NE = bounds.getNorthEast();
      const SW = bounds.getSouthWest();

      wkbGeomTempCoords.push([NE.lng(), SW.lat()]);
      wkbGeomTempCoords.push([
        bounds.getNorthEast().lng(),
        bounds.getNorthEast().lat()
      ]);
      wkbGeomTempCoords.push([SW.lng(), NE.lat()]);
      wkbGeomTempCoords.push([
        bounds.getSouthWest().lng(),
        bounds.getSouthWest().lat()
      ]);
      wkbGeomTempCoords.push([NE.lng(), SW.lat()]);
      lat = center.lat();
      long = center.lng();
      setRadius(0);
    } else if (type2 === "circle") {
      lat = overlay.getCenter().lat();
      long = overlay.getCenter().lng();
      centroid = `POINT(${lat} ${long})`;
      const numSides = 48;
      const degreeStep = 360 / numSides;

      for (let i = 0; i < numSides; i += 1) {
        const gpos = google.maps.geometry.spherical.computeOffset(
          new google.maps.LatLng(lat, long),
          overlay.getRadius(),
          degreeStep * i
        );
        wkbGeomTempCoords.push([gpos.lng(), gpos.lat()]);
      }

      // Duplicate the last point to close the geojson ring
      wkbGeomTempCoords.push(wkbGeomTempCoords[0]);
      setRadius(overlay.getRadius());
    }
    // overlay.setMap(null);
    if (type === type2) {
      setOpacity({ ...opacity, [type]: opacityRef.current });
    } else {
      setOpacity({ ...opacity, [type2]: opacityRef.current });
    }

    setType(type2);
    setLocationProps({ lat: lat, lon: long });
    setWkbGeometryProps({ type: "polygon", coordinates: [wkbGeomTempCoords] });
    setSelectDrawing(null);

    // For instersecting geofence
    const wktTempCoords = wkbGeomTempCoords.map(e => [e[1], e[0]]);

    const toStringPoly = stringify({
      type: "MultiPolygon",
      coordinates: [[wktTempCoords]]
    });

    client
      .query({
        // check if the multipolygon has intersected other universal geofence.
        query: GET_INTERSECT_UNIVERSAL_GEOFENCE,
        variables: {
          centroid,
          geom_type: type2 === "circle" ? "circle" : "multipolygon",
          geom: toStringPoly,
          intersect_percent: 60
        }
      })
      .then(res => {
        if (res.data.get_intersect_universal_geofence.count) {
          geofenceRef.current.openModal();
          setTempDrawing(
            res.data.get_intersect_universal_geofence.universal_geofences[0]
          );
        }
      });
  };

  // const mapViewRef = useRef();
  const handleOnMakerDragEnd = event => {
    const { lat, lng } = event.latLng;
    setLocationProps({ lat: lat(), lon: lng() });
  };

  const handleIdle = () => {
    setZoom(mapViewRef.current.getZoom());
    setBounds(getGeoPaths(mapViewRef));
  };

  const handleCenter = () => {
    if (mapViewRef.current) {
      setCenter(mapViewRef.current.getCenter().toJSON());
    }
  };

  const handleToggle = e => {
    setUniversalGeo(e.target.checked);
  };

  useEffect(() => {
    if (zoom >= 17 && zoom <= 22 && universalGeo && bounds.length) {
      getUniversalGeo({
        variables: {
          geom_type: "viewport",
          geom: stringify({
            type: "MultiPolygon",
            coordinates: [[bounds]]
          })
          // intersect_percent: 1
        }
      });
    }
  }, [bounds, universalGeo]);

  const [getUniversalGeo, { data: intersectData }] = useLazyQuery(
    GET_INTERSECT_UNIVERSAL_GEOFENCE,
    {
      variables: {}
    }
  );

  useEffect(() => {
    if (intersectData?.get_intersect_universal_geofence?.universal_geofences) {
      setGeoDrawing(
        _.uniqWith(
          intersectData?.get_intersect_universal_geofence?.universal_geofences,
          (a, b) => a.geofence_geom_id === b.geofence_geom_id
        )
      );
    }
  }, [intersectData]);

  const renderMap = () => (
    <Fragment>
      <BasicModal ref={modalRef}>
        <div>
          <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Select Geofence</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to select geofence? Your drawn shape will
                be remove?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  borderRadius: 100,
                  width: 150,
                  borderColor: "#f44336",
                  color: "#f44336"
                }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setTempDrawing();
                  modalRef.current.closeModal();
                }}
              >
                No
              </Button>
              <Button
                style={{ borderRadius: 100, color: "white", width: 150 }}
                variant="contained"
                onClick={() => {
                  setWkbGeometryProps({ type: "", coordinates: [[[]]] });
                  drawRef?.current?.overlay &&
                    drawRef.current.overlay.setMap(null);
                  // selectDrawing ? setSelectDrawing(geoDrawing[0]) : setSelectDrawing(null);
                  // setLocationProps({
                  //   lat: geoDrawing[0].location.lat,
                  //   lon: geoDrawing[0].location.lon
                  // });
                  setLocationProps({
                    lat: tempDrawing
                      ? tempDrawing?.location.lat
                      : mapViewRef.current.getCenter().lat(),
                    lon: tempDrawing
                      ? tempDrawing?.location.lon
                      : mapViewRef.current.getCenter().lng()
                  });
                  setSelectDrawing(tempDrawing);
                  setDrawingMode("select");
                  if (!universalGeo) setUniversalGeo(true);
                  if (zoom < 17 || zoom > 20) setZoom(17);
                  modalRef.current.closeModal();
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </BasicModal>
      <BasicModal ref={geofenceRef}>
        <div>
          <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Intersecting Geofence
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your created geofence overlaps with an existing universal
                geofence. Would you like to select the universal goefence?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  borderRadius: 100,
                  width: 150,
                  borderColor: "#f44336",
                  color: "#f44336"
                }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  geofenceRef.current.closeModal();
                }}
              >
                No
              </Button>
              <Button
                style={{ borderRadius: 100, color: "white", width: 150 }}
                variant="contained"
                onClick={() => {
                  geofenceRef.current.closeModal();
                  modalRef.current.openModal();
                  // setLocationProps({
                  //   lat: selectDrawing?.location?.lat,
                  //   lon: selectDrawing?.location?.lon
                  // });
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </BasicModal>
      <Map
        center={center}
        defaultZoom={zoom}
        zoom={zoom}
        onIdle={handleIdle}
        mapContainerStyle={{ height: `100%`, width: `100%` }}
        // onLoad={maps => {
        //   // maps.setZoom(16);
        //   mapViewRef.current = maps;
        //   initialState();
        // }}
        onMapTypeIdChanged={() => {
          handleCenter();
        }}
        onZoomChanged={() => handleCenter()}
        onDragEnd={() => handleCenter()}
        options={{
          draggable: editMode,
          mapTypeControl: true,
          gestureHandling: "greedy",
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.LEFT_BOTTOM
          }
        }}
      >
        <Map.Control position={control_positions.TOP_LEFT}>
          {editMode && (
            <CustomOverlays
              // position={google.maps.ControlPosition.TOP_LEFT}
              handleGeofence={handleGeofence}
              setCenter={setCenter}
              center={center}
              setLocationProps={setLocationProps}
              location={location}
              editMode={editMode}
              type={type}
              // bounds={bounds}
              radius={radius}
              setRadius={setRadius}
              opacityRef={opacityRef}
              expanded={expanded}
              setExpanded={setExpanded}
              submitted={submitted}
              opacity={opacity}
              setOpacityProps={setOpacity}
              color={color}
              setColor={setColor}
              wkbGeometry={wkbGeometry}
              handleCenter={handleCenter}
              drawingMode={drawingMode}
              setDrawingMode={setDrawingMode}
              mapViewRef={mapViewRef}
              setType={setType}
              geoDrawing={geoDrawing}
              setWkbGeometryProps={setWkbGeometryProps}
              selectDrawing={selectDrawing}
              setSelectDrawing={setSelectDrawing}
              state={state}
              setState={setState}
            />
          )}
        </Map.Control>
        <Map.Control position={control_positions.RIGHT_BOTTOM}>
          {editMode && (
            <UniversalGeofence
              // position={google.maps.ControlPosition.RIGHT_BOTTOM}
              mapViewRef={mapViewRef}
              universalGeo={universalGeo}
              handleToggle={handleToggle}
            />
          )}
        </Map.Control>
        {universalGeo &&
          zoom >= 17 &&
          // zoom <= 20 &&
          geoDrawing.map(universal => {
            if (
              universal?.geofence_geom_id !== selectDrawing?.geofence_geom_id
            ) {
              return (
                <Polygon
                  key={universal.geofence_geom_id}
                  paths={wktCoordinates(universal.geom)}
                  onMouseDown={() => {
                    if (wkbGeometry.type === "") {
                      setLocationProps({
                        lat: universal.location.lat,
                        lon: universal.location.lon
                      });
                    }
                    // setSelectDrawing(universal);
                    if (editMode && wkbGeometry.type) {
                      modalRef.current.openModal();
                      setTempDrawing(universal);
                    }
                    if (wkbGeometry.type === "") {
                      setSelectDrawing(universal);
                    }
                  }}
                  options={{
                    fillColor: "yellow",
                    fillOpacity: 0.5,
                    strokeWeight: 2,
                    clickable: drawingMode === "select"
                    //  || drawingMode === null && selectDrawing
                  }}
                />
              );
            }
          })}
        {selectDrawing && (
          <Polygon
            paths={wktCoordinates(selectDrawing.geom)}
            options={{
              fillColor:  getGeofenceTypeColor(state.category),
              fillOpacity: opacity.select,
              strokeColor: "red",
              clickable: true,
              zIndex: 1000
            }}
            onLoad={() => {
              if (!selectDrawing.geom) return;
              const initialGeo = wktCoordinates(selectDrawing.geom);
              const bounds = new window.google.maps.LatLngBounds();

              initialGeo.forEach(path => {
                path.forEach(coor => bounds.extend(coor))
              });
              const extendPoint = new window.google.maps.LatLng(
                bounds.getNorthEast().lat(),
                bounds.getNorthEast().lng()
              );
    
              bounds.extend(extendPoint);
              mapViewRef.current.fitBounds(bounds);
            }}
          />
        )}
        <Marker
          draggable={
            editMode && !selectDrawing && !wkbGeometry.coordinates[0][0].length
          }
          onDragEnd={handleOnMakerDragEnd}
          position={{
            lat:
              location.lat !== "" && (wkbGeometry.type || selectDrawing)
                ? parseFloat(location.lat)
                : parseFloat(center.lat),
            lng:
              location.lon !== "" && (wkbGeometry.type || selectDrawing)
                ? parseFloat(location.lon)
                : parseFloat(center.lng)
          }}
        />
      </Map>
    </Fragment>
  );

  return renderMap();
};

export default MapComponent;
