import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 12,
    padding: 13
  },
  title: {
    color: "red",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "25px"
  },
  button: {
    borderRadius: "25px",
    minWidth: "25%",
    margin: "0 15px"
  }
}));
const DeleteDialog = props => {
  const { title, toggle, content, close, deleteAllResponse } = props;
  const { deleted, total, notDeleted } = deleteAllResponse;
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.container }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className={classes.title}>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" component="span">
              {content}
            </Typography>

            <br />
            <br />
            {total > 1 && (
              notDeleted?.length ?
                <strong>{notDeleted.join(", ")}</strong> :
                <>
                  <div style={{ display: "flex" }}>
                    <Typography>Deleted:</Typography>
                    <Typography style={{ color: "green" }}>{deleted}</Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography>Not Deleted:</Typography>
                    <Typography style={{ color: "red" }}>
                      {total - deleted}
                    </Typography>
                  </div>
                </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;
