/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Fab, Checkbox } from "@material-ui/core";
import { Add as AddIcon, RadioButtonUnchecked, CheckCircleRounded } from "@material-ui/icons";
import Swal from "sweetalert2";
import AccessControl from "../../../../utils/AccessControl";
import EnhancedTableToolbar from "../../../../utils/table/EnhancedTableToolbar";
import Pagination from "../../../../utils/table/Pagination";
import Card from "./Card/Card";
import useControlRoomStyles from "./ControlRoom.styles";
import { GET_CONTROL_ROOMS } from "../../../../graphql/Queries";
import Loading from "../../../../utils/Loading";
import { DELETE_CONTROL_ROOM } from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../utils/functions/AddLogs";
import SearchContext from "../../../../context/SearchContext";
import { viewRooms } from "./multipleControlRooms";

function ControlRoom() {
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rooms, setRooms] = useState([]);
  const [dialog, setDialog] = useState(false);
  const channelRef = useRef(null);
  const { data, loading, refetch } = useQuery(GET_CONTROL_ROOMS, {
    variables: {
      query: {
        skip: page * rowsPerPage,
        first: rowsPerPage
      }
    },
    fetchPolicy: "cache-and-network"
  });

  React.useEffect(() => {
    setSearchKeyword(prev => ({ 
      moduleName: process.env.REACT_APP_CONTROL_ROOM_MODULE,
      value: "",
      submit: false
                  
    }));
  }, []);

  React.useEffect(() => {
    if (searchKeyword.submit &&
        searchKeyword.moduleName === process.env.REACT_APP_CONTROL_ROOM_MODULE) {
      refetch({
        query: {
          skip: page * rowsPerPage,
          first: rowsPerPage,
          keyword: searchKeyword.value
        }
      });
    }
  }, [searchKeyword.submit, page, rowsPerPage]);

  const updateCache = (cache, response, context) => {
    if (response.data.delete_control_room.success) {
      const deleted_room_id = context.variables.id;
      const previous_data = cache.readQuery({
        query: GET_CONTROL_ROOMS,
        variables: {
          query: { skip: page * rowsPerPage, first: rowsPerPage }
        }
      });

      const new_data = previous_data?.get_control_rooms?.control_rooms?.filter(
        room => {
          return room.id !== deleted_room_id;
        }
      );

      cache.writeQuery({
        query: GET_CONTROL_ROOMS,
        variables: {
          query: { skip: page * rowsPerPage, first: rowsPerPage }
        },
        data: {
          get_control_rooms: {
            ...previous_data?.get_control_rooms,
            control_rooms: [...new_data]
          }
        }
      });
    }
  };
  const [deleteChannel] = useMutation(DELETE_CONTROL_ROOM, {
    update: updateCache
  });
  const handleOpenDialog = room => {
    channelRef.current = room;
    setDialog(true);
  };
  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleDeleteChannel = () => {
    deleteChannel({
      variables: {
        id: channelRef.current.id
      }
    }).then(response => {
      if (response.data.delete_control_room.success) {
        AddLogs(
          process.env.REACT_APP_CONTROL_ROOM_MODULE,
          "delete",
          channelRef.current.name
        );
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          if (rooms.findIndex((element) => element.id === channelRef.current.id) > -1){
            const updatedRooms = rooms.filter(element => element.id !== channelRef.current.id);
            setRooms(updatedRooms);
          }
          channelRef.current = null;
          refetch();
        });
      } else if (response.data.delete_control_room.error.length > 0) {
        Swal.fire({
          title: response.data.delete_control_room.error[0].message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          channelRef.current = null;
        });
      }
    });
  };

  //select/unselect rooms
  //add to selected rooms if not yet included and unselect if already there 
  const handleSelect = room => {
    if (rooms.findIndex((element) => element.id === room.id) === -1) {
      setRooms([...rooms, room]);
    } else {
      const updatedRooms = rooms.filter(element => element.id !== room.id);
      setRooms(updatedRooms);
    }
  };

  const isChecked = room => {
    if (rooms.findIndex((element) => element.id === room.id) === -1) {
      return false;
    } else {
      return true
    }
  };

  //disable selecting rooms if length = 4 and enable if length < 4
  //do not disable already selected rooms
  const isDisabled = room => {
    if (isChecked(room) === false && (rooms.length >= 4)) {
        return true;
    }
    if (isChecked(room) === true && (rooms.length <= 4)) {
        return false;
    }
  };

  const empty_result = data?.get_control_rooms?.count === 0;

  const styles = useControlRoomStyles();
  return (
    <AccessControl resource={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/`} process="tables">
      {({ permissions }) => (
        <div style={{ height: "calc(100% - 61px)" }}>
          {loading && <Loading />}
          <EnhancedTableToolbar moduleName="Control Room">
            <Pagination
              rowsPerPage={rowsPerPage}
              total={Math.ceil(data?.get_control_rooms?.total / rowsPerPage)}
              currentPage={page}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                setPage(0);
              }}
              onPageChange={n => setPage(n)}
              onPageUp={n => setPage(n)}
              onPageDown={n => setPage(n)}
            />
          </EnhancedTableToolbar>
          {!loading && empty_result ? (
            <div className={styles.no_item_container}>
              <div className={styles.no_item}>
                {searchKeyword.submit && searchKeyword.value !== ""
                  ? "No items matched the search"
                  : "No data found"}
              </div>
            </div>
          ) : (
            <div className={styles.body}>
              {data?.get_control_rooms?.control_rooms.map(room => {
                const { id, name, location, zoom_level } = room;
                const position = { lat: location.lat, lng: location.lon };
                return (
                  <div>
                    <div className={styles.checkbox}>
                      <Checkbox
                        checked={isChecked(room)}
                        disabled={isDisabled(room)}
                        size="small"
                        icon={<RadioButtonUnchecked style={{color: "#D3D3D3"}}/>}
                        checkedIcon={<CheckCircleRounded />}
                        color="primary"
                        onChange={() => handleSelect(room)}
                      />
                    </div>
                    <Card
                      key={id}
                      room={room}
                      rooms={rooms}
                      name={name}
                      position={position}
                      zoom_level={zoom_level}
                      onDelete={e => {
                        e.stopPropagation();
                        handleOpenDialog({ id, name });
                      }}
                      permissions={permissions}
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className={styles.footer}>
              {permissions.edit && (
                <div className={styles.selected}>
                  <span>{rooms.length}/4 control rooms selected</span>
                </div>
              )}

              {permissions.edit && (
                <Button
                  disabled={!rooms.length > 0}
                  size="large"
                  color="primary"
                  variant="contained"
                  className={styles.viewButton}
                  onClick={() => viewRooms(rooms)}
                >
                  <span>View</span>
                </Button>
              )}

              {permissions.edit && (
                <Fab size="large" color="primary" style={{marginRight: "10px"}}>
                  <Link to={`/${process.env.REACT_APP_LINK_CONTROL_ROOM}/add`}>
                    <AddIcon className={styles.addIcon} />
                  </Link>
                </Fab>
              )}
          </div>  

          <ConfirmationDialog
            toggle={dialog}
            close={() => handleCloseDialog()}
            fn={e => handleDeleteChannel(e)}
            title="Delete?"
            content="Are you sure you want to delete this channel?"
          />
        </div>
      )}
    </AccessControl>
  );
}

export default withRouter(ControlRoom);
