/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import { useHistory, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  withStyles,
  Paper,
  List,
  MenuItem,
  Typography,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon, ExpandLess } from "@material-ui/icons";
import _ from "lodash";
import data from "./modules.data";
import Focus, { Indicator } from "../../common/Focus";
import { useOnboardingContext } from "../..";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  test: {
    width: "250px",
    backgroundColor: "#6e6e6e",
    height: "100%"
  },
  list: {
    padding: 0,
    width: "100%",
    minHeight: 40,
    maxHeight: 194,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      // "-webkit-border-radius": "8px",
      background: "rgba(150, 150, 150, 0.4)",
      border: "2px solid white"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      border: "1px solid white",
      background: "rgba(150, 150, 150, 1)"
    }
  },
  listItem: {
    padding: 0
  },
  details: {
    padding: 0
  },
  heading: {
    fontWeight: "bold",
    fontSize: "small",
    background: "transparent !important"
  },
  selected: {
    backgroundColor: `${theme.palette.primary.light} !important`
  },
  selectedSide: {
    background: `${theme.palette.primary.main} !important`
  },
  expanded: {
    "&$expanded": {
      margin: "0 auto"
    }
  },
  loading: {
    width: 300,
    height: 300
  }
}));

const ExpansionPanel = withStyles({
  root: {
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0 auto"
    },
    boxShadow: "none",
    MozBoxShadow: "none",
    WebkitBoxShadow: "none"
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: "0 10px 0 10px",
    backgroundColor: "#6e6e6e !important",
    // marginBlock: 0,
    minHeight: 66, // "auto",
    height: 66, // "auto",
    "&$expanded": {
      minHeight: 66 // "auto"
    }
  },
  expanded: {},
  content: {
    alignItems: "center"
    // marginBlock: 0,
    // "&.Mui-expanded": {
    //   marginBlock: 0
    // }
  },
  expandIcon: {
    // padding: 0
  }
})(MuiExpansionPanelSummary);

const moduleInfo = {
  1: {
    title: "Resources Settings",
    description: "Manage organization's registered resources in the platform"
  },
  2: {
    title: "Trip Settings",
    description: "Manage and organize vehicle-related resources and geofences"
  },
  3: {
    title: "Activity Settings",
    description:
      "Manage who can access to your platform's modules, gather user feedback and closely monitor your created location channels"
  },
  4: {
    title: "WTI Settings",
    description:
      "Manage your clients, devices and alerts, and monitor user activities"
  }
};

export default function Sidebar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { sidebarVal, handleChanges, expanded, setExpanded } = props;
  const {
    progress: { admin: currentStep }
  } = useOnboardingContext();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getName = name => {
    let temp = name.split(" - ")[name.split(" - ").length - 1].toUpperCase();
    temp = temp.concat(temp.split(" ").length === 1 ? " SETTINGS" : "");
    return temp;
  };

  return (
    <div className={classes.test}>
      <Paper className={classes.test} square>
        {data
          .filter(
            modules =>
              modules.parent_id === "0" &&
              modules.resource.split("/")[1] === "admin" &&
              modules.permission.view
          )
          .map((modules, moduleIndex) => {
            const normalizedIndex = moduleIndex + 1;
            const { title, description } = moduleInfo[normalizedIndex];
            return (
              <ExpansionPanel
                key={modules.id}
                square
                expanded={expanded === getName(modules.name)}
                onChange={handleChange(getName(modules.name))}
              >
                <Focus
                  step={normalizedIndex}
                  showInfoBubble={false}
                  component={ExpansionPanelSummary}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    className={classes.heading}
                    style={{ color: "white", flex: 1 }}
                  >
                    {getName(modules.name)}
                  </Typography>
                  <Indicator
                    show={currentStep === normalizedIndex}
                    xOffset={-15}
                    yOffset={-20}
                  >
                    {expanded === getName(modules.name) ? (
                      <ExpandLess style={{ color: "white" }} />
                    ) : (
                      <ExpandMoreIcon style={{ color: "white" }} />
                    )}
                  </Indicator>
                </Focus>
                <Focus
                  step={normalizedIndex}
                  component={MuiExpansionPanelDetails}
                  className={classes.details}
                  infoBubbleProps={{
                    title,
                    description,
                    onNext: context => {
                      // setExpanded(prev => {
                      //   return prev === getName(modules.name)
                      //     ? false
                      //     : getName(modules.name);
                      // });
                      context.nextStep(context.module);
                    }
                  }}
                >
                  <List className={classes.list}>
                    {data
                      .filter(
                        submodule =>
                          submodule.parent_id === modules.id &&
                          submodule.permission.view
                      )
                      .map(submodule => (
                        <MenuItem
                          className={classes.listItem}
                          button
                          key={submodule.id}
                          selected={submodule.name === sidebarVal}
                          classes={{ selected: classes.selected }}
                          onClick={() => {
                            handleChanges(submodule.name, submodule.resource);
                            if (_.isEqual(submodule.name, "Geofences")) {
                              history.push({
                                state: {
                                  hasUploaded: false,
                                  fileInfo: null,
                                  params: { moduleName: submodule.name }
                                }
                              });
                            }
                            // history.push({pathname: submodule.resource});
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              className={
                                submodule.name === sidebarVal ?
                                classes.selectedSide : undefined
                              }
                              style={{
                                width: "15px",
                                backgroundColor:
                                  submodule.name !== sidebarVal ? "#cccccc" : ""
                              }}
                            />
                            <div style={{ padding: "12px" }}>
                              {submodule.name}
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                  </List>
                </Focus>
              </ExpansionPanel>
            );
          })}
      </Paper>
    </div>
  );
}
