/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Typography,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
  Chip,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  HelpRounded,
  Attachment,
  Help as HelpIcon
} from "@material-ui/icons";
import Cookie from "js-cookie";
import _ from "lodash";

// Custom components
import ChipInput from "material-ui-chip-input";
import Header from "../../common/Header";
import ReportTypeInput from "../../common/ReportTypeInput";
import ErrorMessage from "../../common/ErrorMessage";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import VehicleChipInput from "../../common/VechicleChipInput";
import AlertTypeChipInput from "../../common/AlertTypeChipInput";
import TripStatusChipInput from "../../common/TripStatusChipInput";
import ActivityOnlyInput from "../../common/ActivityOnlyInput";
import VehicleSearchInput from "../../common/VehicleSearchInput";
import IdlingDuration, {
  idlingDurationParser
} from "../../common/IdlingDuration";

// Utilities
import { days, times, dates } from "../../utils/rawData";
import validate from "../../../../utils/validation";
import alertFire from "../../utils/alertFire";
import AddLogs from "../../../../utils/functions/AddLogs";

// Mutations
import { ADD_SCHEDULED_REPORT } from "../../../../graphql/Mutations";
import { GET_ACL_MODULES_CLIENT } from "../../../../graphql/Queries";

// Styles
import addScheduledReportStyle from "../styles/useFormStyles";

// Hooks
import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";

// Custom components
import HaulersChipInput from "../../common/HaulerChipInput/HaulerChipInput";
import VehicleStatus, {
  vehicleStatusOptions
} from "../../common/VehicleStatus";
import GPSStatusChipInput, {
  valuesToSting,
  defaultValue as gpsStatusDefault
} from "../../common/GPSStatusChipInput";
import ReportDelaySearchInput, {
  reportDelayOptions
} from "../../common/ReportDelaySearchInput";
import Forbidden from "../../../../utils/Errors/403/Forbidden";
import ReorderList, { parseHeader } from "../../common/ReorderList";
import useUserContext from "../../../../context/User/useUserContext";
import GeofenceChipInput from "../../common/GeofenceChipInput";
import TimeBracket, { defaultTimeBracket } from "../../common/TimeBracket";
import FieldGroup from "../../common/Fields/FieldGroup";
import BatteryLevelInput from "../../common/BatteryLevel";
import SwitchHelper from "../../../../utils/UI/Switch/SwitchHelper";

const AddScheduledReport = props => {
  const GPS_STATUS = process.env.REACT_APP_REPORT_TYPE_GPS_STATUS;
  const TRIP_SUMMARY = process.env.REACT_APP_REPORT_TYPE_TRIP_SUMMARY;
  const VEHICLE_ALERT = process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT;
  const FULL_MOVEMENT = process.env.REACT_APP_REPORT_TYPE_FULL_MOVEMENT;
  const ALERT_COUNT_DASHBOARD =
    process.env.REACT_APP_REPORT_TYPE_ALERT_COUNT_DASHBOARD;
  const VEHICLE_STATUS = process.env.REACT_APP_REPORT_TYPE_VEHICLE_STATUS;
  const STANDARD_IDLING = process.env.REACT_APP_REPORT_TYPE_STANDARD_IDLING;
  const VEHICLE_UTILIZATION =
    process.env.REACT_APP_REPORT_TYPE_VEHICLE_UTILIZATION;

  const CICO = process.env.REACT_APP_REPORT_TYPE_CICO;
  const CICO_NO_TRIP = process.env.REACT_APP_REPORT_TYPE_CICO_NO_TRIP;
  const IGNITION = process.env.REACT_APP_REPORT_TYPE_IGNITION;
  const COMPLETE_LOG = process.env.REACT_APP_REPORT_TYPE_COMPLETE_LOG;
  const SPEEDING = process.env.REACT_APP_REPORT_TYPE_SPEEDING;
  const GEOFENCE_IN_AND_OUT =
    process.env.REACT_APP_REPORT_TYPE_GEOFENCE_IN_AND_OUT;
  const FUEL_CONSUMPTION = process.env.REACT_APP_REPORT_TYPE_FUEL_CONSUMPTION;
  const DLTB_IDLING = process.env.REACT_APP_REPORT_DLTB_IDLING;
  const SPEEDING_DURATION = process.env.REACT_APP_REPORT_SPEEDING_DURATION;
  const UNCATEGORIZED = process.env.REACT_APP_REPORT_UNCATEGORIZED;
  const CONVAN_INVENTORY = process.env.REACT_APP_REPORT_CONVAN_INVENTORY;
  const FUEL_REPORT = process.env.REACT_APP_REPORT_FUEL_REPORT;
  const CICO_HOMEBASE = process.env.REACT_APP_REPORT_CICO_HOMEBASE;
  const ERROR_LOGS = process.env.REACT_APP_REPORT_TYPE_ERROR_LOGS;

  // const user = JSON.parse(Cookie.get("user") || "{}");
  const user = useUserContext();
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const styles = addScheduledReportStyle();
  const { history } = props;
  const [generateInterval, setGenerateInterval] = useState("daily");
  const [every, setEvery] = useState("default");
  const [time, setTime] = useState("default");
  const [recipients, setRecipients] = useState([]);
  const [reportType, setReportType] = useState({
    id: -1,
    name: "",
    headers: [[]]
  });
  const [alertTypes, setAlertTypes] = useState([]);
  const [file, setFile] = useState(null);
  const [sendVia, setSendVia] = useState("email");
  const [tempAddData, setTempAddData] = useState({
    sftp_link: "",
    username: "",
    password: "",
    port: null
  });
  const [uploadFile, setUploadFile] = useState({});

  // Refs
  const reportTypeRef = useRef({ id: undefined, name: undefined });
  const haulerRef = useRef([]);
  const vehiclesRef = useRef([0]);
  const alertTypesRef = useRef([0]);
  const tripStatusRef = useRef([]);
  const activityOnlyRef = useRef(false);
  const vehicleStatusRef = useRef([]);
  const gpsStatusRef = useRef(gpsStatusDefault);
  const reportDelayRef = useRef(reportDelayOptions[0]);
  const idlingDurationRef = useRef([0]);
  const reorderRef = useRef([]);
  const geofencesRef = useRef([]);
  const timeBracketRef = useRef({
    from: defaultTimeBracket.from,
    to: defaultTimeBracket.to
  });
  const batteryLevelRef = useRef("All");

  const [addScheduleReport] = useMutation(ADD_SCHEDULED_REPORT);

  const [errors, setErrors] = useState({
    reportType: null,
    generateInterval: null,
    every: null,
    time: null,
    recipients: null,
    vehicle: null,
    sftp_link: null,
    username: null,
    password: null,
    sftp_key: null,
    port: null,
    timeBracket: null
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedHaulers, setSelectedHaulers] = useState(() => {
    if (+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN) return [];
    return user.group_ids;
  });
  const [isLogitics, setIsLogitics] = useState(false);

  useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: user?.client?.id },
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setIsLogitics(hasTripsPrebooking);
      }
    },
    onError: err => console.log(err),
    skip: isWTIAdmin || VEHICLE_STATUS !== reportType.id,
    // fetchPolicy: "cache-and-network"
  });

  const handleIsLogisticsChange = e => {
    setIsLogitics(e.target.checked);
  };

  const onSelectedAlertTypeChanged = newItems => {
    if ([ALERT_COUNT_DASHBOARD].includes(reportType.id)) {
      setAlertTypes([...newItems]);
    }
  };

  const handleToggleDialog = () => {
    setDialogOpen(prev => !prev);
  };

  const getFormFields = keyword => {
    const selectedTripStatus = `, Trip Status: ${tripStatusRef.current
      .map(status => status.name)
      .toString()}`;
    const selectedHauler = `, Hauler: ${haulerRef.current
      .map(hauler => hauler.group_name)
      .join(", ") || "All"}`;
    const selectedVehicle = `, Plate Number: ${
      vehiclesRef.current[0] === 0
        ? "All"
        : vehiclesRef.current.map(i => i.plateno).join(", ") || "All"
    }`;
    const geofences = `, Geofences: ${
      geofencesRef.current.length === 0
        ? "All"
        : geofencesRef.current.map(g => g.name).join(", ")
    }`;
    const selectedAlerts = `, Alert Type: ${
      alertTypesRef.current[0] === 0
        ? "All"
        : alertTypesRef.current.map(i => i.code).join(", ")
    }`;
    const activityOnly = `, Activity Only: ${activityOnlyRef.current}`;
    // Vehicle Status
    const tempVehicleStatus = vehicleStatusRef.current.map(
      ({ label }) => label
    );
    const vehicleStatusText =
      tempVehicleStatus.length === vehicleStatusOptions.length
        ? "All"
        : tempVehicleStatus.join(", ");
    const vehicleStatus = `, Vehicle Status: ${vehicleStatusText}`;
    // GPS Status
    const gpsStatus = `, GPS Status: ${valuesToSting(gpsStatusRef.current)}`;
    // Reporting Delay
    const reportDelay = `, Reporting delay: ${reportDelayRef.current}`;
    const idlingDuration = `, Idling Duration: ${idlingDurationRef.current}`;
    const batteryLevel = `, Battery level: ${batteryLevelRef.current}`;

    const timeBracket = `, Time bracket: ${timeBracketRef.current.from} to ${timeBracketRef.current.to}`;

    switch (reportTypeRef.current.id) {
      case GPS_STATUS:
        return keyword.concat(selectedHauler);
      case TRIP_SUMMARY:
        return keyword.concat(selectedHauler, selectedTripStatus, gpsStatus);
      case VEHICLE_ALERT:
        return keyword.concat(
          timeBracket,
          selectedHauler,
          selectedAlerts,
          selectedVehicle
        );
      case FULL_MOVEMENT:
        return keyword.concat(
          timeBracket,
          selectedHauler,
          selectedVehicle,
          activityOnly
        );
      case ALERT_COUNT_DASHBOARD:
        return keyword.concat(
          timeBracket,
          selectedAlerts,
          selectedHauler,
          selectedVehicle
        );
      case VEHICLE_STATUS:
        return keyword.concat(
          selectedHauler,
          selectedVehicle,
          vehicleStatus,
          gpsStatus,
          reportDelay
        );
      case STANDARD_IDLING:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle, idlingDuration);
      case VEHICLE_UTILIZATION:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case CICO:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case CICO_NO_TRIP:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle, geofences);
      case IGNITION:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case COMPLETE_LOG:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case SPEEDING:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case SPEEDING_DURATION:
        return keyword.concat(selectedHauler, selectedVehicle);
      case GEOFENCE_IN_AND_OUT:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case FUEL_CONSUMPTION:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case DLTB_IDLING:
        return keyword.concat(timeBracket, selectedHauler, selectedVehicle);
      case CONVAN_INVENTORY:
        return keyword.concat(selectedHauler, /* selectedVehicle, */ batteryLevel);
      case UNCATEGORIZED:
        return keyword.concat(selectedHauler, selectedVehicle);
      case FUEL_REPORT:
        return keyword.concat(selectedHauler, selectedVehicle);
      case CICO_HOMEBASE:
        return keyword.concat(selectedHauler, selectedVehicle);
      case ERROR_LOGS:
        return keyword.concat(timeBracket);
      default:
        return keyword;
    }
  };

  const handleChange = event => {
    const validatedErrors = errors;
    setSendVia(prev => {
      if (prev === "email") {
        validatedErrors.sftp_link = null;
      } else {
        validatedErrors.recipients = null;
      }
      return event.target.value;
    });
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // Characters that are not included in this list (a-zA-Z0-9) are not allowed.
    if (/[^0-9]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleAddValues = e => {
    const { name, value } = e.target;
    const validatedErrors = errors;
    switch (name) {
      case "link":
        if (!_.isEmpty(value)) validatedErrors.sftp_link = null;
        break;
    }

    setTempAddData({
      ...tempAddData,
      [name]: value
    });
  };

  const handleSave = () => {
    const sked = `${generateInterval}${
      generateInterval !== "daily" ? `:${every}` : ""
    }`;

    const status_ids = tripStatusRef.current.map(status =>
      parseInt(status.id, 10)
    );
    
    addScheduleReport({
      variables: {
        user_id: +user?.id,
        reporttype_id: +reportTypeRef.current.id,
        sked,
        timesked: time,
        status: +process.env.REACT_APP_REPORT_STATUS_PENDING,
        ...(sendVia === "email"
          ? { email_add: recipients.toString() }
          : {
              sftp: {
                sftp_link: tempAddData.sftp_link,
                username: tempAddData.username,
                password: tempAddData.password,
                key: file,
                port: parseInt(tempAddData.port, 10)
              }
            }),
        via_email: sendVia === "email" ? 1 : 0,
        via_sftp: sendVia === "sftp" ? 1 : 0,
        // eslint-disable-next-line no-nested-ternary
        // group_ids: [
        //   GPS_STATUS,
        //   TRIP_SUMMARY,
        //   VEHICLE_ALERT,
        //   FULL_MOVEMENT,
        //   ALERT_COUNT_DASHBOARD,
        //   VEHICLE_STATUS,
        //   STANDARD_IDLING,
        //   VEHICLE_UTILIZATION,
        //   CICO,
        //   CICO_NO_TRIP,
        //   IGNITION,
        //   COMPLETE_LOG,
        //   SPEEDING,
        //   GEOFENCE_IN_AND_OUT,
        //   FUEL_CONSUMPTION,
        //   DLTB_IDLING,
        //   SPEEDING_DURATION
        // ].includes(reportTypeRef.current.id)
        //   ? haulerRef.current.map(hauler => Number(hauler.group_id))
        //   : user.group_ids[0],
        group_ids:
          haulerRef.current?.map(hauler => Number(hauler.group_id))
            || user.group_ids[0],
        trip_status_id: status_ids.length ? status_ids : [0],
        device_id:
          vehiclesRef.current[0] === 0 || vehiclesRef.current.length === 0
            ? [0]
            : vehiclesRef.current.map(i => i.device_id),
        alert_description_ids:
          alertTypesRef.current[0] === 0
            ? [0]
            : alertTypesRef.current.map(i => i.id),
        activity_only: activityOnlyRef.current ? 1 : 0,
        vehicle_status: vehicleStatusRef.current.map(({ id }) => +id),
        gps_status: gpsStatusRef.current,
        reporting_delay: reportDelayRef.current,
        duration: idlingDurationParser(idlingDurationRef.current).val,
        headers: JSON.stringify(reorderRef.current),
        geofence_ids: geofencesRef.current.map(geofence => geofence.id),
        startstamp: timeBracketRef.current.from,
        endstamp: timeBracketRef.current.to,
        battery_level: batteryLevelRef.current,
        use_logistics_status: isLogitics
      }
    })
      .then(e => {
        const { error, id: reportId } = e.data.add_scheduled_report;
        const validatedErrors = errors;
        if (error.length > 0) {
          error.map(row => {
            if (row.field === "sftp_key") {
              validatedErrors[row.field] = `This file ${file.name} is empty`;
            } else {
              validatedErrors[row.field] = row.message;
            }
          });
          setErrors(prevErrors => ({ ...prevErrors, ...validatedErrors }));
          return;
        }
        const module = process.env.REACT_APP_AUTOMATED_REPORTS_MODULE;
        let everyValue = "";
        if (generateInterval !== "daily") {
          if (generateInterval === "weekly") {
            everyValue = ` Every: ${days[Number(every) - 1]}`;
          } else {
            everyValue = ` Every: ${dates[Number(every) - 1]}`;
          }
        }
        const keyword = `Report ID: ${reportId}, Added Scheduled Report, Report type: ${
          reportTypeRef.current.name
        }, Interval: ${generateInterval}${everyValue}, Time: ${time}, Recipients: ${recipients.toString()}`;

        AddLogs(module, "add_scheduled_report", getFormFields(keyword));
        alertFire(() => {
          history.push({
            pathname: "/reports/automated"
          });
        });
      })
      .catch(() => {
        alertFire(() => {}, "Error", false);
      });
  };

  const handleFile = event => {
    const validatedErrors = errors;
    const {
      target: {
        files: [file]
      }
    } = event;
    if (file?.size / 1024 / 1024 > 1) {
      validatedErrors.sftp_key = `The file: ${file.name} is too large. It should be below 1 MB`;
    } else {
      validatedErrors.sftp_key = null;
    }

    switch (file?.type?.split("/")[0]) {
      case "image":
        validatedErrors.sftp_key = "Invalid file format";
        break;
    }

    setFile(file);
  };

  const validateForm = () => {
    const validatedErrors = errors;
    if (reportTypeRef.current && reportTypeRef.current.id !== undefined) {
      validatedErrors.reportType = null;
    } else {
      validatedErrors.reportType = "Report type is required.";
    }

    if (generateInterval === "weekly" || generateInterval === "monthly") {
      if (every === "default") {
        validatedErrors.every = `${
          generateInterval === "weekly" ? "Day" : "Date"
        } is required.`;
      } else {
        validatedErrors.every = null;
      }
    } else {
      validatedErrors.every = null;
    }

    if (time === "default") {
      validatedErrors.time = "Time is required.";
    } else {
      validatedErrors.time = null;
    }

    if (sendVia === "email") {
      if (recipients.length === 0) {
        validatedErrors.recipients = "Recipient/s is required.";
      } else {
        validatedErrors.recipients = null;
      }
    } else {
      if (_.isEmpty(tempAddData.sftp_link)) {
        validatedErrors.sftp_link = "Link is required.";
      } else {
        validatedErrors.sftp_link = null;
      }

      if (file?.size / 1024 / 1024 > 1) {
        validatedErrors.sftp_key = `The file: ${file.name} is too large. It should be below 1 MB`;
      } else {
        validatedErrors.sftp_key = null;
      }

      switch (file?.type?.split("/")[0]) {
        case "image":
          validatedErrors.sftp_key = "Invalid file format";
          break;
      }
    }

    if (
      [FULL_MOVEMENT].includes(reportType.id) &&
      (vehiclesRef.current.length === 0 || vehiclesRef.current[0] === 0)
    ) {
      validatedErrors.vehicle = "Plate number is required.";
    } else {
      validatedErrors.vehicle = null;
    }

    setErrors(prevErrors => ({ ...prevErrors, ...validatedErrors }));
  };

  const { loading, permissions } = useAllowedPermissions("/reports/automated/");

  if (loading) {
    return <React.Fragment />;
  }

  // if (!permissions?.edit) {
  //   return <Typography>403!!!!</Typography>;
  // }

  return (
    <div className={styles.root}>
      {!permissions?.edit ? (
        <Forbidden />
      ) : (
        <>
          <Header>
            <div>
              <Typography variant="h6">
                <IconButton
                  onClick={() => {
                    history.push({
                      pathname: "/reports/automated"
                    });
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <span className={styles.formTitle}>Add Automated Reports</span>
              </Typography>
            </div>
          </Header>
          <div className={styles.main}>
            <div className={styles.container}>
              <div className={styles.division}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
                <div className="inputGroups">
                  <ReportTypeInput
                    ref={reportTypeRef}
                    onChange={e => {
                      if (e) {
                        setReportType({
                          ...e,
                          headers: parseHeader(e.headers)
                        });
                        if (isLogitics) setIsLogitics(false);
                      }
                    }}
                  />
                  {errors.reportType && (
                    <ErrorMessage message={`${errors.reportType}`} />
                  )}
                </div>
                <div className="inputGroups">
                  <FormControl required classes={{ root: styles.formRoot }}>
                    <InputLabel id="generateInterval-label">
                      Generate Interval
                    </InputLabel>
                    <Select
                      classes={{ root: styles.selectRoot }}
                      labelId="generateInterval"
                      id="generateInterval-required"
                      value={generateInterval}
                      onChange={event => {
                        setGenerateInterval(event.target.value);
                        setEvery("default");
                      }}
                    >
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {[
                  DLTB_IDLING,
                  CICO,
                  FUEL_CONSUMPTION,
                  FULL_MOVEMENT,
                  SPEEDING,
                  VEHICLE_ALERT,
                  ALERT_COUNT_DASHBOARD,
                  COMPLETE_LOG,
                  ERROR_LOGS,
                  GEOFENCE_IN_AND_OUT,
                  IGNITION,
                  STANDARD_IDLING,
                  CICO_NO_TRIP,
                  VEHICLE_UTILIZATION
                ].includes(reportType.id) && (
                  <div className="inputGroups">
                    <TimeBracket
                      ref={timeBracketRef}
                      error={errors.timeBracket}
                      onError={message =>
                        setErrors(prev => ({ ...prev, timeBracket: message }))
                      }
                    />
                  </div>
                )}
                <div className="inputGroups">
                  <FormControl required classes={{ root: styles.formRoot }}>
                    <InputLabel id="every-label">Every</InputLabel>
                    <Collapse
                      component="div"
                      in={generateInterval !== "daily"}
                      style={{ width: "100%", marginTop: "12px" }}
                    >
                      <Select
                        style={{
                          color: every !== "default" ? "#000" : "#b1b1b1"
                        }}
                        classes={{ root: styles.selectRoot }}
                        labelId="every-id"
                        id="every-required"
                        value={every}
                        onChange={event => setEvery(event.target.value)}
                      >
                        <MenuItem value="default" disabled>
                          {generateInterval === "monthly"
                            ? "Select Date"
                            : "Select Day"}
                        </MenuItem>
                        {generateInterval === "monthly"
                          ? dates.map((date, index) => (
                              <MenuItem
                                key={`date-${index}`}
                                classes={{ root: styles.menuItem }}
                                value={`${date.substring(0, date.length - 2)}`}
                              >
                                {date}
                              </MenuItem>
                            ))
                          : days.map((day, dayIndex) => (
                              <MenuItem
                                key={`day-${dayIndex}`}
                                value={dayIndex + 1}
                                classes={{ root: styles.menuItem }}
                              >
                                {day}
                              </MenuItem>
                            ))}
                      </Select>
                      {errors.every && (
                        <ErrorMessage message={`${errors.every}`} />
                      )}
                    </Collapse>
                  </FormControl>
                  <FormControl required classes={{ root: styles.formRoot }}>
                    <Select
                      style={{
                        color: time !== "default" ? "#000" : "#b1b1b1",
                        marginTop:
                          generateInterval !== "daily" ? "12px" : "auto"
                      }}
                      classes={{ root: styles.selectRoot }}
                      labelId="every-id"
                      id="every-required"
                      value={time}
                      onChange={event => setTime(event.target.value)}
                    >
                      <MenuItem value="default" disabled>
                        Select Time
                      </MenuItem>
                      {times.map((itemTime, index) => (
                        <MenuItem key={`time-${index}`} value={itemTime.value}>
                          {itemTime.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.time && <ErrorMessage message={`${errors.time}`} />}
                  </FormControl>
                </div>
                <div className="inputGroups">
                  <FormControl classes={{ root: styles.formRoot }}>
                    <FormLabel style={{ fontSize: "12px" }}>Send Via</FormLabel>
                    <RadioGroup
                      name="sendVia"
                      value={sendVia}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="email"
                        control={<Radio color="primary" size="small" />}
                        label={<span style={{ fontSize: "12px" }}>Email</span>}
                      />
                      <FormControlLabel
                        value="sftp"
                        control={<Radio color="primary" size="small" />}
                        label={<span style={{ fontSize: "12px" }}>SFTP</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {sendVia === "email" && (
                  <div className="inputGroups">
                    <FormControl classes={{ root: styles.formRoot }}>
                      <ChipInput
                        classes={{ root: styles.textFieldRoot }}
                        style={{ padding: "6px 0px" }}
                        placeholder="Enter Recipient/s"
                        required
                        chipRenderer={(
                          { text, handleDelete, className },
                          key
                        ) => {
                          return (
                            <Chip
                              key={key}
                              size="small"
                              color="primary"
                              className={className}
                              label={text}
                              onDelete={handleDelete}
                            />
                          );
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        label={
                          <span id="recipient">
                            {" "}
                            <Tooltip
                              classes={{ tooltip: styles.toolTip }}
                              title="If multiple recipients, add comma (,) after each email address"
                              placement="top-start"
                            >
                              <HelpRounded className={styles.helpIcon} />
                            </Tooltip>
                            Recipient
                          </span>
                        }
                        // newChipKeyCodes={[32]}
                        newChipKeys={[",", "Enter", " "]}
                        value={recipients}
                        onAdd={chip => {
                          if (validate("email", chip.toString())) {
                            const newRecipients = [...recipients, chip];
                            setRecipients(newRecipients);
                          }
                        }}
                        onDelete={chip => {
                          setRecipients(prevChips =>
                            prevChips.filter(chp => chp !== chip)
                          );
                        }}
                      />
                      {errors.recipients && (
                        <ErrorMessage message={`${errors.recipients}`} />
                      )}
                    </FormControl>
                  </div>
                )}
                {sendVia === "sftp" && (
                  <>
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <TextField
                          classes={{ root: styles.textFieldRoot }}
                          fullWidth
                          required
                          // error={!tempAddData.sftp_link && error}
                          id="sftp_link"
                          placeholder="Enter link"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="SFTP Link"
                          value={tempAddData?.sftp_link || ""}
                          name="sftp_link"
                          onChange={e => {
                            handleAddValues(e);
                          }}
                          onFocus={e => {
                            handleAddValues(e);
                          }}
                        />
                        {errors.sftp_link && (
                          <ErrorMessage message={`${errors.sftp_link}`} />
                        )}
                      </FormControl>
                    </div>
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <TextField
                          classes={{ root: styles.textFieldRoot }}
                          fullWidth
                          // error={!tempAddData.username && error}
                          id="username"
                          placeholder="Enter username"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Username"
                          value={tempAddData.username}
                          name="username"
                          onChange={e => {
                            handleAddValues(e);
                          }}
                        />
                        {errors.username && (
                          <ErrorMessage message={`${errors.username}`} />
                        )}
                      </FormControl>
                    </div>
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <TextField
                          classes={{ root: styles.textFieldRoot }}
                          fullWidth
                          // error={!tempAddData.password && error}
                          id="link"
                          placeholder="Enter password"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Password"
                          value={tempAddData.password}
                          name="password"
                          onChange={e => {
                            handleAddValues(e);
                          }}
                        />
                        {errors.password && (
                          <ErrorMessage message={`${errors.password}`} />
                        )}
                      </FormControl>
                    </div>
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <TextField
                          classes={{ root: styles.textFieldRoot }}
                          fullWidth
                          // error={!tempAddData.key && error}
                          id="key"
                          placeholder="Select File"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Key"
                          value={file?.name || ""}
                          name="sftp_key"
                          InputProps={{
                            readOnly: true,
                            placeholder: "Select File",
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ color: "gray" }}
                              >
                                <Attachment />
                              </InputAdornment>
                            )
                          }}
                          onClick={() => uploadFile.click()}
                        />
                        {errors.sftp_key && (
                          <ErrorMessage message={`${errors.sftp_key}`} />
                        )}
                        <input
                          type="file"
                          ref={fileUpload => setUploadFile(fileUpload)}
                          style={{ visibility: "hidden", display: "block" }}
                          onChange={e => handleFile(e)}
                          // accept=".jpg,.jpeg,.png"
                        />
                      </FormControl>
                    </div>
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <TextField
                          classes={{ root: styles.textFieldRoot }}
                          fullWidth
                          // error={!tempAddData.port && error}
                          id="port"
                          placeholder="Enter port"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Port"
                          value={tempAddData.port}
                          name="port"
                          onChange={e => {
                            handleAddValues(e);
                          }}
                          onKeyPress={handleKeyPress}
                        />
                        {errors.port && (
                          <ErrorMessage message={`${errors.port}`} />
                        )}
                      </FormControl>
                    </div>
                  </>
                )}
                <div className="inputGroups">
                  <FieldGroup reportType={reportType.id}>
                    <FieldGroup.Field>
                      <HaulersChipInput
                        ref={haulerRef}
                        setSelectedHaulers={setSelectedHaulers}
                      />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <AlertTypeChipInput
                        values={alertTypes}
                        ref={alertTypesRef}
                        params={{ haulers: selectedHaulers }}
                        onSelectedAlertTypeChanged={onSelectedAlertTypeChanged}
                      />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <VehicleChipInput
                        ref={vehiclesRef}
                        group_ids={selectedHaulers}
                      />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <VehicleSearchInput
                        ref={vehiclesRef}
                        group_ids={selectedHaulers}
                        vehicleError={errors.vehicle}
                      />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <GeofenceChipInput
                        ref={geofencesRef}
                        groups={selectedHaulers}
                      />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <>
                        {(VEHICLE_STATUS === reportType.id && isWTIAdmin) && (
                          <SwitchHelper
                            handleChangeHandler={handleIsLogisticsChange}
                            checkedValue={isLogitics}
                            label="Use Logistics Status"
                            name="is_logistics"
                            tooltipPlacement="top-start"
                            tooltipTitle={
                              <>
                                Toggle on: report that will be generated will
                                display logistics (Booked/Available) status <br />
                                <br />
                                Toggle off: report that will be based on
                                non-logistics (Active) status
                              </>
                            }
                            icon={<HelpIcon className={styles.helpIcon} />}
                            customStyle={{ padding: 0, margin: "0 0 24px 0" }}
                          />
                        )}
                        <VehicleStatus
                          ref={vehicleStatusRef}
                          isLogitics={{ isTriggered: true, value: isLogitics }}
                        />
                      </>
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <GPSStatusChipInput ref={gpsStatusRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <ReportDelaySearchInput ref={reportDelayRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <TripStatusChipInput ref={tripStatusRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <ActivityOnlyInput ref={activityOnlyRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <IdlingDuration ref={idlingDurationRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <BatteryLevelInput ref={batteryLevelRef} />
                    </FieldGroup.Field>
                    <FieldGroup.Field>
                      <ReorderList
                        // initialValues={[
                        //   ["Field 1", "Field 2", "Field 3"],
                        //   ["Field 1", "Field 2", "Field 3"].reverse()
                        // ]}
                        ref={reorderRef}
                        initialValues={reportType.headers}
                        nonpermanentItems={alertTypes}
                        onSelectedAlertTypeChanged={onSelectedAlertTypeChanged}
                      />
                    </FieldGroup.Field>
                  </FieldGroup>
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.saveButton}
                onClick={() => {
                  validateForm();
                  const errorCount = Object.values(errors).filter(
                    err => err !== null
                  ).length;

                  if (errorCount === 0) {
                    handleToggleDialog();
                  }
                }}
              >
                Save Details
              </Button>
            </div>
          </div>
          <ConfirmationDialog
            toggle={dialogOpen}
            close={() => handleToggleDialog()}
            fn={e => handleSave(e)}
            title="Save"
            content="Are you sure you want to save this data?"
          />
        </>
      )}
    </div>
  );
};

export default withRouter(AddScheduledReport);
