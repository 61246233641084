import React, { useState, useCallback, useEffect, memo, useRef } from "react";
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
  Tab,
  Tabs
} from "@material-ui/core";
import moment from "moment";
import {
  TuneRounded as TuneRoundedIcon,
  AccessTimeRounded as AccessTimeRoundedIcon
} from "@material-ui/icons";
import useUserContext from "../../context/User/useUserContext";
import _ from "lodash";

/*
    Design: 
    1. created a new header for new dashboard.
    2. added a "Dashboard" header title.
    3. added current time and date.
    4  added filter button only visible on demurrage and OTTA/OTSA/OTD and should be clickable and show filter module.
    5. tab should be visible if user doesn't have any access right. 
*/
const OVERVIEW_OPT = "Overview";
const DEMURRAGE_OPT = "Demmurage";
const ONTIME_OPT = "OTTA/OTSA/OTD";

const Header = props => {
  const {
    setTab: setActiveTab,
    tab: activeTab,
    filterHandler,
    isFiltered,
    setIsFiltered
  } = props;

  const [time, setTime] = useState(moment().format("LLL"));

  const classes = useStyles();
  const _user_context = useUserContext();
  const accessTabRef = useRef([]);
  // this function is use for setting the active tab for the dashboard.
  const tabOnChangeHandler = (event, newValue) => {
    setActiveTab(newValue);
    setIsFiltered(false);
  }
  // a function to display dashboard tabs;
  // this will also validate if user has access rights on different tabs on the dashboard.
  const getTabDashboards = () => {
    const tabs = [];
    _user_context.access_rights.forEach(object => {
      if (
        +object.parent_id === +process.env.REACT_APP_ACCESS_OBJECT_DASHBOARD &&
        object.permission.view
      ) {
        tabs.push(object);
      }
    });

    return tabs;
  };
  // this will set the default active tab if OTTA/OTSA/OTD is not accessible.
  useEffect(() => {
    const dashboardCtx = _user_context.access_rights.filter(
      acc => +acc.parent_id === +process.env.REACT_APP_ACCESS_OBJECT_DASHBOARD
    );
    // sets the current active tab. 
    if (dashboardCtx.length > 0) {
      if (_.isEqual(dashboardCtx[0].name, ONTIME_OPT) && dashboardCtx[0].permission.view) setActiveTab(0);
      else if (_.isEqual(dashboardCtx[0].name, DEMURRAGE_OPT) && dashboardCtx[0].permission.view) setActiveTab(1);
      else if (_.isEqual(dashboardCtx[0].name, OVERVIEW_OPT) && dashboardCtx[0].permission.view) setActiveTab(2);

      accessTabRef.current = dashboardCtx;
    }
  }, [_user_context]);

  useEffect(() => {
    const clock = setInterval(() => {
      setTime(moment().format('LLL'));
    }, 60000);

    return () => clearInterval(clock);
  }, []);

  // set tab value for selected tabs.
  function tabValueHandler(initialValue) {
    let tabValue = 0;
    switch (initialValue) {
      case "Demmurage":
        tabValue = 1;
        break;
      case "Overview":
        tabValue = 2;
        break;
      default:
        tabValue = 0;
        break;
    }
    return tabValue;
  }
  // display the ui for dashboard tabs.
  const tabUIController = getTabDashboards().map(opt => (
    <Tab
      key={opt.id}
      value={tabValueHandler(opt.name)}
      label={<span className={classes.tabLabel}>{opt.name}</span>}
      className={classes.tabOptions}
      classes={{ root: classes.tabBtn }}
    />
  ));

  return (
    <Box className={classes.header__box}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header__label__container}
      >
        <Typography className={classes.header__label}>Dashboard</Typography>
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            gridGap={10}
            alignItems="center"
            className={classes.date_time_box}
          >
            <AccessTimeRoundedIcon className={classes.time__icon} />
            <Typography className={classes.header__current_date_time}>
              As of {time}
            </Typography>
          </Box>
          {activeTab !== 2 && (
            <Tooltip title="Filter Dashboard" placement="bottom" arrow>
              <IconButton
                size="small"
                className={classes.filter_btn}
                onClick={filterHandler}
                color={isFiltered ? "primary" : "default"}
              >
                <TuneRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabMenus}
          onChange={tabOnChangeHandler}
          aria-label="dashboard tabs"
          scrollButtons="auto"
          variant="scrollable"
        >
          {tabUIController}
        </Tabs>
      </Box>
    </Box>
  );
};

export default memo(Header);

// styles for this module.
const useStyles = makeStyles(theme => ({
  header__box: {
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #EAEAEA"
  },
  header__label: {
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: 800,
    letterSpacing: 1
  },
  date_time_box: {
    backgroundColor: "#F6f6f6",
    borderRadius: 50,
    padding: "0.5rem 0.8rem",
    marginRight: 5
  },
  time__icon: {
    color: "#808080",
    fontSize: 20
  },
  filter__icon: {
    fontSize: 16
  },
  header__current_date_time: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    fontSize: 12
  },
  tabOptions: {
    fontSize: 12,
    color: "#BEBEBE",
    letterSpacing: 1
  },
  tabBtn: {
    width: 170,
    [theme.breakpoints.down("sm")]: {
      width: 145
    },
    minWidth: "auto",
    transition: "0.5s",
    backgroundColor: "transparent !important",
    border: "none",
    borderRadius: "0"
  },
  tabLabel: {
    textTransform: "none",
    letterSpacing: 1,
    whiteSpace: "nowrap"
  },
  tabMenus: {},
  header__label__container: {
    padding: "1rem 2rem 0 2rem"
  },
  filter_btn: {
    marginLeft: 5,
    // color: "#808080",
    backgroundColor: "#F6F6F6",
    transition: "0.5s",
    opacity: "1",
    fontSize: 12,
    padding: 5,
    "&:hover": {
      opacity: "0.5"
    }
  }
}));
