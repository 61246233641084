/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  Typography,
  styled,
  Box,
  Divider,
  Grid,
  Link,
  Button
} from "@material-ui/core";
import {
  AccountBoxRounded as AccountBoxRoundedIcon,
  SettingsCellRounded as SettingsCellRoundedIcon,
  VpnKey as VpnKeyIcon
} from "@material-ui/icons";
import { UPDATE_PROFILE_MUTATION } from "../../../../graphql/Mutations";
import {
  userProfileSchema,
  profileFields
} from "../../../../data/userProfileInfo";
import useYupValidationResolver from "../../../../utils/hooks/useYupValidationResolver";
import useFetchUserEmail from "../../../../utils/hooks/useFetchUserEmail";
import useDebounce from "../../../../utils/hooks/useDebounce";
import AddLogs from "../../../../utils/functions/AddLogs";
import updateCookie from "../../../../utils/functions/updateCookie";
import UserPasswordInfoPanel from "../UserPasswordInfoPanel";
import FormField from "../UserFormField";
import FormContext from "../../../../context/formContext";
import useFetchUserProfile from "../../../../utils/hooks/useFetchUserProfile";

const UserProfileForm = props => {
  const { defaultUserInfo, hasUploaded, setHasUploaded } = props;
  const user = JSON.parse(Cookies.get("user"));
  const userInfo = useFetchUserProfile(user.id, user);
  const formCtx = useContext(FormContext);
  const resolver = useYupValidationResolver(userProfileSchema());
  const {
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    setError,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty, dirtyFields }
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultUserInfo,
    resolver
  });

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted: response => {
      const { update_user } = response;
      const previousCookie = JSON.parse(Cookies.get("user"));
      updateCookie("user", { ...previousCookie, ...update_user.user });
      if (update_user.success) {
        // eslint-disable-next-line no-unused-expressions
        hasUploaded.status && AddLogs("Profile", "change_profile", "");
        AddLogs("Profile", "update_profile", "");
        formCtx.setUpdatedUserInfo(getValues());
        reset(getValues());
        setHasUploaded({ image: "", status: false });
        clearErrors();
        Swal.fire({
          icon: "success",
          text: "Updated successfully",
          showConfirmButton: false,
          timer: 2500
        });
      } else {
        if (update_user?.error[0]?.field === "email_address") {
          setError("email_address", {
            type: "manual",
            message: "This email already exist"
          });
        };
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something weng wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const [enteredEmail, setEnteredEmail] = useDebounce("", 2000);
  const variable = {
    condition: [{ field: "users.email_address", value: enteredEmail }]
  };

  const isEmailExist = useFetchUserEmail(variable, !enteredEmail);

  const emailErrorHandler = () => {
    if (isEmailExist.length > 0 && getValues("id") !== isEmailExist[0]?.id) {
      setError("email_address", {
        type: "manual",
        message: "This email already exist"
      });
    }
  };

  const updateUserProfileHandler = () => {
    emailErrorHandler();

    const userInfoTemp = {
      id: getValues("id"),
      first_name: getValues("first_name"),
      last_name: getValues("last_name"),
      email_address: getValues("email_address"),
      contact_number: getValues("contact_number")
    };

    if (
      !isEmailExist ||
      _.isEmpty(isEmailExist) ||
      isEmailExist[0]?.id === getValues("id")
    ) {
      updateProfile({
        variables: hasUploaded.status
          ? { ...userInfoTemp, profile_image: getValues("profile_image") }
          : userInfoTemp
      });
    }
  };

  useEffect(() => {
    if (dirtyFields?.email_address) setEnteredEmail(getValues("email_address"));
    formCtx.hasUpdate = isDirty;
  }, [watch(), hasUploaded]);

  useEffect(() => emailErrorHandler(), [isEmailExist]);

  useEffect(() => {
    if (hasUploaded.status) {
      setValue("profile_image", hasUploaded.image, {
        shouldDirty: true
      });
    }
  }, [hasUploaded, dirtyFields?.profile_image]);

  useEffect(() => {
    const contactNumber = getValues("contact_number").replace(/^09/, "+639");
    setValue("contact_number", contactNumber);
  }, [dirtyFields?.contact_number, watch().contact_number]);

  return (
    <>
      <StyledFormContainer>
        <form onSubmit={handleSubmit(updateUserProfileHandler)}>
          <StyledHeaderForm>
            <AccountBoxRoundedIcon /> Personal Information
          </StyledHeaderForm>
          <StyledSubHeaderForm>
            This pertains to essential details regarding how we should address
            you.
          </StyledSubHeaderForm>
          <Box m={2}>
            <Grid container spacing={4}>
              {/* {generateFields("personalInfo")} */}
              <FormField
                fields={profileFields}
                Controller={Controller}
                control={control}
                errors={errors}
                fieldCategory="personalInfo"
              />
              <Link
                component="button"
                color="primary"
                style={{
                  margin: "-0.5rem 0px 0.5rem 1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: 5
                }}
                type="button"
                onClick={() => setOpenPasswordModal(!openPasswordModal)}
              >
                <VpnKeyIcon fontSize="small" /> Change Password?
              </Link>
            </Grid>
          </Box>
          <Divider />
          <StyledHeaderForm>
            <SettingsCellRoundedIcon /> Contact Information
          </StyledHeaderForm>
          <StyledSubHeaderForm>
            This pertains to your contact details and the security of your
            accounts, outlining how we can effectively reach you.
          </StyledSubHeaderForm>
          <Box m={2}>
            <Grid container spacing={4}>
              <FormField
                fields={profileFields}
                Controller={Controller}
                control={control}
                errors={errors}
                fieldCategory="contactInfo"
              />
            </Grid>
          </Box>
          <div style={{ width: "100%", textAlign: "center" }}>
            <StyledSubmitBtn disabled={!isDirty || isSubmitting} type="submit">
              Update Profile
            </StyledSubmitBtn>
          </div>
        </form>
      </StyledFormContainer>
      {openPasswordModal && (
        <UserPasswordInfoPanel
          isOpen={openPasswordModal}
          setIsOpen={setOpenPasswordModal}
          defaultUserInfo={defaultUserInfo}
        />
      )}
    </>
  );
};

const StyledFormContainer = styled(Box)(() => ({
  width: "80%",
  margin: "0 auto"
}));

const StyledHeaderForm = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1em",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  gap: 5,
  marginTop: "1rem"
}));

const StyledSubHeaderForm = styled(Typography)(() => ({
  color: "#BEBEBE",
  fontSize: "0.8em",
  margin: "0.25rem 0 1rem 0"
}));

const StyledSubmitBtn = styled(Button)(({ theme }) => ({
  margin: "2rem auto",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textTransform: "capitalize",
  padding: "5px 10px",
  borderRadius: 30,
  width: 150,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    opacity: "0.75"
  }
}));

export default UserProfileForm;
