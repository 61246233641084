import AddLogs from "../../../../utils/functions/AddLogs";
// subtract the difference of windows taskbar to total height or width of the screen for better splitting

//set the height for popup window
const getHeight = (room_count, index) => {
  let height = window.screen.availHeight - ((window.screen.height - window.screen.availHeight) + window.screen.height*0.1);
  if (room_count === 2) {
    //return full height only for 2 popups split
    return height;
  } else return height / 2;
};

//set the width for popup window
const getWidth = (room_count, index) => {
  let width = window.screen.availWidth - ((window.screen.width - window.screen.availWidth) + window.screen.width*0.015);
  if (room_count === 3 && index === 0) {
    //return full width only for the bottom of 3 popup split
    return width;
  } else return width / 2;
};

//sets the y axis position of the popup
const getTop = (room_count, index) => {
  let screenHeight = window.screen.height;
  //set popups to bottom half of the screen
  if ((room_count === 4 && index <= 1) || (room_count === 3 && index === 0)) {
    if(window.screenTop > 1){
      return window.screenTop + screenHeight/2;
    }
    else return screenHeight / 2;
  } 
  else { //else popup from the top
    if(window.screenTop < 0){
      return window.screenTop
    }
    return 0; 
  }
};

//sets the x axis position of the popup
const getLeft = (room_count, index) => {
  let screenWidth = window.screen.width;
  //set the popups to the right half of the screen
  if (
    (room_count === 4 && index % 2 === 0) ||
    (room_count === 3 && index === 1) ||
    (room_count === 2 && index === 0)
  ) {
    if(window.screenLeft > 1){
      return window.screenLeft  + screenWidth/2;
    }
    else return screenWidth / 2;
  } else { //else popup from the left
      if(window.screenLeft < 0){
        return window.screenLeft 
      }
      else  return 0;
    }
};

export const viewRooms = rooms => {
  if (rooms.length === 1) {
    let screenHeight = window.screen.height;
    let screenWidth = window.screen.width;
    let systemZoom = screenWidth / window.screen.availWidth;
    window.open(
      `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/view/${rooms[0].id}`,
      "_blank",
      `noopener, width=${screenWidth * 0.8} , height=${screenHeight * 0.7}, 
        left=${(screenWidth - screenWidth * 0.8) / 2 / systemZoom + window.screenLeft},
        top=${(screenHeight - screenHeight * 0.7) / 4 / systemZoom + window.screenTop} `
    );
  } else {
    let names = ""
    rooms.forEach((room, index) => {
      names += `${room.name}, `
      let features = `width=${getWidth(
        rooms.length,
        index
      )}, height=${getHeight(rooms.length, index)},
                    left=${getLeft(rooms.length, index)}, top=${getTop(
        rooms.length,
        index
      )}`;

      window.open(
        `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/view/${room.id}`,
        "_blank",
        features
      );

     if(rooms.length-1 === index){
      AddLogs(
        process.env.REACT_APP_CONTROL_ROOM_MODULE,
        "view",
        names
      );
     }
     
    });
  }
};
