/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import Sidebar from "./Sidebar";
import Users from "./Resources/Users/Users";
import Clients from "./WTI/Clients/Clients";
import Geofences from "./Trip/Geofences/Geofences";
import AccessRights from "./Activity/AccessRights/AccessRights";
import ControlRoom from "./Activity/ControlRoom/ControlRoom";
import Alerts from "./Activity/Alerts/Alerts";
import Devices from "./WTI/Devices/Devices";
import GPSProviders from "./WTI/GPSProviders/GPSProviders";
import Vehicles from "./Resources/Vehicles";
import VehicleTypes from "./Resources/VehicleType/VehicleType";
import AlertDescriptions from "./WTI/AlertDescriptions/AlertDescriptions";
import AlertReferences from "./WTI/AlertReferences/AlertReferences";
import SMSSending from "./WTI/SMSSending/SMSSending";
import ActivityLogs from "./WTI/ActivityLogs/ActivityLogs";
import Feedbacks from "./Activity/Feedback/Feedbacks";
// import { GET_ACCESS_OBJECTS } from "../../graphql/Queries";
import SearchContext from "../../context/SearchContext";
import useUserContext from "../../context/User/useUserContext";
import DriverInfos from "./DriverManagement/DriverInfo/DriverInfos";
import WorkingHours from "./DriverManagement/WorkingHours/WorkingHours";
import UpcomingFeature from "../../utils/UpcomingFeature";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "100%"
  },
  sidebar: {
    flexShrink: 0,
    height: "100%"
  }
}));

function Index(props) {
  const classes = useStyles();
  // const history = useHistory();
  // const user = JSON.parse(Cookie.get("user"));

  const { location } = props;

  const [sidebarVal, setSidebarVal] = useState("");
  const [expanded, setExpanded] = useState("RESOURCES SETTINGS");
  const [resource, setResource] = useState("");
  // const { data } = useQuery(GET_ACCESS_OBJECTS, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     user_level_id: user.user_level_id
  //   }
  // });
  const user_context = useUserContext();

  const { setSearchKeyword } = useContext(SearchContext);

  const handleChanges = (text, res) => {
    setSearchKeyword(prev => {
      const isDifferentModule = prev.moduleName !== text;
      if (isDifferentModule) {
        return {
          ...prev,
          moduleName: text,
          value: "",
          submit: false
        };
      }

      return prev;
    });
    // setSearchKeyword(prev => ({ ...prev, moduleName: text }));
    setSidebarVal(text);
    // history.push({pathname: res});
    setResource(res);
  };

  const setAdminLandingPage = () => {
    if (user_context.access_rights) {
      user_context.access_rights
        .filter(
          modules =>
            modules.parent_id === "0" &&
            modules.resource.split("/")[1] === "admin" &&
            modules.permission.view
        )
        .some(modules => {
          const submodule = user_context.access_rights.filter(
            v => v.parent_id === modules.id && v.permission.view
          );
          // console.log(submodule[0].name, submodule[0].resource);
          handleChanges(submodule[0].name, submodule[0].resource);
          return true;
        });
    }
  };

  useEffect(() => {
    if (_.isEmpty(location.state)) {
      setAdminLandingPage();
    }
  }, [user_context.access_rights]);

  useEffect(() => {
    if (!_.isEmpty(location.state)) {
      setSearchKeyword(prev => {
        const isDifferentModule = prev.moduleName !== location.state.params.moduleName;
        if (isDifferentModule) {
          return {
            ...prev,
            moduleName: location.state.params.moduleName,
            value: "",
            submit: false
          };
        }
        return prev;
      });
      setSidebarVal(location.state.params.moduleName);
      if (
        location.state.params.moduleName === process.env.REACT_APP_USERS_MODULE
      ) {
        setExpanded("RESOURCES SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_VEHICLES_MODULE
      ) {
        setExpanded("RESOURCES SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_VEHICLETYPES_MODULE
      ) {
        setExpanded("RESOURCES SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_PERSONNEL_MODULE
      ) {
        setExpanded("RESOURCES SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_HAULERS_MODULE
      ) {
        setExpanded("TRIPS SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_SERVICETYPES_MODULE
      ) {
        setExpanded("TRIPS SETTINGS");
      }
      if (
        location.state.params.moduleName === process.env.REACT_APP_ISSUES_MODULE
      ) {
        setExpanded("TRIPS SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_CLIENTS_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_GPSDEVICES_MODULE
      ) {
        setExpanded("RESOURCES SETTINGS");
      }
      if (
        location.state.params.moduleName === process.env.REACT_APP_RIGHTS_MODULE
      ) {
        setExpanded("ACTIVITY SETTINGS");
      }
      if (
        location.state.params.moduleName === process.env.REACT_APP_ALERTS_MODULE
      ) {
        setExpanded("ACTIVITY SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_FEEDBACK_MESSAGE
      ) {
        setExpanded("ACTIVITY SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_CONTROL_ROOM_MODULE
      ) {
        setExpanded("ACTIVITY SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_GEOFENCES_MODULE
      ) {
        setExpanded("TRIPS SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_GPS_PROVIDER_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_ACTIVITY_LOGS_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_ALERT_REFERENCES_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_SMS_SENDING_MODULE
      ) {
        setExpanded("WTI SETTINGS");
      }
      if (
        location.state.params.moduleName ===
        process.env.REACT_APP_DRIVER_INFO_MODULE
      ) {
        setExpanded("DRIVER MANAGEMENT");
      }
      if (
        location.state.params.moduleName === process.env.REACT_APP_WORKING_HOURS
      ) {
        setExpanded("DRIVER MANAGEMENT");
      }
    }
  }, [location.state]);

  const getSelectedModule = moduleName => {
    let component;
    switch (moduleName) {
      case "Users":
        component = <Users />;
        break;
      case "Access Rights":
        component = <AccessRights />;
        break;
      case "Alerts":
        component = <Alerts />;
        break;
      case "Geofences":
        component = <Geofences />;
        break;
      case "GPS Devices":
        component = <Devices />;
        break;
      case "GPS Providers":
        component = <GPSProviders />;
        break;
      case "Vehicles/CV":
        component = <Vehicles />;
        break;
      case "Vehicle Types":
        component = <VehicleTypes />;
        break;
      case "Clients":
        component = <Clients />;
        break;
      case "Alert Descriptions":
        component = <AlertDescriptions />;
        break;
      case "Alert References":
        component = <AlertReferences />;
        break;
      case "SMS Sending":
        component = <SMSSending />;
        break;
      case "Activity Logs":
        component = <ActivityLogs />;
        break;
      case "Feedback Message":
        component = <Feedbacks />;
        break;
      case "Control Room":
        component = <ControlRoom />;
        break;
      case "Driver Info":
        component = <DriverInfos />;
        break;
      case "Driver Score":
        component = <UpcomingFeature />;
        break;
      case "Working Hours":
        component = <WorkingHours />
        // component = <UpcomingFeature />
        break;
      default:
        break;
    }
    return component;
  };
  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <Sidebar
          sidebarVal={sidebarVal}
          handleChanges={handleChanges}
          expanded={expanded}
          setExpanded={setExpanded}
          resource={resource}
          setResource={setResource}
        />
      </div>
      <div className={classes.content}>{getSelectedModule(sidebarVal)}</div>
    </div>
  );
}

export default withRouter(Index);
