import React from "react";
import { lighten, makeStyles, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative",
    fontWeight: "600",
    letterSpacing: "1.25px"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6">
        Driver Info
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
