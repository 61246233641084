import { makeStyles } from "@material-ui/core";

const blinkColor = {
  3: "#FF0202",
  2: "#FBBC49",
  1: "#64F160"
};

export const useStyles = makeStyles(() => ({
    iconContainer: {
      height: "30px",
      width: "30px",
      position: "relative",
      
    },
    markerVehicleIcon: {
      width: "20px",
      height: "20px",
      padding: "5px",
      cursor: "pointer",
    },
    vehicleContainer: {
      // width: "30px",
      // height: "30px",
      // backgroundColor: "#3A3A3A",
      // position: "absolute",
      // borderRadius: "50%",
      // border: "3px solid #D2D2D2",
      // left: "-10%",
      // top: "-4%"
    },
    vehicleLightContainer: {
      width: "35px",
      height: "30px",
      position: "absolute",
      left: "50%",
      top: "50%",  
      transform: "translate(-50%, -50%)",
      zIndex: -2,
    },
    vehicleLight: {
      width: "30px",
      height: "56px",
      background: "linear-gradient(180deg, transparent, rgba(70, 132, 242))",
      position: "absolute",
      transform: "translate(-50%, -50%) perspective(25px) rotateX(315deg)",
      transformOrigin: "center",
      zIndex: -1,
      top: "50%",
      left: "50%",
    },
    vehicleLightTrips: {
      width: "30px",
      height: "56px",
      background: "linear-gradient(180deg, transparent, rgba(70, 132, 242))",
      position: "relative",
      transform: "translate(-50%, -50%) perspective(25px) rotateX(315deg)",
      transformOrigin: "center",
      zIndex: -1,
      top: "100%",
      left: "100%",      
    },
    vehicleLightHistory: {
      width: "30px",
      height: "56px",
      background: "linear-gradient(180deg, transparent, rgba(70, 132, 242))",
      position: "absolute",
      left: "20%",
      top: "-20px",
      transform: "translateX(-50%) perspective(25px) rotateX(315deg)",
      transformOrigin: "center",
      zIndex: -1,
    },
    labelVehicle: {
      position: "absolute",
      top: 37,
      left: -6,
      fontSize: 9,
      zIndex: 3,
      padding: "0.15rem",
      borderRadius: "5px",
    },
    labelVehicleMapView: {
      position: "absolute",
      top: "55px",
      left: "45%",
      fontSize: 9,
      zIndex: 3,
      padding: "0.15rem",
      borderRadius: "5px",      
    },
    iconBlinker: {
      backgroundColor: props => blinkColor[props.alert_level] || "none",
      height: "60px",
      width: "60px",
      borderRadius: "50%",
      opacity: 0.8,
      position: "absolute",
      top: "-40%",
      left: "-40%",
      // top: "-5%",
      // left: "-5%",
      animation: `$blinker 1.5s linear infinite`,
      margin: "0 !important",
      zIndex: "-2 !important"
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0
      }
    },
}));