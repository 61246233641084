import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Container,
  Typography,
  InputAdornment
} from "@material-ui/core";
import Cookie from "js-cookie";
import {
  AccountCircle,
  ArrowBack,
  AssignmentInd,
  Email,
  Phone
} from "@material-ui/icons";

import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/client";
import ChangePasswordDialog from "../utils/modal/ChangePassword";

import { UPDATE_PROFILE_MUTATION } from "../../../graphql/Mutations";
import { GET_USER, GET_USER_LEVEL } from "../../../graphql/Queries";
import AddLogs from "../../../utils/functions/AddLogs";

import { emailValidator, Helper, validateContact } from "../../Utils";
import ConfirmationDialog from "../../../utils/modal/ConfirmationDialog";
import updateCookie from "../../../utils/functions/updateCookie";

const drawerWidth = 325;
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    background: "#F8F9F9"
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawer: {
    width: drawerWidth
  },
  filterInput: {
    marginBottom: "20px"
  }
}));

export default function EditProfile(props) {
  const classes = useStyles();
  const { history } = props;
  const [uploadFile, setUploadFile] = useState({});
  const [changePasswordModal, toggleChangePasswordModal] = useState(false);
  const user = JSON.parse(Cookie.get("user"));
  const [id] = useState(user.id);
  const [emailHelper, setEmailHelper] = useState("");
  const [contactHelper, setContactHelper] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [landingPageLink, setLandingPageLink] = useState('');
  const [userData, setUserData] = useState({
    first_name: "",
    email_address: "",
    username: "",
    last_name: "",
    contact_number: ""
  });
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const {
    username,
    first_name,
    last_name,
    email_address,
    contact_number,
    profile_image
  } = userData;

  const [errors, setErrors] = useState({
    username_error: "",
    first_name_error: "",
    last_name_error: "",
    email_address_error: "",
    contact_number_error: "",
    profile_image_error: ""
  });

  const handleFile = event => {
    const {
      target: {
        files: [file]
      }
    } = event;

    setImageUpload(file);
  };

  useEffect(() => {
    let tempImageUrl = null;
    if (imageUpload !== null) {
      tempImageUrl = URL.createObjectURL(imageUpload);
      setPreviewImage(tempImageUrl);
    }

    return () => {
      URL.revokeObjectURL(tempImageUrl);
      setPreviewImage(null);
    };
  }, [imageUpload]);

  const openChangePasswordModal = () => {
    toggleChangePasswordModal(true);
  };

  const closeChangePasswordModal = () => {
    toggleChangePasswordModal(false);
  };

  const { data, error, loading } = useQuery(
    GET_USER,
    { variables: { id: user.id } },
    {
      onCompleted: response => {
        if (response) {
          const { get_user } = response;
          get_user.contact_number = get_user.contact_number.replace("+", "");
          setInitialData(get_user);
          setUserData(get_user);
          // setUsername(get_user.username);
          // setFirstName(get_user.first_name);
          // setLastName(get_user.last_name);
          // setEmailAddress(get_user.email_address);
          // setContactNumber(get_user.contact_number.replace("+", ""));
          // setProfileUrl(get_user.profile_image);
        }
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
          showConfirmButton: false,
          timer: 2500
        });
      }
    }
  );
  // fetch user level for setting up landing page when backbutton is clicked. 
  const { data: userLevelData, loading: userLevelLoading } = useQuery(GET_USER_LEVEL, {
    variables: { id: initialData?.user_level_id },
    onCompleted: (data) => {
      setLandingPageLink(data.get_user_level.acl_module_resource)
    },
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (!error && !loading) {
      // const { get_user } = data;
      const dataInstance = { ...data };
      let { get_user } = dataInstance;
      get_user = {
        ...get_user,
        // contact_number: get_user.contact_number.replace("+", "")
      };
      setInitialData(get_user);
      setUserData(get_user);
      // setUsername(get_user.username);
      // setFirstName(get_user.first_name);
      // setLastName(get_user.last_name);
      // setEmailAddress(get_user.email_address);
      // setContactNumber(get_user.contact_number.replace("+", ""));
      // setProfileUrl(get_user.profile_image);
    }
  }, [data, error, loading]);
  const [update] = useMutation(UPDATE_PROFILE_MUTATION, {
    variables: imageUpload
      ? {
          id,
          username,
          first_name,
          last_name,
          email_address,
          contact_number,
          profile_image: imageUpload
        }
      : {
          id,
          username,
          first_name,
          last_name,
          email_address,
          contact_number
        },
    onCompleted: response => {
      const { update_user } = response;
      const previousCookie = JSON.parse(Cookie.get("user"));

      // Cookie.set("user", { ...previousCookie, ...update_user.user });
      updateCookie("user",{ ...previousCookie, ...update_user.user })
      if (update_user.success) {
        imageUpload
          ? AddLogs("Profile", "change_profile", "")
          : AddLogs("Profile", "update_profile", "");
        Swal.fire({
          icon: "success",
          text: "Updated successfully",
          showConfirmButton: false,
          timer: 2500,
          onClose: () => {
            window.location.reload();
          }
        });
      } else if (update_user.error) {
        update_user.error.forEach(element => {
          const { field, message } = element;
          const errorField = `${field}_error`;
          errors[errorField] = message;
        });
        setErrors(errors);
      }
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something weng wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const handleChange = (event, value, name) => {
    const updatedData = { ...userData };
    if (name) {
      updatedData[name] = value;
    } else {
      updatedData[event.target.name] = event.target.value;
    }
    setUserData(updatedData);
  };

  const redirectAction = () => {
    if (_.isEqual(initialData, userData) && !imageUpload) {
      history.push(landingPageLink);
    } else {
      setConfirmationDialog(true);
    }
  };

  return (
    <div>
      <Box component="span" m={1} style={{ width: "100%" }}>
        {/* <Link to={{ pathname: "/trips" }} style={{ textDecoration: "none" }}> */}
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={redirectAction}
        >
          <ArrowBack />
        </IconButton>
        {/* </Link> */}
        Edit profile
      </Box>
      <hr />
      <Container maxWidth="md" className={classes.root}>
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            let has_no_errors = true;
            // update();
            if (!emailValidator(email_address)) {
              setEmailHelper(<Helper message="Invalid email address" />);
              has_no_errors = false;
            }

            if (!validateContact(`${contact_number}`)) {
              setContactHelper(
                <Helper message="Must follow number format (+63) xxx-xxx-xxxx" />
              );
              has_no_errors = false;
            }
            if (has_no_errors) {
              // console.log(imageUpload)
              update();
            }
          }}
        >
          <Grid container style={{ padding: 20 }}>
            <Grid item xs={12} sm={6} style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Avatar
                    className={classes.large}
                    alt=""
                    src={
                      previewImage ||
                      process.env.REACT_APP_CLOUD_STORAGE_BASE_URL +
                        profile_image
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <AssignmentInd color="primary" style={{ marginRight: 5 }} />
                    First Name <Typography color="primary">&nbsp;*</Typography>
                  </Box>
                  <TextField
                    value={userData.first_name}
                    fullWidth
                    required
                    onChange={e => {
                      // setFirstName(e.target.value);
                      handleChange(e);
                    }}
                    id="firstName"
                    name="first_name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Email color="primary" style={{ marginRight: 5 }} />
                    Email <Typography color="primary">&nbsp;*</Typography>
                  </Box>
                  <TextField
                    value={userData.email_address}
                    fullWidth
                    required
                    helperText={emailHelper}
                    onChange={e => {
                      // setEmailAddress(e.target.value);
                      handleChange(e);
                    }}
                    id="emailAddress"
                    name="email_address"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={() => uploadFile.click()}
                    style={{
                      color: "#FF7043",
                      borderColor: "#FF7043",
                      margin: 1,
                      borderRadius: 20
                    }}
                  >
                    Upload Photo
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={openChangePasswordModal}
                    style={{
                      color: "#FF7043",
                      borderColor: "#FFB74D",
                      margin: 1,
                      borderRadius: 20
                    }}
                  >
                    Change Password
                  </Button>
                  <input
                    type="file"
                    ref={fileUpload => setUploadFile(fileUpload)}
                    style={{ visibility: "hidden", display: "block" }}
                    onChange={e => handleFile(e)}
                    accept=".jpg,.jpeg,.png"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <AccountCircle color="primary" style={{ marginRight: 5 }} />
                    Username <Typography color="primary">&nbsp;*</Typography>
                  </Box>
                  <TextField
                    value={userData.username}
                    fullWidth
                    required
                    id="username"
                    name="username"
                    onChange={handleChange}
                  />
                  <div style={{ color: "red", paddingBottom: 10 }}>
                    {errors.username_error}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <AssignmentInd color="primary" style={{ marginRight: 5 }} />
                    Last Name <Typography color="primary">&nbsp;*</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    value={userData.last_name}
                    required
                    id="lastName"
                    onChange={e => {
                      // setLastName(e.target.value);
                      handleChange(e);
                    }}
                    name="last_name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Phone color="primary" style={{ marginRight: 5 }} />
                    Contact Number
                    <Typography color="primary">&nbsp;*</Typography>
                  </Box>
                  <TextField
                    value={userData.contact_number}
                    fullWidth
                    required
                    helperText={contactHelper}
                    id="contactNumber"
                    onChange={e => {
                      // setContactNumber(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={e => {
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^09/, "+639");
                      setUserData(prev => ({
                        ...prev,
                        contact_number: beginWith63
                      }));
                    }}
                    name="contact_number"
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">+</InputAdornment>
                      // )
                      inputProps: {
                        maxLength: /^\+639/.test(userData.contact_number)
                          ? 13
                          : 11
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  margin: 10,
                  borderRadius: 20,
                  padding: 12
                }}
              >
                Update Profile
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <ChangePasswordDialog
        toggle={changePasswordModal}
        closeChangePasswordModal={closeChangePasswordModal}
      />
      <ConfirmationDialog
        toggle={confirmationDialog}
        close={() => setConfirmationDialog(null)}
        fn={() => history.push("/trips")}
        title="Discard Changes?"
        content="Are you sure you want to leave this page and discard changes?"
      />
    </div>
  );
}
