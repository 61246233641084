/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/core";

export default makeStyles({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    overflow: "auto"
  },
  collapseContainer: {
    padding: props => (props.selectedRows.length > 0 ? "15px 22px" : "0 22px"),
    // background: "#5d5d5d0f",
    // color: "white",
    // background: "#00a7f7",
    background: "#5d5d5d0f",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  collapseText: {
    // textShadow: "0px 5px 23px #0000009e",
    fontWeight: "bold",
    letterSpacing: "0.75px"
  },
  collapseDeleteButton: {
    // color: "#00A7F7",
    // background: "white",
    padding: "5px 20px",
    borderRadius: 20,
    boxShadow: "0px 5px 10px -5px #b3b3b3",
    textTransform: "capitalize",
    letterSpacing: 0.25,
    marginLeft: 5,
    maxWidth: 150,
    minWidth: 150,
    // "&:hover": {
    //   background: "#f8fdff"
    // }
  }
});
