/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }from "react";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import {
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GET_ADMIN_CLIENTS } from "../../../graphql/Queries";
import _ from "lodash";
import useUserContext from "../../../context/User/useUserContext";

const useToolbarStyles = makeStyles(theme => ({
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#FFB74D",
      borderRadius: 7,
      color: "white",
      fontSize: "14px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiIconButton-root": {
      color: "white"
    }
  }
}));

const ClientGroupAutocomplete = (props) => {
  const {
    groupFilter,
    setter,
    ...autocompleteProps
  } = props;
  const user = useUserContext();
  const isWTIAdmin = _.isEqual(+user.user_level_id, +process.env.REACT_APP_WTI_ADMIN);
  const classes = useToolbarStyles();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState(user.client.group_ids.map((gid, index) => {
    return {
      id: gid,
      name: user.client.group_names[index],
      group_names: [user.client.group_names[index]],
      group_ids: [gid]
    }
  }));
  const { data: clients } = useQuery(GET_ADMIN_CLIENTS, {
    variables: { first: 5, keyword },
    skip: !isWTIAdmin
  });
  
  useEffect(() => {
    if(clients?.get_clients?.clients) {
      const arr = clients?.get_clients?.clients.filter(d => d?.name?.toLowerCase() !== groupFilter?.name?.toLowerCase()) 
      setData(_.without([groupFilter?.name && groupFilter, ...arr], undefined));
    }
  },[clients])

  return (
    <Autocomplete
      className={classes.autoComplete}
      style={{ height: 30, width: 200 }}
      options={data || []}
      getOptionLabel={option => option?.name}
      getOptionSelected={(o, v) => {
          if (v.name === o.name) return o.name;
      }}
      renderInput={
        params => <TextField 
          {...params}
          placeholder="All"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onBlur={() => setKeyword("")}
        />
      }
      disableClearable
      value={groupFilter}
      onChange={(e, nv) => {
        setter(nv);
      }}
      {...autocompleteProps}
    /> 
  );
};
  
export default ClientGroupAutocomplete;
