/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
// import { useHistory, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  withStyles,
  Paper,
  List,
  MenuItem,
  Typography,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Cookie from "js-cookie";
import _ from "lodash";
import Loading from "../../utils/Loading";
import { GET_ACCESS_OBJECTS, GET_USER } from "../../graphql/Queries";
import useUserContext from "../../context/User/useUserContext";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  test: {
    width: "250px",
    backgroundColor: "#6e6e6e",
    height: "100%"
  },
  list: {
    padding: 0,
    width: "100%",
    minHeight: 40,
    maxHeight: 194,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      // "-webkit-border-radius": "8px",
      background: "rgba(150, 150, 150, 0.4)",
      border: "2px solid white"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      border: "1px solid white",
      background: "rgba(150, 150, 150, 1)"
    }
  },
  listItem: {
    padding: 0
  },
  details: {
    padding: 0
  },
  heading: {
    fontWeight: "bold",
    fontSize: "small"
  },
  selected: {
    backgroundColor: `${theme.palette.primary.light} !important`
  },
  selectedSide: {
    background: `${theme.palette.primary.main} !important`
  },
  expanded: {
    "&$expanded": {
      margin: "0 auto"
    }
  },
  loading: {
    width: 300,
    height: 300
  }
}));

const ExpansionPanel = withStyles({
  root: {
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0 auto"
    },
    boxShadow: "none",
    MozBoxShadow: "none",
    WebkitBoxShadow: "none"
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: "0 10px 0 10px",
    backgroundColor: "#6e6e6e",
    minHeight: 66,
    height: 66,
    "&$expanded": {
      minHeight: 66
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ADMIN_MODULE = "admin";
const MECHANIC_MODULE = "pms";
const ALLOCATION_MODULE = "allocation";

export default function Sidebar(props) {
  const classes = useStyles();
  const history = useHistory();
  const user = JSON.parse(Cookie.get("user"));
  const { sidebarVal, handleChanges, expanded, setExpanded, activeTab } = props;

  // const { loading, data } = useQuery(GET_ACCESS_OBJECTS, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     user_level_id: user.user_level_id
  //   }
  // });

  // const { loading: loading2, data: data2 } = useQuery(GET_USER, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     id: user.id
  //   }
  // });

  // const { loading, data, refetch } = useQuery(GET_ACCESS_OBJECTS, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     user_level_id: user.user_level_id
  //   }
  // });

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getName = name => {
    let temp = name.split(" - ")[name.split(" - ").length - 1].toUpperCase();
    temp = temp.concat(
      temp.split(" ").length === 1 && temp.toLowerCase() !== MECHANIC_MODULE && temp.toLowerCase() !== ALLOCATION_MODULE
        ? " SETTINGS"
        : ""
    );
    return temp;
  };

  const user_context = useUserContext();

  // useEffect(() => {
  //   if (!loading2) {
  //     refetch({ user_level_id: data2.get_user.user_level_id });
  //   }
  // }, [data2]);

  // checks if the selected module on the sidebar is mechanic or admin modules.
  const filterModule = _.isEqual(activeTab, "PMS")
    ? MECHANIC_MODULE
    : _.isEqual(activeTab, "allocation")
    ? ALLOCATION_MODULE
    : ADMIN_MODULE;

  const startcaseLabel = ["360 checklist"];

  return user_context.loading ? (
    <Loading />
  ) : (
    <div className={classes.test}>
      <Paper className={classes.test} square>
        {user_context.access_rights
          .filter(
            modules =>
              modules.parent_id === "0" &&
              modules.resource.split("/")[1] === filterModule &&
              modules.permission.view
          )
          .map(modules => (
            <ExpansionPanel
              key={modules.id}
              square
              expanded={expanded === getName(modules.name)}
              onChange={handleChange(getName(modules.name))}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography
                  className={classes.heading}
                  style={{ color: "white" }}
                >
                  {getName(modules.name)}
                </Typography>
              </ExpansionPanelSummary>
              <MuiExpansionPanelDetails className={classes.details}>
                <List className={classes.list}>
                  {user_context.access_rights
                    .filter(
                      submodule =>
                        submodule.parent_id === modules.id &&
                        submodule.permission.view
                    )
                    .map(submodule => (
                      <MenuItem
                        className={classes.listItem}
                        button
                        key={submodule.id}
                        selected={submodule.name === sidebarVal}
                        classes={{ selected: classes.selected }}
                        onClick={() => {
                          handleChanges(submodule.name, submodule.resource);
                          history.push({
                            state: {
                              hasUploaded: false,
                              fileInfo: null,
                              params: { moduleName: submodule.name }
                            }
                          });
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            className={
                              submodule.name === sidebarVal
                                ? classes.selectedSide
                                : undefined
                            }
                            style={{
                              width: "15px",
                              backgroundColor:
                                submodule.name !== sidebarVal ? "#cccccc" : ""
                            }}
                          />
                          <div style={{ padding: "12px" }}>
                            {startcaseLabel.includes(submodule.name)
                              ? _.startCase(submodule.name)
                              : submodule.name}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </List>
              </MuiExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </Paper>
    </div>
  );
}
