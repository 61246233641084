import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableBody,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Button
} from "@material-ui/core";
import {
  Visibility as VisibleIcon,
  InfoOutlined as InfoOutlinedIcon,
  FiberManualRecord as FiberManualRecordIcon
} from "@material-ui/icons";
import { Polygon } from "@react-google-maps/api";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import wkt, { stringify } from "wkt";
import Header from "../../Header";
import { styles } from "./styles/UploadDetails";
import { uploadHeaderRows } from "./data/TableHeaders";
import {
  GET_INTERSECT_UNIVERSAL_GEOFENCE,
  GET_REVERSE_GEOCODE,
  GET_GEOFENCES
} from "../../../../graphql/Queries";
import { ADD_GEOFENCE, EDIT_GEOFENCE } from "../../../../graphql/Mutations";
import client from "../../../../Client";
import AddLogs from "../../../../utils/functions/AddLogs";
import CircleBoundConverter from "../../../../utils/functions/CircleBoundConverter";
import BasicModal from "../../../../utils/modal/BasicModal";
import Map from "../../../../utils/Map/index";
// import coordinatesParser from "../../../../utils/functions/coordinatesParser";
import { wktToCoordinates } from "../../../../utils/functions/coordinatesParserV2";
import { mapRef } from "../../../../utils/Map/Inner";
import Swal from "sweetalert2";
import Loading from "../../../../utils/Loading";

const UploadDetails = () => {
  const classes = styles();
  const location = useLocation();
  // const { google } = window;
  const { uploadedData, fileInfo } = location.state;
  const [selectedData, setSelectedData] = useState([]);
  const [useGeofence, setUseGeofence] = useState([]);
  const [allDataSelected, setAllDataSelected] = useState(false);
  const [useAllGeofence, setUseAllGeofence] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [tableRowsData, setTableRowsData] = useState();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [shownData, setShownData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selectedlength, setSelectedLength] = useState(0);

  const modalRef = useRef();
  const infoLabel = (
    <Typography className={classes.info_label}>
      <span className={classes.info_title}>Universal Geofence</span> - is a
      geofence created based on clusters formed by vehicle's GPS points verified
      by the GIS Team to ensure a well-covered area of interest to acquire more
      accurate event captures of vehicles entering and leaving the area of
      interest vehicle's GPS points.
    </Typography>
  );

  // open modal when save button is pressed.
  const openModalSaveHandler = () => setOpenSaveModal(true);
  const closeModalSaveHandler = () => setOpenSaveModal(false);

  // verify the save status of the uploaded file and the number of saved geofence/s.
  const checkFileStatusHandler = (noOfSelected, hasErrorData) => {
    setSelectedLength(noOfSelected);
    if (_.isEqual(noOfSelected, uploadedData.length) && !hasErrorData) {
      fileInfo[fileInfo.length - 1].status = "Success";
    } else if (
      (!_.isEqual(noOfSelected, uploadedData.length) &&
        noOfSelected !== undefined) ||
      (hasErrorData && _.isEqual(noOfSelected, uploadedData.length))
    ) {
      fileInfo[fileInfo.length - 1].status = "Partial Success";
      fileInfo[fileInfo.length - 1].noOfNotSaved =
        uploadedData.length - noOfSelected;
    } else {
      fileInfo[fileInfo.length - 1].status = " ";
    }
  };

  const uploadFileHandler = () => {
    // getting the selected value;
    let countSelected = 0;
    let hasErrorData = false;
    let address = "";
    Promise.all(
      formattedData.map(async (data, index) => {
        if (selectedData.includes(index)) {
          setLoadingUpload(true);
          let dataCopy = { ...data };
          countSelected++;
          if (_.isEqual(typeof data.radius, "string")) dataCopy.radius = null;

          if (isUseGeofence(index)) {
            dataCopy.geofence_geom_id = data?.universalGeom[0].geofence_geom_id;
            dataCopy.geom = data?.universalGeom[0].geom;
          }
          // deletes the row property before saving the data
          if (data?.row) delete data.row;
          /*
           *** checks if the error data has a value
           *** validate if the geofence code that was selected exists on the error data.
           *** sets the fileInfo error
           */
          if (errorData.length > 0) {
            const isExistingErr = errorData.filter(
              err => err.code === data.geofence_code
            );
            if (isExistingErr.length > 0) {
              fileInfo[fileInfo.length - 1].errors.push({
                row: isExistingErr[0].row,
                errors: "Geofence name already exist"
              });
              hasErrorData = true;
            }
          }
          // get the barangay, municipality, province & region.
          await client
            .query({
              query: GET_REVERSE_GEOCODE,
              variables: {
                latitude: dataCopy.location.lat,
                longitude: dataCopy.location.lon
              }
            })
            .then(locationInfo => {
              if (locationInfo) {
                const {
                  barangay,
                  municipality,
                  province,
                  region
                } = locationInfo.data.get_reverse_geocode;
                // set the locationInfo according to the properties.
                const addressCopy = {
                  barangay: barangay,
                  municipality: municipality,
                  province: province,
                  region: region
                };
                dataCopy = Object.assign(dataCopy, addressCopy);
                address = `${barangay} ${municipality} ${province} ${region}`;
              }
            });
          // save geofence or update geofence
          if (dataCopy?.id) {
            client
              .mutate({
                mutation: EDIT_GEOFENCE,
                variables: {
                  ...dataCopy,
                  barangay: _.isNull(dataCopy.barangay) ? "" : dataCopy.barangay,
                  municipality: _.isNull(dataCopy.municipality) ? "" : dataCopy. municipality,
                  province: _.isNull(dataCopy.province) ? "" : dataCopy.province,
                  region: _.isNull(dataCopy.region) ? "" : dataCopy.region,
                  address: _.isNull(dataCopy.region) ? dataCopy.name : address
                }
              })
              .then(() => {
                AddLogs("Admin - Geofences", "upload", "");
                setLoadingUpload(false);
              });
          } else {
            client
              .mutate({
                mutation: ADD_GEOFENCE,
                variables: {
                  ...dataCopy,
                  barangay: _.isNull(dataCopy.barangay) ? "" : dataCopy.barangay,
                  municipality: _.isNull(dataCopy.municipality) ? "" : dataCopy. municipality,
                  province: _.isNull(dataCopy.province) ? "" : dataCopy.province,
                  region: _.isNull(dataCopy.region) ? "" : dataCopy.region,
                  address: _.isNull(dataCopy.region) ? dataCopy.name : address
                }
              })
              .then(() => {
                AddLogs("Admin - Geofences", "upload", "");
                setLoadingUpload(false);
              });
          }
        }
        return true;
      })
    );
    // setting the status if success or partially success for not selecting all the uploaded data.
    checkFileStatusHandler(countSelected, hasErrorData);
  };

  // validate if the index is in the selected data array
  const isSelected = index => !_.isEqual(selectedData.indexOf(index), -1);
  const isUseGeofence = index => !_.isEqual(useGeofence.indexOf(index), -1);

  // select all the data
  const selectAllHandler = event => {
    const { checked } = event.target;
    const selectedDataTemp = selectedData;
    if (checked) {
      uploadedData.forEach((data, index) => selectedDataTemp.push(index));
      setSelectedData(_.uniq(selectedDataTemp));
      setAllDataSelected(true);
    } else {
      setSelectedData([]);
      setAllDataSelected(false);
    }
  };

  const selectAllGeofenceHandler = event => {
    const { checked } = event.target;
    const selectedGeofenceTemp = useGeofence;
    if (checked) {
      formattedData.forEach((data, index) => {
        if (!_.isEmpty(data.universalGeom)) {
          selectedGeofenceTemp.push(index);
        }
      });
      setUseGeofence(_.uniq(selectedGeofenceTemp));
      setUseAllGeofence(true);
    } else {
      setUseGeofence([]);
      setUseAllGeofence(false);
    }
  };

  // select single data
  const selectDataHandler = (event, index) => {
    let newSelectedTemp = [];
    const selectedIndex = selectedData.indexOf(index);

    if (_.isEqual(selectedIndex, -1)) {
      newSelectedTemp = newSelectedTemp.concat(selectedData, index);
    } else if (_.isEqual(selectedIndex, 0)) {
      newSelectedTemp = newSelectedTemp.concat(selectedData.slice(1));
    } else if (_.isEqual(selectedIndex, selectedData.length - 1)) {
      newSelectedTemp = newSelectedTemp.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedTemp = newSelectedTemp.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      );
    }
    setAllDataSelected(false);
    setSelectedData(newSelectedTemp);
  };

  // use universal geofence
  const universalGeomHandler = (event, index) => {
    let newUniversalGeomTemp = [];
    const selectedIndex = useGeofence.indexOf(index);

    if (_.isEqual(selectedIndex, -1)) {
      newUniversalGeomTemp = newUniversalGeomTemp.concat(useGeofence, index);
    } else if (_.isEqual(selectedIndex, 0)) {
      newUniversalGeomTemp = newUniversalGeomTemp.concat(useGeofence.slice(1));
    } else if (_.isEqual(selectedIndex, useGeofence.length - 1)) {
      newUniversalGeomTemp = newUniversalGeomTemp.concat(
        useGeofence.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newUniversalGeomTemp = newUniversalGeomTemp.concat(
        useGeofence.slice(0, selectedIndex),
        useGeofence.slice(selectedIndex + 1)
      );
    }

    setUseAllGeofence(false);
    setUseGeofence(newUniversalGeomTemp);
  };

  // table header
  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {uploadHeaderRows.map(headerRow => (
            <TableCell
              key={headerRow.id}
              className={
                _.isEqual(headerRow.id, "geofences") ? classes.table_cell : null
              }
            >
              {headerRow.hasCheckbox && _.isEmpty(headerRow.label) ? (
                <Checkbox
                  color="primary"
                  onChange={selectAllHandler}
                  checked={
                    allDataSelected ||
                    _.isEqual(selectedData.length, uploadedData.length)
                  }
                  indeterminate={
                    !_.isEmpty(selectedData) &&
                    !_.isEqual(selectedData.length, uploadedData.length)
                  }
                />
              ) : headerRow.hasCheckbox ? (
                <Checkbox
                  color="primary"
                  onChange={selectAllGeofenceHandler}
                  checked={
                    useAllGeofence ||
                    _.isEqual(
                      formattedData.filter(
                        data => !_.isEmpty(data.universalGeom)
                      ).length,
                      useGeofence.length
                    )
                  }
                  indeterminate={
                    !_.isEmpty(useGeofence) &&
                    !_.isEqual(
                      formattedData.filter(
                        data => !_.isEmpty(data.universalGeom)
                      ).length,
                      useGeofence.length
                    )
                  }
                />
              ) : null}
              {headerRow.label}

              {_.isEqual(headerRow.label, "Universal") && (
                <Tooltip title={infoLabel} placement="bottom">
                  <InfoIcon size="small" className={classes.info_icon} />
                </Tooltip>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const processUpload = async () => {
    const intersectResult = await Promise.all(
      uploadedData.map((data, index) => {
        const { location, radius } = data;
        // convert points to multipolygon.
        const convertedPoints = CircleBoundConverter(
          location.lat,
          location.lon,
          radius
        );
        let toStringPoly;

        // check if the user inputs a polygon on the specific row.
        if (_.isEmpty(data.geom)) {
          // prepares the format for multipolygon
          const multipolygon = {
            type: "MultiPolygon",
            coordinates: convertedPoints
          };
          // convert to a multipolygon
          toStringPoly = stringify(multipolygon);
        }

        return client
          .query({
            // check if the multipolygon has intersected other universal geofence.
            query: GET_INTERSECT_UNIVERSAL_GEOFENCE,
            variables: {
              centroid: radius ? `POINT(${location.lat} ${location.lon})` : "",
              geom_type: radius ? "circle" : "multipolygon",
              geom: data.geom ? data.geom : toStringPoly,
              intersect_percent: 60
            }
          })
          .then(res => {
            const shape =
              res.data.get_intersect_universal_geofence.universal_geofences;
            setFormattedData(prevValue => [
              ...prevValue,
              {
                ...data,
                geom: data.geom || toStringPoly,
                universalGeom: shape
              }
            ]);

            setShownData(prevValue => [
              ...prevValue,
              {
                ...data,
                geom: data.geom,
                universalGeom: shape
              }
            ]);

            return true;
          })
          .catch(err => {
            console.log(err);
            return false;
          });
      })
    );

    if (intersectResult.some(res => !res)) {
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 1500,
        showConfirmButton: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    processUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tempData = [];
    const tableData = shownData.map((data, index) => {
      const isIntersected = !_.isEmpty(data.universalGeom);
      const isItemSelected = isSelected(index);
      const isGeofenceInUse = isUseGeofence(index);

      // setup the existing geofence code name.
      if (data?.id) {
        // do nothing.
      } else {
        client
          .query({
            query: GET_GEOFENCES,
            variables: {
              filter: { field: "name", value: [data.name] }
            }
          })
          .then(res => {
            if (
              res.data.get_geofences.count >= 1 &&
              errorData?.code !== data.geofence_code
            ) {
              tempData.push({
                row: data.row,
                name: data.name,
                code: data.geofence_code
              });
              setErrorData(tempData);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }

      return (
        <TableRow
          key={`row-${index}`}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
        >
          <TableCell className={classes.table_checkBox}>
            <Checkbox
              color="primary"
              onClick={event => selectDataHandler(event, index)}
              checked={isItemSelected}
            />
          </TableCell>
          <TableCell>
            <Tooltip
              title={isIntersected ? "Intersects" : "No Intersect"}
              placement="top"
            >
              <FiberManualRecordIcon
                className={
                  isIntersected ? classes.dot_enabled : classes.dot_disabled
                }
              />
            </Tooltip>
          </TableCell>
          <TableCell>{data.name}</TableCell>
          <TableCell>{data.location.lat}</TableCell>
          <TableCell>{data.location.lon}</TableCell>
          <Tooltip
            title={uploadedData[index].geom && data.geom}
            placement="top-end"
          >
            <TableCell className={classes.geom_cell}>
              <div className={classes.geom_div}>
                <Box textOverflow="ellipsis" overflow="hidden">
                  {data.geom ? data.geom : "-"}
                </Box>
              </div>
            </TableCell>
          </Tooltip>
          <TableCell>{data.radius ? data.radius : "-"}</TableCell>
          <TableCell>
            <Checkbox
              disabled={!isIntersected}
              color="primary"
              onClick={event => universalGeomHandler(event, index)}
              checked={isGeofenceInUse}
            />
            Use
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                // setCurrentItem({ index, ...data });
                const data = formattedData[index];
                setCurrentItem({ index, ...data });
                modalRef.current.openModal();
              }}
            >
              <VisibleIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });

    setTableRowsData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, selectedData, useGeofence, shownData]);

  return (
    <Fragment>
      <Header
        moduleName="Geofences"
        hasUploadedGeofence={
          location.pathname.search("geofences/upload/details") > 0
        }
        countOfData={
          formattedData.filter(data => !_.isEmpty(data.universalGeom)).length
        }
        totalOfData={uploadedData.length}
        isSavable={!_.isEmpty(selectedData)}
        openModalSaveHandler={openModalSaveHandler}
        closeModalSaveHandler={closeModalSaveHandler}
        openSaveModal={openSaveModal}
        uploadFileHandler={uploadFileHandler}
        fileInfo={fileInfo}
        selectedData={selectedData}
        checkFileStatusHandler={checkFileStatusHandler}
        loadingUpload={loadingUpload}
        selectedlength={selectedlength}
      />
      {(loading || loadingUpload) && <Loading />}
      <TableContainer
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 131px)",
          backgroundColor: "white"
        }}
      >
        <Table stickyHeader>
          <TableHeader />
          <TableBody>{tableRowsData}</TableBody>
        </Table>
      </TableContainer>
      <BasicModal ref={modalRef}>
        {({ getContainerClassName }) => {
          if (!currentItem) return null;
          const ownedGeoPath = wktToCoordinates(currentItem?.geom);
          let univGeofencePath = [];
          const intersected = currentItem.universalGeom.length > 0;
          if (intersected) {
            univGeofencePath = wktToCoordinates(currentItem.universalGeom[0].geom);
          }

          const allPoints = [...ownedGeoPath, ...univGeofencePath];

          // Auto zoom to view whole intersected geofence
          let paths = [];
          var bounds = new window.google.maps.LatLngBounds();
          allPoints.forEach(path =>
            path.forEach(coor => {
              paths.push({ lat: coor.lat(), lng: coor.lng() })
            })
          );
          paths.forEach(path => bounds.extend(path));

          var extendPoint = new window.google.maps.LatLng(
            bounds.getNorthEast().lat(),
            bounds.getNorthEast().lng()
          );

          bounds.extend(extendPoint);

          // const univGeofencePath = wkt.parse(current?.universalGeom?.[0]?.geom);
          return (
            <div className={`${getContainerClassName()}`}>
              <div className={classes.modal_content}>
                <div className={classes.modal_header}>
                  <span style={{ flex: 1 }}>
                    <b>{currentItem.name}</b>
                  </span>
                  <span className={classes.owned_legend}>
                    <FiberManualRecordIcon
                      style={{ fontSize: "14px", marginTop: 2 }}
                      htmlColor="#00A4F4"
                    />
                    Owned
                  </span>
                  {intersected && (
                    <span className={classes.universal_legend}>
                      <FiberManualRecordIcon
                        style={{ fontSize: "14px", marginTop: 2 }}
                        htmlColor="#FFEE7F"
                      />
                      Universal
                    </span>
                  )}
                </div>
                {intersected && (
                  <div style={{ position: "relative" }}>
                    <div className={classes.universal_info}>
                      <span>
                        <InfoOutlinedIcon
                          fontSize="small"
                          htmlColor="#00A4F4"
                        />
                      </span>
                      <span>
                        <b>UNIVERSAL</b> is a geofence created based on clusters
                        formed by vehicles GPS points verified by the GIS Team
                        to ensure a well-covered area of interest to acquire
                        more accurate event captures of vehicles entering and
                        leaving the area of interest vehicle&apos;s GPS.
                      </span>
                    </div>
                  </div>
                )}
                <div className={classes.map_container}>
                  <Map
                    // zoom={15}
                    center={
                      currentItem
                        ? {
                            lat: currentItem.location.lat,
                            lng: currentItem.location.lon
                          }
                        : { lat: 14.676, lng: 121.0437 }
                    }
                    options={{
                      fullscreenControl: false,
                      gestureHandling: "none",
                      zoomControl: true,
                      disableDefaultUI: true,
                      mapTypeControlOptions: {
                        mapTypeIds: [
                          // google.maps.MapTypeId.ROADMAP,
                          // google.maps.MapTypeId.HYBRID
                        ]
                      }
                    }}
                  >
                    {currentItem?.geom && (
                      <Polygon
                        onLoad={() => mapRef.current.fitBounds(bounds)}
                        paths={ownedGeoPath}
                        options={{
                          fillColor: "#00A6F7",
                          fillOpacity: 0.9,
                          strokeColor: "#000",
                          strokeOpacity: 0.5,
                          strokeWeight: 2,
                          clickable: false,
                          draggable: false,
                          editable: false,
                          geodesic: false,
                          zIndex: 1
                        }}
                      />
                    )}
                    {currentItem?.universalGeom?.length && (
                      <Polygon
                        paths={univGeofencePath}
                        options={{
                          fillColor: "#FFEE7F",
                          fillOpacity: 0.9,
                          strokeColor: "#000",
                          strokeOpacity: 0.5,
                          strokeWeight: 2,
                          clickable: false,
                          draggable: false,
                          editable: false,
                          geodesic: false,
                          zIndex: 1
                        }}
                      />
                    )}
                  </Map>
                </div>
                {intersected && (
                  <div style={{ textAlign: "right" }}>
                    {!isUseGeofence(currentItem.index) ? (
                      <Button
                        className={classes.use_universal_button}
                        variant="contained"
                        onClick={() => {
                          universalGeomHandler(undefined, currentItem.index);
                          modalRef.current.closeModal();
                        }}
                      >
                        Use Universal
                      </Button>
                    ) : (
                      <Button
                        className={classes.unuse_universal_button}
                        variant="contained"
                        onClick={() => {
                          universalGeomHandler(undefined, currentItem.index);
                          modalRef.current.closeModal();
                        }}
                      >
                        Unuse
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </BasicModal>
    </Fragment>
  );
};

export default UploadDetails;
