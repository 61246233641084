import React, { useState, memo, useContext, useEffect, useRef } from "react";
// custom hooks.
import useFetchNotifications from "../../utils/hooks/useFetchNotifications";
import useScrollPosition from "../../utils/hooks/useScrollPosition";
import useFetchSearch from "../../utils/hooks/useFetchSearch";
import useDebounce from "../../utils/hooks/useDebounce";
import { getBooking } from "../../api/bookingRepository";

import Cookie from "js-cookie";
import moment from "moment";
import Loading from "../../utils/Loading";
import {
  GET_VEHICLES_LOOKUP,
  GET_UNIQUE_TRIP_NUMBERS
} from "../../graphql/Queries";
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Button,
  IconButton,
  Tooltip,
  ListItem,
  InputAdornment,
  FormControl,
  TextField,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchContext from "../../context/SearchContext";
import {
  TuneRounded as TuneRoundedIcon,
  GetAppRounded as GetAppRoundedIcon,
  NavigationRounded as NavigationRoundedIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
  AssignmentTurnedInRounded as AssignmentTurnedInRoundedIcon,
  ExpandMoreOutlined as ExpandMoreOutlinedIcon,
  Event,
  FilterListRounded as FilterListRoundedIcon
} from "@material-ui/icons";
import Filter from "../../utils/Filter";
import AddLogs from "../../utils/functions/AddLogs";
import NoNotification from "./NoNotification";
import TripImage from "../../assets/Trip.png";
import TargetImage from "../../assets/Target_light.png";
import { CSVLink } from "react-csv";
import useUserContext from "../../context/User/useUserContext";
import {
  ClassificationOptions
} from "../../data/NotificationData";

const Highlight = require("react-highlighter");
const notifCategory = [
  "Trip",
  "Dwell Time",
  "GPS Alert",
  "Trip Upload",
  "Activity Logs",
  "User Activity",
  "System Response"
];
const redirectTripCategory = ["Trip", "Dwell Time", "User Activity"];

const headersCSV = [
  { label: "Date", key: "created" },
  { label: "Plate Number", key: "vehicle_plate_no" },
  { label: "Trip Number", key: "trip_number" },
  { label: "Notification", key: "notification" }
];

const AllNotifications = props => {
  // fetch user on cookies.
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const initialFilter = [
    { field: "group_ids", value: user.group_ids.map(gi => gi.toString()) },
    { field: "category.keyword", value: ClassificationOptions[0].value }
  ];
  const { history } = props;
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  const { setSearchKeyword, searchKeyword } = contextData;
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [scrollPosition, setContainerElementId] = useScrollPosition(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState(
    ClassificationOptions[0]
  );
  //   const [order, setOrder] = useState("desc");
  //   const [orderBy, setOrderBy] = useState("created");
  const [isCopied, setIsCopied] = useState({
    isOpen: false,
    vertical: "bottom",
    horizontal: "left"
  });
  const { vertical, horizontal, isOpen } = isCopied;
  const copyRef = useRef(false);
  const debounceTime = 1000;
  const rowsPerPage = 10;
  const orderBy = "created";
  const order = "desc";
  const initialDateRange = {
    startDate: moment()
      .subtract(3, "days")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm"),
    endDate: moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm")
  };
  const initialFilterValues = {
    body: {
      filter: initialFilter,
      keyword: keyword,
      dateRange: {
        start: initialDateRange.startDate,
        end: initialDateRange.endDate
      }
    },
    vehicle_id: null,
    trip_number: null,
    isDisabled: true
  };
  const initialKeyLogValues = {
    dateRange: {
      from: initialDateRange.startDate,
      to: initialDateRange.endDate
    },
    plateno: null,
    trip_number: null
  };
  const [keyLog, setKeyLog] = useState(initialKeyLogValues);
  const [plateNumberKeyword, setPlateNumberKeyword] = useDebounce(
    "",
    debounceTime
  );
  const [tripNumberKeyword, setTripNumberKeyword] = useDebounce(
    "",
    debounceTime
  );
  // variable conditions for each query called.
  const uniqueTripNumbersVars = {
    keyword: tripNumberKeyword,
    first: 5
  };
  const vehicleInfosVars = {
    // keyword: plateNumberKeyword,
    filter: [{ field: "vehicle_info.plateno", value: plateNumberKeyword }],
    first: 15
  };

  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [filterSearch, setFilterSearch] = useState(filterValues.body);
  const initialVariablesForNotif = {
    ...filterSearch,
    first: rowsPerPage,
    skip: currentPage * rowsPerPage
  };
  // custom hook for fetching notifications data.
  const fetchDetails = useFetchNotifications({
    initVariables: initialVariablesForNotif,
    initOrderOfData: {
      orderBy: [
        {
          field: orderBy === "created" ? orderBy : `${orderBy}.keyword`,
          direction: order
        }
      ]
    }
  });
  // custom useFetch when using filter vehicle plate no and trip numbers.
  const uniqueTripNumbersData = useFetchSearch(
    GET_UNIQUE_TRIP_NUMBERS,
    uniqueTripNumbersVars,
    "TRIP_NUMBERS_NOTIF"
  );
  const vehicleInfosData = useFetchSearch(
    GET_VEHICLES_LOOKUP,
    vehicleInfosVars,
    "VEHICLE_PLATE_NO_NOTIF"
  );

  history.listen(location => {
    const { pathname } = location;
    if (pathname !== "/notifications") {
      if (searchKeyword.moduleName) {
        setSearchKeyword({ ...searchKeyword, moduleName: "" });
      } else {
        setSearchKeyword({ ...searchKeyword, value: "" });
      }
    }
  });

  const resetFilterHandler = () => {
    setFilterValues(initialFilterValues);
    setFilterSearch(filterValues.body);
    setSelectedClassification(ClassificationOptions[0]);
    setIsFiltered(false);
  };

  const gpsNotifOnClick = notificationInfo => {
    //history.replace(`/notification/view/${notifId}`)
    localStorage.setItem(
      "gps_notification",
      JSON.stringify({ notification: notificationInfo })
    );
    window.open(`/notification/view/${notificationInfo.id}`, "_blank");
  };

  const tripNotifOnClickHandler = async (event, notificationInfo) => {
    //updateReadNotificationHandler(notificationInfo);
    if (
      notificationInfo.notification.includes("New trip has been assigned") || 
      (notificationInfo.notification.includes("Trip request") && 
      notificationInfo.notification.includes("has expired"))
    ) {
      const variables = {
        conditions: [
          {
            "field": "trip_number",
            "value": notificationInfo.trip_number
          }
        ]
      };

      history.push({
        pathname: "/allocation",
        state: {
          params: {
            moduleName: process.env.REACT_APP_VEHICLE_ALLOCATION,
            vars: variables,
            isFromNotif: true
          }
        }
      });
    } else {
      const booking = await getBooking(notificationInfo.trip_number);
      const state = {
        tripData: booking.tripList[0],
        rawTripData: booking.rawTripData[0]
      };
      if (booking.tripList.length === 1) {
        if (booking.tripList[0].tripNumber === notificationInfo.trip_number) {
          const to = {
            pathname: "/details",
            state: state
          };
          history.push("/temp");
          history.push(to);
        }
      }
    }
  };

  // search keyword for the navigation search bar.
  useEffect(() => {
    if (searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setFilterSearch({ ...filterSearch, keyword: searchKeyword.value });
    }
  }, [searchKeyword]);

  // a function that will scroll to the very top of the page.
  // id of the div "#notificationScrollable"
  function scrollBehaviorHandler() {
    const notifBody = document.querySelector("#allnotificationScrollable");
    notifBody.scrollTo({ top: 0, behavior: "smooth" });
  }
  // logs
  function logHandler(module, action) {
    AddLogs(
      module,
      action,
      `${
        keyLog.dateRange
          ? `Date Range: ${moment(keyLog.dateRange.from).format(
              "lll"
            )} - ${moment(keyLog.dateRange.to).format("lll")}, `
          : ``
      }${keyLog.plateno ? `Plate Number: ${keyLog.plateno}, ` : ""}${
        keyLog.trip_number ? `Trip Number: ${keyLog.trip_number}` : ``
      }`
    );
  };

  useEffect(() => {
    if (!isFiltered) resetFilterHandler();
  }, [isFiltered])


  return (
    <div
      className={fetchDetails.count > 5 ? classes.boxNotif : classes.boxNoNotif}
      id="allnotificationScrollable"
    >
      {fetchDetails.hasLoaded && <Loading />}
      <Container className={classes.notificationContainer}>
        <InfiniteScroll
          dataLength={fetchDetails.data.length}
          next={() => {
            fetchDetails.fetchMoreHandler();
            setContainerElementId("allnotificationScrollable");
          }}
          hasMore={fetchDetails?.count !== fetchDetails?.total}
          scrollableTarget="allnotificationScrollable"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center" gridGap="5px">
              <Tooltip title="Back" placement="bottom" arrow>
                <IconButton size="small" onClick={() => history.goBack()}>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Tooltip>
              <Typography className={classes.title}>Notification</Typography>
            </Box>
            <Box display="flex" gridGap="0.5rem">
              <Button
                variant="contained"
                size="small"
                startIcon={<GetAppRoundedIcon />}
                className={classes.downloadBtn}
                disableElevation
                onClick={() => logHandler("Notification", "download_table")}
              >
                <CSVLink
                  style={{ color: "white", textDecoration: "none" }}
                  filename={`Notification List - (${moment(
                    keyLog.dateRange.from
                  ).format("ll")} - ${moment(keyLog.dateRange.to).format(
                    "ll"
                  )}).csv`}
                  data={fetchDetails.data}
                  headers={headersCSV}
                >
                  Download
                </CSVLink>
              </Button>
              <Tooltip title="Filter Notifications" placement="bottom" arrow>
                <IconButton
                  size="small"
                  color={isFiltered ? "primary" : "default"}
                  onClick={() => {
                    setToggleFilter(!toggleFilter);
                  }}
                >
                  <FilterListRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            {fetchDetails.count > 0 ? (
              fetchDetails.data.map((data, index) => {
                return (
                  <Box
                    className={classes.notifBox}
                    key={index}
                    onClick={event => {
                      if (data.category === notifCategory[2]) {
                        gpsNotifOnClick(data);
                      } else if (redirectTripCategory.includes(data.category)) {
                        if (data.trip_number) {
                          tripNotifOnClickHandler(event, data);
                        }
                        //data.category === notifCategory[0]
                        // if (event.currentTarget === event.target) {
                        // }
                      }
                    }}
                  >
                    {data.category === notifCategory[0] ||
                    data.category === notifCategory[5] ? (
                      <img
                        src={TripImage}
                        alt="trip notifications"
                        className={classes.notifImage}
                      />
                    ) : (
                      <img
                        src={TargetImage}
                        alt="trip notifications"
                        className={classes.notifImage}
                      />
                    )}
                    <Box>
                      <Box display="flex" gridGap={10} alignItems="center">
                        <Typography className={classes.vehicleLabel}>
                          <span style={{ fontWeight: "500", marginRight: 5 }}>
                            Vehicle Plate No:
                          </span>

                          {
                            <Highlight search={contextData.searchKeyword.value}>
                              {data.vehicle_plate_no || (
                                <span className={classes.error}>
                                  Not Available
                                </span>
                              )}
                            </Highlight>
                          }
                        </Typography>
                        {data.trip_number && (
                          <Tooltip placement="bottom" title="Copy" arrow>
                            <Button
                              className={classes.tripNumber}
                              onClick={event => {
                                event.stopPropagation();
                                try {
                                  navigator.clipboard
                                    .writeText(data.trip_number)
                                    .then(() =>
                                      setIsCopied(prev => ({
                                        ...prev,
                                        isOpen: true
                                      }))
                                    )
                                    .catch(() => console.log("error"));
                                } catch (error) {}
                              }}
                            >
                              ({data.trip_number})
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                      <Box className={classes.notifBody}>
                        <Typography className={classes.descriptionNotif}>
                          <Highlight search={contextData.searchKeyword.value}>
                            {data.notification}
                          </Highlight>
                        </Typography>
                        <Typography className={classes.timeDate}>
                          {moment(data.created).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <NoNotification />
            )}
          </Box>
        </InfiniteScroll>
        {scrollPosition > 1000 && (
          <IconButton
            onClick={scrollBehaviorHandler}
            className={classes.scrollBtn}
          >
            <NavigationRoundedIcon />
          </IconButton>
        )}
      </Container>
      <Snackbar
        autoHideDuration={1500}
        open={isOpen}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setIsCopied(prev => ({ ...prev, isOpen: false }))}
      >
        <Alert
          icon={<AssignmentTurnedInRoundedIcon fontSize="inherit" />}
          className={classes.alertBox}
          severity=""
        >
          Trip Number Copied!
        </Alert>
      </Snackbar>
      {toggleFilter && (
        <Filter
          toggleFilter={toggleFilter}
          setToggleFilter={setToggleFilter}
          primaryName="Notification Settings"
          applyEvent={() => {
            logHandler("Notification", "filter");
            setFilterSearch(filterValues.body);
            setCurrentPage(0);
            setIsFiltered(true);
          }}
          isDisabled={filterValues.isDisabled}
          clearAllAction={resetFilterHandler}
        >
          <ListItem>
            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                className={classes.filterInput}
                options={ClassificationOptions}
                getOptionLabel={option => option.label}
                id="vehicle-filter"
                disableClearable={true}
                defaultValue={ClassificationOptions[0]}
                value={selectedClassification}
                onChange={(event, value) => {
                  setIsFiltered(true);
                  setSelectedClassification(value);
                  setFilterValues(prev => ({
                    ...prev,
                    body: {
                      ...prev.body,
                      filter: [
                        {
                          field: "group_ids",
                          value: user.group_ids.map(gi => gi.toString())
                        },
                        { field: "category.keyword", value: value.value }
                      ]
                    },
                    isDisabled: false
                  }));
                  return;
                }}
                popupIcon={
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                }
                disableopenonfocus="true"
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.filterInput}>
                        Classification
                      </span>
                    }
                    className={classes.filterInput}
                  />
                )}
                renderOption={option => (
                  <Typography className={classes.textStyle}>
                    {option.label}
                  </Typography>
                )}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth
                disableFuture
                label="From"
                value={filterValues.body.dateRange.start}
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.filterInput}
                onChange={date => {
                  const temp = {
                    ...filterValues,
                    body: {
                      ...filterValues.body,
                      dateRange: {
                        ...filterValues.body.dateRange,
                        start: moment(date).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  };
                  const tempKeyLog = {
                    ...keyLog,
                    dateRange: {
                      ...keyLog.dateRange,
                      from: moment(date).format("YYYY-MM-DD HH:mm:ss")
                    }
                  };
                  if (
                    moment(date).format("YYYY-MM-DD HH:mm:ss") !==
                    filterValues.body.dateRange.start
                  ) {
                    temp.isDisabled = false;
                  }
                  setKeyLog(tempKeyLog);
                  setFilterValues(temp);
                }}
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="inherit" style={{ color: "#808080" }} />
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.textStyle
                  }
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </ListItem>
          <ListItem>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth
                disableFuture
                label="To"
                value={filterValues.body.dateRange.end}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={date => {
                  const temp = {
                    ...filterValues,
                    body: {
                      ...filterValues.body,
                      dateRange: {
                        ...filterValues.body.dateRange,
                        end: moment(date).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  };
                  const tempKeyLog = {
                    ...keyLog,
                    dateRange: {
                      ...keyLog.dateRange,
                      to: moment(date).format("YYYY-MM-DD HH:mm:ss")
                    }
                  };
                  if (
                    moment(date).format("YYYY-MM-DD HH:mm:ss") !==
                    filterValues.body.dateRange.end
                  ) {
                    temp.isDisabled = false;
                  }
                  setKeyLog(tempKeyLog);
                  setFilterValues(temp);
                }}
                className={classes.filterInput}
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="inherit" style={{ color: "#808080" }} />
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.textStyle
                  }
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </ListItem>
          <ListItem>
            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                className={classes.filterInput}
                options={vehicleInfosData.data}
                getOptionLabel={option => option}
                id="vehicle-filter"
                value={filterValues.vehicle_id}
                loading={vehicleInfosData.isLoading}
                onChange={(event, value) => {
                  let temp = filterValues.body.filter;
                  temp = temp.filter(e => e.field !== "vehicle_id");
                  let vehicle_id = null;
                  if (temp) {
                    temp = temp.filter(e => e.field !== "vehicle_plate_no");
                  }
                  if (value) {
                    temp.push({
                      field: "vehicle_plate_no",
                      value: value
                    });
                    vehicle_id = value;
                  }
                  setKeyLog({
                    ...keyLog,
                    plateno: value ? value : null
                  });
                  setFilterValues({
                    ...filterValues,
                    vehicle_id: vehicle_id,
                    body: {
                      ...filterValues.body,
                      filter: temp
                    },
                    isDisabled: false
                  });
                }}
                popupIcon={
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                }
                disableopenonfocus="true"
                renderOption={option => (
                  <Typography className={classes.textStyle}>
                    {option}
                  </Typography>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.filterInput}>Plate Number</span>
                    }
                    onChange={e => setPlateNumberKeyword(e.target.value)}
                    className={classes.filterInput}
                  />
                )}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                className={classes.filterInput}
                options={uniqueTripNumbersData.data}
                getOptionLabel={option => option}
                id="vehicle-filter"
                value={filterValues.trip_number}
                loading={uniqueTripNumbersData?.isLoading}
                onChange={(event, value) => {
                  let temp = filterValues.body.filter;
                  let trip_number = "";
                  let tempKeyword = "";
                  if (temp) {
                    temp = temp.filter(e => e.field !== "trip_number");
                  }
                  if (value) {
                    temp.push({
                      field: "trip_number",
                      value: value ? [value] : []
                    });
                    trip_number = value;
                    tempKeyword = value;
                  }
                  setKeyLog({
                    ...keyLog,
                    trip_number: value ? value : null
                  });
                  setFilterValues({
                    ...filterValues,
                    trip_number: trip_number,
                    body: {
                      ...filterValues.body,
                      keyword: tempKeyword
                    },
                    isDisabled: false
                  });
                }}
                popupIcon={
                  <ExpandMoreOutlinedIcon
                    style={{ color: "#808080", fontSize: 18 }}
                  />
                }
                disableopenonfocus="true"
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.filterInput}>Trip Number</span>
                    }
                    className={classes.filterInput}
                    onChange={e => setTripNumberKeyword(e.target.value)}
                  />
                )}
                renderOption={option => (
                  <Typography className={classes.textStyle}>
                    {option}
                  </Typography>
                )}
              />
            </FormControl>
          </ListItem>
        </Filter>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  boxNoNotif: {
    backgroundColor: "#F9F9F9",
    padding: "3rem 0 0 0",
    overflow: "none",
    height: "100vh"
  },
  boxNotif: {
    backgroundColor: "#F9F9F9",
    padding: "3rem 0 0 0",
    overflow: "none",

    height: "calc(100% - 64px)",
    transition: "0.5s",
    "&:hover": {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5,
        backgroundColor: "#F9F9F9 !important",
        borderRadius: "0px !important"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F9F9F9 !important",
        border: "none !important"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
        border: "0px solid  rgba(0, 0, 0, 0) !important",
        borderRadius: 0
      }
    }
  },
  notificationContainer: {
    minWidth: 300,
    maxWidth: 700,
    backgroundColor: "#FFFFFF",
    minHeight: "100vh",
    borderRadius: "40px 40px 0px 0px",
    padding: "2rem 2rem 0 2rem"
  },
  title: {
    color: theme.palette.primary.dark,
    fontSize: 20,
    fontWeight: 500
  },
  downloadBtn: {
    borderRadius: 20,
    textTransform: "none",
    width: 120,
    transition: "0.3s",
    backgroundColor: `${theme.palette.primary.main} !important`,
    "&:hover": {
      opacity: "0.7",
      backgrounColor: theme.palette.primary.main
    }
  },
  notifBox: {
    borderBottom: "1px solid #F9F9F9",
    padding: "1rem 0.5rem",
    display: "flex",
    alignItems: "center",
    gap: 10,
    transition: "0.3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F9F9F9",
      borderRadius: 10
    }
  },
  notifImage: {
    width: 30,
    padding: "0.5rem",
    backgroundColor: "#F9F9F9",
    borderRadius: "50%"
  },
  vehicleLabel: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  descriptionNotif: {
    fontSize: 12,
    color: theme.palette.primary.dark
  },
  error: {
    fontSize: 13,
    color: theme.palette.error.main
  },
  tripNumber: {
    fontSize: 10,
    color: theme.palette.primary.main,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  notifBody: {},
  timeDate: {
    fontSize: 10,
    color: theme.palette.info.main
  },
  scrollBtn: {
    position: "absolute",
    right: "2.5%",
    bottom: "5%",
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    opacity: 1,
    transition: "0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.7"
    }
  },
  alertBox: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    fontSize: 13
  },
  textStyle: {
    fontSize: 13,
    color: theme.palette.primary.dark
  },
  filterInput: {
    minWidth: "250px",
    width: "250px",
    fontSize: 14,
    "& .MuiAutocomplete-inputRoot": {
      fontSize: 13,
      color: theme.palette.primary.dark
    }
  }
}));

export default memo(AllNotifications);
