import React, { useState } from "react";
import { Autocomplete as PlacesAutocomplete } from "@react-google-maps/api";
import { TextField } from "@material-ui/core";
import useSearchStyles from "./Search.styles";

const PlaceAutocomplete = React.forwardRef(
  (
    {
      onPlaceChanged = () => {},
      onTextSearchChanged = () => {},
      placeText = ""
    },
    ref
  ) => {
    const autocompleteRef = React.useRef();
    const styles = useSearchStyles();
    // const [selectedPlace, setSelectedPlace] = useState(() => {
    //   // if (ref.current) return ref.current.getPlace()?.formatted_address;
    //   return "";
    // });
    const onPlacesAutocompleteLoad = autocomplete => {
      // eslint-disable-next-line no-param-reassign
      if (!autocompleteRef.current) autocompleteRef.current = autocomplete;
    };

    const onPlaceChange = () => {
      if (autocompleteRef.current) {
        onTextSearchChanged(
          autocompleteRef.current.getPlace()?.formatted_address
        );
        if (autocompleteRef.current.getPlace?.()?.geometry) {
          onPlaceChanged(autocompleteRef.current);
        }
      }
    };

    const handleTextChange = e => {
      onTextSearchChanged(e.target.value);
    };

    return (
      <PlacesAutocomplete
        onLoad={onPlacesAutocompleteLoad}
        onPlaceChanged={onPlaceChange}
        restrictions={{ country: "ph" }}
      >
        <TextField
          // inputRef={ref}
          style={{ width: "250px", background: "white", margin: 0 }}
          InputProps={{
            classes: {
              root: styles.textField
            }
          }}
          placeholder="Search Places"
          fullWidth
          value={placeText}
          onChange={handleTextChange}
          InputLabelProps={{
            shrink: true,
            style: { fontWeight: "bold" }
          }}
          margin="normal"
        />
      </PlacesAutocomplete>
    );
  }
);

export default PlaceAutocomplete;
