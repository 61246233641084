import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import useFetchVehicles from "../../utils/hooks/useFetchVehicles";
import useUserContext from "../../context/User/useUserContext";
import Loading from "../../utils/Loading";
import VehicleIssues from "./VehicleIssues";
import VehicleInformation from "./VehicleInformation";
import Checklist360 from "./Checklist360/index";
import Sidebar from "../Admin/Sidebar";
// import EditChecklist from "./360 Checklist/EditChecklist";
import SearchContext from "../../context/SearchContext";
// import AddLogs from "../../utils/functions/AddLogs";
// import UpcomingFeature from "../../utils/UpcomingFeature";
// constant values;
const INITIAL_FIELD = "vehicle_info.plateno";
const INITIAL_DIRECTION = "asc";
const INITIAL_FORMAT_DATE = "YYYY-MM-DD";
const INITIAL_FORMAT_TIME = "00:00:00";
const CURRENT_DATE = moment().format(INITIAL_FORMAT_DATE);
const INITIAL_DATE_RANGE = { start: `${CURRENT_DATE} ${INITIAL_FORMAT_TIME}` };
const INITIAL_ORDER = { field: INITIAL_FIELD, direction: INITIAL_DIRECTION };
const INITIAL_ROWS = 10;
const INITIAL_CURRENT_PAGE = 0;
const INITIAL_TOTAL_PAGES = 0;
const PARENT_ID_MOD = 0;
const ACT_MODULE = "pms";
const INIT_EXP_TAB = "PMS";

const Mechanic = props => {
  const { location } = props;
  // user values
  const userCtx = useUserContext();
  const contextData = useContext(SearchContext);
  const { setSearchKeyword, searchKeyword } = contextData;
  const accessRightsCtx = userCtx.access_rights;
  const userGroupIds = userCtx.group_ids;
  const userGroupNames = userCtx.group_names;
  const clientName = userCtx.client.name;
  const userGroupId =
    userGroupIds[
      _.findIndex(userGroupNames, names => _.isEqual(names, clientName))
    ];
  // states
  const [expandedTab, setExpandedTab] = useState(INIT_EXP_TAB);
  const [sidebarVal, setSidebarVal] = useState("");
  const [resource, setResource] = useState("");
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState({
    rows: INITIAL_ROWS,
    total: INITIAL_TOTAL_PAGES,
    currentPage: INITIAL_CURRENT_PAGE
  });
  // variables conditions
  const variables = {
    dateRange: INITIAL_DATE_RANGE,
    // group_ids: [userGroupId],
    orderBy: [{ field: order.field, direction: order.direction }],
    first: page.rows,
    skip: page.currentPage * page.rows,
    condition: [],
    keyword: keyword
  };
  // fetching vehicle values
  const {
    vehicles,
    count,
    total,
    loading,
    totalPage,
    refetchData
  } = useFetchVehicles(variables, setPage, page, searchKeyword);

  // functions
  // handle changes for sidebar values.
  function handleChanges(text, res) {
    setSidebarVal(text);
    setResource(res);
  }
  // set landing page for sidebar active value.
  function setAdminLandingPage() {
    if (accessRightsCtx) {
      accessRightsCtx
        .filter(
          modules =>
            _.isEqual(+modules.parent_id, PARENT_ID_MOD) &&
            _.isEqual(modules.resource.split("/")[1], ACT_MODULE) &&
            modules.permission.view
        )
        .some(modules => {
          const submodule = accessRightsCtx.filter(
            v => _.isEqual(v.parent_id, modules.id) && v.permission.view
          );

          handleChanges(submodule[0].name, submodule[0].resource);
          return true;
        });
    }
  }
  // display the selected module on accordion sidebar.
  const getMenuHandler = useCallback(
    module => {
      let displayComponent;
      switch (module) {
        case "Vehicle Information":
          displayComponent = (
            <VehicleInformation
              page={page}
              setPage={setPage}
              vehicles={vehicles}
              count={count}
              total={total}
              order={order}
              setOrder={setOrder}
              vars={variables}
              totalPage={totalPage}
              refetchVehicle={refetchData}
            />
          );
          break;
        case "Vehicle Issues":
          displayComponent = (
            <VehicleIssues
              page={page}
              setPage={setPage}
              vehicles={vehicles}
              count={count}
              total={total}
              order={order}
              vars={variables}
              setOrder={setOrder}
              totalPage={totalPage}
              refetchVehicle={refetchData}
            />
          );
          break;
        case "360 checklist":
          displayComponent = (
            <Checklist360
              page={page}
              setPage={setPage}
              vehicles={vehicles}
              count={count}
              total={total}
              order={order}
              vars={variables}
              setOrder={setOrder}
              totalPage={totalPage}
              refetchVehicle={refetchData}
            />
          );
          // displayComponent = <UpcomingFeature />;
          break;
        default: // none
      }

      return displayComponent;
    },
    [loading, vehicles, variables]
  );
  // reset table.
  function resetTableHandler() {
    setPage({
      rows: INITIAL_ROWS,
      total: INITIAL_TOTAL_PAGES,
      currentPage: INITIAL_CURRENT_PAGE
    });
  }

  // useEffect.
  // run only once the access right changes.
  useEffect(() => {
    if (_.isEmpty(location.state)) setAdminLandingPage();
  }, [accessRightsCtx]);
  // searc keyword
  useEffect(() => {
    if (!searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
  }, []);

  // search keyword for adding logs on search.
  useEffect(() => {
    if (searchKeyword.submit && !loading) {
      setKeyword(searchKeyword.value);
      // variables.keyword = searchKeyword.value;
      // variables.group_ids = [];
      setPage(prevState => ({ ...prevState, currentPage: 0 }));
      // refetchData(variables);
      // if (searchKeyword?.value && variables?.keyword !== searchKeyword?.value) {
      //   AddLogs("My Mechanic", "search", searchKeyword.value);
      // }
    }
  }, [searchKeyword]);
  // reset table handler and data.
  useEffect(() => {
    // refetchData(variables);
    if(sidebarVal !== "Vehicle Issues") {
      refetchData({
        ...variables,
        group_ids: sidebarVal === "360 checklist" ? [userGroupId] : []
      });
    }
    resetTableHandler();
    // set search module name.
    switch (sidebarVal) {
      case "Vehicle Information":
        searchKeyword.moduleName = process.env.REACT_APP_VEHICLE_INFO;
        break;
      case "Vehicle Issues":
        searchKeyword.moduleName = process.env.REACT_APP_VEHICLE_ISSUES;
        break;
      case "360 checklist":
        searchKeyword.moduleName = process.env.REACT_APP_360_CHECKLIST;
        break;
      default: // default value.
    }
  }, [sidebarVal]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box display="flex" height="100%" width="100%">
          <Sidebar
            sidebarVal={sidebarVal}
            handleChanges={handleChanges}
            expanded={expandedTab}
            setExpanded={setExpandedTab}
            resource={resource}
            setResource={setResource}
            activeTab={INIT_EXP_TAB}
          />
          <Box width="100%">{getMenuHandler(sidebarVal)}</Box>
        </Box>
      )}
    </>
  );
};

export default Mechanic;
