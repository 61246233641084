/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Paper, useTheme } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import {
  FiberManualRecord as FiberManualRecordIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  MyLocation as MyLocationIcon
} from "@material-ui/icons";
// import Geocode from "react-geocode";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import MapComponent from "./MapComponent";
import Loading from "../../../utils/Loading";
import { statusColor } from "../../Utils";
import {
  GET_DEVICE,
  GET_TRIP_LOG_V2,
  GET_GEOFENCES,
  GET_TEXTUAL_LOCATION,
  GET_CICOS_NO_GEOFENCE
} from "../../../graphql/Queries";
import { VEHICLE_SUBSCRIPTION } from "../../../graphql/Subscription";

const MapView = props => {
  const theme = useTheme();
  const [geofences, setGeofences] = useState([]);
  const [geofencesStops, setGeofencesStops] = useState([]);
  const [bbox, setBbox] = useState(null);
  const [tempIds, setTempIds] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const { parsedTripData, history } = props;
  const [itinerary, setItinerary] = useState([]);
  const [center, setCenter] = useState({ latitude: "", longitude: "" });
  const [tab, setTab] = useState("stops");
  const [skip, setSkip] = useState(true);
  const [geofenceList, setGeofenceList] = useState([]);
  const [points, setPoints] = useState([]);
  const [zoom, setZoom] = useState(16);
  const [directions, setDirections] = useState(null);

  const computeStartTime = time => {
    const { actual_arrival, manual_arrival, arrival } = time;
    const defaultArrivalVal = "1111-11-11 00:00:00";

    const isManArrDef = manual_arrival === defaultArrivalVal;
    const isActArrDef = actual_arrival === defaultArrivalVal;
    const isArrDef = arrival === defaultArrivalVal;

    if (manual_arrival && !isManArrDef) {
      return manual_arrival;
    } else if (actual_arrival && !isActArrDef) {
      return actual_arrival;
    } else if (arrival && !isArrDef) {
      return arrival;
    } else {
      return moment().format("YYYY-MM-DD hh:mm:ss");
    }

    // if (isManArrDef && isActArrDef && isArrDef) {
    //   return moment().format("YYYY-MM-DD hh:mm:ss");
    // } else if (
    //   (actual_arrival && !isActArrDef) ||
    //   (manual_arrival && !isManArrDef)
    // ) {
    //   return actual_arrival || manual_arrival;
    // } else {
    //   return arrival;
    // }

    // if (
    //   (actual_arrival || manual_arrival)
    // ) {
    //   return actual_arrival || manual_arrival;
    // }
    // return arrival;
  };

  const year = moment(computeStartTime(parsedTripData.pickups[0]))
    .utc()
    .format("YYYY");

  const [filters, setFilters] = useState({
    geofences: false,
    stops: true,
    traffic: false,
    road: false
  });

  const getCicoVariables = () => {
    // const { trip_status } = parsedTripData;
    const start = moment(computeStartTime(parsedTripData.pickups[0]))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let end = moment()
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    if (parsedTripData.trip_status.toLowerCase() === "completed") {
      const lastDropoff =
        parsedTripData.dropoffs[parsedTripData.dropoffs.length - 1];
      end = moment(
        lastDropoff.actual_departure
          ? lastDropoff.actual_departure
          : lastDropoff.manual_departure
      )
        .subtract(8, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    const variable = {
      inFilter: [
        // {
        //   field: "vehicle_plate_no",
        //   value: vehicleInfo?.vehicle_info?.plateno
        // }
        {
          field: "device_id",
          value: parsedTripData.vehicle_id
        }
      ],
      dateRange: {
        field: "entry_stamp",
        start: start,
        end: end
      },
      first: 10000
    };

    return variable;
  };

  const [getCicos, { data: cicosData }] = useLazyQuery(GET_CICOS_NO_GEOFENCE, {
    //variables: getCicoVariables(),
    // fetchPolicy: "cache-first",
    // skip: !vehicleInfo,
    onError: () => {
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          history.push({
            pathname: "/trips"
          });
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          history.push({
            pathname: "/trips"
          });
        }
      });
    }
  });

  const getVariables = () => {
    // const { trip_status } = parsedTripData;
    const start = moment(computeStartTime(parsedTripData.pickups[0]))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let end = moment()
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    if (parsedTripData.trip_status.toLowerCase() === "completed") {
      const lastDropoff =
        parsedTripData.dropoffs[parsedTripData.dropoffs.length - 1];
      end = moment(
        lastDropoff.actual_departure
          ? lastDropoff.actual_departure
          : lastDropoff.manual_departure
      )
        .subtract(8, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    const platenoObj = {
      field: "plateno.keyword",
      value:
        vehicleInfo?.vehicle_info?.plateno || parsedTripData.vehicle_plate_no
    };
    const variable = {
      // filter: { field: "device_id", value: parsedTripData.vehicle_id },
      filter: vehicleInfo?.vehicle_info?.id ? [] : [platenoObj],
      or: vehicleInfo?.vehicle_info?.id
        ? [
            {
              field: "vehicle_id",
              value: vehicleInfo?.vehicle_info?.id || ""
            },
            platenoObj
          ]
        : [],
      exists: ["alert_msgs"],
      dateFilter: {
        field: "datestamp",
        gte: start,
        lte: end
      },
      first: 10000
    };

    // if (trip_status !== "Completed")
    //   variable = {
    //     ...variable,
    //     or: [
    //       { field: "alert_msgs", value: "Left" },
    //       { field: "alert_msgs", value: "Entered" }
    //     ]
    //   };

    return variable;
  };

  const [getTripLogs, { loading, data: tripLogData }] = useLazyQuery(
    GET_TRIP_LOG_V2,
    {
      // variables: getVariables(),
      // fetchPolicy: "cache-first",
      skip: !vehicleInfo,
      onError: () => {
        Swal.fire({
          title: "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            history.push({
              pathname: "/trips"
            });
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            history.push({
              pathname: "/trips"
            });
          }
        });
      }
    }
  );

  useEffect(() => {
    if (cicosData) {
      let copyArr = [...cicosData?.get_cicos?.cicos];

      if (year !== "2022") {
        setItinerary(copyArr);
      }
    }
  }, [cicosData]);

  useEffect(() => {
    if (vehicleInfo) {
      getTripLogs({
        variables: getVariables()
      });
      if (year !== "2022") {
        getCicos({
          variables: getCicoVariables()
        });
      }
    }
  }, [vehicleInfo]);

  const getGeoVars = () => {
    const v = { first: 100 };

    if (bbox) {
      v.bbox = bbox;
    }
    return v;
  };

  const { data: dataHistory } = useQuery(GET_GEOFENCES, {
    variables: getGeoVars(),
    // skip: !bbox
    skip: !filters.geofences
  });

  const getGeoVarsStops = () => {
    const v = { filter: geofencesStops, first: 100 };
    return v;
  };

  const { data: dataStops } = useQuery(GET_GEOFENCES, {
    variables: getGeoVarsStops(),
    skip: skip
  });

  const [geofenceName, setGeofenceName] = useState(null);

  useQuery(GET_GEOFENCES, {
    variables: { filter: { field: "name", value: geofenceName } },
    skip: !geofenceName,
    onCompleted: data => {
      if (data) {
        const { geofences: geoInfo } = data.get_geofences;
        if (geoInfo.length > 0) {
          const { location } = geoInfo[0];
          if (location) {
            const { lat, lon } = location;
            setCenter({ latitude: lat, longitude: lon });
          }
        }
      }
    }
  });

  useEffect(() => {
    let historyGeo = [];
    let stopsGeo = [];
    if (dataStops) {
      stopsGeo = dataStops.get_geofences.geofences;
    }

    if (dataHistory) {
      historyGeo = dataHistory.get_geofences.geofences;
    }

    const list = historyGeo.concat(stopsGeo);
    setGeofenceList(list);
  }, [dataHistory, dataStops]);

  const { subscribeToMore, loading: deviceLoading } = useQuery(GET_DEVICE, {
    variables: {
      id: parsedTripData.vehicle_id
    },
    onCompleted(data) {
      if (data.get_device?.device_status) {
        setVehicleInfo(data.get_device);
        const { latitude, longitude } = data.get_device.device_status;
        setCenter({ latitude: latitude, longitude: longitude });
      }
    },
    onError: () => {
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          history.push({
            pathname: "/trips"
          });
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          history.push({
            pathname: "/trips"
          });
        }
      });
    }
  });

  const subscribeToNewVehicleLocation = () => {
    const unsubscribe = subscribeToMore({
      document: VEHICLE_SUBSCRIPTION,
      variables: {
        filter: [
          {
            field: "devicealias",
            value: parsedTripData.vehicle_plate_no
          }
        ]
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newLocation = subscriptionData.data.device_updated;
        const {
          latitude,
          longitude
        } = subscriptionData.data.device_updated.device_status;
        setVehicleInfo(newLocation);
        setCenter({ latitude: latitude, longitude: longitude });
        return newLocation;
      }
    });
    return unsubscribe;
  };

  const parseAlert = (alert, datestamp) => {
    const alertArray = alert.split(" ");
    const action = alertArray[0].toLowerCase().includes("left")
      ? "Left"
      : "Entered";
    alertArray.shift();
    const geofenceN = alertArray.join(" ");

    return {
      geofenceName: geofenceN,
      // [action]: moment(datestamp)
      //   .add(8, "hours")
      //   .format("YYYY-MM-DD hh:mm:ss A")
      [action]: datestamp
    };
  };

  const handleCenter = name => {
    const geofence = geofenceList.filter(geo => geo.name === name);
    if (geofence.length > 0) {
      const { location } = geofence[0];
      if (location) {
        const { lat, lon } = location;
        setCenter({ latitude: lat, longitude: lon });
      }
    } else {
      setGeofenceName(name);
    }
  };

  const handleVehicleCenter = () => {
    const { latitude, longitude } = vehicleInfo.device_status;
    setCenter({ latitude: latitude, longitude: longitude });
  };

  const getNextStop = () => {
    const { pickups, dropoffs } = parsedTripData;
    let nextStop = new window.google.maps.LatLng(
      14.637041357136862,
      121.04160785047702
    );
    let geoId = null; // webcast lat long
    if (pickups.some(pickup => pickup.status_code_id === 2)) {
      geoId = pickups.filter(pickup => pickup.status_code_id === 2)[0]
        .geofence_id;
    } else if (dropoffs.some(dropoff => dropoff.status_code_id === 2)) {
      geoId = dropoffs.filter(dropoff => dropoff.status_code_id === 2)[0]
        .geofence_id;
    }

    if (geoId) {
      let temp = "";
      temp = geofenceList.filter(geofence => geofence.id === geoId);
      if (temp.length > 0) {
        nextStop = new window.google.maps.LatLng(
          temp[0].location.lat,
          temp[0].location.lon
        );
      }
    }

    return nextStop;
  };

  useEffect(() => {
    if (
      vehicleInfo?.device_status?.latitude ||
      vehicleInfo?.device_status?.longitude
    ) {
      if (
        parsedTripData.trip_status === "Incoming" ||
        parsedTripData.trip_status === "In Transit"
      ) {
        if (!directions && geofenceList.length > 0) {
          const DirectionsService = new window.google.maps.DirectionsService();
          DirectionsService.route(
            {
              origin: new window.google.maps.LatLng(
                vehicleInfo.device_status.latitude,
                vehicleInfo.device_status.longitude
              ),
              destination: getNextStop(),
              travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              }
            }
          );
        }
      }
    }
  }, [vehicleInfo, geofenceList]);

  useEffect(() => {
    if (parsedTripData) {
      const { trip_status } = parsedTripData;
      if (trip_status !== "Incoming" || trip_status !== "In Transit") {
        setDirections(null);
      }
    }
  }, [parsedTripData]);

  useEffect(() => {
    const unsubscribe = subscribeToNewVehicleLocation();
    return () => unsubscribe();
  });

  useEffect(() => {
    const temp = [];
    const tempId = [];
    const { pickups, dropoffs } = parsedTripData;
    pickups.map(pickup => {
      temp.push({ field: "id", value: pickup.geofence_id });
      tempId.push(pickup.geofence_id);
      return true;
    });
    dropoffs.map(dropoff => {
      temp.push({ field: "id", value: dropoff.geofence_id });
      tempId.push(dropoff.geofence_id);
      return true;
    });
    setGeofencesStops(temp);
    setTempIds(tempId);
  }, []);

  useEffect(() => {
    if (tripLogData) {
      const { get_trip_log_v2 } = tripLogData;
      if (get_trip_log_v2.length > 0) {
        let tempPoints = [];
        if (get_trip_log_v2.length > 0) {
          tempPoints = get_trip_log_v2.map(point => {
            const { lat, lon } = point.location;
            return { lat: lat, lng: lon };
          });
        }
        setPoints(tempPoints);

        if (year === "2022") {
          setItinerary([]);
        }
        if (get_trip_log_v2.length > 0) {
          const tempItinerary = [];
          const alertAddresses = [];

          get_trip_log_v2
            .filter(trip_log => {
              let needed = false;
              if (
                trip_log.alert_msgs.filter(
                  alert_msg =>
                    alert_msg.toLowerCase().includes("left") ||
                    alert_msg.toLowerCase().includes("enter")
                ).length > 0
              ) {
                needed = true;
              }
              return needed && trip_log.alert_codes.length > 0;
            })
            .map(trip_log =>
              trip_log.alert_msgs
                .filter(
                  alert =>
                    alert.toLowerCase().includes("left") ||
                    alert.toLowerCase().includes("enter")
                )
                .map(alert => {
                  tempItinerary.push(parseAlert(alert, trip_log.datestamp));

                  const alertArray = alert.split(" ");
                  alertArray.shift();
                  alertAddresses.push(alertArray.join(" "));

                  // alertAddresses.push(
                  //   alert.includes("Left")
                  //     ? alert.replace("Left ", "")
                  //     : alert.replace("Enter", "").trim()
                  // );
                  return true;
                })
            );
          const asd = [];
          _.uniq(alertAddresses).map(adr => {
            // band aid solution for trip logs error
            const item = [];
            tempItinerary
              .filter(i => i.geofenceName === adr)
              .map((i, index) => {
                if (index === 0) {
                  item.push(i);
                } else {
                  const alert =
                    "Left" in
                    tempItinerary.filter(g => g.geofenceName === adr)[index]
                      ? "left"
                      : "enter";
                  const alertPrev =
                    "Left" in
                    tempItinerary.filter(g => g.geofenceName === adr)[index - 1]
                      ? "left"
                      : "enter";
                  if (alert !== alertPrev) item.push(i);
                }
                return true;
              });
            // up to here
            // const item = tempItinerary.filter(i => i.geofenceName === adr);
            if (item.length > 1) {
              if (item.length % 2 === 0) {
                if ("Left" in item[0]) {
                  asd.push({ ...item[0] });
                  asd.push({ ...item[item.length - 1] });
                  for (let i = 1; i < item.length - 2; i += 2) {
                    asd.push({ ...item[i], ...item[i + 1] });
                  }
                } else {
                  for (let i = 0; i < item.length; i += 2) {
                    asd.push({ ...item[i], ...item[i + 1] });
                  }
                }
              } else if (item.length % 2 === 1) {
                if ("Left" in item[0]) {
                  asd.push({ ...item[0] });
                  for (let i = 1; i < item.length; i += 2) {
                    asd.push({ ...item[i], ...item[i + 1] });
                  }
                } else {
                  for (let i = 0; i < item.length - 1; i += 2) {
                    asd.push({ ...item[i], ...item[i + 1] });
                  }
                  asd.push({ ...item[item.length - 1] });
                }
              }
            } else {
              asd.push({ ...item[0] });
            }
            return true;
          });
          if (year === "2022") {
            setItinerary(asd);
          }
          const temp = geofences;
          temp.push({ field: "name.keyword", value: _.uniq(alertAddresses) });
          temp.push({
            field: "address.keyword",
            value: _.uniq(alertAddresses)
          });
          setGeofences(temp);
          setSkip(false);
        }
      } else {
        setSkip(false);
      }
    }
  }, [tripLogData]);

  const firstLastLocation = () => {
    let firstLastLoc = { firstLocation: null, lastLocation: null };
    if (tripLogData) {
      const { get_trip_log_v2 } = tripLogData;
      if (get_trip_log_v2.length > 0) {
        firstLastLoc = {
          firstLocation: get_trip_log_v2[0].location,
          lastLocation: get_trip_log_v2[get_trip_log_v2.length - 1].location
        };
      }
    }

    return firstLastLoc;
  };

  const renderMarker = name => {
    let marker = {
      marker: `${process.env.PUBLIC_URL}/assets/Markers/Side Geo.png`,
      color: "#b1b1b1"
    };
    if (parsedTripData.pickups.some(pickup => pickup.name === name)) {
      marker = {
        marker: `${process.env.PUBLIC_URL}/assets/Markers/Pickup.png`,
        color: "#a81ea1"
      };
    } else if (parsedTripData.dropoffs.some(dropoff => dropoff.name === name)) {
      marker = {
        marker: `${process.env.PUBLIC_URL}/assets/Markers/Dropoff 1.png`,
        color: "#4386c4"
      };
    }
    return marker;
  };

  const handleBbox = geopath => {
    setBbox(geopath);
  };

  const handleCenterOnDrag = (lat, lng) => {
    setCenter({ latitude: lat, longitude: lng });
  };

  const handleZoom = zoomLevel => {
    setZoom(zoomLevel);
  };

  // (geofences.length < 1 || geofencesStops.length < 1) ||

  // geoLoading ||
  // geoLoadingStops ||
  // geofenceInfoLoading
  return loading || deviceLoading ? (
    <Loading />
  ) : (
    <>
      {parsedTripData && (
        <Grid container style={{ height: 400, overflow: "hidden" }}>
          <Grid item xs={3}>
            <Milestones
              parsedTripData={parsedTripData}
              vehicleInfo={vehicleInfo}
              itinerary={itinerary}
              handleCenter={handleCenter}
              tab={tab}
              setTab={setTab}
              renderMarker={renderMarker}
              handleVehicleCenter={handleVehicleCenter}
            />
          </Grid>
          <Grid item xs={9}>
            <MapComponent
              parsedTripData={parsedTripData}
              vehicleInfo={vehicleInfo}
              center={center}
              geofenceList={geofenceList}
              tempIds={tempIds}
              firstLastLocation={firstLastLocation()}
              renderMarker={renderMarker}
              points={points}
              handleBbox={handleBbox}
              handleCenterOnDrag={handleCenterOnDrag}
              zoom={zoom}
              handleZoom={handleZoom}
              filters={filters}
              setFilters={setFilters}
              directions={directions}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Milestones = props => {
  const theme = useTheme();
  const {
    parsedTripData,
    vehicleInfo,
    itinerary,
    handleCenter,
    tab,
    setTab,
    renderMarker,
    handleVehicleCenter
  } = props;
  const { dropoffs, pickups } = parsedTripData;
  const [expanded, setExpanded] = useState("");
  const [lastLoc, setLastLoc] = useState("");
  const user = JSON.parse(Cookies.get("user"));
  const [expandArr, setExpandArr] = useState([]);
  const [getTextualLocation] = useLazyQuery(GET_TEXTUAL_LOCATION);

  const displayGeofenceName = itinerary => {
    if (itinerary.hasOwnProperty("geofenceName")) {
      if (itinerary?.geofenceName.length > 30) {
        return `${itinerary?.geofenceName.substring(0, 25)}...`;
      } else {
        return itinerary?.geofenceName;
      }
    } else {
      if (itinerary.hasOwnProperty("geofence_name")) {
        if (itinerary?.geofence_name.length > 30) {
          return `${itinerary?.geofence_name.substring(0, 25)}...`;
        } else {
          return itinerary?.geofence_name;
        }
      }
    }
  };
  const displayEntryStamp = itinerary => {
    if (itinerary.hasOwnProperty("Entered")) {
      if (itinerary?.Entered === "") {
        return "";
      } else {
        return `${moment(itinerary?.Entered)
          .add(8, "hours")
          .format("MMM DD, YYYY hh:mm A")}`;
      }
    } else if (itinerary.hasOwnProperty("entry_stamp")) {
      return `${moment(itinerary?.entry_stamp).format("MMM DD, YYYY hh:mm A")}`;
    } else return "";
  };

  const displayLeftStamp = itinerary => {
    if (itinerary.hasOwnProperty("Left")) {
      return `${moment(itinerary?.Left)
        .add(8, "hours")
        .format("MMM DD, YYYY hh:mm A")}`;
    } else if (itinerary.hasOwnProperty("left_stamp")) {
      if (moment(itinerary?.left_stamp).format("YYYY") === "1111") {
        return "";
      } else {
        return `${moment(itinerary?.left_stamp).format(
          "MMM DD, YYYY hh:mm A"
        )}`;
      }
    } else return "";
  };

  useEffect(() => {
    if (!vehicleInfo?.device_status) return;
    const { latitude, longitude } = vehicleInfo.device_status;
    const { trip_status } = parsedTripData;
    async function fetchData() {
      if (latitude && longitude) {
        // Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
        // const res = await Geocode.fromLatLng(latitude, longitude).then(
        //   response => {
        //     return response.results[0].formatted_address;
        //   },
        //   // eslint-disable-next-line no-unused-vars
        //   error => {
        //     return "";
        //   }
        // );

        getTextualLocation({
          variables: {
            points: {
              lat: latitude,
              lon: longitude
            },
            group_ids: user.group_ids
          }
        }).then(e => {
          setLastLoc(e.data.get_textual_location);
        });
      }
    }

    if (
      trip_status === "Processing" ||
      trip_status === "Completed" ||
      trip_status === "Closed"
    ) {
      setLastLoc("N/A");
    } else {
      fetchData();
    }
  }, [vehicleInfo]);

  const handleExpand = id => {
    // set expand for expandmore or less icon
    const expandTemp = expandArr;
    let newList = [];

    if (!expandTemp.includes(id)) {
      expandTemp.push(id);
    } else {
      // expandTemp.filter((nameId, index) => {
      //   expandTemp.splice(_.isEqual(nameId, id), 1);
      // });

      newList = expandTemp.filter(nameId => nameId !== id);
      setExpandArr(newList);
    }

    if (!expandTemp.includes(id)) setExpandArr(expandTemp);

    setExpanded(expanded === id ? "" : id);
  };

  const getActualArrival = info => {
    let time = "-";
    const { manual_arrival, actual_arrival } = info;
    time = manual_arrival || time;
    time = actual_arrival || time;
    // switch (status_code_id) {
    //   case 27:
    //     time = manual_arrival;
    //     break;
    //   case 4:
    //     time = actual_arrival;
    //     break;
    //   default:
    //     break;
    // }
    if (time !== "-") {
      time = moment(time).format("MMM DD, YYYY hh:mm A");
    }
    return time;
  };

  const getActualDeparture = info => {
    let time = "-";
    const { manual_departure, actual_departure } = info;
    time = manual_departure || time;
    time = actual_departure || time;
    // const { status_code_id, manual_departure, actual_departure } = info;
    // switch (status_code_id) {
    //   case 27:
    //     time = manual_departure;
    //     break;
    //   case 4:
    //     time = actual_departure;
    //     break;
    //   default:
    //     break;
    // }
    if (time !== "-") {
      time = moment(time).format("MMM DD, YYYY hh:mm A");
    }
    return time;
  };

  const reorder = (a, b) => {
    const first = a.Entered
      ? moment(a.Entered).format("YYYY-MM-DD HH:mm:ss")
      : moment("1970-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss");
    const second = b.Entered
      ? moment(b.Entered).format("YYYY-MM-DD HH:mm:ss")
      : moment("1970-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss");

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const renderStops = () => {
    return (
      <>
        {pickups.map((pickup, index) => (
          <Grid
            key={pickup.geofence_id}
            container
            // key={pickup.trip_id}
            style={{ width: "100%", paddingRight: 16 }}
          >
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#8800a0",
                  borderRadius: 5,
                  padding: "0 6px"
                }}
              >
                <Typography style={{ color: "white", fontSize: 13 }}>
                  {index + 1}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={10} style={{ display: "flex" }}>
              <Typography
                style={{
                  fontSize: 13,
                  color:
                    moment(getActualArrival(pickup)) > moment(pickup.arrival)
                      ? "#EA4335"
                      : theme.palette.primary.dark
                }}
                onClick={() => {
                  handleCenter(pickup.name);
                }}
              >{`${pickup.geofence_code}-${pickup.name}`}</Typography>
              <div style={{ flexGrow: 1 }} />
              <FiberManualRecordIcon
                style={{
                  fontSize: 18,
                  marginTop: 3,
                  color: statusColor(pickup.status_code_id)
                }}
              />
              {/* <ExpandMoreIcon
                onClick={() => handleExpand(pickup.trip_id)}
                style={{ color: theme.palette.primary.main }}
              /> */}
              {expandArr.includes(pickup.name) ? (
                <ExpandLessIcon
                  onClick={() => handleExpand(pickup.name)}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer"
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  onClick={() => handleExpand(pickup.name)}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer"
                  }}
                />
              )}
            </Grid>
            <Grid item xs={2} style={{ padding: "4px 0" }}>
              <div
                style={{
                  width: "50%",
                  height: "100%",
                  borderRight: "2px #c9c3c9 dashed"
                }}
              />
            </Grid>
            <Grid item xs={10} style={{ paddingRight: 16, margin: "16px 0" }}>
              <>
                {expandArr.includes(pickup.name) && (
                  <div style={{ width: "100%" }}>
                    <Typography
                      style={{
                        fontSize: 10
                      }}
                    >
                      {pickup.location_details}
                    </Typography>
                    <div>
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          pading: "16px 32px 16px 16px",
                          margin: "16px 0"
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Planned Time Arrival:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {pickup.arrival
                              ? moment(pickup.arrival).format(
                                  "MMM DD, YYYY hh:mm A"
                                )
                              : "-"}
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Actual Time Arrival:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {getActualArrival(pickup)}
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Actual Departure:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {getActualDeparture(pickup)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <hr style={{ width: "100%" }} />
              </>
            </Grid>
          </Grid>
        ))}
        {dropoffs.map((dropoff, index) => (
          <Grid
            key={dropoff.geofence_id}
            container
            // key={dropoff.trip_id}
            style={{ width: "100%", paddingRight: 16 }}
          >
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "blue",
                  borderRadius: 5,
                  padding: "0 6px"
                }}
              >
                <Typography style={{ color: "white", fontSize: 13 }}>
                  {index + 1}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={10} style={{ display: "flex" }}>
              <Typography
                style={{
                  fontSize: 13,
                  color:
                    moment(getActualArrival(dropoff)) > moment(dropoff.arrival)
                      ? "#EA4335"
                      : theme.palette.primary.dark
                }}
                onClick={() => {
                  handleCenter(dropoff.name);
                }}
              >{`${dropoff.geofence_code}-${dropoff.name}`}</Typography>
              <div style={{ flexGrow: 1 }} />
              <FiberManualRecordIcon
                style={{
                  fontSize: 18,
                  marginTop: 3,
                  color: statusColor(dropoff.status_code_id)
                }}
              />
              {/* <ExpandMoreIcon
                onClick={() => handleExpand(dropoff.trip_id)}
                style={{ color: theme.palette.primary.main }}
              /> */}
              {expandArr.includes(dropoff.name) ? (
                <ExpandLessIcon
                  onClick={() => handleExpand(dropoff.name)}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer"
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  onClick={() => handleExpand(dropoff.name)}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer"
                  }}
                />
              )}
            </Grid>
            <Grid item xs={2} style={{ padding: "4px 0" }}>
              {dropoffs.length !== index + 1 && (
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    borderRight: "2px #c9c3c9 dashed"
                  }}
                />
              )}
            </Grid>
            <Grid item xs={10} style={{ paddingRight: 16, margin: "16px 0" }}>
              <>
                {expandArr.includes(dropoff.name) && (
                  <div style={{ width: "100%" }}>
                    <Typography style={{ fontSize: 10 }}>
                      {dropoff.location_details}
                    </Typography>
                    <div>
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          pading: "16px 32px 16px 16px",
                          margin: "16px 0"
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Planned Time Arrival:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {dropoff.arrival
                              ? moment(dropoff.arrival).format(
                                  "MMM DD, YYYY hh:mm A"
                                )
                              : "-"}
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Actual Time Arrival:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {getActualArrival(dropoff)}
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12 }}>
                            Actual Departure:
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography style={{ fontSize: 12 }}>
                            {getActualDeparture(dropoff)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <hr style={{ width: "100%" }} />
              </>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const renderHistory = () => {
    return itinerary.length > 0 ? (
      itinerary.sort(reorder).map((itin, index) => (
        <Grid
          key={`${itin}--${index}`}
          container
          style={{ width: "100%", marginTop: index === 0 ? "16px" : 0 }}
        >
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                width: "50%",
                //background: renderMarker(itin.geofenceName).color,
                background: itin.hasOwnProperty("geofenceName")
                  ? renderMarker(itin.geofenceName).color
                  : renderMarker(itin.geofence_name).color,
                display: "flex",
                borderRadius: 5,
                justifyContent: "center"
              }}
            >
              <Typography style={{ color: "white" }}>{index + 1}</Typography>
            </div>
          </Grid>
          <Grid item xs={10} style={{ display: "flex" }}>
            <Typography
              style={{ fontSize: 16 }}
              onClick={() => {
                if (itin.hasOwnProperty("geofenceName")) {
                  handleCenter(itin?.geofenceName);
                } else {
                  handleCenter(itin?.geofence_name);
                }
              }}
            >
              {/* {itin?.geofenceName.length > 30
                ? `${itin?.geofenceName.substring(0, 25)}...`
                : itin?.geofenceName} */}
              {displayGeofenceName(itin)}
            </Typography>
            <div style={{ flexGrow: 1 }} />
            {expanded === index ? (
              <ExpandLessIcon
                onClick={() => handleExpand(index)}
                style={{ color: theme.palette.primary.main, cursor: "pointer" }}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => handleExpand(index)}
                style={{ color: theme.palette.primary.main, cursor: "pointer" }}
              />
            )}
          </Grid>
          <Grid item xs={2} style={{ padding: "4px 0" }}>
            {index < itinerary.length - 1 && (
              <div
                style={{
                  width: "50%",
                  height: "100%",
                  borderRight: "2px #c9c3c9 dashed"
                }}
              />
            )}
          </Grid>
          <Grid item xs={10} style={{ paddingRight: 16, margin: "0 0 16px" }}>
            <>
              {expanded === index && (
                <div style={{ width: "100%" }}>
                  <Typography style={{ fontSize: 10 }}>
                    {itin.hasOwnProperty("geofenceName")
                      ? itin?.geofenceName
                      : itin?.geofence_name}
                  </Typography>
                  <div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        pading: "16px 32px 16px 16px",
                        margin: "16px 0"
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Typography style={{ fontSize: 12 }}>
                          Entry Time:
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography style={{ fontSize: 12 }}>
                          {displayEntryStamp(itin)}
                          {/* {itin.hasOwnProperty("Entered")
                            ? moment(itin.Entered)
                                .add(8, "hours")
                                .format("MMM DD, YYYY hh:mm A")
                            : moment(itin.entry_stamp)
                                // .add(8, "hours")
                                .format("MMM DD, YYYY hh:mm A")} */}
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Typography style={{ fontSize: 12 }}>
                          Exit Time:
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography style={{ fontSize: 12 }}>
                          {displayLeftStamp(itin)}
                          {/* {itin.hasOwnProperty("Left")
                            ? moment(itin?.Left)
                                .add(8, "hours")
                                .format("MMM DD, YYYY hh:mm A")
                            : moment(itin?.left_stamp)
                                // .add(8, "hours")
                                .format("MMM DD, YYYY hh:mm A")} */}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <hr style={{ width: "100%" }} />
            </>
          </Grid>
        </Grid>
      ))
    ) : (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography style={{ fontSize: 13, color: theme.palette.primary.dark }}>
          No Vehicle History
        </Typography>
      </div>
    );
  };

  const locationEnabled = () => {
    let enabled = true;
    const { trip_status } = parsedTripData;
    if (
      trip_status === "Processing" ||
      trip_status === "Completed" ||
      trip_status === "Closed"
      // ||
      // vehicleInfo.device_status.status_code === "Inactive"
    ) {
      enabled = false;
    }
    return enabled;
  };

  const locationButton = locationEnabled();
  return (
    <div>
      {!!vehicleInfo?.device_status && (
        <div style={{ padding: 16 }}>
          <Typography
            style={{ fontSize: 11, color: theme.palette.primary.dark }}
          >{`As of ${moment(vehicleInfo.device_status.reportstamp).format(
            "MMM DD, YYYY hh:mm A"
          )}`}</Typography>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "flex", padding: "4px 4px 4px 0" }}>
              <MyLocationIcon
                style={{
                  fontSize: "1rem",
                  // color: locationButton ? "#60b5f9" : "black",
                  color: theme.palette.primary.main,
                  cursor: locationButton ? "pointer" : "default"
                }}
                onClick={() => locationButton && handleVehicleCenter()}
              />
            </div>
            <div>
              <Typography
                style={{
                  fontSize: 13,
                  marginTop: 3,
                  color: theme.palette.primary.dark
                }}
              >
                Current Location:
              </Typography>
              <Typography
                style={{ fontSize: 11, color: theme.palette.primary.dark }}
              >
                {lastLoc}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          textAlign: "center",
          paddingBottom: 8,
          cursor: "pointer"
        }}
      >
        {[
          { display: "Stops", id: "stops" },
          { display: "View History", id: "history" }
        ].map(item => (
          <Paper
            key={item.id}
            variant="outlined"
            square
            style={{
              width: "50%",
              padding: "8px 0",
              backgroundColor:
                tab === item.id ? theme.palette.primary.main : "white"
            }}
          >
            <Typography
              style={{
                color: tab === item.id ? "white" : "#A2A2A2",
                fontSize: 13,
                fontFamily: "Nunito"
              }}
              onClick={() => setTab(item.id)}
            >
              {item.display}
            </Typography>
          </Paper>
        ))}
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ overflowY: "auto", maxHeight: 210, paddingTop: 8 }}>
          {tab === "stops" ? renderStops() : renderHistory()}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MapView);
