import moment from "moment";

const isWithinRange = (basis_date, date) => {
  if (!date) return;
  const testing_date = moment(date);
  const start_date = moment(basis_date.start).subtract(8, "hours");
  const end_date = moment(basis_date.selectedRange || basis_date.end).subtract(
    8,
    "hours"
  );

  return testing_date.isBetween(start_date, end_date, undefined, "[]");
};

export default isWithinRange;
