import { makeStyles } from "@material-ui/core";

export default makeStyles({
  draggableItem: {
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: props => (props.hidden ? "0px" : "2px 10px"),
    fontSize: 12,
    letterSpacing: "1px",
    fontFamily: "Nunito",
    transition: "all 200ms ease-in-out",
    borderBottom: props =>
      !props.draggable
        ? !props.hidden
          ? "1px solid rgb(0 0 0 / 15%)"
          : ""
        : "1px solid rgb(0 0 0 / 15%)",
    // borderTop: props => (props.draggable ? "0px solid transparent" : "none"),
    borderTop: "0px solid transparent !important",
    // paddingTop: props => (!props.draggable ? 25 : "auto"),
    "&.drag-over": {
      borderTop: "25px solid #f1f1f16b !important"
    },
    background: props =>
      !props.draggable && props.hidden ? "#f3f3f3" : "inherit",
    // marginBlockEnd: props => (props.hidden ? "24px" : "0px")
  }
});
