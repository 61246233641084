/* global google */
import React, { createRef } from "react";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import Loading from "../../../../../utils/Loading";

export const libraries = ["geometry", "drawing", "places"];

export const DEFAULT_CENTER = {
  lat: 12.248727944234455,
  lng: 121.95099687500002
};
export const MAX_ZOOM = 15;
export const DEFAULT_ZOOM = 6;
export const ZOOM = null;
export const MAP_REFERENCE = createRef();
export const MAP_MARKERS = createRef();

const InnerMap = props => {
  const {
    onMapBoundsChanged,
    devices,
    center,
    currentLoggedInUser,
    children,
    searchedCoordinates,
    fitVehicleMarkers
  } = props;
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    channel: `tmsuite-portal-${currentLoggedInUser.client.name}`,
    version: "3.47",
    libraries
  });

  if (!isLoaded) return <Loading />;

  const DEFAULT_OPTIONS = {
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.LEFT_BOTTOM,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE,
        google.maps.MapTypeId.HYBRID
      ]
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        height: `calc(100vh - 128px)`
      }}
      zoom={
        devices.length === 1 || searchedCoordinates
          ? ZOOM || MAX_ZOOM
          : ZOOM || DEFAULT_ZOOM
      }
      maxZoom={MAX_ZOOM}
      center={center}
      options={DEFAULT_OPTIONS}
      // onIdle={onMapBoundsChanged}
      onLoad={map => {
        MAP_REFERENCE.current = map;
        if (devices.length > 0) {
          fitVehicleMarkers();
        }
        setIsMapLoaded(true);
      }}
      ref={map => {
        if (map) {
          MAP_MARKERS.current = devices.map(device => {
            if (!device) return null;

            const { latitude, longitude } = device.device_status;
            if (latitude && longitude) {
              return new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                title: device.client_id?.toString()
              });
            }
            return null;
          });

          return map;
        }
        return null;
      }}
      onDragEnd={onMapBoundsChanged}
      onZoomChanged={onMapBoundsChanged}
    >
      {isLoaded && isMapLoaded && children}
    </GoogleMap>
  );
};

export default InnerMap;
