import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  wrapper: {
    // maxHeight: "calc(100% - 66px)",
    padding: "0px",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
  },
  container_body: {
    position: "relative",
    // display: "flex",
    overflow: "auto",
    padding: "25px 25px 50px 25px",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    height: "calc(100vh - 66px - 66px)"
  },
  map_body: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    padding: "25px 25px 50px 25px",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    height: "calc(100vh - 66px - 66px)"
  },
  map_container: {
    position: "relative",
    height: "100%",
    width: "50%",
    overflow: "hidden"
  },
  form_container: {
    position: "relative",
    height: "100%",
    width: "50%",
    padding: theme.spacing(3),
    boxSizing: "border-box",
    overflowY: "auto"
  },
  searchRoot: {
    padding: "0px 9px",
    display: "flex",
    alignItems: "center",
    width: 325,
    height: 36,
    backgroundColor: "#fafafa",
    borderRadius: "25px",
    boxShadow: "none",
    border: "1px solid #ccc"
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 600,
    fontSize: 14,
    "& .MuiInputBase-input": {
      letterSpacing: 0.7
    }
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  addIcon: {
    width: "50px",
    height: "50px",
    color: "white"
  },
  // container: {
  //   height: "calc(100% - 66px)",
  //   // margin: theme.spacing(2, 4),
  //   flexGrow: 1,
  //   boxSizing: "border-box",
  //   padding: theme.spacing(3),
  //   overflow: "auto",
  //   background: "#F8F8F8",
  //   backgroundColor: "black"
  //   // background: "#cfe8fc"
  // },
  // box: {
  //   height: "80vh",
  //   flexGrow: 1,
  //   boxSizing: "border-box",
  //   padding: theme.spacing(3),
  //   overflow: "auto",
  //   background: "white"
  //   // background: "#cfe8fc"
  // },
  // wrapper: {
  //   minHeight: "100%",
  //   height: "auto",
  //   boxSizing: "border-box",
  //   padding: theme.spacing(3),
  //   borderRadius: 2,
  //   border: "1px solid #ccc",
  //   background: "white"
  // },
  field: {
    margin: "18px 0px 0px 20px",
    width: "85%",
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      opacity: 1
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "12px 0"
    }
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "85%",
    margin: "0px 0px 0px 20px"
  },
  mediumTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#565656",
    letterSpacing: 0.7
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  switch: {
    margin: "18px 0 0 20px",
    display: "flex",
    alignItems: "center"
  },
  partners: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "18px 0 0 20px"
  },
  table: {
    height: "35vh"
  },
  tablePaper: {
    overflow: "auto",
    margin: "18px 0 0 20px"
  },
  addEntryButton: {
    fontSize: 12,
    letterSpacing: 0.7,
    fontWeight: 600,
    margin: "0px 20px 0 0",
    textTransform: "capitalize"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  drawing_mode_btn: {
    minWidth: "auto",
    margin: "0px 12px 10px 0px",
    background: "white",
    "&:hover": {
      background: "#fafafaba"
    }
  },
  zoom_control_btn_grp: {
    background: "white",
    marginRight: 15,
    transform: "translate(0px, -4px)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px"
  },
  zoom_add_btn: {
    minWidth: "auto"
  },
  zoom_minus_btn: {
    minWidth: "auto"
  }
}));
