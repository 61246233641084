import React, { useState, useEffect, useContext, createRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Button,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  InputLabel,
  CircularProgress,
  InputBase,
  Fade,
  Slide
} from "@material-ui/core";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search as SearchIcon,
  Clear
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import _ from "lodash";
import { GET_GEOFENCES } from "../../../../graphql/Queries";
import { CLONE_GEOFENCES } from "../../../../graphql/Mutations";
import useUserContext from "../../../../context/User/useUserContext";

import CloneDrawer from "../../WTI/AlertReferences/utils/CloneDrawer";
import useFilterHauler from "../../../../utils/hooks/useFilterHauler";
import useDebounce from "../../../../utils/hooks/useDebounce";
import geofencesStyle from "./styles/Geofences.style";
import AddLogs from "../../../../utils/functions/AddLogs";
import { geofenceTypes } from "../../../Utils";

function desc(a, b, orderBy) {
  const first =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const second =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

// for sorrting in geofences tabke
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const first = Object.assign({ ...a[0], name: a[0].name.toLowerCase() });
    const second = Object.assign({ ...b[0], name: b[0].name.toLowerCase() });
    const order = cmp(first, second);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// header of table inside the clone drawer
const headCellsGeofence = [
  {
    id: "geofence",
    numeric: false,
    disablePadding: true,
    label: "Geofences",
    align: "left"
  },
  {
    id: "category",
    numeric: false,
    disablePadding: true,
    label: "Category",
    align: "left"
  }
];

const CloneGeofences = props => {
  const classes = geofencesStyle();
  const user = useUserContext();

  // props to triggge the drawer and checkbox function
  const { cloneDrawer, setCloneDrawer, orderBy, isChecked } = props;

  const [cloneData, setCloneData] = React.useState();
  const [clonePage, setClonePage] = React.useState(1);
  const [selectedCloneGeo, setSelectedCloneGeo] = React.useState({
    id: [],
    name: []
  });
  const [groupOptions, setGroupOptions] = useState([]);
  const [openexistingClone, setOpenexistingClone] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [existingGeofence, setExistingGeofence] = useState();
  const [clone, setClone] = React.useState({
    from: "",
    to: "",
    category: ""
  });

  const userLevelId = user.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);

  // keyword for searching clients
  const DEBOUNCE_TIME = 800;
  const [clientFromKeyword, setClientFromKeyword] = useDebounce(
    "",
    DEBOUNCE_TIME
  );
  const [clientToKeyword, setClientToKeyword] = useDebounce("", DEBOUNCE_TIME);
  const [searchClone, setSearchClone] = useDebounce("", DEBOUNCE_TIME);

  // query the search keyword
  const clientFromOptions = useFilterHauler(clientFromKeyword);
  const clientToOptions = useFilterHauler(clientToKeyword);

  // mutation for cloning geofence from source to destiantion
  const [saveCloneGeo] = useMutation(CLONE_GEOFENCES, {
    onCompleted: data => {
      if (data.clone_geofences.success) {
        AddLogs("Admin - Geofences", "geofence_sharing", [
          selectedCloneGeo.name.map(n => n),
          clone.from.label,
          clone.to.label
        ]);
        // return if has the same geofence code already
        if (data.clone_geofences.existing_geofence_codes.length) {
          setExistingGeofence(data.clone_geofences);
          setOpenexistingClone(true);
        } else {
          // success mutation and no same geofence code
          Swal.fire({
            title: "Clone was successful",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: `${classes.my_swal}`
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              setClone("");
              setSearchClone("");
              setCloneData();
              setClonePage(1);
              setClientToKeyword("");
              setClientFromKeyword("");
              setCloneDrawer(false);
            }
          });
        }
      } else {
        // not success and return if has the same geofence code already in the destination
        if (data.clone_geofences.existing_geofence_codes.length) {
          setExistingGeofence(data.clone_geofences);
          setOpenexistingClone(true);
        }
      }
    }
  });

  useEffect(() => {
    // trigger the swal if clone was successful even with geofence code exist
    if (existingGeofence?.success && !openexistingClone) {
      Swal.fire({
        title: "Clone was successful",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: `${classes.my_swal}`
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          setClone("");
          setSearchClone("");
          setCloneData();
          setClonePage(1);
          setClientToKeyword("");
          setClientFromKeyword("");
          setCloneDrawer(false);
        }
      });
    }
  }, [openexistingClone]);

  // get the goefences of selected source
  const [cloningGeofence, { loading: loadingCloneGeo }] = useLazyQuery(
    GET_GEOFENCES
  );

  // variables that refelected to cloningGeofence
  const cloneVariables = () => {
    let condition = [];
    let not = [];
    let variables = {};
    // selected client from from field
    if (clone.from?.id) {
      // condition.push({
      //   field: "group_id",
      //   value: clone?.from?.value?.toString()
      // });
      condition.push({
        field: "client_ids",
        value: clone.from?.id?.toString()
      });
    }

    // selected client from to field
    if (clone.category) {
      condition.push({
        field: "geofences.category",
        value: [clone?.category?.toString()]
      });
    }

    variables = {
      keyword: searchClone,
      first: 10,
      skip: clonePage === 1 ? 0 : clonePage * 10 - 10,
      condition: condition,
      not: not,
      keyword_fields: [
        "name",
        "geofence_code",
        "category",
        "region",
        "province",
        "municipality"
      ],
      orderBy: [
        {
          field: "name",
          direction: "asc"
        }
      ]
    };
    return variables;
  };

  useEffect(() => {
    // get the goefences of selected source
    if (cloneDrawer && clone.from && clone.to) {
      cloningGeofence({
        variables: cloneVariables(),
        onCompleted: data => {
          setCloneData(data.get_geofences);
        }
      });
    }

    // reset the fields for new selected client
    if (!clone?.from?.id || !clone?.to?.id) {
      setCloneData("");
      setClonePage(1);
      setSelectedCloneGeo({ id: [], name: [] });
    }
  }, [clone, clonePage, searchClone]);

  // const hanldeOpenExistingDialog = () => {
  //   setOpenexistingClone(true);
  // };

  // modal for existing geofence codes
  const handleCloseExistingDialog = () => {
    setOpenexistingClone(false);
  };

  // get the name for selected geofence in table
  const isSelectedGeo = name => selectedCloneGeo.id.indexOf(name) !== -1;

  // const openCloneDrawer = () => {
  //   setCloneDrawer(true);
  // };

  // close the clone drawer and reset all fields
  const closeCloneDrawer = () => {
    setClone("");
    setSearchClone("");
    setCloneData();
    setClonePage(1);
    setClientToKeyword("");
    setClientFromKeyword("");
    setCloneDrawer(false);
  };

  // handle changes for the fields
  const handleSelectClient = (name, value) => {
    setClone({ ...clone, [name]: value });
  };

  // handle selection of geofences in the table
  const handleClickGeo = (id, name) => {
    const selectedIndex = selectedCloneGeo.id.indexOf(id);
    const selectedIndexName = selectedCloneGeo.name.indexOf(name);
    let newSelected = [];
    let newSelectedName = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCloneGeo.id, id);
      newSelectedName = newSelectedName.concat(selectedCloneGeo.name, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCloneGeo.id.slice(1));
      newSelectedName = newSelectedName.concat(selectedCloneGeo.name.slice(1));
    } else if (selectedIndex === selectedCloneGeo.id.length - 1) {
      newSelected = newSelected.concat(selectedCloneGeo.id.slice(0, -1));
      newSelectedName = newSelectedName.concat(
        selectedCloneGeo.name.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCloneGeo.id.slice(0, selectedIndex),
        selectedCloneGeo.id.slice(selectedIndex + 1)
      );
      newSelectedName = newSelectedName.concat(
        selectedCloneGeo.name.slice(0, selectedIndexName),
        selectedCloneGeo.name.slice(selectedIndexName + 1)
      );
    }

    setSelectedCloneGeo({
      ...selectedCloneGeo,
      id: newSelected,
      name: newSelectedName
    });
  };

  // hanlde checkbox heaedr for checking all geofences
  const isCheckedClone = () => {
    let checked = { all: false, indeterminate: false };
    if (!selectedCloneGeo.id.length < cloneData?.count) {
      const displayedGeo = cloneData?.geofences.map(d => parseInt(d.id, 10));
      if (displayedGeo.every(data => selectedCloneGeo?.id?.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (
        displayedGeo.some(data => selectedCloneGeo.id.indexOf(data) > -1)
      )
        checked = { all: false, indeterminate: true };
    }
    return checked;
  };

  // handle select all geofence
  const handleSelectAllGeofence = e => {
    const tempSelected = selectedCloneGeo.id;
    const tempSelectedName = selectedCloneGeo.name;
    const deselect = () => {
      const displayGeo = cloneData.geofences.map(v => parseInt(v.id, 10));
      const displayGeoName = cloneData.geofences.map(v => v.name);

      const newSelected = tempSelected
        .filter(id => displayGeo.indexOf(parseInt(id, 10)) < 0)
        .map(id => id);

      const newSelectedName = tempSelectedName
        .filter(name => displayGeoName.indexOf(name))
        .map(name => name);

      setSelectedCloneGeo({
        ...selectedCloneGeo,
        id: newSelected,
        name: newSelectedName
      });
    };

    if (e.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      cloneData.geofences.map(n => tempSelected.push(parseInt(n.id, 10)));
      cloneData.geofences.map(n => tempSelectedName.push(n.name));
      setSelectedCloneGeo({
        ...selectedCloneGeo,
        id: _.uniq(tempSelected),
        name: _.uniq(tempSelectedName)
      });
      return;
    }
    deselect();
  };

  // mutation for cloning geofence from source to destiantion
  const handleSaveClone = () => {
    saveCloneGeo({
      variables: {
        group_id_source: clone.from.value[0],
        group_id_destination: clone.to.value[0],
        geofence_ids: selectedCloneGeo.id
      }
    });
  };

  return (
    <>
      <CloneDrawer
        cloneDrawer={cloneDrawer}
        selectedClone={selectedCloneGeo.id}
        closeCloneDrawer={closeCloneDrawer}
        moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
        handleSave={handleSaveClone}
      >
        <Typography variant="subtitle1" className={classes.cloneTitle}>
          Geofences
        </Typography>
        <FormControl className={classes.field}>
          <InputLabel required shrink className={classes.InputLabel}>
            From
          </InputLabel>
          <Autocomplete
            name="from"
            options={clientFromOptions || []}
            value={clone?.from?.name}
            getOptionLabel={option => option.label || ""}
            getOptionSelected={(option, value) => option.label === value.label}
            getOptionDisabled={option =>
              (isWTIAdmin ? option?.id : option?.value?.[0]) ===
                (isWTIAdmin ? clone?.to?.id : clone?.to?.value?.[0]) ||
              option.label === "All"
            }
            onChange={(e, value) => {
              handleSelectClient("from", value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ marginTop: 16 }}
                placeholder="Select Client"
                value={clone?.from}
                classes={{ root: classes.textFieldRoot }}
                onChange={e => setClientFromKeyword(e.target.value)}
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.field}>
          <InputLabel required shrink className={classes.InputLabel}>
            To
          </InputLabel>
          <Autocomplete
            name="to"
            options={clientToOptions || []}
            getOptionLabel={option => option.label || ""}
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(e, value) => {
              handleSelectClient("to", value);
            }}
            getOptionDisabled={option =>
              (isWTIAdmin ? option?.id : option?.value?.[0]) ===
                (isWTIAdmin ? clone?.from?.id : clone?.from?.value?.[0]) ||
              option.label === "All"
            }
            renderInput={params => (
              <TextField
                {...params}
                style={{ marginTop: 16 }}
                placeholder="Select Client"
                value={clone?.to}
                classes={{ root: classes.textFieldRoot }}
                onChange={e => setClientToKeyword(e.target.value)}
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.field}>
          <InputLabel shrink className={classes.InputLabel}>
            Category
          </InputLabel>
          <Autocomplete
            name="category"
            options={geofenceTypes.map(geo => geo.label) || []}
            getOptionLabel={option => option}
            getOptionSelected={(option, value) => option === value}
            onChange={(e, value) => {
              handleSelectClient("category", value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ marginTop: 16 }}
                placeholder="Select Category"
                value={clone?.category}
                classes={{ root: classes.textFieldRoot }}
              />
            )}
          />
        </FormControl>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography style={{ textAlign: "left", paddingTop: "20px" }}>
              List of Geofences
            </Typography>
            {openSearch ? (
              <Slide in={openSearch} direction="left">
                <Paper className={classes.searchRoot}>
                  <IconButton
                    disabled
                    className={classes.iconButton}
                    style={{ padding: "5px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Search Geofence"
                    inputProps={{ "aria-label": "clone" }}
                    onChange={e => {
                      setSearchClone(e.target.value);
                      setClonePage(1);
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                    style={{ padding: "5px" }}
                    aria-label="search"
                    onClick={() => {
                      setOpenSearch(false);
                      setSearchClone("");
                    }}
                  >
                    <Clear />
                  </IconButton>
                </Paper>
              </Slide>
            ) : (
              <Paper className={classes.closeSearch}>
                <IconButton
                  className={classes.iconButton}
                  style={{ padding: "5px" }}
                  aria-label="search"
                  onClick={() => setOpenSearch(true)}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}
          </div>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      disabled={!cloneData?.count}
                      indeterminate={isCheckedClone().indeterminate}
                      checked={isCheckedClone().all}
                      onChange={handleSelectAllGeofence}
                    />
                  </TableCell>
                  {headCellsGeofence.map(cell => (
                    <TableCell align={cell.align} key={cell.id}>
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingCloneGeo ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : cloneData ? (
                  stableSort(cloneData?.geofences, getSorting(orderBy, "name"))
                    .filter(
                      row =>
                        row.name.toUpperCase().indexOf("".toUpperCase()) > -1 &&
                        row.name
                          .toLowerCase()
                          .indexOf(searchClone.toLowerCase()) > -1
                    )
                    .map(row => (
                      <TableRow>
                        <TableCell
                          padding="checkbox"
                          onClick={() => {
                            handleClickGeo(parseInt(row.id), row.name);
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isSelectedGeo(parseInt(row.id, 10))}
                          />
                        </TableCell>
                        <TableCell
                          id={row.id}
                          component="th"
                          scope="row"
                          align="left"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          id={row.category}
                          component="th"
                          scope="row"
                          align="left"
                          padding="none"
                        >
                          {row.category}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.clonePagination}>
            <Typography color="inherit" variant="subtitle1" component="div">
              {!!selectedCloneGeo.id.length &&
                `${selectedCloneGeo.id.length} geofence/s selected`}
            </Typography>
            <div>
              <IconButton
                name="page_down"
                disabled={clonePage === 1}
                onClick={() => setClonePage(clonePage - 1)}
              >
                <KeyboardArrowLeft />
              </IconButton>
              {`${Math.ceil(cloneData?.total / 10) ? clonePage : 0} / 
                ${
                  Math.ceil(cloneData?.total / 10)
                    ? Math.ceil(cloneData?.total / 10)
                    : 0
                }`}
              <IconButton
                name="page_up"
                disabled={clonePage === Math.ceil(cloneData?.total / 10)}
                onClick={() => setClonePage(clonePage + 1)}
              >
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </div>
        </div>
      </CloneDrawer>
      <Dialog
        open={openexistingClone}
        onClose={handleCloseExistingDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.cloneDialogContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography color="primary" variant="h6">
            Existing Geofence
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body2">
              The following geofences already exist in the destination group and
              cannot be cloned. If you wish to proceed the action, click Okay.
            </Typography>
            <TableContainer className={classes.existingCloneContainer}>
              <Table stickyHeader>
                <TableHead className={classes.existingTableHead}>
                  <TableRow>
                    <TableCell id="gefoence_header">Geofences</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {existingGeofence?.existing_geofence_codes &&
                    existingGeofence?.existing_geofence_codes?.map(geo => (
                      <TableRow>
                        <TableCell id={geo?.id}>{geo?.name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btn_okay}
            variant="contained"
            onClick={handleCloseExistingDialog}
            color="primary"
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CloneGeofences;
