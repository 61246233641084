/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  FormControl,
  Badge,
  InputAdornment
} from "@material-ui/core";
import { FilterList as FilterListIcon, Event } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
import SearchContext from "../../../../context/SearchContext";
import Filter from "../../Filter";
import { GET_FEEDBACKS } from "../../../../graphql/Queries";
import AddLogs from "../../../../utils/functions/AddLogs";
import useFormStyles from "../../../../styles/useFormStyles";
import AccordionCell from "./AccordionCell";
import ClientsSearchableInput from "./ClientsSearchableInput";
import UserSearchableInput from "./UserSearchableInput";
import EnhancedTableToolbar from "./EnhancedToolbar/EnhancedToolbar";
import EnhancedTableHead from "./EnhancedTableHead/EnhancedTableHead";
import useFeedbackStyles from "./Feedbacks.styles";
import Pagination from "../../../../utils/table/Pagination";

const Highlight = require("react-highlighter");

function desc(a, b, orderBy) {
  const first =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const second =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function Feedbacks() {
  const classes = useFeedbackStyles();
  const formStyles = useFormStyles();
  const contextData = useContext(SearchContext);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datestamp");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null); // eslint-disable-line no-unused-vars
  const { setSearchKeyword, searchKeyword } = contextData;
  const clientRef = useRef(null);
  const userRef = useRef(null);
  const [dateRange, setDateRange] = useState({
    from: null,
    to: null
  });



  const {
    called: calledFeedbacks,
    loading: loadingFeedbacks,
    data: feedbacks,
    // fetchMore,
    refetch,
    error
  } = useQuery(GET_FEEDBACKS, {
    variables: {
      first: rowsPerPage,
      skip: 0,
      orderBy: { field: orderBy, direction: order },
      ...(userRef.current?.label && {username: userRef.current?.label || ""}),
      ...(clientRef.current?.value && {client_id: clientRef.current?.value || ""}),
      ...(dateRange?.from &&
        dateRange?.to && {
          dateRange: {
            field: "datestamp",
            start: dateRange.from || "",
            end: dateRange.to || ""
          }
        })  
      
    },
    notifyOnNetworkStatusChange: true
  });

  const [open, setOpen] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (searchKeyword.submit) {
      setPage(0);
      setRowsPerPage(10);
      refetch({
        first: 10,
        skip: 0,
        keyword: searchKeyword.value,
        username: userRef.current?.label || "",
        client_id: clientRef.current?.value || "",
        ...(dateRange?.from &&
          dateRange?.to && {
            dateRange: {
              field: "datestamp",
              start: dateRange.from || "",
              end: dateRange.to || ""
            }
          })
      }).then(() => {
        AddLogs(
          process.env.REACT_APP_FEEDBACK_MESSAGE,
          "search",
          searchKeyword.value
        );
      });
    }
  }, [searchKeyword.submit]);

  React.useEffect(() => {
    setPage(0);
    refetch({
      first: rowsPerPage,
      skip: 0,
      ...(searchKeyword.value && {keyword: searchKeyword.value}),
      ...(userRef.current?.label && {username: userRef.current?.label || ""}),
      ...(clientRef.current?.value && {client_id: clientRef.current?.value || ""}),
      ...(dateRange?.from &&
        dateRange?.to && {
          dateRange: {
            field: "datestamp",
            start: dateRange.from || "",
            end: dateRange.to || ""
          }
        })
    });
  }, [rowsPerPage]);

  React.useEffect(() => {
    refetch({
      first: rowsPerPage,
      skip: rowsPerPage * page,
      ...(searchKeyword.value && {keyword: searchKeyword.value}),
      ...(userRef.current?.label && {username: userRef.current?.label || ""}),
      ...(clientRef.current?.value && {client_id: clientRef.current?.value || ""}),
      ...(dateRange?.from &&
        dateRange?.to && {
          dateRange: {
            field: "datestamp",
            start: dateRange.from || "",
            end: dateRange.to || ""
          }
        })
      });
  }, [page]);

  useEffect(() => {
    setSearchKeyword(prev => ({ ...prev, value: "" }));
    return () => {
      userRef.current = null;
      clientRef.current = null;
    };
  }, []);

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorEl.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getData = () => {
    if (!loadingFeedbacks && feedbacks?.get_feedback?.feedback?.length) {
      return feedbacks.get_feedback.feedback
        ? stableSort(
            feedbacks.get_feedback.feedback,
            getSorting(order, orderBy)
          )
        : // .slice(
          //   Number(page) * rowsPerPage,
          //   Number(page) * rowsPerPage + rowsPerPage
          // )
          [];
    }
    return [];
  };

  const handleSetFilters = () => {
    setPage(0);
    setRowsPerPage(10);
    refetch({
      first: 10,
      skip: 0,
      keyword: searchKeyword.value,
      username: userRef.current?.label || "",
      client_id: clientRef.current?.value || "",
      ...(dateRange?.from &&
        dateRange?.to && {
          dateRange: {
            field: "datestamp",
            start: dateRange.from || "",
            end: dateRange.to || ""
          }
        })
    }).then(() => {
      AddLogs(
        process.env.REACT_APP_FEEDBACK_MESSAGE,
        "filter",
        `Filter set: ${
          dateRange.from && dateRange.to
            ? `Date from ${dateRange.from} to ${dateRange.to}`
            : ``
        } ${clientRef.current ? `Client ${clientRef.current.label}` : ``}
          ${userRef.current ? `User ${userRef.current.label}` : ``}`
      );
      closeFilterDrawer();
    });
  };

  const handleClearFilter = () => {
    refetch({
      keyword: searchKeyword.value,
      username: "",
      client_id: "",
      dateRange: {}
    }).then(() => {
      userRef.current = null;
      clientRef.current = null;
      setDateRange({ from: null, to: null });
    });
  };

  if (error) {
    return <ErrorPage errorCode={error.networkError.response} />;
  }
  return (
    <AccessControl
      resource="/admin/activity/admin_feedback/"
      process="tables"
      setCanDelete={() => {}}
    >
      <div className={classes.root}>
        {loadingFeedbacks && calledFeedbacks && <Loading />}
        <Paper className={classes.paper}>
          <EnhancedTableToolbar>
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={openFilterDrawer}>
                <Badge
                  color="secondary"
                  variant={
                    (dateRange.from && dateRange.to) ||
                    clientRef.current ||
                    userRef.current
                      ? "dot"
                      : "standard"
                  }
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination
              rowsPerPage={rowsPerPage}
              total={Math.ceil(feedbacks?.get_feedback?.total / rowsPerPage)}
              currentPage={page}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                // setPage(0);
              }}
              onPageChange={n => setPage(n)}
              onPageUp={n => setPage(n)}
              onPageDown={n => setPage(n)}
            />
            
          </EnhancedTableToolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={feedbacks?.get_feedback?.total}
                handleSort={handleSort}
              />
              <TableBody>
                {getData().map((row, index) => {
                  const labelId = `enhanced-feedbacks-table-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        style={{
                          paddingLeft: "20px",
                          minWidth: "150px",
                          maxWidth: "150px"
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left"
                      >
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.datestamp &&
                            moment(row.datestamp).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                        </Highlight>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: "100px", maxWidth: "100px" }}
                      >
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.client_name}
                        </Highlight>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: "100px", maxWidth: "100px" }}
                      >
                        <Highlight search={contextData.searchKeyword.value}>
                          {row.username}
                        </Highlight>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: "500px", maxWidth: "500px" }}
                      >
                        <AccordionCell content={`${row.message}`} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!feedbacks?.get_feedback?.feedback?.length && (
              <span
                style={{
                  width: "100%",
                  height: 72,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>
                  {loadingFeedbacks ? "Loading data" : "No data found"}
                </Typography>
              </span>
            )}
          </TableContainer>
        </Paper>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          handleSetFilters={handleSetFilters}
          handleClearFilter={handleClearFilter}
        >
          <FormControl
            style={{ width: "100%", minWidth: "auto", marginBottom: 24 }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                classes={{ root: formStyles.textFieldRoot }}
                fullWidth
                // disableFuture
                value={dateRange.from}
                label="From"
                format="MM/DD/YYYY"
                minDate={moment()
                  .subtract(30, "days")
                  .format("MM/DD/YYYY")}
                maxDate={moment()
                  .add(30, "days")
                  .format("MM/DD/YYYY")}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: "bold",
                    color: "black",
                    letterSpacing: 1
                  }
                }}
                onChange={date => {
                  setDateRange(prevRange => {
                    return {
                      ...prevRange,
                      from: moment(date)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    };
                  });
                }}
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="small" style={{ color: "#646464" }} />
                    </InputAdornment>
                  )
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl
            style={{ width: "100%", minWidth: "auto", marginBottom: 24 }}
            classes={{ root: formStyles.formRoot }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                classes={{ root: formStyles.textFieldRoot }}
                fullWidth
                // disableFuture
                value={dateRange.to}
                label="To"
                format="MM/DD/YYYY"
                minDate={moment()
                  .subtract(30, "days")
                  .format("MM/DD/YYYY")}
                maxDate={moment()
                  .add(30, "days")
                  .format("MM/DD/YYYY")}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: "bold",
                    color: "black",
                    letterSpacing: 1
                  }
                }}
                onChange={date => {
                  setDateRange(prevRange => {
                    return {
                      ...prevRange,
                      to: moment(date)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    };
                  });
                }}
                InputProps={{
                  placeholder: "Select Date/Time",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event fontSize="small" style={{ color: "#646464" }} />
                    </InputAdornment>
                  )
                }}
                showTodayButton
                todayLabel="NOW"
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <ClientsSearchableInput ref={clientRef} />
          <UserSearchableInput ref={userRef} />
        </Filter>
      </div>
    </AccessControl>
  );
}

export default withRouter(Feedbacks);
