import React, { memo, useState, useEffect } from "react";
import {
  TextField,
  styled,
  Paper,
  Icon,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from "@material-ui/core";
import {
  CloseRounded,
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ErrorOutlineRounded,
  KeyboardArrowDownRounded
} from "@material-ui/icons";
import AddLogs from "../../../utils/functions/AddLogs";
import { Autocomplete } from "@react-google-maps/api";
import { GET_GEOFENCES } from "../../../graphql/Queries";
import { useLazyQuery } from "@apollo/client";
import Cookies from "js-cookie";

const SearchLocation = ({
  setCoordinates,
  setPlaces,
  places,
  component = "map_view"
}) => {
  const user = JSON.parse(Cookies.get("user"));
  const [collapseSearchBar, setCollapseSearchBar] = useState(false);
  const [searchedCoordinates, setSearchedCoordinates] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isAddedLog, setIsAddedLog] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [geofenceList, setGeofenceList] = useState([]);
  const autocompleteRef = React.useRef();

  const [getGeofences] = useLazyQuery(GET_GEOFENCES, {
    skip: places?.place === "google places",
    onCompleted: res => {
      const { get_geofences } = res;
      if (get_geofences) {
        const { geofences } = get_geofences;
        const geofenceTemp = [];
        geofences.forEach(geo => {
          geofenceTemp.push({ name: geo.name, coordinate: geo.location });
        });

        setGeofenceList(geofenceTemp);
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const clearSearchCoordinates = () => {
    setSearchedCoordinates("");
    setCoordinates(null);
    setHasError(false);

    if (component === "historical") {
      setPlaces((prev) => ({
        ...prev,
          isOpen: false,
          geofence: null,
          gmap: null        
      }));
    }
  };

  const collapseSearchHandler = () => {
    setCollapseSearchBar(!collapseSearchBar);
    clearSearchCoordinates();
  };

  const validateEnteredCoordinates = value => {
    if (value) {
      const coordinatesTemp = value.split(",");
      const isCoordinatesAcceptable = /^[0-9,.]+$/g.test(value);
      if (coordinatesTemp.length === 2 && isCoordinatesAcceptable) {
        setCoordinates({ lat: +coordinatesTemp[0], lng: +coordinatesTemp[1] });
        setHasError(false);
        // if (coordinatesTemp[1]) setTimeout(() => setIsAddedLog(true), 5000);
        return;
      }
      setCoordinates(null);
      setHasError(true);
    } else {
      setHasError(false);
      setCoordinates(null);
    }
  };

  const onChangeSearchCoordinates = event => {
    // const searchCoordinates = event.target.value.replaceAll(/\s/g, "");
    const searchCoordinates = event.target.value;
    // validateEnteredCoordinates(searchCoordinates);
    setSearchedCoordinates(searchCoordinates);
  };

  const onSearchLocation = event => {
    if (event.key === "Enter") {
      // validateEnteredCoordinates(searchedCoordinates);
      validateEnteredCoordinates(searchedCoordinates.replaceAll(/\s/g, ""));
      AddLogs("Fleet", "search", searchedCoordinates);
    }
  };

  // useEffect(() => {
  //   if (isAddedLog && searchedCoordinates) {
  //     AddLogs("Fleet", "search", searchedCoordinates);
  //     setTimeout(() => setIsAddedLog(false), 5000);
  //   }
  // }, [isAddedLog]);

  const onPlacesAutocompleteLoad = autocomplete => {
    // eslint-disable-next-line no-param-reassign
    if (!autocompleteRef.current) autocompleteRef.current = autocomplete;
  };

  const onPlaceChange = () => {
    if (autocompleteRef.current) {
      if (autocompleteRef.current.getPlace?.()?.geometry) {
        setCoordinates({
          lat: autocompleteRef.current.getPlace?.()?.geometry?.location.lat(),
          lng: autocompleteRef.current.getPlace?.()?.geometry?.location.lng()
        });
        setSearchedCoordinates(
          autocompleteRef.current.getPlace?.()?.formatted_address
        );

        if (component === "historical") {
          setPlaces(prev => ({
            ...prev,
            geofence: null,
            place: "google places",
            gmap: autocompleteRef.current.getPlace?.()?.formatted_address
          }));
        }

        AddLogs(
          "Fleet",
          "search",
          autocompleteRef.current.getPlace?.()?.formatted_address
        );
      }
    }
  };

  const placesOptHandler = event => {
    setPlaces({ ...places, isOpen: false, place: event.target.value });
  };

  const searchPlaceHandler = event => {
    setSearchKeyword(event.target.value);
  };

  const keyHandler = event => {
    if (event.key === "Backspace" && component === "historical") {
      setPlaces(prev => ({
        ...prev,
        [places.place === "user places" ? "geofence" : "gmap"]: null
      }));
      setCoordinates(null);
    }
  };

  useEffect(() => {
    if (searchKeyword && places.place === "user places") {
      getGeofences({
        variables: {
          keyword: searchKeyword,
          first: 10,
          keyword_fields: ["name"],
          orderBy: [{ field: "name", "direction": "asc" }],
          group_ids: user.group_ids
        }
      });
    }
  }, [searchKeyword]);

  return (
    <>
      <StyledSearchContainer>
        <StyledBox style={{ width: collapseSearchBar ? 0 : "100%" }}>
          {places?.place === "user places" && component === "historical" ? (
            <StyledSearchField
              size="small"
              InputProps={{ disableUnderline: true }}
              placeholder="Search Places"
              value={places.geofence ? places.geofence.name : null}
              onChange={searchPlaceHandler}
              onKeyDown={event => keyHandler(event)}
            />
          ) : (
            <Autocomplete
              fields={["formatted_address", "geometry"]}
              onLoad={onPlacesAutocompleteLoad}
              onPlaceChanged={onPlaceChange}
              restrictions={{ country: "ph" }}
            >
              <StyledSearchField
                size="small"
                InputProps={{ disableUnderline: true }}
                placeholder="Enter place, address or lat,long"
                value={
                  component === "historical" ? places.gmap : searchedCoordinates
                }
                onChange={onChangeSearchCoordinates}
                onKeyPress={onSearchLocation}
                onKeyDown={event => keyHandler(event)}
              />
            </Autocomplete>
          )}
          {component === "historical" && (
            <StyledSearchBtn
              onClick={() => setPlaces({ ...places, isOpen: !places.isOpen })}
            >
              <KeyboardArrowDownRounded
                fontSize="small"
                color="primary"
                style={{ rotate: places?.isOpen ? "180deg" : "360deg" }}
              />
            </StyledSearchBtn>
          )}
          <StyledResetBtn onClick={clearSearchCoordinates}>
            <CloseRounded fontSize="small" color="error" />
          </StyledResetBtn>
        </StyledBox>
        <StyledCollapseBtn onClick={collapseSearchHandler}>
          {collapseSearchBar ? (
            <ArrowForwardIosRounded fontSize="small" color="inherit" />
          ) : (
            <ArrowBackIosRounded fontSize="small" color="inherit" />
          )}
        </StyledCollapseBtn>
      </StyledSearchContainer>
      <div style={{ display: "flex", gap: 5, alignItems: "flex-start" }}>
        {geofenceList.length > 0 && places?.place === "user places" && (
          <StyledSearchPlacesContainer>
            {geofenceList.map(geo => (
              <StyledSearchPlacesTypography
                key={geo.name}
                onClick={event => {
                  setSearchKeyword(geo.name);
                  setPlaces(prev => ({ ...prev, geofence: geo }));
                  setCoordinates(geo.coordinate);
                }}
              >
                {geo.name}
              </StyledSearchPlacesTypography>
            ))}
          </StyledSearchPlacesContainer>
        )}
        {places?.isOpen && (
          <StyledDropdownContainer>
            <RadioGroup
              aria-label="places"
              name="places"
              value={places.place}
              onChange={placesOptHandler}
            >
              <FormControlLabel
                value="google places"
                color="primary"
                control={<Radio size="small" color="primary" />}
                label={
                  <Typography style={{ fontSize: 12 }}>
                    Google Places
                  </Typography>
                }
              />
              <FormControlLabel
                value="user places"
                color="primary"
                control={<Radio size="small" color="primary" />}
                label={
                  <Typography style={{ fontSize: 12 }}>User Places</Typography>
                }
              />
            </RadioGroup>
          </StyledDropdownContainer>
        )}
      </div>

      {hasError && (
        // <StyledErrorMsgContainer>
        //   <ErrorOutlineRounded fontSize="small" /> Invalid Input. This search
        //   bar only accepts lat, long.
        // </StyledErrorMsgContainer>
        <StyledErrorMsgContainer>
          <ErrorOutlineRounded fontSize="small" /> Input lat, long numbers or
          choose a place from the results.
        </StyledErrorMsgContainer>
      )}
    </>
  );
};

export default SearchLocation;

const StyledSearchContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  boxShadow: "0px 0px 18px -8px #484848d4"
}));

const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  overflow: "hidden"
}));

const StyledErrorMsgContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#6e6e6e",
  color: "#c3c3c3",
  padding: "0.5rem",
  marginTop: 5,
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  gap: 5
}));

const StyledDropdownContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginTop: 8,
  borderRadius: 5,
  marginLeft: "auto",
  width: 130,
  padding: "5px 10px",
  boxShadow: "0px 0px 18px -8px #484848d4"
}));

const StyledSearchPlacesContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginTop: 8,
  borderRadius: 5,
  width: 240,
  padding: "5px 10px",
  boxShadow: "0px 0px 18px -8px #484848d4",
  maxHeight: 140,
  overflow: "auto",
  position: "absolute",
  zIndex: 999,
  "&::-webkit-scrollbar": {
    width: 5,
    backgroundColor: "#F9F9F9 !important",
    borderRadius: "0px !important"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F9F9F9 !important",
    border: "none !important"
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    border: "0px solid  rgba(0, 0, 0, 0) !important",
    borderRadius: 0
  }
}));

const StyledSearchPlacesTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.dark,
  backgroundColor: "none",
  padding: "0 0.25rem",
  borderRadius: 5,
  transition: "ease-in-out 0.3s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[300]
  }
}));

const StyledResetBtn = styled(Icon)(({ theme }) => ({
  cursor: "pointer",
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderLeft: "1px solid #c3c3c3",
  borderRight: "1px solid #c3c3c3"
}));

const StyledSearchBtn = styled(Icon)(({ theme }) => ({
  cursor: "pointer",
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderLeft: "1px solid #c3c3c3"
}));

const StyledCollapseBtn = styled(Icon)(({ theme }) => ({
  cursor: "pointer",
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledSearchField = styled(TextField)(({ theme }) => ({
  "& input::placeholder": {
    fontSize: "0.8rem"
  },
  "& input": {
    fontSize: "0.8rem",
    minWidth: 250
  },
  padding: "0 0.5rem"
}));
