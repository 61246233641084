import React, { useState, useEffect } from "react";
import { Autocomplete as PlacesAutocomplete } from "@react-google-maps/api";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const PlaceAutocomplete = (
  ({
    start,
    setDirectionsDetails,
    onPlaceChanged = () => {},
    isDisabled, // from edit
    startAddress,
    endAddress,
    setPointBoolean
  }) => {
    const autocompleteRef = React.useRef();
    const classes = useStyles();
    const [startName, setStartName] = useState(startAddress);
    const [endName, setEndName] = useState(endAddress);
    const [isClickable, setIsClickable] = useState(false);

    const start_props = {
      readOnly: isDisabled,
      disableUnderline: isDisabled,
      classes: {
        input: classes.input_fontSize
      },
      endAdornment: (
        <InputAdornment
          position="end"
          style={{ color: "#0288D1", cursor: "pointer" }}
          disablePointerEvents={isClickable} //disable on isDisabled and if not empty
        >
          <LocationOnIcon
            onClick={() => {
              setPointBoolean(prev => {
                return {
                  ...prev,
                  isStartPoint: true,
                  isEndPoint: false
                };
              });
            }}
          />
        </InputAdornment>
      )
    };

    const end_props = {
      readOnly: isDisabled,
      disableUnderline: isDisabled,
      classes: {
        input: classes.input_fontSize
      },
      endAdornment: (
        <InputAdornment
          position="end"
          style={{ color: "#F49400", cursor: "pointer" }}
          disablePointerEvents={isClickable} //disable on isDisabled and if not empty
        >
          <LocationOnIcon
            onClick={() => {
              setPointBoolean(prev => {
                return {
                  ...prev,
                  isStartPoint: false,
                  isEndPoint: true
                };
              });
            }}
          />
        </InputAdornment>
      )
    };

    const onPlacesAutocompleteLoad = autocomplete => {
      // eslint-disable-next-line no-param-reassign
      if (!autocompleteRef.current) autocompleteRef.current = autocomplete;
    };

    const onPlaceChange = () => {
      if (autocompleteRef.current) {
        if (autocompleteRef.current.getPlace?.()?.geometry) {
          const newPlace = autocompleteRef.current.getPlace?.()?.geometry;
          const formattedAddress = autocompleteRef.current.getPlace?.()
            ?.formatted_address;
          onPlaceChanged({ newPlace, formattedAddress, start });
          if (start) {
            setStartName(formattedAddress);
          } else setEndName(formattedAddress);
        }
      }
    };

    const isEmpty = value => {
      if (value === "" || value === " ") {
        if (start) {
          setDirectionsDetails(prev => {
            return {
              ...prev,
              directions: null,
              multiPolygon: null,
              origin: null
            };
          });
        } else {
          setDirectionsDetails(prev => {
            return {
              ...prev,
              directions: null,
              multiPolygon: null,
              destination: null
            };
          });
        }
        setIsClickable(false);
      } else {
        setIsClickable(true);
        setPointBoolean(prev => {
          return {
            ...prev,
            isStartPoint: false,
            isEndPoint: false
          };
        });
      }
      if (start) {
        setStartName(value);
      } else setEndName(value);
    };

    useEffect(() => {
      setStartName(startAddress);
      setEndName(endAddress);
    }, [startAddress, endAddress]);

    useEffect(() => {
      if (startName === "" || endName === "") {
        setIsClickable(false);
      } else setIsClickable(true);
    }, [startName, endName]);

    if (isDisabled) {
      return (
        <TextField
          fullWidth
          required
          label={start ? "Start Point" : "End Point"}
          placeholder={start ? "Enter starting point" : "Enter end point"}
          InputLabelProps={{
            classes: { root: classes.label },
            shrink: true
          }}
          InputProps={start ? start_props : end_props}
          style={{ marginBottom: "8px" }}
          value={start ? startName : endName}
        />
      )
    } else {
      return (
        <PlacesAutocomplete
          fields={["formatted_address", "geometry"]}
          onLoad={onPlacesAutocompleteLoad}
          onPlaceChanged={onPlaceChange}
          restrictions={{ country: "ph" }}
        >
          <TextField
            fullWidth
            required
            label={start ? "Start Point" : "End Point"}
            placeholder={start ? "Enter starting point" : "Enter end point"}
            InputLabelProps={{
              classes: { root: classes.label },
              shrink: true
            }}
            InputProps={start ? start_props : end_props}
            style={{ marginBottom: "8px" }}
            onChange={e => isEmpty(e.target.value)}
            value={start ? startName : endName}
          />
        </PlacesAutocomplete>
      );
    }
  }
);

const useStyles = makeStyles(() => ({
  input_fontSize: {
    fontSize: "13px"
  },
  label: {
    //fontSize: "14px",
    color: "black"
  }
}));

export default PlaceAutocomplete;
