import React from "react";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SearchGooglePlaces from "./Search.places";
import SearchGeofences from "./Search.geofences";
import SearchMenu from "./SearchMenu";
import useSearchStyles from "./Search.styles";

const Search = ({ onCenterChanged = () => {}, onResetLocation = () => {} }) => {
  const searchRef = React.useRef();
  const [placeText, setPlaceText] = React.useState("");
  const [placesSource, setPlacesSource] = React.useState("google_places");
  const styles = useSearchStyles();

  const handlePlacesSourceChanged = (_, nextValue) => {
    setPlacesSource(nextValue);
  };

  const handleSearchTextChanged = nextText => {
    setPlaceText(nextText);
  };

  const onPlaceChanged = newPlace => {
    if (placesSource === "google_places") {
      onCenterChanged({ ...newPlace.getPlace().geometry.location });
    } else {
      onCenterChanged({
        lat: () => newPlace.location.lat,
        lng: () => newPlace.location.lon
      });
    }
  };

  React.useEffect(() => {
    setPlaceText("");
    onResetLocation();
  }, [placesSource]);

  return (
    <div className={styles.wrapper}>
      {placesSource === "google_places" ? (
        <SearchGooglePlaces
          placeText={placeText}
          ref={searchRef}
          onPlaceChanged={onPlaceChanged}
          onTextSearchChanged={handleSearchTextChanged}
        />
      ) : (
        <SearchGeofences
          ref={searchRef}
          placeText={placeText}
          onPlaceChanged={onPlaceChanged}
          onTextSearchChanged={handleSearchTextChanged}
        />
      )}
      <SearchMenu>
        <RadioGroup value={placesSource} onChange={handlePlacesSourceChanged}>
          <FormControlLabel
            style={{ margin: 0 }}
            value="google_places"
            control={<Radio color="primary" size="small" />}
            label={<Typography variant="body2">Google Places</Typography>}
          />
          <FormControlLabel
            style={{ margin: 0 }}
            value="user_places"
            control={<Radio color="primary" size="small" />}
            label={<Typography variant="body2">User Places</Typography>}
          />
        </RadioGroup>
      </SearchMenu>
      <div className={styles.button}>
        <IconButton
          onClick={() => {
            setPlaceText("");
            onResetLocation();
          }}
          size="small"
        >
          <Close fontSize="small" color="error" />
        </IconButton>
      </div>
      <div className={styles.button}>
        <IconButton onClick={onResetLocation} size="small">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // enable-background="new 0 0 24 24"
            height="16px"
            viewBox="0 0 24 24"
            width="16px"
            fill="#000000"
          >
            <rect fill="none" height="20" width="20" />
            <path d="M15.31,18.9c-0.96,1-2.06,2.03-3.31,3.1c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2c4,0,7.64,2.92,7.97,7.5l3.53,0L19,14 l-4.5-4.5l3.47,0C17.65,6.24,15.13,4,12,4c-3.35,0-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14c0.64-0.59,1.23-1.16,1.77-1.71 c-0.17-0.34-0.27-0.72-0.27-1.12c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5S17.38,19,16,19C15.76,19,15.53,18.97,15.31,18.9z" />
          </svg>
        </IconButton>
      </div>
    </div>
  );
};

export default Search;
