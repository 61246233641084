/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Badge,
  Popper,
  ClickAwayListener,
  Grid,
  Fade,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Divider
} from "@material-ui/core";
import {
  FilterList,
  ExpandMore,
  MapTwoTone,
  ListTwoTone
} from "@material-ui/icons";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import _ from "lodash";
import { useParams, withRouter } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import Cookie from "js-cookie";
import MonitoringTable from "./MonitoringTable";
import {
  GET_VEHICLE_INFOS,
  GET_VEHICLES_COUNT,
  GET_ACL_MODULES_CLIENT
} from "../../graphql/Queries";
import Loading from "../../utils/Loading";
import Filter from "./Filter";
import SearchContext from "../../context/SearchContext";
// import MapView from "./MapView";
import BaseMap from "./BaseMap";
// import VehicleCount from "./VehicleCount";
import AddLogs from "../../utils/functions/AddLogs";
import Pagination from "../../utils/table/Pagination";
import useAllowedPermissions from "../../utils/hooks/useAllowedPermissions";
import ClientAutocomplete from "../../utils/ClientAutocomplete";
import useUserContext from "../../context/User/useUserContext";
import VehicleCount from "../../utils/UI/VehicleCount";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  filterButton: {
    borderRadius: "25px",
    fontSize: "12px"
  },
  textField: {
    borderRadius: "32px !important"
  },
  container: {
    maxHeight: "calc(100vh - 128px)"
  },
  tablePagination: {
    overflow: "hidden",
    "& .MuiIconButton-root": {
      padding: 5
    }
  },
  autoComplete: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      backgroundColor: "#dedede",
      borderRadius: "2px"
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root::after": {
      borderBottom: "none"
    },
    "& .MuiFormControl-root": {
      borderBottom: "none",
      outline: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  alignment: {
    display: "flex",
    alignItems: "center"
  },
  padding: {
    padding: "0px 15px"
  },
  btn: {
    border: "none",
    borderRadius: "50px",
    backgroundColor: "#dedede",
    color: "black",
    fontWeight: "bold"
  },
  outlined: {
    color: "#545454 !important",
    border: "1px solid #d1d1d1 !important"
  },
  outlinedPrimary: {
    "&:hover": {
      backgroundColor: "#f9f9f9"
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "gray"
  },
  listItem: {
    "& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiListItemText-primary": {
      fontSize: "16px"
    }
  },
  listItemTextRoot: {
    color: theme.palette.primary.main
  },
  activeButton: {
    background: theme.palette.primary.main
  },
  inactiveButton: {
    background: "#808080"
  },
  countSelect: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#DEDEDE",
      borderRadius: "10px"
    }
  }
}));

// const VehicleCounts = React.memo(props => {
//   const classes = useStyles();
//   const user = JSON.parse(Cookie.get("user"));
//   const {
//     dataCount,
//     isMapView,
//     filterValues,
//     setFilterValues,
//     isFiltered
//   } = props;

//   return (
//     <PopupState variant="popper" popupId="vehicleCount-popup-popper">
//       {popupState => (
//         <div className={classes.alignment}>
//           <Typography className={classes.padding}> Count: </Typography>
//           <Button
//             variant="outlined"
//             color="primary"
//             className={classes.btn}
//             classes={{
//               outlined: classes.outlined,
//               outlinedPrimary: classes.outlinedPrimary
//             }}
//             {...bindToggle(popupState)}
//           >
//             {dataCount ? (
//               <>
//                 <Badge color="secondary" variant="dot" invisible={!isFiltered}>
//                   {dataCount?.total}
//                   <ExpandMore />
//                 </Badge>
//               </>
//             ) : (
//               <CircularProgress size="1rem" color="primary" />
//             )}
//           </Button>
//           <Popper
//             {...bindPopper(popupState)}
//             placement={isMapView ? "bottom-end" : "bottom"}
//             transition
//             style={{ zIndex: "1100" }}
//           >
//             {({ TransitionProps }) => (
//               <ClickAwayListener
//                 onClickAway={() => {
//                   popupState.close();
//                 }}
//               >
//                 <Fade {...TransitionProps} timeout={350}>
//                   <Paper style={{ width: "250px" }}>
//                     <List className={classes.listItem}>
//                       <ListItem key="count" dense>
//                         <ListItemText
//                           id="count"
//                           primary="Count"
//                           classes={{
//                             root: classes.listItemTextRoot
//                           }}
//                         />
//                       </ListItem>

//                       {user.user_level_id !==
//                         process.env.REACT_APP_WTI_ADMIN && (
//                         <>
//                           <ListItem key="hauler" dense>
//                             <ListItemText id="hauler" primary={`Hauler's`} />
//                             <ListItemSecondaryAction>
//                               <Typography edge="end" aria-label="comments">
//                                 {dataCount?.status_count?.shared || 0}
//                               </Typography>
//                             </ListItemSecondaryAction>
//                           </ListItem>

//                           <ListItem key="owned" dense>
//                             <ListItemText id="owned" primary="Owned" />
//                             <ListItemSecondaryAction>
//                               <Typography edge="end" aria-label="comments">
//                                 {dataCount?.status_count?.owned || 0}
//                               </Typography>
//                             </ListItemSecondaryAction>
//                           </ListItem>
//                           <Divider variant="middle" />
//                         </>
//                       )}

//                       <div
//                         className={
//                           dataCount?.status_count?.available !== 0 &&
//                           classes.countSelect
//                         }
//                         onClick={() => {
//                           if (dataCount?.status_count?.available !== 0) {
//                             const newValue = {
//                               value: process.env.REACT_APP_STATUS_AVAILABLE,
//                               label: "Available"
//                             };
//                             setFilterValues({
//                               ...filterValues,
//                               vehicle_status: newValue,
//                               isCountEffect: true
//                             });
//                           }
//                         }}
//                       >
//                         {" "}
//                         <ListItem key="available" dense>
//                           <ListItemText id="Available" primary="Available" />
//                           <ListItemSecondaryAction>
//                             <Typography
//                               edge="end"
//                               aria-label="comments"
//                               style={{ color: "black" }}
//                             >
//                               {dataCount?.status_count?.available || 0}
//                             </Typography>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       </div>

//                       <div
//                         className={
//                           dataCount?.status_count?.booked !== 0 &&
//                           classes.countSelect
//                         }
//                         onClick={() => {
//                           if (dataCount?.status_count?.booked !== 0) {
//                             const newValue = {
//                               value: process.env.REACT_APP_VEHICLE_STATUS_BOOKED,
//                               label: "Booked"
//                             };

//                             if (filterValues?.vehicle_status?.value === process.env.REACT_APP_VEHICLE_STATUS_UNDER_REPAIR) {
//                               setFilterValues({
//                                 ...filterValues,
//                                 additionalCondition: newValue,
//                                 isCountEffect: true
//                               });
//                             } else if (dataCount?.status_count?.booked !== 0) {
//                               setFilterValues({
//                                 ...filterValues,
//                                 vehicle_status: newValue,
//                                 isCountEffect: true
//                               });
//                             }
//                           }
//                         }}
//                       >
//                         <ListItem key="booked" dense>
//                           <ListItemText id="Booked" primary="Booked" />
//                           <ListItemSecondaryAction>
//                             <Typography
//                               edge="end"
//                               aria-label="comments"
//                               style={{ color: "black" }}
//                             >
//                               {dataCount?.status_count?.booked || 0}
//                             </Typography>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       </div>
//                       <ListItem
//                         className={classes.nested}
//                         key="booked_underrepair"
//                         dense
//                       >
//                         <ListItemText
//                           id="booked_underrepair"
//                           primary="Booked-Under Repair"
//                         />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "gray" }}
//                           >
//                             {dataCount?.status_count?.booked_underrepair || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem>

//                       <ListItem
//                         className={classes.nested}
//                         key="booked_not_in_use"
//                         dense
//                       >
//                         <ListItemText
//                           id="booked_not_in_use"
//                           primary="Booked-Not in use"
//                         />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "gray" }}
//                           >
//                             {dataCount?.status_count?.booked_not_in_use || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem>

//                       <div
//                         className={
//                           dataCount?.status_count?.underrepair !== 0 &&
//                           classes.countSelect
//                         }
//                         onClick={() => {
//                           if (dataCount?.status_count?.underrepair !== 0) {
//                             const newValue = {
//                               value: process.env.REACT_APP_VEHICLE_STATUS_UNDER_REPAIR,
//                               label: "Under Repair"
//                             };
//                             if (filterValues?.vehicle_status?.value === process.env.REACT_APP_VEHICLE_STATUS_BOOKED) {
//                               setFilterValues({
//                                 ...filterValues,
//                                 additionalCondition: newValue,
//                                 isCountEffect: true
//                               });
//                             } else if (
//                               dataCount?.status_count?.underrepair !== 0
//                             ) {
//                               setFilterValues({
//                                 ...filterValues,
//                                 vehicle_status: newValue,
//                                 isCountEffect: true
//                               });
//                             }
//                           }
//                         }}
//                       >
//                         <ListItem key="underrepair" dense>
//                           <ListItemText
//                             id="underrepair"
//                             primary="Under Repair"
//                           />
//                           <ListItemSecondaryAction>
//                             <Typography
//                               edge="end"
//                               aria-label="comments"
//                               style={{ color: "black" }}
//                             >
//                               {dataCount?.status_count?.underrepair || 0}
//                             </Typography>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       </div>
//                       <div
//                         className={
//                           dataCount?.status_count?.not_in_use !== 0 &&
//                           classes.countSelect
//                         }
//                         onClick={() => {
//                           if (dataCount?.status_count?.not_in_use !== 0) {
//                             const newValue = {
//                               value: process.env.REACT_APP_VEHICLE_STATUS_NOT_IN_USE,
//                               label: "Not in use"
//                             };
//                             setFilterValues({
//                               ...filterValues,
//                               vehicle_status: newValue,
//                               isCountEffect: true
//                             });
//                           }
//                         }}
//                       >
//                         <ListItem key="not_in_use" dense>
//                           <ListItemText id="not_in_use" primary="Not in use" />
//                           <ListItemSecondaryAction>
//                             <Typography
//                               edge="end"
//                               aria-label="comments"
//                               style={{ color: "black" }}
//                             >
//                               {dataCount?.status_count?.not_in_use || 0}
//                             </Typography>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       </div>
//                       <Divider variant="middle" />
//                       <ListItem key="gps_status" dense>
//                         <ListItemText
//                           id="gps_status"
//                           primary="GPS Status"
//                           classes={{
//                             root: classes.listItemTextRoot
//                           }}
//                         />
//                       </ListItem>

//                       {[
//                         { value: "999", label: "active", primary: "Active"},
//                         { value: "777", label: "inactive", primary: "Inactive" },
//                         { value: 0, label: "no_gps", primary: "No GPS" },
//                         { value: "888", label: "delay_reporting", primary: "Delayed Reporting" }
//                       ].map(gps => (
//                         <div
//                           className={
//                             dataCount?.status_count?.[gps.label] !== 0 &&
//                             classes.countSelect
//                           }
//                           onClick={() => {
//                             if (dataCount?.status_count?.[gps.label] !== 0) {
//                               const newValue = {
//                                 value: gps.value,
//                                 label: gps.primary
//                               };
//                               setFilterValues({
//                                 ...filterValues,
//                                 gps_status: newValue,
//                                 isCountEffect: true
//                               });
//                             }
//                           }}
//                         >
//                           <ListItem key={gps.label} dense>
//                             <ListItemText id={gps.label} primary={gps.primary} />
//                             <ListItemSecondaryAction>
//                               <Typography
//                                 edge="end"
//                                 aria-label="comments"
//                                 style={{ color: "black" }}
//                               >
//                                 {dataCount?.status_count?.[gps.label] || 0}
//                               </Typography>
//                             </ListItemSecondaryAction>
//                           </ListItem>
//                         </div>
//                       ))}

//                       {/* <ListItem key="active" dense>
//                         <ListItemText id="active" primary="Active" />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "black" }}
//                           >
//                             {dataCount?.status_count?.active || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem>
//                       <ListItem key="inactive" dense>
//                         <ListItemText id="inactive" primary="Inactive" />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "black" }}
//                           >
//                             {dataCount?.status_count?.inactive || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem>
//                       <ListItem key="no_gps" dense>
//                         <ListItemText id="no_gps" primary="No GPS" />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "black" }}
//                           >
//                             {dataCount?.status_count?.no_gps || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem>
//                       <ListItem key="delay_reporting" dense>
//                         <ListItemText id="delay_reporting" primary="Delayed Reporting" />
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             aria-label="comments"
//                             style={{ color: "black" }}
//                           >
//                             {dataCount?.status_count?.delay_reporting || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       </ListItem> */}
//                     </List>
//                   </Paper>
//                 </Fade>
//               </ClickAwayListener>
//             )}
//           </Popper>
//         </div>
//       )}
//     </PopupState>
//   );
// });

const Monitoring = props => {
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user") || "{}");
  const userCtx = useUserContext();
  const userLevelId = userCtx.user_level_id;
  const isWTIAdmin = _.isEqual(+userLevelId, +process.env.REACT_APP_WTI_ADMIN);
  const clientId = userCtx?.client?.id;
  const [hasTripPrebookingAccess, setHasTripPrebookingAccess] = useState(false);
  const { location } = props;
  const [vehicles, setVehicles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  // const [totalPage, setTotalPage] = useState(1);
  const [vars, setVars] = useState(
    !_.isEmpty(location.state) ? location.state.vars : {}
  );
  const [isFiltered, setIsFiltered] = useState(!_.isEmpty(vars));
  const [openFilter, setOpenFilter] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("vehicle_info.plateno");
  const initialValueHauler = isWTIAdmin
    ? { value: [30], label: "Webcast", id: 30 }
    : { value: [], label: "All" };
  const intialFilterValues = location?.state?.filterValues
    ? location.state.filterValues
    : {
        hauler: initialValueHauler,
        isLogistic: false,
        vehicle_status: {
          value: 0,
          label: "All"
        },
        vehicle_type: {
          value: 0,
          label: "All"
        },
        gps_status: {
          value: 1010,
          label: "All"
        },
        vehicle_state: {
          value: 0,
          label: "All"
        },
        temperature: {
          value: 0,
          label: "All"
        },
        location: null,
        isCountEffect: false,
        additionalCondition: null
      };

  const [filterValues, setFilterValues] = useState(intialFilterValues);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const defaultGroupFilter = {
    id: user?.group_ids[0],
    name: user?.group_names[0],
    group_ids: [user?.group_ids[0]]
  };
  const [clientSelection, setClientSelection] = useState(() => {
    return JSON.parse(localStorage.getItem("clientSelection"))
      ? JSON.parse(localStorage.getItem("clientSelection"))
      : defaultGroupFilter;
  });
  const [loaded, hasLoaded] = useState(false);
  const [bound, setBounds] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);
  const { permissions: permissions_control_room } = useAllowedPermissions(
    `/${process.env.REACT_APP_LINK_CONTROL_ROOM}/`
  );
  // const start = moment().format("YYYY-MM-DD");
  const getQueryConditions = () => {
    const condition = [];
    const not = [];
    const filter = [];
    if (!filterValues || !filterValues?.vehicle_status) return;
    // if (!isWTIAdmin && filterValues.hauler.label.toLowerCase() !== "all") {
    //   condition.push({
    //     field: "vehicle_info.client_id",
    //     value: filterValues.hauler.id.toString()
    //   });
    // }
    if (filterValues?.vehicle_status?.value !== 0) {
      // check if applied filter vehicle status is 1
      // if 1 set under repair condition
      if (+filterValues?.vehicle_status?.value === 1) {
        condition.push({
          field: "under_repair",
          value: ["1"]
        });
        //additional condition for vehicle_status when clicking in count
        if (filterValues?.additionalCondition?.label === "Booked") {
          condition.push({
            field: "vehicle_info.status_code_id",
            value: process.env.REACT_APP_VEHICLE_STATUS_BOOKED
          });
        }
      } else if (+filterValues?.vehicle_status?.value === 2) {
        condition.push({
          field: "not_in_use",
          value: ["1"]
        });
        not.push({
          field: "under_repair",
          value: ["1"]
        });
      } else {
        condition.push({
          field: "vehicle_info.status_code_id",
          value: filterValues?.vehicle_status?.value
        });
        //if condition is Available then add "Not in use" in not
        if (
          filterValues?.vehicle_status?.value ===
          process.env.REACT_APP_STATUS_AVAILABLE
        ) {
          not.push({
            field: "not_in_use",
            value: ["1"]
          });
        }
        //additional condition for vehicle_status when clicking in count
        if (filterValues?.additionalCondition?.label === "Under Repair") {
          condition.push({
            field: "under_repair",
            value: ["1"]
          });
        }
        // not.push({
        //   field: "under_repair",
        //   value: ["1"]
        // });
      }
    }
    if (filterValues?.vehicle_state?.value !== 0) {
      if (filterValues?.vehicle_state?.value === 2) {
        condition.push({ field: "rover_status.gpsSpeed", value: "0" });
      } else if (filterValues?.vehicle_state?.value === 1) {
        not.push({ field: "rover_status.gpsSpeed", value: "0" });
      }
    }
    if (filterValues?.gps_status?.value !== 1010) {
      if (filterValues?.gps_status?.value === 0) {
        condition.push({ field: "vehicle_info.device_id", value: "" });
      } else {
        condition.push({
          field: "rover_status.status_code_id",
          value: filterValues?.gps_status?.value.toString()
        });
      }
    }
    if (filterValues?.temperature?.value !== 0) {
      if (filterValues?.temperature?.value === 1) {
        // with temp sensor
        filter.push({ field: "rover_configs.hastempsensor", value: ["1"] });
      } else if (filterValues?.temperature?.value === 2) {
        // without temp sensor
        filter.push({
          field: "rover_configs.hastempsensor",
          value: ["0", "false"]
        });
        filter.push({ field: "vehicle_info.device_id", value: ["0", "false"] });
      }
    }
    if (filterValues?.vehicle_type?.value !== 0) {
      condition.push({
        field: "vehicle_info.vehicle_type_id",
        value: filterValues?.vehicle_type?.value.toString()
      });
    }
    // filtervalues for the hauler groups.
    // if (filterValues.hauler.value) {
    //   condition.push({
    //     field: "vehicles_groups.group_id",
    //     value: filterValues.hauler.value.toString()
    //   });
    // }
    // if (+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN) {
    //   condition.push({
    //     field: "clients.id",
    //     value: clientSelection.id.toString()
    //   });
    // }

    // if (true) {
    //   filter.push({
    //     field: "device_info.device_status.lastlocation",
    //     value: filterValues.location
    //   });
    // }
    return {
      condition: condition,
      not: not,
      filter: filter
    };
  };

  const [getVehicleCount, { data: vehiclesCountData }] = useLazyQuery(
    GET_VEHICLES_COUNT,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  const start = moment().format("YYYY-MM-DD");
  useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: clientId },
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setHasTripPrebookingAccess(hasTripsPrebooking);
      }
    },
    onError: err => console.log(err),
    fetchPolicy: "cache-and-network"
  });
  const { data: vehicles_data, loading, refetch } = useQuery(
    GET_VEHICLE_INFOS,
    {
      variables: {
        ...vars,
        first: rowsPerPage,
        skip: currentPage * rowsPerPage,
        dateRange: {
          start: `${start} 00:00:00`
        },
        orderBy: [
          {
            field: orderBy,
            direction: order
          }
        ],
        ...(vars?.bounds ? { bounds: vars.bounds } : { bounds: bound }),
        // clients group_ids initial value
        ...(+user?.user_level_id === +process.env.REACT_APP_WTI_ADMIN && {
          group_ids: vars?.group_ids
            ? vars.group_ids
            : clientSelection.group_ids
        }),
        is_logistics: !isWTIAdmin
          ? hasTripPrebookingAccess
          : vars.is_logistics || false
        // group_ids: clientSelection.group_ids
        // ...(+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN &&
        //   clientSelection.id && { group_ids: clientSelection.group_ids })
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      pollInterval: 60 * 5000
    }
  );

  useEffect(() => {
    // if (+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN) {
    //   refetch({
    //     ...getQueryConditions(),
    //     // condition: [
    //     //   {
    //     //     field: "clients.id",
    //     //     value: clientSelection.id.toString()
    //     //     // value: nv.id
    //     //   }
    //     // ],
    //     keyword: searchKeyword.value,
    //     first: rowsPerPage,
    //     skip: 0 * rowsPerPage,
    //     bounds: filterValues?.bounds || []
    //   });
    // }
    getVehicleCount({
      variables: {
        ...vars,
        dateRange: {
          start: `${start} 00:00:00`
        },
        ...(+user?.user_level_id === +process.env.REACT_APP_WTI_ADMIN && {
          group_ids: vars?.group_ids
            ? vars.group_ids
            : clientSelection.group_ids
        })
      }
    });
  }, [vars, clientSelection]);

  useEffect(() => {
    if (searchKeyword.submit && loaded) {
      setVars(prev => ({
        ...prev,
        keyword: searchKeyword.value
      }));
      // setFilterValues({
      //   hauler: {
      //     value: 0,
      //     label: "All"
      //   },
      //   vehicle_status: 0,
      //   gps_status: 1010,
      //   vehicle_state: 0,
      //   temperature: 0,
      //   location: null
      // });
      setCurrentPage(0);
      if (searchKeyword?.value && vars?.keyword !== searchKeyword?.value) {
        AddLogs(
          process.env.REACT_APP_FLEET_MODULE,
          "search",
          searchKeyword.value
        );
      }
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (vehicles_data) {
      const table_data = vehicles_data.get_vehicles.vehicles.map(v => {
        return {
          id: v.id,
          device_id: v.device_id,
          icon: v.icon,
          hauler: v.client_name,
          plateno: v.plateno,
          has_gps: v.device_info || false,
          vehicle_type: v.vehicle_type,
          hasTemperatureSensor: v.has_temp_sensor,
          speed: v.device_info?.device_status?.gpsSpeed || 0,
          temperature: v.device_info?.device_status?.temperature || 0,
          trip_count: v.trip_numbers || [],
          gps_status: v.device_info?.device_status?.status_code || "no gps",
          reportstamp: v.device_info?.device_status?.reportstamp,
          client_id: v.client_id,
          hasTempSensor: v.device_info?.device_config?.hastempsensor || false,
          lastlocation: v.device_info?.device_status?.lastlocation,
          battery_level: v.device_info?.device_status?.battery_level,
          device_type_id: v.device_info?.device_type_id,
          under_repair: v.under_repair,
          not_in_use: v.not_in_use,
          has_vss_camera: v.has_vss_camera,
          channel_count: v.channel_count
        };
      });
      setVehicles(table_data);
      hasLoaded(true);
      // setTotalPage(
      //   vehicles_data.get_vehicles.total
      //     ? Math.ceil(vehicles_data.get_vehicles.total / rowsPerPage)
      //     : 1
      // );
      // setTotalPage(vehicles_data.get_vehicles.total);
    }
  }, [vehicles_data]);

  useEffect(() => {
    if (filterValues?.isCountEffect) {
      applyFilter(false);
    }
  }, [
    filterValues?.isCountEffect,
    filterValues?.vehicle_status,
    filterValues?.gps_status
  ]);
  // const handlePaginate = (pageUp = false) => {
  //   if (pageUp) {
  //     setCurrentPage(prevPage => prevPage + 1);
  //   } else {
  //     setCurrentPage(prevPage => prevPage - 1);
  //   }
  // };

  // const handleChangePage = (_event, newPage) => {
  //   setCurrentPage(newPage);
  // };

  const processActLogs = () => {
    const {
      hauler,
      vehicle_status,
      gps_status,
      vehicle_state,
      vehicle_type,
      temperature
    } = filterValues;

    const location =
      filterValues?.location_type === "google_place"
        ? filterValues?.location?.current?.getPlace()?.formatted_address
        : `${filterValues?.location?.geofence_code}-${filterValues?.location?.name}`;

    AddLogs(
      process.env.REACT_APP_FLEET_MODULE,
      "filter",
      `Hauler: ${hauler?.label},Vehicle Status: ${
        vehicle_status?.label
      },GPS Status: ${gps_status?.label},Vehicle Status: ${
        vehicle_state?.label
      },Vehicle Type: ${vehicle_type?.label},Temperature: ${
        temperature?.label
      }${filterValues?.location ? `,Location: ${location}` : ""}`
    );
  };

  const applyFilter = isFilterApplied => {
    if (isLogistics && isFilterApplied) {
      setFilterValues(prev => ({
        ...prev,
        isLogistic: isLogistics
      }));
    }

    if (filterValues.vehicle_status.value === "44" && isLogistics) {
      setIsLogistics(false);
    }

    setCurrentPage(0);
    const v = {
      ...getQueryConditions(),
      group_ids:
        // isWTIAdmin &&
        filterValues?.hauler?.value ? [...filterValues?.hauler?.value] : [],
      keyword: searchKeyword.value,
      first: rowsPerPage,
      skip: 0 * rowsPerPage,
      bounds: filterValues?.bounds || [],
      is_logistics: isFilterApplied ? isLogistics : false
    };
    setIsFiltered(true);
    setVars(v);
    // setVars(getQueryConditions());
    processActLogs();
  };

  const clearFilter = () => {
    if (searchKeyword.value !== "") {
      setVars({
        keyword: searchKeyword.value
      });
    } else {
      setVars({});
    }
    setFilterValues(intialFilterValues);
    setIsLogistics(false);
  };
  const { view } = useParams();
  const [isMapView, setIsMapView] = useState(() => view === "map");
  React.useEffect(() => {
    if (isMapView) {
      AddLogs(process.env.REACT_APP_FLEET_MODULE, "view_map_fleet", "Map View");
      if (!searchKeyword?.moduleName) {
        setSearchKeyword({
          ...searchKeyword,
          moduleName: process.env.REACT_APP_FLEET_MODULE
        });
      }
    }
  }, [isMapView]);
  return (
    <div
      // className={classes.root}
      style={{
        position: "abasolute",
        top: "64px",
        left: "70px",
        width: "calc(100vw - 70px)",
        height: "calc(100vh - 64px)"
      }}
    >
      {/* {(loading ||
        clientLoading ||
        vehiclesCountLoading ||
        networkStatus === 4) && <Loading />} */}
      {loading && <Loading />}
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        setVars={setVars}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        setIsFiltered={setIsFiltered}
        isAdmin={isWTIAdmin}
        hasAccessPrebookingTrips={hasTripPrebookingAccess}
        isLogistics={isLogistics}
        setIsLogistics={setIsLogistics}
      />
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{
          // position: "relative",
          zIndex: "auto"
        }}
      >
        <Toolbar
        // style={{ marginTop: 64, marginLeft: 70 }}
        >
          <Grid container item md={12}>
            <Grid item md={isMapView ? 10 : 6} container alignItems="center">
              <Button
                color="primary"
                variant="contained"
                style={{
                  borderRadius: "25px",
                  margin: "0 20px 0 20px",
                  fontSize: "12px"
                }}
                startIcon={isMapView ? <ListTwoTone /> : <MapTwoTone />}
                onClick={() => setIsMapView(!isMapView)}
              >
                {isMapView ? "List View" : "Map View"}
              </Button>
              <Button
                variant="contained"
                className={
                  isFiltered ? classes.activeButton : classes.inactiveButton
                }
                style={{
                  borderRadius: "25px",
                  margin: "0 20px 0 20px",
                  fontSize: "12px"
                }}
                startIcon={<FilterList />}
                onClick={() => setOpenFilter(true)}
              >
                <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                  Filter
                </Badge>
              </Button>
              {/* check if WTI admin then display dropdown/autocomplete  */}
              {/* {user.user_level_id === process.env.REACT_APP_WTI_ADMIN && (
                <ClientAutocomplete
                  groupFilter={clientSelection}
                  onChange={(e, nv) => {
                    localStorage.setItem("clientSelection", JSON.stringify(nv));
                    setSearchKeyword(prev => ({ ...prev, group: nv }));
                    setClientSelection(nv);
                    setCurrentPage(0);
                  }}
                />
              )} */}
            </Grid>
            <Grid item md={2} container justifyContent="flex-end" spacing={2}>
              {/* <VehicleCounts
                dataCount={vehiclesCountData?.get_vehicles_count}
                isMapView={isMapView}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                isFiltered={isFiltered}
              /> */}
              <VehicleCount
                vehicleCount={vehiclesCountData}
                isMapView={isMapView}
                hasAccessPrebookingTrips={hasTripPrebookingAccess}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                isFiltered={isFiltered}
                module="monitoring-table"
              />
            </Grid>
            {/* <div className={classes.root} /> */}
            {!isMapView && (
              <React.Fragment>
                {/* <Grid
                  item
                  md={4}
                  container
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <VehicleCount
                  />
                </Grid> */}
                <Grid item md={4}>
                  <Pagination
                    rowsPerPage={rowsPerPage}
                    total={Math.ceil(
                      (vehicles_data?.get_vehicles?.total || 0) / rowsPerPage
                    )}
                    // startsWithZero={false}
                    currentPage={currentPage}
                    onRowsPerPageChange={n => {
                      setRowsPerPage(n);
                      setCurrentPage(0);
                    }}
                    onPageChange={n => setCurrentPage(n)}
                    onPageUp={n => setCurrentPage(n)}
                    onPageDown={n => setCurrentPage(n)}
                  />
                  {/* <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    component="div"
                    count={vehicles_data?.get_vehicles.total || 0}
                    page={loading ? 0 : currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                  /> */}
                </Grid>
              </React.Fragment>
              // <>
              //   <InputLabel>Rows per page: &nbsp;&nbsp;</InputLabel>
              //   <TextField
              //     variant="outlined"
              //     value={rowsPerPage}
              //     size="small"
              //     InputProps={{
              //       classes: {
              //         root: classes.textField
              //       }
              //     }}
              //     select
              //     onChange={e => {
              //       setRowsPerPage(e.target.value);
              //       setCurrentPage(0);
              //     }}
              //     margin="normal"
              //   >
              //     {[10, 20, 30, 40, 50].map(rpp => (
              //       <MenuItem key={rpp} value={rpp}>
              //         {rpp}
              //       </MenuItem>
              //     ))}
              //   </TextField>
              //   &nbsp;&nbsp;
              //   <InputLabel>Go to: &nbsp;&nbsp;</InputLabel>
              //   <TextField
              //     variant="outlined"
              //     value={currentPage}
              //     size="small"
              //     InputProps={{
              //       classes: {
              //         root: classes.textField
              //       }
              //     }}
              //     select
              //     onChange={e => setCurrentPage(e.target.value)}
              //     margin="normal"
              //   >
              //     {_.times(totalPage).map(p => (
              //       <MenuItem key={p} value={p}>
              //         {p + 1}
              //       </MenuItem>
              //     ))}
              //   </TextField>
              //   <IconButton
              //     disabled={currentPage === 0}
              //     onClick={() => handlePaginate()}
              //   >
              //     <KeyboardArrowLeft />
              //   </IconButton>
              //   <Typography>{`${currentPage + 1} of ${totalPage}`}</Typography>
              //   <IconButton
              //     disabled={currentPage + 1 >= totalPage}
              //     onClick={() => handlePaginate(true)}
              //   >
              //     <KeyboardArrowRight />
              //   </IconButton>
              // </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {isMapView ? (
        // <MapView
        //   vars={vars}
        //   contRef={VISIBLE_VEHICLES}
        //   // handleVehicleCountChange={handleVehicleCountChange}
        // />
        <BaseMap
          vars={vars}
          filterValues={filterValues}
          clientSelection={clientSelection}
          bound={bound}
          setBounds={setBounds}
          drawing={drawing}
          setDrawing={setDrawing}
          permissions={permissions_control_room}
          vehicles={{ total: vehicles_data?.get_vehicles?.total || 0 }}
        />
      ) : (
        // <BaseMap vars={vars} />
        <MonitoringTable
          vehicles={vehicles}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filterValues={filterValues}
          vars={vars}
        />
      )}
    </div>
  );
};

export default withRouter(Monitoring);
