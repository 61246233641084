import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  Toolbar,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import Cookie from "js-cookie";
import { HelpRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import {
  SearchIcon,
  Delete as DeleteIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";
import { GET_PARTNERS, GET_CLIENT_GROUP } from "../../../../../graphql/Queries";
import {
  EDIT_CLIENT_GROUP,
  DELETE_CLIENT_GROUP
} from "../../../../../graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/Loading";
import AddLogs from "../../../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  partners: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "18px 0 0 0px"
  },
  partnersContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  partnersTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#565656",
    letterSpacing: 0.7
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  search: {
    padding: "0px 9px",
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 190px)",
    height: 35,
    backgroundColor: "#fafafa",
    borderRadius: "25px",
    boxShadow: "none",
    border: "1px solid #ccc"
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 600,
    fontSize: 14,
    "& .MuiInputBase-input": {
      letterSpacing: 0.7
    }
  },
  addEntryButton: {
    fontSize: 12,
    letterSpacing: 0.7,
    fontWeight: 600,
    margin: "0px 20px 0 0",
    textTransform: "capitalize"
  },
  dialogStyle: {
    height: 405,
    width: 600
  },
  iconButton: {
    padding: 0
  },
  paperGroup: {
    display: "flex",
    backgroundColor: "#eaeaea",
    padding: "0px 16px 0 16px",
    position: "relative",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px"
  }
}));

const EditPartnersTable = props => {
  const classes = useStyles();
  const { currentData, editMode } = props;
  const { id: clientId } = useParams();
  const [open, setOpen] = useState(false);
  const [setterGroup, setSetterGroup] = useState();
  const [groupList, setGroupList] = useState();
  const [selected, setSelected] = useState([]);
  const [deleteGroup, setDeleteGroup] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [orderBy, setOrderBy] = useState("asc");
  const [keyword, setKeyword] = useState("");
  const [searchPartner, setSearchPartner] = useState("");
  const [render, setRender] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [ids, setIDs] = useState();

  // Render the list of partners/groups in edit table
  const { data: getGroups, refetch, loading } = useQuery(GET_CLIENT_GROUP, {
    variables: {
      client_id: +clientId
    },
    onCompleted: () => {
      setGroupList(() => {
        const { group_ids, group_names } = getGroups?.get_client_group;
        const display = group_names?.filter(g => g)?.map((name, index) => {
          return {
            id: group_ids?.filter(g => g)[index],
            name
          };
        });
        return display;
      });
    }
  });

  // List of partners/group in modal
  const { data: groups_data, fetchMore, refetch: refetchPartner } = useQuery(
    GET_PARTNERS,
    {
      variables: {
        skip: 0,
        keyword: keyword,
        not: [
          {
            field: "id",
            value: getGroups?.get_client_group?.group_ids?.map(gId => {
              return gId?.toString();
            })
          }
        ]
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  );

  // Edit getGroups (get_client_group) mutation
  const [editClientGroup] = useMutation(EDIT_CLIENT_GROUP, {
    onCompleted: data => {
      if (data.add_client_group.success) {
        refetch();
        refetchPartner();
        AddLogs(
          `Admin - Clients ${groupList[0].name}`,
          "group_partners",
          selected.map(g => g.name)
        );
      }
    }
  });

  // Delete getGroups (get_client_group) mutation
  const [deleteClientGroup] = useMutation(DELETE_CLIENT_GROUP, {
    onCompleted: data => {
      if (data.delete_client_group.success) {
        refetch();
        refetchPartner();
      }
    }
  });

  function handleOpenModal() {
    // this will handle upon open of the modal it will refetch from the normal count of 5 on the groups.
    refetchPartner();
    setOpen(true);
  }

  // Add select partner to the table (edit mutation)
  const handleAddGroups = () => {
    const group_ids = [...getGroups.get_client_group.group_ids];
    const group_names = [...getGroups.get_client_group.group_names];
    const selection = selected.map(e => +e.id);

    _.forEach(selected, sg => {
      group_ids.push(Number(sg.id));
      group_names.push(sg.name);
    });

    setSetterGroup({
      ...getGroups.get_client_group,
      group_names: group_names,
      group_ids: group_ids
    });
    setOpen(false);

    editClientGroup({
      variables: {
        client_group: {
          client_id: getGroups.get_client_group.client_id,
          group_ids: selection
        }
      }
    });
  };

  // Single select checkbox in edit table
  const handleCheckDelete = (id, name, e) => {
    let temp = [...deleteGroup];

    if (e.target.checked) {
      temp.push({ id: id, name: name });
    } else {
      temp = _.filter(temp, t => {
        return t.id !== id;
      });
    }
    setDeleteGroup(temp);
    setRender(!render);
  };

  //Handle check in adding partners
  const handleCheck = (id, name, e) => {
    let temp = [...selected];
    if (e.target.checked) {
      temp.push({
        id: id,
        name: name
      });
    } else {
      temp = _.filter(temp, sg => {
        return sg.id !== id;
      });
    }
    setSelected(temp);
    setRender(!render);
  };

  // Disaplay in edit table
  useEffect(() => {
    if (setterGroup) {
      const { group_ids, group_names } = setterGroup;
      const g = group_names.map((name, index) => {
        return {
          id: group_ids[index],
          name
        };
      });
      setGroupList(g);
    }
  }, [setterGroup]);

  // Display partners in modal
  useEffect(() => {
    if (groups_data) {
      const { get_groups } = groups_data;
      setTotalPage(get_groups.total ? Math.ceil(get_groups.total / 5) : 1);
    }
  }, [groups_data]);

  //Handle the page of partners modal
  const handlePage = page => {
    if (page) {
      setCurrentPage(currentPage + 1);
      const totalLoaded = (currentPage + 1) * 5;
      if (
        groups_data.get_groups.total > groups_data.get_groups.count &&
        totalLoaded <= groups_data.get_groups.count
      ) {
        fetchMore({
          variables: {
            keyword: keyword,
            skip: groups_data.get_groups.count
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              get_groups: {
                ...prev.get_groups,
                total: fetchMoreResult.get_groups.total,
                count: fetchMoreResult.get_groups.count + prev.get_groups.count,
                groups: prev.get_groups.groups.concat(
                  fetchMoreResult.get_groups.groups
                )
              }
            };
          }
        });
      }
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  //Selecting partners in modal
  const handleSelectAll = e => {
    const { get_groups } = groups_data;
    if (e.target.checked) {
      const temp = get_groups.groups.map(group => {
        return {
          id: group.id,
          name: group.name
        };
      });
      setSelected(temp);
      return;
    }
    setSelected([]);
  };

  //Modal single delete partners in edit table
  const handleRemoveGroup = i => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(i);
    deleteClientGroup({
      variables: {
        condition: [
          {
            field: "client_id",
            value: [getGroups.get_client_group.client_id.toString()]
          },
          { field: "group_id", value: [groupList[index].id.toString()] }
        ]
      }
    });

    AddLogs(
      `Admin - Clients ${groupList[0].name}`,
      "delete_partners",
      groupList[index].name
    );
    temp.splice(index, 1);
    setRender(!render);
    setDeleteGroup([]);
    setSearchPartner("");
  };

  //Modal function of multiple delete
  const handleMultipleDelete = () => {
    const temp = groupList;
    const selection = deleteGroup.map(g => g.id);
    for (var i = selection.length - 1; i >= 0; i--) {
      temp.splice(groupList.map(p => p.id).indexOf(selection[i]), 1);
    }

    deleteClientGroup({
      variables: {
        condition: [
          {
            field: "client_id",
            value: [getGroups.get_client_group.client_id.toString()]
          },
          { field: "group_id", value: deleteGroup.map(d => d.id.toString()) }
        ]
      }
    });

    AddLogs(
      `Admin - Clients ${groupList[0].name}`,
      "delete_partners",
      deleteGroup.map(d => d.name)
    );
    setDeleteGroup([]);
    setSearchPartner("");
  };

  //Hanlde check all boxes in edit table
  const handleSelectDelete = e => {
    if (e.target.checked) {
      const temp = groupList
        .filter(
          row =>
            row.name.toLowerCase().indexOf("".toLowerCase()) > -1 &&
            row.name.toLowerCase().indexOf(searchPartner.toLowerCase()) > -1
        )
        .map(group => {
          return {
            id: group.id,
            name: group.name
          };
        });

      const index = temp
        .map(g => g.id)
        .indexOf(currentData.get_client.group_ids[0]);
      if (index > -1) {
        temp.splice(index, 1);
      }
      setDeleteGroup(temp);
      return;
    } else {
      setDeleteGroup([]);
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  if (loading) return <Loading />;

  return (
    <>
      <Dialog open={open} PaperProps={{ style: { width: 435, height: 450 } }}>
        <DialogTitle>Add Entry</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search Client"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                  setCurrentPage(0);
                }}
                InputProps={{
                  style: { borderRadius: 32 },
                  inputProps: {
                    style: { padding: "5px 16px" }
                  }
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Search />
                  //   </InputAdornment>
                  // )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                onClick={() => handlePage(false)}
                disabled={currentPage + 1 === 1}
                className={classes.iconButton}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <TextField
                inputProps={{
                  style: { padding: 0, textAlign: "center" },
                  readOnly: true
                }}
                style={{ width: "32px", marginRight: "2px", marginTop: 4 }}
                variant="outlined"
                value={currentPage + 1}
              />
              {` / ${totalPage}`}
              <IconButton
                onClick={() => handlePage(true)}
                disabled={currentPage + 1 === totalPage}
                className={classes.iconButton}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      onChange={handleSelectAll}
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < groups_data?.get_groups?.count
                      }
                      checked={
                        groups_data &&
                        groups_data?.get_groups?.count === selected.length
                      }
                    />
                  </TableCell>
                  <TableCell>Client</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups_data?.get_groups?.groups &&
                  groups_data?.get_groups?.groups
                    .slice(currentPage * 5, currentPage * 5 + 5)
                    .map(group => (
                      <TableRow hover role="checkbox" key={group.id}>
                        <TableCell
                          padding="checkbox"
                          key={`${group.id}-checkBox`}
                        >
                          <Checkbox
                            color="primary"
                            onChange={e => handleCheck(group.id, group.name, e)}
                            checked={
                              _.findIndex(selected, sg => {
                                return sg.id === group.id;
                              }) !== -1
                            }
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          key={`${group.id}-id`}
                        >
                          {group.name}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setSelected([]);
              setKeyword("");
              setCurrentPage(0);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selected.length}
            onClick={() => {
              handleAddGroups();
              setSelected([]);
              setKeyword("");
              setCurrentPage(0);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        {editMode ? (
          <Grid container className={classes.partners}>
            <Grid item xs={12} className={classes.partnersContent}>
              <div style={{ display: "flex" }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Data client will be linked to all selected partners(and vise versa)"
                  placement="top-start"
                >
                  <HelpRounded className={classes.helpIcon} />
                </Tooltip>
                <InputLabel classes={{ root: classes.partnersTitle }}>
                  Partners/Group
                </InputLabel>
              </div>
              <Paper className={classes.search}>
                <InputBase
                  placeholder="Search Client"
                  className={classes.searchInput}
                  onChange={e => setSearchPartner(e.target.value)}
                  value={searchPartner}
                />
                {/* <IconButton>
                <SearchIcon />
              </IconButton> */}
              </Paper>
            </Grid>
            {deleteGroup?.length > 0 && (
              <Grid item xs={12}>
                <Paper className={classes.paperGroup}>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${deleteGroup.length} item selected`}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setModalDelete(true)}
                    style={{
                      // position: "absolute",
                      // right: 0,
                      borderRadius: 36,
                      color: "white"
                    }}
                  >
                    Delete Selected{" "}
                  </Button>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                style={{ margin: deleteGroup.length ? "0px 0" : "15px 0" }}
              >
                <TableContainer style={{ height: "35vh" }}>
                  <Table stickyHeader size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onChange={handleSelectDelete}
                            indeterminate={
                              deleteGroup?.length > 0 &&
                              deleteGroup?.length < groupList?.length
                            }
                            checked={
                              deleteGroup?.length > 0 &&
                              deleteGroup?.length < groupList?.length
                              // groupList?.length > 0
                              //  && (groupList?.length - 1) === deleteGroup?.length
                            }
                          />
                        </TableCell>
                        <TableCell padding="normal">
                          <TableSortLabel
                            active
                            direction={orderBy}
                            onClick={() => {
                              setOrderBy(prev =>
                                prev === "asc" ? "desc" : "asc"
                              );
                            }}
                          >
                            Hauler/Groups
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            size="small"
                            color="primary"
                            classes={{ root: classes.addEntryButton }}
                            onClick={handleOpenModal}
                          >
                            Add Entry
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupList?.length > 0 ? (
                        stableSort(groupList, getComparator(orderBy, "name"))
                          .filter(
                            row =>
                              row.name.toUpperCase().indexOf("".toUpperCase()) >
                                -1 &&
                              row.name
                                .toLowerCase()
                                .indexOf(searchPartner.toLowerCase()) > -1
                          )
                          .map(row => {
                            const isItemSelected = isSelected(row.id);
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    disabled={
                                      row.id ===
                                      currentData.get_client.group_ids[0]
                                    }
                                    color="primary"
                                    checked={
                                      _.findIndex(deleteGroup, sg => {
                                        return sg.id === row.id;
                                      }) !== -1
                                    }
                                    onChange={e =>
                                      handleCheckDelete(row.id, row.name, e)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  align="left"
                                  scope="row"
                                  padding="none"
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  align="right"
                                  scope="row"
                                  padding="none"
                                >
                                  {row.id !==
                                    currentData.get_client.group_ids[0] && (
                                    <DeleteIcon
                                      onClick={() => {
                                        setModal(true);
                                        setIDs(row.id);
                                      }}
                                      style={{ paddingRight: 8 }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={20} align="center">
                            No records found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.partners}>
            <Grid item xs={12} className={classes.partnersContent}>
              <div style={{ display: "flex" }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Data client will be linked to all selected partners(and vise versa)"
                  placement="top-start"
                >
                  <HelpRounded className={classes.helpIcon} />
                </Tooltip>
                <InputLabel classes={{ root: classes.partnersTitle }}>
                  Hauler/Groups
                </InputLabel>
              </div>
              <Paper className={classes.search}>
                <InputBase
                  placeholder="Search Client"
                  className={classes.searchInput}
                  onChange={e => setSearchPartner(e.target.value)}
                  value={searchPartner}
                />
                {/* <IconButton>
                <SearchIcon />
              </IconButton> */}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                style={{ margin: deleteGroup.length ? "0px 0" : "15px 0" }}
              >
                <TableContainer style={{ height: "35vh" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="normal">
                          <TableSortLabel
                            active
                            direction={orderBy}
                            onClick={() => {
                              setOrderBy(prev =>
                                prev === "asc" ? "desc" : "asc"
                              );
                            }}
                          >
                            Groups
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupList?.length > 0 ? (
                        stableSort(groupList, getComparator(orderBy, "name"))
                          .filter(
                            row =>
                              row.name.toUpperCase().indexOf("".toUpperCase()) >
                                -1 &&
                              row.name
                                .toLowerCase()
                                .indexOf(searchPartner.toLowerCase()) > -1
                          )
                          .map(row => {
                            return (
                              <TableRow key={row.name} hover>
                                <TableCell
                                  component="th"
                                  align="left"
                                  scope="row"
                                  style={{ padding: "10px 20px" }}
                                >
                                  {row.name}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={20} align="center">
                            No records found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        )}
        <ConfirmationDialog
          toggle={modalDelete}
          close={() => setModalDelete(false)}
          fn={() => handleMultipleDelete()}
          title="Delete Partner?"
          content="Are you sure you want to delete this entries?"
        />
        <ConfirmationDialog
          toggle={modal}
          close={() => setModal(false)}
          fn={() => handleRemoveGroup(ids)}
          title="Delete Partner?"
          content="Are you sure you want to delete this entry?"
        />
      </div>
    </>
  );
};

export default EditPartnersTable;
