/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
  Fragment
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import ChipInput from "material-ui-chip-input";
import clsx from "clsx";
import {
  Badge,
  Button,
  Container,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  RadioGroup,
  Radio,
  Switch,
  TextareaAutosize,
  Tooltip,
  Typography,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SketchPicker } from "react-color";
import {
  Help as HelpIcon,
  Event as EventIcon,
  Delete as DeleteIcon,
  // ExpandMore as ExpandMoreIcon,
  Search as SearchIcon
} from "@material-ui/icons";

import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Swal from "sweetalert2";
import _ from "lodash";
// import { set } from "date-fns/fp";
import SearchContext from "../../../../../context/SearchContext";
import {
  GET_ADMIN_ACCESS_OBJECTS,
  GET_CLIENTS_CODES
} from "../../../../../graphql/Queries";
import {
  ADD_ADMIN_CLIENT,
  ADD_INFO_BUBBLE,
  ADD_CLIENT_GROUP
} from "../../../../../graphql/Mutations";
import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../../utils/validation";
import { Helper } from "../../../../Utils";
import AddLogs from "../../../../../utils/functions/AddLogs";

import ModuleTree from "../../../../../utils/ModuleTree";
import useUserContext from "../../../../../context/User/useUserContext";
import PartnersTable from "./PartnersTable";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),

      "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
        color: "red"
      }
    }
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  },
  switch: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  typographyRoot: {
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "0.30px",
    color: "hsl(0deg 0% 0% / 76%)"
  },
  checkboxRoot: {
    "& .MuiSvgIcon-root": {
      height: "0.8em !important",
      fontSize: "1.25rem !important"
    }
  },
  summaryLabelWrapper: {
    flex: 1,
    display: "flex",
    padding: "2px"
  },
  summaryLabelRoot: {
    marginRight: 4,
    "& .MuiButtonBase-root": {
      padding: 0
    }
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  helpIconForm: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

const EditViewMenuTest = props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    access,
    handleAccessView,
    handleAccessEdit
  } = props;
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "left bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        checked={
                          access && access[0]
                            ? access[0].permission.view
                            : false
                        }
                        onClick={e => handleAccessView(e)}
                        name={anchorEl.name}
                        color="primary"
                      />
                    }
                    label="Can View"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        checked={
                          access && access[0]
                            ? access[0].permission.edit
                            : false
                        }
                        onClick={e => handleAccessEdit(e)}
                        name={anchorEl.name}
                        color="primary"
                      />
                    }
                    label="Can Edit"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default function AddClients(props) {
  const { history } = props;
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  const {searchKeyword, setSearchKeyword} = contextData
  const [isLoading, setIsLoading] = useState(true);
  const [tempAddData, setTempAddData] = useState({
    name: "",
    client_type: "Restricted",
    client_category: " ",
    no_of_users: 1,
    no_of_devices: 0,
    no_of_geofences: 0,
    access_end_date: null,
    data_admin: "",
    remarks: "",
    welcome_logo: null,
    welcome_logo_filename: "welcome_logo.png",
    welcome_text: "Welcome to Track Me Suite",
    tagline_text: "Transform your Logistics",
    header_logo: null,
    header_logo_filename: "header_logo.png",
    header_text: "trackMe Suite",
    theme_color: "#3CA4F7",
    modules: [],
    landing_page: "0",
    sms_sending_type: "Not Applicable",
    eta_enabled: 0
    // registered_IPS: []
  });
  const [initialState, setInitialState] = useState(_.cloneDeep(tempAddData));
  const [listModules, setListModules] = useState({
    modules: [],
    mobile: [{ id: 1, name: "TmSuite Mobile", is_checked: false }]
  });
  const [welcomeLogoDefault, setWelcomeLogoDefault] = useState(true);
  const [welcomeTextDefault, setWelcomeTextDefault] = useState(true);
  const [taglineTextDefault, setTaglineTextDefault] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [headerLogoDefault, setHeaderLogoDefault] = useState(true);
  const [headerTextDefault, setHeaderTextDefault] = useState(true);
  const [themeDefault, setThemeDefault] = useState(true);
  const [error, setError] = useState(false);
  const [modulesError, setModulesError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [popper, setPopper] = useState(false);
  const [viewColorPicker, setViewColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [colorPickerAnchor, setColorPickerAnchor] = useState(false);
  const [selected, setSelected] = useState([]);
  const [toDelete, setToDelete] = useState();
  const [toOpen, setToOpen] = useState(false);
  // const [formEdit, setFormEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [check, setCheck] = useState({ status: false, readOnly: false });
  // const [checkAPI, setCheckAPI] = useState({ status: false, readOnly: false });
  const [generatedCC, setGeneratedCC] = useState();
  const [helpers, setHelper] = useState({ code: null });
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [clientCodeData, setClientCodeData] = React.useState({
    code: "",
    remarks: ""
  });
  const [checkList, setCheckList] = useState([]);
  const [adminFieldExist, setAdminFieldExist] = useState(false);
  const [partners, setPartners] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    no_of_users: "",
    no_of_devices: "",
    no_of_geofences: "",
    data_admin: "",
    welcome_text: "",
    tagline_text: "",
    header_text: "",
    client_category: "",
    landing_page: "",
    general: ""
  });

  const InfoBubbleRef = useRef({
    ignition: 0,
    battery: 0,
    door: 0,
    fuel: 0,
    odometer_reading: 0,
    power_source: 0,
    speed: 1,
    temperature: 0,
    driver: 0,
    coordinates: 0
  });
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });
  const [metaData, setMetaData] = useState([]);
  const handleUpdateSelectedModules = useCallback(
    newModules => {
      // const landingPageExist = newModules.reduce((accum, current) => {
      //   if (+current.id === +tempAddData.landing_page) {
      //     return current.permission.view;
      //   }
      //   return accum;
      // }, false);
      const tempModules = newModules?.filter(
        module =>
          module.permission.view === true &&
          module.parent_id === "0" &&
          module.id !== process.env.REACT_APP_ACL_MODULE_STORE_APP &&
          module.id !== process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API
      );
      // setCheckList(
      //   [{ id: "0", name: "Select Landing Page" }].concat(tempModules)
      // );
      setCheckList(tempModules);

      setModulesError(prev => {
        if (prev) {
          return !prev;
        }
      });

      // setTempAddData(prev => ({
      //   ...prev,
      //   // landing_page: landingPageExist ? tempAddData.landing_page : "0"
      //   // landing_page: "0"
      // }));

      setListModules(prev => ({
        ...prev,
        modules: newModules
      }));
    },
    // [setListModules, setTempAddData, tempAddData.landing_page]
    [setListModules, setModulesError]
  );

  // setting landing page according to the selected and checked
  useEffect(() => {
    const isAdminExist = checkList.some(
      module => module.name.trim().substring(0, 5) === "Admin"
    );
    const landingPageExist = checkList.some(
      list => list?.id === tempAddData.landing_page
    );
    setTempAddData(prev => ({
      ...prev,
      landing_page:
        landingPageExist || (tempAddData.landing_page === "1" && isAdminExist)
          ? tempAddData.landing_page
          : "0"
    }));
    setAdminFieldExist(isAdminExist);
  }, [checkList]);
  // const handleToggle = () => {
  //   setPopper(prevPopper => !prevPopper);
  // };
  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setPopper(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopper(false);
    }
  }

  // const setAccessEndData = () => {
  //   let i = 0;
  //   const date = new Date().getDate();
  //   let month = new Date().getMonth() + 7;
  //   if (month > 12) {
  //     i += month % 12;
  //     month -= 12;
  //   }
  //   const year = new Date().getFullYear() + i;
  //   return `${year}-${`00${month}`.slice(-2)}-${`00${date}`.slice(
  //     -2
  //   )} 23:59:00`;
  // };

  React.useMemo(() => {
    setTempAddData({
      ...tempAddData,
      access_end_date: moment().add(6, "months").format("YYYY-MM-DD 23:59:00")
    });
    setInitialState({
      ...initialState,
      access_end_date: moment().add(6, "months").format("YYYY-MM-DD 23:59:00")
    });
  }, []);

  const handleAccessEdit = e => {
    const { name, checked } = e.target;
    const newModules = listModules.modules;
    newModules.map(row => {
      const module = row;
      if (module.name === name) {
        module.permission.edit = checked;
        if (checked) {
          module.permission.view = true;
        }
      }
      return module;
    });

    setTempAddData({
      ...tempAddData,
      landing_page: "0"
    });
    setListModules({
      ...listModules,
      modules: newModules
    });
  };

  const handleAccessView = e => {
    const { name, checked } = e.target;
    const newModules = listModules.modules;
    newModules.map(row => {
      const module = row;
      if (module.name === name) {
        module.permission.view = checked;
        if (!checked) {
          module.permission.edit = false;
        }
      }
      return module;
    });

    setTempAddData({
      ...tempAddData,
      landing_page: "0"
    });
    setListModules({
      ...listModules,
      modules: newModules
    });
  };
  // const [expanded, setExpanded] = React.useState("panel1");

  // const handleChange = panel => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const user_context = useUserContext();
  useEffect(() => {
    const modules = [];
    if (user_context.access_rights) {
      const accessObjects = [...user_context.access_rights];
      accessObjects.map(row => {
        const objectRow = { ...row };
        objectRow.is_checked = false;
        modules.push(objectRow);
        setIsLoading(false);
        return true;
      });
    }
    setListModules({
      ...listModules,
      modules: modules
    });
  }, [user_context]);
  // const { data } = useQuery(GET_ADMIN_ACCESS_OBJECTS, {
  //   onCompleted() {
  //     const modules = [];
  //     if (data.get_access_objects) {
  //       const accessObjects = data.get_access_objects;
  //       accessObjects.map(row => {
  //         const objectRow = row;
  //         objectRow.is_checked = false;
  //         modules.push(objectRow);
  //         setIsLoading(false);
  //         return true;
  //       });
  //     }
  //     setListModules({
  //       ...listModules,
  //       modules: modules
  //     });
  //   },
  //   onError() {
  //     setIsLoading(false);
  //   },
  //   fetchPolicy: "no-cache"
  // });

  // const handleKeyPress = event => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   if (/[0-9.]/g.test(keyValue)) {
  //     if (event.target.value.length === 15) {
  //       event.preventDefault();
  //     }
  //   } else event.preventDefault();
  // };
  const handleBlur = e => {
    const { name, value } = e.target;
    let errorMessage;

    switch (name) {
      case "name":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (!validate("alphanumeric", value.trim())) {
          errorMessage = "Alphanumeric only";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_users":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 1) {
          errorMessage = "Invalid value. Min: 0, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_devices":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 0) {
          errorMessage = "Invalid value. Min: 0, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      case "no_of_geofences":
        if (value === "") {
          errorMessage = "This field is required";
        } else if (validate("number", value.trim())) {
          errorMessage = "Numeric only";
        } else if (value > 99999 || value < 0) {
          errorMessage = "Invalid value. Min: 0, Max: 99,999";
        } else {
          errorMessage = "";
        }
        break;
      default:
        break;
    }
    setErrors({
      ...errors,
      [name]: errorMessage
    });
    setTempAddData({
      ...tempAddData,
      [name]: value
    });
  };

  const handleAddValues = e => {
    const { name, value } = e.target;
    let errorMessage;
    switch (name) {
      case "name":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "welcome_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "tagline_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      case "header_text":
        errorMessage = validate("alphanumeric", value)
          ? ""
          : "Alphanumeric characters only";
        break;
      // case "no_of_users":
      //   errorMessage = validate("numeric", value)
      //     ? ""
      //     : "Numeric characters only";
      //   break;
      // case "no_of_devices":
      //   errorMessage = validate("numeric", value)
      //     ? ""
      //     : "Numeric characters only";
      //   break;
      // case "no_of_geofences":
      //   errorMessage = validate("numeric", value)
      //     ? ""
      //     : "Numeric characters only";
      //   break;
      // case "api_key":
      //   errorMessage = validate("alphanumeric", value)
      //     ? ""
      //     : "Alphanumeric characters only";
      //   break;
      case "data_admin":
        errorMessage = validate("email", value) ? "" : "Invalid Email Address";
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: errorMessage
    });
    setTempAddData({
      ...tempAddData,
      [name]: value
    });
  };

  const handleOnchange = e => {
    setTempAddData({
      ...tempAddData,
      [e.currentTarget.name]: e.currentTarget.checked ? 1 : 0
    });
  };

  // const generateAPI = event => {
  //   if (event.target.checked) {
  //     setCheckAPI({ status: true, readOnly: true });
  //     let d = new Date().getTime();
  //     if (window.performance && typeof window.performance.now === "function") {
  //       d += performance.now();
  //     }
  //     const uuid = "yxxxxyxxxxyxxxxy".replace(/[xy]/g, function(c) {
  //       const r = (d + Math.random() * 16) % 16 | 0;
  //       d = Math.floor(d / 16);
  //       return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  //     });

  //     setTempAddData({
  //       ...tempAddData,
  //       api_key: uuid
  //     });
  //   } else {
  //     setCheckAPI({ status: false, readOnly: false });
  //     setTempAddData({
  //       ...tempAddData,
  //       api_key: ""
  //     });
  //   }
  // };

  const [addInfoBubble] = useMutation(ADD_INFO_BUBBLE);

  const [addClientGroup] = useMutation(ADD_CLIENT_GROUP);

  const [addAdminClient] = useMutation(ADD_ADMIN_CLIENT, {
    onCompleted: res => {
      setDisableSubmit(false);
      setIsLoading(false);
      setModulesError(false);
      if (res.add_client.success) {
        AddLogs("Admin - Clients", "insert", tempAddData.name);
        addInfoBubble({
          variables: {
            InfoBubbleInput: {
              client_id: res.add_client.id,
              ...InfoBubbleRef.current
            }
          }
        });
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Add successful",
          timer: 3000,
          onClose: () => {
            // setting values for table filter values after adding
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_CLIENTS_MODULE,
                  newClient: true,
                  vars: history?.location?.state?.vars,
                  filter: history?.location?.state?.filter
                }
              }
            });
          }
        });
        if (res.add_client.id) {
          addClientGroup({
            variables: {
              client_group: {
                client_id: +res.add_client.id,
                group_ids: partners.map(g => +g.id)
              }
            }
          });
          AddLogs(
            `Admin - Clients ${tempAddData.name}`,
            "add_partners",
            partners.map(g => g.name)
          );
        }
      } else {
        // Swal.fire({
        //   icon: "error",
        //   // title: "Success",
        //   text: "API Key already exists.",
        //   timer: 3000
        // });
        // setErrors({
        //   ...errors,
        //   api_key: "API Key already exists."
        // });
        const tempObj = {};
        res.add_client.error.map(err =>
          Object.assign(tempObj, { [err.field]: err.message })
        );
        setErrors({
          ...errors,
          ...tempObj
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      setError(true);
      setIsLoading(false);
      setModulesError(false);
      // Swal.fire({
      //   icon: "error",
      //   // title: "Success",
      //   text: "Something went wrong"
      // });
    }
  });

  const handleCloseDialog = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const discarded = () => {
    handleCloseDialog("discard");
  };

  const clearErrors = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => {
      temp.push({ [element]: "" });
      return true;
    });
    setErrors(Object.assign(errors, ...temp));
  };
  const areRequiredFieldsFilled = [
    tempAddData.name,
    tempAddData.client_category,
    tempAddData.no_of_users,
    tempAddData.access_end_date,
    tempAddData.data_admin,
    tempAddData.landing_page
  ].some(e => e === "" || e === null || e === " " || e === "0" || e === 0);
  const checker = false;

  const handleDelete = () => {
    const temp = [...metaData];
    temp.splice(toDelete, 1);
    setMetaData([...temp]);
  };

  const handleToDelete = index => {
    setToDelete(index);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCheck(false);
    setClientCodeData({
      code: "",
      remarks: ""
    });
    setGeneratedCC("");
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setClientCodeData({
      code: "",
      remarks: ""
    });
    setGeneratedCC("");
    setCheck(false);
  };

  const headCells = [
    {
      id: "tblCode",
      numeric: false,
      disablePadding: true,
      label: "Client Code"
    },
    {
      id: "tblRemarks",
      numeric: false,
      disablePadding: false,
      label: "Remarks"
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: (
        <Button color="primary" onClick={handleDialogOpen}>
          Add Entry
        </Button>
      ),
      disable: true
    }
  ];

  const isSelected = clientCode => selected.indexOf(clientCode) !== -1;
  const handleOpen = () => {
    setToOpen(true);
  };
  const handleClose = () => {
    setToOpen(false);
  };

  const handleClick = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleMultipleDeleteCode = () => {
    let temp = [...metaData];
    temp = temp.filter(item => !selected.includes(item.code));
    setMetaData([...temp]);
    setSelected([]);
  };

  const checkCCInput = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^a-zA-Z0-9]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const [checkCC1, setCheckCC1] = useState();
  const { data: gcdCode } = useQuery(GET_CLIENTS_CODES);

  React.useMemo(() => {
    if (gcdCode) {
      const temp = [];
      if (gcdCode.get_clients_codes) {
        gcdCode.get_clients_codes.map(row => {
          temp.push(row.code);
          setCheckCC1(temp);
          return null;
        });
      }
    }
  }, [gcdCode]);

  function checkValid(arr, val) {
    return arr.some(arrVal => val === arrVal);
  }

  const handleAddCC = async () => {
    const valid = true;
    let temp = {};
    if (clientCodeData.code === "") {
      temp = { ...helpers, code: "This field is required" };
    } else if (checkValid(checkCC1, clientCodeData.code) === true) {
      temp = { ...helpers, code: "Client Code Already Exist" };
    } else if (metaData.length === 0) {
      temp = { ...helpers, code: "" };
      const temp1 = [...metaData];
      temp1.push({
        code: clientCodeData.code,
        remarks: clientCodeData.remarks
      });
      setMetaData(temp1);
      setDialogOpen(false);
      setGeneratedCC("");
      setClientCodeData({
        code: "",
        remarks: ""
      });
    } else {
      const tempMeta = [];
      metaData.map(row => {
        return tempMeta.push(row.code);
      });
      if (checkValid(tempMeta, clientCodeData.code) === true) {
        temp = { ...helpers, code: "Client Code Already Exist" };
      } else {
        temp = { ...helpers, code: "" };
        const temp1 = [...metaData];
        temp1.push({
          code: clientCodeData.code,
          remarks: clientCodeData.remarks
        });
        setMetaData(temp1);
        setDialogOpen(false);
        setGeneratedCC("");
        setClientCodeData({
          code: "",
          remarks: ""
        });
      }
    }
    setHelper(temp);
    return valid;
  };

  const ccOnChange = e => {
    if (e.target.name === "code") {
      setClientCodeData({
        ...clientCodeData,
        [e.target.name]: e.target.value.toUpperCase()
      });
      setGeneratedCC(e.target.value.toUpperCase());
    } else {
      setClientCodeData({
        ...clientCodeData,
        [e.target.name]: e.target.value
      });
      setGeneratedCC(null);
    }
  };

  const validateCode = () => {
    const temp = tempAddData.name.toString().toUpperCase();
    const holder = temp.replace(/ /g, "");
    const random_number = Math.floor(Math.random() * 99999);
    const tn_to_string = random_number.toString().padStart(5, "0");

    if (holder === "" || holder === null) {
      let random_string = "";
      let random_ascii;
      for (let i = 0; i < 3; i += 1) {
        random_ascii = Math.floor(Math.random() * 25 + 97);
        random_string += String.fromCharCode(random_ascii).toUpperCase();
      }
      return random_string + tn_to_string;
    }
    return holder.substring(0, 3) + tn_to_string;
  };

  const generateClientCode = event => {
    if (event.target.checked) {
      const tempGCC = validateCode();
      setClientCodeData({
        ...clientCodeData,
        code: tempGCC
      });
      setGeneratedCC(tempGCC);
      setCheck({ status: true, readOnly: true });
    } else {
      setClientCodeData({
        ...clientCodeData,
        code: generatedCC
      });
      setCheck({ status: false, readOnly: false });
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = metaData.map(v => v.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSubmit = () => {
    setDisableSubmit(true);
    // e.preventDefault();
    setModulesError(false);
    const modulesData = [];
    const tempParents = [];
    listModules.modules.map(row => {
      if (row.permission.view) tempParents.push(row.parent_id);
      return true;
    });
    const parentIds = [...new Set(tempParents)];
    listModules.modules.map(row => {
      const module = row;
      if (parentIds.includes(row.id)) {
        module.permission.view = true;
        module.permission.edit = true;
      }

      if (module.permission.view) {
        modulesData.push({
          id: module.id,
          resources: module.resource,
          view: module.permission.view,
          edit: module.permission.edit
        });
      }
      return module;
    });

    const errorMessage = "This field is required";
    const errorMessage2 = "Invalid value. Min: 1, Max: 99,999";
    const errorMessage3 = "Invalid value. Min: 0, Max: 99,999";
    setErrors({
      ...errors,
      name: _.isEmpty(tempAddData.name) ? errorMessage : "",
      client_category: tempAddData.client_category === " " ? errorMessage : "",
      data_admin: _.isEmpty(tempAddData.data_admin) ? errorMessage : "",
      landing_page:
        parseInt(tempAddData.landing_page) === 0 ? errorMessage : "",
      no_of_devices: _.isNaN(parseInt(tempAddData.no_of_devices))
        ? errorMessage
        : tempAddData.no_of_devices > 99999 || tempAddData.no_of_devices < 0
        ? errorMessage3
        : "",
      no_of_users: _.isNaN(parseInt(tempAddData.no_of_users))
        ? errorMessage
        : tempAddData.no_of_users > 99999 || tempAddData.no_of_users < 1
        ? errorMessage2
        : "",
      no_of_geofences: _.isNaN(parseInt(tempAddData.no_of_geofences))
        ? errorMessage
        : tempAddData.no_of_geofences > 99999 || tempAddData.no_of_geofences < 0
        ? errorMessage3
        : ""
    });

    if (modulesData.length) {
      setIsLoading(isLoading);
      setModulesError(false);
      // clearErrors();
      if (tempAddData.client_category === " ") {
        setErrors({
          ...errors,
          client_category: "This field is required"
        });
        setDisableSubmit(false);
        return true;
      }
      if (!parseInt(tempAddData.landing_page, 0)) {
        setErrors({
          ...errors,
          landing_page: "This field is required"
        });
        setDisableSubmit(false);
        return true;
      }

      if (areRequiredFieldsFilled) {
        // setErrors({
        //   ...errors,
        //   general: "Please fill all required fields!"
        // });
        setDisableSubmit(false);
        return true;
      }
      addAdminClient({
        variables: {
          name: tempAddData.name,
          category: tempAddData.client_category,
          user_count: parseInt(tempAddData.no_of_users, 0),
          device_count: parseInt(tempAddData.no_of_devices, 0),
          geofence_count: parseInt(tempAddData.no_of_geofences, 0),
          access_end_date: tempAddData.access_end_date,
          data_admin: tempAddData.data_admin,
          remarks: tempAddData.remarks,
          acl_module_id: parseInt(tempAddData.landing_page, 0),
          acl_modules: modulesData,
          client_preference: {
            welcome_logo: tempAddData.welcome_logo,
            welcome_text: tempAddData.welcome_text,
            tagline_text: tempAddData.tagline_text,
            header_logo: tempAddData.header_logo,
            header_text: tempAddData.header_text,
            primary_color: tempAddData.theme_color
          },
          client_codes: metaData,
          sms_sending_type: tempAddData.sms_sending_type,
          eta_enabled: tempAddData.eta_enabled,
          is_restricted: _.isEqual(tempAddData.client_type, "Restricted")
          // registered_IPS: tempAddData.registered_IPS
          //   ? tempAddData.registered_IPS.toString()
          //   : null,
          // api_key: tempAddData.api_key
          //   ? tempAddData.api_key
          //   : null
        }
      });
    }
    // setError(true);
    // setDisableSubmit(false);
    // setModulesError(true);
    return true;
  };

  React.useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div
      style={{
        background: "#EAEAEA",
        height: "100%"
      }}
    >
      <Header
        process="Add"
        // process={_.isEqual(initialState, tempAddData) ? "View" : "Add"}
        moduleName={process.env.REACT_APP_CLIENTS_MODULE}
        history={history?.location?.state}
        redirect={redirect}
        setDiscard={setDiscard}
        setRedirect={setRedirect}
        isDataNotChanged={_.isEqual(initialState, tempAddData)}
      />
      <div className={classes.container}>
        <Container
          style={{
            background: "#FFFFFF",
            // maxHeight: "75vh",
            // overflowY: "scroll",
            paddingTop: 10
          }}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
            className={classes.root}
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5">General Information</Typography>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink>
                      &nbsp;Client Names&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      error={!tempAddData.name && error}
                      id="client-name"
                      placeholder="Enter Client Name"
                      // InputLabelProps={{
                      //   shrink: true
                      // }}
                      // label="Client Name"
                      value={tempAddData.name}
                      name="name"
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                        // const attribute = e.target.getAttribute("name");
                        // setTempAddData({
                        //   ...tempAddData,
                        //   [attribute]: e.target.value.trim()
                        // });
                      }}
                    />
                    {errors.name && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.name}
                      </Alert>
                    )}
                  </Grid>
                  {/* Add New Field Client Type */}
                  {/* <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      id="client_type_id"
                      value={tempAddData.client_type}
                      getOptionLabel={option => option}
                      options={["Restricted", "Non-restricted"]}
                      onSelect={e => handleAddValues(e)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="client_type"
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={true}
                          label={
                            <Fragment>
                              <span>Client Type </span>
                            </Fragment>
                          }
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <InputLabel shrink>&nbsp;Client Type&nbsp;</InputLabel>
                    <TextField
                      fullWidth
                      id="client_type_id"
                      select
                      name="client_type"
                      value={tempAddData.client_type}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    >
                      <MenuItem value={tempAddData.client_type}>
                        {tempAddData.client_type}
                      </MenuItem>
                      {_.isEqual(tempAddData.client_type, "Non-restricted") ? (
                        <MenuItem value="Restricted"> Restricted</MenuItem>
                      ) : (
                        <MenuItem value="Non-restricted">
                          {" "}
                          Non-restricted
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink>
                      &nbsp;Category&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      error={tempAddData.client_category === " " && error}
                      // InputLabelProps={{
                      //   shrink: true
                      // }}
                      // label="Category"
                      id="select-category"
                      select
                      name="client_category"
                      value={tempAddData.client_category}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    >
                      <MenuItem value=" ">Select Category</MenuItem>
                      {[
                        "Division",
                        "Business Unit",
                        "Terminal",
                        "Distribution Center",
                        "Hardware"
                      ].map(category => (
                        <MenuItem key={`${category}1`} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.client_category && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.client_category}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Set maximum number of users for this client
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Set maximum number of users for this client"
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;No. of users&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      error={tempAddData.no_of_users < 1 && error}
                      fullWidth
                      id="number-of-users"
                      type="number"
                      // inputProps={{ min: "1", step: "1", max: "99999" }}
                      value={tempAddData.no_of_users}
                      name="no_of_users"
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.no_of_users && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.no_of_users}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Set maximum number of GPS devices for this client
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Set maximum number of GPS devices for this client"
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;No. of Devices&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      error={tempAddData.no_of_devices < 0 && error}
                      // inputProps={{ min: "0", step: "1", max: "99999" }}
                      id="number-of-devices"
                      type="number"
                      value={tempAddData.no_of_devices}
                      name="no_of_devices"
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.no_of_devices && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.no_of_devices}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Set maximum number of geofences for this client
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Set maximum number of geofences for this client"
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;No. of Geofences&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      error={tempAddData.no_of_geofences < 0 && error}
                      // inputProps={{ min: "0", step: "1", max: "10000" }}
                      id="number-of-geofences"
                      type="number"
                      value={tempAddData.no_of_geofences}
                      name="no_of_geofences"
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.no_of_geofences && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.no_of_geofences}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Access End Date&nbsp;
                    </InputLabel>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        style={{ width: "100%" }}
                        format="MM/DD/YYYY"
                        error={!tempAddData.access_end_date && error}
                        value={tempAddData.access_end_date}
                        name="access_end_date"
                        onChange={date =>
                          setTempAddData({
                            ...tempAddData,
                            access_end_date: moment(date).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          })
                        }
                        InputProps={{
                          placeholder: "Select Date/Time",
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventIcon />
                            </InputAdornment>
                          )
                        }}
                        showTodayButton
                        todayLabel="NOW"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      &nbsp;Data Admin&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Pending/ Registered
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Badge
                        variant="dot"
                        style={{ float: "right" }}
                        classes={{ badge: classes.customBadgePending }}
                      />
                    </HtmlTooltip>
                    <TextField
                      style={{ width: "100%" }}
                      id="standard-required"
                      error={!tempAddData.data_admin && error}
                      type="email"
                      placeholder="Enter Email Address"
                      InputLabelProps={{
                        shrink: true
                      }}
                      // label="Data Admin"
                      name="data_admin"
                      value={tempAddData.data_admin}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    />

                    {errors.data_admin && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.data_admin}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      id="sms-sending-combo-box"
                      value={tempAddData.sms_sending_type}
                      getOptionLabel={option => option}
                      options={["Not Applicable", "Globe labs", "Text me"]}
                      onSelect={e => handleAddValues(e)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="sms_sending_type"
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder="Select sms sending"
                          label="SMS Sending"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.switch}>
                      <InputLabel shrink className={classes.inputLabel}>
                        <HtmlTooltip
                          // title={
                          //   <React.Fragment>
                          //     <Typography color="inherit">
                          //       Turn on: Allow ETA calculations in trip update
                          //     </Typography>
                          //   </React.Fragment>
                          // }
                          placement="top-start"
                          title="Turn on: Allow ETA calculations in trip update"
                        >
                          <HelpIcon className={classes.helpIconForm} />
                        </HtmlTooltip>
                        &nbsp;ETA&nbsp;
                      </InputLabel>
                      <AntSwitch
                        color="primary"
                        name="eta_enabled"
                        checked={!!tempAddData.eta_enabled}
                        onChange={e => handleOnchange(e)}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      IP Address
                    </InputLabel>
                    <div style={{ paddingLeft: 10, width: "100%" }}>
                      <ChipInput
                        InputProps={{
                          className: classes.inputLabel,
                          height: "50px",
                          width: "100%",
                          placeholder: "Type here..."
                        }}
                        value={
                          tempAddData.registered_IPS
                            ? tempAddData.registered_IPS
                            : null
                        }
                        onKeyPress={handleKeyPress}
                        onAdd={chip => tempAddData.registered_IPS.push(chip)}
                        onDelete={chip =>
                          setTempAddData({
                            ...tempAddData,
                            registered_IPS: tempAddData.registered_IPS.filter(
                              item => !chip.includes(item)
                            )
                          })
                        }
                      />
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <br />
                    <InputLabel
                      shrink
                      className={classes.inputLabel}
                      style={{ float: "left" }}
                    >
                      API Key&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <InputLabel
                      shrink
                      className={classes.inputLabel}
                      style={{ float: "right" }}
                    >
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              if turned on, code will be generated.
                              <br />
                              &nbsp;A unique API Key for a client that has ERP
                              integration to TM Suite.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon />
                      </HtmlTooltip>
                      &nbsp; &nbsp;
                      <Switch
                        size="small"
                        color="primary"
                        checked={checkAPI.status}
                        onChange={e => generateAPI(e)}
                      />
                    </InputLabel>
                    <TextField
                      fullWidth
                      inputProps={{
                        readOnly: checkAPI.readOnly
                      }}
                      style={{ width: "85%" }}
                      id="standard-required"
                      error={!tempAddData.api_key && error}
                      type="text"
                      placeholder="Enter API Key"
                      InputLabelProps={{
                        shrink: true
                      }}
                      name="api_key"
                      value={tempAddData.api_key}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      // helperText={!tempAddData.api_key && <Helper message="This field is required" />}
                    />

                    {errors.api_key && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.api_key}
                      </Alert>
                    )}
                  </Grid> */}
                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Remarks
                    </InputLabel>
                    <TextareaAutosize
                      className={classes.inputLabel}
                      aria-label="minimum height"
                      minRows={3}
                      maxRows={6}
                      placeholder="Minimum 3 rows"
                      name="remarks"
                      value={tempAddData.remarks}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <HtmlTooltip
                    // title={
                    //   <React.Fragment>
                    //     <Typography color="inherit">
                    //       Customize theme, welcome text and logo, tagline text,
                    //       and header text and logo
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                    placement="top-start"
                    title="Customize theme, welcome text and logo, tagline text, and header text and logo"
                  >
                    <HelpIcon className={classes.helpIcon} />
                  </HtmlTooltip>
                  &nbsp;Other Information
                </Typography>
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Image specifications:
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Dimension: 100x100 px
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Format: .jpg, .png, .gif
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Preferably no background
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title={
                          <span>
                            Image specifications:
                            <br />
                            Dimension: 100x100 px
                            <br />
                            Format: .jpg, .png, .gif
                            <br />
                            Preferably no background
                          </span>
                        }
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;Welcome Logo&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    {welcomeLogoDefault ? (
                      <TextField
                        fullWidth
                        required
                        id="welcome-logo"
                        name="welcome_logo"
                        InputProps={{
                          readOnly: welcomeLogoDefault,
                          disableUnderline: welcomeLogoDefault,
                          endAdornment: (
                            <InputAdornment position="end">
                              <FormControlLabel
                                control={
                                  <div>
                                    <Checkbox
                                      color="primary"
                                      checked={welcomeLogoDefault}
                                      onChange={() => {
                                        setWelcomeLogoDefault(
                                          !welcomeLogoDefault
                                        );
                                      }}
                                      style={{ padding: 0 }}
                                      name="checkedWelcomeLogo"
                                    />
                                    <span style={{ fontSize: 12 }}>
                                      Use Default
                                    </span>
                                  </div>
                                }
                              />
                            </InputAdornment>
                          )
                        }}
                        placeholder="welcome_logo.png"
                      />
                    ) : (
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          style={{
                            padding: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <input
                            required
                            style={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
                            }}
                            type="file"
                            name="welcome_logo"
                            onChange={event => {
                              const { name } = event.target;
                              const {
                                target: {
                                  files: [file]
                                }
                              } = event;

                              setTempAddData({
                                ...tempAddData,
                                [name]: file
                              });
                            }}
                            accept=".jpg,.png,.gif"
                            multiple
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          align="right"
                          style={{ paddingRight: 10, paddingTop: 5 }}
                        >
                          <Checkbox
                            color="primary"
                            checked={welcomeLogoDefault}
                            onChange={() => {
                              setWelcomeLogoDefault(!welcomeLogoDefault);
                            }}
                            style={{ padding: 0 }}
                            name="checkedWelcomeLogo"
                          />
                          <span style={{ fontSize: 12 }}>Use Default</span>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      Welcome Text&nbsp;<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      required
                      id="welcome-text"
                      name="welcome_text"
                      value={tempAddData.welcome_text}
                      error={!tempAddData.welcome_text && error}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      InputProps={{
                        readOnly: welcomeTextDefault,
                        disableUnderline: welcomeTextDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    checked={welcomeTextDefault}
                                    onChange={() => {
                                      setWelcomeTextDefault(
                                        !welcomeTextDefault
                                      );
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedWelcomeText"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Welcome to Track Me Suite"
                    />
                    {errors.welcome_text && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.welcome_text}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      Tagline Text
                    </InputLabel>
                    <TextField
                      fullWidth
                      required
                      id="tagline-text"
                      name="tagline_text"
                      value={tempAddData.tagline_text}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      InputProps={{
                        readOnly: taglineTextDefault,
                        disableUnderline: taglineTextDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    checked={taglineTextDefault}
                                    onChange={() => {
                                      setTaglineTextDefault(
                                        !taglineTextDefault
                                      );
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedTaglineText"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Transform your Logistics"
                    />
                    {errors.tagline_text && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.tagline_text}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Image specifications:
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Dimension: 100x100 px
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Format: .jpg, .png, .gif
                        //     </Typography>
                        //     <Typography color="inherit">
                        //       Preferably no background
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title={
                          <span>
                            Image specifications:
                            <br />
                            Dimension: 100x100 px
                            <br />
                            Format: .jpg, .png, .gif
                            <br />
                            Preferably no background
                          </span>
                        }
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;Header Logo&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    {headerLogoDefault ? (
                      <TextField
                        fullWidth
                        required
                        id="header-logo"
                        name="header_logo"
                        InputProps={{
                          readOnly: headerLogoDefault,
                          disableUnderline: headerLogoDefault,
                          endAdornment: (
                            <InputAdornment position="end">
                              <FormControlLabel
                                control={
                                  <div>
                                    <Checkbox
                                      color="primary"
                                      checked={headerLogoDefault}
                                      onChange={() => {
                                        setHeaderLogoDefault(
                                          !headerLogoDefault
                                        );
                                      }}
                                      style={{ padding: 0 }}
                                      name="checkedHeaderLogo"
                                    />
                                    <span style={{ fontSize: 12 }}>
                                      Use Default
                                    </span>
                                  </div>
                                }
                              />
                            </InputAdornment>
                          )
                        }}
                        placeholder="header_logo.png"
                      />
                    ) : (
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          style={{
                            padding: 10,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <input
                            required
                            style={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
                            }}
                            type="file"
                            name="header_logo"
                            onChange={event => {
                              const { name } = event.target;
                              const {
                                target: {
                                  files: [file]
                                }
                              } = event;
                              setTempAddData({
                                ...tempAddData,
                                [name]: file
                              });
                            }}
                            accept=".jpg,.png,.gif"
                            multiple
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          align="right"
                          style={{ paddingRight: 10, paddingTop: 5 }}
                        >
                          <Checkbox
                            color="primary"
                            checked={headerLogoDefault}
                            onChange={() => {
                              setHeaderLogoDefault(!headerLogoDefault);
                            }}
                            style={{ padding: 0 }}
                            name="checkedHeaderLogo"
                          />
                          <span style={{ fontSize: 12 }}>Use Default</span>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      Header Text&nbsp;<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      required
                      id="header-text"
                      name="header_text"
                      value={tempAddData.header_text}
                      error={!tempAddData.header_text && error}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      InputProps={{
                        readOnly: headerTextDefault,
                        disableUnderline: headerTextDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    checked={headerTextDefault}
                                    onChange={() => {
                                      setHeaderTextDefault(!headerTextDefault);
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedHeaderText"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder="trackMe Suite"
                    />
                    {errors.header_text && (
                      <Alert severity="error" className={classes.alert}>
                        {errors.header_text}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel} shrink>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Set the primary theme of the trackMe Suite
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Set the primary theme of the trackMe Suite"
                      >
                        <HelpIcon className={classes.helpIconForm} />
                      </HtmlTooltip>
                      &nbsp;Themes&nbsp;<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      required
                      error={!tempAddData.theme_color && error}
                      id="theme-color"
                      name="theme_color"
                      value={tempAddData.theme_color}
                      InputProps={{
                        readOnly: themeDefault,
                        disableUnderline: themeDefault,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControlLabel
                              control={
                                <div>
                                  <Checkbox
                                    color="primary"
                                    checked={themeDefault}
                                    onChange={() => {
                                      setThemeDefault(!themeDefault);
                                    }}
                                    style={{ padding: 0 }}
                                    name="checkedHeaderText"
                                  />
                                  <span style={{ fontSize: 12 }}>
                                    Use Default
                                  </span>
                                </div>
                              }
                            />
                          </InputAdornment>
                        )
                      }}
                      placeholder="#3CA4F7"
                    />
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ border: "1px solid #000000" }}
                      >
                        <p style={{ marginLeft: 10, fontWeight: "bold" }}>
                          Web
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ border: "1px solid #000000" }}
                      >
                        <p style={{ marginLeft: "10%", marginBottom: 0 }}>
                          Presets
                        </p>

                        <RadioGroup
                          align="center"
                          aria-label="theme_color"
                          name="theme_color"
                          value={tempAddData.theme_color}
                          style={{
                            display: "block",
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 10
                          }}
                          // value={value}
                          onChange={e => {
                            handleAddValues(e);
                          }}
                        >
                          <FormControlLabel
                            value="#3CA4F7"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#3CA4F7",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#FFB74D"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#FFB74D",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#FFA1D4"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#FFA1D4",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#01BABD"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#01BABD",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#586E87"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#586E87",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#6D62E9"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#6D62E9",
                                  height: 5,
                                  width: 5
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="#000000"
                            disabled={themeDefault}
                            control={
                              <Radio
                                style={{
                                  background: "#000000",
                                  height: 5,
                                  width: 5
                                }}
                                onClick={e => {
                                  setColorPickerAnchor(e.currentTarget);
                                  setViewColorPicker(true);
                                }}
                              />
                            }
                          />
                        </RadioGroup>

                        <Popper
                          open={viewColorPicker}
                          anchorEl={colorPickerAnchor}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          <ClickAwayListener
                            onClickAway={() => {
                              setViewColorPicker(false);
                            }}
                          >
                            <SketchPicker
                              color={tempAddData.theme_color}
                              onChangeComplete={col =>
                                setTempAddData({
                                  ...tempAddData,
                                  theme_color: col.hex
                                })
                              }
                            />
                          </ClickAwayListener>
                        </Popper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <PartnersTable setPartners={setPartners} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography
                  variant="h6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <HtmlTooltip
                    // title={
                    //   <React.Fragment>
                    //     <Typography color="inherit">
                    //       Set modules that will be accessible for the client
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                    placement="top-start"
                    title="Set modules that will be accessible for the client"
                  >
                    <HelpIcon className={classes.helpIcon} />
                  </HtmlTooltip>
                  &nbsp;Modules&nbsp;<span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    border: "1px solid #cccccc80"
                  }}
                >
                  <ModuleTree
                    updateSelectedModules={handleUpdateSelectedModules}
                  />
                  {/* {listModules &&
                    listModules.modules
                      .filter(obj => obj.parent_id === "0")
                      .map(row => {
                        const children = [];
                        const mainCheckbox = [];

                        listModules.modules
                          .filter(obj => obj.parent_id === row.id)
                          .map(c_row => {
                            children.push(c_row.id);
                            if (
                              c_row.permission.view ||
                              c_row.permission.edit
                            ) {
                              mainCheckbox.push(true);
                            }
                            return true;
                          });
                        return (
                          <ExpansionPanel
                            square
                            key={row.id}
                            expanded={expanded === row.name}
                            onChange={handleChange(row.name)}
                          >
                            <ExpansionPanelSummary
                              aria-controls="panel1d-content"
                              expandIcon={
                                children.length ? (
                                  <ExpandMoreIcon />
                                ) : (
                                  <IconButton
                                    name={row.name}
                                    onClick={e => {
                                      setAnchorEl(e.currentTarget);
                                      handleToggle();
                                    }}
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                )
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                                key={row.id}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        children.length > 0
                                          ? mainCheckbox.length !== 0
                                          : row.permission.view
                                      }
                                      onClick={e => {
                                        const newModules = listModules.modules;

                                        newModules.map(r => {
                                          const module = r;
                                          if (
                                            module.parent_id === row.id ||
                                            module.id === row.id
                                          ) {
                                            if (e.target.checked) {
                                              module.permission.view = true;
                                              module.permission.edit = true;
                                            } else {
                                              module.permission.view = false;
                                              module.permission.edit = false;
                                            }
                                          }
                                          return module;
                                        });

                                        setTempAddData({
                                          ...tempAddData,
                                          landing_page: "0"
                                        });
                                        setListModules({
                                          ...listModules,
                                          modules: newModules
                                        });
                                      }}
                                      color="primary"
                                      name={row.name}
                                    />
                                  }
                                  label={row.name}
                                />
                              </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                              style={{
                                display: "block",
                                borderTop: "solid",
                                borderTopWidth: "1px",
                                borderTopColor: "lightgray",
                                padding: "8px 20px"
                              }}
                            >
                              {listModules &&
                                listModules.modules
                                  .filter(obj => obj.parent_id === row.id)
                                  .map(c_row => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                        key={c_row.id}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={c_row.permission.view}
                                              color="primary"
                                              onClick={e => handleChange(e)}
                                              onChange={() => {
                                                const newModules =
                                                  listModules.modules;
                                                newModules.map(r => {
                                                  const module = r;
                                                  if (
                                                    module.id ===
                                                      c_row.parent_id ||
                                                    module.id === c_row.id
                                                  ) {
                                                    module.permission.view = !c_row
                                                      .permission.view;
                                                    if (
                                                      !c_row.permission.view
                                                    ) {
                                                      module.permission.edit = false;
                                                    }
                                                  }
                                                  return module;
                                                });

                                                setTempAddData({
                                                  ...tempAddData,
                                                  landing_page: "0"
                                                });
                                                setListModules({
                                                  ...listModules,
                                                  modules: newModules
                                                });
                                              }}
                                              name={c_row.name}
                                            />
                                          }
                                          label={c_row.name}
                                          labelPlacement="end"
                                        />
                                        <div style={{ flexGrow: 1 }} />
                                        <IconButton
                                          name={c_row.name}
                                          onClick={e => {
                                            setAnchorEl(e.currentTarget);
                                            handleToggle();
                                          }}
                                        >
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      })} */}
                </Grid>
                {modulesError && (
                  <Alert severity="error" className={classes.alert}>
                    Please select atleast one module
                  </Alert>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    error={parseInt(tempAddData.landing_page, 0) === 0 && error}
                    id="landing-page"
                    fullWidth
                    select
                    label="Landing Page"
                    value={tempAddData.landing_page}
                    name="landing_page"
                    onChange={e => {
                      handleAddValues(e);
                    }}
                    style={{ marginTop: 30 }}
                  >
                    {/* <MenuItem value={tempAddData.landing_page}>
                      {checkList.filter(list => list.id === tempAddData.landing_page).map(list => list.label)}
                    </MenuItem> */}
                    <MenuItem value={0} disabled>
                      <span style={{ color: "#8e8e8e" }}>
                        Select Landing Page
                      </span>
                    </MenuItem>
                    {adminFieldExist && <MenuItem value="1">Admin</MenuItem>}
                    {checkList
                      .filter(
                        list => list.name.trim().substring(0, 5) !== "Admin"
                      )
                      .map(list => {
                        return (
                          <MenuItem
                            key={list.id}
                            value={list.id}
                            disabled={list.id === "0"}
                          >
                            {list.name}
                          </MenuItem>
                        );
                      })}
                    {/* {listModules && listModules.modules
                      ? listModules.modules
                          .filter(obj => obj.parent_id === "0")
                          .map(row => {
                            let isVisible = 0;
                            if (row.permission.view) {
                              isVisible = 1;
                              // return (
                              //   <MenuItem key={row.id} value={row.id}>
                              //     {row.name}
                              //   </MenuItem>
                              // )
                            }
                            listModules.modules
                              .filter(obj => obj.parent_id === row.id)
                              .map(row2 => {
                                if (row2.permission.view) isVisible = 1;
                                return isVisible;
                              });

                            if (isVisible && tempAddData.landing_page !== row.id) {
                              if (row.resource.split("/")[1] !== "admin") {
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                );
                              }

                              if (!checker) {
                                checker = true;
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    Admin
                                  </MenuItem>
                                );
                              }
                            }

                            return "";
                          })
                      : ""} */}
                  </TextField>
                  {errors.landing_page && (
                    <Alert
                      severity="error"
                      // style={{ justifyContent: "center" }}
                      className={classes.alert}
                    >
                      {errors.landing_page}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Toolbar>
                        <Typography
                          variant="h6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: "1"
                          }}
                        >
                          <HtmlTooltip
                            // title={
                            //   <React.Fragment>
                            //     <Typography color="inherit">
                            //       Used as reference code for ERP integration to
                            //       TM Suite
                            //     </Typography>
                            //   </React.Fragment>
                            // }
                            placement="top-start"
                            title="Used as reference code for ERP integration to TM Suite"
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </HtmlTooltip>
                          &nbsp;Client Code
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "2px solid #D2D2D2",
                              boxSizing: "border-box",
                              borderRadius: 23,
                              padding: "4px 10px 3px 10px",
                              marginRight: 20
                            }}
                          >
                            <InputBase
                              placeholder="Search…"
                              style={{ width: "100%" }}
                              classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput
                              }}
                              onChange={() => {
                                // setUserKeyword(e.target.value);
                              }}
                              inputProps={{ "aria-label": "search" }}
                            />
                            <div className={classes.searchIcon}>
                              <SearchIcon />
                            </div>
                          </div>
                        </div>
                      </Toolbar>
                      {selected.length > 0 ? (
                        <Toolbar
                          className={clsx(classes.TBroot, {
                            [classes.highlight]: selected.length > 0,
                            [classes.invisible]: selected.length <= 0
                          })}
                        >
                          {selected.length > 0 && (
                            <Typography
                              className={classes.title}
                              color="inherit"
                              variant="subtitle1"
                              component="div"
                            >
                              <span style={{ color: "#FF845E" }}>
                                {selected.length}
                              </span>{" "}
                              client/s selected
                            </Typography>
                          )}

                          {selected.length > 0 && (
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleOpenDeleteAll();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Toolbar>
                      ) : null}
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  indeterminate={
                                    selected.length > 0 &&
                                    selected.length < metaData.length
                                  }
                                  onChange={handleSelectAllClick}
                                  checked={
                                    selected.length > 0 &&
                                    selected.length === metaData.length
                                  }
                                />
                              </TableCell>
                              {headCells.map(headCell => (
                                <TableCell
                                  key={headCell.id}
                                  // sortDirection={orderBy === headCell.id ? order : false}
                                >
                                  <TableSortLabel
                                  // active={orderBy === headCell.id}
                                  // direction={orderBy === headCell.id ? order : "asc"}
                                  // onClick={() => handleSort(headCell.id)}
                                  >
                                    {headCell.label}
                                    {/* {orderBy === headCell.id ? (
                                      <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                      </span>
                                    ) : null} */}
                                  </TableSortLabel>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {metaData.length > 0 ? (
                              metaData.map((row, index) => {
                                const isItemSelected = isSelected(row.code);
                                const labelId = `enhanced-table-checkbox-${row.code}`;
                                return (
                                  <TableRow
                                    key={`row-${row.code}`}
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    onClick={() => handleClick(row.code)}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId
                                        }}
                                        color="primary"
                                      />
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      <Highlight
                                        search={contextData.searchKeyword.value}
                                      >
                                        {row.code}
                                      </Highlight>
                                    </TableCell>
                                    <TableCell>
                                      <Highlight
                                        search={contextData.searchKeyword.value}
                                      >
                                        {row.remarks}
                                      </Highlight>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleToDelete(index);
                                          handleOpen();
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow style={{ height: 53 * 2 }}>
                                <TableCell colSpan={4} align="center">
                                  No data found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <br />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  {/* <InfoBubble editMode={editMode} ref={InfoBubbleRef} /> */}
                  <Toolbar className={classes.TBroot}>
                    <Typography
                      variant="h5"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit">
                        //       Set the alerts that should appear in the info bubble.
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        placement="top-start"
                        title="Set the alerts that should appear in the info bubble."
                      >
                        <HelpIcon className={classes.helpIcon} />
                      </HtmlTooltip>
                      &nbsp;Vehicle Info Bubble&nbsp;&nbsp;
                    </Typography>
                  </Toolbar>
                  <div style={{ border: "1px solid rgba(204, 204, 204, 0.5)" }}>
                    {Object.keys(InfoBubbleRef.current).map((d, i) => {
                      if (d !== "client_id")
                        return (
                          <div key={d} className={classes.summaryLabelWrapper}>
                            <FormControlLabel
                              classes={{ root: classes.summaryLabelRoot }}
                              control={
                                <Checkbox
                                  color="primary"
                                  defaultChecked={InfoBubbleRef.current[d]}
                                  // checked={InfoBubbleRef.current[d]}
                                  classes={{ root: classes.checkboxRoot }}
                                  onChange={(e, val) => {
                                    InfoBubbleRef.current = {
                                      ...InfoBubbleRef.current,
                                      [d]: +val
                                    };
                                  }}
                                  style={{ padding: 0, marginLeft: 15 }}
                                  name={d}
                                />
                              }
                            />
                            <Typography
                              classes={{ root: classes.typographyRoot }}
                            >
                              &nbsp;&nbsp;
                              {d === "power_source"
                                ? "Running in auxiliary"
                                : _.startCase(d)}
                            </Typography>
                          </div>
                        );
                      return null;
                    })}
                  </div>
                  <br />
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                {/* {errors.general && (
                  <Alert
                    severity="error"
                    style={{ justifyContent: "center" }}
                    className={classes.alert}
                  >
                    {errors.general}
                  </Alert>
                )} */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit}
                  fullWidth
                  style={{
                    margin: 20,
                    width: "30%",
                    marginTop: 10,
                    borderRadius: "100px"
                  }}
                  onClick={handleSubmit}
                >
                  Save Details
                </Button>
              </Grid>
            </Grid>
          </form>
          {anchorEl && (
            <EditViewMenuTest
              open={popper}
              anchorEl={anchorEl}
              access={listModules.modules.filter(
                obj => obj.name === anchorEl.name
              )}
              handleClosePopper={handleClosePopper}
              handleListKeyDown={handleListKeyDown}
              handleAccessEdit={handleAccessEdit}
              handleAccessView={handleAccessView}
            />
          )}
          <ConfirmationDialog
            toggle={dialog.discard}
            close={() => handleCloseDialog("discard")}
            fn={() => discarded()}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />
        </Container>

        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />

        <ConfirmationDialog
          toggle={toOpen}
          close={() => handleClose()}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this client code?"
        />

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Client Code</DialogTitle>
          <DialogContent>
            <InputLabel
              shrink
              className={classes.inputLabel}
              style={{ float: "left" }}
            >
              Client Code&nbsp;
              <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputLabel
              shrink
              className={classes.inputLabel}
              style={{ float: "right" }}
            >
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      If turned on, code will be generated
                    </Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className={classes.helpIconForm} />
              </HtmlTooltip>
              &nbsp; &nbsp;
              <Switch
                size="small"
                color="primary"
                checked={check.status}
                onChange={generateClientCode}
              />
            </InputLabel>
            <TextField
              // required
              minRows={3}
              autoFocus
              margin="dense"
              id="code"
              name="code"
              placeholder="Enter Client Code"
              type="text"
              fullWidth
              inputProps={{
                readOnly: check.readOnly
              }}
              value={generatedCC || null}
              onChange={ccOnChange}
              onKeyPress={checkCCInput}
              helperText={
                helpers.code ? <Helper message={helpers.code} /> : null
              }
            />
            <InputLabel shrink className={classes.inputLabel}>
              Remarks
            </InputLabel>
            <TextareaAutosize
              minRows={3}
              aria-label="minimum height"
              style={{ height: "50px", width: "100%" }}
              autoFocus
              margin="dense"
              id="remarks"
              name="remarks"
              placeholder="Type Here..."
              type="text"
              fullWidth
              onChange={ccOnChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleAddCC}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleMultipleDeleteCode(e)}
          title="Delete?"
          content="Are you sure you want to delete these client codes?"
        />
      </div>
    </div>
  );
}
