/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import AccessControl from "../../utils/AccessControl";
import SearchContext from "../../context/SearchContext";
import AddLogs from "../../utils/functions/AddLogs";
import UserFeedback from "./components/UserFeedback";
import Sidebar from "./components/Sidebar";

const Highlight = require("react-highlighter");
const questions = require("../../data/questions.json");

const Feedback = () => {
  const [view, setView] = useState(0);
  const [keyword, setKeyword] = useState("");
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [loaded, hasLoaded] = useState(false);

  useEffect(() => {
    setSearchKeyword({ ...searchKeyword, value: "", submit: false });
    hasLoaded(true);
  }, []);

  useEffect(() => {
    if (searchKeyword.submit && loaded) {
      setView(0);
      if (searchKeyword.value && keyword !== searchKeyword.value) {
        AddLogs("Help and Feedback", "search", searchKeyword.value);
        setKeyword(searchKeyword.value);
      }
    }
  }, [searchKeyword]);

  return (
    <AccessControl process="feedback" resource="/feedback/">
      <StyledFAQContainer style={{ overflowY: view === 0 ? "auto" : "hidden" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={2}>
            <Sidebar view={view} setView={setView} />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            {view === 0 ? (
              <>
                <StyledTitleTypography noWrap>
                  Frequently Asked Questions?
                </StyledTitleTypography>
                <StyledFAQBox>
                  {questions.data.map((list, index) => (
                    <div key={`module-${index}`} id={`faq-${index}`}>
                      {!searchKeyword.value && (
                        <StyledModuleTypography noWrap>
                          {list.module}
                        </StyledModuleTypography>
                      )}
                      {list.questions.map((q, i) => {
                        if (
                          q.question
                            .toLowerCase()
                            .includes(searchKeyword.value?.toLowerCase()) ||
                          q.answer
                            .toLowerCase()
                            .includes(searchKeyword.value?.toLowerCase())
                        ) {
                          return (
                            <StyledPanel key={`question-${i}`}>
                              <StyledPanelSummary
                                expandIcon={<StyledPanelIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <StyledQuestion search={searchKeyword.value}>
                                  {q.question}
                                </StyledQuestion>
                              </StyledPanelSummary>
                              <AccordionDetails>
                                <StyledAnswer search={searchKeyword.value}>
                                  {q.answer}
                                </StyledAnswer>
                              </AccordionDetails>
                            </StyledPanel>
                          );
                        }
                      })}
                    </div>
                  ))}
                </StyledFAQBox>
              </>
            ) : (
              <UserFeedback />
            )}
          </Grid>
        </Grid>
      </StyledFAQContainer>
    </AccessControl>
  );
};

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "700",
  fontSize: 36,
  fontFamily: "Nunito",
  lineHeight: "49px",
  marginTop: 30,
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    fontSize: 25
  }
}));

const StyledQuestion = styled(Highlight)(() => ({
  fontSize: 13,
  fontFamily: "Nunito",
  fontWeight: "600"
}));

const StyledAnswer = styled(Highlight)(({ theme }) => ({
  fontSize: 13,
  fontFamily: "Nunito",
  color: theme.palette.primary.dark
}));

const StyledPanel = styled(Accordion)(() => ({
  marginTop: "10px",
  marginBottom: "10px",
  borderRadius: 9,
  position: "static"
}));

const StyledPanelSummary = styled(AccordionSummary)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18
}));

const StyledPanelIcon = styled(ExpandMoreRounded)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "50%"
}));

const StyledModuleTypography = styled(Typography)(({ theme }) => ({
  marginTop: "30px",
  fontSize: 13,
  letterSpacing: 1.2,
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  lineHeight: "18px"
}));

const StyledFAQContainer = styled("div")(({ theme }) => ({
  height: "95vh",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: 5,
    backgroundColor: "#F9F9F9 !important",
    borderRadius: "0px !important"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F9F9F9 !important",
    border: "none !important"
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    border: "0px solid  rgba(0, 0, 0, 0) !important",
    borderRadius: 0
  },
  [theme.breakpoints.down("sm")]: {
    height: "95vh"
  }
}));

const StyledFAQBox = styled("div")(() => ({
  overflowY: "auto",
  padding: "1rem",
  overflow: "hidden"
}));

export default withRouter(Feedback);
