/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from "react";
import { Typography, Grid, Box, Badge, Avatar } from "@material-ui/core";
import {
  TripOrigin,
  Visibility,
  History,
  Assignment,
  Share as ShareIcon,
  BuildRounded as UnderRepairIcon,
  SettingsApplications as Settings
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { useStyles } from "../../../../Monitoring/MapPartials/CustomInfoBubble.js/InfoBubbleStyle";
import ActionButton from "../../../../Monitoring/MapPartials/CustomInfoBubble.js/ActionButton";
import not_in_use_icon from "../../../../../assets/Not_in_Use.png";
import AddLogs from "../../../../../utils/functions/AddLogs";
import InfoBubbleDetail from "../../../../Monitoring/MapPartials/CustomInfoBubble.js/InfoBubbleDetail";
import Battery from "../../../../../utils/Battery";
import HistorySelector from "../../../../Monitoring/HistorySelector";
import SharelinkDialog from "../../../../../utils/modals/SharelinkDialog";
import { gpsStatus } from "../../../../Utils";
import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";
import ShareLocation from "../../../../../utils/modals/ShareLocation";
import Swal from "sweetalert2";

const no_vehicle = {
  id: "",
  under_repair: "",
  not_in_use: "",
  ignition_status: "",
  door_status: "",
  has_fuel_sensor: "",
  has_temp_sensor: "",
  plateno: "",
  trip_number: "",
  client_id: "",
  device_info: null,
  personnel_name: ""
};

/* make this info window dynamic for all components can use */
const InfoBubble = props => {
  const vehicleRowRef = useRef({});
  // external styles
  const classes = useStyles();
  // props values
  const {
    componentType,
    // vehicleInfo,
    historyHandler,
    isOpenHistoryModal,
    streetViewHandler,
    infoBubble,
    // filterVal,
    // vars,
    history,
    data
  } = props;

  const {
    under_repair,
    not_in_use,
    ignition_status,
    door_status,
    has_fuel_sensor,
    has_temp_sensor,
    plateno,
    trip_number,
    client_id,
    device_info =  null,
    personnel_name
  } = data.vehicle_info ?? no_vehicle;
  const vehicleInfo = data.vehicle_info ?? no_vehicle;
  const [shareDialogDetails, setShareDialogDetails] = useState({
    isOpen: false,
    vehicle: {}
  });

  const features =
    "menubar=yes,loaction=yes,resizaable=no,scrollbars=yes,status=no,noopener";
  const [generatedLink, setGeneratedLink] = useState("");
  const user = JSON.parse(Cookie.get("user"));
  const clientType = user?.client.is_restricted;
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const { permissions } = useAllowedPermissions("/admin/resources/vehicles/");

  // update or edit vehicle settings redirect to vehicle settings component.
  const deviceSettingsHandler = () =>
    AddLogs("Admin - GPS Device", "view", data.name);
  // trip handler tool.
  const withTrip = !_.isEmpty(trip_number) ? trip_number?.length > 0 : false;
  const withTripHandler = () => {
    if (withTrip)
      history.push({ pathname: "/trips", state: { params: { trip_numbers: [trip_number] } } });
  };

  const svHandler = () => {
    const sv = new window.google.maps.StreetViewService();
    const position = {
      lat: data?.device_status.latitude,
      lng: data?.device_status.longitude,
    };
    sv.getPanorama({
      location: position
    }, (data, status) => {
      if (status === "OK") {
        streetViewHandler(position);
      }
      else {
        Swal.fire({
          title: "Streetview not available",
          icon: "error",
          showConfirmButton: false,
          timer: 3000
        });
      }
    })
  };

  const redirectTo = (r, params = {}) => {
    history.push({
      pathname: r,
      state: {
        params: params,
        // filterValues: filterVal,
        // vars: vars,
        historyFilterVal: params.historyFilterVal
      }
    });
  };

  // let isIsolateViewDisabled = false;
  // // set isolate view disabled
  // if (componentType === "Isolate" /* || vehicleInfo.device_id === "0" */)
  //   isIsolateViewDisabled = true;

  /*
   *** share link function for opening and closing modal share.
   */
  function shareModalHandler() {
    setShareDialogDetails({
      isOpen: !shareDialogDetails.isOpen,
      vehicle: data // device_info
    });
    vehicleRowRef.current = {
      id: vehicleInfo.id,
      device_info: {
        device_status: data?.device_status
      }
    };
  }
  /*
   *** generate the link for the user with the specific vehicle redirected to google maps.
   */
  function generateShareLinkHandler() {
    const { latitude, longitude } = shareDialogDetails.vehicle.device_status;
    setGeneratedLink(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    );
  }

  const alertLength = data?.device_status?.AlertReferences?.length || 0;

  return (
    <>
      <Grid container item md={12}>
        <Box component="div" className={classes.infoHeader}>
          <Box component="div" className={classes.infoLabelBox}>
            <TripOrigin
              className={classes.labelIconStatus}
              style={{
                color: gpsStatus(data?.device_status.status_code.toLowerCase())
                  .color
              }}
            />
            <Typography
              variant="body2"
              className={classes.label}
              style={{
                color: gpsStatus(data?.device_status.status_code.toLowerCase())
                  .color
              }}
            >
              {data.name}
            </Typography>
          </Box>
          <Box component="div" className={classes.infoToolHeader}>
            {under_repair && (
              <ActionButton title="Under Repair">
                <Avatar className={classes.avatar}>
                  <UnderRepairIcon className={classes.underRepair} />
                </Avatar>
              </ActionButton>
            )}
            {not_in_use && (
              <ActionButton avatarSrc={not_in_use_icon} title="Not in use">
                <Avatar src={not_in_use_icon} />
              </ActionButton>
            )}
            {(user.client.id === client_id ||
              isWTIAdmin ||
              (user.client.id !== client_id && !clientType)) && (
              <ActionButton
                isDisabled={!vehicleInfo.id || !vehicleInfo.client_id}
                title="Isolate"
                onClickHandler={() => {
                  // redirectTo("/monitoring/isolate", {
                  //   device_ids: [vehicleInfo.device_id],
                  //   vehicle_ids: [vehicleInfo.id]
                  // });
                  AddLogs("Admin - GPS Device", "isolate_view", data.name);
                  const vehicleIds = JSON.stringify(vehicleInfo.id);
                  window.open(
                    `/monitoring/:view/isolate?vehicle_id=${vehicleIds}`,
                    "_blank",
                    features
                  );
                }}
              >
                <Avatar className={classes.avatar}>
                  <Visibility
                    className={classes.actionIcon}
                    style={{ opacity: 1 }}
                  />
                </Avatar>
              </ActionButton>
            )}
            <ActionButton
              onClickHandler={historyHandler}
              // isDisabled={
              //   !!(
              //     !data?.device_type_id ||
              //     data?.device_status.status_code_id === 0 ||
              //     true
              //   )
              // }
              title="View History"
            >
              <Avatar className={classes.avatar}>
                <History
                  className={classes.actionIcon}
                  style={{
                    opacity:
                      !data?.device_type_id ||
                      data?.device_status.status_code_id
                        ? 1
                        : 0.5
                  }}
                />
              </Avatar>
            </ActionButton>
            {isOpenHistoryModal?.display && (
              <HistorySelector
                open={isOpenHistoryModal?.display}
                vehicle={{
                  client_id: data?.client_id,
                  device_id: data?.id,
                  plateno: data?.vehicle_info?.plateno,
                  group_ids: data?.group_ids,
                  id: data?.vehicle_info?.id,
                  devicealias: data?.devicealias
                }}
                onClose={() => {
                  historyHandler();
                }}
                title="History"
                redirectTo={redirectTo}
                isFromDevices
              />
            )}

            {permissions?.view && (
              <ActionButton
                onClickHandler={deviceSettingsHandler}
                isDisabled={false}
                title="GPS Device Settings"
              >
                <Avatar className={classes.avatar}>
                  <Link
                    to={{
                      pathname: `/admin/resources/devices/view/${data.id}`
                      // state: {
                      //   source: `/monitoring`,
                      //   filterValues: filterVal,
                      //   vars: vars
                      // }
                    }}
                  >
                    <Settings className={classes.actionIcon} />
                  </Link>
                </Avatar>
              </ActionButton>
            )}
            <ActionButton
              onClickHandler={shareModalHandler}
              isDisabled={
                !!(
                  !data?.device_type_id ||
                  data?.device_status.status_code_id === 0
                )
              }
              title={
                !data?.device_type_id ||
                data?.device_status.status_code_id === 0
                  ? "No paired device"
                  : "Share Link"
              }
            >
              <Avatar className={classes.avatar}>
                <ShareIcon
                  className={classes.actionIcon}
                  style={{
                    opacity:
                      !data?.device_type_id ||
                      data?.device_status.status_code_id
                        ? 1
                        : 0.5
                  }}
                />
              </Avatar>
            </ActionButton>
            <ActionButton
              onClickHandler={withTripHandler}
              // isDisabled={true}
              title="Trips"
            >
              <Badge variant="dot" color="secondary" invisible={!withTrip}>
                <Assignment
                  className={withTrip ? classes.withTrip : classes.actionIcon}
                />
              </Badge>
            </ActionButton>
            <ActionButton
              onClickHandler={svHandler}
              isDisabled={false}
              title="Street View"
            >
              <Avatar className={classes.avatar}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/pegman.png`}
                  className={classes.pegman}
                  alt="pegman icon"
                />
              </Avatar>
            </ActionButton>
          </Box>
        </Box>
      </Grid>
      <InfoBubbleDetail
        label="Last Report:"
        content={`${moment(data?.device_status.reportstamp).format(
          "MMM DD, YYYY hh:mm A"
        )}`}
      />
      <InfoBubbleDetail
        label="Alert Message:"
        content={
          alertLength
            ? data?.device_status?.AlertReferences.map((alert, index) => (
                <React.Fragment>
                  {alert.alertmessage}
                  {index !== alertLength - 1 && ","}
                  <br />
                </React.Fragment>
              ))
            : "-"
        }
      />
      {infoBubble?.ignition ? (
        <InfoBubbleDetail label="Ignition:" content={ignition_status || "-"} />
      ) : (
        ""
      )}
      {infoBubble?.driver ? (
        <InfoBubbleDetail
          label="Driver Name:"
          content={personnel_name || "-"}
        />
      ) : (
        ""
      )}
      {infoBubble?.battery ? (
        <InfoBubbleDetail
          label="Battery:"
          content={
            <Battery
              vehicleType={vehicleInfo.vehicle_type}
              deviceTypeId={data?.device_type_id}
              batteryLevel={data?.device_status.battery_level}
              tooltipTitle={
                <>
                  <Typography className={classes.batteryLevel}>
                    {`Battery Life: ${data?.device_status.battery_level}%`}
                  </Typography>
                  <Typography className={classes.batteryReportStamp}>
                    {`As of: ${moment(data?.device_status.reportstamp).format(
                      "lll"
                    )}`}
                  </Typography>
                </>
              }
              showText
              showNAText
            />
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.door ? (
        <InfoBubbleDetail label="Door:" content={door_status || "-"} />
      ) : (
        ""
      )}
      {infoBubble?.fuel && has_fuel_sensor ? (
        <InfoBubbleDetail
          label="Fuel:"
          content={
            data?.device_status?.fuelconsumption >= 0
              ? data?.device_status?.fuelconsumption
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.odometer_reading ? (
        <InfoBubbleDetail
          label="Odometer Reading:"
          content={`${
            data?.device_status.distancetraveled >= 0
              ? Math.round(data?.device_status.distancetraveled / 1000).toLocaleString("en-US")
              : "-"
          } km`}
        />
      ) : (
        ""
      )}
      {infoBubble?.power_source ? (
        <InfoBubbleDetail
          label="Running in auxiliary:"
          content={
            !_.isEmpty(data?.device_status.powersource)
              ? data?.device_status.powersource === "AUX"
                ? "Yes"
                : "No"
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.speed ? (
        <InfoBubbleDetail
          label="Speed:"
          content={
            data?.device_status.gpsSpeed >= 0
              ? `${data?.device_status.gpsSpeed} kph`
              : "-"
          }
        />
      ) : (
        ""
      )}
      {infoBubble?.temperature && has_temp_sensor ? (
        <InfoBubbleDetail
          label="Temperature:"
          content={
            <>
              {data?.device_status.temperature >= 0
                ? data?.device_status.temperature
                : "-"}
              &deg;C
            </>
          }
        />
      ) : (
        ""
      )}
      <InfoBubbleDetail
        label="Current Location:"
        content={data?.device_status.lastlocation}
      />
      {infoBubble?.coordinates ? (
        <InfoBubbleDetail
          label="Coordinates:"
          content={
            (data?.device_status.latitude && data?.device_status.longitude)
              ? `${data?.device_status.latitude},${data?.device_status.longitude}`
              : "-"
          }
        />
      ) : (
        ""
      )}
      <ShareLocation
        title="Share Location"
        description="Generate link and share the location of your vehicle/s"
        toggle={shareDialogDetails.isOpen}
        closeHandler={() => {
          setShareDialogDetails({ isOpen: false, vehicle: {} });
        }}
        vehicleInfo={vehicleRowRef.current && [vehicleRowRef.current]}
      />
      {/* <SharelinkDialog
        toggle={shareDialogDetails.isOpen}
        close={() => {
          setShareDialogDetails({ isOpen: false, vehicle: {} });
          setGeneratedLink("");
        }}
        title="Share Location"
        content="Generate Link and share the location of your vehicle"
        generatedLink={generatedLink}
        fn={generateShareLinkHandler}
      /> */}
    </>
  );
};

export default InfoBubble;
