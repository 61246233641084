import React from "react";
import TruckAvailability from "./TruckAvailability";
import Delivery from "./Delivery";
import CloseCancelled from "./CloseCancelled";
import DashboardContainer from "../../../utils/UI/Container/DashboardContainer";
import useFetchChartData from "../../../utils/hooks/useFetchChartData";
import { GET_CHART_DATA } from "../../../graphql/Queries";
import Loading from "../../../utils/Loading";

// displays the all the available charts in the on time tab.
const OnTime = props => {
  const { variables, history } = props;
  const chartData = useFetchChartData(GET_CHART_DATA, variables, "OnTime");
  
  return chartData.isLoading ? (
    <Loading />
  ) : (
    <>
      <DashboardContainer label="On Time Truck Availability">
        <TruckAvailability chartData={chartData.data} history={history} />
      </DashboardContainer>
      <DashboardContainer label="On Time Delivery">
        <Delivery chartData={chartData.data} history={history} />
      </DashboardContainer>
      <DashboardContainer label="Closed/Cancelled">
        <CloseCancelled chartData={chartData.data} history={history} />
      </DashboardContainer>
    </>
  );
};

export default OnTime;
