export default day => {
  switch (day[day.length - 1]) {
    case 1:
    case "1":
      // eslint-disable-next-line eqeqeq
      return day != 11 ? `${day}st` : `${day}th`;
    case 2:
    case "2":
      // eslint-disable-next-line eqeqeq
      return day != 12 ? `${day}nd` : `${day}th`;
    case 3:
    case "3":
      // eslint-disable-next-line eqeqeq
      return day != 13 ? `${day}rd` : `${day}th`;
    default:
      return `${day}th`;
  }
};
