import React, { useState, useRef, Fragment, useEffect } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Paper, TextField, Tooltip, Typography, makeStyles, useTheme } from "@material-ui/core";
import { ArrowBack, Attachment, Error, MoreVert } from "@material-ui/icons";
import { Link } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import AddLogs from "../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    padding: "0.5rem",
    boxShadow: "none",
    borderRadius: 0,
    borderBottom: "1px solid rgba(209, 209, 209, 0.58)",
  },
  titleText: {
    fontSize: 20,
    color: theme.palette.primary.dark,
    fontWeight: "600",
    fontFamily: "Nunito",
  },
  flexContainer: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between",
  },
  saveButton: {
    borderRadius: "25px",
    // margin: "",
    fontSize: "12px",
    padding: "7px 35px",
    float: "right"
  },
  manualCompleteButton: {
    display: "contents",
    backgroundColor: theme.palette.info.main,
  },
  buttonVehicleHist: {
    margin: 4,
    borderRadius: 32,
    textTransform: "none",
    fontSize: 13,
    color: "white"
  }
}));

const Toolbar = props => {
  const {
    isEdit,
    setEdit,
    manualCompleteMode,
    parsedTripData,
    originalCount,
    manualComplete,
    uploadFiles,
    handleFileDelete,
    handleUpload,
    permissions,
    setCloseDialog,
    setMode,
    // openDispatchModal
    rawTripData,
    openVehicleModal,
    setTripForChangeTruck,
    mode,
    handleOpen,
    saveNewOrder
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const uploaderEl = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMode = () => {
    let newMode = "";
    if (mode === "list" || mode === "reorder") {
      newMode = "map";
    } else {
      newMode = "list";
    }

    setMode(newMode);
  };

  let splitButton;
  if (!_.isEmpty(rawTripData?.value)) {
    splitButton = !rawTripData.value[0].is_split ? (
    <MenuItem
      disabled={mode === "reorder"}
      component={Link}
      to={
        !parsedTripData?.has_empty_so
          ? {
              pathname: `/split`,
              state: {
                parsedTripData,
                rawTripData,
                moduleName: process.env.REACT_APP_DISPATCH_MODULE,
                dateRange: {
                  start:
                    !_.isEmpty(parsedTripData) &&
                    parsedTripData.pickups[0]?.arrival,
                  end:
                    !_.isEmpty(parsedTripData) &&
                    parsedTripData.dropoffs[
                      parsedTripData.dropoffs.length - 1
                    ]?.arrival
                }
              }
            }
          : {}
      }
      onClick={() => {
        if (parsedTripData?.has_empty_so) {
          Swal.fire({
            icon: "warning",
            title: "Warning!",
            text: "Please complete trip details, no SO number",
            timer: 3000
          });
        }
      }}
    >
      Split
    </MenuItem>
    ) : (
      ""
    );
  }

  const handleSelectManualComplete = () => {
    const { dropoffs, pickups } = parsedTripData;
    const invalid_do = _.filter(dropoffs, dropoff => {
      return (
        (dropoff.manual_arrival === null &&
          dropoff.actual_arrival === null) ||
        (dropoff.manual_departure === null &&
          dropoff.actual_departure === null)
      );
    });
    const invalid_pu = _.filter(pickups, p => {
      return (
        (p.manual_arrival === null && p.actual_arrival === null) ||
        (p.manual_departure === null && p.actual_departure === null)
      );
    });

    if (invalid_do.length + invalid_pu.length === originalCount) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please complete atleast one stop"
      });
    } else {
      setConfirmOpen(true);
    }
  };

  return (
    <Paper className={classes.paper}>
      <div style={{ width: "99%"}} className={classes.flexContainer}>
        <div className={classes.flexContainer}>
          {!isEdit && !manualCompleteMode ? (
            <Fragment>
              <Link
                to={{
                  pathname: `/trips`,
                  state: {
                    // tripNumber: row.tripNumber
                    fromDetails: true
                  }
                }}
              >
                <IconButton aria-label="delete">
                  <ArrowBack style={{ fontSize: 22 }} />
                </IconButton>
              </Link>
              <Typography variant="h5" className={classes.titleText}>
                {mode === "reorder" ? "Reorder" : "View Trip Details"}
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                variant="h5"
                style={{
                  display: "contents",
                  backgroundColor: theme.palette.info.main,
                }}
              >
                Manual Complete
              </Typography>
            </Fragment>
          )}
        </div>
        {!_.isEmpty(parsedTripData) && (
          <div>
            {(isEdit || manualCompleteMode || mode === "reorder") ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonVehicleHist}
                  style={{ backgroundColor: "rgb(201, 39, 38)" }}
                  onClick={() => {
                    setMode("list");
                    setEdit(false);
                  }}
                  // disabled={mode === "reorder"}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonVehicleHist}
                  onClick={() => {
                    if (mode === "reorder") {
                      saveNewOrder();
                    } else if (isEdit || manualCompleteMode) {
                      handleSelectManualComplete();
                    }
                  }}
                  // disabled={mode === "reorder"}
                >
                  Save
                </Button>
              </>
            ) : (
              <IconButton
                aria-label="action"
                onClick={event =>{
                  setAnchorEl(event.currentTarget)
                  // toggleOptionsMenu(event, row, rawTripData)
                }}
              >
                <MoreVert />
              </IconButton>
            )}
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  boxShadow: "-1px 6px 10px 0px rgba(0,0,0,0.49)",
                  borderRadius: 7,
                  backgroundColor: "#FFFFFF",
                  // maxHeight: 48 * 4.5,
                  width: 200
                }
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleOpen("historySelector");
                }}
                disabled={mode === "reorder"}
              >
                Vehicle History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleOpen("attachments");
                }}
                disabled={mode === "reorder"}
              >
                Preview Attachments
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleOpen("sharelink");
                }}
                disabled={mode === "reorder"}
              >
                Share Link
              </MenuItem>
              {!isEdit && permissions?.map_view?.edit && (
                  <>
                    {mode === "list" || mode === "reorder" ? (
                      <MenuItem
                        onClick={() => {
                          toggleMode();
                          AddLogs(
                            process.env.REACT_APP_DISPATCH_MODULE,
                            "view_map_trip",
                            parsedTripData.trip_number
                          );
                        }}
                        disabled={mode === "reorder"}
                      >
                        Map View
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          toggleMode();
                        }}
                        disabled={mode === "reorder"}
                      >
                        List View
                      </MenuItem>
                    )}
                  </>
                )}
              {mode !== "map" &&
                !isEdit &&
                permissions?.reorder?.edit &&
                parsedTripData.trip_status !== "Completed" &&
                parsedTripData.trip_status !== "Closed" && (
                  <MenuItem
                    onClick={() => {
                      setMode("reorder");
                      setAnchorEl(null);
                    }}
                    disabled={parsedTripData?.dropoffs?.length === 1}
                  >
                    Reorder
                  </MenuItem>
              )}
              {(permissions?.split?.edit &&
              parsedTripData.has_split &&
              /* mode === "list" && */
              !isEdit &&
              !manualCompleteMode) && 
              splitButton}
              {parsedTripData.trip_status === "Closed" &&
                !isEdit &&
                !manualCompleteMode &&
                /* mode === "list" && */
                permissions?.manual_complete?.edit && (
                  <MenuItem
                    onClick={() => {
                      setEdit(true);
                      setMode("list");
                      setAnchorEl(null);
                    }}
                    disabled={mode === "reorder"}
                  >
                    Manual Complete
                  </MenuItem>
              )}
              {parsedTripData.trip_status !== "Completed" &&
                parsedTripData.trip_status !== "Closed" &&
                !isEdit &&
                !manualCompleteMode &&
                /* mode === "list" && */
                permissions?.change_truck?.edit && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      openVehicleModal();
                      setTripForChangeTruck({
                        dateRange: {
                          start:
                            !_.isEmpty(parsedTripData) &&
                            parsedTripData.pickups[0]?.arrival,
                          end:
                            !_.isEmpty(parsedTripData) &&
                            parsedTripData.dropoffs[parsedTripData.dropoffs.length - 1]
                              ?.arrival
                        },
                        parsedTripData: parsedTripData,
                        rawTripData: rawTripData
                      })
                    }}
                    disabled={mode === "reorder"}
                  >
                    Change Truck
                  </MenuItem>
              )}
              {parsedTripData.trip_status !== "Completed" &&
                parsedTripData.trip_status !== "Closed" &&
                !isEdit &&
                !manualCompleteMode /* &&
                mode === "list" */ &&
                permissions?.close?.edit && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCloseDialog(true);
                    }}
                    disabled={mode === "reorder"}
                  >
                    Close Trip
                  </MenuItem>
              )}
            </Menu>
          </div>
        )}
      </div>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle>Save?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to save the changes made?
          </DialogContentText>
          <Grid container>
            <p style={{ fontSize: 14, color: "gray" }}>
              Attach Document
              <Tooltip title="Limited for 10 attachments only">
                <Error style={{ fontSize: 10 }} />
              </Tooltip>
            </p>
            <Grid container onClick={() => uploaderEl.current.click()}>
              <div
                style={{
                  borderBottom: "1px solid gray",
                  width: "100%",
                  cursor: "pointer"
                }}
              >
                {uploadFiles.length ? (
                  uploadFiles.map(f => (
                    <Chip
                      size="small"
                      label={f.name}
                      onDelete={() => handleFileDelete(f.name)}
                    />
                  ))
                ) : (
                  <Typography>
                    No document chosen{" "}
                    <span style={{ float: "right", color: "gray" }}>
                      <Attachment />
                    </span>
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            inputProps={{
              type: "file",
              multiple: true,
              accepts: ".xlsx,.pdf,.jpg,.jpeg",
              ref: uploaderEl
            }}
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}
            variant="outlined"
            color="primary"
          >
            NO
          </Button>
          <Button
            onClick={() => {
              // mutateStops();
              manualComplete();
              setConfirmOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
};

export default Toolbar;
