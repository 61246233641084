import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  Grid,
  Hidden,
  Typography,
  Container,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon
} from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
// import background from "../../../assets/Background-no-footer.png";
import background from "../../../assets/Background-with-Text.png";
import restLink from "../../../assets/Reset-Link.png";
// import LoginLogo from "../../../assets/Login logo.png";
import LoginLogo from "../../../assets/Seal-Original_4x_1.ico";
// import ResetLogo from "../../../assets/Reset Pass.svg";
import { FORGOT_PASSWORD_MUTATION } from "../../../graphql/Mutations";
import { Helper } from "../../Utils";

const webcastLink = "https://webcast-inc.com.ph/";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16
  },
  svgBackground: {
    color: theme.palette.primary.main
  },
  themeTypography: {
    color: theme.palette.primary.main
  },
  desktop: {
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  mobile: {
    // paddingLeft: "30px",
    // paddingRight: "30px",
    
  }
}));

const EmailConfirmation = props => {
  const { email, router } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12}>
        <Typography align="center" component="div">
          <img alt="" src={ResetLogo} style={{ marginLeft: -20 }} />
        </Typography>
      </Grid> */}
      <Grid item xs={12}>
        <Typography align="center" component="div">
          <img alt="" src={restLink} style={{ marginLeft: -20 }} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" component="span">
          If a trackme account exists for {email}, a reset link will be sent.
          Please check your email to reset your password. You only have 24 hours
          to complete the request test.
          {/* A reset link has been sent to {email}. Please check your email to
          reset your password. You only have 24 hours to complete the request. */}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => router.push("/login")}
          fullWidth
          className={classes.button}
        >
          RETURN TO SIGN IN
        </Button>
      </Grid>
    </Grid>
  );
};

const ForgotPasswordForm = props => {
  const { router, setMode, setEmail, email } = props;
  const [helperText, setHelperText] = useState("");
  const classes = useStyles();
  const [forgot_password] = useMutation(FORGOT_PASSWORD_MUTATION, {
    variables: {
      email: email
    },
    onCompleted: data => {
      const { forgot_password: fpw } = data;
      setMode("confirmation");
      // if (fpw.success) {
      //   setMode("confirmation");
      // } else {
      //   setHelperText(<Helper message={fpw.error[0]} />);
      // }
    },
    onError: ApolloError => {
      const { graphQLErrors: error } = JSON.parse(JSON.stringify(ApolloError));
      error.map(el => setHelperText(<Helper message={el.message} />));
    }
  });

  const triggerForgotPassword = () => {
    if (email === "") {
      setHelperText(<Helper message="This field is required." />);
    } else {
      forgot_password();
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={1}>
        <Typography component="div">
          <IconButton onClick={() => router.push("/login")}>
            <ArrowBackIcon />
          </IconButton>
        </Typography>
      </Grid>
      {/* <Grid item xs={11}>
        <Typography align="center" component="div">
          <img alt="" src={ResetLogo} style={{ marginLeft: -20 }} />
        </Typography>
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Enter your email address to receive link"
          placeholder="Email address"
          onChange={e => setEmail(e.target.value)}
          value={email}
          helperText={helperText}
          InputLabelProps={{
            style: { fontWeight: "bold" }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon className={classes.svgBackground} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <ReCAPTCHA sitekey={process.env.REACT_APP_CLOUD_STORAGE_BASE_URL} />
      </Grid> */}
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          className={classes.button}
          onClick={triggerForgotPassword}
        >
          SEND
        </Button>
      </Grid>
    </Grid>
  );
};

const ForgotPassword = props => {
  const classes = useStyles();
  const screenSize = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { history } = props;
  const [mode, setMode] = useState("form");
  const [email, setEmail] = useState("");
  return (
    <Fragment>
      <Grid container style={{ height: "100vh" }}>
        <Hidden mdDown>
          <Grid
            item
            lg={6}
            style={{
              width: "50%",
              // height: "100vh",
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              position: "relative"
            }}
          >
            <Typography
              onClick={() => window.open(webcastLink, "_blank")}
              variant="h5"
              style={{
                position: "absolute",
                bottom: "30px",
                textAlign: "center",
                width: "100%"
              }}
              color="secondary"
            >
              Contact us{" "}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                position: "absolute",
                bottom: "5px",
                textAlign: "center",
                width: "100%"
              }}
              color="secondary"
            >
              Powered by Webcast Technologies Inc.
            </Typography>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Typography align="center" component="div">
            <img alt="" src={LoginLogo} style={{ height: 95 }} />
          </Typography>
          <Typography
            align="center"
            variant="h4"
            className={classes.themeTypography}
          >
            {mode === "form" ? `Forgot Password` : `Reset link has been sent`}
          </Typography>
          <Container className={screenSize ? classes.desktop : classes.mobile}>
            {mode === "form" ? (
              <ForgotPasswordForm
                setMode={setMode}
                router={history}
                setEmail={setEmail}
                email={email}
              />
            ) : (
              <EmailConfirmation email={email} router={history} />
            )}
          </Container>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(ForgotPassword);
