import { makeStyles } from "@material-ui/core";

export const useFilterStyles = makeStyles(theme => ({
    drawer: {
      "& .MuiDrawer-paper": {
        minWidth: "23%",
        width: "23%"
      }
    },
    container: {
      width: "auto%",
      position: "relative",
      minHeight: "calc(100% - 64px)",
      padding: theme.spacing(2),
      textAlign: "center",
      boxSizing: "border-box",
      overflowY: "auto"
    },
    formRoot: {
      minWidth: "auto",
      marginBottom: 0,
      "&.MuiFormControl-root .MuiAutocomplete-tag": {
        maxWidth: "calc(100% - 22px)"
      }
    },
    selectLabel: {
      fontSize: 12,
      fontWeight: 600
    },
    menuItem: {
      fontSize: 12
    }
  }));