import React, { useReducer } from "react";
import { TableContainer, Table } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_FOR_EMAILING } from "../../../../../graphql/Queries";
import Pagination from "../../../common/Pagination";
import DivisionTitle from "../DivisionTitle";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

import useTableStyles from "../../styles/useTableStyles";
import Loading from "../../../../../utils/Loading";

const actionTypes = {
  initialize_table: "initialize_table",
  change_page: "change_page",
  change_rows_per_page: "change_rows_per_page",
  sort_table: "sort_table"
};
const emailLogsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.initialize_table: {
      return {
        ...state,
        tableRows: action.payload.tableRows,
        total: action.payload.total
      };
    }
    case actionTypes.change_page: {
      return {
        ...state,
        currentPage: action.payload.newPage
      };
    }
    case actionTypes.change_rows_per_page: {
      return {
        ...state,
        currentPage: 0,
        rowsPerPage: action.payload.rowsPerPage
      };
    }
    case actionTypes.sort_table: {
      return {
        ...state,
        sort: action.payload.orderBy
      };
    }
    default:
      return state;
  }
};

const EmailLogsTable = () => {
  const { id: scheduled_report_id } = useParams();
  const [state, dispatch] = useReducer(emailLogsReducer, {
    tableRows: [],
    rowsPerPage: 10,
    currentPage: 0,
    total: 0,
    sort: {
      field: "datesent",
      direction: "desc"
    }
  });

  const { loading } = useQuery(GET_FOR_EMAILING, {
    fetchPolicy: "cache-and-network",
    variables: {
      condition: [{ field: "scheduled_report_id", value: scheduled_report_id }],
      first: state.rowsPerPage,
      skip: state.rowsPerPage * state.currentPage,
      orderBy: state.sort
    },
    onCompleted: response => {
      dispatch({
        type: actionTypes.initialize_table,
        payload: {
          tableRows: response?.get_foremailings.for_emailing,
          total: response?.get_foremailings.total
        }
      });
    }
  });
  const styles = useTableStyles();
  const handlePageChange = (_, newPage) => {
    dispatch({ type: actionTypes.change_page, payload: { newPage } });
  };

  const handleChangeRowsPerPage = event => {
    dispatch({
      type: actionTypes.change_rows_per_page,
      payload: { rowsPerPage: event.target.value }
    });
  };

  const handleSort = (fieldName, dir) => {
    let direction = "asc";
    if (fieldName === state.sort.field) {
      direction = dir === "asc" ? "desc" : "asc";
    }
    dispatch({
      type: actionTypes.sort_table,
      payload: {
        orderBy: {
          direction,
          field: fieldName
        }
      }
    });
  };

  if (loading) return <Loading />;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <DivisionTitle label="Email Logs" />
        </div>
        <div>
          <Pagination
            count={state.total}
            page={state.currentPage}
            rowsPerPage={state.rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <TableContainer classes={{ root: styles.containerRoot }}>
        <Table>
          <TableHeader sort={state.sort} handleSort={handleSort} />
          <TableBody
            tableRows={state.tableRows}
            currentPage={state.currentPage}
            sort={state.sort}
            rowsPerPage={state.rowsPerPage}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmailLogsTable;
