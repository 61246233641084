import React, { useReducer } from "react";
import { useQuery } from "@apollo/client";

// functional reducer to prepare the data to be transfered back to the user.
function queryHandler(state, action) {
  switch (action.type) {
    case "TRIP_NUMBERS_NOTIF":
      return action.val.get_unique_trip_numbers;
    case "VEHICLE_PLATE_NO_NOTIF":
      let vehicleTemp = [];
      action.val.get_vehicles.vehicles.forEach(vehicle =>
        vehicleTemp.push(vehicle.plateno)
      );
      return vehicleTemp;
    default:
      return state;
  }
}

const useFetchSearch = (query = "", variables, typeOfQuery) => {
  const [fetchedData, dispatchQuery] = useReducer(queryHandler, { value: "" });

  const { loading } = useQuery(query, {
    variables: {
      ...variables
    },
    onCompleted: data => {
      if (data) dispatchQuery({ type: typeOfQuery, val: data });
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  return { isLoading: loading, data: fetchedData };
};

export default useFetchSearch;
