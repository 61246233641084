/* global google */
import React from "react";
import {
  makeStyles,
  withStyles,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { createPortal } from "react-dom";
import useUserContext from "../../../context/User/useUserContext";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const MapFilterOverlayStyle = makeStyles({
  root: {
    background: "rgba(255,255,255,0.9)",
    boxShadow: "0px 0px 18px -8px #484848d4",
    width: "150px",
    "& .Mui-expanded": {
      minHeight: "auto"
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0
    },
    "& .MuiIconButton-edgeEnd": {
      margin: 0
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem"
    }
  },
  summary: {
    padding: "5px 8px",
    margin: 0,
    height: "auto",
    minHeight: "auto",
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  },
  details: {
    padding: "5px 8px",
    margin: 0,
    height: "auto"
  },
  switch: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%"
  }
});

export class FilterControls extends React.Component {
  UNSAFE_componentWillMount() {
    const { mapRef, position } = this.props;
    this.map = mapRef.current;
    this.controlContainer = document.createElement("div");
    this.controlContainer.setAttribute(
      "style",
      `
        margin: 10px;
        height: auto;
        width: auto;
      `
    );
    this.map.controls[position].push(this.controlContainer);
  }

  // componentWillUnmount() {
  // console.log("unmount");
  // const { RIGHT_TOP } = google.maps.ControlPosition;
  // this.map.controls[RIGHT_TOP].removeAt(this.divIndex);
  // }

  render() {
    const { children } = this.props;
    return createPortal(children, this.controlContainer);
  }
}

export const parents = { FLEET: "fleet", DEVICES: "devices" };

export const MainControl = props => {
  const user = useUserContext();
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const classes = MapFilterOverlayStyle();
  const {
    handleChangeOverlay,
    filters: {
      showGeofences,
      showTrafficLayer,
      showClusterer,
      showVehicles,
      globeShowDeadspot,
      smartShowDeadspot
    },
    parent = parents.FLEET
  } = props;

  return (
    <Accordion className={classes.root} elevation={0}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="caption">Map Filters</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormGroup style={{ width: "100%" }}>
          <FormControlLabel
            className={classes.switch}
            control={
              <AntSwitch
                name="showGeofences"
                checked={showGeofences}
                onChange={handleChangeOverlay}
              />
            }
            size="small"
            labelPlacement="start"
            label={<Typography variant="caption">Geofences</Typography>}
          />
          <FormControlLabel
            className={classes.switch}
            control={
              <AntSwitch
                name="showTrafficLayer"
                checked={showTrafficLayer}
                onChange={handleChangeOverlay}
              />
            }
            size="small"
            labelPlacement="start"
            label={<Typography variant="caption">Traffic Layer</Typography>}
          />
          {parent === parents.FLEET && (
            <>
              <FormControlLabel
                className={classes.switch}
                control={
                  <AntSwitch
                    name="showClusterer"
                    checked={showClusterer}
                    onChange={handleChangeOverlay}
                  />
                }
                size="small"
                labelPlacement="start"
                label={<Typography variant="caption">Clustering</Typography>}
              />
              <FormControlLabel
                className={classes.switch}
                control={
                  <AntSwitch
                    name="showVehicles"
                    checked={showVehicles}
                    onChange={handleChangeOverlay}
                  />
                }
                size="small"
                labelPlacement="start"
                label={<Typography variant="caption">Vehicles</Typography>}
              />
              {isWTIAdmin && (
                <FormControlLabel
                  className={classes.switch}
                  control={
                    <AntSwitch
                      name="smartShowDeadspot"
                      checked={smartShowDeadspot}
                      onChange={handleChangeOverlay}
                    />
                  }
                  size="small"
                  labelPlacement="start"
                  label={
                    <Typography variant="caption">Smart Deadspot</Typography>
                  }
                />
              )}
              {isWTIAdmin && (
                <FormControlLabel
                  className={classes.switch}
                  control={
                    <AntSwitch
                      name="globeShowDeadspot"
                      checked={globeShowDeadspot}
                      onChange={handleChangeOverlay}
                    />
                  }
                  size="small"
                  labelPlacement="start"
                  label={
                    <Typography variant="caption">Globe Deadspot</Typography>
                  }
                />
              )}
            </>
          )}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

// const FilterControl = (props, context) => {
//   const {
//     handleChangeOverlay,
//     filters: {
//       showGeofences,
//       showTrafficLayer,
//       showClusterer,
//       showVehicles
//     }
//   } = props;
//   const classes = MapFilterOverlayStyle();
//   const map = context[MAP];
//   const overlayContainer = document.createElement("div");
//   overlayContainer.setAttribute(
//     "style",
//     `
//       margin: 10px;
//       height: auto;
//       width: auto;
//     `
//   );

//   useEffect(() => {
//     const controls = map.controls[google.maps.ControlPosition.RIGHT_TOP];
//     const index = controls.length;
//     controls.push(overlayContainer);

//     return () => {
//       controls.removeAt(index);
//     };
//   });

//   return createPortal(
//     <React.Fragment>
//       <ExpansionPanel className={classes.root} elevation={0}>
//         <ExpansionPanelSummary
//           className={classes.summary}
//           expandIcon={<ExpandMoreIcon />}
//         >
//           <Typography variant="caption">Map Filters</Typography>
//         </ExpansionPanelSummary>
//         <ExpansionPanelDetails className={classes.details}>
//           <FormGroup style={{ width: "100%" }}>
//             <FormControlLabel
//               className={classes.switch}
//               control={
//                 <AntSwitch
//                   name="geofences"
//                   checked={showGeofences}
//                   onChange={handleChangeOverlay}
//                 />
//               }
//               size="small"
//               labelPlacement="start"
//               label={<Typography variant="caption">Geofences</Typography>}
//             />
//             <FormControlLabel
//               className={classes.switch}
//               control={
//                 <AntSwitch
//                   name="trafficLayer"
//                   checked={showTrafficLayer}
//                   onChange={handleChangeOverlay}
//                 />
//               }
//               size="small"
//               labelPlacement="start"
//               label={<Typography variant="caption">Traffic Layer</Typography>}
//             />
//             <FormControlLabel
//               className={classes.switch}
//               control={
//                 <AntSwitch
//                   name="clusterer"
//                   checked={showClusterer}
//                   onChange={handleChangeOverlay}
//                 />
//               }
//               size="small"
//               labelPlacement="start"
//               label={<Typography variant="caption">Clustering</Typography>}
//             />
//             <FormControlLabel
//               className={classes.switch}
//               control={
//                 <AntSwitch
//                   name="vehicles"
//                   checked={showVehicles}
//                   onChange={handleChangeOverlay}
//                 />
//               }
//               size="small"
//               labelPlacement="start"
//               label={<Typography variant="caption">Vehicles</Typography>}
//             />
//           </FormGroup>
//         </ExpansionPanelDetails>
//       </ExpansionPanel>
//     </React.Fragment>,
//     overlayContainer
//   );
// };

// FilterControl.contextTypes = {
//   [MAP]: PropTypes.object
// };

// export default FilterControl;
