/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
  Badge,
  Checkbox,
  Toolbar,
  TextField,
  InputLabel,
  FormControl,
  Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
  FilterList,
  GetApp,
  Brightness1,
  MoreVert,
  ErrorOutline,
  TripOrigin,
  AssignmentLate,
  Warning,
  Event as EventIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";
import { CSVLink } from "react-csv";
import SearchContext from "../../../../context/SearchContext";
import AccessControl from "../../../AccessControl";
import Filter from "../../common/Filter";
import Battery from "../../../Battery";
import { gpsStatus } from "../../../../components/Utils";
import Pagination from "../../../table/Pagination";
import useUserContext from "../../../../context/User/useUserContext";
import Onboarding, { useOnboardingContext } from "../../index";
import Focus, { useInfoBubbleStyles } from "../../common/Focus";
import Stage from "../../common/Stage/Stage";
import trips_welcome from "../../images/wavy-buddies-delivering.svg";

import trips_data from "./trips.data";
import useFormStyles from "../../../../styles/useFormStyles";

const Highlight = require("react-highlighter");

const TableCell = withStyles({
  root: {
    textDecoration: "none",
    padding: 5
  }
})(MuiTableCell);

const headCells = [
  // {
  //   id: "dispatch_type",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  // {
  //   id: "isHotload",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  // {
  //   id: "isOverdue",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  {
    id: "ref_number",
    numeric: false,
    disablePadding: false,
    label: "Trip Number",
    sortable: true
  },
  {
    id: "service_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Service Type",
    sortable: true
  },
  {
    id: "pickups.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Pickup Location",
    sortable: true
  },
  {
    id: "pickups.arrival",
    numeric: false,
    disablePadding: false,
    label: "Pickup Date",
    sortable: true
  },
  {
    id: "dropoffs.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Delivery Location",
    sortable: true
  },
  {
    id: "dropoffs.arrival",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    sortable: true
  },
  {
    id: "client_name",
    numeric: false,
    disablePadding: false,
    label: "Hauler",
    sortable: true
  },
  {
    id: "vehicle_plate_no.keyword",
    numeric: false,
    disablePadding: false,
    label: "Plate/ CV no.",
    sortable: true
  },
  {
    id: "status_code",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sortable: false
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, isChecked = false } = props;

  return (
    <Focus step={3} component={TableHead}>
      <TableRow>
        <TableCell padding="checkbox" style={{ background: "transparent" }}>
          <Checkbox
            color="primary"
            indeterminate={isChecked}
            checked={isChecked}
            // onChange={onSelectAllTrips}
          />
        </TableCell>
        <TableCell style={{ background: "transparent" }} />
        <TableCell style={{ background: "transparent", width: 25 }} />
        <TableCell style={{ background: "transparent", width: 25 }} />
        {headCells.map(headCell => (
          <Focus
            component={TableCell}
            style={{ fonwWeight: "bold", background: "transparent" }}
            step={4}
            showInfoBubble={false}
            on={headCell.label === "Action"}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </Focus>
        ))}
      </TableRow>
    </Focus>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  // onSelectAllTrips: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired
};
const drawerWidth = 325;
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    //   width: "100%",
    //   marginBottom: theme.spacing(2),
    //   paddingTop: 64
    width: "100%",
    marginBottom: theme.spacing(20)
  },
  paperToolbar: {
    marginBottom: theme.spacing(2),
    position: "fixed",
    right: 24,
    left: 94
  },
  toolbar_root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  multipleSelect: {
    // marginLeft: 70,
    backgroundColor: "#EAEAEA",
    minHeight: "45px !important",
    height: 45
  },
  title: {
    flex: "1 1 25%",
    color: "black"
    // color: "#000000"
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawer: {
    width: drawerWidth
  },
  filterInput: {
    marginBottom: "20px",
    minWidth: "250px",
    width: "250px"
  },
  tableContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 192px)"
  },
  customBadgeAutomatic: {
    backgroundColor: "#bb6bd9",
    color: "white"
  },
  customBadgeManual: {
    backgroundColor: "#4e9fc0",
    color: "white"
  },
  customBadgeUndefine: {
    backgroundColor: "#cab608",
    color: "white"
  },
  selectedTripsCount: {
    color: theme.palette.primary.main
  },
  popoverPaper: {
    right: 150,
    left: "auto !important",
    width: "fit-content"
  }
}));

const TripStatusLegends = () => {
  const classes = useInfoBubbleStyles();
  return (
    <ul className={classes.infoBubbleLegends}>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <Warning style={{ fontSize: 12, color: "#EA4335" }} />
        </span>
        <span>Hotload</span>
      </li>
      <li className={classes.legend}>
        <span className={classes.icon}>
          <AssignmentLate style={{ fontSize: 12, color: "#EA4335" }} />
        </span>
        <span>Overdue Trip</span>
      </li>
    </ul>
  );
};

const CustomFilterHeader = ({ handleClearFilter }) => {
  const formStyles = useFormStyles();
  return (
    <div
      style={{
        width: "85%",
        textAlign: "right",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
        marginBottom: 25
      }}
    >
      <div>
        <Select
          value={0}
          onChange={() => {}}
          classes={{ root: formStyles.selectRoot }}
        >
          <MenuItem value={0} selected>
            Pickup Date
          </MenuItem>
          <MenuItem value={1}>Delivery Date</MenuItem>
        </Select>
      </div>
      <div>
        <Button onClick={handleClearFilter} size="small">
          <span
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              color: "black"
            }}
          >
            Clear All
          </span>
        </Button>
      </div>
    </div>
  );
};

export default function TripsTable() {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const contextData = useContext(SearchContext);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("pickups.arrival");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [drawer, setDrawer] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [downloadData, setDownloadData] = useState({
    headers: [
      { label: "Trip Number", key: "fo_number" },
      { label: "Service Type", key: "service_type" },
      { label: "Pickup Location", key: "pickup_location" },
      { label: "Pickup Date", key: "pickup_date" },
      { label: "Dropoff Location", key: "dropoff_location" },
      { label: "Delivery Date", key: "dropoff_date" },
      { label: "Hauler", key: "hauler" },
      { label: "Plate/ CV no", key: "plate_no" },
      { label: "Status", key: "status" },
      { label: "Dispatch Type", key: "dispatch_type" }
    ],
    body: []
  });
  const csvLink = useRef();
  // const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [optionsMenuData, setOptionsMenuData] = useState(null);
  const [chosenRowData, setChosenRowData] = useState({});
  const [editPermission, setEditPermission] = useState(false); // eslint-disable-line
  const [parsedTrips, setParsedTrips] = useState([]);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [currentTime, setCurrentTime] = useState(
    moment().format("MMM D YYYY h:mm A")
  );

  const user_context = useUserContext();
  const permissions = React.useMemo(() => {
    if (!user_context.allowed_permissions) return null;

    const { allowed_permissions } = user_context;

    return {
      change_truck: allowed_permissions["/trips/change_truck/"],
      dispatch: allowed_permissions["/trips/dispatch/"],
      manual_complete: allowed_permissions["/trips/manual_complete/"],
      map_view: allowed_permissions["/trips/map_view/"],
      reorder: allowed_permissions["/trips/reorder/"],
      split: allowed_permissions["/trips/split/"],
      close: allowed_permissions["/trips/close/"]
    };
  }, [user_context.allowed_permissions]);

  const [dvar, setdvar] = useState([]);

  const open = Boolean(anchorEl);

  function closeOptionsMenu() {
    // setOptionsMenuData(null);
    setAnchorEl(null);
  }

  const toggleDrawer = () => {
    setDrawer(prev => !prev);
  };

  // Function used to combine and remove duplicate betweeb two array and return a new array
  const combineArray = (firstArray, secondArray) => {
    // Concat the two given array to form a new single array
    const thirdArray = firstArray.concat(secondArray);
    // Convert the new array into a set to remove a duplicate data and sort into a new array
    const uniqueElements = new Set(thirdArray.sort());
    // Convert the new set into an array and Map the new array to display the desired output
    return Array.from(uniqueElements).map(el => {
      return el ? (
        <span key={el} style={{ display: "block" }}>
          {el}
        </span>
      ) : (
        "No SO Number Yet"
      );
    });
  };

  // Function used to display vehicle plate no, gps status and provider, and battery status
  const renderVehicleCell = booking => {
    let cell = "";
    let data = {
      vehicleType: "",
      deviceTypeId: 0,
      batteryLevel: 0,
      reportstampBatt: null
    };
    // dvar.some(elem => elem.id === booking.vehicle_id) &&
    dvar
      .filter(elem => elem.id === booking.vehicle_id)
      .map(drow => {
        data = {
          vehicleType: drow.vehicle_info.vehicle_type,
          deviceTypeId: drow.device_type_id,
          batteryLevel: drow.device_status.battery_level,
          reportstampBatt: drow.device_status.reportstamp
        };
        return data;
      });
    cell = (
      <span>
        <Battery
          vehicleType={data.vehicleType}
          deviceTypeId={data.deviceTypeId}
          batteryLevel={data.batteryLevel}
          tooltipTitle={
            <Fragment>
              <p style={{ margin: 0, padding: 0 }}>
                Battery Life: {data.batteryLevel} %
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                As of{" "}
                {data.reportstampBatt
                  ? moment(data.reportstampBatt).format("lll")
                  : "-"}
              </p>
            </Fragment>
          }
        />
        <Tooltip
          title={
            <Fragment>
              <p style={{ margin: 0, padding: 0 }}>
                {gpsStatus(booking.vehicle_status?.toLowerCase()).label}
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                Last Report:{" "}
                {// Check if the vehicle has a device
                (dvar.some(elem => elem.id === booking.vehicle_id) &&
                  dvar.map(drow => {
                    let reportstamp = "";
                    if (drow.id === booking.vehicle_id) {
                      reportstamp =
                        (drow.device_status.reportstamp &&
                          moment(drow.device_status.reportstamp).format(
                            "lll"
                          )) ||
                        "-";
                      data = {
                        vehicleType: drow.vehicle_info.vehicle_type,
                        deviceTypeId: drow.device_type_id,
                        batteryLevel: drow.device_status.battery_level,
                        reportstampBatt: drow.device_status.reportstamp
                      };
                    }
                    return reportstamp;
                  })) ||
                  "-"}
              </p>
              <p style={{ margin: 0, padding: 0 }}>
                GPS Provider:{" "}
                {(dvar.some(elem => elem.id === booking.vehicle_id) &&
                  dvar.map(drow => {
                    let provider = "";
                    if (drow.id === booking.vehicle_id) {
                      provider = drow.device_config.gps_provider || "-";
                    }
                    return provider;
                  })) ||
                  "-"}
              </p>
            </Fragment>
          }
        >
          <Typography
            style={{
              // display: "inline-block",
              fontSize: "0.875rem"
            }}
            component="span"
            noWrap
          >
            <TripOrigin
              style={{
                fontSize: "12px",
                color: gpsStatus(booking.vehicle_status?.toLowerCase()).color
              }}
            />
            <Highlight search={contextData.searchKeyword.value}>
              {booking.vehicle_plate_no || "-"}
            </Highlight>
          </Typography>
        </Tooltip>
      </span>
    );
    return cell;
  };

  const isSelected = name => selectedTrips.indexOf(name) !== -1;

  const getData = () => {
    return trips_data;
  };

  const {
    progress: { trips: currentStep },
    completeOnboarding
  } = useOnboardingContext();

  const stage = {
    0: {
      image: trips_welcome,
      title: "Trips: Check what's going on to your deliveries",
      description: `Monitor your delivery's status and vehicles real-time location in this module`,
      buttonText: "Got It"
    },
    6: {
      buttonText: "Start",
      buttonProps: {
        onClick: completeOnboarding
      }
    }
  };

  return (
    <AccessControl
      resource="/trips/"
      process="dispatch"
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        <Focus
          step={[0, 6]}
          on={[0, 6].includes(currentStep)}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: [0, 6].includes(currentStep) ? "block" : "none",
            background: "transparent"
          }}
          showInfoBubble={false}
        >
          <Stage {...stage[currentStep]} />
        </Focus>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar_root}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Focus
                step={1}
                on={!drawer}
                indicator={currentStep === 1 && !drawer}
                style={{ padding: 12 }}
                infoBubbleProps={{
                  position: "right",
                  title: "Filter",
                  description:
                    "Allow to display certain data based on selected values"
                  // body: <TripLegends />
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<FilterList />}
                  style={{
                    borderRadius: "25px",
                    fontSize: "12px",
                    backgroundColor: "#808080"
                  }}
                  onClick={() => toggleDrawer()}
                >
                  Filter
                </Button>
              </Focus>
              {/* </Grid> */}
              <Focus
                step={2}
                style={{ padding: 12 }}
                infoBubbleProps={{
                  position: "right",
                  title: "Download",
                  description:
                    "Allows the transfer of data from web to user's device"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "25px",
                    fontSize: "12px",
                    backgroundColor: "#ff8364"
                  }}
                  startIcon={<GetApp />}
                >
                  Download
                </Button>
              </Focus>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <span>As of {currentTime}</span>
              <Pagination
                rowsPerPage={rowsPerPage}
                total={Math.ceil(totalRows / rowsPerPage)}
                currentPage={page}
                onRowsPerPageChange={n => {
                  setRowsPerPage(n);
                  setPage(0);
                }}
                onPageChange={n => {
                  setPage(n);
                  localStorage.setItem("page", n);
                }}
                onPageUp={n => setPage(n)}
                onPageDown={n => setPage(n)}
              />
            </div>
          </Toolbar>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                // onRequestSort={handleRequestSort}
                rowCount={parsedTrips.length}
                // onSelectAllTrips={handleSelectAllTrips}
                // isChecked={isChecked}
                permissions={permissions}
              />
              <TableBody>
                {getData().map((row, index) => {
                  const isItemSelected = isSelected(row.tripNumber);
                  const bookingIndex = _.findIndex(bookings, function a(
                    booking
                  ) {
                    return booking.key === row.tripNumber;
                  });
                  const rawTripData = bookings[bookingIndex];
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const redirectToDetails = label => {
                    return (
                      <div>
                        {/* <Link
                          style={{
                            textDecoration: "none",
                            color: "#000000",
                            paddingTop: 10,
                            paddingBottom: 10
                          }}
                          to={{
                            pathname: detailLink,
                            state: detailState
                          }}
                        > */}
                        <Highlight search={contextData.searchKeyword.value}>
                          {label}
                        </Highlight>
                        {/* </Link> */}
                      </div>
                    );
                  };
                  const key = `${row.tripNumber}-${index}`;
                  return (
                    <Focus
                      step={3}
                      component={TableRow}
                      on={index === 0}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={key}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell>
                        {row.trip_status.toLowerCase() !== "closed" &&
                        row.trip_status.toLowerCase() !== "cancelled" &&
                        row.trip_status.toLowerCase() !== "completed" ? (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell style={{ paddingLeft: "30px" }}>
                        <Tooltip
                          title={
                            row.dispatch_type &&
                            row.dispatch_type.toLowerCase() !==
                              "not yet dispatched"
                              ? `${row.dispatch_type} Dispatched`
                              : "Undefined"
                          }
                        >
                          <Badge
                            classes={{
                              badge: row.dispatch_type
                                ? (row.dispatch_type.toLowerCase() ===
                                    "manual" &&
                                    classes.customBadgeManual) ||
                                  (row.dispatch_type.toLowerCase() ===
                                    "automatic" &&
                                    classes.customBadgeAutomatic) ||
                                  classes.customBadgeUndefine
                                : classes.customBadgeUndefine
                            }}
                            variant="dot"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Focus
                          step={5}
                          on={index === 0}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5,
                            width: "35px",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            boxSizing: "border-box"
                          }}
                          showInfoBubble={false}
                        >
                          {index === 0 && (
                            <Tooltip title="Hotload">
                              <Warning
                                style={{ fontSize: 12, color: "#EA4335" }}
                              />
                            </Tooltip>
                          )}
                        </Focus>
                      </TableCell>
                      <TableCell>
                        <Focus
                          step={5}
                          on={index === 1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 5,
                            width: "35px",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            boxSizing: "border-box"
                          }}
                          // showInfoBubble={false}
                          infoBubbleProps={{
                            title: "Indicators",
                            description:
                              "Indicates the status of the trip if hotload or overdue trip",
                            body: <TripStatusLegends />
                          }}
                        >
                          {index === 1 && (
                            <Tooltip
                              key="overdue-key-1"
                              title="It seems this trip is overdue. Would you like to close it?"
                            >
                              <AssignmentLate
                                style={{ fontSize: 12, color: "#EA4335" }}
                              />
                            </Tooltip>
                          )}
                        </Focus>
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="normal">
                        <Fragment>
                          {redirectToDetails(
                            <Tooltip
                              title={combineArray(
                                row.raw_pickups.map(el => {
                                  return el.so_number;
                                }),
                                row.raw_dropoffs.map(el => {
                                  return el.so_number;
                                })
                              )}
                            >
                              <span>
                                <Highlight
                                  search={contextData.searchKeyword.value}
                                >
                                  {row.tripNumber}
                                </Highlight>
                              </span>
                            </Tooltip>
                          )}
                        </Fragment>
                      </TableCell>
                      <TableCell>
                        {redirectToDetails(row.service_type || "N/A")}
                      </TableCell>
                      <TableCell>
                        {redirectToDetails(
                          row.pickups.map((el, dIndex) => {
                            const deliveryKey = `${el.id}-${dIndex}`;
                            let color = "";
                            const {
                              status_code,
                              actual_arrival,
                              manual_arrival,
                              arrival
                            } = el;
                            let time = "Vehicle not arrived yet";
                            const isLate = moment(arrival).isBefore(
                              actual_arrival
                            );
                            let textColor = "black";
                            switch (status_code) {
                              case "Manually Completed":
                                color = "#219653";
                                time = `Manual arrival time ${moment(
                                  manual_arrival
                                ).format("lll")}`;
                                break;
                              case "Completed":
                                textColor = isLate ? "#EA4335" : "black";
                                time = (
                                  <Fragment>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      Actual Arrival Time{" "}
                                      <span
                                        style={{
                                          color: isLate ? "#EA4335" : "#6DE243"
                                        }}
                                      >
                                        {isLate ? "(Late)" : "(On Time)"}
                                      </span>
                                    </p>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      <Highlight
                                        search={contextData.searchKeyword.value}
                                      >
                                        {moment(actual_arrival).format("lll")}
                                      </Highlight>
                                    </p>
                                  </Fragment>
                                );
                                color = "#2dd112";
                                break;
                              case "Arrived at Pickup":
                                textColor = isLate ? "#EA4335" : "black";
                                time = (
                                  <Fragment>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      Actual Arrival Time{" "}
                                      <span
                                        style={{
                                          color: isLate ? "#EA4335" : "#6DE243"
                                        }}
                                      >
                                        {isLate ? "(Late)" : "(On Time)"}
                                      </span>
                                    </p>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      {moment(actual_arrival).format("lll")}
                                    </p>
                                  </Fragment>
                                );
                                color = "#ffc207";
                                break;
                              default:
                                color = "#c6c6c6";
                                break;
                            }

                            return (
                              <Tooltip
                                title={time}
                                aria-label={status_code}
                                key={deliveryKey}
                              >
                                <span
                                  key={deliveryKey}
                                  style={{
                                    display: "block",
                                    color: textColor
                                  }}
                                >
                                  <Brightness1
                                    style={{
                                      fontSize: "12px",
                                      color: color
                                    }}
                                  />
                                  <Highlight
                                    search={contextData.searchKeyword.value}
                                  >
                                    {el.geofence_code
                                      ? `${el.geofence_code}-${el.name}`
                                      : el.name}
                                  </Highlight>
                                </span>
                              </Tooltip>
                            );
                          })
                        )}
                      </TableCell>
                      <TableCell>
                        {redirectToDetails(
                          row.pickups.map(d => (
                            <span
                              style={{ display: "block" }}
                              key={`${d.geofence_id}-${d.arrival}`}
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {moment(d.arrival).format("lll")}
                              </Highlight>
                            </span>
                          ))
                        )}
                      </TableCell>
                      <Focus
                        step={3}
                        on={index === 0}
                        infoBubbleProps={{
                          position: "bottom",
                          title: "Data Table",
                          description:
                            "Display details and status of a particular trip"
                        }}
                        component={TableCell}
                        align="left"
                      >
                        {redirectToDetails(
                          row.dropoffs.map((el, dIndex) => {
                            const deliveryKey = `${el.id}-${dIndex}`;
                            let color = "";
                            const {
                              status_code,
                              actual_arrival,
                              manual_arrival,
                              arrival
                            } = el;
                            const isLate = moment(arrival).isBefore(
                              actual_arrival
                            );
                            let textColor = "black";
                            let time = "Vehicle not arrived yet";
                            switch (status_code) {
                              case "Manually Completed":
                                color = "#219653";
                                time = `Manual arrival time ${moment(
                                  manual_arrival
                                ).format("lll")}`;
                                break;
                              case "Completed":
                                textColor = isLate ? "#EA4335" : "black";
                                time = (
                                  <Fragment>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      Actual Arrival Time{" "}
                                      <span
                                        style={{
                                          color: isLate ? "#EA4335" : "#6DE243"
                                        }}
                                      >
                                        {isLate ? "(Late)" : "(On Time)"}
                                      </span>
                                    </p>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      {moment(actual_arrival).format("lll")}
                                    </p>
                                  </Fragment>
                                );
                                color = "#2dd112";
                                break;
                              case "Arrived at Drop off":
                                textColor = isLate ? "#EA4335" : "black";
                                time = (
                                  <Fragment>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      Actual Arrival Time{" "}
                                      <span
                                        style={{
                                          color: isLate ? "#EA4335" : "#6DE243"
                                        }}
                                      >
                                        {isLate ? "(Late)" : "(On Time)"}
                                      </span>
                                    </p>
                                    <p style={{ margin: 0, padding: 0 }}>
                                      {moment(actual_arrival).format("lll")}
                                    </p>
                                  </Fragment>
                                );
                                color = "#ffc207";
                                break;
                              default:
                                color = "#c6c6c6";
                                break;
                            }

                            return (
                              <Tooltip
                                title={time}
                                aria-label={status_code}
                                key={deliveryKey}
                              >
                                <span
                                  key={deliveryKey}
                                  style={{
                                    display: "block",
                                    color: textColor
                                  }}
                                >
                                  <Brightness1
                                    style={{
                                      fontSize: "12px",
                                      color: color
                                    }}
                                  />
                                  <Highlight
                                    search={contextData.searchKeyword.value}
                                  >
                                    {el.geofence_code
                                      ? `${el.geofence_code}-${el.name}`
                                      : el.name}
                                  </Highlight>
                                </span>
                              </Tooltip>
                            );
                          })
                        )}
                      </Focus>
                      <TableCell>
                        {redirectToDetails(
                          row.dropoffs.map(d => (
                            <span
                              style={{ display: "block" }}
                              key={`${d.geofence_id}-${d.arrival}`}
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {moment(d.arrival).format("lll")}
                              </Highlight>
                            </span>
                          ))
                        )}
                      </TableCell>
                      <TableCell>{redirectToDetails(row.hauler)}</TableCell>
                      <TableCell>
                        <span
                          style={{ display: "inline-block", width: "100px" }}
                        >
                          {redirectToDetails(renderVehicleCell(row))}
                        </span>
                      </TableCell>
                      <TableCell>
                        {redirectToDetails(
                          <Fragment>
                            <span
                              style={{ color: "#C4C4C4", display: "block" }}
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {row.trip_status}
                              </Highlight>
                            </span>
                            {(row.trip_status.toLowerCase() === "incoming" ||
                              row.trip_status.toLowerCase() === "in transit") &&
                              row.eta && (
                                <Tooltip title={`As of ${row.eta.as_of}`}>
                                  <span
                                    style={{
                                      display: "block",
                                      fontSize: 10,
                                      color: row.eta.isLate ? "red" : "green"
                                    }}
                                  >
                                    <ErrorOutline
                                      style={{
                                        fontSize: "inherit",
                                        color: "inherit"
                                      }}
                                    />
                                    {row.eta.eta}
                                  </span>
                                </Tooltip>
                              )}
                          </Fragment>
                        )}
                      </TableCell>
                      <Focus
                        step={4}
                        on={index === 0}
                        indicator={currentStep === 4 && index === 0}
                        // indicatorProps={{ placement: "top-left" }}
                        showInfoBubble={!open}
                        infoBubbleProps={{
                          position: "left",
                          title: "Action",
                          description:
                            "Shows available actions to be executed on a trip"
                        }}
                        component={TableCell}
                      >
                        {row.trip_status.toLowerCase() !== "completed" &&
                        row.trip_status.toLowerCase() !== "cancelled" ? (
                          <IconButton
                            aria-label="action"
                            // onClick={event =>
                            // toggleOptionsMenu(event, row, rawTripData)
                            // }
                            onClick={event => {
                              if (anchorEl) {
                                setAnchorEl(null);
                                return;
                              }
                              if (currentStep === 4)
                                setAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </Focus>
                    </Focus>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div style={{ display: "none" }}>
          <CSVLink
            data={downloadData.body}
            headers={downloadData.headers}
            ref={csvLink}
            style={{ color: "black", textDecoration: "none" }}
          >
            Download Table
          </CSVLink>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={closeOptionsMenu}
          classes={{
            paper: classes.popoverPaper
          }}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: 200
            }
          }}
        >
          {permissions?.change_truck?.edit && (
            <MenuItem
              onClick={() => {
                // openVehicleModal();
                // closeOptionsMenu();
              }}
              disabled={
                !_.isEmpty(chosenRowData) &&
                (chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                  "completed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "closed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "cancelled")
              }
            >
              Change Truck
            </MenuItem>
          )}
          {permissions?.split?.edit && !chosenRowData?.is_split ? (
            <MenuItem
              // component={Link}
              // to={
              //   !chosenRowData?.parsedTripData?.has_empty_so
              //     ? {
              //         pathname: `/split`,
              //         state: {
              //           ...chosenRowData,
              //           moduleName: process.env.REACT_APP_DISPATCH_MODULE
              //         }
              //       }
              //     : {}
              // }
              disabled={
                !_.isEmpty(chosenRowData) &&
                (!chosenRowData.parsedTripData.has_split ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "incoming" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "completed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "closed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "cancelled")
              }
            >
              Split
            </MenuItem>
          ) : (
            ""
          )}

          {permissions?.dispatch?.edit && (
            <MenuItem
              onClick={() => {
                // openDispatchModal();
                // closeOptionsMenu();
              }}
              disabled={
                !_.isEmpty(chosenRowData) &&
                chosenRowData.parsedTripData.trip_status.toLowerCase() !==
                  "processing"
              }
            >
              Dispatch
            </MenuItem>
          )}
          {permissions?.manual_complete?.edit && (
            <MenuItem
              // component={Link}
              // to={{
              //   pathname: `/details`,
              //   state: {
              //     rawTripData: chosenRowData.rawTripData,
              //     tripData: chosenRowData.parsedTripData,
              //     isEdit: true
              //     // key: chosenRowData.trip_number
              //   }
              // }}
              disabled={
                !_.isEmpty(chosenRowData) &&
                chosenRowData.parsedTripData.trip_status.toLowerCase() !==
                  "closed"
              }
            >
              Manual Complete
            </MenuItem>
          )}
          {permissions?.close?.edit && (
            <MenuItem
              disabled={
                !_.isEmpty(chosenRowData) &&
                (chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                  "completed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "closed" ||
                  chosenRowData.parsedTripData.trip_status.toLowerCase() ===
                    "cancelled")
              }
              onClick={() => {
                // handleCloseClick(true);
                // closeOptionsMenu();
              }}
            >
              Close
            </MenuItem>
          )}
          {permissions?.map_view?.edit && (
            <MenuItem
            // component={Link}
            // // disabled={permissions && !permissions.map_view.edit}
            // to={{
            //   pathname: `/details`,
            //   state: {
            //     rawTripData: chosenRowData.rawTripData,
            //     isEdit: false,
            //     mapView: true
            //     // key: chosenRowData.trip_number
            //   }
            // }}
            >
              Map View
            </MenuItem>
          )}
        </Menu>

        <Filter
          isOpen={drawer}
          closeFilterDrawer={() => setDrawer(false)}
          renderFilterHeader={({ handleClearFilter }) => (
            <CustomFilterHeader handleClearFilter={handleClearFilter} />
          )}
        >
          {[
            { label: "From" },
            { label: "To" },
            { label: "Service Type" },
            { label: "Pickup Location" },
            { label: "Delivery Location" },
            { label: "Hauler" },
            { label: "Plate Number" },
            { label: "Status" }
          ].map(item => (
            <FormControl
              classes={{ root: formStyles.formRoot }}
              style={{ width: "100%", minWidth: "auto" }}
            >
              <InputLabel shrink>{item.label}</InputLabel>
              <br />
              <Autocomplete
                options={["All"]}
                value="All"
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    classes={{ root: formStyles.textFieldRoot }}
                    value="All"
                    placeholder="All"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: "6px 0px"
                      },
                      endAdornment: (
                        <React.Fragment>
                          {["From", "To"].includes(item.label) ? (
                            <EventIcon htmlColor="#707070" fontSize="small" />
                          ) : [
                              "Pickup Location",
                              "Delivery Location",
                              "Hauler",
                              "Plate Number"
                            ].includes(item.label) ? (
                            <SearchIcon htmlColor="#707070" fontSize="small" />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                          {/* {params.InputProps.endAdornment} */}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </FormControl>
          ))}
        </Filter>
      </div>
    </AccessControl>
  );
}
