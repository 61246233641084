import React, { useState, useEffect } from "react";
import {
  styled,
  Button,
  Checkbox,
  Typography,
  Box,
  Divider,
  IconButton
} from "@material-ui/core";
import _ from "lodash";
import SwapVertRoundedIcon from "@material-ui/icons/SwapVertRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

const VehicleTable = props => {
  const {
    data,
    setVehicles,
    modalFn,
    isAddEntryShown,
    order,
    setOrder,
    isLoading,
    currentPage,
    setSelectedVehicles,
    selectedVehicles,
    isEditMode,
    searchedVehicle
  } = props;

  const [isCheckAllVehicles, setIsCheckAllVehicles] = useState(false);

  const checkAllHandler = event => {
    let isChecked = event.target.checked;

    if (isChecked) {
      const tempData = _.uniqBy([...selectedVehicles, ...data], "id");
      setSelectedVehicles(tempData);
    }
    setIsCheckAllVehicles(isChecked);
  };

  const selectVehicleHandler = value => {
    let removeDuplicateVehicle = [];

    const isSelected =
      selectedVehicles.filter(item => item.id === value.id).length > 0;
    if (isSelected) {
      removeDuplicateVehicle = selectedVehicles.filter(
        vehicle => vehicle.id !== value.id
      );
    } else {
      removeDuplicateVehicle = [...selectedVehicles, value];
    }

    setSelectedVehicles(_.uniqBy(removeDuplicateVehicle, "id"));
  };

  const removeVehicleHandler = value => {
    const vehiclesTemp = data.filter(vehicle => +vehicle.id !== +value.id);
    setVehicles(vehiclesTemp);
    setSelectedVehicles(vehiclesTemp);
  };

  const reorderHandler = () => setOrder(!order);

  useEffect(() => {
    if (
      data.length === selectedVehicles.length &&
      isAddEntryShown &&
      selectedVehicles.length > 0
    ) {
      setIsCheckAllVehicles(true);
    } else if (data.length !== selectedVehicles.length && isAddEntryShown) {
      setIsCheckAllVehicles(false);
    }
  }, [selectedVehicles, searchedVehicle]);

  useEffect(() => {
    if (!isAddEntryShown) {
      if (isLoading) {
        setIsCheckAllVehicles(false);
        return;
      }

      let selectedCount = 0;
      selectedVehicles.forEach(vehicle => {
        if (data.filter(item => item.id === vehicle.id).length > 0) {
          selectedCount++;
        }
      });

      setIsCheckAllVehicles(selectedCount === 5);
    } else {
      if (_.isEmpty(data)) setIsCheckAllVehicles(false);
    }
  }, [selectedVehicles, data, currentPage]);

  return (
    <>
      <TableContainer>
        <ContainerStyled justifyContent="space-between" padding={1}>
          <ContainerStyled gridGap={5}>
            <Checkbox
              checked={isCheckAllVehicles}
              color="primary"
              size="small"
              onChange={checkAllHandler}
              disabled={!isEditMode}
            />
            <TblHeaderStyled>Plate No.</TblHeaderStyled>
            <IconButton
              onClick={reorderHandler}
              disabled={!isEditMode}
              size="small"
            >
              <SwapVertRoundedIcon
                fontSize="small"
                color={order ? "primary" : "disabled"}
              />
            </IconButton>
          </ContainerStyled>
          {isAddEntryShown && (
            <Button
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => modalFn()}
              disabled={!isEditMode}
            >
              Add Entry
            </Button>
          )}
        </ContainerStyled>
        <Divider />
        {!isLoading && (
          <ContainerTableData>
            {data.map((info, idx) => (
              <>
                <ContainerStyled
                  justifyContent="space-between"
                  paddingLeft={1}
                  paddingRight={1}
                  key={idx + info.plateno}
                >
                  <ContainerStyled gridGap={5}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={
                        selectedVehicles.filter(
                          vehicle => vehicle.id === info.id
                        )?.length > 0
                      }
                      disabled={!isEditMode}
                      onChange={() => selectVehicleHandler(info)}
                      name={info.plateno}
                      value={info}
                    />
                    <TblData>{info.plateno}</TblData>
                  </ContainerStyled>
                  {isAddEntryShown && (
                    <IconButton
                      onClick={() => removeVehicleHandler(info)}
                      disabled={!isEditMode}
                    >
                      <DeleteRoundedIcon
                        fontSize="small"
                        style={{ color: "#808080" }}
                      />
                    </IconButton>
                  )}
                </ContainerStyled>
                {data.length - 1 !== idx && <Divider />}
              </>
            ))}
          </ContainerTableData>
        )}
      </TableContainer>
    </>
  );
};

export default VehicleTable;

const TblHeaderStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.dark,
  fontWeight: 600
}));
const TblData = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.dark
}));

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center"
}));

const TableContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  minHeight: 200,
  height: "100%",
  marginTop: 8,
  maxHeight: 360
}));

const ContainerTableData = styled("div")(({ theme }) => ({
  maxHeight: 300,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: 5,
    backgroundColor: "#F9F9F9 !important",
    borderRadius: "0px !important"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F9F9F9 !important",
    border: "none !important"
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    border: "0px solid  rgba(0, 0, 0, 0) !important",
    borderRadius: 0
  }
}));
