import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Grid,
  Checkbox,
  Tooltip,
  Divider,
  Chip
} from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#3ba4f7"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16,
    height: "85%"
  },
  input: {
    padding: 0
  }
});

const OTTA = props => {
  const classes = useStyles();
  const {
    data,
    pickups,
    history,
    filterValues,
    setFilterValues,
    tabValue,
    setPickupKeyword
  } = props;
  const [otta, setOtta] = useState({
    incoming: {
      awaiting: {
        count: 0,
        trip_numbers: []
      },
      at_risk: {
        count: 0,
        trip_numbers: []
      },
      at_risk_pre_dispatch: {
        count: 0,
        trip_numbers: []
      },
      at_risk_trip: {
        count: 0,
        trip_numbers: []
      },
      on_track: {
        count: 0,
        trip_numbers: []
      },
      on_track_pre_dispatch: {
        count: 0,
        trip_numbers: []
      },
      on_track_trip: {
        count: 0,
        trip_numbers: []
      }
    },
    arrived: {
      late: {
        count: 0,
        trip_numbers: []
      },
      late_pre_dispatch: {
        count: 0,
        trip_numbers: []
      },
      late_trip: {
        count: 0,
        trip_numbers: []
      },
      on_time: {
        count: 0,
        trip_numbers: []
      },
      on_time_pre_dispatch: {
        count: 0,
        trip_numbers: []
      },
      on_time_trip: {
        count: 0,
        trip_numbers: []
      },
      dispatched: {
        count: 0,
        trip_numbers: []
      }
    }
  });

  useEffect(() => {
    if (data) {
      setOtta(prevOtta => {
        return {
          ...prevOtta,
          ...data.get_otta
        };
      });
    }
  }, [data]);

  const getSubCount = (title, subData, subDataPath) => {
    /**
     * Common component for subcounts
     * Accepts:
     *  title (string) - subcount card title
     *  subData (object) - contains count and trip_numbers
     *  subDataPath (string) - pathname on card click
     */
    return (
      <Grid item xs={6}>
        <div className={classes.dataSubContainer}>
          <Typography align="center">{title}</Typography>
          <Typography
            variant="h4"
            align="center"
            style={{
              cursor: subData.count > 0 ? "pointer" : "default"
            }}
            onClick={() =>
              subData.count > 0 &&
              history.push({
                pathname: subDataPath,
                state: {
                  params: {
                    trip_numbers: subData.trip_numbers
                  }
                }
              })
            }
          >
            {subData.count > 0 ? subData.count : 0}
          </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title} color="primary">
            ON-TIME TRUCK AVAILABILITY
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography>Pickup: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                limitTags={2}
                getLimitTagsText={more => `+${more}`}
                disableCloseOnSelect
                options={pickups}
                getOptionLabel={option => option.label.toString()}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={
                      filterValues[tabValue].pickups.length === 0 ? "All" : ""
                    }
                    onChange={e => setPickupKeyword(e.target.value)}
                  />
                )}
                disableClearable
                value={filterValues[tabValue].pickups}
                onChange={(e, nv) => {
                  const temp = [...filterValues];
                  temp[tabValue] = { ...temp[tabValue], pickups: nv };
                  setFilterValues(temp);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        <Grid item xs={6} style={{ borderRight: "1px solid #D6D6D6" }}>
          <div style={{ paddingTop: 25 }} align="center">
            INCOMING
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Tooltip title="Awaiting Tracking Info">
                  <Typography
                    align="center"
                    noWrap
                    style={{ marginLeft: 8, marginRight: 8 }}
                  >
                    Awaiting Tracking Info
                  </Typography>
                </Tooltip>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    cursor:
                      otta.incoming.awaiting.count > 0 ? "pointer" : "default"
                  }}
                  onClick={() =>
                    otta.incoming.awaiting.count > 0 &&
                    history.push({
                      pathname: "/prebooking",
                      state: {
                        params: {
                          trip_numbers: otta.incoming.awaiting.trip_numbers
                        }
                      }
                    })
                  }
                >
                  {otta.incoming.awaiting.count > 0
                    ? otta.incoming.awaiting.count
                    : 0}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    cursor:
                      otta.incoming.at_risk.count > 0 ? "pointer" : "default"
                  }}
                  onClick={() =>
                    otta.incoming.at_risk.count > 0 &&
                    history.push({
                      pathname: "/trips",
                      state: {
                        params: {
                          trip_numbers: otta.incoming.at_risk.trip_numbers
                        }
                      }
                    })
                  }
                >
                  {otta.incoming.at_risk.count > 0
                    ? otta.incoming.at_risk.count
                    : 0}
                </Typography>
              </div>
              {/* <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  otta.incoming.at_risk_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", otta.incoming.at_risk_trip, "/trips")}
              </Grid> */}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Track</Typography>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    cursor:
                      otta.incoming.on_track.count > 0 ? "pointer" : "default"
                  }}
                  onClick={() =>
                    otta.incoming.on_track.count > 0 &&
                    history.push({
                      pathname: "/trips",
                      state: {
                        params: {
                          trip_numbers: otta.incoming.on_track.trip_numbers
                        }
                      }
                    })
                  }
                >
                  {otta.incoming.on_track.count > 0
                    ? otta.incoming.on_track.count
                    : 0}
                </Typography>
              </div>
              {/* <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  otta.incoming.on_track_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", otta.incoming.on_track_trip, "/trips")}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Late</Typography>
                <Typography variant="h4" align="center">
                  {otta.arrived.late.count > 0 ? otta.arrived.late.count : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  otta.arrived.late_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", otta.arrived.late_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On-Time</Typography>
                <Typography variant="h4" align="center">
                  {otta.arrived.on_time.count > 0
                    ? otta.arrived.on_time.count
                    : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  otta.arrived.on_time_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", otta.arrived.on_time_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Dispatched</Typography>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    cursor:
                      otta.arrived.dispatched.count > 0 ? "pointer" : "default"
                  }}
                  onClick={() =>
                    otta.arrived.dispatched.count > 0 &&
                    history.push({
                      pathname: "/trips",
                      state: {
                        params: {
                          trip_numbers: otta.arrived.dispatched.trip_numbers
                        }
                      }
                    })
                  }
                >
                  {otta.arrived.dispatched.count > 0
                    ? otta.arrived.dispatched.count
                    : 0}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(OTTA);
