import { makeStyles } from "@material-ui/styles";
import backgroundResolve from "../../../assets/checklist-card-details.png";

export default makeStyles(theme => ({
  paper: {
    borderRadius: 12,
    padding: 15,
    backgroundImage: `url(${backgroundResolve})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  return: {
    marginBottom: "0.8vh"
  },
  buttonReturn: {
    fontWeight: "bold",
    fontSize: "2.5vh",
    color: theme.palette.primary.main,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)"
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0"
  },
  buttonDate: {
    backgroundColor: props => props.toResolve ? theme.palette.primary.main : "#D1D1D1",
    fontSize: "calc(0.5em + 0.85vh)",
    border: "none",
    color: "white",
    padding: "8px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: "16px",
    opacity: props => props.toResolve ? "0.75" :  "1"
  },
  buttonDriver: {
    backgroundColor: props => props.toResolve ? theme.palette.primary.main : "#D1D1D1",
    fontSize: "calc(0.5em + 0.85vh)",
    border: "none",
    color: "white",
    padding: "8px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: "5px",
    opacity: props => props.toResolve ? "0.75" :  "1",
    marginLeft: "30px"
  },
  partsContainer: {
    padding: "2.5vh 30px 0 30px",
    overflowY: "auto",
    height: "50vh",
    "&::-webkit-scrollbar": {
      width: 5,
      backgroundColor: "#F9F9F9 !important",
      borderRadius: "0px !important"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F9F9F9 !important",
      border: "none !important"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      border: "0px solid  rgba(0, 0, 0, 0) !important",
      borderRadius: 0
    }
  },
  partCategory: {
    fontWeight: "bold",
    fontSize: "calc(0.5em + 0.9vh)"
  },
  partLabel: {
    fontSize: "calc(0.5em + 0.8vh)",
    marginBottom: "0.5vh"
  },
  buttonDone: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "calc(0.5em + 0.85vh)",
    cursor: "pointer",
    border: "none",
    color: "white",
    padding: "5px 10px",
    textAlign: "center",
    borderRadius: "10px",
    width: "10%",
    marginTop: "5px",
    "&:disabled": {
      cursor: "default",
      opacity: 0.5
    }
  }
}));
