/* eslint-disable react/no-array-index-key */
import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
// import Loading from "../../../utils/Loading";
// import { GET_DEVICE } from "../../../graphql/Queries";
// import { VEHICLE_SUBSCRIPTION } from "../../../graphql/Subscription";
import BaseMap from "./BaseMap";

const MapView = props => {
  const { parsedTripData, rawTripData } = props;
  // const [center, setCenter] = useState({ latitude: "", longitude: "" });
  
  // const { subscribeToMore, loading: deviceLoading } = useQuery(GET_DEVICE, {
  //   variables: {
  //     id: parsedTripData.vehicle_id
  //   },
  //   onCompleted(data) {
  //     if (data.get_device?.device_status) {
  //       setVehicleInfo(data.get_device);
  //       const { latitude, longitude } = data.get_device.device_status;
  //       setCenter({ latitude: latitude, longitude: longitude });
  //     }
  //   },
  //   onError: () => {
  //     Swal.fire({
  //       title: "Something went wrong!",
  //       icon: "error",
  //       showConfirmButton: false,
  //       timer: 3000,
  //       onClose: () => {
  //         history.push({
  //           pathname: "/trips"
  //         });
  //       }
  //     }).then(result => {
  //       if (result.dismiss === Swal.DismissReason.timer) {
  //         history.push({
  //           pathname: "/trips"
  //         });
  //       }
  //     });
  //   }
  // });

  // const subscribeToNewVehicleLocation = () => {
  //   const unsubscribe = subscribeToMore({
  //     document: VEHICLE_SUBSCRIPTION,
  //     variables: {
  //       filter: [
  //         {
  //           field: "devicealias",
  //           value: parsedTripData.vehicle_plate_no
  //         }
  //       ]
  //     },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //       const newLocation = subscriptionData.data.device_updated;
  //       const {
  //         latitude,
  //         longitude
  //       } = subscriptionData.data.device_updated.device_status;
  //       setVehicleInfo(newLocation);
  //       setCenter({ latitude: latitude, longitude: longitude });
  //       return newLocation;
  //     }
  //   });
  //   return unsubscribe;
  // };

  // useEffect(() => {
  //   const unsubscribe = subscribeToNewVehicleLocation();
  //   return () => unsubscribe();
  // });

  return (
    <div style={{ minHeight: 600, overflow: "hidden" }}>
      <BaseMap
        rawTripData={rawTripData}
        parsedTripData={parsedTripData}
        // geofencesStops={geofencesStops}
      />
    </div>
  )
};

export default withRouter(MapView);
