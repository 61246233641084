import React, { useState, useContext } from "react";

import { Box, IconButton, Typography, styled } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import FormContext from "../../../../context/formContext";

const DEFAULT_VALUES = {
  headerLabel: "",
  webPage: "/monitoring",
  hasUpate: false
};

const Header = props => {
  const { headerLabel, webPage, hasUpate } = DEFAULT_VALUES;
  const formCtx = useContext(FormContext);
  const {
    history,
    label = headerLabel,
    redirectPage = webPage,
    hasChangedProfile = hasUpate
  } = props;

  const [profileChanged, setProfileChanged] = useState(false);
  // eslint-disable-next-line consistent-return
  const redirectPageHandler = () => {
    if (formCtx.hasUpdate || hasChangedProfile) return setProfileChanged(true);
    history.push(redirectPage);
    setProfileChanged(false);
  };

  return (
    <>
      <StyledHeaderContainer>
        <StyledBox>
          <StyledIconButton size="small" onClick={redirectPageHandler}>
            <ArrowBackIcon fontSize="small" />
          </StyledIconButton>
          <StyleTypography>{label}</StyleTypography>
        </StyledBox>
      </StyledHeaderContainer>
      <ConfirmationDialog
        toggle={profileChanged}
        close={() => setProfileChanged(false)}
        fn={() => history.push(redirectPage)}
        title="Discard Changes?"
        content="Are you sure you want to leave this page and discard changes?"
      />
    </>
  );
};
export default Header;

const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  padding: "0.5rem",
  justifyContent: "space-between"
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const StyleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.9em",
  fontWeight: "600",
  color: theme.palette.primary.dark
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "0.25rem",
  color: theme.palette.primary.dark,
  "&:hover": {
    color: theme.palette.primary.main
  }
}));
