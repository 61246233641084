/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import AccessControl from "../../../../../utils/AccessControl";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modal/ConfirmationDialog";
import { useStyles } from "./StandardGeofence.styles";
import GeofenceDetails from "./Components/GeofenceDetails";
import MapComponent from "./Components/MapComponent";
import useUserContext from "../../../../../context/User/useUserContext";
import { getGeofenceTypeColor } from "../../../../Utils";
import { EDIT_GEOFENCE } from "../../../../../graphql/Mutations";
import AddLogs from "../../../../../utils/functions/AddLogs";
import { GET_GEOFENCE } from "../../../../../graphql/Queries";
import Loading from "../../../../../utils/Loading";
import redirectToTable from "../../../../../utils/redirect";

const DEFAULT_ZOOM = 17;

const StandardGeofence = props => {
  const classes = useStyles();
  const mapStateRef = useRef();
  const user = useUserContext();
  const history = useHistory();
  const { id } = useParams();
  // const { state: locationState } = history.location;
  const requiredFields = [
    "name",
    "geofence_code",
    "category",
    "address",
    "lat",
    "lon"
  ];
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    name: "",
    geofence_code: "",
    window_time_start: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    window_time_end: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    category: "",
    address: "",
    location: { lat: 14.5995, lon: 120.9842 },
    barangay: "",
    municipality: "",
    province: "",
    region: "",
    zoom: DEFAULT_ZOOM.toString(),
    speedLimit: {
      value: 0,
      custom: 0
    }
  });
  const [editPermission, setEditPermission] = useState(false);
  const [changes, setChanges] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [dialog, setDialog] = useState({
    save: false,
    discard: false
  });

  const [editGeofence] = useMutation(EDIT_GEOFENCE, {
    onCompleted(res) {
      if (res.update_geofence.success) {
        AddLogs("Admin - Geofences", "update", state.name);
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                  vars: history?.location?.state?.vars,
                  filter: history?.location?.state?.filter
                }
              }
            });
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                  vars: history?.location?.state?.params.vars,
                  filter: history?.location?.state?.params.filter
                }
              }
            });
          }
        });
      } else {
        const temp = {};
        res.update_geofence.error.map(err => {
          Object.assign(temp, { [err.field]: err.message });
          if (err.message === "The name already exists") {
            temp.name = "Geofence name already exists";
          }
        });
        setErrors(temp);
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError?.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        });
      }
    }
  });

  const initialData = initValue => {
    const {
      name,
      category,
      geofence_code,
      address,
      barangay,
      municipality,
      province,
      region,
      location,
      window_time_start,
      window_time_end,
      geom,
      geofence_geom_id,
      opacity,
      speed_limit
    } = initValue.get_geofence;
    const geoFencePreparedInfo = {
      name,
      category,
      geofence_code,
      address,
      barangay,
      municipality,
      province,
      region,
      location,
      window_time_start: window_time_start,
      window_time_end: window_time_end,
      geom,
      geofence_geom_id,
      opacity,
      speedLimit: {
        value: [0, 70, 80, 90, 100].includes(+speed_limit)
          ? speed_limit
          : "Custom",
        custom: speed_limit
      }
    };
    setState(prev => ({
      ...prev,
      ...geoFencePreparedInfo
    }));
  };

  const { data, loading } = useQuery(GET_GEOFENCE, {
    variables: {
      id: id
    },
    onCompleted(geoFenceInfo) {
      initialData(geoFenceInfo);
    },
    fetchPolicy: "network-only",
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_GEOFENCES_MODULE);
          }
        });
      }
    }
  });

  const handleOpen = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: true });
  };

  const handleClose = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const check = field => {
    const isLocation = ["lat", "lon"].includes(field);
    if (
      (isLocation && !state.location[field]) ||
      (!isLocation && !state[field])
    ) {
      if (requiredFields.includes(field)) {
        return "This field is required";
      }
      return errors[field];
    }
    return errors[field];
  };

  const validateErrors = () => {
    let tempErrors = _.cloneDeep(errors);
    const temp = requiredFields.map(element => {
      return { [element]: check(element) };
    });
    if (mapStateRef.current?.geom) {
      temp.push({ geofence: "" });
    } else {
      temp.push({ geofence: "Geofence drawing is required" });
    }

    tempErrors = Object.assign(tempErrors, ...temp);
    setErrors(tempErrors);

    if (
      Object.keys(tempErrors)
        .filter(key => typeof tempErrors[key] !== "object")
        .every(element => !tempErrors[element])
    ) {
      handleOpen("save");
    }
  };

  const checkChanges = () => {
    const {
      location: dataLocation,
      name,
      geofence_code,
      address,
      category,
      window_time_start,
      window_time_end,
      speed_limit,
      geom,
      opacity
    } = data.get_geofence;

    return (
      JSON.stringify(state.location) !== JSON.stringify(dataLocation) ||
      name !== state.name ||
      geofence_code !== state.geofence_code ||
      address !== state.address ||
      category !== state.category ||
      window_time_start !== state.window_time_start ||
      window_time_end !== state.window_time_end ||
      +speed_limit !==
        (state.speedLimit.value === "Custom"
          ? +state.speedLimit.custom
          : +state.speedLimit.value) ||
      geom !== mapStateRef.current?.geom ||
      opacity !== mapStateRef.current?.opacity
    );
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpen("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const handleSubmit = () => {
    const { group_ids } = user.client;
    editGeofence({
      variables: {
        id: id,
        address: state.address,
        name: state.name,
        geofence_code: state.geofence_code?.toString()?.trim(),
        category: state.category,
        barangay: state.barangay,
        municipality: state.municipality,
        province: state.province,
        region: state.region,
        window_time_start: state.window_time_start,
        window_time_end: state.window_time_end,
        type: "multipolygon",
        location: state.location,
        radius: mapStateRef.current.radius,
        group_ids: group_ids,
        opacity: mapStateRef.current.opacity,
        color: getGeofenceTypeColor(state.category),
        geom: mapStateRef.current.geom,
        geofence_geom_id: mapStateRef.current.geofence_geom_id,
        speed_limit:
          state.speedLimit.value === "Custom"
            ? +state.speedLimit.custom
            : +state.speedLimit.value
      }
    });
  };

  const discarded = () => {
    setEditMode(false);
    handleClose("discard");
    initialData(data);
  };

  const renderMapComponent = useMemo(
    () => (
      <MapComponent
        state={state}
        setState={setState}
        editMode={editMode}
        mapStateRef={mapStateRef}
      />
    ),
    [state.location, state.zoom, state.category, state.address, editMode]
  );

  const { pathname } = props.location;
  if (loading) return <Loading />;
  return (
    <AccessControl
      resource={pathname}
      setEditPermission={setEditPermission}
      process="view"
    >
      <div className={classes.root}>
        <Header
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          history={history?.location?.state}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid container spacing={6} style={{ height: "calc(100% - 38px)" }}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ minHeight: "500px", maxHeight: "690px" }}
              >
                {renderMapComponent}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", marginBottom: "16px" }}
                >
                  <Typography>All fields with * are required</Typography>
                </Grid>
                <GeofenceDetails
                  state={state}
                  setState={setState}
                  errors={errors}
                  setErrors={setErrors}
                  mapStateRef={mapStateRef}
                  editMode={editMode}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => validateErrors()}
                  >
                    Save Details
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={handleSubmit}
          title="Save"
          content="Are you sure you want to save geofence?"
        />
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleClose("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
};

export default StandardGeofence;
