import { useReducer } from "react";

const actionTypes = {
  TOGGLE_INDEX: "toggle_index",
  TOGGLE_ALL_ACCESS: "toggle_all_access",
  CLOSE_ALL: "close_all"
};

const accordionReducer = (openIndexes, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_INDEX: {
      if (openIndexes.includes(action.id)) {
        return openIndexes.filter(index => index !== action.id);
      }
      return [...openIndexes, action.id];
    }
    case actionTypes.CLOSE_ALL: {
      return [];
    }
    default:
      throw new Error(`Unhandle action type: ${action.type}`);
  }
};

const useAccordion = () => {
  const [openIndexes, dispatch] = useReducer(accordionReducer, []);
  const toggleIndex = id => dispatch({ type: actionTypes.TOGGLE_INDEX, id });
  const closeAll = () => dispatch({ type: actionTypes.CLOSE_ALL });
  return { openIndexes, toggleIndex, closeAll };
};

export default useAccordion;
