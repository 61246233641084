import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  button: {
    color: "white",
    borderRadius: 50,
    textTransform: "none",
    // padding: 12,
    // minWidth: 130,
    boxShadow: "none",
    "&:hover": {
      background: "#f49400"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  icon: {
    opacity: 1
  },
  publishIcon: {
    fontSize: 100,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#ddd"
  },
  link: {
    color: "#757575"
  },
  dialog: {
    borderRadius: "10px"
  },
  loading: {
    width: 300,
    height: 300
  },
  statusUploading: {
    color: "#3f3f3f"
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  },
  expansionPanelRoot: {
    "& .MuiCollapse-entered thead": {
      top: 57,
      left: 0,
      zIndex: 1,
      position: "sticky",
      background: "#f1f1f1"
    }
  }
});
