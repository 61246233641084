import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import UserContext from "./User.context";
import { GET_ACCESS_OBJECTS, GET_USER } from "../../graphql/Queries";
import {
  ADMIN_CLIENTS_SUBSCRIPTION,
  GET_ACTIVE_SESSION
} from "../../graphql/Subscription";
import { EDIT_USER } from "../../graphql/Mutations";
import client from "../../Client";
import updateCookie from "../../utils/functions/updateCookie";

const UserProvider = ({ children, ...props }) => {
  const history = useHistory();
  const { value: user } = props;
  const [updateUser] = useMutation(EDIT_USER);
  const {
    data: userData,
    loading: isUserInfoLoading,
    subscribeToMore
  } = useQuery(GET_USER, {
    variables: {
      id: user.id
    },
    onCompleted: response => {
      const { get_user: userInfo } = response;
      // Cookie.set("user", userInfo);
      updateCookie("user",userInfo);
    }
  });
  const { data, loading, subscribeToMore: sessionSubscription } = useQuery(
    GET_ACCESS_OBJECTS,
    {
      variables: {
        user_level_id: user?.user_level_id
      }
    }
  );

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ADMIN_CLIENTS_SUBSCRIPTION,
      variables: {
        filter: [
          {
            field: "name",
            value: user.client.name
          }
        ]
      },
      updateQuery: (prev, { subscriptionData }) => {
        const {
          data: {
            client_updated: {
              id,
              name,
              is_restricted,
              alert_sounds,
              group_ids,
              group_names
            }
          }
        } = subscriptionData;

        return {
          get_user: {
            ...prev.get_user,
            client: {
              id,
              name,
              is_restricted,
              alert_sounds,
              group_ids,
              group_names
            }
          }
        };
      }
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  const parseJwt = token => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const unsubscribe = sessionSubscription({
      document: GET_ACTIVE_SESSION,
      variables: {
        filter: [
          {
            field: "id",
            value: user.id
          }
        ]
      },
      updateQuery: async (prev, { subscriptionData }) => {
        const {
          data: { session_updated }
        } = subscriptionData;
        if (Cookie.get("token")) {
          const { session_id } = parseJwt(Cookie.get("token")).user;
          if (
            user.id === session_updated.id &&
            session_updated.session_id !== session_id
          ) {
            Swal.fire({
              title: "Session Expired",
              icon: "error",
              showConfirmButton: false,
              timer: 3000
            }).then(result => {
              if (result.isConfirmed || result.isDismissed) {
                client.clearStore();
                Cookie.remove("token");
                Cookie.remove("user");
                history.push("/");
              }
            });
          }
        } else {
          history.push("/login");
        }
      }
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  const value = React.useMemo(() => {
    let access_rights = [];
    let allowed_permissions = {};
    if (data?.get_access_objects) {
      access_rights = [...data.get_access_objects];

      allowed_permissions = data.get_access_objects.reduce((accum, curr) => {
        return { ...accum, [curr.resource]: curr.permission };
      }, {});
    }

    return {
      ...user,
      ...userData?.get_user,
      access_rights,
      allowed_permissions,
      updateUser,
      loading: loading || isUserInfoLoading
    };
  }, [data, loading, userData, isUserInfoLoading]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
