import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  makeStyles,
  Menu,
  MenuItem,
  Divider,
  CircularProgress
} from "@material-ui/core";
import Cookie from "js-cookie";
import client from "../../Client";
import AntSwitch from "../../utils/UI/AntSwitch";
import AddLogs from "../../utils/functions/AddLogs";
import Focus, { Indicator } from "../../utils/Onboarding/common/Focus";
import Onboarding, {
  useOnboardingContext,
  onboarding_ctx_ref,
  onboarding_max
} from "../../utils/Onboarding";

const useStyles = makeStyles(theme => ({
  menuItemPrimary: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  menu: { overflow: "initial", width: 130 }
}));

const UserOptions = ({ anchorEl, menuId, handleMenuClose, ...props }) => {
  const classes = useStyles();
  const isMenuOpen = Boolean(anchorEl);
  const [loader, setLoader] = useState(false);
  const [enableTour, setEnableTour] = useState(false);
  const { progress } = useOnboardingContext();
  const { monitoring: currentStep } = progress;

  const enableTourNextLogin = () => {
    const currentUser = JSON.parse(Cookie.get("user"));
    const currentOnboardingUsers = JSON.parse(
      Cookie.get("onboardingUsers") || "{}"
    );

    Cookie.set(
      "onboardingUsers",
      JSON.stringify({
        ...currentOnboardingUsers,
        [currentUser.id]: Object.entries(Onboarding.modules).reduce(
          (accum, [key, _]) => {
            return {
              ...accum,
              [key]: enableTour || !(progress[key] >= onboarding_max[key])
            };
          },
          {}
        )
      })
    );
  };

  const logout = () => {
    if (enableTour) {
      enableTourNextLogin();
    } else {
      Cookie.remove("onboardingUsers");
    }
    // enableTourNextLogin();
    const { history: historyProps } = props;
    client.clearStore();
    Cookie.remove("token");
    Cookie.remove("user");
    historyProps.push("/");
  };
  const url = window.location.pathname.replace(/\//, "").split("/")[0];
  const clickDisabled =
    onboarding_ctx_ref.current === Onboarding.modules.monitoring &&
    progress.monitoring < onboarding_max[Onboarding.modules.monitoring] &&
    !["feedback", "admin", "profile", ""].includes(url);

  return (
    <Menu
      style={{ top: 60 }}
      classes={{
        paper: classes.menu
      }}
      anchorEl={anchorEl}
      // anchorEl={parentElement.current}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      // keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Focus
        step={8}
        on={currentStep === 8}
        showInfoBubble={
          onboarding_ctx_ref.current === Onboarding.modules.monitoring &&
          progress[onboarding_ctx_ref.current] <
            onboarding_max[onboarding_ctx_ref.current]
        }
        infoBubbleProps={{
          position: "left",
          title: "Tour",
          description:
            "Allows you to turn on/off tutorial anytime you may want to review TM suite features.",
          onNext: context => {
            handleMenuClose();
            context.nextStep(Onboarding.modules.monitoring);
          }
        }}
      >
        <Indicator show={currentStep === 8} placement="top-left">
          <MenuItem
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Tour</span>
            <AntSwitch
              checked={enableTour}
              onChange={e => {
                setEnableTour(e.target.checked);
              }}
              name="checkedNotif"
              value="checkedNotif"
              color="primary"
              // disabled={!editPermission}
            />
          </MenuItem>
        </Indicator>
      </Focus>
      <Divider />
      <Link
        to={{ pathname: "/profile/edit" }}
        className={classes.menuItemPrimary}
        style={{
          pointerEvents: clickDisabled ? "none" : "all"
        }}
      >
        <MenuItem onClick={handleMenuClose}>Edit Profile</MenuItem>
      </Link>
      <MenuItem
        disabled={loader}
        style={{
          pointerEvents: clickDisabled ? "none" : "all"
        }}
        onClick={() => {
          if (clickDisabled) {
            return false;
          }
          setLoader(true);
          AddLogs("Login", "logout", "");
          setTimeout(function() {
            logout();
          }, 3000);
        }}
      >
        Logout
        {loader && (
          <CircularProgress size={15} style={{ marginLeft: "10px" }} />
        )}
      </MenuItem>
    </Menu>
  );
};

export default withRouter(UserOptions);
