/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  InputLabel,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";

import Swal from "sweetalert2";
import Cookie from "js-cookie";
import _ from "lodash";
import { ADD_VEHICLE_TYPE } from "../../../../../graphql/Mutations";
import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import { Helper } from "../../../../Utils";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import AddLogs from "../../../../../utils/functions/AddLogs";
import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";
import Forbidden from "../../../../../utils/Errors/403/Forbidden";
import AccessControl from "../../../../../utils/AccessControl";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header, 32 for top + bottom margins
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  paper: {
    boxSizing: "border-box",
    minHeight: "100%",
    height: "auto",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  inputLabel: {
    // marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  }
}));

export default function AddVehicleType(props) {
  const { history, location } = props;
  const user = JSON.parse(Cookie.get("user"));
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [tempAddData, setTempAddData] = useState({
    name: "",
    capacity_volume_unit: "CBM",
    capacity_weight_unit: "Kg",
    wheels: "0",
    capacity_volume: "",
    capacity_weight: "",
    remarks: ""
  });

  const [initialState] = useState(_.cloneDeep(tempAddData));
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });

  React.useEffect(() => {
    setIsLoading(false);
  }, [discard]);

  const handleCloseDialog = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const handleAddValues = e => {
    setTempAddData({
      ...tempAddData,
      [e.target.name]: e.target.value
    });
  };

  const [addVehicleType] = useMutation(ADD_VEHICLE_TYPE, {
    onCompleted: res => {
      setDisableSubmit(false);
      setIsLoading(false);
      if (res.add_vehicle_type.success) {
        AddLogs("Admin - Vehicle Types", "insert", tempAddData.name);
        Swal.fire({
          icon: "success",
          // title: "Success",
          text: "Add successful",
          timer: 1500,
          showConfirmButton: false,
          onClose: () => {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_VEHICLE_TYPES_MODULE
                }
              }
            });
          }
        });
      } else {
        setErrors({
          ...errors,
          name: <Helper message="Vehicle Type already exists" />
        });
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      setIsLoading(false);
    }
  });

  const validateType = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^a-zA-Z0-9 ]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const validateNumber = event => {
    const regExp = /^\d{5}$/;

    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9.]/g.test(keyValue)) {
      event.preventDefault();
    } else if (event.target.value.match(regExp)) {
      event.preventDefault();
      event.target.value += ".";
    } else {
      const temp = event.target.value;
      if (/[.]/g.test(temp)) {
        if (event.target.value.match(/^[0-9]*\.[0-9][0-9]$/)) {
          event.preventDefault();
        }
      }
    }
  };

  const saveAdd = () => {
    addVehicleType({
      variables: {
        name: tempAddData.name ? tempAddData.name.toString() : "",
        capacity_weight: tempAddData.capacity_weight
          ? Number(tempAddData.capacity_weight)
          : 0,
        capacity_weight_unit: tempAddData.capacity_weight_unit
          ? tempAddData.capacity_weight_unit
          : "",
        capacity_volume: tempAddData.capacity_volume
          ? Number(tempAddData.capacity_volume)
          : 0,
        capacity_volume_unit: tempAddData.capacity_volume_unit
          ? tempAddData.capacity_volume_unit
          : "",
        remarks: tempAddData.remarks ? tempAddData.remarks : "",
        groups: [
          {
            id: user.group_ids[0],
            name: user.group_names[0]
          }
        ],
        number_of_wheels: tempAddData.wheels ? Number(tempAddData.wheels) : 0,
        client_id: Number(user.client.id)
      }
    });
    setErrors([]);
    setDisableSubmit(false);
  };

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/resources/vehicle_types/"
  // );

  if (isLoading) return <Loading />;
  // if (!permissions.edit) return <Typography>403</Typography>;
  const { pathname } = location;
  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_VEHICLE_TYPES_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={_.isEqual(initialState, tempAddData)}
        />
          <Paper className={classes.container} variant="outlined">
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      placeholder="Enter vehicle type"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={
                        <b>
                          Vehicle Type&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </b>
                      }
                      value={tempAddData.name}
                      name="name"
                      onKeyPress={validateType}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                      helperText={errors.name ? errors.name : ""}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
                    <TextField
                      fullWidth
                      id="capacity_volume"
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true
                      }}
                      // type="number"
                      label={<b>Volume Capacity</b>}
                      value={tempAddData.capacity_volume}
                      name="capacity_volume"
                      onKeyPress={validateNumber}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
                    <TextField
                      fullWidth
                      id="capacity_weight"
                      placeholder="0"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={<b>Weight Capacity</b>}
                      value={tempAddData.capacity_weight}
                      name="capacity_weight"
                      onKeyPress={validateNumber}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    />
                  </Grid>

                  <Grid>
                    <InputLabel
                      shrink
                      className={classes.inputLabel}
                      style={{ paddingTop: 20 }}
                    >
                      <b>Description</b>
                    </InputLabel>
                    <TextField
                      style={{ width: "150%" }}
                      fullWidth
                      multiline
                      minRows={4}
                      variant="outlined"
                      placeholder="Type Here..."
                      name="remarks"
                      id="remarks"
                      value={tempAddData.remarks}
                      onKeyPress={validateType}
                      onChange={e => {
                        handleAddValues(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <b>Number of Wheels</b>
                    </InputLabel>
                    <Select
                      value={tempAddData.wheels || "0"}
                      onChange={e => handleAddValues(e)}
                      displayEmpty
                      name="wheels"
                      fullWidth
                    >
                      <MenuItem value="0" disabled>
                        0
                      </MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                      <MenuItem value="14">14</MenuItem>
                      <MenuItem value="16">16</MenuItem>
                      <MenuItem value="18">18</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="24">24</MenuItem>
                      <MenuItem value="26">26</MenuItem>
                      <MenuItem value="28">28</MenuItem>
                      <MenuItem value="34">34</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <b>Unit of Volume</b>
                    </InputLabel>
                    <Select
                      value={tempAddData.capacity_volume_unit || "CBM"}
                      onChange={e => handleAddValues(e)}
                      displayEmpty
                      name="capacity_volume_unit"
                      fullWidth
                    >
                      <MenuItem value="CBM">CBM</MenuItem>
                      <MenuItem value="Liters">Liters</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel shrink className={classes.inputLabel}>
                      <b>Unit of Weight</b>
                    </InputLabel>
                    <Select
                      value={tempAddData.capacity_weight_unit || "Kg"}
                      onChange={e => handleAddValues(e)}
                      displayEmpty
                      name="capacity_weight_unit"
                      fullWidth
                    >
                      <MenuItem value="Kg">Kg</MenuItem>
                      <MenuItem value="Tons">Tons</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit}
                  fullWidth
                  style={{
                    margin: 20,
                    maxWidth: "30%",
                    marginTop: 10,
                    borderRadius: "17px"
                  }}
                  onClick={() => {
                    let temp = {};
                    if (tempAddData.name.length === 0) {
                      temp = {
                        ...temp,
                        name: <Helper message="This field is required" />
                      };
                    } else {
                      setDialog({ ...dialog, save: true });
                    }

                    setErrors(temp);
                  }}
                >
                  Save Details
                </Button>
              </Grid>
            </Grid>
            </div>
          </Paper>

          <ConfirmationDialog
            toggle={dialog.save}
            close={() => handleCloseDialog("save")}
            fn={() => saveAdd()}
            title="Save Changes?"
            content="Are you sure you want to save this data?"
          />
      </div>
    </AccessControl>
  );
}
