import React, { Fragment, useEffect, useState } from 'react';
import Map from '../../../../../utils/Map';
import { wktToCoordinates } from '../../../../../utils/functions/coordinatesParserV2';
import { mapRef as mapViewRef } from "../../../../../utils/Map/Inner";
import { Marker, Polygon } from '@react-google-maps/api';

const DEFAULT_ZOOM = 17;

const MapComponent = props => {
  const {
    location,
    selectGeo,
    isRoute
  } = props;

  var bounds = new window.google.maps.LatLngBounds();
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState({ lat: 14.5995, lng: 120.9842 });

  const startMarkerSVG = {
    path:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 20)
  };
  
  const endMarkerSVG = {
    ...startMarkerSVG,
    fillColor: "#F49400"
  };

  useEffect(() => {
    if (!isRoute && location?.lat !== "" && location?.lon !== "") {
      setCenter({
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lon)
      });
    }
    // fit route geofence to map
    if (isRoute && selectGeo) {
      const paths = wktToCoordinates(selectGeo?.geom)
      paths.forEach(path => {
        path.forEach(coor => bounds.extend(coor));
      });
      var extendPoint = new window.google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
      bounds.extend(extendPoint);
      mapViewRef.current.fitBounds(bounds);
    }
  }, [selectGeo]);

  // start & end points for route
  const getRoutePoints = () => {
    let routePoints = {};
    if (selectGeo) {
      let line_geom_parsed;
      try {
        line_geom_parsed = JSON.parse(JSON.parse(selectGeo.line_geom));
      } catch (e) {
        line_geom_parsed = null;
      }
      if (line_geom_parsed) {
        routePoints = {
          start: line_geom_parsed?.start?.coordinates,
          end: line_geom_parsed?.end?.coordinates
        };
      }
    }
    return routePoints;
  }

  const handleCenter = () => {
    if (mapViewRef.current) {
      setCenter(mapViewRef.current.getCenter().toJSON());
    }
  };

  const renderMap = () => (
    <Fragment>
      <Map
        center={center}
        zoom={!isRoute ? DEFAULT_ZOOM : 14}
        mapContainerStyle={{ height: `100%`, width: `100%` }}
        onMapTypeIdChanged={() => {
          handleCenter();
        }}
        onZoomChanged={() => handleCenter()}
        options={{
          draggable: true,
          mapTypeControl: true,
          gestureHandling: "cooperative",
          minZoom: `${isRoute ? 9 : 16}`
        }}
      >
        {selectGeo && (
          <Polygon
            paths={wktToCoordinates(selectGeo.geom)}
            options={{
              fillColor: `${isRoute ? 'lightblue' : 'yellow'}`,
              fillOpacity: 0.5,
              strokeColor: `${isRoute ? 'red' : 'black'}`,
              strokeOpacity: 0.5,
              strokeWeight: 2,
              clickable: true,
              zIndex: 1000
            }}
          />
        )}
        {isRoute && getRoutePoints().start && (
          <>
            <Marker
              icon={startMarkerSVG}
              position={getRoutePoints().start}
            />
            <Marker 
              icon={endMarkerSVG}
              position={getRoutePoints().end}
            />
          </>
        )}
      </Map>
    </Fragment>
  );
  return renderMap();
};

export default MapComponent;