/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { alpha, makeStyles, rgbToHex } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  AppBar,
  IconButton,
  InputBase,
  Badge,
  Toolbar,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  Popover,
  Avatar,
  Tooltip,
  Grid,
  useTheme,
  Box
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  AssignmentOutlined,
  InsertChartOutlined,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  LocalShipping as LocalShippingIcon,
  Event as EventIcon,
  Help,
  DriveEta,
  DesktopWindowsRounded as DesktopWindowsRoundedIcon,
  HistoryRounded as HistoryRoundedIcon
  // BorderAll
} from "@material-ui/icons";
import { deepOrange } from "@material-ui/core/colors";
import NotificationsIcon from "@material-ui/icons/Notifications";
import _, { debounce } from "lodash";
import Swal from "sweetalert2";
import { SearchProvider } from "../../context/SearchContext";
// import UserContext from "../../context/UserContext";
import LOGO from "../../assets/Images/logo.png";
import AllocationIcon from "../../assets/AllocationIcon.svg";
import Notifications from "../Notifications/Notifications";
import {
  GET_NOTIFICATIONS_QUERY,
  // GET_USER,
  // GET_ACCESS_OBJECTS,
  GET_VEHICLES_LOOKUP,
  // GET_BOOKINGS,
  GET_USERS,
  GET_ADMIN_VEHICLE_TYPES,
  GET_GEOFENCES,
  GET_USER_LEVELS,
  GET_ADMIN_ALERT_REFERENCES,
  GET_ADMIN_CLIENTS,
  GET_DEVICES,
  GET_ALERT_DESCRIPTIONS,
  GET_ALERT_REFERENCES,
  GET_GPS_PROVIDERS,
  GET_NOTIFICATIONS_PAGE,
  GET_SCHEDULED_REPORTS,
  GET_SMS_REQUESTS,
  GET_ACTIVITY_LOGS,
  GET_FEEDBACKS,
  GET_CONTROL_ROOMS,
  GET_DRIVER_INFOS,
  GET_BOOKINGS_FOR_SEARCH,
  NEW_GET_DEVICES,
  GET_VEHICLE_ALLOCATIONS
} from "../../graphql/Queries";
import { CLEAR_ALL_NOTIFICATION_MUTATION } from "../../graphql/Mutations";
import { NOTIFICATIONS_SUBSCRIPTION } from "../../graphql/Subscription";
import { GET_BOOKINGS_v2 } from "../../api/bookingRepository";
import useUserContext from "../../context/User/useUserContext";
import Focus, { Indicator } from "../../utils/Onboarding/common/Focus";
import Onboarding, { useOnboardingContext } from "../../utils/Onboarding";
import { Skeleton } from "@material-ui/lab";
import UserOptions from "./UserOptions";
import AlertNotif from "../Notifications/AlertNotif";
import useDebounce from "../../utils/hooks/useDebounce";
// import Loading from "../../utils/Loading";
import useMultipleAudio from "../Notifications/useMultipleAudio";
import alertlevel1 from "../../assets/Audio/alerts/level1.wav";
import alertlevel2 from "../../assets/Audio/alerts/level2.wav";
import alertlevel3 from "../../assets/Audio/alerts/level3.wav";
import alertlevel4 from "../../assets/Audio/alerts/level4.wav";
import alertlevel5 from "../../assets/Audio/alerts/level5.wav";
import alertlevel6 from "../../assets/Audio/alerts/level6.wav";
import Notification from "../Notifications/Notification";
import Loading from "../../utils/Loading";
import AddLogs from "../../utils/functions/AddLogs";

const Highlight = require("react-highlighter");
const drawerWidth = 70;
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  root: {
    height: "100vh",
    overflowY: "hidden"
    // display: "flex"
  },
  paperNotification: {
    position: "absolute",
    minWidth: 16,
    minHeight: 16,
    height: 500,
    maxWidth: "calc(100vw - 32px)",
    maxHeight: "calc(100vh - 32px)",
    width: 400,
    borderRadius: 30,
    backgroundColor: "#F9F9F9",
    overflow: "hidden"
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: () => {
      const url = window.location.pathname.replace(/\//, "").split("/")[0];
      if (["notifications"].includes(url)) return 1300;
      return "auto";
    },
    // boxShadow: "0px 5px 8px -5px #9c9c9c57",
    boxShadow: "none",
    backgroundColor: theme.palette.secondary.main,
    borderBottom: "1px solid #e9e9e9"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: "visible",
    // zIndex: 1299,
    minHeight: "100%"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#585858",
    // zIndex: -1,
    position: "static",
    border: "none",
    overflow: "visible"
  },
  drawerIcon: {
    color: "white",
    fontSize: "27px"
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    // maxHeight: "100%",
    // overflow: "auto",
    maxHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "#EAEAEA"
  },
  contentTrips: {
    flexGrow: 1,
    maxHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "#EAEAEA"
  },
  contentAdmin: {
    flexGrow: 1,
    height: "calc(100vh - 64px)"
  },
  contentReports: {
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    // background: "#d6d6d6",
    position: "relative",
    // top: "64px",
    boxSizing: "border-box"
  },
  preBookingContent: {
    flexGrow: 1,
    padding: theme.spacing(0)
  },
  toolbar: theme.mixins.toolbar,
  blue: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    backgroundColor: () => {
      return alpha(theme.palette.common.black, 0.1);
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: theme.palette.getContrastText(
      // "#0000000.1999999999999a"
      rgbToHex(alpha(theme.palette.common.white, 1))
    )
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  iconButton: {
    padding: 10,
    "& .MuiIconButton-label .MuiSvgIcon-root": {
      fill: `${theme.palette.getContrastText(
        rgbToHex(alpha(theme.palette.common.white, 1))
      )}`
    }
  },
  paper: {
    position: "absolute",
    overflowY: "auto",
    overflowX: "visible",
    minWidth: 16,
    minHeight: 16,
    maxWidth: "calc(100vw - 32px)",
    maxHeight: "calc(50vh - 32px)",
    width: 450,
    borderRadius: "15px"
  },
  contentRoot: {
    flexGrow: 1
  },
  listItemIconrRoot: {
    minWidth: "auto"
  },
  listItemSelected: {
    display: "flex",
    flexDirection: "column",
    padding: "6px 0",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  listItemTextRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "6px 0",
    backgroundColor: "#585858"
  },
  menuItemPrimary: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  menu: { overflow: "initial", width: 130 },
  skeleton: {
    display: "flex",
    justifyContent: "center",
    gap: 12,
    alignItems: "center",
    flexDirection: "column",
    paddingBlock: 12,
    boxSizing: "border-box"
  },
  moduleCaption: {
    fontSize: "0.6rem",
    color: "white",
    lineHeight: 1
  }
}));

const menuId = "primary-search-account-menu";

const getAudios = sound => {
  switch (sound) {
    case "level1.wav":
      return alertlevel1;
    case "level2.wav":
      return alertlevel2;
    case "level3.wav":
      return alertlevel3;
    case "level4.wav":
      return alertlevel4;
    case "level5.wav":
      return alertlevel5;
    case "level6.wav":
      return alertlevel6;
    default:
      return "";
  }
};

const uppercaseLabel = ["pms"];

const ClippedDrawer = props => {
  const theme = useTheme();
  const recentSearches = localStorage.getItem("recentSearches");
  const recentSearchesParsed = recentSearches
    ? JSON.parse(localStorage.getItem("recentSearches"))
    : [];
  const { isShown, isHeaderShown, children, location } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const userDetails = useContext(UserContext);
  const userDetails = useUserContext();
  const [searchKeyword, setSearchKeyword] = useState({
    value: "",
    submit: false,
    moduleName: ""
  });
  const [notifBadge, setNotifBadge] = useState(0);
  const [searchHistory, setSearchHistory] = useState(recentSearchesParsed);
  const notifVisibilityRef = useRef(false);
  const notifPopupStateRef = useRef();
  const [notificationData, setNotificationData] = useState([]);
  const localStorageMute = localStorage.getItem("mute_notifications");
  const sounds = userDetails.client.alert_sounds || {
    alert_level0_sound: "",
    alert_level1_sound: "",
    alert_level2_sound: "",
    alert_level3_sound: ""
  };
  const { players, toggle, toggleMute, mute } = useMultipleAudio(
    Object.keys(sounds).map(key => getAudios(sounds[key])),
    { pauseWhenDoubleTrigger: false, mute: localStorageMute === "true" }
  );
  const { pathname } = location;
  const module = pathname.split("/")[1];
  // const [alertData, setAlertData] = useState([]);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [moduleOptions, setModuleOptions] = useState({
    query: GET_VEHICLES_LOOKUP, // default
    variables: {
      first: 5
    }
  });
  const [suggestions, setSuggestions] = useState([]);
  // const user = JSON.parse(Cookie.get("user"));
  // eslint-disable-next-line no-underscore-dangle
  const _user_context = useUserContext();
  const group_ids = _user_context.group_ids.map(id => id.toString());
  const {
    progress: { monitoring: monitoringCurrentStep }
  } = useOnboardingContext({ module: Onboarding.modules.monitoring });

  const tripFilters = {
    first: 5,
    or: [
      {
        field: "status_code_id",
        value: [
          process.env.REACT_APP_STATUS_TRIP_INCOMING,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
          process.env.REACT_APP_STATUS_IN_TRANSIT,
          process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
          process.env.REACT_APP_STATUS_TRIP_COMPLETED,
          process.env.REACT_APP_STATUS_TRIP_CLOSED
        ]
      }
    ],
    not: [{ field: "dispatch_type", value: "Not Yet" }],
    date_range: {
      field: "pickups.arrival",
      start: moment()
        .subtract(6, "months")
        .format("YYYY-MM-DD 00:00:00"),
      end: moment()
        .add(60, "days")
        .format("YYYY-MM-DD 23:59:59")
    }
  };

  const prebookingFilters = {
    first: 5,
    not: [
      { field: "status_code.keyword", value: "Arrived at Drop off" },
      { field: "status_code.keyword", value: "Completed" },
      { field: "status_code.keyword", value: "In Transit" }
    ],
    conditions: [{ field: "dispatch_type", value: ["Not Yet"] }],
    date_range: {
      field: "pickups.arrival",
      start: moment()
        .subtract(6, "months")
        .format("YYYY-MM-DD 00:00:00"),
      end: moment()
        .add(60, "days")
        .format("YYYY-MM-DD 23:59:59")
    }
  };

  const geofencesFilters = {
    group_ids: searchKeyword?.group?.group_ids || [],
    keyword_fields: [
      "name",
      "geofence_code",
      "category",
      "region",
      "province",
      "municipality"
      // "city"
    ],
    orderBy: searchKeyword.orderBy
  };

  const vehiclesFilters = {
    group_ids: searchKeyword?.group?.group_ids || [],
    filter: [{ field: "vehicle_info.plateno", value: searchKeyword.value }]
  };

  const automatedReportsFilter = {
    condition: [{ field: "group_ids", value: [group_ids[0]] }]
  };

  const allocationFilters = {
    filter: [
      {
        field: "allocation.group_id",
        value: [
          userDetails.group_ids[
            _.findIndex(userDetails.group_names, gn => {
              return gn === userDetails.client.name;
            })
          ].toString()
        ]
      }
    ]
  };

  const getSmsSendingFilter = () => {
    return {
      dateFilter: {
        field: "created",
        gte: moment()
          .subtract(30, "day")
          .format("YYYY-MM-DD 00:00:00")
      },
      condition: searchKeyword?.group?.id
        ? [
            {
              field: "group_ids",
              value: [searchKeyword.group.id]
            }
          ]
        : []
    };
  };

  const getActivityLogsFilter = () => {
    return {
      condition:
        searchKeyword?.group && searchKeyword?.group?.length > 0
          ? searchKeyword.group.map(id => ({
              field: "group_ids",
              value: id.toString()
            }))
          : _user_context.group_ids.map(id => ({
              field: "group_ids",
              value: id.toString()
            }))
    };
  };

  useEffect(() => {
    let tempArgs = { ...moduleOptions };
    if (pathname !== "/admin") {
      switch (module) {
        case "monitoring":
          tempArgs = {
            query: GET_VEHICLES_LOOKUP,
            dataPath: "get_vehicles.vehicles",
            column: "plateno",
            filterOptions: vehiclesFilters
          };
          break;
        case "prebooking":
          tempArgs = {
            query: GET_BOOKINGS_FOR_SEARCH,
            dataPath: "get_bookings_per_trip.bookings",
            column: "key",
            filterOptions: prebookingFilters
          };
          break;
        case "trips":
          tempArgs = {
            query: GET_BOOKINGS_FOR_SEARCH,
            dataPath: "get_bookings_per_trip.bookings",
            column: "key",
            filterOptions: tripFilters
          };
          break;
        case "notifications":
          tempArgs = {
            query: GET_NOTIFICATIONS_PAGE,
            dataPath: "get_notifications.notifications",
            column: "trip_number"
          };
          break;
        case "reports":
          if (pathname.split("/")[2] === "automated") {
            tempArgs = {
              query: GET_SCHEDULED_REPORTS,
              dataPath: "get_scheduled_reports.scheduled_reports",
              column: "report_type",
              filterOptions: automatedReportsFilter
            };
          }
          break;
        case "pms":
          tempArgs = {
            query: GET_VEHICLES_LOOKUP,
            dataPath: "get_vehicles.vehicles",
            column: "plateno",
            filterOptions: vehiclesFilters
          };
          break;
        case "control_room":
          tempArgs = {
            query: GET_CONTROL_ROOMS,
            dataPath: "get_control_rooms.control_rooms",
            column: "name",
            filterOptions: {
              query: {
                first: 5,
                keyword: `${searchKeyword.value}`
              }
            }
          };
          break;
        case "allocation":
          tempArgs = {
            query: GET_VEHICLE_ALLOCATIONS,
            dataPath: "get_allocations.allocations",
            column: "trip_number",
            filterOptions: allocationFilters
          };
          break;
        default:
          break;
      }
    } else {
      switch (searchKeyword.moduleName) {
        case process.env.REACT_APP_VEHICLES_MODULE:
          tempArgs = {
            query: GET_VEHICLES_LOOKUP,
            dataPath: "get_vehicles.vehicles",
            column: "plateno",
            filterOptions: vehiclesFilters
          };
          break;
        case process.env.REACT_APP_VEHICLETYPES_MODULE:
          tempArgs = {
            query: GET_ADMIN_VEHICLE_TYPES,
            dataPath: "get_vehicle_types.vehicle_types",
            column: "name"
          };
          break;
        case process.env.REACT_APP_GEOFENCES_MODULE:
          tempArgs = {
            query: GET_GEOFENCES,
            dataPath: "get_geofences.geofences",
            column: "name",
            filterOptions: geofencesFilters
          };
          break;
        case process.env.REACT_APP_RIGHTS_MODULE:
          tempArgs = {
            query: GET_USER_LEVELS,
            dataPath: "get_user_levels.userlevels",
            column: "name"
          };
          break;
        case process.env.REACT_APP_ALERTS_MODULE:
          tempArgs = {
            query: GET_ADMIN_ALERT_REFERENCES,
            dataPath: "get_alert_references.alert_references",
            column: "alertmessage"
          };
          break;
        case process.env.REACT_APP_CLIENTS_MODULE:
          tempArgs = {
            query: GET_ADMIN_CLIENTS,
            dataPath: "get_clients.clients",
            column: "name"
          };
          break;
        case process.env.REACT_APP_GPSDEVICES_MODULE:
          tempArgs = {
            query: NEW_GET_DEVICES,
            dataPath: "get_devices.devices",
            column: "name"
          };
          break;
        case process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE:
          tempArgs = {
            query: GET_ALERT_DESCRIPTIONS,
            dataPath: "get_alert_descriptions.alert_descriptions",
            column: "description"
          };
          break;
        case process.env.REACT_APP_ALERT_REFERENCES_MODULE:
          tempArgs = {
            query: GET_ALERT_REFERENCES,
            dataPath: "get_alert_references.alert_references",
            column: "alertmessage"
          };
          break;
        case process.env.REACT_APP_GPS_PROVIDER_MODULE:
          tempArgs = {
            query: GET_GPS_PROVIDERS,
            dataPath: "get_gps_providers.gps_providers",
            column: "name"
          };
          break;
        case process.env.REACT_APP_SMS_SENDING_MODULE:
          tempArgs = {
            query: GET_SMS_REQUESTS,
            dataPath: "get_sms_requests.sms_requests",
            column: "message",
            filterOptions: getSmsSendingFilter()
          };
          break;
        case process.env.REACT_APP_ACTIVITY_LOGS_MODULE:
          tempArgs = {
            query: GET_ACTIVITY_LOGS,
            dataPath: "get_activity_logs.activitylogs",
            column: "activity",
            filterOptions: getActivityLogsFilter()
          };
          break;
        case process.env.REACT_APP_FEEDBACK_MESSAGE:
          tempArgs = {
            query: GET_FEEDBACKS,
            dataPath: "get_feedback.feedback",
            column: "message"
          };
          break;
        case process.env.REACT_APP_DRIVER_INFO_MODULE:
          tempArgs = {
            query: GET_DRIVER_INFOS,
            dataPath: "get_personnels.personnels",
            column: "name",
            filterOptions: {
              query: {
                first: 5,
                keyword: `${searchKeyword.value}`
              }
            }
          };
          break;
        default:
          tempArgs = {
            query: GET_USERS,
            dataPath: "get_users.users",
            column: "username",
            filterOptions: {
              filter: [
                { field: "user_level_id", value: "" }
                // { field: "client_id", value: client }
              ]
            }
          };
          break;
      }
    }
    setModuleOptions(tempArgs);
  }, [location, searchKeyword /* .moduleName */]);

  const [querySuggestions, { loading: isSuggestionLoading }] = useLazyQuery(
    moduleOptions.query,
    {
      onCompleted: response => {
        if (response) {
          const { dataPath, column } = moduleOptions;
          if (dataPath) {
            const [queryName, entity] = dataPath.split(".");
            const data_list = response[queryName][entity];
            if (data_list) {
              const fetchedSuggestions = data_list.reduce((accum, current) => {
                if (current[column] !== null) {
                  return [...accum, current[column]];
                }
                return accum;
              }, []);
              setSuggestions(_.uniq(fetchedSuggestions));
            } else {
              setSuggestions([]);
            }
          }
        }
      },
      variables: {
        keyword: `${searchKeyword.value}`,
        first: 5,
        ...moduleOptions?.filterOptions
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  let handleDebounce = (...args) => {
    handleDebounce = debounce(...args);
    return e => {
      e.persist();
      return handleDebounce(e);
    };
  };

  useEffect(() => {
    if (searchKeyword.value !== "" && module !== "feedback") {
      querySuggestions();
    }
  }, [searchKeyword.value]);

  const searchVal = useRef();
  const handleSearch = (event, value) => {
    let enterKeyUsed = false;
    const text = searchVal.current.value || value || event?.target?.value;
    if (event) {
      //  event.shiftKey
      if (event.ctrlKey || event.altKey) {
        return;
      }
      if (event.key === "Enter") {
        // when enter-key is used to pick from suggestion(s)
        if (text) {
          enterKeyUsed = true;
        }
      }
    }

    setSearchKeyword(prev => ({
      ...prev,
      value: text,
      submit: enterKeyUsed
    }));
  };

  const handleSubmit = (e, opt) => {
    if (e.key === "Enter" || !e.key) {
      const searchString = opt ? opt.trim() : searchVal.current.value.trim();

      if (searchString && module === "monitoring") {
        if (recentSearchesParsed.length >= 5) {
          recentSearchesParsed.pop();
        }

        if (!recentSearchesParsed.includes(searchString)) {
          const searchTemp = [searchString, ...recentSearchesParsed];
          setSearchHistory([searchString, ...recentSearchesParsed]);
          localStorage.setItem("recentSearches", JSON.stringify(searchTemp));
        }
      }

      setSearchKeyword(prev => ({
        ...prev,
        value: searchString,
        submit: true
      }));
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  /* 10 most recent notifications,  is_urgent notifs stay at the top */
  const {
    data,
    subscribeToMore,
    refetch,
    fetchMore,
    loading: loadingNotif
  } = useQuery(GET_NOTIFICATIONS_QUERY, {
    variables: {
      filter: [
        {
          field: "group_ids",
          value: _user_context.group_ids.map(gi => gi.toString())
        },
        {
          field: "is_archived",
          value: "false"
        },
        {
          field: "category.keyword",
          // value: ["Dwell Time", "GPS Alert", "Trip"]
          value: ["Dwell Time", "Trip"]
        }
      ],
      system_notifs: [
        {
          field: "group_ids",
          value: _user_context.group_ids.map(gi => gi.toString())
        },
        {
          field: "is_archived",
          value: "false"
        },
        {
          field: "category.keyword",
          value: ["Trip Upload", "System Response", "User Activity"]
        }
      ],
      gps_notifs: [
        {
          field: "group_ids",
          value: _user_context.group_ids.map(gi => gi.toString())
        },
        {
          field: "is_archived",
          value: "false"
        },
        {
          field: "category.keyword",
          value: ["GPS Alert"]
        }
      ],
      unread_filter: [
        { field: "is_read", value: "false" },
        {
          field: "group_ids",
          value: _user_context.group_ids.map(gi => gi.toString())
        },
        {
          field: "is_archived",
          value: "false"
        },
        {
          field: "category.keyword",
          value: [
            "Trip Upload",
            "Dwell Time",
            "GPS Alert",
            "Trip",
            "System Response",
            "User Activity"
          ]
        }
      ],
      dateRange: {
        start: `${moment()
          .subtract(2, "days")
          .format("YYYY-MM-DD")} 00:00:00`,
        end: `${moment().format("YYYY-MM-DD")} 23:59:59`
      }
    }
  });

  const [clearAllNotifs, { loading: clearAllNotifLoading }] = useMutation(
    CLEAR_ALL_NOTIFICATION_MUTATION,
    {
      variables: {
        conditions: [
          {
            field: "is_archived",
            value: "false"
          },
          {
            field: "group_ids",
            value: _user_context.group_ids.map(gi => gi.toString())
          }
        ]
        // filter: {
        //   field: "group_ids",
        //   value: _user_context.group_ids.map(gi => gi.toString())
        // }
      },
      onCompleted: () => {
        refetch();
      },
      onError: ApolloError => {
        const { graphQLErrors: returnError } = JSON.parse(
          JSON.stringify(ApolloError)
        );
        const returnData = returnError.map(el => {
          return el.message;
        });
        if (returnData) {
          refetch();
          Swal.fire({
            title: returnData[0],
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
    }
  );

  const closeNotifPopup = () => {
    return notifPopupStateRef.current?.close();
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState !== "visible") {
        notifVisibilityRef.current = false;
        closeNotifPopup();
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      const { total_unread } = data;
      setNotifBadge(total_unread.total);

      setNotificationData(data);
    }
  }, [data]);

  const subscribeToNewNotifications = () => {
    const unsubscribe = subscribeToMore({
      document: NOTIFICATIONS_SUBSCRIPTION,
      variables: {
        filter: [
          {
            field: "group_ids",
            value: _user_context.group_ids.map(gi => gi.toString())
          },
          {
            field: "is_archived",
            value: "false"
          }
        ],
        dateRange: {
          start: `${moment()
            .subtract(2, "days")
            .format("YYYY-MM-DD")} 00:00:00`,
          end: `${moment().format("YYYY-MM-DD")} 23:59:59`
        }
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (!prev) return;
        const newNotif = subscriptionData.data.notification_added;
        if (
          newNotif.category === "GPS Alert" &&
          localStorage.getItem("mute_notifications") === "false"
        ) {
          if ([0, 2, 3].includes(newNotif.alert_level) && players.length) {
            let alertLevel;
            switch (newNotif.alert_level) {
              case 0:
                alertLevel = 0;
                break;
              case 2:
                alertLevel = 1;
                break;
              case 3:
                alertLevel = 2;
                break;
              default:
                throw new Error(
                  `Can't handle alert level: ${newNotif.alert_Level}`
                );
            }
            const callback = toggle(alertLevel);
            if (alertLevel === 2) callback();
            else if (!players[2].playing) {
              callback();
            }
          }
        }
        if (!notifVisibilityRef.current) return prev;

        let newData = {};
        if (
          ["Trip Upload", "System Response", "User Activity"].includes(
            newNotif.category
          )
        ) {
          newData = {
            ...prev,
            system_notifications: {
              count: prev?.system_notifications?.count + 1,
              total: prev?.system_notifications?.total + 1,
              notifications: [
                newNotif,
                ...(prev?.system_notifications?.notifications || [])
              ]
            },
            total_unread: {
              total: notifBadge + 1
            }
          };
        } else if (["GPS Alert"].includes(newNotif.category)) {
          newData = {
            ...prev,
            gps_notifications: {
              count: prev?.gps_notifications?.count + 1,
              total: prev?.gps_notifications?.total + 1,
              notifications: [
                newNotif,
                ...(prev?.gps_notifications?.notifications || [])
              ]
            },
            total_unread: {
              total: notifBadge + 1
            }
          };
        } else {
          newData = {
            ...prev,
            get_notifications: {
              count: prev?.get_notifications?.count + 1,
              total: prev?.get_notifications?.total + 1,
              notifications: [
                ...(prev?.get_notifications?.notifications || []),
                newNotif
              ]
            },
            total_unread: {
              total: notifBadge + 1
            }
          };
        }

        return newData;
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = subscribeToNewNotifications();
    return () => unsubscribe();
  }, []);

  const isSearchBarVisible = () => {
    const UrlModule = location.pathname;
    const indexId = UrlModule.lastIndexOf("/");
    const urlId = UrlModule.slice(indexId + 1, UrlModule.length);

    const linksUnshowSearchBar = [
      "/dashboard",
      "/reports",
      `/admin/resources/assign_geofence/${urlId}`,
      "/details",
      "/profile/edit"
    ];

    const isTripAllocation =
      _.isEqual(UrlModule, "/allocation") &&
      (_.isEqual(location?.state?.params?.moduleName, "Trip Allocation") ||
        _.isEmpty(location.state));

    return (
      linksUnshowSearchBar.includes(UrlModule) ||
      UrlModule.includes("view") ||
      UrlModule.includes("add") ||
      isTripAllocation
    );
  };

  const Header = (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar>
        <img
          src={LOGO}
          style={{ position: "absolute", height: "100%" }}
          alt=""
        />
        <div className={classes.grow} />

        {!isSearchBarVisible() && (
          <div className={classes.search}>
            <Autocomplete
              loading={isSuggestionLoading}
              options={
                !searchKeyword?.value && module === "monitoring"
                  ? searchHistory.map(option => option)
                  : suggestions.map(option => option)
              }
              freeSolo
              autoSelect
              value={searchKeyword?.value || ""}
              onInput={handleDebounce(handleSearch, 200)}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <InputBase
                    {...params.inputProps}
                    inputRef={searchVal}
                    placeholder="Search here"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onKeyPress={handleSubmit}
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={
                      searchKeyword.submit
                        ? () => {
                            setSearchKeyword(prev => ({
                              ...prev,
                              value: "",
                              submit: false
                            }));
                          }
                        : handleSubmit
                    }
                  >
                    {searchKeyword.submit ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </div>
              )}
              renderOption={option => (
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width="100%"
                  onClick={event => handleSubmit(event, option)}
                >
                  <Typography variant="subtitle2">
                    <Highlight search={searchKeyword.value}>{option}</Highlight>
                  </Typography>
                  {!searchKeyword?.value && module === "monitoring" && (
                    <HistoryRoundedIcon fontSize="small" color="disabled" />
                  )}
                </Box>
              )}
            />
          </div>
        )}
        <div
          className={classes.sectionDesktop}
          style={{ display: "flex", alignItems: "center" }}
        >
          <AlertNotif classesAlert={classes} />
          <PopupState variant="popover">
            {popupState => (
              <Fragment>
                <IconButton
                  aria-label="show 17 new notifications"
                  color="primary"
                  onClickCapture={() => {
                    notifPopupStateRef.current = popupState;
                    notifVisibilityRef.current = true;
                    refetch();
                  }}
                  {...bindTrigger(popupState)}
                >
                  {/* style={{ backgroundColor: "#fa4a4a", color: "#FFFFFF" }} */}
                  <Badge
                    badgeContent=""
                    variant="dot"
                    color="error"
                    invisible={notifBadge <= 0}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  classes={{ paper: classes.paperNotification }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  onClose={() => {
                    notifVisibilityRef.current = false;
                    popupState.close();
                  }}
                >
                  {/* <Notifications
                    mute={mute}
                    toggleMute={toggleMute}
                    popupState={popupState}
                    notifications={notificationData}
                    refetch={refetch}
                    history={props.history}
                    fetchMore={fetchMore}
                    fn={clearAllNotifs}
                  /> */}
                  {popupState.anchorEl && (
                    <Notification
                      isShown={popupState}
                      notificationData={notificationData}
                      getAgainNotif={refetch}
                      history={props.history}
                      getMoreNotif={fetchMore}
                      clearNotificationHandler={() => {
                        popupState.close();
                        clearAllNotifs().then(() => {
                          Swal.fire({
                            title: "Success",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            onClose: () => {
                              popupState.open();
                            }
                          });
                        });
                        AddLogs("Notification", "clear_notif");
                      }}
                      isFetchingData={loadingNotif}
                      mute={mute}
                      toggleMute={toggleMute}
                    />
                  )}
                </Popover>
              </Fragment>
            )}
          </PopupState>
          <Focus
            step={8}
            on={monitoringCurrentStep === 8}
            style={{ color: "black" }}
            showInfoBubble={!isMenuOpen}
            infoBubbleProps={{
              position: "left",
              disableAction: true,
              title: "Profile",
              description: "Click to show available settings"
            }}
          >
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="secondary"
              style={{ marginRight: 0 }}
            >
              <Indicator
                show={({ progress: { monitoring: currentStep } }) =>
                  currentStep === 8 && !isMenuOpen
                }
              >
                <Avatar
                  src={
                    process.env.REACT_APP_CLOUD_STORAGE_BASE_URL +
                    userDetails.profile_image
                  }
                  style={{
                    color: theme.palette.primary.main,
                    backgroundColor: "white"
                  }}
                />
              </Indicator>
            </IconButton>
          </Focus>
          <UserOptions
            anchorEl={anchorEl}
            menuId={menuId}
            handleMenuClose={handleMenuClose}
          />
        </div>
      </Toolbar>
    </AppBar>
  );

  const getClassname = style => {
    let className = style.content;
    if (module === "addmin") {
      className = style.contentAdmin;
    } else if (
      module === "monitoring" ||
      module === "notifications" ||
      module === "prebooking"
    ) {
      className = style.contentRoot;
    } else if (module === "reports") {
      className = style.contentReports;
    } else if (module === "trips" || module === "details") {
      className = style.contentTrips;
    }
    return className;
  };

  const getIcon = text => {
    let icon;
    switch (text) {
      case "trips":
        icon = <AssignmentOutlined className={classes.drawerIcon} />;
        break;
      case "reports":
        icon = <InsertChartOutlined className={classes.drawerIcon} />;
        break;
      case "admin":
        icon = <SettingsIcon className={classes.drawerIcon} />;
        break;
      case "dashboard":
        icon = <DashboardIcon className={classes.drawerIcon} />;
        break;
      case "monitoring":
        icon = <LocalShippingIcon className={classes.drawerIcon} />;
        break;
      case "prebooking":
        icon = <EventIcon className={classes.drawerIcon} />;
        break;
      case "feedback":
        icon = <Help className={classes.drawerIcon} />;
        break;
      case "pms":
        icon = <DriveEta className={classes.drawerIcon} />;
        break;
      case "control_room":
        icon = <DesktopWindowsRoundedIcon className={classes.drawerIcon} />;
        break;
      case "allocation":
        icon = <img src={AllocationIcon} style={{ height: "27px" }} />;
        break;
      default:
        icon = <AssignmentOutlined className={classes.drawerIcon} />;
        break;
    }
    return icon;
  };

  // const { loading: userLoading, data: userData } = useQuery(GET_USER, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     id: user.id
  //   }
  // });

  const { history } = props;

  // const { loading, data: dataACL, refetch: refetchACL } = useQuery(
  //   GET_ACCESS_OBJECTS,
  //   {
  //     fetchPolicy: "network-only",
  //     variables: {
  //       user_level_id: user.user_level_id
  //     },
  //     onError: () => {
  //       Swal.fire({
  //         title: "Error",
  //         text: "Something went wrong",
  //         icon: "error",
  //         showConfirmButton: false,
  //         timer: 1500,
  //         onClose: () => {
  //           Cookie.remove("token");
  //           Cookie.remove("user");
  //           history.push({
  //             pathname: "/login"
  //           });
  //         }
  //       });
  //     }
  //   }
  // );

  // useEffect(() => {
  //   if (!userLoading) {
  //     refetchACL({ user_level_id: userData.get_user.user_level_id });
  //   }
  // }, [userData]);

  useEffect(() => {
    if (searchKeyword.submit && searchKeyword.moduleName) {
      // Redirect depending on selected module (using pathname variable)
      history.push({
        pathname,
        state: {
          params: {
            moduleName: searchKeyword.moduleName
          }
        }
      });
    }
  }, [searchKeyword]);

  const sortLinks = array => {
    const hierarchy = [
      "monitoring",
      "control_room",
      "prebooking",
      "trips",
      "dashboard",
      "reports",
      "pms",
      "allocation",
      "admin",
      "feedback"
    ];
    const sideLinks = hierarchy
      .filter(moduleName => array.indexOf(moduleName) > -1)
      .map(moduleName => moduleName);
    return sideLinks;
  };

  const getSidelinks = () => {
    const links = [];
    // if (!loading) {
    //   if (dataACL) {
    _user_context.access_rights
      .sort((a, b) => +a.id - +b.id)
      .map(
        object =>
          object.permission.view && links.push(object.resource.split("/")[1])
      );
    //   }
    // }
    return sortLinks(_.uniq(links));
  };

  const onBeta = ["pms"];

  return (
    <div className={classes.root}>
      {clearAllNotifLoading && <Loading />}
      <SearchProvider
        value={{
          searchKeyword: searchKeyword,
          setSearchKeyword: setSearchKeyword
        }}
      >
        {/* <CssBaseline /> */}
        {/* {renderMenu} */}
        {isHeaderShown ? Header : null}
        {/* <Header notifs={data} subs={subscribeToNewNotifications} /> */}
        <div
          style={{
            display: "flex",
            minHeight: "calc(100% - 64px)",
            height: "calc(100% - 64px)"
          }}
        >
          {!isShown && (
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {/* <div className={classes.toolbar} /> */}
              {_user_context.loading ? (
                <div className={classes.skeleton}>
                  {[
                    "module-1",
                    "module-2",
                    "module-3",
                    "module-4",
                    "module-5"
                  ].map(m => (
                    <Skeleton
                      key={m}
                      variant="rect"
                      width={50}
                      height={50}
                      animation="wave"
                      style={{
                        borderRadius: 8
                      }}
                    />
                  ))}
                </div>
              ) : (
                <List>
                  {getSidelinks().map(text => (
                    <Link
                      key={text}
                      to={{
                        pathname: `/${text}`,
                        state: {}
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      {onBeta.includes(text) && (
                        <div
                          style={{
                            position: "absolute",
                            marginTop: "5%",
                            right: "-10px",
                            zIndex: "1",
                            textAlign: "center",
                            width: 30,
                            color: "#ffffff",
                            fontWeight: 700,
                            fontSize: 8,
                            padding: "0.2rem",
                            backgroundColor: "#EDD608",
                            borderRadius: "50px",
                            boxShadow: "0px 7px 9px -7px rgba(0,0,0,0.36)",
                            letterSpacing: 1
                          }}
                        >
                          BETA
                        </div>
                      )}
                      <ListItem
                        button
                        key={text}
                        className={
                          module === text
                            ? classes.listItemSelected
                            : classes.listItemTextRoot
                        }
                      >
                        <ListItemIcon
                          classes={{ root: classes.listItemIconrRoot }}
                        >
                          {getIcon(text)}
                        </ListItemIcon>
                        <Typography
                          variant="caption"
                          className={classes.moduleCaption}
                        >
                          {uppercaseLabel.includes(text)
                            ? text.toUpperCase()
                            : _.startCase(text)}
                        </Typography>
                      </ListItem>
                    </Link>
                  ))}
                </List>
              )}
            </Drawer>
          )}
          {/* !TODO remove styling in styles.content */}
          <main className={getClassname(classes)}>
            {/* <div className={classes.toolbar} /> */}
            {/* CONTENT */}
            {children}
          </main>
        </div>
      </SearchProvider>
    </div>
  );
};

export default withRouter(ClippedDrawer);
