import _ from 'lodash';

/* 
    function validate the uploaded file matches the given template.
*/

export const checkTemplate = (rowData, envData) => {
  let tempCountHeader = 0;
  return rowData.map((data, index) => {
    if (data) {
      // convert white spaces between words to _.
      let tempData = _.lowerCase(data)
        .split(" ")
        .join("_");
      // count the number of header rows on the excel file
      tempCountHeader++;
      
      // validation for upload geofence for client & geofence name
      if (_.isEqual(tempData, "client") ||  _.isEqual(tempData, "geofence_name")) {
        tempData = envData[index];
      }
      // validates if the tempData is included on the envData and has the same length 
      if (envData.includes(tempData) && _.isEqual(tempCountHeader, envData.length)) return true;
      else return false;
    }
  });
};
