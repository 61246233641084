import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  makeStyles
} from "@material-ui/core";

export const tableHeadStyles = makeStyles({
  root: {
    fontWeight: 600,
    "& .MuiTableCell-root": {
      letterSpacing: 0.25,
      fontWeight: 600
    },
    minHeight: "58.5px",
    height: "58.5px"
  },
  cellRoot: {
    paddingLeft: 12,
    paddingTop: "8px !important",
    paddingBottom: "8px !important"
  }
});

export const headerCells = [
  {
    id: "report_type",
    numeric: false,
    disablePadding: true,
    label: "Report Type"
  },
  {
    // id: "scheduled_reports.interval", // use this instead if pagination is implemented in backend
    id: "sked",
    numeric: false,
    disablePadding: true,
    label: "Generate Interval"
  },
  {
    // id: "sked",
    id: "timesked",
    numeric: false,
    disablePadding: true,
    label: "Every"
  },
  {
    id: "email_add",
    numeric: false,
    disablePadding: true,
    label: "Recipients"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Action" }
];

const CustomTableHead = props => {
  const styles = tableHeadStyles();
  const {
    selectedRows,
    toggleSelectAll,
    tableItemsCount,
    handleSort,
    sort,
    permissions
  } = props;

  return (
    <TableHead classes={{ root: styles.root }}>
      <TableRow>
        {permissions.edit && (
          <TableCell padding="checkbox" classes={{ root: styles.cellRoot }}>
            <Checkbox
              color="primary"
              onChange={toggleSelectAll}
              indeterminate={
                selectedRows.length > 0 && selectedRows.length < tableItemsCount
              }
              checked={
                selectedRows.length > 0 &&
                selectedRows.length === tableItemsCount
              }
            />
          </TableCell>
        )}
        {headerCells.map(cell => {
          return (
            <TableCell
              classes={{ root: styles.cellRoot }}
              // padding={cell.disablePadding ? "none" : "normal"}
              padding="checkbox"
              key={cell.id}
              align={cell.id === "actions" ? "center" : "left"}
            >
              {cell.label !== "" && cell.label !== "Action" ? (
                <TableSortLabel
                  active={sort.field === cell.id}
                  direction={sort.field === cell.id ? sort.direction : "asc"}
                  // IconComponent={UnfoldMore}
                  onClick={() => handleSort(cell.id, sort.direction)}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
