/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useCallback, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  Typography,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
  Switch,
  withStyles,
  FormControlLabel,
  Chip,
  RadioGroup,
  FormLabel,
  Radio,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  HelpRounded,
  ArrowBack as ArrowBackIcon,
  Attachment,
  Help as HelpIcon
} from "@material-ui/icons";
import _ from "lodash";
import Cookie from "js-cookie";

// Components
import ChipInput from "material-ui-chip-input";
import ReportTypeInput from "../../common/ReportTypeInput";
import ErrorMessage from "../../common/ErrorMessage";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Header from "../../common/Header";
import Table from "./EmailLogsTable";
import DivisionTitle from "./DivisionTitle";
import VehicleChipInput from "../../common/VechicleChipInput";
import AlertTypeChipInput from "../../common/AlertTypeChipInput";
import TripStatusChipInput from "../../common/TripStatusChipInput";
import ActivityOnlyInput from "../../common/ActivityOnlyInput";
import VehicleSearchInput from "../../common/VehicleSearchInput";
import IdlingDuration, {
  idlingDurationParser,
  reverseDuration
} from "../../common/IdlingDuration";

// Utilities
import { days, times, dates } from "../../utils/rawData";
import validate from "../../../../utils/validation";
import alertFire from "../../utils/alertFire";
import AddLogs from "../../../../utils/functions/AddLogs";

// Queries
import {
  GET_SCHEDULED_REPORT,
  GET_VEHICLE_INFOS,
  GET_GROUPS_OPTIONS,
  GET_ACL_MODULES_CLIENT
} from "../../../../graphql/Queries";

// Mutations
import { EDIT_SCHEDULED_REPORT } from "../../../../graphql/Mutations";

// Styles
import useFormStyles from "../styles/useFormStyles";

// Hooks
import useAllowedPermissions from "../../../../utils/hooks/useAllowedPermissions";
import HaulersChipInput from "../../common/HaulerChipInput/HaulerChipInput";
import VehicleStatus, {
  vehicleStatusOptions
} from "../../common/VehicleStatus";
import GPSStatusChipInput, {
  defaultValue as gpsStatusDefault,
  valuesToSting
} from "../../common/GPSStatusChipInput";
import ReportDelaySearchInput, {
  reportDelayOptions
} from "../../common/ReportDelaySearchInput";
import Forbidden from "../../../../utils/Errors/403/Forbidden";
import ReorderList, { parseHeader } from "../../common/ReorderList";
import useUserContext from "../../../../context/User/useUserContext";
import GeofenceChipInput from "../../common/GeofenceChipInput";
import TimeBracket, { defaultTimeBracket } from "../../common/TimeBracket";
import FieldGroup from "../../common/Fields/FieldGroup";
import BatteryLevelInput from "../../common/BatteryLevel";
import SwitchHelper from "../../../../utils/UI/Switch/SwitchHelper";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 30,
    height: 17,
    padding: 0,
    display: "flex",
    marginRight: 20
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(13px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

const useGetScheduledReport = id => {
  const [initialState, setInitialState] = useState({
    reportType: {
      id: -1,
      name: "",
      headers: [[]]
    },
    generateInterval: "",
    every: "",
    time: "",
    recipients: [],
    hauler: [],
    trip_status_id: [],
    device_id: [],
    alert_description_ids: [],
    activity_only: false,
    vehicle_status: [],
    reporting_delay: reportDelayOptions[0],
    gps_status: gpsStatusDefault,
    duration: 0,
    headers: [],
    sftp: {},
    use_logistics_status: false
  });
  const [getInitialHaulers, { loading: loadingHaulers }] = useLazyQuery(
    GET_GROUPS_OPTIONS
  );
  const [getInitialVehicles, { loading: loadingVechiles }] = useLazyQuery(
    GET_VEHICLE_INFOS
  );

  const { loading, data } = useQuery(GET_SCHEDULED_REPORT, {
    fetchPolicy: "cache-and-network",
    variables: { id },
    onCompleted: res => {
      const { get_scheduled_report: scheduled_report } = res;
      const [type, day] = scheduled_report.sked.split(":");

      const haulersPromise = getInitialHaulers({
        variables: {
          or: [
            {
              field: "id",
              value: (scheduled_report.group_ids || []).map(group_id =>
                group_id.toString()
              )
            }
          ]
        }
      });

      const vehiclesPromise = getInitialVehicles({
        variables: {
          first: 15,
          condition: [
            { field: "devices.id", value: scheduled_report?.device_id }
          ]
          // group_ids: group_ids
        }
      });

      Promise.all([haulersPromise, vehiclesPromise]).then(
        ([{ data: response = {} }, { data: vehicleResponse }]) => {
          const initialHaulers = response?.get_groups?.groups || [];
          const initialVehicles = vehicleResponse.get_vehicles.vehicles;
          setInitialState({
            ...scheduled_report,
            reportType: {
              id: scheduled_report.reporttype_id.toString(),
              name: scheduled_report.report_type
            },
            generateInterval: type,
            every: day,
            time: scheduled_report.timesked,
            recipients:
              scheduled_report.email_add === ""
                ? []
                : scheduled_report.email_add.split(","),
            hauler: initialHaulers.map(({ group_id, group_name }) => ({
              group_id: +group_id,
              group_name
            })),
            // trip_status_id: scheduled_report.trip_status_id,
            device_id: initialVehicles,
            // scheduled_report?.device_id?.[0] === "0"
            //   ? [0]
            //   : scheduled_report?.device_id,
            // alert_description_ids: scheduled_report.alert_description_ids,
            activity_only: Boolean(scheduled_report.activity_only),
            vehicle_status: scheduled_report.vehicle_status ?? [],
            // reporting_delay: scheduled_report.reporting_delay,
            gps_status: scheduled_report.gps_status ?? [],
            duration: scheduled_report.duration ?? 0,
            // headers: [...scheduled_report.headers] ?? []
            headers:
              parseHeader(
                scheduled_report?.headers?.length > 0
                  ? scheduled_report?.headers
                  : "[]"
              ) ?? {}
            // sftp: scheduled_report.sftp,
            // geofences: scheduled_report.geofences
          });
        }
      );

      // getInitialHaulers({
      //   variables: {
      //     or: [
      //       {
      //         field: "id",
      //         value: (scheduled_report.group_ids || []).map(group_id =>
      //           group_id.toString()
      //         )
      //       }
      //     ]
      //   }
      // }).then(({ data: response = {} }) => {
      //   const initialHaulers = response?.get_groups?.groups || [];
      //   setInitialState({
      //     reportType: {
      //       id: scheduled_report.reporttype_id.toString(),
      //       name: scheduled_report.report_type
      //     },
      //     generateInterval: type,
      //     every: day,
      //     time: scheduled_report.timesked,
      //     recipients:
      //       scheduled_report.email_add === ""
      //         ? []
      //         : scheduled_report.email_add.split(","),
      //     hauler: initialHaulers.map(({ group_id, group_name }) => ({
      //       group_id: +group_id,
      //       group_name
      //     })),
      //     trip_status_id: scheduled_report.trip_status_id,
      //     device_id:
      //       scheduled_report?.device_id?.[0] === "0"
      //         ? [0]
      //         : scheduled_report?.device_id,
      //     alert_description_ids: scheduled_report.alert_description_ids,
      //     activity_only: Boolean(scheduled_report.activity_only),
      //     vehicle_status: scheduled_report.vehicle_status ?? [],
      //     reporting_delay: scheduled_report.reporting_delay,
      //     gps_status: scheduled_report.gps_status ?? [],
      //     duration: scheduled_report.duration ?? 0,
      //     // headers: [...scheduled_report.headers] ?? []
      //     headers:
      //       parseHeader(
      //         scheduled_report?.headers?.length > 0
      //           ? scheduled_report?.headers
      //           : "[]"
      //       ) ?? {},
      //     sftp: scheduled_report.sftp,
      //     geofences: scheduled_report.geofences
      //   });
      // });
    }
  });

  return {
    loading,
    loadingHaulers,
    scheduledReport: data?.get_scheduled_report,
    initialState
  };
};

const EditGeneratedReport = props => {
  const GPS_STATUS = process.env.REACT_APP_REPORT_TYPE_GPS_STATUS;
  const TRIP_SUMMARY = process.env.REACT_APP_REPORT_TYPE_TRIP_SUMMARY;
  const VEHICLE_ALERT = process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT;
  const FULL_MOVEMENT = process.env.REACT_APP_REPORT_TYPE_FULL_MOVEMENT;
  const ALERT_COUNT_DASHBOARD =
    process.env.REACT_APP_REPORT_TYPE_ALERT_COUNT_DASHBOARD;
  const VEHICLE_STATUS = process.env.REACT_APP_REPORT_TYPE_VEHICLE_STATUS;
  const STANDARD_IDLING = process.env.REACT_APP_REPORT_TYPE_STANDARD_IDLING;
  const VEHICLE_UTILIZATION =
    process.env.REACT_APP_REPORT_TYPE_VEHICLE_UTILIZATION;

  const CICO = process.env.REACT_APP_REPORT_TYPE_CICO;
  const CICO_NO_TRIP = process.env.REACT_APP_REPORT_TYPE_CICO_NO_TRIP;
  const IGNITION = process.env.REACT_APP_REPORT_TYPE_IGNITION;
  const COMPLETE_LOG = process.env.REACT_APP_REPORT_TYPE_COMPLETE_LOG;
  const SPEEDING = process.env.REACT_APP_REPORT_TYPE_SPEEDING;
  const GEOFENCE_IN_AND_OUT =
    process.env.REACT_APP_REPORT_TYPE_GEOFENCE_IN_AND_OUT;
  const FUEL_CONSUMPTION = process.env.REACT_APP_REPORT_TYPE_FUEL_CONSUMPTION;
  const DLTB_IDLING = process.env.REACT_APP_REPORT_DLTB_IDLING;
  const SPEEDING_DURATION = process.env.REACT_APP_REPORT_SPEEDING_DURATION;
  const UNCATEGORIZED = process.env.REACT_APP_REPORT_UNCATEGORIZED;
  const CONVAN_INVENTORY = process.env.REACT_APP_REPORT_CONVAN_INVENTORY;
  const FUEL_REPORT = process.env.REACT_APP_REPORT_FUEL_REPORT;
  const CICO_HOMEBASE = process.env.REACT_APP_REPORT_CICO_HOMEBASE;
  const ERROR_LOGS = process.env.REACT_APP_REPORT_TYPE_ERROR_LOGS;

  // const user = JSON.parse(Cookie.get("user") || "{}");
  const user = useUserContext();
  const isWTIAdmin = +user.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
  const { id: scheduledReportId } = useParams();
  const {
    loading: loadingInitialData,
    initialState,
    loadingHaulers
  } = useGetScheduledReport(scheduledReportId);
  const typesRef = useRef([]);
  const reportTypeRef = useRef({ id: "", name: "" });
  const haulerRef = useRef([]);
  const vehiclesRef = useRef([0]);
  const alertTypesRef = useRef([0]);
  const tripStatusRef = useRef([]);
  const activityOnlyRef = useRef(false);
  const vehicleStatusRef = useRef([]);
  const gpsStatusRef = useRef(gpsStatusDefault);
  const reportDelayRef = useRef(reportDelayOptions[0]);
  const idlingDurationRef = useRef([0]);
  const reorderRef = useRef([]);
  const geofencesRef = useRef([]);
  const timeBracketRef = useRef({
    from: defaultTimeBracket.from,
    to: defaultTimeBracket.to
  });
  const batteryLevelRef = useRef("All");

  const [generateInterval, setGenerateInterval] = useState("daily");
  const [every, setEvery] = useState("default");
  const [time, setTime] = useState("default");
  const [recipients, setRecipients] = useState([]);
  const { history } = props;
  const [editMode, setEditMode] = useState(false);
  const styles = useFormStyles();
  const [reportType, setReportType] = useState({
    id: -1,
    name: "",
    headers: [[]]
  });
  const [selectedHaulers, setSelectedHaulers] = useState(() => {
    if (+user.user_level_id === +process.env.REACT_APP_WTI_ADMIN) return [];
    return user.group_ids;
  });
  const [fieldData, setFieldData] = useState({
    trip_status: [],
    alert_descriptions: []
  });
  const [file, setFile] = useState(null);
  const [sendVia, setSendVia] = useState("email");
  const [tempAddData, setTempAddData] = useState({
    sftp_link: "",
    username: "",
    password: "",
    port: null
  });
  const [uploadFile, setUploadFile] = useState({});
  const [isLogitics, setIsLogitics] = useState({
    isTriggered: false,
    value: false
  });

  useQuery(GET_ACL_MODULES_CLIENT, {
    variables: { id: user?.client?.id },
    onCompleted: res => {
      if (res) {
        const { acl_modules } = res.get_client;
        const hasTripsPrebooking =
          acl_modules.filter(
            acl =>
              [
                "/trips/",
                "/prebooking/",
                "/allocation/",
                "/allocation/trip/",
                "/allocation/vehicle/"
              ].includes(acl.resources) &&
              acl.view &&
              acl.edit
          ).length > 0;
        setIsLogitics(prev => ({ ...prev, value: hasTripsPrebooking }));
      }
    },
    onError: err => console.log(err),
    skip: isWTIAdmin || VEHICLE_STATUS !== reportType.id,
    // fetchPolicy: "cache-and-network"
  });

  const handleIsLogisticsChange = e => {
    setIsLogitics(prev => ({
      ...prev,
      isTriggered: true,
      value: e.target.checked
    }));
  };

  const updateFieldData = e => {
    setFieldData({
      ...fieldData,
      [e.field]: e.data
    });
  };

  const initializeState = () => {
    reportTypeRef.current = initialState.reportType;
    setGenerateInterval(initialState.generateInterval);
    setEvery(initialState.every || "default");
    setTime(initialState.time || "default");
    setRecipients(initialState.recipients);
    setTempAddData(initialState.sftp);
    if (!_.isEmpty(initialState.recipients)) {
      setSendVia("email");
    }

    if (!_.isEmpty(initialState.sftp.sftp_link)) {
      setSendVia("sftp");
    }
    setFile(null);

    setErrors({
      reportType: null,
      generateInterval: null,
      every: null,
      time: null,
      recipients: null,
      vehicle: null,
      sftp_link: null,
      username: null,
      password: null,
      sftp_key: null,
      port: null
    });

    setIsLogitics(prev => ({
      ...prev,
      isTriggered: false,
      value: initialState.use_logistics_status
    }));
  };

  const initializeReportType = () => {
    const index = typesRef.current.findIndex(
      type => type.id === initialState.reportType.id
    );
    setReportType({
      ...typesRef.current[index],
      // headers: [...(typesRef.current[index]?.headers || [])]
      headers: typesRef.current[index]?.headers
        ? parseHeader(typesRef.current[index]?.headers)
        : {}
    });
  };

  useEffect(() => {
    // if (initialState.reportType.name) {
    // setReportType(initialState.reportType.id.toString());
    initializeReportType();
    const module = process.env.REACT_APP_AUTOMATED_REPORTS_MODULE;
    if (initialState.reportType.name) {
      AddLogs(
        module,
        "view",
        `Report ID: ${scheduledReportId} ${initialState.reportType.name}`
      );
    }
    // }
  }, [initialState /* typesRef.current */]);

  useEffect(() => {
    initializeState();
  }, [initialState]);

  useEffect(() => {
    if (!editMode) {
      initializeState();
      initializeReportType();
    }
  }, [editMode]);

  const toggleEditMode = useCallback(() => {
    setEditMode(prevMode => !prevMode);
  }, [setEditMode]);

  const readOnly = !editMode;

  const [editScheduleReport] = useMutation(EDIT_SCHEDULED_REPORT);

  const [errors, setErrors] = useState({
    reportType: null,
    generateInterval: null,
    every: null,
    time: null,
    recipients: null,
    vehicle: null,
    sftp_link: null,
    username: null,
    password: null,
    sftp_key: null,
    port: null,
    timeBracket: null
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleToggleDialog = () => {
    setDialogOpen(prev => !prev);
  };

  const { data: allVehicles } = useQuery(GET_VEHICLE_INFOS, {
    variables: {
      first: 10000,
      not: [{ field: "devices.id", value: "0" }],
      group_ids: selectedHaulers
    }
  });

  const getMoreChanges = () => {
    const { trip_status, alert_descriptions } = fieldData;
    const {
      hauler,
      trip_status_id,
      device_id,
      alert_description_ids,
      activity_only,
      vehicle_status,
      gps_status,
      reporting_delay,
      duration,
      geofences,
      startstamp,
      endstamp,
      battery_level
    } = initialState;

    // Check Trip Status Change
    let tripStatusChange = "";
    if (
      !_.isEqual(
        trip_status_id,
        tripStatusRef.current.map(status => Number(status.id))
      )
    ) {
      const initTripStatus =
        Number(trip_status_id[0]) === 0 ||
          trip_status_id.length === trip_status.length
          ? "All"
          : trip_status_id
            .map(i => {
              return trip_status.find(e => Number(e.id) === Number(i)).name;
            })
            .join();
      const newTripStatus =
        tripStatusRef.current.length === trip_status.length
          ? "All"
          : tripStatusRef.current.map(status => status.name).toString();
      tripStatusChange = `, Trip Status change from ${initTripStatus} to ${newTripStatus}`;
    }

    // Check Hauler Change
    let haulerChange = "";
    if (!_.isEqual(hauler, haulerRef.current)) {
      const initHauler = hauler.length
        ? hauler.map(i => i.group_name).join()
        : "All";
      const newHauler = haulerRef.current.length
        ? haulerRef.current.map(h => h.group_name).join()
        : "All";
      haulerChange = `, Hauler change from ${initHauler} to ${newHauler}`;
    }

    // Check Vehicle Change
    let vehicleChange = "";
    if (
      !_.isEqual(
        device_id,
        vehiclesRef.current.map(i => i.device_id)
      ) &&
      allVehicles
    ) {
      const initVehicle =
        Number(device_id[0]) === 0
          ? "All"
          : device_id
            .map(i => {
              return allVehicles.get_vehicles.vehicles.find(
                e => Number(e.device_id) === Number(i)
              )?.plateno;
            })
            .join(", ");
      const newVehicle =
        vehiclesRef.current[0] === 0
          ? "All"
          : vehiclesRef.current.map(i => i.plateno).join();
      vehicleChange = `, Plate Number change from ${initVehicle} to ${newVehicle}`;
    }

    let geofenceChange = "";
    const geofencePrevIds = geofences.map(g => g.id);
    const geofenceCurrentIds = geofencesRef.current.map(g => g.id);
    if (!_.isEqual(geofencePrevIds, geofenceCurrentIds)) {
      const prev =
        geofences.length === 0 ? "All" : geofences.map(g => g.name).join(", ");
      const current =
        geofencesRef.current.length === 0
          ? "All"
          : geofencesRef.current.map(g => g.name).join(", ");

      geofenceChange = `, Geofences changed from ${prev} to ${current}`;
    }

    // Check Alerts Change
    let alertChange = "";
    if (
      !_.isEqual(
        alert_description_ids.map(Number),
        alertTypesRef.current[0] === 0
          ? alertTypesRef.current
          : alertTypesRef.current.map(i => Number(i.id))
      )
    ) {
      const initAlert =
        Number(alert_description_ids[0]) === 0
          ? "All"
          : alert_description_ids
            .map(i => {
              return alert_descriptions.find(e => Number(e.id) === Number(i))
                .code;
            })
            .join();
      const newAlert =
        alertTypesRef.current[0] === 0
          ? "All"
          : alertTypesRef.current.map(i => i.code).join();
      alertChange = `, Alert Type change from ${initAlert} to ${newAlert}`;
    }

    // Check Activity Only Change
    let actOnlyChange = "";
    if (!_.isEqual(Boolean(activity_only), activityOnlyRef.current)) {
      actOnlyChange = `, Alert Type change from ${Boolean(activity_only)} to ${activityOnlyRef.current
        }`;
    }

    // Vehicle Status Changes
    let vehicleStatusChanges = "";
    if (
      !_.isEqual(
        vehicle_status,
        vehicleStatusRef.current.map(i => +i.id)
      )
    ) {
      const old = vehicle_status
        .map(item => {
          const [result] = vehicleStatusOptions.filter(
            option => +option.id === item
          );

          return result.name;
        })
        .join(",");

      const tempNewValues = vehicleStatusRef.current.map(item => item.name);
      const newValuesText =
        tempNewValues.length === vehicleStatusOptions.length
          ? "All"
          : tempNewValues.join(",");
      const newValues = vehicleStatusRef.current.length ? newValuesText : "All";
      vehicleStatusChanges = `, Vehicle Status changed from ${old} to ${newValues}`;
    }

    let gpsStatusChanges = "";
    if (!_.isEqual(gps_status, gpsStatusRef.current)) {
      const old = valuesToSting(gps_status);
      const newValues = valuesToSting(gpsStatusRef.current);
      gpsStatusChanges = `, GPS Status changed from ${old} to ${newValues}`;
    }

    let reportingDelayChanges = ``;
    if (reporting_delay !== reportDelayRef.current) {
      reportingDelayChanges = `, Reporting Delay changed from ${reporting_delay} to ${reportDelayRef.current}`;
    }

    let durationChanges = ``;
    if (duration !== idlingDurationRef.current) {
      durationChanges = `, Idling Duration changed from ${reverseDuration(duration).label
        } to ${idlingDurationRef.current}`;
    }

    const batteryLevelChanges = ``;
    if (battery_level !== batteryLevelRef.current) {
      durationChanges = `, Idling Duration changed from ${battery_level} to ${batteryLevelRef.current}`;
    }

    let timeBracketChanges = ``;
    if (
      startstamp !== timeBracketRef.current.from ||
      endstamp !== timeBracketRef.current.to
    ) {
      timeBracketChanges = `, Time bracket changed from '${startstamp} - ${endstamp}' to '${timeBracketRef.current.from} - ${timeBracketRef.current.to}'`;
    }

    switch (reportType.id) {
      case GPS_STATUS:
        return `${haulerChange}`;
      case TRIP_SUMMARY:
        return `${haulerChange}${tripStatusChange}${gpsStatusChanges}`;
      case VEHICLE_ALERT:
        return `${timeBracketChanges}${haulerChange}${alertChange}${vehicleChange}`;
      case FULL_MOVEMENT:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}${actOnlyChange}`;
      case ALERT_COUNT_DASHBOARD:
        return `${timeBracketChanges}${haulerChange}${alertChange}${vehicleChange}`;
      case VEHICLE_STATUS:
        return `${haulerChange}${vehicleChange}${vehicleStatusChanges}${gpsStatusChanges}${reportingDelayChanges}`;
      case STANDARD_IDLING:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}${durationChanges}`;
      case VEHICLE_UTILIZATION:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case CICO:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case CICO_NO_TRIP:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}${geofenceChange}`;
      case IGNITION:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case COMPLETE_LOG:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case SPEEDING:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case SPEEDING_DURATION:
        return `${haulerChange}${vehicleChange}`;
      case GEOFENCE_IN_AND_OUT:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case FUEL_CONSUMPTION:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case DLTB_IDLING:
        return `${timeBracketChanges}${haulerChange}${vehicleChange}`;
      case CONVAN_INVENTORY:
        // return `${haulerChange}${vehicleChange}${batteryLevelChanges}`;
        return `${haulerChange}${batteryLevelChanges}`;
      case UNCATEGORIZED:
        return `${haulerChange}${vehicleChange}`;
      case FUEL_REPORT:
        return `${haulerChange}${vehicleChange}`;
      case CICO_HOMEBASE:
        return `${haulerChange}${vehicleChange}`;
      case ERROR_LOGS:
        return `${timeBracketChanges}`;
      default:
        return "";
    }
  };

  const getChanges = () => {
    const reportTypeChanges =
      parseInt(initialState.reportType.id, 10) !==
        parseInt(reportTypeRef.current.id, 10)
        ? `, Report Type changed from ${initialState.reportType.name} to ${reportTypeRef.current.name}`
        : "";
    const generateIntervalChanges =
      initialState.generateInterval !== generateInterval
        ? `, Interval changed from ${initialState.generateInterval} to ${generateInterval}`
        : "";

    let everyChanges = "";
    if (initialState.every !== every && generateInterval !== "daily") {
      if (generateInterval === "weekly") {
        everyChanges = `, Every changed to ${days[Number(every) - 1]}`;
      } else {
        everyChanges = `, Every changed to ${dates[Number(every) - 1]}`;
      }
    }

    const timeChanges =
      initialState.time !== time
        ? `, Time changed from ${initialState.time} to ${time}`
        : "";
    const recipientsChanges = !_.isEqual(initialState.recipients, recipients)
      ? `, Recipients changed from ${initialState.recipients.toString()} to ${recipients.toString()}`
      : "";

    return {
      reportTypeChanges,
      generateIntervalChanges,
      everyChanges,
      timeChanges,
      recipientsChanges,
      moreChanges: getMoreChanges()
    };
  };

  const handleChange = event => {
    const validatedErrors = errors;
    setSendVia(prev => {
      if (prev === "email") {
        validatedErrors.sftp_link = null;
      } else {
        validatedErrors.recipients = null;
      }
      return event.target.value;
    });
  };

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // Characters that are not included in this list (a-zA-Z0-9) are not allowed.
    if (/[^0-9]/g.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleAddValues = e => {
    const { name, value } = e.target;
    const validatedErrors = errors;
    switch (name) {
      case "link":
        if (!_.isEmpty(value)) validatedErrors.sftp_link = null;
        break;
    }

    setTempAddData({
      ...tempAddData,
      [name]: value
    });
  };

  const handleFile = event => {
    const validatedErrors = errors;
    const {
      target: {
        files: [file]
      }
    } = event;
    if (file?.size / 1024 / 1024 > 1) {
      validatedErrors.sftp_key = `The file: ${file.name} is too large. It should be below 1 MB`;
    } else {
      validatedErrors.sftp_key = null;
    }

    switch (file?.type?.split("/")[0]) {
      case "image":
        validatedErrors.sftp_key = "Invalid file format";
        break;
    }
    setFile(file);
  };

  const handleSave = () => {
    const sked = `${generateInterval}${generateInterval !== "daily" ? `:${every}` : ""
      }`;

    const status_ids = tripStatusRef.current.map(status =>
      parseInt(status.id, 10)
    );

    const default_sftp = {
      sftp_link: "",
      username: "",
      password: "",
      port: 0
    };

    editScheduleReport({
      variables: {
        id: scheduledReportId,
        scheduled_report: {
          reporttype_id: parseInt(reportTypeRef.current.id, 10),
          sked,
          timesked: time,
          ...(sendVia === "email"
            ? {
              email_add: recipients.toString(),
              sftp: default_sftp
            }
            : {
              sftp: {
                sftp_link: tempAddData.sftp_link,
                username: tempAddData.username,
                password: tempAddData.password,
                key: file,
                // ...(_.isEmpty(file?.name) && {key: file})
                port: parseInt(tempAddData.port, 10)
              },
              email_add: ""
            }),
          via_email: sendVia === "email" ? 1 : 0,
          via_sftp: sendVia === "sftp" ? 1 : 0,
          status: +process.env.REACT_APP_REPORT_STATUS_PENDING,
          // group_ids: [
          //   GPS_STATUS,
          //   TRIP_SUMMARY,
          //   VEHICLE_ALERT,
          //   FULL_MOVEMENT,
          //   ALERT_COUNT_DASHBOARD,
          //   VEHICLE_STATUS,
          //   STANDARD_IDLING,
          //   VEHICLE_UTILIZATION,
          //   CICO,
          //   CICO_NO_TRIP,
          //   IGNITION,
          //   COMPLETE_LOG,
          //   SPEEDING,
          //   GEOFENCE_IN_AND_OUT,
          //   FUEL_CONSUMPTION,
          //   DLTB_IDLING,
          //   SPEEDING_DURATION
          // ].includes(reportType.id)
          //   ? haulerRef.current.map(hauler => Number(hauler.group_id))
          //   : user.group_ids[0],
          group_ids:
            haulerRef.current?.map(hauler => Number(hauler.group_id))
            || user.group_ids[0],
          trip_status_id: status_ids.length ? status_ids : [0],
          device_id:
            vehiclesRef.current[0] === 0
              ? [0]
              : vehiclesRef.current.map(i => i.device_id),
          alert_description_ids:
            alertTypesRef.current[0] === 0
              ? [0]
              : alertTypesRef.current.map(i => i.id),
          activity_only: activityOnlyRef.current ? 1 : 0,
          vehicle_status: vehicleStatusRef.current.map(({ id }) => +id),
          gps_status: gpsStatusRef.current,
          reporting_delay: reportDelayRef.current,
          duration: idlingDurationParser(idlingDurationRef.current).val,
          headers: JSON.stringify(reorderRef.current),
          geofence_ids: geofencesRef.current.map(geofence => geofence.id),
          startstamp: timeBracketRef.current.from,
          endstamp: timeBracketRef.current.to,
          battery_level: batteryLevelRef.current,
          use_logistics_status: isLogitics.value
        }
      }
    })
      .then(e => {
        const {
          error,
          scheduled_report: { id: reportId }
        } = e.data.edit_scheduled_report;
        const validatedErrors = errors;
        if (error.length > 0) {
          error.map(row => {
            if (row.field === "sftp_key") {
              validatedErrors[row.field] = `This file ${file.name} is empty`;
            } else {
              validatedErrors[row.field] = row.message;
            }
          });
          setErrors(prevErrors => ({ ...prevErrors, ...validatedErrors }));
          return;
        }
        const module = process.env.REACT_APP_AUTOMATED_REPORTS_MODULE;
        const {
          reportTypeChanges,
          generateIntervalChanges,
          everyChanges,
          timeChanges,
          recipientsChanges,
          moreChanges
        } = getChanges();
        alertFire(() => {
          AddLogs(
            module,
            "update_scheduled_report",
            `Report ID: ${reportId}, Updated Scheduled Report${reportTypeChanges}${generateIntervalChanges}${everyChanges}${timeChanges}${recipientsChanges}${moreChanges}`
          );
          history.push({
            pathname: "/reports/automated"
          });
        });
      })
      .catch(() => {
        alertFire(() => { }, "Error", false);
      });
  };

  const validateForm = () => {
    const validatedErrors = errors;

    if (reportTypeRef.current && reportTypeRef.current.id !== undefined) {
      validatedErrors.reportType = null;
    } else {
      validatedErrors.reportType = "Report type is required.";
    }

    if (generateInterval === "weekly" || generateInterval === "monthly") {
      if (every === "default") {
        validatedErrors.every = `${generateInterval === "weekly" ? "Day" : "Date"
          } is required.`;
      } else {
        validatedErrors.every = null;
      }
    } else {
      validatedErrors.every = null;
    }

    if (time === "default") {
      validatedErrors.time = "Time is required.";
    } else {
      validatedErrors.time = null;
    }

    if (sendVia === "email") {
      if (recipients.length === 0) {
        validatedErrors.recipients = "Recipient/s is required.";
      } else {
        validatedErrors.recipients = null;
      }
    } else {
      if (
        !_.isEmpty(tempAddData.sftp_link) ||
        !_.isEmpty(initialState.sftp.sftp_link)
      ) {
        validatedErrors.sftp_link = null;
      } else {
        validatedErrors.sftp_link = "Link is required.";
      }

      if (file?.size / 1024 / 1024 > 1) {
        validatedErrors.sftp_key = `The file: ${file.name} is too large. It should be below 1 MB`;
      } else {
        validatedErrors.sftp_key = null;
      }

      switch (file?.type?.split("/")[0]) {
        case "image":
          validatedErrors.sftp_key = "Invalid file format";
          break;
      }
    }

    if (
      [FULL_MOVEMENT].includes(reportType.id) &&
      (vehiclesRef.current.length === 0 || vehiclesRef.current[0] === 0)
    ) {
      validatedErrors.vehicle = "Plate number is required.";
    } else {
      validatedErrors.vehicle = null;
    }

    setErrors(prevErrors => ({ ...prevErrors, ...validatedErrors }));
  };

  const [alertTypes, setAlertTypes] = useState([]);
  const onSelectedAlertTypeChanged = newItems => {
    if ([ALERT_COUNT_DASHBOARD].includes(reportType.id)) {
      setAlertTypes([...newItems]);
    }
  };

  const { loading, permissions } = useAllowedPermissions("/reports/automated/");

  if (loading || loadingInitialData) {
    return <React.Fragment />;
  }

  // if (!permissions?.view) {
  //   return <Typography>403!!!!</Typography>;
  // }

  return (
    <React.Fragment>
      <div className={styles.root}>
        {!permissions?.view ? (
          <Forbidden />
        ) : (
          <>
            <Header>
              <div>
                <Typography variant="h6">
                  <IconButton
                    onClick={() => {
                      history.push({
                        pathname: "/reports/automated"
                      });
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <span className={styles.formTitle}>
                    {editMode
                      ? `Edit Automated Reports`
                      : `View Automated Reports`}
                  </span>
                </Typography>
              </div>
              <div>
                <FormControlLabel
                  disabled={!permissions.edit}
                  control={
                    <AntSwitch
                      name="editModeToggle"
                      checked={editMode}
                      onChange={toggleEditMode}
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography
                      style={{
                        fontSize: 12,
                        marginRight: 20,
                        fontWeight: "bold",
                        color: "#454545",
                        letterSpacing: "1px"
                      }}
                    >
                      Edit Mode
                    </Typography>
                  }
                />
              </div>
            </Header>
            <div className={styles.main}>
              {/* <Form readOnly={!editMode} /> */}
              <div className={styles.container}>
                <div className={styles.division}>
                  <DivisionTitle label="Details" />
                  <div className="inputGroups">
                    <ReportTypeInput
                      ref={reportTypeRef}
                      initialReportType={initialState.reportType}
                      readOnly={readOnly}
                      onLoad={response => {
                        typesRef.current = response.get_report_types;
                        initializeReportType();
                      }}
                      onChange={e => {
                        if (e) {
                          setReportType({
                            ...e,
                            headers: parseHeader(e.headers)
                          });
                          if (isLogitics.value) 
                            setIsLogitics(prev => ({
                              ...prev,
                              isTriggered: true,
                              value: false
                            }));
                        }
                      }}
                    />
                    {errors.reportType && (
                      <ErrorMessage message={`${errors.reportType}`} />
                    )}
                  </div>
                  <div className="inputGroups">
                    <FormControl
                      required={!readOnly}
                      classes={{ root: styles.formRoot }}
                    >
                      <InputLabel id="generateInterval-label">
                        Generate Interval
                      </InputLabel>
                      <Select
                        disableUnderline={readOnly}
                        disabled={readOnly}
                        classes={{ root: styles.selectRoot }}
                        labelId="generateInterval"
                        id="generateInterval-required"
                        defaultValue={initialState.generateInterval}
                        value={generateInterval}
                        onChange={event => {
                          setGenerateInterval(event.target.value);
                          setEvery("default");
                        }}
                      >
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {[
                    DLTB_IDLING,
                    CICO,
                    FUEL_CONSUMPTION,
                    FULL_MOVEMENT,
                    SPEEDING,
                    VEHICLE_ALERT,
                    ALERT_COUNT_DASHBOARD,
                    COMPLETE_LOG,
                    ERROR_LOGS,
                    GEOFENCE_IN_AND_OUT,
                    IGNITION,
                    STANDARD_IDLING,
                    CICO_NO_TRIP,
                    VEHICLE_UTILIZATION
                  ].includes(reportType.id) && (
                      <div className="inputGroups">
                        <TimeBracket
                          ref={timeBracketRef}
                          from={initialState.startstamp}
                          to={initialState.endstamp}
                          readOnly={readOnly}
                          error={errors.timeBracket}
                          onError={message =>
                            setErrors(prev => ({ ...prev, timeBracket: message }))
                          }
                        />
                      </div>
                    )}
                  <div className="inputGroups">
                    <FormControl
                      required={!readOnly}
                      classes={{ root: styles.formRoot }}
                    >
                      <InputLabel id="every-label">Every</InputLabel>
                      <Collapse
                        component="div"
                        in={generateInterval !== "daily"}
                        style={{ width: "100%", marginTop: "12px" }}
                      >
                        <Select
                          disableUnderline={readOnly}
                          disabled={readOnly}
                          style={{
                            color:
                              every !== "default" && !readOnly
                                ? "#000"
                                : "#b1b1b1"
                          }}
                          classes={{ root: styles.selectRoot }}
                          labelId="every-id"
                          id="every-required"
                          value={every}
                          onChange={event => setEvery(event.target.value)}
                        >
                          <MenuItem value="default" disabled>
                            {generateInterval === "monthly"
                              ? "Select Date"
                              : "Select Day"}
                          </MenuItem>
                          {generateInterval === "monthly"
                            ? dates.map((date, index) => (
                              <MenuItem
                                key={`date-${index}`}
                                classes={{ root: styles.menuItem }}
                                value={`${date.substring(
                                  0,
                                  date.length - 2
                                )}`}
                              >
                                {date}
                              </MenuItem>
                            ))
                            : days.map((day, dayIndex) => (
                              <MenuItem
                                key={`day-${dayIndex}`}
                                value={dayIndex + 1}
                                classes={{ root: styles.menuItem }}
                              >
                                {day}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.every && (
                          <ErrorMessage message={`${errors.every}`} />
                        )}
                      </Collapse>
                    </FormControl>
                    <FormControl required classes={{ root: styles.formRoot }}>
                      <Select
                        disableUnderline={readOnly}
                        disabled={readOnly}
                        style={{
                          color:
                            time !== "default" && !readOnly
                              ? "#000"
                              : "#b1b1b1",
                          marginTop:
                            generateInterval !== "daily" ? "12px" : "auto"
                        }}
                        classes={{ root: styles.selectRoot }}
                        labelId="every-id"
                        id="every-required"
                        value={time}
                        onChange={event => setTime(event.target.value)}
                      >
                        <MenuItem value="default" disabled>
                          Select Time
                        </MenuItem>
                        {times.map(itemTime => (
                          <MenuItem
                            value={itemTime.value}
                            key={`time-${itemTime.value}`}
                          >
                            {itemTime.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.time && (
                        <ErrorMessage message={`${errors.time}`} />
                      )}
                    </FormControl>
                  </div>
                  <div className="inputGroups">
                    <FormControl classes={{ root: styles.formRoot }}>
                      <FormLabel style={{ fontSize: "12px" }}>
                        Send Via
                      </FormLabel>
                      <RadioGroup
                        name="sendVia"
                        value={sendVia}
                        onChange={event => {
                          if (!readOnly) handleChange(event);
                        }}
                        row
                      >
                        <FormControlLabel
                          value="email"
                          control={<Radio color="primary" size="small" />}
                          label={
                            <span style={{ fontSize: "12px" }}>Email</span>
                          }
                        />
                        <FormControlLabel
                          value="sftp"
                          control={<Radio color="primary" size="small" />}
                          label={<span style={{ fontSize: "12px" }}>SFTP</span>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {sendVia === "email" && (
                    <div className="inputGroups">
                      <FormControl classes={{ root: styles.formRoot }}>
                        <ChipInput
                          disableUnderline={readOnly}
                          disabled={readOnly}
                          classes={{ root: styles.textFieldRoot }}
                          style={{ padding: "6px 0px" }}
                          placeholder="Enter Recipient/s"
                          required={!readOnly}
                          chipRenderer={(
                            { text, handleDelete, className },
                            key
                          ) => {
                            return (
                              <Chip
                                disabled={readOnly}
                                key={key}
                                size="small"
                                color="primary"
                                className={className}
                                label={text}
                                onDelete={handleDelete}
                              />
                            );
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label={
                            <span id="recipient">
                              {" "}
                              <Tooltip
                                classes={{ tooltip: styles.toolTip }}
                                title="If multiple recipients, add comma (,) after each email address"
                                placement="top-start"
                              >
                                <HelpRounded className={styles.helpIcon} />
                              </Tooltip>
                              Recipient
                            </span>
                          }
                          // newChipKeyCodes={[32]}
                          newChipKeys={[",", "Enter", " "]}
                          value={recipients}
                          onAdd={chip => {
                            if (validate("email", chip.toString())) {
                              const newRecipients = [...recipients, chip];
                              setRecipients(newRecipients);
                            }
                          }}
                          onDelete={chip => {
                            setRecipients(prevChips =>
                              prevChips.filter(chp => chp !== chip)
                            );
                          }}
                        />
                        {errors.recipients && (
                          <ErrorMessage message={`${errors.recipients}`} />
                        )}
                      </FormControl>
                    </div>
                  )}
                  {sendVia === "sftp" && (
                    <>
                      <div className="inputGroups">
                        <FormControl classes={{ root: styles.formRoot }}>
                          <TextField
                            classes={{ root: styles.textFieldRoot }}
                            fullWidth
                            required
                            // error={!tempAddData.link && error}
                            id="sftp_link"
                            placeholder="Enter link"
                            InputLabelProps={{
                              shrink: true
                            }}
                            disabled={readOnly}
                            // defaultValue={initialState?.sftp?.sftp_link}
                            label="SFTP Link"
                            value={tempAddData?.sftp_link || ""}
                            name="sftp_link"
                            onChange={e => {
                              handleAddValues(e);
                            }}
                            onFocus={e => {
                              handleAddValues(e);
                            }}
                          />
                          {errors.sftp_link && (
                            <ErrorMessage message={`${errors.sftp_link}`} />
                          )}
                        </FormControl>
                      </div>
                      <div className="inputGroups">
                        <FormControl classes={{ root: styles.formRoot }}>
                          <TextField
                            classes={{ root: styles.textFieldRoot }}
                            fullWidth
                            // error={!tempAddData.link && error}
                            id="username"
                            placeholder="Enter username"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Username"
                            disabled={readOnly}
                            value={tempAddData?.username || ""}
                            name="username"
                            onChange={e => {
                              handleAddValues(e);
                            }}
                          />
                          {errors.username && (
                            <ErrorMessage message={`${errors.username}`} />
                          )}
                        </FormControl>
                      </div>
                      <div className="inputGroups">
                        <FormControl classes={{ root: styles.formRoot }}>
                          <TextField
                            classes={{ root: styles.textFieldRoot }}
                            fullWidth
                            // error={!tempAddData.link && error}
                            id="link"
                            placeholder="Enter password"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Password"
                            disabled={readOnly}
                            value={tempAddData?.password || ""}
                            name="password"
                            onChange={e => {
                              handleAddValues(e);
                            }}
                          />
                          {errors.password && (
                            <ErrorMessage message={`${errors.password}`} />
                          )}
                        </FormControl>
                      </div>
                      <div className="inputGroups">
                        <FormControl classes={{ root: styles.formRoot }}>
                          <TextField
                            classes={{ root: styles.textFieldRoot }}
                            fullWidth
                            // error={!tempAddData.link && error}
                            id="key"
                            placeholder="Select File"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Key"
                            disabled={readOnly}
                            value={file?.name || initialState?.sftp?.key}
                            name="sftp_key"
                            InputProps={{
                              readOnly: true,
                              placeholder: "Select File",
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ color: "gray" }}
                                >
                                  <Attachment />
                                </InputAdornment>
                              )
                            }}
                            onClick={() => !readOnly && uploadFile.click()}
                          />
                          {errors.sftp_key && (
                            <ErrorMessage message={`${errors.sftp_key}`} />
                          )}
                          <input
                            type="file"
                            ref={fileUpload => setUploadFile(fileUpload)}
                            style={{ visibility: "hidden", display: "block" }}
                            onChange={e => handleFile(e)}
                          // accept=".jpg,.jpeg,.png"
                          />
                        </FormControl>
                      </div>
                      <div className="inputGroups">
                        <FormControl classes={{ root: styles.formRoot }}>
                          <TextField
                            classes={{ root: styles.textFieldRoot }}
                            fullWidth
                            // error={!tempAddData.link && error}
                            id="port"
                            placeholder="Enter port"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Port"
                            disabled={readOnly}
                            value={tempAddData?.port || ""}
                            name="port"
                            onChange={e => {
                              handleAddValues(e);
                            }}
                            onKeyPress={handleKeyPress}
                          />
                          {errors.port && (
                            <ErrorMessage message={`${errors.port}`} />
                          )}
                        </FormControl>
                      </div>
                    </>
                  )}
                  <div className="inputGroups">
                    <FieldGroup reportType={reportType.id}>
                      <FieldGroup.Field>
                        <HaulersChipInput
                          ref={haulerRef}
                          loading={loadingHaulers}
                          readOnly={readOnly}
                          initialValues={initialState.hauler}
                          setSelectedHaulers={setSelectedHaulers}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <AlertTypeChipInput
                          ref={alertTypesRef}
                          readOnly={readOnly}
                          initialValues={initialState.alert_description_ids}
                          values={alertTypes}
                          updateFieldData={updateFieldData}
                          onSelectedAlertTypeChanged={
                            onSelectedAlertTypeChanged
                          }
                          params={{ haulers: selectedHaulers }}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <VehicleChipInput
                          ref={vehiclesRef}
                          readOnly={readOnly}
                          initialValues={initialState.device_id}
                          group_ids={selectedHaulers}
                          updateFieldData={updateFieldData}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <VehicleSearchInput
                          ref={vehiclesRef}
                          readOnly={readOnly}
                          initialValues={initialState.device_id}
                          group_ids={selectedHaulers}
                          vehicleError={errors.vehicle}
                          updateFieldData={updateFieldData}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <GeofenceChipInput
                          ref={geofencesRef}
                          readOnly={readOnly}
                          initialValues={initialState.geofences}
                          groups={selectedHaulers}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <>
                          {(VEHICLE_STATUS === reportType.id && isWTIAdmin) && (
                            <SwitchHelper
                              handleChangeHandler={handleIsLogisticsChange}
                              checkedValue={isLogitics.value}
                              label="Use Logistics Status"
                              name="is_logistics"
                              tooltipPlacement="top-start"
                              tooltipTitle={
                                <>
                                  Toggle on: report that will be generated will
                                  display logistics (Booked/Available) status{" "}
                                  <br />
                                  <br />
                                  Toggle off: report that will be based on
                                  non-logistics (Active) status
                                </>
                              }
                              icon={<HelpIcon className={styles.helpIcon} />}
                              disabled={readOnly}
                              customStyle={{ padding: 0, margin: "0 0 24px 0" }}
                            />
                          )}
                          <VehicleStatus
                            ref={vehicleStatusRef}
                            readOnly={readOnly}
                            initialValues={initialState.vehicle_status}
                            isLogitics={isLogitics}
                          />
                        </>
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <GPSStatusChipInput
                          ref={gpsStatusRef}
                          readOnly={readOnly}
                          initialValues={initialState.gps_status}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <ReportDelaySearchInput
                          ref={reportDelayRef}
                          readOnly={readOnly}
                          initialValues={initialState.reporting_delay}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <TripStatusChipInput
                          ref={tripStatusRef}
                          readOnly={readOnly}
                          initialValues={initialState.trip_status_id.map(
                            String
                          )}
                          updateFieldData={updateFieldData}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <ActivityOnlyInput
                          ref={activityOnlyRef}
                          readOnly={readOnly}
                          initialValues={initialState.activity_only}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <IdlingDuration
                          ref={idlingDurationRef}
                          readOnly={readOnly}
                          initialValues={
                            reverseDuration(initialState.duration).label
                          }
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <BatteryLevelInput
                          initialValue={initialState.battery_level}
                          ref={batteryLevelRef}
                          readOnly={readOnly}
                        />
                      </FieldGroup.Field>
                      <FieldGroup.Field>
                        <ReorderList
                          readOnly={!editMode}
                          ref={reorderRef}
                          initialValues={reportType.headers}
                          defaultHeaders={initialState.headers}
                          nonpermanentItems={alertTypes}
                          onSelectedAlertTypeChanged={
                            onSelectedAlertTypeChanged
                          }
                        />
                      </FieldGroup.Field>
                    </FieldGroup>
                  </div>
                </div>
                {readOnly ? (
                  <div className={styles.division}>
                    <Table />
                  </div>
                ) : null}
                {!readOnly ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.saveButton}
                    onClick={() => {
                      validateForm();
                      const errorCount = Object.values(errors).filter(
                        err => err !== null
                      ).length;

                      if (errorCount === 0) {
                        handleToggleDialog();
                      }
                    }}
                  >
                    Save Details
                  </Button>
                ) : null}
              </div>
            </div>

            <ConfirmationDialog
              toggle={dialogOpen}
              close={() => handleToggleDialog()}
              fn={e => handleSave(e)}
              title="Save"
              content="Are you sure you want to save this data?"
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditGeneratedReport);
