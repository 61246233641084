import React, { useState, useEffect, Fragment, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Tooltip,
  Chip,
  useTheme
} from "@material-ui/core";
import { ArrowBack, Attachment, Error } from "@material-ui/icons";
import Swal from "sweetalert2";
import Cookie from "js-cookie";
import _ from "lodash";
import querystring from "query-string";
import moment from "moment";
import DetailsTable from "./DetailsTable";
import VehicleAssignDialog from "../utils/modal/VehicleAssign";
import DispatchDialog from "../utils/modal/DispatchDialog";
import ListTripDetails from "../utils/paper/ListTripDetails";
import { GET_BOOKINGS } from "../../../api/bookingRepository";
import {
  MANUAL_COMPLETE_WITH_UPLOAD,
  MANUAL_COMPLETE,
  ADD_NOTIFICATION
} from "../../../graphql/Mutations";
import { BOOKINGS_SUBSCRIPTION } from "../../../graphql/Subscription";
import AccessControl from "../../../utils/AccessControl";
import Loading from "../../../utils/Loading";
import { DISPATCH_PERMISSIONS } from "../../../graphql/Queries";
import CloseDialog from "../utils/modal/CloseDialog";
import AddLogs from "../../../utils/functions/AddLogs";
import useUserContext from "../../../context/User/useUserContext";
import Toolbar from "./Toolbar";
import { getLocations } from "../../Utils";

const TripDetails = props => {
  const { location, history } = props;
  const [trip_number, set_trip_number] = useState("");
  const [manualCompleteMode, setManualCompleteMode] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [tripData, setTripData] = useState(null);
  const [group_ids, setGroup_ids] = useState(null);
  const [token, setToken] = useState("");
  const [parsing, setParsing] = useState(true);
  const [rawTripData, setRawTripData] = useState({});
  const [closeDialog, setCloseDialog] = useState(false);
  const user =
    Cookie.get("user") === undefined ? {} : JSON.parse(Cookie.get("user"));

  const [tripForChangeTruck, setTripForChangeTruck] = useState();

  // const [permissions, setPermissions] = useState({
  //   change_truck: { view: false, edit: false },
  //   dispatch: { view: false, edit: false },
  //   manual_complete: { view: false, edit: false },
  //   map_view: { view: true, edit: true },
  //   reorder: { view: false, edit: false },
  //   split: { view: false, edit: false },
  //   close: { view: false, edit: false }
  // });
  // const { data: permission_data } = useQuery(DISPATCH_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id
  //   },
  //   skip: _.isEmpty(user),
  //   fetchPolicy: "network-only"
  // });

  const user_context = useUserContext();
  const default_permissions = {
    change_truck: { view: false, edit: false },
    dispatch: { view: false, edit: false },
    manual_complete: { view: false, edit: false },
    map_view: { view: true, edit: true },
    reorder: { view: false, edit: false },
    split: { view: false, edit: false },
    close: { view: false, edit: false }
  };
  const permissions = React.useMemo(() => {
    if (!Object.keys(user_context.allowed_permissions).length) {
      return default_permissions;
    }

    const { allowed_permissions } = user_context;
    return {
      change_truck: allowed_permissions["/trips/change_truck/"],
      dispatch: allowed_permissions["/trips/dispatch/"],
      manual_complete: allowed_permissions["/trips/manual_complete/"],
      map_view: allowed_permissions["/trips/map_view/"],
      reorder: allowed_permissions["/trips/reorder/"],
      split: allowed_permissions["/trips/split/"],
      close: allowed_permissions["/trips/close/"]
    };
  }, [user_context.allowed_permissions]);

  // useEffect(() => {
  //   if (permission_data) {
  //     setPermissions(permission_data);
  //   }
  // }, [permission_data]);
  useEffect(() => {
    if (!_.isEmpty(location.state)) {
      const {
        rawTripData: propRawTripData,
        isEdit,
        mapView: mapViewProps,
        tripData: tripDataProps
      } = location.state;

      setManualCompleteMode(isEdit);
      set_trip_number(propRawTripData.key);
      setMapView(mapViewProps);
      setTripData(tripDataProps);
      setParsing(false);
      setRawTripData(propRawTripData);
    } else {
      const locationParams = querystring.parse(location.search);
      const {
        trip_number: tripNumberParams,
        token: tokenProps,
        group_ids: groupProps
      } = locationParams;

      set_trip_number(tripNumberParams);
      setToken(tokenProps);
      setGroup_ids(groupProps);
      setParsing(false);
    }
  }, []);

  const [vehicleModal, toggleVehicleModal] = useState(false);
  const [dispatchModal, toggleDispatchModal] = useState(false);
  const [editPermission, setEditPermission] = useState(false); // eslint-disable-line
  const [confirmOpen, setConfirmOpen] = useState(false);
  const start = "1970-01-01 00:00:00";
  const end = "2100-01-01 23:59:59";
  const [uploadFiles, setUploadFiles] = useState([]);
  const uploaderEl = useRef(null);
  const [parsedTripData, setParsedTripData] = useState({});
  const [open, setOpen] = useState({
    sharelink: false,
    attachments: false,
    historySelector: false
  });
  const [stops, setStops] = useState([]);
  const [originalCount, setOriginalCount] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [mode, setMode] = useState("list");
  const [completeMutation, { loading }] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Manual completion success"
      });
      setTimeout(() => {
        history.push({
          pathname: `/trips`,
          state: {
            fromDetails: true
          }
        });
      }, 3000);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!"
      });
    }
  });

  const handleOpen = dialogName => {
    setOpen({ ...open, [dialogName]: true });
  };

  const handleClose = dialogName => {
    setOpen({ ...open, [dialogName]: false });
  };

  useEffect(() => {
    if (parsedTripData) {
      const { pickups, dropoffs } = parsedTripData;
      const newStops = _.union(pickups, dropoffs);
      setStops(newStops);
    }
  }, [parsedTripData]);

  const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [reOrderMutation, { loading: reorderLoading }] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      addNotif({
        variables: {
          notification: {
            client_id: [parsedTripData.client_id],
            trip_number: parsedTripData.trip_number,
            vehicle_plate_no: parsedTripData.vehicle_plate_no || "",
            datestamp: moment()
              .format("YYYY-MM-DD HH:mm:ss"),
            notification: `Reordered the trip ${parsedTripData.trip_number}`,
            category: "User Activity",
            is_archived: false,
            creator: "Tmsuite-web",
            group_ids: parsedTripData.group_ids,
            group_names: parsedTripData.group_names
          }
        }
      });

      AddLogs(
        process.env.REACT_APP_DISPATCH_MODULE,
        "reorder",
        parsedTripData.trip_number
      );

      Swal.fire({
        icon: "success",
        // title: "Success",
        text: "Reorder Complete"
      });
      setTimeout(() => {
        // window.location.reload();
        refetch();
      }, 1500);
    }
  });

  const getLoc = async (vehicle_id, geofence_id) => {
    const result = await getLocations(vehicle_id, geofence_id);
    const { get_device, get_geofence } = result;
    const origins = [
      `${get_device.device_status.latitude},${get_device.device_status.longitude}`
    ];
    const destinations = [
      `${get_geofence.location.lat},${get_geofence.location.lon}`
    ];

    return {
      origins,
      destinations,
      geofence_id: get_geofence?.id
    };
  };

  const saveNewOrder = async newStops => {
    // const newDropoffs = _.filter(newStops, ["is_pickup", false]);
    const newDropoffs = newStops.filter(stop => !stop.is_pickup);
    const { dropoffs, trip_status, out_of_route } = parsedTripData;
    const first_d = _.filter(dropoffs, d => {
      return (
        d.status_code_id !== 4 &&
        d.status_code_id !== 15 &&
        d.status_code_id !== 24 &&
        d.status_code_id !== 28
      );
    });
    const first_nd = _.filter(newDropoffs, d => {
      return (
        d.status_code_id !== 4 &&
        d.status_code_id !== 15 &&
        d.status_code_id !== 24 &&
        d.status_code_id !== 28
      );
    });

    const newIsOutOfRouteSequence = newDropoffs.map(d => {
      if (
        [
          +process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF, //24
          +process.env.REACT_APP_STATUS_COMPLETED //4
        ].includes(+d.status_code_id)
      ) {
        return 1;
      }
        return 0;
    });

    /** Check if new sequence has no skipped dropoff
     *  ideal sequence = [1,1,0,0]
     *  out of route = [1,0,1,0]
     *  Note: 1=Completed, 0=Not yet completed
     */
    const idealSequence = [...newIsOutOfRouteSequence].sort().reverse();
    const new_out_of_route = !_.isEqual(newIsOutOfRouteSequence, idealSequence);

    const outOfRoute = out_of_route && !new_out_of_route ? false : out_of_route;

    const updatedTrips = [];

    const oldSequence = dropoffs.map(d => d.so_number);
    const newSequence = newDropoffs.map(d => d.so_number);

    if (_.isEqual(oldSequence, newSequence)) {
      setMode("list");
      return;
    } else {
      const location = await getLoc(
        parsedTripData.vehicle_id,
        first_nd[0].geofence_id
      );
      if (
        trip_status.toLowerCase() === "in transit" &&
        first_d[0].so_number !== first_nd[0].so_number &&
        location?.geofence_id !== "0"
      ) {
        const service = new window.google.maps.DistanceMatrixService();
        // const location = await getLoc(
        //   parsedTripData.vehicle_id,
        //   first_nd[0].geofence_id
        // );
        service.getDistanceMatrix(
          {
            origins: location.origins,
            destinations: location.destinations,
            travelMode: "DRIVING"
          },
          (res, status) => {
            if (status === "OK") {
              const nIndex = _.findIndex(newDropoffs, [
                "so_number",
                first_nd[0].so_number
              ]);
              const oIndex = _.findIndex(newDropoffs, [
                "so_number",
                first_d[0].so_number
              ]);
              newDropoffs[oIndex] = {
                ...newDropoffs[oIndex],
                eta: null,
                eta_modified: null,
                // status_code_id: 2,
                // status_code: "Scheduled",
                actual_eta: null
              };
              newDropoffs[nIndex] = {
                ...newDropoffs[nIndex],
                eta: res.rows[0].elements[0].duration?.value?.toString() || null,
                actual_eta: res.rows[0].elements[0].duration?.value?.toString() || null,
                eta_modified: moment().format("YYYY-MM-DD HH:mm:ss"),
                // status_code_id: 20,
                // status_code: "In Transit"
              };
              _.forEach(newDropoffs, (dropoff, index) => {
                const d = { ...dropoff };
                const { trip_id } = dropoff;
                _.unset(d, "trip_id");
                _.unset(d, "items");
                _.unset(d, "is_pickup");
                _.unset(d, "so_numbers");
                updatedTrips.push({
                  id: trip_id,

                  fields: {
                    dropoffs: d,
                    // status_code_id:
                    //   d.status_code_id === 27 ? 4 : d.status_code_id,
                    // status_code:
                    //   d.status_code_id === 27 ? "Completed" : d.status_code,
                    ordinal: index + 1,
                    out_of_route: outOfRoute
                  }
                });
              });
              //
              reOrderMutation({
                variables: {
                  bookings: updatedTrips
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                // title: "Success",
                text: "Something went wrong"
              });
            }
          }
        );
      } else {
        _.forEach(newDropoffs, (dropoff, index) => {
          const d = { ...dropoff };
          const { trip_id } = dropoff;
          _.unset(d, "trip_id");
          _.unset(d, "items");
          _.unset(d, "is_pickup");
          _.unset(d, "so_numbers");
          updatedTrips.push({
            id: trip_id,
            fields: {
              dropoffs: d,
              ordinal: index + 1,
              out_of_route: outOfRoute
            }
          });
        });
        reOrderMutation({
          variables: {
            bookings: updatedTrips
          }
        });
      }
    }

    setMode("list");
    setParsedTripData({ ...parsedTripData, dropoffs: newDropoffs });
  };

  const getStatus = tripArray => {
    if (tripArray.length === 0) return "-";

    let uniqTrip = _.uniq(tripArray.map(t => t.status_code));
    if (
      uniqTrip.length > 1 &&
      tripArray.some(t => ["Completed", "Closed"].includes(t.status_code))
    ) {
      const newArray = tripArray.filter(t => !["Completed", "Closed"].includes(t.status_code));
      return getStatus(newArray);
    } else {
      return ["accepted", "scheduled"].includes(uniqTrip[0])
        ? "Processing"
        : uniqTrip[0]
    }
  }

  const parseBookings = async ({ get_bookings_with_ongoing_per_trip }) => {
    const { bookings: trips } = get_bookings_with_ongoing_per_trip;
    // const response = {
    //   rawTripData: trips
    // };
    let parsedTrip = {};
    let orig_count = 0;
    _.forEach(trips, trip => {
      const status_ids = [];
      const pickups = [];
      const dropoffs = [];
      const so_numbers = [];
      const raw_pickups = [];
      const raw_dropoffs = [];
      _.forEach(trip.value, so => {
        _.forEach(so.pickups, pickup => {
          const exist = _.findIndex(pickups, {
            geofence_id: pickup.geofence_id,
            arrival: pickup.arrival
          });
          raw_pickups.push(pickup);
          if (exist === -1) {
            const pu = {
              ...pickup,
              is_pickup: true,
              so_numbers: [...so_numbers, pickup.so_number]
            };
            pickups.push(pu);
          } else {
            pickups[exist].so_numbers.push(pickup.so_number);
            pickups[exist].is_pickup = true;
          }
        });
        _.forEach(so.dropoffs, (dropoff, index) => {
          // const items = so.items
          //   ? so.items.filter(item => item.dropoff_index === index)
          //   : [];
          // const drop = {
          //   ...dropoff,
          //   items: items,
          //   trip_id: so.id,
          //   is_pickup: false
          // };
          // dropoffs.push(drop);
          const exist = _.findIndex(dropoffs, {
            geofence_id: dropoff.geofence_id,
            arrival: dropoff.arrival
          });
          raw_dropoffs.push({ ...dropoff, items: so.items, trip_id: so.id });
          if (exist === -1) {
            const drop = {
              ...dropoff,
              so_numbers: [...so_numbers, dropoff.so_number],
              trip_id: so.id,
            };
            dropoffs.push(drop);
          } else {
            dropoffs[exist].so_numbers.push(dropoff.so_number);
            dropoffs[exist].trip_id = so.id;
          }
        });
        status_ids.push(so.status_code_id);
        parsedTrip = {
          ...parsedTrip,
          client_id: so.client_id,
          client_name: so.client_name,
          hauler: so.client_name,
          vehicle_status: so.vehicle_status,
          vehicle_history: so.vehicle_history,
          vehicle_type: so.vehicle_type,
          vehicle_plate_no: so.vehicle_plate_no,
          service_type: so.service_type,
          vehicle_id: so.vehicle_id,
          driver: so.personnels && so.personnels[0].name,
          // trip_status:
          //   so.status_code.toLowerCase() === "accepted" ||
          //   so.status_code.toLowerCase() === "scheduled"
          //     ? "Processing"
          //     : so.status_code,
          has_split:
            so.status_code.toLowerCase() === "accepted" ||
            so.status_code.toLowerCase() === "scheduled" ||
            so.status_code.toLowerCase() === "arrived at pickup",
          raw_trip_status: so.status_code,
          out_of_route: so.out_of_route,
          dispatch_type: so.dispatch_type,
          status_code_id: so.status_code_id,
          dispatch_time: so.dispatch_time,
          group_ids: so.group_ids,
          group_names: so.group_names
        };
      });
      parsedTrip = {
        ...parsedTrip,
        trip_number: trip.key,
        raw_dropoffs: raw_dropoffs,
        pickups: pickups,
        dropoffs: dropoffs,
        raw_pickups: raw_pickups,
        has_empty_so: _.filter(trip.value, t => !t.so_number).length > 0,
        trip_status: getStatus(trip.value)
      };
      orig_count =
        _.filter(dropoffs, d => {
          return (
            (d.manual_arrival === null && d.actual_arrival === null) ||
            (d.manual_departure === null && d.actual_departure === null)
          );
        }).length +
        _.filter(pickups, p => {
          return (
            (p.manual_arrival === null && p.actual_arrival === null) ||
            (p.manual_departure === null && p.actual_departure === null)
          );
        }).length;
    });

    return { parsedTrip, orig_count }
  }

  const parseData = async bookings => {
    const { parsedTrip, orig_count } = await parseBookings(bookings);

    setRawTripData(bookings.get_bookings_with_ongoing_per_trip.bookings[0]);
    setParsedTripData(parsedTrip);
    setOriginalCount(orig_count);
  };

  // const { history: refresher } = useHistory();

  const getGroupIds = () => {
    return group_ids
      ? group_ids.split(",").map(group_id => parseInt(group_id, 10))
      : [];
  };

  const [get_booking_for_reassign] = useLazyQuery(GET_BOOKINGS, {
    onCompleted: async res => {
      const { parsedTrip } = await parseBookings(res);
      setTripForChangeTruck({
        dateRange: {
          start:
            !_.isEmpty(parsedTrip) &&
            parsedTrip.pickups[0]?.arrival,
          end:
            !_.isEmpty(parsedTrip) &&
            parsedTrip.dropoffs[parsedTrip.dropoffs.length - 1]
              ?.arrival
        },
        parsedTripData: parsedTrip,
        rawTripData: res.get_bookings_with_ongoing_per_trip.bookings[0]
      })
    }
  });

  const {
    data: booking_data,
    refetch,
    subscribeToMore,
    loading: bookingsLoading
  } = useQuery(GET_BOOKINGS, {
    variables: {
      skip: 0,
      // rows_per_page: tripData ? tripData.total : 1000,
      rows_per_page: 100,
      date_range: {
        field: "pickups.arrival",
        start: start,
        end: end
      },
      conditions: [
        {
          field: "trip_number.keyword",
          value: trip_number
        }
      ],
      group_ids: getGroupIds()
    },
    // onCompleted: data => {
    //   // console.log(data);
    //   parseData(data);
    // },
    skip: !trip_number,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const subscribeToNewNotifications = () => {
    const unsubscribe = subscribeToMore({
      document: BOOKINGS_SUBSCRIPTION,
      variables: {
        filter: [
          {
            field: "trip_number",
            value: trip_number
          }
        ]
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const { booking_per_trip_updated } = subscriptionData.data;
        const { get_bookings_with_ongoing_per_trip } = prev;
        const { bookings: newData } = booking_per_trip_updated;
        const { bookings: oldData } = get_bookings_with_ongoing_per_trip;

        const updateStop = newData[0].value[0];
        const oldStops = oldData[0].value;

        const index = _.findIndex(oldStops, [
          "so_number",
          updateStop.so_number
        ]);

        const oldDataCopy = _.cloneDeep([...oldData]);
        oldDataCopy[0].value[index] = updateStop;

        const newTrip = {
          ...prev,
          get_bookings_with_ongoing_per_trip: {
            ...prev.get_bookings_with_ongoing_per_trip,
            bookings: oldDataCopy
          }
        };
        // console.log(newTrip);
        parseData(newTrip);
        // refetch();
        return newTrip;
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = subscribeToNewNotifications();
    return () => unsubscribe();
  });

  useEffect(() => {
    if (booking_data) {
      parseData(booking_data);
    }
  }, [booking_data, refetch]);

  function openVehicleModal() {
    toggleVehicleModal(true);
  }

  function closeVehicleModal() {
    toggleVehicleModal(false);
  }

  function openDispatchModal() {
    toggleDispatchModal(true);
  }

  function closeDispatchModal() {
    toggleDispatchModal(false);
  }

  const handleUpload = e => {
    const { files } = e.target;
    const uploads = [];
    _.forEach(files, file => {
      uploads.push(file);
    });
    setUploadFiles(uploads);
  };

  const manualComplete = async () => {
    const {
      trip_number: tripNumber,
      // vehicle_id,
      raw_pickups,
      raw_dropoffs,
      dropoffs,
      pickups
    } = parsedTripData;
    const updatedTrips = [];

    AddLogs(
      process.env.REACT_APP_DISPATCH_MODULE,
      "manual_complete_trip",
      trip_number
    );
    // const { status_code_id } = tripStatus(dropoffs);
    // if (status_code_id === 20) {
    //   const incompleteStop = _.findIndex(dropoffs, d => {
    //     return d.manual_arrival === null && d.actual_arrival === null;
    //   });
    //   const nextStop = dropoffs[incompleteStop];
    //   const { get_device, get_geofence } = await getLocations(
    //     vehicle_id,
    //     nextStop.geofence_id
    //   );
    //   const origins = [
    //     `${get_device.device_status.latitude},${get_device.device_status.longitude}`
    //   ];
    //   const destinations = [
    //     `${get_geofence.location.lat},${get_geofence.location.lon}`
    //   ];
    //   const service = new window.google.maps.DistanceMatrixService();
    //   const eta = await getDistance(service, origins, destinations);
    //   dropoffs[incompleteStop] = {
    //     ...dropoffs[incompleteStop],
    //     eta: eta.rows[0].elements[0].duration.value.toString(),
    //     actual_eta: eta.rows[0].elements[0].duration.value.toString(),
    //     status_code_id: 20,
    //     status_code: "In Transit",
    //     eta_modified: moment().format("YYYY-MM-DD HH:mm:ss")
    //   };
    // }
    const manual_arrivals = [...pickups, ...dropoffs].filter(
      stop => stop.manual_arrival === null
    );
    _.forEach(raw_dropoffs, dropoff => {
      const d = {
        ...dropoff
      };
      const { trip_id } = dropoff;
      _.unset(d, "trip_id");
      _.unset(d, "items");
      _.unset(d, "is_pickup");

      let data = {
        id: trip_id,
        fields: {
          status_code_id: manual_arrivals.length > 0 ? 29 : 4,
          status_code: manual_arrivals.length > 0 ? "Closed" : "Completed",
          dropoffs: [
            {
              ...d
            }
          ]
        }
      };
      const rpus = _.filter(raw_pickups, ["so_number", d.so_number]);
      _.forEach(rpus, (rpu, rpu_i) => {
        if (rpu.manual_arrival !== null) {
          rpus[rpu_i] = {
            ...rpus[rpu_i]
          };
        }
      });
      if (rpus.length > 0) {
        data = {
          ...data,
          fields: {
            ...data.fields,
            pickups: rpus
          }
        };
      }
      updatedTrips.push(data);
    });
    if (uploadFiles.length) {
      if (uploadFiles.length > 10) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Up to 10 attachments is allowed."
        });
      } else {
        completeMutation({
          mutation: MANUAL_COMPLETE_WITH_UPLOAD,
          variables: {
            bookings: updatedTrips,
            file: uploadFiles,
            trip_number: tripNumber
          }
        });
      }
    } else {
      completeMutation({
        mutation: MANUAL_COMPLETE,
        variables: {
          bookings: updatedTrips
        }
      });
    }
  };

  const handleFileDelete = name => {
    const uploads = [...uploadFiles];
    _.remove(uploads, el => {
      return el.name === name;
    });

    setUploadFiles(uploads);
  };

  if (parsing) return <Loading />;

  return (
    <AccessControl
      resource="/trips/"
      process="dispatch"
      setEditPermission={setEditPermission}
      shareable={token}
    >
      <div className="TripDetails">
        {(loading || bookingsLoading || reorderLoading) && <Loading />}
        <Dialog
          open={confirmOpen}
          onClose={() => {
            setConfirmOpen(false);
          }}
          PaperProps={{ style: { width: 600 } }}
        >
          <DialogTitle>Save?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure want to save the changes made?
            </DialogContentText>
            <Grid container>
              <p style={{ fontSize: 14, color: "gray" }}>
                Attach Document
                <Tooltip title="Limited for 10 attachments only">
                  <Error style={{ fontSize: 10 }} />
                </Tooltip>
              </p>
              <Grid container onClick={() => uploaderEl.current.click()}>
                <div
                  style={{
                    borderBottom: "1px solid gray",
                    width: "100%",
                    cursor: "pointer"
                  }}
                >
                  {uploadFiles.length ? (
                    uploadFiles.map(f => (
                      <Chip
                        size="small"
                        label={f.name}
                        onDelete={() => handleFileDelete(f.name)}
                      />
                    ))
                  ) : (
                    <Typography>
                      No document chosen{" "}
                      <span style={{ float: "right", color: "gray" }}>
                        <Attachment />
                      </span>
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              inputProps={{
                type: "file",
                multiple: true,
                accepts: ".xlsx,.pdf,.jpg,.jpeg",
                ref: uploaderEl
              }}
              style={{ display: "none" }}
              onChange={handleUpload}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmOpen(false);
              }}
              variant="outlined"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => {
                // mutateStops();
                manualComplete();
                setConfirmOpen(false);
              }}
              variant="contained"
              color="primary"
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
        <Toolbar
          isEdit={isEdit}
          setEdit={setEdit}
          manualCompleteMode={manualCompleteMode}
          parsedTripData={parsedTripData}
          originalCount={originalCount}
          history={history}
          manualComplete={manualComplete}
          uploadFiles={uploadFiles}
          handleFileDelete={handleFileDelete}
          handleUpload={handleUpload}
          permissions={permissions}
          setCloseDialog={setCloseDialog}
          setMode={setMode}
          rawTripData={rawTripData}
          openVehicleModal={openVehicleModal}
          setTripForChangeTruck={setTripForChangeTruck}
          mode={mode}
          handleOpen={handleOpen}
          saveNewOrder={() => saveNewOrder(stops)}
        />
        <div style={{ display: "flex" }}>
          <ListTripDetails parsedTripData={parsedTripData} />
          <DetailsTable
            rawTripData={rawTripData}
            parsedTripData={parsedTripData}
            setParsedTripData={setParsedTripData}
            isEdit={isEdit}
            manualCompleteMode={manualCompleteMode}
            mapView={mapView}
            mode={mode}
            setMode={setMode}
            getGroupIds={getGroupIds}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            stops={stops}
            setStops={setStops}
          />
        </div>
        <Grid container spacing={3}>
          <VehicleAssignDialog
            moduleName={process.env.REACT_APP_DISPATCH_MODULE}
            toggle={vehicleModal}
            closeVehicleModal={closeVehicleModal}
            openVehicleModal={openVehicleModal}
            setTrip={tripNumber => {
              get_booking_for_reassign({
                variables: {
                  trip_numbers: [tripNumber]
                }
              });
            }}
            // trip_number={trip_number}
            fn={() => {
              refetch();
            }}
            tripData={tripForChangeTruck}
            // tripData={{ parsedTripData }}
            // tripData={{
            //   dateRange: {
            //     start:
            //       !_.isEmpty(parsedTripData) &&
            //       parsedTripData.pickups[0]?.arrival,
            //     end:
            //       !_.isEmpty(parsedTripData) &&
            //       parsedTripData.dropoffs[parsedTripData.dropoffs.length - 1]
            //         ?.arrival
            //   },
            //   parsedTripData: parsedTripData,
            //   rawTripData: rawTripData
            // }}
            queryFilter={{
              skip: 0,
              rows_per_page: tripData ? tripData.total : 1,
              date_range: {
                field: "pickups.arrival",
                start: start,
                end: end
              },
              conditions: [
                {
                  field: "trip_number.keyword",
                  value: trip_number
                }
              ],
              group_ids: getGroupIds()
            }}
          />
          {!_.isEmpty(user) && (
            <DispatchDialog
              moduleName={process.env.REACT_APP_DISPATCH_MODULE}
              toggle={dispatchModal}
              closeDispatchModal={closeDispatchModal}
              tripData={{ rawTripData }}
              queryFilter={{
                skip: 0,
                rows_per_page: tripData ? tripData.total : 1,
                date_range: {
                  field: "pickups.arrival",
                  start: start,
                  end: end
                },
                conditions: [
                  {
                    field: "trip_number.keyword",
                    value: trip_number
                  }
                ],
                group_ids: getGroupIds()
              }}
              // fn={parseBookingData}
              // fn={() => window.location.reload()}
              // fn={refetch}
              fn={() => {}}
            />
          )}
          {!_.isEmpty(user) && (
            <CloseDialog
              moduleName={process.env.REACT_APP_DISPATCH_MODULE}
              queryFilter={{
                skip: 0,
                rows_per_page: tripData ? tripData.total : 1,
                date_range: {
                  field: "pickups.arrival",
                  start: start,
                  end: end
                },
                conditions: [
                  {
                    field: "trip_number.keyword",
                    value: trip_number
                  }
                ],
                group_ids: getGroupIds()
              }}
              open={closeDialog}
              toggle={setCloseDialog}
              tripData={{ rawTripData }}
            />
          )}
        </Grid>
      </div>
    </AccessControl>
  );
};

export default TripDetails;
