import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  dashboard: {
    padding: 20,
    border: "1px solid #D1D1D1",
    background: "#FFFFFF",
    minHeight: "60vh",
  },
  dashboard_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    marginBottom: "0.5rem"
  },
  icon: {
  },
  process_icon: {
    fontSize: "1rem",
    // color: "#757575"
  },
  table_container: {
    border: "1px solid rgba(204, 204, 204, 0.5)",
    padding: 0,
    borderRadius: 10,
    maxHeight: "52vh",
  },
  box_content: {
    width: "100%"
  },
  process_container: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  },
  link: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  link_name: {
    margin: "0 0.6rem",
    fontSize: 12,
    letterSpacing: 0.3
  },
  table_header_name: {
    fontWeight: "bold"
  },
  text_field: {
    "&::placeholder": {
      fontSize: "0.8rem !important"
    }
  },
  text_field_label: {
    fontWeight: "bold"
  },
  custom_alert_box: {
    position: "absolute",
    right: "4%",
    bottom: "3%",
  }
}));
