import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Grid,
  Tooltip,
  Divider
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Focus from "../../../common/Focus";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#3ba4f7"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16,
    height: "85%"
  },
  input: {
    padding: 0
  }
});

const OTTA = () => {
  const classes = useStyles();

  return (
    <Focus
      step={3}
      className={classes.chartRoot}
      infoBubbleProps={{
        title: "Dashboard Table",
        description: "Displays and monitors your delivery's status"
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title} color="primary">
            ON-TIME TRUCK AVAILABILITY
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Focus
            step={4}
            component={Grid}
            container
            spacing={1}
            infoBubbleProps={{
              title: "Location Filter",
              description:
                "Filter the particular dashboard list based on its location"
            }}
          >
            <Grid item xs={3}>
              <Typography>Pickup: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                limitTags={2}
                getLimitTagsText={more => `+${more}`}
                disableCloseOnSelect
                options={[]}
                getOptionLabel={option => option.label.toString()}
                renderInput={params => (
                  <TextField {...params} placeholder="All" />
                )}
                disableClearable
              />
            </Grid>
          </Focus>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} style={{ paddingTop: 10 }}>
        <Grid item xs={6} style={{ borderRight: "1px solid #D6D6D6" }}>
          <div style={{ paddingTop: 25 }} align="center">
            INCOMING
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Tooltip title="Awaiting Tracking Info">
                  <Typography
                    align="center"
                    noWrap
                    style={{ marginLeft: 8, marginRight: 8 }}
                  >
                    Awaiting Tracking Info
                  </Typography>
                </Tooltip>
                <Typography variant="h4" align="center">
                  6
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography variant="h4" align="center">
                  8
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On Track</Typography>
                <Typography variant="h4" align="center">
                  3
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Late</Typography>
                <Typography variant="h4" align="center">
                  14
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      1
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      6
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">On-Time</Typography>
                <Typography variant="h4" align="center">
                  7
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">P</Typography>
                    <Typography variant="h4" align="center">
                      1
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.dataSubContainer}>
                    <Typography align="center">T</Typography>
                    <Typography variant="h4" align="center">
                      1
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Dispatched</Typography>
                <Typography variant="h4" align="center">
                  19
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Focus>
  );
};

export default withRouter(OTTA);
