import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton
} from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import TimeSheetCard from "./TimeSheetCard";
import { GET_DRIVER_ATTENDANCES } from "../../../../../graphql/Queries";
import {
  EDIT_DRIVER_ATTENDANCE,
  EDIT_DRIVERS_ATTENDANCES
} from "../../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../../utils/modal/ConfirmationDialog";
import Swal from "sweetalert2";
import Loading from "../../../../../utils/Loading";
import AddLogs from "../../../../../utils/functions/AddLogs";
import AccessControl from "../../../../../utils/AccessControl";

export default function ForApproval() {
  const classes = useStyles();
  const history = useHistory();
  const responseMode =
    history?.location?.state?.params?.response_status_code_id;
  const [approvalList, setApprovalList] = useState([]);
  const [timesheetResults, setTimesheetsResults] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [editPermission, setEditPermission] = useState(false);

  const get_ids = () => {
    let id_string = window.location.href.split("/").pop();
    let id_array = id_string.split(",");
    return id_array;
  };

  const [approve_timesheets, { loading }] = useMutation(
    EDIT_DRIVERS_ATTENDANCES
  );
  const { data } = useQuery(GET_DRIVER_ATTENDANCES, {
    variables: {
      condition: {
        field: "driver_attendance.id",
        value: get_ids()
      }
    },
    fetchPolicy: "network-only"
  });
  const [timesheetsList, setTimesheetsList] = useState([]);

  useEffect(() => {
    const valid_data = data?.get_driver_attendances?.attendances.filter(
      timesheet => timesheet.status_code_id === Number(responseMode)
    );
    setTimesheetsList(valid_data);
  }, [data]);

  useEffect(() => {
    if (isSelectAll) {
      setApprovalList(timesheetsList);
    } else setApprovalList([]);
  }, [isSelectAll]);

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectAll(false);
    } else setSelectAll(true);
  };

  // Modal handling
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // REDIRECT TO TABLE WHEN ALL TIMESHEETS ARE DONE
  useEffect(() => {
    if (timesheetsList.length === 0 && timesheetResults.length > 0) {
      history.push({
        pathname: "/admin",
        state: {
          params: {
            moduleName: process.env.REACT_APP_WORKING_HOURS
          }
        }
      });
    }
  }, [timesheetsList]);

  useEffect(() => {
    const updatedCards = timesheetsList.filter(
      card => !timesheetResults.includes(card)
    );
    setTimesheetsList(updatedCards);
    const updatedList = approvalList.filter(
      card => !timesheetResults.includes(card)
    );
    setApprovalList(updatedList);
  }, [timesheetResults]);

  // Mutation for approving timesheets
  const editTimesheets = () => {
    setDecision("");
    const newSheets = approvalList.map((sheet, index) => {
      return {
        id: sheet.id,
        attendance: {
          status_code_id:
            decision === "APPROVE"
              ? Number(process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED)
              : decision === "DISAPPROVE"
              ? Number(process.env.REACT_APP_DRIVER_STATUS_CODE_DISAPPROVED)
              : Number(process.env.REACT_APP_DRIVER_STATUS_CODE_FOR_APPROVAL)
        }
      };
    });

    approve_timesheets({
      variables: {
        attendances: newSheets
      }
    })
      .then(response => {
        const error_list = response.data.edit_driver_attendances.map(res => {
          if (!res.success) {
            Swal.fire({
              title: "Error",
              icon: "error",
              showConfirmButton: false,
              timer: 2500
            });
            return res.id;
          }
        });
        const logs_array = approvalList.filter(
          timesheet => !error_list.includes(timesheet.id)
        );
        logs_array.forEach(card => {
          if (
            responseMode !==
            process.env.REACT_APP_DRIVER_STATUS_CODE_FOR_APPROVAL
          ) {
            AddLogs("Admin - Working Hours", "change", [
              card.personnel_name,
              card.time_in,
              card.status_code
            ]);
          } else {
            AddLogs("Admin - Working Hours", decision.toLowerCase(), [
              card.personnel_name,
              card.time_in
            ]);
          }
        });
        setTimesheetsResults(
          approvalList.filter(timesheet => !error_list.includes(timesheet.id))
        );
      })
      .catch(error => {
        Swal.fire({
          title: "Error",
          icon: "error",
          showConfirmButton: false,
          timer: 2500
        });
      });
  };

  const handleDecision = decision_value => {
    setDecision(decision_value);
    handleOpen();
  };

  return (
    <AccessControl
    process="tables"
    setCanDelete={setEditPermission}
    resource="/admin/driver_management/working_hours/"
    // resource={history.location.pathname}
    >
      <>
        <AppBar
          position="static"
          elevation={0}
          variant="outlined"
          color="default"
        >
          <Toolbar style={{ backgroundColor: "#FFD799" }}>
            <IconButton onClick={() => setRedirect(true)}>
              <ArrowBackIcon style={{ margin: "0" }} />
            </IconButton>
            <Typography variant="h6" className={classes.text}>
              {responseMode ===
              process.env.REACT_APP_DRIVER_STATUS_CODE_FOR_APPROVAL
                ? "For Approval Timesheets"
                : responseMode ===
                  process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED
                ? "Approved Timesheets"
                : "Disapproved Timesheets"}
            </Typography>
            <div style={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>

        {/* SUB HEADER AREA with Approve / Disapprove and Select All */}
        <div className={classes.subheader}>
          <div className={classes.subcontainers}>
            <Typography variant="h6" className={classes.typography}>
              <p>Select and review these timesheets &nbsp;</p>
              <p style={{ color: "#F49400" }}>{`(${approvalList.length})`}</p>
            </Typography>
            {timesheetsList[0]?.status_code_id !==
              +process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE && editPermission && (
              <div style={{ marginBottom: "10px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.disapprove}
                  onClick={() => handleDecision("DISAPPROVE")}
                  disabled={
                    approvalList.length < 1 ||
                    responseMode ===
                      process.env.REACT_APP_DRIVER_STATUS_CODE_DISAPPROVED
                  }
                >
                  <ThumbDownIcon fontSize="small" />
                  Disapprove
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.approve}
                  onClick={() => handleDecision("APPROVE")}
                  disabled={
                    approvalList.length < 1 ||
                    responseMode ===
                      process.env.REACT_APP_DRIVER_STATUS_CODE_APPROVED
                  }
                >
                  <ThumbUpIcon fontSize="small" />
                  Approve
                </Button>

                {timesheetsList[0]?.status_code_id !==
                  +process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE && editPermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.forapproval}
                    onClick={() => handleDecision("FOR APPROVAL")}
                    disabled={
                      approvalList.length < 1 ||
                      responseMode ===
                        process.env.REACT_APP_DRIVER_STATUS_CODE_FOR_APPROVAL
                    }
                  >
                    For Approval
                  </Button>
                )}
              </div>
            )}
          </div>
          <div>
            <Button
              disabled={!editPermission ||
                timesheetsList[0]?.status_code_id ===
                +process.env.REACT_APP_DRIVER_STATUS_CODE_INCOMPLETE
              }
              onClick={handleSelectAll}
              variant="contained"
              color="secondary"
              className={isSelectAll ? classes.unselectAll : classes.selectAll}
            >
              {" "}
              Select All{" "}
            </Button>
          </div>
        </div>

        {/* Modal for approve/disapprove */}
        {open && (
          <ConfirmationDialog
            toggle={handleOpen}
            title={
              decision === "APPROVE"
                ? "Approve these Timesheet(s)"
                : decision === "DISAPPROVE"
                ? "Disapprove these Timesheet(s)"
                : "Change these Timesheets"
            }
            content={
              decision === "FOR APPROVAL"
                ? "You are about to change the status of Approved/Disapproved timesheets. Status will change to FOR APPROVAL. Are you sure you want to proceed?"
                : `Are you sure you want to ${decision.toLowerCase()} these timesheets?`
            }
            fn={editTimesheets}
            close={handleClose}
            type=""
          />
        )}

        {/* modal for loading */}
        {loading && <Loading />}

        <div className={classes.cardSection}>
          {/* CARD CONTENT AREA - SCROLLABLE */}
          {timesheetsList.map(timesheet => (
            <TimeSheetCard
              key={timesheet.id}
              timesheet={timesheet}
              setApprovalList={setApprovalList}
              approvalList={approvalList}
              isSelectAll={isSelectAll}
              editPermission={editPermission}
            />
          ))}
        </div>
        {redirect && (
          <Redirect
            push
            to={{
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_WORKING_HOURS
                }
              }
            }}
          />
        )}
      </>
    </AccessControl>
  );
}

// useStyles styling
const useStyles = makeStyles(() => ({
  subheader: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "24px",
    marginTop: "12px"
  },
  subcontainers: {
    display: "flex",
    flexDirection: "row",
    width: "91%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  typography: {
    fontSize: "18px",
    letterSpacing: 1.2,
    fontWeight: 600,
    color: "#3c3c3c",
    display: "flex"
  },
  disapprove: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "#F49400",
    margin: "0 10px 5px 10px",
    padding: "20 5px",
    width: "170px",
    border: "2px solid",
    borderColor: "#F49400",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "space-evenly"
  },
  approve: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 10px 0 10px",
    padding: "20 5px",
    width: "160px",
    border: "2px solid",
    borderColor: "#F49400",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "space-evenly"
  },
  forapproval: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "5px 10px 0 10px",
    padding: "20 5px",
    width: "160px",
    border: "2px solid",
    borderColor: "#F49400",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "space-evenly"
  },
  selectAll: {
    borderRadius: "100px",
    fontSize: "0.8rem",
    color: "#F49400",
    padding: "20 5px",
    width: "100px",
    border: "1px solid",
    borderColor: "#F49400",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "space-evenly"
  },
  unselectAll: {
    borderRadius: "100px",
    fontSize: "0.8rem",
    color: "white",
    background: "#F49400",
    padding: "20 5px",
    width: "100px",
    border: "1px solid",
    borderColor: "#F49400",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "space-evenly"
  },
  cardSection: {
    // height: "calc(100% - 170px)"
    height: "calc(100% - 170px)",
    flexGrow: "1",
    overflow: "auto",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "12px",
    "&::-webkit-scrollbar-track": {
      display: "none"
    },
    "&::-webkit-scrollbar-thumb": {
      borderColor: "#EAEAEA"
    }
  },
  text: {
    fontSize: "18px",
    letterSpacing: 1.2,
    fontWeight: 600,
    marginLeft: 8,
    color: "#3c3c3c"
  }
}));
