import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Grid,
  Checkbox,
  Divider,
  Chip
} from "@material-ui/core";
import _ from "lodash";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  input: {
    padding: 0
  }
});

const Land = props => {
  const classes = useStyles();
  const {
    data,
    pickups,
    dropoffs,
    filterValues,
    setFilterValues,
    tabValue,
    getCursor,
    redirectToTrips,
    redirectToPrebooking
  } = props;

  const [locations, setLocations] = useState([]);
  const [land, setLand] = useState({
    at_risk: {
      count: 0,
      trip_numbers: []
    },
    at_risk_pre_dispatch: {
      count: 0,
      trip_numbers: []
    },
    at_risk_trip: {
      count: 0,
      trip_numbers: []
    },
    resupply: {
      count: 0,
      trip_numbers: []
    },
    resupply_pre_dispatch: {
      count: 0,
      trip_numbers: []
    },
    resupply_trip: {
      count: 0,
      trip_numbers: []
    },
    dc_pickup: {
      count: 0,
      trip_numbers: []
    },
    dc_pickup_pre_dispatch: {
      count: 0,
      trip_numbers: []
    },
    dc_pickup_trip: {
      count: 0,
      trip_numbers: []
    },
    customer: {
      count: 0,
      trip_numbers: []
    }
  });

  useEffect(() => {
    if (data?.get_demurrage_land) {
      setLand(data.get_demurrage_land);
    }
  }, [data]);

  useEffect(() => {
    const merged = _.unionBy(pickups, dropoffs, "value");
    setLocations(merged);
  }, [pickups, dropoffs]);

  const getSubCount = (title, subData, subDataPath) => {
    /**
     * Common component for subcounts
     * Accepts:
     *  title (string) - subcount card title
     *  subData (object) - contains count and trip_numbers
     *  subDataPath (string) - pathname on card click
     */
    return (
      <Grid item xs={6}>
        <div className={classes.dataSubContainer}>
          <Typography align="center">{title}</Typography>
          <Typography
            variant="h4"
            align="center"
            style={{
              cursor: getCursor(subData.count)
            }}
            onClick={() => {
              if (subData.count > 0) {
                if (subDataPath === "/trips") {
                  redirectToTrips(subData.trip_numbers);
                }
                if (subDataPath === "/prebooking") {
                  redirectToPrebooking(subData.trip_numbers);
                }
              }
            }}
          >
            {subData.count > 0 ? subData.count : 0}
          </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4" className={classes.title} color="primary">
            LAND
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ padding: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography>Location: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={locations}
                limitTags={2}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={
                      filterValues[tabValue].locations.length === 0 ? "All" : ""
                    }
                  />
                )}
                disableClearable
                value={filterValues[tabValue].locations}
                onChange={(e, nv) => {
                  const temp = [...filterValues];
                  temp[tabValue] = {
                    ...temp[tabValue],
                    locations: nv
                  };
                  setFilterValues(temp);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          {/* <div style={{ paddingTop: 25 }} align="center">
            ARRIVED
          </div> */}
          <Grid container spacing={3} style={{ padding: 50 }}>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">At Risk</Typography>
                <Typography variant="h4" align="center">
                  {land.at_risk.count > 0 ? land.at_risk.count : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount("P", land.at_risk_pre_dispatch, "/prebooking")}
                {getSubCount("T", land.at_risk_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">Resupply</Typography>
                <Typography variant="h4" align="center">
                  {land.resupply.count > 0 ? land.resupply.count : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount("P", land.resupply_pre_dispatch, "/prebooking")}
                {getSubCount("T", land.resupply_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">DC Pickup</Typography>
                <Typography variant="h4" align="center">
                  {land.dc_pickup.count > 0 ? land.dc_pickup.count : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount("P", land.dc_pickup_pre_dispatch, "/prebooking")}
                {getSubCount("T", land.dc_pickup_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.dataContainer}>
                <Typography align="center">Customer</Typography>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ cursor: getCursor(land.customer.count) }}
                  onClick={() => {
                    if (land.customer.count > 0) {
                      redirectToTrips(land.customer.trip_numbers);
                    }
                  }}
                >
                  {land.customer.count > 0 ? land.customer.count : 0}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Land;
