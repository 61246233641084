import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  containerBox: { 
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 0,
  },
  containerLabel: {
    alignItems: "center",
    display: "flex",
  },
  label: {
    color: "#3F3F3F !important",
    fontSize: "14px !important",
    width: "max-content",
  },
}));
