// import moment from "moment";
import {
  GET_AVAILABLE_VEHICLES_QUERY,
  GET_VEHICLES_TO_PAIR,
  GET_VEHICLES_LOOKUP
} from "../graphql/Queries";
import { CHANGE_VEHICLE_MUTATION } from "../graphql/Mutations";
import client from "../Client";

export const GET_AVAILABLE_VEHICLES = params => {
  let vars = {
    dateRange: params.dateRange
  };
  if (params.keyword !== "") {
    vars = {
      ...vars,
      filter: [{ field: "vehicle_info.plateno", value: [params.keyword] }]
    };
  }
  return new Promise(resolve => {
    client
      .query({
        query: GET_AVAILABLE_VEHICLES_QUERY,
        variables: vars,
        fetchPolicy: "network-only"
      })
      .then(data => {
        resolve(data.data.get_available_vehicles);
      })
      .catch(err => console.log(err));
  });
};

export const CHANGE_VEHICLE = (trip_number, vehicle) => {
  /* 
    fields: [
      { field: "vehicle_plate_no", value: "tmex1111" }
      { field: "vehicle_type", value: "10W" }
      { field: "vehicle_type_id", value: "2" }
      { field: "vehicle_id", value: "15659" }
    ]
    conditions: { field: "trip_number", value: "TIRP_J_TESTNEW" } 
  */
  const {
    vehicle_id,
    vehicle_type,
    vehicle_plate_no,
    vehicle_type_id
  } = vehicle;
  const fields = [
    { field: "vehicle_plate_no", value: vehicle_plate_no },
    { field: "vehicle_type", value: vehicle_type },
    { field: "vehicle_type_id", value: vehicle_type_id.toString() },
    { field: "vehicle_id", value: vehicle_id.toString() }
  ];
  const conditions = { field: "trip_number", value: trip_number };
  return new Promise(resolve => {
    client
      .mutate({
        mutation: CHANGE_VEHICLE_MUTATION,
        variables: {
          fields,
          conditions
        }
      })
      .then(() => {
        resolve("success");
      });
  });
};
export const TEST = () => {
  /* TEst */
};

export const GET_VEHICLES_TO_PAIR_REPO = plateno => {
  const condition = plateno ? [] : [{ field: "vehicle_info.device_id", value: "0" }];
  return new Promise(resolve => {
    client
      .query({
        query: GET_VEHICLES_TO_PAIR,
        variables: {
          first: 100,
          filter: [
            { field: "vehicle_info.plateno", value: plateno }
          ],
          condition
        },
        fetchPolicy: "network-only"
      })
      .then(data => {
        resolve(data.data.get_vehicles.vehicles);
      })
      .catch(err => console.log(err));
  });
};

export const getVehiclesToPair = (vehicleIds = []) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GET_VEHICLES_LOOKUP,
        variables: {
          filter: [
            {
              field: "plateno",
              value: vehicleIds
            }
          ],
          first: vehicleIds.length
        }
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};
