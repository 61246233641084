/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import Cookie from "js-cookie";
import {
  Paper,
  makeStyles,
  // withStyles,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  FormControl,
  Select,
  Tooltip
} from "@material-ui/core";
import {
  // ExpandMore as ExpandMoreIcon,
  HelpRounded
} from "@material-ui/icons";
import Swal from "sweetalert2";
import { Alert } from "@material-ui/lab";
import ModuleTree from "../../../../utils/ModuleTree";
import useFormStyles, {
  useFormErrorStyles
} from "../../../../styles/useFormStyles";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
import SearchContext from "../../../../context/SearchContext";
import { EDIT_USER_LEVEL } from "../../../../graphql/Mutations";
import {
  GET_ACCESS_OBJECTS,
  GET_USER_LEVEL
} from "../../../../graphql/Queries";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import Header from "../../Header";
import ErrorPage from "../../../../utils/errorPage";
import redirectToTable from "../../../../utils/redirect";
import AddLogs from "../../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    "& ::-webkit-scrollbar-thumb": {
      border: "5px solid #f8f8f8"
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      border: "4px solid #f8f8f8",
      background: "#969696"
    },
    "& ::-webkit-scrollbar-track": {
      border: "5px solid #f8f8f8"
    }
  },
  container: {
    height: "calc(100% - 66px)",
    // margin: theme.spacing(2, 4),
    flexGrow: 1,
    boxSizing: "border-box",
    padding: theme.spacing(3),
    overflow: "auto",
    background: "#F8F8F8"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  formLabelRoot: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#454545",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center"
  },
  formOutlined: {
    border: "2px solid #cbcbcb"
  },
  field: {
    margin: "12px 0",
    width: "90%"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  column: {
    fontSize: "1.5rem"
  },
  buttonContainer: { position: "relative", bottom: theme.spacing(3) },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  details: {
    display: "block",
    borderTop: "solid",
    borderTopWidth: "1px",
    borderTopColor: "lightgray",
    padding: "8px 20px"
  },
  dialog: {
    borderRadius: "10px"
  }
}));



const EditViewMenuTest = props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    access,
    handleAccessView,
    handleAccessEdit
  } = props;

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        color="primary"
                        checked={access ? access.view : false}
                        value={access.view || false}
                        onClick={e => handleAccessView(e)}
                        name={anchorEl.name}
                      />
                    }
                    label="Can View"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
                <MenuItem style={{ padding: 0 }}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        color="primary"
                        checked={access ? access.edit : false}
                        value={access.edit || false}
                        onClick={e => handleAccessEdit(e)}
                        name={anchorEl.name}
                      />
                    }
                    label="Can Edit"
                    style={{ margin: "0 16px 0 0" }}
                  />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default function AccessRight(props) {
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));
  const contextData = useContext(SearchContext);
  const [state, setState] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [textual, setTextual] = useState({
    name: "",
    description: "",
    acl_module_id: 0,
    client_id: 0
  });
  const [errors, setErrors] = useState({
    name: "",
    access_rights: "",
    acl_module_id: ""
  });

  const [initialState, setInitialState] = useState({
    state: _.cloneDeep(state),
    textual: _.cloneDeep(textual)
  });
  const [anchorEl, setAnchorEl] = useState(false); // eslint-disable-line no-unused-vars
  const [rerender, setRerender] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });
  const [editPermission, setEditPermission] = useState(false);
  const { location } = props;
  const { pathname } = location;
  const history = useHistory();
  const { id } = useParams();
  const { data: data1, loading } = useQuery(GET_ACCESS_OBJECTS, {
    variables: { user_level_id: id },
    fetchPolicy: "network-only"
  });
  const [adminChecked, setAdminChecked] = useState(false);

  const { data: data2, loading: loading2 } = useQuery(GET_USER_LEVEL, {
    variables: { id: id },
    fetchPolicy: "network-only",
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        });
      }
    }
  });

  const [popper, setPopper] = useState(false);
  const [parsing, setParsing] = useState(true);

  const [editUserLevel] = useMutation(EDIT_USER_LEVEL, {
    onCompleted(data) {
      if (data) {
        if (data.edit_user_level.success) {
          AddLogs("Admin - Access Rights", "update", textual.name);
          Swal.fire({
            title: "Saved",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_RIGHTS_MODULE
                  }
                }
              });
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_RIGHTS_MODULE
                  }
                }
              });
            }
          });
        } else {
          const temp = [];
          data.edit_user_level.error.map(element =>
            temp.push({ name: "This role already exists" })
          );
          setErrors(Object.assign(errors, ...temp));
          setRerender(!rerender);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );

      if (returnError) {
        Swal.fire({
          title: "An error encountered",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_RIGHTS_MODULE);
          }
        });
      }
    }
  });

  const sort = (items = []) => {
    const temp = [...items];
    return temp.sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  };

  const getName = resource => {
    let name = resource.split("/")[resource.split("/").length - 2];
    if (resource.split("/")[1] === "prebooking") {
      name = `${resource.split("/")[resource.split("/").length - 2]}_pre`;
    } else if (resource.split("/")[1] === "dashboard") {
      name = `${resource.split("/")[resource.split("/").length - 1]}_das`;
    } else if (resource.split("/")[1] === "pms") {
      name = `${resource.split("/")[resource.split("/").length - 1]}_pms`;
    } else if (resource.split("/")[1] === "allocation" && resource.split("/")[2] === "trip") {
      name = `${resource.split("/")[resource.split("/").length - 1]}_alloc`;
    }
    return name;
  };

  const checkChanges = () => {
    return (
      Object.keys(data2.get_user_level)
        .filter(element => element in textual)
        .some(element => textual[element] !== data2.get_user_level[element]) ||
      data1.get_access_objects
        .filter(object => getName(object.resource) in state)
        .some(
          object =>
            state[getName(object.resource)].view !== object.permission.view ||
            state[getName(object.resource)].edit !== object.permission.edit
        )
    );
  };

  const check = field => {
    if (!errors[field])
      return textual[field] === "" ? "This field is required" : "";
    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    // setErrors(Object.assign(errors, ...temp));
    const selected = Object.values(state).filter(el => el.view === true);
    if (textual.acl_module_id === 0) {
      temp.push({ acl_module_id: "This field is required" });
    }
    if (textual.name !== "") {
      temp.push({ name: "" });
    }
    if (selected.length <= 0) {
      temp.push({ access_rights: "This field is required" });
    }
    if (temp.length > 0) setErrors(Object.assign(errors, ...temp));

    return (
      keys
        .filter(element => element in textual)
        .every(element => errors[element] === "") &&
      keys.every(key => errors[key] === "")
    );
  };


  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setPopper(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopper(false);
    }
  }

  const handleOpenDialog = dialog_name => {
    if (dialog_name === "save") {
      if (validateFields()) setDialog({ ...dialog, [dialog_name]: true });
      else setRerender(!rerender);
    } else {
      setDialog({ ...dialog, [dialog_name]: true });
    }
  };

  const handleCloseDialog = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const discarded = () => {
    if (data1) {
      const temp1 = [];
      data1.get_access_objects
        .filter(object => getName(object.resource) in state)
        .map(object =>
          temp1.push({
            [getName(object.resource)]: {
              resource: object.resource,
              view: object.permission.view,
              edit: object.permission.edit
            }
          })
        );
      setState(Object.assign(state, ...temp1));
    }
    if (data2) {
      const temp2 = [];
      Object.keys(textual)
        .filter(key => key in data2.get_user_level)
        .forEach(key => {
          temp2.push({ [key]: data2.get_user_level[key] });
        });
      setTextual(Object.assign(textual, ...temp2));
    }
    setErrors({ name: "", access_rights: "", acl_module_id: "" });
    setEditMode(false);
    handleCloseDialog("discard");
  };

  const handleAccessEdit = e => {
    const { name, checked } = e.target;
    setState({
      ...state,
      [name]: {
        ...state[name],
        view: checked ? true : state[name].view,
        edit: checked
      }
    });
  };

  const handleAccessView = e => {
    const { name, checked } = e.target;
    setState({
      ...state,
      [name]: {
        ...state[name],
        view: checked,
        edit: checked ? state[name].edit : checked
      }
    });
  };


  const handleOnSelectedModuleChanged = useCallback(
    (newModuleTree, isModuleEmpty) => {
      if (!editMode) {
        return;
      }

      if (isModuleEmpty) {
        setErrors({ ...errors, access_rights: "This field is required" });
      } else {
        setErrors(prev => ({ ...prev, access_rights: "" }));
      }

      const permissions = newModuleTree.reduce((accum, current) => {
        const accumulatedPermissions = accum;
        accumulatedPermissions[getName(current.resource)] = {
          resources: current.resource,
          view: current.permission.view,
          edit: current.permission.edit
        };
        return accumulatedPermissions;
      }, {});

      const landingPageExist = newModuleTree.reduce((accum, current) => {
        if (+current.id === +textual.acl_module_id) {
          return current.permission.view;
        }
        return accum;
      }, false);

      /*
       *** validates if the admin is selected on the modules.
       */
      const adminSelected = newModuleTree.some(
        module =>
          module.name.trim().substring(0, 5) === "Admin" &&
          module.parent_id === "0" &&
          module.permission.view
      );

      setAdminChecked(adminSelected);
      /*
       *** sets the acl module id to 1 if the admin is selected and checked.
       */
      if (adminSelected && textual.acl_module_id === 1) {
        setTextual(prev => ({ ...prev, acl_module_id: 1 }));
      } else if (!landingPageExist) {
        setTextual(prev => ({ ...prev, acl_module_id: 0 }));
        setErrors(prev => ({
          ...prev,
          acl_module_id: "This field is required"
        }));
      }
      setState(permissions);
    },
    [textual.acl_module_id, getName, setErrors, editMode]
  );

  const handleChangeText = event => {
    const { name, value } = event.target;
    setTextual({
      ...textual,
      [name]: value
    });
  };

  const handleBlur = e => {
    const { name, value } = e.target;
    let error = "";
    switch (name) {
      case "name":
        if (value === "") {
          error = "This field is required";
        } else {
          error = "";
        }
        break;
      default:
        break;
    }
    setErrors({ ...error, name: error });
  };


  const handleSubmit = () => {
    const temp = [];
    Object.values(state)
      .filter(val => val.resources !== "")
      .map(val => temp.push(val));
    editUserLevel({
      variables: {
        id: id,
        user_level: {
          name: textual.name,
          description: textual.description,
          acl_module_id: textual.acl_module_id
        },
        permissions: Object.values(temp)
      }
    });
  };

  useEffect(() => {
    if (data1) {
      const temp1 = [];
      data1.get_access_objects.map(object =>
        temp1.push({
          [getName(object.resource)]: {
            resources: object.resource,
            view: object.permission.view,
            edit: object.permission.edit
          }
        })
      );
      setState(Object.assign(state, ...temp1));
      setInitialState({
        ...initialState,
        state: Object.assign(initialState.state, ...temp1)
      });
      setParsing(false);
      setAdminChecked(
        data1.get_access_objects.some(
          module => module.name.trim().substring(0, 5) === "Admin"
        )
      );
    }
    setRerender(!rerender);
  }, [data1]);

  useEffect(() => {
    if (data2) {
      const temp2 = [];
      Object.keys(textual)
        .filter(key => key in data2.get_user_level)
        .forEach(key => {
          temp2.push({ [key]: data2.get_user_level[key] });
        });
      setTextual(Object.assign(textual, ...temp2));
      setInitialState({
        ...initialState,
        textual: Object.assign(initialState.textual, ...temp2)
      });
    }

    setRerender(!rerender);
  }, [data2]);

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpenDialog("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: process.env.REACT_APP_RIGHTS_MODULE
    });
  }, []);

  const handleLandingPage = e => {
    // setLandingPage(e.target.value);
    setTextual(prev => ({ ...prev, acl_module_id: e.target.value }));
    setErrors({ ...errors, acl_module_id: "" });
  };

  const parsedAccessObject = useMemo(() => {
    return data1?.get_access_objects.map(module => {
      return {
        ...module,
        view: module.permission.view,
        edit: module.permission.edit
      };
    });
  }, [data1]);

  const checkRights = permission => {
    let temp = false;
    if (data2) {
      if (
        data2.get_user_level.name === "Data Admin" &&
        user.user_level_id !== process.env.REACT_APP_WTI_ADMIN
      ) {
        temp = false;
      } else if (data2.get_user_level.id === process.env.REACT_APP_WTI_ADMIN) {
        if (user.user_level_id === process.env.REACT_APP_WTI_ADMIN) {
          temp = true;
        } else {
          temp = false;
        }
      } else {
        temp = permission;
      }
    }
    return temp;
  };

  const formStyles = useFormStyles();
  const formErrorStyles = useFormErrorStyles();

  if (loading || loading2 || parsing) return <Loading />;
  if (!data2) return <ErrorPage />;
  return (
    <AccessControl
      resource={pathname}
      process="view"
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        <Header
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_RIGHTS_MODULE}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={
            checkRights(editPermission)
          }
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={
            _.isEqual(initialState.textual, textual) &&
            _.isEqual(initialState.state, state)
          }
        />
        <Paper className={classes.container} variant="outlined">
          {/* <Grid item xs={12} style={{ display: "flex", marginBottom: 12 }}> */}
          <div className={classes.wrapper}>
            <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
              All fields with <span style={{ color: "red" }}>*</span> are
              required
            </Typography>
            {/* </Grid> */}
            <Grid container spacing={6} style={{ height: "100%" }}>
              <Grid item lg={3} md={6} xs={12}>
                <FormControl required classes={{ root: formStyles.formRoot }}>
                  <TextField
                    required
                    classes={{ root: formStyles.textFieldRoot }}
                    name="name"
                    label="Role"
                    placeholder="Role"
                    value={textual.name}
                    onChange={e => handleChangeText(e)}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                  />
                  {errors.name && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.name}
                    </Alert>
                  )}
                </FormControl>
                {/* <div className={classes.field}> */}
                <FormControl classes={{ root: formStyles.formRoot }}>
                  <Typography
                    classes={{ root: formStyles.formLabelRoot }}
                    variant="subtitle2"
                  >
                    Description
                  </Typography>
                  <TextField
                    name="description"
                    classes={{ root: formStyles.textFieldRoot }}
                    className="outlinedTextarea"
                    placeholder="Type here..."
                    multiline
                    rows="4"
                    value={textual.description}
                    onChange={e => handleChangeText(e)}
                    variant="outlined"
                    fullWidth
                    helperText={`${textual.description.length}/100`}
                    FormHelperTextProps={{ style: { textAlign: "right" } }}
                    inputProps={{
                      disabled: !editMode,
                      readOnly: !editMode,
                      maxLength: 100
                    }}
                  />
                </FormControl>
                {/* </div> */}
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <div className={formStyles.formRoot}>
                  <Typography
                    classes={{ root: formStyles.formLabelRoot }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    <Tooltip
                      classes={{ tooltip: classes.toolTip }}
                      title="Set the modules that will be accessible to this role"
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    Modules &nbsp;<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <Paper
                    variant="outlined"
                    // style={!editMode ? { pointerEvents: "none" } : {}}
                    classes={{ outlined: classes.formOutlined }}
                  >
                    <ModuleTree
                      disabled={!editMode}
                      default_access={parsedAccessObject}
                      updateSelectedModules={handleOnSelectedModuleChanged}
                    />
                  </Paper>
                  {errors.access_rights && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.access_rights}
                    </Alert>
                  )}
                </div>
                
                <FormControl required classes={{ root: formStyles.formRoot }}>
                  <InputLabel shrink>Landing Page</InputLabel>
                  <Select
                    disableUnderline={!editMode}
                    disabled={!editMode}
                    classes={{ root: formStyles.selectRoot }}
                    value={textual.acl_module_id}
                    onChange={e => handleLandingPage(e)}
                    displayEmpty
                  >
                    <MenuItem value={0} disabled>
                      <span style={{ color: "#8e8e8e" }}>
                        Select Landing Page
                      </span>
                    </MenuItem>
                    {adminChecked && <MenuItem value={1}>Admin</MenuItem>}
                    {data1.get_access_objects.length > 0 &&
                      sort(
                        data1.get_access_objects.filter(
                          moduleRow =>
                            moduleRow.parent_id === "0" &&
                            // eslint-disable-next-line no-prototype-builtins
                            state.hasOwnProperty(getName(moduleRow.resource)) &&
                            state[getName(moduleRow.resource)].view &&
                            process.env.REACT_APP_ACL_MODULE_STORE_APP !==
                              moduleRow.id &&
                            process.env.REACT_APP_ACL_MODULE_SMS_SENDING_API !==
                              moduleRow.id &&
                            moduleRow.name.trim().substring(0, 5) !== "Admin"
                        )
                      ).map(moduleRow => (
                        <MenuItem
                          value={parseInt(moduleRow.id, 10)}
                          key={moduleRow.resource}
                        >
                          {moduleRow.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.acl_module_id && (
                    <Alert
                      severity="error"
                      classes={{
                        root: formErrorStyles.root,
                        standardError: formErrorStyles.standardError,
                        icon: formErrorStyles.icon
                      }}
                    >
                      {errors.acl_module_id}
                    </Alert>
                  )}
                </FormControl>
              </Grid>
              {editMode && (
                <Grid item xs={12} className={classes.buttonContainer}>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      left: 0,
                      textAlign: "center"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => handleOpenDialog("save")}
                    >
                      Save Details
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>


            {anchorEl && (
              <EditViewMenuTest
                open={popper}
                anchorEl={anchorEl}
                access={state[anchorEl.name]}
                handleClosePopper={handleClosePopper}
                handleListKeyDown={handleListKeyDown}
                handleAccessEdit={handleAccessEdit}
                handleAccessView={handleAccessView}
              />
            )}
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleCloseDialog("save")}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save access right?"
        />
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleCloseDialog("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
      </div>
    </AccessControl>
  );
}
