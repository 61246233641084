import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Paper, Button, Grid, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import _ from "lodash";
// import { withSwalInstance } from "sweetalert2-react";
import SplitContainer from "./SplitContainer";
// import VehicleAssignDialog from "../utils/modal/VehicleAssign";
import ListTripDetails from "../utils/paper/ListTripDetails";
import ConfirmationDialog from "../../../utils/modal/ConfirmationDialog";
import ErrorDialog from "../../../utils/modal/ErrorDialog";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  }
}));
const SplitTrip = props => {
  const theme = useTheme();
  const classes = useStyles();
  const { location, history } = props;
  const { parsedTripData, rawTripData, dateRange, moduleName } = location.state;
  // const [vehicleModal, toggleVehicleModal] = useState(false);
  const [subTrips, setSubTrips] = useState(null);
  const [dropoffs, setDropoffs] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [confirmationModal, toggleConfirmationModal] = useState(null);
  const [redirectModal, toggleRedirectModal] = useState(null);
  const [errorModal, toggleErrorModal] = useState(null);
  const [updateVehicleInfo, setUpdateVehicleInfo] = useState(false);
  const [splitInfo, setSplitInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const PRE_DISPATCH_MODULE = process.env.REACT_APP_PRE_DISPATCH_MODULE;
  const INCOMING_STATUS = process.env.REACT_APP_INCOMING_STATUS;
  const ARRIVED_AT_PICKUP = process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP;
  const { status_code_id, raw_trip_status } = parsedTripData;
  const isArrivedAtPickup =
    Number(status_code_id) === Number(ARRIVED_AT_PICKUP);

  function openConfirmationModal() {
    toggleConfirmationModal(true);
  }

  function openErrorModal() {
    toggleErrorModal(true);
  }

  // function closeVehicleModal() {
  //   toggleVehicleModal(false);
  // }

  const saveSplitBookings = () => {
    return new Promise(resolve => {
      let errors = [];

      if (subTrips) {
        const variablesArray = [];
        const rawData = { ...rawTripData };
        const so_bookings = rawData.value;
        const data = [...subTrips];
        let selectedVehicles = [];
        if (dropoffs.length) {
          dropoffs.forEach(dropoff => {
            const { trip_number } = dropoff;
            const subTripIndex = _.findIndex(data, x => {
              return x.trip_number === trip_number;
            });
            if (subTripIndex > -1) {
              const {
                vehicle_id,
                vehicle_plate_no,
                vehicle_type,
                vehicle_type_id
              } = data[subTripIndex];

              if (selectedVehicles.indexOf(vehicle_plate_no) === -1) {
                selectedVehicles.push(vehicle_plate_no);
              }
              const so_bookingsIndex = _.findIndex(so_bookings, x => {
                return x.so_number === dropoff.so_number;
              });
              if (so_bookingsIndex < 0) {
                const error = `Old trip data used.`;
                errors.push(error);
                return;
              }
              const { id } = so_bookings[so_bookingsIndex];
              const trip_dropoffs = dropoffs.filter(
                x => x.trip_number === trip_number
              );
              if (!vehicle_id) {
                const error = `${trip_number} has no vehicle.`;
                const e = errors.indexOf(error);
                if (e === -1) {
                  errors.push(error);
                }
                return;
              }
              if (trip_dropoffs.length === dropoffs.length) {
                const error = `${trip_number} should not get all SO.`;
                const e = errors.indexOf(error);
                if (e === -1) {
                  errors.push(error);
                }
                return;
              }
              if (trip_dropoffs.length === 0) {
                const error = `${trip_number} has no SO.`;
                const e = errors.indexOf(error);
                if (e === -1) {
                  errors.push(error);
                }
                return;
              }

              const variables = {
                id,
                trip_number,
                vehicle_id,
                vehicle_plate_no,
                vehicle_type,
                vehicle_type_id,
                is_split: true,
                status_code_id: isArrivedAtPickup
                  ? INCOMING_STATUS
                  : status_code_id,
                status_code: isArrivedAtPickup ? "Incoming" : raw_trip_status,
                for_reprocess: true
              };
              if (errors.length === 0) {
                variablesArray.push(variables);
              }
              // if (errors.length === 0) {
              //   if (selectedVehicles.length > 1) {
              //     // UPDATE_SPLIT_BOOKING(variables);
              //     variablesArray.push(variables);
              //     console.log('update');
              //   } else {
              //     const error = `Same vehicle for both trips.`;
              //     errors.push(error);
              //     return;
              //   }
              // }
            } else {
              const error = `All SO must be checked.`;
              errors.push(error);
            }
          });
          selectedVehicles = selectedVehicles.filter(el => {
            return el != null;
          });
          if (selectedVehicles.length > 1) {
            // Pass variables to VehicleAssign.jsx to process update booking after clearing booked vehicles
            setSplitInfo(variablesArray);
            setUpdateVehicleInfo(true);
          } else if (selectedVehicles.length === 1) {
            const error = `Error with vehicle allocation.`;
            errors.push(error);
          } else {
            const error = `No vehicle for both trips.`;
            errors.push(error);
          }
        } else {
          const error = `Please select SO for splitting.`;
          errors.push(error);
        }
      } else {
        const error = `Please select SO for splitting.`;
        errors.push(error);
      }
      if (errors.length) {
        errors = errors.filter(
          (value, index, self) => self.indexOf(value) === index
        );
        setTitle("Error with Splitting Trips");
        setContent(errors);
        openErrorModal();
        resolve("error");
      } else {
        resolve("success");
      }
    });
  };
  /* DEPRECATED */
  // const saveSplitTrips = () => {
  //   if (subTrips) {
  //     const rawData = { ...rawTripData };
  //     const data = [...subTrips];
  //     data.map((subtrip, i) => {
  //       /* Trip level */
  //       const { trip_number } = subtrip;
  //       const trip_dropoffs = dropoffs.filter(
  //         dropoff => dropoff.trip_number === trip_number
  //       );
  //       const items = [];
  //       trip_dropoffs.forEach((el, dropoff_index) => {
  //         el.items.forEach(item => {
  //           const obj = { ...item };
  //           obj.dropoff_index = dropoff_index;
  //           items.push(obj);
  //         });
  //         trip_dropoffs[dropoff_index].name = el.destination;
  //         delete trip_dropoffs[dropoff_index].trip_number;
  //         delete trip_dropoffs[dropoff_index].destination;
  //         delete trip_dropoffs[dropoff_index].items_qty;
  //         delete trip_dropoffs[dropoff_index].items;
  //         delete trip_dropoffs[dropoff_index].dr_count;
  //         delete trip_dropoffs[dropoff_index].do_status;
  //         delete trip_dropoffs[dropoff_index].status;
  //       });
  //       data[i].dropoffs = trip_dropoffs;
  //       data[i].items = items;
  //       if (trip_dropoffs.length === dropoffs.length) {
  //         // errorAlert();
  //         // error
  //       } else if (trip_dropoffs.length === 0) {
  //         // error
  //         // errorAlert();
  //       }
  //     });
  //     /* Trip A is Update, trip b is add. */
  //     const tripA = data[0];
  //     const tripB = data[1];
  //     console.log(tripB);
  //     rawData.trip_number = tripB.trip_number;
  //     rawData.dropoffs = tripB.dropoffs;
  //     rawData.vehicle_id = tripB.vehicle_id;
  //     rawData.vehicle_plate_no = tripB.vehicle_plate_no;
  //     rawData.vehicle_type = tripB.vehicle_type;
  //     rawData.vehicle_type_id = tripB.vehicle_type_id;
  //     ADD_BOOKING(rawData);
  //     /* UPDATE MAIN TRIP */
  //     rawData.trip_number = tripA.trip_number;
  //     rawData.dropoffs = tripA.dropoffs;
  //     rawData.vehicle_id = tripA.vehicle_id;
  //     rawData.vehicle_plate_no = tripA.vehicle_plate_no;
  //     rawData.vehicle_type = tripA.vehicle_type;
  //     rawData.vehicle_type_id = tripA.vehicle_type_id;
  //     UPDATE_SPLIT_BOOKING(rawData);
  //   } else {
  //     // errorAlert();
  //     // error
  //   }
  // };

  const handleSubTrips = (data, d) => {
    setSubTrips(data);
    setDropoffs(d);
  };

  const redirectAction = () => {
    let process = false;
    if (dropoffs) {
      dropoffs.forEach(x => {
        if (x.trip_number) {
          process = true;
        }
      });
    }
    const to = {
      pathname: moduleName === PRE_DISPATCH_MODULE ? "/prebooking" : "/trips",
      state: {
        // tripNumber: row.tripNumber
        fromDetails: true
      }
    };
    if (process) {
      toggleRedirectModal(true);
    } else {
      history.push(to);
    }
  };

  const toolbar = (
    <Paper className={classes.paper}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          {/* <Link
            to={{
              pathname: `/trips`,
              state: {
                // tripNumber: row.tripNumber
                fromDetails: true
              }
            }}
          > */}
          <IconButton aria-label="delete" onClick={redirectAction}>
            <ArrowBack style={{ margin: "0px 5px" }} />
          </IconButton>
          {/* </Link> */}
          <Typography variant="h5" style={{ display: "contents" }}>
            Split Trip
          </Typography>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <Button
            variant="contained"
            style={{
              borderRadius: "25px",
              // margin: "0 20px 0 20px",
              fontSize: "12px",
              padding: "7px 35px",
              marginRight: "25px",
              float: "right",
              backgroundColor: theme.palette.primary.main
            }}
            onClick={() => {
              setTitle("Split trip?");
              setContent(
                "Are you sure you want to split this trip? You will not be able to undo this action"
              );
              openConfirmationModal();
              // saveSplitBookings();
              // window.location.href = "trips";
            }}
            disabled={loading}
          >
            Split
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <div className="TripDetails">
      {toolbar}
      <Grid container spacing={3}>
        <Grid item xs={3}>
          {/* TRIP DETAILS */}
          <ListTripDetails parsedTripData={parsedTripData} />
        </Grid>
        <Grid item xs={9} style={{ overflowY: "scroll" }}>
          {/* STOPS (DROP OFFS) */}
          <SplitContainer
            parsedTripData={parsedTripData}
            rawTripData={rawTripData}
            handleSubTrips={handleSubTrips}
            updateVehicleInfo={updateVehicleInfo}
            splitInfo={splitInfo}
            moduleName={moduleName}
            dateRange={dateRange}
          />
          <Paper className={classes.paper} />
        </Grid>
      </Grid>

      {/* <VehicleAssignDialog
        toggle={vehicleModal}
        closeVehicleModal={closeVehicleModal}
        tripData={{ dateRange: dateRange }}
      /> */}
      <ConfirmationDialog
        title={title}
        content={content}
        fn={async () => {
          setLoading(true);
          saveSplitBookings();
        }}
        close={() => toggleConfirmationModal(null)}
        toggle={confirmationModal}
      />
      <ConfirmationDialog
        title="Discard Changes?"
        content="Are you sure you want to leave this page and discard changes?"
        fn={() =>
          history.push({
            pathname:
              moduleName === PRE_DISPATCH_MODULE ? "/prebooking" : "/trips",
            state: {
              fromDetails: true
            }
          })
        }
        close={() => toggleRedirectModal(null)}
        toggle={redirectModal}
      />
      <ErrorDialog
        title={title}
        content={content}
        close={() => toggleErrorModal(null)}
        toggle={errorModal}
      />
    </div>
  );
};

export default SplitTrip;
