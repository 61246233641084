export default {
  [process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT]: {
    additional_days: 5
  },
  [process.env.REACT_APP_REPORT_TYPE_ALERT_COUNT_DASHBOARD]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_STANDARD_IDLING]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_VEHICLE_UTILIZATION]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_CICO]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_CICO_NO_TRIP]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_IGNITION]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_COMPLETE_LOG]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_SPEEDING]: {
    additional_days: 30
  },
  [process.env.REACT_APP_REPORT_TYPE_GEOFENCE_IN_AND_OUT]: {
    additional_days: 30
  }
};
