import {
  AppBar,
  Box,
  Button,
  Grid,
  Popover,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableHead,
  withStyles,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  IconButton,
  Tooltip
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { InfoWindow, Marker, GoogleMap } from "@react-google-maps/api";
import querystring from "query-string";
import { useLazyQuery, useQuery } from "@apollo/client";
import { TripOrigin } from "@material-ui/icons";
import _ from "lodash";
import Map from "../../../utils/Map";
import Logo from "../../../assets/Images/logo.png";
import SvgIcons, {
  fillColor,
  strokeColor
} from "../../../assets/SvgIcons/SvgIcons";
import CustomMarker from "../../../utils/Map/Marker/CustomMarker";
import UseMapViewStyles from "./MapView.style";
import { createPortal } from "react-dom";
// import Cookie from "js-cookie";

import {
  GET_INFO_BUBBLE,
  GET_SHARE_LOCATION_V2,
  GET_VEHICLES_COUNT,
  GET_VEHICLE_INFOS,
  GET_ACL_MODULES_CLIENT
} from "../../../graphql/Queries";
import { getLastAlertMsg, getLastAlertStamp, gpsStatus } from "../../Utils";
import Loading from "../../../utils/Loading";
import Battery from "../../../utils/Battery";
import { control_positions } from "../../../utils/Map/Inner";
import BlinkingAlertsLegend from "../../../utils/BlinkingAlertsLegend";
import InfoBubbleDetail from "../MapPartials/CustomInfoBubble.js/InfoBubbleDetail";
import { countVehicles } from "../../../data/vehicleCountData";
import { loadOptions } from "../../../utils/Map/Inner";
import VehicleCount from "../../../utils/UI/VehicleCount";

const zoomLevel = 13;
// const latlng = { lat: 14.570863570431083, lng: 120.95194905532837 };
// const timer = moment().format("HH:MM:SS");
// const time = moment().format("MM/DD/YYYY HH:MM:SS");

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

// For vehicles status and counts in header
// const VehicleCount = memo(props => {
//   const { vehicleCount } = props;
//   const classes = UseMapViewStyles();

//   return (
//     <PopupState variant="popover" popupId="demo-popup-popover">
//       {popupState => (
//         <div className={classes.alignment}>
//           <Typography className={classes.padding}>Count: </Typography>
//           <Button
//             className={classes.btn}
//             variant="outlined"
//             color="primary"
//             classes={{
//               outlined: classes.outlined,
//               outlinedPrimary: classes.outlinedPrimary
//             }}
//             {...bindTrigger(popupState)}
//           >
//             {vehicleCount?.get_vehicles_count?.total}
//             <ExpandMore fontSize="small" />
//           </Button>
//           <Popover
//             {...bindPopover(popupState)}
//             anchorOrigin={{
//               vertical: "bottom",
//               horizontal: "center"
//             }}
//             transformOrigin={{
//               vertical: "top",
//               horizontal: "center"
//             }}
//           >
//             <Box>
//               <List className={classes.listItem}>
//                 {countVehicles.map((info, index) => (
//                   <>
//                     {info.isHeader && index > 1 && <Divider variant="middle" />}
//                     <ListItem key={info.label} dense>
//                       <ListItemText
//                         id={info.label}
//                         primary={info.label}
//                         className={info.isHeader ? classes.text_count : ""}
//                       />
//                       {!info.isHeader && (
//                         <ListItemSecondaryAction>
//                           <Typography
//                             edge="end"
//                             className={classes.countResult}
//                           >
//                             {vehicleCount?.get_vehicles_count?.status_count?.[
//                               info.dataHeader
//                             ] || 0}
//                           </Typography>
//                         </ListItemSecondaryAction>
//                       )}
//                     </ListItem>
//                     {info.subCategory.length > 0 &&
//                       info.subCategory.map(subInfo => (
//                         <ListItem
//                           className={classes.nested}
//                           key={subInfo.label}
//                           dense
//                         >
//                           <ListItemText
//                             id={subInfo.label}
//                             primary={`${info.label}${
//                               subInfo.label === "Under Repair" ? " " : "-"
//                             }${subInfo.label}`}
//                           />
//                           <ListItemSecondaryAction style={{ color: "gray" }}>
//                             <Typography
//                               edge="end"
//                               className={classes.countResult}
//                             >
//                               {vehicleCount?.get_vehicles_count?.status_count?.[
//                                 subInfo.dataHeader
//                               ] || 0}
//                             </Typography>
//                           </ListItemSecondaryAction>
//                         </ListItem>
//                       ))}
//                   </>
//                 ))}
//               </List>
//             </Box>
//           </Popover>
//         </div>
//       )}
//     </PopupState>
//   );
// });

// Custom vehicle markers and info bubble of selected vehicles
const VehicleMarker = props => {
  const classes = UseMapViewStyles();
  const { default_icon } = SvgIcons;
  const { zoomLevel, vehicleData, code } = props;

  const {
    plateno,
    vehicle_type,
    has_fuel_sensor,
    has_temp_sensor,
    door_status,
    ignition_status,
    personnel_name,
    client_name,
    device_info: {
      name,
      device_type_id,
      device_status: {
        gpsSpeed,
        lastlocation,
        reportstamp,
        temperature,
        battery_level,
        fuelconsumption,
        distancetraveled,
        powersource,
        heading,
        AlertReferences,
        last_alert_msg,
        last_alert_stamp
      }
    }
  } = vehicleData;

  const [infoWindow, setInfoWindow] = useState(false);

  const { data: infoBubble } = useQuery(GET_INFO_BUBBLE, {
    variables: { client_id: +vehicleData.client_id, code: code }
  });

  const positionHandler = () => {
    return {
      lat: vehicleData.device_info.device_status.latitude,
      lng: vehicleData.device_info.device_status.longitude
    };
  };

  const onInfoWindowClose = () => {
    setInfoWindow(prev => !prev);
  };

  const isLBSVehicle = AlertReferences?.some(
    alert => alert.alert_code === "LBS DATA"
  );

  const Icon =
    SvgIcons[
      isLBSVehicle
        ? "LBS_vehicle"
        : vehicleData.icon.split(".")[0]
        ? vehicleData.icon.split(".")[0]
        : "default_icon"
    ];
  return (
    <>
      <Marker
        onClick={onInfoWindowClose}
        position={{
          lat: vehicleData.device_info.device_status.latitude,
          lng: vehicleData.device_info.device_status.longitude
        }}
        options={{
          zIndex: 236589,
          icon: {
            path: Icon.path,
            fillColor: isLBSVehicle ? Icon.fill : fillColor[powersource], // Icon.fill,
            fillOpacity: 1,
            strokeColor: isLBSVehicle
              ? Icon.stroke
              : strokeColor[ignition_status], // Icon.stroke,
            strokeWeight: isLBSVehicle ? 1.0 : 1.5,
            scale: 1.2,
            rotation: isLBSVehicle ? 0 : heading,
            size: new window.google.maps.Size(22, 17),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(9, 13)
          }
        }}
      >
        {infoWindow && (
          <InfoWindow
            className={classes.infoWindowContainer}
            position={{
              lat: vehicleData.device_info.device_status.latitude,
              lng: vehicleData.device_info.device_status.longitude
            }}
            onCloseClick={onInfoWindowClose}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Box display="flex" gripGap={5} alignItems={"center"}>
                      <TripOrigin
                        className={classes.labelIconStatus}
                        style={{
                          color: gpsStatus(
                            vehicleData.device_info.device_status.status_code.toLowerCase()
                          ).color
                        }}
                      />
                      <Typography style={{ fontSize: 12, color: "#c4c4c4" }}>
                        {plateno}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <InfoBubbleDetail label="Hauler Name:" content={client_name || "-"} />
                <InfoBubbleDetail
                  label="Vehicle Type:"
                  content={vehicle_type}
                />
                <InfoBubbleDetail
                  label="Last Report:"
                  content={`${moment(reportstamp).format("LT ll")}`}
                />
                <InfoBubbleDetail
                  label="Last Alert:"
                  content={getLastAlertMsg(last_alert_msg) || "-"}
                />
                <InfoBubbleDetail
                  label="Last Alert Timestamp:"
                  content={getLastAlertStamp(last_alert_stamp) || "-"}
                />
                {infoBubble?.get_info_bubble?.ignition ? (
                  <InfoBubbleDetail
                    label="Ignition:"
                    content={ignition_status || "-"}
                  />
                ) : (
                  ""
                )}
                {infoBubble.get_info_bubble.driver ? (
                  <InfoBubbleDetail
                    label="Driver Name:"
                    content={personnel_name || " - "}
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.battery ? (
                  <InfoBubbleDetail
                    label="Battery:"
                    content={
                      <>
                        <Battery
                          vehicleType={vehicle_type}
                          deviceTypeId={device_type_id}
                          batteryLevel={battery_level}
                          tooltipTitle={
                            <>
                              <Typography className={classes.batteryLevel}>
                                {`Battery Life: ${battery_level}%`}
                              </Typography>
                              <Typography
                                className={classes.batteryReportStamp}
                              >
                                {`As of: ${moment(reportstamp).format("lll")}`}
                              </Typography>
                            </>
                          }
                          showText
                          showNAText
                        />
                      </>
                    }
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.door ? (
                  <InfoBubbleDetail
                    label="Door:"
                    content={door_status || "-"}
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.fuel && has_fuel_sensor ? (
                  <InfoBubbleDetail
                    label="Fuel:"
                    content={fuelconsumption >= 0 ? fuelconsumption : "-"}
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.odometer_reading ? (
                  <InfoBubbleDetail
                    label="Odometer Reading:"
                    content={`${
                      distancetraveled >= 0
                        ? Math.round(distancetraveled / 1000).toLocaleString(
                            "en-US"
                          )
                        : "-"
                    } km`}
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.power_source ? (
                  <InfoBubbleDetail
                    label="Running in auxiliary:"
                    content={
                      !_.isEmpty(powersource)
                        ? powersource === "AUX"
                          ? "Yes"
                          : "No"
                        : "-"
                    }
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.speed ? (
                  <InfoBubbleDetail
                    label="Speed:"
                    content={gpsSpeed >= 0 ? `${gpsSpeed} kph` : "-"}
                  />
                ) : (
                  ""
                )}
                {infoBubble?.get_info_bubble?.temperature && has_temp_sensor ? (
                  <InfoBubbleDetail
                    label="Temperature:"
                    content={<>{temperature >= 0 ? temperature : "-"}&deg;C</>}
                  />
                ) : (
                  ""
                )}
                <InfoBubbleDetail
                  label="Current Location:"
                  content={lastlocation}
                />
                {infoBubble?.get_info_bubble?.coordinates ? (
                  <InfoBubbleDetail
                    label="Coordinates:"
                    content={`${vehicleData.device_info.device_status.latitude},${vehicleData.device_info.device_status.longitude}`}
                  />
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </InfoWindow>
        )}
        <CustomMarker
          getVehiclePosition={positionHandler}
          vehicleDirection={vehicleData.device_info.device_status.heading}
          vehiclePlateNo={vehicleData.plateno}
          zoom={zoomLevel}
          alertLevel={null}
          isMoving={vehicleData.device_info.device_status.gpsSpeed > 0}
          moduleType="MAP_VIEW"
        />
      </Marker>
      <Marker
        options={{
          icon: {
            // path: vehicleSvgIcons[data.vehicle_info.icon.split(".")[0]].path,
            path: Icon.path,
            // .pathWithCircle,
            fillColor: fillColor[powersource], // Icon.fill,
            fillOpacity: 0,
            strokeColor: strokeColor[ignition_status], // Icon.stroke,
            strokeWeight: 0,
            scale: 1.2,
            size: new window.google.maps.Size(22, 17),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(5, 9),
            // labelOrigin: new google.maps.Point(8, 48)
            labelOrigin: new window.google.maps.Point(8, 45)
          },
          // zIndex: 214748364,
          label: {
            color: "#3A3A3A",
            fontWeight: "bold",
            text: plateno,
            fontSize: "12px",
            className: "map-label"
          }
        }}
        position={{
          lat: vehicleData.device_info.device_status.latitude,
          lng: vehicleData.device_info.device_status.longitude
        }}
      />
    </>
  );
};

// Update the count down every second of share link expiration
const TimerCount = props => {
  const screenSize = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const classes = UseMapViewStyles();
  const { expiration, setModal } = props;
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState([{ label: "", value: "" }]);
  useEffect(() => {
    const clock = setInterval(() => {
      // Set counting down date
      const countDown = new Date(expiration)?.getTime();
      // get time and date
      const now = new Date().getTime();
      // subtract the distance between now and expiration date
      const distance = countDown - now;
      // Time calculation
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const second = Math.floor((distance % (1000 * 60)) / 1000);
      const all = `${days}d ${hours}h ${minutes}m ${second}s `;
      if (all > "0") {
        setTimer([
          { label: "Day", value: days },
          { label: "Hour", value: hours },
          { label: "Min", value: minutes },
          { label: "Sec", value: second }
        ]);
        setTime(all);
      } else {
        clearInterval(clock);
        setModal(true);
      }
    }, 1000);
  }, []);

  // return time;
  return (
    <>
      <Box className={classes.timerBox}>
        {!screenSize && (
          <Typography className={classes.timerLabel}>Expires in: </Typography>
        )}
        {timer.map(time => (
          <>
            <div className={classes.timerContainer}>
              <Typography className={classes.timerCount}>
                {time.value}
              </Typography>
              <Typography className={classes.timerBoxText}>
                {time.label}
              </Typography>
            </div>
          </>
        ))}
      </Box>
    </>
  );
};

const BlinkingAlertsOverlay = props => {
  const { map } = props;
  const controlDiv = document.createElement("div");
  const controls =
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM];
  controls.push(controlDiv);

  return createPortal(
    <BlinkingAlertsLegend showAlerts showOutlines margin={10} />,
    controlDiv
  );
};

const MapView = ({ location }) => {
  const classes = UseMapViewStyles();
  const screenSize = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [modal, setModal] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [time, setTime] = useState(moment().format("MM/DD/YY hh:mm:ss"));
  const [hasTripPrebookingAccess, setHasTripPrebookingAccess] = useState(false);
  const mapRef = useRef(null);
  const mapViewRef = useRef();
  const mapStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };
  const defaultCenter = { lat: 14.570863570431083, lng: 120.95194905532837 };

  const locationParams = querystring.parse(location.search);
  const { code } = locationParams;
  const [fetchAclModule] = useLazyQuery(GET_ACL_MODULES_CLIENT);
  const { data: shareLocation } = useQuery(GET_SHARE_LOCATION_V2, {
    variables: {
      code: code
    },
    onCompleted: data => {
      const groupID = data?.get_share_location?.vehicle_ids?.map(String);
      if (data?.get_share_location?.id === null) {
        setInvalid(true);
      } else if (moment(data?.get_share_location?.expiration_date) > moment()) {
        dataLocation({
          variables: {
            code: code,
            condition: [
              {
                field: "vehicle_info.id",
                value: groupID
              }
            ]
          }
        });

        fetchAclModule({
          variables: {
            id: data.get_share_location.client_id,
            code: code
          },
          onCompleted: res => {
            if (res) {
              const { acl_modules } = res.get_client;
              const hasTripsPrebooking =
                acl_modules.filter(
                  acl =>
                    [
                      "/trips/",
                      "/prebooking/",
                      "/allocation/",
                      "/allocation/trip/",
                      "/allocation/vehicle/"
                    ].includes(acl.resources) &&
                    acl.view &&
                    acl.edit
                ).length > 0;
              setHasTripPrebookingAccess(hasTripsPrebooking);
            }
          },
          onError: err => console.log(err)
        });

        linkCount({
          variables: {
            code: code,
            condition: [
              {
                field: "vehicle_info.id",
                value: groupID
              }
            ]
          }
        });
      }
    }
  });

  const [dataLocation, { data, refetch, loading }] = useLazyQuery(
    GET_VEHICLE_INFOS
  );

  const [linkCount, { data: vehicleCount }] = useLazyQuery(GET_VEHICLES_COUNT);

  // Fit bounds function
  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    data.get_vehicles.vehicles.map(vehicle => {
      bounds.extend({
        lat: parseFloat(
          +vehicle.device_info.device_status.latitude || defaultCenter.lat
        ),
        lng: parseFloat(
          +vehicle.device_info.device_status.longitude || defaultCenter.lng
        )
      });
    });

    mapRef.current.state.map.fitBounds(bounds);
  };

  // Fit bounds on mount, and when the markers change
  useEffect(() => {
    if (data && mapRef.current !== null) fitBounds();
  }, [data, mapRef]);

  return (
    <>
      <div className={classes.root}>
        {loading && <Loading />}
        <div style={{ flex: "0 1 auto" }}>
          <AppBar position="static" className={classes.appbar} elevation={0}>
            <Toolbar>
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.logo_header}>
                  <img className={classes.logo} src={Logo} />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.timer_header}>
                  <Box className={classes.timer}>
                    <Typography className={classes.timerHeaderText}>
                      {screenSize && "Share Location link will expire in:"}
                    </Typography>
                    {shareLocation?.get_share_location?.expiration_date && (
                      <TimerCount
                        setModal={setModal}
                        expiration={
                          shareLocation?.get_share_location?.expiration_date
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
        <div style={{ flex: "0 1 auto" }}>
          <div className={classes.info_box}>
            <Box className={classes.left_header}>
              <Box gridGap={5} className={classes.locationBox}>
                <Box className={classes.locationContainer} gridGap={5}>
                  <Tooltip title="refresh" arrow>
                    <IconButton
                      onClick={() => (
                        refetch(), setTime(moment().format("MM/DD/YY hh:mm:ss"))
                      )}
                      color="primary"
                      size="small"
                    >
                      <CachedIcon />
                    </IconButton>
                  </Tooltip>
                  <Typography className={classes.locationTitle}>
                    Location last updated:
                  </Typography>
                  {/* <Button
                    className={
                      !screenSize ? classes.refreshIcon : classes.refresh
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={screenSize && <CachedIcon />}
                    onClick={() => (
                      refetch(), setTime(moment().format("MM/DD/YY hh:mm:ss"))
                    )}
                    style={{
                      margin: "0 0 0 20px",
                      borderRadius: "17px"
                    }}
                  >
                    {!screenSize && <CachedIcon />}
                    <div hidden={!screenSize}>Refresh</div>
                  </Button> */}
                </Box>
                <Typography className={classes.locationDateTime}>
                  {time}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.left_header}>
              {/* Count and details of the vehicles on the map */}
              <VehicleCount
                vehicleCount={vehicleCount}
                hasAccessPrebookingTrips={hasTripPrebookingAccess}
                module="share-location"
              />
            </Box>
          </div>
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <div className={classes.map_container}>
            {!modal && !invalid && (
              <GoogleMap
                ref={mapRef}
                defaultCenter={{
                  lat:
                    data?.get_vehicles?.vehicles[0]?.device_info?.device_status
                      ?.latitude || defaultCenter.lat,
                  lng:
                    data?.get_vehicles?.vehicles[0]?.device_info?.device_status
                      ?.longitude || defaultCenter.lng
                }}
                mapContainerStyle={mapStyle}
                options={loadOptions}
                onLoad={map => {
                  mapViewRef.current = map;
                }}
              >
                {data &&
                  data?.get_vehicles?.vehicles.map((data, index) => (
                    <VehicleMarker
                      key={index}
                      vehicleData={data}
                      zoomLevel={zoomLevel}
                      code={code}
                    />
                  ))}
                {mapViewRef.current && (
                  <BlinkingAlertsOverlay map={mapViewRef.current} />
                )}
                {/* <Map.Control position={control_positions.RIGHT_BOTTOM}>
                  <BlinkingAlertsLegend showAlerts showOutlines margin={10} />
                </Map.Control> */}
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
      {/* Dialog for expired link */}
      <Dialog open={modal} classes={{ paper: classes.dialog_paper }}>
        <DialogTitle>
          <span className={classes.dialog_title}>Link Expired</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialog_content}>
            Please request for a new link to view your vehicles
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Dialog for invalid link */}
      <Dialog open={invalid} classes={{ paper: classes.dialog_paper }}>
        <DialogTitle>
          <span className={classes.dialog_title}>Invalid Link</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialog_content}>
            Please request for a new link to view your vehicles
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MapView;
