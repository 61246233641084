import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  IconButton
  // Divider
} from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@material-ui/icons";
import _ from "lodash";
// import { useQuery } from "@apollo/client";
// import { GET_BOOKINGS } from "../../../graphql/Queries";

const useStyles = makeStyles(theme => ({
  tripDetailsContainer: {
    // padding: "10px",
    maxHeight: "100%",
    height: "100%",
    "& .MuiTypography-subtitle2": {
      fontSize: "0.75rem",
      letterSpacing: 1.2
    }
  },
  tripDetailsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "10%",
    boxShadow: "0px 0px 20px -15px #bdbdbd",
    "& .MuiIconButton-root": {
      padding: 5
    }
  },
  tripDetailsStatus: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "90%",
    maxHeight: "90%",
    height: "90%"
  },
  tripDetailsBody: {
    padding: "15px 20px",
    overflowY: "auto",
    minHeight: "90%",
    maxHeight: "90%",
    width: "100%",
    boxSizing: "border-box",
    "& > div": {
      marginBottom: "10px"
    }
  },
  tripDetailsInfo: {
    display: "block"
  }
}));

const TripDetails = prop => {
  const classes = useStyles();
  const {
    // selectedTrip: { start, end, device_id },
    selectedTrip,
    handleSelectedTrip
  } = prop;

  const [tripDetails, setTripDetails] = useState({
    tripNumbers: [],
    serviceTypes: [],
    soNumbers: []
  });
  useEffect(() => {
    const tripNumbers = [];
    const serviceTypes = [];
    const soNumbers = [];
    selectedTrip.forEach(booking => {
      const { geofenceTripNumber, service_type, so_number } = booking;
      if (geofenceTripNumber) tripNumbers.push(geofenceTripNumber);

      if (service_type) serviceTypes.push(service_type);

      if (so_number) soNumbers.push(so_number);
    });
    setTripDetails({
      tripNumbers: _.uniq(tripNumbers),
      serviceTypes: _.uniq(serviceTypes),
      soNumbers: _.uniq(soNumbers)
    });
  }, [selectedTrip]);
  // const { data, loading } = useQuery(GET_BOOKINGS, {
  //   fetchPolicy: "cache-and-network",
  //   onCompleted: response => {
  //     const { bookings } = response.get_bookings;
  //     const tripNumbers = [];
  //     const serviceTypes = [];
  //     const soNumbers = [];
  //     bookings.forEach(booking => {
  //       const { trip_number, service_type, so_number } = booking;
  //       tripNumbers.push(trip_number);
  //       serviceTypes.push(service_type);
  //       soNumbers.push(so_number);
  //     });
  //     setTripDetails({
  //       tripNumbers: _.uniq(tripNumbers),
  //       serviceTypes: _.uniq(serviceTypes),
  //       soNumbers: _.uniq(soNumbers)
  //     });
  //   },
  //   variables: {
  //     condition: [
  //       {
  //         field: "vehicle_id",
  //         value: device_id
  //       }
  //     ],
  //     dateRange: {
  //       field: "pickups.arrival",
  //       start: start || end,
  //       end: end || start
  //     }
  //   }
  // });

  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.tripDetailsContainer}
    >
      <Grid item md={12} className={classes.tripDetailsHeader}>
        <IconButton onClick={() => handleSelectedTrip(null)}>
          <KeyboardArrowLeftIcon size="small" />
        </IconButton>
        <Typography variant="subtitle2">
          <strong>TRIP DETAILS</strong>
        </Typography>
      </Grid>
      {/* {loading ? (
        <Grid
          className={classes.tripDetailsStatus}
          container
          direction="column"
          item
          md={12}
        >
          <Typography variant="caption">Loading...</Typography>
        </Grid>
      ) : (
        <> */}
      {// data?.get_bookings?.count === 0 ?
      _.isEmpty(tripDetails.tripNumbers) &&
      _.isEmpty(tripDetails.serviceTypes) &&
      _.isEmpty(tripDetails.soNumbers) ? (
        <Grid
          className={classes.tripDetailsStatus}
          container
          direction="column"
          item
          md={12}
        >
          <Typography variant="caption">No Trip Details</Typography>
        </Grid>
      ) : (
        <div className={classes.tripDetailsBody}>
          <div>
            <Typography variant="subtitle2">
              <strong>Trip Number:</strong>
            </Typography>
            {!_.isEmpty(tripDetails.tripNumbers) ? (
              tripDetails?.tripNumbers?.map((tripNumber, index) => (
                <Typography
                  key={`tripnumbers-${index}`}
                  className={classes.tripDetailsInfo}
                  variant="caption"
                  gutterBottom
                >
                  {tripNumber}
                </Typography>
              ))
            ) : (
              <Typography
                className={classes.tripDetailsInfo}
                variant="caption"
                gutterBottom
              >
                -
              </Typography>
            )}
          </div>
          <div>
            <Typography variant="subtitle2">
              <strong>Service Type:</strong>
            </Typography>
            {!_.isEmpty(tripDetails.serviceTypes) ? (
              tripDetails?.serviceTypes?.map((serviceType, index) => (
                <Typography
                  key={`serviceType-${index}`}
                  className={classes.tripDetailsInfo}
                  variant="caption"
                  gutterBottom
                >
                  {serviceType}
                </Typography>
              ))
            ) : (
              <Typography
                className={classes.tripDetailsInfo}
                variant="caption"
                gutterBottom
              >
                -
              </Typography>
            )}
          </div>
          <div>
            <Typography variant="subtitle2">
              <strong>SO number:</strong>
            </Typography>
            {!_.isEmpty(tripDetails.soNumbers) ? (
              tripDetails?.soNumbers?.map((soNumber, index) => (
                <Typography
                  key={`so-${index}`}
                  className={classes.tripDetailsInfo}
                  variant="caption"
                  gutterBottom
                >
                  {soNumber}
                </Typography>
              ))
            ) : (
              <Typography
                className={classes.tripDetailsInfo}
                variant="caption"
                gutterBottom
              >
                -
              </Typography>
            )}
          </div>
        </div>
      )}
      {/* </>
      )} */}
    </Grid>
  );
};

export default TripDetails;
