

import React, { useState } from 'react'

const useDialog = () => {
  const [dialog, setDialog] = useState({
    save: false,
    discard: false,
    deleteTrip: false,
    insufficient: false
  });

  const handleDialog = (type, open=true) => {
    setDialog({ ...dialog, [type]: open });
  }

  const handleCloseDialog = type => {
    setDialog({ ...dialog, [type]: false });
  };

  return { dialog, setDialog, handleDialog, handleCloseDialog };
}

export default useDialog