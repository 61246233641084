import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
// Table
  root: {
    width: "100%",
    height: "100%",
    overflowX: "auto"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%"
    // marginBottom: theme.spacing(2)
  },
  fabIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    width: "50px",
    height: "50px",
    color: "white"
  },
  license: {
    textAlign: "left",
    marginBottom: "20px",
    fontSize: "14px"
  },

// Add Driver
  container: {
    height: "calc(100% - 66px)",
    // margin: theme.spacing(2, 4),
    flexGrow: 1,
    boxSizing: "border-box",
    padding: theme.spacing(3),
    overflow: "auto",
    background: "#F8F8F8"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: "30px",
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  mediumTitle: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "black",
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "80%",
    marginTop: "6px 0"
  },
  button: {
    borderRadius: "20px",
    fontSize: "1rem",
    color: "white",
    // margin: "0 auto 90px 0",
    width: "200px"
  },
  textfields: {
    width: "80%"
  },
  unpairBox: {
    // padding: "2px 4px",
    display: "flex",
    alignItems: "end"
  },
  unpairIcon: {
    marginLeft: -45
  },

  // Styles for PairDriver component
  field: {
    margin: "18px 0 0 0",
    width: "100%",
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      opacity: 1
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "12px 0"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#CBCBCB"
    }
  },
  textFieldRoot: {
    "& input": {
      fontSize: "12px"
      //   padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "12px",
      padding: "12px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "12px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    }
  },
  code: {
    color: "black",
    fontWeight: "bold",
    padding: "24px 0 0 0"
  },
  successAlert: {
    margin: "12px 0",
    fontSize: "12px",
    "& .MuiAlert-icon": {
      fontSize: 18
    }
  },
  alertPaper: {
    padding: "0px 4px"
    // margin: "16px 0px"
  },
  dialogContainer: {
    borderRadius: 8,
    minHeight: 450,
    maxWidth: 350
  },
  codeGenerated: {
    minWidth: 130,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "0.25rem",
    borderRadius: 20,
    margin: "12px 0",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      opacity: "0.7"
    }
    // "&.MuiButton-contained.Mui-disabled": {
    //   minWidth: 130,
    //   textTransform: "none",
    //   backgroundColor: theme.palette.primary.main,
    //   color: "#ffffff",
    //   padding: "0.25rem",
    //   borderRadius: 20,
    //   margin: "12px 0"
    // }
  },
  closeBtn: {
    minWidth: 130,
    textTransform: "none",
    color: "#ffffff",
    padding: "0.25rem",
    borderRadius: 20,
    margin: "20px 0",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white !important",
      opacity: "0.7"
    }
  },
  dialogAction: {
    "&.MuiDialogActions-root": {
      flex: "0 0 auto",
      display: "flex",
      padding: "8px",
      alignItems: "center",
      justifyContent: "space-around"
    }
  },
  codeLabel: {
    fontWeight: "bold",
    color: "black",
    display: "flex",
    alignItems: "end",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(1px, -25px) scale(0.75)"
    }
  },
  copy: {
    marginLeft: -45
  },
  formCode: {
    margin: "40px 0 0 0",
    width: "100%"
  },
  outlinedInput: {
    height: "35px"
  },
  contentText: {
    "&.MuiDialogContentText-root": {
      marginBottom: "12px",
      fontSize: "14px"
    }
  }
}));
