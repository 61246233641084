/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import AccessControl from "../../../../../utils/AccessControl";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modal/ConfirmationDialog";
import { useStyles } from "./StandardGeofence.styles";
import GeofenceDetails from "./Components/GeofenceDetails";
import MapComponent from "./Components/MapComponent";
import useUserContext from "../../../../../context/User/useUserContext";
import { getGeofenceTypeColor } from "../../../../Utils";
import { ADD_GEOFENCE } from "../../../../../graphql/Mutations";
import AddLogs from "../../../../../utils/functions/AddLogs";

const DEFAULT_ZOOM = 17;

const StandardGeofence = props => {
  const classes = useStyles();
  const mapStateRef = useRef();
  const user = useUserContext();
  const history = useHistory();
  const { state: locationState } = history.location;
  const requiredFields = [
    "name",
    "geofence_code",
    "category",
    "address",
    "lat",
    "lon"
  ];
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    name: "",
    geofence_code: "",
    window_time_start: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    window_time_end: moment("2013-02-08 00:00").format("YYYY-MM-DD HH:mm:ss"),
    category: "",
    address: "",
    location: locationState?.location || { lat: 14.5995, lon: 120.9842 },
    barangay: "",
    municipality: "",
    province: "",
    region: "",
    zoom: DEFAULT_ZOOM.toString(),
    speedLimit: {
      value: 0,
      custom: 0
    }
  });
  const [dialog, setDialog] = useState({
    save: false,
    discard: false
  });

  const [addGeofence] = useMutation(ADD_GEOFENCE, {
    onCompleted(data) {
      if (data?.add_geofence?.success) {
        AddLogs("Admin - Geofences", "insert", state.name);
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            if (locationState?.source) {
              // history.push({
              //   pathname: locationState.source,
              //   state: locationState
              // });
              history.push(locationState.source);
            } else {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState?.vars,
                    filter: locationState?.filter
                  }
                }
              });
            }
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            if (locationState?.source) {
              // history.push({
              //   pathname: locationState.source,
              //   state: locationState
              // });
              history.push(locationState.source);
            } else {
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState?.vars,
                    filter: locationState?.filter
                  }
                }
              });
            }
          }
        });
      } else {
        const temp = {};
        data.add_geofence.error.map(err => {
          Object.assign(temp, { [err.field]: err.message });
          if (err.message === "The name already exists") {
            temp.name = "Geofence name already exists";
          }
        });
        setErrors(temp);
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            if (locationState?.source) {
              history.push(locationState.source);
              // history.push({
              //   pathname: locationState.source,
              //   state: locationState
              // });
            } else {
              // set values again for geofence table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState?.vars,
                    filter: locationState?.filter
                  }
                }
              });
            }
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            if (locationState?.source) {
              history.push({
                pathname: locationState.source,
                state: locationState
              });
            } else {
              // set values again for geofence table filter after adding
              history.push({
                pathname: "/admin",
                state: {
                  params: {
                    moduleName: process.env.REACT_APP_GEOFENCES_MODULE,
                    vars: locationState?.vars,
                    filter: locationState?.filter
                  }
                }
              });
            }
          }
        });
      }
    }
  });

  const handleOpen = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: true });
  };

  const handleClose = dialog_name => {
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const check = field => {
    const isLocation = ["lat", "lon"].includes(field);
    if (
      (isLocation && !state.location[field]) ||
      (!isLocation && !state[field])
    ) {
      if (requiredFields.includes(field)) {
        return "This field is required";
      }
      return errors[field];
    }
    return errors[field];
  };

  const validateErrors = () => {
    let tempErrors = _.cloneDeep(errors);
    const temp = requiredFields.map(element => {
      return { [element]: check(element) };
    });
    if (mapStateRef.current?.geom) {
      temp.push({ geofence: "" });
    } else {
      temp.push({ geofence: "Geofence drawing is required" });
    }

    tempErrors = Object.assign(tempErrors, ...temp);
    setErrors(tempErrors);

    if (
      Object.keys(tempErrors)
        .filter(key => typeof tempErrors[key] !== "object")
        .every(element => !tempErrors[element])
    ) {
      handleOpen("save");
    }
  };

  const handleSubmit = () => {
    const { group_ids } = user.client;
    addGeofence({
      variables: {
        address: state.address,
        name: state.name,
        geofence_code: state.geofence_code?.toString()?.trim(),
        category: state.category,
        barangay: state.barangay,
        municipality: state.municipality,
        province: state.province,
        region: state.region,
        window_time_start: state.window_time_start,
        window_time_end: state.window_time_end,
        type: "multipolygon",
        location: state.location,
        radius: mapStateRef.current.radius,
        group_ids: group_ids,
        opacity: mapStateRef.current.opacity,
        color: getGeofenceTypeColor(state.category),
        geom: mapStateRef.current.geom,
        geofence_geom_id: mapStateRef.current.geofence_geom_id,
        speed_limit:
          state.speedLimit.value === "Custom"
            ? +state.speedLimit.custom
            : +state.speedLimit.value
      }
    });
  };

  const renderMapComponent = useMemo(
    () => (
      <MapComponent
        state={state}
        setState={setState}
        editMode
        mapStateRef={mapStateRef}
      />
    ),
    [state.location, state.zoom, state.category, state.address]
  );

  const { pathname } = props.location;
  return (
    <AccessControl resource={pathname} process="add">
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_GEOFENCES_MODULE}
          locationState={locationState}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid container spacing={6} style={{ height: "calc(100% - 38px)" }}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ minHeight: "500px", maxHeight: "690px" }}
              >
                {renderMapComponent}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", marginBottom: "16px" }}
                >
                  <Typography>All fields with * are required</Typography>
                </Grid>
                <GeofenceDetails
                  state={state}
                  setState={setState}
                  errors={errors}
                  setErrors={setErrors}
                  mapStateRef={mapStateRef}
                  editMode
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => validateErrors()}
                  >
                    Save Details
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={handleSubmit}
          title="Save"
          content="Are you sure you want to save geofence?"
        />
      </div>
    </AccessControl>
  );
};

export default StandardGeofence;
