import {
  ApolloClient,
  InMemoryCache,
  split,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import Cookie from "js-cookie";
import { createUploadLink } from 'apollo-upload-client';

const token = localStorage.getItem("token");

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEBSOCKET_URL,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: token ? `Bearer ${token}` : ""
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const auth = Cookie.get("token");
  return {
    headers: {
      ...headers,
      authorization: auth ? `Bearer ${auth}` : ""
    }
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_URL
});
// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_WEBSOCKET_URL
// });

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  // authLink.concat(httpLink)
  authLink.concat(uploadLink)
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
