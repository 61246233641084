/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  Grid,
  Badge,
  CircularProgress,
  Fab
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  Block as BlockIcon,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import lodash from "lodash";
import Cookie from "js-cookie";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccessControl from "../../../../utils/AccessControl";
import Loading from "../../../../utils/Loading";
import ErrorPage from "../../../../utils/errorPage";
import SearchContext from "../../../../context/SearchContext";
import Filter from "../../Filter";
import validate from "../../../../utils/validation";
import {
  GET_USERS,
  GET_USER_LEVELS,
  GET_ADMIN_CLIENT_WO_GROUPS,
  GET_GEOFENCES,
  GET_ACCESS_OBJECTS,
  GET_USER_LEVEL
} from "../../../../graphql/Queries";
import {
  DELETE_USER,
  ADD_USERS,
  DELETE_USER_ALL,
  UNBLOCK_USER,
  UNBLOCK_USER_ALL
} from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import client from "../../../../Client";
import AddLogs from "../../../../utils/functions/AddLogs";
import Pagination from "../../Pagination";
import useFormStyles from "../../../../styles/useFormStyles";
import PaperCount from "../../../../utils/UI/Paper/PaperCount";
import debounce from "../../../../utils/functions/debounce.util";
import useUserContext from "../../../../context/User/useUserContext";
import _ from "lodash";

const Highlight = require("react-highlighter");

function desc(a, b, orderBy) {
  const first =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const second =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username"
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "Full Name"
  },
  {
    id: "user_level_id",
    numeric: false,
    disablePadding: false,
    label: "Access Right"
  },
  {
    id: "email_address",
    numeric: false,
    disablePadding: false,
    label: "Email Address"
  },
  {
    id: "contact_number",
    numeric: true,
    disablePadding: false,
    label: "Contact Number"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white",
    backgroundColor: "#ffb677"
  },

  btn_upload: {
    backgroundColor: "#FFB74D",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,

    "&:hover": {
      backgroundColor: "#fe9700"
    }
  },
  btn_download: {
    backgroundColor: "#FF845E",
    borderRadius: "17px",
    color: "#FFFFFF",
    margin: 10,
    "&:hover": {
      backgroundColor: "#ff5723"
    }
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  icon: {
    opacity: 1
  },
  link: {
    color: "#757575"
  },
  dialog: {
    borderRadius: "10px"
  },
  loading: {
    width: 300,
    height: 300
  },
  statusUploading: {
    color: "#3f3f3f"
  },
  statusFailed: {
    color: "#FF7043"
  },
  statusSuccessful: {
    color: "#10E23F"
  }
}));

function EnhancedTableHead(props) {
  const {
    canEdit,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    handleSort,
    isChecked
  } = props;

  return (
    <TableHead
      style={{
        borderTop: "solid #e5e5e5",
        borderBottom: "solid #e5e5e5",
        borderWidth: "1px"
      }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={!canEdit}
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() =>
                handleSort(
                  headCell.id === "full_name" ? "first_name" : headCell.id
                )
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: "flex",
    position: "relative"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { children } = props;

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Users
      </Typography>
      <div style={{ display: "flex", position: "absolute", right: "10px" }}>
        {children}
      </div>
    </Toolbar>
  );
};

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const DlMenu = React.memo(props => {
  const {
    anchorEl,
    open,
    handleClosePopper,
    handleListKeyDown,
    tableData,
    tempFilter
  } = props;

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  onClick={() =>
                    AddLogs("Admin - Users", "download_template", "")
                  }
                >
                  <Link
                    to="/assets/user_upload_template.xls"
                    target="_blank"
                    download
                    underline="none"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Download Template
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    AddLogs(
                      "Admin - Users",
                      "download_table",
                      `Access Rights: ${
                        tempFilter.user_level_id.name
                          ? `${tempFilter.user_level_id.name}, `
                          : `All`
                      }User State: ${
                        tempFilter.user_status
                          ? `${tempFilter.user_status}`
                          : `All`
                      }`
                    )
                  }
                >
                  <CSVLink
                    data={tableData}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Download Table
                  </CSVLink>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

function UploadDialog(props) {
  const classes = useStyles();
  // const user = JSON.parse(Cookie.get("user"));
  const user = useUserContext();
  const {
    uploadDialog,
    handleCloseUpload,
    refetch,
    getVariables,
    clientLimit,
    userTotal
  } = props;
  const [file, setFile] = useState([]);
  const [validIndex, setValidIndex] = useState([]);
  const [re, setRe] = useState(false);
  const [uploadBtn, setUploadBtn] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  // const { loading } = useQuery(GET_USER_LEVELS);
  const [geofenceList, geofenceListResult] = useLazyQuery(GET_GEOFENCES);
  const accessRight = useRef({ row: [] });

  const [addUsers] = useMutation(ADD_USERS, {
    onCompleted(data) {
      AddLogs("Admin - Users", "upload", ``);
      const { add_users } = data;
      const temp = file;
      const errors = add_users.map(val => val.error);
      let status = "Failed";
      if (
        add_users.every(res => res.success) &&
        temp[file.length - 1].errors.length === 0
      ) {
        status = "Success";
      } else if (
        add_users.some(res => res.success) ||
        (add_users.every(res => res.success) &&
          temp[file.length - 1].errors.length > 0)
      ) {
        status = "Partial Success";
      }
      const errorszxc = errors.map(val => {
        const temp = val.map(errormsg => Object.values(errormsg)[1]);
        return temp;
      });
      const tempArr = [];
      errorszxc.map((val, index) =>
        tempArr.push({ row: validIndex[index], errors: lodash.join(val, ", ") })
      );
      const zxc = lodash.concat(temp[file.length - 1].errors, tempArr);
      temp[file.length - 1] = {
        ...temp[file.length - 1],
        status: status,
        errors: zxc
      };

      setFile(temp);
      setRe(!re);
      refetch(getVariables);
    }
  });

  const getUserLevelId = async user_level => {
    const response = await client.query({
      query: GET_USER_LEVELS,
      variables: {
        condition: [
          {
            field: "name",
            value: user_level
          }
        ]
      }
    });

    const result = await response.data;
    const userlevelid = await result.get_user_levels.userlevels;
    // // const userlevelid = userleveldata.get_user_levels.userlevels.filter(
    // //   userlevel => userlevel.name === user_level
    // // )[0];
    if (userlevelid) {
      return await userlevelid[0].id;
    }
    return "";
  };
  // eslint-disable-next-line consistent-return
  const getGEofenceId = value => {
    if (geofenceListResult.data.get_geofences.geofences) {
      const geo_id = geofenceListResult.data.get_geofences.geofences.filter(
        row => row.name.toLowerCase() === value.toString().toLowerCase()
      )[0];
      if (geo_id) {
        return geo_id.id;
      }
    } else {
      return "";
    }
  };

  const validateCell = async (key, value) => {
    let val = "";
    let tempId;
    let geofence_id;
    switch (key) {
      case "client_id":
        if (!value) {
          val = { error: "Client is required" };
        } else {
          val =
            value === user.client.name
              ? user.client.id
              : { error: "Client does not exist" };
        }
        break;
      case "username":
        if (!value) {
          val = { error: "Username is required" };
        } else {
          val = validate("alphanumeric", value)
            ? value.toString()
            : { error: "Username invalid format" };
        }
        break;
      case "first_name":
        if (!value) {
          val = { error: "First name is required" };
        } else {
          val = validate("letters", value)
            ? value
            : { error: "First name is invalid format" };
        }
        break;
      case "last_name":
        if (!value) {
          val = { error: "Last Name is required" };
        } else {
          val = validate("letters", value)
            ? value
            : { error: "Last name is invalid format" };
        }
        break;
      case "email_address":
        if (!value) {
          val = { error: "Email Address is required" };
        } else {
          val = validate("email", value)
            ? value
            : { error: "Email address is invalid format" };
        }
        break;
      case "contact_number":
        if (!value) {
          val = { error: "Contact Number is required" };
        } else if (validate("numeric", value)) {
          if (
            value.toString().length === 12 &&
            value.toString().substring(0, 2) === "63"
          ) {
            val = value.toString();
          } else {
            val = { error: "Contact Number is invalid format" };
          }
        } else {
          val = { error: "Contact Number is invalid format" };
        }
        break;
      case "user_level_id":
        if (!value) {
          val = { error: "Access right is required" };
        } else if (value.toLowerCase() === "data admin") {
          val = { error: "Unable to access this Access Rights" };
        } else {
          tempId = await getUserLevelId(value);
          val =
            tempId === ""
              ? {
                  error: "Access right does not exist"
                }
              : tempId;
        }
        break;
      case "geofence_id":
        if (!value) {
          val = { error: "Geofence name is required" };
        } else {
          geofence_id = getGEofenceId(value);
          val =
            geofence_id === ""
              ? {
                  error: "Geofence does not exist"
                }
              : geofence_id;
        }
        break;
      default:
        break;
    }
    return val;
  };

  const parseRow = async array => {
    const temp = {};
    // process.env.REACT_APP_USERS_KEYS.split(",").forEach( async (key, index) => {
    //   if (key === "geofence_id") {
    //     // if (
    //     //   accessRight.current.name.length === accessRight.current.row.length
    //     // ) {
    //     for (let a = 0; a < accessRight.current.row.length; a += 1) {
    //       if (array[1] === accessRight.current.row[a].username && array[6].toLowerCase() === accessRight.current.row[a].name.toLowerCase()) {
    //         if (
    //           accessRight?.current?.row[a]?.permission?.view === true &&
    //           accessRight?.current?.row[a]?.permission?.edit === true
    //         ) {
    //           Object.assign(temp, { [key]: await validateCell(key, array[index]) });
    //         } else if (array[7]) {
    //           Object.assign(temp, {user_level_id: { error: "Users has no store app access." }});
    //         }
    //       }
    //     }
    //     // }
    //   } else {
    //     Object.assign(temp, { [key]: await validateCell(key, array[index]) });
    //   }
    // });
    await Promise.all(
      process.env.REACT_APP_USERS_KEYS.split(",").map(async (key, index) => {
        const validatedCell = await validateCell(key, array[index]);
        if (key === "geofence_id") {
          // if (
          //   accessRight.current.name.length === accessRight.current.row.length
          // ) {
          for (let a = 0; a < accessRight.current.row.length; a += 1) {
            if (
              array[1] === accessRight.current.row[a].username // &&
              // array[6].toLowerCase() ===
              //   accessRight.current.row[a].name.toLowerCase()
            ) {
              if (
                accessRight?.current?.row[a]?.permission?.view === true &&
                accessRight?.current?.row[a]?.permission?.edit === true
              ) {
                Object.assign(temp, { [key]: validatedCell });
              } else if (array[7]) {
                Object.assign(temp, {
                  user_level_id: { error: "Users has no store app access." }
                });
              }
            }
          }
          // }
        } else {
          Object.assign(temp, { [key]: validatedCell });
        }
      })
    );

    const groups = [];
    const { group_ids, group_names } = user;
    for (let i = 0; i < group_ids.length; i += 1) {
      groups.push({ id: group_ids[i], name: group_names[i] });
    }
    Object.assign(temp, { groups: groups });
    return temp;
  };

  const handleUpload = e => {
    const { files } = e.target;
    setFile([...file, { file: files[0], status: "", errors: [] }]);
    if (files[0]) {
      setUploadBtn(true);
      accessRight.current = { row: [] };
      const f = files[0];
      const reader = new FileReader();

      reader.onload = async e => {
        const dataRes = new Uint8Array(e.target.result);
        const workbook = XLSX.read(dataRes, { type: "array" });
        const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const datz = XLSX.utils.sheet_to_json(first_worksheet, {
          header: 1,
          blankrows: false
        });
        const geofenceNames = [];
        const client_id = [];
        const access = { id: [], name: [] };
        const usernames = [];

        for (let i = 1; i <= datz.length - 1; i += 1) {
          if (datz[i][7] !== undefined) {
            // Geofence
            geofenceNames.push(datz[i][7].toString());
          }
          if (datz[i][0] !== undefined) {
            // Client
            client_id.push(
              datz[i][0].toLowerCase() === user.client.name.toLowerCase()
                ? user.client.id
                : ""
            );
          }
          if (datz[i][6] !== undefined) {
            // Role
            access.id.push(await getUserLevelId(datz[i][6]));
            access.name.push(datz[i][6]);
            usernames.push(datz[i][1]);
          }
        }

        for (let a = 0; a < access.id.length; a += 1) {
          if (client_id[a] !== "") {
            const get_variables = {
              user_level_id: parseInt(access.id[a], 10),
              group_ids: [parseInt(client_id[a], 10)]
            };
            // new Promise(async(resolve) => {
            client
              .query({
                query: GET_ACCESS_OBJECTS,
                variables: get_variables
              })
              .then(async result => {
                result.data.get_access_objects.map(async row => {
                  if (row.name.toLowerCase() === "store app") {
                    // if (row.permission.view)
                    accessRight.current.row.push({
                      username: usernames[a],
                      name: access.name[a],
                      ...row
                    });
                  }
                });
              });
            // })
          }
        }
        geofenceList({
          variables: { filter: { field: "name", value: geofenceNames } }
        });
        setUploadBtn(false);
      };
      reader.readAsArrayBuffer(f);
    }
    e.target.value = null;
  };

  const submitUpload = () => {
    setIsUploading(true);
    const tempFile = file;
    const f = file[file.length - 1].file;
    const reader = new FileReader();
    reader.onload = async e => {
      const dataRes = new Uint8Array(e.target.result);
      const workbook = XLSX.read(dataRes, { type: "array" });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const datz = XLSX.utils.sheet_to_json(first_worksheet, {
        header: 1,
        blankrows: false
      });
      const tempBatchData = [];
      const fileErrors = [];
      const validIndex = [];

      if (f.size / 1024 / 1024 > 5) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is too large"
        };
        setFile(tempFile);
        setRe(!re);
        setIsUploading(false);
        return;
      }

      if (
        datz.length === 0 ||
        datz[0].length !== process.env.REACT_APP_USERS_KEYS.split(",").length
      ) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "Please use the template provided"
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (datz.length === 1) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is empty"
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (datz.length > 1 && datz.length - 1 + userTotal > clientLimit) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "User limit exceeded"
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
        return;
      }

      for (let i = 1; i <= datz.length - 1; i += 1) {
        const temp = await parseRow(datz[i]);
        const tempErrors = [];
        if (
          Object.values(temp).every(value => Object.keys(value)[0] !== "error")
        ) {
          tempBatchData.push(temp);
          validIndex.push(i + 1);
        } else {
          Object.values(temp)
            .filter(value => Object.keys(value)[0] === "error")
            .map(value => tempErrors.push(value.error));
          fileErrors.push({
            row: i + 1,
            errors: lodash.join(tempErrors, ", ")
          });
        }
      }
      if (tempBatchData.length < 1) {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Failed",
          errors: fileErrors
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
      } else {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Uploading",
          errors: fileErrors
        };
        setIsUploading(false);
        setFile(tempFile);
        setValidIndex(validIndex);
        addUsers({ variables: { users: tempBatchData } });
      }
      // if (
      //   datz[0].length !== process.env.REACT_APP_USERS_KEYS.split(",").length
      // ) {
      //   temp[file.length - 1] = { ...temp[file.length - 1], status: "Error" };
      //   setFile(temp);
      //   setRe(!re);
      //   return;
      // }
      // for (let i = 1; i <= datz.length - 1; i += 1) {
      //   const parsedRow = parseRow(datz[i]);
      //   const rowError = { row: i, errors: {} };
      //   if (
      //     Object.values(parsedRow).filter(
      //       val => typeof val === "object" && Object.keys(val)[0] === "error"
      //     ).length === 0
      //   ) {
      //     batchData.push(parsedRow);
      //   } else {
      //     Object.keys(parsedRow)
      //       .filter(
      //         key =>
      //           typeof parsedRow[key] === "object" &&
      //           Object.keys(parsedRow[key])[0] === "error"
      //       )
      //       .forEach(key =>
      //         Object.assign(rowError.errors, {
      //           [key]: parsedRow[key].message
      //         })
      //       );

      //     fileErrors.push(rowError);
      //   }
      // }

      // let status = "Failed";
      // if (batchData.length > 0) {
      //   addUsers({
      //     variables: {
      //       users: batchData
      //     }
      //   });
      //   status = "Uploading";
      // }

      // temp[file.length - 1] = {
      //   ...temp[file.length - 1],
      //   errors: fileErrors,
      //   status: status
      // };
      // setFile(temp);
      // setRe(!re);
    };
    reader.readAsArrayBuffer(f);
  };

  const pendingFile = () => {
    if (file.length > 0) {
      if (!file[file.length - 1].status) {
        return true;
      }
    }
    return false;
  };

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const [expanded, setExpanded] = useState(-1);

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  // if (loading) return <Loading />;
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleCloseUpload}
      aria-labelledby="customized-dialog-title"
      open={uploadDialog}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
        Upload
      </DialogTitle>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContent,
          dividers: classes.dialogContent
        }}
      >
        {file.length > 0 &&
          file.map((fileInfo, index) => (
            <ExpansionPanel
              key={index}
              square
              expanded={index === expanded}
              onClick={() => handleClick(index)}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  (fileInfo.status === "Failed" ||
                    fileInfo.status === "Partial Success") && <ExpandMoreIcon />
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography className={classes.typography}>
                      {fileInfo.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className={classes.typography}>
                      {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {isUploading && file.length - 1 === index ? (
                      <CircularProgress size={12} />
                    ) : (
                      <Typography
                        className={`${classes.typography} ${
                          fileInfo.status === "Partial Success" ||
                          fileInfo.status === "Failed"
                            ? classes.statusFailed
                            : classes.statusSuccessful
                        }`}
                      >
                        {fileInfo.status}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {(fileInfo.status === "Failed" ||
                fileInfo.status === "Partial Success") && (
                <ExpansionPanelDetails
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {typeof fileInfo.errors === "string" ? (
                    <Typography style={{ fontSize: 14, margin: 8 }}>
                      {fileInfo.errors}
                    </Typography>
                  ) : (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "100px" }}>Row</TableCell>
                          <TableCell align="left">Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fileInfo.errors.length > 0 &&
                          fileInfo.errors
                            .sort(reorder)
                            .filter(row => row.errors !== "")
                            .map(row => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  <Typography className={classes.typography}>
                                    {row.row}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.typography}>
                                    {row.errors}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  )}
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>

            // <div style={{ display: "flex" }}>
            //   <Typography className={classes.typography}>
            //     {fileInfo.file.name}
            //   </Typography>
            //   <div style={{ flexGrow: 1 }} />
            //   <Typography className={classes.typography}>
            //     {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
            //   </Typography>
            //   <Typography className={classes.typography}>
            //     {fileInfo.status}
            //   </Typography>
            // </div>
          ))}
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%" }}>
          <label htmlFor="icon-upload-file">
            <input
              className="form-control"
              color="primary"
              accept=".csv, .xlsx, .xls"
              type="file"
              id="icon-upload-file"
              style={{ display: "none" }}
              onChange={e => handleUpload(e)}
            />
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              style={{ backgroundColor: "#ffb677" }}
              startIcon={<PublishIcon />}
              disabled={pendingFile()}
            >
              Add a File
            </Button>
          </label>
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              autoFocus
              color="primary"
              startIcon={<CloudUploadIcon />}
              style={{ color: "white", borderRadius: 50 }}
              onClick={submitUpload}
              disabled={uploadBtn}
            >
              Upload
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

function Users(props) {
  const classes = useStyles();
  const formStyles = useFormStyles();
  // const context = useContext(LayoutContext);
  const { history } = props;
  const contextData = useContext(SearchContext);
  const user = useUserContext();
  const [clearFirst, setClearFirst] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");
  const [selected, setSelected] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [filter, setFilter] = useState({
    user_level_id: {
      name: "",
      id: ""
    },
    user_status: ""
  });
  const [tempFilter, setTempFilter] = useState({
    user_level_id: {
      name: "",
      id: ""
      // label: "",
      // value: ""
    },
    user_status: ""
  });
  // const [client, setClient] = useState("");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [dialog, setDialog] = useState({
    unblock: false,
    delete: false,
    delete_all: false,
    unblock_all: false
  });
  const [uploadDialog, setUploadDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [blockSelect, setBlockSelect] = useState("");
  const [currentNumber, setCurrentNumber] = useState(0);
  const [keyLog, setKeyLog] = useState([]);
  const [userLevels, setUserLevels] = useState([]);
  const [selBlock, setSelBlock] = useState([]); // set selected users to block (Activity Logs multiple block)
  const [selDelete, setSelDelete] = useState([]); // set selected users to delete (Activity Logs multiple delete)
  const [circularLoading, setCircularLoading] = useState(false); // eslint-disable-line
  const { setSearchKeyword, searchKeyword } = contextData;
  // to get filter values
  let variable = history?.location?.state?.params;

  const isWTIClient = _.isEqual(
    +user.client.id,
    +process.env.REACT_APP_WTI_CLIENT_ID
  );

  // const [logUserLevel, setLogUserLevel] = useState();
  // const { data: loginUserLevel } = useQuery(GET_USER_LEVEL, {
  //   variables: {
  //     id: user.user_level_id
  //   },
  //   fetchPolicy: "network-only"
  // });
  // useEffect(() => {
  //   if (loginUserLevel) {
  //     setLogUserLevel(loginUserLevel.get_user_level.name);
  //   }
  // }, [loginUserLevel]);

  const getAccess = level => {
    let access = false;
    if (level) {
      if (
        level.toLowerCase() === "data admin" ||
        user.user_level_id === process.env.REACT_APP_WTI_ADMIN
      ) {
        access = true;
      } else {
        access = false;
      }
    }
    return access;
  };

  const getVariables = () => {
    let variables = {};
    let condition = [];

    if (isWTIClient) {
      variables = {
        keyword: keyword,
        skip: 0,
        first: rowsPerPage,
        orderBy: [{ field: orderBy, direction: order }],
        condition: condition
        // filter: [
        //   { field: "user_level_id", value: filter.user_level_id.id }
        //   // { field: "client_id", value: client }
        // ]
      };
    } else {
      variables = {
        keyword: keyword,
        skip: 0,
        first: rowsPerPage,
        orderBy: [{ field: orderBy, direction: order }],
        not_like: [
          {
            field: "users.email_address",
            value: ["%@findme.com.ph"]
          }
        ]
        // filter: { field: "client_id", value: user.client.id }
      };
    }
    const { user_level_id, user_status } = filter;
    if (user_level_id.id) {
      // Object.assign(variables, {
      //   condition: { field: "user_level_id", value: filter.user_level_id.id }
      // });
      condition.push({
        field: "user_level_id",
        value: filter.user_level_id.id
      });
    }
    if (user_status) {
      if (user_status === "blocked") {
        condition.push({
          field: "is_blocked", // to change when range filter is applied
          value: "1"
        });
        // Object.assign(variables, {
        //   condition: {
        //     field: "is_blocked", // to change when range filter is applied
        //     value: "1"
        //   }
        // });
      } else {
        Object.assign(variables, {
          dateFilterAnd: {
            field: "account_expiration", // to change when range filter is applied
            [user_status === "active" ? "gt" : "lt"]: moment(new Date()).format(
              "YYYY-MM-DD"
            )
          }
        });
      }
      // else if (user_status === "expired") {
      //   Object.assign(variables, {
      //     dateFilterAnd: {
      //       field: "account_expiration", // to change when range filter is applied
      //       lt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      //     }
      //   });
      // }
    }
    return variables;
  };

  const [
    getUserLevelData,
    { loading: loadingUserLevel, data: userLevelData }
  ] = useLazyQuery(GET_USER_LEVELS, {
    // onCompleted: data => {
    //   if (data) {
    //     const { get_user_levels } = data;
    //     if (get_user_levels.userlevels) {
    //       setUserLevels(
    //         get_user_levels.userlevels.map(v => {
    //           return {
    //             label: v.name,
    //             value: v.id
    //           };
    //         })
    //       );
    //     } else {
    //       setUserLevels([]);
    //     }
    //   }
    // },
    variables: {
      first: 5
    }
    // fetchPolicy: "network-only"
  });

  const debouncedOnChange = React.useMemo(
    () =>
      debounce(e => {
        if (e.target.value?.length >= 0) {
          getUserLevelData({
            variables: {
              first: 5,
              keyword: e.target.value
            }
          });
          clearHistory();
        }
      }, 250),
    []
  );

  // reset default value of filter
  const clearHistory = () => {
    history.push({
      state: {
        params: {
          moduleName: "Users"
        }
      }
    });
  }

  // useEffect(() => {
  //   getUserLevelData({
  //     variables: {
  //       first: 5
  //     }
  //   });
  // }, []);
  // const { data: userleveldata, loading: loading2 } = useQuery(GET_USER_LEVELS, {
  //   variables: {
  //     first: 5
  //   },
  //   onCompleted: data => {
  //     const { get_user_levels } = data;
  //     setUserLevels(
  //       get_user_levels.userlevels.map(v => {
  //         return {
  //           label: v.name,
  //           value: v.id
  //         };
  //       })
  //     );
  //   },
  //   fetchPolicy: "network-only"
  // });

  const { loading: loadingData, data, fetchMore, refetch, error } = useQuery(
    GET_USERS,
    {
      variables: getVariables(),
      // _.isEqual(getVariables(), variable)
      //   ? history?.location?.state?.params?.vars
      //   : getVariables(),
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      onCompleted: () => {
        setLoading(false);
        if (keyword) {
          AddLogs("Admin - Users", "search", searchKeyword.value);
        }
        if (data) {
          if (!currentNumber) {
            setCurrentNumber(data.get_users.total);
          }

          // set/use the save filter values to query
          setFilter({
            ...tempFilter,
            user_level_id: {
              name: variable?.filter
                ? variable?.filter?.user_level_id?.name
                : tempFilter?.user_level_id?.name,
              id: variable?.filter
                ? variable?.filter?.user_level_id?.id
                : tempFilter?.user_level_id?.id
            },
            user_status:
              variable?.vars?.condition[0]?.field === "is_blocked" ||
              variable?.vars?.condition[1]?.field === "is_blocked"
                ? "blocked"
                : variable?.vars?.dateFilterAnd?.gt
                ? "active"
                : variable?.vars?.dateFilterAnd?.lt
                ? "expired"
                : tempFilter.user_status
          });

          // set/use the value for the filter fields
          setTempFilter({
            ...tempFilter,
            user_level_id: {
              name: variable?.filter
                ? variable?.filter?.user_level_id?.name
                : tempFilter?.user_level_id?.name,
              id: variable?.filter
                ? variable?.filter?.user_level_id?.id
                : tempFilter?.user_level_id?.id
            },
            user_status:
              variable?.vars?.condition[0]?.field === "is_blocked" ||
              variable?.vars?.condition[1]?.field === "is_blocked"
                ? "blocked"
                : variable?.vars?.dateFilterAnd?.gt
                ? "active"
                : variable?.vars?.dateFilterAnd?.lt
                ? "expired"
                : tempFilter.user_status
          });
        }
        // setPage(1);
      },
      onError: () => {
        // FUTURE REFERENCE FOR NETWORK ERROR RESPONSE
        // console.log(error.networkError.statusCode);
        // console.log(error.networkError.result);
        // console.log(error.networkError.response);
        // console.log(error.networkError.name);
      }
    }
  );

  const [getMultipleBlock] = useLazyQuery(GET_USERS, {
    onCompleted: data => {
      if (data?.get_users?.users) {
        const temp = [];
        data.get_users.users.map(value => {
          return temp.push(value.username);
        });
        setSelBlock(temp);
        setSelDelete(temp);
      }
    },
    variables: {
      first: 10
    }
  });

  const { loading: clientLoading, data: clientData } = useQuery(
    GET_ADMIN_CLIENT_WO_GROUPS,
    {
      variables: { id: user.client.id },
      fetchPolicy: "network-only"
    }
  );

  const [toDelete, setToDelete] = useState([]);

  const [open, setOpen] = useState(false);

  const handleOpenUpload = () => {
    setUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setUploadDialog(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClosePopper = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(field);
      setOrder("asc");
    }
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorEl.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const openFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getData = () => {
    return data?.get_users?.users
      ? stableSort(data.get_users.users, getSorting(order, orderBy)).slice(
          Number(page - 1) * rowsPerPage,
          Number(page - 1) * rowsPerPage + rowsPerPage
        )
      : [];
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (!selected.length < getData().length) {
      const displayedData = getData().map(data => data.id);
      if (displayedData.every(data => selected.indexOf(data) > -1))
        checked = { all: true, indeterminate: false };
      else if (displayedData.some(data => selected.indexOf(data) > -1))
        checked = { all: false, indeterminate: true };
    }

    return checked;
  };

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const tempBlock = selectedBlock;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);

      const displayedBlocked = getData()
        .filter(u => u.is_blocked)
        .map(u => u.id);
      const newBlocked = tempBlock
        .filter(tb => displayedBlocked.indexOf(tb) < 0)
        .map(id => id);

      setSelectedBlock(newBlocked);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      getData()
        .filter(u => u.is_blocked)
        .map(u => tempBlock.push(u.id));
      setSelected(lodash.uniq(tempSelected));
      setSelectedBlock(lodash.uniq(tempBlock));
      return;
    }
    deselect();
  };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = data.get_users.users
  //       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //       .map(n => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, row) => {
    const { id, is_blocked } = row;
    const selectedIndex = selected.indexOf(id);
    const selectedUnblockIndex = selectedBlock.indexOf(id);
    let newSelected = [];
    let newSelectedBlock = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    if (is_blocked) {
      if (selectedUnblockIndex === -1) {
        newSelectedBlock = newSelectedBlock.concat(selectedBlock, id);
      } else if (selectedUnblockIndex === 0) {
        newSelectedBlock = newSelectedBlock.concat(selectedBlock.slice(1));
      } else if (selectedUnblockIndex === selectedBlock.length - 1) {
        newSelectedBlock = newSelectedBlock.concat(selectedBlock.slice(0, -1));
      } else if (selectedUnblockIndex > 0) {
        newSelectedBlock = newSelectedBlock.concat(
          selectedBlock.slice(0, selectedUnblockIndex),
          selectedBlock.slice(selectedUnblockIndex + 1)
        );
      }

      setSelectedBlock(newSelectedBlock);
    }
  };

  const handleOpen = name => {
    setDialog({ ...dialog, [name]: true });
  };

  const handleClose = name => {
    setDialog({ ...dialog, [name]: false });
  };

  const handleFilters = e => {
    const { name, value } = e.target;
    setTempFilter({ ...tempFilter, [name]: value });
    clearHistory();
  };

  const handleSetFilters = () => {
    AddLogs(
      "Admin - Users",
      "filter",
      `Access Rights: ${
        tempFilter.user_level_id.name
          ? `${tempFilter.user_level_id.name}, `
          : `All`
      }User State: ${
        tempFilter.user_status ? `${tempFilter.user_status}` : `All`
      }`
    );
    setPage(1)
    setFilter(tempFilter);
    closeFilterDrawer();
  };

  const handleClearFilter = () => {
    const freshState = {
      // user_level_id: { label: "", value: "" },
      user_level_id: { name: "", id: "" },
      user_status: ""
    };
    setFilter(freshState);
    setTempFilter(freshState);
  };

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted(data1) {
      if (data1.delete_user.success) {
        handleClose("delete");
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setPage(1);
            refetch(getVariables());
          }
        });
        AddLogs("Admin - Users", "delete", keyLog);
      }
    }
  });

  const [deleteUserAll] = useMutation(DELETE_USER_ALL, {
    onCompleted(data2) {
      if (data2.delete_user_all.success) {
        AddLogs("Admin - Users", "delete", selDelete);
        setSelected([]);
        handleClose("delete");
        Swal.fire({
          title: "Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const [unblockUserAll] = useMutation(UNBLOCK_USER_ALL, {
    onCompleted: data => {
      if (
        data.unblock_users.length > 0 &&
        data.unblock_users[0].response.success
      ) {
        handleClose("delete");
        AddLogs("Admin - Users", "unblock", selBlock);
        Swal.fire({
          title: "Unblocked",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const [unblockUser] = useMutation(UNBLOCK_USER, {
    onCompleted(dataUnblock) {
      if (dataUnblock.unblock_user.success) {
        handleClose("delete");
        AddLogs("Admin - Users", "unblock", keyLog);
        Swal.fire({
          title: "Unblocked",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setPage(1);
            setSelected([]);
            refetch(getVariables());
          }
        });
      }
    }
  });

  const handleDelete = () => {
    if (data.get_users.count === 1 && data.get_users.total > 1)
      setPage(page - 1);
    toDelete.map(row_id =>
      deleteUser({
        variables: { id: row_id }
      })
    );
  };

  const handleUnblock = () => {
    const temp = [""];
    temp.push(blockSelect);
    getMultipleBlock({
      variables: {
        filter: { field: "users.id", value: temp }
      }
    });
    unblockUser({
      variables: { id: blockSelect }
    });
  };

  const handleDeleteAll = () => {
    const temp = [""];
    if (selected.length > 0) {
      selected.map(id => temp.push(id));
      getMultipleBlock({
        variables: {
          filter: { field: "users.id", value: temp },
          first: selected.length
        }
      });
      deleteUserAll({
        variables: { condition: { field: "id", value: selected } }
      });
    }
  };

  const handleUnblockAll = () => {
    const temp = [""];
    if (selectedBlock.length > 0) {
      selectedBlock.map(id => temp.push(id));
      getMultipleBlock({
        variables: {
          filter: { field: "users.id", value: temp },
          first: selectedBlock.length
        }
      });

      unblockUserAll({
        variables: { ids: selectedBlock }
      });
    }
  };

  const handleToDelete = (id, username) => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
    setKeyLog(username);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const checkGeofence = data => {
    if (data.geofence_name) {
      return data.geofence_name;
    }
    return "";
  };

  const dataToArray = () => {
    const temp = [
      [
        "Client",
        "User Name",
        "First Name",
        "Last Name",
        "Email Address",
        "Contact Number",
        "Access Rights",
        "Geofence Name"
      ]
    ];

    getData().map(row =>
      temp.push([
        row.client.name,
        row.username,
        row.first_name,
        row.last_name,
        row.email_address,
        row.contact_number,
        row.user_level_name,
        checkGeofence(row)
      ])
    );

    return temp;
  };

  useEffect(() => {
    if (searchKeyword.moduleName) {
      setSearchKeyword({ ...searchKeyword, moduleName: "" });
    } else {
      setSearchKeyword({ ...searchKeyword, value: "" });
    }
    setClearFirst(true);
  }, []);

  useEffect(() => {
    if (clearFirst && searchKeyword.submit) {
      setKeyword(searchKeyword.value);
      setFilter({
        user_level_id: { name: "", id: "" }
      });
      setPage(1);
    }
  }, [searchKeyword, clearFirst]);

  // useEffect(() => {
  //   if (contextData.searchKeyword.submit) {
  //     setKeyword(contextData.searchKeyword.value);
  //   }
  // }, [contextData]);

  const fetchMoreData = nextPage => {
    if (nextPage * rowsPerPage >= data?.get_users?.users.length) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          skip: nextPage * rowsPerPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            get_users: {
              count: fetchMoreResult.get_users.count,
              total: fetchMoreResult.get_users.total,
              users: [
                ...prev.get_users.users,
                ...fetchMoreResult.get_users.users
              ]
            }
          };
        }
      }).catch(() => {
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            setPage(1);
            refetch(getVariables());
          }
        });
      });
    }
  };

  // const handlePage = e => {
  //   const { value } = e.target;
  //   if (page < value) {
  //     if (value > Math.ceil(data.get_users.total / rowsPerPage)) {
  //       setPage(1);
  //     } else {
  //       for (let i = 1; i <= value; i += 1) {
  //         if (i * rowsPerPage > data.get_users.users.length) {
  //           fetchMoreData(i - 1);
  //         }
  //       }
  //       setPage(value);
  //     }
  //   } else if (Number(value) === 0) {
  //     setPage(1);
  //   } else {
  //     setPage(value);
  //   }
  // };

  // // Function: Block other inputs beside number/integer
  // const handleKeyPress = event => {
  //   // get the event value based on keyCode or which
  //   const keyCode = event.keyCode || event.which;
  //   // convert variable keyCode into a string
  //   const keyValue = String.fromCharCode(keyCode);
  //   // check if variable keyValue is NOT number or integer
  //   if (/[^0-9]/g.test(keyValue)) {
  //     // if true
  //     // blocked the value of keyValue
  //     event.preventDefault();
  //   }
  // };

  if (loadingData || clientLoading || loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorPage errorCode={error.networkError.response} />;
  }
  return (
    <AccessControl
      resource="/admin/resources/users/"
      process="tables"
      setCanDelete={setCanEdit}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length}>
            <Button
              ref={anchorEl}
              className={classes.btn_download}
              variant="contained"
              startIcon={<GetAppIcon />}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                handleToggle();
              }}
            >
              Download
            </Button>
            {canEdit && (
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<PublishIcon />}
                onClick={handleOpenUpload}
              >
                Upload
              </Button>
            )}
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={openFilterDrawer}>
                <Badge
                  color="secondary"
                  variant={
                    filter.user_level_id.id || filter.user_status
                      ? "dot"
                      : "standard"
                  }
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Pagination
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              dataLength={
                data?.get_users?.users ? data?.get_users.users.length : 0
              }
              fetchMoreData={fetchMoreData}
              totalRows={data?.get_users?.total || 0}
              totalPage={Math.ceil(data?.get_users?.total / rowsPerPage)}
              refetch={refetch}
              getVariables={getVariables}
              setRowsPerPage={setRowsPerPage}
              // setCircularLoading={setCircularLoading}
              setLoading={setLoading}
            />
          </EnhancedTableToolbar>
          {selected.length > 0 && (
            <Paper
              style={{
                display: "flex",
                backgroundColor: "#eaeaea",
                paddingLeft: 16,
                position: "relative",
                minHeight: "64px",
                alignItems: "center",
                gap: 12
              }}
              elevation={0}
              square
            >
              <Typography>
                {selected.length} user/s is selected on this page
              </Typography>
              <div style={{ flexGrow: 1 }} />
              {user &&
              (user.user_level_name.toLowerCase() === "data admin" ||
                user.user_level_id === process.env.REACT_APP_WTI_ADMIN) ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: 100,
                    color: "white"
                  }}
                  disabled={
                    selectedBlock.length < 1 ||
                    selectedBlock.length !== selected.length ||
                    !canEdit
                  }
                  onClick={() => handleOpen("unblock_all")}
                >
                  Unblock Selected
                </Button>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: 100,
                  color: "white"
                }}
                onClick={() => handleOpen("delete_all")}
                disabled={!canEdit}
              >
                Delete Selected
              </Button>
            </Paper>
          )}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data?.get_users?.total}
                handleSort={handleSort}
                isChecked={isChecked}
                canEdit={canEdit}
              />

              {data?.get_users?.users && (
                <TableBody>
                  {getData().map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={!canEdit}
                            onClick={event => handleClick(event, row)}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.username}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          <Highlight
                            search={contextData.searchKeyword.value}
                          >{`${row.first_name} ${row.last_name}`}</Highlight>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.user_level_name}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.email_address}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Highlight search={contextData.searchKeyword.value}>
                            {row.contact_number}
                          </Highlight>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            style={{ paddingLeft: "0px" }}
                            onClick={() => {
                              AddLogs("Admin - Users", "view", row.username);
                              // save the set filter (view user)
                              history.push({
                                pathname: `/admin/resources/users/view/${row.id}`,
                                state: {
                                  params: {
                                    vars: getVariables(),
                                    filter: tempFilter
                                  }
                                }
                              });
                            }}
                          >
                            <Link
                              style={{ color: "#757575" }}
                              aria-label="Back"
                              to={{
                                pathname: `/admin/resources/users/view/${row.id}`
                              }}
                            >
                              <VisibilityIcon />
                            </Link>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleToDelete(row.id, row.username);
                              handleOpen("delete");
                            }}
                            disabled={!canEdit}
                            style={{ paddingLeft: "0px" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {row.is_blocked && (
                            <IconButton
                              onClick={() => {
                                setKeyLog(row.username);
                                handleOpen("unblock");
                                setBlockSelect(row.id);
                              }}
                              disabled={!getAccess(user.user_level_name)}
                              style={{ paddingLeft: "0px", color: "red" }}
                            >
                              <BlockIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {!data?.get_users?.users && (
              <span
                style={{
                  width: "100%",
                  height: 72,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>No data found</Typography>
              </span>
            )}
          </TableContainer>
        </Paper>
        <div className={classes.fab}>
          <PaperCount
            label="users created"
            currentTotal={currentNumber}
            data={clientData && clientData.get_client.user_count}
          />
          {/* <Paper
            variant="outlined"
            style={{
              height: "50px",
              width: "200px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
          >
            <Typography style={{ fontWeight: " bold" }}>{`${currentNumber} / ${
              clientData ? clientData.get_client.user_count : "-"
            } users created`}</Typography>
          </Paper> */}
          {canEdit && (
            <Fab
              size="large"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                // setSearchKeyword({ ...searchKeyword, value: "" });
                history.push({
                  pathname: "/admin/resources/users/add",
                  state: {
                    params: {
                      vars: getVariables(),
                      filter: tempFilter
                    }
                  }
                });
              }}
            >
              <AddIcon
                style={{
                  width: "50px",
                  height: "50px"
                }}
              />
            </Fab>
          )}
        </div>
        <Filter
          filterDrawer={filterDrawer}
          closeFilterDrawer={closeFilterDrawer}
          moduleName={process.env.REACT_APP_USERS_MODULE}
          handleSetFilters={handleSetFilters}
          handleClearFilter={() => handleClearFilter()}
          history={() => clearHistory()}
        >
          <FormControl
            classes={{ root: formStyles.formRoot }}
            style={{ width: "100%", minWidth: "auto" }}
          >
            <InputLabel shrink required>
              Access Rights
            </InputLabel>
            <br />
            <Autocomplete
              loading={loadingUserLevel}
              id="user_level_id"
              options={userLevelData?.get_user_levels?.userlevels || []}
              value={tempFilter.user_level_id}
              getOptionLabel={option => option.name || ""}
              getOptionSelected={(o, v) => v.id === o.id}
              renderOption={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  classes={{ root: formStyles.textFieldRoot }}
                  value={tempFilter.label}
                  placeholder="All"
                  onChange={debouncedOnChange}
                  onClick={debouncedOnChange}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "6px 0px"
                    },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              onChange={(e, val) => {
                if (val) {
                  setTempFilter({
                    ...tempFilter,
                    user_level_id: val
                  });
                }
              }}
              disableClearable
            />
            {/* <Select
              value={tempFilter.user_level_id}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="user_level_id"
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              {userleveldata.get_user_levels.userlevels.map(access_right => {
                return (
                  <MenuItem value={access_right.id} key={access_right.id}>
                    {access_right.name}
                  </MenuItem>
                );
              })}
            </Select> */}
          </FormControl>
          <FormControl
            classes={{ root: formStyles.formRoot }}
            style={{ width: "100%", minWidth: "auto" }}
          >
            <InputLabel shrink required>
              User State
            </InputLabel>
            <Select
              classes={{ root: formStyles.selectRoot }}
              style={{ textAlign: "left" }}
              value={tempFilter.user_status}
              onChange={e => handleFilters(e)}
              displayEmpty
              name="user_status"
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              {[
                {
                  display: "Blocked",
                  value: "blocked"
                },
                {
                  display: "Active",
                  value: "active"
                },
                {
                  display: "Expired",
                  value: "expired"
                }
              ].map(user_state => {
                return (
                  <MenuItem value={user_state.value} key={user_state.value}>
                    {user_state.display}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Filter>
        <ConfirmationDialog
          toggle={dialog.unblock}
          close={() => {
            handleClose("unblock");
            setBlockSelect("");
          }}
          fn={e => handleUnblock(e)}
          title="Unblock?"
          content="Are you sure you want to unblock this user?"
        />
        <ConfirmationDialog
          toggle={dialog.delete}
          close={() => handleClose("delete")}
          fn={e => handleDelete(e)}
          title="Delete?"
          content="Are you sure you want to delete this user?"
        />
        <ConfirmationDialog
          toggle={dialog.delete_all}
          close={() => handleClose("delete_all")}
          fn={e => handleDeleteAll(e)}
          title="Delete?"
          content="Are you sure you want to delete these users?"
        />
        <ConfirmationDialog
          toggle={dialog.unblock_all}
          close={() => handleClose("unblock_all")}
          fn={e => handleUnblockAll(e)}
          title="Unblock?"
          content="Are you sure you want to unblock these users?"
        />
        {/* <Dialog
          open={dialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: 16 }}>
            <Button
              onClick={handleClose}
              style={{ borderRadius: 100, width: 100 }}
              variant="outlined"
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDelete();
              }}
              style={{ borderRadius: 100, color: "white", width: 100 }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogDeleteAll}
          onClose={handleCloseDeleteAll}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete these users?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: 16 }}>
            <Button
              onClick={handleCloseDeleteAll}
              style={{ borderRadius: 100, width: 100 }}
              variant="outlined"
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDeleteAll();
                handleCloseDeleteAll();
              }}
              style={{ borderRadius: 100, color: "white", width: 100 }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog> */}
        <UploadDialog
          uploadDialog={uploadDialog}
          handleCloseUpload={handleCloseUpload}
          refetch={refetch}
          getVariables={getVariables}
          clientLimit={clientData?.get_client?.user_count || 0}
          userTotal={currentNumber}
        />
        {/* <DownloadMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} /> */}
        {anchorEl && (
          <DlMenu
            open={open}
            anchorEl={anchorEl}
            handleClosePopper={handleClosePopper}
            handleListKeyDown={handleListKeyDown}
            tableData={dataToArray()}
            tempFilter={tempFilter}
          />
        )}
      </div>
    </AccessControl>
  );
}

export default withRouter(Users);
