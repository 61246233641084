import React from "react";
import _ from "lodash";

const CircleBoundConverter = (lat, lng, radius = null) => {
  const circle = new window.google.maps.Circle({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0,
    // center: searchLatLng || { lat, lng },
    // radius: searchRadius || 250
    center: { lat, lng },
    radius: radius || 250
  });
  const numberOfPoints = 48;
  const points = [];
  const steps = 360 / numberOfPoints;
  for (let i = 0; i < numberOfPoints; i += 1) {
    const gpos = window.google.maps.geometry.spherical.computeOffset(
      circle.center,
      circle.radius, // meter,
      steps * i
    );
    points.push([gpos.lat(), gpos.lng()]);
  }
  // Duplicate the last point to close the geojson ring
  points.push(points[0]);

  return [[points]];
};

export default CircleBoundConverter;
