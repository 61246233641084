import React, { useState, Fragment } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Paper
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import _ from "lodash";
// import SearchContext from "../../../../../context/SearchContext";
import { EDIT_ALERT_DESCRIPTION } from "../../../../../graphql/Mutations";
import {
  GET_ALERT_DESCRIPTIONS,
  GET_ALERT_DESCRIPTION
} from "../../../../../graphql/Queries";

import Loading from "../../../../../utils/Loading";
import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../../utils/validation";
import { Helper } from "../../../../Utils";
import AccessControl from "../../../../../utils/AccessControl";
import redirectToTable from "../../../../../utils/redirect";
import ErrorPage from "../../../../../utils/errorPage";
import AddLogs from "../../../../../utils/functions/AddLogs";

// const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  container: {
    // height: "calc(100% - 66px - 32px)", // 66 for header, 32 for top + bottom margins
    minHeight: "100%",
    height: "auto",
    margin: theme.spacing(2, 4),
    flexGrow: 1,
    padding: theme.spacing(5),
    boxSizing: "border-box"
    // overflow: "auto"
  },
  scrollable: {
    height: "calc(100% - 66px)",
    boxSizing: "border-box",
    overflow: "auto"
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  }
}));

export default function ViewAlertDescriptions(props) {
  const { history, location } = props;
  const { id } = useParams();
  const { pathname } = location;
  const classes = useStyles();
  // const contextData = useContext(SearchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [toOpen, setToOpen] = useState(false);
  const [filterData, setFilterData] = useState([]); // set filter data from backend
  const [editMode, setEditMode] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [changes, setChanges] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [state, setState] = useState({
    code: "",
    description: "",
    parent_id: null
  });
  const [errors, setErrors] = useState({
    code: ""
  });
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });

  const getVariables = () => {
    const variables = {
      condition: {
        field: "code",
        value: state.code
      }
    };
    return variables;
  };
  const { data: reCheck, refetch } = useQuery(GET_ALERT_DESCRIPTIONS, {
    variables: getVariables(),
    fetchPolicy: "network-only",
    skip: !state.code
  });

  const [get_description] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        setFilterData(data.get_alert_descriptions.alert_descriptions);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  const { data: data1 } = useQuery(GET_ALERT_DESCRIPTION, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(
              history,
              process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE
            );
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(
              history,
              process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE
            );
          }
        });
      }
    }
  });

  React.useEffect(() => {
    if (data1) {
      setInitialData(data1.get_alert_description);
      setState(data1.get_alert_description);
      setIsLoading(false);
    }
  }, [data1, discard]);

  const handleAddValues = e => {
    // let errorMessage;
    // if (e.target.name === "code") {
    //   if (e.target.value.length === 0) {
    //     errorMessage = validate("alphanumeric", e.target.value) ? (
    //       ""
    //     ) : (
    //       <Helper message="This field is required" />
    //     );
    //   } else {
    //     errorMessage = validate("alphanumeric", e.target.value) ? (
    //       ""
    //     ) : (
    //       <Helper message="Alphanumeric characters only" />
    //     );
    //   }
    // }

    // setErrors({
    //   ...errors,
    //   [e.target.name]: errorMessage
    // });
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handleBlur = (e) => {
    const {name, value} = e.target
    let err;
    switch(name){
      case "code":
        value === "" 
        ? err = <Helper message="This field is required!" />
        : err = !validate("alphanumeric", value)
        ? err = <Helper message="Alphanumeric characters only" />
        : err = ""
      break;
      default:
      break
    }
    setErrors({...errors, code: err})
  }

  const [editAlertDescription] = useMutation(EDIT_ALERT_DESCRIPTION, {
    onCompleted: res => {
      setDisableSubmit(false);
      setIsLoading(false);
      refetch(getVariables());
      if (reCheck) {
        if (initialData.code === state.code) {
          if (res.edit_alert_description.success) {
            AddLogs("Admin - Alert Descriptions", "update", state.code);
            Swal.fire({
              icon: "success",
              // title: "Success",
              text: "Edit successful",
              timer: 1500,
              onClose: () => {
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName:
                        process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
                      filter: history?.location?.state?.params?.filter
                    }
                  }
                });
              }
            });
          } else {
            setErrors({
              code: <Helper message="Alert description already exists" />
            });
          }
        } else if (reCheck.get_alert_descriptions.total === 0) {
          if (res.edit_alert_description.success) {
            Swal.fire({
              icon: "success",
              // title: "Success",
              text: "Edit successful",
              timer: 1500,
              onClose: () => {
                history.push({
                  pathname: "/admin",
                  state: {
                    params: {
                      moduleName:
                        process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE,
                      filter: history?.location?.state?.params?.filter
                    }
                  }
                });
              }
            });
          } else {
            setErrors({
              code: <Helper message="Alert description already exists" />
            });
          }
        } else {
          setErrors({
            code: <Helper message="Alert description already exists" />
          });
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      setIsLoading(false);
    }
  });

  const handleCloseDialog = dialog_name => {
    setToOpen(false);
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const discarded = () => {
    handleCloseDialog("discard");
  };

  const saveEdit = () => {
    editAlertDescription({
      variables: {
        id: id,
        alert_description: {
          code: state.code,
          description: state.description,
          parent_id: Number(state.parent_id)
        }
      }
    });
    setErrors([]);
    setDisableSubmit(false);
  };

  const handleOpen = () => {
    setToOpen(true);
  };

  const checkChanges = () => {
    return Object.keys(data1.get_alert_description)
      .filter(element => element in state)
      .some(element => state[element] !== data1.get_alert_description[element]);
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpen("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
  };

  if (isLoading) return <Loading />;

  if (initialData.code === null) {
    return <ErrorPage />;
  }
  return (
    <AccessControl
      resource={pathname}
      setEditPermission={setEditPermission}
      process="view"
    >
      <div className={classes.root}>
        <Header
          // process={!_.isEqual(initialData, state) ? "Edit" : "View"}
          process={editMode ? "Edit" : "View"}
          moduleName={process.env.REACT_APP_ALERT_DESCRIPTIONS_MODULE}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={_.isEqual(initialData, state)}
          history={history?.location?.state?.params}
        />
        <div className={classes.scrollable}>
          {/* <form
            onSubmit={e => {
              e.preventDefault();
            }}
            className={classes.root}
            autoComplete="off"
          > */}
          <Paper className={classes.container} variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
                <br />
                <Grid container spacing={3}>
                  <Fragment>
                    <Grid item xs={4}>
                      <Typography variant="h6">
                        Alert Description Information
                      </Typography>
                      <List key={state.code}>
                        <ListItem>
                          <ListItemText
                            disableTypography={editMode}
                            primary={
                              <b>
                                Alert Description{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            }
                            secondary={
                              editMode ? (
                                <TextField
                                  autoFocus
                                  fullWidth
                                  id="code"
                                  placeholder="Enter Alert Description"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={state.code}
                                  name="code"
                                  onBlur={e => handleBlur(e)}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                  helperText={errors.code ? errors.code : ""}
                                />
                              ) : (
                                state.code
                              )
                            }
                          />
                        </ListItem>
                        <ListItem style={{ width: "100%" }}>
                          <ListItemText
                            disableTypography={editMode}
                            primary={<b>Parent Description</b>}
                            secondary={
                              editMode ? (
                                <Autocomplete
                                  id="parent_id"
                                  options={filterData}
                                  value={state.parent}
                                  getOptionLabel={option => {
                                    return option.code || "";
                                  }}
                                  getOptionSelected={(o, v) => {
                                    if (filterValue) {
                                      return v.value === o.value;
                                    }
                                    if (state.parent.code === o.code) {
                                      setFilterValue(o.code);
                                      return null;
                                    }
                                    return null;
                                  }}
                                  renderOption={option => option.code}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      value={
                                        state.parent ? state.parent.code : ""
                                      }
                                      placeholder="Select Parent"
                                      onChange={event => {
                                        if (event.target.value.length > 0) {
                                          get_description({
                                            variables: {
                                              first: 10,
                                              filter: {
                                                field: "code",
                                                value: event.target.value
                                              }
                                            }
                                          });
                                        } else {
                                          setState({
                                            ...state,
                                            parent_id: null,
                                            parent: {
                                              id: null,
                                              code: null,
                                              description: null
                                            }
                                          });
                                        }
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        )
                                      }}
                                    />
                                  )}
                                  onChange={(e, val) => {
                                    if (val) {
                                      setState({
                                        ...state,
                                        parent_id: val.id,
                                        parent: {
                                          id: val.id,
                                          code: val.code,
                                          description: val.description
                                        }
                                      });
                                      setFilterValue(val);
                                    }
                                  }}
                                  disableClearable
                                />
                              ) : state.parent ? (
                                state.parent.code
                              ) : (
                                ""
                              )
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            disableTypography={editMode}
                            primary={<b>Remarks</b>}
                            secondary={
                              editMode ? (
                                <TextField
                                  style={{ display: "block" }}
                                  multiline
                                  minRows={4}
                                  maxRows={4}
                                  variant="outlined"
                                  placeholder="Type Here..."
                                  name="description"
                                  id="description"
                                  value={state.description}
                                  onChange={e => {
                                    handleAddValues(e);
                                  }}
                                  helperText={
                                    errors.description ? errors.description : ""
                                  }
                                />
                              ) : (
                                state.description
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Fragment>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                {errors.general && (
                  <Alert
                    severity="error"
                    style={{ justifyContent: "center" }}
                    className={classes.alert}
                  >
                    {errors.general}
                  </Alert>
                )}
                {editMode ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    fullWidth
                    style={{
                      margin: 20,
                      maxWidth: "30%",
                      marginTop: 10,
                      borderRadius: "17px"
                    }}
                    onClick={() => {
                      let temp = {};

                      if (state.code.length === 0) {
                        temp = {
                          ...temp,
                          code: <Helper message="This field is required" />
                        };
                      }

                      if (/[^a-zA-Z0-9 ]/.test(state.description)) {
                        temp = {
                          ...temp,
                          description: (
                            <Helper message="Numbers and Letters only" />
                          )
                        };
                      }

                      if (/[^a-zA-Z0-9 ]/.test(state.code)) {
                        temp = {
                          ...temp,
                          code: <Helper message="Numbers and Letters only" />
                        };
                      }

                      setErrors(temp);
                      if (
                        !/[^a-zA-Z0-9 ]/.test(state.code) &&
                        !/[^a-zA-Z0-9 ]/.test(state.description)
                      ) {
                        if (state.code.length === 0) {
                          temp = {
                            ...temp,
                            code: <Helper message="This field is required" />
                          };
                        } else if (!_.isEqual(state, initialData)) {
                          setDialog({ ...dialog, save: true });
                        } else {
                          setState(initialData);
                          setEditMode(false);
                          // Swal.fire({
                          //   title: "No Changes have been made",
                          //   icon: "success",
                          //   showConfirmButton: false,
                          //   timer: 10000,
                          //   onClose: () => {
                          //     setState(initialData);
                          //     setEditMode(false);
                          //   }
                          // })
                        }
                      }
                      return null;
                    }}
                  >
                    Save Details
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
          {/* </form> */}
          <ConfirmationDialog
            toggle={dialog.save}
            close={() => handleCloseDialog("save")}
            fn={() => saveEdit()}
            title="Save Changes?"
            content="Are you sure you want to save this changes?"
          />

          <ConfirmationDialog
            toggle={dialog.discard}
            close={() => handleCloseDialog("discard")}
            fn={() => discarded()}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />

          <ConfirmationDialog
            toggle={toOpen}
            close={() => setToOpen(false)}
            fn={
              () => {
                setState(initialData);
                setEditMode(false);
              }
              // Swal.fire({
              //   title: "Discarded",
              //   icon: "success",
              //   showConfirmButton: false,
              //   timer: 1500,
              //   onClose: () => {
              //     setState(initialData);
              //     setEditMode(false);
              //   }
              // })
            }
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />
        </div>
      </div>
    </AccessControl>
  );
}
