import React from "react";

export const SkipPrevious = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.38476 9.22547L9.57795 12.8358C10.172 13.2527 11 12.8269 11 12.1084L11 4.88778C11 4.16927 10.181 3.75236 9.57795 4.1604L4.38476 7.7707C3.87174 8.12552 3.87174 8.87065 4.38476 9.22547Z" />
    <path d="M13.25 4C13.6625 4 14 4.3375 14 4.75L14 12.25C14 12.6625 13.6625 13 13.25 13C12.8375 13 12.5 12.6625 12.5 12.25L12.5 4.75C12.5 4.3375 12.8375 4 13.25 4Z" />
  </svg>
);

export const SkipNext = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.6152 7.77453L8.42205 4.16423C7.82803 3.74732 7 4.1731 7 4.89161L7 12.1122C7 12.8307 7.81903 13.2476 8.42205 12.8396L13.6152 9.2293C14.1283 8.87448 14.1283 8.12935 13.6152 7.77453Z" />
    <path d="M4.75 13C4.3375 13 4 12.6625 4 12.25L4 4.75C4 4.3375 4.3375 4 4.75 4C5.1625 4 5.5 4.3375 5.5 4.75L5.5 12.25C5.5 12.6625 5.1625 13 4.75 13Z" />
  </svg>
);
