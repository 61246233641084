import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // root: {
  //   position: "absolute",
  //   top: "0",
  //   left: "0",
  //   width: "100%",
  //   maxWidth: "100%",
  //   height: "100%"
  // },
  // main: {
  //   padding: "20px",
  //   boxSizing: "border-box",
  //   position: "relative",
  //   minHeight: "calc(100% - 64px)",
  //   height: "auto"
  // },
  // container: {
  //   position: "relative",
  //   minHeight: "100%",
  //   height: "auto",
  //   boxShadow: "0px 0px 2px #b3b3b3",
  //   borderRadius: "12px",
  //   padding: "30px",
  //   boxSizing: "border-box",
  //   display: "flex",
  //   justifyContent: "space-evenly",
  //   "& .MuiAutocomplete-inputRoot": {
  //     fontSize: "12px",
  //     padding: "12px 0px"
  //   },
  //   "& .MuiInput-underline:before": {
  //     borderBottom: "2px solid #ccc"
  //   },
  //   "& .WAMuiChipInput-underline-44:before": {
  //     borderBottom: "2px solid #ccc"
  //   },
  //   "& .MuiFormLabel-root": {
  //     fontWeight: "bold",
  //     color: "#454545",
  //     letterSpacing: "1px"
  //   }
  // },
  // division: {
  //   position: "relative",
  //   width: "100%",
  //   "& > .inputGroups": {
  //     margin: "24px 0px",
  //     width: "250px"
  //   },
  //   "&:nth-child(1)": {
  //     flex: 1
  //   },
  //   "&:nth-child(2)": {
  //     marginTop: "-12px",
  //     flex: 2
  //   }
  // },
  // divisionTitle: {
  //   fontSize: 16,
  //   fontWeight: "bold",
  //   color: "#454545",
  //   letterSpacing: "1.5px"
  // },
  formLabelRoot: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#454545",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center",
    marginBottom: 12
  },
  formRoot: {
    margin: "0 0 24px 0",
    // width: "250px",
    minWidth: "250px",
    width: "100%",
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiFormLabel-root": {
      fontWeight: "bold",
      color: "#454545",
      letterSpacing: "1px"
    },
    "& .MuiInput-formControl": {
      width: "100%"
    },
    "& .MuiInputBase-multiline": {
      padding: 0
    },
    "& .Mui-disabled .MuiSvgIcon-root": {
      display: "none"
    }
  },
  selectRoot: {
    fontSize: "12px",
    padding: "12px 0px",
    textTransform: "capitalize",
    textAlign: "left"
  },
  textFieldRoot: {
    "& input": {
      fontSize: "12px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "12px",
      padding: "12px 0px 12px 0px"
    },
    "&.outlinedTextarea textarea": {
      fontSize: "12px",
      padding: "12px",
      border: "2px solid #CBCBCB"
    },
    "& textarea.Mui-disabled": {
      border: "2px solid transparent"
    },
    "& textarea.Mui-disabled + fieldset": {
      display: "none"
    }
  },
  menuItem: {
    textTransform: "capitalize"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  saveButton: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    width: "200px",
    borderRadius: "100px",
    transform: "translateX(-50%)"
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    justifyItems: "left"
  },
  checkBoxTitle: {
    fontSize: "0.75rem",
    fontWeight: 600,
    letterSpacing: 0.7
  }
});

const useFormErrorStyles = makeStyles({
  root: {
    lineHeight: 0,
    alignItems: "center",
    fontSize: "0.75rem"
  },
  icon: {
    fontSize: 14,
    marginRight: 12
  },
  standardError: {}
});

export { useFormErrorStyles };
