/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion as MuiExpansionPanel,
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  Grid
} from "@material-ui/core";
import {
  Publish as PublishIcon,
  CloudUpload as CloudUploadIcon,
  ExpandMore,
  ExpandLess
} from "@material-ui/icons";
import XLSX from "xlsx";
import lodash from "lodash";
import validate from "../../../../../utils/validation";
import { GET_GPS_PROVIDERS } from "../../../../../graphql/Queries";
import { UPLOAD_GPS_PROVIDERS } from "../../../../../graphql/Mutations";
import client from "../../../../../Client";
import AddLogs from "../../../../../utils/functions/AddLogs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: 20,
    right: 20
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    margin: "12px",
    borderRadius: "100px",
    color: "white"
  },
  dialogPaper: {
    height: "70%",
    width: "50%"
  },
  dialogContent: {
    padding: 0
  },
  typography: {
    fontSize: 12
  },
  typography_red: {
    fontSize: 12,
    color: "red"
  },
  icon: {
    opacity: 1
  },
  dialog: {
    borderRadius: "10px",
    width: 600
  }
}));

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const UploadDialog = props => {
  const classes = useStyles();
  const {
    dataCount,
    uploadDialog,
    handleCloseUpload,
    refetch,
    getVariables
  } = props;
  const [file, setFile] = useState([]);
  const [summary, setSummary] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [counter, setCounter] = useState({
    previous: 0
  });

  const [providers, setProviders] = useState([]);
  const [re, setRe] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const { data } = useQuery(GET_GPS_PROVIDERS, {
    variables: {
      filter: { field: "name", value: providers },
      // first: 1000
      first: dataCount
    },
    skip: providers.length === 0
  });

  useEffect(() => {
    if (summary) {
      if (fileErrors.length > 0) {
        const temp = file;
        let status = "";
        if (fileErrors.every(error => !error.errors)) {
          refetch(getVariables);
          status = "Success";
        } else if (fileErrors.every(error => error.errors !== "")) {
          status = "Failed";
        } else if (fileErrors.some(error => error.errors !== "")) {
          refetch(getVariables);
          status = "Partial Success";
        }

        temp[temp.length - 1] = {
          ...temp[temp.length - 1],
          status: status,
          errors: fileErrors
        };
        setFile(temp);
        setRe(!re);
        setSummary(false);
        setFileErrors([]);
      }
    }
  }, [summary, fileErrors]);

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const validateCell = (key, value) => {
    let val = "";
    switch (key) {
      case "name":
        if (value.trim() === "") {
          val = { error: "GPS Provider is required" };
        } else {
          val = validate("alphanumeric", value)
            ? value.toString()
            : {
                error:
                  "GPS Provider invalid format. Please use Alphanumeric characters only"
              };
        }
        break;
      case "registered_IPS":
        if (value) {
          let counter = 0;
          value.split(",").map(item => {
            if (
              /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                item
              )
            ) {
              if (item !== undefined) {
                return (counter = 0);
              }
            } else {
              return (counter = 1);
            }
            return item;
          });
          if (counter === 1) {
            val = {
              error:
                "IP Address invalid format. Please use Numeric Character in (4) 8 bit fields separated by period only"
            };
          } else {
            val = value.toString();
          }
        }
        break;
      case "api_key":
        if (value) {
          if (/^[a-zA-Z0-9-]*$/.test(value)) {
            val = value.toString();
          } else {
            val = {
              error: `API Key invalid format. Please use Alphanumeric characters only\n`
            };
          }
        }
        break;
      case "remarks":
        if (value) {
          val = validate("alphanumeric", value)
            ? value.toString()
            : {
                error:
                  "GPS Provider remarks invalid format. Please use Alphanumeric characters only"
              };
        }
        break;
      default:
        break;
    }

    return val;
  };

  const parseRow = async row => {
    const temp = {};
    const header = [];
    process.env.REACT_APP_GPS_PROVIDERS_KEYS.split(",").map(item => {
      if (item === "GPS Providers*") {
        header.push("name");
      } else if (item === "IP Address") {
        header.push("registered_IPS");
      } else if (item === "API Key") {
        header.push("api_key");
      } else if (item === "Remarks") {
        header.push("remarks");
      }
      return item;
    });
    header.forEach((key, index) =>
      Object.assign(temp, { [key]: validateCell(key, row[index]) })
    );
    return temp;
  };

  const handleUpload = e => {
    const { files } = e.target;
    setFile([...file, { file: files[0], status: "", errors: [] }]);
    e.target.value = null;
  };

  const mutationAddGpsProvider = batchData => {
    return new Promise(resolve => {
      client
        .mutate({
          mutation: UPLOAD_GPS_PROVIDERS,
          variables: {
            gps_providers: batchData
          }
        })
        .then(data => {
          AddLogs("Admin - GPS Providers", "upload", "");
          resolve(data);
        });
    });
  };

  const parseResponse = (res, count, fileErrors, validIndex) => {
    const { add_gps_providers } = res.data;
    const errors = add_gps_providers.map(val => val.error);
    if (errors.length > 0) {
      const errorMsgs = errors.map(val => {
        const temp = val.map(errormsg => Object.values(errormsg)[1]);
        return temp;
      });
      errorMsgs.map((val, index) =>
        fileErrors.push({
          row: validIndex[index],
          errors: lodash.join(val, ", ")
        })
      );

      setCounter({ ...counter, previous: add_gps_providers.length });
    }
    if (fileErrors.length === count) {
      setFileErrors(fileErrors);
      setSummary(true);
    }
  };

  const uploadFile = async batchData => {
    const tempFile = file;
    tempFile[file.length - 1] = {
      ...tempFile[file.length - 1],
      status: "Uploading"
    };
    setFile(tempFile);
    setForceRerender(!forceRerender);
    let tempBatchData = [];
    let validData = false;
    const fileError = [];
    const interval = 10;
    for (let i = 1; i <= batchData.length - 1; i += interval) {
      const validIndex = [];
      const tempDatz = batchData.slice(i, i + interval);
      for (let u = 0; u <= tempDatz.length - 1; u += 1) {
        // eslint-disable-next-line no-await-in-loop
        const temp = await parseRow(tempDatz[u]);
        if (temp) {
          const tempErrors = [];
          if (
            Object.values(temp).every(
              value => Object.keys(value)[0] !== "error"
            )
          ) {
            tempBatchData.push(temp);
            validIndex.push(i + u + 1);
            validData = true;
          } else {
            Object.values(temp)
              .filter(value => Object.keys(value)[0] === "error")
              .map(value => tempErrors.push(value.error));
            fileError.push({
              row: i + u + 1,
              errors: lodash.join(tempErrors, ", ")
            });
          }
        }
      }

      if (tempBatchData.length > 0) {
        // eslint-disable-next-line no-await-in-loop
        // console.log({
        //   name: tempBatchData.name,
        //   remarks: tempBatchData.remarks,
        //   api_key: tempBatchData.api_key,
        //   registered_IPS: tempBatchData.registered_IPS,
        //   group_ids: []
        // });
        const response = await mutationAddGpsProvider(tempBatchData);
        parseResponse(response, batchData.length - 1, fileError, validIndex);
        tempBatchData = [];
      }
      if (i + interval > batchData.length - 1) {
        if (!validData) {
          setFileErrors(fileError);
          setSummary(true);
        }
      }
    }

    setProviders([]);
  };

  const submitUpload = async () => {
    const tempFile = file;
    const f = file[file.length - 1].file;
    const reader = new FileReader();
    reader.onload = async e => {
      const dataRes = new Uint8Array(e.target.result);
      const workbook = XLSX.read(dataRes, { type: "array" });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const raw_data = XLSX.utils.sheet_to_json(first_worksheet, {
        header: 1,
        blankrows: false,
        defval: ""
      });
      const datz = raw_data.map(r =>
        r.slice(0, process.env.REACT_APP_GPS_PROVIDERS_KEYS.split(",").length)
      );

      if (datz.length - 1 > 1000) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "Maximum number of rows in a file should be 1000 only"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (f.size / 1024 / 1024 > 5) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is too large"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (
        datz.length === 0 ||
        !lodash.isEqual(
          datz[0],
          process.env.REACT_APP_GPS_PROVIDERS_KEYS.split(",")
        )
      ) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "Please use the template provided"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (datz.length === 1) {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Failed",
          errors: "File is empty"
        };
        setFile(tempFile);
        setRe(!re);
        return;
      }

      const providersNameInFile = await datz
        .filter((d, index) => index > 0)
        .map(d => String(d[0]));

      setUploadData(datz);
      setProviders(providersNameInFile);
    };
    reader.readAsArrayBuffer(f);
  };

  useEffect(() => {
    if (data) {
      uploadFile(uploadData);
    }
  }, [data]);

  const pendingFile = () => {
    if (file.length > 0) {
      if (!file[file.length - 1].status) {
        return true;
      }
    }
    return false;
  };

  const [expanded, setExpanded] = useState(-1);

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  const statusColor = status => {
    let style = {
      fontSize: 12,
      color: "#3f3f3f"
    };
    switch (status) {
      case "Failed":
      case "Partial Success":
        style = {
          fontSize: 12,
          color: "#ff7043"
        };
        break;
      case "Success":
        style = {
          fontSize: 12,
          color: "#10e23f"
        };
        break;
      default:
        break;
    }

    return style;
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={handleCloseUpload}
        aria-labelledby="customized-dialog-title"
        open={uploadDialog}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
          Uploads
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            root: classes.dialogContent,
            dividers: classes.dialogContent
          }}
        >
          {file.length > 0 &&
            file.map((fileInfo, index) => (
              <ExpansionPanel
                key={index}
                square
                expanded={index === expanded}
                onClick={() => handleClick(index)}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <Typography className={classes.typography}>
                        {fileInfo.file.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.typography}>
                        {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Typography style={statusColor(fileInfo.status)}>
                            {fileInfo.status}
                          </Typography>
                        </Grid>
                        {fileInfo.status && (
                          <Grid item>
                            <IconButton size="small">
                              {index === expanded ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                {(fileInfo.status === "Failed" ||
                  fileInfo.status === "Partial Success") && (
                  <ExpansionPanelDetails>
                    {typeof fileInfo.errors === "string" ? (
                      <Typography style={{ fontSize: 14, margin: 8 }}>
                        {fileInfo.errors}
                      </Typography>
                    ) : (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "100px" }}>
                              Row
                            </TableCell>
                            <TableCell align="left">Reason</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fileInfo.errors.length > 0 &&
                            fileInfo.errors
                              .filter(row => row.errors !== "")
                              .sort(reorder)
                              .map(row => (
                                <TableRow key={row.name}>
                                  <TableCell component="th" scope="row">
                                    <Typography className={classes.typography}>
                                      {row.row}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    <Typography className={classes.typography}>
                                      {row.errors}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    )}
                  </ExpansionPanelDetails>
                )}
              </ExpansionPanel>
            ))}
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", width: "100%" }}>
            <label htmlFor="icon-upload-file">
              <input
                className="form-control"
                color="primary"
                accept=".csv, .xlsx, .xls"
                type="file"
                id="icon-upload-file"
                style={{ display: "none" }}
                onChange={e => handleUpload(e)}
                disabled={pendingFile()}
              />
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                style={{ backgroundColor: "#ffb677" }}
                startIcon={<PublishIcon />}
                disabled={pendingFile()}
              >
                Add a File
              </Button>
            </label>
            <div style={{ flexGrow: 1 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                variant="contained"
                autoFocus
                color="primary"
                startIcon={<CloudUploadIcon />}
                style={{ color: "white", borderRadius: 50 }}
                onClick={submitUpload}
                disabled={
                  file.length === 0 || file[file.length - 1].status !== ""
                }
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadDialog;
