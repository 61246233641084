import React, { useRef, useState } from "react";
import {
  Box,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { useStyles } from "../TripAllocation.styles";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
import {
  GET_BOOKINGS_NO_HAULERS
} from "../../../../graphql/Queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import TripSelection from "./TripSelection/TripSelection";
import HaulerAllocation from "./HaulerAllocation/HaulerAllocation";
import AccessControl from "../../../../utils/AccessControl";
import useUserContext from "../../../../context/User/useUserContext";
import Forbidden from "../../../../utils/Errors/403/Forbidden";
import UndoSnackbar from "../../../../utils/UndoSnackbar";
import useDialog from "./useDialog";

const AddAllocation = props => {
  const classes = useStyles();
  const history = useHistory();
  const userCtx = useUserContext();
  const isWTIAdmin = _.isEqual(+userCtx.user_level_id, +process.env.REACT_APP_WTI_ADMIN);
  const { location } = props;
  const [step, setStep] = useState(0);
  const allocationsRef = useRef({
    trips: [],
    confirmationDate: null
  });
  
  const [trips, setTrips] = useState([]);
  const [isManual, setIsManual] = useState(true);

  const [date, setDate] = useState({
    from: null,
    to: null
  });

  const { dialog, setDialog, handleDialog, handleCloseDialog } = useDialog();

  /**
   * removes a trip from the list
   * and stores the id and index of the removed trip
   */
  const handleDelete = (trip) => {
    setTrips(prev => prev.filter((t) => t.id !== trip.id));
    handleDialog("deleteTrip");
  };

  const handleUndoDelete = () => {
    setTrips(allocationsRef.current.trips);
    handleCloseDialog("deleteTrip");
  };

  const parseBookingData = bookings => {
    const parsedTrips = bookings.map(trip => {
      const pickups = trip.value.flatMap(so => so.pickups[0]);
      const dropoffs = trip.value.flatMap(so => so.dropoffs.flatMap(a => a));
      
      const { id, trip_number, vehicle_type, vehicle_plate_no, group_ids, client_id } =
        trip.value[0] || null;
        
      if (!vehicle_plate_no) {
        return {
          id: id,
          trip_number: trip_number,
          vehicle_type: vehicle_type,
          pickups: pickups,
          dropoffs: dropoffs,
          group_id: group_ids[0],
          client_id: client_id
        };
      }
    });

    return parsedTrips.filter(t => t);
  };

  const { loading: bookingsLoading } = useQuery(GET_BOOKINGS_NO_HAULERS, {
    variables: {
      is_single_hauler: true,
      conditions: [
        {
          field: "dispatch_type",
          value: ["Not Yet"]
        }
      ],
      or: [
        { 
          field: "status_code_id", 
          value: [
            process.env.REACT_APP_ACCEPTED_STATUS,
            process.env.REACT_APP_SCHEDULED_STATUS
          ] 
        }
      ],
      date_range: {
        field: "pickups.arrival",
        start: moment(date.from).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(date.to).format("YYYY-MM-DD HH:mm:ss")
      },
      orderBy: [
        {
          field: "is_urgent",
          direction: "desc"
        },
        {
          field: "overdue",
          direction: "desc"
        },
        {
          field: "pickups.arrival",
          direction: "desc"
        }
      ],
      first: 10000
    },
    onCompleted: data => {
      const { bookings } = data.get_bookings_per_trip;
      const parsedData = parseBookingData(bookings);

      setTrips(parsedData);
      allocationsRef.current.trips = parsedData;
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: !date.from || !date.to
  });

  const getStepHandler = () => {
    switch (step) {
      case 0:
        return (
          <TripSelection
            trips={trips}
            date={date}
            setDate={setDate}
            handleDelete={handleDelete}
            handlePrevStep={handlePrevStep}
            handleNextStep={handleNextStep}
          />
        );
      case 1:
        return (
          <HaulerAllocation
            trips={trips}
            setTrips={setTrips}
            date={date}
            isManual={isManual}
            allocationsRef={allocationsRef}
            handlePrevStep={handlePrevStep}
            redirectToTable={redirectToTable}
          />
        );
      default:
        break;
    }
  };

  const redirectToTable = () => {
    history.push({
      pathname: `/allocation`,
      state: {
        params: {
          moduleName: process.env.REACT_APP_TRIP_ALLOCATION_MODULE,
          vars: location?.state?.params?.vars
        }
      }
    });
  };

  const hasUnsavedChanges = () => {
    return (!_.isNull(date.from) || !_.isNull(date.to));
  };

  const handleBack = () => {
    if (!hasUnsavedChanges()) {
      redirectToTable();
    }
    setDialog({ ...dialog, discard: hasUnsavedChanges() });
  };

  const handlePrevStep = () => {
    setStep(prev => (prev !== 0 ? prev - 1 : prev));
    handleCloseDialog("deleteTrip");
    if (!step) {
      handleBack();
    }
  };

  const handleNextStep = ({ isManual }) => {
    setStep(prev => (prev === 0 ? prev + 1 : prev));
    setIsManual(isManual);
    handleCloseDialog("deleteTrip");
    if (step) {
      setDialog({ ...dialog, save: true });
    }
  };
  
  if (isWTIAdmin) {
    return <Forbidden />
  }

  return (
    <AccessControl resource="/allocation/trip/" process="add">
      <Box height="100%" display="flex" flexDirection="column">
        <Toolbar style={{ background: "white" }}>
          <IconButton onClick={handleBack}>
            <BackIcon />
          </IconButton>
          <Typography variant="h6">Trip Allocation</Typography>
        </Toolbar>
        <Box
          display="flex"
          flexDirection="column"
          style={{ height: "100%", overflowY: "auto", padding: 16 }}
        >
          {getStepHandler()}
        </Box>
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleCloseDialog("discard")}
          fn={() => redirectToTable()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page? Unsaved changes will be discarded."
        />
        <UndoSnackbar
          classes={classes}
          open={dialog.deleteTrip}
          handleClose={(evt, reason) => {
            if (reason === "clickaway") {
              return;
            }
            handleCloseDialog("deleteTrip");
          }}
          handleAction={handleUndoDelete}
          message="Trip has been removed"
        />
      </Box>
    </AccessControl>
  );
};

export default AddAllocation;
