import React, { createContext, useContext, useEffect, useState } from "react";

export const ReportsFieldsContext = createContext();
export const useReportFieldsContext = () => {
  const context = useContext(ReportsFieldsContext);

  if (!context) {
    throw new Error(
      `Compound components can't rendered outside ReportFieldContext`
    );
  }

  return context;
};

const GPS_STATUS = process.env.REACT_APP_REPORT_TYPE_GPS_STATUS;
const TRIP_SUMMARY = process.env.REACT_APP_REPORT_TYPE_TRIP_SUMMARY;
const VEHICLE_ALERT = process.env.REACT_APP_REPORT_TYPE_VEHICLE_ALERT;
const VEHICLE_UTILIZATION =
  process.env.REACT_APP_REPORT_TYPE_VEHICLE_UTILIZATION;
const FULL_MOVEMENT = process.env.REACT_APP_REPORT_TYPE_FULL_MOVEMENT;
const ALERT_COUNT_DASHBOARD =
  process.env.REACT_APP_REPORT_TYPE_ALERT_COUNT_DASHBOARD;
const VEHICLE_STATUS = process.env.REACT_APP_REPORT_TYPE_VEHICLE_STATUS;
const STANDARD_IDLING = process.env.REACT_APP_REPORT_TYPE_STANDARD_IDLING;
const CICO = process.env.REACT_APP_REPORT_TYPE_CICO;
const CICO_NO_TRIP = process.env.REACT_APP_REPORT_TYPE_CICO_NO_TRIP;
const IGNITION = process.env.REACT_APP_REPORT_TYPE_IGNITION;
const COMPLETE_LOG = process.env.REACT_APP_REPORT_TYPE_COMPLETE_LOG;
const SPEEDING = process.env.REACT_APP_REPORT_TYPE_SPEEDING;
const GEOFENCE_IN_AND_OUT =
  process.env.REACT_APP_REPORT_TYPE_GEOFENCE_IN_AND_OUT;
const FUEL_CONSUMPTION = process.env.REACT_APP_REPORT_TYPE_FUEL_CONSUMPTION;
const DLTB_IDLING = process.env.REACT_APP_REPORT_DLTB_IDLING;
const SPEEDING_DURATION = process.env.REACT_APP_REPORT_SPEEDING_DURATION;
const UNCATEGORIZED = process.env.REACT_APP_REPORT_UNCATEGORIZED;
const CONVAN_INVENTORY = process.env.REACT_APP_REPORT_CONVAN_INVENTORY;
const FUEL_REPORT = process.env.REACT_APP_REPORT_FUEL_REPORT;
const CICO_HOMEBASE = process.env.REACT_APP_REPORT_CICO_HOMEBASE;

const Haulers = 0;
const AlertType = 1;
const VehicleChipInput = 2;
const VehicleSearchInput = 3;
const GeofencesChipInput = 4;
const VehicleStatus = 5;
const GPSStatusChipInput = 6;
const ReportDelaySearchInput = 7;
const TripStatusChipInput = 8;
const ActivityOnlyInput = 9;
const IdlingDuration = 10;
const BatteryLevelSelect = 11;
const ReorderDraggable = 12;

export const patternList = {
  [GPS_STATUS]: [Haulers],
  [TRIP_SUMMARY]: [
    Haulers,
    GPSStatusChipInput,
    TripStatusChipInput,
    ReorderDraggable
  ],
  [VEHICLE_ALERT]: [Haulers, AlertType, VehicleChipInput, ReorderDraggable],
  [VEHICLE_UTILIZATION]: [Haulers, VehicleChipInput, ReorderDraggable],
  [FULL_MOVEMENT]: [
    Haulers,
    VehicleSearchInput,
    ActivityOnlyInput,
    ReorderDraggable
  ],
  [ALERT_COUNT_DASHBOARD]: [
    Haulers,
    AlertType,
    VehicleChipInput,
    ReorderDraggable
  ],
  [VEHICLE_STATUS]: [
    Haulers,
    VehicleChipInput,
    VehicleStatus,
    GPSStatusChipInput,
    ReportDelaySearchInput,
    ReorderDraggable
  ],
  [STANDARD_IDLING]: [
    Haulers,
    VehicleChipInput,
    IdlingDuration,
    ReorderDraggable
  ],
  [CICO]: [
    Haulers,
    VehicleChipInput,
    ReorderDraggable
    // GeofencesChipInput
  ],
  [CICO_NO_TRIP]: [
    Haulers,
    VehicleChipInput,
    ReorderDraggable,
    GeofencesChipInput
  ],
  [IGNITION]: [Haulers, VehicleChipInput, ReorderDraggable],
  [COMPLETE_LOG]: [Haulers, VehicleChipInput, ReorderDraggable],
  [SPEEDING]: [Haulers, VehicleChipInput, ReorderDraggable],
  [SPEEDING_DURATION]: [Haulers, VehicleChipInput, ReorderDraggable],
  [GEOFENCE_IN_AND_OUT]: [Haulers, VehicleChipInput, ReorderDraggable],
  [FUEL_CONSUMPTION]: [Haulers, VehicleChipInput, ReorderDraggable],
  [DLTB_IDLING]: [VehicleChipInput, Haulers],
  [CONVAN_INVENTORY]: [
    Haulers,
    // VehicleChipInput,
    BatteryLevelSelect,
    ReorderDraggable
  ],
  [UNCATEGORIZED]: [VehicleChipInput, Haulers, ReorderDraggable],
  [FUEL_REPORT]: [VehicleChipInput, Haulers, ReorderDraggable],
  [CICO_HOMEBASE]: [VehicleChipInput, Haulers, ReorderDraggable]
};

const FieldGroup = ({ children, reportType = -1 }) => {
  const [selectedReportType, setSelectedReportType] = useState(reportType);

  const onReportTypeChanged = React.useCallback(() => {}, [
    setSelectedReportType
  ]);

  const value = React.useMemo(() => {
    return {
      selectedReportType,
      onReportTypeChanged
    };
  }, [selectedReportType, onReportTypeChanged]);

  return (
    <div>
      <ReportsFieldsContext.Provider value={value}>
        {reportType !== -1 &&
          patternList[reportType] &&
          React.Children.toArray(children).map((child, index) => {
            const clone = React.cloneElement(child, {
              index,
              selectedReportType: reportType,
              pattern: patternList[reportType]
            });

            return clone;
          })}
      </ReportsFieldsContext.Provider>
    </div>
  );
};

const Field = ({ children, ...props }) => {
  const { index, pattern, selectedReportType } = props;
  const isIncludedField = pattern.includes(index);
  const clonedElement = React.cloneElement(children, { selectedReportType });
  return isIncludedField && clonedElement;
};

FieldGroup.Field = Field;

export default FieldGroup;
