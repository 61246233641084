import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Grid,
  Checkbox,
  Divider,
  Chip
} from "@material-ui/core";
import _ from "lodash";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  Event as EventIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontWeight: "bold !important",
    color: "#E96E3F !important"
  },
  select: {
    height: 25
  },
  dataContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 8
  },
  dataSubContainer: {
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginBottom: 8
  },
  chartRoot: {
    padding: 8,
    border: "1px solid #D6D6D6",
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  input: {
    padding: 0
  },
  dateFilterContainer: {
    display: "flex"
  },
  calendarIcon: {
    marginRight: 5,
    cursor: "pointer"
  },
  calendarInput: {
    display: "none"
  },
  calendarLabel: {
    fontSize: "0.85rem"
  }
});

const CloseCancel = props => {
  const classes = useStyles();
  const {
    data,
    pickups,
    dropoffs,
    filterValues,
    setFilterValues,
    tabValue,
    getCursor,
    redirectToTrips,
    redirectToPrebooking,
    applyFilter
  } = props;

  const [locations, setLocations] = useState([]);
  const [ccData, setCcData] = useState({
    closed_trip: {
      count: 0,
      trip_numbers: []
    },
    closed_trip_pre_dispatch: {
      count: 0,
      trip_numbers: []
    },
    closed_trip_trip: {
      count: 0,
      trip_numbers: []
    },
    cancelled_trip: {
      count: 0,
      trip_numbers: []
    },
    cancelled_trip_pre_dispatch: {
      count: 0,
      trip_numbers: []
    },
    cancelled_trip_trip: {
      count: 0,
      trip_numbers: []
    }
  });
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (data) {
      setCcData(prevData => {
        return {
          ...prevData,
          ...data.get_closed_trip,
          ...data.get_cancelled_trip
        };
      });
    }
  }, [data]);

  useEffect(() => {
    const merged = _.unionBy(pickups, dropoffs, "value");
    setLocations(merged);
  }, [pickups, dropoffs]);

  useEffect(() => {
    const startDate = moment(filterValues[0].dateRange.start).format(
      "YYYY-MM-DD"
    );
    if (startDate === selectedDate) {
      applyFilter();
    }
  }, [filterValues[0].dateRange.start]);

  const getSubCount = (title, subData, subDataPath) => {
    /**
     * Common component for subcounts
     * Accepts:
     *  title (string) - subcount card title
     *  subData (object) - contains count and trip_numbers
     *  subDataPath (string) - pathname on card click
     */
    return (
      <Grid item xs={6}>
        <div className={classes.dataSubContainer}>
          <Typography align="center">{title}</Typography>
          <Typography
            variant="h4"
            align="center"
            style={{
              cursor: getCursor(subData.count)
            }}
            onClick={() => {
              if (subData.count > 0) {
                if (subDataPath === "/trips") {
                  redirectToTrips(subData.trip_numbers);
                }
                if (subDataPath === "/prebooking") {
                  redirectToPrebooking(subData.trip_numbers);
                }
              }
            }}
          >
            {subData.count > 0 ? subData.count : 0}
          </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.chartRoot}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.title}>CLOSED/CANCELLED</Typography>
          <div className={classes.dateFilterContainer}>
            <Typography className={classes.calendarIcon}>
              <EventIcon
                style={{ fontSize: "0.875rem" }}
                onClick={() => setOpenDatePicker(true)}
              />
            </Typography>
            <span className={classes.calendarInput}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={filterValues[0].dateRange.start}
                  onChange={nv => {
                    setOpenDatePicker(false);
                    const temp = [...filterValues];
                    temp[tabValue] = {
                      ...temp[tabValue],
                      dateRange: {
                        start: moment(nv).format("YYYY-MM-DD 00:00:00"),
                        end: moment(nv).format("YYYY-MM-DD 23:59:59")
                      }
                    };
                    setFilterValues(temp);
                    setSelectedDate(moment(nv).format("YYYY-MM-DD"));
                  }}
                  open={openDatePicker}
                  onClose={() => {
                    setOpenDatePicker(false);
                  }}
                  disableFuture
                  showTodayButton
                  todayLabel="NOW"
                />
              </MuiPickersUtilsProvider>
            </span>
            <Typography className={classes.calendarLabel}>
              As of{" "}
              {moment(filterValues[0].dateRange.start).format("MMM DD, YYYY")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography>Location: </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={locations}
                limitTags={2}
                getOptionLabel={option => option.label}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        color="primary"
                        label={option.label}
                      />
                    );
                  })
                }
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={
                      filterValues[tabValue].locations.length === 0 ? "All" : ""
                    }
                  />
                )}
                disableClearable
                value={filterValues[tabValue].locations}
                onChange={(e, nv) => {
                  const temp = [...filterValues];
                  temp[tabValue] = {
                    ...temp[tabValue],
                    locations: nv
                  };
                  setFilterValues(temp);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            style={{ padding: 50 }}
            justifyContent="center"
          >
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Closed</Typography>
                <Typography variant="h4" align="center">
                  {ccData.closed_trip.count > 0 ? ccData.closed_trip.count : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  ccData.closed_trip_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", ccData.closed_trip_trip, "/trips")}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.dataContainer}>
                <Typography align="center">Cancelled</Typography>
                <Typography variant="h4" align="center">
                  {ccData.cancelled_trip.count > 0
                    ? ccData.cancelled_trip.count
                    : 0}
                </Typography>
              </div>
              <Grid container spacing={1}>
                {getSubCount(
                  "P",
                  ccData.cancelled_trip_pre_dispatch,
                  "/prebooking"
                )}
                {getSubCount("T", ccData.cancelled_trip_trip, "/trips")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CloseCancel;
