import { makeStyles } from "@material-ui/core";

export default makeStyles({
  cellRoot: {
    paddingLeft: "18px !important",
    paddingTop: "14px !important",
    paddingBottom: "14px !important"
  },
  othersRoot: {
    color: "#00a7f7",
    fontWeight: 600,
    letterSpacing: "0.6px"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
});
