/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Table,
  InputBase,
  IconButton,
  Toolbar,
  Tooltip,
  InputAdornment,
  Switch,
  withStyles,
  Box
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  TripOrigin,
  HelpRounded
  // LinkOff as LinkOffIcon
} from "@material-ui/icons";
import {Search as SearchIcon} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import {
  MuiPickersUtilsProvider,
  // DatePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import ErrorPage from "../../../../utils/errorPage";
import Header from "../../Header";
import PairVehicle from "../../../../utils/modals/PairVehicle";
import SelectGroup from "../../../../utils/modals/SelectGroup";
import Loading from "../../../../utils/Loading";
import {
  GET_CLIENTS_QUERY_WO_GROUPS,
  GET_DEVICE,
  GET_GPS_PROVIDERS,
  GET_DEVICE_TYPES,
  // GET_ADMIN_CLIENT
} from "../../../../graphql/Queries";
import { EDIT_DEVICE, EDIT_VEHICLE, ADD_NOTIFICATION } from "../../../../graphql/Mutations";
import ConfirmationDialog from "../../../../utils/modals/ConfirmationDialog";
// import UserContext from "../../../../context/UserContext";
import validate from "../../../../utils/validation";
import redirectToTable from "../../../../utils/redirect";
import SearchContext from "../../../../context/SearchContext";
import { gpsStatus } from "../../../Utils";
import Battery from "../../../../utils/Battery";
import AddLogs from "../../../../utils/functions/AddLogs";
import useFormStyles from "../../../../styles/useFormStyles";
import AccessControl from "../../../../utils/AccessControl";
import useUserContext from "../../../../context/User/useUserContext";
import { getMaxLength } from "./utils";
import { getBookingsOfVehicle, resetTrips } from "../../../../utils/functions/unpairingFunctions";
import UnpairingDialog from "../../../../utils/modals/UnpairingDialog";
import debounceKeywordChange from "../../../../utils/functions/debounce.util";

const { REACT_APP_GPSDEVICES_MODULE } = process.env;
const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#F8F8F8",
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      border: "5px solid #f8f8f8"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      border: "4px solid #f8f8f8",
      background: "#969696"
    },
    "&::-webkit-scrollbar-track": {
      border: "5px solid #f8f8f8"
    }
  },
  searchRoot: {
    padding: "0px 9px",
    display: "flex",
    alignItems: "center",
    width: 325,
    height: 36,
    backgroundColor: "#fafafa",
    borderRadius: "25px",
    boxShadow: "none",
    border: "2px solid #ccc"
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 600,
    fontSize: 14,
    "& .MuiInputBase-input": {
      letterSpacing: 0.7
    }
  },
  container: {
    minHeight: "calc(100% - 66px - 32px - 36px)", // 66 for header, 32 for top + bottom margins
    margin: theme.spacing(0, 4, 2),
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    borderRadius: 0
  },
  scrollable: {
    height: "calc(100% - 66px)",
    boxSizing: "border-box",
    overflow: "auto"
  },
  field: {
    margin: "18px 0 0 0",
    width: "90%",
    "& .Mui-disabled svg": {
      display: "none"
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      opacity: 1
    },
    "& input:read-only::-webkit-inner-spin-button": {
      opacity: 0
    },
    "& input:read-only::-webkit-outer-spin-button": {
      opacity: 0
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: 600,
      color: "#000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #CBCBCB"
    },
    "& .MuiInputLabel-formControl": {
      letterSpacing: 1
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "12px 0"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#CBCBCB"
    }
  },
  addEntryButton: {
    fontSize: 12,
    letterSpacing: 0.7,
    // fontWeight: 600,
    textTransform: "capitalize"
  },
  column: {
    fontSize: "1.5rem"
  },
  button: {
    borderRadius: "100px",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto",
    width: "200px"
  },
  formControl: {
    margin: theme.spacing(1),
    display: "block"
  },
  tabs: {
    margin: theme.spacing(2, 4, 0)
  },
  tab: {
    width: "150px",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  tab_active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
  alert: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  alertDate: {
    "& + .MuiFormHelperText-root.Mui-error": {
      marginTop: 0
    }
  },
  alertGroups: {
    boxSizing: "border-box",
    borderRadius: 0,
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "100%",
    marginTop: "6px 8px"
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  textField: {
    "& .MuiInput-underline:after, & .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    verticalAlign: "unset"
  },
  checkBoxTitle: { fontSize: 14, fontWeight: 600, letterSpacing: 0.7 },
  mediumTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#565656",
    letterSpacing: 0.7
  },
  tabTitle: {
    fontWeight: 600,
    letterSpacing: 1.5,
    textTransform: "capitalize"
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  tooltipTitle: {
    padding: 0,
    margin: 0
  },
  switch: {
    margin: "18px 0 0 0",
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    display: "flex",
    alignItems: "center"
  },
  unpairBox: {
    // padding: "2px 4px",
    display: "flex",
    alignItems: "end"
  },
  unpairIcon: {
    marginLeft: -45,
  },
  customTextFieldLabel: {
    fontSize: "12px",
    fontFamily: "Nunito",
    lineHeight: 1,
    letterSpacing: "1px",
    fontWeight: 600,
    color: "black",
    marginTop: "22px"
  },
  customInputLabel: {
    fontSize: "12px",
    fontFamily: "Nunito",
    lineHeight: 1,
    letterSpacing: "1px",
    fontWeight: 600,
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px"
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 17,
    padding: 0,
    display: "flex",
    marginLeft: 15
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8
      }
    }
  },
  thumb: {
    width: 13,
    height: 13,
    boxShadow: "none"
  },
  track: {
    background: "#aaa"
  },
  checked: {}
}))(Switch);

export default function Device(props) {
  // const user = useContext(UserContext);
  const [activeTab, setActiveTab] = React.useState(0);
  const user = useUserContext();
  const classes = useStyles(activeTab);
  const formStyles = useFormStyles();
  const contextData = useContext(SearchContext);
  const [state, setState] = useState({
    name: "",
    devicealias: "",
    device_type_id: "",
    client_id: -1,
    remarks: "",
    mirror_to_ngt: false,
    contract_ref_no: "",
    reserve_field: ""
  });
  const [keyword, setKeyword] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [deviceConfig, setDeviceConfig] = useState({
    mobileno: "",
    intervalreporting: 1,
    battery_intervalreporting: 0,
    aux_voltage: 0,
    warranty: moment().format("MM-DD-YYYY"),
    dateinstalled: moment().format("MM-DD-YYYY"),
    expirationdate: moment().format("MM-DD-YYYY"),
    hasFuelSensor: false,
    fuelrefill: 0,
    fuelpilferage: 0,
    hastempsensor: false,
    mintemp: 0,
    maxtemp: 0,
    monitordooropenning: false,
    checkhomebasestatus: false,
    speedlimit: 0,
    maxvalidspeed: 0,
    highwayspeedlimit: 0,
    warningspeed: 0,
    idlesetting: 1,
    gps_provider_id: null,
    use_start_end_idle: true,
    ha_min: 0,
    ha_max: 0,
    hb_min: 0,
    hb_max: 0
  });
  const [initialData, setInitialData] = useState({
    state: _.cloneDeep(state),
    deviceConfig: _.cloneDeep(deviceConfig)
  });
  const [errors, setErrors] = useState({
    name: "",
    devicealias: "",
    mobileno: "",
    client_id: "",
    device_type_id: "",
    gps_provider_id: "",
    groupList: "",
    idlesetting: "",
    intervalreporting: "",
    battery_intervalreporting: "",
    aux_voltage: "",
    speedlimit: "",
    maxvalidspeed: "",
    highwayspeedlimit: "",
    warningspeed: "",
    warranty: "",
    dateinstalled: "",
    expirationdate: "",
    ha_min: "",
    ha_max: "",
    hb_min: "",
    hb_max: "",
    contract_ref_no: "",
    reserve_field: "",
  });
  const [vehicleModal, toggleVehicleModal] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState();
  const [dialog, setDialog] = useState({ save: false, discard: false });
  const [forceRerender, setForceRerender] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupModal, toggleGroupModal] = useState(false);
  const [render, setRender] = useState(false);
  const [primaryClient, setPrimaryClient] = useState({});
  // const [vehicleClient, setVehicleClient] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [unpair, setUnpair] = useState(false);
  const [clientCode, setClientCode] = useState({
    bdg: "",
    qne: ""
  });
  const history = useHistory();
  const { id } = useParams();
  const { loading, data: dataClients, refetch } = useQuery(GET_CLIENTS_QUERY_WO_GROUPS, {
    variables: {
      first: 15
    }
  });
  const newVehicleRef = useRef();
  const bookingRef = useRef();

  const { data: deviceData, loading: loading1 } = useQuery(GET_DEVICE, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        Swal.fire({
          title: returnData[0],
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          onClose: () => {
            redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          }
        });
      }
    }
  });

  const { loading: deviceTypesLoading, data: deviceTypesData } = useQuery(
    GET_DEVICE_TYPES
  );

  // const [get_vehicle_client] = useLazyQuery(GET_ADMIN_CLIENT, {
  //   // onCompleted: data => {
  //   //   if (data) {
  //   //     setVehicleClient({
  //   //       id: Number(data.get_client.group_ids),
  //   //       name: data.get_client.group_names.toString()
  //   //     });
  //   //   }
  //   // },
  //   variables: {
  //     first: 50
  //   },
  //   fetchPolicy: "network-only"
  // });

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < groupList.length) {
        const displayedData = groupList.map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const onSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = groupList.map(n => n.id);
      // Deselects all items(clients)
      const newSelected = tempSelected
        .filter(clientId => displayedData.indexOf(clientId) < 0)
        .map(clientSelectedId => clientSelectedId);
      // Set new selected items(clients)
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      groupList.map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleClick = (_event, clientId) => {
    const selectedIndex = selected.indexOf(clientId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, clientId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleCloseUnpair = () => {
    newVehicleRef.current = null;
    setUnpair(false);
  }

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleDeleteGroups = () => {
    setGroupList(groupList.filter(item => !selected.includes(item.id)));
    setSelected([]);
  };

  const {
    loading: gpsProvidersLoading,
    data: gpsProvidersData
  } = useQuery(GET_GPS_PROVIDERS, { variables: { first: 50 } });

  const initialState = () => {
    const temp = [];
    const tempConfig = [];
    const tempGroupList = [];
    const { clients } = dataClients.get_clients;
    let clientTemp;
    const qne = deviceData?.get_device?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "qne")
    const bdg = deviceData?.get_device?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "bdg")
    Object.keys(deviceData.get_device)
      .filter(element => element in state)
      .forEach(element => {
        temp.push({ [element]: deviceData.get_device[element] });
      });
    if(deviceData.get_device.device_config){
    Object.keys(deviceData.get_device.device_config)
      .filter(element => element in deviceConfig)
      .forEach(element => {
        tempConfig.push({
          [element]: deviceData.get_device.device_config[element]
        });
      });
    }

    for (let i = 0; i < deviceData.get_device.group_ids.length; i += 1) {
      tempGroupList.push({
        id: deviceData.get_device.group_ids[i],
        name: deviceData.get_device.group_names[i]
      });
    }
    // if (clients.length > 0 && deviceData.get_device.client_id) {
    //   clientTemp = clients.filter(
    //     cli => cli.id.toString() === deviceData.get_device.client_id.toString()
    //   );
    //   if (clientTemp.length > 0) {
    //     setPrimaryClient(clientTemp[0]);
    //   }
    // }
    clientTemp = { id: deviceData.get_device.client_id, name: deviceData.get_device.client_name }
    setPrimaryClient({ id: deviceData.get_device.client_id, name: deviceData.get_device.client_name })
    setGroupList(tempGroupList);
    setState(Object.assign(state, ...temp));
    if (gpsProvidersData) {
      gpsProvidersData.get_gps_providers.gps_providers.map(provider => {
        if (Number(provider.id) === Number(tempConfig[5]?.gps_provider_id)) {
          tempConfig[5].gps_provider_id = provider;
        }
        return tempConfig;
      });
    }
    setClientCode({...clientCode, 
      qne,
      bdg
    })
    setDeviceConfig(Object.assign(deviceConfig, ...tempConfig));
    // if (deviceData.get_device.vehicle_info) {   
    //   delete deviceData.get_device.vehicle_info.icon;
    // }
    setInitialData({
      state: Object.assign(initialData.state, ...temp),
      deviceConfig: Object.assign(initialData.deviceConfig, ...tempConfig),
      vehicle_info: _.cloneDeep(deviceData.get_device.vehicle_info),
      groupList: _.cloneDeep(tempGroupList),
      client: _.cloneDeep(clientTemp)
    });
    setVehicleInfo(deviceData.get_device.vehicle_info);
    setRerender(!rerender);
  };

  useEffect(() => {
    if (!loading1 && !loading) {
      if (deviceData) {
        initialState();
        // get_vehicle_client({
        //   variables: {
        //     id: deviceData?.get_device?.vehicle_info?.client_id
        //   }
        // }); dataClients,
      }
    }
  }, [deviceData, loading1, loading, gpsProvidersData]);

  const { setSearchKeyword, searchKeyword } = contextData;

  useEffect(() => {
    setSearchKeyword({
      ...searchKeyword,
      submit: false,
      moduleName: REACT_APP_GPSDEVICES_MODULE
    });
  }, []);

  const [updateVehiclePartner] = useMutation(EDIT_VEHICLE, {
    onCompleted: () => {
      Swal.fire({
        title: "Saved",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
        // onClose: () => {
        //  redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
        // }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
          // redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          // set values again for device table filter after saving
          history.push({
            pathname: "/admin",
            state: {
              params: {
                moduleName: process.env.REACT_APP_GPSDEVICES_MODULE,
                vars: history?.location?.state?.vars,
                filter: history?.location?.state?.filter
              }
            }
          })
        }
      });
    }
  });

  // const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [editDevice] = useMutation(EDIT_DEVICE, {
    onCompleted(data) {
      newVehicleRef.current = null;
      if (data) {
        if (data.edit_device.success) {
          AddLogs("Admin - GPS Devices", "update", state.name);
          const { vehicle_info } = data.edit_device.device;
          if(initialData.vehicle_info?.id && !vehicleInfo.id) {
            AddLogs(
              "Admin - GPS Devices",
              "unpaired_device",
              [initialData.state.name, initialData.vehicle_info.plateno]
            );
            // addNotif({
            //   variables: {
            //     notification: {
            //       client_id: [data.edit_device.device.client_id],
            //       vehicle_plate_no: initialData.vehicle_info.plateno,
            //       datestamp: moment()
            //         .format("YYYY-MM-DD HH:mm:ss"),
            //       notification: `GPS Device ${initialData.state.name} was unpaired_device to Vehicle/CV ${initialData.vehicle_info.plateno}`,
            //       category: "User Activity",
            //       is_archived: false,
            //       creator: "Tmsuite-web",
            //       group_ids: data.edit_device.device.group_ids,
            //       group_names: data.edit_device.device.group_names
            //     }
            //   }
            // });
          } else if (!_.isEqual(initialData.vehicle_info?.id, vehicleInfo.id)) {
            AddLogs(
              "Admin - GPS Devices",
              "paired_device",
              [state.name, vehicle_info.plateno]
            );
            // addNotif({
            //   variables: {
            //     notification: {
            //       client_id: [data.edit_device.device.client_id],
            //       vehicle_plate_no: vehicle_info.plateno,
            //       datestamp: moment()
            //         .format("YYYY-MM-DD HH:mm:ss"),
            //       notification: `GPS Device ${state.name} was paired to Vehicle/CV ${vehicle_info.plateno}`,
            //       category: "User Activity",
            //       is_archived: false,
            //       creator: "Tmsuite-web",
            //       group_ids: data.edit_device.device.group_ids,
            //       group_names: data.edit_device.device.group_names
            //     }
            //   }
            // });
          }

          if (data.edit_device.device.vehicle_info) {
            updateVehiclePartner({
              variables: {
                id: Number(data.edit_device.device.vehicle_info.id),
                vehicle: {
                  plateno: data.edit_device.device.vehicle_info.plateno,
                  group_ids: data.edit_device.device.group_ids
                }
              }
            });
          } else {
            updateVehiclePartner({
              variables: {
                id: Number(data.edit_device.device.vehicle_info.id),
                vehicle: {
                  plateno: data.edit_device.device.vehicle_info.plateno,
                  group_ids: null,
                  device_id: 0
                }
              }
            });
          }
          // Swal.fire({
          //   title: "Saved",
          //   icon: "success",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   onClose: () => {
          //     redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          //   }
          // }).then(result => {
          //   if (result.dismiss === Swal.DismissReason.timer) {
          //     redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          //   }
          // });
        } else {
          const temp = data.edit_device.error.map(element => {
            const errorKey = element.field.split(".");
            if (errorKey.length > 1) {
              const secondKey = errorKey[1];
              return { [secondKey]: element.message };
            }

            return { [element.field]: element.message };
          });
          // temp.push(element.split(".").length > 1 ? {  : element.message })
          setErrors(Object.assign(errors, ...temp));
          setRender(!render);
        }
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      if (returnError) {
        const returnData = returnError.map(el => {
          return el.message;
        });
        if (returnData) {
          Swal.fire({
            title: returnData[0],
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            onClose: () => {
              redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
            }
          });
        }
      } else {
        Swal.fire({
          title: "Saved",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          // onClose: () => {
          //   redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
          // }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            // redirectToTable(history, process.env.REACT_APP_GPSDEVICES_MODULE);
            // set values again for device table filter after saving
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_GPSDEVICES_MODULE,
                  vars: history?.location?.state?.vars,
                  filter: history?.location?.state?.filter
                }
              }
            })
          }
        });
      }
    }
  });

  const check = field => {
    if (!errors[field])
      if (field === "mobileno") {
        // if (!deviceConfig[field]) {
        //   return "This field is required";
        // }
        // if (deviceConfig[field].length < 11) {
        //   return "Contact number must be 11 digits";
        // }
      } else if (field === "gps_provider_id") {
        return deviceConfig.gps_provider_id === null
          ? "This field is required"
          : "";
      } else if (field === "groupList") {
        return groupList.length > 0 ? "" : "This field is required";
      } else if (field === "client_id") {
        return _.isEmpty(primaryClient) ? "This field is required" : "";
      } else {
        // return state[field] === "" || state[field] === -1
        //   ? "This field is required"
        //   : "";
      }
    return errors[field];
  };

  const validateFields = () => {
    const keys = Object.keys(errors);
    const temp = [];
    keys.map(element => temp.push({ [element]: check(element) }));
    setErrors(Object.assign(errors, ...temp));
    return keys.every(element => errors[element] === "");
  };

  const handleOpen = name => {
    if (name === "save") {
      if (validateFields()) setDialog({ ...dialog, [name]: true });
      else setForceRerender(!forceRerender);
    } else {
      setDialog({ ...dialog, [name]: true });
    }
  };

  function openGroupModal() {
    toggleGroupModal(true);
  }

  function closeGroupModal() {
    toggleGroupModal(false);
  }

  const handlePrimaryClient = (e, val) => {
    const qne = val?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "qne")
    const bdg = val?.clients_codes?.find(c => c?.remarks?.toLowerCase() === "bdg")

    setPrimaryClient(val);

    setClientCode({...clientCode, 
      qne,
      bdg
    })

  };

  const handleRemoveGroup = grp_id => {
    const temp = groupList;
    const index = temp.map(g => g.id).indexOf(grp_id);
    temp.splice(index, 1);
    setGroupList(temp);
    setRender(!render);
  };

  const handleGroupList = array => {
    setGroupList(array);
    setErrors({ ...errors, groupList: "" });
  };

  const checkChanges = () => {
    return (
      Object.keys(state)
        .filter(element => element in deviceData.get_device)
        .some(element => state[element] !== deviceData.get_device[element]) ||
      Object.keys(deviceConfig)
        .filter(element => element in deviceData.get_device.device_config)
        .some(element => {
          if (element === "gps_provider_id") {
            return (
              parseInt(deviceConfig[element]?.id) !==
              deviceData.get_device.device_config[element]
            );
          }

          return (
            deviceConfig[element] !==
            deviceData.get_device.device_config[element]
          ) || !_.isEqual(initialData.vehicle_info, vehicleInfo) || !_.isEqual(initialData.groupList, groupList) || !_.isEqual(initialData.client, primaryClient);
        })
    );
  };

  const toggleEditMode = event => {
    if (!event.target.checked) {
      if (checkChanges()) {
        handleOpen("discard");
      } else {
        setEditMode(event.target.checked);
      }
    } else {
      setEditMode(event.target.checked);
    }
    setSelected([]);
  };

  const handleClose = name => {
    setDialog({ ...dialog, [name]: false });
  };

  function openVehicleModal() {
    toggleVehicleModal(true);
  }

  function closeVehicleModal() {
    toggleVehicleModal(false);
  }

  const discarded = () => {
    initialState();
    setEditMode(false);
    handleClose("discard");
    setErrors([]);
    // Swal.fire({
    //   title: "Discarded",
    //   icon: "success",
    //   showConfirmButton: false,
    //   timer: 3000
    // });
  };

  const {
    monitordooropenning,
    checkhomebasestatus,
    hasFuelSensor,
    hastempsensor
  } = deviceConfig;

  const handleChangeTab = newValue => {
    setActiveTab(newValue);
  };

  const handleGPSProviderConfig = (_e, newValue) => {
    setDeviceConfig({
      ...deviceConfig,
      gps_provider_id: newValue
    });
    setErrors({ ...errors, gps_provider_id: "" });
  };

  const handleBlur = e => {
    const { name, value } = e.target;
    let error = "";
    switch (name) {
      case "name":
        if (value.trim() === "") {
          error = "This field is required";
        } else if (!validate("alphanumeric_default", value.trim())) {
          error = "Alphanumeric characters only";
        }
        break;
      case "mobileno":
        if (value === "") {
          error = "";
          // error = "This field is required";
        } else if (!validate("mobile_number_ph", value)) {
          error = "Invalid Mobile Number";
        }
        break;
      case "aux_voltage":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Auxiliary voltage (mV) is invalid format";
        } else if (value > 10080 || value < 0)
          error = "Invalid value min: 0, max: 10,080";
        break;
      case "idlesetting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Idle setting (min) is invalid format";
        } else if (value > 10080 || value < 1)
          error = "Invalid value min: 1, max: 10,080";
        break;
      case "intervalreporting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Interval reporting (min) is invalid format";
        } else if (value > 10080 || value < 1)
          error = "Invalid value min: 1, max: 10,080";
        break;
      case "battery_intervalreporting":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Auxiliary Interval Reporting (min) is invalid format";
        } else if (value > 10080 || value < 0)
          error = "Invalid value min: 0, max: 10,080";
        break;
      case "speedlimit":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Speed Limit is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "maxvalidspeed":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Max Valid Speed is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "highwayspeedlimit":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Highway Speed Limit is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "warningspeed":
        if (value === "") {
          error = "This field is required";
        } else if (!validate("numeric", value)) {
          error = "Warning Speed is invalid format";
        } else if (value > 500 || value < 0)
          error = "Invalid value min: 0, max: 500";
        break;
      case "dateinstalled":
      case "warranty":
      case "expirationdate":
        if (value === null || value === "") {
          error = "This field is required";
        } else if (moment(value).format("MMM DD YYYY") === "Invalid date") {
          error = "Invalid date format";
        }
        break;
      case "devicealias":
        if (value.trim() === "") {
          error = "This field is required";
        } 
        // else if (!validate("alphanumeric_default", value)) {
        //   error = "Alphanumeric characters only";
        // }
        break;
      case "contract_ref_no":
      case "reserve_field":
        if (!validate("alphanumeric", value)) {
          error = "Alphanumeric characters only";
        } else if(value.length > 50) {
            error = "Maximum of 50 characters only"
        } else {
          error = ""
        }
        break;
      default:
        break;
    }

    // setDeviceConfig({
    //   ...deviceConfig,
    //   [name]: type === "checkbox" ? checked : val
    // });
    setErrors({ ...errors, [name]: error });
  };

  const handleConfig = e => {
    const { name, value, checked, type } = e.target;
    let val = value;
    let error = "";
    switch (name) {
      case "mobileno":
        const maxLength = getMaxLength(val)
        if(val.length > maxLength){
          val = deviceConfig.mobileno
        }
        break;
      case "ha_min":
      case "ha_max":
        if (+val >= -0.09 && +val <= 99) {
          val = +value;
        } else {
          error = "Harsh Acceleration(HA) value min: -0.09, max: 99";
        };
        break;
      case "hb_min":
      case "hb_max":
        if (+val >= -0.09 && +val <= 99) {
          val = +value;
        } else {
          error = "Harsh Braking(HB) value min: -0.09, max: 99";
        };
        break;
      // case "aux_voltage":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Auxiliary voltage (mV) is invalid format";
      //   } else if (value > 10080 || value < 0)
      //     error = "Auxiliary voltage (mV) value min: 0, max: 10,080";
      //   break;
      // case "idlesetting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Idle setting (min) is invalid format";
      //   } else if (value > 10080 || value < 1)
      //     error = "Idle setting (min) value min: 1, max: 10,080";
      //   break;
      // case "intervalreporting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Interval reporting (min) is invalid format";
      //   } else if (value > 10080 || value < 1)
      //     error = "Interval reporting (min) value min: 1, max: 10,080";
      //   break;
      // case "battery_intervalreporting":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Auxiliary Interval Reporting (min) is invalid format";
      //   } else if (value > 10080 || value < 0)
      //     error =
      //       "Auxiliary Interval Reporting (min) value min: 0, max: 10,080";
      //   break;
      // case "speedlimit":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Speed Limit is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Speed Limit value min: 0, max: 500";
      //   break;
      // case "maxvalidspeed":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Max Valid Speed is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Max Valid Speed value min: 0, max: 500";
      //   break;
      // case "highwayspeedlimit":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Highway Speed Limit is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Highway Speed Limit value min: 0, max: 500";
      //   break;
      // case "warningspeed":
      //   val = parseInt(value, 10);
      //   if (value === "") {
      //     error = "This field is required";
      //   } else if (!validate("numeric", value)) {
      //     error = "Warning Speed is invalid format";
      //   } else if (value > 500 || value < 0)
      //     error = "Warning Speed value min: 0, max: 500";
      //   break;
      default:
        // val = parseInt(value, 10);
        break;
    }
    setDeviceConfig({
      ...deviceConfig,
      [name]: type === "checkbox" ? checked : +val
    });
    setErrors({ ...errors, [name]: error });
  };

  const handleDate = (val, name) => {
    setDeviceConfig({
      ...deviceConfig,
      [name]: val
    });
  };

  const handleSelectAll = e => {
    const { checked } = e.target;
    setDeviceConfig({
      ...deviceConfig,
      monitordooropenning: checked,
      checkhomebasestatus: checked,
      hasFuelSensor: checked,
      hastempsensor: checked
    });
  };

  const handlePair = () => {
    openVehicleModal();
    // get_vehicle_client({
    //   variables: {
    //     id: vehicleInfo?.client_id
    //   }
    // });
  };

  // React.useEffect(() => {
  //   if (vehicleInfo) {
  //     if (vehicleInfo.plateno) {
  //       get_vehicle_client({
  //         variables: {
  //           id: vehicleInfo?.client_id
  //         }
  //       });
  //     }
  //   }
  // }, [vehicleInfo]);

  const processUpdateDevice = () => {
    // get_vehicle_client({
    //   variables: {
    //     id: vehicleInfo?.client_id
    //   }
    // });

    const group_ids = groupList.map(gl => parseInt(gl.id, 10));

    editDevice({
      variables: {
        id: id,
        device: {
          remarks: state.remarks,
          name: state.name,
          devicealias: state.devicealias,
          device_type_id: parseInt(state.device_type_id, 10),
          client_id: parseInt(primaryClient.id, 10),
          // vehicle_info: vehicleInfo,
          vehicle_info: {
            id: vehicleInfo?.id ? vehicleInfo.id : 0,
            device_id: vehicleInfo?.device_id ? vehicleInfo.device_id : 0,
            plateno: vehicleInfo?.plateno ? vehicleInfo.plateno : ""
          },
          group_ids: group_ids,
          device_config: {
            ...deviceConfig,
            gps_provider_id: parseInt(deviceConfig.gps_provider_id.id, 10),
            battery_intervalreporting: +deviceConfig.battery_intervalreporting,
            intervalreporting: +deviceConfig.intervalreporting,
            aux_voltage: +deviceConfig.aux_voltage,
            highwayspeedlimit: +deviceConfig.highwayspeedlimit,
            idlesetting: +deviceConfig.idlesetting,
            maxvalidspeed: +deviceConfig.maxvalidspeed,
            speedlimit: +deviceConfig.speedlimit,
            warningspeed: +deviceConfig.warningspeed,
            fuelpilferage: +deviceConfig.fuelpilferage,
            fuelrefill: +deviceConfig.fuelrefill,
            maxtemp: +deviceConfig.maxtemp,
            mintemp: +deviceConfig.mintemp
          },
          device_status: { remarks: "Placeholder" },
          mirror_to_ngt: state.mirror_to_ngt,
          contract_ref_no: state.contract_ref_no,
          reserve_field: state.reserve_field
        }
      }
    });
  }  

  const handleSubmit = async e => {
    e.preventDefault();

    // Check if vehicle info is changed
    if (
      +initialData.vehicle_info?.id && 
      !_.isEqual(initialData.vehicle_info?.id, vehicleInfo.id)
    ) {
      // Reset future trips before editing device
      if (bookingRef.current?.futureTrips?.forReset.length) {
        const resetResult = await resetTrips(bookingRef.current.futureTrips.forReset);
        if (resetResult.success) {
          processUpdateDevice();
        } else {
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              container: `${classes.swal}`
            }
          });
        }
      } else {
        processUpdateDevice();
      }
    } else {
      processUpdateDevice();
    }
  };

  const handleUnpaired = () => {
    if(vehicleModal) closeVehicleModal();
    setVehicleInfo(newVehicleRef.current || {
      id: 0,
      device_id: 0,
      plateno: ""
    })
  };

  const allSelected = () => {
    return (
      monitordooropenning &&
      checkhomebasestatus &&
      hasFuelSensor &&
      hastempsensor
    );
  };

  // const handleKeyPress = event => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   // Characters that are not included in this list (a-zA-Z0-9) are not allowed.
  //   if (/[^a-zA-Z0-9]/g.test(keyValue)) {
  //     event.preventDefault();
  //   }
  // };

  const handleChange = event => {
    const { name, type, checked, value } = event.target;
    const error = "";
    switch (name) {
      // case "name":
      //   error = validate("alphanumeric_default", value)
      //     ? ""
      //     : "Alphanumeric characters only";
      //   break;
      // case "devicealias":
      //   error = validate("alphanumeric_default", value)
      //     ? ""
      //     : "Alphanumeric characters only";
      //   break;
      default:
        break;
    }
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value
    });
    setErrors({ ...errors, [name]: error });
  };

  useEffect(() => {
    setErrors({ ...errors, vehicleInfo: "" });
  }, [vehicleInfo]);

  const isWTIAdmin = user.user_level_id === process.env.REACT_APP_WTI_ADMIN;

  const onPrimaryClientChanged = event => {
    refetch({
      first: 15,
      keyword: event.target.value
    })
  }
  const debouncedPrimaryClientChanged = React.useMemo(
    () => debounceKeywordChange(onPrimaryClientChanged, 250),
    []
  );

  if (loading || deviceTypesLoading || gpsProvidersLoading || loading1)
    return <Loading />;

  if (!deviceData) return <ErrorPage />;

  const { pathname } = props.location;

  return (
    <AccessControl
      resource={"/admin/wti/devices/view/"}
      process="view"
      setEditPermission={setEditPermission}
    >
      <div className={classes.root}>
        <Header
          process={editMode ? "Edit" : "View"}
          // process={
          //   _.isEqual(initialData.state, state) &&
          //   _.isEqual(initialData.deviceConfig, deviceConfig)
          //     ? "View"
          //     : "Edit"
          // }
          moduleName={process.env.REACT_APP_GPSDEVICES_MODULE}
          history={history?.location?.state}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          changesDialog={changes}
          setChanges={setChanges}
          editPermission={editPermission}
          redirect={redirect}
          setRedirect={setRedirect}
          setDiscard={setDiscard}
          isDataNotChanged={
            _.isEqual(state, initialData.state) &&
            _.isEqual(deviceConfig, initialData.deviceConfig) &&
            _.isEqual(initialData.vehicle_info, vehicleInfo) &&
            _.isEqual(initialData.groupList, groupList) &&
            _.isEqual(initialData.client, primaryClient)
          }
        />
        <div className={classes.scrollable}>
        <div style={{ display: "flex" }} className={classes.tabs}>
          <Button
            variant="contained"
            style={{
              background: activeTab === 0 ? "#40A8FF" : "white",
              color: activeTab === 0 ? "white" : "black",
              borderRadius: 0,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              padding: 12,
              boxShadow: "none",
              border: "1px solid rgb(0 0 0 / 12%)",
              borderBottom: "none"
            }}
            className={[classes.tab, activeTab === 0 && classes.tab_active]}
            onClick={() => handleChangeTab(0)}
          >
            <span className={classes.tabTitle}>Device Info</span>
          </Button>
          <Button
            variant="contained"
            style={{
              background: activeTab === 1 ? "#40A8FF" : "white",
              color: activeTab === 1 ? "white" : "black",
              borderRadius: 0,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              padding: 12,
              boxShadow: "none",
              border: "1px solid rgb(0 0 0 / 12%)",
              borderBottom: "none"
            }}
            className={[classes.tab, activeTab === 1 && classes.tab_active]}
            onClick={() => handleChangeTab(1)}
          >
            <span className={classes.tabTitle}>Config</span>
          </Button>
        </div>
        <Paper className={classes.container} variant="outlined">
          <Grid item xs={12} style={{ display: "flex", marginBottom: 12 }}>
            <Typography>
              All fields with <span style={{ color: "red" }}>*</span> are
              required
            </Typography>
          </Grid>
          <Grid container spacing={6}>
            {activeTab === 0 && (
              <>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="name"
                    label="IMEI / Serial Number"
                    placeholder="Enter Serial Number"
                    value={state.name}
                    // onKeyPress={handleKeyPress}
                    onChange={e => handleChange(e)}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    InputProps={{
                      disableUnderline: !editMode || !isWTIAdmin,
                      readOnly: !editMode || !isWTIAdmin,
                      style: { color: editMode ? "black" : "#808080" }
                    }}
                    className={classes.field}
                  />

                  {errors.name && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.name}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="devicealias"
                    label="Device Alias"
                    placeholder="Enter Device Alias"
                    value={state.devicealias}
                    // onKeyPress={handleKeyPress}
                    onChange={e => handleChange(e)}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode,
                      style: { color: editMode ? "black" : "#808080" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Battery
                            vehicleType={
                              deviceData.get_device.vehicle_info
                                ? deviceData.get_device?.vehicle_info
                                    ?.vehicle_type
                                : null
                            }
                            deviceTypeId={deviceData.get_device?.device_type_id}
                            batteryLevel={
                              deviceData.get_device.device_status?.battery_level
                            }
                            tooltipTitle={
                              <>
                                <p
                                  className={classes.tooltipTitle}
                                  style={{ fontWeight: "bold" }}
                                >{`Battery Life: ${deviceData.get_device?.device_status?.battery_level}%`}</p>
                                <p className={classes.tooltipTitle}>
                                  {`As of: ${moment(
                                    deviceData.get_device?.device_status
                                      ?.reportstamp
                                  ).format("lll")}
                                  `}
                                </p>
                              </>
                            }
                          />
                          <Tooltip
                            title={
                              deviceData.get_device.device_status
                                ? `Last report: ${moment(
                                    deviceData.get_device.device_status
                                      .reportstamp
                                  ).format("MMM DD, YYYY hh:mm A")}`
                                : "-"
                            }
                          >
                            <TripOrigin
                              style={{
                                fontSize: 12,
                                color: gpsStatus(
                                  deviceData.get_device.device_status
                                    ? deviceData.get_device.device_status
                                        .status_code
                                    : "inactive"
                                ).color
                              }}
                            />
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                    className={classes.field}
                  />

                  {errors.devicealias && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.devicealias}
                    </Alert>
                  )}
                  <FormControl className={classes.field} required>
                    <InputLabel shrink>Device Type</InputLabel>
                    <Select
                      classes={{ root: formStyles.selectRoot }}
                      value={state.device_type_id}
                      onChange={e => handleChange(e)}
                      name="device_type_id"
                      displayEmpty
                      disableUnderline={!editMode}
                      disabled={!editMode}
                    >
                      <MenuItem value="" disabled>
                        <em style={{ color: "#8e8e8e" }}>Select Device Type</em>
                      </MenuItem>
                      {deviceTypesData.get_device_types.device_types.map(
                        device_type => (
                          <MenuItem key={device_type.id} value={+device_type.id}>
                            {device_type.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>

                  {errors.device_type_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.device_type_id}
                    </Alert>
                  )}
                  <Box className={classes.unpairBox}>
                    <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    label="Paired Vehicle/CV"
                    placeholder="Enter Paired Vehicle/CV"
                    value={vehicleInfo ? vehicleInfo.plateno : ""}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    readOnly
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode,
                      style: { color: editMode ? "black" : "#808080" },
                    }}
                    onClick={() => {
                      if (editMode) handlePair();
                    }}
                    className={classes.field}
                  />
                  {vehicleInfo ? (
                    <IconButton
                      className={classes.unpairIcon}
                      disabled={!editMode || !vehicleInfo?.plateno}
                      onClick={async () => {
                        if (_.isEqual(initialData.vehicle_info?.id, vehicleInfo.id)) {
                          const trips = await getBookingsOfVehicle(id, initialData.vehicle_info?.plateno);
                          bookingRef.current = trips;
                          
                          if (trips.error) {
                            Swal.fire({
                              icon: "error",
                              text: "Something went wrong",
                              timer: 1500,
                              showConfirmButton: false,
                              customClass: {
                                container: `${classes.swal}`
                              }
                            });
                            return;
                          } else {
                            setUnpair(true);
                          }
                        } else {
                          setVehicleInfo({
                            id: 0,
                            device_id: 0,
                            plateno: ""
                          });
                        }
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 1024 1024"
                        fill={editMode && vehicleInfo?.plateno ? "#000000" : "none"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                           d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z" 
                          strokeWidth="8"  
                          stroke={editMode && vehicleInfo?.plateno ? "#757575" : "#000000"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                   ) : ""
                  }
                  </Box>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    // required
                    name="mobileno"
                    label="Mobile Number"
                    placeholder="+63----------"
                    defaultValue={deviceConfig.mobileno ?? ""}
                    value={deviceConfig.mobileno ?? ""}
                    onChange={e => handleConfig(e)}
                    onBlur={e => {
                      e.persist();
                      const { value } = e.target;
                      const beginWith63 = value.replace(/^(0?9|639)/, "+639");
                      setDeviceConfig(config => ({
                        ...config,
                        mobileno: beginWith63
                      }));
                      e.target.value = beginWith63;
                      handleBlur(e);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode,
                      style: { color: editMode ? "black" : "#808080" },
                      inputProps: {
                        maxLength: () => {
                          getMaxLength(deviceConfig.mobileno)
                        }
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.mobileno && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.mobileno}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="intervalreporting"
                    label="Main Interval Reporting"
                    value={deviceConfig.intervalreporting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "1",
                      max: "10080",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.intervalreporting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.intervalreporting}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="battery_intervalreporting"
                    label="Auxillary Interval Reporting (min)"
                    value={deviceConfig.battery_intervalreporting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "10080",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.battery_intervalreporting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.battery_intervalreporting}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="idlesetting"
                    label="Idle Setting (min)"
                    value={deviceConfig.idlesetting ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "1",
                      max: "10080",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.idlesetting && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.idlesetting}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="aux_voltage"
                    label="Aux Voltage"
                    value={deviceConfig.aux_voltage ?? ""}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "10080",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.aux_voltage && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.aux_voltage}
                    </Alert>
                  )}
                  {/* <FormControl className={classes.field}>
                    <InputLabel shrink required>
                      GPS Provider
                    </InputLabel>
                    <Select
                      value={deviceConfig.gps_provider_id}
                      onChange={e => handleConfig(e)}
                      name="gps_provider_id"
                      displayEmpty
                      disableUnderline={!editMode}
                      disabled={!editMode}
                    >
                      <MenuItem value={-1} disabled>
                        <em style={{ color: "#8e8e8e" }}>
                          Select GPS Provider
                        </em>
                      </MenuItem>
                      {gpsProvidersData.get_gps_providers.gps_providers.map(
                        gps_provider => (
                          <MenuItem
                            key={gps_provider.id}
                            value={gps_provider.id}
                          >
                            {gps_provider.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl> */}
                  <FormControl className={classes.field}>
                    <InputLabel shrink required>
                      GPS Provider
                    </InputLabel>
                    <Autocomplete
                      disabled={!editMode}
                      // InputLabelProps={{
                      //   shrink: true,
                      //   style: { color: "black" }
                      // }}
                      name="gps_provider_id"
                      options={
                        gpsProvidersData?.get_gps_providers?.gps_providers || []
                      }
                      getOptionLabel={option => option.name || ""}
                      renderOption={option => option.name}
                      defaultValue={deviceConfig?.gps_provider_id}
                      value={deviceConfig?.gps_provider_id}
                      onChange={handleGPSProviderConfig}
                      renderInput={params => (
                        <TextField
                          classes={{ root: formStyles.textFieldRoot }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: !editMode,
                            readOnly: !editMode
                          }}
                          style={{ marginTop: 16 }}
                          placeholder="Select GPS Provider"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  {errors.gps_provider_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.gps_provider_id}
                    </Alert>
                  )}
                  {editMode ? (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          classes={{ root: formStyles.textFieldRoot }}
                          className={classes.field}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                          // onEmptied={e => console.log(e)}
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Date Installed"
                          views={["year", "month", "date"]}
                          InputProps={{
                            className: classes.alertDate
                          }}
                          invalidDateMessage={
                            errors.dateinstalled && (
                              <Alert
                                severity="error"
                                className={classes.alertGroups}
                              >
                                {errors.dateinstalled}
                              </Alert>
                            )
                          }
                          value={deviceConfig.dateinstalled}
                          name="dateinstalled"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "dateinstalled"
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className={classes.field}
                          required
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Date Installed"
                          views={["year", "month", "date"]}
                          value={deviceConfig.dateinstalled}
                          onChange={e =>
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "dateinstalled"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          classes={{ root: formStyles.textFieldRoot }}
                          className={classes.field}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Warranty"
                          views={["year", "month", "date"]}
                          InputProps={{
                            className: classes.alertDate
                          }}
                          invalidDateMessage={
                            errors.warranty && (
                              <Alert
                                severity="error"
                                className={classes.alertGroups}
                              >
                                {errors.warranty}
                              </Alert>
                            )
                          }
                          value={deviceConfig.warranty}
                          name="warranty"
                          onBlur={handleBlur}
                          onChange={e =>
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "warranty"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className={classes.field}
                          required
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Warranty"
                          views={["year", "month", "date"]}
                          value={deviceConfig.warranty}
                          onChange={e =>
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "warranty"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          classes={{ root: formStyles.textFieldRoot }}
                          className={classes.field}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Expiration Date"
                          views={["year", "month", "date"]}
                          InputProps={{
                            className: classes.alertDate
                          }}
                          invalidDateMessage={
                            errors.expirationdate && (
                              <Alert
                                severity="error"
                                className={classes.alertGroups}
                              >
                                {errors.expirationdate}
                              </Alert>
                            )
                          }
                          value={deviceConfig.expirationdate}
                          name="expirationdate"
                          onBlur={handleBlur}
                          onChange={e =>
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "expirationdate"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className={classes.field}
                          required
                          openTo="date"
                          format="MM/dd/yyyy"
                          label="Expiration Date"
                          views={["year", "month", "date"]}
                          value={deviceConfig.expirationdate}
                          onChange={e =>
                            handleDate(
                              moment(e).format("MMM DD YYYY"),
                              "expirationdate"
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>{" "} */}
                    </>
                  ) : (
                    <>
                      <TextField
                        classes={{ root: formStyles.textFieldRoot }}
                        required
                        label="Date Installed"
                        value={deviceConfig.dateinstalled}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "black" }
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true
                        }}
                        className={classes.field}
                      />
                      <TextField
                        classes={{ root: formStyles.textFieldRoot }}
                        required
                        label="Warranty"
                        value={deviceConfig.warranty}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "black" }
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true
                        }}
                        className={classes.field}
                      />
                      <TextField
                        classes={{ root: formStyles.textFieldRoot }}
                        required
                        label="Expiration Date"
                        value={deviceConfig.expirationdate}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "black" }
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true
                        }}
                        className={classes.field}
                      />
                    </>
                  )}
                  <FormControl className={classes.field}>
                    {/* <InputLabel shrink>Primary Client</InputLabel> */}
                    <Autocomplete
                      // label="Aux Voltage"
                      // InputLabelProps={{
                      //   shrink: true,
                      //   style: { color: "black" }
                      // }}
                      loading={loading || loading1}
                      disabled={!editMode}
                      options={dataClients.get_clients.clients || []}
                      getOptionLabel={option => option.name || ""}
                      onChange={handlePrimaryClient}
                      onBlur={e => {
                        let error = "";
                        if (e.target.value === null || e.target.value === "") {
                          error = "This field is required";
                        }
                        setErrors({ ...errors, client_id: error });
                      }}
                      getOptionSelected={(o, v) => v.name === o.name}
                      value={primaryClient}
                      renderInput={params => (
                        <TextField
                          {...params}
                          onChange={debouncedPrimaryClientChanged}
                          required
                          classes={{ root: formStyles.textFieldRoot }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          margin="none"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: !editMode,
                            readOnly: !editMode,
                          }}
                          label={
                            <span>
                            <Tooltip
                              classes={{ tooltip: formStyles.toolTip }}
                              title={
                                <div>
                                  <span>Client Code</span>
                                  <br />
                                  <span>BDG: {clientCode.bdg ? clientCode.bdg.code : "N/A"} </span>
                                  <br />
                                  <span>QNE: {clientCode.qne ? clientCode.qne.code : "N/A"} </span>
                                  <br />
                                </div>
                              }
                              placement="top-start"
                              >
                              <HelpRounded className={classes.helpIcon} />
                            </Tooltip>
                              Primary Client
                            </span>
                          }
                          placeholder="Select Primary Client"
                          // fullWidth
                        />
                      )}
                    />
                  </FormControl>
                  {errors.client_id && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.client_id}
                    </Alert>
                  )}

                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    disabled={!editMode}
                    name="contract_ref_no"
                    label="Contract Reference Code"
                    placeholder="Enter Contract Reference Code"
                    value={state.contract_ref_no}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.contract_ref_no && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.contract_ref_no}
                    </Alert>
                  )}
                  
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    disabled={!editMode}
                    name="reserve_field"
                    label="Reserve Field 1"
                    placeholder="Enter Reserve Field 1"
                    value={state.reserve_field}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black"
                      }
                    }}
                    className={classes.field}
                  />

                  {errors.reserve_field && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.reserve_field}
                    </Alert>
                  )}

                  <div className={classes.switch}>
                    {/* <InputLabel shrink className={classes.inputLabel}> */}
                    <Tooltip
                      classes={{ tooltip: formStyles.toolTip }}
                      title="Turn on: This GPS device info will be passed to NGT"
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    <Typography color="inherit" className={classes.mediumTitle}>
                      &nbsp;Mirror to NGT&nbsp;
                    </Typography>
                    {/* </InputLabel> */}
                    <AntSwitch
                      color="primary"
                      name="mirror_to_ngt"
                      disabled={!editMode}
                      checked={state.mirror_to_ngt}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                  <div className={classes.switch}>
                    {/* <InputLabel shrink className={classes.inputLabel}> */}
                    <Tooltip
                      classes={{ tooltip: formStyles.toolTip }}
                      title={
                        <span>
                          Turn on: Computation will be Idling Duration =
                          idle_end - idle_start + idle_setting
                          <br />
                          Turn Off: Computation will be Idling Duration =
                          idle_setting * number of idle
                        </span>
                      }
                      placement="top-start"
                    >
                      <HelpRounded className={classes.helpIcon} />
                    </Tooltip>
                    <Typography color="inherit" className={classes.mediumTitle}>
                      &nbsp;Start and End Idle Enable toggle&nbsp;
                    </Typography>
                    {/* </InputLabel> */}
                    <AntSwitch
                      color="primary"
                      name="use_start_end_idle"
                      disabled={!editMode}
                      checked={deviceConfig.use_start_end_idle}
                      onChange={e => {
                        const { name, checked } = e.target;
                        setDeviceConfig({ ...deviceConfig, [name]: checked });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.mediumTitle} gutterBottom>
                    Remarks
                  </Typography>
                  <TextField
                    name="remarks"
                    placeholder="Type here..."
                    multiline
                    // rows="4"
                    minRows="4"
                    value={state.remarks}
                    onChange={e => handleChange(e)}
                    variant="outlined"
                    fullWidth
                    className={classes.multiline}
                    helperText={`${
                      state.remarks ? state.remarks.length : 0
                    }/100`}
                    FormHelperTextProps={{ style: { textAlign: "right" } }}
                    InputProps={{
                      readOnly: !editMode,
                      style: { color: editMode ? "black" : "#808080" },
                      maxLength: 100
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 12,
                      marginTop: 12
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Tooltip
                        classes={{ tooltip: formStyles.toolTip }}
                        title="Data of primary client will be linked to all selected partners(and vise versa)"
                        placement="top-start"
                      >
                        <HelpRounded className={classes.helpIcon} />
                      </Tooltip>
                      <InputLabel
                        required
                        classes={{ root: classes.mediumTitle }}
                      >
                        Partners
                      </InputLabel>
                    </div>
                    <Paper component="div" className={classes.searchRoot}>
                      <InputBase
                        className={classes.searchInput}
                        placeholder="Search Client"
                        inputProps={{ "aria-label": "plate #" }}
                        onChange={e => setKeyword(e.target.value)}
                        // value={keyword}
                        defaultValue={keyword}
                      />
                      <IconButton
                        // type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  <Paper
                    style={{
                      minHeight: "50%",
                      maxHeight: "290px",
                      overflow: "auto"
                    }}
                    variant="outlined"
                  >
                    {editMode && selected.length > 0 && (
                      <Toolbar
                        className={clsx(classes.toolbar_root, {
                          [classes.highlight]: selected.length > 0,
                          [classes.invisible]: selected.length <= 0
                        })}
                      >
                        {selected.length > 0 && (
                          <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            <span style={{ color: "#FF845E" }}>
                              {selected.length}
                            </span>{" "}
                            device group/s selected
                          </Typography>
                        )}

                        {selected.length > 0 && (
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleOpenDeleteAll();
                              }}
                              // disabled={!canDelete}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Toolbar>
                    )}

                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              {editMode ? (
                                <Checkbox
                                  color="primary"
                                  indeterminate={isChecked().indeterminate}
                                  checked={isChecked().all}
                                  onChange={onSelectAllClick}
                                  inputProps={{
                                    "aria-label": "select all desserts"
                                  }}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell
                              padding="normal"
                              // sortDirection={orderBy === headCell.id ? order : false}
                            >
                              <TableSortLabel
                              // classes={{ icon: classes.icon }}
                              // active={orderBy === headCell.id}
                              // direction={orderBy === headCell.id ? order : "asc"}
                              // onClick={createSortHandler(headCell.id)}
                              // onClick={() => handleSort(headCell.id)}
                              // IconComponent={UnfoldMoreIcon}
                              >
                                Client
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                              {editMode ? (
                                <Button
                                  size="small"
                                  color="primary"
                                  classes={{ root: classes.addEntryButton }}
                                  onClick={() => openGroupModal()}
                                >
                                  Add Entry
                                </Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupList.length > 0 &&
                            groupList
                              .filter(
                                row =>
                                  row.name
                                    .toUpperCase()
                                    .indexOf("".toUpperCase()) > -1 &&
                                  row.name
                                    .toLowerCase()
                                    .indexOf(keyword.toLowerCase()) > -1
                              )
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    // selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      {editMode ? (
                                        <Checkbox
                                          onClick={event =>
                                            handleClick(event, row.id)
                                          }
                                          checked={isItemSelected}
                                          // onClick={e => handleGroupSelect(e, row)}
                                          // checked={isChecked(row.id)}
                                          inputProps={{
                                            "aria-labelledby": labelId
                                          }}
                                          color="primary"
                                        />
                                      ) : null}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="left"
                                    >
                                      {row.name}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="right"
                                    >
                                      {editMode ? (
                                        <DeleteIcon
                                          onClick={() =>
                                            handleRemoveGroup(row.id)
                                          }
                                          style={{ paddingRight: 8 }}
                                        />
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  {errors.groupList && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.groupList}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
            {activeTab === 1 && (
              <>
                <Grid item xs={4}>
                  <Paper variant="outlined" style={{ padding: 12 }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        <div className={classes.checkBox}>
                          <Typography className={classes.checkBoxTitle}>
                            Select All
                          </Typography>
                          <Checkbox
                            color="primary"
                            checked={allSelected()}
                            onChange={e => handleSelectAll(e)}
                            disabled={!editMode}
                          />
                        </div>
                        {[
                          {
                            label: "Monitor Door Opening",
                            value: deviceConfig.monitordooropenning,
                            name: "monitordooropenning"
                          },
                          {
                            label: "Check Homebase Status",
                            value: deviceConfig.checkhomebasestatus,
                            name: "checkhomebasestatus"
                          }
                        ].map(checkbox => (
                          <div
                            key={checkbox.label}
                            className={classes.checkBox}
                          >
                            <Typography className={classes.checkBoxTitle}>
                              {checkbox.label}
                            </Typography>
                            <Checkbox
                              color="primary"
                              checked={checkbox.value}
                              onClick={e => {
                                handleConfig(e);
                              }}
                              value={checkbox.value}
                              name={checkbox.name}
                              disabled={!editMode}
                            />
                          </div>
                        ))}

                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <div className={classes.checkBox}>
                              <Typography className={classes.checkBoxTitle}>
                                Has Fuel Sensor
                              </Typography>
                              <div style={{ flexGrow: 1 }} />
                              <Checkbox
                                color="primary"
                                checked={deviceConfig.hasFuelSensor}
                                onChange={e => {
                                  handleConfig(e);
                                }}
                                value={deviceConfig.hasFuelSensor}
                                name="hasFuelSensor"
                                disabled={!editMode}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px",
                                  fontSize: 14
                                }}
                              >
                                +&#9651;
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  name="fuelrefill"
                                  value={deviceConfig.fuelrefill}
                                  onChange={e => handleConfig(e)}
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    },
                                    readOnly: !editMode
                                  }}
                                  disabled={!editMode}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  L
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px",
                                  fontSize: 14
                                }}
                              >
                                +&#9651;
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  // variant="outlined"
                                  name="fuelpilferage"
                                  value={deviceConfig.fuelpilferage}
                                  onChange={e => handleConfig(e)}
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                  disabled={!editMode}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  L
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <div className={classes.checkBox}>
                              <Typography className={classes.checkBoxTitle}>
                                Has Temperature Sensor
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={deviceConfig.hastempsensor}
                                onChange={e => {
                                  handleConfig(e);
                                }}
                                name="hastempsensor"
                                value={deviceConfig.hastempsensor}
                                disabled={!editMode}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ padding: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 10 }}>
                                  Min
                                </Typography>
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  value={deviceConfig.mintemp}
                                  name="mintemp"
                                  onChange={e => handleConfig(e)}
                                  // variant="outlined"
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                  disabled={!editMode}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  &#176;C
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} style={{ paddingBottom: "8px" }}>
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #EBEBEB"
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 10 }}>
                                  Max
                                </Typography>
                              </div>
                              <div style={{ padding: 0, width: "100%" }}>
                                <TextField
                                  classes={{ root: classes.textField }}
                                  value={deviceConfig.maxtemp}
                                  name="maxtemp"
                                  onChange={e => handleConfig(e)}
                                  // variant="outlined"
                                  inputProps={{
                                    style: {
                                      padding: 0,
                                      height: "26px",
                                      borderRadius: 0,
                                      color: "#909090"
                                    }
                                  }}
                                  disabled={!editMode}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#ebebeb",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#888888",
                                  minHeight: "26px",
                                  minWidth: "28px"
                                }}
                              >
                                <Typography style={{ fontSize: 12 }}>
                                  &#176;C
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="speedlimit"
                    label="Speed Limit"
                    value={deviceConfig.speedlimit ?? ""}
                    onBlur={handleBlur}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "500",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                  />
                  {errors.speedlimit && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.speedlimit}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="highwayspeedlimit"
                    label="Highway Speed Limit"
                    value={deviceConfig.highwayspeedlimit ?? ""}
                    onBlur={handleBlur}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "500",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                  />
                  {errors.highwayspeedlimit && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.highwayspeedlimit}
                    </Alert>
                  )}
                  <InputLabel
                    className={classes.customTextFieldLabel}
                    required
                  >
                    Harsh Acceleration(HA)
                  </InputLabel>
                  <Grid container>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Min
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1,
                            disableUnderline: !editMode,
                            readOnly: !editMode
                          }}
                          name="ha_min"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.ha_min ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Max
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1,
                            disableUnderline: !editMode,
                            readOnly: !editMode,
                            // style: { padding: "12px 5px" }
                          }}
                          name="ha_max"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.ha_max ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {(errors.ha_min || errors.ha_max) && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.ha_min || errors.ha_max}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    name="maxvalidspeed"
                    label="Maximum Valid Speed"
                    value={deviceConfig.maxvalidspeed ?? ""}
                    onBlur={handleBlur}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "500",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                  />
                  {errors.maxvalidspeed && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.maxvalidspeed}
                    </Alert>
                  )}
                  <TextField
                    classes={{ root: formStyles.textFieldRoot }}
                    required
                    key="warningspeed"
                    name="warningspeed"
                    label="Warning Speed"
                    value={deviceConfig.warningspeed ?? ""}
                    onBlur={handleBlur}
                    onChange={e => handleConfig(e)}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" }
                    }}
                    className={classes.field}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "500",
                      step: "1"
                    }}
                    InputProps={{
                      disableUnderline: !editMode,
                      readOnly: !editMode
                    }}
                  />
                  {errors.warningspeed && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.warningspeed}
                    </Alert>
                  )}
                  <InputLabel
                    className={classes.customTextFieldLabel}
                    required
                  >
                    Harsh Braking(HB)
                  </InputLabel>
                  <Grid container>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Min
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1,
                            disableUnderline: !editMode,
                            readOnly: !editMode
                          }}
                          name="hb_min"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.hb_min ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.customInputLabel}>
                        Max
                      </div>
                      <div style={{ width: "60%" }}>
                        <TextField
                          InputProps={{
                            classes: {
                              root: formStyles.textFieldRoot
                            },
                            max: 99,
                            min: -1,
                            disableUnderline: !editMode,
                            readOnly: !editMode
                          }}
                          name="hb_max"
                          inputProps={{ min: -1, max: 99 }}
                          type="number"
                          value={deviceConfig.hb_max ?? 0}
                          onChange={e => handleConfig(e)}
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {(errors.hb_min || errors.hb_max) && (
                    <Alert severity="error" className={classes.alert}>
                      {errors.hb_min || errors.hb_max}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
            {editMode && (
              <Grid item xs={12} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textAlign: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={
                      () => (handleOpen("save"))
                        // setTimeout(() => {
                        //   handleOpen("save");
                        // }, 2000)
                      // setTimeout(handleOpen("save"), 3000)
                    }
                  >
                    Save Details
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </Paper>
        </div>
        <ConfirmationDialog
          toggle={dialog.save}
          close={() => handleClose("save")}
          fn={e => handleSubmit(e)}
          title="Save"
          content="Are you sure you want to save device?"
        />
        <ConfirmationDialog
          toggle={dialog.discard}
          close={() => handleClose("discard")}
          fn={() => discarded()}
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        <ConfirmationDialog
          toggle={discard}
          close={() => setDiscard(false)}
          fn={() =>
            Swal.fire({
              title: "Discarded",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              onClose: () => {
                setRedirect(true);
              }
            })
          }
          title="Discard Changes?"
          content="Are you sure you want to leave this page and discard changes?"
        />
        {vehicleModal && (
          <PairVehicle
            toggle={vehicleModal}
            closeVehicleModal={closeVehicleModal}
            setVehicleInfo={async newVehicle => {
              // check if value already changed before selecting new vehicle
              if (
                +initialData.vehicle_info?.id && 
                !_.isEqual(initialData.vehicle_info?.id, vehicleInfo.id)
              ) {
                setVehicleInfo(newVehicle);
                closeVehicleModal();
              } else {
                // Get all bookings(ongoing and future)
                const trips = await getBookingsOfVehicle(id, initialData.vehicle_info?.plateno);
                bookingRef.current = trips;
                
                if (trips.error) {
                  Swal.fire({
                    icon: "error",
                    text: "Something went wrong",
                    timer: 1500,
                    showConfirmButton: false,
                    customClass: {
                      container: `${classes.swal}`
                    }
                  });
                  return;
                } else {
                  // If device has ongoing trip, paired vehicle cannot be changed
                  if (trips?.ongoingTrips?.tripNumbers?.length) {
                    setUnpair(true);
                    newVehicleRef.current = newVehicle;
                  } else {
                    setVehicleInfo(newVehicle);
                    closeVehicleModal();
                  }
                }
              }
            }}
          />
        )}
        <SelectGroup
          toggle={groupModal}
          closeGroupModal={closeGroupModal}
          handleGroupList={handleGroupList}
          groupList={groupList}
        />
        <ConfirmationDialog
          toggle={dialogDeleteAll}
          close={() => handleCloseDeleteAll()}
          fn={e => handleDeleteGroups(e)}
          title="Delete?"
          content="Are you sure you want to delete these groups?"
        />
        <UnpairingDialog
          toggle={unpair}
          close={() => handleCloseUnpair()}
          fn={e => handleUnpaired(e)}
          referenceData="device"
          tripInfo={{
            trips: bookingRef.current,
            devicealias: initialData.state.devicealias || "",
            plateno: initialData.vehicle_info?.plateno || ""
          }}
        />
      </div>
    </AccessControl>
  );
}
