import React from "react";
import { withRouter } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
// import LOGO from "../../../assets/LOGO.png";
import LOGO from "../../../assets/Images/logo.png";

const drawerWidth = 70;

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  root: {
    // display: "flex",
    overflow: "hidden",
    height: "100vh",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: "hidden"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#585858"
  },
  drawerIcon: {
    color: "white",
    fontSize: "35px"
  },
  content: {
    
  },
  contentAdmin: {
    flexGrow: 1,
    height: "calc(100vh - 64px)"
  },
  toolbar: theme.mixins.toolbar,
  blue: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  iconButton: {
    padding: 10
  }
}));

const ClippedDrawer = props => {
  const { children } = props;
  const classes = useStyles();

  const Header = (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img
          src={LOGO}
          style={{ position: "absolute", height: "100%" }}
          alt=""
        />
        {/* <Typography className={classes.title} variant="h6" noWrap>
          trackMe Suite
        </Typography> */}
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );

  return (
    <div className={classes.root}>
      {Header}
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default withRouter(ClippedDrawer);
