// import { GET_GEOFENCE_QUERY } from "../graphql/Queries";
import {
  READ_NOTIFICATION_MUTATION,
  CLEAR_ALL_NOTIFICATION_MUTATION
} from "../graphql/Mutations";
import client from "../Client";

export const READ_NOTIFICATION = variables => {
  return new Promise(resolve => {
    client
      .mutate({
        mutation: READ_NOTIFICATION_MUTATION,
        variables
      })
      .then(data => {
        resolve("success");
      })
      .catch(err => console.log(err));
  });
};

export const READ_ALL_NOTIFICATION = async variablesArray => {
  await variablesArray.forEach(variable => {
    const data = {
      id: variable.id,
      notification: variable.notification
    };
    client
      .mutate({
        mutation: READ_NOTIFICATION_MUTATION,
        variables: data
      })
      .catch(err => console.log(err));
  });
  return "success";
};

export const CLEAR_ALL_NOTIFICATION = variables => {
  return new Promise(resolve => {
    client
      .mutate({
        mutation: CLEAR_ALL_NOTIFICATION_MUTATION,
        variables
      })
      .then(data => {
        resolve("success");
      })
      .catch(err => resolve(err));
  });
};
