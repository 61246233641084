import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import { TableRow, TableCell, IconButton, makeStyles } from "@material-ui/core";
import _ from "lodash";
import Highlight from "react-highlighter";
import MechanicModal from "../../utils/modals/MechanicModal";
import Header from "../../utils/UI/Table/Header";
import Table from "../../utils/UI/Table/CustomTable";
import AddLogs from "../../utils/functions/AddLogs";

// constant values.
const Headers = [
  { id: "vehicle_info.plateno", label: "Plate Number", isSortable: true },
  { id: "vehicle_types.name", label: "Vehicle Type", isSortable: true },
  {
    id: "vehicle_info.last_maintenance_km",
    label: "Odometer Since Last Maintenance (km)",
    isSortable: true
  },
  {
    id: "vehicle_info.last_maintenance_date",
    label: "Last Maintenance Date",
    isSortable: true
  },
  {
    id: "rover_status.status_code_id",
    label: "Maintenance Trigger",
    isSortable: false
  },
  { id: "actions", label: "Actions", isSortable: false }
];
const HEADER_LABEL = "Vehicle Information";
const INITIAL_FORMAT_DATE = "LL";
const MODAL_LABEL = "Maintenance Info";
const MODAL_DESCRIPTION =
  "Provide the vehicle's maintenance details and indicate the preferred distance and time maintenance trigger.";

const VehicleInformation = props => {
  const classes = useStyles();
  // constant required input error msg.
  const REQUIRED_INPUT_ERROR = (
    <span className={classes.required_input_error}>Initial Input Required</span>
  );
  const {
    page,
    setPage,
    vehicles,
    count,
    total,
    order,
    setOrder,
    vars,
    refetchVehicle,
    totalPage
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState([]);

  // modal functions.
  function closeModalHandler() {
    setOpenModal(false);
    setSelectedVehicle([]);
  }

  function openModalHandler(data) {
    setOpenModal(true);
    setSelectedVehicle(data);
    AddLogs("PMS - Vehicle Information", "view", data.plateno);
  }

  return (
    <>
      <Header
        label={HEADER_LABEL}
        total={total}
        page={page}
        setPage={setPage}
        totalPage={totalPage}
        vars={vars}
        refetchVehicle={refetchVehicle}
      />
      <Table headers={Headers} order={order} setOrder={setOrder}>
        {count > 0 ? (
          vehicles.map((data, index) => {
            const {
              device_info,
              last_maintenance_date: lastMaintenanceDate,
              last_maintenance_km: lastMaintenanceOdom,
              maintenance_months: maintenanceMonths,
              maintenance_km: maintenanceOdom
            } = data;
            // disable or enable configuration action according to the below conditions.
            const hasError =
              maintenanceOdom <= -1 ||
              lastMaintenanceOdom <= -1 ||
              !maintenanceMonths ||
              !lastMaintenanceDate;

            return (
              <TableRow
                key={index}
                hover
                className={classes.table_data_container}
              >
                <TableCell className={classes.table_data}>
                  <Highlight search={vars.keyword}>
                    {data.plateno}
                  </Highlight>
                </TableCell>
                <TableCell className={classes.table_data}>
                  <Highlight search={vars.keyword}>
                    {data.vehicle_type}
                  </Highlight>
                </TableCell>
                <TableCell>
                  {!_.isNull(lastMaintenanceOdom) ? (
                    <span className={classes.table_data}>
                      {Number(lastMaintenanceOdom).toLocaleString("en-US")}
                    </span>
                  ) : (
                    <span className={classes.table_data}>
                      {REQUIRED_INPUT_ERROR}
                    </span>
                  )}
                </TableCell>
                <TableCell className={classes.table_data}>
                  {lastMaintenanceDate
                    ? moment(lastMaintenanceDate).format(INITIAL_FORMAT_DATE)
                    : REQUIRED_INPUT_ERROR}
                </TableCell>
                <TableCell className={classes.table_data}>
                  {maintenanceMonths && !_.isEqual(maintenanceOdom, "")
                    ? `${Number(maintenanceOdom).toLocaleString(
                        "en-US"
                      )} km / ${maintenanceMonths} months`
                    : REQUIRED_INPUT_ERROR}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => openModalHandler(data)}
                    className={hasError ? classes.error_btn : ""}
                  >
                    <SettingsRoundedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={20}>
              No Records Found
            </TableCell>
          </TableRow>
        )}
      </Table>

      {openModal && (
        <MechanicModal
          toggle={openModal}
          title={MODAL_LABEL}
          content={MODAL_DESCRIPTION}
          close={closeModalHandler}
          vehicle={selectedVehicle}
          type={HEADER_LABEL}
          // vars={{ ...vars, keyword: searchKeyword.value }}
          vars={vars}
          refetchVehicle={refetchVehicle}
        />
      )}
    </>
  );
};

export default VehicleInformation;

const useStyles = makeStyles(theme => ({
  table_data: {
    color: theme.palette.primary.dark,
    fontSize: 13
  },
  table_data_container: {
    backgroundColor: "#FFFFFF"
  },
  required_input_error: {
    color: theme.palette.error.dark,
    fontSize: 13
  },
  error_btn: {
    color: theme.palette.primary.main
  }
}));
