import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/Queries";

function useFetchUserProfile(UserId, userCookies) {
  const [userInfo, setUserInfo] = useState("");

  const { refetch: refetchUser } = useQuery(GET_USER, {
    variables: { id: UserId },
    onCompleted: res => setUserInfo(res?.get_user),
    onError: () => {
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const refetchUserInfoHandler = () => refetchUser({ id: UserId });

  useEffect(() => refetchUserInfoHandler, [userCookies]);

  return userInfo;
}

export default useFetchUserProfile;
