/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  Typography,
  TextField,
  InputBase,
  Paper,
  TableCell,
  TableContainer,
  Table,
  Checkbox,
  TableSortLabel,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Toolbar,
  Tooltip,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Help as HelpIcon
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import _ from "lodash";
import SearchContext from "../../../../../context/SearchContext";
import { ADD_ALERT_REFERENCE } from "../../../../../graphql/Mutations";
import {
  GET_DEVICE_TYPES,
  GET_GROUPS,
  GET_ALERT_DESCRIPTIONS
} from "../../../../../graphql/Queries";

import Header from "../../../Header";
import ConfirmationDialog from "../../../../../utils/modals/ConfirmationDialog";
import validate from "../../../../../utils/validation";
import { Helper } from "../../../../Utils";
import AccessControl from "../../../../../utils/AccessControl";
import AddLogs from "../../../../../utils/functions/AddLogs";
import useAllowedPermissions from "../../../../../utils/hooks/useAllowedPermissions";

import debounce from "../../../../../utils/functions/debounce.util";

const Highlight = require("react-highlighter");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  TBroot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  container: {
    height: "calc(100% - 66px)", // 66 for header, 32 for top + bottom margins
    flexGrow: 1,
    padding: theme.spacing(5),
    overflow: "auto",
    boxSizing: "border-box"
  },
  wrapper: {
    minHeight: "100%",
    height: "auto",
    boxSizing: "border-box",
    padding: theme.spacing(3),
    borderRadius: 2,
    border: "1px solid #ccc",
    background: "white"
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  alert: {
    "& .MuiAlert-icon": {
      padding: 0
    },
    "& .MuiAlert-message": {
      padding: 0
    },
    width: "90%",
    marginTop: "6px 0"
  },
  customBadgeRegistered: {
    backgroundColor: "#34CB1B",
    color: "white"
  },
  customBadgePending: {
    backgroundColor: "#FEC702",
    color: "white"
  },
  table: {
    maxWidth: "100%",
    minHeight: "100"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  helpIcon: {
    fontSize: "16px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  }
}))(Tooltip);

export default function AddAlertReference(props) {
  const { history, location } = props;
  const { pathname } = location;
  const classes = useStyles();
  const contextData = useContext(SearchContext);
  // const [isLoading, setIsLoading] = useState(true);
  const [discard, setDiscard] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [toOpen, setToOpen] = useState(false);
  const [toOpenDeleteDialog, setToOpenDeleteDialog] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [deviceTypeData, setDeviceTypeData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0); // setPage
  const [pagePartner, setPagePartner] = useState(0); // setPage
  const [orderBy, setOrderBy] = useState("code");
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [partner, setPartner] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [clientTotalRows, setClientTotalRows] = useState([]);
  const [clientRowsPerPage, setClientRowsPerPage] = useState(10);
  const [toDelete, setToDelete] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
  // const [userKeyword, setUserKeyword] = useState("");
  // const { setSearchKeyword, searchKeyword } = contextData;
  const [dialogFilter, setDialogFilter] = useState("");
  const [checkCode, setCheckCode] = useState(0);
  const [removeGroup, setRemoveGroup] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [filterMetaData, setFilterMetaData] = useState([]);
  const [formEdit, setFormEdit] = useState(false);
  const [state, setState] = useState({
    alert_description: [],
    device_type: []
  });
  const [initialState] = useState(_.cloneDeep(state));
  const [errors, setErrors] = useState({
    code: "",
    alertmessage: ""
  });
  const [dialog, setDialog] = useState({
    discard: false,
    save: false
  });

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  // const [get_code] = useLazyQuery(GET_ALERT_REFERENCES, {
  //   onCompleted: data => {
  //     if (data) {
  //       setCheckCode(data.get_alert_references.count);
  //     }
  //   },
  //   variables: {
  //     first: 5
  //   },
  //   fetchPolicy: "network-only"
  // });

  const [get_description] = useLazyQuery(GET_ALERT_DESCRIPTIONS, {
    onCompleted: data => {
      if (data) {
        setFilterData(data.get_alert_descriptions.alert_descriptions);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  const [get_deviceType] = useLazyQuery(GET_DEVICE_TYPES, {
    onCompleted: data => {
      if (data) {
        setDeviceTypeData(data.get_device_types.device_types);
      }
    },
    variables: {
      first: 50
    },
    fetchPolicy: "network-only"
  });

  const getData = () => {
    if (groupList) {
      return stableSort(groupList, getSorting(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    return null;
  };

  const getDataPartner = () => {
    if (groupList) {
      return stableSort(metaData, getSorting(order, orderBy)).slice(
        pagePartner * 10,
        pagePartner * 10 + 10
      );
    }
    return null;
  };

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selected.length) {
      if (!selected.length < getData().length) {
        const displayedData = getData().map(data => data.id);
        if (displayedData.every(data => selected.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selected.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  const isCheckedPartner = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedPartner.length) {
      if (!selectedPartner.length < getDataPartner().length) {
        const displayedData = getDataPartner().map(data => data.id);
        if (displayedData.every(data => selectedPartner.indexOf(data) > -1))
          checked = { all: true, indeterminate: false };
        else if (displayedData.some(data => selectedPartner.indexOf(data) > -1))
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const handleSelectAllClick = event => {
    const tempSelected = selected;
    const deselect = () => {
      const displayedData = getData().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelected(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getData().map(n => tempSelected.push(n.id));
      setSelected(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleSelectAllClickPartner = event => {
    const tempSelected = selectedPartner;
    const deselect = () => {
      const displayedData = getDataPartner().map(n => n.id);
      const newSelected = tempSelected
        .filter(id => displayedData.indexOf(id) < 0)
        .map(id => id);
      setSelectedPartner(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      getDataPartner().map(n => tempSelected.push(n.id));
      setSelectedPartner(_.uniq(tempSelected));
      return;
    }
    deselect();
  };

  const handleSort = field => {
    if (orderBy === field) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      if (field === "status") {
        setOrderBy("access_end_date");
      } else {
        setOrderBy(field);
      }

      setOrder("asc");
    }
  };

  // const handleChange = e => {
  //   const { name, value } = e.target;
  //   let errorMessage;
  //   // if (e.target.name === "code") {
  //   //   if (e.target.value.length === 0) {
  //   //     errorMessage = validate("alphanumeric", e.target.value) ? (
  //   //       ""
  //   //     ) : (
  //   //       <Helper message="This field is required" />
  //   //     );
  //   //   } else {
  //   //     errorMessage = validate("alphanumeric", e.target.value) ? (
  //   //       ""
  //   //     ) : (
  //   //       <Helper message="Alphanumeric characters only" />
  //   //     );
  //   //   }
  //   // }
  //   if (name === "event_value") {
  //     if(value > 100000){
  //       errorMessage = <Helper message="Minimum of 0 maximum of 100000 only" />
  //     }else {
  //       errorMessage = ""
  //     }
  //   }
  //   setErrors({
  //     ...errors,
  //     [name]: errorMessage
  //   });
  //   setState({
  //     ...state,
  //     [name]: e.target.value
  //   });
  // };

  const handleChange = e => {
    const {name, value} = e.target;
    let errorMessage;
    let resetEventValue = false;

    switch(name){
      case "code":
      case "alertmessage":
        if(value === ""){
          errorMessage = <Helper message="This field is required" />
        } else {
          errorMessage = ""
        }
        break;
      case "operator":
        if(value === "None"){
          resetEventValue = true;
        }
        break;
      case "event_value":
        if(+value < 0 || +value > 999999){
          errorMessage = <Helper message="Minimum of 0 maximum of 999,999 only" />
        } else {
          errorMessage = ""
        }
        break;
      default:
        break;  
    }
    
    setErrors({
      ...errors,
      [name]: errorMessage,
      ...(resetEventValue && { event_value: "" })
    });
    setState({
      ...state,
      [name]: value,
      ...(resetEventValue && { event_value: "" })
    });
  };

  useEffect(() => {
    if (_.isEqual(initialState, state)) {
      setFormEdit(false);
    } else {
      setFormEdit(true);
    }
  }, [state]);

  const [addAlertReference] = useMutation(ADD_ALERT_REFERENCE, {
    onCompleted: res => {
      setDisableSubmit(false);
      // setIsLoading(false);
      if (res.add_alert_reference.success) {
        refetch(getVariablesDialog());
        AddLogs("Admin - Alert References", "insert", state.code);
        Swal.fire({
          icon: "success",
          text: "Save successful",
          timer: 1500,
          onClose: () => {
            history.push({
              pathname: "/admin",
              state: {
                params: {
                  moduleName: process.env.REACT_APP_ALERT_REFERENCES_MODULE,
                  filter: history?.location?.state?.params?.filter
                }
              }
            });
          }
        });
      } else {
        const temp = {
          alert_code: <Helper message="Alert Code already exists" />
        };
        setErrors(temp);
      }
    },
    onError: ApolloError => {
      const { graphQLErrors: returnError } = JSON.parse(
        JSON.stringify(ApolloError)
      );
      const returnData = returnError.map(el => {
        return el.message;
      });
      if (returnData) {
        setErrors({
          ...errors,
          general: returnData
        });
      }

      setDisableSubmit(false);
      // setIsLoading(false);
    }
  });

  const handleCloseDialog = dialog_name => {
    setToOpen(false);
    setToOpenDeleteDialog(false);
    setDialog({ ...dialog, [dialog_name]: false });
  };

  const saveAdd = () => {
    addAlertReference({
      variables: {
        alert_reference: {
          alert_description_id: state.alert_description
            ? Number(state.alert_description.id)
            : null,
          code: state.code ? state.code : null,
          alertmessage: state.alertmessage ? state.alertmessage : null,
          level: state.level ? Number(state.level) : null,
          device_type_id: state.device_type
            ? Number(state.device_type.id)
            : null,
          description: state.description ? state.description : null,
          group_ids: state.group_ids,
          operator: state.operator !== "None" ? state.operator : null,
          event_value: state.event_value
        }
      }
    });
    setErrors([]);
    setDisableSubmit(false);
  };

  const discarded = () => {
    handleCloseDialog("discard");
  };

  const handleDelete = () => {
    let temp = [...metaData];
    temp = temp.filter(item => item.id !== toDelete.toString());
    setRemoveGroup(removeGroup.filter(item => item !== toDelete.toString()));
    setState({
      ...state,
      group_ids: state.group_ids.filter(item => item !== Number(toDelete))
    });
    setToDelete([]);
    setMetaData(temp);
    setFilterMetaData(temp);
    setSelected([]);
  };

  const handleOpenDeleteAll = () => {
    setDialogDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setDialogDeleteAll(false);
  };

  const handleMultipleDeleteCode = () => {
    const group_ids = [];
    let temp = [...metaData];
    let tempRemove = removeGroup;
    temp = temp.filter(item => !selectedPartner.includes(item.id));
    tempRemove = tempRemove.filter(item => selectedPartner.includes(item.id));
    setRemoveGroup(tempRemove);
    setPagePartner(0);
    setMetaData([...temp]);
    setSelectedPartner([]);

    if (temp) {
      temp.map(row => {
        group_ids.push(Number(row.id));
        return row;
      });
    }
    setState({
      ...state,
      group_ids: group_ids || null
    });
  };

  const handleOpenDeleteClient = () => {
    setToOpenDeleteDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const isSelectedPartner = name => selectedPartner.indexOf(name) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClickPartner = (event, id) => {
    const selectedIndex = selectedPartner.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPartner, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPartner.slice(1));
    } else if (selectedIndex === selectedPartner.length - 1) {
      newSelected = newSelected.concat(selectedPartner.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPartner.slice(0, selectedIndex),
        selectedPartner.slice(selectedIndex + 1)
      );
    }

    setSelectedPartner(newSelected);
  };

  const handleToDelete = id => {
    if (!toDelete.some(row_id => row_id === id)) setToDelete([...toDelete, id]);
    setSelectedPartner([]);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setSelected([]);
  };

  const handleDialogClose = () => {
    // setHelper({ code: "" });
    setDialogOpen(false);
  };

  // Resources for Add Entry Dialog
  const getVariablesDialog = () => {
    const variables = {
      first: clientRowsPerPage,
      orderBy: [{ field: "name", direction: "asc" }],
      not: {
        field: "id",
        value: removeGroup
      }
    };

    if (dialogFilter.length > 0) {
      return Object.assign(variables, {
        filter: [
          {
            field: "name",
            value: dialogFilter ? dialogFilter.toString() : ""
          }
        ]
      });
    }
    return variables;
  };

  const { data: getGroupsDialog, refetch, fetchMore } = useQuery(GET_GROUPS, {
    variables: getVariablesDialog(),
    onCompleted: () => {
      setPage(0);
      setPagePartner(0);
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (getGroupsDialog) {
      if (getGroupsDialog.get_groups.groups) {
        setGroupList(getGroupsDialog.get_groups.groups);
        setClientTotalRows(getGroupsDialog.get_groups.total);
      } else {
        setGroupList([]);
        setClientTotalRows(0);
      }
    }
  }, [getGroupsDialog]);

  const handleAddSelectedClient = () => {
    const holder = metaData;
    const sList = [];
    const removeList = removeGroup;
    selected.map(sRow => {
      groupList.map(cRow => {
        if (sRow === cRow.id) {
          holder.push(cRow);
          // filterMetaData.push(cRow);
          removeList.push(cRow.id.toString());
        }
        return cRow;
      });
      return sList.push(Number(sRow));
    });

    setRemoveGroup(removeList);
    setMetaData(holder);
    setFilterMetaData(holder);
    setPartner(sList);
    setDialogOpen(false);
    setSelected([]);
    refetch(getVariablesDialog());

    const group_ids = [];
    if (metaData.length > 0) {
      metaData.map(row => {
        group_ids.push(Number(row.id));
        return row;
      });
    }
    setState({
      ...state,
      group_ids: group_ids || null
    });
  };

  // useEffect(() => {
  //   get_code({
  //     variables: {
  //       first: 5,
  //       condition: {
  //         field: "alert_references.code",
  //         value: state.code ? state.code : ""
  //       }
  //     }
  //   });
  // }, [state]);

  const validateEntry = () => {
    let temp = {};
    let counter = 0;
    if (state) {
      if (
        state.alert_description === null ||
        state.alert_description === undefined ||
        _.isEmpty(state.alert_description)
      ) {
        temp = {
          ...temp,
          alert_description: <Helper message="This field is required" />
        };
        counter += 1;
      } else {
        temp = {
          ...temp,
          alert_description: ""
        };
        counter -= 1;
      }
      if (state.level === "" || state.level === undefined) {
        temp = {
          ...temp,
          level: <Helper message="This field is required" />
        };
        counter += 1;
      } else {
        temp = {
          ...temp,
          level: ""
        };
        counter -= 1;
      }
      if (
        state.device_type === null ||
        state.device_type === undefined ||
        _.isEmpty(state.device_type)
      ) {
        temp = {
          ...temp,
          device_type: <Helper message="This field is required" />
        };
        counter += 1;
      } else {
        temp = {
          ...temp,
          device_type: ""
        };
        counter -= 1;
      }
      if (
        !state?.group_ids?.length ||
        state.group_ids === "" ||
        _.isEmpty(state.group_ids)
      ) {
        temp = {
          ...temp,
          clients: <Helper message="This field is required" />
        };
        counter += 1;
      } else {
        temp = {
          ...temp,
          clients: ""
        };
        counter -= 1;
      }
    }
    if (state.alertmessage === "" || state.alertmessage === undefined) {
      temp = {
        ...temp,
        alert_message: <Helper message="This field is required" />
      };
      counter += 1;
    } else if (validate("noSpecialChar", state.alertmessage)) {
      temp = {
        ...temp,
        alert_message: (
          <Helper message="Accepts Alphabets or numeric characters only" />
        )
      };
      counter += 1;
    } else {
      temp = {
        ...temp,
        alert_message: ""
      };
      counter -= 1;
    }
    if (state.code === "" || state.code === undefined) {
      temp = {
        ...temp,
        alert_code: <Helper message="This field is required" />
      };
      counter += 1;
    } 
    // else if (validate("noSpecialChar", state.code)) {
    //   temp = {
    //     ...temp,
    //     alert_code: (
    //       <Helper message="Accepts Alphabets or numeric characters only" />
    //     )
    //   };
    //   counter += 1;
    // }
    // else if (checkCode !== 0) {
    //   temp = {
    //     ...temp,
    //     alert_code: <Helper message="Alert Code already exists" />
    //   };
    //   counter += 1;
    // }
    else {
      temp = {
        ...temp,
        alert_code: ""
      };
      counter -= 1;
    }

    if (
      +state.event_value < 0 ||
      +state.event_value > 999999
    ){
      temp = {
        ...temp,
        event_value: <Helper message="Minimum of 0 maximum of 999,999 only" />
      };
      counter += 1;
    } else if (
      [">", "<", "="].includes(state.operator) &&
      (!state.event_value ||
      state.event_value === "")
    ) {
      temp = {
        ...temp,
        event_value: <Helper message="This field is required" />
      };
      counter += 1;
    } else {
      temp = {
        ...temp,
        event_value: ""
      };
      counter -= 1;
    }

    if (counter === -7) {
      setDialog({ ...dialog, save: true });
    }
    setErrors(temp);
    return null;
  };

  const dialogHeadCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Client"
    }
  ];

  function DialogTableHeadCells(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      handleSort,
      isChecked
    } = props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={isChecked().indeterminate}
              checked={isChecked().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {dialogHeadCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const GroupTable = props => {
    return groupList ? (
      <TableBody>
        {getData().map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={`row-${row.id}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <TableCell
                padding="checkbox"
                onClick={event => handleClick(event, row.id)}
              >
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                <Highlight search={contextData.searchKeyword.value}>
                  {row.name ? row.name : ""}
                </Highlight>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  DialogTableHeadCells.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  // const handlePaginationDialog = event => {
  //   setRowsPerPage(event.target.value);
  //   setPage(0);
  //   refetch(getVariablesDialog());
  // };

  const handleRefetchDialog = (task, input) => {
    if (input <= Math.floor(clientTotalRows / clientRowsPerPage)) {
      switch (task) {
        case "prev":
          setPage(page - 1);
          return;
        case "next":
          {
            const nextPage = page + 1;
            setPage(nextPage);
            if (nextPage * clientRowsPerPage === groupList.length) {
              fetchMore({
                variables: {
                  skip: nextPage * clientRowsPerPage,
                  not: {
                    field: "id",
                    value: removeGroup
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...prev,
                    get_groups: {
                      count: fetchMoreResult.get_groups.count,
                      total: fetchMoreResult.get_groups.total,
                      groups: [
                        ...prev.get_groups.groups,
                        ...fetchMoreResult.get_groups.groups
                      ]
                    }
                  };
                }
              }).catch(() => {
                Swal.fire({
                  title: "Something went wrong",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 3000,
                  onClose: () => {
                    setPage(0);
                    refetch(getVariablesDialog());
                  }
                });
              });
            }
          }
          return;
        case "input":
          {
            if (page < input) {
              for (let i = 1; i <= input; i += 1) {
                if (i * clientRowsPerPage > groupList.length) {
                  fetchMore({
                    variables: {
                      skip: i * rowsPerPage,
                      not: {
                        field: "id",
                        value: removeGroup
                      }
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }
                      return {
                        ...prev,
                        get_groups: {
                          count: fetchMoreResult.get_groups.count,
                          total: fetchMoreResult.get_groups.total,
                          groups: [
                            ...prev.get_groups.groups,
                            ...fetchMoreResult.get_groups.groups
                          ]
                        }
                      };
                    }
                  }).catch(() => {
                    Swal.fire({
                      title: "Something went wrong",
                      icon: "error",
                      showConfirmButton: false,
                      timer: 3000,
                      onClose: () => {
                        setPage(0);
                        refetch(getVariablesDialog());
                      }
                    });
                  });
                }
              }
              setPage(input - 1);
            }
            setPage(input - 1);
          }
          break;
        default:
          break;
      }
    } else {
      setDialogOpen(false);
      Swal.fire({
        title: "Input exceeded total  number of page",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        onClose: () => {
          setPage(0);
          refetch(getVariablesDialog());
        }
      });
    }
  };
  //

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Client"
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: (
        <Button color="primary" onClick={handleDialogOpen}>
          Add Entry
        </Button>
      ),
      sortable: false
    }
  ];

  function TableHeadCells(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      handleSort,
      isCheckedPartner
    } = props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={isCheckedPartner().indeterminate}
              checked={isCheckedPartner().all}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  TableHeadCells.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  const searchInputRef = React.useRef();
  const keywordChanged = e => {
    e.persist();
    setDialogFilter(searchInputRef.current.value);
  };

  const PartnerTable = props => {
    const { rowCount } = props;
    if (rowCount > 0) {
      return metaData ? (
        <TableBody>
          {getDataPartner().map((row, index) => {
            const isItemSelected = isSelectedPartner(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                key={`row-${row.id}`}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
              >
                <TableCell
                  padding="checkbox"
                  onClick={event => handleClickPartner(event, row.id)}
                >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  <Highlight search={contextData.searchKeyword.value}>
                    {row.name ? row.name : ""}
                  </Highlight>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      handleToDelete(row.id);
                      handleOpenDeleteClient();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow style={{ height: 53 * 2 }}>
            <TableCell colSpan={6} align="center">
              No data found
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow style={{ height: 53 * 2 }}>
          <TableCell colSpan={6} align="center">
            No data found
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  // const { loading: loadingPermissions, permissions } = useAllowedPermissions(
  //   "/admin/wti/alert_references/"
  // );
  // if (loadingPermissions) return <Typography>Loading...</Typography>;
  // if (!permissions.edit) return <Typography>403</Typography>;
  // if (isLoading) return <Loading />;
  return (
    <AccessControl
      resource={pathname}
      setEditPermission={setEditPermission}
      process="add"
    >
      <div className={classes.root}>
        <Header
          process="Add"
          moduleName={process.env.REACT_APP_ALERT_REFERENCES_MODULE}
          redirect={redirect}
          setDiscard={setDiscard}
          setRedirect={setRedirect}
          isDataNotChanged={!formEdit}
          history={history?.location?.state?.params}
        />
        <Paper className={classes.container} variant="outlined">
          <div className={classes.wrapper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  All fields with <span style={{ color: "red" }}>*</span> are
                  required
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  <InputLabel>
                    <b>Alert Details</b>
                  </InputLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel shrink>
                      <b>
                        Alert Description&nbsp;
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </InputLabel>
                    <Autocomplete
                      id="alert_description"
                      options={filterData}
                      value={state.alert_description}
                      getOptionLabel={option => {
                        return option.code || "";
                      }}
                      getOptionSelected={(o, v) => {
                        return v.value === o.value;
                      }}
                      renderOption={option => option.code}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Select Alert Description"
                          onChange={event => {
                            if (event.target.value.length > 0) {
                              get_description({
                                variables: {
                                  first: 5,
                                  filter: {
                                    field: "code",
                                    value: event.target.value
                                  }
                                }
                              });
                            } else {
                              setState({
                                ...state,
                                alert_description: null
                              });
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          helperText={
                            errors.alert_description
                              ? errors.alert_description
                              : ""
                          }
                        />
                      )}
                      onChange={(e, val) => {
                        if (val) {
                          setState({
                            ...state,
                            alert_description: val
                          });
                        }
                      }}
                      disableClearable
                    />
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        Alert Code&nbsp;<span style={{ color: "red" }}>*</span>
                      </b>
                    </InputLabel>
                    <TextField
                      // autoFocus
                      fullWidth
                      id="code"
                      placeholder="Enter Alert Code"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={state.code ? state.code : ""}
                      name="code"
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleChange(e);
                      }}
                      FormHelperTextProps={{ component: 'div' }}
                      helperText={errors.alert_code ? errors.alert_code : !errors.alert_code ? errors.code :""}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        Comparison/Oparators
                      </b>
                    </InputLabel>
                    <Select
                      name="operator"
                      value={state.operator ? state.operator : " "}
                      onChange={e => {
                        handleChange(e);
                      }}
                      fullWidth
                    >
                      <MenuItem value={" "} disabled>
                        Select comparison/oparator
                      </MenuItem>
                      <MenuItem value=">">
                        Greater than {`>`}
                      </MenuItem>
                      <MenuItem value="<">
                        Less than {`<`}
                      </MenuItem>
                      <MenuItem value="=">
                        Equal to {`=`}
                      </MenuItem>
                      <MenuItem value="None">
                        None
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        Value{[">", "<", "="].includes(state.operator) && (
                          <>&nbsp;<span style={{ color: "red" }}>*</span></>
                        )}
                      </b>
                    </InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      id="event_value"
                      placeholder="Enter value"
                      min={0}
                      max={999999}
                      InputProps={{
                        inputProps: { min: 0, max: 999999 }
                      }}
                      value={state.event_value ? state.event_value : ""}
                      name="event_value"
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleChange(e);
                      }}
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() }
                      helperText={errors.event_value}
                      disabled={![">", "<", "="].includes(state.operator)}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        Message Display&nbsp;
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </InputLabel>
                    <TextField
                      multiline
                      fullWidth
                      // rows={4}
                      minRows={4}
                      maxRows={4}
                      variant="outlined"
                      placeholder="Type Here..."
                      name="alertmessage"
                      id="alertmessage"
                      value={state.alertmessage ? state.alertmessage : ""}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleChange(e)
                      }}
                      FormHelperTextProps={{ component: 'div', variant: "standard" }}
                      helperText={
                        errors.alert_message || errors.alertmessage ? errors.alert_message || errors.alertmessage : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        Alert Level&nbsp;<span style={{ color: "red" }}>*</span>
                      </b>
                    </InputLabel>
                    <Select
                      name="level"
                      value={state.level ? state.level : " "}
                      onChange={e => {
                        handleChange(e);
                      }}
                      fullWidth
                    >
                      <MenuItem value={" "} disabled>
                        Select Alert Level
                      </MenuItem>
                      <MenuItem value="0">
                        <span style={{ color: "#9e9e9e" }}>
                          Discrete
                        </span>
                      </MenuItem>
                      <MenuItem value="1">
                        <span style={{ color: "#63F160" }}>
                          Normal
                        </span>
                      </MenuItem>
                      <MenuItem value="2">
                        <span style={{ color: "#ffd600" }}>
                          Moderate
                        </span>
                      </MenuItem>
                      <MenuItem value="3">
                        <span style={{ color: "#ff5722" }}>
                          Critical
                        </span>
                      </MenuItem>
                    </Select>
                    {errors.level ? errors.level : ""}
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>
                        GPS Device Type&nbsp;
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </InputLabel>
                    <Autocomplete
                      disableClearable
                      id="device_type"
                      options={deviceTypeData}
                      value={state.device_type}
                      getOptionLabel={option => {
                        return option.name || "";
                      }}
                      getOptionSelected={(o, v) => {
                        return v.value === o.value;
                      }}
                      renderOption={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Select Device Type"
                          onChange={event => {
                            if (event.target.value.length > 0) {
                              get_deviceType({
                                variables: {
                                  first: 5,
                                  filter: {
                                    field: "name",
                                    value: event.target.value
                                  }
                                }
                              });
                            } else {
                              setState({
                                ...state,
                                device_type: null
                              });
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          helperText={
                            errors.device_type ? errors.device_type : ""
                          }
                        />
                      )}
                      onChange={(e, val) => {
                        if (val) {
                          setState({
                            ...state,
                            device_type: val
                          });
                        }
                      }}
                      PaperComponent={({ children }) => {
                        return (
                          <Paper className={classes.paper}>
                            <Button
                              fullWidth
                              style={{ justifyContent: "flex-start", padding: "5px 0 0 17px" }}
                              onMouseDown={() => {
                                setState({
                                  ...state,
                                  device_type: {
                                    parent_id: null,
                                    name: "All",
                                    id: 0,
                                    description: "All"
                                  }
                                });
                              }}
                            >
                              All
                            </Button>
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <InputLabel shrink>
                      <b>Remarks</b>
                    </InputLabel>
                    <TextField
                      multiline
                      fullWidth
                      // rows={4}
                      minRows={4}
                      maxRows={4}
                      variant="outlined"
                      placeholder="Type Here..."
                      name="description"
                      id="description"
                      value={state.description ? state.description : ""}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Toolbar
                      style={{ display: "flex" }}
                      className={classes.TBroot}
                      // fullWidth
                    >
                      <Typography style={{ flex: 1 }}>
                        <HtmlTooltip
                          placement="top-start"
                          // title={
                          //   <React.Fragment>
                          //     <Typography color="inherit">
                          //       Data of primary client will be linked to all
                          //       selected partners (and vice versa)
                          //     </Typography>
                          //   </React.Fragment>
                          // }
                          title="Data of primary client will be linked to all selected partners (and vice versa)"
                        >
                          <HelpIcon className={classes.helpIcon} />
                        </HtmlTooltip>
                        &nbsp; Partners <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "2px solid #D2D2D2",
                            boxSizing: "border-box",
                            borderRadius: 23,
                            padding: "4px 10px 3px 10px",
                            marginRight: 20
                          }}
                        >
                          <InputBase
                            placeholder="Search…"
                            style={{ width: "100%" }}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput
                            }}
                            onChange={e => {
                              // if(userKeyword) {
                              if (e.target.value === "") {
                                setMetaData(filterMetaData);
                              } else {
                                const temp = metaData.filter(
                                  item =>
                                    item.name
                                      .toLowerCase()
                                      .indexOf(e.target.value) > -1
                                );
                                setMetaData(temp);
                              }
                              // }

                              // setUserKeyword(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                          />
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                        </div>
                      </div>
                    </Toolbar>
                    {errors.clients}
                    {selectedPartner.length > 0 && (
                      <Toolbar
                        className={clsx(classes.TBroot, {
                          [classes.highlight]: selectedPartner.length > 0,
                          [classes.invisible]: selectedPartner.length <= 0
                        })}
                      >
                        {selectedPartner.length > 0 && (
                          <Tooltip title="Delete" style={{ float: "left" }}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleOpenDeleteAll();
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {selectedPartner.length > 0 && (
                          <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                            style={{ color: "black" }}
                          >
                            <span style={{ color: "#FF845E" }}>
                              {selectedPartner.length}
                            </span>{" "}
                            client/s selected
                          </Typography>
                        )}
                      </Toolbar>
                    )}
                    <TableContainer
                      style={{
                        overflowY: "auto",
                        maxHeight: 500
                      }}
                    >
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                      >
                        <TableHeadCells
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClickPartner}
                          handleSort={handleSort}
                          isCheckedPartner={isCheckedPartner}
                        />
                        <PartnerTable
                          selected={selected}
                          partnerData={metaData}
                          classes={classes}
                          rowCount={metaData.length}
                          handleSelectAllClick={handleSelectAllClick}
                          handleRequestSort={handleRequestSort}
                          order={order}
                          orderBy={orderBy}
                        />
                      </Table>
                    </TableContainer>
                    <div>
                      <IconButton
                        name="down_page"
                        disabled={pagePartner === 0}
                        onClick={() => setPagePartner(pagePartner - 1)}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      {`${pagePartner + 1} / ${Math.ceil(
                        metaData.length / 10
                      )}`}
                      <Button
                        name="up_page"
                        disabled={
                          pagePartner === Math.ceil(metaData.length / 10) - 1
                        }
                        onClick={() => setPagePartner(pagePartner + 1)}
                      >
                        <KeyboardArrowRightIcon />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit}
                  fullWidth
                  style={{
                    margin: 20,
                    maxWidth: "30%",
                    marginTop: 10,
                    borderRadius: "17px"
                  }}
                  onClick={validateEntry}
                >
                  Save Details
                </Button>
              </Grid>
            </Grid>
          </div>
          </Paper>

          <ConfirmationDialog
            toggle={dialog.discard}
            close={() => handleCloseDialog("discard")}
            fn={() => discarded()}
            title="Discard Changes?"
            content="Are you sure you want to leave this page and discard changes?"
          />

          <ConfirmationDialog
            toggle={toOpenDeleteDialog}
            close={() => handleCloseDialog()}
            fn={e => handleDelete(e)}
            title="Delete?"
            content="Are you sure you want to delete this client?"
          />

          <ConfirmationDialog
            toggle={dialogDeleteAll}
            close={() => handleCloseDeleteAll()}
            fn={e => handleMultipleDeleteCode(e)}
            title="Delete?"
            content="Are you sure you want to delete these clients?"
          />

          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add Entry</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", alignItems: "left" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #D2D2D2",
                    boxSizing: "border-box",
                    borderRadius: 23,
                    padding: "4px 10px 3px 10px",
                    marginRight: 20
                  }}
                >
                  <InputBase
                    placeholder="Search…"
                    style={{ width: "100%" }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputRef={searchInputRef}
                    onChange={debounce(keywordChanged, 250)}
                    // onChange={e => {
                    //   // setSearchKeyword({
                    //   //   ...searchKeyword,
                    //   //   value: e.target.value,
                    //   //   submit: false,
                    //   //   moduleName: process.env.REACT_APP_USERS_MODULE
                    //   // });
                    //   // setUserKeyword(e.target.value);
                    //   setDialogFilter(e.target.value);
                    //   refetch(getVariablesDialog());
                    // }}
                    inputProps={{ "aria-label": "search" }}
                  />
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                </div>
                <IconButton
                  name="down_page"
                  disabled={page === 0}
                  onClick={() => handleRefetchDialog("prev", 0)}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <TextField
                  name="page"
                  readOnly
                  onFocus={e => {
                    e.target.select();
                  }}
                  onChange={e => {
                    handleRefetchDialog("input", Number(e.target.value));
                  }}
                  variant="outlined"
                  inputProps={{ style: { padding: 0, textAlign: "center" } }}
                  value={page + 1}
                  style={{ width: "32px", marginRight: "8px" }}
                />
                {`/ ${Math.floor(clientTotalRows / clientRowsPerPage)}`}
                <Button
                  name="up_page"
                  disabled={
                    page + 1 === Math.floor(clientTotalRows / clientRowsPerPage)
                  }
                  onClick={() => handleRefetchDialog("next", 0)}
                >
                  <KeyboardArrowRightIcon />
                </Button>
              </div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <DialogTableHeadCells
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    handleSort={handleSort}
                    isChecked={isChecked}
                    editMode={editMode}
                  />
                  <GroupTable
                    selected={selected}
                    partnerData={partner.clients}
                    classes={classes}
                    rowCount={partner.count}
                    handleSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                  />
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button color="primary" onClick={handleAddSelectedClient}>
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmationDialog
            toggle={dialog.save}
            close={() => handleCloseDialog("save")}
            fn={() => saveAdd()}
            title="Save Changes?"
            content="Are you sure you want to save this data?"
          />
      </div>
    </AccessControl>
  );
}
