import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { InfoRounded as InfoIcon } from "@material-ui/icons";

const errorMessageStyles = makeStyles({
  container: {
    padding: "4px",
    boxSizing: "border-box",
    background: "#fae6e687",
    display: "flex",
    alignItems: "center"
  },
  infoIconRoot: {
    fontSize: "16px",
    color: "#f44336eb"
  },
  messageRoot: {
    flex: 1,
    marginLeft: 4,
    fontSize: "14px",
    color: "#f44336eb"
  }
});

const ErrorMessage = props => {
  const { message } = props;
  const styles = errorMessageStyles();
  return (
    <div className={styles.container}>
      <InfoIcon classes={{ root: styles.infoIconRoot }} />
      <Typography classes={{ root: styles.messageRoot }}>{message}</Typography>
    </div>
  );
};

export default ErrorMessage;
