import buffer from "@turf/buffer";
import { lineString } from "@turf/helpers";
import wkt from "wkt";

export const getMultiPolygonStr = (steps, bufferValue) => {
  // get the locations of steps in directions
  const point_array = [];
  for (let i = 0; i < steps.length; i++) {
    const point = [steps[i].lng(), steps[i].lat()];
    point_array.push(point);
  }

  const linestring = lineString(point_array);
  const bufferedline = buffer(linestring, bufferValue * 0.001, {
    units: "kilometers"
  });
  const bufferedline_reverse = bufferedline.geometry.coordinates[0].map(
    point => [point[1], point[0]]
  );

  const multipolygon_string = wkt.stringify({
    type: "MultiPolygon",
    coordinates: [[bufferedline_reverse]]
  });

  return multipolygon_string;
};

export const getOverviewPath = (steps) => {
  // get the locations of steps in directions
  const point_array = [];
  for (let i = 0; i < steps.length; i++) {
    const point = {lat: steps[i].lat(), lng: steps[i].lng()}
    point_array.push(point);
  }

  return point_array;
};

export const getWaypoints = (steps, start, end) => {
  // get the locations of steps in directions
  const waypoints = [];

  for (let i = 0; i < steps.length; i++) {
    if (i === 0) {
      const waypoint_start = {
        location: {
          lat: steps[i].start_location.lat(),
          lng: steps[i].start_location.lng()
        },
        stopover: true
      };
      const waypoint_end = {
        location: {
          lat: steps[i].end_location.lat(),
          lng: steps[i].end_location.lng()
        },
        stopover: true
      };
      waypoints.push(waypoint_start, waypoint_end);
    } else
      waypoints.push({
        location: {
          lat: steps[i].end_location.lat(),
          lng: steps[i].end_location.lng()
        },
        stopover: false
      });
  }

  return waypoints;
};

