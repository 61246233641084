import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  TableBody,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from "@material-ui/core";
import { Close, CloudDownload } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { keyGenerator, issues /* downloadFile */ } from "../../Utils";
import { GET_ATTACHMENTS } from "../../../graphql/Queries";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    // width: "100%",
    marginBottom: theme.spacing(2),
    padding: 8
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  list: {
    width: 250
  },
  expandable: {
    verticalAlign: "top"
  },
  subCell: {
    display: "block",
    marginTop: "20px"
  },
  link: {
    margin: 5,
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer"
  }
}));

const headCells = [
  {
    id: "items",
    numeric: false,
    disablePadding: false,
    label: "Items"
  },
  {
    id: "so_qty",
    numeric: true,
    disablePadding: false,
    label: "SO Qty"
  },
  {
    id: "dr_qty",
    numeric: true,
    disablePadding: false,
    label: "DR Qty"
  }
];

const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{ backgroundColor: "#E0F0FA" }}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ItemsDialog = props => {
  const classes = useStyles();
  const { items, open, setItemsDialogOpen } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("items");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const stableSort = (array, cmp) => {
    if (!array) return [];
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const desc = (a, b, oB) => {
    if (b[oB] < a[oB]) {
      return -1;
    }
    if (b[oB] > a[oB]) {
      return 1;
    }
    return 0;
  };

  const getSorting = (o, oB) => {
    return o === "desc" ? (a, b) => desc(a, b, oB) : (a, b) => -desc(a, b, oB);
  };
  return (
    <Dialog
      open={open.items}
      onClose={() => {
        const dialogOpen = { ...open, items: false };
        setItemsDialogOpen(dialogOpen);
      }}
    >
      <DialogTitle>SO Details</DialogTitle>
      <DialogContent>
        <TableContainer component="table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {items.length &&
              stableSort(items[0].items, getSorting(order, orderBy)).map(
                item => (
                  <TableRow key={keyGenerator(4)}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="center">{item.qty}</TableCell>
                    <TableCell align="center">{item.dr_count}</TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const dialogOpen = { ...open, items: false };
            setItemsDialogOpen(dialogOpen);
          }}
          style={{ borderRadius: 32 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AttachmentsDialog = props => {
  const { open, tripNumber, close } = props;
  const [attachments, setAttachments] = useState([]);
  useQuery(GET_ATTACHMENTS, {
    variables: {
      condition: [
        {
          field: "trip_number",
          value: tripNumber ? [tripNumber] : []
        }
      ]
    },
    onCompleted: ({ get_trip_attachments }) => {
      setAttachments(get_trip_attachments.data);
    }
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        Preview Attachments{" "}
        <IconButton onClick={close} style={{ float: "right" }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <List component="nav">
          {attachments.length > 0 &&
            attachments.map(attachment => (
              <ListItem key={attachment.id}>
                <ListItemText
                  primary={`${attachment.name} (${(
                    attachment.size / 1000
                  ).toFixed(2)} KB)`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="primary"
                    title="Download"
                    // onClick={() => downloadFile(attachment.file)}
                  >
                    <CloudDownload />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export const IssuesDialog = props => {
  const { open, setItemsDialogOpen, addIssue } = props;
  const [issue, setIssue] = useState(null);
  const [issueText, setIssueText] = useState(null);

  useEffect(() => {
    if (
      _.findIndex(issues, is => {
        return is === open.issues.issue;
      }) === -1 &&
      open.issues.issue
    ) {
      setIssue("Others");
      setIssueText(open.issues.issue);
    } else {
      setIssue(open.issues.issue);
      setIssueText(open.issues.issue);
    }
  }, [open]);

  const handleChange = event => {
    setIssue(event.target.value);
  };

  const handleIssueText = event => {
    setIssueText(event.target.value);
  };

  return (
    <Dialog
      open={open.issues.open}
      onClose={() =>
        setItemsDialogOpen({
          ...open,
          issues: { open: false, mode: "edit", activeIndex: null, issue: null }
        })
      }
    >
      <DialogTitle>Delivery feedback</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              value={issue}
              onChange={handleChange}
              name="issue"
            >
              {issues.map(i => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {issue === "Others" && (
              <TextField
                fullWidth
                placeholder="Please specify"
                value={issueText}
                onChange={handleIssueText}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            setItemsDialogOpen({
              ...open,
              issues: {
                open: false,
                mode: "edit",
                activeIndex: null,
                issue: null
              }
            })
          }
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 32 }}
          onClick={() => {
            addIssue(
              issue === "Others" ? issueText : issue,
              open.issues.activeIndex,
              open.issues.is_pickup
            );
            setItemsDialogOpen({
              ...open,
              issues: {
                open: false,
                mode: "edit",
                activeIndex: null,
                issue: null
              }
            });
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
