import React, { useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { ExpandMore, TripOrigin } from "@material-ui/icons";
import {
  Button,
  Popper,
  ClickAwayListener,
  Fade,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
  Tabs,
  Tab,
  withStyles,
  Box
} from "@material-ui/core";
import moment from "moment";
import { GET_VEHICLES_COUNT } from "../../../graphql/Queries";
// import UserContext from "../../../context/UserContext";
import useVehicleCountStyles from "./VehicleCount.styles";
import VehicleVariations from "./VehicleVariations";
import { mapRef } from "../Inner";
import useUserContext from "../../../context/User/useUserContext";
import { gpsStatus } from "../../../components/Utils";
import { countVehicles } from "../../../data/vehicleCountData";
import UseMapViewStyles from "../../../components/Monitoring/Share Location/MapView.style";

const VehicleCount = React.memo(
  ({
    setSelectedVehicle,
    vehicles,
    renderVehicleCount,
    bounds,
    groupIds = [],
    hasAccessPrebookingTrips
  }) => {
    const styles = useVehicleCountStyles();
    const classes = UseMapViewStyles();
    // const user = useContext(UserContext);
    const userCtx = useUserContext();
    const isWTIAdmin =
      +userCtx.user_level_id === +process.env.REACT_APP_WTI_ADMIN;
    const isAvailableBookedShown = hasAccessPrebookingTrips || isWTIAdmin;

    const [tabValue, setTabValue] = useState(0);
    const { data: vehicleCounts, loading } = useQuery(GET_VEHICLES_COUNT, {
      variables: {
        start: moment().format("YYYY-MM-DD HH:mm:ss"),
        // group_ids: [user.group_ids[0]],
        bounds,
        group_ids: groupIds
      },
      skip: !bounds
    });

    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };

    return renderVehicleCount ? (
      renderVehicleCount(vehicleCounts)
    ) : (
      <PopupState variant="popper" popupId="vehicleCount-popup-popper">
        {popupState => (
          <div className={styles.alignment}>
            <Typography className={styles.typography}> Count: </Typography>
            <Button
              // variant="contained"
              color="primary"
              className={styles.btn}
              {...bindToggle(popupState)}
            >
              {loading ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                vehicleCounts?.get_vehicles_count?.total || 0
              )}
              <ExpandMore />
            </Button>
            <Popper
              {...bindPopper(popupState)}
              // placement={isMapView ? "bottom-end" : "bottom"}
              transition
              style={{ zIndex: "1100" }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={() => {
                    popupState.close();
                  }}
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      style={{
                        width: "250px",
                        minHeight: "30vh",
                        maxHeight: "60vh",
                        overflow: "auto"
                      }}
                    >
                      <CustomTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                      >
                        <CustomTab
                          label={
                            <div style={{ textTransform: "none" }}>Count</div>
                          }
                        />
                        <CustomTab
                          label={
                            <div style={{ textTransform: "none" }}>
                              Vehicles
                            </div>
                          }
                        />
                      </CustomTabs>

                      {tabValue === 1 &&
                        vehicles.map(vehicle => (
                          <Box
                            key={vehicle.plateno}
                            component="div"
                            className={styles.infoLabelBox}
                            onClick={() => setSelectedVehicle(vehicle)}
                          >
                            <TripOrigin
                              className={styles.labelIconStatus}
                              style={{
                                color: gpsStatus(
                                  vehicle.device_info?.device_status.status_code.toLowerCase()
                                ).color
                              }}
                            />
                            <Typography className={styles.label}>
                              {vehicle.plateno}
                            </Typography>
                          </Box>
                        ))}

                      {tabValue === 0 && (
                        <List
                          className={
                            !tabValue ? styles.listItem : styles.listItemHidden
                          }
                        >
                          {countVehicles.map((info, index) => (
                            <>
                              {info.isHeader && index > 1 && (
                                <Divider variant="middle" />
                              )}
                              <ListItem key={info.label} dense>
                                {info.label !== "Count" &&
                                  (["Available", "Booked"].includes(
                                    info.label
                                  ) ? (
                                    <ListItemText
                                      id={info.label}
                                      primary={info.label}
                                      className={
                                        info.isHeader ? classes.text_count : ""
                                      }
                                      style={{
                                        display: hasAccessPrebookingTrips
                                          ? "block"
                                          : "none"
                                      }}
                                    />
                                  ) : (
                                    <ListItemText
                                      id={info.label}
                                      primary={info.label}
                                      className={
                                        info.isHeader ? classes.text_count : ""
                                      }
                                      style={{
                                        display:
                                          info.label !== "Active"
                                            ? "block"
                                            : !hasAccessPrebookingTrips &&
                                              info.label === "Active"
                                            ? "block"
                                            : "none"
                                      }}
                                    />
                                  ))}
                                {!info.isHeader && (
                                  <ListItemSecondaryAction>
                                    {["Available", "Booked"].includes(
                                      info.label
                                    ) ? (
                                      <Typography
                                        edge="end"
                                        className={classes.countResult}
                                        style={{
                                          display: isAvailableBookedShown
                                            ? "block"
                                            : "none"
                                        }}
                                      >
                                        {info.isTotal
                                          ? vehicles.length
                                          : vehicleCounts?.get_vehicles_count
                                              ?.status_count?.[
                                              info.dataHeader
                                            ] || 0}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        edge="end"
                                        className={classes.countResult}
                                        style={{
                                          display:
                                            info.label !== "Active"
                                              ? "block"
                                              : !hasAccessPrebookingTrips &&
                                                info.label === "Active"
                                              ? "block"
                                              : "none"
                                        }}
                                      >
                                        {info.isTotal
                                          ? vehicles.length
                                          : vehicleCounts?.get_vehicles_count
                                              ?.status_count?.[
                                              info.dataHeader
                                            ] || 0}
                                      </Typography>
                                    )}
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                              {info.subCategory.length > 0 &&
                                info.subCategory.map(subInfo => {
                                  return [
                                    "Under Repair",
                                    "Not In Use"
                                  ].includes(subInfo.label) ? (
                                    <ListItem
                                      className={classes.nested}
                                      key={subInfo.label}
                                      dense
                                      style={{
                                        display: hasAccessPrebookingTrips
                                          ? "block"
                                          : "none"
                                      }}
                                    >
                                      <ListItemText
                                        id={subInfo.label}
                                        primary={`${info.label}${
                                          subInfo.label === "Under Repair"
                                            ? " "
                                            : "-"
                                        }${subInfo.label}`}
                                      />
                                      <ListItemSecondaryAction
                                        style={{
                                          color: "gray",
                                          display: hasAccessPrebookingTrips
                                            ? "block"
                                            : "none"
                                        }}
                                      >
                                        <Typography
                                          edge="end"
                                          className={classes.countResult}
                                        >
                                          {vehicleCounts?.get_vehicles_count
                                            ?.status_count?.[
                                            subInfo.dataHeader
                                          ] || 0}
                                        </Typography>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  ) : (
                                    <ListItem
                                      className={classes.nested}
                                      key={subInfo.label}
                                      dense
                                    >
                                      <ListItemText
                                        id={subInfo.label}
                                        primary={`${info.label}${
                                          subInfo.label === "Under Repair"
                                            ? " "
                                            : "-"
                                        }${subInfo.label}`}
                                      />
                                      <ListItemSecondaryAction
                                        style={{ color: "gray" }}
                                      >
                                        <Typography
                                          edge="end"
                                          className={classes.countResult}
                                        >
                                          {vehicleCounts?.get_vehicles_count
                                            ?.status_count?.[
                                            subInfo.dataHeader
                                          ] || 0}
                                        </Typography>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })}
                            </>
                          ))}
                        </List>
                      )}
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    );
  }
);

export const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    "& > span": {
      maxWidth: 40,
      width: "100%"
    }
  }
})(Tabs);

const CustomTab = withStyles(theme => ({
  root: {
    fontSize: 16,
    marginRight: theme.spacing(0),
    "&:hover": {
      color: "#F49400",
      opacity: 1
    },
    "&$selected": {
      color: "#F49400",
      backgroundColor: "transparent"
    },
    "&:focus": {
      color: "#F49400"
    },
    borderStyle: "none",
    padding: "0px",
    minWidth: 90,
    height: 10
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

export default VehicleCount;
