/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from "react";
import {
  AppBar,
  CircularProgress,
  Toolbar,
  Button,
  Typography,
  IconButton,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  Checkbox,
  TableBody,
  Popover,
  Tooltip,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuList,
  Grid,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  useTheme
} from "@material-ui/core";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import {
  FilterList,
  GetApp,
  MoreVert,
  ErrorOutline,
  TripOrigin,
  Brightness1,
  AssignmentLate,
  Warning,
  Publish,
  CloudUpload,
  ExpandMore
} from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import XLSX from "xlsx";
import Cookie from "js-cookie";
import swal from "sweetalert2";
import PropTypes from "prop-types";
import withReactContent from "sweetalert2-react-content";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
// import UserContext from "../../context/UserContext";
import {
  GET_BOOKINGS_v2,
  GET_BOOKINGS,
  GET_BOOKINGS_DOWNLOADABLE_TABLE_DATA,
  GET_DISPATCH_BOOKINGS_V2,
  GET_TABLEDATA_BOOKINGS_V2,
  GET_CLOSED_BOOKINGS_v2,
  GET_BOOKING_LIST_V2
} from "../../api/bookingRepository";
import Filter from "./Filter";
import {
  REFRESH_TRIPS,
  MANUAL_COMPLETE,
  ADD_NOTIFICATION,
  ADD_BOOKING_MUTATION,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  UPDATE_INTERISLAND_BOOKING
} from "../../graphql/Mutations";
import Loading from "../../utils/Loading";
import AccessControl from "../../utils/AccessControl";
import {
  // PREBOOKING_PERMISSIONS,
  // GET_DEVICES,
  GET_DEVICES_TRIPS,
  GET_GEOFENCES_TABLE,
  GET_VEHICLES_LOOKUP,
  GET_CLIENTS_QUERY,
  GET_SERVICE_TYPES_QUERY
} from "../../graphql/Queries";
import DispatchDialog from "../Trip/utils/modal/DispatchDialog";
import VehicleAssignDialog from "../Trip/utils/modal/VehicleAssign";
import CloseDialog from "../Trip/utils/modal/CloseDialog";
import SearchContext from "../../context/SearchContext";
import { BOOKINGS_SUBSCRIPTION } from "../../graphql/Subscription";
import Battery from "../../utils/Battery";
import AddLogs from "../../utils/functions/AddLogs";
import ConfirmationDialog from "../../utils/modals/ConfirmationDialog";
import { gpsStatus, parseDateExcel } from "../Utils";
import Pagination from "../../utils/table/Pagination";
import validate from "../../utils/validation";
import client from "../../Client";
import useUserContext from "../../context/User/useUserContext";
import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "./Prebooking.Style";
import { theme1 } from "../../context/Theme/themes";

const Highlight = require("react-highlighter");

const MySwal = withReactContent(swal);

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  // {
  //   id: "dispatch_type",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  // {
  //   id: "isHotload",
  //   numeric: false,
  //   sortable: false,
  //   label: ""
  // },
  {
    id: "isOverdue",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "trip_number",
    numeric: false,
    disablePadding: false,
    label: "Trip Number",
    sortable: true
  },
  {
    id: "hasEmptySo",
    numeric: false,
    sortable: false,
    label: ""
  },
  {
    id: "service_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Service Type",
    sortable: true
  },
  {
    id: "pickups.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Pickup Location",
    sortable: true
  },
  {
    id: "pickups.arrival",
    numeric: false,
    disablePadding: false,
    label: "Pickup Date",
    sortable: true
  },
  {
    id: "dropoffs.name.keyword",
    numeric: false,
    disablePadding: false,
    label: "Delivery Location",
    sortable: true
  },
  {
    id: "dropoffs.arrival",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    sortable: true
  },
  {
    // id: "client_name.keyword",
    id: "group_names.keyword",
    numeric: false,
    disablePadding: false,
    label: "Hauler",
    sortable: true
  },
  {
    id: "vehicle_plate_no.keyword",
    numeric: false,
    disablePadding: false,
    label: "Plate/ CV no.",
    sortable: true
  },
  {
    id: "trip_type.keyword",
    numeric: false,
    disablePadding: false,
    label: "Trip Type",
    sortable: true
  },
  {
    id: "status_code.keyword",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sortable: false
  }
];

const EnhancedTableHead = props => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    component,
    onSelectAllTrips,
    isChecked
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead component={component}>
      <TableRow component={component}>
        <TableCell padding="checkbox" style={{ backgroundColor: "#FFFFFF" }}>
          <Checkbox
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={isChecked().indeterminate}
            checked={isChecked().all}
            onChange={onSelectAllTrips}
            color="primary"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            component={component}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.muiTableHeader}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              <span style={{ whiteSpace: "nowrap" }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Download = React.memo(props => {
  const { downloadBTN, getDownloadData } = props;
  const classes = useStyles();
  return (
    <PopupState variant="popper" popupId="vehicleCount-popup-popper">
      {popupState => (
        <div className={classes.alignment}>
          <Button
            variant="contained"
            className={classes.btn_download}
            {...bindToggle(popupState)}
          >
            <GetApp /> Download
          </Button>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom"
            transition
            style={{ zIndex: "1100", marginTop: 10 }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener
                onClickAway={() => {
                  popupState.close();
                }}
              >
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <MenuList id="menu-list-grow">
                      <MenuItem
                        onClick={() => {
                          AddLogs(
                            process.env.REACT_APP_PRE_DISPATCH_MODULE,
                            "download_template",
                            ""
                          );
                        }}
                      >
                        <Link
                          to="/assets/trips_upload_template.xlsx"
                          target="_blank"
                          download
                          underline="none"
                          style={{ textDecoration: "none", color: "black" }}
                          className={classes.muiTableCellData}
                        >
                          Download Template
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          getDownloadData();
                        }}
                        disabled={downloadBTN}
                        className={classes.muiTableCellData}
                      >
                        {downloadBTN ? `Preparing...` : `Download Table`}
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
});

const UploadDialog = React.memo(props => {
  const classes = useStyles();
  const user = JSON.parse(Cookie.get("user"));
  const { uploadDialog, handleCloseUpload, serviceTypes } = props;
  const [file, setFile] = useState([]);
  // const [validIndex, setValidIndex] = useState([]);
  const [re, setRe] = useState(false);
  const [uploadBtn, setUploadBtn] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [bookingList, setBookingList] = useState([]);

  const [addBooking] = useMutation(ADD_BOOKING_MUTATION);
  const [updateBooking] = useMutation(UPDATE_BOOKING);
  const [deleteBooking] = useMutation(DELETE_BOOKING);

  // For parsing of new data add or update
  const parserTrip = async (action, data, trip) => {
    try {
      const {
        reference_number,
        so_number,
        status,
        trucker,
        booking_remarks,
        dr_number,
        driver,
        dropoff_end_time,
        dropoff_location,
        dropoff_location_details,
        dropoff_start_time,
        items,
        ordinal,
        pickup_end_time,
        pickup_start_time,
        pickup_location_details,
        pickup_location,
        plate_number,
        vehicle_type,
        service_type
      } = data;

      const { vehicle_history, status_code_id, dispatch_type: dispatch_name } =
        trip && trip.length
          ? { ...trip[0] }
          : { vehicle_history: [], status_code_id: 0 };

      // const is_update_but_new_insert = action === 1 && trip && trip.length;
      const vehicle_history_array = [...(vehicle_history || [])];

      const data_complete = false;

      const pickups = [
        {
          location_details: pickup_location_details,
          so_number
        }
      ];
      const dropoffs = [
        {
          location_details: dropoff_location_details,
          so_number
        }
      ];

      // for checking of pickup location and drop off location if exist
      if (geofenceList) {
        _.forEach(geofenceList, geo => {
          if (geo.geofence_code === pickup_location) {
            pickups[0] = {
              ...pickups[0],
              geofence_id: geo.id,
              geofence_code: geo.geofence_code,
              name: geo.name,
              arrival: pickup_start_time,
              departure: pickup_end_time
            };
          }
          if (geo.geofence_code === dropoff_location) {
            dropoffs[0] = {
              ...dropoffs[0],
              geofence_id: geo.id,
              geofence_code: geo.geofence_code,
              name: geo.name,
              arrival: dropoff_start_time,
              departure: dropoff_end_time
            };
          }
        });

        if (!pickups[0].geofence_code) {
          pickups[0] = {
            ...pickups[0],
            geofence_code: pickup_location,
            arrival: pickup_start_time,
            departure: pickup_end_time
          };
        }
        if (!dropoffs[0].geofence_code) {
          dropoffs[0] = {
            ...dropoffs[0],
            geofence_code: dropoff_location,
            arrival: dropoff_start_time,
            departure: dropoff_end_time
          };
        }
      } else {
        pickups[0] = {
          ...pickups[0],
          geofence_code: pickup_location,
          arrival: pickup_start_time,
          departure: pickup_end_time
        };
        dropoffs[0] = {
          ...dropoffs[0],
          geofence_code: dropoff_location,
          arrival: dropoff_start_time,
          departure: dropoff_end_time
        };
      }

      const variables = {
        trip_number: reference_number,
        so_number,
        ref_number: reference_number,
        personnels: [{ name: driver }],
        dr_number: dr_number.toString(),
        pickups,
        dropoffs,
        data_complete,
        items,
        remarks: booking_remarks,
        is_urgent: false
      };

      // checking of trucker/client/hauler
      if (trucker) {
        _.forEach(clientList, clients => {
          if (clients.name.toLowerCase() === trucker.toLowerCase()) {
            variables.client_id = clients.id;
            variables.client_name = clients.name;
            variables.group_ids = clients.group_ids;
            variables.group_names = clients.group_names;
          }
        });
      }

      // for checking if add or update
      // for update just get the previous status code for pickup and dropoff

      // if (action === 1) {
      //   if (
      //     is_update_but_new_insert &&
      //     trip[0].pickups &&
      //     trip[0].pickups.length
      //   ) {
      //     // pickups[0].status_code_id = trip[0].pickups[0].status_code_id || 1;
      //     // pickups[0].status_code = trip[0].pickups[0].status_code || "Accepted";

      //     pickups[0].status_code_id = status === 1 ? 1 : status === 2 ? 2 : trip[0].pickups[0].status_code_id;
      //     pickups[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : trip[0].pickups[0].status_code_id;

      //     if (trip[0].pickups[0].actual_arrival) {
      //       pickups[0].actual_arrival = trip[0].pickups[0].actual_arrival;
      //     }
      //   } else {
      //     pickups[0].status_code_id = status === 1 ? 1 : status === 2 ? 2 : trip?.[0].pickups?.[0]?.status_code_id || 1;
      //     pickups[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : trip?.[0]?.pickups?.[0]?.status_code_id || "Accepted";
      //   }
      //   if (
      //     is_update_but_new_insert &&
      //     trip[0].dropoffs &&
      //     trip[0].dropoffs.length
      //   ) {
      //     const selected_trip = trip.filter(t => {
      //       const dindex = t.dropoffs.findIndex(
      //         d => d.geofence_code === dropoffs[0].geofence_code
      //       );
      //       return dindex >= 0;
      //     });
      //     const dropoff_index = selected_trip.length
      //       ? selected_trip[0].dropoffs.findIndex(
      //           d => d.geofence_code === dropoffs[0].geofence_code
      //         )
      //       : -1;
      //     if (dropoff_index >= 0) {
      //       // dropoffs[0].status_code_id =
      //       //   selected_trip[0].dropoffs[dropoff_index].status_code_id || 1;
      //       // dropoffs[0].status_code =
      //       //   selected_trip[0].dropoffs[dropoff_index].status_code ||
      //       //   "Accepted";

      //       dropoffs[0].status_code_id =
      //         status === 1 ? 1 : status === 2 ? 2 : selected_trip?.[0]?.dropoffs?.[dropoff_index]?.status_code_id;
      //       dropoffs[0].status_code =
      //         status === 1 ? "Accepted" : status === 2 ? "Scheduled" : selected_trip?.[0]?.dropoffs?.[dropoff_index]?.status_code;
      //     }
      //   } else {
      //     dropoffs[0].status_code_id = status === 1 ? 1 : status === 2 ? 2 : trip[0].dropoffs?.[0]?.status_code_id || 1;
      //     dropoffs[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : trip[0].dropoffs?.[0]?.status_code || "Accepted";
      //   }
      // } else {
      //   const toUpdate = trip.length
      //     ? trip.filter(t => t.trip_number === reference_number)
      //     : [];
      //   if (toUpdate.length) {
      //     pickups[0].status_code_id = parseInt(
      //       status === 1 ? 1 : status === 2 ? 2 : toUpdate?.[0]?.pickups?.[0]?.status_code_id || 1,
      //       0
      //     );
      //     pickups[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : toUpdate?.[0]?.pickups?.[0]?.status_code || "Accepted";

      //     dropoffs[0].status_code_id = parseInt(
      //       status === 1 ? 1 : status === 2 ? 2 : toUpdate?.[0]?.pickups?.[0]?.status_code_id || 1,
      //       0
      //     );
      //     dropoffs[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : toUpdate?.[0]?.pickups?.[0]?.status_code || "Accepted";
      //   } else {
      //     pickups[0].status_code_id = status === 1 ? 1 : status === 2 ? 2 : toUpdate?.[0]?.pickups?.[0]?.status_code_id || 1;
      //     pickups[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : toUpdate?.[0]?.pickups?.[0]?.status_code || "Accepted";

      //     dropoffs[0].status_code_id = status === 1 ? 1 : status === 2 ? 2 : toUpdate?.[0]?.pickups?.[0]?.status_code_id || 1;
      //     dropoffs[0].status_code = status === 1 ? "Accepted" : status === 2 ? "Scheduled" : toUpdate?.[0]?.pickups?.[0]?.status_code || "Accepted";
      //   }
      // }

      if (ordinal) {
        variables.ordinal = ordinal;
      }

      if (service_type) {
        variables.service_type = service_type.name;
        variables.service_type_id = +service_type.id;
      }

      // for checking if add or update
      // for update just get the previous status code
      // if (action === 1) {
      //   if (is_update_but_new_insert) {
      //     variables.status_code_id = status === 1 ? 1 : status === 2 ? 27 : trip?.[0]?.status_code_id || 1;
      //     variables.status_code = status === 1 ? "Accepted" : status === 2 ? "Incoming" : trip?.[0]?.status_code || "Accepted";
      //   } else {
      //     variables.status_code_id = status === 1 ? 1 : status === 2 ? 27 : trip?.[0]?.status_code_id || 1;
      //     variables.status_code = status === 1 ? "Accepted" : status === 2 ? "Incoming" : trip?.[0]?.status_code || "Accepted";
      //   }
      // } else {
      //   variables.status_code_id = status === 1 ? 1 : status === 2 ? 27 : trip?.[0]?.status_code_id || 1;
      //   variables.status_code = status === 1 ? "Accepted" : status === 2 ? "Incoming" : trip?.[0]?.status_code || "Accepted";
      // }

      // for adding a vehicle history
      const new_vehicle_history = {
        user_full_name: user.username,
        datestamp: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      if (vehicleList.length > 0) {
        _.forEach(vehicleList, vehicle => {
          if (vehicle.plateno.toLowerCase() === plate_number.toLowerCase()) {
            variables.vehicle_plate_no = vehicle.plateno;
            variables.vehicle_id = parseInt(vehicle.device_id, 0);
            variables.vehicle_type = vehicle.vehicle_type
              ? vehicle.vehicle_type
              : vehicle_type;
            // vehicle history
            new_vehicle_history.vehicle_id = parseInt(vehicle.device_id, 0);
            new_vehicle_history.vehicle_plate_no = vehicle.plateno;
          }
        });

        if (!variables.vehicle_plate_no) {
          variables.vehicle_plate_no = plate_number;
          variables.vehicle_id = null;
          new_vehicle_history.vehicle_plate_no = plate_number;
          new_vehicle_history.vehicle_id = null;
          variables.vehicle_type = vehicle_type;
        }
      } else if (plate_number) {
        variables.vehicle_plate_no = plate_number;
        variables.vehicle_type = vehicle_type;
        if (action === 0) {
          variables.vehicle_id = null;
        }
        // vehicle history
        new_vehicle_history.vehicle_plate_no = plate_number;
        new_vehicle_history.vehicle_id = null;
      } else {
        variables.vehicle_type = vehicle_type;
      }

      if (variables.vehicle_plate_no) {
        const vehicle_history_index = vehicle_history_array.length
          ? vehicle_history_array.length - 1
          : -1;
        const last_vehicle =
          vehicle_history_index >= 0
            ? vehicle_history_array[vehicle_history_index].vehicle_plate_no
            : "";
        if (
          vehicle_history_index < 0 ||
          last_vehicle !== variables.vehicle_plate_no
        ) {
          vehicle_history_array.push(new_vehicle_history);
        }
      }
      if (vehicle_history_array.length) {
        if (
          status_code_id &&
          [
            process.env.REACT_APP_INCOMING_STATUS,
            process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP
          ].includes(`${status_code_id}`)
        ) {
          if (
            vehicle_history_array[vehicle_history_array.length - 1]
              .vehicle_plate_no !== variables.vehicle_plate_no
          ) {
            vehicle_history_array.pop();
          }
        }
        variables.vehicle_history = vehicle_history_array;
      }

      // for checking if data_complete true
      if (
        reference_number &&
        variables.pickups &&
        variables.pickups[0].geofence_id &&
        variables.pickups[0].arrival &&
        variables.dropoffs &&
        variables.dropoffs[0].geofence_id &&
        variables.dropoffs[0].arrival &&
        variables.vehicle_id !== null
        // && so_number !== "" &&
        // so_number !== null &&
        // so_number !== undefined
      ) {
        variables.data_complete = true;
        variables.pickups[0].status_code_id =
          status === 1
            ? 1
            : status === 2
            ? 2
            : trip?.[0].pickups?.[0]?.status_code_id || 1;
        variables.pickups[0].status_code =
          status === 1
            ? "Accepted"
            : status === 2
            ? "Scheduled"
            : trip?.[0]?.pickups?.[0]?.status_code || "Accepted";

        variables.dropoffs[0].status_code_id =
          status === 1
            ? 1
            : status === 2
            ? 2
            : trip?.[0].dropoffs?.[0]?.status_code_id || 1;
        variables.dropoffs[0].status_code =
          status === 1
            ? "Accepted"
            : status === 2
            ? "Scheduled"
            : trip?.[0]?.dropoffs?.[0]?.status_code || "Accepted";

        variables.status_code_id =
          status === 1 ? 1 : status === 2 ? 27 : trip?.[0]?.status_code_id || 1;
        variables.status_code =
          status === 1
            ? "Accepted"
            : status === 2
            ? "Incoming"
            : trip?.[0]?.status_code || "Accepted";

        variables.dispatch_type =
          status === 1
            ? "Not Yet"
            : status === 2
            ? "Automatic"
            : dispatch_name || "Not Yet";

        variables.pre_dispatched_ready = true;
        variables.dispatch_time = moment().format("YYYY-MM-DD HH:mm:ss");

        if (status === 1) {
          delete variables?.dispatch_time;
        }

        if (status === 3) {
          if (!trip?.[0]?.dispatch_time) {
            delete variables?.dispatch_time;
          }
        }
      } else {
        variables.pickups[0].status_code_id = 1;
        variables.pickups[0].status_code = "Accepted";

        variables.dropoffs[0].status_code_id = 1;
        variables.dropoffs[0].status_code = "Accepted";

        variables.status_code_id = 1;
        variables.status_code = "Accepted";
        variables.dispatch_type = "Not Yet";
        variables.pre_dispatched_ready = false;
        // variables.dispatch_time = null
      }

      // const statusCodeId = variables?.dropoffs?.[0]?.status_code_id;
      // const statusCode = variables?.dropoffs?.[0]?.status_code;

      // checking if add or updated
      if (action === 1) {
        await addBooking({ variables });

        // for update missing so
        // for (let count = 0; trip?.length > count; count += 1) {
        //   if (
        //     !trip[count].id.includes(for_deletions) ||
        //     for_deletions.length === 0
        //   ) {
        //     variables.id = trip[count].id;
        //     variables.dropoffs = _.cloneDeep(trip[count].dropoffs);
        //     delete variables.dropoffs[0].status_code_id;
        //     delete variables.dropoffs[0].status_code;
        //     variables.dropoffs[0].status_code_id = statusCodeId;
        //     variables.dropoffs[0].status_code = statusCode;
        //     variables.so_number = trip[count].so_number;
        //     delete variables.pickups[0].so_number;
        //     variables.pickups[0].so_number = trip[count].so_number;
        //     await updateBooking({ variables });
        //   }
        // }
      } else {
        let id;
        const so_nums = [];
        const missing_ids = [];
        const dropoffsUpdate = [];

        // for update to get the id
        _.forEach(trip, _so => {
          if (
            _so.ref_number === reference_number &&
            _so.so_number !== "" &&
            _so.so_number !== null &&
            _so.so_number !== undefined &&
            _so.so_number === so_number
          ) {
            id = _so.id;
          } else {
            // checking if deleted data is in trips
            missing_ids.push(_so.id);
            so_nums.push(_so.so_number);
            dropoffsUpdate.push(_so.dropoffs);
          }
        });
        // some case has deleted trips so incase of update we will add
        if (id) {
          variables.id = id;
          await updateBooking({ variables });
        } else {
          await addBooking({ variables });
        }

        // for update missing so
        // for (let count = 0; missing_ids?.length > count; count += 1) {
        //   if (
        //     !missing_ids[count].includes(for_deletions) ||
        //     for_deletions.length === 0
        //   ) {
        //     variables.id = missing_ids[count];
        //     variables.dropoffs = _.cloneDeep(dropoffsUpdate[count]);
        //     delete variables.dropoffs[0].status_code_id;
        //     delete variables.dropoffs[0].status_code;
        //     variables.dropoffs[0].status_code_id = statusCodeId;
        //     variables.dropoffs[0].status_code = statusCode;
        //     variables.so_number = so_nums[count];
        //     delete variables.pickups[0].so_number;
        //     variables.pickups[0].so_number = so_nums[count];
        //     await updateBooking({ variables });
        //     console.log(variables, 'not existing')
        //   }
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delete_booking = async ids => {
    // for deleting blank so
    _.forEach(ids, _id => {
      deleteBooking({ variables: { id: _id } });
    });
  };

  const uploader = async tripsList => {
    // use reference_number-so_number as reference when getting unique rows (for so_number with multiple items)
    const tempArray = [
      ...new Set(
        tripsList.map(row => `${row.reference_number}-${row.so_number}`)
      )
    ];
    const tripsBatch = tempArray.map(tripSO => {
      return tripsList.find(
        t => `${t.reference_number}-${t.so_number}` === tripSO
      );
    });

    const temp = file;
    if (tripsBatch.length) {
      // _.forEach(tripsBatch, async (tempTrip) => {
      for (let i = 0; i < tripsBatch.length; i += 1) {
        // const fo_number = tempTrip.reference_number;
        // const { so_number } = tempTrip;
        const fo_number = tripsBatch[i].reference_number;
        const { so_number } = tripsBatch[i];
        const bookingResponse = await client.query({
          query: GET_BOOKING_LIST_V2,
          variables: {
            trip_numbers: fo_number,
            orderBy: { field: "created", direction: "desc" }
          },
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first"
        });
        const { bookings } = await bookingResponse.data.get_bookings_per_trip;
        if (bookings) {
          let for_add = true;
          _.forEach(bookings, async _trip => {
            const { key, value } = _trip;
            const id = [];
            const forDeletion = [];
            if (key === fo_number) {
              for_add = false;
              _.forEach(value, so => {
                if (
                  so.so_number === null ||
                  so.so_number === undefined ||
                  so.so_number === ""
                ) {
                  forDeletion.push(so.id);
                }

                if (so.so_number === so_number) {
                  id.push(so.id);
                }
              });

              if (forDeletion.length) {
                await delete_booking(forDeletion);
              }

              if (id.length) {
                await parserTrip(0, tripsBatch[i], value, forDeletion);
              } else {
                await parserTrip(1, tripsBatch[i], value, forDeletion);
              }
            }
          });

          if (for_add) {
            await parserTrip(1, tripsBatch[i]);
          }
        } else {
          await parserTrip(1, tripsBatch[i]);
        }
      }

      await AddLogs(process.env.REACT_APP_PRE_DISPATCH_MODULE, "upload", ``);

      if (temp[file.length - 1].errors.length) {
        temp[file.length - 1] = {
          ...file[file.length - 1],
          status: "Partial Success"
        };
        setIsUploading(false);
        setFile(temp);
        setRe(!re);
      } else {
        temp[file.length - 1] = {
          ...file[file.length - 1],
          status: "Success"
        };
        setIsUploading(false);
        setFile(temp);
        setRe(!re);
      }
    } else if (tripsBatch.length < 1) {
      temp[file.length - 1] = {
        ...file[file.length - 1],
        status: "Failed"
        // errors: fileErrors
      };
      setIsUploading(false);
      setFile(temp);
      setRe(!re);
    }
  };

  const getData = async (tempClient, trips, geofence, vehicle) => {
    // for getting all data that needed
    const clientResponse = await client.query({
      query: GET_CLIENTS_QUERY,
      variables: {
        filter: [
          {
            field: "name",
            value: tempClient.length ? tempClient : ""
          }
        ],
        first: 1000
      },
      fetchPolicy: "network-only"
    });

    const geofenceResponse = await client.query({
      query: GET_GEOFENCES_TABLE,
      variables: {
        filter: [
          {
            field: "geofence_code",
            value: geofence.length ? geofence : ""
          }
        ],
        first: 10000
      },
      fetchPolicy: "network-only"
    });

    const vehicleResponse = await client.query({
      query: GET_VEHICLES_LOOKUP,
      variables: {
        filter: [
          {
            field: "plateno",
            value: vehicle.length ? vehicle : ""
          }
        ],
        first: vehicle.length ? 1000 : 1
      },
      fetchPolicy: "network-only"
    });

    const bookingResponse = await client.query({
      query: GET_BOOKING_LIST_V2,
      variables: {
        trip_numbers: trips.length ? trips : "",
        orderBy: { field: "created", direction: "desc" },
        first: 1000
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first"
    });

    const { clients } = await clientResponse.data.get_clients;
    const { geofences } = await geofenceResponse.data.get_geofences;
    const { vehicles } = await vehicleResponse.data.get_vehicles;
    const { bookings } = await bookingResponse.data.get_bookings_per_trip;

    setClientList(clients);
    setGeofenceList(geofences);
    setVehicleList(vehicles);
    setBookingList(bookings);

    setUploadBtn(false);
  };

  const truckerChecker = async val => {
    // validation if trucker/client/hauler is exist
    if (clientList) {
      const clients = clientList.filter(
        row => row.name.toLowerCase() === val.toString().toLowerCase()
      )[0];

      if (clients) {
        return clients.name;
      }
      return "";
    }
    return "";
  };

  // eslint-disable-next-line consistent-return
  const validateCell = async (keys, value) => {
    let val = "";
    let temp = "";
    switch (keys) {
      case "reference_number":
        if (!value) {
          val = { error: "Trip Number is required" };
        } else if (bookingList.length) {
          _.forEach(bookingList, _trips => {
            const { key, value: _value } = _trips;
            if (key === value.trim()) {
              const excluded_status = [
                process.env.REACT_APP_STATUS_IN_TRANSIT,
                process.env.REACT_APP_STATUS_ARRIVED_AT_DROPOFF,
                process.env.REACT_APP_STATUS_COMPLETED,
                process.env.REACT_APP_STATUS_COMPLETED_LATE,
                process.env.REACT_APP_STATUS_TRIP_CLOSED,
                process.env.REACT_APP_STATUS_DECLINED_ID,
                process.env.REACT_APP_STATUS_ARCHIVED_ID,
                process.env.REACT_APP_STATUS_CANCEL_ID,
                process.env.REACT_APP_STATUS_TRIP_MANUAL_COMPLETE
              ];
              if (
                _value[0].status_code_id &&
                excluded_status.includes(`${_value[0].status_code_id}`)
              ) {
                val = {
                  error: `Trip Number is already ${_value[0].status_code}`
                };
              }
            }
          });

          if (val === "") {
            val = value.trim();
          }
        } else {
          val = value.trim();
        }
        break;
      case "trucker":
        if (!value) {
          val = { error: "Trucker is required" };
        } else {
          temp = await truckerChecker(value);
          val = temp === "" ? { error: "Trucker does not exist" } : temp;
        }
        break;
      case "pickup_location":
        if (!value) {
          val = { error: "Pickup location is required" };
        } else {
          val = value.toString().trim();
        }
        break;
      case "dropoff_location":
        if (!value) {
          val = { error: "Dropoff location is required" };
        } else {
          val = value.toString().trim();
        }
        break;
      case "status":
        if (!value || +value > 3) {
          val = 1;
        } else {
          val = +value;
        }
        break;
      case "so_number":
        if (value) {
          val = value.trim();
        }
        break;
      case "so_qty_volume":
        if (!value || !validate("numeric", value)) {
          val = 0;
        } else {
          val = parseInt(value, 10);
        }
        break;
      case "dr_qty_volume":
        if (!value || !validate("numeric", value)) {
          val = 0;
        } else {
          val = parseInt(value, 10);
        }
        break;
      case "plate_number":
        val = value ? value.trim() : "";
        break;
      case "service_type":
        if (value) {
          const serviceInfo = serviceTypes.find(
            s => s.name.toLowerCase() === value.trim().toLowerCase()
          );
          val = serviceInfo || "";
        }
        break;
      default:
        val = value || "";
        break;
    }
    return val;
  };

  const parseRow = async array => {
    const temp = {};
    await Promise.all(
      process.env.REACT_APP_TRIPS_KEYS.split(",").map(async (key, index) => {
        const validatedCell = await validateCell(key, array[index]);
        if (
          key === "pickup_start_time" ||
          key === "pickup_end_time" ||
          key === "dropoff_start_time" ||
          key === "dropoff_end_time"
        ) {
          let val = null;
          if (array[index]?.toString().trim()) {
            const tempDate = moment(
              parseDateExcel(array[index]?.toString().trim())
            ).format("YYYY-MM-DD HH:mm:ss");
            if (tempDate.toLocaleLowerCase() === "invalid date") {
              val = { error: `${_.startCase(key)} is invalid format` };
            } else {
              val = tempDate;
            }
          }
          if (val) {
            Object.assign(temp, {
              [key]: val
            });
          }
        } else {
          Object.assign(temp, { [key]: validatedCell });
        }
      })
    );
    return temp;
  };

  const handleUpload = async e => {
    setUploadBtn(true);
    const { files } = e.target;
    setFile([
      ...file,
      { file: files[0], status: "", errors: [], warnings: [] }
    ]);
    if (files[0]) {
      const f = files[0];
      const reader = new FileReader();

      reader.onload = async event => {
        const dataRes = new Uint8Array(event.target.result);
        const workbook = XLSX.read(dataRes, { type: "array" });
        const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const datz = XLSX.utils.sheet_to_json(first_worksheet, {
          header: 1,
          blankrows: false
        });
        const headers = process.env.REACT_APP_TRIPS_KEYS.split(",");

        const findHeaderIndex = colName =>
          headers.findIndex(h => h === colName);

        const clientIndex = findHeaderIndex("trucker");
        const tripIndex = findHeaderIndex("reference_number");
        const pickupIndex = findHeaderIndex("pickup_location");
        const dropoffIndex = findHeaderIndex("dropoff_location");
        const vehicleIndex = findHeaderIndex("plate_number");

        const tempClient = [];
        const tempTripNumber = [];
        const tempGeofence = [];
        const tempVehicle = [];
        if (datz.length === 0 || datz[0].length === headers.length) {
          for (let i = 1; i <= datz.length - 1; i += 1) {
            if (datz[i][clientIndex] !== undefined) {
              tempClient.push(datz[i][clientIndex].toString());
            }

            if (datz[i][tripIndex] !== undefined) {
              tempTripNumber.push(datz[i][tripIndex].toString().trim());
            }

            if (datz[i][pickupIndex] !== undefined) {
              tempGeofence.push(datz[i][pickupIndex].toString().trim());
            }

            if (datz[i][dropoffIndex] !== undefined) {
              tempGeofence.push(datz[i][dropoffIndex].toString().trim());
            }

            if (datz[i][vehicleIndex] !== undefined) {
              tempVehicle.push(datz[i][vehicleIndex].toString().trim());
            }
          }
          getData(tempClient, tempTripNumber, tempGeofence, tempVehicle);
        } else {
          setUploadBtn(false);
        }
      };
      reader.readAsArrayBuffer(f);
    }
    e.target.value = null;
  };

  const parseItem = val => {
    return {
      name: val.item,
      volume: val.dr_qty_volume,
      qty: val.so_qty_volume,
      dr_count: val.dr_qty_volume,
      uom: val.uom,
      reference_number: val.reference_number
    };
  };

  const submitUpload = () => {
    setIsUploading(true);
    const tempFile = file;
    const f = file[file.length - 1].file;
    const reader = new FileReader();
    reader.onload = async e => {
      const dataRes = new Uint8Array(e.target.result);
      const workbook = XLSX.read(dataRes, { type: "array" });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const datz = XLSX.utils.sheet_to_json(first_worksheet, {
        header: 1,
        blankrows: false
      });
      const tempBatchData = [];
      const fileErrors = [];
      const fileWarnings = [];
      const validIdx = [];

      if (f.size / 1024 / 1024 > 5) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is too large"
        };
        setFile(tempFile);
        setRe(!re);
        setIsUploading(false);
        return;
      }

      if (
        datz.length === 0 ||
        datz[0].length !== process.env.REACT_APP_TRIPS_KEYS.split(",").length
      ) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "Please use the template provided"
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
        return;
      }

      if (datz.length === 1) {
        tempFile[file.length - 1] = {
          ...tempFile[file.length - 1],
          status: "Failed",
          errors: "File is empty"
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
        return;
      }

      for (let i = 1; i <= datz.length - 1; i += 1) {
        const temp = await parseRow(datz[i]);
        if (
          Object.values(temp).every(value => Object.keys(value)[0] !== "error")
        ) {
          tempBatchData.push(temp);
          validIdx.push(i + 1);
        } else {
          Object.values(temp)
            .filter(value => Object.keys(value)[0] === "error")
            .map(value => {
              fileErrors.push({
                row: i + 1,
                errors: value.error
              });
            });
          tempBatchData.push({
            ...temp,
            toDelete: true
          });
        }
      }
      if (tempBatchData.filter(d => !d.toDelete).length < 1) {
        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Failed",
          errors: fileErrors
        };
        setIsUploading(false);
        setFile(tempFile);
        setRe(!re);
      } else {
        const tempData = [...tempBatchData];
        const tripLevelValidation = [
          { label: "Service Type", key: "service_type" },
          { label: "Booking Remarks", key: "booking_remarks" },
          { label: "Vehicle Type", key: "vehicle_type" },
          { label: "Driver", key: "driver" }
        ];
        const soLevelValidation = [
          { label: "Pickup Location", key: "pickup_location" },
          { label: "Pickup Location Details", key: "pickup_location_details" },
          { label: "Pickup Start Time", key: "pickup_start_time" },
          { label: "Pickup End Time", key: "pickup_end_time" },
          { label: "Dropoff Location", key: "dropoff_location" },
          {
            label: "Dropoff Location Details",
            key: "dropoff_location_details"
          },
          { label: "Dropoff Start Time", key: "dropoff_start_time" },
          { label: "Dropoff End Time", key: "dropoff_end_time" },
          { label: "Ordinal", key: "ordinal" }
        ];
        _.forEach(tempBatchData, (data, index) => {
          if (data.toDelete) delete tempData[index];

          const errMessage = [];
          const tripWarnings = [];
          const soWarnings = [];
          for (let i = 0; i < tempBatchData.length; i += 1) {
            if (+index !== +i) {
              if (
                data.so_number &&
                tempBatchData[i].so_number &&
                data.so_number === tempBatchData[i].so_number &&
                data.reference_number !== tempBatchData[i].reference_number
              ) {
                errMessage.push(
                  "There seems to be a duplicate SO with different trip number"
                );
              }

              if (
                data.reference_number === tempBatchData[i].reference_number &&
                data.plate_number !== tempBatchData[i].plate_number
              ) {
                errMessage.push(
                  "There seems to be a different plate number of the same trip number"
                );
              }

              if (
                data.reference_number === tempBatchData[i].reference_number &&
                +data.status !== +tempBatchData[i].status
              ) {
                errMessage.push(
                  "There seems to be a different status for SOs of the same trip number"
                );
              }

              if (
                data.reference_number === tempBatchData[i].reference_number &&
                data.so_number === tempBatchData[i].so_number &&
                tempData[index]
              ) {
                tempData[index].items = [
                  ...(tempData[index].items || [parseItem(tempData[index])]),
                  parseItem(tempBatchData[i])
                ];
              }

              if (data.reference_number === tempBatchData[i].reference_number) {
                tripLevelValidation.forEach(t => {
                  if (data[t.key] !== tempBatchData[i][t.key]) {
                    tripWarnings.push(t.label);
                  }
                });
                if (data.so_number === tempBatchData[i].so_number) {
                  soLevelValidation.forEach(s => {
                    if (data[s.key] !== tempBatchData[i][s.key]) {
                      soWarnings.push(s.label);
                    }
                  });
                }
              }
            } else if (tempData[index] && !tempData[index].items) {
              tempData[index].items = [parseItem(tempData[index])];
            }
          }

          if (errMessage.length) {
            delete tempData[index];
            [...new Set(errMessage)].map(value => {
              fileErrors.push({
                row: index + 2,
                errors: value
              });
            });
          }

          if (tripWarnings.length) {
            const tripWarningText =
              tripWarnings.length > 0
                ? `Columns does not match on the same Trip: ${_.join(
                    [...new Set(tripWarnings)],
                    ", "
                  )}`
                : "";
            if (tripWarningText) {
              fileWarnings.push({
                row: index + 2,
                warnings: tripWarningText
              });
            }
          }

          if (soWarnings.length) {
            const soWarningText =
              soWarnings.length > 0
                ? `Columns does not match on the same SO: ${_.join(
                    [...new Set(soWarnings)],
                    ", "
                  )}`
                : "";
            if (soWarningText) {
              fileWarnings.push({
                row: index + 2,
                warnings: soWarningText
              });
            }
          }
        });

        tempFile[file.length - 1] = {
          ...file[file.length - 1],
          status: "Uploading",
          errors: fileErrors,
          warnings: fileWarnings
        };

        setIsUploading(false);
        setFile(tempFile);
        // setValidIndex(validIdx);
        setUploadBtn(true);

        uploader(tempData.filter(Boolean));
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const pendingFile = () => {
    if (file.length > 0) {
      if (!file[file.length - 1].status) {
        return true;
      }
    }
    return false;
  };

  const reorder = (a, b) => {
    const first = a.row;
    const second = b.row;

    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }

    return comparison;
  };

  const [expanded, setExpanded] = useState(-1);

  const handleClick = index => {
    setExpanded(index === expanded ? -1 : index);
  };

  // if (loading) return <Loading />;
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleCloseUpload}
      aria-labelledby="customized-dialog-title"
      open={uploadDialog}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseUpload}>
        Upload
      </DialogTitle>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContent,
          dividers: classes.dialogContent
        }}
      >
        {file.length > 0 &&
          file.map((fileInfo, index) => (
            <ExpansionPanel
              key={index}
              square
              expanded={index === expanded}
              onClick={() => handleClick(index)}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  ["Failed", "Partial Success", "Success"].includes(
                    fileInfo.status
                  ) &&
                  !![...fileInfo.errors, ...fileInfo.warnings].length && (
                    <ExpandMore />
                  )
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography className={classes.typography}>
                      {fileInfo.file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className={classes.typography}>
                      {(fileInfo.file.size / 1024 / 1024).toFixed(2)}mb
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {isUploading && file.length - 1 === index ? (
                      <CircularProgress size={12} />
                    ) : (
                      <Typography
                        className={`${classes.typography} ${
                          fileInfo.status === "Partial Success" ||
                          fileInfo.status === "Failed"
                            ? classes.statusFailed
                            : classes.statusSuccessful
                        }`}
                      >
                        {fileInfo.status}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {["Failed", "Partial Success", "Success"].includes(
                fileInfo.status
              ) &&
                !![...fileInfo.errors, ...fileInfo.warnings].length && (
                  <ExpansionPanelDetails
                    onClick={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    {typeof fileInfo.errors === "string" ? (
                      <Typography style={{ fontSize: 14, margin: 8 }}>
                        {fileInfo.errors}
                      </Typography>
                    ) : (
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "100px" }}>
                              Row
                            </TableCell>
                            <TableCell align="left">Reason</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[...fileInfo.errors, ...fileInfo.warnings].length >
                            0 &&
                            [...fileInfo.errors, ...fileInfo.warnings]
                              .sort(reorder)
                              .filter(row => row.errors !== "")
                              .map(row => (
                                <TableRow key={row.name}>
                                  <TableCell component="th" scope="row">
                                    <Typography className={classes.typography}>
                                      {row.row}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography className={classes.typography}>
                                      {row.errors || row.warnings}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    )}
                  </ExpansionPanelDetails>
                )}
            </ExpansionPanel>
          ))}
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%" }}>
          <label htmlFor="icon-upload-file">
            <input
              className="form-control"
              color="primary"
              accept=".csv, .xlsx, .xls"
              type="file"
              id="icon-upload-file"
              style={{ display: "none" }}
              disabled={pendingFile()}
              onChange={e => handleUpload(e)}
            />
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              style={{ backgroundColor: "#ffb677" }}
              startIcon={<Publish />}
              disabled={pendingFile()}
            >
              Add a File
            </Button>
          </label>
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              autoFocus
              color="primary"
              startIcon={<CloudUpload />}
              style={{ color: "white", borderRadius: 50 }}
              onClick={submitUpload}
              disabled={uploadBtn}
            >
              Upload
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
});

const PreBooking = () => {
  const theme = useTheme();
  // const { location } = props;
  const location = useLocation();
  const classes = useStyles();
  const [keyLog, setKeyLog] = useState(
    `Service Type: All, Dispatch Type All, Status: All`
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("pickups.arrival");
  const [bookings, setBookings] = useState([]);
  // const userDetails = useContext(UserContext);
  const [isFiltered, setIsFiltered] = useState(false);
  const csvLink = useRef();
  const [downloadData, setDownloadData] = useState({
    headers: [
      { label: "Trip Number", key: "fo_number" },
      { label: "Service Type", key: "service_type" },
      { label: "Pickup Location", key: "pickup_location" },
      { label: "Pickup Date", key: "pickup_date" },
      { label: "Dropoff Location", key: "dropoff_location" },
      { label: "Delivery Date", key: "dropoff_date" },
      { label: "Hauler", key: "hauler" },
      { label: "Plate/ CV no", key: "plate_no" },
      { label: "Trip Type", key: "trip_type" },
      { label: "Status", key: "status" },
      { label: "Dispatch Type", key: "dispatch_type" }
    ],
    body: []
  });
  const [activeRow, setActiveRow] = useState({});
  const [activeRawData, setActiveRawData] = useState({});
  const [openDispatchModal, setOpenDispatchModal] = useState(false);
  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [closeModal, toggleCloseModal] = useState(false);
  const contextData = useContext(SearchContext);
  const [permissions, setPermissions] = useState({
    change_truck: { view: false, edit: false },
    dispatch: { view: false, edit: false },
    manual_complete: { view: false, edit: false },
    split: { view: false, edit: false },
    close: { view: false, edit: false },
    upload: { view: false, edit: false }
  });
  const user = JSON.parse(Cookie.get("user"));
  const user_context = useUserContext();
  // const { data: permission_data } = useQuery(PREBOOKING_PERMISSIONS, {
  //   variables: {
  //     user_id: user.id
  //   },
  //   fetchPolicy: "cache-and-network"
  // });
  const [trip, setTrip] = useState();
  const [tripReassign, setTripReassign] = useState([]);
  const [dvar, setdvar] = useState([]);

  const [loaded, hasLoaded] = useState(false);

  const [openActions, setOpenActions] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [downloadBTN, setDownloadBTN] = useState(false);
  const [openRefreshSingle, setOpenRefreshSingle] = useState(false);
  const [openRefresh, setOpenRefresh] = useState(false);
  const [openDispatch, setOpenDispatch] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [closeData, setCloseData] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [dispatchContent, setDispatchContent] = useState();
  const [dispatchTitle, setDispatchTitle] = useState();
  const [loadOngoing, setLoadOngoing] = useState(false);

  const [uploadDialog, setUploadDialog] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const open = Boolean(openActions);
  const id = open ? "actions-popover" : undefined;
  const [openInterIsland, setOpenInterIsland] = useState(false);

  useQuery(GET_SERVICE_TYPES_QUERY, {
    onCompleted: data => {
      const { get_service_types } = data;
      setServiceTypes(get_service_types);
    },
    skip: !openFilter
    // fetchPolicy: "network-only"
  });

  const initialFilter = {
    date_range: {
      field: "pickups.arrival",
      start: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD 00:00:00"),
      end: moment()
        .add(60, "days")
        .format("YYYY-MM-DD 23:59:59")
    },
    // skip: 0,
    // rows_per_page: rowsPerPage,
    orderBy: !isFiltered
      ? [
          { field: "is_urgent", direction: "desc" },
          { field: "overdue", direction: "desc" },
          { field: orderBy, direction: order }
        ]
      : [{ field: orderBy, direction: order }],
    conditions: [
      // {
      //   field: "client_id",
      //   value: userDetails.client.id.toString()
      // },
      // { field: "data_complete", value: ["false"] },
      // {
      //   // field: "status_code",
      //   // value: ["Accepted", "Scheduled", "Incoming", "Arrived at Pickup"]
      //   field: "status_code_id",
      //   value: ["1", "2", "27", "15"]
      // },
      {
        field: "dispatch_type",
        value: ["Not Yet"]
      }
      // {
      //   field: "data_complete",
      //   value: ["false"]
      // }
    ],
    or: [
      // {
      //   field: "status_code",
      //   value: ["Accepted", "Scheduled", "Incoming", "Arrived at Pickup"]
      // },
      // { field: "data_complete", value: "false" }
      // {
      //   field: "status_code_id",
      //   value: ["1", "2", "27", "15"]
      // },
      {
        field: "status_code_id",
        value: [
          process.env.REACT_APP_ACCEPTED_STATUS,
          process.env.REACT_APP_SCHEDULED_STATUS,
          process.env.REACT_APP_INCOMING_STATUS,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
          ...(location?.state?.params?.trip_numbers?.length
            ? [
                process.env.REACT_APP_STATUS_TRIP_CLOSED,
                process.env.REACT_APP_STATUS_CANCEL_ID
              ]
            : [])
        ]
      }
    ],
    // not: [
    //   // { field: "status_code.keyword", value: "Arrived at Drop off" },
    //   // { field: "status_code.keyword", value: "Completed" },
    //   // { field: "status_code.keyword", value: "In Transit" },
    //   // { field: "status_code_id.keyword", value: "24" },
    //   // { field: "status_code_id.keyword", value: "4" },
    //   // { field: "status_code_id.keyword", value: "20" },
    //   { field: "dispatch_type.keyword", value: "Automatic" },
    //   { field: "dispatch_type.keyword", value: "Manual" },
    //   {
    //     field: "so_number",
    //     value: ""
    //   }
    // ],
    trip_numbers:
      location?.state && location?.state?.params
        ? location.state.params.trip_numbers
        : []
  };
  const savedFilter = JSON.parse(localStorage.getItem("prebooking_filter"));
  const [queryFilter, setQueryFilter] = useState(savedFilter || initialFilter);

  const [get_devices] = useLazyQuery(GET_DEVICES_TRIPS, {
    onCompleted: data => {
      if (data) {
        setdvar(data.get_devices.devices);
      }
    },
    fetchPolicy: "network-only"
    // fetchPolicy: "cache-and-network"
  });

  const {
    data: bookings_data,
    refetch,
    loading,
    subscribeToMore,
    fetchMore
  } = useQuery(GET_TABLEDATA_BOOKINGS_V2, {
    variables: {
      ...queryFilter,
      // skip: currentPage * rowsPerPage,
      rows_per_page: currentPage * rowsPerPage + rowsPerPage,
      skip: 0
      // rows_per_page: rowsPerPage
    },
    // variables: queryFilter,
    onCompleted: result => {
      if (contextData.searchKeyword.value) {
        AddLogs(
          process.env.REACT_APP_PRE_DISPATCH_MODULE,
          "search",
          contextData.searchKeyword.value
        );
      }
      // const plate_no = [];
      const device_ids = [];
      result.get_bookings_per_trip.bookings.map(row => {
        return row.value.map(drow => {
          if (drow.vehicle_id) {
            device_ids.push(drow.vehicle_id);
            // plate_no.push(drow.vehicle_plate_no);
          }
          return device_ids;
        });
      });
      if (_.uniq(device_ids).length) {
        get_devices({
          variables: {
            first: 10000,
            // condition: [{ field: "vehicle_info.plateno", value: plate_no }]
            condition: [{ field: "devices.id", value: _.uniq(device_ids) }]
          }
        });
      }
    },
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: "network-only",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  // mutation for interisland
  const [updateInterisland] = useMutation(UPDATE_INTERISLAND_BOOKING, {
    onCompleted: data => {
      if (!data.update_booking_all) return;
      const { trip_number, is_inter_island } = data.update_booking_all[0];

      // update logs for updated trip inter island.
      AddLogs(
        process.env.REACT_APP_PRE_DISPATCH_MODULE,
        "is_inter_island_update",
        `${trip_number} to ${
          is_inter_island ? "inter island" : "standard"
        } trip`
      );
      setOpenConfirm(true);
    }
  });

  const fetchMoreData = nextPage => {
    fetchMore({
      variables: {
        rows_per_page: nextPage * rowsPerPage + rowsPerPage,
        skip: nextPage * rowsPerPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          get_bookings_per_trip: {
            count: fetchMoreResult.get_bookings_per_trip.count,
            total: fetchMoreResult.get_bookings_per_trip.total,
            bookings: [
              // ...prev.get_bookings_per_trip.bookings,
              ...fetchMoreResult.get_bookings_per_trip.bookings
            ]
          }
        };
      }
    }).catch(() => {
      // MySwal.fire({
      //   title: "Something went wrong",
      //   icon: "error",
      //   showConfirmButton: false,
      //   timer: 3000,
      //   onClose: () => {
      //     setCurrentPage(0);
      //     refetch();
      //   }
      // });
    });
  };

  function desc(a, b, orb) {
    if (b[orb] < a[orb]) {
      return -1;
    }
    if (b[orb] > a[orb]) {
      return 1;
    }
    return 0;
  }

  const isSelected = name => selectedTrips.indexOf(name) !== -1;

  function getSorting(or, orb) {
    return or === "desc"
      ? (a, b) => desc(a, b, orb)
      : (a, b) => -desc(a, b, orb);
  }

  const getData = useCallback(() => {
    if (bookings) {
      return stableSort(bookings, getSorting(order, orderBy)).slice(
        Number(currentPage) * rowsPerPage,
        Number(currentPage) * rowsPerPage + rowsPerPage
      );
    }
    return null;
  }, [bookings]);

  const isChecked = () => {
    let checked = { all: false, indeterminate: false };
    if (selectedTrips.length) {
      if (!selectedTrips.length < getData().length) {
        const displayedData = getData().map(data => data.tripNumber);
        if (
          displayedData.every(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: true, indeterminate: false };
        else if (
          displayedData.some(
            tripNumber => selectedTrips.indexOf(tripNumber) > -1
          )
        )
          checked = { all: false, indeterminate: true };
      }
    }

    return checked;
  };

  // eslint-disable-next-line no-shadow
  const handleClick = (event, id) => {
    const selectedIndex = selectedTrips.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTrips, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTrips.slice(1));
    } else if (selectedIndex === selectedTrips.length - 1) {
      newSelected = newSelected.concat(selectedTrips.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTrips.slice(0, selectedIndex),
        selectedTrips.slice(selectedIndex + 1)
      );
    }

    setSelectedTrips(newSelected);
  };

  const handleSelectAllTrips = event => {
    const tempSelected = selectedTrips;
    const deselect = () => {
      const displayedData = getData()
        .filter(n => {
          if (
            n.status.toLowerCase() !== "closed" &&
            n.status.toLowerCase() !== "completed" &&
            n.status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
          return n;
        })
        .map(n => n.tripNumber);
      // const displayedData = getData().map(n => n.status);
      const newSelected = tempSelected
        .filter(tripNumber => displayedData.indexOf(tripNumber) < 0)
        .map(tripNumber => tripNumber);
      setSelectedTrips(newSelected);
    };

    if (event.target.checked) {
      if (isChecked().indeterminate) {
        deselect();
        return;
      }
      // getData().map(n => tempSelected.push(n.tripNumber));
      getData()
        .filter(n => {
          if (
            n.status.toLowerCase() !== "closed" &&
            n.status.toLowerCase() !== "completed" &&
            n.status.toLowerCase() !== "cancelled"
          ) {
            return n.tripNumber;
          }
        })
        .map(n => tempSelected.push(n.tripNumber));
      setSelectedTrips(_.uniq(tempSelected));
      return;
    }
    deselect();

    // if (e.target.checked) {
    //   setSelectedTrips(
    //     bookings.filter(b => !b.data_completed).map(b => b.tripNumber)
    //   );
    // } else {
    //   setSelectedTrips([]);
    // }
  };

  const handleOpenUpload = () => {
    setUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setUploadDialog(false);
  };

  // useEffect(() => {
  //   if (permission_data) {
  //     setPermissions(permission_data);
  //   }
  // }, [permission_data]);

  useEffect(() => {
    if (Object.keys(user_context.allowed_permissions).length) {
      const { allowed_permissions } = user_context;
      setPermissions({
        change_truck: allowed_permissions["/prebooking/change_truck/"],
        dispatch: allowed_permissions["/prebooking/dispatch/"],
        manual_complete: allowed_permissions["/prebooking/manual_complete/"],
        split: allowed_permissions["/prebooking/split/"],
        close: allowed_permissions["/prebooking/close/"],
        upload: allowed_permissions["/prebooking/manual_upload/"]
      });
    }
  }, [user_context.allowed_permissions]);

  useEffect(() => {
    if (trip) {
      const { get_bookings_per_trip } = bookings_data;
      const rawTripData = get_bookings_per_trip.bookings.filter(
        b => b.key === trip
      );
      const active_row = bookings.filter(b => b.tripNumber === trip);
      setActiveRawData(rawTripData[0]);
      setActiveRow(active_row[0]);
    }
  }, [trip]);

  const applyFilter = filterValues => {
    const {
      dateRange,
      dropoff_location,
      pickup_location,
      status,
      hauler,
      vehicle_id,
      service_type,
      clear,
      dispatch_type,
      groups,
      is_inter_island
    } = filterValues;

    setKeyLog(
      `${
        dateRange.start
          ? `Date Range: ${moment(dateRange?.start).format("lll")} - ${moment(
              dateRange?.end
            ).format("lll")}, `
          : ``
      }Service Type: ${service_type}, Dispatch Type ${dispatch_type}, ${
        pickup_location?.label
          ? `Pickup Location: ${pickup_location?.label}, `
          : ``
      }${
        dropoff_location?.label
          ? `Delivery Location: ${dropoff_location?.label}, `
          : ``
      }${hauler?.label ? `Hauler: ${hauler?.label}, ` : ``}${
        vehicle_id?.label ? `Plate Number: ${vehicle_id?.label}, ` : ``
      }Status: ${status}`,
      `${groups ? `Groups: ${groups}, ` : ``} ${
        is_inter_island !== "All" ? `Trip Type: ${is_inter_island}` : ``
      }`
    );

    AddLogs(
      process.env.REACT_APP_PRE_DISPATCH_MODULE,
      "filter",
      `${
        dateRange.start
          ? `Date Range: ${moment(dateRange?.start).format("lll")} - ${moment(
              dateRange?.end
            ).format("lll")}, `
          : ``
      }Service Type: ${service_type}, Dispatch Type ${dispatch_type}, ${
        pickup_location?.label
          ? `Pickup Location: ${pickup_location?.label}, `
          : ``
      }${
        dropoff_location?.label
          ? `Delivery Location: ${dropoff_location?.label}, `
          : ``
      }${hauler?.label ? `Hauler: ${hauler?.label}, ` : ``}${
        vehicle_id?.label ? `Plate Number: ${vehicle_id?.label}, ` : ``
      }Status: ${status}`,
      `${groups ? `Groups: ${groups}, ` : ``} ${
        is_inter_island !== "All" ? `Trip Type: ${is_inter_island}` : ``
      }`
    );

    // setDownloadBTN(true);
    let vars = {
      skip: 0 * rowsPerPage,
      rows_per_page: 0 * rowsPerPage + rowsPerPage,
      // date_range: {
      //    field: dateRange.field
      // },
      conditions: [
        // {
        //   field: "client_id",
        //   value: userDetails.client.id.toString()
        // }
        // {
        //   field: "data_complete",
        //   value: ["false"]
        // }
        {
          field: "dispatch_type",
          value: ["Not Yet"]
        }
      ],
      or: [],
      // not: [
      //   // { field: "status_code_id.keyword", value: "24" },
      //   // { field: "status_code_id.keyword", value: "4" },
      //   // { field: "status_code_id.keyword", value: "20" },
      //   { field: "dispatch_type", value: "Automatic" },
      //   { field: "dispatch_type.keyword", value: "Manual" }
      // ],
      orderBy: {},
      // or: []
      keyword: contextData.searchKeyword.value
    };

    if (is_inter_island !== "All") {
      vars = {
        ...vars,
        is_inter_island: is_inter_island === "Interisland"
      };
    }
    if (pickup_location !== null) {
      vars = { ...vars, pickups: [pickup_location.value] };
    }
    if (dropoff_location !== null) {
      vars = { ...vars, dropoffs: [dropoff_location.value] };
    }
    if (hauler !== null) {
      vars.conditions.push({
        // field: "client_name.keyword",
        field: "group_names.keyword",
        value: [hauler]
      });
    }
    if (service_type !== "All") {
      vars.conditions.push({
        field: "service_type.keyword",
        value: [service_type]
      });
    }
    if (dispatch_type !== "All") {
      if (
        dispatch_type.toLowerCase() === "not yet dispatched" ||
        dispatch_type.toLowerCase() === "not yet"
      ) {
        // vars.not.push(
        //   {field: "dispatch_type", value: "Automatic"},
        //   {field: "dispatch_type", value: "Manual"},
        //   {field: "dispatch_type", value: ""}
        // );
        vars.conditions.push({
          field: "dispatch_type.keyword",
          value: "Not Yet"
        });
      } else {
        vars.conditions.push({
          field: "dispatch_type.keyword",
          value: [dispatch_type]
        });
      }
    }
    if (status !== "All") {
      if (status === "Processing") {
        vars.or.push({
          field: "status_code_id",
          value: [
            process.env.REACT_APP_ACCEPTED_STATUS,
            process.env.REACT_APP_SCHEDULED_STATUS
          ]
        });
      } else if (status === "Completed" || status === "Closed") {
        // vars.conditions.push({ field: "status_code", value: status });
        // vars.conditions = [];
        vars.or.push({
          field: "status_code_id",
          value:
            status === "Completed"
              ? process.env.REACT_APP_STATUS_TRIP_COMPLETED
              : process.env.REACT_APP_STATUS_TRIP_CLOSED
        });
        // vars = {
        //   ...vars,
        //   date_range: {
        //     ...vars.date_range,
        //     start: "1970-01-01 00:00:00",
        //     end: "2100-01-01 23:59:59"
        //   }
        // };
      } else {
        vars.conditions.push({ field: "status_code.keyword", value: status });
        vars.conditions.push({ field: "data_complete", value: "false" });
      }
    } else if (clear && !contextData.searchKeyword.value) {
      // vars.conditions = [];
      vars.or.push({
        // field: "status_code",
        // value: ["Accepted", "Scheduled", "Incoming", "Arrived at Pickup"]
        field: "status_code_id",
        // value: ["1", "2", "27", "15"]
        value: [
          process.env.REACT_APP_ACCEPTED_STATUS,
          process.env.REACT_APP_SCHEDULED_STATUS,
          process.env.REACT_APP_STATUS_TRIP_INCOMING,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP
        ]
      });
      // vars.conditions.push({ field: "data_complete", value: "false" });
    } else {
      // vars.conditions = []
      vars.or.push({
        field: "status_code_id",
        // value: ["1", "2", "27", "15", "29", "13"]
        value: [
          process.env.REACT_APP_ACCEPTED_STATUS,
          process.env.REACT_APP_SCHEDULED_STATUS,
          process.env.REACT_APP_STATUS_TRIP_INCOMING,
          process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
          process.env.REACT_APP_STATUS_TRIP_CLOSED,
          process.env.REACT_APP_STATUS_CANCEL_ID
        ]
      });
      // vars.conditions.push({
      //   field: "status_code",
      //   value: [
      //     "Accepted",
      //     "Scheduled",
      //     "Incoming",
      //     "Arrived at Pickup",
      // "Closed",
      // "Cancelled"
      //   ]
      // });
      // vars.conditions.push({ field: "data_complete", value: "false" });
    }
    if (vehicle_id !== null) {
      vars.conditions.push({ field: "vehicle_id", value: [vehicle_id.value] });
    }

    if (dateRange.start !== null && dateRange.end !== null) {
      vars = { ...vars, date_range: { ...dateRange } };
    } else if (!contextData.searchKeyword.value) {
      vars = { ...vars, date_range: initialFilter.date_range };
    } else {
      vars = {
        ...vars,
        date_range: {
          field: "pickups.arrival",
          start: moment()
            .subtract(6, "months")
            .format("YYYY-MM-DD 00:00:00"),
          end: moment()
            .add(60, "days")
            .format("YYYY-MM-DD 23:59:59")
        }
      };
    }
    const filter =
      vehicle_id !== null ||
      status !== "All" ||
      service_type !== "All" ||
      hauler !== null ||
      dropoff_location !== null ||
      pickup_location !== null ||
      dispatch_type !== "All" ||
      (dateRange.start !== null && dateRange.end !== null) ||
      is_inter_island !== "All";
    setIsFiltered(filter);
    vars = {
      ...vars,
      orderBy: !filter
        ? [
            { field: "is_urgent", direction: "desc" },
            { field: "overdue", direction: "desc" },
            { field: orderBy, direction: order }
          ]
        : [{ field: orderBy, direction: order }]
    };
    localStorage.setItem("prebooking_filter", JSON.stringify(filterValues));
    setCurrentPage(0);
    setQueryFilter(vars);
  };

  useEffect(() => {
    if (location?.state?.params?.trip_numbers?.length) {
      setQueryFilter({
        trip_numbers: location.state.params.trip_numbers,
        orderBy: [{ field: orderBy, direction: order }]
      });
    } else if (localStorage.getItem("prebooking_filter")) {
      // const savedFilter = JSON.parse(localStorage.getItem("prebooking_filter"));
      setIsFiltered(true);
      setQueryFilter(savedFilter);
      applyFilter(savedFilter);
    }
  }, []);

  useEffect(() => {
    if (contextData.searchKeyword.submit === true && loaded) {
      contextData.setSearchKeyword(prev => ({
        ...prev,
        submit: false
      }));
      const newConditions = [
        // { field: "client_id", value: userDetails.client.id.toString() },
        {
          field: "status_code_id",
          // value: ["27", "15", "1", "29", "2", "13"]
          value: [
            process.env.REACT_APP_ACCEPTED_STATUS,
            process.env.REACT_APP_SCHEDULED_STATUS,
            process.env.REACT_APP_STATUS_TRIP_INCOMING,
            process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
            process.env.REACT_APP_STATUS_TRIP_CLOSED,
            process.env.REACT_APP_STATUS_CANCEL_ID
          ]
        }
      ];
      setCurrentPage(0);
      // setQueryFilter({
      //   ...initialFilter,
      //   keyword: contextData.searchKeyword.value,
      //   // conditions:
      //   //   contextData.searchKeyword.value !== ""
      //   //     ? newConditions
      //   //     : initialFilter.conditions,
      //   // conditions: [{ field: "data_complete", value: ["true"]  }],
      //   or:
      //     contextData.searchKeyword.value !== ""
      //       ? newConditions
      //       : initialFilter.conditions,
      //   // not: [
      //   //   { field: "status_code_id.keyword", value: "24" },
      //   //   { field: "status_code_id.keyword", value: "4" },
      //   //   { field: "status_code_id.keyword", value: "20" }
      //   // ],
      //   orderBy: !isFiltered
      //     ? [
      //         { field: "is_urgent", direction: "desc" },
      //         { field: "overdue", direction: "desc" },
      //         { field: orderBy, direction: order }
      //       ]
      //     : [{ field: orderBy, direction: order }]
      // });
      setQueryFilter(prev => {
        let tempDateRange = {};
        const saved = JSON.parse(localStorage.getItem("prebooking_filter"));

        if (contextData.searchKeyword.value) {
          if (_.isEqual(prev.date_range, initialFilter.date_range)) {
            // If no daterange is set on filter drawer, set dateRange -6months to +2months
            tempDateRange = {
              date_range: {
                field: "pickups.arrival",
                start: moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD 00:00:00"),
                end: moment()
                  .add(60, "days")
                  .format("YYYY-MM-DD 23:59:59")
              }
            };
          }
        } else if (!saved?.dateRange?.start || !saved?.dateRange?.end) {
          tempDateRange = { date_range: initialFilter.date_range };
        }
        return {
          ...prev,
          orderBy: !isFiltered
            ? [
                { field: "is_urgent", direction: "desc" },
                { field: "overdue", direction: "desc" },
                { field: orderBy, direction: order }
              ]
            : [{ field: orderBy, direction: order }],
          keyword: contextData.searchKeyword.value,
          ...((!saved || saved?.status === "All") && {
            or:
              contextData.searchKeyword.value !== ""
                ? newConditions
                : initialFilter.or
          }),
          ...tempDateRange
        };
      });
    }
    // if (
    //   !loaded &&
    //   contextData.searchKeyword.moduleName !== process.env.REACT_APP_PRE_DISPATCH_MODULE
    // ) {
    //   contextData.setSearchKeyword({
    //     ...contextData.searchKeyword,
    //     submit: false,
    //     value: ""
    //   });
    // }
    hasLoaded(true);
  }, [contextData.searchKeyword.submit]);

  useEffect(() => {
    if (
      contextData.searchKeyword.moduleName !==
      process.env.REACT_APP_PRE_DISPATCH_MODULE
    ) {
      contextData.setSearchKeyword({
        ...contextData.searchKeyword,
        value: "",
        moduleName: process.env.REACT_APP_PRE_DISPATCH_MODULE
      });
    }
  }, []);

  const [refreshMutation, { loading: mutation_loading }] = useMutation(
    REFRESH_TRIPS,
    {
      refetchQueries: () => {
        return [{ query: GET_TABLEDATA_BOOKINGS_V2, variables: queryFilter }];
      },
      onCompleted: () => {
        setActiveRow({});
        setSelectedTrips([]);
        setOpenActions(null);
        MySwal.fire({
          icon: "success",
          text: "Refresh Done",
          timer: 3000,
          showConfirmButton: false,
          onClose: () => {
            refetch();
          }
        }).then(result => {
          if (result.dismiss === MySwal.DismissReason.timer) {
            refetch();
          } else {
            refetch();
          }
        });
      }
    }
  );

  // Function prepared to fetch the selected trip
  const [get_booking_details] = useLazyQuery(GET_BOOKINGS_v2, {
    onCompleted: res => setTripReassign(res)
  });

  useEffect(() => {
    // Check if variable trip has a vallid value
    if (trip) {
      // run the function prepared to fetch the selected trip
      get_booking_details({
        variables: {
          trip_numbers: [trip]
        }
      });
    }
  }, [trip]);

  const getEta = (pickups, dropoffs, out_of_route) => {
    let eta = false;

    const active_pu = _.filter(
      pickups,
      pu => pu.actual_eta && pu.status_code_id === 2
    );
    const active_do = _.filter(
      dropoffs,
      d => d.actual_eta && d.status_code_id === 2
    );

    if (active_do.length > 0) {
      const modifiedTime = moment(active_do[0].eta_modified).format("lll");
      const expected_time = `${moment(active_do[0].eta_modified)
        .add(active_do[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_do[0].arrival);
      eta =
        active_do[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    } else if (active_pu.length > 0) {
      const modifiedTime = moment(active_pu[0].eta_modified).format("lll");
      const expected_time = `${moment(active_pu[0].eta_modified)
        .add(active_pu[0].actual_eta, "seconds")
        .format("lll")}`;
      const isLate = moment(expected_time).isAfter(active_pu[0].arrival);
      eta =
        active_pu[0].actual_eta <= 0
          ? false
          : {
              ...eta,
              eta: expected_time,
              as_of: modifiedTime,
              isLate: isLate
            };
    }
    return moment().isBefore(eta.eta) && !out_of_route ? eta : false;
  };

  const dataParser = data => {
    const { bookings: trips, total } = data.get_bookings_per_trip;
    const sample = [];
    _.forEach(trips, _trip => {
      const pickups = [];
      const dropoffs = [];
      const raw_dropoffs = [];
      const raw_pickups = [];
      let parsedTrip = {};

      _.forEach(_trip.value, so => {
        _.forEach(so.pickups, pickup => {
          const exist = pickup.geofence_id
            ? _.findIndex(pickups, {
                geofence_id: pickup.geofence_id,
                arrival: pickup.arrival
              })
            : _.findIndex(pickups, {
                name: pickup.name,
                arrival: pickup.arrival
              });
          raw_pickups.push(pickup);
          if (exist === -1) {
            pickups.push({ ...pickup, is_pickup: true });
          }
        });
        _.forEach(so.dropoffs, (d, i) => {
          const exist = d.geofence_id
            ? _.findIndex(dropoffs, {
                geofence_id: d.geofence_id,
                arrival: d.arrival
              })
            : _.findIndex(dropoffs, {
                name: d.name,
                arrival: d.arrival
              });

          const items = so.items
            ? so.items.filter(item => item.dropoff_index === i)
            : [];
          raw_dropoffs.push({ ...d, items: items, trip_id: so.id });
          if (exist === -1) {
            dropoffs.push({ ...d, trip_id: so.id, is_pickup: false });
          }
        });

        if (so.dispatch_time) {
          // if (!so.data_complete && !so.pre_dispatched_ready) {
          parsedTrip = {
            ...parsedTrip,
            vehicle_status: so.vehicle_status,
            vehicle_plate_no: so.vehicle_plate_no,
            service_type: so.service_type,
            vehicle_id: so.vehicle_id,
            vehicle_history: so.vehicle_history,
            status: [
              +process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
              +process.env.REACT_APP_STATUS_SCHEDULED
            ].includes(+so.status_code_id)
              ? // +so.status_code_id === +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
                // +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED
                "Processing"
              : so.status_code,
            tripNumber: so.trip_number,
            is_urgent: so.is_urgent,
            raw_pickups: raw_pickups,
            raw_dropoffs: raw_dropoffs,
            hauler: so.client_name,
            total: _trip.value.length,
            has_split:
              +so.status_code_id ===
                +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
              +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED ||
              +so.status_code_id ===
                +process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
            has_no_dispatch:
              +so.status_code_id !==
                +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
              +so.status_code_id !== +process.env.REACT_APP_STATUS_SCHEDULED ||
              +so.status_code_id !==
                +process.env.REACT_APP_STATUS_TRIP_INCOMING,
            raw_trip_status: so.status_code,
            out_of_route: so.out_of_route,
            data_completed: so.data_complete,
            is_overdue: so.overdue,
            pre_dispatched_ready: so.pre_dispatched_ready,
            dispatch_type: so.dispatch_type,
            trip_status: [
              +process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
              +process.env.REACT_APP_STATUS_SCHEDULED
            ].includes(+so.status_code_id)
              ? // +so.status_code_id === +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
                // +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED
                "Processing"
              : so.status_code,
            is_split: so.is_split,
            status_code_id: so.status_code_id,
            is_inter_island: so.is_inter_island
          };
          // }
        } else {
          parsedTrip = {
            ...parsedTrip,
            vehicle_status: so.vehicle_status,
            vehicle_plate_no: so.vehicle_plate_no,
            service_type: so.service_type,
            vehicle_id: so.vehicle_id,
            vehicle_history: so.vehicle_history,
            status: [
              +process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
              +process.env.REACT_APP_STATUS_SCHEDULED
            ].includes(+so.status_code_id)
              ? // +so.status_code_id === +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
                // +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED
                "Processing"
              : so.status_code,
            tripNumber: so.trip_number,
            is_urgent: so.is_urgent,
            raw_pickups: raw_pickups,
            raw_dropoffs: raw_dropoffs,
            hauler: so.client_name,
            total: _trip.value.length,
            has_split:
              +so.status_code_id ===
                +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
              +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED ||
              +so.status_code_id ===
                +process.env.REACT_APP_STATUS_ARRIVED_AT_PICKUP,
            has_no_dispatch:
              +so.status_code_id !==
                +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
              +so.status_code_id !== +process.env.REACT_APP_STATUS_SCHEDULED ||
              +so.status_code_id !==
                +process.env.REACT_APP_STATUS_TRIP_INCOMING,
            raw_trip_status: so.status_code,
            out_of_route: so.out_of_route,
            data_completed: so.data_complete,
            is_overdue: so.overdue,
            pre_dispatched_ready: so.pre_dispatched_ready,
            dispatch_type: so.dispatch_type,
            trip_status: [
              +process.env.REACT_APP_STATUS_TRIP_ACCEPTED,
              +process.env.REACT_APP_STATUS_SCHEDULED
            ].includes(+so.status_code_id)
              ? // +so.status_code_id === +process.env.REACT_APP_STATUS_TRIP_ACCEPTED ||
                // +so.status_code_id === +process.env.REACT_APP_STATUS_SCHEDULED
                "Processing"
              : so.status_code,
            is_split: so.is_split,
            status_code_id: so.status_code_id,
            is_inter_island: so.is_inter_island
          };
        }
      });
      if (Object.keys(parsedTrip).length) {
        parsedTrip = {
          ...parsedTrip,

          dropoffs: dropoffs,
          pickups: pickups,
          first_pickup: pickups[0].name,
          first_pickup_arrival: pickups[0].arrival,
          first_dropoff: dropoffs[0].name,
          first_dropoff_arrival: dropoffs[0].arrival,
          eta: getEta(
            parsedTrip.raw_pickups,
            parsedTrip.raw_dropoffs,
            parsedTrip.out_of_route
          ),
          has_empty_so: _.filter(_trip.value, t => !t.so_number).length > 0
        };

        sample.push(parsedTrip);
      }
    });
    // const toDisplayBookings = _.filter(
    //   sample,
    //   s => !s.data_completed || s.status === "Processing"
    //   // s => s.status === "Processing" || s.status === "Incoming"
    // );
    return { toDisplayBookings: sample, total, sample };
  };

  useEffect(() => {
    // check if the variable tripReassign is empty
    if (!_.isEmpty(tripReassign)) {
      // re-organize the data using dataParser function
      const { sample } = dataParser(tripReassign);
      const { get_bookings_per_trip } = tripReassign;
      const rawTripData = get_bookings_per_trip.bookings.filter(
        b => b.key === trip
      );
      const active_row = sample.filter(b => b.tripNumber === trip);

      setActiveRow(active_row[0]);
      setActiveRawData(rawTripData[0]);
    }
  }, [tripReassign]);

  const subscribeToBookingsUpdate = React.useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: BOOKINGS_SUBSCRIPTION,
      // variables: {
      //   conditions: [
      //     // {
      //     //   field: "client_id",
      //     //   value: userDetails.client.id.toString()
      //     // }
      //   ]
      // },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        if (!prev) return false;
        if (!prev?.get_bookings_per_trip) return false;

        const pre_booking_status = [
          "Incoming",
          "Arrived at Pickup",
          "Accepted",
          "Closed"
        ];
        const {
          bookings: newData,
          count: newCount,
          total: newTotal
        } = subscriptionData.data.booking_per_trip_updated;
        const {
          bookings: prevBookingsFromResponse,
          count,
          total
        } = prev.get_bookings_per_trip;

        if (!newData.length) return prev;
        const prevBookings = _.cloneDeep([...prevBookingsFromResponse]);
        const parsedNewData = newData[0];
        const newDataValue = parsedNewData.value[0];
        const newDataStatus = parsedNewData.value[0].status_code;
        const newDataTripNumber = parsedNewData.key;
        const oldTripNumberIndex = _.findIndex(prevBookings, function a(o) {
          return o.key === newDataTripNumber;
        });

        let data = {};
        if (oldTripNumberIndex !== -1) {
          if (
            _.findIndex(pre_booking_status, st => st === newDataStatus) !== -1
          ) {
            const oldSOIndex = _.findIndex(
              prevBookings[oldTripNumberIndex].value,
              function a(o) {
                return o.id === newDataValue.id;
              }
            );
            if (oldSOIndex !== -1) {
              prevBookings[oldTripNumberIndex].value.splice(
                oldSOIndex,
                1,
                newDataValue
              );
            }
            data = {
              bookings: prevBookings,
              count: count + newCount,
              total: total + newTotal
            };
          } else {
            const newBookings = _.filter(
              prevBookings,
              pb => pb.key !== newDataTripNumber
            );

            data = {
              bookings: newBookings,
              count: count + newCount,
              total: total + newTotal
            };
          }

          return {
            get_bookings_per_trip: data
          };
        }

        return { ...prev, bookings: prevBookings };
      }
    });
    return unsubscribe;
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToBookingsUpdate();
    return () => unsubscribe();
  }, [subscribeToBookingsUpdate]);

  const checkDispatchStatus = type => {
    let tempType = "";
    if (type) {
      if (type.toLowerCase() === "automatic") {
        tempType = "Automatic Dispatched";
      } else if (type.toLowerCase() === "manual") {
        tempType = "Manually Dispatched";
      } else if (type.toLowerCase() === "not yet") {
        tempType = "Not Yet Dispatched";
      } else {
        tempType = "Undefined";
      }
    } else {
      tempType = "Undefined";
    }
    return tempType;
  };

  const handleClose = () => {
    setOpenActions(null);
    setActiveRow({});
  };

  // function to update trip type and set trip to interisland or untag trip interisland;
  async function interIslandHandler() {
    const interIslandIds = [];

    if (activeRawData && activeRawData.value) {
      activeRawData.value.forEach(data => interIslandIds.push(data.id));
      await updateInterisland({
        variables: {
          fields: [
            {
              field: "is_inter_island",
              value: activeRow.is_inter_island ? "false" : "true"
            }
          ],
          conditions: [{ field: "id", value: interIslandIds }]
        }
      });
      handleClose();
    }
  }

  const [getDownloadData] = useLazyQuery(GET_BOOKINGS_DOWNLOADABLE_TABLE_DATA, {
    onCompleted: d => {
      // setDownloadBTN(false);
      const { toDisplayBookings } = dataParser(d);
      const downloadTrips = toDisplayBookings.map(trips => {
        return {
          fo_number: trips.tripNumber,
          service_type: trips.service_type || "N/A",
          pickup_location: trips.pickups
            .map(pickup =>
              pickup.geofence_code
                ? `${pickup.geofence_code}-${pickup.name}`
                : pickup.name
            )
            .join("\n"),
          pickup_date: trips.pickups
            .map(pickup => moment(pickup.arrival).format("lll"))
            .join("\n"),
          dropoff_location: trips.dropoffs
            .map(dropoff =>
              dropoff.geofence_code
                ? `${dropoff.geofence_code}-${dropoff.name}`
                : dropoff.name
            )
            .join("\n"),
          dropoff_date: trips.dropoffs
            .map(dropoff => moment(dropoff.arrival).format("lll"))
            .join("\n"),
          hauler: trips.hauler,
          plate_no: trips.vehicle_plate_no,
          status: trips.status,
          dispatch_type: checkDispatchStatus(trips.dispatch_type),
          trip_type: trips.is_inter_island ? "Interisland trip" : "Standard trip"
        };
      });
      setDownloadData({ ...downloadData, body: downloadTrips });
    },
    onError: () => {
      MySwal.fire({
        icon: "error",
        title: "Too many data to prepare!",
        text: "Please use filter before downloading",
        timer: 3000
      });
      setDownloadBTN(false);
    }
  });

  // useEffect(() => {
  //   // const abortController = new AbortController();
  //   // const { signal } = abortController;
  //   if (bookings_data?.get_bookings_per_trip?.total) {
  //     // setTimeout(() => {
  //     getDownloadData({
  //       variables: {
  //         ...queryFilter,
  //         rows_per_page: bookings_data?.get_bookings_per_trip?.total
  //       }
  //       // options: {
  //       //   context: { fetchOptions: { signal: abortController.signal }},
  //       // }
  //       // context: { fetchOptions: { signal } }
  //     });
  //     // }, 20000);
  //   } else {
  //     setDownloadBTN(false);
  //     setDownloadData({ ...downloadData, body: [] });
  //   }
  //   // return () => abortController.abort();
  // }, [bookings_data]);

  // useQuery(GET_BOOKINGS_v2, {
  //   variables: {
  //     ...queryFilter,
  //     skip: currentPage * rowsPerPage,
  //     rows_per_page: currentPage * rowsPerPage + rowsPerPage
  //   },
  //   onCompleted: d => {
  //     setDownloadBTN(false);
  //     const { toDisplayBookings } = dataParser(d);
  //     const downloadTrips = toDisplayBookings.map(trips => {
  //       return {
  //         fo_number: trips.tripNumber,
  //         service_type: trips.service_type || "N/A",
  //         pickup_location: trips.pickups
  //           .map(pickup =>
  //             pickup.geofence_code
  //               ? `${pickup.geofence_code}-${pickup.name}`
  //               : pickup.name
  //           )
  //           .join("\n"),
  //         pickup_date: trips.pickups
  //           .map(pickup => moment(pickup.arrival).format("lll"))
  //           .join("\n"),
  //         dropoff_location: trips.dropoffs
  //           .map(dropoff =>
  //             dropoff.geofence_code
  //               ? `${dropoff.geofence_code}-${dropoff.name}`
  //               : dropoff.name
  //           )
  //           .join("\n"),
  //         dropoff_date: trips.dropoffs
  //           .map(dropoff => moment(dropoff.arrival).format("lll"))
  //           .join("\n"),
  //         hauler: trips.hauler,
  //         plate_no: trips.vehicle_plate_no,
  //         status: trips.status,
  //         dispatch_type: checkDispatchStatus(trips.dispatch_type)
  //       };
  //     });
  //     setDownloadData({ ...downloadData, body: downloadTrips });
  //   },
  //   fetchPolicy: "network-only"
  // });

  useEffect(() => {
    fetchMoreData(currentPage);
  }, [currentPage]);

  // const handleChangePage = (event, newPage) => {
  //   setCurrentPage(newPage);
  //   // fetchMoreData(newPage)
  //   // console.log(newPage);
  //   // console.log(event);
  //   // // setCurrentPage(newPage);
  //   // if (currentPage > newPage) {
  //   //   setCurrentPage(currentPage - 1);
  //   // } else {
  //   //   // check if page is less than the inputted value
  //   //   // eslint-disable-next-line no-lonely-if
  //   //   if (currentPage < newPage) {
  //   //     // check if the input value is greater than the total page
  //   //     if (newPage > Math.ceil(totalPage / rowsPerPage)) {
  //   //       // if true
  //   //       setCurrentPage(0);
  //   //     } else {
  //   //       // if false
  //   //       // loop the input value to request the data without skipping other page
  //   //       for (let i = 0; i <= newPage; i += 1) {
  //   //         // check if the value is already fetch to the server
  //   //         if (
  //   //           i * rowsPerPage >=
  //   //           bookings.length // &&
  //   //           // (i - 1) * rowsPerPage >= bookings.length
  //   //         ) {
  //   //           fetchMoreData(i);
  //   //         }
  //   //       }
  //   //       // set page based on user's input
  //   //       setCurrentPage(newPage);
  //   //     }
  //   //   } else {
  //   //     // check if the input value is zero
  //   //     // eslint-disable-next-line no-lonely-if
  //   //     if (Number(newPage) === 0) {
  //   //       // set page to default
  //   //       setCurrentPage(0);
  //   //     } else {
  //   //       // set page based on user's input
  //   //       setCurrentPage(newPage);
  //   //     }
  //   //   }
  //   // }
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setCurrentPage(0);
  // };

  useMemo(() => {
    if (bookings_data && !_.isEmpty(bookings_data)) {
      const { toDisplayBookings, total } = dataParser(bookings_data);
      setBookings(toDisplayBookings);
      // const displayLength = toDisplayBookings.length;
      // setTotalPage(displayLength > 0 ? Math.ceil(total / rowsPerPage) : 1);
      setTotalPage(total);
    }
  }, [bookings_data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQueryFilter({
      ...queryFilter,
      orderBy: !isFiltered
        ? [
            { field: "is_urgent", direction: "desc" },
            { field: "overdue", direction: "desc" },
            { field: orderBy, direction: isAsc ? "desc" : "asc" }
          ]
        : [{ field: orderBy, direction: isAsc ? "desc" : "asc" }]
    });
  };

  const toggleDrawer = () => {
    setOpenFilter(!openFilter);
  };

  const refreshTrips = multiple => {
    if (multiple) {
      refreshMutation({ variables: { trip_numbers: selectedTrips } });
      AddLogs(
        process.env.REACT_APP_PRE_DISPATCH_MODULE,
        "refresh_trip",
        selectedTrips
      );
    } else {
      refreshMutation({ variables: { trip_numbers: [activeRow.tripNumber] } });
      AddLogs(
        process.env.REACT_APP_PRE_DISPATCH_MODULE,
        "refresh_trip",
        activeRow.tripNumber
      );
    }
  };

  // Function used to display vehicle plate no, gps status and provider, and battery status
  const renderVehicleCell = useCallback(
    booking => {
      let cell = "";
      if (booking.vehicle_id) {
        let data = {
          vehicleType: "",
          deviceTypeId: 0,
          batteryLevel: 0,
          reportstampBatt: null,
          vehicle_status: null
        };
        const checkBooking = dvar.filter(
          elem => elem.id === booking.vehicle_id
        );

        // checkBooking &&
        checkBooking.map(drow => {
          data = {
            vehicleType: drow.vehicle_info?.vehicle_type,
            deviceTypeId: drow.device_type_id,
            batteryLevel: drow.device_status?.battery_level,
            reportstampBatt: drow.device_status?.reportstamp,
            vehicle_status: drow.device_status?.status_code
          };
          return data;
        });
        cell = (
          <div style={{ display: "flex" }}>
            <Battery
              vehicleType={data.vehicleType}
              deviceTypeId={data.deviceTypeId}
              batteryLevel={data.batteryLevel}
              tooltipTitle={
                <Fragment>
                  <p style={{ margin: 0, padding: 0 }}>
                    Battery Life: {data.batteryLevel || "-"} %
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    As of{" "}
                    {data.reportstampBatt
                      ? moment(data.reportstampBatt).format("lll")
                      : "-"}
                  </p>
                </Fragment>
              }
            />
            <Tooltip
              title={
                <Fragment>
                  <p style={{ margin: 0, padding: 0 }}>
                    {gpsStatus(data.vehicle_status?.toLowerCase()).label}
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    Last Report:{" "}
                    {// Check if the vehicle has a device
                    (dvar.some(elem => elem.id === booking.vehicle_id) &&
                      dvar.map(drow => {
                        let reportstamp = "";
                        // Check if the device id is matched in the vehicle id
                        if (drow.id === booking.vehicle_id) {
                          reportstamp =
                            (drow.device_status?.reportstamp &&
                              moment(drow.device_status.reportstamp).format(
                                "lll"
                              )) ||
                            "-";
                          // data = {
                          //   vehicleType: drow.vehicle_info?.vehicle_type,
                          //   deviceTypeId: drow.device_type_id,
                          //   batteryLevel: drow.device_status?.battery_level,
                          //   reportstampBatt: drow.device_status?.reportstamp
                          // };
                        }
                        return reportstamp;
                      })) ||
                      "-"}
                  </p>
                  <p style={{ margin: 0, padding: 0 }}>
                    GPS Provider:{" "}
                    {(dvar.some(elem => elem.id === booking.vehicle_id) &&
                      dvar.map(drow => {
                        let provider = "";
                        if (drow.id === booking.vehicle_id) {
                          provider = drow.device_config?.gps_provider || "-";
                        }
                        return provider;
                      })) ||
                      "-"}
                  </p>
                </Fragment>
              }
            >
              <Typography
                style={{
                  display: "flex",
                  gap: 5,
                  fontSize: "0.875rem"
                }}
                component="span"
                noWrap
              >
                <TripOrigin
                  style={{
                    fontSize: "12px",
                    marginTop: 2,
                    color: gpsStatus(data.vehicle_status?.toLowerCase()).color
                  }}
                />
                <Highlight
                  search={contextData.searchKeyword.value}
                  className={classes.muiTableCellData}
                >
                  {booking.vehicle_plate_no || "-"}
                </Highlight>
              </Typography>
            </Tooltip>
          </div>
        );
      } else {
        const title = booking.vehicle_plate_no
          ? "Please set up this vehicle. Vehicle setup is required to start monitoring this trip"
          : "Please assign a vehicle";
        cell = (
          <Tooltip title={title}>
            <Typography
              style={{
                display: "flex",
                gap: 5,
                fontSize: "13px",
                color: "#3F3F3F"
              }}
              component="span"
              noWrap
            >
              <ErrorOutline
                style={{
                  fontSize: "12px",
                  color: "red",
                  marginTop: 3
                }}
              />
              <Highlight search={contextData.searchKeyword.value}>
                {booking.vehicle_plate_no || "-"}
              </Highlight>
            </Typography>
          </Tooltip>
        );
      }
      return cell;
    },
    [contextData.searchKeyword, dvar]
  );

  const getDestinationStyle = stop => {
    let color = "";
    const { status_code, actual_arrival, manual_arrival, arrival } = stop;
    let time = "Vehicle not arrived yet";
    const isLate = arrival ? moment(arrival).isBefore(actual_arrival) : false;
    let textColor = "#3f3f3f";
    switch (status_code) {
      case "Manually Completed":
        color = "#219653";
        time = `Manual arrival time ${moment(manual_arrival).format("lll")}`;
        break;
      case "Completed":
        textColor = isLate ? "#EA4335" : "#3f3f3f";
        time = (
          <Fragment>
            <p style={{ margin: 0, padding: 0 }}>
              Actual Arrival Time{" "}
              <span
                style={{
                  color: isLate ? "#EA4335" : "#6DE243"
                }}
              >
                {isLate ? "(Late)" : "(On Time)"}
              </span>
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              {moment(actual_arrival).format("lll")}
            </p>
          </Fragment>
        );
        color = "#2dd112";
        break;
      case `Arrived at ${stop.is_pickup ? "Pickup" : "Drop off"}`:
        textColor = isLate ? "#EA4335" : "#3F3F3";
        time = (
          <Fragment>
            <p style={{ margin: 0, padding: 0 }}>
              Actual Arrival Time{" "}
              <span
                style={{
                  color: isLate ? "#EA4335" : "#6DE243"
                }}
              >
                {isLate ? "(Late)" : "(On Time)"}
              </span>
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              {moment(actual_arrival).format("lll")}
            </p>
          </Fragment>
        );
        color = "#ffc207";
        break;
      default:
        color = "#c6c6c6";
        break;
    }
    return { color, textColor, isLate, time };
  };

  const renderDestination = (stop, index) => {
    let display = "";
    if (stop.geofence_id) {
      const { textColor, time, color } = getDestinationStyle(stop);
      display = (
        <Tooltip
          title={time}
          aria-label={stop.status_code}
          key={`${stop.name}-${index}`}
        >
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "flex",
              gap: 5,
              color: textColor
            }}
          >
            <Brightness1
              style={{
                fontSize: "12px",
                color: color,
                marginTop: 3
              }}
            />
            <Highlight search={contextData.searchKeyword.value}>
              {stop.geofence_code
                ? `${stop.geofence_code}-${stop.name}`
                : stop.name}
            </Highlight>
          </span>
        </Tooltip>
      );
    } else {
      const title =
        "Please set up the geofence. Geofence setup is required to start monitoring this trip";
      display = (
        <Tooltip title={title} key={`${stop.name}-${index}`}>
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "flex",
              gap: 5
            }}
          >
            <ErrorOutline
              style={{
                fontSize: "12px",
                color: "red",
                marginTop: 3
              }}
            />

            <Highlight search={contextData.searchKeyword.value}>
              {stop.geofence_code || stop.name || "-"}
            </Highlight>
          </span>
        </Tooltip>
      );
    }
    return display;
  };

  const renderDestinationArrival = (stop, index) => {
    const { arrival } = stop;

    let display = "";
    const title =
      "Please complete the trip details. This field is required to start monitoring the trip.";
    if (arrival && arrival !== "1111-11-11 11:11:00") {
      display = (
        <span
          style={{
            display: "flex",
            gap: 5
          }}
          key={`${stop.geofence_id}-${stop.arrival}`}
        >
          <Highlight search={contextData.searchKeyword.value}>
            {moment(stop.arrival).format("lll")}
          </Highlight>
        </span>
      );
    } else {
      display = (
        <Tooltip title={title} key={`${stop.name}-${index}`}>
          <span
            key={`${stop.name}-${index}`}
            style={{
              display: "flex",
              gap: 5
            }}
          >
            <ErrorOutline
              style={{
                fontSize: "12px",
                color: "red",
                marginTop: 3
              }}
            />
            -
          </span>
        </Tooltip>
      );
    }
    return display;
  };

  const [listOverlapTrip, setListOverLapTrip] = useState([]);
  const [selectedDispatchTrip, setSelectedDispatchTrip] = useState({
    // over_lap: listOverlapTrip,
    data_incomplete: [],
    dispatchable: []
  });
  const [listTrip, setListTrip] = useState([]);
  const [get_selectedBookings] = useLazyQuery(GET_CLOSED_BOOKINGS_v2, {
    onCompleted: data => {
      if (data) {
        setCloseData(data.get_bookings_per_trip.bookings);
        // setDispatchData(data.get_bookings_per_trip.bookings);
      }
    }
  });

  const [get_dispatchBookings] = useLazyQuery(GET_DISPATCH_BOOKINGS_V2, {
    onCompleted: data => {
      if (data) {
        // setCloseData(data.get_bookings_per_trip.bookings);
        setDispatchData(data.get_bookings_per_trip.bookings);
      }
    }
  });

  const [
    get_selectedOngoingBookings,
    { loading: ongoingLoading }
  ] = useLazyQuery(GET_BOOKINGS, {
    onCompleted: data => {
      setLoadOngoing(true);
      if (data) {
        const overLap = [];
        if (listTrip) {
          Array.from(listTrip[0], key1 => {
            let counter = 0;
            Array.from(
              data.get_bookings_with_ongoing_per_trip.bookings,
              key => {
                if (
                  key.value[0].vehicle_plate_no ===
                  key1.value[0].vehicle_plate_no
                ) {
                  if (counter === 0) {
                    overLap.push(key1);
                    counter = 1;
                  }
                }
                return key;
              }
            );
            return key1;
          });
        }
        setListOverLapTrip(overLap);
      }
    },
    fetchPolicy: "network-only"
  });

  const [get_selectedDispatchBookings] = useLazyQuery(GET_BOOKINGS_v2, {
    onCompleted: data => {
      if (data) {
        const dispatchable = [];
        const nonDispatchable = [];
        const forDispatchData = [];
        const list = [];
        Array.from(data.get_bookings_per_trip.bookings, key => {
          if (key.value[0].pre_dispatched_ready === false) {
            nonDispatchable.push(data.get_bookings_per_trip.bookings);
          } else {
            forDispatchData.push(key.key);
            dispatchable.push(data.get_bookings_per_trip.bookings);
          }
          list.push(data.get_bookings_per_trip.bookings);
          return key;
        });
        setListTrip(list);
        setSelectedDispatchTrip({
          // over_lap: [],
          data_incomplete: nonDispatchable,
          dispatchable: dispatchable
        });
        // if (forDispatchData.length > 0) {
        //   get_selectedBookings({
        //     variables: {
        //       trip_numbers: forDispatchData
        //     }
        //   });
        // }
        const vehiclePlateNo = [];
        Array.from(data.get_bookings_per_trip.bookings, key => {
          if (key.value[0].vehicle_plate_no) {
            vehiclePlateNo.push({
              field: "vehicle_plate_no",
              value: key.value[0].vehicle_plate_no
            });
          }
          return key;
        });

        if (vehiclePlateNo.length > 0) {
          get_selectedOngoingBookings({
            variables: {
              filter: vehiclePlateNo,
              not: [
                { field: "status_code", value: "Accepted" },
                { field: "status_code", value: "Scheduled" },
                { field: "status_code", value: "Completed" },
                { field: "status_code", value: "Closed" },
                { field: "status_code", value: "Cancelled" },
                { field: "status_code", value: "Manually Completed" }
              ]
            }
          });
          // setLoadOngoing(true);
        } else {
          setLoadOngoing(true);
        }
      }
    },
    fetchPolicy: "network-only"
  });

  function multiDimensionalUnique(arr) {
    const uniques = [];
    const itemsFound = {};
    for (let i = 0, l = arr.length; i < l; i++) {
      const stringified = JSON.stringify(arr[i]);
      if (itemsFound[stringified]) {
        continue; // eslint-disable-line no-continue
      }
      uniques.push(arr[i]);
      itemsFound[stringified] = true;
    }
    return uniques;
  }
  const [selectedCount, setSelectedCount] = useState({
    nonDispatchable: 0,
    dispatchable: 0,
    overlap: 0
  });

  const dispatchResultContent = () => {
    setDispatchTitle("");
    // setLoadOngoing(false);
    const dispatchable = [];
    const nonDispatchable = [];
    const overLap = [];
    const d_trip = [];
    const e_trip = [];
    const o_trip = [];
    let d = 0;
    let e = 0;
    let o = 0;
    if (
      listOverlapTrip.length > 0 &&
      selectedDispatchTrip.data_incomplete.length > 0
    ) {
      nonDispatchable.splice(0, nonDispatchable.length);
      Array.from(selectedDispatchTrip.data_incomplete[0], key => {
        const tempHolder = [];
        if (listOverlapTrip.length > 0) {
          Array.from(listOverlapTrip, key1 => {
            if (key.key !== key1.key) {
              tempHolder.push(key);
            }
            return key1;
          });
        }
        nonDispatchable.push(key);
        return key;
      });
    } else if (selectedDispatchTrip.data_incomplete.length > 0) {
      nonDispatchable.splice(0, nonDispatchable.length);
      Array.from(selectedDispatchTrip.data_incomplete[0], key => {
        if (key.value[0].data_complete === false) {
          nonDispatchable.push(key);
        }
        return key;
      });
    }

    if (listOverlapTrip.length > 0) {
      overLap.splice(0, overLap.length);
      Array.from(listOverlapTrip, key => {
        overLap.push(key);
        return key;
      });
    }

    if (selectedDispatchTrip.dispatchable.length > 0) {
      dispatchable.splice(0, dispatchable.length);
      Array.from(selectedDispatchTrip.dispatchable, key => {
        const tempHolder = [];
        Array.from(key, key1 => {
          if (key1.value[0].data_complete === true) {
            tempHolder.push(key1);
          }
          return key1;
        });
        dispatchable.push(tempHolder);
        return key;
      });
    }

    if (dispatchable.length > 0) {
      const setOverlap = [];
      Array.from(multiDimensionalUnique(dispatchable), element => {
        Array.from(element, sub => {
          if (sub.value[0].vehicle_plate_no) {
            for (let i = 0; i < dispatchable.length; i++) {
              if (element[i].value[0].vehicle_plate_no) {
                if (sub.key !== element[i].key) {
                  if (
                    sub.value[0].vehicle_plate_no ===
                    element[i].value[0].vehicle_plate_no
                  ) {
                    setOverlap.push(element[i]);
                  }
                }
              }
            }
          }
          return sub;
        });
        return element;
      });
      Array.from(multiDimensionalUnique(setOverlap), elem => {
        overLap.push(elem);
        return elem;
      });

      for (let i = 0; i < overLap.length; i++) {
        for (let j = 0; j < dispatchable[0].length; j++) {
          if (overLap[i].key === dispatchable[0][j].key) {
            dispatchable[0].splice(j, 1);
          }
        }
      }
    }

    if (loadOngoing) {
      if (dispatchable[0] && dispatchable[0].length === 0) {
        // setDispatchTitle("Dispatch Error!");
        if (nonDispatchable) {
          Array.from(multiDimensionalUnique(nonDispatchable), key => {
            e += 1;
            e_trip.push(key.key);
            return key;
          });
        }
        if (overLap) {
          Array.from(overLap, key => {
            o += 1;
            o_trip.push(key.key);
            return key;
          });
        }
      } else if (nonDispatchable.length === 0 && overLap.length === 0) {
        // setDispatchTitle("Dispatch Selected?");
        if (dispatchable[0]) {
          Array.from(dispatchable[0], key => {
            d += 1;
            d_trip.push(key.key);
            return key;
          });
        }
      } else {
        // setDispatchTitle("Continue Dispatch?");
        if (dispatchable[0]) {
          Array.from(dispatchable[0], key => {
            d += 1;
            d_trip.push(key.key);
            return key;
          });
        }
        if (overLap) {
          Array.from(overLap, key => {
            o += 1;
            o_trip.push(key.key);
            return key;
          });
        }
        if (nonDispatchable) {
          Array.from(multiDimensionalUnique(nonDispatchable), key => {
            if (key?.key) {
              e += 1;
              e_trip.push(key.key);
            }
            return key;
          });
        }
      }
      // setLoadOngoing(true);
    }

    if (d > 0) {
      get_dispatchBookings({
        variables: {
          trip_numbers: d_trip
        }
      });
    }

    for (let i = 0; i < e_trip.length; i++) {
      for (let j = 0; j < o_trip.length; j++) {
        if (e_trip[i] === o_trip[j]) {
          e_trip.splice(i, 1);
        }
      }
    }

    if (d === 0) {
      setDispatchTitle("Dispatch Error!");
    } else if (e === 0 && o === 0) {
      setDispatchTitle("Dispatch Selected?");
    } else {
      setDispatchTitle("Continue Dispatch?");
    }

    setSelectedCount({
      nonDispatchable: e,
      dispatchable: d,
      overlap: o
    });

    return (
      <>
        {(!loadOngoing && (
          <CircularProgress size={15} style={{ position: "center" }} />
        )) ||
          (d_trip.length === 0 && (
            <p>Error encountered in dispatching some trips.</p>
          )) ||
          (e_trip.length === 0 && o_trip.length === 0 && (
            <p>Are you sure you want to dispatch the selected trips?</p>
          )) || (
            <p>
              Some items will not be processed for dispatch. Are you sure you
              want to proceed with the dispatching of available trips?
            </p>
          )}
        <Table>
          <TableRow>
            <TableBody>
              {(!loadOngoing && null) ||
                (d_trip.length === 0 && (
                  <>
                    {o_trip.length > 0 && (
                      <TableRow>
                        <TableCell style={{ width: 200, color: "#FBBF77" }}>
                          OverLap Trips:
                        </TableCell>
                        <TableCell>
                          {o_trip.toString().replace(/,/g, ", ")}
                        </TableCell>
                      </TableRow>
                    )}
                    {e_trip.length > 0 && (
                      <TableRow>
                        <TableCell style={{ width: 200, color: "#FBBF77" }}>
                          Data Incomplete:
                        </TableCell>
                        <TableCell>
                          {e_trip.toString().replace(/,/g, ", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )) ||
                (e_trip.length === 0 && o_trip.length === 0 && (
                  <TableRow>
                    <TableCell style={{ width: 100, color: "#90ee90" }}>
                      Dispatchable:
                    </TableCell>
                    <TableCell>
                      {d_trip.toString().replace(/,/g, ", ")}
                    </TableCell>
                  </TableRow>
                )) || (
                  <>
                    {o_trip.length > 0 && (
                      <TableRow>
                        <TableCell style={{ width: 100, color: "#FBBF77" }}>
                          OverLap Trips:
                        </TableCell>
                        <TableCell>
                          {o_trip.toString().replace(/,/g, ", ")}
                        </TableCell>
                      </TableRow>
                    )}
                    {e_trip.length > 0 && (
                      <TableRow>
                        <TableCell style={{ width: 100, color: "#FBBF77" }}>
                          Data Incomplete:
                        </TableCell>
                        <TableCell>
                          {e_trip.toString().replace(/,/g, ", ")}
                        </TableCell>
                      </TableRow>
                    )}
                    {d_trip.length > 0 && (
                      <TableRow>
                        <TableCell style={{ width: 100, color: "#90ee90" }}>
                          For Dispatch:
                        </TableCell>
                        <TableCell>
                          {d_trip.toString().replace(/,/g, ", ")}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
            </TableBody>
          </TableRow>
        </Table>
      </>
    );
  };

  useEffect(() => {
    if (
      ongoingLoading ||
      selectedDispatchTrip ||
      listOverlapTrip ||
      !loadOngoing
    ) {
      setDispatchContent(dispatchResultContent());
    }
  }, [ongoingLoading, selectedDispatchTrip, listOverlapTrip, loadOngoing]);

  const [addNotif] = useMutation(ADD_NOTIFICATION);

  const [closeTripMutation] = useMutation(MANUAL_COMPLETE, {
    onCompleted: () => {
      setSelectedTrips([]);
      MySwal.fire({
        icon: "success",
        text: "Closed.",
        timer: 3000,
        showConfirmButton: false,
        onAfterClose: () => {}
      }).then(result => {
        if (result.dismiss === MySwal.DismissReason.timer) {
          refetch();
        } else {
          refetch();
        }
      });
      AddLogs(
        process.env.REACT_APP_PRE_DISPATCH_MODULE,
        "close_trip",
        selectedTrips
      );
    },
    refetchQueries: () => {
      return [{ query: GET_BOOKINGS_v2, variables: queryFilter }];
    }
  });

  const [dispatchMutation] = useMutation(MANUAL_COMPLETE, {
    onCompleted: res => {
      if (res?.update_booking_many?.every(element => element.success)) {
        _.forEach(dispatchData, key => {
          addNotif({
            variables: {
              notification: {
                client_id: [key.value[0].client_id],
                trip_number: key.value[0].trip_number,
                vehicle_plate_no: key.value[0].vehicle_plate_no || "",
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                notification: `Dispatch the trip ${key.value[0].trip_number}`,
                category: "User Activity",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: key.value[0].group_ids,
                group_names: key.value[0].group_names
              }
            }
          });
          addNotif({
            variables: {
              notification: {
                client_id: [key.value[0].client_id],
                trip_number: key.value[0].trip_number,
                vehicle_plate_no: key.value[0].vehicle_plate_no || "",
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                notification: `Trip ${key.value[0].trip_number} is currently incoming`,
                category: "Trip",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: key.value[0].group_ids,
                group_names: key.value[0].group_names
              }
            }
          });

          AddLogs(
            process.env.REACT_APP_PRE_DISPATCH_MODULE,
            "dispatch_trip",
            `${key.value[0].trip_number}, ${key.value[0].vehicle_plate_no}`
          );
        });

        handleClose();
        setSelectedTrips([]);
        MySwal.fire({
          icon: "success",
          text: "The trip was successfully dispatched",
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          refetch();
          setDispatchData([]);
        });
      } else {
        handleClose();
        setSelectedTrips([]);
        MySwal.fire({
          icon: "error",
          text: "Something went wrong",
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          setDispatchData([]);
        });
      }
    },
    onError: () => {
      handleClose();
      setSelectedTrips([]);
      MySwal.fire({
        icon: "error",
        text: "Something went wrong",
        timer: 3000,
        showConfirmButton: false
      }).then(() => {
        setDispatchData([]);
      });
    }
    // refetchQueries: () => {
    //   return [{ query: GET_BOOKINGS_v2, variables: queryFilter }];
    // }
  });

  const handleCloseDialog = type => {
    switch (type) {
      case "close":
        setOpenClose(false);
        break;
      case "dispatch":
        setOpenDispatch(false);
        break;
      case "refresh_single":
        setOpenRefreshSingle(false);
        break;
      case "refresh_multiple":
        setOpenRefresh(false);
        break;
      default:
        break;
    }
  };

  const handleSubmit = type => {
    switch (type) {
      case "close":
        // eslint-disable-next-line no-case-declarations
        const forClose = [];
        _.forEach(closeData, key => {
          _.forEach(key.value, values => {
            forClose.push({
              id: values.id,
              fields: {
                trip_number: values.trip_number,
                status_code_id: process.env.REACT_APP_STATUS_TRIP_CLOSED,
                status_code: "Closed",
                closed_stamp: moment()
                  .utc(+8)
                  .format("YYYY-MM-DD HH:mm:ss")
              }
            });
          });

          addNotif({
            variables: {
              notification: {
                client_id: [key.value[0].client_id],
                trip_number: key.value[0].trip_number,
                vehicle_plate_no: key.value[0].vehicle_plate_no || "",
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                notification: `Closed ${key.value[0].trip_number}`,
                category: "User Activity",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: key.value[0].group_ids,
                group_names: key.value[0].group_names
              }
            }
          });

          addNotif({
            variables: {
              notification: {
                client_id: [key.value[0].client_id],
                trip_number: key.value[0].trip_number,
                vehicle_plate_no: key.value[0].vehicle_plate_no || "",
                datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                notification: `Trip ${key.value[0].trip_number} has been closed.`,
                category: "Trip",
                is_archived: false,
                creator: "Tmsuite-web",
                group_ids: key.value[0].group_ids,
                group_names: key.value[0].group_names
              }
            }
          });
        });
        closeTripMutation({ variables: { bookings: forClose } });
        break;
      case "dispatch":
        // eslint-disable-next-line no-case-declarations
        const forDispatch = [];
        _.forEach(dispatchData, key => {
          _.forEach(key.value, values => {
            forDispatch.push({
              id: values.id,
              fields: {
                pickups: values.pickups[0],
                status_code: "Incoming",
                status_code_id: process.env.REACT_APP_STATUS_TRIP_INCOMING,
                dropoffs: values.dropoffs[0],
                dispatch_time: moment().format("YYYY-MM-DD HH:mm:ss"),
                dispatch_type: "Manual", // Manual or Auto
                dispatch_gps_status: values.vehicle_status, // Dispatch GPS Status
                dispatcher: user.username // Username
              }
            });

            _.forEach(values.dropoffs, d => {
              if (d.status_code === "Accepted") {
                addNotif({
                  variables: {
                    notification: {
                      client_id: [values.client_id],
                      trip_number: values.trip_number,
                      vehicle_plate_no: values.vehicle_plate_no || "",
                      datestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                      notification: `Good day! Please be informed that your order ${d.so_number} is now being processed`,
                      category: "Trip",
                      is_archived: false,
                      do_number: d.so_number,
                      geofence_id: [d.geofence_id],
                      creator: "Tmsuite-web",
                      group_ids: values.group_ids,
                      group_names: values.group_names,
                      booking_id: values.id
                    }
                  }
                });
              }
            });
          });
        });
        dispatchMutation({ variables: { bookings: forDispatch } });
        break;
      default:
        break;
    }
  };

  // const handleGetDispatchContent = () => {
  // get_selectedDispatchBookings({
  //   variables: {
  //     trip_numbers: selectedTrips
  //   }
  // });
  // if (openDispatch) {
  // setOpenDispatch(true);
  // }
  // };

  const handleCloseDispatch = () => {
    setActiveRawData({});
    setActiveRow({});
    setOpenDispatchModal(false);
  };

  // const handleCloseVehicle = () => {
  //   setActiveRawData({});
  //   setActiveRow({});
  //   setOpenVehicleModal(false);
  // };

  // Function used to open vehicle modal
  const handleOpenVehicleModal = () => {
    // set variable setOpenVehicleModal to true
    setOpenVehicleModal(true);
  };

  // Function used to close vehicle modal
  const handleCloseVehicleModal = () => {
    // set variable setOpenVehicleModal to false
    setOpenVehicleModal(false);
  };

  const getDateRange = () => {
    let date_range = {};
    if (!_.isEmpty(activeRow)) {
      if (
        activeRow.raw_pickups[0].arrival &&
        activeRow.raw_dropoffs[activeRow.raw_dropoffs.length - 1].arrival
      ) {
        date_range = {
          start: activeRow.raw_pickups[0].arrival,
          end: activeRow.raw_dropoffs[activeRow.raw_dropoffs.length - 1].arrival
        };
      } else {
        date_range = false;
      }
    }
    return date_range;
  };

  const handleCloseClick = val => {
    if (!val) {
      setActiveRawData({});
      setActiveRow({});
    }
    toggleCloseModal(val);
  };

  // Function used to combine and remove duplicate betweeb two array and return a new array
  const combineArray = (firstArray, secondArray) => {
    // Concat the two given array to form a new single array
    const thirdArray = firstArray.concat(secondArray);
    // Convert the new array into a set to remove a duplicate data and sort into a new array
    const uniqueElements = new Set(thirdArray.sort());
    // Convert the new set into an array and Map the new array to display the desired output
    return Array.from(uniqueElements).map(el => {
      return el ? (
        <span style={{ display: "block" }}>{el}</span>
      ) : (
        "No SO Number Yet"
      );
    });
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectAllTrips: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  return (
    <AccessControl resource="/prebooking/" process="prebooking">
      <Fragment>
        <div style={{ display: "none" }}>
          <CSVLink
            data={downloadData.body}
            headers={downloadData.headers}
            ref={csvLink}
            style={{ color: "black", textDecoration: "none" }}
          >
            Download Table
          </CSVLink>
        </div>
        {(mutation_loading || loading) && <Loading />}
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Button
              variant="contained"
              startIcon={<FilterList />}
              onClick={() => toggleDrawer()}
              style={{
                borderRadius: "25px",
                margin: "0 5px",
                fontSize: "12px",
                textTransform: "none",
                backgroundColor: isFiltered
                  ? theme.palette.primary.main
                  : "#808080"
              }}
            >
              <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                Filter
              </Badge>
            </Button>
            {/* <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                fontSize: "12px",
                backgroundColor: downloadBTN ? "#808080" : "#ff8364"
              }}
              startIcon={
                downloadBTN ? <CircularProgress size={15} /> : <GetApp />
              }
              onClick={() => {
                csvLink.current.link.click();
                AddLogs(
                  process.env.REACT_APP_PRE_DISPATCH_MODULE,
                  "download_table",
                  keyLog
                );
              }}
              disabled={downloadBTN}
            >
              {downloadBTN ? `Preparing...` : `Download`}
            </Button> */}
            <Download
              csvLink={csvLink}
              downloadBTN={downloadBTN}
              keyLog={keyLog}
              getDownloadData={() => {
                setDownloadBTN(true);
                getDownloadData({
                  fetchPolicy: "cache-and-network",
                  variables: {
                    ...queryFilter,
                    rows_per_page: bookings_data?.get_bookings_per_trip?.total
                  }
                }).then(() => {
                  csvLink.current.link.click();
                  AddLogs(
                    process.env.REACT_APP_PRE_DISPATCH_MODULE,
                    "download_table",
                    keyLog
                  );
                  setDownloadBTN(false);
                });
              }}
            />
            {permissions?.upload?.edit && (
              <Button
                className={classes.btn_upload}
                variant="contained"
                startIcon={<Publish />}
                onClick={handleOpenUpload}
              >
                Upload
              </Button>
            )}
            <div className={classes.root} />
            <Typography className={classes.dateToday}>
              As of {moment().format("lll")}
            </Typography>
            <Pagination
              rowsPerPage={rowsPerPage}
              total={Math.ceil(totalPage / rowsPerPage)}
              currentPage={currentPage}
              onRowsPerPageChange={n => {
                setRowsPerPage(n);
                setCurrentPage(0);
              }}
              onPageChange={n => setCurrentPage(n)}
              onPageUp={n => setCurrentPage(n)}
              onPageDown={n => setCurrentPage(n)}
            />
            {/* <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={totalPage}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              // onChangePage={handleChangePage}
              onPageChange={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ overflow: "hidden", color: "#9F9F9F" }}
            /> */}
          </Toolbar>
          {selectedTrips.length > 0 && (
            <Toolbar className={classes.multipleSelect}>
              <Typography className={classes.countSelected}>
                {/* <span className={classes.countSelectedTrips}>{selectedTrips.length}</span> */}
                <span style={{ color: theme1.palette.primary.main }}>
                  {selectedTrips.length}
                </span>{" "}
                trip/s is selected on this page
              </Typography>
              <div className={classes.root} />
              {permissions?.close?.edit && (
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    backgroundColor: "#C80202"
                  }}
                  className={classes.muiButtonSelectedCheckBox}
                  onClick={() => {
                    setOpenClose(true);
                    get_selectedBookings({
                      variables: {
                        trip_numbers: selectedTrips
                      }
                    });
                  }}
                >
                  Close
                </Button>
              )}
              &nbsp;
              {permissions?.dispatch?.edit && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: theme.palette.info.main
                  }}
                  className={classes.muiButtonSelectedCheckBox}
                  // disabled={ongoingLoading}
                  onClick={() => {
                    get_selectedDispatchBookings({
                      variables: {
                        rows_per_page: selectedTrips.length,
                        trip_numbers: selectedTrips
                      }
                    });
                    setLoadOngoing(false);
                    setOpenDispatch(true);
                  }}
                >
                  Dispatch
                </Button>
              )}
              &nbsp;
              <Button
                // color="default"
                variant="contained"
                style={{ backgroundColor: "#808080" }}
                className={classes.muiButtonSelectedCheckBox}
                // onClick={() => refreshTrips(true)}
                onClick={() => setOpenRefresh(true)}
              >
                Refresh
              </Button>
            </Toolbar>
          )}
        </AppBar>
        <div
          className={classes.tableRoot}
          // style={{ marginTop: selectedTrips.length > 0 ? 109 : 64 }}
        >
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                onRequestSort={handleRequestSort}
                onSelectAllTrips={handleSelectAllTrips}
                isChecked={isChecked}
              />
              <TableBody>
                {bookings.length ? (
                  getData().map((booking, index) => {
                    const isItemSelected = isSelected(booking.tripNumber);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      // <TableRow key={booking.tripNumber}>
                      <TableRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${booking.tripNumber}-${index}`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          {booking.status.toLowerCase() !== "closed" &&
                          booking.status.toLowerCase() !== "cancelled" &&
                          booking.status.toLowerCase() !== "completed" ? (
                            <Checkbox
                              color="primary"
                              // name={booking.tripNumber}
                              // checked={
                              //   selectedTrips.indexOf(booking.tripNumber) !== -1
                              // }
                              // onChange={handleTripSelect}
                              checked={isItemSelected}
                              onClick={event =>
                                handleClick(event, booking.tripNumber)
                              }
                              inputProps={{ "aria-labelledby": labelId }}
                              // disabled={booking.data_completed}
                            />
                          ) : null}
                        </TableCell>
                        {/* <TableCell style={{ paddingLeft: "30px" }}>
                          <Tooltip
                            title={
                              booking.dispatch_type &&
                              booking.dispatch_type.toLowerCase() !==
                                "not yet dispatched"
                                ? `${booking.dispatch_type} Dispatched`
                                : `Undefined`
                            }
                          >
                            <Badge
                              classes={{
                                badge: booking.dispatch_type
                                  ? (booking.dispatch_type.toLowerCase() ===
                                      "manual" &&
                                      classes.customBadgeManual) ||
                                    (booking.dispatch_type.toLowerCase() ===
                                      "automatic" &&
                                      classes.customBadgeAutomatic) ||
                                    (booking.dispatch_type.toLowerCase() ===
                                      "not yet" &&
                                      classes.customBadgeNotYetDispatch) ||
                                    classes.customBadgeUndefine
                                  : classes.customBadgeUndefine
                              }}
                              variant="dot"
                            />
                          </Tooltip>
                        </TableCell> */}
                        {/* <TableCell>
                          {booking.is_urgent && (
                            <Tooltip title="Hotload">
                              <Warning
                                style={{ fontSize: 12, color: "#EA4335" }}
                              />
                            </Tooltip>
                          )}
                        </TableCell> */}
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          {booking.is_overdue && (
                            <Tooltip title="It seems this trip is overdue. Would you like to close it?">
                              <AssignmentLate
                                style={{ fontSize: 12, color: "#EA4335" }}
                              />
                            </Tooltip>
                          )}
                          {booking.is_urgent && (
                            <Tooltip title="Hotload">
                              <Warning
                                style={{ fontSize: 12, color: "#EA4335" }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ width: "140px", verticalAlign: "text-top" }}
                        >
                          <Tooltip
                            title={combineArray(
                              booking.raw_pickups.map(el => {
                                return el.so_number;
                              }),
                              booking.raw_dropoffs.map(el => {
                                return el.so_number;
                              })
                            )}
                          >
                            <span className={classes.muiTableCellData}>
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {booking.tripNumber}
                              </Highlight>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          {booking.has_empty_so && (
                            <Tooltip title="No SO number yet">
                              <ErrorOutline
                                style={{
                                  fontSize: "inherit",
                                  color: "red "
                                }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ width: "140px", verticalAlign: "text-top" }}
                        >
                          <Highlight
                            search={contextData.searchKeyword.value}
                            className={classes.muiTableCellData}
                          >
                            {booking.service_type || "N/A"}
                          </Highlight>
                        </TableCell>
                        <TableCell
                          style={{ width: "150px", verticalAlign: "text-top" }}
                        >
                          <span className={classes.muiTableCellData}>
                            {booking.pickups.map((p, p_index) => {
                              return renderDestination(p, p_index);
                            })}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ width: "170px", verticalAlign: "text-top" }}
                        >
                          <Highlight
                            search={contextData.searchKeyword.value}
                            className={classes.muiTableCellData}
                          >
                            {booking.pickups.map((p, p_index) => {
                              return renderDestinationArrival(p, p_index);
                            })}
                          </Highlight>
                        </TableCell>
                        <TableCell
                          style={{ width: "150px", verticalAlign: "text-top" }}
                        >
                          <span className={classes.muiTableCellData}>
                            {booking.dropoffs.map((p, p_index) => {
                              return renderDestination(p, p_index);
                            })}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ width: "170px", verticalAlign: "text-top" }}
                        >
                          <Highlight
                            search={contextData.searchKeyword.value}
                            className={classes.muiTableCellData}
                          >
                            {booking.dropoffs.map((p, p_index) => {
                              return renderDestinationArrival(p, p_index);
                            })}
                          </Highlight>
                        </TableCell>
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          <Highlight
                            search={contextData.searchKeyword.value}
                            className={classes.muiTableCellData}
                          >
                            {booking.hauler}
                          </Highlight>
                        </TableCell>
                        <TableCell
                          style={{ width: "100px", verticalAlign: "text-top" }}
                        >
                          <span>
                            {renderVehicleCell(booking)}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ width: "90px", verticalAlign: "text-top" }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "90px",
                              color: booking.is_inter_island
                                ? "#66347F"
                                : "#2B3467"
                            }}
                            className={classes.muiTableCellData}
                          >
                            {booking.is_inter_island
                              ? "Interisland trip"
                              : "Standard trip"}
                          </span>
                        </TableCell>
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          <Fragment>
                            <span
                              style={{ color: "#C4C4C4", display: "block" }}
                              className={classes.muiTableCellData}
                            >
                              <Highlight
                                search={contextData.searchKeyword.value}
                              >
                                {booking.status}
                              </Highlight>
                            </span>
                            {(booking.status.toLowerCase() === "incoming" ||
                              booking.status.toLowerCase() === "in transit") &&
                              booking.eta && (
                                <Tooltip title={`As of ${booking.eta.as_of}`}>
                                  <span
                                    style={{
                                      display: "block",
                                      fontSize: 10,
                                      color: booking.eta.isLate
                                        ? "red"
                                        : "green"
                                    }}
                                  >
                                    <ErrorOutline
                                      style={{
                                        fontSize: "inherit",
                                        color: "inherit"
                                      }}
                                    />
                                    {booking.eta.eta}
                                  </span>
                                </Tooltip>
                              )}
                          </Fragment>
                        </TableCell>
                        <TableCell style={{ verticalAlign: "text-top" }}>
                          <IconButton
                            aria-describedby={id}
                            onClick={event => {
                              const { get_bookings_per_trip } = bookings_data;
                              const rawTripData = get_bookings_per_trip.bookings.filter(
                                b => b.key === booking.tripNumber
                              );
                              setActiveRawData(rawTripData[0]);
                              setActiveRow(booking);
                              setOpenActions(event.currentTarget);
                            }}
                            style={{ fontSize: "1.2rem", padding: 6 }}
                          >
                            {booking.status.toLowerCase() !== "cancelled" ? (
                              <MoreVert />
                            ) : (
                              ""
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={20}
                      align="center"
                      style={{ backgroundColor: "#F8f8f8" }}
                    >
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ margin: "15px" }}
                        className={classes.closeDescription}
                      >
                        No data to display
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={openActions}
          onClose={handleClose}
        >
          {activeRow && activeRow.status === "Closed" ? (
            <>
              {permissions?.manual_complete?.edit && (
                <MenuItem className={classes.muiMenuCell}>
                  Manual Complete
                </MenuItem>
              )}

              <MenuItem
                disabled={activeRow.data_completed}
                onClick={() => refreshTrips(false)}
                className={classes.muiMenuCell}
              >
                Refresh
              </MenuItem>
            </>
          ) : (
            <>
              {permissions?.change_truck?.edit && (
                <MenuItem
                  disabled={!getDateRange() || activeRow?.status === "Closed"}
                  onClick={() => {
                    setOpenVehicleModal(true);
                    setOpenActions(null);
                  }}
                  className={classes.muiMenuCell}
                >
                  Change Truck
                </MenuItem>
              )}
              {permissions?.split?.edit && !activeRow?.is_split ? (
                <MenuItem
                  component={Link}
                  disabled={!activeRow?.has_split}
                  className={classes.muiMenuCell}
                  to={
                    activeRow?.data_completed && !activeRow?.has_empty_so
                      ? {
                          pathname: `/split`,
                          state: {
                            parsedTripData: activeRow,
                            rawTripData: activeRawData,
                            moduleName:
                              process.env.REACT_APP_PRE_DISPATCH_MODULE,
                            dateRange: getDateRange()
                          }
                        }
                      : {}
                  }
                  onClick={() => {
                    if (!activeRow?.data_completed || activeRow?.has_empty_so) {
                      MySwal.fire({
                        icon: "warning",
                        title: "Warning!",
                        text: "Please complete trip details",
                        timer: 3000
                      });
                    }
                  }}
                >
                  Split
                </MenuItem>
              ) : (
                ""
              )}

              {permissions?.dispatch?.edit && (
                <MenuItem
                  disabled={activeRow?.status !== "Processing"}
                  className={classes.muiMenuCell}
                  onClick={() => {
                    if (
                      !_.isEmpty(activeRow) &&
                      activeRow?.pre_dispatched_ready
                    ) {
                      setOpenDispatchModal(true);
                      setOpenActions(null);
                    } else {
                      MySwal.fire({
                        icon: "warning",
                        title: "Warning!",
                        text: "Please complete trip details",
                        timer: 3000
                      });
                    }
                  }}
                >
                  Dispatch
                </MenuItem>
              )}

              {permissions?.close?.edit && (
                <MenuItem
                  disabled={activeRow?.status === "Closed"}
                  onClick={() => {
                    handleCloseClick(true);
                    setOpenActions(null);
                  }}
                  className={classes.muiMenuCell}
                >
                  Close
                </MenuItem>
              )}
              <MenuItem
                disabled={activeRow?.data_completed}
                onClick={() => setOpenRefreshSingle(true)}
                className={classes.muiMenuCell}
              >
                Refresh
              </MenuItem>
              <MenuItem
                // disabled={activeRow?.data_completed}
                onClick={() => setOpenInterIsland(true)}
                className={classes.muiMenuCell}
              >
                {activeRow.is_inter_island
                  ? "Untag as Interisland"
                  : "Tag as Interisland"}
              </MenuItem>
            </>
          )}
        </Popover>
        {openFilter && (
          <Filter
            drawer={openFilter}
            toggleDrawer={toggleDrawer}
            applyFilter={applyFilter}
            loaded={loaded}
            serviceTypes={serviceTypes}
            moduleName="Prebooking"
          />
        )}
        {openDispatchModal && (
          <DispatchDialog
            moduleName={process.env.REACT_APP_PRE_DISPATCH_MODULE}
            toggle={openDispatchModal}
            tripData={{ parsedTripData: activeRow, rawTripData: activeRawData }}
            queryFilter={{
              ...queryFilter,
              skip: currentPage * rowsPerPage,
              rows_per_page: currentPage * rowsPerPage + rowsPerPage
            }}
            closeDispatchModal={handleCloseDispatch}
          />
        )}
        {openVehicleModal && (
          <VehicleAssignDialog
            moduleName={process.env.REACT_APP_PRE_DISPATCH_MODULE}
            toggle={openVehicleModal}
            openVehicleModal={handleOpenVehicleModal}
            closeVehicleModal={handleCloseVehicleModal}
            setTrip={setTrip}
            queryFilter={{
              ...queryFilter,
              skip: currentPage * rowsPerPage,
              rows_per_page: currentPage * rowsPerPage + rowsPerPage
            }}
            tripData={{
              parsedTripData: activeRow,
              rawTripData: activeRawData,
              dateRange: getDateRange()
            }}
            fn={() => {
              refetch();
            }}
            // closeVehicleModal={handleCloseVehicle}
          />
        )}
        {uploadDialog && (
          <UploadDialog
            uploadDialog={uploadDialog}
            handleCloseUpload={handleCloseUpload}
            serviceTypes={serviceTypes}
            // - refetch={refetch}
            // getVariables={getVariables}
            // clientLimit={clientData?.get_client?.user_count || 0}
            // userTotal={currentNumber}
          />
        )}
        {closeModal && (
          <CloseDialog
            moduleName={process.env.REACT_APP_PRE_DISPATCH_MODULE}
            open={closeModal}
            toggle={handleCloseClick}
            queryFilter={{
              ...queryFilter,
              skip: currentPage * rowsPerPage,
              rows_per_page: currentPage * rowsPerPage + rowsPerPage
            }}
            tripData={{ parsedTripData: activeRow, rawTripData: activeRawData }}
          />
        )}
        {openClose && (
          <ConfirmationDialog
            toggle={openClose}
            close={() => handleCloseDialog("close")}
            fn={() => handleSubmit("close")}
            title="Close All Selected?"
            content="You cannot undo this action. Are you sure you want to close the selected items?"
          />
        )}
        {openRefresh && (
          <ConfirmationDialog
            toggle={openRefresh}
            close={() => handleCloseDialog("refresh_multiple")}
            fn={() => refreshTrips(true)}
            title="Refresh All Selected?"
            content="You cannot undo this action. Are you sure you want to refresh the selected items?"
          />
        )}
        {openRefreshSingle && (
          <ConfirmationDialog
            toggle={openRefreshSingle}
            close={() => handleCloseDialog("refresh_single")}
            fn={() => refreshTrips(false)}
            title="Refresh?"
            content="Are you sure you want to refresh the details for this trip?"
          />
        )}
        {/* <ConfirmationDialog
          toggle={openDispatch}
          close={() => handleCloseDialog("dispatch")}
          fn={() => handleSubmit("dispatch")}
          title={dispatchTitle}
          content={loadOngoing ? dispatchContent : <span style={{float: "center"}} ><CircularProgress size={15} /></span>}
        /> */}
        {openDispatch && (
          <Dialog
            open={openDispatch}
            onClose={() => handleCloseDialog("dispatch")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.closeContainer }}
          >
            {loadOngoing ? (
              <DialogTitle id="alert-dialog-title">
                <span className={classes.closeTitle}>{dispatchTitle}</span>
              </DialogTitle>
            ) : null}
            <DialogContent>
              {!loadOngoing ? (
                <Grid container style={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CircularProgress size={25} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  />
                </Grid>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  <span className={classes.closeDescription}>
                    {dispatchContent}
                  </span>
                </DialogContentText>
              )}
            </DialogContent>
            {loadOngoing ? (
              <DialogActions>
                {selectedCount.dispatchable > 0 ? (
                  <>
                    <Button
                      style={{
                        borderRadius: 100,
                        width: 150,
                        borderColor: "#f44336",
                        color: "#f44336"
                      }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleCloseDialog("dispatch")}
                    >
                      No
                    </Button>
                    <Button
                      style={{ borderRadius: 100, color: "white", width: 150 }}
                      variant="contained"
                      onClick={() => {
                        // fn(event);
                        handleSubmit("dispatch");
                        handleCloseDialog("dispatch");
                      }}
                      color="primary"
                      autoFocus
                    >
                      Yes
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      // style={{ borderRadius: 100, color: "white", width: 150 }}
                      variant="contained"
                      onClick={() => handleCloseDialog("dispatch")}
                      // color={theme.palette.primary.main}
                      className={classes.circularButtonYes}
                      autoFocus
                    >
                      Ok
                    </Button>
                  </>
                )}
              </DialogActions>
            ) : null}
          </Dialog>
        )}
        {openInterIsland && (
          <ConfirmationDialog
            toggle={openInterIsland}
            close={() => setOpenInterIsland(false)}
            fn={() => interIslandHandler()}
            title={
              activeRow?.is_inter_island
                ? "Untag Trip as Interisland"
                : "Tag Trip as Interisland?"
            }
            content={`Are you sure you want to ${
              activeRow?.is_inter_island ? "untag" : "tag"
            } this trip as interisland?`}
          />
        )}
        {openConfirm && (
          <ConfirmationDialog
            toggle={openConfirm}
            close={() => setOpenConfirm(false)}
            fn={() => refetch()}
            title="Success"
            content="Trip type updated successfully."
            type="basic_confirm_modal"
          />
        )}
      </Fragment>
    </AccessControl>
  );
};

export default PreBooking;
