import { makeStyles } from "@material-ui/core";

export default makeStyles({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    height: "100%"
  },
  main: {
    padding: "20px 25px",
    boxSizing: "border-box",
    position: "relative",
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    overflowY: "auto",
    height: "auto",
    background: "#F8F8F8",
    "&::-webkit-scrollbar-thumb": {
      border: "5px solid #f8f8f8"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      border: "4px solid #f8f8f8",
      background: "#969696"
    },
    "&::-webkit-scrollbar-track": {
      border: "5px solid #f8f8f8"
    }
  },
  formTitle: {
    fontSize: "18px",
    letterSpacing: 1.2,
    fontWeight: 600,
    marginLeft: 8,
    color: "#3c3c3c"
  },
  container: {
    background: "#fff",
    position: "relative",
    minHeight: "100%",
    height: "auto",
    // boxShadow: "0px 0px 2px #b3b3b3",
    border: "1px solid #b3b3b35c",
    borderRadius: "12px",
    padding: "30px",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: "1rem",
    // justifyContent: "space-evenly",
    "& .MuiAutocomplete-inputRoot": {
      fontSize: "12px",
      padding: "12px 0px"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #ccc"
    },
    "& .WAMuiChipInput-underline-44:before": {
      borderBottom: "2px solid #ccc"
    },
    "& .MuiFormLabel-root": {
      fontWeight: "bold",
      color: "#454545",
      letterSpacing: "1px"
    }
  },
  division: {
    position: "relative",
    width: "100%",
    "& > .inputGroups": {
      margin: "24px 0px",
      width: "250px"
    },
    "&:nth-child(1)": {
      gridColumn: "1 / 4"
    },
    "&:nth-child(2)": {
      gridColumn: "4 / 12",
      marginTop: "-12px"
    }
  },
  divisionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#454545",
    letterSpacing: "1.5px"
  },
  formRoot: {
    width: "250px",
    "& .MuiInput-formControl": {
      width: "100%"
    },
    "& #every-label": {
      margin: "0px !important"
    },
    "& .MuiInputBase-multiline": {
      padding: 0
    },
    "& .Mui-disabled .MuiSvgIcon-root": {
      display: "none"
    }
  },
  selectRoot: {
    fontSize: "12px",
    padding: "12px 0px",
    textTransform: "capitalize"
  },
  textFieldRoot: {
    "& input": {
      fontSize: "12px",
      padding: "12px 0px"
    },
    "& textarea": {
      fontSize: "12px",
      padding: "12px 0px 12px 0px"
    }
  },
  menuItem: {
    textTransform: "capitalize"
  },
  helpIcon: {
    fontSize: "21px !important",
    verticalAlign: "text-top",
    marginRight: 8,
    opacity: 0.5,
    cursor: "help",
    color: "black"
  },
  toolTip: {
    background: "#0000008f",
    maxWidth: "40ch",
    borderRadius: "4px",
    letterSpacing: "0.5px"
  },
  saveButton: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    width: "200px",
    borderRadius: "100px",
    transform: "translateX(-50%)"
  }
});
